import { $qs, $setAttr } from 'fxdom/es';
import { find, go, keys, pick, sel } from 'fxjs/es';
import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { OMPCoreLayoutF } from '../../../Core/Layout/F/Function/module/OMPCoreLayoutF.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';

export const getIsUser = (user) =>
  user
    ? go(
        user,
        pick([
          'id',
          'type',
          'email',
          'name',
          'collabo_type',
          'review_alert',
          'is_mobile_cert',
          'team_id',
          sel('_.policies', user) ? '_' : void 0,
        ]),
      )
    : null;

export const afterSignIn = (user) => {
  OMPCoreUtilF.resetCartWish();
  const is_user = box.sel('is_user');
  const same_user = go(
    keys(is_user),
    find((key) => {
      if (key === '_') return false;
      return is_user[key] !== user[key];
    }),
  );

  if (is_user && !same_user) return;

  const new_user = getIsUser(user);
  box.set('is_user', new_user);

  $setAttr(
    {
      is_session: true,
      mp_worker_policy: !!user?._?.policies?.mp_worker_policy,
      line_worker_policy: !!user?._?.policies?.line_worker_policy,
      master_admin: !!user?._?.policies?.master_admin,
      is_mobile_cert: user.is_mobile_cert,
      is_verify: user.type !== 'NEED_VERIFY',
    },
    $qs('html'),
  );

  if (OMPCoreUtilF.isMobile()) {
    OMPCoreLayoutF.resetHamMenuLogin(new_user);
  } else {
    OMPCoreLayoutF.resetLeftSubMenu(new_user);
  }
};

export const catchError = async (e) => {
  $.don_loader_end();
  // MEMO: 프론트 에러
  if (e?.handle) {
    await OMPCoreUtilF.alert({
      content: e?.message || TT('error::common::error_01'),
    });
    if (e?.omp_data?.id) $qs(`#${e.omp_data.id}`)?.focus();
    return;
  }

  // MEMO: axios 에러
  if (e?.response?.data?.message) {
    await OMPCoreUtilF.alert({
      content: e?.response?.data?.message || TT('error::common::error_01'),
    });
    $qs(`#email`)?.focus();
    return;
  }

  // MEMO: fetch 에러
  if (e?.message) {
    await OMPCoreUtilF.alert({
      content: e?.message || TT('error::common::error_01'),
    });
    $qs(`#email`)?.focus();
    return;
  }

  OMPCoreUtilF.alert({
    content: TT('error::common::error_01'),
  });
  ConfigSentryF.error(e);
};
