import { filter, flatMap, go, html, strMap } from 'fxjs/es';
import { OMPCoreUtilF } from '../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';

export const makeModalHtml = ({ type, tab_names: option_names, selected_name, tabs }) => {
  const contentDescDetailHtml = (info) => {
    return html`<div class="content_info__content__description">${info.description}</div>
      <div class="content_info__content__detail">
        ${strMap((i) => {
          return html`
            <div>
              <div class="title">${i.title}</div>
              <div class="content">
                <span class="content__main">${i.content}</span>
                ${i.content2 ? `<span class="content__sub">${i.content2}</span>` : ''}
              </div>
            </div>
          `;
        }, info.info)}
      </div>`;
  };

  return go(
    option_names,
    flatMap((o) => filter(({ name }) => o === name, tabs)),
    strMap(async (info) => {
      const name = info.name;

      return html`<div
        class="content_info ${name === selected_name ? 'selected' : ''}"
        data-title="${name}"
        data-modal_title=${type}
      >
        <div class="content_info__content">
          ${OMPCoreUtilF.isMobile() ? '' : `<div class="content_info__content__title">${name}</div>`}
          ${OMPCoreUtilF.isMobile()
            ? html`<img src=${info.img} alt="${name}" /> ${contentDescDetailHtml(info)}`
            : contentDescDetailHtml(info)}
        </div>
        ${OMPCoreUtilF.isMobile() ? '' : `<img src=${info.img} alt="${name}"/>`}
      </div>`;
    }),
  );
};
