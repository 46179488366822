import { getCvDesigns, getCvObj } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { go, not, some } from 'fxjs/es';
import { getRealFcanvass } from '../../../../Maker/F/getSth.js';

function vertical(cv_print_area, px_per_1mm, mm) {
  const center_y = cv_print_area.top + (cv_print_area.scaleY * cv_print_area.height) / 2;
  const top = center_y - 1 * px_per_1mm - (mm / 2) * px_per_1mm;
  const left = cv_print_area.left;
  const width = cv_print_area.width * cv_print_area.scaleX;
  const height = px_per_1mm * mm;
  const tl = new fabric.Point(left, top);
  const br = new fabric.Point(left + width, top + height);
  return { tl, br };
}

function horizontal(cv_print_area, px_per_1mm, mm) {
  const center_x = cv_print_area.left + (cv_print_area.scaleX * cv_print_area.width) / 2;
  const left = center_x - 1 * px_per_1mm - (mm / 2) * px_per_1mm;
  const top = cv_print_area.top;
  const width = px_per_1mm * mm;
  const height = cv_print_area.height * cv_print_area.scaleY;
  const tl = new fabric.Point(left, top);
  const br = new fabric.Point(left + width, top + height);
  return { tl, br };
}

function _func(fcanvas, is_horizontal) {
  const mm = 4;
  const px_per_1mm = fcanvas._px_per_1cm * 0.1;
  const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');

  const { tl, br } = is_horizontal
    ? horizontal(cv_print_area, px_per_1mm, mm)
    : vertical(cv_print_area, px_per_1mm, mm);
  return go(
    getCvDesigns(fcanvas._objects),
    some((cv_obj) => {
      return cv_obj.intersectsWithRect(tl, br) || cv_obj.isContainedWithinRect(tl, br);
    }),
  );
}

export function isWeFolidngPass() {
  return go(
    getRealFcanvass(),
    some((fcanvas) => {
      const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
      return _func(fcanvas, cv_print_area.width > cv_print_area.height);
    }),
    not,
  );
}
