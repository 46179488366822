import { each, go, map } from 'fxjs/es';
import { getRealFcanvass } from '../../../../Maker/F/getSth.js';
import { getCvDesigns } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { NewMakerCheckerS } from '../../S/Function/module/NewMakerCheckerS.js';
import { full_cv_text_image } from '../../../../Maker/F/text.js';

export const isCheckWrongTextPass = async () => {
  let is_pass = true;
  await go(
    getRealFcanvass(),
    map((fcanvas) => getCvDesigns(fcanvas._objects)),
    each(async (designs) => {
      await go(
        designs,
        each(async (design) => {
          if (design._data.cv_type === 'cv_text_image') {
            const text = design._data.text_info.text;
            const _text = await NewMakerCheckerS.getOnlyPossibleText(text);
            if (text !== _text) {
              is_pass = false;
              design._data.text_info.text = _text;
              if (!design._data.text_info.text) {
                design.canvas.remove(design);
                return;
              }
              await full_cv_text_image(function () {}, design);
            }
          }
        }),
      );
    }),
  );
  if (!is_pass) {
    $.alert(T('maker::Please notice that unsupportive characters are going to be excluded.'));
  }
  return is_pass;
};
