import { CommonNS } from '@marpple/sticker-editor';
import { go, head, split } from 'fxjs/es';

export const convertURLToEl = async (url) => {
  const url_without_qs = go(
    box.sel('maker->product_color->_->base_product->maker_type') !== 'STICKER' ? G.to_original(url) : url,
    split('?'),
    head,
  );
  const img_el = /** @type {HTMLImageElement} */ await new Promise((resolve, reject) => {
    const img_el = new Image();
    img_el.crossOrigin = 'Anonymous';
    img_el.onload = () => resolve(img_el);
    img_el.onerror = reject;
    img_el.src = `${url_without_qs}?canvas=marpple-sticker-editor-v3`;
  });

  const canvas_el = document.createElement('canvas');
  canvas_el.width = img_el.width;
  canvas_el.height = img_el.height;
  canvas_el
    .getContext('2d')
    .drawImage(img_el, 0, 0, img_el.width, img_el.height, 0, 0, canvas_el.width, canvas_el.height);

  const blob = /** @type {Blob} */ await new Promise((resolve, reject) =>
    canvas_el.toBlob((blob) => {
      if (blob == null) {
        const error = new Error(`Cannot convert PNG to Blob.`);
        error.__mp_alert_message = T('maker_error_alert::PNG 이미지를 해석할 수 없습니다.');
        return reject(error);
      }
      return resolve(blob);
    }, 'image/png'),
  );

  const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.addEventListener('error', reject);
    file_reader.readAsDataURL(blob);
  });

  const image_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'image');
  image_el.setAttributeNS(null, 'width', `${img_el.width}`);
  image_el.setAttributeNS(null, 'height', `${img_el.height}`);
  image_el.setAttributeNS(null, 'href', data_url);
  image_el.dataset.mpse__original_url = url_without_qs;

  return image_el;
};
