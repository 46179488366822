import { filter, go, each, some, flatMap, compact } from 'fxjs/es';

/*조건 제품이 태스크세트를 갖고 있고, 그 세트 태스크의 부모와 자기 태스크의 부모가 일치해야함*/
export const setProjectionsWithOnlyItsUp = ({ projection, task_parent_id }) =>
  go(JSON.parse(JSON.stringify(projection)), (projection) => {
    projection._.ups = go(
      projection._.ups,
      filter((up) => {
        return go(
          up._.base_product?._?.task_set?._?.tasks,
          some((task) => {
            return task.parent_id === task_parent_id;
          }),
        );
      }),
    );
    return projection;
  });
export const setTaskWithOnlyItsUp = (task_list) =>
  go(
    task_list,
    each((task) => {
      const task_parent_id = task.parent_id;
      task._.projection = JSON.parse(JSON.stringify(task._.projection));
      task._.projection._.ups = go(
        task._.projection._.ups,
        filter((up) => {
          const has_press_type = go(
            up._.up_cs,
            flatMap((up_c) => up_c._.printable_product?.product_faces2?.value),
            compact,
            flatMap((pf) => pf.printable_files),
            some((print_file) => {
              if (!print_file.press_type_id) return;
              if (!task.press_type_id) return;
              return print_file.press_type_id == task.press_type_id;
            }),
          );

          if (has_press_type) return true;

          return go(
            up._.base_product?._?.task_set?._?.tasks,
            some((task) => {
              return task.parent_id === task_parent_id;
            }),
          );
        }),
      );
    }),
  );
