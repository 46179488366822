import { createCanvasElement } from '../../../Canvas/S/util.js';
import { go } from 'fxjs/es';
import { mappingBilinearInterpolation } from './composite_core.js';
import { fourPointMapping } from './mapping_fns.js';
import { makeImageFromUrl } from '../../../Maker/F/util.js';

function getFullCylinderPrint(size_face, print_area_canvas) {
  const cylinder_width_cm = size_face.print.etc_meta?.cylinder_width_cm;
  const width_cm = size_face.print.cm.width;
  const cylinder_px = print_area_canvas.width * (cylinder_width_cm / width_cm);

  const margin = 2;
  const left_margin = (cylinder_px - print_area_canvas.width) / 2;
  const cylinder_canvas_with_margin = createCanvasElement({
    width: cylinder_px + print_area_canvas.width + left_margin,
    height: print_area_canvas.height + margin * 2,
  });
  const ctx = cylinder_canvas_with_margin.getContext('2d');
  ctx.save();
  ctx.translate(left_margin, 0);
  ctx.drawImage(
    print_area_canvas,
    0,
    0,
    print_area_canvas.width,
    print_area_canvas.height,
    0,
    margin,
    print_area_canvas.width,
    print_area_canvas.height,
  );
  ctx.drawImage(
    print_area_canvas,
    0,
    0,
    print_area_canvas.width,
    print_area_canvas.height,
    cylinder_px,
    margin,
    print_area_canvas.width,
    print_area_canvas.height,
  );
  ctx.restore();
  return { cylinder_canvas_with_margin, cylinder_px };
}
/*
 * 288/311
 * 331/365
 * */
function get180ImageFromPercent({ cylinder_canvas_with_margin, cylinder_px }, percent) {
  const canvas180 = createCanvasElement({
    width: cylinder_px / 2,
    height: cylinder_canvas_with_margin.height,
  });
  const ctx = canvas180.getContext('2d');
  ctx.drawImage(
    cylinder_canvas_with_margin,
    cylinder_px * percent,
    0,
    canvas180.width,
    cylinder_canvas_with_margin.height,
    0,
    0,
    canvas180.width,
    canvas180.height,
  );
  return canvas180;
}

export async function makeWarpCylinder(
  { size_face, print_area_canvas, mask_url, canvas_width },
  {
    percent = 0,
    up_side = 0,
    down_side = 0,
    top,
    left,
    angle = 0,
    angle_v2 = 0,
    width,
    height = 0,
    x1_perspective,
    y1_perspective,
    x2_perspective,
    y2_perspective,
    x3_perspective,
    y3_perspective,
    x4_perspective,
    y4_perspective,
  },
  is_test,
) {
  top *= canvas_width / 900;
  left *= canvas_width / 900;
  width *= canvas_width / 900;
  height *= canvas_width / 900;
  const mask_img = mask_url && (await makeImageFromUrl(mask_url));
  const { cylinder_canvas_with_margin, cylinder_px } = getFullCylinderPrint(size_face, print_area_canvas);
  const canvas180 = go(get180ImageFromPercent({ cylinder_canvas_with_margin, cylinder_px }, percent), (c) => {
    if (!is_test) return c;
    const canvas = createCanvasElement(c);
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#bf00ff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(c, 0, 0);
    return canvas;
  });

  const result_warped_canvas = newMakeCircleWarpedCanvas(
    canvas180,
    { up_side, down_side },
    size_face.print?.etc_meta?.is_cylinder2,
  );
  const warped_canvas_width = result_warped_canvas.width;
  const warped_canvas_height = result_warped_canvas.height;
  const x1d = warped_canvas_width * x1_perspective || 0;
  const y1d = y1_perspective * warped_canvas_height || 0;

  const x3d = result_warped_canvas.width - warped_canvas_width * x3_perspective;
  const y3d = y3_perspective * warped_canvas_height || 0;

  const x2d = warped_canvas_width * x2_perspective || 0;
  const y2d = result_warped_canvas.height - y2_perspective * warped_canvas_height;

  const x4d = result_warped_canvas.width - warped_canvas_width * x4_perspective;
  const y4d = result_warped_canvas.height - y4_perspective * warped_canvas_height;
  const perspectived_canvas = go(
    fourPointMapping(
      result_warped_canvas,
      result_warped_canvas.width,
      result_warped_canvas.height,
      result_warped_canvas.width,
      result_warped_canvas.height,
      0,
      0,
      x1d,
      y1d,
      0,
      result_warped_canvas.height,
      x2d,
      y2d,
      result_warped_canvas.width,
      0,
      x3d,
      y3d,
      result_warped_canvas.width,
      result_warped_canvas.height,
      x4d,
      y4d,
    ),
    (image_data) => {
      const canvas = createCanvasElement(result_warped_canvas);
      const ctx = canvas.getContext('2d');
      ctx.putImageData(image_data, 0, 0);
      return canvas;
    },
  );
  const result_canvas = createCanvasElement({ width: canvas_width || 900, height: canvas_width || 900 });
  const ctx = result_canvas.getContext('2d');
  const target_coord = {
    x: left,
    y: top,
    width,
    height: width * (perspectived_canvas.height / perspectived_canvas.width) + height,
  };
  if (angle_v2) {
    ctx.save();
    const translateX = target_coord.x;
    const translateY = target_coord.y;
    ctx.translate(translateX, translateY);
    ctx.rotate((angle_v2 * Math.PI) / 180);
    ctx.translate(-translateX, -translateY);
    ctx.drawImage(
      perspectived_canvas,
      0,
      0,
      perspectived_canvas.width,
      perspectived_canvas.height,
      target_coord.x,
      target_coord.y,
      target_coord.width,
      target_coord.height,
    );
  } else {
    ctx.save();
    const translateX = target_coord.x + target_coord.width / 2;
    const translateY = target_coord.y + target_coord.height / 2;
    ctx.translate(translateX, translateY);
    ctx.rotate((angle * Math.PI) / 180);
    ctx.translate(-translateX, -translateY);
    ctx.drawImage(
      perspectived_canvas,
      0,
      0,
      perspectived_canvas.width,
      perspectived_canvas.height,
      target_coord.x,
      target_coord.y,
      target_coord.width,
      target_coord.height,
    );
  }
  ctx.restore();
  if (mask_img) {
    ctx.globalCompositeOperation = 'destination-in';
    ctx.drawImage(
      mask_img,
      0,
      0,
      mask_img.width,
      mask_img.height,
      0,
      0,
      result_canvas.width,
      result_canvas.height,
    );
  }
  return result_canvas;
}

function newEnCylinderizeSize({ width, height, top = 0, left = 0 }, { up_side, down_side }) {
  const _width = (width / Math.PI) * 2;
  if (up_side < 0) {
    top -= Math.abs(up_side);
  }
  height += Math.abs(up_side);
  // if (down_side > 0) {
  height += Math.abs(down_side);
  // }
  return {
    width: _width,
    height,
    top,
    left: left + (width - _width) / 2,
  };
}
function newEnCylinderizeSize2({ width, height, top = 0, left = 0 }) {
  const _width = (width / Math.PI) * 2;
  return {
    width: _width,
    height,
  };
}

function toDegrees(radians) {
  const pi = Math.PI;
  return radians * (180 / pi);
}

function ffff(x, r) {
  const ax = x >= 0 && x <= 0.5 ? -x : x - 1;
  return 2 * Math.PI * r * (toDegrees(Math.acos((ax + r) / r)) / 360);
}
function makeX(x, src, dest) {
  const de_x = x / dest.width;
  if (de_x >= 0 && de_x <= 0.5) {
    return ffff(de_x, 0.5) * (0.5 / ffff(0.5, 0.5)) * src.width;
  } else if (de_x > 0.5 && de_x <= 1) {
    return (ffff(0.5, 0.5) * 2 - ffff(de_x, 0.5)) * (1 / (2 * ffff(0.5, 0.5))) * src.width;
  }
}
function cccc(w, h, x) {
  return Math.sqrt((1 - Math.pow(x, 2) / Math.pow(w, 2)) * Math.pow(h, 2));
}

function makeY(x, y, src_c, dest_c, up_side, down_side) {
  let start_y = 0;
  if (up_side > 0) {
    start_y += up_side - cccc(dest_c.width / 2, up_side, x - dest_c.width / 2);
  } else {
    start_y += cccc(dest_c.width / 2, up_side, x - dest_c.width / 2);
  }

  let end_y = dest_c.height;
  if (down_side < 0) {
    end_y = end_y + down_side + cccc(dest_c.width / 2, down_side, x - dest_c.width / 2);
  } else {
    end_y -= cccc(dest_c.width / 2, down_side, x - dest_c.width / 2);
  }

  const y_length = end_y - start_y;
  const ratio_y = (y - start_y) / y_length;
  if (y >= start_y && y <= end_y) {
    return ratio_y * src_c.height;
  }
}

function newMakeCircleWarpedCanvas(src_c, { up_side, down_side }, is_cylinder2) {
  up_side = up_side * src_c.width;
  down_side = down_side * src_c.width;
  return go(
    src_c,
    (src_c) => {
      const dest_size = is_cylinder2
        ? newEnCylinderizeSize2(src_c)
        : newEnCylinderizeSize(src_c, { up_side, down_side });
      return [src_c, createCanvasElement({ width: dest_size.width, height: dest_size.height })];
    },
    ([src_c, dest_c]) => {
      const src_ctx = src_c.getContext('2d');
      const dest_ctx = dest_c.getContext('2d');
      const src_image_data = src_ctx.getImageData(0, 0, src_c.width, src_c.height).data;
      const img_data = dest_ctx.getImageData(0, 0, dest_c.width, dest_c.height);
      const dest_image_data = img_data.data;
      for (let y = 0; y < dest_c.height; y++) {
        for (let x = 0; x < dest_c.width; x++) {
          const idx = (x + y * dest_c.width) * 4;
          mappingBilinearInterpolation(
            src_image_data,
            dest_image_data,
            makeX(x, src_c, dest_c),
            makeY(x, y, src_c, dest_c, up_side, down_side),
            src_c.width,
            idx,
          );
        }
      }
      dest_ctx.putImageData(img_data, 0, 0);
      return dest_c;
    },
  );
}
