import { $find } from 'fxdom/es';
import { go } from 'fxjs/es';
import { ComponentsInputF } from '../../../../Components/Input/F/Function/module/ComponentsInputF.js';
import { OMPSearchF } from './module/OMPSearchF.js';

/**
 * 검색 페이지 진입 시 입력창에 auto focus
 * @param {HTMLElement} frame_el
 * @return {void}
 */
export const setAutoFocusToSearchInput = (frame_el) => {
  const scroll_y = OMPSearchF.getScrollYPosition(frame_el);
  // scroll이 20px 이하일 때만 focus
  // 0으로 잡으면 뒤로가기 할 때마다 맨 위로 올라가서 어림잡아 20으로 설정
  if (scroll_y < 20) {
    go(frame_el, $find('.omp-atom__input'), ComponentsInputF.focusToInput);
  }
};

export const getScrollYPosition = () => {
  return window.scrollY || document.documentElement.scrollTop;
};
