import { appendL, defaultTo, go, head, isNil, join, mapL } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { PathEditorNS } from '@marpple/sticker-editor';

export const handleRightPanelAddLButtonClick =
  ({ tab_el }) =>
  () => {
    const cutting_line_editor = tab_el.__mp_cutting_line_editor;
    if (!cutting_line_editor) {
      return;
    }

    const active_command = cutting_line_editor.getActiveCuttingLinePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    const next_command = cutting_line_editor.getNextCuttingLinePathCommand(active_command);
    if (next_command == null) {
      return;
    }
    const next_command_target_point = next_command.getTargetPoint();

    cutting_line_editor.insertCuttingLinePathEditorBefore({
      ref_command: next_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const command_l = new PathEditorNS.ModelNS.CommandL({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: (active_command_target_point.x + next_command_target_point.x) / 2,
            y: (active_command_target_point.y + next_command_target_point.y) / 2,
          }),
        });
        const command_l_controller = new PathEditorNS.ControllerNS.CommandLController({
          svg_el,
          command_l,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const command_l_view = new PathEditorNS.ViewNS.CommandLView({
          command_l_controller,
          target_point_view,
        });
        return {
          model: command_l,
          view: command_l_view,
          point_el_list: [target_point_view.getEl()],
          line_el_list: [],
        };
      },
    });
  };

export const handleRightPanelAddCButtonClick =
  ({ tab_el }) =>
  () => {
    const cutting_line_editor = tab_el.__mp_cutting_line_editor;
    if (!cutting_line_editor) {
      return;
    }

    const active_command = cutting_line_editor.getActiveCuttingLinePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    const next_command = cutting_line_editor.getNextCuttingLinePathCommand(active_command);
    if (next_command == null) {
      return;
    }
    const next_command_target_point = next_command.getTargetPoint();

    cutting_line_editor.insertCuttingLinePathEditorBefore({
      ref_command: next_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const target_point_x = (active_command_target_point.x + next_command_target_point.x) / 2;
        const target_point_y = (active_command_target_point.y + next_command_target_point.y) / 2;
        const control_point1_x = (active_command_target_point.x * 2 + target_point_x) / 3;
        const control_point1_y =
          active_command_target_point.y < target_point_y
            ? active_command_target_point.y - Math.abs(active_command_target_point.y - target_point_y) / 2
            : active_command_target_point.y + Math.abs(active_command_target_point.y - target_point_y) / 2;
        const control_point2_x = (active_command_target_point.x + target_point_x * 2) / 3;
        const control_point2_y =
          active_command_target_point.y < target_point_y
            ? target_point_y + Math.abs(active_command_target_point.y - target_point_y) / 2
            : target_point_y - Math.abs(active_command_target_point.y - target_point_y) / 2;
        const command_c = new PathEditorNS.ModelNS.CommandC({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: target_point_x,
            y: target_point_y,
          }),
          control_point1: new PathEditorNS.ModelNS.Point({
            x: control_point1_x,
            y: control_point1_y,
          }),
          control_point2: new PathEditorNS.ModelNS.Point({
            x: control_point2_x,
            y: control_point2_y,
          }),
        });
        const command_c_controller = new PathEditorNS.ControllerNS.CommandCController({
          svg_el,
          command_c,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point1_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point2_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_line1_view = new PathEditorNS.ViewNS.LineView({
          document,
        });
        const control_line2_view = new PathEditorNS.ViewNS.LineView({
          document,
        });
        const command_c_view = new PathEditorNS.ViewNS.CommandCView({
          command_c_controller,
          target_point_view,
          control_point1_view,
          control_point2_view,
          control_line1_view,
          control_line2_view,
        });
        return {
          model: command_c,
          view: command_c_view,
          point_el_list: [
            control_point1_view.getEl(),
            control_point2_view.getEl(),
            target_point_view.getEl(),
          ],
          line_el_list: [control_line1_view.getEl(), control_line2_view.getEl()],
        };
      },
    });
  };

export const handleRightPanelRemoveButtonClick =
  ({ tab_el }) =>
  () => {
    const cutting_line_editor = tab_el.__mp_cutting_line_editor;
    if (!cutting_line_editor) {
      return;
    }

    const active_command = cutting_line_editor.getActiveCuttingLinePathCommand();
    if (active_command == null) {
      return;
    }
    cutting_line_editor.removeCuttingLinePathCommand(active_command);
  };

export const handleRightPanelChangeToLButtonClick =
  ({ tab_el }) =>
  () => {
    const cutting_line_editor = tab_el.__mp_cutting_line_editor;
    if (!cutting_line_editor) {
      return;
    }

    const active_command = cutting_line_editor.getActiveCuttingLinePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    cutting_line_editor.replaceCuttingLinePathEditorCommand({
      ref_command: active_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const command_l = new PathEditorNS.ModelNS.CommandL({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: active_command_target_point.x,
            y: active_command_target_point.y,
          }),
        });
        const command_l_controller = new PathEditorNS.ControllerNS.CommandLController({
          svg_el,
          command_l,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const command_l_view = new PathEditorNS.ViewNS.CommandLView({
          command_l_controller,
          target_point_view,
        });
        return {
          model: command_l,
          view: command_l_view,
          point_el_list: [target_point_view.getEl()],
          line_el_list: [],
        };
      },
    });
  };

export const handleRightPanelChangeToCButtonClick =
  ({ tab_el }) =>
  () => {
    const cutting_line_editor = tab_el.__mp_cutting_line_editor;
    if (!cutting_line_editor) {
      return;
    }

    const active_command = cutting_line_editor.getActiveCuttingLinePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    const prev_command = cutting_line_editor.getPrevCuttingLinePathCommand(active_command);
    if (prev_command == null) {
      return;
    }
    const prev_command_target_point = prev_command.getTargetPoint();

    cutting_line_editor.replaceCuttingLinePathEditorCommand({
      ref_command: active_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const current_point_x = prev_command_target_point.x;
        const current_point_y = prev_command_target_point.y;
        const target_point_x = active_command_target_point.x;
        const target_point_y = active_command_target_point.y;
        const control_point1_x = (current_point_x * 2 + target_point_x) / 3;
        const control_point1_y =
          current_point_y < target_point_y
            ? current_point_y - Math.abs(current_point_y - target_point_y) / 2
            : current_point_y + Math.abs(current_point_y - target_point_y) / 2;
        const control_point2_x = (current_point_x + target_point_x * 2) / 3;
        const control_point2_y =
          current_point_y < target_point_y
            ? target_point_y + Math.abs(current_point_y - target_point_y) / 2
            : target_point_y - Math.abs(current_point_y - target_point_y) / 2;
        const command_c = new PathEditorNS.ModelNS.CommandC({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: target_point_x,
            y: target_point_y,
          }),
          control_point1: new PathEditorNS.ModelNS.Point({
            x: control_point1_x,
            y: control_point1_y,
          }),
          control_point2: new PathEditorNS.ModelNS.Point({
            x: control_point2_x,
            y: control_point2_y,
          }),
        });
        const command_c_controller = new PathEditorNS.ControllerNS.CommandCController({
          svg_el,
          command_c,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point1_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point2_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_line1_view = new PathEditorNS.ViewNS.LineView({
          document,
        });
        const control_line2_view = new PathEditorNS.ViewNS.LineView({
          document,
        });
        const command_c_view = new PathEditorNS.ViewNS.CommandCView({
          command_c_controller,
          target_point_view,
          control_point1_view,
          control_point2_view,
          control_line1_view,
          control_line2_view,
        });
        return {
          model: command_c,
          view: command_c_view,
          point_el_list: [
            control_point1_view.getEl(),
            control_point2_view.getEl(),
            target_point_view.getEl(),
          ],
          line_el_list: [control_line1_view.getEl(), control_line2_view.getEl()],
        };
      },
    });
  };

export const handleRightPanelResetButtonClick =
  ({ tab_el }) =>
  () => {
    const cutting_line_editor = tab_el.__mp_cutting_line_editor;
    if (!cutting_line_editor) {
      return;
    }

    const editor_container_bbox = go(
      tab_el,
      $findAll(`.left_container .editor_container`),
      mapL((el) => el.getBoundingClientRect()),
      head,
    );
    if (isNil(editor_container_bbox)) {
      return;
    }
    const {
      x: editor_container_x,
      y: editor_container_y,
      width: editor_container_width,
      height: editor_container_height,
    } = editor_container_bbox;
    const top_menu_container_height = go(
      tab_el,
      $findAll(`.left_container .top_menu_container`),
      mapL((el) => el.getBoundingClientRect().height),
      head,
      defaultTo(0),
    );

    const editor_visible_width = editor_container_width;
    const editor_visible_height = editor_container_height - top_menu_container_height;
    const editor_visible_x = editor_container_x;
    const editor_visible_y = editor_container_y + top_menu_container_height;

    const width = Math.round(editor_visible_width / 3);
    const height = Math.round(editor_visible_height / 3);
    const x = editor_visible_x + (editor_visible_width - width) / 2;
    const y = editor_visible_y + (editor_visible_height - height) / 2;

    const d = go(
      [
        ['M', x, y],
        ['L', x + width, y],
        ['L', x + width, y + height],
        ['L', x, y + height],
      ],
      mapL(([command, x, y]) => {
        const view_port_point = cutting_line_editor.toViewPortPoint({ x, y });
        const user_point = cutting_line_editor.toUserPoint({
          x: view_port_point.x,
          y: view_port_point.y,
        });
        return `${command} ${user_point.x} ${user_point.y}`;
      }),
      appendL('Z'),
      join(' '),
    );

    cutting_line_editor.endEditCuttingLinePath();
    cutting_line_editor.setCuttingLinePathData(d);
    cutting_line_editor.startEditCuttingLinePath();
  };
