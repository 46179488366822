import { html } from 'fxjs/es';
export const decoration = ({ loc_optimization_icon_html }) => html`
  <div class="decoration_menu super_menu">
    <div class="main_icon circle_icon marpple_icon">
      <div class="icon">
        <div class="cross">
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="text">${T('maker::Design')}</div>
    </div>
    <div class="main_icon circle_icon for_line add_sticker_line">
      <div class="icon"></div>
      <div class="text">${T('maker::Character')}</div>
    </div>
    <div class="main_icon circle_icon add_sticker_custom">
      <div class="icon"></div>
      <div class="text">${T('maker::Character')}</div>
    </div>
    <ul class="body">
      ${loc_optimization_icon_html}
      <li class="add_sticker2 circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Design')}</div>
      </li>
      <li class="add_text circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Text')}</div>
      </li>
      <li class="add_img circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Image')}</div>
        <input
          type="file"
          style="display: none;"
          accept="image/jpeg,image/png,image/pjpeg,image/tiff,application/postscript"
        />
      </li>
      <li class="open_cv_background_frame circle_icon">
        <div class="icon"></div>
        <div class="text">${T('maker_background_color_editor::배경 색상')}</div>
      </li>
    </ul>
  </div>
`;
