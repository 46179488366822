import { find, flat, go, html, sel, strMap } from 'fxjs/es';
import { isSameColorCode } from '../../F/Function/event.js';
export const colorsWrapper = ({ colors, color_code }) => {
  const flat_colors = flat(colors);
  if (!find((fc) => isSameColorCode(fc.color_code, color_code))(flat_colors)) {
    color_code = go(find((v) => v.is_etc)(flat_colors), sel('color_code'));
  }
  return html`
    <div class="colors">
      ${go(
        colors,
        strMap(
          (colors) => html`
            <div class="row">
              ${strMap((color) =>
                !color.name
                  ? html` <div class="color is_not_color"></div> `
                  : html`
                      <div
                        data-color_name="${color.name[T.lang]}"
                        data-color_code="${color.color_code}"
                        data-is_etc="${color.is_etc}"
                        class="is_cursor_pointer color ${isSameColorCode(color_code, color.color_code)
                          ? 'active'
                          : ''}"
                      >
                        <span
                          style="${color.thumbnail.url
                            ? `background-image:url(${color.thumbnail.url});`
                            : `background-color:${color.thumbnail.color}`}"
                        ></span>
                      </div>
                    `,
              )(colors)}
            </div>
          `,
        ),
      )}
    </div>
  `;
};
