import paper from 'paper';

import { go, find, maxBy, sel, head } from 'fxjs/es';
import { makeCanvasByUrl } from '../../../../Canvas/S/util.js';
import { CanvasS } from '../../../../Canvas/S/Function/module/CanvasS.js';
import axios from 'axios';
import { getSvgEl, getSvgString } from './util.js';

/**
 * @typedef {{is_OK: true, data: any, reason: null}} SuccessReturn
 * @typedef {{is_OK: false, data: null, reason: string}} FailReturn
 * @typedef {SuccessReturn | FailReturn} SvgUtilReturn
 * */

/**
 * @define 성공 결과 리턴
 * @return {SuccessReturn}
 * */
function returnSuccess({ data }) {
  return { is_OK: true, data, reason: null };
}

/**
 * @define 실패 결과 리턴
 * @return {FailReturn}
 * */
function returnFail({ reason = '' }) {
  return { is_OK: false, data: null, reason };
}

async function isBiggerHole({ printable_url, ring_position }) {
  // if(svg_product_page.meta.is_bigger_hole_2023_01_04 === false)
  const canvas = await makeCanvasByUrl(printable_url);
  const hole_cm = 0.4;
  const ctx = canvas.getContext('2d');
  const x = ring_position.x * canvas.width;
  const y = ring_position.y * canvas.height;
  ctx.globalCompositeOperation = 'destination-in';
  ctx.beginPath();
  ctx.arc(x, y, (hole_cm / 2 / 2.54) * 300, 0, 2 * Math.PI);
  ctx.fill();
  return CanvasS.isEmptyInCanvas(ctx.getImageData(0, 0, canvas.width, canvas.height));
}

async function makeBiggerHole({ printable_url, ring_position }) {
  const canvas = await makeCanvasByUrl(printable_url);
  const hole_cm = 0.3;
  const ctx = canvas.getContext('2d');
  const x = ring_position.x * canvas.width;
  const y = ring_position.y * canvas.height;
  ctx.globalCompositeOperation = 'destination-out';
  ctx.beginPath();
  ctx.arc(x, y, (hole_cm / 2 / 2.54) * 300, 0, 2 * Math.PI);
  ctx.fill();
  return canvas;
}

function handleFileUpload(url) {
  const blobBin = atob(url.split(',')[1]);
  const array = [];
  for (let i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }
  const file = new Blob([new Uint8Array(array)], { type: 'image/png' });
  const formData = new FormData();
  formData.append('file', file, 'vector_small_hole_verified.png');
  // axios를 사용하여 파일 업로드 요청 보내기
  return go(
    axios.post('/@fileUpload/file_only_original', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // 파일 업로드 시에는 반드시 'multipart/form-data'로 설정해야 합니다.
      },
    }),
    sel('data'),
    head,
  );
}

/**
 * @define svg 구멍에 디자인 있는지 검증
 * @param {string} cutting_line_url 검증할 svg element 혹은 svg string
 * @param {string} printable_url 검증할 svg url
 * @param {number} svg_product_page_id svg_product_pages.id
 * @return {SvgUtilReturn} {data: 검증된 svg 요소}
 * */
export async function verifySmallHoleSvg({ cutting_line_url, printable_url, svg_product_page_id }) {
  try {
    const _svg = await getSvgString({ svg_url: cutting_line_url });
    if (_svg == null) {
      throw new Error(`Cannot retrieve SVG`);
    }

    const { data: ring_position } = await extractRingRelativePositionSvg({ svg_url: cutting_line_url });
    if (await isBiggerHole({ printable_url, ring_position })) {
      await axios.post('/@api/svg_product_page/verified_url', {
        id: svg_product_page_id,
        verified_printable_url: printable_url,
        is_legacy_no_hole: false,
      });
      return returnSuccess({ data: printable_url });
    } else {
      const canvas = await makeBiggerHole({ printable_url, ring_position });
      const { url } = await handleFileUpload(canvas.toDataURL());
      await axios.post('/@api/svg_product_page/verified_url', {
        id: svg_product_page_id,
        verified_printable_url: url,
        is_legacy_no_hole: false,
      });
      return returnSuccess({ data: url });
    }
  } catch (err) {
    return returnFail({ reason: err.message });
  }
}

/**
 * @define svg 고리 상대적 위치 추출
 *         - 마플의 키링 고리에 특화 되어 있음 (일반적이지 않으므로 다른 용도로 사용시에는 주의 요함)
 *         - 마플의 키링 고리의 면적에 의존하므로 다른 고리 면적 svg 에는 대응되지 않음 (~2024/01 마플 고리 크기는 동일)
 * @param {SVGElement | string | undefined} svg 검증할 svg element 혹은 svg string
 * @param {string | undefined} svg_url 검증할 svg url
 * @param {number | undefined} product_color_id ups.product_color_id
 * @return {SvgUtilReturn} {data: 고리 상대적 위치}
 * */
async function extractRingRelativePositionSvg({ svg, svg_url, product_color_id }) {
  try {
    const _svg = await getSvgString({ svg, svg_url, product_color_id });
    if (_svg == null) {
      throw new Error(`Cannot retrieve SVG`);
    }
    const ring_position = await extractRingPosition({ svg: _svg });

    return returnSuccess({ data: ring_position });
  } catch (err) {
    console.error(err);
    return returnFail({ reason: err.message });
  }
}

async function extractRingPosition({ svg }) {
  const paper = await importSvgWithPaperJS({ svg });
  const paths = paper.project.getItems({
    type: 'path',
  });

  const view_box = go(
    paper.project.getItems({
      class: paper.Shape,
      shape: 'rectangle',
    }),
    maxBy((p) => p.area),
  );

  if (view_box == null) {
    throw new Error(`뷰박스 요소를 찾지 못했습니다.`);
  }

  const ring_path = go(
    paths,
    find((p) => {
      const is_segment_under_8 = p.segments.length <= 8;
      const is_area_bet_50_60 = p.area > 50 && p.area < 60;

      return is_segment_under_8 && is_area_bet_50_60;
    }),
  );

  if (ring_path == null) throw new Error(`링 요소를 찾지 못했습니다.`);

  const { width, height } = view_box.getBounds();
  const { centerX, centerY } = ring_path.getBounds();

  // 고리 요소의 상대적 x, y 위치
  return {
    x: centerX / width,
    y: centerY / height,
  };
}

function importSvgWithPaperJS({ svg }) {
  return new Promise((res, rej) => {
    paper.setup();
    // paper.settings.applyMatrix = false;
    // paper.view.update();

    const svg_el = getSvgEl({ svg });

    paper.project.activeLayer.importSVG(svg_el, {
      onLoad: (imported_items) => {
        const bounds = imported_items.bounds;
        paper.view.viewSize = new paper.Size(bounds.width, bounds.height);
        paper.view.center = imported_items.position;
        res(paper);
      },
      onError: (err) => {
        rej(err);
      },
    });
  });
}
