import $dataStr from 'fxdom/es/dataStr.js';
import { html, strMap } from 'fxjs/es';
import { OMPCoreCellTmplS } from '../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { OMPReviewConstantS } from '../Constant/module/OMPReviewConstantS.js';

export const noPhotoReviewsTmpl = () => {
  return html`<div class="omp-review__no-photo">${T('product_detail::no_photo_reviews')}</div>`;
};

export const reviewPhotoListTmpl = (reviews, is_mobile) => {
  return html` <div class="omp-review__contents infi_container" data-type="is_photo">
    ${reviews.length === 0
      ? noPhotoReviewsTmpl()
      : strMap(
          (review) => html`
            <div class="omp-review__content-wrapper infi_item">
              ${is_mobile
                ? OMPCoreCellTmplS.verticalReviewMoTmpl(review)
                : OMPCoreCellTmplS.verticalReviewTmpl(review)}
            </div>
          `,
          reviews,
        )}
  </div>`;
};

export const reviewInfinityCommonListTmpl = (reviews, is_mobile) => {
  return html` <div class="omp-review__contents infi_container" data-type="is_all">
    ${strMap((review) => {
      return reviewInfinityCommonItemTmpl(review, is_mobile);
    }, reviews)}
  </div>`;
};

export const reviewInfinityCommonItemTmpl = (review, is_mobile) => {
  return html`
    <div class="omp-review__content-wrapper infi_item">
      ${is_mobile
        ? OMPCoreCellTmplS.verticalReviewTmpl(review)
        : OMPCoreCellTmplS.horizontalReviewTmpl(review)}
    </div>
  `;
};

export const reviewCommonListTmpl = (reviews, is_mobile) => {
  return html` <div class="omp-review__contents" data-type="is_all">
    ${strMap(
      (review) => html`
        <div class="omp-review__content-wrapper">
          ${is_mobile
            ? OMPCoreCellTmplS.verticalReviewTmpl(review)
            : OMPCoreCellTmplS.horizontalReviewTmpl(review)}
        </div>
      `,
      reviews,
    )}
  </div>`;
};

/**
 * @param {object} param
 * @param {boolean?} param.is_mobile
 * @param {object[]} param.reviews
 * @param {number | { count: number }} param.review_total
 * @param {{offset: number?, limit: number?, is_all: boolean|'true'}} param.query
 * @param {object?} param.data `dataset`에 추가로 넣을 데이터
 */
export const reviewListTmpl = ({ is_mobile, reviews, review_total, query, data }) => {
  const next = query.offset
    ? Number(query.offset) < review_total
    : review_total > OMPReviewConstantS.REVIEW_LIST_LIMIT;

  return html` <div
    class="omp-review__contents-wrapper"
    data-type="${query.is_all === true || query.is_all === 'true' ? 'is_all' : 'is_photo'}"
    data-next="${next}"
    data-total="${review_total?.count || review_total}"
    data-fx-json="${$dataStr(data || {})}"
  >
    ${query.is_all === true || query.is_all === 'true'
      ? reviewInfinityCommonListTmpl(reviews, is_mobile)
      : reviewPhotoListTmpl(reviews, is_mobile)}
  </div>`;
};
