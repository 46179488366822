import { sel, strMap } from 'fxjs/es';
import { makePaginationHtml } from '../../../Pagination/S/tmpl.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';

const replaceSnsUrl = (url) => {
  try {
    if (!url || !url.length) return '';
    if (url.indexOf('instagram') != -1) return 'instagram';
    if (url.indexOf('youtu') != -1) return 'youtube';
    if (url.indexOf('facebook') != -1) return 'facebook';
    url = url.replace('http://', '');
    url = url.replace('https://', '');
    url = url.replace('www.', '');
    return url.substring(0, 20);
  } catch (_e) {
    return '';
  }
};
const makeSelectedOption = (key, val) => (key == val ? 'selected' : '');
const makeTypeHtml = (type) => {
  if (!type) return '';
  if (type == 'pass') return '통과';
  if (type == 'defer') return '후보';
  if (type == 'fail') return '탈락';
  if (type == 'ing') return '심사중';
  if (type == 'duplicate') return '중복';
  if (type == 'pass_mail_fail') return '합격메일실패';
};

export const makeAdvanceReservationListHtml = (
  advance_reservations,
  query,
  counts,
  pagination_state,
) => legacyHtml`
  <div id="advance_reservations">
    <div class="header" id="filter">
      <div class="title">
        <h1>마플샵 사전신청</h1>
      </div>
      <div class="search_bar">
        <form action="/advance_reservation" method="get" id="search_bar">
          <div class="sd">
            <div class="s_col search">
              <input
                type="text"
                name="search"
                placeholder="샵 주소,셀러,번호,이메일,심사평"
                value="${query.search}"
              />
            </div>
            <div class="s_col title"><span>심사결과</span></div>
            <div class="s_col type">
              <label>
                <select name="type">
                  <option value="">전체</option>
                  <option value="ing" ${makeSelectedOption(query.type, 'ing')}>심사중</option>
                  <option value="pass" ${makeSelectedOption(query.type, 'pass')}>통과</option>
                  <option value="defer" ${makeSelectedOption(query.type, 'defer')}>후보</option>
                  <option value="fail" ${makeSelectedOption(query.type, 'fail')}>탈락</option>
                  <option value="duplicate" ${makeSelectedOption(query.type, 'duplicate')}>중복</option>
                  <option value="pass_mail_fail" ${makeSelectedOption(query.type, 'pass_mail_fail')}>
                    합격메일실패
                  </option>
                </select>
              </label>
            </div>
            <div class="s_col title"><span>정렬</span></div>
            <div class="s_col sort">
              <label>
                <select name="sort">
                  <option value="desc" ${makeSelectedOption(query.sort, 'desc')}>최신순</option>
                  <option value="asc" ${makeSelectedOption(query.sort, 'asc')}>오래된순</option>
                </select>
              </label>
            </div>
          </div>
          <div class="search_result search_btn">
            <a href="/advance_reservation"><button class="init" type="button">초기화</button></a>
            <button class="go" type="submit">검색</button>
            <div class="mail_btns">
              <!--              <button class="btn_all_test" type="button">테스트 메일보내기</button>-->
              <button class="btn_all_pass_mail" type="button">합격자 메일보내기</button>
              <button class="btn_all_fail_mail" type="button">미선정자 메일보내기</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="body">
      <div class="counts">
        ${strMap(
          (c) => legacyHtml`
            <div class="count">
              <div class="name">${makeTypeHtml(c.type)}</div>
              <span>:</span>
              <div class="total">${UtilS.commify(c.count)}</div>
            </div>
          `,
          counts,
        )}
      </div>
      <div class="advance_reservation_list" _sel="advance_reservations">
        <table>
          <thead>
            <tr>
              <th>수정</th>
              <th>ID</th>
              <th>심사</th>
              <th>셀러</th>
              <th>신청날짜</th>
              <th>주소</th>
              <th>EMAIL</th>
              <th>전화번호</th>
              <th>웹사이트</th>
              <th>SNS</th>
              <th>소개</th>
              <th>첨부파일</th>
              <th>심사평</th>
            </tr>
          </thead>
          <tbody>
            ${strMap(makeAdvanceReservationTrHtml, advance_reservations)}
          </tbody>
        </table>
      </div>
      ${makePaginationHtml(pagination_state)}
    </div>
  </div>
`;

export const makeAdvanceReservationTrHtml = (advance_reservation) => legacyHtml`
  <tr class="advance_reservation_item" _sel="./(#${advance_reservation.id})">
    <td class="patching">수정</td>
    <td class="id">${advance_reservation.id}</td>
    <td class="type" data-type="${advance_reservation.type}">${makeTypeHtml(advance_reservation.type)}</td>
    <td class="seller_name">${UtilS.escape(advance_reservation.seller_name)}</td>
    <td class="created_at">${moment(advance_reservation.created_at).format('YYYY/MM/DD')}</td>
    <td class="store_url">${UtilS.escape(advance_reservation.name)}</td>
    <td class="email" data-is_email="${advance_reservation.is_mail_fail}">${UtilS.escape(
  advance_reservation.email,
)}</td>
    <td class="mobile">${advance_reservation.mobile}</td>
    <td class="sample_url">
      <a href="${UtilS.makeUrl(advance_reservation.sample_url)}" target="_blank">${replaceSnsUrl(
  UtilS.escape(advance_reservation.sample_url),
)}</a>
    </td>
    <td class="sns_url">
      <a href="${UtilS.makeUrl(UtilS.escape(advance_reservation.sns_url))}" target="_blank">${replaceSnsUrl(
  UtilS.escape(advance_reservation.sns_url),
)}</a>
    </td>
    <td class="description">${UtilS.linkify(advance_reservation.description).replace(/<br>/g, '')}</td>
    <td class="files" data-len="${sel('length', advance_reservation.files)}">
      ${
        sel('length', advance_reservation.files)
          ? legacyHtml`
              <div class="hover_wrap">
                <span>첨부파일 ${sel('length', advance_reservation.files)}개</span>
                <div class="files_wrap">
                  <ul>
                    ${strMap(
                      (file) => legacyHtml`
                        <li>
                          <a href="${file.url}" target="_blank">${file.name || '이름없음'}</a>
                        </li>
                      `,
                      advance_reservation.files,
                    )}
                  </ul>
                </div>
              </div>
            `
          : ''
      }      
      </div>
    </td>
    <td class="memo">${UtilS.linkify(advance_reservation.memo).replace(/<br>/g, '')}</td>              
  </tr>
  `;

export const makeAdvanceReservationDetailHtml = (advance_reservation) => legacyHtml`
  <div id="advance_reservation_detail">
    <div class="header">
      <h2>신청서</h2>
    </div>
    <div class="id">
      <div class="title">ID</div>
      <div class="content">${advance_reservation.id}</div>
    </div>
    <div class="created_at">
      <div class="title">신청날짜</div>
      <div class="content">${moment(advance_reservation.created_at).format('YYYY/MM/DD')}</div>
    </div>
    <div class="seller_name">
      <div class="title">셀러이름</div>
      <div class="content">${UtilS.escape(advance_reservation.seller_name)}</div>
    </div>
    <div class="store_url">
      <div class="title">샵 주소</div>
      <div class="content">${UtilS.escape(advance_reservation.name)}</div>
    </div>
    <div class="type">
      <div class="title">심사결과 수정</div>
      <div class="content">
        <label>
          <select name="type">
            <option value="">심사 중</option>
            <option value="pass" ${makeSelectedOption(advance_reservation.type, 'pass')}>통과</option>
            <option value="defer" ${makeSelectedOption(advance_reservation.type, 'defer')}>후보</option>
            <option value="fail" ${makeSelectedOption(advance_reservation.type, 'fail')}>탈락</option>
            <option value="duplicate" ${makeSelectedOption(advance_reservation.type, 'duplicate')}>
              중복
            </option>
            <option value="pass_mail_fail" ${makeSelectedOption(advance_reservation.type, 'pass_mail_fail')}>
              합격메일실패
            </option>
          </select>
        </label>
      </div>
    </div>
    <div class="email">
      <div class="title">이메일</div>
      <div class="content" data-is_email="${advance_reservation.is_mail_fail}">
        ${UtilS.escape(advance_reservation.email)}${
  advance_reservation.is_mail_fail ? ' (메일 보내기 완료)' : ''
}
      </div>
    </div>
    <div class="admin_sns_url">
      <div class="title">대표 sns url 설정</div>
      <div class="content">
        ${
          advance_reservation.admin_sns_url
            ? legacyHtml`
              <a href="${UtilS.makeUrl(UtilS.escape(advance_reservation.admin_sns_url))}" target="_blank"
                >${UtilS.escape(advance_reservation.admin_sns_url || '')}</a
              >
            `
            : ''
        }
        <input
          type="text"
          name="admin_sns_url"
          value="${UtilS.escape(advance_reservation.admin_sns_url || '')}"
          placeholder="대표 sns url 설정"
        />
      </div>
    </div>
    <div class="mobile">
      <div class="title">전화번호</div>
      <div class="content">${advance_reservation.mobile}</div>
    </div>
    <div class="sample_url">
      <div class="title">웹사이트</div>
      <div class="content">
        ${
          advance_reservation.sample_url
            ? legacyHtml`
              <a href="${UtilS.makeUrl(advance_reservation.sample_url)}" target="_blank"
                >${UtilS.escape(advance_reservation.sample_url || '')}</a
              >
            `
            : ''
        }
        <input
          type="text"
          name="sample_url"
          value="${UtilS.escape(advance_reservation.sample_url || '')}"
          placeholder="웹사이트 주소"
        />
      </div>
    </div>
    <div class="sns_url">
      <div class="title">SNS</div>
      <div class="content">
        ${
          advance_reservation.sns_url
            ? legacyHtml`
              <a href="${UtilS.makeUrl(advance_reservation.sns_url)}" target="_blank"
                >${UtilS.escape(advance_reservation.sns_url || '')}</a
              >
            `
            : ''
        }
        <input
          type="text"
          name="sns_url"
          value="${UtilS.escape(advance_reservation.sns_url || '')}"
          placeholder="sns 주소"
        />
      </div>
    </div>
    <div class="description">
      <div class="title">소개</div>
      <div class="content">${UtilS.linkify(advance_reservation.description).replace(/<br>/g, '')}</div>
    </div>
    <div class="files">
      <div class="title">첨부파일</div>
      <div class="content">
        <div class="files_wrap">
          <ul>
            ${strMap(
              (file) =>
                legacyHtml` <li><a href="${file.url}" target="_blank">${file.name || '이름없음'}</a></li> `,
              advance_reservation.files,
            )}
          </ul>
        </div>
      </div>
    </div>
    <div class="memo">
      <div class="title">심사평</div>
      <div class="content">
        <textarea name="memo" placeholder="심사평을 작성해 주세요.">
${advance_reservation.memo || ''}</textarea
        >
      </div>
    </div>
    <div class="patch">
      <button type="button" class="btn_modify">수정</button>
      <button
        type="button"
        class="btn_send_mail"
        ${advance_reservation.type == 'pass' ? '' : `style="display: none;"`}
      >
        사전신청 승인 메일보내기
      </button>
    </div>
  </div>
`;
