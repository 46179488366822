import JsBarcode from 'jsbarcode';
import { createCanvas } from 'canvas';

/**
 *  @function Code128 바코드 생성
 *  @param {string} text 바코드 문자
 * */
export function createCode128Barcode(text) {
  const canvas = createCanvas();
  // Set the barcode options
  const options = {
    format: 'CODE128',
    width: 2,
    height: 80,
    displayValue: false,
    text,
  };
  // Generate the barcode on the canvas
  JsBarcode(canvas, text, options);
  return canvas;
}
