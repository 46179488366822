import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

export const makeReviewImgListHtml = ({ id, file }) => html`
  <div class="omp-review-write__photo-container" data-id="${id}">
    <div class="omp-review-write__photo">
      <img src="${file.url || ''}" alt="review photo" />
    </div>
    <div class="omp-review-write__photo-remove">${OMPCoreAtomTmplS.xIcon({ color: 'GY_80' })}</div>
  </div>
`;
