import { go, html, strMap } from 'fxjs/es';

/**
 * @param {{title: string, value: string, checked: boolean, icon: string}[]} options
 * @param {string} name
 * @param {boolean} is_mobile
 * @returns {string}
 */
export const selectDropdownTmpl = ({ options, name, is_mobile = false }) => {
  if (is_mobile) options = [...options].reverse();

  return html`<div class="omp-cell__dropdown" tabindex="0">
    ${go(
      options,
      strMap(
        ({ title, value, checked, icon }) =>
          html`<input
              type="radio"
              name="${name}"
              value="${value}"
              ${checked ? 'checked' : ''}
              id="${name}-${value}"
              tabindex="0"
              onchange="this.focus()"
            />
            <label for="${name}-${value}"><span class="icon">${icon}</span>${title}</label>`,
      ),
    )}
  </div>`;
};
