import { each, every, find, go, map, mapC } from 'fxjs/es';
import { $find, $findAll, $setCss } from 'fxdom/es';
import { BpOptionS } from '../../../../BpOption/S/Function/module/BpOptionS.js';
import { getBaseProductInMaker, getCurrentBpfInMaker } from '../../../../Maker/F/getSth.js';
import { getCurrentFcanvas, getCvDesigns } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { NewMakerCvObjectUtilF } from '../../../../NewMaker/CvObject/Util/F/Function/module/NewMakerCvObjectUtilF.js';
import { SVGEditorUploadImageF } from '../../../UploadImage/F/Function/module/SVGEditorUploadImageF.js';
import { VectorEditorConstantS } from '../../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import { NewMakerCvObjActionF } from '../../../../NewMaker/CvObjAction/F/Function/module/NewMakerCvObjActionF.js';
import { makeBpcfAvgSize } from '../../../../Maker/F/canvas_size.js';
import { makeFakeMpMakerSize } from '../../../../Maker/F/fake_mp_maker.js';
import { BpOptionKeyRingConstantS } from '../../../../BpOption/KeyRing/S/Constant/module/BpOptionKeyRingConstantS.js';
import { NewMakerBpOptionF } from '../../../../NewMaker/BpOption/F/Function/module/NewMakerBpOptionF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

function makeVectorEditorPreviewCvObj(bp_option_groups_el) {
  const option_names = go(
    bp_option_groups_el,
    $findAll('select'),
    map((select_el) => {
      return $find(`option[value="${select_el.value}"]`)(select_el);
    }),
    map((el) => el.dataset.dev_name?.toLowerCase().replace(/\s/g, '')),
  );
  const sf = getCurrentBpfInMaker().size_faces[0];
  const { url } =
    go(
      sf.sticker_previews,
      find((sp) =>
        every((on) => option_names.includes(on.toLowerCase().replace(/\s/g, '')))(sp.option_names),
      ),
    ) || {};
  if (url) {
    const print_area = sf.print.px;
    const fcanvas = getCurrentFcanvas();
    return NewMakerCvObjectUtilF.addLockedAnyImageToCanvas({
      location: print_area,
      fcanvas,
      image: { url },
    });
  }
}

export const vectorEditorPreviewInit = async (bp_option_groups_el) => {
  const selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);
  if (getBaseProductInMaker().maker_type == VectorEditorConstantS.KEYRING_EDITOR) {
    const canvas = getCurrentFcanvas().lowerCanvasEl;
    $setCss({ visibility: 'hidden' }, canvas);
    box().maker.product_color._.selected_option_group = selected_option_group;
    await NewMakerCvObjActionF.render({ bp_option_ids: selected_option_group.bp_option_ids });
    G.mp.maker.bpcf_size = makeBpcfAvgSize();
    if (
      UtilF.isLegacyMobile() &&
      box().maker.product_color._.selected_option_group.bp_option_ids.includes(
        BpOptionKeyRingConstantS.KEYRING_NO_GORI_BP_OPTION_ID,
      )
    ) {
      G.mp.maker.bpcf_size = {
        top: 207.12209302325581,
        left: 132.99418604651163,
        height: 239.38953488372093,
        bottom: 220.63953488372093,
        width: 110.75581395348837,
      };
    }
    makeFakeMpMakerSize();
    G.mp.maker.zoom.canvas_container_display_mode(true);
    await BpOptionF.makeSelectedOptionPriceC2323({
      base_products_bp_option_groups:
        box().maker.product_color._.base_product._.base_products_bp_option_groups,
      bp_option_groups_el,
      selected_option_group,
    });
    $setCss({ visibility: 'visible' }, canvas);
    return;
  }
  if (bp_option_groups_el.dataset.is_designed !== 'true') {
    await stickerChangePreview(bp_option_groups_el);
  }

  if (getBaseProductInMaker().maker_type == VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR) {
    if (bp_option_groups_el.dataset.is_designed !== 'true') {
      const preview_images = BpOptionS.selectedOptionGroup.getPreviewImagesInMaker(
        box().maker.product_color._.base_product._.base_products_bp_option_groups,
        selected_option_group,
      );
      if (preview_images?.length) {
        await NewMakerBpOptionF.addPreviewImage(preview_images);
      }
    }
    G.mp.maker.bpcf_size = makeBpcfAvgSize();
    makeFakeMpMakerSize();
    G.mp.maker.zoom.canvas_container_display_mode(true);
    await BpOptionF.makeSelectedOptionPriceC2323({
      base_products_bp_option_groups:
        box().maker.product_color._.base_product._.base_products_bp_option_groups,
      bp_option_groups_el,
      selected_option_group,
    });
  }
};
export const stickerChangePreview = async (bp_option_groups_el) => {
  const fcanvas = getCurrentFcanvas();
  const designs = getCvDesigns(fcanvas._objects);
  const cv_image = await makeVectorEditorPreviewCvObj(bp_option_groups_el);
  if (!cv_image) return;
  go(
    designs,
    each((cv_obj) => fcanvas.remove(cv_obj)),
  );
  fcanvas.add(cv_image);
  await G.mp.maker.modified();
};

export async function createSVGMaterialForPC({ svg_product_materials, selected_option_group }) {
  return {
    svg_product_materials: svg_product_materials?.length
      ? await go(
          svg_product_materials,
          mapC(async (material) => {
            const { process_time, original_svg_file_name } = material;
            return {
              process_time,
              original_svg_file_name,
              ...(await SVGEditorUploadImageF.uploadSvgProductMaterialFiles(material)),
            };
          }),
        )
      : undefined,
    selected_option_group: selected_option_group?.bp_option_ids?.length ? selected_option_group : undefined,
  };
}
