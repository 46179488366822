import axios from 'axios';
import { $addClass, $delegate, $find, $removeAttr, $removeClass, $setAttr, $setVal, $val } from 'fxdom/es';
import { go, object } from 'fxjs/es';
import { ConfigSentryF } from '../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPAuthConstantS } from '../../../S/Constant/module/OMPAuthConstantS.js';

export const catchError = async (e) => {
  $.don_loader_end();

  // MEMO: axios 에러
  if (e?.response?.data) {
    let error = {};
    if (typeof e?.response?.data === 'string') error = JSON.parse(e?.response?.data);
    else error = e?.response?.data;

    if (e?.response?.data?.title) {
      await OMPCoreUtilF.titleAlert(error);
    } else {
      await OMPCoreUtilF.alert({
        content: error?.message || TT('error::common::error_01'),
      });
    }
    return;
  }

  // MEMO: 프론트 에러
  if (e?.handle) {
    await OMPCoreUtilF.alert({
      content: e?.message || TT('error::common::error_01'),
    });
    return;
  }

  OMPCoreUtilF.alert({
    content: TT('error::common::error_01'),
  });
  ConfigSentryF.error(e);
};

export const events = (tab_el) => {
  return go(
    tab_el,
    $delegate('focusout', 'input[name="email"]', function (e) {
      $setVal(UtilS.lowerCase($val(e.currentTarget)), e.currentTarget);
    }),
    $delegate('input', 'input[name="email"]', (e) => {
      const email = $val(e.currentTarget).trim();
      const btn = $find('.omp-reset-password__button', e.delegateTarget);
      const disabled = btn.disabled;

      if (!UtilS.is_valid_email(email)) {
        if (!disabled) go(btn, $removeClass('enable'), $setAttr({ disabled: '' }));
        return;
      }

      if (disabled) {
        go(btn, $addClass('enable'), $removeAttr('disabled'));
      }
    }),
    $delegate('submit', '.omp-reset-password__form', async (e) => {
      e.originalEvent.preventDefault();
      try {
        await go(
          e.currentTarget,
          (el) => new FormData(el).entries(),
          object,
          async ({ email }) => {
            if (!email) {
              $find('input[name="email"]', e.currentTarget)?.focus();
              throw new OMPAuthConstantS.AuthError(TT('auth::reset_password::reset_password_03'));
            }

            if (!OMPCoreUtilS.isValidEmail(email)) {
              $find('.input[name="email"]', e.currentTarget)?.focus();
              throw new OMPAuthConstantS.AuthError(TT('error::auth::error_05'));
            }

            $.don_loader_start();
            const res = await axios.post(`/${TT.lang}/@api/reset_password/request_cert`, { email });
            $.don_loader_end();
            OMPCoreUtilF.titleAlert({ ...res.data });
          },
        );
      } catch (e) {
        await catchError(e);
      }
    }),
  );
};
