import { each, go, mapObject, pick, tap } from 'fxjs/es';
import { addTdeisngsToFcanvasInMaker } from '../../../../Maker/F/draw_product_faces.js';
import { minusStrokeWidth } from '../../../../Maker/F/Fcanvas/stroke.js';
import { ungroupAllWithObjAttrs } from '../../../../Maker/F/util.js';

export const makePrintCanvasFromCvPrintArea = async ({ pf, px_width, px_height }) => {
  G.mp.maker.is_auto_print = true;
  const print_px = go(pf.cv_print_area, pick(['top', 'left', 'width', 'height']), minusStrokeWidth);
  if (!(px_width || px_height)) throw Error('px_width, px_height 없음.');
  const ratio = px_width ? px_width / print_px.width : px_height / print_px.height;
  const ratioed_print_px = go(
    print_px,
    mapObject((v) => v * ratio),
  );
  const f_canvas = new fabric.StaticCanvas(document.createElement('canvas'), {
    width: ratioed_print_px.width,
    height: ratioed_print_px.height,
    enableRetinaScaling: false,
  });
  await go(
    pf.cv_background ? [pf.cv_background].concat(pf.designs) : pf.designs,
    (designs) => ungroupAllWithObjAttrs(f_canvas, designs),
    (designs) => addTdeisngsToFcanvasInMaker(designs, f_canvas, ratio),
  );

  go(
    f_canvas._objects,
    each((design) => {
      design.set({
        top: Math.round(design.top - ratioed_print_px.top),
        left: Math.round(design.left - ratioed_print_px.left),
      });
    }),
  );
  f_canvas.renderAll();
  G.mp.maker.is_auto_print = false;
  return f_canvas.lowerCanvasEl;
};

export const makePrintCanvasFromPrintPx = async ({ pf, px_width, px_height, print_px }) => {
  if (!(px_width || px_height)) throw Error('px_width, px_height 없음.');
  const ratio = px_width ? px_width / print_px.width : px_height / print_px.height;
  const ratioed_print_px = go(
    print_px,
    mapObject((v) => v * ratio),
  );
  const f_canvas = new fabric.StaticCanvas(document.createElement('canvas'), {
    width: ratioed_print_px.width,
    height: ratioed_print_px.height,
    enableRetinaScaling: false,
  });
  await go(
    pf.cv_background ? [pf.cv_background].concat(pf.designs) : pf.designs,
    (designs) => ungroupAllWithObjAttrs(f_canvas, designs),
    tap(() => {
      G.mp.maker.is_auto_print = true;
    }),
    (designs) => addTdeisngsToFcanvasInMaker(designs, f_canvas, ratio),
  );
  G.mp.maker.is_auto_print = false;
  go(
    f_canvas._objects,
    each((design) => {
      design.set({
        top: Math.round(design.top - ratioed_print_px.top),
        left: Math.round(design.left - ratioed_print_px.left),
      });
    }),
  );
  f_canvas.renderAll();

  return f_canvas.lowerCanvasEl;
};
