export function withdrawalKr(user) {
  return _p.t(
    'user',
    '\
    .user_withdrawal_area\
      .description\
        p 그 동안 마플을 이용해주셔서 감사합니다.\
        p\
          |불편하셨던 점이나 불만사항을 알려주시면 적극 반영하여<span class="br"></span>\
          |보다 나은 서비스가 되도록 노력하겠습니다. 아울러 회원 탈퇴시 아래 사항을 숙지해 주시기 바랍니다.\
        p\
          |회원에서 탈퇴하시는 경우 고객님의 개인정보는 마플 고객정보 보호정책<span class="br"></span>\
          |<span class="txt1">(상품 반품 및 A/S를 위해 [ 전자상거래 등에서의 소비자 보호에 관한 법률 ] 에 의거)</span> 에 따라 관리 됩니다.<br>\
          |재가입은 가능하지만,<br>기존의 정보(주문 이력, 업로드한 이미지, 장바구니 정보 및 보유 중인 적립금 소멸)는 복구되지 않습니다.\
      .point_area\
        사용 가능한 고객님의 포인트는 <span class="point">{{user.point}} 점</span> 입니다.\
      .buttons\
        button[type="button"].btn_withdrawal 회원 탈퇴\
  ',
  )(user);
}

export function withdrawalEn(user) {
  return _p.t(
    'user',
    '\
    .user_withdrawal_area\
      .description\
        p\
          |Thank you for using Marpple.com\
        p\
          |If you let us know any inconveniences or complaints, we will make every effort to serve you better.\
          |In addition, please be aware of the following when deleting your account.\
        p\
          |If you delete your account, your personal information will be managed in accordance with Marpple\'s \
          |Customer Information Protection policy (In accordance with the "Law on Consumer Protection in Electronic Trades" for product return and service). Your coupon will be expired and you will not be able to re-use your ID once you delete your account.\
      .buttons\
        button[type="button"].btn_withdrawal Submit\
  ',
  )(user);
}

export function withdrawalJp(user) {
  return _p.t(
    'user',
    '\
    .user_withdrawal_area\
      .description\
        p Marppleをご利用いただきありがとうございます。\
        p\
          |ご不便な点などをお教えいただければ、お客様のご意見を積極的に反映し、<span class="br"></span>\
          |より良いサービスを提供できるよう努めてまいります。<span class="br"></span>\
          |また、会員退会の前に、以下の内容をご確認ください。\
        p\
          |退会される場合、お客様の情報はプライバシーポリシー（返品およびアフターサービスのため「電子商取引上の消費者保護に関する法律」に準拠）に従って管理されます。再登録は可能ですが、既存の情報（注文履歴、保存されたイメージなど）は元に戻すことができませんのでご注意ください。\
      .buttons\
        button[type="button"].btn_withdrawal 会員退会\
  ',
  )(user);
}
