import { $find, $qs, $setCss } from 'fxdom/es';
import { go, tap } from 'fxjs/es';

!(function () {
  $.frame.defn_frame({
    frame_name: 'df.receipt_document',
    page_name: 'df.receipt_document',
  });

  $.frame.defn_page({
    page_name: 'df.receipt_document',
    tabs: [
      {
        tab_name: 'df.receipt_document',
        infinite: function (tab_el) {
          return $.frame.infi($1('.infinite_wrapper'), {
            limit: 50,
            template: (for_receipt) => G.df.receipt_document.tmpl(for_receipt),
            data_func: function (offset, limit) {
              return _p.go(
                $.get(
                  '/@api/receipt/receipt_items',
                  _p.extend(
                    {
                      offset: offset,
                      limit: limit,
                    },
                    G.query_str(location.search),
                  ),
                ),
                function (for_receipts) {
                  window.box.push.apply(null, ['for_receipts'].concat(for_receipts));
                  return for_receipts;
                },
              );
            },
          });
        },
      },
    ],
    appended: __(
      $.on2('change', '.input_content[name="receipt_type"]', function ({ currentTarget: { value: curVal } }) {
        go(
          $qs('.input_content[name="receipt_status"]'),
          tap($find('[value="is_canceled"]'), $setCss({ display: curVal == '3' ? 'none' : '' })),
          $find('[value="need_reissue"]'),
          $setCss({ display: curVal == '3' ? '' : 'none' }),
        );
      }),
      $.on2('click', '.business_license', function (e) {
        const url = box.sel($.closest(e.currentTarget, '.for_receipt')).receipt.business_license;
        if (url) return window.open(url);
      }),
      $.on2('click', '.filter .search', function (e) {
        return _p.go(
          $('.filter .input_content'),
          _p.map(function (sel) {
            const key = $.attr(sel, 'name');
            const value = $.val(sel);
            return { key, value };
          }),
          _reject((sel) => sel.value === ''),
          _p.reduce((mem, sel) => G.update_query_str(mem, sel.key, sel.value), location.pathname),
          (href) => (location.href = href),
        );
      }),
      $.on2('click', '.for_receipt .projection_id', function (e) {
        e.preventDefault();
        const projection_id = _go(e.currentTarget, $.closest('[_sel]'), box.sel).projection_id;
        _p.go($.get('/@api/projection/detail', { projection_id }), function (projection) {
          box.set('projection', projection);

          return G.df.projection.detail.open(projection, 'projection.detail.payment');
        });
      }),
      $.on2('click', '.open_receipt', function (e) {
        const receipt = box.sel($.closest(e.currentTarget, '.for_receipt')).receipt;
        if (!receipt.done) return $.alert('발급 되지 않은 영수증입니다.');
        if (receipt.type == 3) return $.alert('세금 계산서는 여기서 영수증을 볼수 없습니다.');
        window.open(receipt.response.receipt_url);
      }),
    ),
  });

  G.df.receipt = G.df.receipt || {};
})();
