import axios from 'axios';
import { $delegate, $find, $hasClass, $removeClass, $setText, $setVal, $val } from 'fxdom/es';
import { go } from 'fxjs/es';
import { AlertTmplS } from '../../../../Alert/S/Tmpl/module/AlertTmplS.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { checkPhoneNumberSms } from '../../../../Sms/Fs/S/fs.js';
import { UserFindIdResultMuiF } from '../../Result/F/Mui/module/UserFindIdResultMuiF.js';
import { UserFindIdF } from './module/UserFindIdF.js';

export const events = (tab_el) => {
  const auth_timer = new UserFindIdF.$ComTimer($find('.find-id__data__cert__timer', tab_el));

  return go(
    tab_el,
    $delegate('input', 'input[name="mobile"]', (e) => {
      $setVal($val(e.currentTarget).replace(/[^0-9]/g, ''), e.currentTarget);
      if ($val(e.currentTarget).length > e.currentTarget.maxLength)
        $setVal($val(e.currentTarget).trim().slice(0, e.currentTarget.maxLength), e.currentTarget);
    }),
    $delegate('input', 'input[name="code"]', (e) => {
      $setVal($val(e.currentTarget).replace(/[^0-9]/g, ''), e.currentTarget);
      const code = go(e.currentTarget, $val).trim();
      if (code.length > e.currentTarget.maxLength)
        $setVal(code.slice(0, e.currentTarget.maxLength), e.currentTarget);
    }),
    $delegate('click', '#request-cert', async (e) => {
      const mobile_el = go(e.delegateTarget, $find('input[name="mobile"]'));
      const name_el = go(e.delegateTarget, $find('input[name="name"]'));
      const cert_code_el = go(e.delegateTarget, $find('.find-id__data__cert'));
      const mobile = $val(mobile_el).trim();
      const name = $val(name_el).trim();

      if (!mobile || (mobile && !checkPhoneNumberSms(mobile))) {
        await $.alert(
          AlertTmplS.alertTmpl({
            title: '인증번호 전송 불가',
            content: '인증번호를 전송할 수 없습니다<br/>연락처를 확인해주세요.',
          }),
        );
        mobile_el.focus();
        return;
      }

      if (!name) {
        await $.alert(
          AlertTmplS.alertTmpl({
            title: '인증번호 전송 불가',
            content: '이름을 입력하여 주세요.',
          }),
        );
        name_el.focus();
        return;
      }

      try {
        $.don_loader_start();
        const res = await axios.post('/@api/find_id/request_cert', { mobile, name });
        if (!res.data.result) return $.alert(AlertTmplS.alertTmpl(res.data));
        if ($hasClass('disabled', cert_code_el)) {
          $setText('인증번호 재전송', $find('#request-cert', e.delegateTarget));
          $removeClass('disabled', cert_code_el);
        }
        auth_timer.timerReStart();
        $.don_loader_end();
      } catch (e) {
        $.don_loader_end();
        $.alert(AlertTmplS.alertTmpl(e.response.data || e.message));
      }
    }),
    $delegate('submit', '#find-id__data', async (e) => {
      e.originalEvent.preventDefault();
      const mobile_el = go(e.delegateTarget, $find('input[name="mobile"]'));
      const name_el = go(e.delegateTarget, $find('input[name="name"]'));
      const code_el = go(e.delegateTarget, $find('input[name="code"]'));
      const code = util.only_number($val(code_el).trim());
      const mobile = $val(mobile_el).trim();
      const name = $val(name_el).trim();
      const valid_code = code.length === 6;

      if (!mobile || (mobile && !checkPhoneNumberSms(mobile))) {
        await $.alert(
          AlertTmplS.alertTmpl({
            title: '인증번호 전송 불가',
            content: '인증번호를 전송할 수 없습니다<br/>연락처를 확인해주세요.',
          }),
        );
        mobile_el.focus();
        return;
      }

      if (!name) {
        await $.alert(
          AlertTmplS.alertTmpl({
            title: '인증번호 전송 불가',
            content: '이름을 입력하여 주세요.',
          }),
        );
        name_el.focus();
        return;
      }

      if (!valid_code) {
        await $.alert(AlertTmplS.alertTmpl('인증번호 6자리를 입력해 주세요.'));
        code_el.focus();
        return;
      }

      try {
        $.don_loader_start();
        const res = await axios.post('/@api/find_id/verify_cert', { mobile, name, code });
        $.don_loader_end();

        if (!res.data.result) return $.alert(AlertTmplS.alertTmpl(res.data));

        auth_timer.timerStop();

        $setVal('', code_el);
        await MuiF.pushPage(UserFindIdResultMuiF.page, (p, [t]) => {
          p.hide_frame_button_type = 'X';
          p.title = '아이디 찾기 결과';
          t.makeData = () => res.data.users;
        });
      } catch (e) {
        $.don_loader_end();
        $.alert(AlertTmplS.alertTmpl(e.response.data || e.message));
      }
    }),
  );
};
