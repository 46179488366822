import { alertsLgl } from '../../../F/Function/alerts.js';

export const alertInbound = {
  repress: {
    alert: async ({ confirmCallback }) => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 400;">본 주문서 운송장 관리로 이동할게요</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">해외 운송장은 본 주문서에서<br>모든 과정의 운송장 관리를 할 수 있어요.</p>',
        width: '336',
        confirmButtonText: '이동하기',
        cancelButtonText: '아니요',
        confirmCallback,
      });
    },
  },
  waybill: {
    deleteConfirm: async ({ waybill_index, waybill_no, confirmCallback }) => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 400;"><strong>운송장${
          waybill_index + 1
        }</strong> ${waybill_no}</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">이 운송장을 정말 삭제할까요?<br>운송장 삭제 시, 해당 운송장은 반드시 폐기되어요!</p>',
        width: '336',
        confirmButtonText: '운송장 삭제',
        cancelButtonText: '아니요',
        confirmCallback,
      });
    },
    deleteAlert: async () => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">비어있는 운송장이 아니에요</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">담겨있는 상품을 모두 비운 후, 삭제할 수 있어요.</p>',
        width: '336',
        confirmButtonText: '확인',
        showCancelButton: false,
      });
    },
    emptyWaybillAlert: async () => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">비어있는 운송장이 있어요</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">비어있는 운송장을 삭제하고 다시 완료해 주세요.</p>',
        width: '336',
        confirmButtonText: '확인',
        showCancelButton: false,
      });
    },
  },
  inbound: {
    requestConfirm: async ({ confirmCallback }) => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">입고 요청하시겠어요?</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">LGL 물류 센터로 입고 요청되어요.</p>',
        width: '336',
        confirmButtonText: '입고 요청',
        cancelButtonText: '아니요',
        confirmCallback,
      });
    },
    cancelConfirm: async ({ confirmCallback }) => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">정말 입고 요청을 취소하시겠어요?</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">상품 포장 탭에서 다시 포장 완료해주셔야 해요.</p>',
        width: '336',
        confirmButtonText: '요청 취소',
        cancelButtonText: '아니요',
        actionsClass: 'lgl_alerts',
        confirmButtonClass: 'confirm_button_danger',
        confirmCallback,
      });
    },
    quantityDisparityAlert: async () => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">특정 상품 수량의 감소가 있어요!</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">감소 수량을 확인하고 운송장에서 재포장해 주세요.</p>',
        width: '336',
        confirmButtonText: '확인',
        showCancelButton: false,
      });
    },
    quantityZeroAlert: async () => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">주문서 상품 목록이 변경되었어요!</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">특정 상품이 주문 목록에서 제외되었어요.<br>최종 반영된 상품 목록으로 재포장해주세요.</p>',
        width: '336',
        confirmButtonText: '확인',
        showCancelButton: false,
      });
    },
    returnOnlyRejectItemsConfirm: async ({ confirmCallback }) => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">검수 불량을 회수 요청할까요?</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">회수 요청 후에는 취소할 수 없어요.</p>',
        width: '336',
        confirmButtonText: '회수 요청',
        cancelButtonText: '아니요',
        actionsClass: 'lgl_alerts',
        confirmButtonClass: 'confirm_button_danger',
        confirmCallback,
      });
    },
    returnOnlyOkItemsConfirm: async ({ confirmCallback }) => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">검수 정상을 회수 요청할까요?</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">회수 요청 후에는 취소할 수 없어요.</p>',
        width: '336',
        confirmButtonText: '회수 요청',
        cancelButtonText: '아니요',
        actionsClass: 'lgl_alerts',
        confirmButtonClass: 'confirm_button_danger',
        confirmCallback,
      });
    },
    returnItemsConfirm: async ({ confirmCallback }) => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">검수 정상도 회수 요청할까요?</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">회수 요청 후에는 취소할 수 없어요.</p>',
        width: '336',
        confirmButtonText: '회수 요청',
        cancelButtonText: '아니요',
        actionsClass: 'lgl_alerts',
        confirmButtonClass: 'confirm_button_danger',
        confirmCallback,
      });
    },
  },
};

export const alertOutbound = {
  outbound: {
    disableAutoRequestAlert: async () => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">자동 출고 중지</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">일시적으로 출고를 막아야 하는 상황이에요.<br>문제를 해결 후, 직접 출고 주문을 요청해 주세요.</p>',
        width: '336',
        confirmButtonText: '확인',
        showCancelButton: false,
      });
    },
    enableAutoRequestAlert: async () => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">자동 출고 가능</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">출고에 문제가 없을 경우, <br>자동으로 출고 주문을 생성해서 진행되어요.</p>',
        width: '336',
        confirmButtonText: '확인',
        showCancelButton: false,
      });
    },
    requestConfirm: async ({ confirmCallback }) => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">출고 주문을 요청할까요?</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">출고 주문을 요청하면, <br>출고 상품 목록 확인 후, 출고가 진행되어요.</p>',
        width: '336',
        confirmButtonText: '요청하기',
        cancelButtonText: '아니요',
        confirmCallback,
      });
    },
    cancelConfirm: async ({ confirmCallback }) => {
      await alertsLgl.confirm({
        title: `<h5 style="font-size: 18px; margin: 0; font-weight: 700;">정말 출고 요청을 취소하시겠어요?</h5>`,
        msg: '<p style="font-size: 14px; line-height: 21px; margin: 0;">메시지 요청 필요</p>',
        width: '336',
        confirmButtonText: '요청 취소',
        cancelButtonText: '아니요',
        actionsClass: 'lgl_alerts',
        confirmButtonClass: 'confirm_button_danger',
        confirmCallback,
      });
    },
  },
};
