import { go, strMap } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';

export const htmlEditorSelectBtns = (initial_editor, editor_mode) => {
  return go(
    editor_mode,
    strMap(
      ([editor, data]) =>
        legacyHtml`
          ${
            !G.is_pc_size()
              ? legacyHtml` <div style="display:none">
                <img class="btn_img_normal" src="${data.url_normal_icon}" alt="" />
                <img class="btn_img_selected" src="${data.url_select_icon}" alt="" />
              </div>`
              : ''
          }
          <div class="${editor}_select_icon">
            <button
              name="${editor}"
              item="${editor}"
              type="button"
              class="${editor}_select_btn ${initial_editor === editor ? 'selected' : ''}"
            >
              ${G.is_pc_size() ? legacyHtml`<span>${T(`image_editor::${data.display_name}`)}</span>` : ''}
            </button>
          </div>
        `,
    ),
  );
};

export const htmlAddPresetFilterCanvas = (item, display_name) => {
  const is_select = item === 'original' ? 'selected' : '';
  return legacyHtml`
    <div class="filter_item swiper-slide">
      <span class="filter_name ${is_select}" item="${item}">${T(`image_editor::${display_name}`)}</span>
      <div class="blue_box shadow ${is_select}" item="${item}"></div>
      <div class="blue_box ${is_select}" item="${item}"></div>
      <canvas class="filter ${is_select}" item="${item}"></canvas>
    </div>
  `;
};

export const htmlAddPresetFilterSlider = (item) => {
  const is_select = item === 'original' ? 'selected' : '';
  return legacyHtml`
    <div class="filter_item_slider ${is_select}" item="${item}">
      ${
        item === 'original'
          ? `<span item="${item}">${T('image_editor::edit_freely')}</span>`
          : `<input class="filter_strength " type="range" item="${item}" min="0" max="1" step="0.001" value="1" /><span>100</span>
`
      }
    </div>
  `;
};

export const htmlAddAdjustmentBtn = (item, data, idx, is_mp_worker_policy) => {
  const is_select = idx === 0 ? 'selected' : '';
  return legacyHtml`
    <div class="adjustment_item btn_item swiper-slide ${
      data.is_show || is_mp_worker_policy ? '' : 'hidden'
    }" >
      <span class="${is_select}" item="${item}">${T(`image_editor::${data.display_name}`)}</span>
      <div class="button ${is_select}" item="${item}">
        <img class="btn_img_normal" item="${item}" src="${data.url_img_normal}" alt="" />
        <img class="btn_img_selected" item="${item}" src="${data.url_img_selected}" alt="" />
      </div>
    </div>
  `;
};

export const htmlAddAdjustmentSlider = (
  method,
  item,
  display_name,
  range_options,
  idx,
  is_show,
  is_mp_worker_policy,
) => {
  const is_select = idx === 0 ? 'selected' : '';
  return legacyHtml`
    <div class="adjustment_item_slider slider_item ${is_select} ${
    is_show || is_mp_worker_policy ? '' : 'hidden'
  }" item="${item}">
      <input
        method="${method}"
        item="${item}"
        display_name="${display_name}"
        type="range"
        value="${range_options.value}"
        min="${range_options.min}"
        max="${range_options.max}"
        step="${range_options.step}"
      />
      <span>${range_options.value}</span>
    </div>
  `;
};

export const htmlAddCropButtons = (item, data, idx) => {
  const is_select = idx === 0 ? 'selected' : '';
  return legacyHtml`
    <div class="crop_item btn_item swiper-slide">
      <span class="${is_select}" item="${item}">${T(`image_editor::${data.display_name}`)}</span>
      <div class="button ${is_select}" item="${item}">
        <img class="btn_img_normal" item="${item}" src="${data.url_img_normal}" alt="" />
        <img class="btn_img_selected" item="${item}" src="${data.url_img_selected}" alt="" />
      </div>
    </div>
  `;
};

export const htmlAddCropSliders = (method, item, display_name, range_options, idx) => {
  const is_select = idx === 0 ? 'selected' : '';
  return legacyHtml`
    <div class="crop_item_slider slider_item ${is_select}" item="${item}">
      <input
        method="${method}"
        item="${item}"
        display_name="${display_name}"
        type="range"
        value="${range_options.value}"
        min="${range_options.min}"
        max="${range_options.max}"
        step="${range_options.step}"
      />
      <span>${range_options.value}</span>
    </div>
  `;
};
