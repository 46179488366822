!(function (win) {
  $.frame.defn_frame([
    {
      frame_name: 'share.order_align_editor',
      page_name: 'share.order_align_page',
      el_class: 'share_order_align_editor',
      title: '순서 정렬',
      always_remove: true,
    },
  ]);

  $.frame.defn_page([
    {
      page_name: 'share.order_align_page',
      hide_frame_button_type: 'X',
      title: '순서 정렬',
      tabs: [
        {
          tab_name: 'share.order_align_tab',
          title: '순서 정렬',
          template: _p.pipe(
            function (data) {
              // type: image, text, image_text
              return {
                type: data.type ? data.type : 'image',
                order_by: data.order_by ? data.order_by : 'asc',
                items: data.items,
              };
            },
            _p.t$(
              '\
          .order_align[order_by="{{ $.order_by }}"]\
            button[type="button"].btn_finish 완료\
            #share_order_align_editor[type="{{$.type}}"]\
              {{_sum($.items, ',
              _p.t(
                'item',
                '\
                .item[_id="{{item.id}}" is_group="{{item.is_group}}"]\
                  .no {{ item.no }}\
                  .image[style="background-image: url({{G.to_150(item.url)}});" url="{{item.url}}"]\
                  .name[title="{{ item.name }}"] {{ item.name }}\
                  .btns\
                    button[type=button].btn_first first\
                    button[type=button].btn_last last\
              ',
              ),
              ')}}',
            ),
          ),
          appended: function (container) {
            const list = $1('#share_order_align_editor');

            Sortable.create(list, {
              animation: 0,
              handle:
                $.attr(list, 'type') == 'image'
                  ? '.image'
                  : $.attr(list, 'type') == 'image_text'
                  ? '.image'
                  : '.item',
            });

            return _p.go(
              container,
              $.on('click', '.order_btn_close', function (e) {
                $.frame.close();
              }),
              $.on('click', '.btn_first', function (e) {
                const item = $.closest(e.$currentTarget, '.item');
                $.prepend_to(item, list);
              }),
              $.on('click', '.btn_last', function (e) {
                const item = $.closest(e.$currentTarget, '.item');
                $.append_to(item, list);
              }),
              $.on('click', '.btn_finish', function (e) {
                const _items = $.find(e.$delegateTarget, '.item');
                const order_by = $.attr($.find1(e.$delegateTarget, '.order_align'), 'order_by') == 'desc';
                let count = order_by ? _items.length : 0;

                const items = _map(_items, function (item) {
                  return {
                    id: $.attr(item, '_id'),
                    url: $.attr($.find1(item, '.image'), 'url'),
                    no: order_by ? count-- : count++,
                  };
                });

                $.frame.close(items);
              }),
            );
          },
        },
      ],
    },
  ]);

  function editor(data) {
    return new Promise(function (rs) {
      $.frame.open(
        {
          frame_name: 'share.order_align_editor',
          page_name: 'share.order_align_page',
          closed: function (X, items) {
            rs(items);
          },
        },
        {
          page_name: 'share.order_align_page',
          tabs: [
            {
              tab_name: 'share.order_align_tab',
              data_func: function () {
                return data;
              },
            },
          ],
        },
      );
    });
  }

  G.order_align = {
    editor: editor,
  };
})(window);
