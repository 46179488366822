import { extend, go, map, object, pipe, strMap } from 'fxjs/es';
import { $attr, $closest, $delegate, $find, $findAll, $setVal, $trigger, $val } from 'fxdom/es';
import { legacyHtml } from '../../../../Util/S/Function/util.js';

export const makeBPFTemplateEditorHtml = (template) => legacyHtml`
  <label for="">템플릿</label>
  <div class="wrapper main-wrapper">
    <div class="wrapper row" key="template">
      <label for="">탬플릿 크기</label>
      <div class="item row">
        ${strMap(
          ({ key, title, value }) => legacyHtml`
            <div class="wrapper row">
              <label for="">${title}</label>
              <input type="number" name="${key}" value="${value || 0}" />
            </div>
          `,
          [
            { key: 'width', title: 'width', value: template.width },
            { key: 'height', title: 'height', value: template.height },
          ],
        )}
      </div>
      <div class="item row file_upload">
        <button type="button">템플릿 파일 업로드</button>
        <input type="file" />
        <input type="text" name="template_file_url" value="${template.template_file_url}" />
      </div>
      <div class="item row file_upload">
        <button type="button">마스크 업로드</button>
        <input type="file" />
        <input type="text" name="mask_url" value="${template.mask_url}" />
      </div>
    </div>
    <div class="wrapper row">
      <label for="">최대 인쇄영역 정보</label>
      <div class="item row" key="maximum_print_area">
        ${strMap(
          ({ key, title, value }) => legacyHtml`
            <div class="wrapper row">
              <label for="">${title}</label>
              <input type="number" name="${key}" value="${value || 0}" />
            </div>
          `,
          [
            { key: 'left', title: 'left', value: template.maximum_print_area.left },
            { key: 'top', title: 'top', value: template.maximum_print_area.top },
            { key: 'width', title: 'width', value: template.maximum_print_area.width },
            { key: 'height', title: 'height', value: template.maximum_print_area.height },
          ],
        )}
      </div>
    </div>
  </div>
`;

export function getBPFTemplateSaveInfo(el) {
  const template_el = go(el, $find('.template_editor'));
  if (!go(template_el, $find('input'))) return;
  const template = go(
    template_el,
    $findAll('[key="template"] input[name]'),
    map((el) => {
      const val = $val(el);
      return [$attr('name', el), parseInt(val) ? parseInt(val) : val];
    }),
    object,
  );
  const maximum_print_area = go(
    template_el,
    $findAll('[key="maximum_print_area"] input[name]'),
    map((el) => {
      const val = $val(el);
      return [$attr('name', el), parseInt(val) ? parseInt(val) : val];
    }),
    object,
  );
  return extend(template, { maximum_print_area });
}

export const makeBPFTemplateEvent = pipe(
  $delegate('change', '.template_editor .file_upload input[type="file"]', async (e) => {
    $.don_loader_start();
    const { url, ai_url } = await $.upload(e.currentTarget);
    go(e.currentTarget, $closest('.file_upload'), $find('input[type="text"]'), $setVal(ai_url || url));
    $.don_loader_end();
    $.alert('업로드 완료');
  }),
  $delegate('click', '.template_editor .file_upload button', async (e) => {
    go(e.currentTarget, $closest('.file_upload'), $find('input[type="file"]'), (el) => $trigger('click', el));
  }),
);
