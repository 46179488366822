import { html } from 'fxjs/es';

export const makeFooterHtml = ({ is_show = true } = {}) => html`
  <div class="container" data-is_show="${!!is_show}">
    <div class="description">
      <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fill-rule="evenodd">
          <g fill-rule="nonzero">
            <g>
              <g transform="translate(-67 -569) translate(0 545) translate(67 24)">
                <g fill="#000">
                  <path
                    d="M6 11.2h-.4c-.22 0-.4.18-.4.4 0 .22.18.4.4.4H6c.22 0 .4-.18.4-.4 0-.22-.18-.4-.4-.4zM1.672 11.154c-.194-.055-.373-.16-.517-.303-.156-.156-.41-.154-.565.002-.156.157-.156.41.001.565.24.24.539.414.863.506.036.01.073.015.11.015.173 0 .334-.114.383-.29.061-.213-.062-.435-.275-.495zM.8 9.646v-.81c0-.22-.18-.4-.4-.4-.22 0-.4.18-.4.4v.81c0 .22.18.4.4.4.22 0 .4-.18.4-.4zM.4 2.767c.22 0 .4-.179.4-.4V2c0-.111.015-.22.045-.326.06-.213-.063-.434-.276-.494-.211-.063-.434.062-.494.276C.025 1.633 0 1.816 0 2v.367c0 .221.18.4.4.4zM.4 5.194c.22 0 .4-.18.4-.4v-.81c0-.22-.18-.4-.4-.4-.22 0-.4.18-.4.4v.81c0 .22.18.4.4.4zM.4 7.62c.22 0 .4-.18.4-.4v-.81c0-.22-.18-.4-.4-.4-.22 0-.4.18-.4.4v.81c0 .22.18.4.4.4zM2.353 0H2c-.191 0-.38.027-.562.08-.212.062-.333.285-.272.497.052.174.212.287.384.287.038 0 .076-.005.113-.016C1.772.816 1.885.8 2 .8h.353c.221 0 .4-.18.4-.4 0-.22-.18-.4-.4-.4zM11.535 1.949c.037 0 .075-.005.112-.017.212-.062.335-.284.272-.496-.094-.323-.272-.62-.513-.858-.156-.156-.41-.155-.565.003-.155.157-.154.41.003.565.144.144.251.322.307.515.051.174.21.288.384.288zM11.6 2.76c-.22 0-.4.18-.4.4v.809c0 .22.18.4.4.4.22 0 .4-.18.4-.4V3.16c0-.222-.18-.4-.4-.4zM3.983 11.2h-.809c-.22 0-.4.18-.4.4 0 .22.18.4.4.4h.81c.22 0 .4-.18.4-.4 0-.22-.18-.4-.4-.4zM9.631 0h-.809c-.22 0-.4.18-.4.4 0 .22.18.4.4.4h.81c.22 0 .4-.18.4-.4 0-.22-.18-.4-.4-.4zM4.78 0h-.81c-.22 0-.4.18-.4.4 0 .22.18.4.4.4h.81c.22 0 .4-.18.4-.4 0-.22-.18-.4-.4-.4zM7.206 0h-.81c-.22 0-.4.18-.4.4 0 .22.18.4.4.4h.809c.221 0 .4-.18.4-.4 0-.22-.179-.4-.4-.4z"
                  />
                </g>
                <rect width="11" height="11" x="3.5" y="3.5" fill="#FFF" stroke="#000" rx="1.5" />
              </g>
            </g>
          </g>
        </g>
      </svg>
      <p>${T('modules::VectorEditor::Sticker::FreeGrid::복제할 영역을 선택하고 완료해 주세요.')}</p>
    </div>
    <div class="buttons">
      <button type="button" class="cancel">${T('modules::VectorEditor::Sticker::FreeGrid::취소')}</button>
      <button type="button" class="done">${T('modules::VectorEditor::Sticker::FreeGrid::완료')}</button>
    </div>
  </div>
`;
