import { html } from 'fxjs/es';

export const changeLang = (href, lang) => {
  lang = `/${lang}`;
  return html`<br /><br />
    <div>
      ${T.lang != 'kr'
        ? html`<a
            href="${href.replace(lang, '/kr')}"
            class="creator-footer__anchor mp-shop-lang"
            lang="kr"
            style="text-decoration: none;"
          >
            <span class="creator-footer__text">한국어</span>
          </a>`
        : ''}
      ${T.lang != 'en'
        ? html`<a
            href="${href.replace(lang, '/en')}"
            class="creator-footer__anchor mp-shop-lang"
            lang="en"
            style="text-decoration: none;"
          >
            <span class="creator-footer__text">English</span>
          </a>`
        : ''}
      ${T.lang != 'jp'
        ? html`<a
            href="${href.replace(lang, '/jp')}"
            class="creator-footer__anchor mp-shop-lang"
            lang="jp"
            style="text-decoration: none;"
          >
            <span class="creator-footer__text">日本語</span>
          </a>`
        : ''}
    </div>`;
};

export const accessDenied = () => {
  return html`
    <div class="adult-access-denied">
      <img
        class="adult-access-denied__img"
        src="//s3.marpple.co/files/u_29089/2023/1/original/c68f3de5da1fcf4808df65941fba7675d09ca53f1.webp"
        alt=""
      />
      <div class="adult-access-denied__title">${T('mshop::Access Denied')}</div>
      <div class="adult-access-denied__description">
        ${T('mshop::Users must be at least 18 to access age-restricted contents.')}
      </div>
    </div>
  `;
};

export const adultItem = () => {
  const age = T.lang == 'kr' ? 19 : 18;
  return html`
    <div class="adult-item adult-item--${age}">
      <div class="adult-item__age">${age}</div>
      <div class="adult-item__plus">+</div>
    </div>
  `;
};
