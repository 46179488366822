import {
  DF_ADVANCE_RESERVATION_DETAIL_FRAME,
  DF_ADVANCE_RESERVATION_DETAIL_PAGE,
  DF_ADVANCE_RESERVATION_DETAIL_TAB,
} from '../../S/constant.js';
import { makeAdvanceReservationDetailHtml } from '../../S/tmpl.js';

$.frame.defn_frame({
  frame_name: DF_ADVANCE_RESERVATION_DETAIL_FRAME,
  page_name: DF_ADVANCE_RESERVATION_DETAIL_PAGE,
  always_remove: true,
});

$.frame.defn_page({
  page_name: DF_ADVANCE_RESERVATION_DETAIL_PAGE,
  hide_frame_button_type: 'X',
  tabs: [
    {
      tab_name: DF_ADVANCE_RESERVATION_DETAIL_TAB,
      template: makeAdvanceReservationDetailHtml,
    },
  ],
});
