/**
 *
 * @param {object} params
 * @param {HTMLDivElement} params.tab_el
 * @param {number} params.size
 * @returns {void}
 */
export const setSizeDisplayValue = (params) => {
  if (Number.isNaN(params.size) || !Number.isFinite(params.size)) {
    return;
  }

  const value_el = params.tab_el.querySelector(`.footer_item_size .footer_item_control_value`) ?? null;
  if (value_el === null) {
    return;
  }

  value_el.innerHTML = `${params.size}mm`;
};
