import { each, go, html } from 'fxjs/es';
import { $findAll, $removeAttr, $setAttr, $setHTML } from 'fxdom/es';
import { VectorEditorKeyringFreeMobileConstantS } from '../../S/Constant/module/VectorEditorKeyringFreeMobileConstantS.js';

export const showTopMenuBlankPlate = ({ tab_el }) => {
  each((el) => {
    el.dataset.is_show = 'true';
  })($findAll(`.blank_plate[data-target="top_menu"]`)(tab_el));
};

export const hideTopMenuBlankPlate = ({ tab_el }) => {
  each((el) => {
    el.dataset.is_show = 'false';
  })($findAll(`.blank_plate[data-target="top_menu"]`)(tab_el));
};

export const showTopMenuContainer = ({ tab_el }) => {
  each((el) => {
    el.dataset.is_show = 'true';
  })($findAll(`.top_menu_container`)(tab_el));
};

export const hideTopMenuContainer = ({ tab_el }) => {
  each((el) => {
    el.dataset.is_show = 'false';
  })($findAll(`.top_menu_container`)(tab_el));
};

export const activateTopMenus = ({ tab_el, activate_ids, deactivate_ids }) => {
  each((el) => {
    if (activate_ids.has(el.dataset.icon_type)) {
      go(el, $findAll('input[type="button"]:disabled'), each($removeAttr('disabled')));
      return;
    }

    if (deactivate_ids.has(el.dataset.icon_type)) {
      go(el, $findAll(`input[type="button"]:not(:disabled)`), each($setAttr(['disabled', ''])));
    }
  })($findAll(`.top_menu_container .small_icon_menu`)(tab_el));
};

export const activateTopMenuUnlockIcon = ({ tab_el }) => {
  go(
    tab_el,
    $findAll(
      `.top_menu_container .small_icon_menu[data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.UNLOCK.ID}"] input[type="button"]:disabled`,
    ),
    each($removeAttr('disabled')),
  );
};

export const deactivateTopMenuUnlockIcon = ({ tab_el }) => {
  go(
    tab_el,
    $findAll(
      `.top_menu_container .small_icon_menu[data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.UNLOCK.ID}"] input[type="button"]:not(:disabled)`,
    ),
    each($setAttr(['disabled', ''])),
  );
};

export const setLockCount = ({ tab_el, count }) => {
  go(
    tab_el,
    $findAll(
      `.top_menu_container .small_icon_menu[data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.UNLOCK.ID}"] .lock_count`,
    ),
    each($setHTML(count <= 0 ? '' : html`<span>${count}</span>`)),
  );
};
