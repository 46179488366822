import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

/*
 * @description 프로 에디터 템플릿 주소 경로 가져오는 함수
 * @params lang 국문(kr) 영문(en) 일문(jp)
 * @param base_product_id
 * @params file_extension (기본값 ai 사용)
 * @returns 템플릿 url 경로
 * */
export async function getProEditorTemplateUrl({ lang, base_product_id, file_extension = 'ai' }) {
  if (!['kr', 'en', 'jp'].includes(lang)) throw new Error(`Invalid language option`);

  const BUCKET = 's3.marpple.co';
  const key = `pro_editor/templates/${lang}`;
  const filename = `${base_product_id}.${file_extension}`;

  const endpoint_url = `https://s3.ap-northeast-2.amazonaws.com/${BUCKET}/${key}/${filename}`;

  if ((await UtilS.isValidUrl({ endpoint_url })) === false)
    throw new Error(`Not exist resource at ${endpoint_url}`);

  return { endpoint_url, file_extension };
}
