import { $attr, $closest, $delegate, $el, $find, $qs, $replaceWith } from 'fxdom/es';
import { go } from 'fxjs/es';
import { OMPCoreCellTmplS } from '../../S/Tmpl/module/OMPCoreCellTmplS.js';

export const renderTablePage = ({
  selector = '.omp-cell__table-wrapper',
  total_page,
  current_page,
  body_data: { data, columns, empty_notice, merge_column_key },
}) => {
  const $new_table_body = $el(
    OMPCoreCellTmplS.tableBodyTmpl({ data, columns, empty_notice, merge_column_key }),
  );
  const $new_table_page = $el(OMPCoreCellTmplS.tablePageTmpl({ total_page, current_page }));
  const $table_wrapper = $qs(selector);

  go($table_wrapper, $find('tbody'), $replaceWith($new_table_body));
  go($table_wrapper, $find('.omp-cell__table-pages'), $replaceWith($new_table_page));
};

export const tablePaginationDelegate = ({ selector = '.omp-cell__table-wrapper', callback = () => {} }) => {
  return (el) =>
    go(
      el,
      $delegate('click', `${selector} .omp-cell__table-page`, (e) => {
        const same_page_button_active = go(
          e.currentTarget,
          $closest('.omp-cell__table-pages'),
          (page) => page.dataset?.offset === e.currentTarget.dataset?.offset,
        );

        if (same_page_button_active) return;

        return callback(e);
      }),
      $delegate('click', `${selector} .omp-cell__table-page-buttons span`, (e) => {
        const disabled = $attr('disabled', e.currentTarget);

        if (disabled === 'true') return;

        return callback(e);
      }),
    );
};
