import { go, split } from 'fxjs/es';

export const converter = {
  xmmToSize: (xmm_string) => {
    return go(xmm_string, split('x'), ([width_str, height_str]) => {
      return {
        width: parseInt(width_str),
        height: parseInt(height_str.split('mm')[0]),
      };
    });
  },
  mmToInch: (n) => {
    return Number((n / 2.54).toFixed(2));
  },
};
