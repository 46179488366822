import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from './module/OMPCoreAtomTmplS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

/**
 * 장바구니 아이콘
 * @param {object} [param = {}]
 * @param {number?} param.badge 뱃지 숫자 (99 이상이면 99로 보여집니다.)
 */
export const cartButton = (param = {}) => {
  return html`<button class="omp-atom__icon-button">
    ${OMPCoreAtomTmplS.cartIcon()}
    ${param.badge
      ? html`<span class="omp-atom__icon-badge">${getBoundedCountForIconBadge(param.badge)}</span>`
      : ''}
  </button>`;
};

/**
 * 장바구니 아이콘
 * @param {object} [param = {}]
 * @param {number?} param.badge 뱃지 숫자 (99 이상이면 99로 보여집니다.)
 * @param {string?} param.link link
 */
export const cartButtonLink = (param = {}) => {
  return html`<a class="omp-atom__icon-button" href="${param.link}">
    ${OMPCoreAtomTmplS.cartIcon()}
    ${param.badge
      ? html`<span class="omp-atom__icon-badge">${getBoundedCountForIconBadge(param.badge)}</span>`
      : ''}
  </a>`;
};

/**
 * @param {boolean} [param.active = false]
 */
export const heartCircleButton = (param = { active: false }) => {
  return html`
    <button class="omp-atom__heart-circle-button">
      ${OMPCoreAtomTmplS.heartIcon({ ...param, color: 'gray' })}
    </button>
  `;
};

/**
 * 배경이 없는 화살표 아이콘 버튼
 * @param {object} [param = {}]
 * @param {'left' | 'right' | 'up' | 'down'} [param.direction = 'left']
 */
export const arrowButton = (param = { direction: 'left' }) => {
  return html`<button class="omp-atom__icon-button">${OMPCoreAtomTmplS.arrowIcon(param)}</button>`;
};

/**
 * 배경이 있는 더하기 아이콘
 */
export const plusButton = () => {
  return html`<button class="omp-atom__icon-button">${OMPCoreAtomTmplS.plusRoundIcon()}</button>`;
};

export const searchButton = () => {
  return html`<button class="omp-atom__icon-button">${OMPCoreAtomTmplS.searchIcon()}</button>`;
};

export const avatarButton = () => {
  return html`<button class="omp-atom__icon-button">${OMPCoreAtomTmplS.avatarIcon()}</button>`;
};

/**
 * 하트 아이콘 버튼
 * @param {boolean} [param.active = false]
 * @param {'gray'?} param.color 하트 outline 색상
 * @param {number?} param.badge 뱃지 숫자 (99 이상이면 99로 보여집니다.)
 */
export const heartButton = (param = { active: false }) => {
  return html`<button class="omp-atom__icon-button">
    ${OMPCoreAtomTmplS.heartIcon({ color: param.color, active: param.active })}
    ${param.badge
      ? html`<span class="omp-atom__icon-badge">${getBoundedCountForIconBadge(param.badge)}</span>`
      : ''}
  </button>`;
};

export const heartButtonLink = (param = { active: false }) => {
  return html`<a class="omp-atom__icon-button" href="${param.link}">
    ${OMPCoreAtomTmplS.heartIcon({ color: param.color, active: param.active })}
    ${param.badge
      ? html`<span class="omp-atom__icon-badge">${getBoundedCountForIconBadge(param.badge)}</span>`
      : ''}
  </a>`;
};

/**
 * `omp-atom__heart-count-button`에 표시될 카운트를 구하는 함수 (`MAX_COUNT` 999)
 * @param {number} count
 * @param {number} max
 * @return {number}
 */
const getBoundedCountForHeartIconBadge = (count, max) => {
  const MAX_COUNT = max || 999;
  if (count > MAX_COUNT) return UtilS.commify(MAX_COUNT);
  return UtilS.commify(count);
};

/**
 * 하트 카운트 아이콘 버튼
 * @param {boolean} [param.active = false]
 * @param {number?} param.badge 카운트 숫자
 */
export const heartCountButton = (param = { active: false, badge: 0, badge_max: 999 }) => {
  return html`
    <span class="omp-atom__heart-count-button">
      <button class="omp-atom__icon-button">
        ${OMPCoreAtomTmplS.heartIcon({ color: param.active ? '' : 'gray', active: param.active })}
      </button>
      <span class="omp-atom__icon-count" data-style_active="${param.active}">
        ${getBoundedCountForHeartIconBadge(param.badge, param.badge_max)}
      </span>
    </span>
  `;
};

export const hamButton = () => {
  return html`<button class="omp-atom__icon-button">${OMPCoreAtomTmplS.hamIcon()}</button>`;
};

export const closeButton = ({ color = 'white' }) => {
  return html`<button class="omp-atom__icon-button">${OMPCoreAtomTmplS.closeIcon({ color })}</button>`;
};

/**
 * `omp-atom__icon-badge`에 표시될 카운트를 구하는 함수 (`MAX_COUNT` 99)
 * @param {number} count
 * @return {number}
 */
const getBoundedCountForIconBadge = (count) => {
  const MAX_COUNT = 99;
  if (count > MAX_COUNT) return MAX_COUNT;
  return count;
};
