import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSinglePCCuttingLineAutoMuiF } from '../Mui/module/VectorEditorStickerSinglePCCuttingLineAutoMuiF.js';

export const makeCuttingLineAuto = ({
  single_sticker_editor,
  frame_position: { top: frame_position_top, height: frame_position_height },
  postProcess,
  prev_frame_right_panel_el,
  prev_page_right_panel_el,
  editor_padding: {
    top: editor_padding_top,
    right: editor_padding_right,
    bottom: editor_padding_bottom,
    left: editor_padding_left,
  },
}) =>
  MuiF.pushPage(VectorEditorStickerSinglePCCuttingLineAutoMuiF.page, (page, [tab]) => {
    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_single_sticker_editor = single_sticker_editor;
      tab_el.__mp_frame_position_top = frame_position_top;
      tab_el.__mp_frame_position_height = frame_position_height;
      tab_el.__mp_postProcess = postProcess;
      tab_el.__mp_prev_frame_right_panel_el = prev_frame_right_panel_el;
      tab_el.__mp_prev_page_right_panel_el = prev_page_right_panel_el;
      tab_el.__mp_editor_padding_top = editor_padding_top;
      tab_el.__mp_editor_padding_bottom = editor_padding_bottom;
      tab_el.__mp_editor_padding_left = editor_padding_left;
      tab_el.__mp_editor_padding_right = editor_padding_right;

      return tab_appending.call(tab, tab_el);
    };
  }).catch(async (error) => {
    await MuiF.popPage();
    throw error;
  });
