import strokeFragmentShaderSrc from '../../shaders/fragment/stroke.fragment.glsl';
import {
  createWebglProgram,
  getRectangle,
  setupAttrBufferData,
  transferBufferToLocation,
} from '../helpers/shaders.js';
import imageVertexShaderSrc from '../../shaders/vertex/image.vertex.glsl';

const INITIAL_STYLE = {
  color: [0.0, 0.0, 0.0, 1.0],
  thickness: 10,
  u_alpha_threshold: 0.3,
};

export class RasterStroke {
  constructor() {
    this.color = INITIAL_STYLE.color;
    this.thickness = INITIAL_STYLE.thickness;
    this.alpha_threshold = INITIAL_STYLE.u_alpha_threshold;
    this.#initialize();
  }

  #initialize() {
    const canvas = document.createElement('canvas');

    const options = { antialias: true, preserveDrawingBuffer: false };

    const gl = canvas.getContext('webgl', options);
    if (!gl) {
      throw new Error('Browser cannot support webgl');
    }

    this.canvas = canvas;
    this.gl = gl;

    const webglProgram = createWebglProgram({
      gl,
      vertexShaderSrc: imageVertexShaderSrc,
      fragmentShaderSrc: strokeFragmentShaderSrc,
    });

    this.gl = gl;
    this.webglProgram = webglProgram;

    const positionBuffer = setupAttrBufferData({
      gl,
      data: getRectangle({ w: 1.0, h: 1.0 }),
    });
    gl.useProgram(webglProgram);
    transferBufferToLocation({
      gl,
      webglProgram,
      attrName: 'a_position',
      arrayBuffer: positionBuffer,
      size: 2,
    });

    this.#setUniforms({
      uniforms: [
        { name: 'u_flipY', type: '1f', values: [-1.0] },
        { name: 'u_alpha_threshold', type: '1f', values: [this.alpha_threshold] },
      ],
    });
  }

  #syncCanvasSize(image) {
    const { width, height } = image;
    this.size = { w: width, h: height };

    this.canvas.width = width;
    this.canvas.height = height;

    this.#setUniforms({
      uniforms: [
        {
          name: 'u_resolution',
          type: '2f',
          values: [width, height],
        },
      ],
    });
  }

  setImage(image) {
    const gl = this.gl;
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

    this.#syncCanvasSize(image);
  }

  #setUniforms({ uniforms }) {
    this.gl.useProgram(this.webglProgram);
    uniforms.forEach(({ name, type, values }) => {
      this.gl[`uniform${type}`](this.gl.getUniformLocation(this.webglProgram, name), ...values);
    });
  }

  setTargetRaster(raster) {
    this.target_raster = raster;
  }

  drawImageToTargetCanvas() {
    this.target_raster.clear();
    this.target_raster.drawImage(this.gl.canvas);
  }

  clear() {
    const gl = this.gl;
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);
  }

  drawStroke({ color, thickness, alpha_threshold }) {
    this.gl.useProgram(this.webglProgram);

    if (color) {
      if (this.isColorHex(color)) {
        color = this.convertHexColorToVector(color);
      } else if (Array.isArray(color) && (color.length === 3 || color.length === 4)) {
        if (color.length === 3) {
          color.push(1.0);
        }
      } else {
        throw new Error(`Wrong color format ${color}`);
      }

      this.color = color;
    }

    if (thickness) {
      if (typeof thickness !== 'number') {
        throw new Error(`Wrong number format ${thickness}`);
      }
      this.thickness = thickness;
    }

    if (alpha_threshold) {
      if (typeof alpha_threshold !== 'number') {
        throw new Error(`Wrong number format ${alpha_threshold}`);
      }
      this.alpha_threshold = alpha_threshold;
    }

    this.#setUniforms({
      uniforms: [
        { name: 'u_radius', type: '1f', values: [this.thickness] },
        { name: 'u_color', type: '4f', values: this.color },
        { name: 'u_alpha_threshold', type: '1f', values: [this.alpha_threshold] },
      ],
    });
    this.render();
  }

  prepareRender() {
    const gl = this.gl;
    const { w, h } = this.size;

    gl.viewport(0, 0, w, h);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);
  }

  render() {
    this.prepareRender();
    const gl = this.gl;
    gl.drawArrays(gl.TRIANGLES, 0, 6);
    this.drawImageToTargetCanvas();
  }

  toDataURL() {
    return this.gl.canvas.toDataURL('image/png', 1);
  }

  getCanvas() {
    return this.gl.canvas;
  }

  isColorHex(value) {
    const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return hexColorRegex.test(value);
  }

  convertHexColorToVector(hex) {
    /* #RGB */
    if (hex.length === 4) {
      hex = hex.replace(/^#(.)(.)(.)$/, '#$1$1$2$2$3$3ff');
    }

    /* #RGBA */
    if (hex.length === 5) {
      hex = hex.replace(/^#(.)(.)(.)$/, '#$1$1$2$2$3$3$4$4');
    }

    /* #RRGGBB */
    if (hex.length === 7) {
      hex = hex + 'ff';
    }

    const r = parseInt(hex.substring(1, 3), 16) / 255;
    const g = parseInt(hex.substring(3, 5), 16) / 255;
    const b = parseInt(hex.substring(5, 7), 16) / 255;
    const a = parseInt(hex.substring(7, 9), 16) / 255;

    return [r, g, b, a];
  }
}
