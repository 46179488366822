import { delay, extend, go, identity, mapObject, pick, pipe, sel, strMap } from 'fxjs/es';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { getCurrentFcanvas, getCvObj } from '../../Fcanvas/cv_object.js';
import { plusStrokeWidth } from '../../Fcanvas/stroke.js';
import {
  addBlackSqaureCvPrintAreaToFcanvas,
  addCvMoireToFcanvas,
  endAdjust,
  getItemValue,
  getKey,
  makeCvPrintAreaForMask,
  startAdjust,
} from './fs.js';
import { getCurrentBpfInMaker, getItsSf } from '../../getSth.js';
import { enCylinderizeSize } from '../../../../Composite/Core/F/mapping.js';
import { makePreviewGradation, removeCvPreview } from '../cv_object.js';
import { updateBaseProductFacePreview } from '../../CvPrintArea/cv_object.js';
import { $delegate, $val } from 'fxdom/es';
import { getPreviewClinder } from '../Cylinder/obj.js';
import { renderFacesByCurrentBpcfsOnlySizeInMakerA } from '../../mp_maker.js';
import {
  cvMask1CvMask2CvPrintAreaForceOff,
  cvMask1CvMask2CvPrintAreaForceOffDone,
  extractUrlSpecificCvObjBySize770,
  getFwidth,
  isBase64,
} from '../../util.js';
import { control } from './event.js';
import axios from 'axios';
import { createCanvasElement, makeCanvasByUrl } from '../../../../Canvas/S/util.js';

export const makeCylinderEditorHtml = (cylinder) => legacyHtml`
  <label for="">위아래 왜곡 조절</label>
  <div class="wrapper main-wrapper" adjust>
    <div class="wrapper row">
      <div class="item row adjust" adjust-name="size" key="cylinder_print_area">
        <label for="">원통 사이즈</label>
        <div class="wrapper row start_end horizontal_func">
          ${strMap(
            ({ key, title, value }) => legacyHtml`
              <div class="wrapper cv_print_area_item row">
                <label for="">${title}</label>
                <input type="number" name="${key}" value="${value || 0}" />
              </div>
            `,
            [
              { key: 'width', title: 'width', value: cylinder.area.width },
              { key: 'height', title: 'height', value: cylinder.area.height },
              { key: 'left', title: 'left', value: cylinder.area.left },
              { key: 'top', title: 'top', value: cylinder.area.top },
            ],
          )}
          <button type="button" class="horizontal_center column">가로 중앙 정렬</button>
        </div>
        <div class="wrapper create row">
          <button class="start">시작</button>
          <button class="end">끝</button>
        </div>
      </div>
    </div>
    <div class="wrapper row upside_downside adjust" adjust-name="upside_downside">
      <label for="">아래 위 왜곡</label>
      <div class="wrapper row start_end">
        ${strMap(
          ({ key, title }) => legacyHtml`
            <div class="item" key="${key}">
              <button type="button">${title}</button>
            </div>
          `,
          [
            { key: 'up_side_up', title: '윗부분 올리기' },
            { key: 'up_side_down', title: '윗부분 내리기' },
            { key: 'down_side_up', title: '아랫 부분 올리기' },
            { key: 'down_side_down', title: '아랫 부분 내리기' },
          ],
        )}
      </div>
      <div class="wrapper create row">
        <button class="start">시작</button>
        <button class="end">끝</button>
      </div>
    </div>
    <div class="wrapper row">
      <div class="item row adjust" key="reflection_url" adjust-name="reflection">
        <label for="">원통 음영</label>
        <div class="start_end row wrapper">
          <div class="wrapper row">
            <label for="">위치</label>
            <input type="number" name="location" step="1" value="50" max="100" />
          </div>
          <div class="wrapper row">
            <label for="">[음영의 밝기 0 - 255 기본값: 150]</label>
            <input type="number" name="brightness" step="1" value="150" max="255" />
          </div>
        </div>
        <div class="wrapper create row">
          <button class="start">시작</button>
          <button class="end">끝</button>
        </div>
      </div>
    </div>
    <div class="wrapper row">
      <button class="make_cv_mask1">마스크 저장</button>
      <button class="make_cv_mask2">마스크2 저장</button>
      <button class="make_cylinder_shape">원형 틀 추출</button>
    </div>
  </div>
`;

export const makeCylinderEvent = pipe(
  $.on3('click', '.cv_preview_editor .cylinder_editor .upside_downside .item button', async (e) => {
    const fcanvas = getCurrentFcanvas();
    const key = getKey(e);
    const { cylinder } = fcanvas.preview;
    if (key === 'up_side_up') {
      cylinder.up_side -= 1;
    } else if (key === 'up_side_down') {
      cylinder.up_side += 1;
    } else if (key === 'down_side_up') {
      cylinder.down_side -= 1;
    } else if (key === 'down_side_down') {
      cylinder.down_side += 1;
    }
    await control(fcanvas, (cv_obj) => {});
  }),
  $.on3('click', '.cv_preview_editor .cylinder_editor .upside_downside .start', async (e) => {
    if (!startAdjust(e)) return;
    const fcanvas = getCurrentFcanvas();
    cvMask1CvMask2CvPrintAreaForceOff(fcanvas);
    const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
    extend(cv_print_area, plusStrokeWidth(fcanvas.preview.cylinder.area));
    fcanvas.renderAll();
    await addCvMoireToFcanvas(fcanvas);
  }),
  $.on3('click', '.cv_preview_editor .cylinder_editor .upside_downside .end', async (e) => {
    const fcanvas = getCurrentFcanvas();
    getCvObj(fcanvas._objects, 'cv_grid').remove();
    const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
    const sf = getItsSf(fcanvas);
    extend(cv_print_area, plusStrokeWidth(sf.print.px));
    fcanvas.preview.cylinder.enCylinderized_area = enCylinderizeSize(
      fcanvas.preview.cylinder.area,
      fcanvas.preview.cylinder,
    );
    removeCvPreview(fcanvas, null, true);
    await updateBaseProductFacePreview(fcanvas, fcanvas.preview);
    cvMask1CvMask2CvPrintAreaForceOffDone(fcanvas);
    endAdjust(e);
  }),
  $.on3('click', '.cv_preview_editor .cylinder_editor .item[key="degree"]', async (e) => {
    const fcanvas = getCurrentFcanvas();
    fcanvas.preview.cylinder[getKey(e)] = fcanvas.preview[getKey(e)] || 0;
    fcanvas.preview.cylinder[getKey(e)] += 1;
    await control(fcanvas, (cv_obj) => {});
  }),

  $.on3(
    'change',
    '.cv_preview_editor .cylinder_editor .main-wrapper[adjust="reflection"] input[name="brightness"]',
    async (e) => {
      const fcanvas = getCurrentFcanvas();
      const brightness = parseInt($val(e.currentTarget));
      fcanvas.preview.cylinder.reflection = fcanvas.preview.cylinder.reflection || {};
      fcanvas.preview.cylinder.reflection.ratio = fcanvas.preview.cylinder.reflection.ratio || 50;
      fcanvas.preview.cylinder.reflection.brightness = brightness;
      fcanvas.preview.cylinder.reflection_url = makePreviewGradation(
        fcanvas.preview.cylinder.reflection.ratio,
        go(
          getPreviewClinder(fcanvas).area,
          pick(['width', 'height']),
          mapObject((v) => (v * fcanvas.lowerCanvasEl.width) / fcanvas.width),
        ),
        brightness,
      ).toDataURL();
      await control(fcanvas, (cv_obj) => {});
    },
  ),
  $.on3(
    'change',
    '.cv_preview_editor .cylinder_editor .main-wrapper[adjust="reflection"] input[name="location"]',
    async (e) => {
      const fcanvas = getCurrentFcanvas();
      const ratio = parseInt($val(e.currentTarget)) / 100;
      fcanvas.preview.cylinder.reflection = fcanvas.preview.cylinder.reflection || {};
      fcanvas.preview.cylinder.reflection.ratio = ratio;
      fcanvas.preview.cylinder.reflection.brightness = fcanvas.preview.cylinder.reflection.brightness || 50;
      fcanvas.preview.cylinder.reflection_url = makePreviewGradation(
        ratio,
        go(
          getPreviewClinder(fcanvas).area,
          pick(['width', 'height']),
          mapObject((v) => (v * fcanvas.lowerCanvasEl.width) / fcanvas.width),
        ),
        fcanvas.preview.cylinder.reflection.brightness,
      ).toDataURL();
      await control(fcanvas, (cv_obj) => {});
    },
  ),

  $.on3(
    'click',
    '.cv_preview_editor .cylinder_editor .main-wrapper [adjust-name="reflection"] .start',
    async (e) => {
      if (!startAdjust(e)) return;
      const fcanvas = getCurrentFcanvas();
      cvMask1CvMask2CvPrintAreaForceOff(fcanvas);
      await addBlackSqaureCvPrintAreaToFcanvas(fcanvas, 'rgb(255, 255, 255, 1)');
      const cv_black_square_print_area = getCvObj(fcanvas._objects, 'cv_black_square_print_area');
      cv_black_square_print_area.set(plusStrokeWidth(fcanvas.preview.cylinder.area));
      await control(fcanvas, identity);
    },
  ),
  $.on3(
    'click',
    '.cv_preview_editor .cylinder_editor .main-wrapper [adjust-name="reflection"] .end',
    async (e) => {
      $.don_loader_start();
      const fcanvas = getCurrentFcanvas();
      const cv_black_square_print_area = getCvObj(fcanvas._objects, 'cv_black_square_print_area');
      cv_black_square_print_area.remove();
      fcanvas.renderAll();
      control(fcanvas, identity);
      if (isBase64(fcanvas.preview.cylinder.reflection_url)) {
        fcanvas.preview.cylinder.reflection_url = await go(
          $.uploadFileToOnlyOriginalUrl({
            url: fcanvas.preview.cylinder.reflection_url,
            image_type: 'JPEG',
          }),
          sel('url'),
        );
      }
      cvMask1CvMask2CvPrintAreaForceOffDone(fcanvas);
      $.don_loader_end();
      endAdjust(e);
    },
  ),
  $.on3('click', '.cv_preview_editor .cylinder_editor .item[key="cylinder_print_area"] .start', async (e) => {
    if (!startAdjust(e)) return;
    const fcanvas = getCurrentFcanvas();
    cvMask1CvMask2CvPrintAreaForceOff(fcanvas);
    fcanvas.renderAll();
    await addCvMoireToFcanvas(fcanvas);
  }),
  $.on3('click', '.cv_preview_editor .cylinder_editor .item[key="cylinder_print_area"] .end', async (e) => {
    $.don_loader_start();
    const fcanvas = getCurrentFcanvas();
    const { cylinder } = fcanvas.preview;
    const cv_cylinder_area = getCvObj(fcanvas._objects, 'cv_cylinder_area');
    cylinder.area = go(cv_cylinder_area, pick(['top', 'left', 'width', 'height']));
    await updateBaseProductFacePreview(fcanvas, fcanvas.preview);
    getCvObj(fcanvas._objects, 'cv_grid').remove();
    await control(fcanvas, identity);
    await renderFacesByCurrentBpcfsOnlySizeInMakerA();
    cvMask1CvMask2CvPrintAreaForceOffDone(fcanvas);
    $.don_loader_end();
    endAdjust(e);
  }),
  $.on3(
    'change',
    '.cv_preview_editor .item[key="cylinder_print_area"] .cv_print_area_item input',
    async (e) => {
      try {
        const fcanvas = getCurrentFcanvas();
        const cv_cylinder_area = getCvObj(fcanvas._objects, 'cv_cylinder_area');
        go(getItemValue(e), (v) => {
          extend(cv_cylinder_area, v);
        });
        const cv_grid = getCvObj(fcanvas._objects, 'cv_grid');
        cv_grid.scaleToWidth(getFwidth(getCvObj(fcanvas._objects, 'cv_cylinder_area')));
        G.mp.maker.align_print_area_center(cv_grid);
        // fcanvas.renderAll();
        await control(fcanvas, identity);
      } catch (e) {
        console.error(e);
      }
    },
  ),
  $delegate('click', '.cylinder_editor .make_cv_mask1', async (e) => {
    try {
      $.don_loader_start();
      delay(100, identity);
      const fcanvas = getCurrentFcanvas();
      cvMask1CvMask2CvPrintAreaForceOff(fcanvas);
      const cv_print_area_for_mask = await makeCvPrintAreaForMask(fcanvas);
      const cv_preview = getCvObj(fcanvas._objects, 'cv_preview');

      await go(
        extractUrlSpecificCvObjBySize770(fcanvas, cv_preview),
        (todataurl) => $.uploadFileToUrl(todataurl, 'mask_url'),
        sel('url'),
        (url) =>
          axios.post('/@api/base_product_color_faces/update', {
            values: { mask_url: url },
            condition: { base_product_id: box().maker.product_color.base_product_id },
          }),
      );
      cv_print_area_for_mask.remove();
      removeCvPreview(fcanvas, true, true);
      fcanvas.renderAll();
      cvMask1CvMask2CvPrintAreaForceOffDone(fcanvas);
      $.don_loader_end();
      $.alert('마스크 저장 완료');
    } catch (e) {
      console.error(e);
      $.alert('실패');
    }
  }),
  $.on3('click', '.cylinder_editor .make_cv_mask2', async (e) => {
    try {
      $.don_loader_start();
      const fcanvas = getCurrentFcanvas();
      cvMask1CvMask2CvPrintAreaForceOff(fcanvas);
      const cv_print_area_for_mask = await makeCvPrintAreaForMask(fcanvas);
      cv_print_area_for_mask.visible = true;
      await go(
        extractUrlSpecificCvObjBySize770(fcanvas, cv_print_area_for_mask),
        async (todataurl) => {
          const canvas = await makeCanvasByUrl(todataurl);
          const imgdata = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);

          const canvas2 = createCanvasElement(canvas);

          const ctx2 = canvas2.getContext('2d');
          for (let y = 0; y < canvas.height; y++) {
            for (let x = 0; x < canvas.width; x++) {
              const idx = (x + y * canvas.width) * 4;
              if (imgdata.data[idx + 3] > 0) imgdata.data[idx + 3] = 255;
            }
          }
          ctx2.putImageData(imgdata, 0, 0);
          return canvas2.toDataURL();
        },
        (todataurl) => $.uploadFileToUrl(todataurl, 'mask_url'),
        sel('url'),
        async (url) => {
          await axios.post('/@api/base_product_color_faces/update', {
            values: { mask2_url: url },
            condition: { base_product_id: box().maker.product_color.base_product_id },
          });
          getCurrentBpfInMaker().mask2_url = url;
        },
      );

      cv_print_area_for_mask.remove();
      removeCvPreview(fcanvas, true, true);
      fcanvas.renderAll();
      renderFacesByCurrentBpcfsOnlySizeInMakerA();
      cvMask1CvMask2CvPrintAreaForceOffDone(fcanvas);
      $.don_loader_end();
      $.alert('마스크 2 저장 완료');
    } catch (e) {
      $.alert('실패');
    }
  }),
  $.on3('click', '.cylinder_editor .make_cylinder_shape', async (e) => {
    $.don_loader_start();
    const fcanvas = getCurrentFcanvas();
    cvMask1CvMask2CvPrintAreaForceOff(fcanvas);
    const cv_print_area_for_mask = await addCvMoireToFcanvas(fcanvas);
    const cv_preview = getCvObj(fcanvas._objects, 'cv_preview');
    const mockup_url = await extractUrlSpecificCvObjBySize770(fcanvas, cv_preview);
    cv_print_area_for_mask.remove();
    removeCvPreview(fcanvas, true, true);
    fcanvas.renderAll();
    $.don_loader_end();
    G.mp.maker.download(mockup_url, '원형 틀');
    cvMask1CvMask2CvPrintAreaForceOffDone(fcanvas);
  }),
);
