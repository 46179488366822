import { $find } from 'fxdom/es';
import { VectorEditorStickerGridCreatorPCCopyGridF } from '../../CopyGrid/F/Function/module/VectorEditorStickerGridCreatorPCCopyGridF.js';

export const handleTopMenuDeleteClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_selected_grid_id == null) {
      return;
    }
    const grid_id = tab_el.__mp_selected_grid_id;

    tab_el.__mp_grid_sticker_editor?.unsetTargetImage?.(grid_id);
  };

export const handleTopMenuDuplicateClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_grid_sticker_editor == null) {
      return;
    }
    const grid_sticker_editor = tab_el.__mp_grid_sticker_editor;

    const right_wrapper_el = $find(`.right_container .right_wrapper`)(tab_el);
    if (right_wrapper_el == null) {
      return;
    }

    const {
      top: frame_position_top,
      left: frame_position_left,
      width: frame_position_width,
      height: frame_position_height,
    } = right_wrapper_el.getBoundingClientRect?.() ?? {};
    if (frame_position_top == null) {
      return;
    }
    if (frame_position_left == null) {
      return;
    }
    if (frame_position_width == null) {
      return;
    }
    if (frame_position_height == null) {
      return;
    }

    return VectorEditorStickerGridCreatorPCCopyGridF.copyGrid({
      frame_position: {
        top: frame_position_top,
        left: frame_position_left,
        width: frame_position_width,
        height: frame_position_height,
      },
      prev_frame_right_panel: right_wrapper_el,
      grid_sticker_editor,
      grids: tab_el.__mp_grids,
    });
  };
