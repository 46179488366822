import { LAYER_NAME } from './Constant/constants.js';
import { TOOL_NAMES } from '../F/Lib/state.js';

export const guard = {
  checkToolName: ({ tool_name }) => {
    if (!TOOL_NAMES.includes(tool_name)) return `툴 이름이 존재하지 않습니다. ${tool_name}`;
  },
  checkImgSrc: ({ img_src }) => {
    if (img_src == null) return '이미지 에디터에서 편집할 image source 가 없습니다.';
  },
  checkRasterExist: ({ raster }) => {
    if (raster == null) return 'Raster 이미지가 존재하지 않습니다.';
  },

  checkPrintableFileInfoExist: ({ printable_file_info }) => {
    if (printable_file_info == null) {
      return 'printable 파일 정보가 존재하지 않습니다.';
    }
  },
  isLayerIsNotDefaultToolLayer: ({ layer }) => {
    if (layer == null) return 'layer 가 존재하지 않습니다.';
    if (layer.name === LAYER_NAME.tool) return 'Tool layer 에는 tool 외의 item 을 insert 할 수 없습니다.';
  },
  checkAllowedEventType: ({ type }) => {
    if (
      ![
        'mousedown',
        'mouseup',
        'mousedrag',
        'mousemove',
        'keydown',
        'keyup',
        'activate',
        'deactivate',
      ].includes(type)
    )
      return `지원되지 않은 tool event type 입니다. ${type} `;
  },
};
