import { $attr, $closest, $delegate, $el, $find, $on, $qs, $replaceWith } from 'fxdom/es';
import { go } from 'fxjs/es';
import { OMPCoreUtilS } from '../../../Util/S/Function/module/OMPCoreUtilS.js';
import { OMPCoreCellPaginatedTableF } from '../../PaginatedTable/F/Function/module/OMPCoreCellPaginatedTableF.js';
import { getPageArrowType } from '../../PaginatedTable/F/Function/pageArrow.js';
import { OMPCoreCellTmplS } from '../../S/Tmpl/module/OMPCoreCellTmplS.js';

export const renderPaginatedTablePage = ({
  selector = '.omp-cell__table-wrapper',
  total_page,
  current_page,
  body_data: { data, columns, empty_notice, merge_column_key },
}) => {
  const $new_table_body = $el(
    OMPCoreCellTmplS.paginatedTableBodyTmpl({ data, columns, empty_notice, merge_column_key }),
  );
  const $new_table_page = $el(OMPCoreCellTmplS.paginatedTablePageTmpl({ total_page, current_page }));
  const $table_wrapper = $qs(selector);

  go($table_wrapper, $find('tbody'), $replaceWith($new_table_body));
  go($table_wrapper, $find('.omp-cell__table-pages'), $replaceWith($new_table_page));
};

export const paginatedTablePaginationDelegate = ({
  selector = '.omp-cell__table-wrapper',
  callback = () => {},
} = {}) => {
  return (el) => {
    // 마우스, 브라우저의 뒤로가기, 앞으로가기 버튼 클릭시 페이지 변경
    $on('popstate', (e) => {
      const { offset, limit } = e.state;
      const page_change_event = OMPCoreCellPaginatedTableF.makePageChangeEvent({ offset, limit });
      el.dispatchEvent(page_change_event);
    })(window);

    return go(
      el,
      $on('page-change', (e) => {
        e.state = e.detail;
        return callback(e);
      }),
      $delegate('click', `${selector} .omp-cell__table-page`, (e) => {
        const $page_wrap = $closest('.omp-cell__table-pages', e.currentTarget);
        const current_offset = OMPCoreUtilS.safeParseInt($page_wrap.dataset?.offset); // 현재 페이지의 offset
        const selected_offset = OMPCoreUtilS.safeParseInt(e.currentTarget.dataset?.offset); // 선택한 페이지의 offset
        const limit = OMPCoreUtilS.safeParseInt(e.currentTarget.dataset?.limit) || 10;

        // 현재의 페이지를 선택했을 경우 종료
        if (current_offset === selected_offset) {
          return;
        }
        const fetch_option = { offset: selected_offset, limit };
        const page_change_event = OMPCoreCellPaginatedTableF.makePageChangeEvent(fetch_option);
        OMPCoreCellPaginatedTableF.updateOffsetAndLimitOfLocationSearch(fetch_option);
        e.currentTarget.dispatchEvent(page_change_event);

        return callback(e);
      }),
      $delegate('click', `${selector} .omp-cell__table-page-buttons span`, (e) => {
        const is_disabled = $attr('disabled', e.currentTarget) === 'true';
        if (is_disabled) {
          return;
        }

        const page_arrow_type = getPageArrowType(e.currentTarget);
        if (!page_arrow_type) {
          throw new Error('OMP 테이블 페이지네이션 오류');
        }

        const fetch_option = {
          offset: OMPCoreUtilS.safeParseInt(e.currentTarget.dataset?.offset),
          limit: OMPCoreUtilS.safeParseInt(e.currentTarget.dataset?.limit),
        };
        e.currentTarget.dispatchEvent(
          OMPCoreCellPaginatedTableF.makePageArrowClickEvent(page_arrow_type, fetch_option),
        );
        e.currentTarget.dispatchEvent(OMPCoreCellPaginatedTableF.makePageChangeEvent(fetch_option));
        OMPCoreCellPaginatedTableF.updateOffsetAndLimitOfLocationSearch(fetch_option);

        return callback(e);
      }),
    );
  };
};
