import { go } from 'fxjs/es';
import { $closest, $data, $delegate, $find } from 'fxdom/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { DfInhouseF } from '../../../../Inhouse/F/Function/module/DfInhouseF.js';
import { alertsLgl } from '../../../F/Function/alerts.js';
import { DfLglF } from '../../../F/Function/module/DfLglF.js';
import { DfLglOutboundProductListMuiF } from '../../../OutboundProductList/F/Mui/module/DfLglOutboundProductListMuiF.js';
import { DFLglShippingDetailMuiS } from '../../S/Mui/module/DFLglShippingDetailMuiS.js';
import { loadProductsImage } from '../Function/item.js';
import { DFLglShippingDetailF } from '../Function/module/DFLglShippingDetailF.js';
import { showTabContent } from '../Function/tab.js';
import { handleCheckAllItemsInQueueTable, handleResizeTableHeader } from '../Function/table.event.js';

export function base_tab(tab_name) {
  const mui = DFLglShippingDetailMuiS;
  // const tag = mui[tab_name].tag;
  return {
    ...mui[tab_name],
    async makeData() {},
    appending(el_tab) {},
    appended(el_tab) {},
    showing(el_tab) {},
    showed(el_tab) {},
    infinite(el_tab) {},
    async rendered(el_tab) {
      showTabContent({ el: el_tab });
      const tab_wrapper = go(el_tab, $find('.tab_wrapper'));
      go(
        tab_wrapper,
        $delegate('click', '.waybill', DfLglF.handleLglWaybill),
        $delegate('click', '.tab_item', DfLglF.handleTabChange),
        $delegate('click', '.delete_waybill', DfLglF.handleDeleteLglWaybill),
        $delegate('click', '.packing_complete', DFLglShippingDetailF.handleCompletePackaging),
        $delegate('click', '.packing_edit', DFLglShippingDetailF.handleEditPackaging),
        $delegate('click', '.request_inbound', DFLglShippingDetailF.handleRequestInbound),
        $delegate('click', '.cancel_request_inbound', DFLglShippingDetailF.handleCancelRequestInbound),
        $delegate('click', '.tracking_inbound', DFLglShippingDetailF.handleTrackingInbound),
        $delegate(
          'click',
          '.table_wrapper table thead input[type="checkbox"]',
          DFLglShippingDetailF.handleHeaderCheckboxChanged,
        ),
        $delegate(
          'click',
          '.table_wrapper table tbody input[type="checkbox"]',
          DFLglShippingDetailF.handleCheckboxChanged,
        ),
        $delegate('keydown', '.move_quantity', DFLglShippingDetailF.handleMoveQuantityOnlyNumber),
        $delegate('keyup', '.move_quantity', DFLglShippingDetailF.handleMoveQuantityUnderMaxValue),
        $delegate(
          'click',
          '.input_number_wrapper button',
          DFLglShippingDetailF.handleMoveQuantityButtonClick,
        ),
        $delegate('click', '.return_request_inbound', DFLglShippingDetailF.handleRequestReturn),
        $delegate('click', '.inspection_detail', DFLglShippingDetailF.handleOpenInspectionDetail),
        $delegate(
          'click',
          '.shipout_from_lgl_detail',
          DFLglShippingDetailF.handleOpenOutboundProductListDetail,
        ),
        $delegate('click', 'button.return_detail', DFLglShippingDetailF.handleOpenReturnDetail),
        $delegate('click', '.transfer_tool .left', DFLglShippingDetailF.handleMoveItemToQueue),
        $delegate('click', '.transfer_tool .right', DFLglShippingDetailF.handleMoveItemToWaybill),
        $delegate('click', '.tab_item', ({ currentTarget: ct }) => {
          const tab_inbound = go(ct, $closest('.don_tab[tab_name="inbound"]'));
          const tab_packaging = go(ct, $closest('.don_tab[tab_name="packaging'));
          const tab_rebound = go(ct, $closest('.don_tab[tab_name="rebound'));
          if (tab_inbound) {
            DFLglShippingDetailF.handleChangeInboundTab({ currentTarget: ct });
          } else if (tab_packaging) {
            DFLglShippingDetailF.handleChangePackagingTab({ currentTarget: ct });
          } else if (tab_rebound) {
            DFLglShippingDetailF.handleChangeReboundTab({ currentTarget: ct });
          }
        }),
        $delegate('click', '.rebound_waybill', DfLglF.handleReboundWaybill),
        $delegate('click', '.delete_rebound_waybill', DfLglF.handleDeleteReboundWaybill),
        $delegate('click', '.rebound_packing_complete', DFLglShippingDetailF.handleCompleteReboundWaybill),
        $delegate('click', '.rebound_packing_edit', DFLglShippingDetailF.handleEditRebounWaybill),
        $delegate('click', '.request_rebound', DFLglShippingDetailF.handleRequestRebound),
        $delegate('click', '.cancel_request_rebound', DFLglShippingDetailF.handleCancelRequestRebound),
        $delegate('change', '.auto_request_outbound', DFLglShippingDetailF.handleChangeAutoRequestOutbound),
        $delegate('click', '.request_outbound', DFLglShippingDetailF.handleRequestOutbound),
        $delegate('click', '.cancel_request_outbound', DFLglShippingDetailF.handleCancelRequestOutbound),
        $delegate('click', '.show_all_outbound', async ({ currentTarget: ct }) => {
          try {
            DfInhouseF.lottie_loader.start('dots', null, null, null);
            await MuiF.openFrame(DfLglOutboundProductListMuiF.frame, async (frame, page, [tab]) => {
              const data = go(ct, $closest('.tab_wrapper'), $data);
              tab.makeData = () => ({ data, base: 'projection' });
            });
          } catch (err) {
            console.error(err);
            await alertsLgl.error({ err });
          } finally {
            DfInhouseF.lottie_loader.end();
          }
        }),
        $delegate('click', '.outbound_detail', DFLglShippingDetailF.handleOpenOutboundDetail),
      );

      loadProductsImage(el_tab);

      // 상품 포장 탭 화면이 렌더링되었을 때
      // 대기열 주문 상품 목록을 전체 선택
      handleCheckAllItemsInQueueTable({ el_tab });

      handleResizeTableHeader({ el: el_tab });
    },
    hiding(el_tab, v) {},
    hided(el_tab, v) {},
    removing(el_tab, v) {},
    removed(el_tab, v) {},
  };
}
