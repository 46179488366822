import { pipe } from 'fxjs/es';

export const defnHelpListTab = () => ({
  tab_name: 'mp.help_center_tab',
  appended: pipe(
    $.on('click', '.back', function (e) {
      window.location.href = '/help_center?type=' + G.$location().query.type;
    }),
    $.on('change', '.help_group_type select', function (e) {
      const val = $.val($.find1($.closest(e.currentTarget, '.help_group_type'), 'option:checked'));
      if (G.$location().query.type == val) return;
      window.location.href = '/help_center?type=' + val;
    }),
    $.on('click', '.help_item .question', function (e) {
      const item = $.closest(e.currentTarget, '.help_item');

      if ($.attr(item, 'selected') == true) {
        $.attr(item, 'selected', false);
        return;
      }
      $.attr(item, 'selected', true);
      $.scroll_top2($('html, body'), e.currentTarget.offsetTop - 100 || 0, 400);

      window.history.replaceState(
        window.history.state,
        '',
        '/help_center?type=' +
          G.$location().query.type +
          '&hg_id=' +
          box.sel(item).help_group_id +
          '&hp_id=' +
          box.sel(item).id,
      );
    }),
  ),
  showed: function (tab) {
    const selected_item = $.find1(tab, '.help_item[selected="true"]');
    if (!selected_item) return;

    setTimeout(function () {
      $.scroll_top2($('html, body'), selected_item.offsetTop - 100 || 0, 400);
    }, 300);
  },
});
