import { each, go, isNil, mapL, equals2, join, flatL, defaultTo } from 'fxjs/es';
import { $findAll, $setHTML } from 'fxdom/es';
import { pathOffsets } from '@marpple/stickerizer';
import { $$parsePathDateL } from 'fxsvg';

export const makeCuttingLine = async ({ tab_el, offset }) => {
  await new Promise((resolve) => setTimeout(resolve, 60));
  const offset_cutting_line = await pathOffsets(
    tab_el.__mp_base_cutting_line.paper_obj,
    offset * tab_el.__mp_factor,
  );
  const path_data = go(
    offset_cutting_line.path_data,
    $$parsePathDateL,
    mapL(({ command, parameters }) => {
      if (equals2('M')(command) || equals2('L')(command)) {
        return go(
          parameters,
          mapL((a) => a / tab_el.__mp_factor),
          join(' '),
          (a) => `${command} ${a}`,
        );
      }

      if (equals2('Q')(command) || equals2('C')(command)) {
        return go(
          parameters,
          flatL,
          mapL((a) => a / tab_el.__mp_factor),
          join(' '),
          (a) => `${command} ${a}`,
        );
      }

      if (equals2('A')(command)) {
        return `A ${parameters[0] / tab_el.__mp_factor} ${parameters[1] / tab_el.__mp_factor} ${
          parameters[2]
        } ${parameters[3]} ${parameters[4]} ${parameters[5] / tab_el.__mp_factor} ${
          parameters[6] / tab_el.__mp_factor
        }`;
      }

      if (equals2('Z')(command)) {
        return 'Z';
      }

      throw new Error(`잘못된 패스 정보입니다. (command: ${command})`);
    }),
    join(' '),
    defaultTo(''),
  );

  const m_count = path_data.match(/[mM]/g)?.length ?? 0;
  if (m_count <= 0) {
    const error = new Error(`Invalid path data. There is no command "M".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::Single::Mobile::CuttingLineAuto::error_message::cutting_line_making_error',
    );
    throw error;
  }
  if (m_count > 1) {
    const error = new Error(`Invalid path data. There are multiple command "M"s.`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::Single::Mobile::CuttingLineAuto::error_message::cutting_line_making_error',
    );
    throw error;
  }

  const z_count = path_data.match(/[zZ]/g)?.length ?? 0;
  if (z_count <= 0) {
    const error = new Error(`Invalid path data. There is no command "Z".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::Single::Mobile::CuttingLineAuto::error_message::cutting_line_making_error',
    );
    throw error;
  }
  if (z_count > 1) {
    const error = new Error(`Invalid path data. There are multiple command "Z"s.`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::Single::Mobile::CuttingLineAuto::error_message::cutting_line_making_error',
    );
    throw error;
  }

  if (!/^[Mm]/.test(path_data)) {
    const error = new Error(`Invalid path data. Not started with command "M".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::Single::Mobile::CuttingLineAuto::error_message::cutting_line_making_error',
    );
    throw error;
  }

  if (!/[Zz]$/.test(path_data)) {
    const error = new Error(`Invalid path data. Not ended with command "M".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::Single::Mobile::CuttingLineAuto::error_message::cutting_line_making_error',
    );
    throw error;
  }

  return path_data;
};

export const getCuttingLine = ({ tab_el, index }) => {
  const cutting_line = tab_el.__mp_cutting_lines[index];
  if (!isNil(cutting_line)) {
    return cutting_line;
  }

  if (index < 0) {
    return getCuttingLine({ tab_el, index: 0 });
  }

  if (index > tab_el.__mp_cutting_lines.length - 1) {
    return getCuttingLine({ tab_el, index: tab_el.__mp_cutting_lines.length - 1 });
  }

  return (async () => {
    const offset = tab_el.__mp_offsets[index];
    const path_data = await makeCuttingLine({ tab_el, offset });
    const cutting_line = {
      index,
      label: `${offset}`,
      path_data,
    };
    tab_el.__mp_cutting_lines[index] = cutting_line;
    return cutting_line;
  })();
};

export const setCuttingLine = ({ tab_el, label, path_data }) => {
  tab_el.__mp_cutting_line_editor.setCuttingLinePathData(path_data);
  go(tab_el, $findAll(`.offset_control .offset`), each($setHTML(label)));
};
