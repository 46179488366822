import { go, html, maxBy, range, sel, strMap, uniqueBy, values } from 'fxjs/es';
import { DfInhouseConstantS } from '../Constant/module/DfInhouseConstantS.js';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import format from 'date-fns/format/index.js';
import $dataStr from 'fxdom/es/dataStr.js';

export function inhouseShippingPage({
  initial_search_dates_for_buckets: { start_date, end_date },
  [DfInhouseConstantS.gBox.buckets]: buckets,
  [DfInhouseConstantS.gBox.crew_inhouses]: shippings,
}) {
  const { CREATE, DELETE, REPRINT_WAYBILL, COMPLETE } = DfInhouseConstantS.BUCKET_CONTROL_NAME;
  return html`
    <script type="text/javascript" src="https://oss.sheetjs.com/sheetjs/xlsx.full.min.js"></script>
    <div class="inhouse-shipment" _sel="${DfInhouseConstantS.gBox.rootKey}">
      <h1>사내 배송 관리</h1>
      ${getBucketOverallStatuses()}

      <section class="controllers">
        <div class="containers half">
          <div class="container bucket-search">
            <p class="title">버킷 조회</p>
            <div class="items">
              <!-- 버킷 기간 조회 -->
              <div class="item dates">
                <label>기간</label>
                <div class="buttons">
                  <input
                    type="date"
                    class="date-picker"
                    data-start_date="${start_date}"
                    data-end_date="${end_date}"
                  />
                  <button class="reset" name="${DfInhouseConstantS.SEARCH_BUTTON_NAME.date_reset}"></button>
                  <button class="search" name="${DfInhouseConstantS.SEARCH_BUTTON_NAME.date_range}"></button>
                </div>
              </div>
              <div class="item dates">
                <label>주문#</label>
                <div class="buttons">
                  <input
                    type="text"
                    class="search projection"
                    oninput="this.value =
                  this.value.replace(/[^0-9.]/g, '').replace(/^0+/, '')"
                  />
                  <button
                    class="search"
                    name="${DfInhouseConstantS.SEARCH_BUTTON_NAME.projection_search}"
                  ></button>
                </div>
              </div>
              <div class="item dates">
                <label>운송장#</label>
                <div class="buttons">
                  <input
                    type="text"
                    class="search waybill"
                    oninput="(() => {
                      const regexPattern = new RegExp(\`.{1,${4}}\`, 'g');
                      const input_str = this.value.replace(/[^0-9.]/g, '');
                      const parts = input_str.match(regexPattern);
                      this.value = parts ? parts.join('-') : input_str
                  })()"
                  />
                  <button
                    class="search"
                    name="${DfInhouseConstantS.SEARCH_BUTTON_NAME.waybill_search}"
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <div class="container bucket-control">
            <p class="title">버킷 제어</p>
            <div class="items">
              <!-- 운송장 출력 - 버킷 생성/삭제 -->
              <div class="item waybill">
                <label>버킷 박스</label>
                <div class="buttons">
                  ${strMap(
                    (control) => html` <button name="${control.key}">${control.name}</button> `,
                    [CREATE, REPRINT_WAYBILL, DELETE, COMPLETE],
                  )}
                </div>
              </div>
              <div class="item mode">
                <label>주문 박스</label>
                <div class="buttons">
                  <button class="selected" name="${DfInhouseConstantS.BUCKET_MODE_KEY.PACK.key}">
                    담기 모드
                  </button>
                  <button name="${DfInhouseConstantS.BUCKET_MODE_KEY.UNPACK.key}">제거 모드</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- 버킷 대시보드 bucket entity -->
      <section class="buckets" _sel="./${DfInhouseConstantS.gBox.buckets}">
        ${makeBucketEntitiesHtml({ buckets })}
      </section>

      <!-- 주문 박스 정보 테이블 -->
      <section class="box-table">
        <div class="don_loader_wrap">
          <div class="don_loader_img"></div>
        </div>
        <div class="table" id="${DfInhouseConstantS.TABLE.ID}"></div>
      </section>
    </div>
  `;
}

export function makeBucketEntitiesHtml({ buckets, selected_bucket_id }) {
  return html` <div class="dashboard">
    ${go(
      buckets,
      zipWithIndex,
      strMap(([i, bucket]) =>
        makeBucketEntityHtml({
          bucket,
          selected: selected_bucket_id ? bucket.id === selected_bucket_id : i === 0,
        }),
      ),
    )}
  </div>`;
}

export function makeBucketEntityHtml({ bucket, is_hydrating = false, selected }) {
  const {
    id: bucket_id,
    // crew_inhouse_shipping_id,
    waybill_no,
    is_packed,
    _: {
      crew_inhouse_shipping: { company_label, location_name },
      inhouse_shipping_boxes,
    },
  } = bucket;

  const { status: bucket_status, status_name, time: bucket_status_time_at } = getBucketStatus({ bucket });
  const box_ctn = inhouse_shipping_boxes.length;

  return html`
    <div
      class="bucket-entity ${selected ? 'selected' : ''}"
      _id="${bucket_id}"
      _sel="./(#${bucket_id})"
      is_packed="${is_packed}"
      hydrating="${is_hydrating}"
      waybill_no="${waybill_no}"
    >
      <div class="boxes" style="display:none;" _sel="./_->inhouse_shipping_boxes"></div>
      <p class="company-label">${company_label}</p>
      <button class="location-name" _sel="./_->crew_inhouse_shipping">${location_name}</button>
      <button class="waybill-no" _sel="./waybill_no">
        ${UtilStringS.insertHyphens({ input_str: waybill_no, chunk_size: 4 })}
      </button>
      <div class="bucket-image" status="${getBucketStatusClassName({ is_packed, box_ctn })}"></div>
      <div class="bucket-status" status="${bucket_status}">
        ${shippingStatusHtml({ projection_status: bucket_status })}
      </div>
      <div class="status-description">${status_name} ${formatDateForStatus(bucket_status_time_at)}</div>
      <div class="description">
        <p class="box-counter">${getBoxCtnDescription({ box_ctn })}</p>
      </div>
      <button class="print-bucket-item"></button>
    </div>
  `;
}

function formatDateForStatus(date) {
  return format(new Date(date), 'MM/dd HH:mm');
}

function getBucketOverallStatuses() {
  return html`
    <div class="bucket-statuses-info">
      ${go(
        DfInhouseConstantS.SHIPPING_STATUSES,
        values,
        uniqueBy(({ step }) => step),
        strMap(({ name, step }) => {
          return html`
            <div class="status-item">
              <div class="dot" step="${step}"></div>
              <div class="status-name">${name}</div>
            </div>
          `;
        }),
      )}
    </div>
  `;
}

const bucket_status_name = {
  before_deliver: '배송 전',
  delivering: '배송 중',
  before_confirm: '배송 완료',
  confirmed: '구매 확정',
};

// @description 버킷의 배송 상태 - is_packed 전이면 "배송전", 패킹 후에는 모든 box projection 의 status 운명 공동체 이므로 첫번째 projection.status 를 따름
function getBucketStatus({ bucket }) {
  const {
    is_packed,
    packing_at,
    packed_at,
    _: { inhouse_shipping_boxes },
  } = bucket;

  // 포장 전
  if (!is_packed) {
    return { status: 'before_deliver', status_name: '배송 전', time: packing_at };
  }

  if (UtilArrayS.isArrayOk(inhouse_shipping_boxes)) {
    // 박스가 담긴 버킷 포장 완료 상태
    const head_projection = inhouse_shipping_boxes[0]._.projection;
    const status = head_projection.status;

    if (['before_print', 'printing', 'before_deliver'].includes(status)) {
      return { status: 'delivering', status_name: bucket_status_name.delivering, time: packed_at };
    } else {
      return { status, status_name: bucket_status_name[status], time: head_projection?.[`${status}_at`] };
    }
  } else {
    // 빈 박스 버킷 포장 완료 상태
    return { status: 'confirmed', status_name: bucket_status_name.before_confirm, time: packed_at };
  }
}

export function getBoxCtnDescription({ box_ctn }) {
  if (box_ctn === 0) return '비었음';
  if (box_ctn > 0) return `${box_ctn} 개 주문 담김`;
}

export function getBucketStatusClassName({ is_packed, box_ctn }) {
  const criteria_for_many = 5;
  if (is_packed) return 'PACKED';
  if (box_ctn === 0) return 'INITIAL';
  if (box_ctn <= criteria_for_many) return 'PACKING-LITTLE';
  else return 'PACKING-MANY';
}

export function shippingStatusHtml({ projection_status, projection }) {
  if (projection_status == null) return '';

  const overall_shipping_statuses = DfInhouseConstantS.SHIPPING_STATUSES;
  const current_status = overall_shipping_statuses[projection_status];

  if (current_status == null) return '';

  const { step: current_projection_status_step } = current_status;

  const last_step_no = go(
    overall_shipping_statuses,
    values,
    maxBy(({ step }) => step),
    sel('step'),
  );

  let status_ats;

  if (projection) {
    const {
      ordered_at,
      before_print_at,
      printing_at,
      before_deliver_at,
      delivering_at,
      before_confirm_at,
      confirmed_at,
      canceled_at,
    } = projection;

    status_ats = {
      ordered_at,
      before_print_at,
      printing_at,
      before_deliver_at,
      delivering_at,
      before_confirm_at,
      confirmed_at,
      canceled_at,
    };
  }

  return html`
    <div
      class="status-bar"
      step="${current_projection_status_step}"
      data-projection_status="${projection_status}"
      data-fx-json="${$dataStr(status_ats ?? {})}"
    >
      ${go(
        range(last_step_no),
        strMap((idx) => {
          const step_no = idx + 1;
          return html`
            <div class="status-step">
              <div
                class="dot"
                step="${step_no}"
                is_done="${Number(step_no) <= Number(current_projection_status_step)}"
              ></div>
            </div>
          `;
        }),
      )}
    </div>
  `;
}
