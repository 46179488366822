import { extend } from 'fxjs/es';

export function setBGImageSizeLocationBySf(sf) {
  const { width, height } = sf.img;

  return {
    width,
    height,
    top: (G.mp.maker.CANVAS_WIDTH_ORIGIN - height) / 2 - sf.img.diff,
    left: (G.mp.maker.CANVAS_WIDTH_ORIGIN - width) / 2,
  };
}

export function resetBgWithoutCvMaskBySf(cv_background, sf) {
  extend(cv_background, setBGImageSizeLocationBySf(sf));
  G.mp.maker.nscreen(cv_background);
  return cv_background;
}

function getChangedSf(img, sf, cv_bpcf_height) {
  if (cv_bpcf_height === img.height) return sf.img.height;
  const ratio = sf.img.width / img.width;
  const diff_height = img.height - img.width;

  return sf.img.height + diff_height * ratio;
}

export function resetCvMaskBySf(cv_background, sf) {
  if (!cv_background) return;
  if (!cv_background._element) return;
  const cv_bpcf_height = G.mp.maker.cv_bpcf(cv_background.canvas)._element.height;
  const sf_height = getChangedSf(cv_background._element, sf, cv_bpcf_height);
  cv_background.width = sf.img.width;
  cv_background.height = sf_height;
  cv_background.top = (G.mp.maker.CANVAS_WIDTH_ORIGIN - cv_background.height) / 2;
  cv_background.left = (G.mp.maker.CANVAS_WIDTH_ORIGIN - cv_background.width) / 2;
  cv_background.top = cv_background.top - sf.img.diff;
  G.mp.maker.nscreen(cv_background);
  return cv_background;
}
