import { hasVideoUrl } from '../CvBpcfVideo/fs.js';
import { resetCvMaskBySf } from '../SizeFaces/fs.js';
import { go, html } from 'fxjs/es';
import { $addClass, $appendTo, $el, $qs, $removeClass } from 'fxdom/es';
import { makeCanvasByUrl } from '../../../Canvas/S/util.js';
import { makeCanvasTextured } from '../cylinder.js';
import { makeImageFromUrl } from '../util.js';

export function makeCvMask2InMakerA(canvas, bpcf_mask2_url, sf) {
  return new Promise(function (resolve) {
    const last_cv_mask2 = G.mp.maker.cv_mask2(canvas);
    if (last_cv_mask2) canvas.remove(last_cv_mask2);
    if (hasVideoUrl()) bpcf_mask2_url = null;
    fabric.Image.fromURL(G.mp.maker.url(bpcf_mask2_url), function (cv_mask2) {
      cv_mask2.set({
        selectable: false,
        evented: false,
        visible: false,
        globalCompositeOperation: 'destination-in',
        _data: { cv_type: 'cv_mask2', is_not_design: true },
      });
      canvas.add(cv_mask2);
      resetCvMaskBySf(cv_mask2, sf);
      canvas.renderAll();
      // addImgLineInFabricCanvas(canvas, cv_mask2);
      resolve();
    });
  });
}
export function cvMask2Event() {
  if ($qs('.show_cv_mask2.circle_icon')) return;
  go(
    html`
      <li class="show_cv_mask2 circle_icon">
        <div class="icon"></div>
        <div class="text">마스크2 보기</div>
      </li>
    `,
    $el,
    $appendTo($qs('.decoration_menu_for_pc')),
    $.on('click', async function () {
      const maker_frame_el = $qs('#maker_frame');
      if ($.has_class(maker_frame_el, 'show_cv_mask2')) {
        $removeClass('show_cv_mask2')($1('#maker_frame'));
        const canvas = G.mp.maker.editing_canvas();
        const cv_mask1 = G.mp.maker.cv_mask2(canvas);
        cv_mask1.globalCompositeOperation = 'destination-in';
        canvas.renderAll();
      } else {
        $addClass('show_cv_mask2')(maker_frame_el);
        const canvas = G.mp.maker.editing_canvas();
        const cv_mask2 = G.mp.maker.cv_mask2(canvas);
        if (!cv_mask2._element) return;
        cv_mask2._element = await go(makeCanvasByUrl(cv_mask2._element.src), (c) => {
          const canvas = makeCanvasTextured(c, '#32ff32');
          return makeImageFromUrl(canvas.toDataURL());
        });
        cv_mask2.globalCompositeOperation = 'source-over';
        canvas.renderAll();
        canvas.renderAll();
      }
    }),
  );
}
