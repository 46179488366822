import { last, go, reject } from 'fxjs/es';
import { changeCvObjectToImgSrc } from '../../../../../modules/Maker/F/draw_product_faces.js';
import { getCvImage } from '../../../../../modules/Maker/F/CvImage/fs.js';
import { NewMakerCvObjectCvImageF } from '../../../../../modules/NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';

!(function (LF) {
  G.df.projection = { pc_preview: {} };

  G.df.projection.pc_preview.frame = function (
    product_color,
    user_product,
    is_printable,
    size_name,
    face_idx,
    design_idx,
  ) {
    fabric.charWidthsCache = {};
    $.frame.open(
      {
        frame_name: 'df.projection.pc_preview',
      },
      {
        tabs: [
          {
            tab_name: 'pc_preview',
            data_func: _p.c(_p.mr(product_color, user_product)),
            face_idx: face_idx,
            design_idx: design_idx,
            is_printable: is_printable,
            size_name,
          },
        ],
      },
    );
  };

  $.frame.defn_frame({
    frame_name: 'df.projection.pc_preview',
    el_class: 'pc_preview df_grid',
    el_id: 'pc_preview',
    always_remove: true,
    page_name: 'df.projection.pc_preview',
    appended: _p.pipe(
      $.on('click', '.reverse', function (e) {
        $.toggle_class($.closest(e.delegateTarget, '.don_frame'), 'reversed');
      }),
    ),
  });

  function _objects(d) {
    return _p.flatten(d._objects ? d._objects.map((d) => _objects(d)) : [d]);
  }

  function _objects(d) {
    return _p.flatten(
      d._objects ? d._objects.map((d) => _objects(d)) : d.objects ? d.objects.map((d) => _objects(d)) : [d],
    );
  }

  const tmpl_design = function (width) {
    return async function (a) {
      return _p.go(
        a,
        G.mp.maker.reject_ai,
        reject((cv_obj) => cv_obj._data.clone_parent_cid),
        _p.filter((d) => d.visible),
        _p.map(async function (d) {
          const need_to_data_url = _p.contains(
            ['cv_text', 'cv_group', 'cv_pattern', 'cv_text_image', 'cv_text_image_pattern'],
            d._data.cv_type,
          );
          if (!need_to_data_url) {
            return {
              design: d,
              src: width > 300 ? G.to_600(d.src) : G.to_150(d.src),
              children: [],
            };
          } else {
            return _p.go(
              G.mp.maker.from_cv_attrs_for_maker(d, width > 300 ? 1 : 100 / (d.width * d.scaleX), true),
              function (design) {
                return _p.go(changeCvObjectToImgSrc(design, width, true), async function (src) {
                  return {
                    design: design,
                    src: src,
                    children: await _p.map(
                      d._data.cv_type == 'cv_group' ? G.mp.maker.reject_ai(_objects(d)) : [],
                      async function (d2) {
                        const need_to_data_url2 = _p.contains(
                          ['cv_text', 'cv_pattern', 'cv_text_image', 'cv_text_image_pattern'],
                          d2._data.cv_type,
                        );
                        if (!need_to_data_url2) {
                          return {
                            design: d2,
                            src: width > 300 ? G.to_600(d2.src) : G.to_150(d2.src),
                          };
                        } else {
                          return _p.go(
                            G.mp.maker.from_cv_attrs_for_maker(
                              d2,
                              width > 300 ? 1 : 100 / (d.width * d.scaleX),
                              true,
                            ),
                            async function (d2) {
                              return {
                                src: await changeCvObjectToImgSrc(d2, width, true),
                                design: d2,
                              };
                            },
                          );
                        }
                      },
                    ),
                  };
                });
              },
            );
          }
        }),
        _p.sum(
          (info, i) => pug`
          .view.design[_sel="./designs->${i}" idx="${i}" cv_type="${
            info.design._data.cv_type
          }" is_marpple_image="${!!info.design._data.is_marpple_image}" original_is_ai="${!!info.design._data
            .original_is_ai}" is_embro="${!!info.design._data.is_embro}" is_low_quality_agree="${!!info.design
            ._data.is_low_quality_agree}"]
            .row
              .img
                img[src="${info.src || ''}"]
              .type.row
                .name ${
                  info.design._data.is_embro
                    ? '패치 - ' + (info.design._data.name || '이름없음')
                    : info.design._data.is_marpple_image
                    ? '마플 - ' + (info.design._data.name || '이름없음')
                    : ''
                }
                .color ${info.design._data.press_color_name || '색없음'}
              .size_info
                .px ${go(getCvImage(info.design), (cv_image) => {
                  if (!cv_image)
                    return info.design._data.image_width + ' x ' + info.design._data.image_height + ' px';
                  return cv_image._data.image_width + ' x ' + cv_image._data.image_height + ' px';
                })}
                .cm ${info.design._data.width_cm} x ${info.design._data.height_cm} cm
                ${
                  !NewMakerCvObjectCvImageF.hasRequestBgRemoval(info.design)
                    ? ''
                    : '<div class="request_bg_removal">배경 지움</div>'
                }
                ${!info.design.flipX ? '' : `<div class="flip">좌우반전</div>`}
                ${!info.design.flipY ? '' : `<div class="flip">상하반전</div>`}
              .down
                button[type="button"].down 다운
                button[type="button"].original_down ${info.design._data.original_is_ai ? 'AI' : '원본'}
            .children
              ${_p.sum(
                info.children,
                (info) => pug`
                .design[cv_type="${info.design._data.cv_type}" is_marpple_image="${!!info.design._data
                  .is_marpple_image}" original_is_ai="${!!info.design._data
                  .original_is_ai}" is_embro="${!!info.design._data.is_embro}"]
                  .row
                    .img
                      img[src="${info.src || ''}"]
                    .type.row
                      .name ${
                        info.design._data.is_embro
                          ? '패치 - ' + (info.design._data.name || '이름없음')
                          : info.design._data.is_marpple_image
                          ? '마플 - ' + (info.design._data.name || '이름없음')
                          : ''
                      }
                      .color ${info.design._data.press_color_name || '색없음'}
                    .size_info.row
                      .px ${info.design._data.image_width} x ${info.design._data.image_height} px
                      ${
                        !NewMakerCvObjectCvImageF.hasRequestBgRemoval(info.design)
                          ? ''
                          : '<div class="request_bg_removal">배경 지움</div>'
                      }
                      ${!info.design.flipX ? '' : `<div class="flip">좌우반전</div>`}
                      ${!info.design.flipY ? '' : `<div class="flip">상하반전</div>`}
                    .down
                      button[type="button"].down 다운
                      button[type="button"].original_down ${info.design._data.original_is_ai ? 'AI' : '원본'}
              `,
              )}
        `,
        ),
      );
    };
  };

  const tmpl_printable = (printable_files, i) =>
    _p.go(
      printable_files,
      _p.sum(
        (file) => pug`
        .view.design[_sel="./designs->${i}" idx="${i++}" cv_type="printable"]
          .row
            .img
              img[src="${file.type === 'image/svg+xml' ? file.url : G.to_600(file.url)}"]
            .type.row
              .name
              .color ${file.press_type_name || '선택안됨'}
            .down
              a.download[href="${file.ai_url || file.url}" download="${file.name}" target="_blank"] 인쇄용 ${
          file.ai_url ? `${last(file.ai_url.split('.'))} ` : ''
        } 파일 다운로드
      `,
      ),
    );

  $.frame.defn_page({
    page_name: 'df.projection.pc_preview',
    tabs: [
      {
        tab_name: 'pc_preview',
        template: _p.pipe(
          _p.tap(function (product_color, user_product) {
            box.set('df/projection/pc_preview', {
              user_product: user_product,
              product_color: product_color,
            });
          }),
          _p.t(
            'product_color, user_product, size_name',
            '\
          .pc_preview.up_item[_sel="df/projection/pc_preview->user_product"]\
            .product_color[_sel="df/projection/pc_preview->product_color"].left\
              .product_face_list {{_.sum(product_color.product_faces2.value, ',
            _p.t(
              'pf, i',
              '\
                .view_list.product_face[idx={{i}} _sel="./product_faces2->value->{{i}}"]\
                  .view\
                    .img.canvas_860\
                      canvas\
                      .don_loader_wrap2\
                        .don_loader_img2\
                  {{_.go(pf.designs, ',
              tmpl_design(400),
              ')}}\
                  {{_.go(_.mr(pf.printable_files, _.v(pf, "designs.length")), ',
              tmpl_printable,
              ')}}\
              ',
            ),
            ')}}\
            .product_color[_sel="df/projection/pc_preview->product_color"].right\
              .product_face_list {{_.sum(product_color.product_faces2.value, ',
            _p.t(
              'pf, i',
              '\
                .view_list.product_face[idx={{i}} _sel="./product_faces2->value->{{i}}->designs"].designs\
                  .view\
                    .img.canvas_60\
                      canvas\
                      .don_loader_wrap2\
                        .don_loader_img2\
                    .infos\
                      sp.name {{pf.face_name}}\
                      button[type="button"].dci 합성 다운로드\
                      button[type="button"].resized_dci 합성 다운로드(resized)\
                      button[type="button"].print_area_down 프린트영역 다운로드\
                  {{_.go(pf.designs, ',
              tmpl_design(40, true),
              ')}}\
                  {{_.go(_.mr(pf.printable_files, _.v(pf, "designs.length")), ',
              tmpl_printable,
              ')}}\
              ',
            ),
            ')}}\
        ',
          ),
        ),
        appended: _p.pipe(
          $.on('click', '.right .view_list .view', function (e) {
            const view_list_idx = _go(e.currentTarget, $.closest('.view_list'), $.attr('idx'));
            const view_idx = _go(e.currentTarget, $.closest('.view_list'), $.find('.view')).indexOf(
              e.currentTarget,
            );
            _go(
              e.delegateTarget,
              $.find('.left .view.selected, .right .view.selected'),
              $.remove_class('selected'),
            );
            _go(
              e.delegateTarget,
              $.find(
                '.left .view_list[idx="' +
                  view_list_idx +
                  '"] .view:nth-child(' +
                  (view_idx + 1) +
                  '), .right .view_list[idx="' +
                  view_list_idx +
                  '"] .view:nth-child(' +
                  (view_idx + 1) +
                  ')',
              ),
              $.add_class('selected'),
            );
          }),
          _p.tap(function (el_don_tab) {
            _p.go(
              el_don_tab,
              $.closest('.don_frame.pc_preview'),
              $.find1('>.don_wrapper >.header >.title'),
              _p(
                $.text,
                _p,
                (el_don_tab.tab_opt.is_printable ? '인쇄용 시안 미리보기' : '고객 시안 미리보기') +
                  ` - ${el_don_tab.tab_opt.size_name}`,
              ),
            );

            $.append(
              _p.go(el_don_tab, $.closest('.don_frame.pc_preview'), $.find1('>.don_wrapper >.header')),
              $.el(pug`
                button[type="button"].reverse 반전
              `),
            );

            const face_idx = el_don_tab.tab_opt.face_idx;
            const design_idx = el_don_tab.tab_opt.design_idx;
            if (design_idx === undefined) {
              _p.go(
                el_don_tab,
                $.find1('.right .view_list[idx="' + face_idx + '"] .view:first-child'),
                $.trigger('click'),
              );
            } else {
              _p.go(
                el_don_tab,
                $.find1('.right .view_list[idx="' + face_idx + '"] .view.design[idx="' + design_idx + '"]'),
                $.trigger('click'),
              );
            }
          }),
          _tap(function (el_parent) {
            _go(el_parent, $.find('.product_color.left'), function (el_product_colors) {
              G.mp.maker.draw_product_face4(el_product_colors, true);
            });
            _go(el_parent, $.find('.product_color.right'), function (el_product_colors) {
              G.mp.maker.draw_product_face4(el_product_colors);
            });
          }),
          _p.f('G.df.projection.detail.dci_init'),
        ),
      },
    ],
  });
})({});
