import { DfWaybillF } from '../../../Waybill/F/Function/module/DfWaybillF.js';
import { LGL_SHIPPING_INFO } from './waybill.format.js';
import { go, zip, map } from 'fxjs/es';

export async function printLglWaybill({ projection_id }) {
  const receivers = [LGL_SHIPPING_INFO];
  const goods_name = await DfWaybillF.getUserProductsFromProjection(projection_id);

  const { process_statuses, waybill_label_datas } = await DfWaybillF.waybillGaraGo({
    receivers,
    additional_label_datas: {
      goods_name,
    },
    orderer_name: '마플 해외배송 담당지',
    allowed_devices: ['ZD420', 'ZD421'],
    is_print_prj_qr: true,
    is_alert_popup: true,
    is_override: window?.ENV?.label_printer_simulate,
  });

  return go(
    zip(process_statuses, receivers, waybill_label_datas),
    map(([print_result, receiver, waybill_label_data]) => ({ print_result, receiver, waybill_label_data })),
  );
}
