export const STATUS = {
  PASS: 'PASS',
  NOPASS: 'NOPASS',
  PENDING: 'PENDING',
};

export const STATUS_TO_NAME = {
  PASS: T('마플 이커머스'),
  NOPASS: T('심사 보류'),
  PENDING: T('심사중'),
};
