export default function (lo) {
  const el_editor = $('#simple_group_editor');

  function to_data() {
    return {
      id: parseInt($.val($.find1(el_editor, '[name="id"]'))),
      data: {
        title: $.val($.find1(el_editor, '[name="title"]')),
        type: $.val($.find1(el_editor, '[name="type"]')),
        keys: JSON.parse($.val($.find1(el_editor, '[name="keys"]'))),
      },
    };
  }

  _p.go(
    el_editor,
    $.on('click', '.create', async function () {
      if (!(await $.post('/@api/simple_group/create', _p.omit(to_data(), 'id'))))
        return alert('동일한 type이 있습니다.');
      history.back();
    }),
    $.on('click', '.edit', async function () {
      if (!(await $.put('/@api/simple_group/edit', to_data()))) return alert('동일한 type이 있습니다.');
      history.back();
    }),
    $.on('click', '.remove', async function () {
      await $.del('/@api/simple_group/remove', _p.pick(to_data(), 'id'));
      history.back();
    }),
  );
}
