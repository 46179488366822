import { DfInhouseF } from '../../F/Function/module/DfInhouseF.js';
import { html, strMap } from 'fxjs/es';
import { getProjectionUrl } from './table.inhouse.helper.js';
import { DfInhouseTmplS } from '../../S/Tmpl/module/DfInhouseTmplS.js';
import { $addClass, $data, $find, $removeClass } from 'fxdom/es';
import format from 'date-fns/format/index.js';
import { DfInhouseConstantS } from '../../S/Constant/module/DfInhouseConstantS.js';

const path = {
  prj: `_->projection`,
  store: `_->projection->_->store`,
  crew: `_->projection->_->store->_->crew`,
};

export const TABLE_CONST = {
  columns: {
    parent: [
      {
        title: '',
        field: 'tree_toggler',
        width: '3rem',
        headerSort: false,
        download: false,
        print: false,
      },
      {
        title: '주문#',
        field: 'projection_id',
        width: '5.5rem',
        formatter: 'html',
        adapter: {
          path: `${path.prj}->id`,
          fn: (projection_id) => {
            return html`
              <a href="${getProjectionUrl({ projection_id })}" target="_blank">${projection_id}</a>
            `;
          },
        },
        headerSort: true,
        sorter: 'number',
        hozAlign: 'left',
        headerHozAlign: 'left',
        cellMouseOver: (e, cell) => {
          $addClass('link', cell.getElement());
        },
        cellMouseOut: (e, cell) => {
          $removeClass('link', cell.getElement());
        },
        download: false,
        print: false,
      },
      {
        title: '크루',
        field: 'crew_name',
        width: '7rem',
        formatter: 'html',
        adapter: {
          path: `${path.crew}`,
          fn: (data) => {
            const {
              _: { crew_inhouse },
            } = data;

            const { crew_company_logo, crew_company_name } = crew_inhouse;
            return html`
              <div style="display:flex;align-items:center;gap:4px">
                <img src="${crew_company_logo}" alt="company logo" height="20px" />
                <span>${crew_company_name}</span>
              </div>
            `;
          },
        },
        headerSort: false,
        download: false,
        print: false,
      },
      {
        title: '스토어',
        field: 'store_name',
        formatter: 'html',
        adapter: {
          path: `${path.store}`,
          fn: (store) => {
            const store_name = store.name;
            const outbound_domain = store._.crew._.crew_outbound_domain?.outbound_domain;
            if (outbound_domain != null) {
              return html`<a href="https://${outbound_domain}" target="_blank">${store_name}</a>`;
            }

            return html`<span>${store_name}</span>`;
          },
        },
        cellMouseOver: (e, cell) => {
          $addClass('link', cell.getElement());
        },
        cellMouseOut: (e, cell) => {
          $removeClass('link', cell.getElement());
        },
        headerSort: false,
        download: false,
        print: false,
      },
      {
        title: '주문자',
        field: 'orderer_name',
        width: '5rem',
        adapter: { path: `${path.prj}->orderer_name` },
        headerSort: false,
        download: true,
        print: true,
      },
      {
        title: '법인명',
        field: 'orderer_company_name',
        adapter: { path: `${path.prj}->_->crew_authorization_user_profile->company_name` },
        headerSort: false,
        download: true,
        print: true,
      },
      {
        title: '사번',
        field: 'orderer_employee_no',
        adapter: { path: `${path.prj}->_->crew_authorization_user_profile->employee_no` },
        headerSort: false,
        download: true,
        print: true,
      },
      {
        title: '이메일',
        field: 'orderer_employee_email',
        adapter: { path: `${path.prj}->_->crew_authorization_user_profile->employee_email` },
        headerSort: false,
        download: true,
        print: true,
      },
      {
        title: '취소상태',
        field: 'is_canceled',
        width: '5rem',
        adapter: {
          path: `${path.prj}`,
          fn: ({ is_canceled, is_cancel_req }) => is_canceled || is_cancel_req,
        },
        headerSort: false,
        formatter: (cell) => {
          if (!cell.getData().is_child) {
            $addClass(cell.getValue() === true ? 'canceled' : 'not-canceled', cell.getElement());
          }
        },
        download: false,
        print: false,
      },
      {
        title: '총 수량',
        field: 'quantity',
        width: '4rem',
        adapter: { path: `${path.prj}->quantity` },
        headerSort: false,
        download: false,
        print: false,
      },
      {
        title: '담긴 시각',
        field: 'bucketed_at',
        width: '6rem',
        adapter: { path: `bucketed_at` },
        headerSort: false,
        formatter: (cell) => {
          const bucketed_at = cell.getValue();

          if (bucketed_at) {
            return format(new Date(bucketed_at), 'dd일 HH:mm');
          }
        },
        download: false,
        print: false,
      },
      {
        title: '배송 상태',
        field: 'status',
        width: '10rem',
        adapter: {
          path: path.prj,
        },
        formatter: 'html',
        mutator: (projection) => {
          if (projection == null) return;
          const { status: projection_status } = projection;
          return DfInhouseTmplS.shippingStatusHtml({ projection, projection_status });
        },
        cellClick: (e, cell) => {
          const el = cell.getElement();

          if (el) {
            const status_el = $find('.status-bar', el);
            if (status_el) {
              const status_ats = $data(status_el);
              const projection_status = status_el.dataset?.projection_status;

              DfInhouseF.alert
                .normal({
                  title: '주문 상태 상세 시각',
                  html: html`
                    <div class="status-ats">
                      ${strMap((status) => {
                        const { key, name } = status;
                        const time_at = status_ats[key];
                        return html`
                          <div
                            class="status-at"
                            status="${key}"
                            style="${projection_status === key.replace('_at', '')
                              ? 'background-color:#e9b0ff;'
                              : ''}"
                          >
                            <p class="name">${name}</p>
                            <p class="time">
                              ${time_at ? format(new Date(time_at), 'yyyy-MM-dd  HH:mm') : '-'}
                            </p>
                          </div>
                        `;
                      }, DfInhouseConstantS.PROJECTION_STATUSES)}
                    </div>
                  `,
                })
                .then();
            }
          }
        },
        headerSort: false,
        download: false,
        print: false,
      },
    ],
    child: [
      {
        title: '상품명',
        field: 'product_name',
        adapter: {
          path: `_`,
          fn: ({ base_product, product_color }) => {
            return base_product.name ?? product_color.name ?? '상품명 없음';
          },
        },
        headerSort: false,
        hozAlign: 'left',
        headerHozAlign: 'left',
        download: false,
        print: false,
      },
      {
        title: '상품 정보',
        field: 'product_info',
        width: '20rem',
        adapter: {
          path: `_`,
          fn: ({ base_product_color, base_product_size, spo_item }) =>
            DfInhouseF.getProductInfoStr({ base_product_color, base_product_size, spo_item }),
        },
        headerSort: false,
        hozAlign: 'left',
        headerHozAlign: 'left',
        download: false,
        print: false,
      },
      {
        title: '상품 수량',
        field: 'product_qty',
        width: '6rem',
        adapter: {
          path: `quantity`,
        },
        headerSort: false,
        hozAlign: 'center',
        download: false,
        print: false,
      },
    ],
  },
  childTreeFieldName: 'ups',
  childFieldRoot: {
    adapter: {
      path: `${path.prj}->_->user_products`,
    },
  },
  treeElements: {
    expand: html` <img
      src="//s3.marpple.co/files/u_1187078/2023/9/original/fc58170dbf3be7c01ae5879feb2ff22232b03b4a1.png"
    />`,
    collapse: html` <img
      src="//s3.marpple.co/files/u_1187078/2023/9/original/636866e2f235e9345c69d088f42892c8ffbc8cac1.png"
    />`,
  },
};
