import { every, go, map, sel, selEq, some } from 'fxjs/es';

export const makeDfProjectionReceiptStatusForSidebarJson = (prj_payments) => {
  if (prj_payments.length === 0) return;
  return {
    done: go(prj_payments, map(sel('receipt')), (receipts) =>
      receipts.length ? every(sel('done'), receipts) : null,
    ),
    need_reissue: go(prj_payments, some(selEq('receipt.need_reissue', true))),
  };
};
