$.frame.defn_frame({
  frame_name: 'terms_conditions_frame',
  page_name: 'terms_conditions_page',
});

$.frame.defn_frame({
  frame_name: 'privacy_frame',
  page_name: 'privacy_page',
});

$.frame.defn_frame({
  frame_name: 'privacy_login_frame',
  page_name: 'privacy_login_page',
});
$.frame.defn_frame({
  frame_name: 'commerce_law_frame',
  page_name: 'commerce_law_page',
});
