export function task_set_detail_tmpl(task, user) {
  return pug`
      .wrapper[_sel="task" is_draft="${task.is_draft}" is_hidden="${task.is_hidden}"]
        .head
          .close 닫기
          .title ${task.is_hidden ? '[삭제]' : ''} 태스크 상세보기 - #${
    task.id
  }<span id="task_save_state"></span>
          .delete_task[type="is_hidden" val="${task.is_hidden}"] ${
    task.is_hidden ? '태스크 복구' : '태스크 삭제'
  }
          .create_task 태스크 생성
        .body_wrapper
          .task_body[task_id="${task.id}" is_canceled="${task.is_canceled}" is_hidden="${
    task.is_hidden
  }" status="${task.status}"]
            .head
              .title
                label 작업명 &nbsp:&nbsp ${task.title || '작업명 없음'}
            .wrapper
              .rowm.margin_bottom
                .title.content[type="title"]
                  label.label 작업명
                  input.input[type="text" value="${task.title || ''}" placeholder="작업명을 입력하시오" ${
    task.parent_id && user.id !== task.worker_id ? 'disabled' : ''
  }]
                  .option
                    button.save[type="button" ${
                      task.parent_id && user.id !== task.worker_id ? 'disabled' : ''
                    }] ${task.is_draft ? '작업명 저장' : '작업명 변경'}
                    button.cancel[type="button"] 취소
                .content.press_type[type="press_type_id"]
                  label.label 인쇄방식
                  .press_type_select[_sel="press_types" current_value="${
                    task.press_type_id
                  }" value_key="id" name_key="name" select_name="press_type_id" el_class="press_type_select_ui" el_id="press_type_select_ui"]
                    .selected ${_p.val(task, '_.press_type.name') || '선택 안함'}
              .rowm.margin_bottom
                .manager.worker[key="worker_id"].content.task_detail_inline_block
                  label.name.label 담당자
                  .body.label_content
                    .add
                      .manager_blank ${_p.v(task, `_.worker.name`) || '담당자 선택'}
                    .option ...
                .manager.admin[key="admin_id"].content.task_detail_inline_block
                  label.name.label 서브 담당자
                  .body.label_content
                    .add
                      .manager_blank ${_p.v(task, `_.admin.name`) || '담당자 선택'}
                    .option ...
              .rowm.margin_bottom
                .body.content[type="body"]
                  label.label 내용
                  textarea.input ${task.body || ''}
                  .option
                    button.save[type="button"] 저장
                    button.cancel[type="button"] 취소
              .rowm.margin_bottom
                .body.check_box
                  label.label 외주 정산을 위한 입고 태스크
                  input[type="checkbox" name="is_receiving_task" ${task.is_receiving_task ? 'checked' : ''}]
                  .option
                    button.save[type="button"] 저장
    `;
}
