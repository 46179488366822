import { html, strMap, values } from 'fxjs/es';
import { OMPCoreUtilS } from '../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPMyPageConstantS } from '../Constant/module/OMPMyPageConstantS.js';
export const myPageLayoutTmpl = ({ locals, body, selected_menu }) => {
  if (OMPCoreUtilS.isMobile(locals)) return body;

  const my_page_title = TT('my_page::layout::layout_01');

  return html`
    <div class="omp-my-page">
      <div class="omp-my-page__header">
        <a class="omp-my-page__header-title" href="/${TT.lang}/my-page">${my_page_title}</a>
        <ul class="omp-my-page__header-menus">
          ${strMap((menu) => {
            return html`
              <li data-active="${menu.key === selected_menu}" class="omp-my-page__header-menu">
                <a href="/${TT.lang}/${menu.url}" target="${menu.key === 'info' ? '_blank' : '_self'}"
                  ><span>${menu.title}</span> ${menu.key === 'info'
                    ? OMPMyPageConstantS.OUT_LINK_ICON
                    : ''}</a
                >
              </li>
            `;
          }, values(OMPMyPageConstantS.MyPageMenu()))}
        </ul>
      </div>
      <div class="omp-my-page__body">${body}</div>
    </div>
  `;
};
