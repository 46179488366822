import { $data, $find } from 'fxdom/es';
import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { drawProductFace } from '../../../../Maker/F/draw_product_faces.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';

export const catchError = async (e) => {
  // MEMO: axios 에러
  if (e?.response?.data?.message) {
    await OMPCoreUtilF.alert({
      content: e?.response?.data?.message || TT('error::common::error_01'),
    });
    return;
  }

  // MEMO: 프론트 에러
  if (e?.handle) {
    await OMPCoreUtilF.alert({
      content: e?.message || TT('error::common::error_01'),
    });
    return;
  }

  OMPCoreUtilF.alert({
    content: TT('error::common::error_01'),
  });

  e.name = '마이페이지 에러';
  ConfigSentryF.error(e);
};

export const drawThumbnail = (product_face_el) => {
  if (!product_face_el.dataset.fxJson) return;
  const product_face = $data(product_face_el);
  const canvas = $find('canvas')(product_face_el);
  product_face_el.dataset.product_face = null;
  drawProductFace(canvas, product_face, false, false, false, false);
};
