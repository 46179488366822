import { go, map, filter, flat } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { DfInhouseF } from '../../../../Inhouse/F/Function/module/DfInhouseF.js';
import { apiCalls } from '../../../F/Function/api.calls.js';
import { page } from '../Mui/page.js';
import { tab_inbound } from '../Mui/tab_inbound.js';
import { tab_outbound } from '../Mui/tab_outbound.js';
import { tab_packaging } from '../Mui/tab_packaging.js';
import { tab_rebound } from '../Mui/tab_rebound.js';
import { tab_return } from '../Mui/tab_return.js';
import { tab_tracking } from '../Mui/tab_tracking.js';
import { checkItemsQuantity } from './item.js';
import { getProjectionStatus, getShippingStatus } from './status.js';
import { getTabId } from './tab.js';

export async function fetchLglData({ projection_id }) {
  const [
    projection_data,
    { lgl_inbound_order_group },
    { lgl_outbound_order_group },
    { lgl_return_order_group },
    { lgl_overall_status },
  ] = await Promise.all([
    apiCalls.get.projection.userProducts({ projection_id }),
    apiCalls.get.inbound.orderGroup({
      projection_id,
    }),
    apiCalls.get.outbound.orderGroup({ projection_id }),
    apiCalls.get.return.orderGroup({ projection_id }),
    apiCalls.get.tracking.overallStatus({ projection_id }),
  ]);

  const inbound_orders = lgl_inbound_order_group ? lgl_inbound_order_group._.lgl_inbound_orders : [];
  const return_orders = lgl_return_order_group ? lgl_return_order_group._.lgl_return_orders : [];
  const projection_status = getProjectionStatus({ projection: projection_data });

  return {
    projection_data,
    projection_status,
    inbound_order_group: lgl_inbound_order_group,
    inbound_orders,
    return_orders,
    outbound_orders: lgl_outbound_order_group,
    lgl_overall_status,
  };
}

export function getHeaderData({ projection_status, inbound_orders, outbound_orders }) {
  const inbound_order_statuses = go(
    inbound_orders,
    map(({ current_status }) => current_status),
  );

  const type = UtilS.isEmpty(outbound_orders) ? 'INBOUND' : 'OUTBOUND';
  const status = getShippingStatus({
    projection_status,
    inbound_order_statuses,
    outbound_order_statuses: outbound_orders, // TODO outbund_order_statuses 조회하기
  });

  const reject_in_inbound =
    go(
      inbound_orders,
      filter(({ current_status }) => ['INSPECTING', 'INSPECTED'].includes(current_status)),
      map(({ _: { lgl_items } }) => lgl_items),
      flat,
      filter(
        ({ _: { lgl_item_inbound_inspections, lgl_return_orders } }) =>
          !lgl_item_inbound_inspections.is_ok && !lgl_return_orders,
      ),
    ).length > 0;

  const selected_tab_id = getTabId({ type, status });
  const error = false;
  const error_tab_id = reject_in_inbound ? 'INBOUND' : '';

  return {
    type,
    status,
    error,
    selected_tab_id,
    error_tab_id,
  };
}

/*
 *  @description 해외배송 전체 탭을 렌더링
 *  @params projection_id - projection의 id
 */
export async function renderAllTab({ projection_id }) {
  DfInhouseF.lottie_loader.start('dots');

  const {
    projection_data,
    projection_status,
    inbound_order_group,
    inbound_orders,
    outbound_orders,
    lgl_overall_status,
  } = await fetchLglData({
    projection_id,
  });
  const { type, status, error, selected_tab_id, error_tab_id } = getHeaderData({
    projection_status,
    inbound_orders,
  });

  page.render_header({ type, status, error, selected_tab_id, error_tab_id });

  tab_packaging.render({
    projection: projection_data,
    projection_status,
    inbound_orders,
  });

  tab_inbound.render({
    inbound_orders,
  });

  tab_rebound.render({
    inbound_orders,
  });

  tab_outbound.render({
    projection_id,
    inbound_order_group,
    outbound_orders,
  });

  tab_tracking.render({ lgl_overall_status });

  DfInhouseF.lottie_loader.end();

  if (selected_tab_id === 'PACKAGING') {
    if (inbound_orders.length > 0) {
      const [{ current_status }] = inbound_orders;
      await checkItemsQuantity({ projection_id, current_status });
    }
  }
}

export async function renderPackagingTab({ projection_id }) {
  DfInhouseF.lottie_loader.start('dots');

  const { projection_data, projection_status, inbound_orders, outbound_orders } = await fetchLglData({
    projection_id,
  });

  const { type, status, error, error_tab_id } = getHeaderData({
    projection_status,
    inbound_orders,
    outbound_orders,
  });
  page.render_header({ type, status, error, selected_tab_id: 'PACKAGING', error_tab_id });

  tab_packaging.render({
    projection: projection_data,
    projection_status,
    inbound_orders,
  });

  DfInhouseF.lottie_loader.end();

  if (inbound_orders.length > 0) {
    const [{ current_status }] = inbound_orders;
    await checkItemsQuantity({ projection_id, current_status });
  }
}

export async function renderReboundTab({ projection_id }) {
  DfInhouseF.lottie_loader.start('dots');

  const { projection_status, inbound_orders, outbound_orders } = await fetchLglData({
    projection_id,
  });
  const { type, status, error, error_tab_id } = getHeaderData({
    projection_status,
    inbound_orders,
    outbound_orders,
  });
  page.render_header({ type, status, error, selected_tab_id: 'REBOUND', error_tab_id });

  tab_rebound.render({ inbound_orders });

  DfInhouseF.lottie_loader.end();
}

export async function renderInboundTab({ projection_id }) {
  DfInhouseF.lottie_loader.start('dots');

  const { projection_status, inbound_orders, outbound_orders } = await fetchLglData({ projection_id });
  const { type, status, error, error_tab_id } = getHeaderData({
    projection_status,
    inbound_orders,
    outbound_orders,
  });
  page.render_header({ type, status, error, selected_tab_id: 'INBOUND', error_tab_id });

  tab_inbound.render({
    inbound_orders,
  });

  DfInhouseF.lottie_loader.end();
}

export async function renderReturnTab({ projection_id }) {
  DfInhouseF.lottie_loader.start('dots', null, null, null);

  const { projection_status, inbound_orders, return_orders, outbound_orders } = await fetchLglData({
    projection_id,
  });
  const { type, status, error, error_tab_id } = getHeaderData({
    projection_status,
    inbound_orders,
    outbound_orders,
  });
  page.render_header({ type, status, error, selected_tab_id: 'RETURN', error_tab_id });

  tab_return.render({
    return_orders,
  });

  DfInhouseF.lottie_loader.end();
}

export async function renderOutboundTab({ projection_id }) {
  DfInhouseF.lottie_loader.start('dots');

  const { projection_status, inbound_order_group, inbound_orders, outbound_orders } = await fetchLglData({
    projection_id,
  });
  const { type, status, error, error_tab_id } = getHeaderData({
    projection_status,
    inbound_orders,
    outbound_orders,
  });
  page.render_header({ type, status, error, selected_tab_id: 'OUTBOUND', error_tab_id });

  tab_outbound.render({ projection_id, inbound_order_group, outbound_orders });

  DfInhouseF.lottie_loader.end();
}

export async function renderTrackingTab({ projection_id }) {
  DfInhouseF.lottie_loader.start('dots');

  const { projection_status, inbound_orders, outbound_orders, lgl_overall_status } = await fetchLglData({
    projection_id,
  });
  const { type, status, error, error_tab_id } = getHeaderData({
    projection_status,
    inbound_orders,
    outbound_orders,
  });
  page.render_header({ type, status, error, selected_tab_id: 'TRACKING', error_tab_id });

  tab_tracking.render({ lgl_overall_status });

  DfInhouseF.lottie_loader.end();
}
