import { $closest, $findAll } from 'fxdom/es';
import { each, go, tap } from 'fxjs/es';

export const reviewAppendReadMore = tap((el) => {
  go(
    el,
    $findAll('.omp-cell__review__description'),
    each(($desc) => {
      const $review = $closest('.omp-cell__review', $desc);
      if ($desc.offsetHeight >= $desc.scrollHeight) {
        $review.dataset.read_more = 'false';
      } else {
        $review.dataset.read_more = 'true';
      }
    }),
  );
});
