import { $appendTo, $delegate, $el, $find, $on, $qs, $remove, $text } from 'fxdom/es';
import { go, pipe } from 'fxjs/es';
import { UtilS } from '../S/Function/module/UtilS.js';
import { copyText } from './fs.js';

export function copyModal(text) {
  const copy_modal_style = `
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `;
  const wrapper_style = `
    padding: 16px;
    background: #fff;
    border: 1px solid #484848;
    border-radius: 10px;
    font-size: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;
  const body_style = `
    text-align: center;
    margin-bottom: 16px;
  `;
  const option_style = `
    display: flex;
    justify-content: center;
  `;
  const button_style = `
    border-color: #000;
    background-color: #000;
    color: #fff;
  `;
  // function close(e) {
  //   $remove(e.currentTarget);
  // }
  return go(
    UtilS.legacyHtml`
      <div class="copyModal" style="${copy_modal_style}" tabindex="-1">
        <div style="${wrapper_style}">
          <div style="${body_style}">
            <div class="text-target">${text}</div>
          </div>
          <div style="${option_style}">
            <button type="button" class="copy-event" style="${button_style} margin-right: 16px;">복사</button>
            <button type="button" class="close-event" style="${button_style}">닫기</button>
          </div>
        </div>
      </div>
    `,
    $el,
    $appendTo($qs('body')),
    pipe(
      $delegate('click', '.copy-event', (e) => {
        go(e.delegateTarget, $find('.text-target'), $text, copyText);
      }),
      $delegate('click', '.close-event', (e) => {
        $remove(e.delegateTarget);
      }),
      $on('keydown', (e) => {
        if (e.key === 'Escape') $remove(e.currentTarget);
      }),
      (el) => el.focus(),
    ),
  );
}
