import { UtilS } from '../../../../../modules/Util/S/Function/module/UtilS.js';
import { go } from 'fxjs';
import { MuiF } from '../../../../../modules/Mui/F/Function/module/MuiF.js';
import { DfFileIssueModalMuiF } from '../../../../../modules/Df/FileIssueModal/F/Mui/module/DfFileIssueModalMuiF.js';
import { $attr, $closest } from 'fxdom/es';

!(function (LF) {
  const sort_issue = _p.tap(
    $.find('.issue_list'),
    _each(
      __(_p.add_arg($.find('[is_read="true"]')), (el, is_reads) => {
        is_reads.length && $.append($.find(el, '.issues'), is_reads);
      }),
    ),
  );

  function t_issue(_sel, user) {
    return (issue) => {
      const my_issue = _go(
        issue._.issues_users,
        _p.find((issue_user) => issue_user.user_id == user.id),
      );
      const iam_thumb = user.id == issue.user_id;
      return pug`
      .issue[issue_id='${issue.id}' _sel='${_sel(issue.id)}' my_issue="${iam_thumb}" is_read='${_go(
        my_issue,
        _p.v('is_read'),
      )}']
        .title ${UtilS.linkify(issue.title || '')}
        .body ${UtilS.linkify(issue.body || '-')}
        .info
          .thumb_issue_user
            ${G.df.user.t_style_user('./_->user')(issue._.user)}
          .issues_users
            ${_p.sum(
              issue._.issues_users,
              __(
                (issue_user) => pug`
                .issue_user[user_id="${issue_user.user_id}" issue_user_id="${
                  issue_user.id
                }" _sel="./_->issues_users->(#${issue_user.id})"]
                  .user
                    .img
                    .name ${issue_user._.user.name}
                  .status_read ${issue_user.is_read ? '읽음' : '읽지 않음'}
              `,
              ),
            )}
          .add_issue_user
            .btn 확인자 +
          .cancel_issue
            .btn 이슈 삭제
          .created_at ${moment(issue.created_at).format('YYYY.MM.DD HH:mm')}
        .option
          button.confirm[type="button"] 확인
          label 읽음
    `;
    };
  }

  function t_issue_list(issues) {
    return pug`
          ${_p.sum(
            issues,
            t_issue((task_id) => `issues->(#${task_id})`, box.sel('is_user')),
          )}
    `;
  }

  function t_unread_issue_list(issues) {
    return pug`
      .wrapper
        .body 읽지않은 이슈`;
  }

  function render_selected_users(don_page) {
    return (selected_users) =>
      _go(selected_users, _p.sum(t_user), $.html_to($.find1(don_page, '.user_body >.wrapper .issues_users')));
  }

  function render_disabled_users(don_page) {
    return (disabled_users) =>
      _go(
        disabled_users,
        _p.sum(t_user),
        $.html_to($.find1(don_page, '.user_body >.wrapper.thumb .issue_user')),
      );
  }
  LF.issue_events = {};

  LF.issue_events.confirm = function (e) {
    const user = box.sel('is_user');
    _p.go(
      e.currentTarget,
      $.closest('.issue'),
      _p.tap(
        box.sel,
        _p.v('_.issues_users'),
        _p.find((issue_user) => issue_user.user_id == user.id),
        _p.tap(function (issue_user) {
          if (!issue_user) throw new Error('no_user');
        }),
        _p(_p.extend, _p, { is_read: true }),
        _p.pick(['id', 'is_read', 'issue_id', 'user_id']),
        _p($.post, '/@api/issue_user/modify'),
        function (bool) {
          if (!bool) throw new Error('error');
        },
      ),
      _p.tap(_p($.toggle_attr, _p, 'is_read')),
      $.find1(`.issue_user[user_id="${user.id}"] .status_read`),
      $.text('읽음'),
      _p.catch(function () {
        $.alert('문제가 발생했습니다.');
      }),
    );
  };

  LF.issue_events.add_user = function (e) {
    return new Promise(function (resolve) {
      const issue_el = $.closest(e.currentTarget, '.issue');
      return _p.go(
        issue_el,
        $.find('.issue_user'),
        _map(__(box.sel, _p.v('_.user'))),
        (disabled_users) => {
          box.set('df.worker.list_disabled_workers', disabled_users);
          box.set('df.worker.list_selected_workers', []);
        },
        function () {
          $.frame.open({
            frame_name: 'df.worker.list',
            frame_tag: 'multiple',
            closed: function (el, selected_users) {
              if (!selected_users) return;
              _p.go(
                selected_users,
                _map(function (v) {
                  return _p.extend(
                    {
                      user_id: v.id,
                    },
                    { issue_id: $.attr(issue_el, 'issue_id') },
                  );
                }),
                _p.nest('issues_users'),
                function (issues_users) {
                  return {
                    issues_users,
                    task: { id: box.sel('task->id') },
                  };
                },
                _p($.post, '/@api/issues_users/add'),
                function () {
                  box.unset('df.worker.list_disabled_workers');
                  box.unset('df.worker.list_selected_workers');
                  resolve();
                },
              );
            },
          });
        },
      );
    });
  };

  LF.issue_events.cancel_issue = function (funcs) {
    return function (e) {
      const issue_el = $.closest(e.currentTarget, '.issue');
      return _p.go(
        issue_el,
        box.sel,
        _p
          .if2((issue) => box.sel('is_user->id') == issue.user_id)(
            _p.nest('issue'),
            _p($.post, '/@api/issue/delete'),
            function () {
              funcs && funcs(e, issue_el);
            },
          )
          .else(_p.c('발행자가 아니여서 취소 할 수 없습니다.'), $.confirm),
      );
    };
  };

  const modal_events = __($.on('click', '.issue >.option .confirm', LF.issue_events.confirm));

  const task_issue_list_events = __(
    $.on('click', '.issue_list .issue >.option .confirm', LF.issue_events.confirm),
    $.on(
      'click',
      '.issue_list .issue .add_issue_user',
      __(LF.issue_events.add_user, G.df.task.set_task_box_task_detail_render),
    ),
    $.on(
      'click',
      '.issue_list .issue .cancel_issue',
      LF.issue_events.cancel_issue(function () {
        G.df.task.set_task_box_task_detail_render();
      }),
    ),
  );

  function modal(task_title, dt) {
    const modal_man_el = $1('.modal_man.issue_man');
    if (modal_man_el) $.remove(modal_man_el);
    const thumb_issue = _p.pick(box.sel('issues')[0], ['type', 'type_id']);
    return new Promise(function (res) {
      return _p.go(
        pug`
        .modal_man.issue_man[type="${thumb_issue.type}" type_id="${thumb_issue.type_id}"]
          .wrapper
            .head
              .not_yet
              .title  ${thumb_issue.type == 'task' ? '태스크' : '주문서'} - ${task_title}
              .close 닫기
            .body.issues
              ${G.df.issue.t_issue_list(box.sel('issues'))}
      `,
        $.el,
        $.append_to(dt),
        $.on('click', '.close', function (e) {
          $.remove(e.delegateTarget);
          res(true);
        }),
        _p.tap((el_parent) => G.df.issue.modal_events(el_parent)),
      );
    });
  }

  function t_user(user) {
    return pug`
      .user[user_id=${user.id} _sel='selected_users->(#${user.id})']
        .name ${user.name}
    `;
  }

  const issue_create_tmpl = pug`
    .wrapper[_sel="issue"]
      .head
        .close 취소
        .title 이슈 만들기
        .create 만들기
      .issue_body
        .title 
          label 제목
          input[type="text"]
        .body 
          label 내용
          textarea
      .user_body
        .wrapper.thumb
          .head 발행자
          .issue_user
        .wrapper
          .head 
            label 확인자
            .add_issues_users
              label 추가
              .btn.add_btn
          .issues_users
    `;

  $.frame.defn_frame({
    frame_name: 'df.issue.create_one',
    el_class: 'issue_create_one issue_create',
    page_name: 'issue.create_one',
    is_modal: true,
    always_remove: true,
  });

  $.frame.defn_frame({
    frame_name: 'df.issue.create_mult',
    el_class: 'issue_create_mult issue_create',
    page_name: 'issue.create_mult',
    is_modal: true,
    always_remove: true,
  });

  $.frame.defn_page({
    page_name: 'issue.create_mult',
    tabs: [
      {
        tab_name: 'issue_create_mult_tab',
        template: () => issue_create_tmpl,
        appended: __(
          $.on('click', '.don_wrapper >.wrapper >.head .close', function () {
            $.frame.close();
          }),
          $.on('click', '.don_wrapper >.wrapper >.head .create', function (e) {
            if ($.val($1('.issue_create .issue_body >.title input')).length >= 255) {
              $.alert('제목의 글자수가 초과했습니다.');
              return;
            }
            _p.go($1('.issue_create .issue_body >.title input'), $.val, (val) =>
              _p.each(box.sel('issues'), (issue, i) => {
                box.sel('issues')[i].title = val;
              }),
            );

            _p.go($1('.issue_body >.body textarea'), $.val, (val) =>
              _p.each(box.sel('issues'), (issue, i) => {
                box.sel('issues')[i].body = val;
              }),
            );
            _p.go($.post('/@api/issues/create', { issues: box.sel('issues') }), function (result) {
              if (!result) {
                $.alert('문제가 발생했습니다. 제목의 글자수가 많다면, 줄여주세요.');
                return;
              }
              $.frame.close();
            });
          }),
          $.on('change', '.issue_body >.title input', function (e) {
            if ($.val(e.currentTarget).length > 255) return $.alert('제목의 글자수가 초과했습니다.');
          }),
        ),
      },
    ],
  });

  $.frame.defn_page({
    page_name: 'issue.create_one',
    tabs: [
      {
        tab_name: 'issue_create_one_tab',
        template: () => issue_create_tmpl,
        appended: __(
          _p.tap(function (don_tab_el) {
            _go(
              box.sel('df.issue.create_one_issuer'),
              _p.tap(render_disabled_users(don_tab_el)),
              (disabled_users) => disabled_users.concat(box.sel('df.issue.create_one_issuees')),
              (users) => _p.uniq(users, _p.v('id')),
              render_selected_users(don_tab_el),
            );
          }),
          $.on('click', '.wrapper >.head .close', function () {
            $.frame.close();
          }),
          $.on('click', '.wrapper >.head .create', function (e) {
            if ($.val($1('.issue_create .issue_body >.title input')).length >= 255) {
              $.alert('제목의 글자수가 초과했습니다.');
              return;
            }

            _p.go($1('.issue_create .issue_body >.title input'), $.val, (val) =>
              box.extend2('issue', { title: val }),
            );

            _p.go($1('.issue_create .issue_body >.body textarea'), $.val, (val) =>
              box.extend2('issue', { body: val }),
            );

            _p.go($.post('/@api/issues/create', { issues: [box.sel('issue')] }), function (result) {
              if (!result) {
                $.alert('문제가 발생했습니다. 제목의 글자수가 많다면, 줄여주세요.');
                return;
              }
              $.frame.close();
            });
          }),
          $.on('change', '.issue_body >.title input', function (e) {
            if ($.val(e.currentTarget).length >= 255) return $.alert('제목의 글자수가 초과했습니다.');
          }),
          $.on('click', '.add_issues_users', function (e) {
            box.set('df.worker.list_disabled_workers', box.sel('df.issue.create_one_issuer'));
            box.set('df.worker.list_selected_workers', _p.clone(box.sel('df.issue.create_one_issuees')));
            $.frame.open({
              frame_name: 'df.worker.list',
              frame_tag: 'multiple',
              closed: __(
                function (el, selected_users) {
                  if (!selected_users) return;
                  box.set('df.issue.create_one_issuees', _p.clone(selected_users));
                  _p.go(
                    selected_users,
                    function (selected_users) {
                      return _p.uniq(
                        selected_users.concat(box.sel('df.issue.create_one_issuer')),
                        _p.v('id'),
                      );
                    },
                    _p.tap(function (union_users) {
                      box.set(
                        'issue->_->issues_users',
                        _map(union_users, (s_user) => ({
                          user_id: s_user.id,
                        })),
                      );
                    }),
                    render_selected_users(e.delegateTarget),
                  );
                },
                function () {
                  box.unset('df.worker.list_disabled_workers');
                  box.unset('df.worker.list_selected_workers');
                },
              ),
            });
          }),
        ),
      },
    ],
  });

  G.df.issue = {};

  G.df.issue.t_issue = t_issue;
  G.df.issue.t_unread_issue_list = t_unread_issue_list;
  G.df.issue.task_issue_list_events = task_issue_list_events;
  G.df.issue.t_issue_list = t_issue_list;
  G.df.issue.modal = modal;
  G.df.issue.modal_events = modal_events;
  G.df.issue.sort_issue = sort_issue;
})({});
