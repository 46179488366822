import { compact, each, extend, go, flatten, map } from 'fxjs/es';
import { makeOutlineCanvas2, sourceOverCanvas } from '../../../../../Canvas/S/util.js';
import { CanvasS } from '../../../../../Canvas/S/Function/module/CanvasS.js';

const getFlattenCvDesigns = (cv_objs) => {
  return go(
    cv_objs,
    map((cv_obj) =>
      cv_obj.objects?.length || cv_obj._objects?.length
        ? getFlattenCvDesigns(cv_obj.objects || cv_obj._objects)
        : cv_obj,
    ),
    flatten,
  );
};

export const bpcColorCode2Render = {
  makeColorRenderedCanvas: (src_image, color_code) => {
    if (color_code === '#000000') {
      color_code = '#171717';
    }
    if (color_code === '#ffffff') {
      color_code = '#f9f9f9';
    }
    const design_src = CanvasS.makeCanvasTexturedInS(src_image, color_code);
    return design_src;
  },
  makeRenderedCanvas: async (src_image, { color_code }) => {
    const design_src = bpcColorCode2Render.makeColorRenderedCanvas(src_image, color_code);
    const shade_canvas = await bpcColorCode2Render.getShadeCanvas(design_src);
    return sourceOverCanvas(design_src, shade_canvas);
  },
  getShadeCanvas: async (canvas) => {
    const deep_px = -1;
    const shade_strength = 50;
    return sourceOverCanvas(canvas, makeOutlineCanvas2(canvas, deep_px, shade_strength));
  },
  setColorCode2: function (cv_obj, bpc) {
    const _datas = go(
      [cv_obj._data?.cv_text_image_attrs?._data, cv_obj._data?.cv_image_attrs?._data, cv_obj._data],
      compact,
    );
    go(
      _datas,
      each((_data) => {
        if (_data.cv_type === 'cv_text_image') {
          extend(_data, {
            only_flex: false,
            press_color_id: `bpc_${bpc.id}`,
            press_color_code: bpc.color_code2,
            press_color_name: bpc.name,
            press_color_name_en: bpc.name_en,
            press_color_name_jp: bpc.name_jp,
          });
        }
        _data.color_code2 = bpc.color_code2;
      }),
    );
  },
  changeColorData: (product_face, bpc) => {
    go(
      product_face.designs,
      getFlattenCvDesigns,
      each((cv_obj) => bpcColorCode2Render.setColorCode2(cv_obj, bpc)),
    );
  },
};
