import moment from 'moment';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { PdfS } from '../../../../../Pdf/S/Function/module/PdfS.js';

export const orderPrintingFileTmpl = ({ pdf_printing_file = {} }) => {
  const is_show_pdf_printing_file = PdfS.is_pdf_uploaded_by_customer({ pdf_printing_file });
  if (!is_show_pdf_printing_file) return ``;

  const { file_name, pdf_url, created_at, expired_at } = pdf_printing_file;
  const [file_title] = (file_name || '').split('.pdf');
  const is_expired = OMPCoreUtilS.isExpired({ expired_at });

  return `<span class="omp-order-detail__product__info-key">${TT('my_page::order::order_36')}</span>
            <div class="omp-order-detail__product__info-value">
          ${
            is_expired
              ? `<div class="omp-order-product__printing-file-name disabled">
                    <span class="omp-order-product__printing-file-title">${file_title}</span>
                    <span class="omp-order-product__printing-file-mimetype">.pdf</span>
                </div>`
              : `<div class="omp-order-product__printing-file-name">
                    <span class="omp-order-product__printing-file-title" data-file-url="${pdf_url}" data-file-name="${file_name}">${file_title}</span>
                    <span class="omp-order-product__printing-file-mimetype">.pdf</span>
                </div>`
          }
            
            <div class="omp-order-product__printing-file-date">
                ${is_expired ? `${TT('pdf::expired::2')}` : moment(created_at).format('YYYY-MM-DD HH:mm')}
            </div>
          </div>`;
};
