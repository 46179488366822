import axios from 'axios';
import { $delegate, $hasClass, $qsa, $setHTML } from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { OMPAuthF } from '../../../Auth/F/Function/module/OMPAuthF.js';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';

export const delegateBaseProductLike = (delegateTarget$) =>
  go(
    delegateTarget$,
    $delegate('click', '.omp-cell__bp-like', async ({ originalEvent, currentTarget }) => {
      originalEvent.preventDefault();
      if (box.sel('is_user')) {
        const { base_product_id } = currentTarget.dataset;
        const active = currentTarget.dataset.active != 'true';
        each((like_el) => {
          like_el.dataset.active = active;
          if (like_el.dataset.wish_count) {
            const badge = parseInt(like_el.dataset.wish_count) + (active ? 1 : -1);
            like_el.dataset.wish_count = badge;
            $setHTML(
              OMPCoreAtomTmplS.heartCountButton({
                active,
                badge,
                badge_max: 9999,
              }),
              like_el,
            );
          } else {
            $setHTML(
              $hasClass('omp-cell__product-mo-badge-wrapper', currentTarget)
                ? OMPCoreAtomTmplS.heartCircleButton({
                    active,
                    color: 'gray',
                  })
                : OMPCoreAtomTmplS.heartButton({
                    active,
                    color: 'gray',
                  }),
              like_el,
            );
          }
        }, $qsa(`.omp-cell__bp-like[data-base_product_id="${base_product_id}"]`));

        await axios.post(`/${TT.lang}/@api/base_product/like`, {
          base_product_id,
          active,
        });
        const user_counts = await OMPCoreUtilF.resetCartWish();
        const userCountUpdatedEvent = new CustomEvent('updated:user_counts', {
          bubbles: true,
          cancelable: true,
          detail: user_counts,
        });
        currentTarget.dispatchEvent(userCountUpdatedEvent);
      } else {
        await OMPAuthF.openLogin();
      }
    }),
  );
