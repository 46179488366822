import { UtilS } from '../../S/Function/module/UtilS.js';
import { $qs } from 'fxdom/es';

/**
 *
 * @param {Record<string, string | null>} params
 * @return {string}
 */
export const updateUrlParams = (params, preserve_prev) =>
  UtilS.updateUrlParams(location.href, params, preserve_prev);

export const isBackForward = () => {
  try {
    return window.performance.getEntriesByType('navigation')[0].type == 'back_forward';
  } catch (e) {
    return window?.performance?.navigation?.type == 2;
  }
};

export const initBackForward = async (func) => {
  if (isBackForward()) await func();
  window.addEventListener('pageshow', (e) => {
    if (e.persisted) func(true);
  });
};

/**
 *
 * @param {string} title
 * @return void
 */
export const changeMetaTitle = (title) => {
  const title_el = $qs('title');
  if (!title_el) return;

  title_el.innerText = title;
};

export const clipboard = (text) => {
  const _clipboard = (function (window, document, navigator) {
    let textArea;

    function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.value = text;
      document.body.appendChild(textArea);
    }

    function selectText() {
      let range, selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }

    function copyToClipboard() {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }

    return {
      copy: (text) => {
        createTextArea(text);
        selectText();
        copyToClipboard();
      },
    };
  })(window, document, navigator);

  _clipboard.copy(text);
};

export const startTimerMinutes = (duration, timer_el, endFunc) => {
  let timer = duration;
  let minutes;
  let seconds;
  const intervalId = setInterval(function () {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    timer_el.textContent = minutes + ':' + seconds;

    if (--timer < 0) {
      clearInterval(intervalId); // interval 제거
      endFunc();
    }
  }, 1000);
};

/**
 * @define 이스터 에그 버튼
 * */
export function getEasterEgg({ activation_msg, click_interval = 150, double_click_count = 10 }) {
  const easter_egg = {
    is_activated: false,
    clicked_at: new Date(),
    count: 0,
  };

  return {
    easter_egg,
    easter_egg_evt_handler: () => {
      const now = new Date();
      // 한번 활성화 되었으면 비활성화 시키지 않음.
      if (easter_egg.is_activated) return;

      if (now - easter_egg.last_clicked_at < click_interval) {
        // 더블클릭 속도 300 내로 ms
        easter_egg.count++;
      } else {
        easter_egg.count = 0;
      }

      easter_egg.last_clicked_at = now;

      if (easter_egg.count > double_click_count) {
        easter_egg.is_activated = true;
        $.alert(activation_msg);
      }
    },
  };
}
