import BLazy from 'blazy';
import {
  $addClass,
  $find,
  $height,
  $hide,
  $qs,
  $remove,
  $removeClass,
  $scrollTop,
  $setAttr,
  $setCss,
  $setScrollTop,
  $setText,
} from 'fxdom/es';
import { join, map, go, html, mapObject, object, each, compact, flatten } from 'fxjs/es';
import UAParser from 'ua-parser-js';
import { PdfF } from '../../../../../Pdf/F/Function/module/PdfF.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreUtilS } from '../../S/Function/module/OMPCoreUtilS.js';

const parser = new UAParser();
const is_mobile_cache = ['mobile'].includes(parser.getDevice().type);
const is_tablet_cache = ['tablet'].includes(parser.getDevice().type);

export const isWhale = () => parser.getBrowser()?.name?.toLowerCase() === 'whale';
export const isMobile = () => {
  if (UtilF.isForceM()) return true;
  return is_mobile_cache;
};
export const isTablet = () => {
  if (UtilF.isForceM()) return;
  if (!isMobile() && window.navigator.maxTouchPoints === 5) return true;
  /* ipad 구별 */
  return is_tablet_cache;
};
export const isIOS = () => /IOS/i.test(parser.getOS()?.name);
export const isAndroid = () => /Android/i.test(parser.getOS()?.name);

export const isMpApp = () =>
  !!window.ReactNativeWebView && window.navigator.userAgent.indexOf('MarppleApp') > -1;
export const getOSVersion = () => parser.getOS()?.version;

export const alert = ({ content = '', ok = '', msg_width }) => {
  const prom = $.alert(html`
    <div class="custom-body">
      <p>${content}</p>
    </div>
  `);

  setTimeout(() => {
    const el = $qs('.don_dialog_msg');
    if (el) $setAttr({ type: 'alert' }, el);
    if (ok) go(el, $find('.ok'), $setText(ok));
    if (msg_width) go(el, $find('.msg'), $setCss({ maxWidth: msg_width }));
    if (msg_width) go(el, $find('.custom-body'), $setCss({ maxWidth: msg_width }));
  }, 0);

  return prom;
};

export const titleAlert = ({
  title,
  message,
  content = '',
  ok = '',
  msg_width,
  width,
  style = { content_color: 'BK' },
}) => {
  const prom = $.alert(html`
    <div class="custom-body">
      ${title ? html` <span class="omp-msg-title">${title}</span>` : ''}
      <span
        ${OMPCoreUtilS.createStyleSet(style)}
        class="omp-msg-message">${message || content}</p>
    </div>
  `);

  setTimeout(() => {
    const el = $qs('.don_dialog_msg');
    if (el) $setAttr({ type: 'alert_title' }, el);
    if (ok) go(el, $find('.ok'), $setText(ok));
    if (msg_width) go(el, $find('.msg'), $setCss({ maxWidth: msg_width }));
    if (msg_width) go(el, $find('.custom-body'), $setCss({ maxWidth: msg_width }));
    if (width) go(el, $find('.content'), $setCss({ width }));
  }, 0);

  return prom;
};

export const confirm = ({
  content = '',
  title,
  cancel = '',
  ok = '',
  min_width,
  width,
  style = { color_reverse: false, row_reverse: false, column: false },
}) => {
  const prom = $.confirm(html`
    <div class="custom-body">
      ${title ? html` <span class="omp-msg-title">${title}</span>` : ''}
      <p class="omp-msg-message">${content}</p>
    </div>
  `);

  setTimeout(() => {
    const el = $qs('.don_dialog_msg');
    if (el) $setAttr({ type: 'confirm' }, el);
    if (cancel) {
      go(el, $find('.cancel'), $setText(cancel));
    }
    if (ok) {
      go(el, $find('.ok'), $setText(ok));
    }
    if (min_width) go(el, $find('.content'), $setCss({ minWidth: min_width }));
    if (width) go(el, $find('.content'), $setCss({ width }));
    if (style.color_reverse) {
      go(
        [$find('.ok', el), $find('.cancel', el)],
        each(
          $setAttr({
            color_reverse: style.color_reverse,
          }),
        ),
      );
    }
    if (style.row_reverse) {
      go(el, $find('.buttons'), $setCss({ flexDirection: 'row-reverse' }));
    }

    go(el, $find('.buttons'), (buttons_el) => {
      buttons_el.dataset.column = style.column;
    });
  }, 0);

  return prom;
};

export const fileUploadAlert = ({
  uploader_selector = 'button',
  content = '',
  show_ok = true,
  append_event = () => {},
  upload_disabled_condition = () => false,
}) => {
  $.alert(html`${content}`);
  const el = $qs('.don_dialog_msg');
  if (!show_ok) go(el, $find('.ok'), $hide);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const file_uploader = go(el, $find(`${uploader_selector}`));
      if (file_uploader) {
        file_uploader.addEventListener('click', async () => {
          if (!upload_disabled_condition(file_uploader)) {
            try {
              const file = await PdfF.triggerPdfFileInput();
              resolve({ file });
            } catch (err) {
              reject(err);
            } finally {
              $remove(el);
            }
          }
        });
      }
      if (append_event) {
        append_event(el);
      }
    }, 0);
  });
};
const marpple_blazy = new BLazy({
  selector: '.omp-image-lazy',
  offset: window.innerHeight,
});
export const imageLazyLoad = (lazy) => {
  (lazy || marpple_blazy).revalidate();
};

/**
 * form_el 또는 FormData를 object로 변환
 * @param {HTMLFormElement|FormData} form
 * @return {object}
 */
export const formDataToObject = (form) => {
  let form_data;
  if (form instanceof HTMLFormElement) {
    form_data = new FormData(form);
  } else if (form instanceof FormData) {
    form_data = form;
  } else {
    throw new Error('form is not valid.');
  }

  return go(
    form_data.entries(),
    object,
    mapObject((str) => str.trim()),
  );
};

export const bodyFixed$ = (be_fix) => {
  if (be_fix) {
    if (!isMobile() && $height(document.body) >= window.innerHeight) {
      $setCss({ 'overflow-y': 'scroll' }, document.body);
    }

    document.body.fixed_top = $scrollTop(window);
    go(document.body, $addClass('body-fixed'), $setCss({ top: -document.body.fixed_top }));
  } else {
    go(document.body, $removeClass('body-fixed'), $setCss({ top: '', 'overflow-y': '' }));
    $setScrollTop(document.body.fixed_top, window);
    document.body.fixed_top = void 0;
  }
};

export const alertPdfFileExpired = async ({ product }) => {
  const selected_option_group = product?._?.selected_option_group;

  const quantity = product?.product_faces2?.biz?.quantity;
  const base_products_bp_option_groups = go(
    [selected_option_group.option_names].concat(
      quantity
        ? {
            option_group_name: T('cart_modal::quantity'),
            option_name: TT('my_page::landing::landing_10', { count: UtilS.commify(quantity) }),
          }
        : null,
    ),
    flatten,
    compact,
  );

  const selected_options_el = go(
    base_products_bp_option_groups,
    map((option) => {
      return `<div class="option">
                          <span class="option_group_name">${option.option_group_name}</span>
                          <span class="option_name">${option.option_name}</span>
                        </div>`;
    }),
    join(''),
  );

  return isMobile()
    ? await titleAlert({
        title: `${TT('pdf::expired::6')}`,
        content: `${TT('pdf::expired::7')}`,
        ok: `${TT('pdf::expired::10')}`,
      })
    : await titleAlert({
        title: `${TT('pdf::expired::6')}`,
        content: html`<div class="file-expired-alert">
          ${TT('pdf::expired::8')}
          <div class="file-expired-alert-content">
            <div class="title">${TT('pdf::expired::9')}</div>
            <div class="file-expired-alert-options">${selected_options_el}</div>
          </div>
        </div>`,
        ok: `${TT('pdf::expired::10')}`,
      });
};

export const calcOffset = (number, limit) => {
  if (number === 0) {
    return 0;
  }

  if (number <= limit) {
    return limit;
  }

  const base = Math.floor(number / limit) * limit;
  if (number % limit === 0) {
    return number;
  } else {
    return base + limit;
  }
};
