import { go } from 'fxjs/es';

function rowBlank(imageData, width, y) {
  for (let x = 0; x < width; ++x) {
    if (imageData.data[y * width * 4 + x * 4 + 3] > 40) return false;
  }
  return true;
}

function columnBlank(imageData, width, x, top, bottom) {
  for (let y = top; y < bottom; ++y) {
    if (imageData.data[y * width * 4 + x * 4 + 3] > 40) return false;
  }
  return true;
}

export const getImageDataSize = function (imageData) {
  var width = imageData.width;
  let top = 0;
  let bottom = imageData.height;
  let left = 0;
  let right = imageData.width;

  while (top < bottom && rowBlank(imageData, width, top)) ++top;
  while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1)) --bottom;
  while (left < right && columnBlank(imageData, width, left, top, bottom)) ++left;
  while (right - 1 > left && columnBlank(imageData, width, right - 1, top, bottom)) --right;

  bottom = imageData.height - bottom;
  right = imageData.width - right;
  const height = imageData.height - top - bottom;
  var width = imageData.width - left - right;
  return { top, bottom, left, right, width, height };
};

export const getSizeFromCanvasInMaker = function (target_canvas) {
  const ratio = 1;
  const _width = G.mp.maker.CANVAS_WIDTH / ratio;
  const _height = G.mp.maker.CANVAS_HEIGHT / ratio;

  const size = _p.go(
    document.createElement('canvas'),
    _p.tap(function (canvas) {
      canvas.width = _width;
      canvas.height = _width;
    }),
    _p.add_arg((canvas) => canvas.getContext('2d')),
    function (canvas, ctx22) {
      ctx22.drawImage(target_canvas, 0, 0, _width, _height);
      return _p.go(
        ___,
        () => ctx22.getImageData(0, 0, canvas.width, canvas.height),
        getImageDataSize,
        _p.map_object(function (v) {
          return parseInt(v * ratio);
        }),
      );
    },
  );

  size.y_diff = -((size.top - size.bottom) / 2);
  size.x_diff = -((size.left - size.right) / 2);
  return size;
};

export const getTargetSizeByAttr = function (attr) {
  const el_canvas = document.createElement('canvas');
  const canvas = new fabric.StaticCanvas(el_canvas, {
    enableRetinaScaling: false,
    width: G.mp.maker.CANVAS_WIDTH_ORIGIN,
    height: G.mp.maker.CANVAS_WIDTH_ORIGIN,
  });
  return go(G.mp.maker.from_cv_attrs_for_maker(attr, 1, true), function (cv_obj) {
    cv_obj.visible = true;
    cv_obj.globalCompositeOperation = 'source-over';
    canvas.add(cv_obj);
    canvas.renderAll();
    return getSizeFromCanvasInMaker(el_canvas);
  });
};
