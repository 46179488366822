import { each, filter, flatMap, go, map, sumBy } from 'fxjs';
import { countCharacters, getFaceInfoText, isDarkColor, pdfUtil, removeKoreanLetters } from './pdfHelpers.js';
import { getJustifyCanvas } from '../../../../../../modules/Maker/F/canvas_trim.js';
import { compact, join } from 'fxjs/es';
import { UtilArrayS } from '../../../../../../modules/Util/Array/S/Function/module/UtilArrayS.js';

export function renderHeader({ doc, page_margin, qr_image }) {
  const marpple_logo_image_url =
    '//s3.marpple.co/files/u_1187078/2023/8/original/2ce5855d3f91711c6f63949cc3d2466b8f330f421.png';
  const logo_size = 7;

  // Marpple Logo
  doc.addImage(marpple_logo_image_url, 'png', page_margin + 5, page_margin + 4, logo_size, logo_size);
  // Title
  pdfUtil.drawText({
    doc,
    size: 22,
    isBold: true,
    text: 'WORK ORDER SHEET',
    x: page_margin + 17,
    y: 20,
  });
  // Qr Code (projection 검색)
  doc.addImage(qr_image, 'png', 188, page_margin + 2, 10, 10);
}

const sanitizedString = (input_string) =>
  input_string
    ? input_string.replace(/[\r\n]+/g, ' ').replace(/[^\w\sㄱ-ㅎㅏ-ㅣ가-힣ぁ-んァ-ンa-zA-Z]/g, '')
    : '';

const limitString = (input_string, limit) => input_string.substring(0, limit);

function sanitizeAndLimitString({ text_arr, limit }) {
  return go(
    text_arr,
    map((text) => {
      const res = sanitizedString(text);
      return limitString(res, limit);
    }),
    join('\n'),
  );
}

export function renderFooter({ doc, page_margin, notes }) {
  const note_sentences = sanitizeAndLimitString({ text_arr: notes, limit: 65 });

  const memo_y = doc.getPageHeight() - page_margin - 2.5;

  /* 메모 */
  if (note_sentences != null && note_sentences !== '') {
    pdfUtil.drawTextWithBox({
      doc,
      font: 'malgun',
      size: 10,
      text: 'Memo',
      x: page_margin + 4,
      y: memo_y - 2.5,
      w: 14,
      text_h: 8,
      fillColor: '#FFA500',
      textColor: 0,
      rect_round: 1,
    });

    pdfUtil.drawText({
      doc,
      size: 8,
      font: 'malgun',
      text: note_sentences,
      x: page_margin + 18,
      y: memo_y - 2.5 * notes.length,
    });
  }

  /* 마플 소유권 경고 문구 */
  pdfUtil.drawText({
    doc,
    size: 8,
    font: 'malgun',
    text: '※ 본 POD 작업 지시서는 마플 코퍼레이션의 소유입니다.',
    x: page_margin + 3,
    y: doc.getPageHeight() - page_margin + 3,
  });
}

export function renderShiboriTable({ doc, bp_sizes, top, left }) {
  const cell_data = go(
    bp_sizes,
    filter(({ shibori_cm }) => shibori_cm != null && shibori_cm > 0),
    map((bp_size) => ({ size: bp_size.name, shibori: `${Number(bp_size.shibori_cm).toFixed(1)}cm` })),
  );

  const default_cell_style = {
    overflow: 'hidden',
    cellPadding: 1,
    fontSize: 8,
    lineWidth: 0.2,
    lineColor: 0,
  };

  if (!UtilArrayS.isArrayOk(cell_data)) return;
  doc.autoTable({
    startY: Math.round(top),
    margin: { left: Math.round(left) + 1 },
    headStyles: {
      cellPadding: 1,
      lineWidth: 0.2,
      lineColor: 0,
      fontSize: 8,
      overflow: 'hidden',
      fillColor: 200,
      textColor: 0,
      valign: 'middle',
    },
    columns: [
      { header: 'Size', dataKey: 'size' },
      { header: 'Shibori', dataKey: 'shibori' },
    ],
    columnStyles: {
      size: { ...default_cell_style, cellWidth: 14 },
      shibori: { ...default_cell_style, cellWidth: 13 },
    },
    body: cell_data,
  });
}

export function renderOptionTable({ doc, up_cs, top, right }) {
  const cell_data = go(
    up_cs,
    flatMap((upc) => {
      return go(
        upc._.up_c_ss,
        filter((up_c_s) => up_c_s.quantity > 0),
        map((up_c_s) => {
          const qty = up_c_s.quantity;
          const { base_product_color, base_product_size } = up_c_s._;
          const color = base_product_color.name;
          const size = removeKoreanLetters(base_product_size.name);
          return { color, size, qty: `${qty}`, color_code: base_product_color.color_code };
        }),
      );
    }),
  );

  const cell_color_w = 30;
  const cell_size_w = 35;
  const table_width = 80;
  const cell_qty_w = table_width - cell_color_w - cell_size_w;

  const columnWidths = [cell_color_w, cell_size_w, cell_qty_w];
  const header_height = 10;
  const cell_height = 8;

  const default_cell_style = {
    minCellHeight: cell_height,
    lineWidth: 0.5,
    lineColor: [0, 0, 0],
    fontSize: 12,
    overflow: 'hidden',
  };

  doc.autoTable({
    startY: top,
    margin: { left: right - table_width },
    headStyles: {
      ...default_cell_style,
      fillColor: 200,
      fontStyle: 'normal',
      textColor: 0,
      valign: 'middle',
      fontSize: 14,
      minCellHeight: header_height,
    },
    columns: [
      { header: 'Color', dataKey: 'color' },
      { header: 'Size', dataKey: 'size' },
      { header: 'Qty', dataKey: 'qty' },
    ],
    body: cell_data,
    columnStyles: {
      color: {
        font: 'malgun',
        cellWidth: columnWidths[0],
        ...default_cell_style,
      },
      size: {
        font: 'helvetica',
        cellWidth: columnWidths[1],
        ...default_cell_style,
      },
      qty: {
        font: 'helvetica',
        cellWidth: columnWidths[2],
        halign: 'center',
        ...default_cell_style,
      },
    },
    didParseCell: function (data) {
      if (data.column.dataKey === 'color') {
        const color_code = data.row.raw.color_code;

        if (color_code) {
          // Set the cell background color
          data.cell.styles.fillColor = color_code;
          data.cell.styles.textColor = isDarkColor(color_code) ? 255 : 0;
        }
      }
    },
  });

  return { table_width, table_height: header_height + cell_height * cell_data.length };
}

export function renderThumbNails({ doc, pfs, x, y, w, h, text: { offset_x, offset_y } }) {
  if (!pfs.length) return;
  const thumbs_with_render_info = go(
    pfs,
    compact,
    map((pf) => {
      const thumbnail_canvas = getJustifyCanvas(pf.canvas, 100);

      const sf = w / thumbnail_canvas.width;
      return { pf, thumbnail_canvas, render_size: { width: w, height: thumbnail_canvas.height * sf } };
    }),
  );

  const total_vertical_height = sumBy(({ render_size }) => render_size.height, thumbs_with_render_info);

  let sf = h / Math.max(h, total_vertical_height);
  let start_y = y;
  go(
    thumbs_with_render_info,
    each(({ pf, thumbnail_canvas, render_size }) => {
      const render_width = render_size.width * sf;
      const render_height = render_size.height * sf;
      const resource = thumbnail_canvas.toDataURL('image/png');
      const pos_x = x + (w - render_width) / 2;
      const pos_y = start_y;

      /* 썸네일 이미지 렌더링 */
      doc.addImage(resource, 'PNG', pos_x, pos_y + 3, render_width, render_height);

      const { face_name_str, design_size } = getFaceInfoText({ pf });
      /* 면 이름 렌더링 */
      if (face_name_str) {
        const { korean, english, special } = countCharacters(face_name_str);

        const face_text_width = 3 + korean * 4.2 + (english + special) * 2.1;
        const face_text_y = pos_y + offset_y;
        pdfUtil.drawTextWithBox({
          doc,
          font: 'malgun',
          size: 11,
          text: face_name_str,
          x: x + offset_x,
          y: face_text_y,
          w: face_text_width,
          text_h: 8,
          fillColor: 0,
          textColor: '#fff',
          rect_round: 1,
        });
        design_size.line > 0 &&
          pdfUtil.drawText({
            doc,
            size: 12,
            text: design_size.str,
            x: x + offset_x + face_text_width + 1,
            y: face_text_y,
          });
      }

      renderShiboriTable({ doc, bp_sizes: pf.bp_sizes, top: pos_y + offset_y + 5, left: x + offset_x - 2 });

      /* 다음 그릴 canvas position y 값을 누적 */
      start_y += render_height;
    }),
  );
}
