import {
  ADDITIONAL_SHIPPING_COSTS,
  SHIPPING_COSTS,
  SHIPPING_TYPE_COMBINED,
  SHIPPING_TYPE_PARCEL,
  SHIPPING_TYPE_PARTITION,
  SHIPPING_TYPE_QUICK,
  SHIPPING_TYPE_VISIT,
} from './constant.js';
import { isAfter } from 'date-fns';
import { last } from 'fxjs/es';

export const computeCostParcel = ({ amount_for_free, cost, order_amount }) =>
  amount_for_free > order_amount ? cost : 0;

const computeCostOthers = ({ amount_for_free, cost, order_amount }) =>
  amount_for_free > order_amount ? 0 : -1 * cost;

const shipping_type_func_table = {
  [SHIPPING_TYPE_PARCEL]: computeCostParcel,
  [SHIPPING_TYPE_PARTITION]: computeCostParcel,
  [SHIPPING_TYPE_QUICK]: computeCostOthers,
  [SHIPPING_TYPE_VISIT]: computeCostOthers,
  [SHIPPING_TYPE_COMBINED]: computeCostOthers,
};

function findCost(collabo_type, ordered_at = new Date()) {
  const cost =
    SHIPPING_COSTS.find(({ date }) => {
      return (
        (typeof ordered_at === 'string' ? new Date(ordered_at + ' 00:00:00 GMT+0900') : ordered_at) >= date
      );
    }) || last(SHIPPING_COSTS);
  return cost[collabo_type];
}
export function getAdditionalShippingCost(ordered_at = new Date()) {
  const cost =
    ADDITIONAL_SHIPPING_COSTS.find(({ date }) => {
      return isAfter(new Date(ordered_at), date);
    }) || last(ADDITIONAL_SHIPPING_COSTS);
  return cost.fee;
}

export const computeShippingCostKR = ({ collabo_type, shipping_type, order_amount, ordered_at }) => {
  const [amount_for_free, cost] = findCost(collabo_type, ordered_at);
  return shipping_type_func_table[shipping_type]({
    amount_for_free,
    cost,
    order_amount,
  });
};
