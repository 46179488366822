import { go, html, filter, reject, strMap } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { OMPCartAddColorTmplS } from './module/OMPCartAddColorTmplS.js';

export const cartAddColorColorsTmplMo = ({
  base_product,
  user_product,
  base_product_colors,
  up_selector,
  selector,
  selected_bpc_id,
}) => {
  const added_colors = go(
    base_product_colors,
    filter((bpc) => bpc.added),
  );

  const enable_colors = go(
    base_product_colors,
    reject((bpc) => bpc.added),
  );

  return html`<div
    class="color_body"
    data-fx-json="${$dataStr({
      base_product_colors: base_product,
      user_product,
    })}"
    _sel="${up_selector}"
  >
    <div class="title">${TT('cart::cart_11')}</div>
    <div class="iscroll_wrapper">
      <div class="colors_wrapper">
        <div class="added_colors">
          <input type="checkbox" id="added_colors_title" />
          <label for="added_colors_title" class="colors_title added"
            ><span>${TT('cart::cart_33')}</span
            >${OMPCartAddColorTmplS.openIcon}${OMPCartAddColorTmplS.closeIcon}</label
          >
          <div class="tab_wrapper">
            <div class="colors_container" _sel="${selector}">
              ${go(
                added_colors,
                strMap((bpc) => {
                  return html`<div
                    _id="${bpc.id}"
                    _sel="./(#${bpc.id})"
                    class="color_item ${bpc.id === selected_bpc_id ? 'selected' : ''} ${bpc.added
                      ? 'added'
                      : ''}"
                    color_code="${bpc.color_code}"
                    need_ccc="${bpc.need_ccc}"
                  >
                    <div class="code_wrap">
                      <div
                        class="code"
                        style="background: ${bpc.color_code2
                          ? `linear-gradient(135deg, ${bpc.color_code} 50%, ${bpc.color_code2} 50%)`
                          : bpc.color_code}"
                      ></div>
                    </div>
                    <div class="name">${bpc['name' + _en]}</div>
                  </div>`;
                }),
              )}
            </div>
          </div>
        </div>
        <div class="enable_colors">
          <div class="colors_title">${TT('cart::cart_34')}</div>
            ${
              enable_colors.length > 0
                ? html`<div class="colors_container" _sel="${selector}">
                    ${go(
                      enable_colors,
                      strMap((bpc) => {
                        return html`<div
                          _id="${bpc.id}"
                          _sel="./(#${bpc.id})"
                          class="color_item ${bpc.id === selected_bpc_id ? 'selected' : ''} ${bpc.added
                            ? 'added'
                            : ''}"
                          color_code="${bpc.color_code}"
                          need_ccc="${bpc.need_ccc}"
                        >
                          <div class="code_wrap">
                            <div
                              class="code"
                              style="background: ${bpc.color_code2
                                ? `linear-gradient(135deg, ${bpc.color_code} 50%, ${bpc.color_code2} 50%)`
                                : bpc.color_code}"
                            ></div>
                          </div>
                          <div class="name">${bpc['name' + _en]}</div>
                        </div>`;
                      }),
                    )}
                  </div>`
                : `<div class="message">${TT('cart::cart_35')}</div>`
            }
          </div>
        </div>
      </div>
    </div>
  </div>`;
};
