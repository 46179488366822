import { mauth } from '@marpple/auth';

await mauth.init({
  host: process.env.AUTH_DOMAIN,
  client_id: process.env.CLIENT_ID,
  version: 1,
  lang: 'kr',
});

mauth.addEventListener('Logout', () => {
  return location.reload();
});

mauth.addEventListener('DifferentUser', () => {
  return location.reload();
});
