import { $addClass, $attr, $find, $qs, $removeClass, $setCss } from 'fxdom/es';
import { svgs } from '../S/constant.js';

const status_color = {
  ready: '#e8d600',
  on: '#00bfff',
  completed: '#4cc37f',
};

const status_svg_pathdata = {
  ready: svgs.sad_face,
  on: svgs.neutral_face,
  completed: svgs.happy_face,
};

export const blinkRow = (row_el, timeout) => {
  const task_status = $attr('t_status', row_el);
  const animation_class_name = `blink_${task_status}`;
  $addClass(animation_class_name, row_el);
  window.setTimeout(() => $removeClass(animation_class_name, row_el), timeout);
};

export const popupFace = (target_el = document, status) => {
  const popup_classname = 'status_popup';

  const exist_el = $find(`.${popup_classname}_container`, target_el);
  exist_el && (exist_el.style.display = 'none');

  const svg_face = status_svg_pathdata[status];

  const div_face_container_el = document.createElement('div');
  div_face_container_el.class = `${popup_classname}_container`;

  const div_face_bg_el = document.createElement('div');

  const div_face_svg_el = document.createElement('div');
  div_face_svg_el.className = popup_classname;
  div_face_svg_el.style.backgroundColor = status_color[status];
  div_face_svg_el.innerHTML = svg_face;

  div_face_container_el.appendChild(div_face_bg_el);
  div_face_container_el.appendChild(div_face_svg_el);

  target_el.appendChild(div_face_container_el);
  const size = { width: 500, height: 500 };
  $setCss(
    {
      position: 'fixed',
      backgroundColor: 'white',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      zIndex: Number.MAX_SAFE_INTEGER,
    },
    div_face_bg_el,
  );
  $setCss(
    {
      position: 'fixed',
      width: `${size.width}px`,
      height: `${size.height}px`,
      top: `${(window.innerHeight - size.height) / 2}`,
      left: `${(window.innerWidth - size.width) / 2}`,
    },
    div_face_svg_el,
  );

  setTimeout(() => {
    target_el.removeChild(div_face_container_el);
    if ($qs('.task_title.tobe_updated')) {
      $.don_loader_start();
    } else {
      $.don_loader_end();
    }
  }, 800);
};
