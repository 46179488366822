import {
  keys,
  html,
  go,
  map,
  join,
  zipWithIndexL,
  filter,
  indexBy,
  some,
  every,
  groupBy,
  mapObject,
  flat,
  sortBy,
  reject,
  sortDesc,
} from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import moment from 'moment';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import {
  ARRIVED_TABLE_COLUMN,
  INSPECT_AND_RETURN_TABLE_COLUMN,
  INSPECT_TABLE_COLUMN,
  LGL_STATUSES,
  LGL_WAYBILL_TYPE,
  MESSAGES,
  OUTBOUND_PACKING_TABLE_COLUMN,
  OUTBOUND_REQUESTED_TABLE_COLUMN,
  OUTBOUND_SHIPOUT_TABLE_COLUMN,
  PACKAGING_QUEUE_TABLE_COLUMN,
  PACKAGING_TABLE_COLUMN,
  PACKED_TABLE_COLUMN,
  PRODUCT_LIST_TABLE_COLUMN,
  REBOUND_PACKAGING_TABLE_COLUMN,
  REBOUND_QUEUE_TABLE_COLUMN,
  RECEIVED_TABLE_COLUMN,
  REQUESTED_TABLE_COLUMN,
  RETURN_HALF_TABLE_COLUMN,
  RETURN_TABLE_COLUMN,
  SHIPOUT_TABLE_COLUMN,
  TABS,
  TRACKING_OVERALL_STATUS_TABLE_COLUMN,
} from '../../../S/Constant/lgl.js';
import { getInboundTabFooterMessage, getProductInfoByUpc, getProducts } from '../../F/Function/inbound.js';
import { getPackagingFooterStatus, getStatusAt } from '../../F/Function/status.js';
import { bubbleIcon, failDocumentIcon, leftIcon, rightIcon } from './tmpl.icon.js';
import { makeTableHtml } from './tmpl.table.js';

export function makeTabButtonsHtml({ selected_tab_id, error_tab_id }) {
  return html`
    <div class="tab_buttons">
      ${go(
        TABS,
        map(
          ({ id, title, tab_name }) => html`<button
            class="tab_button ${error_tab_id === id ? ' error' : ''}"
            selected="${selected_tab_id === id}"
            tab_name="${tab_name}"
            tab_index="0"
          >
            <span>${title}</span>
          </button>`,
        ),
        join(''),
      )}
    </div>
  `;
}

function makeTabNavHtml({ key, title, selected }) {
  return html`<button class="tab_item ${selected ? 'selected' : ''}" id="tab_item_${key}" data-id="${key}">
    ${title}
  </button>`;
}

function makeTabContent({ key, data, contentTemplate, selected }) {
  return html`<div
    id="tab_content_${key}"
    class="tab_content ${selected ? 'selected' : ''}"
    data-id="${key}"
    data-fx-json="${$dataStr(data)}"
  >
    ${contentTemplate}
  </div>`;
}

export function makeTabsWithTablesHtml({ data }) {
  const tabNavs = go(data, map(makeTabNavHtml), join(''));
  const tabContents = go(data, map(makeTabContent), join(''));
  return html` <div class="tabs column">
    <nav>${tabNavs}</nav>
    ${tabContents}
  </div>`;
}

function makeWaybillTabsHtml({ inbound, columns = PACKAGING_TABLE_COLUMN, rebound = false }) {
  // const requested_quantities = getInboundOrdersRequestedQuantity({ inbound_orders: inbound });
  const data = go(
    inbound,
    zipWithIndexL,
    map(
      ([
        idx,
        {
          current_status,
          id: key,
          waybill_no_domestic: waybill_no,
          _: { lgl_items },
        },
      ]) => {
        const data = go(
          lgl_items,
          map(
            ({
              id: lgl_item_id,
              user_product_id: upcs_id,
              rebounded_lgl_item_id,
              _: {
                up_c,
                up_c_ss,
                lgl_item_quantities: { inbound_order_requested_quantity: loaded_quantity },
              },
            }) => {
              const product_info = getProductInfoByUpc({ up_c, up_c_ss });

              return {
                ...product_info,
                upcs_id,
                lgl_item_id,
                loaded_quantity,
                rebounded_lgl_item_id,
                // quantity_diff: requested_quantities[upcs_id] - product_info.quantity,
              };
            },
          ),
        );

        const items = rebound
          ? go(
              data,
              indexBy(({ lgl_item_id }) => lgl_item_id),
            )
          : go(
              data,
              indexBy(({ upcs_id }) => upcs_id),
            );

        const error = false;

        const items_count = go(items, keys).length;
        const items_count_el =
          items_count > 0
            ? html`<span class="badge items_count${error ? ' danger' : ''}">${items_count}</span>`
            : '';

        const backdrop_el = rebound && current_status !== 'PACKING' ? `<div class="backdrop"></div>` : '';
        return {
          title: html`<span class="strong title">${rebound ? '재입고 ' : ''}운송장 ${idx + 1}</span>
            <span class="waybill_no">${waybill_no}</span>
            ${items_count_el}`,
          name: 'waybill',
          idx,
          key,
          data: inbound[idx],
          contentTemplate: `${backdrop_el}${makeTableHtml({
            data: items,
            columns,
            no_data_message: MESSAGES.PACKAGING.NO_DAT_IN_WAYBILL,
          })}`,
          selected: idx === 0,
        };
      },
    ),
  );

  return makeTabsWithTablesHtml({ data });
}

function makePackagingHtml({ products, inbound }) {
  return `<div class="table_left queue_table column">
                <div class="header">
                    <h4 class="title">주문 상품 리스트</h4>
                </div>
                ${makeTableHtml({
                  data: products,
                  columns: PACKAGING_QUEUE_TABLE_COLUMN,
                  no_data_message: MESSAGES.PACKAGING.NO_DATA_IN_LIST,
                })}
          </div>
          <div class="transfer_tool column">
              <button type="button" class="right disabled">
                ${rightIcon()}
              </button>
              <button type="button" class="left disabled">
                ${leftIcon()}
              </button>
          </div>
          <div class="table_right">
            ${makeWaybillTabsHtml({ inbound })}
          </div>`;
}

function makeOrderListHtml({ products, rebound = false }) {
  return `<div class="header"><h4 class="title">${
    rebound ? '재입고' : '주문'
  } 상품 리스트</h4></div>${makeTableHtml({
    data: products,
    columns: PRODUCT_LIST_TABLE_COLUMN,
  })}`;
}

export function makePackagingTabInitialHtml({ projection }) {
  const products = getProducts({ user_products: projection._.ups });
  return html`<div class="tab_wrapper packaging" data-fx-json="${$dataStr(projection)}">
    <div class="body">
      <div class="table_wrapper column">${makeOrderListHtml({ products })}</div>
    </div>
    ${makePackagingTabFooterHtml({
      message: MESSAGES.PACKAGING.BEFORE_DELIVER,
      action_button_template: html`<div class="buttons">
        <button class="secondary waybill">운송장 발급하기</button>
      </div>`,
    })}
  </div>`;
}

export function makeInboundTabActionButtonHtml({
  status,
  waybill_index = 0,
  rebound = false,
  tab_title = '',
}) {
  const title = rebound ? `${tab_title} ` : '';
  const request_button = `<button class="request_${rebound ? 'rebound' : 'inbound'} ${
    rebound ? 'button-lg primary' : 'dark'
  }">${title}입고 요청하기</button>`;

  const cancel_request_button = `<button class="cancel_request_${
    rebound ? 'rebound button-lg' : 'inbound'
  } danger">${title}입고 요청 취소</button>`;

  const tracking_button = `<button class="tracking_inbound normal ${rebound ? 'button-lg' : ''}">${
    rebound ? title : `운송장 ${waybill_index + 1}`
  } 배송조회</button>`;

  const return_request_button = `<button class="return_request_inbound gray disabled">회수 요청하기</button>`;
  let action_button_html = '';

  if (status === 'PACKED') {
    action_button_html = request_button;
  } else if (status === 'REQUESTED') {
    action_button_html = cancel_request_button;
  } else if (status === 'SHIPOUT' || status === 'ARRIVED') {
    action_button_html = tracking_button;
  } else if (status === 'INSPECTING' || status === 'INSPECTED') {
    action_button_html = return_request_button;
  }

  return html` <div class="buttons">${action_button_html}</div> `;
}

export function makeReboundTabActionButtonHtml({
  empty_left_products,
  packing = true,
  after_requested = true,
  waybill_index,
  waybill_count,
}) {
  const add_waybill_button = `<button class="secondary rebound_waybill">운송장 ${
    waybill_count + 1
  } 추가하기</button>`;
  const delete_waybill_button = `<button class="danger delete_rebound_waybill">운송장${
    waybill_index + 1
  } 삭제하기</button>`;
  const complete_button = `<button class="primary rebound_packing_complete">운송장 ${
    waybill_index + 1
  } 포장 완료</button>`;
  const edit_button = `<button class="rebound_packing_edit ${
    after_requested ? 'disabled gray' : 'normal'
  }">포장 수정하기</button>`;

  return html`
    <div class="buttons">
      ${packing
        ? empty_left_products
          ? html`${delete_waybill_button}${complete_button}`
          : html`${add_waybill_button}${delete_waybill_button}`
        : html`${add_waybill_button}${edit_button}`}
    </div>
  `;
}

export function makePackagingTabActionButtonHtml({
  empty_left_products,
  packing = true,
  after_requested = true,
}) {
  const add_waybill_button = `<button class="secondary waybill">운송장 추가하기</button>`;
  const delete_waybill_button = `<button class="danger delete_waybill">운송장 삭제하기</button>`;
  const complete_button = `<button class="primary packing_complete">포장 완료하기</button>`;
  const edit_button = `<button class="packing_edit ${
    after_requested ? 'disabled gray' : 'normal'
  }">포장 수정하기</button>`;

  return html`
    <div class="buttons">
      ${packing
        ? empty_left_products
          ? html`${delete_waybill_button}${complete_button}`
          : html`${add_waybill_button}${delete_waybill_button}`
        : edit_button}
    </div>
  `;
}

export function makePackagingTabInProgressHtml({ projection, inbound_orders }) {
  const not_rebound_orders = go(
    inbound_orders,
    reject(({ is_rebound }) => is_rebound),
  );

  const products = go(
    getProducts({ user_products: projection._.ups }),
    filter(({ left_quantity }) => left_quantity > 0),
    indexBy(({ upcs_id }) => upcs_id),
  );

  const current_statuses = go(
    not_rebound_orders,
    map(({ current_status }) => current_status),
  );
  const packing = go(
    current_statuses,
    every((status) => status === 'PACKING'),
  );

  const after_packed = !packing;

  const after_requested = go(
    current_statuses,
    every((status) => status !== 'PACKING' && status !== 'PACKED'),
  );

  const none_empty_waybill = go(
    not_rebound_orders,
    map(({ _: { lgl_items } }) => lgl_items.length),
    some((count) => count > 0),
  );

  const empty_left_products = go(products, keys).length === 0;
  let footer_status = 'EMPTY_WAYBILL';

  if (packing) {
    footer_status = getPackagingFooterStatus({ empty_left_products, none_empty_waybill });
  } else {
    footer_status = 'PACKED';
  }

  const action_button_template = makePackagingTabActionButtonHtml({
    empty_left_products,
    packing,
    after_requested,
  });

  return html`<div class="tab_wrapper packaging" data-fx-json="${$dataStr(projection)}">
    <div class="body">
      ${after_packed ? `<div class="backdrop"></div>` : ''}
      <div class="table_wrapper">${makePackagingHtml({ products, inbound: not_rebound_orders })}</div>
    </div>
    ${makePackagingTabFooterHtml({
      message: MESSAGES.PACKAGING[footer_status],
      action_button_template,
    })}
  </div>`;
}

export function makePackagingTabFooterHtml({ message, action_button_template }) {
  return html`<div class="footer">
    <div class="wrapper">
      <p class="description">${message}</p>
      ${action_button_template}
    </div>
  </div>`;
}

export function makePackagingTabHtml({ projection, projection_status, inbound_orders }) {
  const is_inbound = inbound_orders.length > 0;
  let template = makePackagingTabInitialHtml({ projection, inbound_orders });

  if (
    projection_status === 'ordering' ||
    projection_status === 'before_print' ||
    projection_status === 'printing'
  ) {
    template = makeBeforePrintTabHtml({ projection });
  }

  if (is_inbound) {
    template = makePackagingTabInProgressHtml({ projection, inbound_orders });
  }

  return template;
}

export function makeInboundTabFooterHtml({ message, action_button_template, class_name = '' }) {
  return `<div class="footer ${class_name}">
    <div class="wrapper">
      <p class="description">${message}</p>
      ${action_button_template}
    </div>
  </div>`;
}

function makeInboundOrderRequestInfoHtml({ id, requested_at, shipout_at, arrived_at, received_at }) {
  return html`<div class="request_info">
    <span><strong>입고 요청 번호:</strong> ${id}</span>
    <span><strong>입고 요청 일시:</strong> ${moment(requested_at).format('YYYY.MM.DD HH:mm')}</span>
    ${shipout_at
      ? `<span><strong>집하 일시:</strong> ${moment(shipout_at).format('YYYY.MM.DD HH:mm')}</span>`
      : ''}
    ${arrived_at
      ? `<span><strong>도착 일시:</strong> ${moment(arrived_at).format('YYYY.MM.DD HH:mm')}</span>`
      : ''}
    ${received_at
      ? `<span><strong>입고 완료 일시:</strong> ${moment(received_at).format('YYYY.MM.DD HH:mm')}</span>`
      : ''}
  </div>`;
}
export function makeInboundOrdersHtml({ inbound_orders }) {
  const inbound_orders_by_type = go(
    inbound_orders,
    reject(({ current_status }) => current_status === 'PACKING'),
    groupBy(({ is_rebound }) => (is_rebound ? 'REBOUND' : 'INBOUND')),
  );

  const waybills = go(
    inbound_orders_by_type,
    keys,
    sortDesc,
    zipWithIndexL,
    map(([index, type]) => {
      return go(
        inbound_orders_by_type[type],
        zipWithIndexL,
        map(
          ([
            idx,
            {
              id: key,
              waybill_no_domestic: waybill_no,
              _: { lgl_items },
            },
          ]) => {
            const data = go(
              lgl_items,
              map(
                ({
                  id: lgl_item_id,
                  lgl_outbound_order_id,
                  user_product_id: upcs_id,
                  _: {
                    up_c,
                    up_c_ss,
                    lgl_item_quantities: { inbound_order_requested_quantity: loaded_quantity },
                    lgl_item_inbound_inspections,
                    lgl_return_orders,
                  },
                }) => {
                  const product_info = getProductInfoByUpc({ up_c, up_c_ss });

                  return {
                    ...product_info,
                    upcs_id,
                    lgl_item_id,
                    lgl_outbound_order_id,
                    loaded_quantity,
                    lgl_item_inbound_inspections,
                    lgl_return_orders,
                  };
                },
              ),
              indexBy(({ upcs_id }) => upcs_id),
            );

            const {
              current_status,
              id,
              _: { lgl_inbound_order_status_histories },
            } = inbound_orders_by_type[type][idx];

            const items_count = go(data, keys).length;
            const items_count_el =
              items_count > 0 && current_status === 'PACKED'
                ? html`<span class="badge items_count">${items_count}</span>`
                : '';

            // 검수 불량 + 회수 미요청 카운트
            const reject_items_count = go(
              data,
              filter(
                ({ lgl_item_inbound_inspections, lgl_return_orders }) =>
                  !lgl_item_inbound_inspections.is_ok && !lgl_return_orders,
              ),
            ).length;

            const reject_items_count_el =
              ['INSPECTING', 'INSPECTED'].includes(current_status) && reject_items_count > 0
                ? html`<span class="badge items_count danger">${reject_items_count}</span>`
                : '';

            let columns = PACKED_TABLE_COLUMN;
            if (current_status === 'REQUESTED') {
              columns = REQUESTED_TABLE_COLUMN;
            } else if (current_status === 'SHIPOUT') {
              columns = SHIPOUT_TABLE_COLUMN;
            } else if (current_status === 'ARRIVED') {
              columns = ARRIVED_TABLE_COLUMN;
            } else if (current_status === 'RECEIVED') {
              columns = RECEIVED_TABLE_COLUMN;
            } else if (['INSPECTED', 'INSPECTING'].includes(current_status)) {
              columns = INSPECT_TABLE_COLUMN;
            }
            const return_requested =
              go(
                data,
                filter(({ lgl_return_orders }) => lgl_return_orders),
              ).length > 0;

            if (return_requested) {
              columns = INSPECT_AND_RETURN_TABLE_COLUMN;
            }

            let request_info = '';

            if (
              ['REQUESTED', 'SHIPOUT', 'ARRIVED', 'RECEIVED', 'INSPECTING', 'INSPECTED'].includes(
                current_status,
              )
            ) {
              const { status_at: requested_at } = getStatusAt({
                histories: lgl_inbound_order_status_histories,
                status_text: 'REQUESTED',
              });
              const { status_at: shipout_at } = getStatusAt({
                histories: lgl_inbound_order_status_histories,
                status_text: 'SHIPOUT',
              });
              const { status_at: arrived_at } = getStatusAt({
                histories: lgl_inbound_order_status_histories,
                status_text: 'ARRIVED',
              });
              const { status_at: received_at } = getStatusAt({
                histories: lgl_inbound_order_status_histories,
                status_text: 'RECEIVED',
              });

              request_info = makeInboundOrderRequestInfoHtml({
                id,
                requested_at,
                shipout_at,
                arrived_at,
                received_at,
              });
            }

            return {
              title: html`<span class="strong title"
                  >${type === 'REBOUND' ? '재입고 ' : ''}운송장 ${idx + 1}</span
                >
                <span class="waybill_no">${waybill_no}</span>
                ${items_count_el}${reject_items_count_el}`,
              name: 'waybill',
              key,
              data: inbound_orders_by_type[type][idx],
              contentTemplate: `${makeTableHtml({
                data,
                columns,
              })}${request_info}`,
              selected: index === 0 && idx === 0,
            };
          },
        ),
      );
    }),
    flat,
  );

  const [inbound_order] = waybills;
  const {
    data: { current_status, is_rebound: rebound },
  } = inbound_order;

  const return_in_progress = false;
  const return_completed = false;
  const message = getInboundTabFooterMessage({
    current_status,
    return_in_progress,
    return_completed,
    rebound,
  });
  const action_button_template = makeInboundTabActionButtonHtml({
    status: current_status,
    rebound,
    tab_title: '재입고 운송장 1',
  });

  return html`<div class="tab_wrapper">
    <div class="body">
      <div class="table_wrapper">${makeTabsWithTablesHtml({ data: waybills })}</div>
    </div>
    ${makeInboundTabFooterHtml({
      message,
      action_button_template,
      class_name: !rebound && current_status === 'PACKED' ? 'primary' : '',
    })}
  </div>`;
}
export function makeInboundTabHtml({ inbound_orders }) {
  const empty = inbound_orders.length === 0;
  const packing = go(
    inbound_orders,
    map(({ current_status }) => current_status),
    every((status) => status === 'PACKING'),
  );

  if (empty || packing) {
    return makeEmptyInboundOrdersHtml();
  }

  return makeInboundOrdersHtml({ inbound_orders });
}

export function makeReturnTabHtml({ return_orders } = {}) {
  if (UtilS.isEmpty(return_orders)) {
    return makeEmptyReturnHtml();
  }

  // LGL-BUWHAL
  const inbound_return_orders = go(
    return_orders,
    reject(({ _: { lgl_items } }) => {
      const [first_lgl_item] = lgl_items;
      const { lgl_outbound_order_id } = first_lgl_item;
      return lgl_outbound_order_id;
    }),
  );

  const outbound_return_orders = go(
    return_orders,
    filter(({ _: { lgl_items } }) => {
      const [first_lgl_item] = lgl_items;
      const { lgl_outbound_order_id } = first_lgl_item;
      return lgl_outbound_order_id;
    }),
  );

  const has_inbound_outbound = inbound_return_orders.length > 0 && outbound_return_orders.length > 0;

  const getReturnOrdersItems = ({ orders, table_size_half }) => {
    return go(
      orders,
      zipWithIndexL,
      map(
        ([
          idx,
          {
            id: key,
            waybill_no_domestic: waybill_no,
            current_status,
            created_at,
            shipout_from_lgl_date,
            _: { lgl_items },
          },
        ]) => {
          const data = go(
            lgl_items,
            map(
              ({
                id: lgl_item_id,
                user_product_id: upcs_id,
                lgl_outbound_order_id,
                _: {
                  up_c,
                  up_c_ss,
                  lgl_item_quantities: { inbound_order_requested_quantity: loaded_quantity },
                  lgl_item_inbound_inspections,
                  lgl_item_outbound_inspections,
                  lgl_return_orders,
                },
              }) => {
                const product_info = getProductInfoByUpc({ up_c, up_c_ss });

                return {
                  ...product_info,
                  upcs_id,
                  lgl_item_id,
                  loaded_quantity,
                  lgl_outbound_order_id,
                  lgl_item_inbound_inspections,
                  lgl_item_outbound_inspections,
                  current_status,
                  lgl_return_orders,
                };
              },
            ),
            indexBy(({ upcs_id }) => upcs_id),
          );

          const orders_has_waybill_no = go(
            go(
              orders,
              filter(({ waybill_no_domestic }) => waybill_no_domestic),
            ),
          );

          const waybill_no_index = orders_has_waybill_no.findIndex(
            ({ waybill_no_domestic }) => waybill_no_domestic === waybill_no,
          );
          const request_info = `<div class="request_info">
              <span><strong>요청</strong> ${moment(created_at).format('YYYY.MM.DD HH:mm')}</span>
              ${
                shipout_from_lgl_date
                  ? `<span><strong>발송</strong> ${moment(shipout_from_lgl_date).format(
                      'YYYY.MM.DD HH:mm',
                    )}</span>`
                  : ''
              }
            </div>`;

          return {
            title: html`<span class="strong"
                >${current_status === 'REQUESTED' ? '회수 요청 진행중' : `운송장 ${waybill_no_index + 1}`}
              </span>
              <span class="waybill_no">${UtilS.isEmpty(waybill_no) ? '' : waybill_no}</span>`,
            name: 'waybill',
            idx,
            key,
            data: inbound_return_orders[idx],
            contentTemplate: `${makeTableHtml({
              data,
              columns: table_size_half ? RETURN_HALF_TABLE_COLUMN : RETURN_TABLE_COLUMN,
            })}${request_info}`,
            selected: idx === 0,
          };
        },
      ),
    );
  };

  const inbound_return_orders_data = getReturnOrdersItems({
    orders: inbound_return_orders,
    table_size_half: has_inbound_outbound,
  });

  const outbound_return_orders_data = getReturnOrdersItems({
    orders: outbound_return_orders,
    table_size_half: has_inbound_outbound,
  });

  const has_inbound_return_orders = inbound_return_orders.length > 0;
  return html`<div class="tab_wrapper">
    <div class="header"></div>
    <div class="body">
      <div class="table_wrapper return">
        ${has_inbound_outbound
          ? `<div class="inbound column h-fit">
               <h2 class="title">입고 회수</h2>
                ${makeTabsWithTablesHtml({ data: inbound_return_orders_data })}  
            </div>
            <div class="space-20"></div>
            <div class="outbound column h-fit">
                <h2 class="title">출고 회수</h2>
                ${makeTabsWithTablesHtml({ data: outbound_return_orders_data })}  
            </div>`
          : `<div class="column h-fit">
                <h2 class="title">${has_inbound_return_orders ? '입고' : '출고'} 회수</h2>
                ${makeTabsWithTablesHtml({
                  data: has_inbound_return_orders ? inbound_return_orders_data : outbound_return_orders_data,
                })}
            </div>`}
      </div>
    </div>
    <div class="footer">
      <div class="wrapper">
        <p class="description"></p>
      </div>
    </div>
  </div>`;
}

function emptyReboundHtml() {
  return html`<div class="tab_wrapper">
    <div class="header"></div>
    <div class="body">
      <div class="empty column">
        ${bubbleIcon()}
        <h2>${MESSAGES.REBOUND.EMPTY}</h2>
      </div>
    </div>
    <div class="footer">
      <div class="wrapper">
        <p class="description">${MESSAGES.REBOUND.NO_REBOUND_DATA}</p>
        <div class="buttons"><button class="rebound_waybill gray disabled">운송장 발급하기</button></div>
      </div>
    </div>
  </div>`;
}

export function makeReboundTabHtml({ inbound_orders }) {
  const inspected_orders = go(
    inbound_orders,
    filter(({ current_status }) => ['INSPECTING', 'INSPECTED'].includes(current_status)),
  );

  // 검수 불량 상품들
  const rejected_items = go(
    inspected_orders,
    map(({ _: { lgl_items } }) => lgl_items),
    flat,
    filter(
      ({ _: { lgl_item_inbound_inspections } }) =>
        !UtilS.isEmpty(lgl_item_inbound_inspections) && !lgl_item_inbound_inspections.is_ok,
    ),
  );

  const rebound_orders = go(
    inbound_orders,
    filter(({ is_rebound }) => is_rebound),
  );

  // 검수 불량 건이 없고, 재입고 주문도 없다면 내역이 없는 것
  if (rejected_items.length === 0 && rebound_orders.length === 0) {
    return emptyReboundHtml();
  }

  const rebounded_items = go(
    rebound_orders,
    map(({ _: { lgl_items } }) => lgl_items),
    flat,
    indexBy(({ rebounded_lgl_item_id }) => rebounded_lgl_item_id),
  );

  const not_rebound_items = go(
    rejected_items, // 검수 불량이 있다면
    reject(({ id }) => rebounded_items[id]), // 재입고 되지 않은 상품 조회
    map(
      ({
        id: lgl_item_id,
        user_product_id: upcs_id,
        _: {
          up_c,
          up_c_ss,
          lgl_item_quantities: { inbound_order_requested_quantity: loaded_quantity },
        },
      }) => {
        const product_info = getProductInfoByUpc({ up_c, up_c_ss });

        return {
          ...product_info,
          upcs_id,
          lgl_item_id,
          loaded_quantity: 0,
          left_quantity: loaded_quantity, // 불량시 입고 요청 수량
          quantity: loaded_quantity, // 불량시 입고 요청 수량
        };
      },
    ),
    indexBy(({ lgl_item_id }) => lgl_item_id),
  );

  const none_empty_waybill = go(
    inbound_orders,
    map(({ _: { lgl_items } }) => lgl_items.length),
    some((count) => count > 0),
  );

  const empty_left_products = go(not_rebound_items, keys).length === 0;

  let footer_el = html`<div class="footer">
    <div class="wrapper">
      <p class="description"><strong>운송장을 발급</strong>하면 운송장에 상품을 담을 수 있어요.</p>
      <div class="buttons">
        <button class="secondary rebound_waybill">운송장 발급하기</button>
      </div>
    </div>
  </div>`;

  if (rebound_orders.length > 0) {
    const [first_rebound_order] = rebound_orders;
    const { current_status } = first_rebound_order;

    const packing = current_status === 'PACKING';
    const after_requested = !['PACKING', 'PACKED'].includes(current_status);
    let footer_status = 'EMPTY_WAYBILL';

    if (packing) {
      footer_status = getPackagingFooterStatus({ empty_left_products, none_empty_waybill });
    } else {
      footer_status = 'PACKED';
    }

    footer_el = makePackagingTabFooterHtml({
      message: MESSAGES.PACKAGING[footer_status],
      action_button_template: makeReboundTabActionButtonHtml({
        empty_left_products,
        packing,
        after_requested,
        waybill_index: 0,
        waybill_count: rebound_orders.length,
      }),
    });
  }
  return html`<div
    class="tab_wrapper rebound"
    data-fx-json="${$dataStr(rebound_orders.length > 0 ? rebound_orders : inbound_orders)}"
  >
    <div class="body" data-fx-json="${$dataStr(inbound_orders)}">
      ${rebound_orders.length > 0
        ? `<div class="table_wrapper"><div class="table_left queue_table column">
              <div class="header">
                <h4 class="title">재입고 상품 리스트</h4>
              </div>
              ${makeTableHtml({
                data: not_rebound_items,
                columns: REBOUND_QUEUE_TABLE_COLUMN,
                no_data_message: MESSAGES.PACKAGING.NO_DATA_IN_LIST,
              })}
            </div>
            <div class="transfer_tool column">
                <button type="button" class="right disabled">
                  ${rightIcon()}
                </button>
                <button type="button" class="left disabled">
                  ${leftIcon()}
                </button>
            </div>
            <div class="table_right">
              ${makeWaybillTabsHtml({
                inbound: rebound_orders,
                columns: REBOUND_PACKAGING_TABLE_COLUMN,
                rebound: true,
              })}
            </div></div>`
        : `<div class="table_wrapper column">${makeOrderListHtml({
            products: not_rebound_items,
            rebound: true,
          })}</div>`}
    </div>
    ${footer_el}
  </div>`;
}

function makeFailedOutboundHtml({
  inbound_order_group,
  outbound_request_fail_reason,
  outbound_request_not_ready_reason,
}) {
  // LGL-BUWHAL
  const { projection_id, is_outbound_holding } = inbound_order_group;

  return html`<div class="tab_wrapper">
    <div class="header"></div>
    <div class="body">
      <div class="empty column">
        ${failDocumentIcon()}
        <h2 class="outbound_fail_title">출고 주문 생성 실패</h2>
        <p class="outbound_fail_description">
          ${outbound_request_fail_reason || outbound_request_not_ready_reason}
        </p>
      </div>
    </div>
    ${makePackagingTabFooterHtml({
      message: MESSAGES.OUTBOUND.FAILED,
      action_button_template: html`<div class="buttons">
        ${outbound_request_fail_reason
          ? '<button type="button" class="request_outbound dark">출고 주문 요청</button>'
          : html` <div class="toggle_container">
              <input
                type="checkbox"
                class="auto_request_outbound"
                ${is_outbound_holding ? '' : 'checked'}
                data-projection-id="${projection_id}"
              />
              <div class="slider round"></div>
            </div>`}
      </div>`,
    })}
  </div>`;
}

export function makeOutboundTabHtml({ projection_id, inbound_order_group, outbound_orders }) {
  if (!UtilS.isEmpty(inbound_order_group)) {
    const { outbound_request_fail_reason, outbound_request_not_ready_reason } = inbound_order_group;

    if (outbound_request_fail_reason || outbound_request_not_ready_reason) {
      return makeFailedOutboundHtml({
        inbound_order_group,
        outbound_request_fail_reason,
        outbound_request_not_ready_reason,
      });
    }
  }

  if (UtilS.isEmpty(outbound_orders)) {
    return makeEmptyOutboundHtml();
  }

  const { order_id, is_packing } = outbound_orders;
  const {
    _: { lgl_items },
  } = outbound_orders;

  const data = go(
    lgl_items,
    filter(({ projection_id: prj_id }) => prj_id === projection_id),
    map(
      ({
        id: lgl_item_id,
        lgl_outbound_order_id,
        user_product_id: upcs_id,
        _: {
          up_c,
          up_c_ss,
          lgl_item_quantities: {
            outbound_order_requested_quantity: requested_quantity,
            outbound_order_confirmed_quantity: confirmed_quantity,
          },
          lgl_item_inbound_inspections,
          lgl_item_outbound_inspections,
          lgl_return_orders,
        },
      }) => {
        const product_info = getProductInfoByUpc({ up_c, up_c_ss });
        return {
          ...product_info,
          upcs_id,
          lgl_item_id,
          lgl_outbound_order_id,
          requested_quantity,
          confirmed_quantity,
          lgl_item_inbound_inspections,
          lgl_item_outbound_inspections,
          lgl_return_orders,
        };
      },
    ),
    indexBy(({ upcs_id }) => upcs_id),
  );

  const all_outbound_orders_shipout = go(
    outbound_orders._.lgl_outbound_orders,
    every(({ current_status }) => current_status !== 'PACKED'),
  );
  const all_outbound_orders_packed = go(
    outbound_orders._.lgl_outbound_orders,
    every(({ current_status }) => current_status === 'PACKED'),
  );

  outbound_orders.projection_id = projection_id;
  return html`<div class="tab_wrapper" data-fx-json="${$dataStr(outbound_orders)}">
    <div class="header">
      <div><strong class="mr-2">출고 주문 번호</strong>${order_id}</div>
    </div>
    <div class="body">
      <div class="table_wrapper">
        ${makeTableHtml({
          data,
          columns:
            all_outbound_orders_packed || all_outbound_orders_shipout
              ? OUTBOUND_SHIPOUT_TABLE_COLUMN
              : is_packing
              ? OUTBOUND_PACKING_TABLE_COLUMN
              : OUTBOUND_REQUESTED_TABLE_COLUMN,
        })}
      </div>
    </div>
    <div class="footer">
      <div class="wrapper">
        <p class="description">
          ${all_outbound_orders_packed
            ? '모든 상품이 합포장이 완료되었어요. LGL 센터 출고 전까지 출고 요청 취소가 가능해요.'
            : all_outbound_orders_shipout
            ? '모든 상품이 합포장 되어 LGL 센터에서 출고되었어요. 오른쪽 버튼을 클릭하면, <strong>출고된 전체 상품 목록과 배송조회</strong>를 확인할 수 있어요.'
            : is_packing
            ? '출고 요청이 완료되었어요. 아직  합포장 진행 중이에요'
            : '출고 요청이 완료되었어요. 아직 합포장 진행 전이에요.'}
        </p>
        <div class="buttons">
          ${all_outbound_orders_shipout
            ? '<button class="show_all_outbound normal">출고 상품 전체보기</button>'
            : `<button class="cancel_request_outbound danger_alt ">출고 요청 취소하기</button>`}
        </div>
      </div>
    </div>
  </div>`;
}
export function makeTrackingTabHtml({ lgl_overall_status } = {}) {
  if (!lgl_overall_status) {
    return makeEmptyTrackingHtml();
  }

  const overall_status = go(
    lgl_overall_status,
    map(({ step: origin_step, status_at, waybill_no, status, created_at, id }) => {
      let step = origin_step;

      if (origin_step === 'INBOUND') {
        if (['PACKING', 'PACKED'].includes(status)) {
          step = 'PACKAGING';
        } else if (['INSPECTING', 'INSPECTED'].includes(status)) {
          step = 'INSPECTION';
        }
      } else if (origin_step === 'OUTBOUND') {
        if (['LOCAL_ARRIVED', 'LOCAL_CUSTOMS_CLEARING', 'LOCAL_DELIVERING', 'DELIVERED'].includes(status)) {
          step = 'LOCAL';
        }
      }

      return {
        id,
        status_at,
        step,
        waybill_no,
        status: LGL_STATUSES[origin_step][status],
        created_at,
      };
    }),
  );
  const groupByStatus = go(
    overall_status,
    groupBy(({ step }) => {
      if (['PACKAGING', 'INBOUND', 'INSPECTION'].includes(step)) {
        return 'INBOUND';
      } else if (['OUTBOUND', 'LOCAL'].includes(step)) {
        return 'OUTBOUND';
      } else {
        return step;
      }
    }),
    mapObject((v) =>
      go(
        v,
        filter(({ id }) => id),
        groupBy(({ id }) => id),
      ),
    ),
  );

  const tabs_by_waybill = go(
    groupByStatus,
    keys,
    filter((key) => ['INBOUND', 'RETURN', 'OUTBOUND'].includes(key)),
    zipWithIndexL,
    map(([index, step]) => {
      return go(
        groupByStatus[step],
        keys,
        sortBy((key) => groupByStatus[step][key][0].created_at),
        zipWithIndexL,
        map(([idx, id]) => {
          const [{ waybill_no }] = groupByStatus[step][id];
          return {
            title: `<strong>${LGL_WAYBILL_TYPE[step]} 운송장 ${idx + 1}</strong> ${
              UtilS.isEmpty(waybill_no) ? '' : `${waybill_no}`
            }`,
            name: waybill_no,
            idx: index + 1,
            key: waybill_no,
            data: groupByStatus[step][id],
            contentTemplate: makeTableHtml({
              data: groupByStatus[step][id],
              columns: TRACKING_OVERALL_STATUS_TABLE_COLUMN,
            }),
            selected: false,
          };
        }),
      );
    }),
    flat,
  );

  const total_tab = {
    title: `<strong>전체 현황 조회</strong>`,
    name: 'waybill',
    idx: 0,
    key: 0,
    data: overall_status,
    contentTemplate: makeTableHtml({
      data: overall_status,
      columns: TRACKING_OVERALL_STATUS_TABLE_COLUMN,
    }),
    selected: true,
  };

  const data = [total_tab, ...tabs_by_waybill];

  return html`<div class="tab_wrapper">
    <div class="body">
      <div class="table_wrapper">${makeTabsWithTablesHtml({ data })}</div>
    </div>
    <div class="footer"></div>
  </div>`;
}

export function makeHistoryTabHtml() {
  return html`<div class="tab_wrapper">
    <div class="header"><h1>History</h1></div>
    <div class="body"></div>
    <div class="footer"></div>
  </div>`;
}

export function makeErrorTabHtml({ err }) {
  return html`<div class="tab_wrapper">
    <div class="header"></div>
    <div class="body">
      <div class="error column">
        <h2>데이터 조회 중 오류가 발생했습니다.</h2>
        <p>${err.message}</p>
      </div>
    </div>
    <div class="footer"></div>
  </div>`;
}

export function makeBeforePrintTabHtml({ projection }) {
  return html`<div class="tab_wrapper" data-fx-json="${$dataStr(projection)}">
    <div class="header"></div>
    <div class="body">
      <div class="empty column">
        ${bubbleIcon()}
        <h2>${MESSAGES.PACKAGING.EMPTY}</h2>
      </div>
    </div>
    ${makePackagingTabFooterHtml({
      message: MESSAGES.PACKAGING.BEFORE_PRINT,
      action_button_template: html`<div class="buttons">
        <button class="gray disabled">운송장 발급하기</button>
      </div>`,
    })}
  </div>`;
}

export function makeEmptyInboundOrdersHtml() {
  return html`<div class="tab_wrapper">
    <div class="header"></div>
    <div class="body">
      <div class="empty column">
        ${bubbleIcon()}
        <h2>${MESSAGES.INBOUND.EMPTY_INBOUND_REQUEST}</h2>
      </div>
    </div>
    ${makePackagingTabFooterHtml({
      message: MESSAGES.INBOUND.BEFORE_PACKED,
      action_button_template: html`<div class="buttons">
        <button class="gray disabled">입고 요청하기</button>
      </div>`,
    })}
  </div>`;
}

export function makeEmptyReturnHtml() {
  return html`<div class="tab_wrapper">
    <div class="header"></div>
    <div class="body">
      <div class="empty column">
        ${bubbleIcon()}
        <h2>${MESSAGES.RETURN.EMPTY}</h2>
      </div>
    </div>
    ${makePackagingTabFooterHtml({
      message: MESSAGES.RETURN.INITIAL,
      action_button_template: html``,
    })}
  </div>`;
}

export function makeEmptyOutboundHtml() {
  return html`<div class="tab_wrapper">
    <div class="header"></div>
    <div class="body">
      <div class="empty column">
        ${bubbleIcon()}
        <h2>${MESSAGES.OUTBOUND.EMPTY}</h2>
      </div>
    </div>
    ${makePackagingTabFooterHtml({
      message: MESSAGES.OUTBOUND.INITIAL,
      action_button_template: html``,
    })}
  </div>`;
}

export function makeEmptyTrackingHtml() {
  return html`<div class="tab_wrapper">
    <div class="header"></div>
    <div class="body">
      <div class="empty column">
        ${bubbleIcon()}
        <h2>${MESSAGES.TRACKING.EMPTY}</h2>
      </div>
    </div>
    ${makePackagingTabFooterHtml({
      message: MESSAGES.TRACKING.INITIAL,
      action_button_template: html``,
    })}
  </div>`;
}
