import { DfTaskDetailF } from './module/DfTaskDetailF.js';
import { DfTaskDetailTmplS } from '../../S/Tmpl/module/DfTaskDetailTmplS.js';

export function detail_saving(func) {
  return function (e) {
    $.don_loader_start();
    _p.go(
      e,
      _p.tap(_p.$('#task_save_state'), $.text('(저장중...)')),
      func,
      _p.$('#task_save_state'),
      $.text(''),
      _p.tap(function () {
        $.don_loader_end();
      }),
      _p.catch(function () {
        $.text($1('#task_save_state'), '');
        $.don_loader_end();
      }),
    );
  };
}

export function set_task_box(task_id) {
  return _p.go(
    $1('html.task_set_manage')
      ? $.get(`/@api/modify_task/detail/${task_id}`)
      : $.get(`/@api/task/detail/${task_id}`),
    (task) => box.set('task', task),
  );
}

export function task_detail_render() {
  const don_wrapper = _go(
    $(`.don_frame[frame_tag="${box.sel('task->id')}"]`),
    _p.last,
    $.find1('.don_tab > .don_wrapper'),
  );
  const user = box.sel('user') || box.sel('is_user');
  const sub_tab = _go(don_wrapper, $.closest('.don_tab'), $.attr('detail_tab')) || 'issue_list';
  return _p.go(
    box.sel('task'),
    (task) =>
      box.sel('task').is_original || box.sel('task').task_set_id
        ? DfTaskDetailTmplS.task_set_detail_tmpl(task, user)
        : DfTaskDetailF.main_task_detail_tmpl(
            task,
            user,
            sub_tab,
            box.sel('prj_product_mode'),
            box.sel('is_user->_->policies->mp_worker_policy'),
          ),
    $.html_to(don_wrapper),
    $.find1('.option[is_show]'),
    _p.tap(function (el) {
      $.don_select($1('.press_type_select'));
      const showed_tab = _go(el, $.attr('is_show'));
      if (showed_tab === 'issue_list' || showed_tab === 'scanner_setting') {
        // return G.df.issue.sort_issue(el);
      } else return G.mp.maker.draw_product_face_in_ups(el);
    }),
    $.css({ display: 'block' }),
  );
}

export function set_task_box_task_detail_render(task_id) {
  return _p.go(task_id || box.sel('task->id'), DfTaskDetailF.set_task_box, task_detail_render);
}

export function get_mihaldang() {
  return {
    id: 1084989,
    name: '미할당',
    email: 'mihaldang@marpple.com',
  };
  // if (box.sel('mihaldang')) return box.sel('mihaldang');
  // return _.go($.get('/@api/get_mihaldang2'), function(user) {
  //   box.set('mihaldang', user);
  //   return user;
  // });
}
