import { go, reduce, each, entriesL } from 'fxjs/es';
import { $attr, $find, $findAll, $qs, $removeClass } from 'fxdom/es';
import { updateTexture, updateUniforms, calcTransformFitting } from './module/index.js';

export const isCropChange = (view_gl) => {
  const current_crop_pos = getCurrentCropPosFromGPU(view_gl);
  const crop_width = current_crop_pos.right - current_crop_pos.left;
  const crop_height = current_crop_pos.bottom - current_crop_pos.top;
  const canvas_width = parseInt(view_gl.canvas.style.width);
  const canvas_height = parseInt(view_gl.canvas.style.height);
  return !(Math.abs(canvas_width - crop_width) < 5 && Math.abs(canvas_height - crop_height) < 5);
};

export const getCurrentCropPosFromGPU = (gl, is_origin_lb) => {
  const current_crop_pos = gl.getUniform(gl.programs.view, gl.programs.view.locations.u_crop_pos);
  if (is_origin_lb) {
    const client_h = parseInt(gl.canvas.style.height);
    return {
      left: current_crop_pos[0],
      right: current_crop_pos[1],
      top: client_h - current_crop_pos[2],
      bottom: client_h - current_crop_pos[3],
    };
  } else {
    return {
      left: current_crop_pos[0],
      right: current_crop_pos[1],
      top: current_crop_pos[2],
      bottom: current_crop_pos[3],
    };
  }
};

export const getUniformValue = (gl, program, uniform_name) =>
  gl.getUniform(gl.programs[program], gl.programs[program].locations[uniform_name]);

export const getFitViewport = (gl, current_crop_pos) => {
  const client_w = parseInt(gl.canvas.style.width);
  const client_h = parseInt(gl.canvas.style.height);
  const { left, right, top, bottom } = getCurrentCropPosFromGPU(gl, true);
  const ratio_crop_box = (right - left) / (top - bottom);
  const ratio_canvas = client_w / client_h;
  let gap;
  let margin;
  if (ratio_crop_box > ratio_canvas) {
    const r1 = gl.canvas.width / client_w;
    const r2 = client_w / (right - left);
    gap = (r1 * client_h - r1 * r2 * (top - bottom)) / 2;
    gap = gap < r1 * r2 * bottom ? gap - r1 * r2 * bottom : 0;
    margin = -r1 * client_h * (r2 - 1);
    return {
      vx: Math.round(0 - r1 * r2 * left),
      vy: Math.round(Math.max(gap, margin)),
      vw: Math.round(r1 * r2 * client_w),
      vh: Math.round(r1 * r2 * client_h),
    };
  } else {
    const r1 = gl.canvas.height / client_h;
    const r2 = client_h / (top - bottom);
    gap = (r1 * client_w - r1 * r2 * (right - left)) / 2;
    gap = gap < r1 * r2 * left ? gap - r1 * r2 * left : 0;
    margin = -r1 * client_w * (r2 - 1);
    return {
      vx: Math.round(Math.max(gap, margin)),
      vy: Math.round(0 - r1 * r2 * bottom),
      vw: Math.round(r1 * r2 * client_w),
      vh: Math.round(r1 * r2 * client_h),
    };
  }
};

export const getCurrentViewportFromGPU = (gl) => {
  const [vx, vy, vw, vh] = gl.getParameter(gl.VIEWPORT);
  return {
    vx,
    vy,
    vw,
    vh,
  };
};

const getViewportChangeStatus = (current_vp, target_vp) => {
  const { vx: cvx, vy: cvy, vw: cvw, vh: cvh } = current_vp;
  const { vx: tvx, vy: tvy, vw: tvw, vh: tvh } = target_vp;
  return Math.max(Math.abs(cvx - tvx), Math.abs(cvy - tvy), Math.abs(cvw - tvw), Math.abs(cvh - tvh));
};

export const cropPosStatus = (crop_pos_a, crop_pos_b) => {
  const { left: a_left, right: a_right, top: a_top, bottom: a_bottom } = crop_pos_a;
  const { left: b_left, right: b_right, top: b_top, bottom: b_bottom } = crop_pos_b;
  return Math.max(
    Math.abs(a_left - b_left),
    Math.abs(a_right - b_right),
    Math.abs(a_top - b_top),
    Math.abs(a_bottom - b_bottom),
  );
};

const getCropPosChangeStatus = (current_crop_pos, target_crop_pos) => {
  const { left: c_left, right: c_right, top: c_top, bottom: c_bottom } = current_crop_pos;
  const { left: t_left, right: t_right, top: t_top, bottom: t_bottom } = target_crop_pos;
  return Math.max(
    Math.abs(c_left - t_left),
    Math.abs(c_right - t_right),
    Math.abs(c_top - t_top),
    Math.abs(c_bottom - t_bottom),
  );
};

const getSine = (start, end, progress) => {
  return (end - start) * Math.pow(Math.sin((Math.PI / 2) * progress), 0.6) + start;
};

export const animateCropOut = (gl, timeout, run_speed, target_color, target_alpha, registerCancel) => {
  const start = new Date().getTime();
  const current_alpha = getUniformValue(gl, 'view', 'u_outside_crop_alpha');
  const current_color = getUniformValue(gl, 'view', 'u_outside_crop_color');
  if (Math.abs(current_alpha - target_alpha) < 0.01 && Math.abs(current_color - target_color) < 0.01) {
    return;
  }
  const loop = () => {
    const dt = new Date().getTime() - start;
    const progress = ((dt - timeout) / 1000) * run_speed;
    if (progress < 1) {
      if (dt >= timeout) {
        gl.uniform1f(
          gl.programs.view.locations.u_outside_crop_alpha,
          getSine(current_alpha, target_alpha, progress),
        );
        gl.uniform1f(
          gl.programs.view.locations.u_outside_crop_color,
          getSine(current_color, target_color, progress),
        );
        gl.drawArrays(gl.TRIANGLES, 0, 6);
      }
      const raf = requestAnimationFrame(loop);
      registerCancel(() => cancelAnimationFrame(raf));
    }
  };
  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

export const animateGridLine = (gl, timeout, run_speed, target_alpha, registerCancel) => {
  const start = new Date().getTime();
  const current_alpha = getUniformValue(gl, 'view', 'u_grid_line_alpha');
  if (Math.abs(current_alpha - target_alpha) < 0.01) return;
  const loop = () => {
    const dt = new Date().getTime() - start;
    const progress = ((dt - timeout) / 1000) * run_speed;
    if (progress < 1) {
      if (dt >= timeout) {
        gl.uniform1f(
          gl.programs.view.locations.u_grid_line_alpha,
          getSine(current_alpha, target_alpha, progress),
        );
        gl.drawArrays(gl.TRIANGLES, 0, 6);
      }
      const raf = requestAnimationFrame(loop);
      registerCancel(() => cancelAnimationFrame(raf));
    }
  };
  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

export const animateCropViewportInitialize = (gl, timeout, run_speed, target_crop_pos) => {
  const start = new Date().getTime();
  const current_crop_pos = getCurrentCropPosFromGPU(gl);
  const current_vp = getCurrentViewportFromGPU(gl);
  const target_vp = { vx: 0, vy: 0, vw: gl.canvas.width, vh: gl.canvas.height };
  if (
    getViewportChangeStatus(current_vp, target_vp) < 2 &&
    getCropPosChangeStatus(current_crop_pos, target_crop_pos) < 2
  ) {
    return;
  }
  const loop = () => {
    const dt = new Date().getTime() - start;
    const progress = ((dt - timeout) / 1000) * run_speed;
    if (progress < 1) {
      const new_crop_pos = {
        left: getSine(current_crop_pos.left, target_crop_pos.left, progress),
        right: getSine(current_crop_pos.right, target_crop_pos.right, progress),
        top: getSine(current_crop_pos.top, target_crop_pos.top, progress),
        bottom: getSine(current_crop_pos.bottom, target_crop_pos.bottom, progress),
      };
      updateUniforms(gl, gl.programs.view, {
        u_crop_pos: { type: '4fv', value: [Object.values(new_crop_pos)] },
      });
      gl.viewport(
        getSine(current_vp.vx, target_vp.vx, progress),
        getSine(current_vp.vy, target_vp.vy, progress),
        getSine(current_vp.vw, target_vp.vw, progress),
        getSine(current_vp.vh, target_vp.vh, progress),
      );
      gl.uniform1f(
        gl.programs.view.locations.u_viewport_scale_ratio,
        getSine(current_vp.vw, target_vp.vw, progress) / gl.canvas.width,
      );
      gl.drawArrays(gl.TRIANGLES, 0, 6);
    }
    requestAnimationFrame(loop);
  };
  requestAnimationFrame(loop);
};

export const animateCropChange = (gl, timeout, run_speed, target_crop_pos, registerCancel) => {
  const start = new Date().getTime();
  const current_crop_pos = getCurrentCropPosFromGPU(gl);
  if (getCropPosChangeStatus(current_crop_pos, target_crop_pos) < 2) return;
  const loop = () => {
    const dt = new Date().getTime() - start;
    const progress = ((dt - timeout) / 1000) * run_speed;
    if (progress < 1) {
      const new_crop_pos = {
        left: getSine(current_crop_pos.left, target_crop_pos.left, progress),
        right: getSine(current_crop_pos.right, target_crop_pos.right, progress),
        top: getSine(current_crop_pos.top, target_crop_pos.top, progress),
        bottom: getSine(current_crop_pos.bottom, target_crop_pos.bottom, progress),
      };
      updateUniforms(gl, gl.programs.view, {
        u_crop_pos: { type: '4fv', value: [Object.values(new_crop_pos)] },
      });
      gl.drawArrays(gl.TRIANGLES, 0, 6);
    }
    const raf = requestAnimationFrame(loop);
    registerCancel(() => cancelAnimationFrame(raf));
  };
  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

export const setTransformDataToSlider = (el$, set_value) => {
  el$.value = Number(set_value);
  jQuery(el$).rangeslider('update', true);
};

export const animateTransformInit = (gl, view_gl, timeout, run_speed, last_transform_data) => {
  const start = new Date().getTime();
  const crop_sliders$ = $qs('.crop_item_sliders');
  const selected_crop_slider$ = $find('.selected input', crop_sliders$);
  const selected_item = $attr('item', selected_crop_slider$);
  const loop = () => {
    const dt = new Date().getTime() - start;
    const progress = ((dt - timeout) / 1000) * run_speed;
    if (progress < 1) {
      if (dt > timeout) {
        let transform_data = go(entriesL(last_transform_data), (arr) =>
          reduce(
            (obj, [item, val]) => {
              const target_val = getSine(val, 0, progress);
              if (selected_item === item) {
                selected_crop_slider$.value = Number(target_val);
                jQuery(selected_crop_slider$).rangeslider('update', true);
              }
              obj[item] = target_val;
              return obj;
            },
            {},
            arr,
          ),
        );
        transform_data = calcTransformFitting(gl, transform_data);
        updateUniforms(gl, gl.programs.image_editor, {
          u_rotation: { type: 'Matrix2fv', value: [transform_data.rot_matrix] },
          u_shift: { type: '2f', value: [transform_data.translate_x, transform_data.translate_y] },
        });
        gl.drawArrays(gl.TRIANGLES, 0, 6);
        updateTexture(view_gl, view_gl.texture, gl.canvas);
        view_gl.drawArrays(view_gl.TRIANGLES, 0, 6);
      }
      requestAnimationFrame(loop);
    } else {
      go(
        $findAll(`input`, crop_sliders$),
        each((input$) => {
          const input$_jquery = jQuery(input$);
          input$.value = 0;
          $find('span', input$.parentNode).textContent = 0;
          input$_jquery.rangeslider('update', true);
          $removeClass('down', input$);
        }),
      );
    }
  };
  requestAnimationFrame(loop);
};

export const switchingViewportFitting = (gl, timeout, run_speed) => {
  const start = new Date().getTime();
  const current_vp = getCurrentViewportFromGPU(gl);
  const target_vp = getFitViewport(gl);
  const fit_status = getViewportChangeStatus(current_vp, target_vp);
  let loop = () => {};
  if (fit_status > 5) {
    loop = () => {
      const dt = new Date().getTime() - start;
      const progress = ((dt - timeout) / 1000) * run_speed;
      if (progress < 1) {
        if (dt >= timeout) {
          gl.viewport(
            getSine(current_vp.vx, target_vp.vx, progress),
            getSine(current_vp.vy, target_vp.vy, progress),
            getSine(current_vp.vw, target_vp.vw, progress),
            getSine(current_vp.vh, target_vp.vh, progress),
          );
          gl.uniform1f(
            gl.programs.view.locations.u_viewport_scale_ratio,
            getSine(current_vp.vw, target_vp.vw, progress) / gl.canvas.width,
          );
          gl.drawArrays(gl.TRIANGLES, 0, 6);
        }
        requestAnimationFrame(loop);
      }
    };
  } else {
    loop = () => {
      const dt = new Date().getTime() - start;
      const progress = ((dt - timeout) / 1000) * run_speed;
      if (progress < 1) {
        if (dt >= timeout) {
          gl.viewport(
            getSine(current_vp.vx, 0, progress),
            getSine(current_vp.vy, 0, progress),
            getSine(current_vp.vw, gl.canvas.width, progress),
            getSine(current_vp.vh, gl.canvas.height, progress),
          );
          gl.uniform1f(
            gl.programs.view.locations.u_viewport_scale_ratio,
            getSine(current_vp.vw, target_vp.vw, progress) / gl.canvas.width,
          );
          gl.drawArrays(gl.TRIANGLES, 0, 6);
        }
        requestAnimationFrame(loop);
      }
    };
  }
  requestAnimationFrame(loop);
};

export const animateMinorGridLine = (gl, timeout, run_speed, registerCancel, target_alpha) => {
  const start = new Date().getTime();
  const current_alpha = getUniformValue(gl, 'view', 'u_minor_grid_line_alpha');
  const loop = () => {
    const dt = new Date().getTime() - start;
    const progress = ((dt - timeout) / 1000) * run_speed;
    if (progress < 1) {
      if (dt >= timeout) {
        gl.uniform1f(
          gl.programs.view.locations.u_minor_grid_line_alpha,
          getSine(current_alpha, target_alpha, progress),
        );
        gl.drawArrays(gl.TRIANGLES, 0, 6);
      }
      const raf = requestAnimationFrame(loop);
      registerCancel(() => cancelAnimationFrame(raf));
    }
  };
  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

export const animateViewportFitting = (gl, timeout, run_speed, registerCancel, initialize) => {
  const start = new Date().getTime();
  const current_vp = getCurrentViewportFromGPU(gl);
  const target_vp = initialize
    ? { vx: 0, vy: 0, vw: gl.canvas.width, vh: gl.canvas.height }
    : getFitViewport(gl);
  if (getViewportChangeStatus(current_vp, target_vp) < 2) return;
  const loop = () => {
    const dt = new Date().getTime() - start;
    const progress = ((dt - timeout) / 1000) * run_speed;
    if (progress < 1) {
      if (dt >= timeout) {
        gl.viewport(
          Math.round(getSine(current_vp.vx, target_vp.vx, progress)),
          Math.round(getSine(current_vp.vy, target_vp.vy, progress)),
          Math.round(getSine(current_vp.vw, target_vp.vw, progress)),
          Math.round(getSine(current_vp.vh, target_vp.vh, progress)),
        );
        gl.uniform1f(
          gl.programs.view.locations.u_viewport_scale_ratio,
          Math.round(getSine(current_vp.vw, target_vp.vw, progress)) / gl.canvas.width,
        );
        gl.drawArrays(gl.TRIANGLES, 0, 6);
      }
      const raf = requestAnimationFrame(loop);
      registerCancel(() => cancelAnimationFrame(raf));
    }
  };
  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

export const getCursorPosLimitedInBoxRange = (cursor_pos, box_w, box_h) => {
  const { x: cur_x, y: cur_y } = cursor_pos;
  const x = cur_x < 0 ? 0 : cur_x > box_w ? box_w : cur_x;
  const y = cur_y < 0 ? 0 : cur_y > box_h ? box_h : cur_y;
  return { x, y };
};

export const isCursorInsideCanvas = (cursor_pos, box_w, box_h) => {
  const { x, y } = cursor_pos;
  return x >= 0 && x <= box_w && y >= 0 && y <= box_h;
};

export const getCropToViewCropPos = (gl, crop_pos, latest_viewport) => {
  const { vx, vy, vw } = latest_viewport || getCurrentViewportFromGPU(gl);
  const client_w = parseInt(gl.canvas.style.width);
  const client_h = parseInt(gl.canvas.style.height);
  let { left, right, top, bottom } = crop_pos;
  const r1 = gl.canvas.width / client_w;
  const r2 = vw / gl.canvas.width;

  const top_from_viewport = client_h - top;
  const bottom_from_viewport = client_h - bottom;

  left = Math.round((left + vx / r1 / r2) * r2);
  right = Math.round((right + vx / r1 / r2) * r2);
  top = Math.round(client_h - (top_from_viewport + vy / r1 / r2) * r2);
  bottom = Math.round(client_h - (bottom_from_viewport + vy / r1 / r2) * r2);
  return { left, right, top, bottom };
};

export const getViewCropToCropPos = (gl, view_crop_pos, latest_viewport) => {
  const { vx, vy, vw } = latest_viewport || getCurrentViewportFromGPU(gl);
  let { left, right, top, bottom } = view_crop_pos;
  const client_w = parseInt(gl.canvas.style.width);
  const client_h = parseInt(gl.canvas.style.height);
  const r1 = gl.canvas.width / client_w;
  const r2 = vw / gl.canvas.width;

  left = Math.round((left - vx / r1) / r2);
  right = Math.round((right - vx / r1) / r2);
  top = Math.round((top - client_h) / r2 + client_h + vy / r1 / r2);
  bottom = Math.round((bottom - client_h) / r2 + client_h + vy / r1 / r2);

  return { left, right, top, bottom };
};

export const changeCrop = (gl, crop_pos, cursor_status, cursor_pos, down_coord, min_crop_px) => {
  const viewport = getCurrentViewportFromGPU(gl);
  const client_w = parseInt(gl.canvas.style.width);
  const client_h = parseInt(gl.canvas.style.height);
  let { left, right, top, bottom } = getCropToViewCropPos(gl, crop_pos, viewport);
  const vp_to_canvas_ratio = viewport.vw / gl.canvas.width;
  const { down_x, down_y } = down_coord;
  const { x, y } = getCursorPosLimitedInBoxRange(cursor_pos, client_w, client_h);
  if (cursor_status === 'out') {
    const new_view_crop_pos = {
      left: Math.min(down_x, x) - (min_crop_px * vp_to_canvas_ratio) / 2,
      right: Math.max(down_x, x) + (min_crop_px * vp_to_canvas_ratio) / 2,
      top: Math.min(down_y, y) - (min_crop_px * vp_to_canvas_ratio) / 2,
      bottom: Math.max(down_y, y) + (min_crop_px * vp_to_canvas_ratio) / 2,
    };
    if (new_view_crop_pos.left < 0) {
      new_view_crop_pos.left = 0;
      new_view_crop_pos.right = Math.max(min_crop_px * vp_to_canvas_ratio, new_view_crop_pos.right);
    }
    if (new_view_crop_pos.right > client_w) {
      new_view_crop_pos.right = client_w;
      new_view_crop_pos.left = Math.min(client_w - min_crop_px * vp_to_canvas_ratio, new_view_crop_pos.left);
    }
    if (new_view_crop_pos.top < 0) {
      new_view_crop_pos.top = 0;
      new_view_crop_pos.bottom = Math.max(new_view_crop_pos.bottom, min_crop_px * vp_to_canvas_ratio);
    }
    if (new_view_crop_pos.bottom > client_h) {
      new_view_crop_pos.bottom = client_h;
      new_view_crop_pos.top = Math.min(client_h - min_crop_px * vp_to_canvas_ratio, new_view_crop_pos.top);
    }

    updateUniforms(gl, gl.programs.view, {
      u_crop_pos: {
        type: '4fv',
        value: [Object.values(getViewCropToCropPos(gl, new_view_crop_pos, viewport))],
      },
    });
    gl.drawArrays(gl.TRIANGLES, 0, 6);
  }
  // crop 영역 안: box 전체 shift 하기
  else if (cursor_status === 'in') {
    const shift_x = cutoff(-left, client_w - right, x - down_x);
    const shift_y = cutoff(-top, client_h - bottom, y - down_y);

    left += shift_x;
    right += shift_x;
    top += shift_y;
    bottom += shift_y;

    const shifted_crop_pos = getViewCropToCropPos(
      gl,
      {
        left,
        right,
        top,
        bottom,
      },
      viewport,
    );
    updateUniforms(gl, gl.programs.view, {
      u_crop_pos: { type: '4fv', value: [Object.values(shifted_crop_pos)] },
    });
    gl.drawArrays(gl.TRIANGLES, 0, 6);
  }

  // crop 영역 경계인 경우 (left, right, top, bottom, lt, lb, rt, rb) : 경계 부분만 shift 하기
  else {
    const shifted_crop_pos = shiftCropPos(
      gl,
      cursor_status,
      crop_pos,
      {
        x,
        y,
      },
      min_crop_px,
    );
    updateUniforms(gl, gl.programs.view, {
      u_crop_pos: { type: '4fv', value: [Object.values(shifted_crop_pos)] },
    });
    gl.drawArrays(gl.TRIANGLES, 0, 6);
  }
};

export const cutoff = (ll, hl, val) => (val < ll ? ll : val > hl ? hl : val);

export const shiftCropPos = (gl, cursor_status, crop_pos, cursor_position, min_crop_length) => {
  const { x, y } = cursor_position;
  const viewport = getCurrentViewportFromGPU(gl);
  crop_pos = getCropToViewCropPos(gl, crop_pos, viewport);
  const view_to_canvas_ratio = viewport.vw / gl.canvas.width;
  if (cursor_status.includes('left')) {
    const limit = Math.round(crop_pos.right - min_crop_length * view_to_canvas_ratio);
    crop_pos.left = Math.min(x, limit);
  }
  if (cursor_status.includes('right')) {
    const limit = Math.round(crop_pos.left + min_crop_length * view_to_canvas_ratio);
    crop_pos.right = Math.max(x, limit);
  }
  if (cursor_status.includes('top')) {
    const limit = Math.round(crop_pos.bottom - min_crop_length * view_to_canvas_ratio);
    crop_pos.top = Math.min(y, limit);
  }
  if (cursor_status.includes('bottom')) {
    const limit = Math.round(crop_pos.top + min_crop_length * view_to_canvas_ratio);
    crop_pos.bottom = Math.max(y, limit);
  }
  return getViewCropToCropPos(gl, crop_pos, viewport);
};

export const shiftViewport = (gl, shift, current_vp, current_crop_pos) => {
  const { vx: cvx, vy: cvy, vw: cvw, vh: cvh } = current_vp || getCurrentViewportFromGPU(gl);
  const { vx: tvx, vy: tvy, vw: tvw, vh: tvh } = getFitViewport(gl, current_crop_pos);
  let nvx, nvy, nvw, nvh;
  if (shift > 0) {
    nvx = tvx > cvx ? Math.min(tvx, (tvx - cvx) * shift + cvx) : Math.max(tvx, (tvx - cvx) * shift + cvx);
    nvy = tvy > cvy ? Math.min(tvy, (tvy - cvy) * shift + cvy) : Math.max(tvy, (tvy - cvy) * shift + cvy);
    nvw = Math.min(tvw, (tvw - cvw) * shift + cvw);
    nvh = Math.min(tvh, (tvh - cvh) * shift + cvh);
  } else {
    nvx = Math.min(0, -(0 - cvx) * shift + cvx);
    nvy = Math.min(0, -(0 - cvy) * shift + cvy);
    nvw = Math.max(gl.canvas.width, -(gl.canvas.width - cvw) * shift + cvw);
    nvh = Math.max(gl.canvas.height, -(gl.canvas.height - cvh) * shift + cvh);
  }
  return {
    vx: nvx,
    vy: nvy,
    vw: nvw,
    vh: nvh,
  };
};
