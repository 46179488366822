import { filter, flatten, go, map, some, tap } from 'fxjs/es';
import { getRealFcanvass } from '../../../../../Maker/F/getSth.js';
import { getCvDesigns } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { shinhanPfCondition } from '../../../../../NewMaker/Checker/F/Function/isImageQualityPass.js';
import { OMPMakerEventS } from '../../../S/Function/module/OMPMakerEventS.js';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';

export const shinhanPriceMaker = (event, { fcanvass, base_product_id }) => {
  if (!event) return;
  const designs = go(
    fcanvass,
    map((fc) => {
      return G.mp.maker.designs(fc);
    }),
    filter((cv_objs) => cv_objs.length),
  );
  const event_bp = OMPMakerEventS.getBp(event, base_product_id);
  const { price } = event_bp.price_info.find(({ face_length }) => face_length === designs.length) || {
    price: 0,
  };
  let i = 0;
  go(
    designs,
    flatten,
    tap((designs) => {
      G.mp.maker.cv_objects_deep_each(designs, (cv_obj) => {
        if (i++ === 0) {
          cv_obj._data.price = price;
        } else {
          cv_obj._data.price = 0;
        }
      });
    }),
  );
};
export const shinhanIsGoodCondition = async (event, product_color) => {
  if (!event) return;
  product_color.product_faces2.ev = { ...event };
  if (product_color.product_faces2.ev.don_frame_key === event.don_frame_key) {
    const has_shinhan_required_image = go(
      getRealFcanvass(),
      some((fcanvas) => {
        return G.mp.maker.cv_objects_deep_find(getCvDesigns(fcanvas._objects), (cv_obj) => {
          if (cv_obj._data?.cv_image_attrs?._data) {
            return cv_obj._data?.cv_image_attrs?._data?.name?.includes('sh_x');
          }
          return cv_obj._data?.name?.includes('sh_x');
        });
      }),
    );
    if (!has_shinhan_required_image) {
      $.don_loader_end();
      await OMPCoreUtilF.alert({
        content: `신한프렌즈 캐릭터 이미지(필수 에셋)와<br>함께 디자인 해주세요.`,
        msg_width: 324,
      });
      return;
    }
    const result = await shinhanPfCondition({
      product_faces2: box().maker.product_color.product_faces2,
      base_product_size_id: box().maker.product_color.base_product_size_id,
    });
    if (result?.message) {
      $.don_loader_end();
      await OMPCoreUtilF.alert({
        content: result?.message,
        msg_width: 345,
      });
      return;
    }
  }
  return true;
};
