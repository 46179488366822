import { defaults, each, go, mapObject } from 'fxjs/es';
import { legacyHtml } from '../../Util/S/Function/util.js';
import { getImageSizeInFabricCanvas, isDreamFactory } from './util.js';
import { getCurrentBpcfsInMaker } from './getSth.js';
import { isCateItemPhoneCase } from './categorize.js';

function cvBlueLine(canvas) {
  return _filter(G.mp.maker.not_designs(canvas), function (obj) {
    return obj._data.cv_type === 'cv_blue_line';
  });
}

export const removeCvBlueLineInMaker = function () {
  $.remove_class($1('#maker_frame'), 'cv_blue_line');
  go(
    cvBlueLine(),
    each((cv_blue_line) => cv_blue_line.canvas.remove(cv_blue_line)),
  );
  G.mp.maker.editing_canvas().renderAll();
};

function isPossibleBlueLine() {
  return getCurrentBpcfsInMaker()[0]._.base_product_face.blue_line_real_width_mm;
}

function is_like_jelly(bp) {
  return (
    bp.name.indexOf('젤리') != -1 ||
    bp.name.indexOf('투명') != -1 ||
    bp.name.indexOf('글리터') != -1 ||
    bp.name.indexOf('네온') != -1 ||
    bp.name.indexOf('스트랩 폰케이스') != -1
  );
}

const makeCvBlueLineInMaker = function () {
  const canvas = G.mp.maker.editing_canvas();
  const last_cv_blue_lines = cvBlueLine(canvas);
  if (last_cv_blue_lines.length > 0)
    each((last_cv_blue_line) => canvas.remove(last_cv_blue_line), last_cv_blue_lines);
  if (!isCateItemPhoneCase()) return;
  if (!isPossibleBlueLine()) return;
  $.add_class($1('#maker_frame'), 'cv_blue_line');
  //80.601 mm	159.283 mm	68.548 mm	148.657 mm	5.312 mm	4.96 mm
  const cv_mask1_size = getImageSizeInFabricCanvas(canvas, G.mp.maker.cv_mask1(canvas));
  const bpf = box.sel('maker->product_color->_->base_product_color')._.base_product_color_faces[0]._
    .base_product_face;
  const _is_like_jelly = is_like_jelly(box.sel('maker->product_color->_->base_product'));
  const width_line = _is_like_jelly ? bpf.blue_line_real_width_mm : bpf.outline_real_width_mm;
  const height_line = _is_like_jelly ? bpf.blue_line_real_height_mm : bpf.outline_real_height_mm;

  const ratio1 = cv_mask1_size.width / cv_mask1_size.height;
  const ratio2 = width_line / height_line;
  const sub_ratio = Math.abs(ratio2 - ratio1);

  const is_big_difference = sub_ratio > 0.0025;
  const px_per_1cm = (cv_mask1_size.height / height_line) * 10;
  const pixel_per_1mm = px_per_1cm / 10;

  const color_code = oppisiteColor(box().maker.product_color._.base_product_color.color_code);

  /*outline 나옴 사진과 데이터의 차이가 클때만 나옴.*/
  if (is_big_difference && !$1('html#dream_factory'))
    addCvBlueLine({
      top: 0,
      left: 0,
      width: width_line,
      height: height_line,
    });

  /*하드케이스 일때만, 블루라인 나옴*/
  if (!_is_like_jelly)
    addCvBlueLine({
      top: bpf.blue_line_real_top_mm,
      left: bpf.blue_line_real_left_mm,
      width: bpf.blue_line_real_width_mm,
      height: bpf.blue_line_real_height_mm,
    });

  function addCvBlueLine(obj) {
    const cv_blue_line_attr = go(
      obj,
      mapObject((v) => v * pixel_per_1mm),
      (obj) => {
        obj.top += cv_mask1_size.top - 1;
        // obj.height -= 2;
        obj.width += 1;
        obj.height += 1;
        obj.left += cv_mask1_size.left - 1;
        return obj;
      },
      (obj) =>
        defaults(obj, {
          selectable: false,
          evented: false,
          visible: true,
          is_cv_print_area: true,
          strokeWidth: 2,
          stroke: color_code,
          fill: 'rgba(0,0,0,0)',
          _data: {
            cv_type: 'cv_blue_line',
            is_not_design: true,
            nscreened: true,
          },
        }),
    );
    canvas.add(new fabric.Rect(cv_blue_line_attr));
  }
};

export function canGoBlueLIne() {
  return isDreamFactory();
}

function oppisiteColor(color_code) {
  const will_go_red = [
    '#ffffff',
    '#bde4c3',
    '#c3bfbe',
    '#e7e3b0',
    '#b5dff7',
    '#efba90',
    '#0e3a95',
    '#4f96b8',
    '#edbf04',
    '#14291c',
    '#000000',
    '#229844',
    '#022954',
    '#eda9a7',
    '#f0b5d1',
    '#304dc2',
  ];
  const will_go_blue = ['#04c0ff', '#fa733c', '#d02933', '#e3905d', '#da2028', '#cc722c', '#f47920'];
  if (will_go_red.includes(color_code)) {
    return '#ff0000';
  } else if (will_go_blue.includes(color_code)) {
    return '#1cff7b';
  } else {
    return '#ff0000';
  }
}

export const initBlueLine = function () {
  go(
    legacyHtml`
      <li class="open_blue_line circle_icon">
        <div class="icon"></div>
        <div class="text">폰케이스 라인</div>
      </li>
    `,
    $.el,
    $.append_to($1('.decoration_menu_for_pc')),
    $.on('click', function () {
      if ($.has_class($1('#maker_frame'), 'cv_blue_line')) {
        removeCvBlueLineInMaker();
      } else {
        makeCvBlueLineInMaker();
      }
    }),
  );
};
