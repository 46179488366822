import axios from 'axios';
import { $delegate, $find, $val } from 'fxdom/es';
import { go } from 'fxjs/es';
import { AlertTmplS } from '../../../../../Alert/S/Tmpl/module/AlertTmplS.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';

export const events = (tab_el) => {
  go(
    tab_el,
    $delegate('click', '.find-id-result__button.reset_pw', async (e) => {
      const email = go(e.delegateTarget, $find('input[name="user"]:checked'), $val);
      try {
        $.don_loader_start();
        const res = await axios.post('/@api/reset_password/request_cert', { email });
        $.alert(AlertTmplS.alertTmpl(res.data));
      } catch (e) {
        $.alert(AlertTmplS.alertTmpl(e?.response?.data || e?.message));
      } finally {
        $.don_loader_end();
      }
    }),
    $delegate('click', '.find-id-result__button.login', (e) => {
      const email = go(e.delegateTarget, $find('input[name="user"]:checked'), $val);
      MuiF.closeFrame({ email });
    }),
  );
};
