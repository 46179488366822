import { defaults } from 'fxjs/es';
import { cv_type } from '../../S/Constant/cvbackground.js';

export const create = function (obj) {
  const cv_obj = new window.fabric.Rect(
    defaults(
      {
        selectable: false,
        evented: false,
        visible: true,
        _data: {
          cv_type,
          is_not_design: true,
        },
      },
      obj,
    ),
  );
  defaults(cv_obj._data, obj._data);
  return cv_obj;
};
