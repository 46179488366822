const pxToCm = (px, dpi) => {
  return (px / dpi) * 2.54;
};
function makeWidth(size, { from, to }) {
  const nomalized = size / 800;
  return (to - from) * nomalized + from;
}
export const getPxByDpiAndPxPer1cm = (cv_obj, fcanvas) => {
  const { image_width, image_height } = cv_obj._data;
  const { _px_per_1cm, _real_dpi } = fcanvas;
  if (image_width > image_height) {
    const cm_width = pxToCm(image_width, _real_dpi);
    const width = Math.min(Math.floor(_px_per_1cm * cm_width), 800);
    return {
      width: makeWidth(width, { from: 200, to: 750 }),
    };
  } else {
    const cm_height = pxToCm(image_height, _real_dpi);
    const height = makeWidth(Math.min(Math.floor(_px_per_1cm * cm_height), 800), { from: 200, to: 750 });
    const width = Math.floor(image_width * (height / image_height));
    return {
      width,
    };
  }
};
