import { go, html, mapL, reduce } from 'fxjs/es';
import { VectorEditorSettingBackgroundPCTmplS } from '../../../../../SettingBackground/PC/S/Tmpl/module/VectorEditorSettingBackgroundPCTmplS.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';
import { VectorEditorTopMenuPCTmplS } from '../../../../../TopMenu/PC/S/Tmpl/module/VectorEditorTopMenuPCTmplS.js';

/** @todo i18n */
export const makeTabHtml = () => html`
  <div class="left_container">
    <div class="editor_container"></div>
    <div class="top_menu_container">
      ${VectorEditorTopMenuPCTmplS.makeTopMenusHtml({
        disabled_ids: go(
          Object.values(VectorEditorTopMenuPCConstantS.TOP_MENU),
          mapL(({ ID }) => ID),
          (ids) =>
            reduce(
              (set, id) => {
                set.add(id);
                return set;
              },
              new Set(),
              ids,
            ),
        ),
      })}
    </div>
  </div>
  <div class="right_container">
    <div class="right_wrapper">
      <div class="panel_container">
        <div class="panel_wrapper" data-panel="home" data-is_show="true">
          <div class="inner_wrapper">
            ${VectorEditorSettingBackgroundPCTmplS.makeRightPanelHtml({
              class_list: ['right_panel', 'right_panel_home'],
            })}
          </div>
          <div class="button_container">
            <button type="button" class="cancel">취소</button>
            <button type="button" class="done">완료</button>
          </div>
        </div>
        <div class="panel_wrapper" data-panel="select" data-is_show="false">
          <div class="right_panel right_panel_select">
            <div class="header">
              <button type="button" class="back"></button>
              <span class="title">포토카드</span>
            </div>
            <div class="wrapper">
              <div class="description">
                <svg
                  class="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g stroke="#000">
                      <g>
                        <g>
                          <path
                            d="M5.92 9.021l12.33.301c.407.01.773.181 1.037.452.264.27.427.64.427 1.048h0v2.362c0 .415-.168.79-.44 1.061-.27.27-.643.438-1.055.44h0l-12.289-.3-2.333-2.333 2.323-3.03z"
                            transform="translate(-1572 -507) translate(1460 71) translate(112 436) rotate(-45 11.375 11.875)"
                          />
                          <path
                            stroke-linecap="square"
                            d="M14.5 6.5L17.5 9.5M9 14L14 9"
                            transform="translate(-1572 -507) translate(1460 71) translate(112 436)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <p>만든 포토카드 수정이 필요하신가요?</p>
              </div>
              <button type="button" class="edit">포토카드 편집하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`;
