import { add, differenceInMilliseconds } from 'date-fns';
import { $addClass, $removeClass } from 'fxdom/es';

export function $ComTimer(dom, timerDoneCallback, timerStartCallback) {
  this.dom = dom;
  this.timerDoneCallback = timerDoneCallback || function () {};
  this.timerStartCallback = timerStartCallback || function () {};
}

$ComTimer.prototype = {
  time: null,
  timer: null,
  dom: null,
  timerDoneCallback: function () {},
  timerStartCallback: function () {},
  timerFn: function () {
    const seconds = Math.round(differenceInMilliseconds(this.time, new Date()) / 1000);
    if (seconds <= 0) {
      this.dom.innerText = '0:00';
      $addClass('red', this.dom);
      clearInterval(this.timer); // 타이머 해제
      this.timer = null;
      this.timerDoneCallback(this.dom);
    } else {
      this.dom.innerText =
        Math.floor(seconds / 60) + ':' + (seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60);
    }
  },
  timerReStart: function (time = add(new Date(), { minutes: 5 })) {
    this.timerStartCallback(this.dom);
    $removeClass('red', this.dom);
    this.time = time;
    this.timerFn();
    this.timer = setInterval(() => {
      this.timerFn();
    }, 1000);
  },
  timerStart: function (time = add(new Date(), { minutes: 5 })) {
    this.timerStartCallback(this.dom);
    $removeClass('red', this.dom);
    this.time = time;
    this.timerFn();
    this.timer = setInterval(() => {
      this.timerFn();
    }, 1000);
  },
  timerStop: function () {
    $addClass('red', this.dom);
    clearInterval(this.timer);
    this.timer = null;
  },
};
