async function connectToLabelIndexedDB(databaseName, storeName) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(databaseName, 1);

    request.onerror = (event) => {
      reject(new Error(`[DB OPEN ERROR] 품표 인쇄를 위한 프린터 설치 및 시리얼 번호 등록을 진행해 주세요.`));
    };

    request.onupgradeneeded = (e) => {
      const db = e.target.result;
      db.createObjectStore(storeName, { keyPath: 'label_type' });
      reject(new Error(`[DB NOT EXIST] 품표 인쇄를 위한 프린터 설치 및 시리얼 번호 등록을 진행해 주세요.`));
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      resolve(db);
    };
  });
}

export async function getValueFromLabelIndexedDB(id) {
  const databaseName = 'df_label_printers';
  const storeName = 'label_types_printer_models';
  const db = await connectToLabelIndexedDB(databaseName, storeName);

  return new Promise((resolve, reject) => {
    let transaction;
    try {
      transaction = db.transaction(storeName, 'readonly');
    } catch (e) {
      console.error(e);
      reject(
        new Error('[STORE OPEN ERROR] 품표 인쇄를 위한 프린터 설치 및 시리얼 번호 등록을 진행해 주세요.'),
      );
    }
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.get(id);

    request.onerror = (event) => {
      reject(
        new Error('[STORE READ ERROR] 품표 인쇄를 위한 프린터 설치 및 시리얼 번호 등록을 진행해 주세요.'),
      );
    };

    request.onsuccess = (event) => {
      const value = event.target.result;
      resolve(value);
    };
  });
}
