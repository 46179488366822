import { $qs } from 'fxdom/es';

import { NewMakerProductStyleS } from '../../../../ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { NewMakerBaseProductsTattooConstantS } from '../../../../BaseProducts/Tattoo/S/Constant/module/NewMakerBaseProductsTattooConstantS.js';
import axios from 'axios';
import { NewMakerGuideConfirmF } from '../../../../Guide/Confirm/F/Function/module/NewMakerGuideConfirmF.js';
import { NewMakerCanvasEditorF } from '../../../../CanvasEditor/F/Function/module/NewMakerCanvasEditorF.js';
import { NewMakerCallConditionPopupF } from '../../../../CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';

function isSupportWebgl() {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl');
  if (gl == null) {
    axios.post(`/${T.lang}/@api/prerequisite_maker/error_logs`, {
      name: 'webgl support',
    });
  }
  return gl != null;
}
const memory = new Set();
export const openConfirmModal = (base_product) => {
  if ($qs('#dream_factory')) return;
  const canvas_editor = NewMakerCanvasEditorF.service.get();

  if (memory.has(canvas_editor.id)) return;
  memory.add(canvas_editor.id);

  if (NewMakerCallConditionPopupF.is_mapping_confirm_popup()) return;

  const confirm_popup = NewMakerGuideConfirmF.makeConfirmPopupPrams(base_product);
  if (confirm_popup) return NewMakerGuideConfirmF.open(confirm_popup);

  if (base_product.is_need_full_cover)
    return $.alert(
      T(`maker::Message::전면 인쇄를 원하시는 경우 반드시 이미지를 점선 끝까지 채워주시기 바랍니다.`),
    );
  if (base_product.id === 5650) return $.alert(T(`maker::Message::로고 주변은 피해서 디자인 해주세요.`));

  if (
    G.collabo_type !== 'creator' &&
    NewMakerProductStyleS.SHOW_NOT_POSSIBLE_EMBRO_MESSAGE_BP_IDS.includes(base_product.id)
  ) {
    return $.alert(T(`maker::Message::해당 제품의 경우 자수 제작은 불가합니다.`));
  }
  if (NewMakerBaseProductsTattooConstantS.ids.includes(base_product.id) && !isSupportWebgl()) {
    return $.alert(
      '현재 고객님의 기기는 타투 제품을 생성하는데 적합하지 않는것으로 보입니다.<br>다른 기기에서 생성 부탁 드립니다.',
    );
  }
};
