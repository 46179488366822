import { applyCuttingLine, setCuttingLine } from './cutting_line.js';
import { go, join, mapL, noop } from 'fxjs/es';
import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorKeyringHookMobileF } from '../../../../../Hook/Mobile/F/Function/module/VectorEditorKeyringHookMobileF.js';
import { CommonNS } from '@marpple/sticker-editor';
import { VectorEditorKeyringFreeMobileCuttingLineManualF } from '../../../CuttingLineManual/F/Function/module/VectorEditorKeyringFreeMobileCuttingLineManualF.js';

export const handleControlAutoMinusButtonClick =
  ({ tab_el }) =>
  async () => {
    try {
      await setCuttingLine({ tab_el, index: tab_el.__mp_cur_cutting_line_index - 1 });
    } catch (error) {
      console.error(error);
      const message =
        error?.__mp_alert_message ??
        T(
          'modules::VectorEditor::Keyring::Free::Mobile::CuttingLineAuto::error_message::min_cutting_line_offset_error',
        );
      await $.alert(message).catch(noop);
    }
  };

export const handleControlAutoPlusButtonClick =
  ({ tab_el }) =>
  async () => {
    try {
      await setCuttingLine({ tab_el, index: tab_el.__mp_cur_cutting_line_index + 1 });
    } catch (error) {
      console.error(error);
      const message =
        error?.__mp_alert_message ??
        T(
          'modules::VectorEditor::Keyring::Free::Mobile::CuttingLineAuto::error_message::max_cutting_line_offset_error',
        );
      await $.alert(message).catch(noop);
    }
  };

export const handleControlManualButtonClick =
  ({ tab_el }) =>
  async () => {
    try {
      const makeTargetImageEl = tab_el.__mp_makeTargetImageEl;
      const postProcess = tab_el.__mp_postProcess;
      const cutting_line = tab_el.__mp_cutting_lines[tab_el.__mp_cur_cutting_line_index];
      const { outer_cutting_line_path_data, inner_cutting_line_path_data } = cutting_line;

      await VectorEditorKeyringFreeMobileCuttingLineManualF.makeCuttingLineManual({
        art_board: { width: tab_el.__mp_art_board_width, height: tab_el.__mp_art_board_height },
        cutting_line: { inner: inner_cutting_line_path_data, outer: outer_cutting_line_path_data },
        makeTargetImageEl,
        postProcess,
      });
    } catch (error) {
      console.error(error);
      const message =
        error?.__mp_alert_message ??
        T(
          'modules::VectorEditor::Keyring::Free::Mobile::CuttingLineAuto::error_message::manual_cutting_line_error',
        );
      await $.alert(message).catch(noop);
    }
  };

export const handleHeaderPrevButtonClick = () => () => MuiF.popPage().catch(noop);

export const handleHeaderNextButtonClick =
  ({ tab_el }) =>
  async () => {
    try {
      const makeTargetImageEl = tab_el.__mp_makeTargetImageEl;
      const postProcess = tab_el.__mp_postProcess;
      const cutting_line = tab_el.__mp_cutting_lines[tab_el.__mp_cur_cutting_line_index];
      const { outer_cutting_line_path_data, inner_cutting_line_path_data } = cutting_line;

      await VectorEditorKeyringHookMobileF.makeHook({
        art_board: {
          width: tab_el.__mp_art_board_width,
          height: tab_el.__mp_art_board_height,
          shape_path_data: outer_cutting_line_path_data,
        },
        makeTargetImageEl: async () => {
          const target_image_el = await makeTargetImageEl({ factor: 1 });
          applyCuttingLine({ target_image_el, path_data: inner_cutting_line_path_data });
          return target_image_el;
        },
        postProcess: async ({
          cutting_line_path_data,
          hook_position: { x: hook_position_x, y: hook_position_y },
        }) => {
          const cutting_line_result = (() => {
            try {
              const cutting_line_svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
              cutting_line_svg_el.setAttributeNS(
                'http://www.w3.org/2000/xmlns/',
                'xmlns',
                'http://www.w3.org/2000/svg',
              );
              cutting_line_svg_el.setAttributeNS(null, 'viewBox', '0 0 0 0');

              const cutting_line_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
              cutting_line_path_el.setAttributeNS(null, 'd', cutting_line_path_data);
              cutting_line_path_el.setAttributeNS(null, 'fill', 'none');
              cutting_line_path_el.setAttributeNS(null, 'stroke', '#FF0000');
              cutting_line_path_el.setAttributeNS(null, 'stroke-width', '0.001');
              cutting_line_svg_el.appendChild(cutting_line_path_el);

              document.body.appendChild(cutting_line_svg_el);
              const margin = 2;
              const bbox = cutting_line_path_el.getBBox();
              const view_box_x = Math.floor(bbox.x) - margin;
              const view_box_y = Math.floor(bbox.y) - margin;
              const view_box_width = Math.ceil(bbox.width) + margin * 2;
              const view_box_height = Math.ceil(bbox.height) + margin * 2;
              document.body.removeChild(cutting_line_svg_el);

              cutting_line_svg_el.setAttributeNS(
                null,
                'viewBox',
                go(
                  [view_box_x, view_box_y, view_box_width, view_box_height],
                  mapL((a) => `${a}`),
                  join(' '),
                ),
              );

              const hook_position_ratio = {
                x: (hook_position_x - view_box_x) / view_box_width,
                y: (hook_position_y - view_box_y) / view_box_height,
              };

              return {
                success: true,
                value: {
                  cutting_line_svg_el,
                  hook_position_ratio,
                  view_box: { x: view_box_x, y: view_box_y, width: view_box_width, height: view_box_height },
                },
              };
            } catch (error) {
              console.error(error);
              return {
                success: false,
                value:
                  error?.__mp_alert_message ??
                  T(
                    'modules::VectorEditor::Keyring::Free::Mobile::CuttingLineAuto::error_message::cutting_line_calculation_error',
                  ),
              };
            }
          })();
          if (!cutting_line_result.success) {
            return cutting_line_result;
          }
          const {
            cutting_line_svg_el,
            hook_position_ratio,
            view_box: outer_view_box,
          } = cutting_line_result.value;

          const inner_view_box_result = (() => {
            try {
              const svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
              svg_el.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns', 'http://www.w3.org/2000/svg');
              svg_el.setAttributeNS(null, 'viewBox', '0 0 0 0');

              const path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
              path_el.setAttributeNS(null, 'd', outer_cutting_line_path_data);
              path_el.setAttributeNS(null, 'fill', 'none');
              path_el.setAttributeNS(null, 'stroke', 'none');
              svg_el.appendChild(path_el);

              document.body.appendChild(svg_el);
              const margin = 2;
              const bbox = path_el.getBBox();
              const view_box_x = Math.floor(bbox.x) - margin;
              const view_box_y = Math.floor(bbox.y) - margin;
              const view_box_width = Math.ceil(bbox.width) + margin * 2;
              const view_box_height = Math.ceil(bbox.height) + margin * 2;
              document.body.removeChild(svg_el);

              return {
                success: true,
                value: { x: view_box_x, y: view_box_y, width: view_box_width, height: view_box_height },
              };
            } catch (error) {
              console.error(error);
              return { success: false, value: '고리 재단선 계산에 실패했습니다.' };
            }
          })();
          if (!inner_view_box_result.success) {
            return inner_view_box_result;
          }
          const { value: inner_view_box } = inner_view_box_result;

          return postProcess({
            cutting_line_svg_el,
            hook_position_ratio,
            outer_view_box,
            inner_view_box,
            makeTargetImageEl: async ({ factor }) => {
              const target_image_el = await makeTargetImageEl({ factor });
              applyCuttingLine({ target_image_el, path_data: inner_cutting_line_path_data });
              return target_image_el;
            },
          });
        },
      });
    } catch (error) {
      await $.alert(
        error?.__mp_alert_message ??
          T(
            'modules::VectorEditor::Keyring::Free::Mobile::CuttingLineAuto::error_message::cutting_line_making_error',
          ),
      );
      throw error;
    }
  };
