import { html, go, map, join, zipWithIndexL } from 'fxjs/es';
import { INBOUND_STATUSES, OUTBOUND_STATUSES, STATUSES } from '../../../S/Constant/lgl.js';
import { makeTabButtonsHtml } from './tmpl.tabs.js';

export function makeShippingDetailHeaderHtml({ projection_id, order_id = '' }) {
  return html` <div class="title">
      <strong>해외 운송장 관리</strong> 주문번호: ${projection_id}
      ${order_id ? `&nbsp;출고주문번호: ${order_id}` : ``}
    </div>
    <button type="button" class="don_hide_frame"><span></span></button>`;
}

/*
 *  @description 해외배송 modal 상단 렌더링
 *  @params type - 현재 상태 type (INBOUND: 입고, OUTBOUND: 출고)
 *  @params status - 현재 상태 값
 *  @params selected_tab_id - 현재 선택된 tab id
 *  @params error - 현재 상태 에러 여부 (검수 불량, 합포장 보류)
 *  @params error_tab_id - 에러가 발생한 tab id
 */
export function makeShippingDetailPageHeaderHtml({
  type,
  status,
  selected_tab_id,
  error,
  error_tab_id = '',
}) {
  return html` ${makeShippingDetailStepsHtml({
    type,
    status,
    error,
  })}
  ${makeTabButtonsHtml({ selected_tab_id, error_tab_id })}`;
}
/*
 *  @description 해외배송 상태 값 렌더링
 *  @params type - 현재 상태 type (INBOUND: 입고, OUTBOUND: 출고)
 *  @params status - 현재 상태 값
 *  @params error - 현재 상태 에러 여부 (검수 불량, 합포장 보류)
 */
export function makeShippingDetailStepsHtml({ type = 'INBOUND', status, error }) {
  const status_index = type === 'INBOUND' ? INBOUND_STATUSES[status] : OUTBOUND_STATUSES[status];

  return html`
    <div class="steps">
      ${go(
        STATUSES,
        zipWithIndexL,
        map(([idx, title]) => {
          return html`<div
            class="step ${status_index >= idx ? 'active' : ''} ${status_index === idx && error
              ? 'error'
              : ''}"
          >
            <div class="content">${title}</div>
          </div>`;
        }),
        join(''),
      )}
    </div>
  `;
}
