import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import { alertInbound } from '../../ShippingDetail/F/Function/alert.js';
import { fetchLglData, getHeaderData } from '../../ShippingDetail/F/Function/render.js';
import { DFLglShippingDetailMuiF } from '../../ShippingDetail/F/Mui/module/DFLglShippingDetailMuiF.js';
import {
  makeShippingDetailHeaderHtml,
  makeShippingDetailPageHeaderHtml,
} from '../../ShippingDetail/S/Tmpl/tmpl.shippingdetail.js';
import { alertsLgl } from './alerts.js';

/*
 *  @description 해외배송 modal 열기
 *  @params projection - projection
 */
export async function handleOpenDetail({ projection }) {
  try {
    // LGL-BUWHAL
    const { is_repress } = projection;
    if (is_repress) {
      await alertInbound.repress.alert({ confirmCallback: () => {} });
      return;
    }

    DfInhouseF.lottie_loader.start('cardboard', null, null, null);
    await MuiF.openFrame(
      DFLglShippingDetailMuiF.frame,
      async (
        frame,
        page,
        [tab_packaging, tab_inbound, tab_rebound, tab_return, tab_outbound, tab_tracking],
      ) => {
        const projection_id = projection.id;

        const {
          projection_data,
          projection_status,
          inbound_order_group,
          inbound_orders,
          return_orders,
          outbound_orders,
          lgl_overall_status,
        } = await fetchLglData({
          projection_id,
        });

        const { type, status, error, selected_tab_id, error_tab_id } = getHeaderData({
          projection_status,
          inbound_orders,
          outbound_orders,
        });

        frame.custom_header = () =>
          makeShippingDetailHeaderHtml({ projection_id, order_id: outbound_orders?.order_id });
        frame.projection_id = projection_id;

        page.custom_header = () =>
          makeShippingDetailPageHeaderHtml({ type, status, selected_tab_id, error, error_tab_id });

        tab_packaging.makeData = () => ({
          projection: projection_data,
          projection_status,
          inbound_orders,
        });
        tab_inbound.makeData = () => ({ inbound_orders });
        tab_return.makeData = () => ({ return_orders });
        tab_rebound.makeData = () => ({ inbound_orders });
        tab_outbound.makeData = () => ({ projection_id, inbound_order_group, outbound_orders });
        tab_tracking.makeData = () => ({ lgl_overall_status });
      },
    );
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}
