import axios from 'axios';
import { $delegate, $find } from 'fxdom/es';
import { go } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import {
  changeCurrentTab,
  compactObject,
  getSelectValue,
  goToTheSiteEvent,
  renderThumbnailPage,
  testPageLoader,
} from './fs.js';

export const watch_real_thumbnails = {
  tab_name: 'watch_real_thumbnails',
  title: '실제 올려진 썸네일 확인',
  template: function () {
    return legacyHtml`
      <div class="option">
        <button class="more">...</button>
      </div>
      <div class="list_wrapper"></div>
    `;
  },
  appended: async function (don_tab) {
    const list_wrapper_el = $find('.list_wrapper', don_tab);
    let offset = 0;
    const step = 28;
    async function _render() {
      const { data: product_colors } = await axios.post('/@api/composite/test_page/get_pcs', {
        cond: compactObject({
          base_product_id: box.sel('bp->id'),
          base_product_color_id: getSelectValue(don_tab, 'base_product_color_id'),
        }),
        offset,
        limit: step,
      });
      offset += step;
      await renderThumbnailPage(list_wrapper_el, product_colors);
    }
    don_tab.tab_opt.reset_render = async function () {
      list_wrapper_el.innerHTML = '';
      offset = 0;
      await _render();
    };
    go(don_tab, $delegate('click', '.more', testPageLoader(_render)), goToTheSiteEvent);
  },
  showed: function (don_tab) {
    changeCurrentTab(don_tab);
  },
};
