import { $find, $findAll } from 'fxdom/es';
import { chunk, find, flatMap, go, map, mapC } from 'fxjs/es';

import { render_canvas } from '../../Core/S/composite_template_canvas.js';

export async function renderAssocCompositeTemplates(
  assoc_composite_templates_els,
  assoc_composite_templates,
  product,
) {
  await go(
    assoc_composite_templates_els,
    $findAll('.assoc_composite_template'),
    chunk(5),
    flatMap(
      mapC(async (assoc_composite_template_el) => {
        const { composite_template_id } = assoc_composite_template_el.dataset;
        const canvas = $find('canvas', assoc_composite_template_el);
        const assoc_composite_template = go(
          assoc_composite_templates,
          find((assoc_composite_template) => assoc_composite_template.id === parseInt(composite_template_id)),
        );

        canvas.width = assoc_composite_template.width;
        canvas.height = assoc_composite_template.height;
        return render_canvas({
          assoc_composite_template,
          canvas_el: canvas,
          selected_base_product_colors: go(
            [product],
            map((pc) => {
              return {
                id: pc.base_product_color_id,
                base_product_id: pc.base_product_id,
              };
            }),
          ),
          design_ready_url: assoc_composite_template._.composite_result.design_ready_url,
        });
      }),
    ),
  );
}
