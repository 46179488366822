import { filter, find, go, map, reject, sel, take, omit, defaults, some } from 'fxjs/es';
import { evaluateColorBrightness } from '../../Canvas/S/util.js';
import { $closest } from 'fxdom/es';
import { NewMakerWeS } from '../../NewMaker/We/S/Function/module/NewMakerWeS.js';
import { UtilObjS } from '../../Util/Object/S/Function/module/UtilObjS.js';
import { BpOptionConstantS } from '../../BpOption/S/Constant/module/BpOptionConstantS.js';
import {
  LOC_OPT_ACTIVATE_ATTR_NAME,
  LOC_OPT_ENABLE_ATTR_NAME,
  LOC_OPT_REQUEST_CS,
  LOC_OPT_SEEN_PROOF,
} from '../S/constant.js';

const getMaker = () => box().maker;

export function getMpMakerEl(fcanvas) {
  return go(fcanvas.lowerCanvasEl, $closest('.mp_maker'));
}

export const getProductColorInMaker = () => getMaker().product_color;
export const getProductFaces2InMaker = () => getProductColorInMaker().product_faces2.value;
export const getBaseProductInMaker = () => getProductColorInMaker()._.base_product;
export const getBaseProductSizesInMaker = () => getBaseProductInMaker()._.base_product_sizes;
export const getBaseProductColorsInMaker = () => getBaseProductInMaker()._.base_product_colors;
export const getBaseProductColorInMaker = () => getProductColorInMaker()._.base_product_color;
export const getBaseProductColorFacesInMaker = () => getBaseProductColorInMaker()._.base_product_color_faces;

export const getSelectedOptionGroupInMaker = () => {
  const selected_option_group = getProductColorInMaker()._.selected_option_group;
  if (selected_option_group?.bp_option_ids) return selected_option_group;
};

export const getBaseProductFacesInMaker = () =>
  go(
    getBaseProductColorFacesInMaker(),
    map((bpcf) => bpcf._.base_product_face),
  );

export const isMakerContext = () => {
  return !!window.box && box.sel('maker') != null;
};

export function bpcIsBlack() {
  const bp = getBaseProductInMaker();
  return go(getBaseProductColorInMaker(), (bpc) => {
    if (bpc.is_black) return true;
    if (bp?.id === 2996) {
      if (bpc.color_code === '#d02933') return;
      if (bpc.color_code === '#304dc2') return;
      if (bpc.color_code === '#082e57') return;
      if (bpc.color_code === '#000000') return;
    }
    return evaluateColorBrightness(bpc.color_code) < 0.3;
  });
}
export function getBpfOfFcanvas(f_canvas) {
  return go(
    getBaseProductColorFacesInMaker(),
    find((bpcf) => bpcf._.base_product_face.id === f_canvas.bpf_id),
    sel('_.base_product_face'),
  );
}

export function getFcanvasByBpfId(bpf_id) {
  return go(
    getRealFcanvass(),
    find((fcanvas) => fcanvas.bpf_id == bpf_id),
  );
}

export const getProperProductFaces = (pc) =>
  (some((pf) => pf.is_design_over_bpf, pc.product_faces2.value) && sel('value', pc.product_faces)) ||
  pc.product_faces2.value;

export const getBpcfsLength = function () {
  return getBaseProductColorFacesInMaker().length;
};

export const getFcanvass = () => {
  return getMaker().canvass;
};

export const getRealFcanvass = () => {
  return go(getFcanvass(), take(getBpcfsLength()));
};

export const getOtherCanvass = (fcanvas) => {
  return go(
    getRealFcanvass(),
    reject((rc) => rc === fcanvas),
  );
};

export const getDesigns = (fcanvas) => {
  return go(
    fcanvas._objects,
    reject((cv_obj) => cv_obj._data.is_not_design),
  );
};

export const getCvBpcf = (fcanvas) => {
  return go(
    fcanvas._objects,
    find((cv_obj) => cv_obj._data.cv_type === 'cv_bpcf'),
  );
};

export const getCanvasOtherObject = (cv_obj) => {
  return go(
    cv_obj.canvas._objects,
    reject((_cv_obj) => cv_obj === _cv_obj),
  );
};

export const getSelectedCanvasContainer = () => {
  return $1('.canvas_container.selected');
};
export const getCanvasContainers = () => {
  return $('.canvas_container');
};

export const getSelfCanvasContainer = (f_canvas) => {
  return $.closest(f_canvas.lowerCanvasEl, '.canvas_container');
};

export const getSelfCanvasZoomWrapper = (f_canvas) => {
  return $.closest(f_canvas.lowerCanvasEl, '.canvas_zoom_wrapper');
};

export const getSelectedCanvasZoomWrapperEl = () => {
  return $.find1(getSelectedCanvasContainer(), '.canvas_zoom_wrapper');
};
export const getCanvasZoomWrapperEls = () => {
  return $('.canvas_container .canvas_zoom_wrapper');
};

export const getRealCanvasZoomWrapper = () => {
  return go(getCanvasZoomWrapperEls(), take(getBpcfsLength()));
};

export const getOtherScreenCanvass = () => {
  const selected = getSelectedCanvasContainer();
  return go(
    getCanvasContainers(),
    reject((c) => selected === c),
  );
};

export const getCanvasContainerElSize = () => {
  return go(getSelectedCanvasContainer(), (el) => {
    return {
      width: $.width(el),
      height: $.height(el),
    };
  });
};

export const getCanvasScreenRatio = (f_canvas) => {
  return getCanvasContainerElSize().width / f_canvas.lowerCanvasEl.width;
};

export const getCvTexts = (f_canvas) => {
  f_canvas = f_canvas || G.mp.maker.editing_canvas();
  return go(
    f_canvas._objects,
    filter((design) => design._data.cv_type === 'cv_text'),
  );
};

export function selFromPcPf2WhereBpfId(bpf_id) {
  return go(
    getProductFaces2InMaker(),
    find((pf) => pf.bpf_id === bpf_id),
  );
}

export function getPfByFcanvas(fcanvas) {
  return selFromPcPf2WhereBpfId(fcanvas.bpf_id);
}

G.mp.maker = G.mp.maker || {};
let first_editing_canvas = true;
G.mp.maker.editing_canvas = function (idx, skip_push) {
  const current_idx = box.sel('maker->editing_canvas_idx');
  if (idx == null || idx === current_idx) return box.sel('maker->editing_canvas');
  const bpcfs = box.sel('maker->product_color->_->base_product_color->_->base_product_color_faces');
  if (bpcfs.length - 1 < idx) idx = 0;
  const canvas = box.sel('maker->canvass->' + (idx = idx === -1 ? 0 : idx));
  _go(
    $1('#maker'),
    $.find('.canvas_container'),
    $.hide,
    $.eq((idx = idx === -1 ? 0 : idx)),
    $.selectable2,
    $.show,
  );

  box.set('maker->editing_canvas', canvas);
  box.set('maker->editing_canvas_idx', idx);

  const bpcf = box.sel('maker->product_color->_->base_product_color->_->base_product_color_faces')[idx];

  $.text($1('.select_face .selected_face .text'), bpcf._.base_product_face['name' + _en]);

  if (!first_editing_canvas && !skip_push) G.mp.maker.state.push();
  first_editing_canvas = false;
  return canvas;
};

G.mp.maker.editing_canvas_idx = function () {
  return box.sel('maker->editing_canvas_idx');
};

export function getCurrentBpId() {
  return getProductColorInMaker().base_product_id;
}

export function getCurrentBpcId() {
  return getProductColorInMaker().base_product_color_id;
}

export function getCurrentBpsId() {
  return getProductColorInMaker().base_product_size_id;
}

export function getCurrentBpcInMaker() {
  return getProductColorInMaker()._.base_product_color;
}

export function getCurrentBpcfsInMaker() {
  return getCurrentBpcInMaker()._.base_product_color_faces;
}

export function getCurrentBpcfInMaker() {
  const current_bpf_id = G.mp.maker.editing_canvas().bpf_id;
  return go(
    getBaseProductColorFacesInMaker(),
    find((bpcf) => bpcf.base_product_face_id === current_bpf_id),
  );
}

export function getCurrentBpfInMaker() {
  return go(getCurrentBpcfInMaker(), (bpcf) => bpcf._.base_product_face);
}

export const isEmbro = function (cv_object) {
  return cv_object._data.is_embro;
};

export const isOnlyFlex = function (cv_object) {
  return cv_object._data.is_embro || G.mp.maker.only_flex(cv_object);
};

export const isCvImage = function (cv_object) {
  return cv_object._data.cv_type === 'cv_image';
};

export const getItsBpf = function (f_canvas) {
  const bpf_id = f_canvas.bpf_id;
  return go(
    getBaseProductColorFacesInMaker(),
    find((bpcf) => bpcf.base_product_face_id === bpf_id),
    (bpcf) => bpcf._.base_product_face,
  );
};

export const getFcanvasOfBpfId = function (bpf_id) {
  return go(
    getRealFcanvass(),
    find((fcanvas) => fcanvas.bpf_id === bpf_id),
  );
};

export const getItsSf = function (f_canvas) {
  const bps_id = getProductColorInMaker().base_product_size_id;
  return go(
    getItsBpf(f_canvas).size_faces,
    find((sf) => sf.base_product_size_id === bps_id),
  );
};

export const getItsPrintArea = function (f_canvas) {
  return getItsSf(f_canvas)?.print;
};

export const getItsProductArea = function (f_canvas) {
  return getItsSf(f_canvas)?.product;
};

export const getItsSafetyArea = function (f_canvas) {
  return getItsSf(f_canvas)?.safety;
};

export function getSfFromBpcfByBpsIdInMaker(bpcf, bps_id) {
  return find(function (sf) {
    return sf.base_product_size_id === bps_id;
  }, bpcf._.base_product_face.size_faces);
}

export const isCanOverflow = function (base_product) {
  base_product = base_product || getBaseProductInMaker();
  return sel('can_overflow', base_product);
};

function getDesignsNotDesigns(cv_arr) {
  return _p.go(
    cv_arr,
    _p.partition((cv_obj) => cv_obj._data.is_not_design),
    function ([not_designs, designs]) {
      return { designs, not_designs };
    },
  );
}

function getGroupedByCvType(cv_objs) {
  return _p.go(
    cv_objs,
    _p.groupBy((cv_obj) => cv_obj._data.cv_type),
  );
}

export function makeProductFaces2Value(fcanvas, product_color) {
  const cv_attrs_list = _p.go(
    fcanvas.toJSON().objects,
    _p.map(function (cv_obj) {
      cv_obj.visible = cv_obj._data._visible || cv_obj.visible;
      return G.mp.maker.onescreen(cv_obj);
    }),
  );
  const { designs, not_designs } = getDesignsNotDesigns(cv_attrs_list);
  G.mp.maker.cv_objects_deep_each(designs, function (cv_attrs) {
    if (cv_attrs._data._visible) {
      delete cv_attrs._data._visible;
      cv_attrs.visible = true;
    }
    if (cv_attrs._data.cv_type === 'cv_pattern') {
      if (cv_attrs._data.cv_image_attrs.src)
        cv_attrs._data.cv_image_attrs.src = G.remove_protocol2(cv_attrs._data.cv_image_attrs.src);
    }
    if (cv_attrs._data.cv_type === 'cv_pattern' || cv_attrs._data.cv_type === 'cv_text_image') {
      delete cv_attrs.src;
    }
    if (cv_attrs._data.cv_type === 'cv_text_image_pattern') {
      delete cv_attrs.src;
      cv_attrs._data = defaults({}, cv_attrs._data);
      cv_attrs._data.cv_text_image_attrs = omit(['src'], cv_attrs._data.cv_text_image_attrs);
      // cv_attrs._data.cv_text_image_attrs = omit(['src'], cv_attrs._data.cv_text_image_attrs);
    }
    if (cv_attrs.src) cv_attrs.src = G.remove_protocol2(cv_attrs.src);
  });
  const {
    cv_bpcf,
    cv_print_area,
    cv_mask1,
    cv_mask2,
    cv_shading,
    cv_preview,
    cv_background,
    cv_bp_option_layer,
  } = _p.go(
    not_designs,
    getGroupedByCvType,
    _p.mapObject((arr) => _p.first(arr)),
    _p.mapObject(function (obj) {
      if (obj.src) obj.src = obj.src = G.remove_protocol2(obj.src);
      return obj;
    }),
  );
  const bpf = getBpfOfFcanvas(fcanvas);
  const pf = _p.find(_p.v(product_color, 'product_faces2.value'), function (pf) {
    return pf.bpf_id === bpf.id;
  });

  const printable_files = _p.v(pf, 'printable_files') || [];
  const debug = {};
  if (!cv_mask1) {
    try {
      debug.cv_mask1 = cv_mask1;
      debug.real_cv_mask1 = _p.go(
        G.mp.maker.cv_mask1(fcanvas),
        (cv_mask1) => cv_mask1.toJSON(),
        G.mp.maker.onescreen,
      );
    } catch (e) {
      debug.cv_mask1 = 'no_cvmask1';
    }
  }
  return {
    bpf_id: bpf.id,
    rotate: (pf && pf.rotate) || 0,
    debug,
    bpf_mask2_padding_top: bpf.mask2_padding_top,
    face_name: bpf.name,
    cv_background,
    start_name: bpf.start_name,
    face_name_en: bpf.name_en || bpf.name,
    face_name_jp: bpf.name_jp || bpf.name_en || bpf.name,
    size_faces: bpf.size_faces,
    size_info: fcanvas.size_info,
    [LOC_OPT_ENABLE_ATTR_NAME]: bpf?.[LOC_OPT_ENABLE_ATTR_NAME] ?? false, // 해당면이 위치 조정 요청 기능 가능한 된 면인지 여부
    [LOC_OPT_ACTIVATE_ATTR_NAME]: pf?.[LOC_OPT_ACTIVATE_ATTR_NAME] ?? false, // 유저에 의해 위치 취적화 요청을 활성화 시켰는지 여부
    [LOC_OPT_SEEN_PROOF]: pf?.[LOC_OPT_SEEN_PROOF] ?? false, // 유저가 위치 조정 요청을 확인했는지 여부
    [LOC_OPT_REQUEST_CS]: pf?.[LOC_OPT_REQUEST_CS] ?? false, // 유저가 주문 후에 CS 를 통해서 위치 조정 요청을 했는지 여부
    designs,
    cv_bpcf: _p.extend(cv_bpcf, {
      visible: true,
    }),
    cv_print_area: _p.extend(cv_print_area, {
      visible: false,
    }),
    cv_mask1: _p.extend(cv_mask1, {
      visible: true,
    }),
    cv_preview,
    cv_mask2: _p.extend(cv_mask2, {
      visible: false,
    }),
    cv_shading,
    cv_bp_option_layer,
    printable_files,
  };
}

export function setProductFaces2Value() {
  const product_color = getProductColorInMaker();
  const fcanvass = getRealFcanvass();
  const faces = _map(fcanvass, (fcanvas) => makeProductFaces2Value(fcanvas, product_color));
  const can_not_change_color = _p.v(product_color.product_faces2, 'can_not_change_color');

  // pf2 -> biz 데이터 캐싱
  const biz_pf2_key = BpOptionConstantS.BIZ_PF2_KEY;
  const exist_pf2_biz_option_values = product_color.product_faces2?.[biz_pf2_key];

  if (collabo_type === 'creator') {
    product_color.product_faces = {};
    product_color.product_faces.value = faces;
  }
  product_color.product_faces2 = {};
  product_color.product_faces2.value = NewMakerWeS.isWeBaseProduct(product_color.base_product_id)
    ? faces
    : _p.filter(faces, 'designs.length');
  product_color.product_faces2.can_not_change_color = can_not_change_color;
  if (!product_color.product_faces2.value.length) {
    product_color.product_faces2.value = [faces[0]];
  }

  // pf2.biz 데이터가 있으면 유지 복사
  UtilObjS.isNotEmpty(exist_pf2_biz_option_values) &&
    (product_color.product_faces2[biz_pf2_key] = exist_pf2_biz_option_values);
}

export function setProductFaces2Value2() {
  const product_color = getProductColorInMaker();
  const fcanvass = getRealFcanvass();
  const faces = _map(fcanvass, (fcanvas) => makeProductFaces2Value(fcanvas, product_color));
  const can_not_change_color = _p.v(product_color.product_faces2, 'can_not_change_color');
  product_color.product_faces = {};
  product_color.product_faces.value = faces;
  product_color.product_faces2 = {};
  product_color.product_faces2.value = faces;
  product_color.product_faces2.can_not_change_color = can_not_change_color;
  if (!product_color.product_faces2.value.length) {
    product_color.product_faces2.value = [faces[0]];
  }
}

export function createCanvasElementByBpcfs() {
  return go(
    getBaseProductColorFacesInMaker(),
    map(function () {
      return document.createElement('canvas');
    }),
  );
}
