import { add, go, join, map, reduce } from 'fxjs/es';
import { GoodsTypeS } from '../../../GoodsType/S/Function/module/GoodsTypeS.js';

export const getWaybillCJgoodsNameStr = (goods_info, projection_id, worker_name, show_ctn = 9) => {
  const split_goods_info = goods_info.slice(0, show_ctn);
  const residual_len = goods_info.slice(show_ctn).length;

  const total_quantity = go(
    goods_info,
    map((g) => g.quantity),
    reduce(add),
  );
  return (
    ` 주문번호 : ${projection_id}        총 수량 : ${total_quantity}개        검수자 : ${
      worker_name.substr(0, 1) + '♡' + worker_name.substr(2)
    }\\&` +
    go(
      split_goods_info,
      map((g) => {
        if (GoodsTypeS.isTpl(g.goods_type_id)) {
          const option_name = g.group_names_option_names?.length
            ? go(
                g.group_names_option_names,
                map(({ option_name }) => option_name),
                join(','),
              )
            : '';
          return `□ ${g.product_name} ${option_name}-${g.quantity}개`;
        }
        return `□ ${g.bp_name} ${g.bp_name.includes(g.color) ? '' : g.color ?? ''}-${g.size ?? ''}-${
          g.quantity ?? ''
        }개`;
      }),
      join('\\&'),
    ) +
    `${residual_len === 0 ? '' : `  ....외 ${residual_len}건`}`
  );
};
