import { legacyHtml } from '../../../Util/S/Function/util.js';
import { changeCurrentTab, makeThumbnailMigrationTestTab } from './fs.js';

export const thumbnail_migration_test_composite_tab = {
  tab_name: 'thumbnail_migration_test_composite_tab',
  title: '썸네일 마이그레이션 테스트 합성',
  template: () => legacyHtml`
    <div class="option">
      <button class="migration">Migration</button>
      <button class="more">...</button>
    </div>
    <div class="option2">
      <div class="wrapper">
        <label for="is_width_og">is_with_og</label>
        <input type="checkbox" name="is_with_og" />
      </div>
      <div class="wrapper">
        <label for="is_width_og">is_only_og</label>
        <input type="checkbox" name="is_only_og" />
      </div>
      <div class="wrapper">
        <label for="select_new_thumb">select_new_thumb</label>
        <input type="checkbox" name="select_new_thumb" />
      </div>
      <div class="wrapper">
        <label for="with_out_created_composite_thumbnail">이미 만들어진 합성 썸네일 제외</label>
        <input type="checkbox" name="with_out_created_composite_thumbnail" />
      </div>
      <div class="wrapper">
        <label for="">total products :</label>
        <span class="total_pc_length"></span>
      </div>
    </div>
    <div class="list_wrapper"></div>
  `,
  appended: async function (don_tab) {
    await makeThumbnailMigrationTestTab(don_tab, { is_composite: true, is_for_migration: true });
  },
  showed: function (don_tab) {
    changeCurrentTab(don_tab);
  },
};
