import { makePackagingTabHtml } from '../Tmpl/tmpl.tabs.js';
import { base_tab } from './base_tab.js';

const mui_ref_path = '/modules/Df/Lgl/ShippingDetail/S/Mui/tab_packaging.js';
export const tab_packaging = base_tab({
  mui_ref_path,
  title: '상품 포장',
  tag: 'packaging',
  template: makePackagingTabHtml,
});
