import { equals2 } from 'fxjs/es';
import { VectorEditorStickerSingleMobileConstS } from '../../S/Const/module/VectorEditorStickerSingleMobileConstS.js';
import { VectorEditorStickerSingleMobileF } from '../Function/module/VectorEditorStickerSingleMobileF.js';

const TYPE_NONE_TOP_MENU_ID = {
  ACTIVATE: new Set(),
  DEACTIVATE: new Set([
    VectorEditorStickerSingleMobileConstS.TOP_MENU.DELETE.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FORWARD.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.BACKWARD.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.GROUP.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.UNGROUP.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.LOCK.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
};
const TYPE_SINGLE_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorStickerSingleMobileConstS.TOP_MENU.DELETE.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.LOCK.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FORWARD.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.BACKWARD.ID,
  ]),
  DEACTIVATE: new Set([
    VectorEditorStickerSingleMobileConstS.TOP_MENU.UNGROUP.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.GROUP.ID,
  ]),
};
const TYPE_GROUP_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorStickerSingleMobileConstS.TOP_MENU.DELETE.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.LOCK.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FORWARD.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.BACKWARD.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.UNGROUP.ID,
  ]),
  DEACTIVATE: new Set([VectorEditorStickerSingleMobileConstS.TOP_MENU.GROUP.ID]),
};
const TYPE_MULTIPLE_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorStickerSingleMobileConstS.TOP_MENU.DELETE.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.GROUP.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.LOCK.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
  DEACTIVATE: new Set([
    VectorEditorStickerSingleMobileConstS.TOP_MENU.FORWARD.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.BACKWARD.ID,
    VectorEditorStickerSingleMobileConstS.TOP_MENU.UNGROUP.ID,
  ]),
};

const handleSingleStickerEditorSelectOrUnselect =
  ({ tab_el }) =>
  (event) => {
    const single_sticker_editor = tab_el.__mp_single_sticker_editor;
    const { selected_els } = event.detail;

    if (single_sticker_editor == null) {
      VectorEditorStickerSingleMobileF.showTypeNoneFooters(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
      VectorEditorStickerSingleMobileF.showBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.activateTopMenus({
        tab_el,
        activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
      });
      return;
    }

    if (selected_els == null) {
      VectorEditorStickerSingleMobileF.showTypeNoneFooters(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
      VectorEditorStickerSingleMobileF.showBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.activateTopMenus({
        tab_el,
        activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
      });
      return;
    }

    const count = selected_els.size;
    if (count > 0) {
      single_sticker_editor.turnOffArtBoardClipPath();
    } else {
      single_sticker_editor.turnOnArtBoardClipPath();
    }

    if (count == null || count <= 0) {
      VectorEditorStickerSingleMobileF.showTypeNoneFooters(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
      VectorEditorStickerSingleMobileF.showBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.activateTopMenus({
        tab_el,
        activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
      });
      return;
    }

    if (count >= 2) {
      VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.hideAllFooters(tab_el);
      VectorEditorStickerSingleMobileF.activateTopMenus({
        tab_el,
        activate_ids: TYPE_MULTIPLE_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_MULTIPLE_TOP_MENU_ID.DEACTIVATE,
      });
      return;
    }

    const selected_el = selected_els.values().next().value;

    if (selected_el == null) {
      VectorEditorStickerSingleMobileF.showTypeNoneFooters(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
      VectorEditorStickerSingleMobileF.showBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.activateTopMenus({
        tab_el,
        activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
      });
      return;
    }

    const selected_el_tag_name = selected_el.tagName;

    if (selected_el_tag_name == null) {
      VectorEditorStickerSingleMobileF.showTypeNoneFooters(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
      VectorEditorStickerSingleMobileF.showBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.activateTopMenus({
        tab_el,
        activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
      });
      return;
    }

    if (equals2(selected_el_tag_name)('text')) {
      VectorEditorStickerSingleMobileF.showTypeTextFooters(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.activateTopMenus({
        tab_el,
        activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
      });
      return;
    }

    if (equals2(selected_el_tag_name)('path')) {
      VectorEditorStickerSingleMobileF.showTypePathFooters(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.activateTopMenus({
        tab_el,
        activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
      });
      return;
    }

    if (new Set(['circle']).has(selected_el_tag_name)) {
      VectorEditorStickerSingleMobileF.showTypeShapeFooters(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.activateTopMenus({
        tab_el,
        activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
      });
      return;
    }

    if (single_sticker_editor.getIsGroupEl(selected_el)) {
      VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
      VectorEditorStickerSingleMobileF.hideBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
      VectorEditorStickerSingleMobileF.hideAllFooters(tab_el);
      VectorEditorStickerSingleMobileF.activateTopMenus({
        tab_el,
        activate_ids: TYPE_GROUP_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_GROUP_TOP_MENU_ID.DEACTIVATE,
      });
      return;
    }

    VectorEditorStickerSingleMobileF.showTypeObjectFooters(tab_el);
    VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
    VectorEditorStickerSingleMobileF.hideBottomMenus(tab_el);
    VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
    VectorEditorStickerSingleMobileF.activateTopMenus({
      tab_el,
      activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
      deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
    });
  };

export const handleSingleStickerEditorSelect =
  ({ tab_el }) =>
  (event) =>
    handleSingleStickerEditorSelectOrUnselect({ tab_el })(event);

export const handleSingleStickerEditorUnselect =
  ({ tab_el }) =>
  (event) =>
    handleSingleStickerEditorSelectOrUnselect({ tab_el })(event);

const handleSingleStickerEditorLockOrUnlock =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    const lock_count = editor.getLockedEls().size;
    VectorEditorStickerSingleMobileF.setLockCount({ tab_el, count: lock_count });
    lock_count <= 0
      ? VectorEditorStickerSingleMobileF.deactivateTopMenuUnlockIcon(tab_el)
      : VectorEditorStickerSingleMobileF.activateTopMenuUnlockIcon(tab_el);
  };

export const handleSingleStickerEditorLock =
  ({ tab_el }) =>
  () =>
    handleSingleStickerEditorLockOrUnlock({ tab_el })();

export const handleSingleStickerEditorUnlock =
  ({ tab_el }) =>
  () =>
    handleSingleStickerEditorLockOrUnlock({ tab_el })();

export const handleSingleStickerEditorAdd =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_empty_template_el != null) {
      tab_el.__mp_empty_template_el.setAttributeNS(null, 'display', 'none');
    }
  };

export const handleSingleStickerEditorRemove =
  ({ tab_el }) =>
  () => {
    const count = tab_el.__mp_single_sticker_editor?.getGraphicsEls?.()?.size;
    if (count != null && count <= 0 && tab_el.__mp_empty_template_el != null) {
      tab_el.__mp_empty_template_el.removeAttributeNS(null, 'display');
    }
  };
