import { NewMakerConditionAgreeTextF } from '../../../NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { editOldCvTextFontStyle, editOldCvTextText } from '../text.js';
import { go } from 'fxjs/es';
import { makeCvOldTextHtml } from '../CvOldText/tmpl.js';

export const event = (el) =>
  go(
    el,
    $.on3('click', '.text_old_ev .edit_font_style', editOldCvTextFontStyle),
    $.on3('click', '.text_old_ev .edit_text', editOldCvTextText),
    (el) => NewMakerConditionAgreeTextF.clickEvent(el, '.condition_agree_text'),
  );

export const marpplizerCvOldTextImage = async function () {
  const cv_active = G.mp.maker.active();
  return {
    html: legacyHtml` <div class="activity">${makeCvOldTextHtml(cv_active)}</div> `,
    name: 'old_text',
    app: 'base2',
    event,
  };
};
