import UAParser from 'ua-parser-js';
import { $qs } from 'fxdom/es';
const parser = new UAParser();
const is_whale = parser?.getBrowser()?.name?.toLowerCase() === 'whale';
export const systemFontAffectCanvasFont = () => {
  if (!is_whale) return;
  if ($qs('html#dream_factory')) return;
  if (G.collabo_type === 'line') return;

  const is_mobile_cache = ['mobile', 'tablet'].includes(parser.getDevice().type);
  const canvas = document.createElement('canvas');
  canvas.width = 100;
  canvas.height = 100;
  const ctx = canvas.getContext('2d');
  const font = ctx.font;
  ctx.fillText('test', 0, 0);
  const after_font = ctx.font;
  if (font === after_font) return;
  const font_family = after_font.split(' ')[1];
  if (!font_family) return;
  const browser_custom_font = font_family.replace(/,/gi, '');
  /*네이버 앱의 디폴트 폰트는 잘 되니까 통과*/
  if (browser_custom_font.toLowerCase() === 'roboto-regular') return;
  const safari_app = T.lang === 'kr' ? '사파리 앱' : T.lang === 'jp' ? 'Safari app' : 'Safari app';
  const chrome_app =
    T.lang === 'kr'
      ? '크롬 또는 인터넷 앱'
      : T.lang === 'jp'
      ? 'Chrome or the Internet app'
      : 'Chrome or the Internet app';
  const chrome_browser =
    T.lang === 'kr' ? '"사파리" 또는 "크롬"' : T.lang === 'jp' ? 'Chrome browser' : 'Chrome browser';
  const browsers = is_mobile_cache
    ? /IOS/i.test(parser.getOS()?.name)
      ? safari_app
      : chrome_app
    : chrome_browser;

  if (G.collabo_type === 'creator') {
    const is_cart = /cart/i.test(window.location.href);
    const is_checkout = /checkout/i.test(window.location.href);
    const is_detail = box.sel('product_detail->product_color');
    if (is_cart || is_checkout || is_detail) {
      $.alert(T('maker_error_alert::시스템 글꼴이 마플샵 고객 뷰에 영향을 줌', { browsers }));
      return true;
    }
  }
  if (box.sel('maker')) {
    $.alert(T('maker_error_alert::시스템 글꼴이 만들기툴에 영향을 줌', { browsers }));
    return true;
  } else {
    if (G.collabo_type === 'creator') return;
    const is_cart = /cart/i.test(window.location.href);
    const is_checkout = /checkout/i.test(window.location.href);
    if (is_cart || is_checkout)
      $.alert(T('maker_error_alert::시스템 글꼴이 마플 고객 뷰에 영향을 줌', { browsers }));
    return true;
  }
};
