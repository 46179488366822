import { NewMakerSizeFacesEditorMuiS } from '../../S/Mui/module/NewMakerSizeFacesEditorMuiS.js';
import { basicDataSetting } from '../../../../Maker/F/Setting/data.js';
import { initMpMaker } from '../../../../Maker/F/mp_maker.js';
import { $find } from 'fxdom/es';
import { unsetMakerPage } from '../../../../Maker/F/Setting/unset.js';
import { sizeFaceEditorInit } from '../Function/sizefaceseditor.js';
import { go, sel } from 'fxjs/es';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...NewMakerSizeFacesEditorMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  async showed(tab_el$) {
    const main_el = $find('.main')(tab_el$);
    const base_product_id = main_el.dataset.base_product_id;
    await basicDataSetting({ base_product_id });
    /*DF*/
    box().maker.product_color._.base_product._.base_product_sizes = await go(
      $.get('/@api/base_product_sizes/by_bp_id', { base_product_id }),
      sel('base_product_sizes'),
    );
    fabric.charWidthsCache = {};
    await initMpMaker(main_el);
    await sizeFaceEditorInit();
    // G.mp.maker.zoom(true);
    // setTimeout(function () {
    //   const cv_print_area = getCvObj(getCurrentFcanvas()._objects, 'cv_print_area');
    //   cv_print_area.hasControls = false;
    //   G.mp.maker.multiple_select(cv_print_area);
    //   $.don_loader_end();
    // }, 1000);
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed() {
    unsetMakerPage();
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
