import { html } from 'fxjs/es';

export const product = html`
  <div class="product_menu super_menu">
    <div class="main_icon circle_icon product_icon">
      <div class="icon"></div>
      <div class="text">${T('maker::Product')}</div>
    </div>
    <div class="main_icon circle_icon zoom_in_1">
      <div class="icon"></div>
      <div class="text">${T('maker::100%')}</div>
    </div>
  </div>
`;
