import { compact, each, find, go, map, reverse, filter, findIndex } from 'fxjs/es';
import { DfBpLabelF } from './module/DfBpLabelF.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { DfBpLabelConstantS } from '../../S/Constant/module/DfBpLabelConstantS.js';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';
import { mmToDot } from './zpl_helpers.js';
import { zplWriteGenerator } from '../../../Stock/Labeling/F/Function/events.js';
import format from 'date-fns/format/index.js';

export async function createBpLabelFromUpcs({ up_c_ss, is_need_full_option_names }) {
  // api 재호출 방지 캐싱
  const cache_map = new Map();

  const bp_labels = await go(
    up_c_ss,
    filter((up_c_s) => {
      return up_c_s.is_hidden === false && up_c_s.quantity > 0;
    }),
    map(async (up_c_s) => {
      const barcode_data = `UP${up_c_s.id}`;
      const is_seller_product = up_c_s?.spo_item?.id;
      // POD 상품에 한해 라벨링 출력 진행
      return is_seller_product
        ? await getSellerProductLabel({ up_c_s })
        : await getMpsBpLabel({ up_c_s, barcode_data, cache_map, is_need_full_option_names });
    }),
    compact,
    reverse,
  );

  cache_map.clear();
  return bp_labels;
}

async function getMpsBpLabel({ up_c_s, cache_map, barcode_data, is_need_full_option_names }) {
  const { base_product_id, base_product_color_id, quantity } = up_c_s;
  const bp_label = { quantity, barcode_data };

  const unique_id = `bp${base_product_id}-bpc${base_product_color_id}`;

  if (cache_map.has(unique_id)) {
    bp_label.label_data = cache_map.get(unique_id);
  } else {
    const labels = await DfBpLabelF.api_call.get.mpsLabelData({
      base_product_id,
      base_product_color_id,
    });

    if (UtilArrayS.isEmNil(labels)) return null;

    const label_data = pickMpsLabelDataFromLabels({ labels });
    cache_map.set(unique_id, label_data);
    bp_label.label_data = label_data;

    const option_value = getBpLabelOptionStr({ up_c_s, is_seller_product: false, is_need_full_option_names });

    // 동적 추가 -> 옵션명
    if (UtilStringS.isNotEmpty(option_value)) {
      UtilArrayS.insertValueIntoArray({
        array: label_data,
        index: findIdxFromLabelData({ label_data, field_name: '상품명', default_idx: 3 }),
        value: makeOptionLabelField({ option_value }),
      });
    }

    // 동적 추가 -> 제조연월
    UtilArrayS.insertValueIntoArray({
      array: label_data,
      index: findIdxFromLabelData({ label_data, field_name: '제조국명', default_idx: 3 }),
      value: makeProductionDataLabelField(),
    });
  }

  return bp_label;
}

// @description 옵션명 동적 삽입 위치 결정 (상품명 필드 다음에 추가)
function findIdxFromLabelData({ label_data, field_name, default_idx }) {
  const product_name_idx = findIndex((l) => l.k === field_name, label_data);
  return product_name_idx > 0 ? product_name_idx + 1 : default_idx;
}

function makeOptionLabelField({ option_value }) {
  return { k: '옵션', v: option_value, a: 'inline', m: 'text' };
}
function makeProductionDataLabelField() {
  return { k: '제조연월', v: format(new Date(), 'yyyy년 M월'), a: 'inline', m: 'text' };
}

async function getSellerProductLabel({ up_c_s }) {
  // TBD - 셀러 상품일 경우 셀러가 직접 품표 부착을 진행하는 기획으로 갈 수도 있어, 기획에 따라 이후 작업
  return null;
}

function getBpLabelOptionStr({ up_c_s, is_seller_product, is_need_full_option_names }) {
  if (is_seller_product) {
    // TBD
    return '';
  }

  const {
    _: {
      base_product_color: { name: color_name },
      base_product_size: { name: size_name },
    },
  } = up_c_s;

  return is_need_full_option_names ? `${color_name}, ${size_name}` : color_name;
}

function pickMpsLabelDataFromLabels({ labels }) {
  // 공통 정보 -> base_product_id 만 존재하는 row
  // 그 외 base_product_color_id 가 존재하는 row 는 specific 라벨 정보 (없는 경우 공통 정보 사용)
  const common_label = find((l) => l.base_product_id, labels);
  const specific_label = find((l) => l.base_product_color_id, labels);
  return specific_label?.label_data ?? common_label.label_data;
}

// @params labels {label_data: [], quantity: Number}[]
export async function printProductLabel({ device, labels }) {
  const DPMM = 8;
  const { BLOCK, INLINE, HOR_LINE } = DfBpLabelConstantS.BP_LABEL_LAYOUT(DPMM);
  const kr_encoding = `^CI28`;
  const rotate_C90 = `^FWR,1`;
  const home = `^LH${DfBpLabelF.mmToDot(0)},${DfBpLabelF.mmToDot(2)}`;

  const LABEL_HEIGHT = 100; // mm
  const LABEL_WIDTH = 60; // mm
  const BARCODE_HEIGHT = 8; // mm

  const image_zpl_cache = new Map();

  const label_pages_zpl = await go(
    labels,
    map(async ({ label_data, quantity, barcode_data }) => {
      let content_zpl = '';
      let content_height = 0; // mm

      await go(
        label_data,
        each(async (label) => {
          const font_size = (label.fh || 14) / DPMM; // mm
          const font_unit_size = font_size / 2;
          const field_title = label.k;
          const field_content = label.v;

          const align = label.a;

          switch (align) {
            case 'block': {
              const { margin_y, x_pos, line_gap, max_width } = BLOCK;
              content_height += margin_y; // margin-top

              // -> 필드 타이틀
              if (UtilStringS.isNotEmpty(field_title)) {
                const { zpl, height_adder } = DfBpLabelF.getZplForText({
                  x: x_pos,
                  y: content_height,
                  text: field_title,
                  font_unit_size,
                  max_width,
                  line_gap,
                  font_size,
                  font_type: 'W', // 타이틀은 항상 bold
                  just: 'L', // 필드명은 항상 Left 배치
                });
                content_zpl += zpl;
                content_height += height_adder;
              }

              // -> 필드 내용 (텍스트 | 이미지 | 바코드)
              if (UtilStringS.isNotEmpty(field_content)) {
                // 텍스트 유형
                if (label.m === 'text') {
                  const { zpl, height_adder } = DfBpLabelF.getZplForText({
                    x: x_pos,
                    y: content_height,
                    text: field_content,
                    font_unit_size,
                    max_width,
                    line_gap,
                    font_size,
                    font_type: label.f || 'R',
                    just: label.j || 'J', // 블락일 때 필드 내용 텍스트 배치 기본값 Justification
                  });
                  content_zpl += zpl;
                  content_height += height_adder;
                }
                // 이미지 유형
                if (label.m === 'image') {
                  const image_src = label.v;

                  let image_zpl = '';
                  let image_height_px = 0;
                  content_height += 3 / DPMM;

                  if (image_zpl_cache.has(image_src)) {
                    const {
                      zpl,
                      size: { height },
                    } = image_zpl_cache.get(image_src);
                    image_zpl = zpl;
                    image_height_px = height;
                  } else {
                    const { is_OK, data, error } = await DfBpLabelF.graphicsToZpl({ image_src });
                    if (is_OK) {
                      const {
                        zpl,
                        size: { height },
                      } = data;

                      image_zpl = zpl;
                      image_height_px = height;
                      image_zpl_cache.set(image_src, data);
                    } else {
                      throw new Error(error);
                    }
                  }

                  if (UtilStringS.isEmNil(image_zpl))
                    throw new Error(`Image zpl 이 생성되지 않았습니다. 개발자 문의`);

                  if (image_height_px == null)
                    throw new Error(`라벨 이미지의 높이값 정보가 없습니다. 개발자 문의`);

                  content_zpl += DfBpLabelF.getZplGraphics({
                    x: 0,
                    y: content_height,
                    graphic_zpl: image_zpl,
                  });
                  content_height += image_height_px / DPMM;
                }
              }
              content_height += margin_y; //margin-bottom
              break;
            }

            case 'inline': {
              if (label.m === 'image')
                throw new Error(`라벨 이미지는 inline 을 허용하지 않습니다. 개발자 문의`);

              const {
                content: { x_pos, max_width },
              } = INLINE;

              const line_ctn = DfBpLabelF.calculateTextLines({
                text: field_content,
                max_width,
                unit_width: font_unit_size,
              });

              // 필드 타이틀
              const { line_gap } = INLINE;
              if (UtilStringS.isNotEmpty(field_title)) {
                const {
                  title: { x_pos, max_width },
                } = INLINE;
                const { zpl } = DfBpLabelF.getZplForText({
                  x: x_pos,
                  y: content_height,
                  text: field_title,
                  line_ctn,
                  font_unit_size,
                  max_width,
                  line_gap,
                  font_size,
                  font_type: 'W', // 타이틀은 항상 bold
                  just: 'L', // 필드명은 항상 Left 배치
                });
                content_zpl += zpl;
                // inline 배치 -> height 추가하지 않음.
              }

              // 필드 내용
              if (UtilStringS.isNotEmpty(field_content)) {
                if (label.m === 'text') {
                  const { zpl, height_adder } = DfBpLabelF.getZplForText({
                    x: x_pos,
                    y: content_height,
                    text: field_content,
                    line_ctn,
                    font_unit_size,
                    max_width,

                    line_gap,
                    font_size,
                    font_type: label.f || 'R',
                    just: label.j || 'L', // 인라인 때 필드 내용 텍스트 배치 기본값 Left
                  });
                  content_zpl += zpl;
                  content_height += height_adder;
                }
              } else {
                throw new Error(
                  `라벨 Inline 배치에서 빈 텍스트 값은 허용되지 않습니다. 필드타이틀: ${field_title}. 개발자 문의`,
                );
              }

              break;
            }
            case 'line': {
              const { x_pos, width, margin_y } = HOR_LINE;
              content_height += margin_y;
              content_zpl += DfBpLabelF.getZplHorLineR({ x: x_pos, y: content_height, w: width });
              content_height += margin_y;
              break;
            }
            default:
              throw new Error(`라벨에 알 수 없는 align 값이 존재합니다. ${align}. 개발자 문의`);
          }
        }),
      );

      const calculateBarcodeXPos = (barcode_data) => {
        const len = barcode_data.length;
        const BASE_X_POS = 6 + (LABEL_WIDTH - 60) / 2; // 60 mm 가로폭 라벨 기준으로 6 mm 일 때 중앙 위치
        if (len <= 9) return BASE_X_POS;

        return Math.max(0, BASE_X_POS - (len - 9) * 1.5);
      };

      const barcode_zpl = barcode_data
        ? DfBpLabelF.getZplBarcode({
            x: calculateBarcodeXPos(barcode_data), // mm
            y: LABEL_HEIGHT - BARCODE_HEIGHT - 4, // mm (5 for text)
            data: barcode_data,
            h: mmToDot(BARCODE_HEIGHT),
          })
        : '';

      const page_ctn = DfBpLabelF.getZplPrintQuantity({
        quantity,
      });

      return `^XA${kr_encoding}${rotate_C90}${home}${content_zpl}${barcode_zpl}${page_ctn}^XZ`;
    }),
  );

  for await (const value of zplWriteGenerator(device, label_pages_zpl)) {
    if (value === 1) {
      console.log('상품 품표 인쇄 전송 완료');
    }
  }
}
