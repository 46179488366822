import { $find } from 'fxdom/es';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';
import { ZOOM } from '../../S/Constant/constants.js';
import { identity } from 'fxjs/es';

let is_mouse_drag = false;

export function toolView({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const view = paper_scope.view;
  const $work_canvas = paper_scope.view.element;

  $work_canvas.addEventListener(
    'wheel',
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (DfImageEditorLibF.view_control.modes.zoom) {
        zooming({ view: paper_scope.view, e });
      }
    },
    { capture: false },
  );
  $work_canvas.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });

  view.onKeyDown = (paper_e) => {
    const e = paper_e.event;
    if (e.ctrlKey && e.code === 'Digit0') {
      DfImageEditorLibF.view_control.modes.fit = true;
      DfImageEditorF.setViewToBoundsFit({
        paper_scope,
        bounds: DfImageEditorF.findOutmostPixelCoords({ paper_scope }),
      });
    }
    if (DfImageEditorLibF.view_control.isControlling() === false) {
      if (e.code === 'Space') {
        DfImageEditorF.setCanvasCursor('grab', true);
        DfImageEditorLibF.view_control.modes.pan = true;
      } else if (e.altKey) {
        DfImageEditorF.setCanvasCursor('zoom-in', true);
        DfImageEditorLibF.view_control.modes.zoom = true;
      }
    }
  };
  view.onKeyUp = () => {
    if (DfImageEditorLibF.view_control.isControlling()) {
      DfImageEditorF.setCanvasCursor();
      DfImageEditorLibF.view_control.initialize();
    }
  };

  view.onMouseDown = (paper_e) => {
    const e = paper_e.event;

    if (e.button === 0) {
      is_mouse_drag = false;
      const start_x = e.clientX;
      const start_y = e.clientY;

      const start_point = view.viewToProject(new paper_scope.Point(start_x, start_y, { insert: false }));

      DfImageEditorF.closeAllBpcfPickerWindow();

      function handleMouseMove(paper_e) {
        const e = paper_e.event;
        if (!is_mouse_drag) {
          is_mouse_drag = true;
        }
        if (DfImageEditorLibF.view_control.modes.pan) {
          const current_x = e.clientX;
          const current_y = e.clientY;
          const move_point = paper_scope.view.viewToProject(
            new paper_scope.Point(current_x, current_y, { insert: false }),
          );

          paper_scope.view.translate(move_point.subtract(start_point));
        }
      }

      function handleMouseUp() {
        if (is_mouse_drag) {
          is_mouse_drag = false;
        }

        view.onMouseMove = undefined;
        view.onMouseUp = undefined;
      }
      view.onMouseMove = handleMouseMove;
      view.onMouseUp = handleMouseUp;
    }
  };
}

function zooming({ view, e }) {
  view.scale(
    1 + ZOOM.scale_delta * (e.deltaY > 0 ? -1 : 1),
    view.viewToProject({ x: e.offsetX, y: e.offsetY }),
  );
}

export function findOutmostPixelCoords({ paper_scope }) {
  const result = {
    top: null,
    bottom: null,
    left: null,
    right: null,
    width: null,
    height: null,
  };

  const r_stroke_bounds = DfImageEditorF.getRStrokeFitBounds({ tab_el: paper_scope.tab_el });
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });

  [r_stroke_bounds, printable_raster].filter(identity).forEach((item) => {
    ['top', 'bottom', 'left', 'right'].forEach((bound_position) => {
      if (result[bound_position] == null) {
        result[bound_position] = item.bounds[bound_position];
      } else {
        if (bound_position === 'top' || bound_position === 'left') {
          result[bound_position] = Math.min(result[bound_position], item.bounds[bound_position]);
        } else {
          result[bound_position] = Math.max(result[bound_position], item.bounds[bound_position]);
        }
      }
    });
  });

  result.width = result.right - result.left;
  result.height = result.bottom - result.top;

  return result;
}

export function findBoundaryItem({ items }) {
  const result = {
    top: null,
    bottom: null,
    left: null,
    right: null,
  };

  items.forEach((item) => {
    ['top', 'bottom', 'left', 'right'].forEach((bound_position) => {
      if (result[bound_position] == null) {
        result[bound_position] = item;
      } else {
        const previous_coord = result[bound_position].bounds[bound_position];
        const path_coord = item.bounds[bound_position];
        const is_far_more_from_center =
          bound_position === 'top' || bound_position === 'left'
            ? previous_coord > path_coord
            : previous_coord < path_coord;

        is_far_more_from_center && (result[bound_position] = item);
      }
    });
  });

  return result;
}

export function onViewResize({ tab_el, paper_scope }) {
  const $artboard = $find('.artboard', tab_el);
  const { width, height } = $artboard.getBoundingClientRect();
  DfImageEditorF.resizeAndRedrawCanvas({ paper_scope, width, height });
}
