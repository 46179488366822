import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerGridMobileCopyGridMuiF } from '../Mui/module/VectorEditorStickerGridMobileCopyGridMuiF.js';

export const copyGrid = ({ grids, onShowing, onHiding, container_el, grid_sticker_editor }) => {
  return MuiF.openFrame(VectorEditorStickerGridMobileCopyGridMuiF.frame, (frame, page, [tab]) => {
    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_grids = grids;
      tab_el.__mp_grid_sticker_editor = grid_sticker_editor;
      tab_el.__mp_container_el = container_el;
      tab_el.__mp_onShowing = onShowing;
      tab_el.__mp_onHiding = onHiding;
      return tab_appending.call(tab, tab_el);
    };
  });
};
