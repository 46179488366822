import { each, go } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { VectorEditorAcrylicFigureFreeMobileConstantS } from '../../S/Constant/module/VectorEditorAcrylicFigureFreeMobileConstantS.js';

export const activateLayersIcon = ({ tab_el }) => {
  go(
    tab_el,
    $findAll(
      `.header .icons [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.TOGGLE_LAYERS_MODE.ID}"]`,
    ),
    each((el) => (el.dataset.is_active = 'true')),
  );
};

export const deactivateLayersIcon = ({ tab_el }) => {
  go(
    tab_el,
    $findAll(
      `.header .icons [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.TOGGLE_LAYERS_MODE.ID}"]`,
    ),
    each((el) => (el.dataset.is_active = 'false')),
  );
};
