import { getCurrentFcanvas } from '../../../../../modules/Maker/F/Fcanvas/cv_object.js';
import { NewMakerCvObjectUtilF } from '../../../../../modules/NewMaker/CvObject/Util/F/Function/module/NewMakerCvObjectUtilF.js';
import { errorHandlingEventWrapper } from '../../../../../modules/NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { NewMakerFcanvasFuncF } from '../../../../../modules/NewMaker/Fcanvas/Func/F/Function/module/NewMakerFcanvasFuncF.js';

(function () {
  const all_class_names = [
    'has_clip_active',
    'has_active',
    'none_active',
    'has_clip_none',
    'has_clip_active',
    'is_show',
  ];
  function close() {
    _each(all_class_names, _p($.remove_class, $1('#tooltip_canvas')));
  }

  function event(maker_el) {
    _go(
      maker_el,
      $.on(
        'click',
        '#tooltip_canvas .copy',
        errorHandlingEventWrapper(function (e) {
          G.mp.maker.copy();
          G.mp.maker.tooltip_canvas.close();
        }),
      ),
      $.on(
        'click',
        '#tooltip_canvas .duplicate',
        errorHandlingEventWrapper(function (e) {
          G.mp.maker.duplicate();
          G.mp.maker.tooltip_canvas.close();
        }),
      ),
      $.on(
        'click',
        '#tooltip_canvas .cut',
        errorHandlingEventWrapper(function (e) {
          G.mp.maker.cut();
          G.mp.maker.tooltip_canvas.close();
        }),
      ),
      $.on(
        'click',
        '#tooltip_canvas .remove',
        errorHandlingEventWrapper(async function (e) {
          await NewMakerFcanvasFuncF.removeActiveCvObj();
          G.mp.maker.tooltip_canvas.close();
        }),
      ),
      $.on(
        'click',
        '#tooltip_canvas .paste',
        errorHandlingEventWrapper(function (e) {
          G.mp.maker.paste();
          G.mp.maker.tooltip_canvas.close();
        }),
      ),
      $.on(
        'click',
        '#tooltip_canvas .select_all',
        errorHandlingEventWrapper(function (e) {
          G.mp.maker.select_all(e);
          G.mp.maker.tooltip_canvas.close();
        }),
      ),
      $.on(
        'click',
        '#tooltip_canvas .remove_all',
        errorHandlingEventWrapper(async function (e) {
          G.mp.maker.unselect_all(getCurrentFcanvas());
          NewMakerCvObjectUtilF.removeFcanvasDesigns(getCurrentFcanvas());
          await G.mp.maker.modified();
          G.mp.maker.tooltip_canvas.close();
        }),
      ),
    );
  }

  G.mp.maker.tooltip_canvas = {
    close: close,
    event: event,
  };
})();
