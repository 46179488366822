import { DfInhouseF } from './module/DfInhouseF.js';
import { go, pluck, filter, map, reject, join, reverse, html, strMap } from 'fxjs/es';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';
import { DfInhouseConstantS } from '../../S/Constant/module/DfInhouseConstantS.js';
import { printWaybillCJ } from '../../../Waybill/F/Function/waybill_cj_printing.js';
import format from 'date-fns/format/index.js';
import { alertTrackingResult, parseTrackingReturnErrorMsg } from '../../../Projection/List/F/event.js';
import { $closest, $find, $parent } from 'fxdom/es';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';
import { boxTable } from './table.inhouse.js';

const {
  CREATE: { key: create_command },
  DELETE: { key: delete_command },
  REPRINT_WAYBILL: { key: reprint_waybill_command },
  COMPLETE: { key: complete_command },
} = DfInhouseConstantS.BUCKET_CONTROL_NAME;

export async function handleControlBucketEntity({ currentTarget: ct }) {
  const action = ct.name;
  switch (action) {
    /* 버킷 생성 시 */
    case create_command: {
      try {
        const crew_inhouses = DfInhouseF.stateBucket.gBox.get.crewInhouses();

        /* 선택 프롬프트 - crew_inhouse (crew 회사) */
        const { is_selected_crew_inhouse, selected_crew_inhouse } =
          await DfInhouseF.alert.prompt.selectCrewInhouse({ crew_inhouses });
        if (!is_selected_crew_inhouse) return;

        /* 선택 프롬프트 - crew_inhouse_shippings (crew 의 회사들 주소) */
        const { is_selected_crew_inhouse_shippings, selected_crew_inhouse_shippings } =
          await DfInhouseF.alert.prompt.selectCrewInhouseShippings({
            crew_inhouse_shippings: selected_crew_inhouse._.crew_inhouse_shippings,
          });
        if (!is_selected_crew_inhouse_shippings || UtilArrayS.isEmNil(selected_crew_inhouse_shippings))
          return;

        /* 라벨 프린트 -> 정상 프린트 된 운송장들만 -> bucket INSERT */
        DfInhouseF.lottie_loader.start('cardboard');

        const created_inhouse_shipping_bucket_ids = await go(
          await DfInhouseF.labelPrint.bucketWaybill({
            // 운송장 출력
            shippings: selected_crew_inhouse_shippings,
            is_override: false,
          }),
          filter(({ print_result: { is_OK } }) => is_OK), // 운송장 정상 출력
          reverse,
          map(
            async ({
              print_result: { waybill_no },
              shipping: { id: crew_inhouse_shipping_id },
              waybill_label_data,
            }) => {
              try {
                return await DfInhouseF.apiCalls.post.inhouseShippingBucket({
                  // INSERT
                  crew_inhouse_shipping_id,
                  waybill_no,
                  waybill_label_data: JSON.stringify(waybill_label_data),
                });
              } catch (err) {
                console.error(err);

                /* 운송장 출력은 되었으나 DB 인서트 실패한 경우 -> 운송장을 사용할 수 없음 안내 */
                await DfInhouseF.alert.error({
                  title: `운송장 DB 등록 실패<br>${waybill_no} `,
                  err,
                  additional_msg: `해당 운송장은 출력이 되었더라도 즉시 파기하여 주세요.`,
                });
              }
            },
          ),
          reject((data) => data == null),
          pluck('id'),
        );

        if (UtilArrayS.isArrayOk(created_inhouse_shipping_bucket_ids)) {
          DfInhouseF.lottie_loader.start('cardboard');
          const newly_created_buckets = await DfInhouseF.apiCalls.get.inhouseShippingBucketsFromIds({
            inhouse_shipping_bucket_ids: created_inhouse_shipping_bucket_ids,
          });

          /* gBOX 싱크 */
          DfInhouseF.stateBucket.gBox.insert.inhouseShippingBuckets({ buckets: newly_created_buckets });

          /* DOM 추가 */
          DfInhouseF.stateDOM.insert.inhouse_shipping_buckets({ buckets: newly_created_buckets });

          /* 테이블 업데이트 */
          await DfInhouseF.boxTable.replaceRowsToSelectedBucket();
        }
      } catch (err) {
        DfInhouseF.lottie_loader.end();
        await DfInhouseF.alert.error({ err });
      } finally {
        DfInhouseF.lottie_loader.end();
      }
      break;
    }

    /* 버킷 삭제 시 */
    case delete_command: {
      try {
        const bucket_el = DfInhouseF.stateDOM.get.selectedBucket();
        if (bucket_el == null) {
          throw new Error(`삭제할 선택된 버킷이 없습니다.`);
        }

        DfInhouseF.lottie_loader.start('cardboard');
        const bucket = DfInhouseF.stateDOM.get.selectedBucket(true);
        const boxes = DfInhouseF.stateDOM.get.selectedBucketBoxes(true);
        const box_ctn = boxes.length;

        // [validation] 이미 포장 완료된 박스 여부 체크
        const { is_packed, packed_at } = await DfInhouseF.apiCalls.get.bucketPackedStatus({
          bucket_id: bucket.id,
        });

        if (is_packed) {
          throw new Error(
            `이미 포장 완료된 버킷은 삭제할 수 없습니다.<br>포장 시각: ${format(
              new Date(packed_at),
              'yyyy년 MM월 dd일 HH시 mm분',
            )}`,
          );
        }

        // [validation] - 주문 박스가 담겨 있으면 경고 띄워줌.
        if (box_ctn > 0) {
          const { isDeleteConfirmed } = await DfInhouseF.alert.prompt.confirmBucketDeletion({ box_ctn });
          if (!isDeleteConfirmed) return;
        }

        const { deleted_bucket_id, deleted_box_ids } =
          await DfInhouseF.apiCalls.delete.inhouseShippingBucketByBucketId({
            bucket_id: bucket.id,
          });

        const deleted_bucket = DfInhouseF.stateBucket.gBox.get.inhouseShippingBucket({
          bucket_id: deleted_bucket_id,
        });

        const deleted_bucket_waybill_no = UtilStringS.insertHyphens({
          input_str: deleted_bucket.waybill_no,
          chunk_size: 4,
        });

        const deleted_bucket_location_name = deleted_bucket._.crew_inhouse_shipping.location_name;

        /* gBox 에서 제거 */
        DfInhouseF.stateBucket.gBox.delete.inhouseShippingBucket({ bucket_id: deleted_bucket_id });

        /* DOM 업데이트 */
        await DfInhouseF.stateDOM.delete.inhouse_shipping_bucket({ bucket_id: deleted_bucket_id });

        /* 제거 완료 알림 - 연결이 끊어진 주문 박스가 있으면 꼭 알려줘야함. */
        await DfInhouseF.alert.success({
          title: `버킷 제거 성공<br>[${deleted_bucket_location_name}]<br>${deleted_bucket_waybill_no}`,
          msg: `${
            deleted_box_ids.length > 0
              ? `다음 주문 박스들이 버킷에서 제거되었습니다.<br>
      ${join(', ', pluck('projection_id', boxes))}`
              : ''
          }<br><br>버킷은 즉시 폐기해 주세요.`,
        });
      } catch (err) {
        await DfInhouseF.alert.error({ err });
      } finally {
        DfInhouseF.lottie_loader.end();
      }
      break;
    }

    /* 운송장 재 출력 */
    case reprint_waybill_command: {
      try {
        const bucket_el = DfInhouseF.stateDOM.get.selectedBucket();
        if (bucket_el == null) {
          throw new Error(`운송장을 재출력할 선택된 버킷이 없습니다.`);
        }
        DfInhouseF.lottie_loader.start('printer');

        const bucket = DfInhouseF.stateDOM.get.selectedBucket(true);

        const {
          waybill: { printer_serial, format_data, dataFn },
        } = DfInhouseF.LABEL_CONFIG;

        await printWaybillCJ(
          [bucket.waybill_label_data], // 자신의 운송장만 단건 출력
          {
            is_print_prj_qr: false,
            additional_label_datas: {
              dataFn,
              formats: format_data,
            },
          },
          printer_serial,
        );
      } catch (err) {
        await DfInhouseF.alert.error({ err });
      } finally {
        DfInhouseF.lottie_loader.end();
      }

      break;
    }

    case complete_command: {
      try {
        const bucket_el = DfInhouseF.stateDOM.get.selectedBucket();
        if (bucket_el == null) {
          throw new Error(`포장 완료시킬 선택된 버킷이 없습니다.`);
        }
        DfInhouseF.lottie_loader.start('cardboard');
        const bucket_selected = await DfInhouseF.stateDOM.get.selectedBucket(true);

        // hydration
        await DfInhouseF.stateBucket.gBox.hydrate.bucket({ bucket_id: bucket_selected.id });

        // 버킷 완료 처리
        await DfInhouseF.setBucketComplete({ bucket: bucket_selected });
      } catch (err) {
        await DfInhouseF.alert.error({ err });
      } finally {
        DfInhouseF.lottie_loader.end();
      }
      break;
    }

    default:
      throw new Error(`Unhandled box entity control action ${action}`);
  }
}

// @description 버킷 선택 했을 때 이벤트 핸들러
export async function handleSelectBucketEntity({ currentTarget: ct }) {
  DfInhouseF.tableLoader.start();
  const is_selected_again = DfInhouseF.stateDOM.get.isSelectedAgain(ct);
  if (!is_selected_again) {
    // 다른 bucket 선택시 -> 선택 toggle
    DfInhouseF.stateDOM.update.toggleSelected(ct);
  }

  await DfInhouseF.boxTable.replaceRowsToSelectedBucket(true, is_selected_again);
  DfInhouseF.lottie_loader.end();
}

export function handleControlBucketMode({ currentTarget: ct }) {
  DfInhouseF.stateDOM.update.toggleBucketModeButton({ mode_name: ct.name });
}

export async function handleBucketReset({ currentTarget: ct }) {
  const action = ct.name;

  switch (action) {
    case DfInhouseConstantS.SEARCH_BUTTON_NAME.date_reset: {
      try {
        DfInhouseF.datePicker.init();
        const { start_date, end_date } = DfInhouseF.datePicker.getDates();
        await searchBucketByDates({ start_date, end_date });
      } catch (err) {
        console.error(err);
        await DfInhouseF.alert.error({ err });
      } finally {
        DfInhouseF.lottie_loader.end();
      }
      break;
    }
    default:
      throw new Error(`제공되지 않는 조회 기능입니다. ${action}`);
  }
}

export async function handleBucketSearch({ currentTarget: ct }) {
  const action = ct.name;

  switch (action) {
    // @description 기간으로 버킷 조회
    case DfInhouseConstantS.SEARCH_BUTTON_NAME.date_range: {
      try {
        const { start_date, end_date } = DfInhouseF.datePicker.getDates();
        await searchBucketByDates({ start_date, end_date });
      } catch (err) {
        console.error(err);
        await DfInhouseF.alert.error({ err });
      } finally {
        DfInhouseF.lottie_loader.end();
      }
      break;
    }

    // @description 주문 번호로 버킷 조회 - 조회 되면 해당 버킷의 패킹 날짜에 대한 모든 버킷 조회
    case DfInhouseConstantS.SEARCH_BUTTON_NAME.projection_search: {
      try {
        DfInhouseF.lottie_loader.start();

        const input_el = go(ct, $parent, $find('input'));

        if (input_el.value === '') return;

        const projection_id = Number(input_el.value);
        input_el.value = '';

        const projection_status = await DfInhouseF.apiCalls.get.projectionStatus({ projection_id });

        // [ Validation ] - 주문 번호 유효성 체크
        if (projection_status == null) {
          await DfInhouseF.alert.error({
            title: '주문 검색 오류',
            err: new Error('유효한 주문 번호가 아닙니다.<br>주문 번호를 확인해 주세요.'),
          });
          return;
        }

        // [ Validation ] - 주문 취소 여부 확인
        const { is_cancel_req, is_canceled } = projection_status;
        if (is_canceled || is_cancel_req) {
          await DfInhouseF.alert.error({
            title: '주문서 상태 확인',
            err: new Error(
              `취소된 주문 건 입니다.<br>${DfInhouseF.getProjectionLinkHtml({ projection_id })}`,
            ),
          });
          return;
        }

        // [ Validation ] - 사내 배송 여부 확인
        const { customer_order_crew_inhouse } =
          await DfInhouseF.apiCalls.get.customerOrderCrewInhouseFromPrjId({
            projection_id,
          });
        if (customer_order_crew_inhouse == null) {
          await DfInhouseF.alert.error({
            title: '주문 검색 오류',
            err: new Error('사내 배송 주문이 아닙니다.'),
          });
          return;
        }

        // [ Validation ] - 포장한 주문인지 (라벨링) 확인
        const {
          _: { inhouse_shipping_box },
        } = customer_order_crew_inhouse;
        if (UtilObjS.isEmNil(inhouse_shipping_box)) {
          await DfInhouseF.alert.error({
            title: '포장 전 상태 주문',
            err: new Error(
              `아직 주문 박스 포장 전 입니다. (사내배송 라벨링 전)<br>${DfInhouseF.getProjectionLinkHtml({
                projection_id,
              })}`,
            ),
          });
          return;
        }

        // [ Validation ] - 버킷에 들어간 주문인지 확인
        const {
          _: { inhouse_shipping_bucket },
        } = inhouse_shipping_box;
        if (UtilObjS.isEmNil(inhouse_shipping_bucket)) {
          await DfInhouseF.alert.error({
            title: '포장 전 상태 주문',
            err: new Error(`버킷에 담긴 주문 박스가 아닙니다.`),
          });
          return;
        }

        const { start_date, end_date } = DfInhouseF.datePicker.setDateRangeByBucketPackingAt({
          bucket: inhouse_shipping_bucket,
        });

        await searchBucketByDates({
          start_date,
          end_date,
          selected_bucket_id: inhouse_shipping_bucket.id,
          is_notify: false,
        });
        await DfInhouseF.boxTable.replaceRowsToSelectedBucket();
        DfInhouseF.boxTable.highlightRow({ row_id: inhouse_shipping_box.id, color: 'green' });
      } catch (err) {
        console.error(err);
        await DfInhouseF.alert.error({ err });
      } finally {
        DfInhouseF.lottie_loader.end();
      }
      break;
    }

    // @description 운송장 번호로 버킷 조회 - 조회 되면 해당 버킷의 패킹 날짜에 대한 모든 버킷 조회
    case DfInhouseConstantS.SEARCH_BUTTON_NAME.waybill_search: {
      try {
        const input_el = go(ct, $parent, $find('input'));
        const waybill_no = input_el.value.replace(/[^0-9.]/g, '');

        if (waybill_no === '') return;
        input_el.value = '';

        // 현재 gbox 에 waybill 이 있으면 그걸로 바로 toggle 시킴
        const bucket_already_has_waybill = DfInhouseF.stateBucket.gBox.get.inhouseShippingBucketByWaybillNo({
          waybill_no,
        });

        if (bucket_already_has_waybill != null) {
          DfInhouseF.stateBucket.triggerBucketSelection({ bucket_id: bucket_already_has_waybill.id });
          DfInhouseF.stateBucket.scrollTo({ bucket_id: bucket_already_has_waybill.id });
        } else {
          DfInhouseF.lottie_loader.start();
          // 없는 경우에는 api 호출해서 toggle
          const bucket_having_waybill_no = await DfInhouseF.apiCalls.get.inhouseShippingBucketFromWaybillNo({
            waybill_no,
          });

          if (bucket_having_waybill_no == null) {
            await DfInhouseF.alert.error({
              title: '버킷 찾을 수 없음',
              err: new Error(
                `해당 운송장 번호를 가진 버킷을 검색할 수 없습니다.<br>운송장 번호를 확인해 주세요.`,
              ),
            });
            return;
          }

          const { start_date, end_date } = DfInhouseF.datePicker.setDateRangeByBucketPackingAt({
            bucket: bucket_having_waybill_no,
          });
          await searchBucketByDates({
            start_date,
            end_date,
            selected_bucket_id: bucket_having_waybill_no.id,
            is_notify: false,
          });
          await DfInhouseF.boxTable.replaceRowsToSelectedBucket();
        }
      } catch (err) {
        console.error(err);
        await DfInhouseF.alert.error({ err });
      } finally {
        DfInhouseF.lottie_loader.end();
      }
      break;
    }
    default:
      throw new Error(`제공되지 않는 조회 기능입니다. ${action}`);
  }
}

export async function setBucketComplete({ bucket }) {
  const bucket_id = bucket.id;

  // [validation] 이미 포장 완료된 박스 여부 체크
  const { is_packed, packed_at } = await DfInhouseF.apiCalls.get.bucketPackedStatus({ bucket_id });

  if (is_packed) {
    throw new Error(
      `이미 포장 완료된 버킷입니다.<br>포장 시각: ${format(
        new Date(packed_at),
        'yyyy년 MM월 dd일 HH시 mm분',
      )}`,
    );
  }

  // [validation] cancel 주문 체크
  const canceled_row = DfInhouseF.boxTable.getRowByFilter((row) => {
    return row.getData().is_canceled;
  });

  if (canceled_row.length > 0) {
    DfInhouseF.boxTable.highlightRow({ row_id: canceled_row[0], color: 'red', timeout: 3000 });
    throw new Error(`취소된 주문이 있습니다.<br>버킷에서 제거 후 다시 시도해 주세요.`);
  }

  const { isCompleteConfirmed } = await DfInhouseF.alert.prompt.confirmBucketCompletion({
    bucket,
  });

  if (!isCompleteConfirmed) return;

  DfInhouseF.lottie_loader.start();
  const { bucket_id: packed_bucket_id } = await DfInhouseF.apiCalls.put.completeBucket({
    bucket_id,
  });

  // 완료 후 hydration
  await DfInhouseF.stateBucket.gBox.hydrate.bucket({ bucket_id: packed_bucket_id });

  // 완료 알람
  await DfInhouseF.alert.success({
    title: '포장 성공',
    msg: '택배 상차 장소로 버킷을 이동시키세요.',
  });
}

export async function handleLocation({ currentTarget: ct }) {
  const crew_inhouse_shipping = box.sel(ct);

  const { company_label, company_name, company_address, company_tel, company_code, company_postcode } =
    crew_inhouse_shipping;

  await DfInhouseF.alert.normal({
    title: '회사 정보',
    html: html`
      <div style="text-align:left;margin-left:2rem;">
        ${strMap(
          ([label, info]) =>
            html`
              <div>
                <label style="display:inline-block;width:5rem;font-weight:bold;font-size:1.2rem;"
                  >${label}</label
                >
                <span>${info}</span>
              </div>
            `,
          [
            ['프로젝트', company_label],
            ['회사명', company_name],
            ['회사코드', company_code],
            ['주소', company_address],
            ['연락처', company_tel],
            ['우편번호', company_postcode],
          ],
        )}
      </div>
    `,
  });
}

export async function handleTracking({ currentTarget: ct }) {
  const waybill_no = box.sel(ct);
  const bucket = go(ct, $closest('.bucket-entity'), box.sel);

  const {
    crew_inhouse_shipping: { location_name, company_tel },
  } = bucket._;

  try {
    const returning_data = await DfInhouseF.apiCalls.get.cjWaybillTracking({ waybill_no });

    let error = true;
    let tracking_data;
    let error_msg = '';

    switch (returning_data.RESULT_CD) {
      case 'S': {
        error = false;
        tracking_data = returning_data.DATA;
        break;
      }
      case 'E': {
        error_msg = parseTrackingReturnErrorMsg(returning_data.RESULT_DETAIL);
        break;
      }
    }

    await alertTrackingResult(
      error,
      error_msg,
      waybill_no,
      { name: location_name, waybill_number: waybill_no, mobile: company_tel },
      tracking_data,
    );
  } catch (err) {
    console.error(err);
  }
}

export async function searchBucketByDates({ start_date, end_date, selected_bucket_id, is_notify = true }) {
  /*
   * 1. 조회 API call
   * 2. buckets 들 데이터 보기
   * 3. gBox 변경하고
   * 4. DOM 처리
   * */

  const buckets = await DfInhouseF.apiCalls.get.inhouseShippingBucketsFromDateRange({
    start_date,
    end_date,
  });

  DfInhouseF.stateBucket.gBox.replace.buckets({ buckets });
  await DfInhouseF.stateDOM.update.bucketEntities({ buckets, selected_bucket_id });

  if (is_notify) {
    await DfInhouseF.alert.success({
      title: `조회 성공 ${buckets.length === 0 ? '...그러나' : ''}`,
      icon_override: buckets.length === 0 ? 'warning' : 'success',
      msg:
        buckets.length === 0
          ? '조회된 버킷이 하나도 없습니다.<br>다른 기간을 검색해 보시겠어요?'
          : `총 ${buckets.length} 개의 버킷이 조회되었습니다.`,
      timer: 3000,
    });
  }
}

/**
 * @define 버킷의 내용물을 엑셀로 다운로드 하는 기능
 * */
export async function handleBucketItemsPrint({ currentTarget: ct }) {
  const bucket_el = go(ct, $closest('.bucket-entity'));
  boxTable.download_excel({ bucket_el });
}
