import { each, every, go, reject, some } from 'fxjs/es';

const STICKER_EDITOR_DEFAULT_BP_OPTION_IDS = [349, 129, 131];
const STICKER_EDITOR_KRAFT_PREREQUSITE_BP_OPTION_IDS = [85, 109];
const STICKER_EDITOR_NORMAL_PREREQUSITE_BP_OPTION_IDS = [83, 84, 108];
const STICKER_EDITOR_FREE_BP_OPTION_ID = 111;
const STICKER_EDITOR_GRID_BP_OPTION_ID = 110;
export const halfStickerChecker = ({ selected_option_group, svg_product_materials, svg_product_id }) => {
  if (svg_product_id) {
    console.log(!!svg_product_id);
  } else if (svg_product_materials) {
    go(
      svg_product_materials,
      each(
        ({ original_cutting_line_svg_file_url, original_svg_file_url, printable_file_url, process_time }) => {
          console.log(
            !!process_time,
            !!original_cutting_line_svg_file_url.indexOf('s3.marpple.co') > -1,
            !!original_svg_file_url.indexOf('s3.marpple.co') > -1,
            !!printable_file_url.indexOf('s3.marpple.co') > -1,
          );
        },
      ),
    );
  } else {
    console.log(false);
  }
  const { bp_option_ids } = selected_option_group;
  if (bp_option_ids.includes(83)) console.log('아트지');
  else if (bp_option_ids.includes(84)) console.log('모조지');
  else if (bp_option_ids.includes(85)) console.log('크라프트');
  else console.error('용지가 선택되지 않았음.');

  if (bp_option_ids.includes(108)) console.log('일반용지');
  else if (bp_option_ids.includes(109)) console.log('크라프트용지');
  else console.error('일반인지, 크라프트인지 선택되지 않았음.');

  if (bp_option_ids.includes(STICKER_EDITOR_GRID_BP_OPTION_ID)) console.log('규격');
  else if (bp_option_ids.includes(STICKER_EDITOR_FREE_BP_OPTION_ID)) console.log('자유');
  else console.error('규격인지 자유인지 선택되지 않았음.');

  if (
    every((id) => some((bp_option_id) => bp_option_id == id)(bp_option_ids))(
      STICKER_EDITOR_DEFAULT_BP_OPTION_IDS,
    )
  ) {
    console.log('공통 사항 선택됌.');
  } else {
    console.error('공통 사항 선택 안됌.');
  }
  go(
    bp_option_ids,
    reject((id) => some((bp_option_id) => bp_option_id == id)(STICKER_EDITOR_DEFAULT_BP_OPTION_IDS)),
    reject((id) =>
      some((bp_option_id) => bp_option_id == id)(STICKER_EDITOR_KRAFT_PREREQUSITE_BP_OPTION_IDS),
    ),
    reject((id) =>
      some((bp_option_id) => bp_option_id == id)(STICKER_EDITOR_NORMAL_PREREQUSITE_BP_OPTION_IDS),
    ),
    reject((id) => id == STICKER_EDITOR_FREE_BP_OPTION_ID),
    reject((id) => id == STICKER_EDITOR_GRID_BP_OPTION_ID),
    (ids) => (ids.length == 2 ? console.log('개별 옵션 통과') : console.error('개별 옵션 실패')),
  );
  return bp_option_ids.length === 8;
};
