import { delay, each, go, identity, mapObject, pipe } from 'fxjs/es';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { getCurrentFcanvas } from '../../Fcanvas/cv_object.js';
import { $closest, $find, $findAll, $setVal, $trigger, $val } from 'fxdom/es';
import { changeCvBpcfWithUrlAncCutCvPrintArea, endAdjust, getKey, startAdjust } from './fs.js';
import { evaluateImageBrightness, makeCanvasByUrl, rgbToHex } from '../../../../Canvas/S/util.js';
import { control } from './event.js';

export const makeShadeEditorHtml = (shade) => legacyHtml`
    <label for="">음영</label>
    <div class="wrapper main-wrapper" adjust>
      <div class="wrapper row">
        <div class="item hex_input row" key="hex">
          <label for="" class=>재질의 색상</label>
          <input type="color" value="${shade.hex}" />
        </div>
        <div class="item row is_black_and_white_contrast" key="is_black_and_white_contrast">
          <label for="" class=>흑백 Contrast</label>
          <input type="checkbox" ${shade.is_black_and_white_contrast ? 'checked' : ''}/>
        </div>
        <div class="item hex_input row" key="lighten_hex" style="display: none">
          <label for="">빛 색상</label>
          <input type="color" value="${shade.lighten_hex}" />
        </div>
        <div class="item hex_input row" key="darken_hex" style="display: none">
          <label for="">그림자 색상</label>
          <input type="color" value="${shade.darken_hex}" />
        </div>
        <div class="item adjust row file_upload" adjust-name="texture_url" key="texture_url">
          <label for="">텍스쳐 레이어</label>
          <input type="file" />
          <div class="wrapper create row">
            <button class="start">시작</button>
            <button class="end">끝</button>
          </div>
        </div>
        <div class="item adjust row original_texture_url" adjust-name="original_texture_url" key="original_texture_url">
          <label for="">텍스쳐 오리지날</label>
          <input type="text" value="${shade.original_texture_url}" />
        </div>
        <div class="item" key="deep_step">
          <label for="">음영 깊이</label>
          <input type="number" step="1" value="${shade.deep_step}" />
        </div>
        <div class="item row" key="light_location">
          <label for="">빛의 위치</label>
          <div class="wrapper">
            ${makeLightLocationHtml(shade.light_location)}
          </div>
        </div>
      </div>
    </div>
  `;

export const makeShade2EditorHtml = (shade2) => legacyHtml`
    <label for="">음영</label>
    <div class="wrapper main-wrapper" adjust>
      <div class="wrapper row">
        <div class="item" key="deep_step">
          <label for="">음영 깊이</label>
          <input type="number" step="0.01" max="1" min="-1" value="${shade2.deep_step}" />
        </div>
      </div>
    </div>
  `;

function makeLightLocationHtml(location) {
  function selectIf(top, left) {
    return location.top === top && location.left === left ? 'selected' : '';
  }
  return legacyHtml`
    <select name="" id="">
      <option value="" data-top="-1" data-left="0" ${selectIf(-1, 0)}>위</option>
      <option value="" data-top="-1" data-left="-1" ${selectIf(-1, -1)}>위 왼쪽</option>
      <option value="" data-top="-1" data-left="1" ${selectIf(-1, 1)}>위 오른쪽</option>
      <option value="" data-top="1" data-left="0" ${selectIf(1, 0)}>아래</option>
      <option value="" data-top="1" data-left="-1" ${selectIf(1, -1)}>아래 왼쪽</option>
      <option value="" data-top="1" data-left="1" ${selectIf(1, 1)}>아래 오른쪽</option>
    </select>
  `;
}

export const makeShadeEvent = pipe(
  $.on3('click', '.cv_preview_editor .shade_editor .adjust[adjust-name="texture_url"] .start', async (e) => {
    if (!startAdjust(e)) return;
    $.don_loader_start();
    const fcanvas = getCurrentFcanvas();
    fcanvas.is_preview_pass = true;
    $.don_loader_end();
  }),
  $.on3('click', '.cv_preview_editor .shade_editor .adjust[adjust-name="texture_url"] .end', async (e) => {
    go(
      e.currentTarget,
      $closest('.adjust'),
      $findAll('input[type="file"]'),
      each((el) => $trigger('change', el)),
    );
    const fcanvas = getCurrentFcanvas();
    fcanvas.is_preview_pass = false;
  }),
  $.on3('change', '.cv_preview_editor .shade_editor .adjust[adjust-name="texture_url"] input', async (e) => {
    $.don_loader_start();
    const fcanvas = getCurrentFcanvas();
    if (!G.mp.maker.active()) {
      G.mp.maker.multiple_select(G.mp.maker.designs());
      await delay(1000, identity);
    }
    fcanvas.preview.shade.texture_url = await changeCvBpcfWithUrlAncCutCvPrintArea(null, fcanvas);
    if (confirm('텍스쳐 오리지날도 적용 하시겠습니까(합성을 위함)?'))
      fcanvas.preview.shade.original_texture_url = fcanvas.preview.shade.texture_url;
    fcanvas.preview.shade.hex = await getAvgTextureUrl(fcanvas);
    go(
      e.currentTarget,
      $closest('.shade_editor'),
      $find('.hex_input input'),
      $setVal(fcanvas.preview.shade.hex),
    );
    await G.mp.maker.state.reset();
    $.don_loader_end();
    endAdjust(e);
    go(e.currentTarget, $closest('.shade_editor'), (shade_editor_el) => {
      shade_editor_el.innerHTML = makeShadeEditorHtml(fcanvas.preview.shade);
    });
  }),
  $.on3('change', '.cv_preview_editor .shade_editor .item[key="light_location"] select', async (e) => {
    const fcanvas = getCurrentFcanvas();
    fcanvas.preview.shade[getKey(e)] = go(
      e.currentTarget.options,
      (options) => options[options.selectedIndex],
      (option) => option.dataset,
      mapObject(parseInt),
    );
    await control(fcanvas, (cv_obj) => {});
  }),
  $.on3('change', '.cv_preview_editor .shade_editor .item[key="deep_step"] input', async (e) => {
    const fcanvas = getCurrentFcanvas();
    fcanvas.preview.shade[getKey(e)] = parseInt($val(e.currentTarget));
    await control(fcanvas, (cv_obj) => {});
  }),
  $.on3('change', '.cv_preview_editor .shade_editor .hex_input input', async (e) => {
    const fcanvas = getCurrentFcanvas();
    fcanvas.preview.shade[getKey(e)] = $val(e.currentTarget);
    await control(fcanvas, async (cv_obj) => {});
  }),
  $.on3('change', '.cv_preview_editor .shade_editor .original_texture_url input', async (e) => {
    const fcanvas = getCurrentFcanvas();
    fcanvas.preview.shade[getKey(e)] = $val(e.currentTarget);
    await control(fcanvas, async (cv_obj) => {});
  }),
  $.on3('change', '.is_black_and_white_contrast input', async (e) => {
    const fcanvas = getCurrentFcanvas();
    fcanvas.preview.shade.is_black_and_white_contrast = e.currentTarget.checked;
    await control(fcanvas, async (cv_obj) => {});
  }),
);

function getAvgTextureUrl(fcanvas) {
  return go(
    fcanvas.preview.shade.texture_url,
    makeCanvasByUrl,
    evaluateImageBrightness,
    (avg) => parseInt(avg * 255),
    (avg) => {
      return rgbToHex(avg, avg, avg);
    },
  );
}
