import { difference, filter, flat, go, map, pipe, sel, tap } from 'fxjs/es';
import { AdScriptCriteoMpF } from '../../../AdScript/Criteo/Mp/F/Function/module/AdScriptCriteoMpF.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { updateProductListView } from './fs.js';

const make_fis1 = () =>
  filter(
    sel('selected'),
    flat(_p.deep_pluck(window.box.sel('product_list->bp_info->filter_lists'), '_.filter_items')),
  );

$.frame.defn_frame({
  frame_name: 'mp.product.list',
  hide_frame_button_type: 'X',
  appended: (el_frame) => {
    if (T.lang == 'kr' && window.criteo_q) {
      try {
        AdScriptCriteoMpF.viewList(
          map((bp) => {
            return bp.id;
          }, box().product_list.bp_info.bps.slice(0, 3)),
        );
      } catch (e) {}
    }

    return el_frame;
  },
});
$.frame.defn_frame({
  frame_name: 'mp.product.list.filter',
  always_remove: true,
  animation: true,
  hide_frame_button_type: 'X',
  is_modal: G.is_pc_size(),
  title: T('Select Filters'),
  closing: function (el_frame) {
    const bfis = el_frame.frame_opt.before_selected_filter_items;
    const fis1 = make_fis1();
    if (difference(bfis, fis1).length || difference(fis1, bfis).length)
      return go(void 0, updateProductListView());
  },
  appended: pipe(
    $.on2('click', '#list_changer', $.frame.close),
    tap($.find1('>.don_wrapper'), (don_wrapper) =>
      $.append(
        don_wrapper,
        legacyHtml`
          <div class="footer">
            <div class="buttons">
              <button id="list_changer" type="button">${T('Show products')}</button>
            </div>
          </div>
        `,
      ),
    ),
    (el_frame) => (el_frame.frame_opt.before_selected_filter_items = make_fis1()),
  ),
});
