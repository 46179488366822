import {
  each,
  eachC,
  filterL,
  go,
  isNil,
  join,
  mapL,
  noop,
  reduce,
  rejectL,
  takeAll,
  takeAllC,
} from 'fxjs/es';
import { VectorEditorKeyringGridMobileF } from '../../Grid/Mobile/F/Function/module/VectorEditorKeyringGridMobileF.js';
import { beforeLoginCheck } from '../../../../User/Login/F/fs.js';
import { SVGEditorUtilF } from '../../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { VectorEditorFontF } from '../../../Font/F/Function/module/VectorEditorFontF.js';
import { CommonNS } from '@marpple/sticker-editor';
import axios from 'axios';
import { SVGEditorFontConstS } from '../../../../SVGEditor/Font/S/Const/module/SVGEditorFontConstS.js';
import { VectorEditorKeyringFreeMobileF } from '../../Free/Mobile/F/Function/module/VectorEditorKeyringFreeMobileF.js';
import { VectorEditorKeyringGridPCF } from '../../Grid/PC/F/Function/module/VectorEditorKeyringGridPCF.js';
import { VectorEditorKeyringFreePCF } from '../../Free/PC/F/Function/module/VectorEditorKeyringFreePCF.js';
import { VectorEditorKeyringGridCreatorPCF } from '../../Grid/CreatorPC/F/Function/module/VectorEditorKeyringGridCreatorPCF.js';
import { VectorEditorKeyringFreeCreatorPCF } from '../../Free/CreatorPC/F/Function/module/VectorEditorKeyringFreeCreatorPCF.js';

const PNG_DPI = 300;
const SVG_DPI = 72;
const INCH_PER_MM = 5 / 127;
const CUTTING_LINE_SVG_LAYER_ID = '4-Laser';

export const makeKeyringMobile = async ({ svg_file, options, postProcess, thumbnail_size_px }) => {
  const svg_el = await parseSVGFile(await svg_file);
  const meta = makeMetaFromOptions(options);
  const { editor_type } = meta;

  if (editor_type === 'grid') {
    const {
      grid_meta_width,
      grid_meta_height,
      grid_meta_inner_cutting_shape_path_data,
      grid_meta_outer_cutting_shape_path_data,
      empty_template_el,
    } = selectGridKeyringDataFromMeta(meta);
    return VectorEditorKeyringGridMobileF.makeKeyring({
      art_board: {
        width: grid_meta_width,
        height: grid_meta_height,
        shape_path_data: grid_meta_outer_cutting_shape_path_data,
      },
      clip_path_shape_path_data: grid_meta_inner_cutting_shape_path_data,
      empty_template_el,
      preProcess: makePreProcess({ svg_el }),
      postProcess: makePostProcess({
        postProcess,
        printable_scale_factor: INCH_PER_MM * PNG_DPI,
        cutting_line_scale_factor: INCH_PER_MM * SVG_DPI,
        cutting_line_svg_layer_id: CUTTING_LINE_SVG_LAYER_ID,
        thumbnail_size_px,
        selectMakeTargetImageEl: (payload) => payload?.makeTargetImageEl ?? null,
        selectCuttingLineSVGEl: (payload) => payload?.cutting_line_svg_el ?? null,
        selectViewBox: (payload) => payload?.view_box ?? null,
        selectHookPositionRatio: (payload) => payload?.hook_position_ratio ?? null,
        selectFittedSize: () => ({ width: grid_meta_width, height: grid_meta_height }),
      }),
    });
  }

  if (editor_type === 'free') {
    const { free_meta_width, free_meta_height, free_meta_bounding_shape_path_data, empty_template_el } =
      selectFreeKeyringDataFromMeta(meta);
    return VectorEditorKeyringFreeMobileF.makeKeyring({
      art_board: {
        width: free_meta_width,
        height: free_meta_height,
        shape_path_data: free_meta_bounding_shape_path_data,
      },
      empty_template_el,
      preProcess: makePreProcess({ svg_el }),
      postProcess: makePostProcess({
        postProcess,
        printable_scale_factor: INCH_PER_MM * PNG_DPI,
        cutting_line_scale_factor: INCH_PER_MM * SVG_DPI,
        cutting_line_svg_layer_id: CUTTING_LINE_SVG_LAYER_ID,
        thumbnail_size_px,
        selectMakeTargetImageEl: (payload) => payload?.makeTargetImageEl ?? null,
        selectCuttingLineSVGEl: (payload) => payload?.cutting_line_svg_el ?? null,
        selectViewBox: (payload) => payload?.outer_view_box ?? null,
        selectHookPositionRatio: (payload) => payload?.hook_position_ratio ?? null,
        selectFittedSize: (payload) => {
          const width = payload?.inner_view_box?.width;
          const height = payload?.inner_view_box?.height;
          if (width == null || height == null) {
            return null;
          }
          return { width, height };
        },
      }),
    });
  }

  const error = new Error(`editor_type 옵션이 grid 또는 free 가 아닙니다. : ${editor_type}`);
  error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_editor_type');
  throw error;
};

export const makeKeyringPC = async ({
  title,
  price,
  frame_position: { top: frame_position_top, height: frame_position_height },
  prev_right_panel_el,
  onFrameHiding,
  svg_file,
  options,
  postProcess,
  thumbnail_size_px,
}) => {
  const svg_el = await parseSVGFile(await svg_file);
  const meta = makeMetaFromOptions(options);
  const { editor_type } = meta;

  if (editor_type === 'grid') {
    const {
      grid_meta_width,
      grid_meta_height,
      grid_meta_inner_cutting_shape_path_data,
      grid_meta_outer_cutting_shape_path_data,
      empty_template_el,
    } = selectGridKeyringDataFromMeta(meta);
    return VectorEditorKeyringGridPCF.makeKeyring({
      title,
      price,
      frame_position: { top: frame_position_top, height: frame_position_height },
      prev_right_panel_el,
      onFrameHiding,
      art_board: {
        width: grid_meta_width,
        height: grid_meta_height,
        shape_path_data: grid_meta_outer_cutting_shape_path_data,
      },
      clip_path_shape_path_data: grid_meta_inner_cutting_shape_path_data,
      empty_template_el,
      preProcess: makePreProcess({ svg_el }),
      postProcess: makePostProcess({
        postProcess,
        printable_scale_factor: INCH_PER_MM * PNG_DPI,
        cutting_line_scale_factor: INCH_PER_MM * SVG_DPI,
        cutting_line_svg_layer_id: CUTTING_LINE_SVG_LAYER_ID,
        thumbnail_size_px,
        selectMakeTargetImageEl: (payload) => payload?.makeTargetImageEl ?? null,
        selectCuttingLineSVGEl: (payload) => payload?.cutting_line_svg_el ?? null,
        selectViewBox: (payload) => payload?.view_box ?? null,
        selectHookPositionRatio: (payload) => payload?.hook_position_ratio ?? null,
        selectFittedSize: () => ({ width: grid_meta_width, height: grid_meta_height }),
      }),
    });
  }

  if (editor_type === 'free') {
    const { free_meta_width, free_meta_height, free_meta_bounding_shape_path_data, empty_template_el } =
      selectFreeKeyringDataFromMeta(meta);
    return VectorEditorKeyringFreePCF.makeKeyring({
      title,
      price,
      frame_position: { top: frame_position_top, height: frame_position_height },
      prev_right_panel_el,
      onFrameHiding,
      art_board: {
        width: free_meta_width,
        height: free_meta_height,
        shape_path_data: free_meta_bounding_shape_path_data,
      },
      empty_template_el,
      preProcess: makePreProcess({ svg_el }),
      postProcess: makePostProcess({
        postProcess,
        printable_scale_factor: INCH_PER_MM * PNG_DPI,
        cutting_line_scale_factor: INCH_PER_MM * SVG_DPI,
        cutting_line_svg_layer_id: CUTTING_LINE_SVG_LAYER_ID,
        thumbnail_size_px,
        selectMakeTargetImageEl: (payload) => payload?.makeTargetImageEl ?? null,
        selectCuttingLineSVGEl: (payload) => payload?.cutting_line_svg_el ?? null,
        selectViewBox: (payload) => payload?.outer_view_box ?? null,
        selectHookPositionRatio: (payload) => payload?.hook_position_ratio ?? null,
        selectFittedSize: (payload) => {
          const width = payload?.inner_view_box?.width;
          const height = payload?.inner_view_box?.height;
          if (width == null || height == null) {
            return null;
          }
          return { width, height };
        },
      }),
    });
  }

  const error = new Error(`editor_type 옵션이 grid 또는 free 가 아닙니다. : ${editor_type}`);
  error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_editor_type');
  throw error;
};

export const makeKeyringCreatorPC = async ({
  title,
  price,
  svg_file,
  options,
  postProcess,
  thumbnail_size_px,
}) => {
  const svg_el = await parseSVGFile(await svg_file);
  const meta = makeMetaFromOptions(options);
  const { editor_type } = meta;

  if (editor_type === 'grid') {
    const {
      grid_meta_width,
      grid_meta_height,
      grid_meta_inner_cutting_shape_path_data,
      grid_meta_outer_cutting_shape_path_data,
      empty_template_el,
    } = selectGridKeyringDataFromMeta(meta);
    return VectorEditorKeyringGridCreatorPCF.makeKeyring({
      title,
      price,
      art_board: {
        width: grid_meta_width,
        height: grid_meta_height,
        shape_path_data: grid_meta_outer_cutting_shape_path_data,
      },
      clip_path_shape_path_data: grid_meta_inner_cutting_shape_path_data,
      empty_template_el,
      preProcess: makePreProcess({ svg_el }),
      postProcess: makePostProcess({
        postProcess,
        printable_scale_factor: INCH_PER_MM * PNG_DPI,
        cutting_line_scale_factor: INCH_PER_MM * SVG_DPI,
        cutting_line_svg_layer_id: CUTTING_LINE_SVG_LAYER_ID,
        thumbnail_size_px,
        selectMakeTargetImageEl: (payload) => payload?.makeTargetImageEl ?? null,
        selectCuttingLineSVGEl: (payload) => payload?.cutting_line_svg_el ?? null,
        selectViewBox: (payload) => payload?.view_box ?? null,
        selectHookPositionRatio: (payload) => payload?.hook_position_ratio ?? null,
        selectFittedSize: () => ({ width: grid_meta_width, height: grid_meta_height }),
      }),
    });
  }

  if (editor_type === 'free') {
    const { free_meta_width, free_meta_height, free_meta_bounding_shape_path_data, empty_template_el } =
      selectFreeKeyringDataFromMeta(meta);
    return VectorEditorKeyringFreeCreatorPCF.makeKeyring({
      title,
      price,
      art_board: {
        width: free_meta_width,
        height: free_meta_height,
        shape_path_data: free_meta_bounding_shape_path_data,
      },
      empty_template_el,
      preProcess: makePreProcess({ svg_el }),
      postProcess: makePostProcess({
        postProcess,
        printable_scale_factor: INCH_PER_MM * PNG_DPI,
        cutting_line_scale_factor: INCH_PER_MM * SVG_DPI,
        cutting_line_svg_layer_id: CUTTING_LINE_SVG_LAYER_ID,
        thumbnail_size_px,
        selectMakeTargetImageEl: (payload) => payload?.makeTargetImageEl ?? null,
        selectCuttingLineSVGEl: (payload) => payload?.cutting_line_svg_el ?? null,
        selectViewBox: (payload) => payload?.outer_view_box ?? null,
        selectHookPositionRatio: (payload) => payload?.hook_position_ratio ?? null,
        selectFittedSize: (payload) => {
          const width = payload?.inner_view_box?.width;
          const height = payload?.inner_view_box?.height;
          if (width == null || height == null) {
            return null;
          }
          return { width, height };
        },
      }),
    });
  }

  const error = new Error(`editor_type 옵션이 grid 또는 free 가 아닙니다. : ${editor_type}`);
  error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_editor_type');
  throw error;
};

function makePreProcess({ svg_el }) {
  return async function preProcess({ addEl }) {
    if (svg_el == null) {
      return;
    }

    const working_layer_el = svg_el.querySelector(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="working-layer"]`);
    if (working_layer_el == null) {
      const error = new Error(
        `이전에 작업한 SVG 파일에서 working-layer 역할을 가진 엘리먼트를 찾을 수 없습니다.`,
      );
      error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_prev_keyring_svg');
      throw error;
    }

    const image_els = working_layer_el.querySelectorAll(`image[data-mpse__original_url]`);
    if (image_els.length > 0) {
      await eachC(async (image_el) => {
        const data_url = await makeImageDataURL(image_el.dataset.mpse__original_url);
        image_el.setAttributeNS(null, 'href', data_url);
      })(image_els);
    }

    await loadUsedFonts(working_layer_el);

    go(
      working_layer_el.children,
      takeAll,
      each((el) => addEl({ el })),
    );
  };
}

function makePostProcess({
  postProcess: outerPostProcess,
  printable_scale_factor,
  cutting_line_scale_factor,
  cutting_line_svg_layer_id,
  thumbnail_size_px,
  selectMakeTargetImageEl,
  selectCuttingLineSVGEl,
  selectViewBox,
  selectHookPositionRatio,
  selectFittedSize,
}) {
  return async function postProcess(payload) {
    if (!box.sel('shared_product')) {
      const is_login = await beforeLoginCheck(true);
      if (!is_login) {
        return { success: false, value: T('modules::VectorEditor::Keyring::message::login_first') };
      }
    }

    const loaderDone = SVGEditorUtilF.percentLoader({
      message: T('modules::VectorEditor::Keyring::message::make_images'),
      time: 10 * 1000,
      clazz: 'mobile',
    });
    try {
      const { makeTargetImageEl, cutting_line_svg_el, view_box, hook_position_ratio, fitted_size } = (() => {
        const makeTargetImageEl = selectMakeTargetImageEl(payload);
        if (makeTargetImageEl == null) {
          throw new Error(`Cannot select "makeTargetImageEl" from payload.`);
        }

        const cutting_line_svg_el = selectCuttingLineSVGEl(payload);
        if (cutting_line_svg_el == null) {
          throw new Error(`Cannot select "cutting_line_svg_el" from payload.`);
        }

        const view_box = selectViewBox(payload);
        if (view_box == null) {
          throw new Error(`Cannot select "view_box" from payload.`);
        }

        const hook_position_ratio = selectHookPositionRatio(payload);
        if (hook_position_ratio == null) {
          throw new Error(`Cannot select "hook_position_ratio" from payload.`);
        }

        const fitted_size = selectFittedSize(payload);
        if (fitted_size == null) {
          throw new Error(`Cannot select "fitted_size" from payload.`);
        }

        return {
          makeTargetImageEl,
          cutting_line_svg_el,
          view_box,
          hook_position_ratio,
          fitted_size,
        };
      })();

      const thumbnail_scale_factor = (() => {
        const view_box_size = Math.max(view_box.width, view_box.height);
        return thumbnail_size_px / view_box_size;
      })();

      const original_svg_el = await makeTargetImageEl({ factor: 1 });
      const printable_svg_el = await makeTargetImageEl({ factor: printable_scale_factor });
      const thumbnail_svg_el = await makeTargetImageEl({ factor: thumbnail_scale_factor });

      cutting_line_svg_el.setAttributeNS(null, 'id', cutting_line_svg_layer_id);
      const cutting_line_svg_width = Math.ceil(view_box.width * cutting_line_scale_factor * 1000) / 1000;
      cutting_line_svg_el.setAttributeNS(null, 'width', `${cutting_line_svg_width}`);
      const cutting_line_svg_height = Math.ceil(view_box.height * cutting_line_scale_factor * 1000) / 1000;
      cutting_line_svg_el.setAttributeNS(null, 'height', `${cutting_line_svg_height}`);

      const xml_serializer = new XMLSerializer();
      const original_svg_file = await (async (original_svg_el) => {
        each((image_el) => {
          const original_url = image_el.dataset.mpse__original_url;
          image_el.setAttributeNS(null, 'href', original_url);
        })(original_svg_el.querySelectorAll('image[data-mpse__original_url]'));

        const font_style_el = await VectorEditorFontF.makeFontStyleEl({ is_encode_base64: false })(
          original_svg_el,
        );
        const font_style_defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
        font_style_defs_el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE] = 'font-face-defs';
        font_style_defs_el.appendChild(font_style_el);
        original_svg_el.prepend(font_style_defs_el);

        return new File([xml_serializer.serializeToString(original_svg_el)], 'keyring_original.svg', {
          type: 'image/svg+xml',
        });
      })(original_svg_el);
      const cutting_line_svg_file = new File(
        [xml_serializer.serializeToString(cutting_line_svg_el)],
        'keyring_cutting_line.svg',
        { type: 'image/svg+xml' },
      );
      const makePrintablePNGFile = async () => {
        const font_style_el = await VectorEditorFontF.makeFontStyleEl({ is_encode_base64: true })(
          original_svg_el,
        );
        const font_style_defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
        font_style_defs_el.appendChild(font_style_el);
        printable_svg_el.prepend(font_style_defs_el);

        const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
          const file_reader = new FileReader();
          file_reader.addEventListener('error', (event) => {
            console.error(event);
            const error = new Error(`printable_svg_el 을 data_url 로 변환하는 데 실패했습니다.`);
            error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::too_large_image');
            reject(error);
          });
          file_reader.addEventListener('load', () => resolve(file_reader.result));
          file_reader.readAsDataURL(
            new Blob([xml_serializer.serializeToString(printable_svg_el)], { type: 'image/svg+xml' }),
          );
        });
        const img_el = await new Promise((resolve, reject) => {
          const img_el = new Image();
          img_el.addEventListener(
            'error',
            (event) => {
              console.error(event);
              const error = new Error(`printable_svg_el 의 data_url 을 img_el 에 그리는 데 실패했습니다.`);
              error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::too_large_image');
              reject(error);
            },
            { once: true },
          );
          img_el.addEventListener('load', () => resolve(img_el), { once: true });
          img_el.src = data_url;
        });
        await new Promise((resolve) => setTimeout(resolve, 3000));

        const canvas_el = document.createElement('canvas');
        canvas_el.width = img_el.width;
        canvas_el.height = img_el.height;
        const canvas_ctx = canvas_el.getContext('2d');
        canvas_ctx.drawImage(
          img_el,
          0,
          0,
          img_el.width,
          img_el.height,
          0,
          0,
          canvas_el.width,
          canvas_el.height,
        );

        const scaled_view_box_x = Math.floor(view_box.x * printable_scale_factor);
        const scaled_view_box_y = Math.floor(view_box.y * printable_scale_factor);
        const scaled_view_box_width = Math.ceil(view_box.width * printable_scale_factor);
        const scaled_view_box_height = Math.ceil(view_box.height * printable_scale_factor);

        const src_x = Math.max(0, scaled_view_box_x);
        const src_y = Math.max(0, scaled_view_box_y);
        let src_width = scaled_view_box_width + scaled_view_box_x - src_x;
        src_width = Math.min(src_width, canvas_el.width - src_x);
        let src_height = scaled_view_box_height + scaled_view_box_y - src_y;
        src_height = Math.min(src_height, canvas_el.height - src_y);

        const dest_x = src_x - scaled_view_box_x;
        const dest_y = src_y - scaled_view_box_y;
        const dest_width = Math.min(src_width, scaled_view_box_width - dest_x);
        const dest_height = Math.min(src_height, scaled_view_box_height - dest_y);

        const png_canvas_el = document.createElement('canvas');
        png_canvas_el.width = scaled_view_box_width;
        png_canvas_el.height = scaled_view_box_height;
        const png_canvas_ctx = png_canvas_el.getContext('2d');
        png_canvas_ctx.drawImage(
          canvas_el,
          src_x,
          src_y,
          src_width,
          src_height,
          dest_x,
          dest_y,
          dest_width,
          dest_height,
        );

        const blob = /** @type {Blob} */ await new Promise((resolve, reject) =>
          png_canvas_el.toBlob((blob) => {
            if (blob == null) {
              const error = new Error(`png_canvas_el 에서 blob 객체를 만드는 데 실패했습니다.`);
              error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::too_large_image');
              reject(error);
              return;
            }
            resolve(blob);
          }, 'image/png'),
        );
        return new File([blob], 'printable.png', { type: 'image/png' });
      };
      const makeThumbnailPNGFile = async () => {
        const font_style_el = await VectorEditorFontF.makeFontStyleEl({ is_encode_base64: true })(
          original_svg_el,
        );
        const font_style_defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
        font_style_defs_el.appendChild(font_style_el);
        thumbnail_svg_el.prepend(font_style_defs_el);

        const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
          const file_reader = new FileReader();
          file_reader.addEventListener('error', (event) => {
            console.error(event);
            const error = new Error(`thumbnail_svg_el 을 data_url 로 만드는 데 실패했습니다.`);
            error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::too_large_image');
            reject(error);
          });
          file_reader.addEventListener('load', () => resolve(file_reader.result));
          file_reader.readAsDataURL(
            new Blob([xml_serializer.serializeToString(thumbnail_svg_el)], { type: 'image/svg+xml' }),
          );
        });
        const img_el = await new Promise((resolve, reject) => {
          const img_el = new Image();
          img_el.addEventListener(
            'error',
            (event) => {
              console.error(event);
              const error = new Error(`thumbnail_svg_el 의 data_url 을 img_el 에 그리는 데 실패했습니다.`);
              error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::too_large_image');
              reject(error);
            },
            { once: true },
          );
          img_el.addEventListener('load', () => resolve(img_el), { once: true });
          img_el.src = data_url;
        });
        await new Promise((resolve) => setTimeout(resolve, 3000));

        const canvas_el = document.createElement('canvas');
        canvas_el.width = img_el.width;
        canvas_el.height = img_el.height;
        const canvas_ctx = canvas_el.getContext('2d');
        canvas_ctx.drawImage(
          img_el,
          0,
          0,
          img_el.width,
          img_el.height,
          0,
          0,
          canvas_el.width,
          canvas_el.height,
        );

        const scaled_view_box_x = Math.floor(view_box.x * thumbnail_scale_factor);
        const scaled_view_box_y = Math.floor(view_box.y * thumbnail_scale_factor);
        const scaled_view_box_width = Math.ceil(view_box.width * thumbnail_scale_factor);
        const scaled_view_box_height = Math.ceil(view_box.height * thumbnail_scale_factor);

        const src_x = Math.max(0, scaled_view_box_x);
        const src_y = Math.max(0, scaled_view_box_y);
        let src_width = scaled_view_box_width + scaled_view_box_x - src_x;
        src_width = Math.min(src_width, canvas_el.width - src_x);
        let src_height = scaled_view_box_height + scaled_view_box_y - src_y;
        src_height = Math.min(src_height, canvas_el.height - src_y);

        const dest_x = src_x - scaled_view_box_x;
        const dest_y = src_y - scaled_view_box_y;
        const dest_width = Math.min(src_width, scaled_view_box_width - dest_x);
        const dest_height = Math.min(src_height, scaled_view_box_height - dest_y);

        const png_canvas_el = document.createElement('canvas');
        png_canvas_el.width = scaled_view_box_width;
        png_canvas_el.height = scaled_view_box_height;
        const png_canvas_ctx = png_canvas_el.getContext('2d');
        png_canvas_ctx.drawImage(
          canvas_el,
          src_x,
          src_y,
          src_width,
          src_height,
          dest_x,
          dest_y,
          dest_width,
          dest_height,
        );

        const blob = /** @type {Blob} */ await new Promise((resolve, reject) =>
          png_canvas_el.toBlob((blob) => {
            if (blob == null) {
              const error = new Error(`png_canvas_el 에서 blob 객체를 만드는 데 실패했습니다.`);
              error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::too_large_image');
              reject(error);
              return;
            }
            resolve(blob);
          }, 'image/png'),
        );
        return new File([blob], 'thumbnail.png', { type: 'image/png' });
      };

      await outerPostProcess({
        original_svg_file,
        cutting_line_svg_file,
        makePrintablePNGFile,
        makeThumbnailPNGFile,
        hook_position_ratio,
        fitted_size,
      });

      return { success: true, value: null };
    } catch (error) {
      console.error(error);
      const message =
        error.__mp_alert_message ?? T('modules::VectorEditor::Keyring::message::output_making_error');
      return { success: false, value: message };
    } finally {
      await loaderDone().catch(noop);
    }
  };
}

async function makeImageDataURL(url) {
  const [url_without_qs, qs] = url.split(`?`);
  const converted_url = go(
    qs ?? `canvas=marpple-sticker-editor-v3`,
    (qs) => qs.split(`&`),
    mapL((a) => a.split(`=`)),
    mapL(([a, b]) => {
      if (decodeURIComponent(a) === 'canvas') {
        return [a, 'marpple-sticker-editor-v3'];
      }
      return [a, b];
    }),
    mapL(mapL(encodeURIComponent)),
    mapL(join('=')),
    join('&'),
    (qs) => `${url_without_qs}?${qs}`,
  );
  const blob = /** @type {Blob} */ await axios
    .get(converted_url, { responseType: 'blob' })
    .then(({ data }) => data);
  return /** @type {Promise<string>} */ new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.addEventListener('error', (event) => {
      console.error(event);
      const error = new Error(`이미지의 blob 데이터를 읽는 데 실패했습니다.`);
      error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::load_image_error');
      reject(error);
    });
    file_reader.readAsDataURL(blob);
  });
}

async function loadUsedFonts(el) {
  const used_fonts = await VectorEditorFontF.getAllUsedFonts(el);
  if (used_fonts.size > 0) {
    await go(
      SVGEditorFontConstS.FONTS,
      filterL(({ fontFamily }) => used_fonts.has(fontFamily)),
      mapL(({ fontFamily: font_family, fontStyle: font_style, fontWeight: font_weight }) =>
        new FontFaceObserver(font_family, { weight: font_weight, style: font_style }).load(null, 10000),
      ),
      takeAllC,
    );
  }
}

async function parseSVGFile(svg_file) {
  if (svg_file == null) {
    return null;
  }

  const svg_str = /** @type {string} */ await new Promise((resolve, reject) => {
    $.don_loader_start();
    try {
      const file_reader = new FileReader();
      file_reader.addEventListener('load', () => resolve(file_reader.result));
      file_reader.addEventListener('error', reject);
      file_reader.readAsText(svg_file);
    } catch (error) {
      reject(error);
    } finally {
      $.don_loader_end();
    }
  });
  const svg_doc = new DOMParser().parseFromString(svg_str, 'image/svg+xml');
  const parser_error_el = svg_doc.querySelector('parsererror');
  if (parser_error_el != null) {
    const error = new Error(parser_error_el.innerText);
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_prev_keyring_svg');
    throw error;
  }
  const svg_el = svg_doc.querySelector('svg');
  if (svg_el == null) {
    const error = new Error('이전에 작업한 키링 SVG 파일에서 svg 엘리먼트를 찾을 수 없습니다.');
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_prev_keyring_svg');
    throw error;
  }

  return svg_el;
}

function makeMetaFromOptions(options) {
  return go(
    options,
    mapL((o) => o?.snapshot?.maker_meta?.value),
    rejectL(isNil),
    (iter) => reduce((a, b) => Object.assign(a, b), {}, iter),
  );
}

function selectGridKeyringDataFromMeta(meta) {
  const { grid_meta } = meta;

  if (grid_meta == null) {
    const error = new Error(`메타정보 객체에 grid_meta 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_meta_data');
    throw error;
  }

  const {
    width: grid_meta_width,
    height: grid_meta_height,
    outer_cutting_shape_path_data: grid_meta_outer_cutting_shape_path_data,
    inner_cutting_shape_path_data: grid_meta_inner_cutting_shape_path_data,
  } = grid_meta;

  if (Number.isNaN(grid_meta_width) || !Number.isFinite(grid_meta_width) || grid_meta_width <= 0) {
    const error = new Error(`메타정보 객체에 grid_meta.width 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_meta_data');
    throw error;
  }

  if (Number.isNaN(grid_meta_height) || !Number.isFinite(grid_meta_height) || grid_meta_height <= 0) {
    const error = new Error(`메타정보 객체에 grid_meta.height 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_meta_data');
    throw error;
  }

  if (grid_meta_outer_cutting_shape_path_data == null) {
    const error = new Error(`메타정보 객체에 grid_meta.outer_cutting_shape_path_data 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_meta_data');
    throw error;
  }

  if (grid_meta_inner_cutting_shape_path_data == null) {
    const error = new Error(`메타정보 객체에 grid_meta.inner_cutting_shape_path_data 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_meta_data');
    throw error;
  }

  /**
   * @todo
   * 나중에 grid_meta 에 있는 S3 리소스 주소를 통해
   * 동적으로 SVG 리소스 다운받기
   */
  const empty_template_el = (() => {
    let font_size = Math.max(1, Math.round((Math.min(grid_meta_width, grid_meta_height) / 50) * 3));
    if (T.lang === 'jp') {
      font_size = Math.ceil((font_size / 3) * 2 * 1000) / 1000;
    }
    const svg_str = `
      <svg xmlns="http://www.w3.org/2000/svg">
        <g class="root">
          <text
            style="user-select: none;"
            x="${grid_meta_width / 2}"
            y="${grid_meta_height / 2}"
            font-family="AppleSDGothicNeo, -apple-system, BlinkMacSystemFont, 'Droid Sans', 'Roboto', 'Segoe UI', 'Helvetica', Arial, sans-serif" 
            font-size="${font_size}"
            font-weight="normal"
            font-stretch="normal"
            font-style="normal"
            fill="${G.collabo_type === '' ? '#FF6B00' : '#000000'}"
            text-anchor="middle"
            alignment-baseline="middle"
            letter-spacing="0"
          >
            ${T('modules::VectorEditor::Keyring::template::empty_template_guide')}
          </text> 
        </g>
      </svg> 
    `;
    const svg_doc = new DOMParser().parseFromString(svg_str, 'image/svg+xml');
    const el = svg_doc.querySelector('.root');
    el.removeAttributeNS(null, 'class');
    return el;
  })();

  return {
    grid_meta_width,
    grid_meta_height,
    grid_meta_inner_cutting_shape_path_data,
    grid_meta_outer_cutting_shape_path_data,
    empty_template_el,
  };
}

function selectFreeKeyringDataFromMeta(meta) {
  const { free_meta } = meta;

  if (free_meta == null) {
    const error = new Error(`메타정보 객체에 free_meta 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_meta_data');
    throw error;
  }

  const {
    width: free_meta_width,
    height: free_meta_height,
    bounding_shape_path_data: free_meta_bounding_shape_path_data,
  } = free_meta;

  if (Number.isNaN(free_meta_width) || !Number.isFinite(free_meta_width) || free_meta_width <= 0) {
    const error = new Error(`메타정보 객체에 free_meta.width 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_meta_data');
    throw error;
  }
  if (Number.isNaN(free_meta_height) || !Number.isFinite(free_meta_height) || free_meta_height <= 0) {
    const error = new Error(`메타정보 객체에 free_meta.height 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_meta_data');
    throw error;
  }

  if (free_meta_bounding_shape_path_data == null) {
    const error = new Error(`메타정보 객체에 free_meta.bounding_shape_path_data 데이터가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Keyring::message::invalid_meta_data');
    throw error;
  }

  /**
   * @todo
   * 나중에 free_meta 에 있는 S3 리소스 주소를 통해
   * 동적으로 SVG 리소스 다운받기
   */
  const empty_template_el = (() => {
    let font_size = Math.max(1, Math.round((Math.min(free_meta_width, free_meta_height) / 50) * 3));
    if (T.lang === 'jp') {
      font_size = Math.ceil((font_size / 3) * 2 * 1000) / 1000;
    }
    const svg_str = `
      <svg xmlns="http://www.w3.org/2000/svg">
        <g class="root">
          <text
            style="user-select: none;"
            x="${free_meta_width / 2}"
            y="${free_meta_height / 2}"
            font-family="AppleSDGothicNeo, -apple-system, BlinkMacSystemFont, 'Droid Sans', 'Roboto', 'Segoe UI', 'Helvetica', Arial, sans-serif" 
            font-size="${font_size}"
            font-weight="normal"
            font-stretch="normal"
            font-style="normal"
            fill="${G.collabo_type === '' ? '#FF6B00' : '#000000'}"
            text-anchor="middle"
            alignment-baseline="middle"
            letter-spacing="0"
          >
            ${T('modules::VectorEditor::Keyring::template::empty_template_guide')}
          </text> 
        </g>
      </svg> 
    `;
    const svg_doc = new DOMParser().parseFromString(svg_str, 'image/svg+xml');
    const el = svg_doc.querySelector('.root');
    el.removeAttributeNS(null, 'class');
    return el;
  })();

  return {
    free_meta_width,
    free_meta_height,
    free_meta_bounding_shape_path_data,
    empty_template_el,
  };
}
