import { equals2, go, isNil, mapL, reduce, rejectL } from 'fxjs/es';
import { VectorEditorPhotoCardGridPCF } from '../../Grid/PC/F/Function/module/VectorEditorPhotoCardGridPCF.js';

const dom_parser = new DOMParser();
const parseSVGFromStr = (svg_str) => dom_parser.parseFromString(svg_str, 'image/svg+xml');

const makeGridPhotoCardMobile = async ({
  svg_el,
  title,
  postProcess,
  meta,
  // art_board_size,
}) => {
  // @TODO KUNHO 구현
  throw new Error(`NOT_IMPLEMENTED`);
};

export const makePhotoCardMobile = async ({ title, options, svg_el: svg_file, postProcess }) => {
  const meta = go(
    options,
    mapL((o) => o?.snapshot?.maker_meta?.value),
    rejectL(isNil),
    (iter) => reduce((a, b) => Object.assign(a, b), {}, iter),
  );

  // @TODO KUNHO meta 에서 필요한 정보 뽑아냄

  const svg_el = await (async () => {
    if (!svg_file) {
      return null;
    }

    const _svg_file = await svg_file;
    if (!_svg_file) {
      return null;
    }

    const svg_str = /** @type {string} */ await new Promise((resolve, reject) => {
      $.don_loader_start();
      try {
        const file_reader = new FileReader();
        file_reader.addEventListener('load', () => resolve(file_reader.result));
        file_reader.addEventListener('error', reject);
        file_reader.readAsText(_svg_file);
      } catch (error) {
        reject(error);
      } finally {
        $.don_loader_end();
      }
    });

    const svg_doc = parseSVGFromStr(svg_str);
    const parser_error_el = svg_doc.querySelector('parsererror');
    if (parser_error_el != null) {
      const error = new Error(parser_error_el.innerText);
      // @todo 메세지 i18n 처리
      error.__mp_alert_message = 'SVG 파일을 해석할 수 없습니다.';
      throw error;
    }
    const svg_el = svg_doc.querySelector('svg');
    if (svg_el == null) {
      const error = new Error('Cannot find a SVG element.');
      // @todo 메세지 i18n 처리
      error.__mp_alert_message = 'SVG 객체를 찾을 수 없습니다.';
      throw error;
    }

    return svg_el;
  })();

  return await makeGridPhotoCardMobile({ svg_el, title, postProcess, meta });
};

const makeGridPhotoCardPC = async ({
  svg_el,
  title,
  price,
  postProcess,
  meta,
  prev_frame_right_panel,
  // art_board_size,
  frame_position,
  onFrameHiding,
}) => {
  // @TODO KUNHO

  return await VectorEditorPhotoCardGridPCF.makePhotoCard({
    title,
    price,
    frame_position,
    // art_board_size,
    // background,
    // grids,
    prev_frame_right_panel,
    onFrameHiding,
    preProcess: async () => {
      // @TODO KUNHO
      throw new Error(`NOT_IMPLEMENTED`);
    },
    postProcess: async () => {
      // @TODO KUNHO
      throw new Error(`NOT_IMPLEMENTED`);
    },
  });
};

const makeGridPhotoCardCreatorPC = async ({
  svg_el,
  title,
  price,
  postProcess,
  meta,
  // art_board_size,
}) => {
  // @TODO KUNHO 구현
  throw new Error(`NOT_IMPLEMENTED`);
};

export const makePhotoCardPC = async ({
  app,
  title,
  price,
  options,
  svg_el: svg_file,
  prev_frame_right_panel,
  postProcess,
  getFramePosition,
  onFrameHiding,
}) => {
  const svg_el = await (async () => {
    if (!svg_file) {
      return null;
    }

    $.don_loader_start();
    try {
      const _svg_file = await svg_file;
      if (!_svg_file) {
        return null;
      }

      const svg_str = /** @type {string} */ await new Promise((resolve, reject) => {
        try {
          const file_reader = new FileReader();
          file_reader.addEventListener('load', () => resolve(file_reader.result));
          file_reader.addEventListener('error', reject);
          file_reader.readAsText(_svg_file);
        } catch (error) {
          reject(error);
        }
      });

      const svg_doc = parseSVGFromStr(svg_str);
      const parser_error_el = svg_doc.querySelector('parsererror');
      if (parser_error_el != null) {
        const error = new Error(parser_error_el.innerText);
        // @todo 메세지 i18n 처리
        error.__mp_alert_message = 'SVG 파일을 해석할 수 없습니다.';
        throw error;
      }
      const svg_el = svg_doc.querySelector('svg');
      if (svg_el == null) {
        const error = new Error('Cannot find a SVG element.');
        // @todo 메세지 i18n 처리
        error.__mp_alert_message = 'SVG 객체를 찾을 수 없습니다.';
        throw error;
      }

      return svg_el;
    } finally {
      $.don_loader_end();
    }
  });

  const { top: frame_top, height: frame_height } = await (async () => {
    $.don_loader_start();
    try {
      return await getFramePosition();
    } finally {
      $.don_loader_end();
    }
  })();

  const meta = go(
    options,
    mapL((o) => o?.snapshot?.maker_meta?.value),
    rejectL(isNil),
    (iter) => reduce((a, b) => Object.assign(a, b), {}, iter),
  );

  // @TODO KUNHO meta 에서 필요한 정보 뽑아냄

  if (equals2(app)('mp')) {
    return await makeGridPhotoCardPC({
      svg_el,
      title,
      price,
      postProcess,
      meta,
      prev_frame_right_panel,
      frame_position: { top: frame_top, height: frame_height },
      onFrameHiding,
    });
  }

  if (equals2(app)('creator')) {
    return await makeGridPhotoCardCreatorPC({ svg_el, title, price, postProcess, meta });
  }

  const error = new Error(`INVALID_APP`);
  /** @todo i18n 적용 */
  error.__mp_alert_message = `잘못된 어플리케이션 타입입니다.`;
  throw error;
};
