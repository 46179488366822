import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerGridPCCopyGridMuiF } from '../Mui/module/VectorEditorStickerGridPCCopyGridMuiF.js';
import { $setCss } from 'fxdom/es';

export const copyGrid = ({
  frame_position: { top: frame_position_top, width: frame_position_width, height: frame_position_height },
  grids,
  grid_sticker_editor,
  prev_frame_right_panel,
}) =>
  MuiF.openFrame(VectorEditorStickerGridPCCopyGridMuiF.frame, (frame, page, [tab]) => {
    const frame_appending = frame.appending;
    frame.appending = (frame_el) => {
      $setCss(['z-index', '1'])(frame_el);
      return frame_appending.call(frame, frame_el);
    };

    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_prev_frame_right_panel_el = prev_frame_right_panel;
      tab_el.__mp_frame_position_top = frame_position_top;
      tab_el.__mp_frame_position_width = frame_position_width;
      tab_el.__mp_frame_position_height = frame_position_height;
      tab_el.__mp_grids = grids;
      tab_el.__mp_grid_sticker_editor = grid_sticker_editor;
      return tab_appending.call(tab, tab_el);
    };
  });
