import { html } from 'fxjs/es';

export const fn = html`
  <div class="btm_menus">
    <div class="btm_menu text_editor">
      <div class="font circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Font Style')}</div>
      </div>
      <div class="keyboard circle_icon">
        <div class="icon"></div>
        <div class="text">${T('keyboard')}</div>
      </div>
    </div>
    <div class="btm_menu text_pattern_editor">
      <div class="font circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Font Style')}</div>
      </div>
    </div>
    <div class="btm_menu image_editor">
      <div class="fit_to_print_width circle_icon">
        <div class="icon"><div class="option check_box"></div></div>
        <div class="text">${T('maker::양옆 채우기')}</div>
      </div>
      <div class="color circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Color')}</div>
      </div>
      <div class="dosu_image_color circle_icon hidden">
        <div class="icon"></div>
        <div class="text">${T('Image Color')}</div>
      </div>
      <div class="pattern circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Pattern')}</div>
      </div>
      <div class="change_img circle_icon for_line">
        <div class="icon"></div>
        <div class="text">${T('line_maker::Change')}</div>
      </div>
    </div>
    <div class="btm_menu image_editor _only_one">
      <div class="fit_to_print_width circle_icon">
        <div class="icon"><div class="option check_box"></div></div>
        <div class="text">${T('maker::양옆 채우기')}</div>
      </div>
      <div class="pattern circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Pattern')}</div>
      </div>
      <div class="change_img circle_icon for_line">
        <div class="icon"></div>
        <div class="text">${T('line_maker::Change')}</div>
      </div>
    </div>
    <div class="btm_menu image_editor _from_library">
      <div class="fit_to_print_width circle_icon">
        <div class="icon"><div class="option check_box"></div></div>
        <div class="text">${T('maker::양옆 채우기')}</div>
      </div>
      <div style="display: none">
        <img
          src="https://s3.marpple.co/files/u_1187078/2021/1/original/b0a0e252b5eb03a3740a1a8f047f01e5e4f528201.png"
          alt="filter"
        />
        <img
          src="https://s3.marpple.co/files/u_1187078/2021/1/original/a66f1f953edac5ec717103ecaf3f0b7bb2c0483f1.png"
          alt="adjustment"
        />
        <img
          src="https://s3.marpple.co/files/u_1187078/2021/1/original/273880595e88f5329fe9316d85c2a48ca64dcfc41.png"
          alt="crop"
        />
      </div>
      <div class="filter circle_icon">
        <div class="icon"></div>
        <div class="text">${T('image_editor::filter')}</div>
      </div>
      <div class="adjustment circle_icon">
        <div class="icon"></div>
        <div class="text">${T('image_editor::adjustment')}</div>
      </div>
      <div class="crop circle_icon">
        <div class="icon"></div>
        <div class="text">${T('image_editor::transform')}</div>
      </div>
      <div class="dosu_image_color circle_icon hidden">
        <div class="icon"></div>
        <div class="text">${T('Image Color')}</div>
      </div>
      <div class="pattern circle_icon">
        <div class="icon"></div>
        <div class="text">${T('Pattern')}</div>
      </div>
      <div class="change_img circle_icon for_line">
        <div class="icon"></div>
        <div class="text">${T('line_maker::Change')}</div>
      </div>
      <div class="request_bg_removal circle_icon">
        <div class="icon">
          <div class="option check_box"></div>
        </div>
        <div class="text">${T('Background Remove Request')}</div>
      </div>
    </div>
  </div>
`;
