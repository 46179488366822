import { CommonNS } from '@marpple/sticker-editor';
import { defaultTo, each, equals2, flatMap, go, head, mapL, takeL } from 'fxjs/es';
import { VectorEditorStickerSinglePCF } from '../../../../Single/PC/F/Function/module/VectorEditorStickerSinglePCF.js';
import { $findAll, $scrollTop } from 'fxdom/es';

export const handleRightPanelSelectBackButtonClick =
  ({ tab_el }) =>
  () => {
    tab_el.__mp_grid_sticker_editor?.selectGrid?.(null);
    tab_el.__mp_selected_grid_id = null;
  };

export const handleRightPanelSelectEditButtonClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_selected_grid_id == null) {
      return;
    }
    const grid_id = tab_el.__mp_selected_grid_id;

    const grid = tab_el.__mp_grids?.get?.(grid_id);
    if (grid == null) {
      return;
    }
    const { x, y, width, height, foreground_el } = grid;

    const target_image_el = tab_el.__mp_grid_sticker_editor?.getTargetImage?.(grid_id);
    if (target_image_el == null) {
      return;
    }
    const clone_target_image_el = await (async () => {
      $.don_loader_start();
      try {
        return await CommonNS.UtilNS.deepCloneNode(target_image_el);
      } finally {
        $.don_loader_end();
      }
    })();

    const { fill, opacity } = go(
      clone_target_image_el.querySelectorAll(
        `[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-layer"] [data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-color"]`,
      ),
      mapL((rect_el) => {
        const fill = rect_el.getAttributeNS(null, 'fill');
        const opacity = rect_el.getAttributeNS(null, 'opacity');
        return { fill, opacity: parseFloat(opacity) };
      }),
      head,
      defaultTo({ fill: '#FFFFFF', opacity: 1 }),
    );

    await go(
      tab_el,
      $findAll(`.right_container .right_wrapper`),
      takeL(1),
      each((right_wrapper_el) =>
        VectorEditorStickerSinglePCF.makeSticker({
          title: tab_el.__mp_title,
          price: tab_el.__mp_price,
          frame_position: { top: tab_el.__mp_frame_position_top, height: tab_el.__mp_frame_position_height },
          prev_frame_right_panel: right_wrapper_el,
          art_board_size: { width, height },
          foreground_el,
          is_need_cutting_line: false,
          can_set_background: true,
          background: { fill, opacity },
          onFrameHiding: () => {
            (tab_el?.tab_opt ?? {}).tab_top = $scrollTop(window);
          },
          preProcess: (single_sticker_editor) => {
            go(
              clone_target_image_el.querySelectorAll(
                `[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="working-layer"]`,
              ),
              flatMap((layer_el) => layer_el.children),
              each((el) => single_sticker_editor.addEl(el)),
            );
          },
          postProcess: async (single_sticker_editor) => {
            if (equals2(single_sticker_editor.getGraphicsEls().size)(0)) {
              const error = new Error('the sticker is empty.');
              error.__mp_alert_message = T(
                'modules::VectorEditor::Sticker::message::빈 스티커입니다. 스티커를 만들어주세요 :)',
              );
              throw error;
            }

            const new_target_image_el = await (async () => {
              $.don_loader_start();
              try {
                return (
                  await single_sticker_editor.exportEditor({
                    factor: 1,
                    container_el: document.body,
                  })
                ).with_background_el;
              } finally {
                $.don_loader_end();
              }
            })();

            new_target_image_el.setAttributeNS(null, 'x', `${x}`);
            new_target_image_el.setAttributeNS(null, 'y', `${y}`);

            tab_el.__mp_grid_sticker_editor?.setTargetImage({
              id: grid_id,
              target_image_el: new_target_image_el,
            });
            tab_el.__mp_grid_sticker_editor?.selectGrid(grid_id);
          },
        }),
      ),
    );
    tab_el.__mp_grid_sticker_editor?.selectGrid?.(null);
    tab_el.__mp_selected_grid_id = null;
  };
