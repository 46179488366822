import { entries, html, strMap } from 'fxjs/es';
import { OMPCoreCellTmplS } from '../../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { isAllOrders, upsertQueryParams } from '../../../../Core/Util/S/Function/util.js';
import { OMPMyPageConstantS } from '../../../S/Constant/module/OMPMyPageConstantS.js';
import { OMPMyPageOrderS } from '../Function/module/OMPMyPageOrderS.js';

export const orderTmplPc = ({
  current_projections,
  order_total_page,
  order_page,
  order_status_data,
  current_status,
}) => {
  const columns = OMPMyPageConstantS.OrderTableColumn();
  const my_page_menu = OMPMyPageConstantS.MyPageMenu();
  const order_status = {
    ...OMPMyPageConstantS.OrderTotalStatus(),
    ...OMPMyPageConstantS.OrderStatus(),
    ...OMPMyPageConstantS.OrderCanceledStatus(),
  };

  const order_status_title = OMPMyPageConstantS.OrderStatusTitle();

  const empty_notice = TT('my_page::order::order_23_01', {
    status: order_status_title[current_status],
  });

  const order_all_count = Object.values(order_status_data).reduce((prev, acc) => (prev += acc), 0);

  const process_projections = OMPMyPageOrderS.makeTableProjections(current_projections);

  return html`
    <div class="omp-my-page-orders">
      <div class="omp-my-page-orders__wrapper">
        <div class="omp-my-page-orders__wrapper__status">
          ${strMap(([key, status]) => {
            const order_status_data_key = order_status_data?.[key] || 0;
            const order_status_data_additional_key = order_status_data?.[status?.additional_key] || 0;

            const count = isAllOrders(key)
              ? order_all_count
              : order_status_data_key + order_status_data_additional_key;

            const href = upsertQueryParams(`/${TT.lang}/${my_page_menu.order.url}`, { status: key });

            return html`
              <a href="${href}" class="omp-my-page-orders__wrapper__status-wrapper" data-active="${!!count}">
                <div
                  data-active="${current_status === key}"
                  class="omp-my-page-orders__wrapper__status-wrapper__top"
                >
                  <span class="omp-my-page-orders__wrapper__status-wrapper__top-count">${count}</span>
                  <span class="omp-my-page-orders__wrapper__status-wrapper__top-title">${status.title}</span>
                </div>
              </a>
            `;
          }, entries(order_status))}
        </div>
        <div class="omp-order__status-desc">${order_status[current_status].desc_oneline}</div>
      </div>
      <div class="omp-order__table">
        ${OMPCoreCellTmplS.paginatedTable({
          columns,
          data: process_projections,
          empty_notice: html` <div class="td-wrapper">${empty_notice}</div> `,
          merge_column_key: [columns[0].key],
          total_page: Number(order_total_page),
          current_page: Number(order_page),
        })}
      </div>
    </div>
  `;
};
