import { saveAs } from 'file-saver';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreUtilF } from './module/OMPCoreUtilF.js';

/**
 * @define 웹, 웹뷰 호환 파일 다운로드 기능
 * @param {string} url
 * @param {string} file_name
 * */
export function downloadFileAppCompatible({ url, file_name }) {
  UtilS.emptyNullGuard([url, file_name]);

  const resource_uri = decodeURI(url);

  if (OMPCoreUtilF.isMpApp()) {
    const params = JSON.stringify({
      type: 'file_download',
      data: { url, file_name },
    });
    // ReactNative 로 message post
    window.ReactNativeWebView.postMessage(params);
  } else {
    saveAs(resource_uri, file_name);
  }
}
