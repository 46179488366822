import { chunk, filter, find, flatMap, flatten, go, groupBy, html, map, mapC, sel, strMap } from 'fxjs/es';
import axios from 'axios';
import { getWaybillCJgoodsNameStr } from '../../S/waybill_fns.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import Swal from 'sweetalert2';
import { waybill_CJ_label_data_formatter } from './waybill_cj_formating.js';
import { printWaybillCJ } from './waybill_cj_printing.js';
import { makeDfProjectionListUpdate } from '../../../Projection/List/F/fs.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';

export async function printShopChildrenWaybills({ parent_projection_id }) {
  $.don_loader_start();
  const merged_children_projections = await go(
    axios({
      url: '/@api/waybill/children_from_merged_parent_projection_cj',
      method: 'get',
      params: { projection_id: parent_projection_id },
    }),
    sel('data'),
    filter((prj) => {
      const shippings = prj._.shippings;

      if (UtilArrayS.isEmNil(shippings)) return;
      const shipping = shippings[0];

      // 검증
      // - 주문서 상태 (제작준비중 + 제작완료)
      // - 택배 배송
      // - 운송장 번호 비어 있어야 함

      const is_projection_status_ready_to_ship = prj.status === 'before_deliver' || prj.status === 'printing';
      const is_waybill_empty = shipping.waybill_number == null || shipping.waybill_number === '';
      const is_parcel = shipping.type === 'parcel';

      return is_projection_status_ready_to_ship && is_parcel && is_waybill_empty;
    }),
  );

  $.don_loader_end();
  const { isConfirmed: is_waybill_print } = await Swal.fire({
    icon: 'warning',
    title: '모든 개별 배송 주문서 출력',
    width: 800,
    html: `CJ 택배로 발송할<br><span style="font-weight:bold;color:green;font-size:1.2rem">총 ${merged_children_projections.length} 개<br></span>의 개별 배송용 주문서가 검색되었습니다.<br><br><b>운송장 출력을 진행하시겠습니까?</b><br>(운송장 용지가 충분한지 확인해 주세요.)`,
    showConfirmButton: true,
    showDenyButton: true,
    showCancelButton: false,
    denyButtonText: '아니오',
    confirmButtonText: '네',
  });

  if (is_waybill_print === false) return;

  /*
   * @returns
   * { is_OK: boolean, message: string, data: { booking_result, ref_address, shipping }  }
   * */
  $.don_loader_start();
  const cj_waybills = (
    await axios.post('/@api/waybill/cj/req_cj_waybills', {
      shippings: merged_children_projections.map((prj) => {
        return { projection_id: prj.id, ...prj._.shippings[0] };
      }),
    })
  ).data;

  const group_by_pass_wabyills = go(
    cj_waybills,
    groupBy(({ is_OK }) => is_OK),
  );

  // CJ 에서 운송장 발급 조건이 안되는 운송장들이 있는 경우 주문 번호 알려줌 -> 진행 여부 Prompt
  // ex. 주소 정제 실패, 이름 조건 실패, 전화번호 없음 등
  if (UtilArrayS.isArrayOk(group_by_pass_wabyills.false)) {
    $.don_loader_end();
    const { isConfirmed } = await Swal.fire({
      icon: 'warning',
      title: '운송장 발급이 불가한 주문서 목록 존재',
      width: 800,
      html: html`
        <div style="display:flex;flex-direction:column;gap:4px;align-items:center;justify-content:center">
          ${go(
            group_by_pass_wabyills.false,
            strMap(({ message, data: { shipping } }) => {
              return html`<div style="display: flex; gap:8px">
                <div style="width:80px;text-align:left">${shipping.projection_id}</div>
                <div style="max-width:650px;text-wrap:nowrap;overflow:hidden;text-overflow:ellipsis">
                  ${message}
                </div>
              </div>`;
            }),
          )}
        </div>
      `,
      showConfirmButton: true,
      confirmButtonText: '불가한 주문서 빼고 나머지 진행',
      showCancelButton: true,
      cancelButtonText: '진행 취소',
    });

    // 진행 취소
    if (isConfirmed === false) return;
  }

  // CJ 운송장 발급된 것들만 운송장 출력
  if (UtilArrayS.isArrayOk(group_by_pass_wabyills.true)) {
    DfInhouseF.lottie_loader.start('printer');
    // 운송장 출력 데이터 생성
    const waybill_print_data = go(
      group_by_pass_wabyills.true,
      map((waybill) => {
        const {
          data: {
            booking_result: { SEND_DATA },
            ref_address,
            shipping: { id, projection_id },
          },
        } = waybill;

        return {
          ...SEND_DATA,
          ...ref_address,
          projection_id,
          shipping_id: id,
          goods_name: getGoodsNameStrFromProjectionId({ projection_id, merged_children_projections }),
        };
      }),
      waybill_CJ_label_data_formatter,
    );

    // 라벨 프린터 출력
    const {
      is_OK: is_printed,
      reason,
      error,
    } = await printWaybillCJ(waybill_print_data, {
      is_print_prj_qr: true,
      additional_label_datas: null,
      allowed_devices: ['ZD420', 'ZD421'],
    });

    DfInhouseF.lottie_loader.end();
    if (!is_printed && reason != null) {
      return await Swal.fire({
        icon: 'error',
        width: 500,
        backdrop: true,
        title: '에러 발생',
        imageUrl: error?.image ?? '',
        imageWidth: 200,
        html: `<span>${reason}</span>`,
        showConfirmButton: true,
      });
    }

    /* DB 업데이트
     *  1. shipping (waybill_number, updated_at) from shipping_id
     *  2. task (배송태스크, updated_at, status, completed_at) from projection_id
     *  3. parent 의 모든 merged projection 의 status 가 배송 완료된 경우 parent 의 shipping task 도 완료
     *  4. 모든 projection status 업데이트
     * */

    // 배송 태스크 완료 처리 의사 결정 Prompt
    const { isConfirmed } = await Swal.fire({
      icon: 'warning',
      title: '배송 태스크 완료 처리 결정',
      width: 800,
      html: `정상 출력된 모든 배송용 주문의<br><span style="color:red;font-weight:bold;">[마플샵 개별배송]</span> 태스크를<br><span style="color:green;font-weight:bold;">일괄 완료 처리</span> 하시겠어요?`,
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: false,
      denyButtonText: '아니오',
      confirmButtonText: '네',
    });

    // 배송 태스크 완료 미처리
    if (isConfirmed === false) return;

    // 태스크, 프로젝션 업데이트 처리
    $.don_loader_start();
    const response_projection_ids = await go(
      waybill_print_data,
      map((d) => ({
        projection_id: d.projection_id,
        shipping_id: d.shipping_id,
        waybill_number: d.invoice_no_barcode,
      })),
      chunk(250),
      mapC(async (data) => {
        return (
          await axios.post('/@api/waybill/cj/update_shipping_task_and_shop_child_prj_statuses', {
            data,
          })
        ).data;
      }),
      flatten,
    );

    $.don_loader_end();
    await Swal.fire({
      icon: 'success',
      title: '개별배송 태스크 [완료] -> 주문 상태 [배송중] 처리 -> 문자 발송',
      width: 800,
      html: response_projection_ids.join(', '),
      showConfirmButton: true,
      confirmButtonText: '확인',
      showCancelButton: false,
    });
  } else {
    $.don_loader_end();
    await Swal.fire({
      icon: 'error',
      width: 500,
      backdrop: true,
      title: '출력 조건이 되는 주문서 없음',
      showConfirmButton: true,
    });
    return;
  }

  if (UtilArrayS.isEmNil(group_by_pass_wabyills.false)) {
    // 실패 처리된 운송장이 없는 경우, 병합 주문서도 업데이트
    $.don_loader_start();

    await axios.post('/@api/waybill/cj/update_merged_parent_projection', {
      parent_projection_id,
    });

    $.don_loader_end();
  }

  makeDfProjectionListUpdate();
}

function getGoodsNameStrFromProjectionId({ projection_id, merged_children_projections }) {
  const prj = find((p) => p.id === projection_id, merged_children_projections);
  const goods_info = flatMap((up) => {
    const {
      quantity,
      goods_type_id,
      _: { base_product, base_product_color, base_product_size, product, spo_items },
    } = up;

    return {
      projection_id: prj.id,
      goods_type_id,
      bp_name: base_product.name,
      color: base_product_color.name,
      size: base_product_size.name,
      quantity,
      product_name: product.name,
      group_names_option_names: spo_items?.group_names_option_names,
    };
  }, prj._.shippings[0]._.projection._.ups);

  return getWaybillCJgoodsNameStr(goods_info, prj.id, box().user.name);
}
