import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

export const findIDTmpl = () => {
  const find_id = TT('auth::find_id::find_id_02');
  const name = TT('auth::find_id::find_id_03');
  const name_placeholder = TT('auth::find_id::find_id_04');
  const mobile = TT('auth::find_id::find_id_05');
  const mobile_placeholder = TT('auth::find_id::find_id_06');
  const mobile_request = TT('auth::find_id::find_id_07');
  const mobile_request_placeholder = TT('auth::find_id::find_id_08');

  return html`
    <form class="omp-find-id">
      <div class="omp-find-id__data">
        <div class="omp-find-id__sub-title">${find_id}</div>
        <div class="omp-find-id__form-value">
          <span class="omp-find-id__form-key">${name}</span>
          ${OMPCoreAtomTmplS.input({
            name: 'name',
            placeholder: name_placeholder,
            style: {
              height: 50,
              border: false,
            },
          })}
        </div>

        <div class="omp-find-id__form-value">
          <span class="omp-find-id__form-key">${mobile}</span>
          ${OMPCoreAtomTmplS.input({
            name: 'mobile',
            maxlength: 11,
            placeholder: mobile_placeholder,
            style: {
              height: 50,
              border: false,
            },
          })}
          <span class="omp-find-id__form-button">
            ${OMPCoreAtomTmplS.actionButton({
              klass: 'omp-find-id__mobile-request',
              solidity: 'normal-thin',
              text_size: 'small-normal',
              text: mobile_request,
              disabled: true,
              type: 'button',
            })}
          </span>
        </div>

        <div class="omp-find-id__form-value" data-requested="false">
          ${OMPCoreAtomTmplS.input({
            name: 'code',
            maxlength: 6,
            placeholder: mobile_request_placeholder,
            style: {
              height: 50,
              border: false,
            },
          })}
          <div class="omp-find-id__timer-wrapper">
            <span class="omp-find-id__timer">5:00</span>
          </div>
        </div>
      </div>
      <div class="omp-find-id__button-wrapper">
        <span class="omp-find-id__button">
          ${OMPCoreAtomTmplS.actionButton({
            klass: 'omp-find-id__submit',
            text: TT('auth::find_id::find_id_01'),
            disabled: true,
            solidity: 'thick',
          })}
        </span>
      </div>
    </form>
  `;
};
