import axios from 'axios';
import { $addClass, $find, $qs, $qsa, $removeClass, $setAttr, $setHTML, $setText } from 'fxdom/es';
import { each } from 'fxjs/es';
import { updateMyWishTabCount } from '../../../../MyPage/Wish/F/Function/myWishTabCount.js';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

/**
 * @typedef {object} OmpUserCount
 * @property {number} cart 장바구니 상품의 개수
 * @property {string|number} my_image 만들기툴에서 업로드한 사진의 개수 (자세한 것은 정익님께 문의), string or number 타입이므로 주의
 * @property {number} wish "나중에 구매할 상품"의 개수
 * @property {number} wish_products "내가 찜한 상품"의 개수
 */

/**
 * @param {OmpUserCount?} user_counts
 * @return {Promise<OmpUserCount>}
 */
export const resetCartWish = async (user_counts) => {
  const counts = await getOmpUserCounts(user_counts);
  box.set('user_counts', counts);

  // Pc, Mo 분기 나누고 싶은데 확실하지 않아서 일단 통합
  rerenderCartAndWishInPcHeader(counts);
  rerenderCartAndWishInMoHeader(counts);
  rerenderCartAndWishInDrawerHeader(counts);

  rerenderMyImageCountInImageUploadFrame(counts);
  rerenderMyPageWishTabCounts(counts);

  return counts;
};

/**
 * @param {OmpUserCount?} user_counts
 * @return {Promise<OmpUserCount>}
 */
const getOmpUserCounts = async (user_counts) => {
  if (user_counts) return user_counts;
  const { data: counts } = await axios.get(`/${TT.lang}/@api/omp/user_counts`);
  return counts;
};

/**
 * @param {OmpUserCount} counts
 */
const rerenderCartAndWishInPcHeader = (counts) => {
  each((el) => {
    $setHTML(OMPCoreAtomTmplS.heartButton({ badge: counts.wish + counts.wish_products }), el);
  }, $qsa('.omp-cell__side-header-wish'));

  each((el) => {
    $setHTML(OMPCoreAtomTmplS.cartButton({ badge: counts.cart }), el);
  }, $qsa('.omp-cell__side-header-cart'));
};

/**
 * @param {OmpUserCount} counts
 */
const rerenderCartAndWishInMoHeader = (counts) => {
  each((el) => {
    $setHTML(
      OMPCoreAtomTmplS.heartButtonLink({
        badge: counts.wish + counts.wish_products,
        link: `/${TT.lang}/wish/list`,
        active: false,
      }),
      el,
    );
  }, $qsa('.mp-header-mo__wish'));

  each((el) => {
    $setHTML(OMPCoreAtomTmplS.cartButtonLink({ badge: counts.cart, link: `/${TT.lang}/cart` }), el);
  }, $qsa('.mp-header-mo__cart'));
};

/**
 * @param {OmpUserCount} counts
 */
const rerenderCartAndWishInDrawerHeader = (counts) => {
  each((count_el) => {
    if (counts.cart > 0) $addClass('drawer__cart-count-box--show', count_el);
    else $removeClass('drawer__cart-count-box--show', count_el);
    if (counts.cart > 9) $addClass('drawer__cart-count-box--wide', count_el);
    else $removeClass('drawer__cart-count-box--wide', count_el);
    $setText(counts.cart, $find('.drawer__cart-count', count_el));
  }, $qsa('.drawer__cart-count-box'));
};

/**
 * @param {OmpUserCount} counts
 */
const rerenderMyImageCountInImageUploadFrame = (counts) => {
  each(
    $setText(counts.my_image),
    $qsa('#maker .circle_icon.my_lib .count, .image_upload_frame .btn_my_lib .count'),
  );
  $setAttr({ my_image_count: counts.my_image }, $qs('html'));
};

/**
 * [마이페이지 - 찜한 상품]의 탭 카운트 다시 그려주기
 * @param {OmpUserCount} counts
 */
const rerenderMyPageWishTabCounts = (counts) => {
  return updateMyWishTabCount(document.body, counts);
};
