import { LAYER_NAME } from '../../S/Constant/constants.js';
import { DfImageEditorF } from './module/DfImageEditorF.js';

export function createLayerStructureAndStack({ paper_scope }) {
  /* Layer structure
   *  1. tool
   *  2. stroke
   *  3. erase
   *  4. default (printable raster)
   * */
  createLayer({ paper_scope, name: LAYER_NAME.rStroke, is_insert: true });
  createLayer({ paper_scope, name: LAYER_NAME.vStroke, is_insert: true });
  createLayer({ paper_scope, name: LAYER_NAME.default, is_insert: true });
  createLayer({ paper_scope, name: LAYER_NAME.spot, is_insert: true });
  createLayer({ paper_scope, name: LAYER_NAME.erase, is_insert: true });
  createLayer({ paper_scope, name: LAYER_NAME.tool, is_insert: true });
}

export function hidePeriphralLayers({ paper_scope }) {
  const PeriphralLayers = [LAYER_NAME.tool, LAYER_NAME.spot, LAYER_NAME.erase];
  paper_scope.project.layers.forEach((layer) => {
    if (PeriphralLayers.includes(layer.name)) {
      layer.visible = false;
    }
  });
}

export function showAllLayers({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  paper_scope.project.layers.forEach((layer) => {
    layer.visible = true;
  });
}

export function getLayerByIdx({ paper_scope, idx }) {
  const layer = paper_scope.project.layers[idx];
  if (layer == null) {
    throw new Error(`Not exist layer idx ${idx}`);
  }
  return layer;
}

export function createLayer({ paper_scope, name, is_insert }) {
  const layer = new paper_scope.Layer();

  if (name == null) {
    throw new Error(`Not exist name`);
  }
  if (is_insert == null) {
    throw new Error(`Not exist is_insert parameter for createLayer`);
  }
  layer.name = name;
  is_insert && paper_scope.project.addLayer(layer);

  const variable_layer_name = name.replace('-', '_');
  window[variable_layer_name] = layer;
  return layer;
}

export function createLayerThanInsertAbove({ paper_scope, new_layer_name, below_layer_name }) {
  const new_layer = createLayer({ paper_scope, name: new_layer_name, is_insert: false });
  const below_layer = DfImageEditorF.getLayerByName({ paper_scope, name: below_layer_name });
  if (below_layer == null) {
    throw new Error(`Not exist below layer ${below_layer_name}`);
  }
  new_layer.insertAbove(below_layer);
  return new_layer;
}

export function createLayerThanInsertBelow({ paper_scope, new_layer_name, above_layer_name }) {
  const new_layer = createLayer({ paper_scope, name: new_layer_name, is_insert: false });
  const above_layer = DfImageEditorF.getLayerByName({ paper_scope, name: above_layer_name });
  if (above_layer == null) {
    throw new Error(`Not exist below layer ${above_layer_name}`);
  }
  new_layer.insertBelow(above_layer);
  return new_layer;
}

export function getLayerByName({ paper_scope, name }) {
  return paper_scope.project.layers.find((layer) => layer.name === name);
}

export function getItemInLayer({ layer, condition }) {
  return layer.getItem(condition);
}

export function getItemsInLayer({ layer, condition }) {
  return layer.getItems(condition);
}

export function getDefaultLayer({ paper_scope }) {
  const default_layer = getLayerByName({ paper_scope, name: LAYER_NAME.default });
  if (default_layer == null) {
    throw new Error(`Not exist default layer`);
  }
  return default_layer;
}

export function getEraseLayer({ paper_scope }) {
  const erase_layer = getLayerByName({ paper_scope, name: LAYER_NAME.erase });
  if (erase_layer == null) {
    throw new Error(`Not exist erase layer`);
  }
  return erase_layer;
}

export function getToolLayer({ paper_scope }) {
  const tool_layer = getLayerByName({ paper_scope, name: LAYER_NAME.tool });
  if (tool_layer == null) {
    throw new Error(`Not exist tool layer`);
  }
  return tool_layer;
}

export function getRasterStrokeLayer({ paper_scope }) {
  const tool_layer = getLayerByName({ paper_scope, name: LAYER_NAME.rStroke });
  if (tool_layer == null) {
    throw new Error(`Not exist raster stroke layer`);
  }
  return tool_layer;
}

export function getVectorStrokeLayer({ paper_scope }) {
  const tool_layer = getLayerByName({ paper_scope, name: LAYER_NAME.vStroke });
  if (tool_layer == null) {
    throw new Error(`Not exist stroke layer`);
  }
  return tool_layer;
}

export function getVisibleStrokeItems({ paper_scope }) {
  const stroke_layer = getVectorStrokeLayer({ paper_scope });
  return stroke_layer.getItems({ visible: true, className: 'CompoundPath' });
}

export function getAllStrokeItems({ paper_scope }) {
  const stroke_layer = getVectorStrokeLayer({ paper_scope });
  return stroke_layer.getItems({ data: { is_stroke: true } });
}

export function getSpotLayer({ paper_scope }) {
  const tool_layer = getLayerByName({ paper_scope, name: LAYER_NAME.spot });
  if (tool_layer == null) {
    throw new Error(`Not exist spot layer`);
  }
  return tool_layer;
}
