import { every, find, go, not } from 'fxjs/es';
import { $find, $findAll } from 'fxdom/es';

export const isVirtualBpOptionNotNormal = (bp_option_groups_el) => {
  if (!bp_option_groups_el) return;
  const window_search = window.location.search;
  if (window_search.includes('lang_col')) return;
  const pc_id = go(
    window_search.replace('?', '').split('&'),
    find((str) => str.includes('pc_id')),
    (pc_id_str) => pc_id_str && pc_id_str.replace('pc_id=', ''),
    (pc_id_str) => pc_id_str && parseInt(pc_id_str),
  );
  if (
    pc_id &&
    [
      9486606, 9546160, 9563930, 9567752, 9569289, 9591654, 9602856, 9604440, 9629157, 9629996, 9633707,
    ].includes(pc_id)
  )
    return true;
  const virtual_bp_option_group_els = $findAll(
    '.bp_option_group[data-is_virtual="true"]',
    bp_option_groups_el,
  );
  if (!virtual_bp_option_group_els.length) return;
  return go(
    virtual_bp_option_group_els,
    every((bp_option_group_el) =>
      go(
        bp_option_group_el,
        $find('select'),
        (select_el) => select_el.value === bp_option_group_el.dataset._default_bp_option_id,
      ),
    ),
    not,
  );
};
