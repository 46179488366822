import { $attr, $closest, $data } from 'fxdom/es';
import { curry, go, identity, map, sel, takeWhile } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

const recursiveWithStartL = curry(function* (f, start) {
  yield start;
  yield* UtilS.recursiveL(f)(start);
});

const $closestDataFxSel = $closest('.don_tab [data-fx-sel]');

export const selTabData = (el$) => {
  const tab_data = $data($closest('.don_tab', el$));
  return go(
    $closestDataFxSel(el$),
    recursiveWithStartL((el$) => $closestDataFxSel(el$.parentElement)),
    takeWhile(identity),
    map($attr('data-fx-sel')),
    (sels) => (sels.length ? sel(sels.reverse(), tab_data) : undefined),
  );
};
