import { $find, $scrollTop, $setScrollTop } from 'fxdom/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSingleCreatorPCCuttingLineAutoF } from '../../CuttingLineAuto/F/Function/module/VectorEditorStickerSingleCreatorPCCuttingLineAutoF.js';

export const handleRightCancelButtonClick = () => MuiF.closeFrame(null);

export const handleRightDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    try {
      await tab_el?.__mp_postProcess?.(editor);
    } catch (error) {
      console.error(error);
      $.alert(error.__mp_alert_message ?? T('modules::VectorEditor::Sticker::message::fail_image'));
      return;
    }

    MuiF.closeFrame();
  };

export const handleRightNextButtonClick =
  ({ tab_el }) =>
  async () => {
    try {
      if (tab_el.__mp_single_sticker_editor.getGraphicsEls().size <= 0) {
        /** @todo alert message */
        return;
      }

      if ($scrollTop(window) > 30) {
        await window.anime({
          targets: 'html, body',
          scrollTop: 0,
          duration: 400,
          easing: 'easeInOutCubic',
        }).finished;
      } else {
        $setScrollTop(0)(window);
      }

      await VectorEditorStickerSingleCreatorPCCuttingLineAutoF.makeCuttingLineAuto({
        single_sticker_editor: tab_el.__mp_single_sticker_editor,
        postProcess: tab_el.__mp_postProcess,
        prev_page_right_panel_el: $find(`.right_container .right_wrapper`)(tab_el),
        editor_padding: {
          top: tab_el.__mp_editor_padding_top,
          right: tab_el.__mp_editor_padding_right,
          bottom: tab_el.__mp_editor_padding_bottom,
          left: tab_el.__mp_editor_padding_left,
        },
      });
    } catch (error) {
      await $.alert(T('modules::VectorEditor::Sticker::message::cutting_line_making_error'));
      throw error;
    }
  };
