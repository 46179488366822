import { each, go } from 'fxjs/es';
import { $findAll } from 'fxdom/es';

export const showBottomMenuBlankPlate = (tab_el) => {
  go(
    tab_el,
    $findAll(`.blank_plate[data-target="bottom_menu"]`),
    each((el) => {
      el.dataset.is_show = 'true';
    }),
  );
};

export const hideBottomMenuBlankPlate = (tab_el) => {
  go(
    tab_el,
    $findAll(`.blank_plate[data-target="bottom_menu"]`),
    each((el) => {
      el.dataset.is_show = 'false';
    }),
  );
};

export const activateBottomMenus = (tab_el) => {
  go(
    tab_el,
    $findAll('.bottom_menu'),
    each((el) => {
      el.dataset.is_active = 'true';
    }),
  );
};

export const deactivateBottomMenus = (tab_el) => {
  go(
    tab_el,
    $findAll('.bottom_menu'),
    each((el) => {
      el.dataset.is_active = 'false';
    }),
  );
};

export const showBottomMenus = (tab_el) => {
  go(
    tab_el,
    $findAll('.bottom_menu'),
    each((el) => {
      el.dataset.is_show = 'true';
    }),
  );
};

export const hideBottomMenus = (tab_el) => {
  go(
    tab_el,
    $findAll('.bottom_menu'),
    each((el) => {
      el.dataset.is_show = 'false';
    }),
  );
};
