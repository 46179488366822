import { each, eachL, go, isNil, mapL, pipe, rejectL, takeAll, takeL, tap } from 'fxjs/es';
import { $findAll, $on } from 'fxdom/es';
import { VectorEditorSettingBackgroundPCF } from '../../../../../SettingBackground/PC/F/Function/module/VectorEditorSettingBackgroundPCF.js';
import { VectorEditorStickerFreeCreatorPCMuiS } from '../../S/Mui/module/VectorEditorStickerFreeCreatorPCMuiS.js';
import { VectorEditorStickerFreeCreatorPCEventF } from '../Event/module/VectorEditorStickerFreeCreatorPCEventF.js';
import { EditorNS } from '@marpple/sticker-editor';
import { VectorEditorStickerFreeCreatorPCF } from '../Function/module/VectorEditorStickerFreeCreatorPCF.js';
import { VectorEditorTopMenuPCF } from '../../../../../TopMenu/PC/F/Function/module/VectorEditorTopMenuPCF.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';
import { VectorEditorSettingBackgroundPCEventF } from '../../../../../SettingBackground/PC/F/Event/module/VectorEditorSettingBackgroundPCEventF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerFreeCreatorPCMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_title = tab_el.__mp_title ?? '';
    tab_el.__mp_price = tab_el.__mp_price ?? '';
    tab_el.__mp_background_color = tab_el.__mp_background_color ?? '#ffffff';
    tab_el.__mp_background_opacity = tab_el.__mp_background_opacity ?? 1;
    tab_el.__mp_art_board_size = {
      width: tab_el.__mp_art_board_size?.width ?? 0,
      height: tab_el.__mp_art_board_size?.height ?? 0,
    };
    tab_el.__mp_free_template_svg_el = tab_el.__mp_free_template_svg_el ?? null;
    tab_el.__mp_single_empty_template_svg_el = tab_el.__mp_single_empty_template_svg_el ?? null;
    tab_el.__mp_single_art_board_size = {
      width: tab_el?.__mp_single_art_board_size?.width ?? 0,
      height: tab_el?.__mp_single_art_board_size?.height ?? 0,
    };
    tab_el.__mp_sticker_collision_margin = tab_el.__mp_sticker_collision_margin ?? 1.5;
    tab_el.__mp_collision_container_path_data = tab_el.__mp_collision_container_path_data ?? null;
    tab_el.__mp_collision_container_area_position = tab_el.__mp_collision_container_area_position ?? null;
    tab_el.__mp_collision_forbidden_area_position = tab_el.__mp_collision_forbidden_area_position ?? null;
    tab_el.__mp_preProcess = tab_el.__mp_preProcess ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_free_sticker_editor = null;

    go(
      tab_el,
      $findAll(`.top_menu_container .top_menus`),
      each(VectorEditorTopMenuPCF.hideToggleLayersModeIcon),
    );

    go(
      tab_el,

      tap(
        $findAll(`.left_container .new_sticker_button`),
        each($on('click', VectorEditorStickerFreeCreatorPCEventF.handleNewStickerButtonClick({ tab_el }))),
      ),

      tap(
        $findAll(`.right_container .right_wrapper .button_container .done`),
        each(
          $on('click', VectorEditorStickerFreeCreatorPCEventF.handleRightBottomDoneButtonClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .button_container .cancel`),
        each($on('click', VectorEditorStickerFreeCreatorPCEventF.handleRightBottomCancelButtonClick)),
      ),

      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID}"]`,
        ),
        each($on('click', VectorEditorStickerFreeCreatorPCEventF.handleTopMenuDuplicateClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID}"]`,
        ),
        each($on('click', VectorEditorStickerFreeCreatorPCEventF.handleTopMenuDeleteClick({ tab_el }))),
      ),

      tap(
        $findAll(`.right_container .right_wrapper .panel_container .right_panel.right_panel_home`),
        each(
          pipe(
            tap((el) => VectorEditorSettingBackgroundPCF.setTitle({ el, title: tab_el.__mp_title })),
            tap((el) =>
              VectorEditorSettingBackgroundPCEventF.addColorSelectorNotEtcClickEventHandler({
                el,
                f: VectorEditorStickerFreeCreatorPCEventF.handleRightPanelHomeColorSelectorNotEtcClick({
                  tab_el,
                }),
              }),
            ),
            tap((el) =>
              VectorEditorSettingBackgroundPCEventF.addColorSelectorEtcClickEventHandler({
                el,
                ...VectorEditorStickerFreeCreatorPCEventF.handleRightPanelHomeColorSelectorEtcClick({
                  tab_el,
                }),
              }),
            ),
            tap((el) =>
              VectorEditorSettingBackgroundPCF.setColor({ el, color_code: tab_el.__mp_background_color }),
            ),
          ),
        ),
      ),

      tap(
        $findAll(`.right_container .right_wrapper .panel_container .right_panel.right_panel_select`),
        each(
          pipe(
            tap(
              $findAll(`.header .back`),
              each(
                $on(
                  'click',
                  VectorEditorStickerFreeCreatorPCEventF.handleRightPanelSelectBackClick({ tab_el }),
                ),
              ),
            ),
            tap(
              $findAll(`.wrapper .edit`),
              each(
                $on(
                  'click',
                  VectorEditorStickerFreeCreatorPCEventF.handleRightPanelSelectEditClick({ tab_el }),
                ),
              ),
            ),
          ),
        ),
      ),

      tap(
        $on(
          '@mp/sticker-editor/add',
          VectorEditorStickerFreeCreatorPCEventF.handleFreeStickerEditorAdd({ tab_el }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/remove',
          VectorEditorStickerFreeCreatorPCEventF.handleFreeStickerEditorRemove({ tab_el }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/select',
          VectorEditorStickerFreeCreatorPCEventF.handleFreeStickerEditorSelect({ tab_el }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/unselect',
          VectorEditorStickerFreeCreatorPCEventF.handleFreeStickerEditorUnselect({ tab_el }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/transform',
          VectorEditorStickerFreeCreatorPCEventF.handleFreeStickerEditorTransform({ tab_el }),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    const free_sticker_editor = new EditorNS.FreeStickerEditorNS.PCNS.FreeStickerPCEditor({
      window,
      document,
      max_count: 20,
    });
    free_sticker_editor.initDOM();
    free_sticker_editor.initEditor();
    go(
      tab_el,
      $findAll(`.editor_container`),
      takeL(1),
      each((el) => free_sticker_editor.appendTo(el)),
    );
    free_sticker_editor.setBackground({
      type: 'color',
      fill: tab_el.__mp_background_color,
      opacity: tab_el.__mp_background_opacity,
    });
    free_sticker_editor.setArtBoardSize({
      width: tab_el.__mp_art_board_size.width,
      height: tab_el.__mp_art_board_size.height,
    });
    if (tab_el.__mp_free_template_svg_el != null) {
      each(
        $on('click', VectorEditorStickerFreeCreatorPCEventF.handleFreeTemplateCuttingLineClick({ tab_el })),
      )($findAll(`[data-free-sticker-template-role="cutting-line"]`)(tab_el.__mp_free_template_svg_el));
      tab_el.__mp_free_template_svg_el.classList.add('free_template');
      free_sticker_editor.setTemplate(tab_el.__mp_free_template_svg_el);
      tab_el.__mp_free_template_svg_el.dataset.is_show = 'true';
      go(
        tab_el,
        $findAll(`.new_sticker_button`),
        each((el) => (el.dataset.is_show = 'false')),
      );
    } else {
      go(
        tab_el,
        $findAll(`.new_sticker_button`),
        each((el) => (el.dataset.is_show = 'true')),
      );
    }
    tab_el.__mp_free_sticker_editor = free_sticker_editor;
    return go(
      tab_el.__mp_preProcess?.(free_sticker_editor, tab_el),
      () => tab_el.__mp_free_sticker_editor?.getEls?.() ?? [],
      eachL(({ id, wrapper_el }) =>
        VectorEditorStickerFreeCreatorPCF.setOffsetPathData({ tab_el, wrapper_el, id }),
      ),
      eachL(({ id, wrapper_el }) =>
        VectorEditorStickerFreeCreatorPCF.transformOffsetPathData({ tab_el, wrapper_el, id }),
      ),
      takeAll,
      (stickers) => {
        if (stickers.length > 0) {
          VectorEditorStickerFreeCreatorPCF.checkCollision({ tab_el });
        }
      },
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    tab_el.__mp_free_sticker_editor?.fitSize();
    go(
      tab_el,
      $findAll(`.top_menu_container`),
      mapL((el) => el.getBoundingClientRect().height),
      mapL((top_menu_height) =>
        tab_el.__mp_free_sticker_editor?.calculateViewBoxFitToArtBoard({
          padding_in_vcs: { top: top_menu_height + 80, bottom: 80, left: 300, right: 300 },
        }),
      ),
      rejectL(isNil),
      each((view_box_vo) => tab_el.__mp_free_sticker_editor?.setViewBox(view_box_vo)),
    );
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {
    tab_el.__mp_free_sticker_editor?.selectEls?.([]);
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {
    tab_el.__mp_title = '';
    tab_el.__mp_price = '';
    tab_el.__mp_background_color = '#ffffff';
    tab_el.__mp_background_opacity = 1;
    tab_el.__mp_art_board_size = { width: 0, height: 0 };
    tab_el.__mp_free_template_svg_el = null;
    tab_el.__mp_single_empty_template_svg_el = null;
    tab_el.__mp_single_art_board_size = { width: 0, height: 0 };
    tab_el.__mp_sticker_collision_margin = 0;
    tab_el.__mp_collision_container_path_data = null;
    tab_el.__mp_collision_container_area_position = null;
    tab_el.__mp_collision_forbidden_area_position = null;
    tab_el.__mp_preProcess = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_free_sticker_editor?.destroy();
    tab_el.__mp_free_sticker_editor = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
