import { html, go, mapL, join, chunkL } from 'fxjs/es';

export const makeColorSelectorHtml = ({ colors = [] } = {}) => html`
  <div class="color_selector">
    <span class="color_selector_label"></span>
    <div class="color_grid">
      ${go(
        colors,
        mapL(({ color_code, color_name, border, background_color, background_image, is_etc }) =>
          go(
            [
              ['background-color', background_color],
              ['background-image', background_image],
              ['border', border],
            ],
            mapL(([k, v]) => (v ? `${k}: ${v};` : '')),
            join(' '),
            (style) => html`
              <div
                class="color"
                data-color_code="${color_code}"
                data-color_name="${color_name}"
                data-is_etc="${is_etc}"
              >
                <div class="color_outer"></div>
                <div class="color_inner" style="${style.trim()}"></div>
              </div>
            `,
          ),
        ),
        chunkL(8),
        mapL(join('\n')),
        mapL((s) => html`<div class="color_line">${s}</div>`),
        join('\n'),
      )}
    </div>
  </div>
`;
