import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';
import { DfInhouseF } from './module/DfInhouseF.js';

export const stateBox = {
  get: {
    boxesFromBucketId: ({ bucket_id }) => {
      const bucket = DfInhouseF.stateBucket.gBox.get.inhouseShippingBucket({ bucket_id });
      return DfInhouseF.stateBox.get.boxesFromBucket({ bucket });
    },
    boxesFromBucket: ({ bucket }) => {
      if (UtilObjS.isEmNil(bucket)) return [];
      return bucket._.inhouse_shipping_boxes;
    },
  },
};
