import { each, find, go, map, sel, range } from 'fxjs/es';
import { makeOnlyDesignFaceCanvas } from '../../../../Maker/F/draw_product_faces.js';
import { isSameCanvas, isSameSize } from '../../../../Maker/F/canvas_trim.js';

function makeManyCanvas(length, func) {
  return go(
    range(length),
    map(() => func()),
  );
}

export const makePngForWeProduct = async (up_ids) => {
  await go(
    up_ids,
    map(async (id) => {
      console.log(id, '-------------------------');
      const { product_faces2, base_product_size_id, product_color_id } = await $.get(
        '/@api/prerequisite_maker/abc',
        {
          up_id: id,
        },
      );
      // const width = 4429.133858267716;
      G.mp.maker.is_auto_print = true;
      await go(
        product_faces2.value,
        each(async (pf) => {
          const { size_faces } = await $.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
            id: pf.bpf_id,
          });
          const {
            print: { cm },
            dpi,
          } = go(
            size_faces,
            find((sf) => sf.base_product_size_id == base_product_size_id),
          );
          const width = (cm.width / 2.54) * dpi;
          const canvass = await makeManyCanvas(2, () =>
            makeOnlyDesignFaceCanvas(pf, base_product_size_id, {
              width,
            }),
          );
          const is_same_size = isSameSize(canvass);
          if (!is_same_size) throw new Error(`NOT SAME SIZE`);
          const is_same_canvas1 = isSameCanvas(canvass, 20);
          if (!is_same_canvas1) {
            throw new Error('NOT SAME CANVAS');
          }
          pf.we_print_url = await go(
            $.uploadFileToOnlyOriginalUrl({
              original_name: 'design_print',
              image_type: 'png',
              url: canvass[0].toDataURL('image/png', 1),
            }),
            sel('url'),
          );
          console.log(pf.we_print_url, '---------------');
        }),
      );
      G.mp.maker.is_auto_print = false;

      await $.post('/@api/prerequisite_maker/abc', { product_color_id, product_faces2 });
    }),
  );
};
