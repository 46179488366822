import { DfImageEditorF } from './module/DfImageEditorF.js';
import { guard } from '../../S/guard.js';
import { DATA_SET_KEY, ITEM_NAME, MARGIN } from '../../S/Constant/constants.js';
import { setViewToBoundsFit } from './helpers.paper.js';
import { restoreOriginalRaster } from './tool.erase.js';
import { getJustifyCanvas } from '../../../../Maker/F/canvas_trim.js';
import { go } from 'fxjs/es';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';

export function getPrintableRasterSizeWithMargin({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  const margin = MARGIN.RASTER;

  const { width, height } = printable_raster.canvas;

  return { w: width + margin * 2, h: height + margin * 2 };
}

export async function getPrintableRasterImageWithMargin({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  return await DfImageEditorF.getImageWithMargin({
    canvas: printable_raster.canvas,
    margin: MARGIN.RASTER,
  });
}

export function isColorDark({ paper_color, alpha }) {
  alpha = alpha ?? 1;

  /* pre-multiplied alpha */
  const luminance =
    (0.2126 * paper_color.red + 0.7152 * paper_color.green + 0.0722 * paper_color.blue) / alpha;
  return luminance <= 0.5;
}

export function insertRaster({ paper_scope, layer, image_src }) {
  const raster = new paper_scope.Raster(image_src);
  layer.addChild(raster);

  return raster;
}

export function insertPrintableRaster({ paper_scope, img_src, is_window_attach = false }) {
  return new Promise((resolve, reject) => {
    const layer = DfImageEditorF.getDefaultLayer({ paper_scope });
    const check = guard.isLayerIsNotDefaultToolLayer({ layer });
    check && reject(check);
    const printable_raster = new paper_scope.Raster({
      source: img_src,
      smoothing: 'off',
      onLoad: () => {
        setViewToBoundsFit({ paper_scope, bounds: DfImageEditorF.findOutmostPixelCoords({ paper_scope }) });
        printable_raster.onLoad = null;

        DfImageEditorF.initializeStrokeProperty();
        setDesignCmSize({ tab_el: paper_scope.tab_el, with_dom_update: true });
        resolve(printable_raster);
      },
    });
    printable_raster.name = ITEM_NAME.printable_raster;
    layer.addChild(printable_raster);
    is_window_attach && (window.printable_raster = printable_raster);
  });
}

export function getPrintableRaster({ paper_scope }) {
  const default_layer = DfImageEditorF.getDefaultLayer({ paper_scope });
  const printable_raster = default_layer.getItem({ name: ITEM_NAME.printable_raster });
  if (printable_raster == null) {
    throw new Error(`Not exist printable raster`);
  }
  return printable_raster;
}

export function getRasterSubPixelHexColor({ raster, range_item, isWithoutAlpha }) {
  return DfImageEditorF.paperColorToHex({
    paper_color: raster.getAverageColor(range_item),
    isWithoutAlpha,
  });
}

export function getPrintableRasterAverageSize({ paper_scope }) {
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  const { width, height } = printable_raster.bounds.size;
  return (width + height) / 2;
}

export function getPrintableRasterMaxSize({ paper_scope }) {
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  const { width, height } = printable_raster.bounds.size;
  return Math.max(width, height);
}

export function getJustifyPrintableRasterDataUrl({ raster }) {
  return go(raster.canvas, getJustifyCanvas, (justify_canvas) => justify_canvas.toDataURL());
}

export function canvasToDataUrl(canvas) {
  return canvas.toDataURL();
}

export async function applyPrintableRasterToJustify({ paper_scope }) {
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });

  const justify_data_url = getJustifyPrintableRasterDataUrl({ raster: printable_raster });

  return new Promise((resolve, reject) => {
    printable_raster.onLoad = () => {
      resolve();
      printable_raster.onLoad = null;
    };
    printable_raster.onError = (e) => {
      reject(e);
    };
    printable_raster.source = justify_data_url;
  });
}

export function cancelErase({ tab_el, onAfterRestore }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  printable_raster.data = null;

  printable_raster.onLoad = () => {
    onAfterRestore && onAfterRestore(printable_raster);
    printable_raster.onLoad = null;
  };

  const { data_url: original_image_src } = DfImageEditorF.getPrintableDesign({ tab_el });
  restoreOriginalRaster({ paper_scope, original_image_src });

  DfImageEditorF.updateDesignSizeChange({ printable_raster, erase_delta: 'init' });
}

export function getPrintableDesign({ tab_el }) {
  return tab_el.tab_opt.design_source;
}

export function getDesignMarginX({ tab_el }) {
  const design_source = getPrintableDesign({ tab_el });

  const design_margin = design_source.design_margin_x;
  if (design_margin == null || design_margin.margin_x == null || design_margin.margin_direction == null) {
    return null;
  } else {
    return design_margin;
  }
}

export function getImageSourceMultiplierAndDpi({ tab_el }) {
  const design_source = getPrintableDesign({ tab_el });
  const DEFAULT_MULTIPLIER = 1;
  const DEFAULT_DPI = 300;

  return {
    multiplier: design_source.multiplier ?? DEFAULT_MULTIPLIER,
    dpi: design_source.dpi ?? DEFAULT_DPI,
  };
}

export function getDesignCmSize({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  /* 디자인 픽셀 과 관련된 layer - stroke_layer 와 printable_raster */
  /* 중에서 가장 큰 bounds 영역으로 크기를 산출 */
  const { width, height } = DfImageEditorF.findOutmostPixelCoords({
    paper_scope,
  });

  const { cm_width, cm_height } = rasterPxToCm({ tab_el, width, height });

  return { cm_width, cm_height, px_width: width, px_height: height };
}

export function setDesignCmSize({ tab_el, with_dom_update }) {
  const { cm_width, cm_height } = getDesignCmSize({ tab_el });

  DfImageEditorLibF.setState.property.designSize({
    width: cm_width,
    height: cm_height,
    with_dom_update,
  });
}

export function updateDesignSizeChange({ tab_el, printable_raster, scale_delta, erase_delta }) {
  if (printable_raster == null) {
    const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
    printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  }

  const exist_design_size_data = DfImageEditorF.getDataFromItem({
    item: printable_raster,
    key: DATA_SET_KEY.design_top_change,
  });

  if (exist_design_size_data == null) {
    const initial = {
      scale_delta: 0,
      erase_delta: 0,
    };
    DfImageEditorF.addDataToItem({
      item: printable_raster,
      data: { [DATA_SET_KEY.design_top_change]: initial },
    });
  }
  if (erase_delta === 'init') {
    printable_raster.data[DATA_SET_KEY.design_top_change].erase_delta = 0;
  } else {
    printable_raster.data[DATA_SET_KEY.design_top_change].erase_delta += erase_delta ?? 0;
  }

  if (scale_delta === 'init') {
    printable_raster.data[DATA_SET_KEY.design_top_change].scale_delta = 0;
  } else {
    printable_raster.data[DATA_SET_KEY.design_top_change].scale_delta += scale_delta ?? 0;
  }
}

export function getDesignTopEditingChange({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });

  /*
   * data: {
   *   design_top_change: {
   *       erase_delta,
   *       scale_delta
   *    }
   * }
   * */
  return DfImageEditorF.getDataFromItem({
    item: printable_raster,
    key: DATA_SET_KEY.design_top_change,
  });
}

export function rasterPxToCm({ tab_el, width, height }) {
  const { multiplier, dpi } = DfImageEditorF.getImageSourceMultiplierAndDpi({ tab_el });
  const INCH_TO_CM = 2.54;

  const cm_width = ((width / multiplier) * INCH_TO_CM) / dpi;
  const cm_height = ((height / multiplier) * INCH_TO_CM) / dpi;

  return { cm_width, cm_height };
}

export function cmToPx({ tab_el, cm }) {
  const { multiplier, dpi } = DfImageEditorF.getImageSourceMultiplierAndDpi({ tab_el });
  const INCH_TO_CM = 2.54;

  return ((cm * multiplier) / INCH_TO_CM) * dpi;
}

export function calculateTotalEditingDesignOffsetY({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });

  /* 원본 디자인에서 justify 되어 trim 된 top px */
  const design_trim_offset_y = DfImageEditorF.getDesignTrimOffsetYFn({ tab_el });

  /* 편집에 의해 justify 이미지에서 추가로 잘라 나간 top px */
  const design_top_editing_change = DfImageEditorF.getDesignTopEditingChange({ tab_el });

  const bounds_item = DfImageEditorF.getMaxBounds({ tab_el });
  const outer_bounds_top = bounds_item.bounds.top;
  const printable_raster_top = printable_raster.bounds.top;

  const design_top_offset = outer_bounds_top - printable_raster_top;

  let total_editing_design_offset;

  if (design_top_editing_change == null) {
    total_editing_design_offset = design_trim_offset_y + design_top_offset;
  } else {
    const { erase_delta } = design_top_editing_change;
    /* scale_delta, stroke_delta 값 무시 */
    total_editing_design_offset = design_trim_offset_y + erase_delta + design_top_offset;
  }

  /* 최초 원본 디자인에서부터 justify 하면서 trim 된 top + editing 하면서 trim 된 top 수치 */
  return total_editing_design_offset;
}
