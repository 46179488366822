import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { DfTaskS } from '../Function/module/DfTaskS.js';
import { DfTaskTmplS } from './module/DfTaskTmplS.js';
import { makeDfProjectionItemColsHtml } from '../../../Projection/List/F/tmpl.js';
import { DfTaskConstantS } from '../Constant/module/DfTaskConstantS.js';

export const t_my_task = (task) => pug`
    .task[_sel="task_list->(#${task.id})" is_canceled="${task.is_canceled}" task_id="${
  task.id
}" has_issue='${!!_p.v(task._, 'issues.length')}' status="${UtilS.escape(task.status)}" href="/task/detail/${
  task.id
}"]
      .head
        .title ${UtilS.escape(task.title)}
        .status ${DfTaskS.statusToKr(UtilS.escape(task.status))}
      .option
        .colmn1.task_issue_wrapper[task_issue_length="${_p.v(task._, 'issues.length')}"]
          .issue.task_issue 이슈 (${_p.v(task._, 'issues.length')})
        .colmn2
          .created_at 생성일: ${moment(UtilS.escape(task.created_at)).format('YY.MM.DD HH:mm')}
          .due_at 기한: ${moment(UtilS.escape(task.due_at)).format('YY.MM.DD HH:mm')}
          .task_id ID:${UtilS.escape(task.id)}
      .workers
        .manager
          label 담당자 :
          div ${_p.v(task, '_.worker.name')}
        .manager
          label 서브 담당자 :
          div ${_p.v(task, '_.admin.name')}
      .task_body[has_item=${!!task.body}]
        label 내용
        .body ${UtilS.escape(task.body)}
      .files[has_item=${_go(task, _p.v('_.files'), _p.first, _p.v('attached_id')) || false}]
        label 첨부된 파일
        ul.file_list
          ${_p.sum(
            task._.files,
            (file, i) => pug`
            li
              a[href="${UtilS.escape(file.url)}" download="${UtilS.escape(
              file.original_name,
            )}"] ${UtilS.escape(file.original_name)}
          `,
          )}
  `;

export const t_task_item = async (task) => pug`
      .task_item.infi_item[task_id="${task.id}" prj_id="${task._.projection.id}" is_canceled="${
  task.is_canceled
}" _sel="task_list->(#${task.id})"]
        .wrapper
          ${DfTaskTmplS.t_my_task(task)}
        ul.prj.df_grid
          li.grid.prj_item[prj_id="${task._.projection.id}" _sel="./_->projection" is_seller_support="${[
  'SUPPORT_SELLER_M_SGN',
  'NO_PRINT_NO_PROFIT_M_SGN',
].includes(task._.projection.promotion_code)}"]
            ${makeDfProjectionItemColsHtml(task._.projection)}
            .check_bp_stock[style="display:none;" class="${task.title == '재고확인' ? '' : 'no_need'}"]
              label
                input[type="checkbox"]
                span
    `;

export const t_projection_task = (task) => {
  return pug`
    .task[task_id="${task.id}" _sel="./(#${task.id})" has_issue='${!!_p.v(
    task._,
    'issues.length',
  )}' is_hidden="${task.is_hidden}" is_canceled="${task.is_canceled}" status="${
    task.status
  }" href="/task/detail/${task.id}"]
      .title.head ${(task.is_hidden ? '[삭제]' : '') + task.title}
      .manager[style="${_p.v(task, '_.worker.worker_policy') ? '' : 'background-color: #ffaed7'}"] ${
    _p.v(task, '_.worker.name') || '담당자 없음'
  }
        .product-position-status
          ${
            (task?.type === 'printing' || task?.type === 'before_deliver') &&
            task.title !== '라벨부착' &&
            task?.product_position_status &&
            task.product_position_status !== DfTaskConstantS.PRODUCT_POSITION_STATUS.NONE
              ? pug`
                ${
                  task.product_position_status_changed_at
                    ? pug`
                  span.time
                    ${moment(task.product_position_status_changed_at).format('MM.DD HH:mm:ss')}
                `
                    : ''
                }
                span.icon.${
                  task.product_position_status === DfTaskConstantS.PRODUCT_POSITION_STATUS.IMPORT
                    ? 'is-import'
                    : task.product_position_status ===
                      DfTaskConstantS.PRODUCT_POSITION_STATUS.EXPORT_TO_DELIVERY
                    ? 'is-export-to-delivery'
                    : 'is-export'
                }
                  ${DfTaskConstantS.PRODUCT_POSITION_STATUS_ICON[task.product_position_status]}
              `
              : ''
          }
      .due_at
        label 기한
        div ${moment(task.due_at).format('MM.DD HH:mm')}
      .completed_at
        label 완료
        div ${task.completed_at ? moment(task.completed_at).format('MM.DD HH:mm') : '-'}
      .issue_shape.task_issue 이슈
        .deco
        `;
};

const hex_code_map = {
  '!': '21',
  '[': '5b',
  ']': '5d',
  '%': '25',
  0: '30',
  1: '31',
  2: '32',
  3: '33',
  4: '34',
  5: '35',
  6: '36',
  7: '37',
  8: '38',
  9: '39',
};

const createNlsBarcodeHtml = (key_code) =>
  pug`
       .nls-barcode
         label ${key_code}
         div.code[key='${key_code}']
       `;

const createHexCodeOfString = (str) =>
  _p.go([...hex_code_map[str]], _p.map(createNlsBarcodeHtml), _p.join(''));

const task_press_type_id_scan_code = (press_type_id) =>
  _p.go([...press_type_id?.toString()], _p.map(createHexCodeOfString), _p.join(''));

export function t_prj_task_parent_id(task_parent_id, task_press_type_id, title) {
  if (task_parent_id == null) {
    return `<span class="description">해당 태스크는 스캔 설정이 가능하지 않습니다.</span>`;
  }

  const createAztecCodeHtml = (key_code) =>
    pug`
       .aztec-code
         label ${key_code}
         div.code[key='${key_code}']
       `;

  const createSimpleAztecCode = (key_code) =>
    pug`
       .aztec-code.center
         div.code[key='${key_code}']
       `;

  const parent_id_scan_codes = _p.go(
    [...task_parent_id.toString()],
    _p.map(createAztecCodeHtml),
    _p.join(''),
  );

  const is_deliver = /(한진|배송)+/.test(title);

  return pug`
    ${
      task_press_type_id || is_deliver
        ? pug`
      .sub-tab
        .option[key="task_auto_complete"]
          태스크 자동 완료 처리
        .option[key="task_prod_deliver_scan"]
          제품 전달 스캐너 설정
    `
        : ''
    }
    .sub-tab-contents
      .content[key="task_auto_complete"]
        .scan-codes
          section.test
            h1.title [스캐너 설정된 자동 완료 처리 태스크 확인]
            h2.description 아래 코드를 스캔하시면 스캐너에 세팅된 태스크 항목을 알 수 있습니다.
            ${createSimpleAztecCode('test-task-mode')}
          section.setting
            h1.title [태스크 자동 완료 처리 설정]
            h2.description 위 -> 아래, 왼쪽 <-> 오른쪽 교차해서 차례대로 스캔해 주세요.
            ${createAztecCodeHtml('prefix-add')}
            ${createAztecCodeHtml('!')}
            ${createAztecCodeHtml('suffix-add')}
            ${createAztecCodeHtml('[')}
            ${parent_id_scan_codes}
            ${createAztecCodeHtml(']')}
            ${createAztecCodeHtml('amp')}
          section.reset
            h1.title [태스크 자동 완료 처리 설정 초기화]
            h2.description 아래 두 코드를 스캔하시면 스캐너가 주문 조회 모드로만 동작하게 됩니다.
            .reset-codes
              ${createSimpleAztecCode('prefix-add')}
              ${createSimpleAztecCode('suffix-add')}
      ${
        task_press_type_id || is_deliver
          ? pug`
        .content[key="task_prod_deliver_scan"]
          .scan-codes
            section
              h1.title [제품 전달 확인]
              span
                b [태스크] > [태스크 입 출고 스캔]
                메뉴에서 스캐너로 주문서번호를 스캔하여 제작 태스크의 제품 입출고를 처리합니다.
              span
                b 주문서번호 + 인쇄방식
                으로 태스크를 찾아 입출고를 처리하기 때문에 아래 스캐너 설정으로 각 제작 태스크별 스캐너를 셋팅해주세요
              span 출고시 
                b 태스크명(+)
                는 타팀으로 제품을 전달할때 출고하는 태스크입니다.
            section.mode-setting
              h1.title [스캐너 설정]
              .scanner-link
                a.manual.link[target="_blank" href="https://s3.marpple.co/files/u_2947328/2024/1/original/ffc7fb5fb8c0ad9eb9af5c5fd8a1e3191bca193d1.pdf"] 스캐너 메뉴얼 (스캐너 모델 명: NLS-HR3280-BT)
                a.link[target="_blank" href="https://www.compuzone.co.kr/product/product_detail.htm?ProductNo=634181" style="margin-left: 8px;"] NLS-HR32BT 구매처
                a.link[target="_blank" href="https://www.compuzone.co.kr/product/product_detail.htm?ProductNo=1074416" style="margin-left: 8px;"] 태블릿(엠피지오_레전드_옥타곤) 구매처
              h2.description 스캔 도중 잘못 입력시 exit-setup 후 다시 처음부터 하시면 됩니다.
              h2.description 첫 구매 스캐너 세팅. 아래쪽으로 차례대로 스캔해 주세요.
              ${createNlsBarcodeHtml('enter-setup')}
              ${createNlsBarcodeHtml('set-volume')}
              ${createNlsBarcodeHtml('1')}
              ${createNlsBarcodeHtml('save')}
              ${createNlsBarcodeHtml('enable-aztec')}
              ${createNlsBarcodeHtml('enable-bluetooth')}
              ${createNlsBarcodeHtml('exit-setup')}
            section.custom-prefix-suffix
              h1.title [태스크 제품 전달 인쇄방식 설정]
              h2.description 위 -> 아래, 왼쪽 <-> 오른쪽 교차해서 차례대로 스캔해 주세요.
              ${createNlsBarcodeHtml('enter-setup')}
              ${createNlsBarcodeHtml('set-prefix')}
              ${createHexCodeOfString('!')}
              ${createNlsBarcodeHtml('save')}
              ${createNlsBarcodeHtml('enable-prefix')}
              ${createNlsBarcodeHtml('set-suffix')}
              ${createHexCodeOfString('[')}
              ${task_press_type_id_scan_code(is_deliver ? 1 : task_press_type_id)}
              ${createHexCodeOfString(']')}
              ${createHexCodeOfString('%')}
              ${createNlsBarcodeHtml('save')}
              ${createNlsBarcodeHtml('enable-suffix')}
              ${createNlsBarcodeHtml('exit-setup')}
              h2.description 확인 코드: !#주문서ID[${is_deliver ? 1 : task_press_type_id}]%
            section.reset
              h1.title [스캐너 설정 초기화]
              h2.description 아래 코드는 모든 설정이 초기화 됩니다.
              .reset-codes
                ${createNlsBarcodeHtml('enter-setup')}
                ${createNlsBarcodeHtml('reset-factory')}
                ${createNlsBarcodeHtml('exit-setup')}
      `
          : ''
      }
  `;
}
