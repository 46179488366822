import { each, go } from 'fxjs/es';
import { $findAll, $setHTML } from 'fxdom/es';
import { pathOffsets } from '@marpple/stickerizer';
import svgpath from 'svgpath';
import { VectorEditorKeyringFreeConstantS } from '../../../../S/Constant/module/VectorEditorKeyringFreeConstantS.js';
import { CommonNS } from '@marpple/sticker-editor';
import { nanoid } from 'nanoid';

export const setCuttingLine = async ({ tab_el, index }) => {
  if (tab_el.__mp_keyring_cutting_line_editor == null) {
    const error = new Error(`tab_el.__mp_keyring_cutting_line_editor 객체가 null 입니다.`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::error_message::no_keyring_cutting_line_editor',
    );
    throw error;
  }
  const editor = tab_el.__mp_keyring_cutting_line_editor;

  if (tab_el.__mp_max_cutting_line_index <= index) {
    const error = new Error(
      `tab_el.__mp_max_cutting_line_index 보다 크거나 같은 index 를 사용할 수 없습니다.`,
    );
    error.__mp_alert_message = T(
      'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::error_message::max_cutting_line_offset_error',
    );
    throw error;
  }

  if (index < 0) {
    const error = new Error(`0 보다 작은 index 를 사용할 수 없습니다.`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::error_message::min_cutting_line_offset_error',
    );
    throw error;
  }

  if (tab_el.__mp_cutting_lines[index] == null) {
    if (tab_el.__mp_cutting_lines[0]?.outer_cutting_line == null) {
      const error = new Error(`tab_el.__mp_cutting_lines[0].outer_cutting_line 가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::error_message::no_default_outer_cutting_line',
      );
      throw error;
    }
    const base_outer_cutting_line = tab_el.__mp_cutting_lines[0]?.outer_cutting_line;

    $.don_loader_start();
    try {
      await new Promise((resolve) => setTimeout(resolve, 30));
      const offset = index * 0.5;
      const outer_cutting_line = await pathOffsets(
        base_outer_cutting_line.paper_obj,
        offset * tab_el.__mp_factor,
      );
      const outer_cutting_line_path_data = svgpath(outer_cutting_line.path_data)
        .scale(1 / tab_el.__mp_factor)
        .toString();
      const inner_cutting_line = await pathOffsets(
        outer_cutting_line.paper_obj,
        -VectorEditorKeyringFreeConstantS.SAFE_MARGIN_MM * tab_el.__mp_factor,
      );
      const inner_cutting_line_path_data = svgpath(inner_cutting_line.path_data)
        .scale(1 / tab_el.__mp_factor)
        .toString();

      const cutting_line_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
      cutting_line_path_el.setAttributeNS(null, 'd', outer_cutting_line_path_data);
      cutting_line_path_el.setAttributeNS(null, 'stroke', 'none');
      cutting_line_path_el.setAttributeNS(null, 'fill', 'none');
      const cutting_line_svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
      cutting_line_svg_el.appendChild(cutting_line_path_el);
      document.body.appendChild(cutting_line_svg_el);
      const bbox = cutting_line_path_el.getBBox();
      document.body.removeChild(cutting_line_svg_el);
      if (bbox.width > tab_el.__mp_art_board_width || bbox.height > tab_el.__mp_art_board_height) {
        tab_el.__mp_max_cutting_line_index = index - 1;
        const error = new Error(`outer_cutting_line_path_data 가 아트보드 크기를 벗어났습니다.`);
        error.__mp_alert_message = T(
          'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::error_message::max_cutting_line_offset_error',
        );
        throw error;
      }

      tab_el.__mp_cutting_lines[index] = {
        offset,
        inner_cutting_line,
        inner_cutting_line_path_data,
        outer_cutting_line,
        outer_cutting_line_path_data,
      };
    } finally {
      $.don_loader_end();
    }
  }

  const cutting_line = tab_el.__mp_cutting_lines[index];
  editor.setOuterCuttingLinePathData(cutting_line.outer_cutting_line_path_data);
  editor.setInnerCuttingLinePathData(cutting_line.inner_cutting_line_path_data);
  go(
    tab_el,
    $findAll(`.right_container .wrapper_body_control_wrapper_auto_label`),
    each($setHTML(`${cutting_line.offset}`)),
  );
  tab_el.__mp_cur_cutting_line_index = index;
};

export const applyCuttingLine = ({ target_image_el, path_data }) => {
  const inner_cutting_line_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
  inner_cutting_line_path_el.setAttributeNS(null, 'd', path_data);

  const clip_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'clipPath');
  clip_path_el.setAttributeNS(
    null,
    'id',
    `${CommonNS.ConstNS.DATA_KEY_ROLE}__inner-cutting-line__${nanoid()}`,
  );
  clip_path_el.appendChild(inner_cutting_line_path_el);

  const defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
  defs_el.appendChild(clip_path_el);

  const wrapper_g_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
  wrapper_g_el.setAttributeNS(null, 'clip-path', `url(#${clip_path_el.id})`);

  Array.from(target_image_el.childNodes).forEach((node) => wrapper_g_el.appendChild(node));
  target_image_el.appendChild(defs_el);
  target_image_el.appendChild(wrapper_g_el);

  return target_image_el;
};
