import { $closest, $data, $delegate, $hasClass } from 'fxdom/es';
import { go } from 'fxjs/es';

/**
 * `OMPCoreAtomTmplS.removableButton` 이벤트
 * @param {HTMLElement} el
 * @return {HTMLElement} el
 */
export const delegateRemovableButton = (el) => {
  const Klass = Object.freeze({
    removableBtn: 'omp-atom__removable-button',
    removeIcon: 'omp-atom__icon-x',
  });

  return go(
    el,
    $delegate('click', `.${Klass.removableBtn}`, (e) => {
      const is_remove_clicked = $hasClass(`${Klass.removeIcon}`)(e.target);
      if (is_remove_clicked) {
        return;
      }

      const $remove_btn = e.currentTarget;
      const selectEvent = new CustomEvent('select', {
        bubbles: true,
        cancelable: true,
        detail: {
          id: $remove_btn.dataset.id,
          text: $remove_btn.dataset.text,
          data: $data($remove_btn),
        },
      });
      $remove_btn.dispatchEvent(selectEvent);
    }),
    $delegate('click', `.${Klass.removeIcon}`, (e) => {
      const $remove_btn = $closest(`.${Klass.removableBtn}`)(e.currentTarget);
      const removeEvent = new CustomEvent('remove', {
        bubbles: true,
        cancelable: true,
        detail: {
          id: $remove_btn.dataset.id,
          text: $remove_btn.dataset.text,
          data: $data($remove_btn),
        },
      });
      $remove_btn.dispatchEvent(removeEvent);
    }),
  );
};

/**
 *
 * @param {HTMLElement} el
 * @return {HTMLElement} el
 */
export const delegateToggleRemovableButton = (el) => {
  const Klass = Object.freeze({
    removableBtn: 'omp-atom__removable-button',
    removeIcon: 'omp-atom__icon-x',
  });

  return $delegate('click', `.${Klass.removableBtn}`, (e) => {
    const is_remove_clicked = $hasClass(`${Klass.removeIcon}`)(e.target);
    if (is_remove_clicked) {
      return;
    }

    const $remove_btn = e.currentTarget;
    const { is_active } = $remove_btn.dataset;
    $remove_btn.dataset.is_active = is_active === 'true' ? 'false' : 'true';
  })(el);
};
