import { equals, go, last, mapL, rangeL, rejectL, some, take } from 'fxjs/es';
import moment from 'moment';

const weekends = [0, 6];

const isWeekEndOrHoliday = (momentDate, holidays) =>
  some(equals(momentDate.weekday()), weekends) || some(equals(momentDate.format('YYYY-MM-DD')), holidays);

export const _getBusinessDayAfter = (days = 0, originDate, holidays) => {
  const direction = days >= 0 ? 1 : -1;
  const _originDate = originDate && moment(originDate).isValid ? moment(originDate) : moment();
  const is_minus_day = isWeekEndOrHoliday(_originDate, holidays);

  return go(
    rangeL(0, direction * Infinity),
    mapL((after) => _originDate.clone().add(after, 'day')),
    rejectL((day) => isWeekEndOrHoliday(day, holidays)),
    take(Math.max(Math.abs(is_minus_day ? days - 1 : days) + 1, 1)),
    last,
    (day) => day.toDate(),
  );
};
