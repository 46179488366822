import { delay, go, html } from 'fxjs/es';
import {
  $addClass,
  $appendTo,
  $delegate,
  $el,
  $find,
  $on,
  $qs,
  $remove,
  $removeClass,
  $setCss,
  $setText,
} from 'fxdom/es';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { SVGEditorUtilF } from './module/SVGEditorUtilF.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

export const halfFrameClose = (frame_el$) => {
  go(
    frame_el$,
    $delegate('click', '>.don_wrapper >.header', (e) => {
      MuiF.closeFrame();
    }),
  );
  go(
    document.createElement('div'),
    $setCss({
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }),
    $appendTo(frame_el$),
    $on('click', (e) => {
      MuiF.closeFrame();
    }),
  );
};
export const percentLoader = ({ message, time, clazz = '' }) => {
  const obj = { time, charged: '0%' };
  const el = go(
    html`
      <div class="process_time_loader ${clazz}">
        <div class="process_time_loader__container">
          <div class="process_time_loader__percent">${obj.charged}</div>
          <div class="process_time_loader__message">${message}</div>
        </div>
      </div>
    `,
    $el,
  );
  const percent_el = go(el, $appendTo($qs('body')), $find('.process_time_loader__percent'));
  let is_done = false;
  const loaderDone = async (is_cancel) => {
    is_done = true;
    if (!is_cancel) $setText('100%')(percent_el);
    $removeClass('fixed')($qs('html'));
    $removeClass('fixed')($qs('body'));
    await delay(800, null);
    $remove(el);
  };
  $addClass('fixed')($qs('html'));
  $addClass('fixed')($qs('body'));
  const an = anime({
    targets: obj,
    charged: '100%',
    duration: time,
    round: 1,
    easing: 'linear',
    update: function () {
      if (is_done) {
        an.pause();
        return;
      }
      if (obj.charged === '100%') {
        an.pause();
        return;
      }
      $setText(obj.charged)(percent_el);
    },
  });
  return loaderDone;
};

export async function convertSvgElementToUploadedUrl(svg_el) {
  const svg_string = new XMLSerializer().serializeToString(svg_el);
  const blob = new Blob([svg_string], { type: 'image/svg+xml' });

  const file = new File([blob], 'example.svg', { type: 'image/svg+xml' });
  const formData = new FormData();
  formData.append('original_svg_file', file);
  const { original_svg_file_url } = await $.upload(formData, {
    url: '/@fileUpload/svg_images_images/upload_svg_product_materials',
    data: {},
  });
  return original_svg_file_url;
}

/**
 * @define svg
 * @param {SVGElement | string | undefined} svg
 * @param {string | undefined} svg_url
 * @param {number | string | undefined} product_color_id
 * */
export async function getSvgString({ svg, svg_url, product_color_id }) {
  if (typeof svg === 'string') {
    return svg;
  }

  if (svg instanceof SVGElement) {
    // Convert the SVG element to an SVG string
    return new XMLSerializer().serializeToString(svg);
  }

  if (typeof svg_url === 'string') {
    return await SVGEditorUtilF.getCuttingLineSvgFromUrl({ cutting_line_url: svg_url });
  }

  if (['number', 'string'].includes(typeof product_color_id)) {
    return await SVGEditorUtilF.getCuttingLineSvg({
      product_id: product_color_id,
    });
  }

  throw new Error(`svg 문자열을 가져올 수 없습니다.`);
}

/**
 * @define svg 문자열을 svg element 로 변환
 * @param {string} svg_string
 * @return {HTMLElement | SVGElement} svg DOM 요소
 * */
export function convertSvgTextToSvgEl({ svg_string }) {
  const parser = new DOMParser();
  const svg_document = parser.parseFromString(svg_string, 'image/svg+xml');
  return svg_document.documentElement;
}

/**
 * @define svg 요소 내에 모든 path 요소를 조회
 * @param {SVGElement} svg_el
 * @param {boolean | undefined} is_clone
 * @return {SVGElement[] | null}
 * */
export function findAllPathEls({ svg_el, is_clone = false }) {
  if (!(svg_el instanceof SVGElement)) {
    throw new Error('Invalid SVG element provided.');
  }

  const path_els = svg_el.querySelectorAll('path');

  if (UtilS.isEmpty(path_els)) return null;

  return is_clone ? Array.from(path_els) : path_els;
}

export function extractElsFromSvg({ svg_el, tags }) {
  if (!(svg_el instanceof SVGElement) || !(Array.isArray(tags) && tags.length > 0)) {
    throw new Error(`Invalid input parameters`);
  }

  let matching_els = [];

  tags.forEach((tag) => {
    matching_els = matching_els.concat([...svg_el.querySelectorAll(tag)]);
  });

  return matching_els;
}

export function getSvgEl({ svg }) {
  if (svg instanceof SVGElement) {
    return svg;
  } else if (typeof svg === 'string') {
    return convertSvgTextToSvgEl({ svg_string: svg });
  } else {
    throw new Error(`svg is neither svg element or svg string`);
  }
}
