import { getCurrentFcanvas, getCvDesigns } from '../../../Maker/F/Fcanvas/cv_object.js';
import { BpOptionS } from '../../S/Function/module/BpOptionS.js';
import { renderMpMakerProductPrice } from '../../../Maker/F/Product/product_tmpl.js';

export const makeSelectedOptionPriceC2323 = async (
  { base_products_bp_option_groups, bp_option_groups_el, selected_option_group },
  bp_option_id_for_db_data,
  is_not_change_text,
) => {
  const cv_objs = getCvDesigns(getCurrentFcanvas()._objects);
  await BpOptionS.legacyCalcPriceKeyringAsync(
    {
      base_products_bp_option_groups,
      selected_option_group,
      cv_objs,
    },
    {
      bp_option_id_for_db_data,
      getOptionApi: (bp_option_id) => $.get('/@api/bp_option/get_bp_option', { id: bp_option_id }),
    },
  );
  G.mp.maker.reset_box_data();

  if (!is_not_change_text) {
    renderMpMakerProductPrice();
  }
};
