import { html, strMap } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Util/S/Function/module/OMPCoreUtilS.js';
import { OMPCoreCellTmplS } from './module/OMPCoreCellTmplS.js';

export const aboutIntroHTML = ({ title, image, set, text, target = '', buttonLink, buttonTxt }) => {
  return html` <div class="omp__intro">
    <div class="omp__image">
      <img src="${image}" alt="" />
    </div>
    <div class="omp__content">
      <h3>${OMPCoreUtilS.BR(title)}</h3>
      <p>${OMPCoreUtilS.BR(text)}</p>
      ${buttonTxt
        ? html`<a class="omp__button" href="${buttonLink}" target="${target}">${buttonTxt}</a>`
        : ''}
    </div>
  </div>`;
};

export const grid2ColumnsHTML = ({ items, title, customClass = '' }) => {
  return html` <div class="omp__section">
    ${title ? `<h3 class="omp__section-header">${OMPCoreUtilS.BR(title)}</h3>` : ''}
    <div class="omp__grid grid--2 ${customClass}">
      ${strMap(
        (item) => `
          <div class="omp__card">
            ${item.title ? `<h3 class="omp__section-header">${OMPCoreUtilS.BR(item.title)}</h3>` : ''}
            <div class="omp__image"><img src="${item.image}"  alt=""></div>
            ${
              item.text
                ? `
                  <div class="omp__flex-start">
                    ${
                      item.strong
                        ? html`<p class="omp__text--strong">${OMPCoreUtilS.BR(item.strong)}</p>`
                        : ''
                    }
                    <p class="omp__text">${OMPCoreUtilS.BR(item.text)}</p>
                  </div>
                `
                : ''
            }
          </div>  
        `,
        items,
      )}
    </div>
  </div>`;
};

export const grid3ColumnsHTML = ({ title, items, customClass = '' }) => {
  return html` <div class="omp__section ${customClass}">
    <h3 class="omp__section-header">${OMPCoreUtilS.BR(title)}</h3>
    <div class="omp__grid grid--3">
      ${strMap(
        (item) => `
          <div class="omp__card">
            <div class="omp__image"><img src="${item.image}"  alt=""></div>
            <div class="omp__text">
              ${item.strong ? `<span>${OMPCoreUtilS.BR(item.strong)}</span>` : ''}
              ${item.text}
            </div>
          </div>    
        `,
        items,
      )}
    </div>
  </div>`;
};

export const grid4ColumnsHTML = ({ title, items }) => {
  return html` <div class="omp__section">
    <h3 class="omp__section-header">${OMPCoreUtilS.BR(title)}</h3>
    <div class="omp__grid grid--4">
      ${strMap(
        (item) => `
          <div class="omp__card">
            <div class="omp__image">
              ${
                item.buttonUrl
                  ? `<a href="${item.buttonUrl}"><img src="${item.image}"  alt=""></a>`
                  : `<img src="${item.image}"  alt="">`
              }
              ${item.buttonTxt ? `<span>${item.buttonTxt}</span>` : ''}
            </div>
            <div class="omp__flex-start">
              <p class="omp__text--strong omp__text--num">${item.num}</p>            
              <div>
                <p class="omp__text--strong">${OMPCoreUtilS.BR(item.strong)}</p>
                <p class="omp__text">${OMPCoreUtilS.BR(item.text)}</p>              
              </div>
            </div>
          </div>    
        `,
        items,
      )}
    </div>
  </div>`;
};

export const ompMarqueeHTML = ({ title, isMobile, images }) => {
  return html`
    <div class="omp__section omp__section--marquee">
      <h3 class="omp__section-header omp__section-header--center">${OMPCoreUtilS.BR(title)}</h3>
      <div class="omp__marquee">
        ${images
          .map(
            (group, index) => `
          <div class="${index % 2 ? 'omp__justify-end' : 'omp__justify-start'}">
            <div class="omp__marquee-group ${index % 2 ? 'right' : 'left'}">
              ${strMap((img) => `<div><img src="${img}" alt=""/></div>`, group)} 
            </div>
            ${UtilS.htmlIf(
              isMobile,
              `<div class="omp__marquee-group ${index % 2 ? 'right' : 'left'}">
                ${strMap((img) => `<div><img src="${img}" alt="" /></div>`, group)}
              </div>`,
            )}
          </div>`,
          )
          .join('')}
      </div>
    </div>
  `;
};

export const ompAboutReviewsHTML = ({ title, moreUrl, moreLabel, isMobile, reviews }) => {
  return `
    <div class="omp__section">
      <h3 class="omp__section-header omp__section-header--between">
        ${OMPCoreUtilS.BR(title)}
        <a href="${moreUrl}" class="omp__section-header--more">${moreLabel}</a>        
      </h3>
      <div class="omp__reviews">
        ${
          !isMobile
            ? OMPCoreAtomTmplS.swiper({
                customClass: 'review-swiper',
                navigationClass: 'review-swiper-nav',
                arrow: true,
                children: OMPCoreAtomTmplS.swiperItems({
                  items: reviews,
                  customClass: 'review-item',
                  renderer: OMPCoreCellTmplS.verticalReviewTmpl,
                }),
              })
            : strMap(
                (review) =>
                  html`<div class="review-item">${OMPCoreCellTmplS.verticalReviewTmpl(review)}</div>`,
                reviews,
              )
        }
      </div>
    </div>
  `;
};

export const ompCompaniesHTML = ({ isMobile, title, companies = {} }) => {
  const {
    youtube = '//s3.marpple.co/files/u_29089/2023/4/original/6833845ebae80bc46d75f9b115942087145263fa13.png',
    adobe = '//s3.marpple.co/files/u_29089/2023/4/original/6f389511a9116ee627b04a7ada7af4333c35049214.png',
    samsung = '//s3.marpple.co/files/u_29089/2023/4/original/a921a8b95c4f124c0e97819fff243abb0d96cdd515.png',
    hyundai = '//s3.marpple.co/files/u_29089/2023/4/original/391aa9bf4577628a798e43bf7dc94533bd04f06d16.png',
    line = '//s3.marpple.co/files/u_29089/2023/4/original/5edcb477185eb161a6e13eade7d863fbdba2fbc117.png',
    mbc = '//s3.marpple.co/files/u_29089/2023/4/original/61845aa570d0d5ac7ab4d6fef857611459ec171119.png',
    sbs = '//s3.marpple.co/files/u_29089/2023/4/original/de157ebdaf8ef6b0e18d94ea01aa0b2bee8b8e3818.png',
    hanwha = '//s3.marpple.co/files/u_29089/2023/4/original/292548b889b7a31c8d19d4e0718f94705c4038b021.png',
    ebs = '//s3.marpple.co/files/u_29089/2023/4/original/b515443a10e49e41e7267297bcfbe6ad2e11c62b20.png',
    antenna = '//s3.marpple.co/files/u_29089/2023/4/original/a667b31cf6bdacb1d987fc4c4aad6ab6338689d722.png',
    kLeague = '//s3.marpple.co/files/u_29089/2023/4/original/c5f0a7d3b854950e1e788860e9055cc11f154a6323.png',
    saveTheChildren = '//s3.marpple.co/files/u_29089/2023/4/original/229036475de65a76c2f6fd9f2cbc28810ee32ba224.png',
    loopy = '//s3.marpple.co/files/u_29089/2023/4/original/c7a45d96c168c3b581608157deee5fc4d037b69525.png',
    inflearn = '//s3.marpple.co/files/u_29089/2023/4/original/82b04973c1ecd3aa81fbd22d422afe32f9d6352c26.png',
    lululala = '//s3.marpple.co/files/u_29089/2023/4/original/1e028fedd17d7e228aab0b5a0d486bedb1bc64e230.png',
    shopify = '//s3.marpple.co/files/u_29089/2023/4/original/64e310cec75280b6be43b9067351e78a9ee2939427.png',
    kolmar = '//s3.marpple.co/files/u_29089/2023/4/original/b1260e5de9d6b969b064625a9c62e8cdf27b881828.png',
    smilegate = '//s3.marpple.co/files/u_29089/2023/4/original/0cd32f7ab35c04ba1fa755d2a92a99901258cd2a29.png',
    metaComedy = '//s3.marpple.co/files/u_1504988/2023/6/original/89d41d6688a2fb4aaa6f98c734dd71ae1d07e1f72.png',
    nexon = '//s3.marpple.co/files/u_29089/2023/4/original/49ca4e252fb6bc384c90cfc8167282b3d3aa254e31.png',
    manwha = '//s3.marpple.co/files/u_1504988/2023/6/original/b92a78bbfab2e9c82cfca16826ba293a9c95976f1.png',
  } = companies;

  const rows = [
    UtilImageS.getResized70WebpImages([youtube, adobe, samsung, hyundai, line, nexon, sbs], 155),
    UtilImageS.getResized70WebpImages(
      [hanwha, ebs, antenna, kLeague, saveTheChildren, loopy, inflearn, lululala],
      155,
    ),
    UtilImageS.getResized70WebpImages([shopify, kolmar, smilegate, metaComedy, mbc, manwha], 155),
  ];

  return html`
    <div class="omp__section omp__section--marquee">
      <h3 class="omp__section-header omp__section-header--center">${OMPCoreUtilS.BR(title)}</h3>
      <div class="omp__marquee companies">
        ${rows
          .map((row, index) => {
            return html`
              <div class="${index % 2 ? 'omp__justify-start' : 'omp__justify-end'}">
                <div class="${index % 2 ? 'omp__marquee-group left' : 'omp__marquee-group right'}">
                  ${strMap((img) => `<div class="company"><img src="${img}" alt="" /></div>`, row)}
                </div>
                ${UtilS.htmlIf(
                  isMobile,
                  html`
                    <div class="${index % 2 ? 'omp__marquee-group left' : 'omp__marquee-group right'}">
                      ${strMap((img) => `<div class="company"><img src="${img}" alt=""/></div>`, row)}
                    </div>
                  `,
                )}
              </div>
            `;
          })
          .join('')}
      </div>
    </div>
  `;
};

export const ompCompanyGoodsHTML = ({ title, subTitle, images, btnLabel1, btnLabel2 }) => {
  return html` <div class="omp__section omp__goods">
    <h3 class="omp__section-header omp__section-header--center">${OMPCoreUtilS.BR(title)}</h3>
    <p class="omp__section-sub-header" data-pc-only>${OMPCoreUtilS.BR(subTitle)}</p>

    <div class="omp__grid grid--4">
      ${strMap((image) => html` <div class="omp__card"><img src="${image.src}" alt="" /></div>`, images)}
    </div>

    <div class="omp__goods--buttons">
      <a href="https://www.marpplebiz.help/" target="_blank" class="btn ">${btnLabel1}</a>
      <a href="mailto:biz@marpple.com" class="btn mail">${btnLabel2}</a>
    </div>
  </div>`;
};

export const ompFAQHTML = ({ title, faq }) => {
  return html`
    <section class="omp__section omp__faq" data-sectioning="true">
      <h3 class="omp__section-header">${OMPCoreUtilS.BR(title)}</h3>

      <div class="omp__faq-list">
        ${strMap(
          (_faq) => html`
            <div class="omp__faq-item">
              <details data-details="true">
                <summary class="omp__faq-q">
                  <span title="${_faq.q}">${_faq.q}</span>
                  <span class="icon" data-icon="true"></span>
                </summary>
                <div class="omp__faq-a">${strMap((a) => `<p>${OMPCoreUtilS.BR(a)}</p>`, _faq.a)}</div>
              </details>
            </div>
          `,
          faq,
        )}
      </div>
    </section>
  `;
};

export const ompGroupOrderItem = ({
  src = '',
  srcset = [],
  title = '',
  desc = '',
  linkUrl = '',
  linkLabel = '',
}) => {
  return html`
    <div class="group-order-item">
      <div class="image">
        <img src="${src}" alt="${srcset.join(',')}" />
      </div>
      <div class="text">
        <h4>${title}</h4>
        <p>${desc}</p>
        <a href="${linkUrl}">${linkLabel}</a>
      </div>
    </div>
  `;
};

export const recommendByPriceHTML = ({ isMobile }) => {
  const pcImages = {
    kr: {
      1: '//s3.marpple.co/files/u_3040850/2023/10/original/a5f0f684c85b4cd5d997809431fa032ed7d5b6771.png',
      2: '//s3.marpple.co/files/u_3040850/2023/10/original/d8631388f6cf60051ea627fbefe48b0a66d8762a1.png',
      3: '//s3.marpple.co/files/u_3040850/2023/10/original/fcdaf1e8da97ff94ca11360a5227ba0e32489ebc1.png',
      4: '//s3.marpple.co/files/u_3040850/2023/10/original/782db38dda241e4d536001cb776ec762d11fb1291.png',
      5: '//s3.marpple.co/files/u_3040850/2023/10/original/901ff3d2e4dfcc3064b63e1ba6df8c124c3e922d1.png',
    },
    en: {
      1: '//s3.marpple.co/files/u_2659171/2023/10/original/6d53923155dd65a23c575661dbc57c2e9b57bb821.png',
      2: '//s3.marpple.co/files/u_2659171/2023/10/original/f3ce43d52b4a9e5b65e1cdf9d62d4063fea0b31d1.png',
      3: '//s3.marpple.co/files/u_2659171/2023/10/original/28838ae2d3623522efece470ce4af8a7e44cdce41.png',
      4: '//s3.marpple.co/files/u_2659171/2023/10/original/400cf8f57c4ba2d13bd1e3e55ec748a2e1f759ee1.png',
      5: '//s3.marpple.co/files/u_2659171/2023/10/original/01c4a3e3fc4a7fa2df75709db526cb08faa0876c1.png',
    },
    jp: {
      1: '//s3.marpple.co/files/u_2659171/2023/10/original/ed27c0972e0fb119d786b1e952861ed92c4e2f001.png',
      2: '//s3.marpple.co/files/u_2659171/2023/10/original/c7fcfd36bf1723113455fb2525a319411a69369e1.png',
      3: '//s3.marpple.co/files/u_2659171/2023/10/original/132f573a4106dc115cb977f5c43ec738308678b41.png',
      4: '//s3.marpple.co/files/u_2659171/2023/10/original/fe288742613c2563cdafddf3b9c7fe73b34a9b701.png',
      5: '//s3.marpple.co/files/u_2659171/2023/10/original/d11fa4608fdbdb8ed226db9f97a697ce2717dbbc1.png',
    },
  };

  const moImages = {
    kr: {
      1: '//s3.marpple.co/files/u_3040850/2023/10/original/df597a8a43cb1f973a378a9ce0890fdba41388da1.png',
      2: '//s3.marpple.co/files/u_2659171/2023/9/original/86bec08c759cb90371c14ac4f9900ddb08633b991.png',
      3: '//s3.marpple.co/files/u_2659171/2023/9/original/b58d9cf3354f805e80c3318827323724f50cc1fe1.png',
      4: '//s3.marpple.co/files/u_2659171/2023/9/original/9c8b1c3fa8f58eb9191907df78b7defc5b42dbf61.png',
      5: '//s3.marpple.co/files/u_2659171/2023/9/original/446aa0cfc412a450135fee8c36ccc574152113611.png',
    },
    en: {
      1: '//s3.marpple.co/files/u_2659171/2023/10/original/6d53923155dd65a23c575661dbc57c2e9b57bb821.png',
      2: '//s3.marpple.co/files/u_2659171/2023/10/original/f3ce43d52b4a9e5b65e1cdf9d62d4063fea0b31d1.png',
      3: '//s3.marpple.co/files/u_2659171/2023/10/original/28838ae2d3623522efece470ce4af8a7e44cdce41.png',
      4: '//s3.marpple.co/files/u_2659171/2023/10/original/400cf8f57c4ba2d13bd1e3e55ec748a2e1f759ee1.png',
      5: '//s3.marpple.co/files/u_2659171/2023/10/original/01c4a3e3fc4a7fa2df75709db526cb08faa0876c1.png',
    },
    jp: {
      1: '//s3.marpple.co/files/u_2659171/2023/10/original/ed27c0972e0fb119d786b1e952861ed92c4e2f001.png',
      2: '//s3.marpple.co/files/u_2659171/2023/10/original/c7fcfd36bf1723113455fb2525a319411a69369e1.png',
      3: '//s3.marpple.co/files/u_2659171/2023/10/original/132f573a4106dc115cb977f5c43ec738308678b41.png',
      4: '//s3.marpple.co/files/u_2659171/2023/10/original/fe288742613c2563cdafddf3b9c7fe73b34a9b701.png',
      5: '//s3.marpple.co/files/u_2659171/2023/10/original/d11fa4608fdbdb8ed226db9f97a697ce2717dbbc1.png',
    },
  };
  return html`
    <section class="omp__section omp__recommend">
      <h3 class="omp__section-header">
        ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_header_mo'), TT('biz::kit::kit_header_pc'))}
      </h3>
      <div class="omp__recommend-list">
        <div class="omp__recommend-item">
          ${UtilS.htmlIfElse(
            isMobile,
            html`<img
              src="${UtilImageS.getResized70Webp(moImages[T.lang][1], T.lang === 'kr' ? 686 : 2418)}"
              alt="${TT('biz::kit::kit_img_alt_1')}"
            />`,
            html`<img
              src="${UtilImageS.getResized70Webp(pcImages[T.lang][1], 2418)}"
              alt="${TT('biz::kit::kit_img_alt_1')}"
            />`,
          )}
          <div class="omp__recommend-info">
            <div class="omp__recommend-left">
              <div class="omp__recommend-title">
                ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_title_mo_1'), TT('biz::kit::kit_title_pc_1'))}
              </div>
            </div>
            <div class="omp__recommend-right">
              <div class="omp__recommend-summary">${TT('biz::kit::kit_summary_1')}</div>
              <div class="omp__recommend-desc">
                ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_desc_mo_1'), TT('biz::kit::kit_desc_pc_1'))}
              </div>
              <div class="omp__recommend-point">
                ${UtilS.htmlIf(!isMobile, html`<span>${TT('biz::kit::kit_point')}</span>`)}
                <ol>
                  ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_list_mo_1'), TT('biz::kit::kit_list_pc_1'))}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="omp__recommend-item">
          ${UtilS.htmlIfElse(
            isMobile,
            html`<img
              src="${UtilImageS.getResized70Webp(moImages[T.lang][2], T.lang === 'kr' ? 686 : 2418)}"
              alt="${TT('biz::kit::kit_img_alt_2')}"
            />`,
            html` <img
              src="${UtilImageS.getResized70Webp(pcImages[T.lang][2], 2418)}"
              alt="${TT('biz::kit::kit_img_alt_2')}"
            />`,
          )}
          <div class="omp__recommend-info">
            <div class="omp__recommend-left">
              <div class="omp__recommend-title">
                ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_title_mo_2'), TT('biz::kit::kit_title_pc_2'))}
              </div>
            </div>
            <div class="omp__recommend-right">
              <div class="omp__recommend-summary">${TT('biz::kit::kit_summary_2')}</div>
              <div class="omp__recommend-desc">
                ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_desc_mo_2'), TT('biz::kit::kit_desc_pc_2'))}
              </div>
              <div class="omp__recommend-point">
                ${UtilS.htmlIf(!isMobile, html`<span>${TT('biz::kit::kit_point')}</span>`)}
                <ol>
                  ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_list_mo_2'), TT('biz::kit::kit_list_pc_2'))}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="omp__recommend-item">
          ${UtilS.htmlIfElse(
            isMobile,
            html` <img
              src="${UtilImageS.getResized70Webp(moImages[T.lang][3], T.lang === 'kr' ? 686 : 2418)}"
              alt="${TT('biz::kit::kit_img_alt_3')}"
            />`,
            html`<img
              src="${UtilImageS.getResized70Webp(pcImages[T.lang][3], 2418)}"
              alt="${TT('biz::kit::kit_img_alt_3')}"
            />`,
          )}
          <div class="omp__recommend-info">
            <div class="omp__recommend-left">
              <div class="omp__recommend-title">
                ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_title_mo_3'), TT('biz::kit::kit_title_pc_3'))}
              </div>
            </div>
            <div class="omp__recommend-right">
              <div class="omp__recommend-summary">${TT('biz::kit::kit_summary_3')}</div>
              <div class="omp__recommend-desc">
                ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_desc_mo_3'), TT('biz::kit::kit_desc_pc_3'))}
              </div>
              <div class="omp__recommend-point">
                ${UtilS.htmlIf(!isMobile, html`<span>${TT('biz::kit::kit_point')}</span>`)}
                <ol>
                  ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_list_mo_3'), TT('biz::kit::kit_list_pc_3'))}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="omp__recommend-item">
          ${UtilS.htmlIfElse(
            isMobile,
            html` <img
              src="${UtilImageS.getResized70Webp(moImages[T.lang][4], T.lang === 'kr' ? 686 : 2418)}"
              alt="${TT('biz::kit::kit_img_alt_4')}"
            />`,
            html`<img
              src="${UtilImageS.getResized70Webp(pcImages[T.lang][4], 2418)}"
              alt="${TT('biz::kit::kit_img_alt_4')}"
            /> `,
          )}
          <div class="omp__recommend-info">
            <div class="omp__recommend-left">
              <div class="omp__recommend-title">
                ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_title_mo_4'), TT('biz::kit::kit_title_pc_4'))}
              </div>
            </div>
            <div class="omp__recommend-right">
              <div class="omp__recommend-summary">${TT('biz::kit::kit_summary_4')}</div>
              <div class="omp__recommend-desc">
                ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_desc_mo_4'), TT('biz::kit::kit_desc_pc_4'))}
              </div>
              <div class="omp__recommend-point">
                ${UtilS.htmlIf(!isMobile, html`<span>${TT('biz::kit::kit_point')}</span>`)}
                <ol>
                  ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_list_mo_4'), TT('biz::kit::kit_list_pc_4'))}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="omp__recommend-item">
          ${UtilS.htmlIfElse(
            isMobile,
            html`<img
              src="${UtilImageS.getResized70Webp(moImages[T.lang][5], T.lang === 'kr' ? 686 : 2418)}"
              alt="${TT('biz::kit::kit_img_alt_5')}"
            />`,
            html`<img
              src="${UtilImageS.getResized70Webp(pcImages[T.lang][5], 2418)}"
              alt="${TT('biz::kit::kit_img_alt_5')}"
            />`,
          )}
          <div class="omp__recommend-info">
            <div class="omp__recommend-left">
              <div class="omp__recommend-title">
                ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_title_mo_5'), TT('biz::kit::kit_title_pc_5'))}
              </div>
            </div>
            <div class="omp__recommend-right">
              <div class="omp__recommend-summary">${TT('biz::kit::kit_summary_5')}</div>
              <div class="omp__recommend-desc">
                ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_desc_mo_5'), TT('biz::kit::kit_desc_pc_5'))}
              </div>
              <div class="omp__recommend-point">
                ${UtilS.htmlIf(!isMobile, html`<span>${TT('biz::kit::kit_point')}</span>`)}
                <ol>
                  ${UtilS.htmlIfElse(isMobile, TT('biz::kit::kit_list_mo_5'), TT('biz::kit::kit_list_pc_5'))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  `;
};

export const kitIntroHTML = ({ title, image, set, text, target = '', buttonLink, buttonTxt }) => {
  return html` <div class="omp__kit-intro">
    <div class="omp__image">
      <img src="${image}" alt="" />
    </div>
    <div class="omp__content">
      <h3>${OMPCoreUtilS.BR(title)}</h3>
      <p>${OMPCoreUtilS.BR(text)}</p>
      ${buttonTxt
        ? html`<a class="omp__button" href="${buttonLink}" target="${target}">${buttonTxt}</a>`
        : ''}
    </div>
  </div>`;
};
