import { ConfigSentryF } from '../../../Config/Sentry/F/Function/module/ConfigSentryF.js';

export const api = {
  MakerDebugPost: (product, e, category, category_item) => {
    if (!product) return;
    if (!window.window.ENV.is_prod) {
      console.error(e);
      return;
    }
    try {
      if (!product?._?.base_product?.is_use) return;
      $.post('/@api/maker_debug', {
        base_product_id: product.base_product_id,
        base_product_color_id: product.base_product_color_id,
        base_product_size_id: product.base_product_size_id,
        product_faces2:
          typeof product.product_faces2 === 'object' ? JSON.stringify(product.product_faces2) : null,
        error_name: e?.name,
        error_message: e?.message,
        error_stack: e?.stack,
        category,
        category_item,
      });
    } catch (e) {
      ConfigSentryF.error(e);
    }
  },
};
