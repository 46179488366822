import axios from 'axios';

function blendingMath(dest, src, idx, blendingMode) {
  for (let i = 0; i < 3; i++) {
    const dest_norm = dest[idx + i] / 255;
    const src_norm = src[idx + i] / 255;
    switch (blendingMode) {
      case 'multiply':
        dest[idx + i] = dest_norm * src_norm * 255;
        break;
      case 'screen':
        dest[idx + i] = (1 - (1 - dest_norm) * (1 - src_norm)) * 255;
        break;
    }
  }
}

export function imageBlender(dest_canvas, src_img, blendingMode) {
  const src_canvas = document.createElement('canvas');
  src_canvas.width = src_img.width;
  src_canvas.height = src_img.height;
  const src_ctx = src_canvas.getContext('2d');
  src_ctx.drawImage(src_img, 0, 0);
  const src_img_data = src_ctx.getImageData(0, 0, src_canvas.width, src_canvas.height).data;

  const dest_ctx = dest_canvas.getContext('2d');
  const width = dest_canvas.width;
  const height = dest_canvas.height;
  const dest_img_data = dest_ctx.getImageData(0, 0, width, height);
  for (let y = 0, len = height; y < len; y++) {
    for (let x = 0, len = width; x < len; x++) {
      const idx = (x + y * width) * 4;
      if (src_img_data[idx + 3] === 0) continue;
      blendingMath(dest_img_data.data, src_img_data, idx, blendingMode);
    }
  }
  dest_ctx.clearRect(0, 0, width, height);
  dest_ctx.putImageData(dest_img_data, 0, 0);
  return dest_canvas;
}

export function imageBlender2(dest_canvas, src_img, blendingMode) {
  const src_canvas = document.createElement('canvas');
  src_canvas.width = src_img.width;
  src_canvas.height = src_img.height;
  const src_ctx = src_canvas.getContext('2d');
  src_ctx.drawImage(src_img, 0, 0);
  const src_img_data = src_ctx.getImageData(0, 0, src_canvas.width, src_canvas.height).data;

  const dest_ctx = dest_canvas.getContext('2d');
  const width = dest_canvas.width;
  const height = dest_canvas.height;
  const dest_img_data = dest_ctx.getImageData(0, 0, width, height);
  for (let y = 0, len = height; y < len; y++) {
    for (let x = 0, len = width; x < len; x++) {
      const idx = (x + y * width) * 4;
      if (src_img_data[idx + 3] === 0) continue;
      blendingMath(dest_img_data.data, src_img_data, idx, blendingMode);
    }
  }
  dest_ctx.clearRect(0, 0, width, height);
  dest_ctx.putImageData(dest_img_data, 0, 0);
  return dest_canvas;
}

export async function selectCompositeTemplatesList(base_product_id) {
  const { data } = await axios.get('/@api/composite/select_full_composite_templates', {
    params: { base_product_id },
  });
  return data;
}

export async function createNonTexturedImageByBG(value) {
  const { data } = await axios.post('/@fileUpload/composite/create_non_textured_image', value);
  return data;
}

export async function selectCompositeTemplate(id) {
  const { data } = await axios.get('/@api/composite/composite_template', {
    params: { id },
  });
  return data;
}

export async function updateCompositeTemplate(value) {
  const { data } = await axios.post('/@fileUpload/composite/update_composite_template', value);
  return data;
}

export async function updateCropData(value) {
  const { data } = await axios.post('/@api/composite/update_crop_data', value);
  return data;
}

export async function updateCompositeMask(value) {
  const { data } = await axios.post('/@fileUpload/composite/update_composite_mask', value);
  return data;
}

export async function getShadeUrlForTexture(value) {
  const { data } = await axios.post('/@fileUpload/composite/callTextureMaster', value);
  return data;
}

export async function updateCompositeTexture(value) {
  const { data } = await axios.post('/@api/composite/update_composite_texture', value);
  return data;
}

export async function updateCompositeFaces(value) {
  const { data } = await axios.post('/@api/composite/update_composite_faces', value);
  return data;
}

export async function insertCompositeFaces(value) {
  const { data } = await axios.post('/@api/composite/insert_composite_faces', value);
  return data;
}

export async function updateCompositeFacesByCompositeMaskId(value) {
  const { data } = await axios.post('/@api/composite/update_composite_faces_by_mask_id', value);
  return data;
}

export async function selectAssoCompositeTemplate(id) {
  const { data } = await axios.get('/@api/composite/select_assoc_composite_template', {
    params: { id },
  });
  return data;
}

export async function updateDateCompositeTemplate(ct_id) {
  await axios.post('/@api/composite/update_updated_at', { id: ct_id });
}
