export default function (lo) {
  if (!$1('.kakao_surem_logs')) return;

  $.frame({
    frame_name: 'df.kakao_surem_logs',
  });

  $.frame.defn_frame({
    frame_name: 'df.kakao_surem_logs',
    page_name: 'df.kakao_surem_logs_page',
    // hide_frame_button_type: 'X',
    appended: function () {},
  });

  $.frame.defn_page({
    page_name: 'df.kakao_surem_logs_page',
    tabs: [
      {
        tab_name: 'df.kakao_surem_logs_tab',
        appended: __(function (tab) {
          return tab;
        }),
        infinite: function (don_tab) {
          return $.frame.infi($.find1(don_tab, '#log_body'), {
            limit: 50,
            scroll_target: G.is_pc_size() ? window : window,
            template: function (log) {
              return _p.t(
                'log',
                '\
              .tr\
                .td {{log.caller_number}}\
                .td {{log.receive_number}}\
                .td.text \
                  sp {{log.text}}\
                .td {{moment(log.created_at).format("YYYY-MM-DD HH:mm")}}\
                .td {{log.return_value}}\
            ',
              )(log);
            },
            data_func: function (offset, limit) {
              return _p.go(
                $.get('/@api/sms_logs', {
                  offset,
                  limit,
                }),
                function (logs) {
                  window.box.push.apply(null, ['logs'].concat(logs));
                  return logs;
                },
              );
            },
          });
        },
      },
    ],
  });
}
