import { includes } from 'fxjs/es';
import { ComponentsInputConstantS } from '../../S/Constant/module/ComponentsInputConstantS.js';

export const focusToInput = ($input) => {
  if (!$input) return new Error('input element is required.');

  setTimeout(() => {
    const value = $input.value;
    $input.value = '';
    $input.focus();
    $input.value = value;
  }, 100);
};

export const isSpecialKey = (key_code) => {
  if (!key_code) return false;
  return includes(key_code, Object.values(ComponentsInputConstantS.KEY_CODE));
};

/**
 * @param {HTMLInputElement} inputEl
 * @return {string}
 */
export const getSafeInputValue = (inputEl) => {
  if (!(inputEl instanceof HTMLInputElement)) {
    console.warn('inputEl이 유효하지 않습니다.');
    return '';
  }
  return (inputEl?.value || '').trim();
};
