import { compact, deepFlat, every, filter, find, go, head, identity, map, unique } from 'fxjs/es';
import { drawProductFace } from '../../../../../../modules/Maker/F/draw_product_faces.js';
import { UtilArrayS } from '../../../../../../modules/Util/Array/S/Function/module/UtilArrayS.js';
import { createCanvasElement } from '../../../../../../modules/Canvas/S/util.js';
import {
  calculatePrintableFileShiboriCm,
  calculateShiboriForDesign,
  getTracedDesign,
} from '../../../../../../modules/Df/Projection/List/F/shibori.js';
import { UtilObjS } from '../../../../../../modules/Util/Object/S/Function/module/UtilObjS.js';

// @description 디자인 시안을 기반으로 pf2 정보 핸들링
export async function getProductFacesWithDesign({ pf2, bp_sizes_info }) {
  return await go(
    pf2,
    map(async (pf) => {
      return {
        ...UtilObjS.deepClone(pf),
        canvas: await getPfCanvas({ pf }),
        selected_printable_file: null,
        bp_sizes: map(
          (bp_size) => ({
            ...UtilObjS.deepClone(bp_size),
            shibori_cm: calculateShiboriForDesign({ pf, base_product_size_id: bp_size.id }),
          }),
          bp_sizes_info,
        ),
      };
    }),
    compact,
  );
}

// @description 인쇄용 시안을 기반으로 pf2 정보 핸들링
export async function getProductFacesWithPrintables({
  printable_pf2,
  bp_sizes_info,
  selected_press_type,
  is_include_all_printable_files,
}) {
  return await go(
    printable_pf2,
    map(async (_pf) => {
      const pf = UtilObjS.deepClone(_pf);
      const files_with_selected_press_type = getSelectedPressTypePrintableFiles({ pf, selected_press_type });
      if (UtilArrayS.isArrayOk(files_with_selected_press_type)) {
        // 선택된 press type 에 해당하는 printable file 이 존재하는 "면" 만 처리
        if (is_include_all_printable_files === false) {
          const linked_designs = getDesignsLinkedWithPrintableFiles({ pf, files_with_selected_press_type });

          /* 인쇄용 시안이 존재하는 디자인만 캔버스에 적용 */
          UtilArrayS.isArrayOk(linked_designs) && (pf.designs = linked_designs);
        }

        const selected_printable_file = head(files_with_selected_press_type);

        return {
          ...pf,
          selected_printable_file,
          canvas: await getPfCanvas({ pf }),
          bp_sizes: map((bp_size) => {
            const shibori_cm = calculatePrintableFileShiboriCm({
              pf,
              bp_size_id: bp_size.id,
              file: selected_printable_file,
            });

            return {
              ...UtilObjS.deepClone(bp_size),
              shibori_cm,
            };
          }, bp_sizes_info),
        };
      }
    }),
    compact,
  );
}

export function getProjectionBpSizesInfo({ projection_bp_sizes, pf2 }) {
  const size_faces = pf2[0].size_faces;

  return go(
    projection_bp_sizes,
    map((bp_size) => {
      const size_face = find((sf) => {
        return sf.base_product_size_id === bp_size.id;
      }, size_faces);
      if (size_face) {
        return { ...UtilObjS.deepClone(bp_size), ...size_face };
      }
    }),
  );
}

function getDesignsLinkedWithPrintableFiles({ pf, files_with_selected_press_type }) {
  const trackable_cids = map((file) => file.cid, files_with_selected_press_type);

  return filter((design) => {
    return trackable_cids.includes(design.cid);
  }, pf.designs);
}

const getPfCanvas = async ({ pf, size = 1440 }) => {
  G.mp.maker.is_auto_print = true;
  const canvas = createCanvasElement({ width: size, height: size });
  await drawProductFace(canvas, pf, false, pf.base_product_size_id, true, false, false);
  G.mp.maker.is_auto_print = false;
  return canvas;
};

// @description: 업로드 된 인쇄용 시안들 중 특정 press type 으로 등록된 인쇄용 시안 정보 가져오기 (하나만 있다고 가정)
const getSelectedPressTypePrintableFiles = ({ pf, selected_press_type }) => {
  return filter((file) => file.press_type_name === selected_press_type, pf.printable_files);
};

export function getAllPrintableFilesPressTypes({ pf2 }) {
  if (pf2 == null) return null;

  return go(
    pf2,
    map((pf) => map((file) => file?.press_type_name, pf.printable_files)),
    deepFlat,
    unique,
    filter((_) => _ !== '선택 안함'),
    compact,
  );
}

//@description 각각의 면에 선택된 press type 으로 등록되어 있는 인쇄용 시안 파일이 모두 design 을 추적할 수 있는지 판단
export function isAllPrintableFilesTrackable({ printable_pf2, selected_press_type }) {
  return go(
    printable_pf2,
    map((pf) => {
      const selected_press_type_files = getSelectedPressTypePrintableFiles({ pf, selected_press_type });
      return UtilArrayS.isArrayOk(selected_press_type_files) ? { pf, selected_press_type_files } : null;
    }),
    compact,
    map(({ pf, selected_press_type_files }) =>
      every((file) => {
        return getTracedDesign({ pf, file });
      }, selected_press_type_files),
    ),
    every(identity),
  );
}
