import {
  each,
  equals,
  filter,
  flatten,
  go,
  head,
  identity,
  map,
  max,
  min,
  pick,
  pipe,
  reject,
  sel,
  selEq,
  some,
  take,
  take1,
  values,
} from 'fxjs/es';
import { legacyHtml } from '../../Util/S/Function/util.js';
import { getBaseProductInMaker, getFcanvass, getProductFaces2InMaker } from './getSth.js';
import { makeTrimCanvas, trimCanvasSize } from './canvas_trim.js';
import { arosegida_reject_fonts, isAirPodCase, isArosegidaBp, isCateItemPhoneCase } from './categorize.js';
import { createCanvasElement, makeCanvasCutByLocation, makeImageCanvasByRatio } from '../../Canvas/S/util.js';
import { getCvObj, makeCvObjsVisibleFalse, makeCvObjsVisibleTrue } from './Fcanvas/cv_object.js';
import { $qs } from 'fxdom/es';
import { NewMakerCvObjectCvBackgroundConstantS } from '../../NewMaker/CvObject/CvBackground/S/Constant/module/NewMakerCvObjectCvBackgroundConstantS.js';
import { NewMakerUtilF } from '../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { NewMakerBaseProductsEmbroideryConstantS } from '../../NewMaker/BaseProducts/Embroidery/S/Constant/module/NewMakerBaseProductsEmbroideryConstantS.js';
import { NewMakerPropertyBpfF } from '../../NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';

function rotateVector(vector, angle) {
  const radian = (angle * Math.PI) / 180;
  const sin = Math.sin(radian);
  const cos = Math.cos(radian);
  const rx = vector.x * cos - vector.y * sin;
  const ry = vector.x * sin + vector.y * cos;
  return {
    x: rx,
    y: ry,
  };
}

export const getRotatePoint = function (standard_point, point, angle) {
  const x = point.x - standard_point.x;
  const y = point.y - standard_point.y;
  const v = rotateVector({ x, y }, angle);
  return {
    x: standard_point.x + v.x,
    y: standard_point.y + v.y,
  };
};

export function getAngleFromXY(x, y) {
  return Math.atan(y / x) * (180 / Math.PI);
}

export function getFwidth({ scaleX, width, strokeWidth = 0 }) {
  return scaleX * (width + strokeWidth);
}

export function getFheight({ scaleY, height, strokeWidth = 0 }) {
  return scaleY * (height + strokeWidth);
}

export const getAllPoints = function (cv_obj) {
  const { top, left, angle } = cv_obj;
  const standard_point = { x: left, y: top };
  return {
    tl: standard_point,
    tr: getRotatePoint(
      standard_point,
      { x: standard_point.x + getFwidth(cv_obj), y: standard_point.y },
      angle,
    ),
    bl: getRotatePoint(
      standard_point,
      { x: standard_point.x, y: standard_point.y + getFheight(cv_obj) },
      angle,
    ),
    br: getRotatePoint(
      standard_point,
      {
        x: standard_point.x + getFwidth(cv_obj),
        y: standard_point.y + getFheight(cv_obj),
      },
      angle,
    ),
  };
};

export const getCvDesignsSize = function (cv_objs) {
  return go(
    cv_objs,
    map(getAllPoints),
    map(values),
    flatten,
    (arr_xy) => {
      const arr_x = map((xy) => xy.x, arr_xy);
      const arr_y = map((xy) => xy.y, arr_xy);
      const min_x = min(arr_x);
      const max_x = max(arr_x);
      const min_y = min(arr_y);
      const max_y = max(arr_y);
      return {
        tl: { x: min_x, y: min_y },
        tr: { x: max_x, y: min_y },
        bl: { x: min_x, y: max_y },
        br: { x: max_x, y: max_y },
      };
    },
    function (point) {
      return {
        bounding_rect: {
          top: point.tl.y,
          left: point.tl.x,
          width: point.tr.x - point.tl.x,
          height: point.bl.y - point.tl.y,
        },
        center_point: {
          x: (point.tl.x + point.tr.x) / 2,
          y: (point.bl.y + point.tl.y) / 2,
        },
      };
    },
  );
};

export const is_temp_group = (obj) => obj._objects && obj._data.cv_type !== 'cv_group';

export const in_temp_group = (obj) => sel('group', obj) && !obj.group._data.cv_type;

export const init_is_size_v = () => {
  const base_product = getBaseProductInMaker();
  $.attr($1('html'), {
    is_size_view_out: isCateItemPhoneCase() || base_product.is_bps_hidden,
  });
};

export const get_coord = (coord) => {
  return {
    top: Math.floor(coord.top),
    left: Math.floor(coord.left),
    right: Math.ceil(coord.left + coord.width),
    bottom: Math.ceil(coord.top + coord.height),
  };
};

export const makeLeftForBeingInCenter = (target, bg_target) => {
  return bg_target / 2 - target / 2;
};

function selSameAttrCvObjInMaker(designs, attr, value) {
  return filter((o) => o[attr] === value, designs);
}

function setAttrWithoutMeInMaker(designs, attr, value) {
  go(
    designs,
    each((o) => (o[attr] = value)),
  );
}

export const selDesignedCanvass = () =>
  go(
    box.sel('maker->canvass'),
    take(G.mp.maker.get_bpcfs_length()),
    filter((c) => G.mp.maker.designs(c).length),
    (c) => (c.length > 0 ? c : take1(box.sel('maker->canvass'))),
  );

G.mp.maker = G.mp.maker || {};
G.mp.maker.alert_no_data = false;
function isWrongCvTextImageInMaker(cv_text_image) {
  const text = sel('_data.text_info.text', cv_text_image);
  if (text === undefined) return false;

  if (sel('_data.no_data', cv_text_image)) {
    if (!G.mp.maker.alert_no_data) {
      G.mp.maker.alert_no_data = true;
      $.alert(
        '글꼴 또는 디자인이 새 마플과 함께 변경 되었습니다.\n 원래 디자인과 다를 수 있습니다.\n 뉴 마플에서 새롭게 디자인 해보세요~!',
      );
    }
    return true;
  }
  return text === '' || text.charCodeAt() === 65039;
}

export const rejectWrongCvTextImage = reject(isWrongCvTextImageInMaker);

export const uploadMakerOneOgImage = () =>
  go(
    selDesignedCanvass(),
    head,
    (c) => c.lowerCanvasEl,
    (c) => makeTrimCanvas(c, 1080),
    (c) => makeImageCanvasByRatio(c, 1080, '#ffffff', 1 / 2, 1 / 3, 4 / 5),
    (c) =>
      $.uploadFileToOnlyOriginalUrl({
        url: c.toDataURL(),
        original_name: 'product_color_og',
        image_type: 'JPEG',
      }),
    sel('url'),
  );

/**
 * @return {HTMLImageElement}
 */
export function makeImageFromUrl(url) {
  const image = new Image();
  image.crossOrigin = 'Anonymous';
  url =
    url.indexOf('base64') != -1
      ? url
      : url.includes('?')
      ? url + '&canvas=v3'
      : url + G.mp.maker.cross_origin_query_str;
  image.src = url;
  return new Promise(function (resolve) {
    image.onload = function () {
      resolve(image);
    };
  });
}

export const makeOgImageUrlFromUrl = (url) =>
  go(
    url,
    makeImageFromUrl,
    (img) => makeImageCanvasByRatio(img, 1080, '#ffffff', 1 / 2, 1 / 3, 4 / 5),
    (c) =>
      $.uploadFileToOnlyOriginalUrl({
        url: c.toDataURL(),
        original_name: 'product_color_og',
        image_type: 'JPEG',
      }),
    sel('url'),
  );

const _beforeunload = (event) => {
  // 표준에 따라 기본 동작 방지
  event.preventDefault();
  // Chrome에서는 returnValue 설정이 필요함
  event.returnValue = T('If you move from this page, the design you created will disappear.');
  return T('If you move from this page, the design you created will disappear.');
};

export const onbeforeunload_event_on = function (state_index) {
  if ($1('#dream_factory')) return;
  if (
    state_index > 0 &&
    some((v) => v.designs && v.designs.length, box.sel('maker->product_color->product_faces2->value'))
  ) {
    window.addEventListener('beforeunload', _beforeunload);
  } else {
    window.removeEventListener('beforeunload', _beforeunload);
  }
};

export const onbeforeunload_event_on_vector_editor = function (is_started_design) {
  if (is_started_design) {
    window.addEventListener('beforeunload', _beforeunload);
  } else {
    window.removeEventListener('beforeunload', _beforeunload);
  }
};

export const onbeforeunload_event_off = function () {
  if ($1('#dream_factory')) return;
  window.removeEventListener('beforeunload', _beforeunload);
};
export const getImageSizeInFabricCanvas = function (f_canvas, cv_obj, trim_strength) {
  const last_visible = cv_obj.visible;
  cv_obj.visible = true;
  const before_status_of_globalCompositeOperation = cv_obj.globalCompositeOperation;
  cv_obj.globalCompositeOperation = 'source-over';
  const designs = go(
    f_canvas._objects,
    reject((cv_obj2) => cv_obj2 === cv_obj),
    (cv_objs) => selSameAttrCvObjInMaker(cv_objs, 'visible', true),
  );

  setAttrWithoutMeInMaker(designs, 'visible', false);
  f_canvas.renderAll();

  const img_size = trimCanvasSize(f_canvas.lowerCanvasEl, trim_strength);
  cv_obj.visible = last_visible;
  cv_obj.globalCompositeOperation = before_status_of_globalCompositeOperation;
  setAttrWithoutMeInMaker(designs, 'visible', true);
  f_canvas.renderAll();
  const ratio = G.mp.maker.CANVAS_WIDTH_ORIGIN / f_canvas.lowerCanvasEl.width;
  return _p.map_object(img_size, function (v) {
    return v * ratio;
  });
};
export function createFcanvas(el_canvas) {
  const fcanvas = new fabric.Canvas(el_canvas || document.createElement('canvas'), {
    preserveObjectStacking: true,
    uniScaleKey: null,
    enableRetinaScaling: false,
    width: G.mp.maker.CANVAS_WIDTH,
    height: G.mp.maker.CANVAS_HEIGHT,
    selectionColor: NewMakerUtilF.getMakerBoxColor(0.2),
    selectionBorderColor: 'rgba(255, 255, 255, 0.3)',
  });
  fcanvas.getContext().imageSmoothingQuality = 'high';
  return fcanvas;
}

// export const addImgLineInFabricCanvasForPreview = function(canvas) {
//   return _addImgLineInFabricCanvas(canvas, G.mp.maker.cv_mask2(canvas), true, true, 'red');
// };

// function _addImgLineInFabricCanvas(canvas, img, has_line, visible, color_code = '#b7c1cc') {
//   const img_size = has_line
//     ? getImageSizeInFabricCanvas(canvas, img, 50)
//     : { top: 0, bottom: 0, left: 0, right: 0, width: 0, height: 0 };
//   const img_line_attr = _.defaults(
//     plusStrokeWidth({
//       left: img_size.left,
//       top: img_size.top,
//       width: img_size.width,
//       height: img_size.height
//     }),
//     {
//       selectable: false,
//       evented: false,
//       visible: visible,
//       stroke: color_code,
//       rx: 40,
//       ry: 40,
//       globalCompositeOperation: 'source-over',
//       _data: { cv_type: 'cv_img_line', is_not_design: true, nscreened: true }
//     },
//     G.mp.maker.IMG_LINE
//   );
//   if (isSquarePrintArea()) {
//     img_line_attr.rx = 0;
//     img_line_attr.ry = 0;
//   }
//   const img_line = new fabric.Rect(img_line_attr);
//   canvas.add(img_line);
//   img_line.bringToFront();
//   canvas.renderAll();
// }

export const getIndexOfAppropriateFaceInMaker = () => {
  const product_color = box.sel('maker->product_color');
  const pf = _p.find(_p.v(product_color, 'product_faces2.value'), function (pf) {
    return pf.designs.length;
  });
  if (!pf) return 0;
  return _p.find_i(box.sel('maker->canvass'), function (canvas) {
    return canvas.bpf_id == pf.bpf_id;
  });
};

export function getCvObjectIdx(cv_obj, canvas = G.mp.maker.editing_canvas()) {
  return canvas._objects.indexOf(cv_obj);
}

export function insertCvObject(target_cv_object, to_cv_object, not_call_selected) {
  let last_selected_pass;
  if (not_call_selected) {
    last_selected_pass = G.mp.maker.selected_pass;
    G.mp.maker.selected_pass = true;
  }
  const canvas = to_cv_object.canvas;
  const idx = getCvObjectIdx(to_cv_object);
  G.mp.maker.cleared_pass = true;
  to_cv_object.remove();
  G.mp.maker.cleared_pass = false;
  canvas.insertAt(target_cv_object, idx);
  canvas.setActiveObject(target_cv_object);
  if (not_call_selected) G.mp.maker.selected_pass = last_selected_pass;
}

export const orderFaceBackgroundInMaker = (canvas) => {
  const not_designs = G.mp.maker.not_designs(canvas);
  const order_background = [
    NewMakerCvObjectCvBackgroundConstantS.cv_type,
    'cv_print_item',
    'cv_print_area_mask',
    'cv_preview',
    'cv_mask1',
    'cv_mask2',
    'cv_bpcf',
    NewMakerPropertyBpfF.bpOptionLayers.cv_type,
    'cv_shading',
    'cv_blue_line',
    'cv_safe_area',
    'cv_safety_area',
    'cv_print_area',
    'cv_obj_alignment',
    'cv_cylinder_area',
    'cv_mockup',
  ];
  go(
    order_background,
    each((cv_type) =>
      go(
        not_designs,
        filter((obj) => obj._data.cv_type === cv_type),
        each((obj) => {
          if (obj._data.cv_type === NewMakerCvObjectCvBackgroundConstantS.cv_type) {
            obj.sendToBack();
          } else {
            obj.bringToFront();
          }
        }),
      ),
    ),
  );
};

export function isTemporalGroupInMaker(cv_target) {
  return cv_target && cv_target.type === 'group' && !cv_target._data.cv_type;
}

export function ungroupAll(canvas, objects) {
  return _p.each([...objects], function (object) {
    if (object._data.cv_type === 'cv_group') {
      const objects = object._objects;
      G.mp.maker.ungrouping_only(canvas, object);
      ungroupAll(canvas, objects);
    }
  });
}

export const ungroupAllWithObjAttrs = function (canvas, objs_arr) {
  return _p.go(
    objs_arr,
    each((design) =>
      go(
        design,
        (design) => G.mp.maker.from_cv_attrs_for_maker(design, 1),
        function (item) {
          return item && canvas.add(item);
        },
      ),
    ),
    function () {
      return canvas._objects;
    },
    (objects) => ungroupAll(canvas, objects),
    function () {
      return _p.map(canvas._objects, function (cv_object) {
        return cv_object.toObject();
      });
    },
    _p.tap(function () {
      canvas.remove.apply(canvas, canvas._objects);
    }),
  );
};

export const makeFcanvasSelectionFalse = function () {
  go(
    getFcanvass(),
    each((f_canvas) => (f_canvas.selection = false)),
  );
};
export const makeFcanvasSelectionTrue = function () {
  go(
    getFcanvass(),
    each((f_canvas) => (f_canvas.selection = true)),
  );
};

function cv_objects_deep_each(cv_object, func) {
  return (function f(cv_object) {
    if (!cv_object) return;
    const objects = cv_object._objects || cv_object.objects;
    if (objects) {
      return _p.each(objects, function (cv_object) {
        return f(cv_object);
      });
    }
    return _p.is_array(cv_object)
      ? _p.each(cv_object, function (cv_obj) {
          return f(cv_obj);
        })
      : func(cv_object);
  })(cv_object);
}
G.mp.maker.cv_objects_deep_each = cv_objects_deep_each;

export const deepFindCvObjects = function (cv_object, func) {
  return (function f(cv_object) {
    if (cv_object._objects) {
      return _p.find(cv_object._objects, function (cv_object) {
        return f(cv_object);
      });
    }
    if (_p.is_array(cv_object)) {
      return _p.find(cv_object, function (cv_obj) {
        return f(cv_obj);
      });
    } else {
      return func(cv_object) && cv_object;
    }
  })(cv_object);
};

export const deepFindCvObjectsAsync = function (cv_object, func) {
  return (async function f(cv_object) {
    if (cv_object._objects) {
      return _p.find(cv_object._objects, function (cv_object) {
        return f(cv_object);
      });
    }
    if (_p.is_array(cv_object)) {
      return _p.find(cv_object, function (cv_obj) {
        return f(cv_obj);
      });
    } else {
      return (await func(cv_object)) && cv_object;
    }
  })(cv_object);
};

G.mp.maker.cv_objects_deep_find = deepFindCvObjects;
G.mp.maker.is_cv_print_area = function (cv_obj) {
  return sel('_data.cv_type', cv_obj) === 'cv_print_area';
};

G.mp.maker.is_cv_print_area_mask = function (cv_obj) {
  return sel('_data.cv_type', cv_obj) === 'cv_print_area_mask';
};

G.mp.maker.isAirPodCase = isAirPodCase;

export const onBlockingCanvas = function () {
  $.append($1('#body'), legacyHtml` <div class="canvas_blocking"></div> `);
  $.css($1('.canvas_blocking'), {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'transparent',
    zIndex: 100,
  });
};

export const offBlockingCanvas = function () {
  $.remove($1('.canvas_blocking'));
};

export const isWorkerInMaker = () => {
  return $1('html[mp_worker_policy="true"]');
};

export const isDreamFactory = () => {
  return $1('html#dream_factory');
};

export function isDFOrWorker() {
  return isDreamFactory() || isWorkerInMaker();
}

export const fcanvasChanging = function (func) {
  return async function (...args) {
    if ($.has_class($1('#maker_frame'), 'pc_canvas_change_ing')) return;
    try {
      $.add_class($1('#maker_frame'), 'pc_canvas_change_ing');
      await func(...args);
    } catch (e) {
      if (e.__mp_message) $.alert(e.__mp_message);
      else console.error(e);
    } finally {
      $.remove_class($1('#maker_frame'), 'pc_canvas_change_ing');
    }
  };
};

export function getFonts() {
  if (box.sel('fonts')) return box.sel('fonts');
  return _p.go($.get('/@api/maker/fonts'), function (font_arr) {
    box.set('fonts', font_arr);
    return box.sel('fonts');
  });
}

export function somePFDesigns(func) {
  return go(
    getProductFaces2InMaker(),
    map((pf) => pf.designs),
    some((designs) =>
      deepFindCvObjects(designs, (cv_obj) => {
        return func(cv_obj);
      }),
    ),
  );
}
export function sortFonts(fonts) {
  const orders = {
    kr: { kr: 1, en: 2, jp_ch: 3 },
    jp: { jp_ch: 1, en: 2, kr: 3 },
    en: { en: 1, kr: 2, jp_ch: 3 },
  };

  const order = orders[T.lang];

  return fonts.sort((a, b) => order[a.type] - order[b.type]);
}

export function rejectFonts(fonts) {
  const is_arosegida = isArosegidaBp();
  const is_pu_case = $qs('#maker_frame')?.dataset?.is_carved_phonecase_product === 'true';
  const embroidery_config = NewMakerBaseProductsEmbroideryConstantS.PROPERTY.getConf(
    NewMakerBaseProductsEmbroideryConstantS.PROPERTY.configs,
    getBaseProductInMaker()?.id,
  );
  return go(
    fonts,
    reject((font) => {
      if (is_arosegida) {
        return go(arosegida_reject_fonts, some(selEq('id', font.id)));
      }
      if (is_pu_case) {
        return go([{ id: 109, fontFamily: 'Sonsie One' }], some(selEq('id', font.id)));
      }
    }),
    embroidery_config
      ? pipe(
          filter((font) => {
            return embroidery_config.font_names.includes(font.name.replace(/\s/g, '').toLowerCase());
          }),
        )
      : identity,
  );
}

export function getSthForAutoSwiper({ width, height, margin, whole_width, whole_height }) {
  const outer_width = width + margin;
  const outer_height = height + margin;
  const column_length = Math.floor(whole_width / outer_width);
  const app_width = column_length * outer_width;

  return {
    app_width,
    quantity_of_item_in_one_slide: column_length * Math.floor(whole_height / outer_height),
  };
}

export function makeAutoSwiperActive(don_tab_el) {
  if (!G.mp.maker.active()) return $.frame.close();
  const automatic_swiper_el = $.find1(don_tab_el, '.automatic_swiper');
  const slide_length = $.attr(automatic_swiper_el, 'slide_length');
  if (slide_length > 1) {
    automatic_swiper_el.my_swiper = new Swiper($.find1(automatic_swiper_el, '.swiper-container'), {
      pagination: {
        el: $.find1(automatic_swiper_el, '.swiper-pagination'),
      },
      initialSlide: _go(
        automatic_swiper_el,
        $.find('.swiper-slide'),
        _p.find_i(function (el) {
          return _go(el, $.find1('.selected'));
        }),
      ),
    });
  }
}

export function removeAllDesigns() {
  _p.each(getFcanvass(), function (canvas) {
    _p.each(G.mp.maker.designs(canvas), function (design) {
      canvas.remove(design);
    });
  });
}

export function selectFaceByDesigns() {
  go(
    box.sel('maker->canvass'),
    _p.find_i(function (canvas) {
      return G.mp.maker.designs(canvas).length;
    }),
    G.mp.maker.editing_canvas,
  );
}

export function setPfColllaboTypeBpsId(pc, collabo_type, base_product_size_id) {
  go(
    pc.product_faces2.value,
    each((pf) => {
      pf.collabo_type = collabo_type;
      pf.base_product_size_id = pf.size_faces && pf.size_faces.length > 1 && base_product_size_id;
    }),
  );
}

export function readyPfColllaboTypeBpsId() {
  box.set('maker->collabo_type', box.sel('maker->product_color->product_faces2->value->0->collabo_type'));
  box.set(
    'maker->base_product_size_id',
    box.sel('maker->product_color->product_faces2->value->0->base_product_size_id'),
  );
}
export function unsetPfCollaboTypeBpsId() {
  box.unset('maker->collabo_type');
  box.unset('maker->base_product_size_id');
}

export function isBase64(url) {
  return url && url.indexOf('base64') > -1;
}

export function isS3Url(url) {
  return url && url.indexOf('s3.marpple.co') > -1;
}

export function duplicateLocationSize(target) {
  const attr = pick(['top', 'left', 'scaleX', 'scaleY', 'angle'], target);
  G.mp.maker.active().set(attr);
  G.mp.maker.active().canvas.renderAll();
}

export async function extractUrlSpecificCvObjBySize770(fcanvas, target_cv_obj) {
  const img_width = go(getCvObj(fcanvas._objects, 'cv_bpcf'), (cv_obj) => cv_obj._element.width);
  const cv_objs = go(
    fcanvas._objects,
    reject((cv_obj) => cv_obj === target_cv_obj),
  );
  makeCvObjsVisibleFalse(cv_objs);
  fcanvas.renderAll();
  const ratio = fcanvas.lowerCanvasEl.width / fcanvas.width;
  const cv_bpcf = getCvObj(fcanvas._objects, 'cv_bpcf');
  const mockup_url = await go(
    fcanvas.lowerCanvasEl,
    (c) =>
      makeCanvasCutByLocation(c, {
        width: cv_bpcf.width * ratio,
        height: cv_bpcf.height * ratio,
        top: cv_bpcf.top * ratio,
        left: cv_bpcf.left * ratio,
      }),
    async (c) => {
      const width = img_width;
      const height = img_width;
      const new_c = createCanvasElement({ width, height });
      new_c.getContext('2d').drawImage(c, 0, 0, c.width, c.height, 0, 0, width, height);
      const img = await makeImageFromUrl(G.to_original(G.mp.maker.cv_bpcf()._element.src));
      const ctx = new_c.getContext('2d');
      ctx.globalCompositeOperation = 'destination-in';
      ctx.drawImage(img, 0, 0);
      return new_c;
    },
    (c) => c.toDataURL(),
  );
  makeCvObjsVisibleTrue(cv_objs);
  return mockup_url;
}

export function cvMask1CvMask2CvPrintAreaForceOff(fcanvas) {
  fcanvas.is_editing_cylinder = true;
  go(
    [
      getCvObj(fcanvas._objects, 'cv_mask1'),
      getCvObj(fcanvas._objects, 'cv_mask2'),
      getCvObj(fcanvas._objects, 'cv_print_area'),
    ],
    each((cv_obj) => (cv_obj.visible = false)),
  );
}
export function cvMask1CvMask2CvPrintAreaForceOffDone(fcanvas) {
  delete fcanvas.is_editing_cylinder;
}

export function bufferDownload(buffer, name) {
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  const file_url = (window.webkitURL || window.URL).createObjectURL(blob);
  go(
    legacyHtml` <a href="${file_url}" download="${name}"></a> `,
    $.appendTo(document.body),
    $.trigger('click'),
    $.remove,
  );
}

export function getImageUrlByZoom(url, zoom) {
  if (!url) return;
  if (url.indexOf(';base64,') !== -1) return url;
  if (zoom < 0.2) return G.to_150(url);
  else if (zoom >= 0.2 && zoom < 0.4) return G.to_350(url);
  else if (zoom >= 0.4 && zoom < 0.7) return G.to_600(url);
  else if (zoom >= 0.7 && zoom <= 2) {
    const src_900 = G.to_900(url);
    if (src_900 === G.to_original(url)) {
      return G.to_600(url); // 900 없는 무료 이미지들 -> 600 size 로 fallback
    }
    return src_900;
  } else return G.to_original(url);
}

export const rejectPressColors = (arr) => reject((color) => some(equals(color.id), arr));

export const makeTempUploadForText = (canvass) => {
  return go(
    canvass,
    map((canvas) =>
      $.uploadFileToOnlyOriginalUrl({
        url: canvas.toDataURL(),
        original_name: 'makeTempUploadForText',
        image_type: 'PNG',
      }),
    ),
  );
};
