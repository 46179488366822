import { $qs } from 'fxdom/es';
import { each, extend, filter, go, identity, noop, pipe, reduce, reject, sel, strMap, tap } from 'fxjs/es';
import { NewMakerProductStyleS } from '../../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { makeBaseProductItemHtml, makeSelectedFilterItemsHtml } from '../S/tmpl.js';
import { IS_PRODUCT_LIST_PC_SIZE } from './constant.js';
import StickySidebar from 'sticky-sidebar';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';

export function makeStickySlideBar() {
  if (IS_PRODUCT_LIST_PC_SIZE)
    new StickySidebar('#side_bar', {
      containerSelector: '#product_list',
      innerWrapperSelector: '#side_bar_inner',
      bottomSpacing: 40,
      resizeSensor: true,
    });
}

export function updateProductListView() {
  const fns = _p.to_array(arguments);
  fns.unshift(updateProductListView2);
  return function () {
    $.don_loader_start();
    return go(
      void 0,
      _p.async_queue(updateProductListView1, pipe(...fns), 'cate_selector'),
      noop,
      $.don_loader_end,
    );
  };
}

export function changFilterListCount(don_filter_list) {
  const count = (window.box.sel(don_filter_list).count = sel(
    'length',
    filter(sel('selected'), window.box.sel(don_filter_list)._.filter_items),
  ));
  $.html($.find1(don_filter_list, '.filter_list_name .count_wrap > .count'), count);
  $.attr($.find1(don_filter_list, '.filter_list_name'), 'count', count);
}

export function checkedFilterItem(don_checkbox) {
  const don_filter_item = $.closest(don_checkbox, '.filter_item');
  const filter_id = window.box.sel(don_filter_item).id;
  const filter_type = $.attr(don_checkbox, 'filter_type');
  const query = window.box.sel('product_list->query');
  const obj = { selected: don_checkbox.checked };
  window.box.extend2($.attr(don_filter_item, obj), obj);
  if (!don_checkbox.checked) query[filter_type] = reject((id) => id === filter_id, query[filter_type]);
  else if ((query[filter_type] = query[filter_type] || []).indexOf(filter_id) === -1)
    query[filter_type].push(filter_id);
  return don_checkbox;
}

export function clickProductListClear(e) {
  return go(
    e,
    sel('delegateTarget'),
    $.find('.product_filter_list .filter_list'),
    tap(
      $.find('input'),
      each((input) => (input.checked = false)),
      each(checkedFilterItem),
    ),
    each(changFilterListCount),
  );
}

export function changeSelectedFilterItem(target) {
  return go(
    target,
    $.closest('.don_tab'),
    $.find1('.selected_filter_items'),
    $.after(makeSelectedFilterItemsHtml(window.box.sel('product_list->bp_info'))),
    $.remove,
  );
}

function updateProductListView1() {
  const don_product_list = $1('#product_list_body');
  if (IS_PRODUCT_LIST_PC_SIZE) $.scroll_top(window, $.offset(don_product_list).top);
  else if (!IS_PRODUCT_LIST_PC_SIZE && !UtilF.isLegacyMobile())
    $.scroll_top($1('.don_frame.product_detail_list >.don_wrapper >.body'), 0);
  else if (UtilF.isLegacyMobile()) $.scroll_top(window, 0);
  $.css($.siblings($.remove_class($1('#selected_cate_list'), 'open'), '.cate_list_wrap'), { height: 0 });
  return go(
    $1('#ordering'),
    tap(function (don_ordering) {
      extend(window.box.sel('product_list->query'), {
        ordering: $.val($.find1(don_ordering, 'option:checked')) /*, cut_img: $.val(don_cut_img)*/,
      });
    }),
    function (don_ordering) {
      const cate_list_id = window.box.sel('product_list->cate_list_id');
      if ($1('html.product.list') && UtilF.isLegacyMobile()) {
        $.removeClass($1('.mp-header-ham__item.selected'), 'selected');
        $.addClass($1(`.mp-header-ham__item[data-cate_list_id="${cate_list_id}"]`), 'selected');
      }
      if (!cate_list_id) return '/product/list_is_not_use';
      const pathname = '/product/list/' + cate_list_id;
      if (!$.hasClass($.closest(don_ordering, '.don_frame'), 'product_detail_list'))
        $.frame.extend_state(
          void 0,
          null,
          '/' + T.lang + pathname + '?' + $.param(window.box.sel('product_list->query')),
          true,
        );
      return pathname;
    },
    function (pathname) {
      const is_change_bp = !!$qs('.don_frame.product_detail_list');
      return go(
        $.get('/@api' + pathname, { ...window.box.sel('product_list->query'), is_change_bp }),
        is_change_bp
          ? tap(({ bp_info }) => (bp_info.bps = reject(NewMakerProductStyleS.isCarvedPhoneCase, bp_info.bps)))
          : identity,
      );
    },
  );
}

function updateProductListView2(product_list) {
  const don_product_list = $1('#product_list_body');
  const el_frame = $.closest(don_product_list, '.don_frame');
  window.box.extend2('product_list', product_list);
  $.attr(don_product_list, 'total_count', product_list.bp_info.count);
  $.html(
    $.find1(don_product_list, '.product_list .total_count'),
    product_list.bp_info.count + ' item' + (parseInt(product_list.bp_info.count) < 2 ? '' : 's'),
  );
  $.html(
    $.find1(don_product_list, '.base_product_list'),
    go(
      product_list.bp_info.bps,
      strMap((bp) => makeBaseProductItemHtml(window.box.sel('product_list->query'), bp)),
    ),
  );

  go(don_product_list, $.find1('.count_sort'), function (el_count_sort) {
    $.remove($.find1(don_product_list, '.selected_filter_items'));
    $.after(el_count_sort, makeSelectedFilterItemsHtml(product_list.bp_info));
    $.html(
      $.find1(el_count_sort, '.filter .count'),
      '(' + reduce((mem, fl) => mem + fl.count, 0, product_list.bp_info.filter_lists) + ')',
    );
  });

  if (!el_frame.frame_opt.modal_lazy) G.don_lazy();
  else el_frame.frame_opt.modal_lazy.revalidate();
  return product_list;
}
