import { $findAll } from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerFreeMobileMuiF } from '../Mui/module/VectorEditorStickerFreeMobileMuiF.js';

export const makeSticker = async ({
  title,
  art_board_size: { width, height },
  free_template_svg_el,
  single_empty_template_svg_el,
  single_art_board_size: { width: single_art_board_size_width, height: single_art_board_size_height },
  collision: {
    sticker_margin: sticker_collision_margin,
    container_path_data: collision_container_path_data,
    container_area_position: collision_container_area_position,
    forbidden_area_position: collision_forbidden_area_position,
  },
  preProcess,
  postProcess,
}) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorStickerFreeMobileMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = async (frame_el, data) => {
        await frame_closed.call(frame, frame_el, data);

        return resolve(data);
      };

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_preProcess = preProcess;
        tab_el.__mp_postProcess = postProcess;
        tab_el.__mp_art_board_size = { width, height };
        tab_el.__mp_free_template_svg_el = free_template_svg_el;
        tab_el.__mp_single_empty_template_svg_el = single_empty_template_svg_el;
        tab_el.__mp_single_art_board_size = {
          width: single_art_board_size_width,
          height: single_art_board_size_height,
        };
        tab_el.__mp_sticker_collision_margin = sticker_collision_margin;
        tab_el.__mp_collision_container_path_data = collision_container_path_data;
        tab_el.__mp_collision_container_area_position = collision_container_area_position;
        tab_el.__mp_collision_forbidden_area_position = collision_forbidden_area_position;
        return tab_appending.call(tab, tab_el);
      };

      const tab_appended = tab.appended;
      tab.appended = async (tab_el) => {
        const return_val = await tab_appended.call(tab, tab_el);

        go(
          tab_el,
          $findAll('.header .title'),
          each((el) => (el.innerHTML = title)),
        );

        tab_el.__mp_free_sticker_editor?.setArtBoardSize({ width, height });

        return return_val;
      };

      const tab_showed = tab.showed;
      tab.showed = async (tab_el) => {
        await tab_showed.call(tab, tab_el);

        tab_el.__mp_free_sticker_editor?.fitSize();
        const view_box_vo = tab_el.__mp_free_sticker_editor?.calculateViewBoxFitToArtBoard({
          padding_in_vcs: { top: 16 + 48, right: 16, bottom: 16 + 144, left: 16 },
        });
        tab_el.__mp_free_sticker_editor?.setViewBox(view_box_vo);
      };
    }).catch(reject),
  );
