import { html, isNil } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerGridCreatorPCF } from '../Function/module/VectorEditorStickerGridCreatorPCF.js';
import { beforeLoginCheck } from '../../../../../../User/Login/F/fs.js';

export const handleRightCancelButtonClick = () => VectorEditorStickerGridCreatorPCF.cancelStickerEditor();

export const handleRightDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    const editor = tab_el.__mp_grid_sticker_editor;
    if (isNil(editor)) {
      return;
    }

    if (!editor.getIsAllGridExisting()) {
      await $.alert(
        html`<span
          >${T('modules::VectorEditor::Sticker::FreeGrid::반칸이 있습니다. 모든 빈칸을 채워주세요.')}</span
        >`,
      );
      return;
    }

    const is_login = await beforeLoginCheck(true);
    if (!is_login) {
      return;
    }

    try {
      const result = (await tab_el.__mp_postProcess?.(editor)) ?? null;
      MuiF.closeFrame(result);
    } catch (error) {
      console.error(error);
      $.alert(
        error?.__mp_alert_message ??
          T('modules::VectorEditor::Sticker::message::죄송합니다. 다시 시도해 주세요.'),
      );
    }
  };
