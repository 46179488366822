import { DfInhouseConstantS } from '../../S/Constant/module/DfInhouseConstantS.js';
import { DfInhouseF } from './module/DfInhouseF.js';
import { $attr, $setAttr } from 'fxdom/es';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { find } from 'fxjs/es';

const inhouse_root = DfInhouseConstantS.gBox.rootKey;

export const stateBucket = {
  triggerBucketSelection: ({ bucket_id }) => {
    const bucket_el = DfInhouseF.stateDOM.get.bucketEntityEl({ bucket_id });
    if (bucket_el) {
      bucket_el.click();
    }
    return bucket_el;
  },
  scrollTo: ({ bucket_id }) => {
    if (bucket_id == null) return;

    const selected_bucket_el = DfInhouseF.stateDOM.get.bucketEntityEl({ bucket_id });
    if (selected_bucket_el) {
      setTimeout(() => {
        selected_bucket_el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  },
  gBox: {
    get: {
      crewInhouses: () => box.sel(`${inhouse_root}->${DfInhouseConstantS.gBox.crew_inhouses}`),
      crewInhouseShippingForBucket: ({ bucket_id }) =>
        box.sel(
          `${inhouse_root}->${DfInhouseConstantS.gBox.buckets}->(#${bucket_id})->_->crew_inhouse_shipping`,
        ),
      inhouseShippingBucket: ({ bucket_id }) =>
        box.sel(`${inhouse_root}->${DfInhouseConstantS.gBox.buckets}->(#${bucket_id})`),
      inhouseShippingBucketByWaybillNo: ({ waybill_no }) => {
        const buckets = DfInhouseF.stateBucket.gBox.get.inhouseShippingBuckets();
        if (UtilArrayS.isArrayOk(buckets)) {
          return find((bucket) => bucket.waybill_no === waybill_no, buckets);
        }
      },
      inhouseShippingBuckets: () => box.sel(`${inhouse_root}->${DfInhouseConstantS.gBox.buckets}`),
    },
    insert: {
      inhouseShippingBuckets: ({ buckets }) => {
        const insert_buckets = [...buckets].reverse();
        box.unshift(`${inhouse_root}->${DfInhouseConstantS.gBox.buckets}`, ...insert_buckets);
      },
      inhouseShippingBox: ({ bucket_id, inhouse_shipping_box }) => {
        box.unshift(
          `${inhouse_root}->${DfInhouseConstantS.gBox.buckets}->(#${bucket_id})->_->inhouse_shipping_boxes`,
          inhouse_shipping_box,
        );
      },
    },
    delete: {
      inhouseShippingBucket: ({ bucket_id }) => {
        box.remove2(`${inhouse_root}->${DfInhouseConstantS.gBox.buckets}->(#${bucket_id})`);
      },
      inhouseShippingBox: ({ bucket_id, box_id }) => {
        box.remove2(
          `${inhouse_root}->${DfInhouseConstantS.gBox.buckets}->(#${bucket_id})->_->inhouse_shipping_boxes->(#${box_id})`,
        );
      },
    },
    update: {
      bucket: ({ bucket_id, data }) => {
        box.extend2(`${inhouse_root}->${DfInhouseConstantS.gBox.buckets}->(#${bucket_id})`, data);
      },
    },
    replace: {
      buckets: ({ buckets }) => {
        box.set(`${inhouse_root}->${DfInhouseConstantS.gBox.buckets}`, buckets);
      },
    },
    hydrate: {
      bucket: async ({ bucket_id }) => {
        const bucket_entity_el = DfInhouseF.stateDOM.get.bucketEntityEl({ bucket_id });

        /* hydrating 중일 때에는 re-hydrating 방지 */
        if ($attr('hydrating', bucket_entity_el) === 'true') return;

        /* hydrating 상태 켜기 */
        $setAttr({ hydrating: true }, bucket_entity_el);

        /* 로더 에니메이션 실행해서 DOM 에 추가 */
        const bucket_loader_el = DfInhouseF.bucketLoader.start();
        bucket_entity_el.prepend(bucket_loader_el);

        // 버킷의 새로운 정보 fetch -> 조용히 -> gBox 싱크 -> bucket entity DOM 싱크 -> 만약 현재 bucket 선택되어 있다면 테이블도 싱크
        return DfInhouseF.apiCalls.get
          .inhouseShippingBucketFromId({ inhouse_shipping_bucket_id: bucket_id })
          .then((bucket) => {
            /* gBox 싱크 */
            DfInhouseF.stateBucket.gBox.update.bucket({ bucket_id, data: bucket });

            /* bucket entity 싱크 */
            DfInhouseF.stateDOM.update.bucketEntity({ bucket, is_hydrating: true });

            /* 로더 에니메이션 제거 */
            DfInhouseF.bucketLoader.end(bucket_loader_el);

            /* hydrating 상태 끄기 */
            setTimeout(() => {
              $setAttr({ hydrating: false }, DfInhouseF.stateDOM.get.bucketEntityEl({ bucket_id }));
            }, DfInhouseConstantS.HYDRATING_TIME_LIMIT_SEC * 1000);

            /* 현재 selected bucket 상태이면 테이블 rows 도 업데이트
             *  - 만약 비동기 실행 전에 다른 bucket 을 selection 했다면 업데이트 해 줘서는 안됨
             *    - 다른 bucket 의 boxes rows 를 table 에 그려 놓았기 때문
             *    - 그리고 다시 해당 bucket 을 선택했을 때는 자연스럽게 싱크가 이미 되어 있는 gBox 최신 상태 값으로 table rows 를 렌더링 하게됨.
             *   */
            const { id: current_selected_bucket_id } = DfInhouseF.stateDOM.get.selectedBucket(true);
            if (current_selected_bucket_id === bucket_id) {
              // 비동기가 실행될 때 동일한 bucket 을 바라보고 있을 때에만 table DOM 변경
              const boxes = DfInhouseF.stateBox.get.boxesFromBucket({ bucket });
              DfInhouseF.boxTable.upsertOrDeleteRows(boxes).then(() => {});
            }
          });
      },
    },
  },
};
