import axios from 'axios';
import Swal from 'sweetalert2';
import { TabulatorFull as Tabulator } from 'tabulator_new';
import { $attr, $closest, $data, $find, $findAll } from 'fxdom/es';
import { extend, go, pipe, sel, sortBy, map, maxBy, every, each } from 'fxjs/es';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { NewMakerProductF } from '../../../../NewMaker/Product/F/Function/module/NewMakerProductF.js';
import { DfLglF } from '../../../Lgl/F/Function/module/DfLglF.js';
import { DfLogModalMuiF } from '../../../LogModal/F/Mui/module/DfLogModalMuiF.js';
import { initQuickCancel } from '../../Detail/F/fs.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import { shipping_events } from './event.shipping.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { makeApiUrl } from '../../../../Util/S/Function/util.js';
import { makeDfProjectionListUpdate } from './fs.js';

export const makeDfProjectionShipEvent = pipe(
  shipping_events,
  $.on('click', 'button.inhouse_label_print', DfInhouseF.handleInhouseLabelPrint),
  $.on('click', 'button.delivery_tracking', async ({ currentTarget }) => {
    const shipping_id = go(currentTarget, $closest('.ship_item'), $attr('shipping_id'));
    await cjTrackingFromShippingId({ shipping_id });
  }),

  $.on('click', '.lgl .lgl_detail', DfLglF.handleOpenDetail),
);

export const lglExpressTracking = async ({ projection_id }) => {
  try {
    $.don_loader_start();

    const { tracking_list } = (
      await axios.get(
        makeApiUrl('/@api/df/lgl/status/tracking/haezle/projection/:projection_id', {
          projection_id,
        }),
      )
    ).data;

    $.don_loader_end();
    return tracking_list;
  } catch (err) {
    $.don_loader_end();
    throw new Error(err);
  }
};

export const cjTrackingFromShippingId = async ({ shipping_id }) => {
  const shipping_data = await getShippingRowFromShippingId(shipping_id);
  let tracking_data;
  /*
   * 추적 거부 조건
   * 1. type != 'parcel
   * 2. shipping_company_id != 'cj'
   * 3. isNil(waybill_number) == true
   * */
  let error = true;
  let error_msg = '';
  const tracking_invoice_number = shipping_data.waybill_number_inbound || shipping_data.waybill_number;

  $.don_loader_start();
  if (shipping_data.type !== 'parcel') {
    error_msg = '택배 배송이 아님';
  } else if (shipping_data.oversea_3pl_company !== 'lgl' && shipping_data.shipping_company_id !== 'cj') {
    // lgl 국내배송은 CJ 로만 하는 것으로 가정
    error_msg = '대한통운 배송이 아님';
  } else if (UtilS.isEmpty(tracking_invoice_number)) {
    error_msg = '운송장 번호가 없음';
  } else {
    //택배 추적 api 호출
    try {
      const returning_data = go(
        await axios({
          method: 'get',
          url: '/@api/waybill/cj/req_parcel_req_parcel_tracking',
          params: { INVC_NO: tracking_invoice_number },
        }),
        sel('data'),
      );
      switch (returning_data.RESULT_CD) {
        case 'S': {
          error = false;
          tracking_data = returning_data.DATA;
          break;
        }
        case 'E': {
          error_msg = parseTrackingReturnErrorMsg(returning_data.RESULT_DETAIL);
          break;
        }
      }
    } catch (e) {
      error = true;
      error_msg = '대한통운 서버 요청 에러 (개발자 문의)';
      console.error(e.response.data);
      await $.alert(`에러: ${e.response.data}`);
    }
  }
  $.don_loader_end();
  await alertTrackingResult(error, error_msg, tracking_invoice_number, shipping_data, tracking_data);
};

export const parseTrackingReturnErrorMsg = (result_detail) => {
  if (/no data/i.test(result_detail)) {
    return '아직 대한통운 집화 등록 처리 전 입니다.<br>대한통운이 고객님의 상품을 인수 받은 후 추적이 가능합니다.<br>혹은 운송장 유효기간이 만료되어 더이상 조회되지 않는 운송장 입니다.';
  } else {
    return result_detail;
  }
};

export const alertTrackingResult = async (is_error, error_msg, waybill_no, shipping_data, track_data) => {
  await Swal.fire({
    customClass: {
      container: 'delivery_tracking_alert',
    },
    position: 'center',
    width: '1100',
    icon: is_error ? 'error' : 'success',
    title: is_error ? '배송 추적 오류' : '배송 추적 결과',
    imageUrl: is_error
      ? getDeliveryTrackingImageUrl()
      : getDeliveryTrackingImageUrl(getLastTrackingCode(track_data)),
    imageWidth: '85%',
    stopKeydownPropagation: false,
    html: `
      ${
        error_msg
          ? `
        <div class="tracking_error_msg">
          <span style="color:orangered">원인: ${error_msg}</span>
        </div>
      `
          : ``
      }
     <div>
        <span>고객님 성함 <b>${shipping_data.name}</b> | </span>
        <span>운송장 번호 <b>${shipping_data.waybill_number || '없음'}</b> | </span>
        <span>고객님 연락처 <b>${
          shipping_data.mobile ||
          shipping_data.mobile1 ||
          shipping_data.mobile2 ||
          shipping_data.mobile_en ||
          '없음'
        }</b></span>
     </div>
     <div id = "delivery_tracking_table" style="width:99%;"></div>`,
    didRender: () => {
      if (is_error || !track_data) return;
      const waybill_tracking_table = new Tabulator('#delivery_tracking_table', {
        layout: 'fitDataStretch',
        columns: [
          //Define Table Columns
          { title: '일자/시각', field: 'scan_date_time', headerSort: false },
          { title: '배송 단계', field: 'cargo_status', headerSort: false },
          { title: '담당 장소', field: 'dealt_location_name', headerSort: false },
          { title: '담당자', field: 'dealt_employee_name', headerSort: false },
          { title: '단계 설명', field: 'description', headerSort: false },
        ],
        data: makeTrackingTableData(track_data),
      });
      waybill_tracking_table.on('tableBuilt', () => {
        const rows = waybill_tracking_table.getRows();
        const last_row = rows[rows.length - 1];
        last_row.getElement().style.backgroundColor = 'orange';
      });
    },
    showConfirmButton: true,
  });
};

const getLastTrackingCode = pipe(
  maxBy((d) => new Date(`${d.SCAN_YMD} ${d.SCAN_HOUR}`)),
  sel('CRG_ST'),
);

const getDeliveryTrackingImageUrl = (status_code) => {
  switch (status_code) {
    case '91':
      return '//s3.marpple.co/files/u_1187078/2021/12/original/19c05ee9174dfbc18d3a68dddac440533bcf3d546.png'; //배송완료
    case '84':
      return '//s3.marpple.co/files/u_1187078/2021/12/original/ae6b1a94d0d93d58d007075d66f3ec60a66197091.png'; //미배달
    case '82':
      return '//s3.marpple.co/files/u_1187078/2021/12/original/b777f5d11c84468d041515100fdd176b348fe0a05.png'; //배송출발
    case '42':
      return '//s3.marpple.co/files/u_1187078/2021/12/original/ff7c864f636f029dda65a44ee82915434bce06cb4.png'; //간선하차
    case '41':
      return '//s3.marpple.co/files/u_1187078/2021/12/original/de4df1213f5622fb27c278d96d274dc78c07c0283.png'; //간선상차
    case '12':
      return '//s3.marpple.co/files/u_1187078/2021/12/original/5cd7a1278eb5ba9023e966dd5351063b65c22b021.png'; //미집화
    case '11':
      return '//s3.marpple.co/files/u_1187078/2021/12/original/90bd7ff831541a84898aac739f01b447856d55da2.png'; //집하처리
    case '01':
      return '//s3.marpple.co/files/u_1187078/2021/12/original/c9993305e8c60236c17ad6f6a2078e776628393c1.png'; //집하지시
    default:
      return '//s3.marpple.co/files/u_1187078/2021/12/original/c9993305e8c60236c17ad6f6a2078e776628393c1.png';
  }
};

const makeTrackingTableData = pipe(
  sortBy((row) => new Date(`${row.SCAN_YMD} ${row.SCAN_HOUR}`)),
  map((tr) => ({
    cargo_status: tr.CRG_ST_NM,
    scan_date_time: `${tr.SCAN_YMD} ${tr.SCAN_HOUR}`,
    description:
      tr.CRG_ST === '12' || tr.CRG_ST === '84'
        ? `${CARGO_STATUS_DESCRIPTIONS[tr.CRG_ST]}, 원인: ${tr.DETAIL_RSN ?? '알수 없음'}`
        : CARGO_STATUS_DESCRIPTIONS[tr.CRG_ST],
    dealt_location_name: tr.DEALT_BRAN_NM,
    dealt_employee_name: tr.DEALT_EMP_NM,
  })),
);

const CARGO_STATUS_DESCRIPTIONS = {
  '01': '대한통운에서 고객님의 상품을 인수 받기 위해 MARPPLE 로 오는 중입니다.', //집화지시
  11: '대한통운에서 고객님의 상품을 인수 받아 배송이 시작되었습니다.', //집화처리
  12: '대한통운에서 고객님의 상품을 인수 받지 못하였습니다.', //미집화
  41: '고객님의 상품이 간선 터미널로 이동 중 입니다. (여러 터미널을 거쳐갈 수 있습니다)', //간선상차
  42: '고객님의 상품이 간선 터미널에 도착하였습니다. (여러 터미널을 거쳐갈 수 있습니다)', //간선하차
  82: '고객님의 상품을 문 앞으로 배달할 예정입니다.', //배송출발
  84: '고객님의 상품이 배송되지 못하였습니다.', //미배송
  91: '고객님의 상품이 문 앞에 배송 완료 되었습니다.', //배송완료
};

export const getShippingRowFromShippingId = async (shipping_id) => {
  try {
    return go(
      await axios({
        method: 'get',
        url: '/@api/projection/shippingRow',
        params: { shipping_id },
      }),
      sel('data'),
    );
  } catch (e) {
    console.error(e);
    $.alert(e);
  }
};

export const makeDfProjectionSideBarEvent = pipe(
  initQuickCancel,
  $.on('click', '.projection_item .important button', function (e) {
    const input = $.prev(e.currentTarget, 'input[type="checkbox"]');
    const prj_item = $.closest(input, '.projection_item');
    const p = box.sel(prj_item);
    go(
      $.post('/@api/projection/update', {
        important: input.checked,
        id: p.id,
      }),
      (prj) => {
        const { important } = prj;
        $.attr($.find(prj_item, '.due_at'), { important });
        extend(p, prj);
        filterBlur(input);
      },
    );
  }),
  $.on('click', '.projection_item .internal-important button', function (e) {
    const input = $.prev(e.currentTarget, 'input[type="checkbox"]');
    const prj_item = $.closest(input, '.projection_item');
    const p = box.sel(prj_item);
    go(
      $.post('/@api/projection/update', {
        internal_important: input.checked,
        id: p.id,
      }),
      (prj) => {
        const { internal_important } = prj;
        $.attr($.find(prj_item, '.due_at'), { 'internal-important': internal_important });
        extend(p, prj);
        filterBlur(input);
      },
    );
  }),
  $.on('click', '.projection_item .due_at button', async function (e) {
    const prj_item = $.closest(e.currentTarget, '.projection_item');
    const input = $.find1(prj_item, 'input.due_at');
    const p = box.sel(prj_item);
    const val = $.val(input);
    if (Number.isNaN(new Date(val).getFullYear())) return $.alert('출고일 날짜 포맷이 정상적이지 않습니다.');
    if (
      new Date() > new Date($.val(input)) &&
      !(await $.confirm('시간이 지났습니다. 그래도 반영하시겠습니까?'))
    ) {
      return;
    }
    go(
      $.post('/@api/projection/update', {
        due_at: new Date($.val(input)),
        id: p.id,
      }),
      function (prj) {
        if (prj.fail_msg) return $.alert('출고일 날짜 포맷이 정상적이지 않습니다.');
        extend(p, prj);
      },
      () => filterBlur(input),
    );
  }),
  $.on('click', '.projection_item .link', function (e) {
    if (!e.metaKey && !e.ctrlKey) {
      e.preventDefault();
      if (box.sel('user->outsourcing_company->id')) {
        $.alert('현재 이용하실수 없습니다.<br>필요시 담당자에게 연락 부탁 드립니다.');
        return;
      }
      _go(e.currentTarget, box.sel, G.df.projection.detail.open);
      return false;
    } else {
      if (box.sel('user->outsourcing_company->id')) {
        e.preventDefault();
        $.alert('현재 이용하실수 없습니다.<br>필요시 담당자에게 연락 부탁 드립니다.');
      }
    }
  }),
);

const toggleDoubleChecker = (ct) => {
  const CHECKED = 'checked';
  const isChecked = ct.classList.contains(CHECKED);
  if (isChecked) {
    ct.classList.remove(CHECKED);
  } else {
    ct.classList.add(CHECKED);
  }
};
const getAllDoubleCheckerEls = (ct) => {
  return go(ct, $closest('.up_c_s_list'), $findAll('.double_check:not(.total)'));
};

export const handleDoubleChecker = (e) => {
  const ct = e.currentTarget;
  toggleDoubleChecker(ct);
  const checker_els = getAllDoubleCheckerEls(ct);
  if (checker_els.length > 1) {
    const is_total_el = ct.classList.contains('total');
    const is_all_checked = every((el) => el.classList.contains('checked'), checker_els);
    if (!is_total_el) {
      const double_check_total_el = go(ct, $closest('.up_c_s_list'), $find('.double_check.total'));
      is_all_checked
        ? double_check_total_el.classList.add('checked')
        : double_check_total_el.classList.remove('checked');
    } else {
      const is_total_el_checked = !ct.classList.contains('checked');
      if (is_total_el_checked) {
        go(
          checker_els,
          each((el) => el.classList.remove('checked')),
        );
      } else {
        go(
          checker_els,
          each((el) => el.classList.add('checked')),
        );
      }
    }
  }
};

export const makeDfProjectionListEvent = pipe(
  makeDfProjectionSideBarEvent,

  $.on2('click', '.warning_message', function (e) {
    return go(e.currentTarget, box.sel, (p) => {
      if (p.merged_type == 'parent') {
        G.df.projection.detail.open(p, 'projection_detail_creator_parent_tab');
      } else {
        G.df.projection.detail.open(p, 'projection.detail_user_projections');
      }
    });
  }),

  $.on2('click', '.projection_item .type', function (e) {
    return go(e.currentTarget, box.sel, (p) => G.df.projection.detail.open(p, 'projection_detail_info'));
  }),

  $.on2('click', '.projection_item .price', function (e) {
    return go(e.currentTarget, box.sel, (p) => G.df.projection.detail.open(p, 'projection.detail.payment'));
  }),

  $.on2('change', 'select.prj_product_mode', G.df.search_btn_event),

  $.on('click', '.up_c_s_item .double_check', handleDoubleChecker),

  $.on('click', '.wow_ordnum span', async function ({ currentTarget }) {
    const { up_id } = box.sel(currentTarget);

    const { data } = await axios.get('/@api/wow_logs', {
      params: { user_product_id: up_id },
    });

    console.log(data);
    await MuiF.openFrame(DfLogModalMuiF.frame, (f, p, [t]) => {
      f.is_modal = true;
      p.hide_frame_button_type = 'X';
      p.title = 'wow 로그 상세 ';
      f.always_remove = true;
      t.makeData = () => data;
    });
  }),
  $.on('click', '.shopfreeca-phonecase button', async (e) => {
    const { up_cs } = $data(e.currentTarget);
    $.don_loader_start($closest('.ship_and_ups', e.currentTarget), {
      position: 'absolute',
      background: 'rgba(0,0,0,0.3)',
    });
    await go(
      up_cs,
      map(({ _: { product_color } }) => {
        return NewMakerProductF.updateProductDesignsChangingBpAndBpc(product_color);
      }),
    );
    await makeDfProjectionListUpdate();
    $.don_loader_end($closest('.ship_and_ups', e.currentTarget));
  }),
  makeDfProjectionShipEvent,

  // G.mp.maker.draw_product_face_in_ups,
  // _.f('G.df.projection.detail.up_item_init')
);
