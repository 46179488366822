// @description 샵 주문에서 사내 배송지 선택으로 발송 요청한 경우 사내 배송 요청지 배송 정보 리턴
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';
import { DfInhouseF } from './module/DfInhouseF.js';

export const stateProjection = {
  gBox: {
    get: {
      projectionFromProjectionId: ({ projection_id }) => box.sel(`projections->(#${projection_id})`),
      /* @description 샵 주문에서 사내 배송지 선택으로 발송 요청한 경우 사내 배송 요청지 배송 정보 리턴 */
      customer_order_crew_inhouse: ({ projection, projection_id }) => {
        const is_not_repress = !projection?.is_repress;
        if (is_not_repress && projection) {
          return projection._?.shipping_order._?.store_order._?.customer_order_crew_inhouse;
        } else {
          let coci = box.sel(
            `projections->(#${projection_id})->_->shipping_order->_->store_order->_->customer_order_crew_inhouse`,
          );

          if (UtilObjS.isEmNil(coci)) {
            // 재제작 주문서 등 shipping_order 가 존재하지 않는 경우가 있음
            coci = box.sel(
              `projections->(#${projection_id})->_->shippings->0->_->customer_order->_->customer_order_crew_inhouse`,
            );
          }

          return coci;
        }
      },
      crew_inhouse_shipping: ({ projection_id }) =>
        box.sel(
          `projections->(#${projection_id})->_->shipping_order->_->store_order->_->customer_order_crew_inhouse->_->crew_inhouse_shipping`,
        ),
      inhouseShippingBox: ({ projection_id }) =>
        box.sel(
          `projections->(#${projection_id})->_->shipping_order->_->store_order->_->customer_order_crew_inhouse->_->inhouse_shipping_box`,
        ),

      /* @description 사내 배송 주문인지 확인 - 스토어 주문 -> 스토어 -> 크류 -> 크류가 inhouse 인지 판단 */
      isPrjInhouse: ({ projection, projection_id }) => {
        if (projection) {
          return UtilObjS.isNotEmpty(projection._?.store?._?.crew?._?.crew_inhouse);
        } else {
          return UtilObjS.isNotEmpty(
            box.sel(`projections->(#${projection_id})->_->store->_->crew->_->crew_inhouse`),
          );
        }
      },

      /* @description 배송을 사내배송 (사내) 로 해야 하는지 판단. (사내배송 주문 중 사내로 배송하는 경우) */
      isShipToInhouse: ({ prj, projection_id }) => {
        prj = prj ?? DfInhouseF.stateProjection.gBox.get.projectionFromProjectionId({ projection_id });

        if (UtilObjS.isEmNil(prj)) throw new Error(`주문 정보를 가져올 수 없습니다.`);

        const customer_order_crew_inhouse = DfInhouseF.stateProjection.gBox.get.customer_order_crew_inhouse({
          projection_id: prj.id,
        });

        if (
          DfInhouseF.stateProjection.gBox.get.isPrjInhouse({ projection_id: prj.id }) &&
          UtilObjS.isNotEmpty(customer_order_crew_inhouse)
        ) {
          return !customer_order_crew_inhouse.is_hidden;
        }

        return false;
      },
    },
    update: {
      customerOrderCrewInhouse: ({ projection_id, customer_order_crew_inhouse }) => {
        box.extend2(
          `projections->(#${projection_id})->_->shipping_order->_->store_order->_->customer_order_crew_inhouse`,
          customer_order_crew_inhouse,
        );
        box.extend2(
          `projections->(#${projection_id})->_->shippings->0->_->customer_order->_->customer_order_crew_inhouse`,
          customer_order_crew_inhouse,
        );
      },
    },

    hydrate: {
      customerOrderCrewInhouse: async ({ projection_id, customer_order_crew_inhouse_id }) => {
        const customer_order_crew_inhouse = await DfInhouseF.apiCalls.get.customerOrderCrewInhouseFromId({
          customer_order_crew_inhouse_id,
        });
        DfInhouseF.stateProjection.gBox.update.customerOrderCrewInhouse({
          projection_id,
          customer_order_crew_inhouse,
        });
      },
    },
  },
};
