import { $qs } from 'fxdom/es';
import { delay, each, filter, go, head, reject, some, tap } from 'fxjs/es';
import { isNoNeedAutoCenter } from '../../../../../modules/Maker/F/categorize.js';
import {
  getShadeFcanvas,
  renderMakerPageShade,
} from '../../../../../modules/Maker/F/CvPreview/Shade/render.js';
import {
  getCurrentFcanvas,
  getFcanvasFlattenCvObjs,
  getFlattenCvDesigns,
  makeFilterCvObj,
} from '../../../../../modules/Maker/F/Fcanvas/cv_object.js';
import { getBaseProductInMaker } from '../../../../../modules/Maker/F/getSth.js';
import { marpplizerProxy } from '../../../../../modules/Maker/F/Marpplizer/marpplizer.js';
import { checkIsOverflowInCurrentFace } from '../../../../../modules/Maker/F/overflow.js';
import { renderSpecialColor, setCvTextImagePaddingA } from '../../../../../modules/Maker/F/text.js';
import { NewMakerCheckerF } from '../../../../../modules/NewMaker/Checker/F/Function/module/NewMakerCheckerF.js';
import { NewMakerMenuF } from '../../../../../modules/NewMaker/Menu/F/Function/module/NewMakerMenuF.js';
import { NewMakerProductStyleS } from '../../../../../modules/NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { NewMakerUtilF } from '../../../../../modules/NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { NewMakerFcanvasFuncF } from '../../../../../modules/NewMaker/Fcanvas/Func/F/Function/module/NewMakerFcanvasFuncF.js';
import { minusStrokeWidth } from '../../../../../modules/Maker/F/Fcanvas/stroke.js';
import { NewMakerMaskingTapeF } from '../../../../../modules/NewMaker/MaskingTape/F/Function/module/NewMakerMaskingTapeF.js';
import { orderFaceBackgroundInMaker } from '../../../../../modules/Maker/F/util.js';
import { BpOptionConstantS } from '../../../../../modules/BpOption/S/Constant/module/BpOptionConstantS.js';
import { NewMakerMaskingTapeConstantS } from '../../../../../modules/NewMaker/MaskingTape/S/Constant/module/NewMakerMaskingTapeConstantS.js';
import { NewMakerFitPrintWidthF } from '../../../../../modules/NewMaker/FitPrintWidth/F/Function/module/NewMakerFitPrintWidthF.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';
import { processBulkCvObjsAdd } from '../../../../../modules/NewMaker/Util/F/Function/processBulkCvObjsAdd.js';
import { OMPDosuF } from '../../../../../modules/OMP/Dosu/F/Function/module/OMPDosuF.js';
import { BpOptionF } from '../../../../../modules/BpOption/F/Function/module/BpOptionF.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../../../modules/NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { NewMakerPropertyBaseProductF } from '../../../../../modules/NewMaker/Property/BaseProduct/F/Function/module/NewMakerPropertyBaseProductF.js';
import { take1 } from 'fxjs';
import { OMPDosuConstantS } from '../../../../../modules/OMP/Dosu/S/Constant/module/OMPDosuConstantS.js';
import { NewMakerCallConditionPopupF } from '../../../../../modules/NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';

!(function (LF) {
  const MOVEMENT_DELTA = 1; // 방향키 이동 정도(속도)

  add_arrow_key('left', function (active) {
    active.set('left', active.get('left') - MOVEMENT_DELTA).setCoords();
    NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(active);
  });

  add_arrow_key('right', function (active) {
    active.set('left', active.get('left') + MOVEMENT_DELTA).setCoords();
    NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(active);
  });

  add_arrow_key('up', function (active) {
    active.set('top', active.get('top') - MOVEMENT_DELTA).setCoords();
    NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(active);
  });

  add_arrow_key('down', function (active) {
    active.set('top', active.get('top') + MOVEMENT_DELTA).setCoords();
    NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(active);
  });

  function mouseTrapEventWrapper(func) {
    return (e) => {
      const maker_frame_el = $qs('#maker_frame');
      if (!maker_frame_el) return;
      if (maker_frame_el.dataset.is_carved_phonecase_product === 'true') return;
      if (maker_frame_el.dataset.is_embroidery_app === 'true') return;
      if (maker_frame_el.dataset.is_vector === 'true') return;
      func(e);
    };
  }

  // 전체선택
  const select_all = function (e) {
    const canvas = box.sel('maker->editing_canvas');
    if (!canvas) return;
    e.preventDefault();
    G.mp.maker.unselect_all(canvas);
    go(
      G.mp.maker.designs(),
      filter((cv_obj) => cv_obj.evented && cv_obj.visible),
      G.mp.maker.multiple_select,
    );
  };
  G.mp.maker.select_all = select_all;
  Mousetrap.bind(['command+a', 'ctrl+a'], mouseTrapEventWrapper(select_all));

  let copied_cv_object;
  let copied_fcanvas_idx;
  let pasted_count = 0;
  const PASTE_GAP = 10; // 복붙할 때 사용
  // undo
  Mousetrap.bind(['command+z', 'ctrl+z'], mouseTrapEventWrapper(G.mp.maker.state.undo));

  // redo
  Mousetrap.bind(['command+shift+z', 'ctrl+shift+z'], mouseTrapEventWrapper(G.mp.maker.state.redo));

  function add_arrow_key(key, set) {
    Mousetrap.bind(
      key,
      mouseTrapEventWrapper(
        G.mp.maker.has_active_object_or_group(function (canvas, active) {
          set(active);
          active.setCoords();
          checkIsOverflowInCurrentFace();
          canvas.renderAll();
          NewMakerCallConditionPopupF.setAndRender(active);
          modified();
        }),
      ),
    );
  }

  function remove_temp_group(canvas, group) {
    _each(_clone(group._objects), function (obj) {
      group.removeWithUpdate(obj);
      canvas.remove(obj);
    });
    canvas.discardActiveGroup(); // 임시 그룹에서만 해줘야함
    return group;
  }

  G.mp.maker.remove_temp_group = remove_temp_group;

  // 삭제
  Mousetrap.bind(['del'], mouseTrapEventWrapper(NewMakerFcanvasFuncF.removeActiveCvObj));

  G.mp.maker.clone = function (cv_object) {
    return new Promise(function (resolve) {
      cv_object.clone(async function (cloned) {
        // $도수 - 가드(면 도수 옵션그룹)
        BpOptionF.biz.get.currentFace.hasDosuRelatedOptions() &&
          (await OMPDosuF.cvObj.update.dosuColorToCvObj({ cv_obj: cloned }));

        if (cloned.src)
          cloned.src = cloned.src
            .replace(/\?canvas=v1/g, '')
            .replace(/\?canvas=v2/g, '')
            .replace(/\?canvas=v3/g, '');
        _p.go(renderSpecialColor(cloned), function () {
          cloned._data = JSON.parse(JSON.stringify(cloned._data));
          resolve(_extend(cloned, G.mp.maker.BASECONF()));
        });
      });
    });
  };

  const clipboard = _p.pipe(G.mp.maker.clone, function (cloned) {
    copied_fcanvas_idx = G.mp.maker.editing_canvas_idx();
    copied_cv_object = cloned;
    G.mp.maker.copied_cv_object = cloned;
    pasted_count = 0;
    return copied_cv_object;
  });

  // 복사
  const copy = G.mp.maker.has_active_object_or_group(function (canvas, active) {
    clipboard(active);
  });
  Mousetrap.bind(['command+c', 'ctrl+c'], mouseTrapEventWrapper(copy));
  G.mp.maker.copy = copy;
  // 잘라내기/오려두기
  const cut = G.mp.maker.has_active_object_or_group(function (canvas, active) {
    _p.go(
      active,
      clipboard,
      function (copied_cv_object) {
        copied_cv_object.set('left', copied_cv_object.left - PASTE_GAP).setCoords();
        copied_cv_object.set('top', copied_cv_object.top - PASTE_GAP).setCoords();
      },
      NewMakerFcanvasFuncF.removeActiveCvObj,
    );
  });
  Mousetrap.bind(['command+x', 'ctrl+x'], mouseTrapEventWrapper(cut));
  G.mp.maker.cut = cut;
  // 붙여넣기
  const paste = G.mp.maker.has_editing_canvas(async function (canvas, active, e) {
    const mp_maker = $qs('.mp_maker');
    // const is_same_fcanvas = copied_fcanvas_idx === G.mp.maker.editing_canvas_idx();
    const fcanvas = getCurrentFcanvas();
    // if (
    //   !is_same_fcanvas &&
    //   NewMakerPropertyBpOptionF.dosuColors.isLimited({
    //     base_product: getBaseProductInMaker(),
    //     bpf_id: fcanvas.bpf_id,
    //   })
    // )
    //   return;
    $.don_loader_start();
    try {
      G.mp.maker.cleared_pass = true;
      G.mp.maker.selected_pass = true;
      G.mp.maker.unselect_all();
      const is_hard_computing = mp_maker?.dataset?.is_bad_computing_power === 'true';
      if (is_hard_computing) {
        await delay(200, () => {});
      }
      const design_collection_type = $.attr($1('html'), 'design_collection_type');
      if ($.attr($1('html'), 'collabo_type') === 'line' && design_collection_type) {
        const line_cv_obj = G.mp.maker.cv_objects_deep_find(copied_cv_object, function (cv_obj) {
          return _p.v(cv_obj, '_data.design_collection_type');
        });
        if (line_cv_obj && design_collection_type !== line_cv_obj._data.design_collection_type) {
          return $.alert('다른 종류의 스티커입니다.');
        }
      }
      let when_copy_paste_location;
      const maker_frame_el = $qs('#maker_frame');
      const top = parseInt(maker_frame_el.dataset.long_press_top);
      const left = parseInt(maker_frame_el.dataset.long_press_left);
      if (top && left) {
        when_copy_paste_location = { top, left };
      }
      copied_cv_object &&
        (await _p.go(
          _p.mr(
            await go(
              copied_cv_object,
              tap(async (cv_obj) => {
                const base_product = getBaseProductInMaker();
                if (BpOptionF.biz.get.currentBp.hasDosuRelatedOptions()) {
                  const max_dosu_count = BpOptionF.biz.get.currentFace.maxDosuCount();
                  if (max_dosu_count === 1) {
                    await go(
                      undefined,
                      () => {
                        let color_code = OMPDosuF.cvObj.get.usedDosuColorCodes(
                          take1(getFcanvasFlattenCvObjs(fcanvas)),
                        )?.[0];
                        if (color_code) return color_code;

                        if (
                          base_product.maker_features?.[
                            NewMakerPropertyBaseProductConstantS.ALL_FACES_SINGLE_COLOR
                          ]
                        ) {
                          const color_code =
                            NewMakerPropertyBaseProductF.allFacesSingleColor.getCurrentColorCode();
                          if (color_code) return color_code;
                        }

                        const cv_obj_color_code = go(getFlattenCvDesigns([cv_obj]), head, (cv_obj) =>
                          cv_obj._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR]?.toUpperCase(),
                        );
                        const hex_codes = BpOptionF.biz.get.currentFace.dosuColorHexCodes(canvas.bpf_id);
                        if (!hex_codes.includes(cv_obj_color_code)) {
                          const color_code = hex_codes[0];
                          if (color_code) return color_code;
                        }
                      },
                      async (color_code) => {
                        const cv_obj_color_code = go(getFlattenCvDesigns([cv_obj]), head, (cv_obj) =>
                          cv_obj._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR]?.toUpperCase(),
                        );
                        if (color_code && cv_obj_color_code !== color_code.toUpperCase()) {
                          await OMPDosuF.cvObj.update.dosuColorToCvObj({ cv_obj, color_code });
                        }
                      },
                    );
                  }
                }
              }),
            ),
            ++pasted_count,
            when_copy_paste_location,
          ),
          paste_body,
          async function (active) {
            if (active) {
              const smallest_ratio = NewMakerCheckerF.getSmallestRatio({
                cv_obj: active,
                ratio: active.canvas._print_ratio,
              });
              const agree_image_size = G.mp.maker.cv_objects_deep_find(active, (co) => {
                if (co._data.cv_type !== 'cv_image') return;
                return co._data.is_low_quality_agree;
              });
              if (!agree_image_size && smallest_ratio < 1) {
                active.scale(active.scaleX * smallest_ratio);
                active.canvas.renderAll();
              }

              if (active && G.mp.maker.is_embro(active)) {
                G.mp.maker.cv_objects_deep_each(active, function (cv_obj) {
                  if (!cv_obj._data.is_embro) return;
                  const width = G.mp.maker.to_px('image_width_cm', cv_obj._data);
                  const height = G.mp.maker.to_px('image_height_cm', cv_obj._data);
                  cv_obj.set({ width, height }).scale(1).setCoords();
                  G.mp.maker.reset_data_cm(cv_obj);
                });
                active.canvas.renderAll();
              }
            }
            G.mp.maker.modified();
            NewMakerCallConditionPopupF.setAndRender(active);
            G.mp.maker.cleared_pass = false;
            G.mp.maker.selected_pass = false;
            if (G.mp.maker.active() === active) {
              await marpplizerProxy(active);
            }
          },
        ));
    } catch (e) {
      console.log(e);
    } finally {
      G.mp.maker.cleared_pass = false;
      G.mp.maker.selected_pass = false;
      $.don_loader_end();
    }
    // G.mp.maker.unselect_all();
  });
  Mousetrap.bind(['command+v', 'ctrl+v'], mouseTrapEventWrapper(paste));
  G.mp.maker.paste = paste;
  const duplicate = G.mp.maker.has_active_object_or_group(async function (canvas, active) {
    const mp_maker = $qs('.mp_maker');

    $.don_loader_start();
    try {
      const is_hard_computing = mp_maker?.dataset?.is_bad_computing_power === 'true';
      if (is_hard_computing) {
        await delay(200, () => {});
      }
      await processBulkCvObjsAdd(() => {
        _p.go(_p.mr(active, 1), paste_body, (active) => {
          G.mp.maker.modified();
          NewMakerCallConditionPopupF.setAndRender(active);
        });
      }, canvas);
    } catch (e) {
      console.log(e);
    } finally {
      $.don_loader_end();
    }
  });
  // 복제하기
  Mousetrap.bind(['command+d', 'ctrl+d'], mouseTrapEventWrapper(duplicate));
  G.mp.maker.duplicate = duplicate;
  function add_cloned(cloned, pasted_count, location) {
    const canvas = box.sel('maker->editing_canvas');
    G.mp.maker.cv_objects_deep_each(cloned, (cv_obj) => (cv_obj.cid = _p.uniqueId('c')));
    canvas.add(
      cloned.set(
        _extend(
          {
            left: cloned.left + PASTE_GAP * pasted_count,
            top: cloned.top + PASTE_GAP * pasted_count,
          },
          G.mp.maker.BASECONF(),
        ),
      ),
    );
    if (location) {
      const rect = cloned.getBoundingRect();
      cloned
        .set({
          left: location.left - rect.width / 2,
          top: location.top - rect.height / 2,
        })
        .setCoords();
    }
    return cloned;
  }

  function paste_body(cv_object, pasted_count, location) {
    return new Promise(function (resolve) {
      const canvas = box.sel('maker->editing_canvas');

      if (cv_object.type == 'group' && cv_object._data.cv_type !== 'cv_group') {
        G.mp.maker.cleared_pass = true;
        G.mp.maker.selected_pass = true;
        return _p.go(
          _p.mr(G.mp.maker.clone(cv_object), pasted_count, location),
          add_cloned,
          _p.tap(G.mp.maker.ungrouping_body),
          // (cloned) => {
          //   const cv_objs = getFlattenCvDesigns([cloned]);
          //   NewMakerDesignColorF.setDesignColorByPallets(canvas, cv_objs);
          // },
          async function (cloned) {
            canvas.discardActiveGroup().setActiveObject(cloned);
            await renderMakerPageShade(getShadeFcanvas(canvas), cloned);
            canvas.renderAll();
            G.mp.maker.selected_pass = false;
            await G.mp.maker.ungrouping_body();
            G.mp.maker.cleared_pass = false;
            canvas.renderAll();
            resolve(G.mp.maker.active());
          },
        );
      }
      return _p.go(
        _p.mr(G.mp.maker.clone(cv_object), pasted_count, location),
        add_cloned,
        async function (cloned) {
          if (cloned?._data?.is_fitted_to_print_width) {
            cloned._data.is_fitted_to_print_width = false;
          }
          canvas.setActiveObject(cloned);
          await renderMakerPageShade(getShadeFcanvas(canvas), cloned);
          canvas.renderAll();
          return cloned;
        },
        resolve,
      );
    });
  }
  function modified() {
    G.mp.maker.modified();
  }

  // 그룹핑/언그룹핑
  const grouping = G.mp.maker.has_active_group(function (canvas, activeGroup) {
    if (G.mp.maker.is_embro(activeGroup)) return $.alert(T('Embroidery patches can not be grouped.'));
    return _p.go(grouping_body(canvas, activeGroup), tap(modified), (cv_group) => {
      NewMakerCallConditionPopupF.setAndRender(cv_group);
    });
  });
  G.mp.maker.grouping = grouping;
  function grouping_body(canvas, activeGroup) {
    const objectsInGroup = activeGroup.getObjects();
    const group_attrs = activeGroup.toJSON();
    return _p.go(group_attrs, G.mp.maker.create_cv_group, function (cv_group) {
      go(
        objectsInGroup,
        some((cv_obj) => cv_obj._data.is_fitted_to_print_width),
        (fitted_cv_obj) => {
          if (!fitted_cv_obj) return;
          NewMakerFitPrintWidthF.activateDataFitToPrintWidth(cv_group);
        },
      );
      cv_group._data.nscreened = true;
      G.mp.maker.cleared_pass_discardActiveGroup(canvas);
      const index =
        _p.reduce(
          objectsInGroup,
          function (m, obj) {
            return Math.max(m, canvas.getObjects().indexOf(obj));
          },
          0,
        ) -
        (objectsInGroup.length - 1); // 합쳐진 개수 - 1 만큼 빼야됨
      _each(objectsInGroup, function (object) {
        canvas.remove(object);
      });
      canvas.insertAt(cv_group, index, false).setActiveObject(cv_group);
      return cv_group;
    });
  }
  G.mp.maker.grouping_body = G.mp.maker.has_active_group(grouping_body);
  G.mp.maker.grouping_body2 = grouping_body;
  function ungrouping_only(canvas, activeObject) {
    let index = canvas.getObjects().indexOf(activeObject);
    const visible = activeObject.visible;
    const cv_objects = activeObject._objects;
    activeObject._restoreObjectsState();
    canvas.remove(activeObject);

    let i = cv_objects.length;
    let cv_object;
    if (canvas.fcanvas_data?.maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR) {
      go(
        cv_objects,
        each((cv_object) => {
          cv_object.visible = visible;
          canvas.insertAt(cv_object, index, false);
          const cv_objs = go(
            canvas.getObjects(),
            reject((cv_obj) => cv_obj._data.clone_parent_cid),
          );
          const target_cv_obj = cv_objs[cv_objs.indexOf(cv_object) + 1] || cv_objs[cv_objs.length - 1];
          index = canvas.getObjects().indexOf(target_cv_obj);
        }),
      );
    } else {
      while ((cv_object = cv_objects[--i])) {
        cv_object.visible = visible;
        _p.extend(cv_object, G.mp.maker.BASECONF());
        canvas.insertAt(cv_object, index, false);
        // canvas.item(canvas.size() - 1).hasControls = true;
        cv_object.setCoords();
        cv_object.dirty = true;
      }
    }
    return _p.go(
      _p.cmap(cv_objects, function (cv_target) {
        if (cv_target && cv_target._data.cv_type === 'cv_text_image') {
          return setCvTextImagePaddingA(cv_target);
        }
      }),
      function () {
        return cv_objects;
      },
    );
  }
  G.mp.maker.ungrouping_only = ungrouping_only;

  const ungrouping = G.mp.maker.has_active_object_group(
    _p.pipe(ungrouping_body, tap(modified), (cv_objects) => {
      go(
        cv_objects,
        each((cv_obj) => NewMakerCallConditionPopupF.setAndRender(cv_obj)),
      );
    }),
  );
  async function ungrouping_body(canvas, activeObject) {
    const cv_objects = activeObject._objects;
    await ungrouping_only(canvas, activeObject);
    G.mp.maker.multiple_select(cv_objects, canvas);
    return cv_objects;
  }
  G.mp.maker.ungrouping = ungrouping;
  G.mp.maker.ungrouping_body = G.mp.maker.has_active_object_group(ungrouping_body);

  Mousetrap.bind(['command+g', 'ctrl+g'], mouseTrapEventWrapper(grouping));

  Mousetrap.bind(['command+shift+g', 'ctrl+shift+g'], mouseTrapEventWrapper(ungrouping));

  /* 플립 */
  function bflip(key) {
    return G.mp.maker.has_active_object_or_group(function (canvas, active) {
      if ($.has_class($1('.mp_maker'), 'has_embro')) return;
      active[key] = !active[key];
      const maker_type = box.sel('maker->product_color->_->base_product->maker_type');
      if (maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR) {
        NewMakerMaskingTapeF.setCloneHorizontalCvObjLocation(active, canvas);
      }
      canvas.renderAll();
      marpplizerProxy(active);
      modified();
    });
  }
  const flip_x = bflip('flipX');
  const flip_y = bflip('flipY');
  G.mp.maker.flip_x = flip_x;
  G.mp.maker.flip_y = flip_y;
  /* 앞으로, 뒤로 */
  function layer_align(method_name) {
    const canvas = box.sel('maker->editing_canvas');
    if (!canvas.getActiveGroup() && !canvas.getActiveObject()) return;
    const is_group = canvas.getActiveGroup();
    const maker_type = $qs('#maker_frame').dataset.maker_type;
    return _p.go(
      is_group ? G.mp.maker.grouping_body() : undefined,
      function () {
        return canvas.getActiveObject();
      },
      function (active) {
        if (maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR) {
          NewMakerMaskingTapeF.removeCloneHorizontalCvObj(active);
          const i =
            method_name === 'bringForward'
              ? NewMakerMaskingTapeConstantS.CLONE_OBJ_LENGTH * 2 + 1
              : -(NewMakerMaskingTapeConstantS.CLONE_OBJ_LENGTH * 2 + 1);
          const num = canvas._objects.indexOf(active);
          if (num + i < 0) {
            NewMakerMaskingTapeF.insertCloneHorizontalCvObj(active);
            return;
          }
          active.moveTo(num + i);
          NewMakerMaskingTapeF.insertCloneHorizontalCvObj(active);
        } else {
          active[method_name]();
        }
      },
      is_group ? G.mp.maker.ungrouping_body : _p.noop,
      _p.c(canvas),
      function (canvas) {
        orderFaceBackgroundInMaker(canvas);
      },
      G.mp.maker.modified,
    );
  }
  G.mp.maker.forward = _p(layer_align, 'bringForward');
  G.mp.maker.backward = _p(layer_align, 'sendBackwards');

  G.mp.maker.align_print_area_center = function (cv_object, canvas) {
    const p = G.mp.maker.cv_print_area(canvas);
    center(cv_object, p.getCenterPoint().x, p.getCenterPoint().y);
    return cv_object;
  };

  const center_h = G.mp.maker.has_active_object_or_group(function (canvas, active) {
    if (!active) return;
    const p_rect = (() => {
      const cv_obj_alignments = makeFilterCvObj(canvas._objects, 'cv_obj_alignment');
      if (cv_obj_alignments?.length) {
        return NewMakerUtilF.getNearCvObjAlignment(cv_obj_alignments, active);
      } else {
        return G.mp.maker.cv_print_area(canvas);
      }
    })();
    center(active, p_rect.getCenterPoint().x, active.getCenterPoint().y);
    canvas.renderAll();
    modified();
  });
  G.mp.maker.center_h = center_h;
  const center_v = G.mp.maker.has_active_object_or_group(function (canvas, active) {
    if (!active) return;
    const p_rect = (() => {
      const cv_obj_alignments = makeFilterCvObj(canvas._objects, 'cv_obj_alignment');
      if (cv_obj_alignments?.length) {
        return NewMakerUtilF.getNearCvObjAlignment(cv_obj_alignments, active);
      } else {
        return G.mp.maker.cv_print_area(canvas);
      }
    })();
    center(active, active.getCenterPoint().x, p_rect.getCenterPoint().y);
    canvas.renderAll();
    modified();
  });
  G.mp.maker.center_v = center_v;
  function center(cv_object, x, y) {
    cv_object.setPositionByOrigin(new fabric.Point(x, y), 'center', 'center');
    cv_object.setCoords();
    const maker_type = box.sel('maker->product_color->_->base_product->maker_type');
    if (maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR) {
      if (cv_object.canvas) {
        NewMakerMaskingTapeF.setCloneHorizontalCvObjLocation(cv_object, cv_object.canvas);
      }
    }
  }

  G.mp.maker.center = center;
  G.mp.maker.center_mode = {};
  G.mp.maker.center_mode.SENS = 4;
  G.mp.maker.center_mode.init = function () {
    if (!G.mp.maker.editing_canvas()) return;
    G.mp.maker.center_mode.active = !isNoNeedAutoCenter();

    G.mp.maker.canvas_container = $('.canvas_container')[G.mp.maker.editing_canvas_idx()];
    _p.go(
      G.mp.maker.canvas_container,
      _p.tap($.find1('canvas'), function (el_canvas) {
        G.mp.maker.center_mode.width = $.width(el_canvas);
        G.mp.maker.center_mode.height = $.height(el_canvas);
      }),
    );
    G.mp.maker.center_mode.targets = [];
    const cv_obj_alignments = makeFilterCvObj(G.mp.maker.editing_canvas()._objects, 'cv_obj_alignment');

    if (cv_obj_alignments?.length) {
      go(
        cv_obj_alignments,
        each((cv_obj_alignment) => {
          G.mp.maker.center_mode.targets.push(
            cond_center(cv_obj_alignment.getCenterPoint().y, {
              center: true,
            }),
          );
          G.mp.maker.center_mode.targets.push(
            cond_center(cv_obj_alignment.getCenterPoint().x, {
              center: true,
              is_horizontal: true,
            }),
          );
          G.mp.maker.center_mode.targets.push(
            cond_center(cv_obj_alignment.left, {
              side: 'left',
              is_horizontal: true,
              dont_show: true,
            }),
          );
          G.mp.maker.center_mode.targets.push(
            cond_center(cv_obj_alignment.left + cv_obj_alignment.width, {
              side: 'right',
              is_horizontal: true,
              dont_show: true,
            }),
          );
          G.mp.maker.center_mode.targets.push(
            cond_center(cv_obj_alignment.top, {
              side: 'top',
              is_horizontal: false,
              dont_show: true,
            }),
          );
          G.mp.maker.center_mode.targets.push(
            cond_center(cv_obj_alignment.top + cv_obj_alignment.height, {
              side: 'bottom',
              is_horizontal: false,
              dont_show: true,
            }),
          );
        }),
      );
    } else {
      G.mp.maker.center_mode.cv_print_area = G.mp.maker.cv_print_area(G.mp.maker.editing_canvas());
      if (!NewMakerProductStyleS.isSlipper(getBaseProductInMaker()))
        G.mp.maker.center_mode.targets.push(
          cond_center(G.mp.maker.center_mode.cv_print_area.getCenterPoint().x, {
            center: true,
            is_horizontal: true,
          }),
        );
      if (
        !NewMakerProductStyleS.isMugCup(getBaseProductInMaker()) &&
        !NewMakerProductStyleS.isWatchBand(getBaseProductInMaker())
      )
        G.mp.maker.center_mode.targets.push(
          cond_center(G.mp.maker.center_mode.cv_print_area.getCenterPoint().y, {
            center: true,
          }),
        );
      const getBoundingRect = get_bounding_print_rect_for_align(
        _p.v(G.mp.maker.center_mode.cv_print_area, '_data.print_px'),
      );
      G.mp.maker.center_mode.targets.push(
        cond_center(getBoundingRect.left, {
          side: 'left',
          is_horizontal: true,
          dont_show: true,
        }),
      );
      G.mp.maker.center_mode.targets.push(
        cond_center(getBoundingRect.right, {
          side: 'right',
          is_horizontal: true,
          dont_show: true,
        }),
      );
      G.mp.maker.center_mode.targets.push(
        cond_center(getBoundingRect.top, {
          side: 'top',
          is_horizontal: false,
          dont_show: true,
        }),
      );
      G.mp.maker.center_mode.targets.push(
        cond_center(getBoundingRect.bottom, {
          side: 'bottom',
          is_horizontal: false,
          dont_show: true,
        }),
      );
    }
  };

  G.mp.maker.center_mode.auto_center = function (target) {
    return _p.go(
      G.mp.maker.center_mode.targets,
      _p.map(function (v) {
        return v(target);
      }),
      _p.all(__(_p.map(_p.v('horizontal')), _p.some), __(_p.map(_p.v('vertical')), _p.some)),
      _p.tap(G.mp.maker.center_mode.show_cond),
    );
  };

  G.mp.maker.center_mode.show_cond = function (hori, vert) {
    _p.go(G.mp.maker.canvas_container, $.find1('.cross.horizontal'), _p.if2(_p.c(hori))($.show).else($.hide));
    _p.go(G.mp.maker.canvas_container, $.find1('.cross.vertical'), _p.if2(_p.c(vert))($.show).else($.hide));
    if (!hori && !vert) _p.go(G.mp.maker.canvas_container, $.find('.cross'), $.hide);
  };

  function auto_coord(cv_object, getBoundingRect, bg_target_coord, side) {
    if (side == 'right') {
      cv_object.left -= getBoundingRect[side] - bg_target_coord;
      cv_object.set('left', cv_object.left);
    } else if (side == 'bottom') {
      cv_object.top -= getBoundingRect[side] - bg_target_coord;
      cv_object.set('top', cv_object.top);
    } else {
      cv_object[side] -= getBoundingRect[side] - bg_target_coord;
      cv_object.set(side, cv_object[side]);
    }
    cv_object.setCoords();
  }

  function cond_center(bg_target_coord, option) {
    return function (cv_object) {
      const getCenterPoint = cv_object.getCenterPoint();
      // var getBoundingRect = cv_object.getBoundingRect();
      const getBoundingRect = get_obj_coord(cv_object);
      const target_is_center =
        Math.abs(
          bg_target_coord -
            (option.center ? getCenterPoint[option.is_horizontal ? 'x' : 'y'] : getBoundingRect[option.side]),
        ) < G.mp.maker.center_mode.SENS;
      if (target_is_center) {
        if (option.is_horizontal) {
          if (!option.check) {
            if (option.center) center(cv_object, bg_target_coord, getCenterPoint.y);
            else auto_coord(cv_object, getBoundingRect, bg_target_coord, option.side);
          }
          if (option.dont_show)
            _go(G.mp.maker.canvas_container, $.find1('.horizontal'), $.css({ left: -10000 }));
          else
            _go(
              G.mp.maker.canvas_container,
              $.find1('.horizontal'),
              $.css({
                top: 0,
                left:
                  parseInt($.css($.find1(G.mp.maker.canvas_container, '.canvas-container'), 'marginLeft')) +
                  (bg_target_coord * G.mp.maker.center_mode.width) / G.mp.maker.CANVAS_WIDTH -
                  1,
              }),
            );
          return { vertical: false, horizontal: true };
        } else {
          if (!option.check) {
            if (option.center) center(cv_object, getCenterPoint.x, bg_target_coord);
            else auto_coord(cv_object, getBoundingRect, bg_target_coord, option.side);
          }
          if (option.dont_show)
            _go(G.mp.maker.canvas_container, $.find1('.vertical'), $.css({ top: -10000 }));
          else
            _go(
              G.mp.maker.canvas_container,
              $.find1('.vertical'),
              $.css({
                top: (bg_target_coord * G.mp.maker.center_mode.height) / G.mp.maker.CANVAS_HEIGHT - 1,
              }),
            );
          return { vertical: true, horizontal: false };
        }
      } else {
        return { vertical: false, horizontal: false };
      }
    };
  }

  G.mp.maker.cleared_pass_discardActiveGroup = function (canvas) {
    G.mp.maker.cleared_pass = true;
    canvas.discardActiveGroup();
    G.mp.maker.cleared_pass = false;
  };

  G.mp.maker.center_mode.hide = function () {
    _go($('#maker_frame .canvas_container .cross'), $.hide);
  };
  function bgroup_align(setter) {
    return G.mp.maker.has_active_group(function (canvas, group) {
      if (!group) return;
      const cv_objects = group._objects;
      G.mp.maker.cleared_pass_discardActiveGroup(canvas);
      setter(
        _map(cv_objects, function (active) {
          return get_obj_coord(active);
        }),
        cv_objects,
      );
      G.mp.maker.multiple_select(cv_objects);
      canvas.renderAll();
      modified();
    });
  }
  const group_left = bgroup_align(function (rects, cv_objects) {
    const left = _p.min(_p.pluck(rects, 'left'));
    _p.each(cv_objects, function (cv_object, i) {
      cv_object.set('left', left + cv_object.left - rects[i].left).setCoords();
    });
  });
  G.mp.maker.group_left = group_left;
  const group_top = bgroup_align(function (rects, cv_objects) {
    const top = _p.min(_p.pluck(rects, 'top'));
    _p.each(cv_objects, function (cv_object, i) {
      cv_object.set('top', top + cv_object.top - rects[i].top).setCoords();
    });
  });
  G.mp.maker.group_top = group_top;
  const group_right = bgroup_align(function (rects, cv_objects) {
    // var rs = rights(rects);
    const r = _p.max(_p.pluck(rects, 'right'));
    _p.each(cv_objects, function (cv_object, i) {
      cv_object.left = r;
      const rect = get_obj_coord(cv_object);
      cv_object.left -= rect.right - r;
      cv_object.set('left', cv_object.left).setCoords();
    });
  });
  G.mp.maker.group_right = group_right;
  const group_bottom = bgroup_align(function (rects, cv_objects) {
    // var bs = bottoms(rects);
    const b = _p.max(_p.pluck(rects, 'bottom'));
    _p.each(cv_objects, function (cv_object, i) {
      cv_object.top = b;
      const rect = get_obj_coord(cv_object);
      cv_object.top -= rect.bottom - b;
      cv_object.set('top', cv_object.top).setCoords();
    });
  });
  G.mp.maker.group_bottom = group_bottom;
  const group_center_h = bgroup_align(function (rects, cv_objects) {
    const left = _p.min(_p.pluck(rects, 'left'));
    const right = _p.max(_p.pluck(rects, 'right'));
    const x = left + (right - left) / 2;
    _each(cv_objects, function (cv_object) {
      center(cv_object, x, cv_object.getCenterPoint().y);
    });
  });
  G.mp.maker.group_center_h = group_center_h;
  const group_center_v = bgroup_align(function (rects, cv_objects) {
    const top = _p.min(_p.pluck(rects, 'top'));
    const bottom = _p.max(_p.pluck(rects, 'bottom'));
    const y = top + (bottom - top) / 2;
    _each(cv_objects, function (cv_object) {
      center(cv_object, cv_object.getCenterPoint().x, y);
    });
  });
  G.mp.maker.group_center_v = group_center_v;
  function get_obj_coord(active) {
    const coord = active.calcCoords(true);
    const xs = _p.pluck(coord, ['x']);
    const ys = _p.pluck(coord, ['y']);

    return {
      left: _p.min(xs),
      right: _p.max(xs),
      top: _p.min(ys),
      bottom: _p.max(ys),
    };
  }
  G.mp.maker.get_obj_coord = get_obj_coord;
  function get_bounding_print_rect(print_px) {
    print_px = print_px || _p.v(G.mp.maker.cv_print_area(), '_data.print_px');
    return {
      top: print_px.top + (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2,
      left: print_px.left,
      right: print_px.left + print_px.width,
      bottom: print_px.top + print_px.height + (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2,
    };
  }
  function get_bounding_print_rect_for_align(print_px) {
    if (print_px) {
      const plus_px = (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2;
      const px = print_px;
      return {
        top: px.top + plus_px,
        left: px.left,
        right: px.left + px.width,
        bottom: px.top + px.height + plus_px,
      };
    } else {
      const px = minusStrokeWidth(G.mp.maker.cv_print_area());
      return {
        top: px.top,
        left: px.left,
        right: px.left + px.width,
        bottom: px.top + px.height,
      };
    }
  }
  G.mp.maker.get_bounding_print_rect = get_bounding_print_rect;

  function b_align(setter) {
    return G.mp.maker.has_active_object_or_group(function (canvas, active) {
      const p = G.mp.maker.cv_print_area(canvas);
      const p_rect = (() => {
        const cv_obj_alignments = makeFilterCvObj(canvas._objects, 'cv_obj_alignment');
        if (cv_obj_alignments?.length) {
          return get_obj_coord(NewMakerUtilF.getNearCvObjAlignment(cv_obj_alignments, active));
        } else {
          return get_bounding_print_rect_for_align(_p.v(p, '_data.print_px'));
        }
      })();
      const a_rect = get_obj_coord(active);
      setter(active, p_rect, a_rect);
      active.setCoords();
      const maker_type = box.sel('maker->product_color->_->base_product->maker_type');
      if (maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR) {
        NewMakerMaskingTapeF.setCloneHorizontalCvObjLocation(active, canvas);
      }
      checkIsOverflowInCurrentFace();
      canvas.renderAll();
      modified();
    });
  }
  const left = b_align(function (active, p_rect, a_rect) {
    active.left -= a_rect.left - p_rect.left;
    active.set('left', active.left);
  });
  const right = b_align(function (active, p_rect, a_rect) {
    active.left += p_rect.right - a_rect.right;
    active.set('left', active.left);
  });
  const top = b_align(function (active, p_rect, a_rect) {
    active.top -= a_rect.top - p_rect.top;
    active.set('top', active.top);
  });
  const bottom = b_align(function (active, p_rect, a_rect) {
    active.top += p_rect.bottom - a_rect.bottom;
    active.set('top', active.top);
  });

  G.mp.maker.top = top;
  G.mp.maker.left = left;
  G.mp.maker.right = right;
  G.mp.maker.bottom = bottom;

  function ban_frame_show() {
    if (!UtilF.isLegacyMobile()) return;
    $.add_class($1('html'), 'maker_menu_just_go_out clear_maker_canvas');
    NewMakerMenuF.hide();
  }

  G.mp.maker.ban_frame_show = ban_frame_show;
  function ban_frame_hide() {
    if (!UtilF.isLegacyMobile()) return;
    $.remove_class($1('html'), 'maker_menu_just_go_out');
    setTimeout(function () {
      $.remove_class($1('html'), 'clear_maker_canvas');
    }, 500);
  }
  G.mp.maker.ban_frame_hide = ban_frame_hide;
})({});
