import {
  createWebglProgram,
  getRectangle,
  prepareRender,
  setupAttrBufferData,
  setupImageTexture,
  transferBufferToLocation,
} from './shaders.js';
import imageVertexShaderSrc from '../../shaders/vertex/image.vertex.glsl';

export function loadBaseWebGlImageCanvas({ image, fragmentShaderSrc }) {
  const { width: w, height: h } = image;
  const gl = createWebglCanvas({
    w,
    h,
  });

  /* Create webgl program linked with shaders */
  const webglProgram = createWebglProgram({
    gl,
    vertexShaderSrc: imageVertexShaderSrc,
    fragmentShaderSrc,
  });

  /* save position (triangle) to buffer */
  const positionBuffer = setupAttrBufferData({
    gl,
    data: getRectangle({ w: 1.0, h: 1.0 }),
  });

  /* save texture to buffer */
  setupImageTexture({ gl, image });

  /* Prepare webgl rendering */
  prepareRender({ gl, w: gl.canvas.width, h: gl.canvas.height });

  gl.useProgram(webglProgram);

  /* Transfer attribute values */
  transferBufferToLocation({
    gl,
    webglProgram,
    attrName: 'a_position',
    arrayBuffer: positionBuffer,
    size: 2,
  });
  return { gl, webglProgram };
}

export function createWebglCanvas({ w, h, appendTo }) {
  const canvas = document.createElement('canvas');
  appendTo && appendTo.appendChild(canvas);
  canvas.width = w;
  canvas.height = h;

  const gl = canvas.getContext('webgl', { antialias: true, preserveDrawingBuffer: false });
  if (!gl) {
    throw new Error('Browser cannot support webgl');
  }

  return gl;
}
