import '../../../../Event/List/F/route.js';
import { go, identity } from 'fxjs/es';
export const defnDfEventListRoute = () =>
  $.frame(
    {
      frame_name: 'mp.event.list',
    },
    {
      page_name: 'mp.event.list',
      tabs: [
        {
          tab_name: 'mp.event.list',
          appended: $.on2('click', 'button#new', () =>
            go(
              $.confirm('이벤트를 새로 만드시겠습니까?'),
              _p.if2(identity)(
                () => $.post('/@api/event'),
                (e) => (location.href = `/event/detail/${e.id}/update`),
              ),
            ),
          ),
        },
      ],
    },
  );
