import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSingleMobilePathEditorMuiF } from '../Mui/module/VectorEditorStickerSingleMobilePathEditorMuiF.js';

export const editPath = ({ single_sticker_editor, path_el }) =>
  MuiF.openFrame(VectorEditorStickerSingleMobilePathEditorMuiF.frame, (frame, page, [tab]) => {
    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_single_sticker_editor = single_sticker_editor;
      tab_el.__mp_path_el = path_el;
      return tab_appending.call(tab, tab_el);
    };
  });
