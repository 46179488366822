import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSingleMobileCuttingLineAutoMuiF } from '../Mui/module/VectorEditorStickerSingleMobileCuttingLineAutoMuiF.js';

export const makeCuttingLineAuto = async ({ single_sticker_editor, postProcess }) =>
  MuiF.pushPage(VectorEditorStickerSingleMobileCuttingLineAutoMuiF.page, (page, [tab]) => {
    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_single_sticker_editor = single_sticker_editor;
      tab_el.__mp_postProcess = postProcess;
      return tab_appending.call(tab, tab_el);
    };
  }).catch(async (error) => {
    await MuiF.popPage();
    throw error;
  });
