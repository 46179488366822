const red = '#ff0000';
const blue = '#0000ff';
export const STAMP_EDITOR = 'STAMP';
export const stamps = {
  s5773: [
    {
      color_code: red,
      texture_url:
        '//s3.marpple.co/files/u_193535/2022/8/original/a072d264d62a910e9a877fbdcefa6b8f7d81d0471.png',
    },
    {
      color_code: blue,
      texture_url:
        '//s3.marpple.co/files/u_193535/2022/8/original/83feb84d999a2febadd3c5d8d5ae4dfa175a48971.png',
    },
  ],
};
