import { go } from 'fxjs/es';

import { createCanvasElement } from '../../../Canvas/S/util.js';
import { mappingBilinearInterpolation } from './composite_core.js';

// import glfx from "./glfx.js";
// function makeCircleWarpedImage(src_img) {
//   return go(
//     makeCanvasByImg(src_img),
//     src_c => {
//       const { width: src_width, height: src_height } = src_c;
//       const dest_width = src_width / Math.PI;
//       return [src_c, createCanvasElement({ width: dest_width, height: src_height })];
//     },
//     ([src_c, dest_c]) => {
//       const src_ctx = src_c.getContext('2d');
//       const dest_ctx = dest_c.getContext('2d');
//       const src_image_data = src_ctx.getImageData(0, 0, src_c.width, src_c.height).data;
//       const dest_image_data = dest_ctx.getImageData(0, 0, dest_c.width, dest_c.height).data;
//       for (let y; y < dest_c.height; y++) {
//         const src_row = src_image_data.slice(y * src_c.width * 4, (y + 1) * src_c.width * 4);
//         for (let x; x < dest_c.width; x++) {
//           const idx = (x + y * dest_c.width) * 4;
//           const start = x / dest_c.width;
//           const rgba = src_row.slice();
//           mappingBilinearInterpolation(
//             src_pixel_arr,
//             dest_pixel_arr,
//             src_x,
//             src_y,
//             src_width,
//             src_height,
//             dest_x,
//             dest_y,
//             dest_width
//           )
//           dest_image_data[idx] = dest_image_data[idx + 1];
//           dest_image_data[idx + 2];
//           dest_image_data[idx + 3];
//         }
//       }
//     }
//   );
// }

// function circleEq(x) {
//   if (x >= 0 && x <= 0.5) {
//     return Math.sqrt(0.25 - Math.pow(x - 0.5, 2));
//   } else if (x > 0.5 && x <= 1) {
//     return 1 - Math.sqrt(0.25 - Math.pow(x - 0.5, 2));
//   }
// }
//
// function fa(x, w, f, h, u, v) {
//   return (Math.pow(x * u - f, 1 / w) + h) / v;
// }
// function fb(x, a, b, c, s, t) {
//   return (-Math.pow(b - x * s, a) + c) / t;
// }
//
// function curveF(x) {
//   if (x <= 0 && x <= 0.5) {
//     return fb(x, 1.5, 0, 0, 2, 2);
//   } else {
//     return fa(x, 0.75, 1, 1, 2, 2);
//   }
// }
// function toRadians(angle) {
//   return angle * (Math.PI / 180);
// }

function toDegrees(radians) {
  const pi = Math.PI;
  return radians * (180 / pi);
}

function ffff(x, r) {
  const ax = x >= 0 && x <= 0.5 ? -x : x - 1;
  return 2 * Math.PI * r * (toDegrees(Math.acos((ax + r) / r)) / 360);
}

export function deCylinderizeSize({ width, height, top, left }) {
  const _width = (width / 2) * Math.PI;
  return {
    width: _width,
    height,
    top,
    left: left + (width - _width) / 2,
  };
}

export function enCylinderizeSize({ width, height, top = 0, left = 0 }, cylinder) {
  const _width = (width / Math.PI) * 2;
  const ratio = width / cylinder.area.width;
  const up_side = ratio * cylinder.up_side;
  const down_side = ratio * cylinder.down_side;
  if (up_side < 0) {
    height += Math.abs(up_side);
    top -= Math.abs(up_side);
  }
  if (down_side > 0) {
    height += down_side;
  }
  return {
    width: _width,
    height,
    top,
    left: left + (width - _width) / 2,
  };
}

function is1(image_data, idx) {
  return image_data[idx + 3] === 255;
}

function is0to1(image_data, idx, width = 1) {
  const before_idx = idx - width * 4;
  return is1(image_data, idx) && !is1(image_data, before_idx);
}

export function is1to0(image_data, idx, width = 1) {
  const after_idx = idx + width * 4;
  return is1(image_data, idx) && !is1(image_data, after_idx);
}

function fillPixel(src_image_data, dest_image_data, idx, strength, s, width = 1) {
  if (strength > 0) {
    for (let i = 0; i < strength; i++) {
      dest_image_data[idx + i * width * 4 + 0] = s;
      dest_image_data[idx + i * width * 4 + 1] = s;
      dest_image_data[idx + i * width * 4 + 2] = s;
      dest_image_data[idx + i * width * 4 + 3] = src_image_data[idx + i * width * 4 + 3];
    }
  } else if (strength < 0) {
    for (let i = 0; i > strength; i--) {
      dest_image_data[idx + i * width * 4 + 0] = s;
      dest_image_data[idx + i * width * 4 + 1] = s;
      dest_image_data[idx + i * width * 4 + 2] = s;
      dest_image_data[idx + i * width * 4 + 3] = src_image_data[idx + i * width * 4 + 3];
    }
  }
}

export async function makeShadowedCanvas(src_c, strength, blur, s) {
  const dest_c = createCanvasElement({ width: src_c.width, height: src_c.height });
  const src_image_data = src_c.getContext('2d').getImageData(0, 0, src_c.width, src_c.height);
  const result_c = dest_c;
  const result_ctx = result_c.getContext('2d');
  const result_image_data = result_ctx.getImageData(0, 0, result_c.width, result_c.height);
  for (let y = 0; y < result_c.height; y++)
    for (let x = 0; x < result_c.width; x++) {
      const idx = (x + y * result_c.width) * 4;
      if (src_image_data.data[idx + 3] !== 255) {
        result_image_data.data[idx + 0] = s;
        result_image_data.data[idx + 1] = s;
        result_image_data.data[idx + 2] = s;
        result_image_data.data[idx + 3] = src_image_data.data[idx + 3];
      }
      if (is0to1(src_image_data.data, idx)) {
        fillPixel(src_image_data.data, result_image_data.data, idx, strength, s, 1);
      }
      if (is1to0(src_image_data.data, idx)) {
        fillPixel(src_image_data.data, result_image_data.data, idx, -strength, s, 1);
      }
      if (is0to1(src_image_data.data, idx, result_c.width)) {
        fillPixel(src_image_data.data, result_image_data.data, idx, strength, s, result_c.width);
      }
      if (is1to0(src_image_data.data, idx, result_c.width)) {
        fillPixel(src_image_data.data, result_image_data.data, idx, -strength, s, result_c.width);
      }
    }
  result_ctx.putImageData(result_image_data, 0, 0);
  return result_c;
  // return makeImageBlur(await loadImageFromUrl(result_c.toDataURL()), blur);
}

export async function makeShadowedCanvas2(src_c, strength, blur, s) {
  const dest_c = createCanvasElement({ width: src_c.width, height: src_c.height });
  const src_image_data = src_c.getContext('2d').getImageData(0, 0, src_c.width, src_c.height);
  const result_c = dest_c;
  const result_ctx = result_c.getContext('2d');
  const result_image_data = result_ctx.getImageData(0, 0, result_c.width, result_c.height);
  for (let y = 0; y < result_c.height; y++)
    for (let x = 0; x < result_c.width; x++) {
      const idx = (x + y * result_c.width) * 4;
      if (src_image_data.data[idx + 3] !== 255) {
        result_image_data.data[idx + 0] = s;
        result_image_data.data[idx + 1] = s;
        result_image_data.data[idx + 2] = s;
        result_image_data.data[idx + 3] = src_image_data.data[idx + 3];
      }
      if (is0to1(src_image_data.data, idx)) {
        fillPixel(src_image_data.data, result_image_data.data, idx, strength, s, 1);
      }
      if (is1to0(src_image_data.data, idx)) {
        fillPixel(src_image_data.data, result_image_data.data, idx, -strength, s, 1);
      }
      if (is0to1(src_image_data.data, idx, result_c.width)) {
        fillPixel(src_image_data.data, result_image_data.data, idx, strength, s, result_c.width);
      }
      if (is1to0(src_image_data.data, idx, result_c.width)) {
        fillPixel(src_image_data.data, result_image_data.data, idx, -strength, s, result_c.width);
      }
    }
  result_ctx.putImageData(result_image_data, 0, 0);
  return result_c;
  // return makeImageBlur(await loadImageFromUrl(result_c.toDataURL()), blur);
}

function cccc(w, h, x) {
  return Math.sqrt((1 - Math.pow(x, 2) / Math.pow(w, 2)) * Math.pow(h, 2));
}

function makeX(x, src, dest) {
  const de_x = x / dest.width;
  if (de_x >= 0 && de_x <= 0.5) {
    return ffff(de_x, 0.5) * (0.5 / ffff(0.5, 0.5)) * src.width;
  } else if (de_x > 0.5 && de_x <= 1) {
    return (ffff(0.5, 0.5) * 2 - ffff(de_x, 0.5)) * (1 / (2 * ffff(0.5, 0.5))) * src.width;
  }
}

function makeY(x, y, src_c, dest_c, up_side, down_side) {
  let start_y = 0;
  if (up_side > 0) {
    start_y += up_side - cccc(dest_c.width / 2, up_side, x - dest_c.width / 2);
  } else {
    start_y += cccc(dest_c.width / 2, up_side, x - dest_c.width / 2);
  }

  let end_y = dest_c.height;
  if (down_side < 0) {
    end_y = end_y + down_side + cccc(dest_c.width / 2, down_side, x - dest_c.width / 2);
  } else {
    end_y -= cccc(dest_c.width / 2, down_side, x - dest_c.width / 2);
  }

  const y_length = end_y - start_y;
  const ratio_y = (y - start_y) / y_length;
  if (y >= start_y && y <= end_y) {
    return ratio_y * src_c.height;
  }
}

function _makeOtherPoint(src_c, dest_c, ratio, point, type) {
  const diff = (src_c[type] - dest_c[type]) / 2;
  if (ratio < 0) return point - diff;
  if (ratio > 1) return point + diff * 2;
  return point;
}

export function getCylinderWarppedCoord(dest_x, dest_y, src_c, dest_c, up_side, down_side) {
  const ratio_x = dest_x / dest_c.width;
  const ratio_y = dest_y / dest_c.height;
  return {
    x: ratio_x < 0 || ratio_x > 1 ? dest_x : makeX(dest_x, src_c, dest_c),
    y:
      ratio_y < 0 || ratio_y > 1 || ratio_x < 0 || ratio_x > 1
        ? dest_y
        : makeY(dest_x, dest_y, src_c, dest_c, up_side, down_side),
  };
}
G.mp.maker.getCylinderWarppedCoord = getCylinderWarppedCoord;

export function getCylinderWarppedCoord2(dest_x, dest_y, src_c, dest_c, up_side, down_side) {
  const ratio_x = dest_x / dest_c.width;
  const ratio_y = dest_y / dest_c.height;
  return {
    x:
      ratio_x < 0 || ratio_x > 1
        ? _makeOtherPoint(src_c, dest_c, ratio_x, dest_x, 'width')
        : makeX(dest_x, src_c, dest_c),
    y:
      ratio_y < 0 || ratio_y > 1 || ratio_x < 0 || ratio_x > 1
        ? _makeOtherPoint(src_c, dest_c, ratio_y, dest_y, 'height')
        : makeY(dest_x, dest_y, src_c, dest_c, up_side, down_side),
  };
}
G.mp.maker.getCylinderWarppedCoord2 = getCylinderWarppedCoord2;

export function makeCircleWarpedCanvas(src_c, cylinder) {
  const ratio = src_c.width / cylinder.area.width;
  const up_side = ratio * cylinder.up_side;
  const down_side = ratio * cylinder.down_side;
  return go(
    src_c,
    (src_c) => {
      const dest_size = enCylinderizeSize(src_c, cylinder);
      return [src_c, createCanvasElement({ width: dest_size.width, height: dest_size.height })];
    },
    ([src_c, dest_c]) => {
      const src_ctx = src_c.getContext('2d');
      const dest_ctx = dest_c.getContext('2d');
      const src_image_data = src_ctx.getImageData(0, 0, src_c.width, src_c.height).data;
      const img_data = dest_ctx.getImageData(0, 0, dest_c.width, dest_c.height);
      const dest_image_data = img_data.data;
      for (let y = 0; y < dest_c.height; y++) {
        for (let x = 0; x < dest_c.width; x++) {
          const idx = (x + y * dest_c.width) * 4;
          mappingBilinearInterpolation(
            src_image_data,
            dest_image_data,
            makeX(x, src_c, dest_c),
            makeY(x, y, src_c, dest_c, up_side, down_side),
            src_c.width,
            idx,
          );
        }
      }
      dest_ctx.putImageData(img_data, 0, 0);
      return dest_c;
    },
  );
}

export function secondShot(src_c, up_side = 0, down_side = 0) {
  return go(
    src_c,
    (src_c) => {
      let height = src_c.height;
      if (up_side > 0) height += up_side;
      if (down_side > 0) height += down_side;
      // if (sub) dest_size.width -= sub;
      return [src_c, createCanvasElement({ width: src_c.width, height })];
    },
    ([src_c, dest_c]) => {
      const src_ctx = src_c.getContext('2d');
      const dest_ctx = dest_c.getContext('2d');
      const src_image_data = src_ctx.getImageData(0, 0, src_c.width, src_c.height).data;
      const img_data = dest_ctx.getImageData(0, 0, dest_c.width, dest_c.height);
      const dest_image_data = img_data.data;
      for (let y = 0; y < dest_c.height; y++) {
        for (let x = 0; x < dest_c.width; x++) {
          const idx = (x + y * dest_c.width) * 4;
          const src_x = x;
          let start_y = 0;
          start_y += cccc(dest_c.width / 2, up_side, x - dest_c.width / 2);
          let end_y = dest_c.height;
          end_y -= -cccc(dest_c.width / 2, down_side, x - dest_c.width / 2);
          const y_length = end_y - start_y;
          let src_y;
          if (y >= start_y && y <= end_y) {
            if (y / y_length <= 1) {
              src_y = ((y - start_y) / y_length) * src_c.height;
            }
          }
          mappingBilinearInterpolation(src_image_data, dest_image_data, src_x, src_y, src_c.width, idx);
        }
      }
      dest_ctx.putImageData(img_data, 0, 0);
      return dest_c;
    },
  );
}

export const convertURLtoCanvas = async (url) => {
  const canvas = document.createElement('canvas');
  const img = new Image();
  img.src = url;
  return new Promise((resolve, reject) => {
    img.addEventListener('load', function () {
      const { width, height } = img;
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas);
    });
    img.addEventListener('error', function (event) {
      reject(event);
    });
  });
};

// export const makeImageBlur = (image, blurPixelLength) => {
//   const { width: w, height: h } = image;
//   const glfx_canvas = glfx.canvas();
//   const texture = glfx_canvas.texture(image);
//   glfx_canvas
//     .draw(texture)
//     .triangleBlur(blurPixelLength)
//     .update();
//   const output_canvas = document.createElement('canvas');
//   output_canvas.width = w;
//   output_canvas.height = h;
//   const output_ctx = output_canvas.getContext('2d');
//   output_ctx.drawImage(glfx_canvas, 0, 0);
//   return output_canvas;
// };

export const showCanvasToScreen = (canvas, label, class_name) => {
  const divEl = document.createElement('div');
  const labelEl = document.createElement('p');
  labelEl.innerText = label;
  divEl.appendChild(labelEl);
  divEl.className = 'imgBox';
  divEl.className += ' ' + class_name;
  divEl.appendChild(canvas);
  document.body.appendChild(divEl);
};

export const convertURLtoImgObj = async (url) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve, reject) => {
    img.addEventListener('load', function () {
      resolve(img);
    });
    img.addEventListener('error', function (event) {
      reject(event);
    });
  });
};
// export function detectEdgeMask(
//   mask_image,
//   line_color_black_amount,
//   blur_strength,
//   is_dashed = false,
//   number_of_dashed_lines = 0,
//   dashed_line_cut_width = 0
// ) {
//   console.time('edge_detection: ');
//   const { width, height } = mask_image;
//   // const mask_canvas = makeImageBlur(mask_image, blur_strength);
//   const mask_canvas = makeImageBlur(mask_image, blur_strength);
//   const mask_ctx = mask_canvas.getContext('2d');
//   const mask_pixel_arr = mask_ctx.getImageData(0, 0, width, height).data;
//   const edge_pixel_arr = new Uint8ClampedArray(width * height * 4);
//   const convertPixelPointToArrIndex = (x, y, width) => (x + y * width) * 4;
//   const square_length = Math.max(width, height);
//   const margin_length =
//     (square_length - dashed_line_cut_width * number_of_dashed_lines) / (number_of_dashed_lines + 1);
//   for (let src_y = 0; src_y < height; src_y++) {
//     const r_y = is_dashed && src_y % (margin_length + dashed_line_cut_width);
//     for (let src_x = 0; src_x < width; src_x++) {
//       const r_x = is_dashed && src_x % (margin_length + dashed_line_cut_width);
//       if (
//         is_dashed &&
//         ((r_x >= margin_length && r_x < margin_length + dashed_line_cut_width) ||
//           (r_y >= margin_length && r_y < margin_length + dashed_line_cut_width))
//       ) {
//         continue;
//       }
//       const src_x1 = src_x;
//       const src_x0 = src_x - 1 < 0 ? 0 : src_x - 1;
//       const src_x2 = src_x + 1 > width - 1 ? width - 1 : src_x + 1;
//       const src_y1 = src_y;
//       const src_y0 = src_y - 1 < 0 ? 0 : src_y - 1;
//       const src_y2 = src_y + 1 > height - 1 ? height - 1 : src_y + 1;
//       const src_idx_tl = convertPixelPointToArrIndex(src_x0, src_y0, width);
//       const src_idx_tr = convertPixelPointToArrIndex(src_x2, src_y0, width);
//       const src_idx_cc = convertPixelPointToArrIndex(src_x1, src_y1, width);
//       const src_idx_bl = convertPixelPointToArrIndex(src_x0, src_y2, width);
//       const src_idx_br = convertPixelPointToArrIndex(src_x2, src_y2, width);
//       const edge_idx = src_idx_cc;
//       const src_around_alpha_arr = [
//         mask_pixel_arr[src_idx_tl + 3],
//         mask_pixel_arr[src_idx_tr + 3],
//         mask_pixel_arr[src_idx_bl + 3],
//         mask_pixel_arr[src_idx_br + 3]
//       ];
//       const alpha_zero_count = src_around_alpha_arr.filter(alpha => alpha === 0).length;
//       if (alpha_zero_count < 4) {
//         const alpha_substract_arr = src_around_alpha_arr.map(alpha =>
//           Math.abs(mask_pixel_arr[src_idx_cc + 3] - alpha)
//         );
//         let len = alpha_substract_arr.filter(substract_value => substract_value !== 0).length;
//         if (len === 0) len = 1;
//         const substract_avg = alpha_substract_arr.reduce((a, b) => a + b) / len;
//         for (let k = 0; k < 3; k++) {
//           edge_pixel_arr[edge_idx + k] = substract_avg / line_color_black_amount;
//         }
//         edge_pixel_arr[edge_idx + 3] = substract_avg * 10;
//       }
//     }
//   }
//   const edge_canvas = document.createElement('canvas');
//   edge_canvas.width = width;
//   edge_canvas.height = height;
//   const edge_ctx = edge_canvas.getContext('2d');
//   edge_ctx.putImageData(new ImageData(edge_pixel_arr, width, height), 0, 0);
//   console.timeEnd('edge_detection: ');
//   return edge_canvas;
// }
