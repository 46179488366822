import { go } from 'fxjs/es';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';
import { MarstagramEditorMuiS } from '../../S/Mui/module/MarstagramEditorMuiS.js';
import { page } from './page.js';
import { $addClass, $attr, $delegate, $find, $hasClass } from 'fxdom/es';
import { getCurrentCropPosFromGPU } from '../Function/module/index.js';
/*
 * 프론트엔드에서 사용될 frame 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#b847a14a76274bff9aaa6d549c5bef88
 * */
export const frame = {
  ...MarstagramEditorMuiS.frame, // S의 Mui에서 프레임옵션을 가져옵니다.

  root_page: page, // /F/page 옵션객체 - 필수

  height: undefined, // 모바일 전용, frame 높이
  prev_frame_show: true, // openFrame시에 바로 하단의 frame을 html에서 삭제안함
  always_remove: true, // closeFrame 때 무조건 삭제
  animation: !MuiF.IS_IOS, // frame을 열거나 닫을때 애니메이션 (기본 - ios면 false 이외 true)
  is_modal: G.is_pc_size(),

  makeData(frame) {}, // frame에서 데이터를 공유할 값을 리턴하는 함수

  appending(frame_el$) {}, // frame 엘리먼트가 만들어지면 울리는 함수
  appended(frame_el$) {
    go(
      frame_el$,
      $delegate('click', '.header_btns', async (e) => {
        const ct = e.currentTarget;
        const is_done = go(ct, $attr('action')) === 'complete';
        const view_canvas = $find('#view_gl_canvas', frame_el$);
        //todojaemin
        //premuiltiplied Alpha set to true
        //set to powerPreference to default
        //remove depth buffer
        const view_gl = view_canvas.getContext('webgl', {
          alpha: true,
          antialias: false,
          powerPreference: 'default',
          premultipliedAlpha: true,
          preserveDrawingBuffer: true,
          // depth: false,
        });
        const view_size = {
          w: view_canvas.width,
          h: view_canvas.height,
        };

        if (is_done) {
          const is_cropped_or_transformed =
            $hasClass('cropped', view_canvas) || $hasClass('transformed', view_canvas);
          const is_color_changed = $hasClass('color_changed', view_canvas);

          if (
            await $.confirm(
              `${is_color_changed ? T('image_editor::color_change_alert') + '<br><br>' : ''}${
                is_cropped_or_transformed ? T('image_editor::crop_alert') + '<br><br>' : ''
              }${T('image_editor::apply')}`,
            )
          ) {
            $addClass('hide_frame', frame_el$);
            const cropped_canvases = go(view_gl, (view_gl) => {
              view_gl.uniform1f(view_gl.programs.view.locations.u_outside_crop_alpha, 1.0);
              view_gl.uniform1f(view_gl.programs.view.locations.u_outside_crop_color, 1.0);
              view_gl.uniform1f(view_gl.programs.view.locations.u_grid_line_alpha, 0.0);
              view_gl.uniform1i(view_gl.programs.view.locations.u_crop_on, 0);
              view_gl.viewport(0, 0, view_size.w, view_size.h);
              view_gl.drawArrays(view_gl.TRIANGLES, 0, 6);
              const export_canvas = document.createElement('canvas');
              const export_ctx = export_canvas.getContext('2d');
              if ($hasClass('cropped', view_gl.canvas)) {
                export_canvas.width = view_size.w;
                export_canvas.height = view_size.h;
                export_ctx.drawImage(view_gl.canvas, 0, 0);
                const crop_pos = getCurrentCropPosFromGPU(view_gl, false);

                const canvas_to_client_ratio = view_size.w / parseInt(view_gl.canvas.style.width);
                const crop_info = {
                  x: Math.ceil(Math.max(crop_pos.left * canvas_to_client_ratio)),
                  y: Math.ceil(Math.max(crop_pos.top * canvas_to_client_ratio)),
                  w: Math.floor(Math.min((crop_pos.right - crop_pos.left) * canvas_to_client_ratio)),
                  h: Math.floor(Math.min((crop_pos.bottom - crop_pos.top) * canvas_to_client_ratio)),
                };
                if (crop_info.x + crop_info.w > view_size.w) {
                  crop_info.w = view_size.w - crop_info.x;
                }
                if (crop_info.y + crop_info.h > view_size.h) {
                  crop_info.h = view_size.h - crop_info.y;
                }
                export_canvas.width = crop_info.w;
                export_canvas.height = crop_info.h;
                export_ctx.drawImage(
                  view_gl.canvas,
                  crop_info.x,
                  crop_info.y,
                  crop_info.w,
                  crop_info.h,
                  0,
                  0,
                  export_canvas.width,
                  export_canvas.height,
                );
              } else {
                export_canvas.width = view_size.w;
                export_canvas.height = view_size.h;
                export_ctx.drawImage(view_gl.canvas, 0, 0);
              }
              return {
                original: export_canvas,
                // resized: resizeImage(900, export_canvas),
              };
            });
            return MuiF.closeFrame({ is_done: true, cropped_canvases });
          }
        } else {
          if (await $.confirm(`${T('image_editor::editor_cancel')}`)) {
            return MuiF.closeFrame({ is_done: false });
          }
        }
      }),
    );
  }, // frame 엘리먼트가 html에 붙으면 울리는 함수
  showing(frame_el$) {}, // frame 엘리먼트 show하기 전 울리는 함수
  showed(frame_el$) {}, // frame 엘리먼트 show하고 나서 울리는 함수
  /* frame을 닫을때 울리는 함수 */
  closing(frame_el$, val) {}, // frame이 닫히기 전 울리는 함수
  hiding(frame_el$, val) {}, // frame이 가려지기 전 울리는 함수
  hided(frame_el$, val) {}, // frame이 가려진 후 울리는 함수
  removing(frame_el$, val) {}, // frame이 삭제되기 전 울리는 함수
  removed(frame_el$, val) {}, // frame이 삭제된 후 울리는 함수
  closed(frame_el$, val) {}, // frame이 닫힌 후 울리는 함수
};
