import { legacyHtml } from '../../../Util/S/Function/util.js';
import { NewMakerConditionAgreeTextF } from '../../../NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';

export const makeCvOldTextHtml = (cv_old_text) => legacyHtml`
  <div class="cv_text_editor marpplizer_contents_wrapper body text_old_ev">
    <div class="main_head">
      <div class="body">${T('Font Style')}</div>
      <div class="option"><button class="marpplizer_back_btn marpplizer_back_btn_styl"></button></div>
    </div>
    <div class="control section select_for_old">
      <div class="edit_font_style">폰트 스타일 편집</div>
      <div class="edit_text">텍스트 편집</div>
    </div>
    <div class="only_df control section">
      <div class="head">텍스트 정보</div>
      <textarea>${cv_old_text._data.old_text_data.text}</textarea>
      <div class="font">
        <span>폰트 :</span>
        <img
          src="//s3.marpple.co/s/5${cv_old_text._data.old_text_data.font.src.replace(
            '//s3.marpple.co/s/5',
            '',
          )}"
          alt=""
        />
      </div>
      <div class="color">
        <span>색상 :</span>
        <span
          class="background_color"
          style="background: ${
            cv_old_text._data.press_color_code
          }; width: 24px; height: 24px; display : inline-block; vertical-align: middle; border-radius: 50%;"
        ></span>
        <span class="color_name">${cv_old_text._data.press_color_name}</span>
      </div>
    </div>
    <div class="condition_agree_text section">${NewMakerConditionAgreeTextF.fn(cv_old_text)}</div>
  </div>
`;
