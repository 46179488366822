import { $find, $scrollTop, $setScrollTop } from 'fxdom/es';
import { filter, flatten, go, indexBy, map, pluck } from 'fxjs/es';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPProductListF } from '../../../F/Function/module/OMPProductListF.js';
import { OMPProductListModalMuiS } from '../../S/Mui/module/OMPProductListModalMuiS.js';
import { OMPProductListModalF } from '../Function/module/OMPProductListModalF.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...OMPProductListModalMuiS.tab,

  inner_scroll_target: '.omp-product-list-modal__body', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  async makeData() {
    /* 기본은 상품만들기 */
    const base_product = box.sel('maker->product_color->_->base_product');
    const { checked_cate_list, checked_cate_item } = OMPProductListF.checkedCateItem(base_product);
    const query = { cate_list_id: checked_cate_list.id, cate_item_id: checked_cate_item?.id, sort: 'pick' };
    const [bps, cate_lists] = await Promise.all([
      OMPProductListModalF.axiosGetBpsWithoutCarvedBps(query),
      go(box.sel('cate_lists'), async (cate_lists) => {
        if (box().not_vector_cate_lists) return box().not_vector_cate_lists;
        const cate_item_ids = go(
          cate_lists,
          map(({ _: { cate_items } }) => pluck('id', cate_items)),
          flatten,
          (r) => r.join(','),
        );

        const data = await $.get(`/@api/not_vector_categories`, {
          cate_item_ids,
        });
        const indexByData = indexBy((d) => d.cate_item_id, data);

        return (box().not_vector_cate_lists = go(
          cate_lists,
          map((c) => {
            const {
              _: { cate_items },
              _: rels,
            } = c;
            return {
              ...c,
              _: {
                ...rels,
                cate_items: filter(({ id }) => indexByData[id], cate_items),
              },
            };
          }),
          filter(({ _: { cate_items } }) => cate_items.length),
        ));
      }),
    ]);
    return {
      cate_lists,
      checked_cate_list,
      checked_cate_item,
      query,
      bps,
      is_mobile: OMPCoreUtilF.isMobile(),
    };
  }, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    return OMPProductListModalF.event(tab_el);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    if (tab_el.tab_opt.inner_scroll_top) {
      $setScrollTop(tab_el.tab_opt.inner_scroll_top, $find(tab_el.tab_opt.inner_scroll_target, tab_el));
    }
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {
    tab_el.tab_opt.lazy = new Blazy({
      selector: '.omp-image-lazy',
      container: '.omp-product-list-modal__body',
    });
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {
    tab_el.tab_opt.inner_scroll_top = $scrollTop($find(tab_el.tab_opt.inner_scroll_target, tab_el));
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
