export const imageUploadAlert = (accept_arr) => {
  if (accept_arr.length) {
    if (accept_arr.includes('application/postscript')) {
      return $.alert(T(`maker::Accepted File Types: JPEG, PNG, AI.`));
    } else if (accept_arr.includes('image/svg+xml')) {
      return $.alert(T(`maker::Accepted File Types: JPEG, PNG, SVG.`));
    }
  }
  return $.alert(T(`maker::Accepted File Types: JPEG, PNG.`));
};
