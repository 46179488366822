import { entries, go, head, html, range, reject, strMap } from 'fxjs/es';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Util/S/Function/module/OMPCoreUtilS.js';

/**
 * @typedef {object} Column
 * @property {string} key
 * @property {number} size
 * @property {string} title
 * @property {function} template
 */

/**
 * @typedef {object} TableBodyParam
 * @property {Column[]} columns
 * @property {object[] | array []} data
 * @property {function} separator
 * @property {string} empty_notice
 * @property {string} merge_column_key
 * @property {object} style
 */

/**
 * @typedef {TableBodyParam} TableParam
 * @property {number?} total_page
 * @property {number?} current_page
 */

/**
 * @typedef {object} TablePageParam
 * @property {number?} total_page
 * @property {number?} current_page
 */

/**
 * @param {TableBodyParam} param
 */
export const tableBodyTmpl = ({
  row_klass,
  merge_column_key,
  columns,
  separator = () => {},
  data = [],
  empty_notice,
  style = { border_color: 'BK' },
}) => {
  if (typeof merge_column_key === 'string') {
    merge_column_key = [merge_column_key];
  }

  // `data` 비어있음
  if (!data.length) {
    return html`
      <tbody>
        <tr data-last="true" class="omp-cell__table-body">
          <td
            ${columns.size ? `data-size="${columns.size}"` : ''}
            colspan=${columns.length}"
          >
            ${empty_notice}
          </td>
        </tr>
      </tbody>
    `;
  }

  // `data`가 2차원 배열 (최대 2차원까지만 지원)
  if (Array.isArray(head(data))) {
    return html`
      <tbody>
        ${strMap((data_set) => {
          return html`
            ${go(
              /** @type {object[]} */ data_set,
              zipWithIndex,
              strMap(([index, value]) => {
                return html`
                  <tr
                    ${value?.data
                      ? strMap(([key, value]) => html` data-${key}="${value}" `, entries(value.data))
                      : ''}
                    ${style ? strMap(([key, val]) => html` data-style_${key}="${val}" `, entries(style)) : ''}
                    data-last="${index + 1 === data_set.length}"
                    class="omp-cell__table-body ${row_klass || ''} ${index + 1 === data_set.length
                      ? 'is_last'
                      : ''}"
                  >
                    ${strMap(({ key, size, template }) => {
                      if (merge_column_key && merge_column_key.includes(key) && index !== 0)
                        return html` <td ${size ? `data-size="${size}"` : ''}>
                          <div class="empty"></div>
                        </td>`;

                      return html` <td ${size ? `data-size="${size}"` : ''}>
                        <div class="td-wrapper" data-key="${key}">${template(value[key])}</div>
                      </td>`;
                    }, columns)}
                  </tr>
                `;
              }),
            )}
            ${separator(data_set)
              ? html`<tr data-last="true" class="omp-cell__table-body">
              <td colspan=${columns.length}">${separator(data_set)}</td>
            </tr>`
              : ''}
          `;
        }, data)}
      </tbody>
    `;
  }

  // `data`가 1차원 배열
  return html`
    <tbody>
      ${strMap(
        /** @type {[number, object]} */ ([index, row_data]) => {
          return html`
            <tr
              data-style_border_color="${style.border_color}"
              class="omp-cell__table-body ${index + 1 === data.length ? 'is_last' : ''}"
            >
              ${strMap(({ key, size, template }) => {
                return html`
                  <td ${size ? `data-size="${size}"` : ''}>
                    <div class="td-wrapper" data-key="${key}">${template(row_data[key])}</div>
                  </td>
                `;
              }, columns)}
            </tr>
          `;
        },
        zipWithIndex(data),
      )}
    </tbody>
  `;
};

/**
 * @param {TablePageParam} param
 */
export const tablePageTmpl = ({ total_page, current_page, limit = 10, page_button_length = 5 }) => {
  const pages = go(
    Math.floor(current_page / page_button_length) * page_button_length,
    (page_first) => range(page_first + 1, page_first + 6),
    reject((page) => page > total_page),
  );

  const is_first = current_page === 1;
  const is_last = current_page === total_page;

  return html` <div class="omp-cell__table-pages">
    <div class="omp-cell__table-page-buttons">
      <span
        class="omp-cell__table-page-prev__first"
        data-offset="0"
        disabled="${is_first}"
        data-limit="${limit}"
      >
        ${OMPCoreAtomTmplS.doubleArrowIcon({ direction: 'left', color: is_first ? 'GY_50' : 'BK' })}
      </span>
      <span
        class="omp-cell__table-page-prev__prev"
        data-offset="${(current_page - 2) * limit}"
        data-limit="${limit}"
        disabled="${is_first}"
      >
        ${OMPCoreAtomTmplS.arrowIcon({ direction: 'left', color: is_first ? 'GY_50' : 'BK' })}
      </span>
    </div>
    ${go(
      pages,
      strMap((page) => {
        const offset = (page - 1) * limit;
        return html`<span
          data-offset="${offset}"
          data-active="${page === current_page}"
          data-limit="${limit}"
          class="omp-cell__table-page"
        >
          ${page}
        </span>`;
      }),
    )}
    <div class="omp-cell__table-page-buttons">
      <span
        class="omp-cell__table-page-prev__next"
        data-offset="${current_page * limit}"
        data-limit="${limit}"
        disabled="${is_last}"
      >
        ${OMPCoreAtomTmplS.arrowIcon({ direction: 'right', color: is_last ? 'GY_50' : 'BK' })}
      </span>
      <span
        class="omp-cell__table-page-prev__last"
        data-offset="${(total_page - 1) * limit}"
        data-limit="${limit}"
        disabled="${is_last}"
      >
        ${OMPCoreAtomTmplS.doubleArrowIcon({ direction: 'right', color: is_last ? 'GY_50' : 'BK' })}
      </span>
    </div>
  </div>`;
};

/**
 * @deprecated Use `paginatedTable`
 * @param {TableParam} body
 * @description
 * `tableTmpl`은 template(data) 템플릿에서 전달받는 데이터가 data[column.key]의 값이고,
 * `paginatedTable`은 `data`를 그대로 전달 받는다.
 */
export const tableTmpl = ({
  klass,
  merge_column_key,
  columns,
  data = [],
  empty_notice,
  separator,
  total_page,
  current_page,
  style,
}) => {
  return html`
    <div class="omp-cell__table-wrapper ${klass || ''}">
      <table class="omp-cell__table">
        <thead>
          <tr class="omp-cell__table-header">
            ${strMap(
              ({ size, title = '' }) => html`<th ${size ? `data-size="${size}"` : ''}>${title}</th>`,
              columns,
            )}
          </tr>
        </thead>
        ${tableBodyTmpl({ merge_column_key, columns, data, separator, empty_notice, style })}
      </table>
      ${data.length && OMPCoreUtilS.isNumeric(total_page) && OMPCoreUtilS.isNumeric(current_page)
        ? tablePageTmpl({ total_page, current_page })
        : ''}
    </div>
  `;
};
