import { strMap, html } from 'fxjs/es';
import { legacyHtml } from '../../../../../modules/Util/S/Function/util.js';

G.$t_$folder_list_item = (folder) => legacyHtml`
  <div
    class="don_pgn_item folder_item"
    folder_id="${folder.id}"
    style="background-image: url(${G.to_150(folder.url)});"
    _sel="./(#${folder.id})"
  >
    <a href="/folder/detail/${folder.id}">
      <span class="name">kr: ${folder.name || '이름 없음'}</span>
      <span class="name_en">en: ${folder.name_en || 'no name'}</span>
    </a>
    <span class="btns">
      <button type="button" class="btn_delete">삭제</button>
    </span>
  </div>
`;

G.$t_$folder_list_item___ = (folder) => pug`
  .don_pgn_item.folder_item[folder_id="${folder.id}" style="background-image: url(${G.to_150(
  folder.url,
)});" _sel="./(#${folder.id})"]
    a[href="/folder/detail/${folder.id}"]
      .name kr: ${folder.name || '이름 없음'}
      .name_en en: ${folder.name_en || 'no name'}
    sp.btns
      button[type="button"].btn_delete 삭제
`;

G.$t_$folder2_list_item = (folder) => pug`
  .don_pgn_item.folder_item[folder_id="${folder.id}" style="background-image: url(${G.to_150(folder.url)});"]
    a[href=/folder2/detail/${folder.id}]
      .name ${folder.name || '이름 없음'}
    sp.btns
      button[type="button"].btn_delete 삭제
`;

G.$t_$image_list_item = (image) => pug`
  li.image_item[_sel="./(#${image.id})" _id="${image.id}" is_group="${
  image.is_group
}" style="background-image: url(${G.to_150(image.url)});"]
    .thumbs[group_length="${image.group_thumb ? image.group_thumb.length : 0}"]
      ${_go(image, (image) => {
        if (image.is_group)
          return _sum(
            image.group_thumb,
            (thumb) => pug`
          sp.thumb[style="background-image: url(${G.to_150(thumb.url)});"]
        `,
          );
      })}
    .name ${image.name || '이름 없음'}
    .price ${G.$commify(image.price)}
    .resolution ${image.width} x ${image.height}
    .btns
      button[type=button].btn_image_download 다운
      button[type=button].btn_group_make 그룹만들기
      button[type=button].btn_group_first 그룹대표지정
      button[type=button].btn_image_select 그룹이미지선택
      button[type=button].btn_image_cancel 그룹선택취소
      button[type=button].btn_pop 그룹빼기
      button[type=button].btn_modify 수정
      button[type=button].btn_delete 삭제
`;

G.$t_$folder2_image_list_item = (image) => pug`
  .don_is_item[_sel="images->(#${image.id})" image_id="${image.id}" style="background-image: url(${G.to_150(
  image.url,
)})"]
    .name ${image.name || '이름 없음'}
    .price ${G.$commify(image.price)}
    .btns
      button[type="button"].btn_delete 삭제
`;

G.$t_$image_color_item = (image, ic, press_colors) => html`
  <li
    class="ic_item"
    _sel="./(#${ic.id})"
    _id="${ic.id}"
    is_first="${image.image_color_id == ic.id ? 'true' : 'false'}"
  >
    <div class="img">
      <img src="${G.to_150(ic.url)}" alt="" />
    </div>
    <div class="controls">
      <div class="control name">
        <span class="head">name</span>
        <input type="text" name="name" value="${ic.name || ''}" />
        <input type="hidden" name="id" value="${ic.id}" />
      </div>
      <div class="control name_en">
        <span class="head">name en</span>
        <input type="text" name="name_en" value="${ic.name_en || ''}" />
      </div>
      <div class="control name_jp">
        <span class="head">name jp</span>
        <input type="text" name="name_jp" value="${ic.name_jp || ''}" />
      </div>
      <div class="control width">
        <span class="head">width ${ic.width || 0}</span>
      </div>
      <div class="control height">
        <span class="head">height ${ic.height || 0}</span>
      </div>
      <div class="control press_color">
        <span class="head">인쇄 색상</span>&nbsp;
        <select name="press_color_id">
          <option value="">선택</option>
          ${strMap(
            (pc) => html`
              <option value="${pc.id}" ${ic.press_color_id == pc.id ? 'selected' : ''}>${pc.name}</option>
            `,
            press_colors,
          )}
        </select>
      </div>
    </div>
    <div class="btns">
      <span class="first_txt">대표</span>
      <button type="button" class="btn_first_image">대표설정</button>
      <button type="button" class="btn_ic_save">저장</button>
      <button type="button" class="btn_ic_delete">삭제</button>
    </div>
  </li>
`;
