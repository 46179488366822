import { removeMakerPageClass } from '../mp_maker.js';
import { onbeforeunload_event_off } from '../util.js';
import { cancelCvBpcfVideoAni } from '../CvBpcfVideo/fs.js';

export function unsetMakerPage() {
  $.off(window, 'resize.maker');
  removeMakerPageClass();
  onbeforeunload_event_off();
  cancelCvBpcfVideoAni();
}
