import { $find } from 'fxdom/es';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { go, pipe } from 'fxjs/es';

export const defnDfUserVerifyLoginTab = () => ({
  tab_name: 'df.verify_login_tab',
  appended: pipe(
    $.on('keyup', 'input[name="email"]', (e) => {
      if (e.keyCode != 13) return;
      $.trigger($.find(e.delegateTarget, '.btn_verify_otp_email'), 'click');
    }),
    $.on2('click', '.btn_verify_otp_email', function (e) {
      const input_email = $.find1(e.delegateTarget, 'input[name="email"]');
      if (!UtilS.is_valid_email($.val(input_email)))
        return go('이메일을 입력해 주세요.', $.alert, () => {
          input_email.focus();
        });

      return go(
        $.post('/@api/df/login_otp_request', {
          email: $.val(input_email),
        }),
        (data) => {
          if (!data || !data.result) return $.alert('로그인 실패');

          const login_body_el = $find('.df-login__body', e.delegateTarget);
          login_body_el.dataset.status = 'cert';

          const timer_el = $find('.df-login__otp-timer', login_body_el);
          const five_minutes = 60 * 5;
          // const five_minutes = 10;
          UtilF.startTimerMinutes(five_minutes, timer_el, () => {
            login_body_el.dataset.status = 'error';
          });
        },
      );
    }),
    $.on('click', '.btn_df_login_reset', () => {
      location.reload();
    }),
    $.on2('click', '.btn_df_login', function (e) {
      const input_email = $.find1(e.delegateTarget, 'input[name="email"]');
      const input_pw = $.find1(e.delegateTarget, 'input[name="password"]');
      const input_cert = $.find1(e.delegateTarget, 'input[name="cert"]');

      if (!UtilS.is_valid_email($.val(input_email)))
        return go(T('This email address is not available.'), $.alert);

      if (UtilS.is_empty($.val(input_pw))) return go('패스워드를 입력하세요.', $.alert);

      if (UtilS.is_empty($.val(input_cert))) return go('인증번호를 입력하세요.', $.alert);

      return go(
        $.post('/@api/df/login_verify', {
          email: $.val(input_email),
          password: $.val(input_pw),
          cert: $.val(input_cert),
        }),
        (data) => {
          if (!data || !data.result) return go('로그인 실패', $.alert);

          return go('로그인되었습니다.', $.alert, () => {
            window.location.reload();
          });
        },
      );
    }),
    // $.on2('click', '.btn_verify_email', function (e) {
    //   const input_email = $.find1(e.delegateTarget, 'input[name="email"]');
    //   if (!UtilS.is_valid_email($.val(input_email)))
    //     return go('이메일을 입력해 주세요.', $.alert, () => {
    //       input_email.focus();
    //     });
    //
    //   return go(
    //     $.post('/@api/df/login_verify_request', {
    //       email: $.val(input_email),
    //     }),
    //     (data) => {
    //       if (!data || !data.result) return $.alert('로그인 실패');
    //       return go('인증 메일이 발송 되었습니다.<br>메일의 링크를 통해 로그인해 주세요.', $.alert);
    //     },
    //   );
    // }),
  ),
});

export const defnDfUserVerifyLoginPwTab = () => ({
  tab_name: 'df.verify_login_pw_tab',
  appended: pipe(
    $.on('keyup', 'input[name="password"]', (e) => {
      if (e.keyCode != 13) return;
      $.trigger($.find(e.delegateTarget, '.btn_login'), 'click');
    }),
    $.on2('click', '.btn_login', function (e) {
      const input_email = $.find1(e.delegateTarget, 'input[name="email"]');
      const input_txt = $.find1(e.delegateTarget, 'input[name="txt"]');
      const input_pw = $.find1(e.delegateTarget, 'input[name="password"]');

      if (!UtilS.is_valid_email($.val(input_email)))
        return go(T('This email address is not available.'), $.alert);

      if (UtilS.is_empty($.val(input_pw))) return go('패스워드를 입력하세요.', $.alert);

      return go(
        $.post('/@api/df/login_verify', {
          email: $.val(input_email),
          txt: $.val(input_txt),
          password: $.val(input_pw),
        }),
        (data) => {
          if (!data || !data.result) return go('로그인 실패', $.alert);

          return go('로그인되었습니다.', $.alert, () => {
            window.location.reload();
          });
        },
      );
    }),
  ),
});
