export function makeGrid(canvas, width, out_line_width) {
  const ctx = canvas.getContext('2d');
  ctx.strokeStyle = '#000000';
  ctx.lineWidth = 3;
  for (let y = 0; y < canvas.height; ) {
    for (let x = 0; x < canvas.width; ) {
      ctx.strokeRect(x, y, width, width);
      x += width;
    }
    y += width;
  }
  if (out_line_width) {
    ctx.strokeStyle = '#ff0000';
    ctx.lineWidth = 4;
    ctx.strokeRect(0, 0, canvas.width, canvas.height);
  }
  return canvas;
}
