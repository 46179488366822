import { chunk, find, flat, html } from 'fxjs/es';
import { SVGEditorAttrS } from '../../../Attr/S/Function/module/SVGEditorAttrS.js';
import { VectorEditorColorConstS } from '../../../../VectorEditor/Color/S/Const/module/VectorEditorColorConstS.js';
import { isSameColorCode } from '../../../Color/F/Function/event.js';
import { colorsWrapper } from '../../../Color/S/Tmpl/color.js';

export const color = ({ svg_el, one_line_length }) => {
  const color_code = SVGEditorAttrS.get('fill', svg_el);
  const colors = chunk(one_line_length, VectorEditorColorConstS.COLORS32);
  const flat_colors = flat(colors);
  const selected_color = find((fc) => isSameColorCode(fc.color_code, color_code))(flat_colors);
  const title = selected_color ? selected_color.name[T.lang] : color_code;
  return html`
    <div class="colors_wrapper" data-key="fill">
      <div class="title">${title}</div>
      ${colorsWrapper({ colors, color_code })}
    </div>
  `;
};
