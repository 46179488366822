import { dropWhile } from 'fxjs/es';
export const guards = {
  skuKeys: ({ sku }) => {
    const keys = Object.keys(sku);
    const required_keys = ['width', 'height', 'length', 'weight'];
    const missing_keys = dropWhile((k) => keys.includes(k), required_keys);

    if (missing_keys.length > 0) {
      throw new Error(`${missing_keys.join(', ')} 이 존재하지 않습니다.`);
    }
  },
};
