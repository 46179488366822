import { each, go } from 'fxjs/es';

export const bpOptionLayers = {
  validate: ({ bp_option_id, bp_option_name }) => {
    if (!bp_option_id) throw new Error('bp_option_id must be');
    if (!bp_option_name) throw new Error('bp_option_id must be');
  },
  validatePropertyValue: (property_value) => {
    if (!property_value?.length) throw new Error('property_value must be array and at least 1 length');
    go(property_value, each(bpOptionLayers.validate));
  },
};
