const COLOR2 = {
  NONE: {
    name: {
      kr: '없음',
      en: 'None',
      jp: 'なし',
    },
    is_etc: false,
    color_code: 'none',
    thumbnail: {
      color: null,
      border: null,
      url: '//s3.marpple.co/files/u_193535/2021/4/original/321b230231a41aaf607a45f149b392c5a1979c372.png',
    },
  },
  WHITE: {
    name: {
      kr: '흰색',
      en: 'White',
      jp: '白',
    },
    is_etc: false,
    color_code: '#ffffff',
    thumbnail: {
      color: '#ffffff',
      border: '#dee2e6',
      url: null,
    },
  },
  RED: {
    name: {
      kr: '빨강',
      en: 'Red',
      jp: '赤',
    },
    is_etc: false,
    color_code: '#c40101',
    thumbnail: {
      color: '#c40101',
      border: null,
      url: null,
    },
  },
  ORANGE: {
    name: {
      kr: '주황',
      en: 'Orange',
      jp: 'オレンジ',
    },
    is_etc: false,
    color_code: '#ff7903',
    thumbnail: {
      color: '#ff7903',
      border: null,
      url: null,
    },
  },
  YELLOW: {
    name: {
      kr: '노랑',
      en: 'Yellow',
      jp: 'イエロー',
    },
    is_etc: false,
    color_code: '#ffd300',
    thumbnail: {
      color: '#ffd300',
      border: null,
      url: null,
    },
  },
  LEMON: {
    name: {
      kr: '레몬',
      en: 'Lemon',
      jp: 'レモン',
    },
    is_etc: false,
    color_code: '#fff800',
    thumbnail: {
      color: '#fff800',
      border: null,
      url: null,
    },
  },
  LIGHT_GREEN: {
    name: {
      kr: '연두',
      en: 'Light green',
      jp: 'ライトグリーン',
    },
    is_etc: false,
    color_code: '#cde605',
    thumbnail: {
      color: '#cde605',
      border: null,
      url: null,
    },
  },
  LIME: {
    name: {
      kr: '라임',
      en: 'Lime',
      jp: 'ライム',
    },
    is_etc: false,
    color_code: '#7ed405',
    thumbnail: {
      color: '#7ed405',
      border: null,
      url: null,
    },
  },
  APPLE_MINT: {
    name: {
      kr: '애플민트',
      en: 'Apple mint',
      jp: 'アップルミント',
    },
    is_etc: false,
    color_code: '#00d7b9',
    thumbnail: {
      color: '#00d7b9',
      border: null,
      url: null,
    },
  },
  SKY_BLUE: {
    name: {
      kr: '하늘',
      en: 'Sky blue',
      jp: '空',
    },
    is_etc: false,
    color_code: '#4fdef4',
    thumbnail: {
      color: '#4fdef4',
      border: null,
      url: null,
    },
  },
  SEA_BLUE: {
    name: {
      kr: '바다',
      en: 'Sea',
      jp: '海',
    },
    is_etc: false,
    color_code: '#1bafe3',
    thumbnail: {
      color: '#1bafe3',
      border: null,
      url: null,
    },
  },
  BLUE: {
    name: {
      kr: '파랑',
      en: 'Blue',
      jp: '青',
    },
    is_etc: false,
    color_code: '#275ebc',
    thumbnail: {
      color: '#275ebc',
      border: null,
      url: null,
    },
  },
  INDIGO: {
    name: {
      kr: '남색',
      en: 'Indigo',
      jp: '藍色',
    },
    is_etc: false,
    color_code: '#314689',
    thumbnail: {
      color: '#314689',
      border: null,
      url: null,
    },
  },
  ROYAL_BLUE: {
    name: {
      kr: '로얄블루',
      en: 'Royal blue',
      jp: 'ロイヤルブルー',
    },
    is_etc: false,
    color_code: '#3635ab',
    thumbnail: {
      color: '#3635ab',
      border: null,
      url: null,
    },
  },
  PURPLE: {
    name: {
      kr: '보라',
      en: 'Purple',
      jp: 'パープル',
    },
    is_etc: false,
    color_code: '#6f00cd',
    thumbnail: {
      color: '#6f00cd',
      border: null,
      url: null,
    },
  },
  PINK: {
    name: {
      kr: '핑크',
      en: 'Pink',
      jp: 'ピンク',
    },
    is_etc: false,
    color_code: '#d320c2',
    thumbnail: {
      color: '#d320c2',
      border: null,
      url: null,
    },
  },
  PISTACHIO: {
    name: {
      kr: '피스타치오',
      en: 'Pistachio',
      jp: 'ピスタチオ',
    },
    is_etc: false,
    color_code: '#d8e2dc',
    thumbnail: {
      color: '#d8e2dc',
      border: null,
      url: null,
    },
  },
  LIGHT_PINK: {
    name: {
      kr: '라이트핑크',
      en: 'Light pink',
      jp: 'ライトピンク',
    },
    is_etc: false,
    color_code: '#ffe5d9',
    thumbnail: {
      color: '#ffe5d9',
      border: null,
      url: null,
    },
  },
  BABY_PINK: {
    name: {
      kr: '베이비핑크',
      en: 'Baby pink',
      jp: 'ベビーピンク',
    },
    is_etc: false,
    color_code: '#ffcad4',
    thumbnail: {
      color: '#ffcad4',
      border: null,
      url: null,
    },
  },
  ROSE: {
    name: {
      kr: '로즈',
      en: 'Rose',
      jp: 'ローズ',
    },
    is_etc: false,
    color_code: '#f3acb8',
    thumbnail: {
      color: '#f3acb8',
      border: null,
      url: null,
    },
  },
  VIOLET: {
    name: {
      kr: '바이올렛',
      en: 'Violet',
      jp: 'バイオレット',
    },
    is_etc: false,
    color_code: '#c092ba',
    thumbnail: {
      color: '#c092ba',
      border: null,
      url: null,
    },
  },
  LAVENDER: {
    name: {
      kr: '라벤더',
      en: 'Lavender',
      jp: 'ラベンダー',
    },
    is_etc: false,
    color_code: '#9d9ace',
    thumbnail: {
      color: '#9d9ace',
      border: null,
      url: null,
    },
  },
  PACIFIC_BLUE: {
    name: {
      kr: '퍼시픽블루',
      en: 'Pacific Blue',
      jp: 'パシフィックブルー',
    },
    is_etc: false,
    color_code: '#8aabc7',
    thumbnail: {
      color: '#8aabc7',
      border: null,
      url: null,
    },
  },
  CELLULIAN_BLUE: {
    name: {
      kr: '셀룰리안블루',
      en: 'Cellulian Blue',
      jp: 'セルリアンブルー',
    },
    is_etc: false,
    color_code: '#8eace0',
    thumbnail: {
      color: '#8eace0',
      border: null,
      url: null,
    },
  },
  CELADON: {
    name: {
      kr: '셀라돈',
      en: 'Celadon',
      jp: 'セラドン',
    },
    is_etc: false,
    color_code: '#abdcd8',
    thumbnail: {
      color: '#abdcd8',
      border: null,
      url: null,
    },
  },
  SAGE: {
    name: {
      kr: '세이지',
      en: 'Sage',
      jp: 'セージ',
    },
    is_etc: false,
    color_code: '#cadcab',
    thumbnail: {
      color: '#cadcab',
      border: null,
      url: null,
    },
  },
  CORAL: {
    name: {
      kr: '코랄',
      en: 'Coral',
      jp: 'コーラル',
    },
    is_etc: false,
    color_code: '#ffc49b',
    thumbnail: {
      color: '#ffc49b',
      border: null,
      url: null,
    },
  },
  LIGHT_ORANGE: {
    name: {
      kr: '라이트오렌지',
      en: 'Light orange',
      jp: 'ライトオレンジ',
    },
    is_etc: false,
    color_code: '#ffe49b',
    thumbnail: {
      color: '#ffe49b',
      border: null,
      url: null,
    },
  },
  CREAM: {
    name: {
      kr: '크림',
      en: 'Cream',
      jp: 'クリーム',
    },
    is_etc: false,
    color_code: '#f6f1d1',
    thumbnail: {
      color: '#f6f1d1',
      border: null,
      url: null,
    },
  },
  GRAY: {
    name: {
      kr: '회색',
      en: 'Gray',
      jp: 'グレー',
    },
    is_etc: false,
    color_code: '#a3a3a3',
    thumbnail: {
      color: '#a3a3a3',
      border: null,
      url: null,
    },
  },
  BLACK: {
    name: {
      kr: '블랙',
      en: 'Black',
      jp: 'ブラック',
    },
    is_etc: false,
    color_code: '#000000',
    thumbnail: {
      color: '#000000',
      border: null,
      url: null,
    },
  },
  ETC: {
    name: {
      kr: '기타',
      en: 'Etc',
      jp: 'その他',
    },
    is_etc: true,
    color_code: null,
    thumbnail: {
      color: null,
      border: null,
      url: '//s3.marpple.co/files/u_193535/2021/4/original/9a86070a9ec1ddc50a8f7ebb1eba88615396f0561.png',
    },
  },
};

export const COLORS32 = [
  COLOR2.NONE,
  COLOR2.WHITE,
  COLOR2.RED,
  COLOR2.ORANGE,
  COLOR2.YELLOW,
  COLOR2.LEMON,
  COLOR2.LIGHT_GREEN,
  COLOR2.LIME,
  COLOR2.APPLE_MINT,
  COLOR2.SKY_BLUE,
  COLOR2.SEA_BLUE,
  COLOR2.BLUE,
  COLOR2.INDIGO,
  COLOR2.ROYAL_BLUE,
  COLOR2.PURPLE,
  COLOR2.PINK,
  COLOR2.PISTACHIO,
  COLOR2.LIGHT_PINK,
  COLOR2.BABY_PINK,
  COLOR2.ROSE,
  COLOR2.VIOLET,
  COLOR2.LAVENDER,
  COLOR2.PACIFIC_BLUE,
  COLOR2.CELLULIAN_BLUE,
  COLOR2.CELADON,
  COLOR2.SAGE,
  COLOR2.CORAL,
  COLOR2.LIGHT_ORANGE,
  COLOR2.CREAM,
  COLOR2.GRAY,
  COLOR2.BLACK,
  COLOR2.ETC,
];

export const COLORS16 = [
  COLOR2.NONE,
  COLOR2.WHITE,
  COLOR2.RED,
  COLOR2.ORANGE,
  COLOR2.YELLOW,
  COLOR2.LEMON,
  COLOR2.LIGHT_GREEN,
  COLOR2.LIME,
  COLOR2.APPLE_MINT,
  COLOR2.SKY_BLUE,
  COLOR2.SEA_BLUE,
  COLOR2.BLUE,
  COLOR2.PURPLE,
  COLOR2.PINK,
  COLOR2.BLACK,
  COLOR2.ETC,
];

export const COLOR = {
  TRANSPARENT: {
    name: {
      kr: '투명',
      en: 'Transparent',
      jp: 'Transparent',
    },
    is_etc: false,
    color_code: 'none',
    thumbnail: {
      color: null,
      border: null,
      url: '//s3.marpple.co/files/u_193535/2021/4/original/321b230231a41aaf607a45f149b392c5a1979c372.png',
    },
  },
  PINK: {
    name: {
      kr: '핑크',
      en: 'Pink',
      jp: 'Pink',
    },
    is_etc: false,
    color_code: '#ff3c98',
    thumbnail: {
      color: '#ff3c98',
      border: null,
      url: null,
    },
  },
  ORANGE: {
    name: {
      kr: '주황',
      en: 'Orange',
      jp: 'Orange',
    },
    is_etc: false,
    color_code: '#ff7903',
    thumbnail: {
      color: '#ff7903',
      border: null,
      url: null,
    },
  },
  YELLOW: {
    name: {
      kr: '노랑',
      en: 'Yellow',
      jp: 'Yellow',
    },
    is_etc: false,
    color_code: '#ffd300',
    thumbnail: {
      color: '#ffd300',
      border: null,
      url: null,
    },
  },
  LEMON: {
    name: {
      kr: '레몬',
      en: 'Lemon',
      jp: 'Lemon',
    },
    is_etc: false,
    color_code: '#f3ff00',
    thumbnail: {
      color: '#f3ff00',
      border: null,
      url: null,
    },
  },
  KHAKI: {
    name: {
      kr: '카키',
      en: 'Khaki',
      jp: 'Khaki',
    },
    is_etc: false,
    color_code: '#0e3e12',
    thumbnail: {
      color: '#0e3e12',
      url: null,
    },
  },
  YELLOW_GREEN: {
    name: {
      kr: '연두',
      en: 'Yellow green',
      jp: 'Yellow green',
    },
    is_etc: false,
    color_code: '#6eba2c',
    thumbnail: {
      color: '#6eba2c',
      border: null,
      url: null,
    },
  },
  FLUORESCENT_GREEN: {
    name: {
      kr: '파스텔 그린',
      en: 'Fluorescent green',
      jp: 'Fluorescent green',
    },
    is_etc: false,
    color_code: '#00e608',
    thumbnail: {
      color: '#00e608',
      border: null,
      url: null,
    },
  },
  NAVY_BLUE: {
    name: {
      kr: '네이비',
      en: 'Navy blue',
      jp: 'Navy blue',
    },
    is_etc: false,
    color_code: '#091054',
    thumbnail: {
      color: '#091054',
      border: null,
      url: null,
    },
  },
  BLUE: {
    name: {
      kr: '파랑',
      en: 'Blue',
      jp: 'Blue',
    },
    is_etc: false,
    color_code: '#3635ab',
    thumbnail: {
      color: '#3635ab',
      border: null,
      url: null,
    },
  },
  FLUORESCENT_SKY: {
    name: {
      kr: '하늘',
      en: 'Fluorescent sky',
      jp: 'Fluorescent sky',
    },
    is_etc: false,
    color_code: '#2fa8ff',
    thumbnail: {
      color: '#2fa8ff',
      border: null,
      url: null,
    },
  },
  PURPLE: {
    name: {
      kr: '보라',
      en: 'Purple',
      jp: 'Purple',
    },
    is_etc: false,
    color_code: '#7a23a2',
    thumbnail: {
      color: '#7a23a2',
      border: null,
      url: null,
    },
  },
  BROWN: {
    name: {
      kr: '밤색',
      en: 'Brown',
      jp: 'Brown',
    },
    is_etc: false,
    color_code: '#482712',
    thumbnail: {
      color: '#482712',
      border: null,
      url: null,
    },
  },
  BLACK: {
    name: {
      kr: '검정',
      en: 'Black',
      jp: 'Black',
    },
    is_etc: false,
    color_code: '#000000',
    thumbnail: {
      color: '#000000',
      border: null,
      url: null,
    },
  },
  WHITE: {
    name: {
      kr: '흰색',
      en: 'White',
      jp: 'White',
    },
    is_etc: false,
    color_code: '#ffffff',
    thumbnail: {
      color: '#ffffff',
      border: '#dee2e6',
      url: null,
    },
  },
  UNKNOWN1: {
    name: {
      kr: '남색',
      en: 'Navy',
      jp: 'Navy',
    },
    is_etc: false,
    color_code: '#1f4fa1',
    thumbnail: {
      color: '#1f4fa1',
      border: null,
      url: null,
    },
  },
  UNKNOWN2: {
    name: {
      kr: '무슨 색일까',
      en: '???',
      jp: '???',
    },
    is_etc: false,
    color_code: '#74abd3',
    thumbnail: {
      color: '#74abd3',
      border: null,
      url: null,
    },
  },
  UNKNOWN3: {
    name: {
      kr: '무슨 색일까',
      en: '???',
      jp: '???',
    },
    is_etc: false,
    color_code: '#c1c1c1',
    thumbnail: {
      color: '#c1c1c1',
      border: null,
      url: null,
    },
  },
  UNKNOWN4: {
    name: {
      kr: '무슨 색일까',
      en: '???',
      jp: '???',
    },
    is_etc: false,
    color_code: '#b7c7cf',
    thumbnail: {
      color: '#b7c7cf',
      border: null,
      url: null,
    },
  },
  ETC: {
    name: {
      kr: '사용자 지정',
      en: 'Etc',
      jp: 'Etc',
    },
    is_etc: true,
    color_code: null,
    thumbnail: {
      color: null,
      border: null,
      url: '//s3.marpple.co/files/u_193535/2021/4/original/9a86070a9ec1ddc50a8f7ebb1eba88615396f0561.png',
    },
  },
};
