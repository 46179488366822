import { filter, find, go, map } from 'fxjs/es';
import { NewMakerCheckerS } from '../../../Checker/S/Function/module/NewMakerCheckerS.js';

/**
 * 이미지 해상도 체크 로직
 * */
export const findSmallerCvImageThanRequirement = (cv_target) => {
  const base_product_faces = go(
    box().maker.product_color._.base_product_color._.base_product_color_faces,
    map((bpcf) => bpcf._.base_product_face),
  );
  const { base_product_size_id } = box().maker.product_color;
  const product_faces2 = box().maker.product_color.product_faces2;

  return go(
    product_faces2.value,
    find(({ designs, bpf_id }) => {
      const { print, dpi } = NewMakerCheckerS.getSizeFace({
        base_product_faces,
        base_product_face_id: bpf_id,
        base_product_size_id,
      });
      const ratio = _getPrintRatio({ print, dpi });
      if (!ratio) return;
      return go(
        designs,
        filter((design) => design.cid === cv_target.cid),
        find((cv_obj) => _isSmallerCvImageThanRequirement({ cv_obj, ratio })),
      );
    }),
  );
};

function _getPrintRatio({ print, dpi }) {
  const { cm, px, result_px } = print;
  if (dpi) {
    const real_width_px = cmToInch(cm.width) * dpi;
    return real_width_px / px.width;
  }
  if (result_px?.width) {
    const { px } = print;
    return result_px.width / px.width;
  }
}

function _isSmallerCvImageThanRequirement({ cv_obj, ratio, parent_ratio = 1 }) {
  const cv_objs = cv_obj._objects || cv_obj.objects;
  if (cv_objs)
    return find((_cv_obj) =>
      _isSmallerCvImageThanRequirement({
        cv_obj: _cv_obj,
        ratio,
        parent_ratio: cv_obj.scaleX * parent_ratio,
      }),
    )(cv_objs);
  if (cv_obj._data.cv_type !== 'cv_image') return;
  if (cv_obj._data.image_color_id) return;
  const dpi_ratio = _getDpiRatio(cv_obj);
  return cv_obj.scaleX * cv_obj.width * ratio * parent_ratio > cv_obj._data.image_width * dpi_ratio;
}

function cmToInch(cm) {
  return cm / 2.54;
}

function _getDpiRatio(cv_obj) {
  const basic_dpi = 150;
  return basic_dpi / (cv_obj._data.dpi > basic_dpi ? cv_obj._data.dpi : basic_dpi);
}
