import { PriceConstantS } from '../Constant/module/PriceConstantS.js';

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export function price(n) {
  if (typeof n == 'string') n = n.replace(/,/g, '');
  if (!n || isNaN(n)) return 0;
  return isFloat(parseFloat(n)) ? parseFloat(parseFloat(n || 0).toFixed(PriceConstantS.DIGIT)) : parseInt(n);
}
