export const purchase = (projection) => {
  const cts_script = function (p) {
    //<![CDATA[
    if (typeof DaumConversionScriptLoaded == 'undefined' && location.protocol != 'file:') {
      window.DaumConversionDctSv = 'type=P,orderID=' + p.id + ',amount=' + p.product_price;
      window.DaumConversionAccountID = 'kbIs1intPX9vRxZ7Z4IXxw00';
      window.DaumConversionScriptLoaded = true;
      (function () {
        const d = document.createElement('script');
        d.type = 'text/javas' + 'cript';
        d.src =
          (location.protocol == 'https:' ? 'https' : 'http') +
          '://t1.daumcdn.net/cssjs/common/cts/vr200/dcts.js';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(d, s);
      })();
    }
    //]]>
  };

  setTimeout(function () {
    cts_script(projection);
  }, 100);
};

export const mpShopApplication = () => {
  const cts_script = function () {
    //<![CDATA[
    if (typeof DaumConversionScriptLoaded == 'undefined' && location.protocol != 'file:') {
      window.DaumConversionDctSv = 'type=M,orderID=,amount=';
      window.DaumConversionAccountID = 'kbIs1intPX9vRxZ7Z4IXxw00';
      window.DaumConversionScriptLoaded = true;
      (function () {
        const d = document.createElement('script');
        d.type = 'text/javas' + 'cript';
        d.src =
          (location.protocol == 'https:' ? 'https' : 'http') +
          '://t1.daumcdn.net/cssjs/common/cts/vr200/dcts.js';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(d, s);
      })();
    }
    //]]>
  };

  setTimeout(function () {
    cts_script();
  }, 100);
};

export const plusApplication = () => {
  const cts_script = function () {
    //<![CDATA[
    if (typeof DaumConversionScriptLoaded == 'undefined' && location.protocol != 'file:') {
      window.DaumConversionDctSv = 'type=W,orderID=,amount=';
      window.DaumConversionAccountID = 'kbIs1intPX9vRxZ7Z4IXxw00';
      window.DaumConversionScriptLoaded = true;
      (function () {
        const d = document.createElement('script');
        d.type = 'text/javas' + 'cript';
        d.src =
          (location.protocol == 'https:' ? 'https' : 'http') +
          '://t1.daumcdn.net/cssjs/common/cts/vr200/dcts.js';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(d, s);
      })();
    }
    //]]>
  };

  setTimeout(function () {
    cts_script();
  }, 100);
};
