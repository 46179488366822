export const animation_data = {
  printer: {
    v: '5.5.7',
    meta: { g: 'LottieFiles AE 0.1.20', a: '', k: '', d: '', tc: '' },
    fr: 60,
    ip: 0,
    op: 71,
    w: 256,
    h: 256,
    nm: 'Printer Icon (Lottie Animation)',
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: 'File with Words Matte',
        td: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [128, 137, 0], ix: 2 },
          a: { a: 0, k: [63, 36, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [27, 119],
                      [-27, 119],
                      [-27, -6],
                      [27, -6],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 2,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [63, 51], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Paper Output',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 71,
        st: -1,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: 'File with words',
        tt: 1,
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              { i: { x: [0.301], y: [1] }, o: { x: [0.989], y: [0] }, t: 61, s: [100] },
              { t: 74, s: [0] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: { a: 0, k: 128, ix: 3 },
            y: {
              a: 1,
              k: [
                { i: { x: [0.019], y: [1] }, o: { x: [0.22], y: [0.706] }, t: 0, s: [-47] },
                { i: { x: [0.919], y: [0.606] }, o: { x: [0.942], y: [0] }, t: 20, s: [101] },
                { t: 70, s: [305] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [42, 57, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        hasMask: true,
        masksProperties: [
          {
            inv: false,
            mode: 'a',
            pt: {
              a: 1,
              k: [
                {
                  i: { x: 0.75, y: 1 },
                  o: { x: 0.958, y: 0 },
                  t: 20,
                  s: [
                    {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.844, 102],
                        [10.768, 101.955],
                        [10.854, 101.91],
                        [72.93, 101.955],
                      ],
                      c: true,
                    },
                  ],
                },
                {
                  t: 68,
                  s: [
                    {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [72.902, 10.625],
                        [10.826, 10.58],
                        [10.826, 102.785],
                        [72.902, 102.83],
                      ],
                      c: true,
                    },
                  ],
                },
              ],
              ix: 1,
            },
            o: { a: 0, k: 100, ix: 3 },
            x: { a: 0, k: 0, ix: 4 },
            nm: 'Mask 1',
          },
        ],
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [51, 66],
                          [57, 66],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 6, ix: 5 },
                    lc: 2,
                    lj: 1,
                    ml: 10,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [27, 66],
                          [42, 66],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 6, ix: 5 },
                    lc: 2,
                    lj: 1,
                    ml: 10,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 2',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [42, 54],
                          [57, 54],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 6, ix: 5 },
                    lc: 2,
                    lj: 1,
                    ml: 10,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 3',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 3,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [27, 54],
                          [33, 54],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 6, ix: 5 },
                    lc: 2,
                    lj: 1,
                    ml: 10,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 4',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 4,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [39, 78],
                          [57, 78],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 6, ix: 5 },
                    lc: 2,
                    lj: 1,
                    ml: 10,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 5',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 5,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [27, 78],
                          [30, 78],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 6, ix: 5 },
                    lc: 2,
                    lj: 1,
                    ml: 10,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 6',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 6,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [27, 42],
                          [57, 42],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 6, ix: 5 },
                    lc: 2,
                    lj: 1,
                    ml: 10,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 7',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 7,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Text Lines',
            np: 7,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [-1.657, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 1.657],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-7.5, -7.5],
                          [-7.5, 4.5],
                          [-4.5, 7.5],
                          [7.5, 7.5],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 6, ix: 5 },
                    lc: 1,
                    lj: 1,
                    ml: 10,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [61.5, 22.5], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 8',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [3.313, 0],
                          [0, 0],
                          [0, 3.313],
                          [0, 0],
                          [-3.313, 0],
                          [0, 0],
                          [-1.125, -1.125],
                          [0, 0],
                          [0, 0],
                          [0, -1.591],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-3.313, 0],
                          [0, 0],
                          [0, -3.313],
                          [0, 0],
                          [1.591, 0],
                          [0, 0],
                          [0, 0],
                          [1.125, 1.125],
                          [0, 0],
                          [0, 3.313],
                        ],
                        v: [
                          [21, 42],
                          [-21, 42],
                          [-27, 36],
                          [-27, -36],
                          [-21, -42],
                          [12.515, -42],
                          [16.757, -40.243],
                          [21, -36],
                          [25.243, -31.757],
                          [27, -27.515],
                          [27, 36],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 6, ix: 5 },
                    lc: 1,
                    lj: 1,
                    ml: 10,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [42, 57], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 9',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Paper',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 20,
        op: 72,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: 'Printer',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [128, 137, 0], ix: 2 },
          a: { a: 0, k: [63, 36, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [27, 6],
                      [-27, 6],
                      [-27, -6],
                      [27, -6],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 2,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [63, 51], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Paper Output',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: { a: 0, k: { i: [[0, 0]], o: [[0, 0]], v: [[27, 27]], c: true }, ix: 2 },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Left Dot',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [87, 27],
                      [99, 27],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Right Line',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 3,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [3.313, 0],
                      [0, 0],
                      [0, 3.313],
                      [0, 0],
                      [-3.313, 0],
                      [0, 0],
                      [0, -3.313],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-3.313, 0],
                      [0, 0],
                      [0, -3.313],
                      [0, 0],
                      [3.313, 0],
                      [0, 0],
                      [0, 3.313],
                    ],
                    v: [
                      [42, 21],
                      [-42, 21],
                      [-48, 15],
                      [-48, -15],
                      [-42, -21],
                      [42, -21],
                      [48, -15],
                      [48, 15],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 2,
                lj: 2,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [63, 36], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Printer Body',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 4,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 71,
        st: -1,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: 'Empty File (Motion Test) 2',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              { i: { x: [0.019], y: [1] }, o: { x: [0.22], y: [0.706] }, t: 0, s: [0] },
              { t: 20, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: { a: 0, k: 128, ix: 3 },
            y: {
              a: 1,
              k: [
                { i: { x: [0.019], y: [1] }, o: { x: [0.22], y: [0.706] }, t: 0, s: [-47] },
                { i: { x: [0.919], y: [0.606] }, o: { x: [0.942], y: [0] }, t: 20, s: [101] },
                { t: 70, s: [305] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [42, 57, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-1.657, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 1.657],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-7.5, -7.5],
                      [-7.5, 4.5],
                      [-4.5, 7.5],
                      [7.5, 7.5],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [61.5, 22.5], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [3.313, 0],
                      [0, 0],
                      [0, 3.313],
                      [0, 0],
                      [-3.313, 0],
                      [0, 0],
                      [-1.125, -1.125],
                      [0, 0],
                      [0, 0],
                      [0, -1.591],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-3.313, 0],
                      [0, 0],
                      [0, -3.313],
                      [0, 0],
                      [1.591, 0],
                      [0, 0],
                      [0, 0],
                      [1.125, 1.125],
                      [0, 0],
                      [0, 3.313],
                    ],
                    v: [
                      [21, 42],
                      [-21, 42],
                      [-27, 36],
                      [-27, -36],
                      [-21, -42],
                      [12.515, -42],
                      [16.757, -40.243],
                      [21, -36],
                      [25.243, -31.757],
                      [27, -27.515],
                      [27, 36],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [42, 57], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 2',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 72,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  },
  cardboard: {
    v: '5.4.2',
    fr: 60,
    ip: 0,
    op: 180,
    w: 500,
    h: 500,
    nm: 'crate',
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: 'crate Outlines - Group 2',
        parent: 5,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [221.13, 233.631, 0], ix: 2 },
          a: { a: 0, k: [22.284, 22.124, 0], ix: 1 },
          s: { a: 0, k: [750, 750, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 0,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [-5.742, -1.105],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [1.908, -1.373],
                            [-5.746, -5.795],
                            [-5.742, -1.105],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 27,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [1.908, -1.373],
                            [-5.746, -5.795],
                            [-5.742, -1.105],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [-2.592, 5.773],
                            [-10.246, 1.351],
                            [-5.742, -1.104],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 50,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [-2.592, 5.773],
                            [-10.246, 1.351],
                            [-5.742, -1.104],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [-2.592, 5.773],
                            [-10.246, 1.351],
                            [-5.742, -1.105],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 127,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [-2.592, 5.773],
                            [-10.246, 1.351],
                            [-5.742, -1.105],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [1.908, -1.373],
                            [-5.746, -5.795],
                            [-5.742, -1.105],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 145,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [1.908, -1.373],
                            [-5.746, -5.795],
                            [-5.742, -1.105],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [-5.742, -1.105],
                          ],
                          c: true,
                        },
                      ],
                    },
                    { t: 166 },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0.223528977936, 0.192156967462, 0.674509983437, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 2,
                lj: 2,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [24.217, 20.99], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 2',
            np: 3,
            cix: 2,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 180,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: 'crate Outlines - Group 1',
        parent: 5,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [223.882, 199.311, 0], ix: 2 },
          a: { a: 0, k: [22.651, 17.547, 0], ix: 1 },
          s: { a: 0, k: [750, 750, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 0,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [1.914, -3.315],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-5.861, -3.794],
                            [1.793, -8.215],
                            [1.914, -3.315],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 27,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-5.861, -3.794],
                            [1.793, -8.215],
                            [1.914, -3.315],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-9.78, -1.085],
                            [-2.126, -5.506],
                            [1.914, -3.315],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 50,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-9.78, -1.085],
                            [-2.126, -5.506],
                            [1.914, -3.315],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-9.78, -1.085],
                            [-2.126, -5.506],
                            [1.914, -3.315],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 127,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-9.78, -1.085],
                            [-2.126, -5.506],
                            [1.914, -3.315],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-5.861, -3.794],
                            [1.793, -8.215],
                            [1.914, -3.315],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 145,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-5.861, -3.794],
                            [1.793, -8.215],
                            [1.914, -3.315],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [1.914, -3.315],
                          ],
                          c: true,
                        },
                      ],
                    },
                    { t: 166 },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0.223528977936, 0.192156967462, 0.674509983437, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 2,
                lj: 2,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [24.218, 18.781], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 3,
            cix: 2,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 180,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: 'crate Outlines - Group 3',
        parent: 5,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [278.846, 233.382, 0], ix: 2 },
          a: { a: 0, k: [29.98, 22.09, 0], ix: 1 },
          s: { a: 0, k: [750, 750, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 27,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [1.915, -3.315],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-1.833, -0.967],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [5.823, -5.385],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 54,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-1.833, -0.967],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [5.823, -5.385],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [2.267, 5.603],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [9.923, 1.185],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 77,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [2.267, 5.603],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [9.923, 1.185],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [2.267, 5.603],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [9.923, 1.185],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 90,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [2.267, 5.603],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [9.923, 1.185],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-1.833, -0.967],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [5.823, -5.385],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 110,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-1.833, -0.967],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [5.823, -5.385],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-5.741, 1.103],
                            [-1.913, 3.315],
                            [5.741, -1.106],
                            [1.915, -3.315],
                          ],
                          c: true,
                        },
                      ],
                    },
                    { t: 133 },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0.223528977936, 0.192156967462, 0.674509983437, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 2,
                lj: 2,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [28.046, 20.99], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 3',
            np: 3,
            cix: 2,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 180,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: 'crate Outlines - Group 4',
        parent: 5,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [273.38, 199.381, 0], ix: 2 },
          a: { a: 0, k: [25.717, 19.657, 0], ix: 1 },
          s: { a: 0, k: [750, 750, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 27,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [-5.742, -1.105],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [5.814, -3.589],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [-1.845, -8.009],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 54,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [5.814, -3.589],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [-1.845, -8.009],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [9.951, -1.384],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [2.292, -5.804],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 77,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [9.951, -1.384],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [2.292, -5.804],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [9.951, -1.384],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [2.292, -5.804],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 90,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [9.951, -1.384],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [2.292, -5.804],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [5.814, -3.589],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [-1.845, -8.009],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.333, y: 0 },
                      n: '0p667_1_0p333_0',
                      t: 110,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [5.814, -3.589],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [-1.845, -8.009],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.917, 3.316],
                            [5.742, 1.107],
                            [-1.913, -3.316],
                            [-5.742, -1.105],
                          ],
                          c: true,
                        },
                      ],
                    },
                    { t: 133 },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [0.223528977936, 0.192156967462, 0.674509983437, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1, ix: 5 },
                lc: 2,
                lj: 2,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [24.217, 20.99], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 4',
            np: 3,
            cix: 2,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 180,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: 'Merged Shape Layer',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0.374, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                n: '0p667_1_0p333_0',
                t: 0,
                s: [250, 250, 0],
                e: [250, 223, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                n: '0p667_1_0p333_0',
                t: 89,
                s: [250, 223, 0],
                e: [250, 250, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 179 },
            ],
            ix: 2,
          },
          a: { a: 0, k: [250, 250, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [26.132, 15.465],
                          [26.132, 24.305],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0.223528981209, 0.192156970501, 0.674510002136, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 1, ix: 5 },
                    lc: 2,
                    lj: 2,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 5',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [249.988, 216.844], ix: 2 },
                a: { a: 0, k: [26.132, 19.885], ix: 1 },
                s: { a: 0, k: [750, 750], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'crate Outlines - Group 5',
            np: 1,
            cix: 2,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.001, -4.42],
                          [-7.656, 0],
                          [0.003, 4.42],
                          [7.656, -0.002],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0.223528981209, 0.192156970501, 0.674510002136, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 1, ix: 5 },
                    lc: 2,
                    lj: 2,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [26.131, 19.885], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 6',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [249.985, 216.844], ix: 2 },
                a: { a: 0, k: [26.131, 19.885], ix: 1 },
                s: { a: 0, k: [750, 750], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'crate Outlines - Group 6',
            np: 1,
            cix: 2,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [3.824, -6.632],
                          [3.828, 2.21],
                          [-3.828, 6.632],
                          [-3.828, -2.21],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0.223528981209, 0.192156970501, 0.674510002136, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 1, ix: 5 },
                    lc: 2,
                    lj: 2,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [29.963, 26.515], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 7',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [278.723, 266.569], ix: 2 },
                a: { a: 0, k: [29.963, 26.515], ix: 1 },
                s: { a: 0, k: [750, 750], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'crate Outlines - Group 7',
            np: 1,
            cix: 2,
            ix: 3,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.829, -6.63],
                          [-3.829, 2.208],
                          [3.829, 6.63],
                          [3.829, -2.21],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [0.223528981209, 0.192156970501, 0.674510002136, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 1, ix: 5 },
                    lc: 2,
                    lj: 2,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [22.305, 26.516], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 8',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [221.288, 266.576], ix: 2 },
                a: { a: 0, k: [22.305, 26.516], ix: 1 },
                s: { a: 0, k: [750, 750], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'crate Outlines - Group 8',
            np: 1,
            cix: 2,
            ix: 4,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 180,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  },
  dots: {
    nm: 'Comp 1',
    ddd: 0,
    h: 600,
    w: 600,
    meta: { g: '@lottiefiles/toolkit-js 0.26.1' },
    layers: [
      {
        ty: 4,
        nm: 'Shape Layer 7',
        sr: 1,
        st: 0,
        op: 25.0000010182709,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [-216, -5.25, 0], ix: 1 },
          s: { a: 0, k: [75, 75, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [346.79474999999996, 296.0625, 0],
                t: 15,
                ti: [0, 0, 0],
                to: [0, -8.667, 0],
              },
              {
                o: { x: 0.333, y: 0 },
                i: { x: 0.833, y: 0.833 },
                s: [346.79474999999996, 257.0625, 0],
                t: 20,
                ti: [0, -1.267, 0],
                to: [0, 0, 0],
              },
              { s: [346.79474999999996, 296.0625, 0], t: 25.0000010182709 },
            ],
            ix: 2,
          },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [38.221, 38.221], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.5255, 0.4471, 0.9804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-216.145, -5.272], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 1,
      },
      {
        ty: 4,
        nm: 'Shape Layer 9',
        sr: 1,
        st: 0,
        op: 25.0000010182709,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [-216, -5.25, 0], ix: 1 },
          s: { a: 0, k: [75, 75, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [308.91974999999996, 296.0625, 0],
                t: 10,
                ti: [0, 0, 0],
                to: [0, -8.667, 0],
              },
              {
                o: { x: 0.333, y: 0 },
                i: { x: 0.833, y: 0.833 },
                s: [308.91974999999996, 257.0625, 0],
                t: 15,
                ti: [0, -1.267, 0],
                to: [0, 0, 0],
              },
              { s: [308.91974999999996, 296.0625, 0], t: 20.0000008146167 },
            ],
            ix: 2,
          },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [38.221, 38.221], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.1647, 0.8706, 0.6314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-216.145, -5.272], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 2,
      },
      {
        ty: 4,
        nm: 'Shape Layer 8',
        sr: 1,
        st: 0,
        op: 25.0000010182709,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [-216, -5.25, 0], ix: 1 },
          s: { a: 0, k: [75, 75, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [271.79474999999996, 296.0625, 0],
                t: 5,
                ti: [0, 0, 0],
                to: [0, -8.667, 0],
              },
              {
                o: { x: 0.333, y: 0 },
                i: { x: 0.833, y: 0.833 },
                s: [271.79474999999996, 257.0625, 0],
                t: 10,
                ti: [0, -1.267, 0],
                to: [0, 0, 0],
              },
              { s: [271.79474999999996, 296.0625, 0], t: 15.0000006109625 },
            ],
            ix: 2,
          },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [38.221, 38.221], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [1, 0.7412, 0.2118], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-216.145, -5.272], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 3,
      },
      {
        ty: 4,
        nm: 'Shape Layer 1',
        sr: 1,
        st: 0,
        op: 25.0000010182709,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [-216, -5.25, 0], ix: 1 },
          s: { a: 0, k: [75, 75, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [234.66974999999996, 296.0625, 0],
                t: 0,
                ti: [0, 0, 0],
                to: [0, -8.667, 0],
              },
              {
                o: { x: 0.333, y: 0 },
                i: { x: 0.833, y: 0.833 },
                s: [234.66974999999996, 257.0625, 0],
                t: 5,
                ti: [0, -1.267, 0],
                to: [0, 0, 0],
              },
              { s: [234.66974999999996, 296.0625, 0], t: 10.0000004073083 },
            ],
            ix: 2,
          },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [38.221, 38.221], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.9137, 0.2745, 0.3373], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-216.145, -5.272], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 4,
      },
    ],
    v: '5.7.4',
    fr: 30,
    op: 24,
    ip: 0,
    assets: [],
  },
  check: {
    nm: 'Comp 2',
    ddd: 0,
    h: 360,
    w: 360,
    meta: { g: '@lottiefiles/toolkit-js 0.26.1' },
    layers: [
      {
        ty: 4,
        nm: 'Shape Layer 6',
        sr: 1,
        st: -10.0000004073083,
        op: 290.000011811942,
        ip: 107.000004358199,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [120, 120, 100], t: 107 },
              { s: [100, 100, 100], t: 114.000004643315 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [180, 173, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0], t: 107 },
              { s: [100], t: 114.000004643315 },
            ],
            ix: 11,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Shape 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-35.5, 2.5],
                      [-11, 27.5],
                      [41, -22],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 1,
      },
      {
        ty: 4,
        nm: 'Shape Layer 5',
        sr: 1,
        st: 100.000004073084,
        op: 400.000016292334,
        ip: 100.000004073084,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [11, -8, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [19, 19, 100], t: 100 },
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [120, 120, 100], t: 106.25 },
              { s: [100, 100, 100], t: 117.500004785873 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [179.326, 179.326, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [0], t: 100 },
              { s: [100], t: 106.250004327651 },
            ],
            ix: 11,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [149.348, 149.348], ix: 2 },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.1569, 0.7059, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [11.674, -7.326], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 2,
      },
      {
        ty: 4,
        nm: 'Shape Layer 4',
        sr: 0.8,
        st: 85.000003462121,
        op: 325.000013237521,
        ip: 85.000003462121,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [168.326, 187.326, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [149.348, 149.348], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                c: { a: 0, k: [0.1569, 0.7059, 0.3882], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [11.674, -7.326], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'tm',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Filter - Trim',
            nm: 'Trim Paths 1',
            ix: 2,
            e: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 85 },
                { s: [0], t: 100.000004073084 },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            s: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.667, y: 1 }, s: [0], t: 85 },
                { s: [100], t: 100.000004073084 },
              ],
              ix: 1,
            },
            m: 1,
          },
        ],
        ind: 3,
      },
      {
        ty: 4,
        nm: 'Shape Layer 3',
        sr: 0.8,
        st: 57.0000023216576,
        op: 297.000012097058,
        ip: 57.0000023216576,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [168.326, 187.326, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [149.348, 149.348], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                c: { a: 0, k: [0.7922, 0.8118, 0.8235], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [11.674, -7.326], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'tm',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Filter - Trim',
            nm: 'Trim Paths 1',
            ix: 2,
            e: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0], t: 57 },
                { s: [100], t: 85.000003462121 },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            s: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [3], t: 57 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [71.171], t: 67.4 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [90.083], t: 77 },
                { s: [100], t: 85.000003462121 },
              ],
              ix: 1,
            },
            m: 1,
          },
        ],
        ind: 4,
      },
      {
        ty: 4,
        nm: 'Shape Layer 2',
        sr: 0.8,
        st: 29.0000011811942,
        op: 269.000010956595,
        ip: 29.0000011811942,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [168.326, 187.326, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [149.348, 149.348], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                c: { a: 0, k: [0.7922, 0.8118, 0.8235], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [11.674, -7.326], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'tm',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Filter - Trim',
            nm: 'Trim Paths 1',
            ix: 2,
            e: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0], t: 29 },
                { s: [100], t: 57.0000023216576 },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            s: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [3], t: 29 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [71.171], t: 39.4 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [90.083], t: 49 },
                { s: [100], t: 57.0000023216576 },
              ],
              ix: 1,
            },
            m: 1,
          },
        ],
        ind: 5,
      },
      {
        ty: 4,
        nm: 'Shape Layer 1',
        sr: 0.8,
        st: 0,
        op: 240.0000097754,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [168.326, 187.326, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [149.348, 149.348], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 6, ix: 5 },
                c: { a: 0, k: [0.7922, 0.8118, 0.8235], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [11.674, -7.326], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'tm',
            bm: 0,
            hd: false,
            mn: 'ADBE Vector Filter - Trim',
            nm: 'Trim Paths 1',
            ix: 2,
            e: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0], t: 0 },
                { s: [100], t: 28.0000011404634 },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            s: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [3], t: 0 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [71.171], t: 10 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [90.083], t: 20 },
                { s: [100], t: 28.0000011404634 },
              ],
              ix: 1,
            },
            m: 1,
          },
        ],
        ind: 6,
      },
    ],
    v: '5.1.16',
    fr: 29.9700012207031,
    op: 145.000005905971,
    ip: 0,
    assets: [],
  },
};
