import { html } from 'fxjs/es';
import { SizeTipTmpl } from '../../../CateList/S/Function/SizeTipTmpl.js';
import { CateListConstantS } from '../../../CateList/S/Constant/module/CateListConstantS.js';

const small_product_bp_ids = [
  4444, 4445, 4446, 4447, 4448, 4449, 4450, 4451, 4452, 4634, 4644, 4692, 4702, 4703, 4553, 4554, 4555, 4556,
  4401, 4395, 4498, 4402, 4399, 4400, 4349, 4350, 4351, 4352, 4348, 4347,
];

export const size = ({ size_table, size_info_url, id, cate_list_id, cate_item_id }) =>
  size_table
    ? html`
        <div class="toggle_cont tg_size" is_open="true">
          <div class="tg_head">
            <span class="tit">${T('product_detail::Size chart')}</span>
            <span class="btn"></span>
          </div>
          <div class="tg_body">
            ${size_table}
            ${T.lang == 'kr' ? SizeTipTmpl({ id, cate_list_id, cate_item_id, size_info_url }) : html``}
            ${T.lang == 'jp' &&
            cate_list_id != CateListConstantS.PHONE_ACC_LIST_ID &&
            !small_product_bp_ids.includes(id)
              ? `<p class="size_txt1">表記サイズには若干の(約1~3cm)誤差がある場合がございますが、ご了承ください。</p>`
              : html``}
          </div>
        </div>
      `
    : '';
