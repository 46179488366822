import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

export const cateSelectorBody = ({ cate_lists, checked_cate_item_id, checked_cate_list_id }) => {
  const tt_all = TT('product::list::list_9_1');
  return strMap((cate_list) => {
    const checked = !checked_cate_item_id && cate_list.id == checked_cate_list_id;
    return html`<div class="omp-product-cate-selector__cate-list">
      <div class="omp-product-cate-selector__cate-list-name">${cate_list.name}</div>
      <div class="omp-product-cate-selector__cate-items">
        <label class="omp-product-cate-selector__cate-item">
          <input
            type="radio"
            name="cate-item"
            class="omp-product-cate-selector__cate-item-radio"
            ${checked ? 'checked' : ''}
            data-cate_list_id="${cate_list.id}"
          />
          <span class="omp-product-cate-selector__cate-item-name" data-radio-checked="${checked}"
            >${tt_all}</span
          >
        </label>
        ${strMap((cate_item) => {
          const checked = cate_item.id == checked_cate_item_id;
          return html`
            <label class="omp-product-cate-selector__cate-item">
              <input
                type="radio"
                name="cate-item"
                class="omp-product-cate-selector__cate-item-radio"
                ${checked ? 'checked' : ''}
                data-cate_list_id="${cate_list.id}"
                data-cate_item_id="${cate_item.id}"
              />
              <span class="omp-product-cate-selector__cate-item-name" data-radio-checked="${checked}"
                >${cate_item.name}</span
              >
            </label>
          `;
        }, cate_list._.cate_items)}
      </div>
    </div>`;
  }, cate_lists);
};
export const cateSelector = ({ cate_lists, checked_cate_list_id, checked_cate_item_id }) => {
  const tt_title = TT('product::list::list_15_1');

  return html`
    <div class="omp-product-cate-selector">
      <div class="omp-product-cate-selector__body">
        <div class="omp-product-cate-selector__title">
          ${tt_title} <button class="omp-product-cate-selector__x-btn">${OMPCoreAtomTmplS.xIcon()}</button>
        </div>
        <div class="omp-product-cate-selector__cate-lists">
          ${cateSelectorBody({ cate_lists, checked_cate_list_id, checked_cate_item_id })}
        </div>
      </div>
    </div>
  `;
};
