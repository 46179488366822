import * as _tab from './tab.js';

$.frame.defn_page({
  page_name: 'terms_conditions_page',
  title: T('Terms & Conditions'),
  tabs: [_tab.terms_and_conditions_tab()],
});

$.frame.defn_page({
  page_name: 'privacy_page',
  title: T('Privacy Policy'),
  tabs: [_tab.privacy_tab()],
});

$.frame.defn_page({
  page_name: 'privacy_login_page',
  title: T('Privacy Policy'),
  tabs: [_tab.privacy_login_tab()],
});

$.frame.defn_page({
  page_name: 'commerce_law_page',
  title: '特定商取引法に基づく表記',
  tabs: [_tab.commerce_law_tab()],
});
