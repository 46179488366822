import { R_STROKE_PROPERTY_NAME, STROKE_PROPERTY_NAME, TOOL_NAME } from '../../S/Constant/constants.js';
import { DfImageEditorLibF } from './module/DfImageEditorLibF.js';
import { DfImageEditorF } from '../Function/module/DfImageEditorF.js';

export const getState = {
  selected_tool: () => DfImageEditorLibF.imageEditorStatus.selectedTool,
  printable_file_info: () => DfImageEditorLibF.imageEditorStatus.printable_file_info,
  subTool: {
    [TOOL_NAME.erase]: () => DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.erase].subTool,
  },
  property: {
    rStroke: {
      thickness: () =>
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.rStroke].properties[
          R_STROKE_PROPERTY_NAME.thickness
        ],
      color: () =>
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.rStroke].properties[R_STROKE_PROPERTY_NAME.color],
      alphaThreshold: () =>
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.rStroke].properties[
          R_STROKE_PROPERTY_NAME.alpha_threshold
        ],
    },
    strokeColor: () =>
      DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.color],
    strokeWidth: () =>
      DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.width],
    strokeOffset: () =>
      DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.offset],
    eraseBrush: () => getBrushState(TOOL_NAME.erase),
  },
  vStroke: {
    boundaryStrokes: () => DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.vStroke].boundary.strokes,
    bounds: ({ stroke_layer }) =>
      DfImageEditorF.getItemInLayerById({
        layer: stroke_layer,
        id: DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.vStroke].boundary.bounds,
      }),
  },
  brush: {
    slider: ({ tool_name, brush_name }) =>
      DfImageEditorLibF.imageEditorStatus.tool[tool_name].properties.brush[brush_name].slider,
    indicator: ({ tool_name, brush_name }) =>
      DfImageEditorLibF.imageEditorStatus.tool[tool_name].properties.brush[brush_name].indicator,
  },
};

export const getBrushState = (tool_name) =>
  DfImageEditorLibF.imageEditorStatus.tool[tool_name].properties.brush;
