import { every, go, range, filter } from 'fxjs/es';
import { getPfByFcanvas } from '../../../../Maker/F/getSth.js';
import { NewMakerFcanvasFuncF } from '../../../Fcanvas/Func/F/Function/module/NewMakerFcanvasFuncF.js';
import { createCanvasElement, makeCloneCanvas } from '../../../../Canvas/S/util.js';
import { isSameCanvas } from './utils.js';

/**
 * 이미지 점선 꽉 채움 체크 로직
 * */
export async function isFullFillImage(cv_target) {
  const pf = getPfByFcanvas(cv_target.canvas);
  const width = 400;
  const base_canvas = await getBaseCanvas(pf, width);
  const target_canvas = await go(makeCloneCanvas(base_canvas), async (canvas) => {
    const ctx = canvas.getContext('2d');
    const design_canvas = await NewMakerFcanvasFuncF.makeCanvasToCheckWithAnyCvObs({
      cv_objs: go(
        pf.designs,
        filter((design) => design.cid === cv_target.cid),
      ),
      width,
    });
    ctx.globalCompositeOperation = 'destination-in';
    ctx.drawImage(design_canvas, 0, 0);
    return canvas;
  });
  return isSameCanvas(base_canvas, target_canvas);
}

function makeWrongPartCanvas(canvas1, canvas2) {
  const canvas = createCanvasElement(canvas1);
  const width = canvas.width;
  const height = canvas.height;
  const ctx = canvas.getContext('2d');
  const image_data1 = canvas1.getContext('2d').getImageData(0, 0, width, height).data;
  const image_data2 = canvas2.getContext('2d').getImageData(0, 0, width, height).data;
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  for (let y = 0; y < height; ++y) {
    for (let x = 0; x < width; ++x) {
      const index = (y * width + x) * 4;
      if (
        go(
          range(4),
          every((i) => image_data1[index + i] === image_data2[index + i]),
        )
      )
        continue;
      imageData.data[index] = 255;
      imageData.data[index + 3] = 255;
    }
  }
  ctx.putImageData(imageData, 0, 0);
}

async function getBaseCanvas(pf, width) {
  const base_canvas = await NewMakerFcanvasFuncF.makeCanvasToCheckWithAnyCvObs({
    cv_objs: [pf.cv_mask2],
    width,
  });

  const cv_bpcf = await NewMakerFcanvasFuncF.makeCanvasToCheckWithAnyCvObs({
    cv_objs: [pf.cv_mask1],
    width,
  });

  const base_canvas_ctx = base_canvas.getContext('2d');
  base_canvas_ctx.globalCompositeOperation = 'source-over';
  base_canvas_ctx.drawImage(cv_bpcf, 0, 0);
  return base_canvas;
}

function changeNonTransparentPixelsToBlack(imageData, width, height, strength = 0) {
  for (let y = 0; y < height; ++y) {
    for (let x = 0; x < width; ++x) {
      const index = (y * width + x) * 4;
      if (imageData.data[index + 3] > strength) {
        imageData.data[index] = 0; // R
        imageData.data[index + 1] = 0; // G
        imageData.data[index + 2] = 0; // B
        imageData.data[index + 3] = 255;
      }
    }
  }
}
