import { compact, each, filter, go, join, map, sel } from 'fxjs/es';
import { BpOptionS } from '../../../BpOption/S/Function/module/BpOptionS.js';
import { isNeedPreview } from '../../../Maker/S/categorize.js';

export const naming = {
  makeBpNameWithColor: ({ base_product, base_product_color }, _en = G._en) => {
    let name = base_product['name' + _en];
    if (
      !isNeedPreview(base_product) &&
      !base_product.is_bpc_hidden &&
      base_product_color &&
      base_product_color['name' + _en]
    ) {
      name += ` [${base_product_color['name' + _en]}]`;
    }
    return name;
  },
  /* type:'/' */
  makeOptionNames1: (
    {
      selected_option_group = {},
      base_product_size = {},
      base_product_color = {},
      base_product,
      product_faces2 = {},
    },
    type = ' / ',
    _en = G._en,
    only_non_designed = false,
  ) => {
    if (type === ' / ') {
      const option_names = go(
        [
          only_non_designed && !base_product.is_bpf_hidden && _en === ''
            ? go(
                product_faces2?.value,
                filter(sel('designs.length')),
                map((pf) => pf['face_name' + _en] || pf.face_name_en),
                (r) => r.join(' / '),
                (face_names) => {
                  return face_names !== '' ? '' : '인쇄 : 없음 (무지 상품)';
                },
              )
            : null,
        ]
          .concat([
            base_product?.is_bpc_hidden || isNeedPreview(base_product)
              ? null
              : base_product_color?.['name' + _en],
          ])
          .concat(BpOptionS.selectedOptionGroup.getOnlyOptionNames(selected_option_group, _en))
          .concat(base_product?.is_bps_hidden ? null : [base_product_size?.['name' + _en]]),
        compact,
        join(' / '),
      );
      return option_names;
    } else {
      const face_translation = { name: '인쇄', name_en: 'Print', name_jp: '印刷' };
      const color_translation = { name: '색상', name_en: 'Color', name_jp: 'カラー' };
      const size_translation = { name: '사이즈', name_en: 'Size', name_jp: 'サイズ' };
      const option_names = go(
        [
          base_product.is_bpf_hidden
            ? null
            : go(
                product_faces2?.value,
                filter(sel('designs.length')),
                map((pf) => pf['face_name' + _en] || pf.face_name_en),
                (r) => r.join(' / '),
                (face_names) => ({
                  option_group_name: face_translation['name' + _en],
                  option_name: face_names === '' && _en === '' ? '없음 (무지 상품)' : face_names,
                }),
              ),
        ]
          .concat([
            base_product?.is_bpc_hidden || isNeedPreview(base_product)
              ? null
              : {
                  option_group_name: color_translation['name' + _en],
                  option_name: base_product_color?.['name' + _en],
                },
          ])
          .concat(BpOptionS.selectedOptionGroup.getSortedOptions(selected_option_group, _en))
          .concat([
            base_product?.is_bps_hidden
              ? null
              : {
                  option_group_name: size_translation['name' + _en],
                  option_name: base_product_size?.['name' + _en],
                },
          ]),
        filter((option) => option?.option_name),
      );
      return option_names;
    }
  },
  makeEmptyUpSize: (up_c_ss) => {
    go(
      up_c_ss,
      each((upcs) => {
        upcs.size = '';
      }),
    );
  },
};
