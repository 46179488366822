import { noop } from 'fxjs/es';
import { produce, setAutoFreeze, enableES5 } from 'immer';
enableES5();
setAutoFreeze(false);
export const tabInfinite = async (container_el$, original_infinite, assign = noop) => {
  const infinite = await produce(original_infinite, async (original_infinite) => {
    await assign(original_infinite);
  });
  infinite.data_func = infinite.data_func || infinite.makeInfiniteData || noop;
  return $.frame.infi(container_el$, infinite);
};
