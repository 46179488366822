import { each } from 'fxjs/es';

import { makeReviewDetailHtml } from '../S/tmpl.js';

export const defnReviewDetailTab = () => ({
  tab_name: 'mp.review.detail',
  removing: () => window.box.unset('review_list->review_detail'),
  template: (review) => {
    window.box.set('review_list->review_detail', review);
    return makeReviewDetailHtml(review);
  },
  appended: (el_don_tab) => {
    if (G.is_mobile_size()) $.after($.find1(el_don_tab, '.comment_wrap'), $.find1(el_don_tab, '.left_info'));
  },
  rendered: function (el_don_tab) {
    if (G.is_pc_size())
      new Swiper($.add_class($.find1(el_don_tab, '.review_imgs.swiper-container'), 'swiper-no-swiping'), {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
      });
    if (G.is_mobile_size())
      each((el_review_img) => {
        const file = window.box.sel(el_review_img);
        const w = $.width($.find1(el_don_tab, '.left_info'));
        $.css($.find1(el_review_img, 'img'), 'height', (w * file.height) / file.width || 'auto');
      }, $.find(el_don_tab, '.review_imgs_wrap .review_img'));
  },
});
