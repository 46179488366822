export const CARD_TAG = {
  bp_id: 5065,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/8676e7257e9d3f7af9951c608a45c0d987e67eff4.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/2c4a9a90517ccaad5da3625d9781630d3f52b41510.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/8388e70f953b54c5aa60d142cb146a1d9d8821bb11.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/29ee17311b0da1930d51aaea6c4b9e9f11d6899611.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/8ea5dc5e87376ae4c41780815155de446d3587d35.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/6ce9dea70f9912e004a097fc14ec177ea810fc773.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/0ae5d7f2367c56351d8a657e78241a6cdced688a9.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/70885881ea243b749bf9727eaea02511571fe5b28.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/37425de2f7df79461fefcae127e502e845dd71626.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/400b8e9645ce485f00098d018a92e35adc4ed5137.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/fbc7b8b55b70a810f7401611cd61d82440fa876a1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/fa098f3a2a1cd887bd2864109718e91db9320aa92.png',
};
export const SINGLE_BOOKMARK = {
  bp_id: 5066,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/dd4b498d46394cecfd5861bfca9ff2287298c6d14.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/9646f015852f68e42a2c1b22651363e341054c5f10.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/0764c2a9aa94f1076c5c06b8dcf261d6c112531f12.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/7e546e53bb6f377f36cd1b393236af5604a6181b12.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/555fd99ca50ebed6606fb5a54adf7ae2b1b0b34d5.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/b3e7fdc918e358830bc8ea41c3aa86d6d1c593453.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/719bb530fe76931a20aec9a6aa153dc4455593a89.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/8455fe81eac5f26a418e81cbdd1fea8d625cb75c8.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/884b9ef69721de6bb0e652d34a8427c9770956e56.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a53fd3d8de049328bd3081247679c760b13755097.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/042154bdf3ea23146ea97bf921905b752e94c0531.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/51b3cc9a8559b8764ca33dabf857ff92695c1f2f2.png',
};
export const ARCH_POSTCARD_S = {
  bp_id: 5057,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/8591d7a8200f546e269816efd9af4a56726170734.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/a7be4f08b038835e8baf912e34732d515f0ab65110.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/7fa01d4509256c9e4c9ff7edc6e3a6f7ed4265e83.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/44df050abb1c79a527dc1de36c9e0e0ff6830c493.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/1421eea868e56a22bbf0ef4ea89c83b6c64550b75.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/d487a8a31b4a07609688a261de9ebf3bed0e03333.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/65969503da925c36662cead9cb74e7a40d8343319.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/23505ca8d710335d53a1745dc381bd286d126ac08.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/594bb53441e7443dcbb5ab53f827299bd5edb1696.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/8dd3820c79d7ad66b662908fe15eb781ea7a74107.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/9ae73cf95d09b278eb2347c3f53ac54e7ab1b6151.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/9e48806af3e6623b6d54b50da770e7224eb0c7b52.png',
};
export const ARCH_POSTCARD_M = {
  bp_id: 5062,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/c21af3b3ab49c0e3479a3e303a9a26024c7184ed4.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/39995c5a4d9f5a3e0200917937d644f0692d270e10.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/cbba43cbeda2a789e46448adb5a1f3929e8cad958.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/00b21d3ada27a862123623f0c3f2e9f17dd7b7648.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/aacee19af50e5a2fe3b9f02e4750abe9a87401225.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/b692a7ab5fa1b3eb03e5c33bee6807072f9a1abd3.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/71d12caedfe795011c757d455bf91ed0db321e059.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/3c7d633a492c8e2a3369b737e6e9cc0198cabdee8.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/58de212612ec27a1fee4538734c08e8402fb61d16.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/f1c45fc008ecb9668de215a48166227b8f9a88277.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/67c9cdea1d6b85bc0e112949dcde98610e7f9d901.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/02413bdc66c9c6f249862ae7c8959ebda9ec5fdd2.png',
};
export const STANDARD_FOLDING_CARD_HORIZON = {
  bp_id: 5055,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/9bdcf916f768963a54c12c57fa236a259155ae476.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/63c8eaf3a49959dd92b2ed5d0cbe457ae164261914.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/29ae35e5723599aa5599e270468a771ef6e3c2b01.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/244921d2645a2237fa0dda4d5b7d4720e6d99eec1.jpg',
  folding_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/f61150c94eb16504004d04e92be21429682c3f321.jpg',
  folding_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/60cfcc06afc7cd5ac530d517a0419942f451a3eb2.jpg',
  folding_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/8612275f803e11eccd00b31d62bc0017ac5b51292.png',
  folding_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/25fb7c0c9f8aec0a59b3a518058cdccf2eea3ab54.png',
  folding_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/f3d55b22003ee7e74434d5953e422bffdca117168.png',
  folding_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/2bde832755fe7f8e6bf0ce512dbcf9221f31650711.png',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/4aa85344da67ce8ed0661ce1f417e16979cc10b47.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/40a2287be5c9a200e98c39be9cf5236890a827175.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/7e0cbe48b0e02aaee2a498d9d176d53d7f80ea4e13.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/35b458eb683607c6423a40aea9aed7660f56000c12.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/5a8c56465810c8f3bb133933d304060b5276e1f49.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a2ca63d6152bff39100e8f3cc53532ef6e87abc010.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/25330b9fa65a4d890f7eb1448cc11bfd7d281da11.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/c82fbf63bea2901372932b21b57b081fd877cda73.png',
};
export const STANDARD_FOLDING_CARD_VERTICAL = {
  bp_id: 5241,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/92921b50ac9220811c7719508fc50c2fe60cce196.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/94fa05b35dccddb718d7dc68b16aa487a1568f3b14.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/bd22bcb1eb162d4a0550fef02c788db3c0fbca532.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/9053ed47c67a2af42764eb5b47dda956b13165fa2.jpg',
  folding_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/f61150c94eb16504004d04e92be21429682c3f321.jpg',
  folding_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/60cfcc06afc7cd5ac530d517a0419942f451a3eb2.jpg',
  folding_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/cb61a7cef1514b6a6bd0ccf87d9efc8ac506e3042.png',
  folding_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/da6cf9084184dcd8cefed7d9066b89c9fd6185264.png',
  folding_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/7e024eff2a096612bcf680b59a51bdf6dd5935088.png',
  folding_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/1de4dd2ea41cc8fd40d7f7c6c72abd68bcbf18bd11.png',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/96adb5a6310b29244d7238e37292b19c87bfa5a37.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/69f686426aeb1904004d9b78fab78827ce260fc25.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/3228efd09a59a8281221c8ff62601405279ec4bf13.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a8b08d0d5efa40eab1f659601da0b44c1cac279c12.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/1ad9975ecb210d63df38be0f98300cbb4c4e1f299.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/4e18a1de01f8b6b8b7994344fd93fc32cd45d38210.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/7009dc90e82e817f6ce561793c94fd963600b59a1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/d01b3ac6708598332df4248dc7e60dc62c93cc0c3.png',
};
export const SQUARE_FOLDING_CARD_HORIZON = {
  bp_id: 5058,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/40acfd86aee863f52287c27244a01c9db2be91406.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/9572ade742eee3e89ef7c229d2cd15c6aac8ca3b14.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/98e67ef6a495609b48e8ec552d05a0683461e2424.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/73099baa3dcdf3156280ecf528c092cf4cd89ba94.jpg',
  folding_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/f61150c94eb16504004d04e92be21429682c3f321.jpg',
  folding_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/60cfcc06afc7cd5ac530d517a0419942f451a3eb2.jpg',
  folding_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/08337b6b7ceb3e639a914abe6dadfcea921fa69e2.png',
  folding_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/9bbec707acb55782e29b03f88787a42aa2d8c74f4.png',
  folding_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/1389bd241ac4a326bf23438312834e7b3ec7fda08.png',
  folding_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a4fc61a35a2acd92cbfec7c6fa264194130175ab11.png',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/aabd759e1ff375adfb25ba14c02d08139e81bd0e7.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/0397f216b07e0199695518cfbb6b5ca6436f8c2b5.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/09a9eecd749a3eba72b59ff4b364986a787eac1913.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/9c1fc5019cfc6ddead55ba98669cdb8429f3bd8e12.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/fc450ddb8c3dc4e19ca6da7cfcc2ed01ce2294039.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/0e8ce23743f0ba4136fa472a5194aef6ca695e0e10.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/e8fd52ea8a24cdd784fc15629e2a6718aac72b441.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a9faea63aea41f11263e69ff229ada4eb25dbf503.png',
};
export const SQUARE_FOLDING_CARD_VERTICAL = {
  bp_id: 5059,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/22a00632c3968bfd90cf346a485f8102ef54e5476.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/cf90e3c781bfaba734c9b8f754f40fe7a802254114.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/d9b83f7fc58d26045273cd3c9918f2ddaaf2cb655.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/b8e11daf66e9b6eb6a622e272e12ff4337afbcb55.jpg',
  folding_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/f61150c94eb16504004d04e92be21429682c3f321.jpg',
  folding_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/60cfcc06afc7cd5ac530d517a0419942f451a3eb2.jpg',
  folding_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/134aa4f15a0301a2e49d69191020050e21e3b4072.png',
  folding_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a4d2d4441a2d2365352759dfc68462ca4c65ebfa4.png',
  folding_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/6e39eca404d150b12e93951d83ee85b86ced32388.png',
  folding_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/ad7494cc6b06c361b9e5dc74fd87e36fd1cfd36d11.png',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/c029dd1fb5ab76dafe6cc084ee9d80dcf54502347.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/32e855a0be4998b3f450384b533e516ac58105745.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/003764416dbc886d1d0ece44a0261f19bfdb43fd13.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/6219ab5c5e14fc302e4473f759bf9c094a76c0e512.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/02936b868943f17fc5b3572eb65a8bf26e17f3649.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/2823a7be6192c83f5ddb73fe409d847dddbf4cfe10.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/26fcdf38121849642b083c7aa71886142bbf190b1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/6e6e210daaf4503f60e38315981576e57ffa91323.png',
};
export const MINI_SQUARE_FOLDING_CARD_HORIZON = {
  bp_id: 5061,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/fa85631b52ab7de936a9d69d9dfa7bf9fa8304587.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/14527f7410e8f42b1bc73f59b040d88c070a907214.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/17e1b509afc81399753d3ea4700d0a6fe2225f4e7.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/a01022bc19cf7f7395732e6826449e3007e817a87.jpg',
  folding_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/f61150c94eb16504004d04e92be21429682c3f321.jpg',
  folding_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/60cfcc06afc7cd5ac530d517a0419942f451a3eb2.jpg',
  folding_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/3c07337e1bfad9e375ccb4fc036527fdf89653922.png',
  folding_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/575a7aba845bf056061e8087e0ec7b87b3f1ee1f4.png',
  folding_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/788f065d1de414127c1b8a8b41591986f43d335b9.png',
  folding_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/c974fae97a0368964b705960fea2ec0f3bfd14d611.png',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/c0b17344a7845bc5b119650a33202651977fc6f88.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/baf56565a4d6d6cf50c9cd47f0d9a41ae1fa87306.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/1e96f01e5e8dc2b68dbfcb5f00d9017527fc39b713.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/afac4fc8d18abedb7952a5dd35fee3b6837945b212.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/648b19b213f3e8031d2ee435bba841b3b8508fcf5.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/59781a3b0973ad48f639a46685a986dde33c788910.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/233b82ae6387fd6d46f0dd9b55cb99446771dbca1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/cd24b81fe9d4cd43b2306cc3515c1618a1bab5463.png',
};
export const MINI_SQUARE_FOLDING_CARD_VERTICAL = {
  bp_id: 5060,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/e23a28c26179b39df44ba59043922d84a7a177d96.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/f83b3cc3002173dcdc59c9f50afd0c08e7171f6b14.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/50b9353f9d78057433fa08c667c46fb2f5b2c3af6.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/10e5ffaf329bb591d27235715806daa77dddf2da6.jpg',
  folding_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/f61150c94eb16504004d04e92be21429682c3f321.jpg',
  folding_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/60cfcc06afc7cd5ac530d517a0419942f451a3eb2.jpg',
  folding_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/f758bedacdc8b0bb1f2c13a73b179c48400fac4c2.png',
  folding_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/6159c147c3f4f4b45cbd2f7ba32c7c5243c212184.png',
  folding_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/8e8c68def1de4e74e59f4cc42f74c07a2198abfa8.png',
  folding_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/69dbb69eb6ff25cc9a12f5e180cd385520d2e91011.png',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/9e64a5e37438e84829aae7c16187ad7ccac25e077.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/71c692d99c8dccc75d032399242ad8071b0b99b95.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/6eafb03bef665fd270c180047bddb3eee1264d0d13.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/b0c9d75f91361268c777059ca899995249f5f35712.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/39f2d9b38a34fb1a06e54f256b94cfd97b27985f9.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/600a7b9ee1b2a53641fa24232baea78e56b2c95210.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/c924fe0e1595600771cd66319ad9b4a252fd94ae1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/37a5f62b36094664257abb126151a6bc0b0ec04b3.png',
};
export const WIDE_SLOGAN = {
  bp_id: 5063,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/0a2247a96d976167a614913b1a02557f922432c64.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/07653a22b5393303e199edefaab6a0fc2a512a4e10.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/871bd0a03c9a014c8ac9b6973dcd1d352160b5ca9.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/09831936f200aa35bba3bbb1c05a170565aa7d319.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/13cd8be8d9fe843417268717680a399bcd318a095.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/2b1344ad6dd9b9c8775fa0e5689fd0b906a0a99c3.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/c45d713f4bbcad351f21e3fbc9e472f2a6d855239.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/31a0612671cb54b7e5111428bb0f4055f47941ff8.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/9918abf4340fd539a51ce9223783549bd38595ee6.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a58b89d3359441d69507813333984d146c72cd327.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/0b486b796ca157b6c3699cf2ac7bb7ef78e34eae1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a6e00ade5275dbb15a6af3fd7e5c2dd9b3e135212.png',
};
export const STANDARD_SLOGAN = {
  bp_id: 5064,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/04a1fdc73430e18fd688a3ff11bae7e2635fe48d4.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/dcd710cdfc45291f930a560402d8d9b41f83566410.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/f86768352513398ff539d28c9eaa144cf2a1592010.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/1e1a88f6e24a203cd2098e12be3c14c844126ee110.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/0342d682f7f1e484aa1028dcd4b71a453c92c4915.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/911a19a2adb320e005e2187aa7aa6b3692a1cce93.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/42684a2121a67bfa9e9fb51c622fe40822eab9d59.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/ff38b695afc68bc7eb9e8422b7efb33768a66d838.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/bd5d2149a9cd71b20c9cccaacd43c35db03ba5446.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/0e8467eeb47032f2f62e3f403452c5cbccff36607.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/c3e0fda7a1a77d61c3021c59cb46d07288b3fe0c1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/d75cd296eecb1e34391efb101081b35b31f058772.png',
};

export const POSTER_A3 = {
  bp_id: 4856,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/a3ad71d67767e1730c0070505e950ad0e406d0564.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/298540bef60e5fdc907263d45de6630078cd378410.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/0a4f13a40c851d1472c8b56368d05e8edaace3c91.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/45ea851fe52338a89d01225d35b414a47b8f65eb1.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/7042888356b8c2a760fa6d33e89e5a819dbdfe675.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a0b25378ea1d7cbc1bb1abe102db30ca3b13738d3.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/9c2ad66873b4399e9c74c6ff796001511e2a27669.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/267fdccbdbd5ce8e247b9cff35752d0cd3f75dd68.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/f1116a7105476b4689d6fd541086beddbbbcf3966.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/40a93a30cc3d2c729fdd063d316184a7ce82e9477.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/01faf366863f464413d5a77991865f98a615e6811.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/abc54fa28aaf7ee0076d428b0ffd2a9f51d204bb2.png',
};

export const POSTER_A4 = {
  bp_id: 4857,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/3b6a588d753aea6c2b522ef373dfbc13b3d875e84.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/f0904a42e4b1330f94674adea036f38f28d7b7c410.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/0fdef2f82fbcd50b4f084ac6e684cff16efbbc682.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/3a5533915729251903ede5a2a391b60fdaf391832.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/60c34043aa5ea6ad577e7e2f4f850e5c30610ca55.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a3eaba940dd9c0db3447e8edbb7fc67648e805e03.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/6e7a46a99bd7dc20e9b4c4c89731dcb3ff2dd6149.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/bd4ecf68ff2a8dc62b7bf19d0780abcf63a052108.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/8c0cfa47b236e57f8b54523b1cdac81e0b1ae7af6.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/e7c19e83a05e3b67e5e691ff72b395ad2813d6157.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/ab1defaf5fb821737089da19dd3915ba5e83949c1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/94464835d688616ff9d1fee2b1957819f73ba44b2.png',
};

export const SQUARE_POSTER = {
  bp_id: 4858,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/9859ecacf2b62672fa6b98ae0d182bbe4c1c85544.png',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/7ee957e2dd7b37358200f12a06ed1fca0d1ef4675.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/7b369c003956c529e921c501fa4eaf71522116083.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/7c68d3d066ee771554720213f55533ade9d1894710.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/f793b962b9aad812f07e8cea7cf5372fc36c9e1d9.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/70d0b86ae96fbd88c6deaf1a9413dabf4c38564c8.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/eb3d626fbc8657afb1af33d6c4b3cae4f7299fd53.jpg',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/69acd16a8c5222503cfef27626262ae63050a7796.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a55c843c74ceee45a9c2f814db871089756f638c7.png',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/ae32cc635d2926973c7de7ee2f07c4de4f63ec9a3.jpg',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/763a109261b66e1b980d3c423ed70d1669d9f6271.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/051de3f47c78a285fea1611a3ca22c48317fb9472.png',
};

export const SKKU_NOTE = {
  bp_id: 5103,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/4c82250a6f65290834efe4a09deca6c6e77734744.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/e9d18083f54e21aea0e966a83612ab8c9695288510.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/3ab4c0f0d4743603e82bb1ce96c3ae349b9747424.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/57d60c091bb9ebd3862229ebed883dc2fb647b554.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/e4f711b72bfdd650dab3e970bebe17f96f1d046b5.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/5014dcd6e3eef4e63743aee7b76e1fa53fc58f493.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/d4cfedbd15c77739a6d807c827dca45e1d202e3b9.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/aa8ad009feb17942775d999ed51759ca4fcfa1428.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/d7e2aad42189c7b67d7beb8d021904f8a7ff6e306.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/16857602bc3ce40c282b1eff346a1eaf9ea815fa7.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/501e4e5c58a7a28c94888a5f3703a4332f1f131c1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/06f02c818d4679be14725b388c6b091b498c6e122.png',
};

export const SPRING_NOTE_A4 = {
  bp_id: 5106,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/a90c623245d1fd3fd0fe881a9315a148efee34564.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/073ba271940aa9cc2e87befd0d2fbfc6ddf22f6610.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/b48147b35ca728ca71082f368a89e80408cd6b026.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/a35501ce5bca53272a1ab34e96d9153016b053bb6.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/d8de9d352f45967eb1be6a476285969cba071d4f5.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/5fb085dafc6d49dea9a81a663cf24ec2bc39410f3.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/3eb3875ff47927ebd9651d60eedee1522a4a39d59.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/2a8a39a8dca46af6499704c9c9872d743306fff58.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/6ce4df3bbdc16a2747b328958159c940c39912466.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/5788510235a0e8f56b8ef12ff521a29de612dcd97.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/1af96abdb5bdfaa38335db51ae7d0445c22b51a01.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/a844e0c6266320096c933ef2901d97aed163db062.png',
};

export const SPRING_NOTE_A5 = {
  bp_id: 5104,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/d42e1d77da0e739c24d60d600a6b40dd6dd910d64.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/416ea467249dcaadf0804ac8ca619c378734f70c10.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/3ab4c0f0d4743603e82bb1ce96c3ae349b9747424.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/57d60c091bb9ebd3862229ebed883dc2fb647b554.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/75f91b5e0ffbc563b496e403d5231e01858040965.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/187cb13cd818d689bf0a08134437292d88ef10473.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/03bc91dc5696172a0e04af2c698076e24a737d849.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/11cee429ca22b70cfaff7ac64028c5b5233229498.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/9d9820ad21cca3efe0377432a252557d946971bc6.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/d16d1fe3283a6a19a2bae8cf3a91d895ab134c1b7.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/97d1c2415cc503cb87dc437129446835e8e90cb31.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/4dd35c47b95fabf089f7adc9f8192b9d5b0e53182.png',
};

export const SPRING_NOTE_B5 = {
  bp_id: 5105,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/0f554cefb8d5922b655278e25468ef013a689e604.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2022/2/original/4177ce05da1ef1bf66dc95134d92e35026e5822545.jpg',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/7cdf02ea57c83bf5fc2c2f139dbdc60b46fcfc3a5.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/2a1b3421120fa90fbad5dc044ba6440e8f43849f5.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/08f9fe4ab7b06d57b2f9900c136e55cd9a764a1b5.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/ce3565d3ba4fc5fb953940709a8cc3a11f4e00883.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/f01e087de779ccec41db2f5739d274c95e13fad910.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/2dc9a58ba06f14b8e23c30ca77f0b3b4166a27b08.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/bd926e1d9a3155f1ddc68d4f8b16613edaf52aae6.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/bfb1337800ff23711bda193f719fb51cd3eeb1217.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/4cc6441d7325bb5493140e0846f4649993f34c8d1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/45d4c3b7ed1654618e8624c69d3d3f57348850332.png',
};

export const SPRING_NOTE_A6 = {
  bp_id: 5107,
  confirm_m_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/52b4dc4cb43de100f758338364e1af9f75ac1d724.png',
  confirm_pc_url:
    '//s3.marpple.co/files/u_193535/2023/6/original/607ce1f82d689683b8fd002fdc0ecaaa4a002c3d10.png',
  ambiguous_m_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/88f06d9a35b0ec6aee2406828192699dc49e135f7.jpg',
  ambiguous_pc_url:
    '//s3.marpple.co/files/u_193535/2021/11/original/1aa9dec7a9b8003db7ec200b3b79d691fc27b7227.jpg',
  confirm_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/df59fd78d83c995fa2a656ee617154ef1e9c6ea25.png',
  confirm_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/2dc5dfab6aea080e3f827ca3ed349ff3af004dbe3.png',
  confirm_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/b6a80410ed0a94b51dabf24d604f7bf7e7038ada9.png',
  confirm_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/09f91a4b505c627bd9dd6f58c6991c3a97d4c1298.png',
  ambiguous_m_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/7c61fc6130fe084a6d0e7f4e7416d46b2f6594b36.png',
  ambiguous_m_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/1ff3b451c07b377e4c771dd059e9a52bf24ccfaa7.png',
  ambiguous_pc_url_en:
    '//s3.marpple.co/files/u_193535/2023/6/original/1830702ac19ded6e849c51835f5a64c29e5c313f1.png',
  ambiguous_pc_url_jp:
    '//s3.marpple.co/files/u_193535/2023/6/original/b2865381b356bd6ce5d9a7c3fe94ead0b3d0e0702.png',
};
