import { $closest, $el, $findAll, $prependTo, $remove } from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { makeDfProjectionShipItemsHtml } from '../../../Projection/List/F/shipItemTmpl.js';

export function reRenderShippingList({ ct, prj }) {
  /* 기존 ship_item DOM 에서 모두 제거 */
  const shipping_list_el = $closest('.shipping_list', ct);
  go(
    shipping_list_el,
    $findAll('.ship_item'),
    each((el) => $remove(el)),
  );

  /* 신규 ship_item DOM 생성 */
  go(makeDfProjectionShipItemsHtml({ prj }), $el, $prependTo(shipping_list_el));
}
