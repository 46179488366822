import { html } from 'fxjs/es';

export const fn = function ({ key, title, step, unit = 1, min, max, value, unit_name = '' }) {
  return html`
    <div class="title">${title}</div>
    <div class="range_wrapper">
      <input
        type="range"
        name="_range"
        data-key="${key}"
        step="${step}"
        data-unit="${unit}"
        min="${min}"
        max="${max}"
        value="${value}"
      />
    </div>
    <div class="number_wrapper">
      <div class="number_box" data-unit_name="${unit_name}">${value}</div>
    </div>
  `;
};
export const fn2 = function ({
  is_hidden_range_slider,
  key,
  step,
  unit = 1,
  min,
  max,
  value,
  unit_name = '',
}) {
  return html`
    <div class="range_wrapper" data-is_hidden_range_slider="${is_hidden_range_slider}">
      <input
        type="range"
        name="_range"
        data-key="${key}"
        step="${step}"
        data-unit="${unit}"
        min="${min}"
        max="${max}"
        value="${value}"
      />
    </div>
    <div
      class="number_wrapper"
      data-key="${key}"
      data-is_hidden_range_slider="${is_hidden_range_slider}"
      data-up_down="true"
    >
      <div class="number_box" data-unit_name="${unit_name}">${value}</div>
      <div class="up_down">
        <button type="button" class="up"></button>
        <button type="button" class="down"></button>
      </div>
    </div>
  `;
};
