import { html } from 'fxjs/es';

export const makeListItemHtml = ({
  label,
  is_locked = false,
  is_selected = false,
  is_visible = true,
}) => html`
  <div
    class="list_item"
    data-is_locked="${!!is_locked}"
    data-is_selected="${!!is_selected}"
    data-is_visible="${!!is_visible}"
  >
    <div class="image_container"></div>
    <div class="label_container">
      <span class="layer_label">${label}</span>
    </div>
    <div class="visible_container">
      <button type="button" class="visible_icon"></button>
    </div>
    <div class="lock_container">
      <button type="button" class="lock_icon"></button>
    </div>
  </div>
`;
