import { defnDfUserVerifyLoginTab, defnDfUserVerifyLoginPwTab } from './tab.js';

$.frame.defn_page({
  page_name: 'df.verify_login_page',
  tabs: [defnDfUserVerifyLoginTab()],
});

$.frame.defn_page({
  page_name: 'df.verify_login_pw',
  tabs: [defnDfUserVerifyLoginPwTab()],
});
