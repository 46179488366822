import { makeOutboundTabHtml } from '../Tmpl/tmpl.tabs.js';
import { base_tab } from './base_tab.js';

const mui_ref_path = '/modules/Df/Lgl/ShippingDetail/S/Mui/tab_outbound.js';
export const tab_outbound = base_tab({
  mui_ref_path,
  title: '출고 확인',
  tag: 'outbound',
  template: makeOutboundTabHtml,
});
