import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { setSizeDisplayValue } from './size.js';
import { $hide } from 'fxdom/es';

export const handleRightCancelButtonClick = () => () => MuiF.popPage().catch(console.error);

export const handleRightDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    if (tab_el.__mp_postProcess == null) {
      return;
    }
    const postProcess = tab_el.__mp_postProcess;

    const result1 = acryl_stand.getGroundMidPoint();
    if (!result1.ok) {
      console.error(result1.value);
      // @TODO KHL i18n
      $.alert(`ERROR`);
      return;
    }
    const { x: stand_leg_x, y: stand_leg_y } = result1.value;

    const result2 = acryl_stand.getUnitedPathdata({});
    if (!result2.ok) {
      console.error(result2.value);
      // @TODO KHL i18n
      $.alert(`ERROR`);
      return;
    }
    const path_data = result2.value;
    const { success, value } = await postProcess({
      cutting_line_path_data: path_data,
      stand_leg_position: { x: stand_leg_x, y: stand_leg_y },
    });

    if (!success && value == null) {
      return;
    }
    if (!success) {
      await $.alert(value);
      return;
    }

    return MuiF.closeFrame(value);
  };

export const handleRightMinusButtonClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    const new_width = tab_el.__mp_stand_leg_ground_width - tab_el.__mp_stand_leg_ground_size_delta;
    if (new_width < tab_el.__mp_stand_leg_ground_min_width) {
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::min_stand_leg_width_error',
        ),
      );
      return;
    }

    const result1 = acryl_stand.setGroundWidth({ new_width });
    if (!result1.ok) {
      console.error(result1.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::min_stand_leg_width_error',
        ),
      );
      return;
    }
    const result2 = acryl_stand.getStandPathdata({});
    if (!result2.ok) {
      console.error(result2.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::min_stand_leg_width_error',
        ),
      );
      return;
    }
    const path_data = result2.value;

    tab_el.__mp_stand_leg_ground_width = new_width;
    editor.setStandLegPath({ path_data });
    setSizeDisplayValue({ tab_el, size: new_width });
  };

export const handleRightPlusButtonClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    const new_width = tab_el.__mp_stand_leg_ground_width + tab_el.__mp_stand_leg_ground_size_delta;
    if (new_width > tab_el.__mp_stand_leg_ground_max_width) {
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::max_stand_leg_width_error',
        ),
      );
      return;
    }

    const result1 = acryl_stand.setGroundWidth({ new_width });
    if (!result1.ok) {
      console.error(result1.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::max_stand_leg_width_error',
        ),
      );
      return;
    }
    const result2 = acryl_stand.getStandPathdata({});
    if (!result2.ok) {
      console.error(result2.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::max_stand_leg_width_error',
        ),
      );
      return;
    }
    const path_data = result2.value;

    tab_el.__mp_stand_leg_ground_width = new_width;
    editor.setStandLegPath({ path_data });
    setSizeDisplayValue({ tab_el, size: new_width });
  };

export const handleRightLeftButtonClick =
  ({ tab_el }) =>
  () => {
    tab_el.__mp_is_stand_leg_moving = false;

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    const result1 = acryl_stand.getGroundMidPoint();
    if (!result1.ok) {
      console.error(result1.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::cutting_line_calculation_error',
        ),
      );
      return;
    }
    const x = result1.value.x - tab_el.__mp_stand_leg_ground_position_delta;

    const result2 = acryl_stand.getStandPathdata({ x });
    if (!result2.ok) {
      console.error(result2.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::cutting_line_calculation_error',
        ),
      );
      return;
    }
    const path_data = result2.value;
    editor.setStandLegPath({ path_data });

    tab_el.querySelectorAll(`.foreground_container .reposition_guide_container`).forEach((el) => {
      $hide(el);
    });
  };

export const handleRightRightButtonClick =
  ({ tab_el }) =>
  () => {
    tab_el.__mp_is_stand_leg_moving = false;

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    const result1 = acryl_stand.getGroundMidPoint();
    if (!result1.ok) {
      console.error(result1.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::cutting_line_calculation_error',
        ),
      );
      return;
    }
    const x = result1.value.x + tab_el.__mp_stand_leg_ground_position_delta;

    const result2 = acryl_stand.getStandPathdata({ x });
    if (!result2.ok) {
      console.error(result2.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::cutting_line_calculation_error',
        ),
      );
      return;
    }
    const path_data = result2.value;
    editor.setStandLegPath({ path_data });

    tab_el.querySelectorAll(`.foreground_container .reposition_guide_container`).forEach((el) => {
      $hide(el);
    });
  };

export const handleEditorContainerMouseDown =
  ({ tab_el }) =>
  ({ target: target_el, clientX: client_x, clientY: client_y }) => {
    if (tab_el.__mp_is_stand_leg_moving) {
      return;
    }

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    if (target_el instanceof Node && editor.getIsInStandLegEl(target_el)) {
      const { x } = editor.convertCoordsToInside({ x: client_x, y: client_y });
      const result = acryl_stand.getStandPathdata({ x });
      if (!result.ok) {
        console.error(result.value);
        $.alert(
          T(
            'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::cutting_line_calculation_error',
          ),
        );
        return;
      }
      const path_data = result.value;

      tab_el.__mp_is_stand_leg_moving = true;
      editor.setStandLegPath({ path_data });

      tab_el.querySelectorAll(`.foreground_container .reposition_guide_container`).forEach((el) => {
        $hide(el);
      });
    }
  };

export const handleTabElMouseMove =
  ({ tab_el }) =>
  (event) => {
    if (!tab_el.__mp_is_stand_leg_moving) {
      return;
    }

    event.preventDefault();

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    const { clientX: client_x, clientY: client_y } = event;
    const { x } = editor.convertCoordsToInside({ x: client_x, y: client_y });
    const result = acryl_stand.getStandPathdata({ x });
    if (!result.ok) {
      console.error(result.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::CreatorPC::error_message::cutting_line_calculation_error',
        ),
      );
      return;
    }
    const path_data = result.value;
    editor.setStandLegPath({ path_data });
  };

export const handleTabElMouseUp =
  ({ tab_el }) =>
  (event) =>
    handleTabElMouseUpOrMouseLeave({ tab_el, event });

export const handleTabElMouseLeave =
  ({ tab_el }) =>
  (event) =>
    handleTabElMouseUpOrMouseLeave({ tab_el, event });

function handleTabElMouseUpOrMouseLeave({ tab_el, event }) {
  if (!tab_el.__mp_is_stand_leg_moving) {
    return;
  }
  event.preventDefault();
  tab_el.__mp_is_stand_leg_moving = false;
}
