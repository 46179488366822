export const BP_VIEW_SIZE = {
  6501: {
    mobile_size_percent: 100,
    mobile_detail_scale: 1,
  },
  6503: {
    mobile_size_percent: 100,
    mobile_detail_scale: 1,
  },
  6504: {
    mobile_size_percent: 100,
    mobile_detail_scale: 1,
  },
  6368: {
    mobile_size_percent: 70,
    mobile_detail_scale: 1.3,
  },
  6369: {
    mobile_size_percent: 70,
    mobile_detail_scale: 1.3,
  },
  6370: {
    mobile_size_percent: 70,
    mobile_detail_scale: 1.3,
  },
  6371: {
    mobile_size_percent: 70,
    mobile_detail_scale: 1.3,
  },
  // 7106: {
  //   mobile_detail_scale: 1,
  // },
};
