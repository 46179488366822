import { VectorEditorStickerFreePCF } from '../Function/module/VectorEditorStickerFreePCF.js';
import { equals2 } from 'fxjs/es';

export const handleFreeTemplateCuttingLineClick =
  ({ tab_el }) =>
  () => {
    if (!equals2(tab_el.__mp_free_template_svg_el?.dataset?.is_show)('true')) {
      return;
    }
    return VectorEditorStickerFreePCF.addNewSticker({ tab_el });
  };
