import { html } from 'fxjs/es';

export const en_privacy_220307 = () => {
  return html`
    <div class="terms_body">
      <div class="title">
        <h1 class="text_align_center">LINE FRIENDS Creator Privacy Policy</h1>
      </div>
      <div class="body">
        <div class="text_body">
          <div class="paragraph">
            <p class="text_align_right">March 4, 2022</p>
            <p>
              This Privacy Policy of IPX Corporation (“IPX”, “creator”, “we”, “us”, or “our”) applies to the
              operations of LINE FRIENDS Creator that we offer website (the "Services"). It provides the
              policies and procedures for collecting, using, and disclosing the information of each visitor to
              or user of the Services ("you", "your", or the “User”). In this Privacy Policy (the “Policy”),
              which describes what information we collect and what it's used for. We take protecting your
              privacy seriously, and we want you to understand exactly how and why we use your information.
            </p>
            <p>
              Please read this Privacy Policy carefully. Your use of and/or registration will constitute your
              agreement to our policy. If you cannot agree to the terms and conditions set forth below, please
              do not use our Services.
            </p>
          </div>
          <div class="paragraph">
            <h2>What Information We Collect</h2>
            <ul>
              <li>
                <h3>Information You Give Us</h3>
                <p>
                  We collect personal information from you and any devices you use when you: use our Services,
                  register for an account with us, update or add information to your account, or when you
                  purchase our products.
                </p>
                <ul>
                  <li>
                    - when you register, we may collect your name, email address, you can also register via
                    Facebook, at that time we may collect your name and profile image, email address.
                  </li>
                  <li>
                    - purchase and delivery information you provide such as receiver name, e-mail address,
                    address, phone number.
                  </li>
                  <li>
                    - In some instances, when you use our Services, you may provide birthday, phone number.
                  </li>
                </ul>
              </li>
              <li>
                <h3>Information We Collect Automatically</h3>
                <p>
                  We may automatically collect information about your use of the Services such as: access
                  time; device ID or other unique identifier; IP address; mac address; language information;
                  device name and model; locale and timezone; geo-location data; carrier provider; operating
                  system information; your activities within the Services; and the length of time that you are
                  logged in. And we may automatically collect information about display name; profile. We may
                  combine this information with other information that we have collected about you.
                </p>
              </li>
              <li>
                <h3>How We Use Information We Collect</h3>
                <p>
                  In general, we use the information we collect from and about you to provide and operate the
                  Services, respond to your requests, and to provide customer service and technical support.
                  We may also use information:
                </p>
                <ul>
                  <li>
                    - To tailor the information that we may send or display to you, send you push
                    notifications and alerts, order again, recommend an item, and to otherwise personalize
                    your experiences while using our Services.
                  </li>
                  <li>
                    - To deliver targeted marketing, targeted advertising, service updates, and promotional
                    offers based on your communication preferences.
                  </li>
                  <li>
                    - To personalize, measure, and improve our advertising based on your advertising
                    customization preferences.
                  </li>
                  <li>- To send you notices, order confirmation and communication.</li>
                  <li>
                    - To verify your identity and prevent spam or other unauthorized or illegal activity.
                  </li>
                  <li>
                    - To better understand how users access and use our Services, both on an aggregated and
                    individualized basis, in order to improve our Services and respond to user desires and
                    preferences, and for other research and analytical purposes.
                  </li>
                  <li>
                    - To prevent, detect, mitigate, and investigate fraud, security breaches or other
                    potentially prohibited or illegal activities.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <hr />
          <div class="paragraph">
            <h2>How We Share Information</h2>
            <ul>
              <li>
                <h3>Service Providers</h3>
                <p>
                  We may disclose the information we collect to third party vendors, service providers,
                  contractors or agents who perform functions on our behalf.
                </p>
              </li>
              <li>
                <h3>Business Transfers</h3>
                <p>
                  We may transfer the information we have collected to another company in connection with, or
                  during negotiations of, any merger, sale of company assets, financing, or acquisition of all
                  or a portion of our business.
                </p>
              </li>
              <li>
                <h3>Third Parties and Partners</h3>
                <p>
                  We may share the information with our third party partners, including advertisers and
                  researchers. For example, if you post content that mentions one of our advertisers, we may
                  share that content with them. Or, we may work with a researcher to analyze the content on a
                  particular feed.
                </p>
              </li>
              <li>
                <h3>In Response to Legal Process</h3>
                <p>
                  We also may disclose the information in order to comply with the law, a judicial proceeding,
                  court order, subpoena, or other legal process. For example, we may share your IP address or
                  other identifier, your location information, or any other information we have collected
                  about you and your device.
                </p>
              </li>
              <li>
                <h3>To Protect Us and Others</h3>
                <p>
                  We also may disclose information if we believe it is necessary to investigate, prevent, or
                  take action regarding illegal activities, suspected fraud, situations involving potential
                  threats to the safety of any person, violations of our Terms of Service or this Policy, or
                  as evidence in litigation in which LINE FRIENDS Creator is involved.
                </p>
              </li>
              <li>
                <h3>To Aggregate and De-Identified Information</h3>
                <p>
                  We may also share aggregate or de-identified information about users with third parties for
                  marketing, advertising, research or similar purposes.
                </p>
              </li>
            </ul>
          </div>
          <hr />
          <div class="paragraph">
            <h2>How to Delete Information</h2>
            <ul>
              <li>
                <p>
                  Right to Know and Access. You may submit a verifiable request for information regarding the:
                  (1) categories of Personal Information collected or disclosed by us; (2) purposes for which
                  categories of Personal Information are collected by us; (3) categories of sources from which
                  we collect Personal Information; and (4) specific pieces of Personal Information we have
                  collected about you during the past twelve months.
                </p>
              </li>
              <li>
                <p>
                  Right to Delete. Subject to certain exceptions, you have the option to delete Personal
                  Information about you that we have collected from you.
                </p>
              </li>
              <li>
                <p>
                  Right to Opt-out. We do not sell your personal information, so we do not offer an opt out.
                </p>
              </li>
            </ul>
          </div>
          <hr />
          <div class="paragraph">
            <h2>Cookies</h2>
            <p>
              A cookie is a small text file that is stored on your computer when visiting a website. This text
              file stores information that can be read by the website when visiting it again at a later date.
              Some of these cookies are necessary because otherwise the site is unable to function properly.
              Other cookies are convenient for the visitor: they remember your username in a secure way as
              well as your language preferences, for example. Cookies mean that you do not need to fill out
              the same information each time you visit a website.
            </p>
            <h4>We use cookies:</h4>
            <ul>
              <li>
                - To help us maintain your session when you use Services and/or any additional services.
              </li>
              <li>- To enhance security of our systems.</li>
              <li>- To store your preferences and provide customized services to you.</li>
              <li>- To help us understand how people use Services and to improve them.</li>
            </ul>
            <p>
              Most Internet browsers allow you to control whether or not to accept cookies. If, however, you
              deactivate cookies or limit the ability to set cookies, you may not be able to obtain the full
              benefit of the Website or access all of its functions, which may limit your overall user
              experience.
            </p>
          </div>
          <hr />
          <div class="paragraph">
            <h2>Retention of Personal Information</h2>
            <p>
              We will retain your personal information for a certain period of time, if there is a need to
              retain it pursuant to the company’s internal policy or the relevant laws and regulations.
            </p>
          </div>
          <hr />
          <div class="paragraph">
            <h2>Push Notifications/Alerts</h2>
            <p>
              We may send push notifications or alerts via email or SMS. You can deactivate these messages at
              any time by changing the menu of your personal information edit.
            </p>
          </div>
          <hr />
          <div class="paragraph">
            <h2>Third Party Analytics</h2>
            <p>
              We may use automated devices and applications, included those offered by third parties to
              evaluate usage of our Services. We also may use other analytic means to evaluate our Services.
              We use these tools to help us improve our Services, performance and user experiences. The
              information collected may include unique device identifiers, device manufacturer and operating
              system, IP address, mac address, location, browser type, session start/stop time and user
              activity.
            </p>
          </div>
          <hr />
          <div class="paragraph">
            <h2>Third-Party Links</h2>
            <p>
              Our Services may contain links to third-party websites. Any access to and use of such linked
              websites is not governed by this Policy, but instead is governed by the privacy policies of
              those third party websites. We are not responsible for any privacy issues arising from your
              accessing to and using of those third party websites.
            </p>
          </div>
          <hr />
          <div class="paragraph">
            <h2>Contact Us</h2>
            <p>
              If you have questions about the privacy aspects of our Services or would like to make a
              complaint, please contact us. (creatorcare@linefriends.com)
            </p>
          </div>
          <hr />
          <div class="paragraph">
            <h2>Changes to this Policy</h2>
            <p>
              This Policy is current as of the Effective Date set forth above. We may change this Policy from
              time to time, so please be sure to check back periodically.
            </p>
          </div>
          <div>
            <p><a href="/en/privacy_190919">- Previous Privacy Policy</a></p>
          </div>
        </div>
      </div>
      <div class="option">
        <button class="done">Done</button>
      </div>
    </div>
  `;
};

export const kr_full_privacy_220307 = () => html`
  <div class="terms_body">
    <div class="title">
      <h1>LINE FRIENDS Creator 개인정보처리방침</h1>
      <p>
        아이피엑스 주식회사(이하 “회사”)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법,
        통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호
        규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고
        있습니다.
      </p>
    </div>
    <div class="body">
      <div class="text_body">
        <div class="paragraph">
          <h2 class="header">1. 수집하는 개인정보의 항목 및 수집방법</h2>
          <ol>
            <li>
              <h3>가. 수집하는 개인정보의 항목</h3>
              <p>
                이용자는 회원가입을 하지 않아도 상품 검색 및 상품 설명 등의 서비스는 이용이 가능합니다.
                이용자가 상품 구매 및 결제를 위해 회원가입을 할 경우, 원활한 서비스 제공 및 계약 이행을 위해
                최소한의 개인정보를 수집합니다.
              </p>
              <ol>
                <li>
                  <h4>
                    첫째, 회사는 회원가입 시점에, 간편로그인 계정 연동 시점에 이용자로부터 아래의 개인정보를
                    수집합니다.
                  </h4>
                  <ul>
                    <li>– 이메일 회원가입 시: (필수)이름, 이메일주소</li>
                    <li>– 페이스북 계정 연동 : (필수)사용자 이름, 프로필 사진, (선택)이메일주소</li>
                  </ul>
                </li>
                <li>
                  <h4>둘째, 상품 구매 과정에서 아래와 같은 주문자 정보 및 배송지 정보가 수집됩니다.(필수)</h4>
                  <ul>
                    <li>– 구매자 정보(이름, 연락처, 이메일), 수신자 정보(이름, 연락처, 주소)</li>
                  </ul>
                </li>
                <li>
                  <h4>
                    셋째, 고객 문의 처리와 이벤트 경품 등을 목적으로 해당 서비스 이용자에 한해 아래와 같은
                    정보들을 수집할 수 있습니다. (아래 항목 해당 시)
                  </h4>
                  <ul>
                    <li>- 고객 문의 처리 이름, 연락처(이메일 주소, 전화번호), 배송정보, 환불계좌번호</li>
                    <li>- 배송 관련 문의 시: 배송정보</li>
                  </ul>
                </li>
                <li>
                  <h4>넷째, 마이페이지에서 개인정보 변경 시 추가적인 정보를 수집합니다. (선택)</h4>
                  <ul>
                    <li>- 생년월일, 휴대폰번호</li>
                    <li>- 문자 또는 이메일 수신 등의 여부</li>
                  </ul>
                </li>
                <li>
                  <h4>
                    다섯째, 서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이 자동으로 생성되어
                    수집될 수 있습니다. (거부 가능, 8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
                    참고)
                  </h4>
                  <ul>
                    <li>
                      - 최근접속일, 접속 IP정보, 쿠키, 구매로그, 이벤트로그IP Address, 쿠키, 방문 일시, 서비스
                      이용 기록, 불량 이용 기록
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <h3>나. 개인정보 수집방법</h3>
              <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
              <ul>
                <li>
                  – 회원가입 및 서비스 이용과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를
                  입력하는 경우
                </li>
                <li>– 계정 연동 시 이용자 확인 및 서비스 운영을 위해 제공 받는 경우</li>
                <li>- 홈페이지, 서면양식, 팩스, 전화, 상담 게시판, 이메일 주소, 이벤트 응모, 배송요청</li>
                <li>- 자동 생성정보 수집 툴을 통한 수집</li>
              </ul>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h2 class="header">2. 개인정보의 수집 및 이용목적</h2>
          <ol>
            <li>
              <h3>가. 서비스 제공에 관한 계약 이행</h3>
              <p>상품정보 제공, 구매 및 물품배송 등 서비스 운영을 위하여 개인정보를 이용합니다.</p>
            </li>
            <li>
              <h3>나. 회원관리</h3>
              <p>
                회원제 서비스 이용, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입의사 확인, 가입
                및 가입횟수 제한, 분쟁 조정을 위한 기록 보존, 불만처리 등 민원처리, 고지사항 전달 등의
                회원관리를 위하여 개인정보를 이용합니다.
              </p>
            </li>
            <li>
              <h3>다. 신규 서비스 개발 및 마케팅•광고에의 활용</h3>
              <p>
                신규 서비스 개발 및 맞춤서비스 제공, 광고성 SMS 및 이메일 발송, 통계학적 특성에 따른 서비스
                제공 및 광고 게제, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공,
                접속빈도 파악, 회원의 서비스 이용에 대한 통계 등에 개인정보를 이용합니다.
              </p>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h2>3. 개인정보의 공유 및 제공</h2>
          <p>
            회사는 이용자들의 개인정보를 "2. 개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며,
            이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에
            공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
          </p>
          <ul>
            <li>- 이용자들이 사전에 공개에 동의한 경우</li>
            <li>
              - 법령의 규정에 의거하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는
              경우
            </li>
          </ul>
        </div>
        <div class="paragraph">
          <h2 class="header">4. 개인정보의 취급위탁</h2>
          <p>
            회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시
            개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
          </p>
          <p>회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.</p>
          <table class="tb1">
            <thead>
              <tr>
                <th>수탁업체</th>
                <th>수탁업무 내용</th>
                <th>개인정보의 이용 및 보유기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>마플코퍼레이션</td>
                <td>플랫폼 개발 및 서비스 운영, 배송업무, CS, 문자발송</td>
                <td>회원탈퇴시 혹은 위탁계약 종료시까지</td>
              </tr>
              <tr>
                <td>케이지이니시스㈜</td>
                <td>결제처리(신용카드, 계좌이체, 기타 결제수단, 환불계좌 인증)</td>
                <td>회원탈퇴시 혹은 위탁계약 종료시까지</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paragraph">
          <h2 class="header">5. 개인정보의 보유 및 이용기간</h2>
          <p>
            이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. <br />
            단, 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가
            있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. <br />
            이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
          </p>
          <ul>
            <li>
              <h4>- 계약 또는 청약철회 등에 관한 기록</h4>
              <ul>
                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                <li>보존 기간 : 5년</li>
              </ul>
            </li>
            <li>
              <h4>- 대금결제 및 재화 등의 공급에 관한 기록</h4>
              <ul>
                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                <li>보존 기간 : 5년</li>
              </ul>
            </li>
            <li>
              <h4>- 소비자의 불만 또는 분쟁처리에 관한 기록</h4>
              <ul>
                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                <li>보존 기간 : 3년</li>
              </ul>
            </li>
            <li>
              <h4>– 웹사이트 방문기록</h4>
              <ul>
                <li>보존 이유 : 통신비밀보호법</li>
                <li>보존 기간 : 3개월</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="paragraph">
          <h2 class="header">6. 개인정보 파기절차 및 방법</h2>
          <p>이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.</p>
          <p>회사의 개인정보 파기절차 및 방법은 다음과 같습니다.</p>
          <ol>
            <li>
              <h3>가. 파기절차</h3>
              <ul>
                <li>
                  - 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우
                  별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간
                  참조) 일정 기간 저장된 후 파기됩니다.
                </li>
                <li>
                  - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지
                  않습니다.
                </li>
              </ul>
            </li>
            <li>
              <h3>나. 파기방법</h3>
              <ul>
                <li>- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
                <li>
                  - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
                  삭제합니다.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h2 class="header">7. 이용자 및 법정대리인의 권리와 그 행사방법</h2>
          <ul>
            <li>
              - 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를
              조회하거나 삭제를 요청할 수 있습니다.
            </li>
            <li>
              - 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해
              개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는
              정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
            </li>
            <li>
              - 회사는 이용자 혹은 법정 대리인의 요청에 의해 삭제된 개인정보를 "5. 개인정보의 보유 및
              이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
              있습니다.
            </li>
          </ul>
        </div>
        <div class="paragraph">
          <h2 class="header">8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h2>
          <p>
            회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는
            '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는 서버가 이용자의 브라우저에게 보내는 아주
            작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.
          </p>
          <ol>
            <li>
              <h3>가. 쿠키의 사용 목적</h3>
              <ul>
                <li>
                  - 이용자들이 방문한 LINE FRIENDS STORE의 각 웹 페이지에 대한 방문 및 이용형태, 회원과
                  비회원의 접속 빈도 또는 머문 시간, 이용자 규모 등을 파악하여 이용자에게 최적화된 서비스를
                  제공하기 위하여 사용합니다.
                </li>
              </ul>
            </li>
            <li>
              <h3>나. 쿠키의 설치/운영 및 거부</h3>
              <ul>
                <li>
                  - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을
                  설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
                  쿠키의 저장을 거부할 수도 있습니다.
                </li>
                <li>
                  - 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 서비스 이용 시에 어려움이 있을 수
                  있습니다.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h2 class="header">9. 개인정보의 안전성 확보조치</h2>
          <p>회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
          <ol>
            <li>
              <h3>가. 관리적 조치</h3>
              <p>내부관리계획 수립 및 시행, 정기적 임직원 교육 등</p>
            </li>
            <li>
              <h3>나. 기술적 조치</h3>
              <p>
                개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화,
                보안프로그램 설치
              </p>
            </li>
            <li>
              <h3>다. 물리적 조치</h3>
              <p>데이터센터, 자료보관실 등의 접근통제</p>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h2 class="header">10. 개인정보보호 담당부서 및 연락처</h2>
          <p>
            귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호
            담당부서로 신고하실 수 있습니다.
            <br />회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
          </p>
          <table class="tb2">
            <thead>
              <tr>
                <th>개인정보보호책임자</th>
                <th>개인정보보호담당자</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>이름: 박현세</td>
                <td>이름: 변은진</td>
              </tr>
              <tr>
                <td>소속: Security</td>
                <td>소속: DTC Sales</td>
              </tr>
              <tr>
                <td>전화: 1600-8113</td>
                <td>전화: 1600-8113</td>
              </tr>
              <tr>
                <td>메일: dl_lfsec@linefriends.com</td>
                <td>메일: dl_lfsec@linefriends.com</td>
              </tr>
            </tbody>
          </table>
          <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
          <ul>
            <li>• 개인정보침해신고센터 http://privacy.kisa.or.kr 국번없이 118</li>
            <li>• 대검찰청 사이버범죄수사단 http://www.spo.go.kr 국번없이 1301</li>
            <li>• 경찰청 사이버테러대응센터 https://ecrm.policy.go.kr 국번없이 182</li>
          </ul>
        </div>
        <div class="paragraph">
          <h2 class="header">11. 적용범위</h2>
          <p>
            LINE FRIENDS Creator에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본
            “개인정보처리방침“이 적용되지 않음을 알려 드립니다.
          </p>
        </div>
        <div class="paragraph">
          <h2 class="header">12. 고지의무</h2>
          <p>
            현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의
            ‘공지사항‘을 통해 고지할 것입니다.<br />
            다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소
            30일 전에 고지합니다
          </p>
        </div>
        <div class="paragraph">
          <p>- 시행일자 : 2022년 3월 4일</p>
        </div>
        <div class="paragraph">
          <p><a href="/kr/privacy_190919">- 이전 개인정보처리방침 보기</a></p>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;

// 220307 최초 버전 2022 07 26에 변경 됨 이전 내용 기록용 변수
export const kr_full_privacy_220307_old = () => html`
  <div class="terms_body">
    <div class="title">
      <h1>LINE FRIENDS Creator 개인정보처리방침</h1>
      <p>
        아이피엑스 주식회사(이하 “회사”)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법,
        통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호
        규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고
        있습니다.
      </p>
    </div>
    <div class="body">
      <div class="text_body">
        <div class="paragraph">
          <h2 class="header">1. 수집하는 개인정보의 항목 및 수집방법</h2>
          <ol>
            <li>
              <h3>가. 수집하는 개인정보의 항목</h3>
              <p>
                이용자는 회원가입을 하지 않아도 상품 검색 및 상품 설명 등의 서비스는 이용이 가능합니다.
                이용자가 상품 구매 및 결제를 위해 회원가입을 할 경우, 원활한 서비스 제공 및 계약 이행을 위해
                최소한의 개인정보를 수집합니다.
              </p>
              <ol>
                <li>
                  <h4>
                    첫째, 회사는 회원가입 시점에, 간편로그인 계정 연동 시점에 이용자로부터 아래의 개인정보를
                    수집합니다.
                  </h4>
                  <ul>
                    <li>– 이메일 회원가입 시: (필수)이름, 이메일주소</li>
                    <li>– 페이스북 계정 연동 : (필수)사용자 이름, 프로필 사진, (선택)이메일주소</li>
                  </ul>
                </li>
                <li>
                  <h4>둘째, 상품 구매 과정에서 아래와 같은 주문자 정보 및 배송지 정보가 수집됩니다.(필수)</h4>
                  <ul>
                    <li>– 구매자 정보(이름, 연락처, 이메일), 수신자 정보(이름, 연락처, 주소)</li>
                  </ul>
                </li>
                <li>
                  <h4>
                    셋째, 고객 문의 처리와 이벤트 경품 등을 목적으로 해당 서비스 이용자에 한해 아래와 같은
                    정보들을 수집할 수 있습니다. (아래 항목 해당 시)
                  </h4>
                  <ul>
                    <li>- 고객 문의 처리 이름, 연락처(이메일 주소, 전화번호), 배송정보, 환불계좌번호</li>
                    <li>- 배송 관련 문의 시: 배송정보</li>
                  </ul>
                </li>
                <li>
                  <h4>넷째, 마이페이지에서 개인정보 변경 시 추가적인 정보를 수집합니다. (선택)</h4>
                  <ul>
                    <li>- 생년월일, 휴대폰번호</li>
                    <li>- 문자 또는 이메일 수신 등의 여부</li>
                  </ul>
                </li>
                <li>
                  <h4>
                    다섯째, 서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이 자동으로 생성되어
                    수집될 수 있습니다. (거부 가능, 8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
                    참고)
                  </h4>
                  <ul>
                    <li>
                      - 최근접속일, 접속 IP정보, 쿠키, 구매로그, 이벤트로그IP Address, 쿠키, 방문 일시, 서비스
                      이용 기록, 불량 이용 기록
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <h3>나. 개인정보 수집방법</h3>
              <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
              <ul>
                <li>
                  – 회원가입 및 서비스 이용과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를
                  입력하는 경우
                </li>
                <li>– 계정 연동 시 이용자 확인 및 서비스 운영을 위해 제공 받는 경우</li>
                <li>- 홈페이지, 서면양식, 팩스, 전화, 상담 게시판, 이메일 주소, 이벤트 응모, 배송요청</li>
                <li>- 자동 생성정보 수집 툴을 통한 수집</li>
              </ul>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h2 class="header">2. 개인정보의 수집 및 이용목적</h2>
          <ol>
            <li>
              <h3>가. 서비스 제공에 관한 계약 이행</h3>
              <p>상품정보 제공, 구매 및 물품배송 등 서비스 운영을 위하여 개인정보를 이용합니다.</p>
            </li>
            <li>
              <h3>나. 회원관리</h3>
              <p>
                회원제 서비스 이용, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입의사 확인, 가입
                및 가입횟수 제한, 분쟁 조정을 위한 기록 보존, 불만처리 등 민원처리, 고지사항 전달 등의
                회원관리를 위하여 개인정보를 이용합니다.
              </p>
            </li>
            <li>
              <h3>다. 신규 서비스 개발 및 마케팅•광고에의 활용</h3>
              <p>
                신규 서비스 개발 및 맞춤서비스 제공, 광고성 SMS 및 이메일 발송, 통계학적 특성에 따른 서비스
                제공 및 광고 게제, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공,
                접속빈도 파악, 회원의 서비스 이용에 대한 통계 등에 개인정보를 이용합니다.
              </p>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h2>3. 개인정보의 공유 및 제공</h2>
          <p>
            회사는 이용자들의 개인정보를 "2. 개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며,
            이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에
            공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
          </p>
          <ul>
            <li>- 이용자들이 사전에 공개에 동의한 경우</li>
            <li>
              - 법령의 규정에 의거하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는
              경우
            </li>
          </ul>
        </div>
        <div class="paragraph">
          <h2 class="header">4. 개인정보의 취급위탁</h2>
          <p>
            회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시
            개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
          </p>
          <p>회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.</p>
          <table class="tb1">
            <thead>
              <tr>
                <th>수탁업체</th>
                <th>수탁업무 내용</th>
                <th>개인정보의 이용 및 보유기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>마플코퍼레이션</td>
                <td>플랫폼 개발 및 서비스 운영, 배송업무, CS, 문자발송</td>
                <td>회원탈퇴시 혹은 위탁계약 종료시까지</td>
              </tr>
              <tr>
                <td>케이지이니시스㈜</td>
                <td>결제처리(신용카드, 계좌이체, 기타 결제수단, 환불계좌 인증)</td>
                <td>회원탈퇴시 혹은 위탁계약 종료시까지</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paragraph">
          <h2 class="header">5. 개인정보의 보유 및 이용기간</h2>
          <p>
            이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. <br />
            단, 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가
            있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. <br />
            이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
          </p>
          <ul>
            <li>
              <h4>- 계약 또는 청약철회 등에 관한 기록</h4>
              <ul>
                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                <li>보존 기간 : 5년</li>
              </ul>
            </li>
            <li>
              <h4>- 대금결제 및 재화 등의 공급에 관한 기록</h4>
              <ul>
                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                <li>보존 기간 : 5년</li>
              </ul>
            </li>
            <li>
              <h4>- 소비자의 불만 또는 분쟁처리에 관한 기록</h4>
              <ul>
                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                <li>보존 기간 : 3년</li>
              </ul>
            </li>
            <li>
              <h4>– 웹사이트 방문기록</h4>
              <ul>
                <li>보존 이유 : 통신비밀보호법</li>
                <li>보존 기간 : 3개월</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="paragraph">
          <h2 class="header">6. 개인정보 파기절차 및 방법</h2>
          <p>이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.</p>
          <p>회사의 개인정보 파기절차 및 방법은 다음과 같습니다.</p>
          <ol>
            <li>
              <h3>가. 파기절차</h3>
              <ul>
                <li>
                  - 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우
                  별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간
                  참조) 일정 기간 저장된 후 파기됩니다.
                </li>
                <li>
                  - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지
                  않습니다.
                </li>
              </ul>
            </li>
            <li>
              <h3>나. 파기방법</h3>
              <ul>
                <li>- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
                <li>
                  - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
                  삭제합니다.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h2 class="header">7. 이용자 및 법정대리인의 권리와 그 행사방법</h2>
          <ul>
            <li>
              - 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를
              조회하거나 삭제를 요청할 수 있습니다.
            </li>
            <li>
              - 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해
              개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는
              정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
            </li>
            <li>
              - 회사는 이용자 혹은 법정 대리인의 요청에 의해 삭제된 개인정보를 "5. 개인정보의 보유 및
              이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
              있습니다.
            </li>
          </ul>
        </div>
        <div class="paragraph">
          <h2 class="header">8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h2>
          <p>
            회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는
            '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는 서버가 이용자의 브라우저에게 보내는 아주
            작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.
          </p>
          <ol>
            <li>
              <h3>가. 쿠키의 사용 목적</h3>
              <ul>
                <li>
                  - 이용자들이 방문한 LINE FRIENDS STORE의 각 웹 페이지에 대한 방문 및 이용형태, 회원과
                  비회원의 접속 빈도 또는 머문 시간, 이용자 규모 등을 파악하여 이용자에게 최적화된 서비스를
                  제공하기 위하여 사용합니다.
                </li>
              </ul>
            </li>
            <li>
              <h3>나. 쿠키의 설치/운영 및 거부</h3>
              <ul>
                <li>
                  - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을
                  설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
                  쿠키의 저장을 거부할 수도 있습니다.
                </li>
                <li>
                  - 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 서비스 이용 시에 어려움이 있을 수
                  있습니다.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h2 class="header">9. 개인정보의 기술적/관리적 보호 대책</h2>
          <p>
            회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록
            안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
          </p>
          <ol>
            <li>
              <h3>가. 이용자 개인정보의 암호화</h3>
              <p>
                회사는 관련 법령과 정부 가이드라인에서 요구하는 수준 이상의 개인정보 암호화를 실시하고
                있습니다.
              </p>
            </li>
            <li>
              <h3>나. 해킹 등에 대비한 대책</h3>
              <p>
                회사는 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신 프로그램을 이용하여
                이용자들의 개인 정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을
                통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입 차단
                시스템을 이용하여 외부로 부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을
                확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
              </p>
            </li>
            <li>
              <h3>다. 취급직원의 최소화 및 교육</h3>
              <p>
                회사의 개인정보 관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여
                정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보처리방침의 준수를 항상
                강조하고 있습니다.
              </p>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h2 class="header">10. 개인정보보호 담당부서 및 연락처</h2>
          <p>
            귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호
            담당부서로 신고하실 수 있습니다.
            <br />회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
          </p>
          <table class="tb2">
            <thead>
              <tr>
                <th>개인정보보호책임자</th>
                <th>개인정보보호담당자</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>이름: 박현세</td>
                <td>이름: 변은진</td>
              </tr>
              <tr>
                <td>소속: LF Security</td>
                <td>소속: DTC Sales</td>
              </tr>
              <tr>
                <td>전화: 1544-5921</td>
                <td>전화: 1544-5921</td>
              </tr>
              <tr>
                <td>메일: dl_privacy_lf_kr@linecorp.com</td>
                <td>메일: dl_privacy_lf_kr@linecorp.com</td>
              </tr>
            </tbody>
          </table>
          <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
          <ul>
            <li>• 개인정보침해신고센터 http://privacy.kisa.or.kr 국번없이 118</li>
            <li>• 대검찰청 사이버범죄수사단 http://www.spo.go.kr 국번없이 1301</li>
            <li>• 경찰청 사이버테러대응센터 https://ecrm.policy.go.kr 국번없이 182</li>
          </ul>
        </div>
        <div class="paragraph">
          <h2 class="header">11. 적용범위</h2>
          <p>
            LINE FRIENDS Creator에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본
            “개인정보처리방침“이 적용되지 않음을 알려 드립니다.
          </p>
        </div>
        <div class="paragraph">
          <h2 class="header">12. 고지의무</h2>
          <p>
            현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의
            ‘공지사항‘을 통해 고지할 것입니다.<br />
            다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소
            30일 전에 고지합니다
          </p>
        </div>
        <div class="paragraph">
          <p>- 시행일자 : 2022년 3월 4일</p>
        </div>
        <div class="paragraph">
          <p><a href="/kr/privacy_190919">- 이전 개인정보처리방침 보기</a></p>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;
