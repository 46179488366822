import { entries, html, strMap, reject, go } from 'fxjs/es';
import { OMPCoreLayoutConstantS } from '../Constant/module/OMPCoreLayoutConstantS.js';

export const ompLeftMenu = (selected_menu) => {
  const left_menu = OMPCoreLayoutConstantS.LEFT_MENU();
  return html`
    <ul class="omp-left__menu">
      ${strMap(([, menu]) => {
        return html`
          <li class="omp-left__menu-li" data-active="${menu.key === selected_menu}">
            <a href="/${TT.lang}/${menu.url}">${menu.title}</a>
          </li>
        `;
      }, entries(left_menu))}
    </ul>
  `;
};

export const ompLeftSubMenu = ({ selected_menu, is_login, mp_worker }) => {
  const left_sub_menu_data = OMPCoreLayoutConstantS.LEFT_SUB_MENU();

  const left_sub_menu = go(
    left_sub_menu_data,
    entries,
    reject(([, menu]) => {
      if (is_login) {
        return menu.key === left_sub_menu_data.login.key || menu.key === left_sub_menu_data.sign_up.key;
      }
      return menu.key === left_sub_menu_data.my_page.key || menu.key === left_sub_menu_data.logout.key;
    }),
    reject(([, menu]) => {
      if (mp_worker) return false;
      return menu.key === left_sub_menu_data.print_service.key;
    }),
  );

  return html`
    <ul class="omp-left__menu2">
      ${strMap(([, menu]) => {
        if (menu.key == 'question') {
          return html` <li class="omp-left__menu2-li live_chat">${menu.title}</li> `;
        }

        return html`
          <li
            class="omp-left__menu2-li"
            data-active="${menu.key === selected_menu}"
            data-action="${menu.key}"
          >
            <a href="/${TT.lang}/${menu.url}">${menu.title}</a>
          </li>
        `;
      }, left_sub_menu)}
    </ul>
  `;
};

export const leftHTML = ({ locals }) => {
  return html`
    <div id="omp-left" class="omp-left">
      <div class="omp-left__wrap">
        ${ompLeftMenu(locals.omp_menu)}
        ${ompLeftSubMenu({
          selected_menu: locals.omp_menu,
          is_login: locals.login_user,
          mp_worker: !!locals?.policies?.mp_worker_policy,
        })}
      </div>
    </div>
  `;
};

export const leftFixedHTML = ({ locals }) => {
  return html`
    <div class="omp-layout-sidebar" data-left_is_show="false">
      <div class="omp-layout-left">
        <div class="omp-layout-left__logo">
          <h1 class="omp-layout-left__logo-h1">
            <a class="omp-layout-left__logo-a" href="/${T.lang}/">
              <img
                class="omp-layout-left__logo-img"
                src="//s3.marpple.co/files/u_218933/2023/7/original/f01a70531eac8ab8ced3654146a153db12da80a91.svg"
                alt="MARPPLE"
              />
            </a>
          </h1>
        </div>
        <div class="omp-layout-left__menu">
          <div id="omp-left" class="omp-left">
            <div class="omp-left__wrap">
              ${ompLeftMenu(locals.omp_menu)}
              ${ompLeftSubMenu({
                selected_menu: locals.omp_menu,
                is_login: locals.login_user,
                mp_worker: !!locals?.policies?.mp_worker_policy,
              })}
            </div>
          </div>
        </div>
      </div>
      <div class="omp-layout-sidebar__close">
        <button type="button" class="omp-layout-sidebar__btn-close">
          <img
            class="omp-layout-sidebar__btn-close-img"
            src="//s3.marpple.co/files/u_218933/2023/6/original/2ac1e56288412f50efe289696373aa7bb310be571.png"
            alt="X"
          />
        </button>
      </div>
      <div class="omp-layout-sidebar__open">
        <button type="button" class="omp-layout-sidebar__btn-open">
          <img
            class="omp-layout-sidebar__btn-open-img"
            src="//s3.marpple.co/files/u_218933/2023/6/original/8e502e1253f9c4d9359249b8390874473e611fe81.png"
            alt="X"
          />
        </button>
      </div>
    </div>
  `;
};
