import { find, last, sortBy } from 'fxjs/es';
import { PriceS } from '../../Price/S/Function/module/PriceS.js';

export function makeSaleManager(saleRanges) {
  /* 서버에서 받아온 할인범위를 start를 기준으로 정렬합니다. */
  function sortByStart(arr) {
    return sortBy((a) => a.start, arr);
  }

  function parseInt10(num) {
    return parseInt(num, 10);
  }

  /* 할인된 가격을 반환하기 전에 100원단위는 올림합니다. */
  // function round100(num) {
  //   return parseInt10(Math.round(num / 100) * 100);
  // }
  /*10원단위로 올림*/
  function round10(num) {
    return parseInt10(Math.round(num / 10) * 10);
  }

  /* 개당 가격과 할인율을 인자로 아 계산하는 함수입니다. */
  function discountPrice(price, percentage) {
    return PriceS.sub(price, PriceS.mult(price, Math.round(percentage) / 100));
    // return parseInt10(price - (price * (percentage) / 100));
  }

  /* 할인된 가격을 반환하는 함수입니다. 인자로 가격과 할인율을 받습니다. */
  function calculateSalePrice(price, percentage, p_of_p, hookF) {
    return hookF(discountPrice(PriceS.price(price), (parseInt10(percentage) * p_of_p) / 100));
  }

  /* start와 end 사이에 n이 있는지 확인하는 함수입니다. */
  function isBetweenAB(A, N, B) {
    return A <= N && N <= B;
  }

  /* 할인 범위를 골라내기 위해 최초로 받은 n을 이용하여 알아내는 함수입니다. */
  function selectPriceOfSaleRange(range, n) {
    return isBetweenAB(range.start, n, range.end);
  }

  /* 할인 범위를 골라내는 함수 입니다.
   *  인자 ranges는 type에 맞는 할인 범위들이고 n은 구매개수입니다.
   */
  function resultSaleRangeObject(ranges, n) {
    const _last = last(ranges);
    return find((range) => selectPriceOfSaleRange(range, n), ranges) || (_last.end < n && _last);
  }

  /* 할인율(percentage)를 알아내는 함수입니다.
   인자 ranges는 type에 맞는 할인 범위들이고 n은 구매개수입니다. */
  function resultSalePercent(ranges, n) {
    return resultSaleRangeObject(ranges, n).percentage || 0;
  }

  /* 인자를 type으로 받아 이에 맞는 총 할인 범위를 가져오는 함수입니다. */
  function selectSaleRanges(type) {
    /* saleRanges는 인자 type("PRESS" or "PRODUCT")을 이용하여 /api/ranges/:type 의 값을
     * 가져왔다고 가정하였습니다. /javascript/public/views/ranges-view.js의 21, 73라인에서 값을 지정해주었습니다. */
    return sortByStart(saleRanges);
  }

  /* !!!!!!!!!!
   * 할인된 가격을 계산하기 위한 함수 시작.
   * type은 PRDUCT, PRESS 로 구분합니다.
   * n은 개수, price는 해당 가격. console에 테스트하실 수 있습니다.*/
  function resultSalePrice(n, price, p_of_p, lang) {
    /* n의 개수로 percentage를 알아낸 후 할인된 한개의 가격을 반환합니다. */
    if (!selectSaleRanges().length) return price;

    return calculateSalePrice(
      price,
      resultSalePercent(selectSaleRanges(), parseInt10(n)),
      p_of_p === undefined ? 100 : parseInt10(p_of_p),
      lang == 'kr' ? round10 : lang == 'jp' ? parseInt : PriceS.price,
    );
  }

  return resultSalePrice;
}
