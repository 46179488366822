import { each, noop } from 'fxjs/es';
import { produce, setAutoFreeze, enableES5 } from 'immer';
enableES5();
setAutoFreeze(false);
export const pushPage = async (original_page, assign = noop) => {
  if (!$.frame.__pages[original_page.page_name]) $.frame.defn_page(original_page);
  const page = await produce(original_page, async (page) => {
    await assign(page, page.tabs);
  });
  each((t) => (t.data_func = t.makeData || noop), page.tabs);
  return $.frame.add_page(page);
};
