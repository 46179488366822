import { defnReviewWriteClauseTab, defnReviewWriteTab } from './tab.js';

$.frame.defn_page({
  page_name: 'mp.review.write.clause',
  animation: G.is_mobile_size(),
  tabs: [defnReviewWriteClauseTab()],
});

$.frame.defn_page({
  page_name: 'mp.review.write',
  tabs: [defnReviewWriteTab()],
});
