import { OMPMyPageOrderS } from '../../../MyPage/Order/S/Function/module/OMPMyPageOrderS.js';
import { OMPCartTmplS } from '../Tmpl/module/OMPCartTmplS.js';

export const CartProductTableColumns = () => {
  return [
    {
      key: 'product_number',
      title: TT('cart::cart_04'),
      size: 12,
      template: OMPCartTmplS.cartProductNumberColumnTmpl,
    },
    {
      key: 'product_info',
      title: TT('cart::cart_05'),
      url: (up_c) => {
        const can_purchase_url = up_c._.product_color._.base_product.id;

        return up_c.base_product_id && can_purchase_url ? OMPMyPageOrderS.makeUpcUrl(up_c) : '';
      },
      template: OMPCartTmplS.cartProductInfoColumnTmpl,
    },
    {
      key: 'product_sizes',
      title: TT('cart::cart_06'),
      size: 18,
      template: OMPCartTmplS.cartProductSizesColumnTmpl,
    },
    {
      key: 'product_price',
      title: TT('cart::cart_07'),
      size: 12,
      template: OMPCartTmplS.cartProductPriceColumnTmpl,
    },
    {
      key: 'product_edit',
      title: TT('cart::cart_08'),
      size: 15,
      template: OMPCartTmplS.cartProductEditColumnTmpl,
    },
  ];
};
