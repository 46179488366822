import { $delegate, $setData, $setText } from 'fxdom/es';
import { go } from 'fxjs/es';
import { OMPReviewWriteF } from '../../../Review/Write/F/Function/module/OMPReviewWriteF.js';

export const delegateWriteReview = (el) => {
  return $delegate('click', '.table-button__review', async (e) => {
    const up_c_id = e.currentTarget.dataset.up_c_id;
    const closed = (frame, review) => {
      if (review) go(e.currentTarget, $setData({ up_c_id: '' }), $setText(''));
    };
    if (up_c_id) await OMPReviewWriteF.openReviewWrite(up_c_id, closed);
  })(el);
};
