import { resetBgWithoutCvMaskBySf } from '../SizeFaces/fs.js';

export function makeCvBpcfInMakerA(canvas, bpcf, sf) {
  const last_cv_bpcf = G.mp.maker.cv_bpcf(canvas);
  if (last_cv_bpcf) canvas.remove(last_cv_bpcf);
  return new Promise(function (resolve) {
    fabric.Image.fromURL(G.mp.maker.url(bpcf.url), function (img) {
      img.set({
        selectable: false,
        evented: false,
        globalCompositeOperation: 'destination-over',
        _data: { cv_type: 'cv_bpcf', is_not_design: true },
      });
      resetBgWithoutCvMaskBySf(img, sf);
      canvas.add(img);
      canvas.renderAll();
      resolve();
    });
  });
}
