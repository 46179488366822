import { $addClass, $find, $findAll, $removeClass } from 'fxdom/es';
import { each, equals, go, isNil, rejectL } from 'fxjs/es';

export const setColor = ({ el, color_code }) => {
  const color_el =
    $find(`.color_selector .color[data-color_code="${color_code}"]`)(el) ??
    $find(`.color_selector .color[data-is_etc="true"]`)(el);
  if (isNil(color_el)) {
    return;
  }

  $addClass('active')(color_el);
  go(el, $findAll('.color_selector .color'), rejectL(equals(color_el)), each($removeClass('active')));
};
