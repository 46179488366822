import { isEmpty, isNil } from 'fxjs/es';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';

const isEmNil = (_) => isEmpty(_) || isNil(_);

// @description 샵 주문에서 사내 배송지 선택으로 발송 요청한 경우 사내 배송 요청지 배송 정보 리턴
export function getCustomerOrderCrewInhouse({ prj }) {
  const coci_from_shipping = prj._?.shippings?.[0]?._?.customer_order?._?.customer_order_crew_inhouse;
  const coci_from_shipping_order = prj._?.shipping_order?._?.store_order?._?.customer_order_crew_inhouse;

  return coci_from_shipping || coci_from_shipping_order;
}

// @description 사내 배송 주문인지 확인 - 스토어 주문 -> 스토어 -> 크류 -> 크류가 inhouse 인지 판단
export function isInhouseProjection({ prj }) {
  const is_crew_inhouse_1 = prj?._?.store?._?.crew?._?.crew_inhouse;
  const is_crew_inhouse_2 = prj._?.store?._?.crews_stores?._?.crew_inhouse?.id; // 사내몰 parent -> store 로 사내몰 판단
  const is_inhouse_shipping_1 = prj._?.shipping_order?._?.store_order?._?.customer_order_crew_inhouse?.id; // 사내몰 child -> shipping 으로 판단
  const is_inhouse_shipping_2 = prj._?.shippings?.[0]?._?.customer_order?._?.customer_order_crew_inhouse?.id;

  return (
    !isEmNil(is_crew_inhouse_1) ||
    !isEmNil(is_crew_inhouse_2) ||
    !isEmNil(is_inhouse_shipping_1) ||
    !isEmNil(is_inhouse_shipping_2)
  );
}

// @description 배송을 사내배송 (사내) 로 해야 하는지 판단. (사내배송이라도 개인 배송지로 배송 X)
export function isShipToInhouse({ prj }) {
  const customer_order_crew_inhouse = getCustomerOrderCrewInhouse({ prj });

  return (
    isInhouseProjection({ prj }) &&
    UtilObjS.isNotEmpty(customer_order_crew_inhouse) &&
    !customer_order_crew_inhouse.is_hidden
  );
}
