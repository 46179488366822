import { VectorEditorKeyringHookPCMuiS } from '../../S/Mui/module/VectorEditorKeyringHookPCMuiS.js';
import { $find, $findAll, $on, $setCss } from 'fxdom/es';
import { PCRightFixedPanelF } from '../../../../../../PCRightFixedPanel/F/Function/module/PCRightFixedPanelF.js';
import { EditorNS } from '@marpple/sticker-editor';
import KeyringHook from '@marpple/keyring_hook/src/index.js';
import { each, go, tap } from 'fxjs/es';
import { VectorEditorKeyringHookPCF } from '../Function/module/VectorEditorKeyringHookPCF.js';

const HOOK_INNER_R = 1.5;
const HOOK_OUTER_R = 4.5;

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorKeyringHookPCMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_frame_position_top = tab_el.__mp_frame_position_top ?? 0;
    tab_el.__mp_frame_position_height = tab_el.__mp_frame_position_height ?? 0;
    tab_el.__mp_prev_right_panel_el = tab_el.__mp_prev_right_panel_el ?? null;
    tab_el.__mp_art_board_width = tab_el.__mp_art_board_width ?? 0;
    tab_el.__mp_art_board_height = tab_el.__mp_art_board_height ?? 0;
    tab_el.__mp_art_board_shape_path_data = tab_el.__mp_art_board_shape_path_data ?? `M 0 0 Z`;
    tab_el.__mp_makeTargetImageEl = tab_el.__mp_makeTargetImageEl ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_keyring_hook_editor = null;
    tab_el.__mp_hook = null;
    tab_el.__mp_is_hook_moving = false;

    go(
      tab_el,

      tap($on('mousemove', VectorEditorKeyringHookPCF.handleTabElMouseMove({ tab_el }))),
      tap($on('mouseup', VectorEditorKeyringHookPCF.handleTabElMouseUp({ tab_el }))),
      tap($on('mouseleave', VectorEditorKeyringHookPCF.handleTabElMouseLeave({ tab_el }))),

      tap(
        $findAll(`.editor_container`),
        each($on('mousedown', VectorEditorKeyringHookPCF.handleEditorContainerMouseDown({ tab_el }))),
      ),

      tap(
        $findAll(`.right_container .wrapper_footer .cancel`),
        each($on('click', VectorEditorKeyringHookPCF.handleRightCancelButtonClick({ tab_el }))),
      ),
      tap(
        $findAll(`.right_container .wrapper_footer .done`),
        each($on('click', VectorEditorKeyringHookPCF.handleRightDoneButtonClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.right_container .wrapper_body_control_wrapper_out .wrapper_body_control_wrapper_out_inside`,
        ),
        each($on('click', VectorEditorKeyringHookPCF.handleRightInsideButtonClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.right_container .wrapper_body_control_wrapper_in .wrapper_body_control_wrapper_in_outside`,
        ),
        each($on('click', VectorEditorKeyringHookPCF.handleRightOutsideButtonClick({ tab_el }))),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    const keyring_hook_editor = new EditorNS.KeyringHookEditorNS.PCNS.KeyringHookPCEditor({
      window,
      document,
    });
    tab_el.__mp_keyring_hook_editor = keyring_hook_editor;
    keyring_hook_editor.initDOM();
    keyring_hook_editor.initEditor();
    keyring_hook_editor.setArtBoardSize({
      width: tab_el.__mp_art_board_width,
      height: tab_el.__mp_art_board_height,
    });
    keyring_hook_editor.setArtBoardShapePath({
      path_data: tab_el.__mp_art_board_shape_path_data,
    });

    const editor_container_el = $find(`.editor_container`)(tab_el);
    if (editor_container_el == null) {
      const error = new Error(`editor_container 클래스를 가진 엘리먼트를 찾을 수 없습니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Hook::PC::error_message::no_editor_container_el',
      );
      throw error;
    }
    keyring_hook_editor.appendTo(editor_container_el);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  async showed(tab_el) {
    $setCss(['top', `${tab_el.__mp_frame_position_top}px`])(tab_el);
    $setCss(['height', `${tab_el.__mp_frame_position_height}px`])(tab_el);

    if (tab_el.__mp_keyring_hook_editor == null) {
      const error = new Error(`tab_el.__mp_keyring_hook_editor 객체가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Hook::PC::error_message::no_keyring_hook_editor',
      );
      throw error;
    }
    const editor = tab_el.__mp_keyring_hook_editor;

    if (tab_el.__mp_makeTargetImageEl == null) {
      const error = new Error(`tab_el.__mp_makeTargetImageEl 함수가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Hook::PC::error_message::no_make_target_image_el',
      );
      throw error;
    }
    const target_image_el = await tab_el.__mp_makeTargetImageEl();
    editor.setTargetImageEl(target_image_el);

    editor.fitSize();
    const view_box_vo = editor.calculateViewBoxFitToArtBoard({
      padding_in_vcs: { top: 180, right: 300, bottom: 180, left: 300 },
      vertical_align: 'center',
    });
    editor.setViewBox(view_box_vo);

    const hook = new KeyringHook({
      art_board_size: { width: tab_el.__mp_art_board_width, height: tab_el.__mp_art_board_height },
      path_data: tab_el.__mp_art_board_shape_path_data,
      radius: { inner: HOOK_INNER_R, outer: HOOK_OUTER_R },
      default_positioning: 'outside',
    });
    tab_el.__mp_hook = hook;
    const { x, y } = hook.init_center_position;

    editor.setHookSize({ outer_r: HOOK_OUTER_R, inner_r: HOOK_INNER_R });
    editor.setHookPosition({ x, y });

    return (() => {
      const right_el = $find(`.right_container .right_wrapper`)(tab_el) ?? document.createElement('div');
      const left_el = tab_el.__mp_prev_right_panel_el ?? document.createElement('div');
      return PCRightFixedPanelF.showPcRightFixedPanel({ right_el, left_el });
    })();
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, _v) {
    return (() => {
      const right_el = $find(`.right_container .right_wrapper`)(tab_el) ?? document.createElement('div');
      const left_el = tab_el.__mp_prev_right_panel_el ?? document.createElement('div');
      return PCRightFixedPanelF.hidePcRightFixedPanel({ right_el, left_el });
    })();
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, _v) {
    if (tab_el.__mp_keyring_hook_editor != null) {
      const editor = tab_el.__mp_keyring_hook_editor;
      editor.destroy();
    }

    tab_el.__mp_frame_position_top = 0;
    tab_el.__mp_frame_position_height = 0;
    tab_el.__mp_prev_right_panel_el = null;
    tab_el.__mp_art_board_width = 0;
    tab_el.__mp_art_board_height = 0;
    tab_el.__mp_art_board_shape_path_data = `M 0 0 Z`;
    tab_el.__mp_makeTargetImageEl = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_keyring_hook_editor = null;
    tab_el.__mp_hook = null;
    tab_el.__mp_is_hook_moving = false;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
