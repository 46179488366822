import { go, html } from 'fxjs/es';
import { makeImageFromUrl } from '../../../../Maker/F/util.js';
import { $appendTo, $el, $on, $qs, $remove, $setCss } from 'fxdom/es';

export async function showImage(url) {
  await go(makeImageFromUrl(url), (img) => {
    const img_wrapper =
      $qs('.show_image_img_wrapper') ||
      go(
        html` <div class="show_image_img_wrapper"></div> `,
        $el,
        $setCss({
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'scroll',
          background: '#fff',
          zIndex: 999,
        }),
        $on('click', (e) => {
          $remove(e.currentTarget);
        }),
        $appendTo($qs('body')),
      );
    go(img, $appendTo(img_wrapper));
  });
}
