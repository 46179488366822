import { SVGEditorUtilF } from '../../../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { PdfF } from '../../../../../Pdf/F/Function/module/PdfF.js';
import { each, go, flatten, html } from 'fxjs/es';
import { $attr, $hide, $on, $qs, $qsa, $setAttr, $setCss, $show } from 'fxdom/es';
import { getBaseProductInMaker, getProductColorInMaker } from '../../../../../Maker/F/getSth.js';
import { BpOptionUploadTypeS } from '../../../../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { marpplizerProxy } from '../../../../../Maker/F/Marpplizer/marpplizer.js';
import { alertProEditorCsError } from '../../../../../VectorEditor/F/Function/pro_editor.error.js';
import tippy from 'tippy.js';
import { NewMakerPropertyBaseProductF } from './module/NewMakerPropertyBaseProductF.js';
import { setDataUploadTypeIsDesigned } from '../../../../Util/F/Function/makeDataIsDesigned.js';
import { OMPCoreUtilF } from '../../../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { NewMakerCanvasEditorF } from '../../../../CanvasEditor/F/Function/module/NewMakerCanvasEditorF.js';
import { PrintTemplateS } from '../../../../../PrintTemplate/S/Function/module/PrintTemplateS.js';
import { PrintTemplateF } from '../../../../../PrintTemplate/F/Function/module/PrintTemplateF.js';

const renderDirectResultEl = async (pdf_printing_file) => {
  await go(
    $qsa('.board__upload-type-direct-result'),
    each(async (el) => {
      if (pdf_printing_file) {
        const printing_template = getBaseProductInMaker()._.printing_templates?.[0];
        el.innerHTML = await BpOptionUploadTypeS.UPLOAD_TYPE.option.direct.makePdfResultTmpl({
          pdf_printing_file,
          printing_template,
        });
      } else {
        el.innerHTML = ``;
      }
    }),
  );
};
export const pdfFilePossible = {
  fileUploadEvent: async (ct) => {
    let loaderDone = null;
    try {
      loaderDone = SVGEditorUtilF.percentLoader({
        message: T('modules::VectorEditor::AcrylicFigure::message::make_images'),
        time: 6 * 1000,
        clazz: UtilF.isLegacyMobile() ? 'mobile' : 'pc',
      });
      const file = ct.files[0];

      if (file) {
        const result = await PdfF.uploadPdfFile({ file });
        if (result.success) {
          const template = await PrintTemplateF.getPrintTemplate({
            bp_id: getBaseProductInMaker().id,
            lang: T.lang,
          });
          const pdf_printing_file = result.data[0];
          if (BpOptionUploadTypeS.UPLOAD_TYPE.option.editor.isReadyBp(getProductColorInMaker())) {
            const is_valid_pdf_with_template = PrintTemplateS.validatePdfPrintingFile({
              template,
              pdf_printing_file,
            });
            if (is_valid_pdf_with_template.is_OK === false) {
              // OMPCoreUtilF.showToast({
              //   text: TT('pdf::uploader::invalid_template'),
              //   position: { vertical: 'bottom', horizontal: 'center' },
              //   time: 3000,
              // });
              await OMPCoreUtilF.titleAlert({
                title: TT(`${TT('pdf::uploader::invalid_template2')}`),
                content: TT(`${TT('pdf::uploader::invalid_template3')}`),
                width: 360,
              });
              return;
            }
          }
          NewMakerPropertyBaseProductF.pdfFilePossible.setPdf(pdf_printing_file);
          setDataUploadTypeIsDesigned();
          await renderDirectResultEl(pdf_printing_file);

          await marpplizerProxy();
        } else {
          await alertProEditorCsError({ title: TT('pro_editor::alert::title::dev') });
        }
      }
    } finally {
      $.don_loader_end();
      if (loaderDone) {
        await loaderDone().catch(() => {});
        loaderDone = null;
      }
      ct.value = null;
    }
  },

  mpMakerUpload: async (product) => {
    const images_by_pf =
      await NewMakerPropertyBaseProductF.bpOptionMakerMaterial.makePrintableImageFromPrintableInfo(product);
    const pdf_file = await PdfF.burnPDF({
      pdf_spec: {
        metadata: {
          title: `MPMAKER_${getBaseProductInMaker().name}_${
            box().maker.product_color._.selected_option_group.title
          }`,
          author: 'marpple',
          keywords: ['pdf', 'marpple', 'postcard'],
        },
        pages: images_by_pf.map(({ img_src, size, with_white_background, cutline }) => {
          return {
            layout: {
              w_mm: size.w_mm,
              h_mm: size.h_mm,
            },
            images: [
              {
                layer: 1,
                src: img_src,
                posToPage: {
                  x_ratio: 0,
                  y_ratio: 0,
                },
                sizeToPage: {
                  w_ratio: 1,
                  h_ratio: 1,
                },
                rotate_deg: 0,
                opacity: 1,
                with_white_background,
              },
            ],
            cutline,
          };
        }),
      },
    });
    const result = await PdfF.uploadPdfFile({ file: pdf_file });
    if (result.success) {
      const pdf_printing_file = result.data[0];
      pdfFilePossible.setPdf(pdf_printing_file, true);
      return true;
    }
  },
  setPdf: (pdf_printing_file, override = false) => {
    box.sel('maker->product_color').pdf_printing_file_id = pdf_printing_file.id;
    // override : pdf template validation 로직 무시
    box.sel('maker->product_color')._.pdf_printing_file = { ...pdf_printing_file, override: !!override };
  },
  unsetPdf: async () => {
    box.sel('maker->product_color').pdf_printing_file_id = null;
    box.sel('maker->product_color')._.pdf_printing_file = {};
    await renderDirectResultEl();
  },
  setProductFaceThumbnail: (pdf_printing_file) => {
    box.sel('maker->product_color').product_faces2.value[0].pdf_thumb_url =
      pdf_printing_file.thumbnail_urls[0];
  },
  isPassUploadTypeDirect: (product) => {
    return !!product.pdf_printing_file_id;
  },
  goToEditorType: async (bp_option_id, need_hide) => {
    const base_product = getBaseProductInMaker();
    if (base_product.maker_type === 'DEFAULT') {
      if (!UtilF.isLegacyMobile()) {
        const image_upload_desc_el = $qs('.image_upload_desc');
        if (image_upload_desc_el && $attr('is_enter', image_upload_desc_el) === 'true') {
          $setAttr({ is_enter: false }, image_upload_desc_el);
          $hide(image_upload_desc_el);
        }
      }
      const product = getProductColorInMaker();
      const is_direct_type = BpOptionUploadTypeS.UPLOAD_TYPE.isDirectType(product);
      const is_editor_type = BpOptionUploadTypeS.UPLOAD_TYPE.isEditorType(product);
      const is_pass = NewMakerPropertyBaseProductF.pdfFilePossible.isPassUploadTypeDirect(product);
      if (
        (bp_option_id == BpOptionUploadTypeS.UPLOAD_TYPE.option.direct.bp_option_id &&
          is_editor_type &&
          (flatten(G.mp.maker.all_designs())?.length || is_pass)) ||
        (bp_option_id == BpOptionUploadTypeS.UPLOAD_TYPE.option.editor.bp_option_id &&
          is_direct_type &&
          is_pass)
      ) {
        // const is_confirmed = await $.confirm('초기화 됩니다. 실행하시겠습니까?');
        const is_confirmed = await OMPCoreUtilF.confirm({
          content: T('maker::option::content_2'),
          min_width: 'initial',
          width: 300,
        });
        if (!is_confirmed) {
          return false;
        }
        if (need_hide) $hide($qs('.canvass'));
        await NewMakerCanvasEditorF.service.reset();
        await NewMakerPropertyBaseProductF.pdfFilePossible.unsetPdf();
        if ($qs('.mp_product_detail__main-image')) {
          $qs('.mp_product_detail__main-image').outerHTML = html`
            <div class="fake_mp_maker">
              <div class="wrapper"></div>
              <div class="left"></div>
              <div class="right"></div>
              <div class="dots"></div>
            </div>
          `;
          $show($qs('.mp_maker'));
        }
        return true;
      }
    }
  },
  activeTippy: () => {
    if (!UtilF.isLegacyMobile()) return;
    const upload_popup_open_el = $qs('.mp-maker-pdf-file-upload-pop-up-tooltip');
    if (!upload_popup_open_el) return;
    upload_popup_open_el.__tippy = tippy(upload_popup_open_el, {
      content: `<div style="display: flex;align-items: center;"><span>${TT(
        'maker_upload_type::만들기 툴은 곧 제공 예정이에요!',
      )}</span><img src="//s3.marpple.co/files/u_193535/2024/2/original/6e76d55351484915787811d054dd869df3348b4c1.png" style="width:8px;height:8px;margin-left:8px;" alt=""></img></div>`,
      allowHTML: true,
      theme: 'maker-select-face-mobile',
      showOnCreate: true,
      placement: 'top',
      appendTo: upload_popup_open_el,
      maxWidth: 'none',
      trigger: 'click',
      onShown(instance) {
        go(
          instance.popper,
          $on('click', () => {
            instance.destroy();
          }),
          $setCss({ 'pointer-events': 'initial' }),
        );
        // ...
      },
      hideOnClick: false,
    });
  },
};
