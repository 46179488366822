import axios from 'axios';
import { $delegate } from 'fxdom/es';
import { go } from 'fxjs/es';
import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { OMPCoreAtomF } from '../../../Core/Atom/F/Function/module/OMPCoreAtomF.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPSearchAutoCompleteF } from '../../AutoComplete/F/Function/module/OMPSearchAutoCompleteF.js';
import { SearchHistoryApiHelper } from '../../History/F/Function/SearchHistoryApiHelper.js';
import { OMPSearchConstantS } from '../../S/Constant/module/OMPSearchConstantS.js';
import { OMPSearchS } from '../../S/Function/module/OMPSearchS.js';

/**
 * `.omp-search__search-form`에 추가하는 delegate event
 * @param {HTMLElement} el
 * @return {HTMLElement} el
 */
export const delegateSearchFormEvent = (el) => {
  try {
    if (window.VisualViewport) {
      visualViewport.addEventListener('resize', OMPCoreUtilF.resizeHeightOfBodyByKeyboardVisibility);
    }

    return go(
      el,
      OMPCoreAtomF.delegateInputForm,
      OMPSearchAutoCompleteF.delegateSearchAutoComplete,
      $delegate('submit-input-form', `.${OMPSearchConstantS.Klass.searchForm}`, async (e) => {
        const { inputValue } = e.detail;
        if (inputValue) {
          await SearchHistoryApiHelper.post([inputValue]);
        }
        location.href = OMPSearchS.getSearchResultUrl(inputValue);
      }),
      $delegate('debounce-input', `.${OMPSearchConstantS.Klass.searchForm}`, async (e) => {
        const { inputValue } = e.detail;
        if (!inputValue) {
          return;
        }

        /** @type {AutoCompleteItem[]} */
        const items = inputValue
          ? await axios
              .get(`/${T.lang}/@api/search/autocomplete`, { params: { q: inputValue } })
              .then((res) => res.data)
          : [];
        OMPSearchAutoCompleteF.updateAutoCompleteItems(items, e.currentTarget);
      }),
      $delegate('reset', `.${OMPSearchConstantS.Klass.searchForm}`, (e) => {
        OMPSearchAutoCompleteF.flushAutoCompleteItems(e.currentTarget);
      }),
    );
  } catch (e) {
    e.message = 'OMPSearchF.delegateSearchFormEvent Error';
    ConfigSentryF.error(e);
  }
};
