import { eventResetPassword, eventVerifyEmail } from '../../../../modules/User/Verify/F/event.js';

export default function (lo) {
  if ($.has_class($1('body'), 'verify_area')) {
    $.frame({
      frame_name: 'mp.verify_frame',
    });
  }

  if ($.has_class($1('body'), 'verify_reset_password')) {
    $.frame({
      frame_name: 'mp.change_password_frame',
    });
  }

  $.frame.defn_frame({
    frame_name: 'mp.verify_frame',
    page_name: 'mp.verify_email_page',
  });

  $.frame.defn_page([
    {
      page_name: 'mp.verify_email_page',
      tabs: [
        {
          tab_name: 'mp.verify_email_tab',
          appended: eventVerifyEmail,
          rendered: function (tab) {
            const location = G.$location();
            if (_p.v(location, 'query.verified') == 'false') {
              $.alert(T('login::Your email address is not verified.'));
              window.history.replaceState(window.history.state, '', '/verify/email');
            }
          },
        },
      ],
    },
  ]);

  $.frame.defn_frame({
    frame_name: 'mp.change_password_frame',
    page_name: 'mp.change_password_page',
  });

  $.frame.defn_page([
    {
      page_name: 'mp.change_password_page',
      tabs: [
        {
          tab_name: 'mp.change_password_tab',
          appended: eventResetPassword,
        },
      ],
    },
  ]);
}
