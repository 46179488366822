import { EditorNS } from '@marpple/sticker-editor';
import { $findAll, $off, $on } from 'fxdom/es';

import { each, go, takeL, tap } from 'fxjs/es';
import { VectorEditorStickerGridMobileEventF } from '../Event/module/VectorEditorStickerGridMobileEventF.js';
import { VectorEditorStickerGridMobileMuiS } from '../../S/Mui/module/VectorEditorStickerGridMobileMuiS.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerGridMobileMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {
    tab_el$.__mp_handleWindowMouseMove = null;
    tab_el$.__mp_is_prevent_mouse_move = false;

    go(
      tab_el$,

      tap(
        $findAll(`.header button.cancel`),
        each($on('click', VectorEditorStickerGridMobileEventF.handleHeaderCancelButtonClick())),
      ),
      tap(
        $findAll(`.header button.done`),
        each(
          $on('click', VectorEditorStickerGridMobileEventF.handleHeaderDoneButtonClick({ tab_el: tab_el$ })),
        ),
      ),

      tap(
        $findAll(`.footer[data-icon_type="set_background"]`),
        each(
          $on(
            'click',
            VectorEditorStickerGridMobileEventF.handleFooterSetBackgroundIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer[data-icon_type="delete_target_image"]`),
        each(
          $on(
            'click',
            VectorEditorStickerGridMobileEventF.handleFooterDeleteTargetImageIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer[data-icon_type="duplicate_target_image"]`),
        each(
          $on(
            'click',
            VectorEditorStickerGridMobileEventF.handleFooterDuplicateTargetImageIconClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer[data-icon_type="edit_target_image"]`),
        each(
          $on(
            'click',
            VectorEditorStickerGridMobileEventF.handleFooterEditTargetImageIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),

      tap(
        $on(
          '@mp/sticker-editor/select',
          VectorEditorStickerGridMobileEventF.handleEditorSelect({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/unselect',
          VectorEditorStickerGridMobileEventF.handleEditorUnselect({ tab_el: tab_el$ }),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    tab_el$.__mp_handleWindowMouseMove = VectorEditorStickerGridMobileEventF.handleWindowMouseMove({
      tab_el: tab_el$,
    });
    $on('touchmove', tab_el$.__mp_handleWindowMouseMove, { passive: false })(window);

    const grid_sticker_editor = new EditorNS.GridStickerEditorNS.MobileNS.GridStickerMobileEditor({
      window,
      document,
    });
    grid_sticker_editor.initDOM();
    grid_sticker_editor.initEditor();
    go(
      tab_el$,
      $findAll('.editor_container'),
      takeL(1),
      each((el) => grid_sticker_editor.appendTo(el)),
    );
    tab_el$.__mp_grid_sticker_editor = grid_sticker_editor;
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {
    tab_el$.__mp_is_prevent_mouse_move = true;
  }, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {
    tab_el$.__mp_grid_sticker_editor?.selectGrid?.(null);
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {
    tab_el$.__mp_is_prevent_mouse_move = false;
  }, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {
    if (tab_el$.__mp_handleWindowMouseMove != null) {
      $off('touchmove', tab_el$.__mp_handleWindowMouseMove, { passive: false })(window);
    }

    tab_el$.__mp_handleWindowMouseMove = null;
    tab_el$.__mp_is_prevent_mouse_move = false;

    tab_el$.__mp_grid_sticker_editor?.destroy();
    tab_el$.__mp_grid_sticker_editor = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
