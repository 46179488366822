!(function () {
  $.frame.defn_frame({
    frame_name: 'df.order_editor_frame',
    el_class: 'df_order_editor_frame',
    hide_frame_button_type: 'X',
    header_height: 60,
    always_remove: true,
    title: '발주 생성',
  });

  $.frame.defn_page({
    page_name: 'df.order_editor_page',
    tabs: [
      {
        tab_name: 'df.order_editor_tab',
        template: __(
          function (req_bp_stocks, order, task_ids) {
            // data_func _.mr(bp_stocks, order_id, task_ids);
            const order_id = order && _p.v(order, 'id');

            return _p.go(
              $.post('/@api/df/get/bp_stocks', {
                bp_stocks: req_bp_stocks,
                order_id: order_id,
              }),
              function (get_bp_stocks) {
                const indexed_req_bp_stocks = _p.index_by(req_bp_stocks, 'id');
                const _order = {
                  id: order_id,
                  name: _p.v(order, 'name') || '',
                  _: {
                    order_items: _p.map(get_bp_stocks, function (bp_stock) {
                      return {
                        order_id: order_id,
                        bp_stock_id: bp_stock.id,
                        order_count: indexed_req_bp_stocks[bp_stock.id].quantity,
                        _: {
                          bp_stock: bp_stock,
                        },
                      };
                    }),
                  },
                };
                box.set('order_editor', {
                  order: _order,
                  task_ids: task_ids,
                  req_bp_stocks: req_bp_stocks,
                });
                return _p.mr(_order, task_ids);
              },
            );
          },
          _p.t(
            'order, task_ids',
            '\
          .df_order_editor[order_id="{{order.id || ""}}" _sel="order_editor->order" has_task="{{_.v(task_ids, "length") ? "true" : "false"}}"]\
            .options\
              발주 이름: \
              input[type="text" name="name" value="{{order.name || ""}}" placeholder="발주 이름"]\
              button[type="button"].btn_confirm {{task_ids && task_ids.length ? "재고 확인 및 발주 추가 완료" : "확인"}}\
            .task_ids\
              h3 태스크 아이디들\
              ul\
                {{_sum(task_ids, ',
            _p.t(
              'task_id',
              '\
                  li #{{task_id}}\
                ',
            ),
            ')}}\
            .list[_sel="./_->order_items"]\
              table\
                tr\
                  th 업체명\
                  th 상품명\
                  th 업체상품명\
                  th 색상\
                  th 사이즈\
                  th.projection_count 주문예정수량\
                  th 발주 수량\
                  th\
                  th 현 재고\
                  th 발주 중 수량\
                  th.expect_count 예상 재고\
                {{_.go(_.mr(order, task_ids),',
            function (order, task_ids) {
              return _p.sum(order._.order_items, function (oi) {
                return G.$t_$order_item(oi, task_ids);
              });
            },
            ')}}\
        ',
          ),
        ),
        removed: function () {
          box.unset('order_editor');
        },
        appended: __(
          $.on('input', 'input[name="order_count"]', function (e) {
            const has_task = $.attr($.find1(e.delegateTarget, '.df_order_editor'), 'has_task');
            const order_item_el = $.closest(e.currentTarget, '.order_item');
            const val = $.val(e.currentTarget);
            const projection_count = has_task
              ? parseInt($.val($.find1(order_item_el, 'input[name="projection_count"]')))
              : 0;
            const expect_count_el = $.find1(order_item_el, 'td.expect_count');
            const stock_count = parseInt($.text($.find1(order_item_el, 'td.stock_count')));
            const ordering_count = parseInt($.text($.find1(order_item_el, 'td.ordering_count')));

            if (val < 0)
              return _p.go('0개이상 주문 수량을 맞춰주세요.', $.alert, function () {
                $.val(e.currentTarget, 0);
                $.text(expect_count_el, -projection_count + 0 + stock_count + ordering_count);
                e.currentTarget.focus();
              });

            $.text(expect_count_el, -projection_count + val + stock_count + ordering_count);
          }),
          $.on2('click', '.btn_confirm', function (e) {
            return _p.go(
              $[box.sel('order_editor->order->id') ? 'put' : 'post']('/@api/df/order', {
                order_id: box.sel('order_editor->order->id'),
                name: $.val($.find1(e.delegateTarget, 'input[name="name"]')),
                task_ids: box.sel('order_editor->task_ids'),
                order_items: _map($.find(e.delegateTarget, '.order_item'), function (oi_el) {
                  return {
                    bp_stock_id: $.attr(oi_el, 'bp_stock_id'),
                    company: $.val($.find1(oi_el, 'input[name="company"]')),
                    company_pd_name: $.val($.find1(oi_el, 'input[name="company_pd_name"]')),
                    order_count: $.val($.find1(oi_el, 'input[name="order_count"]')),
                  };
                }),
                req_bp_stocks: box.sel('order_editor->req_bp_stocks'),
              }),
              function (data) {
                if (!data.result) return $.alert('발주서 작성이 실패하였습니다.');

                return _p.go(
                  $.confirm('발주서 작성이 완료되었습니다.<br>발주 상세 페이지로 가시겠습니까?'),
                  _p
                    .if2(_p.idtt)(function () {
                      window.location.href = '/df/order_detail/' + data.order.id;
                    })
                    .else(function () {
                      $.frame.close(data.order);
                    }),
                );
              },
            );
          }),
        ),
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'df.orders_frame',
    el_class: 'df_orders_frame',
  });

  $.frame.defn_page({
    page_name: 'df.orders_page',
    tabs: [
      {
        tab_name: 'df.orders_tab',
        appended: __(
          _p.tap(function (tab) {
            $.ui.pagination(
              $.find1(tab, '.don_pgn_container'),
              {
                url: '/@api/df/orders',
                data: {
                  //
                  limit: 15 /* 기본값 20 */,
                },
                // is_history: true, /* 기본값 false */
                is_memoize: true /* 기본값 false */,
                paging_animate: ___(
                  function (don_pgn_wrap, new_items) {
                    this.don_pgn_wrap = don_pgn_wrap;
                    this.new_items = new_items;
                    return anime({
                      targets: don_pgn_wrap,
                      opacity: 0,
                      duration: 400,
                      easing: 'linear',
                    }).finished;
                  },
                  function () {
                    $.html(this.don_pgn_wrap, this.new_items);
                    anime({
                      targets: this.don_pgn_wrap,
                      opacity: 1,
                      duration: 400,
                      easing: 'linear',
                    });
                  },
                ),
                // target: '#scroll_test', /* 기본값 window 스크롤 이벤트 타겟 */
                total_count: function () {
                  return box().total_count;
                },
              },
              _p.sum(G.$t_$order),
            );
          }),
        ),
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'df.order_detail_frame',
    el_class: 'df_order_detail_frame',
  });

  $.frame.defn_page({
    page_name: 'df.order_detail_page',
    tabs: [
      {
        tab_name: 'df.order_detail_tab',
        appended: __(
          $.on2('click', '.btn_save_name', function (e) {
            const order = box.sel($.find1(e.delegateTarget, '.df_order_detail'));

            return _p.go(
              $.put('/@api/df/order/name', {
                order_id: order.id,
                name: $.val($.find1(e.delegateTarget, 'input[name="name"]')),
              }),
              function (data) {
                if (!data.result) return $.alert('이름이 변경되지 않았습니다.');
                $.alert('발주서 이름이 저장되었습니다.');
                box.set('order->name', data.order.name);
              },
            );
          }),
          $.on2('click', '.btn_order_complete', function (e) {
            const order = box.sel($.find1(e.delegateTarget, '.df_order_detail'));
            if (!order || !order.id) return;

            return _p.go($.put('/@api/df/order/complete', { order_id: order.id }), function (data) {
              if (!data.result || !data.order.ordered_at) return $.alert('발주 완료 실패');
              window.location.reload();
            });
          }),
          $.on2('click', '.btn_order_count', function (e) {
            const $order_item = $.closest(e.currentTarget, '.order_item');
            const order_item = box.sel($order_item);

            if (!order_item || !order_item.id)
              return $.alert('데이터가 잘못되었습니다. 새로고침 후 다시 사용해보세요.');

            return _p.go(
              $.put('/@api/order_item/order_count', {
                order_item_id: order_item.id,
                add_order_count: $.val($.find1($order_item, 'input[name="add_order_count"]')),
              }),
              function (data) {
                if (!data.result) return $.alert('수량 추가 실패');
                box.extend2('order->_->order_items->(#' + data.order_item.id + ')', data.order_item);
                $.after(
                  $order_item,
                  G.$t_$order_detail_item(
                    box.sel('order->_->order_items->(#' + data.order_item.id + ')'),
                    box.sel('order'),
                  ),
                );
                $.remove($order_item);
              },
            );
          }),
          $.on2('click', '.btn_order_company', function (e) {
            const $order_item = $.closest(e.currentTarget, '.order_item');
            const order_item = box.sel($order_item);

            if (!order_item || !order_item.id)
              return $.alert('데이터가 잘못되었습니다. 새로고침 후 다시 사용해보세요.');

            return _p.go(
              $.put('/@api/order_item/company', {
                order_item_id: order_item.id,
                company: $.val($.find1($order_item, 'input[name="company"]')),
              }),
              function (data) {
                if (!data.result) return $.alert('업체명 변경 실패');
                $.alert('업체명이 변경되었습니다.');
                box.set(
                  'order->_->order_items->(#' + data.order_item.id + ')->company',
                  data.order_item.company,
                );
              },
            );
          }),

          $.on2('click', '.btn_all_arrival_confirm', function (e) {
            const order_items = _p.map($.find(e.delegateTarget, '.order_item'), function ($order_item) {
              const oi = box.sel($order_item);
              const add_arrival_count =
                oi.order_count - oi.cancel_count > oi.arrival_count
                  ? oi.order_count - oi.cancel_count - oi.arrival_count
                  : 0;

              return {
                id: oi.id,
                order_count: oi.order_count,
                arrival_count: oi.arrival_count,
                add_arrival_count: add_arrival_count,
                cancel_count: oi.cancel_count,
              };
            });

            return _p.go(
              $.confirm('전체 입고 수량을 자동으로 추가 하시겠습니까?'),
              _p.if2(_p.idtt)(
                function () {
                  return $.post('/@api/df/order/all_arrival_count', {
                    order_id: box.sel('order->id'),
                    order_items: order_items,
                  });
                },
                function (data) {
                  if (!data.result) return $.alert(data.msg);
                  location.reload();
                },
              ),
            );
          }),
          $.on2('click', '.btn_order_company_pd_name', function (e) {
            const $order_item = $.closest(e.currentTarget, '.order_item');
            const order_item = box.sel($order_item);

            if (!order_item || !order_item.id)
              return $.alert('데이터가 잘못되었습니다. 새로고침 후 다시 사용해보세요.');

            return _p.go(
              $.put('/@api/order_item/company_pd_name', {
                order_item_id: order_item.id,
                company_pd_name: $.val($.find1($order_item, 'input[name="company_pd_name"]')),
              }),
              function (data) {
                if (!data.result) return $.alert('업체상품명 변경 실패');
                $.alert('업체상품명이 변경되었습니다.');
                box.set(
                  'order->_->order_items->(#' + data.order_item.id + ')->company_pd_name',
                  data.order_item.company_pd_name,
                );
              },
            );
          }),
          $.on2('click', '.order_item .btn_add_arrival_count', function (e) {
            const $order_item = $.closest(e.currentTarget, '.order_item');
            const order_item = box.sel($order_item);
            const add_arrival_count = $.val($.find1($order_item, 'input[name="add_arrival_count"]'));

            if (!order_item || !order_item.id)
              return $.alert('데이터가 잘못되었습니다. 새로고침 후 다시 사용해보세요.');
            if (add_arrival_count == 0) return $.alert('입고 수량을 입력해 주세요.');
            // if (order_item.order_count < order_item.arrival_count + add_arrival_count + order_item.cancel_count)
            //   return $.alert('발주 수량보다 입고 수량이 많게 입력되었습니다.');

            return _p.go(
              $.put('/@api/order_item/arrival', {
                order_item_id: order_item.id,
                add_arrival_count: add_arrival_count,
              }),
              function (data) {
                if (!data.result) return $.alert('수정 실패');
                box.extend2('order->_->order_items->(#' + data.order_item.id + ')', data.order_item);
                $.after(
                  $order_item,
                  G.$t_$order_detail_item(
                    box.sel('order->_->order_items->(#' + data.order_item.id + ')'),
                    box.sel('order'),
                  ),
                );
                $.remove($order_item);
              },
            );
          }),
          $.on2('click', '.order_item .btn_add_cancel_count', function (e) {
            const $order_item = $.closest(e.currentTarget, '.order_item');
            const order_item = box.sel($order_item);
            const cancel_count = $.val($.find1($order_item, 'input[name="cancel_count"]'));

            if (!order_item || !order_item.id)
              return $.alert('데이터가 잘못되었습니다. 새로고침 후 다시 사용해보세요.');
            if (cancel_count < 0) return $.alert('0 이상 입력해 주세요.');

            if (order_item.order_count < order_item.arrival_count + cancel_count)
              return $.alert('남은 입고 예정 수량보다 취소 수량이 많습니다.');

            return _p.go(
              $.put('/@api/order_item/cancel_count', {
                order_item_id: order_item.id,
                cancel_count: cancel_count,
              }),
              function (data) {
                if (!data.result) return $.alert('변경 실패');
                box.extend2('order->_->order_items->(#' + data.order_item.id + ')', data.order_item);
                $.after(
                  $order_item,
                  G.$t_$order_detail_item(
                    box.sel('order->_->order_items->(#' + data.order_item.id + ')'),
                    box.sel('order'),
                  ),
                );
                $.remove($order_item);
              },
            );
          }),
          $.on2('click', '.order_item .btn_order_item_delete', function (e) {
            const $order_item = $.closest(e.currentTarget, '.order_item');
            const order_item = box.sel($order_item);

            if (!order_item || !order_item.id)
              return $.alert('데이터가 잘못되었습니다. 새로고침 후 다시 사용해보세요.');

            return _p.go(
              $.confirm('제품을 발주 목록에서 삭제하시겠습니까?'),
              _p.if2(_p.idtt)(
                function () {
                  return $.delete('/@api/order_item/delete', {
                    order_item_id: order_item.id,
                  });
                },
                function (data) {
                  if (!data.result) return $.alert('삭제 실패');
                  box.remove2($order_item);
                  $.remove($order_item);
                },
              ),
            );
          }),
          $.on2('click', '.btn_order_delete', function (e) {
            const order = box.sel($.closest(e.currentTarget, '.df_order_detail'));

            return _p.go(
              $.confirm('발주서를 삭제하시겠습니까?'),
              _p.if2(_p.idtt)(
                function () {
                  return $.delete('/@api/order/delete', { order_id: order.id });
                },
                function (data) {
                  if (!data.result) return $.alert('삭제 실패');
                  location.href = '/df/order/list';
                },
              ),
            );
          }),
        ),
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'df.ready_order_list_frame',
    el_class: 'df_ready_order_list_frame',
    hide_frame_button_type: 'X',
    header_height: 60,
    always_remove: true,
    title: '발주 준비중 목록',
  });

  $.frame.defn_page({
    page_name: 'df.ready_order_list_page',
    tabs: [
      {
        tab_name: 'df.ready_order_list_tab',
        template: __(
          function (bp_stocks, task_ids) {
            return _p.go($.get('/@api/df/orders', { type: 'ready', limit: 'all' }), function (order_list) {
              box.set('ready_order_data', {
                bp_stocks: bp_stocks,
                task_ids: task_ids,
                order_list: order_list,
              });
              return order_list;
            });
          },
          _p.t(
            'ready_order_list',
            '\
          .df_ready_order_list[_sel="ready_order_data->order_list"]\
            table.df_table\
              tr\
                th 발주 이름\
                th 생성일\
                th 총수량\
                th 생성자\
                th 상태\
                th 선택\
              {{_sum(ready_order_list, ',
            _p.t(
              'order',
              '\
                tr.order[order_id="{{order.id}}" _sel="./(#{{order.id}})"]\
                  td {{order.name || "이름없음"}}\
                  td {{moment(order.created_at).format("YYYY-MM-DD HH:mm")}}\
                  td {{ _.go(order._.order_items, ',
              _p.reduce(function (count, order_item) {
                return count + _p.v(order_item, 'order_count');
              }, 0),
              ') }}\
                  td.user_name {{_.v(order, "_.user.name") || ""}}\
                  td 발주준비중\
                  td\
                    button[type="button"].btn_select 선택\
              ',
            ),
            ')}}\
        ',
          ),
        ),
        removed: function () {
          box.unset('ready_order_data');
        },
        appended: __(
          $.on('click', '.btn_select', function (e) {
            $.frame.open(
              {
                frame_name: 'df.order_editor_frame',
                page_name: 'df.order_editor_page',
                title: '재고 확인',
                closed: function (X, order) {
                  if (!order) return;
                  setTimeout(function () {
                    $.frame.close(order);
                  }, 1);
                },
              },
              {
                page_name: 'df.order_editor_page',
                tabs: [
                  {
                    tab_name: 'df.order_editor_tab',
                    data_func: function () {
                      return _p.mr(
                        box.sel('ready_order_data->bp_stocks'),
                        box.sel($.closest(e.currentTarget, '.order')),
                        box.sel('ready_order_data->task_ids'),
                      );
                    },
                  },
                ],
              },
            );
          }),
        ),
      },
    ],
  });
})();
