export const _DATA_NAMES = {
  DOSU_COLOR: 'dosu_color',
  DOSU_ORIGINAL_COLORS: 'dosu_colors_original',
  DOSU_VALIDATION: 'dosu_validation',
  DOSU_VALIDATION_COLORS: 'is_allowed_dosu_colors',
  DOSU_VALIDATION_COUNTS: 'is_allowed_dosu_counts',
  DOSU_INIT: 'dosu_init',
};

export const IMAGE_ANALYSIS = {
  EXTRACT_COLORS_MAX_COUNT: 10,
};

export const QUANTIZED_BIT_FOR_DOSU = 3;

export const ORIGINAL_DOSU_COLOR_OPTION = {
  dev_name: 'original',
  name: '원본',
  name_en: 'original',
  name_jp: '原本',
};

export const ORIGINAL_DOSU_COLOR_CODE_NAME = 'original';

export const COLOR_PICKER_CN = {
  selected: 'selected',
};
