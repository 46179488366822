import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPDosuF } from './module/OMPDosuF.js';

export const dosu_confirm = {
  isMoreThanLimitedDosu: async (bpf_id, cv_objs) => {
    const dosu_count = BpOptionF.biz.get.currentFace.maxDosuCount(bpf_id);
    const is_over_dosu_count = OMPDosuF.cvObj.get.usedDosuColorCodes(cv_objs)?.length > dosu_count;
    if (is_over_dosu_count) {
      const is_confirmed = await OMPCoreUtilF.confirm({
        content: OMPCoreUtilF.isMobile()
          ? TT('maker::dosu::confirm1_content_mobile', { dosu_count })
          : TT('maker::dosu::confirm1_content', { dosu_count }),
        title: TT('maker::dosu::confirm1_title'),
        ok: TT('maker::dosu::confirm1_ok'),
        cancel: TT('maker::dosu::confirm1_cancel'),
        style: {
          row_reverse: !OMPCoreUtilF.isMobile(),
          color_reverse: true,
          column: OMPCoreUtilF.isMobile(),
        },
      });
      return is_confirmed;
    }
  },
};
