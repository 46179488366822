import Swal from 'sweetalert2';
import { every, extend, go, html, map, strMap } from 'fxjs/es';
import { SVG_ICONS } from './svg_icons.js';
import { FRONT_SHIPPING_PROCESS_ALERT_TIMEOUT } from '../../S/constant.js';
import { downloadSingleAudioThenPlay } from '../../../Stock/Holding/F/Function/holding.js';
import { waybill_CJ_speech_data } from '../../../Stock/Holding/F/Constant/tts.js';
import { makeUrlLabelAztecCodeFromLambda } from '../../../Stock/Labeling/S/Function/utils.js';
import { waybillCJgo } from './waybill_cj_go.js';
import { $delegate } from 'fxdom/es';

export const overallDeliveryStatusAudioPlay = async (overall_delivery_status) => {
  switch (overall_delivery_status) {
    case 'all': {
      await downloadSingleAudioThenPlay({
        audio_data_title: 'waybill_CJ_audios',
        single_speech_data: waybill_CJ_speech_data.all_completed,
        audio_key: 'all_completed',
        is_play: true,
      });
      break;
    }
    case 'partial': {
      await downloadSingleAudioThenPlay({
        audio_data_title: 'waybill_CJ_audios',
        single_speech_data: waybill_CJ_speech_data.partial_completed,
        audio_key: 'partial_completed',
        is_play: true,
      });
      break;
    }
    case 'fail': {
      await downloadSingleAudioThenPlay({
        audio_data_title: 'waybill_CJ_audios',
        single_speech_data: waybill_CJ_speech_data.all_fail,
        audio_key: 'all_fail',
        is_play: true,
      });
      break;
    }
  }
};

const renderProgressBar = (el) => {
  const progress_els = el.getElementsByClassName('progress-status');
  if (progress_els.length > 0) {
    const progress_el = progress_els[0];
    const percent = progress_el.dataset?.percent;

    if (percent) {
      progress_el.style.background = `-webkit-linear-gradient(left, lightgreen 0%, lightgreen ${Math.max(
        0,
        Number(percent) - 2.5,
      )}%, yellow ${Math.min(Number(percent) + 2.5, 100)}%, yellow 100%)`;
    } else {
      throw new Error('Cannot get progress percent');
    }
  }
};

export const alertWaybillCJstatus = {
  process: async ({
    process_summary,
    title,
    icon,
    is_timer,
    width = 1300,
    complete_process_idx,
    stop_event_propagation,
  }) => {
    return Swal.fire(
      extend(
        {
          title,
          icon,
          width,
          html: `
        ${SVG_ICONS.delivery_process_icons}
        ${strMap((prc) => {
          //이미 운송장 번호가 존재하는 경우에는 노란색 모두 진행으로 표시
          return html` <div>
              ${prc.error.code === '1201' ? SVG_ICONS.process_999 : SVG_ICONS[`process_${prc.process_idx}`]}
            </div>
            <span
              >주문자[${prc.name}] - 주소[${prc.address} ${prc.address2}] -
              연락처[${prc.mobile1 || prc.mobile2}] - 배송번호[${prc.id}]
              ${prc.process_idx === complete_process_idx
                ? ` - 발급 송장번호[${prc.INVC_NO}]`
                : prc.waybill_number
                ? ` - 기존 송장번호[${prc.waybill_number}]`
                : ''}
            </span>
            <div style="font-size:small">
              ${prc.error.code === '0000'
                ? '정상 처리됨 ==>  택배 GO~! (づ ￣ ³￣)づ'
                : ` 문제 발생 [에러 코드:${prc.error.code}] - [원인: ${prc.error.message}]
              ${
                prc.server_message || prc.error?.server_message
                  ? `<br>${prc.server_message || prc.error.server_message}`
                  : ''
              }`}
            </div>
            <div>${SVG_ICONS.hoz_line}</div>`;
        }, process_summary)}
        `,
          confirmButtonText: '확인',
          stopKeydownPropagation: stop_event_propagation,
        },
        /* 송장이 출력되고 배송들이 모두 완료 처리된 경우 자동으로 팝업창 닫힘.
         *  아닌 경우에는 팝업 확인을 통해 닫기
         * */
        is_timer
          ? {
              timer: FRONT_SHIPPING_PROCESS_ALERT_TIMEOUT,
              timerProgressBar: true,
            }
          : {},
      ),
    );
  },
  process_fail: async (error, override = false, projection_id) => {
    if (error == null) return;
    if (!override) {
      const error_code = error.code;
      const fail_audio_key = error_code.slice(0, 2) === '99' ? '9900' : error.code;
      await downloadSingleAudioThenPlay({
        audio_data_title: 'waybill_CJ_audios',
        single_speech_data: waybill_CJ_speech_data[fail_audio_key],
        audio_key: '' + fail_audio_key,
        is_play: true,
      });
    }

    if (override) {
      // override 가능한 상태면 warning 을 거치지 않고 바로 운송장 발급
      await waybillCJgo({ projection_id, force: true });
    } else {
      Swal.fire({
        title: '배송 처리 불가',
        icon: 'error',
        stopKeydownPropagation: false,
        html: `<span>원인: ${error.message}<br>${error.server_message ?? ''}</span>`,
        imageUrl:
          'https://s3.marpple.co/files/u_1187078/2021/12/original/f486408a2c27f34c75d0515a8f7850f05bbdcad91.png',
        imageWidth: 200,
        confirmButtonText: '확인',
        showDenyButton: override,
        denyButtonText: '강제 배송 진행하기',
      }).then(async ({ isDenied }) => {
        if (isDenied) {
          Swal.fire({
            title: '강제 배송 처리 경고',
            icon: 'warning',
            html: `<span>병합 주문건의 모든 제작 태스크가 완료되지 않은 상태입니다.<br>본 주문에 대한 상품 제작은 확실히 완료되어<br>배송이 정말로 가능한가요?</span>`,
            stopKeydownPropagation: false,
            showConfirmButton: true,
            confirmButtonText: '그래도 진행',
            showCancelButton: true,
            cancelButtonText: '중단',
          }).then(async ({ isConfirmed }) => {
            isConfirmed && (await waybillCJgo({ projection_id, force: true }));
          });
        }
      });
    }
  },
  combined_shipping_tasks: async (projection_data, is_parent) => {
    const parent_shipping_task = projection_data.shipping_task;
    const children_shipping_task = go(
      projection_data.combined_children,
      map((combined_child) =>
        extend(combined_child.shipping_task, {
          is_urgent: combined_child.important,
          is_internal_urgent: combined_child.internal_important ?? false,
        }),
      ),
    );

    const is_all_combined_completed = go(
      projection_data.combined_children,
      every((child) => child.shipping_task.status === 'completed'),
    );
    if (is_all_combined_completed) {
      if (is_parent) {
        await downloadSingleAudioThenPlay({
          audio_data_title: 'waybill_CJ_audios',
          single_speech_data: waybill_CJ_speech_data.complete_combined,
          audio_key: 'complete_combined',
          is_play: true,
        });
      } else {
        await downloadSingleAudioThenPlay({
          audio_data_title: 'waybill_CJ_audios',
          single_speech_data: waybill_CJ_speech_data.applause,
          audio_key: 'applause',
          is_play: true,
        });
        await downloadSingleAudioThenPlay({
          audio_data_title: 'waybill_CJ_audios',
          single_speech_data: waybill_CJ_speech_data.complete_combined_goto_parent,
          audio_key: 'complete_combined_goto_parent',
          is_play: true,
        });
      }
    } else {
      const audio_key = is_parent ? 'remaining_combined_parent' : 'remaining_combined_child';
      await downloadSingleAudioThenPlay({
        audio_data_title: 'waybill_CJ_audios',
        single_speech_data: waybill_CJ_speech_data[audio_key],
        audio_key,
        is_play: true,
      });
    }

    return Swal.fire(
      extend(
        {
          title: '묶음 배송 처리 현황',
          stopKeydownPropagation: false,
          width: '80%',
          imageUrl: is_all_combined_completed
            ? '//s3.marpple.co/files/u_1187078/2021/12/original/cfaa989557cc1109db2889c13561712e89641a471.gif'
            : '//s3.marpple.co/files/u_1187078/2021/12/original/43d70c94b47386299f269d1c2b1fa3061b01e7001.png',
          imageWidth: is_all_combined_completed ? 300 : 150,
          html: makeParentChildrenHtml(parent_shipping_task, children_shipping_task),
          returnFocus: false,
          didRender: renderProgressBar,
        },
        !is_parent && is_all_combined_completed
          ? {
              backdrop: `
                rgba(0,0,123,0.4)
                url("//s3.marpple.co/files/u_1187078/2021/12/original/b44626c948108acfa22182ae2f529df161265ad41.gif")
                left top
                no-repeat
          `,
            }
          : {},
      ),
    );
  },
  shop_siblings_shipping_tasks: async (parent, children) => {
    /* 모든 merged 건들이 완료되었는지 음성 처리 분기 */
    if (parent.status === 'completed') {
      await downloadSingleAudioThenPlay({
        audio_data_title: 'waybill_CJ_audios',
        single_speech_data: waybill_CJ_speech_data.applause,
        audio_key: 'applause',
        is_play: true,
      });
      await downloadSingleAudioThenPlay({
        audio_data_title: 'waybill_CJ_audios',
        single_speech_data: waybill_CJ_speech_data.complete_merged,
        audio_key: 'complete_merged',
        is_play: true,
      });
    } else {
      await downloadSingleAudioThenPlay({
        audio_data_title: 'waybill_CJ_audios',
        single_speech_data: waybill_CJ_speech_data.remaining_merged,
        audio_key: 'remaining_merged',
        is_play: true,
      });
    }

    return Swal.fire(
      extend(
        {
          title: `배송 처리 현황<br>- ${parent.store_name} -`,
          stopKeydownPropagation: false,
          width: '80%',
          imageUrl:
            parent.status === 'completed'
              ? '//s3.marpple.co/files/u_1187078/2021/12/original/cfaa989557cc1109db2889c13561712e89641a471.gif'
              : '//s3.marpple.co/files/u_1187078/2021/12/original/5d0b1a734ba07f0e8cd38a6643c26950ad59ba5a1.png',
          imageWidth: parent.status === 'completed' ? 300 : 150,
          html: makeParentChildrenHtml(parent, children),
          animation: false,
          returnFocus: false,
          didRender: renderProgressBar,
          didOpen: (el) => {
            go(
              el,
              $delegate('click', '#shipping_task_statuses img.aztec_code', (e) => {
                const projection_id = e.currentTarget.dataset.id;
                if (projection_id != null) {
                  window.open(
                    `${location.origin}/projection/list?status=all&p_id=${projection_id}`,
                    '_blank',
                  );
                }
              }),
            );
          },
        },
        parent.status === 'completed'
          ? {
              backdrop: `
                rgba(0,0,123,0.4)
                url("//s3.marpple.co/files/u_1187078/2021/12/original/b44626c948108acfa22182ae2f529df161265ad41.gif")
                
                left top
                no-repeat
          `,
            }
          : {},
      ),
    );
  },
};

const markImportant = (data) => (data.is_urgent ? '&#x2605 ' : data.is_internal_urgent ? '&#x2606 ' : '');

const makeParentChildrenHtml = (parent, children) => {
  const totalChildrenLen = children.length;
  const completedChildrenLen = children.filter((child) => child.status === 'completed').length;
  const processRatio = Math.round((completedChildrenLen / totalChildrenLen) * 100);

  return html`
    <div id="shipping_task_statuses">
      <div class="progress-status" data-percent="${processRatio}">
        <p>배송 진행률 : ${processRatio} %</p>
      </div>
      <div class="shipping_label shipping_parent ${parent.status}">
        <span class="aztec_projection_id">${parent.projection_id}</span>
        <img
          class="aztec_code"
          src="${makeUrlLabelAztecCodeFromLambda(parent.projection_id)}"
          data-id="${parent.projection_id}"
          alt="2d code"
        />
      </div>
      <div class="shipping_childrens">
        ${strMap(
          (child) =>
            html`
              <div class="shipping_label shipping_child ${child.status}">
                <span class="aztec_projection_id">${markImportant(child)}${child.projection_id}</span>
                <img
                  class="aztec_code"
                  src="${makeUrlLabelAztecCodeFromLambda(child.projection_id)}"
                  data-id="${child.projection_id}"
                  alt="2d code"
                />
              </div>
            `,
          children,
        )}
      </div>
    </div>
  `;
};
