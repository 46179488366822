import { pick, go } from 'fxjs/es';
import { QrConstantS } from '../Constant/module/QrConstantS.js';
import querystring from 'querystring';

export const createImageUrl = (options) => {
  return go(
    options,
    pick(QrConstantS.GENERATE_PARAMS),
    (params) => QrConstantS.GENERATE_LAMBDA_URL + '?' + querystring.stringify(params),
  );
};
