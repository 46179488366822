import { compact, filter, flatMap, go, map, reject, unique } from 'fxjs/es';
import { getCvDesigns, getFlattenCvDesigns } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { OMPDosuConstantS } from '../../../../../OMP/Dosu/S/Constant/module/OMPDosuConstantS.js';
import { getBaseProductInMaker, getBpfOfFcanvas } from '../../../../../Maker/F/getSth.js';
import { NewMakerPropertyBpOptionS } from '../../S/Function/module/NewMakerPropertyBpOptionS.js';

export const dosuColors = {
  getCvObjHexCodes: (fcanvas) => {
    return go(
      getCvDesigns(fcanvas._objects),
      filter((cv_obj) => cv_obj._data?._visible || cv_obj.visible),
      getFlattenCvDesigns,
      flatMap((design) =>
        (design._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_ORIGINAL_COLORS] || []).concat(
          design._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR] || [],
        ),
      ),
      compact,
      map((hex_code) => hex_code.toUpperCase()),
      unique,
    );
  },
  getFcanvasPallets: (fcanvass, fcanvas) => {
    const base_product = getBaseProductInMaker();
    const color_codes = NewMakerPropertyBpOptionS.dosuColors.getHexCodes({
      bpf_id: fcanvas.bpf_id,
      base_product,
    });
    return go(
      fcanvass,
      filter((fcanvas) => {
        const bpf = getBpfOfFcanvas(fcanvas);
        return NewMakerPropertyBpOptionS.dosuColors.isUnlimited({ bpf_id: bpf.id, base_product });
      }),
      flatMap(dosuColors.getCvObjHexCodes),
      reject((color_code) => color_codes.includes(color_code)),
      unique,
    );
  },
};
