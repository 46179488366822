import { each, go, map } from 'fxjs/es';
import { getCvObj } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { minusStrokeWidth } from '../../../../Maker/F/Fcanvas/stroke.js';
import { NewMakerMaskingTapeF } from '../../../MaskingTape/F/Function/module/NewMakerMaskingTapeF.js';
import { getRealFcanvass } from '../../../../Maker/F/getSth.js';

export function activateDataFitToPrintWidth(cv_obj) {
  cv_obj._data.is_fitted_to_print_width = true;
  cv_obj.lockMovementX = true;
  cv_obj.lockRotation = true;
  cv_obj.lockScalingX = true;
  cv_obj.lockScalingY = true;
}

export function cancelSetFitToPrintWidth(cv_obj) {
  cv_obj._data.is_fitted_to_print_width = false;
  cv_obj.lockMovementX = false;
  cv_obj.lockRotation = false;
  cv_obj.lockScalingX = false;
  cv_obj.lockScalingY = false;
}

export const activateFitToPrintWidth = (cv_obj, fcanvas) => {
  const { left, width } = go(getCvObj(fcanvas._objects, 'cv_print_area'), minusStrokeWidth);
  cv_obj.setAngle(0);
  const prev_height = cv_obj.getHeight();
  cv_obj.scaleToWidth(width);
  const current_height = cv_obj.getHeight();

  cv_obj.set({ left, top: cv_obj.top - (current_height - prev_height) / 2 });
  activateDataFitToPrintWidth(cv_obj);
  cv_obj.setCoords();
  NewMakerMaskingTapeF.rotateCloneHorizontalCvObj(cv_obj);
  NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(cv_obj);
};

export const cancelFitToPrintWidth = (cv_obj) => {
  if (cv_obj._data.is_fitted_to_print_width) {
    cancelSetFitToPrintWidth(cv_obj);
  }
};
export const needFitToPrintWidth = (base_product) => {
  return [
    /*마스킹테이프*/
    5200, 5201, 5202, 5203, 5204, 5205, 5206, 5207, 5208 /*아웃도어 보틀*/, 5793, 5794, 5795, 5796,
  ].includes(base_product?.id);
};
export const initActiveFitToPrintWidth = (base_product) => {
  const is_need_fit_to_print_width = needFitToPrintWidth(base_product);
  go(
    getRealFcanvass(),
    map((fcanvas) => G.mp.maker.designs(fcanvas)),
    each((cv_objs) =>
      G.mp.maker.cv_objects_deep_each(cv_objs, (cv_obj) => {
        if (cv_obj._data?.clone_parent_cid) return;
        if (cv_obj._data?.cv_type === 'cv_image' && cv_obj._data.is_fitted_to_print_width) {
          if (is_need_fit_to_print_width) {
            activateFitToPrintWidth(cv_obj, cv_obj.canvas);
          } else {
            $.alert(T('maker::Message::it_is_not_product_for_fit_width_function'));
            cancelSetFitToPrintWidth(cv_obj);
          }
          cv_obj.canvas.renderAll();
        }
      }),
    ),
  );
};
