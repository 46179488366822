import { go, indexBy, sel, strMap } from 'fxjs/es';
import { $closest, $find } from 'fxdom/es';
import { SHIP_IMG } from '../../../../Shipping/Waybill/S/constant.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { COLLABO_TYPE_OBJ } from '../../S/constant.js';

const shipping_company_obj = indexBy(sel('id'), box.sel('shipping_companies'));
const makeShippingCompanyHtml = ({ shipping_company_id }) =>
  sel('name', shipping_company_obj[shipping_company_id]);
const makeShippingServiceHtml = ({ collabo_type }) => COLLABO_TYPE_OBJ[collabo_type];
const makeWaybillRecordsHtml = () =>
  go(
    $1('table.waybill_records tbody tr'),
    $.remove,
    () => $.get(`/@api/waybill_records`),
    strMap(
      (waybill_record) => legacyHtml`
        <tr>
          <td>${waybill_record._.user.name}</td>
          <td>${moment(waybill_record.download_at).format('YYYY월MM월DD일 A hh시mm분ss초 ')}</td>
          <td>${makeShippingCompanyHtml(waybill_record)}</td>
          <td>${makeShippingServiceHtml(waybill_record)}</td>
          <td>
            <a
              href="/projection/list?status=all&order_by=DESC&p_id=${waybill_record.projections.join(',')}"
              target="_blank"
            >
              주문서 ${waybill_record.projections.length}개 / 배송지 ${waybill_record._.shipping_len}개
            </a>
          </td>
          <td>
            <form action="/@api/waybill/download_at" class="waybill_form" method="POST">
              <input type="hidden" value="${waybill_record.id}" name="id" />
              <input type="hidden" value="${waybill_record.shipping_company_id}" name="shipping_company_id" />
              <input type="hidden" value="${waybill_record.collabo_type}" name="collabo_type" />
              <button type="submit">다운받기</button>
            </form>
          </td>
        </tr>
      `,
    ),
    (r) => $.html($1('table.waybill_records tbody'), r),
  );

export const defnWaybillRoute = () =>
  go(
    $1('#body'),
    $.on('change', 'select[name="shipping_company_id"]', (e) => {
      const form$ = $closest('form', e.currentTarget);
      const shipping_company_id = $.val(e.currentTarget);
      $find('input[type="file"], button', form$).disabled = !shipping_company_id;
      $.css($.attr($find('.shipping_company_img img', form$), 'src', SHIP_IMG[shipping_company_id] || ''), {
        height: shipping_company_id ? 30 : 0,
      });
    }),
    $.on('change', '#waybill_upload input[type="file"]', async (e) => {
      const form$ = $closest('form', e.currentTarget);
      const shipping_company_select$ = $find('select[name="shipping_company_id"]', form$);
      const shipping_company_id = $.val(shipping_company_select$);
      const result = await $.upload(e.currentTarget, {
        url: `/@api/waybill/upload`,
        is_multiple: true,
        data: {
          shipping_company_id,
        },
      });
      await $.alert(`총 ${result.length}개가 변경 되었습니다.`);
      $.val(shipping_company_select$, '');
      $.trigger(shipping_company_select$, 'change');
      await makeWaybillRecordsHtml();
    }),
    $.on('change', '#waybill_upload_dump input[type="file"]', async (e) => {
      const form$ = $closest('form', e.currentTarget);
      const shipping_company_select$ = $find('select[name="shipping_company_id"]', form$);
      const shipping_company_id = $.val(shipping_company_select$);
      const result = await $.upload(e.currentTarget, {
        url: '/@api/waybill/upload/waybill_number/dump',
        is_multiple: true,
        data: {
          shipping_company_id,
        },
      });
      await $.alert(`총 ${result.length}개가 변경 되었습니다.`);
      $.val(shipping_company_select$, '');
      $.trigger(shipping_company_select$, 'change');
    }),
    $.on('submit', '#waybill_down', (e) => {
      e.preventDefault();
      const shipping_company_id = $.val($.find1(e.currentTarget, 'select[name="shipping_company_id"]'));
      const collabo_type = $.val($.find1(e.currentTarget, 'select[name="collabo_type"]'));
      if (!shipping_company_id || !collabo_type) return alert('택배사와 타입을 모두 선택해 주세요.');
      e.currentTarget.submit();
    }),
    $.on('click', '#refresh_record', async () => {
      await makeWaybillRecordsHtml();
    }),
    makeWaybillRecordsHtml,
  );
