import axios from 'axios';

/**
 * F에서 호출하는 axios 함수를 래핑한 클래스
 */
export class SearchHistoryApiHelper {
  static getUri = () => `/${TT.lang}/@api/search/history`;

  /**
   * @return {Promise<SearchHistory>}
   */
  static async get() {
    return axios.get(this.getUri()).then((res) => res.data);
  }

  /**
   * @param {string[]} queries
   * @return {Promise<SearchHistory>}
   */
  static async post(queries) {
    return axios.post(this.getUri(), { queries }).then((res) => res.data);
  }

  /**
   * @param {string} query
   * @return {Promise<SearchHistory>}
   */
  static async delete(query) {
    return axios.delete(this.getUri(), { params: { q: query } }).then((res) => res.data);
  }

  /**
   * @return {Promise<SearchHistory>}
   */
  static async flush() {
    return axios.delete(`${this.getUri()}/all`).then((res) => /** @type {SearchHistory} */ res.data);
  }
}
