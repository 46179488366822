import {
  noop,
  pipe,
  tap,
  reject,
  sel,
  map,
  go,
  pluck,
  extend,
  find,
  constant,
  filter,
  identity,
  delay,
} from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function//module/UtilS.js';
import { makeReviewListScoreHtml } from '../../List/S/tmpl.js';
import { makeReviewWriteClauseHtml, makeReviewWriteHtml, makeReviewWriteImgListHtml } from '../S/tmpl.js';
import { frameOpenSignUpIn } from '../../../User/Login/F/tab.js';
export const defnReviewWriteClauseTab = () => ({
  tab_name: 'mp.review.write.clause',
  appended: $.on('click', '.footer button', pipe(noop, $.frame.back_page)),
  template: makeReviewWriteClauseHtml,
});
export const defnReviewWriteTab = () => ({
  tab_name: 'mp.review.write',
  inner_scroll_target: 'textarea',
  template: pipe(function (user_product) {
    const writing_review = {
      id: user_product.id,
      before_files: [],
      user_product,
      ordered_at:
        window.box.sel('projection->ordered_at') ||
        user_product._.projection.ordered_at ||
        user_product.customer_order_created_at,
      collabo_type: G.collabo_type,
    };
    window.box.set('writing_review', writing_review);
    return makeReviewWriteHtml(writing_review);
  }),
  appended: pipe(
    tap(function (el_don_tab) {
      if (G.is_pc_size()) $.add_class($.find1(el_don_tab, '#before_files'), 'swiper-no-swiping');
      el_don_tab.swiper = new Swiper('#before_files', {
        slidesPerView: 'auto',
      });
      const e = $.find1(el_don_tab, '#write_review_form');
      G.mp.maker.draw_product_face2(e, window.box.sel(e).user_product._.product_color);
    }),
    $.on('change', '.clause #clause', (e) =>
      $.attr($.next(e.currentTarget, 'span'), 'is_checked', e.currentTarget.checked),
    ),
    $.on('click', '.clause_view', () => $.frame.add_page({ page_name: 'mp.review.write.clause' })),
    $.on2('click', 'button.continue', () => $.frame.remove(true)),
    $.on('click', '.review_score .star', function (e) {
      if (G.is_mobile_size()) return;
      const select = $.prev($.closest(e.currentTarget, '.review_score'), 'select');
      $.find1(select, 'option[value="' + $.attr(e.currentTarget, 'rate') + '"]').selected = true;
      $.trigger(select, 'change');
    }),
    $.on('change', '#write_review_form .score_wrap select[name="score"]', (e) =>
      $.html($.next(e.currentTarget, '.review_score'), makeReviewListScoreHtml($.val(e.currentTarget))),
    ),
    $.on('click', '#before_files button.remove_before_file', function (e) {
      const don_before_file = $.closest(e.currentTarget, '.before_file');
      const don_before_files = $.closest(don_before_file, '#before_files');
      const before_file = window.box.sel(don_before_file);
      window.box.set(
        don_before_files,
        reject((bf) => bf.id == before_file.id, window.box.sel(don_before_files)),
      );
      const swiper = $.closest(e.currentTarget, '.don_tab').swiper;
      swiper.removeSlide($.find(don_before_files, '.before_file').indexOf(don_before_file));
      const review_file_upload = $.find1(don_before_files, '#review_file_upload');
      return swiper.appendSlide($.show(review_file_upload));
    }),
    $.on2('click', 'form#write_review_form #write', async function (e) {
      const form = $.closest(e.currentTarget, 'form#write_review_form');
      const comment = $.val($.find1(form, 'textarea'));
      const files = pluck('file', window.box.sel($.find1(form, '#before_files')));
      if (G.collabo_type == 'creator' && !files.length) return $.alert(T('review::사진을 올려 주세요.'));
      if ((sel('length', UtilS.trim(comment)) || 0) < 5) return $.alert(T('review::Minimum of 5 words'));
      if ((sel('length', UtilS.trim(comment)) || 0) > 2000)
        return $.alert(T('review::Maximum of 2000 words'));
      if (G.collabo_type !== 'line' && !_p.val($.find1(form, 'input[name="is_agreement"]'), 'checked'))
        return $.alert('리뷰 활용 약관에 동의해 주세요.');
      const sns_url = $.val($.find1(form, '[name="sns_url"]'));
      if (sns_url && !linkify.test(sns_url)) return $.alert(T('review::Wrong sns'));
      $.don_loader_start();
      const user_product_id = $.val($.find1(form, 'input[type="hidden"][name="user_product_id"]'));
      try {
        await go(
          $.upload_exif(
            {
              files,
            },
            {
              max_resize: 1200,
              url: form.action,
              data: extend(
                {
                  comment,
                  score: $.val($.find1(form, '.score_wrap select')),
                },
                sns_url ? { sns_url } : {},
                { user_product_id },
              ),
            },
          ),
          function (result) {
            if (result == 'cancel') return;
            if (!result) return Promise.reject('');
            window.box.set(
              'writing_review',
              result.article_id
                ? {
                    article_id: result.article_id,
                  }
                : {},
            );
            $.show($.next(form, '.thankyou'));
            $.remove(form);
          },
        );
      } catch (err) {
        const message = sel('message', JSON.parse(err.response));
        if (message == 'Not signed in')
          return go($.alert(T('로그인 후 이용해 주세요.')), noop, frameOpenSignUpIn);
        else $.alert(message);
      }
      $.don_loader_end();
    }),
    $.on('change', '#review_file_upload input[type="file"]', async function (e) {
      if ($.valid_files(e.currentTarget)) return $.alert(T('review::Accepted File Types: JPEG, PNG, GIF'));
      const review_file_upload = $.closest(e.currentTarget, '#review_file_upload');
      if ($.has_class(review_file_upload, 'ing')) return false;
      const don_before_files = $.closest(review_file_upload, '#before_files');
      const total_len = $.find(don_before_files, '.before_file').length + e.currentTarget.files.length;
      $.add_class(review_file_upload, 'ing');
      e.currentTarget.disabled = true;
      $.don_loader_start();
      try {
        var a1 = await go(
          e.currentTarget.files,
          tap(function (files) {
            if (total_len > 5) return Promise.reject(T('review::Maximum of MAX photos', { max: 5 }));
            if (
              find(function (file) {
                return file.size > 10485760;
              }, files)
            )
              return Promise.reject(T('File size is too large', { mb: 10 }));
          }),
          map(function (file) {
            if (!file.type.match('image')) return;
            const before_file = { id: _p.unique_id('file'), file };
            const don_li = $.el(makeReviewWriteImgListHtml(before_file));
            $.attr($.find1(don_li, 'img'), 'loaded', false);
            window.box.push(don_before_files, before_file);
            return go(
              file,
              $.get_data_url(160),
              function (data_uri) {
                $.attr($.find1(don_li, 'img'), {
                  src: (before_file.url = data_uri),
                  loaded: true,
                });
              },
              constant(don_li),
            );
          }),
        );
      } catch (e) {
        a1 = await $.alert(e);
      }

      await go(
        a1,
        filter(identity),
        function (don_lis) {
          setTimeout(function () {
            e.currentTarget.type = '';
            e.currentTarget.type = 'file';
            $.don_loader_end();
          }, 0);
          e.currentTarget.disabled = false;
          if (!sel('length', don_lis)) return;
          const swiper = $.closest(e.currentTarget, '.don_tab').swiper;
          swiper.appendSlide(don_lis);
          if (total_len < 5) return swiper.appendSlide($.show(review_file_upload));
          return swiper.appendSlide($.hide(review_file_upload));
        },
        delay(500),
        function () {
          $.remove_class(review_file_upload, 'ing');
        },
      );
    }),
  ),
  showed: function (el_don_tab) {
    if (G.is_mobile_size())
      $.css($.find1(el_don_tab, '.thankyou'), {
        height: $.innerHeight(window) - 64,
      });
  },
});
