import { sel, strMap } from 'fxjs/es';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { makeBPFTemplateEditorHtml } from './template.js';
import { makeCylinderEditorHtml } from './cylinder.js';
import { makeShade2EditorHtml, makeShadeEditorHtml } from './shade.js';
import { makeCompositeEditorHtml } from './composite.js';

export const makeCvPreviewEditorHtml = ({ preview, sf }) => {
  const cv_print_area = sf.print.px;
  const template = sf.template;
  return legacyHtml`
    <div
      class="cv_preview_editor ${sel('cylinder', preview) ? 'is_cylinder' : ''} ${
    template ? 'is_template' : ''
  }  ${sel('shade', preview) ? 'is_shade' : ''}"
    >
      <div class="option row">
        <button type="button" class="hide">숨기기</button>
        <button type="button" class="zoom">zoom</button>
        <button type="button" class="save">저장</button>
        <button type="button" class="refresh">새로고침</button>
      </div>
      <div class="wrapper for_cv_print_area group1">
        <label for="">프린트 영역</label>
        <div class="wrapper">
          <div class="item row horizontal_func" key="cv_print_area">
            ${strMap(
              ({ key, title, value }) => legacyHtml`
                <div class="wrapper row cv_print_area_item">
                  <label for="">${title}</label>
                  <input type="number" name="${key}" value="${value || 0}" />
                </div>
              `,
              [
                { key: 'width', title: 'width', value: cv_print_area.width },
                { key: 'height', title: 'height', value: cv_print_area.height },
                { key: 'left', title: 'left', value: cv_print_area.left },
                { key: 'top', title: 'top', value: cv_print_area.top },
                { key: 'rx', title: 'radius_x', value: cv_print_area.rx },
                { key: 'ry', title: 'radius_y', value: cv_print_area.ry },
              ],
            )}
            <button type="button" class="horizontal_center">가로 중앙 정렬</button>
            <button type="button" class="save_cv_print_area">프린트 영역 적용</button>
          </div>
          <div class="wrapper row">
            <div class="item checkbox_input" key="is_shade">
              <label for="">음영</label>
              <input type="checkbox" ${sel('shade', preview) ? 'checked' : ''} />
            </div>
            <div class="item checkbox_input" key="shade2">
              <label for="">음영2</label>
              <input type="checkbox" ${sel('shade2', preview) ? 'checked' : ''} />
            </div>
            <div class="item checkbox_input" key="is_cylinder">
              <label for="">원통형</label>
              <input type="checkbox" ${sel('cylinder', preview) ? 'checked' : ''} />
            </div>
            <div class="item checkbox_input" key="is_composite">
              <label for="">합성</label>
              <input type="checkbox" ${sel('composite', preview) ? 'checked' : ''} />
            </div>
            <div class="item checkbox_input" key="is_template">
              <label for="">템플릿(현재 플떡랜드만 해당)</label>
              <input type="checkbox" ${template ? 'checked' : ''} />
            </div>
            <div class="item checkbox_input" key="is_multiply">
              <label for="">Multiply</label>
              <input type="checkbox" ${sel('multiply', preview) ? 'checked' : ''} />
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper template_editor group1">${template ? makeBPFTemplateEditorHtml(template) : ''}</div>
      <div class="wrapper group1 cylinder_editor">
        ${sel('cylinder', preview) ? makeCylinderEditorHtml(sel('cylinder', preview)) : ''}
      </div>
      <div class="wrapper shade_editor group1">
        ${sel('shade', preview) ? makeShadeEditorHtml(sel('shade', preview)) : ''}
      </div>
      <div class="wrapper shade2_editor group1">
        ${sel('shade2', preview) ? makeShade2EditorHtml(sel('shade2', preview)) : ''}
      </div>
      <div class="wrapper composite_editor group1">
        ${sel('composite', preview) ? makeCompositeEditorHtml(sel('composite', preview)) : ''}
      </div>
    </div>
  `;
};
