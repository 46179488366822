import { flatMap, go, map } from 'fxjs/es';
import { changeDpiBlob } from 'changedpi';
import { makePrintByBpsTemplateMeta, makeTitleByKeys } from './makePxToResultPx.js';
import { makeTiffBlob } from './makeTiff.js';

export const makeCanvasForPrint = ({ title, up_c_s_projection, product_faces2, all_faces_single_color }) => {
  const { template_meta } = up_c_s_projection._.base_product_size;
  return go(
    Array.isArray(template_meta)
      ? flatMap(
          (template_meta) =>
            makePrintByBpsTemplateMeta({
              template_meta,
              base_product_size_id: up_c_s_projection.base_product_size_id,
              base_product_color_id: up_c_s_projection.base_product_color_id,
              product_faces2,
              title,
            }),
          template_meta,
        )
      : makePrintByBpsTemplateMeta({
          template_meta,
          base_product_size_id: up_c_s_projection.base_product_size_id,
          base_product_color_id: up_c_s_projection.base_product_color_id,
          product_faces2,
          title,
        }),
    map(async ({ canvas, plus_title, template_meta }) => {
      const file_name = `${plus_title || ''}${
        typeof title === 'string' ? title : makeTitleByKeys(title, template_meta.text.file_name)
      }${all_faces_single_color?.name ? `_${all_faces_single_color?.name}` : ''}.${
        template_meta.mimetype.split('/')[1]
      }`;
      const canvas_blob =
        template_meta.mimetype === 'image/tiff'
          ? await makeTiffBlob(canvas, template_meta.dpi)
          : await new Promise((resolve) => {
              canvas.toBlob(
                (blob) => {
                  resolve(changeDpiBlob(blob, template_meta.dpi));
                },
                template_meta.mimetype,
                1,
              );
            });

      return {
        canvas_blob,
        file_name,
      };
    }),
  );
};
