$.frame.defn_frame({
  frame_name: 'mp.about_us',
  page_name: 'mp.about_us',
  header_height: G.is_pc_size() ? 0 : 60,
  appended: function () {
    if (!G.is_pc_size()) {
      $.css($1('.main.section .img'), {
        height: ($.width(window) * 240) / 375,
      });
    }
  },
});
