export const status_keys = [
  'communication_settings',
  'paper_out_flag',
  'pause_flag',
  'label_length',
  'number_of_formats_in_receive_buffer',
  'buffer_full_flag',
  'communications_diagnostic_mode_flag',
  'partial_format_flag',
  'unused',
  'corrupt_RAM_flag',
  'under_temperature_flag',
  'over_temperature_flag',
  'function_settings',
  'unused',
  'head_up_flag',
  'ribbon_out_flag',
  'thermal_transfer_mode_flag',
  'print_mode',
  'print_width_mode',
  'label_waiting_flag',
  'labels_remaining_in_batch',
  'format_while_printing_flag',
  'number_of_graphic_images_stored_in_memory',
  'password',
  'static_RAM_installation',
];

export const baud_table = {
  '000': '110',
  '001': '300',
  '010': '600',
  '011': '1200',
  100: '2400',
  101: '4800',
  110: '9600',
  111: '19200',
};

export const print_mode_table = {
  0: 'Rewind',
  1: 'Peel-Off',
  2: 'Tear-Off',
  3: 'Cutter',
  4: 'Applicator',
  5: 'Delayed cut',
  6: 'Linerless Peel',
  7: 'Linerless Rewind',
  8: 'Partial Cutter',
  9: 'RFID',
  K: 'Kiosk',
  S: 'Stream',
};

export const label_test_data = [
  {
    projection_id: 9885845,
    printed_at: false,
    ordered_at: '2021-10-06T00:11:40.057Z',
    created_at: '2021-10-13T05:43:52.916Z',
    due_at: '2021-10-07T09:00:00.000Z',
    is_urgent: true,
    shipping_type: '택배',
    stock_confirmed_at: '2021-10-07T00:21:31.680Z',
    confirm_task_status: 'on',
    is_cancel_requested: true,
    order_category: 'Marpple',
    bp_c_ss: [
      {
        supplier: '가나다라마바',
        color: '블랙',
        size: 'S',
        size_no: 1,
        bp_name: '프린트스타 17수 라운드 반팔(남녀공용)123456789아마니아지아니아',
        bp_id: 4112,
        press_types: [],
        qty_bp: 2,
        supplier_priority: 1,
        up_no: 1,
      },
      {
        supplier: '1가2나3다',
        color: '블랙(치키12)',
        size: 'MLJDS(EW)',
        size_no: 2,
        bp_name: '프린트스타 17수 라운드 반팔(남녀공용)394라운드 반팔(남녀공용)9아아라',
        bp_id: 4112,
        press_types: [],
        qty_bp: 121,
        supplier_priority: 1,
        up_no: 2,
      },
      {
        supplier: '39타다자',
        color: '블랙버드아리',
        size: 'L큰거23박',
        size_no: 3,
        bp_name: '프린트스타프린트스타프린트스타프린트스타프린트스타프린트스타',
        bp_id: 4112,
        press_types: [],
        qty_bp: 6329,
        supplier_priority: 1,
        up_no: 3,
      },
      {
        supplier: '톰스',
        color: '블랙29(화이)',
        size: 'XL',
        size_no: 4,
        bp_name: '프린트스타 17수 라운드 반팔(남녀공용)',
        bp_id: 4112,
        press_types: [],
        qty_bp: 12345,
        supplier_priority: 1,
        up_no: 4,
      },
    ],
    press_type: 'HOLDING+외준내셔알+dkw아그피나이+ksd오123로롱',
    qty_label: 9321,
    supplier_priority: 1,
    priority_supplier: '톰스',
    scan_info: [885845, 1, 1],
    no: 1,
    search_mode: 'period',
    is_check: true,
  },
  {
    projection_id: 886444,
    printed_at: false,
    ordered_at: '2021-10-06T08:05:06.012Z',
    created_at: '2021-10-13T05:43:52.935Z',
    due_at: '2021-10-08T09:00:00.000Z',
    is_urgent: false,
    shipping_type: '택배',
    stock_confirmed_at: '2021-10-07T00:18:54.987Z',
    confirm_task_status: 'completed',
    is_cancel_requested: true,
    order_category: 'Marpple',
    bp_c_ss: [
      {
        supplier: '톰스',
        color: '딥네이비',
        size: 'M',
        size_no: 2,
        bp_name: '젤란 라이트 후드(남녀공용)',
        bp_id: 4114,
        press_types: [
          {
            id: 218,
            data: {
              no: 13,
              tag: 'PRESS',
              name: '디티에프',
              priority: 3,
            },
            is_hidden: false,
            created_at: '2021-04-05T02:32:07.118Z',
            updated_at: '2021-05-14T01:39:48.927Z',
            simple_group_id: 2,
          },
        ],
        qty_bp: 1,
        supplier_priority: 1,
        up_no: 1,
      },
    ],
    press_type: 'PRESS + 외12전사 + abPREasds323wFK123QDTPDTF',
    qty_label: 9,
    supplier_priority: 1,
    priority_supplier: '톰스',
    scan_info: [886444, 1, 1],
    no: 5,
    search_mode: 'period',
    is_check: true,
  },
];
