import { $find, $trigger, $val } from 'fxdom/es';
import { MPS_SMS_TYPE } from '../../../../modules/Creator/Sms/S/constant.js';
import { eventSmsImageUpload } from '../../../../modules/Df/Sms/Df/F/event.js';
import { makeSmsSendReceiveItemDFHtml } from '../../../../modules/Df/Sms/Df/S/tmpl.js';
import { checkPhoneNumberSms } from '../../../../modules/Sms/Fs/S/fs.js';
import { msgSelectBoxEvent } from '../../../../modules/Df/Sms/Df/F/msg_select_box.js';
import { tap } from 'fxjs/es';
import { NESS_SMS_TYPE } from '../../../../modules/Sms/Ness/B/constant.js';

export default function (lo) {
  if (!$1('.kakao_surem_send')) return;

  $.frame({
    frame_name: 'df.kakao_surem_send',
  });

  $.frame.defn_frame({
    frame_name: 'df.kakao_surem_send',
    page_name: 'df.kakao_surem_send_page',
    // hide_frame_button_type: 'X',
    appended: function () {},
  });

  $.frame.defn_page({
    page_name: 'df.kakao_surem_send_page',
    tabs: [
      {
        tab_name: 'df.kakao_surem_send_tab',
        appended: __(
          tap(msgSelectBoxEvent),
          $.on('change', 'input[name="sms_type"]', function (e) {
            const cont = $.find1(e.delegateTarget, '.ks_send_area');
            $.attr(cont, 'sms_type', $.val(e.currentTarget));
          }),
          $.on('keyup', 'textarea', function (e) {
            const val = $.val(e.$currentTarget);
            const byte_txt_el = $.find1($.closest(e.$currentTarget, '.control'), 'p .byte_txt');
            const txt1_el = $.find1($.closest(e.$currentTarget, '.control'), 'p .txt1');

            const abc = (val.length * 9 - encodeURIComponent(val).length) / 8; // 영문숫자의 개수
            const msg_byte = parseInt((val.length - abc) * 2 + abc, 10); // euc-kr 바이트 구하기

            $.html(byte_txt_el, msg_byte);

            if (msg_byte > 90) $.show(txt1_el);
            else $.hide(txt1_el);
          }),
          $.on('keydown', 'input[name="receive_num"]', function (e) {
            e.keyCode == 13 &&
              $.trigger($.find1($.closest(e.currentTarget, '.receive_area'), '.add_number'), 'click');
          }),
          $.on('click', '.add_number', function (e) {
            const el_receive = $.closest(e.currentTarget, '.receive_area');
            const ul = $.find1(el_receive, '.receive_list');
            const dialing_code = $.val($.find1(el_receive, '.dialing_code'));
            const input_receive_num = $.find1(el_receive, 'input[name="receive_num"]');
            let number = $.val(input_receive_num);
            number = number.replace(/[^0-9]/g, '');
            number = checkPhoneNumberSms(number) ? number.substring(1) : number;

            if (_p.is_empty(number)) return $.alert('번호를 입력해 주세요.');

            $.append(ul, makeSmsSendReceiveItemDFHtml(`${dialing_code}-${number}`));
            $.val(input_receive_num, '');
            input_receive_num.focus();
          }),
          $.on('click', '.receive_list .btn_del', function (e) {
            $.remove($.closest(e.currentTarget, 'li'));
          }),
          $.on('click', '.btn_add_img', function (e) {
            $trigger('click', $find('input[name="sms_img_upload"]', e.delegateTarget));
          }),
          $.on('change', 'input[name="sms_img_upload"]', eventSmsImageUpload),

          $.on2('click', '.btn_send', function (e) {
            const send_el = e.$delegateTarget;
            const sms_type = $val($find('.radio_sms_type:checked', send_el));

            let caller_number1 = '';
            let caller_number2 = '';
            let caller_number3 = '';
            let collabo_type = '';
            let etc_sms_type = '';

            if (sms_type == '') {
              collabo_type = '';
              caller_number1 = '1566';
              caller_number2 = '9437';
            } else if (sms_type == 'creator') {
              collabo_type = 'creator';
              caller_number1 = '1566';
              caller_number2 = '5496';
            } else if (sms_type == NESS_SMS_TYPE.NESS) {
              collabo_type = 'creator';
              etc_sms_type = NESS_SMS_TYPE.NESS;
              caller_number1 = '1566';
              caller_number2 = '7960';
            } else if (sms_type == NESS_SMS_TYPE.NESS_PRIME) {
              collabo_type = 'creator';
              etc_sms_type = NESS_SMS_TYPE.NESS_PRIME;
              caller_number1 = '1566';
              caller_number2 = '2180';
            } else if (sms_type == MPS_SMS_TYPE.SHOPFREECA) {
              collabo_type = 'creator';
              caller_number1 = '070';
              caller_number2 = '7815';
              caller_number3 = '0009';
            } else if (sms_type == 'direct') {
              collabo_type = '';
              caller_number1 = $.val($.find1(send_el, 'input.caller_number1'));
              caller_number2 = $.val($.find1(send_el, 'input.caller_number2'));
              caller_number3 = $.val($.find1(send_el, 'input.caller_number3'));
            } else {
              return $.alert('보내는 번호를 선택하거나 직접 입력해 주세요.');
            }

            const receive_list = $.find(send_el, 'ul.receive_list li');
            const msg = $.val($.find1(send_el, 'textarea'));

            if (!receive_list.length) return $.alert('번호를 추가 후 이용해 주세요.');

            if (_p.is_empty(msg)) {
              $.alert('보낼 메시지를 입력하세요.');
              return;
            }

            return _p.go(
              $.post('/@api/sms2/send', {
                caller_number1,
                caller_number2,
                caller_number3,
                etc_sms_type,
                receive_numbers: _p.map(receive_list, (li) => {
                  return $.text($.find1(li, '.num'));
                }),
                msg,
                collabo_type,
              }),
              (data) => {
                if (!data || !data.result) return $.alert('전송 실패 다시 요청해 주세요.');
                return $.alert('전송되었습니다.');
              },
            );
          }),
        ),
      },
    ],
  });
}
