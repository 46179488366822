export const CREATOR_CUSTOMER_TEL = '1566-5496';
export const CREATOR_CUSTOMER_SMS = '15665496';

export const SHOPFREECA_TEL = '070-7815-0009';
export const SHOPFREECA_SMS = '07078150009';

export const MPS_SMS_TYPE = {
  SHOPFREECA: 'SHOPFREECA',
  MARPPLE_SHOP: 'MARPPLE_SHOP',
};

export const __shopfreecaSuremCode = {
  usercode: 'marketpress',
  deptcode: '24-6ZB-V2',
  from: CREATOR_CUSTOMER_SMS,
  url: 'https://api.surem.com/alimtalk/v2/json',
  yellowid_key: 'f9941c0834fad30c2e1725fa758908014f2d43d1',
};

export const shopfreecaSuremCode = {
  usercode: 'marketpress1',
  deptcode: 'D9-6I6-A3',
  from: SHOPFREECA_SMS,
  url: 'https://rest.surem.com/intl/text',
};
