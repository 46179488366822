import { flatMap, each, find, go, pluck, reject, sel, map, compact, unique } from 'fxjs/es';

import { NewMakerPropertyBpfConstantS } from '../../NewMaker/Property/Bpf/S/Constant/module/NewMakerPropertyBpfConstantS.js';
import { NewMakerPropertyBpfS } from '../../NewMaker/Property/Bpf/S/Function/module/NewMakerPropertyBpfS.js';
import { UtilS } from '../../Util/S/Function/module/UtilS.js';
import { NewMakerCvObjectUtilS } from '../../NewMaker/CvObject/Util/S/Function/module/NewMakerCvObjectUtilS.js';
import axios from 'axios';
import { parse as svgParser } from 'svgson';
import { ConfigSentryF } from '../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { VectorEditorConstantS } from '../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';

export const excludingDiscontinued = (bpss, discountinued_size_ids) => {
  return go(
    bpss,
    reject((bp) => discountinued_size_ids.includes(bp.id)),
  );
};

export const discontinuedSizeIds = (product_color, base_product_colors) =>
  go(
    base_product_colors,
    find((bpc) => bpc.id === product_color.base_product_color_id),
    sel('_.discontinued_sizes'),
    pluck('id'),
  );

export const soldOutSizeIds = (product_color, base_product_colors) =>
  go(
    base_product_colors,
    find((bpc) => bpc.id === product_color.base_product_color_id),
    sel('_.sold_out_sizes'),
    pluck('id'),
  );

function resetFaces(faces, bpcfs, base_product_color) {
  each(function (face) {
    const bpcf = find((bpcf) => bpcf.base_product_face_id == face.bpf_id, bpcfs);
    if (!bpcf) return;
    face.cv_bpcf.src = bpcf.url || '';
    face.cv_mask1.src = bpcf.mask_url || '';
    face.cv_mask2.src = bpcf.mask2_url || '';
    face.cv_shading.src = bpcf._.base_product_face.shading_url || '';
    if (face.cv_preview?._data?.shade_material) {
      const shade_material = face.cv_preview?._data?.shade_material;
      if (shade_material)
        NewMakerPropertyBpfS.common.shadeMaterial.changeColorData({
          shade_material,
          base_product_color,
        });
      if (
        !shade_material.name ||
        shade_material.name === NewMakerPropertyBpfConstantS.BPC_COLOR_CODE2_RENDER
      ) {
        NewMakerPropertyBpfS.bpcColorCode2Render.changeColorData(face, base_product_color);
      }
    }
  }, faces);
}

export function changeBpc2(product_color, base_product_color) {
  product_color.base_product_color_id = base_product_color.id;
  const bpcfs = base_product_color._.base_product_color_faces;
  resetFaces(sel('product_faces2.value', product_color), bpcfs, base_product_color);
  if (sel('product_faces.value', product_color))
    resetFaces(sel('product_faces.value', product_color), bpcfs, base_product_color);
}

export async function getUsedImageIdsFromSvgProductMaterials(svg_product_materials) {
  if (UtilS.isEmpty(svg_product_materials)) return [];

  return go(
    svg_product_materials,
    map(async (svg_product_material) => {
      const svg_file_url = svg_product_material.original_svg_file_url;
      if (svg_file_url == null) return null;
      return fetchSvgAndParse(svg_file_url);
    }),
    flatMap(extractImageIds),
    compact,
    unique,
  );
}

export function getUsedImageIdsFromPf2(pf2) {
  const values = pf2?.value;

  if (UtilS.isEmpty(values)) return [];

  return go(
    values,
    flatMap((value) =>
      go(
        value.designs,
        NewMakerCvObjectUtilS.getFlattenCvDesigns,
        map((design) => design?._data?.image_id),
      ),
    ),
    compact,
    unique,
  );
}

async function fetchSvgAndParse(svg_file_url) {
  try {
    const response = await axios.get(UtilS.ensureProtocol(svg_file_url), { responseType: 'text' });
    const svgText = response.data;

    return parseSvgText(svgText);
  } catch (error) {
    ConfigSentryF.error(error);
    return null;
  }
}

export async function parseSvgText(svgText) {
  if (UtilS.isEmpty(svgText)) return null;
  if (typeof svgText !== 'string') return null;

  return svgParser(svgText);
}

export function extractImageIds(parsedSvg) {
  if (parsedSvg == null) return [];

  const imageIds = [];

  const traverse = (node) => {
    const imageDataKey = `${VectorEditorConstantS.VECTOR_EL_DATA_PREFIX}image_id`;
    const imageId = node.attributes[imageDataKey];
    if (node.name === 'image' && imageId) {
      imageIds.push(parseInt(imageId));
    }
    if (node.children) {
      node.children.forEach(traverse);
    }
  };

  traverse(parsedSvg);
  return imageIds;
}
