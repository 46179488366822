import $dataStr from 'fxdom/es/dataStr.js';
import { compact, go, html, sel, strMap } from 'fxjs/es';
import { GoodsTypeS } from '../../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { MpShopProductTmplS } from '../../../../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { creatorPriceOfProduct } from '../../../../../../ProductColorPrice/S/fs.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppAdultTmplS } from '../../../../Adult/S/Tmpl/module/MShopAppAdultTmplS.js';
import { MShopAppProductItemS } from '../Function/module/MShopAppProductItemS.js';

export const makeBpName = (name, title) => {
  if (!name) return '';
  return name + (title ? ` - ${title}` : '');
};

export const item =
  (sub_class = ['v1'], cus_class = '', data_src) =>
  (product) => {
    const {
      id,
      need_adult_check,
      thumbnails,
      name: _name = '',
      rank,
      ordered_at,
      _: {
        spo,
        selected_option_group: { title } = {},
        stores_product: {
          goods_type_id,
          sell_start_at,
          sell_end_at,
          quantity,
          is_quantity_public,
          order_count,
          id: stores_product_id,
          _: {
            stores_products_token_gates = [],
            stores_products_like = {},
            store: { domain_name = '', name: store_name = '', logo_img } = {},
          } = {},
        } = {},
        base_product,
        base_product: { thumbnail_ratio, base_product_color_face_url, name: base_product_name } = {},
      } = {},
    } = product || { _: {} };
    const name = UtilS.trim(_name);
    const bp_name = makeBpName(base_product_name, title) || '';
    const badge = MShopAppProductItemS.setBadge(
      {
        spo_item_stocks: spo?._?.spo_item_stocks,
        sell_start_at,
        sell_end_at,
        quantity,
        order_count,
        ordered_at,
        is_quantity_public,
        is_sptg: !!stores_products_token_gates.length,
      },
      false,
    );
    const product_post_msg = {
      screen: {
        type: 'util',
        title: T('mshop::상품상세'),
        uri: `/${T.lang}/${domain_name}/products/${id}`,
      },
    };
    const store_post_msg = {
      screen: {
        type: 'util',
        title: store_name,
        titleImage: logo_img,
        uri: `/${T.lang}/${domain_name}`,
      },
    };
    const cate_item_name = sel('name' + G._en)(base_product?._?.cate_list);
    const img_alt = go(void 0, () => {
      const _name = name || base_product_name;
      if (UtilS.isMarppleShopApp() && T.lang == 'kr') {
        return `${store_name} ${cate_item_name}, ${_name} 굿즈, 굿즈 판매, 굿즈샵`;
      } else {
        return `${store_name} ${cate_item_name}, ${_name}`;
      }
    });

    return html`
      <div
        class="app-product-item${strMap((sc) => ` app-product-item--${sc}`, compact(sub_class))} ${cus_class}"
      >
        <div class="app-product-item__top-info">
          <a href="${product_post_msg.screen.uri}" data-post-message="${$dataStr(product_post_msg)}">
            <div class="app-product-item__img">
              ${need_adult_check
                ? html`<span class="mp-product-img-bg-wrap" style="background-color: #f6f6f6">
                    ${MShopAppAdultTmplS.adultItem()}
                  </span>`
                : MpShopProductTmplS.thumbnail(
                    { thumbnail_ratio, thumbnails, base_product_color_face_url },
                    {
                      width: 600,
                      data_src,
                      img_alt,
                    },
                  )}
            </div>
            ${badge
              ? html`
                  <div class="app-product-item__badge app-product-item__badge--${badge.type}">
                    ${badge.name}
                  </div>
                `
              : ''}
          </a>
          ${!need_adult_check
            ? html`
                <button
                  class="app-product-item__like"
                  data-stores_product_id="${stores_product_id}"
                  data-already_like="${!!stores_products_like?.id}"
                >
                  <div
                    class="app-product-item__heart${stores_products_like?.id
                      ? ' app-product-item__heart--on'
                      : ''}"
                  ></div>
                </button>
              `
            : ''}
        </div>
        <div class="app-product-item__bottom-info">
          ${rank
            ? `
              <a
                href="${store_post_msg.screen.uri}"
                data-post-message="${$dataStr(store_post_msg)}"
                class='app-product-item__rank'
                
              >
                <span>${rank}</span>
              </a>
            `
            : ''}
          <a
            href="${store_post_msg.screen.uri}"
            data-post-message="${$dataStr(store_post_msg)}"
            class="app-product-item__store-name"
          >
            ${store_name}
          </a>
          <a href="${product_post_msg.screen.uri}" data-post-message="${$dataStr(product_post_msg)}">
            <div class="app-product-item__p-name">
              ${GoodsTypeS.isMps(goods_type_id)
                ? (!need_adult_check && name) || bp_name || '&nbsp;'
                : name || '&nbsp;'}
            </div>
            <div class="app-product-item__category-name">${cate_item_name}</div>
            ${product.id
              ? `<div class="app-product-item__price mp_currency2">${UtilS.commify(
                  creatorPriceOfProduct(product),
                )}</div>`
              : ''}
          </a>
        </div>
      </div>
    `;
  };
