import axios from 'axios';
import {
  $append,
  $closest,
  $delegate,
  $el,
  $find,
  $qs,
  $removeAttr,
  $replaceWith,
  $setAttr,
  $setVal,
  $remove,
} from 'fxdom/es';
import { entries, map, each, find, go, object, reject, tap } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPReviewWriteConstantS } from '../../S/Constant/module/OMPReviewWriteConstantS.js';
import { OMPReviewWriteTmplS } from '../../S/Tmpl/module/OMPReviewWriteTmplS.js';
import { productRateStarsTmpl } from '../../S/Tmpl/reviewWriteTmplPc.js';
import { OMPReviewWriteF } from './module/OMPReviewWriteF.js';

const MAX_REVIEW_FILE_LENGTH = 5;
export const event = (tab_el) => {
  let review_files = [];

  go(
    tab_el,
    $delegate('click', '.omp-review-write__product-rate-star', (e) => {
      const $score = $qs('input[name="score"]');
      $setVal(e.currentTarget.dataset.rate, $score);

      const $wrapper = $closest('.omp-review-write__product-rate-star-wrapper', e.currentTarget);
      $replaceWith($el(productRateStarsTmpl({ rate: Number(e.currentTarget.dataset.rate) })), $wrapper);
    }),
    $delegate('change', '.omp-review-write__photo-wrapper input[name="file"]', async (e) => {
      const $input = e.currentTarget;
      const $wrapper = $closest('.omp-review-write__photo-wrapper', e.currentTarget);
      try {
        OMPReviewWriteF.validFiles($input);
        $input.disabled = true;
        $.don_loader_start();

        await go(
          $input.files,
          tap((files) => {
            if (review_files.length + files.length > MAX_REVIEW_FILE_LENGTH) {
              throw new OMPReviewWriteConstantS.MyPageReviewWriteError(
                TT('error::review::write_02', { max: MAX_REVIEW_FILE_LENGTH }),
              );
            }

            if (find((file) => file.size > 10485760, files)) {
              throw new OMPReviewWriteConstantS.MyPageReviewWriteError(
                TT('error::review::write_03', { mb: 10 }),
              );
            }
          }),
          each(async (file) => {
            if (!file.type.match('image')) return;
            const array = new Uint32Array(3);
            const id = crypto.getRandomValues(array).join('-');
            const file_with_id = { id, file };
            review_files = [...review_files, file_with_id];
            const data_uri = await $.get_data_url(160)(file);
            const $photos = $find('.omp-review-write__photos', $wrapper);
            const $review_image = go(
              file_with_id,
              OMPReviewWriteTmplS.makeReviewImgListHtml,
              $el,
              tap($find('img'), $setAttr({ src: (file.url = data_uri), loaded: true })),
            );

            $append($review_image, $photos);
          }),
        );

        if (review_files.length) {
          $setAttr({ 'data-style_gap': 8 }, $wrapper);
        } else {
          $removeAttr('data-style_gap', $wrapper);
        }

        $input.disabled = false;
        $.don_loader_end();
      } catch (e) {
        $input.disabled = false;
        $.don_loader_end();
        await OMPReviewWriteF.catchError(e);
      }
    }),
    $delegate('click', '.omp-review-write__photo-remove', async (e) => {
      const $wrapper = $closest('.omp-review-write__photo-container', e.currentTarget);
      review_files = reject((review_file) => review_file.id === $wrapper.dataset?.id, review_files);
      $remove($wrapper);
    }),
    $delegate('submit', '.omp-review-write', async (e) => {
      e.originalEvent.preventDefault();
      try {
        const review_data = go(new FormData(e.currentTarget).entries(), object);
        if (OMPCoreUtilS.trim(review_data.comment)?.length < 5) {
          throw new OMPReviewWriteConstantS.MyPageReviewWriteError(TT('error::review::write_04'));
        }
        if (OMPCoreUtilS.trim(review_data.comment)?.length > 2000) {
          throw new OMPReviewWriteConstantS.MyPageReviewWriteError(TT('error::review::write_05'));
        }
        if (review_data?.agree_marketing !== 'on') {
          throw new OMPReviewWriteConstantS.MyPageReviewWriteError(TT('error::review::write_06'));
        }
        if (review_data.sns_url && !linkify.test(review_data.sns_url)) {
          throw new OMPReviewWriteConstantS.MyPageReviewWriteError(TT('error::review::write_07'));
        }
        if (find((file) => file.size > 10485760, review_files)) {
          throw new OMPReviewWriteConstantS.MyPageReviewWriteError(TT('error::review::write_03', { mb: 10 }));
        }

        $.don_loader_start();

        const form_data = new FormData();
        await go(
          review_files,
          map((file) => ({ file: file.file, name: file.file.name })),
          each((val) => {
            form_data.append('files[]', val.file, val.name);
          }),
        );

        go(
          review_data,
          entries,
          reject(([key]) => key === 'file'),
          each(([key, val]) => {
            form_data.append(key, val);
          }),
        );

        const res = await axios.post(`/${TT.lang}/@api/review/write`, form_data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        $.don_loader_end();

        await OMPCoreUtilF.alert({
          content: TT('review::write::write_10'),
        });
        MuiF.closeFrame(res.data);
      } catch (e) {
        $.don_loader_end();
        await OMPReviewWriteF.catchError(e);
      }
    }),
  );
};
