import { filter, go } from 'fxjs/es';
import { getPfByFcanvas } from '../../../../Maker/F/getSth.js';
import { NewMakerFcanvasFuncF } from '../../../Fcanvas/Func/F/Function/module/NewMakerFcanvasFuncF.js';
import { isSameCanvas } from './utils.js';

/**
 * 제품 전면 영역 이탈 체크
 * */
export async function isAnyCornerOutOfBounds(cv_target) {
  const pf = getPfByFcanvas(cv_target.canvas);
  const width = 400;
  const base_canvas = await NewMakerFcanvasFuncF.makeCanvasToCheckWithAnyCvObs({
    cv_objs: [pf.cv_mask1],
    width,
  });

  const target_canvas = await NewMakerFcanvasFuncF.makeCanvasToCheckWithAnyCvObs({
    cv_objs: go(
      pf.designs,
      filter((design) => design.cid === cv_target.cid),
    ),
    width,
  });

  const base_canvas_ctx = base_canvas.getContext('2d');
  base_canvas_ctx.globalCompositeOperation = 'source-in';
  base_canvas_ctx.drawImage(target_canvas, 0, 0);
  return isSameCanvas(base_canvas, target_canvas);
}
