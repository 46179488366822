export default function (lo) {
  if (!$1('html.dev_image')) return;
  let _infi = null;
  const dev_id = $.attr($1('#dev_image'), 'dev_id');

  function dev_image_infinite() {
    const images = $1('#dev_image .images');
    return _p.go(
      $.frame.infi(images, {
        template: images_tmpl,
        limit: 36,
        data_func: function (offset, limit) {
          return _p.go(
            $.get('/@api/dev_image/' + dev_id, {
              offset,
              limit,
            }),
            function (imgs) {
              window.box.push.apply(null, [images].concat(imgs));
              return imgs;
            },
          );
        },
      }),
      function (infi) {
        _infi = infi;
      },
    );
  }

  function images_tmpl(image) {
    return pug`
    .image.infi_item[_sel="./(#${image.id})"]
      .img
        img[src=${G.to_150(image.url)}]
      .info
        .original_name ${image.original_name}              
        .created_at ${moment(image.created_at).format('YYYY.MM.DD HH:mm')}
        .copy url 복사`;
  }

  _p.go(
    $1('#dev_image'),
    $.on(
      'change',
      '#dev_image_upload',
      __(
        _p.val('currentTarget'),
        _p($.upload, _p, {
          url: '/@fileUpload/dev_image/images/' + dev_id,
        }),
        function (images) {
          _infi.prepend(images, window.box.sel('images'));
        },
      ),
    ),
    $.on(
      'click',
      '.image .info',
      __(_p.val('currentTarget'), window.box.sel, _p.val('url'), G.copy_clipboard),
    ),
    $.on(
      'click',
      '.image .img',
      __(_p.val('currentTarget'), window.box.sel, _p.val('url'), (url) => window.open('https://' + url)),
    ),
    $.on('click', '.remove button', function () {
      if (confirm('선택되어있는 영역을 삭제하시겠습니까??'))
        _p.go($.del('/@api/dev_image/remove/' + dev_id), () => location.reload());
    }),
    _p.tap(dev_image_infinite),
  );
}
