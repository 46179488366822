import { each, go, noop, tap } from 'fxjs/es';
import { $find, $findAll, $on, $setCss, $setHTML } from 'fxdom/es';
import axios from 'axios';
import { VectorEditorKeyringFreePCMuiS } from '../../S/Mui/module/VectorEditorKeyringFreePCMuiS.js';
import { VectorEditorFontF } from '../../../../../Font/F/Function/module/VectorEditorFontF.js';
import { PCRightFixedPanelF } from '../../../../../../PCRightFixedPanel/F/Function/module/PCRightFixedPanelF.js';
import { CommonNS, EditorNS } from '@marpple/sticker-editor';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';
import { VectorEditorKeyringFreePCF } from '../Function/module/VectorEditorKeyringFreePCF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorKeyringFreePCMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  async appending(tab_el) {
    tab_el.__mp_title = tab_el.__mp_title ?? '';
    tab_el.__mp_price = tab_el.__mp_price ?? '';
    tab_el.__mp_frame_position_top = tab_el.__mp_frame_position_top ?? 0;
    tab_el.__mp_frame_position_height = tab_el.__mp_frame_position_height ?? 0;
    tab_el.__mp_prev_right_panel_el = tab_el.__mp_prev_right_panel_el ?? null;
    tab_el.__mp_keyring_editor = null;
    tab_el.__mp_art_board_width = tab_el.__mp_art_board_width ?? 0;
    tab_el.__mp_art_board_height = tab_el.__mp_art_board_height ?? 0;
    tab_el.__mp_art_board_shape_path_data = tab_el.__mp_art_board_shape_path_data ?? `M 0 0 Z`;
    tab_el.__mp_empty_template_el = tab_el.__mp_empty_template_el ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_preProcess = tab_el.__mp_preProcess ?? null;
    tab_el.__mp_fonts = null;

    go(
      tab_el,
      tap($findAll(`.right_container .right_panel_home .title`), each($setHTML(tab_el.__mp_title))),
      tap($findAll(`.right_container .right_panel_home .price`), each($setHTML(tab_el.__mp_price))),
    );

    go(
      tab_el,

      tap(
        $findAll(`.right_container .button_container .cancel`),
        each($on('click', VectorEditorKeyringFreePCF.handleRightBottomCancelButtonClick())),
      ),
      tap(
        $findAll(`.right_container .button_container .next`),
        each($on('click', VectorEditorKeyringFreePCF.handleRightBottomNextButtonClick({ tab_el }))),
      ),

      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.TOGGLE_LAYERS_MODE.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuToggleLayerModeClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuDuplicateClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuDeleteClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuForwardClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuBackwardClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuGroupClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuUngroupClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuFlipHorizontalClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuFlipVerticalClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuMoveToLeftEndClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuMoveToHorizontalCenterClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuMoveToRightEndClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuMoveToTopEndClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuMoveToVerticalCenterClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID}"]`,
        ),
        each($on('click', VectorEditorKeyringFreePCF.handleTopMenuMoveToBottomEndClick({ tab_el }))),
      ),

      tap(
        $findAll(`.left_menu[data-icon_type="upload_image"]`),
        each($on('click', VectorEditorKeyringFreePCF.handleLeftMenuUploadImageIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.left_menu[data-icon_type="my_image"]`),
        each($on('click', VectorEditorKeyringFreePCF.handleLeftMenuMyImageIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.left_menu[data-icon_type="shape"]`),
        each($on('click', VectorEditorKeyringFreePCF.handleLeftMenuShapeIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.left_menu[data-icon_type="path"]`),
        each($on('click', VectorEditorKeyringFreePCF.handleLeftMenuPathIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.left_menu[data-icon_type="text"]`),
        each($on('click', VectorEditorKeyringFreePCF.handleLeftMenuTextIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.layer_container .header .close_layer`),
        each($on('click', VectorEditorKeyringFreePCF.handleLayerCloseClick())),
      ),

      tap(
        $findAll(`.editor_container`),
        each(
          $on('@mp/sticker-editor/select', VectorEditorKeyringFreePCF.handleKeyringEditorSelect({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.editor_container`),
        each(
          $on(
            '@mp/sticker-editor/unselect',
            VectorEditorKeyringFreePCF.handleKeyringEditorUnselect({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.editor_container`),
        each($on('@mp/sticker-editor/add', VectorEditorKeyringFreePCF.handleKeyringEditorAdd({ tab_el }))),
      ),
      tap(
        $findAll(`.editor_container`),
        each(
          $on('@mp/sticker-editor/remove', VectorEditorKeyringFreePCF.handleKeyringEditorRemove({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.editor_container`),
        each(
          $on(
            '@mp/sticker-editor/reorder',
            VectorEditorKeyringFreePCF.handleKeyringEditorReorder({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.editor_container`),
        each($on('@mp/sticker-editor/lock', VectorEditorKeyringFreePCF.handleKeyringEditorLock({ tab_el }))),
      ),
      tap(
        $findAll(`.editor_container`),
        each(
          $on('@mp/sticker-editor/unlock', VectorEditorKeyringFreePCF.handleKeyringEditorUnlock({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.editor_container`),
        each($on('@mp/sticker-editor/show', VectorEditorKeyringFreePCF.handleKeyringEditorShow({ tab_el }))),
      ),
      tap(
        $findAll(`.editor_container`),
        each($on('@mp/sticker-editor/hide', VectorEditorKeyringFreePCF.handleKeyringEditorHide({ tab_el }))),
      ),
      tap(
        $findAll(`.editor_container`),
        each(
          $on(
            '@mp/sticker-editor/transform',
            VectorEditorKeyringFreePCF.handleKeyringEditorTransform({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.editor_container`),
        each(
          $on(
            '@mp/sticker-editor/attr-change',
            VectorEditorKeyringFreePCF.handleKeyringEditorAttrChange({ tab_el }),
          ),
        ),
      ),
    );

    tab_el.__mp_fonts = await go(
      axios.get(`/${T.lang}/@api/maker/fonts`),
      (res) => res?.data,
      (fonts) => fonts ?? null,
    );
    await VectorEditorFontF.loadBaseFonts();
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  async appended(tab_el) {
    const keyring_editor = new EditorNS.AcrylicEditorNS.PCNS.AcrylicPCEditor({
      window,
      document,
    });
    tab_el.__mp_keyring_editor = keyring_editor;
    keyring_editor.initDOM();
    keyring_editor.initEditor();
    keyring_editor.setArtBoardSize({
      width: tab_el.__mp_art_board_width,
      height: tab_el.__mp_art_board_height,
    });
    keyring_editor.setArtBoardShapePath({ path_data: tab_el.__mp_art_board_shape_path_data });
    keyring_editor.setArtBoardClipPathShapePath({ path_data: tab_el.__mp_art_board_shape_path_data });
    keyring_editor.turnOnArtBoardClipPath();
    const foreground_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
    if (tab_el.__mp_empty_template_el != null) {
      foreground_el.appendChild(tab_el.__mp_empty_template_el);
    }
    keyring_editor.setForeground(foreground_el);

    const editor_container_el = $find('.editor_container')(tab_el);
    if (editor_container_el == null) {
      const error = new Error(`editor_container 클래스를 가진 엘리먼트를 찾을 수 없습니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Free::PC::error_message::no_editor_container_el',
      );
      throw error;
    }
    keyring_editor.appendTo(editor_container_el);

    try {
      await tab_el.__mp_preProcess?.({ addEl: ({ el }) => keyring_editor.addEl(el) });
    } catch (error) {
      console.error(error);
      const message =
        error?.__mp_alert_message ??
        T('modules::VectorEditor::Keyring::Free::PC::error_message::no_pre_process');
      $.alert(message).catch(noop);
    }
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    $setCss(['top', `${tab_el.__mp_frame_position_top}px`])(tab_el);
    $setCss(['height', `${tab_el.__mp_frame_position_height}px`])(tab_el);

    if (tab_el.__mp_keyring_editor == null) {
      const error = new Error(`tab_el.__mp_keyring_editor 객체가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Free::PC::error_message::no_keyring_editor',
      );
      throw error;
    }
    const editor = tab_el.__mp_keyring_editor;

    editor.fitSize();
    const view_box_vo = editor.calculateViewBoxFitToArtBoard({
      padding_in_vcs: { top: 180, right: 300, bottom: 180, left: 300 },
    });
    editor.setViewBox(view_box_vo);

    return (() => {
      const right_el = $find(`.right_container .right_wrapper`)(tab_el) ?? document.createElement('div');
      const left_el = tab_el.__mp_prev_right_panel_el ?? document.createElement('div');
      return PCRightFixedPanelF.showPcRightFixedPanel({ right_el, left_el });
    })();
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, _v) {
    return (() => {
      const right_el = $find(`.right_container .right_wrapper`)(tab_el) ?? document.createElement('div');
      const left_el = tab_el.__mp_prev_right_panel_el ?? document.createElement('div');
      return PCRightFixedPanelF.hidePcRightFixedPanel({ right_el, left_el });
    })();
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, _v) {
    if (tab_el.__mp_keyring_editor != null) {
      const editor = tab_el.__mp_keyring_editor;
      editor.destroy();
    }

    tab_el.__mp_title = '';
    tab_el.__mp_price = '';
    tab_el.__mp_frame_position_top = 0;
    tab_el.__mp_frame_position_height = 0;
    tab_el.__mp_prev_right_panel_el = null;
    tab_el.__mp_keyring_editor = null;
    tab_el.__mp_art_board_width = 0;
    tab_el.__mp_art_board_height = 0;
    tab_el.__mp_art_board_shape_path_data = `M 0 0 Z`;
    tab_el.__mp_empty_template_el = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_preProcess = null;
    tab_el.__mp_fonts = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
