import { each, eachC, equals2, filterL, go, head, mapL, reduce, takeL } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { CommonNS } from '@marpple/sticker-editor';

export const handleTopMenuToggleLayerModeClick =
  ({ tab_el }) =>
  () => {
    go(
      tab_el,
      $findAll(`.left_container .layer_container`),
      takeL(1),
      each((layer_container_el) => {
        const is_layer_mode = equals2(layer_container_el.dataset.is_show)('true');
        if (is_layer_mode) {
          layer_container_el.dataset.is_show = 'false';
        } else {
          layer_container_el.dataset.is_show = 'true';

          return (async () => {
            $.don_loader_start();
            try {
              await go(
                layer_container_el,
                $findAll(`.layer_item`),
                eachC(
                  (layer_item_el) =>
                    new Promise((resolve, reject) =>
                      requestAnimationFrame(() => {
                        try {
                          const bbox = layer_item_el.__mp_clone_el.getBBox();
                          const matrix =
                            CommonNS.UtilNS.getConsolidatedTransformMatrix(layer_item_el.__mp_clone_el) ??
                            layer_item_el.__mp_svg_el.createSVGMatrix();
                          const view_box = go(
                            [
                              [bbox.x, bbox.y],
                              [bbox.x + bbox.width, bbox.y],
                              [bbox.x + bbox.width, bbox.y + bbox.height],
                              [bbox.x, bbox.y + bbox.height],
                            ],
                            mapL(CommonNS.UtilNS.createSVGPoint(layer_item_el.__mp_svg_el)),
                            mapL((point) => point.matrixTransform(matrix)),
                            (points) =>
                              reduce(
                                (acc, { x, y }) => {
                                  acc.min_x = Math.min(acc.min_x, x);
                                  acc.min_y = Math.min(acc.min_y, y);
                                  acc.max_x = Math.max(acc.max_x, x);
                                  acc.max_y = Math.max(acc.max_y, y);
                                  return acc;
                                },
                                { min_x: Infinity, min_y: Infinity, max_x: -Infinity, max_y: -Infinity },
                                points,
                              ),
                            ({ min_x, min_y, max_x, max_y }) => {
                              const fit_width = max_x - min_x;
                              const fit_height = max_y - min_y;
                              const x_margin = fit_width / 10;
                              const y_margin = fit_height / 10;
                              return `${min_x - x_margin} ${min_y - y_margin} ${fit_width + x_margin * 2} ${
                                fit_height + y_margin * 2
                              }`;
                            },
                          );
                          layer_item_el.__mp_svg_el.setAttributeNS(null, 'viewBox', view_box);
                          layer_item_el.__mp_stroke_sync.sync();
                          resolve();
                        } catch (error) {
                          reject(error);
                        }
                      }),
                    ),
                ),
              );
            } finally {
              $.don_loader_end();
            }
          })();
        }
      }),
    );
  };

export const handleTopMenuDuplicateClick =
  ({ tab_el }) =>
  () => {
    const selected_els = tab_el.__mp_single_sticker_editor?.getSelectedEls?.();
    if (selected_els == null) {
      return;
    }

    const selected_count = selected_els.size;
    if (selected_count <= 0 || selected_count > 1) {
      return;
    }

    const el = head(selected_els);
    if (el == null) {
      return;
    }

    const clone_el = tab_el.__mp_single_sticker_editor?.copyEl(el);
    tab_el.__mp_single_sticker_editor?.selectEls([clone_el]);
  };

export const handleTopMenuDeleteClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    each((el) => editor.removeEl(el))(editor.getSelectedEls());
  };

export const handleTopMenuForwardClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    go(
      editor.getSelectedEls(),
      takeL(1),
      each((el) => editor.moveForward(el)),
    );
  };

export const handleTopMenuBackwardClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    go(
      editor.getSelectedEls(),
      takeL(1),
      each((el) => editor.moveBackward(el)),
    );
  };

export const handleTopMenuGroupClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    const els = [...editor.getSelectedEls()];
    if (els.length <= 1) {
      return;
    }

    const group_el = editor.groupEls(els);
    editor.selectEls([group_el]);
  };

export const handleTopMenuUngroupClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    const group_el = go(
      editor.getSelectedEls(),
      filterL((el) => editor.getIsGroupEl(el)),
      head,
    );
    if (group_el == null) {
      return;
    }

    const ungrouped_els = editor.ungroupEl(group_el);
    editor.selectEls(ungrouped_els);
  };

export const handleTopMenuFlipHorizontalClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    each((el) => editor.flipHorizontal(el))(editor.getSelectedEls());
  };

export const handleTopMenuFlipVerticalClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    each((el) => editor.flipVertical(el))(editor.getSelectedEls());
  };

export const handleTopMenuMoveToLeftEndClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    editor.alignToLeftEnd([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToHorizontalCenterClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    editor.alignToHorizontalCenter([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToRightEndClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    editor.alignToRightEnd([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToTopEndClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    editor.alignToTopEnd([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToVerticalCenterClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    editor.alignToVerticalCenter([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToBottomEndClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    const editor = tab_el.__mp_single_sticker_editor;

    editor.alignToBottomEnd([...editor.getSelectedEls()]);
  };
