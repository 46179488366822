//language=glsl
export const image_editor_vertex_src = `

    attribute vec2 a_position;
    attribute vec2 a_tex_coord;
    uniform float u_flipY;
    uniform mat2 u_rotation;
    uniform vec2 u_shift;
    varying vec2 v_tex_coord;

    void main() {
        vec2 clip_space = a_position * 2.0 - 1.0;
        gl_Position = vec4(clip_space * vec2(1.0, u_flipY), 0, 1);
        v_tex_coord = (a_tex_coord - 0.5) * u_rotation + u_shift + 0.5;
    }
`;
//language=glsl
export const image_editor_fragment_src = `
    precision highp float;
    uniform sampler2D u_image;
    uniform vec2 u_res;
    varying vec2 v_tex_coord;
    uniform mat4 u_color_multiplier;
    uniform vec4 u_color_shifter;
    uniform float u_emboss_strength;
    uniform float u_sharp_blur_size;
    uniform float u_noise_strength;
    uniform float u_vibrance_strength;
    uniform float u_gamma_strength;
    uniform float u_darken;
    uniform float u_brighten;

    vec3 clamping(vec3 color) {
        color.r = clamp(color.r, 0.0, 1.0);
        color.g = clamp(color.g, 0.0, 1.0);
        color.b = clamp(color.b, 0.0, 1.0);
        return color;
    }

    vec3 embossing(float emboss_str) {
        vec3 convoluted_color;
        float b = emboss_str;
        float c = emboss_str * 2.0;
        convoluted_color += -c * texture2D(u_image, v_tex_coord + vec2(-1, -1) / u_res).rgb;
        convoluted_color += -b * texture2D(u_image, v_tex_coord + vec2(0, -1) / u_res).rgb;
        convoluted_color += -b * texture2D(u_image, v_tex_coord + vec2(-1, 0) / u_res).rgb;
        convoluted_color += texture2D(u_image, v_tex_coord + vec2(0, 0) / u_res).rgb;
        convoluted_color += b * texture2D(u_image, v_tex_coord + vec2(1, 0) / u_res).rgb;
        convoluted_color += b * texture2D(u_image, v_tex_coord + vec2(0, 1) / u_res).rgb;
        convoluted_color += c * texture2D(u_image, v_tex_coord + vec2(1, 1) / u_res).rgb;
        return convoluted_color;
    }

    vec3 kerneling() {
        if (u_sharp_blur_size == 0.0 && u_emboss_strength == 0.0) {
            return texture2D(u_image, v_tex_coord).rgb;
        } else {
            vec3 emboss_pixel = u_emboss_strength == 0.0 ? texture2D(u_image, v_tex_coord).rgb : embossing(3.0 * u_emboss_strength);
            vec3 sharp_blur_pixel;
            if (u_sharp_blur_size != 0.0) {
                const float two_PI = 6.2831853;
                const float kernel_spread = 4.0;
                const float angle_step = two_PI / kernel_spread;
                float blur_size = u_sharp_blur_size * 15.0;
                vec2 radius = blur_size / u_res.xy;
                const vec2 pos1 = vec2(cos(0.0), sin(0.0));
                const vec2 pos2 = vec2(cos(angle_step), sin(angle_step));
                const vec2 pos3 = vec2(cos(angle_step * 2.0), sin(angle_step * 2.0));
                const vec2 pos4 = vec2(cos(angle_step * 3.0), sin(angle_step * 3.0));
                sharp_blur_pixel = texture2D(u_image, v_tex_coord).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos1 * radius * 0.2).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos1 * radius * 0.4).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos1 * radius * 0.6).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos1 * radius * 0.8).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos1 * radius * 1.0).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos2 * radius * 0.2).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos2 * radius * 0.4).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos2 * radius * 0.6).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos2 * radius * 0.8).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos2 * radius * 1.0).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos3 * radius * 0.2).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos3 * radius * 0.4).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos3 * radius * 0.6).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos3 * radius * 0.8).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos3 * radius * 1.0).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos4 * radius * 0.2).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos4 * radius * 0.4).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos4 * radius * 0.6).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos4 * radius * 0.8).rgb;
                sharp_blur_pixel += texture2D(u_image, v_tex_coord + pos4 * radius * 1.0).rgb;
                sharp_blur_pixel /= 21.0;
            } else {
                sharp_blur_pixel = texture2D(u_image, v_tex_coord).rgb;
            }
            if (u_sharp_blur_size > 0.0) {
                sharp_blur_pixel = 2.0 * texture2D(u_image, v_tex_coord).rgb - sharp_blur_pixel;
            }
            vec3 result_pixel = clamping((sharp_blur_pixel + emboss_pixel) / 2.0);
            return result_pixel;
        }
    }

    float color_mix(float color, float max_color, float amt) {
        if (color != max_color) {
            color += (max_color - color) * amt;
            color = clamp(color, 0.0, 1.0);
        }
        return color;
    }

    vec3 vibrance(vec3 input_rgb) {
        if (u_vibrance_strength == 0.0) {
            return input_rgb;
        } else {
            float avg_color = (input_rgb.r + input_rgb.g + input_rgb.b) / 3.0;
            float max_color = max(input_rgb.r, max(input_rgb.g, input_rgb.b));
            float v = abs(max_color - avg_color) * 5.0 * (-u_vibrance_strength);
            input_rgb.r = color_mix(input_rgb.r, max_color, v);
            input_rgb.g = color_mix(input_rgb.g, max_color, v);
            input_rgb.b = color_mix(input_rgb.b, max_color, v);
            return input_rgb;
        }
    }

    float rand(vec2 coord) {
        const float u_rand_b = 78.223;
        const float u_rand_c = 43758.5453;
        return fract(sin(dot(coord.xy, vec2(23.456 * u_noise_strength, u_rand_b))) * u_rand_c);
    }

    vec3 noise(vec3 input_rgb) {
        if (u_noise_strength == 0.0) {
            return input_rgb;
        } else {
            float n = rand(v_tex_coord.xy);
            const float coeff = 0.65;
            return mix(input_rgb, vec3(n, n, n), coeff * u_noise_strength);
        }
    }

    vec3 gamma(vec3 input_rgb) {
        if (u_gamma_strength == 0.0) {
            return input_rgb;
        } else {
            float gamma = pow(2.2, u_gamma_strength);
            return pow(input_rgb, vec3(gamma, gamma, gamma));
        }
    }

    float getGrayscale(vec3 input_rgb)
        {
            return dot(input_rgb, vec3(0.2126, 0.7152, 0.0722));
        }
    
    vec3 curve(vec3 input_rgb) {
        if (u_darken == 0.0 && u_brighten == 0.0) {
            return input_rgb;
        } else {
            const float scaling = 3.0;
            const float PI = 3.14159265359;
            float grayscale = getGrayscale(input_rgb);
            //input값이 어두운 영역일 때
            if (grayscale < 0.5) {
                if (u_darken < 0.0) {
                    float adj = pow(sin(PI * grayscale), scaling * -u_darken);
                    return input_rgb * adj;
                } else if (u_darken > 0.0) {
                    float adj = 2.0 - pow(sin(PI * grayscale), scaling * u_darken);
                    return input_rgb * adj;
                } else {
                    return input_rgb;
                }
            }
            //input값이 밝은 영역 일 때
            else if (grayscale < 1.0){
                if (u_brighten > 0.0) {
                    float adj = pow(sin(PI * grayscale), scaling * u_brighten);
                    return 1.0 - ((1.0 - input_rgb) * adj);
                } else if (u_brighten < 0.0) {
                    float adj = 2.0 - pow(sin(PI * grayscale), scaling * -u_brighten);
                    return 1.0 - ((1.0 - input_rgb) * adj);
                } else {
                    return input_rgb;
                }
            } else {
                return input_rgb;
            }
        }
    }

    void main() {
        vec3 kernel_pixel = kerneling();
        vec3 vib_pixel = vibrance(kernel_pixel);
        vec3 noise_pixel = noise(vib_pixel);
        vec3 gamma_pixel = gamma(noise_pixel);
        vec3 curve_pixel = curve(gamma_pixel);
        vec4 non_kernel_pixel = vec4(curve_pixel, texture2D(u_image, v_tex_coord).a) * u_color_multiplier + u_color_shifter;
        gl_FragColor = non_kernel_pixel;
    }
`;

//language=glsl
export const view_vertex_src = `

    attribute vec2 a_position;
    attribute vec2 a_tex_coord;
    uniform float u_flipY;
    varying vec2 v_tex_coord;

    void main() {
        vec2 clip_space = a_position * 2.0 - 1.0;
        gl_Position = vec4(clip_space * vec2(1.0, u_flipY), 0, 1);
        v_tex_coord = a_tex_coord;
    }
`;
//language=glsl

export const view_fragment_src = `
    precision mediump float;

    uniform bool u_crop_on;
    uniform sampler2D u_image;
    uniform vec4 u_crop_pos;
    uniform vec2 u_view_res;
    uniform float u_viewport_scale_ratio;
    uniform float u_outside_crop_color;
    uniform float u_outside_crop_alpha;
    uniform float u_grid_line_alpha;
    uniform float u_minor_grid_line_alpha;
    uniform float u_grid_thickness;
    
    uniform float u_vignette_strength;
    varying vec2 v_tex_coord;
    float avg_res_size = (u_view_res.x + u_view_res.y) / 2.0;

    vec3 vignetting(vec3 input_rgb) {
        if (u_vignette_strength == 0.0) {
            return input_rgb;
        } else {
            float crop_width = u_crop_pos.y - u_crop_pos.x;
            float crop_height = u_crop_pos.w - u_crop_pos.z;
            float adj_factor_a = pow(crop_width / u_view_res.x, 2.0);
            float adj_factor_b = pow(crop_height / u_view_res.y, 2.0);
            vec2 v_crop_coord;
            v_crop_coord.x = -(v_tex_coord.x - u_crop_pos.x / u_view_res.x) * (v_tex_coord.x - u_crop_pos.y / u_view_res.x) / adj_factor_a;
            v_crop_coord.y = -(v_tex_coord.y - u_crop_pos.z / u_view_res.y) * (v_tex_coord.y - u_crop_pos.w / u_view_res.y) / adj_factor_b;
            v_crop_coord = clamp(v_crop_coord, 0.0, 1.0);
            float vig = pow(v_crop_coord.x * v_crop_coord.y * (20.0), u_vignette_strength);
            vig = smoothstep(0.0, 1.0, vig);
            return mix(input_rgb, input_rgb * vig, u_vignette_strength);
        }
    }

    void main() {
        const float grid_line_color_multiplier = 1.8;
        const float grid_line_color_shifter = 0.3;
        const float corner_t = 3.0;
        const float corner_len = 40.0;
        float t_major = u_grid_thickness / u_viewport_scale_ratio;
        float t_minor = t_major / 1.5;
        float crop_width = u_crop_pos.y - u_crop_pos.x;
        float crop_height = u_crop_pos.w - u_crop_pos.z;
        float num_w = crop_width < u_view_res.x / 3.0 ? 0.0 : crop_width < u_view_res.x / 2.0 ? 1.0 : 2.0;
        float num_h = crop_height < u_view_res.y / 3.0 ? 0.0 : crop_height < u_view_res.y / 2.0 ? 1.0 : 2.0;
        float w_line_gap = (crop_width - 2.0 * t_major - num_w * t_minor) / (num_w + 1.0);
        float h_line_gap = (crop_height - 2.0 * t_major - num_h * t_minor) / (num_h + 1.0);
        float w_modulus = mod(v_tex_coord.x * u_view_res.x - u_crop_pos.x - t_major, w_line_gap + t_minor);
        float h_modulus = mod(v_tex_coord.y * u_view_res.y - u_crop_pos.z - t_major, h_line_gap + t_minor);
        vec4 texture_raw = texture2D(u_image, v_tex_coord);
//        vec3 vignette_pixel = vignetting(texture.rgb);
          vec4 texture = vec4(vignetting(texture_raw.rgb), texture_raw.a);
        //크롭 바깥 영역
        if (v_tex_coord.x < u_crop_pos.x / u_view_res.x
        || v_tex_coord.x > u_crop_pos.y / u_view_res.x
        || v_tex_coord.y < u_crop_pos.z / u_view_res.y
        || v_tex_coord.y > u_crop_pos.w / u_view_res.y
        ) {
            gl_FragColor = vec4(texture.rgb * u_outside_crop_color, texture.a < 0.1 ? mix(texture.a * u_outside_crop_alpha, u_crop_on ? 0.1 : texture.a, u_outside_crop_alpha) : texture.a * u_outside_crop_alpha);
        }
        // major grid line 영역
        //left
        else if (v_tex_coord.x <= (u_crop_pos.x + t_major * corner_t) / u_view_res.x) {
            vec3 adj_texture = mix(texture.rgb, (texture.r > 0.95 && texture.g > 0.95 && texture.b > 0.95) ? texture.rgb * 0.5 : texture.rgb, u_grid_line_alpha);
            vec3 color = adj_texture * mix(1.0, grid_line_color_multiplier, u_grid_line_alpha) + mix(0.0, grid_line_color_shifter, u_grid_line_alpha);
            float alpha = texture.a < 0.1 ? mix(texture.a, 0.5, u_grid_line_alpha) : texture.a;
            if (v_tex_coord.y <= (u_crop_pos.z + corner_len) / u_view_res.y
            || v_tex_coord.y >= (u_crop_pos.w - corner_len) / u_view_res.y) {
                gl_FragColor = vec4(color, alpha);
            } else {
                if (v_tex_coord.x <= (u_crop_pos.x + t_major) / u_view_res.x) {
                    gl_FragColor = vec4(color, alpha);
                } else {
                    gl_FragColor = texture;
                }
            }
        }
        //right
        else if (v_tex_coord.x >= (u_crop_pos.y - t_major * corner_t) / u_view_res.x) {
            vec3 adj_texture = mix(texture.rgb, (texture.r > 0.95 && texture.g > 0.95 && texture.b > 0.95) ? texture.rgb * 0.5 : texture.rgb, u_grid_line_alpha);
            vec3 color = adj_texture * mix(1.0, grid_line_color_multiplier, u_grid_line_alpha) + mix(0.0, grid_line_color_shifter, u_grid_line_alpha);
            float alpha = texture.a < 0.1 ? mix(texture.a, 0.5, u_grid_line_alpha) : texture.a;
            if (v_tex_coord.y <= (u_crop_pos.z + corner_len) / u_view_res.y
            || v_tex_coord.y >= (u_crop_pos.w - corner_len) / u_view_res.y) {
                gl_FragColor = vec4(color, alpha);
            } else
            {
                if (v_tex_coord.x >= (u_crop_pos.y - t_major) / u_view_res.x) {
                    gl_FragColor = vec4(color, alpha);
                } else {
                    gl_FragColor = texture;
                }
            }
        }
        //top
        else if (v_tex_coord.y <= (u_crop_pos.z + t_major * corner_t) / u_view_res.y) {
            vec3 adj_texture = mix(texture.rgb, (texture.r > 0.95 && texture.g > 0.95 && texture.b > 0.95) ? texture.rgb * 0.5 : texture.rgb, u_grid_line_alpha);
            vec3 color = adj_texture * mix(1.0, grid_line_color_multiplier, u_grid_line_alpha) + mix(0.0, grid_line_color_shifter, u_grid_line_alpha);
            float alpha = texture.a < 0.1 ? mix(texture.a, 0.5, u_grid_line_alpha) : texture.a;
            if (v_tex_coord.x <= (u_crop_pos.x + corner_len) / u_view_res.x
            || v_tex_coord.x >= (u_crop_pos.y - corner_len) / u_view_res.x) {
                gl_FragColor = vec4(color, alpha);
            } else
            {
                if (v_tex_coord.y <= (u_crop_pos.z + t_major) / u_view_res.y) {
                    gl_FragColor = vec4(color, alpha);
                } else {
                    gl_FragColor = texture;
                }
            }
        }
        //bottom
        else if (v_tex_coord.y >= (u_crop_pos.w - t_major * corner_t) / u_view_res.y) {
            vec3 adj_texture = mix(texture.rgb, (texture.r > 0.95 && texture.g > 0.95 && texture.b > 0.95) ? texture.rgb * 0.5 : texture.rgb, u_grid_line_alpha);
            vec3 color = adj_texture * mix(1.0, grid_line_color_multiplier, u_grid_line_alpha) + mix(0.0, grid_line_color_shifter, u_grid_line_alpha);
            float alpha = texture.a < 0.1 ? mix(texture.a, 0.5, u_grid_line_alpha) : texture.a;
            if (v_tex_coord.x <= (u_crop_pos.x + corner_len) / u_view_res.x
            || v_tex_coord.x >= (u_crop_pos.y - corner_len) / u_view_res.x) {
                gl_FragColor = vec4(color, alpha);
            } else
            {
                if (v_tex_coord.y >= (u_crop_pos.w - t_major) / u_view_res.y) {
                    gl_FragColor = vec4(color, alpha);
                } else {
                    gl_FragColor = texture;
                }
            }
        }

        // minor grid line 영역
        else if ((w_modulus >= w_line_gap && w_modulus <= w_line_gap + t_minor)
        || (h_modulus >= h_line_gap && h_modulus <= h_line_gap + t_minor))
        {
            vec3 adj_texture = mix(texture.rgb, (texture.r > 0.95 && texture.g > 0.95 && texture.b > 0.95) ? texture.rgb * 0.5 : texture.rgb, u_grid_line_alpha * u_minor_grid_line_alpha);
            vec3 color = adj_texture * mix(1.0, grid_line_color_multiplier / 1.5, u_grid_line_alpha * u_minor_grid_line_alpha) + mix(0.0, grid_line_color_shifter, u_grid_line_alpha * u_minor_grid_line_alpha);
            float alpha = texture.a < 0.1 ? mix(texture.a, 0.5, u_grid_line_alpha) : texture.a;
            gl_FragColor = vec4(color, alpha);
        }

        // 그 외 순수 이미지 영역
        else {
            gl_FragColor = texture;
        }

    }
  `;
