import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { NewMakerCvObjectCvBackgroundF } from '../Function/module/NewMakerCvObjectCvBackgroundF.js';
import { frame } from './frame.js';
import { getOrMakeCvBackground } from '../Function/GetOrMakeCvBackground.js';

export const openFrame = async (one_line_color_length) => {
  const cv_background = getOrMakeCvBackground();
  await MuiF.openFrame(frame, (frame, page, [colors_tab]) => {
    colors_tab.makeData = () => {
      return {
        colors: NewMakerCvObjectCvBackgroundF.makeTwoDepthColors(one_line_color_length),
        color_code: cv_background.fill,
      };
    };
  });
};
