import {
  drawProductFace,
  makeOnlyDesignFaceCanvasByPrintArea,
} from '../../../../../Maker/F/draw_product_faces.js';
import { find, go, ippL, map, mapC, range } from 'fxjs/es';

export const makeMaskingTapePreviewThumbnail = async ({
  product_face,
  base_product_size_id,
  canvas_width = 900,
}) => {
  if (!product_face) throw new Error('no product_face');
  if (!base_product_size_id) throw new Error('no base_product_size_id');
  const {
    print: { px: print_area },
  } = go(
    product_face.size_faces,
    find((sf) => sf.base_product_size_id === base_product_size_id),
  );
  const basic_canvas_width = 900;
  const ratio = canvas_width / basic_canvas_width;
  const print_area_width = print_area.width * ratio;
  const print_area_top = print_area.top * ratio;
  const print_area_left = print_area.left * ratio;
  const print_area_url = await go(
    makeOnlyDesignFaceCanvasByPrintArea({
      product_face,
      print_area,
      width: print_area_width,
    }),
    (canvas) => {
      const result_canvas = document.createElement('canvas');
      result_canvas.width = canvas.width * 3;
      result_canvas.height = canvas.height;
      const ctx = result_canvas.getContext('2d');
      ctx.drawImage(canvas, 0, 0);
      ctx.drawImage(canvas, canvas.width, 0);
      ctx.drawImage(canvas, canvas.width * 2, 0);
      return result_canvas.toDataURL();
    },
  );
  if (!print_area_url) throw new Error('print_area_url not created');
  const main_canvas = document.createElement('canvas');
  main_canvas.width = canvas_width;
  main_canvas.height = canvas_width;
  const main_cv_image_objs = await go(
    range(1),
    mapC(() => {
      return new Promise(function (resolve) {
        fabric.Image.fromURL(
          print_area_url,
          async function (cv_image) {
            cv_image._data = {
              cv_type: 'cv_image',
            };
            resolve(cv_image);
          },
          { width: print_area_width * 3, top: print_area_top, left: print_area_left - print_area_width },
        );
      });
    }),
    ippL,
    map(([i, cv_image]) => {
      return cv_image.toObject();
    }),
  );
  await drawProductFace(
    main_canvas,
    go(product_face, (product_face) => {
      return {
        ...product_face,
        designs: main_cv_image_objs,
      };
    }),
    false,
    base_product_size_id,
    true,
    false,
  );
  return main_canvas;
};
