export function mmToDot(mm, dpmm = 8) {
  return Math.round(dpmm * mm);
}

export function mmToPosR(x, y, width = 99.5) {
  return mmToDot(width - y) + ',' + mmToDot(x);
}

function escapingZplSafeText(text) {
  return text.replace('_', '_5f').replace('~', `_7e`).replace('^', '_5e');
}

function createText({ text, type, h }) {
  // Rotation: R 기본
  return `^A${type}R,${mmToDot(h)}^FH^FD${escapingZplSafeText(text)}^FS`;
}

export function getZplForText({
  x,
  y,
  text,
  line_ctn,
  font_unit_size,
  max_width,
  line_gap,
  font_size,
  font_type,
  just,
}) {
  let zpl = '';
  // 텍스트 유형
  line_ctn =
    line_ctn ||
    calculateTextLines({
      text,
      unit_width: font_unit_size,
      max_width,
    });
  zpl += createFieldBox({
    x,
    y,
    max_w: max_width,
    lines: line_ctn,
    line_gap,
    just,
  });
  zpl += createText({ type: font_type, h: font_size, text });
  const height_adder = line_ctn * (font_size + line_gap);

  return { zpl, height_adder };
}

export function calculateTextLines({ text, max_width, unit_width }) {
  let lines = 1;
  let stride = 0;
  const words = text.split(' ');

  words.forEach((word) => {
    const last_word_stride = getWordLenMm({ word, unit_width }) + (stride === 0 ? 0 : unit_width); // 시작 word 가 아니면 공백 1개 추가
    stride += last_word_stride;

    if (stride > max_width) {
      ++lines;
      stride = last_word_stride - unit_width; // 이전 line 에서 추가된 공백 제거 (시작 word)
    }
  });

  return lines;
}

function getWordLenMm({ word, unit_width }) {
  const ko_char_ctn = countKoreanChars({ text: word });
  const not_ko_char_ctn = word.length - ko_char_ctn;
  const total_unit_ctn = not_ko_char_ctn + 2 * ko_char_ctn;
  return total_unit_ctn * unit_width; // mm
}

function countKoreanChars({ text }) {
  const koreanCharacters = text.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g);
  if (!koreanCharacters) {
    return 0;
  }
  return koreanCharacters.length;
}

export function getZplHorLineR({ x, y, w }) {
  return `^FO${mmToPosR(x, y)}^GB0,${mmToDot(w - 0.5)},${mmToDot(1 / 8)},B,0^FS`;
}

function createFieldBox({ x, y, max_w, lines, line_gap, just }) {
  return `^FO${mmToPosR(x, y)}^FB${mmToDot(max_w)},${lines},${mmToDot(line_gap)},${just}`;
}

export function getZplGraphics({ x, y, graphic_zpl }) {
  if (!graphic_zpl.startsWith('^GFA')) throw new Error(`Graphic zpl 문자열이 ^GFA 로 시작하지 않습니다.`);
  return `^FO${mmToPosR(x, y)}${graphic_zpl}`;
}

export function getZplBarcode({ x, y, data, h }) {
  return `^BY2^FO${mmToPosR(x, y)}^BCR,${h}^FD${data}^FS`;
}

export function getZplPrintQuantity({ quantity }) {
  return `^PQ${quantity}`;
}
