import { go, join, map, pipe, unique, values } from 'fxjs/es';
import { makeCanvasByImageWithSize } from '../../Canvas/S/util.js';
import { isBase64 } from './util.js';

export function getJustifyImageSize({ canvas, margin = 0 }) {
  const imgData = getImageData({ canvas });

  const pointer = {
    top: null,
    bottom: null,
    left: null,
    right: null,
  };
  const { width: w, height: h } = imgData;

  // i: row, j: column
  const getAlpha = (i, j) => imgData.data[(i * w + j) * 4 + 3];

  const alpha_threshold = 1;

  for (let i = 0; i < h; ++i) {
    if (pointer.top != null && pointer.bottom != null) break;
    for (let j = 0; j < w; ++j) {
      // sweep from top
      pointer.top == null && getAlpha(i, j) > alpha_threshold && (pointer.top = i);
      // sweep from bottom
      pointer.bottom == null && getAlpha(h - i - 1, j) > alpha_threshold && (pointer.bottom = h - i - 1);
    }
  }

  for (let j = 0; j < w; ++j) {
    if (pointer.left != null && pointer.right != null) break;
    for (let i = pointer.top + 1; i < pointer.bottom - 1; ++i) {
      // sweep from left (in reduced range)
      pointer.left == null && getAlpha(i, j) > alpha_threshold && (pointer.left = j);
      // sweep from right (in reduced range)
      pointer.right == null && getAlpha(i, w - j - 1) > alpha_threshold && (pointer.right = w - j - 1);
    }
  }

  return {
    x: (pointer.left ?? 0) - margin,
    y: (pointer.top ?? 0) - margin,
    w: (pointer.right ?? 0) - (pointer.left ?? 0) + 1 + margin * 2,
    h: (pointer.bottom ?? 0) - (pointer.top ?? 0) + 1 + margin * 2,
  };
}

function getImageData({ canvas }) {
  const ctx = canvas.getContext('2d');
  return ctx.getImageData(0, 0, canvas.width, canvas.height);
}

export function getJustifyCanvas(canvas, margin = 0) {
  return cropCanvas({ canvas, size: getJustifyImageSize({ canvas, margin }) });
}

export function cropCanvas({ canvas, size }) {
  const { x, y, w, h } = size;
  const cropped_canvas = document.createElement('canvas');
  const ctx = cropped_canvas.getContext('2d');
  cropped_canvas.width = w;
  cropped_canvas.height = h;

  ctx.drawImage(canvas, x, y, w, h, 0, 0, w, h);
  return cropped_canvas;
}

/*  @description Canvas 에 좌/우측 margin 영역이 추가된 canvas 를 리턴
 *  @param position { 'left' | 'right' | 'center' } margin 을 어디에 넣을 건지
 *  @param margin_x { number } 몇 픽셀의 가로 마진을 넣을 것인지
 *  @param src_canvas
 *  @return canvas 마진 영역이 설정된 캔버스
 * */
export function addCanvasMargin({ src_canvas, margin_direction, margin_x }) {
  if (src_canvas == null) throw new Error(`Not exist src canvas`);
  const { width, height } = src_canvas;
  const marginal_canvas = document.createElement('canvas');
  marginal_canvas.height = height;
  marginal_canvas.width = width + margin_x;
  const ctx = marginal_canvas.getContext('2d');

  switch (margin_direction) {
    case 'center': {
      ctx.drawImage(src_canvas, 0, 0, width, height, 0, 0, width, height);
      break;
    }
    case 'left': {
      ctx.drawImage(src_canvas, 0, 0, width, height, margin_x, 0, width, height);
      break;
    }
    case 'right': {
      ctx.drawImage(src_canvas, 0, 0, width, height, 0, 0, width, height);
      break;
    }
    default:
      throw new Error(`Unhandled Position ${margin_direction}`);
  }
  return marginal_canvas;
}

function rowBlank(imageData, width, y, strength = 0) {
  for (let x = 0; x < width; ++x) {
    if (imageData.data[y * width * 4 + x * 4 + 3] > strength) return false;
  }
  return true;
}

function columnBlank(imageData, width, x, top, bottom, strength = 0) {
  for (let y = top; y < bottom; ++y) {
    if (imageData.data[y * width * 4 + x * 4 + 3] > strength) return false;
  }
  return true;
}

export function getTopInImageData(imageData, strength) {
  let top = 0;
  const bottom = imageData.height;
  const width = imageData.width;
  while (top < bottom && rowBlank(imageData, width, top, strength)) ++top;
  return top;
}

function getBottomInImageData(imageData, strength) {
  let bottom = imageData.height;
  const top = 0;
  const width = imageData.width;
  const height = imageData.height;
  while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1, strength)) --bottom;
  return height - bottom;
}

function getLeftInImageData(imageData, strength) {
  let left = 0;
  const top = 0;
  const width = imageData.width;
  const bottom = imageData.height;
  while (left < width && columnBlank(imageData, width, left, top, bottom, strength)) ++left;
  return left;
}

function getRightInImageData(imageData, strength) {
  let right = imageData.width;
  const top = 0;
  const left = 0;
  const width = imageData.width;
  const bottom = imageData.height;
  while (right - 1 > left && columnBlank(imageData, width, right - 1, top, bottom, strength)) --right;
  return width - right;
}

export function trimCanvasSize(canvas, trim_strength) {
  const ctx = canvas.getContext('2d');
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const top = getTopInImageData(imageData, trim_strength);
  const bottom = getBottomInImageData(imageData, trim_strength);
  const left = getLeftInImageData(imageData, trim_strength);
  const right = getRightInImageData(imageData, trim_strength);
  return {
    top,
    left,
    right,
    bottom,
    width: imageData.width - right - left,
    height: imageData.height - bottom - top,
  };
}

export function makeCanvasCut(c) {
  const ctxx = c.getContext('2d');
  const imageData = ctxx.getImageData(0, 0, c.width, c.height);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = c.width;
  const ratio = 0.55;
  canvas.height = c.height * ratio;
  ctx.putImageData(imageData, 0, (-c.height * (1 - ratio)) / 2);
  return canvas;
}

export function makeImage(url) {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.src = isBase64(url) ? url : url + G.mp.maker.cross_origin_query_str;
  return new Promise(function (resolve) {
    img.onload = function () {
      resolve(img);
    };
  });
}

function makeCanvasResized(image_data, size) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = image_data.width;
  canvas.height = image_data.height;

  ctx.putImageData(image_data, 0, 0);
  if (!size) return canvas;
  return makeCanvasByImageWithSize(canvas, size);
}

function makeMaxSize({ width, height }, maxSize) {
  if (!maxSize) return;
  if (height <= width) {
    return {
      width: maxSize,
      height: maxSize * (height / width),
    };
  } else {
    return {
      width: maxSize * (width / height),
      height: maxSize,
    };
  }
}

export function makeTrimCanvas(canvas, size) {
  const ctx = canvas.getContext('2d');
  return go(trimCanvasSize(canvas, 0), ({ top, left, width, height }) => {
    const image_data = ctx.getImageData(left, top, width, height);
    return makeCanvasResized(
      image_data,
      makeMaxSize({ width: image_data.width, height: image_data.height }, size),
    );
  });
}

function makeErrorCanvas(canvas) {
  const c = document.createElement('canvas');
  c.width = canvas.width;
  c.height = canvas.height;
  const ctx = c.getContext('2d');
  ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height - 1, 0, 0, c.width, c.height - 1);
  return c;
}
export function resizeCanvas(canvas, width) {
  const resized_canvas = document.createElement('canvas');
  const resized_canvas_ctx = resized_canvas.getContext('2d');
  resized_canvas.width = width;
  resized_canvas.height = canvas.height * (width / canvas.width);

  resized_canvas_ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    resized_canvas.width,
    resized_canvas.height,
  );
  return resized_canvas;
}
function makeStrCanvasData(c) {
  const c_ctx = c.getContext('2d');
  const c_image_data = c_ctx.getImageData(0, 0, c.width, c.height);

  return c_image_data.data.join('');
}

export const isSameCanvas = (canvass, width) => {
  return go(
    // [canvass[0], makeErrorCanvas(canvass[1])],
    canvass,
    map((c) => resizeCanvas(c, width)),
    (resized_canvass) => {
      return go(resized_canvass, map(makeStrCanvasData), unique, (str_image_datas) => {
        if (str_image_datas?.length === 1) return true;
        // await go(resized_canvass, makeTempUploadForText, (url) => console.log('url', url));
        return false;
      });
    },
  );
};
export const isSameSize = (canvass) => {
  return go(
    canvass,
    map((canvas) => getJustifyImageSize({ canvas })),
    map(pipe(values, join(''))),
    unique,
    (arr) => arr.length === 1,
  );
};

export function makeTrimCanvas2(canvas) {
  const ctx = canvas.getContext('2d');
  return go(trimCanvasSize(canvas, 30), ({ top, left, width, height }) => {
    const image_data = ctx.getImageData(left - 4, top - 4, width + 8, height + 8);
    const canvas2 = document.createElement('canvas');
    const ctx2 = canvas2.getContext('2d');
    canvas2.width = image_data.width;
    canvas2.height = image_data.height;
    ctx2.putImageData(image_data, 0, 0);
    return canvas2;
  });
}

export function makeTrimCanvas3(canvas) {
  const ctx = canvas.getContext('2d');
  return go(trimCanvasSize(canvas, 0), ({ top, left, width, height }) => {
    const image_data = ctx.getImageData(left, top, width, height);
    const canvas2 = document.createElement('canvas');
    const ctx2 = canvas2.getContext('2d');
    canvas2.width = image_data.width;
    canvas2.height = image_data.height;
    ctx2.putImageData(image_data, 0, 0);
    return canvas2;
  });
}

export function makeTrimCanvas4(canvas) {
  const ctx = canvas.getContext('2d');
  return go(trimCanvasSize(canvas, 0), ({ top, left, width, height }) => {
    const image_data = ctx.getImageData(0, top, canvas.width, height);
    const canvas2 = document.createElement('canvas');
    const ctx2 = canvas2.getContext('2d');
    canvas2.width = canvas.width;
    canvas2.height = image_data.height;
    ctx2.putImageData(image_data, 0, 0);
    return canvas2;
  });
}

export function rotateCanvas(canvas, angle) {
  const { width, height } = canvas;
  const ctx = canvas.getContext('2d');
  const image = new Image();
  image.src = canvas.toDataURL();
  return new Promise(function (resolve) {
    image.onload = function () {
      ctx.save();
      ctx.clearRect(0, 0, width, height);
      ctx.translate(0.5 * width, 0.5 * height);
      ctx.rotate((angle * Math.PI) / 180);
      ctx.translate(-0.5 * width, -0.5 * height);
      ctx.drawImage(image, 0, 0, width, height);
      ctx.restore();
      resolve();
    };
  });
}

export function compareLocationAndSize(base_canvas, target_canvas) {
  const base_canvas_size = getJustifyImageSize({ canvas: base_canvas });
  const target_canvas_size = getJustifyImageSize({ canvas: target_canvas });

  return (
    base_canvas_size.x === target_canvas_size.x &&
    base_canvas_size.y === target_canvas_size.y &&
    base_canvas_size.w === target_canvas_size.w &&
    base_canvas_size.h === target_canvas_size.h
  );
}
