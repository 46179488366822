import axios from 'axios';
import { go, map, sel } from 'fxjs/es';
import { $closest, $data, $find, $findAll } from 'fxdom/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';

export const getCustomBadges = async () => go(axios.get('/@api/custom_badges'), sel('data'));

export const relationCustomBadges = async (e) => {
  const badge_ids = go(
    e.delegateTarget,
    $findAll('[type="checkbox"]:checked'),
    map($closest('tr')),
    map($data),
    map(({ id }) => id),
  );

  const bp_ids = go(e.delegateTarget, $find('.custom-badges__relation-bp-container'), $data, (bp_ids) => {
    return Array.isArray(bp_ids) ? bp_ids : [bp_ids];
  });

  if (badge_ids.length > 2) {
    $.alert('커스텀 뱃지는 최대 2개까지 연결 가능합니다.');
    return;
  }
  try {
    $.don_loader_start();
    await axios.post('/@api/base_products_custom_badges', { bp_ids, badge_ids });
    MuiF.closeFrame(true);
  } catch (e) {
    e?.response?.data && $.alert(e?.response?.data);
  } finally {
    $.don_loader_end();
  }
};
