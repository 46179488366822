import { entries, go, groupBy, map, object, reduce } from 'fxjs/es';
import Swal from 'sweetalert2';
import { DfInhouseF } from './module/DfInhouseF.js';

export function getCrewCompanyInhouseOptions({ crew_inhouse_id }) {
  return DfInhouseF.apiCalls.get
    .crewInhouseShippingFromId({ crew_inhouse_id })
    .then((crew_company_inhouses) => {
      return go(
        crew_company_inhouses,
        groupBy(({ company_name }) => company_name),
        entries,
        map(([company_name, company_inhouses]) => [
          company_name,
          reduce(
            (options, company_inhouse) => {
              const { id: crew_inhouse_shipping_id, company_address } = company_inhouse;
              options[crew_inhouse_shipping_id] = company_address;
              return options;
            },
            {},
            company_inhouses,
          ),
        ]),
        object,
      );
    })
    .catch((err) => {
      Swal.showValidationMessage(
        DfInhouseF.parseErrorMessage(err?.response?.data ?? '사내 배송지 목록을 가져올 수 없습니다.'),
      );
      Swal.disableInput();
      Swal.getConfirmButton().remove();
      return {};
    });
}
