import { delay, go, mapObject } from 'fxjs/es';
import { loadImageFromUrl } from '../../../../Canvas/S/util.js';
import { QrConstantS } from '../../../../Qr/S/Constant/module/QrConstantS.js';
import querystring from 'querystring';

export const drawQr = async ({ qr_data, canvas, qr_value, dpi }) => {
  const px_per_cm = (1 / 2.54) * dpi;
  const { x, y, width, height, margin } = go(
    qr_data,
    mapObject((cm) => {
      return Math.round(cm * px_per_cm);
    }),
  );
  const ctx = canvas.getContext('2d');
  const qr_image = await loadImageFromUrl(
    `${QrConstantS.GENERATE_LAMBDA_URL}?${querystring.stringify({
      value: qr_value,
      height,
      format: 'svg',
      margin: 0,
      displayValue: false,
    })}`,
  );
  await delay(1000);
  const qr_canvas = document.createElement('canvas');
  qr_canvas.width = width + margin + margin;
  qr_canvas.height = height + margin + margin;
  const qr_ctx = qr_canvas.getContext('2d');
  qr_ctx.fillStyle = '#ffffff';
  qr_ctx.fillRect(0, 0, qr_canvas.width, qr_canvas.height);
  qr_ctx.drawImage(qr_image, 0, 0, qr_image.width, qr_image.height, margin, margin, width, height);
  ctx.drawImage(qr_canvas, 0, 0, qr_canvas.width, qr_canvas.height, x, y, qr_canvas.width, qr_canvas.height);
};
