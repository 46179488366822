import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { DfTaskHistoryMuiF } from '../Mui/module/DfTaskHistoryMuiF.js';
import axios from 'axios';
import { DfTaskHistoryTmplS } from '../../S/Tmpl/module/DfTaskHistoryTmplS.js';

export const historyOpen = (projection_id) => {
  if (!projection_id) {
    return $.alert('프로젝션을 찾지 못했습니다.');
  }
  return MuiF.openFrame(DfTaskHistoryMuiF.frame, (f, p, [t]) => {
    t.makeData = async () => {
      $.don_loader_start();
      const { data } = await axios
        .get(`/@api/tasks/histories`, { params: { projection_id } })
        .finally($.don_loader_end);
      return data;
    };
    t.template = DfTaskHistoryTmplS.historyGroup;
  });
};
