import { GoodsTypeConstantS } from '../../../../GoodsType/S/Constant/module/GoodsTypeConstantS.js';
import { html } from 'fxjs/es';
export const makeDfProjectionListFilterHeader = (states) =>
  html`
    <div class="sd">
      <div class="s_col title">주문종류</div>
      <div class="s_col">
        <label>
          <input
            value="2"
            type="checkbox"
            name="marketplace_ids"
            class="query arr"
            ${states.marketplace_ids?.indexOf('2') > -1 ? 'checked' : ''}
          />
          <span>NESS 주문</span>
        </label>
      </div>
      <div class="s_col title"></div>
      <div class="s_col">
        <label>
          <input
            value="mp"
            type="checkbox"
            name="collabo_type"
            ${states.collabo_type.indexOf('mp') > -1 ? 'checked' : ''}
            class="query arr"
          />
          <span>마플 주문</span>
        </label>
      </div>
      <div class="s_col">
        <label>
          <input value="true" type="checkbox" name="is_biz" ${states.is_biz ? 'checked' : ''} class="query" />
          <span>마플 비즈</span>
        </label>
      </div>
      <div class="s_col title"></div>
      <div class="s_col">
        <label>
          <input
            value="parent"
            type="checkbox"
            name="merged_type"
            ${states.merged_type.indexOf('parent') > -1 ? 'checked' : ''}
            class="query arr"
          />
          <span>샵 병합 (제작용)</span>
        </label>
      </div>
      <div class="s_col">
        <label>
          <input
            value="child"
            type="checkbox"
            name="merged_type"
            ${states.merged_type.indexOf('child') > -1 ? 'checked' : ''}
            class="query arr"
          />
          <span>샵 (배송용)</span>
        </label>
      </div>
      <div class="s_col">
        <label>
          <input
            value="sample"
            type="checkbox"
            name="merged_type"
            ${states.merged_type.indexOf('sample') > -1 ? 'checked' : ''}
            class="query arr"
          />
          <span>샵 샘플 (제작용)</span>
        </label>
      </div>
      <div class="s_col">
        <label>
          <input
            value="fast_child"
            type="checkbox"
            name="merged_type"
            ${states.merged_type.indexOf('fast_child') > -1 ? 'checked' : ''}
            class="query arr"
          />
          <span>빠른POD (제작용)</span>
        </label>
      </div>
      <div class="s_col">
        <label>
          <input
            value="TPL"
            type="checkbox"
            name="goods_type_id"
            ${states?.goods_type_id?.indexOf(GoodsTypeConstantS.TPL.id) > -1 ? 'checked' : ''}
            class="query arr"
          />
          <span>샵 입고 포함주문</span>
        </label>
      </div>
      <div class="s_col title"></div>
      <div class="s_col">
        <label>
          <input
            value="true"
            type="checkbox"
            name="is_afreeca"
            ${states?.is_afreeca ? 'checked' : ''}
            class="query arr"
          />
          <span>숲토어 주문</span>
        </label>
      </div>
      <div class="s_col" style="transform: scale(.8);">
        <div class="s_col">
          <label>
            <input
              value="line"
              type="checkbox"
              name="collabo_type"
              ${states.collabo_type.indexOf('line') > -1 ? 'checked' : ''}
              class="query arr"
            />
            <span>라인 주문</span>
          </label>
        </div>
        <div class="s_col">
          <label>
            <input
              value="parent"
              type="checkbox"
              name="shopify_merged_type"
              ${states.shopify_merged_type.indexOf('parent') > -1 ? 'checked' : ''}
              class="query arr"
            />
            <span>쇼피파이 병합 (제작용)</span>
          </label>
        </div>
        <div class="s_col">
          <label>
            <input
              value="child"
              type="checkbox"
              name="shopify_merged_type"
              ${states.shopify_merged_type.indexOf('child') > -1 ? 'checked' : ''}
              class="query arr"
            />
            <span>쇼피파이 (배송용)</span>
          </label>
        </div>
        <div class="s_col">
          <label>
            <input
              value="true"
              type="checkbox"
              name="is_wow"
              ${states.is_wow ? 'checked' : ''}
              class="query"
            />
            <span>와우 주문</span>
          </label>
        </div>
      </div>
    </div>
    <div class="sd">
      <div class="s_col title">언어</div>
      <div class="s_col">
        <label>
          <input type="checkbox" name="lang_kr" ${states.lang_kr ? 'checked' : ''} class="query" />
          <span>국문</span>
        </label>
      </div>
      <div class="s_col">
        <label>
          <input type="checkbox" name="lang_en" ${states.lang_en ? 'checked' : ''} class="query" />
          <span>영문</span>
        </label>
      </div>
      <div class="s_col">
        <label>
          <input type="checkbox" name="lang_jp" ${states.lang_jp ? 'checked' : ''} class="query" />
          <span>일문</span>
        </label>
      </div>
    </div>
  `;
