import { CateListConstantS } from '../../../../../CateList/S/Constant/module/CateListConstantS.js';

export const getWorkType = ({ name, cate_list_id, is_not_image_task, has_template }) => {
  if (is_not_image_task) return '자동화';
  if (has_template) return '검수';
  const bp_name = name.replace(/ /g, '');
  /* TODO 카테고리 하드코딩 */
  const prev_phoncase =
    cate_list_id == CateListConstantS.PHONE_ACC_LIST_ID &&
    bp_name.indexOf('투명') == -1 &&
    (bp_name.indexOf('하드케이스') != -1 ||
      bp_name.indexOf('범퍼슬라이드') != -1 ||
      bp_name.indexOf('범퍼케이스') != -1);
  if (prev_phoncase) return '검수';
  return '작업';
};
