import { $find, $findAll, $on } from 'fxdom/es';
import { each, eachL, equals, go, mapL, noop, sum, tap } from 'fxjs/es';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { SVGEditorMobileUnlockItemsMuiS } from '../../S/Mui/module/SVGEditorMobileUnlockItemsMuiS.js';
import { SVGEditorMobileUnlockItemsEventF } from '../Event/module/SVGEditorMobileUnlockItemsEventF.js';
import { SVGEditorMobileUnlockItemsF } from '../Function/module/SVGEditorMobileUnlockItemsF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...SVGEditorMobileUnlockItemsMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {
    SVGEditorMobileUnlockItemsEventF.initWindowEvent(window);
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    each(
      $on(
        'click',
        ({ target, currentTarget: current_target }) =>
          equals(target)(current_target) && MuiF.closeFrame({ error: null }),
      ),
    )($findAll('.blank_plate')(tab_el$));
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  async showed(tab_el$) {
    const { __mp_sticker_editor: sticker_editor } = tab_el$;
    if (sticker_editor == null) {
      throw new Error(`sticker_editor 를 찾을 수 없습니다.`);
    }

    const outer_container_el = $find('.outer_container')(tab_el$);
    if (outer_container_el == null) {
      throw new Error(`outer_container 엘리먼트를 찾을 수 없습니다.`);
    }

    const inner_container_el = $find('.inner_container')(tab_el$);
    if (inner_container_el == null) {
      throw new Error(`inner_container 엘리먼트를 찾을 수 없습니다.`);
    }

    $.don_loader_start();
    try {
      await go(
        sticker_editor.getLockedEls(),
        mapL(async (original_el) => {
          const item_el = await SVGEditorMobileUnlockItemsF.makeItem$(original_el);
          return { original_el, item_el };
        }),
        eachL(({ item_el }) =>
          SVGEditorMobileUnlockItemsF.initItemImgSize$({ parent_el: inner_container_el, item_el }),
        ),
        eachL(({ original_el, item_el }) =>
          SVGEditorMobileUnlockItemsEventF.initItemEvent({ sticker_editor, original_el, item_el }),
        ),
        mapL(({ item_el }) => SVGEditorMobileUnlockItemsF.getItemWidthAsync$(item_el)),
        sum,
        (total_width) => total_width > outer_container_el.getBoundingClientRect().width,
        tap((is_overflow) => {
          outer_container_el.dataset.is_overflow = `${is_overflow}`;

          if (!is_overflow) {
            return;
          }

          outer_container_el.classList.add('swiper-container');
          inner_container_el.classList.add('swiper-wrapper');
          new Swiper(outer_container_el, {
            slidesPerView: 'auto',
            freeMode: true,
          });
        }),
      );
    } catch (error) {
      console.error(error);
      const message =
        error.__mp_alert_message ?? T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.');
      await $.alert(message)
        .then(() => MuiF.closeFrame({ error }))
        .catch(noop);
    } finally {
      $.don_loader_end();
    }
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {
    SVGEditorMobileUnlockItemsEventF.cleanUpWindowEvent(window);
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
