export default function (lo) {
  $.frame(
    {
      frame_name: 'df.free_folder_detail',
      page_name: 'df.free_folder_detail_page',
    },
    {
      page_name: 'df.free_folder_detail_page',
      tabs: [
        {
          tab_name: 'df.free_folder_detail_tab',
          data_func: function () {
            return box.sel('folder');
          },
        },
      ],
    },
  );
}
