import { each, go, mapC } from 'fxjs/es';
import { makePrintableFiles } from './makePrintableFiles.js';

// if (qr_value && template_meta.qr_data) {
//   await NewMakerPrintResultF.drawQr({
//     qr_data: template_meta.qr_data,
//     qr_value: `^02${up_id}`,
//     dpi: template_meta.dpi,
//     canvas,
//   });
// }
export const uploadPrintableFiles = async ({
  base_product_color_id,
  product_faces2,
  base_product_size_id,
}) => {
  const printable_files = await go(
    makePrintableFiles({
      base_product_size_id,
      base_product_color_id,
      product_faces2: product_faces2.value,
    }),
    mapC(async (file) => {
      const form_data = new FormData();
      go(
        [file.data_url, file.glue_layer_data_url],
        each((data_url) => {
          const blobBin = atob(data_url.split(',')[1]);
          const array = [];
          for (let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
          }
          form_data.append('files', new Blob([new Uint8Array(array)], { type: file.mimetype }));
        }),
      );
      const { files } = await $.upload(form_data, {
        url: '/@api/prerequisite_maker/product_printable_files',
      });
      return {
        url: files[0].url,
        size: files[0].size,
        width: files[0].width,
        height: files[0].height,
        format: files[0].format,
        glue_layer_url: files[1].url,
        glue_layer_size: files[1].size,
        glue_layer_format: files[1].format,
      };
    }),
  );
  return printable_files;
};

/*
 * product_faces2.printable_files [{url, glue_layer_url, width, height, url_size, glue_layer_url_size}]
 *
 *
 * */
