import Joi from 'joi';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { identity } from 'fxjs/es';

const validators = {
  required: (val) => {
    if (UtilS.isEmpty(val)) {
      throw new Error(`필수값입니다.`);
    }
  },
};

export const SUB_GROUP_NAMES = {
  projection_info: 'projection_info',
  package_info: 'package_info',
  receiver_info: 'receiver_info',
  sender_info: 'sender_info',
  product_info: 'product_info',
};

export const FORM = {
  GROUP_NAMES: [
    SUB_GROUP_NAMES.projection_info,
    SUB_GROUP_NAMES.package_info,
    SUB_GROUP_NAMES.receiver_info,
    SUB_GROUP_NAMES.sender_info,
  ],
  GROUP_COLS: {
    [SUB_GROUP_NAMES.projection_info]: [
      {
        no: 1,
        dev: 'projection_id',
        title: '주문번호',
        validation: (val) =>
          Joi.object({
            projection_id: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 2,
        dev: 'cargo_type',
        title: '화물구분', // Document: 서류(1), Package: 화물(2)
        validation: (val) =>
          Joi.object({
            cargo_type: Joi.string().valid('2').required(),
          }).validateAsync(val),
      },
      {
        no: 3,
        dev: 'express_code',
        title: '운송구분',
        validation: (val) =>
          Joi.object({
            express_code: Joi.string()
              .valid(...Object.values(SHIPPING_COMPANY_ID_TO_LGL_EXPRESS_CODE))
              .allow(null, ''),
          }).validateAsync(val),
      },
      {
        no: 4,
        dev: 'express_service_code',
        title: '서비스구분',
        validation: () => {},
      },
      {
        no: 5,
        dev: 'lgl_client_code',
        title: '거래처코드',
        validation: (val) =>
          Joi.object({
            lgl_client_code: Joi.string().valid(LGL_CLIENT_CODE).required(),
          }).validateAsync(val),
      },
      {
        no: 6,
        dev: 'lgl_account_code',
        title: '어카운트코드',
        validation: (val) =>
          Joi.object({
            lgl_account_code: Joi.string()
              .valid(...Object.values(LGL_ACCOUNT_CODE))
              .allow(null, ''),
          }).validateAsync(val),
      },
    ],
    [SUB_GROUP_NAMES.package_info]: [
      {
        no: 1,
        dev: 'currency',
        title: '화폐',
        validation: (val) =>
          Joi.object({
            currency: Joi.string().valid('KRW').required(),
          }).validateAsync(val),
      },
      {
        no: 2,
        dev: 'package_ctn',
        title: '패키지수량',
        validation: (val) =>
          Joi.object({
            package_ctn: Joi.number().integer().required(),
          }).validateAsync(val),
      },
      {
        no: 3,
        dev: 'length',
        title: '가로',
        validation: (val) =>
          Joi.object({
            length: Joi.number(),
          }).validateAsync(val),
      },
      {
        no: 4,
        dev: 'width',
        title: '세로',
        validation: (val) =>
          Joi.object({
            width: Joi.number(),
          }).validateAsync(val),
      },
      {
        no: 5,
        dev: 'height',
        title: '높이',
        validation: (val) =>
          Joi.object({
            height: Joi.number(),
          }).validateAsync(val),
      },
      {
        no: 6,
        dev: 'weight',
        title: '실중량',
        validation: (val) =>
          Joi.object({
            weight: Joi.number(),
          }).validateAsync(val),
      },
      {
        no: 7,
        dev: 'volumetric_weight',
        title: '부피중량',
        validation: (val) =>
          Joi.object({
            volumetric_weight: Joi.number(),
          }).validateAsync(val),
      },
    ],
    [SUB_GROUP_NAMES.receiver_info]: [
      {
        no: 1,
        dev: 'receiver_name',
        title: '이름',
        validation: (val) =>
          Joi.object({
            receiver_name: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 2,
        dev: 'receiver_store',
        title: '상호',
        validation: (val) =>
          Joi.object({
            receiver_store: Joi.string().allow(''),
          }).validateAsync(val),
      },
      {
        no: 3,
        dev: 'receiver_postcode',
        title: '우편번호',
        validation: (val) =>
          Joi.object({
            receiver_postcode: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 4,
        dev: 'receiver_tel1',
        title: '전화번호1',
        validation: (val) =>
          Joi.object({
            receiver_tel1: Joi.string().allow(''),
          }).validateAsync(val),
      },
      {
        no: 5,
        dev: 'receiver_tel2',
        title: '전화번호2',
        validation: (val) =>
          Joi.object({
            receiver_tel2: Joi.string().allow(''),
          }).validateAsync(val),
      },
      {
        no: 6,
        dev: 'receiver_country_code',
        title: '도착지국가코드',
        validation: (val) =>
          Joi.object({
            receiver_country_code: Joi.string()
              .regex(/^[A-Z]{2}$/)
              .message('The input must be a two-character uppercase string.')
              .required(),
          }).validateAsync(val),
      },
      {
        no: 7,
        dev: 'receiver_state',
        title: '주(Province)',
        validation: (val) =>
          Joi.object({
            receiver_state: Joi.string().allow(''),
          }).validateAsync(val),
      },
      {
        no: 8,
        dev: 'receiver_city',
        title: '도시(City)',
        validation: (val) =>
          Joi.object({
            receiver_city: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 9,
        dev: 'receiver_address',
        title: '상세주소',
        validation: (val) =>
          Joi.object({
            receiver_address: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 10,
        dev: 'receiver_email',
        title: 'E-MAIL',
        validation: (val) =>
          Joi.object({
            // todo @ddevkim 주문정보에서 받아야 함. allow('') 제거하고 required() 로 변경
            receiver_email: Joi.string().allow(''),
          }).validateAsync(val),
      },
    ],
    [SUB_GROUP_NAMES.sender_info]: [
      {
        no: 1,
        dev: 'sender_name',
        title: '이름',
        validation: (val) =>
          Joi.object({
            sender_name: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 2,
        dev: 'sender_store',
        title: '상호',
        validation: (val) =>
          Joi.object({
            sender_store: Joi.string().allow(''),
          }).validateAsync(val),
      },
      {
        no: 3,
        dev: 'sender_postcode',
        title: '우편번호',
        validation: (val) =>
          Joi.object({
            sender_postcode: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 4,
        dev: 'sender_tel1',
        title: '전화번호1',
        validation: (val) =>
          Joi.object({
            sender_tel1: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 5,
        dev: 'sender_tel2',
        title: '전화번호2',
        validation: (val) =>
          Joi.object({
            sender_tel2: Joi.string().allow(''),
          }).validateAsync(val),
      },
      {
        no: 6,
        dev: 'sender_city',
        title: '도/시',
        validation: (val) =>
          Joi.object({
            sender_city: Joi.string().allow(''),
          }).validateAsync(val),
      },
      {
        no: 7,
        dev: 'sender_state',
        title: '시/군/구',
        validation: (val) =>
          Joi.object({
            sender_state: Joi.string().allow(''),
          }).validateAsync(val),
      },
      {
        no: 8,
        dev: 'sender_address',
        title: '상세주소',
        validation: (val) =>
          Joi.object({
            sender_address: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 9,
        dev: 'sender_email',
        title: 'E-MAIL',
        validation: (val) =>
          Joi.object({
            sender_email: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 10,
        dev: 'export_type',
        title: '통관구분',
        validation: (val) =>
          Joi.object({
            export_type: Joi.string().valid('L', 'S').required(), // L: 목록, S: 간이
          }).validateAsync(val),
      },
      {
        no: 11,
        dev: 'export_no',
        title: '수출신고번호',
        validation: (val) =>
          Joi.object({
            export_no: Joi.string().allow(''),
          }).validateAsync(val),
      },
    ],
    [SUB_GROUP_NAMES.product_info]: [
      {
        no: 1,
        dev: 'user_product_id',
        title: '상품코드',
        validation: (val) =>
          Joi.object({
            user_product_id: Joi.number().integer().required(),
          }).validateAsync(val),
      },
      {
        no: 2,
        dev: 'product_name',
        title: '상품명',
        validation: (val) =>
          Joi.object({
            product_name: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 3,
        dev: 'hs_code',
        title: 'HS_CODE',
        validation: (val) =>
          Joi.object({
            hs_code: Joi.string().required(),
          }).validateAsync(val),
      },
      {
        no: 4,
        dev: 'quantity',
        title: '수량',
        validation: (val) =>
          Joi.object({
            quantity: Joi.number().integer().required(),
          }).validateAsync(val),
      },
      {
        no: 5,
        dev: 'unit_price',
        title: '판매단가',
        validation: (val) =>
          Joi.object({
            unit_price: Joi.number().required(),
          }).validateAsync(val),
      },
      {
        no: 6,
        dev: 'is_cloth',
        title: '의류여부',
        validation: (val) =>
          Joi.object({
            is_cloth: Joi.string().allow('Y', 'N').required(),
          }).validateAsync(val),
      },
      {
        no: 7,
        dev: 'cloth_material',
        title: '의류대표소재',
        validation: identity,
      },
      {
        no: 8,
        dev: 'origin',
        title: '원산지',
        validation: (val) =>
          Joi.object({
            origin: Joi.string()
              .regex(/^[A-Z]{2}$/)
              .message('The input must be a two-character uppercase string.'),
          }).validateAsync(val),
      },
    ],
  },
};

export const LGL_CARGO_TYPE = {
  DOCUMENT: '1',
  PACKAGE: '2',
};

/**
 * @define LGL 에서 요구하는 express 사 별 코드
 * */
export const SHIPPING_COMPANY_ID_TO_LGL_EXPRESS_CODE = {
  lotte_economy_jp: '052',
  lotte_economy_sea: '050',
  lotte_economy_us: '042',
  k_packet: '029',
  ems: '029',
  sf_express: '016',
  ups: '009',
  dhl: '004',
};

export const LGL_CLIENT_CODE = 'MPCORP';
export const LGL_ACCOUNT_CODE = {
  dhl: 'D2',
  ups: 'U2',
};

export const LGL_SENDER = {
  sender_name: 'Marpple Corporation',
  sender_store: '(주)마플코퍼레이션',
  sender_postcode: '08589',
  sender_tel1: '1566-9437',
  sender_tel2: '1566-9437',
  sender_city: 'Seoul',
  sender_state: 'Geumcheon-gu',
  sender_address: 'Gasan digital 1-ro 75-24',
  sender_email: 'help@marpple.com',
  export_type: 'S', // 간이 수출 (목록 통관일 때 L)
  export_no: '',
};

export const LGL_EXPRESS_RESPONSE_ERROR_CODES = {
  S01: 'Request parameter 에러',
  S02: 'Sign 값 에러',
  S04: 'Filed 값 에러',
  S99: '기타 에러 (LGL 문의)',
};

/**
 * @typedef {{[trackingCodeLgl: string]: {description: string, projection_status: string, shipping_status: string}}} LglExpressTrackingCodesStatus
 * */

/**
 * @type {LglExpressTrackingCodesStatus} TRACKING_CODE_PARSER
 * */
export const TRACKING_CODE_PARSER = {
  10: { description: '주문 접수', projection_status: 'delivering', shipping_status: 'ORDER_ACCEPTANCE' },
  17: { description: '라벨 생성', projection_status: 'delivering', shipping_status: 'LABEL_CREATION' },
  35: { description: '입고 및 중량 측정', projection_status: 'delivering', shipping_status: 'INBOUNDED' },
  41: { description: '운송사 접수', projection_status: 'delivering', shipping_status: 'EXPRESS_ACCEPTANCE' },
  50: { description: '운송장 콘솔', projection_status: 'delivering', shipping_status: 'EXPRESS_CONSOLE' },
  51: {
    description: '현지 공항 도착',
    projection_status: 'delivering',
    shipping_status: 'LOCAL_AIRPORT_ARRIVAL',
  },
  58: { description: '현지 통관 관련', projection_status: 'delivering', shipping_status: 'LOCAL_CUSTOMS' },
  59: { description: '현지 통관 관련', projection_status: 'delivering', shipping_status: 'LOCAL_CUSTOMS' },
  60: { description: '현지 통관 관련', projection_status: 'delivering', shipping_status: 'LOCAL_CUSTOMS' },
  61: { description: '현지 통관 관련', projection_status: 'delivering', shipping_status: 'LOCAL_CUSTOMS' },
  70: { description: '현지 배송 관련', projection_status: 'delivering', shipping_status: 'LOCAL_DELIVERING' },
  71: { description: '현지 배송 출발', projection_status: 'delivering', shipping_status: 'LOCAL_DELIVERING' },
  90: { description: '현지 배달 완료', projection_status: 'before_confirm', shipping_status: 'DELIVERED' },
};

export const LGL_EXPRESS_DELIVERED_CODE = '90';
