import { html } from 'fxjs/es';

/* guide_m, guide_pc */
export const guide = ({ guide }) => html`
  <div class="toggle_cont toggle_cont_img guide" is_open="true">
    <div class="tg_head">
      <span class="tit">${T('product_detail::Guide')}</span>
      <span class="btn" style="display: none;"></span>
    </div>
    <div class="tg_body">
      <div class="guide_text">${guide}</div>
    </div>
  </div>
`;
