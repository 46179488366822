import { go, filter, pluck, reject } from 'fxjs/es';

export const TEST_USERS = [193535, 4469081];
export const IS_PUBLIC = {
  MARPPLE: true,
  MARPPLE_SHOP: true,
};
export const ALL_PAYMENT_METHODS = [
  {
    method_key: 'PAYMENTWALL_CREDIT_CARD',
    name: 'Credit Card',
    method_type: 'CARD',
    langs: ['en', 'jp'],
    use: ['', 'creator'],
    pmt_channel: 'PAYMENTWALL',
    channel_key: 'PAYMENTWALL',
  },
  {
    method_key: 'PORTONE_PAYMENTWALL_CREDIT_CARD',
    name: 'Credit Card TEST',
    method_type: 'CARD',
    langs: ['en', 'jp'],
    // use: ['', 'creator'],
    use: [],
    pmt_channel: 'PORTONE_KR',
    channel_key: 'PORTONE_KR',
  },
  {
    method_key: 'PAYMENTWALL_KONBINI',
    name: 'Konbini',
    method_type: 'OTC',
    langs: ['jp'],
    // use: ['', 'creator'],
    use: [],
    pmt_channel: 'PAYMENTWALL',
    channel_key: 'PAYMENTWALL',
  },
  {
    method_key: 'KOMOJU_BANK_TRANSFER',
    name: 'Bank transfer',
    name2: 'Bank transfer (+190円)',
    name2_mobile: 'Bank transfer<br />(+190円)',
    method_type: 'BANK_TRANSFER',
    langs: ['jp'],
    // use: [''],
    use: [],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
  },
  {
    method_key: 'KOMOJU_PAY_EASY',
    name: 'PayEasy',
    name2: 'PayEasy (+190円)',
    name2_mobile: 'PayEasy<br />(+190円)',
    method_type: 'BANK_TRANSFER',
    langs: ['jp'],
    use: [''],
    // use: [],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
  },
  {
    method_key: 'KOMOJU_KONBINI_DAILY_YAMAZAKI',
    name: 'Daily Yamazaki',
    name2: 'Daily Yamazaki (+190円)',
    name2_mobile: 'Daily Yamazaki<br />(+190円)',
    method_type: 'OTC',
    langs: ['jp'],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
    use: [''],
    // use: [],
  },
  {
    method_key: 'KOMOJU_KONBINI_LAWSON',
    name: 'Lawson',
    name2: 'Lawson (+190円)',
    name2_mobile: 'Lawson<br />(+190円)',
    method_type: 'OTC',
    langs: ['jp'],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
    use: [''],
    // use: [],
  },
  {
    method_key: 'KOMOJU_KONBINI_FAMILY_MART',
    name: 'FamilyMart',
    name2: 'FamilyMart (+190円)',
    name2_mobile: 'FamilyMart<br />(+190円)',
    method_type: 'OTC',
    langs: ['jp'],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
    use: [''],
    // use: [],
  },
  {
    method_key: 'KOMOJU_KONBINI_MINISTOP',
    name: 'MiniStop',
    name2: 'MiniStop (+190円)',
    name2_mobile: 'MiniStop<br />(+190円)',
    method_type: 'OTC',
    langs: ['jp'],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
    use: [''],
    // use: [],
  },
  {
    method_key: 'KOMOJU_KONBINI_SEICOMART',
    name: 'Seicomart',
    name2: 'Seicomart (+190円)',
    name2_mobile: 'Seicomart<br />(+190円)',
    method_type: 'OTC',
    langs: ['jp'],
    pmt_channel: 'KOMOJU',
    channel_key: 'KOMOJU',
    use: [''],
    // use: [],
  },
];

function extractPayments(lang, collabo_type) {
  return go(
    ALL_PAYMENT_METHODS,
    filter(({ langs, use }) => langs?.includes(lang) && use.includes(collabo_type)),
  );
}

export const CURRENT_PAYMENT_METHODS_EN = extractPayments('en', '');
export const CURRENT_PAYMENT_METHODS_JP = extractPayments('jp', '');

export const CURRENT_PAYMENT_METHODS_CREATOR_EN = extractPayments('en', 'creator');
export const CURRENT_PAYMENT_METHODS_CREATOR_JP = extractPayments('jp', 'creator');

export const STATUS = {
  Success: 'Success',
  Pending: 'Pending',
  Failed: 'Failed',
  Expired: 'Expired',
  Initiated: 'Initiated',
  UnderReview: 'Under Review',
  /* 새로 이런 상태를 봤길래 추가해 봄 */
};

export const REQUEST_TYPE = {
  checkout: 'checkout',
  additional_payment: 'additional_payment',
};

export const METHOD_TYPE = {
  CARD: 'CARD',
  OTC: 'OTC',
  BANK_TRANSFER: 'BANK_TRANSFER',
};

export const VBANK_STYLE_PAY_METHODS = go(
  ALL_PAYMENT_METHODS,
  filter(({ method_type }) => [METHOD_TYPE.BANK_TRANSFER, METHOD_TYPE.OTC].includes(method_type)),
  pluck('method_key'),
);

export const BANK_TRANSFER_PAY_METHODS = go(
  ALL_PAYMENT_METHODS,
  filter(({ method_type }) => [METHOD_TYPE.BANK_TRANSFER].includes(method_type)),
  pluck('method_key'),
);

export const CHECKOUT_SERVICE_PAYMENT_METHODS = go(
  ALL_PAYMENT_METHODS,
  filter(({ pmt_channel }) => pmt_channel === 'PAYMENTWALL'),
);
export const PAYMENT_SERVICE_PAYMENT_METHODS = go(
  ALL_PAYMENT_METHODS,
  reject(({ pmt_channel }) => pmt_channel === 'PAYMENTWALL'),
);

export const METHOD_KEYS = go(ALL_PAYMENT_METHODS, pluck('method_key'));
