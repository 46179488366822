import axios from 'axios';
import { $closest, $data, $delegate, $find, $hasClass } from 'fxdom/es';
import { go } from 'fxjs/es';
import { PdfF } from '../../../../Pdf/F/Function/module/PdfF.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPMyPageTmplS } from '../../S/Tmpl/module/OMPMyPageTmplS.js';

/**
 * @param {HTMLElement} el
 * @return {HTMLElement}
 */
export const delegateClickResubmit = (el) => {
  return $delegate('click', '.table-button__resubmit', async ({ currentTarget }) => {
    try {
      const product_data = go(currentTarget, $data);
      const user_product_id = product_data.up_id;

      const content = OMPMyPageTmplS.myPageResubmitFileTmpl({ mobile: OMPCoreUtilF.isMobile() });
      const { file } = await OMPCoreUtilF.fileUploadAlert({
        content,
        show_ok: false,
        append_event: (el) => {
          go(
            el,
            $delegate('click', '#omp-my-resubmit-agreed', (e) => {
              const checked = e.target.checked;
              const wrapper = go(e.target, $closest('.omp-my-resubmit__wrapper'));
              const upload_button = go(wrapper, $find('.omp-my-resubmit__upload-button'));
              if (upload_button) {
                upload_button.classList.toggle('disabled', !checked);
              }
            }),
          );
        },
        upload_disabled_condition: (file_uploader_el) => go(file_uploader_el, $hasClass('disabled')),
      });

      $.don_loader_start();

      const result = await PdfF.uploadPdfFile({ file });
      if (result.success) {
        const [{ id: pdf_printing_file_id }] = result.data;
        await axios.post(`/@api/wow_press/file_upload`, {
          user_product_id,
          pdf_printing_file_id,
        });
        window.location.reload();
        $.don_loader_end();
      } else {
        $.alert(result.message);
        $.don_loader_end();
      }
    } catch (err) {
      $.alert('문제가 발생했습니다. 다시 시도 해주세요.');
      $.don_loader_end();
    }
  })(el);
};
