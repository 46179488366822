import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSingleMobileCuttingLineAutoF } from '../../CuttingLineAuto/F/Function/module/VectorEditorStickerSingleMobileCuttingLineAutoF.js';
import { VectorEditorStickerSingleMobileF } from '../Function/module/VectorEditorStickerSingleMobileF.js';
import { VectorEditorStickerSingleMobileElementLayerF } from '../../ElementLayer/F/Function/module/VectorEditorStickerSingleMobileElementLayerF.js';

export const handleHeaderMoreIconClick =
  ({ tab_el }) =>
  () => {
    VectorEditorStickerSingleMobileF.showTopMenuBlankPlate(tab_el);
    VectorEditorStickerSingleMobileF.showTopMenuContainer(tab_el);
  };

export const handleHeaderDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    try {
      const result = await tab_el?.__mp_postProcess?.(editor);
      MuiF.closeFrame(result);
    } catch (error) {
      console.error(error);
      $.alert(error.__mp_alert_message ?? T('modules::VectorEditor::Sticker::message::fail_image'));
    }
  };

export const handleHeaderNextButtonClick =
  ({ tab_el }) =>
  async () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    try {
      if (editor.getGraphicsEls().size <= 0) {
        /** @todo alert message */
        return;
      }

      await VectorEditorStickerSingleMobileCuttingLineAutoF.makeCuttingLineAuto({
        single_sticker_editor: editor,
        postProcess: tab_el.__mp_postProcess,
      });
    } catch (error) {
      await $.alert(T('modules::VectorEditor::Sticker::message::fail_image'));
      throw error;
    }
  };

export const handleHeaderLayersIconClick =
  ({ tab_el }) =>
  async () => {
    VectorEditorStickerSingleMobileF.activateLayersIcon(tab_el);
    await VectorEditorStickerSingleMobileElementLayerF.openElementLayer({
      single_sticker_editor: tab_el.__mp_single_sticker_editor,
      editor_wrapper_el: tab_el,
    });
    VectorEditorStickerSingleMobileF.deactivateLayersIcon(tab_el);
  };
