import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerFreePCMuiF } from '../Mui/module/VectorEditorStickerFreePCMuiF.js';
import { $setCss } from 'fxdom/es';
import { go, tap } from 'fxjs/es';

export const makeSticker = ({
  title,
  price,
  frame_position: { top: frame_position_top, height: frame_position_height },
  prev_frame_right_panel,
  onFrameHiding,
  art_board_size,
  free_template_svg_el,
  single_empty_template_svg_el,
  single_art_board_size,
  collision: {
    sticker_margin: sticker_collision_margin,
    container_path_data: collision_container_path_data,
    container_area_position: collision_container_area_position,
    forbidden_area_position: collision_forbidden_area_position,
  },
  background_color,
  background_opacity,
  preProcess,
  postProcess,
}) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorStickerFreePCMuiF.frame, (frame, page, [tab]) => {
      const frame_appending = frame.appending;
      frame.appending = (frame_el) => {
        $setCss(['z-index', '1'])(frame_el);
        return frame_appending.call(frame, frame_el);
      };
      const frame_hiding = frame.hiding;
      frame.hiding = (frame_el, val) => {
        onFrameHiding(frame_el, val);
        return frame_hiding.call(frame, frame_el, val);
      };
      const frame_closed = frame.closed;
      frame.closed = (frame_el, val) =>
        go(
          frame_closed.call(frame, frame_el, val),
          tap(() => resolve(val)),
        );

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_title = title;
        tab_el.__mp_price = price;
        tab_el.__mp_background_color = background_color;
        tab_el.__mp_background_opacity = background_opacity;
        tab_el.__mp_frame_position_top = frame_position_top;
        tab_el.__mp_frame_position_height = frame_position_height;
        tab_el.__mp_art_board_size = art_board_size;
        tab_el.__mp_free_template_svg_el = free_template_svg_el;
        tab_el.__mp_single_empty_template_svg_el = single_empty_template_svg_el;
        tab_el.__mp_single_art_board_size = single_art_board_size;
        tab_el.__mp_prev_right_panel = prev_frame_right_panel;
        tab_el.__mp_sticker_collision_margin = sticker_collision_margin;
        tab_el.__mp_collision_container_path_data = collision_container_path_data;
        tab_el.__mp_collision_container_area_position = collision_container_area_position;
        tab_el.__mp_collision_forbidden_area_position = collision_forbidden_area_position;
        tab_el.__mp_preProcess = preProcess;
        tab_el.__mp_postProcess = postProcess;
        return tab_appending.call(tab, tab_el);
      };
    }).catch(reject),
  );
