import { go, sel, flatMap } from 'fxjs/es';

export const util = {
  getUpCsQuantity: (ups) => {
    return go(
      ups,
      flatMap((up) => up._.up_cs),
      sel('length'),
    );
  },
};
