import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import Swal from 'sweetalert2';
import { parseErrorMessage } from '../../../Inhouse/F/Function/alert.inhouse.js';
import { noop } from 'fxjs/es';

export const alertsLgl = {
  prompt: {},
  success: async ({ title, msg, timer = 1000, icon_override }) => {
    DfInhouseF.lottie_loader.end();
    return await Swal.fire({
      title,
      icon: icon_override ?? 'success',
      width: 600,
      html: msg ? `<p>${msg}</p>` : undefined,
      timerProgressBar: true,
      timer,
      backdrop: true,
    });
  },
  warning: async ({ msg, title = '경고', image }) => {
    DfInhouseF.lottie_loader.end();
    await warningAlert({ title, msg, image });
  },
  error: async ({ err, title = '확인 필요', image, additional_msg }) => {
    DfInhouseF.lottie_loader.end();
    if (err?.response) {
      // 서버 응답 상태 코드가 2xx 범위를 벗어난 경우
      const data = err.response.data;
      console.error('서버 응답 에러:', err.response.status, data);
      await errorAlert({ title, data, err, image, additional_msg });
    } else if (err?.request) {
      // 서버로 요청이 전송되지 않은 경우 (네트워크 문제 등)
      console.error('요청 전송 에러:', err.request);
      await errorAlert({ title, data: '서버 응답 없음', err, image, additional_msg });
    } else {
      // 다른 에러 (예를 들어 요청을 만들 때 발생한 에러)
      console.error('일반 에러:', err?.message);
      await errorAlert({ title, data: err?.message ?? '', err, image, additional_msg });
    }
  },
  confirm: async ({
    title,
    width = 600,
    msg,
    confirmButtonText,
    cancelButtonText,
    actionsClass = 'lgl_alerts',
    confirmButtonClass = 'confirm_button',
    cancelButtonClass = 'cancel_button',
    confirmCallback = noop,
    showCancelButton = true,
  }) => {
    const result = await Swal.fire({
      title,
      html: msg || undefined,
      width,
      confirmButtonColor: '#111111',
      cancelButtonColor: '#ffffff',
      showCancelButton,
      confirmButtonText,
      cancelButtonText,
      customClass: {
        actions: actionsClass,
        confirmButton: confirmButtonClass,
        cancelButton: cancelButtonClass,
      },
      showClass: {
        popup: ``,
      },
      hideClass: {
        popup: ``,
      },
    });

    if (result.isConfirmed) {
      confirmCallback();
    }
  },
};

async function errorAlert({ title, data, err, image, additional_msg }) {
  await Swal.fire({
    title,
    html: `<span>${parseErrorMessage(data)}${additional_msg ? `<br><br>${additional_msg}` : ''}</span>`,
    width: 500,
    imageUrl: image ?? err?.image ?? '',
    imageHeight: 300,
    icon: 'error',
    backdrop: true,
    timerProgressBar: true,
    timer: 10000,
  });
}

async function warningAlert({ title, msg, image }) {
  await Swal.fire({
    title,
    html: `<span>${msg}</span>`,
    width: 500,
    imageUrl: image ?? '',
    imageHeight: 300,
    icon: 'warning',
    backdrop: true,
    timerProgressBar: true,
    timer: 6000,
  });
}
