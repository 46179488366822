import { NewMakerVirtualMakerMuiS } from '../../S/Mui/module/NewMakerVirtualMakerMuiS.js';
import { $find } from 'fxdom/es';
import { initMpMaker } from '../../../../Maker/F/mp_maker.js';
import { makeFcanvasByProductColor } from '../../../../Maker/F/draw_product_faces.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...NewMakerVirtualMakerMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  async showed(tab_el) {
    const { product_color_id } = tab_el.tab_opt;
    const don_wrapper_el = $find('.don_wrapper')(tab_el);
    const product_color = await $.get('/@api/product_color', {
      id: product_color_id,
    });
    /*DF*/
    await makeFcanvasByProductColor(product_color);
    box.set('maker', {
      product_color,
      up_c: {
        base_product_id: product_color.base_product_id,
        base_product_color_id: product_color.base_product_color_id,
        base_product_size_id: product_color.base_product_size_id,
      },
    });
    fabric.charWidthsCache = {};
    await initMpMaker(don_wrapper_el);
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
