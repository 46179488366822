import { MPAY_CHECKOUT_FRAME } from '../../../../modules/MPay/Checkout/S/constant.js';

export default function (lo) {
  $.frame({ frame_name: MPAY_CHECKOUT_FRAME });

  // kr 주문페이지
  try {
    if (typeof fbq == 'function') {
      fbq('track', 'InitiateCheckout', {
        content_type: 'product',
        contents: _p.map(box.sel('user_products'), function (up) {
          return {
            id: up.base_product_id,
            name: up._.product_color._.base_product.name,
            item_price: up._.product_color['price' + _en],
            quantity: _sum(up._.up_cs, function (up_c) {
              return _sum(up_c._.up_c_ss, function (up_c_s) {
                return up_c_s.quantity;
              });
            }),
          };
        }),
        currency: T.lang == 'kr' ? 'KRW' : 'USD',
        value: box.sel('projection_price_info->product_price'),
      });
    }

    if (typeof gtag == 'function') {
      gtag('event', 'checkout_progress', {
        dynx_itemid: _p.map(box.sel('user_products'), function (up) {
          return up.base_product_id;
        }), // 구매할 상품의 아이디 배열
        dynx_itemid2: _p.map(box.sel('user_products'), function (up) {
          return up.base_product_id;
        }), // 구매할 상품의 아이디 배열
        dynx_pagetype: 'conversionintent',
        dynx_totalvalue: box.sel('projection_price_info->product_original_price'), // 구매할 상품 총 금액
        ecomm_prodid: _p.map(box.sel('user_products'), function (up) {
          return up.base_product_id;
        }),
        ecomm_pagetype: 'conversionintent',
        event_label: 'conversionintent',
      });
    }
  } catch (err) {}
}
