import axios from 'axios';

export const apiCall = {
  /**
   * @define maker_metas 에 데이터를 신규 생성하고 bp_option_id 를 연결하는 api 호출
   * @param {string} name
   * @param {object} value
   * @param {number} bp_option_id
   * @return {Promise<{ bp_option_id: number, maker_meta_id:number }>}
   * */
  createMakerMeta: async ({ name, value, bp_option_id }) => {
    return (await axios.post('/@api/maker/meta', { name, value, bp_option_id })).data;
  },
};
