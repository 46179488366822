import { $appendTo, $el, $find } from 'fxdom/es';
import { go, html, mapL, reduce } from 'fxjs/es';

export const makeItem$ = async (original_el) =>
  new Promise((resolve, reject) =>
    requestAnimationFrame(() => {
      try {
        resolve(original_el.cloneNode(true));
      } catch (error) {
        reject(error);
      }
    }),
  ).then(
    (clone_el) =>
      new Promise((resolve, reject) =>
        requestAnimationFrame(() => {
          try {
            const item_el = $el(html`
              <div class="item swiper-slide" data-is_locked="true">
                <div class="item_wrapper">
                  <svg
                    class="img"
                    width="100"
                    height="100"
                    viewBox="0 0 0 0"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g class="dummy"></g>
                  </svg>
                  <div class="lock"><span></span></div>
                  <div class="unlock"><span></span></div>
                </div>
              </div>
            `);
            const svg_el = $find('svg')(item_el);
            svg_el.appendChild(clone_el);
            resolve(item_el);
          } catch (error) {
            reject(error);
          }
        }),
      ),
  );

export const initItemImgSize$ = async ({ parent_el, item_el }) => {
  await new Promise((resolve, reject) =>
    requestAnimationFrame(() => {
      try {
        $appendTo(parent_el)(item_el);
        resolve();
      } catch (error) {
        reject(error);
      }
    }),
  );

  const svg_el = $find('svg')(item_el);

  const { bbox, screen_ctm } = await new Promise((resolve, reject) =>
    requestAnimationFrame(() => {
      try {
        const dummy_g_el = svg_el.querySelector('.dummy');
        const { lastElementChild: el } = svg_el;

        const dummy_g_el_screen_ctm = dummy_g_el.getScreenCTM();
        const bbox = el.getBBox();
        const el_screen_ctm = el.getScreenCTM();
        const screen_ctm = dummy_g_el_screen_ctm.inverse().multiply(el_screen_ctm);

        resolve({ bbox, screen_ctm });
      } catch (error) {
        reject(error);
      }
    }),
  );

  const view_box = await new Promise((resolve, reject) =>
    requestAnimationFrame(() => {
      try {
        const view_box = go(
          [
            [bbox.x, bbox.y],
            [bbox.x + bbox.width, bbox.y],
            [bbox.x + bbox.width, bbox.y + bbox.height],
            [bbox.x, bbox.y + bbox.height],
          ],
          mapL(([x, y]) => {
            const point = svg_el.createSVGPoint();
            point.x = x;
            point.y = y;
            return point.matrixTransform(screen_ctm);
          }),
          (points) =>
            reduce(
              (acc, point) => {
                acc.min_x = Math.min(acc.min_x, point.x);
                acc.min_y = Math.min(acc.min_y, point.y);
                acc.max_x = Math.max(acc.max_x, point.x);
                acc.max_y = Math.max(acc.max_y, point.y);
                return acc;
              },
              { min_x: Infinity, min_y: Infinity, max_x: -Infinity, max_y: -Infinity },
              points,
            ),
          ({ min_x, min_y, max_x, max_y }) =>
            `${min_x - 8} ${min_y - 8} ${max_x - min_x + 16} ${max_y - min_y + 16}`,
        );
        resolve(view_box);
      } catch (error) {
        reject(error);
      }
    }),
  );

  svg_el.setAttributeNS(null, 'viewBox', view_box);
};

export const getItemWidthAsync$ = (item_el) =>
  new Promise((resolve, reject) =>
    requestAnimationFrame(() => {
      try {
        resolve(item_el.getBoundingClientRect().width);
      } catch (error) {
        reject(error);
      }
    }),
  );
