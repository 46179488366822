import { makeMiddleBannerHtml, makeSwipeBannerHtml, makeTopBannerHtml } from './tmpl.js';

export const LANG_TO_KR = {
  kr: '국문',
  en: '영문',
  jp: '일문',
};

export const BANNER_FUNCTIONS = {
  top_banners: makeTopBannerHtml,
  middle_banners: makeMiddleBannerHtml,
  swipe_banners: makeSwipeBannerHtml,
};
