import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

(function () {
  function on_off(el_menu) {
    if (!UtilF.isLegacyMobile()) return;
    return new Promise(function (resolve) {
      const duration = 500;
      const show_hide_duration = 400;
      const easing = 'easeInOutQuart';

      const el_maker_frame = $1('#maker_frame');
      const el_lis = $.find(el_menu, 'ul.body > li');
      const el_texts = $.find(el_lis, '.text');
      const el_super_lis = $.find(el_maker_frame, '.super_menu ul.body > li');
      const el_super_texts = $.find(el_super_lis, '.text');

      if ($.has_class(el_maker_frame, 'decoration_mode')) {
        $.remove_class(el_maker_frame, 'decoration_mode');
        $.remove_class(el_maker_frame, 'decoration_mode_a');
        setTimeout(function () {
          $.hide(el_super_lis);
          resolve();
        }, show_hide_duration);

        anime({
          targets: el_super_lis,
          translateY: 0,
          duration: duration,
          easing: easing,
        });
        anime({
          targets: el_super_texts,
          duration: 10,
          easing: easing,
          opacity: 0,
        });
      } else {
        $.add_class(el_maker_frame, 'decoration_mode');
        $.show(el_lis);
        setTimeout(function () {
          $.add_class(el_maker_frame, 'decoration_mode_a');
          resolve();
        }, show_hide_duration);

        _p.each(el_lis, function (el, i) {
          anime({
            targets: el,
            translateY: -72 * (i + 1),
            duration: duration,
            easing: easing,
          });
        });

        anime({
          targets: el_texts,
          duration: 200,
          delay: 200,
          easing: easing,
          opacity: 1,
        });
      }
    });
  }

  G.mp.maker.super_menu = {
    on_off: on_off,
    // reset_background_card: reset_background_card
  };
})();
