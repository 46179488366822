import { $delegate, $hide, $qsa, $show } from 'fxdom/es';
import { getBaseProductInMaker } from '../../../../Maker/F/getSth.js';
import { NewMakerCallConditionPopupF } from '../../../CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { each, entries, filter, find, go, html, identity, join, map, some, tap } from 'fxjs/es';
import { NewMakerCheckConditionConstantS } from '../../../CheckCondition/S/Constant/module/NewMakerCheckConditionConstantS.js';
import { NewMakerConditionPopupF } from '../../../ConditionPopup/F/Function/module/NewMakerConditionPopupF.js';
import { NewMakerComponentsAtomTmplS } from '../../../Components/Atom/S/Tmpl/module/NewMakerComponentsAtomTmplS.js';
import { NewMakerConditionAgreeTextF } from './module/NewMakerConditionAgreeTextF.js';

const makeConditionAgreeTextWrapper = (result) => {
  return html` <div class="condition_agree_text_wrapper">
    ${go(
      result,
      map(
        (confirm_popup) =>
          html`
            <div class="text_wrapper">
              <p class="warning_text">${confirm_popup[`cart_modal_agreement_text${G._en}`]}</p>
              <p class="view_more" data-type="${confirm_popup.type}">${T('translation::자세히 보기')}</p>
            </div>
          `,
      ),
      join(''),
    )}
  </div>`;
};

export const fn = (cv_obj, is_check_view) => {
  const is_mapping = NewMakerCallConditionPopupF.is_mapping_confirm_popup();
  if (!is_mapping) return html``;
  const result = getAgreeTextData(cv_obj?.cid, is_check_view);
  return html`
    ${makeConditionAgreeTextWrapper(result)}
    ${is_check_view
      ? result?.length
        ? html`
            <div class="condition_agree_text_checkbox_wrapper">
              ${NewMakerComponentsAtomTmplS.emptyCheckbox({
                style: { size: 'small' },
                name: 'warning_checkbox',
                title: TT('maker::agree_text'),
              })}
            </div>
          `
        : html``
      : html``}
  `;
};

export const renderActiveObj = (cv_object) => {
  const is_mapping = NewMakerCallConditionPopupF.is_mapping_confirm_popup();
  if (!is_mapping) return;
  go(
    $qsa('.condition_agree_text'),
    each((el) => {
      if (el.dataset.route === 'home') {
        el.innerHTML = fn(null, true);
        if (!getAgreeTextData(null, true)?.length) {
          $hide(el);
        } else {
          $show(el);
        }
      } else {
        el.innerHTML = fn(cv_object);
      }
    }),
  );
};

export const changeEvent = (el, plus_sel = '') =>
  go(
    el,
    tap(
      $delegate('change', `${plus_sel} input[name="warning_checkbox"]`, (e) => {
        const checked = e.target.checked;
        const maker_done_els = $qsa('.mp_maker_done');
        const complete_els = $qsa('.condition_tooltip_btn.complete');

        go(
          maker_done_els,
          each((el) => {
            if (checked) {
              el.classList.remove('btn_disabled');
            } else {
              el.classList.add('btn_disabled');
            }
          }),
        );
        go(
          complete_els,
          each((el) => {
            if (checked) {
              el.classList.remove('disabled');
            } else {
              el.classList.add('disabled');
            }
          }),
        );
      }),
    ),
  );

export const clickEvent = (el, plus_sel = '') =>
  go(
    el,
    tap(
      $delegate('click', `${plus_sel} .view_more`, async (e) => {
        const target = e.target;
        const type = target.dataset.type;

        return NewMakerConditionPopupF.open(type);
      }),
    ),
  );

export const checkAddCartDisabled = () => {
  const is_disabled =
    NewMakerCallConditionPopupF.is_mapping_confirm_popup() &&
    NewMakerConditionAgreeTextF.getAgreeTextData()?.length;
  go(
    $qsa('.mp_maker_done'),
    each((to_option) => {
      if (is_disabled) {
        to_option.classList.add('btn_disabled');
      } else {
        to_option.classList.remove('btn_disabled');
      }
    }),
  );
};

export const agreeTextCount = () => {
  const is_mapping = NewMakerCallConditionPopupF.is_mapping_confirm_popup();
  if (!is_mapping) return 0;
  const confirm_popups = box().maker ? getBaseProductInMaker()._?.confirm_popups : [];
  if (!confirm_popups.length) return 0;

  const result = getAgreeTextData(null, true);
  return result.length;
};

export function getAgreeTextData(cid, with_wrong_text) {
  const confirm_popups = getBaseProductInMaker()._?.confirm_popups;
  if (!confirm_popups?.length) return [];
  const is_passes = go(
    NewMakerCallConditionPopupF.conditionPass,
    entries,
    filter(([key]) => key.match(/^c.*[0-9]$/)),
    cid ? filter(([key]) => key === cid) : identity,
    map(([, value]) => value),
  );

  const result = go(
    confirm_popups,
    filter((popup) => popup.type !== NewMakerCheckConditionConstantS.CONFIRM_POPUP_WRONG_TEXT_TYPE),
    filter((popup) =>
      go(
        is_passes,
        filter((pass) => pass[popup.type]),
        map((pass) => pass[popup.type].is_pass),
        some((v) => !v),
      ),
    ),
  );
  if (with_wrong_text) {
    const wrong_text = go(
      confirm_popups,
      find((popup) => popup.type === NewMakerCheckConditionConstantS.CONFIRM_POPUP_WRONG_TEXT_TYPE),
    );

    if (result?.length && wrong_text) {
      result.push(wrong_text);
    }
  }

  return result;
}
