import { go, range, map, object } from 'fxjs/es';

// ASCII 코드 색상
const ASCII_COLORS = {
  black: '\x1b[30m',
  red: '\x1b[31m',
  green: '\x1b[32m',
  yellow: '\x1b[33m',
  blue: '\x1b[34m',
  magenta: '\x1b[35m',
  cyan: '\x1b[36m',
  white: '\x1b[37m',
  bg_black: '\x1b[40m',
  bg_red: '\x1b[41m',
  bg_green: '\x1b[42m',
  bg_yellow: '\x1b[43m',
  bg_blue: '\x1b[44m',
  bg_magenta: '\x1b[45m',
  bg_cyan: '\x1b[46m',
  bg_white: '\x1b[47m',
  reset: '\x1b[0m',
};

/**
 * @define console.log 색상 style 로깅 (vanilla)
 * @param {string[]} texts 로그 문자열
 * @param {string[]} colors 로그 문자색
 * @param {string[]} bg_colors 로그 문자 배경색
 * @return {undefined}
 * */
export function colorLog(texts = [], colors = [], bg_colors = []) {
  if (texts.length === 0) return;

  let output = '';
  for (let i = 0; i < texts.length; i++) {
    const textColor = ASCII_COLORS[colors[i]] || ASCII_COLORS.white;
    const bgColor = ASCII_COLORS[bg_colors[i]] || ASCII_COLORS.reset;

    output += bgColor + textColor + texts[i];
  }
  output += ASCII_COLORS.reset;
  console.log(output);
}

/**
 * @define stride 간격만큼만 log 를 한번씩 찍어줌 (percent 진행률 변경시마다 너무 많은 로그 방지)
 *         (ex. stride 10 이면 0, 10, 20, ... 100 % 될때만 logging)
 *         (ex. stride 25 이면 0, 25, 50, 75, 100 % 될때만 logging)
 * @param {number} stride 간격
 * @param {function} log_fn
 * @return {undefined}
 * */
export const percentStrideLogger = ({ stride }, log_fn) => {
  const log_histogram = go(
    range(0, 100, stride),
    map((_) => [_, false]),
    object,
  );

  return (percent) => {
    if (percent >= 100) {
      log_fn(percent);
      return;
    }
    const target = percent - (percent % stride);
    const is_logged = log_histogram[target];
    if (!is_logged) {
      log_fn(percent);
      log_histogram[target] = true;
    }
  };
};
