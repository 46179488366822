import { DfImageEditorF } from './module/DfImageEditorF.js';
import { groupBy, filter, go, head, every } from 'fxjs/es';
import { SVG_EL_ITEM } from '../../S/Constant/constants.js';
import { handleSvgImageFile, setWidthAndHeightIntoCm } from './tool.file.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';

export async function getSvgPrintableImageWithCuttingPath({
  tab_el,
  paper_scope,
  cutting_path,
  file_info: { projection_id, up_id, face_name, total_qty },
  is_fill_color,
}) {
  const TRANSPARENT = new paper_scope.Color(0, 0, 0, 0);

  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });

  let cut;
  if (is_fill_color) {
    const paths = DfImageEditorF.getAllPaths({ item: cutting_path });
    let cutting_paths;

    const group_by_clockwise = groupBy((p) => (p.clockwise ? 'outer_paths' : 'inner_paths'), paths);

    if (!UtilArrayS.isArrayOk(group_by_clockwise.inner_paths)) {
      if (!UtilArrayS.isArrayOk(group_by_clockwise.outer_paths)) {
        throw new Error(`내/외부 칼선 path 가 존재하지 않습니다.`);
      }

      /* 모두 outer path 로만 구성되어 있는 경우 -> 색상 채우고 추출  */
      const { outer_paths } = group_by_clockwise;
      outer_paths.forEach((p) => {
        p.fillColor = printable_raster.getAverageColor(p);
        p.strokeColor = TRANSPARENT;
        p.visible = true;
      });
      cutting_paths = outer_paths;
    } else {
      /* inner path 들이 존재하는 경우
       *  1. inner path 의 outer path 가 누구인지 tagging 해서 정리
       *  2. outer path 중 tagging 된 inner path 가 있으면 subtract 로 제거 process
       *  3. outer path 를 grouping
       * */
      const { outer_paths, inner_paths } = group_by_clockwise;
      inner_paths.forEach((inner_path) => {
        const outer_path = findIncludedOuterPath({ paths: outer_paths, inner_path });

        if (outer_path == null) {
          throw new Error(`Inner path 에 해당하는 outer path 를 감지하지 못했습니다.`);
        }

        if (outer_path.data?.inner_paths == null) {
          /* outer path 의 data kv 로 inner_paths 배열에 path id 를 추가 */
          outer_path.data.inner_paths = [inner_path.id];
        } else {
          outer_path.data.inner_paths.push(inner_path.id);
        }
      });

      const new_cutting_paths = [];
      outer_paths.forEach((outer_path) => {
        // outer_path.fillColor = printable_raster.getAverageColor(outer_path);
        if (outer_path.data.inner_paths && outer_path.data.inner_paths.length) {
          const inner_path_ids = outer_path.data.inner_paths;
          let new_cutting_path = outer_path.clone();
          inner_path_ids.forEach((inner_path_id) => {
            const inner_path = inner_paths.find((p) => p.id === inner_path_id);
            if (inner_path == null) {
              throw new Error(`Inner path 를 찾을 수 없습니다.`);
            }
            new_cutting_path = new_cutting_path.subtract(inner_path, { insert: false });
          });
          new_cutting_path.fillColor = printable_raster.getAverageColor(new_cutting_path);
          new_cutting_path.strokeColor = TRANSPARENT;
          new_cutting_paths.push(new_cutting_path);
        } else {
          const new_cutting_path = outer_path.clone();
          new_cutting_path.fillColor = printable_raster.getAverageColor(new_cutting_path);
          new_cutting_path.strokeColor = TRANSPARENT;
          new_cutting_paths.push(new_cutting_path);
        }
      });
      cutting_paths = new_cutting_paths;
    }
    cut = new paper_scope.Group(cutting_paths);
  } else {
    cut = cutting_path.clone();
  }
  cut.strokeColor = TRANSPARENT;
  cut.name = '칼선';

  const stroke = DfImageEditorF.getRStrokeRaster({ tab_el });

  let output_raster;
  if (stroke) {
    output_raster = stroke.clone();
    output_raster.drawImage(
      printable_raster.image,
      printable_raster.bounds.topLeft.subtract(output_raster.bounds.topLeft),
    );
  } else {
    output_raster = printable_raster.clone();
  }
  output_raster.name = SVG_EL_ITEM.raster;

  /* 1. 시안 정보 QR 코드, 주문 정보, 디자인 크기 등
   *     - 추가하지 않는 것으로 스펙 변경되어 commented out
   * */
  // const info_group = await DfImageEditorF.getExportFileInfoGroup({
  //   tab_el,
  //   projection_id,
  //   up_id,
  //   face_name,
  //   total_qty,
  // });
  /* --- 여기까지 기존 project 내의 item 들을 가지고 파일 추출을 위한 post-processing 진행 */
  /* project 깨끗하게 정리한 다음에 export layer 한 층을 추가해서 여기에 추출할 정리된 객체들을 담음 */
  paper_scope.project.clear();
  const export_layer = new paper_scope.Layer({ insert: true });

  /* 1. path 에 색상을 채우는 경우 (ex. FLEX) "칼선"
     - 디자인 이미지는 필요 없기 떄문에 추출하지 않고 칼선만 추출함.
   * 2. 일반적인 경우에는 raster 이미지와 칼선을 함께 추출 "디자인 칼선"
  */
  if (is_fill_color) {
    export_layer.addChild(cut);
  } else {
    const raster_and_path_group = new paper_scope.Group();
    raster_and_path_group.addChild(output_raster);
    raster_and_path_group.addChild(cut);
    raster_and_path_group.name = '디자인 칼선';
    export_layer.addChild(raster_and_path_group);
  }

  // export_layer.addChild(info_group);
  export_layer.visible = true;
  export_layer.position = new paper_scope.Point(0, 0).subtract(export_layer.bounds.topLeft);

  // const export_svg = DfImageEditorF.sanitizeSvgEl({
  //   svg_el: paper_scope.project.exportSVG({
  //     bounds: 'content',
  //     embedImages: true,
  //   }),
  // });
  const export_svg = paper_scope.project.exportSVG({
    bounds: 'content',
    embedImages: true,
  });

  setWidthAndHeightIntoCm({ svg_el: export_svg });

  return {
    svg_string: new XMLSerializer().serializeToString(export_svg),
    // svg_base64: await DfImageEditorF.svgToBase64({ svg_el: export_svg }),
    svg_image_base64: handleSvgImageFile({ tab_el, svg_image_canvas: output_raster.canvas }),
  };
}

function findIncludedOuterPath({ paths, inner_path }) {
  return go(
    paths,
    filter((test_path) => {
      if (test_path !== inner_path) {
        return every((inner_seg) => test_path.contains(inner_seg.point), inner_path.segments);
      }
    }),
    head,
  );
}
