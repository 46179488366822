import { VectorEditorAcrylicFigureFreeMobileCuttingLineManualMuiS } from '../../S/Mui/module/VectorEditorAcrylicFigureFreeMobileCuttingLineManualMuiS.js';
import { $find, $findAll, $on } from 'fxdom/es';
import { each, go, tap } from 'fxjs/es';
import { EditorNS } from '@marpple/sticker-editor';
import { VectorEditorAcrylicFigureFreeMobileCuttingLineManualF } from '../Function/module/VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorAcrylicFigureFreeMobileCuttingLineManualMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_acrylic_figure_cutting_line_editor = null;
    tab_el.__mp_base_outer_cutting_line_path_data =
      tab_el.__mp_base_outer_cutting_line_path_data ?? 'M 0 0 Z';
    tab_el.__mp_base_inner_cutting_line_path_data =
      tab_el.__mp_base_inner_cutting_line_path_data ?? 'M 0 0 Z';
    tab_el.__mp_art_board_width = tab_el.__mp_art_board_width ?? 0;
    tab_el.__mp_art_board_height = tab_el.__mp_art_board_height ?? 0;
    tab_el.__mp_ucs_x = tab_el.__mp_ucs_x ?? 0;
    tab_el.__mp_ucs_y = tab_el.__mp_ucs_y ?? 0;
    tab_el.__mp_ucs_width = tab_el.__mp_ucs_width ?? 0;
    tab_el.__mp_ucs_height = tab_el.__mp_ucs_height ?? 0;
    tab_el.__mp_stand_leg_ground_size_delta = tab_el.__mp_stand_leg_ground_size_delta ?? 4;
    tab_el.__mp_stand_leg_ground_position_delta = tab_el.__mp_stand_leg_ground_position_delta ?? 1;
    tab_el.__mp_stand_leg_ground_min_width = tab_el.__mp_stand_leg_ground_min_width ?? 0;
    tab_el.__mp_stand_leg_ground_max_width = tab_el.__mp_stand_leg_ground_max_width ?? 0;
    tab_el.__mp_stand_leg_ground_width = tab_el.__mp_stand_leg_ground_width ?? 0;
    tab_el.__mp_stand_leg_ground_height = tab_el.__mp_stand_leg_ground_height ?? 0;
    tab_el.__mp_stand_leg_notch_round = tab_el.__mp_stand_leg_notch_round ?? 1;
    tab_el.__mp_stand_leg_notch_width = tab_el.__mp_stand_leg_notch_width ?? 0;
    tab_el.__mp_stand_leg_notch_height = tab_el.__mp_stand_leg_notch_height ?? 0;
    tab_el.__mp_makeTargetImageEl = tab_el.__mp_makeTargetImageEl ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_inner_cutting_line_update_timeout_id = null;

    go(
      tab_el,

      tap($on('touchmove', (event) => event.preventDefault(), { passive: false })),

      tap(
        $on(
          '@mp/sticker-editor/outer-cutting-line-changed',
          VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.handleEditorOuterCuttingLineChanged({
            tab_el,
          }),
        ),
      ),

      tap(
        $findAll(`.header .cancel`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.handleHeaderCancelButtonClick()),
        ),
      ),
      tap(
        $findAll(`.header .done`),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.handleHeaderDoneButtonClick({ tab_el }),
          ),
        ),
      ),

      tap(
        $findAll(`.footer .footer_menu.reset`),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.handleFooterResetIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.add_l`),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.handleFooterAddLIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.add_c`),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.handleFooterAddCIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.remove`),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.handleFooterRemoveIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.change_to_l`),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.handleFooterChangeToLIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.change_to_c`),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.handleFooterChangeToCIconClick({ tab_el }),
          ),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    const acrylic_figure_cutting_line_editor =
      new EditorNS.AcrylicCuttingLineEditorNS.MobileNS.AcrylicCuttingLineMobileEditor({
        window,
        document,
        touch_margin: 6,
        min_zoom_scale: 1,
        max_zoom_scale: 3,
      });
    tab_el.__mp_acrylic_figure_cutting_line_editor = acrylic_figure_cutting_line_editor;
    acrylic_figure_cutting_line_editor.initDOM();
    acrylic_figure_cutting_line_editor.initEditor();

    const editor_container_el = $find('.editor_container')(tab_el);
    if (editor_container_el == null) {
      const error = new Error(`editor_container 클래스를 가진 엘리먼트를 찾을 수 없습니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::AcrylicFigure::Free::Mobile::CuttingLineManual::template::error_message::no_editor_container_el',
      );
      throw error;
    }
    acrylic_figure_cutting_line_editor.appendTo(editor_container_el);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  async rendered(tab_el) {
    const footer_container_el = $find(`.footer_container`)(tab_el);
    if (footer_container_el != null) {
      new Swiper(footer_container_el, { slidesPerView: 'auto', freeMode: true });
    }

    if (tab_el.__mp_acrylic_figure_cutting_line_editor == null) {
      const error = new Error(`tab_el.__mp_acrylic_figure_cutting_line_editor 객체가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::AcrylicFigure::Free::Mobile::CuttingLineManual::template::error_message::no_acrylic_figure_cutting_line_editor',
      );
      throw error;
    }
    const editor = tab_el.__mp_acrylic_figure_cutting_line_editor;

    if (tab_el.__mp_makeTargetImageEl == null) {
      const error = new Error(`tab_el.__mp_makeTargetImageEl 함수가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::AcrylicFigure::Free::Mobile::CuttingLineManual::template::error_message::no_make_target_image_el',
      );
      throw error;
    }
    const makeTargetImageEl = tab_el.__mp_makeTargetImageEl;

    $.don_loader_start();
    try {
      editor.fitSize();

      const target_image_el = await makeTargetImageEl({ factor: 1 });
      editor.setTargetImageEl(target_image_el);

      editor.setOuterCuttingLinePathData(tab_el.__mp_base_outer_cutting_line_path_data);
      editor.setInnerCuttingLinePathData(tab_el.__mp_base_inner_cutting_line_path_data);

      const outer_cutting_line_path_el = editor.getOuterCuttingLinePathEl();
      const outer_cutting_line_path_el_bbox = outer_cutting_line_path_el.getBBox();
      tab_el.__mp_ucs_x = outer_cutting_line_path_el_bbox.x;
      tab_el.__mp_ucs_y = outer_cutting_line_path_el_bbox.y;
      tab_el.__mp_ucs_width = outer_cutting_line_path_el_bbox.width;
      tab_el.__mp_ucs_height = outer_cutting_line_path_el_bbox.height;
      const view_box_vo = editor.calculateViewBoxFitTo({
        size_in_ucs: {
          x: tab_el.__mp_ucs_x - 5,
          y: tab_el.__mp_ucs_y - 5,
          width: tab_el.__mp_ucs_width + 10,
          height: tab_el.__mp_ucs_height + 10,
        },
        padding_in_vcs: { top: 16 + 48, right: 16, bottom: 16 + 144, left: 16 },
        vertical_align: 'center',
      });
      editor.setViewBox(view_box_vo);

      VectorEditorAcrylicFigureFreeMobileCuttingLineManualF.setSizeGuide({ tab_el, editor });

      editor.startEditCuttingLinePath();
    } finally {
      $.don_loader_end();
    }
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, _v) {
    if (tab_el.__mp_inner_cutting_line_update_timeout_id != null) {
      const timeout_id = tab_el.__mp_inner_cutting_line_update_timeout_id;
      clearTimeout(timeout_id);
    }
    if (tab_el.__mp_acrylic_figure_cutting_line_editor != null) {
      const editor = tab_el.__mp_acrylic_figure_cutting_line_editor;
      editor.destroy();
    }

    tab_el.__mp_acrylic_figure_cutting_line_editor = null;
    tab_el.__mp_base_outer_cutting_line_path_data = 'M 0 0 Z';
    tab_el.__mp_base_inner_cutting_line_path_data = 'M 0 0 Z';
    tab_el.__mp_art_board_width = 0;
    tab_el.__mp_art_board_height = 0;
    tab_el.__mp_ucs_x = 0;
    tab_el.__mp_ucs_y = 0;
    tab_el.__mp_ucs_width = 0;
    tab_el.__mp_ucs_height = 0;
    tab_el.__mp_stand_leg_ground_size_delta = 0;
    tab_el.__mp_stand_leg_ground_position_delta = 0;
    tab_el.__mp_stand_leg_ground_min_width = 0;
    tab_el.__mp_stand_leg_ground_max_width = 0;
    tab_el.__mp_stand_leg_ground_width = 0;
    tab_el.__mp_stand_leg_ground_height = 0;
    tab_el.__mp_stand_leg_notch_round = 1;
    tab_el.__mp_stand_leg_notch_width = 0;
    tab_el.__mp_stand_leg_notch_height = 0;
    tab_el.__mp_makeTargetImageEl = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_inner_cutting_line_update_timeout_id = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
