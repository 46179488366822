import { html } from 'fxjs/es';

const mui_ref_path = '/modules/VectorEditor/Sticker/Single/CreatorPC/PathEditor/S/Mui/tab.js';
/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */

const translate_base = 'modules::VectorEditor::Sticker::Single::CreatorPC::CuttingLineManual';

export const tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  title: '', // tab button의 title
  selected: false, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  makeData(tab) {}, // template 함수 인자에 들어갈 값, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
  template(data) {
    console.log(T(`${translate_base}::template::header_title`), '-------------');
    return html`
      <div class="container">
        <div class="header">
          <span class="title">${T(`${translate_base}::template::header_title`)}</span>
        </div>

        <div class="wrapper">
          <div class="button_group">
            <span class="button_group_label">${T(`${translate_base}::template::path_button_group_add`)}</span>
            <div class="button_list">
              <button type="button" class="add_l">
                <div class="button_icon inactive"></div>
                <div class="button_icon active"></div>
                <span class="button_text">${T(`${translate_base}::template::path_button_add_l`)}</span>
              </button>
              <button type="button" class="add_c">
                <div class="button_icon inactive"></div>
                <div class="button_icon active"></div>
                <span class="button_text">${T(`${translate_base}::template::path_button_add_c`)}</span>
              </button>
            </div>
          </div>
          <div class="button_group">
            <span class="button_group_label"
              >${T(`${translate_base}::template::path_button_group_delete_close`)}</span
            >
            <div class="button_list">
              <button type="button" class="remove">
                <div class="button_icon inactive"></div>
                <div class="button_icon active"></div>
                <span class="button_text">${T(`${translate_base}::template::path_button_remove`)}</span>
              </button>
              <button type="button" class="close_or_open" data-is_closed="false">
                <div class="button_icon inactive"></div>
                <div class="button_icon active"></div>
                <span class="button_text close">${T(`${translate_base}::template::path_button_close`)}</span>
                <span class="button_text open">${T(`${translate_base}::template::path_button_open`)}</span>
              </button>
            </div>
          </div>
          <div class="button_group">
            <span class="button_group_label"
              >${T(`${translate_base}::template::path_button_group_change`)}</span
            >
            <div class="button_list">
              <button type="button" class="change_to_l">
                <div class="button_icon inactive"></div>
                <div class="button_icon active"></div>
                <span class="button_text">${T(`${translate_base}::template::path_button_change_to_l`)}</span>
              </button>
              <button type="button" class="change_to_c">
                <div class="button_icon inactive"></div>
                <div class="button_icon active"></div>
                <span class="button_text">${T(`${translate_base}::template::path_button_change_to_c`)}</span>
              </button>
            </div>
          </div>
        </div>

        <div class="button_container">
          <button type="button" class="done">${T(`${translate_base}::template::prev_button`)}</button>
        </div>
      </div>
    `;
  }, // 탭에 들어갈 html 을 그리는 함수
};
