import { $qsa, $setHTML } from 'fxdom/es';
import { each } from 'fxjs/es';
import { OMPCoreLayoutTmplS } from '../../S/Tmpl/module/OMPCoreLayoutTmplS.js';

export const resetHamMenuLogin = (login_user) => {
  each((el) => {
    $setHTML(OMPCoreLayoutTmplS.hamMenuLoginHTML(login_user), el);
  }, $qsa('.mp-header-ham__section-login'));
};

// window.testOpen = testOpen;
