import { each, go } from 'fxjs/es';
import { $findAll } from 'fxdom/es';

// const dom_parser = new DOMParser();
// const parseSVGFromStr = (svg_str) => dom_parser.parseFromString(svg_str, 'image/svg+xml');
export const changeSvgColorToOG = (svg_el) => {
  const color = '#FF6B00';
  if (svg_el.getAttribute('stroke') === '#0157FF') svg_el.setAttribute('stroke', color);
  if (svg_el.getAttribute('stroke') === '#FF00FF') svg_el.setAttribute('stroke', color);
  // const svg_el = await go(
  //   axios.get(url, { responseType: 'text', params: { now: `${Date.now()}` } }),
  //   ({ data }) => data,
  //   parseSVGFromStr,
  //   (svg_doc) => svg_doc.querySelector('svg'),
  // );
  go(
    svg_el,
    $findAll('text'),
    each((text) => {
      text.setAttribute('fill', color);
    }),
  );

  go(
    svg_el,
    $findAll('[stroke="#0157FF"]'),
    each((svg_el) => {
      svg_el.setAttribute('stroke', color);
    }),
  );
  go(
    svg_el,
    $findAll('[stroke="#FF00FF"]'),
    each((svg_el) => {
      svg_el.setAttribute('stroke', color);
    }),
  );

  return svg_el;
  // const svgString = new XMLSerializer().serializeToString(svg_el);
  // return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;
  // Step 2: Convert the SVG string to base64
  // const blob = new Blob([svgString], { type: 'image/svg+xml' });
  // const file = new File([blob], 'file.svg', { type: blob.type });
  // // Step 3: Create a Data URL
  // const formData = new FormData();
  // formData.append('files[]', file);
  // try {
  //   return go(
  //     $.upload(formData, {
  //       url: '/@api/upload_svg_for_legacy',
  //       data: { url },
  //     }),
  //     ({ url }) => {
  //       return url;
  //     },
  //   );
  // } catch (e) {
  //   return {};
  // }
};
