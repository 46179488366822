import { DfInhouseF } from './module/DfInhouseF.js';
import { DfInhouseConstantS } from '../../S/Constant/module/DfInhouseConstantS.js';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';

const {
  PACK: { key: pack_mode },
  UNPACK: { key: unpack_mode },
} = DfInhouseConstantS.BUCKET_MODE_KEY;
const {
  COMPLETE: { key: complete_command },
} = DfInhouseConstantS.BUCKET_CONTROL_NAME;

// @description 운송장 컨트롤 QR code 스캔했을 때 처리
export async function handleScanWaybillControlCode({ mode, waybill_no }) {
  const bucket_scanned = DfInhouseF.stateBucket.gBox.get.inhouseShippingBucketByWaybillNo({ waybill_no });

  if (bucket_scanned == null) {
    throw new Error(`스캔하신 운송장 번호 <b>(${waybill_no})</b><br>를 가진 버킷이 존재하지 않습니다.`);
  }

  /* 전체 모듈 공통
   *   1. toggle selection
   *   2. 스캔된 bucket hydration
   * */

  await DfInhouseF.stateDOM.update.toggleSelected(
    DfInhouseF.stateDOM.get.bucketEntityEl({ bucket_id: bucket_scanned.id }),
  );

  await DfInhouseF.stateBucket.gBox.hydrate.bucket({ bucket_id: bucket_scanned.id });

  DfInhouseF.stateBucket.scrollTo({ bucket_id: bucket_scanned.id });
  switch (mode) {
    case pack_mode:
    case unpack_mode: {
      // 모드 전환
      DfInhouseF.stateDOM.update.toggleBucketModeButton({
        mode_name: mode,
      });

      // 전환된 버킷, 모드 정보 표시 알림
      const bucket_crew_inhouse_shipping = DfInhouseF.stateBucket.gBox.get.crewInhouseShippingForBucket({
        bucket_id: bucket_scanned.id,
      });
      const { company_address, location_name } = bucket_crew_inhouse_shipping;
      await DfInhouseF.alert.success({
        title: bucket_scanned.is_packed ? '포장 완료 버킷' : DfInhouseConstantS.BUCKET_MODE_KEY[mode].name,
        icon_override: mode === DfInhouseConstantS.BUCKET_MODE_KEY.PACK.key ? 'success' : 'warning', // 포장 모드일때 긍정 표시
        msg: `
          <b>회사</b>: ${location_name}
          <br><br>
          <b>주소</b>: ${company_address}
          <br><br>
          <b>운송장</b>: ${UtilStringS.insertHyphens({ input_str: waybill_no, chunk_size: 4 })}
          <br><br>
         `,
        timer: 3000,
      });
      break;
    }
    case complete_command: {
      // 버킷 완료 처리
      await DfInhouseF.setBucketComplete({ bucket: bucket_scanned });
      break;
    }
    default:
      throw new Error(`인식 불가능한 운송장 스캔 코드입니다. ${mode}`);
  }
}

// @description 주문 박스 라벨 바코드 스캔했을 때 처리
export async function handleScanProjectionBox({ projection_id }) {
  /*
   * 1. projection_id 로 쿼리 날림
   *    - 유효성 판단
   *     -> 주문 데이터 없으면 유효하지 않은 주문번호 입니다 alert.
   *
   * 2. projection_id 를 가진 inhouse_shipping_box 데이터 가져오기
   *    - 유효성 판단
   *     -> box 데이터 없으면, 주문 박스 라벨 부착 처리를 먼저 하셔야 합니다. 주문서 링크 alert
   *    -
   *
   * 2. 현재 모드가 담기인지, 제거인지 DOM 에서 가져오기
   *  2-1. 담기 일때,
   *    - 유효성 판단
   *      - box 데이터에 inhouse_shipping_bucket_id 가 이미 있어서 담겨 있는 경우
   *       -> 이미 xxx 운송장 버킷에 담겨 있어요 alert
   *      - bucket 정보의 customer_order_crew_inhouse_id 와 box 의 customer_order_crew_inhouse_id 가 같은지 여부 확인 (운송장은 달라도 배송지가 같아야 함)
   *       -> 다르면, 해당 주문은 배송지가 xxx 인데 ooo 발송 버킷에 담기를 시도하고 있습니다. alert 처리
   *    - box 데이터에 inhouse_shipping_bucket_id 를 PUT
   *    - await hydrating
   *    - 끝났으면 짧은 timer 로 success alert
   *  2-2. 제거 일때,
   *    - 유효성 판단
   *      - box 에 inhouse_shipping_bucket_id 가 존재 여부 확인
   *        - 존재 X
   *          -> bucket 에 담기지 않은 주문 박스 입니다. "담기 모드" 로 변경한 뒤 재 스캔하여 버킷에 주문 박스를 담아주세요.
   *        - 존재 O, but 현재 선택된 bucket waybill 이 아닌 곳에 담겨 있음.
   *          -> 다른 bucket 에 담겨 있어서 제거할 수 없습니다. 해당 운송장을 가진 버킷에서 제거 모드를 진행해 주세요.
   *    - bucket 에서 제거
   *    - await hydrating
   *    - 끝났으면 짧은 timer 로 success alert
   * */

  // 가드 - 선택된 버킷이 없을 때
  const bucket_selected = DfInhouseF.stateDOM.get.selectedBucket(true);

  if (bucket_selected == null) {
    throw new Error(`선택된 버킷이 없습니다.<br>주문 박스를 담을 버킷을 선택하셔야 합니다.`);
  }

  // 가드 - 유효한 주문번호 인지 확인
  const projection_status = await DfInhouseF.apiCalls.get.projectionStatus({ projection_id });
  if (projection_status == null) {
    throw new Error(`유효하지 않은 주문 번호 입니다.`);
  }

  // 가드 - 취소된 건인지 확인
  const { status, is_cancel_req, is_canceled } = projection_status;
  if (status === 'canceled' || is_cancel_req || is_canceled) {
    throw new Error(`취소된 주문 건 입니다.`);
  }

  if (['before_order', 'ordering', 'before_print'].includes(status)) {
    throw new Error(`아직 제작 시작 전 단계 주문입니다.`);
  }

  const projection_link_html = DfInhouseF.getProjectionLinkHtml({ projection_id });

  // 가드 - 개인 배송으로 전환된 건을 버킷에 담으려고 하는 시도인지 확인
  const { customer_order_crew_inhouse } = await DfInhouseF.apiCalls.get.customerOrderCrewInhouseFromPrjId({
    projection_id,
  });
  if (customer_order_crew_inhouse.is_hidden) {
    throw new Error(
      `본 주문 박스는 개인배송으로 전환된 건 입니다.<br>${projection_link_html} 에서 확인해주세요.`,
    );
  }

  // 가드 - 주문서 라벨을 출력하고 포장이 된 건인지 확인
  const box_scanned = await DfInhouseF.apiCalls.get.inhouseShippingBoxFromPrjId({
    projection_id,
  });

  if (box_scanned == null) {
    throw new Error(
      `아직 주문 박스 생성이 되지 않았습니다.<br>${projection_link_html} 로 가셔서 라벨 출력을 먼저 하세요.`,
    );
  }

  const bucket_selected_shipping = bucket_selected._.crew_inhouse_shipping;
  const {
    inhouse_shipping_bucket_id,
    _: {
      customer_order_crew_inhouse: {
        _: { crew_inhouse_shipping: box_scanned_shipping },
      },
      inhouse_shipping_bucket: bucket_scanned,
    },
  } = box_scanned;

  const bucket_mode_selected = DfInhouseF.stateDOM.get.bucketMode();

  const { PACK, UNPACK } = DfInhouseConstantS.BUCKET_MODE_KEY;

  switch (bucket_mode_selected) {
    case PACK.key: {
      // 가드 - 이미 버킷에 담겨서 bucket_id 를 가지고 있으면 안됨.
      if (inhouse_shipping_bucket_id != null) {
        throw new Error(
          `<b>운송장: ${bucket_scanned.waybill_no}<br>배송지: ${box_scanned_shipping.location_name}</b><br>버킷에 이미 담긴 주문 박스입니다.`,
        );
      }

      // 가드 - 버킷의 배송지가 스캔한 박스의 배송지와 동일해야 함.
      if (Number(bucket_selected_shipping.id) !== Number(box_scanned_shipping.id)) {
        throw new Error(
          `스캔하신 주문박스의 배송지가 버킷의 배송지와 다릅니다.<br>다른 버킷에 담으려는 실수일 수 있습니다.<br>배송지를 재확인 해 주세요.<br><br><b>주문 배송지</b>: [${box_scanned_shipping.company_name}] ${box_scanned_shipping.company_address}<br><b>버킷 배송지</b>: [${bucket_selected_shipping.company_name}] ${bucket_selected_shipping.company_address}<br>${projection_link_html}`,
        );
      }

      const { id: updated_box_id } = await DfInhouseF.apiCalls.put.boxLinkingToBucket({
        box_id: box_scanned.id,
        bucket_id: bucket_selected.id,
      });

      // box -> bucket 연결
      const updated_box = await DfInhouseF.apiCalls.get.inhouseShippingBoxFromId({
        inhouse_shipping_box_id: updated_box_id,
      });

      // gBox sync
      DfInhouseF.stateBucket.gBox.insert.inhouseShippingBox({
        bucket_id: bucket_selected.id,
        inhouse_shipping_box: updated_box,
      });

      // 테이블에 박스 row 추가
      await DfInhouseF.boxTable.addBoxDataToRow({
        inhouse_shipping_box: updated_box,
        target_bucket_id: bucket_selected.id,
      });

      // box counter 업데이트
      await DfInhouseF.stateDOM.update.uptoDateBoxCounter({ target_bucket_id: bucket_selected.id });

      DfInhouseF.stateDOM.update.bucketImageStatus({
        bucket_id: bucket_selected.id,
        is_packed: false,
        box_ctn: DfInhouseF.stateDOM.get.selectedBucketBoxes(true).length,
      });

      DfInhouseF.stateBucket.scrollTo({ bucket_id: bucket_selected.id });

      await DfInhouseF.alert.success({ title: '담기 성공', timer: 800 });
      break;
    }
    case UNPACK.key: {
      // 가드 - 버킷에 담겨 있어야 제거가 가능.
      if (inhouse_shipping_bucket_id == null) {
        throw new Error(`해당 주문 박스는 버킷에 담겨있지 않습니다.<br>제거할 필요가 없습니다.`);
      } else {
        // 가드 - 운송장이 같은 버킷에서 제거되어야 함.
        if (bucket_selected.waybill_no !== bucket_scanned.waybill_no) {
          throw new Error(
            `<b>운송장: ${bucket_selected.waybill_no}<br>배송지: ${bucket_selected._.crew_inhouse_shipping.location_name}</b><br><br>다른 버킷에 담긴 주문 박스입니다.<br>해당 운송장을 가진 버킷에서 <b>제거 모드</b>를 실행해 주세요.`,
          );
        }

        // box -> bucket 연결 제거
        await DfInhouseF.apiCalls.put.boxUnlinkingToBucket({ box_id: box_scanned.id });

        // gBox sync
        await DfInhouseF.stateBucket.gBox.delete.inhouseShippingBox({
          bucket_id: bucket_selected.id,
          box_id: box_scanned.id,
        });

        // 테이블에 박스 row 제거
        await DfInhouseF.boxTable.deleteBoxDataRow({
          box_id: box_scanned.id,
          target_bucket_id: bucket_selected.id,
        });

        // box counter 업데이트
        await DfInhouseF.stateDOM.update.uptoDateBoxCounter({ target_bucket_id: bucket_selected.id });

        DfInhouseF.stateDOM.update.bucketImageStatus({
          bucket_id: bucket_selected.id,
          is_packed: false,
          box_ctn: DfInhouseF.stateDOM.get.selectedBucketBoxes(true).length,
        });

        DfInhouseF.stateBucket.scrollTo({ bucket_id: bucket_selected.id });

        await DfInhouseF.alert.success({ title: '제거 성공', timer: 800 });
      }

      break;
    }
    default:
      throw new Error(`알 수 없는 버킷 모드입니다. 스캔된 모드: ${bucket_mode_selected}`);
  }
}
