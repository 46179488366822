import { html, strMap, values, filter, entries, go, zipWithIndexL } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { addIcon, removeIcon } from './tmpl.icon.js';

function makeTableNoDataRowHtml({ columns, no_data_message }) {
  return html`<tr class="no_data">
    <td
      class="text_center no_data"
      colspan="${go(
        columns,
        values,
        filter(({ show }) => show),
      ).length}"
    >
      ${no_data_message}
    </td>
  </tr>`;
}

export function makeTableRowHtml({ row, columns, idx = 0, row_length = 0 }) {
  return html`<tr data-fx-json="${$dataStr(row)}">
    ${go(
      columns,
      entries,
      strMap(([col, { type, show, data_class, width, template }]) => {
        let content = row[col];

        if (type === 'checkbox') {
          content = html`<div class="checkbox_wrapper">
            <label class="checkbox_label"><input type="checkbox" /></label>
          </div>`;
        }

        if (type === 'custom') {
          content = template(row);
        }

        if (type === 'merged') {
          if (idx === 0) {
            content = template(row);
            return html`<td
              class="${data_class}"
              style="${`width: ${width}; ${show ? '' : 'display: none;'}`}"
              rowspan="${row_length}"
            >
              ${content}
            </td>`;
          } else {
            return '';
          }
        }
        return html`<td class="${data_class}" style="${`width: ${width}; ${show ? '' : 'display: none;'}`}">
          ${content}
        </td>`;
      }),
    )}
  </tr>`;
}

export function makeTableHtml({ data, columns, no_data_message = '데이터가 존재하지 않습니다.' }) {
  const rows = go(data, values);
  const DATA_EXISTS = rows.length > 0;

  return html`
    <table class="table" data-fx-json="${$dataStr(data)}">
      <thead>
        <tr>
          ${go(
            columns,
            values,
            strMap(({ title, type, header_class, width = 0, show }) => {
              let content = title;
              if (type === 'checkbox') {
                content = html`<div class="checkbox_wrapper">
                  <label class="checkbox_label"><input type="checkbox" /> </label>
                </div>`;
              }
              const style = width !== 0 ? `style="width: ${width}; ${show ? '' : 'display: none;'}"` : '';
              return html` <th class="${header_class}" ${style}>${content}</th>`;
            }),
          )}
        </tr>
      </thead>
      <tbody class="tbody">
        ${DATA_EXISTS
          ? go(
              rows,
              zipWithIndexL,
              strMap(([idx, row]) => makeTableRowHtml({ row, columns, idx, row_length: rows.length })),
            )
          : makeTableNoDataRowHtml({ columns, no_data_message })}
      </tbody>
    </table>
  `;
}

export function makeTableProductInfoHtml({ tpl, thumbnail_url, product_faces2, name, option, upcs_id }) {
  return html`<div class="product_info">
    <div class="thumb">
      ${tpl
        ? html`<img
            src="${UtilS.escape(
              UtilImageS.getResizedUrl({ url: thumbnail_url, width: 60, height: 60, quality: 80 }),
            )}"
            alt=""
          />`
        : html`<canvas data-fx-json="${$dataStr({ product_face: product_faces2?.value[0] })}"></canvas>`}
    </div>
    <div class="description">
      <div class="name">${name}</div>
      <div class="options">${option} ${`/ ${upcs_id}`}</div>
    </div>
  </div>`;
}

export function makeTableInputNumberHtml({ value, min_value = 1 }) {
  return html`<div class="input_number_wrapper">
    <button class="input_number_decrement" type="button">${removeIcon()}</button>
    <input type="number" class="move_quantity" min="${min_value}" max="${value}" value="${value}" step="1" />
    <button class="input_number_increment" type="button">${addIcon()}</button>
  </div>`;
}

export function makeTableStepBadgeHtml({ step }) {
  let title = '포장';
  switch (step) {
    case 'INBOUND':
      title = '입고';
      break;
    case 'INSPECTION':
      title = '검수';
      break;
    case 'RETURN':
      title = '회수';
      break;
    case 'OUTBOUND':
      title = '출고';
      break;
    case 'LOCAL':
      title = '현지';
      break;
  }

  return `<span class="badge badge_${step.toLowerCase()}">${title}</span>`;
}

export function makeTableInspectionStatusButtonHtml({
  lgl_item_id,
  lgl_outbound_order_id,
  lgl_item_inbound_inspections,
  lgl_item_outbound_inspections,
}) {
  const inbound = !lgl_outbound_order_id;
  if (inbound) {
    const hasInspection = !UtilS.isEmpty(lgl_item_inbound_inspections);
    if (hasInspection) {
      const { is_ok } = lgl_item_inbound_inspections;
      return `<button type="button" class="inspection_detail line ${
        is_ok ? '' : 'danger'
      }" data-lgl-item-id="${lgl_item_id}">검수 ${is_ok ? '정상' : '불량'}</button>`;
    } else {
      return `<button type="button" class="inspection_detail disabled">검수 예정</button>`;
    }
  } else {
    const hasInspection = !UtilS.isEmpty(lgl_item_outbound_inspections);
    if (hasInspection) {
      const { is_ok } = lgl_item_outbound_inspections;
      return `<button type="button" class="inspection_detail line ${
        is_ok ? '' : 'danger'
      }" data-lgl-item-id="${lgl_item_id}">검수 ${is_ok ? '정상' : '불량'}</button>`;
    } else {
      return `<button type="button" class="inspection_detail disabled">검수 예정</button>`;
    }
  }
}

export function makeTableReturnStatusButtonHtml(row) {
  const { lgl_return_orders } = row;
  if (lgl_return_orders.current_status === 'REQUESTED') {
    return `<span class="return_detail">요청 완료</span>`;
  } else {
    return `<button type="button" class="return_detail line" data-fx-json="${$dataStr(
      lgl_return_orders,
    )}">회수 발송</button>`;
  }
}
