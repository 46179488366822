import { format } from 'date-fns';
import dataStr from 'fxdom/es/dataStr.js';
import { filter, go, html, map, sel, strMap, join, reject } from 'fxjs/es';
import { selectedOptionGroup } from '../../../../BpOption/S/Function/selectedOptionGroup.js';
import { isNeedPreview } from '../../../../Maker/S/categorize.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { NewMakerWeS } from '../../../../NewMaker/We/S/Function/module/NewMakerWeS.js';
import { UserProductS } from '../../../../UserProduct/S/Function/module/UserProductS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPMyPageOrderS } from '../../../MyPage/Order/S/Function/module/OMPMyPageOrderS.js';
import { OMPCartTmplS } from './module/OMPCartTmplS.js';
import { PdfS } from '../../../../Pdf/S/Function/module/PdfS.js';

export const cartProductNumberColumnTmpl = (up_c) => {
  return html`
    <span class="omp-cart__product-number">${format(new Date(up_c.created_at), 'yyyy-MM-dd')}</span>
    <span class="omp-cart__product-number">${TT('cart::cart_09', { no: up_c.id })}</span>
  `;
};

export const cartProductInfoColumnTmpl = (up_c) => {
  const thumbnail = {
    product_face: sel('_.product_color.product_faces2.value.0', up_c),
    // thumbnail_url: go(sel('_.product_color.thumbnails.value', up_c), find(sel('is_thumb')), sel('url')),
  };
  const product_color = up_c._.product_color;
  const base_product_color = product_color._.base_product_color;
  const base_product = product_color._.base_product;
  const name = UserProductS.naming.makeBpNameWithColor({ base_product, base_product_color });

  const { pdf_printing_file } = up_c;

  const is_show_pdf_printing_file = PdfS.is_pdf_uploaded_by_customer({ pdf_printing_file });

  const pdf_printing_file_el = is_show_pdf_printing_file
    ? OMPCartTmplS.cartPrintingFileTmpl({ pdf_printing_file })
    : '';
  const pdf_file_expiration = is_show_pdf_printing_file
    ? OMPCoreUtilS.isExpired({ expired_at: pdf_printing_file?.expired_at })
    : false;

  const product_faces2 = product_color.product_faces2;
  const selected_option_group = product_color._.selected_option_group;

  const option_names = go(
    UserProductS.naming.makeOptionNames1(
      {
        selected_option_group,
        base_product,
        product_faces2,
      },
      '',
    ),
    reject((option) => option.option_group_name === '사이즈'),
  );

  const base_product_colors = up_c._.product_color._.base_product._.base_product_colors;

  const disabled =
    NewMakerWeS.isWeBaseProduct(up_c.base_product_id) ||
    up_c._.product_color?._?.base_product_color?.name === '' ||
    isNeedPreview(up_c._.product_color._.base_product) ||
    base_product_colors.length === 1 ||
    !!up_c._.product_color._.base_product?.maker_features?.[
      NewMakerPropertyBaseProductConstantS.NOT_SHOW_ADD_COLOR
    ];

  return html`
    <div
      class="omp-cart__product-thumbnail"
      data-fx-json="${!thumbnail.thumbnail_url && thumbnail.product_face
        ? dataStr(thumbnail.product_face)
        : ''}"
    >
      ${pdf_file_expiration
        ? `<div class="expiration">${html`${TT('pdf::expired::1')}`}</div>`
        : thumbnail.thumbnail_url
        ? html` <img src="${thumbnail.thumbnail_url}" alt="" /> `
        : html`${UserProductS.thumbnail.smallCanvasHtml()}`}
    </div>
    <div class="omp-cart__product-info-wrapper">
      <div class="omp-cart__product-info">
        <span class="omp-cart__product-name">${name}</span>
        ${go(
          option_names,
          map((option) => {
            return `<span class="omp-cart__product-print">${option.option_group_name} : ${option.option_name}</span>`;
          }),
          join(''),
        )}
        ${pdf_printing_file_el}
        <div data-up_id="${up_c.up_id}" class="omp-cart__add-color-wrapper">
          <div class="omp-cart__add-color" data-disabled="${disabled}">
            <div class="omp-cart__add-color-icon">
              ${OMPCoreAtomTmplS.plusIcon({ color: disabled ? 'GY' : 'OG' })}
            </div>
            <div>${TT('cart::cart_11')}</div>
          </div>
        </div>
      </div>
    </div>
  `;
};

export const cartProductSizesColumnTmpl = (up_c) => {
  const size_option = selectedOptionGroup.getSizeValue(up_c._.product_color._.selected_option_group);

  const sizes = map(
    (up_c_s) => {
      return {
        _is_not_stock: up_c_s._is_not_stock,
        _is_discontinued: up_c_s._is_discontinued,
        size: up_c_s['_name' + G._en],
        quantity: UtilS.commify(up_c_s.quantity),
      };
    },
    filter((up_c_s) => up_c_s.quantity, up_c._.up_c_ss),
  );

  if (!UtilS.isEmpty(size_option)) {
    const [size] = sizes;
    const { _is_not_stock, _is_discontinued, quantity } = size || {};
    return html`
      <div
        class="omp-cart__product-size-set"
        data-is_not_stock="${_is_not_stock}"
        data-is_discontinued="${_is_discontinued}"
      >
        <span class="omp-cart__product-size"
          >${size_option +
          (_is_discontinued
            ? ` (${TT('cart::cart_24')})`
            : _is_not_stock
            ? ` (${TT('cart::cart_23')})`
            : '')}</span
        >
        <span class="omp-cart__product-quantity">${_is_discontinued ? 0 : quantity}</span>
      </div>
    `;
  }

  return strMap(
    ({ size, quantity, _is_not_stock, _is_discontinued }) => html`
      <div
        class="omp-cart__product-size-set"
        data-is_not_stock="${_is_not_stock}"
        data-is_discontinued="${_is_discontinued}"
      >
        <span class="omp-cart__product-size"
          >${size +
          (_is_discontinued
            ? ` (${TT('cart::cart_24')})`
            : _is_not_stock
            ? ` (${TT('cart::cart_23')})`
            : '')}</span
        >
        <span class="omp-cart__product-quantity">${_is_discontinued ? 0 : quantity}</span>
      </div>
    `,
    sizes,
  );
};

export const cartProductPriceColumnTmpl = (up_c) => {
  const { price, discounted_price } = OMPMyPageOrderS.makeUpcProductPrice(up_c);

  return price === discounted_price
    ? html`<div>${TT('common::currency', { price })}</div>`
    : html`
        <div class="discounted">${TT('common::currency', { price })}</div>
        <div>${TT('common::currency', { price: discounted_price })}</div>
      `;
};

export const cartProductEditColumnTmpl = () => {
  return html`
    <button type="button" class="omp-cart__product-edit">${TT('cart::cart_20')}</button>
    <button type="button" class="omp-cart__product-save">${TT('cart::cart_21')}</button>
    <button type="button" class="omp-cart__product-delete">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M1 1L13 13M1 13L13 1" stroke="#858585" stroke-width="1.2" stroke-linecap="round" />
      </svg>
    </button>
  `;
};
