import {
  $closest,
  $contains,
  $delegate,
  $find,
  $findAll,
  $next,
  $on,
  $prev,
  $removeAttr,
  $setAttr,
} from 'fxdom/es';
import { go, find, head, last } from 'fxjs/es';
import { ComponentsInputConstantS } from '../../../../../Components/Input/S/Constant/module/ComponentsInputConstantS.js';
import { OMPSearchConstantS } from '../../../S/Constant/module/OMPSearchConstantS.js';
import { showAutoCompleteItems, hideAutoCompleteItems } from './event.js';

/**
 * @param {HTMLElement} el
 * @return {HTMLElement}
 */
export const delegateSearchAutoComplete = (el) => {
  const $search_form = $find(`.${OMPSearchConstantS.Klass.inputForm}`)(el);

  hideAutoCompleteOnSearchFormOuterClicked($search_form);
  hideAutoCompleteOnEscKeydown($search_form);
  showAutoCompleteWhenFocusingSearchInputIfExist($search_form);

  go(
    document.body,
    // 자동완성 아이템 클릭하여 검색
    $delegate('click', `.omp-search__auto-complete-item`, (e) => {
      const { id: inputValue } = e.currentTarget.dataset;
      const $input_form = $find(`.${OMPSearchConstantS.Klass.inputForm}`)(e.delegateTarget);

      const submitEvent = new CustomEvent('submit-input-form', {
        bubbles: true,
        detail: {
          inputValue,
        },
      });
      $input_form.dispatchEvent(submitEvent);
    }),
  );

  return go(
    el,
    $delegate('mouseover', `.${OMPSearchConstantS.Klass.autoCompleteItem}`, (e) => {
      const $list = $closest('.omp-search__auto-complete-items', e.currentTarget);
      const $items = $findAll('.omp-search__auto-complete-item', $list);
      const $cur_focused = findFocusedItem($items);
      if ($cur_focused) {
        $removeAttr('selected', $cur_focused);
      }
      $setAttr({ selected: true }, e.currentTarget);
    }),

    // 키보드 방향키 위 아래로 자동완성 아이템 이동, 엔터로 자동완성 항목 선택
    $delegate('keydown', `.${OMPSearchConstantS.Klass.input}`, (e) => {
      // input 에서 한글자판 사용시 IME 에서 메시지를 가로채기 때문에 keyup/keydown 이벤트가 여러 번 울린다.
      // 이를 방지하기 위해 `isComposing`이 `true`일 경우에는 이벤트를 무시한다.
      if (e.isComposing) {
        return;
      }

      const $search_form = $closest(`.${OMPSearchConstantS.Klass.searchForm}`)(e.currentTarget);
      const $input = $find(`.${OMPSearchConstantS.Klass.input}`)($search_form);
      const $items = $search_form && $findAll(`.${OMPSearchConstantS.Klass.autoCompleteItem}`, $search_form);
      if (!$items?.length) {
        return;
      }

      if (e.code === ComponentsInputConstantS.KEY_CODE.ENTER) {
        const $focused_item = findFocusedItem($items);
        if ($focused_item) {
          $input.value = $focused_item.dataset.id;
        }
      } else if (e.code === ComponentsInputConstantS.KEY_CODE.ARROW_DOWN) {
        e.originalEvent.preventDefault();
        moveFocusedAutoCompleteItemToDownOnArrowDown($items);
      } else if (e.code === ComponentsInputConstantS.KEY_CODE.ARROW_UP) {
        e.originalEvent.preventDefault();
        moveFocusedAutoCompleteItemToUpOnArrowUp($items);
      }
    }),
  );
};

/** 외부 클릭 시 자동완성 목록 가리기
 * @param {HTMLFormElement} $search_form
 * @return {void}
 */
const hideAutoCompleteOnSearchFormOuterClicked = ($search_form) => {
  $on('click', (e) => {
    if ($contains(e.target, $search_form)) {
      return;
    }
    hideAutoCompleteItems($search_form);
  })(document);
};

/** `esc` 눌렀을 때 자동완성 목록 가리기
 * @param {HTMLFormElement} $search_form
 * @return {void}
 */
const hideAutoCompleteOnEscKeydown = ($search_form) => {
  $on('keydown', (e) => {
    if (e.code === ComponentsInputConstantS.KEY_CODE.ESC) {
      hideAutoCompleteItems($search_form);
    }
  })(document);
};

/**
 * 검색어 입력창에 focus 했을 때 자동완성 목록이 있다면 다시 보여주기
 */
const showAutoCompleteWhenFocusingSearchInputIfExist = ($search_form) => {
  const $search_input = $find(`.${OMPSearchConstantS.Klass.input}`)($search_form);

  $on('focus', () => {
    showAutoCompleteItems($search_form);
  })($search_input);
};

/**
 * 키보드 아래 방향키를 눌렀을 때 자동완성 목록의 선택된 항목을 아래로 이동시킨다.
 * @param {HTMLElement[]} $items
 */
const moveFocusedAutoCompleteItemToDownOnArrowDown = ($items) => {
  const $focused_item = findFocusedItem($items);
  if (!$focused_item) {
    return head($items).setAttribute('selected', true);
  }
  const $next_item = $next($focused_item) ?? head($items);
  $focused_item.removeAttribute('selected');
  $next_item.setAttribute('selected', true);
};

/**
 * 키보드 위쪽 방향키를 눌렀을 때 자동완성 목록의 선택된 항목을 아래로 이동시킨다.
 * @param {HTMLElement[]} $items
 */
const moveFocusedAutoCompleteItemToUpOnArrowUp = ($items) => {
  const $focused_item = findFocusedItem($items);
  if (!$focused_item) {
    return last($items).setAttribute('selected', true);
  }
  const $prev_item = $prev($focused_item) ?? last($items);
  $focused_item.removeAttribute('selected');
  $prev_item.setAttribute('selected', true);
};

/**
 * 현재 방향키나 moseover로 선택된 자동완성 항목을 찾는다.
 * @param {HTMLElement[]} $items
 * @return {HTMLElement | null}
 */
const findFocusedItem = ($items) => {
  return find(($item) => $item.getAttribute('selected'), $items);
};
