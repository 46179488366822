import { OMPCoreUtilF } from '../../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

export const confirm = {
  proceedPreviousPdfVersion: () => {
    return OMPCoreUtilF.confirm({
      content: UtilF.isLegacyMobile()
        ? TT('NewMaker::Alert::useLatestPdfVersion::content_mobile')
        : TT('NewMaker::Alert::useLatestPdfVersion::content'),
      title: TT('NewMaker::Alert::useLatestPdfVersion::title'),
      ok: TT('NewMaker::Alert::useLatestPdfVersion::ok'),
      cancel: TT('NewMaker::Alert::useLatestPdfVersion::cancel'),
      style: UtilF.isLegacyMobile()
        ? { color_reverse: true, column: true }
        : { color_reverse: true, reverse: true },
    });
  },
};
