import { go1, strMap } from 'fxjs/es';
import { hexToRGB } from '../../../Canvas/S/util.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';

export function makeColorTabHtml([ct_list, bp_colors]) {
  return legacyHtml`
    <div class="ct_bg_canvas_list">
      ${
        ct_list.length
          ? strMap(
              (ct_list_item) =>
                legacyHtml`
                <div class="bg_img composite_template" data-id="${ct_list_item.id}">
                  <canvas width="${ct_list_item.width}" height="${ct_list_item.height}"></canvas>
                </div>
              `,
              ct_list,
            )
          : legacyHtml`
            <img
              src="https://s3.marpple.co/files/u_1187078/2020/6/original/18687251edb842d9a8cd6ab0db9e145bb2482ea322cfc77.png" alt=""
              style="max-width: 100%; height:auto"
            />
          `
      }
    </div>
    <div class="ct_bg_colorSelector_wrapper">
      <table class="ct_bg_color_Table">
        <thead>
          <tr class="table_header">
            <th>선택</th>
            <th>컬러명</th>
            <th>Hex</th>
            <th>RGB</th>
            <th>절대색상</th>
            <th>완료</th>
          </tr>
        </thead>
        <tbody>
          ${strMap(
            ({ color_code, abs_color_code, base_product_id, is_melange, is_abs_color_done, name, id }) => {
              abs_color_code = abs_color_code || color_code;
              return legacyHtml`
                <tr
                  class="color_info"
                  data-id="${id}"
                  data-base_product_id="${base_product_id}"
                  data-color_name=${name}
                  data-color_code=${color_code}
                  data-abs_color_code=${abs_color_code}
                >
                  <td><div class="color_pick" style="background-color:${color_code}"></div></td>
                  <td>${name}</td>
                  <td>${color_code}</td>
                  <td name="picker_color_rgb">
                    ${go1(hexToRGB(color_code), ({ r, g, b }) => `${r},${g},${b}`)}
                  </td>
                  <td><input type="color" name="abs_color_code" value="${abs_color_code}" /></td>
                  <td>
                    <label>
                      <div class="toggle">
                        <input
                          class="toggle_state"
                          type="checkbox"
                          name="is_abs_color_done"
                          ${is_abs_color_done ? 'checked' : ''}
                        />
                        <div class="indicator">
                          <svg width="10px" height="10px">
                            <path
                              d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </label>
                  </td>
                </tr>
              `;
            },
            bp_colors,
          )}
        </tbody>
      </table>
    </div>
  `;
}
