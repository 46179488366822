import { $findAll, $qs } from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { BpOptionS } from '../../S/Function/module/BpOptionS.js';
import { getBaseProductInMaker } from '../../../Maker/F/getSth.js';

export const makeSelectDisabled = () => {
  const base_product = getBaseProductInMaker();

  if (BpOptionS.hasBpOptionListWithoutVectorEditor(base_product)) {
    const bp_option_groups_el = $qs('.bp_option_groups');
    if (!bp_option_groups_el) return;
    bp_option_groups_el.dataset.is_designed = BpOptionS.isDesigned(box.sel('maker->product_color'));
    if (bp_option_groups_el) {
      const is_designed = bp_option_groups_el.dataset.is_designed === 'true';

      go(
        bp_option_groups_el,
        $findAll('.bp_option_group[data-is_lockable="true"] select'),
        each((select_el) => {
          select_el.disabled = is_designed;
        }),
      );
    }
  }
};
