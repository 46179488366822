import { $closest, $find, $qs } from 'fxdom/es';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { SCALE_PROPERTY_NAME, TOOL_NAME } from '../../S/Constant/constants.js';
import { tools } from '../Lib/state.js';

export function focusBackToFrame({ tab_el }) {
  $closest('.don_frame', tab_el).focus();
}

export function setCanvasCursor(new_cursor_name, is_caching) {
  const $work_canvas = $qs('canvas.work-canvas');
  if (new_cursor_name == null) {
    $work_canvas.style.cursor = $work_canvas.dataset.previous_cursor;
  } else {
    if (is_caching) {
      $work_canvas.dataset.previous_cursor = $work_canvas.style.cursor;
    }
    $work_canvas.style.cursor = new_cursor_name;
  }
}

export function getCurrentTool({ tab_el }) {
  const $selected_tool = $find(`aside.tool-panel button.selected`, tab_el);
  const tool_name = $selected_tool.getAttribute('tool-name');
  if (tool_name == null) {
    throw new Error(`Not exist tool name`);
  }
  return tool_name;
}

export function isNotKeyDownWithMeta({ keyboard_event }) {
  return (
    !keyboard_event.ctrlKey && !keyboard_event.altKey && !keyboard_event.shiftKey && !keyboard_event.metaKey
  );
}

export async function donAlertAsync({ tab_el, msg }) {
  await $.alert(msg);
  console.error(msg);
  DfImageEditorF.focusBackToFrame({ tab_el });
}

export function closeFrameWhenError({ msg }) {
  window.setTimeout(() => {
    $.alert(msg).then(() => {
      MuiF.closeFrame();
    });
  }, 500);
}

export function getPrintableRasterSizeControlInputEls() {
  const $printable_raster_size_indicator = $qs(
    `.property-panel .properties[tool-name=${TOOL_NAME.scale}] .property[property-name=${
      tools.scale.properties[SCALE_PROPERTY_NAME.size].propertyName
    }]`,
  );

  if ($printable_raster_size_indicator == null) {
    throw new Error(`Not exist printable raster property size indicator`);
  }

  const $width_input = $find('input[name="width"]', $printable_raster_size_indicator);
  const $height_input = $find('input[name="height"]', $printable_raster_size_indicator);
  return { $width_input, $height_input };
}
