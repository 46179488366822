import { $delegate, $qs } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { NewMakerCheckConditionConstantS } from '../../../CheckCondition/S/Constant/module/NewMakerCheckConditionConstantS.js';
import { NewMakerConditionAgreeTextF } from '../../../ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { NewMakerConditionPopupF } from '../../../ConditionPopup/F/Function/module/NewMakerConditionPopupF.js';
import { NewMakerConditionTooltipMoMuiF } from '../Mui/module/NewMakerConditionTooltipMoMuiF.js';

export const open = async () => {
  const popup_data = NewMakerConditionPopupF.getMappingConfirmPopupData(
    NewMakerCheckConditionConstantS.CONFIRM_POPUP_ANY_CORNER_OUT_BOUNDS_FULL_FILL_TYPE,
  );
  if (!popup_data) throw new Error('popup_data 없음');
  const is_mobile = UtilF.isLegacyMobile();
  if (!is_mobile) throw new Error('pc frame 없음');
  setTimeout(() => {
    const frame = $qs('.don_frame[frame_name="/modules/NewMaker/ConditionTooltipMo/S/Mui/frame.js"]');
    if (!frame) {
      open();
    }
  }, 100);

  return new Promise((resolve) => {
    return MuiF.openFrame(NewMakerConditionTooltipMoMuiF.frame, (frame, page, [tab]) => {
      frame.title = TT('maker::tooltip::mo_title');
      frame.prev_frame_show = is_mobile;
      frame.is_modal = !is_mobile;
      frame.height = 500 + G.mp.frame.v_height + NewMakerConditionAgreeTextF.agreeTextCount() * 38;
      frame.closed = ($frame, val) => {
        resolve(val);
      };

      const tooltip = popup_data[`tooltip${G._en}`];

      tab.makeData = () => {
        return {
          tooltip,
        };
      };
    });
  });
};

export const halfFrameClose = (frame_el$) => {
  go(
    frame_el$,
    $delegate('click', '>.don_wrapper >.header', (e) => {
      MuiF.removeFrame();
    }),
  );
};
