import { defnProductListTab, defnProductListFilterTab, defnProductListFilterTab2 } from './tab.js';

$.frame.defn_page({
  page_name: 'mp.product.list',
  tabs: [defnProductListTab()],
});

$.frame.defn_page({
  tabs: [defnProductListFilterTab()],
  showing: (el_page) =>
    $.html($.find1($.closest(el_page, '.don_frame'), '>.don_wrapper >.header .title'), T('Select Filters')),
  page_name: 'mp.product.list.filter',
});

$.frame.defn_page({
  animation: true,
  page_name: 'mp.product.list.filter2',
  tabs: [defnProductListFilterTab2()],
});
