import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPProductListTmplS } from '../../../S/Tmpl/module/OMPProductListTmplS.js';

export const filterList = ({
  bp_filters: { brand_filter, color_filter, size_filter, filter_lists },
  checked_filter_items,
}) => {
  const tt_title = TT('product::list::list_7_1');
  const tt_select_filter = TT('product::list::list_18_1');

  const fls = [brand_filter, color_filter, size_filter, ...filter_lists].filter(({ _: { filter_items } }) => {
    return filter_items.length > 0;
  });
  return html`
    <div class="omp-product-list-filter-modal">
      <div class="omp-product-list-filter-modal__header">
        <div class="omp-product-list-filter-modal__title">${tt_title}</div>
        <div
          class="omp-product-list-filter-modal__checked-filter-wrapper"
          data-checked="${checked_filter_items.length > 0}"
        >
          <div
            class="omp-product-list-filter-modal__checked-filter-items opm-product-list-checked-filter-items"
            data-list_show="${checked_filter_items.length > 0}"
          >
            ${OMPProductListTmplS.checkedFilterItemsWithReset(checked_filter_items)}
          </div>
          <div class="omp-product-list-filter-modal__tt-select-filter">${tt_select_filter}</div>
        </div>
      </div>
      <div class="omp-product-list-filter-modal__body">
        <div class="omp-product-list-filter-modal__filter-lists">
          ${strMap((fl) => {
            return html` <button
              class="omp-product-list-filter-modal__filter-list"
              data-filter_list_id="${fl.id}"
            >
              <div class="omp-product-list-filter-modal__flex">
                <div class="omp-product-list-filter-modal__filter-list-name">${fl.name}</div>
                <div class="omp-product-list-filter-modal__filter-list-count" data-count="${fl.count}">
                  (${fl.count})
                </div>
              </div>
              <div class="omp-product-list-filter-modal__filter-list-icon">
                ${OMPCoreAtomTmplS.arrowIcon({ direction: 'right' })}
              </div>
            </button>`;
          }, fls)}
        </div>
      </div>
    </div>
  `;
};
