import { equals2 } from 'fxjs/es';
import { VectorEditorStickerFreeCreatorPCF } from '../Function/module/VectorEditorStickerFreeCreatorPCF.js';

export const handleFreeTemplateCuttingLineClick =
  ({ tab_el }) =>
  () => {
    if (!equals2(tab_el.__mp_free_template_svg_el?.dataset?.is_show)('true')) {
      return;
    }
    return VectorEditorStickerFreeCreatorPCF.addNewSticker({ tab_el });
  };
