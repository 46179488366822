import { html, strMap } from 'fxjs/es';
import { enFirst } from '../../../Main/S/Tmpl/enFrist.js';
import { jpFirst } from '../../../Main/S/Tmpl/jpFirst.js';

export const privacy = (tds) => html`
  <div class="privacy-popup">
    <div class="privacy-popup__body">
      <table class="privacy-popup__table">
        <thead>
          <tr>
            <th>수집 목적</th>
            <th>수집 항목</th>
            <th>보유 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            ${strMap((td) => html` <td>${td}</td> `, tds)}
          </tr>
        </tbody>
      </table>
      <p class="privacy-popup__p">
        개인정보 제공에 동의하지 않으실 수 있으며, 동의하지 않으실 경우 서비스 이용이 제한될 수 있습니다. 그
        밖의 사항은 (주)마플코퍼레이션의
        <a href="/${T.lang}/${G.collabo_type == 'creator' ? '@/' : ''}privacy" target="_blank"
          >개인정보처리방침</a
        >에 따릅니다.
      </p>
      <div class="option">
        <button class="done">확인</button>
      </div>
    </div>
  </div>
`;

const loginKr = () =>
  privacy([
    '회원제 서비스 이용에 따른 본인 식별',
    '이름, 이메일, 비밀번호',
    '회원 탈퇴하거나 회원에 제명된 때 즉시 파기',
  ]);

const LOGIN_PRIVACY_HTML = {
  kr: loginKr,
  en: enFirst,
  jp: jpFirst,
};

export const login = () => LOGIN_PRIVACY_HTML[T.lang]() || '';

const loginCheckoutKr = () =>
  privacy([
    '상품/경품 배송 및 계약이행, 환불 및 상담 회신',
    '주문자 정보: 이름, 연락처, 이메일<br>수령자 정보: 이름, 연락처, 배송지',
    '개인정보 수집 및 이용목적 달성 시 및 관련 법령에 따른 기간까지 보관',
  ]);

const LOGIN_CHECKOUT_PRIVACY_HTML = {
  kr: loginCheckoutKr,
  en: enFirst,
  jp: jpFirst,
};

export const loginCheckout = () => LOGIN_CHECKOUT_PRIVACY_HTML[T.lang]() || '';

const signUpCheckoutKr = () =>
  privacy([
    `주문자 정보(회원 가입 정보) : 회원제 서비스 이용에 따른 본인 식별<br>수령자 정보 : 상품/경품 배송 및 계약이행, 환불 및 상담 회신`,
    `주문자 정보(회원 가입 정보) : 이름, 연락처, 이메일, 비밀번호<br>수령자 정보: 이름, 연락처, 배송지 `,
    `주문자 정보(회원 가입 정보) : 회원 탈퇴하거나 회원에 제명된 때 즉시 파기<br>수령자 정보 : 개인정보 수집 및 이용목적 달성 시 및 관련 법령에 따른 기간까지 보관`,
  ]);

const SIGN_UP_CHECKOUT_PRIVACY_HTML = {
  kr: signUpCheckoutKr,
  en: enFirst,
  jp: jpFirst,
};

export const signUpCheckout = () => SIGN_UP_CHECKOUT_PRIVACY_HTML[T.lang]() || '';

export const checkoutThirdParties = () => {
  return html`
    <div class="privacy-popup">
      <div class="privacy-popup__body">
        <p>
          ㈜마플코퍼레이션의 회원계정으로 셀러배송상품 및 셀러의 독자적인 서비스를 구매하고자 할 경우,
          ㈜마플코퍼레이션은 개인정보보호법 제 17조 (개인정보의 제공)에 따라 거래 당사자간 원활한 의사소통 및
          배송, 상담 등 거래이행을 위하여 필요한 최소한의 개인정보만을 마플샵 입점 셀러 및 배송사에 아래와
          같이 공유하고 있습니다.
          <br /><br />

          ① 개인정보를 공유받는자 : 주문한 상품을 발송할 마플샵 셀러<br />
          ② 공유하는 개인정보 항목 - 구매자 정보: 성명, 전화번호, ID, 휴대전화 번호, 메일주소, 상품 구매정보 -
          수령자 정보: 성명, 전화번호, 휴대전화 번호, 배송지 주소<br />
          ③ 개인정보를 공유받는 자의 이용 목적 : 셀러와 구매자의 거래의 원활한 진행, 본인의사의 확인, 고객
          상담 및 불만처리, 상품과 경품 배송을 위한 배송지 확인 등<br />
          ④ 개인정보를 공유받는 자의 개인정보 보유 및 이용 기간 : 상품 구매/배송/반품 등 서비스 처리 완료 후
          180일간 보관 후 파기<br />
          단, 관계 법령의 규정에 의하여 일정 기간 보존이 필요한 경우에는 해당 기간만큼 보관 후 삭제합니다.<br />
          ➄동의 거부 시 불이익 : 본 개인정보 공유에 동의하지 않으시는 경우, 동의를 거부할 수 있으며, 이 경우
          거래가 제한됩니다.
        </p>
        <div class="option">
          <button class="done">확인</button>
        </div>
      </div>
    </div>
  `;
};
