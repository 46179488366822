import { html } from 'fxjs/es';
import { OMPCoreUtilS } from '../../../Util/S/Function/module/OMPCoreUtilS.js';
import { OMPCoreLayoutConstantS } from '../Constant/module/OMPCoreLayoutConstantS.js';
import { OMPCoreLayoutTmplS } from './module/OMPCoreLayoutTmplS.js';

const mobileTmpl = ({ body, footer_hidden, header_banner_type }) => {
  const footer_html = () => {
    if (footer_hidden) return '';
    return html`<div class="omp-layout-body__footer">${OMPCoreLayoutTmplS.footerMobileHTML()}</div>`;
  };

  return html`
    <div class="omp-layout-body" data-header_banner="${header_banner_type}">
      <div class="omp-layout-body__wrap">
        <div class="omp-layout-body__cont">${body}</div>
      </div>
      ${footer_html()}
    </div>
  `;
};

export const leftHideBodyTmpl = ({ locals, body } = {}) => {
  if (OMPCoreUtilS.isMobile(locals)) {
    return mobileTmpl({ body });
  }
  return html`
    <div class="omp-layout-body" data-left_mode="${OMPCoreLayoutConstantS.LEFT_MODE.left_hide}">
      <div class="omp-layout-body__wrap">
        <div class="omp-layout-body__cont">${body}</div>
      </div>
      <div class="omp-layout-body__footer">${OMPCoreLayoutTmplS.footerHTML()}</div>
    </div>
  `;
};

export const bodyTmpl = ({ locals, body, custom_left, left_mode, footer_hidden } = {}) => {
  const { header_banner } = locals;
  const footer_html = () => {
    if (footer_hidden) return '';
    return html`<div class="omp-layout-body__footer">${OMPCoreLayoutTmplS.footerHTML()}</div>`;
  };

  const header_banner_type = header_banner ? 'header_banner' : '';

  if (OMPCoreUtilS.isMobile(locals)) {
    return mobileTmpl({ body, footer_hidden, header_banner_type });
  } else {
    if (left_mode === OMPCoreLayoutConstantS.LEFT_MODE.left_hide) {
      return html`
        <div class="omp-layout-body" data-left_mode="${left_mode}" data-header_banner="${header_banner_type}">
          <div class="omp-layout-body__wrap">
            <div class="omp-layout-body__cont">${body}</div>
          </div>
          ${footer_html()}
        </div>
      `;
    } else if (left_mode === OMPCoreLayoutConstantS.LEFT_MODE.left_delete) {
      return html`
        <div class="omp-layout-body" data-left_mode="${left_mode}" data-header_banner="${header_banner_type}">
          <div class="omp-layout-body__wrap">
            <div class="omp-layout-body__cont">${body}</div>
          </div>
          ${footer_html()}
        </div>
      `;
    } else if (left_mode === OMPCoreLayoutConstantS.LEFT_MODE.left_fixed) {
      return html`
        <div class="omp-layout-body" data-left_mode="${left_mode}" data-header_banner="${header_banner_type}">
          <div class="omp-layout-body__wrap">
            <div class="omp-layout-body__cont">${body}</div>
          </div>
          <div class="omp-layout__sidebar">
            ${custom_left || OMPCoreLayoutTmplS.leftFixedHTML({ left_mode, locals })}
          </div>
          ${footer_html()}
        </div>
      `;
    } else {
      return html`
        <div
          class="omp-layout-body"
          data-left_mode="${left_mode || OMPCoreLayoutConstantS.LEFT_MODE.left}"
          data-header_banner="${header_banner_type}"
        >
          <div class="omp-layout-body__wrap">
            <div class="omp-layout-body__left">
              <div class="omp-layout-body__left-wrap">
                ${custom_left || OMPCoreLayoutTmplS.leftHTML({ left_mode, locals })}
              </div>
            </div>
            <div class="omp-layout-body__cont">${body}</div>
          </div>
          ${footer_html()}
        </div>
      `;
    }
  }
};
