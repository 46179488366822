import { extend, go } from 'fxjs/es';
import { errorHandlingEventWrapper } from '../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { legacyHtml } from '../../Util/S/Function/util.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';

const default_range_slider_attr = () => ({
  only_number: false,
  step: 1,
  up_down_control: true,
  to_fixed: 0,
  number_input_disabled: UtilF.isLegacyMobile(),
  loop: false,
});

export const makeRangeSliderHtml = function (attr) {
  const _attr = extend({}, default_range_slider_attr(), attr);
  const min = _attr.loop ? _attr.min - 1 : _attr.min;
  const max = _attr.loop ? _attr.max + 1 : _attr.max;
  return legacyHtml`
    <div
      class="maker_range_wrapper"
      id="${_attr.id}"
      only_number="${_attr.only_number}"
      up_down_control="${_attr.up_down_control}"
    >
      <div class="head">
        <div class="title">${_attr.title}</div>
      </div>
      <div class="body">
        <div class="range_wrapper">
          <input
            type="range"
            class="range_input render_input"
            step="${_attr.step}"
            min="${min}"
            max="${max}"
            value="${_attr.value}"
          />
        </div>
        <div class="blank"></div>
        <div class="number_wrapper">
          <input
            type="number"
            class="number_input render_input"
            value="${_attr.value}"
            min="${min}"
            max="${max}"
            step="${_attr.step}"
            ${_attr.number_input_disabled === 'true' ? 'disabled' : ''}
          />
          <div class="up_down_control">
            <div class="up">
              <img
                src="//s3.marpple.co/file/guest/2018/7/original/f_5290_1531386687863_y1tOGa0MSh3DM2GTvFk.png"
                alt=""
                srcset="
                  //s3.marpple.co/file/guest/2018/7/original/f_5291_1531386687863_ytcWcQtW9SCra0c0FP.png 2x
                "
              />
            </div>
            <div class="down">
              <img
                src="//s3.marpple.co/file/guest/2018/7/original/f_5288_1531386687863_Xg0N8Dp4D8x7AlAIpPqz.png"
                alt=""
                srcset="
                  //s3.marpple.co/file/guest/2018/7/original/f_5289_1531386687863_Om8RB5Z8HC3L7EHqWl5ah.png 2x
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

function getNumberInput(ct) {
  return go(ct, $.closest('.maker_range_wrapper'), $.find1('.number_input'));
}

function getRangeInput(ct) {
  return go(ct, $.closest('.maker_range_wrapper'), $.find1('.range_input'));
}

function syncRenderInput(ct, value) {
  if (Number.isNaN(Number(value))) return;
  go(ct, $.closest('.maker_range_wrapper'), $.find('.render_input'), $.val(value));
}

function isOnlyNumber(e) {
  return go(e.currentTarget, $.closest('.maker_range_wrapper'), $.attr('only_number'));
}

export const makeRangeSliderEvent = function (el, attr) {
  const _attr = extend({}, default_range_slider_attr(), attr);

  function setInput(e) {
    const val = $.val(e.currentTarget);
    let value;
    if (_attr.loop) {
      value = val < _attr.min ? _attr.max : val > _attr.max ? _attr.min : val;
    } else {
      value = val;
    }

    $.val(getRangeInput(e.currentTarget), value);
  }
  const _id = '#' + _attr.id;
  return go(
    el,
    $.on2(
      'input',
      _id + ' .range_input',
      errorHandlingEventWrapper(function (e) {
        return go(_attr.render(e), function (value) {
          return syncRenderInput(e.currentTarget, value);
        });
      }),
    ),
    $.on(
      'input',
      _id + ' .number_input',
      errorHandlingEventWrapper(function (e) {
        setInput(e);
        if (isOnlyNumber(e)) $.trigger(getRangeInput(e.currentTarget), 'input');
        else jQuery(getRangeInput(e.currentTarget)).rangeslider('update', true);
      }),
    ),
    $.on(
      'click',
      _id + ' .up_down_control .up',
      errorHandlingEventWrapper(function (e) {
        const number_input = getNumberInput(e.currentTarget);
        $.val(number_input, (parseFloat($.val(number_input)) + _attr.step).toFixed(_attr.to_fixed));
        $.trigger(number_input, 'input');
      }),
    ),
    $.on(
      'click',
      _id + ' .up_down_control .down',
      errorHandlingEventWrapper(function (e) {
        const number_input = getNumberInput(e.currentTarget);
        $.val(number_input, (parseFloat($.val(number_input)) - _attr.step).toFixed(_attr.to_fixed));
        $.trigger(number_input, 'input');
      }),
    ),
  );
};

export const convert1To10 = function (num) {
  return parseInt((num * 10).toFixed());
};

export const convert10To1 = function (num) {
  return num / 10;
};
