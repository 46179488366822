export const OTHER_BRAND_ID = 211;

export const SORT_LIST = () => [
  {
    id: 'pick',
    name: TT('product::list::list_4_1'),
  },
  {
    id: 'low_price',
    name: TT('product::list::list_5_1'),
  },
  {
    id: 'high_price',
    name: TT('product::list::list_6_1'),
  },
];

export const MOBILE_HEADER_HEIGHT = {
  filter: 190,
  no_filter: 144,
};
