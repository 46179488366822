export const PDF_PRINTING_FILE_S3_BUCKET = 'prod-mp-mpcom-pdf-printing-files';
export const PDF_PRINTING_FILE_CLOUD_FRONT_HOST = '7dnmuzhtu9q7u9sbdyuf.cf.marpple.com';

/**
 * @typedef PdfThumbnailOptions
 * @property {string | undefined} format
 * @property {number | undefined} quality
 * @property {number | undefined} width
 * @property {number | undefined} height
 * @property {boolean | undefined} preserveAspectRatio
 * @property {number | undefined} density dpi
 * @property {string | undefined} compression
 * */
export const PDF_THUMBNAIL = {
  OPTIONS: {
    format: 'png',
    width: 600,
    height: 600,
    preserveAspectRatio: true,
    density: 300,
    compression: 'JPEG',
  },
};

export const PDF_FILE_SIZE_LIMIT = 100; // Mb
export const PDF_PAGE_CTN_LIMIT = 5;

export const PDF_MADE_BY_MARPPLE_EDITOR = 'pdf_made_by_marpple_editor';
