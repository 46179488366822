import { eachL, equals, equals2, every, filterL, go, isNil, mapL, rejectL, takeAll, zipL } from 'fxjs/es';
import svgpath from 'svgpath';
import {
  getOffsetPathdataStr,
  getTranslateCoordsToSnapContainerSimpler,
  stickerCollisionCheck,
} from '@marpple/stickerizer';
import { VectorEditorStickerSingleMobileF } from '../../../../Single/Mobile/F/Function/module/VectorEditorStickerSingleMobileF.js';
import { CommonNS } from '@marpple/sticker-editor';
import { show2TypeNoneMakeStickerFooter } from './footer.js';

export const setOffsetPathData = ({ tab_el, id = null, wrapper_el = null }) => {
  if (isNil(id) && isNil(wrapper_el)) {
    return;
  }

  const sticker_collision_margin = tab_el.__mp_sticker_collision_margin;
  if (isNil(sticker_collision_margin)) {
    const error = new Error(`tab_el.__mp_sticker_collision_margin 가 없습니다.`);
    error.__mp_alert_message = `T('modules::VectorEditor::Sticker::message::안전 간격을 계산할 수 없습니다.');`;
    throw error;
  }

  go(
    tab_el?.__mp_free_sticker_editor?.getEls?.() ?? [],
    filterL((sticker) => equals2(id)(sticker?.id) || equals(wrapper_el)(sticker?.wrapper_el)),
    rejectL((sticker) => isNil(sticker?.target_image_el)),
    rejectL((sticker) => isNil(sticker?.cutting_line_path_data)),
    eachL(({ target_image_el, cutting_line_path_data }) => {
      const offset_path_data = getOffsetPathdataStr(cutting_line_path_data, sticker_collision_margin);
      target_image_el.__mp_offset_path_data = offset_path_data;
      target_image_el.__mp_transformed_offset_path_data = offset_path_data;
    }),
    takeAll,
  );
};

export const transformOffsetPathData = ({ tab_el, id = null, wrapper_el = null }) => {
  if (isNil(id) && isNil(wrapper_el)) {
    return;
  }

  go(
    tab_el?.__mp_free_sticker_editor?.getEls?.() ?? [],
    filterL((sticker) => equals2(id)(sticker?.id) || equals(wrapper_el)(sticker?.wrapper_el)),
    rejectL((sticker) => isNil(sticker?.target_image_el)),
    rejectL((sticker) => isNil(sticker?.target_image_el?.__mp_offset_path_data)),
    rejectL((sticker) => isNil(sticker?.wrapper_el)),
    eachL(({ target_image_el, wrapper_el }) => {
      const transform = wrapper_el.getAttributeNS(null, 'transform');
      if (!transform) {
        return;
      }
      target_image_el.__mp_transformed_offset_path_data = svgpath(target_image_el.__mp_offset_path_data)
        .transform(transform)
        .toString();
    }),
    takeAll,
  );
};

export const checkCollision = ({ tab_el }) => {
  const collision_container_path_data = tab_el.__mp_collision_container_path_data;
  if (isNil(collision_container_path_data)) {
    const error = new Error(`tab_el.__mp_collision_container_path_data 가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::안전 영역을 계산할 수 없습니다.');
    throw error;
  }
  const collision_container_area_position = tab_el.__mp_collision_container_area_position;
  if (isNil(collision_container_area_position)) {
    const error = new Error(`tab_el.__mp_collision_container_area_position 가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::안전 영역을 계산할 수 없습니다.');
    throw error;
  }
  const collision_forbidden_area_position = tab_el.__mp_collision_forbidden_area_position;
  if (isNil(collision_forbidden_area_position)) {
    const error = new Error(`tab_el.__mp_collision_forbidden_area_position 가 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::안전 영역을 계산할 수 없습니다.');
    throw error;
  }

  const stickers = tab_el?.__mp_free_sticker_editor?.getEls?.();
  if (isNil(stickers)) {
    const error = new Error(`tab_el.__mp_free_sticker_editor.getEls() 를 구할 수 없습니다.`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::스티커를 가져올 수 없습니다.');
    throw error;
  }

  const sticker_path_datas = go(
    stickers,
    mapL((sticker) => sticker?.target_image_el?.__mp_transformed_offset_path_data),
    eachL((path_data) => {
      if (isNil(path_data)) {
        const error = new Error(`target_image_el 의 __mp_transformed_offset_path_data 가 없습니다.`);
        error.__mp_alert_message = T(
          'modules::VectorEditor::Sticker::message::스티커 영역을 계산할 수 없습니다.',
        );
        throw error;
      }
    }),
    takeAll,
  );

  const { container_path_tobe_resolved, design_paths_tobe_resolved_arr } = stickerCollisionCheck(
    collision_container_path_data,
    sticker_path_datas,
  );

  go(
    zipL(stickers, design_paths_tobe_resolved_arr),
    eachL(([{ id }, tobe_resolved]) => {
      if (tobe_resolved) {
        tab_el.__mp_free_sticker_editor.setCuttingLineStrokeStyle({ id, stroke: '#FF0000' });
      } else {
        tab_el.__mp_free_sticker_editor.resetCuttingLineStrokeStyle({ id });
      }
    }),
    takeAll,
  );

  if (container_path_tobe_resolved) {
    go(
      zipL(stickers, design_paths_tobe_resolved_arr),
      filterL(([, tobe_resolved]) => tobe_resolved),
      eachL(([{ id, target_image_el }]) => {
        const { x, y } = getTranslateCoordsToSnapContainerSimpler(
          collision_container_area_position,
          collision_forbidden_area_position,
          target_image_el.__mp_transformed_offset_path_data,
        );
        if (every(equals2(0))([x, y])) {
          return;
        }
        tab_el?.__mp_free_sticker_editor?.translateEl?.({ id, tx: x, ty: y });
      }),
      takeAll,
    );
  }
};

export const addNewSticker = async ({ tab_el }) => {
  const editor = tab_el.__mp_free_sticker_editor;
  if (isNil(editor)) {
    return;
  }

  if (editor.getIsFull()) {
    await $.alert(T('modules::VectorEditor::Sticker::message::스티커는 최대 20개까지 생성할 수 있습니다.'));
    return;
  }

  const {
    value: { fill, opacity },
  } = editor.getBackground();

  tab_el.__mp_is_prevent_mouse_move = false;
  try {
    let is_added = false;
    await VectorEditorStickerSingleMobileF.makeSticker({
      art_board_size: tab_el.__mp_single_art_board_size,
      foreground_el: null,
      empty_template_el: tab_el.__mp_single_empty_template_svg_el?.cloneNode?.(true) ?? null,
      preProcess: () => {},
      postProcess: ({ target_image_el, path_data }) => {
        const { id, wrapper_el } = editor.addEl({
          target_image_el,
          cutting_line_path_data: path_data,
        });
        is_added = true;
        const wrapper_bbox = wrapper_el.getBBox();
        let wrapper_center_point = target_image_el.createSVGPoint();
        wrapper_center_point.x = wrapper_bbox.x + wrapper_bbox.width / 2;
        wrapper_center_point.y = wrapper_bbox.y + wrapper_bbox.height / 2;
        const wrapper_consolidated_transform_matrix =
          CommonNS.UtilNS.getConsolidatedTransformMatrix(wrapper_el) ?? target_image_el.createSVGMatrix();
        wrapper_center_point = wrapper_center_point.matrixTransform(wrapper_consolidated_transform_matrix);

        const art_board_center_x = tab_el.__mp_art_board_size.width / 2;
        const art_board_center_y = tab_el.__mp_art_board_size.height / 2;

        const tx = art_board_center_x - wrapper_center_point.x;
        const ty = art_board_center_y - wrapper_center_point.y;

        editor.translateEl({ id, tx, ty });
      },
      is_need_cutting_line: true,
      can_set_background: false,
      background: { fill, opacity },
    });
    if (is_added && tab_el.__mp_free_template_svg_el != null) {
      tab_el.__mp_free_template_svg_el.dataset.is_show = 'false';
      show2TypeNoneMakeStickerFooter(tab_el);
    }
  } finally {
    tab_el.__mp_is_prevent_mouse_move = true;
  }
};
