import { html } from 'fxjs/es';
import { SVGEditorAttrS } from '../../../Attr/S/Function/module/SVGEditorAttrS.js';

export const fontStyleWrapper = (svg_el) => html`
  <div class="font_style_wrapper">
    <div
      data-key="font-weight"
      data-default_value="normal"
      data-value="bold"
      class="is_cursor_pointer font_style ${SVGEditorAttrS.get('font-weight', svg_el) === 'bold'
        ? 'active'
        : ''}"
    ></div>
    <div
      data-key="font-style"
      data-value="italic"
      data-default_value=""
      class="is_cursor_pointer font_style ${SVGEditorAttrS.get('font-style', svg_el) === 'italic'
        ? 'active'
        : ''}"
    ></div>
    <div
      data-key="text-decoration"
      data-value="underline"
      data-default_value=""
      class="is_cursor_pointer font_style ${SVGEditorAttrS.get('text-decoration', svg_el).indexOf(
        'underline',
      ) > -1
        ? 'active'
        : ''}"
    ></div>
    <div
      data-key="text-decoration"
      data-value="line-through"
      data-default_value=""
      class="is_cursor_pointer font_style ${SVGEditorAttrS.get('text-decoration', svg_el).indexOf(
        'line-through',
      ) > -1
        ? 'active'
        : ''}"
    ></div>
  </div>
`;
