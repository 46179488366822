import { find, go, html, sel, strMap, tap } from 'fxjs/es';
import { $el, $appendTo, $delegate, $find, $qs, $remove, $setCss } from 'fxdom/es';

const EDITORS = [
  { name: '일반', pc_id: 87202 },
  { name: '규격형 스티커', pc_id: 7844482 },
  { name: '규격형 크라프트 스티커', pc_id: 7844516 },
  { name: '자유형 스티커', pc_id: 7848621 },
  { name: '자유형 크라프트 스티커', pc_id: 7848617 },
  { name: '마스킹 테이프 (12mm)', pc_id: '9163389' },
  { name: '마스킹 테이프 (15mm)', pc_id: '9163484' },
  { name: '마스킹 테이프 (20mm)', pc_id: '9163311' },
  { name: '스파클링 마스킹 테이프 (12mm)', pc_id: '9163457' },
  { name: '스파클링 마스킹 테이프 (15mm)', pc_id: '9163479' },
  { name: '스파클링 마스킹 테이프 (20mm)', pc_id: '9163481' },
  { name: '자유형 아크릴 키링 (투명)', pc_id: '8870691' },
  { name: '사각 아크릴 키링 (투명)', pc_id: '8871035' },
  { name: '아크릴 스탠드 S (~가로 7cm)', pc_id: '13415375' },
  { name: '아크릴 스탠드 M (~가로 14cm)', pc_id: '13460412' },
  { name: '아크릴 스탠드 L (~가로 20cm)', pc_id: '13460413' },
];
export const getEditorPcId = (location_el) => {
  if ($qs('.select_box_wrapper .select_box')) return;
  const { top, left, width, height } = location_el.getBoundingClientRect();
  const prevent_default_func = (e) => {
    e.preventDefault();
  };
  return new Promise((resolve) => {
    go(
      html`
        <div class="select_box">
          <div class="blank" style=""></div>
          <select name="pc_id">
            <option value="null">선택</option>
            ${go(
              EDITORS,
              strMap((editor) => html` <option value="${editor.pc_id}">${editor.name}</option> `),
            )}
          </select>
        </div>
      `,
      $el,
      tap(
        $find('select'),
        $setCss({
          top,
          left,
          width,
          height,
        }),
      ),
      $appendTo($qs('.select_box_wrapper')),
      tap((el) => {
        el.addEventListener('wheel', prevent_default_func);
      }),
      $delegate('click', '.blank', (e) => {
        $remove(e.delegateTarget);
        resolve();
      }),
      $delegate('change', 'select', (e) => {
        const pc_id = e.currentTarget.value;
        $remove(e.delegateTarget);
        resolve(
          go(
            EDITORS,
            find((editor) => pc_id == editor.pc_id),
            sel('pc_id'),
          ),
        );
      }),
    );
  });
};
