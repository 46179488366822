export const SHIPPING_COMPANY = {
  CJ: 'cj',
  DOORA: 'doora',
  EMS: 'ems',
  DHL: 'dhl',
  LOTTELOGI: 'lotte',
};

export const ORDER_STATUS = Object.freeze({
  BEFORE_ORDER: 'before_order',
  ALL: 'all', // 전체
  ORDERING: 'ordering', // 결제 확인
  BEFORE_PRINT: 'before_print', // 제작 준비중
  PRINTING: 'printing', // 제작중
  BEFORE_DELIVER: 'before_deliver', // 배송 준비중
  DELIVERING: 'delivering', // 배송중
  BEFORE_CONFIRM: 'before_confirm', // 배송 완료 (구매 확정 전)
  CONFIRMED: 'confirmed', // 배송 완료 (구매 확정)
  CANCELED: 'canceled', // 주문 취소
  CANCEL_REQUEST: 'cancel_request', // 주문 취소 요청
  INDIVIDUAL_CANCEL: 'individual_canceled', // 개별 취소
});
