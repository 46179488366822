import { each, extend, go, pick, pipe } from 'fxjs/es';
import { $attr, $closest, $find, $findAll, $hasClass, $setAttr, $setVal, $toggleClass, $val } from 'fxdom/es';
import { getCurrentFcanvas, getCvObj, getCvObjLocation } from '../../Fcanvas/cv_object.js';
import { cylinderObj } from '../Cylinder/obj.js';
import { getCurrentBpfInMaker, getMpMakerEl } from '../../getSth.js';
import { shadeObj } from '../Shade/obj.js';
import { compositeObj } from '../Composite/obj.js';
import { isShadeStyle } from '../Shade/render.js';
import { renderFacesByCurrentBpcfsOnlySizeInMakerA } from '../../mp_maker.js';
import { isCylinderStyle } from '../../categorize.js';
import { addBlackSqaureCvPrintAreaToFcanvas, getItemValue } from './fs.js';
import { updateBaseProductFaceSizeFaces } from '../../CvPrintArea/cv_object.js';
import { plusStrokeWidth } from '../../Fcanvas/stroke.js';
import { control } from './event.js';
import { makeBPFTemplateEditorHtml } from './template.js';
import { makeCylinderEditorHtml } from './cylinder.js';
import { makeShade2EditorHtml, makeShadeEditorHtml } from './shade.js';
import { makeCompositeEditorHtml } from './composite.js';

function getKey(e) {
  return go(e.currentTarget, $closest('.item'), $attr('key'));
}

function cvPrintAreaCylinderStart(fcanvas) {
  fcanvas.is_editing_cylinder = true;
  getCvObj(fcanvas._objects, 'cv_mask1').globalCompositeOperation = 'source-over';
  getCvObj(fcanvas._objects, 'cv_mask1').visible = false;
  getCvObj(fcanvas._objects, 'cv_mask2').visible = false;
  fcanvas.renderAll();
}
function cvPrintAreaCylinderEnd(fcanvas) {
  delete fcanvas.is_editing_cylinder;
  getCvObj(fcanvas._objects, 'cv_mask1').globalCompositeOperation = 'destination-in';
  fcanvas.renderAll();
}

export const makeCvPrintAreaEvent = pipe(
  $.on3('change', '.cv_preview_editor .item.checkbox_input input', async (e) => {
    $.don_loader_start();
    const fcanvas = getCurrentFcanvas();
    const key = getKey(e);
    fcanvas.preview = fcanvas.preview || {};
    await go(e.currentTarget, $closest('.cv_preview_editor'), async (el) => {
      if (e.currentTarget.checked) {
        if (key === 'is_multiply') {
          fcanvas.preview.multiply = true;
        } else if (key === 'is_cylinder') {
          fcanvas.preview.cylinder = cylinderObj({
            area: go(
              getCvObj(fcanvas._objects, 'cv_print_area'),
              getCvObjLocation,
              pick(['top', 'left', 'width', 'height']),
            ),
          });
          go(el, $find('.cylinder_editor'), (shade_editor_el) => {
            shade_editor_el.innerHTML = makeCylinderEditorHtml(fcanvas.preview.cylinder);
          });
        } else if (key === 'is_shade') {
          const obj = fcanvas.preview.cylinder
            ? { light_location: { top: -1, left: 0 } }
            : { light_location: { top: -1, left: -1 } };
          fcanvas.preview.shade = shadeObj(obj);
          go(el, $find('.shade_editor'), (shade_editor_el) => {
            shade_editor_el.innerHTML = makeShadeEditorHtml(fcanvas.preview.shade);
          });
        } else if (key === 'shade2') {
          fcanvas.preview.shade2 = { deep_step: 1, type: 'font_color' };
          go(el, $find('.shade2_editor'), (shade_editor_el) => {
            shade_editor_el.innerHTML = makeShade2EditorHtml(fcanvas.preview.shade2);
          });
        } else if (key === 'is_composite') {
          fcanvas.preview.composite = compositeObj({
            area: go(
              getCvObj(fcanvas._objects, 'cv_print_area'),
              getCvObjLocation,
              pick(['top', 'left', 'width', 'height']),
            ),
          });
          go(el, $find('.composite_editor'), (shade_editor_el) => {
            shade_editor_el.innerHTML = makeCompositeEditorHtml(fcanvas.preview.composite);
          });
        } else if (key === 'is_template') {
          const template = {
            width: 0,
            height: 0,
            maximum_print_area: {
              top: 0,
              left: 0,
              width: 0,
              bottom: 0,
            },
          };
          go(el, $find('.template_editor'), (template_editor_el) => {
            template_editor_el.innerHTML = makeBPFTemplateEditorHtml(template);
          });
        }
      } else {
        if (key === 'is_cylinder') {
          delete fcanvas.preview.cylinder;
          go(el, $find('.cylinder_editor'), (el) => (el.innerHTML = ''));
        } else if (key === 'is_multiply') {
          delete fcanvas.preview.multiply;
        } else if (key === 'is_shade') {
          delete fcanvas.preview.shade;
          go(el, $find('.shade_editor'), (el) => (el.innerHTML = ''));
        } else if (key === 'is_composite') {
          delete fcanvas.preview.composite;
          go(el, $find('.composite_editor'), (el) => (el.innerHTML = ''));
        } else if (key === 'is_template') {
          go(el, $find('.template_editor'), (el) => (el.innerHTML = ''));
        }
        if (!fcanvas.preview.cylinder && !fcanvas.preview.shade) {
          delete fcanvas.preview;
        }
      }
      const bpf = getCurrentBpfInMaker();
      bpf.preview = fcanvas.preview;
      go(getMpMakerEl(fcanvas), $setAttr({ is_shade: isShadeStyle(fcanvas) }));
    });

    await renderFacesByCurrentBpcfsOnlySizeInMakerA();
    $.don_loader_end();
  }),

  $.on3('change', '.cv_preview_editor .item[key="cv_print_area"] .cv_print_area_item input', async (e) => {
    const fcanvas = getCurrentFcanvas();
    let cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
    const name = $attr('name', e.currentTarget);

    if (isCylinderStyle(fcanvas)) {
      cvPrintAreaCylinderStart(fcanvas);
      addBlackSqaureCvPrintAreaToFcanvas(fcanvas, '#c0c0c0');
      const cv_black_square_print_area = getCvObj(fcanvas._objects, 'cv_black_square_print_area');
      if (name === 'width') {
        const width = go(e.currentTarget, $val, parseInt);
        const left = (860 - width) / 2;
        go(e.currentTarget, $closest('.horizontal_func'), $findAll('[name="width"]'), each($setVal(width)));
        go(e.currentTarget, $closest('.horizontal_func'), $findAll('[name="left"]'), each($setVal(left)));
      }
      go(getItemValue(e), (v) => {
        extend(cv_black_square_print_area, v);
        extend(cv_black_square_print_area, plusStrokeWidth(cv_black_square_print_area));
        extend(cv_print_area, v);
        extend(cv_print_area, plusStrokeWidth(cv_print_area));
      });
      fcanvas.renderAll();
      await control(fcanvas, (cv_obj) => {});
    } else {
      go(getItemValue(e), (v) => {
        extend(cv_print_area, v);
        extend(cv_print_area, plusStrokeWidth(cv_print_area));
      });
      if (name === 'rx' || name === 'ry') {
        await updateBaseProductFaceSizeFaces(fcanvas, (sf) => {
          const cv_print_area_location = getCvObjLocation(cv_print_area);
          extend(sf.print.px, pick(['rx', 'ry'], cv_print_area), cv_print_area_location);
        });
        await renderFacesByCurrentBpcfsOnlySizeInMakerA();
      }
      await control(fcanvas, (cv_obj) => {});
      cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
      cv_print_area.visible = true;
      fcanvas.renderAll();
    }
  }),
  $.on3('click', '.cv_preview_editor .item[key="cv_print_area"] button.save_cv_print_area', async (e) => {
    const fcanvas = getCurrentFcanvas();
    const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
    go(getItemValue(e), (v) => {
      extend(cv_print_area, v);
      extend(cv_print_area, plusStrokeWidth(cv_print_area));
    });
    $.don_loader_start();
    const cv_print_area_location = getCvObjLocation(cv_print_area);
    await updateBaseProductFaceSizeFaces(fcanvas, (sf) => {
      extend(sf.print.px, cv_print_area_location);
    });
    getCvObj(fcanvas._objects, 'cv_black_square_print_area') &&
      getCvObj(fcanvas._objects, 'cv_black_square_print_area').remove();
    await control(fcanvas, (cv_obj) => {});
    cv_print_area.visible = false;
    fcanvas.renderAll();
    $.don_loader_end();
    cvPrintAreaCylinderEnd(fcanvas);
    $.alert('적용 됐습니다.');
  }),

  $.on3('click', '.cv_preview_editor >.option .hide', (e) => {
    go(e.currentTarget, $closest('.cv_preview_editor'), $toggleClass('hided'));
  }),
  $.on3('click', '.cv_preview_editor >.option .zoom', (e) => {
    const cv_preview_editor = go(e.currentTarget, $closest('.cv_preview_editor'));
    go(cv_preview_editor, $toggleClass('zoom'));
    G.mp.maker.zoom($hasClass('zoom', cv_preview_editor));
  }),
);
