export const SHINHAN_EVENT = {
  event_name: 'shinhanbrand',
  bps: [
    {
      base_product_id: 3179,
      base_product_face_ids: [5026, 5028],
      price_info: [
        { face_length: 1, price: 14450 - 17000 },
        { face_length: 2, price: 21250 - 25000 },
      ],
    },
    {
      base_product_id: 3178,
      base_product_face_ids: [5023, 5022],
      price_info: [
        { face_length: 1, price: 14280 - 16800 },
        { face_length: 2, price: 21080 - 24800 },
      ],
    },
  ],
  view_style: 'custom-detail-style-a',
  no_story: true,
  no_review: true,
  event_url: 'shinhanbrand',
  url: 'shinhanbrand/maker',
  // promotion_code_id: 11966,
  // coupon: '신한프렌즈',
  // purchase_limit_quantity: 1,
  don_frame_key: 'shinhan',
  box_folders: 'shinhan_folders',
  box_group_images: 'shinhan_group_images',
  box_images: 'shinhan_images',
  // max_day_coupon_count: 100,
  message: {
    cart_checkout_event_ended: '이벤트가 종료 됐습니다.<br>신한브랜드 상품을 삭제해주세요.',
    error: '이벤트 기간이 아닙니다.',
  },
};
export const EVENTS = [SHINHAN_EVENT];
