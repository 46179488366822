import axios from 'axios';
import { $delegate, $find, $removeAttr, $setAttr, $setText, $setVal, $val } from 'fxdom/es';
import { go } from 'fxjs/es';
import { ConfigSentryF } from '../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPAuthConstantS } from '../../../S/Constant/module/OMPAuthConstantS.js';
import { OMPAuthFindIDResultMuiF } from '../../Result/F/Mui/module/OMPAuthFindIDResultMuiF.js';
import { OMPAuthFindIDResultTmplS } from '../../Result/S/Tmpl/module/OMPAuthFindIDResultTmplS.js';

export const catchError = async (e) => {
  $.don_loader_end();

  // MEMO: axios 에러
  if (e?.response?.data) {
    let error = {};
    if (typeof e?.response?.data === 'string') error = JSON.parse(e?.response?.data);
    else error = e?.response?.data;

    if (e?.response?.data?.title) {
      await OMPCoreUtilF.titleAlert(error);
    } else {
      await OMPCoreUtilF.alert({
        content: error?.message || TT('error::common::error_01'),
      });
    }
    return;
  }

  // MEMO: 프론트 에러
  if (e?.handle) {
    if (e?.json) {
      await OMPCoreUtilF.titleAlert(e.json);
      return;
    }

    await OMPCoreUtilF.alert({
      content: e?.message || TT('error::common::error_01'),
    });
    return;
  }

  OMPCoreUtilF.alert({
    content: TT('error::common::error_01'),
  });
  ConfigSentryF.error(e);
};

export const events = (tab_el) => {
  const auth_timer = new OMPCoreUtilF.Timer($find('.omp-find-id__timer', tab_el));

  return go(
    tab_el,
    $delegate('input', 'input[name="name"]', (e) => {
      const name = $val(e.currentTarget);
      const mobile_el = go(e.delegateTarget, $find('input[name="mobile"]'));
      const mobile = $val(mobile_el).trim();

      const req_btn = go(e.delegateTarget, $find('.omp-find-id__mobile-request'));
      const is_pass = OMPCoreUtilS.checkPhoneNumberSms(mobile) && !!name;

      if (is_pass && req_btn.disabled) {
        go(req_btn, $removeAttr('disabled'));
      }

      if (!is_pass && !req_btn.disabled) {
        go(req_btn, $setAttr({ disabled: '' }));
      }
    }),
    $delegate('input', 'input[name="mobile"]', (e) => {
      const mobile = OMPCoreUtilS.getOnlyNumber($val(e.currentTarget));
      const name_el = go(e.delegateTarget, $find('input[name="name"]'));
      const name = $val(name_el).trim();

      $setVal(mobile, e.currentTarget);
      if (mobile.length > e.currentTarget.maxLength) {
        $setVal(mobile.slice(0, e.currentTarget.maxLength), e.currentTarget);
      }

      const req_btn = go(e.delegateTarget, $find('.omp-find-id__mobile-request'));
      const is_pass = OMPCoreUtilS.checkPhoneNumberSms(mobile) && !!name;

      if (is_pass && req_btn.disabled) {
        go(req_btn, $removeAttr('disabled'));
      }

      if (!is_pass && !req_btn.disabled) {
        go(req_btn, $setAttr({ disabled: '' }));
      }
    }),
    $delegate('input', 'input[name="code"]', (e) => {
      $setVal($val(e.currentTarget).replace(/[^0-9]/g, ''), e.currentTarget);
      const code = go(e.currentTarget, $val).trim();
      if (code.length > e.currentTarget.maxLength) {
        $setVal(code.slice(0, e.currentTarget.maxLength), e.currentTarget);
      }

      const submit_btn = go(e.delegateTarget, $find('.omp-find-id__submit'));
      const valid_code = code.length === 6;

      if (valid_code && submit_btn.disabled) {
        go(submit_btn, $removeAttr('disabled'));
      }

      if (!valid_code && !submit_btn.disabled) {
        go(submit_btn, $setAttr({ disabled: '' }));
      }
    }),
    $delegate('click', '.omp-find-id__mobile-request', async (e) => {
      const mobile_el = go(e.delegateTarget, $find('input[name="mobile"]'));
      const name_el = go(e.delegateTarget, $find('input[name="name"]'));
      const cert_code_el = go(e.delegateTarget, $find('.omp-find-id__form-value[data-requested]'));
      const req_btn = go(e.delegateTarget, $find('.omp-find-id__mobile-request'));
      const mobile = $val(mobile_el).trim();
      const name = $val(name_el).trim();

      if (!mobile || (mobile && !OMPCoreUtilS.checkPhoneNumberSms(mobile))) {
        mobile_el.focus();
        throw new OMPAuthConstantS.AuthError({
          title: TT('error::auth::error_28'),
          content: TT('error::auth::error_28_01'),
        });
      }

      if (!name) {
        name_el.focus();
        throw new OMPAuthConstantS.AuthError({
          title: TT('error::auth::error_28'),
          content: TT('error::auth::error_06'),
        });
      }

      try {
        $.don_loader_start();
        const res = await axios.post('/@api/find_id/request_cert', { mobile, name });
        if (!res.data.result) return OMPCoreUtilF.titleAlert(res.data);
        if (cert_code_el.dataset.requested === 'false') {
          $setText(TT('auth::find_id::find_id_09'), req_btn);
          cert_code_el.dataset.requested = 'true';
        }
        auth_timer.timerReStart();
        $.don_loader_end();
      } catch (e) {
        return catchError(e);
      }
    }),
    $delegate('submit', '.omp-find-id', async (e) => {
      e.originalEvent.preventDefault();
      const submit_btn = go(e.delegateTarget, $find('.omp-find-id__submit'));
      const mobile_el = go(e.delegateTarget, $find('input[name="mobile"]'));
      const name_el = go(e.delegateTarget, $find('input[name="name"]'));
      const code_el = go(e.delegateTarget, $find('input[name="code"]'));
      const cert_code_el = go(e.delegateTarget, $find('.omp-find-id__form-value[data-requested]'));
      const code = OMPCoreUtilS.getOnlyNumber($val(code_el).trim());
      const mobile = $val(mobile_el).trim();
      const name = $val(name_el).trim();
      const valid_code = code.length === 6;

      if (!mobile || (mobile && !OMPCoreUtilS.checkPhoneNumberSms(mobile))) {
        mobile_el.focus();
        throw new OMPAuthConstantS.AuthError({
          title: TT('error::auth::error_28'),
          content: TT('error::auth::error_28_01'),
        });
      }

      if (!name) {
        name_el.focus();
        throw new OMPAuthConstantS.AuthError({
          title: TT('error::auth::error_28'),
          content: TT('error::auth::error_06'),
        });
      }

      if (!valid_code) {
        code_el.focus();
        throw new OMPAuthConstantS.AuthError({
          content: TT('error::auth::error_29'),
        });
      }

      try {
        $.don_loader_start();
        const res = await axios.post('/@api/find_id/verify_cert', { mobile, name, code });
        $.don_loader_end();

        if (!res.data.result) return OMPCoreUtilF.titleAlert(res.data);
        auth_timer.timerStop();

        $setVal('', code_el);
        cert_code_el.dataset.requested = 'false';

        if (!submit_btn.disabled) {
          go(submit_btn, $setAttr({ disabled: '' }));
        }

        await MuiF.pushPage(OMPAuthFindIDResultMuiF.page, (p, [t]) => {
          p.hide_frame_button_type = 'X';
          p.title = TT('auth::find_id::find_id_10');
          t.makeData = () => res.data.users;
          t.template = OMPAuthFindIDResultTmplS.findIDResultTmpl;
        });
      } catch (e) {
        return catchError(e);
      }
    }),
  );
};
