export const lottieAnimationData = {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 3.4.5', a: '', k: '', d: '', tc: '' },
  fr: 30,
  ip: 0,
  op: 44,
  w: 50,
  h: 50,
  nm: 'Comp 2',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 1,
      nm: 'Black Solid 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: { a: 0, k: [25, 25, 0], ix: 2 },
        a: { a: 0, k: [50, 22, 0], ix: 1 },
        s: { a: 0, k: [113.636, 113.636, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                i: { x: 0, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 0,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [72, 17.257],
                      [72, 17.257],
                      [72, 26.743],
                      [72, 26.743],
                      [72, 17.257],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 14,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [55.214, 17.257],
                      [28, 17.257],
                      [28, 26.743],
                      [72, 26.743],
                      [72, 17.257],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 30,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [55.214, 17.257],
                      [28, 17.257],
                      [28, 26.743],
                      [72, 26.743],
                      [72, 17.257],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 43,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [26.145, 17.257],
                      [28, 17.257],
                      [28, 26.743],
                      [25, 26.743],
                      [25, 17.257],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      sw: 100,
      sh: 44,
      sc: '#000000',
      ip: 0,
      op: 44,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 1,
      nm: 'Black Solid 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [25, 25, 0], ix: 2 },
        a: { a: 0, k: [50, 22, 0], ix: 1 },
        s: { a: 0, k: [113.636, 113.636, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                i: { x: 0, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 0,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [28, 17.257],
                      [28, 17.257],
                      [28, 26.743],
                      [28, 26.743],
                      [28, 17.257],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 14,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [55.214, 17.257],
                      [28, 17.257],
                      [28, 26.743],
                      [72, 26.743],
                      [72, 17.257],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: { x: 0.833, y: 1 },
                o: { x: 0.167, y: 0 },
                t: 30,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [55.214, 17.257],
                      [28, 17.257],
                      [28, 26.743],
                      [72, 26.743],
                      [72, 17.257],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 43,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [79.725, 17.257],
                      [92.25, 17.257],
                      [92.25, 26.743],
                      [72, 26.743],
                      [72, 17.257],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      sw: 100,
      sh: 44,
      sc: '#000000',
      ip: 0,
      op: 44,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
