import sub from 'date-fns/fp/sub/index.js';
import startOfDay from 'date-fns/fp/startOfDay/index.js';
import endOfDay from 'date-fns/fp/endOfDay/index.js';
import setDay from 'date-fns/fp/setDay/index.js';
import { go } from 'fxjs/es';

export const MAX_CUTTING_PAPER = 300;

// 일요일 - 0,  월요일 - 1
export const WORK_SCHEDULE = {
  1: (date) => [
    {
      start: go(date, sub({ days: 7 }), setDay(1), startOfDay), // 지난주 월요일
      end: go(date, setDay(0), endOfDay), // 일요일
      collabo_type: 'creator',
      merged_type: 'child',
    },
    {
      start: go(date, sub({ days: 7 }), setDay(5), startOfDay), // 지난주 금요일
      end: go(date, setDay(0), endOfDay), // 일요일
      collabo_type: 'creator',
      merged_type: 'sample',
    },
    {
      start: go(date, sub({ days: 7 }), setDay(5), startOfDay), // 지난주 금요일
      end: go(date, setDay(0), endOfDay), // 일요일
      collabo_type: 'creator',
      merged_type: 'fast_child',
    },
    {
      start: go(date, sub({ days: 7 }), setDay(5), startOfDay), // 지난주 금요일
      end: go(date, setDay(0), endOfDay), // 일요일
      collabo_type: '',
    },
  ],
  2: (date) => [
    {
      start: go(date, setDay(1), startOfDay), // 이번주 월요일 0시
      end: go(date, setDay(1), endOfDay), // 이번주 월요일 24시
      collabo_type: 'creator',
      merged_type: 'sample',
    },
    {
      start: go(date, setDay(1), startOfDay), // 이번주 월요일 0시
      end: go(date, setDay(1), endOfDay), // 이번주 월요일 24시
      collabo_type: 'creator',
      merged_type: 'fast_child',
    },
    {
      start: go(date, setDay(1), startOfDay), // 이번주 월요일 0시
      end: go(date, setDay(1), endOfDay), // 이번주 월요일 24시
      collabo_type: '',
    },
  ],
  3: (date) => [
    {
      start: go(date, setDay(2), startOfDay), // 이번주 화요일 0시
      end: go(date, setDay(2), endOfDay), // 이번주 화요일 24시
      collabo_type: 'creator',
      merged_type: 'sample',
    },
    {
      start: go(date, setDay(2), startOfDay), // 이번주 화요일 0시
      end: go(date, setDay(2), endOfDay), // 이번주 화요일 24시
      collabo_type: 'creator',
      merged_type: 'fast_child',
    },
    {
      start: go(date, setDay(2), startOfDay), // 이번주 화요일 0시
      end: go(date, setDay(2), endOfDay), // 이번주 화요일 24시
      collabo_type: '',
    },
  ],
  4: (date) => [
    {
      start: go(date, setDay(3), startOfDay), // 이번주 수요일 0시
      end: go(date, setDay(3), endOfDay), // 이번주 수요일 24시
      collabo_type: 'creator',
      merged_type: 'sample',
    },
    {
      start: go(date, setDay(3), startOfDay), // 이번주 수요일 0시
      end: go(date, setDay(3), endOfDay), // 이번주 수요일 24시
      collabo_type: 'creator',
      merged_type: 'fast_child',
    },
    {
      start: go(date, setDay(3), startOfDay), // 이번주 수요일 0시
      end: go(date, setDay(3), endOfDay), // 이번주 수요일 24시
      collabo_type: '',
    },
  ],
  5: (date) => [
    {
      start: go(date, setDay(4), startOfDay), // 이번주 목요일 0시
      end: go(date, setDay(4), endOfDay), // 이번주 목요일 24시
      collabo_type: 'creator',
      merged_type: 'sample',
    },
    {
      start: go(date, setDay(4), startOfDay), // 이번주 목요일 0시
      end: go(date, setDay(4), endOfDay), // 이번주 목요일 24시
      collabo_type: 'creator',
      merged_type: 'fast_child',
    },
    {
      start: go(date, setDay(4), startOfDay), // 이번주 목요일 0시
      end: go(date, setDay(4), endOfDay), // 이번주 목요일 24시
      collabo_type: '',
    },
  ],
};

export const WE_TYPE = {
  NOTE: 'note',
  POSTCARD: 'postcard',
  A5: 'a5',
  B5: 'b5',
  RECT: 'rect',
  SQUARE: 'square',
};

export const WE_PDF_STATUS = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
};

export const QR_ACTION = {
  PRODUCT: 'product',
  PRJ: 'prj',
};
