import { $findAll } from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { activate, deactivate } from './is_active.js';
import { show, hide } from './is_show.js';

export const activateFooterAddIcon = (tab_el) => {
  go(tab_el, $findAll(`.footer[data-icon_type="add"]`), each(activate));
};

export const deactivateFooterAddIcon = (tab_el) => {
  go(tab_el, $findAll(`.footer[data-icon_type="add"]`), each(deactivate));
};

export const hideAllFooters = (tab_el) => {
  go(tab_el, $findAll(`.footer`), each(hide));
};

export const showTypeNoneFooters = (tab_el) => {
  go(
    tab_el,
    $findAll(`.footer`),
    each((el) => {
      if (el.classList.contains('none')) {
        show(el);
        return;
      }
      hide(el);
    }),
  );
};

export const showTypeTextFooters = (tab_el) => {
  go(
    tab_el,
    $findAll('.footer'),
    each((el) => {
      if (el.classList.contains('text')) {
        show(el);
        return;
      }
      hide(el);
    }),
  );
};

export const showTypePathFooters = (tab_el) => {
  go(
    tab_el,
    $findAll('.footer'),
    each((el) => {
      if (el.classList.contains('path')) {
        show(el);
        return;
      }
      hide(el);
    }),
  );
};

export const showTypeShapeFooters = (tab_el) => {
  go(
    tab_el,
    $findAll(`.footer`),
    each((el) => {
      if (el.classList.contains('shape')) {
        show(el);
        return;
      }
      hide(el);
    }),
  );
};

export const showTypeObjectFooters = (tab_el) => {
  go(
    tab_el,
    $findAll('.footer'),
    each((el) => {
      if (el.classList.contains('object')) {
        show(el);
        return;
      }
      hide(el);
    }),
  );
};
