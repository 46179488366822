import { compact, find, go, join, map, pluck, sel, sortBy } from 'fxjs/es';
import { BpOptionF } from '../../../../../BpOption/F/Function/module/BpOptionF.js';
import { BpOptionConstantS } from '../../../../../BpOption/S/Constant/module/BpOptionConstantS.js';

function getBpOptionGroupIds(bp) {
  return go(
    bp._.base_product_colors[0]._.base_product_color_faces,
    map((base_product_color_face) => base_product_color_face._.base_product_face),
    sortBy(sel('no')),
    pluck('id'),
    map((bpf_id) =>
      go(
        BpOptionF.getBpOptionGroupsOfBpfId(bpf_id, bp),
        find(
          (bp_option_group) =>
            bp_option_group.dev_name === BpOptionConstantS.BIZ_OPTIONS_DEV_NAMES.DOSU_COLORS,
        ),
      ),
    ),
    compact,
    pluck('id'),
    join(','),
  );
}

export const whenChangeRemoveDesign = {
  canDuplicateBpfDesigns: (src_bp, target_bp) => {
    if (!getBpOptionGroupIds(src_bp)) return;
    if (!getBpOptionGroupIds(target_bp)) return;
    return getBpOptionGroupIds(src_bp) === getBpOptionGroupIds(target_bp);
  },
};
