import { $closest } from 'fxdom/es';
import { SVGEditorColorAndBorderMuiS } from '../../S/Mui/module/SVGEditorColorAndBorderMuiS.js';
import { SVGEditorRangeSliderWrapperF } from '../../../RangeSliderWrapper/F/Function/module/SVGEditorRangeSliderWrapperF.js';
import { SVGEditorColorF } from '../../../Color/F/Function/module/SVGEditorColorF.js';
import { SVGEditorStrokeDashF } from '../../../StrokeDash/F/Function/module/SVGEditorStrokeDashF.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const border_tab = {
  ...SVGEditorColorAndBorderMuiS.border_tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    const frame_opt = $closest('.don_frame', tab_el).frame_opt;
    tab_el.tab_opt.__pickr = SVGEditorColorF.event({
      el: tab_el,
      key: 'stroke',
      ___selected_el: frame_opt.___selected_el,
    });
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    const { ___selected_el, __mp_sticker_editor } = $closest('.don_frame', tab_el).frame_opt;
    SVGEditorRangeSliderWrapperF.event({ el: tab_el, ___selected_el, __mp_sticker_editor });
    SVGEditorStrokeDashF.event({
      target_el: tab_el,
      svg_el: ___selected_el,
      mp_sticker_editor: __mp_sticker_editor,
    });
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후as 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {
    tab_el$.tab_opt.__pickr?.destroyAndRemove();
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
