import { entries, go, object, reject, sel } from 'fxjs/es';
import axios from 'axios';
import { $qs } from 'fxdom/es';

const memoize = (func) => {
  func.___result = {};
  setInterval(() => {
    func.___result = go(
      func.___result,
      entries,
      reject(([key, result]) => {
        const is_old = Math.abs(result.time - new Date()) > 1000 * 60 * 2;
        return is_old;
      }),
      object,
    );
  }, 1000 * 10);
  return (...args) => {
    const key = args.join('');
    if (func.___result[key] && func.___result[key].value) {
      func.___result[key].time = new Date();
      return func.___result[key].value;
    }
    return go(func(...args), (value) => {
      func.___result[key] = {
        value,
        time: new Date(),
      };
      return value;
    });
  };
};

const resizeToBase64 = memoize(async (url, width) => {
  try {
    return await go(
      axios.post(
        $qs('html#dream_factory')
          ? '/@fileUpload/prerequisite_maker/resize'
          : `/${T.lang}/@fileUpload/prerequisite_maker/resize`,
        { url, width: Math.ceil(width) },
        { responseType: 'arraybuffer' },
      ),
      sel('data'),
      (buffer) => new Uint8Array(buffer),
      (buffer) =>
        'data:image/png;base64,' + btoa(buffer.reduce((data, byte) => data + String.fromCharCode(byte), '')),
    );
  } catch (e) {
    axios.post(`/${T.lang}/@api/prerequisite_maker/error_logs`, {
      json_memo: {
        error_stack: e.stack,
        url,
        width,
      },
      name: 'maker_resizeToBase64',
    });
    return url;
  }
});

const getProperUrl = ({ url, width }) => {
  const original_url = G.to_original(url);
  if (width <= 150) {
    return G.to_150(original_url);
  } else if (width > 150 && width <= 350) {
    return G.to_350(original_url);
  } else if (width > 350 && width <= 600) {
    return G.to_600(original_url);
  } else if (width > 600 && width <= 900) {
    return G.to_900(original_url);
  } else {
    return original_url;
  }
};
export const makeResizedImage = ({ url, width, need_resize }) => {
  if (url.indexOf('base64') !== -1) return url;
  const url_for_resize = getProperUrl({ url, width });
  if (!need_resize) return url_for_resize;
  return resizeToBase64(url_for_resize, width);
};
