import lottie from 'lottie-web';
import { go, html, tap, extend, map } from 'fxjs/es';
import { $appendTo, $el, $find, $findAll, $qs, $setCss } from 'fxdom/es';
import { animation_data } from './loader.inhouse.lottie.js';
import { DfInhouseF } from './module/DfInhouseF.js';

// @params name - "printer" | "cardboard"
export const lottie_loader = {
  start: (name = 'cardboard', parent, css, timeout = 15000) => {
    $.don_loader_end();
    const WRAPPER = `lottie_loader_wrapper`;
    const IMG = `lottie_loader_img`;

    const loader_wrap_el = $qs(`.${WRAPPER}`);
    if (loader_wrap_el) {
      return;
    }

    return go(
      $el(html`
        <div class="${WRAPPER}">
          <div class="${IMG}"></div>
        </div>
      `),
      $setCss(
        extend(
          {
            width: '18rem',
            height: '18rem',
            zIndex: 99999,
            position: 'fixed',
            top: 'calc(50% - 10rem)',
            left: 'calc(50% - 10rem)',
          },
          css ?? {},
        ),
      ),
      $appendTo(parent || $qs('body')),
      (loader_wrapper) => {
        loader_wrapper.__lottie__ = lottie.loadAnimation({
          container: $find(`.${IMG}`, loader_wrapper),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: animation_data[name],
        });
        loader_wrapper.__lottie__.play();
        return loader_wrapper;
      },
      tap(() => {
        if (timeout != null) {
          setTimeout(() => {
            lottie_loader.end(parent);
          }, timeout);
        }
      }),
    );
  },
  end: (delay_out, parent) => {
    $.don_loader_end();
    const WRAPPER = `lottie_loader_wrapper`;
    go(
      $findAll(`.${WRAPPER}`, parent || $1('body')),
      map((loader_wrapper) => {
        if (loader_wrapper) {
          if (delay_out) {
            setTimeout(() => {
              loader_wrapper && removeLoader(loader_wrapper);
            }, delay_out);
          } else {
            removeLoader(loader_wrapper);
          }
        }
      }),
    );
  },
};

function removeLoader(wrapper_el) {
  if (wrapper_el?.__lottie__) {
    wrapper_el?.__lottie__.destroy();
  }
  wrapper_el.remove();
}

export const tableLoader = {
  start: () => {
    DfInhouseF.lottie_loader.start('cardboard', DfInhouseF.stateDOM.get.section('box-table'), {
      position: 'absolute',
      top: '30%',
    });
  },
};

export const bucketLoader = {
  start: () => {
    const bucket_loader_el = $el(html`<div class="bucket-loader"></div>`);
    const animation = lottie.loadAnimation({
      container: bucket_loader_el,
      renderer: 'svg',
      animationData: animation_data.check,
    });
    animation.setSpeed(1.5);
    animation.playSegments([0, 29], true);
    animation.loop = true;
    animation.play();
    bucket_loader_el.animation = animation;
    return bucket_loader_el;
  },
  end: (el) => {
    const animation = el?.animation;
    if (animation != null) {
      animation.loop = false;
      animation.playSegments([85, 145], false);
      setTimeout(() => {
        animation.destroy();
      }, 1500);
    }
    setTimeout(() => {
      el && el.remove();
    }, 1500);
  },
};
