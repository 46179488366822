import {
  defnCertificationResetPasswordTab,
  defnEmailVerifyTab,
  defnIdPasswordFindTab,
  defnMpLoginSignInTab,
  defnMpLoginSignUpTab,
  defnPhoneCertificationCompleteTab,
  defnPhoneCertificationTab,
  defnResetPasswordTab,
} from './tab.js';
import { makeFbLogin } from './fs.js';

$.frame.defn_page([
  {
    page_name: 'mp_login_page',
    appended: $.on2('click', '.btn_fb_login', makeFbLogin),
    tabs: [defnMpLoginSignUpTab(), defnMpLoginSignInTab()],
  },
]);

$.frame.defn_page([
  {
    page_name: 'mp.reset_password_page',
    tabs: [defnResetPasswordTab()],
  },
]);

$.frame.defn_page({
  page_name: 'mp.id_pw_find_page',
  tabs: [defnIdPasswordFindTab()],
});

$.frame.defn_page([
  {
    page_name: 'mp.phone_cert_page',
    tabs: [defnPhoneCertificationTab()],
  },
]);

$.frame.defn_page([
  {
    page_name: 'mp.phone_cert_complete_page',
    tabs: [defnPhoneCertificationCompleteTab()],
  },
]);

// 사용안함
$.frame.defn_page([
  {
    page_name: 'mp.cert_reset_password_page',
    tabs: [defnCertificationResetPasswordTab()],
  },
]);

$.frame.defn_page({
  page_name: 'mp.email_verify_page',
  tabs: [defnEmailVerifyTab()],
});
