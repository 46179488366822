import axios from 'axios';
import {
  delay,
  each,
  find,
  flatMap,
  html,
  go,
  map,
  pick,
  pipe,
  pluck,
  sel,
  selEq,
  sort,
  uniq,
} from 'fxjs/es';
import { $append, $appendTo, $attr, $closest, $delegate, $el, $find, $qs, $setCss, $val } from 'fxdom/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { openCompositeTemplateMakerFrame } from '../../Maker/F/frame.js';
import { selectCompositeTemplatesList, updateCompositeTemplate } from '../../Maker/F/fs.js';
import { getBoxData, getFrameTag } from '../../Maker/F/util.js';
import { composite_template_list } from '../S/tmpl.js';
import { render_canvas } from '../../Core/S/composite_template_canvas.js';
import { makeDesignReadyBlobUrl } from '../../Core/F/render_canvas.js';
import { makeCanvasByUrl } from '../../../Canvas/S/util.js';
import { NewMakerFcanvasSettingF } from '../../../NewMaker/FcanvasSetting/F/Function/module/NewMakerFcanvasSettingF.js';
import { makeBlueMockup, makeRedLineMockup } from '../../Core/F/mockup_fns.js';
import { makeWarpCylinder } from '../../Core/F/cylinder.js';
import { SimpleModalMuiF } from '../../../SimpleModal/F/Mui/module/SimpleModalMuiF.js';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';

$.frame.defn_frame({
  frame_name: 'composite_templates_list',
  page_name: 'composite_templates_list_page',
  is_modal: true,
  always_remove: true,
  prev_frame_show: G.is_mobile_size(),
});

function renderList() {
  const full_composite_template_list_el = $qs('.composite_template_list');
  full_composite_template_list_el.innerHTML = composite_template_list(box.sel('composite_templates'));
}

async function initializeBoxData(base_product_id) {
  const composite_templates = await selectCompositeTemplatesList(base_product_id);
  box.set('composite_templates', composite_templates);
  return composite_templates;
}

$.frame.defn_page({
  page_name: 'composite_templates_list_page',
  hide_frame_button_type: 'X',
  title: '합성 템플릿 목록',
  tabs: [
    {
      tab_name: 'composite_templates_list_tab',
      template: () => legacyHtml`
        <div class="composite_templates_wrapper">
          <div class="option">
            <button class="add">합성 템플릿 추가</button>
            <button class="ct_total_test" style="margin:0 50px;">합성 템플릿 테스트</button>
          </div>
          <div class="composite_template_list"></div>
        </div>
      `,
      showed: async function (el) {
        const base_product_id = getFrameTag(el);
        await initializeBoxData(base_product_id);
        renderList();
      },
      appended: pipe(
        $.on3('click', '.ct_total_test', async (e) => {
          $.don_loader_start();
          const base_product_id = getFrameTag(e.currentTarget);
          const composite_template_list = await $.get('/@api/composite/select_full_composite_templates', {
            base_product_id,
          });
          const canvass = await go(
            composite_template_list,
            map(async (assoc_composite_template) => {
              await go(
                assoc_composite_template._.composite_masks,
                each((cm) =>
                  go(
                    cm._.composite_faces,
                    each(async (cf) => {
                      await NewMakerFcanvasSettingF.init({ base_product_id: cm._.base_product.id });
                      /*DF*/
                      const base_product_face_id = cf.base_product_face_id;
                      const {
                        data: { product_img_type, sf, mask_url, mask_url2, safe_area_url },
                      } = await axios.get('/@api/composite/get_sf_and_product_url', {
                        params: {
                          base_product_face_id,
                        },
                      });
                      cf.mockup_url = await go(
                        makeRedLineMockup({
                          product_img_type,
                          sf,
                          mask_url,
                          mask_url2,
                          safe_area_url,
                        }),
                        (canvas) => canvas.toDataURL(),
                        (url) => $.uploadFileToOnlyOriginalUrl({ url, original_name: 'temp_cf_mockup' }),
                        sel('url'),
                      );
                    }),
                  ),
                ),
              );
              const design_ready_canvas = await go(undefined, async () => {
                const etc_meta = assoc_composite_template._.composite_masks[0]._.composite_faces[0].etc_meta;
                if (etc_meta?.is_cylinder) {
                  const base_product_size_id = await go(
                    $.get('/@api/prerequisite_maker/thumb_base_product_size', {
                      base_product_id: assoc_composite_template._.composite_masks[0].base_product_id,
                    }),
                    sel('id'),
                  );
                  const size_face = await go(
                    $.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
                      id: assoc_composite_template._.composite_masks[0]._.composite_faces[0]
                        .base_product_face_id,
                    }),
                    sel('size_faces'),
                    find((sf) => sf.base_product_size_id === base_product_size_id),
                  );
                  return makeWarpCylinder(
                    {
                      size_face,
                      print_area_canvas: await makeCanvasByUrl(
                        assoc_composite_template._.composite_masks[0]._.composite_faces[0].mockup_url,
                      ),
                      canvas_width: assoc_composite_template.width,
                      mask_url: assoc_composite_template._.composite_masks[0].mask_transparent_url,
                    },
                    etc_meta,
                  );
                } else {
                  return go(makeDesignReadyBlobUrl(assoc_composite_template), makeCanvasByUrl);
                }
              });
              const bpc =
                assoc_composite_template._.composite_masks[0]._.base_product._.base_product_colors[0];
              return render_canvas({
                assoc_composite_template,
                canvas_el: document.createElement('canvas'),
                design_ready_canvas,
                selected_base_product_colors: [bpc],
              });
            }),
          );
          const mask_canvass = await go(
            composite_template_list,
            map(async (assoc_composite_template) => {
              await go(
                assoc_composite_template._.composite_masks,
                each((cm) =>
                  go(
                    cm._.composite_faces,
                    each(async (cf) => {
                      await NewMakerFcanvasSettingF.init({ base_product_id: cm._.base_product.id });
                      /*DF*/
                      const base_product_face_id = cf.base_product_face_id;
                      const {
                        data: { sf },
                      } = await axios.get('/@api/composite/get_sf_and_product_url', {
                        params: {
                          base_product_face_id,
                        },
                      });
                      await delay(500);
                      cf.mockup_url = await go(
                        makeBlueMockup({
                          sf,
                        }),
                        (canvas) => canvas.toDataURL(),
                        (url) => $.uploadFileToOnlyOriginalUrl({ url, original_name: 'temp_cf_mockup' }),
                        sel('url'),
                      );
                    }),
                  ),
                ),
              );
              const design_ready_canvas = await go(undefined, async () => {
                const etc_meta = assoc_composite_template._.composite_masks[0]._.composite_faces[0].etc_meta;
                if (etc_meta?.is_cylinder) {
                  const base_product_size_id = await go(
                    $.get('/@api/prerequisite_maker/thumb_base_product_size', {
                      base_product_id: assoc_composite_template._.composite_masks[0].base_product_id,
                    }),
                    sel('id'),
                  );
                  const size_face = await go(
                    $.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
                      id: assoc_composite_template._.composite_masks[0]._.composite_faces[0]
                        .base_product_face_id,
                    }),
                    sel('size_faces'),
                    find((sf) => sf.base_product_size_id === base_product_size_id),
                  );
                  return makeWarpCylinder(
                    {
                      size_face,
                      print_area_canvas: await makeCanvasByUrl(
                        assoc_composite_template._.composite_masks[0]._.composite_faces[0].mockup_url,
                      ),
                      canvas_width: assoc_composite_template.width,
                      mask_url: assoc_composite_template._.composite_masks[0].mask_transparent_url,
                    },
                    etc_meta,
                  );
                } else {
                  return go(makeDesignReadyBlobUrl(assoc_composite_template), makeCanvasByUrl);
                }
              });
              const bpc =
                assoc_composite_template._.composite_masks[0]._.base_product._.base_product_colors[0];
              return render_canvas({
                assoc_composite_template,
                canvas_el: document.createElement('canvas'),
                design_ready_canvas,
                selected_base_product_colors: [bpc],
              });
            }),
          );
          $.don_loader_end();
          await MuiF.openFrame(SimpleModalMuiF.frame, (frame, page, [tab]) => {
            tab.appended = (tab_el) => {
              go(
                tab_el,
                $closest('.don_frame'),
                $find('.don_wrapper'),
                $setCss({
                  width: 'inherit',
                  height: 'inherit',
                }),
              );
              go(
                tab_el,
                $find('.done'),
                $setCss({
                  display: 'none',
                }),
              );
              const first_el = go(tab_el, $find('.body'), $append($el(html`<div class="first"></div>`)));
              const second_el = go(tab_el, $find('.body'), $append($el(html`<div class="second"></div>`)));
              $setCss({
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                width: '100%',
                overflowY: 'scroll',
              })(first_el);
              go(
                tab_el,
                $delegate('click', '.done', () => {
                  MuiF.closeFrame(null);
                }),
              );
              go(canvass, each($setCss({ width: '100%', height: 'auto' })), each($appendTo(first_el)));
              go(mask_canvass, each($appendTo(second_el)));
            };
          });
        }),
        $.on3('change', '.no input', async (e) => {
          const composite_template = getBoxData(e.currentTarget, '.composite_template');
          composite_template.no = parseInt($val(e.currentTarget));
          $.don_loader_start();
          await updateCompositeTemplate(pick(['no', 'id'], composite_template));
          await delay(500, () => {});
          const base_product_id = getFrameTag(e.currentTarget);
          await initializeBoxData(base_product_id);
          renderList();
          $.don_loader_end();
        }),
        $delegate('click', '.duplicate', async function (e) {
          $.don_loader_start();
          const composite_template = getBoxData(e.currentTarget, '.composite_template');
          await axios.post('/@api/composite/duplicate_composite_template', {
            id: composite_template.id,
          });
          const base_product_id = getFrameTag(e.currentTarget);
          await initializeBoxData(base_product_id);
          renderList();
          $.don_loader_end();
        }),
        $delegate('change', 'select[name="change_content_in_composite_template"]', async function (e) {
          try {
            const original_composite_template = getBoxData(e.currentTarget, '.composite_template');
            const composite_template_id_for_change = $val(e.currentTarget);
            const bool = await $.confirm(
              `${original_composite_template.id}의 내용물을 ${composite_template_id_for_change}의 내용물로 변경하시겠습니까?`,
            );
            const is_different_bpfs = go(
              [parseInt(composite_template_id_for_change), parseInt(original_composite_template.id)],
              map(
                pipe(
                  (id) => find(selEq('id', id), box.sel('composite_templates')),
                  sel('_.composite_masks'),
                  flatMap((cm) => cm._.composite_faces),
                  pluck('base_product_face_id'),
                  uniq,
                  sort,
                ),
              ),
              ([bpfs, _bpfs]) => bpfs.toString() !== _bpfs.toString(),
            );
            if (is_different_bpfs) return $.alert('같은 면끼리만 변경 할 수 있습니다.');
            $.don_loader_start();
            if (bool) {
              await axios.post('/@api/composite/change_content_in_composite_template', {
                composite_template_id_for_change: parseInt(composite_template_id_for_change),
                original_composite_template_id: parseInt(original_composite_template.id),
              });
              $.alert('변경됐습니다.');
            }

            const base_product_id = getFrameTag(e.currentTarget);
            await initializeBoxData(base_product_id);
            renderList();
          } catch (e) {
            console.error(sel('response.data')(e) || e);
          }
          $.don_loader_end();
        }),
        $delegate('click', '.enter_wrapper', async function (e) {
          go(
            undefined,
            function () {
              if (
                go(
                  e.currentTarget,
                  $closest('.composite_template'),
                  $attr('data-is_published'),
                  (bool) => bool === 'true',
                )
              ) {
                return go(
                  $.confirm(
                    '이미 출시된 합성템플릿이여서 수정이 위험할 수 있습니다. 개발팀과 상의해 주세요.',
                  ),
                  (bool) => {
                    if (!bool) {
                      throw new Error();
                    }
                  },
                );
              }
            },
            async function () {
              const composite_template = getBoxData(e.currentTarget, '.composite_template');
              const base_product_id = getFrameTag(e.currentTarget);
              await openCompositeTemplateMakerFrame(composite_template.id, base_product_id);
              await initializeBoxData(base_product_id);
              renderList();
            },
          );
        }),
        $delegate('click', '.add', async function (e) {
          const base_product_id = getFrameTag(e.currentTarget);
          await openCompositeTemplateMakerFrame(null, base_product_id);
          await initializeBoxData(base_product_id);
          renderList();
        }),
        $delegate('click', '.delete', function (e) {
          go($.confirm('삭제 하시겠습니까?'), async (bool) => {
            if (!bool) return;
            const composite_template = getBoxData(e.currentTarget, '.composite_template');
            composite_template.is_hidden = true;
            await updateCompositeTemplate(pick(['is_hidden', 'id'], composite_template));
            const base_product_id = getFrameTag(e.currentTarget);
            await initializeBoxData(base_product_id);
            renderList();
          });
        }),
        $delegate('change', 'select[name="is_public"]', async function (e) {
          const composite_template = getBoxData(e.currentTarget, '.composite_template');
          composite_template.is_public = $val(e.currentTarget) === 'true';
          await updateCompositeTemplate(pick(['is_public', 'id'], composite_template));
          const base_product_id = getFrameTag(e.currentTarget);
          await initializeBoxData(base_product_id);
          renderList();
        }),
      ),
    },
  ],
});

export function openCompositeTemplatesListFrame(base_product_id) {
  $.frame.open(
    {
      frame_name: 'composite_templates_list',
      frame_tag: base_product_id,
    },
    {
      page_name: 'composite_templates_list_page',
      tabs: [
        {
          tab_name: 'composite_templates_list_tab',
        },
      ],
    },
  );
}
