import { filter, find, findWhere, flat, go, head, last, pluck, reduce, sel, strMap } from 'fxjs/es';
import { CateListConstantS } from '../../../CateList/S/Constant/module/CateListConstantS.js';
import { PriceS } from '../../../Price/S/Function/module/PriceS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { getResizedUrl } from '../../../Util/Image/S/Function/getResized.js';

const productHref = function (query, bp) {
  const basic = `bp_id=${bp.id}${query.lang_col ? `&lang_col=${query.lang_col}` : ''}`;
  if (!sel('length', query.f_color)) return basic;
  const bpc_id = go(
    bp._.base_product_colors,
    find((bpc) => (query.f_color || []).indexOf(bpc.color_filter_id) > -1),
    sel('id'),
  );
  return bpc_id ? `bp_id=${bp.id}&bpc_id=${bpc_id}` : basic;
};

const productImgSrc = function (query, bp) {
  /* TODO 카테고리 하드코딩 */
  const is_model_thumb =
    bp.cate_item_id == CateListConstantS.CUSHION_ITEM_ID ||
    bp.cate_list_id == CateListConstantS.PHONE_ACC_LIST_ID;
  if (sel('length', query.f_color))
    return (
      go(
        bp._.base_product_colors,
        find((bpc) => (query.f_color || []).indexOf(bpc.color_filter_id) > -1),
        sel('product_thumb_url'),
      ) || sel(query.cut_img == 'product' ? 'product_thumb_url' : 'model_thumb_url', bp)
    );
  if (G.collabo_type == 'line' && !is_model_thumb)
    return sel('product_thumb_url', find(sel('is_thumb'), bp._.base_product_colors));
  return sel('model_thumb_url', bp);
};

const makeCateListHtml = (cate_list_id, cate_item_id, cate_list) => legacyHtml`
  <li class="cate_list" _sel="./(#${cate_list.id})">
    <h3
      class="cate_selector"
      selected="${!cate_item_id && cate_list_id == cate_list.id}"
      cate_list_id="${cate_list.id}"
    >
      ${cate_list.name}
    </h3>
    <ul class="cate_items" _sel="./_->cate_items">
      ${strMap(
        (cate_item) => legacyHtml`
          <li
            class="cate_item cate_selector"
            _sel="./(#${cate_item.id})"
            selected="${cate_item.id == cate_item_id}"
            cate_list_id="${cate_list.id}"
            cate_item_id="${cate_item.id}"
          >
            <a href="/${T.lang}/product/list/${cate_list.id}?cate_item_id=${cate_item.id}">
              ${cate_item.name}
              <span class="total_count2 pc_v">(${cate_item.total_count})</span>
            </a>
          </li>
        `,
        cate_list._.cate_items,
      )}
    </ul>
  </li>
`;

const makeProductListBodyHtml = (bp_info, query, cate_lists, cate_list_id) => legacyHtml`
  <div id="product_list_body" total_count="${bp_info.count}">
    <div class="product_list_wrap">
      <div class="product_list">
        <div class="product_list_header">
          ${
            cate_list_id
              ? legacyHtml`
                <div class="count_sort">
                  ${go(
                    cate_lists,
                    findWhere({ id: cate_list_id }),
                    (cate_list) =>
                      query.cate_item_id
                        ? findWhere({ id: parseInt(query.cate_item_id) }, sel('_.cate_items', cate_list))
                        : cate_list,
                    (cate_item) => legacyHtml`
                      <div id="selected_cate_list" class="mobile_v ff_ccl_h">
                        ${cate_item.name}<span></span>
                      </div>
                    `,
                  )}
                  <button class="filter mobile_v" type="button">
                    ${T('Filters')}
                    <span class="count"
                      >(${reduce((mem, { count }) => mem + count, 0, bp_info.filter_lists)})</span
                    >
                  </button>
                  <div class="ordering_cut_img">
                    <div
                      id="ordering"
                      _sel="./bp_info->sort_by"
                      el_id="ordering_select"
                      current_value="${query.ordering}"
                      name_key="name"
                      value_key="id"
                    >
                      <div class="selected">
                        ${sel('name', findWhere({ id: query.ordering }, bp_info.sort_by))}
                      </div>
                    </div>
                  </div>
                  <div class="cate_list_wrap mobile_v">
                    <ul class="cate_lists" _sel="cate_lists">
                      ${strMap(
                        (cate_list) =>
                          makeCateListHtml(cate_list_id, parseInt(query.cate_item_id), cate_list),
                        cate_lists,
                      )}
                    </ul>
                  </div>
                </div>
              `
              : ''
          }
          ${makeSelectedFilterItemsHtml(bp_info)}
        </div>
        <div id="not_found_results">${T('No products matching the conditions.')}</div>
        <div class="base_product_list" _sel="./bp_info->bps">
          ${strMap((bp) => makeBaseProductItemHtml(query, bp), bp_info.bps)}
        </div>
      </div>
    </div>
  </div>
`;

export function makeProductListSizesHtml(sizes) {
  return sizes.length < 3
    ? pluck('short_name', sizes).join(', ')
    : sel('short_name', head(sizes)) + ' ~ ' + sel('short_name', last(sizes));
}

export const makeBaseProductItemHtml = function (query, bp) {
  bp.discount = parseFloat(bp.original_price) > parseFloat(bp.price);
  if (bp.discount) bp._.base_product_tags.unshift({ name: 'SALE' });
  return legacyHtml`
  <div class="base_product" _sel="./(#${bp.id})">
    <a href=/${T.lang}/product/detail?${productHref(query, bp)}>
      <div class="bp_inner">
        <div class="img"><img alt="" data-src="${getResizedUrl({
          url: productImgSrc(query, bp),
          quality: 90,
          width: 624,
        })}" class="don_lazy"></div>
        <div class="hover_design">
          <div class="txt">Start creating
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
      </div>
      <div class="spec" is-color-for-list="${bp.is_color_for_list}" is-size-for-list="${bp.is_size_for_list}">
        <div class="colors" is_pc_over="${bp._.base_product_colors.length > 19}" is_mobile_over="${
    bp._.base_product_colors.length > 13
  }">
          ${strMap(
            (bpc) => legacyHtml`
              <div
                class="bp_color"
                color_code="${bpc.color_code}"
                style="background-color:${bpc.color_code};"
              ></div>
            `,
            bp._.base_product_colors,
          )}
        </div>
        <div class="sizes">${makeProductListSizesHtml(bp._.base_product_sizes)}</div>
        <div class="name">${bp.name || '상품이름을(또는 라인프렌즈)설정해 주세요.'}</div>
        <div class="tags">
          <ul>
            ${strMap(
              (bpt) => legacyHtml` <li class="tag" type="${bpt.name}">${bpt.name}</li> `,
              bp._.base_product_tags,
            )}
          </ul>
        </div>
        <div class="price_info" discount="${bp.discount}">
          <div class="price o_price">${PriceS.pricify(PriceS.add(bp.original_price, bp.print_price))}
            <span class="line"></span>
          </div>
          <div class="price d_price mp_currency">${PriceS.pricify(PriceS.add(bp.price, bp.print_price))}</div>
          <span class="line2">~</span>
        </div>
      </div>
    </a>
  </div>`;
};

export const makeSelectedFilterItemsHtml = function ({ filter_lists }) {
  const selected_filter_items = filter(sel('selected'), flat(_p.deep_pluck(filter_lists, '_.filter_items')));
  return legacyHtml`
    <div class="selected_filter_items" selected="${!!selected_filter_items.length}">
      <div class="unselected">${T('Please select the filter you want.')}</div>
      <div class="selected">
        <div class="clear">${T('Clear')}</div>
        <ul>
          ${strMap(
            (sf) => legacyHtml`
              <li
                class="selected_filter_item filter_item"
                _sel="product_list->bp_info->filter_lists->(fl=>fl.id=='${sf.filter_list_id}')->_->filter_items->(#${sf.id})"
              >
                ${sf.name}
                <span class="close"></span>
                <label>
                  <input
                    type="checkbox"
                    filter_item_id="${sf.id}"
                    filter_type="f_${sf.filter_list_id}"
                    checked
                  />
                </label>
              </li>
            `,
            selected_filter_items,
          )}
        </ul>
      </div>
    </div>
  `;
};

export const makeProductFilterListHtml = (filter_lists) => legacyHtml`
  <div class="product_filter_list" _sel="product_list->bp_info->filter_lists">
    <ul>
      ${strMap(
        (fl) => legacyHtml`
          <li
            class="filter_list"
            filter_list_id="${fl.id}"
            selected="${!!fl.count}"
            _sel="./(fl=>fl.id=='${fl.id}')"
          >
            <div class="filter_list_name" count="${fl.count}">
              ${fl.name}
              <span class="count_wrap">
                <span>(</span>
                <span class="count">${fl.count}</span>
                <span>)</span>
              </span>
              <span class="plus"></span>
            </div>
            ${makeFilterItemsHtml(fl._.filter_items)}
          </li>
        `,
        filter_lists,
      )}
    </ul>
  </div>
`;

export const makeFilterItemsHtml = (filter_items) => legacyHtml`
  <div class="filter_items" _sel="./_->filter_items">
    <ul>
      ${strMap(
        (fi) => legacyHtml`
          <li class="filter_item" selected="${fi.selected}" _sel="./(#${fi.id})" filter_item_id="${fi.id}">
            <label>
              <input
                type="checkbox"
                filter_item_id="${fi.id}"
                filter_type="f_${fi.filter_list_id}"
                ${fi.selected ? 'checked' : ''}
              />
              ${
                fi.filter_list_id == 'color'
                  ? legacyHtml`
                    <span
                      class="checkbox"
                      color_name="${fi.name2}"
                      style="background-color: ${fi.name2};"
                    ></span>
                  `
                  : legacyHtml` <span class="checkbox"></span> `
              }
              <span class="filter_item_name">${fi.name}</span>
            </label>
          </li>
        `,
        filter_items,
      )}
    </ul>
  </div>
`;

export const makeProductListHtml = (bp_info, cate_list_id, query, cate_lists) => legacyHtml`
  <div id="product_list" _sel="product_list">
    <div class="header pc_v">
      <div id="side_bar" class="filter_lists_wrap">
        <div id="side_bar_inner">
          <div id="cate_list_selector">
            <div class="cate_list_wrap">
              <ul class="cate_lists pc_v" _sel="cate_lists">
                ${
                  !cate_list_id
                    ? ''
                    : makeCateListHtml(
                        cate_list_id,
                        parseInt(query.cate_item_id),
                        findWhere({ id: cate_list_id }, cate_lists),
                      )
                }
              </ul>
            </div>
          </div>
          <div class="filter_selector_wrap pc_v">
            <div class="filter_title">
              <h4>${T('Filter')}</h4>
              <a href="#" class="clear">${T('Clear')}</a>
            </div>
            <div class="filter_selector">${makeProductFilterListHtml(bp_info.filter_lists)}</div>
          </div>
        </div>
      </div>
    </div>
    ${makeProductListBodyHtml(bp_info, query, cate_lists, cate_list_id)}
  </div>
`;
