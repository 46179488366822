import { tap } from 'fxjs/es';
import { ungroupAllWithObjAttrs } from '../../../../../Maker/F/util.js';

export const makePrintableCanvas = ({
  product_face_designs,
  px_per_1cm,
  dpi,
  plus_ratio = 1,
  is_sharp = true,
}) => {
  const canvas_ratio =
    (((G.mp.maker.CANVAS_WIDTH_ORIGIN / px_per_1cm / 2.54) * dpi) / G.mp.maker.CANVAS_WIDTH_ORIGIN) *
    plus_ratio;
  const width = G.mp.maker.CANVAS_WIDTH_ORIGIN * canvas_ratio;
  const canvas = new fabric.Canvas(document.createElement('canvas'), {
    width,
    height: width,
    enableRetinaScaling: false,
  });
  G.mp.maker.sharp_resize_print = false;
  return _p.go(
    product_face_designs,
    _p.reject((design) => design.visible === false),
    (product_face_designs) => ungroupAllWithObjAttrs(canvas, product_face_designs),
    tap(() => {
      if (is_sharp) G.mp.maker.sharp_resize_print = true;
    }),
    _p.map((design) => G.mp.maker.from_cv_attrs_for_maker(design, canvas_ratio)),
    _p.each(function (obj) {
      obj.padding = 1;
    }),
    function (objs) {
      return G.mp.maker.create_cv_group(objs);
    },
    function (cv_group) {
      canvas.add(cv_group);
      canvas.renderAll();
      G.mp.maker.sharp_resize_print = false;
      return [canvas, cv_group];
    },
  );
};
