import pixelBinarizeFragmentShaderSrc from '../../shaders/fragment/pixelBinarize.fragment.glsl';
import { renderWebgl, setUniforms } from '../helpers/shaders.js';
import { loadBaseWebGlImageCanvas } from '../helpers/webglCanvas.js';

/*
 * @description 픽셀을 alpha 값에 따라 이분화 시키는 webgl 함수
 * @param {Image} img - 이미지 객체
 * @param {number} threshold - alpha 기준 값 (0.0 ~ 1.0)
 * @param {[number, number, number, number]} color - 색상 RGBA vector (threshold 를 넘어선 값에 적용되는 color)
 * @returns {string} - 출력 이미지의 dataURL
 * */
export async function pixelBinarize({ image, threshold, color, is_alpha_binarize = false }) {
  color = color ?? [0.0, 0.0, 0.0]; // default color is black
  const { gl, webglProgram } = loadBaseWebGlImageCanvas({
    image,
    fragmentShaderSrc: pixelBinarizeFragmentShaderSrc,
  });

  const isFlip = true;
  setUniforms({
    gl,
    webglProgram,
    uniforms: [
      { name: 'u_flipY', type: '1f', values: [isFlip ? -1.0 : 1.0] },
      { name: 'u_alpha_threshold', type: '1f', values: [threshold] },
      { name: 'u_is_alpha_binarize', type: '1f', values: [is_alpha_binarize ? 1.0 : 0.0] },
      {
        name: 'u_resolution',
        type: '2f',
        values: [gl.canvas.width, gl.canvas.height],
      },
      { name: 'u_color', type: '3f', values: color },
    ],
  });
  renderWebgl({ gl, points: 6 });

  return gl.canvas.toDataURL('image/png', 1);
}
