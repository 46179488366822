import {
  createCanvasElement,
  destinationInCanvas,
  evaluateColorBrightness,
  hexToRGB,
  rgbToHex,
  sourceOverCanvas,
} from '../../Canvas/S/util.js';
import { curry, each, go, mapObject, range, reverse } from 'fxjs/es';

function renderTexture(dest_c, hex, material_c) {
  const ctx = dest_c.getContext('2d');
  if (hex) {
    ctx.fillStyle = hex;
    ctx.fillRect(0, 0, dest_c.width, dest_c.height);
  } else {
    ctx.drawImage(material_c, 0, 0, material_c.width, material_c.height, 0, 0, dest_c.width, dest_c.height);
  }
  return dest_c;
}

function getSize(src_c) {
  return { width: src_c.width, height: src_c.height };
}

const makeEdgeCanvasInner = curry(function ({ x, y, w, h }, src_c) {
  const { width, height } = getSize(src_c);
  const canvas = createCanvasElement({ width, height });
  const ctx = canvas.getContext('2d');
  ctx.drawImage(src_c, 0, 0, width, height);
  ctx.globalCompositeOperation = 'destination-out';
  ctx.drawImage(src_c, 0, 0, width, height, x || 0, y || 0, width + (w || 0), height + (h || 0));
  return canvas;
});

export function makeCanvasTextured(src_c, hex, material_c) {
  const { width, height } = src_c;
  return go(
    createCanvasElement({ width, height }),
    (c) => renderTexture(c, hex, material_c),
    (c) => destinationInCanvas(c, src_c),
  );
}
G.__makeCanvasTextured = makeCanvasTextured;

function getOutlineOption({ shade, cylinder }, ratio, is_black) {
  const { light_location } = shade;
  return go(
    undefined,
    () => {
      const { top, left } = light_location;
      if (top === -1 && left === 0) {
        if (cylinder) {
          let x = 1;
          let w = x * 2;
          x *= ratio;
          w *= ratio;
          return { x, w: -w, h: -x, y: 0 };
        } else {
          return { x: 0, w: 0, h: 0, y: -ratio };
        }
      } else if (top === 1 && left === 0) {
        if (cylinder) {
          let x = 1;
          let w = x * 2;
          x *= ratio;
          w *= ratio;
          return { x: -x, w, h: -x, y: x };
        } else {
          return { x: 0, w: 0, h: 0, y: ratio };
        }
      } else if (top === -1 && left === -1) {
        let x = 1;
        x *= ratio;
        return { x: -x, y: -x };
      } else if (top === -1 && left === 1) {
        let x = 1;
        x *= ratio;
        return { x, y: -x };
      } else if (top === 1 && left === -1) {
        let x = 1;
        x *= ratio;
        return { x: -x, y: x };
      } else if (top === 1 && left === 1) {
        let x = 1;
        x *= ratio;
        return { x, y: x };
      }
    },
    (obj) => {
      if (is_black) return mapObject((v) => -v, obj);
      return obj;
    },
  );
}

function makeOutlineC(src_c, hex_code, is_black, { shade, cylinder }) {
  function _func(src_c, hex, outline_option) {
    return go(makeCanvasTextured(src_c, hex), (c) => makeEdgeCanvasInner(outline_option)(c));
  }
  const c = createCanvasElement(src_c);
  const step = shade.deep_step;
  const str = shade.deep_step > 1 ? (is_black ? -15 : 11) : 0;
  const brightness = hexToRGB(hex_code).r + (is_black ? -str * step : 0);
  let hex = rgbToHex(brightness, brightness, brightness);
  go(
    range(1, 1 + step),
    reverse,
    each((i) => {
      const brightness = hexToRGB(hex).r + (is_black ? str : -str);
      hex = rgbToHex(brightness, brightness, brightness);
      sourceOverCanvas(c, _func(src_c, hex, getOutlineOption({ cylinder, shade }, i, is_black)), true);
    }),
  );
  return c;
}

export async function makeShadowAndGeometryCanvas(src_c, { shade, cylinder }, color_code) {
  const { width, height } = src_c;
  const red_box = go(createCanvasElement({ width, height }));
  const ctx_red = red_box.getContext('2d');

  const white_outline = makeOutlineC(src_c, shade.lighten_hex, null, { shade, cylinder });
  ctx_red.drawImage(white_outline, 0, 0);
  const black_outline = makeOutlineC(
    src_c,
    color_code && evaluateColorBrightness(color_code) < 0.2 ? shade.darken_hex : rgbToHex(80, 80, 80),
    true,
    { shade, cylinder },
  );
  ctx_red.drawImage(black_outline, 0, 0);
  return red_box;
}
