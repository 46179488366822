import { composite_template_bg_step_page } from './composite_template_bg_step_page.js';
import { $delegate } from 'fxdom/es';
import { initializeBoxDataAssocCompositeTemplate } from './util.js';
import axios from 'axios';
import { go, map, some, split } from 'fxjs/es';

$.frame.defn_frame({
  frame_name: 'composite_template_maker',
  is_modal: true,
  always_remove: true,
  prev_frame_show: G.is_mobile_size(),
  appended: $delegate('click', '.prev', function (e) {
    $.frame.back_page();
  }),
});
export async function openCompositeTemplateMakerFrame(composite_template_id, base_product_id) {
  if (!composite_template_id) {
    const base_product_ids = go(
      prompt(
        '합성 이미지에 보이는 제품 id를 모두 입력해 주세요.\n(예시1)같은 제품 2개를 합성할 때: 2751 2751\n(예시2)다른 제품 2개를 합성할 때: 2753 2775',
        base_product_id,
      ),
      split(' '),
      map(parseInt),
    );
    if (some(Number.isNaN, base_product_ids)) {
      return;
    }
    try {
      const { data: composite_template } = await axios.post('/@api/composite/insert_composite_template', {
        base_product_ids,
      });
      composite_template_id = composite_template.id;
    } catch (e) {
      $.alert('문제가 발생했습니다.');
      return;
    }
  }
  return new Promise(function (resolve) {
    $.frame.open(
      {
        frame_name: 'composite_template_maker',
        frame_tag: base_product_id,
        closed: function () {
          resolve();
        },
      },
      {
        page_name: composite_template_bg_step_page,
        tabs: [
          {
            tab_name: 'composite_template_bg_step_tab',
            data_func: async () => {
              // if (!composite_template_id) {
              //   const base_product_ids = go(
              //     prompt('합성할 제품의 id를 모두 입력해 주세요'),
              //     split(' '),
              //     map(parseInt)
              //   );
              //   if(some(Number.isNaN, base_product_ids)) {
              //     return
              //   }
              //   const { data: composite_template } = await axios.post(
              //     '/@api/composite/insert_composite_template',
              //     {
              //       base_product_ids
              //     }
              //   );
              //   await initializeBoxDataAssocCompositeTemplate(composite_template.id);
              // } else {
              // }
              await initializeBoxDataAssocCompositeTemplate(composite_template_id);
              return box.sel('assoc_composite_template');
            },
          },
        ],
      },
    );
  });
}
