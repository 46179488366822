import { $addClass, $find, $hasClass, $hide, $qs, $removeClass, $setHTML, $show } from 'fxdom/es';
import { go } from 'fxjs/es';
import { OMPCoreUtilF } from '../../../../Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreLayoutNavigationTmplS } from '../../S/Tmpl/module/OMPCoreLayoutNavigationTmplS.js';

export const show = (cate_list_id) => {
  const html_el = $qs('html');
  const header_el = $qs('.omp-header');
  const header_navi_el = $qs('.omp-header__navi');
  const mp_navigations = box.sel('mp_navigations');
  const cate_lists = box.sel('cate_lists');
  const login_user = box.sel('is_user');
  const make_navi_el = OMPCoreLayoutNavigationTmplS.makeNavi({
    cate_list_id,
    is_mobile: false,
    mp_navigations,
    cate_lists,
    login_user,
  });

  if (!make_navi_el) return $hide(header_navi_el);

  if (!$hasClass('body-fixed', document.body)) {
    OMPCoreUtilF.bodyFixed$(true);
  }

  $addClass('omp-gnb-hover', html_el);

  go(header_navi_el, $find('.omp-header__navi-body'), $setHTML(make_navi_el));

  if (!cate_list_id) {
    const prev_active_button = $find('.omp-header__menus [data-active="true"]', header_el);
    if (prev_active_button) {
      prev_active_button.dataset.active = 'false';
    }
    const current_button = $find('.omp-header__menus .omp-atom__header-menu-arrow', header_el);
    if (current_button) {
      current_button.dataset.active = 'true';
    }
  } else {
    const prev_active_button = $find('.omp-header__menus [data-active="true"]', header_el);
    if (prev_active_button) {
      prev_active_button.dataset.active = 'false';
    }
    const current_button = $find(`.omp-atom__header-menu-button[data-id="${cate_list_id}"]`, header_el);
    if (current_button) {
      current_button.dataset.active = 'true';
    }
  }

  $show(header_navi_el);
};

export const hide = () => {
  const html_el = $qs('html');
  const header_menus_el = $qs('.omp-header__menus');
  const header_navi_el = $qs('.omp-header .omp-header__navi');
  $hide(header_navi_el);
  $removeClass('omp-gnb-hover', html_el);

  const prev_active_button = $find('[data-active="true"]', header_menus_el);
  if (prev_active_button) {
    prev_active_button.dataset.active = 'false';
  }
  const current_button = $find(
    `.omp-atom__header-menu-button[data-id="${header_menus_el.dataset.active_id}"]`,
    header_menus_el,
  );
  if (current_button) {
    current_button.dataset.active = 'true';
  }

  OMPCoreUtilF.bodyFixed$(false);
};

export const moAllShow = (cate_list_id) => {
  const header_el = $qs('.omp-header');
  const category_layer_el = $find('.omp-header__all-menus', header_el);
  const mp_navigations = box.sel('mp_navigations');
  const cate_lists = box.sel('cate_lists');
  const is_mobile = OMPCoreUtilF.isMobile();
  const login_user = box.sel('is_user');

  go(
    category_layer_el,
    $setHTML(
      OMPCoreLayoutNavigationTmplS.makeNavi({
        cate_list_id,
        is_mobile,
        mp_navigations,
        cate_lists,
        login_user,
      }),
    ),
  );
  category_layer_el.dataset.show = 'true';
  OMPCoreUtilF.bodyFixed$(true);
};

export const moAllHide = () => {
  const header_el = $qs('.omp-header');
  const category_layer_el = $find('.omp-header__all-menus', header_el);
  category_layer_el.dataset.show = 'false';
  OMPCoreUtilF.bodyFixed$(false);
};

export const moPdListCateShow = () => {
  const header_el = $qs('.omp-header');
  const category_layer_el = $find('.omp-header__product-list-menus', header_el);
  category_layer_el.dataset.show = 'true';
  OMPCoreUtilF.bodyFixed$(true);
};

export const moPdListCateHide = () => {
  const header_el = $qs('.omp-header');
  const category_layer_el = $find('.omp-header__product-list-menus', header_el);
  category_layer_el.dataset.show = 'false';
  OMPCoreUtilF.bodyFixed$(false);
};
