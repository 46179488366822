import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { each, filterL, go, takeL } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { CommonNS } from '@marpple/sticker-editor';

export const handleHeaderCancelButtonClick = () => () => MuiF.popPage().catch(console.error);

export const handleHeaderDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_hook == null) {
      return;
    }
    const hook = tab_el.__mp_hook;

    if (tab_el.__mp_postProcess == null) {
      return;
    }
    const postProcess = tab_el.__mp_postProcess;

    const { x: hook_cx, y: hook_cy } = hook.last_center_position;

    const path_data = hook.getPathDataHookUnited();
    const { success, value } = await postProcess({
      cutting_line_path_data: path_data,
      hook_position: { x: hook_cx, y: hook_cy },
    });

    if (!success && value == null) {
      return;
    }
    if (!success) {
      await $.alert(value);
      return;
    }

    return MuiF.closeFrame(value);
  };

export const handleFooterInsideButtonClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_hook == null) {
      return;
    }
    const hook = tab_el.__mp_hook;

    if (tab_el.__mp_keyring_hook_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_hook_editor;

    if (hook.current_positioning_mode === 'inside') {
      return;
    }

    const { x: last_hook_center_x, y: last_hook_center_y } = hook.last_center_position;
    hook.updateHookPositioningMode('inside');
    const { x, y } = hook.getHookCenter({ x: last_hook_center_x, y: last_hook_center_y });
    editor.setHookPosition({ x, y });
    go(
      tab_el,
      $findAll(`.footer .controls`),
      each((el) => (el.dataset.hook_position = 'inside')),
    );
  };

export const handleFooterOutsideButtonClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_hook == null) {
      return;
    }
    const hook = tab_el.__mp_hook;

    if (tab_el.__mp_keyring_hook_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_hook_editor;

    if (hook.current_positioning_mode === 'outside') {
      return;
    }

    const { x: last_hook_center_x, y: last_hook_center_y } = hook.last_center_position;
    hook.updateHookPositioningMode('outside');
    const { x, y } = hook.getHookCenter({ x: last_hook_center_x, y: last_hook_center_y });
    editor.setHookPosition({ x, y });
    go(
      tab_el,
      $findAll(`.footer .controls`),
      each((el) => (el.dataset.hook_position = 'outside')),
    );
  };

export const handleEditorContainerTouchStart =
  ({ tab_el }) =>
  (event) => {
    if (tab_el.__mp_hook_touch_id != null) {
      return;
    }

    if (tab_el.__mp_keyring_hook_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_hook_editor;

    if (tab_el.__mp_hook == null) {
      return;
    }
    const hook = tab_el.__mp_hook;

    event.preventDefault();

    go(
      event.changedTouches,
      CommonNS.UtilNS.convertTouchListToGen,
      filterL(({ target }) => target instanceof Node && editor.getIsInHookEl(target)),
      takeL(1),
      each(({ identifier, clientX: client_x, clientY: client_y }) => {
        const { x, y } = editor.convertCoordsToInside({ x: client_x, y: client_y });
        const { x: hook_center_x, y: hook_center_y } = hook.getHookCenter({ x, y });

        tab_el.__mp_hook_touch_id = identifier;
        editor.setHookPosition({ x: hook_center_x, y: hook_center_y });
      }),
    );
  };

export const handleTabElTouchMove =
  ({ tab_el }) =>
  (event) => {
    event.preventDefault();

    if (tab_el.__mp_hook_touch_id == null) {
      return;
    }
    const touch_id = tab_el.__mp_hook_touch_id;

    if (tab_el.__mp_keyring_hook_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_hook_editor;

    if (tab_el.__mp_hook == null) {
      return;
    }
    const hook = tab_el.__mp_hook;

    go(
      event.changedTouches,
      CommonNS.UtilNS.convertTouchListToGen,
      filterL(({ identifier }) => identifier === touch_id),
      takeL(1),
      each(({ clientX: client_x, clientY: client_y }) => {
        const { x, y } = editor.convertCoordsToInside({ x: client_x, y: client_y });
        const { x: hook_center_x, y: hook_center_y } = hook.getHookCenter({ x, y });
        editor.setHookPosition({ x: hook_center_x, y: hook_center_y });
      }),
    );
  };

export const handleTabElTouchEnd =
  ({ tab_el }) =>
  (event) =>
    handleTabElTouchEndOrTouchCancel({ tab_el, event });

export const handleTabElTouchCancel =
  ({ tab_el }) =>
  (event) =>
    handleTabElTouchEndOrTouchCancel({ tab_el, event });

function handleTabElTouchEndOrTouchCancel({ tab_el, event }) {
  if (tab_el.__mp_hook_touch_id == null) {
    return;
  }
  const touch_id = tab_el.__mp_hook_touch_id;

  event.preventDefault();

  go(
    event.changedTouches,
    CommonNS.UtilNS.convertTouchListToGen,
    filterL(({ identifier }) => identifier === touch_id),
    takeL(1),
    each(() => {
      tab_el.__mp_hook_touch_id = null;
    }),
  );
}
