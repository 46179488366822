import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { NewMakerFramesMobilePdfUploaderMuiS } from '../../S/Mui/module/NewMakerFramesMobilePdfUploaderMuiS.js';
import { page } from './page.js';
import { go, delay } from 'fxjs/es';
import { $attr, $closest, $data, $delegate } from 'fxdom/es';
import {
  getPdfFileUploadedFromDOM,
  refreshFileUploadHtml,
  switchHeaderConfirmButton,
} from '../Function/domHandler.js';
import { PdfF } from '../../../../../Pdf/F/Function/module/PdfF.js';
import { VectorEditorF } from '../../../../../VectorEditor/F/Function/module/VectorEditorF.js';
import { hasPdfFile, setFrameCustomData } from '../../S/Function/mobilepdfuploader.js';
import { alertProEditorCsError } from '../../../../../VectorEditor/F/Function/pro_editor.error.js';
import { PrintTemplateF } from '../../../../../PrintTemplate/F/Function/module/PrintTemplateF.js';
import { PrintTemplateS } from '../../../../../PrintTemplate/S/Function/module/PrintTemplateS.js';
import { OMPCoreUtilF } from '../../../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { BpOptionUploadTypeS } from '../../../../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { getProductColorInMaker } from '../../../../../Maker/F/getSth.js';
/*
 * 프론트엔드에서 사용될 frame 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#b847a14a76274bff9aaa6d549c5bef88
 * */
export const frame = {
  ...NewMakerFramesMobilePdfUploaderMuiS.frame, // S의 Mui에서 프레임옵션을 가져옵니다.

  root_page: page, // /F/page 옵션객체 - 필수

  height: undefined, // 모바일 전용, frame 높이
  prev_frame_show: undefined, // openFrame시에 바로 하단의 frame을 html에서 삭제안함
  always_remove: true, // closeFrame 때 무조건 삭제
  animation: !MuiF.IS_IOS, // frame을 열거나 닫을때 애니메이션 (기본 - ios면 false 이외 true)
  is_modal: false, // frame 을 모달로 열때

  appending(frame_el$) {}, // frame 엘리먼트가 만들어지면 울리는 함수
  appended(frame_el$) {
    go(
      frame_el$,
      /* 헤더 메인 버튼 (취소, 완료) */
      $delegate('click', '.pdf_uploader__header button', async ({ currentTarget: ct }) => {
        const action = ct.name;
        const pdf_printing_file = getPdfFileUploadedFromDOM();
        const has_pdf_file = hasPdfFile({ pdf_printing_file });

        if (action === 'confirm' && has_pdf_file) {
          MuiF.closeFrame({ pdf_printing_file });
        }

        if (action === 'cancel') {
          MuiF.closeFrame({ pdf_printing_file: null });
        }
      }),
      /* PDF 파일 업로드 버튼 */
      $delegate(
        'click',
        '.pdf_uploader__body .section.file[has_pdf_file="false"]',
        async ({ currentTarget: ct }) => {
          try {
            const file = await PdfF.triggerPdfFileInput();

            if (file) {
              $.don_loader_start();
              const upload_response = await PdfF.uploadPdfFile({ file });
              $.don_loader_end();

              if (upload_response.success) {
                const pdf_printing_file = upload_response.data[0];

                const bp_id = go(ct, $closest('.pdf_uploader__body'), $attr('bp_id'));

                const template = go(ct, $closest('.pdf_uploader__body'), $data);
                if (BpOptionUploadTypeS.UPLOAD_TYPE.option.editor.isReadyBp(getProductColorInMaker())) {
                  const is_valid_pdf_with_template = PrintTemplateS.validatePdfPrintingFile({
                    template,
                    pdf_printing_file,
                  });

                  if (is_valid_pdf_with_template.is_OK === false) {
                    OMPCoreUtilF.showToast({
                      text: TT('pdf::uploader::invalid_template'),
                      position: { vertical: 'bottom', horizontal: 'center' },
                      time: 3000,
                    });
                    return;
                  }
                }

                await refreshFileUploadHtml({ pdf_printing_file, bp_id: parseInt(bp_id) });
                setFrameCustomData({ frame: frame_el$, data: { pdf_printing_file } });
                switchHeaderConfirmButton({ is_disabled: false });
              } else {
                await VectorEditorF.alertProEditorCsError({ title: TT('pro_editor::alert::title::dev') });
              }
            }
          } catch (err) {
            $.don_loader_end();
            await VectorEditorF.alertProEditorCsError({ title: TT('pro_editor::alert::title::dev') });
          }
        },
      ),
      /* PDF 파일 제거 버튼 */
      $delegate(
        'click',
        '.pdf_uploader__body .section.file button[name="remove"]',
        ({ currentTarget: ct }) => {
          go($.confirm(T('삭제 하시겠습니까?')), async (yes) => {
            if (!yes) return;

            const bp_id = go(ct, $closest('.pdf_uploader__body'), $attr('bp_id'));

            await refreshFileUploadHtml({ bp_id: parseInt(bp_id) });
            setFrameCustomData({ frame: frame_el$, data: { pdf_printing_file: null } });
            switchHeaderConfirmButton({ is_disabled: true });
          });
        },
      ),
      /* 지류 인쇄 템플릿 다운로드 */
      $delegate(
        'click',
        '.pdf_uploader__body button[name="template_down"]',
        async ({ currentTarget: ct }) => {
          const bp_id = $attr('bp_id', ct);
          if (bp_id) {
            try {
              $.don_loader_start();
              const base_product = box.sel('maker->product_color->_->base_product');
              const bp_id = base_product.id;
              const printing_template = await PrintTemplateF.getPrintTemplate({
                bp_id,
                lang: T.lang,
              });

              if (printing_template == null) {
                throw new Error(`인쇄 시안 템플릿 등록이 필요합니다. 상품아이디${bp_id}`);
              }

              const { file_name, url } = printing_template;
              const template_resource_url = PrintTemplateS.printingTemplateS3UrlToCloudFrontUrl(url);

              OMPCoreUtilF.downloadFileAppCompatible({
                url: template_resource_url,
                file_name,
              });

              await delay(2000, () => {});
              $.don_loader_end();
            } catch (err) {
              await alertProEditorCsError({ title: TT('pro_editor::alert::title::download') });
              $.don_loader_end();
              console.error(err);
            }
          }
        },
      ),
    );
  }, // frame 엘리먼트가 html에 붙으면 울리는 함수
  showing(frame_el$) {}, // frame 엘리먼트 show하기 전 울리는 함수
  showed(frame_el$) {}, // frame 엘리먼트 show하고 나서 울리는 함수
  /* frame을 닫을때 울리는 함수 */
  closing(frame_el$, val) {}, // frame이 닫히기 전 울리는 함수
  hiding(frame_el$, val) {}, // frame이 가려지기 전 울리는 함수
  hided(frame_el$, val) {}, // frame이 가려진 후 울리는 함수
  removing(frame_el$, val) {}, // frame이 삭제되기 전 울리는 함수
  removed(frame_el$, val) {}, // frame이 삭제된 후 울리는 함수
  closed(frame_el$, val) {}, // frame이 닫힌 후 울리는 함수
};
