import { go, html } from 'fxjs/es';
import { $delegate, $qs } from 'fxdom/es';
import { getCurrentFcanvas, getCvObj } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { cv_type } from '../../S/Constant/cvbackground.js';
import { marpplizerProxy } from '../../../../../Maker/F/Marpplizer/marpplizer.js';
import { NewMakerCvObjectCvBackgroundTmplS } from '../../S/Tmpl/module/NewMakerCvObjectCvBackgroundTmplS.js';
import { colorChangeEvent } from './ColorChangeEvent.js';
import { makeTwoDepthColors } from './util.js';
import { getOrMakeCvBackground } from './GetOrMakeCvBackground.js';

export const openCvBackgroundMarpplizer = async () => {
  G.mp.maker.unselect_all();
  const cv_background = getOrMakeCvBackground();
  await marpplizerProxy(cv_background);
};

export const marpplizerCvBackground = () => {
  const id = 'cv_background';
  const fcanvas = getCurrentFcanvas();
  const cv_background = getCvObj(fcanvas._objects, cv_type);
  const one_line_color_length = $qs('html[marpplizer_mode="short"]') ? 8 : 10;
  const html_body = () => html`
    <div class="main_head">
      <div class="body">${T('maker_background_color_editor::배경 색상')}</div>
      <div class="option">
        <button class="marpplizer_cv_background_back_btn marpplizer_back_btn_styl"></button>
      </div>
    </div>
    <div class="control section">
      <div class="body mp_maker_colors_wrapper_style colors_wrapper">
        ${NewMakerCvObjectCvBackgroundTmplS.colorsWrapper({
          colors: makeTwoDepthColors(one_line_color_length),
          color_code: cv_background.fill,
        })}
      </div>
    </div>
  `;
  const event = (el) => {
    go(
      el,
      $delegate('click', '.marpplizer_cv_background_back_btn', () => marpplizerProxy()),
    );
    colorChangeEvent(el, cv_background);
  };
  return {
    name: 'cv_background',
    app: 'base2',
    html: html`
      <div class="activity" id="${id}">
        <div class="body cv_background marpplizer_contents_wrapper">${html_body()}</div>
      </div>
    `,
    event,
  };
};
