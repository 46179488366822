import { html } from 'fxjs/es';
import { reviewHeaderTmpl } from './reviewHeaderTmpl.js';
import { reviewListTmpl } from './reviewListTmpl.js';
import { OMPCoreLayoutTmplS } from '../../../Core/Layout/S/Tmpl/module/OMPCoreLayoutTmplS.js';

export const reviewTmpl = ({ is_mobile, is_best, reviews, review_total, cate_lists, query, sub_menus }) => {
  return html`
    ${sub_menus ? OMPCoreLayoutTmplS.bodySubMenu(sub_menus) : ''}
    <div class="omp-review">
      ${reviewHeaderTmpl({ is_mobile, cate_lists, query, is_best, review_total })}
      ${reviewListTmpl({
        is_mobile,
        reviews,
        review_total,
        query,
      })}
    </div>
  `;
};
