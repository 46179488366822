import { deepFlat, every, find, go, map, selEq, sum } from 'fxjs/es';

const cmToInch = (cm) => {
  return cm / 2.54;
};

export function getPrintRatio({ print, dpi }) {
  const { cm, px, result_px } = print;
  if (dpi) {
    const real_width_px = cmToInch(cm.width) * dpi;
    return real_width_px / px.width;
  }
  if (result_px?.width) {
    const { px } = print;
    return result_px.width / px.width;
  }
}

function getImageQualityPercent({ cv_obj, ratio, parent_ratio = 1 }) {
  const cv_objs = cv_obj._objects || cv_obj.objects;
  if (cv_objs)
    return map((_cv_obj) =>
      getImageQualityPercent({ cv_obj: _cv_obj, ratio, parent_ratio: cv_obj.scaleX * parent_ratio }),
    )(cv_objs);
  if (cv_obj._data.cv_type !== 'cv_image') return 1;
  return Math.min(1, cv_obj._data.image_width / (cv_obj.scaleX * cv_obj.width * ratio * parent_ratio));
}
export const getImageQualities = ({ product_face, base_product_size_id }) => {
  if (!every([product_face, base_product_size_id]))
    throw Error('require product_faces2, base_product_size_id');
  const { px_per_1cm } = go(
    product_face.size_faces,
    find(selEq('base_product_size_id', base_product_size_id)),
  );
  const dpi = 300;
  const ratio = ((1 / 2.54) * dpi) / px_per_1cm;
  if (!ratio) return;
  return go(
    product_face.designs,
    map((cv_obj) => getImageQualityPercent({ cv_obj, ratio })),
    deepFlat,
    (percents) => {
      return percents.length ? sum(percents) / percents.length : null;
    },
  );
};

export const getMinimumImageInfo = ({ image_width, image_height, width_cm }) => {
  if (!(image_width && image_height && width_cm)) return;
  const good_width_px = (width_cm / 2.54) * 300;
  const minimum_width_px = good_width_px / 2;
  let status = '';
  if (good_width_px <= image_width) {
    status = 'good';
  } else if (good_width_px <= image_width) {
    status = 'normal';
  } else if (good_width_px <= image_width) {
    status = 'bad';
  } else {
    status = 'very bad';
  }
  return {
    status,
    minimum: {
      width: minimum_width_px,
      height: image_height * (minimum_width_px / image_width),
    },
    good: {
      width: good_width_px,
      height: image_height * (good_width_px / image_width),
    },
  };
};
