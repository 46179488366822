import './frame.js';
import './page.js';
import { go } from 'fxjs/es';
import { makeUserDetailMenuHtml } from '../S/tmpl.js';

function removeTitles(type) {
  go(
    $1('.don_page >.don_wrapper >.header'),
    $.prepend(makeUserDetailMenuHtml(type, box.sel('detail_user'))),
    $.find1('.title h1'),
    $.remove,
  );
}

export const defnDfUserDetailRoute = () => {
  if ($1('html.user.projection'))
    return go(
      $.frame({
        frame_name: 'projection.all',
        frame_tag: 'projection',
      }),
      () => removeTitles('projection'),
    );

  if ($1('html.user.old_message'))
    return go(
      $.frame({
        frame_name: 'mp.old_message',
      }),
      () => removeTitles('projection'),
    );
};
