import $dataStr from 'fxdom/es/dataStr.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { disabledCheckboxIcon } from '../../ShippingDetail/S/Tmpl/tmpl.icon.js';
import {
  makeTableInputNumberHtml,
  makeTableInspectionStatusButtonHtml,
  makeTableProductInfoHtml,
  makeTableReturnStatusButtonHtml,
  makeTableStepBadgeHtml,
} from '../../ShippingDetail/S/Tmpl/tmpl.table.js';
import { html } from 'fxjs/es';
import moment from 'moment';

export const LGL_SELLER_MARPPLE_BUSINESS_NO = '105-88-13322';

export const PACKAGING_TABLE_COLUMN = {
  checkbox: {
    title: 'checkbox',
    type: 'checkbox',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '50px',
  },
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '353px',
    template: makeTableProductInfoHtml,
  },
  quantity: {
    title: '담긴 수량',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '90px',
    template: ({ upcs_id, loaded_quantity }) =>
      html`<div class="loaded_quantity" data-upcs-id="${upcs_id}">${loaded_quantity}</div>`,
  },
  move_quantity: {
    title: '이동 수량',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
    template: ({ loaded_quantity }) => makeTableInputNumberHtml({ value: loaded_quantity }),
  },
};

export const REBOUND_PACKAGING_TABLE_COLUMN = {
  checkbox: {
    title: 'checkbox',
    type: 'checkbox',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '50px',
  },
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '498px',
    template: makeTableProductInfoHtml,
  },
  quantity: {
    title: '담긴 수량',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
    template: ({ upcs_id, loaded_quantity }) =>
      html`<div class="loaded_quantity" data-upcs-id="${upcs_id}">${loaded_quantity}</div>`,
  },
  move_quantity: {
    title: '이동 수량',
    type: 'custom',
    show: false,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
    template: ({ loaded_quantity }) => makeTableInputNumberHtml({ value: loaded_quantity }),
  },
};

export const PACKAGING_QUEUE_TABLE_COLUMN = {
  checkbox: {
    title: 'checkbox',
    type: 'checkbox',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '50px',
  },
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '353px',
    template: makeTableProductInfoHtml,
  },
  left_quantity: {
    title: '남은 수량',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '90px',
    template: ({ upcs_id, left_quantity }) =>
      html`<div class="left_quantity" data-upcs-id="${upcs_id}">${left_quantity}</div>`,
  },
  move_quantity: {
    title: '이동 수량',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
    template: ({ left_quantity: value }) => makeTableInputNumberHtml({ value }),
  },
};

export const PRODUCT_LIST_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '900px',
    template: makeTableProductInfoHtml,
  },
  quantity: {
    title: '총 수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '400px',
  },
};

export const PACKED_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '992px',
    template: makeTableProductInfoHtml,
  },
  loaded_quantity: {
    title: '수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
  },
};

export const REBOUND_QUEUE_TABLE_COLUMN = {
  checkbox: {
    title: 'checkbox',
    type: 'checkbox',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '50px',
  },
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '480px',
    template: makeTableProductInfoHtml,
  },
  left_quantity: {
    title: '남은 수량',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '100px',
    template: ({ upcs_id, left_quantity }) =>
      html`<div class="left_quantity" data-upcs-id="${upcs_id}">${left_quantity}</div>`,
  },
  move_quantity: {
    title: '이동 수량',
    type: 'custom',
    show: false,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
    template: ({ left_quantity: value }) => makeTableInputNumberHtml({ value }),
  },
};

export const REQUESTED_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '684px',
    template: makeTableProductInfoHtml,
  },
  loaded_quantity: {
    title: '수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
  },
  status: {
    title: '입고 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
    template: () => `요청 완료`,
  },
};

export const SHIPOUT_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '684px',
    template: makeTableProductInfoHtml,
  },
  loaded_quantity: {
    title: '수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
  },
  status: {
    title: '입고 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
    template: () => `마플 집하`,
  },
};

export const ARRIVED_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '684px',
    template: makeTableProductInfoHtml,
  },
  loaded_quantity: {
    title: '수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
  },
  status: {
    title: '입고 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
    template: () => `LGL 도착`,
  },
};

export const RECEIVED_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '684px',
    template: makeTableProductInfoHtml,
  },
  loaded_quantity: {
    title: '수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
  },
  status: {
    title: '입고 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
    template: () => `입고 완료`,
  },
};

export const INSPECT_TABLE_COLUMN = {
  checkbox: {
    title: 'checkbox',
    type: 'checkbox',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '80px',
  },
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '794px',
    template: makeTableProductInfoHtml,
  },
  loaded_quantity: {
    title: '수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
  },
  status: {
    title: '입고 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: () => `입고 완료`,
  },
  inspection_status: {
    title: '검수 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: ({ lgl_item_inbound_inspections, lgl_item_id }) => {
      const hasInspection = !UtilS.isEmpty(lgl_item_inbound_inspections);
      if (hasInspection) {
        const { is_ok } = lgl_item_inbound_inspections;
        return `<button type="button" class="inspection_detail line ${
          is_ok ? '' : 'danger'
        }" data-lgl-item-id="${lgl_item_id}">검수 ${is_ok ? '정상' : '불량'}</button>`;
      } else {
        return `<button type="button" class="inspection_detail disabled">검수 예정</button>`;
      }
    },
  },
};

export const INSPECT_AND_RETURN_TABLE_COLUMN = {
  checkbox: {
    title: `<div class="checkbox_wrapper"><label class="checkbox_label"><input type="checkbox"/></label></div>`,
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '90px',
    template: ({ lgl_return_orders }) => {
      if (!UtilS.isEmpty(lgl_return_orders)) {
        return `<div class="checkbox_wrapper">${disabledCheckboxIcon()}</div>`;
      } else {
        return `<div class="checkbox_wrapper"><label class="checkbox_label"><input type="checkbox"/></label></div>`;
      }
    },
  },
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '626px',
    template: makeTableProductInfoHtml,
  },
  loaded_quantity: {
    title: '수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
  },
  status: {
    title: '입고 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: () => `입고 완료`,
  },
  inspection_status: {
    title: '검수 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: ({ lgl_item_inbound_inspections, lgl_item_id }) => {
      const hasInspection = !UtilS.isEmpty(lgl_item_inbound_inspections);
      if (hasInspection) {
        const { is_ok } = lgl_item_inbound_inspections;
        return `<button type="button" class="inspection_detail line ${
          is_ok ? '' : 'danger'
        }" data-lgl-item-id="${lgl_item_id}">검수 ${is_ok ? '정상' : '불량'}</button>`;
      } else {
        return `<button type="button" class="inspection_detail disabled">검수 예정</button>`;
      }
    },
  },
  return_status: {
    title: '회수 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: (row) => {
      const { lgl_return_orders, lgl_outbound_order_id } = row;
      // 입고 회수 주문만 조회
      if (!UtilS.isEmpty(lgl_return_orders) && !lgl_outbound_order_id) {
        return makeTableReturnStatusButtonHtml(lgl_return_orders);
      } else {
        return ``;
      }
    },
  },
};

export const RETURN_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '850px',
    template: makeTableProductInfoHtml,
  },
  loaded_quantity: {
    title: '수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
  },
  inspect_status: {
    title: '검수 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: makeTableInspectionStatusButtonHtml,
  },
  return_status: {
    title: '회수 상태',
    type: 'merged',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: makeTableReturnStatusButtonHtml,
  },
};

export const RETURN_HALF_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '380px',
    template: makeTableProductInfoHtml,
  },
  loaded_quantity: {
    title: '수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '80px',
  },
  inspect_status: {
    title: '검수 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '100px',
    template: makeTableInspectionStatusButtonHtml,
  },
  return_status: {
    title: '회수 상태',
    type: 'merged',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '100px',
    template: makeTableReturnStatusButtonHtml,
  },
};

export const OUTBOUND_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '684px',
    template: makeTableProductInfoHtml,
  },
  requested_quantity: {
    title: '총 수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
  },
  status: {
    title: '출고 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
    template: () => `주문 생성`,
  },
};

export const OUTBOUND_REQUESTED_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '900px',
    template: makeTableProductInfoHtml,
  },
  requested_quantity: {
    title: '총 수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
  },
  status: {
    title: '출고 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: () => `요청 완료`,
  },
  pack_status: {
    title: '합포장 처리',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: () => `합포장 진행전`,
  },
};

export const OUTBOUND_PACKING_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '900px',
    template: makeTableProductInfoHtml,
  },
  requested_quantity: {
    title: '총 수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
  },
  status: {
    title: '출고 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: () => `요청 완료`,
  },
  pack_status: {
    title: '합포장 처리',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: () => `합포장 진행중`,
  },
};

export const OUTBOUND_SHIPOUT_TABLE_COLUMN = {
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '760px',
    template: makeTableProductInfoHtml,
  },
  requested_quantity: {
    title: '요청 수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
  },
  confirmed_quantity: {
    title: '확정 수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
  },
  status: {
    title: '출고 상태',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: () => `요청 완료`,
  },
  pack_status: {
    title: '합포장 처리',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '160px',
    template: (row) => {
      if (row?.lgl_item_outbound_inspections) {
        const reject = !row.lgl_item_outbound_inspections.is_ok;
        return `<button type="button" class="outbound_detail line" data-fx-json="${$dataStr(
          row.lgl_item_outbound_inspections,
        )}">${reject ? '보류 사유' : '합포장 완료'}</button>`;
      }
      return `합포장 완료`;
    },
  },
};

export const TABS = [
  {
    id: 'PACKAGING',
    title: '상품 포장',
    tab_name: 'packaging',
    show_status: [],
    disabled_status: [],
  },
  {
    id: 'REBOUND',
    title: '재입고 포장',
    tab_name: 'rebound',
    show_status: [],
    disabled_status: [],
  },
  {
    id: 'INBOUND',
    title: '입고 / 검수',
    tab_name: 'inbound',
    show_status: [],
    disabled_status: [],
  },
  {
    id: 'RETURN',
    title: '회수 상태',
    tab_name: 'return',
    show_status: [],
    disabled_status: [],
  },
  {
    id: 'OUTBOUND',
    title: '출고 확인',
    tab_name: 'outbound',
    show_status: [],
    disabled_status: [],
  },
  {
    id: 'TRACKING',
    title: '현황 / 배송 조회',
    tab_name: 'tracking',
    show_status: [],
    disabled_status: [],
  },
];

export const INBOUND_STATUSES = {
  BEFORE_PRINT: -1,
  BEFORE_DELIVER: 0,
  CREATED: 1,
  CANCELED: 1,
  PACKED: 1,
  REQUESTED: 2,
  SHIPOUT: 2,
  ARRIVED: 3,
  RECEIVED: 3,
  INSPECTING: 4,
  INSPECTED: 4,
};
export const OUTBOUND_STATUSES = {
  CREATED: 5,
  PACKING: 6,
  PACKED: 6,
  SHIPOUT_FROM_LGL: 7,
  CUSTOMS_DECLARED: 7,
  CUSTOMS_CLEARED: 7,
  SHIPOUT_FROM_EXPRESS: 7,
  CARGO_LOADED: 7,
  AIRPLANE_DEPARTED: 7,
  LOCAL_ARRIVED: 7,
  LOCAL_CUSTOMS_CLEARING: 7,
  LOCAL_DELIVERING: 7,
  DELIVERED: 8,
};
export const STATUSES = [
  '상품 제작 완료',
  '상품 포장',
  'LGL 입고 요청',
  'LGL 입고 완료',
  'LGL 검수 시작',
  '출고 주문 생성',
  '합포장 처리',
  'LGL 센터 출고',
  '배송 완료',
];

export const MESSAGES = {
  PACKAGING: {
    EMPTY: '상품이 아직 제작중이에요.',
    BEFORE_PRINT: '모든 상품이 제작 완료된 이후부터 해외 운송장을 발급할 수 있어요.',
    BEFORE_DELIVER: '<strong>운송장을 발급</strong>하면 운송장에 상품을 담을 수 있어요.',
    EMPTY_WAYBILL: '[->] 이동 버튼을 클릭하여 오른쪽 운송장으로 담을 수 있어요.',
    NON_EMPTY_WAYBILL: '[->] 이동 버튼을 클릭하여 오른쪽 운송장으로 담을 수 있어요.',
    FULL_WAYBILL: '모든 상품이 운송장에 잘 담겼다면, 포장 완료 버튼을 클릭해 주세요!',
    PACKED: '주문 상품이 포장 완료되었어요. 이제 입고 요청할 수 있어요!',
    NO_DATA_IN_LIST: '모든 상품이 운송장에 담겼어요!',
    NO_DAT_IN_WAYBILL: '운송장에 상품을 담아주세요!',
  },
  REBOUND: {
    EMPTY: '재입고 상품이 없어요.',
    NO_REBOUND_DATA: '재제작 상품이 없거나 제작 중일 수 있어요.',
    EMPTY_WAYBILL: '[->] 이동 버튼을 클릭하여 오른쪽 운송장으로 담을 수 있어요.',
    NON_EMPTY_WAYBILL: '[->] 이동 버튼을 클릭하여 오른쪽 운송장으로 담을 수 있어요.',
    PACKED: '재입고 요청 전, 운송장에 담긴 상품 리스트를 한번 더 확인해 주세요.',
    REQUESTED: '<strong>LGL 센터로 입고 요청</strong>이 완료되었어요.',
    SHIPOUT: '입고 요청된 상품이 <strong>마플에서 집하 처리</strong>되었어요.',
    ARRIVED: '입고 요청한 상품이 <strong>LGL 센터에 도착</strong>했어요!',
    RECEIVED: '입고 요청한 상품이 <strong>LGL 센터로 입고 완료</strong>되었어요.',
    INSPECTING: '회수가 필요한 상품이 있다면, <strong>검수 상태에 상관없이 회수를 요청</strong>할 수 있어요.',
    INSPECTED: '회수가 필요한 상품이 있다면, <strong>검수 상태에 상관없이 회수를 요청</strong>할 수 있어요.',
    RETURN_IN_PROGRESS:
      '회수가 필요한 상품이 있다면, <strong>검수 상태에 상관없이 회수를 요청</strong>할 수 있어요.',
    RETURN_COMPLETED: 'LGL 입고된 모든 상품의 <strong>검수가 완료</strong>되었어요.',
  },
  INBOUND: {
    EMPTY_INBOUND_REQUEST: '해당 주문서의 입고/검수 내역이 없어요.',
    BEFORE_PACKED: '상품 포장이 완료되어야 입고 요청할 수 있어요.',
    PACKED: '입고 요청 전, 운송장에 담긴 상품 리스트를 한번 더 확인해 주세요.',
    REQUESTED: '<strong>LGL 센터로 입고 요청</strong>이 완료되었어요.',
    SHIPOUT: '입고 요청된 상품이 <strong>마플에서 집하 처리</strong>되었어요.',
    ARRIVED: '입고 요청한 상품이 <strong>LGL 센터에 도착</strong>했어요!',
    RECEIVED: '입고 요청한 상품이 <strong>LGL 센터로 입고 완료</strong>되었어요.',
    INSPECTING: '회수가 필요한 상품이 있다면, <strong>검수 상태에 상관없이 회수를 요청</strong>할 수 있어요.',
    INSPECTED: '회수가 필요한 상품이 있다면, <strong>검수 상태에 상관없이 회수를 요청</strong>할 수 있어요.',
    RETURN_IN_PROGRESS:
      '회수가 필요한 상품이 있다면, <strong>검수 상태에 상관없이 회수를 요청</strong>할 수 있어요.',
    RETURN_COMPLETED: 'LGL 입고된 모든 상품의 <strong>검수가 완료</strong>되었어요.',
  },
  RETURN: {
    EMPTY: '해당 주문서의 회수 내역이 없어요.',
    INITIAL: '회수 건이 있다면, 검수 불량품과 합포장 보류 건에 대한 회수 상태를 볼 수 있어요.',
  },
  OUTBOUND: {
    EMPTY: '해당 주문서의 출고 내역이 없어요.',
    INITIAL: '모든 상품이 입고되어 검수 완료 - 합포장 완료 후에 출고되어요.',
    FAILED: '출고 주문 생성 실패 사유를 확인 후, 해결하면 출고 주문 생성 요청을 하실 수 있어요.',
  },
  TRACKING: {
    EMPTY: '해당 주문서의 조회할 내용이 없어요.',
    INITIAL:
      '해당 주문서의 해외 배송에 대한 모든 현황과 배송 조회가 가능한 페이지로, 상품 제작 완료 시점부터 기록되어요.',
  },
};

export const TRACKING_OVERALL_STATUS_TABLE_COLUMN = {
  status_at: {
    title: '일시',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '296px',
    template: (row) => `${moment(row.status_at).format('YYYY.MM.DD HH:mm')}`,
  },
  step: {
    title: '물류 스텝',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '280px',
    template: makeTableStepBadgeHtml,
  },
  waybill_no: {
    title: '운송장',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '300px',
    template: ({ waybill_no }) => `${waybill_no || '-'}`,
  },
  status: {
    title: '현황',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    template: (row) => {
      if (row.status === 'LGL 센터 출고 완료') {
        return `<button type="button" class="shipout_from_lgl_detail line">LGL 출고 완료 배송 조회</button>`;
      } else {
        return row.status;
      }
    },
    width: '400px',
  },
};

export const TRACKING_DETAIL_TABLE_COLUMN = {
  date: {
    title: '일시',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '30%',
  },
  status: {
    title: '현황',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '70%',
  },
};

export const TRACKING_DETAIL_NOT_FULFILLMENT_TABLE_COLUMN = {
  trackingYmd: {
    title: '날짜',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '11%',
    template: (row) => `${moment(row.trackingYmd).format('YYYY.MM.DD')}`,
  },
  trackingTime: {
    title: '시간',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '11%',
    template: (row) => `${moment(row.trackingTime, 'HHmmss').format('HH:mm:ss')}`,
  },
  location: {
    title: '위치',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '20%',
  },
  trackingCodeLgl: {
    title: '코드',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '8%',
  },
  trackingDescLgl: {
    title: '현황',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '50%',
  },
};

export const OUTBOUND_PRODUCT_BY_PROJECTION_TABLE_COLUMN = {
  waybill_no: {
    title: '운송장 번호',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '140px',
  },
  name: {
    title: '상품 정보',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: '',
    width: '920px',
    template: makeTableProductInfoHtml,
  },
  quantity: {
    title: '수량',
    type: 'text',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '120px',
  },
  note: {
    title: '비고',
    type: 'custom',
    show: true,
    header_class: 'text_center',
    data_class: 'text_center',
    width: '200px',
    template: () => `<button class="tracking_detail line">배송조회</button>`,
  },
};

export const LGL_STATUSES = {
  BEFORE_DELIVER: {
    BEFORE_DELIVER: '제작 완료',
  },
  INBOUND: {
    PACKING: '운송장 발급',
    PACKED: '포장 완료',
    REQUESTED: 'LGL 입고 요청',
    CANCELED: '입고 요청 취소',
    SHIPOUT: '마플 집하',
    ARRIVED: 'LGL 도착',
    RECEIVED: 'LGL 입고 확인',
    INSPECTING: '검수 시작',
    INSPECTED: '검수 완료',
  },
  OUTBOUND: {
    CREATED: '출고 주문 생성',
    PACKING: '합포장 처리 중',
    PACKED: '합포장 완료',
    SHIPOUT_FROM_LGL: 'LGL 센터 출고 완료',
    CUSTOMS_DECLARED: '수출 신고',
    CUSTOMS_CLEARED: '수출 통관 완료',
    SHIPOUT_FROM_EXPRESS: '특송사에서 출고',
    CARGO_LOADED: '항공기에 선적',
    AIRPLANE_DEPARTED: '비행기 출발',
    LOCAL_ARRIVED: '현지 도착',
    LOCAL_CUSTOMS_CLEARING: '현지 통관 중',
    LOCAL_DELIVERING: '현지 배송 중',
    DELIVERED: '배송 완료',
  },
  RETURN: {
    RETURN_REQUESTED: '회수 요청',
    RETURN_CONFIRMED: '회수 발송',
  },
};

export const LGL_WAYBILL_TYPE = {
  INBOUND: '입고',
  OUTBOUND: '출고',
  RETURN: '회수',
};

export const LGL_TASK_TITLE = {
  INBOUND_REQUEST: 'LGL입고요청',
  REBOUND_REQUEST: 'LGL재입고요청',
  OUTBOUND_REQUEST: 'LGL출고요청',
  OUTBOUND_REQUEST_MANUAL: 'LGL수동출고요청',
  CENTER_SHIPOUT: 'LGL센터출고',
  RETURN_REQUEST: 'LGL회수요청',
};
