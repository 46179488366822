import { $addClass, $css, $delegate, $findAll, $qs, $setCss } from 'fxdom/es';
import { compact, each, go, map, sel, strMap } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { color_test_tab } from './color_test_tab.js';
import { watch_real_thumbnails } from './watch_real_thumbnails.js';
import { thumbnail_migration_test_composite_tab } from './thumbnail_migration_test_composite.js';
import { thumbnail_migration_test_normal_composite_tab } from './thumbnail_migration_test_normal_composite.js';
import { composite_thumbnail_test_tab } from './composite_thumbnail_test.js';
import { untitled_tab } from './untitled.js';
import axios from 'axios';
import {
  makeSelectBaseProductColorIdHtml,
  makeCheckCompositeTemplateIdHtml,
  testPageLoader,
  makeChangeCompositeHtml,
} from './fs.js';
import { map_test_tab } from './map_test_tab.js';

export const composite_template_test_frame = 'composite_template_test_frame';
export const composite_template_test_page = 'composite_template_test_page';
$.frame.defn_frame({
  frame_name: composite_template_test_frame,
  page_name: composite_template_test_page,
  always_remove: true,
  appended: function (don_frame) {
    don_frame.frame_opt.dream_factory_css = go($qs('#dream_factory'), $css('height'));
    don_frame.frame_opt.bbody_css = go($qs('#body'), $css('height'));
    go([$qs('.dream_factory'), $qs('#body')], each($setCss({ height: '100%' })));
  },
  closed: function (don_frame) {
    go($qs('#dream_factory'), $css({ height: don_frame.frame_opt.dream_factory_css }));
    go($qs('#body'), $css({ height: don_frame.frame_opt.bbody_css }));
    $addClass('test_page_started', $qs('#dream_factory'));
  },
});

$.frame.defn_page({
  page_name: composite_template_test_page,
  hide_frame_button_type: 'X',
  title: '테스트',
  tabs: [untitled_tab]
    .concat([color_test_tab, map_test_tab, composite_thumbnail_test_tab, watch_real_thumbnails])
    .concat(
      [thumbnail_migration_test_normal_composite_tab, thumbnail_migration_test_composite_tab],
      // box.sel('is_user->id') === 193535
      //   ? [thumbnail_migration_test_normal_composite_tab, thumbnail_migration_test_composite_tab]
      //   : [],
    ),
  appended: function (don_page) {
    go(
      don_page,
      $delegate('change', '[name="select_base_product_color_id"]', (e) => {
        go(
          e.delegateTarget,
          $findAll('.don_tab.is_show'),
          map(sel('tab_opt.reset_render')),
          compact,
          each((_render) => testPageLoader(_render)(e)),
        );
      }),
    );
  },
});
export async function openCompositeTemplateTestFrame(bp_id) {
  const { data: assoc_composite_templates } = await axios.get('/@api/composite/assoc_composite_templates', {
    params: {
      base_product_id: bp_id,
      composite_thumbnail_type: 'thumbnail',
      is_for_worker: true,
    },
  });
  const has_ct = !!assoc_composite_templates.length;

  const { data: base_product_colors } = await axios.get('/@api/base_product_color/list', {
    params: {
      base_product_id: box.sel('bp->id'),
      is_hidden: false,
      is_public: true,
    },
  });
  $.frame.open(
    {
      frame_name: composite_template_test_frame,
      el_class: has_ct ? 'has_composite' : '',
    },
    {
      page_name: composite_template_test_page,
      custom_header: (don_page) => {
        don_page.assoc_composite_templates = assoc_composite_templates;
        don_page.base_product_colors = base_product_colors;
        don_page.bp_id = bp_id;
        return legacyHtml`
          <button type="button" class="don_back_page">
            <span></span>
          </button>
          <div class="title">${don_page.title}</div>
          <div class="filter">
            ${makeSelectBaseProductColorIdHtml(base_product_colors)}
            ${makeCheckCompositeTemplateIdHtml(assoc_composite_templates)}
            ${makeChangeCompositeHtml(assoc_composite_templates)}
            <div class="option">
              <button type="button" func_name="apply">적용</button>
            </div>
          </div>
          <div class="tab_buttons">
            ${strMap(
              (tab) => legacyHtml`
                <button class="tab_button" selected="${!!tab.selected}" tab_name="${tab.tab_name}">
                  ${tab.title}
                </button>
              `,
              don_page.tabs,
            )}
          </div>
          <button type="button" class="don_hide_frame"></button>
        `;
      },
      appended: function (don_page) {
        go(
          don_page,
          $delegate('click', '.filter button[func_name="apply"]', async function (e) {
            $.don_loader_start();
            await go(
              don_page,
              $findAll('.don_tab.is_show'),
              each((don_tab) => don_tab.tab_opt.reset_render()),
            );
            $.don_loader_end();
          }),
        );
      },
    },
  );
}
