import axios from 'axios';
import { join, map, go, each, tap } from 'fxjs/es';
import { html } from 'rune-ts';
import { $attr, $find, $findAll, $closest, $setAttr, $removeClass } from 'fxdom/es';
import { BP_OPTION_LAYERS } from '../../../../NewMaker/Property/Bpf/S/Constant/bpf.js';
import { DefaultModalView } from '../../../DefaultModal/S/Function/defaultmodal.js';

/**
 * @typedef {Object} Props
 * @property {string} title - The Title of Modal
 * @property {string} width - The width of the Modal
 * @property {string} height - The height of the Modal
 * @property {BP_OPTION[]} all_bp_options
 * @property {PREVIEW_LAYER} preview_layers
 * */

/**
 * @typedef {Object} BP_OPTION
 * @property {number} bp_option_group_id
 * @property {string} name
 * */

/**
 * @typedef {Object.<string, BP_LAYER>} PREVIEW_LAYER
 * */

/**
 * @typedef {Object} BP_LAYER
 * @property {number} bp_option_id
 * @property {string} bp_option_name
 * @property {string} image_url
 * */

/**
 * @typedef {Object} EXTRA_DATA
 * @property {number} bp_option_id
 * @property {string} bp_option_name
 * */

export class SelectModalView extends DefaultModalView {
  /**
   * @type {BP_LAYER}
   * */
  selected = [];

  /**
   * @type {Object.<string, string>}
   */
  image_by_bp_option_id = {};

  onRender() {
    super.onRender();
    this.addEventListeners();
  }

  /**
   * Validate the OK button click event.
   * @throws {Error} If validation fails.
   */
  validHandleOk() {
    if (this.selected.length < 1) {
      throw new Error('최소 1개는 선택되어야 합니다.');
    }

    const check_none_image_url = this.selected.some((v) => !v.image_url);
    if (check_none_image_url) {
      throw new Error('선택한 옵션은 이미지를 반드시 업로드 하셔야 합니다.');
    }
  }

  /**
   * Handle the OK button click event.
   * @param {Event} e - The click event.
   */
  async handleOk(e) {
    try {
      this.validHandleOk();
      _p.go(e.currentTarget, filterBlur);

      await axios.post('/@api/df/base_product_face/preview_property', {
        property_name: BP_OPTION_LAYERS,
        property_value: this.selected,
        bpf_id: this.data.bpf_id,
      });

      this.updateFaceOptionSection();
      $.alert('저장되었습니다.');
      this.hide();
    } catch (e) {
      $.alert(e.message);
    }
  }

  updateFaceOptionSection() {
    const $el = document.querySelector(`.bp_face[bpf_id="${this.data.bpf_id}"]`);
    go($el, $find('.bp_option_layer_choice-delete'), $removeClass('d-none'));

    const layer_img_wrapper = $find(`.bp_option_layer_choice-img`, $el);
    const new_layer_images = go(
      this.selected,
      map(
        (selected) =>
          `<div><img src="${selected.image_url ? G.to_150(selected.image_url) : ''}" alt=""  class="${
            selected.image_url ? '' : 'd-none'
          }" /><div>${selected.bp_option_name}</div></div>`,
      ),
      join(''),
    );
    layer_img_wrapper.innerHTML = new_layer_images;
  }

  /**
   * Remove a selected item.
   * @param {BP_LAYER} selected - The selected item.
   */
  addSelected(selected) {
    this.selected.push(selected);
  }

  /**
   * Remove a selected item.
   * @param {BP_LAYER} selected - The selected item.
   */
  removeSelected(selected) {
    this.selected = this.selected.filter((v) => v.bp_option_id !== selected.bp_option_id);
  }

  /**
   * Update a selected item.
   * @param {number} bp_option_id - The ID of the option to update.
   * @param {Object} value - The new values for the selected item.
   */
  updateSelected(bp_option_id, value) {
    this.selected = this.selected.map((selected) => {
      if (selected.bp_option_id === bp_option_id) {
        return {
          ...selected,
          ...value,
        };
      }

      return selected;
    });
  }

  /**
   * Extract selected data from an element.
   * @param {HTMLElement} element - The element to extract data from.
   * @return {EXTRA_DATA} The extracted data.
   */
  extractSelectedData(element) {
    return {
      bp_option_id: Number($attr('data-id', element)),
      bp_option_name: $attr('data-name', element),
    };
  }

  /**
   * Handle the card click event
   * @param {Event} e - The click event.
   * */
  handleCardClick(e) {
    e.stopPropagation();
    const card = e.currentTarget;
    const selectedData = this.extractSelectedData(card);

    if (card.classList.contains('selected')) {
      this.removeSelected(selectedData);
    } else {
      this.addSelected({
        ...selectedData,
        image_url: selectedData.image_url || this.image_by_bp_option_id[selectedData.bp_option_id] || '',
      });
    }

    card.classList.toggle('selected');
  }

  /**
   * Handle the image upload event.
   * @param {Event} e - The change event.
   */
  handleImageUpload(e) {
    const input = e.currentTarget;
    const card = $closest(`.${this}__container-body__card`, input);
    _p.go(card, filterBlur);

    go(
      $.upload(input, { url: '/@fileUpload/file' }),
      tap((file) => {
        const image_url = file.url;
        go(card, $attr('data-id'), Number, (bp_option_id) => {
          this.image_by_bp_option_id[bp_option_id] = image_url;
          this.updateSelected(bp_option_id, { image_url });
        });
        go(card, $find('img'), $setAttr(['src', image_url]), $removeClass('d-none'));
      }),
    );
  }

  /**
   * Handle the image upload click event.
   * @param {Event} e - The click event.
   */
  handleImageUploadClick(e) {
    e.stopPropagation();
  }

  addCardClickListener() {
    go(
      $findAll(`.${this}__container-body__card`, this.element()),
      each((card) => card.addEventListener('click', (e) => this.handleCardClick(e))),
    );
  }

  addImageUploadListener() {
    go(
      $findAll(`.${this}__container-body__card-image-upload input[type="file"]`, this.element()),
      each((uploadInput) => {
        uploadInput.addEventListener('click', (e) => this.handleImageUploadClick(e));
        uploadInput.addEventListener('change', (e) => this.handleImageUpload(e));
      }),
    );
  }

  addEventListeners() {
    this.addCardClickListener();
    this.addImageUploadListener();
  }

  renderBpOptions() {
    /**
     * @type {Props}
     * */
    const data = this.data;
    const { all_bp_options, preview_layers } = data;

    return html`
      ${all_bp_options.map((bp_option) => {
        const { id, name } = bp_option;
        const is_selected = preview_layers[id];

        let image_url = '';

        if (is_selected) {
          const { bp_option_id, bp_option_name, image_url: selected_image_url } = is_selected;
          this.image_by_bp_option_id[bp_option_id] = selected_image_url;

          this.addSelected({
            bp_option_id,
            bp_option_name,
            image_url: selected_image_url,
          });

          image_url = selected_image_url;
        }

        return html` <div
          data-id="${id}"
          data-name="${name}"
          class="${this}__container-body__card ${is_selected ? 'selected' : ''}"
        >
          <div class="${this}__container-body__card-image-upload">
            <img id="img_${id}" src="${image_url}" alt="Preview Image" class="${image_url ? '' : 'd-none'}" />

            <input type="file" id="file_${id}" accept="image/*" />
            <span class="${this}__container-body__card-image-upload-text">이미지 업로드</span>
          </div>
          <div class="${this}__container-body__card-meta">
            <div>${name}</div>
          </div>
        </div>`;
      })}
    `;
  }

  renderChildren() {
    return html`<div class="${this}__container-body__card-container">${this.renderBpOptions()}</div> `;
  }
}
