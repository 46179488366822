import { html, strMap } from 'fxjs/es';
import { tools } from '../../F/Lib/state.js';
import {
  BACKGROUND_PROPERTY_NAME,
  BRUSH_PROPERTY_NAME,
  CONTROL_NAME,
  FILE_PROPERTY_NAME,
  R_STROKE_PROPERTY_NAME,
  SCALE_PROPERTY_NAME,
  SPOT_PROPERTY_NAME,
  STROKE_PROPERTY_NAME,
  SUB_TOOL_NAME,
  TOOL_NAME,
} from '../Constant/constants.js';
import { DfImageEditorLibF } from '../../F/Lib/module/DfImageEditorLibF.js';
import { addButtonControllerProperty, brushTool, sliderTool } from './helpers.tmpl.js';

export const tmpl = ({ press_types }) => {
  const erase_brush = DfImageEditorLibF.getBrushState(TOOL_NAME.erase);
  return html`<main class="image-editor">
    <div class="property-panel flex-center">
      <section class="${tools[TOOL_NAME.selection].className} selected">
        <div>이미지를 자유롭게 편집하세요.</div>
      </section>
      <section class="${tools[TOOL_NAME.background].className}">
        <div class="properties" tool-name="${TOOL_NAME.background}">
          <div
            class="property"
            property-name="${tools[TOOL_NAME.background].properties[BACKGROUND_PROPERTY_NAME.color]
              .propertyName}"
          >
            <label
              >${tools[TOOL_NAME.background].properties[BACKGROUND_PROPERTY_NAME.color].displayName}</label
            >
            <div class="color-controls">
              <button name="transparent"></button>
              <button name="auto"></button>
              <button name="black"></button>
              <button name="gray"></button>
              <button name="white"></button>
            </div>
          </div>
          <div
            class="property"
            property-name="${tools[TOOL_NAME.background].properties[BACKGROUND_PROPERTY_NAME.color]
              .propertyName}"
          >
            <label>피커</label>
            <input type="color" />
          </div>
        </div>
      </section>
      <section class="${tools[TOOL_NAME.erase].className}">
        <div class="sub-tools" tool-name="${TOOL_NAME.erase}">
          <button class="selected" name="${SUB_TOOL_NAME.erase.brush}">브러쉬</button>
          <button class="" name="${SUB_TOOL_NAME.erase.square}">사각</button>
          <button class="" name="${SUB_TOOL_NAME.erase.circle}">원형</button>
          <button class="" name="${SUB_TOOL_NAME.erase.free}">자유</button>
        </div>
        <div class="double-partition"></div>
        <div class="properties" tool-name="${TOOL_NAME.erase}">
          <div class="property" property-name="${BRUSH_PROPERTY_NAME.size}">
            ${brushTool({
              ...erase_brush[BRUSH_PROPERTY_NAME.size],
              tool_name: TOOL_NAME.erase,
            })}
          </div>
          <div class="property" property-name="${BRUSH_PROPERTY_NAME.hardness}">
            ${brushTool({
              ...erase_brush[BRUSH_PROPERTY_NAME.hardness],
              tool_name: TOOL_NAME.erase,
            })}
          </div>
        </div>
        <div class="double-partition"></div>
        <div class="controls" tool-name="${TOOL_NAME.erase}" control-name="${CONTROL_NAME.execute}">
          <button class="undo" name="undo"></button>
          <button class="redo" name="redo"></button>
          <button class="apply" name="apply"></button>
          <button class="cancel" name="cancel"></button>
        </div>
      </section>
      <section class="${tools[TOOL_NAME.scale].className}">
        <div class="properties" tool-name="${TOOL_NAME.scale}">
          <div
            class="property"
            property-name="${tools[TOOL_NAME.scale].properties[SCALE_PROPERTY_NAME.size].propertyName}"
          >
            <label>${tools[TOOL_NAME.scale].properties[SCALE_PROPERTY_NAME.size].displayName}</label>
            <input class="indicator wide" name="width" type="number" min="1" max="100" step="0.1" />
            x
            <input class="indicator wide" name="height" type="number" min="1" max="100" step="0.1" />
          </div>
        </div>
      </section>
      <section class="${tools[TOOL_NAME.spot].className}">
        <div class="properties" tool-name="${TOOL_NAME.spot}">
          <div
            class="property"
            property-name="${tools[TOOL_NAME.spot].properties[SPOT_PROPERTY_NAME.area].propertyName}"
          >
            <label>${tools[TOOL_NAME.spot].properties[SPOT_PROPERTY_NAME.area].displayName}</label>
            <div class="slider wide"></div>
          </div>
          <div class="double-partition"></div>
          <div class="controls" tool-name="${TOOL_NAME.spot}" control-name="${CONTROL_NAME.position}">
            <button class="leftmost" name="leftmost"></button>
            <button class="left" name="left"></button>
            <button class="right" name="right"></button>
            <button class="rightmost" name="rightmost"></button>
          </div>
          <div class="double-partition"></div>
          <div class="controls" tool-name="${TOOL_NAME.spot}" control-name="${CONTROL_NAME.execute}">
            <button class="delete" name="delete"></button>
            <button class="cancel" name="cancel"></button>
          </div>
        </div>
      </section>

      <section class="${tools[TOOL_NAME.rStroke].className}">
        <div class="properties" tool-name="${TOOL_NAME.rStroke}">
          <div
            class="property"
            property-name="${tools[TOOL_NAME.rStroke].properties[R_STROKE_PROPERTY_NAME.color].propertyName}"
          >
            <label>${tools[[TOOL_NAME.rStroke]].properties[R_STROKE_PROPERTY_NAME.color].displayName}</label>
            <input type="color" />
            <div class="custom-bpcf-picker">
              <button class="open-bpcf-color-picker"></button>
              <div class="bpcf-window hidden" tabindex="0">
                <button class="close"></button>
                <canvas class="bpcf" width="400" height="400"></canvas>
              </div>
            </div>
          </div>
          ${addButtonControllerProperty({
            ...tools[TOOL_NAME.rStroke].properties[R_STROKE_PROPERTY_NAME.thickness],
            tool_name: TOOL_NAME.rStroke,
          })}
          <div
            class="property"
            property-name="${tools[TOOL_NAME.rStroke].properties[R_STROKE_PROPERTY_NAME.alpha_threshold]
              .propertyName}"
          >
            ${sliderTool({
              ...DfImageEditorLibF.getState.property.rStroke.alphaThreshold(),
              tool_name: TOOL_NAME.rStroke,
            })}
          </div>
          <div class="double-partition"></div>
          <div class="controls" tool-name="${TOOL_NAME.rStroke}" control-name="${CONTROL_NAME.execute}">
            <button class="apply" name="apply"></button>
            <button class="cancel" name="cancel"></button>
          </div>
        </div>
      </section>

      <section class="${tools[TOOL_NAME.vStroke].className}">
        <div class="properties" tool-name="${TOOL_NAME.vStroke}">
          <div
            class="property"
            property-name="${tools[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.color].propertyName}"
          >
            <label>${tools[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.color].displayName}</label>
            <input type="color" />
          </div>
          ${addButtonControllerProperty({
            ...tools[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.width],
            tool_name: TOOL_NAME.vStroke,
          })}
          ${addButtonControllerProperty({
            ...tools[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.offset],
            tool_name: TOOL_NAME.vStroke,
          })}
        </div>
        <div class="double-partition"></div>
        <div class="controls" tool-name="${TOOL_NAME.vStroke}" control-name="${CONTROL_NAME.execute}">
          <div>
            <label>디테일 수준</label>
            <button class="apply-with-level no1" name="apply-with-level" level="1"></button>
            <button class="apply-with-level no2" name="apply-with-level" level="2"></button>
            <button class="apply-with-level no3" name="apply-with-level" level="3"></button>
            <button class="apply-with-level no4" name="apply-with-level" level="4"></button>
            <button class="apply-with-level no5" name="apply-with-level" level="5"></button>
            <button class="apply-with-level no6" name="apply-with-level" level="6"></button>
            <button class="apply-with-level no7" name="apply-with-level" level="7"></button>
          </div>
          <button class="cancel" name="cancel"></button>
        </div>
      </section>
      <section class="${tools[TOOL_NAME.file].className}">
        <div class="properties" tool-name="${TOOL_NAME.file}">
          <div class="property" property-name="${FILE_PROPERTY_NAME.press_type}">
            <label>${tools[TOOL_NAME.file].properties.pressType.displayName}</label>
            <select>
              ${strMap((type) => `<option value="${type.id}">${type.name}</option>`, press_types)}
            </select>
          </div>
        </div>
        <div class="double-partition"></div>
        <div class="controls" tool-name="${TOOL_NAME.file}" control-name="${CONTROL_NAME.upload}">
          <button class="upload" name="upload"></button>
          <button class="margin-upload" name="margin-upload"></button>
          <button class="cut-upload" name="cut-upload"></button>
        </div>
      </section>
    </div>
    <div class="content">
      <aside class="tool-panel">
        <section class="default">
          <button class="${tools[TOOL_NAME.selection].className} selected" tool-name="${TOOL_NAME.selection}">
            ${tools[TOOL_NAME.selection].displayName}
          </button>
        </section>
        <section class="basic">
          <button class="${tools[TOOL_NAME.background].className}" tool-name="${TOOL_NAME.background}">
            ${tools[TOOL_NAME.background].displayName}
          </button>
          <button class="${tools[TOOL_NAME.erase].className}" tool-name="${TOOL_NAME.erase}">
            ${tools[TOOL_NAME.erase].displayName}
          </button>
          <button class="${tools[TOOL_NAME.scale].className}" tool-name="${TOOL_NAME.scale}">
            ${tools[TOOL_NAME.scale].displayName}
          </button>
        </section>
        <section class="advanced">
          <button class="${tools[TOOL_NAME.spot].className}" tool-name="${TOOL_NAME.spot}">
            ${tools[TOOL_NAME.spot].displayName}
          </button>
          <button class="${tools[TOOL_NAME.rStroke].className}" tool-name="${TOOL_NAME.rStroke}">
            ${tools[TOOL_NAME.rStroke].displayName}
          </button>
          <button class="${tools[TOOL_NAME.vStroke].className}" tool-name="${TOOL_NAME.vStroke}">
            ${tools[TOOL_NAME.vStroke].displayName}
          </button>
        </section>
        <section class="file-handle">
          <button class="${tools[TOOL_NAME.file].className}" tool-name="${TOOL_NAME.file}">
            ${tools[TOOL_NAME.file].displayName}
          </button>
        </section>
      </aside>
      <section class="work-area">
        <div class="size-indicator">
          <span name="width"></span>
          <span>x</span>
          <span name="height"></span>
        </div>
        <div class="artboard" color="transparent">
          <canvas class="work-canvas"></canvas>
        </div>
      </section>
    </div>
  </main> `;
};
