import { go, map, sel } from 'fxjs/es';
import { getProductColorInMaker } from '../getSth.js';
import { changeProductColorByBps, renderFacesByCurrentBpcfsOnlySizeInMakerA } from '../mp_maker.js';
import { $hasClass, $qs } from 'fxdom/es';
import { NewMakerUtilF } from '../../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { NewMakerCallConditionPopupF } from '../../../NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { renderVisibleWhenSelected } from '../select_faces.js';
import { getCurrentFcanvas } from '../Fcanvas/cv_object.js';

export function product_editor_size_init(bpss) {
  const is_basic = $qs('html[marpplizer_mode="basic"]');
  const { marpplizer_wrapper_width, min_width, item_margin_right, item_min_padding, border, font_size } =
    NewMakerUtilF.marpplizerProductSize(is_basic);
  const wrapper_width = marpplizer_wrapper_width + item_margin_right;
  const short_name_el_size = $.biggest_span_size(go(bpss, map(sel('short_name' + _en))), font_size);

  let item_outer_width = short_name_el_size + item_min_padding + item_margin_right + border;

  item_outer_width = item_outer_width < min_width ? min_width : item_outer_width;
  const one_line_items_length = Math.floor(wrapper_width / item_outer_width);

  return go(wrapper_width / one_line_items_length, Math.floor, function (ex_item_width) {
    return { width: ex_item_width - item_margin_right, one_line_items_length };
  });
}

export function getObjectForBpsSizeItem(bps, sold_out_size_ids) {
  const base_product_size_id = getProductColorInMaker().base_product_size_id;
  const id = bps.id;
  const short_name = bps['short_name' + _en];
  const selected = bps.id === base_product_size_id ? 'selected' : '';
  const sold_out = sold_out_size_ids.includes(bps.id) ? 'sold_out' : '';
  return { id, short_name, selected, sold_out };
}

export async function eventProductBpsA(e) {
  const ct = e.currentTarget;
  if ($hasClass('sold_out', e.currentTarget)) {
    const error = new Error();
    error.__mp_message = T('maker_error_alert::품절되었습니다.');
    throw error;
  }
  $.selectable2(e.currentTarget);
  changeProductColorByBps(box.sel(ct));
  $.don_loader_start();
  await renderFacesByCurrentBpcfsOnlySizeInMakerA();
  NewMakerCallConditionPopupF.uiRender();
  if (NewMakerCallConditionPopupF.is_mapping_confirm_popup()) {
    renderVisibleWhenSelected(getCurrentFcanvas());
  }
  $.don_loader_end();
}

export async function changeProductBps(base_product_size_id) {
  changeProductColorByBps({ id: base_product_size_id });
  await renderFacesByCurrentBpcfsOnlySizeInMakerA();
  NewMakerCallConditionPopupF.uiRender();
  if (NewMakerCallConditionPopupF.is_mapping_confirm_popup()) {
    renderVisibleWhenSelected(getCurrentFcanvas());
  }
}
