import { NewMakerProductStyleS } from '../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';

export function isBpStrapCase(base_product) {
  return base_product && base_product.name.indexOf('스트랩 케이스') > -1;
}

export const isNeedPreview = function (base_product) {
  if (!base_product?.name) return;
  const bp_name = base_product.name.replace(/\s/g, '');
  return NewMakerProductStyleS.PREVIEW_PHONE_CASE_BP_NAMES.test(bp_name);
};

export const isWatchBand = (bp) => bp && [4788, 4789].includes(bp.id);
