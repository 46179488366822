import { CommonNS } from '@marpple/sticker-editor';
import { $append, $el, $findAll, $hide, $on, $remove, $show } from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerGridMobileCopyGridMuiS } from '../../S/Mui/module/VectorEditorStickerGridMobileCopyGridMuiS.js';
import { VectorEditorStickerGridMobileCopyGridTmplS } from '../../S/Tmpl/module/VectorEditorStickerGridMobileCopyGridTmplS.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerGridMobileCopyGridMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {
    tab_el$.__mp_is_finished = false;
    tab_el$.__mp_blank_plate_el = $el(VectorEditorStickerGridMobileCopyGridTmplS.makeBlankPlateHtml());
    tab_el$.__mp_copy_grid_footer_el = $el(
      VectorEditorStickerGridMobileCopyGridTmplS.makeFooterHtml({ is_show: false }),
    );

    go(
      tab_el$.__mp_copy_grid_footer_el,
      $findAll(`.cancel`),
      each(
        $on('click', () => {
          tab_el$.__mp_grid_sticker_editor?.abortCopy();
          tab_el$.__mp_is_finished = true;
          MuiF.closeFrame();
        }),
      ),
    );
    go(
      tab_el$.__mp_copy_grid_footer_el,
      $findAll(`.done`),
      each(
        $on('click', async () => {
          const is_ok =
            tab_el$.__mp_grid_sticker_editor?.getOverrideCopyDestinationGridIds().length <= 0 ||
            (await $.confirm(
              T('modules::VectorEditor::Sticker::FreeGrid::기존 스티커를 덮어쓰기 하시겠습니까?'),
            ));

          if (!is_ok) {
            return;
          }

          $.don_loader_start();
          try {
            await tab_el$.__mp_grid_sticker_editor.endCopy(async ({ id, target_image_el }) => {
              const grid = tab_el$.__mp_grids?.get?.(id);
              if (!grid) {
                const error = new Error(`No grid`);
                error.__mp_alert_message = `복사할 대상 그리드 정보를 찾을 수 없습니다.`;
                throw error;
              }

              const { x, y } = grid;
              const clone_target_image_el = await CommonNS.UtilNS.deepCloneNode(target_image_el);
              clone_target_image_el.setAttributeNS(null, 'x', `${x}`);
              clone_target_image_el.setAttributeNS(null, 'y', `${y}`);
              return clone_target_image_el;
            });
            tab_el$.__mp_is_finished = true;
          } finally {
            $.don_loader_end();
          }
          MuiF.closeFrame();
        }),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    $append(tab_el$.__mp_copy_grid_footer_el)(tab_el$.__mp_container_el);
    $append(tab_el$.__mp_blank_plate_el)(tab_el$.__mp_container_el);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  async showing(tab_el$) {
    await new Promise((resolve) => setTimeout(resolve, 10));
    tab_el$.__mp_copy_grid_footer_el.dataset.is_show = 'true';
    return tab_el$.__mp_onShowing?.();
  }, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {
    $hide(tab_el$.__mp_blank_plate_el);
    tab_el$.__mp_grid_sticker_editor?.startCopy();
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {
    $show(tab_el$.__mp_blank_plate_el);
    tab_el$.__mp_copy_grid_footer_el.dataset.is_show = 'false';
    return tab_el$?.__mp_onHiding?.();
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  async removed(tab_el$, v) {
    if (!tab_el$.__mp_is_finished) {
      tab_el$.__mp_grid_sticker_editor?.abortCopy();
      tab_el$.__mp_is_finished = true;
    }

    $remove(tab_el$.__mp_copy_grid_footer_el);
    $remove(tab_el$.__mp_blank_plate_el);
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
