import moment from 'moment/moment.js';

export const makeOutboundDetailHtml = (data) => {
  const { is_ok, inspected_at, reject_url, reject_reason, memo } = data;

  const reject_el = is_ok
    ? ''
    : `
                <dt>보류 사유</dt>
                <dd>${reject_reason}</dd>
                <dt>보류 상품</dt>
                <dd><img loading="lazy" alt="보류 상품 이미지" src="${reject_url}"/></dd>`;

  return `<div class="header">
            <h1>합포장 상세 정보</h1>
          </div>
          <div class="body">
            <dl>
              <dt>완료 일시</dt>
              <dd>${moment(inspected_at).format('YYYY.MM.DD HH:mm')}</dd>
              ${reject_el}
              <dt>memo</dt>
              <dd>${memo}</dd>
            </dl>
        </div>`;
};
