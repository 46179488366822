import { $setCss } from 'fxdom/es';
import { go, tap } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorPhotoCardGridPCMuiF } from '../Mui/module/VectorEditorPhotoCardGridPCMuiF.js';

export const makePhotoCard = ({
  title,
  price,
  frame_position: { top: frame_position_top, height: frame_position_height },
  // art_board_size: { width: art_board_width, height: art_board_height },
  // background: { fill: background_fill = '#ffffff', opacity: background_opacity = 1 },
  // grids,
  prev_frame_right_panel,
  onFrameHiding,
  preProcess,
  postProcess,
}) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorPhotoCardGridPCMuiF.frame, (frame, page, [tab]) => {
      const frame_appending = frame.appending;
      frame.appending = (_frame_el) => {
        $setCss(['z-index', '1'])(_frame_el);
        return frame_appending.call(frame, _frame_el);
      };
      const frame_hiding = frame.hiding;
      frame.hiding = (frame_el, val) =>
        go(onFrameHiding(frame_el, val), () => frame_hiding.call(frame, frame_el, val));
      const frame_closed = frame.closed;
      frame.closed = (frame_el, val) =>
        go(
          frame_closed.call(frame, frame_el, val),
          tap(() => resolve(val)),
        );

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        // @TODO KUNHO
        // tab_el.__mp_title = title;
        // tab_el.__mp_price = price;
        // tab_el.__mp_frame_position_top = frame_position_top;
        // tab_el.__mp_frame_position_height = frame_position_height;
        // tab_el.__mp_art_board_size = { width: art_board_width, height: art_board_height };
        // tab_el.__mp_background_color = background_fill;
        // tab_el.__mp_background_opacity = background_opacity;
        // tab_el.__mp_prev_frame_right_panel_el = prev_frame_right_panel;
        // tab_el.__mp_grids = reduce(
        //   (m, grid) => {
        //     m.set(grid.id, grid);
        //     return m;
        //   },
        //   new Map(),
        //   grids,
        // );
        // tab_el.__mp_preProcess = preProcess;
        // tab_el.__mp_postProcess = postProcess;
        return tab_appending.call(tab, tab_el);
      };
    }).catch(reject),
  );
