import UserWithdrawalS from '../S/index.js';

export const main = () => ({
  tab_name: 'mp.user_withdrawal_tab',
  template: (user) => {
    if (T.lang == 'kr') return UserWithdrawalS.tmpl.withdrawalKr(user);
    else if (T.lang == 'jp') return UserWithdrawalS.tmpl.withdrawalJp(user);
    return UserWithdrawalS.tmpl.withdrawalEn(user);
  },
  appended: __(
    $.on2('click', '.btn_withdrawal', function () {
      return _p.go(
        T('my_info::Do you really want to leave?'),
        $.confirm,
        _p.if2(_p.idtt)(
          function () {
            return $.post('/@api/user/withdrawal', {
              user_id: box.sel('is_user->id'),
            });
          },
          function (data) {
            if (!data.result) return $.alert(T('my_info::fail please try again.'));

            _p.go(T('my_info::Success'), $.alert, function () {
              location.href = '/';
            });
          },
        ),
      );
    }),
  ),
});
