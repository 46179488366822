export default function (lo) {
  // $.frame2({
  //   is_mobile_size: false,
  //   open_frame: {
  //     frame_name: 'df.folder2_detail_frame'
  //   },
  //   open_page: {
  //     data_func: function() {
  //       return box.sel('folder2');
  //     }
  //   }
  // });
}
