import { go, pluck, unique } from 'fxjs/es';
import { makeCanvasByUrl } from '../../../../Canvas/S/util.js';
import { makeTrimCanvas3 } from '../../../../Maker/F/canvas_trim.js';
import { DfAutoImageWorkConstantS } from '../../S/Constant/module/DfAutoImageWorkConstantS.js';
import { makePrintableCanvas } from './private/makePrintableCanvas.js';

export const makeTrimmedCanvas = async ({ product_face, press_type_id, dpi }) => {
  const auto_press_type = DfAutoImageWorkConstantS.AUTO_PRESS_TYPES.find(
    ({ press_type_id: _press_type_id }) => _press_type_id == press_type_id,
  );
  if (!auto_press_type) throw new Error('자동화 인쇄방식이 아닙니다.');
  const px_per_1cm = go(product_face.size_faces, pluck('px_per_1cm'), unique, (a) => a.length === 1 && a[0]);
  if (!px_per_1cm) return $.alert('ERROR::px_per_1cm - 사이즈마다 다른 px_per_1cm을 갖고 있습니다.');
  if (!dpi) return $.alert('ERROR::dpi - 사이즈마다 다른 dpi 갖고 있습니다.');

  const [canvas, cv_group] = await makePrintableCanvas({
    product_face_designs: product_face.designs,
    px_per_1cm,
    dpi,
  });
  const trimmed_canvas = await go(cv_group.toDataURL('image/png', 1), makeCanvasByUrl, makeTrimCanvas3);
  canvas.dispose();
  return trimmed_canvas;
};

// export const makeAutoImageBase64 = async ({ product_face, press_type_id }) => {
//   const auto_press_type = DfAutoImageWorkConstantS.AUTO_PRESS_TYPES.find(
//     ({ press_type_id: _press_type_id }) => _press_type_id == press_type_id,
//   );
//   if (!auto_press_type) throw new Error('자동화 인쇄방식이 아닙니다.');
//   const px_per_1cm = go(product_face.size_faces, pluck('px_per_1cm'), unique, (a) => a.length === 1 && a[0]);
//   const dpi = go(product_face.size_faces, pluck('dpi'), compact, unique, (a) =>
//     a.length > 1 ? null : a[0] || 300,
//   );
//   if (!px_per_1cm) return $.alert('ERROR::px_per_1cm - 사이즈마다 다른 px_per_1cm을 갖고 있습니다.');
//   if (!dpi) return $.alert('ERROR::dpi - 사이즈마다 다른 dpi 갖고 있습니다.');
//   if (auto_press_type.press_type_id === DfAutoImageWorkConstantS.DTF.press_type_id) {
//     const plus_ratio = 5;
//     const trimmed_canvas_for_outline = await go(undefined, async () => {
//       const [canvas, cv_group] = await makePrintableCanvas({
//         product_face_designs: product_face.designs,
//         px_per_1cm,
//         dpi,
//         plus_ratio,
//       });
//       const _canvas = await go(cv_group.toDataURL('image/png', 1), makeCanvasByUrl, makeTrimCanvas3);
//       canvas.dispose();
//       return _canvas;
//     });
//     // const px_per_cm = (1 / 2.54) * dpi;
//     // const offset_pixel = Math.floor(0.03 * px_per_cm);
//     const offset_pixel = 5;
//     const margin1 = offset_pixel * plus_ratio;
//     const margin_canvas = makeMarginCanvas({
//       canvas: trimmed_canvas_for_outline,
//       margin: margin1,
//     });
//     const margin_canvas_ctx = margin_canvas.getContext('2d');
//     margin_canvas_ctx.drawImage(
//       trimmed_canvas_for_outline,
//       0,
//       0,
//       trimmed_canvas_for_outline.width,
//       trimmed_canvas_for_outline.height,
//       margin1,
//       margin1,
//       trimmed_canvas_for_outline.width,
//       trimmed_canvas_for_outline.height,
//     );
//     const outline_layer_url = await NewMakerBaseProductsWebGlProcessingF.getOutlineLayer({
//       uri: margin_canvas.toDataURL('image/png', 1),
//       colorDistancePercent: 0.0,
//       offsetPixel: offset_pixel,
//       color: [0, 0, 0, 1.0],
//     });
//     const outline_canvas = await makeCanvasByUrl(outline_layer_url);
//     const trimmed_canvas = await go(undefined, async () => {
//       const [canvas, cv_group] = await makePrintableCanvas({
//         product_face_designs: product_face.designs,
//         px_per_1cm,
//         dpi,
//       });
//       const _canvas = await go(cv_group.toDataURL('image/png', 1), makeCanvasByUrl, makeTrimCanvas3);
//       canvas.dispose();
//       return _canvas;
//     });
//     const margin2 = offset_pixel;
//     const merged_canvas = makeMarginCanvas({ canvas: trimmed_canvas, margin: margin2 });
//     const ctx = merged_canvas.getContext('2d');
//     ctx.drawImage(
//       outline_canvas,
//       0,
//       0,
//       outline_canvas.width,
//       outline_canvas.height,
//       0,
//       0,
//       merged_canvas.width,
//       merged_canvas.height,
//     );
//     ctx.drawImage(
//       trimmed_canvas,
//       0,
//       0,
//       trimmed_canvas.width,
//       trimmed_canvas.height,
//       margin2,
//       margin2,
//       trimmed_canvas.width,
//       trimmed_canvas.height,
//     );
//     return changeDpiDataUrl(merged_canvas.toDataURL('image/png', 1), dpi);
//   } else {
//     const [canvas, cv_group] = await makePrintableCanvas({
//       product_face_designs: product_face.designs,
//       px_per_1cm,
//       dpi,
//     });
//     const trimmed_canvas = await go(cv_group.toDataURL('image/png', 1), makeCanvasByUrl, makeTrimCanvas3);
//     canvas.dispose();
//     return changeDpiDataUrl(trimmed_canvas.toDataURL('image/png', 1), dpi);
//   }
// };
