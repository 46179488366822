import { entries, find, go } from 'fxjs/es';

function _getNotSameKey(src_obj, target_obj) {
  return go(
    src_obj,
    entries,
    find(([k, v]) => {
      if (typeof target_obj[k] === 'object' && typeof v === 'object') return getNotSameKey(v, target_obj[k]);
      if (!target_obj[k]) return true;
      return target_obj[k] != v;
    }),
    (arr) => (arr ? arr[0] : null),
  );
}

export const getNotSameKey = function (src_obj, target_obj) {
  if (Array.isArray(src_obj) && Array.isArray(target_obj)) {
    throw new Error(`getNotSameKey argument type error Array 비교 불가`);
  }
  if (typeof src_obj === 'object' && typeof target_obj === 'object') {
    return _getNotSameKey(src_obj, target_obj);
  }
  throw new Error(
    `getNotSameKey argument type error src_obj: ${typeof src_obj}, target_obj:${typeof target_obj}`,
  );
};
