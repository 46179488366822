import './frame.js';
import './page.js';

export const defnUserLoginRoute = () => {
  const location = G.$location();
  // 여기 다시 손보자...
  if (_p.v(location, 'query.verified') == 'true') {
    $.alert(T('login::Your email address is verified.'));
    window.history.replaceState(
      window.history.state,
      '',
      location.query.url ? location.pathname + '?url=' + location.query.url : location.pathname,
    );
  } else if (_p.v(location, 'query.verified') == 'false') {
    $.alert(T('login::Your email address is not verified.'));
    window.history.replaceState(
      window.history.state,
      '',
      location.query.url ? location.pathname + '?url=' + location.query.url : location.pathname,
    );
  }

  $.frame(
    {
      frame_name: 'mp.login',
      page_name: 'mp_login_page',
    },
    {
      page_name: 'mp_login_page',
      tabs: [
        {
          tab_name: 'mp_login_tab',
          selected: true,
          showed: function (tab) {
            if (G.is_pc_size() && $.css($1('#footer'), 'display') != 'block') {
              $.append($1('#body'), $1('#footer'));
              $.show($1('#footer'));
            }

            if (!G.is_pc_size()) {
              $.append($.find1(tab, '.don_wrapper'), $1('#footer'));
              $.show($1('#footer'));
            }
          },
        },
        {
          tab_name: 'mp_login_sign_up_tab',
          showed: function (tab) {
            if (!G.is_pc_size()) {
              $.append($.find1(tab, '.don_wrapper'), $1('#footer'));
              $.show($1('#footer'));
            }
          },
        },
      ],
    },
  );
};
