import { filter, flatMap, go, map, mapObject, not, omit, pick, pipe, reject, some, values } from 'fxjs/es';
import { makeCanvasByUrl } from '../../../../Canvas/S/util.js';
import { getCvDesigns, makeFilterCvObj } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { getItsSf } from '../../../../Maker/F/getSth.js';
import { filterParallelCoords } from '../../../Checker/F/Function/filterParallelCoords.js';
import { isNotFullImage } from '../../../Checker/F/Function/isNotFullImage.js';

/**
 * 점선 이내(MM mm 기본 0.3mm) 이며 평행 체크
 * */
export const isParallelCheckPass = async (cv_target, MM = 0.3) => {
  const cid = cv_target.cid;

  return go(
    [cv_target.canvas],
    some((fcanvas) => {
      return go(
        makeFilterCvObj(fcanvas._objects, 'cv_obj_alignment'),
        some((cv_obj) => {
          return _isSthOnParallel(fcanvas, {
            cid,
            big: {
              top: cv_obj.top - fcanvas._px_per_1cm * MM,
              left: cv_obj.left - fcanvas._px_per_1cm * MM,
              width: cv_obj.width * cv_obj.scaleX + fcanvas._px_per_1cm * MM * 2,
              height: cv_obj.height * cv_obj.scaleY + fcanvas._px_per_1cm * MM * 2,
            },
            small: {
              top: cv_obj.top + fcanvas._px_per_1cm * MM,
              left: cv_obj.left + fcanvas._px_per_1cm * MM,
              width: cv_obj.width * cv_obj.scaleX - fcanvas._px_per_1cm * MM * 2,
              height: cv_obj.height * cv_obj.scaleY - fcanvas._px_per_1cm * MM * 2,
            },
          });
        }),
      );
    }),
    not,
  );
};

async function _isSthOnParallel(fcanvas, { big, small, cid }, is_bounding_rect = false) {
  const { px_per_1cm } = getItsSf(fcanvas);
  const cv_print_area_xys = go(big, ({ top, left, width, height }) => ({
    tl: { x: left, y: top },
    tr: { x: left + width, y: top },
    bl: { x: left, y: top + height },
    br: { x: left + width, y: top + height },
  }));

  const cv_safety_area_minus_5mm_xys = go(small, ({ top, left, width, height }) => ({
    tl: { x: left, y: top },
    tr: { x: left + width, y: top },
    bl: { x: left, y: top + height },
    br: { x: left + width, y: top + height },
  }));
  const all_xys = await go(
    getCvDesigns(fcanvas._objects),
    filter((design) => design.cid === cid),
    reject(async (cv_obj) => {
      if (cv_obj._data.cv_type === 'cv_image') {
        const img_canvas = await makeCanvasByUrl(G.to_150(cv_obj._data.image_url));
        return isNotFullImage(img_canvas);
      }
      return cv_obj._data.cv_type === 'cv_group';
    }),
    flatMap((cv_obj) =>
      go(is_bounding_rect ? _getPoints(cv_obj) : cv_obj.oCoords, pick(['tl', 'tr', 'bl', 'br']), values),
    ),
  );
  const horizontal_xys = go(
    all_xys,
    filter((point) => {
      return (
        isContainPointOnlySquare(
          {
            tl: { x: 0, y: cv_print_area_xys.tl.y },
            br: { x: G.mp.maker.CANVAS_WIDTH, y: cv_safety_area_minus_5mm_xys.tl.y },
          },
          point,
        ) ||
        isContainPointOnlySquare(
          {
            tl: {
              x: 0,
              y: cv_safety_area_minus_5mm_xys.bl.y,
            },
            br: {
              x: G.mp.maker.CANVAS_WIDTH,
              y: cv_print_area_xys.br.y,
            },
          },
          point,
        )
      );
    }),
    map(
      pipe(
        (point) => JSON.parse(JSON.stringify(point)),
        omit(['corner']),
        mapObject((v) => Math.round(v)),
      ),
    ),
    (xys) => filterParallelCoords({ xys, is_horizontal: true }),
    reject(([_first, _last]) => {
      const cm = Math.abs(_first.x - _last.x) / px_per_1cm;
      return cm < 1;
    }),
  );
  const vertical_xys = go(
    all_xys,
    filter((point) => {
      return (
        isContainPointOnlySquare(
          {
            tl: {
              x: cv_print_area_xys.tl.x,
              y: 0,
            },
            br: { x: cv_safety_area_minus_5mm_xys.tl.x, y: G.mp.maker.CANVAS_HEIGHT },
          },
          point,
        ) ||
        isContainPointOnlySquare(
          {
            tl: {
              x: cv_safety_area_minus_5mm_xys.tr.x,
              y: 0,
            },
            br: {
              x: cv_print_area_xys.br.x,
              y: G.mp.maker.CANVAS_HEIGHT,
            },
          },
          point,
        )
      );
    }),
    map(
      pipe(
        (point) => JSON.parse(JSON.stringify(point)),
        omit(['corner']),
        mapObject((v) => Math.round(v)),
      ),
    ),
    (xys) => filterParallelCoords({ xys, is_horizontal: false }),
    reject(([_first, _last]) => {
      const cm = Math.abs(_first.y - _last.y) / px_per_1cm;
      return cm < 1;
    }),
  );
  return horizontal_xys.length || vertical_xys.length;
}

function _getPoints(cv_obj) {
  const { top, left, width, height } = cv_obj.getBoundingRect();
  return {
    tl: { x: left, y: top },
    tr: { x: left + width, y: top },
    bl: { x: left, y: top + height },
    br: { x: left + width, y: top + height },
  };
}

function isContainPointOnlySquare({ tl, br }, point) {
  return tl.x <= point.x && br.x >= point.x && tl.y <= point.y && br.y >= point.y;
}
