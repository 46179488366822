export const SHIPPING_COST_LINE = 3000;
export const AMOUNT_FOR_FREE = 100000;
export const AMOUNT_FOR_FREE_CREATOR = Number.MAX_SAFE_INTEGER;
export const AMOUNT_FOR_FREE_LINE = Number.MAX_SAFE_INTEGER;
export const SHIPPING_TYPE_PARCEL = 'parcel';
export const SHIPPING_TYPE_QUICK = 'quick';
export const SHIPPING_TYPE_VISIT = 'visit';
export const SHIPPING_TYPE_COMBINED = 'combined';
export const SHIPPING_TYPE_PARTITION = 'partition';

export const SHIPPING_COSTS = [
  {
    date: new Date('2023-01-07 00:00:00 GMT+0900'),
    line: [AMOUNT_FOR_FREE_LINE, 3000],
    '': [AMOUNT_FOR_FREE, 3000],
    creator: [AMOUNT_FOR_FREE_CREATOR, 3000],
  },
  {
    date: new Date('2015-01-01 00:00:00 GMT+0900'),
    line: [AMOUNT_FOR_FREE_LINE, 3000],
    '': [AMOUNT_FOR_FREE, 2500],
    creator: [AMOUNT_FOR_FREE_CREATOR, 2500],
  },
];

export const ADDITIONAL_SHIPPING_COSTS = [
  {
    date: new Date('2023-08-18 00:00:00 GMT+0900'),
    fee: 3000,
  },
];
