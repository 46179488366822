export const NESS_CUSTOMER_SMS = '15667960';
export const NESS_CUSTOMER_TEL = '1566-7960';
export const NESS_PRIME_CUSTOMER_SMS = '15662180';
export const NESS_PRIME_CUSTOMER_TEL = '1566-2180';

export const nessSuremKaTalkCode = {
  usercode: 'ness1',
  deptcode: 'LS--PA-EU',
  from: NESS_CUSTOMER_SMS,
  url: 'https://api.surem.com/alimtalk/v2/json',
  yellowid_key: '?????',
};

export const nessSuremSmsCode = {
  usercode: 'ness1',
  deptcode: 'LS--PA-EU',
  from: NESS_CUSTOMER_SMS,
  url: 'https://rest.surem.com/intl/text',
};

export const nessPrimeSuremSmsCode = {
  usercode: 'ness1',
  deptcode: 'LS--PA-EU',
  from: NESS_PRIME_CUSTOMER_SMS,
  url: 'https://rest.surem.com/intl/text',
};

export const NESS_SMS_TYPE = {
  NESS_PRIME: 'NESS_PRIME',
  NESS: 'NESS',
};
