import { each, equals, filterL, go } from 'fxjs/es';
import { $findAll } from 'fxdom/es';

export const handleSingleStickerEditorSelect =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_selected = 'true')),
    );
  };

export const handleSingleStickerEditorUnselect =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_selected = 'false')),
    );
  };

export const handleSingleStickerEditorLock =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_locked = 'true')),
    );
  };

export const handleSingleStickerEditorUnlock =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_locked = 'false')),
    );
  };

export const handleSingleStickerEditorShow =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_visible = 'true')),
    );
  };

export const handleSingleStickerEditorHide =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_visible = 'false')),
    );
  };
