import { reject, selEq, strMap } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';

export const composite_template_list = (composite_templates) => legacyHtml`
  <div class="composite_template_list">
    ${strMap(
      (composite_template) => legacyHtml`
        <div
          class="composite_template neo_border"
          _sel="composite_templates->(#${composite_template.id})"
          data-is_published="${composite_template.is_published}"
        >
          <div class="composite_template_id">#${composite_template.id}</div>
          <select name="change_content_in_composite_template" id="">
            <option value="">변경할 템플릿 선택</option>
            ${strMap((ct) => legacyHtml` <option value="${ct.id}">#${ct.id}</option> `)(
              reject(selEq('id', composite_template.id))(composite_templates),
            )}
          </select>
          <div class="enter_wrapper">
            <div class="img" is_blank="${!composite_template.bg_url}">
              <img class="neo_border" style="padding:0" src="${G.to_350(composite_template.bg_url)}" alt="" />
            </div>
            <div class="title text_input" is_blank="${!composite_template.title}">
              ${composite_template.title || '타이틀이 없습니다.'}
            </div>
          </div>
          <div class="user text_input">${composite_template._.user.name}</div>
          <div class="is_done text_input">${composite_template.is_done ? '완성' : '미완성'}</div>
          <div class="no">
            <label for="">#</label>
            <input class="text_input" type="number" value="${composite_template.no}" />
          </div>
          <select name="is_public" ${composite_template.is_public ? 'disabled' : ''}>
            <option value="true" ${composite_template.is_public === true ? 'selected' : ''}>공개</option>
            <option value="false" ${composite_template.is_public === false ? 'selected' : ''}>비공개</option>
          </select>
          <button type="button" class="duplicate">복제</button>
          <button type="button" class="delete">삭제</button>
        </div>
      `,
      composite_templates,
    )}
  </div>
`;

export const composite_templates_wrapper = (composite_templates) => legacyHtml`
  <div class="composite_templates_wrapper">
    ${composite_template_list(composite_templates)}
    <div class="option">
      <button class="add">추가</button>
    </div>
  </div>
`;
