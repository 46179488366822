import { delay, go, identity, pipe, sel } from 'fxjs/es';
import { $delegate, $find, $trigger } from 'fxdom/es';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { getCurrentFcanvas, getCvObj, getCvObjLocation } from '../../Fcanvas/cv_object.js';
import {
  addFlexibleRectToFcanvas,
  downloadMapMcokupBackground,
  endAdjust,
  makeCvPrintAreaForMask,
  startAdjust,
} from './fs.js';
import { removeCvPreview } from '../cv_object.js';
import { renderFacesByCurrentBpcfsOnlySizeInMakerA } from '../../mp_maker.js';
import { control } from './event.js';
import axios from 'axios';
import {
  cvMask1CvMask2CvPrintAreaForceOff,
  cvMask1CvMask2CvPrintAreaForceOffDone,
  extractUrlSpecificCvObjBySize770,
} from '../../util.js';
import { decodePNG16bits, encodePNG16bits } from '../../../../Composite/Core/F/image_mapping.js';

export const makeCompositeEditorHtml = () => legacyHtml`
  <label for="">합성</label>
  <div class="wrapper main-wrapper" adjust>
    <div class="item row adjust" adjust-name="composite_area" key="composite_area">
      <label for="">목업 만들기</label>
      <div class="wrapper create row">
        <button class="start">시작</button>
        <button class="end">끝</button>
      </div>
    </div>
    <div class="wrapper row">
      <button type="button" class="map_download">배경 & 목업 & 맵 다운</button>
      <div class="wrapper file_upload">
        <button type="button" class="mockup_upload">맵 업로드</button>
        <input type="file" />
      </div>
      <button type="button" class="make_cv_mask2">마스크2 저장</button>
    </div>
  </div>
`;

async function saveMask() {
  $.don_loader_start();
  delay(100, identity);
  const fcanvas = getCurrentFcanvas();
  cvMask1CvMask2CvPrintAreaForceOff(fcanvas);
  const cv_print_area_for_mask = await makeCvPrintAreaForMask(fcanvas);
  const cv_preview = getCvObj(fcanvas._objects, 'cv_preview');

  await go(
    extractUrlSpecificCvObjBySize770(fcanvas, cv_preview),
    (todataurl) => $.uploadFileToUrl(todataurl, 'mask_url'),
    sel('url'),
    (url) =>
      axios.post('/@api/base_product_color_faces/update', {
        values: { mask_url: url },
        condition: { base_product_id: box().maker.product_color.base_product_id },
      }),
  );
  cv_print_area_for_mask.remove();
  removeCvPreview(fcanvas, true, true);
  fcanvas.renderAll();
  cvMask1CvMask2CvPrintAreaForceOffDone(fcanvas);

  $.don_loader_end();
}
export const makeCompositeEvent = pipe(
  $delegate('click', '.composite_editor .make_cv_mask2', async (e) => {
    try {
      const fcanvas = getCurrentFcanvas();
      fcanvas.is_preview_pass = true;
      const cv_print_area_for_mask = await makeCvPrintAreaForMask(fcanvas);
      cv_print_area_for_mask.top -= 2;
      cv_print_area_for_mask.left -= 2;
      cv_print_area_for_mask.width += 4;
      cv_print_area_for_mask.height += 4;
      cv_print_area_for_mask.visible = true;

      await go(
        extractUrlSpecificCvObjBySize770(fcanvas, cv_print_area_for_mask),
        (todataurl) => $.uploadFileToUrl(todataurl, 'mask_url'),
        sel('url'),
        (url) =>
          axios.post('/@api/base_product_color_faces/update', {
            values: { mask2_url: url },
            condition: { base_product_id: box().maker.product_color.base_product_id },
          }),
      );
      cv_print_area_for_mask.remove();
      removeCvPreview(fcanvas, true, true);
      fcanvas.renderAll();
      delete fcanvas.is_preview_pass;
      await saveMask();
      $.alert('마스크 저장 완료');
    } catch (e) {
      $.alert('실패');
    }
  }),
  $delegate('click', '[adjust-name="composite_area"] .start', (e) => {
    if (!startAdjust(e)) return;
    const fcanvas = getCurrentFcanvas();
    addFlexibleRectToFcanvas(fcanvas);
  }),
  $delegate('click', '[adjust-name="composite_area"] .end', async (e) => {
    $.don_loader_start();
    const fcanvas = getCurrentFcanvas();
    const cv_flexible_rect = getCvObj(fcanvas._objects, 'cv_flexible_rect');
    cv_flexible_rect.remove();
    fcanvas.preview.composite.area = getCvObjLocation(cv_flexible_rect);
    $.don_loader_end();
    endAdjust(e);
  }),
  $.on3('click', '.composite_editor .map_download', async (e) => {
    $.don_loader_start();
    await downloadMapMcokupBackground(getCurrentFcanvas());
    $.don_loader_end();
  }),
  $.on3('click', '.composite_editor .mockup_upload', (e) => {
    $trigger('click', go(e.currentTarget, $.closest('.wrapper'), $find('input')));
  }),
  $.on('change', '.composite_editor input[type="file"]', async function (e) {
    $.don_loader_start();

    const fcanvas = getCurrentFcanvas();
    const preview = fcanvas.preview;
    const ct = e.currentTarget;
    const file = ct.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async function (e) {
      const encoded_array = new Uint8Array(e.target.result);
      preview.composite.logo = await encodePNG16bits(encoded_array);
      preview.composite.m = await decodePNG16bits(preview.composite.logo);

      await renderFacesByCurrentBpcfsOnlySizeInMakerA();
      control(fcanvas, identity);
      $.don_loader_end();
    };
  }),
);
