export function createVertexShader({ gl, source }) {
  const shader = gl.createShader(gl.VERTEX_SHADER);
  return compileShader({ gl, shader, source });
}

export function createFragmentShader({ gl, source }) {
  const shader = gl.createShader(gl.FRAGMENT_SHADER);
  return compileShader({ gl, shader, source });
}

export function compileShader({ gl, shader, source }) {
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  if (success) {
    return shader;
  } else {
    const err_msg = gl.getShaderInfoLog(shader);
    gl.deleteShader(shader);
    throw new Error(err_msg);
  }
}

export function createWebglProgram({ gl, vertexShaderSrc, fragmentShaderSrc }) {
  return createProgram({
    gl,
    shaders: [
      createVertexShader({ gl, source: vertexShaderSrc }),
      createFragmentShader({ gl, source: fragmentShaderSrc }),
    ],
  });
}

export function createProgram({ gl, shaders }) {
  const program = gl.createProgram();
  shaders.forEach((shader) => gl.attachShader(program, shader));
  gl.linkProgram(program);

  const success = gl.getProgramParameter(program, gl.LINK_STATUS);
  if (success) {
    return program;
  } else {
    const err_msg = gl.getProgramInfoLog(program);
    gl.deleteProgram(program);
    throw new Error(err_msg);
  }
}

export function setUniforms({ gl, webglProgram, uniforms }) {
  uniforms.forEach(({ name, type, values }) => {
    gl[`uniform${type}`](gl.getUniformLocation(webglProgram, name), ...values);
  });
}

export function setupAttrBufferData({ gl, data }) {
  const buffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(data), gl.STATIC_DRAW);
  return buffer;
}

const isPowerOfTwo = (value) => (value & (value - 1)) === 0;

export function setupImageTexture({ gl, image }) {
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
  if (isPowerOfTwo(image.width) && isPowerOfTwo(image.height)) {
    gl.generateMipmap(gl.TEXTURE_2D);
  }
  return texture;
}

export function prepareRender({ gl, w, h }) {
  gl.viewport(0, 0, w, h);
  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT);
}

export function transferBufferToLocation({ gl, webglProgram, attrName, arrayBuffer, size }) {
  const location = gl.getAttribLocation(webglProgram, attrName);
  gl.enableVertexAttribArray(location);
  gl.bindBuffer(gl.ARRAY_BUFFER, arrayBuffer);
  gl.vertexAttribPointer(location, size, gl.FLOAT, false, 0, 0);
}

export function renderWebgl({ gl, points }) {
  gl.drawArrays(gl.TRIANGLES, 0, points);
}

export function getRectangle({ w, h }) {
  const { x1, x2, y1, y2 } = { x1: 0, x2: w, y1: 0, y2: h };
  const positions = [
    [x1, y1],
    [x2, y1],
    [x1, y2],
    [x1, y2],
    [x2, y1],
    [x2, y2],
  ];
  return positions.flat();
}
