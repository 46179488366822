const MP_NAVER_KEY = 's_bf44c7098c8';

export const bizGoodsTalk = () => {
  if (typeof G.naver_wcslog == 'function') {
    G.naver_wcslog('4', '1');
  }
};

// export const sendMpEventClickBizApplicationSubmit = () => {
//   // 미래아이엔씨 대행사 요청으로 해당 이벤트를 add_to_cart로 대체
//   // 지금은 안쓰는 상태
//   if (typeof G.naver_wcs_trans == 'function') {
//     G.naver_wcs_trans({
//       key: MP_NAVER_KEY,
//       type: 'inquiry',
//       value: '1',
//     });
//   }
// };

export const sendMpPurchase = ({ id, value, items }) => {
  if (typeof G.naver_wcs_trans == 'function') {
    G.naver_wcs_trans({
      key: MP_NAVER_KEY,
      id: `${id}`,
      type: 'purchase',
      value: `${value}`,
      items,
    });
  }
};

export const sendMpViewProduct = ({ items }) => {
  if (typeof G.naver_wcs_trans == 'function') {
    G.naver_wcs_trans({
      key: MP_NAVER_KEY,
      type: 'view_product',
      items,
    });
  }
};

export const sendMpAddToCart = ({ value }) => {
  if (typeof G.naver_wcs_trans == 'function') {
    G.naver_wcs_trans({
      key: MP_NAVER_KEY,
      type: 'add_to_cart',
      value: `${value}`,
    });
  }
};
