import { find, go } from 'fxjs/es';
import { getBaseProductInMaker } from '../../../../Maker/F/getSth.js';
import { NewMakerCheckConditionConstantS } from '../../S/Constant/module/NewMakerCheckConditionConstantS.js';
import { NewMakerCheckConditionF } from './module/NewMakerCheckConditionF.js';
import { $qs } from 'fxdom/es';
import {
  checkCvObjInAmbiguousArea,
  getMaterialForCheckCvObjInAmbiguousArea,
} from './checkCvObjInAmbiguousArea.js';

export const is_condition_pass = async (type, cv_target) => {
  switch (type) {
    case NewMakerCheckConditionConstantS.CONFIRM_POPUP_QUALITY_TYPE:
      return !NewMakerCheckConditionF.findSmallerCvImageThanRequirement(cv_target);
    case NewMakerCheckConditionConstantS.CONFIRM_POPUP_FULL_FILL_TYPE:
      return NewMakerCheckConditionF.isFullFillImage(cv_target);

    case NewMakerCheckConditionConstantS.CONFIRM_POPUP_FOLDING_TYPE:
      return NewMakerCheckConditionF.isParallelCheckPass(cv_target, 0.3);

    case NewMakerCheckConditionConstantS.CONFIRM_POPUP_ANY_CORNER_OUT_BOUNDS:
      return NewMakerCheckConditionF.isAnyCornerOutOfBounds(cv_target);

    case NewMakerCheckConditionConstantS.CONFIRM_POPUP_ANY_CORNER_OUT_BOUNDS_FULL_FILL_TYPE: {
      const ambiguous_material = getMaterialForCheckCvObjInAmbiguousArea();
      return ambiguous_material
        ? !(await checkCvObjInAmbiguousArea(ambiguous_material, cv_target))
        : (await NewMakerCheckConditionF.isAnyCornerOutOfBounds(cv_target)) ||
            NewMakerCheckConditionF.isFullFillImage(cv_target);
    }
    default:
      return true;
  }
};

export const isConfirmPopupMapping = () => {
  if ($qs('#dream_factory')) return;
  const confirm_popups = getBaseProductInMaker()._?.confirm_popups;
  return !!confirm_popups?.length;
};

export const is_full_fill_or_out_bounds = () => {
  const confirm_popups = getBaseProductInMaker()._?.confirm_popups;
  if (!confirm_popups?.length) return;
  return go(
    confirm_popups,
    find(
      (popup) =>
        popup.type === NewMakerCheckConditionConstantS.CONFIRM_POPUP_FULL_FILL_TYPE ||
        popup.type === NewMakerCheckConditionConstantS.CONFIRM_POPUP_ANY_CORNER_OUT_BOUNDS ||
        popup.type === NewMakerCheckConditionConstantS.CONFIRM_POPUP_ANY_CORNER_OUT_BOUNDS_FULL_FILL_TYPE,
    ),
    ({ type }) => type,
  );
};
