import {
  getBaseProductColorFacesInMaker,
  getFcanvass,
  getItsSf,
  getSelfCanvasZoomWrapper,
} from '../getSth.js';
import { createFcanvas } from '../util.js';
import { find, go, sel } from 'fxjs/es';
import { resetBgWithoutCvMaskBySf, resetCvMaskBySf } from '../SizeFaces/fs.js';
import { makeCvBpcfInMakerA } from '../CvBpcf/fs.js';
const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;
const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

function cvObjs(canvas) {
  return canvas ? canvas._objects : [];
}

function findCvBpcfVideo(fcanvas) {
  return find(function (obj) {
    return obj._data.cv_type == 'cv_bpcf_video';
  }, cvObjs(fcanvas));
}
function findCvBpcfVideoMask(canvas) {
  return find(function (obj) {
    return obj._data.cv_type == 'cv_bpcf_video_mask';
  }, cvObjs(canvas));
}

export const hasVideoUrl = function () {
  return !!getBaseProductColorFacesInMaker()[0].video_url;
};

function makeCvBpcfVideo(canvas, bpcf_video_url, sf) {
  /*비디오 하나일때, 면이 하나일때만 올바르게 동작*/
  const last_cv_bpcf_video = findCvBpcfVideo(canvas);
  if (last_cv_bpcf_video) canvas.remove(last_cv_bpcf_video);
  const video = $1('#marpple_video');

  const cv_bpcf_video = new fabric.Image(video, {
    visible: false,
    objectCaching: false,
    selectable: false,
    evented: false,
    globalCompositeOperation: 'destination-over',
    _data: { cv_type: 'cv_bpcf_video', is_not_design: true },
  });
  canvas.add(cv_bpcf_video);
  resetBgWithoutCvMaskBySf(cv_bpcf_video, sf);
  if (!bpcf_video_url) return;
  if (G.mp.maker.is_ie || !video || !video.play || !requestAnimationFrame) return;
  video.src = bpcf_video_url + G.mp.maker.cross_origin_query_str;
  playCvBpcfVideoAni();
}

function makeCvBpcfVideoMask(canvas, bpcf_mask2_url, sf) {
  const last_cv_bpcf_video_mask = findCvBpcfVideoMask(canvas);
  if (last_cv_bpcf_video_mask) canvas.remove(last_cv_bpcf_video_mask);
  return new Promise(function (resolve) {
    fabric.Image.fromURL(G.mp.maker.url(bpcf_mask2_url), function (img) {
      img.set({
        selectable: false,
        evented: false,
        globalCompositeOperation: 'destination-in',
        _data: { cv_type: 'cv_bpcf_video_mask', is_not_design: true },
      });
      img.set({ visible: !!bpcf_mask2_url });
      canvas.add(img);
      resetCvMaskBySf(img, sf);
      canvas.renderAll();
      resolve();
    });
  });
}

function disposeVideo() {
  const cv_bpcf_video_data = sel('_cv_bpcf_video_data', $1('#marpple_video'));
  if (!cv_bpcf_video_data) return;
  if (cv_bpcf_video_data.fcanvas) {
    cancelCvBpcfVideoAni();
    const fcanvas = cv_bpcf_video_data.fcanvas;
    $.prepend(fcanvas.wrapperEl, fcanvas.lowerCanvasEl);
    fcanvas.dispose();
    cv_bpcf_video_data.fcanvas = null;
  }
}

async function initVideo(bpcf, sf, f_canvas) {
  const f_canvas_for_video = createFcanvas();
  const zoom_wrapper = getSelfCanvasZoomWrapper(f_canvas);
  go(f_canvas_for_video.lowerCanvasEl, $.prepend_to(zoom_wrapper));
  $1('#marpple_video')._cv_bpcf_video_data = {
    raf: null,
    fcanvas: f_canvas_for_video,
  };
  await makeCvBpcfInMakerA(f_canvas_for_video, bpcf, sf);
  await makeCvBpcfVideo(f_canvas_for_video, bpcf.video_url, sf);
  await makeCvBpcfVideoMask(f_canvas_for_video, bpcf.mask2_url, sf);
  const cv_bpcf = G.mp.maker.cv_bpcf(f_canvas_for_video);
  cv_bpcf.bringToFront();
}

export async function dealWithVideoUrl() {
  if (G.mp.maker.is_ie) return;
  disposeVideo();
  if (hasVideoUrl()) {
    const f_canvas = getFcanvass()[0];
    G.mp.maker.cv_bpcf(f_canvas).visible = false;
    const bpcf = getBaseProductColorFacesInMaker()[0];
    const sf = getItsSf(f_canvas);
    await initVideo(bpcf, sf, f_canvas);
  }
}
export function cancelCvBpcfVideoAni() {
  const cv_bpcf_data = sel('_cv_bpcf_video_data', $1('#marpple_video'));
  if (!cv_bpcf_data) return;
  if (cv_bpcf_data.raf) {
    cancelAnimationFrame(cv_bpcf_data.raf);
    cv_bpcf_data.raf = null;
  }
}
export function playCvBpcfVideoAni() {
  const video = $1('#marpple_video');
  const cv_bpcf_video_data = sel('_cv_bpcf_video_data', video);
  if (!cv_bpcf_video_data) return;
  if (cv_bpcf_video_data.raf) return;
  try {
    const fcanvas = cv_bpcf_video_data.fcanvas;
    const cv_bpcf_video = findCvBpcfVideo(fcanvas);
    video
      .play()
      .then(() => {
        cv_bpcf_video.visible = true;
        requestAnimationFrame(function render() {
          if (G.mp.maker.active()) {
            video.pause();
          } else {
            video.play().catch(() => '');
            fcanvas.renderAll();
          }
          cv_bpcf_video_data.raf = requestAnimationFrame(render);
        });
      })
      .catch(() => {});
  } catch (e) {}
}
