import { strMap, html, go } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const makeSelectQuantityHtml = ({ quantity, quantities, max, min }) => html`
  <div class="input_wrap mp-select-product-quantity">
    <select name="quantities" id="">
      ${go(
        quantities.sort((a, b) => a - b),
        strMap(
          (_quantity) => html`
            <option value="${_quantity}" ${_quantity === quantity ? 'selected' : ''}>
              ${UtilS.commify(_quantity)}${UtilS.translateUnit('개')}
            </option>
          `,
        ),
      )}
    </select>
    <input
      type="number"
      class="quantity"
      value="${quantity}"
      min="${min}"
      max="${max}"
      inputmode="numeric"
      pattern="[0-9]*"
      style="display: none;"
    />
  </div>
`;
