import format from 'date-fns/format/index.js';
import { go, html, range, strMap } from 'fxjs/es';
import { makeDfProjectionItemWithoutTaskHtml } from '../../../../Projection/List/F/tmpl.js';
import { DfTaskF } from '../../../F/Function/module/DfTaskF.js';
import { DfTaskTmplS } from '../../../S/Tmpl/module/DfTaskTmplS.js';

function select_time(real_time) {
  const twenty_four = range(0, 48);
  const h = moment(real_time).format('HH');
  const m = parseInt(moment(real_time).format('mm')) / 30 >= 1 ? '30' : '00';
  const time = real_time && h + ':' + m;
  return html`
    <select class="time">
      <option ${!time ? 'selected' : ''}>미정</option>
      ${go(
        twenty_four,
        strMap((hour) => {
          const is_half = hour % 2 == 1;
          const d = new Date();
          const h = Math.floor(hour / 2);
          d.setHours(Math.floor(hour / 2));
          d.setMinutes(is_half ? 30 : 0);
          const t = format(d, 'HH:mm');
          return html`<option value="${t}+0900" ${time == t ? ' selected' : ''}>
            오${h >= 12 ? '후' : '전'} ${h}시${d.getMinutes() ? ` ${d.getMinutes()}분` : ''}
          </option>`;
        }),
      )}
    </select>
  `;
}
export function main_task_detail_tmpl(task, user, sub_tab) {
  return pug`
      .wrapper[_sel="task" is_draft="${task.is_draft}" is_hidden="${task.is_hidden}"]
        .head
          .close 닫기
          .title ${task.is_hidden ? '[삭제]' : ''} 태스크 상세보기 - #${
    task.id
  }<span id="task_save_state"></span>
          .cancel[type="is_canceled" val="${task.is_canceled}"] ${
    task.is_canceled ? '태스크 복구' : '태스크 취소'
  }
          .delete[type="is_hidden" val="${task.is_hidden}"] ${task.is_hidden ? '태스크 복구' : '태스크 삭제'}
          .create 태스크 생성
        .body_wrapper
          .task_body[task_id="${task.id}" is_canceled="${task.is_canceled}" is_hidden="${
    task.is_hidden
  }" status="${task.status}"]
            .head
              .title
                label 작업명 &nbsp:&nbsp ${task.title || '작업명 없음'}
              .status.content[type="status"]
                label 상태
                select[${
                  task.type != 'as' && [/*'before_confirm', */ 'confirmed'].includes(task._.projection.status)
                    ? 'disabled'
                    : ''
                }]
                  option[value="ready" ${task.status == 'ready' && 'selected'}] 진행전
                  option[value="on" ${task.status == 'on' && 'selected'}] 진행중
                  option[value="completed" ${task.status == 'completed' && 'selected'}] 완료
            .wrapper
              .rowm.margin_bottom
                .title.content[type="title"]
                  label.label 작업명
                  input.input[type="text" value="${task.title || ''}" placeholder="작업명을 입력하시오" ${
    task.parent_id && user.id !== task.worker_id ? 'disabled' : ''
  }]
                  .option
                    button.save[type="button" ${
                      task.parent_id && user.id !== task.worker_id ? 'disabled' : ''
                    }] ${task.is_draft ? '작업명 저장' : '작업명 변경'}
                    button.cancel[type="button"] 취소
                .content.press_type[type="press_type_id"]
                  label.label 인쇄방식
                  .press_type_select[_sel="press_types" current_value="${
                    task.press_type_id
                  }" value_key="id" name_key="name" select_name="press_type_id" el_class="press_type_select_ui" el_id="press_type_select_ui"]
                    .selected ${_p.val(task, '_.press_type.name') || '선택 안함'}
              .rowm.margin_bottom
                .attached_file_wrap
                  .attached_file.content[type="attached_file"]
                    label.label 파일 첨부
                    input.label_content[type="file" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, image/*, .pdf, application/postscript"]
                  .files[has_item=${
                    !!_go(task, _p.v('_.files'), _p.first, _p.v('attached_id')) || false
                  } id="files"]
                    label.label 첨부된 파일
                    ul.file_list.label_content2
                      ${_p.sum(
                        task._.files,
                        (file) => pug`
                        li.file_content.file[_sel="./_->files->(#${file.id})"]
                          a[href="${file.ai_url || file.url}" download="${file.original_name}"] ${
                          file.original_name
                        }
                          button.remove[type="button"] 삭제
                      `,
                      )}
              
                ${
                  (task.type === 'printing' && task.title !== '라벨부착') ||
                  (task.type === 'before_deliver' && /(한진|배송)+/.test(task.title))
                    ? pug`
                    .rowm.margin_bottom
                      .product_position_status.content[type="product_position_status"]
                        label.label 제품 입출고
                        .body.label_content
                          select
                            option[value="NONE" ${task.product_position_status === 'NONE' && 'selected'}] 
                            option[value="IMPORT" ${
                              task.product_position_status === 'IMPORT' && 'selected'
                            }] 제품입고
                            ${
                              task.type === 'printing'
                                ? pug`
                              option[value="EXPORT" ${
                                task.product_position_status === 'EXPORT' && 'selected'
                              }] 제품출고
                              option[value="EXPORT_TO_DELIVERY" ${
                                task.product_position_status === 'EXPORT_TO_DELIVERY' && 'selected'
                              }] 제품 배송팀으로 출고`
                                : ''
                            }
                      .product_position_status_changed_at.content[type="product_position_status_changed_at"]
                        label.label 입출고 일자
                        .body.label_content
                          ${
                            task.product_position_status_changed_at
                              ? moment(task.product_position_status_changed_at).format('YYYY-MM-DD, a h:mm')
                              : ''
                          }`
                    : ''
                }
                
              .rowm.margin_bottom
                .manager.worker[key="worker_id"].content.task_detail_inline_block
                  label.name.label 담당자
                  .body.label_content
                    .add
                      .manager_blank ${_p.v(task, `_.worker.name`) || '담당자 선택'}
                    .option ...
                .manager.admin[key="admin_id"].content.task_detail_inline_block
                  label.name.label 서브 담당자
                  .body.label_content
                    .add
                      .manager_blank ${_p.v(task, `_.admin.name`) || '담당자 선택'}
                    .option ...
              .rowm.margin_bottom
                .start_at.content[type="start_at"]
                  label.label 시작일자
                  .date_time
                    input[type="date" value="${moment(task.start_at || new Date()).format(
                      'YYYY-MM-DD',
                    )}"].date
                    ${select_time(task.start_at)}
              .rowm
                .due_at.content[type="due_at"].task_detail_inline_block
                  label.label 기한
                  .date_time.label_content ${
                    task.due_at ? moment(task.due_at).format('YYYY-MM-DD, a h:mm') : '-'
                  }
                .original_due_at.content[type="due_at"].task_detail_inline_block
                  label.label 실제 기한
                  .date_time.label_content ${
                    task.original_due_at ? moment(task.original_due_at).format('YYYY-MM-DD, a h:mm') : '-'
                  }
              .rowm
                .completed_at.content[type="completed_at"].task_detail_inline_block
                  label.label 완료 날짜
                  .date_time.label_content ${
                    task.completed_at ? moment(task.completed_at).format('YYYY-MM-DD, a h:mm') : '-'
                  }
                .created_at.content[type="created_at"].task_detail_inline_block
                  label.label 생성 날짜
                  .date_time.label_content ${
                    task.created_at ? moment(task.created_at).format('YYYY-MM-DD, a h:mm') : '-'
                  }
              .rowm.margin_bottom
                .assigned_at.content[type="assigned_at"].task_detail_inline_block
                  label.label 담당자 할당 날짜
                  .date_time.label_content ${
                    task.assigned_at ? moment(task.assigned_at).format('YYYY-MM-DD, a h:mm') : '-'
                  }
              .rowm.margin_bottom
                .remover.content.task_detail_inline_block
                  label.label 최근 삭제한 사람
                  .label_content ${task._.remover.name}
                .task_creator.content.task_detail_inline_block
                  label.label 태스크 생성자
                  .label_content ${task._.user.name}
              .rowm.margin_bottom
                .body.content[type="body"]
                  label.label 내용
                  textarea.input ${task.body || ''}
                  .option
                    button.save[type="button"] 저장
                    button.cancel[type="button"] 취소
            .related_tasks.content.task_create_possible[prj_id="${task._.projection.id}"]
              ${DfTaskF.t_prj_tasks(task._.projection._.tasks)}
          .option[is_show="${sub_tab}" style="display: none;"]
            .tab
              .tab_issue_list[key="issue_list"] 이슈
              .tab_projection[key="projection"] 주문서
              .tab_scanner_setting[key="scanner_setting"] 스캔 설정
              ${
                user._.policies.master_admin
                  ? html`
                      <div class="tab_tasks_log" key="task_logs" data-task_id="${task.id}">기록 확인</div>
                    `
                  : ''
              }
            .issue_list.body[type_id="${task.id}" type="task"]
              .wrapper
                .head
                  .btn.issue_create 이슈 생성
                .body.issues
                  ${_p.sum(
                    task._.issues,
                    G.df.issue.t_issue((task_id) => `task->_->issues->(#${task_id})`, user),
                  )}
            .projection.body[prj_product_mode="${box.sel('prj_product_mode') || 'up_list'}"]
              .df_grid.prj_item.prj[prj_id="${
                task._.projection.id
              }" _sel="./_->projection" is_seller_support="${[
    'SUPPORT_SELLER_M_SGN',
    'NO_PRINT_NO_PROFIT_M_SGN',
  ].includes(task._.projection.promotion_code)}"]
                ${makeDfProjectionItemWithoutTaskHtml(task._.projection)}
            .scanner_setting
              .body[is_show="task_auto_complete"]
                ${DfTaskTmplS.t_prj_task_parent_id(task.parent_id, task.press_type_id, task.title)}
            ${
              user._.policies.master_admin
                ? html`
                    <div class="task_logs">
                      <table>
                        <thead>
                          <th>날짜</th>
                          <th>상태</th>
                          <th>이름</th>
                          <th>직원번호</th>
                          <th>로우데이터</th>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  `
                : ''
            }
    `;
}
