import { format } from 'date-fns';
import { find, go, html, range, sel, strMap } from 'fxjs/es';
import { GoodsTypeS } from '../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { UtilImageS } from '../../../Util/Image/S/Function/module/UtilImageS.js';
import { makeReviewListScoreHtml } from '../../List/S/tmpl.js';

const makeCommentPlaceholder = ({ is_creator, order_point, seller_point, goods_type_id }) => {
  if (GoodsTypeS.isNotPod(goods_type_id)) {
    return T('review::리뷰를 입력해 주세요. 자세한 리뷰는 크리에이터에게 큰 응원이 됩니다.', {
      order_point,
    });
  }
  if (is_creator) {
    return T('review::Get MarppleShop points for both you and your seller by writing a review.', {
      order_point,
      seller_point,
    });
  }
  if (G.collabo_type == '') {
    return T('review::Write a review for this item.');
  }
  return T('review::Write a review for this item.2');
};

export const makeReviewWriteHtml = (writing_review) => {
  const order_point = '300';
  const seller_point = '1,000';
  const is_creator = writing_review.collabo_type == 'creator';
  const color_name = writing_review.user_product._.product_color._.base_product_color['name' + G._en];
  const { goods_type_id } = writing_review.user_product;

  return html`
    <form
      action="/${T.lang}/@api/review/write${GoodsTypeS.isPod(goods_type_id) ? '' : '/pb'}"
      id="write_review_form"
      method="post"
      _sel="writing_review"
    >
      <h1>${is_creator ? T('review::Support your Sellers') : T('review::Write a Review')}</h1>
      <div class="upc_wrap">
        <div class="upc_img canvas_75">
          ${GoodsTypeS.isPod(goods_type_id)
            ? html`<canvas></canvas>`
            : html`<div class="img-wrap">
                <img
                  src="${UtilImageS.getResizedUrl({
                    width: 150,
                    url: go(
                      sel('_.product_color.thumbnails.value', writing_review.user_product),
                      find(sel('is_thumb')),
                      sel('url'),
                    ),
                    quality: 60,
                  })}"
                  alt=""
                />
              </div>`}
        </div>
        <div class="ordered_at ${writing_review.ordered_at ? '' : 'none'}">
          ${writing_review.ordered_at ? format(new Date(writing_review.ordered_at), 'yyyy-MM-dd HH:mm') : ''}
          <span>No.${writing_review.id}</span>
        </div>
        <div class="name">
          ${writing_review.user_product._.product_color['name' + G._en] ||
          writing_review.user_product._.product_color._.base_product.name}
          ${color_name
            ? `<span class="color"
            >[${color_name}]</span
          >`
            : ''}
        </div>
        <label>
          <input type="hidden" name="user_product_id" value="${writing_review.user_product.id}" />
        </label>
      </div>
      <div class="form_wrap score_wrap">
        <div class="header">
          <h3>${T('review::Select Points')}</h3>
        </div>
        <div class="body">
          <label>
            <select name="score">
              ${strMap(
                (i) => html` <option value="${i}" rate="${i}" ${i == 5 ? 'selected' : ''}>${i}</option> `,
                range(5, 0, -1),
              )}
            </select>
            <span class="review_score">${makeReviewListScoreHtml(5)}</span>
          </label>
        </div>
      </div>
      <div class="form_wrap comment_wrap">
        <div class="header">
          <h3>${is_creator ? T('review::Support your sellers with a review.') : T('review::Review')}</h3>
          ${is_creator
            ? ''
            : html`
                <div class="min_txt_count">
                  <span>
                    <span>${T('review::Minimum of 5 words')}</span>
                  </span>
                </div>
              `}
        </div>
        <div class="body">
          <label>
            <textarea
              name="comment"
              placeholder="${makeCommentPlaceholder({
                goods_type_id,
                is_creator,
                order_point,
                seller_point,
              })}"
            ></textarea>
          </label>
          <div class="file_upload_wrap">
            <div id="before_files" class="swiper-container" _sel="./before_files">
              <ul class="swiper-wrapper">
                <li id="review_file_upload" class="swiper-slide">
                  <label>
                    <input
                      type="file"
                      name="file"
                      accept="image/jpeg,image/png,image/pjpeg,image/gif"
                      multiple
                    />
                    <span></span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="is_kr none_for_line" lang="${T.lang}">
        <div class="form_wrap sns_url_wrap">
          <div class="header">
            <h3>SNS 공유</h3>
            <div class="sns_info">
              ${is_creator
                ? html`
                    셀러의 SNS <span>@아이디</span>와 <span>#마플샵</span>을 태그한 리뷰를 함께 올려보세요!
                    당신의 응원이 크리에이터의 작품 활동에 도움이 되어요.
                  `
                : html`
                    본인의 SNS에. <span>#내가만듦</span><span>#마플</span>
                    해시태그와 함께 내용+사진 후기를 올려주시면 선정 후 구매금액 상관없이 500P 적립!
                    <br />
                    (해시태그가 없거나 잘못된 링크는 포인트 적립이 어렵습니다. ^^;)
                  `}
            </div>
          </div>
          <div class="body">
            <label>
              <input type="text" name="sns_url" placeholder="SNS 링크를 입력해 주세요." />
            </label>
          </div>
        </div>
      </div>
      <div class="write_footer">
        <div class="clause is_kr none_for_line">
          ${T.lang == 'kr'
            ? html`<label>
                <input type="checkbox" id="clause" name="is_agreement" />
                <span is_checked="false"
                  >작성하신 리뷰는 마플${is_creator ? '샵' : ''} 홍보 콘텐츠로 활용될 수 있습니다.
                  (필수)</span
                >
              </label> `
            : html` <label>
                  <input type="checkbox" id="clause" name="is_agreement" checked />
                  <span is_checked="true">${T('review::Agree to terms of review')}</span>
                </label>
                <span class="clause_view">${T('review::view')}</span>`}
        </div>
        <div class="buttons">
          <button id="write" type="button">${T('review::Post Review')}</button>
        </div>
      </div>
    </form>
    <div class="thankyou">
      <div class="thanks">
        <h2>
          <div>
            <img
              src="//s3.marpple.co/files/u_29089/2018/12/original/f_18091_1545422586625_yqqx6XU3TMrKZg8i6lq.png"
              alt=""
            />
          </div>
          <div>${T('review::Thank you for your comment')}.</div>
        </h2>
      </div>
      <button class="continue">${T('review::Continue Shopping')}</button>
    </div>
  `;
};

export const makeReviewWriteImgListHtml = (before_file) => html`
  <li class="before_file swiper-slide" _sel="./(bf=>bf.id=='${before_file.id}')">
    <div class="img">
      <img src="${before_file.url || ''}" alt="" width="78" height="78" />
    </div>
    <div class="remove">
      <button class="remove_before_file" type="button">x</button>
    </div>
  </li>
`;

function getReviewCluaseTitle() {
  if (T.lang == 'kr') {
    if (G.collabo_type == '') return '마플';
    if (G.collabo_type == 'line') return '라인프렌즈 크리에이터';
  } else {
    if (G.collabo_type == '') return 'Marpple';
    if (G.collabo_type == 'line') return 'Linefridens Creator';
  }
}

function getReviewCluaseDate() {
  if (T.lang == 'kr') {
    if (G.collabo_type == '') return '2018년 4월 24일';
    if (G.collabo_type == 'line') return '2019년 9월 19일';
  } else {
    if (G.collabo_type == '') return 'April 24 in 2018';
    if (G.collabo_type == 'line') return '2019-09-19';
  }
}
export const makeReviewWriteClauseHtml = () =>
  T.lang == 'kr'
    ? html`
        <div class="review_clause">
          <div class="review_clause_wrap">
            <h2>${getReviewCluaseTitle()} 리뷰 수집 및 사용 동의</h2>
            <p>
              ${getReviewCluaseTitle()}은 다음의 목적을 위해 사용자가 ${getReviewCluaseTitle()} 홈페이지에
              올린 리뷰를 제 가공 혹은 전문 그대로 컨텐츠로 활용할 수 있습니다.
            </p>
            <div class="article">
              <h3>1. 정보 수집, 이용 목적</h3>
              <p>
                1. 서비스 품질 향상 및 개선<br />2-1. 특정 다수에게 노출되는 컨텐츠 광고의 활용<br />2-2. 특정
                다수에게 노출되는 컨텐츠 광고로 활용할 경우에는 리뷰에 적은 본문 및 업로드한 사진, 연동한 SNS
                게시글의 사진 (연동된 게시글에 한함), ${getReviewCluaseTitle()}에 가입된 닉네임 및 이름
                정보만을 활용합니다.
              </p>
            </div>
            <div class="article">
              <h3>2. 고유 식별 정보 수집 동의</h3>
              <p>
                ${getReviewCluaseTitle()}은 이를 활용할 때 서면의 방식으로 (문자, 이메일 등) 사용자에게 사용
                관련한 정보와 그 범위를 공개하며 이에 대해 서면으로 연락하기 위해 가입시 받은 정보인 전화번호,
                이메일을 추가적으로 활용합니다. 수집된 고유 식별 정보는 해당 목적이외의 용도로는 이용되지
                않습니다.
              </p>
            </div>
            <div class="article">
              <h3>3. 이용 범위 및 사용 기간</h3>
              <p>
                1. 이용 범위 : SNS 및 비디오 매체, 플랫폼, 커뮤니티 및 재배포 가능한 인터넷망 사이트<br />2.
                이용 불가 범위 : 지면 광고 및 기타 인쇄를 통한 광고물, 지상파 및 케이블 방송<br />3. 이용 기간
                : 회사가 해당 광고에 대해 삭제하는 시기까지
              </p>
            </div>
            <div class="article">
              <h3>4. 초상권 사용 동의</h3>
              <p>
                ${getReviewCluaseTitle()}은 해당 리뷰를 위와 같은 이용 목적을 위해 이용 범위와 사용 기간을
                설정하고 해당 리뷰에 대해 사용하며 초상권은 홍보매체 활용 이외의 용도로는 이용, 제공되지
                않습니다.
              </p>
            </div>
            <div class="article">
              <h3>5. 소비자 권익 보호</h3>
              <p>
                해당 컨텐츠가 불합리적인 사회적 문제나 공공의 이익을 해치는 컨텐츠가 되거나 문제가 발생할 경우
                ${getReviewCluaseTitle()}은 이에 대해 인지하고 해당 컨텐츠를 삭제 처리합니다.
              </p>
            </div>
            <div class="article">
              <h3>6. 동의 방식</h3>
              <p>소비자는 리뷰를 올림으로써 해당 약관에 동의한것으로 확인합니다</p>
            </div>
            <div class="article">
              <h3>7. 시행일</h3>
              <p>
                본 동의약관은 별도의 ${getReviewCluaseTitle()} 이용약관을 개정하지 않는 개별 약관으로
                ${getReviewCluaseDate()} 공표, 시행합니다.
              </p>
            </div>
          </div>
          <div class="footer">
            <button type="button">확인</button>
          </div>
        </div>
      `
    : html`
        <div class="review_clause">
          <div class="review_clause_wrap">
            <h2>Agreement on the Collection and Use of ${getReviewCluaseTitle()} Reviews</h2>
            <p>
              ${getReviewCluaseTitle()} can use reviews posted on the website as it is or reprocess them for
              the following purposes:
            </p>
            <div class="article">
              <h3>1.Information collection and purpose of use</h3>
              <p>
                1. Improving service quality<br />2-1. Leverage the content as an advertisement released to a
                specific number of people <br />2-2. When used as an advertisement to a specific number of
                people; photos uploaded on review page, photos from linked SNS postings (linked postings
                only), website nicknames and name information that are registered on ${getReviewCluaseTitle()}
                are going to be utilized.
              </p>
            </div>
            <div class="article">
              <h3>2. Agreement on collection of unique identification information</h3>
              <p>
                ${getReviewCluaseTitle()} shares the information and extent of review use to users in written
                form, and thus use contact information (e.g. phone no. and e-mail) received at the time of
                subscription to contact you. Unique identification information collected will not be used for
                any other purposes than that.
              </p>
            </div>
            <div class="article">
              <h3>3. Scope and duration of use</h3>
              <p>
                1. Scope of use: Social media channels and video media, platforms, online communities, and
                redistributable Internet network sites<br />2. Unavailable scope: Printed medias and broadcast
                on cable and terrestrial channels<br />3. Period of use: Until the time when the company
                deletes the content.
              </p>
            </div>
            <div class="article">
              <h3>4. Agreement on the use of portrait rights</h3>
              <p>
                ${getReviewCluaseTitle()} sets the scope and the duration of the use and the utilization of
                the reviews for the purpose written above. Portrait rights are not used or provided for other
                purposes than the use on the promotional media.
              </p>
            </div>
            <div class="article">
              <h3>5. Consumer rights protection</h3>
              <p>
                If the content becomes an unreasonable social issue or harms the public interest,
                ${getReviewCluaseTitle()} recognizes and deletes the content immediately.
              </p>
            </div>
            <div class="article">
              <h3>6. Consent method</h3>
              <p>
                The consumers confirm that one has agreed to the terms and conditions by uploading the review.
              </p>
            </div>
            <div class="article">
              <h3>7. Effective Date</h3>
              <p>
                These terms and conditions are announced and enforced on ${getReviewCluaseDate()} as
                individual terms that do not amend the separate Terms and Conditions of
                ${getReviewCluaseTitle()}.
              </p>
            </div>
          </div>
          <div class="footer">
            <button type="button">Confirm</button>
          </div>
        </div>
      `;
