import { changeCvObjectToImgSrc } from '../../../../../modules/Maker/F/draw_product_faces.js';
import { checkIsOverflowOfObjInPrintArea } from '../../../../../modules/Maker/F/overflow.js';
import { NewMakerMenuF } from '../../../../../modules/NewMaker/Menu/F/Function/module/NewMakerMenuF.js';
import { NewMakerCheckerF } from '../../../../../modules/NewMaker/Checker/F/Function/module/NewMakerCheckerF.js';
import { NewMakerCallConditionPopupF } from '../../../../../modules/NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';

(function () {
  let pinch_obj = null;
  function ang(source_angle) {
    const angle_360 = source_angle % 360;
    const angle_45 = Math.abs(angle_360) % 45;
    const is_minus = source_angle < 0;
    let angle;
    if (angle_45 <= 3) {
      var a = is_minus ? Math.ceil(angle_360 / 45) : Math.floor(angle_360 / 45);
      angle = a * 45;
    } else if (angle_45 >= 42) {
      var a = is_minus ? Math.ceil(angle_360 / 45) - 1 : Math.floor(angle_360 / 45) + 1;
      angle = a * 45;
    } else {
      angle = angle_360;
    }
    return is_minus ? 360 + angle : angle;
  }
  // const type = ['cv_text', 'cv_pattern'];

  // function has_not_expandable_obj(obj, arr) {
  //   if (!obj.v || !obj.v._data) return;
  //   obj.v._objects
  //     ? _each(
  //         obj.v._objects,
  //         __(function (v) {
  //           return { v: v, scaleX: v.scaleX * obj.scaleX };
  //         }, _(has_not_expandable_obj, _, arr)),
  //       )
  //     : _.contains(type, obj.v._data.cv_type)
  //     ? arr.push(obj)
  //     : undefined;
  //   return arr;
  // }

  function pinch_mode() {
    let INIT_SCALE = 1;
    let angle;
    let canvas, selected, is_embro;
    let pinch_x = 0;
    let pinch_y = 0;
    // let pass = false;
    // let not_expandable_obj = null;
    let is_pinch_called = false;
    let is_acting = false;
    // const not_expandabel_obj_width = G.mp.maker.CANVAS_WIDTH;
    // const not_expandabel_obj_height = G.mp.maker.CANVAS_HEIGHT;

    const pinch_selected_ani = G.mp.maker.has_active_object_or_group(function (canvas, selected) {
      const ani_man = __(
        _p.rest,
        _p.find(function (v) {
          return new Promise(function (next) {
            setTimeout(function () {
              if (is_pinch_called) next(true);
              try {
                const point = new fabric.Point(selected.getCenterPoint().x, selected.getCenterPoint().y);
                selected.scale(v).setCoords();
                selected.setPositionByOrigin(point, 'center', 'center');
                canvas.renderAll();
                next(false);
              } catch (e) {
                next(false);
              }
            }, 10);
          });
        }),
      );
      let scale = selected.scaleX;
      return _p.go(
        _p.range(8),
        _p.map(function (v, i) {
          return (scale *= 0.005 * i + 1);
        }),
        _p.tap(ani_man),
        _p.method('reverse'),
        _p.tap(_p.initial, ani_man),
        _p.last,
        function (v) {
          const point = new fabric.Point(selected.getCenterPoint().x, selected.getCenterPoint().y);
          selected.scale(v).setCoords();
          selected.setPositionByOrigin(point, 'center', 'center');
          canvas.renderAll();
        },
      );
    });
    let transform_kind = '';
    let multi_touch_started = false;
    pinch_obj = new AlloyFinger($1('#maker .pinch_canvas'), {
      touchStart: G.mp.maker.has_active_object_or_group(function (canvas1, selected1) {
        if (!selected1) return;
        NewMakerMenuF.hide();
        canvas = canvas1;
        selected = selected1;
        is_embro = G.mp.maker.is_embro(selected);
      }),
      multipointStart: G.mp.maker.has_active_object_or_group(function (canvas1, selected1) {
        INIT_SCALE = selected1.scaleX;
        angle = selected1.angle;
        canvas = canvas1;
        selected = selected1;
        is_embro = G.mp.maker.is_embro(selected);
        multi_touch_started = true;
        // not_expandable_obj = _go(
        //   has_not_expandable_obj({ v: selected, scaleX: selected.scaleX }, []),
        //   _.if2(_.l('$.length'))(
        //     _.sort_by(function (selected_wrapper) {
        //       return -(selected_wrapper.v.width * selected_wrapper.scaleX);
        //     }),
        //     _.first,
        //   ),
        // );
        // pass =
        //   not_expandable_obj &&
        //   (not_expandable_obj.v.width * not_expandable_obj.scaleX > not_expandabel_obj_width ||
        //     not_expandable_obj.v.height * not_expandable_obj.scaleX > not_expandabel_obj_height);
      }),
      rotate: function (e) {
        if (!selected) return;
        transform_kind = 'rotate';
        const point = selected.getCenterPoint();
        angle = (angle || 0) + e.angle;
        selected.set('angle', Math.round(ang(angle))).setCoords();
        selected.setPositionByOrigin(point, 'center', 'center');
        // G.mp.maker.overflow_current_face_checker();

        if (selected._data.is_embro) G.mp.maker.show_rotation_info(selected);
        canvas.renderAll();
        // G.mp.maker.reset_data_cm(selected);
      },
      pinch: function (e) {
        if (is_embro) return;
        if (!selected) return;
        transform_kind = 'pinch';
        const v_scale = e.zoom * INIT_SCALE;
        // if (not_expandable_obj) {
        //   const expect_width = not_expandable_obj.v.width * not_expandable_obj.scaleX * e.zoom;
        //   const expect_height = not_expandable_obj.v.height * not_expandable_obj.scaleX * e.zoom;
        //   if (pass) {
        //     if (e.zoom > 1) return;
        //     if (expect_width < not_expandabel_obj_width && expect_height < not_expandabel_obj_height)
        //       pass = false;
        //   } else {
        //     if (expect_width > not_expandabel_obj_width || expect_height > not_expandabel_obj_height) {
        //       pinch_obj.pinchStartLen_jip = true;
        //       INIT_SCALE = selected.scaleX;
        //       not_expandable_obj = _go(
        //         has_not_expandable_obj({ v: selected, scaleX: selected.scaleX }, []),
        //         _.if2(_.l('$.length'))(
        //           _.sort_by(function (selected_wrapper) {
        //             return -(selected_wrapper.v.width * selected_wrapper.scaleX);
        //           }),
        //           _.first,
        //         ),
        //       );
        //       return;
        //     }
        //   }
        // }
        const point = new fabric.Point(selected.getCenterPoint().x, selected.getCenterPoint().y);
        selected.scale(v_scale);
        selected.setPositionByOrigin(point, 'center', 'center');
        canvas.renderAll();

        G.mp.maker.show_rotation_info(selected);
      },
      pressMove: function (e) {
        if (!selected || e.deltaX < -50) return;
        transform_kind = 'move';
        e.stopPropagation();
        const pinch_x_is_more = Math.abs(pinch_x) >= G.mp.maker.center_mode.SENS;
        const pinch_y_is_more = Math.abs(pinch_y) >= G.mp.maker.center_mode.SENS;
        e.deltaX = (e.deltaX * G.mp.maker.CANVAS_WIDTH) / $.width(window);
        e.deltaY = (e.deltaY * G.mp.maker.CANVAS_HEIGHT) / $.height(window);
        e.deltaX /= G.mp.maker.zoom_attr.scale;
        e.deltaY /= G.mp.maker.zoom_attr.scale;

        selected.set('left', selected.left + e.deltaX + (pinch_x_is_more ? pinch_x : 0)).setCoords();
        selected.set('top', selected.top + e.deltaY + (pinch_y_is_more ? pinch_y : 0)).setCoords();

        if (pinch_x_is_more) pinch_x = 0;
        if (pinch_y_is_more) pinch_y = 0;
        if (G.mp.maker.center_mode.active && (Math.abs(e.deltaX) < 1 || Math.abs(e.deltaY) < 1)) {
          _p.go(G.mp.maker.center_mode.auto_center(selected), function (hori, vert) {
            if (hori && vert) {
              pinch_x += e.deltaX;
              pinch_y += e.deltaY;
            } else if (hori) {
              pinch_x += e.deltaX;
              pinch_y = 0;
            } else if (vert) {
              pinch_y += e.deltaY;
              pinch_x = 0;
            } else {
              pinch_x = 0;
              pinch_y = 0;
            }
          });
        }
        canvas.renderAll();
      },
      touchEnd: async function () {
        if (!selected) return;
        if (multi_touch_started && (transform_kind === 'pinch' || transform_kind === 'rotate')) {
          multi_touch_started = false;
          await NewMakerCheckerF.alertImageQuality({ cv_obj: selected, ratio: canvas._print_ratio });
        }
        transform_kind = '';
        canvas.renderAll();
        checkIsOverflowOfObjInPrintArea(selected);
        await G.mp.maker.modified();
        NewMakerCallConditionPopupF.setAndRender(selected);
        G.mp.maker.center_mode.hide();
        canvas = null;
        selected = null;
      },
    });
    return _p.go(
      ___,
      _p.t$(
        '\
        .pinch_menu\
          .wrapper\
            .obj_imgs\
          p.no_image {{T("There are no images selected.")}}\
      ',
      ),
      $.append_to('#maker'),
      $.on('click', 'p.no_image', function (e) {
        G.mp.maker.pinch_mode.close();
      }),
      $.on('click', '.obj_img', function (e) {
        e.preventDefault();
        if ($.has_class($1('#maker .msg.pinch'), 'is_show'))
          setTimeout(function () {
            $.remove_class($1('#maker .msg.pinch'), 'is_show');
            setTimeout(function () {
              $.hide($1('#maker .msg.pinch'));
            }, 1000);
          }, 1500);
        /* 연속 클릭시 발생하는 이슈 처리 */
        if (!is_acting) is_acting = true;
        else {
          is_pinch_called = true;
          return;
        }

        $.toggle_attr(e.currentTarget, 'selected');

        const designs = sel_designs_without_locked();

        _p.go(
          $('.pinch_menu .obj_img[selected="true"]'),
          _map((obj_el) => $.attr(obj_el, '_idx')),
          _map((_idx) => designs[_idx]),
          function (selecteds) {
            G.mp.maker.unselect_all();
            G.mp.maker.multiple_select(selecteds);
          },
        );
        _p.go(pinch_selected_ani(), function () {
          is_acting = false;
          is_pinch_called = false;
        });
      }),
      _p.tap(function (el) {
        return render();
      }),
    );
  }

  function close() {
    if (!$.has_class($1('#maker_frame'), 'pinch_mode')) return;
    pinch_obj && pinch_obj.destroy();
    pinch_obj = null;
    $.remove_class($1('#maker_frame'), 'pinch_mode');
    $.remove_class($1('#maker .msg.pinch'), 'is_show');
    G.mp.maker.multiple_select(G.mp.maker.active());
    $.remove($1('.pinch_menu'));
  }

  const open_toggle = function () {
    if ($.has_class($1('#maker_frame'), 'pinch_mode')) return close();
    return _p.go(pinch_mode(), function () {
      $.add_class($1('#maker_frame'), 'pinch_mode');
      G.mp.maker.reset_corner_size2(G.mp.maker.active());
      G.mp.maker.editing_canvas().renderAll();
      _p.defer(function () {
        $.add_class($1('#maker .msg.pinch'), 'is_show');
      });
    });
  };

  function unselect_all() {
    _p.each($('.pinch_menu .obj_img'), function (el) {
      $.attr(el, { selected: 'false' });
    });
  }

  function show_or_hide(el, cond) {
    _p.go(
      el,
      _p
        .if2(_p.c(cond))($.show, function (target) {
          _p.defer(function () {
            $.css(target, { opacity: 1 });
          });
        })
        .else($.css({ opacity: 0, display: 'none' })),
    );
  }

  function sel_designs_without_locked() {
    return _filter(G.mp.maker.designs(), _p.v('evented'));
  }

  function render() {
    const el_pinch_menu = $1('.pinch_menu');
    if (!el_pinch_menu) return;
    const designs = sel_designs_without_locked();

    show_or_hide($.find1(el_pinch_menu, 'p.no_image'), !designs.length);
    return _p.go(
      designs,
      _p.map((design) => changeCvObjectToImgSrc(design, 50)),
      _p.map(function (v, i) {
        return {
          active: designs[i].active,
          url: v,
          is_locked: !designs[i].evented,
          _idx: i,
        };
      }),
      (designs) => designs.reverse(),
      _p.sum(
        _p.t$(
          '\
        .obj_img[selected="{{!!$.active}}" is_locked="{{$.is_locked}}" _idx="{{$._idx}}"]\
          img[src="{{$.url}}"]\
        ',
        ),
      ),
      function (obj_img_html) {
        if (!obj_img_html) return $.html($1('.pinch_menu .obj_imgs'), '');
        $.html($1('.pinch_menu .obj_imgs'), obj_img_html);
      },
    );
  }
  //
  // function event (maker_el) {
  //   _go(
  //     maker_el,
  //     $.on('touchmove', '.pinch_canvas', function(e) {
  //       e.preventDefault();
  //     })
  //   )
  // }

  G.mp.maker.pinch_mode = {
    close: close,
    open_toggle: open_toggle,
    render: render,
    // event : event,
    unselect_all: unselect_all,
  };
})();
