import { sel } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';

export const makeEventCommentHtml = (event_comment) => legacyHtml`
  <div class="event_comment_item infi_item" _sel="./(#${event_comment.id})">
    <div class="header">
      <div class="date">${moment(event_comment.created_at).format('YYYY.MM.DD')}</div>
      <div class="user_name">${sel('_.user.email', event_comment)}</div>
      <div class="like_point_wrap">
        <div class="like_point" like_point="${event_comment.like_point}">${event_comment.like_point}</div>
        <button
          type="button"
          selected="${!!sel('_.event_comment_like_points.length', event_comment)}"
        ></button>
      </div>
    </div>
    <div class="body">
      <div class="comment">
        <div class="comment_wrap">${UtilS.linkify(event_comment.comment)}</div>
      </div>
      <div class="photo" has_photo="${!!event_comment.photo_url}">
        <img src="${G.to_600(event_comment.photo_url)}" alt="" />
      </div>
      <div class="remove" is_me="${!!event_comment._is_me}">
        <button type="button">${T('Remove')}</button>
      </div>
    </div>
  </div>
`;
