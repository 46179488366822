import { MShopUtilF } from '../../../../../modules/MShop/Util/F/Function/module/MShopUtilF.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

!(function (LF) {
  LF.toggle_section = function (type) {
    return __(_p.v('currentTarget'), $.closest('.section.' + type), $.toggle_class('show hide'));
  };

  $.frame.defn_frame({
    frame_name: 'mp.checkout.change_email',
    page_name: 'mp.checkout.change_email',
    title: G.collabo_type === 'creator' ? T('login::Change Email Address') : '',
    hide_frame_button_type: G.collabo_type === 'creator' ? 'X' : '',
    ...(G.collabo_type === 'creator' ? { header_height: MShopUtilF.isMobile() ? 84 : 98 } : {}),
    is_modal: !UtilF.isLegacyMobile(),
    animation: !!(G.collabo_type === 'creator' && MShopUtilF.isMobile()),
    appending: __(
      $.on('click', function (e) {
        if (e.target == e.delegateTarget) $.frame.close();
      }),
    ),
  });

  $.frame.defn_page({
    page_name: 'mp.checkout.change_email',
    hide_frame_button_type: G.collabo_type === 'creator' && MShopUtilF.isMobile() ? '' : 'X',
    tabs: [
      {
        tab_name: 'mp.checkout.change_email',
        is_top_auto: true,
        template: __(
          _p.t(
            'user',
            '\
          .page_body\
            .header_title {{T("login::Change Email Address")}}\
            .current_data\
              .title {{T("login::Current Email Address")}}\
              .email_text {{user.email || ""}}\
              .notice\
                span {{T("login::We ll send an order confirmation to this address.")}}\
                br\
                span {{T("login::Please verify your current e-mail address.")}}\
            .inputs[is_social="{{user.social_id}}"]\
              .title {{T("login::New Email Address")}}\
              input[name="email" placeholder="" type="email"].new_email\
              .title[style="display: {{user.social_id ? "none" : "inline"}};"] {{T("login::Password")}}\
              input[name="password" placeolder="" type="password" style="display: {{user.social_id ? "none" : "inline"}};"].password\
            .submit_btn {{T("login::Save Changes")}}\
        ',
          ),
        ),
      },
    ],
    appended: __(
      $.on2('click', '.submit_btn', async function (e) {
        const $page = e.delegateTarget;
        const $new_email = $.find1($page, 'input.new_email');
        const $password = $.find1($page, 'input.password');

        // if (!$new_email.value) return $.alert(T('login::Enter your new email'));
        if (util.validation.is_input_empty($.val($new_email)))
          return $.alert(T('login::Enter your new email'));
        if (!util.validation.is_valid_email($.val($new_email))) {
          _p.go(T('This email address is not available.'), $.alert, function () {
            $new_email.focus();
          });
          return;
        }
        if (!$.attr($.find($page, '.inputs'), 'is_social').length && !$password.value)
          return $.alert(T('login::Enter your password'));
        if ($new_email.value == box.sel('is_user->email')) return $.alert(T('login::Check your email again'));
        $.don_loader_start();
        try {
          return await _p.go(
            void 0,
            function () {
              return $.post('/@api/mp/change_email', {
                email: G.to_lower(box.sel('is_user->email')),
                new_email: G.to_lower($new_email.value),
                password: $password && $password.value.length ? $password.value : '',
                social_id: $.attr($.find1($page, '.inputs'), 'is_social'),
              });
            },
            function (res) {
              if (!res.is_success) {
                $new_email.value = '';
                $password.value = '';
                return $.alert(res.msg);
              }

              return _p.go(void 0, function () {
                box.set('is_user->email', res.user.email);
                // $page.data_update && $page.data_update(res);
                if ($page.data_update) {
                  $page.data_update(res);
                } else if ($1('html.verify_area')) {
                  window.location.reload();
                }
                $.don_loader_end();
                $.frame.remove({ result: true, user: res.user });
              });
            },
          );
        } catch {
          $.don_loader_end();
        }
      }),
    ),
  });

  $.frame.defn_frame({
    frame_name: 'mp.quick_shipping_price',
    page_name: 'mp.quick_shipping_price_page',
    is_modal: !UtilF.isLegacyMobile(),
  });

  const data_quick_shipping = {
    seoul: [
      { destination: '강남/서초구', price: 12000 },
      { destination: '송파구', price: 15000 },
      { destination: '중구/종로구', price: 12000 },
      { destination: '용산구', price: 10000 },
      { destination: '마포구', price: 10000 },
      { destination: '여의도', price: 11000 },
      { destination: '영등포', price: 8000 },
      { destination: '관악/동작구', price: 10000 },
      { destination: '성동/광진구', price: 15000 },
      { destination: '동대문/중랑구', price: 15000 },
      { destination: '서대문', price: 12000 },
      { destination: '강동구', price: 18000 },
      { destination: '양천구', price: 10000 },
      { destination: '금천구', price: 10000 },
      { destination: '구로구', price: 8000 },
      { destination: '은평구', price: 15000 },
      { destination: '성북/강북구', price: 15000 },
      { destination: '도봉/노원구', price: 18000 },
    ],
    etc: [
      { destination: '안산/인천', price: 25000 },
      { destination: '의왕', price: 20000 },
      { destination: '이천/안성', price: 50000 },
      { destination: '용인', price: 40000 },
      { destination: '오산', price: 45000 },
      { destination: '양주', price: 40000 },
      { destination: '양평', price: 50000 },
      { destination: '여주/여천', price: 55000 },
      { destination: '죽전', price: 25000 },
      { destination: '장흥', price: 30000 },
      { destination: '청평/평택', price: 50000 },
      { destination: '파주', price: 30000 },
      { destination: '판교', price: 25000 },
      { destination: '팔당', price: 40000 },
      { destination: '포천', price: 45000 },
      { destination: '퇴계', price: 30000 },
      { destination: '하남', price: 30000 },
      { destination: '화성', price: 40000 },
    ],
  };

  $.frame.defn_page({
    page_name: 'mp.quick_shipping_price_page',
    hide_frame_button_type: 'X',
    title: '마플 본사(가산) 출발 기본 퀵비용',
    tabs: [
      {
        tab_name: 'mp.quick_shipping_price_tab',
        template: __(
          _p.c(data_quick_shipping),
          _p.t$(
            '\
          .body\
            table\
              tr\
                th 도착지\
                th.last 요금(원)\
              {{_.sum($.seoul, ',
            _p.t$(
              '\
              tr\
                td {{$.destination}}\
                td.last {{_.commify($.price)}}원~\
              ',
            ),
            ')}}\
            table\
              tr\
                th 도착지\
                th 요금(원)\
              {{_.sum($.etc, ',
            _p.t$(
              '\
              tr\
                td {{$.destination}}\
                td {{_.commify($.price)}}원~\
              ',
            ),
            ')}}\
          p - 날씨, 시간, 무게 등 변동사항 발생시 가격은 변동될 수 있습니다.\
          p - 야간 및 1박스 이상 물품은 비용 추가됩니다.\
        ',
          ),
        ),
      },
    ],
  });
})({});
