import { each, filter, find, go, map, omit, pick, pipe, reject, sel, tap } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import DesignCollectionDetailF from './index.js';
import {
  design_collection_mobile_j,
  design_collection_pc_line_j,
  design_collection_pc_mp_j,
} from '../S/tmpl.js';
import axios from 'axios';
import { SVGEditorProductDetailF } from '../../../SVGEditor/ProductDetail/F/Function/module/SVGEditorProductDetailF.js';
import { SVGEditorTempSvgProductsF } from '../../../SVGEditor/TempSvgProducts/F/Function/module/SVGEditorTempSvgProductsF.js';
import { getEditorPcId } from './EditorSelect.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { $addClass, $attr, $closest, $find, $prev, $qs, $removeClass, $setCss, $trigger } from 'fxdom/es';
import { setTextAlign } from './align.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { makeOgImageUrlFromUrl } from '../../../Maker/F/util.js';

export const mp_design_collection_list_editor_tab_appended = __(
  $.on2('click', '.create_design_collection', function (e) {
    e.preventDefault();
    return go(
      $.post_load2('/@api/design_collection/create_design_collection', {
        type: $.attr(e.currentTarget, 'type'),
      }),
      function (d) {
        if (G.collabo_type === 'line') {
          location.href = '/' + d.type + '/update/' + d.id;
        } else {
          location.href = `/admin_design_collection/${d.type}/detail/${d.id}`;
        }
      },
    );
  }),
);

const isLine = function () {
  return !!$1('html[collabo_type="line"]');
};

function renderDesignCollectionMakerHtml() {
  const design_collection = box.sel('design_collection');
  _p.go(
    design_collection,
    isPcType()
      ? isLine()
        ? design_collection_pc_line_j
        : design_collection_pc_mp_j
      : design_collection_mobile_j,
    $.html_to($1('.design_collection_body')),
    function () {
      renderSortable();
      $.don_loader_end();
    },
  );
}

function getDesignCollection() {
  return go(
    $.get('/@api/design_collection', _p.pick(box.sel('design_collection'), ['id'])),
    function (design_collection) {
      box.set('design_collection', design_collection);
      // set_design_collection_content_thumbnail_items();
    },
  );
}

function update_design_collection() {
  const design_collection = _p.omit(box.sel('design_collection'), ['_']);

  axios.patch('/@api/seo_meta/update_by_type_value', {
    meta: {
      type: 'design_collection',
      type_value: design_collection.id,
      ['description' + _en]: design_collection['short_name' + _en],
      ['og_title' + _en]: `[${TT('omp_layout::title')}] ${design_collection['thumbnail_title' + _en]}`,
      og_img: design_collection['og_image_url' + _en],
    },
  });

  return $.post_load2('/@api/collaboration/update', design_collection);
}

function update_design_collection_content(design_collection_content) {
  design_collection_content.title = design_collection_content.title || '';
  design_collection_content.description = design_collection_content.description || '';
  return _p.go(
    $.post('/@api/collaboration/update_design_collection_contents', [
      _p.omit(design_collection_content, ['_']),
    ]),
    function (updated_design_collection_content) {
      _p.extend(design_collection_content, updated_design_collection_content);
    },
  );
}

function update_design_collection_content_item(design_collection_content_item) {
  return _p.go(
    $.post_load2('/@api/collaboration/update_design_collection_content_items', [
      _p.omit(design_collection_content_item, ['_']),
    ]),
    function (updated_design_collection_content_item) {
      _p.extend(design_collection_content_item, updated_design_collection_content_item);
    },
  );
}

const debounced_update_design_collection_content_item = _p.debounce(function (dci, el) {
  update_design_collection_content_item(dci);
  $.remove_class(el, 'zoom_ing');
}, 500);

function update_box_dt(design_collection_content) {
  if (design_collection_content && design_collection_content.design_collection_id)
    return update_design_collection_content(design_collection_content);
  return update_design_collection();
}

const debounced_update_box_dt = _p.debounce(update_box_dt, 500);

function isPcType() {
  return $.has_class($1('html'), 'pc_type');
}

// G.mp.maker.canvas_all_size = function(canvas) {
//   return _.go(
//     _.mr(G.mp.maker.get_canvas_size(canvas), _.go(G.mp.maker.copied_90_rodated_canvas(canvas), G.mp.maker.get_canvas_size)),
//     function(vertical_size, horizontal_size) {
//       return {
//         top: vertical_size.top,
//         left: horizontal_size.top,
//         bottom: vertical_size.bottom,
//         right: horizontal_size.bottom,
//         width: horizontal_size.height,
//         height: vertical_size.height
//       }
//     }
//   )
// };

function design_collection_set(key, val) {
  box.set('design_collection->' + key, val);
}

function switch_type(type) {
  $.remove_class($1('html'), 'pc_type mobile_type');
  $.add_class($1('html'), type || (G.is_pc_size() ? 'pc_type' : 'mobile_type'));
}

// var a_event_prevent = __(
//   $.on('click', '.a_wrapper', function(e) {
//     e.preventDefault();
//   }),
//   $.on('click', '.thumbnail a', function(e) {
//     if (location.search && ((location.href.indexOf('editor') != -1) || (location.href.indexOf('update') != -1)) ) {
//       e.preventDefault();
//       var href = $.attr(e.currentTarget, 'href');
//       location.href = href + location.search;
//     }
//     e.stopPropagation();
//   })
// );

function getBox(ct) {
  return go(ct, $.closest('[_sel]'), box.sel);
}

function getClosestElAndBox(ct) {
  return go(ct, $.closest('[_sel]'), (a) => [a, box.sel(a)]);
}

// function w_h_percent(file_width, file_height, url) {
//   let width, height;
//   const default_percent = 90;
//
//   if (file_width > file_height) {
//     width = default_percent;
//     var ratio = file_height / file_width;
//     height = Math.round(default_percent * ratio);
//   } else {
//     height = default_percent;
//     var ratio = file_width / file_height;
//     width = Math.round(default_percent * ratio);
//   }
//
//   return {
//     width: width,
//     height: height,
//     url: url,
//     top: 50,
//   };
// }

function open_maker_by_pc_id(pc_id, design_collection_attr) {
  return new Promise(function (resolve) {
    $.frame.open(
      {
        frame_name: 'maker.design_collection',
        animation: false,
        appending: function () {
          $.hide($1('#header'));
          go([$qs('body'), $qs('html')], each($addClass('maker_canvas_editor_v1')));
        },
        closing: async function (X, wek) {
          $.show($1('#header'));
          go([$qs('body'), $qs('html')], each($removeClass('maker_canvas_editor_v1')));
          if (wek) {
            const { svg_product_materials, selected_option_group } =
              await SVGEditorProductDetailF.createSVGMaterialForPC(
                pick(['svg_product_materials', 'selected_option_group'])(box.sel('maker->product_color->_')),
              );
            const product_color = wek.pc;
            resolve({
              svg_product_materials,
              selected_option_group,
              product_color,
              thumbnail: wek.thumbnail,
            });
            return;
          }
          resolve();
        },
      },
      {
        tabs: [
          {
            data_func: pipe(
              () => $.get('/@api/product_color_detail', { pc_id }),
              tap(function (product_color) {
                if (design_collection_attr) _p.extend(product_color, design_collection_attr);
              }),
              _p.omit(['id']),
            ),
          },
        ],
      },
    );
  });
}

export function renderDesignCollectionContentPaddingMobile() {
  if (G.collabo_type !== '') return;
  const first_design_collection_content = $('.design_collection_content')[0];
  if ($attr('type')(first_design_collection_content) === 'h1') {
    $setCss({ marginBottom: 30 })(first_design_collection_content);
  }
  go(
    $('.design_collection_content'),
    each(function (el) {
      if (G.collabo_type === '') {
        if (['items', 'items_5', 'items_6'].includes($attr('type')(el))) {
          const h1_el = $prev(el);
          if (h1_el && $attr('type')(h1_el) === 'h1') {
            $setCss({ marginBottom: 25 })(h1_el);
          }
        }
      }
      const title_description_background_color_el = $.find1(el, '.title_description_background_color');
      if (!title_description_background_color_el) return;
      if (['left_img', 'right_img'].includes($attr('type')(el))) {
        const h1_el = $prev(el);
        if (h1_el && $attr('type')(h1_el) === 'h1') {
          $setCss({ marginBottom: 0 })(h1_el);
        }
        $setCss({ marginBottom: 40 })(el);
      }
      const thumbnail_url_el = $.find1(el, '>.body .wrapper >.thumbnail_url');
      const wrapper_el = $.find1(el, '.title_description_background_color >.wrapper');
      const height = $.height($.find1(el, '.title_description_background_color .wrapper2'));
      const padding = 60;
      $.css(title_description_background_color_el, {
        paddingTop: ($.width(el) - 64) / 2 + height + padding,
      });
      $.css(wrapper_el, {
        paddingTop: height + padding,
      });
      $.css(thumbnail_url_el, {
        top: height + padding,
      });
    }),
    function () {
      $.css($('.design_collection_content'), {
        opacity: 1,
      });
    },
  );
}

export const mp_design_collection_tab_appended = __(
  _p.tap(function () {
    if (UtilF.isLegacyMobile()) {
      renderDesignCollectionContentPaddingMobile();
    }
  }),
);

function updateSortedDci(design_collection_content_item) {
  return go(
    design_collection_content_item,
    _p.map(function (dci_el, i) {
      const dci = box.sel(dci_el);
      const is_thumbnail = $.has_class(dci_el, 'thumbnail_item');
      if (is_thumbnail) {
        dci.no_thumbnail = i;
      } else dci.no = i;
      return _p.pick(dci, ['no', 'id', 'no_thumbnail']);
    }),
    _p($.post_load2, '/@api/collaboration/update_design_collection_content_items'),
  );
}

function renderSortable() {
  go(
    $('.design_collection_content_items'),
    each(function (el) {
      el.sortable = Sortable.create(el, {
        onEnd: function (evt) {
          return go(evt.from, $.find('.design_collection_content_item'), updateSortedDci);
        },
      });
    }),
  );
}

function opacity_show(_sel) {
  return _p.go($1(_sel), $.css({ opacity: 0 }), function (el) {
    return anime({
      targets: el,
      opacity: 1,
      duration: 900,
    }).finished;
  });
}
// ,
// function(dcis) {
//   box.set('cloned_design_collection_content_items', _.clone(dcis));
//   return dcis;
// }
export const design_collection_content_items = () => {
  return _p.go(
    box.sel('design_collection->_->design_collection_contents'),
    _p.map((dc) => dc._.design_collection_content_items),
    _p.flatten,
  );
};

function set_design_collection_content_thumbnail_items() {
  box.set(
    'design_collection->_->design_collection_content_thumbnail_items',
    sort_thumbnail_no(design_collection_content_items()),
  );
}

function get_thumbnail_no() {
  return _p.go(
    $('.design_collection_content_items_frame .item'),
    reject((dci) => $.attr(dci, 'no_thumbnail') === null),
    (arr) => arr.length,
  );
}

function sort_thumbnail_no(design_collection_content_thumbnail_items) {
  return _p.go(
    design_collection_content_thumbnail_items,
    filter((a) => sel('no_thumbnail', a) != null),
    _p.sort_by((dci) => dci.no_thumbnail),
    _p.each((dci, i) => (dci.no_thumbnail = i)),
    _p.each(function (dci) {
      $.attr($1('.design_collection_content_items_frame .item[dci_id="' + dci.id + '"]'), {
        no_thumbnail: dci.no_thumbnail,
      });
    }),
  );
}

export const mp_design_collection_content_items_frame_closed = function () {
  return go(
    DesignCollectionDetailF.fs.design_collection_content_items(),
    reject((a) => sel('no_thumbnail', a) === null),
    each((dci) => (dci.no_thumbnail = null)),
    map(omit(['_'])),
    function (dcis) {
      return $.post_load2('/@api/collaboration/update_design_collection_content_items', dcis);
    },
    () => box.sel('cloned_design_collection_content_items'),
    reject((a) => sel('no_thumbnail', a) === null),
    map(omit(['_'])),
    function (dcis) {
      return $.post_load2('/@api/collaboration/update_design_collection_content_items', dcis);
    },
    function () {
      return getDesignCollection();
    },
    renderDesignCollectionMakerHtml,
  );
};

export const mp_design_collection_content_items_tab_appended = __(
  $.on2('click', '.item', function (e) {
    return _p.go(e.currentTarget, function (el) {
      const dci = box.sel(el);
      dci.no_thumbnail = dci.no_thumbnail === null ? get_thumbnail_no() : null;
      $.attr(e.currentTarget, { no_thumbnail: dci.no_thumbnail + '' });
    });
  }),
);

function remove_design_collection_content_thumbnail_item(el) {
  return _p.go(
    el,
    // $.attr('dci_id'),
    function (el) {
      const dci = box.sel(el);
      dci.no_thumbnail = null;
      $.don_loader_start();
      return $.post('/@api/collaboration/update_design_collection_content_items', [dci]);
    },
    function () {
      box.set(
        'design_collection->_->design_collection_content_thumbnail_items',
        sort_thumbnail_no(box.sel('design_collection->_->design_collection_content_thumbnail_items')),
      );
      return $.post(
        '/@api/collaboration/update_design_collection_content_items',
        box.sel('design_collection->_->design_collection_content_thumbnail_items'),
      );
    },
    function () {
      // reset_design_collection_content_thumbnail_items();
      $.don_loader_end();
    },
  );
}

function open_edit(design_collection_content, key, edit_btn_wrapper) {
  function find_body(currentTarget) {
    return $.find1($.closest(currentTarget, '.edit_btn_wrapper'), '>.body');
  }

  if (key === 'background_color')
    go(
      legacyHtml`
        <div class="edit_option">
          <input
            type="text"
            css_key="${UtilS.toCamel(key)}"
            key="${key}"
            value="${design_collection_content[key] || '#e9439a'}"
          />
        </div>
      `,
      $.el,
      $.append_to(edit_btn_wrapper),
      _p.tap($.closest('.edit_btn_wrapper'), $.add_class('editing')),
      $.on('change', `[key="${key}"]`, function (e) {
        design_collection_content[key] = $.val(e.currentTarget);
        update_box_dt(design_collection_content);
        $.css(find_body(e.currentTarget), $.attr(e.currentTarget, 'css_key'), design_collection_content[key]);
      }),
    );
  else if (
    key === 'title_color' ||
    key === 'description_color' ||
    key === 'title_color_pc' ||
    key === 'description_color_pc' ||
    key === 'title_color_mobile' ||
    key === 'description_color_mobile'
  )
    go(
      legacyHtml`
        <div class="edit_option">
          <input type="text" css_key="color" key="${key}" value="${design_collection_content[key]}" />
        </div>
      `,
      $.el,
      $.append_to(edit_btn_wrapper),
      _p.tap($.closest('.edit_btn_wrapper'), $.add_class('editing')),
      $.on('change', `[key="${key}"]`, function (e) {
        design_collection_content[key] = $.val(e.currentTarget);
        update_box_dt(design_collection_content);
        $.css(find_body(e.currentTarget), $.attr(e.currentTarget, 'css_key'), design_collection_content[key]);
      }),
    );
  else if (
    key === 'thumbnail_url' ||
    key === 'thumbnail_url_mobile' ||
    key === 'main_thumbnail_url' ||
    key === 'main_thumbnail_url_mobile' ||
    key === 'long_thumbnail_url' ||
    key === 'long_thumbnail_url_mobile' ||
    key === 'background_url_pc' ||
    key === 'background_url_mobile'
  )
    go(
      legacyHtml`
        <div class="edit_option">
          <button class="library">라이브러리</button>
          <button class="upload">업로드</button>
          <button class="remove">삭제</button>
          <button class="hide" key="option_pc">배경 가리기</button>
          <input type="file" accept="image/*" class="hidden_input" key="${key}" />
        </div>
      `,
      $.el,
      $.append_to(edit_btn_wrapper),
      _p.tap($.closest('.edit_btn_wrapper'), $.add_class('editing')),
      $.on('click', '.hide', function (e) {
        design_collection_content.option_pc = 0;
        $.attr($1('.design_collection'), { no_background_url: true });
        update_box_dt();
      }),
      $.on('click', '.library', function (e) {
        $.tooltip({
          data: box.sel('design_collection->_->files'),
          template: _p.sum(
            _p.t$(
              '\
          .item[background_height="{{$.height}}" url="{{$.url}}"]\
            img[src="{{G.to_350($.url)}}" style="width: 100%;"]\
            .text {{$.width + " X " + $.height}}\
        ',
            ),
          ),
          target: e.currentTarget,
          class: 'img_urls',
          width: 200,
          appended: __(
            _p.tap(function (don_tooltip) {
              go(
                $1('body'),
                $.prepend($.el(legacyHtml` <div class="bg"></div> `)),
                $.find1('.bg'),
                $.on('click', function (e) {
                  $.remove(don_tooltip);
                  $.remove(e.currentTarget);
                  close_edit();
                }),
              );
            }),
            $.on('click', '.item', function (e) {
              const url = $.attr(e.currentTarget, 'url');
              if (key === 'background_url_pc')
                design_collection_content.background_height = $.attr(e.currentTarget, 'background_height');
              design_collection_content[key] = url;
              go(update_box_dt(design_collection_content), renderDesignCollectionMakerHtml, function () {
                close_edit();
                $.remove(e.delegateTarget);
                $.remove($('body>.bg'));
              });
            }),
          ),
        });
      }),
      $.on('click', '.remove', function (e) {
        design_collection_content[key] = null;
        $.don_loader_start();
        go(
          update_box_dt(design_collection_content),
          getDesignCollection,
          renderDesignCollectionMakerHtml,
          $.don_loader_end,
        );
      }),
      $.on('click', '.upload', function (e) {
        _p.go(e.delegateTarget, $.find('input[type="file"]'), $.trigger('click'));
      }),
      $.on('change', `[key="${key}"]`, function (e) {
        $.don_loader_start();
        return _p.go(
          e.currentTarget,
          _p($.upload, _p, {
            url: '/@api/collaboration/images/' + box.sel('design_collection').id,
          }),
          _p.tap(_p.v('url'), $.img_onload),
          async function (file) {
            if (!file) return Promise.reject('fail-get-url');
            design_collection_content[key] = file.url;
            if (!design_collection_content.design_collection_id && key === 'thumbnail_url') {
              design_collection_content.og_image_url = await makeOgImageUrlFromUrl(
                design_collection_content[key],
              );
            }
            return update_box_dt(design_collection_content);
          },
          getDesignCollection,
          renderDesignCollectionMakerHtml,
          $.don_loader_end,
        );
      }),
    );
}

function close_edit() {
  go($('.edit_option'), _p.tap($.closest('.edit_btn_wrapper'), $.remove_class('editing')), $.remove);
}

function goBack() {
  if (G.collabo_type === '') {
    window.location.href = `/admin_design_collection/${box.sel('design_collection->type')}/list/${
      location.search
    }`;
  } else {
    window.location.href = `/${box.sel('design_collection->type')}/list/admin/${location.search}?lang=kr`;
  }
}

export const mp_design_collection_maker_tab_appended = __(
  _p.tap(renderSortable),
  // _.tap(a_event_prevent),
  _p.tap(function () {
    // set_design_collection_content_thumbnail_items();
    // $.on($1('body'), 'click', function(e) {
    //   if($.has_class(e.target, 'plus_button')) return;
    //   if($.closest(e.target, '.don_tooltip')) return;
    //   $.remove($('.don_tooltip.for_design_collection_content'))
    // })
  }),
  $.on('click', '.force_update', async (e) => {
    $.don_loader_start();
    await update_design_collection();
    $.don_loader_end();
  }),
  $.on('click', '#main_option .go_back', function () {
    goBack();
  }),
  $.on('click', '#main_option .preview', function () {
    const origin = location.origin;
    const lang = $.attr($1('html'), 'lang');
    if (G.collabo_type === '') {
      window.open(`/${lang}/custom_preview/detail/${box.sel('design_collection').id}`);
    } else {
      const pathname = '/' + lang + /design_collection_preview/ + box.sel('design_collection').id;
      window.open(origin + pathname);
    }
  }),

  $.on2('change', '#main_option .ordering', function (e) {
    const key = $.attr(e.currentTarget, 'key');
    const __en = $.attr(e.currentTarget, '__en');
    const is_main = $.attr($1('#main_option'), 'is_main' + __en);
    const val = $.val(e.currentTarget);
    if (!is_main)
      return _p.go($.alert('메인으로 설정된 콜라보만 정렬이 가능합니다.'), function () {
        $.val(e.currentTarget, 0);
      });

    return _p.go(
      $.val(e.currentTarget),
      function () {
        design_collection_set(key, val);
        return $.post_load2('/@api/collaboration/update/ordering_main_design_collections', {
          design_collection: _p.omit(box.sel('design_collection'), '_'),
        });
      },
      function () {
        location.reload();
      },
    );
  }),

  $.on('click', '#main_option .is_main', function (e) {
    const __en = $.attr(e.currentTarget, '__en');
    const key = 'is_main' + __en;
    if (box.sel('design_collection->is_public' + __en)) {
      const bool = box.sel('design_collection->' + key);
      _p.confirm(bool ? '메인으로 설정을 취소하시겠습니까?' : '메인으로 설정 하시겠습니까?', function () {
        design_collection_set(key, !bool);
        design_collection_set('no' + __en, 0);
        $.attr($1('#main_option'), key, !bool);
        return _p.go(
          $.post_load2('/@api/collaboration/update/ordering_main_design_collections', {
            design_collection: _p.omit(box.sel('design_collection'), '_'),
          }),
          function () {
            location.reload();
          },
        );
      })();
    } else {
      $.alert('공개 설정 후에 메인으로 설정이 가능합니다.');
    }
  }),
  $.on('click', '#main_option .is_hidden_public', async function (e) {
    const key = $.attr(e.currentTarget, 'key');
    if (
      (key === 'is_hidden_public' && !box.sel('design_collection->thumbnail_title')) ||
      (key === 'is_hidden_public_en' && !box.sel('design_collection->thumbnail_title_en')) ||
      (key === 'is_hidden_public_jp' && !box.sel('design_collection->thumbnail_title_jp'))
    ) {
      return $.alert('하단 메인 썸네일 만들기(제목) 을 입력해주세요.');
    }
    const boolean = box.sel('design_collection->' + key);
    design_collection_set(key, !boolean);
    update_box_dt();
    await $.alert('새로고침 됩니다.');
    window.location.reload();
  }),
  $.on('click', '#main_option .is_public', function (e) {
    $.remove($('.need_to_fill'));
    const __en = $.attr(e.currentTarget, '__en');
    if ($.attr($1('#main_option'), 'lang') != __en) {
      G._en = __en;
      $.attr($1('#main_option'), 'lang', G._en);
      renderDesignCollectionMakerHtml();
    }
    const key = 'is_public' + __en;
    const boolean = box.sel('design_collection->' + key);
    if (box.sel('design_collection->is_main' + __en)) {
      return $.alert('메인으로 설정되어 있어서 비공개로 전환 할수 없습니다.');
    } else {
      return _p.confirm(!boolean ? '공개로 전환 하시겠습니까?' : '비공개로 전환 하시겠습니까?', function (e) {
        if (
          G.collabo_type !== 'line' &&
          !boolean &&
          !_p.every(
            _p.map(
              _p.pick(box.sel('design_collection'), [
                'thumbnail_title' + __en,
                'thumbnail_description' + __en,
                'thumbnail_url',
              ]),
              function (content, key) {
                if (!content) {
                  _p.go(
                    $('[key="' + key + '"]'),
                    _p.reject(function (el) {
                      return (
                        $.closest(el, '.design_collection_content') ||
                        $.closest(el, '.design_collection_content_item')
                      );
                    }),
                    _p.first,
                    function (el) {
                      const offset = $.offset(el);
                      const height = el.tagName == 'INPUT' ? $.height(el) : $.height(el) / 2;
                      _p.go(
                        _p.t$(
                          '\
                      .need_to_fill[style="position: absolute"]\
                    ',
                        )(),
                        $.append_to($1('body')),
                        $.css({
                          width: height,
                          height,
                          borderRadius: '50%',
                          backgroundColor: 'red',
                          top: offset.top,
                          opacity: 0.5,
                          left: offset.left + $.width(el) / 2 - height / 2,
                        }),
                      );
                    },
                  );
                  return;
                }
                return true;
              },
            ),
            _p.idtt,
          )
        )
          return $.alert('표시된 부분을 채우셔야 메인으로 공개 할 수 있습니다.');
        design_collection_set(key, !boolean);
        $.attr($1('#main_option'), key, !boolean);
        update_box_dt();
      })();
    }
  }),
  $.on(
    'click',
    '#main_option .delete',
    _p.confirm('삭제 하시겠습니까?', function (e) {
      if (
        box.sel('design_collection->is_main') ||
        box.sel('design_collection->is_main_en') ||
        box.sel('design_collection->is_main_jp')
      )
        return $.alert('메인으로 설정되어있어 삭제 할수 없습니다.');

      design_collection_set('is_hidden', true);
      _p.go(update_box_dt(), function () {
        goBack();
      });
    }),
  ),
  $.on('click', '#main_option .change_lang', function (e) {
    const __en = $.attr(e.currentTarget, '__en');
    const lang = $.attr(e.currentTarget, 'html_lang');
    if (G._en == __en) return;
    $.remove($('.need_to_fill'));
    G._en = __en;
    $.attr($1('#main_option'), { lang: __en });
    $.attr($1('html'), { lang });
    renderDesignCollectionMakerHtml();
  }),

  $.on('click', '#main_option .pc', function () {
    $.remove($('.need_to_fill'));
    switch_type('pc_type');
    renderDesignCollectionMakerHtml();
  }),
  $.on('click', '#main_option .mobile', function () {
    $.remove($('.need_to_fill'));
    switch_type('mobile_type');
    renderDesignCollectionMakerHtml();
    renderDesignCollectionContentPaddingMobile();
  }),

  $.on2('click', '#main_option .design_option', function (e) {
    const key = $.attr(e.currentTarget, 'key');
    const val = $.attr($1('#main_option'), key) == 1 ? null : 1;
    design_collection_set(key, val);
    $.attr($1('.design_collection'), key, val);
    $.attr($1('#main_option'), key, val);
    return _p.go(update_box_dt(), renderDesignCollectionMakerHtml);
  }),

  $.on('click', '.hidden_option button', function (e) {
    design_collection_set($.attr(e.currentTarget, 'key'), null);
    $.attr($1('.design_collection'), { no_background_url: false });
    update_box_dt();
  }),

  $.on('focusout', '.design_collection .design_collection_thumbnail .text_input', function (e) {
    const ct = e.currentTarget;
    const key = $.attr(ct, 'key');
    _p.go(
      getClosestElAndBox(ct),
      function ([el, d__]) {
        d__[key] = $.val(ct);
        return d__;
      },
      update_box_dt,
    );
  }),

  $.on2('click', '.design_collection_contents .plus_content .plus_button', function (e) {
    const target = _p.go(
      e.currentTarget,
      $.closest('.plus_content'),
      $.prev('.design_collection_content'),
      box.sel,
    );
    $.tooltip({
      data: [
        {
          type: 'h1',
          url: '//s3.marpple.co/files/u_193535/2019/6/350/f_266469_1559715019727_H3JQ3Z6yFTTv8KG7dE9pa.png',
        },
        {
          type: 'p1',
          url: '//s3.marpple.co/files/u_193535/2019/6/350/f_266470_1559715019727_mSK5w5Kw9wGhsI9gZqf.png',
        },
        {
          type: 'banner',
          url: '//s3.marpple.co/files/u_193535/2019/6/350/f_266440_1559669116907_Ra5RUoo6d0s8c3AqFb5iU.png',
        },
        {
          type: 'full_img',
          url: '//s3.marpple.co/files/u_193535/2019/4/350/f_75510_1555401366003_g3TFIr7E6K9xDoTT8Fsy.png',
        },
        {
          type: 'left_img',
          url: '//s3.marpple.co/files/u_193535/2019/4/350/f_75511_1555401366003_y7nSvJ1K5hv8x2V4lIN9zF.png',
        },
        {
          type: 'right_img',
          url: '//s3.marpple.co/files/u_193535/2019/4/350/f_75512_1555401366003_ETH6vUZE4eV2zsFeou.png',
        },
        {
          type: 'items',
          url: '//s3.marpple.co/files/u_193535/2019/4/350/f_75513_1555401366003_o5T7noWNG4v3o1fLDcX6J.png',
        },
        {
          type: 'items_5',
          url: '//s3.marpple.co/files/u_193535/2021/3/350/9552c13d7661538aca82e2e91bd2ab5dafac6dca1.png',
        },
        // {
        //   type: 'items_6',
        //   url: '//s3.marpple.co/files/u_193535/2019/6/350/f_266454_1559670733860_K0q1xT0Z9Ddo0M8Ba6eNit.png',
        // },
      ],
      template: _p.sum(
        _p.t$(
          '\
        .item[type="{{$.type}}"]\
          img[src="{{$.url}}" style="width: 100%;"]\
      ',
        ),
      ),
      target: e.currentTarget,
      class: 'for_design_collection_content',
      width: 200 + 200 + 32 + 20,
      appended: __(
        _p.tap(function (don_tooltip) {
          go(
            $1('body'),
            $.prepend($.el(legacyHtml` <div class="bg"></div> `)),
            $.find1('.bg'),
            $.on('click', function (e) {
              $.remove(don_tooltip);
              $.remove(e.currentTarget);
            }),
          );
        }),
        $.on('click', '.item', function (e) {
          const type = $.attr(e.currentTarget, 'type');
          $.don_loader_start();
          return _p.go(
            target,
            function (target) {
              return $.post('/@api/collaboration/create_content2', {
                design_collection_id: box.sel('design_collection').id,
                no: target ? target.no + 1 : 0,
                type,
              });
            },
            getDesignCollection,
            renderDesignCollectionMakerHtml,
            function () {
              $.remove(e.delegateTarget);
              $.remove($('body>.bg'));
              $.don_loader_end();
            },
          );
        }),
      ),
    });
  }),
  $.on('click', '.design_collection_content >.option >.body >.sorting', function (e) {
    const num = $.has_class(e.currentTarget, 'up') ? -1 : 1;
    const dcs = box.sel('design_collection->_->design_collection_contents');
    _p.go(
      getClosestElAndBox(e.currentTarget),
      function ([el, dc]) {
        const my_no = dc.no;
        const target_no = my_no + num;
        const target_dc = dcs[target_no];
        if (!target_dc) return Promise.reject('sorting-no where to go');
        dc.no = target_no;
        target_dc.no = my_no;
        return [dc, target_dc];
      },
      _map(_p.pick(['id', 'no'])),
      _p($.post_load2, '/@api/collaboration/update_design_collection_contents'),
      function () {
        box.set('design_collection->_->design_collection_contents', _p.sort_by(dcs, _p.v('no')));
        renderDesignCollectionMakerHtml();
      },
    );
  }),
  $.on(
    'click',
    '.design_collection_content >.option >.body >.text_align',
    async function ({ currentTarget }) {
      $.don_loader_start();
      await setTextAlign({
        el: currentTarget,
        text_align: currentTarget.dataset.text_align,
        device_type: currentTarget.dataset.device_type,
      });
      renderDesignCollectionMakerHtml();
      $.don_loader_end();
    },
  ),
  $.on2('click', '.design_collection_content >.option >.body >.remove', function (e) {
    return _p.go(
      $.confirm('삭제 하시겠습니까?'),
      _p.if2(_p.idtt)(
        () => getClosestElAndBox(e.currentTarget),
        function ([el, dc]) {
          return $.post_load2(
            '/@api/collaboration/remove_design_collection_contents',
            _p.pick(dc, ['design_collection_id', 'id', 'type']),
          );
        },
        function () {
          _p.go(getDesignCollection(), renderDesignCollectionMakerHtml);
        },
      ),
    );
  }),
  $.on('click', '.design_collection_content >.option >.body >.duplicate', function (e) {
    let next_index;
    _p.go(
      getClosestElAndBox(e.currentTarget),
      function ([el, dc]) {
        next_index = $.attr(el, 'index') + 1;
        return $.post_load2('/@api/collaboration/duplicate_design_collection_content', dc);
      },
      getDesignCollection,
      renderDesignCollectionMakerHtml,
      function () {
        $.scroll_top2(
          window,
          $.offset($1('.design_collection_content[index="' + next_index + '"]')).top - 60,
          300,
        );
      },
    );
  }),
  $.on('click', '.design_collection_content_thumbnail_items .select_product_wrapper', function (e) {
    $.frame.open({ frame_name: 'mp.design_collection_content_items_frame' });
  }),
  $.on(
    'click',
    '.design_collection_content .design_collection_content_items .add_product_wrapper',
    async function (e) {
      const design_collection_content = box.sel($.closest(e.currentTarget, '.design_collection_content'));
      const design_collection_id = box.sel($.closest(e.currentTarget, '.design_collection')).id;

      const index = _p.go(
        $.prev(e.currentTarget, '.design_collection_content_item'),
        box.sel,
        function (prev_dci) {
          return prev_dci ? prev_dci.no + 1 : 0;
        },
      );

      const design_collection_attr = {
        design_collection_id,
        design_collection_content_id: design_collection_content.id,
        design_collection_origin_type: box.sel('design_collection').type,
      };
      const pc_id = await go(undefined, () => {
        if (collabo_type === 'line') return 1697876;
        return getEditorPcId(e.currentTarget);
      });
      if (!pc_id) return;
      return _p.go(open_maker_by_pc_id(pc_id, design_collection_attr), async function (pc_dci) {
        if (!pc_dci) return;
        const design_collection_content_item = {};
        _p.extend(design_collection_content_item, {
          design_collection_id,
          design_collection_content_id: design_collection_content.id,
          no: index,
        });

        _p.extend(design_collection_content_item, _p.pick(pc_dci.thumbnail, ['url']));
        const { svg_product_materials, selected_option_group, product_color } = pc_dci;
        const base_product_id = product_color.base_product_id;
        await SVGEditorTempSvgProductsF.forceRemoveTempSvgProduct({
          user_id: box.sel('is_user->id'),
          base_product_id,
        });
        return _p.go(
          $.post('/@api/collaboration/create_design_collection_content_item_and_pc', {
            product_color,
            design_collection_content_item,
            svg_product_materials,
            selected_option_group,
          }),
          _p.tap(getDesignCollection, renderDesignCollectionMakerHtml),
          async function (dci) {
            opacity_show(`[_sel="./_->design_collection_content_items->(#${dci.id})"]`);
          },
        );
      });
    },
  ),
  $.on('click', '.design_collection_content_item .move', function (e) {
    const ct = e.currentTarget;
    _p.go(getClosestElAndBox(ct), function ([el, dci]) {
      const img_el = $.find1(el, '.img img');
      const zoom_text_el = $.find1(el, '.img .zoom_text');
      dci.top = dci.top || 50;
      dci.left = dci.left || 50;

      $.add_class(el, 'zoom_ing');
      if ($.has_class(ct, 'top')) {
        --dci.top;

        $.css(img_el, { top: dci.top + '%' });
        $.text(zoom_text_el, dci.top);
      } else if ($.has_class(ct, 'down')) {
        ++dci.top;

        $.css(img_el, { top: dci.top + '%' });
        $.text(zoom_text_el, dci.top);
      } else if ($.has_class(ct, 'left')) {
        --dci.left;
        $.css(img_el, { left: dci.left + '%' });
        $.text(zoom_text_el, dci.left);
      } else {
        ++dci.left;
        $.css(img_el, { left: dci.left + '%' });
        $.text(zoom_text_el, dci.left);
      }

      go(
        sel('pcfs.value', dci),
        find((pcf) => pcf.base_product_face_id === dci.base_product_face_id),
        function (pcf) {
          if (!_p.v(pcf, 'top')) return;
          pcf.top = dci.top;
          pcf.left = dci.left;
        },
      );
      debounced_update_design_collection_content_item(_p.pick(dci, ['id', 'top', 'pcfs', 'left']), el);
    });
  }),
  $.on('click', '.design_collection_content_item .zoom', function (e) {
    const ct = e.currentTarget;
    go(getClosestElAndBox(ct), function ([el, dci]) {
      const img_el = $.find1(el, '.img img');
      const zoom_text_el = $.find1(el, '.img .zoom_text');

      if ($.has_class(ct, 'zoom_out')) {
        --dci.width;
      } else {
        ++dci.width;
      }
      $.add_class(el, 'zoom_ing');
      $.css(img_el, { width: dci.width + '%' });
      $.text(zoom_text_el, dci.width);
      go(
        sel('pcfs.value', dci),
        find((pcf) => pcf.base_product_face_id === dci.base_product_face_id),
        function (pcf) {
          if (!_p.v(pcf, 'width')) return;
          pcf.width = dci.width;
        },
      );
      debounced_update_design_collection_content_item(_p.pick(dci, ['id', 'width', 'pcfs']), el);
    });
  }),
  $.on2(
    'click',
    '.design_collection_content_thumbnail_items .design_collection_content_item >.option >.body >.remove',
    function (e) {
      return go(
        $.confirm('삭제 하시겠습니까?'),
        _p.if2(_p.idtt)(
          () =>
            remove_design_collection_content_thumbnail_item(
              $.closest(e.currentTarget, '.design_collection_content_item'),
            ),
          getDesignCollection,
          renderDesignCollectionMakerHtml,
        ),
      );
    },
  ),
  $.on(
    'click',
    '.design_collection_contents .design_collection_content_item >.option >.body >.remove',
    function (e) {
      return _p.go(
        $.confirm('삭제 하시겠습니까?'),
        _p.if2(_p.idtt)(
          () => {
            const current_dt = getBox(e.currentTarget);
            $.don_loader_start();
            current_dt.is_hidden = true;
            current_dt.no_thumbnail = null;
          },
          () => $.closest(e.currentTarget, '.design_collection_content'),
          box.sel,
          _p.v('_.design_collection_content_items'),
          _p.tap(
            _p.reject(function (item) {
              return item.is_hidden;
            }),
            _p.each(function (item, i) {
              item.no = i;
            }),
          ),
          _p.map(function (item) {
            return _p.pick(item, ['is_hidden', 'no', 'id', 'no_thumbnail']);
          }),
          function (items) {
            return $.post('/@api/collaboration/update_design_collection_content_items', items);
          },
          function () {
            set_design_collection_content_thumbnail_items();
            return $.post(
              '/@api/collaboration/update_design_collection_content_items',
              box.sel('design_collection->_->design_collection_content_thumbnail_items'),
            );
          },
          getDesignCollection,
          function () {
            _go(e.currentTarget, $.closest('.design_collection_content_item'), $.remove);
            renderDesignCollectionMakerHtml();
            $.don_loader_end();
          },
        ),
      );
    },
  ),

  $.on('click', '.design_collection_content_item >.option >.body >.duplicate', function (e) {
    const current_dt = getBox(e.currentTarget);
    $.don_loader_start();
    _p.go(
      $.post('/@api/collaboration/duplicate_design_collection_content_item_and_pc', {
        product_color: _p.omit(current_dt._.product_color, ['id', '_', 'product_id']),
        design_collection_content_item: _p.extend(
          _p.omit(current_dt, ['id', '_', 'product_color_id', 'no_thumbnail']),
          {
            no: current_dt.no + 1,
          },
        ),
      }),
      getDesignCollection,
      renderDesignCollectionMakerHtml,
    );
  }),
  $.on('click', '.design_collection_content_item >.option >.body >.revise', function (e) {
    const current_dt = getBox(e.currentTarget);
    const dci_el = $.closest(e.currentTarget, '.design_collection_content_item');
    const dc = box.sel($.closest(e.currentTarget, '.design_collection_content'));
    const _sel = _p.s$('[_sel="{{$}}"]')($.attr(dci_el, '_sel'));
    _p.go(dci_el, $.css({ opacity: 0 }));
    _p.go(
      open_maker_by_pc_id(current_dt.product_color_id),
      async function (pc_dci) {
        if (!pc_dci) return;
        const design_collection_content_item = {};
        console.log('pc_dci.thumbnail', pc_dci.thumbnail);

        _p.extend(design_collection_content_item, _p.pick(pc_dci.thumbnail, ['url']));

        design_collection_content_item.id = current_dt.id;
        pc_dci.product_color.id = current_dt.product_color_id;

        _p.extend(pc_dci.product_color, {
          design_collection_id: dc.design_collection_id,
          design_collection_content_id: dc.id,
          design_collection_content_item_id: design_collection_content_item.id,
        });
        const { svg_product_materials, selected_option_group, product_color } = pc_dci;
        const base_product_id = product_color.base_product_id;
        await SVGEditorTempSvgProductsF.forceRemoveTempSvgProduct({
          user_id: box.sel('is_user->id'),
          base_product_id,
        });
        return $.post('/@api/collaboration/update_design_collection_content_item_and_pc', {
          svg_product_materials,
          selected_option_group,
          product_color,
          design_collection_content_item,
        });
      },
      getDesignCollection,
      renderDesignCollectionMakerHtml,
      function () {
        opacity_show(_sel);
      },
    );
  }),

  $.on('click', function (e) {
    const target = e.target;
    if ($.has_class($.closest(target, '.edit_btn_wrapper'), 'editing')) return;
    close_edit();
  }),

  $.on('click', '.edit_btn', function (e) {
    const edit_btn_wrapper = $.closest(e.currentTarget, '.edit_btn_wrapper');
    const key = go(edit_btn_wrapper, $.attr('key'));
    const design_collection_content = box.sel($.closest(e.currentTarget, '[_sel]'));
    open_edit(design_collection_content, key, edit_btn_wrapper);
  }),

  $.on('keyup', 'textarea', function (e) {
    const rows = _p.row_length_n($.val(e.currentTarget));
    const height = $.attr(e.currentTarget, 'hei');
    $.height(e.currentTarget, rows * height);
  }),
  $.on('focusout', '.textarea_wrapper >.val', function (e) {
    const key = $.attr(e.currentTarget, 'key');
    _p.go(getClosestElAndBox(e.currentTarget), function ([el, d__]) {
      const val = $.val(e.currentTarget);
      $.val(e.currentTarget, val);
      d__[key] = _p.to_br(val);
      const rows = _p.row_length_br(d__[key]);
      const height = $.attr(e.currentTarget, 'hei');
      $.height(e.currentTarget, rows * height);
      return update_box_dt(d__);
    });
  }),

  $.on('click', function (e) {
    _p.go(
      e.target,
      $.closest('.ing'),
      function (parent) {
        return _p.reject($('.ing'), function (el) {
          return el == parent;
        });
      },
      $.remove_class('ing'),
    );
  }),

  $.on('change', 'input[key="margin_bottom"]', function (e) {
    const val = $.val(e.currentTarget);
    _p.go(getClosestElAndBox(e.currentTarget), function ([el, dt]) {
      dt.margin_bottom = val;
      $.css(el, 'margin-bottom', (val * 6) / 7);
      debounced_update_box_dt(dt);
    });
  }),
  $.on('change', '.color_input_wrapper .color_text_input', function (e) {
    const ct = e.currentTarget;
    const target = _go(ct, $.closest('.color_input_wrapper'), $.find1('.val'));
    const css_key = $.attr(ct, 'css_key');
    const val = $.val(ct);
    $.css(target, css_key, val);

    _p.go(
      getClosestElAndBox(e.currentTarget),
      function ([el, d__]) {
        const key = $.attr(ct, 'key');
        d__[key] = val;
        return d__;
      },
      debounced_update_box_dt,
    );
  }),
  $.on('change', '.color_input_wrapper input[type="color"]', function (e) {
    const ct = e.currentTarget;
    const val = $.val(ct);
    const target = _go(ct, $.closest('.color_input_wrapper'), $.find1('.color_text_input'));
    _p.go(target, $.val(val), $.trigger('change'));
  }),

  $.on('change', '.thumbnail_title input.text_input', function (e) {
    _p.go(e.currentTarget, $.val, $.text_to($1('.design_collection_long_thumbnail .thumbnail_title .text')));
  }),
  $.on('click', '.file_dcci_add', function (e) {
    const file_dcci_upload_el = go(
      e.currentTarget,
      $closest('.design_collection_content_item'),
      $find('.file_dcci_upload'),
    );
    $trigger('click', file_dcci_upload_el);
  }),
  $.on('change', '.file_dcci_upload', async function (e) {
    $.don_loader_start();
    const design_collection_content_item = go(
      e.currentTarget,
      $closest('.design_collection_content_item'),
      box.sel,
    );
    await go(
      $.upload(e.currentTarget, {
        data: {
          attached_type: 'design_collection_content_item',
          attached_id: design_collection_content_item.id,
        },
      }),
      async function (data) {
        await $.post('/@api/collaboration/update_only_design_collection_content_item', {
          design_collection_content_item: {
            url: data.url,
            is_thumbnail_size_full: true,
            id: design_collection_content_item.id,
          },
        });
      },
      getDesignCollection,
      renderDesignCollectionMakerHtml,
    );
    $.don_loader_end();
  }),
);
