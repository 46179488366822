import { makeAllRealBpcfSize2 } from '../canvas_size.js';
import { each, find, go, map, pick, sel } from 'fxjs/es';
import { getBaseProductSizesInMaker, getCurrentBpcfsInMaker, getRealFcanvass } from '../getSth.js';
import { makeFcanvasByProductColor } from '../draw_product_faces.js';
import { $append } from 'fxdom/es';

export async function appendFcanvasRenderedByBpId(product_color, el) {
  await makeFcanvasByProductColor(product_color);
  await go(
    getRealFcanvass(),
    each((fcanvas) => {
      G.mp.maker.cv_print_area(fcanvas).visible = true;
      fcanvas.renderAll();
      if (!el) return;
      const image = new Image();
      image.src = fcanvas.toDataURL();
      return new Promise(function (resolve) {
        image.onload = function () {
          $append(image, el);
          resolve();
        };
      });
    }),
  );
}

export function resetBpfSize(product_color) {
  const all_bpcf_sizes = makeAllRealBpcfSize2(
    product_color._.base_product_color._.base_product_color_faces[0].mask2_url
      ? G.mp.maker.cv_mask2
      : G.mp.maker.cv_mask1,
  );

  if (getCurrentBpcfsInMaker()[0]._.base_product_face.size_faces.length > 1) {
    alert('사이즈 변경시 이미지 면 이미지의 크기가 변경되면 이 작업을 하시면 안됩니다.');
  }
  if (getBaseProductSizesInMaker().length > 1) {
    alert('개발팀의 문의해 주세요2');
    return;
  }
  go(
    getCurrentBpcfsInMaker(),
    each((bpcf) => {
      const bpf = bpcf._.base_product_face;
      go(
        bpf.size_faces,
        each((sf) => {
          sf.print.px = go(
            all_bpcf_sizes,
            find((size) => size.bpf_id === bpf.id),
            sel('size_info'),
            pick(['top', 'left', 'width', 'height']),
          );
        }),
      );
    }),
  );
}

export async function updateBaseProductFacesPrintArea(product_color) {
  await go(
    product_color._.base_product_color._.base_product_color_faces,
    map((bpcf) => bpcf._.base_product_face),
    each(async (bpf) => {
      const data = await $.post(
        '/@api/base_product_faces/size_faces_update',
        pick(['size_faces', 'id'], bpf),
      );
      if (data.is_error) {
        alert('post error');
      }
    }),
  );
}
