export const MAKER_STROKE_WIDTH = 2;
export function plusStrokeWidth(obj) {
  const stroke_to_sub = MAKER_STROKE_WIDTH;
  return {
    width: obj.width + stroke_to_sub,
    height: obj.height + stroke_to_sub,
    top: obj.top - stroke_to_sub,
    left: obj.left - stroke_to_sub,
  };
}

export function minusStrokeWidth(obj) {
  const stroke_to_sub = MAKER_STROKE_WIDTH;
  return {
    width: obj.width - stroke_to_sub,
    height: obj.height - stroke_to_sub,
    top: obj.top + stroke_to_sub,
    left: obj.left + stroke_to_sub,
  };
}
