import { jsPDF } from 'jspdf';
import { MALGUN_GOTIC } from '../../../../../www/module/front/app/df/WorkOrderSheet/resources/font.js';
import { pdfUtil } from '../../../../../www/module/front/app/df/WorkOrderSheet/pdfHelpers.js';
import { format } from 'date-fns';
import { createCode128Barcode } from './barcode.js';
import { go, join, filter, identity } from 'fxjs/es';

/**
 *  @function POD 제품 한진 입고를 위한 입고 지시서 PDF 생성/다운로드
 *  @param {number} projection_id
 *  @param {{due_at: string, cate_list_name: string, cate_item_name: string, product_name: string, bp_name: string, bpc_name: string, bps_name: string, thumbnail_canvas: HTMLCanvasElement, sku: number | string, quantity: number, pf2: object}[]} ups
 *  @return {undefined}
 * */
export async function createHanjinInboundOrderSheetPdf({ projection_id, ups }) {
  try {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
    });

    const page_margin = 10;

    /* 한글 폰트 설정 */
    doc.addFileToVFS('malgun.ttf', MALGUN_GOTIC);
    doc.addFont('malgun.ttf', 'malgun', 'normal');

    ups.forEach(
      (
        {
          due_at,
          cate_list_name,
          cate_item_name,
          product_name,
          quantity,
          bp_name,
          bpc_name,
          bps_name,
          sku,
          thumbnail_canvas,
        },
        index,
      ) => {
        // Add a new page for each item in the ups array (except for the first page)
        index > 0 && doc.addPage();

        // Add rectangle border with a thick line
        pdfUtil.drawLayoutBorder({ doc, page_margin, width: 5 });

        helpers.drawBlackBackground({ doc, page_margin });

        // Add Header
        helpers.renderHeader({ doc, page_margin, quantity });

        // Add Body
        helpers.renderBody({
          doc,
          page_margin,
          cate_list_name,
          cate_item_name,
          product_name,
          sku,
          quantity,
          bp_name,
          bps_name,
          bpc_name,
        });

        // Add Thumbnail Image
        helpers.renderThumbnail({
          doc,
          canvas: thumbnail_canvas,
          page_margin,
        });

        helpers.renderBarcode({ doc, barcode_text: sku });
        helpers.renderFooter({ doc, projection_id, due_at, current_page: index + 1, total_page: ups.length });
      },
    );

    const file_name = `한진 POD 입고 지시서(${projection_id})_${format(Date.now(), 'MM/dd HH:mm:ss')}`;
    doc.save(file_name);
  } catch (err) {
    console.error(err);
  }
}

const helpers = {
  renderBarcode: ({ doc, barcode_text }) => {
    const barcode_canvas = createCode128Barcode(barcode_text);
    const image = barcode_canvas.toDataURL('image/png');
    doc.addImage(image, 'PNG', 20, 145, 155, 45);
  },
  renderThumbnail: ({ doc, canvas, page_margin }) => {
    const image = canvas.toDataURL('image/png');

    const container_size = {
      x: 192,
      y: 65,
      width: 95,
      height: 135,
    };
    const image_size = {
      width: canvas.width,
      height: canvas.height,
    };

    const { width, height, offset_x, offset_y } = calculateImagePosition(container_size, image_size);

    doc.addImage(image, 'PNG', offset_x, offset_y, width, height);

    const v_line_x = doc.getPageWidth() - page_margin - 97;
    doc.line(v_line_x, page_margin + 55, v_line_x, page_margin + 188);
  },
  drawBlackBackground: ({ doc, page_margin }) => {
    pdfUtil.drawGrayBox({
      doc,
      x: page_margin + 155,
      y: page_margin + 2.5,
      fillColor: '#ff0000',
      style: 'F',
      w: 60,
      h: 26,
    });

    pdfUtil.drawGrayBox({
      doc,
      x: page_margin + 215,
      y: page_margin + 2.5,
      fillColor: '#30cf35',
      style: 'F',
      w: 60,
      h: 26,
    });

    pdfUtil.drawGrayBox({
      doc,
      x: page_margin,
      y: 40,
      fillColor: 'black',
      style: 'F',
      w: doc.getPageWidth() - 2 * page_margin,
      h: 22,
    });
  },
  renderHeader: ({ doc, page_margin, quantity }) => {
    const marpple_logo_image_url =
      '//s3.marpple.co/files/u_1187078/2023/8/original/2ce5855d3f91711c6f63949cc3d2466b8f330f421.png';
    const logo_size = 18;

    // Marpple Logo
    doc.addImage(marpple_logo_image_url, 'png', page_margin + 6, page_margin + 6, logo_size, logo_size);
    const today = format(new Date(), 'MM/dd');

    // Title
    pdfUtil.drawText({
      doc,
      font: 'malgun',
      size: 50,
      text: `POD 한진입고`,
      x: page_margin + 30,
      y: 30,
    });

    // Quantity
    pdfUtil.drawText({
      doc,
      font: 'malgun',
      size: 50,
      textColor: 'black',
      text: `${quantity}개`,
      x: page_margin + 175 - ('' + quantity).length * 4,
      y: 30,
    });

    // Date of Creation
    const v_line_x = doc.getPageWidth() - page_margin - 60;
    doc.line(v_line_x, page_margin, v_line_x, page_margin + 30);
    pdfUtil.drawText({
      doc,
      font: 'malgun',
      size: 50,
      text: today,
      x: doc.getPageWidth() - page_margin - 53,
      y: 31,
    });

    // Header Bottom Line
    pdfUtil.drawFullHorLines({ doc, page_margin, start_top: 40, line_height: 0, counts: 1 });
  },
  renderBody: ({
    doc,
    page_margin,
    cate_list_name,
    cate_item_name,
    product_name,
    bp_name,
    bpc_name,
    bps_name,
    sku,
  }) => {
    const font_height = 9;
    const line_height = 15;
    const line_start_x = page_margin + 5;
    const line_start_y = page_margin + 45;
    // 상품명
    pdfUtil.drawText({
      doc,
      font: 'malgun',
      size: 26,
      textColor: 'yellow',
      text: `${go([bp_name, product_name], filter(identity), join(' / ')) ?? '없음'}`,
      x: line_start_x,
      y: line_start_y,
    });
    pdfUtil.drawFullHorLines({
      doc,
      page_margin,
      start_top: line_start_y + font_height,
      line_height: 0,
      counts: 1,
    });
    [
      `옵션: ${go([bpc_name, bps_name], filter(identity), join(' / ')) ?? '없음'}`,
      `분류: ${cate_list_name} / ${cate_item_name}`,
      `SKU: ${sku}`,
    ].forEach((text, idx) => {
      const y = line_start_y + (line_height + font_height) * (idx + 1);
      pdfUtil.drawText({
        doc,
        font: 'malgun',
        size: 30,
        text,
        x: line_start_x,
        y,
      });

      doc.line(page_margin, y + font_height, 190, y + font_height);
    });
  },
  renderFooter: ({ doc, projection_id, due_at, current_page, total_page }) => {
    pdfUtil.drawText({
      doc,
      font: 'malgun',
      size: 10,
      text: `NESS  -  주문번호 ${projection_id}  -  출고일 기한 ${format(
        new Date(due_at),
        'yyyy/MM/dd',
      )}  -  페이지 ${current_page} / ${total_page}`,
      textColor: '#fff',
      x: 100,
      y: doc.getPageHeight() - 9,
    });
  },
};

function calculateImagePosition(container, image) {
  const containerRatio = container.width / container.height;
  const imageRatio = image.width / image.height;

  let width, height;

  if (containerRatio > imageRatio) {
    // Container is wider than the image
    width = container.height * imageRatio;
    height = container.height;
  } else {
    // Container is taller than the image
    width = container.width;
    height = container.width / imageRatio;
  }

  const offset_x = (container.width - width) / 2 + container.x;
  const offset_y = (container.height - height) / 2 + container.y;

  return { width, height, offset_x, offset_y };
}
