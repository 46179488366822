import { go, filter } from 'fxjs/es';

export default function (lo) {
  $.frame.defn_frame({
    frame_name: 'df.bp_stock.list',
    page_name: 'df.bp_stock.list',
  });

  $.frame.defn_page({
    page_name: 'df.bp_stock.list',
    tabs: [
      {
        tab_name: 'df.bp_stock.list',
        appending: __(
          $.on('submit', '#search form', function (e) {
            e.preventDefault();
            const val = $.val($.find1(e.currentTarget, 'input[name="bp_name"]'));
            const is_not_stock = $.find1(e.currentTarget, 'input[name="is_not_stock"]').checked;
            const is_discontinued = $.find1(e.currentTarget, 'input[name="is_discontinued"]').checked;

            _p.go(
              $.get('/@api/base_products/search', { val, is_not_stock, is_discontinued }),
              _p.tap(function (bps) {
                window.box.set('bps', bps);
              }),
              _p
                .if2(_p.val('length'))(
                  _p.sum(
                    (bp) => pug`
              .bp[_sel=./(#${bp.id})]
                .thumb
                  img.don_lazy[data-src=${G.to_150(bp.model_thumb_url_en)}]
                .name ${bp.name}
              `,
                  ),
                )
                .else(
                  () => pug`
              h2 결과가 없습니다...`,
                ),
              function (tmpl) {
                $.html($1('#base_products_result'), tmpl);
              },
              G.don_lazy,
            );
          }),
          $.on('click', '#base_products_result .bp', function (e) {
            $.frame.open(
              {
                frame_name: 'df.bp_stock.item',
                always_remove: true,
              },
              {
                page_name: 'df.bp_stock.item1',
                tabs: [
                  {
                    tab_name: 'df.bp_stock.item1',
                    data_func: () => window.box.sel(e.currentTarget),
                  },
                ],
              },
            );
          }),
        ),
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'df.bp_stock.item',
    page_name: 'df.bp_stock.item1',
  });

  const $setBpStockCount = (el_bpc) => {
    if (!el_bpc) return;
    const bp_stock_count = box.sel(el_bpc)._.bp_stocks.length;
    const discontinued_count = box.sel(el_bpc)._.discontinued_sizes.length;
    $.attr($.removeClass(el_bpc, 'checked'), 'is_not_stock', bp_stock_count > 0);
    $.html($.find1(el_bpc, 'span.bp_stock_count'), ` (${bp_stock_count})`);
    $.html($.find1(el_bpc, 'span.discontinued_count'), ` (${discontinued_count})`);
  };

  $.frame.defn_page({
    page_name: 'df.bp_stock.item1',
    hide_frame_button_type: 'X',
    tabs: [
      {
        tab_name: 'df.bp_stock.item1',
        template: (bp) => {
          return pug`
      .color_selector[_sel="bps->(#${bp.id})"]
        h2 ${bp.name}
        button.is_not_stock_all[type="button"] 전체 품절  
        button.is_not_stock_not_all[type="button"] 전체 품절 해제  
        .base_product_colors[_sel="./_->base_product_colors"]
          ${_p.sum(bp._.base_product_colors, (bpc) => {
            return pug`
          .base_product_color[_sel=./(#${bpc.id}) is_not_stock=${bpc._.bp_stocks.length > 0}]
            .product_thumb_url
              img[src=${G.to_150(bpc.product_thumb_url)}]
            .color_code[style="background-color: ${bpc.color_code}; width: 30px; height: 30px;"]
            .name ${bpc.name}
            .not_stock.badge
              sp 일시 품절
              sp.bp_stock_count &nbsp;(${bpc._.bp_stocks.length})
            .discontinued.badge
              sp 단종
              sp.discontinued_count &nbsp;(${bpc._.discontinued_sizes.length})
          `;
          })}
          `;
        },
        showed: function (el_don_tab) {
          const el_bpc = $.find1(el_don_tab, '.base_product_color.checked');
          $setBpStockCount(el_bpc);
        },
        appended: (el_don_tab) =>
          _p.go(
            el_don_tab,
            $.on('click', '.is_not_stock_all', async (e) => {
              if (
                !(await $.confirm(
                  '기존에 일시품절 되어있던 색상과 사이즈는 기록되지 않습니다. 진행하시겠습니까?',
                ))
              )
                return;
              $.don_loader_start();
              const bp = window.box.sel(e.currentTarget);
              const bp_stocks = _p.groupBy(
                await $.put('/@api/bp_stock/is_not_stock/all', { id: bp.id }),
                'base_product_color_id',
              );
              bp._.base_product_colors.forEach((bpc) => {
                bpc._.bp_stocks = bp_stocks[bpc.id];
                bpc._.discontinued_sizes = [];
              });
              $.find(el_don_tab, '.base_product_color').forEach($setBpStockCount);
              $.don_loader_end();
              $.alert('모든 색, 사이즈 품절되었습니다.');
            }),
            $.on('click', '.is_not_stock_not_all', async (e) => {
              if (!(await $.confirm('모든 색상과 사이즈가 일시품절 해제됩니다. 진행하시겠습니까?'))) return;
              $.don_loader_start();
              const bp = window.box.sel(e.currentTarget);
              await $.put('/@api/bp_stock/is_not_stock/not_all', { id: bp.id });
              bp._.base_product_colors.forEach((bpc) => {
                bpc._.bp_stocks = [];
              });
              $.find(el_don_tab, '.base_product_color').forEach($setBpStockCount);
              $.don_loader_end();
              $.alert('모든 색, 사이즈 품절이 해제되었습니다.');
            }),
            /*$.on('click', '.discontinued_all', async (e) => {
              if (
                !(await $.confirm(
                  '기존에 단종 되어있던 색상과 사이즈는 기록되지 않습니다. 진행하시겠습니까?',
                ))
              )
                return;

              $.don_loader_start();
              const bp = window.box.sel(e.currentTarget);
              const bp_stocks = _p.groupBy(
                await $.put('/@api/bp_stock/discontinued/all', { id: bp.id }),
                'base_product_color_id',
              );

              bp._.base_product_colors.forEach((bpc) => {
                bpc._.bp_stocks = [];
                bpc._.discontinued_sizes = bp_stocks[bpc.id];
              });

              $.find(el_don_tab, '.base_product_color').forEach($setBpStockCount);
              $.alert('모든 색, 사이즈가 단종되었습니다. 상품을 비공개 처리해 주세요.');
              $.don_loader_end();
            }),
            $.on('click', '.discontinued_not_all', async (e) => {
              if (!(await $.confirm('모든 색상과 사이즈가 단종 해제됩니다. 진행하시겠습니까?'))) return;

              $.don_loader_start();
              const bp = window.box.sel(e.currentTarget);
              await $.put('/@api/bp_stock/discontinued/not_all', { id: bp.id });
              bp._.base_product_colors.forEach((bpc) => {
                bpc._.discontinued_sizes = [];
              });
              $.find(el_don_tab, '.base_product_color').forEach($setBpStockCount);
              $.don_loader_end();
              $.alert('모든 색, 사이즈 단종이 해제되었습니다.');
            }),*/
            $.on('click', '.base_product_color', function (e) {
              const bpc = window.box.sel($.add_class(e.currentTarget, 'checked'));
              $.removeClass($.siblings(e.currentTarget), 'checked');
              _p.go(
                $.get('/@api/bp_stock', {
                  bpc_id: bpc.id,
                }),
                _p.tap(function (bp_stocks) {
                  window.box.set('bp_stocks', bp_stocks);
                }),
                (bp_stocks) =>
                  $.frame.add_page({
                    page_name: 'df.bp_stock.item2',
                    tabs: [
                      {
                        tab_name: 'df.bp_stock.item2',
                        data_func: _p.a(
                          _p.mr(
                            window.box.sel($.closest(e.currentTarget, '.color_selector')),
                            bp_stocks,
                            bpc,
                          ),
                        ),
                      },
                    ],
                  }),
              );
            }),
          ),
      },
    ],
  });

  $.frame.defn_page({
    page_name: 'df.bp_stock.item2',
    hide_frame_button_type: 'X',
    tabs: [
      {
        tab_name: 'df.bp_stock.item2',
        template: (bp, bp_stocks, bpc) => {
          return pug`
      .bp_stock_list[_sel="bp_stocks"]
        h2 ${bp.name} / (${bpc.name})
        .left
          .thumb
            img[src=${G.to_350(bpc.product_thumb_url)}]
        .right
          ${_p.sum(
            bp_stocks,
            (bp_stock) => pug`
          .bp_stock_item[_sel=./(#${bp_stock.id})]
            .size ${bp_stock.bps_name}
              sp.ml8 (${bp_stock.bps_short_name})
            .is_not_stock
                select[data-is-thumb="${bp_stock.bps_is_thumb}" data-is-not-stock="${
              bp_stock.is_not_stock
            }" data-is-discontinued="${bp_stock.is_discontinued}"]
                  option.none[value=""] 선택하세요.
                  option[value="is_not_stock" ${bp_stock.is_not_stock ? 'selected' : ''}] 일시 품절
                  option[value="is_discontinued" ${bp_stock.is_discontinued ? 'selected' : ''}] 단종

        `,
          )}`;
        },
        appended: __(
          $.on('change', '.bp_stock_item .is_not_stock select', async function (e) {
            const DISCONTINUED = 'is_discontinued';
            const NOT_STOCK = 'is_not_stock';

            const {
              currentTarget: {
                value: currentValue,
                dataset: { isThumb, isNotStock },
              },
            } = e;
            const is_thumb = isThumb === 'true';

            const allSizes = _p.go(e.currentTarget, $.closest('.right'), $.find('select'));
            const discontinuedSizes = go(
              allSizes,
              filter((select) => select.dataset.isDiscontinued === 'true'),
            );

            if (currentValue === DISCONTINUED) {
              const AVAILABLE_COUNT = 2;
              const availableSizes = go(
                allSizes,
                filter((select) => select.dataset.isDiscontinued === 'false'),
              );
              if (is_thumb && availableSizes.length >= AVAILABLE_COUNT) {
                $.alert('현재 색상의 대표 사이즈입니다. 대표 사이즈를 다른 사이즈로 변경 후 진행해 주세요.');
                e.currentTarget.value = isNotStock === 'true' ? NOT_STOCK : '';
                return;
              }
            } else {
              if (allSizes.length === discontinuedSizes.length) {
                if (!is_thumb) {
                  $.alert('해당 사이즈를 대표 사이즈로 변경 후 진행해주세요.');
                  e.currentTarget.value = DISCONTINUED;
                  return;
                }
              }
            }

            _p.go(
              $.put('/@api/bp_stock/is_not_stock', {
                id: window.box.sel(e.currentTarget).id,
                status: _p.go(e.currentTarget, $.val),
              }),
              ({ bp_stock, message }) => {
                if (message !== '') {
                  $.alert(message);
                }

                e.currentTarget.dataset.isNotStock = currentValue === NOT_STOCK;
                e.currentTarget.dataset.isDiscontinued = currentValue === DISCONTINUED;

                window.box.extend2(e.currentTarget, bp_stock);
              },
              () => filterBlur(e.currentTarget, true),
              _p.catch((err) => {
                if (currentValue === DISCONTINUED) {
                  e.currentTarget.value = isNotStock === 'true' ? NOT_STOCK : '';
                } else {
                  e.currentTarget.value = DISCONTINUED;
                }
                $.alert(err.message);
              }),
            );
          }),
        ),
        hided: function (el_don_tab) {
          _p.go(el_don_tab.tab_opt.data_func(), function (bp, bp_stocks, bpc) {
            bpc._.bp_stocks = _p.filter(bp_stocks, (bp_stock) => bp_stock.is_not_stock);
            bpc._.discontinued_sizes = _p.filter(bp_stocks, (bp_stock) => bp_stock.is_discontinued);
          });
        },
      },
    ],
  });

  $.frame(
    {
      frame_name: 'df.bp_stock.list',
      page_name: 'df.bp_stock.list',
    },
    {
      page_name: 'df.bp_stock.list',
    },
  );
}
