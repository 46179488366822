import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorKeyringHookPCMuiF } from '../Mui/module/VectorEditorKeyringHookPCMuiF.js';

export const makeHook = ({
  frame_position: { top: frame_position_top, height: frame_position_height },
  prev_right_panel_el,
  art_board: { width: art_board_width, height: art_board_height, shape_path_data: art_board_shape_path_data },
  makeTargetImageEl,
  postProcess,
}) =>
  MuiF.pushPage(VectorEditorKeyringHookPCMuiF.page, (page, [tab]) => {
    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_frame_position_top = frame_position_top;
      tab_el.__mp_frame_position_height = frame_position_height;
      tab_el.__mp_prev_right_panel_el = prev_right_panel_el;
      tab_el.__mp_art_board_width = art_board_width;
      tab_el.__mp_art_board_height = art_board_height;
      tab_el.__mp_art_board_shape_path_data = art_board_shape_path_data;
      tab_el.__mp_makeTargetImageEl = makeTargetImageEl;
      tab_el.__mp_postProcess = postProcess;

      return tab_appending.call(tab, tab_el);
    };
  }).catch(async (error) => {
    try {
      await MuiF.popPage();
    } catch (error) {
      console.error(error);
    }

    throw error;
  });
