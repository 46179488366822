export const mocks = {
  inbound_orders: [
    {
      id: 405,
      created_at: '2024-01-29T04:43:31.911Z',
      updated_at: '2024-01-29T05:02:58.006Z',
      projection_id: 1348595,
      lgl_inbound_trans_code_id: 'CJ',
      waybill_no_domestic: '540117894335',
      lgl_inbound_order_group_id: 303,
      current_status: 'PACKING',
      combined_projection_id: 1348595,
      _: {
        lgl_items: [
          {
            id: 1566,
            created_at: '2024-01-29T04:43:38.151Z',
            updated_at: '2024-01-30T02:31:17.885Z',
            lgl_product_id: 1863,
            user_product_id: 8847824,
            projection_id: 1348595,
            combined_projection_id: 1348595,
            lgl_inbound_order_id: 402,
            lgl_outbound_order_id: null,
            lgl_return_order_id: null,
            lgl_inbound_order_group_id: 303,
            lgl_return_order_group_id: null,
            lgl_outbound_order_group_id: null,
            _: {
              lgl_return_orders: {},
              lgl_item_quantities: {
                id: 1434,
                lgl_item_id: 1566,
                inbound_order_requested_quantity: 10,
                inbound_order_confirmed_quantity: 10,
                return_order_requested_quantity: null,
                return_order_confirmed_quantity: null,
                outbound_order_requested_quantity: 10,
                outbound_order_confirmed_quantity: null,
              },
              lgl_product: {
                id: 1863,
                created_at: '2024-01-29T04:43:38.151Z',
                updated_at: null,
                sku_id: 101477,
                lgl_seller_id: 1,
              },
              lgl_item_inbound_inspections: {
                id: 170,
                lgl_item_id: 1566,
                is_ok: true,
                work_type: 'G',
                work_url: null,
                work_reason: null,
                reject_url: null,
                reject_reason: null,
                memo: null,
                inspected_at: '2024-01-29T05:01:39.961Z',
              },
              up_c: {
                id: 8847824,
                type: 'projection',
                is_selected: true,
                quantity: 10,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.729Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8466,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: null,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                o_id: null,
                o_pp_id: null,
                o_pcp_id: null,
                o_user_id: null,
                o_projection_id: null,
                o_base_product_size_id: null,
                o_colored_preview_id: null,
                o_copied_colored_preview_id: null,
                up_main: null,
                upc_main: null,
                collabo_type: '',
                oem_price: 0,
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                original_quantity: null,
                is_pb: false,
                spo_item_id: null,
                goods_type_id: null,
                partial_delivery_at: null,
                _: {
                  base_product: {
                    name: '길단 12000 크루넥 맨투맨(USA핏)',
                    id: 4042,
                  },
                  product_color: {
                    name: null,
                    product_faces2: {
                      value: [
                        {
                          debug: {},
                          bpf_id: 7663,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c209',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848622_1568783363805_i0eDm0Hq8aVlm3aK2xKK.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c50',
                              src: '//s3.marpple.co/files/guest/2022/11/900/ede03535df534f84a3483dca7651681a14befd601.png',
                              top: 263.5365073919586,
                              fill: 'rgb(0,0,0)',
                              left: 490.12456091926396,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742996,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 9,
                                height_cm: 6,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/ede03535df534f84a3483dca7651681a14befd601.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 2874,
                                image_height: 1934,
                                real_width_cm: 9,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 6,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                is_low_quality_agree: false,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.999232,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 403.757312,
                              scaleX: 0.11979255180235973,
                              scaleY: 0.11979255180235973,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c210',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848642_1568783437332_s3KUE2h4FAMadAtgPq.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c211',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '앞면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 101.25058139534885,
                            right: 100.6703488372093,
                            width: 297.07906976744187,
                            bottom: 78.62151162790698,
                            height: 353.6517441860465,
                            x_diff: -0.2901162790697711,
                            y_diff: 5.947383720930233,
                          },
                          cv_shading: {
                            cid: 'c212',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 40,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 306,
                                  width: 248,
                                  height: 320,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 42,
                                },
                                px: {
                                  top: 242,
                                  left: 302,
                                  width: 256,
                                  height: 336,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 298,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 44,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 298,
                                  width: 264,
                                  height: 352,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 294,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 46,
                                },
                                px: {
                                  top: 242,
                                  left: 294,
                                  width: 272,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Front',
                          face_name_jp: '前面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c249',
                            top: 240,
                            fill: 'rgba(0,0,0,0)',
                            left: 296,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 242,
                                left: 298,
                                width: 264,
                                height: 352,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 44.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 44.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 354,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                        {
                          debug: {},
                          bpf_id: 7661,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c216',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848621_1568783360511_U6NnXJsss0OL1UsiFA.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c58',
                              src: '//s3.marpple.co/files/guest/2022/11/900/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                              top: 329.50662377386357,
                              fill: 'rgb(0,0,0)',
                              left: 337.2,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742987,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 23.4,
                                height_cm: 4.3,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 6080,
                                image_height: 1120,
                                real_width_cm: 23.4,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 4.3,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.9987199999999,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 110.52608,
                              scaleX: 0.3126673336903119,
                              scaleY: 0.3126673336903119,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c217',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_850337_1568798541299_ZST5D1Wx7Z8liIqDAgR.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c218',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '뒷면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 100.96046511627907,
                            right: 100.38023255813954,
                            width: 297.6593023255814,
                            bottom: 77.17093023255813,
                            height: 355.10232558139535,
                            x_diff: -0.290116279069764,
                            y_diff: 5.222093023255809,
                          },
                          cv_shading: {
                            cid: 'c219',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 46,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 306,
                                  width: 248,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 46,
                                },
                                px: {
                                  top: 226,
                                  left: 302,
                                  width: 256,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 299,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 47,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 299,
                                  width: 264,
                                  height: 376,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 295,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 48,
                                },
                                px: {
                                  top: 226,
                                  left: 295,
                                  width: 272,
                                  height: 384,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Back',
                          face_name_jp: '背面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c251',
                            top: 224,
                            fill: 'rgba(0,0,0,0)',
                            left: 297,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 226,
                                left: 299,
                                width: 264,
                                height: 376,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 47.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 47.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 378,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                      ],
                      can_not_change_color: false,
                    },
                    thumbnails: null,
                    id: 11631021,
                  },
                  printable_product: {},
                },
              },
              up_c_ss: {
                id: 8847824,
                type: 'projection',
                is_selected: true,
                quantity: 10,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.729Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8466,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: null,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                _name: 'M',
                _name_en: 'M',
                _is_not_stock: false,
                _bp_stock_id: 27164,
                _no: 2,
                oem_price: 0,
                collabo_type: '',
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                _name_jp: 'M',
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                spo_item_id: null,
                original_quantity: null,
                is_pb: false,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                goods_type_id: null,
                _is_discontinued: false,
                _: {
                  base_product_size: {
                    name: 'M',
                    id: 8466,
                  },
                  base_product_color: {
                    name: '네이비',
                    id: 35570,
                  },
                },
              },
            },
          },
          {
            id: 1567,
            created_at: '2024-01-29T04:43:38.148Z',
            updated_at: '2024-01-30T02:31:17.885Z',
            lgl_product_id: 1864,
            user_product_id: 8847823,
            projection_id: 1348595,
            combined_projection_id: 1348595,
            lgl_inbound_order_id: 402,
            lgl_outbound_order_id: null,
            lgl_return_order_id: null,
            lgl_inbound_order_group_id: 303,
            lgl_return_order_group_id: null,
            lgl_outbound_order_group_id: null,
            _: {
              lgl_return_orders: {},
              lgl_item_quantities: {
                id: 1435,
                lgl_item_id: 1567,
                inbound_order_requested_quantity: 2,
                inbound_order_confirmed_quantity: 2,
                return_order_requested_quantity: null,
                return_order_confirmed_quantity: null,
                outbound_order_requested_quantity: 2,
                outbound_order_confirmed_quantity: null,
              },
              lgl_product: {
                id: 1864,
                created_at: '2024-01-29T04:43:38.148Z',
                updated_at: null,
                sku_id: 101478,
                lgl_seller_id: 1,
              },
              lgl_item_inbound_inspections: {
                id: 171,
                lgl_item_id: 1567,
                is_ok: true,
                work_type: 'G',
                work_url: null,
                work_reason: null,
                reject_url: null,
                reject_reason: null,
                memo: null,
                inspected_at: '2024-01-29T05:02:58.091Z',
              },
              up_c: {
                id: 8847823,
                type: 'projection',
                is_selected: true,
                quantity: 2,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.724Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8465,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: 11639221,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                o_id: null,
                o_pp_id: null,
                o_pcp_id: null,
                o_user_id: null,
                o_projection_id: null,
                o_base_product_size_id: null,
                o_colored_preview_id: null,
                o_copied_colored_preview_id: null,
                up_main: null,
                upc_main: null,
                collabo_type: '',
                oem_price: 0,
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                original_quantity: null,
                is_pb: false,
                spo_item_id: null,
                goods_type_id: null,
                partial_delivery_at: null,
                _: {
                  base_product: {
                    name: '길단 12000 크루넥 맨투맨(USA핏)',
                    id: 4042,
                  },
                  product_color: {
                    name: null,
                    product_faces2: {
                      value: [
                        {
                          debug: {},
                          bpf_id: 7663,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c209',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848622_1568783363805_i0eDm0Hq8aVlm3aK2xKK.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c50',
                              src: '//s3.marpple.co/files/guest/2022/11/900/ede03535df534f84a3483dca7651681a14befd601.png',
                              top: 263.5365073919586,
                              fill: 'rgb(0,0,0)',
                              left: 490.12456091926396,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742996,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 9,
                                height_cm: 6,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/ede03535df534f84a3483dca7651681a14befd601.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 2874,
                                image_height: 1934,
                                real_width_cm: 9,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 6,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                is_low_quality_agree: false,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.999232,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 403.757312,
                              scaleX: 0.11979255180235973,
                              scaleY: 0.11979255180235973,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c210',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848642_1568783437332_s3KUE2h4FAMadAtgPq.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c211',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '앞면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 101.25058139534885,
                            right: 100.6703488372093,
                            width: 297.07906976744187,
                            bottom: 78.62151162790698,
                            height: 353.6517441860465,
                            x_diff: -0.2901162790697711,
                            y_diff: 5.947383720930233,
                          },
                          cv_shading: {
                            cid: 'c212',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 40,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 306,
                                  width: 248,
                                  height: 320,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 42,
                                },
                                px: {
                                  top: 242,
                                  left: 302,
                                  width: 256,
                                  height: 336,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 298,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 44,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 298,
                                  width: 264,
                                  height: 352,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 294,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 46,
                                },
                                px: {
                                  top: 242,
                                  left: 294,
                                  width: 272,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Front',
                          face_name_jp: '前面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c249',
                            top: 240,
                            fill: 'rgba(0,0,0,0)',
                            left: 296,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 242,
                                left: 298,
                                width: 264,
                                height: 352,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 44.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 44.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 354,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                        {
                          debug: {},
                          bpf_id: 7661,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c216',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848621_1568783360511_U6NnXJsss0OL1UsiFA.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c58',
                              src: '//s3.marpple.co/files/guest/2022/11/900/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                              top: 329.50662377386357,
                              fill: 'rgb(0,0,0)',
                              left: 337.2,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742987,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 23.4,
                                height_cm: 4.3,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 6080,
                                image_height: 1120,
                                real_width_cm: 23.4,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 4.3,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.9987199999999,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 110.52608,
                              scaleX: 0.3126673336903119,
                              scaleY: 0.3126673336903119,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c217',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_850337_1568798541299_ZST5D1Wx7Z8liIqDAgR.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c218',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '뒷면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 100.96046511627907,
                            right: 100.38023255813954,
                            width: 297.6593023255814,
                            bottom: 77.17093023255813,
                            height: 355.10232558139535,
                            x_diff: -0.290116279069764,
                            y_diff: 5.222093023255809,
                          },
                          cv_shading: {
                            cid: 'c219',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 46,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 306,
                                  width: 248,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 46,
                                },
                                px: {
                                  top: 226,
                                  left: 302,
                                  width: 256,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 299,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 47,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 299,
                                  width: 264,
                                  height: 376,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 295,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 48,
                                },
                                px: {
                                  top: 226,
                                  left: 295,
                                  width: 272,
                                  height: 384,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Back',
                          face_name_jp: '背面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c251',
                            top: 224,
                            fill: 'rgba(0,0,0,0)',
                            left: 297,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 226,
                                left: 299,
                                width: 264,
                                height: 376,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 47.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 47.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 378,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                      ],
                      can_not_change_color: false,
                    },
                    thumbnails: null,
                    id: 11631021,
                  },
                  printable_product: {
                    id: 11639221,
                    name: null,
                    product_faces2: {
                      value: [
                        {
                          debug: {},
                          bpf_id: 7663,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c99',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848622_1568783363805_i0eDm0Hq8aVlm3aK2xKK.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_bpcf',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c50',
                              src: '//s3.marpple.co/files/guest/2022/11/900/ede03535df534f84a3483dca7651681a14befd601.png',
                              top: 263.5365073919586,
                              fill: 'rgb(0,0,0)',
                              left: 490.12456091926396,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742996,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 9,
                                height_cm: 6,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/ede03535df534f84a3483dca7651681a14befd601.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 2874,
                                image_height: 1934,
                                real_width_cm: 9,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 6,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                is_low_quality_agree: false,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.999232,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 403.757312,
                              scaleX: 0.11979255180235973,
                              scaleY: 0.11979255180235973,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c108',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848642_1568783437332_s3KUE2h4FAMadAtgPq.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask1',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c109',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask2',
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '앞면',
                          size_info: {
                            top: 118.20930232558139,
                            left: 178.85581395348837,
                            right: 177.82790697674417,
                            width: 527.3162790697673,
                            bottom: 139.7953488372093,
                            height: 625.9953488372092,
                            x_diff: -0.5139534883720955,
                            y_diff: 10.793023255813956,
                          },
                          cv_shading: {
                            cid: 'c110',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_shading',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 40,
                                },
                                px: {
                                  top: 242,
                                  left: 306,
                                  width: 248,
                                  height: 320,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 42,
                                },
                                px: {
                                  top: 242,
                                  left: 302,
                                  width: 256,
                                  height: 336,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 298,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 44,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 298,
                                  width: 264,
                                  height: 352,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 294,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 46,
                                },
                                px: {
                                  top: 242,
                                  left: 294,
                                  width: 272,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Front',
                          face_name_jp: '前面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c112',
                            top: 240,
                            fill: 'rgba(0,0,0,0)',
                            left: 296,
                            type: 'rect',
                            _data: {
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 242,
                                left: 298,
                                width: 264,
                                height: 352,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 354,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [
                            {
                              id: 22391387,
                              url: '//s3.marpple.co/files/u_1885698/2022/11/original/88671dbb31ad42572a74ecd86307116b4ef0eeb31.png',
                              name: '88671dbb31ad42572a74ecd86307116b4ef0eeb31.png',
                              o_id: null,
                              size: 175599,
                              type: 'image/png',
                              width: 1063,
                              ai_url: null,
                              height: 716,
                              user_id: 1885698,
                              is_hidden: false,
                              o_user_id: null,
                              created_at: '2022-11-10T06:18:18.575Z',
                              updated_at: null,
                              attached_id: null,
                              attached_type: 'printable_products',
                              o_attached_id: null,
                              original_name: '주문번호_1348595_상품번호_8847823_앞면_0.png',
                              press_type_id: 218,
                              unique_in_site: null,
                              o_attached_type: null,
                              press_type_name: '디티에프',
                            },
                          ],
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                        {
                          debug: {},
                          bpf_id: 7661,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c106',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848621_1568783360511_U6NnXJsss0OL1UsiFA.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_bpcf',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c58',
                              src: '//s3.marpple.co/files/guest/2022/11/900/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                              top: 258.16273237114865,
                              fill: 'rgb(0,0,0)',
                              left: 337.2,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742987,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 23.4,
                                height_cm: 4.3,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 6080,
                                image_height: 1120,
                                real_width_cm: 23.4,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 4.3,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.9987199999999,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 110.52608,
                              scaleX: 0.3126673336903119,
                              scaleY: 0.3126673336903119,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c114',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_850337_1568798541299_ZST5D1Wx7Z8liIqDAgR.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask1',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c115',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask2',
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '뒷면',
                          size_info: {
                            top: 118.20930232558139,
                            left: 178.85581395348837,
                            right: 177.82790697674417,
                            width: 527.3162790697673,
                            bottom: 136.71162790697673,
                            height: 629.0790697674419,
                            x_diff: -0.5139534883720955,
                            y_diff: 9.25116279069767,
                          },
                          cv_shading: {
                            cid: 'c116',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_shading',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 46,
                                },
                                px: {
                                  top: 226,
                                  left: 306,
                                  width: 248,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 46,
                                },
                                px: {
                                  top: 226,
                                  left: 302,
                                  width: 256,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 299,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 47,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 299,
                                  width: 264,
                                  height: 376,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 295,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 48,
                                },
                                px: {
                                  top: 226,
                                  left: 295,
                                  width: 272,
                                  height: 384,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Back',
                          face_name_jp: '背面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c118',
                            top: 224,
                            fill: 'rgba(0,0,0,0)',
                            left: 297,
                            type: 'rect',
                            _data: {
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 226,
                                left: 299,
                                width: 264,
                                height: 376,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 378,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [
                            {
                              id: 22391419,
                              url: '//s3.marpple.co/files/u_1885698/2022/11/original/947bf22dd276136b8e7d20ac8e26c5a744e5cd3a1.png',
                              name: '947bf22dd276136b8e7d20ac8e26c5a744e5cd3a1.png',
                              o_id: null,
                              size: 253515,
                              type: 'image/png',
                              width: 2828,
                              ai_url: null,
                              height: 579,
                              user_id: 1885698,
                              is_hidden: false,
                              o_user_id: null,
                              created_at: '2022-11-10T06:18:59.999Z',
                              updated_at: null,
                              attached_id: null,
                              attached_type: 'printable_products',
                              o_attached_id: null,
                              original_name: '주문번호_1348595_상품번호_8847823_뒷면_0.png',
                              press_type_id: 218,
                              unique_in_site: null,
                              o_attached_type: null,
                              press_type_name: '디티에프',
                            },
                          ],
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                      ],
                      can_not_change_color: false,
                    },
                    updated_at: '2022-11-10T09:58:33.880Z',
                    base_product_size_id: 8467,
                  },
                },
              },
              up_c_ss: {
                id: 8847823,
                type: 'projection',
                is_selected: true,
                quantity: 2,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.724Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8465,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: 11639221,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                _name: 'S',
                _name_en: 'S',
                _is_not_stock: false,
                _bp_stock_id: 27163,
                _no: 1,
                oem_price: 0,
                collabo_type: '',
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                _name_jp: 'S',
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                spo_item_id: null,
                original_quantity: null,
                is_pb: false,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                goods_type_id: null,
                _is_discontinued: false,
                _: {
                  base_product_size: {
                    name: 'S',
                    id: 8465,
                  },
                  base_product_color: {
                    name: '네이비',
                    id: 35570,
                  },
                },
              },
            },
          },
        ],
        projection: {
          status: 'before_deliver',
          projection_id: 1348595,
          is_cancel_req: false,
          is_canceled: false,
          is_hidden: false,
          id: 1348595,
        },
        lgl_inbound_order_status_histories: [
          {
            lgl_inbound_order_id: 402,
            status: 'INSPECTED',
            status_at: '2024-01-29T05:02:58.006Z',
            id: 227,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'INSPECTING',
            status_at: '2024-01-29T05:01:39.887Z',
            id: 102,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'RECEIVED',
            status_at: '2024-01-29T04:57:25.030Z',
            id: 225,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'ARRIVED',
            status_at: '2024-01-29T04:47:34.111Z',
            id: 100,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'SHIPOUT',
            status_at: '2024-01-29T04:45:15.649Z',
            id: 97,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'REQUESTED',
            status_at: '2024-01-29T04:43:59.735Z',
            id: 220,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'PACKED',
            status_at: '2024-01-29T04:43:56.359Z',
            id: 79,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'PACKING',
            status_at: '2024-01-29T04:43:31.911Z',
            id: 218,
          },
        ],
        current_status: {
          id: 'INSPECTED',
          is_cancelable: false,
          is_returnable: true,
        },
      },
    },
    {
      id: 402,
      created_at: '2024-01-29T04:43:31.911Z',
      updated_at: '2024-01-29T05:02:58.006Z',
      projection_id: 1348595,
      lgl_inbound_trans_code_id: 'CJ',
      waybill_no_domestic: '540117894335',
      lgl_inbound_order_group_id: 303,
      current_status: 'PACKING',
      combined_projection_id: 1348595,
      _: {
        lgl_items: [
          {
            id: 1566,
            created_at: '2024-01-29T04:43:38.151Z',
            updated_at: '2024-01-30T02:31:17.885Z',
            lgl_product_id: 1863,
            user_product_id: 8847824,
            projection_id: 1348595,
            combined_projection_id: 1348595,
            lgl_inbound_order_id: 402,
            lgl_outbound_order_id: null,
            lgl_return_order_id: null,
            lgl_inbound_order_group_id: 303,
            lgl_return_order_group_id: null,
            lgl_outbound_order_group_id: null,
            _: {
              lgl_return_orders: {},
              lgl_item_quantities: {
                id: 1434,
                lgl_item_id: 1566,
                inbound_order_requested_quantity: 10,
                inbound_order_confirmed_quantity: 10,
                return_order_requested_quantity: null,
                return_order_confirmed_quantity: null,
                outbound_order_requested_quantity: 10,
                outbound_order_confirmed_quantity: null,
              },
              lgl_product: {
                id: 1863,
                created_at: '2024-01-29T04:43:38.151Z',
                updated_at: null,
                sku_id: 101477,
                lgl_seller_id: 1,
              },
              lgl_item_inbound_inspections: {
                id: 170,
                lgl_item_id: 1566,
                is_ok: true,
                work_type: 'G',
                work_url: null,
                work_reason: null,
                reject_url: null,
                reject_reason: null,
                memo: null,
                inspected_at: '2024-01-29T05:01:39.961Z',
              },
              up_c: {
                id: 8847824,
                type: 'projection',
                is_selected: true,
                quantity: 10,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.729Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8466,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: null,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                o_id: null,
                o_pp_id: null,
                o_pcp_id: null,
                o_user_id: null,
                o_projection_id: null,
                o_base_product_size_id: null,
                o_colored_preview_id: null,
                o_copied_colored_preview_id: null,
                up_main: null,
                upc_main: null,
                collabo_type: '',
                oem_price: 0,
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                original_quantity: null,
                is_pb: false,
                spo_item_id: null,
                goods_type_id: null,
                partial_delivery_at: null,
                _: {
                  base_product: {
                    name: '길단 12000 크루넥 맨투맨(USA핏)',
                    id: 4042,
                  },
                  product_color: {
                    name: null,
                    product_faces2: {
                      value: [
                        {
                          debug: {},
                          bpf_id: 7663,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c209',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848622_1568783363805_i0eDm0Hq8aVlm3aK2xKK.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c50',
                              src: '//s3.marpple.co/files/guest/2022/11/900/ede03535df534f84a3483dca7651681a14befd601.png',
                              top: 263.5365073919586,
                              fill: 'rgb(0,0,0)',
                              left: 490.12456091926396,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742996,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 9,
                                height_cm: 6,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/ede03535df534f84a3483dca7651681a14befd601.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 2874,
                                image_height: 1934,
                                real_width_cm: 9,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 6,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                is_low_quality_agree: false,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.999232,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 403.757312,
                              scaleX: 0.11979255180235973,
                              scaleY: 0.11979255180235973,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c210',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848642_1568783437332_s3KUE2h4FAMadAtgPq.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c211',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '앞면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 101.25058139534885,
                            right: 100.6703488372093,
                            width: 297.07906976744187,
                            bottom: 78.62151162790698,
                            height: 353.6517441860465,
                            x_diff: -0.2901162790697711,
                            y_diff: 5.947383720930233,
                          },
                          cv_shading: {
                            cid: 'c212',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 40,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 306,
                                  width: 248,
                                  height: 320,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 42,
                                },
                                px: {
                                  top: 242,
                                  left: 302,
                                  width: 256,
                                  height: 336,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 298,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 44,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 298,
                                  width: 264,
                                  height: 352,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 294,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 46,
                                },
                                px: {
                                  top: 242,
                                  left: 294,
                                  width: 272,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Front',
                          face_name_jp: '前面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c249',
                            top: 240,
                            fill: 'rgba(0,0,0,0)',
                            left: 296,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 242,
                                left: 298,
                                width: 264,
                                height: 352,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 44.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 44.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 354,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                        {
                          debug: {},
                          bpf_id: 7661,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c216',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848621_1568783360511_U6NnXJsss0OL1UsiFA.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c58',
                              src: '//s3.marpple.co/files/guest/2022/11/900/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                              top: 329.50662377386357,
                              fill: 'rgb(0,0,0)',
                              left: 337.2,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742987,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 23.4,
                                height_cm: 4.3,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 6080,
                                image_height: 1120,
                                real_width_cm: 23.4,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 4.3,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.9987199999999,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 110.52608,
                              scaleX: 0.3126673336903119,
                              scaleY: 0.3126673336903119,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c217',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_850337_1568798541299_ZST5D1Wx7Z8liIqDAgR.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c218',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '뒷면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 100.96046511627907,
                            right: 100.38023255813954,
                            width: 297.6593023255814,
                            bottom: 77.17093023255813,
                            height: 355.10232558139535,
                            x_diff: -0.290116279069764,
                            y_diff: 5.222093023255809,
                          },
                          cv_shading: {
                            cid: 'c219',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 46,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 306,
                                  width: 248,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 46,
                                },
                                px: {
                                  top: 226,
                                  left: 302,
                                  width: 256,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 299,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 47,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 299,
                                  width: 264,
                                  height: 376,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 295,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 48,
                                },
                                px: {
                                  top: 226,
                                  left: 295,
                                  width: 272,
                                  height: 384,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Back',
                          face_name_jp: '背面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c251',
                            top: 224,
                            fill: 'rgba(0,0,0,0)',
                            left: 297,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 226,
                                left: 299,
                                width: 264,
                                height: 376,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 47.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 47.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 378,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                      ],
                      can_not_change_color: false,
                    },
                    thumbnails: null,
                    id: 11631021,
                  },
                  printable_product: {},
                },
              },
              up_c_ss: {
                id: 8847824,
                type: 'projection',
                is_selected: true,
                quantity: 10,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.729Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8466,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: null,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                _name: 'M',
                _name_en: 'M',
                _is_not_stock: false,
                _bp_stock_id: 27164,
                _no: 2,
                oem_price: 0,
                collabo_type: '',
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                _name_jp: 'M',
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                spo_item_id: null,
                original_quantity: null,
                is_pb: false,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                goods_type_id: null,
                _is_discontinued: false,
                _: {
                  base_product_size: {
                    name: 'M',
                    id: 8466,
                  },
                  base_product_color: {
                    name: '네이비',
                    id: 35570,
                  },
                },
              },
            },
          },
          {
            id: 1567,
            created_at: '2024-01-29T04:43:38.148Z',
            updated_at: '2024-01-30T02:31:17.885Z',
            lgl_product_id: 1864,
            user_product_id: 8847823,
            projection_id: 1348595,
            combined_projection_id: 1348595,
            lgl_inbound_order_id: 402,
            lgl_outbound_order_id: null,
            lgl_return_order_id: null,
            lgl_inbound_order_group_id: 303,
            lgl_return_order_group_id: null,
            lgl_outbound_order_group_id: null,
            _: {
              lgl_return_orders: {},
              lgl_item_quantities: {
                id: 1435,
                lgl_item_id: 1567,
                inbound_order_requested_quantity: 2,
                inbound_order_confirmed_quantity: 2,
                return_order_requested_quantity: null,
                return_order_confirmed_quantity: null,
                outbound_order_requested_quantity: 2,
                outbound_order_confirmed_quantity: null,
              },
              lgl_product: {
                id: 1864,
                created_at: '2024-01-29T04:43:38.148Z',
                updated_at: null,
                sku_id: 101478,
                lgl_seller_id: 1,
              },
              lgl_item_inbound_inspections: {
                id: 171,
                lgl_item_id: 1567,
                is_ok: true,
                work_type: 'G',
                work_url: null,
                work_reason: null,
                reject_url: null,
                reject_reason: null,
                memo: null,
                inspected_at: '2024-01-29T05:02:58.091Z',
              },
              up_c: {
                id: 8847823,
                type: 'projection',
                is_selected: true,
                quantity: 2,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.724Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8465,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: 11639221,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                o_id: null,
                o_pp_id: null,
                o_pcp_id: null,
                o_user_id: null,
                o_projection_id: null,
                o_base_product_size_id: null,
                o_colored_preview_id: null,
                o_copied_colored_preview_id: null,
                up_main: null,
                upc_main: null,
                collabo_type: '',
                oem_price: 0,
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                original_quantity: null,
                is_pb: false,
                spo_item_id: null,
                goods_type_id: null,
                partial_delivery_at: null,
                _: {
                  base_product: {
                    name: '길단 12000 크루넥 맨투맨(USA핏)',
                    id: 4042,
                  },
                  product_color: {
                    name: null,
                    product_faces2: {
                      value: [
                        {
                          debug: {},
                          bpf_id: 7663,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c209',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848622_1568783363805_i0eDm0Hq8aVlm3aK2xKK.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c50',
                              src: '//s3.marpple.co/files/guest/2022/11/900/ede03535df534f84a3483dca7651681a14befd601.png',
                              top: 263.5365073919586,
                              fill: 'rgb(0,0,0)',
                              left: 490.12456091926396,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742996,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 9,
                                height_cm: 6,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/ede03535df534f84a3483dca7651681a14befd601.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 2874,
                                image_height: 1934,
                                real_width_cm: 9,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 6,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                is_low_quality_agree: false,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.999232,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 403.757312,
                              scaleX: 0.11979255180235973,
                              scaleY: 0.11979255180235973,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c210',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848642_1568783437332_s3KUE2h4FAMadAtgPq.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c211',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '앞면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 101.25058139534885,
                            right: 100.6703488372093,
                            width: 297.07906976744187,
                            bottom: 78.62151162790698,
                            height: 353.6517441860465,
                            x_diff: -0.2901162790697711,
                            y_diff: 5.947383720930233,
                          },
                          cv_shading: {
                            cid: 'c212',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 40,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 306,
                                  width: 248,
                                  height: 320,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 42,
                                },
                                px: {
                                  top: 242,
                                  left: 302,
                                  width: 256,
                                  height: 336,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 298,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 44,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 298,
                                  width: 264,
                                  height: 352,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 294,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 46,
                                },
                                px: {
                                  top: 242,
                                  left: 294,
                                  width: 272,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Front',
                          face_name_jp: '前面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c249',
                            top: 240,
                            fill: 'rgba(0,0,0,0)',
                            left: 296,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 242,
                                left: 298,
                                width: 264,
                                height: 352,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 44.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 44.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 354,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                        {
                          debug: {},
                          bpf_id: 7661,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c216',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848621_1568783360511_U6NnXJsss0OL1UsiFA.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c58',
                              src: '//s3.marpple.co/files/guest/2022/11/900/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                              top: 329.50662377386357,
                              fill: 'rgb(0,0,0)',
                              left: 337.2,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742987,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 23.4,
                                height_cm: 4.3,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 6080,
                                image_height: 1120,
                                real_width_cm: 23.4,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 4.3,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.9987199999999,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 110.52608,
                              scaleX: 0.3126673336903119,
                              scaleY: 0.3126673336903119,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c217',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_850337_1568798541299_ZST5D1Wx7Z8liIqDAgR.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c218',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '뒷면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 100.96046511627907,
                            right: 100.38023255813954,
                            width: 297.6593023255814,
                            bottom: 77.17093023255813,
                            height: 355.10232558139535,
                            x_diff: -0.290116279069764,
                            y_diff: 5.222093023255809,
                          },
                          cv_shading: {
                            cid: 'c219',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 46,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 306,
                                  width: 248,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 46,
                                },
                                px: {
                                  top: 226,
                                  left: 302,
                                  width: 256,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 299,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 47,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 299,
                                  width: 264,
                                  height: 376,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 295,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 48,
                                },
                                px: {
                                  top: 226,
                                  left: 295,
                                  width: 272,
                                  height: 384,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Back',
                          face_name_jp: '背面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c251',
                            top: 224,
                            fill: 'rgba(0,0,0,0)',
                            left: 297,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 226,
                                left: 299,
                                width: 264,
                                height: 376,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 47.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 47.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 378,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                      ],
                      can_not_change_color: false,
                    },
                    thumbnails: null,
                    id: 11631021,
                  },
                  printable_product: {
                    id: 11639221,
                    name: null,
                    product_faces2: {
                      value: [
                        {
                          debug: {},
                          bpf_id: 7663,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c99',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848622_1568783363805_i0eDm0Hq8aVlm3aK2xKK.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_bpcf',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c50',
                              src: '//s3.marpple.co/files/guest/2022/11/900/ede03535df534f84a3483dca7651681a14befd601.png',
                              top: 263.5365073919586,
                              fill: 'rgb(0,0,0)',
                              left: 490.12456091926396,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742996,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 9,
                                height_cm: 6,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/ede03535df534f84a3483dca7651681a14befd601.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 2874,
                                image_height: 1934,
                                real_width_cm: 9,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 6,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                is_low_quality_agree: false,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.999232,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 403.757312,
                              scaleX: 0.11979255180235973,
                              scaleY: 0.11979255180235973,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c108',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848642_1568783437332_s3KUE2h4FAMadAtgPq.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask1',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c109',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask2',
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '앞면',
                          size_info: {
                            top: 118.20930232558139,
                            left: 178.85581395348837,
                            right: 177.82790697674417,
                            width: 527.3162790697673,
                            bottom: 139.7953488372093,
                            height: 625.9953488372092,
                            x_diff: -0.5139534883720955,
                            y_diff: 10.793023255813956,
                          },
                          cv_shading: {
                            cid: 'c110',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_shading',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 40,
                                },
                                px: {
                                  top: 242,
                                  left: 306,
                                  width: 248,
                                  height: 320,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 42,
                                },
                                px: {
                                  top: 242,
                                  left: 302,
                                  width: 256,
                                  height: 336,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 298,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 44,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 298,
                                  width: 264,
                                  height: 352,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 294,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 46,
                                },
                                px: {
                                  top: 242,
                                  left: 294,
                                  width: 272,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Front',
                          face_name_jp: '前面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c112',
                            top: 240,
                            fill: 'rgba(0,0,0,0)',
                            left: 296,
                            type: 'rect',
                            _data: {
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 242,
                                left: 298,
                                width: 264,
                                height: 352,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 354,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [
                            {
                              id: 22391387,
                              url: '//s3.marpple.co/files/u_1885698/2022/11/original/88671dbb31ad42572a74ecd86307116b4ef0eeb31.png',
                              name: '88671dbb31ad42572a74ecd86307116b4ef0eeb31.png',
                              o_id: null,
                              size: 175599,
                              type: 'image/png',
                              width: 1063,
                              ai_url: null,
                              height: 716,
                              user_id: 1885698,
                              is_hidden: false,
                              o_user_id: null,
                              created_at: '2022-11-10T06:18:18.575Z',
                              updated_at: null,
                              attached_id: null,
                              attached_type: 'printable_products',
                              o_attached_id: null,
                              original_name: '주문번호_1348595_상품번호_8847823_앞면_0.png',
                              press_type_id: 218,
                              unique_in_site: null,
                              o_attached_type: null,
                              press_type_name: '디티에프',
                            },
                          ],
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                        {
                          debug: {},
                          bpf_id: 7661,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c106',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848621_1568783360511_U6NnXJsss0OL1UsiFA.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_bpcf',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c58',
                              src: '//s3.marpple.co/files/guest/2022/11/900/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                              top: 258.16273237114865,
                              fill: 'rgb(0,0,0)',
                              left: 337.2,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742987,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 23.4,
                                height_cm: 4.3,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 6080,
                                image_height: 1120,
                                real_width_cm: 23.4,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 4.3,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.9987199999999,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 110.52608,
                              scaleX: 0.3126673336903119,
                              scaleY: 0.3126673336903119,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c114',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_850337_1568798541299_ZST5D1Wx7Z8liIqDAgR.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask1',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c115',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask2',
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '뒷면',
                          size_info: {
                            top: 118.20930232558139,
                            left: 178.85581395348837,
                            right: 177.82790697674417,
                            width: 527.3162790697673,
                            bottom: 136.71162790697673,
                            height: 629.0790697674419,
                            x_diff: -0.5139534883720955,
                            y_diff: 9.25116279069767,
                          },
                          cv_shading: {
                            cid: 'c116',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_shading',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 46,
                                },
                                px: {
                                  top: 226,
                                  left: 306,
                                  width: 248,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 46,
                                },
                                px: {
                                  top: 226,
                                  left: 302,
                                  width: 256,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 299,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 47,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 299,
                                  width: 264,
                                  height: 376,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 295,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 48,
                                },
                                px: {
                                  top: 226,
                                  left: 295,
                                  width: 272,
                                  height: 384,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Back',
                          face_name_jp: '背面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c118',
                            top: 224,
                            fill: 'rgba(0,0,0,0)',
                            left: 297,
                            type: 'rect',
                            _data: {
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 226,
                                left: 299,
                                width: 264,
                                height: 376,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 378,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [
                            {
                              id: 22391419,
                              url: '//s3.marpple.co/files/u_1885698/2022/11/original/947bf22dd276136b8e7d20ac8e26c5a744e5cd3a1.png',
                              name: '947bf22dd276136b8e7d20ac8e26c5a744e5cd3a1.png',
                              o_id: null,
                              size: 253515,
                              type: 'image/png',
                              width: 2828,
                              ai_url: null,
                              height: 579,
                              user_id: 1885698,
                              is_hidden: false,
                              o_user_id: null,
                              created_at: '2022-11-10T06:18:59.999Z',
                              updated_at: null,
                              attached_id: null,
                              attached_type: 'printable_products',
                              o_attached_id: null,
                              original_name: '주문번호_1348595_상품번호_8847823_뒷면_0.png',
                              press_type_id: 218,
                              unique_in_site: null,
                              o_attached_type: null,
                              press_type_name: '디티에프',
                            },
                          ],
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                      ],
                      can_not_change_color: false,
                    },
                    updated_at: '2022-11-10T09:58:33.880Z',
                    base_product_size_id: 8467,
                  },
                },
              },
              up_c_ss: {
                id: 8847823,
                type: 'projection',
                is_selected: true,
                quantity: 2,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.724Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8465,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: 11639221,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                _name: 'S',
                _name_en: 'S',
                _is_not_stock: false,
                _bp_stock_id: 27163,
                _no: 1,
                oem_price: 0,
                collabo_type: '',
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                _name_jp: 'S',
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                spo_item_id: null,
                original_quantity: null,
                is_pb: false,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                goods_type_id: null,
                _is_discontinued: false,
                _: {
                  base_product_size: {
                    name: 'S',
                    id: 8465,
                  },
                  base_product_color: {
                    name: '네이비',
                    id: 35570,
                  },
                },
              },
            },
          },
        ],
        projection: {
          status: 'before_deliver',
          projection_id: 1348595,
          is_cancel_req: false,
          is_canceled: false,
          is_hidden: false,
          id: 1348595,
        },
        lgl_inbound_order_status_histories: [
          {
            lgl_inbound_order_id: 402,
            status: 'INSPECTED',
            status_at: '2024-01-29T05:02:58.006Z',
            id: 227,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'INSPECTING',
            status_at: '2024-01-29T05:01:39.887Z',
            id: 102,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'RECEIVED',
            status_at: '2024-01-29T04:57:25.030Z',
            id: 225,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'ARRIVED',
            status_at: '2024-01-29T04:47:34.111Z',
            id: 100,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'SHIPOUT',
            status_at: '2024-01-29T04:45:15.649Z',
            id: 97,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'REQUESTED',
            status_at: '2024-01-29T04:43:59.735Z',
            id: 220,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'PACKED',
            status_at: '2024-01-29T04:43:56.359Z',
            id: 79,
          },
          {
            lgl_inbound_order_id: 402,
            status: 'PACKING',
            status_at: '2024-01-29T04:43:31.911Z',
            id: 218,
          },
        ],
        current_status: {
          id: 'INSPECTED',
          is_cancelable: false,
          is_returnable: true,
        },
      },
    },
    {
      id: 403,
      created_at: '2024-01-29T04:43:34.831Z',
      updated_at: '2024-01-29T05:06:05.819Z',
      projection_id: 1348595,
      lgl_inbound_trans_code_id: 'CJ',
      waybill_no_domestic: '540117894464',
      lgl_inbound_order_group_id: 303,
      current_status: 'PACKING',
      combined_projection_id: 1348595,
      _: {
        lgl_items: [
          {
            id: 1568,
            created_at: '2024-01-29T04:43:49.016Z',
            updated_at: '2024-01-30T02:31:17.885Z',
            lgl_product_id: 1862,
            user_product_id: 8847825,
            projection_id: 1348595,
            combined_projection_id: 1348595,
            lgl_inbound_order_id: 403,
            lgl_outbound_order_id: null,
            lgl_return_order_id: null,
            lgl_inbound_order_group_id: 303,
            lgl_return_order_group_id: null,
            lgl_outbound_order_group_id: null,
            _: {
              lgl_return_orders: {},
              lgl_item_quantities: {
                id: 1436,
                lgl_item_id: 1568,
                inbound_order_requested_quantity: 8,
                inbound_order_confirmed_quantity: 8,
                return_order_requested_quantity: null,
                return_order_confirmed_quantity: null,
                outbound_order_requested_quantity: 8,
                outbound_order_confirmed_quantity: null,
              },
              lgl_product: {
                id: 1862,
                created_at: '2024-01-29T04:43:38.154Z',
                updated_at: null,
                sku_id: 101476,
                lgl_seller_id: 1,
              },
              lgl_item_inbound_inspections: {
                id: 172,
                lgl_item_id: 1568,
                is_ok: true,
                work_type: 'G',
                work_url: null,
                work_reason: null,
                reject_url: null,
                reject_reason: null,
                memo: null,
                inspected_at: '2024-01-29T05:06:05.916Z',
              },
              up_c: {
                id: 8847825,
                type: 'projection',
                is_selected: true,
                quantity: 8,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.729Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8467,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: null,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                o_id: null,
                o_pp_id: null,
                o_pcp_id: null,
                o_user_id: null,
                o_projection_id: null,
                o_base_product_size_id: null,
                o_colored_preview_id: null,
                o_copied_colored_preview_id: null,
                up_main: null,
                upc_main: null,
                collabo_type: '',
                oem_price: 0,
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                original_quantity: null,
                is_pb: false,
                spo_item_id: null,
                goods_type_id: null,
                partial_delivery_at: null,
                _: {
                  base_product: {
                    name: '길단 12000 크루넥 맨투맨(USA핏)',
                    id: 4042,
                  },
                  product_color: {
                    name: null,
                    product_faces2: {
                      value: [
                        {
                          debug: {},
                          bpf_id: 7663,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c209',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848622_1568783363805_i0eDm0Hq8aVlm3aK2xKK.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c50',
                              src: '//s3.marpple.co/files/guest/2022/11/900/ede03535df534f84a3483dca7651681a14befd601.png',
                              top: 263.5365073919586,
                              fill: 'rgb(0,0,0)',
                              left: 490.12456091926396,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742996,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 9,
                                height_cm: 6,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/ede03535df534f84a3483dca7651681a14befd601.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 2874,
                                image_height: 1934,
                                real_width_cm: 9,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 6,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                is_low_quality_agree: false,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.999232,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 403.757312,
                              scaleX: 0.11979255180235973,
                              scaleY: 0.11979255180235973,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c210',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848642_1568783437332_s3KUE2h4FAMadAtgPq.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c211',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '앞면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 101.25058139534885,
                            right: 100.6703488372093,
                            width: 297.07906976744187,
                            bottom: 78.62151162790698,
                            height: 353.6517441860465,
                            x_diff: -0.2901162790697711,
                            y_diff: 5.947383720930233,
                          },
                          cv_shading: {
                            cid: 'c212',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 40,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 306,
                                  width: 248,
                                  height: 320,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 42,
                                },
                                px: {
                                  top: 242,
                                  left: 302,
                                  width: 256,
                                  height: 336,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 298,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 44,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 298,
                                  width: 264,
                                  height: 352,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 294,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 46,
                                },
                                px: {
                                  top: 242,
                                  left: 294,
                                  width: 272,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Front',
                          face_name_jp: '前面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c249',
                            top: 240,
                            fill: 'rgba(0,0,0,0)',
                            left: 296,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 242,
                                left: 298,
                                width: 264,
                                height: 352,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 44.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 44.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 354,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                        {
                          debug: {},
                          bpf_id: 7661,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c216',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848621_1568783360511_U6NnXJsss0OL1UsiFA.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c58',
                              src: '//s3.marpple.co/files/guest/2022/11/900/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                              top: 329.50662377386357,
                              fill: 'rgb(0,0,0)',
                              left: 337.2,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742987,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 23.4,
                                height_cm: 4.3,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 6080,
                                image_height: 1120,
                                real_width_cm: 23.4,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 4.3,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.9987199999999,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 110.52608,
                              scaleX: 0.3126673336903119,
                              scaleY: 0.3126673336903119,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c217',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_850337_1568798541299_ZST5D1Wx7Z8liIqDAgR.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c218',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '뒷면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 100.96046511627907,
                            right: 100.38023255813954,
                            width: 297.6593023255814,
                            bottom: 77.17093023255813,
                            height: 355.10232558139535,
                            x_diff: -0.290116279069764,
                            y_diff: 5.222093023255809,
                          },
                          cv_shading: {
                            cid: 'c219',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 46,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 306,
                                  width: 248,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 46,
                                },
                                px: {
                                  top: 226,
                                  left: 302,
                                  width: 256,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 299,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 47,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 299,
                                  width: 264,
                                  height: 376,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 295,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 48,
                                },
                                px: {
                                  top: 226,
                                  left: 295,
                                  width: 272,
                                  height: 384,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Back',
                          face_name_jp: '背面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c251',
                            top: 224,
                            fill: 'rgba(0,0,0,0)',
                            left: 297,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 226,
                                left: 299,
                                width: 264,
                                height: 376,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 47.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 47.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 378,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                      ],
                      can_not_change_color: false,
                    },
                    thumbnails: null,
                    id: 11631021,
                  },
                  printable_product: {},
                },
              },
              up_c_ss: {
                id: 8847825,
                type: 'projection',
                is_selected: true,
                quantity: 8,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.729Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8467,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: null,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                _name: 'L',
                _name_en: 'L',
                _is_not_stock: false,
                _bp_stock_id: 27165,
                _no: 3,
                oem_price: 0,
                collabo_type: '',
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                _name_jp: 'L',
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                spo_item_id: null,
                original_quantity: null,
                is_pb: false,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                goods_type_id: null,
                _is_discontinued: false,
                _: {
                  base_product_size: {
                    name: 'L',
                    id: 8467,
                  },
                  base_product_color: {
                    name: '네이비',
                    id: 35570,
                  },
                },
              },
            },
          },
        ],
        projection: {
          status: 'before_deliver',
          projection_id: 1348595,
          is_cancel_req: false,
          is_canceled: false,
          is_hidden: false,
          id: 1348595,
        },
        lgl_inbound_order_status_histories: [
          {
            lgl_inbound_order_id: 403,
            status: 'INSPECTED',
            status_at: '2024-01-29T05:06:05.819Z',
            id: 103,
          },
          {
            lgl_inbound_order_id: 403,
            status: 'RECEIVED',
            status_at: '2024-01-29T04:57:43.340Z',
            id: 101,
          },
          {
            lgl_inbound_order_id: 403,
            status: 'ARRIVED',
            status_at: '2024-01-29T04:47:30.393Z',
            id: 224,
          },
          {
            lgl_inbound_order_id: 403,
            status: 'SHIPOUT',
            status_at: '2024-01-29T04:45:23.185Z',
            id: 223,
          },
          {
            lgl_inbound_order_id: 403,
            status: 'REQUESTED',
            status_at: '2024-01-29T04:43:59.735Z',
            id: 221,
          },
          {
            lgl_inbound_order_id: 403,
            status: 'PACKED',
            status_at: '2024-01-29T04:43:56.359Z',
            id: 95,
          },
          {
            lgl_inbound_order_id: 403,
            status: 'PACKING',
            status_at: '2024-01-29T04:43:34.831Z',
            id: 78,
          },
        ],
        current_status: {
          id: 'INSPECTED',
          is_cancelable: false,
          is_returnable: true,
        },
      },
    },
    {
      id: 404,
      created_at: '2024-01-29T04:43:45.488Z',
      updated_at: '2024-02-01T14:20:53.409Z',
      projection_id: 1348595,
      lgl_inbound_trans_code_id: 'CJ',
      waybill_no_domestic: '530118201871',
      lgl_inbound_order_group_id: 303,
      current_status: 'PACKING',
      combined_projection_id: 1348595,
      _: {
        lgl_items: [
          {
            id: 1569,
            created_at: '2024-01-29T04:43:52.159Z',
            updated_at: '2024-01-30T02:31:17.885Z',
            lgl_product_id: 1866,
            user_product_id: 8847826,
            projection_id: 1348595,
            combined_projection_id: 1348595,
            lgl_inbound_order_id: 404,
            lgl_outbound_order_id: null,
            lgl_return_order_id: null,
            lgl_inbound_order_group_id: 303,
            lgl_return_order_group_id: null,
            lgl_outbound_order_group_id: null,
            _: {
              lgl_return_orders: {},
              lgl_item_quantities: {
                id: 1437,
                lgl_item_id: 1569,
                inbound_order_requested_quantity: 4,
                inbound_order_confirmed_quantity: 0,
                return_order_requested_quantity: null,
                return_order_confirmed_quantity: null,
                outbound_order_requested_quantity: 4,
                outbound_order_confirmed_quantity: null,
              },
              lgl_product: {
                id: 1866,
                created_at: '2024-01-29T04:43:52.159Z',
                updated_at: null,
                sku_id: 101480,
                lgl_seller_id: 1,
              },
              lgl_item_inbound_inspections: {
                id: 199,
                lgl_item_id: 1569,
                is_ok: false,
                work_type: null,
                work_url: null,
                work_reason: null,
                reject_url: 'google.com',
                reject_reason: 3,
                memo: null,
                inspected_at: '2024-02-01T14:20:53.455Z',
              },
              up_c: {
                id: 8847826,
                type: 'projection',
                is_selected: true,
                quantity: 4,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.729Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8468,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: null,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                o_id: null,
                o_pp_id: null,
                o_pcp_id: null,
                o_user_id: null,
                o_projection_id: null,
                o_base_product_size_id: null,
                o_colored_preview_id: null,
                o_copied_colored_preview_id: null,
                up_main: null,
                upc_main: null,
                collabo_type: '',
                oem_price: 0,
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                original_quantity: null,
                is_pb: false,
                spo_item_id: null,
                goods_type_id: null,
                partial_delivery_at: null,
                _: {
                  base_product: {
                    name: '길단 12000 크루넥 맨투맨(USA핏)',
                    id: 4042,
                  },
                  product_color: {
                    name: null,
                    product_faces2: {
                      value: [
                        {
                          debug: {},
                          bpf_id: 7663,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c209',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848622_1568783363805_i0eDm0Hq8aVlm3aK2xKK.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c50',
                              src: '//s3.marpple.co/files/guest/2022/11/900/ede03535df534f84a3483dca7651681a14befd601.png',
                              top: 263.5365073919586,
                              fill: 'rgb(0,0,0)',
                              left: 490.12456091926396,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742996,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 9,
                                height_cm: 6,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/ede03535df534f84a3483dca7651681a14befd601.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 2874,
                                image_height: 1934,
                                real_width_cm: 9,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 6,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                is_low_quality_agree: false,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.999232,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 403.757312,
                              scaleX: 0.11979255180235973,
                              scaleY: 0.11979255180235973,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c210',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848642_1568783437332_s3KUE2h4FAMadAtgPq.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c211',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '앞면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 101.25058139534885,
                            right: 100.6703488372093,
                            width: 297.07906976744187,
                            bottom: 78.62151162790698,
                            height: 353.6517441860465,
                            x_diff: -0.2901162790697711,
                            y_diff: 5.947383720930233,
                          },
                          cv_shading: {
                            cid: 'c212',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 40,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 306,
                                  width: 248,
                                  height: 320,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 42,
                                },
                                px: {
                                  top: 242,
                                  left: 302,
                                  width: 256,
                                  height: 336,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 4,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 298,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 44,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 242,
                                  left: 298,
                                  width: 264,
                                  height: 352,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 294,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 46,
                                },
                                px: {
                                  top: 242,
                                  left: 294,
                                  width: 272,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 5,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Front',
                          face_name_jp: '前面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c249',
                            top: 240,
                            fill: 'rgba(0,0,0,0)',
                            left: 296,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 242,
                                left: 298,
                                width: 264,
                                height: 352,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 44.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 44.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 354,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                        {
                          debug: {},
                          bpf_id: 7661,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c216',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_848621_1568783360511_U6NnXJsss0OL1UsiFA.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_bpcf',
                              width_cm: 82.5,
                              height_cm: 82.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 82.5,
                              real_height_cm: 82.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c58',
                              src: '//s3.marpple.co/files/guest/2022/11/900/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                              top: 329.50662377386357,
                              fill: 'rgb(0,0,0)',
                              left: 337.2,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9742987,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 23.4,
                                height_cm: 4.3,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/11/original/d207b851f5b11738575e1c95d6c28f4831a5f64f1.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 6080,
                                image_height: 1120,
                                real_width_cm: 23.4,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 4.3,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.9987199999999,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 110.52608,
                              scaleX: 0.3126673336903119,
                              scaleY: 0.3126673336903119,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c217',
                            src: '//s3.marpple.co/files/u_1085120/2019/9/original/f_850337_1568798541299_ZST5D1Wx7Z8liIqDAgR.png',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask1',
                              width_cm: 96.3,
                              height_cm: 96.3,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 96.3,
                              real_height_cm: 96.3,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c218',
                            src: '',
                            top: 0,
                            fill: 'rgb(0,0,0)',
                            left: 0,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_mask2',
                              width_cm: 0,
                              height_cm: 0,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 0,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 0,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '뒷면',
                          size_info: {
                            top: 66.72674418604652,
                            left: 100.96046511627907,
                            right: 100.38023255813954,
                            width: 297.6593023255814,
                            bottom: 77.17093023255813,
                            height: 355.10232558139535,
                            x_diff: -0.290116279069764,
                            y_diff: 5.222093023255809,
                          },
                          cv_shading: {
                            cid: 'c219',
                            src: '',
                            top: 94,
                            fill: 'rgb(0,0,0)',
                            left: 100,
                            type: 'image',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_shading',
                              width_cm: 0,
                              height_cm: 0,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 0,
                              real_height_cm: 0,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 660,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 660,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              img: {
                                diff: 15,
                                width: 620,
                                height: 620,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 31,
                                  height: 51,
                                },
                                px: {
                                  top: 210,
                                  left: 306,
                                  width: 248,
                                  height: 408,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 31,
                                  height: 46,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 306,
                                  width: 248,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8465,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 640,
                                height: 640,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 32,
                                  height: 53,
                                },
                                px: {
                                  top: 210,
                                  left: 302,
                                  width: 256,
                                  height: 424,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 32,
                                  height: 46,
                                },
                                px: {
                                  top: 226,
                                  left: 302,
                                  width: 256,
                                  height: 368,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 8,
                              base_product_size_id: 8466,
                            },
                            {
                              img: {
                                diff: 6,
                                width: 660,
                                height: 660,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 33,
                                  height: 55,
                                },
                                px: {
                                  top: 202,
                                  left: 299,
                                  width: 264,
                                  height: 440,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 33,
                                  height: 47,
                                },
                                px: {
                                  rx: 0,
                                  ry: 0,
                                  top: 226,
                                  left: 299,
                                  width: 264,
                                  height: 376,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8467,
                            },
                            {
                              img: {
                                diff: 0,
                                width: 680,
                                height: 680,
                              },
                              line: {
                                top: 0,
                                left: 0,
                                width: 0,
                                height: 0,
                              },
                              real: {
                                cm: {
                                  width: 34,
                                  height: 57,
                                },
                                px: {
                                  top: 202,
                                  left: 295,
                                  width: 272,
                                  height: 456,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 34,
                                  height: 48,
                                },
                                px: {
                                  top: 226,
                                  left: 295,
                                  width: 272,
                                  height: 384,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              px_per_1cm: 8,
                              is_standard: false,
                              end_point_cm: 0,
                              start_point_cm: 9,
                              base_product_size_id: 8468,
                            },
                          ],
                          start_name: '시보리',
                          collabo_type: '',
                          face_name_en: 'Back',
                          face_name_jp: '背面',
                          cv_print_area: {
                            rx: 0,
                            ry: 0,
                            cid: 'c251',
                            top: 224,
                            fill: 'rgba(0,0,0,0)',
                            left: 297,
                            type: 'rect',
                            _data: {
                              rotate: 0,
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 0,
                                ry: 0,
                                top: 226,
                                left: 299,
                                width: 264,
                                height: 376,
                                scaleX: 1,
                                scaleY: 1,
                              },
                              width_cm: 33.5,
                              height_cm: 47.5,
                              nscreened: false,
                              is_not_design: true,
                              real_width_cm: 33.5,
                              real_height_cm: 47.5,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 266,
                            clipTo: null,
                            height: 378,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#eaeaea',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: 8467,
                          bpf_mask2_padding_top: 0,
                        },
                      ],
                      can_not_change_color: false,
                    },
                    thumbnails: null,
                    id: 11631021,
                  },
                  printable_product: {},
                },
              },
              up_c_ss: {
                id: 8847826,
                type: 'projection',
                is_selected: true,
                quantity: 4,
                user_id: 2221644,
                product_color_id: 11631021,
                created_at: '2022-11-09T06:11:07.729Z',
                updated_at: '2022-11-10T09:58:33.893Z',
                user_product_id: null,
                projection_id: 1348595,
                base_product_id: 4042,
                base_product_color_id: 35570,
                base_product_size_id: 8468,
                buyer_id: null,
                shipping_id: 1345851,
                printable_product_id: null,
                up_id: 8847823,
                up_c_id: 8847823,
                bp_price: 21000,
                print_price: 16000,
                price: 37000,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2022-11-09T06:11:07.724Z',
                up_c_cart_at: '2022-11-09T06:11:07.724Z',
                discounted_price: 32800,
                bp_price_en: '19.110000',
                print_price_en: '16.000000',
                price_en: '35.110000',
                discounted_price_en: '30.950000',
                memo: null,
                is_review: false,
                review_id: null,
                _name: 'XL',
                _name_en: 'XL',
                _is_not_stock: false,
                _bp_stock_id: 27166,
                _no: 4,
                oem_price: 0,
                collabo_type: '',
                bp_price_jp: 2055,
                print_price_jp: 1600,
                price_jp: 3655,
                discounted_price_jp: 3335,
                _name_jp: 'XL',
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                spo_item_id: null,
                original_quantity: null,
                is_pb: false,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                goods_type_id: null,
                _is_discontinued: false,
                _: {
                  base_product_size: {
                    name: 'XL',
                    id: 8468,
                  },
                  base_product_color: {
                    name: '네이비',
                    id: 35570,
                  },
                },
              },
            },
          },
        ],
        projection: {
          status: 'before_deliver',
          projection_id: 1348595,
          is_cancel_req: false,
          is_canceled: false,
          is_hidden: false,
          id: 1348595,
        },
        lgl_inbound_order_status_histories: [
          {
            lgl_inbound_order_id: 404,
            status: 'INSPECTED',
            status_at: '2024-01-29T05:25:12.706Z',
            id: 228,
          },
          {
            lgl_inbound_order_id: 404,
            status: 'RECEIVED',
            status_at: '2024-01-29T04:57:49.283Z',
            id: 226,
          },
          {
            lgl_inbound_order_id: 404,
            status: 'ARRIVED',
            status_at: '2024-01-29T04:45:56.148Z',
            id: 99,
          },
          {
            lgl_inbound_order_id: 404,
            status: 'SHIPOUT',
            status_at: '2024-01-29T04:45:25.823Z',
            id: 98,
          },
          {
            lgl_inbound_order_id: 404,
            status: 'REQUESTED',
            status_at: '2024-01-29T04:43:59.735Z',
            id: 222,
          },
          {
            lgl_inbound_order_id: 404,
            status: 'PACKED',
            status_at: '2024-01-29T04:43:56.359Z',
            id: 96,
          },
          {
            lgl_inbound_order_id: 404,
            status: 'PACKING',
            status_at: '2024-01-29T04:43:45.488Z',
            id: 219,
          },
        ],
        current_status: {
          id: 'INSPECTED',
          is_cancelable: false,
          is_returnable: true,
        },
      },
    },
  ],
};
