import { ani_slomo_opts, animations } from '../Constant/animations.js';
import { each, entries, go, map, mapC, object, zipL } from 'fxjs/es';
import { $addClass, $closest, $findAll, $hasClass, $removeClass } from 'fxdom/es';
import { EVAL_RESULT_NAMES } from '../Constant/eval_result_names.js';
import localForage from 'localforage';
import { aztec_codes_info } from '../../../Labeling/S/Constant/label_list_constants.js';

export const parseAztecDataAdv = (aztec_val) => {
  return go(aztec_val, zipL(aztec_codes_info), object);
};

export const triggerFocus = (el) => {
  const eventType = 'onfocusin' in el ? 'focusin' : 'focus';
  const bubbles = 'onfocusin' in el;
  let event;

  if ('createEvent' in document) {
    event = document.createEvent('Event');
    event.initEvent(eventType, bubbles, true);
  } else if ('Event' in window) {
    event = new Event(eventType, { bubbles, cancelable: true });
  }
  el.focus();
  el.dispatchEvent(event);
};

export const initializingFocus = (tab_el, wrapper_el, label_scan_el) => {
  document.hasFocus() ? $addClass('focusing', wrapper_el) : $addClass('out_of_focus', wrapper_el);
  tab_el.classList.remove('hidden');
  label_scan_el.focus();
};

export const showAztecCodes =
  (tab_el, is_show = true) =>
  () =>
    go(
      tab_el,
      $closest('.don_frame'),
      $findAll('.aztec_code'),
      each((el) => (is_show ? $removeClass('hidden', el) : $addClass('hidden', el))),
    );

export const updateCounter = (item_res, counter_numbers, counter_els) => {
  const eval_arr = item_res.evaluation;
  if (!item_res.is_repeat) {
    //A. 중복 스캔 X => 신규 데이터임. always add counter
    //SPEAK: evaluation 에 따라 설명해줌
    //    - SUCCESS: 제품 전달해 주세요.
    //    - FAIL: 아직 기다리셔야 해요.
    //    - CANCEL: 취소된 주문이에요. 상품을 제거해 주세요.
    counter_numbers.countByEvaluation(eval_arr[0], null, counter_els);
  } else {
    //B. 중복 스캔
    //  B-1. update O => counter 가감
    //  B-2. update X => counter 유지 (noop)
    const len = eval_arr.length;
    const current_eval = eval_arr[len - 1];
    const prev_eval = eval_arr[len - 2];
    if (item_res.is_update) {
      counter_numbers.countByEvaluation(current_eval, prev_eval, counter_els);
    } else {
      // 업데이트 없이 상황이 유지된 경우 (중복)
      // counter 가감 없이 중복된 것 강조만 진행
      emphasisCounter(current_eval, counter_els);
    }
  }
};

export const downloadSingleAudioThenPlay = async ({ audio_data_title, audio_key, is_play }) => {
  const existed_audio_data = await localForage.getItem(audio_data_title);
  let audio_array_buffer;

  if (existed_audio_data && existed_audio_data[audio_key]) {
    audio_array_buffer = existed_audio_data[audio_key];
    const audio_ctx = new (window.AudioContext || window.webkitAudioContext)();
    const buffer = await audio_ctx.decodeAudioData(audio_array_buffer);
    const result = { audio_ctx, buffer };
    return is_play ? playAudio(result) : result;
  }
};

export const downloadAudios = async (speech_name, speech_data) => {
  let audio_items = {};
  const local_audio_array_buffers = {};
  const local_audio_array_buffer_arr = await localForage.getItem(speech_name);

  if (local_audio_array_buffer_arr == null || Object.keys(local_audio_array_buffer_arr).length === 0) {
    audio_items = null;
  } else {
    await go(
      entries(speech_data),
      mapC(async ([name]) => {
        try {
          const audio_array_buffer = local_audio_array_buffer_arr?.[name];
          if (audio_array_buffer) {
            const audio_ctx = new (window.AudioContext || window.webkitAudioContext)();
            local_audio_array_buffers[name] = audio_array_buffer.slice(0);

            const buffer = await audio_ctx.decodeAudioData(audio_array_buffer);
            audio_items[name] = { audio_ctx, buffer };
          }
        } catch (e) {
          console.error(e);
        }
      }),
    );
  }
  return audio_items;
};

export const playAudio = (audio_item_name) => {
  if (audio_item_name == null || Object.keys(audio_item_name).length === 0) {
    return;
  }

  const { audio_ctx, buffer } = audio_item_name;
  // Get an AudioBufferSourceNode.
  // This is the AudioNode to use when we want to play an AudioBuffer
  const audio_src = audio_ctx.createBufferSource();

  // set the buffer in the AudioBufferSourceNode
  audio_src.buffer = buffer;
  // connect the AudioBufferSourceNode to the destination so we can hear the sound
  audio_src.connect(audio_ctx.destination);
  audio_src.loop = false;
  audio_src.start();
};

export const parseAztecData = (scan_val) => {
  return go(
    scan_val.split(','),
    map((number) => parseInt(number.replace(/[^0-9]/g, ''))),
  );
};

const evaluateLabel = (label_data) => {
  /* 라벨 스캔은 단건으로만 조회가 가능한 경우로 한정 */
  const label = label_data[0];
  //취소된 주문인 경우
  if (label.is_cancel_requested) {
    //CANCEL 로 평가
    return EVAL_RESULT_NAMES.cancel.dev;
  } else if (label.press_type === '') {
    /* label 이 여전히 holding 상태 (API 반환은 '' 빈 문자열로 press type 이 넘어옴. HOLDING 의미)
     *  인 경우라도 fast_track 으로 분류된 경우에는 성공 처리 */
    if (label.fast_track_outsourcing_is_show) {
      return EVAL_RESULT_NAMES.success.dev;
    } else {
      //press_type 이 결정되었는지 확인 => press_type이 하나라도 빈 문자라면 FAIL 반
      return EVAL_RESULT_NAMES.fail.dev;
    }
  } else {
    //press type 이 정해져 있으면 SUCCESS 반환
    return EVAL_RESULT_NAMES.success.dev;
  }
};

const makeHistoryItemObj = (prj_id, evaluation, label_data) => {
  return {
    projection_id: prj_id,
    evaluation,
    label: label_data,
    scan_at: new Date(),
  };
};

const makeHistoryEvalReturnItem = (
  evaluation,
  is_repeat,
  is_update,
  projection_id,
  scan_history_index,
  scan_at,
  scan_label_data,
) => {
  return {
    evaluation,
    is_repeat,
    is_update,
    projection_id,
    scan_history_index,
    scan_at,
    scan_label_data,
  };
};

export const updateScanHistoryAndEvaluate = (
  scan_history_item_list,
  scan_history_indexer,
  scan_projection_id,
  scan_label_data,
) => {
  /*
   *  히스토리 리스트는 순서가 보장되어야 하므로 array 로 만든다.
   *  히스토리 내부 데이터는 { projection_id(NUMBER), label_data( [ { }, { } ] ), evaluation(STRING) } 으로 정리한다.
   *     - 한가지 projection_id에 label_data 는 여러개가 있을 수 있으므로 array 로 넣는다.
   *  scan_history_list = [ { projection_id, label_data, evaluation }, { }, { }... ]
   *  scan_history_indexer = { projection: idx(scan_history_list 내 idx) }
   *    => 이건 새로 들어온 스캔 데이터가 히스토리에 존재하는지 여부를 빠르게 판단하고 존재한다면
   *       scan_history_list (array) 에서 빠르게 검색할 수 있도록 하기 위함.
   *
   * && 스캔 주문이 이전 히스토리에 존재하는지 검사 한다.
   *   A. 존재하지 않는 경우 - 이 페이지 로딩된 후 처음으로 찍는 것이다.
   *      a. 상태 평가를 한다.
   *         i. 취소된 주문인 경우
   *            => CANCEL 로 평가
   *         ii. 취소가 아닌 경우 (이미지 작업 완료 여부 확인)
   *            - press_type 모두 존재하는가 => SUCCESS 로 평가
   *            - press_type 하나라도 없는 경우 => FAIL 로 평가
   *      b. 히스토리에 추가한다.
   *         - 3가지 유형으로 평가된 projection 존재함 (SUCCESS, FAIL, CANCEL)
   *
   *   B. 존재하는 경우 - 페이지 로딩 된 후 두번 이상 스캔한 것이다. (중복)
   *                    - 이게 어디까지 했는지 까먹고 확인차 다시 스캔할 수도 있고, 업데이트가 된 것을 반영하는 목적도 있다.
   *      a. 히스토리 내 주문 정보의 모든 data 가 동일한 가를 확인한다.
   *         i. 동일하다. (단순 중복 스캔)
   *            => REPEAT_FAIL, REPEAT,SUCCESS, REPEAT_CANCEL 로 평가.
   *            => 히스토리에는 반영하지 않는다.
   *         ii. 동일하지 않다. (주문 정보가 업데이트 됨)
   *            => 재평가를 진행한다. SUCCESS, FAIL, CANCEL 중 하나로 평가해서 처리
   *            => 히스토리에는 반영하되 데이터의 인덱스는 유지한다. (인덱스는 시간 우선보다 물리적인 위치와 동일성을 유지하는 게 좋다고 생각)
   *
   * note: evaulation 은 히스토리가 있어야 해야 counter 를 빼고 추가하고 할 수 있다. array 관리
   * */
  //이 함수를 타는 자는 항상 스캔 시간을 찍으니라
  const scan_at = new Date();
  //1. 스캔 주문이 이전 히스토리에 존재하는지 검사
  if (scan_history_indexer[scan_projection_id] === undefined) {
    // A. 히스토리 내 존재하지 않는 경우 - 처음 scan 하는 projection
    //   a. 상태 평가 진행
    const evaluation = evaluateLabel(scan_label_data);
    //   b. 히스토리에 추가
    const scan_history_index = scan_history_item_list.length;
    scan_history_indexer[scan_projection_id] = scan_history_index;
    scan_history_item_list.push(makeHistoryItemObj(scan_projection_id, [evaluation], scan_label_data));
    //최초의 evaluation 이므로 array 로 감싸서 히스토리에 기록
    return makeHistoryEvalReturnItem(
      [evaluation],
      false,
      false,
      scan_projection_id,
      scan_history_index,
      scan_at,
      scan_label_data,
    );
  } else {
    //B. 존재하지 않는 경우 - 페이지 로딩 된 후 두번 이상 스캔함
    //   a. 히스토리 내 주문 정보의 모든 데이터가 동일한지 검사
    const scan_history_index = scan_history_indexer[scan_projection_id];
    const scan_history_item = scan_history_item_list[scan_history_index];
    scan_history_item.scan_at = scan_at;
    const current_scan_evaluation = evaluateLabel(scan_label_data);

    //created_at은 라벨 조회 시점의 시각이 되므로 항상 바뀌는 부분임. 그 외에는 deep하게 조사
    // 1. 만약에 비교를 deep 비교를 해야 한다면 stringify 를 쓴다.
    // 2. 지금 생각에는 press type 에 따라서 평가가 이전 평가와 달라졌을 때만 판단하는게 맞는것 같음.
    // const is_equal = getStringifyStr(scan_history_label_data) === getStringifyStr(scan_label_data);
    const is_equal = scan_history_item.evaluation.slice(-1)[0] === current_scan_evaluation;
    if (is_equal) {
      // 데이터가 히스토리와 동일한 경우 (히스토리 업데이트 할 필요 없다. 시간만 업데이트)
      // evaluation 만 동일한 evaulation push
      scan_history_item.evaluation.push(scan_history_item.evaluation.slice(-1)[0]);
      return makeHistoryEvalReturnItem(
        scan_history_item.evaluation,
        true,
        false,
        scan_projection_id,
        scan_history_index,
        scan_at,
        scan_label_data,
      );
    } else {
      //데이터가 히스토리와 동일하지 않은 경우 => 재평가 진행하고 히스토리에 반영
      //1. 재평가 해서 evaluation array 에 추가
      //2. label 데이터 내용을 신규로 교체
      //3. scan time 업데이트
      scan_history_item.evaluation.push(current_scan_evaluation);
      scan_history_item.label = scan_label_data;
      scan_history_item.scan_at = scan_at;

      return makeHistoryEvalReturnItem(
        scan_history_item.evaluation,
        true,
        true,
        scan_projection_id,
        scan_history_index,
        scan_at,
        scan_label_data,
      );
    }
  }
};

export const emphasisCounter = (target_eval, ctn_els) => {
  go(
    ctn_els,
    each((el) => {
      if ($hasClass(target_eval, el)) {
        $addClass('scan_now', el);
      } else {
        $removeClass('scan_now', el);
      }
    }),
  );
};

export const removeColumnEditors = (columns) =>
  go(
    columns,
    each((col) => {
      if (col?.editor) {
        delete col.editor;
      }
      if (col?.columns) {
        go(
          col.columns,
          each((child_col) => {
            if (child_col?.editor) {
              delete child_col.editor;
            }
          }),
        );
      }
    }),
  );

export class ManageCounters {
  constructor() {
    this.cancel = 0;
    this.success = 0;
    this.fail = 0;
  }

  countByEvaluation(up_eval, down_eval, ctn_els) {
    go(
      ctn_els,
      each((ctn_el) => {
        if (up_eval && $hasClass(up_eval, ctn_el)) {
          //evaluation에 해당하는 값 상승시키고 span 에 업데이트해서 표시
          ctn_el.innerText = ++this[up_eval];
          //스타일 글자 크게 표시 (.scan_now)
          ctn_el.classList.add('scan_now');
        } else if (down_eval && $hasClass(down_eval, ctn_el)) {
          //변경이 일어난 경우에 카운트 가/감 적용
          ctn_el.innerText = --this[down_eval];
          ctn_el.classList.remove('scan_now');
        } else {
          ctn_el.classList.remove('scan_now');
        }
      }),
    );
  }
}

const cleaningAnimationClassname = (ani_target_el) => {
  //이전에 실행되고 있는 animation class 가 존재하는 경우 삭제
  //focusing, out_of_focus don_wrapper 클래스 제외
  const exist_classname = ani_target_el.classList.value
    .replace('don_wrapper', '')
    .replace('focusing', '')
    .replace('out_of_focus', '')
    .trim();
  exist_classname.length && $removeClass(exist_classname, ani_target_el);
};

export const updateScreenInteraction = (wrapper_el, item_res) => {
  /*
   * 1. 신규 스캔 - 4번 느리게 pulse
   *    A. 취소 - red
   *    B. 성공 - green
   *    C. 홀딩 - yellow
   * 2. 중복 스캔
   *  2-1. 데이터 업데이트 X
   *    - 6번 빠르게 pulse
   *      A. 취소 - red
   *      B. 성공 - green
   *      C. 홀딩 - yellow
   *  2-2. 데이터 업데이트 O
   *    - 4번 느리게 pulse
   *    - 이전/이후 변화 그라데이션 표시
   * */

  cleaningAnimationClassname(wrapper_el);
  const [billboard_prj_id_el, billboard_press_type_el] = go(wrapper_el, $findAll('.billboard span'));
  billboard_prj_id_el.innerText = item_res.projection_id;

  const eval_arr = item_res.evaluation;
  const eval_len = eval_arr.length;
  const current_eval = eval_arr[eval_len - 1];
  const prev_eval = eval_arr[eval_len - 2];
  if (!prev_eval) {
    // 1. 신규 스캔 - 느린 pulse => 단일 색상 컬러
    billboard_press_type_el.innerText = EVAL_RESULT_NAMES[current_eval].kr;
    wrapper_el.animate(...Object.values(animations[current_eval](true)));
  } else {
    //중복 스캔 - 평가 변경 여부로 분기
    const ani_classname = `${prev_eval}_to_${current_eval}`;
    billboard_press_type_el.innerText = EVAL_RESULT_NAMES[ani_classname].kr;
    if (prev_eval === current_eval) {
      // 2. 업데이트 없는 단순 중복 - 빠른 pulse
      wrapper_el.animate(...Object.values(animations[current_eval](false)));
    } else {
      // 3. 업데이트 있는 재스캔 확인 - 느린 pulse => 색 변화로 변경 여부 알려줌.
      //linear-gradient 는 animation 지원되지 않는다.
      //따라서 pseudo element :after 로 고정 linear gradient 를 덮어씌우고
      //이를 opacity animation 을 통해서 pulsation 을 구현함.
      $addClass(ani_classname, wrapper_el);
      //animation 종료 시간 이후에 class 를 삭제
      window.setTimeout(
        () => $removeClass(ani_classname, wrapper_el),
        ani_slomo_opts.duration * ani_slomo_opts.iterations,
      );
    }
  }
};

export const updateSpeechInteraction = (item_res, audio_items) => {
  const eval_arr = item_res.evaluation;
  const current_eval = eval_arr[eval_arr.length - 1];
  const prev_eval = eval_arr[eval_arr.length - 2];
  if (!prev_eval) {
    playAudio(audio_items[EVAL_RESULT_NAMES[current_eval].dev]);
  } else {
    playAudio(audio_items[EVAL_RESULT_NAMES[`${prev_eval}_to_${current_eval}`].dev]);
  }
};
