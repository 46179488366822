import { $closest, $data, $find, $findAll } from 'fxdom/es';
import { find, keys, map, zipWithIndexL, go, reject } from 'fxjs/es';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import { alertInbound } from '../../ShippingDetail/F/Function/alert.js';
import { renderPackagingTab, renderReboundTab } from '../../ShippingDetail/F/Function/render.js';

import { alertsLgl } from './alerts.js';
import { guards } from '../../S/Function/guards.js';
import { apiCalls } from './api.calls.js';
import { printLglWaybill } from './waybill.print.js';
export async function handleLglWaybill({ currentTarget: ct }) {
  try {
    DfInhouseF.lottie_loader.start('printer');
    const tab = go(ct, $closest('.tab_wrapper'));
    const projection = $data(tab);
    const projection_id = projection.id;
    guards.nullCheck(projection_id, `projection_id is required`);

    // CJ 운송장 출력
    const [{ print_result }] = await printLglWaybill({ projection_id });

    // 운송장 출력이 성공적으로 진행됨
    if (print_result.is_OK) {
      const waybill_no_domestic = print_result.waybill_no;
      await apiCalls.post.inbound.createOrder({ projection_id, waybill_no_domestic });
      await renderPackagingTab({ projection_id });
    }
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleDeleteLglWaybill({ currentTarget: ct }) {
  try {
    const { is_empty, waybill_index, waybill_no, lgl_inbound_order_id, projection_id } = getWaybillInfo({
      ct,
    });

    if (is_empty) {
      await alertInbound.waybill.deleteConfirm({
        waybill_index,
        waybill_no,
        confirmCallback: async () => {
          try {
            await apiCalls.delete.inbound.order({ lgl_inbound_order_id });

            await renderPackagingTab({ projection_id });
          } catch (err) {
            console.error(err);
            await alertsLgl.error({ err });
          }
        },
      });
    } else {
      await alertInbound.waybill.deleteAlert();
    }
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleReboundWaybill({ currentTarget: ct }) {
  try {
    DfInhouseF.lottie_loader.start('printer');
    const tab = go(ct, $closest('.tab_wrapper.rebound'));
    const inbound_orders = $data(tab);
    const [inbound_order] = inbound_orders;
    console.log({ inbound_orders });
    const body = go(tab, $find('.body'));
    const all_inbound_orders = $data(body);

    // 모두 INSPECTED 된 상태에서 재입고를 하기 위함.
    const all_inbound_orders_after_requested = go(
      all_inbound_orders,
      reject(({ current_status }) => ['PACKED, PACKING, REQUESTED'].includes(current_status)),
    );
    const every_inbound_orders_inspected = go(
      all_inbound_orders_after_requested.every(({ current_status }) => current_status === 'INSPECTED'),
    );
    if (every_inbound_orders_inspected) {
      const { projection_id } = inbound_order;
      guards.nullCheck(projection_id, `projection_id is required`);

      // CJ 운송장 출력
      const [{ print_result }] = await printLglWaybill({ projection_id });

      // 운송장 출력이 성공적으로 진행됨
      if (print_result.is_OK) {
        const waybill_no_domestic = print_result.waybill_no;
        await apiCalls.post.rebound.createOrder({ projection_id, waybill_no_domestic });
        await renderReboundTab({ projection_id });
      }
    } else {
      // alert message 추가
    }
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleDeleteReboundWaybill({ currentTarget: ct }) {
  try {
    const { is_empty, waybill_index, waybill_no, lgl_inbound_order_id, projection_id } = getWaybillInfo({
      ct,
      rebound: true,
    });

    if (is_empty) {
      await alertInbound.waybill.deleteConfirm({
        waybill_index,
        waybill_no,
        confirmCallback: async () => {
          try {
            await apiCalls.delete.rebound.order({ lgl_inbound_order_id });
            await renderReboundTab({ projection_id });
          } catch (err) {
            console.error(err);
            await alertsLgl.error({ err });
          }
        },
      });
    } else {
      await alertInbound.waybill.deleteAlert();
    }
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

function getWaybillInfo({ ct, rebound = false }) {
  const tab = go(ct, $closest('.tab_wrapper'));
  const current_inbound_order = go(tab, $find('.tab_content.selected'));
  const inbound = $data(current_inbound_order);
  const { id: lgl_inbound_order_id } = current_inbound_order.dataset;
  const [waybill_index] = go(
    tab,
    $find('.table_right'),
    $findAll('.tab_item'),
    map(({ dataset }) => dataset.id),
    zipWithIndexL,
    find(([idx, id]) => id === lgl_inbound_order_id),
  );
  const { waybill_no_domestic: waybill_no } = inbound;
  const inbound_order_list = go(current_inbound_order, $find('.table'));
  const inbound_order_items = $data(inbound_order_list);
  const tab_wrapper = go(current_inbound_order, $closest('.tab_wrapper'));

  let projection_id;
  if (rebound) {
    const [inbound_order] = $data(tab_wrapper);
    projection_id = inbound_order.projection_id;
  } else {
    const projection = $data(tab_wrapper);
    projection_id = projection.id;
  }

  const is_empty = go(inbound_order_items, keys).length === 0;

  return {
    is_empty,
    waybill_index,
    waybill_no,
    lgl_inbound_order_id,
    projection_id,
  };
}
