import axios from 'axios';
import { getFileFormData } from './helpers.js';

const form_header = {
  headers: {
    'Content-Type': 'multipart/form-data', // 파일 전송 시 필요한 헤더
  },
};

/**
 * @define 프론트 - pdf 파일 업로드 요청
 * @param {File} file
 * @return {Promise<PdfResCommit<PdfUploadResult> | PdfResRollback>}
 * */
export async function uploadPdfFile({ file }) {
  return (await axios.post(`/@api/pdf/upload/printing`, getFileFormData({ file }), form_header)).data;
}

/**
 * @define 프론트 - pdf 메타데이터 조회 요청
 * @param {File} file
 * @return {Promise<PdfResCommit<PdfMetadata[]> | PdfResRollback>}
 * */
export async function getMetadataPdfFile({ file }) {
  return (await axios.post(`/@api/pdf/metadata`, getFileFormData({ file }), form_header)).data;
}
