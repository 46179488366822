import { $appendTo, $delegate, $el, $qs } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MuiF } from '../../../../../modules/Mui/F/Function/module/MuiF.js';
import { DfTaskDeliveryScanningModePopMuiF } from '../../../../../modules/Df/Task/DeliveryScanning/ModePop/F/Mui/module/DfTaskDeliveryScanningModePopMuiF.js';

!(function head() {
  /**
   * df 메뉴 이벤트
   */
  window.addEventListener('DOMContentLoaded', () => {
    const menu_el = $qs('#header .menu');
    go(
      menu_el,
      $delegate('click', '.menu2 li.list2 a.prod-delivery-scan', (e) => {
        e.originalEvent.preventDefault();
        return MuiF.openFrame(DfTaskDeliveryScanningModePopMuiF.frame, (f, p, [t]) => {
          f.closed = (f, r) => {
            if (r) {
              window.location.href = `/task/import_export_scanning/?mode=${r}`;
            }
          };
        });
      }),
    );
  });
})();
