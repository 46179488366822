import { NewMakerWeS } from '../../We/S/Function/module/NewMakerWeS.js';
import { BpOptionConstantS } from '../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { NewMakerBaseProductsKeyCapConstantS } from '../../BaseProducts/KeyCap/S/Constant/module/NewMakerBaseProductsKeyCapConstantS.js';

const ambiguous = [
  {
    base_product_id: 4578,
    name: 'postcard_115',
    pc: '//s3.marpple.co/files/u_218933/2021/2/original/fe87d966c54adf01e26fbf5de760cb63bdeba7861.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/27181adfdea3e8864e1dab581b73fc0759a22ca41.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/d01eb42e7e3d82b1a67c1a71530ea2fb948c69e72.png',
    m: '//s3.marpple.co/files/u_218933/2021/2/original/b98e769d096712aa9ad3dc983a056d6e0bd82a821.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/1660babf1159f3afaaa574772e7d6589d418bdb56.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/054266783b4753c38b52f5a64373062e40d879f27.png',
  },
  {
    base_product_id: 4602,
    name: 'postcard_135',
    pc: '//s3.marpple.co/files/u_218933/2021/2/original/d9256fbc9c5d840f16d9a0739a64c1e6ecab86f51.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/9b53db9f3b6c2b6558be0d9323a91c1f15e9d1e11.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/0337ffcf816f5b7e87ba18f23ddbdf8faa234e662.png',
    m: '//s3.marpple.co/files/u_218933/2021/2/original/6c1d5a2dcdf628b5f523ec851d7d6041f55578d01.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/345b1f39e69523e1dc54dc7becd59770c2f80e066.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/7299b2d307bef9fa10f7c86970a39af2005e50847.png',
  },
  {
    base_product_id: 4577,
    name: 'postcard_257',
    pc: '//s3.marpple.co/files/u_218933/2021/2/original/971b958cad73d6d1f423544fcc9c52f65705d4a91.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/260c3e59a43675877ef0eff9a28e5bfb2c98ec841.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/f33dfee297cb8ffbb8fe83f67b0a6a8cacc2e9732.png',
    m: '//s3.marpple.co/files/u_218933/2021/2/original/5c27807da90247a7df5c8d5199f44bd1fdc7b7f41.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/e50b090b81e34c644cf83f44ba9d9e0132a45f876.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/d847cf2e7325274785d0d89d4d6d72de68868a5b7.png',
  },
  {
    base_product_id: 4579,
    name: 'node_a5',
    pc: '//s3.marpple.co/files/u_218933/2021/2/original/659d08a3132a4c95a4a9313cdbfc29d29f0730a81.png',
    m: '//s3.marpple.co/files/u_218933/2021/2/original/17fccedeafad63eaff1ca4a6241f9fa2bfe06cb91.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/4eda0a058781be7f01ff327d02c6c43ee3f849081.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/7bf48c88eb4ccfccb4fff26f0a046fb8f4fedfea2.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/7cddc7f985478d470fdf029a4e5dbc1e3899a7f06.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/8f35d5c1386f320fe8e4fedc59242064466ef4927.png',
  },
  {
    base_product_id: 4580,
    name: 'node_b5',
    pc: '//s3.marpple.co/files/u_218933/2021/2/original/eac74ce082e3f80805ffc8a18c33a955ac7ed6191.png',
    m: '//s3.marpple.co/files/u_218933/2021/2/original/a1d3e7b475f3ccc96dc6dd74af5e6e1bb96217bc1.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/cc6c1a95fc289893c8630a764b4e2ac87187a88f1.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/1934ac8af427dbdad8c43c18a3f52355231336252.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/9e4a9c92cd5dca033b58696f9e40fddca8cac03a6.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/dc78da52bd23b17c1a7fdccc4178a105b5ca0e557.png',
  },
  {
    base_product_id: 5757,
    name: 'clapper',
    pc: '//s3.marpple.co/files/u_193535/2023/6/original/bb345a1309482eba1b2ed3457fd120e60892d4751.png',
    m: '//s3.marpple.co/files/u_193535/2023/6/original/692ee70ca672c5e91bd4e2d414631c3b25c4a11b2.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/5c77cd0bccce3eabeacde9ab4e350c752d39f9971.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/3a8d865136ffca3bb3c80022d4fbb64c3c0c3e3e2.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/6f7b7290ec504a1f18d64a6429d38704418f933d6.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/7b0a61e4a7c6351e996b222c72e5d83f589bbc3a7.png',
  },
];

const confirm = [
  {
    base_product_id: 4578,
    name: 'postcard_115',
    pc: '//s3.marpple.co/files/u_193535/2023/6/original/d768bb4700bd039474933f53bd3509f9335e9edb10.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/7dec35a2f24216e9f43e83807e4b7ea21c3b6f5b9.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/e795703402d3df1a4a935fb392aab434b4f137a28.png',
    m: '//s3.marpple.co/files/u_193535/2023/6/original/7889161e95454fc74857779993443f816fea7fbe4.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/d43544681c3e52e702630dc5c5d7905233fadf2f5.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/6ee69c4e3c4a2721aa4607d63398a7b8551530503.png',
  },
  {
    base_product_id: 4602,
    name: 'postcard_135',
    pc: '//s3.marpple.co/files/u_193535/2023/6/original/adc329ee41f040bfdade5352cddabe9de6e4a3b410.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/5ef53a70491a0e3d504f689689f3cf2c8c42de419.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/96251abf4d3bcbe25e08c9dbeb156d50bd2c1b428.png',
    m: '//s3.marpple.co/files/u_193535/2023/6/original/0e5428dcdcc9ead6f88b031fec4d39a8bcd24a674.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/44781d6d018548659a0cbb67958efbf88663e6145.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/9c843c9bfa5bbc42709d25d9019e008370ad12223.png',
  },
  {
    base_product_id: 4577,
    name: 'postcard_257',
    pc: '//s3.marpple.co/files/u_193535/2023/6/original/2318d6f07040a25cf1337419c73b38be2985f42110.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/92f3583551cc320cdd04a411c9f8ec9295e07f7a9.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/f61716bbc43560f603b35731c9328abfe0ed58b88.png',
    m: '//s3.marpple.co/files/u_193535/2023/6/original/323846fb2f3e2988c9212357268d4aba3543ac684.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/8893ff17a11a192fe7e60c7739de8dd5c9f71bba5.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/b9b5382aed1a6981a6e0f5177e2c19153adf807f3.png',
  },
  {
    base_product_id: 4579,
    name: 'node_a5',
    pc: '//s3.marpple.co/files/u_193535/2023/6/original/d9fdedc57de31d4f3a02616515d38c4a86085c6510.png',
    m: '//s3.marpple.co/files/u_193535/2023/6/original/ec2b2c57cdfbb7dee9712beb89339d6cf1877e224.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/1cd816ca3e885b3f4257a691acb9d3aee7f0b9819.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/6e81be1ac42e7f088323d3e59e4e2ae8d48886d38.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/4d7b37456ede18ded7d8841116d3dcb334f652bb5.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/50412f2fc8a97686c3c60f55142a4f4fd280f6713.png',
  },
  {
    base_product_id: 4580,
    name: 'node_b5',
    pc: '//s3.marpple.co/files/u_193535/2023/6/original/d3a4118f133edc4132c0a8a8ea20eda6e7631e7110.png',
    m: '//s3.marpple.co/files/u_193535/2023/6/original/d44429dc8c4052e86caaacce775d694cc26d8c9e4.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/d19508c8a07f3408b6b534ce7b6da2a264b24e339.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/e417117cfcc0ca225769800fe0b98d4b29789c128.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/1b8a7e875a6bb567bd95eb330129812aa3593aa15.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/2058c67e4aa86caf92cb6e1916309e1d7801419e3.png',
  },
  {
    base_product_id: 5757,
    name: 'clapper',
    pc: '//s3.marpple.co/files/u_193535/2023/6/original/948436275c6dead2c0cfa076a37213d2d46e77fb10.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/afd8cacfa1d5d125b068a9cb5fcd4abedc1058459.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/039349386a79586a88d06c1f57c828a7ffc993b98.png',
    m: '//s3.marpple.co/files/u_193535/2023/6/original/13bc34c2e4130edc20941a7e2da9ef978fd9c2274.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/65a64d3fbbb3a641dab9ebebe0c43c15732dd1445.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/998daab1db87b07751fd4b6bc90a5b4ed043650b3.png',
  },
];

export const GUIDE_DATA = {
  confirm: confirm
    .concat(NewMakerWeS.CONFIRM_BPS)
    .concat(BpOptionConstantS.MASKING_TAPE_CONFIRMS)
    .concat(NewMakerBaseProductsKeyCapConstantS.confirm),
  ambiguous: ambiguous.concat(NewMakerWeS.AMBIGUOUS_BPS),
  parallel: {
    base_product_ids: NewMakerWeS.WE_BP_IDS,
    pc: '//s3.marpple.co/files/u_218933/2021/2/original/793b800aa72577b206780d93d8c53d3d015c81fb1.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/fb93d4bf943e9deaa62cfdfb8b37fe2a3d98c6e62.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/be89e15d50dc865ebaf9b90526ba88f05647d7151.png',
    m: '//s3.marpple.co/files/u_218933/2021/2/original/201bf804766930c6c9c481feb911a698995b0e921.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/0d75b52481d080355a04f72bf1d07de7fd31e3cc7.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/01a438b7641fa991e8e6d66cb6cace567fc234b44.png',
  },
  image_quality: {
    base_product_ids: NewMakerWeS.WE_BP_IDS,
    pc: '//s3.marpple.co/files/u_218933/2021/2/original/a32d92dd8f6a3ca5c83869c319c0d4b18524fc491.png',
    pc_en: '//s3.marpple.co/files/u_193535/2023/6/original/5c5d271eb4c138bb4d93c445415d1439bfdfebe86.png',
    pc_jp: '//s3.marpple.co/files/u_193535/2023/6/original/2222d9af697c5e14895a2e9e736241850bc36f9f3.png',
    m: '//s3.marpple.co/files/u_218933/2021/2/original/6d1c762c5bd3c71f990138c86569ecc285ede2131.png',
    m_en: '//s3.marpple.co/files/u_193535/2023/6/original/9a26c5150e463bb91890458246d304225c9732db8.png',
    m_jp: '//s3.marpple.co/files/u_193535/2023/6/original/db72a88aa6a9656f07776d9f140cdfd9026e5bc95.png',
  },
};
