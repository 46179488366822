import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { go, map, reduce, html } from 'fxjs/es';
import { DfInhouseF } from './module/DfInhouseF.js';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';

export function adapterInhouseBoxesToTableRows({ boxes }) {
  if (UtilArrayS.isEmNil(boxes)) return [];

  return map(adapterInhouseBoxToTableRow, boxes);
}

// @description 사내배송 box 객체에서 테이블 row 형태로 데이터 프로세싱
export function adapterInhouseBoxToTableRow(box_data) {
  /* parent row 생성 */
  const parent_row = reduce(
    (row_data, column) => {
      row_data[column.field] = getPathValueFromObj({ obj: box_data, adapter: column.adapter });
      return row_data;
    },
    { id: box_data.id, is_child: false },
    DfInhouseF.TABLE_CONST.columns.parent,
  );

  /* parent <- child row 주입 */
  parent_row[DfInhouseF.TABLE_CONST.childTreeFieldName] = go(
    getPathValueFromObj({ obj: box_data, adapter: DfInhouseF.TABLE_CONST.childFieldRoot.adapter }),
    map((up) => {
      return reduce(
        (child_row_data, column) => {
          child_row_data[column.field] = getPathValueFromObj({ obj: up, adapter: column.adapter });
          return child_row_data;
        },
        { id: up.id, is_child: true },
        DfInhouseF.TABLE_CONST.columns.child,
      );
    }),
  );
  return parent_row;
}

// @description adapter path 를 이용해서 obj 의 경로 값 추출
function getPathValueFromObj({ obj, adapter }) {
  if (adapter == null) return '';
  const { path, fn } = adapter; // path: gBox selector 방식, fn: 마지막 path 값을 태우는 데이터 후처리
  const dest_value = reduce(
    (dest_value, sub_path) => {
      const middle_value = dest_value[sub_path];
      if (middle_value != null) dest_value = middle_value;
      return dest_value;
    },
    obj,
    path.split('->'),
  );

  return fn ? fn(dest_value) : dest_value;
}

// @description 옵션명 문장을 생성 (kr, en)
export function getProductInfoStr({ spo_item, base_product_color, base_product_size }) {
  return UtilObjS.isNotEmpty(spo_item)
    ? spo_item.group_names_option_names
        ?.map(({ group_name, option_name }) => {
          return `${group_name}:${option_name}`;
        })
        .join('/') ?? '옵션 없음'
    : `색상:${base_product_color.name}/사이즈:${base_product_size.name}`;
}

export function getProjectionUrl({ projection_id }) {
  return `/projection/list?status=all&at_opt=ordered&p_id=${projection_id}&product_mode=up_list`;
}

export function getProjectionLinkHtml({ projection_id }) {
  return html` <a
    style="color:dodgerblue;text-decoration:underline;"
    href="${getProjectionUrl({
      projection_id,
    })}"
    target="_blank"
    >주문서 페이지 링크</a
  >`;
}
