import { resetCvMaskBySf } from '../SizeFaces/fs.js';
import { go, html } from 'fxjs/es';
import { $addClass, $appendTo, $el, $qs, $removeClass } from 'fxdom/es';
import { makeCanvasByUrl } from '../../../Canvas/S/util.js';
import { makeCanvasTextured } from '../cylinder.js';
import { makeImageFromUrl } from '../util.js';

export function makeCvMask1InMakerA(canvas, bpcf_mask_url, sf) {
  const last_cv_mask1 = G.mp.maker.cv_mask1(canvas);
  if (last_cv_mask1) canvas.remove(last_cv_mask1);
  return new Promise(function (resolve) {
    fabric.Image.fromURL(G.mp.maker.url(bpcf_mask_url), function (img) {
      img.set({
        visible: !!bpcf_mask_url,
        selectable: false,
        evented: false,
        globalCompositeOperation: 'destination-in',
        _data: { cv_type: 'cv_mask1', is_not_design: true },
      });
      canvas.add(img);
      resetCvMaskBySf(img, sf);
      canvas.renderAll();
      resolve();
    });
  });
}

export function cvMask1Event() {
  if ($qs('.show_cv_mask1.circle_icon')) return;
  go(
    html`
      <li class="show_cv_mask1 circle_icon">
        <div class="icon"></div>
        <div class="text">마스크1 보기</div>
      </li>
    `,
    $el,
    $appendTo($qs('.decoration_menu_for_pc')),
    $.on('click', async function () {
      const maker_frame_el = $qs('#maker_frame');
      if ($.has_class(maker_frame_el, 'show_cv_mask1')) {
        $removeClass('show_cv_mask1')($1('#maker_frame'));
        const canvas = G.mp.maker.editing_canvas();
        const cv_mask1 = G.mp.maker.cv_mask1(canvas);
        cv_mask1.globalCompositeOperation = 'destination-in';
        canvas.renderAll();
      } else {
        $addClass('show_cv_mask1')(maker_frame_el);
        const canvas = G.mp.maker.editing_canvas();
        const cv_mask1 = G.mp.maker.cv_mask1(canvas);
        if (!cv_mask1._element) return;
        cv_mask1._element = await go(makeCanvasByUrl(cv_mask1._element.src), (c) => {
          const canvas = makeCanvasTextured(c, '#ffff00');
          return makeImageFromUrl(canvas.toDataURL());
        });
        cv_mask1.globalCompositeOperation = 'source-over';
        canvas.renderAll();
      }
    }),
  );
}
