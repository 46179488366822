export const gBox = {
  rootKey: 'inhouse_shippings',
  buckets: 'buckets',
  crew_inhouses: 'crew_inhouses',
};

export const BUCKET_MODE_KEY = {
  PACK: { key: 'PACK', key_kr: 'ㅖㅁㅊㅏ', key_kr2: 'ㅖㅁ차', name: '담기모드' },
  UNPACK: { key: 'UNPACK', key_kr: 'ㅕㅜㅖㅁㅊㅏ', key_kr2: 'ㅕㅜㅖㅁ차', name: '제거모드' },
};

export const TABLE = {
  ID: 'inhouse-projection-table',
  KEY: '__table',
};

export const HYDRATING_TIME_LIMIT_SEC = 10;

export const PROJECTION_STATUSES = [
  { key: 'ordered_at', name: '주문 완료' },
  { key: 'before_print_at', name: '제작 준비' },
  { key: 'printing_at', name: '제작 시작' },
  { key: 'before_deliver_at', name: '제작 완료' },
  { key: 'delivering_at', name: '배송 출발' },
  { key: 'before_confirm_at', name: '배송 도착' },
  { key: 'confirmed_at', name: '구매 확정' },
  { key: 'canceled_at', name: '취소' },
];
//
// before_order (결제 전)
// * ordering (무통장, 가상계좌)
// * before_print (ordered) (제작이 시작될 수 있는 시점, 이미지 작업 태스크 생성)
// * printing (제작준비중 태스크들이 완료된 시점)
// * before_deliver (printed) (제작준비중 + 제작 태스크들이 완료된 시점)
// * delivering (제품에 송장번호가 부여되는 시점)
// * before_confirm (delivered) (고객에게 택배 완료된 시점 - 크롤링 조회)
// * confirmed (배송완료 후 일주일 타임아웃)

export const SHIPPING_STATUSES = {
  ordering: { name: '배송 전', step: 1 }, // 주문 완료
  before_print: { name: '배송 전', step: 1 }, // 제작 준비중
  printing: { name: '배송 전', step: 1 }, // 제작 중
  before_deliver: { name: '배송 전', step: 1 }, // 제작 완료
  delivering: { name: '배송 중', step: 2 }, // 배송중
  before_confirm: { name: '배송 완', step: 3 }, // 배송 완료
  confirmed: { name: '구매 확정', step: 4 }, // 구매확정
  // canceled
};

export const BUCKET_CONTROL_NAME = {
  CREATE: { key: 'CREATE', name: '생성' },
  DELETE: { key: 'DELETE', name: '삭제' },
  REPRINT_WAYBILL: { key: 'REPRINT_WAYBILL', name: '재출력' },
  COMPLETE: { key: 'COMPLETE', key_kr: 'ㅊㅒㅡㅖㅣㄸㅆㄸ', key_kr2: '챼ㅡㅖㅣㄸㅆㄸ', name: '포장 완료' },
};

export const BUCKET_CONTROL_PREFIX = {
  key: 'INH',
  key_kr: 'ㅑㅜㅗ',
};

export const SEARCH_BUTTON_NAME = {
  date_reset: 'date-reset',
  date_range: 'date-range',
  projection_search: 'projection-search',
  waybill_search: 'waybill-search',
};
