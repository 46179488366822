import axios from 'axios';
import { $findAll } from 'fxdom/es';
import { each, go, html } from 'fxjs/es';
import { OMPCoreCellF } from '../../../../Core/Cell/F/Function/module/OMPCoreCellF.js';
import { OMPMyPageF } from '../../../F/Function/module/OMPMyPageF.js';
import { OMPMyPageConstantS } from '../../../S/Constant/module/OMPMyPageConstantS.js';
import { ORDER_STATUS } from '../../S/Constant/order.js';
import { OMPMyPageOrderS } from '../../S/Function/module/OMPMyPageOrderS.js';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';

export const tablePaginationDelegate = ({ duration, empty_notice, tab_el } = {}) => {
  const columns = OMPMyPageConstantS.OrderTableColumn();
  const callback = async (e, is_back) => {
    try {
      $.don_loader_start();
      const offset = e.state?.offset || e.currentTarget?.dataset?.offset || 0;

      if (!OMPCoreUtilF.isMobile()) {
        const search = new URLSearchParams(location.search);
        const status = search.get('status') || ORDER_STATUS.ALL;
        const res = await axios.get(`/${TT.lang}/@api/my_order`, {
          params: { offset, duration, status },
        });
        OMPCoreCellF.renderPaginatedTablePage({
          total_page: Number(res.data.total_page),
          current_page: Number(offset) / 10 + 1,
          body_data: {
            columns,
            data: OMPMyPageOrderS.makeTableProjections(res.data.projections),
            empty_notice: html`
              <div class="td-wrapper">${empty_notice || TT('my_page::order::order_23')}</div>
            `,
            merge_column_key: [columns[0].key],
          },
        });
      }

      go(
        tab_el,
        $findAll('.omp-order-product__thumbnail'),
        each((product_face_el) => {
          OMPMyPageF.drawThumbnail(product_face_el);
        }),
      );

      const query = new URLSearchParams(location.search);
      query.set('offset', offset);
      $.don_loader_end();
    } catch (e) {
      $.don_loader_end();
      await OMPMyPageF.catchError(e);
    }
  };

  return OMPCoreCellF.paginatedTablePaginationDelegate({
    callback,
  });
};
