import { SVGEditorTextFrameMuiS } from '../../S/Mui/module/SVGEditorTextFrameMuiS.js';
import { font_tab } from './font_tab.js';

export const font_page = {
  ...SVGEditorTextFrameMuiS.font_page,
  tabs: [font_tab],

  animation: true, // page을 열거나 닫을때 애니메이션 (기본 - ios면 false 이외 true)

  appending(page_el$) {}, // page 엘리먼트가 만들어지면 울리는 함수
  appended(page_el$) {}, // page 엘리먼트가 html에 붙으면 울리는 함수
  showing(page_el$) {}, // page 엘리먼트 show하기 전 울리는 함수
  showed(page_el$) {}, // page 엘리먼트 show하고 나서 울리는 함수
  /* page를 닫을때 울리는 함수들 */
  hiding(page_el$, val) {}, // page이 가려지기 전 울리는 함수
  hided(page_el$, val) {}, // page이 가려진 후 울리는 함수
  removing(page_el$, val) {}, // page이 삭제되기 전 울리는 함수
  removed(page_el$, val) {}, // page이 삭제된 후 울리는 함수
};
