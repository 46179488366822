import { html } from 'fxjs/es';

export const fn = (src) => html`
  <div class="maker_guide_body">
    <div class="img"><img src="${src}" alt="" /></div>
    <div class="btns">
      <button class="btn_next">${T('maker::Message::그대로 작업하기')}</button>
      <button class="btn_edit">${T('maker::Message::수정하러 가기')}</button>
    </div>
  </div>
`;
