import { UtilS } from '../../S/Function/module/UtilS.js';

/**
 * @define 셀렉트 옵션 박스의 선택된 옵션을 안전하게 가져오는 함수
 * @return {any} 선택값
 * */
export function getSelectBoxOptionValue(selector) {
  const el = document.querySelector(selector);
  if (el == null) return null;
  const value = el.options[el.selectedIndex]?.value;
  return UtilS.returnIfEmptyNull(value);
}
