import { defaults, extend, go, tap } from 'fxjs/es';
import { getCvObj } from '../../Fcanvas/cv_object.js';

export function compositeObj(obj) {
  return extend(
    {
      area: { top: 0, left: 0, width: 0, height: 0 },
      m: null,
    },
    obj,
  );
}

export const makeCvMockupInMaker = function (fcanvas) {
  const last_cv_print_area = getCvObj(fcanvas._objects, 'cv_mockup');
  if (last_cv_print_area) fcanvas.remove(last_cv_print_area);
  return go(
    new fabric.Rect(
      defaults(
        {
          selectable: false,
          evented: false,
          visible: false,
          // fill: 'transparent',
          fill: 'rgb(0, 0, 0, 1)',
          _data: {
            cv_type: 'cv_mockup',
            is_not_design: true,
          },
        },
        fcanvas.preview.composite.area,
      ),
    ),
    G.mp.maker.nscreen,
    tap((cv_obj) => fcanvas.add(cv_obj)),
  );
};
