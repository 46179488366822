import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSinglePCCuttingLineManualMuiF } from '../Mui/module/VectorEditorStickerSinglePCCuttingLineManualMuiF.js';

export const makeCuttingLineManual = ({
  target_image_el,
  cutting_line_path_data,
  frame_position: { top: frame_position_top, height: frame_position_height },
  prev_frame_right_panel_el,
  prev_page_right_panel_el,
  postProcess,
  editor_padding: {
    top: editor_padding_top,
    bottom: editor_padding_bottom,
    left: editor_padding_left,
    right: editor_padding_right,
  },
}) =>
  MuiF.pushPage(VectorEditorStickerSinglePCCuttingLineManualMuiF.page, (page, [tab]) => {
    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_frame_position_top = frame_position_top;
      tab_el.__mp_frame_position_height = frame_position_height;
      tab_el.__mp_prev_frame_right_panel_el = prev_frame_right_panel_el;
      tab_el.__mp_prev_page_right_panel_el = prev_page_right_panel_el;
      tab_el.__mp_target_image_el = target_image_el;
      tab_el.__mp_cutting_line_path_data = cutting_line_path_data;
      tab_el.__mp_postProcess = postProcess;
      tab_el.__mp_editor_padding_top = editor_padding_top;
      tab_el.__mp_editor_padding_bottom = editor_padding_bottom;
      tab_el.__mp_editor_padding_left = editor_padding_left;
      tab_el.__mp_editor_padding_right = editor_padding_right;
      return tab_appending.call(tab, tab_el);
    };
  });
