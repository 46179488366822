import { each, find, go, map } from 'fxjs/es';
import { getCvObj, makeFilterCvObj } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { NewMakerUtilF } from '../../../Util/F/Function/module/NewMakerUtilF.js';
import { getDesigns } from '../../../../Maker/F/getSth.js';

export const makeCvObjAlignment = ({ top, left, width, height }) => {
  return go(
    new fabric.Rect({
      top,
      left,
      width,
      height,
      opacity: 0.6,
      selectable: false,
      evented: false,
      strokeWidth: 0,
      visible: false,
      fill: '#00f8ff',
      _data: {
        cv_type: 'cv_obj_alignment',
        is_not_design: true,
      },
    }),
    G.mp.maker.nscreen,
  );
};

export const addCvObjAlignmentToFcanvas = (fcanvas, size_face) => {
  go(
    makeFilterCvObj(fcanvas._objects, 'cv_obj_alignment'),
    each((cv_obj) => fcanvas.remove(cv_obj)),
  );
  const attrs = size_face.cv_obj_alignments_attrs;
  if (!attrs?.length) return;
  go(
    attrs,
    map(makeCvObjAlignment),
    each((cv_obj) => {
      fcanvas.add(cv_obj);
    }),
  );
  fcanvas.renderAll();
};

export const makeAlignmentCenter = (cv_object, fcanvas) => {
  const target_cv_obj =
    NewMakerUtilF.getNearCvObjAlignment(makeFilterCvObj(fcanvas._objects, 'cv_obj_alignment'), cv_object) ||
    getCvObj(fcanvas._objects, 'cv_print_area');
  if (target_cv_obj) {
    cv_object.setPositionByOrigin(target_cv_obj.getCenterPoint(), 'center', 'center');
    cv_object.setCoords();
  }
};

export const makeAlignmentCenter2 = (cv_object, fcanvas) => {
  /* 항상 아무것도 없는 쪽에 중간정렬 해준다. */
  const cv_obj_alignments = makeFilterCvObj(fcanvas._objects, 'cv_obj_alignment');
  if (!cv_obj_alignments?.length) return;
  const target_cv_obj = go(
    getDesigns(fcanvas),
    find((co) => co !== cv_object),
    (co) => {
      if (!co) return cv_obj_alignments[0];
      const target = NewMakerUtilF.getNearCvObjAlignment(cv_obj_alignments, co);
      return go(
        cv_obj_alignments,
        find((coa) => coa !== target),
      );
    },
  );
  if (target_cv_obj) {
    cv_object.setPositionByOrigin(target_cv_obj.getCenterPoint(), 'center', 'center');
    cv_object.setCoords();
  }
};
