import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { each, go, tap } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { VectorEditorAcrylicFigureFreeMobileElementLayerMuiF } from '../Mui/module/VectorEditorAcrylicFigureFreeMobileElementLayerMuiF.js';

export const openElementLayer = ({ acrylic_figure_editor, editor_wrapper_el }) =>
  new Promise((resolve, reject) => {
    let tab_el = null;

    MuiF.openFrame(VectorEditorAcrylicFigureFreeMobileElementLayerMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = (frame_el, val) =>
        go(
          frame_closed.call(frame, frame_el, val),
          tap(() => resolve(val)),
        );

      const tab_appending = tab.appending;
      tab.appending = (_tab_el) => {
        tab_el = _tab_el;
        _tab_el.__mp_acrylic_figure_editor = acrylic_figure_editor;
        _tab_el.__mp_editor_wrapper_el = editor_wrapper_el;
        return tab_appending.call(tab, _tab_el);
      };
    })
      .then(() => {
        if (tab_el != null) {
          return go(
            tab_el,
            $findAll(`.list_container`),
            each((el) => new Swiper(el, { slidesPerView: 'auto', freeMode: true, direction: 'vertical' })),
          );
        }
      })
      .catch(reject);
  });
