import { getCurrentFcanvas, getCvObj } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { getItsSf } from '../../../../../Maker/F/getSth.js';
import { cv_type } from '../../S/Constant/cvbackground.js';
import { add } from './add.js';

export const getOrMakeCvBackground = () => {
  const fcanvas = getCurrentFcanvas();
  const size_face = getItsSf(fcanvas);
  return getCvObj(fcanvas._objects, cv_type) || add(fcanvas, size_face);
};
