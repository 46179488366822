import { each, entries, go, head, map } from 'fxjs/es';
import { StrokeNS } from '@marpple/sticker-editor';
import { SVGEditorFontConstS } from '../../../Font/S/Const/module/SVGEditorFontConstS.js';

function convertStrokeDasharrayStrToArray(stroke_dash_array) {
  return go(stroke_dash_array ? stroke_dash_array.split(',') : [], (arr) => {
    if (arr.length !== 2) arr = [0, 0];
    return map(parseFloat)(arr);
  });
}

function getFontFamily(value) {
  return go(
    value.split(','),
    map((v) => v.trim()),
    head,
  );
}

function setFontFamily(value) {
  return `${getFontFamily(value)}, ${SVGEditorFontConstS.DEFAULT_DEFAULT_FONT_FAMILLY}`;
}

export const get = (attr, svg_el) => {
  const value = svg_el.getAttributeNS(null, attr);
  if (attr === 'fill') return value;
  if (attr === 'stroke') return value;
  if (attr === 'opacity') {
    return parseFloat(parseFloat(value).toFixed(2)) || 1;
  }
  if (svg_el.nodeName === 'text') {
    if (attr === 'font-family') {
      return getFontFamily(value);
    }
    if (attr === 'text-decoration') {
      return value.split(' ');
    }
    if (attr === 'strokeDasharray1') {
      return convertStrokeDasharrayStrToArray(svg_el.getAttributeNS(null, 'stroke-dasharray'))[0];
    }
    if (attr === 'strokeDasharray2') {
      return convertStrokeDasharrayStrToArray(svg_el.getAttributeNS(null, 'stroke-dasharray'))[1];
    }
    if (attr === 'letter-spacing') {
      return parseFloat(parseFloat(value).toFixed(1));
    }
    if (attr === 'stroke-width') {
      return parseFloat(value);
    }
  }
  if (svg_el.nodeName === 'path' || svg_el.nodeName === 'circle') {
    if (attr === 'strokeDasharray1') {
      return JSON.parse(svg_el.dataset[StrokeNS.ModelNS.DATA_KEY_STROKE_DASHARRAY])[0];
    }
    if (attr === 'strokeDasharray2') {
      return JSON.parse(svg_el.dataset[StrokeNS.ModelNS.DATA_KEY_STROKE_DASHARRAY])[1];
    }
    if (attr === 'stroke-width') {
      return parseFloat(svg_el.dataset[StrokeNS.ModelNS.DATA_KEY_STROKE_WIDTH]);
    }
  }

  return value;
};

export const set = (obj, svg_el, mp_sticker_editor) => {
  go(
    obj,
    entries,
    each(([k, v]) => {
      if (svg_el.nodeName === 'text') {
        if (k === 'font-family') {
          v = setFontFamily(v);
        }
        if (k === 'strokeDasharray1') {
          const arr = [v, get('strokeDasharray2', svg_el)];
          svg_el.setAttributeNS(null, 'stroke-dasharray', arr.join(','));
          return;
        }
        if (k === 'strokeDasharray2') {
          const arr = [get('strokeDasharray1', svg_el), v];
          svg_el.setAttributeNS(null, 'stroke-dasharray', arr.join(','));
          return;
        }
        if (k === 'text-decoration') {
          svg_el.setAttributeNS(null, k, v ? v.join(' ').trim() : '');
          return;
        }

        if (k === 'letter-spacing') {
          svg_el.setAttributeNS(null, k, v);
          mp_sticker_editor?.syncSelectorWithSelection();
          return;
        }
      }
      if (svg_el.nodeName === 'path' || svg_el.nodeName === 'circle') {
        if (k === 'stroke-width') {
          mp_sticker_editor?.setStrokeWidth({ el: svg_el, stroke_width: v });
          return;
        }
        if (k === 'strokeDasharray1') {
          const stroke_dasharray = JSON.parse(svg_el.dataset[StrokeNS.ModelNS.DATA_KEY_STROKE_DASHARRAY]);
          stroke_dasharray[0] = v;
          mp_sticker_editor?.setStrokeDasharray({ el: svg_el, stroke_dasharray });
          return;
        }
        if (k === 'strokeDasharray2') {
          const stroke_dasharray = JSON.parse(svg_el.dataset[StrokeNS.ModelNS.DATA_KEY_STROKE_DASHARRAY]);
          stroke_dasharray[1] = v;
          mp_sticker_editor?.setStrokeDasharray({ el: svg_el, stroke_dasharray });
          return;
        }
      }
      svg_el.setAttributeNS(null, k, v);
    }),
  );
  svg_el.dispatchEvent?.(
    new window.CustomEvent('@mp/sticker-editor/attr-change', {
      bubbles: true,
      cancelable: true,
      composed: false,
      detail: {
        el: svg_el,
      },
    }),
  );
  return svg_el;
};
