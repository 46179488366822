import { each, extend, go, find } from 'fxjs/es';
import { $closest, $find, $trigger } from 'fxdom/es';
import { render_canvas } from '../../Core/S/composite_template_canvas.js';

export const makeIsDoneFalse = (e) => {
  const isDoneNode = go(e.currentTarget, $closest('tr'), $find('input[name="is_abs_color_done"]'));
  if (isDoneNode.checked) $trigger('click', isDoneNode);
};

export const renderDonTabCanvas = async function (don_tab_el) {
  const { assoc_composite_templates } = don_tab_el.tab_opt;
  const bpc_data = extend({}, $find('.color_info.selected', don_tab_el).dataset);
  // bpc_data.is_melange = bpc_data.is_melange === 'true';
  bpc_data.id = parseInt(bpc_data.id);
  bpc_data.base_product_id = parseInt(bpc_data.base_product_id);
  go(
    assoc_composite_templates,
    each((ct) =>
      go(
        ct._.composite_masks,
        each((cm) =>
          go(
            cm._.base_product._.base_product_colors,
            find((bpc) => bpc.id === bpc_data.id),
            (bpc) => {
              extend(bpc, bpc_data);
            },
          ),
        ),
      ),
    ),
  );

  await go(
    assoc_composite_templates,
    each((assoc_composite_template) => {
      const canvas_el = go(
        don_tab_el,
        $find(`.composite_template[data-id="${assoc_composite_template.id}"] canvas`),
      );
      canvas_el.width = 900;
      return render_canvas({
        assoc_composite_template,
        canvas_el,
        selected_base_product_colors: [bpc_data],
      });
    }),
  );
};
