import { html } from 'fxjs/es';

export const printGuide = ({ print_guide }) => html`
  <div class="toggle_cont toggle_cont_img print_guide" is_open="true">
    <div class="tg_head">
      <span class="tit">${T('product_detail::Print Guide')}</span>
      <span class="btn"></span>
    </div>
    <div class="tg_body">
      <div class="print_guide_text">${print_guide}</div>
    </div>
  </div>
`;
