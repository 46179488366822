import { $qs, $setAttr } from 'fxdom/es';
import { go, pluck, some, compact } from 'fxjs/es';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';

let previous_design_sources = [];
let previous_pdf_printing_file_id = null;
export const is_shared_product_design_changed = () => {
  const is_vector_editor = $qs('.mp_product_detail').dataset.is_vector_editor === 'true';
  const product_color = box().maker.product_color;
  const current_pdf_printing_file_id = product_color.pdf_printing_file_id;

  if (!current_pdf_printing_file_id && !is_vector_editor) return;

  if (current_pdf_printing_file_id) {
    if (previous_pdf_printing_file_id) {
      const is_new_pdf_printing_file = current_pdf_printing_file_id != previous_pdf_printing_file_id;
      $setAttr({ can_save: is_new_pdf_printing_file }, $qs('#to_save_changes'));
    }

    previous_pdf_printing_file_id = current_pdf_printing_file_id;
  } else {
    const current_design_sources = go(product_color.product_faces2.value[0].designs, pluck('src'), compact);

    if (previous_design_sources.length) {
      const is_edited =
        previous_design_sources.length !== current_design_sources.length ||
        go(
          previous_design_sources,
          some((src) => !current_design_sources.includes(src)),
        ) ||
        go(
          current_design_sources,
          some((src) => !previous_design_sources.includes(src)),
        );

      if (UtilF.isLegacyMobile()) {
        $setAttr({ can_save: is_edited }, $qs('.footer_buttons #to_save_changes'));
      } else {
        $setAttr({ can_save: is_edited }, $qs('#to_save_changes_vector'));
      }
    }

    previous_design_sources = current_design_sources;
  }
};
