import { compact, go, map, mapC } from 'fxjs/es';
import { DfAutoImageWorkConstantS } from '../../S/Constant/module/DfAutoImageWorkConstantS.js';
import { DfAutoImageWorkF } from './module/DfAutoImageWorkF.js';
import { changeDpiDataUrl } from 'changedpi';
import { LoaderF } from '../../../../Loader/F/Function/module/LoaderF.js';

export const eventAutoPrintFile = async ({ press_type_id, ready_print_files }) => {
  if (!ready_print_files.length) return $.alert('작업할 대상이 없습니다.');
  const work_count = ready_print_files.length * 2;
  const loaderCount = LoaderF.init({ work_count, message: '이미지 작업 진행중입니다.' });
  return go(
    ready_print_files,
    map(async ({ product_face, printable_product_id, color_code }) => {
      const bpf_id = product_face.bpf_id;
      const dpi = 300;
      let result;
      try {
        if (press_type_id === DfAutoImageWorkConstantS.DTP.press_type_id) {
          result = {
            bpf_id,
            base64: await go(
              DfAutoImageWorkF.makeTrimmedCanvas({
                product_face,
                press_type_id,
                dpi,
              }),
              (canvas) => changeDpiDataUrl(canvas.toDataURL('image/png', 1), dpi),
            ).catch((e) => {
              console.log(e);
              $.alert(e.message);
            }),
            printable_product_id,
          };
        } else if (press_type_id === DfAutoImageWorkConstantS.DTF.press_type_id) {
          result = {
            bpf_id,
            base64: await go(
              DfAutoImageWorkF.makeTrimmedCanvas({
                product_face,
                press_type_id,
                dpi,
              }),
              (canvas) => DfAutoImageWorkF.makeCanvasWithOutline(canvas, color_code, dpi),
              (canvas) => changeDpiDataUrl(canvas.toDataURL('image/png', 1), dpi),
            ).catch((e) => {
              console.log(e);
              $.alert(e.message);
            }),
            printable_product_id,
          };
        }
      } finally {
        loaderCount();
      }
      return result;
    }),
    mapC(async ({ bpf_id, base64, printable_product_id }) => {
      if (!base64) {
        loaderCount();
        return;
      }
      let result;
      try {
        result = {
          bpf_id,
          printable_product_id,
          printable_file: await DfAutoImageWorkF.makePrintableFile({ press_type_id, base64 }),
        };
      } finally {
        loaderCount();
      }
      return result;
    }),
    compact,
  );
};
