import { VectorEditorStickerSinglePCCuttingLineAutoF } from '../Function/module/VectorEditorStickerSinglePCCuttingLineAutoF.js';
import { VectorEditorStickerSinglePCCuttingLineManualF } from '../../../CuttingLineManual/F/Function/module/VectorEditorStickerSinglePCCuttingLineManualF.js';
import { $closest, $find, $scrollTop, $setScrollTop } from 'fxdom/es';

export const handleRightPanelOffsetDecreaseClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_cutting_line_index <= 0) {
      return;
    }

    $.don_loader_start();
    try {
      const cutting_line = await VectorEditorStickerSinglePCCuttingLineAutoF.getCuttingLine({
        tab_el,
        index: tab_el.__mp_cutting_line_index - 1,
      });
      VectorEditorStickerSinglePCCuttingLineAutoF.setCuttingLine({
        tab_el,
        label: cutting_line.label,
        path_data: cutting_line.path_data,
      });
      tab_el.__mp_cutting_line_index = cutting_line.index;
    } catch (error) {
      $.alert(
        error?.__mp_alert_message ?? T('modules::VectorEditor::Sticker::message::재단선을 만들 수 없습니다.'),
      );
    } finally {
      $.don_loader_end();
    }
  };

export const handleRightPanelOffsetIncreaseClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_cutting_line_index >= tab_el.__mp_cutting_lines.length - 1) {
      return;
    }

    $.don_loader_start();
    try {
      const cutting_line = await VectorEditorStickerSinglePCCuttingLineAutoF.getCuttingLine({
        tab_el,
        index: tab_el.__mp_cutting_line_index + 1,
      });
      VectorEditorStickerSinglePCCuttingLineAutoF.setCuttingLine({
        tab_el,
        label: cutting_line.label,
        path_data: cutting_line.path_data,
      });
      tab_el.__mp_cutting_line_index = cutting_line.index;
    } catch (error) {
      $.alert(
        error?.__mp_alert_message ?? T('modules::VectorEditor::Sticker::message::재단선을 만들 수 없습니다.'),
      );
    } finally {
      $.don_loader_end();
    }
  };

export const handleRightPanelEditManualClick =
  ({ tab_el }) =>
  async () => {
    if ($scrollTop(window) > 30) {
      await window.anime({
        targets: 'html, body',
        scrollTop: 0,
        duration: 400,
        easing: 'easeInOutCubic',
      }).finished;
    } else {
      $setScrollTop(0)(window);
    }

    const page_el = $closest('.don_page')(tab_el);
    page_el.style.display = 'block';
    tab_el.__mp_is_go_to_next_page = true;
    await VectorEditorStickerSinglePCCuttingLineManualF.makeCuttingLineManual({
      frame_position: {
        top: tab_el.__mp_frame_position_top,
        height: tab_el.__mp_frame_position_height,
      },
      prev_frame_right_panel_el: tab_el.__mp_prev_frame_right_panel_el,
      prev_page_right_panel_el: $find(`.right_container .right_wrapper`)(tab_el),
      target_image_el: tab_el.__mp_target_image_el,
      cutting_line_path_data: tab_el.__mp_cutting_line_editor.getCuttingLinePathData(),
      postProcess: tab_el.__mp_postProcess,
      editor_padding: {
        top: tab_el.__mp_editor_padding_top,
        bottom: tab_el.__mp_editor_padding_bottom,
        left: tab_el.__mp_editor_padding_left,
        right: tab_el.__mp_editor_padding_right,
      },
    });
    page_el.style.removeProperty('display');
  };
