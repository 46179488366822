import { each, eachL, entriesL, filter, flatMap, go, map, mapL, rangeL, takeAll } from 'fxjs/es';
import { createImageUrl } from '../../../../../Qr/S/Function/create.js';
import { label_aztec_code_src } from '../../../../Projection/List/S/label_aztec_code_src.js';

export const isDateBlank = (date_pickers_arr) => {
  const blank_picker_arr = [];
  go(
    date_pickers_arr,
    each((picker) => {
      if (!picker.latestSelectedDateObj) {
        blank_picker_arr.push(picker);
      }
    }),
  );
  return blank_picker_arr;
};

export const blinkPickerInput = (picker, blink_color, duration) => {
  let picker_arr = [];
  if (!Array.isArray(picker)) {
    picker_arr.push(picker);
  } else {
    picker_arr = picker;
  }
  go(
    picker_arr,
    each((picker) => {
      picker._input.style.backgroundColor = blink_color;
      window.setTimeout(() => (picker._input.style.backgroundColor = '#ffff'), duration);
    }),
  );
};

export const changePickerSelectedDate = (picker, shift_day) => {
  const sel_date = picker.latestSelectedDateObj;
  sel_date.setDate(sel_date.getDate() + shift_day);
  picker.setDate(sel_date);
};

export const changeDate = (utc, shift_day) => {
  const current_date = utc.getDate();
  utc.setDate(current_date + shift_day);
  return utc;
};

const convertHHMMstrToObj = (hhmm_str) => {
  const time = hhmm_str.split(':');
  return { hh: Number(time[0]), mm: Number(time[1]) };
};

const isTimeOver = (ref_time, check_time) => {
  if (check_time.hh > ref_time.hh) {
    return true;
  } else if (check_time.hh === ref_time.hh) {
    return check_time.mm >= ref_time.mm;
  } else {
    return false;
  }
};

export const isTimeInRange = (utc_str, start_hh_mm_str, end_hh_mm_str) => {
  const start_hhmm = convertHHMMstrToObj(start_hh_mm_str);
  const end_hhmm = convertHHMMstrToObj(end_hh_mm_str);
  const check_utc_time = new Date(utc_str);
  const check_hhmm = { hh: check_utc_time.getHours(), mm: check_utc_time.getMinutes() };
  return isTimeOver(start_hhmm, check_hhmm) && !isTimeOver(end_hhmm, check_hhmm);
};

export const isIsoDate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
    return false;
  }
  const d = new Date(str);
  return d.toISOString() === str;
};

export const blackCellFormatter = (cell, formatterParams, onRendered) => {
  const value = cell.getValue();
  if (!value) {
    cell.getElement().style.backgroundColor = '#bbb';
  }
  return value;
};

export const dayFormatting = (cell, formatterParams, onRendered) => {
  //Input: ISO date format
  const val = cell.getValue();
  if (val) {
    const date_obj = new Date(val);
    return `${date_obj.getDate()}일`;
  }
};

export const dateFormatting = (cell, formatterParams, onRendered) => {
  //Input: ISO date format
  const date_ISO = cell.getValue();
  if (date_ISO) {
    if (formatterParams.is_color && cell.getData().is_check) {
      cell.getElement().style.color = 'red';
    }
    const date_obj = new Date(date_ISO);
    return `${date_obj.getDate()}일 ${date_obj.getHours().toString().padStart(2, '0')}:${date_obj
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  }
};

export const dateSimplifyFormatter = (cell, formatterParams, onRendered) => {
  const date_values = cell.getValue();
  if (date_values) {
    const date_simple_view = date_values.split('-')[2].split(' ');
    if (formatterParams.is_color && !cell.getData().is_check) {
      cell.getElement().style.color = 'red';
    }
    return `${date_simple_view[0].replace(/(^0+)/, '')}일 ${date_simple_view[1]}`;
  }
};

export const convertISOtoSimpleFormat = (ISO_date_str) => {
  const d = new Date(ISO_date_str);
  return `${d.getFullYear() - 2000}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d
    .getDate()
    .toString()
    .padStart(2, '0')} ${d.getHours().toString().padStart(2, '0')}:${d
    .getUTCMinutes()
    .toString()
    .padStart(2, '0')}`;
};

export const addNumberingToLabelList = (label_data, from_row_idx = 0, add_data_obj) => {
  let no = from_row_idx;
  let up_no = 0;
  go(
    label_data,
    each((label) => {
      label.no = ++no;
      up_no = 0;
      if (add_data_obj) {
        go(
          add_data_obj,
          entriesL,
          eachL(([k, v]) => (label[k] = v)),
          takeAll,
        );
      }
      each((label) => (label.up_no = ++up_no), label.bp_c_ss);
    }),
  );
  return label_data;
};

export const getTreeMergeData = (row_data, start_col_idx, end_col_idx, start_data_row) => {
  let row_idx = start_data_row;
  return go(
    row_data,
    map((row) => row.getTreeChildren().length),
    flatMap((merge_height) => {
      const merge_data = getHeaderColumnMergeData(start_col_idx, end_col_idx, row_idx, merge_height + 1);
      row_idx += merge_height + 1;
      return merge_data;
    }),
  );
};

export const getHeaderRowMergeData = (start_col_idx, end_col_idx) => {
  return { s: { r: 0, c: start_col_idx }, e: { r: 0, c: end_col_idx } };
};

export const getHeaderColumnMergeData = (start_col_idx, end_col_idx, start_row_idx, height) => {
  return go(
    rangeL(start_col_idx, end_col_idx),
    mapL((col_idx) => {
      return { s: { r: start_row_idx, c: col_idx }, e: { r: start_row_idx + (height - 1), c: col_idx } };
    }),
    takeAll,
  );
};

export const getColumnLength = (table) => {
  return {
    total: go(
      table.getColumns(),
      filter((c) => c.getDefinition().download),
      (c) => c.length,
    ),
    parent: go(
      table.getColumns(true),
      filter((c) => {
        const def = c.getDefinition();
        return def.download && !def.columns;
      }),
      (c) => c.length,
    ),
    init: function () {
      this.child = this.total - this.parent;
      return this;
    },
  }.init();
};

export const getExcelColumnWidthArr = (table) => {
  const excel_column_widths = [];
  go(
    table.getColumns(),
    each((c) => {
      if (c.getDefinition().download) {
        excel_column_widths.push({ wch: Math.ceil(c.getWidth()) / 8 });
      }
    }),
  );
  return excel_column_widths;
};

export const changeStyleOnHyperlink = (e, cell) => {
  const val = cell.getValue();
  if (val) {
    cell.getElement().classList.add('link');
  }
  return val;
};

export const changeStyleOffHyperlink = (e, cell) => {
  const val = cell.getValue();
  if (val) {
    cell.getElement().classList.remove('link');
  }
  return val;
};

export const convertUTCtoHyphenDate = (utc_date_obj) => {
  return `${utc_date_obj.getFullYear()}-${utc_date_obj.getMonth() + 1}-${utc_date_obj.getDate()}`;
};

export const convertDateToHumanize = (date_obj) => {
  return `${date_obj.getFullYear()}년 ${date_obj.getMonth() + 1}월 ${date_obj.getDate()}일 ${String(
    date_obj.getHours(),
  ).padStart(2, '0')}:${String(date_obj.getMinutes()).padStart(2, '0')}`;
};

export const getDateObjFromToday = (shift_day, time_str) => {
  const date = new Date();
  date.setDate(date.getDate() + shift_day);
  if (time_str) {
    const time = time_str.split(':');
    const hr = Number(time[0]);
    const min = Number(time[1]);
    date.setHours(hr);
    date.setMinutes(min);
  }
  return date;
};

export const createSelectNode = (id, option_array) => {
  const select_node = document.createElement('select');
  select_node.id = id;
  go(
    option_array,
    each((option_item) => {
      const option = document.createElement('option');
      option.value = option_item;
      option.text = option_item;
      select_node.appendChild(option);
    }),
  );
  return select_node;
};

export const inEqualityFilterEditor = function (cell, onRendered, success, cancel, editorParams) {
  const container = document.createElement('span');

  //create and style inputs
  const operator = createSelectNode('filter_type', ['', '>', '<']);
  const criteria_value = document.createElement('input');
  criteria_value.setAttribute('type', 'text');
  criteria_value.setAttribute('placeholder', 'value');
  criteria_value.style.padding = operator.style.padding = '4px 2px';
  criteria_value.style.width = '40px';
  operator.style.width = '35px';
  criteria_value.style.boxSizing = operator.style.boxSizing = 'border-box';

  criteria_value.value = cell.getValue();

  function buildValues() {
    success({
      value: criteria_value.value,
      operator: operator.value,
    });
  }

  function keypress(e) {
    if (e.keyCode == 13) {
      //enter
      buildValues();
    }

    if (e.keyCode == 27) {
      //esc
      cancel();
    }
  }

  // end = start.cloneNode();
  operator.setAttribute('placeholder', 'op');

  criteria_value.addEventListener('change', buildValues);
  criteria_value.addEventListener('blur', buildValues);
  criteria_value.addEventListener('keydown', keypress);

  operator.addEventListener('change', buildValues);
  operator.addEventListener('blur', buildValues);
  operator.addEventListener('keydown', keypress);

  container.appendChild(operator);
  container.appendChild(criteria_value);

  return container;
};

//custom max min filter function
export function inEqualityFilterFunction(headerValue, rowValue, rowData, filterParams) {
  //headerValue - the value of the header filter element
  //rowValue - the value of the column in this row
  //rowData - the data for the row being filtered
  //filterParams - params object passed to the headerFilterFuncParams property

  if (rowValue) {
    if (headerValue.operator !== '' && headerValue.value !== '') {
      return stringBooleanCalculator(rowValue, headerValue.operator, headerValue.value);
    }
  }

  return true; //must return a boolean, true if it passes the filter.
}
const stringBooleanCalculator = (test_data, operator, criteria_value) => {
  switch (operator) {
    case '!=':
      return Boolean(Number(test_data) !== Number(criteria_value));
    case '=':
      return Boolean(Number(test_data) === Number(criteria_value));
    case '>=':
      return Boolean(Number(test_data) >= Number(criteria_value));
    case '<=':
      return Boolean(Number(test_data) <= Number(criteria_value));
    case '>':
      return Boolean(Number(test_data) > Number(criteria_value));
    case '<':
      return Boolean(Number(test_data) < Number(criteria_value));
    default:
      return true;
  }
};

export const makeUrlLabelAztecCodeFromLambda = (projection_id) =>
  createImageUrl({
    value: label_aztec_code_src({ projection_id }),
    margin: 0,
    format: 'webp',
    codeType: 'aztec',
    displayValue: false,
  });
