import { go, pluck } from 'fxjs/es';

export const confirm = [
  {
    base_product_id: 5588,
    pc: '//s3.marpple.co/files/u_193535/2022/6/original/86e0ba4659c7df2c850867b67595be199f3aee121.jpg',
    pc_en: '//s3.marpple.co/files/u_193535/2022/6/original/7a2f84d463fd1886912d3bb653ce75f9139744e15.jpg',
    pc_jp: '//s3.marpple.co/files/u_193535/2022/6/original/00f5a9494d1fe0e763676f0df757f008609909f96.jpg',
    m: '//s3.marpple.co/files/u_193535/2022/6/original/923b4a65ecc9083f2144643829cb0d24b57e44c61.jpg',
    m_en: '//s3.marpple.co/files/u_193535/2022/6/original/63aba89a99763b8df291677403a5d78e700e48782.jpg',
    m_jp: '//s3.marpple.co/files/u_193535/2022/6/original/0c2d24f683464376934ba127a90eeb7d80f6e03e3.jpg',
  },
  {
    base_product_id: 5589,
    pc: '//s3.marpple.co/files/u_193535/2022/6/original/86e0ba4659c7df2c850867b67595be199f3aee121.jpg',
    pc_en: '//s3.marpple.co/files/u_193535/2022/6/original/7a2f84d463fd1886912d3bb653ce75f9139744e15.jpg',
    pc_jp: '//s3.marpple.co/files/u_193535/2022/6/original/00f5a9494d1fe0e763676f0df757f008609909f96.jpg',
    m: '//s3.marpple.co/files/u_193535/2022/6/original/923b4a65ecc9083f2144643829cb0d24b57e44c61.jpg',
    m_en: '//s3.marpple.co/files/u_193535/2022/6/original/63aba89a99763b8df291677403a5d78e700e48782.jpg',
    m_jp: '//s3.marpple.co/files/u_193535/2022/6/original/0c2d24f683464376934ba127a90eeb7d80f6e03e3.jpg',
  },
  {
    base_product_id: 5590,
    pc: '//s3.marpple.co/files/u_193535/2022/6/original/86e0ba4659c7df2c850867b67595be199f3aee121.jpg',
    pc_en: '//s3.marpple.co/files/u_193535/2022/6/original/7a2f84d463fd1886912d3bb653ce75f9139744e15.jpg',
    pc_jp: '//s3.marpple.co/files/u_193535/2022/6/original/00f5a9494d1fe0e763676f0df757f008609909f96.jpg',
    m: '//s3.marpple.co/files/u_193535/2022/6/original/923b4a65ecc9083f2144643829cb0d24b57e44c61.jpg',
    m_en: '//s3.marpple.co/files/u_193535/2022/6/original/63aba89a99763b8df291677403a5d78e700e48782.jpg',
    m_jp: '//s3.marpple.co/files/u_193535/2022/6/original/0c2d24f683464376934ba127a90eeb7d80f6e03e3.jpg',
  },
  {
    base_product_id: 5591,
    pc: '//s3.marpple.co/files/u_193535/2022/6/original/86e0ba4659c7df2c850867b67595be199f3aee121.jpg',
    pc_en: '//s3.marpple.co/files/u_193535/2022/6/original/7a2f84d463fd1886912d3bb653ce75f9139744e15.jpg',
    pc_jp: '//s3.marpple.co/files/u_193535/2022/6/original/00f5a9494d1fe0e763676f0df757f008609909f96.jpg',
    m: '//s3.marpple.co/files/u_193535/2022/6/original/923b4a65ecc9083f2144643829cb0d24b57e44c61.jpg',
    m_en: '//s3.marpple.co/files/u_193535/2022/6/original/63aba89a99763b8df291677403a5d78e700e48782.jpg',
    m_jp: '//s3.marpple.co/files/u_193535/2022/6/original/0c2d24f683464376934ba127a90eeb7d80f6e03e3.jpg',
  },
];

export const bp_ids = go(confirm, pluck('base_product_id'));
