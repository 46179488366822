import { html, reject, some } from 'fxjs/es';
import { isNeedPreview } from '../../../../Maker/S/categorize.js';
import { NewMakerWeS } from '../../../../NewMaker/We/S/Function/module/NewMakerWeS.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreCellTmplS } from '../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { OMPCartS } from '../Function/module/OMPCartS.js';
import { OMPCartConstantS } from '../Constant/module/OMPCartConstantS.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';

export const separator = (up_cs) => {
  const base_product_colors = reject(
    (bpc) => some((up_c) => up_c.base_product_color_id === bpc.id, up_cs),
    up_cs[0]._.product_color._.base_product._.base_product_colors,
  );

  const disabled =
    NewMakerWeS.isWeBaseProduct(up_cs[0].base_product_id) ||
    up_cs[0]._.product_color?._?.base_product_color?.name === '' ||
    isNeedPreview(up_cs[0]._.product_color._.base_product) ||
    !base_product_colors.length ||
    !!up_cs[0]._.product_color._.base_product?.maker_features?.[
      NewMakerPropertyBaseProductConstantS.NOT_SHOW_ADD_COLOR
    ];
  return html`
    <div data-up_id="${up_cs[0].up_id}" class="omp-cart__footer">
      <div class="omp-cart__add-color" data-disabled="${disabled}">
        <div class="omp-cart__add-color-icon">
          ${OMPCoreAtomTmplS.plusIcon({ color: disabled ? 'GY' : 'OG' })}
        </div>
        <div>${TT('cart::cart_11')}</div>
      </div>
    </div>
  `;
};

export const cartTmplPc = ({ user_products, price_info, shipping_price }) => {
  const cart_title = TT('cart::cart_01');
  const projection_step = TT('cart::cart_02');
  const projection_done = TT('cart::cart_03');
  const up_cs_count = TT('cart::cart_12');
  const total_product_price = TT('cart::cart_13');
  const total_shipping = TT('cart::cart_14');
  const total_price = TT('cart::cart_15');
  const empty_notice = TT('cart::cart_18');
  const empty_desc = TT('cart::cart_19');

  const product_table_columns = OMPCartConstantS.CartProductTableColumns();

  const is_free_shipping_price_kr = 100000 - price_info.product_price;
  const is_kr = TT.lang === 'kr';
  const is_oversea = !is_kr;

  return html`
    <div class="omp-cart">
      <div class="omp-cart__header">
        <div class="omp-cart__header-title">${cart_title}</div>
        <div class="omp-cart__header-steps">
          <span class="omp-cart__header-step" data-active="true">${cart_title}</span>
          <span class="omp-cart__header-icon">
            ${OMPCoreAtomTmplS.arrowIcon({ direction: 'right', color: 'GY' })}
          </span>
          <span class="omp-cart__header-step">${projection_step}</span>
          <span class="omp-cart__header-icon">
            ${OMPCoreAtomTmplS.arrowIcon({ direction: 'right', color: 'GY' })}
          </span>
          <span class="omp-cart__header-step">${projection_done}</span>
        </div>
      </div>
      ${!user_products.length
        ? html`
            <div class="omp-cart__empty">
              <div class="omp-cart__empty-notice">${empty_notice}</div>
              <div class="omp-cart__empty-desc">${empty_desc}</div>
            </div>
          `
        : html`
            <div class="omp-cart__product-table">
              ${OMPCoreCellTmplS.paginatedTable({
                row_klass: 'omp-cart__product',
                columns: product_table_columns,
                data: OMPCartS.processUserProductsForCartTable(user_products),
                is_pagination_hidden: true,
                style: {
                  inter_border_color: 'GY_50',
                  border_color: 'GY_50',
                },
              })}
            </div>
            ${is_kr
              ? html`<div class="omp-cart__buttons">
                  <button type="button" class="omp-cart__buttons-quotation">견적서 인쇄</button>
                </div>`
              : ''}
            <div class="omp-cart__summary">
              <div class="omp-cart__summary-header">
                <span class="omp-cart__summary-header-title">${up_cs_count}</span>
                <span class="omp-cart__summary-header-title">${total_product_price}</span>
                ${is_oversea
                  ? ''
                  : html`
                      <span class="omp-cart__summary-header-title">
                        <span>${total_shipping}</span>
                        ${is_kr
                          ? html` <div class="omp-cart__shipping-price-notice-wrapper">
                              <div class="omp-cart__shipping-price-notice">
                                ${is_free_shipping_price_kr <= 0
                                  ? '무료로 배송해 드려요!'
                                  : html`<span class="omp-cart__shipping-price-notice-desc">
                                        ${UtilS.commify(is_free_shipping_price_kr)}원 더 담으면
                                        <span class="og">배송비 무료</span>
                                      </span>
                                      <a href="/${TT.lang}/best-products">
                                        <span> 더 담으러 가기 </span>
                                        <div class="omp-cart__shipping-price-notice-more">
                                          ${OMPCoreAtomTmplS.arrowIcon({ direction: 'right' })}
                                        </div>
                                      </a>`}
                              </div>
                            </div>`
                          : ''}
                      </span>
                    `}

                <span class="omp-cart__summary-header-title">${total_price}</span>
              </div>
              <div class="omp-cart__summary-body">
                <span class="omp-cart__summary-body-value">
                  ${TT('common::quantity', { quantity: UtilS.commify(price_info.up_cs_count) })}
                </span>
                <span class="omp-cart__summary-body-value">
                  ${TT('common::currency', { price: PriceS.pricify(price_info.product_price) })}
                </span>
                <span class="omp-cart__summary-body-value shipping-price">
                  ${TT('common::currency', { price: PriceS.pricify(shipping_price) })}
                </span>
                <div class="total-price">
                  <span class="omp-cart__summary-body-value" data-style_total="true">
                    ${TT('common::currency', {
                      price: PriceS.pricify(PriceS.add(price_info.product_price, shipping_price)),
                    })}
                    ${is_oversea ? T('mshop_cart_and_checkout::배송비추가') : ``}
                  </span>
                  <span class="delivery-fee-info">${TT('cart::cart_29')}</span>
                </div>
              </div>
              <div class="omp-cart__button">
                ${OMPCoreAtomTmplS.actionButton({
                  klass: 'omp-cart__checkout',
                  text: TT('cart::cart_16'),
                  solidity: 'thick',
                })}
              </div>
            </div>
          `}
    </div>
  `;
};
