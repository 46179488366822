import axios from 'axios';
import { go, sel } from 'fxjs/es';
import { makeApiUrl } from '../../../../Util/S/Function/util.js';

export const apiCalls = {
  post: {
    item: {
      createSku: async ({
        base_product_id,
        base_product_color_id,
        base_product_size_id,
        product_id,
        spo_item_id,
      }) => {
        return go(
          await axios.post('/@api/df/lgl/sku', {
            base_product_id,
            base_product_color_id,
            base_product_size_id,
            product_id,
            spo_item_id,
          }),
        );
      },
      createLglProduct: async ({ upcs_id }) => {
        return go(
          await axios.post('/@api/df/lgl/product', {
            upcs_id,
          }),
        );
      },
      createLglItem: async ({
        user_product_id,
        lgl_inbound_order_id,
        inbound_order_requested_quantity,
        thumbnail_urls,
      }) => {
        return go(
          await axios.post('/@api/df/lgl/item', {
            user_product_id,
            lgl_inbound_order_id,
            inbound_order_requested_quantity,
            thumbnail_urls,
          }),
          sel('data'),
        );
      },
    },
    inbound: {
      createOrder: async ({ projection_id, lgl_inbound_trans_code_id = 'CJ', waybill_no_domestic }) => {
        return go(
          await axios.post('/@api/df/lgl/inbound/order', {
            projection_id,
            lgl_inbound_trans_code_id,
            waybill_no_domestic,
          }),
          sel('data'),
        );
      },
      requestOrder: async ({ projection_id }) => {
        return go(
          await axios.post('/@api/df/lgl/inbound/orders/request', {
            projection_id,
          }),
          sel('data'),
        );
      },
      cancelRequestOrder: async ({ projection_id }) => {
        return go(
          await axios.post('/@api/df/lgl/inbound/orders/request_cancel', {
            projection_id,
          }),
          sel('data'),
        );
      },
    },
    rebound: {
      createLglItem: async ({ rebound_lgl_inbound_order_id, rebounded_lgl_item_id }) => {
        return go(
          await axios.post('/@api/df/lgl/rebound/item', {
            rebound_lgl_inbound_order_id,
            rebounded_lgl_item_id,
          }),
          sel('data'),
        );
      },
      createOrder: async ({ projection_id, lgl_inbound_trans_code_id = 'CJ', waybill_no_domestic }) => {
        return go(
          await axios.post('/@api/df/lgl/rebound/order', {
            projection_id,
            lgl_inbound_trans_code_id,
            waybill_no_domestic,
          }),
          sel('data'),
        );
      },
      requestOrder: async ({ rebound_lgl_inbound_order_id }) => {
        return go(
          await axios.post('/@api/df/lgl/rebound/order/request', {
            rebound_lgl_inbound_order_id,
          }),
          sel('data'),
        );
      },
      cancelRequestOrder: async ({ rebound_lgl_inbound_order_id }) => {
        return go(
          await axios.post('/@api/df/lgl/rebound/order/request_cancel', {
            rebound_lgl_inbound_order_id,
          }),
          sel('data'),
        );
      },
    },
    outbound: {
      cancelOrder: async ({ lgl_outbound_order_group_id }) => {
        return go(
          await axios.post('/@api/df/lgl/outbound/order_group/cancel', { lgl_outbound_order_group_id }),
          sel('data'),
        );
      },
      cancelRequestOrder: async ({ lgl_outbound_order_group_id }) => {
        return go(
          await axios.post('/@api/df/lgl/outbound/order_group/cancel', {
            lgl_outbound_order_group_id,
          }),
          sel('data'),
        );
      },
    },
  },
  get: {
    projection: {
      userProducts: async ({ projection_id }) => {
        const [user_products] = go(
          await axios.get(makeApiUrl('/@api/df/user_products/:projection_id', { projection_id })),
          sel('data'),
        );
        return user_products;
      },
    },
    inbound: {
      orderGroup: async ({ projection_id }) => {
        return go(
          await axios.get(
            makeApiUrl('/@api/df/lgl/inbound/order_group/projection/:projection_id', { projection_id }),
          ),
          sel('data'),
        );
      },
      isMatchPackingItemsWithUps: async ({ projection_id }) => {
        return go(
          await axios.get(
            makeApiUrl(
              '/@api/df/lgl/inbound/order_group/projection/:projection_id/is_match_packing_items_with_ups',
              { projection_id },
            ),
          ),
          sel('data'),
        );
      },
    },
    return: {
      orderGroup: async ({ projection_id }) => {
        return go(
          await axios.get(
            makeApiUrl('/@api/df/lgl/return/order_group/projection/:projection_id', { projection_id }),
          ),
          sel('data'),
        );
      },
    },
    outbound: {
      orderGroup: async ({ projection_id }) => {
        return go(
          await axios.get(
            makeApiUrl('/@api/df/lgl/outbound/order_group/projection/:projection_id', { projection_id }),
          ),
          sel('data'),
        );
      },
    },
    item: {
      inspection: async ({ lgl_item_id }) => {
        return go(
          await axios.get(makeApiUrl('/@api/df/lgl/item/inbound_inspection/:lgl_item_id', { lgl_item_id })),
          sel('data'),
        );
      },
      sku: async ({ user_product_id }) => {
        return go(
          await axios.get(makeApiUrl('/@api/df/lgl/item/sku/:user_product_id', { user_product_id })),
          sel('data'),
        );
      },
    },
    tracking: {
      overallStatus: async ({ projection_id }) => {
        return go(
          await axios.get(makeApiUrl('/@api/df/lgl/status/overall/:projection_id', { projection_id })),
          sel('data'),
        );
      },
    },
  },
  put: {
    item: {
      changeLglItemQuantity: async ({ lgl_item_id, type, action, quantity }) => {
        return go(
          await axios.put(
            makeApiUrl('/@api/df/lgl/item/:lgl_item_id/type/:type/action/:action/quantity/:quantity', {
              lgl_item_id,
              type,
              action,
              quantity,
            }),
          ),
        );
      },
    },
    inbound: {
      changeOrderPackStatus: async ({ pack_status, projection_id }) => {
        return go(
          await axios.put(
            makeApiUrl('/@api/df/lgl/inbound/orders/pack_status/:pack_status/projection/:projection_id', {
              pack_status,
              projection_id,
            }),
            sel('data'),
          ),
        );
      },
    },
    rebound: {
      changeOrderPackStatus: async ({ rebound_lgl_inbound_order_id, pack_status }) => {
        return go(
          await axios.put(
            makeApiUrl('/@api/df/lgl/rebound/order/:rebound_lgl_inbound_order_id/pack_status/:pack_status', {
              rebound_lgl_inbound_order_id,
              pack_status,
            }),
            sel('data'),
          ),
        );
      },
    },
    outbound: {
      holding: async ({ projection_id, is_outbound_holding }) => {
        return go(
          await axios.put(
            makeApiUrl('/@api/df/lgl/outbound/projection/:projection_id/holding/:is_outbound_holding', {
              projection_id,
              is_outbound_holding,
            }),
            sel('data'),
          ),
        );
      },
    },
  },
  delete: {
    inbound: {
      order: async ({ lgl_inbound_order_id }) => {
        return await axios.delete(
          makeApiUrl('/@api/df/lgl/inbound/order/:lgl_inbound_order_id', { lgl_inbound_order_id }),
          sel('data'),
        );
      },
    },
    item: {
      lglItem: async ({ lgl_item_id }) => {
        return await axios.delete(makeApiUrl('/@api/df/lgl/item/:lgl_item_id', { lgl_item_id }));
      },
    },
    rebound: {
      lglItem: async ({ lgl_item_id }) => {
        return await axios.delete(makeApiUrl('/@api/df/lgl/rebound/item/:lgl_item_id', { lgl_item_id }));
      },
      order: async ({ lgl_inbound_order_id }) => {
        return await axios.delete(
          makeApiUrl('/@api/df/lgl/rebound/order/:lgl_inbound_order_id', { lgl_inbound_order_id }),
          sel('data'),
        );
      },
    },
  },
};
