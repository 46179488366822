import { strMap } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';

export const makeEventListHtml = (events, is_df) => legacyHtml`
  <div id="event_list">
    ${
      is_df
        ? legacyHtml`
          <div class="buttons">
            <button id="new" type="button">새로운 이벤트 생성하기</button>
          </div>
        `
        : ''
    }
    <div class="header">
      <h1>${T('gnb::EVENT')}</h1>
    </div>
    <div class="body">
      <div class="event_list infi_container" _sel="event_list">${strMap(makeEventItemHtml, events)}</div>
    </div>
  </div>
`;

export const makeEventItemHtml = (event) => legacyHtml`
  <div class="event_item infi_item" _sel="./(#${event.id})">
    <a href="/event/detail/${event.id}">
      <div class="thumbnail" is_over="${event.is_over}">
        <div class="thumbnail_url">
          <img src="${event.thumbnail_url || ''}" alt="" />
        </div>
        <div class="is_over_wrap"></div>
      </div>
      <div class="info">
        <div class="title">${event.title}</div>
        <div class="sub_title">${event.sub_title || ''}</div>
        <div class="date">
          <span>${moment(event.open_at).format('YYYY.MM.DD HH:mm')}</span>
          <span>&nbsp~&nbsp${moment(event.close_at).format('YYYY.MM.DD HH:mm')}</span>
        </div>
      </div>
    </a>
  </div>
`;
