import { html, strMap } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { UtilF } from '../../../../../../Util/F/Function/module/UtilF.js';
import { OMPCoreFramesTabStyleConstantS } from '../../S/Constant/module/OMPCoreFramesTabStyleConstantS.js';
import { OMPCoreFramesTabStyleFMuiF } from '../Mui/module/OMPCoreFramesTabStyleMuiF.js';

export const openInfo = async ({
  tab_names,
  selected_name,
  type,
  info: { description, content },
  bp_id,
  bp_name,
}) => {
  return MuiF.openFrame(OMPCoreFramesTabStyleFMuiF.frame, (frame, page, [tab]) => {
    frame.hide_frame_button_type = 'X';
    const is_mobile = UtilF.isLegacyMobile();
    frame.prev_frame_show = false;
    frame.is_modal = !is_mobile;
    frame.always_remove = true;
    frame.attrs = {
      modal_title: type,
    };
    const { title, tabs } = OMPCoreFramesTabStyleConstantS.infos(type)[type];
    page.custom_header = () => {
      return html`<div class="modal_title">${title}</div>
        ${description
          ? html`<div class="modal_description">
              ${is_mobile
                ? html`<div class="modal_description__content">${description.content}</div>
                    <div class="modal_description__title">${description.title}</div> `
                : html`<div class="modal_description__title">${description.title}</div>
                    <div class="modal_description__content">${description.content}</div>`}
            </div>`
          : ''} `;
    };

    tab.makeData = () => ({ selected_name });
    tab.template = ({ selected_name }) => {
      return html`
        <div class="modal_wrapper">
          <div class="modal_side">
            <ul data-fx-json="${tab_names}" data-modal_title="${type}">
              ${strMap(
                (name) =>
                  `<li data-title="${name}" class="list ${
                    selected_name === name ? 'selected' : ''
                  }">${name}</li>`,
                tab_names,
              )}
            </ul>
          </div>
          <div class="modal_content">
            <div>
              ${content({
                type,
                tab_names,
                selected_name,
                tabs,
                bp_id,
                bp_name,
              })}
            </div>
          </div>
        </div>
      `;
    };
  });
};
