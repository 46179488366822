import { range, sumBy, min, curry2, mapObject, pick, go } from 'fxjs/es';
import querystring from 'querystring';
import { legacyHtml } from '../../../Util/S/Function/util.js';

const make_url = curry2(
  (path, query, next_page) => `${path}?${querystring.stringify({ ...query, current_page: next_page })}`,
);
const to_number_value = mapObject(Number);

export const main = (pagination_states = {}) => {
  const { url_path, query } = pagination_states;
  const { total_item_count, item_count, phase_size, current_page } = go(
    pagination_states,
    pick(['total_item_count', 'item_count', 'phase_size', 'current_page']),
    to_number_value,
  );

  const make_url_to_page = make_url(url_path, {
    ...query,
    phase_size,
    item_count,
  });

  const phase_start = current_page - (current_page % phase_size);
  const page_count = Math.ceil(total_item_count / item_count);
  const phase_end = phase_start + min([phase_size, page_count]) - 1;

  const has_prev_phase = phase_start >= phase_size;
  const has_next_phase = phase_size <= page_count;
  const prev_phase_url = make_url_to_page(phase_start - phase_size);
  const next_phase_url = make_url_to_page(phase_start + phase_size);

  return legacyHtml`
    <div class="df-pagination">
      <a data-direction="prev" ${has_prev_phase ? `href="${prev_phase_url}"` : 'class="disabled"'}><</a>
      ${sumBy(
        (n) => legacyHtml`
          <a
            class="page-number ${n === current_page ? 'current' : ''}"
            ${n === current_page ? '' : `href="${make_url_to_page(n)}"`}
            >${n + 1}</a
          >
        `,
        range(phase_start, phase_end + 1),
      )}
      <a data-direction="next" ${has_next_phase ? `href="${next_phase_url}"` : 'class="disabled"'}>></a>
    </div>
  `;
};
