import { html, View } from 'rune-ts';
import { go, each } from 'fxjs/es';

/**
 * @typedef {Object} Props
 * @property {string} title - The Title of Modal
 * @property {string} width - The width of the Modal
 * @property {string} height - The height of the Modal
 * @property {string} okText - The Ok Button Text of the Modal
 * @property {string} cancelText - The Cancel Button Text of the Modal
 * */

/**
 * Class representing a default modal view
 * @extends {View<Props>}
 * */
export class DefaultModalView extends View {
  /**
   * Render the children elements of the modal.
   * This method should be overridden by subclasses to provide custom content.
   */
  renderChildren() {}

  /**
   * Handle the OK button click event.
   * This method should be overridden by subclasses to provide custom behavior.
   * @param {Event} e - The click event.
   */
  handleOk(e) {}

  onRender() {
    this.addCancelListener();
    this.addOKListener();
  }

  show() {
    document.body.appendChild(this.render());
  }

  hide() {
    document.body.removeChild(this.element());
  }

  addOKListener() {
    this.element()
      .querySelector(`.${this}__container-footer-btn.ok`)
      .addEventListener('click', (e) => this.handleOk(e));
  }

  addCancelListener() {
    go(
      [
        this.element().querySelector(`.${this}__container-header__cancel_btn`),
        this.element().querySelector(`.${this}__container-footer-btn.close`),
        this.element().querySelector(`.${this}__container-dim`),
      ],
      each((elem) => elem.addEventListener('click', () => this.hide())),
    );
  }

  /**
   * @param {Props} data
   */
  template({ title = '옵션 선택', width = '80vw', height = '80vh', okText = '저장', cancelText = '닫기' }) {
    return html`<div
      class="${this}__wrapper base_modal__wrapper"
      style="position:fixed; top:50%; left: 50%; transform:translate(-50%, -50%); z-index: 999;"
    >
      <div class="${this}__container base_modal__container" style="width: ${width}; height: ${height};">
        <div class="${this}__container-header base_modal__container-header">
          <div class="${this}__container-header__title base_modal__container-header__title">${title}</div>
          <div class="${this}__container-header__cancel_btn base_modal__container-header__cancel_btn">X</div>
        </div>
        <div class="${this}__container-body base_modal__container-body">${this.renderChildren()}</div>
        <div class="${this}__container-footer base_modal__container-footer">
          <button class="${this}__container-footer-btn base_modal__container-footer-btn close">
            ${cancelText}
          </button>
          <button class="${this}__container-footer-btn base_modal__container-footer-btn ok">${okText}</button>
        </div>
      </div>
      <div class="${this}__container-dim base_modal__container-dim"></div>
    </div>`;
  }
}
