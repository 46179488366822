import { go, extend } from 'fxjs/es';
import { $delegate, $qs } from 'fxdom/es';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';
import { FILE_PROPERTY_NAME, TOOL_NAME } from '../../S/Constant/constants.js';
import { getCurrentOffsetPath } from './tool.vStroke.js';
import { uploadSvgFile } from './helpers.file.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';

export function toolFile({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  go(
    tab_el,
    $delegate('click', `.property-panel .controls[tool-name=${TOOL_NAME.file}] button`, async (e) => {
      const ct = e.currentTarget;
      const upload_action = ct.name;
      try {
        $.don_loader_start();

        /* 0. 파일 작업 처리 전 */
        const {
          projection_id,
          up_id,
          total_qty,
          face_name,
          base_product_face_id,
          product_id,
          press_type_id,
          press_type_name,
          cid,
        } = prepareFileExport();

        /* 1. 이미지 에디터 작업 캔버스 -> 작업된 이미지 data url 추출 (DPI 300 설정) */
        DfImageEditorF.hidePeriphralLayers({ paper_scope });

        const offset_top_by_editing = DfImageEditorF.calculateTotalEditingDesignOffsetY({ tab_el });

        let printable_file_data;
        /* 2. 인쇄용 시안 파일 업로드 하고 파일 정보 받기 */
        if (upload_action === 'cut-upload') {
          const cutting_path = getCurrentOffsetPath({
            stroke_layer: DfImageEditorF.getVectorStrokeLayer({ paper_scope }),
          });
          if (cutting_path == null) {
            $.alert(`칼선 작업이 필요합니다.`);
            return;
          }
          const { svg_string, svg_image_base64 } = await DfImageEditorF.getSvgPrintableImageWithCuttingPath({
            tab_el,
            paper_scope,
            cutting_path,
            file_info: { projection_id, face_name, total_qty, up_id },
            is_fill_color: containsStr(press_type_name, 'flex'),
          });

          /* 2-1. 인쇄용 시안 (PU + FLEX 용) svg + png 업로드 -> 파일 데이터 응답 */
          const [svg_file_data, svg_image_file_data] = await Promise.all([
            uploadSvgFile(svg_string, 'printable_file.svg'),
            $.uploadFileToUrl(svg_image_base64, 'printable_file'),
          ]);

          svg_file_data.png = svg_image_file_data;

          printable_file_data = svg_file_data;
        } else {
          const png_image_base64 = await getPngPrintableImageBase64({
            tab_el,
            paper_scope,
            upload_action,
          });

          /* 2-2. 인쇄용 시안 png 이미지 업로드 -> 파일 데이터 응답 */
          printable_file_data = await $.uploadFileToUrl(png_image_base64, 'printable_file');
        }

        printable_file_data = extend(printable_file_data, {
          cid,
          press_type_id,
          press_type_name,
          offset_top_by_editing,
        });

        /* 3. 프린트 파일 정보 업데이트 */
        const is_upload_ok = await DfImageEditorF.addPrintFile({
          product_id,
          file_data: printable_file_data,
          face_id: base_product_face_id,
        });
        if (is_upload_ok === false) {
          await DfImageEditorF.donAlertAsync({
            tab_el,
            msg: `인쇄 파일 정보 업데이트에 문제가 발생했습니다. Please retry.`,
          });
          return;
        }

        /* 4. 프린트 파일 총 수량 업데이트  */
        const is_update_print_file_count_ok = DfImageEditorF.updatePrintFileCounts({ projection_id });
        if (is_update_print_file_count_ok === false) {
          await DfImageEditorF.donAlertAsync({
            tab_el,
            msg: `인쇄 파일 수량 업데이트 문제가 발생했습니다. Please retry.`,
          });
          return;
        }

        MuiF.removeFrame();
      } catch (e) {
        await DfImageEditorF.donAlertAsync({
          tab_el,
          msg: e.message,
        });
      } finally {
        $.don_loader_end();
      }
    }),
  );
}

export function activateFileTool({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const file_tool_name = TOOL_NAME.file;

  const file_tool = DfImageEditorF.prepareTool({
    paper_scope,
    tool_name: file_tool_name,
    eventHandlers: {},
  });
  file_tool.activate();
}

function prepareFileExport() {
  const { projection_id, base_product_face_id, product_id, up_id, total_qty, face_name, cid } =
    DfImageEditorLibF.getState.printable_file_info();

  const $press_type = $qs(`.property-panel .property[property-name=${FILE_PROPERTY_NAME.press_type}] select`);
  const press_type_id = Number($press_type.value);
  const press_type_name = $press_type.options[$press_type.selectedIndex].text;

  return {
    cid,
    projection_id,
    base_product_face_id,
    product_id,
    press_type_id,
    up_id,
    total_qty,
    face_name,
    press_type_name,
  };
}

export function setWidthAndHeightIntoCm({ svg_el, DPI = 300 }) {
  /* svg 는 dpi 개념이 없기 때문에 svg el 를 원하는 cm size 로 width, height 설정
   *  -> adode 같은 3rd party program 에서 해당 unit scale 맞게 rendering 해 줌.
   *  -> 만약 px 로 설정되어 있으면 adode program 의 기본 dpi 해석 설정에 맞춰서 rendering 되므로 원하지 않은 크기로 rendering 됨
   * */
  const w = svg_el.getAttribute('width');
  const h = svg_el.getAttribute('height');

  const DPC = DPI / 2.54;

  svg_el.setAttribute('width', `${w / DPC}cm`);
  svg_el.setAttribute('height', `${h / DPC}cm`);
}

export async function getPngPrintableImageBase64({ tab_el, paper_scope, upload_action }) {
  const svg_el = paper_scope.project.exportSVG({
    bounds: 'content',
    embedImages: true,
  });

  const { multiplier: src_multiplier, dpi } = DfImageEditorF.getImageSourceMultiplierAndDpi({
    tab_el,
  });

  const image = await DfImageEditorF.svgToImg({ svg_el });
  const design_margin_x = DfImageEditorF.getDesignMarginX({ tab_el });
  const toMarginCanvas = DfImageEditorF.getMarginAddFn({ upload_action, design_margin_x });
  const toPrintableSizeCanvas = DfImageEditorF.getSizeRecoverFn({ src_multiplier });
  const to300Dpi = DfImageEditorF.setDpiFn({ dpi });

  return go(
    image,
    DfImageEditorF.imageToCanvas,
    DfImageEditorF.toJustifyCanvas,
    toMarginCanvas,
    toPrintableSizeCanvas,
    DfImageEditorF.canvasToDataUrl,
    to300Dpi,
  );
}

export function handleSvgImageFile({ tab_el, svg_image_canvas }) {
  const { multiplier: src_multiplier, dpi } = DfImageEditorF.getImageSourceMultiplierAndDpi({
    tab_el,
  });
  const to300Dpi = DfImageEditorF.setDpiFn({ dpi });
  const toPrintableSizeCanvas = DfImageEditorF.getSizeRecoverFn({ src_multiplier });

  return go(
    svg_image_canvas,
    DfImageEditorF.toJustifyCanvas,
    toPrintableSizeCanvas,
    DfImageEditorF.canvasToDataUrl,
    to300Dpi,
  );
}

function containsStr(mainString, searchString) {
  // 대소문자 구별 없이 검색할 문자열을 대문자로 변환
  const mainStringUpper = mainString.toUpperCase();
  const searchStringUpper = searchString.toUpperCase();

  // 대소문자 구별 없이 검색할 문자열을 포함하는지 확인
  return mainStringUpper.includes(searchStringUpper);
}
