import { $addClass, $closest, $find, $findAll, $removeClass } from 'fxdom/es';
import { go, map } from 'fxjs/es';
import { handleResizeTableHeader } from '../../ShippingDetail/F/Function/table.event.js';

export function handleTabChange({ target }) {
  const tab = $closest('.tabs', target);
  const tab_item = $closest('.tab_item', target);
  if (tab_item) {
    const tab_id = tab_item.id;
    const tabItems = $findAll('.tab_item', tab);
    go(
      tabItems,
      map((item) => $removeClass('selected', item)),
    );
    const tabContents = $findAll('.tab_content', tab);
    go(
      tabContents,
      map((content) => $removeClass('selected', content)),
    );

    $addClass('selected', tab_item);
    const [_, id] = tab_id.split('tab_item_');
    const content = $find(`#tab_content_${id}`, tab);
    if (content) {
      $addClass('selected', content);
      handleResizeTableHeader({ el: content });
    }
  }
}
