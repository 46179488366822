export const PARTNER = {
  NESS: 'NESS',
};

export const legacy_keyring_bp_options = [
  {
    id: 270,
    name: '볼체인 (골드)',
    price: 100,
    price_en: 0.09,
    price_jp: 10,
  },
  {
    id: 269,
    name: '볼체인 (실버)',
    price: 100,
    price_en: 0.09,
    price_jp: 10,
  },
  {
    id: 271,
    name: '볼체인 (핑크)',
    price: 100,
    price_en: 0.09,
    price_jp: 10,
  },
  {
    id: 244,
    name: '자물쇠 고리 (골드)',
    price: 500,
    price_en: 0.45,
    price_jp: 49,
  },
  {
    id: 243,
    name: '자물쇠 고리 (핑크)',
    price: 500,
    price_en: 0.45,
    price_jp: 49,
  },
  {
    id: 272,
    name: '자물쇠 고리 (실버)',
    price: 500,
    price_en: 0.45,
    price_jp: 49,
  },
  {
    id: 348,
    name: '추가안함',
    price: 0,
    price_en: 0,
    price_jp: 0,
  },
];
