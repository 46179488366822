import { $closest, $delegate, $find, $trigger, $val } from 'fxdom/es';
import { isMimeTypeCorrect } from '../../../Util/F/fs.js';
import { composite_template_bg_step } from '../S/composite_template_maker_tmpl.js';
import { delay, every, go, pick, pipe } from 'fxjs/es';
import {
  createNonTexturedImageByBG,
  selectCompositeTemplate,
  updateCompositeTemplate,
  updateDateCompositeTemplate,
} from './fs.js';
import { openCompositeMaksStepPage } from './composite_masks_step_page.js';
import { isNullBoxData } from './util.js';
import axios from 'axios';

export const composite_template_bg_step_page = 'composite_template_bg_step_page';
$.frame.defn_page({
  page_name: composite_template_bg_step_page,
  hide_frame_button_type: 'X',
  title: '합성 배경 (1/5)',
  tabs: [
    {
      tab_name: 'composite_template_bg_step_tab',
      template: composite_template_bg_step,
      appended: pipe(
        $delegate('click', '.type.content .save', async function (e) {
          $.don_loader_start();
          box.sel('assoc_composite_template').type = go(
            e.currentTarget,
            $closest('.content'),
            $find('select'),
            $val,
          );
          await delay(100);
          await updateCompositeTemplate(pick(['id', 'type'], box.sel('assoc_composite_template')));
          if (go(e.currentTarget, $closest('.bg_url.content')))
            await updateDateCompositeTemplate(box.sel('assoc_composite_template').id);
          $.don_loader_end();
        }),
        $delegate('click', '.is_cond_or .save', async function (e) {
          $.don_loader_start();
          box.sel('assoc_composite_template').is_cond_or = go(
            e.currentTarget,
            $closest('.content'),
            $find('input[name="is_cond_or"]'),
            (checkbox_el) => checkbox_el.checked,
          );
          await delay(100);
          await updateCompositeTemplate(pick(['id', 'is_cond_or'], box.sel('assoc_composite_template')));
          await updateDateCompositeTemplate(box.sel('assoc_composite_template').id);
          $.don_loader_end();
        }),
        $delegate('change', '.bg_url input[type="file"]', async function (e) {
          const ct = e.currentTarget;
          $.don_loader_start();

          if (await isMimeTypeCorrect(ct.files[0], false, true, false, false)) {
            const data = await $.upload(ct);
            box.sel('assoc_composite_template').bg_url = data.url;
            box.sel('assoc_composite_template').width = data.width;
            box.sel('assoc_composite_template').height = data.height;
            const img_wrapper = go(ct, $closest('.content'), $find('.img_wrapper'));
            img_wrapper.dataset.has_img = !!data.url;
            go(img_wrapper, $find('img'), (img) => (img.src = data.url));
            $.alert('올리신 이미지가 맞다면 저장 버튼을 눌러주세요.');
          } else {
            $.alert('지원되지 않는 파일 형식 입니다. 파일을 확인하시고 다시 시도해 주세요.');
          }
          $.don_loader_end();
        }),
        $delegate('change', '.reference_url input[type="file"]', async function (e) {
          const ct = e.currentTarget;
          $.don_loader_start();

          if (await isMimeTypeCorrect(ct.files[0], false, true, false, false)) {
            const data = await $.upload(ct);
            box.sel('assoc_composite_template').reference_url = data.url;
            const img_wrapper = go(ct, $closest('.content'), $find('.img_wrapper'));
            img_wrapper.dataset.has_img = !!data.url;
            go(img_wrapper, $find('img'), (img) => (img.src = data.url));
            $.alert('올리신 이미지가 맞다면 저장 버튼을 눌러주세요.');
          } else {
            $.alert('지원되지 않는 파일 형식 입니다. 파일을 확인하시고 다시 시도해 주세요.');
          }
          $.don_loader_end();
        }),
        $delegate('click', '.bg_url.content .upload', function (e) {
          $trigger('click', go(e.currentTarget, $closest('.content'), $find('input[type="file"]')));
        }),
        $delegate('click', '.bg_url.content .save', async function () {
          go(
            $.confirm('합성템플릿이 만들어진 후 사진 변경은 위험 할 수 있습니다.'),
            _p.if2(_p.idtt)(async function () {
              $.don_loader_start();
              await updateCompositeTemplate(
                pick(['id', 'bg_url', 'width', 'height'], box.sel('assoc_composite_template')),
              );
              $.don_loader_end();
            }),
          );
        }),
        $delegate('click', '.title.content .save', async function () {
          $.don_loader_start();
          await updateCompositeTemplate(pick(['id', 'title'], box.sel('assoc_composite_template')));
          $.don_loader_end();
        }),
        $delegate('click', '.make_composite_texture', async (e) => {
          $.don_loader_start();
          await axios.post('/@api/composite/insert_composite_textures', {
            composite_template_id: box.sel('assoc_composite_template->id'),
          });
          $.don_loader_end();
        }),
        $delegate('change', 'input[name="title"]', async function (e) {
          box.sel('assoc_composite_template').title = go(e.currentTarget, $val);
        }),
        $delegate('click', '.next', async function () {
          const composite_template = await selectCompositeTemplate(box.sel('assoc_composite_template').id);
          if (isNullBoxData(pick(['title', 'bg_url', 'type'], composite_template)))
            return $.alert('모든 정보를 저장해야 넘어갈수 있습니다.');
          if (
            box().bp.print_type === 'none_fabric' &&
            !every((cm) => cm.shade_url)(box.sel('assoc_composite_template')._.composite_masks)
          ) {
            await createNonTexturedImageByBG(pick(['id', 'bg_url'], box.sel('assoc_composite_template')));
          }
          await openCompositeMaksStepPage(box.sel('assoc_composite_template').id);
        }),
      ),
    },
  ],
});
