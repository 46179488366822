/*---cate_lists---*/
/* 기획상품 카테아이템아이디 */
export const PLAN_PRODUCT_ITEM_ID = 68;
export const CLOTH_LIST_ID = 9;
export const ACCESSORY_LIST_ID = 10;
export const HOME_DECO_LIST_ID = 11;
export const KIDS_LIST_ID = 13;
export const DIGITAL_LIST_ID = 47;
export const STICKER_LIST_ID = 84;
export const SPORTS_LIST_ID = 113;
export const PET_LIST_ID = 190;
export const PHONE_ACC_LIST_ID = 12;
/*---cate_items---*/
export const CUSHION_ITEM_ID = 51;
export const IPHONE_ITEM_ID = 55;
export const GALAXY_ITEM_ID = 56;
export const LG_ITEM_ID = 57;
export const AIR_POD_BUDS = 58;
export const KEYRING_ITEM_ID = 101;
export const SPECIFIC_STICKER_ITEM_ID = 269;
export const FREE_STICKER_ITEM_ID = 270;
export const HANDY_STICKER_ITEM_ID = 294;
export const HD_METAL_FRAME_ITEM_ID = 289;
export const GOLF_BALL_ITEM_ID = 299;
export const TATTOO_ITEM_ID = 431;
export const PHONE_CASE_ITEM_IDS = [IPHONE_ITEM_ID, GALAXY_ITEM_ID, LG_ITEM_ID];
// 가방, 파우치, 모자, 양말, 앞치마, 노트북케이스
export const STOCK_TASK_CATE_ITEM_IDS = [47, 255, 49, 259, 257, 256];
export const SHOPIFY_PRODUCT_CATEGORY_ITEM_IDS = [
  43 /* 티셔츠/셔츠 */, 44 /* 맨투맨/후디/집업 */, 59 /* 키즈 티셔츠/셔츠 */, 60 /* 키즈 맨투맨/후디/집업 */,
  51 /* 쿠션 */, 64 /* 담요 */,
];
