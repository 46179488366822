import moment from 'moment';

export const makeReturnDetailHtml = ({ data }) => {
  const {
    fare_code,
    receiver_name,
    receiver_phone,
    receiver_zipcode,
    receiver_address,
    created_at,
    shipout_from_lgl_date,
  } = data;

  return `<div class="header">
            <h1>회수 상세 정보</h1>
          </div>
          <div class="body">
            <dl>
              <dt>운임 구분</dt>
              <dd>${fare_code === '02' ? '착불' : '신용'}</dd>
              <dt>회수 요청</dt>
              <dd>${moment(created_at).format('YYYY.MM.DD HH:mm')}</dd>
              <dt>회수 발송</dt>
              <dd>${
                shipout_from_lgl_date ? moment(shipout_from_lgl_date).format('YYYY.MM.DD HH:mm') : ''
              }</dd>
              <dt>받는이</dt>
              <dd>${receiver_name}</dd>
              <dt>전화번호</dt>
              <dd>${receiver_phone}</dd>
              <dt>주소</dt>
              <dd>(${receiver_zipcode}) ${receiver_address}</dd>
            </dl>
        </div>`;
};
