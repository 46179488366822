import { legacyHtml } from '../../../Util/S/Function/util.js';
import { changeCurrentTab, makeThumbnailMigrationTestTab } from './fs.js';

export const composite_thumbnail_test_tab = {
  tab_name: 'composite_thumbnail_test_tab',
  title: '합성 썸네일 테스트',
  template: () => legacyHtml`
    <div class="option">
      <button class="more">...</button>
    </div>
    <div class="list_wrapper"></div>
  `,
  appended: async function (don_tab) {
    await makeThumbnailMigrationTestTab(don_tab, {
      is_composite: true,
      is_with_og: true,
      is_for_worker: true,
    });
  },
  showed: function (don_tab) {
    changeCurrentTab(don_tab);
  },
};
