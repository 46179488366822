import { $attr, $closest, $delegate, $findAll, $setAttr, $setVal, $val } from 'fxdom/es';
import {
  composite_faces_select_step,
  renderCompositeMaskBpfsHtml,
} from '../S/composite_template_maker_tmpl.js';
import { each, filter, find, flatMap, go, map, pick, pipe, some, sortBy } from 'fxjs/es';
import {
  insertCompositeFaces,
  updateCompositeFacesByCompositeMaskId,
  updateCompositeMask,
  updateDateCompositeTemplate,
} from './fs.js';
import { getBoxData, initializeBoxDataAssocCompositeTemplate, isNullBoxData } from './util.js';
import { openCompositeFacesStepPage } from './composite_faces_step_page.js';

export const composite_faces_select_step_page = 'composite_faces_select_step_page';
$.frame.defn_page({
  page_name: composite_faces_select_step_page,
  hide_frame_button_type: 'X',
  title: '합성면 선택 (3/5)',
  tabs: [
    {
      tab_name: 'composite_faces_select_step_tab',
      template: composite_faces_select_step,
      appended: pipe(
        $delegate('change', '.composite_mask_no', async (e) => {
          const composite_mask = getBoxData(e.currentTarget, '.composite_mask');
          const prev_no = composite_mask.no;
          if (!(await $.confirm('수정 하시겠습니까?'))) {
            go(e.currentTarget, $setVal(prev_no));
            return;
          }
          $.don_loader_start();
          const no = go(e.currentTarget, $val, parseInt);
          if (!composite_mask.id) throw new Error('composite_mask_id 없음');
          if (!Number.isInteger(no)) throw new Error('no 비정상');
          composite_mask.no = no;
          await updateCompositeMask(pick(['id', 'no'], composite_mask));
          await initializeBoxDataAssocCompositeTemplate(box.sel('assoc_composite_template').id);
          $closest('.main', e.currentTarget).outerHTML = composite_faces_select_step(
            box.sel('assoc_composite_template'),
          );
          $.don_loader_end();
        }),
        $delegate('change', '.composite_mask_is_shade_composite_ignore', async (e) => {
          const composite_mask = getBoxData(e.currentTarget, '.composite_mask');
          const prev_is_shade_composite_ignore = composite_mask.is_shade_composite_ignore;
          if (!(await $.confirm('수정 하시겠습니까?'))) {
            e.currentTarget.checked = !!prev_is_shade_composite_ignore;
            return;
          }
          $.don_loader_start();
          const is_shade_composite_ignore = e.currentTarget.checked;
          if (!composite_mask.id) throw new Error('composite_mask_id 없음');
          if (![true, false].includes(is_shade_composite_ignore))
            throw new Error('is_shade_composite_ignore 비정상');
          composite_mask.is_shade_composite_ignore = is_shade_composite_ignore;
          await updateCompositeMask(pick(['id', 'is_shade_composite_ignore'], composite_mask));
          await initializeBoxDataAssocCompositeTemplate(box.sel('assoc_composite_template').id);
          $closest('.main', e.currentTarget).outerHTML = composite_faces_select_step(
            box.sel('assoc_composite_template'),
          );
          $.don_loader_end();
        }),
        $delegate('click', '.revise', function (e) {
          go(
            $.confirm('수정하시면, 해당 제품의 모든 포지션 값이 삭제됩니다. 계속 진행하시겠습니까?'),
            async (bool) => {
              if (!bool) return;
              const composite_mask = getBoxData(e.currentTarget, '.composite_mask');
              $.don_loader_start();
              await updateCompositeFacesByCompositeMaskId({
                composite_mask_id: composite_mask.id,
                is_hidden: true,
              });
              await initializeBoxDataAssocCompositeTemplate(box.sel('assoc_composite_template').id);
              $closest('.main', e.currentTarget).outerHTML = composite_faces_select_step(
                box.sel('assoc_composite_template'),
              );
              $.don_loader_end();
            },
          );
        }),
        $delegate('change', '.base_product_faces input[type="checkbox"]', function (e) {
          const ct = e.currentTarget;
          const checkboxs = go(ct, $closest('.select_bpfs'), $findAll('[type="checkbox"]'));
          go(
            checkboxs,
            filter((el) => el.checked),
            (els) => {
              if (els.length <= 1) {
                go(checkboxs, each($setAttr({ order: null })));
                if (els.length <= 1) $setAttr({ order: 1 }, els[0]);
              } else {
                const length = els.length;
                go(
                  els,
                  find((el) => Number.isNaN(parseInt($attr('order', el)))),
                  $setAttr({ order: length }),
                );
              }
            },
          );
        }),
        $delegate('change', '.select_thumb_bpfs select', async function (e) {
          const composite_mask = getBoxData(e.currentTarget, '.composite_mask');
          const selected_base_product_face_id = await go(e.currentTarget, $val, parseInt);
          if (selected_base_product_face_id)
            composite_mask.base_product_face_id = selected_base_product_face_id;
          else composite_mask.base_product_face_id = null;
          try {
            $.don_loader_start();
            await updateCompositeMask(pick(['id', 'base_product_face_id'], composite_mask));
            $.don_loader_end();
          } catch (e) {
            $.alert('Error');
            $.don_loader_end();
            console.error(e);
          }
        }),
        $delegate('click', '.select_bpfs .apply', async function (e) {
          _p.go(
            $.confirm('저장 후 변경이 불가합니다.'),
            _p.if2(_p.idtt)(async function () {
              const composite_mask = getBoxData(e.currentTarget, '.composite_mask');
              $.don_loader_start();

              const composite_faces = await go(
                e.currentTarget,
                $closest('.select_bpfs'),
                $findAll('[type="checkbox"]'),
                filter((el) => el.checked),
                sortBy(pipe($attr('order'), parseInt)),
                map(
                  pipe($val, parseInt, async (base_product_face_id) => {
                    // const data = await $.uploadFileToUrl(design_mockup_url, 'design_mockup');
                    return {
                      base_product_face_id,
                      composite_mask_id: composite_mask.id,
                      // mockup_url: data.url
                    };
                  }),
                ),
              );
              /**/
              await insertCompositeFaces(composite_faces);
              await updateDateCompositeTemplate(box.sel('assoc_composite_template').id);
              await initializeBoxDataAssocCompositeTemplate(box.sel('assoc_composite_template').id);
              $.don_loader_end();
              const _composite_mask_el = go(e.currentTarget, $closest('.composite_mask'));
              const _composite_mask = box.sel(_composite_mask_el);
              renderCompositeMaskBpfsHtml(
                e.currentTarget,
                _composite_mask,
                box.sel('assoc_composite_template'),
              );
            }),
          );

          // renderCompositeMaskHtml(e.currentTarget);
        }),
        $delegate('click', '.next', async function (e) {
          const datas = go(
            box.sel('assoc_composite_template->_->composite_masks'),
            flatMap((composite_mask) => go(composite_mask._.composite_faces, map(pick(['id'])))),
          );
          if (datas.length === 0 || some(isNullBoxData, datas))
            return $.alert('모든 정보를 저장해야 넘어갈수 있습니다.');
          openCompositeFacesStepPage(box.sel('assoc_composite_template').id);
        }),
      ),
    },
  ],
});

export function openCompositeFacesSelectStepPage(composite_template_id) {
  $.frame.add_page({
    page_name: composite_faces_select_step_page,
    tabs: [
      {
        tab_name: 'composite_faces_select_step_tab',
        data_func: async () => {
          await initializeBoxDataAssocCompositeTemplate(composite_template_id);
          return box.sel('assoc_composite_template');
        },
      },
    ],
  });
}
