import { extend } from 'fxjs/es';

export function shadeObj(obj) {
  return extend(
    {
      texture_url: null,
      light_location: { top: -1, left: -1 },
      lighten_url: null,
      hex: '#cccccc',
      darken_hex: '#1c1c1c',
      deep_step: 0,
      lighten_hex: '#ffffff',
    },
    obj,
  );
}
