import { DfInhouseF } from './module/DfInhouseF.js';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';
import { getUserProductsFromProjection } from '../../../Waybill/F/Function/waybill_cj_go.js';

// @description 주문 박스 라벨에 들어갈 데이터 정제
export async function generateProjectionBoxLabelData({ projection_id }) {
  /* 라벨 생성을 위한 데이터 요청 */
  const { customer_order_crew_inhouse, crew_authorization_user_profile_company_name } =
    await DfInhouseF.apiCalls.get.customerOrderCrewInhouseFromPrjId({
      projection_id,
    });

  /* 1. 가드 - 라벨 데이터 요청 실패 시 */
  if (UtilObjS.isEmNil(customer_order_crew_inhouse)) {
    throw new Error(`사내 배송 라벨 생성을 위한 데이터를 가져올 수 없습니다.`);
  }

  // employee_email
  const {
    employee_name,
    employee_no,
    is_hidden,
    _: { crew_inhouse_shipping },
  } = customer_order_crew_inhouse;

  const { location_name, company_label, company_address } = crew_inhouse_shipping;

  /* 2. 가드 - 사내 배송 주문 -> 사내로 안 보내고 개인 배송 건일 때 */
  if (is_hidden === true) {
    throw new Error(`사내 배송 스토어에서 주문하였으나<br>개인 배송으로 수령하는 건 입니다.`);
  }

  /* 3. 갸드 - 사내 배송지 위치 파악이 안 될 때 */
  if (UtilObjS.isEmNil(crew_inhouse_shipping)) {
    throw new Error(`사내 배송지 정보를 가져올 수 없습니다.<br>사내 배송지를 지정해 주세요.`);
  }

  return {
    label_data: {
      employee: `${crew_authorization_user_profile_company_name} - ${employee_name} - ${employee_no}`,
      company_label,
      location_name,
      projection_id,
      barcode_projection_id: projection_id,
      qrcode: `#${projection_id}`,
      address: `${company_address}`,
      up_name_list: await getUserProductsFromProjection(projection_id),
    },
    customer_order_crew_inhouse,
  };
}
