import { html } from 'fxjs/es';
import moment from 'moment';
import { OMPCoreUtilS } from '../../../Core/Util/S/Function/module/OMPCoreUtilS.js';

export const cartPrintingFileTmpl = ({ pdf_printing_file = {}, label = false }) => {
  const { file_name, pdf_url, created_at, expired_at } = pdf_printing_file;
  const is_expired = OMPCoreUtilS.isExpired({ expired_at });
  const [file_title] = (file_name || '').split('.pdf');

  return html` ${label ? html`<span class="key">${TT('cart::cart_28')}</span>` : ''}
    <div class="omp-cart__product-file-wrapper">
      <div
        class="omp-cart__product-file-name ${is_expired ? 'disabled' : ''}"
        data-file-url="${is_expired ? '' : pdf_url}"
        data-file-name="${file_name}"
      >
        <span class="omp-cart__product-file-title">${file_title}</span
        ><span class="omp-cart__product-file-mimetype">.pdf</span>
      </div>
      <div class="omp-cart__product-file-date">
        ${is_expired ? `${TT('pdf::expired::2')}` : moment(created_at).format('YYYY-MM-DD HH:mm')}
      </div>
    </div>`;
};
