import { page as root_page } from './page.js';
const mui_ref_path = '/modules/SVGEditor/MobileTextInput/S/Mui/frame.js';
/*
 * 서버와 프론트에서 사용될 공통 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#0ed8bb87d5ff4363b7a1ba2ba4b7b0d9
 */
export const frame = {
  frame_name: mui_ref_path, // frame 고유이름 - 필수
  frame_tag: '', // frame 태그 구분자
  title: '', // frame header의 title
  el_id: '', // frame element id 추가
  el_class: '', // frame element class 추가
  header_height: undefined, // frame의 헤더 높이
  hide_frame_button_type: '', // frame 닫는 버튼 구분자 (''는 버튼이 나오지않음, 'V', 'v', 'X', '그외 아무 문자')
  attrs: {}, // frame 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  custom_header(frame) {}, //frame header 커스텀 html 을 만드는 함수

  /*
   * root_page 는 없으면 에러. 기본은 같이 만들어진 page 입니다.
   * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#a41af56cf6d94620848e34a54088dd50
   * */
  root_page, // /S/page 옵션객체 - 필수
};
