import { $closest, $val } from 'fxdom/es';
import { html, strMap, go } from 'fxjs/es';

!(function () {
  const t_projection_task = (task) => {
    return pug`
    .task[task_id="${task.id}" data-task_parent_id="${task.parent_id}" _sel="./(#${
      task.id
    })" has_issue='${!!_p.v(task._, 'issues.length')}' is_hidden="${task.is_hidden}" is_canceled="${
      task.is_canceled
    }" status="${task.status}" href="/task/detail/${task.id}"]
      .title.head ${(task.is_hidden ? '[삭제]' : '') + task.title}
      .manager ${_p.v(task, '_.worker.name') || '담당자 없음'}
      `;
  };
  function renderSelect(type, target) {
    return type === target ? 'selected' : '';
  }
  function render_task_set_create_page(don_tab_el) {
    $.don_loader_start();
    const don_wrapper = $.find1(don_tab_el, '>.don_wrapper');
    return _p.go(
      box.sel('task_set'),
      async function (task_set) {
        const [
          original_before_print_tasks,
          original_printing_tasks,
          original_before_deliver_tasks,
          original_as_tasks,
        ] = await Promise.all([
          $.get('/@api/tasks/get_original_tasks', {
            type: 'before_print',
          }),
          $.get('/@api/tasks/get_original_tasks', { type: 'printing' }),
          $.get('/@api/tasks/get_original_tasks', {
            type: 'before_deliver',
          }),
          $.get('/@api/tasks/get_original_tasks', { type: 'as' }),
        ]);
        return pug`
          .head
            .close 닫기
            .title 태스크 세트 상세정보 - #${task_set && task_set.id}<span id="task_save_state"></span>
            .delete[type="is_hidden"] 삭제
          .task_set_body.task_set.df_grid[task_set_id="${task_set && task_set.id}"]
            .title.content.text_content[type="title"]
              label 태스크 세트 제목
              input.input[type="text" value="${
                task_set.title || ''
              }" placeholder="태스크 세트 제목을 입력하시오"]
              .option
                button.save[type="button"] 저장
            .body.content.text_content[type="body"]
              label 태스크 세트 내용
              textarea.input[placeholder="태스크 세트 내용을 입력하시오"] ${task_set.body || ''}
              .option
                button.save[type="button"] 저장
            .type.content[type="type"]
              label 태스크 세트 타입
              select 
                option[value=""] 없음
                option[value="before_print" ${renderSelect(task_set.type, 'before_print')}] 제작준비중
                option[value="printing" ${renderSelect(task_set.type, 'printing')}] 제작
                option[value="before_deliver" ${renderSelect(task_set.type, 'before_deliver')}] 출고
                option[value="as" ${renderSelect(task_set.type, 'as')}] AS/기타
            .default.content[type="is_auto"]
              label 결제 완료시 자동 추가
              input[type="checkbox" ${task_set.is_auto ? 'checked' : ''}]
            ${_p.go(
              task_set._.tasks,
              _p.group_by((task) => task.type),
              (tasks) => pug`
            .table.task_items
              .col.type[type='before_print']
                .cell.head
                  label 제작준비중
                  .btn.add[style=${!box.sel('is_user->_->policies->mp_worker_policy') ? 'display:none;' : ''}]
                  select[name="original_task_id"]
                    option[value=""] 태스크 선택
                    ${strMap(
                      (original_task) => html`
                        <option value="${original_task.id}">
                          ${original_task.title}-${original_task._.worker.name}
                        </option>
                      `,
                    )(original_before_print_tasks)}
                .cell.body
                  ${_p.sum(tasks.before_print || [], t_projection_task)}
              .col.type[type='printing']
                .cell.head
                  label 제작
                  .btn.add[style=${!box.sel('is_user->_->policies->mp_worker_policy') ? 'display:none;' : ''}]
                  select[name="original_task_id"]
                    option[value=""] 태스크 선택
                    ${strMap(
                      (original_task) => html`
                        <option value="${original_task.id}">
                          ${original_task.title}-${original_task._.worker.name}
                        </option>
                      `,
                    )(original_printing_tasks)}
                .cell.body
                  ${_p.sum(
                    _p.go(
                      tasks.printing || [],
                      _p.sort_by((task) => task.title == '제품확인'),
                    ),
                    t_projection_task,
                  )}
              .col.type[type='before_deliver']
                .cell.head
                  label 출고
                  .btn.add[style=${!box.sel('is_user->_->policies->mp_worker_policy') ? 'display:none;' : ''}]
                  select[name="original_task_id"]
                    option[value=""] 태스크 선택
                    ${strMap(
                      (original_task) => html`
                        <option value="${original_task.id}">
                          ${original_task.title}-${original_task._.worker.name}
                        </option>
                      `,
                    )(original_before_deliver_tasks)}
                .cell.body
                  ${_p.sum(tasks.before_deliver || [], t_projection_task)}
              .col.type[type='as']
                .cell.head
                  label AS/기타
                  .btn.add[style=${!box.sel('is_user->_->policies->mp_worker_policy') ? 'display:none;' : ''}]
                  select[name="original_task_id"]
                    option[value=""] 태스크 선택
                    ${strMap(
                      (original_task) => html`
                        <option value="${original_task.id}">
                          ${original_task.title}-${original_task._.worker.name}
                        </option>
                      `,
                    )(original_as_tasks)}
                .cell.body
                  ${_p.sum(tasks.as || [], t_projection_task)}
          `,
            )}
        `;
      },
      $.html_to(don_wrapper),
    );
  }

  $.frame.defn_frame({
    frame_name: 'df.task.task_set.create',
    page_name: 'task.task_set.create',
    always_remove: true,
    appending: function () {
      $.don_loader_start();
    },
  });

  $.frame.defn_page({
    page_name: 'task.task_set.create',
    tabs: [
      {
        tab_name: 'task.task_set.create',
        tab_title: '태스크 만들기',
        template: () => pug`
      `,
        showed: __(_p.tap(set_task_set_box), render_task_set_create_page, function () {
          $.don_loader_end();
        }),
        appended: __(
          G.df.task.projection.task_set_tasks_event_init,
          $.on('change', '.don_wrapper select[name="original_task_id"]', async function (e) {
            $.don_loader_start();
            const task_set_id = box.sel('task_set').id;
            const original_task_set_id = e.currentTarget.value;
            const ct = $closest('.don_tab', e.currentTarget);
            if (original_task_set_id) {
              await $.post('/@api/tasks/create_task_from_original_task', {
                id: original_task_set_id,
                task_set_id,
              });
              await go(set_task_set_box(), () => render_task_set_create_page(ct));
            }
            $.don_loader_end();
          }),
          $.on('click', '.don_wrapper >.head .close', function () {
            $.frame.close();
          }),
          $.on('click', '.don_wrapper >.head .delete', function () {
            _p.go(
              $.confirm('태스크 세트를 삭제하시겠습니까?'),
              _p.if2(_p.idtt)(
                () => box.extend2('task_set', { is_hidden: true }),
                () => task_box_to_server(['is_hidden']),
                () => $.frame.close(),
              ),
            );
          }),
          $.on(
            'click',
            '.text_content .save',
            G.df.task.detail_saving(function (e) {
              return _p.go(
                e.currentTarget,
                $.closest('.content'),
                _p.add_arg($.find1('.input'), $.val),
                (content, val) => {
                  const type = $.attr(content, 'type');
                  box.extend2('task_set', { [type]: val });
                  return task_box_to_server([type]);
                },
              );
            }),
          ),
          $.on(
            'change',
            '.content[type="type"] select',
            G.df.task.detail_saving(function (e) {
              return _p.go(e.currentTarget, $val, (val) => {
                box.sel('task_set').type = val === '' ? null : val;
                return task_box_to_server('type');
              });
            }),
          ),
        ),
      },
    ],
  });

  function set_task_set_box() {
    return _p.go($.get(`/@api/task/task_set/detail`, { id: box.sel('task_set').id }), (task) =>
      box.set('task_set', task),
    );
  }

  function render_task_set_list(el) {
    return _p.go(
      $.get('/@api/task/task_set/list'),
      (tasks) => box.set('task_set_list', tasks),
      () => box.sel('task_set_list'),
      function (task_set_list) {
        return _p.sum(
          task_set_list,
          (task_set) => pug`
          .task_set[_sel="task_set_list->(#${task_set.id})" task_set_id="${task_set.id}" is_auto="${
            task_set.is_auto
          }"]
            .title 
              .head 제목
              .body ${task_set.title}
            .body
              .head 내용
              .body ${task_set.body || '-'}
            ${G.df.task.t_prj_tasks(_p.v(task_set, '_.tasks'))}
            .option
              button.copy[type="button"] id 복사
              button.modify[type="button"] 수정
        `,
        );
      },
      $.html_to($.find1(el, '.task_set_list')),
    );
  }

  function task_box_to_server(keys) {
    return _p.go(box.sel('task_set'), _p.pick(['id'].concat(keys)), _p($.post, '/@api/task/task_set/modify'));
  }

  $.frame.defn_frame({
    frame_name: 'df.task.task_set.list',
    page_name: 'task.task_set.list',
  });

  $.frame.defn_page({
    page_name: 'task.task_set.list',
    tabs: [
      {
        tab_name: 'tasks.task_set.list',
        template: () => pug`
        .option
          .create_task_set
            .btn_add 태스크 세트 생성
        .task_set_list
      `,
        showed: __(
          _p.tap(function () {
            $.don_loader_start();
          }),
          (don_tab_el) => render_task_set_list(don_tab_el),
          function () {
            $.don_loader_end();
          },
        ),
        appended: __(
          $.on(
            'click',
            '.task_items .task',
            __(_p.v('currentTarget'), (el) => {
              $.alert('작동하지 않습니다.');
            }),
          ),
          $.on2('click', '.create_task_set .btn_add', function (e) {
            return _p.go(
              $.post('/@api/task/task_set/create/'),
              function (task_set) {
                box.set('task_set', task_set);
              },
              function () {
                $.frame.open({
                  frame_name: 'df.task.task_set.create',
                });
              },
            );
          }),
          $.on('click', '.copy', (e) => {
            _p.go(e.currentTarget, $.closest('.task_set'), box.sel, (task_set) => {
              G.copy_clipboard(task_set.id);
            });
          }),
          $.on('click', '.modify', function (e) {
            $.don_loader_start();
            _p.go(
              e.currentTarget,
              $.closest('.task_set'),
              box.sel,
              (task_set) => box.set('task_set', task_set),
              function () {
                $.frame.open({
                  frame_name: 'df.task.task_set.create',
                });
              },
            );
          }),
        ),
      },
    ],
  });
})();
