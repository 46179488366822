import cart from './mp/js/cart.js';
import wish from './mp/js/wish.js';
import checkout from './mp/js/checkout.js';
import checkout_kr from './mp/js/checkout.kr.js';
import guideline from './mp/js/guideline.js';
import high_quality from './mp/js/high_quality.js';
import order_detail from './mp/js/order.detail.js';
import order from './mp/js/order.js';
import print_receipt from './mp/js/print_receipt.js';
import product_detail from './mp/js/product.detail.js';
import receipt from './mp/js/receipt.js';
import verify from './mp/js/verify.js';
/*
 * ./base.js 에서 자동으로 frame을 실행해주는 부분이 있습니다.
 * 사용되지 않더라도 ../../modules/Event/List/F/route.js 처럼 frame을 등록시키는 모듈을 가져와주세요
 * */
import '../../modules/Event/List/F/route.js';
import '../../modules/Event/Detail/F/route.js';
import '../../modules/User/Point/F/route.js';

import '../../modules/Terms/Detail/F/index.js';
import '../../modules/User/Withdrawal/F/index.js';
import '../../modules/Help/List/F/route.js';
import '../../modules/AboutUs/Detail/F/route.js';
import '../../modules/Error/404/F/route.js';

import {
  defnMyReviewListRoute,
  defnMyReviewRoute,
  defnReviewListRoute,
} from '../../modules/Review/List/F/route.js';
import { defnProductListRoute } from '../../modules/Product/List/F/route.js';
import { defnFooterEvent } from '../../modules/Footer/Detail/F/event.js';
import { defnOldMessageListRoute } from '../../modules/OldMessage/List/F/route.js';
import { defnUserLoginRoute } from '../../modules/User/Login/F/route.js';

defnFooterEvent();
Routes.mp = {
  cart,
  checkout,
  checkout_kr,
  guideline,
  high_quality,
  my_review: defnMyReviewRoute,
  my_review_list: defnMyReviewListRoute,
  order_detail,
  order,
  print_receipt,
  product_detail,
  product_list: defnProductListRoute,
  receipt,
  review_list: defnReviewListRoute,
  user: defnUserLoginRoute,
  verify,
  wish,
  old_message: defnOldMessageListRoute,
};
