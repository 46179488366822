import { filter, find, flatMap, go } from 'fxjs/es';
import { BpOptionConstantS } from '../Constant/module/BpOptionConstantS.js';

export const selectFittedSizeBpOption = ({ base_products_bp_option_groups, selected_option_group }) => {
  return go(
    base_products_bp_option_groups,
    filter((bpbog) =>
      BpOptionConstantS.FITTED_SIZE_PRICE_STYLE_BP_OPTION_GROUP_IDS.includes(bpbog._.bp_option_group.id),
    ),
    flatMap((bpbog) => bpbog._.bp_option_group._.bp_options),
    find((bp_option) => selected_option_group.bp_option_ids.includes(bp_option.id)),
  );
};
