import { each, go } from 'fxjs/es';
import { $findAll } from 'fxdom/es';

export const showTypeNoneFooters = (tab_el) =>
  go(
    tab_el,
    $findAll(`.footer`),
    each((el) => {
      if (el.classList.contains('none')) {
        el.dataset.is_show = 'true';
        return;
      }
      el.dataset.is_show = 'false';
    }),
  );

export const showTypeSelectFooters = (tab_el) =>
  go(
    tab_el,
    $findAll(`.footer`),
    each((el) => {
      if (el.classList.contains('select')) {
        el.dataset.is_show = 'true';
        return;
      }
      el.dataset.is_show = 'false';
    }),
  );

export const hideAllFooters = (tab_el) =>
  go(
    tab_el,
    $findAll(`.footer`),
    each((el) => (el.dataset.is_show = 'false')),
  );

export const hide2TypeNoneMakeStickerFooter = (tab_el) =>
  go(
    tab_el,
    $findAll(`.footer.none[data-icon_type="make_sticker"]`),
    each((el) => {
      el.dataset.is_show2 = 'false';
    }),
  );

export const show2TypeNoneMakeStickerFooter = (tab_el) =>
  go(
    tab_el,
    $findAll(`.footer.none[data-icon_type="make_sticker"]`),
    each((el) => {
      el.dataset.is_show2 = 'true';
    }),
  );
