import { $addClass, $el, $find, $hasClass, $parent, $qs, $removeClass, $children, $setAttr } from 'fxdom/es';
import { eachL, go, head, mapL, takeAll } from 'fxjs/es';
import { DfInhouseConstantS } from '../../S/Constant/module/DfInhouseConstantS.js';
import { DfInhouseF } from './module/DfInhouseF.js';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { DfInhouseTmplS } from '../../S/Tmpl/module/DfInhouseTmplS.js';

export const stateDOM = {
  get: {
    searchBucketByPeriod: () => {
      const search_btn_el = $qs(`button[name=${DfInhouseConstantS.SEARCH_BUTTON_NAME.date_range}]`);
      if (search_btn_el == null) throw new Error(`버킷 기간 조회 버튼 요소를 찾을 수 없습니다.`);
      return search_btn_el;
    },
    section: (section_name) => {
      const section_el = $qs(`section.${section_name}`);
      if (section_el == null) throw new Error(`Section 요소가 존재하지 않습니다.`);
      return section_el;
    },
    bucketEntityEl: ({ bucket_id }) => {
      return $qs(`.bucket-entity[_id="${bucket_id}"]`);
    },
    bucketBoxCounterEl: ({ bucket_id }) => {
      const bucket_el = DfInhouseF.stateDOM.get.bucketEntityEl({ bucket_id });
      if (bucket_el == null)
        throw new Error(`해당 bucket_id ${bucket_id} 를 가진 버킷 요소를 찾을 수 없습니다.`);

      const bucket_counter_el = $find('.description .box-counter', bucket_el);
      if (bucket_counter_el == null)
        throw new Error(`bucket_id ${bucket_id}의 Bucket counter 요소를 찾을 수 없습니다.`);
      return bucket_counter_el;
    },
    isSelectedAgain: (el) => {
      if (el == null) return;
      return $hasClass('selected', el);
    },
    boxTable: (is_el = true) => {
      const table_el = $qs(`#${DfInhouseConstantS.TABLE.ID}`);
      if (table_el == null) throw new Error(`Table 요소가 존재하지 않습니다.`);
      return is_el ? table_el : table_el[DfInhouseConstantS.TABLE.KEY];
    },
    bucketMode: () => {
      const mode_el = $qs('.bucket-control .item.mode button.selected');
      if (mode_el == null) throw new Error(`선택된 버킷 모드가 없습니다.`);
      return mode_el.name;
    },
    selectedBucket: (is_gbox) => {
      const bucket_selected_el = $qs(`.bucket-entity.selected`);
      if (bucket_selected_el == null) return null;
      return is_gbox ? box.sel(bucket_selected_el) : bucket_selected_el;
    },
    selectedBucketBoxes: (is_gbox) => {
      const selected_bucket = DfInhouseF.stateDOM.get.selectedBucket();
      if (selected_bucket == null) return [];

      const boxes_el = $find('.boxes', selected_bucket);
      if (boxes_el == null) return [];
      return is_gbox ? box.sel(boxes_el) : boxes_el;
    },
    bucketDashboard: () => {
      const dashboard_el = $qs(`section.buckets .dashboard`);
      if (dashboard_el == null) throw new Error('Bucket entity 대시보드 요소가 존재하지 않습니다.');
      return dashboard_el;
    },
  },
  insert: {
    inhouse_shipping_buckets: ({ buckets }) => {
      const dashboard_el = DfInhouseF.stateDOM.get.bucketDashboard();

      DfInhouseF.stateDOM.update.deselectCurrentSelectedBucket();
      go(
        buckets,
        zipWithIndex,
        mapL(([idx, bucket]) =>
          DfInhouseTmplS.makeBucketEntityHtml({ bucket, selected: idx === buckets.length - 1 }),
        ),
        eachL((bucket_html) => dashboard_el.prepend($el(bucket_html))),
        takeAll,
      );
    },
  },
  update: {
    toggleSelected: (el) => {
      if (el == null) return { is_toggled: false };
      const parent_el = $parent(el);
      const current_selected_el = $find('.selected', parent_el);

      if (current_selected_el === el) {
        return { is_toggled: false };
      } else {
        $removeClass('selected', current_selected_el);
        $addClass('selected', el);
        return { is_toggled: true };
      }
    },
    toggleBucketModeButton: ({ mode_name }) => {
      const mode_el = $qs(`.bucket-control .item.mode button[name="${mode_name}"]`);
      if (mode_el == null) throw new Error(`존재하지 않는 버킷 모드 입니다. ${mode_name}`);

      const is_selected_again = DfInhouseF.stateDOM.get.isSelectedAgain(mode_el);

      if (!is_selected_again) {
        DfInhouseF.stateDOM.update.toggleSelected(mode_el);
      }
    },
    bucketImageStatus: ({ bucket_id, is_packed, box_ctn }) => {
      const bucket_el = DfInhouseF.stateDOM.get.bucketEntityEl({ bucket_id });
      if (bucket_el == null)
        throw new Error(`해당 bucket_id ${bucket_id} 를 가진 버킷 요소를 찾을 수 없습니다.`);

      const bucket_image_el = $find('.bucket-image', bucket_el);
      if (bucket_image_el == null)
        throw new Error(`bucket_id ${bucket_id}의 Bucket image 요소를 찾을 수 없습니다.`);

      $setAttr(['status', DfInhouseTmplS.getBucketStatusClassName({ is_packed, box_ctn })], bucket_image_el);
    },
    selectFirst: (el) => {
      if (el == null) return;
      const first_el = go(el, $parent, $children, head);
      first_el && DfInhouseF.stateDOM.update.toggleSelected(first_el);
    },
    deselect: (el) => {
      if (el == null) {
        go(el, $parent, $find('.selected'), $removeClass('selected'));
      }
      $hasClass('selected', el) && $removeClass('selected', el);
    },
    uptoDateBoxCounter: ({ target_bucket_id }) => {
      const bucket_box_counter_el = DfInhouseF.stateDOM.get.bucketBoxCounterEl({
        bucket_id: target_bucket_id,
      });
      const boxes = DfInhouseF.stateBox.get.boxesFromBucketId({ bucket_id: target_bucket_id });
      bucket_box_counter_el.textContent = DfInhouseTmplS.getBoxCtnDescription({ box_ctn: boxes.length });
    },
    deselectCurrentSelectedBucket: () => {
      const bucket_selected = DfInhouseF.stateDOM.get.selectedBucket();

      bucket_selected && $removeClass('selected', bucket_selected);
    },

    // @description 기존 버킷 entity 의 내용을 새로운 DOM 으로 교체
    bucketEntity: ({ bucket, is_hydrating }) => {
      const exist_bucket_el = DfInhouseF.stateDOM.get.bucketEntityEl({ bucket_id: bucket.id });
      if (exist_bucket_el == null) return;

      const loader = $find('.bucket-loader', exist_bucket_el);

      const new_bucket_el = $el(
        DfInhouseTmplS.makeBucketEntityHtml({
          bucket,
          is_hydrating,
          selected: $hasClass('selected', exist_bucket_el),
        }),
      );

      if (loader) {
        new_bucket_el.prepend(loader);
      }

      exist_bucket_el.replaceWith(new_bucket_el);
    },

    bucketEntities: async ({ buckets, selected_bucket_id }) => {
      // 기존 dashboard DOM 삭제
      DfInhouseF.stateDOM.delete.bucketEntities();
      const bucket_section_el = DfInhouseF.stateDOM.get.section('buckets');

      // 신규 dashboard DOM 추가
      bucket_section_el.prepend($el(DfInhouseTmplS.makeBucketEntitiesHtml({ buckets, selected_bucket_id })));
      DfInhouseF.stateBucket.scrollTo({ bucket_id: selected_bucket_id });

      // table 교체
      await DfInhouseF.boxTable.replaceRowsToSelectedBucket();
    },
  },
  delete: {
    bucketEntities: () => {
      const bucket_dashboard_el = DfInhouseF.stateDOM.get.bucketDashboard();
      bucket_dashboard_el.remove();
    },
    inhouse_shipping_bucket: async ({ bucket_id }) => {
      const bucket_el = $qs(`.bucket-entity[_id="${bucket_id}"]`);

      if ($hasClass('selected', bucket_el)) {
        const next_el = bucket_el.nextElementSibling;
        const prev_el = bucket_el.previousElementSibling;

        const toggle_selected_target = next_el ?? prev_el;

        /* 지워진 버킷의 바로 이전 혹은 이후 버킷 선택으로 자동 변경 */
        if (toggle_selected_target) {
          DfInhouseF.stateDOM.update.toggleSelected(toggle_selected_target);

          /* 선택 토클 후에는 테이블 rows 를 선택된 bucket 으로 replace */
          await DfInhouseF.boxTable.replaceRowsToSelectedBucket();
        }
      }

      bucket_el.remove();
    },
  },
};
