import { pipe, sel } from 'fxjs/es';
import '../../../../www/module/front/app/mp/mp.checkout.js';
import { MShopUtilF } from '../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { checkPassword } from '../../Login/S/fs.js';

export const eventResetPassword = pipe(
  $.on('keyup', 'input[name="password"]', function (e) {
    if (e.keyCode != 13) return;
    $.trigger($.find1(e.delegateTarget, '.btn_change_password'), 'click');
  }),
  $.on('click', '.btn_change_password', function (e) {
    const input_pw = $.find1(e.delegateTarget, 'input[name="password"]');

    const { msg, result } = checkPassword($.val(input_pw));

    if (!result) {
      _p.go(msg, $.alert, function () {
        input_pw.focus();
      });
      return;
    }

    _p.go(
      $.post('/@api/mp/change_password', {
        password: $.val($.find1(e.delegateTarget, 'input[name="password"]')),
        certification_txt: $.val($.find1(e.delegateTarget, 'input[name="certification_txt"]')),
      }),
      function (data) {
        if (data.result) {
          _p.go(T('login::Success change password'), $.alert, function () {
            const lo = G.$location();

            if (MShopUtilF.isCreatorApp()) {
              return MShopUtilF.postMessage({
                resetNavigate: {
                  sel: 'TabNavigation.HomeStack.Home',
                  params: {
                    uri: `/${T.lang}`,
                  },
                },
              });
            }

            window.location.href = lo.query.url ? `${lo.query.url}` : '/';
          });
        } else {
          $.alert(T('login::Failed please retry'));
        }
      },
    );
  }),
);

export const eventVerifyEmail = pipe(
  $.on('click', '.btn_back', function () {
    if (MShopUtilF.isApp()) {
      MShopUtilF.postMessage({
        goBack: true,
      });
    } else {
      window.history.back();
    }
  }),
  $.on2('click', '.btn_change_email', function (e) {
    return _p.go($.get('/@api/get/user'), function (data) {
      if (!data.result)
        return $.alert(T('my_info::There is no user information. Please log in again after login.'));

      $.frame.open(
        {
          frame_name: 'mp.checkout.change_email',
          closed: function (X, data) {
            if (!data || !data.result) return;
            window.location.reload();
          },
        },
        {
          tabs: [
            {
              page_name: 'mp.checkout.change_email',
              data_func: function () {
                return data.user;
              },
            },
          ],
        },
      );
    });
  }),
  $.on('click', '.btn_resend_verify_email', function (e) {
    const lo = G.$location();
    _p.go($.post('/@api/mp/resend_verification', { url: sel('query.url', lo) }), function (data) {
      if (data.result) {
        $.alert(T('login::Check your email.'));
      } else {
        $.alert(T('login::failed please retry'));
      }
    });
  }),
);
