import { $qs } from 'fxdom/es';
import { go, tap } from 'fxjs/es';
import { createCanvasElement, makeCanvasByUrl, makeGradientCanvas } from '../../../../../../Canvas/S/util.js';
import { auroraAcril, makeShadowAndGeometryCanvas, masking, white } from './composite.js';
import { trimCanvasSize } from '../../../../../../Maker/F/canvas_trim.js';

export async function svgdataurlToCanvas({ svg_data_url, width, height }) {
  const svg_doc = new DOMParser().parseFromString(svg_data_url, 'image/svg+xml');
  const svg_el = svg_doc.querySelector('svg');
  svg_el.setAttributeNS(null, 'width', width);
  svg_el.setAttributeNS(null, 'height', height);
  go($qs('path', svg_el), (path_el) => {
    path_el.setAttribute('style', '');
    path_el.setAttributeNS(null, 'fill', `#ffffff`);
    path_el.setAttributeNS(null, 'stroke-width', 0);
  });
  const svg_str2 = new XMLSerializer().serializeToString(svg_el);

  const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
    const blob = new Blob([svg_str2], { type: 'image/svg+xml' });
    const file_reader = new FileReader();
    file_reader.addEventListener('error', reject);
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.readAsDataURL(blob);
  });
  const img = new Image();
  img.src = data_url;

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const canvas = createCanvasElement(img);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      resolve(canvas);
    };
    img.onerror = reject;
  });
}

export async function svgDataUrlToWhiteCanvasUrl({ svg_data_url, width, height }) {
  const svg_doc = new DOMParser().parseFromString(svg_data_url, 'image/svg+xml');
  const svg_el = svg_doc.querySelector('svg');
  svg_el.setAttributeNS(null, 'width', width);
  svg_el.setAttributeNS(null, 'height', height);
  go($qs('path', svg_el), (path_el) => {
    path_el.setAttributeNS(null, 'stroke', `#ffffff`);
    path_el.setAttributeNS(null, 'stroke-width', 1);
  });
  const svg_str2 = new XMLSerializer().serializeToString(svg_el);

  const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
    const blob = new Blob([svg_str2], { type: 'image/svg+xml' });
    const file_reader = new FileReader();
    file_reader.addEventListener('error', reject);
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.readAsDataURL(blob);
  });
  const img = new Image();
  img.src = data_url;

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const canvas = createCanvasElement(img);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
    img.onerror = reject;
  });
}

export async function addFImage(url, option) {
  return new Promise((resolve) => {
    fabric.Image.fromURL(url, (cv_img) => {
      cv_img.set(option);
      resolve(cv_img);
    });
  });
}
/*구멍 뚫기*/
export async function func2(c, { x, y }, ratio) {
  const fcanvas = new fabric.StaticCanvas(document.createElement('canvas'), {
    width: c.width,
    height: c.height,
    enableRetinaScaling: false,
  });
  const f_image = await addFImage(c.toDataURL(), { top: 0, left: 0, width: c.width, height: c.height });
  fcanvas.add(f_image);
  const r = 1.5 * (300 / 25.4) * ratio;
  const top = c.height * y;
  const left = c.width * x;
  const circle = new fabric.Circle({
    top: top - r,
    left: left - r,
    radius: r,
    fill: 'green',
    globalCompositeOperation: 'destination-out',
  });
  fcanvas.add(circle);
  return fcanvas.toDataURL();
}

/*각도 구하기*/
export function getAngle({ x1, y1 }, { x2, y2 }) {
  const x = x2 - x1;
  const y = y2 - y1;
  const r = Math.atan2(y, x);
  let degree = r * (180 / Math.PI);
  if (degree < 0) {
    degree = 180 + 180 + degree;
  }
  const theta_thres = 3;
  return Math.abs(degree - 270) < theta_thres ? 270 : degree;
}
export function getR({ x1, y1 }, { x2, y2 }) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

// const angle = func3({ x1, y1 }, { x2, y2 });
// const point = selected.getCenterPoint();
// angle = (angle || 0) + e.angle;
// selected.set('angle', Math.round(ang(angle))).setCoords();
// selected.setPositionByOrigin(point, 'center', 'center');

/*최소 가능한 크기의 캔버스와 각도 회전된 url 리턴, 회전후 고리 x,y 리턴*/
export async function func4(c, { x, y }) {
  const max_width = Math.sqrt(Math.pow(c.width, 2) + Math.pow(c.height, 2));
  const fcanvas = new fabric.StaticCanvas(document.createElement('canvas'), {
    width: max_width,
    height: max_width,
    enableRetinaScaling: false,
  });
  const fimg = await addFImage(c.toDataURL(), {
    width: c.width,
    height: c.height,
    top: (max_width - c.height) / 2,
    left: (max_width - c.width) / 2,
  });
  fcanvas.add(fimg);

  const point = fimg.getCenterPoint();
  const angle = getAngle({ x1: c.width / 2, y1: c.height / 2 }, { x2: x * c.width, y2: y * c.height });
  const r = getR({ x1: c.width / 2, y1: c.height / 2 }, { x2: x * c.width, y2: y * c.height });
  fimg.set('angle', 270 - angle).setCoords();
  fimg.setPositionByOrigin(point, 'center', 'center');
  return { data_url: fcanvas.toDataURL(), coord: { x: 0.5, y: (fcanvas.height / 2 - r) / fcanvas.height } };
}

export function getTrimmedCanvas({ canvas, top, left, width, height }) {
  const image_data = canvas.getContext('2d').getImageData(left, top, width, height);
  const result_canvas = document.createElement('canvas');
  result_canvas.width = image_data.width;
  result_canvas.height = image_data.height;
  result_canvas.getContext('2d').putImageData(image_data, 0, 0);
  return result_canvas;
}

// async function shadowPart({ acril_data_url, white_data_url, shadow_brightness }) {
//   return makeShadowAndGeometryCanvas(
//     await makeCanvasByUrl(acril_data_url),
//     {
//       right_solid_opacity: 0.5,
//       right_solid_top_hex: '#c1c1c1',
//       right_solid_center_hex: '#696969',
//       right_solid_bottom_hex: '#c1c1c1',
//       right_solid_x: 9,
//       right_solid_y: 3,
//     },
//     {
//       left_solid_opacity: 0.5,
//       left_solid_top_hex: '#c1c1c1',
//       left_solid_center_hex: '#696969',
//       left_solid_bottom_hex: '#c1c1c1',
//       left_solid_x: 9,
//       left_solid_y: 7,
//     },
//     shadow_brightness,
//   );
// }

async function auroraShadowPart({ acril_data_url, white_data_url }, ratio) {
  return makeShadowAndGeometryCanvas(
    await makeCanvasByUrl(acril_data_url),
    {
      right_solid_opacity: 0.5,
      right_solid_top_hex: '#90bddc',
      right_solid_center_hex: '#4e4568',
      right_solid_bottom_hex: '#90bddc',
      right_solid_x: 9,
      right_solid_y: 3,
    },
    {
      left_solid_opacity: 0.5,
      left_solid_top_hex: '#90bddc',
      left_solid_center_hex: '#4e4568',
      left_solid_bottom_hex: '#90bddc',
      left_solid_x: 9,
      left_solid_y: 3,
    },
    150,
    ratio,
  );
}

export async function keyringEffect({
  white_data_url,
  acril_data_url,
  design_data_url,
  material_data_url,
  material,
  acril_coord,
  ratio,
}) {
  const c = createCanvasElement(await makeCanvasByUrl(acril_data_url));
  const ctx = c.getContext('2d');

  if (material === 'gl') {
    ctx.drawImage(
      await makeShadowAndGeometryCanvas(
        await makeCanvasByUrl(acril_data_url),
        {
          right_solid_opacity: 0.5,
          right_solid_top_hex: '#c1c1c1',
          right_solid_center_hex: '#696969',
          right_solid_bottom_hex: '#c1c1c1',
          right_solid_x: 9,
          right_solid_y: 3,
        },
        {
          left_solid_opacity: 0.5,
          left_solid_top_hex: '#c1c1c1',
          left_solid_center_hex: '#696969',
          left_solid_bottom_hex: '#c1c1c1',
          left_solid_x: 9,
          left_solid_y: 7,
        },
        200,
        ratio,
      ),
      0,
      0,
    );
    ctx.drawImage(await makeCanvasByUrl(material_data_url), 0, 0);
    ctx.drawImage(await makeCanvasByUrl(design_data_url), 0, 0);
    ctx.drawImage(
      await go(
        makeGradientCanvas(
          c,
          [
            {
              offset: 0,
              hex: 'rgba(200, 200, 200, 0)',
            },
            {
              offset: 0.4,
              hex: 'rgba(200, 200, 200, 0.2)',
            },
            {
              offset: 0.5,
              hex: 'rgba(255, 255, 255, 0.2)',
            },
            {
              offset: 0.6,
              hex: 'rgba(200, 200, 200, 0.2)',
            },
            {
              offset: 1,
              hex: 'rgba(200, 200, 200, 0)',
            },
          ],
          { x1: 0, y1: 0, x2: c.width, y2: c.height },
        ),
        async (c) => masking({ mask: await makeCanvasByUrl(acril_data_url), target: c }),
      ),
      0,
      0,
    );
    return exportResultCanvas({ ctx, acril_data_url, c, ratio, acril_coord });
  } else if (material === 'au') {
    ctx.drawImage(await auroraShadowPart({ acril_data_url, white_data_url }, ratio), 0, 0);
    ctx.drawImage(
      auroraAcril(await makeCanvasByUrl(acril_data_url), {
        shine_opacity: 0.5,
        shine_top_hex: '#00afff',
        shine_bottom_hex: '#8666f8',
      }),
      0,
      0,
    );
    ctx.drawImage(await makeCanvasByUrl(design_data_url), 0, 0);
    return go(
      exportResultCanvas({ ctx, acril_data_url, c, ratio, acril_coord }),
      tap(({ canvas: result_canvas, coord }) => {
        const rc_ctx = result_canvas.getContext('2d');
        rc_ctx.drawImage(
          makeGradientCanvas(
            result_canvas,
            [
              {
                offset: 0,
                hex: 'rgba(255, 255, 255, 0)',
              },
              {
                offset: 0.4,
                hex: 'rgba(255, 255, 255, 0.1)',
              },
              {
                offset: 0.5,
                hex: 'rgba(255, 255, 255, 0.2)',
              },
              {
                offset: 0.6,
                hex: 'rgba(255, 255, 255, 0.1)',
              },
              {
                offset: 1,
                hex: 'rgba(255, 255, 255, 0)',
              },
            ],
            { x1: 0, y1: 0, x2: result_canvas.width, y2: result_canvas.height },
          ),
          0,
          0,
        );
      }),
    );
  } else {
    ctx.drawImage(await makeCanvasByUrl(design_data_url), 0, 0);
    ctx.drawImage(
      await makeShadowAndGeometryCanvas(
        await makeCanvasByUrl(acril_data_url),
        {
          right_solid_opacity: 0.5,
          right_solid_top_hex: '#c1c1c1',
          right_solid_center_hex: '#696969',
          right_solid_bottom_hex: '#c1c1c1',
          right_solid_x: 9,
          right_solid_y: 3,
        },
        {
          left_solid_opacity: 0.5,
          left_solid_top_hex: '#c1c1c1',
          left_solid_center_hex: '#696969',
          left_solid_bottom_hex: '#c1c1c1',
          left_solid_x: 9,
          left_solid_y: 7,
        },
        210,
        ratio,
      ),
      0,
      0,
    );
    ctx.drawImage(
      await go(
        makeGradientCanvas(
          c,
          [
            {
              offset: 0,
              hex: 'rgba(200, 200, 200, 0)',
            },
            {
              offset: 0.4,
              hex: 'rgba(200, 200, 200, 0.2)',
            },
            {
              offset: 0.5,
              hex: 'rgba(255, 255, 255, 0.2)',
            },
            {
              offset: 0.6,
              hex: 'rgba(200, 200, 200, 0.2)',
            },
            {
              offset: 1,
              hex: 'rgba(200, 200, 200, 0)',
            },
          ],
          { x1: 0, y1: 0, x2: c.width, y2: c.height },
        ),
        async (c) => masking({ mask: await makeCanvasByUrl(acril_data_url), target: c }),
      ),
      0,
      0,
    );
    return exportResultCanvas({ ctx, acril_data_url, c, ratio, acril_coord });
  }
}

async function exportResultCanvas({ ctx, acril_data_url, c, ratio, acril_coord }) {
  const white_c = white(await makeCanvasByUrl(acril_data_url), ratio);
  ctx.drawImage(white_c, 0, 0);
  const { top, left, width, height } = trimCanvasSize(c);
  const image_data = ctx.getImageData(left, top, width, height);

  const coord = {
    x: (acril_coord.x * c.width - left) / width,
    y: (acril_coord.y * c.height - top) / height,
  };
  const result_canvas = document.createElement('canvas');
  result_canvas.width = image_data.width;
  result_canvas.height = image_data.height;
  result_canvas.getContext('2d').putImageData(image_data, 0, 0);
  return { canvas: result_canvas, coord };
}
