export const categoryOptions = {
  CATE_ITEMS: '중 카테고리',
  TEXT: '텍스트',
  PRODUCT: '상품',
  BANNER: '배너',
  COLOR: '색상',
  LINK: '링크',
};
export const textTypeThead = {
  TITLE: '타이틀',
  URL: '링크',
};

export const bannerTypeThead = {
  DESC: '설명',
  URL: '링크',
  BANNER_IMG: '배너이미지',
};

export const productTypeThead = {
  BPID: 'BPID',
};

export const defaultTypeThead = {
  TITLE: ' ',
};

export const linkTypeThead = {
  URL: '링크',
};

export const langs = {
  kr: '국문',
  en: '영문',
  jp: '일문',
};

export const isMobile = {
  false: '',
  true: '모바일',
};

export const allCategoryNavigationMenuIDByLang = {
  kr: [69, 72],
  en: [70, 73],
  jp: [71, 74],
};
