import { html, isObject, isString } from 'fxjs/es';

const parseAlert = (alert) => {
  if (isObject(alert)) {
    return alert;
  }

  if (!isString(alert)) return '오류가 발생했습니다.';

  try {
    return JSON.parse(alert);
  } catch (e) {
    return alert;
  }
};

export const alertTmpl = (alert) => {
  const parsed_alert = parseAlert(alert);

  if (typeof parsed_alert === 'string') {
    return parsed_alert;
  }

  if (!parsed_alert.title && (parsed_alert.content || parsed_alert.message || parsed_alert.msg))
    return parsed_alert.content || parsed_alert.message || parsed_alert.msg;

  if (parsed_alert.title && !(parsed_alert.content || parsed_alert.message || parsed_alert.msg))
    return parsed_alert.title;

  if (G.collabo_type !== 'creator') {
    return html`
      <div class="custom-body">
        ${(parsed_alert.content || parsed_alert.message || parsed_alert.msg) &&
        html`<p>${parsed_alert.content || parsed_alert.message || parsed_alert.msg}</p>`}
        ${parsed_alert?.email && html`<span>${parsed_alert?.email}</span>`}
      </div>
    `;
  }

  return html`
    <div class="custom-body">
      ${parsed_alert?.title && html`<h1>${parsed_alert.title}</h1>`}
      ${(parsed_alert.content || parsed_alert.message || parsed_alert.msg) &&
      html`<p>${parsed_alert.content || parsed_alert.message || parsed_alert.msg}</p>`}
      ${parsed_alert?.email && html`<span>${parsed_alert?.email}</span>`}
    </div>
  `;
};
