import { go } from 'fxjs/es';
import { $closest, $css, $delegate, $find, $height, $setAttr } from 'fxdom/es';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';

export const getRootEl = (el, is_creator_pc) => {
  return is_creator_pc
    ? go(el, $closest('.don_frame'), $find('>.don_wrapper >.body'))
    : document.querySelector('html');
};

// export const getOffsetTop = (el, is_creator_pc) => {
//   return is_creator_pc
//     ? go(undefined, () => {
//         const target_parent_el = go(el, $closest('.don_wrapper'));
//         const f = (offset_top, el) => {
//           const new_offset_top = offset_top + el.offsetTop;
//           return el === target_parent_el ? new_offset_top : f(new_offset_top, el.offsetParent);
//         };
//         return f(0, el) - 56;
//       })
//     : $offset(el).top;
// };

export const getOffsetTop = (el) => {
  let i = 0;
  return go(undefined, () => {
    const f = (offset_top, el) => {
      if (i++ > 10) throw Error('too much call getOffsetTop func');
      const is_don_wrapper_el = el === go(el, $closest('.don_frame'), $find('>.don_wrapper'));
      const new_offset_top = offset_top + el.offsetTop;
      if (is_don_wrapper_el) return new_offset_top;
      return f(new_offset_top, el.offsetParent);
    };
    return f(el.offsetTop, el.offsetParent);
  });
};
export const event = (tab_el) =>
  go(
    tab_el,
    $delegate('click', '.swiper_thumbnails_icon .swiper-slide', (e) => {
      const pager_btns = $.find(
        e.delegateTarget,
        '.thumb_swiper .swiper-pagination .swiper-pagination-bullet',
      );
      if (!UtilF.isLegacyMobile() && $.attr(e.currentTarget, 'index') == pager_btns.length)
        pager_btns[parseInt($.attr(e.currentTarget, 'index'), 10) - 1].click();
      else pager_btns[$.attr(e.currentTarget, 'index')].click();
    }),
    $delegate('click', '.tg_head', function (e) {
      if (!UtilF.isLegacyMobile()) return;
      const btn_el = $find('.btn', e.currentTarget);
      if ($css('display', btn_el) === 'none') return;
      const tg_cont = $.closest(e.currentTarget, '.toggle_cont');
      $.attr(tg_cont, 'is_open', !$.attr(tg_cont, 'is_open'));
      if ($.attr(tg_cont, 'is_open')) $.scroll_top2(window, e.currentTarget.offsetTop + 300 || 0, 400);
    }),
    $delegate('click', '.tg_delivery .price_table', function () {
      $.frame.open({ frame_name: 'mp.quick_shipping_price' });
    }),
    $delegate('click', '.bp_info_pop_buttons span.delivery', function (e) {
      $.frame.open(
        {
          frame_name: 'mp.pd_delivery_frame',
          page_name: 'mp.pd_delivery_page',
        },
        {
          page_name: 'mp.pd_delivery_page',
          tabs: [
            {
              tab_name: 'mp.pd_delivery_tab',
            },
          ],
        },
      );
    }),
    $delegate('click', '.bp_info_pop_buttons span.guide', function (e) {
      $.frame.open(
        {
          frame_name: 'mp.pd_guide_frame',
          page_name: 'mp.pd_guide_page',
        },
        {
          page_name: 'mp.pd_guide_page',
          tabs: [
            {
              tab_name: 'mp.pd_guide_tab',
            },
          ],
        },
      );
    }),
    $delegate('click', '.product_detail_tab', (e) => {
      const container_el = go(e.currentTarget, $closest('.product_detail_tabs_container'));
      const tab_body_el_to_move = go(
        container_el,
        $find(`.product_detail_tab_body[data-tab_name="${e.currentTarget.dataset.tab_name}"]`),
      );
      go(tab_body_el_to_move, $find('.toggle_cont'), (el) => el && $setAttr({ is_open: true })(el));
      const { scroll_target, is_header_headroom, header_height } = container_el._tab_move_attributes;
      let top = getOffsetTop(tab_body_el_to_move) - go(container_el, $find(`.product_detail_tabs`), $height);
      top += 5;
      const is_pinned = go(undefined, () => {
        const scroll_top = scroll_target === window ? window.scrollY : scroll_target.scrollY;
        return scroll_top > top;
      });
      if (is_header_headroom) {
        if (is_pinned) top -= parseInt(header_height);
      } else {
        top -= parseInt(header_height);
      }
      const time_per_1000_px = 100;
      const duration = (Math.abs($.scroll_top(scroll_target) - top) / 1000) * time_per_1000_px;
      anime({
        targets: scroll_target === window ? 'html, body' : scroll_target,
        scrollTop: top,
        duration,
        easing: 'easeInOutCubic',
      }).finished;
    }),
  );
