import paper from 'paper';
import potrace from 'potrace';
import { PaperOffset } from 'paperjs-offset';

import {
  ALPHA_THRESHOLD,
  ITEM_NAME,
  STROKE_PROPERTY_NAME,
  STROKE_STYLE,
  TOOL_NAME,
} from '../../S/Constant/constants.js';
import { each, every, filter, go, identity, map, minBy } from 'fxjs/es';
import { $addClass, $attr, $delegate, $qs, $qsa, $removeClass, $trigger } from 'fxdom/es';
import { guard } from '../../S/guard.js';
import { pixelBinarize } from '../../../../NewMaker/BaseProducts/WebGlProcessing/F/private/filters/pixelBinarize.js';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';
import { tools } from '../Lib/state.js';
import { isNotKeyDownWithMeta } from './helpers.dom.js';

const hit_item = {
  stroke: null,
  segment: null,
  handleIn: null,
  handleOut: null,
};

const initializeHitItems = () => {
  if (hit_item.stroke) {
    hit_item.stroke.selected = false;
    hit_item.stroke = null;
  }
  if (hit_item.segment) {
    hit_item.segment.selected = false;
    hit_item.segment = null;
  }
  hit_item.handleIn = null;
  hit_item.handleOut = null;
};

export function toolVStroke({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const stroke_layer = DfImageEditorF.getVectorStrokeLayer({ paper_scope });
  const stroke_color_control_property_class = `.property-panel .properties[tool-name=${
    TOOL_NAME.vStroke
  }] .property[property-name=${
    tools[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.color].propertyName
  }]`;

  const stroke_width_control_property_class = `.property-panel .properties[tool-name=${
    TOOL_NAME.vStroke
  }] .property[property-name=${
    tools[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.width].propertyName
  }]`;

  const stroke_offset_control_property_class = `.property-panel .properties[tool-name=${
    TOOL_NAME.vStroke
  }] .property[property-name=${
    tools[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.offset].propertyName
  }]`;

  let continuous_event_ids = [];
  let continuous_timeout_event_ids = [];
  let is_mouse_downing = false;

  go(
    tab_el,
    /* 스트로크 색상 조절 */
    $delegate('input', `${stroke_color_control_property_class} input[type="color"]`, (e) => {
      const ct = e.currentTarget;
      applyStrokeStyleToAllStrokes({ stroke_layer, color: ct.value });
      DfImageEditorF.focusBackToFrame({ tab_el });
    }),
    /* 스트로크 두께 조절 */
    $delegate('mousedown', `${stroke_width_control_property_class} button`, (e) => {
      is_mouse_downing = true;
      if (e.button === 0) {
        changeStrokeWidth({ stroke_layer, control_name: e.currentTarget.name });
        if (continuous_event_ids.length === 0) {
          continuous_timeout_event_ids.push(
            setTimeout(() => {
              if (is_mouse_downing) {
                const normal_speed_event_id = setInterval(() => {
                  changeStrokeWidth({ stroke_layer, control_name: e.currentTarget.name });
                }, 100);
                continuous_event_ids.push(normal_speed_event_id);
                continuous_timeout_event_ids.push(
                  setTimeout(() => {
                    if (
                      is_mouse_downing &&
                      continuous_event_ids.length > 0 &&
                      continuous_event_ids.includes(normal_speed_event_id)
                    ) {
                      // speed up
                      const speed_up_event_id = setInterval(() => {
                        changeStrokeWidth({ stroke_layer, control_name: e.currentTarget.name });
                      }, 100);
                      continuous_event_ids.push(speed_up_event_id);
                    }
                  }, 1000),
                );
              }
            }, 300),
          );
        }
      }
    }),
    $delegate('change', `${stroke_width_control_property_class} input.indicator`, (e) => {
      const input_value = e.currentTarget.value;
      const stroke_width_property = DfImageEditorLibF.getState.property.strokeWidth();
      const { min, max } = stroke_width_property;
      const new_target_value = DfImageEditorF.prepareNumber({ num: input_value, min, max });
      input_value !== new_target_value && (e.currentTarget.value = new_target_value);
      applyStrokeStyleToAllStrokes({
        stroke_layer,
        width: new_target_value,
      });
      DfImageEditorF.focusBackToFrame({ tab_el });
    }),
    /* 스트로크 오프셋 */
    $delegate('mousedown', `${stroke_offset_control_property_class} button`, (e) => {
      is_mouse_downing = true;
      if (e.button === 0) {
        changeStrokeOffset({ stroke_layer, control_name: e.currentTarget.name });
      }
    }),
    $delegate('change', `${stroke_offset_control_property_class} input.indicator`, (e) => {
      const input_value = e.currentTarget.value;
      const stroke_offset_property = DfImageEditorLibF.getState.property.strokeOffset();
      const { min, max } = stroke_offset_property;
      const new_target_value = DfImageEditorF.prepareNumber({ num: input_value, min, max });
      input_value !== new_target_value && (e.currentTarget.value = new_target_value);
      DfImageEditorLibF.setState.property.vStroke.offset(new_target_value, false);
      changeStrokeOffset({
        stroke_layer,
        new_target_value: DfImageEditorLibF.getState.property.strokeOffset().value,
      });
      DfImageEditorF.focusBackToFrame({ tab_el });
    }),
    /* 컨트롤 버튼들 (적용, 재생성, 취소) */
    $delegate('click', `.property-panel .controls[tool-name=${TOOL_NAME.vStroke}] button`, async (e) => {
      const ct = e.currentTarget;
      const control_name = ct.name;
      switch (control_name) {
        case 'apply-with-level': {
          const selected_el = findSelectedStorkeApplyButton();
          if (ct === selected_el) return;

          clearSelectedAllStrokeApplyButtons();
          $addClass('selected', ct);

          const level = $attr('level', ct);
          try {
            $.don_loader_start();
            const stroke_original_item = getStrokeOriginalItem({ stroke_layer });
            if (stroke_original_item) {
              await reCalculateStroke({ paper_scope, level });
            } else {
              await doVectorStroke({ tab_el, level });
            }
          } catch (e) {
            await DfImageEditorF.donAlertAsync({
              tab_el,
              msg: e.message,
            });
          } finally {
            $.don_loader_end();
          }

          break;
        }
        case 'cancel': {
          cleanStrokeLayer({ paper_scope });
          clearSelectedAllStrokeApplyButtons();
          break;
        }
      }
    }),
  );
  tab_el.addEventListener('mouseup', (e) => {
    is_mouse_downing = false;
    if (continuous_event_ids.length) {
      continuous_event_ids.forEach((event_id) => {
        clearInterval(event_id);
      });
      continuous_event_ids = [];
    }
    if (continuous_timeout_event_ids.length) {
      continuous_timeout_event_ids.forEach((event_id) => clearTimeout(event_id));
      continuous_timeout_event_ids = [];
    }
  });
}

export async function activateStrokeTool({ tab_el }) {
  try {
    $.don_loader_start();

    const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });

    const stroke_tool_name = TOOL_NAME.vStroke;
    const stroke_layer = DfImageEditorF.getVectorStrokeLayer({ paper_scope });

    const updateStrokeSelected = ({ hit_stroke }) => {
      const previous_stroke = hit_item.stroke;
      if (previous_stroke == null || previous_stroke !== hit_stroke) {
        initializeHitItems();
        hit_item.stroke = hit_stroke;
        hit_item.stroke.selected = true;
      }
    };

    const removeSegmentSelected = () => {
      hit_item.segment && (hit_item.segment.selected = false);
      hit_item.segment = null;
    };

    const updateSegmentSelected = ({ hit_segment, handle_type }) => {
      const previous_segment = hit_item.segment;
      const current_segment = hit_segment;

      if (previous_segment != null && previous_segment !== current_segment) {
        previous_segment.selected = false;
      }
      current_segment.selected = true;
      hit_item.segment = current_segment;
      hit_item.handleIn = false;
      hit_item.handleOut = false;
      if (handle_type === 'handle-in') {
        hit_item.handleIn = true;
      } else if (handle_type === 'handle-out') {
        hit_item.handleOut = true;
      }
    };

    let is_control_pressed = false;
    let is_path_altered = false;

    const stroke_tool = DfImageEditorF.prepareTool({
      paper_scope,
      tool_name: stroke_tool_name,
      eventHandlers: {
        keydown: (e) => {
          const keyboard_event = e.event;
          if (keyboard_event.code === 'ControlLeft') {
            is_control_pressed = true;
          }
          if (isNotKeyDownWithMeta({ keyboard_event })) {
            if (keyboard_event.code === 'KeyA') {
              $trigger('click', getStrokeControlButton({ name: 'apply' }));
            }
            if (keyboard_event.code === 'KeyC') {
              $trigger('click', getStrokeControlButton({ name: 'cancel' }));
              $trigger('click', getStrokeControlButton({ name: 'cancel' }));
            }
            if (keyboard_event.code === 'BracketLeft') {
              changeStrokeWidth({ stroke_layer, control_name: 'minus' });
            }
            if (keyboard_event.code === 'BracketRight') {
              changeStrokeWidth({ stroke_layer, control_name: 'plus' });
            }
            if (keyboard_event.code === 'Comma') {
              changeStrokeOffset({ stroke_layer, control_name: 'minus' });
            }
            if (keyboard_event.code === 'Period') {
              changeStrokeOffset({ stroke_layer, control_name: 'plus' });
            }
            if (keyboard_event.code === 'Delete') {
              if (hit_item.segment) {
                hit_item.segment.remove();
                removeSegmentSelected();
              } else if (hit_item.stroke) {
                hit_item.stroke.remove();
                initializeHitItems();
              }
            }
          }
        },
        keyup: (e) => {
          is_control_pressed = false;
        },
        mousedown: (e) => {
          if (e.event.button === 0) {
            if (DfImageEditorLibF.view_control.isControlling() === false) {
              const hit_result = stroke_layer.hitTest(e.point, {
                stroke: true,
                segments: true,
                handles: true,
              });

              if (hit_result == null) {
                initializeHitItems();
              } else if (hit_item.stroke == null || hit_result.type === 'stroke') {
                updateStrokeSelected({ hit_stroke: hit_result.item });
                removeSegmentSelected();
              } else if (
                hit_result.type === 'segment' ||
                hit_result.type === 'handle-in' ||
                hit_result.type === 'handle-out'
              ) {
                enhanceSegmentHitAccuracy({ hitResult: hit_result, click_point: e.point });

                updateStrokeSelected({ hit_stroke: hit_result.item });
                updateSegmentSelected({ hit_segment: hit_result.segment, handle_type: hit_result.type });
              }
            }
          }
        },
        mousedrag: (e) => {
          if (DfImageEditorLibF.view_control.isControlling() === false) {
            if (hit_item.stroke != null && hit_item.segment !== null) {
              const segment = hit_item.segment;
              is_path_altered = true;
              if (hit_item.handleIn) {
                hit_item.segment.handleIn = hit_item.segment.handleIn.add(e.delta);
                !is_control_pressed &&
                  (hit_item.segment.handleOut = hit_item.segment.handleOut.subtract(e.delta));
              } else if (hit_item.handleOut) {
                hit_item.segment.handleOut = hit_item.segment.handleOut.add(e.delta);
                !is_control_pressed &&
                  (hit_item.segment.handleIn = hit_item.segment.handleIn.subtract(e.delta));
              } else {
                segment.point = segment.point.add(e.delta);
              }
            }
          }
        },
        mouseup: () => {
          if (is_path_altered) {
            is_path_altered = false;
          }
        },
        activate: async () => {
          const stroke_original_item = getStrokeOriginalItem({ stroke_layer });
          if (stroke_original_item) {
            const current_path = getCurrentOffsetPath({ stroke_layer });
            if (current_path) {
              current_path.selected = true;
              current_path.visible = true;
            }
          } else {
            /* 기본으로 활성화 시키지 않음. */
            // await doVectorStroke({ tab_el });
          }
        },
        deactivate: () => {
          deSelectAllPaths({ stroke_layer });
        },
      },
    });
    paper_scope.settings.handleSize = 6;
    stroke_tool.activate();
  } catch (e) {
    await DfImageEditorF.donAlertAsync({
      tab_el,
      msg: e.message,
    });
  } finally {
    $.don_loader_end();
  }
}

function getTraceOptionByLevel({ level }) {
  let enhance_ratio;
  let trace_tolerance;

  switch (level) {
    case '1': {
      enhance_ratio = 0.4;
      trace_tolerance = 0.15;
      break;
    }
    case '2': {
      enhance_ratio = 0.6;
      trace_tolerance = 0.125;
      break;
    }
    case '3': {
      enhance_ratio = 0.8;
      trace_tolerance = 0.1;
      break;
    }
    case '4': {
      enhance_ratio = 1.0;
      trace_tolerance = 0.08;
      break;
    }
    case '5': {
      enhance_ratio = 1.5;
      trace_tolerance = 0.06;
      break;
    }
    case '6': {
      enhance_ratio = 2.0;
      trace_tolerance = 0.04;
      break;
    }
    case '7': {
      enhance_ratio = 2.5;
      trace_tolerance = 0.02;
      break;
    }
    default: {
      throw new Error(`Unidentified trace level ${level}`);
    }
  }

  return { enhance_ratio, trace_tolerance };
}

export async function doVectorStroke({ tab_el, level }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });

  const { enhance_ratio, trace_tolerance } = getTraceOptionByLevel({ level });

  const v_stroke_layer = DfImageEditorF.getVectorStrokeLayer({ paper_scope });
  await tracePrintableRaster({
    paper_scope,
    alpha_threshold: ALPHA_THRESHOLD.vStroke,
    is_alpha_binarize: false,
    enhance_ratio,
    trace_tolerance,
    onload: async (stroke_item) => {
      v_stroke_layer.addChild(stroke_item);
      stroke_item.name = ITEM_NAME.stroke;
      stroke_item.selected = true;

      DfImageEditorF.addDataToItem({
        item: stroke_item,
        data: { is_stroke: true, is_offset: false, offset: 0 },
      });

      const color = DfImageEditorLibF.getState.property.strokeColor().value;
      const width = DfImageEditorLibF.getState.property.strokeWidth().value;

      const current_offset = DfImageEditorLibF.getState.property.strokeOffset().value;
      if (current_offset !== 0) {
        changeStrokeOffset({
          stroke_layer: v_stroke_layer,
          new_target_value: current_offset,
        });
      }

      applyStrokeStyleToAllStrokes({ stroke_layer: v_stroke_layer, color, width });
    },
  });
}

export function hasStrokeItem({ paper_scope }) {
  const stroke_layer = DfImageEditorF.getVectorStrokeLayer({ paper_scope });
  return !!getStrokeOriginalItem({ stroke_layer });
}

function getStrokeOriginalItem({ stroke_layer }) {
  return stroke_layer.getItem({ name: ITEM_NAME.stroke });
}

function getAllStrokeItems({ stroke_layer }) {
  return DfImageEditorF.getItemsInLayer({ layer: stroke_layer, condition: { data: { is_stroke: true } } });
}

function getAllSelectedPaths({ stroke_layer }) {
  return DfImageEditorF.getItemsInLayer({ layer: stroke_layer, condition: { selected: true } });
}

export function cleanStrokeLayer({ paper_scope, with_update_design_size = true }) {
  const stroke_layer = DfImageEditorF.getVectorStrokeLayer({ paper_scope });
  stroke_layer.children.filter(identity).forEach((stroke_item) => {
    stroke_item.remove();
    stroke_item = null;
  });
}

export async function reCalculateStroke({ paper_scope, level }) {
  cleanStrokeLayer({ paper_scope, with_update_design_size: false });
  await doVectorStroke({ tab_el: paper_scope.tab_el, level });
}

function applyStrokeStyleToAllStrokes({ stroke_layer, width, color }) {
  const all_stroke_items = getAllStrokeItems({ stroke_layer });
  if (all_stroke_items && all_stroke_items.length > 0) {
    all_stroke_items.forEach((stroke_item) => {
      color && (stroke_item.strokeColor = color);
      width && (stroke_item.strokeWidth = width);
    });
  }
  width && DfImageEditorLibF.setState.property.vStroke.width(width, true);
  color && DfImageEditorLibF.setState.property.vStroke.color(color, true);
}

function deSelectAllPaths({ stroke_layer }) {
  go(
    getAllSelectedPaths({ stroke_layer }),
    each((path) => {
      path.selected = false;
    }),
  );
}

function hideAllStrokePaths({ stroke_layer }) {
  const stroke_items = getAllStrokeItems({ stroke_layer });
  stroke_items.forEach((item) => {
    item.visible = false;
    item.selected = false;
  });
}

async function handleOffsetPath({ stroke_layer, offset }) {
  const stroke_item = getStrokeOriginalItem({ stroke_layer });
  if (stroke_item == null) return;

  hideAllStrokePaths({ stroke_layer });
  initializeHitItems();

  if (offset === 0) {
    stroke_item.visible = true;
    stroke_item.selected = true;
    return;
  }

  const exist_offset_path = DfImageEditorF.getItemInLayer({
    layer: stroke_layer,
    condition: { data: { is_offset: true, offset } },
  });

  if (exist_offset_path) {
    exist_offset_path.visible = true;
    exist_offset_path.selected = true;
  } else {
    const offset_path = createOffsetPath({ reference_stroke_item: stroke_item, offset });
    stroke_layer.addChild(offset_path);
    offset_path.visible = true;
    offset_path.selected = true;
  }
}

function createOffsetPath({ reference_stroke_item, offset }) {
  const offset_path = PaperOffset.offset(reference_stroke_item, offset, {
    insert: false,
    join: 'miter',
  });
  offset_path.closed = true;

  const offset_path_united = unitePaths({ item: offset_path });
  sanitizePath({ item: offset_path_united });
  offset_path_united.copyAttributes(reference_stroke_item, false);
  DfImageEditorF.addDataToItem({
    item: offset_path_united,
    data: { is_stroke: true, is_offset: true, offset },
  });
  return offset_path_united;
}

function unitePaths({ item }) {
  const inner_paths = [];
  if (DfImageEditorF.isPath({ item })) {
    return item;
  } else {
    let united_path = null;
    go(
      DfImageEditorF.getAllPaths({ item }),
      each((src_path) => {
        if (src_path.clockwise === false) {
          inner_paths.push(src_path);
        } else {
          united_path = unitePath({ src_path, united_path });
          src_path.remove();
        }
      }),
    );

    if (inner_paths.length > 0) {
      if (DfImageEditorF.isPath({ item: united_path })) {
        united_path = convertPathToCompoundPath({ path: united_path });
      }
      inner_paths.forEach((inner_path) => united_path.addChild(inner_path));
    }
    return united_path.unite(united_path, { insert: false });
  }
}
function unitePath({ src_path, united_path }) {
  return united_path ? united_path.unite(src_path, { insert: false }) : src_path;
}

function sanitizePath({ item }) {
  go(
    DfImageEditorF.getAllPaths({ item }),
    filter((p) => Math.abs(p.area) < 10),
    each((p) => p.remove()),
  );
}

function convertPathToCompoundPath({ path }) {
  const compound_path = new paper.CompoundPath({ children: [path] });
  compound_path.copyAttributes(path, true);

  return compound_path;
}

function removeSvgViewBox({ paper_scope, stroke_item_group }) {
  const view_box_item = stroke_item_group.getItem({ type: 'shape' }); // rectangle
  view_box_item.remove();

  return DfImageEditorF.groupItemsToSinglePath({ paper_scope, group: stroke_item_group });
}

function syncStrokeViewToRaster({ stroke_item, raster }) {
  stroke_item.position = raster.center;
  stroke_item.scale(raster.scaling);
}

export async function tracePrintableRaster({
  paper_scope,
  image_or_canvas,
  alpha_threshold, // 0 ~ 100
  is_alpha_binarize,
  onload,
  enhance_ratio = 1.0,
  trace_tolerance = 0.1,
}) {
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  const check = guard.checkRasterExist({ raster: printable_raster });
  if (check) {
    await DfImageEditorF.donAlertAsync({ tab_el: paper_scope.tab_el, msg: check });
    return;
  }

  let src_image;

  if (image_or_canvas) {
    src_image = image_or_canvas;
  } else {
    if (enhance_ratio === 1.0) {
      src_image = printable_raster.image;
    } else {
      src_image = DfImageEditorF.resizeImage(printable_raster.canvas, enhance_ratio);
    }
  }

  const binarize_img = await pixelBinarize({
    image: src_image,
    threshold: alpha_threshold / 100, // normalized to 0.0 ~ 1.0
    color: [0.0, 0.0, 0.0],
    is_alpha_binarize,
  });

  return new Promise((resolve, reject) => {
    potrace.trace(
      binarize_img,
      {
        background: potrace.Potrace.COLOR_TRANSPARENT,
        color: potrace.Potrace.COLOR_TRANSPARENT,
        alphaMax: 1.0,
        optTolerance: trace_tolerance,
        optCurve: true,
      },
      (e, svg) => {
        if (e) {
          reject(e);
        } else {
          paper_scope.project.importSVG(svg, {
            insert: false,
            onLoad: async (stroke_item_group) => {
              syncStrokeViewToRaster({ stroke_item: stroke_item_group, raster: printable_raster });
              stroke_item_group.scale(1 / enhance_ratio);
              const stroke_item = removeSvgViewBox({
                paper_scope,
                stroke_item_group,
                name: ITEM_NAME.stroke,
              });
              reOrientPath({ stroke_item });
              const transparent_color = new paper_scope.Color(0, 0, 0, 0);
              stroke_item.strokeJoin = 'round';
              stroke_item.strokeCap = 'round';
              stroke_item.fillColor = transparent_color;

              if (stroke_item.children) {
                stroke_item.children.forEach((path) => (path.fillColor = transparent_color));
              } else {
                stroke_item.fillColor = transparent_color;
              }

              await onload(stroke_item);
              resolve(stroke_item);
            },
          });
        }
      },
    );
  });
}

function reOrientPath({ stroke_item }) {
  if (stroke_item instanceof paper.CompoundPath) {
    const paths = stroke_item.children;

    go(
      paths,
      each((target_path) => {
        target_path.clockwise = true;
        target_path.closed = true;
      }),
      each((target_path) => {
        go(
          paths,
          each((compare_path) => {
            if (target_path.id !== compare_path.id) {
              const is_inside = target_path.isInside(compare_path.bounds);
              if (is_inside) {
                if (isContainAllPathSegments({ target_path, compare_path })) {
                  target_path.clockwise = false;
                }
              }
            }
          }),
        );
      }),
    );
  } else if (stroke_item instanceof paper.Path) {
    stroke_item.clockwise = true;
    stroke_item.closed = true;
  }
}

function isContainAllPathSegments({ target_path, compare_path }) {
  return go(
    target_path.segments,
    every((seg) => compare_path.contains(seg.point)),
  );
}

export function initializeStrokeProperty() {
  DfImageEditorLibF.setState.property.vStroke.width(STROKE_STYLE.v_stroke_initial_width, true);
}

function getStrokeControlButton({ name }) {
  const $control_btn = $qs(`.property-panel .controls[tool-name=${TOOL_NAME.vStroke}] button[name=${name}]`);
  if ($control_btn == null) {
    throw new Error(`Not exist control button el ${name}`);
  }
  return $control_btn;
}

function changeStrokeWidth({ stroke_layer, control_name }) {
  const stroke_width_property = DfImageEditorLibF.getState.property.strokeWidth();
  const { value, min, max, step } = stroke_width_property;

  let new_target_value;

  switch (control_name) {
    case 'plus': {
      new_target_value = Math.min(value + step, max);
      break;
    }
    case 'minus': {
      new_target_value = Math.max(value - step, min);
      break;
    }
    default:
      throw new Error(`Unhandled stroke control button ${control_name}`);
  }
  applyStrokeStyleToAllStrokes({
    stroke_layer,
    width: new_target_value,
  });
}

function changeStrokeOffset({ stroke_layer, new_target_value, control_name }) {
  const stroke_item = getStrokeOriginalItem({ stroke_layer });

  const stroke_offset_property = DfImageEditorLibF.getState.property.strokeOffset();
  const { value, min, max, step } = stroke_offset_property;

  switch (control_name) {
    case 'plus': {
      new_target_value = Math.min(value + step, max);
      break;
    }
    case 'minus': {
      new_target_value = Math.max(value - step, min);
      break;
    }
  }

  DfImageEditorLibF.setState.property.vStroke.offset(new_target_value, true);

  if (stroke_item) {
    try {
      $.don_loader_start();
      setTimeout(async () => {
        await handleOffsetPath({
          stroke_layer,
          offset: new_target_value,
        });
        $.don_loader_end();
      }, 0);
    } catch (err) {
      console.error(err);
      $.don_loader_end();
    }
  }
}

export function getCurrentOffsetPath({ stroke_layer }) {
  const current_stroke_offset = DfImageEditorLibF.getState.property.strokeOffset().value;

  return DfImageEditorF.getItemInLayer({
    layer: stroke_layer,
    condition: { data: { offset: current_stroke_offset } },
  });
}

export function updateVStrokeColor({ stroke_layer, color }) {
  if (color == null) return;
  applyStrokeStyleToAllStrokes({ stroke_layer, color });
}

export function getVisibleCuttingPath({ paper_scope }) {
  const cutting_layer = DfImageEditorF.getVectorStrokeLayer({ paper_scope });
  return DfImageEditorF.getAllVisiblePaths({ item: cutting_layer });
}

/* @description segment 와 handle 중 어느 점이 더 click_point 에서 가까운지 판별해서 hitResult type 에 side effect 를 일으킴 (paperjs 의 hit test 가 너무 세밀할 때 정확하지 않는 부분 해결하기 위함) */
function enhanceSegmentHitAccuracy({ hitResult, click_point }) {
  if (hitResult && hitResult.type === 'segment') {
    const segment = hitResult.segment;
    const handle_in_point = segment.point.add(segment.handleIn);
    const handle_out_point = segment.point.add(segment.handleOut);

    const nearest_hit_from_click_point = go(
      [
        ['segment', segment.point],
        ['handle-in', handle_in_point],
        ['handle-out', handle_out_point],
      ],
      map(([type, at_point]) => {
        return [type, click_point.getDistance(at_point)];
      }),
      minBy(([_, distance]) => distance),
    );

    return (hitResult.type = nearest_hit_from_click_point[0]);
  }
}

function clearSelectedAllStrokeApplyButtons() {
  go(
    $qsa(`.property-panel .controls[tool-name=${TOOL_NAME.vStroke}] button.apply-with-level.selected`),
    each((el) => $removeClass('selected', el)),
  );
}

function findSelectedStorkeApplyButton() {
  return $qs(`.property-panel .controls[tool-name=${TOOL_NAME.vStroke}] button.apply-with-level.selected`);
}
