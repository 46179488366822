import { tab } from './tab.js';

// const mui_ref_path = '/modules/MarstagramEditor/S/Mui/page.js';
/*
 * 서버와 프론트에서 사용될 공통 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#a7b33e4f3e444ea1bf393c574297c5b5
 * */
export const page = {
  page_name: 'marstagram_editor', // page 이름 - 필수
  el_id: '', // page element id 추가
  el_class: '', // page element class 추가
  title: '', // page header의 title
  hide_frame_button_type: '', // frame 닫는 버튼 구분자 (''는 버튼이 나오지않음, 'V', 'v', 'X', '그외 아무 문자')
  custom_header(page) {}, // page header 커스텀 html 을 만드는 함수
  tabs: [tab], // /S/탭들을 미리 정의합니다.(하나이상 필수) https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#e62be34efea545eeb61afa2e5285a893

  // makeData(page) {}, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
};
