import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { isNil } from 'fxjs/es';

export const handleRightFooterCancelButtonClick = () => () => MuiF.popPage();

export const handleRightFooterDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    const target_image_el = tab_el.__mp_target_image_el;
    if (isNil(target_image_el)) {
      /** @todo alert message */
      await MuiF.popPage().catch(console.error);
      return;
    }

    const cutting_line_editor = tab_el.__mp_cutting_line_editor;
    if (isNil(cutting_line_editor)) {
      /** @todo alert message */
      await MuiF.popPage().catch(console.error);
      return;
    }

    const path_data = cutting_line_editor.getCuttingLinePathData();
    const result = await tab_el?.__mp_postProcess?.({ target_image_el, path_data });
    MuiF.closeFrame(result);
  };
