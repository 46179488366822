import { PriceS } from '../../../../modules/Price/S/Function/module/PriceS.js';

export default function (lo) {
  function bp_filter(arr, func) {
    return _p.val(arr, 'length') ? _p.filter(func) : _p.idtt;
  }

  function selected_bps() {
    const is_use_lines = $.val($('#is_use_line .is_use_line_item input:checked'));
    const cate_item_ids = _p.group_by(
      _p.map($('#cate_lists .cate_item input:checked'), (input) => box.sel(input)),
      'id',
    );
    const filter_ids = _p.group_by(
      _p.map($('#filters .filter_item[is_custom="false"] input:checked'), (input) => box.sel(input)),
      'filter_list_id',
    );
    const filter_list_group = _p.group_by(
      _p.map($('#filters .filter_item[is_custom="true"] input:checked'), (input) => box.sel(input)),
      'filter_list_id',
    );
    const name = $.val($1('#search input'));

    return _p.go(
      box.sel('base_products'),
      name ? _p.filter((bp) => bp.name.replace(/\s/g, '').indexOf(name.replace(/\s/g, '')) > -1) : _p.idtt,
      is_use_lines.length ? _p.filter((bp) => _p.find(is_use_lines, (key) => bp[key])) : _p.idtt,
      _p.size(cate_item_ids) ? _p.filter((bp) => cate_item_ids[bp.cate_item_id]) : _p.idtt,
      bp_filter(filter_ids.brand, (bp) =>
        _p.find(filter_ids.brand, (brand) => brand.id == bp.brand_filter_id),
      ),
      bp_filter(filter_ids.color, (bp) =>
        _p.find(filter_ids.color, (color) =>
          _p.find(bp._.base_product_colors, (bpc) => bpc.color_filter_id == color.id),
        ),
      ),
      bp_filter(filter_ids.size, (bp) =>
        _p.find(filter_ids.size, (size) =>
          _p.find(bp._.base_product_sizes, (bps) => bps.size_filter_id == size.id),
        ),
      ),
      (bps) =>
        _p.reduce(
          filter_list_group,
          (_bps, filter_items, fl_id) =>
            bp_filter(filter_items, (bp) => {
              const bp_filter_list = _p.find(bp._.bp_filter_lists, (fl) => fl.filter_list_id == fl_id);
              return (
                bp_filter_list &&
                _p.find(filter_items, (fi) =>
                  _p.find(
                    bp_filter_list._.bp_filter_list_filter_items,
                    (blfi) => blfi.filter_item_id == fi.id,
                  ),
                )
              );
            })(_bps),
          bps,
        ),
      function (bps) {
        $.remove_class($('li.bp_td'), 'show');
        _p.each(bps, function (bp) {
          $.add_class($1(`li.bp_td[data-id="${bp.id}"]`), 'show');
        });
        G.don_lazy();
      },
    );
  }

  $.frame.defn_frame({
    frame_name: 'df.base_product.list',
    page_name: 'df.base_product.list',
  });

  $.frame.defn_page({
    page_name: 'df.base_product.list',
    tabs: [
      {
        tab_name: 'df.base_product.list',
        appending: __(
          $.on('click', '.bp_sort button', function (e) {
            $.frame.open(
              {
                frame_name: 'df.base_product.sort',
                page_name: 'df.base_product.sort',
              },
              {
                page_name: 'df.base_product.sort',
                tabs: [
                  {
                    tab_name: 'df.base_product.sort',
                    data_func: function () {
                      return box.sel('base_products');
                    },
                  },
                ],
              },
            );
          }),
          $.on('click', '#cate_lists_wrap a', function (e) {
            $.add_class($.find($1($.attr(e.currentTarget, 'href')), 'li'), 'show');
          }),
          $.on(
            'change',
            '#search input, #is_use_line input, #cate_lists .cate_item input, #filters .filter_item input',
            selected_bps,
          ),

          $.on2('change', 'select.cate_list_selector', function (e) {
            return $.post('/@api/base_product/change_cate_list_id', {
              base_product_id: $.attr($.closest(e.currentTarget, 'li.bp_td'), 'data-id'),
              cate_list_id: $.val(e.currentTarget),
            });
          }),

          $.on2('change', 'select.cate_item_selector', function (e) {
            return $.post('/@api/base_product/change_cate_item_id', {
              base_product_id: $.attr($.closest(e.currentTarget, 'li.bp_td'), 'data-id'),
              cate_item_id: $.val(e.currentTarget),
            });
          }),
        ),
        appended: function () {
          _p.each($('input[disabled]'), function (input) {
            input.disabled = false;
          });
        },
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'df.base_product.sort',
    page_name: 'df.base_product.sort',
    el_class: 'share_order_align_editor',
    title: '순서 정렬',
    always_remove: true,
  });

  $.frame.defn_page({
    page_name: 'df.base_product.sort',
    hide_frame_button_type: 'X',
    title: '순서 정렬',
    tabs: [
      {
        title: '순서 정렬',
        tab_name: 'df.base_product.sort',
        template: (bps) => pug`
        .order_align
          button[type="button"].btn_finish 저장 및 완료
          #share_order_align_editor.base_products
            ${_p.sum(
              bps,
              (bp) => pug`
            .base_product[don_id=${bp.id}]
              .img
                img[src=${G.to_150(bp.model_thumb_url)}]
              .info
                .name ${bp.name}
                .name_en ${bp.name_en}
                .price ${PriceS.pricify(PriceS.add(bp.price, bp.print_price))}원
                .price_en $${PriceS.pricify(PriceS.add(bp.price_en, bp.print_price_en))}
            `,
            )}
      `,
        appended: __(
          $.on('click', '.btn_finish', function (e) {
            _p.go(
              $.put('/@api/base_product_sort', {
                bps: _p.go(
                  $.find(e.delegateTarget, '.base_product'),
                  _p.map((el_bp, i) => ({ id: $.attr(el_bp, 'don_id'), no: i })),
                ),
              }),
              _p.redirect(),
            );
          }),
          function (el_don_tab) {
            Sortable.create($.find1(el_don_tab, '.base_products'));
          },
        ),
      },
    ],
  });
  $.frame(
    {
      frame_name: 'df.base_product.list',
      page_name: 'df.base_product.list',
    },
    {
      page_name: 'df.base_product.list',
    },
  );
}
