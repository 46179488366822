import { $setCss } from 'fxdom/es';

/**
 * 모바일 디바이스의 키보드가 열려있을 때, body의 높이를 조정하는 함수
 * @param {UIEvent} event
 * @return {void}
 */
export const resizeHeightOfBodyByKeyboardVisibility = (event) => {
  const { height: visualViewportHeight } = event.target;

  if (isKeyboardVisible(visualViewportHeight)) {
    $setCss({ height: Math.ceil(visualViewportHeight) })(document.body);
  } else {
    $setCss({ height: innerHeight })(document.body);
  }
};

/**
 * 모바일 디바이스의 키보드 열려있는지 확인하는 함수
 * @param {number} visualViewportHeight (double)
 * @return {boolean}
 */
export const isKeyboardVisible = (visualViewportHeight) => {
  return window.innerHeight > Math.ceil(visualViewportHeight);
};
