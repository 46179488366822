import { html } from 'fxjs/es';
import { page as root_page } from './page.js';
const mui_ref_path = '/modules/VectorEditor/SettingBackground/Mobile/S/Mui/frame.js';
/*
 * 서버와 프론트에서 사용될 공통 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#0ed8bb87d5ff4363b7a1ba2ba4b7b0d9
 */
export const frame = {
  frame_name: mui_ref_path, // frame 고유이름 - 필수
  frame_tag: '', // frame 태그 구분자
  title: '', // frame header의 title
  el_id: '', // frame element id 추가
  el_class: '', // frame element class 추가
  header_height: 32, // frame의 헤더 높이
  hide_frame_button_type: '', // frame 닫는 버튼 구분자 (''는 버튼이 나오지않음, 'V', 'v', 'X', '그외 아무 문자')
  attrs: {}, // frame 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  custom_header(frame) {
    return html`
      <div class="header_shadow"></div>
      <button type="button" name="close_frame">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
          <defs>
            <filter
              id="fsja3dgira"
              width="110.7%"
              height="113.2%"
              x="-5.3%"
              y="-6.6%"
              filterUnits="objectBoundingBox"
            >
              <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
              <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
              <feColorMatrix
                in="shadowBlurOuter1"
                result="shadowMatrixOuter1"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feMerge>
                <feMergeNode in="shadowMatrixOuter1" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g>
              <path fill="#F8F9FA" d="M0 0H375V667H0z" transform="translate(-183 -373)" />
              <g filter="url(#fsja3dgira)" transform="translate(-183 -373) translate(0 365)">
                <g>
                  <path
                    fill="#FFF"
                    d="M2 0h46c1.105 0 2 .895 2 2v20H0V2C0 .895.895 0 2 0z"
                    transform="translate(163)"
                  />
                  <path
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M7 -1L3 3 7 7"
                    transform="translate(163) rotate(-90 19 -3)"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    `;
  }, //frame header 커스텀 html 을 만드는 함수

  /*
   * root_page 는 없으면 에러. 기본은 같이 만들어진 page 입니다.
   * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#a41af56cf6d94620848e34a54088dd50
   * */
  root_page, // /S/page 옵션객체 - 필수
};
