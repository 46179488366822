import { $addClass, $closest, $find, $removeClass, $setOuterHTML } from 'fxdom/es';
import { go, isEmpty } from 'fxjs/es';
import { OMPSearchHistoryTmplS } from '../../S/Tmpl/module/OMPSearchHistoryTmplS.js';

/**
 * 최근 검색어 템플릿을 갱신하는 함수
 * @param {SearchHistory} search_history
 * @param {HTMLElement} child_el `omp-search__search-history`의 child element
 */
export const updateSearchHistoryTmpl = (search_history, child_el) => {
  const Klass = Object.freeze({
    searchHistory: 'omp-search__search-history',
    searchHistoryEmpty: 'omp-search__search-history--empty',
    historyBtnWrap: 'omp-search__search-history__history-btns',
  });

  const $search_history = $closest(`.${Klass.searchHistory}`)(child_el);
  if (!$search_history) {
    throw new Error('child_el의 상위 엘리먼트에 omp-search__search-history가 없습니다.');
  }

  if (isEmpty(search_history.queries)) {
    $addClass(Klass.searchHistoryEmpty)($search_history);
  } else {
    $removeClass(Klass.searchHistoryEmpty)($search_history);
  }

  go(
    $search_history,
    $find(`.${Klass.historyBtnWrap}`),
    $setOuterHTML(OMPSearchHistoryTmplS.searchHistoryBtnsTmpl(search_history)),
  );
};
