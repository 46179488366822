import { DfTaskTmplS } from '../../S/Tmpl/module/DfTaskTmplS.js';
export function t_prj_tasks(tasks) {
  return _p.go(
    tasks,
    _p.group_by((task) => task.type),
    (tasks) => pug`
      .table.task_items
        .col.type[type='before_print']
          .cell.head
            label 제작준비중
            .btn.add[style=${!box.sel('is_user->_->policies->mp_worker_policy') ? 'display:none;' : ''}]
          .cell.body
            ${_p.sum(tasks.before_print || [], DfTaskTmplS.t_projection_task)}
        .col.type[type='printing']
          .cell.head
            label 제작
            .btn.add[style=${!box.sel('is_user->_->policies->mp_worker_policy') ? 'display:none;' : ''}]
          .cell.body
            ${_p.sum(
              _p.go(
                tasks.printing || [],
                _p.sort_by((task) => task.title == '제품확인'),
              ),
              DfTaskTmplS.t_projection_task,
            )}
        .col.type[type='before_deliver']
          .cell.head
            label 출고
            .btn.add[style=${!box.sel('is_user->_->policies->mp_worker_policy') ? 'display:none;' : ''}]
          .cell.body
            ${_p.sum(tasks.before_deliver || [], DfTaskTmplS.t_projection_task)}
        .col.type[type='as']
          .cell.head
            label AS/기타
            .btn.add[style=${!box.sel('is_user->_->policies->mp_worker_policy') ? 'display:none;' : ''}]
          .cell.body
            ${_p.sum(tasks.as || [], DfTaskTmplS.t_projection_task)}
    `,
  );
}

export function t_prj_task_column(tasks) {
  return pug`
      .col.tasks_container.task_create_possible[_sel="./_->tasks" show_hidden_tasks="false"]
        .head.cell
          .col.show_hidden_tasks 삭제된 태스크 보기 (${tasks?.filter((t) => t.is_hidden).length})
          .col.cancel_all_tasks 전체 태스크 취소하기
          .col.create_all_issues 전체 태스크 이슈달기
          .col.task_history 변경 내역
        ${t_prj_tasks(tasks)}
    `;
}
