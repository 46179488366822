import { CommonNS, EditorNS } from '@marpple/sticker-editor';
import axios from 'axios';
import { each, eachL, go, identity, mapL, pipe, sel, takeAll, takeL, tap } from 'fxjs/es';
import { $findAll, $hide, $on, $setCss } from 'fxdom/es';
import { PCRightFixedPanelF } from '../../../../../../PCRightFixedPanel/F/Function/module/PCRightFixedPanelF.js';
import { VectorEditorStickerSinglePCEventF } from '../Event/module/VectorEditorStickerSinglePCEventF.js';
import { VectorEditorStickerSinglePCMuiS } from '../../S/Mui/module/VectorEditorStickerSinglePCMuiS.js';
import { VectorEditorSettingBackgroundPCF } from '../../../../../SettingBackground/PC/F/Function/module/VectorEditorSettingBackgroundPCF.js';
import { VectorEditorFontF } from '../../../../../Font/F/Function/module/VectorEditorFontF.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';
import { VectorEditorSettingBackgroundPCEventF } from '../../../../../SettingBackground/PC/F/Event/module/VectorEditorSettingBackgroundPCEventF.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerSinglePCMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  async appending(tab_el$) {
    tab_el$.__mp_title = tab_el$.__mp_title ?? '';
    tab_el$.__mp_price = tab_el$.__mp_price ?? '';
    tab_el$.__mp_is_need_cutting_line = tab_el$.__mp_is_need_cutting_line ?? false;
    tab_el$.__mp_can_set_background = tab_el$.__mp_can_set_background ?? true;
    tab_el$.__mp_background_color = tab_el$.__mp_background_color ?? 'none';
    tab_el$.__mp_background_opacity = tab_el$.__mp_background_opacity ?? 1;
    tab_el$.__mp_frame_position_top = tab_el$.__mp_frame_position_top ?? 0;
    tab_el$.__mp_frame_position_height = tab_el$.__mp_frame_position_height ?? 0;
    tab_el$.__mp_art_board_size = {
      width: tab_el$.__mp_art_board_size?.width ?? 0,
      height: tab_el$.__mp_art_board_size?.height ?? 0,
    };
    tab_el$.__mp_foreground_el = tab_el$.__mp_foreground_el ?? null;
    tab_el$.__mp_empty_template_el = tab_el$.__mp_empty_template_el ?? null;
    tab_el$.__mp_prev_right_panel = tab_el$.__mp_prev_right_panel ?? null;
    tab_el$.__mp_preProcess = tab_el$.__mp_preProcess ?? null;
    tab_el$.__mp_postProcess = tab_el$.__mp_postProcess ?? null;
    tab_el$.__mp_is_go_to_next_page = false;
    tab_el$.__mp_editor_padding_top = 80;
    tab_el$.__mp_editor_padding_right = 300;
    tab_el$.__mp_editor_padding_bottom = 80;
    tab_el$.__mp_editor_padding_left = 300;

    go(
      tab_el$,

      tap(
        $findAll(`.left_menu[data-icon_type="upload_image"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCEventF.handleLeftMenuUploadImageIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.left_menu[data-icon_type="my_image"]`),
        each(
          $on('click', VectorEditorStickerSinglePCEventF.handleLeftMenuMyImageIconClick({ tab_el: tab_el$ })),
        ),
      ),
      tap(
        $findAll(`.left_menu[data-icon_type="shape"]`),
        each(
          $on('click', VectorEditorStickerSinglePCEventF.handleLeftMenuShapeIconClick({ tab_el: tab_el$ })),
        ),
      ),
      tap(
        $findAll(`.left_menu[data-icon_type="path"]`),
        each(
          $on('click', VectorEditorStickerSinglePCEventF.handleLeftMenuPathIconClick({ tab_el: tab_el$ })),
        ),
      ),
      tap(
        $findAll(`.left_menu[data-icon_type="text"]`),
        each(
          $on('click', VectorEditorStickerSinglePCEventF.handleLeftMenuTextIconClick({ tab_el: tab_el$ })),
        ),
      ),

      tap(
        $findAll(`.right_container .right_wrapper .button_container .cancel`),
        each($on('click', VectorEditorStickerSinglePCEventF.handleRightCancelButtonClick)),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .button_container .done`),
        eachL((el) => (el.dataset.is_show = `${!tab_el$.__mp_is_need_cutting_line}`)),
        each($on('click', VectorEditorStickerSinglePCEventF.handleRightDoneButtonClick({ tab_el: tab_el$ }))),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .button_container .next`),
        eachL((el) => (el.dataset.is_show = `${!!tab_el$.__mp_is_need_cutting_line}`)),
        each($on('click', VectorEditorStickerSinglePCEventF.handleRightNextButtonClick({ tab_el: tab_el$ }))),
      ),
      tap(
        $findAll(`.right_container .panel_wrapper[data-panel_wrapper="left"] .right_panel_home`),
        each(
          pipe(
            tap((el) => VectorEditorSettingBackgroundPCF.setTitle({ el, title: tab_el$.__mp_title })),
            tap((el) => VectorEditorSettingBackgroundPCF.setPrice({ el, price: tab_el$.__mp_price })),
            tab_el$.__mp_can_set_background
              ? identity
              : tap(
                  tap($findAll(`.color_selector`), each($hide)),
                  tap($findAll(`.opacity_selector`), each($hide)),
                ),
            tap((el) =>
              VectorEditorSettingBackgroundPCEventF.addColorSelectorNotEtcClickEventHandler({
                el,
                f: VectorEditorStickerSinglePCEventF.handleRightPanelHomeColorSelectorNotEtcClick({
                  tab_el: tab_el$,
                }),
              }),
            ),
            tap((el) =>
              VectorEditorSettingBackgroundPCEventF.addColorSelectorEtcClickEventHandler({
                el,
                ...VectorEditorStickerSinglePCEventF.handleRightPanelHomeColorSelectorEtcClick({
                  tab_el: tab_el$,
                }),
              }),
            ),
            tap((el) =>
              VectorEditorSettingBackgroundPCF.setColor({ el, color_code: tab_el$.__mp_background_color }),
            ),
          ),
        ),
      ),

      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.TOGGLE_LAYERS_MODE.ID}"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCEventF.handleTopMenuToggleLayerModeClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID}"]`,
        ),
        each(
          $on('click', VectorEditorStickerSinglePCEventF.handleTopMenuDuplicateClick({ tab_el: tab_el$ })),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID}"]`,
        ),
        each($on('click', VectorEditorStickerSinglePCEventF.handleTopMenuDeleteClick({ tab_el: tab_el$ }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD.ID}"]`,
        ),
        each($on('click', VectorEditorStickerSinglePCEventF.handleTopMenuForwardClick({ tab_el: tab_el$ }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD.ID}"]`,
        ),
        each($on('click', VectorEditorStickerSinglePCEventF.handleTopMenuBackwardClick({ tab_el: tab_el$ }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP.ID}"]`,
        ),
        each($on('click', VectorEditorStickerSinglePCEventF.handleTopMenuGroupClick({ tab_el: tab_el$ }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP.ID}"]`,
        ),
        each($on('click', VectorEditorStickerSinglePCEventF.handleTopMenuUngroupClick({ tab_el: tab_el$ }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL.ID}"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCEventF.handleTopMenuFlipHorizontalClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL.ID}"]`,
        ),
        each(
          $on('click', VectorEditorStickerSinglePCEventF.handleTopMenuFlipVerticalClick({ tab_el: tab_el$ })),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID}"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCEventF.handleTopMenuMoveToLeftEndClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID}"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCEventF.handleTopMenuMoveToHorizontalCenterClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID}"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCEventF.handleTopMenuMoveToRightEndClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END.ID}"]`,
        ),
        each(
          $on('click', VectorEditorStickerSinglePCEventF.handleTopMenuMoveToTopEndClick({ tab_el: tab_el$ })),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID}"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCEventF.handleTopMenuMoveToVerticalCenterClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID}"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCEventF.handleTopMenuMoveToBottomEndClick({ tab_el: tab_el$ }),
          ),
        ),
      ),

      tap(
        $findAll(`.left_container .layer_container .header .close_layer`),
        each($on('click', VectorEditorStickerSinglePCEventF.handleLayerCloseClick({ tab_el: tab_el$ }))),
      ),

      tap(
        $on(
          '@mp/sticker-editor/select',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorSelect({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/unselect',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorUnselect({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/add',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorAdd({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/remove',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorRemove({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/reorder',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorReorder({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/lock',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorLock({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/unlock',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorUnlock({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/show',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorShow({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/hide',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorHide({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/transform',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorTransform({ tab_el: tab_el$ }),
        ),
      ),

      tap(
        $on(
          '@mp/sticker-editor/attr-change',
          VectorEditorStickerSinglePCEventF.handleSingleStickerEditorAttrChange({ tab_el: tab_el$ }),
        ),
      ),
    );

    tab_el$.__fonts = await go(axios.get(`/${T.lang}/@api/maker/fonts`), sel('data'));
    await VectorEditorFontF.loadBaseFonts();
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    const single_sticker_editor = new EditorNS.SingleStickerEditorNS.PCNS.SingleStickerPCEditor({
      window,
      document,
    });
    single_sticker_editor.initDOM();
    single_sticker_editor.initEditor();
    single_sticker_editor.setBackground({
      type: 'color',
      fill: tab_el$.__mp_background_color,
      opacity: tab_el$.__mp_background_opacity,
    });
    single_sticker_editor.setArtBoardSize({
      width: tab_el$.__mp_art_board_size.width,
      height: tab_el$.__mp_art_board_size.height,
    });
    const foreground_el = (() => {
      const foreground_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
      if (tab_el$.__mp_foreground_el != null) {
        foreground_el.appendChild(tab_el$.__mp_foreground_el);
      }
      if (tab_el$.__mp_empty_template_el != null) {
        tab_el$.__mp_empty_template_el.removeAttributeNS(null, 'display');
        foreground_el.appendChild(tab_el$.__mp_empty_template_el);
      }
      return foreground_el;
    })();
    single_sticker_editor.setForeground(foreground_el);
    go(
      tab_el$,
      $findAll(`.editor_container`),
      takeL(1),
      each((el) => single_sticker_editor.appendTo(el)),
    );
    tab_el$.__mp_single_sticker_editor = single_sticker_editor;
    return tab_el$.__mp_preProcess?.(single_sticker_editor);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {
    $setCss(['top', `${tab_el$.__mp_frame_position_top}px`])(tab_el$);
    $setCss(['height', `${tab_el$.__mp_frame_position_height}px`])(tab_el$);

    tab_el$.__mp_single_sticker_editor?.fitSize();
    go(
      tab_el$,
      $findAll(`.top_menu_container`),
      mapL((el) => el.getBoundingClientRect().height),
      eachL((top_menu_height) => {
        tab_el$.__mp_editor_padding_top = 80 + top_menu_height;
      }),
      takeAll,
    );
    const view_box_vo = tab_el$.__mp_single_sticker_editor?.calculateViewBoxFitToArtBoard({
      padding_in_vcs: {
        top: tab_el$.__mp_editor_padding_top,
        bottom: tab_el$.__mp_editor_padding_bottom,
        left: tab_el$.__mp_editor_padding_left,
        right: tab_el$.__mp_editor_padding_right,
      },
    });
    if (view_box_vo != null) {
      tab_el$.__mp_single_sticker_editor?.setViewBox(view_box_vo);
    }

    if (tab_el$.__mp_is_go_to_next_page) {
      tab_el$.__mp_is_go_to_next_page = false;
      return;
    }

    return go(
      tab_el$,
      $findAll(`.right_container .right_wrapper`),
      takeL(1),
      each((right_wrapper_el) => {
        if (tab_el$.__mp_prev_right_panel != null) {
          return PCRightFixedPanelF.showPcRightFixedPanel({
            right_el: right_wrapper_el,
            left_el: tab_el$.__mp_prev_right_panel,
          });
        }
      }),
    );
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {
    tab_el$.__mp_single_sticker_editor?.selectEls?.([]);

    if (tab_el$.__mp_is_go_to_next_page) {
      return;
    }

    return go(
      tab_el$,
      $findAll(`.right_container .right_wrapper`),
      takeL(1),
      each((right_wrapper_el) => {
        if (tab_el$.__mp_prev_right_panel != null) {
          return PCRightFixedPanelF.hidePcRightFixedPanel({
            right_el: right_wrapper_el,
            left_el: tab_el$.__mp_prev_right_panel,
          });
        }
      }),
    );
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {
    tab_el$.__mp_title = '';
    tab_el$.__mp_price = '';
    tab_el$.__mp_is_need_cutting_line = false;
    tab_el$.__mp_can_set_background = true;
    tab_el$.__mp_background_color = 'none';
    tab_el$.__mp_background_opacity = 1;
    tab_el$.__mp_frame_position_top = 0;
    tab_el$.__mp_frame_position_height = 0;
    tab_el$.__mp_art_board_size = {
      width: 0,
      height: 0,
    };
    tab_el$.__mp_foreground_el = null;
    tab_el$.__mp_empty_template_el = null;
    tab_el$.__mp_prev_right_panel = null;
    tab_el$.__mp_preProcess = null;
    tab_el$.__mp_postProcess = null;
    tab_el$.__mp_is_go_to_next_page = false;

    tab_el$.__mp_single_sticker_editor?.destroy();
    tab_el$.__mp_single_sticker_editor = null;

    tab_el$.__mp_editor_padding_top = 0;
    tab_el$.__mp_editor_padding_right = 0;
    tab_el$.__mp_editor_padding_bottom = 0;
    tab_el$.__mp_editor_padding_left = 0;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
