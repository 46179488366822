import { go, map } from 'fxjs/es';

export function shallowClone(cv_obj, option) {
  if (cv_obj.type === 'image') {
    return new fabric.Image(cv_obj._element, option);
  } else if (cv_obj.type === 'group') {
    return new fabric.Group(
      go(
        cv_obj._objects,
        map((cv_obj) => shallowClone(cv_obj, cv_obj.toJSON())),
      ),
      option,
    );
  }
}
