import { SVGEditorMobileImageUploadF } from '../../../../../../SVGEditor/MobileImageUpload/F/Function/module/SVGEditorMobileImageUploadF.js';
import { VectorEditorStickerSingleMobileF } from '../Function/module/VectorEditorStickerSingleMobileF.js';
import { VectorEditorFreeShapeMobileF } from '../../../../../FreeShape/Mobile/F/Function/module/VectorEditorFreeShapeMobileF.js';

export const handleBottomMenuBlankPlateClick =
  ({ tab_el }) =>
  () => {
    VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
    VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
    VectorEditorStickerSingleMobileF.deactivateFooterAddIcon(tab_el);
  };

export const handleBottomMenuTextIconClick =
  ({ tab_el }) =>
  () => {
    VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
    VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
    VectorEditorStickerSingleMobileF.deactivateFooterAddIcon(tab_el);

    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.makeNewText(tab_el.__mp_single_sticker_editor);
  };

export const handleBottomMenuPathIconClick =
  ({ tab_el }) =>
  () => {
    VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
    VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
    VectorEditorStickerSingleMobileF.deactivateFooterAddIcon(tab_el);

    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.makeNewPath(tab_el.__mp_single_sticker_editor);
  };

export const handleBottomMenuShapeIconClick =
  ({ tab_el }) =>
  async () => {
    VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
    VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
    VectorEditorStickerSingleMobileF.deactivateFooterAddIcon(tab_el);

    tab_el.__mp_is_prevent_mouse_move = false;
    try {
      await VectorEditorFreeShapeMobileF.useFreeShape({
        postProcess: (el) => {
          if (tab_el.__mp_single_sticker_editor != null) {
            const single_sticker_editor = tab_el.__mp_single_sticker_editor;
            single_sticker_editor.addEl(el);
            single_sticker_editor.scaleWithinArtBoard({ el, scale: 0.4 });
            const els = [el];
            single_sticker_editor.alignToHorizontalCenter(els);
            single_sticker_editor.alignToVerticalCenter(els);
            single_sticker_editor.setStrokeWidth({ el, stroke_width: 0 });
            single_sticker_editor.setStrokeDasharray({ el, stroke_dasharray: [0, 0] });
            single_sticker_editor.selectEls(els);
          }
        },
      });
    } finally {
      tab_el.__mp_is_prevent_mouse_move = true;
    }
  };

export const handleBottomMenuImageIconClick =
  ({ tab_el }) =>
  async () => {
    VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
    VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
    VectorEditorStickerSingleMobileF.deactivateFooterAddIcon(tab_el);

    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }

    await SVGEditorMobileImageUploadF.uploadImage({
      postProcess: (el) => {
        tab_el?.__mp_single_sticker_editor?.addEl?.(el);
        tab_el?.__mp_single_sticker_editor?.scaleWithinArtBoard({ el, scale: 0.4 });
        const els = [el];
        tab_el?.__mp_single_sticker_editor?.alignToHorizontalCenter?.(els);
        tab_el?.__mp_single_sticker_editor?.alignToVerticalCenter?.(els);
        tab_el?.__mp_single_sticker_editor?.selectEls(els);
      },
    });
  };
