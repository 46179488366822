import { isValid, isMatch, parse } from 'date-fns';

/**
 * string 형식의 날짜를 Date 타입으로 변환한다.
 * @param {string} date_str
 * @param {string} format date-fns parse 함수의 dateFormat
 * @return {Date|null}
 */
export const parseStringifyDate = (date_str, format) => {
  // check invalid params
  if (!date_str || !format) {
    return null;
  }

  // trim string
  date_str = date_str.trim();
  if (!isMatch(date_str, format)) {
    return null;
  }

  const date = parse(date_str, format, new Date());
  return isValid(date) ? date : null;
};

/**
 * yyyyMMdd 형식의 string을 Date 타입으로 변환한다.
 * @param {string} date_str
 * @return {Date|null}
 */
export const parseStringifyDate_yyyyMMdd = (date_str) => {
  return parseStringifyDate(date_str, 'yyyyMMdd');
};

export const getKoreanDayOfWeek = (date = new Date()) => {
  const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex];
};

export const getFormattedLocalDate = (date = new Date(), joinStr = '') => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return [year, month, day].join(joinStr);
};
