import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { go, join, mapL, noop } from 'fxjs/es';
import { CommonNS, PathEditorNS } from '@marpple/sticker-editor';
import { getOffsetPathdataStr } from '@marpple/stickerizer';
import { applyCuttingLine } from './cutting_line.js';
import { VectorEditorAcrylicFigureFreeConstantS } from '../../../../S/Constant/module/VectorEditorAcrylicFigureFreeConstantS.js';
import { VectorEditorAcrylicFigureStandLegCreatorPCF } from '../../../../../StandLeg/CreatorPC/F/Function/module/VectorEditorAcrylicFigureStandLegCreatorPCF.js';
import { setSizeGuide } from './size.js';

export const handleRightPrevButtonClick = () => () => MuiF.popPage().catch(noop);

export const handleRightNextButtonClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_inner_cutting_line_update_timeout_id != null) {
      await $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::Free::CreatorPC::CuttingLineManual::error_message::waiting_cutting_line_update',
        ),
      ).catch(noop);
      return;
    }

    const makeTargetImageEl = tab_el.__mp_makeTargetImageEl;
    const postProcess = tab_el.__mp_postProcess;
    const editor = tab_el.__mp_acrylic_figure_cutting_line_editor;

    try {
      const outer_cutting_line_path_data = editor.getOuterCuttingLinePathData();
      const inner_cutting_line_path_data = editor.getInnerCuttingLinePathData();

      (() => {
        const svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
        svg_el.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns', 'http://www.w3.org/2000/svg');
        svg_el.setAttributeNS(null, 'viewBox', '0 0 0 0');

        const path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
        path_el.setAttributeNS(null, 'd', outer_cutting_line_path_data);
        path_el.setAttributeNS(null, 'fill', 'none');
        path_el.setAttributeNS(null, 'stroke', 'none');
        svg_el.appendChild(path_el);

        document.body.appendChild(svg_el);
        const bbox = path_el.getBBox();
        const width = bbox.width;
        const height = bbox.height;
        document.body.removeChild(svg_el);

        if (width > tab_el.__mp_art_board_width || height > tab_el.__mp_art_board_height) {
          const error = new Error(`Too large cutting line.`);
          error.__mp_alert_message = `아크릴 모양이 너무 큽니다. 아크릴 크기 안쪽으로 칼선을 만들어주세요.`;
          throw error;
        }
      })();

      await VectorEditorAcrylicFigureStandLegCreatorPCF.makeStandLeg({
        art_board: {
          width: tab_el.__mp_art_board_width,
          height: tab_el.__mp_art_board_height,
          shape_path_data: outer_cutting_line_path_data,
        },
        stand_leg: {
          ground: {
            size_delta: tab_el.__mp_stand_leg_ground_size_delta,
            position_delta: tab_el.__mp_stand_leg_ground_position_delta,
            min_width: tab_el.__mp_stand_leg_ground_min_width,
            max_width: tab_el.__mp_stand_leg_ground_max_width,
            width: tab_el.__mp_stand_leg_ground_width,
            height: tab_el.__mp_stand_leg_ground_height,
          },
          notch: {
            round: tab_el.__mp_stand_leg_notch_round,
            width: tab_el.__mp_stand_leg_notch_width,
            height: tab_el.__mp_stand_leg_notch_height,
          },
        },
        makeTargetImageEl: async () => {
          const target_image_el = await makeTargetImageEl({ factor: 1 });
          applyCuttingLine({ target_image_el, path_data: inner_cutting_line_path_data });
          return target_image_el;
        },
        postProcess: async ({
          cutting_line_path_data,
          stand_leg_position: { x: stand_leg_position_x, y: stand_leg_position_y },
        }) => {
          const cutting_line_result = (() => {
            try {
              const cutting_line_svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
              cutting_line_svg_el.setAttributeNS(
                'http://www.w3.org/2000/xmlns/',
                'xmlns',
                'http://www.w3.org/2000/svg',
              );
              cutting_line_svg_el.setAttributeNS(null, 'viewBox', '0 0 0 0');

              const cutting_line_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
              cutting_line_path_el.setAttributeNS(null, 'd', cutting_line_path_data);
              cutting_line_path_el.setAttributeNS(null, 'fill', 'none');
              cutting_line_path_el.setAttributeNS(null, 'stroke', '#FF0000');
              cutting_line_path_el.setAttributeNS(null, 'stroke-width', '0.001');
              cutting_line_svg_el.appendChild(cutting_line_path_el);

              document.body.appendChild(cutting_line_svg_el);
              const margin = 2;
              const bbox = cutting_line_path_el.getBBox();
              const view_box_x = Math.floor(bbox.x) - margin;
              const view_box_y = Math.floor(bbox.y) - margin;
              const view_box_width = Math.ceil(bbox.width) + margin * 2;
              const view_box_height = Math.ceil(bbox.height) + margin * 2;
              document.body.removeChild(cutting_line_svg_el);

              cutting_line_svg_el.setAttributeNS(
                null,
                'viewBox',
                go(
                  [view_box_x, view_box_y, view_box_width, view_box_height],
                  mapL((a) => `${a}`),
                  join(' '),
                ),
              );

              const stand_leg_position_ratio = {
                x: (stand_leg_position_x - view_box_x) / view_box_width,
                y: (stand_leg_position_y - view_box_y) / view_box_height,
              };

              return {
                success: true,
                value: {
                  cutting_line_svg_el,
                  stand_leg_position_ratio,
                  view_box: { x: view_box_x, y: view_box_y, width: view_box_width, height: view_box_height },
                },
              };
            } catch (error) {
              console.error(error);
              return {
                success: false,
                value:
                  error?.__mp_alert_message ??
                  T(
                    'modules::VectorEditor::AcrylicFigure::Free::CreatorPC::CuttingLineManual::error_message::cutting_line_calculation_error',
                  ),
              };
            }
          })();
          if (!cutting_line_result.success) {
            return cutting_line_result;
          }
          const { cutting_line_svg_el, stand_leg_position_ratio, view_box } = cutting_line_result.value;

          return postProcess({
            cutting_line_svg_el,
            stand_leg_position_ratio,
            view_box,
            makeTargetImageEl: async ({ factor }) => {
              const target_image_el = await makeTargetImageEl({ factor });
              applyCuttingLine({ target_image_el, path_data: inner_cutting_line_path_data });
              return target_image_el;
            },
          });
        },
      });
    } catch (error) {
      await $.alert(
        error.__mp_alert_message ??
          T(
            'modules::VectorEditor::AcrylicFigure::Free::CreatorPC::CuttingLineManual::error_message::cutting_line_making_error',
          ),
      );
      throw error;
    }
  };

export const handleRightResetIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_acrylic_figure_cutting_line_editor;

    const x = tab_el.__mp_ucs_x - 5;
    const y = tab_el.__mp_ucs_y - 5;
    const width = tab_el.__mp_ucs_width + 10;
    const height = tab_el.__mp_ucs_height + 10;

    editor.endEditCuttingLinePath();
    editor.setOuterCuttingLinePathData(
      `M ${x} ${y} L ${x + width} ${y} L ${x + width} ${y + height} L ${x} ${y + height} Z`,
    );
    editor.startEditCuttingLinePath();
  };

export const handleRightAddLIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_acrylic_figure_cutting_line_editor;

    const active_command = editor.getActiveCuttingLinePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    const next_command = editor.getNextCuttingLinePathCommand(active_command);
    if (next_command == null) {
      return;
    }
    const next_command_target_point = next_command.getTargetPoint();

    editor.insertCuttingLinePathEditorBefore({
      ref_command: next_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const command_l = new PathEditorNS.ModelNS.CommandL({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: (active_command_target_point.x + next_command_target_point.x) / 2,
            y: (active_command_target_point.y + next_command_target_point.y) / 2,
          }),
        });
        const command_l_controller = new PathEditorNS.ControllerNS.CommandLController({
          svg_el,
          command_l,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const command_l_view = new PathEditorNS.ViewNS.CommandLView({
          command_l_controller,
          target_point_view,
        });
        return {
          model: command_l,
          view: command_l_view,
          point_el_list: [target_point_view.getEl()],
          line_el_list: [],
        };
      },
    });
  };

export const handleRightAddCIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_acrylic_figure_cutting_line_editor;

    const active_command = editor.getActiveCuttingLinePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    const next_command = editor.getNextCuttingLinePathCommand(active_command);
    if (next_command == null) {
      return;
    }
    const next_command_target_point = next_command.getTargetPoint();

    editor.insertCuttingLinePathEditorBefore({
      ref_command: next_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const target_point_x = (active_command_target_point.x + next_command_target_point.x) / 2;
        const target_point_y = (active_command_target_point.y + next_command_target_point.y) / 2;
        const control_point1_x = (active_command_target_point.x * 2 + target_point_x) / 3;
        const control_point1_y =
          active_command_target_point.y < target_point_y
            ? active_command_target_point.y - Math.abs(active_command_target_point.y - target_point_y) / 2
            : active_command_target_point.y + Math.abs(active_command_target_point.y - target_point_y) / 2;
        const control_point2_x = (active_command_target_point.x + target_point_x * 2) / 3;
        const control_point2_y =
          active_command_target_point.y < target_point_y
            ? target_point_y + Math.abs(active_command_target_point.y - target_point_y) / 2
            : target_point_y - Math.abs(active_command_target_point.y - target_point_y) / 2;
        const command_c = new PathEditorNS.ModelNS.CommandC({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: target_point_x,
            y: target_point_y,
          }),
          control_point1: new PathEditorNS.ModelNS.Point({
            x: control_point1_x,
            y: control_point1_y,
          }),
          control_point2: new PathEditorNS.ModelNS.Point({
            x: control_point2_x,
            y: control_point2_y,
          }),
        });
        const command_c_controller = new PathEditorNS.ControllerNS.CommandCController({
          svg_el,
          command_c,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point1_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point2_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_line1_view = new PathEditorNS.ViewNS.LineView({
          document,
        });
        const control_line2_view = new PathEditorNS.ViewNS.LineView({
          document,
        });
        const command_c_view = new PathEditorNS.ViewNS.CommandCView({
          command_c_controller,
          target_point_view,
          control_point1_view,
          control_point2_view,
          control_line1_view,
          control_line2_view,
        });
        return {
          model: command_c,
          view: command_c_view,
          point_el_list: [
            control_point1_view.getEl(),
            control_point2_view.getEl(),
            target_point_view.getEl(),
          ],
          line_el_list: [control_line1_view.getEl(), control_line2_view.getEl()],
        };
      },
    });
  };

export const handleRightRemoveIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_acrylic_figure_cutting_line_editor;

    const active_command = editor.getActiveCuttingLinePathCommand();
    if (active_command == null) {
      return;
    }
    editor.removeCuttingLinePathCommand(active_command);
  };

export const handleRightChangeToLIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_acrylic_figure_cutting_line_editor;

    const active_command = editor.getActiveCuttingLinePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    editor.replaceCuttingLinePathEditorCommand({
      ref_command: active_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const command_l = new PathEditorNS.ModelNS.CommandL({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: active_command_target_point.x,
            y: active_command_target_point.y,
          }),
        });
        const command_l_controller = new PathEditorNS.ControllerNS.CommandLController({
          svg_el,
          command_l,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const command_l_view = new PathEditorNS.ViewNS.CommandLView({
          command_l_controller,
          target_point_view,
        });
        return {
          model: command_l,
          view: command_l_view,
          point_el_list: [target_point_view.getEl()],
          line_el_list: [],
        };
      },
    });
  };

export const handleRightChangeToCIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_acrylic_figure_cutting_line_editor;

    const active_command = editor.getActiveCuttingLinePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    const prev_command = editor.getPrevCuttingLinePathCommand(active_command);
    if (prev_command == null) {
      return;
    }
    const prev_command_target_point = prev_command.getTargetPoint();

    editor.replaceCuttingLinePathEditorCommand({
      ref_command: active_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const current_point_x = prev_command_target_point.x;
        const current_point_y = prev_command_target_point.y;
        const target_point_x = active_command_target_point.x;
        const target_point_y = active_command_target_point.y;
        const control_point1_x = (current_point_x * 2 + target_point_x) / 3;
        const control_point1_y =
          current_point_y < target_point_y
            ? current_point_y - Math.abs(current_point_y - target_point_y) / 2
            : current_point_y + Math.abs(current_point_y - target_point_y) / 2;
        const control_point2_x = (current_point_x + target_point_x * 2) / 3;
        const control_point2_y =
          current_point_y < target_point_y
            ? target_point_y + Math.abs(current_point_y - target_point_y) / 2
            : target_point_y - Math.abs(current_point_y - target_point_y) / 2;
        const command_c = new PathEditorNS.ModelNS.CommandC({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: target_point_x,
            y: target_point_y,
          }),
          control_point1: new PathEditorNS.ModelNS.Point({
            x: control_point1_x,
            y: control_point1_y,
          }),
          control_point2: new PathEditorNS.ModelNS.Point({
            x: control_point2_x,
            y: control_point2_y,
          }),
        });
        const command_c_controller = new PathEditorNS.ControllerNS.CommandCController({
          svg_el,
          command_c,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point1_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point2_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_line1_view = new PathEditorNS.ViewNS.LineView({
          document,
        });
        const control_line2_view = new PathEditorNS.ViewNS.LineView({
          document,
        });
        const command_c_view = new PathEditorNS.ViewNS.CommandCView({
          command_c_controller,
          target_point_view,
          control_point1_view,
          control_point2_view,
          control_line1_view,
          control_line2_view,
        });
        return {
          model: command_c,
          view: command_c_view,
          point_el_list: [
            control_point1_view.getEl(),
            control_point2_view.getEl(),
            target_point_view.getEl(),
          ],
          line_el_list: [control_line1_view.getEl(), control_line2_view.getEl()],
        };
      },
    });
  };

export const handleEditorOuterCuttingLineChanged =
  ({ tab_el }) =>
  (event) => {
    const outer_cutting_line_path_data = event?.detail?.path_data;
    const editor = tab_el.__mp_acrylic_figure_cutting_line_editor;

    setSizeGuide({ tab_el, editor });

    if (tab_el.__mp_inner_cutting_line_update_timeout_id != null) {
      clearTimeout(tab_el.__mp_inner_cutting_line_update_timeout_id);
    }

    tab_el.__mp_inner_cutting_line_update_timeout_id = setTimeout(async () => {
      try {
        tab_el.__mp_inner_cutting_line_update_timeout_id = null;
        const inner_cutting_line_path_data = await getOffsetPathdataStr(
          outer_cutting_line_path_data,
          -VectorEditorAcrylicFigureFreeConstantS.SAFE_MARGIN_MM,
        );
        editor.setInnerCuttingLinePathData(inner_cutting_line_path_data);
      } catch (error) {
        console.error(error);
        $.alert(
          error?.__mp_alert_message ??
            T(
              'modules::VectorEditor::AcrylicFigure::Free::CreatorPC::CuttingLineManual::error_message::inner_cutting_line_making_error',
            ),
        ).then(() => MuiF.popPage());
      }
    }, 200);
  };
