import { html, isEmpty, strMap } from 'fxjs/es';
import { OMPSearchAutoCompleteS } from '../Function/module/OMPSearchAutoCompleteS.js';

/**
 * @typedef {object} AutoCompleteItem
 * @property {string} id 검색 결과의 id
 * @property {string} text 검색 결과의 text label
 * @property {string} query 검색어
 */

/**
 * @param {AutoCompleteItem[]} items
 */
export const autoCompleteTmpl = (items) => {
  return html`
    <ul
      class="omp-search__auto-complete-items ${isEmpty(items)
        ? 'omp-search__auto-complete-items--hidden'
        : ''}"
    >
      ${autoCompleteItemsTmpl(items)}
    </ul>
  `;
};

export const emptyAutoCompleteTmpl = () => {
  return autoCompleteTmpl([]);
};

/**
 * `omp-search__auto-complete-item` 태그의 배열
 * `omp-search__auto-complete-items`은 포함되지 않으므로 주의한다.
 * @param {AutoCompleteItem[]} items
 */
const autoCompleteItemsTmpl = (items) => {
  if (!items?.length) return '';

  return strMap((item) => {
    /** @type {ExtractedKeywordAndSurroundings} */
    const keyword = OMPSearchAutoCompleteS.extractKeywordAndSurroundingsInText(item.query, item.text);
    if (!keyword.is_matched) {
      return '';
    }
    return html`
      <li
        class="omp-search__auto-complete-item"
        data-id="${item.id}"
        data-text="${item.text}"
        data-query="${item.query}"
      >
        <span class="omp-search__auto-complete-item__text">${keyword.prefix}</span
        ><span class="omp-search__auto-complete-item__text--pointed">${keyword.extracted}</span
        ><span class="omp-search__auto-complete-item__text">${keyword.suffix}</span>
      </li>
    `;
  })(items);
};
