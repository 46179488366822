import { DfCateListThumbnailModalMuiS } from '../../S/Mui/module/DfCateListThumbnailModalMuiS.js';
import { go } from 'fxjs/es';
import { $attr, $closest, $delegate, $find, $setAttr } from 'fxdom/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...DfCateListThumbnailModalMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    go(
      tab_el,
      $delegate('change', 'input[type="file"]', (e) => {
        $.don_loader_start();

        go(
          $.upload(e.currentTarget, {
            url: '/@fileUpload/file',
            data: {
              attached_type: $attr('attached_type', e.currentTarget),
              attached_id: $attr('attached_id', e.currentTarget),
            },
          }),
          ({ url }) => {
            $.don_loader_end();
            go(e.currentTarget, $closest('.img'), $find('img'), $setAttr({ src: url }));
          },
        ).catch(() => $.don_loader_end());
      }),
      $delegate('click', '.save-btn', async (e) => {
        if (await $.confirm('저장하시겠습니까?')) {
          const url = go(e.delegateTarget, $find('.img img'), $attr('src'));
          MuiF.closeFrame(url);
        }
      }),
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
