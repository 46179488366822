import { getProductColorInMaker } from '../../../../Maker/F/getSth.js';
import { onbeforeunload_event_off } from '../../../../Maker/F/util.js';
import {
  initAfterPlacingProductFaces,
  renderNewFacesByCurrentBpcfsInMakerA,
  getTempLocalStorageProduct,
  removeAllTempLocalStorage,
} from '../../../../Maker/F/mp_maker.js';
import { go } from 'fxjs/es';
import { NewMakerUtilF } from '../../../Util/F/Function/module/NewMakerUtilF.js';

export const service = {
  /* 상품이 처음 세팅 될때 하는 세팅, canvas_editor 자체에 id를 부여 */
  init: (base_product) => {
    box.set('maker->canvas_editor', {
      id: `${base_product.id}_${new Date().getTime()}`,
    });
  },
  get: () => {
    return box.sel('maker->canvas_editor');
  },
  resetAndReload: async () => {
    if (G.collabo_type !== '') return $.alert('해당 서비스에서는 동작하지 않는 기능입니다.');
    getTempLocalStorageProduct();
    removeAllTempLocalStorage();
    onbeforeunload_event_off();
    $.don_loader_start();
    window.location.href = window.location.href = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?bp_id=${getProductColorInMaker().base_product_id}`;
    setTimeout(() => {
      $.don_loader_end();
    }, 5000);
  },
  reset: () => {
    return go(
      renderNewFacesByCurrentBpcfsInMakerA(true),
      () => initAfterPlacingProductFaces(),
      () => {
        NewMakerUtilF.setDataUploadTypeIsDesigned();
        removeAllTempLocalStorage();
      },
    );
  },
  // testGet: () => {
  //   console.log(CanvasEditor.get()?.id);
  //   setInterval(() => {
  //     console.log(CanvasEditor.get()?.id);
  //   }, 3000);
  // },
};
