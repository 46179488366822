import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerGridCreatorPCCopyGridMuiF } from '../Mui/module/VectorEditorStickerGridCreatorPCCopyGridMuiF.js';

export const copyGrid = ({
  frame_position: {
    top: frame_position_top,
    left: frame_position_left,
    width: frame_position_width,
    height: frame_position_height,
  },
  grids,
  grid_sticker_editor,
  prev_frame_right_panel,
}) =>
  MuiF.openFrame(VectorEditorStickerGridCreatorPCCopyGridMuiF.frame, (frame, page, [tab]) => {
    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_prev_frame_right_panel_el = prev_frame_right_panel;
      tab_el.__mp_frame_position_top = frame_position_top;
      tab_el.__mp_frame_position_left = frame_position_left;
      tab_el.__mp_frame_position_width = frame_position_width;
      tab_el.__mp_frame_position_height = frame_position_height;
      tab_el.__mp_grids = grids;
      tab_el.__mp_grid_sticker_editor = grid_sticker_editor;
      return tab_appending.call(tab, tab_el);
    };
  });
