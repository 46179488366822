import { makeImageFromUrl } from '../../../../../Maker/F/util.js';
import {
  createCanvasElement,
  hexToRGB,
  makeOutlineCanvas2,
  sourceOverCanvas,
} from '../../../../../Canvas/S/util.js';
import { getBpfOfFcanvas } from '../../../../../Maker/F/getSth.js';
import { NewMakerPropertyBpfConstantS } from '../../S/Constant/module/NewMakerPropertyBpfConstantS.js';
import { find, go, values } from 'fxjs/es';
import { addCvPreview, removeCvPreview } from '../../../../../Maker/F/CvPreview/cv_object.js';
import { getCurrentFcanvas } from '../../../../../Maker/F/Fcanvas/cv_object.js';

const makeGradientCanvas = ({ width, height, color_codes }) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
  const [a, b] = color_codes;
  gradient.addColorStop(0, a);
  gradient.addColorStop(0.1, b);
  gradient.addColorStop(0.6, a);
  gradient.addColorStop(0.7, b);
  gradient.addColorStop(0.8, a);
  gradient.addColorStop(0.9, b);
  gradient.addColorStop(1, a);

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  return canvas;
};

const makePatternCanvas = async ({ width, height, pattern_url, px_per_1cm }) => {
  const pattern_img = await makeImageFromUrl(pattern_url);
  const pattern_cm_width = (pattern_img.width / 300) * 2.54;
  const pattern_cm_height = (pattern_img.height / 300) * 2.54;
  const pattern_canvas = document.createElement('canvas');
  pattern_canvas.width = Math.round(pattern_cm_width * px_per_1cm);
  pattern_canvas.height = Math.round(pattern_cm_height * px_per_1cm);
  const pattern_ctx = pattern_canvas.getContext('2d');
  pattern_ctx.drawImage(
    pattern_img,
    0,
    0,
    pattern_img.width,
    pattern_img.height,
    0,
    0,
    pattern_canvas.width,
    pattern_canvas.height,
  );
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const pattern = ctx.createPattern(pattern_canvas, 'repeat');
  ctx.fillStyle = pattern;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  // return canvas;
  const black_gradient = makeGradientCanvas({
    width,
    height,
    color_codes: ['rgb(0,0,0)', 'rgb(11,11,11)', 'rgb(0,0,0)'],
  });
  const black_gradient_ctx = black_gradient.getContext('2d');
  // black_gradient_ctx.globalCompositeOperation = 'lighten';
  black_gradient_ctx.globalCompositeOperation = 'lighter';
  black_gradient_ctx.drawImage(canvas, 0, 0);
  return black_gradient;
};

const makeResizedCanvas = async ({ url, width, height }) => {
  const img = await makeImageFromUrl(url);
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
  return canvas;
};
export const foilEffects = {
  getIt: (fcanvas, cv_obj) => {
    const bpf = getBpfOfFcanvas(fcanvas);
    const foil_effects = bpf?.preview?.[NewMakerPropertyBpfConstantS.FOIL_EFFECTS];
    if (!foil_effects?.length) return;
    const color_code = cv_obj?._data?.dosu_color;
    if (!color_code) return;
    const foil_effect = go(
      foil_effects,
      find((foil_effect) => foil_effect.hex.toUpperCase() === color_code.toUpperCase()),
    );
    return foil_effect;
  },
  getMyShadeMaterial: (shade_material) => {
    if (!shade_material) return;
    if (shade_material.name !== NewMakerPropertyBpfConstantS.FOIL_EFFECTS) return;
    return shade_material;
  },
  /**
   * @param {Object} foil_effect
   * @param {string=} foil_effect.pattern_dpi300_url
   * @param {string=} foil_effect.gradient_color_codes
   * @param {string=} foil_effect.gradient_1000px_square_url
   * @param {string} foil_effect.hex
   * @param {Object} size_info
   * @param {number} size_info.width
   * @param {number} size_info.height
   * @param {number} size_info.px_per_1cm
   * @return {HTMLCanvasElement}
   */
  makeCanvas: async (foil_effect, size_info) => {
    const { width, height, px_per_1cm } = size_info;

    if (foil_effect.pattern_dpi300_url) {
      const canvas = await makePatternCanvas({
        width,
        height,
        pattern_url: foil_effect.pattern_dpi300_url,
        px_per_1cm,
      });
      return canvas;
    }
    if (foil_effect.gradient_1000px_square_url) {
      const canvas = await makeResizedCanvas({
        width,
        height,
        url: foil_effect.gradient_1000px_square_url,
      });
      return canvas;
    }
    return makeGradientCanvas({ width, height, color_codes: foil_effect.gradient_color_codes });
  },
  isShade: (foil_effect) => {
    return foil_effect?.shade_effect;
  },
  isItself: (foil_effect) => {
    return foil_effect?.is_itself;
  },
  makeShadeResultCanvas: async (canvas, { color_code, deep_px }) => {
    const shade_strength = 50;
    if (color_code?.toLowerCase() === '#ffffff') color_code = '#d8d8d8';
    const rgba = color_code ? go(color_code, hexToRGB, values, (arr) => arr.concat([255])) : null;

    const shade_canvas = makeOutlineCanvas2(canvas, deep_px, shade_strength, rgba);
    return sourceOverCanvas(
      rgba ? createCanvasElement({ width: canvas.width, height: canvas.height }) : canvas,
      shade_canvas,
    );
  },
  test: async (rgba, shade_strength) => {
    G.__rgba = rgba;
    G.__shade_strength = shade_strength;
    const fcanvas = getCurrentFcanvas();
    await removeCvPreview(fcanvas, true, true);
    await addCvPreview(fcanvas);
  },
};

const excample = {
  FOIL_EFFECTS: [
    {
      hex: '#d3aa4a',
      name: '금박',
      gradient_color_codes: ['#bd8923', '#e4c367'],
      shade_effect: {
        shade_strength: 50,
      },
    },
    {
      hex: '#9e9e9e',
      name: '은박',
      gradient_color_codes: ['#949494', '#b5b5b5'],
      shade_effect: {
        shade_strength: 50,
      },
    },
    {
      hex: '#ffffff',
      name: '불박(음영)',
      gradient_color_codes: ['#ffffff', '#ffffff'],
      shade_effect: {
        shade_strength: 50,
      },
      is_itself: true,
    },
  ],
  ADDITIONAL_RENDER: {},
};
