import axios from 'axios';
import { $on } from 'fxdom/es';
import { defaults, delay, equals2, go } from 'fxjs/es';
import { getProductColorInMaker } from '../../../../Maker/F/getSth.js';
import { MakerDebugF } from '../../../../MakerDebug/F/Function/module/MakerDebugF.js';
import { MakerDebugConstantS } from '../../../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import { NewMakerImageF } from '../../../../NewMaker/Image/F/Function/module/NewMakerImageF.js';
import { NewMakerUtilF } from '../../../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { SVGEditorMobileImageUploadF } from '../../../MobileImageUpload/F/Function/module/SVGEditorMobileImageUploadF.js';
import { SVGEditorUploadImageF } from './module/SVGEditorUploadImageF.js';
import { VectorEditorConstantS } from '../../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';

export const uploadSvg = async (svg_file) => {
  const formData = new FormData();
  formData.append('files[]', svg_file);
  return go(
    $.upload(formData, {
      url: '/@fileUpload/svg_images_images/svg_upload',
      data: {},
    }),
    (result) => {
      return result;
    },
  );
};

export const uploadPng = async (png_file) => {
  const formData = new FormData();
  formData.append('files[]', png_file);
  return go(
    $.upload(formData, {
      url: '/@fileUpload/maker/add_img',
      data: { type: 'svg' },
    }),
    (result) => {
      return result;
    },
  );
};

export const uploadSvgFilePngFileToImages = ({ svg_file, png_file }) => {
  return go(
    Promise.all([uploadSvg(svg_file), uploadPng(png_file)]),
    async ([{ svg_image_id, url }, { result, image }]) => {
      if (!result || !svg_image_id) {
        return $.post('/@api/svg_images_images/svg_upload_fail', { svg_image_id, image_id: image.id });
      }
      await $.post('/@api/svg_images_images/svg_upload_success', { svg_image_id, image_id: image.id });
      return { image, svg_image: { id: svg_image_id, url } };
    },
  );
};
export const uploadSvgProductMaterialFiles = async ({
  original_svg_file_url,
  printable_file,
  original_cutting_line_svg_file,
}) => {
  if (!original_svg_file_url) throw Error('original_svg_file_url 없습니다.');
  const formData = new FormData();
  formData.append('printable_file', await printable_file);
  formData.append('original_cutting_line_svg_file', await original_cutting_line_svg_file);
  return defaults(
    { original_svg_file_url },
    await $.upload(formData, {
      url: '/@fileUpload/svg_images_images/upload_svg_product_materials',
      data: {},
    }),
  );
};

export const uploadImageFromInput = async (input_el) => {
  if (input_el.files.length <= 0) {
    input_el.value = '';
    return;
  }

  const file = input_el.files.item(0);
  if (file == null) {
    input_el.value = '';
    return;
  }

  if (file.size > 26214400) {
    input_el.value = '';
    await $.alert(T(`maker_error_alert::too_large_file`, { mb: 25 }));
    return;
  }

  if (equals2(file.type)('image/svg+xml')) {
    try {
      const { svg_file, png_file } = await SVGEditorMobileImageUploadF.processSVGFile(file);
      const { svg_image } = await SVGEditorUploadImageF.uploadSvgFilePngFileToImages({
        svg_file,
        png_file,
      });
      const res = await axios.get(svg_image.url);
      const svg_str = res.data;
      return await SVGEditorMobileImageUploadF.convertSVGStrToEl(svg_str);
    } catch (error) {
      if (NewMakerUtilF.check.isApp() && !error?.__mp_alert_message) {
        $.alert(T('maker_error_alert::사진 권한 엑세스'));
        return;
      }
      await $.alert(
        error?.__mp_alert_message ??
          error?.message ??
          T(`maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.`),
      );
      if (!error?.__mp_alert_message) {
        MakerDebugF.api.MakerDebugPost(
          getProductColorInMaker(),
          error,
          MakerDebugConstantS.Category.SvgEditorImageUpload,
          'svg',
        );
      }
      return;
    } finally {
      input_el.value = '';
    }
  }

  if (new Set(['image/jpeg', 'image/png']).has(file.type)) {
    try {
      const valiation_result = await NewMakerImageF.validateImageSize(file);
      if (!valiation_result?.result) {
        if (valiation_result.message) $.alert(valiation_result.message);
        return;
      }

      const res = await $.upload(input_el, {
        url: '/@fileUpload/maker/add_img',
        data: {},
      });
      if (res?.result === false) {
        await $.alert(res?.msg ?? T(`maker_error_alert::fail_upload_img`));
        return;
      }
      const url = res?.image?.url;
      if (url == null) {
        await $.alert(`업로드한 url 을 찾을 수 없습니다.`);
        return;
      }
      return await SVGEditorMobileImageUploadF.convertURLToEl(G.to_900(url));
    } catch (error) {
      if (NewMakerUtilF.check.isApp() && !error?.__mp_alert_message) {
        $.alert(T('maker_error_alert::사진 권한 엑세스'));
        return;
      }
      await $.alert(
        error?.__mp_alert_message ?? T(`maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.`),
      );
      if (!error?.__mp_alert_message) {
        MakerDebugF.api.MakerDebugPost(
          getProductColorInMaker(),
          error,
          MakerDebugConstantS.Category.SvgEditorImageUpload,
          'jpeg_png',
        );
      }
      return;
    } finally {
      input_el.value = '';
    }
  }
};

export const uploadImage = ({ postProcess }) => {
  const input_el = document.createElement('input');
  input_el.type = 'file';
  input_el.accept = 'image/svg+xml,image/png,image/jpeg';
  input_el.click();
  $on('change', async ({ currentTarget: input_el }) => {
    $.don_loader_start();
    try {
      const el = await Promise.race([uploadImageFromInput(input_el), delay(20000)({ is_time_out: true })]);
      if (el?.is_time_out) {
        $.don_loader_end();
        return $.alert(T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.'));
      }
      if (el) {
        await postProcess(el);
      }
    } catch (err) {
      if (err?.__mp_alert_message) {
        $.alert(err?.__mp_alert_message);
        return;
      }
      $.alert(T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.'));
      MakerDebugF.api.MakerDebugPost(
        getProductColorInMaker(),
        err,
        MakerDebugConstantS.Category.ImageUpload,
        'svg_editor_pc_upload',
      );
    } finally {
      $.don_loader_end();
    }
  })(input_el);
};

const addDataImageIdToSvgImageEl = (svg_image_el, image_id) => {
  const is_image_el = svg_image_el.tagName === 'image';
  if (is_image_el == null || image_id == null) return;

  const dataKey = `${VectorEditorConstantS.VECTOR_EL_DATA_PREFIX}image_id`;

  svg_image_el.setAttribute(dataKey, image_id);
};

export const openPcMyLibrary = async ({ postProcess }) =>
  $.frame.open(
    {
      is_modal: true,
      frame_name: 'maker.my_lib',
      page_name: 'maker_my_lib_page',
      loader: true,
      appending: (frame_el) => {
        $.attr('is_fixed_head', 'true')(frame_el);
      },
      closing: async function (don_frame, image) {
        if (!image) return;
        $.don_loader_start();
        try {
          let svg_el;
          if (image.type === 'svg') {
            const url = await SVGEditorUploadImageF.makeImageIdToSvgUrl(image.id);
            const res = await axios.get(url);
            const svg_str = res.data;
            svg_el = await SVGEditorMobileImageUploadF.convertSVGStrToEl(svg_str);
          } else {
            svg_el = await SVGEditorMobileImageUploadF.convertURLToEl(G.to_900(image.url));
          }

          addDataImageIdToSvgImageEl(svg_el, image.id);

          await postProcess(svg_el);
        } catch (error) {
          console.error(error);
          await $.alert(error?.__mp_alert_message ?? '이미지를 그리는 데 실패했습니다.');
        } finally {
          $.don_loader_end();
        }
      },
    },
    {
      page_name: 'maker_my_lib_page',
      hide_frame_button_type: 'X',
    },
  );

export const embedImageInSvgEditor = async (tab_el, image) => {
  const svg_el = await SVGEditorMobileImageUploadF.convertURLToEl(G.to_900(image.url));
  addDataImageIdToSvgImageEl(svg_el, image.id);
  const editor =
    tab_el.__mp_acrylic_figure_editor || tab_el.__mp_keyring_editor || tab_el.__mp_single_sticker_editor;
  editor.addEl(svg_el);
  editor.scaleWithinArtBoard({ el: svg_el, scale: 0.4 });
  const els = [svg_el];
  editor.alignToHorizontalCenter(els);
  editor.alignToVerticalCenter(els);
  editor.selectEls(els);
};
