import {
  $addClass,
  $after,
  $append,
  $appendTo,
  $closest,
  $data,
  $el,
  $find,
  $findAll,
  $hasClass,
  $remove,
  $removeClass,
  $replaceWith,
  $setData,
  $setHTML,
  $val,
} from 'fxdom/es';
import {
  entries,
  html,
  keys,
  filter,
  last,
  omit,
  each,
  go,
  map,
  extend,
  omitBy,
  mapC,
  indexBy,
  reject,
  flat,
  sel,
} from 'fxjs/es';
import { createCanvasElement } from '../../../../../Canvas/S/util.js';
import { drawProductFace } from '../../../../../Maker/F/draw_product_faces.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { apiCalls } from '../../../F/Function/api.calls.js';
import {
  MESSAGES,
  PACKAGING_QUEUE_TABLE_COLUMN,
  PACKAGING_TABLE_COLUMN,
  REBOUND_PACKAGING_TABLE_COLUMN,
  REBOUND_QUEUE_TABLE_COLUMN,
} from '../../../S/Constant/lgl.js';
import { makeTableRowHtml } from '../../S/Tmpl/tmpl.table.js';
import {
  makePackagingTabActionButtonHtml,
  makePackagingTabFooterHtml,
  makeReboundTabActionButtonHtml,
} from '../../S/Tmpl/tmpl.tabs.js';
import { getPackagingFooterStatus } from './status.js';
import { unCheckedAllCheckboxes } from './table.event.js';

function isCanvasBlank(canvas) {
  const context = canvas.getContext('2d');

  const pixelBuffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);

  return !pixelBuffer.some((color) => color !== 0);
}

export function loadProductsImage(el) {
  go(
    el,
    $findAll('.thumb canvas'),
    each((canvas_el) => {
      if (isCanvasBlank(canvas_el)) {
        const { product_face } = $data(canvas_el);
        canvas_el.width = 60;
        canvas_el.height = 60;
        drawProductFace(canvas_el, product_face);
      }
    }),
  );
}

function updateProductsData({ items, from_table, to_table, packing = true, always_new = false, rebound }) {
  return go(
    items,
    map((item) => {
      const from_data = $data(from_table);
      const from_row_el = go(item, $closest('tr'));
      const from_row_data = $data(from_row_el);

      const { upcs_id, lgl_item_id } = from_row_data;
      const move_quantity = go(from_row_el, $find('input[type="number"]'), $val, parseInt);
      const updated_from_row_data = {
        ...from_row_data,
        left_quantity: from_row_data.left_quantity - move_quantity,
        loaded_quantity: from_row_data.loaded_quantity - move_quantity,
      };

      let updated_from_data = extend(from_data, {
        [rebound ? lgl_item_id : upcs_id]: updated_from_row_data,
      });

      if (packing) {
        if (updated_from_row_data.left_quantity === 0) {
          updated_from_data = go(updated_from_data, omit([`${rebound ? lgl_item_id : upcs_id}`]));
        }
      } else {
        if (updated_from_row_data.loaded_quantity === 0) {
          updated_from_data = go(updated_from_data, omit([`${rebound ? lgl_item_id : upcs_id}`]));
        }
      }

      const to_row_el = always_new
        ? null
        : go(
            to_table,
            $findAll('tbody > tr'),
            filter((tr) => !$hasClass('no_data', tr)),
            filter((tr) => {
              const row_data = $data(tr);
              return row_data.upcs_id === upcs_id;
            }),
            last,
          );

      const to_data = $data(to_table);
      const updated_to_data = extend(to_data);
      let to_row_data = null;
      let updated_to_row_data = null;

      if (to_row_el) {
        to_row_data = $data(to_row_el);

        updated_to_row_data = {
          ...to_row_data,
          loaded_quantity: to_row_data.loaded_quantity + move_quantity,
          left_quantity: to_row_data.left_quantity + move_quantity,
        };

        extend(updated_to_data, { [upcs_id]: updated_to_row_data });
      }

      if (!packing) {
        if (!to_row_el) {
          updated_to_row_data = {
            ...from_row_data,
            lgl_item_id: from_row_data.rebounded_lgl_item_id || from_row_data.lgl_item_id,
            left_quantity: move_quantity,
          };
        }
      }

      return {
        from_row_el,
        from_row_data,
        updated_from_row_data,
        updated_from_data,
        to_row_el,
        to_row_data,
        updated_to_row_data,
        updated_to_data,
        move_quantity,
      };
    }),
  );
}

/**
 *  @description pf2 정보 -> 썸네일 캔버스 생성 -> 파일 업로드 -> URL 반환
 *  @param {number} size - 썸네일 캔버스 사이즈
 *  @param {object} pf2 - 상품의 pf2 데이터
 *  @return {Promise<string[]>} urls
 * */
async function getThumbnailUrlsFromPf2s({ size = 800, product_faces2 }) {
  if (product_faces2 == null) throw new Error(`상품 그리기 정보 pf2 가 존재하지 않습니다.`);
  return go(
    product_faces2.value,
    map(async (pf2) => {
      return await drawProductFace(
        await createCanvasElement({ width: size, height: size }),
        pf2,
        false,
        undefined,
        true,
        false,
        false,
      );
    }),
    map(async (canvas) => {
      return `https:${await go($.uploadFileToUrl(canvas.toDataURL()), sel('url'))}`;
    }),
  );
}

async function createOrUpdateProductsQuantity({ items, lgl_inbound_order_id, packing = true, rebound }) {
  return go(
    items,
    mapC(
      async ({
        from_row_el,
        from_row_data,
        updated_from_data,
        updated_from_row_data,
        to_row_data,
        to_row_el,
        updated_to_data,
        move_quantity,
        updated_to_row_data,
      }) => {
        const { upcs_id, goods_type_id } = from_row_data;
        const { lgl_item_id, new_lgl_item_id } = updated_from_row_data;

        // 상품을 운송장에 담는 경우
        if (packing) {
          // 이미 존재하는 상품
          if (to_row_data) {
            const { lgl_item_id } = to_row_data;

            updated_to_row_data = {
              ...to_row_data,
              loaded_quantity: to_row_data.loaded_quantity + move_quantity,
              left_quantity: to_row_data.left_quantity + move_quantity,
            };

            await apiCalls.put.item.changeLglItemQuantity({
              lgl_item_id,
              type: 'inbound',
              action: 'requested',
              quantity: packing ? updated_to_row_data.loaded_quantity : updated_from_row_data - move_quantity,
            });

            extend(updated_to_data, { [upcs_id]: updated_to_row_data });
          } else {
            // 새 데이터 추가
            if (rebound) {
              // 재입고시 기존 lgl_item_id 연결
              const { lgl_item_id: new_lgl_item_id } = await apiCalls.post.rebound.createLglItem({
                rebound_lgl_inbound_order_id: lgl_inbound_order_id,
                rebounded_lgl_item_id: lgl_item_id,
              });

              updated_to_row_data = {
                ...from_row_data,
                loaded_quantity: move_quantity,
                lgl_item_id,
                new_lgl_item_id,
              };
            } else {
              const { sku } = await apiCalls.get.item.sku({ user_product_id: upcs_id });
              const already_has_thumbnail = sku?.thumbnail_urls && sku?.thumbnail_urls.length > 0;
              const MP_OR_MPS = ['MP', 'MPS'].includes(goods_type_id);
              const thumbnail_urls = already_has_thumbnail
                ? null
                : MP_OR_MPS
                ? await getThumbnailUrlsFromPf2s({
                    product_faces2: from_row_data.product_faces2,
                  })
                : from_row_data.thumbnail_urls;

              const { lgl_item_id } = await apiCalls.post.item.createLglItem({
                user_product_id: upcs_id,
                lgl_inbound_order_id,
                inbound_order_requested_quantity: move_quantity,
                thumbnail_urls,
              });

              updated_to_row_data = {
                ...from_row_data,
                loaded_quantity: move_quantity,
                lgl_item_id,
              };
            }
            extend(updated_to_data, { [rebound ? lgl_item_id : upcs_id]: updated_to_row_data });
          }
        } else {
          // 상품을 운송장에서 제거하는 경우
          if (!updated_from_data[rebound ? lgl_item_id : upcs_id]) {
            // && 남은 수량이 없는 경우 -> 삭제
            if (rebound) {
              await apiCalls.delete.rebound.lglItem({ lgl_item_id: new_lgl_item_id || lgl_item_id });
            } else {
              await apiCalls.delete.item.lglItem({ lgl_item_id });
            }
          } else {
            await apiCalls.put.item.changeLglItemQuantity({
              lgl_item_id,
              type: 'inbound',
              action: 'requested',
              quantity: updated_from_row_data.loaded_quantity,
            });
          }
        }

        return {
          from_row_el,
          updated_from_data,
          updated_from_row_data,
          to_row_el,
          updated_to_data,
          updated_to_row_data,
          exist_in_to_table: !UtilS.isEmpty(to_row_data),
        };
      },
    ),
  );
}

function renderProducts({ items, to_table, from_table, wrapper, packing = true, rebound }) {
  go(
    items,
    map(
      ({
        from_row_el,
        updated_from_row_data,
        to_row_el,
        updated_to_data,
        updated_to_row_data,
        exist_in_to_table,
      }) => {
        const { rebounded_lgl_item_id, lgl_item_id, upcs_id } = updated_from_row_data;

        if (exist_in_to_table) {
          $replaceWith(
            $el(
              makeTableRowHtml({
                row: updated_to_row_data,
                columns: packing ? PACKAGING_TABLE_COLUMN : PACKAGING_QUEUE_TABLE_COLUMN,
              }),
            ),
            to_row_el,
          );
        } else {
          const to_table_body = go(to_table, $find('tbody'));
          const no_data_row = go(to_table_body, $find('tr.no_data'));
          if (no_data_row) {
            $remove(no_data_row);
          }

          const new_row = $el(
            makeTableRowHtml({
              row: updated_to_row_data,
              columns: packing
                ? rebound
                  ? REBOUND_PACKAGING_TABLE_COLUMN
                  : PACKAGING_TABLE_COLUMN
                : rebound
                ? REBOUND_QUEUE_TABLE_COLUMN
                : PACKAGING_QUEUE_TABLE_COLUMN,
            }),
          );

          $appendTo(to_table_body, new_row);
        }

        extend(updated_to_data, {
          [rebound ? rebounded_lgl_item_id || lgl_item_id : upcs_id]: updated_to_row_data,
        });
        if (packing) {
          if (updated_from_row_data.left_quantity === 0) {
            $remove(from_row_el);
          } else {
            $replaceWith(
              $el(makeTableRowHtml({ row: updated_from_row_data, columns: PACKAGING_QUEUE_TABLE_COLUMN })),
              from_row_el,
            );
          }
        } else {
          if (updated_from_row_data.loaded_quantity === 0) {
            $remove(from_row_el);
          } else {
            $replaceWith(
              $el(makeTableRowHtml({ row: updated_from_row_data, columns: PACKAGING_TABLE_COLUMN })),
              from_row_el,
            );
          }
        }
        $removeClass('active', from_row_el);
      },
    ),
  );

  // table 데이터 업데이트
  const from_data = $data(from_table);
  const to_data = $data(to_table);
  let updated_from_data = null;
  let updated_to_data = null;
  if (packing) {
    updated_from_data = go(
      from_data,
      omitBy(([_, { left_quantity }]) => left_quantity === 0),
    );
    updated_to_data = go(
      to_data,
      omitBy(([_, { loaded_quantity }]) => loaded_quantity === 0),
    );
  } else {
    updated_from_data = go(
      from_data,
      omitBy(([_, { loaded_quantity }]) => loaded_quantity === 0),
    );
    updated_to_data = go(
      to_data,
      omitBy(([_, { left_quantity }]) => left_quantity === 0),
    );
  }

  $setData(updated_from_data, from_table);
  $setData(updated_to_data, to_table);

  // table 문구 업데이트
  if (go(updated_from_data, keys).length === 0) {
    const tbody = go(from_table, $find('.tbody'));
    go(
      tbody,
      $append(
        $el(
          html`<tr class="no_data">
            <td class="text_center">
              ${packing ? MESSAGES.PACKAGING.NO_DATA_IN_LIST : MESSAGES.PACKAGING.NO_DAT_IN_WAYBILL}
            </td>
          </tr>`,
        ),
      ),
    );
  }

  // footer 문구 및 button
  let empty_left_products = false;
  let non_empty_waybill = false;
  let waybills = [];

  if (packing) {
    empty_left_products = go(updated_from_data, keys).length === 0;
    non_empty_waybill = go(updated_to_data, keys).length > 0;
    waybills = [...go(to_table, $closest('.tabs'), $findAll('.tab_item'))];
  } else {
    empty_left_products = go(updated_to_data, keys).length === 0;
    non_empty_waybill = go(updated_from_data, keys).length > 0;
    waybills = [...go(from_table, $closest('.tabs'), $findAll('.tab_item'))];
  }

  const waybill_index = waybills.findIndex($hasClass('selected'));
  const waybill_count = waybills.length;

  const footer_status = getPackagingFooterStatus({ empty_left_products, non_empty_waybill });
  const message = MESSAGES.PACKAGING[footer_status];
  const action_button_template = rebound
    ? makeReboundTabActionButtonHtml({ empty_left_products, waybill_index, waybill_count })
    : makePackagingTabActionButtonHtml({
        empty_left_products,
      });
  const footer_el = go(wrapper, $find('.footer'));
  go(
    footer_el,
    $setHTML(
      makePackagingTabFooterHtml({
        message,
        action_button_template,
      }),
    ),
  );

  // 담긴 수량 업데이트
  const selected_tab_item_el = go(wrapper, $find('.tab_item.selected'));
  const waybill_no_el = go(selected_tab_item_el, $find('.waybill_no'));
  const origin_items_count_el = go(selected_tab_item_el, $find('.items_count'));
  if (origin_items_count_el) {
    $remove(origin_items_count_el);
  }
  let items_count = 0;
  if (packing) {
    items_count = go(updated_to_data, keys).length;
  } else {
    items_count = go(updated_from_data, keys).length;
  }
  if (items_count > 0) {
    const items_count_el = $el(html`<span class="badge items_count">${items_count}</span>`);
    go(waybill_no_el, $after(items_count_el));
  }

  loadProductsImage(wrapper);
}

export async function addProductsToWaybill({ from_table, to_table, rebound }) {
  const el_tab = go(from_table, $closest('.don_tab'));
  const selected_tab = go(el_tab, $find('.tab_content.selected'));
  const { id: lgl_inbound_order_id } = selected_tab.dataset;

  const checked_items = go(from_table, $findAll('tbody input[type="checkbox"]:checked'));

  // 변경된 데이터 체크
  const updated_data = updateProductsData({ items: checked_items, from_table, to_table, rebound });

  // api 요청
  const response_data = await createOrUpdateProductsQuantity({
    items: updated_data,
    lgl_inbound_order_id,
    rebound,
  });

  // UI 업데이트
  renderProducts({ items: response_data, to_table, from_table, wrapper: el_tab, rebound });

  go(el_tab, $find('.right'), $addClass('disabled'));

  unCheckedAllCheckboxes({ el: el_tab });
}

export async function removeProductsFromWaybill({ from_table, to_table, rebound }) {
  const el_tab = go(from_table, $closest('.don_tab'));
  const selected_tab = go(el_tab, $find('.tab_content.selected'));
  const { id: lgl_inbound_order_id } = selected_tab.dataset;
  const packing = false;

  const checked_items = go(from_table, $findAll('tbody input[type="checkbox"]:checked'));

  // 변경된 데이터 체크
  const updated_data = updateProductsData({ items: checked_items, from_table, to_table, packing, rebound });
  // api 요청
  const response_data = await createOrUpdateProductsQuantity({
    items: updated_data,
    lgl_inbound_order_id,
    packing,
    rebound,
  });

  // UI 업데이트
  renderProducts({ items: response_data, to_table, from_table, wrapper: el_tab, packing, rebound });

  go(el_tab, $find('.left'), $addClass('disabled'));

  unCheckedAllCheckboxes({ el: el_tab });
}

/*
 *  @description inbound_orders의 status가 PACKED인 경우,
 *               1. 상품 수량이 0이된 항목 확인
 *               2. 상품 수량이 감소된 항목 확인
 *               3. 1~2의 항목들을 포함한 alert 표시
 *               4. 1~2의 항목을 운송장에서 삭제 (DELETE /@api/df/lgl/item/:lgl_item_id 요청)
 *               5. 운송장에서 상품이 삭제되었다면, 화면 재렌더링
 *  @params projection_id - projection의 id
 *  @params current_status - inbound_orders의 current_status
 */
export async function checkItemsQuantity({ projection_id, current_status }) {
  if (['PACKING'].includes(current_status)) {
    const {
      matching_results: { packing_check, quantity_check },
      ups_qty_in_lgl_items,
      ups_qty_in_projections,
      lgl_items,
    } = await apiCalls.get.inbound.isMatchPackingItemsWithUps({ projection_id });

    const tab = document.querySelector('.tab_wrapper.packaging');
    const waybills = go(tab, $findAll('.tab_content'));

    // 수량이 0이 된 상품들
    const removed_items = go(
      waybills,
      map((waybill) => {
        const data = go(waybill, $data);
        const {
          waybill_no_domestic,
          _: { lgl_items },
        } = data;
        return go(
          lgl_items,
          reject((item) => {
            return ups_qty_in_projections[item.user_product_id];
          }),
          map((item) => {
            return {
              waybill_no: waybill_no_domestic,
              item,
            };
          }),
          flat,
        );
      }),
      filter((items) => items.length > 0),
      flat,
    );

    // 수량이 감소된 상품들
    const reduce_items = go(
      ups_qty_in_projections,
      entries,
      filter(([k, v]) => ups_qty_in_lgl_items[k] > v),
      map(([k, v]) => {
        return go(
          waybills,
          map((waybill) => {
            const data = go(waybill, $data);
            const {
              waybill_no_domestic,
              _: { lgl_items },
            } = data;

            const lgl_items_by_user_product = go(
              lgl_items,
              indexBy(({ user_product_id }) => user_product_id),
            );

            return {
              waybill_no: waybill_no_domestic,
              item: lgl_items_by_user_product[k],
            };
          }),
        );
      }),
      flat,
    );

    const delete_items = [...removed_items, reduce_items];
    // LGL-BUWHAL
    //alert이 닫혔을 때 삭제 api 요청하기
    //@ delete api 요청
    // await go(
    //   delete_items,
    //   mapC(async ({ item: { id: lgl_item_id } }) => {
    //     await apiCalls.delete.item.lglItem({ lgl_item_id });
    //   }),
    // );
    if (delete_items.length > 0) {
      // await renderPackagingTab({ projection_id });
    }
  }
}
