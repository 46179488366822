export function BP_LABEL_LAYOUT(DPMM) {
  return {
    // mm 단위
    BLOCK: {
      margin_y: 2 / DPMM,
      x_pos: 0,
      line_gap: 0.35,
      max_width: 364 / DPMM,
    },
    INLINE: {
      line_gap: 0.35,
      title: {
        x_pos: 0,
        max_width: 90 / DPMM,
      },
      content: {
        x_pos: 90 / DPMM,
        max_width: 268 / DPMM,
      },
    },
    HOR_LINE: {
      x_pos: 0,
      margin_y: 3 / DPMM,
      width: 364 / DPMM,
    },
  };
}
