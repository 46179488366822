/*
 * @description 옵션명 문장을 생성 (kr, en)
 * */
export function createItemOptionStr({
  spo_item_id,
  group_names_option_names,
  base_product_color,
  base_product_size,
}) {
  const item_option_str_kr = spo_item_id
    ? group_names_option_names
        .map(({ group_name, option_name }) => {
          return `${group_name}:${option_name}`;
        })
        .join('/')
    : `색상:${base_product_color.name ?? ' - '} / 사이즈:${base_product_size.name ?? ' - '}`;

  const item_option_str_en = spo_item_id
    ? group_names_option_names
        .map(({ group_name_en, option_name_en }) => {
          return `${group_name_en}:${option_name_en}`;
        })
        .join('/')
    : `Color:${base_product_color.name_en ?? ' - '} / Size:${base_product_size.name_en ?? ' - '}`;

  return {
    kr: item_option_str_kr ?? '',
    en: item_option_str_en ?? '',
  };
}
