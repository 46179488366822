import axios from 'axios';

export const pdf = () => {};

export const callGraphicMagickStatus = async () => {
  try {
    return (await axios.get('/@api/pdf/thumbnail/status/hello')).data;
  } catch (err) {
    console.error(err);
  }
};
