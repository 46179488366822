import { DfImageEditorF } from './module/DfImageEditorF.js';
import { format } from 'date-fns';
import { getDesignCmSize } from './helpers.raster.js';
import { createImageUrl } from '../../../../Qr/S/Function/create.js';
import { imageUrlToImageEl } from './helpers.image.js';
import { SVG_EL_ITEM } from '../../S/Constant/constants.js';

export function imageToBlob({ image }) {
  const imageDataURL = DfImageEditorF.getImageDataURL({ image });
  const byteString = atob(imageDataURL.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([uint8Array], { type: 'image/png' });
}

export async function addPrintFile({ product_id, file_data, face_id }) {
  return $.post('/@api/image_work/add_print_file', {
    printable_product_id: product_id,
    printable_file: file_data,
    bpf_id: face_id,
  }).then(({ ok }) => ok);
}

export async function updatePrintFileCounts({ projection_id }) {
  return $.post('/@api/image_work/completed_all_print_files', { projection_id }).then(({ ok }) => ok);
}

export async function uploadSvgFile(svg_string, filename) {
  const file = new Blob([svg_string], { type: 'image/svg+xml' });
  const formData = new FormData();
  formData.append('file', file, filename);
  return await $.upload(formData, {
    url: '/@api/svg_file',
    data: { dpi: 300 },
  });
}

export async function svgToBase64({ svg_el }) {
  const svg_string = new XMLSerializer().serializeToString(svg_el);
  const blob = new Blob([svg_string], { type: 'image/svg+xml' });

  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => {
      console.error(e);
      reject(new Error('svg 를 data_url 로 변환하지 못했습니다.'));
    };
    reader.readAsDataURL(blob);
  });
}

export function downloadUrl({ url, file_name }) {
  const download_link = document.createElement('a');
  download_link.href = url;
  download_link.download = file_name ?? format(Date.now(), 'MM/dd HH:mm:ss');

  download_link.click();

  URL.revokeObjectURL(url);
}

export async function getExportFileInfoGroup({ tab_el, projection_id, up_id, total_qty, face_name }) {
  // projeciton_id / up_id / face_name / total_qty / size
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });

  const file_info_group = new paper_scope.Group();

  const { cm_width, cm_height, px_width, px_height } = getDesignCmSize({ tab_el });
  const HEIGHT_OFFSET = 200;
  const QR_SIZE = 150;

  const start_position = new paper_scope.Point(-px_width / 2, -px_height / 2 - HEIGHT_OFFSET);
  const image_el = await getQrImageEl({ content: `#${projection_id}`, size: QR_SIZE });

  const qr_raster = new paper_scope.Raster(image_el);
  qr_raster.position = start_position.add(QR_SIZE / 2);

  const text_item = new paper_scope.PointText({
    point: [start_position.x + QR_SIZE + 30, start_position.y + 30],
    content: `주문번호(${addSpacesToNumber({
      number: projection_id,
    })})\n상품번호(${addSpacesToNumber({
      number: up_id,
    })})\n${face_name} 수량(${total_qty}개)\n사이즈(${cm_width.toFixed(1)}cm x ${cm_height.toFixed(1)}cm)`,
    fillColor: 'black',
    fontFamily: 'Courier New',
    fontWeight: 'bold',
    fontSize: 32,
  });

  file_info_group.name = SVG_EL_ITEM.info;

  file_info_group.addChild(qr_raster);
  file_info_group.addChild(text_item.rasterize());

  return file_info_group;
}

export async function getQrImageEl({ content, size, format = 'webp' }) {
  const url = createImageUrl({
    value: content,
    margin: 0,
    format,
    codeType: 'aztec',
    width: size,
    height: size,
    displayValue: false,
  });

  return await imageUrlToImageEl({ url });
}

function addSpacesToNumber({ number, step = 4 }) {
  if (typeof number !== 'number' && typeof number !== 'string') {
    throw new Error('Input must be a number or string.');
  }

  const strNumber = number.toString().replace(/\s|,/g, '');

  let formattedNumber = '';
  for (let i = strNumber.length - 1, count = 0; i >= 0; i--, count++) {
    if (count === step) {
      formattedNumber = ' ' + formattedNumber;
      count = 0;
    }
    formattedNumber = strNumber.charAt(i) + formattedNumber;
  }

  return formattedNumber;
}

export function sanitizeSvgEl({ svg_el }) {
  /*
   * 1. svg 에 최상위 그룹 제거
   * 2. 칼선 path 에 fill 속성을 투명으로 적용
   * */
  if (svg_el.firstChild.tagName === 'g') {
    const group = svg_el.firstChild;

    while (group.firstChild) {
      svg_el.insertBefore(group.firstChild, group);
    }
    svg_el.removeChild(group);
  }
  return svg_el;
}

export function getDesignTrimOffsetYFn({ tab_el }) {
  const { trim_offset_y } = DfImageEditorF.getPrintableDesign({ tab_el });

  return trim_offset_y;
}
