import { errorHandlingEventWrapper } from '../../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { product_editor_product_tmpl } from './product_tmpl.js';
import { open_chooser } from './product_fs.js';
import { fcanvasChanging } from '../util.js';
import { makeProductBpcMobileHtml } from './bpc_tmpl.js';
import {
  getBaseProductColorsInMaker,
  getBaseProductSizesInMaker,
  getCurrentBpcId,
  getProductColorInMaker,
} from '../getSth.js';
import { eventProductBpcA, getBpcTitle } from './bpc_fs.js';
import { $find } from 'fxdom/es';
import { go } from 'fxjs/es';
import { discontinuedSizeIds, excludingDiscontinued, soldOutSizeIds } from '../../../ProductColor/S/fs.js';
import { eventProductBpsA } from './bps_fs.js';
import { makeBpsSizeItemMobileHtml } from './bps_tmpl.js';

export function renderProductEditorProductMobile() {
  $.html(
    $1('#maker_product_editor .don_tab[tab_name="product_editor.product"] .don_wrapper'),
    product_editor_product_tmpl(box.sel('maker->product_color')),
  );
}

export const tab_product = {
  tab_name: 'product_editor.product',
  title: T('Product'),
  template: _p.noop,
  showed: __(function () {
    renderProductEditorProductMobile();
  }),
  appended: _pipe($.on('click', '.product_editor_ev .open_chooser', errorHandlingEventWrapper(open_chooser))),
};

export function renderProductBpcMobile(don_tab) {
  const don_wrapper_el = $find('.don_wrapper', don_tab);
  const padding = 6;
  const slide_length = Math.floor(($.width(window) - 12) / (28 + padding * 2)) * 2;
  const bpcss = _p.how_many(getBaseProductColorsInMaker(), slide_length);
  const current_bpc_id = getCurrentBpcId();
  $.html(don_wrapper_el, makeProductBpcMobileHtml(bpcss, slide_length, current_bpc_id));
}

export const tab_product_bpc = {
  tab_name: 'product_editor.color',
  title: T('Color'),
  template: _p.noop,
  showing: _p.pipe($.css({ opacity: 0 }), renderProductBpcMobile),
  appended: $.on3(
    'click',
    '.product_editor_ev .color_item:not(.selected)',
    fcanvasChanging(
      errorHandlingEventWrapper(async function (e) {
        $.don_loader_start();
        await eventProductBpcA(e.currentTarget);
        $.don_loader_end();
      }),
    ),
  ),
  showed: function (el_tab) {
    const bpc = box.sel($1('.color_item.selected'));
    $.text($.find1(el_tab, '.bp_color_name'), bpc['name' + _en]);
    const slide_length = _go(el_tab, $.find('.swiper-slide')).length;
    const first_slide_items_length = _go(el_tab, $.find1('.swiper-slide'), $.find('.color_item')).length;
    const el_color_picker = $.find1(el_tab, '.color_picker');
    const total_items_length = $.attr(el_color_picker, 'items_in_slide');
    $.attr(el_color_picker, {
      'swiper-slide_length': slide_length,
      is_only_one_line: total_items_length / 2 > first_slide_items_length,
    });
    if (slide_length > 1) {
      el_tab.my_swiper = new Swiper($.find1(el_tab, '.swiper-container'), {
        pagination: { el: $.find1(el_tab, '.swiper-pagination') },
        initialSlide: _go(
          el_tab,
          $.find('.swiper-slide'),
          _p.find_i(function (el) {
            return _go(el, $.find1('.selected'));
          }),
        ),
      });
    }
    _go($.find(el_tab, '.color_picker.mkf2 .swiper-slide'), function (el_swiper_slides) {
      const color_item_els = _go(el_tab, $.find('.color_item'));
      const color_item_width = _go(color_item_els, _p.first, $.outerWidth);
      const swiper_slide_width = $.width(el_swiper_slides[0]);
      return _go(swiper_slide_width, function (padding) {
        padding = 6;
        _go(
          color_item_els,
          $.css({
            'margin-left': padding,
            'margin-right': padding,
          }),
        );
        const num = parseInt(swiper_slide_width / (color_item_width + padding * 2));
        const real_width = num * (color_item_width + padding * 2);

        const padding2 = $.width(window) - real_width;
        _go(
          el_swiper_slides,
          $.css({
            padding: '0 ' + padding2 / 2 + 'px 15px',
          }),
        );
        $.css(el_tab, { opacity: 1 });
      });
    });
  },
};

export const tab_product_bps = {
  tab_name: 'product_editor.size',
  title: T('Size'),
  template: _p.noop,
  showing: __($.css({ opacity: 0 }), $.find1('.don_wrapper'), function (don_wrapper_el) {
    const bpss = getBaseProductSizesInMaker();
    $.html(
      don_wrapper_el,
      legacyHtml`
        <div class="product_editor_ev">
          <div class="size section" length="${bpss.length}"></div>
        </div>
      `,
    );
  }),
  showed: function (el_tab) {
    const item_margin_right = 8;
    const item_margin_bottom = 8;
    const el_size_section = $.find1(el_tab, '.size.section');
    const item_min_padding = 10;
    const slide_padding = 24 * 2;
    const border = 2;
    const wrapper_width = $.width(el_size_section) + item_margin_right - slide_padding;
    const min_width = 70;
    const bpss = excludingDiscontinued(
      getBaseProductSizesInMaker(),
      discontinuedSizeIds(getProductColorInMaker(), getBaseProductColorsInMaker()),
    );
    const line_length = 2;

    let item_outer_width =
      $.biggest_span_size(_go(bpss, _map(_p.v('short_name' + _en))), 14) +
      item_min_padding +
      item_margin_right +
      border;

    item_outer_width = item_outer_width < min_width ? min_width : item_outer_width;

    const one_line_items_length = Math.floor(wrapper_width / item_outer_width);

    const items_length_in_slide = one_line_items_length * line_length;
    const sold_out_size_ids = soldOutSizeIds(getProductColorInMaker(), getBaseProductColorsInMaker());
    go(
      makeBpsSizeItemMobileHtml(_p.how_many(bpss, items_length_in_slide), sold_out_size_ids),
      $.html_to(el_size_section),
    );

    _go(wrapper_width / one_line_items_length, function (ex_item_width) {
      const width = ex_item_width - item_margin_right - border;
      const els_item = $.find(el_tab, '.item');
      const el_slide = $.find1(el_tab, '.swiper-slide');
      _go(
        $.find(el_tab, '.swiper-slide'),
        _p.each(function (el_slide) {
          const els_li2 = $.find(el_slide, '.item');
          if (els_li2.length > one_line_items_length) {
            _go(
              _p.range(one_line_items_length),
              _p.each(function (idx) {
                $.css(els_li2[idx], {
                  'margin-bottom': item_margin_bottom,
                });
              }),
            );
          }
        }),
      );
      _p.go(els_item, $.width(width));
      $.attr(el_size_section, { one_line_length: one_line_items_length });
      $.attr(el_size_section, {
        more_than_one: $.find(el_slide, '.item').length > one_line_items_length,
      });
    });

    if ($.find(el_tab, '.size.section .swiper-slide').length > 1) {
      $.add_class(el_size_section, 'has_swiper');
      el_tab.my_swiper = new Swiper($.find1(el_tab, '.swiper-container'), {
        pagination: { el: $.find1(el_tab, '.swiper-pagination') },
        spaceBetween: 20,
      });
      el_tab.my_swiper.slideTo(
        _go(
          el_tab,
          $.find('.swiper-slide'),
          _p.find_i(function (el) {
            return _go(el, $.find1('.selected'));
          }),
        ),
      );
    }
    $.css(el_tab, { opacity: 1 });
  },
  appended: $.on3(
    'click',
    '.product_editor_ev .size.section li:not(.selected)',
    fcanvasChanging(errorHandlingEventWrapper(eventProductBpsA)),
  ),
};

$.frame.defn_frame({
  el_id: 'maker_product_editor',
  frame_name: 'maker.product_editor',
  page_name: 'product_editor.change',
  el_class: 'product_editor maker_dialog close_type can_dual_edit no_header',
  animation: true,
  prev_frame_show: true,
  height: 190 + G.mp.frame.v_height,
  header_height: G.mp.frame.v_height,
  hide_frame_button_type: 'V',
  showed: __($.on_prevent_default, function () {
    $.text($1('.tab_button[tab_name="product_editor.color"] button'), getBpcTitle());
  }),
  showing: function () {
    $.text($1('.tab_button[tab_name="product_editor.color"] button'), getBpcTitle());
    G.mp.maker.ban_frame_show();
  },
  closing: function (a, done) {
    if (!done) G.mp.maker.ban_frame_hide();
    if (!$1('html.prev_product_matcher')) G.mp.maker.zoom.maker_modeA();
    G.mp.maker.extend_state_bp(box.sel('maker->product_color->base_product_id'));
  },
  appended: $.frame_close_bg,
  hiding: $.off_prevent_default,
});

$.frame.defn_page({
  page_name: 'product_editor.change',
  el_class: 'after_bar',
  custom_header: G.mp.frame.t_blue_tab_buttons,
  tabs: [tab_product, tab_product_bpc, tab_product_bps],
});

$.frame.defn_page({
  page_name: 'product_editor.change_not_bp',
  el_class: 'after_bar',
  custom_header: G.mp.frame.t_blue_tab_buttons,
  tabs: [tab_product_bpc, tab_product_bps],
});
export function openProductEditorFrameNotBp() {
  $.frame.open({
    frame_name: 'maker.product_editor',
    page_name: 'product_editor.change_not_bp',
  });
}
export function openProductEditorFrame() {
  $.frame.open({
    frame_name: 'maker.product_editor',
  });
}
