import { makeProductColorByPcAndBpcIdAndBpId } from '../../../../Maker/F/draw_product_faces.js';
import axios from 'axios';

export const updateProductDesignsChangingBpAndBpc = async ({
  id,
  product_faces2,
  base_product_color_id,
  base_product_id,
}) => {
  if (!base_product_id) {
    throw new Error('base_product_id 필요합니다.');
  }
  if (!base_product_color_id) {
    throw new Error('base_product_color_id 필요합니다.');
  }
  if (!product_faces2) {
    throw new Error('product_faces2 필요합니다.');
  }
  if (!id) {
    throw new Error('id 필요합니다.');
  }
  const { new_pc } = await makeProductColorByPcAndBpcIdAndBpId(
    { id, product_faces2 },
    base_product_color_id,
    base_product_id,
  );
  // const regex = /"cid":"c\d+",/g;
  // console.log(
  //   JSON.stringify(JSON.parse(JSON.stringify(new_pc.product_faces2).replace(regex, ''))) ===
  //     JSON.stringify(JSON.parse(JSON.stringify(new_pc2.product_faces2).replace(regex, ''))),
  // );
  // console.log(
  //   JSON.stringify(JSON.parse(JSON.stringify(new_pc.product_faces2).replace(regex, ''))) ===
  //     JSON.stringify(JSON.parse(JSON.stringify(new_pc3.product_faces2).replace(regex, ''))),
  // );
  // console.log(
  //   JSON.stringify(JSON.parse(JSON.stringify(new_pc.product_faces2).replace(regex, ''))) ===
  //     JSON.stringify(JSON.parse(JSON.stringify(new_pc4.product_faces2).replace(regex, ''))),
  // );
  await axios.post('/@api/product_color/product_faces2/update', {
    id,
    product_faces2: new_pc.product_faces2,
  });
};
