export const makePageChangeEvent = (fetch_option) => {
  const offset = fetch_option.offset || 0;
  const limit = fetch_option.limit || 10;

  return new CustomEvent('page-change', {
    bubbles: true,
    cancelable: true,
    detail: {
      offset,
      limit,
      page: offset / limit + 1,
    },
  });
};

export const makePageArrowClickEvent = (arrow_type, fetch_option) => {
  const offset = fetch_option.offset || 0;
  const limit = fetch_option.limit || 10;

  return new CustomEvent(`${arrow_type}-click`, {
    bubbles: true,
    cancelable: true,
    detail: {
      offset,
      limit,
      page: offset / limit + 1,
    },
  });
};
