import { html } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Util/S/Function/module/OMPCoreUtilS.js';

export const bottomBarHTML = ({ locals, activeIndex }) => {
  const is_mobile = OMPCoreUtilS.isMobile(locals);
  if (!is_mobile) return '';

  const home = TT('omp_layout::bottom_bar::txt_01');
  const search = TT('omp_layout::bottom_bar::txt_02');
  const biz_group = TT('omp_layout::bottom_bar::txt_03');
  const review = TT('omp_layout::bottom_bar::txt_04');
  const mypage = TT('omp_layout::bottom_bar::txt_05');

  return html`
    <div class="omp-bottom-bar">
      <ul class="omp-bottom-bar__ul">
        <li class="omp-bottom-bar__li" data-active="${activeIndex == 0}">
          <a href="/${TT.lang}/" class="omp-bottom-bar__a">
            <span class="omp-bottom-bar__icon omp-bottom-bar__icon--home">
              ${OMPCoreAtomTmplS.homeIcon({ color: activeIndex == 0 ? 'BK' : 'GY' })}
            </span>
            <span class="omp-bottom-bar__txt">${home}</span>
          </a>
        </li>
        <li class="omp-bottom-bar__li" data-active="${activeIndex == 1}">
          <a href="/${TT.lang}/search" class="omp-bottom-bar__a">
            <span class="omp-bottom-bar__icon omp-bottom-bar__icon--search">
              ${OMPCoreAtomTmplS.searchIcon({ color: activeIndex == 1 ? 'BK' : 'GY' })}
            </span>
            <span class="omp-bottom-bar__txt">${search}</span>
          </a>
        </li>
        <li class="omp-bottom-bar__li" data-active="${activeIndex == 2}">
          <a href="/${TT.lang}/biz" class="omp-bottom-bar__a">
            <span class="omp-bottom-bar__icon omp-bottom-bar__icon--new-best">
              ${OMPCoreAtomTmplS.bizGroupIcon({ color: activeIndex == 2 ? 'BK' : 'GY' })}
            </span>
            <span class="omp-bottom-bar__txt">${biz_group}</span>
          </a>
        </li>
        <li class="omp-bottom-bar__li" data-active="${activeIndex == 3}">
          <a href="/${TT.lang}/review/list?is_best=true&is_all=false" class="omp-bottom-bar__a">
            <span class="omp-bottom-bar__icon omp-bottom-bar__icon--review">
              ${OMPCoreAtomTmplS.reviewIcon({ color: activeIndex == 3 ? 'BK' : 'GY' })}
            </span>
            <span class="omp-bottom-bar__txt">${UtilS.escape(review)}</span>
          </a>
        </li>
        <li class="omp-bottom-bar__li" data-active="${activeIndex == 4}">
          <a href="/${TT.lang}/my-page" class="omp-bottom-bar__a">
            <span class="omp-bottom-bar__icon omp-bottom-bar__icon--mypage">
              ${OMPCoreAtomTmplS.avatarIcon({ color: activeIndex == 4 ? 'BK' : 'GY' })}
            </span>
            <span class="omp-bottom-bar__txt">${mypage}</span>
          </a>
        </li>
      </ul>
    </div>
  `;
};
