import { $attr, $qs } from 'fxdom/es';
import { not } from 'fxjs/es';
import { startMuiFrame } from '../../base_start_frame.js';

(async function () {
  const $don_frame = $qs('.don_frame');
  if (not($don_frame)) return;
  if ($attr('is-mui', $don_frame) === 'true') {
    await startMuiFrame($don_frame);
  }
})();
