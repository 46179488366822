import { pipe, extend } from 'fxjs/es';
import MarppleTermsS from '../../../Marpple/Terms/S/index.js';
import LineTermsS from '../../../Line/Terms/S/index.js';

function getTermsByLang(func_name, is_login) {
  if (T.lang === 'kr') {
    if (collabo_type === 'line') {
      if (func_name === 'privacy' && is_login) return LineTermsS.tmpl.kr_privacy_login;
      if (func_name === 'privacy' && !G.window) return LineTermsS.tmpl.kr_full_privacy;
      return LineTermsS.tmpl['kr_' + func_name];
    }
    return MarppleTermsS.tmpl['kr_' + func_name];
  }
  if (T.lang === 'en') {
    if (collabo_type === 'line') return LineTermsS.tmpl['en_' + func_name];
    return MarppleTermsS.tmpl['en_' + func_name];
  }
  if (T.lang === 'jp') {
    /*line은 jp 없음*/
    return MarppleTermsS.tmpl['jp_' + func_name];
  }
}

export const terms_and_conditions_tab_core = {
  tab_name: 'terms_conditions_tab',
  template: getTermsByLang('terms'),
};

export const terms_and_conditions_tab = () =>
  extend(
    {
      appended: pipe(
        $.on('click', '.done', function () {
          $.frame.close();
        }),
      ),
    },
    terms_and_conditions_tab_core,
  );

export const privacy_tab_core = {
  tab_name: 'privacy_tab',
  template: getTermsByLang('privacy'),
};

export const privacy_tab = () => {
  return extend(
    {
      appended: pipe(
        $.on('click', '.done', function () {
          $.frame.close();
        }),
      ),
    },
    privacy_tab_core,
  );
};

export const privacy_login_tab = () => {
  return {
    tab_name: 'privacy_tab',
    template: getTermsByLang('privacy', true),
    appended: pipe(
      $.on('click', '.done', function () {
        $.frame.close();
      }),
    ),
  };
};

export const commerce_law_tab_core = {
  tab_name: 'commerce_law_tab',
  template: MarppleTermsS.tmpl.jp_commerce_law,
};

export const commerce_law_tab = () => {
  return commerce_law_tab_core;
};
