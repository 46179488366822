import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { GlobalPortOneRefundBankMuiS } from '../../S/Mui/module/GlobalPortOneRefundBankMuiS.js';
import zenginCode from 'zengin-code';
import { GlobalPortOneRefundBankF } from './module/GlobalPortOneRefundBankF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { GlobalPortOneRefundBankTmplS } from '../../S/Tmpl/module/GlobalPortOneRefundBankTmplS.js';
import { $qs } from 'fxdom/es';

export const openRefundBankInfo = (info) => {
  const is_df = !!$qs('#dream_factory');
  const button_title = is_df ? '저장 완료' : T('refund_bank::04');
  return new Promise((resolve, reject) => {
    MuiF.openFrame(GlobalPortOneRefundBankMuiS.frame, (frame, page, [tab]) => {
      page.title = T('refund_bank::05');
      tab.template = () => {
        return GlobalPortOneRefundBankTmplS.main({
          banks: zenginCode,
          info,
          button_title,
        });
      };
      frame.appended = (frame_el) => {
        GlobalPortOneRefundBankF.event(frame_el);
      };
      frame.is_modal = !UtilF.isMobile();
      frame.always_remove = true;
      frame.closed = (frame_el, data) => {
        if (data) {
          resolve({
            bank_code: data.bank_code,
            branch_code: data.branch_code,
            bank_account: data.bank_account,
            bank_user_name: data.bank_user_name,
          });
        } else {
          resolve();
        }
      };
    });
  });
};
