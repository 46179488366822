import { each, go } from 'fxjs/es';
const LINE_HOME = $1('html').getAttribute('line_home');
const defnLineFooterEvent = () =>
  go(
    $('#footer'),
    $.on('click', '.footer_menu .head', (e) =>
      $.toggle_class($.closest(e.currentTarget, '.footer_menu'), 'on'),
    ),
    $.on('click', '.m_biz_info .biz_head', (e) =>
      $.toggle_class($.closest(e.currentTarget, '.m_biz_info'), 'on'),
    ),
    $.find('.footer_menu.lang a'),
    each((anchor) => (anchor.href = anchor.pathname + '/' + LINE_HOME)),
  );

const defnMpFooterEvent = () =>
  go(
    $1('#footer'),
    $.on('click', '.footer_menu h4', (e) =>
      $.toggle_class($.closest(e.currentTarget, '.footer_menu'), 'open'),
    ),
    $.on('click', '.company_info', (e) => $.toggle_class(e.currentTarget, 'open')),
    $.on('click', '.btn_shipping_county', () => $.frame.open({ frame_name: 'mp.shipping_country' })),
  );

export const defnFooterEvent = () => {
  if (G.collabo_type == 'line') return defnLineFooterEvent();
  return defnMpFooterEvent();
};
