import { compact, find, go, not } from 'fxjs/es';
import { getDesigns, getRealFcanvass } from '../../../../Maker/F/getSth.js';
import { getCvObj } from '../../../../Maker/F/Fcanvas/cv_object.js';

export const goToNotDesignedFcanvas = () => {
  return go(
    getRealFcanvass(),
    find((fcanvas) => {
      return go(
        getDesigns(fcanvas).concat(getCvObj(fcanvas._objects, 'cv_background')),
        compact,
        (arr) => arr.length,
        not,
      );
    }),
    async (fcanvas) => {
      G.mp.maker.unselect_all();
      const idx = box().maker.canvass.indexOf(fcanvas);
      G.mp.maker.editing_canvas(idx);
      await G.mp.maker.reset_layer();
    },
  );
};
