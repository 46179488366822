import { EditorNS } from '@marpple/sticker-editor';
import { $findAll, $off, $on } from 'fxdom/es';
import { each, go, takeL, tap } from 'fxjs/es';
import { VectorEditorStickerSingleMobileCuttingLineManualMuiS } from '../../S/Mui/module/VectorEditorStickerSingleMobileCuttingLineManualMuiS.js';
import { VectorEditorStickerSingleMobileCuttingLineManualEventF } from '../Event/module/VectorEditorStickerSingleMobileCuttingLineManualEventF.js';

const preventDefaultEvent = (event) => event.preventDefault();

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerSingleMobileCuttingLineManualMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {
    const cutting_line_editor = new EditorNS.CuttingLineEditorNS.MobileNS.CuttingLineMobileEditor({
      window,
      document,
      touch_margin: 6,
      min_zoom_scale: 1,
      max_zoom_scale: 3,
    });
    cutting_line_editor.initDOM();
    cutting_line_editor.initEditor();
    go(
      tab_el$,
      $findAll('.editor_container'),
      takeL(1),
      each((editor_container_el) => cutting_line_editor.appendTo(editor_container_el)),
    );
    tab_el$.__mp_cutting_line_editor = cutting_line_editor;

    if (tab_el$.__mp_target_image_el != null) {
      const clone_target_image_el = tab_el$.__mp_target_image_el.cloneNode(true);
      cutting_line_editor.setTargetImageEl(clone_target_image_el);
    }

    if (tab_el$.__mp_cutting_line_data != null) {
      cutting_line_editor.setCuttingLinePathData(tab_el$.__mp_cutting_line_data);
    }
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    $on('touchmove', preventDefaultEvent, { passive: false })(window);
    $on('contextmenu', preventDefaultEvent)(tab_el$);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {
    go(
      tab_el$,
      $findAll('.footer_container'),
      each((el) => {
        new Swiper(el, {
          slidesPerView: 'auto',
          freeMode: true,
        });
      }),
    );

    const cutting_line_editor = tab_el$.__mp_cutting_line_editor;
    if (!cutting_line_editor) {
      return;
    }

    const target_image_el = tab_el$.__mp_target_image_el;
    if (!target_image_el) {
      return;
    }

    cutting_line_editor.fitSize();
    const view_box_vo = cutting_line_editor.calculateViewBoxFitTo({
      size_in_ucs: {
        width: target_image_el.width.baseVal.value,
        height: target_image_el.height.baseVal.value,
      },
      padding_in_vcs: { top: 16 + 48, right: 16, bottom: 16 + 144, left: 16 },
    });
    cutting_line_editor.setViewBox(view_box_vo);
    cutting_line_editor.startEditCuttingLinePath();

    go(
      tab_el$,

      tap(
        $findAll(`.header .cancel`),
        each($on('click', VectorEditorStickerSingleMobileCuttingLineManualEventF.handleHeaderCancelClick())),
      ),
      tap(
        $findAll(`.header .done`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineManualEventF.handleHeaderDoneClick({ tab_el: tab_el$ }),
          ),
        ),
      ),

      tap(
        $findAll('.footer .footer_menu.add_l'),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineManualEventF.handleFooterAddLIconClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
      tap(
        $findAll('.footer .footer_menu.add_c'),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineManualEventF.handleFooterAddCIconClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
      tap(
        $findAll('.footer .footer_menu.remove'),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineManualEventF.handleFooterRemoveIconClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
      tap(
        $findAll('.footer .footer_menu.change_to_l'),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineManualEventF.handleFooterChangeToLIconClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
      tap(
        $findAll('.footer .footer_menu.change_to_c'),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineManualEventF.handleFooterChangeToCIconClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.reset`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineManualEventF.handleFooterResetIconClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
    );
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {
    $off('touchmove', preventDefaultEvent, { passive: false })(window);
    $off('contextmenu', preventDefaultEvent)(tab_el$);

    tab_el$.__mp_target_image_el = null;
    tab_el$.__mp_cutting_line_data = null;
    tab_el$.__mp_cutting_line_editor?.destroy?.();
    tab_el$.__mp_cutting_line_editor = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
