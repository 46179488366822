import { html, strMap, zipWithIndexL } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

export const findIDResultTmpl = (users) => {
  const find_id_result = TT('auth::find_id::find_id_11');

  const reset_password = TT('auth::reset_password::reset_password_01');
  const login = TT('omp_layout::left::sub_02');

  return html`
    <div class="omp-find-id-result">
      <div class="omp-find-id-result__emails">
        <div class="omp-find-id-result__sub-title">${find_id_result}</div>
        <div class="omp-find-id-result__emails">${strMap(findIdResultEmailTmpl, zipWithIndexL(users))}</div>
      </div>
      <!---->
      <div class="omp-find-id-result__button-wrapper">
        <span class="omp-find-id-result__button">
          ${OMPCoreAtomTmplS.actionButton({
            klass: 'omp-find-id-result__button-reset_pw',
            fill: 0,
            type: 'button',
            solidity: 'thick',
            text: reset_password,
          })}
        </span>
        <span class="omp-find-id-result__button">
          ${OMPCoreAtomTmplS.actionButton({
            klass: 'omp-find-id-result__button-login',
            type: 'button',
            solidity: 'thick',
            text: login,
          })}
        </span>
      </div>
    </div>
  `;
};

const findIdResultEmailTmpl = ([index, user]) => {
  const no_email = TT('auth::find_id::find_id_12');
  const facebook = TT('auth::find_id::find_id_13');

  if (!user.email) {
    return '';
  }

  const input_id = `user_${index}`;
  return html`
    <div class="omp-find-id-result__email" data-id="${input_id}">
      ${OMPCoreAtomTmplS.radioInput({
        input_id,
        name: 'user',
        value: user.email,
        label: user.social_id ? `${user.email || no_email}(${facebook})` : user.email,
        is_checked: index === 0,
      })}
    </div>
  `;
};
