export const ResultTab = Object.freeze({
  All: 'all',
  Products: 'products',
  Contents: 'contents',
  Reviews: 'reviews',
});

export const Klass = Object.freeze({
  inputForm: 'omp-atom__form-input',
  searchForm: 'omp-atom__form-search',
  input: 'omp-atom__input',

  autoCompleteItems: 'omp-search__auto-complete-items',
  autoCompleteItem: 'omp-search__auto-complete-item',
});
