export const getErrMsg = (err) => {
  if (!(err instanceof Error)) {
    throw new Error('에러 객체 아님');
  }
  return err.isAxiosError
    ? typeof err.response.data === 'string'
      ? err.response.data
      : err.response.data?.message ?? 'Unknown Error'
    : err?.message ?? 'Unknown Error';
};
