import { $addClass, $closest, $delegate, $find, $findAll, $qs, $removeClass, $setCss, $text } from 'fxdom/es';
import { delay, tap, each, go, pipe } from 'fxjs/es';
import { PrintTemplateF } from '../../../../../../PrintTemplate/F/Function/module/PrintTemplateF.js';
import { PrintTemplateS } from '../../../../../../PrintTemplate/S/Function/module/PrintTemplateS.js';
import { alertProEditorCsError } from '../../../../../../VectorEditor/F/Function/pro_editor.error.js';
import { OMPCoreUtilF } from '../../../../Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreFramesTabStyleFMuiS } from '../../S/Mui/module/OMPCoreFramesTabStyleFMuiS.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...OMPCoreFramesTabStyleFMuiS.tab,

  inner_scroll_target: '.modify_info__content', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    if (OMPCoreUtilF.isMobile()) {
      const has_modal_description = $qs('.modal_description', document);
      if (has_modal_description) {
        go(tab_el, $find('.modal_side'), $setCss({ 'border-bottom': '1px solid black' }));
      }
    }

    go(tab_el, $findAll('li'), (li) => {
      if (li.length === 1 && li[0].dataset.title === '사이즈 다름') {
        go(
          $qs('.don_frame[frame_name="/modules/OMP/Core/Frames/TabStyle/S/Mui/frame.js"]'),
          $find('.don_wrapper'),
          (el) => $addClass('is_size_issue', el),
        );
      }
    });

    go(
      tab_el,
      $delegate('click', '.list', ({ currentTarget }) => {
        const target_name = $text(currentTarget);
        const selected_info = pipe(
          each((el) =>
            el.dataset.title === target_name ? $addClass('selected', el) : $removeClass('selected', el),
          ),
        );
        const type = go(currentTarget, $closest('ul'), tap($findAll('li'), selected_info)).dataset
          .modal_title;
        go(
          currentTarget,
          $closest('.modal_wrapper'),
          $findAll(`.modal_content div[data-modal_title="${type}"]`),
          selected_info,
        );

        go(
          currentTarget,
          $closest('.don_frame[frame_name="/modules/OMP/Core/Frames/TabStyle/S/Mui/frame.js"]'),
          $find('.don_wrapper'),
          (el) => {
            target_name === '사이즈 다름'
              ? $addClass('is_size_issue', el)
              : $removeClass('is_size_issue', el);
          },
        );
      }),
      $delegate('click', '.size_issue_tmpl_download', async ({ currentTarget }) => {
        try {
          $.don_loader_start();
          const { bp_id } = currentTarget.dataset;
          const printing_template = await PrintTemplateF.getPrintTemplate({
            bp_id,
            lang: T.lang,
          });
          const { file_name, url } = printing_template;
          const template_resource_url = PrintTemplateS.printingTemplateS3UrlToCloudFrontUrl(url);

          OMPCoreUtilF.downloadFileAppCompatible({ url: template_resource_url, file_name });

          await delay(500, () => {});
          $.don_loader_end();
        } catch (err) {
          await alertProEditorCsError({ title: TT('pro_editor::alert::title::download') });
          $.don_loader_end();
          console.error(err);
        }
      }),
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
