import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorSettingBackgroundMobileMuiF } from '../Mui/module/VectorEditorSettingBackgroundMobileMuiF.js';

export const setBackground = async ({
  getInitBackground = () => Promise.resolve({ color: 'none' }),
  setBackground = ({ color }) => {
    const error = new Error(`No "setBackground".`);
    error.color = color;
    throw error;
  },
} = {}) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorSettingBackgroundMobileMuiF.frame, (frame, page, [tab]) => {
      const tab_appending = tab.appending;
      tab.appending = async (tab_el2) => {
        const { color } = await getInitBackground();
        tab_el2.__mp_color = color;

        tab_el2.__mp_handleBackgroundChange = setBackground;
        return tab_appending.call(tab, tab_el2);
      };
    }).catch(reject),
  );
