import { each, extend, filter, go, pick, sel } from 'fxjs/es';
import { createCvImageP, makeDefaultCvImageAttrs } from '../../../../../Maker/F/CvImage/fs.js';
import { getFcanvass, getRealFcanvass } from '../../../../../Maker/F/getSth.js';
import { getCvDesigns } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { NewMakerCvObjectUtilF } from './module/NewMakerCvObjectUtilF.js';

export async function convertAllBase64ToFcanvas() {
  await go(
    getRealFcanvass(),
    each((fcanvas) =>
      go(
        getCvDesigns(fcanvas._objects),
        filter((cv_obj) => cv_obj._data.cv_type === 'cv_image' && cv_obj._data.is_base64),
        each((cv_obj) => NewMakerCvObjectUtilF.convertBase64ToImage({ fcanvas, cv_obj })),
      ),
    ),
  );
}

export function changeCvObjByImage({ fcanvas, image, cv_obj }) {
  return go(makeDefaultCvImageAttrs(image), createCvImageP, async (cv_image) => {
    const location = pick(
      ['top', 'left', 'width', 'height', 'scaleX'],
      G.mp.maker.onescreen(cv_obj.toJSON()),
    );
    cv_image.set({ top: location.top, left: location.left });
    cv_image.scale((location.scaleX * location.width) / cv_image.width);
    extend(cv_image, pick(['cid', 'evented', 'selectable'])(cv_obj));
    extend(cv_image._data, pick(['price', 'price_en', 'price_jp', 'is_changeable_image'])(cv_obj._data));
    G.mp.maker.nscreen(cv_image);
    cv_image._data.is_base64 = image.url.includes(';base64,');
    fcanvas.insertAt(cv_image, fcanvas._objects.indexOf(cv_obj));
    fcanvas.remove(cv_obj);
    await G.mp.maker.modified();
    return cv_image;
  });
}

/*is_changeable_image*/
export function addLockedAnyImageToCanvas({ image, location }) {
  return go(makeDefaultCvImageAttrs(image), createCvImageP, async (cv_image) => {
    cv_image.set({ top: location.top, left: location.left });
    cv_image.scale(location.width / cv_image.width);
    cv_image.evented = false;
    cv_image.selectable = false;
    G.mp.maker.nscreen(cv_image);
    cv_image._data.is_base64 = image.url.includes(';base64,');
    cv_image._data.is_changeable_image = true;
    return cv_image;
  });
}

export async function makeImageObjForCvObj(data_url) {
  const arr = data_url.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file = new File([u8arr], 'product_face_design', { type: mime });

  const formData = new FormData();
  formData.append('files[]', file);
  return go(
    $.upload(formData, {
      url: '/@fileUpload/maker/add_img',
      data: { type: 'thumbnail_img' },
    }),
    sel('image'),
    (image) => {
      if (!image) throw Error('no_image');
      return image;
    },
  );
}

export async function convertBase64ToImage({ fcanvas, cv_obj }) {
  return changeCvObjByImage({ fcanvas, image: await makeImageObjForCvObj(cv_obj.src), cv_obj });
}

export function removeAllDesigns() {
  go(
    getFcanvass(),
    each((fcanvas) => {
      go(
        getCvDesigns(fcanvas._objects),
        each((cv_obj) => fcanvas.remove(cv_obj)),
      );
    }),
  );
}

export function removeFcanvasDesigns(fcanvas) {
  return go(
    getCvDesigns(fcanvas._objects),
    each((cv_obj) => fcanvas.remove(cv_obj)),
  );
}

export function addImageUrlToCanvas({ fcanvas, url, location }) {
  return go(makeDefaultCvImageAttrs({ url }), createCvImageP, async (cv_image) => {
    cv_image.set({ top: location.top, left: location.left });
    cv_image.scale(location.width / cv_image.width);
    cv_image.evented = false;
    cv_image.selectable = false;
    G.mp.maker.nscreen(cv_image);
    fcanvas.add(cv_image);
  });
}
