/**
 * OMP Toast 메시지를 표시
 * @param {Object} options - Toast 옵션
 * @param {string} options.text - 표시할 메시지 텍스트
 * @param {number} [options.time=3000] - 메시지가 표시되는 시간 (밀리초 단위)
 * @param {number} [options.delay=0] - 메시지가 표시 시작 시간 지연 (밀리초 단위)
 * @param {Object} [options.position] - Toast 의 위치
 * @param {string} [options.position.vertical='bottom'] - 세로 위치 ('top', 'center', 'bottom')
 * @param {string} [options.position.horizontal='center'] - 가로 위치 ('left', 'center', 'right')
 * @param {Object} [options.style] - 추가적인 스타일 객체
 * @param {HTMLElement} [options.parent] - Toast를 추가할 부모 요소 (기본값: document.body)
 * @returns {Function} Toast 를 제거하는 함수
 */

export const showToast = (options) => {
  const {
    text,
    time = 3000,
    delay = 0,
    position = { vertical: 'bottom', horizontal: 'center' },
    style = {},
    parent = document.body,
  } = options;

  options.time = options.time + options.delay;

  const TOAST_ID = 'omp-core-toast';

  // 기존 toast 요소가 있다면 제거
  const existingToast = document.getElementById(TOAST_ID);
  if (existingToast) {
    existingToast.parentNode.removeChild(existingToast);
  }

  // 새로운 toast 요소 생성
  const toast = document.createElement('div');
  toast.id = TOAST_ID;
  toast.textContent = text;

  // 기본 스타일 설정
  const defaultStyle = {
    position: 'fixed',
    padding: '8px 16px',
    color: 'white',
    borderRadius: '99px',
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
    backdropFilter: 'blur(5px)',
    zIndex: '1000',
    transition: 'opacity 0.3s ease-in-out',
    opacity: '0',
    'text-align': 'center',
    'min-width': '310px',
    'box-sizing': 'border-box',
    'font-size': '14px',
    'font-weight': 500,
  };

  // 위치에 따른 스타일 설정
  switch (position.vertical) {
    case 'top':
      defaultStyle.top = '20px';
      break;
    case 'center':
      defaultStyle.top = '50%';
      defaultStyle.transform = 'translateY(-50%)';
      break;
    case 'bottom':
    default:
      defaultStyle.bottom = '20px';
      break;
  }

  switch (position.horizontal) {
    case 'left':
      defaultStyle.left = '20px';
      break;
    case 'right':
      defaultStyle.right = '20px';
      break;
    case 'center':
    default:
      defaultStyle.left = '50%';
      defaultStyle.transform = (defaultStyle.transform || '') + ' translateX(-50%)';
      break;
  }

  const mergedStyle = { ...defaultStyle, ...style };

  Object.assign(toast.style, mergedStyle);

  // 부모 요소에 toast 요소 추가
  setTimeout(() => {
    parent.appendChild(toast);
  }, delay);

  // 애니메이션을 위해 약간의 지연 후 opacity 변경
  setTimeout(() => {
    toast.style.opacity = '1';
  }, 10);

  const removeToast = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    toast.style.opacity = '0';
    setTimeout(() => {
      if (toast.parentNode) {
        toast.parentNode.removeChild(toast);
      }
    }, 300); // transition 시간과 맞춤
  };

  const timeoutId = setTimeout(removeToast, time);

  return removeToast;
};
