import { go, sumBy } from 'fxjs/es';

/**
 * @param up_c
 * @return {number}
 */
export const getTotalQuantity = (up_c) => {
  return (
    go(
      up_c._.up_c_ss,
      sumBy((up_c_s) => up_c_s.quantity),
    ) || 0
  );
};
