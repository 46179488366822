import { MarketplaceS } from '../../../Marketplace/S/Function/module/MarketplaceS.js';

export const needNessPrice = () => {
  try {
    // return MShopUtilConstantS.NBOX_CREW_ID == box.sel('crew_id') || MarketplaceS.isNessApp();
    return MarketplaceS.isNessApp();
  } catch (e) {
    return false;
  }
};
