import { html, join, go, noop } from 'fxjs/es';
import { SIZE_TIP_TEXT, SMALL_PRODUCT_BP_IDS } from '../constant.js';
import { CateListConstantS } from '../Constant/module/CateListConstantS.js';

const ACC_TIP = ({ id }) => (!SMALL_PRODUCT_BP_IDS.includes(id) ? SIZE_TIP_TEXT.TOLERANCE : '');

const SIZE_TIP_LIST_TMPL = {
  [CateListConstantS.CLOTH_LIST_ID]: ({ id }) => {
    return go([ACC_TIP({ id }), SIZE_TIP_TEXT.CHECK_REAL_DETAIL_SIZE, SIZE_TIP_TEXT.SIZE], join(''));
  },
  [CateListConstantS.ACCESSORY_LIST_ID]: ({ id }) => {
    return go(
      [ACC_TIP({ id }), SIZE_TIP_TEXT.CHECK_REAL_DETAIL_SIZE, SIZE_TIP_TEXT.ONLY_MARPPLE_SIZE],
      join(''),
    );
  },
  [CateListConstantS.HOME_DECO_LIST_ID]: ({ id }) => {
    return go(
      [ACC_TIP({ id }), SIZE_TIP_TEXT.CHECK_REAL_DETAIL_SIZE, SIZE_TIP_TEXT.ONLY_MARPPLE_SIZE],
      join(''),
    );
  },
  [CateListConstantS.PHONE_ACC_LIST_ID]: ({ cate_item_id }) => {
    return cate_item_id === CateListConstantS.AIR_POD_BUDS
      ? ''
      : go([SIZE_TIP_TEXT.PHONE_CASE_SIZE], join(''));
  },
  [CateListConstantS.KIDS_LIST_ID]: ({ id }) => {
    return go([ACC_TIP({ id }), SIZE_TIP_TEXT.CHECK_REAL_DETAIL_SIZE, SIZE_TIP_TEXT.SIZE], join(''));
  },
  /* TODO 추가 카테고리 - 애견 */
  [CateListConstantS.SPORTS_LIST_ID]: ({ id, cate_item_id }) => {
    return cate_item_id === CateListConstantS.GOLF_BALL_ITEM_ID
      ? ''
      : go(
          [
            ACC_TIP({ id }),
            SIZE_TIP_TEXT.CHECK_REAL_DETAIL_SIZE,
            SIZE_TIP_TEXT.SIZE,
            SIZE_TIP_TEXT.PANTS_RISE_SIZE,
          ],
          join(''),
        );
  },
};

export const SizeTipTmpl = ({ id, cate_list_id, cate_item_id, size_info_url }) => {
  return html`
    <div class="size_tip" has_size_info_url="${!!size_info_url}">
      ${size_info_url &&
      [
        CateListConstantS.CLOTH_LIST_ID,
        CateListConstantS.ACCESSORY_LIST_ID,
        CateListConstantS.HOME_DECO_LIST_ID,
        CateListConstantS.PHONE_ACC_LIST_ID,
        CateListConstantS.SPORTS_LIST_ID,
      ].includes(cate_list_id)
        ? html`<div class="size_info_url">
            <img src="${size_info_url}" alt="" />
          </div>`
        : ''}
      <ul>
        ${(SIZE_TIP_LIST_TMPL[cate_list_id] || noop)({ id, cate_item_id })}
      </ul>
    </div>
  `;
};
