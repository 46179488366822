import { $appendTo, $el, $outerHeight, $remove, $setCss } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MShopUtilF } from '../../../MShop/Util/F/Function/module/MShopUtilF.js';

export const makeFrameHeight = async (frame, tab) => {
  const is_mobile = MShopUtilF.isMobile();
  if (!is_mobile) return;
  frame.prev_frame_show = true;
  const data = await tab.makeData(tab);
  tab.makeData = () => data;

  const _html = tab.template(data);
  tab.template = () => _html;

  const tmpl_el = go(
    $el(_html),
    $setCss({ position: 'fixed', left: 0, 'z-index': -10 }),
    $appendTo(document.body),
  );

  const height = $outerHeight(tmpl_el);

  $remove(tmpl_el);
  const result = (frame.header_height || 0) + height;
  frame.height = result >= window.innerHeight ? 0 : result;
  if (frame.height) MShopUtilF.bodyFixed$(true);
  /*
   * MShopUtilF.bodyFixed$(false); closed 에 꼭 써주세요
   * */
  return frame.height;
};
