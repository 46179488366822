export function checkPhoneNumberSms(number) {
  // var chk_number_ex = /((00\d{1,3}-?\d{1,3}-\d{1,4})|(0\d{1,2}))-\d{3,4}-\d{4}/; 국제?
  const chk_number = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
  return chk_number.test(number);
}

export function makeKoreaPhoneSms(number) {
  number = number.replace(/[^0-9]/g, '');
  return '82-' + number.substring(1);
}
