import { equals2, go } from 'fxjs/es';
import { $closest } from 'fxdom/es';

export const handleLayerCloseClick =
  () =>
  ({ currentTarget: close_layer_el }) => {
    go(close_layer_el, $closest(`.layer_container`), (el) => ((el?.dataset ?? {}).is_show = 'false'));
  };

export const handleLayerLayerItemClick =
  ({ tab_el }) =>
  ({ currentTarget: layer_item_el }) => {
    const el = layer_item_el?.__mp_original_el;
    if (!el) {
      return;
    }

    const is_selected = tab_el.__mp_single_sticker_editor?.getSelectedEls?.()?.has(el) ?? false;
    if (is_selected) {
      tab_el.__mp_single_sticker_editor?.unselectEl(el);
    } else {
      tab_el.__mp_single_sticker_editor?.selectEl(el);
    }
  };

export const handleLayerLayerItemLockClick =
  ({ tab_el }) =>
  (event) => {
    event.stopPropagation();

    const { currentTarget: layer_item_lock_button_el } = event;
    const layer_item_el = $closest('.layer_item')(layer_item_lock_button_el);
    const el = layer_item_el?.__mp_original_el;
    if (!el) {
      return;
    }

    const is_locked = tab_el.__mp_single_sticker_editor?.getIsLocked?.(el) ?? false;
    if (is_locked) {
      tab_el.__mp_single_sticker_editor?.unlockEl?.(el);
    } else {
      tab_el.__mp_single_sticker_editor?.lockEl?.(el);
    }
  };

export const handleLayerLayerItemVisibleClick =
  ({ tab_el }) =>
  (event) => {
    event.stopPropagation();

    const { currentTarget: layer_item_visible_button_el } = event;
    const layer_item_el = $closest('.layer_item')(layer_item_visible_button_el);
    const el = layer_item_el?.__mp_original_el;
    if (!el) {
      return;
    }

    const is_visible = !equals2('none')(el.getAttributeNS(null, 'display'));
    if (is_visible) {
      tab_el.__mp_single_sticker_editor?.hideEl?.(el);
    } else {
      tab_el.__mp_single_sticker_editor?.showEl?.(el);
    }
  };
