import { filter, go, identity, ifElse, pipe } from 'fxjs/es';
import { $closest, $delegate, $find, $findAll } from 'fxdom/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { changeCurrentTab, getCheckValue, getSelectValue, renderColorTestPage } from './fs.js';

export const optionTmpl = ({ contrast }) => legacyHtml`
  <button type="button" class="to_small">작게</button>
  <button type="button" class="to_big">크게</button>
  <form action="" class="composite_mask_contrast_form">
    <label for="composite_mask_contrast_input">contrast : </label>
    <input
      type="number"
      id="composite_mask_contrast_input"
      min="-1"
      max="1"
      value="${contrast || 0}"
      step="0.1"
    />
    <button type="button" class="apply">적용</button>
    <button type="button" class="save">저장</button>
  </form>
`;

export const color_test_tab = {
  tab_name: 'color_test_tab',
  title: '색상 테스트',
  template: () => legacyHtml`
    <div class="option"></div>
    <div class="list_wrapper"></div>
  `,
  showed: async function (don_tab) {
    const option_el = $find('.option')(don_tab);
    changeCurrentTab(don_tab);
    const { assoc_composite_templates, base_product_colors } = go(
      don_tab,
      $closest('.don_page'),
      (page) => page.page_opt,
    );
    const list_wrapper_el = $find('.list_wrapper', don_tab);
    if ($findAll('.img_wrapper', don_tab).length > 0) return;
    async function _render() {
      const selected_base_product_colors = go(
        getSelectValue(don_tab, 'base_product_color_id'),
        ifElse(
          identity,
          (bpc_id) => filter((bpc) => bpc_id === bpc.id, base_product_colors),
          () => base_product_colors,
        ),
      );
      const composite_template_ids = getCheckValue(don_tab, 'composite_template_id');
      if (composite_template_ids.length === 0) return;
      const selected_assoc_composite_templates = go(
        assoc_composite_templates,
        filter((ct) => composite_template_ids.includes(ct.id)),
      );
      await renderColorTestPage(
        list_wrapper_el,
        selected_assoc_composite_templates,
        selected_base_product_colors,
      );
      if (selected_assoc_composite_templates.length === 1) {
        option_el.innerHTML = optionTmpl(selected_assoc_composite_templates[0]._.composite_masks[0]);
      } else {
        option_el.innerHTML = ``;
      }
    }
    don_tab.tab_opt.reset_render = async function () {
      list_wrapper_el.innerHTML = '';
      $.don_loader_start();
      await _render();
      $.don_loader_end();
    };
  },
  appended: pipe(
    $delegate('click', '.to_small', (e) => {
      const tab_el = $closest('.don_tab')(e.currentTarget);
      tab_el.dataset.view_type = 'small';
    }),
    $delegate('click', '.to_big', (e) => {
      const tab_el = $closest('.don_tab')(e.currentTarget);
      tab_el.dataset.view_type = 'big';
    }),
  ),
};
