export const TYPE = {
  MATERIAL: 'MATERIAL',
  MODIFY: 'MODIFY',
};

export const infos = () => ({
  MODIFY: {
    title: TT('modify_info::title'),
    tabs: [
      {
        id: 1,
        name: TT('modify_info::1::name'),
        description: TT('modify_info::1::description'),
        images: [
          {
            img: '//s3.marpple.co/files/u_2798351/2024/3/original/a76d57445b04f1f8bff5fb466edfe788fff181e55.png',
            img_mo:
              '//s3.marpple.co/files/u_2798351/2024/3/original/2cfe5ca72a5b87f7556dc8bdbeb5f3dbf79541a41.png',
            content: TT('modify_info::1::content_1'),
          },
          {
            img: '//s3.marpple.co/files/u_2798351/2024/3/original/6b7c39d13ea1af24218af9eb73be2611f42d45446.png',
            img_mo:
              '//s3.marpple.co/files/u_2798351/2024/3/original/2cfe5ca72a5b87f7556dc8bdbeb5f3dbf79541a41.png',
            content: TT('modify_info::1::content_2'),
          },
        ],
      },
      {
        id: 2,
        name: TT('modify_info::2::name'),
        description: TT('modify_info::2::description'),
        images: [
          {
            img: '//s3.marpple.co/files/u_2798351/2024/3/original/ff4df4a974002656c7649be2760e62581221d70b8.png',
            img_mo:
              '//s3.marpple.co/files/u_2798351/2024/3/original/c9feea1d001562145480f2625c2ff3546927b7294.jpg',
            content: TT('modify_info::2::content_1'),
          },
        ],
      },
      {
        id: 3,
        name: TT('modify_info::3::name'),
        description: TT('modify_info::3::description'),
        images: [
          {
            img: '//s3.marpple.co/files/u_2798351/2024/3/original/3445810fd0577d5a823d81ecf37c1d15899ac0967.png',
            img_mo:
              '//s3.marpple.co/files/u_2798351/2024/3/original/8e343c539da0bed22f39a1b139a54c4fd26ace7c3.jpg',
            content: TT('modify_info::3::content_1'),
          },
        ],
      },
      {
        id: 4,
        name: TT('modify_info::4::name'),
        description: TT('modify_info::4::description'),
      },
    ],
  },
  MATERIAL: {
    title: TT('material_info::title'),
    tabs: [
      {
        name: TT('material_info::name::1'),
        description: TT('material_info::description::1'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::3'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::4'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::1'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/dcc24b1ee617e81aba020f85cc634866486f5f461.png',
      },
      {
        name: TT('material_info::name::2'),
        description: TT('material_info::description::2'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::1'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::1'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::1'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/38f2543eef539b6f3b83e03a9fe688e47d3dc18a2.png',
      },
      {
        name: TT('material_info::name::3'),
        description: TT('material_info::description::3'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::6'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::5'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::12'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/c7bfac6f499577b24764595028e5e811e21eda593.png',
      },
      {
        name: TT('material_info::name::4'),
        description: TT('material_info::description::4'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::4'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::3'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::12'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/e34fddc4f74fa3ad7148d40d3673f7394d122a9a4.png',
      },
      {
        name: TT('material_info::name::5'),
        description: TT('material_info::description::5'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::5'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::22'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::1'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/41b93d9bc12d23bc33b78d2176755e231c707a915.png',
      },
      {
        name: TT('material_info::name::6'),
        description: TT('material_info::description::6'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::7'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::6'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::4'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/4/original/12e18f85652515da898a0683458380d78edfc8e01.png',
      },
      {
        name: TT('material_info::name::7'),
        description: TT('material_info::description::7'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::8'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::7'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::5'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/e44ea0b9b33145996a4e05447ca8b1caff84965d22.png',
      },
      {
        name: TT('material_info::name::8'),
        description: TT('material_info::description::8'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::4::content::6'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::8'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::3'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::7'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/cf8c7fe6605f141a081a190ca5007afe77439a2b7.png',
      },
      {
        name: TT('material_info::name::9'),
        description: TT('material_info::description::9'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::9'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::9'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::8'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/f058f8b1af6e60825243379b72033c89921fe17d8.png',
      },
      {
        name: TT('material_info::name::10'),
        description: TT('material_info::description::10'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::10'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::10'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::4'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::8'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/cafa06dae6b8a0505cf409257e4097abda3a6b6b9.png',
      },
      {
        name: TT('material_info::name::11'),
        description: TT('material_info::description::11'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::11'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::11'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::9'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/d3771904f915be11a54eb6a0721063a8413b41e517.png',
      },
      {
        name: TT('material_info::name::12'),
        description: TT('material_info::description::12'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::12'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::12'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::1'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/6d97ff5c86649e38ca3b1a93762ec3367ff39ce01.png',
      },
      {
        name: TT('material_info::name::13'),
        description: TT('material_info::description::13'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::13'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::13'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::9'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/542927d8f41d8499ef8c651f1c013faf84d92faa19.png',
      },
      {
        name: TT('material_info::name::14'),
        description: TT('material_info::description::14'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::14'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::14'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::5'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::10'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/f8bd52e098f2c8ffd88ccf3edad6a0e2342b065f20.png',
      },
      {
        name: TT('material_info::name::15'),
        description: TT('material_info::description::15'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::2'),
          },
          {
            content: TT('material_info::info::2::content::2'),
            title: TT('material_info::info::2::title'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::2'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/e44ea0b9b33145996a4e05447ca8b1caff84965d22.png',
      },
      {
        name: TT('material_info::name::16'),
        description: TT('material_info::description::16'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::15'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::15'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::2'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/ae1a0959dfb4c3ab031b6c4eb9f50e3b35ff209a24.png',
      },
      {
        name: TT('material_info::name::17'),
        description: TT('material_info::description::17'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::2'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::16'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::2'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/235272fb08575b0477eb5acaec27e9643b6460b323.png',
      },
      {
        name: TT('material_info::name::18'),
        description: TT('material_info::description::18'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::15'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::17'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::2'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/6c055909ed236d9a14e1638ee4f0887fcdf1552225.png',
      },
      {
        name: TT('material_info::name::19'),
        description: TT('material_info::description::19'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::16'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::18'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::11'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/29dd578c76057ba89184c0639c067b0e6488ad2e22.png',
      },
      {
        name: TT('material_info::name::20'),
        description: TT('material_info::description::20'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::17'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::19'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::11'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/1548eb5fd62ba40b357bce6078b4778b4a5a4e9626.png',
      },
      {
        name: TT('material_info::name::21'),
        description: TT('material_info::description::21'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::18'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::20'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::2'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::9'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/29dd578c76057ba89184c0639c067b0e6488ad2e22.png',
      },
      {
        name: TT('material_info::name::22'),
        description: TT('material_info::description::22'),
        info: [
          {
            title: TT('material_info::info::1::title'),
            content: TT('material_info::info::1::content::19'),
          },
          {
            title: TT('material_info::info::2::title'),
            content: TT('material_info::info::2::content::21'),
          },
          {
            title: TT('material_info::info::3::title'),
            content: TT('material_info::info::3::content::4'),
          },
          {
            title: TT('material_info::info::4::title'),
            content: TT('material_info::info::4::content::8'),
            content2: TT('material_info::info::4::content::3'),
          },
        ],
        img: '//s3.marpple.co/files/u_2798351/2024/3/original/cb7bb542ddb0b1d0956e797de8daa38ed3709de921.png',
      },
    ],
  },
});
