import { html } from 'fxjs/es';

export const makeLayerItemHtml = ({
  label = '',
  is_selected = false,
  is_visible = true,
  is_locked = false,
} = {}) =>
  html`
    <div
      class="layer_item"
      data-is_selected="${!!is_selected}"
      data-is_visible="${!!is_visible}"
      data-is_locked="${!!is_locked}"
    >
      <div class="image_container"></div>
      <div class="label_container">
        <span class="layer_label">${label}</span>
      </div>
      <div class="button_container">
        <button type="button" class="visible"></button>
        <button type="button" class="lock"></button>
      </div>
    </div>
  `;
