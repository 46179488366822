import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSingleMobileCuttingLineManualMuiF } from '../Mui/module/VectorEditorStickerSingleMobileCuttingLineManualMuiF.js';

export const makeCuttingLineManual = ({ target_image_el, path_data, postProcess }) =>
  MuiF.pushPage(VectorEditorStickerSingleMobileCuttingLineManualMuiF.page, (page, [tab]) => {
    const tab_appending = tab.appending;
    tab.appending = async (tab_el) => {
      tab_el.__mp_target_image_el = target_image_el;
      tab_el.__mp_cutting_line_data = path_data;
      tab_el.__mp_postProcess = postProcess;
      return tab_appending.call(tab, tab_el);
    };
  }).catch(() => {
    /** @todo alert message */
    MuiF.popPage().catch(console.error);
  });
