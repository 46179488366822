import {
  $attr,
  $closest,
  $delegate,
  $find,
  $findAll,
  $qs,
  $setText,
  $setVal,
  $siblings,
  $val,
} from 'fxdom/es';
import { each, entries, every, extend, find, go, head, ippL, map, object, reduce, sel, some } from 'fxjs/es';
import { createCanvasElement, loadImageFromUrl, makeCanvasByUrl } from '../../../../Canvas/S/util.js';
import { NewMakerBpsTemplateMetaMuiS } from '../../S/Mui/module/NewMakerBpsTemplateMetaMuiS.js';
import { checkTemplateIsDone } from '../Function/bpstemplatemeta.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */

function makeGrid(canvas, width) {
  const ctx = canvas.getContext('2d');
  ctx.strokeStyle = '#000000';
  ctx.lineWidth = 3;
  for (let y = 0; y < canvas.height; ) {
    for (let x = 0; x < canvas.width; ) {
      ctx.strokeRect(x, y, width, width);
      x += width;
    }
    y += width;
  }
  return canvas;
}

function makeObj(tab_el) {
  return go(
    tab_el,
    $findAll('.bps_template_meta_editor'),
    map((bps_template_meta_editor_el) =>
      go(bps_template_meta_editor_el, $findAll('form'), (form_els) => {
        return reduce(
          (mem, form_el) => {
            const obj_name = form_el.dataset.obj_name;
            const form_data = go(
              form_el,
              $findAll('[name]'),
              map((input_el) => {
                const name = $attr('name')(input_el);
                const type = $attr('type')(input_el);
                const data_type = input_el.dataset.type;
                let value = $val(input_el);
                if (data_type === 'json') {
                  value = value ? JSON.parse(value) : '';
                } else if (type === 'number') {
                  value = parseFloat(parseFloat(value).toFixed(4));
                } else if (type === 'checkbox') {
                  value = input_el.checked;
                }
                return [name, value];
              }),
              object,
            );
            if (obj_name === 'item') {
              mem.items = mem.items || [];
              mem.items.push(form_data);
            } else if (obj_name === 'qr_data') {
              mem[obj_name] = go(
                form_data,
                entries,
                every(([k, v]) => v),
              )
                ? form_data
                : null;
            } else if (obj_name) {
              mem[obj_name] = form_data;
            } else {
              extend(mem, form_data);
            }
            return mem;
          },
          {},
          form_els,
        );
      }),
    ),
  );
}

async function renderSize(bps_template_meta_editor_el, item, dpi, base_product_face_id, obj_key) {
  const label_el = go(
    bps_template_meta_editor_el,
    $findAll(`[name="base_product_face_id"]`),
    find((el) => $val(el) == base_product_face_id),
    $closest('.obj_wrapper'),
    $find(`[name="${obj_key}"]`),
    $siblings('label'),
    head,
  );
  if (item[obj_key]) {
    const img = await loadImageFromUrl(item[obj_key]);
    go(label_el, $find('.size_px'), $setText(`(${img.width} x ${img.height} px ):`));
    go(
      label_el,
      $find('.size_cm'),
      $setText(`(${((img.width / dpi) * 2.54).toFixed(4)} x ${((img.height / dpi) * 2.54).toFixed(4)} cm ):`),
    );
  } else {
    go(label_el, $find('.size_px'), $setText(``));
    go(label_el, $find('.size_cm'), $setText(``));
  }
}

async function renderTopLayerSize(tab_el) {
  const templates = makeObj(tab_el);
  await go(
    templates,
    ippL,
    each(([idx, template]) => {
      const bps_template_meta_editor_el = go(
        tab_el,
        $findAll('.bps_template_meta_editor'),
        (els) => els[idx],
      );
      const template_size_el = go(bps_template_meta_editor_el, $find('[data-obj_name="size"]'));
      go(
        template_size_el,
        $find('.width_px'),
        $setText(Math.round((template.size.width / 2.54) * template.dpi)),
      );
      go(
        template_size_el,
        $find('.height_px'),
        $setText(Math.round((template.size.height / 2.54) * template.dpi)),
      );
      return go(
        template.items,
        each(async (item) => {
          const base_product_face_id = item.base_product_face_id;
          await renderSize(
            bps_template_meta_editor_el,
            item,
            template.dpi,
            base_product_face_id,
            'top_layer_url',
          );
          await renderSize(bps_template_meta_editor_el, item, template.dpi, base_product_face_id, 'mask_url');
        }),
      );
    }),
  );
}
async function isTestItemSizePass(url, template_meta, item) {
  const img = await makeCanvasByUrl(url);
  const px_width = Math.round((item.width / 2.54) * template_meta.dpi);
  const px_height = Math.round((item.height / 2.54) * template_meta.dpi);
  if (img.width !== px_width) {
    await $.alert(`실제 width: ${img.width}, 템플릿 width: ${px_width} 아이템 크기를 조절해주세요.`);
    return;
  }
  if (img.height !== px_height) {
    await $.alert(`실제 width: ${img.height}, 템플릿 width: ${px_height} 아이템 크기를 조절해주세요.`);
    return;
  }
  return true;
}
export const tab = {
  ...NewMakerBpsTemplateMetaMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  async appended(tab_el) {
    const { base_product_id } = go(tab_el, $find('.bps_template_meta_editor'), (el) => el.dataset);
    // const base_product_size = await $.get('/@api/prerequisite_maker/base_product_size');
    await renderTopLayerSize(tab_el);
    go(
      tab_el,
      $delegate('input', '[data-obj_name="size"] input', (e) => {
        renderTopLayerSize(tab_el);
      }),
      $delegate('input', '[name="dpi"]', () => {
        renderTopLayerSize(tab_el);
      }),
      $delegate('click', '.make_auto_print_item_url', async (e) => {
        $.don_loader_start();
        const item_color = '#ffff00';
        const print_area_line_color = '#ff0000';
        const print_color = '#ff6464';
        const product_area_line_color = '#49ff00';
        const product_color = '#abff8c';
        const safety_area_line_color = '#0015ff';
        const safety_color = '#8282ff';
        const func = ({ width, height, line_width, dpi, color, line_color }) => {
          const px_width = (width / 2.54) * dpi;
          const px_height = (height / 2.54) * dpi;
          const canvas = createCanvasElement({ width: px_width, height: px_height });
          const ctx = canvas.getContext('2d');
          ctx.fillStyle = color;
          ctx.fillRect(0, 0, px_width, px_height);
          ctx.strokeStyle = line_color;
          ctx.lineWidth = ((line_width * 2) / 2.54) * dpi;
          ctx.strokeRect(0, 0, px_width, px_height);
          return canvas;
        };
        const func2 = ({ width, height }, { top, right, bottom, left }) => {
          return {
            top,
            left,
            width: width - right - left,
            height: height - top - bottom,
          };
        };
        const bps_template_meta_editor_el = go(e.currentTarget, $closest('.bps_template_meta_editor'));
        const dpi = go(bps_template_meta_editor_el, $find('[name="dpi"]'), $val, parseFloat);
        const item_el = go(e.currentTarget, $closest('[data-obj_name="item"]'));
        // print_item_url
        const template_meta_item_size = go(item_el, (item_el) => {
          return {
            width: go($find('input[name="width"]')(item_el), $val, parseFloat),
            height: go($find('input[name="height"]')(item_el), $val, parseFloat),
          };
        });
        const print_area = func2(template_meta_item_size, {
          top: go($find('input[name="print_margin_top"]')(item_el), $val, parseFloat),
          right: go($find('input[name="print_margin_right"]')(item_el), $val, parseFloat),
          bottom: go($find('input[name="print_margin_bottom"]')(item_el), $val, parseFloat),
          left: go($find('input[name="print_margin_left"]')(item_el), $val, parseFloat),
        });
        const product_area = func2(template_meta_item_size, {
          top: go($find('input[name="product_margin_top"]')(item_el), $val, parseFloat),
          right: go($find('input[name="product_margin_right"]')(item_el), $val, parseFloat),
          bottom: go($find('input[name="product_margin_bottom"]')(item_el), $val, parseFloat),
          left: go($find('input[name="product_margin_left"]')(item_el), $val, parseFloat),
        });
        const safety_area = func2(template_meta_item_size, {
          top: go($find('input[name="safety_margin_top"]')(item_el), $val, parseFloat),
          right: go($find('input[name="safety_margin_right"]')(item_el), $val, parseFloat),
          bottom: go($find('input[name="safety_margin_bottom"]')(item_el), $val, parseFloat),
          left: go($find('input[name="safety_margin_left"]')(item_el), $val, parseFloat),
        });
        const line_width = 0.1;
        const canvas = go(
          func({
            width: template_meta_item_size.width,
            height: template_meta_item_size.height,
            line_width,
            dpi,
            color: item_color,
            line_color: item_color,
          }),
          (template_meta_item_c) => {
            const ctx = template_meta_item_c.getContext('2d');
            if (!Number.isNaN(print_area.width) && !Number.isNaN(print_area.height)) {
              const print_c = func({
                width: print_area.width,
                height: print_area.height,
                dpi,
                line_width,
                color: print_color,
                line_color: print_area_line_color,
              });
              ctx.drawImage(
                print_c,
                0,
                0,
                print_c.width,
                print_c.height,
                (print_area.left / 2.54) * dpi,
                (print_area.top / 2.54) * dpi,
                print_c.width,
                print_c.height,
              );
            }
            if (!Number.isNaN(product_area.width) && !Number.isNaN(product_area.height)) {
              const product_c = func({
                width: product_area.width,
                height: product_area.height,
                dpi,
                line_width,
                color: product_color,
                line_color: product_area_line_color,
              });
              ctx.drawImage(
                product_c,
                0,
                0,
                product_c.width,
                product_c.height,
                (product_area.left / 2.54) * dpi,
                (product_area.top / 2.54) * dpi,
                product_c.width,
                product_c.height,
              );
            }
            if (!Number.isNaN(safety_area.width) && !Number.isNaN(safety_area.height)) {
              const safety_c = func({
                width: safety_area.width,
                height: safety_area.height,
                dpi,
                line_width,
                color: safety_color,
                line_color: safety_area_line_color,
              });
              ctx.drawImage(
                safety_c,
                0,
                0,
                safety_c.width,
                safety_c.height,
                (safety_area.left / 2.54) * dpi,
                (safety_area.top / 2.54) * dpi,
                safety_c.width,
                safety_c.height,
              );
            }
            const grid_c = makeGrid(createCanvasElement(template_meta_item_c), (1 / 2.54) * dpi);
            ctx.drawImage(grid_c, 0, 0);
            return template_meta_item_c;
          },
        );
        const url = await go(
          $.uploadFileToUrl(canvas.toDataURL(), 'make_auto_print_item_url', 'png'),
          sel('url'),
        );
        go(item_el, $find('[name="print_item_url"]'), $setVal(url));
        $.don_loader_end();
      }),
      $delegate('change', '[type="file"]', async ({ currentTarget }) => {
        $.don_loader_start();
        const { base_product_size_id } = go(
          currentTarget,
          $closest('.bps_template_meta_editor'),
          (el) => el.dataset,
        );
        const { url } = await $.upload(currentTarget, {
          data: { attached_type: 'base_product_size_template_meta', attached_id: base_product_size_id },
        });
        const file_wrapper = $closest('.file_wrapper')(currentTarget);
        go(file_wrapper, $find('input[type="text"]'), $setVal(url));
        await renderTopLayerSize(tab_el);
        $.don_loader_end();
      }),
      $delegate('click', '.save', async (e) => {
        const { base_product_size_id } = go($qs('.bps_template_meta_editor'), (el) => el.dataset);
        const will_affect_pf = await $.confirm(
          '면 작업에 영향을 주시겠습니까?(면작업 칼선 템플릿 수치와 칼선 이미지에 영향을 줍니다.)',
        );
        await go(makeObj(tab_el), async (template_meta) => {
          $.don_loader_start();
          if (
            template_meta.version === '' &&
            !(await $.confirm(
              '과거의 템플릿 수정은 개발팀의 확인이 필요합니다. 그럼에도 계속 진행 하시겠습니까?',
            ))
          ) {
            $.don_loader_end();
            return;
          }

          if (template_meta.version === 'v20230902') {
            const bool = await go(
              template_meta.items,
              some(async (item) => {
                if (item.mask_url) {
                  return !(await isTestItemSizePass(item.mask_url, template_meta, item));
                }
                if (item.print_item_url) {
                  return !(await isTestItemSizePass(item.print_item_url, template_meta, item));
                }
              }),
            );
            if (bool) {
              $.alert('저장에 실패 했습니다');
              $.don_loader_end();
              return;
            }
          }

          await $.post('/@api/prerequisite_maker/update_template_meta', {
            id: base_product_size_id,
            template_meta,
            will_affect_pf,
          });
          $.don_loader_end();
        });
      }),
      $delegate('click', '.template_done', (e) => {
        const { base_product_size_id } = go($qs('.bps_template_meta_editor'), (el) => el.dataset);
        go(makeObj(tab_el), async (template_meta) => {
          $.don_loader_start();
          if (
            await checkTemplateIsDone({
              template_meta,
              product_color_id: box().bp.product_color_id,
              base_product_size_id,
            })
          ) {
            await $.post('/@api/base_product/update_has_template', { base_product_id, has_template: true });
            await $.post('/@api/prerequisite_maker/update_template_meta', {
              id: base_product_size_id,
              template_meta,
            });
          }
          $.don_loader_end();
        });
      }),
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
