import { filter, find, flat, go, map, sel, sumBy } from 'fxjs/es';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPMyPageOrderConstantS } from '../Constant/module/OMPMyPageOrderConstantS.js';
import { OMPMyPageOrderS } from './module/OMPMyPageOrderS.js';
import { ShippingS } from '../../../../../Shipping/S/Function/module/ShippingS.js';

export const makeUpcProductPrice = (up_c, currency_en = G._en) => ({
  discounted_price: PriceS.pricify_by(
    sumBy((up_c_s) => PriceS.mult(up_c_s['discounted_price' + currency_en], up_c_s.quantity), up_c._.up_c_ss),
    currency_en,
  ),
  price: PriceS.pricify_by(
    sumBy((up_c_s) => PriceS.mult(up_c_s['price' + currency_en], up_c_s.quantity), up_c._.up_c_ss),
    currency_en,
  ),
});

export const makeUpcUrl = (up_c) => {
  const ev = sel('_.product_color.product_faces2.ev', up_c);
  if (ev?.url) {
    return `/${T.lang}/${ev.url}?bp_id=${up_c.base_product_id}&pc_id=${up_c.product_color_id}`;
  }
  return `/${TT.lang}/product/detail?bp_id=${up_c.base_product_id}&pc_id=${up_c.product_color_id}`;
};

export const makeShippingCheckLink = (shipping) => {
  const {
    shipping_company_id,
    waybill_number,
    _: {
      country: { country_code } = {},
      shipping_company_service: { service_name: shipping_company_service_name } = {},
    },
  } = shipping;
  if (shipping_company_id === OMPMyPageOrderConstantS.SHIPPING_COMPANY.CJ)
    return `https://trace.cjlogistics.com/next/tracking.html?wblNo=${waybill_number}`;
  if (shipping_company_id === OMPMyPageOrderConstantS.SHIPPING_COMPANY.LOTTELOGI)
    return `https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=${waybill_number}`;

  return ShippingS.getOverseaTrackingUrl({
    shipping_company_id,
    waybill_number,
    shipping_company_service_name,
    country_code,
  });
};

export const makeTableProjections = (projections) =>
  go(
    projections,
    map((prj) =>
      go(
        prj._.ups,
        map((up) =>
          go(
            up._.up_cs,
            map((up_c) => ({
              ...up_c,
              table_data: {
                status: prj.status,
                is_cancel_req: prj.is_cancel_req,
                is_canceled: prj.is_canceled,
                shipping: prj?._?.shippings?.[0],
                order_detail_url: `/${TT.lang}/order/detail/${prj.id}`,
                ordered_at: prj.ordered_at,
                created_at: prj.created_at,
                prj_id: prj.id,
                currency_en: prj.lang == 'kr' ? '' : `_${prj.lang}`,
              },
            })),
          ),
        ),
        flat,
      ),
    ),
  );

export const makeUpDetail = (up_c, projection) => {
  const order_detail_url = `/${TT.lang}/order/detail/${projection.id}`;
  const select_option_name = up_c._.product_color._.selected_option_group[OMPCoreUtilS.col('title')];
  const can_purchase_url =
    up_c.base_product_id && up_c._.product_color._.base_product.id ? OMPMyPageOrderS.makeUpcUrl(up_c) : false;

  const infos = go(
    up_c._.up_c_ss,
    filter((up_c_s) => up_c_s.quantity),
    map((up_c_s) => {
      return {
        color: up_c._.product_color._.base_product_color[OMPCoreUtilS.col('name')],
        size: up_c_s._.base_product_size[OMPCoreUtilS.col('name')],
        select_option_name,
        quantity: UtilS.commify(up_c_s.quantity),
      };
    }),
  );

  return {
    thumbnail: {
      product_face: sel('_.product_color.product_faces2.value.0', up_c),
      thumbnail_url: go(sel('_.product_color.thumbnails.value', up_c), find(sel('is_thumb')), sel('url')),
    },
    name: up_c._.product_color._.base_product[OMPCoreUtilS.col('name')],
    infos,
    can_purchase_url,
    order_detail_url,
  };
};

export const getCurrency = (price, _en) => {
  const is_minus = price.includes('-');
  return go(
    price.replace(/\s*-\s*/, ''),
    (price) => {
      if (_en == '_en') {
        return '$' + price;
      } else if (_en == '_jp') {
        return price + '円';
      } else {
        return price + '원';
      }
    },
    (price_text) => {
      if (is_minus) {
        return `-${price_text}`;
      } else {
        return price_text;
      }
    },
  );
};
