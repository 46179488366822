import { go } from 'fxjs/es';
import { OMPProductListF } from '../../../ProductList/F/Function/module/OMPProductListF.js';
import { OMPMyPageOrderF } from '../../Order/F/Function/module/OMPMyPageOrderF.js';
import { OMPMyPageF } from './module/OMPMyPageF.js';

export const event = (tab_el) => {
  return go(
    tab_el,
    OMPMyPageF.delegateWriteReview,
    OMPMyPageOrderF.tablePaginationDelegate({
      duration: 1,
      empty_notice: TT('my_page::order::order_22'),
      tab_el,
    }),
    OMPProductListF.delegateBaseProductLike,
    OMPMyPageF.delegateFileDownload,
    OMPMyPageF.delegateCheckModifications,
    OMPMyPageF.delegateClickResubmit,
  );
};
