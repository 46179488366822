!(function () {
  $.frame.defn_frame({
    frame_name: 'df.stocks_frame',
    el_class: 'df_bp_stocks',
  });

  $.frame.defn_page({
    page_name: 'df.stocks_page',
    tabs: [
      {
        tab_name: 'df.stocks_tab',
        appended: __(
          $.on('keyup', 'input[name="bp"]', function (e) {
            if (e.keyCode != 13) return;

            const input = $.find1($.closest(e.currentTarget, '.options'), 'input[name="bp"]');
            const val = $.val(input);
            const bp = _p.find(box.sel('bp_list'), function (bp) {
              return bp.name == val;
            });

            if (!bp) return;

            $.trigger($.find1($.closest(e.currentTarget, '.options'), '.btn_search'), 'click');
          }),
          $.on2('click', '.btn_search', function (e) {
            const input = $.find1($.closest(e.currentTarget, '.options'), 'input[name="bp"]');
            const select = $.find1($.closest(e.currentTarget, '.options'), 'select[name="bpcs"]');
            const val = $.val(input);
            const bp = _p.find(box.sel('bp_list'), function (bp) {
              return bp.name == val;
            });
            const bpc_id = $.val(select);
            const query_obj = _p.extend({}, bp && { bp_id: bp.id }, bpc_id && { bpc_id: bpc_id });

            if (!bp) {
              $.alert('전체 검색');
              $.val(input, '');
              $.html(select, '<option value="">색상</option>');

              delete query_obj.bpc_id && query_obj.bpc_id;
            }

            return _p.go(
              $.get('/@api/df/stocks/total', _p.extend({}, query_obj)),
              _p
                .if2(_p.v('result'))(
                  function (data) {
                    box.set('total_count', data.total);
                    return data.total;
                  },
                  function () {
                    return $.get('/@api/df/stocks', _p.extend({ limit: 15 }, query_obj));
                  },
                  function (bp_stocks) {
                    window.history.replaceState(window.history.state, '', '/df/stock/list?offset=0');

                    box.set('bp_stocks', bp_stocks);
                    $.remove($.find1(e.delegateTarget, '.don_pgn_container'));
                    $.append(
                      $.find1(e.delegateTarget, '.stock_list_area'),
                      G.$stock_list_container(bp_stocks),
                    );

                    stock_pagenation(e.delegateTarget, _p.extend({ limit: 15 }, query_obj));
                  },
                )
                .else(_p.c('검색실패'), $.alert),
            );
          }),
          $.on('input', 'input[name="stock"]', function (e) {
            const stock_item = box.sel($.closest(e.currentTarget, '.stock_item'));
            if (!box.sel('selected_bp_stocks->(#' + stock_item.id + ')')) {
              box.push('selected_bp_stocks', stock_item);
            } else {
              box.remove2('selected_bp_stocks->(#' + stock_item.id + ')');
            }
          }),
          $.on('click', '.btn_new_order', function (e) {
            if (!box.sel('selected_bp_stocks').length) return $.alert('제품을 선택해 주세요.');

            $.frame.open(
              {
                frame_name: 'df.order_editor_frame',
                page_name: 'df.order_editor_page',
                closed: function (X, order) {
                  if (!order) return;
                  location.reload();
                },
              },
              {
                page_name: 'df.order_editor_page',
                tabs: [
                  {
                    tab_name: 'df.order_editor_tab',
                    data_func: function () {
                      return _map(box.sel('selected_bp_stocks'), function (sbs) {
                        return { id: sbs.id, quantity: 1 };
                      });
                    },
                  },
                ],
              },
            );
          }),
          $.on('click', '.btn_add_order', function (e) {
            if (!box.sel('selected_bp_stocks').length) return $.alert('제품을 선택해 주세요.');

            $.frame.open(
              {
                frame_name: 'df.ready_order_list_frame',
                page_name: 'df.ready_order_list_page',
                closed: function (X, order) {
                  if (!order) return;
                  location.reload();
                },
              },
              {
                page_name: 'df.ready_order_list_page',
                tabs: [
                  {
                    tab_name: 'df.ready_order_list_tab',
                    data_func: function () {
                      return _map(box.sel('selected_bp_stocks'), function (sbs) {
                        return { id: sbs.id, quantity: 1 };
                      });
                    },
                  },
                ],
              },
            );
          }),
          $.on2('click', '.btn_bp_stock_count', function (e) {
            const stock_item_el = $.closest(e.currentTarget, '.stock_item');
            const stock_item = box.sel(stock_item_el);
            return _p.go(
              $.put('/@api/df/bp_stock/stock_count', {
                bp_stock_id: stock_item.id,
                stock_count: $.val($.find1(stock_item_el, 'input[name="stock_count"]')),
              }),
              function (data) {
                if (!data.result) return $.alert('재고가 변경되지 않았습니다.');
                $.alert('변경되었습니다.');
                box.set('bp_stocks->(#' + data.bp_stock.id + ')->stock_count', data.bp_stock.stock_count);
              },
            );
          }),
          $.on2('awesomplete-selectcomplete', '#bp_list_input', function (e) {
            const val = $.val(e.currentTarget);
            const bp = _p.find(box.sel('bp_list'), function (bp) {
              return bp.name == val;
            });

            if (!bp) return;

            return _p.go($.get('/@api/df/bp/bp_colors', { bp_id: bp.id }), function (data) {
              if (!data.result) return $.alert('색상 가져오기 실패');

              const select_el = $.find1($.closest(e.currentTarget, '.options'), 'select[name="bpcs"]');
              data.bpcs.unshift({ id: null, name: '전체색상' });
              $.html(
                select_el,
                _sum(
                  data.bpcs,
                  _p.t(
                    'bpc',
                    '\
                option[value="{{bpc.id}}"] {{bpc.name}}\
              ',
                  ),
                ),
              );
            });
          }),
          _p.tap(function (tab) {
            new Awesomplete($.find1(tab, '#bp_list_input'), {
              list: '#bp_list',
              minChars: 1,
              maxItems: 500,
            });
          }),
          _p.tap(function (tab) {
            return stock_pagenation(tab, { limit: 15 });
          }),
        ),
      },
    ],
  });

  function stock_pagenation(tab, data) {
    return $.ui.pagination(
      $.find1(tab, '.don_pgn_container'),
      {
        url: '/@api/df/stocks',
        data: data,
        // is_history: true, /* 기본값 false */
        paging_animate: ___(
          function (don_pgn_wrap, new_items) {
            this.don_pgn_wrap = don_pgn_wrap;
            this.new_items = new_items;
            return anime({
              targets: don_pgn_wrap,
              opacity: 0,
              duration: 400,
              easing: 'linear',
            }).finished;
          },
          function () {
            $.html(this.don_pgn_wrap, this.new_items);

            return anime({
              targets: this.don_pgn_wrap,
              opacity: 1,
              duration: 400,
              easing: 'linear',
            }).finished;
          },
          function () {
            return _p.each(box.sel('selected_bp_stocks'), function (sbs) {
              _go(
                tab,
                $.find1('.stock_item[bp_stock_id="' + sbs.id + '"] input[name="stock"]'),
                _p.if2(_p.idtt)(function (input) {
                  input.checked = 'checked';
                }),
              );
            });
          },
        ),
        // target: '#scroll_test', /* 기본값 window 스크롤 이벤트 타겟 */
        total_count: function () {
          return box().total_count;
        },
      },
      _p.sum(G.$t_$stock_item),
    );
  }
})();
