import $dataStr from 'fxdom/es/dataStr.js';
import { html, strMap } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

/**
 * @param {SearchHistory} search_history
 */
export const searchHistoryTmpl = (search_history) => {
  return html`<div
    class="omp-search__search-history ${search_history?.queries?.length
      ? ''
      : 'omp-search__search-history--empty'}"
  >
    <div class="omp-search__search-history__header">
      <span class="omp-search__search-history__header-title">${TT('search::search_07')}</span>
      <button class="omp-search__search-history__remove-all-btn">
        <span>${TT('common::remove_all')}</span>
      </button>
    </div>
    ${searchHistoryBtnsTmpl(search_history)}
  </div>`;
};

/**
 * @param {SearchHistory} search_history
 */
export const searchHistoryBtnsTmpl = (search_history) => {
  return html`
    <div class="omp-search__search-history__history-btns" data-fx-json="${$dataStr(search_history)}">
      ${strMap((query) => {
        return html`
          <span class="omp-search__search-history__history-btn">
            ${OMPCoreAtomTmplS.removableButton({ text: UtilS.escape(query) })}
          </span>
        `;
      })(search_history.queries)}
    </div>
  `;
};
