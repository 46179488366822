import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from './module/OMPCoreAtomTmplS.js';

/**
 * @typedef {InputParam} FormParam
 * @property {string?} form_klass
 * @property {string?} form_id
 */

/**
 * Reset 아이콘이 있는 베이스 Input Form
 * 안드로이드의 경우 `form` 태그로 작성해야 키보드의 `제출` 이벤트가 적용되므로 `input`을 사용하지 말고 `inputForm`을 사용하도록 한다.
 * @param {FormParam} [param = {}]
 */
export const inputForm = ({ form_klass, form_id, ...input_param } = {}) => {
  return html`<form
    ${form_id ? html`id="${form_id}"` : ''}
    class="omp-atom__form omp-atom__form-input ${form_klass}"
  >
    <span class="omp-search-input-wrap"> ${OMPCoreAtomTmplS.input(input_param)} </span>
    <input
      class="omp-atom__input-reset ${input_param.value ? '' : 'omp-atom__input-reset--hidden'}"
      type="reset"
      value=""
    />
  </form>`;
};

/**
 * 검색 아이콘이 있는 Input Form
 * @param {FormParam} [param = {}]
 */
export const searchForm = ({ form_klass, form_id, ...input_param } = {}) => {
  return inputForm({
    ...input_param,
    form_klass: 'omp-atom__form-search' + ` ${form_klass || ''}`,
  });
};
