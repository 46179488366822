!(function () {
  G.$stock_list_container = _p.t(
    'bp_stocks',
    '\
    .stock_pgn.don_pgn_container[_sel="bp_stocks"]\
      table\
        thead\
          tr\
            th 상품명\
            th 색상\
            th 사이즈\
            th 현재 재고\
            th 발주된 수량\
            th 예상 재고\
            th 선택\
        tbody.don_pgn_wrap\
          {{_sum(bp_stocks, G.$t_$stock_item)}}\
      .don_pagination\
  ',
  );

  G.$t_$stock_item = _p.t(
    'bp_stock',
    '\
    tr.stock_item[bp_stock_id="{{bp_stock.id}}" _sel="./(#{{bp_stock.id}})"]\
      td.bp_name {{_.v(bp_stock, "_.base_product.name") || "이름없음"}}\
      td.bpc_name {{_.v(bp_stock, "_.base_product_color.name2") || _.v(bp_stock, "_.base_product_color.name") || "색상명 없음"}}\
      td.bps_name {{_.v(bp_stock, "_.base_product_size.name") || "사이즈명 없음"}}\
      td\
        input[type="number" name="stock_count" value="{{_.v(bp_stock, "stock_count") || 0}}"]\
        button[type="button"].btn_bp_stock_count 재고변경\
      td {{_.v(bp_stock, "ordering_count")}}\
      td {{_.v(bp_stock, "stock_count") + _.v(bp_stock, "ordering_count")}}\
      td\
        input[type="checkbox" name="stock" value="{{bp_stock.id}}"]\
  ',
  );
})();
