import { go, reduce, tap } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerGridCreatorPCMuiF } from '../Mui/module/VectorEditorStickerGridCreatorPCMuiF.js';

export const makeSticker = ({
  title,
  price,
  art_board_size: { width: art_board_width, height: art_board_height },
  background: { fill: background_fill = '#ffffff', opacity: background_opacity = 1 },
  grids,
  preProcess,
  postProcess,
}) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorStickerGridCreatorPCMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = (frame_el, val) =>
        go(
          frame_closed.call(frame, frame_el, val),
          tap(() => resolve(val)),
        );

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_title = title;
        tab_el.__mp_price = price;
        tab_el.__mp_art_board_size = { width: art_board_width, height: art_board_height };
        tab_el.__mp_background_color = background_fill;
        tab_el.__mp_background_opacity = background_opacity;
        tab_el.__mp_grids = reduce(
          (m, grid) => {
            m.set(grid.id, grid);
            return m;
          },
          new Map(),
          grids,
        );
        tab_el.__mp_preProcess = preProcess;
        tab_el.__mp_postProcess = postProcess;
        return tab_appending.call(tab, tab_el);
      };
    }).catch(reject),
  );
