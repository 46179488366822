import { go, groupBy, mapObject, pipe, sortBy, values } from 'fxjs/es';

export const filterParallelCoords = ({ xys, is_horizontal }) => {
  return go(
    xys,
    groupBy(({ x, y }) => (is_horizontal ? y : x)),
    mapObject(
      pipe(
        sortBy(({ x, y }) => (is_horizontal ? x : y)),
        (arr) => [arr[0], arr[arr.length - 1]],
      ),
    ),
    values,
  );
};
