import { makeCvPatternMobileHtml } from './tmpl.js';
import { DEFAULT_PATTERN, makePatternEvent } from './fs.js';
import { NewMakerRangeSliderF } from '../../../NewMaker/RangeSlider/F/Function/module/NewMakerRangeSliderF.js';
import { SVGEditorUtilF } from '../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { OMPDosuF } from '../../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { go, html } from 'fxjs/es';
import { $delegate } from 'fxdom/es';

$.frame.defn_frame({
  frame_name: 'maker.m_image_editor',
  el_class: 'cv_image_editor editor_menu no_header',
  animation: true,
  height: 280 + G.mp.frame.v_height,
  header_height: G.mp.frame.v_height,
  always_remove: true,
  hide_frame_button_type: 'V',
  prev_frame_show: true,
  showing: function () {
    G.mp.maker.ban_frame_show();
    G.mp.maker.zoom.by_active1(G.mp.maker.active(), 272 + G.mp.frame.v_height);
  },
  closing: function () {
    G.mp.maker.ban_frame_hide();
    G.mp.maker.zoom.recover();
  },
  appended: (frame_el) => {
    SVGEditorUtilF.halfFrameClose(frame_el);
  },
  showed: $.on_prevent_default,
  hiding: $.off_prevent_default,
});

export const m_image_pattern_tab = {
  tab_name: 'm_image_pattern',
  title: T('Pattern'),
  el_class: 'mp-maker-padding-tab-1',
  template: function () {
    const pattern_data = G.mp.maker.active()._data.pattern_data || DEFAULT_PATTERN;
    return makeCvPatternMobileHtml(pattern_data);
  },
  showed: (tab_el) => NewMakerRangeSliderF.start(tab_el),
  appended: (tab_el) => makePatternEvent(tab_el),
};

const m_image_dosu_color_picker_tab = {
  tab_name: 'm_image_dosu_color_picker',
  el_class: 'm-image-dosu-color-picker mp-maker-padding-tab-1',
  title: T('Color'),

  template: function () {
    const cv_object = G.mp.maker.active();
    return html`<div class="don-tab-body-i-scroll__wrapper">
      ${OMPDosuF.dosuImageColorPickerHtml(cv_object)}${OMPDosuF.dosuColorPickerHtml(cv_object)}
    </div>`;
  },
  appended: function (tab_el) {
    go(tab_el, $delegate('click', '.dosu-colors .color-picker', OMPDosuF.handleDosuColorPicker.image));
  },
  hided: (tab_el) => {
    tab_el.__iscroll.destroy();
  },
  showed: (tab_el) => {
    tab_el.__iscroll = new IScroll(tab_el, {
      mouseWheel: true,
      scrollbars: true,
      fadeScrollbars: true,
    });
  },
};

$.frame.defn_page({
  page_name: 'm_image_pattern_page',
  el_class: 'one_tab_button m_image_pattern_page after_bar m_image_pattern_tab page_in_mp_maker',
  tabs: [m_image_pattern_tab],
});

$.frame.defn_page({
  page_name: 'm_image_dosu_color_picker_and_pattern_page',
  el_class: 'after_bar page_in_mp_maker m_image_pattern_tab page_in_mp_maker don-tab-body-i-scroll',
  custom_header: G.mp.frame.t_blue_tab_buttons,
  tabs: [m_image_dosu_color_picker_tab, m_image_pattern_tab],
});

export function openCvPatternFrame({ is_with_image_color_picker, open_tab_name }) {
  // 열리는 탭 선택 지정
  if (open_tab_name) {
    [m_image_pattern_tab, m_image_dosu_color_picker_tab].forEach((tab) => {
      tab.selected = tab.tab_name === open_tab_name;
    });
  }
  return $.frame.open({
    frame_name: 'maker.m_image_editor',
    page_name: is_with_image_color_picker
      ? 'm_image_dosu_color_picker_and_pattern_page'
      : 'm_image_pattern_page',
  });
}
