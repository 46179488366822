import { chunk, go, map } from 'fxjs/es';
import { NewMakerCvObjectCvBackgroundConstantS } from '../../S/Constant/module/NewMakerCvObjectCvBackgroundConstantS.js';

export const makeTwoDepthColors = (one_line_color_length) =>
  chunk(
    one_line_color_length,
    go(NewMakerCvObjectCvBackgroundConstantS.COLORS32, (arr) => {
      const _length = one_line_color_length - (arr.length % one_line_color_length);
      const plus_arr = go(
        Array(_length),
        map(() => {
          return {};
        }),
      );
      return arr.concat(plus_arr);
    }),
  );
