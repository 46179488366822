import { each, extend, go, groupBy, isNil, map, omit, sel } from 'fxjs/es';
import {
  $addClass,
  $attr,
  $closest,
  $el,
  $find,
  $findAll,
  $hasClass,
  $qs,
  $qsa,
  $removeClass,
  $replaceWith,
  $toggleClass,
} from 'fxdom/es';
import axios from 'axios';
import Swal from 'sweetalert2';

import { makeCheckPointDashBoardAndTableHtml } from '../S/tmpl.js';

const rotateBtn = (btn_svg_el, timeout) => {
  const is_rotating = $hasClass('rotate', btn_svg_el);
  if (!is_rotating) {
    $addClass('rotate', btn_svg_el);
    return window.setTimeout(() => $removeClass('rotate', btn_svg_el), timeout);
  }
};

export const postTaskStatus = async ({ task_id, status_tobe, projection_id }) => {
  const task_data_tobe_update = { id: task_id, status: status_tobe, projection_id };
  status_tobe === 'completed' && extend(task_data_tobe_update, { completed_at: new Date() });
  return go(await axios.post('/@api/checkpoint/task_status', task_data_tobe_update), sel('data'));
};

export const refreshCheckPointTableOfSelectedTitle = async (refresh_btn_el, show_alert = true) => {
  rotateBtn($find('svg', refresh_btn_el), 700);
  const task_titles_el = $closest('.task_titles', refresh_btn_el);
  const task_title_el = $closest('.task_title', refresh_btn_el);

  $addClass('tobe_updated', task_title_el);
  const task_title = go(task_title_el, $find('.task_title_name')).textContent;
  const query = extend(
    { title: task_title },
    go(box.sel('states'), omit(['date_events', 'titles', 'title'])),
  );
  const is_popup = $qs('.status_popup');
  if (isNil(is_popup)) {
    $.don_loader_start();
  }

  try {
    const rows_data = go(
      await axios.get('/@api/checkpoint', {
        params: query,
      }),
      sel('data'),
      groupBy(sel('name')),
    );

    if (!Object.keys(rows_data).length) {
      $.alert('가져온 데이터가 하나도 없습니다.<br>페이지를 새로고침 후 다시 시도해 주세요.');
      $.don_loader_end();
      return;
    }

    const rows_data_arr = go(
      task_title_el,
      $findAll('.task_worker_name'),
      map((el) => {
        const worker_name = el.textContent;
        return [worker_name, rows_data[worker_name]];
      }),
    );

    // 태스크 대시보드 및 테이블 목록 교체 (각 사용자 별로)
    $replaceWith(
      $el(
        makeCheckPointDashBoardAndTableHtml({
          rows_data_arr,
          task_title: query.title,
          important: $attr('important', task_titles_el),
          internal_important: $attr('internal_important', task_titles_el),
          url: $attr('url', task_titles_el),
          query_str: $attr('query_str', task_titles_el),
          is_ing: $attr('is_ing', task_titles_el) === 'true',
          is_print_mode: !$hasClass('selected', task_title_el),
          is_labeling: query.is_labeling == 'true',
        }),
      ),
      go(refresh_btn_el, $closest('.task_title'), $find('.task_workers')),
    );
    show_alert &&
      Swal.fire({
        position: 'center',
        target: '#body',
        icon: 'success',
        backdrop: false,
        title: `<span style="font-size:18px;">${query.title}<br>태스크가 최신 상태로 갱신되었습니다!</span>`,
        showConfirmButton: false,
        timer: 1800,
      });
  } catch (e) {
    console.error(e);
    await $.alert(
      `에러가 났어요.ᕙ(⇀‸↼‶)ᕗ<br>태스크 목록을 새로고침 하지 못하였어요.<br>개발자에게 문의주세요.`,
    );
  }
  $removeClass('tobe_updated', task_title_el);
  $.don_loader_end();
};

export const searchTasksLink = (e) => {
  e.preventDefault();
  const ct = e.currentTarget;

  const len = Number(ct.textContent);
  if (len) {
    window.open(ct.href, '_blank');
  } else {
    Swal.fire({
      position: 'center',
      target: '#body',
      icon: 'warning',
      backdrop: false,
      title: `<span style="font-size:18px;">조회 가능한 태스크가 없습니다. @( * O * )@</span>`,
      showConfirmButton: false,
      timer: 1500,
    });
  }
};

const toggleHiddenElements = (els) =>
  go(
    els,
    each((el) => $toggleClass('hidden', el)),
  );

export const activateTaskChangeMode = ({ currentTarget: ct }) => {
  const checkbox_el = $find('input[type="checkbox"]', ct);
  const task_title_el = $closest('.task_title', ct);

  toggleHiddenElements($findAll('.label_print_title', task_title_el));
  toggleHiddenElements($findAll('.task_status_change_title', task_title_el));
  toggleHiddenElements($findAll('.label_print_btn', task_title_el));
  toggleHiddenElements($findAll('.status_switch', task_title_el));

  const check_state = (checkbox_el.checked = !checkbox_el.checked);
  const task_title_els = $qsa('.task_title');
  const selected_task_title = $closest('.task_title', ct);
  $toggleClass('selected', selected_task_title);
  if (check_state) {
    task_title_els.forEach((el) => {
      el.style.opacity = 0.2;
      el.style.pointerEvents = 'none';
    });
    selected_task_title.style.opacity = 1;
    selected_task_title.style.pointerEvents = 'auto';
  } else {
    task_title_els.forEach((el) => {
      el.style.opacity = 1;
      el.style.pointerEvents = 'all';
    });
  }
};
