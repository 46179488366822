import { isObject } from 'fxjs/es';

export class MyPageReviewWriteError extends Error {
  constructor(message, omp_data = {}, status = 400, handle = true) {
    if (isObject(message)) {
      super(JSON.stringify(message));
      this.json = message;
    } else {
      super(message);
      this.message = message;
    }

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MyPageReviewWriteError);
    }

    this.name = 'OMPReviewWriteConstantS.MyPageReviewWriteError';
    this.omp_data = omp_data;
    this.handle = handle;
    this.status = status;
  }
}
