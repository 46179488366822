import { each, pipe, go } from 'fxjs/es';
import { makeReviewListInfi } from '../../../Review/List/F/fs.js';
import { defnReviewListEvent } from '../../../Review/List/F/event.js';
import { makeReviewListMainHtml } from '../../../Review/List/S/tmpl.js';
import { defnReviewListAdminEvent } from './event_admin.js';
import {
  makeDfReviewListAdminReplyHtml,
  makeDfReviewListAdminCommnet,
  makeDfReviewListAdminNoTranslateHtml,
  makeDfReviewListAdminButtonsHtml,
} from './tmpl.js';

const makeDfReviewListInfiAppended = (review_lists) => {
  return go(
    review_lists,
    each(
      $.attr($1('.review_list.infi_container'), 'photo_review')
        ? ($review_item) =>
            $.after(
              $.find1($review_item, '.photo_info'),
              makeDfReviewListAdminButtonsHtml(window.box.sel($review_item)),
            )
        : function ($review_item) {
            const review = window.box.sel($review_item);
            $.before($.find1($review_item, '.info'), makeDfReviewListAdminReplyHtml(review));
            if (box.sel('review_list->query->collabo_type')) return;
            const $comment_en = $.el(makeDfReviewListAdminCommnet(review));
            $.after($.find1($review_item, '.review_comment'), $comment_en);
            $.append(
              $.find1($comment_en, '.translate_comment_en div'),
              makeDfReviewListAdminNoTranslateHtml(review.comment_en),
            );
            $.append(
              $.find1($comment_en, '.translate_comment_jp div'),
              makeDfReviewListAdminNoTranslateHtml(review.comment_jp),
            );
          },
    ),
  );
};

export const defnDfReviewListTab = () => ({
  tab_name: 'df.review.list',
  template: makeReviewListMainHtml,
  appended: pipe(defnReviewListEvent, defnReviewListAdminEvent),
  infinite: makeReviewListInfi(makeDfReviewListInfiAppended),
});
