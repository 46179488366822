import axios from 'axios';
import { $closest, $delegate, $find } from 'fxdom/es';
import { extend, filter, flat, flatMap, go, map, pipe, sel, take, uniq } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import {
  changeCurrentTab,
  compactObject,
  getCheckValue,
  getSelectValue,
  goToTheSiteEvent,
  renderMapTestPage,
  testPageLoader,
} from './fs.js';

function getCompositeFaceBpf(selected_assoc_composite_template) {
  return go(
    selected_assoc_composite_template._.composite_masks,
    flatMap(
      pipe(
        sel('_.composite_faces'),
        map((cf) => cf.base_product_face_id),
      ),
    ),
    uniq,
  );
}
export const map_test_tab = {
  tab_name: 'map_test_tab',
  title: '매핑 테스트',
  template: () => legacyHtml`
    <div class="option">
      <button class="more">...</button>
    </div>
    <div class="list_wrapper"></div>
  `,
  showed: function (don_tab) {
    changeCurrentTab(don_tab);
  },
  appended: async function (don_tab) {
    const list_wrapper_el = $find('.list_wrapper', don_tab);
    const assoc_composite_templates = go(
      don_tab,
      $closest('.don_page'),
      (don_page) => don_page.page_opt.assoc_composite_templates,
    );
    let offset = 0;
    const step = 1;
    async function _render() {
      const cond = compactObject({
        base_product_id: box.sel('bp->id'),
        base_product_color_id: getSelectValue(don_tab, 'base_product_color_id'),
      });
      const composite_template_ids = getCheckValue(don_tab, 'composite_template_id');
      if (composite_template_ids.length === 0) return;
      const selected_assoc_composite_templates = go(
        assoc_composite_templates,
        filter((ct) => composite_template_ids.includes(ct.id)),
      );
      const { data: product_colors } = await axios.post(
        '/@api/composite/test_page/get_pcs',
        extend(
          {
            cond,
            offset,
            limit: step,
          },
          compactObject({
            bpf_ids: go(selected_assoc_composite_templates, map(getCompositeFaceBpf), flat, uniq),
          }),
        ),
        take(1),
      );
      offset += step;
      await renderMapTestPage(list_wrapper_el, product_colors, selected_assoc_composite_templates);
    }
    don_tab.tab_opt.reset_render = async function () {
      list_wrapper_el.innerHTML = '';
      offset = 0;
      await _render();
    };
    go(don_tab, $delegate('click', '.more', testPageLoader(_render)), goToTheSiteEvent);
  },
};
