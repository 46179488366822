import { CateListConstantS } from '../Constant/module/CateListConstantS.js';

export const isPhoneCaseByCateItemId = (cate_item_id) => {
  return CateListConstantS.PHONE_CASE_ITEM_IDS.includes(parseInt(cate_item_id));
};
export const isSticker = (cate_item_id) =>
  [CateListConstantS.SPECIFIC_STICKER_ITEM_ID, CateListConstantS.FREE_STICKER_ITEM_ID].includes(
    parseInt(cate_item_id),
  );
export const isHandySticker = (cate_item_id) =>
  [CateListConstantS.HANDY_STICKER_ITEM_ID].includes(cate_item_id);
export const isFrame = (cate_item_id) =>
  [CateListConstantS.HD_METAL_FRAME_ITEM_ID].includes(parseInt(cate_item_id));
export const isTaToo = (cate_item_id) => [CateListConstantS.TATTOO_ITEM_ID].includes(parseInt(cate_item_id));
