import { $qs, $setAttr, $setCss } from 'fxdom/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { OMPCoreUtilF } from '../../../Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreChannelTalkMuiF } from '../Mui/module/OMPCoreChannelTalkMuiF.js';

function getChannelLocale(lang) {
  if (lang == 'kr') return 'ko';
  if (lang == 'en') return 'en';
  if (lang == 'jp') return 'ja';
}

export const loadChannelIO = () => {
  const w = window;
  if (w.ChannelIO) {
    w.console.error('ChannelIO script included twice.');
  }
  const ch = function () {
    ch.c(arguments);
  };
  ch.q = [];
  ch.c = function (args) {
    ch.q.push(args);
  };
  w.ChannelIO = ch;
  function l() {
    if (w.ChannelIOInitialized) {
      return;
    }
    w.ChannelIOInitialized = true;
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
    const x = document.getElementsByTagName('script')[0];
    if (x.parentNode) {
      x.parentNode.insertBefore(s, x);
    }
  }
  if (document.readyState === 'complete') {
    l();
  } else {
    w.addEventListener('DOMContentLoaded', l);
    w.addEventListener('load', l);
  }
  w.ChannelIO(
    'boot',
    {
      pluginKey: '76f20dbe-67a5-413b-910e-0146f0acdf13', // fill your plugin key
      language: getChannelLocale(T.lang),
      customLauncherSelector: '.live_chat',
      ...(box.sel('is_user')
        ? {
            id: box.sel('is_user -> id'),
            userId: box.sel('is_user -> id'),
            profile: {
              // fill user's profile
              name: box.sel('is_user -> name'),
              email: box.sel('is_user -> email'),
            },
          }
        : {}),
    },
    () => {
      w.ChannelIO('updateUser', {
        language: getChannelLocale(T.lang), // sync user language
      });
    },
  );

  if (OMPCoreUtilF.isMobile()) {
    w.ChannelIO('onShowMessenger', function () {
      $setAttr({ style: 'z-index:10000000 !important; display: block !important;' }, $qs('#ch-plugin'));
      return MuiF.openFrame(OMPCoreChannelTalkMuiF.frame, (f) => {
        f.closing = function (_, pass) {
          pass || w.ChannelIO('hideMessenger');
        };
      });
    });
    w.ChannelIO('onHideMessenger', function () {
      $setCss({ display: '' }, $qs('#ch-plugin'));
      if ($qs('.don_frame.fake-channel-talk-frame')) {
        return MuiF.closeFrame();
      }
    });
  }
};
