import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';

export const handleHeaderCancelClick = () => () => MuiF.popPage();

export const handleHeaderDoneClick =
  ({ tab_el }) =>
  async () => {
    const cutting_line_editor = tab_el.__mp_cutting_line_editor;
    if (!cutting_line_editor) {
      /** @todo alert message */
      await MuiF.popPage().catch(console.error);
      return;
    }

    const target_image_el = tab_el.__mp_target_image_el;
    if (!target_image_el) {
      /** @todo alert message */
      await MuiF.popPage().catch(console.error);
      return;
    }

    const path_data = cutting_line_editor.getCuttingLinePathData();

    const result = await tab_el.__mp_postProcess({ target_image_el, path_data });
    MuiF.closeFrame(result);
  };
