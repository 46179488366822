// todo @ddevkim 에러 클래스에 따라 에러 코드 정합성 맞추기

import { PDF_ERR_CODE_REASON } from '../Constant/error.js';

export class PdfApiError extends Error {
  /**
   * @param {string} message
   * @param {string} code - PDF_ERR_CODE
   * */
  constructor(message, code) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PdfApiError);
    }

    this.status_code = 200; // 항상 정상 응답 보냄
    this.code = code;
  }
}

export class PdfBadRequestError extends PdfApiError {
  /**
   * @param {string} code - PDF_ERR_CODE
   * */
  constructor(code) {
    super(PDF_ERR_CODE_REASON[code] ?? 'Bad Request Error', code);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PdfBadRequestError);
    }
  }
}

export class PdfNotFoundError extends PdfApiError {
  /**
   * @param {string} code - PDF_ERR_CODE
   * */
  constructor(code) {
    super(PDF_ERR_CODE_REASON[code] ?? 'Not Found Error', code);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PdfNotFoundError);
    }
  }
}

export class PdfInternalServerError extends PdfApiError {
  /**
   * @param {string} code - PDF_ERR_CODE
   * */
  constructor(code) {
    super(PDF_ERR_CODE_REASON[code] ?? 'Internal Server Error', code);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PdfInternalServerError);
    }
  }
}
