import Pickr from '@simonwep/pickr/dist/pickr.es5.min.js';
import { $findAll, $on } from 'fxdom/es';
import { go, each } from 'fxjs/es';

export const addColorSelectorNotEtcClickEventHandler = ({ el, f }) => {
  go(
    el,
    $findAll(`.color_selector .color[data-is_etc="false"]`),
    each(
      $on('click', ({ currentTarget: color_el }) => {
        const color_code = color_el?.dataset?.color_code;
        if (!color_code) {
          return;
        }

        f({ el, color_code });
      }),
    ),
  );
};

export const addColorSelectorEtcClickEventHandler = ({ el, f1, f2 }) => {
  go(
    el,
    $findAll(`.color_selector .color[data-is_etc="true"]`),
    each(
      $on('click', async ({ currentTarget: color_el }) => {
        const color_code = await f1({ el });

        const picker = Pickr.create({
          el: color_el,
          container: 'body',
          theme: 'monolith',
          useAsButton: true,
          default: color_code,
          components: {
            preview: true,
            hue: true,
            interaction: {
              hex: true,
              input: true,
            },
          },
          i18n: { 'btn:clear': '초기화' },
        })
          .on('hide', () => {
            picker.destroyAndRemove();
          })
          .on('change', (color) => {
            const color_code = color.toHEXA().toString().toLowerCase();
            f2({ el, color_code });
          });
        picker.show();
      }),
    ),
  );
};
