import { deepFlat, flatMap, go, head, map, partition, filter, pipe, pluck, reduce, sel, sum } from 'fxjs/es';
import { GoodsTypeS } from '../../GoodsType/S/Function/module/GoodsTypeS.js';
import { PriceS } from '../../Price/S/Function/module/PriceS.js';

const calc_projection_price_info = function (user_products, _en) {
  const ups = head(
    partition(
      (user_product) =>
        GoodsTypeS.isPod(user_product.goods_type_id) || GoodsTypeS.isTpl(user_product.goods_type_id),
    )(user_products),
  );
  const up_cs_count = go(
    ups,
    flatMap((up) =>
      go(
        up._.up_cs,
        filter((up_c) => up_c.quantity > 0),
      ),
    ),
    sel('length'),
  );

  const only_pod_ups = head(
    partition((user_product) => GoodsTypeS.isPod(user_product.goods_type_id))(user_products),
  );
  _en = _en || '';

  function add(a, b) {
    return a + b;
  }

  const flat_up_c_ss = pipe(
    map(function (up) {
      return map(function (up_c) {
        return up_c._.up_c_ss;
      }, up._.up_cs);
    }),
    deepFlat,
  );

  const quantity =
    go(
      ups,
      flat_up_c_ss,
      map((u) => u.quantity),
      reduce(add),
    ) || 0;

  const product_original_price =
    go(
      ups,
      flat_up_c_ss,
      map(function (up_c_s) {
        return PriceS.mult(up_c_s.quantity, up_c_s['price' + _en]);
      }),
      PriceS.add,
    ) || 0;

  const product_price =
    go(
      ups,
      flat_up_c_ss,
      map(function (up_c_s) {
        return PriceS.mult(up_c_s.quantity, up_c_s['discounted_price' + _en]);
      }),
      PriceS.add,
    ) || 0;

  const pod_product_price =
    go(
      only_pod_ups,
      flat_up_c_ss,
      map(function (up_c_s) {
        return PriceS.mult(up_c_s.quantity, up_c_s['discounted_price' + _en]);
      }),
      PriceS.add,
    ) || 0;

  const discount_price = PriceS.sub(product_original_price, product_price);
  const discount_percent = PriceS.mult(PriceS.div(discount_price, product_original_price), 100) || 0;
  const shipping_price = _en ? 0 : product_price < 100000 && product_price > 0 ? 3000 : 0;

  return {
    quantity,
    product_original_price,
    pod_product_price,
    product_price,
    discount_price,
    shipping_price,
    discount_percent: parseFloat(discount_percent.toFixed(1)),
    up_cs_count,
  };
};

const calc_up_c_ss_price_info = function (up_c_ss, _en = '') {
  const discounted_price =
    reduce(
      function (sum, up) {
        return PriceS.add(sum, PriceS.mult(up.quantity, up['discounted_price' + _en]));
      },
      0,
      up_c_ss,
    ) || 0;

  const total_price =
    reduce(
      function (sum, up) {
        return PriceS.add(sum, PriceS.mult(up.quantity, up['price' + _en]));
      },
      0,
      up_c_ss,
    ) || 0;

  const discount_percent = ((total_price - discounted_price) / total_price) * 100;

  return {
    quantity: sum(pluck('quantity', up_c_ss)),
    discounted_price,
    total_price,
    discount_percent,
  };
};

const Ups = {
  calc_projection_price_info,
  calc_up_c_ss_price_info,
};

export default Ups;
