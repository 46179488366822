import { OMPDosuConstantS } from '../Constant/module/OMPDosuConstantS.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { compact, entries, filter, flatMap, go, groupBy, map, sortByDesc } from 'fxjs/es';
import { BpOptionS } from '../../../../BpOption/S/Function/module/BpOptionS.js';
import { OMPDosuS } from './module/OMPDosuS.js';

export const cvDesign = {
  get: {
    // 지정 도수 색상이 없으면 도수 색상으로 허용된 컬러로 제작된 원본이라는 의미이므로 원본 색상을 도수 색상으로 사용한 것으로 처리해야함.
    dosuColorIfNotOriginalColors: (design) => {
      return (
        design._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR] ??
        design._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_ORIGINAL_COLORS] ??
        []
      );
    },
    usedDosuColorFromPf: (pf) => {
      if (UtilArrayS.isArrayOk(pf?.designs)) {
        return go(
          pf.designs,
          filter((design) => design.visible),
          BpOptionS.makerHelper.get.unGroupDesigns,
          flatMap((d) => OMPDosuS.cvDesign.get.dosuColorIfNotOriginalColors(d)),
          compact,
          map((hex_code) => hex_code.toUpperCase()),
          groupBy((hex_code) => hex_code),
          entries,
          sortByDesc(([_, hex_codes]) => hex_codes?.length),
          map(([hex_code]) => hex_code),
        );
      } else {
        return [];
      }
    },
  },
};
