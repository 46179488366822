import { entries, filter, go, html, map, object, strMap } from 'fxjs/es';
import { $appendTo, $closest, $delegate, $el, $find, $findAll, $remove, $setVal } from 'fxdom/es';

export const editorInit = async ({
  base_product_face_id,
  base_product_id,
  cv_obj_actions_editor_wrapper_el,
}) => {
  if ($find('.cv_obj_actions_editor')(cv_obj_actions_editor_wrapper_el))
    $remove($find('.cv_obj_actions_editor')(cv_obj_actions_editor_wrapper_el));
  const cv_obj_actions = await $.get('/@api/cv_obj_actions/all', { base_product_face_id });
  await go(
    html`
      <div class="cv_obj_actions_editor">
        <div class="option">
          <button type="button" class="cv_obj_action_add">옵션-이미지 추가</button>
        </div>
        <div class="select_bp_options_wrapper"></div>
        <div class="cv_obj_actions">
          ${strMap(
            (cv_obj_action) => html`
              <label for="" style="background: #00bea2;">${cv_obj_action.bp_option_name}</label>
              <form class="cv_obj_action">
                <input type="number" name="id" value="${cv_obj_action.id}" hidden />
                <div class="row_wrapper">
                  <label for="">순서</label>
                  <input type="number" name="no" value="${cv_obj_action.no}" />
                </div>
                <div class="row_wrapper">
                  <label for="">cid</label>
                  <input type="text" name="target_cid" value="${cv_obj_action.target_cid || ''}" />
                </div>
                <div class="row_wrapper">
                  <label for="">cv_type</label>
                  <input type="text" name="target_cv_type" value="${cv_obj_action.target_cv_type || ''}" />
                </div>
                <div class="row_wrapper">
                  <label for="">action</label>
                  <input type="text" name="action" value="${cv_obj_action.action || ''}" />
                </div>
                <textarea name="cv_attr" id="" cols="30" rows="1">
${JSON.stringify(cv_obj_action.cv_attr || {})}</textarea
                >
                <button type="button" class="apply">적용</button>
                <button type="button" class="save">저장</button>
              </form>
            `,
          )(cv_obj_actions)}
        </div>
      </div>
    `,
    $el,
    $appendTo(cv_obj_actions_editor_wrapper_el),
    $delegate('click', '.apply', (e) => {
      go(
        e.currentTarget,
        $closest('form'),
        $.find1('[name="cv_attr"]'),
        $setVal(JSON.stringify(G.mp.maker.onescreen(G.mp.maker.active().toJSON()))),
      );
    }),
    $delegate('click', '.save', async (e) => {
      $.don_loader_start();
      const form_el = $closest('form')(e.currentTarget);
      const form = new FormData(form_el);
      await go(
        form.entries(),
        object,
        entries,
        filter(([k, v]) => v),
        map(([k, v]) => {
          if (k === 'id') return [k, parseInt(v)];
          if (k === 'no') return [k, parseInt(v)];
          return [k, v];
        }),
        object,
        (obj) => $.post('/@api/cv_obj_actions/update', obj),
      );
      $.don_loader_end();
    }),
    $delegate('click', '.cv_obj_action_add', async (e) => {
      if ($find('.select_bp_option')(cv_obj_actions_editor_wrapper_el))
        $remove($find('.select_bp_option')(cv_obj_actions_editor_wrapper_el));
      const bp_option_ids = await go(
        $.get('/@api/cv_obj_actions/get_bp_options', { base_product_id }),
        (bp_options) => html`
          <form class="select_bp_option">
            ${strMap(
              (bp_option) => html` <div class="row_wrapper">
                <label for="">${bp_option.name || ''}</label>
                <input type="checkbox" name="${bp_option.id}" />
              </div>`,
            )(bp_options)}
            <button type="button" class="done">완료</button>
          </form>
        `,
        $el,
        $appendTo($find('.select_bp_options_wrapper')(cv_obj_actions_editor_wrapper_el)),
        (el) =>
          new Promise((resolve, reject) => {
            $delegate('click', '.done', (e) => {
              try {
                go(
                  e.delegateTarget,
                  $findAll('input'),
                  filter((el) => el.checked),
                  map((el) => parseInt(el.name)),
                  resolve,
                );
                $remove(e.delegateTarget);
              } catch (e) {
                reject(e);
              }
            })(el);
          }),
      );
      await $.post('/@api/cv_obj_actions/add', {
        cv_obj_actions: go(
          bp_option_ids,
          map((bp_option_id) => ({ bp_option_id, base_product_face_id })),
        ),
      });
      await editorInit({ base_product_face_id, base_product_id, cv_obj_actions_editor_wrapper_el });
    }),
  );
};
