$.frame.defn_frame({
  frame_name: 'mp.old_message',
  page_name: 'mp.old_message',
  appending: function () {
    $.don_loader_start();
  },
  showed: function () {
    return _p.go(void 0, _p.wait(100), function () {
      $.don_loader_end();
      const old_messages = $1('#old_messages');
      return $.scroll_top2(
        window,
        $.offset(old_messages).top + $.innerHeight(old_messages) - $.innerHeight(window),
        500,
      );
    });
  },
});
