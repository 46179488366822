import axios from 'axios';

/**
 * @param {number} bp_id
 * @param {'kr' | 'en' | 'jp'} lang
 * @return {Promise<PrintingTemplate | null>}
 */
export async function getPrintTemplate({ bp_id, lang }) {
  return (await axios.get(`/@api/print_template`, { params: { bp_id, lang } })).data;
}
