import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { UtilF } from '../../../../../../Util/F/Function/module/UtilF.js';
import { html } from 'fxjs/es';
import { OMPCoreFramesStaticStyleMuiF } from '../Mui/module/OMPCoreFramesStaticStyleMuiF.js';
export const openStaticInfo = ({ img }) => {
  return MuiF.openFrame(OMPCoreFramesStaticStyleMuiF.frame, (frame, page, [tab]) => {
    frame.hide_frame_button_type = 'X';
    const is_mobile = UtilF.isLegacyMobile();
    frame.prev_frame_show = false;
    frame.is_modal = !is_mobile;
    frame.always_remove = true;
    tab.makeData = () => img;

    tab.template = (img) => {
      return html`<div>
        <img src="${img}" alt="" />
      </div>`;
    };
  });
};
