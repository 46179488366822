import { find, html } from 'fxjs/es';
import { OMPCoreCellTmplS } from '../../../Cell/S/Tmpl/module/OMPCoreCellTmplS.js';

export const bodySubMenu = (sub_menus) => {
  const active_sub_menu = find((menu) => {
    return menu.active;
  }, sub_menus);
  return html`
    <div class="omp-layout-body__menus" data-active_id="${active_sub_menu?.id}">
      ${OMPCoreCellTmplS.headerMenus({
        buttons: sub_menus,
      })}
    </div>
  `;
};
