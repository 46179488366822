import { go, map, pluck, reduce, sortBy, takeUntil } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

// first fit decreasing (https://www.youtube.com/watch?v=GbPmmZQHQo8)
export const ffd = (ns, size, get) =>
  go(
    ns,
    sortBy((n1, n2) => get(n2) - get(n1)),
    (sorted) => firstFit(sorted, size, get),
  );

export const firstFit = (ns, size, get) =>
  go(
    reduce(
      (bins, cur) => {
        const cur_size = get(cur);
        const idx = bins.findIndex((bin) => bin.rest >= cur_size);
        if (~idx) {
          bins[idx].push(cur);
          bins[idx].rest -= cur_size;
        } else {
          const bin = [cur];
          bin.rest = size - cur_size;
          bins.push(bin);
        }
        return bins;
      },
      [],
      ns,
    ),
    map((bin) => bin.slice()),
  );

export const forceFitSize = (ns, size, get, split, { bin_count = Infinity } = {}) =>
  go(
    { rest: ns, idx: 0 },
    UtilS.recursiveL((recur) => {
      if (recur.idx == bin_count) return { out: recur.rest };

      const { out, rest } = reduce(
        ({ out_size, out, rest }, cur) => {
          const cur_size = get(cur);

          if (out_size == size) {
            rest.push(cur);
            return { out, rest, out_size };
          }

          const overflow = cur_size + out_size - size;
          if (overflow > 0) {
            const [front, back] = split(cur, cur_size - overflow);
            out.push(front);
            rest.push(back);
            return { out, rest, out_size: size };
          }

          out.push(cur);
          return { out, rest, out_size: out_size + cur_size };
        },
        { out: [], rest: [], out_size: 0 },
        recur.rest,
      );
      return { out, rest: rest.length && rest, idx: recur.idx + 1 };
    }),
    takeUntil((recur) => !recur.rest),
    pluck('out'),
  );

export const splitObject = (obj, size, get, split) =>
  go(
    { rest: obj },
    UtilS.recursiveL((recur) => {
      if (get(recur.rest) <= size) return { out: recur.rest };
      const [out, rest] = split(recur.rest, size);
      return { out, rest };
    }),
    takeUntil((recur) => !recur.rest),
    pluck('out'),
  );
