import { errorHandlingEventWrapper } from '../../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { createCvImageP, getCvImage } from '../CvImage/fs.js';
import { each, extend, go, pick, range, sel } from 'fxjs/es';
import { createCvTextImageA, createCvTextImageSrc } from '../text.js';
import { getFheight, getFwidth, insertCvObject } from '../util.js';
import { setIScroll } from '../Marpplizer/fs.js';
import { $closest } from 'fxdom/es';
import { getCvTextImage } from '../CvTextImage/fs.js';
import { getShadeFcanvas, renderMakerPageShade } from '../CvPreview/Shade/render.js';
import { NewMakerRangeSliderF } from '../../../NewMaker/RangeSlider/F/Function/module/NewMakerRangeSliderF.js';
import { NewMakerImageF } from '../../../NewMaker/Image/F/Function/module/NewMakerImageF.js';
import { NewMakerFitPrintWidthF } from '../../../NewMaker/FitPrintWidth/F/Function/module/NewMakerFitPrintWidthF.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { OMPDosuConstantS } from '../../../OMP/Dosu/S/Constant/module/OMPDosuConstantS.js';
import { OMPDosuF } from '../../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { NewMakerPropertyBpfF } from '../../../NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';

export const DEFAULT_PATTERN = {
  x: 1,
  y: 1,
  size: 100,
  translateX: 0,
  style: 'repeat',
};

function getWidthHeight(img, p_data, zoom) {
  let max_value, width, height;
  if (img.width * p_data.x >= img.height * p_data.y) {
    max_value = Math.min(img.width * p_data.x, 10000);
    const _width = max_value / p_data.x;
    const ratio = _width / img.width;
    width = img.width * ratio;
    height = img.height * ratio;
  } else {
    max_value = Math.min(img.height * p_data.y, 10000);
    const _height = max_value / p_data.y;
    const ratio = _height / img.height;
    width = img.width * ratio;
    height = img.height * ratio;
  }
  return {
    width: Math.round(width),
    height: Math.round(height),
  };
}

function makeDefaultCanvas(img, p_data, width, height) {
  const img_size_ratio = p_data.size * 0.01;
  const padding_size_ratio = 1 - p_data.size * 0.01;
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.translate((padding_size_ratio * width) / 2, (padding_size_ratio * height) / 2);
  ctx.scale(img_size_ratio, img_size_ratio);
  ctx.drawImage(img, 0, 0, width, height);
  return canvas;
}

function makeOneLineRepeatCanvas(idx, p_data, default_canvas, res_canvas_width, width, height) {
  function _makeGridPattern(default_canvas, is_even) {
    const canvas = document.createElement('canvas');
    canvas.width = width * 2;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(default_canvas, is_even ? 0 : width, 0, width, height);
    return canvas;
  }
  function makeDefaultCanvas2() {
    const canvas = document.createElement('canvas');
    canvas.width = width * (p_data.x + 1) * 2;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.rect(0, 0, canvas.width, canvas.height);
    const is_even = idx % 2 === 0;
    const pattern =
      p_data.style === 'repeat'
        ? default_canvas
        : _makeGridPattern(default_canvas, p_data.style === 'grid_1' ? is_even : !is_even);
    ctx.fillStyle = ctx.createPattern(pattern, 'repeat');
    ctx.fill();
    const pattern_translateX = 2 * width - ((width * 2 * (p_data.translateX / 200) * idx) % (width * 2));
    const imageData = ctx.getImageData(pattern_translateX, 0, width * p_data.x, height);
    canvas.width = width * p_data.x;
    canvas.height = height;
    ctx.putImageData(imageData, 0, 0);
    return canvas;
  }
  const repeat_one_line_canvas = makeDefaultCanvas2();

  const one_line_repeat_canvas = document.createElement('canvas');
  one_line_repeat_canvas.width = res_canvas_width;
  one_line_repeat_canvas.height = height;
  const ctx = one_line_repeat_canvas.getContext('2d');
  ctx.rect(0, 0, res_canvas_width, height);
  ctx.fillStyle = ctx.createPattern(repeat_one_line_canvas, 'repeat');
  ctx.fill();
  return one_line_repeat_canvas;
}

function make_pattern(img, p_data, zoom, cv_attrs) {
  p_data.translateX = p_data.translateX || 0;

  const { width, height } = getWidthHeight(img, p_data, zoom);
  const default_canvas = makeDefaultCanvas(img, p_data, width, height);
  const res_canvas_width = width * p_data.x;
  const res_canvas_height = height * p_data.y;
  const res_canvas = go(document.createElement('canvas'), function (canvas) {
    canvas.width = res_canvas_width;
    canvas.height = res_canvas_height;
    const ctx = canvas.getContext('2d');
    go(
      range(p_data.y),
      each(function (idx) {
        const one_line_repeat_canvas = makeOneLineRepeatCanvas(
          idx,
          p_data,
          default_canvas,
          res_canvas_width,
          width,
          height,
        );
        ctx.drawImage(one_line_repeat_canvas, 0, idx * height);
      }),
    );
    return canvas;
  });

  return new Promise(function (res) {
    const img2 = new Image();
    img2.crossOrigin = 'Anonymous';
    img2.src = res_canvas.toDataURL(
      {
        top: 0,
        left: 0,
        width,
        height,
      },
      1,
    );
    img2.onload = function () {
      res(img2);
    };
  });
}

export function makePatternObjA(is_vertical) {
  if ($.has_class($1('.mp_maker'), 'on_pattern')) return;

  const active = G.mp.maker.active();
  $.add_class($1('.mp_maker'), 'on_pattern');
  const pattern_dt = {
    x: $.val($1('.m_image_pattern .horizontal.number input')),
    y: $.val($1('.m_image_pattern .vertical.number input')),
    size: parseInt($.val($1('.m_image_pattern .controls.size .range input'))),
    translateX: parseInt($.val($1('.m_image_pattern .controls.translateX .range input'))),
    style: $.attr($1('.m_image_pattern .controls.type button.selected'), 'pattern_style'),
  };
  if (
    pattern_dt.x == 1 &&
    pattern_dt.y == 1 &&
    !(pattern_dt.size == 100 && pattern_dt.style == 'repeat' && pattern_dt.translateX === 0)
  ) {
    $.remove_class($1('.mp_maker'), 'on_pattern');
    $.val($1('.m_image_pattern .size.controls .range input'), 100);
    $.val($1('.m_image_pattern .translateX.controls .range input'), 0);
    $.selectable2($1('.m_image_pattern .controls.type .repeat'));
    $.trigger($1('.m_image_pattern .size.controls .range input'), 'change');
    return;
  }

  return _p.go(
    _p.mr(active.toObject(), pattern_dt, !!is_vertical),
    _p
      .if2(function (a, b) {
        return b.x == 1 && b.y == 1;
      })(function (cv_attrs) {
        $.add_class($1('.m_image_pattern'), 'default_pattern');
        if (!UtilF.isLegacyMobile()) $.remove_class($1('.m_image_pattern >.head'), 'checked');
        const width = G.mp.maker.fabric_get_width(cv_attrs);
        const angle = cv_attrs.angle;
        $.attr($1('.m_image_pattern .control.locking'), 'locked', true);
        if (cv_attrs._data.cv_type === 'cv_text_image_pattern') {
          if (cv_attrs._data.cv_text_image_attrs) {
            const scale = width / cv_attrs._data.cv_text_image_attrs.width;
            cv_attrs._data.cv_text_image_attrs.scaleX = scale;
            cv_attrs._data.cv_text_image_attrs.scaleY = scale;
            cv_attrs._data.cv_text_image_attrs.angle = angle;
            cv_attrs._data.cv_text_image_attrs.left = cv_attrs.left;
            cv_attrs._data.cv_text_image_attrs.top = cv_attrs.top;
          }
          return createCvTextImageA(
            cv_attrs._data.cv_text_image_attrs ? cv_attrs._data.cv_text_image_attrs : cv_attrs,
          );
        } else {
          if (cv_attrs._data.cv_image_attrs) {
            const scale = width / cv_attrs._data.cv_image_attrs.width;
            cv_attrs._data.cv_image_attrs.scaleX = scale;
            cv_attrs._data.cv_image_attrs.scaleY = scale;
            cv_attrs._data.cv_image_attrs.angle = angle;
            cv_attrs._data.cv_image_attrs.left = cv_attrs.left;
            cv_attrs._data.cv_image_attrs.top = cv_attrs.top;
            if (cv_attrs._data.is_fitted_to_print_width) {
              NewMakerFitPrintWidthF.activateDataFitToPrintWidth(cv_attrs._data.cv_image_attrs);
            } else {
              NewMakerFitPrintWidthF.cancelSetFitToPrintWidth(cv_attrs._data.cv_image_attrs);
            }
          }
          return createCvImageP(cv_attrs._data.cv_image_attrs ? cv_attrs._data.cv_image_attrs : cv_attrs);
        }
      })
      .else(function (cv_attrs, pattern_dt, is_vertical) {
        $.remove_class($1('.m_image_pattern'), 'default_pattern');
        if (!UtilF.isLegacyMobile()) $.add_class($1('.m_image_pattern >.head'), 'checked');
        return _p.mr(cv_attrs, pattern_dt, is_vertical);
      }, createCvPattern),
    _p.tap(function (rect) {
      rect.cid = active.cid || rect.cid;
      $.remove_class($1('.mp_maker'), 'cv_pattern cv_image cv_text_image cv_text_image_pattern');
      $.add_class($1('.mp_maker'), rect._data.cv_type);
    }),
    function (target_cv_object) {
      target_cv_object._data.nscreened = true;
      insertCvObject(target_cv_object, active, true);
      G.mp.maker.debounced_modified(target_cv_object);
    },
    function () {
      $.remove_class($1('.mp_maker'), 'on_pattern');
    },
  );
}

function isCvTextOnlyFlex() {
  const active = G.mp.maker.active();
  const cv_obj = getCvTextImage(active) || getCvImage(active);
  if (!cv_obj) return;
  return cv_obj._data.only_flex === true;
}

export const makePatternEvent = (el) =>
  go(
    el,
    $.on3(
      'click',
      '.m_image_pattern >.head',
      errorHandlingEventWrapper(function (e) {
        if (isCvTextOnlyFlex()) return $.alert(T('선택된 색상으로 패턴을 만들수 없습니다.'));
        // $.toggle_class(e.currentTarget, 'checked');
        if ($.has_class(e.currentTarget, 'checked')) {
          _go($('.controls.number input'), $.val(1));
        } else {
          _go($('.controls.number input'), $.val(2));
        }
        makePatternObjA();
        go(e.currentTarget, $closest('.activity'), NewMakerRangeSliderF.start, setIScroll);
      }),
    ),
    $.on(
      'click',
      '.m_image_pattern .control.locking',
      errorHandlingEventWrapper(function (e) {
        if ($.has_class($1('.mp_maker'), 'on_pattern')) return;
        if (UtilF.isLegacyMobile() && !G.mp.maker.active()) return $.frame.close();
        if (isCvTextOnlyFlex()) return $.alert(T('선택된 색상으로 패턴을 만들수 없습니다.'));
        const x = $.val($1('.m_image_pattern .horizontal.number input'));
        const y = $.val($1('.m_image_pattern .vertical.number input'));
        if (x === 1 && y === 1) return;
        const dt = e.delegateTarget;
        $.toggle_attr(e.currentTarget, 'locked');
        if ($.attr($.find1(dt, '.control.locking'), 'locked')) {
          const num = $.val($.find1(dt, '.control.horizontal.number input'));
          $.val($.find1(dt, '.control.vertical.number input'), num);
          makePatternObjA(true);
        }
      }),
    ),

    $.on(
      'click',
      '.m_image_pattern .control.number .operator',
      errorHandlingEventWrapper(function (e) {
        const ct = e.currentTarget;
        if ($.has_class($1('.mp_maker'), 'on_pattern')) return;
        if (UtilF.isLegacyMobile() && !G.mp.maker.active()) return $.frame.close();
        if (isCvTextOnlyFlex()) return $.alert(T('선택된 색상으로 패턴을 만들수 없습니다.'));
        const el_body = $.closest(ct, '.body');
        const el_control = _go(ct, $.closest('.control'));
        const el_input = _go(el_control, $.find1('input'));
        const operator = _go(ct, $.attr('operator'));
        const locked = _go(el_body, $.find1('.control.locking'), $.attr('locked'));
        const x = $.val($1('.m_image_pattern .horizontal.number input'));
        const y = $.val($1('.m_image_pattern .vertical.number input'));
        const is_horizontal = $.closest(ct, '.control.horizontal');
        if (operator === '-' && is_horizontal && x === 1) return;
        if (operator === '-' && !is_horizontal && y === 1) return;
        _go(
          el_input,
          _p.all($.val, $.attr('max'), $.attr('min')),
          function (val, max, min) {
            const expect_val = _p.l('$' + operator + 1)(val);
            if (expect_val > max || expect_val < min) return val;
            return expect_val;
          },
          function (val) {
            if (locked) {
              $.val(_go(el_body, $.find('input[type="number"]')), val);
              makePatternObjA();
            } else {
              $.val(el_input, val);
              makePatternObjA($.has_class(el_control, 'vertical'));
            }
          },
        );

        go(ct, $closest('.activity'), NewMakerRangeSliderF.start, setIScroll);
      }),
    ),

    $.on(
      'click',
      '.controls.type button',
      errorHandlingEventWrapper(function (e) {
        if (UtilF.isLegacyMobile() && !G.mp.maker.active()) return $.frame.close();
        if ($.has_class($1('.mp_maker'), 'on_pattern')) return;
        if (isCvTextOnlyFlex()) return $.alert(T('선택된 색상으로 패턴을 만들수 없습니다.'));
        $.selectable(e);
        makePatternObjA();
      }),
    ),

    $.on(
      'input.range',
      '.controls.size .range input',
      errorHandlingEventWrapper(function (e) {
        if (UtilF.isLegacyMobile() && !G.mp.maker.active()) return $.frame.close();
        if ($.has_class($1('.mp_maker'), 'on_pattern')) return;
        if (isCvTextOnlyFlex()) return $.alert(T('선택된 색상으로 패턴을 만들수 없습니다.'));
        $.val($.find1(e.delegateTarget, '.controls.size .number input'), $.val(e.currentTarget));
        makePatternObjA();
      }),
    ),

    $.on(
      'input.range',
      '.controls.translateX .range input',
      errorHandlingEventWrapper(function (e) {
        if (UtilF.isLegacyMobile() && !G.mp.maker.active()) return $.frame.close();
        if ($.has_class($1('.mp_maker'), 'on_pattern')) return;
        if (isCvTextOnlyFlex()) return $.alert(T('선택된 색상으로 패턴을 만들수 없습니다.'));
        $.val($.find1(e.delegateTarget, '.controls.translateX .number input'), $.val(e.currentTarget));
        makePatternObjA();
      }),
    ),
  );

async function getUrlForPattern(cv_attrs, pattern_data) {
  const real_width = G.mp.maker.fabric_get_width(cv_attrs) / pattern_data.x;

  let img_src = cv_attrs._data.cv_image_attrs ? cv_attrs._data.cv_image_attrs.src : cv_attrs.src;

  // $도수 - 도수 컬러 적용된 케이스에서는 cv_attrs 이미지 src 는 도수 색상 적용 안된 상태 -> 색상을 입힌 dataUrl 로 src 사용
  const dosu_color_hex_code = cv_attrs._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR];
  if (dosu_color_hex_code != null) {
    img_src = await OMPDosuF.applyColorToImage({ hex_code: dosu_color_hex_code, img_src, isToDataUrl: true });
  }

  const url = await NewMakerImageF.makeResizedImage({
    width: real_width,
    url: img_src,
    need_resize: G.mp.maker.is_auto_print || G.mp.maker.sharp_resize_print,
  });
  if (url.indexOf('base64') !== -1) return url;
  return G.remove_protocol2(url) + G.mp.maker.cross_origin_query_str;
}

function getSrcForPattern(cv_attrs) {
  if (cv_attrs._data.text_info) return cv_attrs.src;
  const cv_text_image_attrs = sel('_data.cv_text_image_attrs', cv_attrs);
  if (cv_text_image_attrs) return cv_text_image_attrs.src;
}

function isLikeCvPattern(cv_attrs) {
  if (cv_attrs._data.cv_type === 'cv_image') return true;
  if (cv_attrs._data.cv_type === 'cv_pattern') return true;
  if (sel('_data.cv_image_attrs', cv_attrs)) return true;
}

export async function createCvPattern(
  cv_attrs,
  pattern_dt,
  is_vertical,
  zoom,
  is_when_only_draw,
  group_zoom = 1,
) {
  const nscreend = cv_attrs._data.nscreened;
  zoom = zoom || 1;
  const pattern_data = pattern_dt || cv_attrs._data.pattern_data;
  const url = isLikeCvPattern(cv_attrs)
    ? await getUrlForPattern(cv_attrs, pattern_data)
    : await go(getSrcForPattern(cv_attrs), function (src) {
        if (src && zoom === 1) {
          if (sel('_data.cv_text_image_attrs', cv_attrs)) {
            cv_attrs._data.cv_text_image_attrs.src = src;
          }
          return src;
        }
        if (zoom === 1) {
          return createCvTextImageSrc(cv_attrs._data.cv_text_image_attrs, zoom, {
            width: ((getFwidth(cv_attrs) * group_zoom) / pattern_data.x) * 2,
            height: ((getFheight(cv_attrs) * group_zoom) / pattern_data.y) * 2,
          });
        }
        return createCvTextImageSrc(cv_attrs._data.cv_text_image_attrs, zoom, {
          width: (getFwidth(cv_attrs) * group_zoom) / pattern_data.x,
          height: (getFheight(cv_attrs) * group_zoom) / pattern_data.y,
        });
      });

  return new Promise(function (resolve) {
    const img = new Image();
    img.crossOrigin = 'Anonymous';

    img.src = url;
    img.onload = function () {
      _p.go(make_pattern(img, pattern_data, zoom, cv_attrs), async function (pattern_img) {
        const rect = new fabric.Image(pattern_img, {
          //너무 큰가??
          top: cv_attrs.top,
          left: cv_attrs.left,
          objectCaching: false,
        });

        if (is_vertical === undefined) {
          // scale_to_width = G.mp.maker.fabric_get_width(cv_attrs);
          // rect.scaleToWidth(scale_to_width);
          rect.scale(getFwidth(cv_attrs) / rect.width);
        } else if (is_vertical) {
          // scale_to_width = G.mp.maker.fabric_get_width(cv_attrs);
          // rect.scaleToWidth(scale_to_width);
          rect.scale(getFwidth(cv_attrs) / rect.width);
        } else {
          // const scale_to_width = G.mp.maker.fabric_get_height(cv_attrs);
          // rect.scaleToHeight(scale_to_width);
          rect.scale(getFheight(cv_attrs) / rect.height);
        }
        rect.set(
          _p.pick(cv_attrs, [
            'flipX',
            'flipY',
            'evented',
            'selectable',
            'visible',
            'is_overflow',
            'is_oversized',
          ]),
        );
        rect.set('angle', cv_attrs.angle);
        rect.cid = cv_attrs.cid || rect.cid;

        rect.set('_data', {
          pattern_data,
          request_bg_removal: cv_attrs.request_bg_removal,
        });

        if (isLikeCvPattern(cv_attrs)) {
          rect._data.cv_image_attrs = cv_attrs._data.cv_image_attrs || G.mp.maker.to_attrs2(cv_attrs);
          rect._data.cv_type = 'cv_pattern';
          if (cv_attrs._data.is_fitted_to_print_width) {
            NewMakerFitPrintWidthF.activateDataFitToPrintWidth(rect);
          } else {
            NewMakerFitPrintWidthF.cancelSetFitToPrintWidth(rect);
          }
        } else {
          rect._data.cv_text_image_attrs =
            cv_attrs._data.cv_text_image_attrs || G.mp.maker.to_attrs2(cv_attrs);
          rect._data.cv_type = 'cv_text_image_pattern';
        }
        extend(
          rect._data,
          pick(['width_cm', 'height_cm', 'real_width_cm', 'real_height_cm'], cv_attrs._data),
        );

        // $도수 - 패턴 생성시 _data 내 도수 관련 값 복사
        extend(rect._data, pick(Object.values(OMPDosuConstantS._DATA_NAMES), cv_attrs._data));

        if (cv_attrs._data.clone_parent_cid) {
          rect._data.clone_parent_cid = cv_attrs._data.clone_parent_cid;
        }
        rect._data.nscreened = nscreend;
        const color_code2 =
          rect._data?.cv_text_image_attrs?._data?.color_code2 ||
          rect._data?.cv_image_attrs?._data?.color_code2;
        if (color_code2) {
          rect._data.color_code2 = color_code2;
          await NewMakerPropertyBpfF.bpcColorCode2Render.applyColor(rect);
        }
        go(renderMakerPageShade(getShadeFcanvas(), rect), () => resolve(rect));
      });
    };
  });
}
