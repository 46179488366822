import { go } from 'fxjs/es';
import { MpAddressConstantS } from '../../../Mp/Address/S/Constant/module/MpAddressConstantS.js';

import { legacyHtml } from '../../../Util/S/Function/util.js';

export const en_terms = () => pug`
    .terms_body
      .title
        h1 Terms &amp; Conditions
      .body
        .text_body
          .paragraph
            h3 Visitor Agreement
            p Any person or entity (“User”) visiting, using or otherwise accessing the site at marpple.com (“Site”) or any information contained in the site agrees to and is bound by the following terms and conditions of this visitor agreement (“Agreement”). You understand that by using the Site and the Service, you have agreed to the terms and conditions of this Agreement and you agree to use the Site and the Service solely as provided in this Agreement.
            p Intellectual Property Rights of Marpple.com
            p Marpple.com is committed to the appropriate and legal use of the intellectual property of others, and we require our users to behave similarly. Unless otherwise noted, all materials, including images, illustrations, designs, icons, photographs and written and other materials that appear on the Marpple.com Site (collectively the "Contents") are copyrights, trademarks, trade dress and/or other intellectual properties owned, controlled or licensed by Marpple.com.
          <hr> 
          .paragraph
            h3 Eligibility
            p Our services are available only to, and may only be used by, individuals who can form legally binding contracts under applicable law. Our services are not available to persons under 16 years of age.
          <hr>
          .paragraph
            h3 Use of Site
            p Generally, products created using images available on the site should not be resold commercially. In the event that you wish to resell such products, you agree to contact Marpple.com and verify the legality of reselling such products prior to doing so. Designs created using the text tools and images available through the site are in no way the exclusive property of the customers who assemble such designs. Marpple.com retains the right to display such designs or offer them (or variations of such designs) to other customers.
            p Additionally, Marpple.com has the sole discretion to reject any order that it considers libelous, defamatory, obscene, profane (according to standards established by the FCC), portraying irresponsible use of alcohol or other substances, advocating persecution based on gender, age, race, religion, disability or national origin, containing explicit sexual content or is otherwise inappropriate for Marpple.com production.
            p You agree not to use Marpple.com's service to create any material that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable, or that infringes on any patent, trademark, trade secret, copyright or other proprietary rights of any third party.
          <hr>
          .paragraph
            h3 Featured Links
            p The Site displays and/or refers to links to other websites from time to time. Marpple: (i) does not endorse or take responsibility for the content of such websites, (ii) is not responsible for the availability of such websites; and (iii) will not be liable in any way for any loss or damage which you may suffer by using such websites. If you decide to access linked websites, you do so at your own risk.
          <hr>
          .paragraph
            h3 Links to Us
            p Any other website may link to our Site, provided it does not imply any endorsement of its products or services by Marpple, does not misrepresent its relationship with or present false information about Marpple, does not infringe any intellectual property or other right of any person and complies with all relevant laws and regulations. Please note, however, that Marpple reserves the right to withdraw such permission at any time and to take any other appropriate action.
          <hr>
          .paragraph
            h3 Putting content on the Marpple.com
            p You keep the copyright in any content you submit or upload to the website. In order to receive the Marpple services you grant Marpple a non-exclusive royalty free license to use and archive the content in accordance with or as reasonably contemplated by this agreement.
            ul.list_style
              li You own all copyright in the content, or if you are not the owner, that you have permission to use the content, and that you have all of the rights required to display, reproduce and sell the content;
              li The content you upload will not infringe the intellectual property rights or other rights of any person or entity, including copyright, moral rights, trade mark, patent or rights of privacy or publicity;
              li Your use of the website will comply with all applicable law, rules and regulations;
              li The content does not contain material that defames or vilifies any person, people, races, religion or religious group and is not obscene, pornographic, indecent, harassing, threatening, harmful, invasive of privacy or publicity rights, abusive, inflammatory or otherwise objectionable;
              li The content does not include malicious code, including but not limited to viruses, trojan horses, worms, time bombs, cancelbots, or any other computer programming routines that may damage, interfere with, surreptitiously intercept, or expropriate any system, program, data, or personal information; and
              li The content is not misleading and deceptive and does not offer or disseminate fraudulent goods, services, schemes, or promotions.
            p Marpple reserves the right to review and if in its sole discretion deemed necessary, remove any content from the website and / or cancel your account, because that content breaches your agreement with us and / or any applicable laws, or otherwise. You agree to indemnify Marpple in respect of any direct or indirect damage caused due to your breach of one or more of these warranties.                
          <hr>
          .paragraph
            h3 Intellectual Property Rights and License
            p By submitting listings to Marpple.com, you grant Marpple a non-exclusive, worldwide, royalty-free, sublicense able and transferable license to use, reproduce, distribute, prepare derivative works of and display the content of such listings in connection with Marpple's (and its successors' and affiliates') services and business in facilitating the sale of your product, including without limitation for promoting and redistributing part or all of the Marpple.com site (and derivative works thereof) in any media formats and through any media channels. You also hereby grant each user of the Marpple.com site a non-exclusive license to access your content through the site, and to use, reproduce, distribute, and display such content as permitted through the functionality of the site and under these Terms & Conditions. The above licenses terminate within a commercially reasonable time after you remove or delete your listings from Marpple.com. The above licenses granted by you in user comments you submit, are perpetual and irrevocable.
            p All intellectual property rights in this website and the Marpple service (including the software and systems underlying the Marpple service, and text, graphics, logos, icons, sound recordings and software) are owned by or licensed to us. Other than for the purposes of, and subject to the conditions prescribed under relevant Copyright and Trade Mark legislation throughout the world, and except as expressly authorized by this agreement, you may not in any form or by any means:
            ul
              li use, adapt, reproduce, store, distribute, print, display, perform, publish or create derivative works from any part of this website; or
              li commercialize any information, products or services obtained from any part of this website,
            p without our written permission.
            p If you use any of our trademarks in reference to our activities, products or services, you must include a statement attributing that trade mark to us. You must not use any of our trademarks in or as the whole or part of your own trademarks; in connection with activities, products or services which are not ours; in a manner which may be confusing, misleading or deceptive; or in a manner that disparages us or our information, products or services (including this website).
          <hr>
          .paragraph
            h3 Reporting inappropriate content to Marpple
            p Marpple does not manually screen content before it is displayed on the website so occasionally members may inadvertently or deliberately submit and display content that breaches this agreement.
            p Inappropriate content includes, but is not limited to, content that infringes the copyright or other intellectual property rights of any person or company, or that defames or vilifies any person, people, races, religion or religious group, is obscene, pornographic, indecent, harassing, threatening, harmful, invasive of privacy or publicity rights, abusive, inflammatory or otherwise objectionable. Marpple reserves the right (but not the obligation) to remove or edit such content, but does not regularly review posted content.
            p Please help us by letting us know straight away about any inappropriate, or potentially inappropriate, content you see on the website. If you believe your copyright or other intellectual property rights are being infringed, you are able to make a formal complaint to <a href="mailto:info@marpple.com">info@marpple.com</a>.
          <hr>
          .paragraph
            h3 Shipping / Delivery Times
            p Marpple’s orders are generally delivered within 14 days. Customers who are concerned about meeting deadlines should contact a Service/Sales Representative. Delivery times may vary during the holiday seasons.
          <hr>
          .paragraph
            h3 Returns Policy
            p Marpple.com goes to great lengths to ensure that all orders are filled accurately and on time and are of the highest quality. Accordingly, Marpple.com accepts returns and will refund payment if and when customers are disappointed by Marpple.com merchandise for one of the following reasons: (a) the blank items themselves are materially flawed; (b) the quality of the decorating (i.e., the printing or embroidery) is below a reasonable range of expectations; or (c) the design of the final product is materially different from the final design submitted by the customer.
          <hr>
          .paragraph
            h3 Damaged Goods
            p If a product is delivered to a customer, whether in electronic or printed format, that is electronically or physically damaged in some way (for example, a misprinted, a framed print has cracked, or there are loose products) Marpple will happily contact the manufacturer or seller to issue a replacement copy of the product after receiving reasonable proof of that damage.
          <hr>
          .paragraph
            h3 Cancellation Policy
            p You may change or cancel your order within twenty-four (24) hours of submitting your order.
          <hr>
          .paragraph
            h3 Hacking
            p You agree not to attempt to damage, deny service to, hack, crack, reverse-engineer, or otherwise interfere (collectively, “Interfere”) with the Site in any manner. If you in any way interfere with the Site, you agree to pay or refund all damages incurred by Marpple. Marpple will cooperate with the authorities in prosecuting any User who interferes with the Site or otherwise attempts to defraud Marpple or any other parties through User’s use of the Site or services provided via the Site. Marpple reserves the right to deny any or all access or service to any User for any reason, at any time, at our sole discretion. User agrees that we may block User’s IP address or addresses at any time, and at our sole discretion to disallow User’s continued use of the Site without giving you any advance notice. We reserve the right to take any action we may deem appropriate in our sole discretion with respect to violations or enforcement of the terms of this Agreement, and we expressly reserve all rights and remedies available to us at law or in equity.
          <hr>
          .paragraph
            h3 Re-targeting
            p Marpple offers users the ability (through the Marketing tab on the dashboard) to run campaigns re-targeting consumers based on data that has been collected through the Marpple platform. Marpple works with a third party vendor called Unite and its partner Google and AppNexus to collect and manage such data and deliver the targeted advertisements on unaffiliated websites around the Internet. By using the marketing and retargeting feature, you agree that in addition to these terms and conditions, your activities shall be governed by http://www.appnexus.com/en/company/platform-privacy-policy#choices–and that Marpple, Unite and/or AppNexus have the right to insert an opt-out mechanism on your advertisements.
          <hr>
          .paragraph
            h3 Complaints
            p If you believe that you are the owner of the copyright or other rights in any material(s) appearing on Marpple, and/or that ownership of the copyright or other rights in any material has been incorrectly used or represented on Marpple, please contact us via <a href="mailto:info@marpple.com">info@marpple.com</a>.
          <hr>
          .paragraph
            h3 Trade Marks
            p The brands, products and service names used in this Site (including without limitation, “Marpple”) are the trademarks or trade names of Marpple or it’s trading partners unless otherwise stated. You may not distribute products or offer services under or by reference to or otherwise use or reproduce any such trademarks, trade names or taglines without the prior written permission of Marpple.
          <hr>
          .paragraph
            h3 Registration
            p If you register with the Site, you must: agree to the terms of the Registered User Agreement; agree to keep your password secure and confidential and not allow anyone else to use your email address or password to access the Site; agree not to do anything which would assist anyone who is not a registered User to gain access to any secured area of the Site; and agree not to create additional registration accounts for the purpose of abusing the functionality of the Site, or other Users, or to seek to pass yourself off as another User.
          <hr>
          .paragraph
            h3 Limitation of Liability
            p User agrees that the liability of Marpple to User under this Agreement shall be limited to the greater of the amount User has actually paid to Marpple for its services and $100. Nothing in this Agreement shall operate to limit or exclude liability for death or personal injury caused by negligence of Marpple.
          <hr>
          .paragraph
            h3 Indemnity
            p User agrees to indemnify Marpple for any loss or damage that may be incurred by Marpple, including without limitation legal fees, arising from User’s use of the Site or User’s use of any information obtained through the Site.
          <hr>
          .paragraph
            h3 Privacy Policy
            p Your privacy is very important to us. Users of our website should refer to our privacy policy – which is incorporated into this agreement by reference, for information about how we collect and use personal information.
          <hr>
          .paragraph
            h3 Security of Information
            p No data transmission over the Internet can be guaranteed as totally secure. We strive to protect such information, however we do not warrant and cannot ensure the security of any information that you transmit to us. Accordingly, any information that you transmit to us is transmitted at your own risk.
          <hr>
          .paragraph
            h3 Termination of Access
            p Access to this website may be terminated at any time by us without notice. Our disclaimer will nevertheless survive any such termination.
          <hr>
          .paragraph
            h3 Severability
            p If any part, term, or provision of this Agreement shall be held by a court of competent jurisdiction to be illegal, unenforceable, or in conflict with any relevant law, the remaining portions or provisions shall still remain valid and continue in full force and effect.
          <hr>
          .paragraph
            h3 Changes to this Agreement
            p This Agreement and/or any other terms of service may be changed without prior notice, and any such amendment will be applicable to all Users from the date and time such revised terms have been posted on the Site. User’s continued use of the Site or services constitutes agreement with and acceptance of any such amendment or other changes.
          <hr>
          .paragraph
            h3 Indemnity
            p You agree to defend, indemnify and hold Marpple.com and its affiliates harmless from and against any and all claims, damages, costs and expenses, including reasonable attorneys' fees, arising from or relating to your use of Marpple.com's Site and the Service, your violation of this Agreement, or your violation of any rights of another.
          <hr>
          .paragraph
            h3 Governing Law
            p This Agreement shall be governed by and will be construed in accordance with the laws of South Korea. Any disputes arising under or in connection with this Agreement shall be subject to the exclusive jurisdiction of the Korean Courts.
      .option
        button.done Done    
`;

export const kr_terms = () => legacyHtml`
  <div class="terms_body">
    <div class="title">
      <h1>이용약관</h1>
    </div>
    <div class="body">
      <div class="agree_header">
        <p>회원가입 전 반드시 이용약관을 읽어보시기 바랍니다.</p>
        <div class="table_of_contents">
          <ul>
            <li><a href="#service1">제1조(목적)</a></li>
            <li><a href="#service2">제2조(정의)</a></li>
            <li><a href="#service3">제3조(약관 등의 명시와 설명 및 개정)</a></li>
            <li><a href="#service4">제4조(서비스의 제공 및 변경)</a></li>
            <li><a href="#service5">제5조(서비스의 중단)</a></li>
            <li><a href="#service6">제6조(회원가입)</a></li>
            <li><a href="#service7">제7조(회원 탈퇴 및 자격 상실 등)</a></li>
          </ul>
          <ul>
            <li><a href="#service8">제8조(회원에 대한 통지)</a></li>
            <li><a href="#service9">제9조(구매신청)</a></li>
            <li><a href="#service10">제10조(계약의 성립)</a></li>
            <li><a href="#service11">제11조(지급방법)</a></li>
            <li><a href="#service12">제12조(수신확인통지, 구매신청 변경 및 취소)</a></li>
            <li><a href="#service13">제13조(재화 등의 공급)</a></li>
            <li><a href="#service14">제14조(환급)</a></li>
          </ul>
          <ul>
            <li><a href="#service15">제15조(청약철회 등)</a></li>
            <li><a href="#service16">제16조(청약철회 등의 효과)</a></li>
            <li><a href="#service17">제17조(개인정보보호)</a></li>
            <li><a href="#service18">제18조(“몰“의 의무)</a></li>
            <li><a href="#service19">제19조(회원의 ID 및 비밀번호에 대한 의무)</a></li>
            <li><a href="#service20">제20조(이용자의 의무)</a></li>
            <li><a href="#service21">제21조(연결“몰”과 피연결“몰” 간의 관계)</a></li>
          </ul>
          <ul>
            <li><a href="#service22">제22조(저작권의 귀속 및 이용제한)</a></li>
            <li><a href="#service23">제23조(분쟁해결)</a></li>
            <li><a href="#service24">제24조(적립금 제도 운영)</a></li>
            <li><a href="#service25">제25조(상품쿠폰)</a></li>
            <li><a href="#service26">제26조(면책)</a></li>
            <li><a href="#service27">제27조(정보의 제공 및 광고의 게재)</a></li>
            <li><a href="#service28">제28조(재판권 및 준거법)</a></li>
          </ul>
        </div>
      </div>
      <div class="text_body">
        <div class="paragraph">
          <h3 id="service1">제1조(목적)</h3>
          <p>
            이 약관은 (주)마플코퍼레이션(전자상거래 사업자)이 운영하는 [마플, 마플샵 (이하 "몰"이라 한다)]에서
            제공하는 개발 및 주문인쇄제작(PRINT ON DEMAND)을 통한 전자상거래 관련 서비스(이하 "서비스"라
            한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.
          </p>
          <p>
            ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을
            준용합니다.」
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service2">제2조(정의)</h3>
          <ol>
            <li>
              ① "몰"이란 (주)마플코퍼레이션이 재화 또는 용역(이하 "재화 등"이라 함)을 이용자에게 제공하기
              위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을
              말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
            </li>
            <li>
              ② "이용자"란 "몰"에 접속하여 이 약관에 따라 "몰"이 제공하는 서비스를 받는 회원 및 비회원을
              말합니다.
            </li>
            <li>
              ③ ‘회원’이라 함은 "몰"에 회원등록을 한 자로서, 계속적으로 "몰"이 제공하는 서비스를 이용할 수
              있는 자를 말합니다.
            </li>
            <li>④ ‘비회원’이라 함은 회원에 가입하지 않고 "몰"이 제공하는 서비스를 이용하는 자를 말합니다.</li>
            <li>
              ⑤ 이외에 이 약관에서 사용하는 용어의 정의는 관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service3">제3조 (약관 등의 명시와 설명 및 개정)</h3>
          <ol>
            <li>
              ① "몰"은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는
              곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호,
              개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 홈페이지의 초기 서비스화면(전면)에
              게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
            </li>
            <li>
              ② "몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임,
              환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을
              제공하여 이용자의 확인을 구하여야 합니다.
            </li>
            <li>
              ③ "몰"은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서
              및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에
              관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서
              이 약관을 개정할 수 있습니다.
            </li>
            <li>
              ④ "몰"이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에
              그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을
              변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "몰"은 개정 전
              내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
            </li>
            <li>
              ⑤ "몰"이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그
              이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을
              체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에
              "몰"에 송신하여 "몰"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
            </li>
            <li>
              ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에
              관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 「전자상거래 등에서의 소비자
              보호지침」 및 관계법령 또는 상관례에 따릅니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service4">제4조(서비스의 제공 및 변경)</h3>
          <ol>
            <li>
              ① "몰"은 다음과 같은 업무를 수행합니다.
              <ol>
                <li>1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결</li>
                <li>2. 구매계약이 체결된 재화 또는 용역의 제작 및 배송</li>
                <li>3. 마플샵의 경우 판매자의 샵 운영과 관련된 정보의 제공</li>
                <li>4. 기타 "몰"이 정하는 업무</li>
              </ol>
            </li>
            <li>
              ② "몰"은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해
              제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및
              제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
            </li>
            <li>
              ③ "몰"이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의
              변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
            </li>
            <li>
              ④ 전항의 경우 "몰"은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, "몰"이 고의 또는 과실이
              없음을 입증하는 경우에는 그러하지 아니합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service5">제5조(서비스의 중단)</h3>
          <ol>
            <li>
              ① "몰"은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는
              서비스의 제공을 일시적으로 중단할 수 있습니다.
            </li>
            <li>
              ② "몰"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은
              손해에 대하여 배상합니다. 단, "몰"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
              아니합니다.
            </li>
            <li>
              ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는
              "몰"은 제8조에 정한 방법으로 이용자에게 통지하고 당초 "몰"에서 제시한 조건에 따라 소비자에게
              보상합니다. 다만, "몰"이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 포인트 또는 적립금
              등을 "몰"에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service6">제6조(회원가입)</h3>
          <ol>
            <li>
              ① 이용자는 "몰"이 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관과 "개인정보 취급방침"에
              동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
            </li>
            <li>
              ② 마플와 마플샵은 회원정보를 상호 공유하며 둘 중 한곳에 가입을 신청한 이용자는 회원으로 등록될
              경우 두 웹사이트 모두 하나의 회원정보로 이용이 가능합니다.
            </li>
            <li>
              ③ "몰"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한
              회원으로 등록합니다.
              <ol>
                <li>
                  1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만
                  제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "몰"의 회원재가입 승낙을 얻은
                  경우에는 예외로 한다.
                </li>
                <li>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                <li>3. 회원 가입일 현재 만 14세 미만인 경우</li>
                <li>4. 기타 회원으로 등록하는 것이 "몰"의 기술상 현저히 지장이 있다고 판단되는 경우</li>
                <li>5. 회원가입계약의 성립 시기는 "몰"의 승낙이 회원에게 도달한 시점으로 합니다.</li>
                <li>
                  6. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 "몰"에 대하여
                  회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service7">제7조(회원 탈퇴 및 자격 상실 등)</h3>
          <ol>
            <li>① 회원은 "몰"에 언제든지 탈퇴를 요청할 수 있으며 "몰"은 즉시 회원탈퇴를 처리합니다.</li>
            <li>
              ② 회원이 다음 각 호의 사유에 해당하는 경우, "몰"은 회원자격을 제한 및 정지시킬 수 있습니다.
              <ol>
                <li>1. 가입 신청 시에 허위 내용을 등록한 경우</li>
                <li>
                  2. "몰"을 이용하여 구입한 재화 등의 대금, 기타 "몰"이용에 관련하여 회원이 부담하는 채무를
                  기일에 지급하지 않는 경우
                </li>
                <li>
                  3. 다른 사람의 "몰" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
                </li>
                <li>4. "몰"을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                <li>5. 기타 서비스 운영을 고의로 방해하는 행위를 하는 경우</li>
              </ol>
            </li>
            <li>
              ③ "몰"이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가
              시정되지 아니하는 경우 "몰"은 회원자격을 상실시킬 수 있습니다.
            </li>
            <li>
              ④ "몰"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고,
              회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service8">제8조(회원에 대한 통지)</h3>
          <ol>
            <li>
              ① "몰"이 회원에 대한 통지를 하는 경우, 회원이 "몰"과 미리 약정하여 지정한 전자우편 주소 또는 SMS
              등으로 할 수 있습니다.
            </li>
            <li>
              ② "몰"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "몰" 게시판에 게시함으로서 개별 통지에
              갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는
              개별통지를 합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service9">제9조(구매신청)</h3>
          <ol>
            <li>
              ① "몰"이용자는 "몰"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "몰"은 이용자가
              구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
              <ol>
                <li>1. 재화 등의 검색 및 선택</li>
                <li>2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</li>
                <li>
                  3. 약관내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한
                  확인
                </li>
                <li>4. 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)</li>
                <li>5. 재화 등의 구매신청 및 이에 관한 확인 또는 "몰"의 확인에 대한 동의</li>
                <li>6. 결제방법의 선택</li>
              </ol>
            </li>
            <li>
              ② "몰"이 제3자에게 구매자 개인정보를 제공·위탁할 필요가 있는 경우 실제 구매신청 시 구매자의
              동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이 때 "몰"은 제공되는
              개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용 목적 및 보유‧이용 기간 등을 구매자에게
              명시하여야 합니다. 다만 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 제25조 제1항에 의한
              개인정보 취급위탁의 경우 등 관련 법령에 달리 정함이 있는 경우에는 그에 따릅니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service10">제10조 (계약의 성립)</h3>
          <ol>
            <li>
              ① "몰"은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만,
              미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는
              법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
              <ol>
                <li>1. 신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                <li>2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우</li>
                <li>3. 기타 구매신청에 승낙하는 것이 "몰" 기술상 현저히 지장이 있다고 판단하는 경우</li>
              </ol>
            </li>
            <li>
              ② "몰"의 승낙이 제12조제1항의 수신확인 통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로
              봅니다.
            </li>
            <li>
              ③ "몰"의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정
              취소 등에 관한 정보 등을 포함하여야 합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service11">제11조(지급방법)</h3>
          <p>
            "몰"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수
            있습니다. 단, "몰"은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여
            징수할 수 없습니다.
          </p>
          <ol>
            <li>1. 인터넷뱅킹 등의 각종 계좌이체</li>
            <li>2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
            <li>3. 온라인무통장입금</li>
            <li>4. 마일리지 등 "몰"이 지급한 포인트에 의한 결제</li>
            <li>5. "몰"과 계약을 맺었거나 "몰"이 인정한 상품권에 의한 결제</li>
            <li>6. 기타 전자적 지급 방법에 의한 대금 지급 등</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service12">제12조(수신확인통지, 구매신청 변경 및 취소)</h3>
          <ol>
            <li>① "몰"은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.</li>
            <li>
              ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시
              구매신청 변경 및 취소를 요청할 수 있고 "몰"은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이
              그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한
              규정에 따릅니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service13">제13조(재화 등의 공급)</h3>
          <ol>
            <li>
              ① "몰"은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, '몰'이 재화 공급 가능한
              빠른 시일 내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만,
              "몰"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 지체없이 조치를 취합니다. 이때
              "몰"은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
            </li>
            <li>
              ② "몰"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을
              명시합니다. 이때 재화의 생산 중단 및 "몰"의 기타 사정에 의한 재화의 공급이 어려운 경우 재화는
              고지 없이 제공이 중단될 수 있으며 이에 대하여 회사는 책임을 부담하지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service14">제14조(환급)</h3>
          <p>
            "몰"은 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체
            없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 이용자에게 대금을
            환급하거나 환급에 필요한 조치를 취합니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service15">제15조(청약철회 등)</h3>
          <ol>
            <li>
              ① "몰"과 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한
              법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이
              늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일
              이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의
              소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
            </li>
            <li>
              ② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수
              없습니다.
              <ol>
                <li>
                  1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을
                  확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
                </li>
                <li>2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우</li>
                <li>3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우</li>
                <li>
                  4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
                </li>
                <li>
                  5. 청약철회를 인정하는 경우 "몰"에게 회복할 수 없는 중대한 피해가 예상되어 사전에 청약철회
                  제한에 대하여 고지하고 이용자의 동의를 받은 경우 (예.이용자의 주문에 따라 개별적으로
                  생산되는 재화)
                </li>
              </ol>
            </li>
            <li>
              ③ 제2항 제2호 내지 제4호의 경우에 "몰"이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알
              수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이
              제한되지 않습니다.
            </li>
            <li>
              ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나
              계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알
              수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service16">제16조(청약철회 등의 효과)</h3>
          <ol>
            <li>
              ① "몰"은 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을
              환급합니다. 이 경우 "몰"이 이용자에게 재화등의 환급을 지연한 때에는 그 지연기간에 대하여
              「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여
              산정한 지연이자를 지급합니다.
            </li>
            <li>
              ② "몰"은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의
              대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를
              정지 또는 취소하도록 요청합니다.
            </li>
            <li>
              ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. "몰"은
              이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이
              표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에
              필요한 비용은 "몰"이 부담합니다.
            </li>
            <li>
              ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 "몰"은 청약철회 시 그 비용을 누가
              부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service17">제17조(개인정보보호)</h3>
          <ol>
            <li>
              ① "몰"은 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를
              수집합니다.
            </li>
            <li>
              ② "몰"은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상
              의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는
              경우에는 그러하지 아니합니다.
            </li>
            <li>
              ③ "몰"은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를
              받습니다.
            </li>
            <li>
              ④ "몰"은 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는
              제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를
              받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
            </li>
            <li>
              ⑤ "몰"이 제3항과 제4항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의
              신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공
              관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에
              관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이
              동의를 철회할 수 있습니다.
            </li>
            <li>
              ⑥ 이용자는 언제든지 "몰"이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수
              있으며 "몰"은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을
              요구한 경우에는 "몰"은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
            </li>
            <li>
              ⑦ "몰"은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며
              신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조
              등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
            </li>
            <li>
              ⑧ "몰" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한
              때에는 당해 개인정보를 지체 없이 파기합니다.
            </li>
            <li>
              ⑨ "몰"은 개인정보의 수집·이용·제공에 관한 동의란을 미리 선택한 것으로 설정해두지 않습니다. 또한
              개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고,
              필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등
              서비스 제공을 제한하거나 거절하지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service18">제18조("몰"의 의무)</h3>
          <ol>
            <li>
              ① "몰"은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에
              따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
            </li>
            <li>
              ② "몰"은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보
              포함)보호를 위한 보안 시스템을 갖추어야 합니다.
            </li>
            <li>
              ③ "몰"이 상품이나 용역에 대하여 「표시·광고의 공정화에 관한 법률」 제3조 소정의 부당한
              표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
            </li>
            <li>④ "몰"은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service19">제19조(회원의 ID 및 비밀번호에 대한 의무)</h3>
          <ol>
            <li>① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</li>
            <li>② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
            <li>
              ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로
              "몰"에 통보하고 "몰"의 안내가 있는 경우에는 그에 따라야 합니다.<br />
              회원이 이를 인지하고서도 통지하지 않거나, 통지한 경우에도 "몰"의 안내에 따르지 않아 발생한
              불이익에 대해 "몰"은 책임지지 않습니다.
            </li>
            <li>
              ④ 회원이 본인의 ID를 소홀히 관리하거나 제 3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는
              "몰"은 책임지지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service20">제20조(이용자의 의무)</h3>
          <p>이용자는 다음 행위를 하여서는 안 됩니다.</p>
          <ol>
            <li>1. 신청 또는 변경시 허위 내용의 등록</li>
            <li>2. 타인의 정보 도용</li>
            <li>3. "몰"에 게시된 정보의 변경</li>
            <li>4. "몰"이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
            <li>5. "몰" 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
            <li>6. "몰" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
            <li>
              7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는
              행위
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service21">제21조(연결"몰"과 피연결"몰" 간의 관계)</h3>
          <ol>
            <li>
              ① 상위 "몰"과 하위 "몰"이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이
              포함됨)방식 등으로 연결된 경우, 전자를 연결 "몰"(웹 사이트)이라고 하고 후자를 피연결
              "몰"(웹사이트)이라고 합니다.
            </li>
            <li>
              ② 연결"몰"은 피연결"몰"이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서
              보증 책임을 지지 않는다는 뜻을 연결"몰"의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한
              경우에는 그 거래에 대한 보증 책임을 지지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service22">제22조(저작권의 귀속 및 이용제한)</h3>
          <ol>
            <li>① "몰"이 작성한 저작물에 대한 저작권 기타 지적재산권은 "몰"에 귀속합니다.</li>
            <li>
              ② 이용자는 "몰"을 이용함으로써 얻은 정보 중 "몰"에게 지적재산권이 귀속된 정보를 "몰"의 사전 승낙
              없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게
              하여서는 안됩니다.
            </li>
            <li>
              ③ "몰"은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
            </li>
            <li>
              ④ "몰"은 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램저작권 등을 침해하였음을
              이유로 "몰"이 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 "몰"의 면책을 위하여
              노력하여야 하며, "몰"이 면책되지 못한 경우 회원은 그로 인해 "몰"에 발생한 모든 손해를 부담하여야
              합니다.
            </li>
            <li>
              ⑤ "몰"은 회원이 서비스 내에 게시한 게시물이 다음 각 호의 경우에 해당한다고 판단되는 경우
              사전통지 없이 삭제, 변경할 수 있으며, 이에 대해 "몰"은 어떠한 책임도 지지 않습니다.
              <ol>
                <li>1. 스팸(spam)성 게시물 및 상업성 게시물</li>
                <li>2. 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 게시물</li>
                <li>3. 동의 없는 타인의 신상공개, 제3자의 저작권 등 권리를 침해하는 내용을 담은 게시물</li>
                <li>4. 기타 관계 법령 및 "몰"의 지침 등에 위반된다고 판단되는 경우</li>
              </ol>
            </li>
            <li>
              ⑥ "회원"의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우
              관리자는 관련볍이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, "몰"은
              관련법에 따라 조치를 취하여야 합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service23">제23조(분쟁해결)</h3>
          <ol>
            <li>
              ① "몰"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여
              피해보상처리기구를 설치․운영합니다.
            </li>
            <li>
              ② "몰"은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한
              처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
            </li>
            <li>
              ③ "몰"과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는
              공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service24">제 24조(적립금 제도 운영)</h3>
          <ol>
            <li>
              ① "몰"은 회원이 상품을 구매하거나, 상품평의 작성 등의 경우 또는 회원이 제휴사 포인트를 전환하는
              경우 회원에게 일정한 포인트를 부여할 수 있습니다. 다만, 상품구매 후 취소 또는 반품을 할 경우에는
              상품 구매시 회사가 부여한 포인트를 회수합니다. 그 구체적인 운영방법은 "몰"의 운영정책에
              의합니다.
            </li>
            <li>
              ② 포인트는 상품 구매시 사용가능 기준 하에 현금가액과 동일하게 사용할 수 있으나 (단, 사용불가
              사전고지품목 제외), 현금으로 환불되지는 않습니다. 또한, 포인트 서비스는 회원에게만 제공되며
              타인에게 양도할 수 없습니다.
            </li>
            <li>
              ③ 부여된 포인트는 부여일로부터 2년간 유효합니다. 다만, "몰"이 별도로 유효기간을 정한 때에는 이에
              따릅니다. 유효기간이 경과한 포인트는 자동적으로 소멸하게 됩니다.
            </li>
            <li>
              ④ "몰"의 회원 중 2년간 거래가 없고, 적립된 포인트가 3,000 포인트 미만인 경우에는 휴면회원으로
              간주하여 "몰"은 해당 회원의 적립된 포인트를 회수하게 됩니다.
            </li>
            <li>⑤ 회원을 탈퇴한 경우 포인트는 자동 소멸됩니다.</li>
            <li>
              ⑥ 부정한 방법으로 포인트를 획득한 사실이 확인될 경우 "몰"은 회원의 포인트 회수, ID(고유번호)
              삭제 및 형사 고발 등 기타 조치를 취할 수 있습니다.
            </li>
            <li>
              ⑦ "몰"은 경영상, 기술상 이유로 사전고지 후 적립금 서비스를 종료할 수 있으며, "몰"은 사전고지를
              최소한 1개월 전에 하여야 합니다. 이 경우 사전에 고지한 서비스 종료일까지 사용되지 않은 기 적립된
              적립금은 소멸됩니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service25">제 25조(상품쿠폰)</h3>
          <ol>
            <li>
              ① "몰"은 구매서비스를 이용하는 회원에게 지정된 상품 구매시 일정액 또는 일정비율을 할인 받을 수
              있는 상품쿠폰을 발급할 수 있습니다.
            </li>
            <li>
              ② 회원은 상품쿠폰을 회원 본인의 구매에 한해서만 사용할 수 있으며, 어떠한 경우에도 이를 타인에게
              실질적으로 매매 또는 양도할 수 없습니다.
            </li>
            <li>
              ③ 상품쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며, 유효기간이 지난 후에는 사용할 수
              없습니다.
            </li>
            <li>④ 회원을 탈퇴한 경우 상품쿠폰은 소멸됩니다.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service26">제 26조(면책)</h3>
          <ol>
            <li>
              ① "몰"은 천재지변, 불가항력 기타 "몰"의 합리적인 통제범위를 벗어난 사유로 인하여 서비스를 제공할
              수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.
            </li>
            <li>
              ② "몰"은 이용자의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지
              않습니다.
            </li>
            <li>
              ③ "몰"은 이용자가 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해 얻은 자료를
              이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다.
            </li>
            <li>
              ④ 이용자가 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 또는 정확성에 대하여는 해당
              이용자가 책임을 부담하며, "몰"은 내용의 부적정성으로 인해 이용자 또는 제3자에게 발생한 손해에
              대하여는 아무런 책임을 부담하지 않습니다.
            </li>
            <li>
              ⑤ "몰"은 서비스 이용과 관련하여 이용자의 고의 또는 과실로 인하여 이용자 또는 제3자에게 발생한
              손해에 대하여는 아무런 책임을 부담하지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service27">제 27조(정보의 제공 및 광고의 게재)</h3>
          <ol>
            <li>
              ① "몰"은 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 전자우편 및 서신우편 등의
              방법으로 회원에게 제공할 수 있습니다. 단, 수신거절의 의사를 명백히 표시한 회원에 대해서는 더
              이상 전자우편 또는 서신우편 등을 발송하지 않습니다.
            </li>
            <li>
              ② "몰"은 서비스의 운영과 관련하여 홈페이지, 서비스 화면, SMS, 전자우편 등에 광고 등을 게재할 수
              있습니다.
            </li>
            <li>
              ③ 회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는
              등의 방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주 간의 문제입니다. 만약 회원과
              광고주간에 문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와 관련하여 "몰"은
              어떠한 책임도 지지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service28">제28조(재판권 및 준거법)</h3>
          <ol>
            <li>
              ① "몰"과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고,
              주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소
              또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
            </li>
            <li>② "몰"과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.</li>
            <li></li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>&lt;부칙&gt;</h3>
          <p>
            본 약관은 2020년 11월 6일부터 시행됩니다.<br />
            2020년 1월 14일부터 시행해 온 약관은 본 약관으로 대체됩니다.
          </p>
          <ul class="prev_link">
            ${
              G.collabo_type == 'creator'
                ? `<li><a href="https://marpple.com/kr/terms_20200114">- 이전 이용약관 보기</a></li>`
                : `<li><a href="/terms_20200114">- 이전 이용약관 보기</a></li>`
            }
          </ul>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;

export const krTerms20200114 = () => legacyHtml`
  <div class="terms_body">
    <div class="title">
      <h1>이용약관</h1>
    </div>
    <div class="body">
      <div class="agree_header">
        <p>회원가입 전 반드시 이용약관을 읽어보시기 바랍니다.</p>
        <div class="table_of_contents">
          <ul>
            <li><a href="#service1">제1조(목적)</a></li>
            <li><a href="#service2">제2조(정의)</a></li>
            <li><a href="#service3">제3조(약관 등의 명시와 설명 및 개정)</a></li>
            <li><a href="#service4">제4조(서비스의 제공 및 변경)</a></li>
            <li><a href="#service5">제5조(서비스의 중단)</a></li>
            <li><a href="#service6">제6조(회원가입)</a></li>
            <li><a href="#service7">제7조(회원 탈퇴 및 자격 상실 등)</a></li>
          </ul>
          <ul>
            <li><a href="#service8">제8조(회원에 대한 통지)</a></li>
            <li><a href="#service9">제9조(구매신청)</a></li>
            <li><a href="#service10">제10조(계약의 성립)</a></li>
            <li><a href="#service11">제11조(지급방법)</a></li>
            <li><a href="#service12">제12조(수신확인통지, 구매신청 변경 및 취소)</a></li>
            <li><a href="#service13">제13조(재화 등의 공급)</a></li>
            <li><a href="#service14">제14조(환급)</a></li>
          </ul>
          <ul>
            <li><a href="#service15">제15조(청약철회 등)</a></li>
            <li><a href="#service16">제16조(청약철회 등의 효과)</a></li>
            <li><a href="#service17">제17조(개인정보보호)</a></li>
            <li><a href="#service18">제18조(“몰“의 의무)</a></li>
            <li><a href="#service19">제19조(회원의 ID 및 비밀번호에 대한 의무)</a></li>
            <li><a href="#service20">제20조(이용자의 의무)</a></li>
            <li><a href="#service21">제21조(연결“몰”과 피연결“몰” 간의 관계)</a></li>
          </ul>
          <ul>
            <li><a href="#service22">제22조(저작권의 귀속 및 이용제한)</a></li>
            <li><a href="#service23">제23조(분쟁해결)</a></li>
            <li><a href="#service24">제24조(적립금 제도 운영)</a></li>
            <li><a href="#service25">제25조(상품쿠폰)</a></li>
            <li><a href="#service26">제26조(면책)</a></li>
            <li><a href="#service27">제27조(정보의 제공 및 광고의 게재)</a></li>
            <li><a href="#service28">제28조(재판권 및 준거법)</a></li>
          </ul>
        </div>
      </div>
      <div class="text_body">
        <div class="paragraph">
          <h3 id="service1">제1조(목적)</h3>
          <p>
            이 약관은 (주)마플코퍼레이션(전자상거래 사업자)이 운영하는 [마플, 마플샵 (이하 "몰"이라 한다)]에서
            제공하는 개발 및 주문인쇄제작(PRINT ON DEMAND)을 통한 전자상거래 관련 서비스(이하 "서비스"라
            한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.
          </p>
          <p>
            ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을
            준용합니다.」
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service2">제2조(정의)</h3>
          <ol>
            <li>
              ① "몰"이란 (주)마플코퍼레이션이 재화 또는 용역(이하 "재화 등"이라 함)을 이용자에게 제공하기
              위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을
              말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
            </li>
            <li>
              ② "이용자"란 "몰"에 접속하여 이 약관에 따라 "몰"이 제공하는 서비스를 받는 회원 및 비회원을
              말합니다.
            </li>
            <li>
              ③ ‘회원’이라 함은 "몰"에 회원등록을 한 자로서, 계속적으로 "몰"이 제공하는 서비스를 이용할 수
              있는 자를 말합니다.
            </li>
            <li>④ ‘비회원’이라 함은 회원에 가입하지 않고 "몰"이 제공하는 서비스를 이용하는 자를 말합니다.</li>
            <li>
              ⑤ 이외에 이 약관에서 사용하는 용어의 정의는 관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service3">제3조 (약관 등의 명시와 설명 및 개정)</h3>
          <ol>
            <li>
              ① "몰"은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는
              곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호,
              개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 홈페이지의 초기 서비스화면(전면)에
              게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
            </li>
            <li>
              ② "몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임,
              환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을
              제공하여 이용자의 확인을 구하여야 합니다.
            </li>
            <li>
              ③ "몰"은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서
              및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에
              관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서
              이 약관을 개정할 수 있습니다.
            </li>
            <li>
              ④ "몰"이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에
              그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을
              변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "몰"은 개정 전
              내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
            </li>
            <li>
              ⑤ "몰"이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그
              이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을
              체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에
              "몰"에 송신하여 "몰"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
            </li>
            <li>
              ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에
              관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 「전자상거래 등에서의 소비자
              보호지침」 및 관계법령 또는 상관례에 따릅니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service4">제4조(서비스의 제공 및 변경)</h3>
          <ol>
            <li>
              ① "몰"은 다음과 같은 업무를 수행합니다.
              <ol>
                <li>1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결</li>
                <li>2. 구매계약이 체결된 재화 또는 용역의 제작 및 배송</li>
                <li>3. 마플샵의 경우 판매자의 샵 운영과 관련된 정보의 제공</li>
                <li>4. 기타 "몰"이 정하는 업무</li>
              </ol>
            </li>
            <li>
              ② "몰"은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해
              제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및
              제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
            </li>
            <li>
              ③ "몰"이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의
              변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
            </li>
            <li>
              ④ 전항의 경우 "몰"은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, "몰"이 고의 또는 과실이
              없음을 입증하는 경우에는 그러하지 아니합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service5">제5조(서비스의 중단)</h3>
          <ol>
            <li>
              ① "몰"은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는
              서비스의 제공을 일시적으로 중단할 수 있습니다.
            </li>
            <li>
              ② "몰"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은
              손해에 대하여 배상합니다. 단, "몰"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
              아니합니다.
            </li>
            <li>
              ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는
              "몰"은 제8조에 정한 방법으로 이용자에게 통지하고 당초 "몰"에서 제시한 조건에 따라 소비자에게
              보상합니다. 다만, "몰"이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 포인트 또는 적립금
              등을 "몰"에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service6">제6조(회원가입)</h3>
          <ol>
            <li>
              ① 이용자는 "몰"이 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관과 "개인정보 취급방침"에
              동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
            </li>
            <li>
              ② 마플와 마플샵은 회원정보를 상호 공유하며 둘 중 한곳에 가입을 신청한 이용자는 회원으로 등록될
              경우 두 웹사이트 모두 하나의 회원정보로 이용이 가능합니다.
            </li>
            <li>
              ③ "몰"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한
              회원으로 등록합니다.
              <ol>
                <li>
                  1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만
                  제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "몰"의 회원재가입 승낙을 얻은
                  경우에는 예외로 한다.
                </li>
                <li>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                <li>3. 회원 가입일 현재 만 14세 미만인 경우</li>
                <li>4. 기타 회원으로 등록하는 것이 "몰"의 기술상 현저히 지장이 있다고 판단되는 경우</li>
                <li>5. 회원가입계약의 성립 시기는 "몰"의 승낙이 회원에게 도달한 시점으로 합니다.</li>
                <li>
                  6. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 "몰"에 대하여
                  회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service7">제7조(회원 탈퇴 및 자격 상실 등)</h3>
          <ol>
            <li>① 회원은 "몰"에 언제든지 탈퇴를 요청할 수 있으며 "몰"은 즉시 회원탈퇴를 처리합니다.</li>
            <li>
              ② 회원이 다음 각 호의 사유에 해당하는 경우, "몰"은 회원자격을 제한 및 정지시킬 수 있습니다.
              <ol>
                <li>1. 가입 신청 시에 허위 내용을 등록한 경우</li>
                <li>
                  2. "몰"을 이용하여 구입한 재화 등의 대금, 기타 "몰"이용에 관련하여 회원이 부담하는 채무를
                  기일에 지급하지 않는 경우
                </li>
                <li>
                  3. 다른 사람의 "몰" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
                </li>
                <li>4. "몰"을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                <li>5. 기타 서비스 운영을 고의로 방해하는 행위를 하는 경우</li>
              </ol>
            </li>
            <li>
              ③ "몰"이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가
              시정되지 아니하는 경우 "몰"은 회원자격을 상실시킬 수 있습니다.
            </li>
            <li>
              ④ "몰"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고,
              회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service8">제8조(회원에 대한 통지)</h3>
          <ol>
            <li>
              ① "몰"이 회원에 대한 통지를 하는 경우, 회원이 "몰"과 미리 약정하여 지정한 전자우편 주소 또는 SMS
              등으로 할 수 있습니다.
            </li>
            <li>
              ② "몰"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "몰" 게시판에 게시함으로서 개별 통지에
              갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는
              개별통지를 합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service9">제9조(구매신청)</h3>
          <ol>
            <li>
              ① "몰"이용자는 "몰"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "몰"은 이용자가
              구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
              <ol>
                <li>1. 재화 등의 검색 및 선택</li>
                <li>2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</li>
                <li>
                  3. 약관내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한
                  확인
                </li>
                <li>4. 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)</li>
                <li>5. 재화 등의 구매신청 및 이에 관한 확인 또는 "몰"의 확인에 대한 동의</li>
                <li>6. 결제방법의 선택</li>
              </ol>
            </li>
            <li>
              ② "몰"이 제3자에게 구매자 개인정보를 제공·위탁할 필요가 있는 경우 실제 구매신청 시 구매자의
              동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이 때 "몰"은 제공되는
              개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용 목적 및 보유‧이용 기간 등을 구매자에게
              명시하여야 합니다. 다만 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 제25조 제1항에 의한
              개인정보 취급위탁의 경우 등 관련 법령에 달리 정함이 있는 경우에는 그에 따릅니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service10">제10조 (계약의 성립)</h3>
          <ol>
            <li>
              ① "몰"은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만,
              미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는
              법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
              <ol>
                <li>1. 신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                <li>2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우</li>
                <li>3. 기타 구매신청에 승낙하는 것이 "몰" 기술상 현저히 지장이 있다고 판단하는 경우</li>
              </ol>
            </li>
            <li>
              ② "몰"의 승낙이 제12조제1항의 수신확인 통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로
              봅니다.
            </li>
            <li>
              ③ "몰"의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정
              취소 등에 관한 정보 등을 포함하여야 합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service11">제11조(지급방법)</h3>
          <p>
            "몰"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수
            있습니다. 단, "몰"은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여
            징수할 수 없습니다.
          </p>
          <ol>
            <li>1. 인터넷뱅킹 등의 각종 계좌이체</li>
            <li>2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
            <li>3. 온라인무통장입금</li>
            <li>4. 마일리지 등 "몰"이 지급한 포인트에 의한 결제</li>
            <li>5. "몰"과 계약을 맺었거나 "몰"이 인정한 상품권에 의한 결제</li>
            <li>6. 기타 전자적 지급 방법에 의한 대금 지급 등</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service12">제12조(수신확인통지, 구매신청 변경 및 취소)</h3>
          <ol>
            <li>① "몰"은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.</li>
            <li>
              ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시
              구매신청 변경 및 취소를 요청할 수 있고 "몰"은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이
              그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한
              규정에 따릅니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service13">제13조(재화 등의 공급)</h3>
          <ol>
            <li>
              ① "몰"은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터
              10영업일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다.
              다만, "몰"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은
              날부터 3영업일 이내에 조치를 취합니다. 이때 "몰"은 이용자가 재화 등의 공급 절차 및 진행 사항을
              확인할 수 있도록 적절한 조치를 합니다.
            </li>
            <li>
              ② "몰"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을
              명시합니다. 만약 "몰"이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야
              합니다. 다만 "몰"이 고의, 과실이 없음을 입증한 경우에는 그러하지 아니합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service14">제14조(환급)</h3>
          <p>
            "몰"은 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체
            없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터
            3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service15">제15조(청약철회 등)</h3>
          <ol>
            <li>
              ① "몰"과 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한
              법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이
              늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일
              이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의
              소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
            </li>
            <li>
              ② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수
              없습니다.
              <ol>
                <li>
                  1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을
                  확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
                </li>
                <li>2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우</li>
                <li>3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우</li>
                <li>
                  4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
                </li>
                <li>
                  5. 청약철회를 인정하는 경우 "몰"에게 회복할 수 없는 중대한 피해가 예상되어 사전에 청약철회
                  제한에 대하여 고지하고 이용자의 동의를 받은 경우 (예.이용자의 주문에 따라 개별적으로
                  생산되는 재화)
                </li>
              </ol>
            </li>
            <li>
              ③ 제2항 제2호 내지 제4호의 경우에 "몰"이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알
              수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이
              제한되지 않습니다.
            </li>
            <li>
              ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나
              계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알
              수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service16">제16조(청약철회 등의 효과)</h3>
          <ol>
            <li>
              ① "몰"은 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을
              환급합니다. 이 경우 "몰"이 이용자에게 재화등의 환급을 지연한 때에는 그 지연기간에 대하여
              「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여
              산정한 지연이자를 지급합니다.
            </li>
            <li>
              ② "몰"은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의
              대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를
              정지 또는 취소하도록 요청합니다.
            </li>
            <li>
              ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. "몰"은
              이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이
              표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에
              필요한 비용은 "몰"이 부담합니다.
            </li>
            <li>
              ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 "몰"은 청약철회 시 그 비용을 누가
              부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service17">제17조(개인정보보호)</h3>
          <ol>
            <li>
              ① "몰"은 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를
              수집합니다.
            </li>
            <li>
              ② "몰"은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상
              의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는
              경우에는 그러하지 아니합니다.
            </li>
            <li>
              ③ "몰"은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를
              받습니다.
            </li>
            <li>
              ④ "몰"은 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는
              제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를
              받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
            </li>
            <li>
              ⑤ "몰"이 제3항과 제4항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의
              신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공
              관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에
              관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이
              동의를 철회할 수 있습니다.
            </li>
            <li>
              ⑥ 이용자는 언제든지 "몰"이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수
              있으며 "몰"은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을
              요구한 경우에는 "몰"은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
            </li>
            <li>
              ⑦ "몰"은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며
              신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조
              등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
            </li>
            <li>
              ⑧ "몰" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한
              때에는 당해 개인정보를 지체 없이 파기합니다.
            </li>
            <li>
              ⑨ "몰"은 개인정보의 수집·이용·제공에 관한 동의란을 미리 선택한 것으로 설정해두지 않습니다. 또한
              개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고,
              필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등
              서비스 제공을 제한하거나 거절하지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service18">제18조("몰"의 의무)</h3>
          <ol>
            <li>
              ① "몰"은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에
              따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
            </li>
            <li>
              ② "몰"은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보
              포함)보호를 위한 보안 시스템을 갖추어야 합니다.
            </li>
            <li>
              ③ "몰"이 상품이나 용역에 대하여 「표시·광고의 공정화에 관한 법률」 제3조 소정의 부당한
              표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
            </li>
            <li>④ "몰"은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service19">제19조(회원의 ID 및 비밀번호에 대한 의무)</h3>
          <ol>
            <li>① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</li>
            <li>② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
            <li>
              ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로
              "몰"에 통보하고 "몰"의 안내가 있는 경우에는 그에 따라야 합니다.<br />
              회원이 이를 인지하고서도 통지하지 않거나, 통지한 경우에도 "몰"의 안내에 따르지 않아 발생한
              불이익에 대해 "몰"은 책임지지 않습니다.
            </li>
            <li>
              ④ 회원이 본인의 ID를 소홀히 관리하거나 제 3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는
              "몰"은 책임지지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service20">제20조(이용자의 의무)</h3>
          <p>이용자는 다음 행위를 하여서는 안 됩니다.</p>
          <ol>
            <li>1. 신청 또는 변경시 허위 내용의 등록</li>
            <li>2. 타인의 정보 도용</li>
            <li>3. "몰"에 게시된 정보의 변경</li>
            <li>4. "몰"이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
            <li>5. "몰" 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
            <li>6. "몰" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
            <li>
              7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는
              행위
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service21">제21조(연결"몰"과 피연결"몰" 간의 관계)</h3>
          <ol>
            <li>
              ① 상위 "몰"과 하위 "몰"이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이
              포함됨)방식 등으로 연결된 경우, 전자를 연결 "몰"(웹 사이트)이라고 하고 후자를 피연결
              "몰"(웹사이트)이라고 합니다.
            </li>
            <li>
              ② 연결"몰"은 피연결"몰"이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서
              보증 책임을 지지 않는다는 뜻을 연결"몰"의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한
              경우에는 그 거래에 대한 보증 책임을 지지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service22">제22조(저작권의 귀속 및 이용제한)</h3>
          <ol>
            <li>① "몰"이 작성한 저작물에 대한 저작권 기타 지적재산권은 "몰"에 귀속합니다.</li>
            <li>
              ② 이용자는 "몰"을 이용함으로써 얻은 정보 중 "몰"에게 지적재산권이 귀속된 정보를 "몰"의 사전 승낙
              없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게
              하여서는 안됩니다.
            </li>
            <li>
              ③ "몰"은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
            </li>
            <li>
              ④ "몰"은 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램저작권 등을 침해하였음을
              이유로 "몰"이 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 "몰"의 면책을 위하여
              노력하여야 하며, "몰"이 면책되지 못한 경우 회원은 그로 인해 "몰"에 발생한 모든 손해를 부담하여야
              합니다.
            </li>
            <li>
              ⑤ "몰"은 회원이 서비스 내에 게시한 게시물이 다음 각 호의 경우에 해당한다고 판단되는 경우
              사전통지 없이 삭제, 변경할 수 있으며, 이에 대해 "몰"은 어떠한 책임도 지지 않습니다.
              <ol>
                <li>1. 스팸(spam)성 게시물 및 상업성 게시물</li>
                <li>2. 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 게시물</li>
                <li>3. 동의 없는 타인의 신상공개, 제3자의 저작권 등 권리를 침해하는 내용을 담은 게시물</li>
                <li>4. 기타 관계 법령 및 "몰"의 지침 등에 위반된다고 판단되는 경우</li>
              </ol>
            </li>
            <li>
              ⑥ "회원"의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우
              관리자는 관련볍이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, "몰"은
              관련법에 따라 조치를 취하여야 합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service23">제23조(분쟁해결)</h3>
          <ol>
            <li>
              ① "몰"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여
              피해보상처리기구를 설치․운영합니다.
            </li>
            <li>
              ② "몰"은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한
              처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
            </li>
            <li>
              ③ "몰"과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는
              공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service24">제 24조(적립금 제도 운영)</h3>
          <ol>
            <li>
              ① "몰"은 회원이 상품을 구매하거나, 상품평의 작성 등의 경우 또는 회원이 제휴사 포인트를 전환하는
              경우 회원에게 일정한 포인트를 부여할 수 있습니다. 다만, 상품구매 후 취소 또는 반품을 할 경우에는
              상품 구매시 회사가 부여한 포인트를 회수합니다. 그 구체적인 운영방법은 "몰"의 운영정책에
              의합니다.
            </li>
            <li>
              ② 포인트는 상품 구매시 사용가능 기준 하에 현금가액과 동일하게 사용할 수 있으나 (단, 사용불가
              사전고지품목 제외), 현금으로 환불되지는 않습니다. 또한, 포인트 서비스는 회원에게만 제공되며
              타인에게 양도할 수 없습니다.
            </li>
            <li>
              ③ 부여된 포인트는 부여일로부터 2년간 유효합니다. 다만, "몰"이 별도로 유효기간을 정한 때에는 이에
              따릅니다. 유효기간이 경과한 포인트는 자동적으로 소멸하게 됩니다.
            </li>
            <li>
              ④ "몰"의 회원 중 2년간 거래가 없고, 적립된 포인트가 3,000 포인트 미만인 경우에는 휴면회원으로
              간주하여 "몰"은 해당 회원의 적립된 포인트를 회수하게 됩니다.
            </li>
            <li>⑤ 회원을 탈퇴한 경우 포인트는 자동 소멸됩니다.</li>
            <li>
              ⑥ 부정한 방법으로 포인트를 획득한 사실이 확인될 경우 "몰"은 회원의 포인트 회수, ID(고유번호)
              삭제 및 형사 고발 등 기타 조치를 취할 수 있습니다.
            </li>
            <li>
              ⑦ "몰"은 경영상, 기술상 이유로 사전고지 후 적립금 서비스를 종료할 수 있으며, "몰"은 사전고지를
              최소한 1개월 전에 하여야 합니다. 이 경우 사전에 고지한 서비스 종료일까지 사용되지 않은 기 적립된
              적립금은 소멸됩니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service25">제 25조(상품쿠폰)</h3>
          <ol>
            <li>
              ① "몰"은 구매서비스를 이용하는 회원에게 지정된 상품 구매시 일정액 또는 일정비율을 할인 받을 수
              있는 상품쿠폰을 발급할 수 있습니다.
            </li>
            <li>
              ② 회원은 상품쿠폰을 회원 본인의 구매에 한해서만 사용할 수 있으며, 어떠한 경우에도 이를 타인에게
              실질적으로 매매 또는 양도할 수 없습니다.
            </li>
            <li>
              ③ 상품쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며, 유효기간이 지난 후에는 사용할 수
              없습니다.
            </li>
            <li>④ 회원을 탈퇴한 경우 상품쿠폰은 소멸됩니다.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service26">제 26조(면책)</h3>
          <ol>
            <li>
              ① "몰"은 천재지변, 불가항력 기타 "몰"의 합리적인 통제범위를 벗어난 사유로 인하여 서비스를 제공할
              수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.
            </li>
            <li>
              ② "몰"은 이용자의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지
              않습니다.
            </li>
            <li>
              ③ "몰"은 이용자가 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해 얻은 자료를
              이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다.
            </li>
            <li>
              ④ 이용자가 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 또는 정확성에 대하여는 해당
              이용자가 책임을 부담하며, "몰"은 내용의 부적정성으로 인해 이용자 또는 제3자에게 발생한 손해에
              대하여는 아무런 책임을 부담하지 않습니다.
            </li>
            <li>
              ⑤ "몰"은 서비스 이용과 관련하여 이용자의 고의 또는 과실로 인하여 이용자 또는 제3자에게 발생한
              손해에 대하여는 아무런 책임을 부담하지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service27">제 27조(정보의 제공 및 광고의 게재)</h3>
          <ol>
            <li>
              ① "몰"은 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 전자우편 및 서신우편 등의
              방법으로 회원에게 제공할 수 있습니다. 단, 수신거절의 의사를 명백히 표시한 회원에 대해서는 더
              이상 전자우편 또는 서신우편 등을 발송하지 않습니다.
            </li>
            <li>
              ② "몰"은 서비스의 운영과 관련하여 홈페이지, 서비스 화면, SMS, 전자우편 등에 광고 등을 게재할 수
              있습니다.
            </li>
            <li>
              ③ 회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는
              등의 방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주 간의 문제입니다. 만약 회원과
              광고주간에 문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와 관련하여 "몰"은
              어떠한 책임도 지지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service28">제28조(재판권 및 준거법)</h3>
          <ol>
            <li>
              ① "몰"과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고,
              주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소
              또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
            </li>
            <li>② "몰"과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.</li>
            <li></li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>&lt;부칙&gt;</h3>
          <p>
            본 약관은 2020년 1월 14일부터 시행됩니다.<br />
            2017년 6월 12일부터 시행해 온 약관은 본 약관으로 대체됩니다.
          </p>
          <ul class="prev_link">
            ${go(void 0, () => {
              return G.collabo_type == 'creator'
                ? `
                      <li><a href="https://marpple.com/kr/terms_20170612">- 이전 이용약관 보기</a></li>
                    `
                : `
                      <li><a href="/terms_20170612">- 이전 이용약관 보기</a></li>
                    `;
            })}
          </ul>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;

// 변경 예정 공지 관련 약관 페이지
export const krTerms20201030 = () => legacyHtml`
  <div class="terms_body">
    <div class="title">
      <h1>[마플, 마플샵] 이용약관 변경 안내</h1>
    </div>
    <div class="body">
      <p>
        안녕하세요. 마플코퍼레이션입니다. 마플, 마플샵 (이하 "몰")의 이용약관의 일부 내용이 변경되어 2020년
        11월 6일자로 적용될 예정입니다. 이에 이용약관 개정에 관한 내용을 사전에 알려드리오니 회원 여러분께서는
        새로운 약관을 확인하시고 참고하여 주시기를 바랍니다
      </p>
      <p><b>변경고지일 : 2020년 10월 30일</b></p>
      <p>변경 내용</p>
      <table class="terms_preview">
        <tr>
          <th>변경 전</th>
          <th>변경 후</th>
        </tr>
        <tr>
          <td>
            <h3>제13조(재화 등의 공급)</h3>
            <p>
              ① "몰"은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터
              10영업일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다.
            </p>
            <p>
              다만, "몰"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은
              날부터 3영업일 이내에 조치를 취합니다. 이때 "몰"은 이용자가 재화 등의 공급 절차 및 진행 사항을
              확인할 수 있도록 적절한 조치를 합니다.
            </p>
            <p>
              ② "몰"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을
              명시합니다.
            </p>
            <p>
              만약 "몰"이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만
              "몰"이 고의, 과실이 없음을 입증한 경우에는 그러하지 아니합니다.
            </p>
          </td>
          <td>
            <h3>제13조(재화 등의 공급)</h3>
            <p>
              ① "몰"은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, '몰'이 재화 공급 가능한
              빠른 시일 내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다.
            </p>
            <p>
              다만, "몰"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 지체없이 조치를 취합니다. 이때
              "몰"은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
            </p>
            <p>
              ② "몰"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을
              명시합니다.
            </p>
            <p>
              <b
                >이때 재화의 생산 중단 및 "몰"의 기타 사정에 의한 재화의 공급이 어려운 경우 재화는 고지 없이
                제공이 중단될 수 있으며 이에 대하여 회사는 책임을 부담하지 않습니다.</b
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <h3>제14조(환급)</h3>
            <p>
              "몰"은 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체
              없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터
              3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
            </p>
          </td>
          <td>
            <h3>제14조(환급)</h3>
            <p>
              "몰"은 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체
              없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는&nbsp;
              <b>이용자에게 대금을 환급하거나 환급에 필요한 조치를 취합니다.</b>
            </p>
          </td>
        </tr>
      </table>

      <p>변경된 서비스 이용약관은&nbsp;<b>2020년 11월 6일부터 적용됩니다.</b></p>
      <p>
        변경된 이용약관에 동의하시지 않는 경우, 마플 홈페이지에서 탈퇴 혹은 마플샵 고객센터를 통해 탈퇴를
        요청하시거나 이의 제기를 하실 수 있습니다.
      </p>
      <p>
        변경된 약관의&nbsp;
        <b
          >사전 변경 고지 개시 후 7일 이내에 별도의 이의 제기를 하지 않는 경우에는 본 약관 변경에 동의한
          것으로 간주</b
        >하여 변경된 이용약관이 적용됩니다.
      </p>
    </div>
    <hr style="margin-top: 50px;" />
  </div>
  <div class="terms_body">
    <div class="title">
      <h1>이용약관</h1>
    </div>
    <div class="body">
      <div class="agree_header">
        <p>회원가입 전 반드시 이용약관을 읽어보시기 바랍니다.</p>
        <div class="table_of_contents">
          <ul>
            <li><a href="#service1">제1조(목적)</a></li>
            <li><a href="#service2">제2조(정의)</a></li>
            <li><a href="#service3">제3조(약관 등의 명시와 설명 및 개정)</a></li>
            <li><a href="#service4">제4조(서비스의 제공 및 변경)</a></li>
            <li><a href="#service5">제5조(서비스의 중단)</a></li>
            <li><a href="#service6">제6조(회원가입)</a></li>
            <li><a href="#service7">제7조(회원 탈퇴 및 자격 상실 등)</a></li>
          </ul>
          <ul>
            <li><a href="#service8">제8조(회원에 대한 통지)</a></li>
            <li><a href="#service9">제9조(구매신청)</a></li>
            <li><a href="#service10">제10조(계약의 성립)</a></li>
            <li><a href="#service11">제11조(지급방법)</a></li>
            <li><a href="#service12">제12조(수신확인통지, 구매신청 변경 및 취소)</a></li>
            <li><a href="#service13">제13조(재화 등의 공급)</a></li>
            <li><a href="#service14">제14조(환급)</a></li>
          </ul>
          <ul>
            <li><a href="#service15">제15조(청약철회 등)</a></li>
            <li><a href="#service16">제16조(청약철회 등의 효과)</a></li>
            <li><a href="#service17">제17조(개인정보보호)</a></li>
            <li><a href="#service18">제18조(“몰“의 의무)</a></li>
            <li><a href="#service19">제19조(회원의 ID 및 비밀번호에 대한 의무)</a></li>
            <li><a href="#service20">제20조(이용자의 의무)</a></li>
            <li><a href="#service21">제21조(연결“몰”과 피연결“몰” 간의 관계)</a></li>
          </ul>
          <ul>
            <li><a href="#service22">제22조(저작권의 귀속 및 이용제한)</a></li>
            <li><a href="#service23">제23조(분쟁해결)</a></li>
            <li><a href="#service24">제24조(적립금 제도 운영)</a></li>
            <li><a href="#service25">제25조(상품쿠폰)</a></li>
            <li><a href="#service26">제26조(면책)</a></li>
            <li><a href="#service27">제27조(정보의 제공 및 광고의 게재)</a></li>
            <li><a href="#service28">제28조(재판권 및 준거법)</a></li>
          </ul>
        </div>
      </div>
      <div class="text_body">
        <div class="paragraph">
          <h3 id="service1">제1조(목적)</h3>
          <p>
            이 약관은 (주)마플코퍼레이션(전자상거래 사업자)이 운영하는 [마플, 마플샵 (이하 "몰"이라 한다)]에서
            제공하는 개발 및 주문인쇄제작(PRINT ON DEMAND)을 통한 전자상거래 관련 서비스(이하 "서비스"라
            한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.
          </p>
          <p>
            ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을
            준용합니다.」
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service2">제2조(정의)</h3>
          <ol>
            <li>
              ① "몰"이란 (주)마플코퍼레이션이 재화 또는 용역(이하 "재화 등"이라 함)을 이용자에게 제공하기
              위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을
              말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
            </li>
            <li>
              ② "이용자"란 "몰"에 접속하여 이 약관에 따라 "몰"이 제공하는 서비스를 받는 회원 및 비회원을
              말합니다.
            </li>
            <li>
              ③ ‘회원’이라 함은 "몰"에 회원등록을 한 자로서, 계속적으로 "몰"이 제공하는 서비스를 이용할 수
              있는 자를 말합니다.
            </li>
            <li>④ ‘비회원’이라 함은 회원에 가입하지 않고 "몰"이 제공하는 서비스를 이용하는 자를 말합니다.</li>
            <li>
              ⑤ 이외에 이 약관에서 사용하는 용어의 정의는 관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service3">제3조 (약관 등의 명시와 설명 및 개정)</h3>
          <ol>
            <li>
              ① "몰"은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는
              곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호,
              개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 홈페이지의 초기 서비스화면(전면)에
              게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
            </li>
            <li>
              ② "몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임,
              환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을
              제공하여 이용자의 확인을 구하여야 합니다.
            </li>
            <li>
              ③ "몰"은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서
              및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에
              관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서
              이 약관을 개정할 수 있습니다.
            </li>
            <li>
              ④ "몰"이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에
              그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을
              변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "몰"은 개정 전
              내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
            </li>
            <li>
              ⑤ "몰"이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그
              이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을
              체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에
              "몰"에 송신하여 "몰"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
            </li>
            <li>
              ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에
              관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 「전자상거래 등에서의 소비자
              보호지침」 및 관계법령 또는 상관례에 따릅니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service4">제4조(서비스의 제공 및 변경)</h3>
          <ol>
            <li>
              ① "몰"은 다음과 같은 업무를 수행합니다.
              <ol>
                <li>1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결</li>
                <li>2. 구매계약이 체결된 재화 또는 용역의 제작 및 배송</li>
                <li>3. 마플샵의 경우 판매자의 샵 운영과 관련된 정보의 제공</li>
                <li>4. 기타 "몰"이 정하는 업무</li>
              </ol>
            </li>
            <li>
              ② "몰"은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해
              제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및
              제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
            </li>
            <li>
              ③ "몰"이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의
              변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
            </li>
            <li>
              ④ 전항의 경우 "몰"은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, "몰"이 고의 또는 과실이
              없음을 입증하는 경우에는 그러하지 아니합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service5">제5조(서비스의 중단)</h3>
          <ol>
            <li>
              ① "몰"은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는
              서비스의 제공을 일시적으로 중단할 수 있습니다.
            </li>
            <li>
              ② "몰"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은
              손해에 대하여 배상합니다. 단, "몰"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
              아니합니다.
            </li>
            <li>
              ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는
              "몰"은 제8조에 정한 방법으로 이용자에게 통지하고 당초 "몰"에서 제시한 조건에 따라 소비자에게
              보상합니다. 다만, "몰"이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 포인트 또는 적립금
              등을 "몰"에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service6">제6조(회원가입)</h3>
          <ol>
            <li>
              ① 이용자는 "몰"이 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관과 "개인정보 취급방침"에
              동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
            </li>
            <li>
              ② 마플와 마플샵은 회원정보를 상호 공유하며 둘 중 한곳에 가입을 신청한 이용자는 회원으로 등록될
              경우 두 웹사이트 모두 하나의 회원정보로 이용이 가능합니다.
            </li>
            <li>
              ③ "몰"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한
              회원으로 등록합니다.
              <ol>
                <li>
                  1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만
                  제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "몰"의 회원재가입 승낙을 얻은
                  경우에는 예외로 한다.
                </li>
                <li>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                <li>3. 회원 가입일 현재 만 14세 미만인 경우</li>
                <li>4. 기타 회원으로 등록하는 것이 "몰"의 기술상 현저히 지장이 있다고 판단되는 경우</li>
                <li>5. 회원가입계약의 성립 시기는 "몰"의 승낙이 회원에게 도달한 시점으로 합니다.</li>
                <li>
                  6. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 "몰"에 대하여
                  회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service7">제7조(회원 탈퇴 및 자격 상실 등)</h3>
          <ol>
            <li>① 회원은 "몰"에 언제든지 탈퇴를 요청할 수 있으며 "몰"은 즉시 회원탈퇴를 처리합니다.</li>
            <li>
              ② 회원이 다음 각 호의 사유에 해당하는 경우, "몰"은 회원자격을 제한 및 정지시킬 수 있습니다.
              <ol>
                <li>1. 가입 신청 시에 허위 내용을 등록한 경우</li>
                <li>
                  2. "몰"을 이용하여 구입한 재화 등의 대금, 기타 "몰"이용에 관련하여 회원이 부담하는 채무를
                  기일에 지급하지 않는 경우
                </li>
                <li>
                  3. 다른 사람의 "몰" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
                </li>
                <li>4. "몰"을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                <li>5. 기타 서비스 운영을 고의로 방해하는 행위를 하는 경우</li>
              </ol>
            </li>
            <li>
              ③ "몰"이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가
              시정되지 아니하는 경우 "몰"은 회원자격을 상실시킬 수 있습니다.
            </li>
            <li>
              ④ "몰"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고,
              회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service8">제8조(회원에 대한 통지)</h3>
          <ol>
            <li>
              ① "몰"이 회원에 대한 통지를 하는 경우, 회원이 "몰"과 미리 약정하여 지정한 전자우편 주소 또는 SMS
              등으로 할 수 있습니다.
            </li>
            <li>
              ② "몰"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "몰" 게시판에 게시함으로서 개별 통지에
              갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는
              개별통지를 합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service9">제9조(구매신청)</h3>
          <ol>
            <li>
              ① "몰"이용자는 "몰"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "몰"은 이용자가
              구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
              <ol>
                <li>1. 재화 등의 검색 및 선택</li>
                <li>2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</li>
                <li>
                  3. 약관내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한
                  확인
                </li>
                <li>4. 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)</li>
                <li>5. 재화 등의 구매신청 및 이에 관한 확인 또는 "몰"의 확인에 대한 동의</li>
                <li>6. 결제방법의 선택</li>
              </ol>
            </li>
            <li>
              ② "몰"이 제3자에게 구매자 개인정보를 제공·위탁할 필요가 있는 경우 실제 구매신청 시 구매자의
              동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이 때 "몰"은 제공되는
              개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용 목적 및 보유‧이용 기간 등을 구매자에게
              명시하여야 합니다. 다만 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 제25조 제1항에 의한
              개인정보 취급위탁의 경우 등 관련 법령에 달리 정함이 있는 경우에는 그에 따릅니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service10">제10조 (계약의 성립)</h3>
          <ol>
            <li>
              ① "몰"은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만,
              미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는
              법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
              <ol>
                <li>1. 신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                <li>2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우</li>
                <li>3. 기타 구매신청에 승낙하는 것이 "몰" 기술상 현저히 지장이 있다고 판단하는 경우</li>
              </ol>
            </li>
            <li>
              ② "몰"의 승낙이 제12조제1항의 수신확인 통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로
              봅니다.
            </li>
            <li>
              ③ "몰"의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정
              취소 등에 관한 정보 등을 포함하여야 합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service11">제11조(지급방법)</h3>
          <p>
            "몰"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수
            있습니다. 단, "몰"은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여
            징수할 수 없습니다.
          </p>
          <ol>
            <li>1. 인터넷뱅킹 등의 각종 계좌이체</li>
            <li>2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
            <li>3. 온라인무통장입금</li>
            <li>4. 마일리지 등 "몰"이 지급한 포인트에 의한 결제</li>
            <li>5. "몰"과 계약을 맺었거나 "몰"이 인정한 상품권에 의한 결제</li>
            <li>6. 기타 전자적 지급 방법에 의한 대금 지급 등</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service12">제12조(수신확인통지, 구매신청 변경 및 취소)</h3>
          <ol>
            <li>① "몰"은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.</li>
            <li>
              ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시
              구매신청 변경 및 취소를 요청할 수 있고 "몰"은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이
              그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한
              규정에 따릅니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service13">제13조(재화 등의 공급)</h3>
          <ol>
            <li>
              ① "몰"은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, '몰'이 재화 공급 가능한
              빠른 시일 내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만,
              "몰"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 지체없이 조치를 취합니다. 이때
              "몰"은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
            </li>
            <li>
              ② "몰"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을
              명시합니다. 이때 재화의 생산 중단 및 "몰"의 기타 사정에 의한 재화의 공급이 어려운 경우 재화는
              고지 없이 제공이 중단될 수 있으며 이에 대하여 회사는 책임을 부담하지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service14">제14조(환급)</h3>
          <p>
            "몰"은 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체
            없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 이용자에게 대금을
            환급하거나 환급에 필요한 조치를 취합니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service15">제15조(청약철회 등)</h3>
          <ol>
            <li>
              ① "몰"과 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한
              법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이
              늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일
              이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의
              소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
            </li>
            <li>
              ② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수
              없습니다.
              <ol>
                <li>
                  1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을
                  확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
                </li>
                <li>2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우</li>
                <li>3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우</li>
                <li>
                  4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
                </li>
                <li>
                  5. 청약철회를 인정하는 경우 "몰"에게 회복할 수 없는 중대한 피해가 예상되어 사전에 청약철회
                  제한에 대하여 고지하고 이용자의 동의를 받은 경우 (예.이용자의 주문에 따라 개별적으로
                  생산되는 재화)
                </li>
              </ol>
            </li>
            <li>
              ③ 제2항 제2호 내지 제4호의 경우에 "몰"이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알
              수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이
              제한되지 않습니다.
            </li>
            <li>
              ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나
              계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알
              수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service16">제16조(청약철회 등의 효과)</h3>
          <ol>
            <li>
              ① "몰"은 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을
              환급합니다. 이 경우 "몰"이 이용자에게 재화등의 환급을 지연한 때에는 그 지연기간에 대하여
              「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여
              산정한 지연이자를 지급합니다.
            </li>
            <li>
              ② "몰"은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의
              대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를
              정지 또는 취소하도록 요청합니다.
            </li>
            <li>
              ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. "몰"은
              이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이
              표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에
              필요한 비용은 "몰"이 부담합니다.
            </li>
            <li>
              ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 "몰"은 청약철회 시 그 비용을 누가
              부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service17">제17조(개인정보보호)</h3>
          <ol>
            <li>
              ① "몰"은 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를
              수집합니다.
            </li>
            <li>
              ② "몰"은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상
              의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는
              경우에는 그러하지 아니합니다.
            </li>
            <li>
              ③ "몰"은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를
              받습니다.
            </li>
            <li>
              ④ "몰"은 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는
              제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를
              받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
            </li>
            <li>
              ⑤ "몰"이 제3항과 제4항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의
              신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공
              관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에
              관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이
              동의를 철회할 수 있습니다.
            </li>
            <li>
              ⑥ 이용자는 언제든지 "몰"이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수
              있으며 "몰"은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을
              요구한 경우에는 "몰"은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
            </li>
            <li>
              ⑦ "몰"은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며
              신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조
              등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
            </li>
            <li>
              ⑧ "몰" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한
              때에는 당해 개인정보를 지체 없이 파기합니다.
            </li>
            <li>
              ⑨ "몰"은 개인정보의 수집·이용·제공에 관한 동의란을 미리 선택한 것으로 설정해두지 않습니다. 또한
              개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고,
              필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등
              서비스 제공을 제한하거나 거절하지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service18">제18조("몰"의 의무)</h3>
          <ol>
            <li>
              ① "몰"은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에
              따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
            </li>
            <li>
              ② "몰"은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보
              포함)보호를 위한 보안 시스템을 갖추어야 합니다.
            </li>
            <li>
              ③ "몰"이 상품이나 용역에 대하여 「표시·광고의 공정화에 관한 법률」 제3조 소정의 부당한
              표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
            </li>
            <li>④ "몰"은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service19">제19조(회원의 ID 및 비밀번호에 대한 의무)</h3>
          <ol>
            <li>① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</li>
            <li>② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
            <li>
              ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로
              "몰"에 통보하고 "몰"의 안내가 있는 경우에는 그에 따라야 합니다.<br />
              회원이 이를 인지하고서도 통지하지 않거나, 통지한 경우에도 "몰"의 안내에 따르지 않아 발생한
              불이익에 대해 "몰"은 책임지지 않습니다.
            </li>
            <li>
              ④ 회원이 본인의 ID를 소홀히 관리하거나 제 3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는
              "몰"은 책임지지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service20">제20조(이용자의 의무)</h3>
          <p>이용자는 다음 행위를 하여서는 안 됩니다.</p>
          <ol>
            <li>1. 신청 또는 변경시 허위 내용의 등록</li>
            <li>2. 타인의 정보 도용</li>
            <li>3. "몰"에 게시된 정보의 변경</li>
            <li>4. "몰"이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
            <li>5. "몰" 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
            <li>6. "몰" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
            <li>
              7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는
              행위
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service21">제21조(연결"몰"과 피연결"몰" 간의 관계)</h3>
          <ol>
            <li>
              ① 상위 "몰"과 하위 "몰"이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이
              포함됨)방식 등으로 연결된 경우, 전자를 연결 "몰"(웹 사이트)이라고 하고 후자를 피연결
              "몰"(웹사이트)이라고 합니다.
            </li>
            <li>
              ② 연결"몰"은 피연결"몰"이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서
              보증 책임을 지지 않는다는 뜻을 연결"몰"의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한
              경우에는 그 거래에 대한 보증 책임을 지지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service22">제22조(저작권의 귀속 및 이용제한)</h3>
          <ol>
            <li>① "몰"이 작성한 저작물에 대한 저작권 기타 지적재산권은 "몰"에 귀속합니다.</li>
            <li>
              ② 이용자는 "몰"을 이용함으로써 얻은 정보 중 "몰"에게 지적재산권이 귀속된 정보를 "몰"의 사전 승낙
              없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게
              하여서는 안됩니다.
            </li>
            <li>
              ③ "몰"은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
            </li>
            <li>
              ④ "몰"은 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램저작권 등을 침해하였음을
              이유로 "몰"이 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 "몰"의 면책을 위하여
              노력하여야 하며, "몰"이 면책되지 못한 경우 회원은 그로 인해 "몰"에 발생한 모든 손해를 부담하여야
              합니다.
            </li>
            <li>
              ⑤ "몰"은 회원이 서비스 내에 게시한 게시물이 다음 각 호의 경우에 해당한다고 판단되는 경우
              사전통지 없이 삭제, 변경할 수 있으며, 이에 대해 "몰"은 어떠한 책임도 지지 않습니다.
              <ol>
                <li>1. 스팸(spam)성 게시물 및 상업성 게시물</li>
                <li>2. 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 게시물</li>
                <li>3. 동의 없는 타인의 신상공개, 제3자의 저작권 등 권리를 침해하는 내용을 담은 게시물</li>
                <li>4. 기타 관계 법령 및 "몰"의 지침 등에 위반된다고 판단되는 경우</li>
              </ol>
            </li>
            <li>
              ⑥ "회원"의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우
              관리자는 관련볍이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, "몰"은
              관련법에 따라 조치를 취하여야 합니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service23">제23조(분쟁해결)</h3>
          <ol>
            <li>
              ① "몰"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여
              피해보상처리기구를 설치․운영합니다.
            </li>
            <li>
              ② "몰"은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한
              처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
            </li>
            <li>
              ③ "몰"과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는
              공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service24">제 24조(적립금 제도 운영)</h3>
          <ol>
            <li>
              ① "몰"은 회원이 상품을 구매하거나, 상품평의 작성 등의 경우 또는 회원이 제휴사 포인트를 전환하는
              경우 회원에게 일정한 포인트를 부여할 수 있습니다. 다만, 상품구매 후 취소 또는 반품을 할 경우에는
              상품 구매시 회사가 부여한 포인트를 회수합니다. 그 구체적인 운영방법은 "몰"의 운영정책에
              의합니다.
            </li>
            <li>
              ② 포인트는 상품 구매시 사용가능 기준 하에 현금가액과 동일하게 사용할 수 있으나 (단, 사용불가
              사전고지품목 제외), 현금으로 환불되지는 않습니다. 또한, 포인트 서비스는 회원에게만 제공되며
              타인에게 양도할 수 없습니다.
            </li>
            <li>
              ③ 부여된 포인트는 부여일로부터 2년간 유효합니다. 다만, "몰"이 별도로 유효기간을 정한 때에는 이에
              따릅니다. 유효기간이 경과한 포인트는 자동적으로 소멸하게 됩니다.
            </li>
            <li>
              ④ "몰"의 회원 중 2년간 거래가 없고, 적립된 포인트가 3,000 포인트 미만인 경우에는 휴면회원으로
              간주하여 "몰"은 해당 회원의 적립된 포인트를 회수하게 됩니다.
            </li>
            <li>⑤ 회원을 탈퇴한 경우 포인트는 자동 소멸됩니다.</li>
            <li>
              ⑥ 부정한 방법으로 포인트를 획득한 사실이 확인될 경우 "몰"은 회원의 포인트 회수, ID(고유번호)
              삭제 및 형사 고발 등 기타 조치를 취할 수 있습니다.
            </li>
            <li>
              ⑦ "몰"은 경영상, 기술상 이유로 사전고지 후 적립금 서비스를 종료할 수 있으며, "몰"은 사전고지를
              최소한 1개월 전에 하여야 합니다. 이 경우 사전에 고지한 서비스 종료일까지 사용되지 않은 기 적립된
              적립금은 소멸됩니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service25">제 25조(상품쿠폰)</h3>
          <ol>
            <li>
              ① "몰"은 구매서비스를 이용하는 회원에게 지정된 상품 구매시 일정액 또는 일정비율을 할인 받을 수
              있는 상품쿠폰을 발급할 수 있습니다.
            </li>
            <li>
              ② 회원은 상품쿠폰을 회원 본인의 구매에 한해서만 사용할 수 있으며, 어떠한 경우에도 이를 타인에게
              실질적으로 매매 또는 양도할 수 없습니다.
            </li>
            <li>
              ③ 상품쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며, 유효기간이 지난 후에는 사용할 수
              없습니다.
            </li>
            <li>④ 회원을 탈퇴한 경우 상품쿠폰은 소멸됩니다.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service26">제 26조(면책)</h3>
          <ol>
            <li>
              ① "몰"은 천재지변, 불가항력 기타 "몰"의 합리적인 통제범위를 벗어난 사유로 인하여 서비스를 제공할
              수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.
            </li>
            <li>
              ② "몰"은 이용자의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지
              않습니다.
            </li>
            <li>
              ③ "몰"은 이용자가 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해 얻은 자료를
              이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다.
            </li>
            <li>
              ④ 이용자가 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 또는 정확성에 대하여는 해당
              이용자가 책임을 부담하며, "몰"은 내용의 부적정성으로 인해 이용자 또는 제3자에게 발생한 손해에
              대하여는 아무런 책임을 부담하지 않습니다.
            </li>
            <li>
              ⑤ "몰"은 서비스 이용과 관련하여 이용자의 고의 또는 과실로 인하여 이용자 또는 제3자에게 발생한
              손해에 대하여는 아무런 책임을 부담하지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service27">제 27조(정보의 제공 및 광고의 게재)</h3>
          <ol>
            <li>
              ① "몰"은 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 전자우편 및 서신우편 등의
              방법으로 회원에게 제공할 수 있습니다. 단, 수신거절의 의사를 명백히 표시한 회원에 대해서는 더
              이상 전자우편 또는 서신우편 등을 발송하지 않습니다.
            </li>
            <li>
              ② "몰"은 서비스의 운영과 관련하여 홈페이지, 서비스 화면, SMS, 전자우편 등에 광고 등을 게재할 수
              있습니다.
            </li>
            <li>
              ③ 회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는
              등의 방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주 간의 문제입니다. 만약 회원과
              광고주간에 문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와 관련하여 "몰"은
              어떠한 책임도 지지 않습니다.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service28">제28조(재판권 및 준거법)</h3>
          <ol>
            <li>
              ① "몰"과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고,
              주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소
              또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
            </li>
            <li>② "몰"과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>&lt;부칙&gt;</h3>
          <p>
            본 약관은 2020년 11월 6일부터 시행됩니다.<br />
            2020년 1월 14일부터 시행해 온 약관은 본 약관으로 대체됩니다.
          </p>
          <ul class="prev_link">
            ${
              G.collabo_type == 'creator'
                ? `<li><a href="https://marpple.com/kr/terms_20200114">- 이전 이용약관 보기</a></li>`
                : `<li><a href="/terms_20200114">- 이전 이용약관 보기</a></li>`
            }
          </ul>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">확인</button>
    </div>
  </div>
`;

export const en_privacy = () => pug`
    .terms_body
      .title
        h1 Privacy Policy
      .body
        .text_body
          .paragraph
            h3 Introduction
            p Marpple Corp. (“Marpple”, “we”, “our” or “us”) respects the privacy of all of our visitors and pledges to provide you with the best online experience possible. This Privacy Policy sets out how we will collect and use the personal information you provide to us while using the website located at www.marpple.com (the “Site”) and/or the services provided from it (the “Services”). This Privacy Policy applies solely to the Site and the Services. It does not apply to any other site, product or service of Marpple, its affiliates, third party sellers, or customers.
            p By accessing or using the Site, you are deemed to accept the terms of this Privacy Policy. If you do not agree to this Privacy Policy, you may not access or otherwise use the Site or Services. We reserve the right, at our discretion, to change, modify, add or remove portions of this Privacy Policy from time to time and such changes shall be posted on the Site. We encourage you to periodically review the Site for the latest information on our privacy practices
          <hr>
          .paragraph
            h3 Information That We Collect
            p We collect information you give us or permit us to access. Information may include, but is not limited to, your name, image, birth date, email and physical address, IP address, telephone number or mobile phone identifier, gender, contact lists, social media information, location (including GPS) information, activity and performance information, and when necessary, credit card information.
            p We may also collect information regarding your interaction with, and use of, our products and services. Information we may collect includes, but is not limited to, your telephone number, Device identifier and hardware information, IP address, browser type and language, cookie information, system type, whether you have enabling software to access certain features, access times, referring website URLs, information about your purchases and other information about your interactions with us.
            p To provide the Site and the Services, we may collect and process certain personal information that you voluntarily submit to us, such as through our contact pages, by email, by creating an account on the Site, by making purchases on the Site or some other means. We take care to keep this information secure and prevent any unauthorized access.
          <hr>
          .paragraph
            h3 How We Collect Information
            p We may use cookies and other collection tools, such as clickstream data and web beacons, to gather information about your visits on our website so we can enhance your experience. This information may be combined with information you have provided to us, which will enable us to analyze and better customize your visits.
            p What Are Cookies? Cookies are data files stored within your browser when you interact with a website. These data files allow the website to recall important information about your visit, help better understand your preferences and improve your overall experience.
            p You may limit or turn off cookies and other collection tools by adjusting your browser settings. If you turn off certain collection tools, you may not have access to many features that make your experience more efficient and some of our services and features may not function properly. We recommend that you leave cookies “turned on” so we can offer you a better shopping experience on our sites.
            p We also work with other companies who use tracking technologies to serve ads on our behalf across the Internet. For example, we use Google Analytics on the website to analyze how users use the website and to provide advertisements to you on other websites. For more information about how to opt-out of having your information used by Google Analytics, visit https://tools.google.com/dlpage/gaoptout. These companies may not recognize or respond to browser “Do Not Track” signals. For more information about third party cookies, including information about how to opt-out of these technologies. We do not operate or control these sites, and are not responsible for the opt-out choices available there.
          <hr>
          .paragraph
            h3 Protection of Personal Information Across Borders.
            p We do not and will never share, disclose, sell, rent or otherwise provide personal information to other companies for the marketing of their own products or services.
            p We provide services to individuals and our technology processes data from users around the world. Accordingly, Marpple may transmit your personal information outside of the country, state, or province in which you are located.
          <hr>
          .paragraph
            h3 Protection of Information
            p Marpple uses technical and organizational measures designed to protect your information against unauthorized access, theft, and loss. While Marpple strives to protect its user's personal information and privacy, we cannot guarantee 100% the security or confidentiality of any information that you provide to us or that is otherwise disclosed by you online. We recommend that you take additional measures to protect yourself and your information, such as installing anti-virus software, closing browsers after use, keeping confidential your log-in credentials and passwords.
          <hr>
          .paragraph
            h3 Children’s Privacy
            p We do not knowingly collect or solicit information online from children under 13 and we do not specifically target children under 13 in our marketing materials or other advertising.
          <hr>
          .paragraph
            h3 Sharing Information
            p We may provide your information to our affiliates and subsidiaries, as well as to our partners, vendors, distributors, and suppliers, some of which may be outside of your country. We may transfer your information to certain service providers in order to conduct our business operations. For example, service providers may handle web hosting, credit card processing, shipping, data management, email distribution, market research, information analysis, and promotions management. We may also share your information as is necessary to complete a transaction or provide a product, service, or feature that you have requested. These serve providers will only use your information for the purpose of carrying out the services provided to Marpple and are required to keep all such information confidential. We may disclose information upon governmental request, in response to a court order, when required by law, to enforce our policies, or to protect our or others' rights, property or safety. We may share information to prevent illegal uses of Marpple's products and services or violations of the Terms of Use, or to defend ourselves against third-party claims. We may also share information with companies assisting in fraud protection or investigation.
            p Your information may be transferred to a third party as a part of our business assets in a sale of a part or all of Marpple. If this should happen, notice will be provided via the website or other appropriate form of communication.
            p You may also choose to share certain information. When you join or use certain services, you agree to publicly share a basic amount of information, which may include your username, city location, and profile picture. In order to participate in certain features, you may have to adjust your privacy settings and share additional information. You may also choose to share your activity on other platforms, such as Instagram, Facebook and Twitter. Please read the privacy policies of those platforms, because your Marpple activity published on those platforms will no longer be governed by this Privacy Policy.
          <hr>
          .paragraph
            h3 Residents of The European Economic Area (“EEA”)
            p Marpple works with artists/marketers and users around the world, including in the EEA. If you are located in the EEA, you have certain rights under European law with respect to your personal data, including the right to request access to, correct, amend, delete, or limit the use of your personal data. If you wish to exercise these rights, please reach out to us using the contact information below. Additionally, if you are located in the EEA, we note that we are generally processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above, unless we are required by law to obtain your consent for a particular processing operation.
          <hr>
          .paragraph
            h3 Data Transfer
            p Our digital operations are conducted, in whole or in part, in your country. Regardless of where you live, you consent to have your personal data transferred, processed and stored in your country, and allow Marpple to use and collect your personal information in accordance with this Privacy Policy.
          <hr>
          .paragraph
            h3 OPT-OUT, Delete or Modify Information
            p You may cancel your account, or opt-out of receiving e-mail messages from Marpple at any time. To unsubscribe and stop receiving communications from Marpple, you can change your account settings or follow the instructions enclosed within the email, text message, notification or other promotional message or communication. If you have unsubscribed from any Marpple communications, due to production schedules, you may receive communications already in production. Marpple may keep information and content in our backup files and archives.
          <hr>
          .paragraph
            h3 Question and Comments
            p If you have any queries or comments about this Privacy Policy or our privacy practices, or wish to resume receiving information which you may have previously opted-out of receiving, please contact us at <a href="mailto:info@marpple.com">info@marpple.com</a>.
            p Attn: Marpple Corp.<br>MarioTower 6F, Digital-ro 30,<br>Guro-gu Seoul,<br>Korea  08389
          <hr>
          .paragraph
            h3 Terms of Use
            p Your use of our products and services, and any disputes arising from them, is subject to this Privacy Policy as well as the Terms of Use. Please visit our Terms of Use, which explains other terms governing the use of this website, as well as our products and services.
      .option
        button.done Done    
  `;

export const jp_terms = () => pug`
    <div class="terms_body">
      <div class="title">
          <h1>利用規約</h1>
      </div>
      <div class="body">
          <div class="text_body">
            <div class="paragraph">
              <h3 id="service1">[ 本規約の適用範囲及び変更 ]</h3>
              <ol>
                <li>• 本規約は弊社サービスの提供またはその利用に関し、当サイト及び閲覧・購入などの利用を行ったお客様に適用されるものとします。</li>
                <li>• 本サービスをご利用いただいたお客様はこの規約に同意されたものとさせていただきます。</li>
                <li>• 当サイトは、利用者の事前の承諾を得る事なく本規約を変更できるものとします。</li>
              </ol>
            </div>
            <div class="paragraph">
              <h3 id="service2">[ 弊社サービスの利用資格の停止 ]</h3>
              <p>当サイトは、利用者が以下の項目に該当する場合、お客様に事前の通知することなく本サービスの利用資格を取り消すことができるものとします。</p>
              <ol>
                  <li>• 名誉や信用毀損などの犯罪的行為や法令に違反する行為があったとき</li>
                  <li>• アカウントの不正使用があったとき</li>
                  <li>• 過去に規約違反等により利用資格が取り消されていることが判明したとき</li>
                  <li>• 一定回数以上のパスワードの入力ミスなど利用者のセキュリティ確保のために必要と認めたとき</li>
                  <li>• 弊社に対する乱暴な言動、危害を加えるような告知行為など、不当な要求行為が行われたとき</li>
                  <li>• その他、本規約に違反する行為があったとき</li>
              </ol>
            </div>
            <div class="paragraph">
              <h3 id="service3">[ 著作権 ]</h3>
              <p>当サイト内全てのURL上に含まれる内容等の著作権は運営管理責任者に帰属します。すべての内容は、「個人的な使用」「引用」など著作権法上認められた場合を除き、著作権者に無断で利用（複製、転載、配布など）することはできません。</p>
            </div>
            <div class="paragraph">
              <h3 id="service4">[ IDおよびパスワードの管理 ]</h3>
              <p>IDおよびパスワードは、他人に知られることがないよう定期的に変更する等、会員本人が責任をもって管理してください。盗用、不正使用その他の事情により会員以外の者が利用している場合であっても、それにより生じた損害について弊社は一切責任を負いません。</p>
            </div>
            <div class="paragraph">
              <h3 id="service5">[ 会員の退会 ]</h3>
              <p>会員が退会を希望する場合には、会員本人が会員情報の削除を行ってください。所定の退会手続の終了後に、退会となります。</p>
            </div>
            <div class="paragraph">
              <h3 id="service6">[ 個人情報の利用 ]</h3>
              <p>弊社は、会員または弊社サービスを利用したお客様からのご注文処理時に入力された税法に関する注文履歴のデータについて、会員の有効是是非に関係なく一定期間保管する義務があります。個⼈情報は会員退会時に削除し、別途弊社が定める「個⼈情報保護⽅針」に基づいて取り扱います。これらの情報は商品のお届けや事務処理手続き、新商品サービスのご案内に限ってのみ使用させて頂きます。お客様の個人情報を以下の項目に該当する場合を除き、当サイト以外の第三者に提供または開示しません。</p>
              <ol>
                  <li>• ご本人の同意がある場合</li>
                  <li>• その他法令にもとづき開示・提供を求められた場合</li>
              </ol>
            </div>
            <div class="paragraph">
              <h3 id="service7">[ サービスの停止 ]</h3>
              <p>当サイトは弊社サービスを常に最善に保つため、次の項目に該当する場合はお客様に事前に通知を行うことなく弊社サービスの提供の全部あるいは一部を停止することができるものとします。</p>
              <ol>
                <li>• システムの定期点検及び緊急保守のために必要な場合</li>
                <li>• 火災、天災、停電、第三者による妨害行為などによりシステムの運用が困難になった場合</li>
                <li>• その他、システムの停止が必要と運営管理責任者が判断した場合</li>
                <li>• 上記の内容によってお客様に損害が発生した場合、弊社及び当サイト、運営管理責任者は一切の責任を負わないものとします。</li>
              </ol>
            </div>
            <div class="paragraph">
              <h3 id="service8">[ 交換・返品対象外の事柄 ]</h3>
              <p>実際発送日において商品発送後、配送業者配送の停滞、事故等によって、お客様に損害が生じた場合、弊社は責任を負わないものとします。</p>
            </div>
            <div class="paragraph">
              <h3 id="service9">[ その他免責事項 ]</h3>
              <ol>
                  <li>• 当サイトは、不良品や商品発送時の不測の事態（交通事情、自然災害等）により納期の遅延が生じた場合の二次的に発生する損害の一切の責任を負わないものとします。</li>
                  <li>• 当サイトは、法律上の請求原因にかかわらず、いかなる場合においても弊社サービスにおいて売買される商品に関する損害、損失、不利益などに関して前項に定める以外の責任を一切負わないものとします。</li>
                  <li>• 当サイトは、お客様が弊社サービスをご利用になれなかったことにより発生した一切の損害について、いかなる責任を負わないものとします。</li>
                  <li>• 弊社グループサイトは、やむを得ぬ事情または突発的な事象により、万が一弊社での生産・出荷体制を確保できなくなった場合、受注後におきましてもご対応できない場合が ありますのでご了承くださいませ。</li>
                  <li>• 受注後、ご決済頂いているものに関してましても、ご決済頂いた金額のみを返金の形で対応させて頂きます。（代引きにつきましては、決済が未処理という形でご対応させて頂きます。）いかなる場合におきましても、お客様には一切の金銭的補償は行いませんのでご了承下さいませ。</li>
                  <li>• ご注文頂いたお客様が弊社サービスをご利用になることにより、別のお客様または第三者に対して損害などを与えた場合にも、お客様の自己の責任と費用において解決していただき、弊社としては一切の責任を負えません。ご了承ください。</li>
                  <li>• お客様が弊社サービスを利用するために必要なコンピュータ機器及び通信機器などの設置に関する費用、弊社サービスを利用するために要した電話料金、LANなどの利用料及び申請料金などは、お客様ご自身がご負担いただきます。</li>
                  <li>• 会員が本規約等に違反したことによって生じた損害については、弊社は一切責任を負いません。</li>
              </ol>
            </div>
            <div class="paragraph">
              <h3 id="service10">[ その他 ]</h3>
              <ol>
                  <li>• 当サイトと利用者との連絡方法は、原則として電子メール及び電話・ファックスによるものとします。</li>
                  <li>• 弊社サービスのご利用に関して、本利用者規約により解決出来ない問題が生じた場合には、当サイト運営側とお客様との間で双方誠意を持って話し合い、これを解決するものとします。</li>
                  <li>• 弊社サービスの利用に関して訴訟の必要が発生した場合には、当サイト所在地を管轄する裁判所を唯一の合意管轄裁判所といたします。</li>
                  <li>• お客様の売買代金不払いその他本規約違反行為によって損害賠償義務が発生し、その請求回収のために当サイト運営側が弁護士を用いた場合には、弁護士報酬規定に基づく弁護士費用についてもお客様の負担とします。</li>
              </ol>
            </div>
            <div class="paragraph">
              <h3 id="service11">[ 受注拒否 ]</h3>
              <ol>
                <li>• 弊社のお客様として不適切と判断し、以後のご注文をお断りする場合があることをご了承いただきます。
                  <ol>
                    <li>（一連の取引での総合的な判断によります）</li>
                    <li>※ 弊社の士気に影響を及ぼすと共に、他のお客様の案件作業にも影響を及ぼしかねませんので、弊社のお客様として不適切と判断した場合以後の受注をお断りしております。</li>
                  </ol>
                </li>
              </ol>
            </div>
            <div class="paragraph">
              <h3 id="service12">[ 判断要素の一例 ]</h3>
              <ol>
                  <li>• メールの文面が著しく配慮を欠いている</li>
                  <li>• 連絡が頻繁で、即時返信を要求する</li>
                  <li>• お客様がする必要のある義務を怠っている（規約の確認・メールチェック・データの修正・支払期日厳守など）</li>
                  <li>• 取引中であっても、弊社が不適切と判断した場合、ご注文をキャンセルさせていただく場合があることをご了承いただきます。</li>
                  <li>• 取引中止または、受注拒否が発生しても、弊社はその理由の説明を行わないものとします。</li>
              </ol>
            </div>
          </div>
      </div>
    <div class="option">
      <button class="done">閉じる</button>
    </div>
  </div>
  `;

export const jp_privacy = () => pug`
    <div class="terms_body">
      <div class="title">
          <h1>プライバシーポリシー</h1>
          <p>個人情報保護方針<br>ティクーンジャパン株式会社は、ネット上での取引及びサービス利用にあたり、お客様からお預かりする個人情報の取り扱いには細心の注意を払い適切に管理し、個人情報の保護に努めてまいります。<br>ここでは、次の項目について下記に記述します。</p>
          <p>
            | 個人情報の収集について<br>
            |  利用目的について<br>
            | 個人情報等の取り扱いについて<br>
            | 個人情報保護方針について<br>
            | お客様が当社およびサービスの利用規約に同意会員登録、サービスを利用し、下記に記述している内容に同意していただいたものとみなします。
          </p>
      </div>
      <div class="body">
          <div class="text_body">
            <div class="paragraph">
              <h3 id="service1">[ 情報の収集及び利用 ]</h3>
              <ol>
                <li>▶ お客様から提供される情報
                  <ol>
                    <li>お客様から注文などの 申し込みを受ける時に、氏名（フリガナを含む）、住所、電話番号(携帯電話・FAXを含む)、電子メールアドレス、携帯メールアドレス、会社名・団体名、その他連絡先に関する情報、生年月日情報など、お客様から当社に提供される一切の情報</li>
                  </ol>
                </li>
                <br>
                <li>▶ サービスの利用に関連して取得される情報
                  <ol>
                    <li>お客様が当社またはサービス提供者が提供する商品または、プレゼント応募、その他の取引を申し込まれた場合の、お客様を識別できる情報と繋がる状況での取引履歴に関する情報</li>
                    <li>当社からのメールマガジンなどの購読に関する情報</li>
                    <li>当社のサービス中ポイント購入および獲得に関する情報</li>
                    <li>当社の電話や電子メールその他の手段により、当社またはサービス提供者に質問する、アンケートやキャンペーン、イベント、サービスを評価するなどを行った場合の、その発言または記載内容に関する情報</li>
                  </ol>
                </li>
                <br>
                <li>▶ アクセスしたことを契機として機械的に取得される情報
                  <ol>
                    <li>お客様のコンピュータがインターネットに接続するときに使用されるIPアドレス、携帯端末の機体識別に関する情報</li>
                    <li>当社の運営するウェブサイトにアクセスしたことを契機として取得された、お使いのブラウザの種類・バージョン、オペレーティングシステムと当社のサービス利用に必要なその他アプリケーションプログラムの種類とバージョン、お客様の閲覧されたページ(URL)、閲覧した日時、表示または検索された商品などに関する情報</li>
                  </ol>
                </li>
              </ol>
            </div>
            <div class="paragraph">
              <h3 id="service2">[ 利用目的について ]</h3>
              <ol>
                <li>
                  ▶ お客様が当社の会員としてサービスを利用する場合
                  <ol>
                    <li>お客様が当社の会員としてログイン時およびログイン後における本人認証、会員情報の自動表示提供</li>
                    <li>お客様が商品または予約・購入・配送・代金決済とお客様からのお問合せへの対応、当社からお客様へのお問合せ、関連するアフターサービス、その他取引遂行にあたって必要な業務</li>
                  </ol>
                </li>
                <br>
                <li>▶ 当社のサービス案内またはマーケティングなど
                  <ol>
                    <li>お客様向け各種メールマガジンなどの情報提供</li>
                    <li>サービスについての電子メール、郵便、電話などによる情報提供</li>
                    <li>お客様によるサービスの利用を分析し、新規サービスの開発や既存サービスの改善をするため</li>
                    <li>アンケート、キャンペーン、 評価掲示板などの意見・情報の交換サービスに関連して、お客様と連絡をとるため</li>
                    <br>
                    <li>個人情報等の取り扱いについて</li>
                    <li>当社は、個人情報保護法に従い、個人情報を取り扱います。</li>
                    <li>当社は、お客様の個人情報を必要な範囲で当社およびサービスで共同利用して以下の項目に該当する場合を除き、当社およびサービス以外の第三者に提供または開示いたしません。</li>
                    <br>
                    <li>ご本人の同意がある場合</li>
                    <li>その他法令にもとづき開示・提供を求められた場合</li>
                    <li>当社およびサービスの上でのお客様の行為がご利用規約に反しており、 当社の権利、サービス等を保護するために必要と求められる場合</li>
                  </ol>
                </li>
              </ol>
            </div>
            <div class="paragraph">
              <h3 id="service3">[ 個人情報保護方針について ]</h3>
              <ol>
                <li>▶ 基本方針
                  <ol>
                    <li>当社およびサービスは、お客様の個人情報保護については重大な社会的責任と認識しており、個人情報保護に関して継続的に改善に取り組むことを宣言します。</li>
                  </ol>
                </li>
                <br>
                <li>▶ 活動指針
                  <ol>
                    <li>個人情報の収集、利用、提供を適正に行い、個人情報の廃棄に関しては万全を期するようにいたします。</li>
                    <li>個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏洩などを防止するため適切な安全対策を行います。</li>
                    <li>個人情報保護方針を役員及び従業員に周知させるとともに、 当社およびサービスのサイトに掲載いたします。</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
      </div>
    <div class="option">
      <button class="done">閉じる</button>
    </div>
  </div>
  `;

export const jp_commerce_law = () => pug`
    <div class="terms_body">
      <div class="title">
        <h1>特定商取引法に基づく表記</h1>
      </div>
      <div class="body">
          <div class="text_body">
            <div class="paragraph">
              <h3 id="service1">1. 社名</h3>
              <p>ティクーンジャパン株式会社</p>
            </div>
            <div class="paragraph">
              <h3 id="service2">2. 代表責任者名</h3>
              <p>中尾 幸夫</p>
            </div>
            <div class="paragraph">
              <h3 id="service3">3. 所在地</h3>
              <p${MpAddressConstantS.MP_ADDRESS_JP}</p>
            </div>
            <div class="paragraph">
              <h3 id="service4">4. 連絡先</h3>
              <ol>
                <li>• 電話番号 050-5840-0207</li>
                <li>• メールアドレス <a href="matilto:cs@marpple.com">cs@marpple.com</a></li>
              </ol>
            </div>
            <div class="paragraph">
              <h3 id="service5">5. 電話対応</h3>
              <p>10:00 ~ 13:00 / 14:00 ~ 18:00（土・日・祝祭日を除く）</p>
            </div>
            <div class="paragraph">
              <h3 id="service6">6. 商品の価格</h3>
              <p>弊社HP各商品ページにて表示</p>
            </div>
            <div class="paragraph">
              <h3 id="service7">7. 送料</h3>
              <p>全国一律 無料</p>
            </div>
            <div class="paragraph">
              <h3 id="service8">8. 決済方法</h3>
              <p>
                | PayPal(ペイパル)<br>
                | ※その他のご決済方法はただいま準備中です。今しばらくお待ちください。</p>
            </div>
            <div class="paragraph">
              <h3 id="service9">9. 返品/交換</h3>
              <ol>
                <li>① 受注生産の特性上、お客様都合による返品 ・交換は原則としてお受けしておりません。また以下の場合も返品 ・交換ができません。
                  <ol>
                    <li>• プリントされたカラーの違い
                      <ol><li>プリント方法や生地の素材により、モニターや紙に印刷した時のカラーと商品に印刷した際のカラーに多少誤差が生じる場合がございます。</li></ol>
                    </li>
                    <li>• 印刷の位置および、大きさの違い
                      <ol><li>製品の素材による特性の違いや、全ての印刷を１つ１つ手作業で行っている関係上、シミュレーターと実際の商品の印刷位置および、大きさに誤差が生じる場合がございます。印刷位置および、大きさを別途で[要請]しなかったご注文に関しては、上記の理由での返品 ・交換ができませんのでご了承ください。</li></ol>
                    </li>
                    <li>• 追加注文時、既存の商品とのカラーの違い
                      <ol><li>商品カラーおよび、サイズは製作の時期、生産シーズンにより、カラーやサイズに誤差が生じる場合がございます。</li></ol>
                    </li>                  
                  </ol>
                </li>
                <li>② また破損・加工不良など、弊社の過失と認められる場合、商品到着後7日以内にご連絡いただければ返品 ・交換が可能です。但し商品到着後7日を経過した後の返品 ・交換は対応いたしかねますのでご了承ください。
                  <ol>
                    <li>• 弊社事由による返品・交換にかかる送料は弊社にて負担させて頂きます。</li>
                    <li>• ご注文いただきました商品以外への交換は承っておりません。</li>
                    <li>• 事前にご連絡のない商品のご返送は対応いたしかねます。</li>
                  </ol>
                </li>
              </ol>
            </div>
            <div class="paragraph">
              <p>★ 注文完了後、商品および、イメージ変更をご希望の場合、[製作準備中]状態でのみ変更が可能です。[製作中]には変更および、キャンセルができませんのでご了承ください。変更をご希望の場合はカスタマーセンターへお問い合わせください。</p>
            </div>
          </div>
      </div>
    <div class="option">
      <button class="done">閉じる</button>
    </div>
  </div>
`;

export const kr_privacy = () => pug`
    <div class="terms_body">
      <div class="title">
        <h2>개인정보 취급방침</h2>
      </div>
      <div class="body">
        <div class="agree_header">
          <p>
            (주)마플코퍼레이션(이하 "회사"라고 함)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며,<br>
            관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 마플코퍼레이션의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.
          </p>
          <div class="table_of_contents">
            <ul>
              <li><a href="#privacy1">제1조 총칙</a></li>
              <li><a href="#privacy2">제2조 수집하는 개인정보의 항목 및 수집방법</a></li>
              <li><a href="#privacy3">제3조 개인정보의 수집 및 이용목적</a></li>
            </ul>
            <ul>
              <li><a href="#privacy4">제4조 개인정보 공유 및 제공</a></li>
              <li><a href="#privacy5">제5조 개인정보의 취급위탁</a></li>
              <li><a href="#privacy6">제6조 개인정보의 보유 및 이용기간</a></li>
            </ul>
            <ul>
              <li><a href="#privacy7">제7조 개인정보 파기절차 및 방법</a></li>
              <li><a href="#privacy8">제8조 이용자 및 법정대리인의 권리와 그 행사방법</a></li>
              <li><a href="#privacy9">제9조 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</a></li>
            </ul>
            <ul>
              <li><a href="#privacy10">제10조 개인정보의 기술적/관리적 보호 대책</a></li>
              <li><a href="#privacy11">제11조 개인정보관리책임자 및 담당자의 연락처</a></li>
              <li><a href="#privacy12">제12조 고지의 의무</a></li>
            </ul>
          </div>
        </div>
        <div class="text_body">
          <div class="paragraph">
            <h3 id="privacy1">제1조 총칙</h3>
            <ol>
                <li>① 개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해 개인을 알아볼 수 있는 부호,문자,음성,음향 및 영상 등의 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함합니다)를 말합니다.</li>
                <li>② ㈜마플코퍼레이션(이하 "회사")은 이용자의 개인정보보호를 매우 중요시하며, 「개인정보보호법」,「정보통신망 이용 촉진 및 정보보호에 관한 법률」 등 개인정보보호 관련 법률 및 하위 법령들을 준수하고 있습니다.</li>
                <li>③ 회사는 개인정보취급방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. 회사는 개인정보취급방침을 홈페이지 첫 화면에 공개함으로써 언제나 용이하게 보실 수 있습니다.</li>
                <li>④ 회사는 개인정보취급방침의 지속적인 개선을 위하여 개정하는데 필요한 절차를 정하고 있으며, 개인정보취급방침을 회사의 필요와 사회적 변화에 맞게 변경할 수 있습니다.</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="privacy2">제2조 수집하는 개인정보의 항목 및 수집방법</h3>
            <ol>
              <li>① 회사는 별도의 회원가입 절차 없이 대부분의 콘텐츠에 자유롭게 접근할 수 있습니다. 단, 회사에서 제공하는 회원제 서비스를 이용하시고자 할 경우 다음의 정보를 입력해주셔야 합니다.
                <ol class="no_padding">
                  <li>1. 필수항목
                    <ul>
                      <li>- 개인회원 : 전자우편(e-mail) 주소, 비밀번호, 닉네임, 전화번호(휴대전화), 생년월일</li>
                      <li>- 개인/법인사업자 : 회사/기관명, 사업자등록번호, 담당자이름, 전자우편(e-mail) 주소, 전화번호(휴대전화)</li>
                    </ul>
                  </li>
                  <li>2. 선택항목
                    <ul>
                      <li>- 신용카드정보, 은행계좌정보, 이동전화정보 등 대금결제에 필요한 정보</li>
                      <li>- 회원가입시 회원이 원하시는 경우에 한하여 추가 정보를 선택, 제공하실 수 있도록 되어있으며, 일부 재화 또는 용역 상품에 대한 주문 및 접수시 회원이 원하는 정확한 주문 내용 파악을 통한 원활한 주문 및 결제, 배송을 위하여 추가적인 정보를 요구하고 있습니다.</li>
                    </ul>
                  </li>
                  <li>3. 서비스 이용과정 및 사업 처리과정에서 수집될 수 있는 개인정보의 범위
                    <ul>
                      <li>- 최근접속일, 접속 IP 정보, 쿠키, 구매로그, 이벤트로그</li>
                    </ul>
                  </li>
                  <li>4. 비회원 고객
                    <ul>
                      <li>- 회사는 비회원 고객의 주문에도 배송, 대금결제, 주문내역 조회 및 구매확인, 실명여부 확인을 위하여 필요한 개인정보만을 요청하고 있으며, 이 경우 그 정보는 대금결제 및 상품의 배송에 관련된 용도 이외에는 다른 어떠한 용도로도 사용되지 않습니다.</li>
                      <li>- 회사는 비회원의 개인정보도 회원과 동등한 수준으로 보호합니다.</li>
                    </ul>
                  </li>
                  <li>5. 14세 미만 아동의 개인정보
                    <ul>
                      <li>- 회사는 법정 대리인의 동의가 필요한 만 14세 미만 아동의 회원가입은 받고 있지 않습니다.</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>② 회사는 다음과 같은 방법으로 개인정보를 수집합니다.
                <ul class="no_padding">
                  <li>- 홈페이지, 서면양식, 팩스, 전화, 상담 게시판, 전자우편 (e-mail), 이벤트 응모, 배송요청</li>
                  <li>- 협력회사로부터의 제공</li>
                  <li>- 로그 분석 프로그램을 통한 생성정보 수집</li>
                </ul>
              </li>
              <li>③ 개인정보 수집에 대한 동의
                <ul class="no_padding">
                  <li>- 회사는 이용자께서 회사의 개인정보취급방침 및 이용약관의 내용에 대해「동의한다」버튼 또는 「동의하지 않는다」 버튼을 클릭할 수 있는 절차를 마련하여,「동의한다」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.</li>
                </ul>
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="privacy3">제3조 개인정보의 수집 및 이용목적</h3>
            <ol>
              <li>① 회사는 다음과 같은 목적을 위하여 필요한 최소한의 개인정보를 수집하고 있습니다.
                <ol class="no_padding">
                  <li>1. 아이디, 비밀번호, 이름 생년월일 : 회원제 서비스 이용에 따른 본인 식별 절차에 이용</li>
                  <li>2. 전자우편 (e-mail) 주소 (뉴스레터 수신여부) : 공지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 새로운 서비스, 신상품이나 이벤트 정보 등 최신 정보의 안내</li>
                  <li>3. 주소, 전화번호 : 쇼핑 물품 배송에 대한 정확한 배송지의 확보</li>
                  <li>4. 그 외 선택항목 : 개인맞춤 서비스를 제공하기 위한 자료</li>
                  <li>5. 회원의 서비스이용에 대한 통계 : 업무와 관련된 통계자료 작성 및 서비스 개발</li>
                </ol>
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="privacy4">제4조 개인정보 공유 및 제공</h3>
            <ol>
              <li>① 회사는 고객의 사전 동의가 있거나 법률에서 정하는 경우 외에는 고객의 개인정보를 제3자에게 제공하거나 제3조 개인정보의 수집 및 이용목적에서 고지한 목적 외의 용도로 이용하지 않습니다.</li>
              <li>② 고객의 편의를 제공하거나 서비스를 증진하기 위하여 다음과 같은 경우에는 고객의 개인정보를 제3자에게 제공하거나 제3자와 공유할 수 있습니다.</li>
              <li>1. 고객이 사전에 동의한 경우</li>
              <li>2. 법령의 규정에 의하거나 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
              <li>3. 개인정보를 제공할 경우에는 사전에 고객에게 개인정보를 제공받는 제3자, 제공하는 개인정보항목명, 개인정보 제공의 목적, 제공하는 개인정보가 언제까지 어떻게 보호·관리되는지, 고객이 종전에 동의한 사항, 고객의 동의하지 않을 권리와 의사표시 방법, 기타 고객의 동의를 얻기 위하여 필요한 사항에 대해 개별적으로 서면, 전자우편(e-mail), 전화 등을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 고객이 동의하지 않는 경우에는 제3자에게 정보를 제공하지 않습니다.</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="privacy5">제5조 개인정보의 취급위탁</h3>
            <ol>
              <li>① 회사는 서비스 제공, 고객상담, 서비스 결제를 위해 최소한의 개인정보를 위탁하고 있습니다.
                <ul class="no_padding">
                  <li>- CJ 대한통운 : 상품 배송업무, 배송위치/도착정보 등 서비스 제공업무</li>
                  <li>- 이니시스 : 결제정보 전송, 결제대행 업무</li>
                  <li>- 슈어엠 : SMS, MMS등 문자메세지 발송 업무</li>
                </ul>
              </li>
              <li>② 회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해 배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</li>
              <li>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="privacy6">제6조 개인정보의 보유 및 이용기간</h3>
            <ol>
              <li>① 이용자의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기됩니다.
                <ol class="no_padding">
                  <li>1. 회원가입정보 : 회원가입을 탈퇴하거나 회원에 제명된 때</li>
                  <li>2. 대금지급정보 : 대금의 완제일 또는 채권소명시효기간이 만료된 때</li>
                  <li>3. 배송정보 : 재화 또는 용역 등 상품이나 서비스가 인도되거나 제공된 때</li>
                  <li>4. 설문조사, 이벤트 등 일시적 목적을 위하여 수집한 경우 : 당해 설문조사, 이벤트 등이 종료한 때</li>
                </ol>
              </li>
              <li>② 단, 위 개인정보 수집목적 달성시 즉시파기 원칙에도 불구하고 다음과 같이 거래관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는「전자상거래 등에서의 소비자보호에 관한 법률」「국세기본법」등 관련 법령의 규정에 근거하여 일정기간 보유합니다.
                <ol class="no_padding">
                  <li>1. 계약 또는 청약철회 등에 관한 기록 : 5년</li>
                  <li>2. 대금결제 및 재화 등의 공급에 관한 기록 : 5년</li>
                  <li>3. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                  <li>4. 웹사이트 방문기록 : 3개월</li>
                </ol>
              </li>
              <li>③ 이용자의 동의를 받아 보유하고 있는 거래정보 등을 이용자께서 열람을 요구하는 경우 회사는 지체없이 열람, 확인할 수 있도록 조치합니다.</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="privacy7">제7조 개인정보 파기절차 및 방법</h3>
            <ol>
              <li>① 회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
                <ol class="no_padding">
                  <li>1. 파기절차
                    <ul>
                      <li>- 이용자가 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(제6조 개인정보의 보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.</li>
                    </ul>
                  </li>
                  <li>2. 파기방법
                    <ul>
                      <li>- 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각을 통하여 파기</li>
                      <li>- 전자적 파일 형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>② 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="privacy8">제8조 이용자의 권리와 그 행사방법</h3>
            <ol>
              <li>① 이용자는 언제든지 등록되어 있는 본인의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 홈페이지내 마이페이지를 클릭하여 직접 열람 또는 정정하거나, 개인정보관리책임자에게 서면/전자우편(e-mail)/모사전송 및 전화로 연락주시면 본인확인 절차를 거쳐 지체없이 조치하겠습니다. 다만, 아이디(전자우편주소)는 변경이 불가능하며 부득이한 사유로 변경하고자 할 경우에는 해당 아이디를 해지한 후 재가입하셔야 합니다. 재가입시에는 기존의 아이디를 사용하실 수 없습니다.</li>
              <li>② 회사는 이용자로부터 전항의 요구가 있는 경우 법정 기한 내에 그 요구에 응답하고 정정을 완료하기 전까지 당해 개인정보를 이용하지 않습니다. 단, 이 경우 해당 기간 내에 응답할 수 없는 정당한 사유가 있을 때에는 이용자에게 그 사유를 알리고 연기할 수 있으며, 그 사유가 소멸하면 지체없이 응답합니다.</li>
              <li>③ 이용자는 동의하신 개인정보의 수집, 이용, 제공에 대해 언제든지 철회하실 수 있습니다. 동의철회는 홈페이지내 마이페이지를 클릭하여 직접 삭제하거나 개인정보관리책임자에게 서면/전자우편(e-mail)/모사전송 및 전화로 연락주시면 본인확인 절차를 거쳐 지체없이 개인정보의 삭제 등 필요한 조치를 하겠습니다. 단, 온라인사이트의 경우 탈퇴시 통계자료 작성상 필요한 구매이력을 제외하고 모두 삭제됩니다.</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="privacy9">제9조 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h3>
            <ol>
              <li>① 회사는 이용자에 대한 정보를 저장하고 검색할 수 있는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트가 이용자의 컴퓨터 브라우저로 전송하는 소량의 정보입니다. 이용자께서 웹사이트에 접속을 하면 회사의 컴퓨터는 이용자의 브라우저에 있는 쿠키의 내용을 읽고, 이용자의 추가정보를 이용자의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는 이용자의 컴퓨터는 식별하지만 이용자를 개인적으로 식별하지는 않습니다.</li>
              <li>② 회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 제공하고 수시로 불러오는 쿠키(cookie)를 통하여 수집된 이용자의 개인정보를 사용합니다.</li>
              <li>③ 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저 옵션에서 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다.
                <ol class="no_padding">
                  <li>1. Internet Explorer의 경우
                    <ul>
                      <li>- [도구]메뉴에서 [인터넷 옵션]을 선택 → [개인정보]를 클릭 → [고급]을 클릭 → 쿠키 허용여부를 선택</li>
                    </ul>
                  </li>
                  <li>2. Safari의 경우
                    <ul>
                      <li>- MacOS 상단 좌측 메뉴바에서 [Safari]에서 [환경설정]선택 → [환경설정]창에서 [보안]으로 이동하여 쿠키 허용여부 선택</li>
                    </ul>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="privacy10">제10조 개인정보의 기술적/관리적 보호 대책</h3>
            <ol>
              <li>① 기술적 대책
                <ul class="no_padding">
                  <li>- 회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.</li>
                </ul>
                <ol class="no_padding">
                  <li>1. 이용자의 개인정보 중 비밀번호 등 중요한 데이터는 암호화되어 저장되며, 개인정보는 이중 방화벽의 안전지대에 안전하게 보관되고 있습니다.</li>
                  <li>2. 회사는 해킹 등에 의해 이용자의 개인정보가 유출되는 것을 방지하기 위해, 외부로부터의 침입을 차단하는 장치를 이용하고 있으며, 각 서버마다 침입탐지시스템을 설치하여 24시간 침입을 감시하고 있습니다.</li>
                </ol>
              </li>
              <li>② 관리적 대책
                <ol class="no_padding">
                  <li>1. 회사는 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보취급방침의 준수를 강조하고 있습니다.</li>
                  <li>2. 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.</li>
                  <li>3. 이용자는 ID와 비밀번호를 타인에게 알려 주어서는 안되며, 홈페이지에 로그인하셔서 이용을 마치신 후에는 반드시 로그아웃해주시기 바랍니다. 이용자 본인의 부주의나 인터넷상의 문제로 개인정보가 유출해 발생하는 문제에 대해 회사는 일체의 책임을 지지 않습니다.</li>
                  <li>4. 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 회사는 즉각 이용자에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.</li>
                </ol>
              </li>
              <li>③ 기타「개인정보보호법」「정보통신망 이용 촉진 및 정보보호에 관한 법률」에 의한 기술적, 관리적 대책을 준수합니다.</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="privacy11">제11조 개인정보관리책임자 및 담당자의 연락처</h3>
            <ol>
              <li>① 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만사항 및 문의를 처리하기 위하여 아래와 같이 개인정보 관리 책임자를 지정하고 있습니다.<br>
                <b>[개인정보 관리 책임자]</b>
                <ul>
                  <li>1. 성명 : 유인동 팀장</li>
                  <li>2. 전화번호 : 1566-9437 / 02-6486-7700</li>
                  <li>3. 전자우편주소(e-mail) : dev@marpple.com</li>
                </ul>
              </li>
              <li>② 기타 개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.
                <ul class="no_padding">
                  <li>- 개인정보침해신고센터 (<a href="https://privacy.kisa.or.kr/" target="_blank">privacy.kisa.or.kr/</a> / 국번없이 118)</li>
                  <li>- 정보보호마크인증위원회 (<a href="http://www.eprivacy.or.kr" target="_blank">www.eprivacy.or.kr</a> / 02-580-0534)</li>
                  <li>- 대검찰청 첨단범죄수사과 (<a href="http://www.spo.go.kr" target="_blank">www.spo.go.kr</a> / 02-3480-2000)</li>
                  <li>- 경찰청 사이버안전국 (<a href="https://cyberbureau.police.go.kr" target="_blank">cyberbureau.police.go.kr</a> / 국번없이 182)</li>
                </ul>
              </li>
            </ol>
            <h3 id="privacy12">제12조 고지의 의무</h3>
            <p>
              현 개인정보취급(처리)방침은 2015년 4월 15일에 개정되었으며 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지를 통해 고지할 것입니다.<br>
              개인정보취급방침 변경일자 : 2015년 4월 15일
            </p>
            <ul class="prev_link">
              <li><a href="https://marpple.com/kr/privacy_20150414">- 이전 개인정보보호 취급방침 보기</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="option">
        <button class="done">확인</button>
      </div>
  </div>
  `;
