export const ENVIRONMENT = Object.freeze({
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
});

export const APP_NAME = Object.freeze({
  MARPPLE: 'mp',
  MARPPLE_SHOP: 'creator',
  DF: 'df',
  SCHEDULER: 'scheduler',
});

export const DENY_URLS = [
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  /instagram/i,
  /hotjar/i,
  /googletagmanager/i,
];
