import { DF_CATE_LIST_FRAME, DF_CATE_LIST_PAGE, DF_CATE_LIST_TAB } from '../S/constant.js';
import { defnCateListRoute } from './route.js';

$.frame.defn_frame({
  frame_name: DF_CATE_LIST_FRAME,
  page_name: DF_CATE_LIST_PAGE,
});

$.frame.defn_page({
  page_name: DF_CATE_LIST_PAGE,
  tabs: [
    {
      tab_name: DF_CATE_LIST_TAB,
      appended: async (tab) => {
        await defnCateListRoute(tab);
      },
    },
  ],
});
