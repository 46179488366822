import { strMap, html } from 'fxjs/es';
import { MarstagramEditorTmplS } from '../../../MarstagramEditor/S/Tmpl/module/MarstagramEditorTmplS.js';
import { makeCvPatternHtml } from '../CvPattern/tmpl.js';
import { makeOpacityFunctionHtml } from '../opacity.js';
import { BpOptionF } from '../../../BpOption/F/Function/module/BpOptionF.js';
import { OMPDosuF } from '../../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { NewMakerConditionAgreeTextF } from '../../../NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';

const makeImageColorItem = (image_color, selected_image_color) => html`
  <div
    class="image_color_item ${selected_image_color.id === image_color.id ? 'selected' : ''}"
    _id="${image_color.id}"
    _sel="./(#${image_color.id})"
  >
    <img src="${G.to_150(image_color.url)}" alt="" />
  </div>
`;
function makeColorName(image_color) {
  if (image_color) return T.lang === 'kr' ? image_color['press_color_name' + _en] || T('maker::Unknown') : '';
}
function makeImageColorHtml(image_colors, selected_image_color) {
  if (!image_colors?.length) return ``;
  selected_image_color = selected_image_color || {};
  return html`
    <div class="image_color section" id="image_color_app">
      <div class="body">
        <div class="head">
          <div class="title">${T('Color')}</div>
          <div class="color_name">${makeColorName(selected_image_color)}</div>
        </div>
        <div class="mkf style1 image_color_picker" _sel="maker->image_colors">
          ${strMap((image_color) => makeImageColorItem(image_color, selected_image_color), image_colors)}
        </div>
      </div>
    </div>
  `;
}

export const makeCvImageHtml = (
  {
    image_colors,
    selected_image_color,
    cv_object,
    pattern_data,
    request_bg_removal,
    is_fitted_to_print_width,
  },
  _cv_object,
  /* cv_object, _cv_object 는 다름 */
) => {
  return html`
    <div class="cv_image_editor body image_editor_ev marpplizer_contents_wrapper">
      <div class="main_head">
        <div class="body">${T('Edit Image')}</div>
        <div class="option"><button class="marpplizer_back_btn marpplizer_back_btn_styl"></button></div>
      </div>
      <div class="section fit_to_print_width">
        <div class="head ${is_fitted_to_print_width ? 'checked' : ''}">
          <div class="option check_box"></div>
          <div class="title">${T('maker::양옆 채우기')}</div>
        </div>
      </div>
      ${makeImageColorHtml(image_colors, selected_image_color)} ${makeCvPatternHtml(pattern_data)}
      <div class="request_bg_removal section">
        <div class="head ${request_bg_removal ? 'checked' : ''}">
          <div class="option check_box"></div>
          <div class="title">${T('Background Remove Request')}</div>
        </div>
      </div>
      <div class="change_img section">
        <button>${T('Change Character')}</button>
      </div>
      <div>${makeOpacityFunctionHtml(cv_object)}</div>
      <div>${MarstagramEditorTmplS.starter()}</div>
      ${OMPDosuF.dosuImageColorPickerHtml(cv_object)}
      <!-- 현재 면이 "제한 도수 색상" 인 경우 (색상 옵션이 존재하는 경우) 이미지 컬러 피커 제공 -->
      ${BpOptionF.biz.get.currentFace.hasDosuColorOptions() ? OMPDosuF.dosuColorPickerHtml(cv_object) : ''}
      <div class="condition_agree_text section">${NewMakerConditionAgreeTextF.fn(_cv_object)}</div>
    </div>
  `;
};

export const makeCvImageFrameHtml = ({
  image_colorss,
  slide_length,
  selected_image_color,
  app_width,
}) => html`
  <div class="image_color section">
    <div class="body">
      <div class="automatic_swiper" _sel="maker->image_colors" slide_length="${slide_length}">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            ${strMap(
              (image_colors) => html`
                <div class="swiper-slide" style="padding: 0 ${($.width(window) - app_width) / 2}px">
                  ${strMap(
                    (font_color) => makeImageColorItem(font_color, selected_image_color),
                    image_colors,
                  )}
                </div>
              `,
              image_colorss,
            )}
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
`;
