import * as xlsx from 'xlsx';

/**
 * @define 엑셀 다운로드
 * @param {string[] | undefined} headers
 * @param {string[]} row_data
 * @param {string} file_name
 * @return {undefined} 엑셀 다운로드됨
 * */
export function downloadExcel({ headers, aoa_data, filename = 'download' }) {
  const book = xlsx.utils.book_new();
  const sheet = xlsx.utils.aoa_to_sheet(headers ? [headers, ...aoa_data] : aoa_data);

  xlsx.utils.book_append_sheet(book, sheet);

  if (filename.lastIndexOf('.xlsx') === -1) filename += '.xlsx';

  xlsx.writeFileXLSX(book, filename);
}

export async function parseExcel({ file }) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const aoa = xlsx.utils.sheet_to_json(sheet, {
          header: 1,
          defval: null,
          raw: false,
          blankrows: false,
          skipHidden: true,
        });

        const trimmed_aoa = aoa.map((a) =>
          a.map((d) => {
            return typeof d === 'string' ? d.trim() : d;
          }),
        );

        resolve(trimmed_aoa);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}
