import { $on } from 'fxdom/es';

export const initItemEvent = ({ sticker_editor, original_el, item_el }) => {
  $on('click', () => {
    const is_locked = sticker_editor.getIsLocked(original_el);
    if (is_locked) {
      sticker_editor.unlockEl(original_el);
      item_el.dataset.is_locked = 'false';
    } else {
      sticker_editor.lockEl(original_el);
      item_el.dataset.is_locked = 'true';
    }
  })(item_el);
};
