import { $attr, $data, $find } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { DFLglShippingDetailMuiS } from '../../S/Mui/module/DFLglShippingDetailMuiS.js';
import { checkItemsQuantity } from '../Function/item.js';

import { handleTabRendering } from '../Function/tab.event.js';
import { renderAllTabContent } from '../Function/tab.js';
import { page } from './page.js';
/*
 * 프론트엔드에서 사용될 frame 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#b847a14a76274bff9aaa6d549c5bef88
 * */
export const frame = {
  ...DFLglShippingDetailMuiS.frame, // S의 Mui에서 프레임옵션을 가져옵니다.

  root_page: page, // /F/page 옵션객체 - 필수

  height: 59, // 모바일 전용, frame 높이
  prev_frame_show: undefined, // openFrame시에 바로 하단의 frame을 html에서 삭제안함
  always_remove: true, // closeFrame 때 무조건 삭제
  animation: !MuiF.IS_IOS, // frame을 열거나 닫을때 애니메이션 (기본 - ios면 false 이외 true)
  is_modal: true, // frame 을 모달로 열때
  projection_id: '',

  appending(frame_el$) {}, // frame 엘리먼트가 만들어지면 울리는 함수
  appended(frame_el$) {}, // frame 엘리먼트가 html에 붙으면 울리는 함수
  showing(frame_el$) {}, // frame 엘리먼트 show하기 전 울리는 함수
  async showed(frame_el$) {
    const selected_tab = go(frame_el$, $find('.tab_button[selected="true"]'));

    renderAllTabContent({ el: frame_el$ });

    const current_tab_name = go(selected_tab, $attr('tab_name'));
    if (current_tab_name === 'packaging') {
      const inbound_order_table = go(frame_el$, $find('.table_right .tab_content.selected'));
      if (inbound_order_table) {
        const inbound_order = go(inbound_order_table, $data);
        const { current_status } = inbound_order;
        await checkItemsQuantity({ projection_id: frame_el$.frame_opt.projection_id, current_status });
      }
    }

    handleTabRendering({ el: frame_el$ });
  }, // frame 엘리먼트 show하고 나서 울리는 함수
  /* frame을 닫을때 울리는 함수 */
  closing(frame_el$, val) {}, // frame이 닫히기 전 울리는 함수
  hiding(frame_el$, val) {}, // frame이 가려지기 전 울리는 함수
  hided(frame_el$, val) {}, // frame이 가려진 후 울리는 함수
  removing(frame_el$, val) {}, // frame이 삭제되기 전 울리는 함수
  removed(frame_el$, val) {}, // frame이 삭제된 후 울리는 함수
  closed(frame_el$, val) {}, // frame이 닫힌 후 울리는 함수
};
