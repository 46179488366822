import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import {
  $addClass,
  $attr,
  $closest,
  $data,
  $find,
  $findAll,
  $hasClass,
  $qs,
  $removeClass,
  $siblings,
} from 'fxdom/es';
import { OMPDosuConstantS } from '../../S/Constant/module/OMPDosuConstantS.js';
import { OMPDosuF } from './module/OMPDosuF.js';
import { compact, each, go, map, tap } from 'fxjs/es';
import { makeMakerColorPickerColorName } from '../../../../Maker/F/CvTextImage/tmpl.js';
import { full_cv_text_image } from '../../../../Maker/F/text.js';
import { getBaseProductInMaker } from '../../../../Maker/F/getSth.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { NewMakerPropertyBaseProductF } from '../../../../NewMaker/Property/BaseProduct/F/Function/module/NewMakerPropertyBaseProductF.js';

// @description 도수 색상 컬러 피커 클릭 핸들러 (도수색상 옵션 존재시만 실행)
export const handleDosuColorPicker = {
  // @description 이미지 컬러 피커 선택 이벤트 핸들러
  image: async ({ currentTarget: ct }) => {
    if (!BpOptionF.biz.get.currentFace.hasDosuColorOptions()) return;

    if ($hasClass(OMPDosuConstantS.COLOR_PICKER_CN.selected, ct)) return;

    const cv_obj = G.mp.maker.active();
    const color_code = $attr('color-code', ct);
    const base_product = getBaseProductInMaker();
    if (base_product?.maker_features?.[NewMakerPropertyBaseProductConstantS.ALL_FACES_SINGLE_COLOR]) {
      await NewMakerPropertyBaseProductF.allFacesSingleColor.applyAllFacesSingleColor(color_code);
    } else {
      await applyColorPickerToCvObj({ cv_obj, apply_color: color_code });
    }
    await G.mp.maker.modified();
    /* 현재 선택 색상 컬러칩, 색상 텍스트 DOM 업데이트 */
    go(
      ct,
      $closest('.dosu'),
      (el) => [el].concat($siblings('.dosu')(el)),
      compact,
      (els) => toggleSelectedColorPicker(color_code, els),
    );
  },
  // @description 텍스트 컬러 피커 선택 이벤트 핸들러
  text: async ({ currentTarget }) => {
    if (!BpOptionF.biz.get.currentFace.hasDosuColorOptions()) return;
    const color_code = $attr('color-code', currentTarget);
    go(
      currentTarget,
      $closest('.dosu'),
      (el) => [el].concat($siblings('.dosu')(el)),
      compact,
      (els) => toggleSelectedColorPicker(color_code, els),
    );

    const cv_obj = G.mp.maker.active();
    const base_product = getBaseProductInMaker();
    if (base_product?.maker_features?.[NewMakerPropertyBaseProductConstantS.ALL_FACES_SINGLE_COLOR]) {
      await NewMakerPropertyBaseProductF.allFacesSingleColor.applyAllFacesSingleColor(color_code);
    } else {
      await applyColorPickerToCvObj({ cv_obj: G.mp.maker.active(), apply_color: color_code });
    }
    if (cv_obj._data.cv_type === 'cv_text_image_pattern') await full_cv_text_image(function (cv_text) {});
    else {
      await G.mp.maker.modified();
    }
  },
};

// @description cv_object 에 컬러 피커 색상 적용 로직 (원본 or 특정 컬러 코드)
export async function applyColorPickerToCvObj({ cv_obj, apply_color }) {
  switch (apply_color) {
    case OMPDosuConstantS.ORIGINAL_DOSU_COLOR_CODE_NAME: {
      await OMPDosuF.cvObj.delete.dosuColor({ cv_obj });
      break;
    }
    default: {
      await applyColorPickerToCvObjByDosu({
        cv_obj,
        apply_color: apply_color.toUpperCase(),
        defaultFn: async ({ cv_obj, apply_color }) => {
          // 모든 객체 도수 컬러 적용
          await OMPDosuF.cvObj.update.dosuColorToCvObj({ cv_obj, color_code: apply_color });
        },
      });
    }
  }
}

// @description 도수 수량에 따른 컬러 피커 색상 적용 로직
async function applyColorPickerToCvObjByDosu({ cv_obj, apply_color, defaultFn }) {
  const dosu_ctn = BpOptionF.biz.get.currentFace.maxDosuCount();
  switch (dosu_ctn) {
    // 1도수 면 - 모든 디자인 색상 이미지를 일괄 변경
    case 1: {
      await OMPDosuF.cvObj.update.dosuColorsToAllCvObjs({
        cv_objs: BpOptionF.makerHelper.get.allCvObjsCurrentFace(),
        color_code: apply_color,
      });
      G.mp.maker.editing_canvas() && G.mp.maker.editing_canvas().renderAll();
      break;
    }
    default: {
      await defaultFn({ cv_obj, apply_color });
    }
  }
}

function toggleSelectedColorPicker(color_code, els) {
  const selected_class_name = OMPDosuConstantS.COLOR_PICKER_CN.selected;
  go(
    els,
    tap(
      each((el) => {
        // 선택 색상 이름 변경
        const color_name_el = go(el, $find('span.color-name'));
        if (color_name_el) {
          color_name_el.textContent = ``;
        }
      }),
    ),
    compact,
    // selected remove class
    each((color_wrapper_el) =>
      go(color_wrapper_el, $findAll(`.${selected_class_name}`), each($removeClass(selected_class_name))),
    ),
    map($find(`[color-code="${color_code}"]`)),
    compact,
    // selected add class
    each($addClass(selected_class_name)),
    each((color_el) => {
      // 선택 색상 이름 변경
      const color_name_el = go(color_el, $closest('.dosu-colors'), $find('span.color-name'));
      if (color_name_el) {
        const data = $data(color_el);
        color_name_el.textContent = makeMakerColorPickerColorName(data['name' + G._en]);
      }
    }),
  );
}

export const dosuColorPickerEvent = {
  updateSelectedToOriginal: () => {
    const selected_el = $qs('.dosu-colors .color-picker.selected');
    selected_el && $removeClass('selected', selected_el);

    const original_el = $qs('.dosu-colors .color-picker[color-code="original"]');
    original_el && $addClass('selected', original_el);
  },
};
