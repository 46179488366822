import { html } from 'fxjs/es';

export const checkboxIcon = ({ klass = 'new-maker-atom__icon-checkbox-check' } = {}) => {
  return html`<svg
    class="${klass}"
    width="100%"
    height="100%"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.0965 25H4.92256C3.87039 25 3.00952 24.0571 3.00952 22.9048V5.09524C3.00952 3.94286 3.87039 3 4.92256 3H23.0965C24.1487 3 25.0095 3.94286 25.0095 5.09524V22.9048C25.0095 24.0571 24.1487 25 23.0965 25Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      class="${klass}-check"
      d="M20 11L12.6667 17.6667L8 13.6667"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> `;
};

export const emptyCheckbox = (
  { name, active, title, style: { size = 'normal' } = { size: 'normal' } } = { style: {} },
) => {
  return html`
    <label class="new-maker-atom__empty-checkbox">
      <input type="checkbox" ${active ? 'checked' : ''} name="${name || ''}" />
      <span class="new-maker-atom__empty-checkbox-box-wrapper" data-style_size="${size}">
        ${checkboxIcon({ klass: 'new-maker-atom__empty-checkbox-box' })}
      </span>
      <span class="new-maker-atom__empty-checkbox-title">${title}</span>
    </label>
  `;
};
