import { DfWaybillF } from '../../../Waybill/F/Function/module/DfWaybillF.js';
import { guards } from '../../S/Function/guards.js';
import { LabelPage, StockLabelFormat } from '../../../Stock/zpl_module/zpl_class.js';
import { each, go, map } from 'fxjs/es';
import { createItemOptionStr } from '../../S/Function/helpers.js';
import { UPCS_FORMAT_SAVE_PATH, UPCS_LABEL_FORMAT } from './label.format.js';
import { zplWriteGenerator } from '../../../Stock/Labeling/F/Function/events.js';
import { alertsLgl } from './alerts.js';
import { DfLabelF } from '../../../Label/F/Function/module/DfLabelF.js';

/*
 *  @description upcs 들의 라벨 출력
 *  @params upcss - upcs[]
 *  @returns { is_OK: Boolean }
 */
export async function printUpcsLabel({ upc }) {
  const upcss = upc?._?.up_c_ss;
  guards.nullCheck(upcss, `Cannot get upcss from upc`);

  const base_product = upc?._?.base_product;
  guards.nullCheck(base_product, `Cannot get base_product from upc`);

  if (window.ENV?.label_printer_simulate) {
    await alertsLgl.success({ title: '바코드 라벨 출력 완료' });
    return;
  }

  const device = await DfWaybillF.prepareLabelPrinter({
    setting_fn: DfWaybillF.printer_config_fn.projection_label,
    allowed_devices: ['ZD420', 'ZD421'],
  });
  guards.nullCheck(device, `Cannot retrieve print device.`);

  const upcs_label_format = createLabelFormat({ format_data: UPCS_LABEL_FORMAT });
  await DfLabelF.setFormatZplToDevice({ device, format: upcs_label_format });

  const label_page_zpl_str = go(
    upcss,
    map((upcs) => {
      const upcs_id = upcs.id;
      const quantity = upcs.quantity;
      const { bp_name, product_option_str } = getUpcsLabelNameTexts({ upcs, base_product });
      const label_data = { upcs_id, barcode: upcs_id, bp_name, product_option_str };
      const page = new LabelPage(upcs_label_format, label_data, null);
      return page.toZPLStr(quantity);
    }),
  );

  for await (const value of zplWriteGenerator(device, label_page_zpl_str)) {
    if (value === 1) {
      await alertsLgl.success({ title: '바코드 라벨 출력 완료' });
    }
  }
}

/*
 * @description 라벨 포맷 객체를 생성하고 포맷 데이터를 받아서 포매팅 데이터를 설정
 * */
function createLabelFormat({ format_data }) {
  const format = new StockLabelFormat(UPCS_FORMAT_SAVE_PATH, 203, 0, 1, false, 50);
  format.setDefaultFont('0', 20);
  formattingLglLabel({ format, format_data });

  return format;
}

/*
 * @description 라벨 포맷 객체에 요소의 포맷 형식을 주입 (side effect)
 * */
function formattingLglLabel({ format, format_data }) {
  go(
    format_data,
    each((f) => {
      const pos = f.position;
      const font = f.font;

      const field = format.makeField(
        f.title,
        f.type,
        f.static_data,
        f.mutator,
        f.tree_name,
        f.scan_code_type,
      );
      if (f.scan_code_type && f.scan_code_type.includes('code128')) {
        field.setCode128BarCode(f.scan_code_options);
      } else if (f.image) {
        const { mag, name } = f.image;
        field.setImage({ x: pos.x, y: pos.y, mag, name });
      }

      format.pushFieldData(field, pos.x, pos.y, font?.name, font?.orient, font?.height, font?.width);
    }),
  );
}

/*
 * @description LGL 상품 라벨 바코드 아래에 적을 영문 text 만들기
 * @returns { bp_name: string, product_option_str: string }
 * */
export function getUpcsLabelNameTexts({ upcs, base_product }) {
  const { base_product_color, base_product_size, spo_item: { group_names_option_names = [] } = {} } = upcs._;

  const item_option_str = createItemOptionStr({
    spo_item_id: upcs.spo_item_id,
    base_product_color,
    base_product_size,
    group_names_option_names,
  });

  return { bp_name: base_product.name_en, product_option_str: item_option_str.en };
}
