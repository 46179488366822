import { html } from 'fxjs/es';
const mui_ref_path = '/modules/VectorEditor/AcrylicFigure/StandLeg/Mobile/S/Mui/tab.js';
/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  title: '', // tab button의 title
  selected: false, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  makeData(tab) {}, // template 함수 인자에 들어갈 값, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
  template(_data) {
    return html`
      <div class="editor_container"></div>
      <div class="foreground_container">
        <div class="reposition_guide_container">
          <div class="reposition_guide_container_left_icon"></div>
          <div class="reposition_guide_container_drag_icon"></div>
          <div class="reposition_guide_container_right_icon"></div>
        </div>
        <div class="size_guide_container">
          <span class="size_guide_container_value"></span>
        </div>
      </div>
      <div class="header">
        <button class="cancel" type="button">
          ${T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::template::cancel_button')}
        </button>
        <span class="title"
          >${T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::template::title')}</span
        >
        <button class="done" type="button">
          ${T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::template::done_button')}
        </button>
      </div>
      <div class="footer">
        <div class="footer_item footer_item_size">
          <div class="footer_item_description">
            <div class="footer_item_description_icon"></div>
            <span class="footer_item_description_text">
              ${T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::template::size_description')}
            </span>
          </div>
          <div class="footer_item_control">
            <button type="button" class="footer_item_control_minus"></button>
            <span class="footer_item_control_value"></span>
            <button type="button" class="footer_item_control_plus"></button>
          </div>
        </div>
        <div class="footer_item footer_item_position">
          <div class="footer_item_description">
            <div class="footer_item_description_icon"></div>
            <span class="footer_item_description_text">
              ${T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::template::position_description')}
            </span>
          </div>
          <div class="footer_item_control">
            <button type="button" class="footer_item_control_left"></button>
            <button type="button" class="footer_item_control_right"></button>
          </div>
        </div>
      </div>
    `;
  }, // 탭에 들어갈 html 을 그리는 함수
};
