import { $setCss } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorKeyringGridPCPathEditorMuiF } from '../Mui/module/VectorEditorKeyringGridPCPathEditorMuiF.js';

export const editPath = ({
  left_menu_els,
  prev_frame_right_panel_el,
  frame_position: { top: frame_position_top, width: frame_position_width, height: frame_position_height },
  keyring_editor,
  path_el,
  onFrameHiding,
}) =>
  MuiF.openFrame(VectorEditorKeyringGridPCPathEditorMuiF.frame, (frame, page, [tab]) => {
    const frame_appending = frame.appending;
    frame.appending = (frame_el) => {
      $setCss(['z-index', '1'])(frame_el);
      return frame_appending.call(frame, frame_el);
    };
    const frame_hiding = frame.hiding;
    frame.hiding = (frame_el, val) =>
      go(onFrameHiding?.(frame_el, val), () => frame_hiding.call(frame, frame_el, val));

    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_left_menu_els = left_menu_els;
      tab_el.__mp_prev_frame_right_panel_el = prev_frame_right_panel_el;
      tab_el.__mp_frame_position_top = frame_position_top;
      tab_el.__mp_frame_position_width = frame_position_width;
      tab_el.__mp_frame_position_height = frame_position_height;
      tab_el.__mp_keyring_editor = keyring_editor;
      tab_el.__mp_path_el = path_el;
      return tab_appending.call(tab, tab_el);
    };
  });
