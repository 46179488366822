import { makeDfProjectionShipEvent } from '../../../../../modules/Df/Projection/List/F/event.js';
import { makeDfProjectionItemsHtml } from '../../../../../modules/Df/Projection/List/F/tmpl.js';

!(function (LF) {
  const tab = {
    title: '<span class="projection_count"></span>',
    // selected: true,
    tab_name: 'projection.detail_user_projections',
    hiding: function (el_don_tab) {
      const el_don_page = $.closest(el_don_tab, '.don_page');
      const el_don_wrapper = $.find1(el_don_page, '>.don_wrapper');
      $.attr(el_don_wrapper, { prev_scroll_top: $.scroll_top(el_don_wrapper) });
      $.css($.find1(el_don_tab, '>.don_wrapper'), { visibility: 'hidden' });
    },
    template: () => '',
    appended: makeDfProjectionShipEvent,
    showed: async (child) => {
      $.don_loader_start();

      const el_don_tab = $.closest(child, '.don_tab');
      // const { tab_opt } = el_don_tab;
      const el_don_frame = $.closest(el_don_tab, '.don_frame');
      const { frame_opt } = el_don_frame;
      const el_don_wrapper = $.find1(el_don_tab, '>.don_wrapper');
      const el_don_page_don_wrapper = $.find1(el_don_frame, '.don_page >.don_wrapper');
      const projection = box.sel('df/projection/detail->projection') || {};
      const { id, updated_at } = projection;
      const need_change = frame_opt.projection_id != id;
      const new_projection = need_change
        ? await $.get('/@api/projection/take', { id: frame_opt.projection_id })
        : (await $.get('/@api/projection/check_updated', { id, updated_at })) || projection;

      box.set('df/projection/detail', { projection: new_projection });

      $.html(
        $.find1(el_don_frame, '.projection_count'),
        `${new_projection._.user.name}님 주문 (${new_projection._.user_projection_count})`,
      );

      if (need_change)
        _p.each($.find(el_don_frame, '.don_tab'), (el_don_tab) => (el_don_tab.tab_opt.updated_at = null));

      if ($.find1(el_don_wrapper, '>*')) {
        $.css(el_don_wrapper, { visibility: 'visible' });
        $.scroll_top2(el_don_page_don_wrapper, $.attr(el_don_page_don_wrapper, 'prev_scroll_top'));
        $.don_loader_end();
        return;
      }

      G.df.projection.detail.render_sidebar(el_don_tab);

      const p = new_projection;

      box.set(
        'df/projection/detail_user_projections',
        await $.get('/@api/projection/list', {
          status: 'all',
          order_by: 'DESC',
          user_id: p.user_id,
        }),
      );
      $.html(
        el_don_wrapper,
        pug`
        .user_projections[lang="${p.lang || 'en'}"]
          .top_id 
            a[href="/projection/detail/${p.id}" target="_blank"] ${p.lang == 'kr' ? '[국문]' : '[영문]'} #${
          p.id
        }
          h2 ${p._.user.name}님 주문 (${p._.user_projection_count})
          .projection_list_container.don_pgn_container[_sel="df/projection/detail_user_projections->projections" prj_product_mode="up_list"]
            ul.df_grid.prj.don_pgn_wrap ${makeDfProjectionItemsHtml(
              box.sel('df/projection/detail_user_projections->projections'),
            )}
            .don_pagination
      `,
      );

      _p.go(
        el_don_wrapper,
        $.find1('.projection_list_container'),
        G.mp.maker.draw_product_face_in_ups,
        _p.f('G.df.projection.detail.up_item_init'),
        G.df.task.projection.init,
      );

      await $.ui.pagination(
        $.find1(el_don_tab, '.projection_list_container'),
        {
          url: '/@api/projection/list',
          render_server: true,
          data: { limit: 10 },
          real_data: () => ({
            is_page: true,
            status: 'all',
            order_by: 'DESC',
            user_id: new_projection.user_id,
          }),
          total_count: function () {
            return window.box.sel('df/projection/detail_user_projections->pagination_total_count') || 1;
          },
          is_url_state: false,
          paging_animate: function (don_pgn_wrap, new_items) {
            return _p.go(
              _p,
              _p.tap(() => $.scrollTop(el_don_page_don_wrapper, 0)),
              _p($.html, don_pgn_wrap, new_items),
              G.mp.maker.draw_product_face_in_ups,
            );
          },
        },
        makeDfProjectionItemsHtml,
      );

      $.don_loader_end();
    },
  };
  G.df.projection.detail_user_projections = { tab };
})({});
