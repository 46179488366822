import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

export const filterItem = (filter_list) => {
  return html`
    <div class="omp-product-list-filter-item-modal">
      <div class="omp-product-list-filter-item-modal__header">
        <div class="omp-product-list-filter-item-modal__title">${filter_list.name}</div>
      </div>
      <div class="omp-product-list-filter-item-modal__body">
        <div class="omp-product-list-filter-item-modal__filter-items">
          ${strMap((fi) => {
            return html` <button
              class="omp-product-list-filter-item-modal__filter-item"
              data-filter_list_id="${fi.filter_list_id}"
              data-id="${fi.id}"
            >
              <div class="omp-product-list-filter-item-modal__filter-item-name">${fi.name}</div>
              <div class="omp-product-list-filter-item-modal__filter-item-icon" data-checked="${fi.checked}">
                ${OMPCoreAtomTmplS.arrowCheckIcon()}
              </div>
            </button>`;
          }, filter_list._.filter_items)}
        </div>
      </div>
    </div>
  `;
};
