import { find, go, html, some } from 'fxjs/es';
import { $addClass, $delegate, $hasClass, $qs, $removeClass } from 'fxdom/es';
import { errorHandlingEventWrapper } from '../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { getBaseProductColorFacesInMaker } from './getSth.js';
import { MuiF } from '../../Mui/F/Function/module/MuiF.js';
import { SimpleModalMuiF } from '../../SimpleModal/F/Mui/module/SimpleModalMuiF.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import { LOC_OPT_ACTIVATE_ATTR_NAME, LOC_OPT_ENABLE_ATTR_NAME, LOC_OPT_SEEN_PROOF } from '../S/constant.js';

function getMakerCurrentCanvasPf() {
  const current_bpf_id = G.mp.maker.editing_canvas().bpf_id;
  const pf2 = box.sel('maker->product_color->product_faces2->value');

  if (current_bpf_id == null || pf2 == null) return null;

  return find((pf) => pf.bpf_id === current_bpf_id, pf2);
}

function getMakerCurrentBpf() {
  const current_bpf_id = G.mp.maker.editing_canvas().bpf_id;
  const bpcfs = getBaseProductColorFacesInMaker();
  if (current_bpf_id == null || bpcfs == null) return null;

  const bpcf = find((bpcf) => bpcf.base_product_face_id === current_bpf_id, bpcfs);
  if (bpcf == null) return null;

  return bpcf._.base_product_face;
}

function getCurrentFaceIsActivateLocOptimizationFromPf() {
  const pf = getMakerCurrentCanvasPf();
  if (pf == null) return false;

  return pf[LOC_OPT_ACTIVATE_ATTR_NAME] ?? false;
}

export function getCurrentFaceIsEnableLocOptimizationFromBpf() {
  const bpf = getMakerCurrentBpf();
  if (bpf == null) return false;

  return bpf[LOC_OPT_ENABLE_ATTR_NAME] ?? false;
}

// @description 면의 위치 조정 요청 관련 데이터 갱신 (요청 가능 여부, 요청 적용 여부)
export function setMakerCurrentFaceLocOptimizationData() {
  // 위치 조정 (보편적 위치) 요청 가능 여부 갱신
  setMakerCurrentFaceIsLocOptimizationEnable();

  // 위치 조정 (보편적 위치) 요청 적용 여부 갱신
  setMakerCurrentFaceIsLocOptimizationActive();
}

// @description 위치 조정 요청 가능 여부 Setter
export function setMakerCurrentFaceIsLocOptimizationEnable() {
  const is_face_loc_optimization_enabled = getCurrentFaceIsEnableLocOptimizationFromBpf();
  const maker_frame_el = $qs('#maker_frame');
  if (maker_frame_el) {
    maker_frame_el.setAttribute(`data-${LOC_OPT_ENABLE_ATTR_NAME}`, is_face_loc_optimization_enabled);
    if (is_face_loc_optimization_enabled === false) {
      const pf = getMakerCurrentCanvasPf();
      if (pf) {
        pf[LOC_OPT_ACTIVATE_ATTR_NAME] = false;
        maker_frame_el.setAttribute(`data-${LOC_OPT_ACTIVATE_ATTR_NAME}`, false);
      }
    }
  }
}
// @description 위치 조정 요청 가능 여부 Getter
export function getMakerDomCurrentPfIsLocOptimizationEnable() {
  const maker_frame_el = $qs('#maker_frame');
  if (maker_frame_el) {
    return maker_frame_el.dataset[LOC_OPT_ENABLE_ATTR_NAME];
  }
}

// @description 위치 조정 요청 적용 여부 Setter
export function setMakerCurrentFaceIsLocOptimizationActive() {
  const is_active_loc_optimization = getCurrentFaceIsActivateLocOptimizationFromPf();
  const maker_frame_el = $qs('#maker_frame');
  if (maker_frame_el) {
    maker_frame_el.setAttribute(`data-${LOC_OPT_ACTIVATE_ATTR_NAME}`, is_active_loc_optimization);
  }
}

// @description 위치 조정 요청 적용 여부 Getter
export function getMakerDomCurrentPfIsLocOptimizationActive() {
  const maker_frame_el = $qs('#maker_frame');
  if (maker_frame_el) {
    return maker_frame_el.dataset[LOC_OPT_ACTIVATE_ATTR_NAME];
  }
}

// @description 위치 조정 설정 가이드 프레임 열기
export async function openFrameLocOptimization() {
  const opened_at = new Date();
  const pf = getMakerCurrentCanvasPf();

  /* 면에 디자인이 없는 경우에는 보편적 위치 설정 불가 경고 */
  if (pf == null || !pf.designs.length) {
    $.alert(`${T('maker::locOpt::noDesignWarning')}`);
    return;
  }

  await MuiF.openFrame(SimpleModalMuiF.frame, async (frame, page, [tab]) => {
    frame.is_modal = !UtilF.isLegacyMobile();
    tab.appended = (tab_el) => {
      go(
        tab_el,
        $delegate('click', 'button.close', MuiF.closeFrame),
        $delegate(
          'click',
          'button.loc_optimization_toggler',
          errorHandlingEventWrapper((e) => {
            const ct = e.currentTarget;
            if ($hasClass('not_allowed', ct)) {
              return;
            }
            /* pf 에 "보편적 위치" 적용 여부 데이터 반영 */
            pf[LOC_OPT_ACTIVATE_ATTR_NAME] = !pf[LOC_OPT_ACTIVATE_ATTR_NAME];
            MuiF.closeFrame();
          }),
        ),
        $delegate(
          'click',
          '.agreement_loc_optimization',
          errorHandlingEventWrapper((e) => {
            const ct = e.currentTarget;
            const is_locked = $hasClass('lock', ct);
            if (is_locked) return;

            const is_checked = $hasClass('checked', ct);
            const request_el = $qs('.loc_optimization_toggler');
            if (is_checked) {
              $removeClass('checked', ct);
              request_el && $addClass('not_allowed', request_el);
            } else {
              $addClass('checked', ct);
              request_el && $removeClass('not_allowed', request_el);
            }
          }),
        ),
      );
    };
    frame.closed = () => {
      /* 유저가 위치 조정 요청 내용을 보았다는 증거 */
      if (!pf[LOC_OPT_SEEN_PROOF]) {
        pf[LOC_OPT_SEEN_PROOF] = new Date() - opened_at > 1500;
      }

      /* 위치 조정 요청 설정 팝업이 닫힐 때 "보편적 위치" 활성화 여부 DOM 갱신 */
      setMakerCurrentFaceIsLocOptimizationActive();
    };
    const IMG_SRC_PATH = '//s3.marpple.co/files/u_1187078/2023/8/original/';
    tab.makeData = () => {
      return {
        modal_name: 'loc_optimization',
        header: `${T('maker::locOpt::guideTitle')}`,
        body: html`
          <div class="contents">
            <div class="thumbnails">
              <div class="example-thumbnail front-left">
                <img
                  src="${IMG_SRC_PATH}08a37c75ff683bf0f6eacfaf10aad262cdaa56e73.png"
                  alt="front-left-example"
                />
                <label>${T('maker::locOpt::frontLeft')}</label>
              </div>
              <div class="example-thumbnail front-left">
                <img
                  src="${IMG_SRC_PATH}15b8d59a18d3e81d0806b3301826463e9fc7dd951.png"
                  alt="front-right-example"
                />
                <label>${T('maker::locOpt::frontRight')}</label>
              </div>
              <div class="example-thumbnail front-center">
                <img
                  src="${IMG_SRC_PATH}dcb2caf4dc246d1ed550d8df45a52bdc16cce59e2.png"
                  alt="front-center-example"
                />
                <label>${T('maker::locOpt::frontCenter')}</label>
              </div>
              <div class="example-thumbnail back-center">
                <img
                  src="${IMG_SRC_PATH}52f38e3804eb138f3842ba3281a62feabb00e3fa1.png"
                  alt="back-center-example"
                />
                <label>${T('maker::locOpt::backCenter')}</label>
              </div>
            </div>
            <div class="description">
              <p class="text">${T('maker::locOpt::guideDescription')}</p>
              <p class="warning">${T('maker::locOpt::guideWarning')}</p>
            </div>
            <div class="agreement_loc_optimization ${pf[LOC_OPT_ACTIVATE_ATTR_NAME] ? 'lock checked' : ''}">
              <div class="check_box"></div>
              <div class="title">${T('maker::locOpt::guideWarningCheckbox')}</div>
            </div>
          </div>
        `,
        footer: html`
          <button type="button" class="close">${T('maker::locOpt::close')}</button>
          <button
            type="button"
            class="loc_optimization_toggler ${pf[LOC_OPT_ACTIVATE_ATTR_NAME] ? '' : 'not_allowed'}"
          >
            ${pf[LOC_OPT_ACTIVATE_ATTR_NAME]
              ? T('maker::locOpt::requestCancel')
              : T('maker::locOpt::requestActivation')}
          </button>
        `,
      };
    };
  });
}

export async function alertWhenIsLocOptimizationResetByBpChange({ pf2_values }) {
  const is_reset = isLocOptimizationActivateInitializeByBpChange({ pf2_values });
  is_reset && (await $.alert(T('maker::locOpt::resetWarning')));
}

export function isLocOptimizationActivateInitializeByBpChange({ pf2_values }) {
  if (pf2_values == null || pf2_values.length === 0) {
    return false;
  }
  return some((pf) => pf[LOC_OPT_ACTIVATE_ATTR_NAME], pf2_values);
}
