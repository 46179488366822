import { getCurrentFcanvas } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { getItsSf } from '../../../../Maker/F/getSth.js';

export const locateToPrintArea = (active) => {
  const {
    print: { px },
  } = getItsSf(getCurrentFcanvas());
  active.scaleToWidth(px.width);
  active.set({ top: px.top, left: px.left });
  active.canvas.renderAll();
};
// G.locateToPrintArea = () => locateToPrintArea(G.mp.maker.active());
