import axios from 'axios';

export async function isValidUrl({ endpoint_url }) {
  try {
    const response = await axios.head(endpoint_url);
    return response.status === 200;
  } catch (err) {
    console.error('Error checking URL:', err);
    return false;
  }
}
