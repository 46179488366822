import { $delegate } from 'fxdom/es';
import { go, html } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { DfInhouseF } from '../../../../Inhouse/F/Function/module/DfInhouseF.js';
import { alertsLgl } from '../../../F/Function/alerts.js';
import { DfLglF } from '../../../F/Function/module/DfLglF.js';
import { loadProductsImage } from '../../../ShippingDetail/F/Function/item.js';
import { handleResizeTableHeader } from '../../../ShippingDetail/F/Function/table.event.js';
import { DfLglTrackingDetailMuiF } from '../../../TrackingDetail/F/Mui/module/DfLglTrackingDetailMuiF.js';
import { DfLglOutboundProductListMuiS } from '../../S/Mui/module/DfLglOutboundProductListMuiS.js';
import { makeOutboundProductListHtml } from '../../S/Tmpl/tmpl.outbound_product_list.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...DfLglOutboundProductListMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {
    loadProductsImage(tab_el);

    go(
      tab_el,
      $delegate('click', '.tab_item', DfLglF.handleTabChange),
      $delegate('click', '.tracking_detail', async ({ currentTarget: ct }) => {
        DfInhouseF.lottie_loader.start('dots', null, null, null);
        try {
          // 현지 배송 상세 modal
          await MuiF.openFrame(DfLglTrackingDetailMuiF.frame, async (frame, page, [tab]) => {
            tab.makeData = () => ({
              data: [
                { date: '2023.12.21 16:28', status: '수취인에게 배달되었습니다.' },
                {
                  date: '2023.12.21 16:28',
                  status: '위탁 운송업체를 통해 배송될 예정이며, 서명 확인은 가능합니다.',
                },
                { date: '2023.12.21 16:28', status: '물품이 목적지 또는 경유지로 발송되었습니다.' },
                {
                  date: '2023.12.21 16:28',
                  status: '발송물이 현재 위치에 표기된 국가에서 다음 연결을 위해 대기 중입니다.',
                },
                { date: '2023.12.21 16:28', status: '목적지(또는 경유지)에서 통관이 완료되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
                { date: '2023.12.21 16:28', status: '물품 정보가 접수되었습니다.' },
              ],
            });
          });
        } catch (err) {
          console.error(err);
          await alertsLgl.error({ err });
        } finally {
          DfInhouseF.lottie_loader.end();
        }
      }),
      $delegate('change', '.base_switch', function ({ currentTarget: ct }) {
        const base = ct.value;
        tab_el.innerHTML = html`<div class="don_wrapper">
          ${makeOutboundProductListHtml({ data: [], base })}
        </div>`;

        loadProductsImage(tab_el);
        handleResizeTableHeader({ el: tab_el });
      }),
    );

    handleResizeTableHeader({ el: tab_el });
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
