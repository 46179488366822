import { createCanvasElement, makeCloneCanvas, makeGradientCanvas } from '../../../../Canvas/S/util.js';
import {
  makeShadowAndGeometryCanvas,
  masking,
  white,
} from '../../../BaseProducts/KeyRing/F/Function/private/composite.js';
import { go } from 'fxjs/es';

function makeMarginCanvas(canvas, margin_x, margin_y) {
  const c = document.createElement('canvas');
  c.width = canvas.width + margin_x;
  c.height = canvas.height + margin_y;
  const ctx = c.getContext('2d');
  ctx.drawImage(canvas, 0, margin_y);
  return c;
}
export async function acrylicEffect({ outline_canvas, design_canvas }) {
  const margin_x = 9;
  const margin_y = 3;
  outline_canvas = makeMarginCanvas(outline_canvas, margin_x, margin_y);
  design_canvas = makeMarginCanvas(design_canvas, margin_x, margin_y);
  const c = createCanvasElement({
    width: outline_canvas.width,
    height: outline_canvas.height,
  });
  const ctx = c.getContext('2d');
  ctx.drawImage(design_canvas, 0, 0);
  ctx.drawImage(
    await makeShadowAndGeometryCanvas(
      makeCloneCanvas(outline_canvas),
      {
        right_solid_opacity: 0.5,
        right_solid_top_hex: '#c1c1c1',
        right_solid_center_hex: '#696969',
        right_solid_bottom_hex: '#c1c1c1',
        right_solid_x: margin_x,
        right_solid_y: -margin_y,
      },
      {
        left_solid_opacity: 0.5,
        left_solid_top_hex: '#c1c1c1',
        left_solid_center_hex: '#696969',
        left_solid_bottom_hex: '#c1c1c1',
        left_solid_x: 0,
        left_solid_y: 0,
      },
      225,
      1,
    ),
    0,
    0,
  );
  ctx.drawImage(
    await go(
      makeGradientCanvas(
        c,
        [
          {
            offset: 0,
            hex: 'rgba(200, 200, 200, 0)',
          },
          {
            offset: 0.4,
            hex: 'rgba(200, 200, 200, 0.2)',
          },
          {
            offset: 0.5,
            hex: 'rgba(255, 255, 255, 0.2)',
          },
          {
            offset: 0.6,
            hex: 'rgba(200, 200, 200, 0.2)',
          },
          {
            offset: 1,
            hex: 'rgba(200, 200, 200, 0)',
          },
        ],
        { x1: 0, y1: 0, x2: c.width, y2: c.height },
      ),
      async (c) => masking({ mask: outline_canvas, target: c }),
    ),
    0,
    0,
  );
  const white_c = white(outline_canvas, 1);
  ctx.drawImage(white_c, 0, 0);
  return c;
  // const { top, left, width, height } = trimCanvasSize(c);
  // const image_data = ctx.getImageData(left, top, width, height);
  // const result_canvas = document.createElement('canvas');
  // result_canvas.width = image_data.width;
  // result_canvas.height = image_data.height;
  // result_canvas.getContext('2d').putImageData(image_data, 0, 0);
}
