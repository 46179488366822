import { $closest, $find } from 'fxdom/es';
import { go, map, filter } from 'fxjs';
import Swal from 'sweetalert2';
import { UtilArrayS } from '../../../../../../modules/Util/Array/S/Function/module/UtilArrayS.js';
import {
  getAllPrintableFilesPressTypes,
  getProjectionBpSizesInfo,
  getProductFacesWithDesign,
  getProductFacesWithPrintables,
  isAllPrintableFilesTrackable,
} from './dataHandler.js';
import { createWorkOrderSheetPdf } from './pdf.js';
import { ConfigSentryF } from '../../../../../../modules/Config/Sentry/F/Function/module/ConfigSentryF.js';

export async function workOrderSheetEventHandler(e) {
  const ct = e.currentTarget;

  const prj_el = $closest('.prj_item', ct);
  const up_item_el = $closest('.up_item', ct);
  const up_item = box.sel(up_item_el);

  try {
    /* 작업지시서 이벤트 기획
     *  - 현재 인쇄용 시안 중 press type 을 선택
     * */

    const notes = [
      box.sel(prj_el).special_request,
      ...(up_item.memo ? Object.values(up_item.memo) : []),
    ].filter((m) => m !== '' && m != null);

    const projection_bp_sizes = go(
      up_item_el,
      $find('.up_c_s_list'),
      box.sel,
      filter((upc) => upc.quantity > 0),
      map((upc) => upc._.base_product_size),
    );

    const firstUpcDesignPf2 = up_item._.up_cs[0]._.product_color.product_faces2.value;
    const bp_sizes_info = getProjectionBpSizesInfo({ projection_bp_sizes, pf2: firstUpcDesignPf2 });

    const printable_pc_el = $find('.printable.product_color', up_item_el);

    if (printable_pc_el == null) {
      $.don_loader_start();

      // 인쇄용 시안이 없는 경우 -> 디자인 기반으로 작업지시서 생성

      const pf2_for_pdf = await getProductFacesWithDesign({ pf2: firstUpcDesignPf2, bp_sizes_info });

      await createWorkOrderSheetPdf({ up_item, pf2: pf2_for_pdf, notes });
    } else {
      // 인쇄용 시안이 있는 경우 -> 인쇄용 시안 기반으로 작업지시서 생성

      // 1. 인쇄용 시안에 등록된 모든 press type 을 가져온다.
      const printable_pc = box.sel(printable_pc_el);
      const printable_pf2 = printable_pc?.product_faces2?.value;
      const all_press_types = getAllPrintableFilesPressTypes({ pf2: printable_pf2 });

      if (printable_pf2 == null || UtilArrayS.isArrayOk(all_press_types) === false) {
        await Swal.fire({
          title: 'Press Type 선택 오류',
          icon: 'error',
          html: `1. 인쇄용 시안 파일이 업로드 되어 있는지 확인하세요.<br>2. 작업 방식 지정이 올바르게 되었는지 확인해 주세요.`,
          showConfirmButton: true,
        });
        return;
      }

      const isAllPrintablesSinglePressType = all_press_types.length === 1;

      let selected_press_type_query = {};

      if (isAllPrintablesSinglePressType) {
        selected_press_type_query.isDismissed = false;
        selected_press_type_query.isConfirmed = true;
        selected_press_type_query.value = 0; // query selector index
      } else {
        // 2. 현재 작업지시서로 작업할 press type 을 선택
        selected_press_type_query = await Swal.fire({
          title: '작업 방식을 선택해 주세요.',
          input: 'radio',
          width: 680,
          confirmButtonText: '확인',
          cancelButtonText: '닫기',
          showCancelButton: true,
          inputOptions: all_press_types,
          inputValidator: (value) => {
            if (!value) {
              return '작업 방식을 선택해 주세요.';
            }
          },
        });
      }

      if (selected_press_type_query.isDismissed === false) {
        // 선택된 press type 만 작업지시서에 포함할 것인지 묻는 UI 는 기획에서 일단 제거, 항상 포함으로 변경

        // 3. 작업지시서 면 별 썸네일에 모든 디자인 이미지를 포함할건지, 선택된 press type 만 포함할건지 물어봄
        const selected_press_type = all_press_types[selected_press_type_query.value];

        const is_all_printable_files_trackable = isAllPrintableFilesTrackable({
          printable_pf2,
          selected_press_type,
        });

        if (!isAllPrintablesSinglePressType && is_all_printable_files_trackable === true) {
          const image_include_type_query = await Swal.fire({
            title: '시안 이미지 포함 방식을 선택해 주세요.',
            width: 680,
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: '닫기',
            confirmButtonText: '모든 시안 넣기',
            denyButtonText: '선택된 작업방식만 시안만 넣기',
          });

          if (image_include_type_query.isDismissed === false) {
            const is_include_all_printable_files = image_include_type_query.isConfirmed;

            // 3. 선택된 작업 방식의 "면" 별 정보를 가져온다.
            if (selected_press_type_query.isConfirmed === true) {
              $.don_loader_start();

              const pf2_for_pdf = await getProductFacesWithPrintables({
                printable_pf2,
                bp_sizes_info,
                selected_press_type,
                is_include_all_printable_files,
              });

              await createWorkOrderSheetPdf({ up_item, pf2: pf2_for_pdf, notes });
            }
          }
        } else {
          $.don_loader_start();

          const pf2_for_pdf = await getProductFacesWithPrintables({
            printable_pf2,
            bp_sizes_info,
            selected_press_type,
            is_include_all_printable_files: true,
          });

          await createWorkOrderSheetPdf({ up_item, pf2: pf2_for_pdf, notes });
        }
      }
    }
  } catch (e) {
    console.error(e);
    $.alert(`에러 발생: 개발팀 문의<br>${e.message}`);
    ConfigSentryF.error(new Error(e), { option: { extra: { up_item } } });
  } finally {
    $.don_loader_end();
  }
}
