export const TOP_MENU = {
  HISTORY_RESET: {
    ID: 'reset',
    NAME: 'Revert',
  },
  HISTORY_UNDO: {
    ID: 'undo',
    NAME: 'Undo',
  },
  HISTORY_REDO: {
    ID: 'redo',
    NAME: 'Redo',
  },
  TOGGLE_LAYERS_MODE: {
    ID: 'layers',
    NAME: 'Layers',
  },
  DELETE: {
    ID: 'trash',
    NAME: 'Delete',
  },
  FORWARD: {
    ID: 'forward',
    NAME: 'Forward',
  },
  BACKWARD: {
    ID: 'backward',
    NAME: 'Backward',
  },
  GROUP: {
    ID: 'group',
    NAME: 'Group',
  },
  UNGROUP: {
    ID: 'ungroup',
    NAME: 'Ungroup',
  },
  LOCK: {
    ID: 'lock',
    NAME: 'Lock',
  },
  UNLOCK: {
    ID: 'unlock',
    NAME: 'Unlock',
  },
  FLIP_HORIZONTAL: {
    ID: 'flip_h',
    NAME: 'Horizontal',
  },
  FLIP_VERTICAL: {
    ID: 'flip_v',
    NAME: 'Vertical',
  },
  MOVE_TO_LEFT_END: {
    ID: 'group_left',
    NAME: 'Left',
  },
  MOVE_TO_RIGHT_END: {
    ID: 'group_right',
    NAME: 'Right',
  },
  MOVE_TO_TOP_END: {
    ID: 'group_top',
    NAME: 'Top',
  },
  MOVE_TO_BOTTOM_END: {
    ID: 'group_bottom',
    NAME: 'Bottom',
  },
  MOVE_TO_HORIZONTAL_CENTER: {
    ID: 'group_center_h',
    NAME: 'Center',
  },
  MOVE_TO_VERTICAL_CENTER: {
    ID: 'group_center_v',
    NAME: 'Middle',
  },
};
