import { go } from 'fxjs/es';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';

export function toolSelect({ tab_el }) {
  go(tab_el);
}

export function activateSelectionTool({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });

  const selection_tool = DfImageEditorF.prepareTool({
    paper_scope,
    tool_name: DfImageEditorLibF.tools.selection.name,
    eventHandlers: {
      activate: () => {
        DfImageEditorF.activateToolLayer({ paper_scope });
      },
      deactivate: () => {
        DfImageEditorF.deactivateToolLayer({ paper_scope });
      },
      mousedown: (e) => {
        if (e.event.button === 0) {
          // const hitResult = paper_scope.project.hitTestAll(e.point);
          // console.log({ hit: hitResult, e, item: e.item });
        }
      },
    },
  });

  selection_tool.activate();
}
