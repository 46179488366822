import { VectorEditorStickerGridPCCopyGridMuiS } from '../../S/Mui/module/VectorEditorStickerGridPCCopyGridMuiS.js';
import { each, go, takeL, tap } from 'fxjs/es';
import { $findAll, $on, $setCss } from 'fxdom/es';
import { VectorEditorStickerGridPCCopyGridEventF } from '../Event/module/VectorEditorStickerGridPCCopyGridEventF.js';
import { PCRightFixedPanelF } from '../../../../../../../PCRightFixedPanel/F/Function/module/PCRightFixedPanelF.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerGridPCCopyGridMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {
    tab_el$.__mp_prev_frame_right_panel_el = tab_el$.__mp_prev_frame_right_panel_el ?? null;
    tab_el$.__mp_frame_position_top = tab_el$.__mp_frame_position_top ?? 0;
    tab_el$.__mp_frame_position_width = tab_el$.__mp_frame_position_width ?? 0;
    tab_el$.__mp_frame_position_height = tab_el$.__mp_frame_position_height ?? 0;
    tab_el$.__mp_grids = tab_el$.__mp_grids ?? new Map();
    tab_el$.__mp_grid_sticker_editor = tab_el$.__mp_grid_sticker_editor ?? null;

    go(
      tab_el$,
      tap(
        $findAll(`.header .back`),
        each(
          $on(
            'click',
            VectorEditorStickerGridPCCopyGridEventF.handleHeaderBackButtonClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .cancel`),
        each(
          $on(
            'click',
            VectorEditorStickerGridPCCopyGridEventF.handleFooterCancelButtonClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .done`),
        each(
          $on(
            'click',
            VectorEditorStickerGridPCCopyGridEventF.handleFooterDoneButtonClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {
    $setCss(['top', `${tab_el$.__mp_frame_position_top}px`])(tab_el$);
    $setCss(['width', `${tab_el$.__mp_frame_position_width}px`])(tab_el$);
    $setCss(['height', `${tab_el$.__mp_frame_position_height}px`])(tab_el$);

    return go(
      tab_el$,
      $findAll(`.container`),
      takeL(1),
      each((container_el) => {
        if (tab_el$.__mp_prev_frame_right_panel_el != null) {
          return PCRightFixedPanelF.showPcRightFixedPanel({
            right_el: container_el,
            left_el: tab_el$.__mp_prev_frame_right_panel_el,
          });
        }
      }),
    );
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {
    tab_el$.__mp_grid_sticker_editor?.startCopy?.();
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {
    if (!v) {
      tab_el$.__mp_grid_sticker_editor?.abortCopy?.();
    }

    return go(
      tab_el$,
      $findAll(`.container`),
      takeL(1),
      each((container_el) => {
        if (tab_el$.__mp_prev_frame_right_panel_el != null) {
          return PCRightFixedPanelF.hidePcRightFixedPanel({
            right_el: container_el,
            left_el: tab_el$.__mp_prev_frame_right_panel_el,
          });
        }
      }),
    );
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {
    tab_el$.__mp_prev_frame_right_panel_el = null;
    tab_el$.__mp_frame_position_top = 0;
    tab_el$.__mp_frame_position_width = 0;
    tab_el$.__mp_frame_position_height = 0;
    tab_el$.__mp_grids = new Map();
    tab_el$.__mp_grid_sticker_editor = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
