import { $appendTo, $remove, $setCss } from 'fxdom/es';
import { equals, go, identity, tap } from 'fxjs/es';
import { hidePcRightFixedPanel, showPcRightFixedPanel } from './show_hide.js';

const SYMBOL_STACK = Symbol(`SYMBOL_STACK`);

export const init = (container_el, { el, appended, removed }) => {
  const home = { el, appended, removed };
  container_el[SYMBOL_STACK] = [];
  container_el[SYMBOL_STACK].push(home);
  go(home.el, $appendTo(container_el), home.appended ? tap(home.appended) : identity);
};
function makeBlank() {
  return go(
    document.createElement('div'),
    $setCss({
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 100,
      bottom: 0,
    }),
    $appendTo(document.body),
  );
}
export const push = async (container_el, item) => {
  const blank = makeBlank();
  try {
    const stack = container_el[SYMBOL_STACK];
    const idx = stack.length - 1;
    stack.push(item);
    await go(item.el, $appendTo(container_el), item.appended ? tap(item.appended) : identity, async (el) => {
      const prev_el = stack[idx].el;
      await showPcRightFixedPanel({ left_el: prev_el, right_el: el });
    });
  } finally {
    $remove(blank);
  }
};

export const pop = async (container_el) => {
  const blank = makeBlank();
  try {
    const stack = container_el[SYMBOL_STACK];
    if (stack.length <= 1) {
      return;
    }
    const idx = stack.length - 1;
    const item = stack[idx];
    stack.pop();
    await go(
      item.el,
      async (el) => {
        const prev_el = stack[stack.length - 1].el;
        await hidePcRightFixedPanel({ left_el: prev_el, right_el: el });
        return el;
      },
      $remove,
      item.removed ? tap(item.removed) : identity,
    );
  } finally {
    $remove(blank);
  }
};

export const replace = (container_el, item) => {
  const blank = makeBlank();
  try {
    const stack = container_el[SYMBOL_STACK];
    const idx = stack.length - 1;
    const prev_item = stack[idx];

    if (equals(item.el)(prev_item.el)) {
      return;
    }

    stack.pop();
    stack.push(item);
    go(item.el, $appendTo(container_el), item.appended ? tap(item.appended) : identity);
    go(prev_item.el, $remove, prev_item.removed ? tap(prev_item.removed) : identity);
  } finally {
    $remove(blank);
  }
};

export const add = async (container_el, item) => {
  const stack = container_el[SYMBOL_STACK];
  if (stack.length <= 1) {
    return push(container_el, item);
  } else {
    return replace(container_el, item);
  }
};
