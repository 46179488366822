export const REMOVE_ATTR_LIST_REGEXP = new RegExp(
  `(${[
    // Event handlers
    'onauxclick',
    'onafterprint',
    'onbeforematch',
    'onbeforeprint',
    'onbeforeunload',
    'onbeforetoggle',
    'onblur',
    'oncancel',
    'oncanplay',
    'oncanplaythrough',
    'onchange',
    'onclick',
    'onclose',
    'oncontextlost',
    'oncontextmenu',
    'oncontextrestored',
    'oncopy',
    'oncuechange',
    'oncut',
    'ondblclick',
    'ondrag',
    'ondragend',
    'ondragenter',
    'ondragleave',
    'ondragover',
    'ondragstart',
    'ondrop',
    'ondurationchange',
    'onemptied',
    'onended',
    'onerror',
    'onfocus',
    'onformdata',
    'onhashchange',
    'oninput',
    'oninvalid',
    'onkeydown',
    'onkeypress',
    'onkeyup',
    'onlanguagechange',
    'onload',
    'onloadeddata',
    'onloadedmetadata',
    'onloadstart',
    'onmessage',
    'onmessageerror',
    'onmousedown',
    'onmouseenter',
    'onmouseleave',
    'onmousemove',
    'onmouseout',
    'onmouseover',
    'onmouseup',
    'onoffline',
    'ononline',
    'onpagehide',
    'onpageshow',
    'onpaste',
    'onpause',
    'onplay',
    'onplaying',
    'onpopstate',
    'onprogress',
    'onratechange',
    'onreset',
    'onresize',
    'onrejectionhandled',
    'onscroll',
    'onscrollend',
    'onsecuritypolicyviolation',
    'onseeked',
    'onseeking',
    'onselect',
    'onslotchange',
    'onstalled',
    'onstorage',
    'onsubmit',
    'onsuspend',
    'ontimeupdate',
    'ontoggle',
    'onunhandledrejection',
    'onunload',
    'onvolumechange',
    'onwaiting',
    'onwheel',
    'onmousewheel',
    'onredo',
    'onundo',
    'onformchange',
    'onforminput',
    'onabort',
    'onreadystatechange',
    'ondragexit',
    'onwebkitanimationend',
    'onwebkitanimationiteration',
    'onwebkitanimationstart',
    'onwebkittransitionend',
  ].join('|')})\\s*=\\s*`,
  'gi',
);
