import { go, map, sortBy } from 'fxjs/es';

export function covertWowQtyPricesToBpDiscountRanges(qty_prices) {
  return go(
    qty_prices,
    sortBy(({ quantity }) => quantity),
    map(({ quantity, price, price_en, price_jp }) => ({
      price,
      price_en,
      price_jp,
      start: quantity,
      end: quantity,
    })),
  );
}
export function reduceUniquePriceBpDiscountRanges(bp_discount_ranges) {
  // const price = 10000000;
  return go(
    bp_discount_ranges,
    sortBy((bp_discount_range) => bp_discount_range.start),
  );
}
export const setVirtualBpDiscountSet = async ({ product_color, selected_bp_option_ids, getSizePrices }) => {
  product_color._ = JSON.parse(JSON.stringify(product_color._));
  const bp_discount_set = product_color._.base_product._.bp_discount_set;
  selected_bp_option_ids = selected_bp_option_ids || product_color._.selected_option_group.bp_option_ids;
  const bp_discount_ranges = await go(
    getSizePrices({ bp_id: bp_discount_set.base_product_id, bp_option_ids: selected_bp_option_ids }),
    covertWowQtyPricesToBpDiscountRanges,
  );

  if (!bp_discount_ranges?.length) {
    console.log(
      ` bp_option_ids: ${selected_bp_option_ids.join(',')}, bp_id: ${bp_discount_set.base_product_id}`,
    );
    throw new Error(`해당하는 옵션의 수량과 가격이 존재 하지 않습니다.`);
  }
  bp_discount_ranges[0].is_default = true;
  bp_discount_set._.bp_discount_ranges = bp_discount_ranges;
};
