import { $delegate, $find, $remove, $setHTML, $setText, $siblings, $trigger } from 'fxdom/es';
import { each, go, tap } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { OMPProductListS } from '../../../S/Function/module/OMPProductListS.js';
import { OMPProductListTmplS } from '../../../S/Tmpl/module/OMPProductListTmplS.js';
import { OMPProductListFilterModalMuiF } from '../Mui/module/OMPProductListFilterModalMuiF.js';
import { OMPProductListFilterModalF } from './module/OMPProductListFilterModalF.js';
/*
 * TODO 상태관리 조심해라
 *  그리고 리팩토링 좀 해주자
 *
 * */

export const filterListEvent = (frame_el, product_list_tap_el, { bp_filters, query }) => {
  const copy_bp_filters = JSON.parse(JSON.stringify(bp_filters));
  return go(
    frame_el,
    $delegate('click', '.omp-product-list-filter-modal__filter-list', ({ currentTarget }) => {
      return MuiF.pushPage(OMPProductListFilterModalMuiF.page_filter_item, (p, [t]) => {
        const { fl } = OMPProductListFilterModalF.findFilterItem(copy_bp_filters, currentTarget.dataset);
        t.makeData = () => {
          return fl;
        };
        p.removed = () => {
          const fl_count$ = $find('.omp-product-list-filter-modal__filter-list-count', currentTarget);
          fl_count$.dataset.count = fl.count;
          $setText(`(${fl.count})`, fl_count$);
        };
      });
    }),
    $delegate('click', '.omp-product-list-filter-item-modal__filter-item', ({ currentTarget }) => {
      const { fl, fi } = OMPProductListFilterModalF.findFilterItem(copy_bp_filters, currentTarget.dataset);

      const icon$ = $find('.omp-product-list-filter-item-modal__filter-item-icon', currentTarget);
      fi.checked = icon$.dataset.checked = icon$.dataset.checked == 'false';
      if (fi.checked) {
        fl.count++;
        copy_bp_filters.total_count++;
      } else {
        fl.count--;
        copy_bp_filters.total_count--;
      }

      go(
        frame_el,
        $find('.omp-product-list-filter-modal__checked-filter-wrapper'),
        tap((w$) => {
          w$.dataset.checked = copy_bp_filters.total_count > 0;
        }),
        $find('.opm-product-list-checked-filter-items'),
        (check_filter_items$) => {
          const checked_filter_items = OMPProductListS.checkedFilterItems(copy_bp_filters);
          const check_filter_length = checked_filter_items.length;
          check_filter_items$.dataset.list_show = check_filter_length > 0;
          $find('.opm-product-list-bps', product_list_tap_el).dataset.filter_show = check_filter_length > 0;
          $setHTML(
            OMPProductListTmplS.checkedFilterItemsWithReset(checked_filter_items),
            check_filter_items$,
          );
        },
      );
    }),
    $delegate('click', '.omp-product-list__checked-filter-item', ({ currentTarget }) => {
      const { fl, fi } = OMPProductListFilterModalF.findFilterItem(copy_bp_filters, currentTarget.dataset);

      fl.count--;
      fi.checked = false;
      copy_bp_filters.total_count--;

      $find('.omp-product-list-filter-modal__checked-filter-wrapper', frame_el).dataset.checked =
        copy_bp_filters.total_count > 0;

      const fl_count$ = $find(
        `.omp-product-list-filter-modal__filter-list[data-filter_list_id="${fl.id}"] 
          .omp-product-list-filter-modal__filter-list-count`,
        frame_el,
      );
      fl_count$.dataset.count = fl.count;
      $setText(`(${fl.count})`, fl_count$);
      $remove(currentTarget);
    }),
    $delegate('click', '.omp-product-list__checked-filter-item-init', ({ currentTarget }) => {
      each((el) => {
        $trigger('click', el);
      }, $siblings('.omp-product-list__checked-filter-item', currentTarget));
    }),
    $delegate('click', '.omp-product-list-filter-modal-frame__footer button', async () => {
      const checked_filter_items = OMPProductListS.checkedFilterItems(copy_bp_filters);
      Object.assign(bp_filters, copy_bp_filters);
      MuiF.closeFrame({ checked_filter_items, product_list_tap_el, query });
    }),
  );
};
