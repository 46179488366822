import { renderWebgl, setUniforms } from '../helpers/shaders.js';
import colorRemovalFragmentShaderSrc from '../../shaders/fragment/colorRemove.fragment.glsl';
import { loadBaseWebGlImageCanvas } from '../helpers/webglCanvas.js';

export async function getColorRemovedImageSrc({
  image, // Image object
  targetColor, // normalized RGB 0.0 ~ 1.0 array
  colorDistancePercent, // % of color distance from target
}) {
  const { gl, webglProgram } = loadBaseWebGlImageCanvas({
    image,
    fragmentShaderSrc: colorRemovalFragmentShaderSrc,
  });

  const isFlip = true;
  setUniforms({
    gl,
    webglProgram,
    uniforms: [
      { name: 'u_flipY', type: '1f', values: [isFlip ? -1.0 : 1.0] },
      { name: 'u_removalColor', type: '3f', values: targetColor },
      {
        name: 'u_removalColorDistancePercent',
        type: '1f',
        values: [colorDistancePercent],
      },
    ],
  });

  renderWebgl({ gl, points: 6 });

  return gl.canvas.toDataURL('image/png');
}
