import { html } from 'fxjs/es';
import { OMPCoreUtilS } from '../../../Util/S/Function/module/OMPCoreUtilS.js';
import { OMPCoreAtomTmplS } from './module/OMPCoreAtomTmplS.js';

/**
 * @global
 * @typedef {object} InputStyle
 * @property {string} [placeholder_color='GY_80']
 * @property {boolean} [underline=false]
 * @property {boolean} [border=true]
 * @property {string} [text_size='normal']
 * @property {40|44|50} [height=44]
 */

/**
 * @global
 * @typedef {object} InputData
 * @property {number} [debounce_time=0]
 * @property {string?} prev_value
 */

/**
 * @global
 * @typedef InputParam
 * @property {string} name
 * @property {string?} input_id
 * @property {string?} input_klass
 * @property {string?} placeholder
 * @property {string} [type='text']
 * @property {string?} value
 * @property {boolean} [required=false]
 * @property {number?} minlength
 * @property {number?} maxlength
 * @property {InputStyle} style
 * @property {InputData?} data
 * @property {number?} debounce_time
 */

/**
 * 기본 Input 태그
 * 안드로이드의 경우 `form` 태그로 작성해야 키보드의 `제출` 이벤트가 적용되므로 `input`을 사용하지 말고 `inputForm`을 사용하도록 한다.
 * @param {InputParam} inputParam
 * @return {string}
 */
export const input = ({
  name,
  input_id,
  input_klass,
  type = 'text',
  placeholder,
  value,
  required = false,
  minlength,
  maxlength,
  data,
  style,
  debounce_time = 0,
} = {}) => {
  const default_style = {
    border: true,
    placeholder_color: 'GY_80',
    underline: false,
    text_size: 'normal',
    height: 44,
  };

  const default_data = {
    debounce_time: debounce_time || 0,
  };

  return html`
    <input
      type="${type || 'text'}"
      ${input_id ? html`id="${input_id}"` : ''}
      ${name ? html`name="${name}"` : ''}
      class="omp-atom__input ${input_klass || ''}"
      placeholder="${placeholder || ''}"
      value="${value || ''}"
      ${OMPCoreUtilS.createStyleSet({ ...default_style, ...style })}
      ${OMPCoreUtilS.createDataSet({ ...default_data, ...data })}
      autocomplete="off"
      spellcheck="false"
      ${required ? 'required' : ''}
      ${minlength ? html`minlength="${minlength}"` : ''}
      ${maxlength ? html`maxlength="${maxlength}"` : ''}
    />
  `;
};

/*
TODO @kjj
지금은 text input으로 구현해두었는데, 필요하면 다른 타입도 추가하기 (방법 2가지)
1. type을 param으로 받는다
2. textInput, numberInput, booleanInput 등으로 나누어 delegate에서 에러처리 할 수 있도록 한다.
*/

/**
 * 경고와 키를 포함한 인풋
 * @param {InputParam} inputParam
 * @param {string?} inputParam.key_el
 * @param {string?} inputParam.action_btn_el
 * @param {Object?} inputParam.warning
 * @param {string?} inputParam.warning.message
 * @param {boolean?} inputParam.warning.visible
 * @return {string}
 */
export const warningInput = ({
  key_el,
  action_btn_el,
  name,
  input_klass,
  type = 'text',
  placeholder,
  value,
  required = false,
  minlength,
  maxlength,
  data,
  style,
  warning: { message = '', visible = false } = { message: '', visible: false },
} = {}) => {
  const default_style = {
    border: false,
    placeholder_color: 'GY_80',
    underline: false,
    text_size: 'normal',
    height: 50,
  };

  const default_data = {
    debounce_time: 0,
  };

  return html`
    <div class="omp-atom__warning-input-wrapper">
      <div class="omp-atom__input-wrapper">
        ${key_el || ''}
        ${input({
          name,
          input_klass,
          type,
          placeholder,
          value,
          required,
          minlength,
          maxlength,
          data: { ...default_data, data },
          style: { ...default_style, style },
        })}
        ${action_btn_el || ''}
      </div>
      <div class="omp-atom__warning-input" data-name="${name}" data-visible="${visible}">${message}</div>
    </div>
  `;
};

export const radioInput = ({ is_checked, name, value, label, input_id }) => {
  return html`<span class="omp-atom__input-radio" ${input_id ? html`data-id="${input_id}"` : ''}>
    <span class="omp-atom__input-radio__icon-wrap">
      ${OMPCoreAtomTmplS.radioIcon(is_checked)}
      <input
        id="${input_id}"
        type="radio"
        name="${name}"
        value="${value || ''}"
        class="omp-atom__input-radio__input"
        ${is_checked ? 'checked' : ''}
      />
    </span>
    <label id="${input_id}" class="omp-atom__input-radio__input-label">${label}</label>
  </span>`;
};
