import { makeInboundTabHtml } from '../Tmpl/tmpl.tabs.js';
import { base_tab } from './base_tab.js';

const mui_ref_path = '/modules/Df/Lgl/ShippingDetail/S/Mui/tab_inbound.js';
export const tab_inbound = base_tab({
  mui_ref_path,
  title: '입고 요청',
  tag: 'inbound',
  template: makeInboundTabHtml,
});
