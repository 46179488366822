import {
  compact,
  each,
  extend,
  filterL,
  go,
  isNil,
  map,
  mapL,
  merge,
  reduce,
  sel,
  takeAll,
  zipL,
  zipWithIndexL,
} from 'fxjs/es';
import axios from 'axios';
import { waybill_CJ_label_data_formatter } from './waybill_cj_formating.js';
import { printWaybillCJ } from './waybill_cj_printing.js';
import { updatePrintProcessStatus } from './waybill_cj_go.js';
import { alertWaybillCJstatus } from './alert_windows.js';
import Swal from 'sweetalert2';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';

// @description - 가라 운송장을 단건 출력해줌 (주문 정보 없이, 받는이만 입력 받아 출력)
// @param additional_label_datas : { dataFn: (label_data) => {}, formats: [], goods_name: string (운송장에 찍힐 아이템 목록) }
// @param is_print_prj_qr - 운송장에 주문서 QR 을 찍을 것인지 여부
// @param allowed_devices - 라벨 출력 가능한 프린터 구분
// @params printer_serial - 특정 프린터 시리얼만 출력 가능하도록 권한 설정
export async function waybillGaraGo({
  orderer_name,
  receivers,
  additional_label_datas,
  allowed_devices,
  printer_serial,
  is_print_prj_qr = false,
  is_override = false,
  is_alert_popup = false,
}) {
  DfInhouseF.lottie_loader.start('printer');
  const { projection_data, shipping_datas } = createGaraProjectionDataForWaybill({
    orderer_name,
    receivers,
  });
  const returning_data = go(
    await axios({
      method: 'post',
      url: '/@api/waybill/cj/req_booking',
      data: {
        projection_data,
        shipping_datas,
      },
    }),
    sel('data'),
  );

  const data = returning_data.data;
  const api_data = returning_data.data.CJ_apis;
  const prc = returning_data.process;

  const process_summary = go(
    zipWithIndexL(prc.shippings_check),
    mapL(([idx, prc_shipping]) => {
      return [
        prc_shipping,
        prc?.CJ_apis_check?.reservation_params_list_check?.[idx],
        prc?.CJ_apis_check?.address_reference_params_list_check?.[idx],
        prc?.CJ_apis_check?.CJ_bookings_check?.[idx],
      ];
    }),
    mapL((prcs) => {
      return go(zipWithIndexL(compact(prcs)), (prcs) =>
        reduce(
          (acc, [idx, prc]) => {
            if (isNil(acc)) acc = extend(prc, { process_idx: idx });
            if (!isNil(prc) && Object.keys(prc).length) {
              acc = extend(prc, { process_idx: idx });
            }
            return acc;
          },
          null,
          prcs,
        ),
      );
    }),
    zipL(data.shippings),
    mapL((x) => merge(...x)),
    zipL(data.CJ_apis?.reservation_params_list),
    mapL((x) => merge(...x)),
    takeAll,
  );

  const waybill_label_datas = go(
    api_data?.CJ_bookings,
    zipWithIndexL,
    //프린트 해야할 배송정보들은 최종 택배 예약 처리까지 완료된 친구들만 진행
    filterL(([_, booking_result]) => booking_result?.RESULT_CD === 'S'),
    (passed_idx_arr) =>
      reduce(
        (acc, [idx, _]) => {
          acc.push({
            projection_id: 0,
            shipping_id: returning_data.data.shippings[idx].id,
            ...api_data.address_reference_params_list[idx],
            ...api_data.reservation_params_list[idx],
          });
          return acc;
        },
        [],
        passed_idx_arr,
      ),
    waybill_CJ_label_data_formatter,
  );

  if (additional_label_datas?.goods_name) {
    await go(
      each(async (data) => (data.goods_name = additional_label_datas.goods_name), waybill_label_datas),
    );
  }

  const COMPLETE_PROCESS_INDEX = 6;

  const { is_OK: is_printed, error } = is_override
    ? { is_OK: true }
    : await printWaybillCJ(
        waybill_label_datas,
        {
          is_print_prj_qr, // 가라 운송장은 주문 번호 없음
          additional_label_datas, // 라벨에 추가할 인쇄 데이터
          allowed_devices,
        },
        printer_serial,
      );
  if (!is_printed && error) throw error;

  updatePrintProcessStatus(process_summary, COMPLETE_PROCESS_INDEX, is_printed);

  const process_statuses = process_summary.map((process) => ({
    is_OK: process.status === 'S' && is_printed,
    waybill_no: process.status === 'S' ? process.INVC_NO : null,
  }));

  DfInhouseF.lottie_loader.end();
  /* 7. 프로세스 팝업 */
  if (is_alert_popup) {
    alertWaybillCJstatus
      .process({
        process_summary,
        title: `택배 운송장 발급 현황`,
        icon: !is_printed
          ? 'error'
          : returning_data.overall_deliver_status === 'all'
          ? 'success'
          : returning_data.overall_deliver_status === 'partial'
          ? 'warning'
          : 'error',
        // is_timer: false,
        is_timer: is_printed && returning_data.overall_deliver_status === 'all',
        complete_process_idx: COMPLETE_PROCESS_INDEX,
        stop_event_propagation: data.projections.merged_type === 'child',
      })
      .then(() => {
        const is_some_process_not_OK = process_statuses.some((process) => process.is_OK === false);
        if (is_some_process_not_OK) {
          Swal.fire({
            icon: 'warning',
            width: 500,
            backdrop: true,
            title: '확인 필요',
            html: `<span>운송장이 정상적으로 처리되지 못한 건이 있습니다.<br>원인 확인 후 다시 진행해 주세요.</span>`,
            showConfirmButton: true,
          });
        }
      });
  }

  return { process_statuses, waybill_label_datas };
}

function createGaraProjectionDataForWaybill({ orderer_name, receivers }) {
  const projection_data = {
    id: 0,
    projection_id: 0,
    orderer_name,
    quantity: 1,
    status: 'before_deliver',
    type: 'parent',
    merged_type: 'unmerged',
    is_cancel_req: false,
    is_canceled: false,
    collabo_type: '',
    due_at: new Date(),
    ordered_at: new Date(),
    is_repress: false,
    is_shopify: false,
    important: false,
    internal_important: false,
    shipping_task: {
      id: 0,
      title: '배송',
      type: 'before_deliver',
      status: 'ready',
      projection_id: 0,
    },
    combined_children: [],
  };
  const shipping_datas = go(
    receivers,
    map((receiver) => {
      const { name, address, address2, postcode, mobile1 } = receiver;
      return {
        id: 0,
        projection_id: 0,
        user_id: 0,
        type: 'parcel',
        waybill_number: '',
        is_downloaded: false,
        download_at: null,
        shipping_company_id: 'cj',
        name,
        address,
        address2,
        postcode,
        mobile1,
        mobile2: '',
        memo: '',
        orderer_sms: false,
      };
    }),
  );

  return { projection_data, shipping_datas };
}
