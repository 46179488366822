import { EVAL_RESULT_NAMES } from './eval_result_names.js';

export const ani_slomo_opts = {
  direction: 'alternate',
  easing: 'ease-out',
  duration: 500,
  iterations: 8,
  fill: 'none',
};

const ani_midmo_opts = {
  direction: 'alternate',
  easing: 'ease-out',
  duration: 350,
  iterations: 10,
  fill: 'none',
};

const ani_fastmo_opts = {
  direction: 'alternate',
  easing: 'ease-out',
  duration: 50,
  iterations: 30,
  fill: 'none',
};

export const animations = {
  [EVAL_RESULT_NAMES.fail.dev]: (is_update, just_opacity) => ({
    keyframes: { backgroundColor: ['#ffd000', '#ff7b00'] },
    options: is_update ? ani_slomo_opts : ani_fastmo_opts,
  }),
  [EVAL_RESULT_NAMES.success.dev]: (is_update, just_opacity) => ({
    keyframes: { backgroundColor: ['#70e000', '#007200'] },
    options: is_update ? ani_slomo_opts : ani_fastmo_opts,
  }),
  [EVAL_RESULT_NAMES.cancel.dev]: (is_update, just_opacity) => ({
    keyframes: { backgroundColor: ['#ff3245', '#d00000'] },
    options: is_update ? ani_slomo_opts : ani_fastmo_opts,
  }),
  error: {
    server_or_algorithm_error: {
      keyframes: { backgroundColor: ['#6d23b6', '#9d4edd'] },
      options: ani_midmo_opts,
    },
    prj_recognition_error: {
      keyframes: { backgroundColor: ['#2b2b2b', '#626161'] },
      options: ani_midmo_opts,
    },
    wrong_scan_code: {
      keyframes: { backgroundColor: ['#d80000', '#0000bc'] },
      options: ani_midmo_opts,
    },
  },
};
