import { $scrollTop, $setScrollTop } from 'fxdom/es';
import { VectorEditorStickerSingleCreatorPCCuttingLineManualF } from '../../../CuttingLineManual/F/Function/module/VectorEditorStickerSingleCreatorPCCuttingLineManualF.js';
import { VectorEditorStickerSingleCreatorPCCuttingLineAutoF } from '../Function/module/VectorEditorStickerSingleCreatorPCCuttingLineAutoF.js';

export const handleRightPanelOffsetDecreaseClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_cutting_line_index <= 0) {
      return;
    }

    $.don_loader_start();
    try {
      const cutting_line = await VectorEditorStickerSingleCreatorPCCuttingLineAutoF.getCuttingLine({
        tab_el,
        index: tab_el.__mp_cutting_line_index - 1,
      });
      VectorEditorStickerSingleCreatorPCCuttingLineAutoF.setCuttingLine({
        tab_el,
        label: cutting_line.label,
        path_data: cutting_line.path_data,
      });
      tab_el.__mp_cutting_line_index = cutting_line.index;
    } catch (error) {
      $.alert(
        error?.__mp_alert_message ?? T('modules::VectorEditor::Sticker::message::재단선을 만들 수 없습니다.'),
      );
    } finally {
      $.don_loader_end();
    }
  };

export const handleRightPanelOffsetIncreaseClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_cutting_line_index >= tab_el.__mp_cutting_lines.length - 1) {
      return;
    }

    $.don_loader_start();
    try {
      const cutting_line = await VectorEditorStickerSingleCreatorPCCuttingLineAutoF.getCuttingLine({
        tab_el,
        index: tab_el.__mp_cutting_line_index + 1,
      });
      VectorEditorStickerSingleCreatorPCCuttingLineAutoF.setCuttingLine({
        tab_el,
        label: cutting_line.label,
        path_data: cutting_line.path_data,
      });
      tab_el.__mp_cutting_line_index = cutting_line.index;
    } catch (error) {
      $.alert(
        error?.__mp_alert_message ?? T('modules::VectorEditor::Sticker::message::재단선을 만들 수 없습니다.'),
      );
    } finally {
      $.don_loader_end();
    }
  };

export const handleRightPanelEditManualClick =
  ({ tab_el }) =>
  async () => {
    if ($scrollTop(window) > 30) {
      await window.anime({
        targets: 'html, body',
        scrollTop: 0,
        duration: 400,
        easing: 'easeInOutCubic',
      }).finished;
    } else {
      $setScrollTop(0)(window);
    }

    return VectorEditorStickerSingleCreatorPCCuttingLineManualF.makeCuttingLineManual({
      target_image_el: tab_el.__mp_target_image_el,
      cutting_line_path_data: tab_el.__mp_cutting_line_editor.getCuttingLinePathData(),
      postProcess: tab_el.__mp_postProcess,
      editor_padding: {
        top: tab_el.__mp_editor_padding_top,
        bottom: tab_el.__mp_editor_padding_bottom,
        left: tab_el.__mp_editor_padding_left,
        right: tab_el.__mp_editor_padding_right,
      },
    });
  };
