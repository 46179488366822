import { guards } from '../../S/Function/guards.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import { printUpcsLabel } from './label.print.js';
import { alertsLgl } from './alerts.js';

export async function handleUpcsLabel({ currentTarget: ct }) {
  try {
    DfInhouseF.lottie_loader.start('printer');
    const upc = box.sel(ct);
    guards.nullCheck(upc, 'upc is required from box');

    await printUpcsLabel({ upc });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}
