import axios from 'axios';
import { makeApiUrl } from '../../../../Util/S/Function/util.js';
import { go, mapC } from 'fxjs/es';
import { drawProductFace } from '../../../../Maker/F/draw_product_faces.js';
import { createCanvasElement } from '../../../../Canvas/S/util.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { createHanjinInboundOrderSheetPdf } from './pdf.hanjin.inbound.js';
import { getJustifyCanvas } from '../../../../Maker/F/canvas_trim.js';

export function isNessFromDfPrj({ prj }) {
  return prj?._?.store?._?.crew?.marketplace_name === 'NESS';
}

/**
 *  @description DF 에서 MPS 타입의 ups 목록을 PDF 생성하여 다운로드
 *  @param {number} projection_id
 *  @return {Promise<undefined>}
 * */

export async function printMpsUpsForNESS({ projection_id }) {
  const mps_ups = await getMpsUps({ projection_id });

  if (UtilArrayS.isEmNil(mps_ups)) {
    throw new Error(`POD 상품 주문이 없습니다.<br>한진 입고주문 출력이 필요 없습니다.`);
  }

  const THUMBNAIL_SIZE = 1080;

  const ups = await go(
    mps_ups,
    mapC(async (up) => {
      const thumbnail_canvas = await makeThumbnailFromPf2({ size: THUMBNAIL_SIZE, pf2: up.pf2 });
      return { ...up, thumbnail_canvas };
    }),
  );

  await createHanjinInboundOrderSheetPdf({ projection_id, ups });
}

/**
 *  @description 주문서의 MPS 타입의 ups 목록 API 조회
 *  @param {number} projection_id
 *  @return {Promise<{due_at: string, cate_list_name: string, cate_item_name: string, product_name: string, bp_name: string, bpc_name: string, bps_name:string, sku: number | string, quantity: number, pf2: object}[] | null>} * */
async function getMpsUps({ projection_id }) {
  return (
    await axios.get(
      makeApiUrl(`/@api/projection/:projection_id/mps_ups`, {
        projection_id,
      }),
    )
  ).data;
}

/**
 *  @description pf2 정보 -> 썸네일 캔버스 redraw 생성
 *  @param {number} size - 썸네일 캔버스 사이즈
 *  @param {object} pf2 - 특정 면의 pf2 데이터
 *  @return {Promise<HTMLCanvasElement>} canvas
 * */
async function makeThumbnailFromPf2({ size, pf2 }) {
  if (pf2 == null) throw new Error(`상품 그리기 정보 pf2 가 존재하지 않습니다.`);
  const canvas = await drawProductFace(
    await createCanvasElement({ width: size, height: size }),
    pf2,
    false,
    undefined,
    true,
    false,
    false,
  );

  return getJustifyCanvas(canvas, 20);
}
