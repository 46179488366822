import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreCateListS } from '../../../CateList/S/Function/module/OMPCoreCateListS.js';

/**
 * Create a category component with the specified options.
 *
 * @param {Object} options - The options for the category.
 * @param {string} [options.id] - The ID of the category.
 * @param {string} [options.category_name=''] - The name of the category (optional, default: '').
 * @param {Object} [options.thumbnail] - The thumbnail options (optional).
 * @param {'product'|'model'} [options.thumbnail.thumbnail_bg='product'] - The background of the thumbnail (optional, default: 'product').
 * @param {string} [options.thumbnail.thumbnail_url=''] - The URL of the thumbnail image (optional, default: '').
 * @param {Object} [options.link] - The link options (optional).
 * @param {boolean} [options.link.is_new_tab=false] - Whether the link should be opened in a new tab (optional, default: false).
 * @param {string} [options.link.href=''] - The URL the category should link to (optional, default: '').
 * @returns {string} The HTML markup for the badge component.
 */

/**
 * @param {CateItem} [cate_item = {}]
 */
export const category = ({
  id,
  name = '',
  mp_thumbnail_url,
  link: { is_new_tab = false, href = '' } = {},
  size = 'M',
} = {}) => {
  return html`
    <a
      id="${id}"
      class="omp-cell__category"
      data-size="${size}"
      href="${href}"
      ${is_new_tab ? 'target="_blank"' : ''}
    >
      <span class="omp-cell__category-thumbnail">
        ${mp_thumbnail_url ? html`<img src="${mp_thumbnail_url}" alt="${name}" />` : ''}
      </span>
      ${name ? html`<span class="omp-cell__category-text">${name}</span>` : ''}
    </a>
  `;
};

/**
 * Create a categoryAll component with the specified options.
 *
 * @param {Object} options - The options for the categoryAll.
 * @param {string} [options.id] - The ID of the categoryAll.
 * @param {string} [options.button_text=''] - The text for the categoryAll button (optional, default: '').
 * @param {Object} [options.thumbnail] - The thumbnail options (optional).
 * @param {'product'|'model'} [options.thumbnail.thumbnail_bg='product'] - The background of the thumbnail (optional, default: 'product').
 * @param {string} [options.thumbnail.thumbnail_url=''] - The URL of the thumbnail image (optional, default: '').
 * @param {Object} [options.link] - The link options (optional).
 * @param {boolean} [options.link.is_new_tab=false] - Whether the link should be opened in a new tab (optional, default: false).
 * @param {string} [options.link.href=''] - The URL the categoryAll should link to (optional, default: '').
 * @returns {string} The HTML markup for the categoryAll component.
 */

/**
 * `link.href`의 값이 `?cate_list_id=$`의 형태라 함수로 만들어서 넣어줌
 * @param {CateList} [cate_list = {}]
 * @param {string} [cate_list.button_text = '']
 */
export const categoryAll = ({
  id,
  button_text = '',
  mp_thumbnail_url,
  link: { is_new_tab = false } = {},
} = {}) => {
  return html`
    <a
      id="${id}"
      class="omp-cell__category-all"
      href="${OMPCoreCateListS.getCateListHref(id)}"
      ${is_new_tab ? 'target="_blank"' : ''}
    >
      <span class="omp-cell__category-all-thumbnail">
        ${mp_thumbnail_url ? html`<img src="${mp_thumbnail_url}" alt="${button_text}" />` : ''}
        <span class="omp-cell__category-all-button-wrapper">
          <span class="omp-cell__category-all-button">${button_text}</span>
          <span class="omp-cell__category-all-plus-button-wrapper"> ${OMPCoreAtomTmplS.plusButton()} </span>
        </span>
      </span>
    </a>
  `;
};

export const categoryListThumbnail = ({ category_title = '', thumbnail_url = '', href = '' } = {}) => {
  return html`
    <a class="omp-cell__category-list-thumbnail" href="${href}">
      ${thumbnail_url ? html`<img src="${thumbnail_url}" alt="${category_title}" />` : ''}
      <span class="omp-cell__category-list-thumbnail__all">${TT('common::show_all')}</span>
    </a>
  `;
};

/**
 * `link.href`의 값이 `?cate_list_id=$`의 형태라 함수로 만들어서 넣어줌
 * @param {CateList} [cate_list = {}]
 */
export const categoryList = ({
  id,
  name,
  mp_main_img_url,
  link: { is_new_tab = false } = {},
  _: { cate_items } = {},
} = {}) => {
  return html`
    <span id="${id}" class="omp-cell__category-list">
      ${categoryListThumbnail({
        category_title: name,
        thumbnail_url: mp_main_img_url,
        href: OMPCoreCateListS.getCateListHref(id),
      })}
      <span class="omp-cell__category-list-text">
        ${name
          ? html`<a
              class="omp-cell__category-list-title"
              href="${OMPCoreCateListS.getCateListHref(id)}"
              ${is_new_tab ? 'target="_blank"' : ''}
            >
              ${name}
            </a>`
          : ''}
        ${strMap(
          (cate_item) => html`<a class="omp-cell__category-list-sub" href="${cate_item.link.href}"
            >${cate_item.name}</a
          >`,
          cate_items,
        )}
      </span>
    </span>
  `;
};

export const subCategoryList = ({ padding_left = 0, sub_categories = [] } = {}) => {
  return html`
    <span class="omp-cell__sub-category-list" style="padding-left: ${padding_left}">
      <span class="omp-cell__sub-category-list-container">
        ${strMap((sub) => category({ ...sub, size: 'S' }), sub_categories)}
      </span>
    </span>
  `;
};

export const categoryButtons = ({ buttons = [] } = {}) => {
  return html`
    <span class="omp-cell__category-buttons"> ${strMap(OMPCoreAtomTmplS.categoryButton, buttons)} </span>
  `;
};

export const headerMenus = ({ buttons = [] } = {}) => {
  return html`
    <div class="omp-header__menu-buttons swiper-container">
      <div class="swiper-wrapper omp-header__menu-buttons-wrap">
        ${strMap(OMPCoreAtomTmplS.headerMenu, buttons)}
      </div>
    </div>
    <div class="swiper-button-next omp-header__menu-buttons-next">
      ${OMPCoreAtomTmplS.pcHeaderMenuArrow()}
    </div>
    <div class="swiper-button-prev omp-header__menu-buttons-prev">
      ${OMPCoreAtomTmplS.pcHeaderMenuArrow()}
    </div>
  `;
};
