import { $qs, $scrollTop } from 'fxdom/es';
import { VectorEditorStickerF } from '../../../../../VectorEditor/Sticker/F/Function/module/VectorEditorStickerF.js';
import { stickerPostProcess } from './stickerPostProcess.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';

export async function openStickerEditor({ editor_props, app, post_process_prop }) {
  const { title, price, options, svg_file, thumbnail_width_min_px, thumbnail_stroke_width_px, tab_el } =
    editor_props;
  if (!UtilF.isLegacyMobile()) {
    await VectorEditorStickerF.makeStickerPC({
      app,
      title,
      price,
      options,
      prev_frame_right_panel: $qs('#marpplizer'),
      thumbnail_width_min_px,
      thumbnail_stroke_width_px,
      svg_el: svg_file,
      postProcess: stickerPostProcess(post_process_prop),
      getFramePosition: () => {
        const { y, height } = tab_el?.querySelector?.('.mp_maker')?.getBoundingClientRect?.() ?? {
          y: 70,
          height: 926,
        };
        return { top: y, height };
      },
      onFrameHiding: () => {
        (tab_el?.tab_opt ?? {}).tab_top = $scrollTop(window);
      },
    });
  } else {
    await VectorEditorStickerF.makeStickerMobile({
      title,
      options,
      thumbnail_width_min_px,
      thumbnail_stroke_width_px,
      svg_el: svg_file,
      postProcess: stickerPostProcess(post_process_prop),
    });
  }
}
