import { VectorEditorKeyringFreeMobileMuiS } from '../../S/Mui/module/VectorEditorKeyringFreeMobileMuiS.js';
import { CommonNS, EditorNS } from '@marpple/sticker-editor';
import { $find, $findAll, $off, $on } from 'fxdom/es';
import { each, go, noop, tap } from 'fxjs/es';
import { VectorEditorKeyringFreeMobileF } from '../Function/module/VectorEditorKeyringFreeMobileF.js';
import { VectorEditorFontF } from '../../../../../Font/F/Function/module/VectorEditorFontF.js';
import axios from 'axios';
import { VectorEditorKeyringFreeMobileConstantS } from '../../S/Constant/module/VectorEditorKeyringFreeMobileConstantS.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorKeyringFreeMobileMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  async appending(tab_el) {
    tab_el.__mp_is_prevent_touch_move = false;
    tab_el.__mp_handleWindowTouchMove = null;
    tab_el.__mp_keyring_editor = null;
    tab_el.__mp_art_board_width = tab_el.__mp_art_board_width ?? 0;
    tab_el.__mp_art_board_height = tab_el.__mp_art_board_height ?? 0;
    tab_el.__mp_art_board_shape_path_data = tab_el.__mp_art_board_shape_path_data ?? `M 0 0 Z`;
    tab_el.__mp_empty_template_el = tab_el.__mp_empty_template_el ?? null;
    tab_el.__mp_preProcess = tab_el.__mp_preProcess ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_fonts = null;

    go(
      tab_el,

      tap(
        $findAll(`.header button.cancel`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleHeaderCancelButtonClick())),
      ),
      tap(
        $findAll(`.header button.next`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleHeaderNextButtonClick({ tab_el }))),
      ),
      tap(
        $findAll(`.header .icons [data-icon_type="more"] input`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleHeaderMoreIconClick({ tab_el }))),
      ),
      tap(
        $findAll('.header .icons [data-icon_type="layers"] input'),
        each($on('click', VectorEditorKeyringFreeMobileF.handleHeaderLayersIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.blank_plate[data-target="top_menu"]`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuBlankPlateClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_RESET.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuHistoryResetIconClick())),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_UNDO.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuHistoryUndoIconClick())),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_REDO.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuHistoryRedoIconClick())),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.DELETE.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuDeleteIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.FORWARD.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuForwardIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.BACKWARD.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuBackwardIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.GROUP.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuGroupIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.UNGROUP.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuUngroupIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.LOCK.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuLockIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.UNLOCK.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuUnlockIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.FLIP_HORIZONTAL.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuFlipHorizontalIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.FLIP_VERTICAL.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuFlipVerticalIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuMoveToLeftEndIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeMobileF.handleTopMenuMoveToHorizontalCenterIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuMoveToRightEndIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_TOP_END.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuMoveToTopEndIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID}"] input[type="button"]`,
        ),
        each(
          $on('click', VectorEditorKeyringFreeMobileF.handleTopMenuMoveToVerticalCenterIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorKeyringFreeMobileF.handleTopMenuMoveToBottomEndIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.footer.none[data-icon_type="add"]`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterNoneAddIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.footer.object[data-icon_type="duplicate"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterObjectDuplicateIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.object[data-icon_type="delete"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterObjectDeleteIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.footer.path[data-icon_type="set_color"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterPathSetColorIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="set_stroke"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterPathSetStrokeIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="edit_path"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterPathEditPathIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="duplicate"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterPathDuplicateIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="delete"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterPathDeleteIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.footer.shape[data-icon_type="set_color"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterShapeSetColorIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.shape[data-icon_type="set_stroke"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterShapeSetStrokeIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.shape[data-icon_type="duplicate"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterShapeDuplicateIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.shape[data-icon_type="delete"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterShapeDeleteIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.footer.text[data-icon_type="decorate_text"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterTextDecorateTextIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.text[data-icon_type="open_keyboard"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterTextOpenKeyboardIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.text[data-icon_type="duplicate"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterTextDuplicateIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.footer.text[data-icon_type="delete"`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleFooterTextDeleteIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.blank_plate[data-target="bottom_menu"]`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleBottomMenuBlankPlateClick({ tab_el }))),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="text"]`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleBottomMenuTextIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="path"]`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleBottomMenuPathIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="shape"]`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleBottomMenuShapeIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="image"]`),
        each($on('click', VectorEditorKeyringFreeMobileF.handleBottomMenuImageIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.editor_container`),
        each(
          $on(
            '@mp/sticker-editor/select',
            VectorEditorKeyringFreeMobileF.handleKeyringEditorSelect({ tab_el }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/unselect',
            VectorEditorKeyringFreeMobileF.handleKeyringEditorUnselect({ tab_el }),
          ),
        ),
        each(
          $on('@mp/sticker-editor/lock', VectorEditorKeyringFreeMobileF.handleKeyringEditorLock({ tab_el })),
        ),
        each(
          $on(
            '@mp/sticker-editor/unlock',
            VectorEditorKeyringFreeMobileF.handleKeyringEditorUnlock({ tab_el }),
          ),
        ),
        each(
          $on('@mp/sticker-editor/add', VectorEditorKeyringFreeMobileF.handleKeyringEditorAdd({ tab_el })),
        ),
        each(
          $on(
            '@mp/sticker-editor/remove',
            VectorEditorKeyringFreeMobileF.handleKeyringEditorRemove({ tab_el }),
          ),
        ),
      ),
    );

    tab_el.__mp_fonts = await go(
      axios.get(`/${T.lang}/@api/maker/fonts`),
      (res) => res?.data,
      (fonts) => fonts ?? null,
    );
    await VectorEditorFontF.loadBaseFonts();
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  async appended(tab_el) {
    tab_el.__mp_handleWindowTouchMove = VectorEditorKeyringFreeMobileF.handleWindowTouchMove({ tab_el });
    $on('touchmove', tab_el.__mp_handleWindowTouchMove, { passive: false })(window);

    const keyring_editor = new EditorNS.AcrylicEditorNS.MobileNS.AcrylicMobileEditor({
      window,
      document,
      touch_margin: 6,
    });
    tab_el.__mp_keyring_editor = keyring_editor;
    keyring_editor.initDOM();
    keyring_editor.initEditor();
    keyring_editor.setArtBoardSize({
      width: tab_el.__mp_art_board_width,
      height: tab_el.__mp_art_board_height,
    });
    keyring_editor.setArtBoardShapePath({ path_data: tab_el.__mp_art_board_shape_path_data });
    keyring_editor.setArtBoardClipPathShapePath({ path_data: tab_el.__mp_art_board_shape_path_data });
    keyring_editor.turnOnArtBoardClipPath();
    const foreground_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
    if (tab_el.__mp_empty_template_el != null) {
      foreground_el.appendChild(tab_el.__mp_empty_template_el);
    }
    keyring_editor.setForeground(foreground_el);

    const editor_container_el = $find('.editor_container')(tab_el);
    if (editor_container_el != null) {
      keyring_editor.appendTo(editor_container_el);
    }

    try {
      await tab_el.__mp_preProcess?.({ addEl: ({ el }) => keyring_editor.addEl(el) });
    } catch (error) {
      console.error(error);
      const message =
        error?.__mp_alert_message ??
        T('modules::VectorEditor::Keyring::Free::Mobile::error_message::reopen_prev_keyring_error');
      $.alert(message).catch(noop);
    }
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {
    tab_el.__mp_is_prevent_touch_move = true;
  }, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    if (tab_el.__mp_keyring_editor != null) {
      const editor = tab_el.__mp_keyring_editor;

      editor.fitSize();
      const view_box_vo = editor.calculateViewBoxFitToArtBoard({
        padding_in_vcs: { top: 16 + 48, right: 16, bottom: 16 + 144, left: 16 },
      });
      editor.setViewBox(view_box_vo);
    }
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, _v) {
    tab_el.__mp_is_prevent_touch_move = false;
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, _v) {
    if (tab_el.__mp_handleWindowTouchMove != null) {
      $off('touchmove', tab_el.__mp_handleWindowTouchMove, { passive: false })(window);
    }

    if (tab_el.__mp_keyring_editor != null) {
      const editor = tab_el.__mp_keyring_editor;
      editor.destroy();
    }

    tab_el.__mp_is_prevent_touch_move = false;
    tab_el.__mp_handleWindowTouchMove = null;
    tab_el.__mp_keyring_editor = null;
    tab_el.__mp_art_board_width = 0;
    tab_el.__mp_art_board_height = 0;
    tab_el.__mp_art_board_shape_path_data = `M 0 0 Z`;
    tab_el.__mp_empty_template_el = null;
    tab_el.__mp_preProcess = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_fonts = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
