import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { DfImageEditorMuiF } from './module/DfImageEditorMuiF.js';
let last_frame_tag_cache = '';

export async function openImageEditorFrame({
  title,
  unique_frame_tag_for_cache,
  printable_file_info: {
    projection_id,
    product_id,
    up_id,
    face_name,
    total_qty,
    product_face_idx,
    base_product_face_id,
    color_code,
    bpcf_img_dataurl,
    cid,
  },
  press_types,
  getImageEditableSourceInfo,
  onRemovingFrame,
}) {
  await MuiF.openFrame(DfImageEditorMuiF.frame, async (frame, page, [tab]) => {
    frame.title = title;
    frame.frame_tag = unique_frame_tag_for_cache;
    frame.removing = onRemovingFrame;

    tab.printable_file_info = {
      cid,
      projection_id,
      product_id,
      up_id,
      face_name,
      total_qty,
      base_product_face_id,
      product_face_idx,
      color_code,
      bpcf_img_src: bpcf_img_dataurl,
    };

    tab.makeData = () => {
      return {
        title,
        press_types,
      };
    };

    frame.removed = () => {
      last_frame_tag_cache = '';
    };

    /*
     * design_source
     * @param {design_margin_x: {margin_direction: 'left' | 'right' | 'center', margin_x: number}}
     * @param {data_url: string}
     * @param {multiplier: number}
     * */
    const is_reuse_frame = last_frame_tag_cache === unique_frame_tag_for_cache;
    if (!is_reuse_frame) {
      const design_source = await getImageEditableSourceInfo();
      if (design_source == null) {
        throw new Error(`Not exist design_source`);
      }

      tab.design_source = design_source;
    }

    last_frame_tag_cache = unique_frame_tag_for_cache;
  });
}
