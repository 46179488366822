import { legacyHtml } from '../../../../Util/S/Function/util.js';

export const makeUserDetailMenuHtml = (select, user) => legacyHtml`
  <div class="user_tab">
    <h1>${user.name}</h1>
    <ul>
      <li class="detail" selected=${select == 'detail'}>
        <a href="/user/detail/${user.id}">정보</a>
      </li>
      <li class="point" selected=${select == 'point'}>
        <a href="/user/detail/${user.id}/point">포인트</a>
      </li>
      <li class="projection" selected=${select == 'projection'}>
        <a href="/user/detail/${user.id}/projection">주문내역</a>
      </li>
      <li class="review" selected=${select == 'review'}>
        <a href="/user/detail/${user.id}/review">리뷰</a>
      </li>
      <li class="review" selected=${select == 'old_message'}>
        <a href="/user/detail/${user.id}/old_message">이전 1:1문의</a>
      </li>
    </ul>
  </div>
`;
