import { find, go } from 'fxjs/es';
import { $addClass, $attr, $closest, $delegate, $find, $findAll, $qs } from 'fxdom/es';
import { SVGEditorAttrS } from '../../../Attr/S/Function/module/SVGEditorAttrS.js';
import { SVGEditorTextFrameMuiS } from '../../S/Mui/module/SVGEditorTextFrameMuiS.js';
import { SVGEditorTextF } from '../../../Text/F/Function/module/SVGEditorTextF.js';

export const font_tab = {
  ...SVGEditorTextFrameMuiS.font_tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData() {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    const { ___selected_el, __mp_sticker_editor } = $closest('.don_frame', tab_el).frame_opt;
    go(
      tab_el,
      $delegate('click', '.font', async (e) => {
        const select_font_el = $qs(
          '.don_tab[tab_name="/modules/SVGEditor/MobileText/S/Mui/text_tab.js"] .select_font',
        );
        if (select_font_el) {
          const deactive_src = go(e.currentTarget, $find('.deactive'), $attr('src'));
          select_font_el.dataset.is_selected_font = 'true';
          go(select_font_el, $find('.font'), (font_el) => (font_el.src = deactive_src));
        }
        await SVGEditorTextF.selectFontEvent({
          ___selected_el,
          __mp_sticker_editor,
          selected_el: e.currentTarget,
          font_els: $findAll('.font')(tab_el),
        });
      }),
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    const { ___selected_el } = $closest('.don_frame', tab_el).frame_opt;
    const selected_font_family = SVGEditorAttrS.get('font-family', ___selected_el);
    const index = go(
      tab_el,
      $findAll('.font'),
      find((font_el) => selected_font_family.indexOf(font_el.dataset.font_family) > -1),
      (font_el) => {
        if (font_el) {
          return go(font_el, $addClass('selected'), $closest('.swiper-slide'), (swiper_slide_el) => {
            const swiper_slide_els = go(
              swiper_slide_el,
              $closest('.swiper-wrapper'),
              $findAll('.swiper-slide'),
            );
            return [...swiper_slide_els].indexOf(swiper_slide_el);
          });
        } else {
          return 0;
        }
      },
    );
    new Swiper($find('.swiper-container')(tab_el), {
      pagination: { el: $find('.swiper-pagination')(tab_el) },
      initialSlide: index,
    });
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
