import { debounce } from 'fxjs/es';
import { makeDfProjectionListUpdate } from './fs.js';
import { $attr, $delegate, $find } from 'fxdom/es';
import { getProjectionLabelData, printProjectionLabel } from './label.js';

let interval = null;
const makeListUpdateAuto = function () {
  interval = setInterval(makeListUpdateDebounce, 8000);
};

const makeListUpdateAutoStop = function () {
  clearInterval(interval);
};

const makeBlurListUpdateAuto = function () {
  makeListUpdateAutoStop();
  $.once(window, 'focus', makeListUpdateAuto);
};

const makeListUpdateDebounce = debounce(function () {
  makeDfProjectionListUpdate();
}, 800);

export const moveScrollToFirstPrj = (tab_el) => {
  const is_prj_searched = !($find('input[name="p_id"]', tab_el)?.value === '');
  if (is_prj_searched) {
    const prj_side_bar_el = $find('.projection_sidebar', tab_el);
    prj_side_bar_el?.offsetTop && window.scrollTo(0, prj_side_bar_el.offsetTop);
  }
};

$.frame.defn_frame({
  frame_name: 'projection.all',
  el_id: 'projection_all',
  el_class: 'projection',
  page_name: 'projection.list',
  animation: false,
  appended: async function (tab_el) {
    $delegate('click', '.projection_code_image', async ({ currentTarget }) => {
      const label_data = await getProjectionLabelData($attr('data-projection_id', currentTarget));
      label_data && label_data.length && (await printProjectionLabel(label_data));
    })(tab_el);
  },
  showed: function (tab_el) {
    makeDfProjectionListUpdate();
    $.on(window, 'scroll', makeListUpdateDebounce);
    $.on(window, 'focus', makeDfProjectionListUpdate);
    makeListUpdateAuto();
    $.on(window, 'blur', makeBlurListUpdateAuto);
  },
  hiding: function () {
    $.off(window, 'scroll', makeListUpdateDebounce);
    $.off(window, 'focus', makeDfProjectionListUpdate);
    $.off(window, 'blur', makeBlurListUpdateAuto);
    makeListUpdateAutoStop();
  },
});
