import axios from 'axios';
import { ConfigSentryF } from '../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPReviewWriteTmplS } from '../../S/Tmpl/module/OMPReviewWriteTmplS.js';
import { OMPReviewWriteMuiF } from '../Mui/module/OMPReviewWriteMuiF.js';

export const openReviewWrite = async (up_id, closed = () => {}) => {
  try {
    $.don_loader_start();
    const prj = await axios.get('/@api/review/projection_status', {
      params: {
        up_id,
      },
    });

    if (prj || (await OMPCoreUtilF.confirm({ content: TT('review::write::write_12') }))) {
      const { data } = await axios.get(`/${TT.lang}/@api/review/up_c`, {
        params: {
          up_id,
        },
      });

      $.don_loader_end();
      if (!data.id) return;
      return OMPCoreUtilF.openFrameFit(OMPReviewWriteMuiF.frame, (frame, page, [tab]) => {
        frame.animation = true;
        tab.makeData = () => data;
        tab.inner_scroll_target = '.omp-review-write__content-wrapper textarea[name="comment"]';
        frame.closed = closed;
        frame.always_remove = true;
        page.title = TT('review::write::write_01');
        tab.template = OMPCoreUtilF.isMobile()
          ? OMPReviewWriteTmplS.reviewWriteTmplMo
          : OMPReviewWriteTmplS.reviewWriteTmplPc;
      });
    }
  } catch (e) {
    $.don_loader_end();
    ConfigSentryF.error(e);
  }
};
