import { $delegate } from 'fxdom/es';
import { go } from 'fxjs/es';
import axios from 'axios';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { ConfigSentryF } from '../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';

const catchError = async (e) => {
  $.don_loader_end();

  // MEMO: axios 에러
  if (e?.response?.data) {
    let error = {};
    if (typeof e?.response?.data === 'string') error = JSON.parse(e?.response?.data);
    else error = e?.response?.data;

    if (e?.response?.data?.title) {
      await OMPCoreUtilF.titleAlert(error);
    } else {
      await OMPCoreUtilF.alert({
        content: error?.message || TT('error::common::error_01'),
      });
    }
    return;
  }

  // MEMO: 프론트 에러
  if (e?.handle) {
    await OMPCoreUtilF.alert({
      content: e?.message || TT('error::common::error_01'),
    });
    return;
  }

  OMPCoreUtilF.alert({
    content: TT('error::common::error_01'),
  });
  ConfigSentryF.error(e);
};

export const popUpEvent = (tab_el) => {
  go(
    tab_el,
    $delegate('click', '.omp-email-verify-request__request-button', async () => {
      try {
        $.don_loader_start();
        const res = await axios.post('/@api/mp/resend_verification', {});
        $.don_loader_end();
        OMPCoreUtilF.titleAlert(res.data);
      } catch (e) {
        await catchError(e);
      }
    }),
  );
};
