import {
  every,
  filter,
  flatMap,
  go,
  map,
  mapObject,
  not,
  omit,
  pick,
  pipe,
  reject,
  some,
  values,
} from 'fxjs/es';
import { getCvDesigns, getCvObj, makeFilterCvObj } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { filterParallelCoords } from './filterParallelCoords.js';
import { getItsSf } from '../../../../Maker/F/getSth.js';
import { minusStrokeWidth } from '../../../../Maker/F/Fcanvas/stroke.js';
import { NewMakerCheckerS } from '../../S/Function/module/NewMakerCheckerS.js';
import { makeCanvasByUrl } from '../../../../Canvas/S/util.js';
import { isNotFullImage } from './isNotFullImage.js';

function getPoints(cv_obj) {
  const { top, left, width, height } = cv_obj.getBoundingRect();
  return {
    tl: { x: left, y: top },
    tr: { x: left + width, y: top },
    bl: { x: left, y: top + height },
    br: { x: left + width, y: top + height },
  };
}

function isContainPointOnlySquare({ tl, br }, point) {
  return tl.x <= point.x && br.x >= point.x && tl.y <= point.y && br.y >= point.y;
}

export const isParallelCheckPassOnlyWe = async (fcanvass, is_bounding_rect = false) => {
  return go(
    fcanvass,
    some(async (fcanvas) => {
      const { px_per_1cm } = getItsSf(fcanvas);
      const cv_print_area_xys = go(
        getCvObj(fcanvas._objects, 'cv_print_area'),
        minusStrokeWidth,
        ({ top, left, width, height }) => ({
          tl: { x: left, y: top },
          tr: { x: left + width, y: top },
          bl: { x: left, y: top + height },
          br: { x: left + width, y: top + height },
        }),
      );

      const cv_safety_area_minus_5mm_xys = go(
        getCvObj(fcanvas._objects, 'cv_safety_area'),
        minusStrokeWidth,
        ({ top, left, width, height }) => {
          const minus = 0.5 * px_per_1cm;
          return {
            width: width - minus,
            height: height - minus,
            top: top + minus,
            left: left + minus,
          };
        },
        ({ top, left, width, height }) => ({
          tl: { x: left, y: top },
          tr: { x: left + width, y: top },
          bl: { x: left, y: top + height },
          br: { x: left + width, y: top + height },
        }),
      );
      const all_xys = await go(
        getCvDesigns(fcanvas._objects),
        reject(async (cv_obj) => {
          if (cv_obj._data.cv_type === 'cv_image') {
            const img_canvas = await makeCanvasByUrl(G.to_150(cv_obj._data.image_url));
            return isNotFullImage(img_canvas);
          }
          return cv_obj._data.cv_type === 'cv_group';
        }),
        flatMap((cv_obj) =>
          go(is_bounding_rect ? getPoints(cv_obj) : cv_obj.oCoords, pick(['tl', 'tr', 'bl', 'br']), values),
        ),
      );
      const horizontal_xys = go(
        all_xys,
        filter((point) => {
          return (
            isContainPointOnlySquare(
              {
                tl: { x: 0, y: cv_print_area_xys.tl.y },
                br: { x: G.mp.maker.CANVAS_WIDTH, y: cv_safety_area_minus_5mm_xys.tl.y },
              },
              point,
            ) ||
            isContainPointOnlySquare(
              {
                tl: {
                  x: 0,
                  y: cv_safety_area_minus_5mm_xys.bl.y,
                },
                br: {
                  x: G.mp.maker.CANVAS_WIDTH,
                  y: cv_print_area_xys.br.y,
                },
              },
              point,
            )
          );
        }),
        map(
          pipe(
            (point) => JSON.parse(JSON.stringify(point)),
            omit(['corner']),
            mapObject((v) => Math.round(v)),
          ),
        ),
        (xys) => filterParallelCoords({ xys, is_horizontal: true }),
        reject(([_first, _last]) => {
          const cm = Math.abs(_first.x - _last.x) / px_per_1cm;
          return cm < 1;
        }),
      );
      const vertical_xys = go(
        all_xys,
        filter((point) => {
          return (
            isContainPointOnlySquare(
              {
                tl: {
                  x: cv_print_area_xys.tl.x,
                  y: 0,
                },
                br: { x: cv_safety_area_minus_5mm_xys.tl.x, y: G.mp.maker.CANVAS_HEIGHT },
              },
              point,
            ) ||
            isContainPointOnlySquare(
              {
                tl: {
                  x: cv_safety_area_minus_5mm_xys.tr.x,
                  y: 0,
                },
                br: {
                  x: cv_print_area_xys.br.x,
                  y: G.mp.maker.CANVAS_HEIGHT,
                },
              },
              point,
            )
          );
        }),
        map(
          pipe(
            (point) => JSON.parse(JSON.stringify(point)),
            omit(['corner']),
            mapObject((v) => Math.round(v)),
          ),
        ),
        (xys) => filterParallelCoords({ xys, is_horizontal: false }),
        reject(([_first, _last]) => {
          const cm = Math.abs(_first.y - _last.y) / px_per_1cm;
          return cm < 1;
        }),
      );
      return horizontal_xys.length || vertical_xys.length;
    }),
    not,
  );
};

export const isSthOnParallel = async (fcanvas, { big, small }, is_bounding_rect = false) => {
  const { px_per_1cm } = getItsSf(fcanvas);
  const cv_print_area_xys = go(big, ({ top, left, width, height }) => ({
    tl: { x: left, y: top },
    tr: { x: left + width, y: top },
    bl: { x: left, y: top + height },
    br: { x: left + width, y: top + height },
  }));

  const cv_safety_area_minus_5mm_xys = go(small, ({ top, left, width, height }) => ({
    tl: { x: left, y: top },
    tr: { x: left + width, y: top },
    bl: { x: left, y: top + height },
    br: { x: left + width, y: top + height },
  }));
  const all_xys = await go(
    getCvDesigns(fcanvas._objects),
    reject(async (cv_obj) => {
      if (cv_obj._data.cv_type === 'cv_image') {
        const img_canvas = await makeCanvasByUrl(G.to_150(cv_obj._data.image_url));
        return isNotFullImage(img_canvas);
      }
      return cv_obj._data.cv_type === 'cv_group';
    }),
    flatMap((cv_obj) =>
      go(is_bounding_rect ? getPoints(cv_obj) : cv_obj.oCoords, pick(['tl', 'tr', 'bl', 'br']), values),
    ),
  );
  const horizontal_xys = go(
    all_xys,
    filter((point) => {
      return (
        isContainPointOnlySquare(
          {
            tl: { x: 0, y: cv_print_area_xys.tl.y },
            br: { x: G.mp.maker.CANVAS_WIDTH, y: cv_safety_area_minus_5mm_xys.tl.y },
          },
          point,
        ) ||
        isContainPointOnlySquare(
          {
            tl: {
              x: 0,
              y: cv_safety_area_minus_5mm_xys.bl.y,
            },
            br: {
              x: G.mp.maker.CANVAS_WIDTH,
              y: cv_print_area_xys.br.y,
            },
          },
          point,
        )
      );
    }),
    map(
      pipe(
        (point) => JSON.parse(JSON.stringify(point)),
        omit(['corner']),
        mapObject((v) => Math.round(v)),
      ),
    ),
    (xys) => filterParallelCoords({ xys, is_horizontal: true }),
    reject(([_first, _last]) => {
      const cm = Math.abs(_first.x - _last.x) / px_per_1cm;
      return cm < 1;
    }),
  );
  const vertical_xys = go(
    all_xys,
    filter((point) => {
      return (
        isContainPointOnlySquare(
          {
            tl: {
              x: cv_print_area_xys.tl.x,
              y: 0,
            },
            br: { x: cv_safety_area_minus_5mm_xys.tl.x, y: G.mp.maker.CANVAS_HEIGHT },
          },
          point,
        ) ||
        isContainPointOnlySquare(
          {
            tl: {
              x: cv_safety_area_minus_5mm_xys.tr.x,
              y: 0,
            },
            br: {
              x: cv_print_area_xys.br.x,
              y: G.mp.maker.CANVAS_HEIGHT,
            },
          },
          point,
        )
      );
    }),
    map(
      pipe(
        (point) => JSON.parse(JSON.stringify(point)),
        omit(['corner']),
        mapObject((v) => Math.round(v)),
      ),
    ),
    (xys) => filterParallelCoords({ xys, is_horizontal: false }),
    reject(([_first, _last]) => {
      const cm = Math.abs(_first.y - _last.y) / px_per_1cm;
      return cm < 1;
    }),
  );
  return horizontal_xys.length || vertical_xys.length;
};

export const isFoldingCardParallalPass = (fcanvass) => {
  return go(
    fcanvass,
    some((fcanvas) => {
      return go(
        makeFilterCvObj(fcanvas._objects, 'cv_obj_alignment'),
        some((cv_obj) =>
          isSthOnParallel(fcanvas, {
            big: {
              top: cv_obj.top - fcanvas._px_per_1cm * 0.3,
              left: cv_obj.left - fcanvas._px_per_1cm * 0.3,
              width: cv_obj.width * cv_obj.scaleX + fcanvas._px_per_1cm * 0.3 * 2,
              height: cv_obj.height * cv_obj.scaleY + fcanvas._px_per_1cm * 0.3 * 2,
            },
            small: {
              top: cv_obj.top + fcanvas._px_per_1cm * 0.3,
              left: cv_obj.left + fcanvas._px_per_1cm * 0.3,
              width: cv_obj.width * cv_obj.scaleX - fcanvas._px_per_1cm * 0.3 * 2,
              height: cv_obj.height * cv_obj.scaleY - fcanvas._px_per_1cm * 0.3 * 2,
            },
          }),
        ),
      );
    }),
    not,
  );
};

export const isParallelCheckPassOnlyWeForServer = async ({
  product_faces2,
  base_product_size_id,
  base_product_faces,
}) => {
  if (!every([product_faces2, base_product_size_id, base_product_faces]))
    throw Error('require product_faces2, base_product_size_id, base_product_faces');
  return go(
    product_faces2.value,
    some(async (pf) => {
      const size_face = NewMakerCheckerS.getSizeFace({
        base_product_faces,
        base_product_size_id,
        base_product_face_id: pf.bpf_id,
      });
      const { px_per_1cm, print, safety } = size_face;
      const cv_print_area_xys = go(print.px, ({ top, left, width, height }) => ({
        tl: { x: left, y: top },
        tr: { x: left + width, y: top },
        bl: { x: left, y: top + height },
        br: { x: left + width, y: top + height },
      }));
      const cv_safety_area_minus_5mm_xys = go(
        safety.px,
        ({ top, left, width, height }) => {
          const minus = 0.5 * px_per_1cm;
          return {
            width: width - minus,
            height: height - minus,
            top: top + minus,
            left: left + minus,
          };
        },
        ({ top, left, width, height }) => ({
          tl: { x: left, y: top },
          tr: { x: left + width, y: top },
          bl: { x: left, y: top + height },
          br: { x: left + width, y: top + height },
        }),
      );
      const all_xys = await go(
        pf.designs,
        filter(({ angle }) => angle === 0),
        reject(async (cv_obj) => {
          if (cv_obj._data.cv_type === 'cv_image') {
            const img_canvas = await makeCanvasByUrl(G.to_150(cv_obj._data.image_url));
            return isNotFullImage(img_canvas);
          }
          return cv_obj._data.cv_type === 'cv_group';
        }),
        map(({ top, left, width, height, scaleX, scaleY }) => ({
          tl: { x: left, y: top },
          tr: { x: left + width * scaleX, y: top },
          bl: { x: left, y: top + height * scaleY },
          br: { x: left + width * scaleX, y: top + height * scaleY },
        })),
        flatMap(values),
      );
      const horizontal_xys = go(
        all_xys,
        filter((point) => {
          return (
            isContainPointOnlySquare(
              {
                tl: { x: 0, y: cv_print_area_xys.tl.y },
                br: { x: G.mp.maker.CANVAS_WIDTH, y: cv_safety_area_minus_5mm_xys.tl.y },
              },
              point,
            ) ||
            isContainPointOnlySquare(
              {
                tl: {
                  x: 0,
                  y: cv_safety_area_minus_5mm_xys.bl.y,
                },
                br: {
                  x: G.mp.maker.CANVAS_WIDTH,
                  y: cv_print_area_xys.br.y,
                },
              },
              point,
            )
          );
        }),
        map(mapObject((v) => Math.round(v))),
        (xys) => filterParallelCoords({ xys, is_horizontal: true }),
        reject(([_first, _last]) => {
          const cm = Math.abs(_first.x - _last.x) / px_per_1cm;
          return cm < 1;
        }),
      );
      const vertical_xys = go(
        all_xys,
        filter((point) => {
          return (
            isContainPointOnlySquare(
              {
                tl: {
                  x: cv_print_area_xys.tl.x,
                  y: 0,
                },
                br: { x: cv_safety_area_minus_5mm_xys.tl.x, y: G.mp.maker.CANVAS_HEIGHT },
              },
              point,
            ) ||
            isContainPointOnlySquare(
              {
                tl: {
                  x: cv_safety_area_minus_5mm_xys.tr.x,
                  y: 0,
                },
                br: {
                  x: cv_print_area_xys.br.x,
                  y: G.mp.maker.CANVAS_HEIGHT,
                },
              },
              point,
            )
          );
        }),
        map(mapObject((v) => Math.round(v))),
        (xys) => filterParallelCoords({ xys, is_horizontal: false }),
        reject(([_first, _last]) => {
          const cm = Math.abs(_first.y - _last.y) / px_per_1cm;
          return cm < 1;
        }),
      );
      return horizontal_xys.length || vertical_xys.length;
    }),
    not,
  );
};
