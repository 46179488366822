import { $find } from 'fxdom/es';
import { go } from 'fxjs/es';
import { makeErrorTabHtml } from '../Tmpl/tmpl.tabs.js';
export function base_tab({ mui_ref_path, title, tag, template }) {
  return {
    tab_name: tag,
    el_id: '',
    el_class: '',
    tag,
    title,
    inner_scroll_target: '.tbody',
    template: (data) => {
      try {
        return template(data);
      } catch (err) {
        return makeErrorTabHtml({ err });
      }
    },
    render(data) {
      const el_tab = document.querySelector(`div[tab_name="${tag}"]`);
      if (el_tab) {
        const tab_wrapper = go(el_tab, $find('.don_wrapper'));
        if (tab_wrapper) {
          tab_wrapper.innerHTML = template(data);
        }
      }
      this.rendered(el_tab);
    },
  };
}
