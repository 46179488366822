import { html } from 'fxjs/es';

export const fn = ({ src1, src2 }) => html`
  <div class="maker_guide_body">
    <div class="img src1"><img src="${src1}" alt="" /></div>
    ${src2 ? html`<div class="img src2" style="display:none;"><img src="${src2}" alt="" /></div>` : html``}
    <div class="btns">
      <button class="btn_confirm">${T('maker_error_alert::확인했습니다.')}</button>
    </div>
  </div>
`;
