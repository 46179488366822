import { html, strMap } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';

export const relationBPTmpl = ({ custom_badges, bp_ids }) => {
  return html`<div class="custom-badges__relation-bp-container" data-fx-json="${$dataStr(bp_ids)}">
    <div class="btn-area">
      <button type="button" class="custom-badges__relation-bp-save-btn btn btn-primary">저장</button>
      <div class="close">X</div>
    </div>
    <div class="content">
      <table class="table table-bordered custom-badges__table text-center">
        <thead class="table-light">
          <tr>
            <th></th>
            <th>국문</th>
            <th>영문</th>
            <th>일문</th>
            <th>순서</th>
          </tr>
        </thead>
        <tbody>
          ${strMap(
            (badge) => html`
              <tr data-fx-json="${$dataStr(badge)}">
                <td>
                  <label class="checkbox-label"
                    ><input type="checkbox" name="check" ${badge.has_badge ? 'checked' : ''}
                  /></label>
                </td>
                <td>${badge.name}</td>
                <td>${badge.name_en}</td>
                <td>${badge.name_jp}</td>
                <td>${badge.no || ''}</td>
              </tr>
            `,
            custom_badges,
          )}
          ${!custom_badges?.length
            ? html`<tr class="no-data">
                <td colspan="5">없습니다.</td>
              </tr>`
            : html``}
        </tbody>
      </table>
    </div>
  </div>`;
};
