import { find, go, sel } from 'fxjs/es';

export async function basicDataSetting({ base_product_id, product_color_id }) {
  if (base_product_id) {
    product_color_id = await go(
      $.get('/@api/only_base_product_with_pc_id', {
        base_product_id,
      }),
      sel('product_color_id'),
    );
  }
  if (!product_color_id) throw Error('no product_color_id');
  const product_color = await $.get('/@api/product_color', { id: product_color_id });
  /*DF*/
  if (base_product_id) {
    product_color.base_product_size_id = go(
      product_color._.base_product._.base_product_sizes,
      find((bps) => bps.is_thumb),
      sel('id'),
    );
  }

  if (!product_color.base_product_size_id) throw Error('no thumb base_product_size');
  box.set('maker', {});
  box.set('maker->up_c', {
    base_product_id: product_color.base_product_id,
    base_product_color_id: product_color.base_product_color_id,
    base_product_size_id: product_color.base_product_size_id,
  });
  box.set('maker->product_color', product_color);
}
