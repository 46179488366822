import { html } from 'fxjs/es';

export const en_terms = () => html`
  <div class="terms_body">
    <div class="title"><h1>Terms of Use</h1></div>
    <div class="body">
      <div class="text_body">
        <div class="paragraph">
          <h3>Article 1 (Purpose)</h3>
          <p>
            These Terms of Use are designed to stipulate the rights, duties and responsibilities of LINE
            Friends Creator (hereinafter referred to as the "Mall") and the "Customer" (defined below) in the
            use of services related to e-commerce (hereinafter referred to as the "Services") via
            print-on-demand provided by the "Mall," operated by LINE FRIENDS Corporation (e-commerce business
            operator).
          </p>
          <p>
            ※ These Terms of Use shall apply to electronic transactions using PC communication, wireless
            device and etc. unless such terms are contrary to the inherent nature of said activities.
          </p>
        </div>
        <div class="paragraph">
          <h3>Article 2 (Definition)</h3>
          <ol>
            <li>
              ① “Mall” refers to a virtual business establishment where LINE FRIENDS Corporation can trade
              goods and/or services (hereinafter referred to as "Products") using computers and other
              information and communication facilities to provide said "Products" to "Customer," while it may
              also indicate the business operator of the "Mall."
            </li>
            <li>
              ② "Customer" refers to a member and non-member who receives the "Services" provided by the
              "Mall" under this Agreement.
            </li>
            <li>
              ③ “Member” means a person who has registered as a member of the "Mall" for continued use of the
              "Services" provided by the "Mall."
            </li>
            <li>
              ④ “Non-member” refers to a person who uses the "Services" provided by the "Mall" without
              subscribing as a “Member.”
            </li>
            <li>
              ⑤ “Guideline” refer to the guideline for customizing the Products as provided in Attachment 1 or
              posted on the “Mall” which may be updated from time to time.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 3 (Specification, Description, and Revision of Terms of Use, etc.)</h3>
          <ol>
            <li>
              ① The "Mall" posts the content of these Terms of Use, the name of the representative, the
              address of the sales office (including the address of a place where the consumer complaint can
              be handled), telephone number, fax number, e-mail address, business registration number,
              mail-order business registration number, and staff in charge of privacy management, etc. on the
              initial service screen of the "Mall" to allow an easy access of the "Customer." However, the
              "Customer" may view the Terms of Use via a linked screen.
            </li>
            <li>
              ② Prior to the "Customer’s" consent to the Terms of Use, the "Mall" has to seek the confirmation
              of the "Customer" by providing a separate linked screen or pop-up screen to help "Customer's"
              understanding of important details such as subscription withdrawal, delivery liability, and
              refund conditions.
            </li>
            <li>
              ③ The "Mall" may modify these Terms of Use to the extent that they do not violate the relevant
              statutes, including the “Act on the Consumer Protection in Electronic Commerce, Etc.,” the “Act
              on the Regulation of Terms and Conditions,” the “Framework Act on Electronic Documents and
              Transactions,” the “Electronic Financial Transactions Act,” the “Digital Signature Act,” the
              “Act on Promotion of Information and Communications Network Utilization and Information
              Protection, Etc.,” and the “Framework Act on the Consumers.”
            </li>
            <li>
              ④ In the event where any modification is made to these Terms of Use, the effective date and
              reasons of such modification will be notified on the initial screen page of the "Mall" for a
              period between seven (7) days prior to the effective date until one (1) day prior to said
              effective date. However, a modification to the Terms of Use that is adverse to the "Customer"
              shall be notified within the grace period of at least thirty (30) days prior to the effective
              date.
            </li>
            <li>
              ⑤ In the event where the "Mall" makes amendment to the Terms of Use, the amended Terms of Use
              will be deemed to have been consented if the "Customer" does not express rejection within seven
              (7) days from the announcement or notification date (thirty (30) days for a significant
              modification according to Section 4 of this Article).
            </li>
            <li>
              ⑥ The matters not specified in these Terms of Use and their interpretations will be subject to
              the "Act on the Consumer Protection in Electronic Commerce, etc.," the "Act on the Regulation of
              Terms and Conditions," and the “ENFORCEMENT DECREE OF THE ACT ON THE CONSUMER PROTECTION IN
              ELECTRONIC COMMERCE, ETC.” set forth by the Korea Fair Trade Commission, and related statutes or
              business practices.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 4 (Service Provision and Modification)</h3>
          <ol>
            <li>
              ① The "Mall" carries out the following tasks:
              <ol>
                <li>
                  1. Providing information about goods and/or services, and entering into purchase agreements;
                </li>
                <li>2. Manufacture and shipping of goods and/or services under purchase agreements;</li>
                <li>3. Other tasks set by the "Mall."</li>
              </ol>
            </li>
            <li>
              ② "Mall" may change the provided content of “Products” under the future agreements if the
              "Products" are sold out or there is a modification in the technical specifications. In this
              case, the change and the delivery date will be immediately notified through the location where
              these Terms of Use remains posted.
            </li>
            <li>
              ③ Where there is a change to the contents to be provided by the "Mall" to the "Customer" under
              the agreements due to sold out "Products" or a change in the technical specifications, the
              "Mall" will notify the "Customer" of the grounds for such change.
            </li>
            <li>
              ④ As for the preceding paragraph, the "Mall" will compensate for actual damages inflicted to the
              "Customer" actually caused by such changes. However, this shall not apply where the act of the
              "Mall" is proved to be neither intentional nor negligent.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 5 (Service Suspension)</h3>
          <ol>
            <li>
              ① The "Mall" may temporarily suspend the "Services" in the event of maintenance inspection,
              replacement and failure, or communication failure of the information and communication
              facilities, such as computers. In this case, the "Customer will be notified of the fact
              indicating the temporary suspension of "Services" and its reasons on the initial page of the
              "Mall."
            </li>
            <li>
              ② The "Mall" will compensate for damages sustained by the "Customer" or third parties due to the
              temporary suspension of the "Services" under the reasons of paragraph 1. However, this shall not
              apply where the act of "Mall" is proved to be neither intentional nor negligent.
            </li>
            <li>
              ③ In the event that the "Services" cannot be provided due to a change in business type, business
              abandonment, or merge of different companies, the "Mall" shall notify the "Customer" in the
              manner specified in Article 8.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 6 (Member Subscription)</h3>
          <ol>
            <li>
              ① The "Customer" applies for membership by filling out the member information according to the
              subscription form set by the "Mall" and expressing their intent to consent to these Terms of Use
              and the Privacy Policy.
            </li>
            <li>
              ② The "Mall" will register the "Customer" who has applied for the member subscription under
              paragraph 1 above as long as the "Customer" does not correspond to any of the following
              subparagraph:
              <ol>
                <li>
                  1. Where the applicant has previously lost his or her membership pursuant to Article 7
                  Section 3 of these Terms of Use, provided that, this will not apply to a case where two
                  years have elapsed since the loss of the applicant’s membership pursuant to Article 7
                  Section 3 or the applicant has obtained approval of the re-subscription from the Mall.
                </li>
                <li>2. Where there is any false, omitted or misleading entry;</li>
                <li>
                  3. Where the applicant is under the age of 14 years old as of the date of member
                  subscription;
                </li>
                <li>
                  4. Where the registration as a non-classified member is deemed to cause a major technical
                  difficulty.
                </li>
                <li>
                  5. The member subscription agreement will be deemed to have concluded at the moment the
                  approval of the "Mall" reaches the "Member."
                </li>
                <li>
                  6. In the event that there is a change in the registration at the time of member
                  subscription, the member is required to inform the changed matters to the "Mall" by means
                  such as the modification to the member information within a reasonable period of time.
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 7 (Membership Withdrawal, Loss of Qualification, etc.)</h3>
          <ol>
            <li>
              ① The "Member" may request withdrawal from the "Mall" at any time, and the "Mall" will
              immediately process the membership withdrawal. The membership qualification will be lost upon
              membership withdrawal, and various discount coupons and event benefits provided by the company
              will also be expired. However, where there is any unused reserve fund, etc. left at the time of
              membership withdrawal, the right of the Member to the reserve fund, etc. will be deemed to have
              been waived according to the "Member's" will and such reserve fund will cease to exist upon
              withdrawal.
            </li>
            <li>
              ② In the event that a member falls under each of the following reasons, the "Mall" may restrict
              or suspend the right to use the "Services" of the "Member."
              <ol>
                <li>
                  1. Where false information has entered in the member information or stolen information of
                  others has been provided;
                </li>
                <li>
                  2. Where the "Member" fails to pay his/her debt incurred in relation to the payment for the
                  "Products" purchased on the "Mall" or other use of the "Mall" until the due date;
                </li>
                <li>
                  3. Where the "Member" threatens to the order of e-commerce by means such as obstructing the
                  use of the "Mall" by others or stealing their information;
                </li>
                <li>
                  4. Where the "Member" uses the "Mall" to conduct an act that is prohibited by these Terms of
                  Use, Guideline (see attached Guideline) or the public order and customs;
                </li>
                <li>
                  5. Where the "Member" intentionally conducts other acts that obstruct the operation of the
                  "Services."
                </li>
              </ol>
            </li>
            <li>
              ③ After the "Mall" restricts and suspends membership, the "Mall" may forfeit the membership if
              the same act is repeated more than once or the cause is not corrected within thirty (30) days.
            </li>
            <li>
              ④ Where the "Mall" forfeits the membership, the member registration will be canceled. In such a
              case, the "Member" will be notified and given an opportunity to justify his/her act within a set
              period of at least thirty (30) days before the member registration become canceled.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 8 (Customer Notification)</h3>
          <ol>
            <li>
              ① A notification to the "Customer" may be made via e-mail, SMS, telephone or fax designated by
              agreement with the member in advance.
            </li>
            <li>
              ② The "Mall" may post a notification for multiple and unspecified "Members" on the bulletin
              board for more than one week instead of sending the notification under the preceding paragraph.
              However, the notification under the preceding paragraph will be sent for the matters that have
              significant impact on the transactions with the "Customer."
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 9 (Purchase Request)</h3>
          <p>
            The "Customer" requests for a purchase in accordance with the following or similar methods, and
            the "Mall" will provide each of the following information in the "Customer" request for purchase
            in an easily understandable manner:
          </p>
          <ol>
            <li>1. Search and selection of "Products";</li>
            <li>2. Recipient's name, address, phone number, e-mail address (or mobile phone number);</li>
            <li>
              3. Details of the Terms of Use, "Services" with limited subscription withdrawal rights, shipping
              charges, installation costs, etc.;
            </li>
            <li>
              4. An indication to show one's consent to this Agreement and confirms or rejects the matters in
              paragraph 3 above (e.g. mouse click);
            </li>
            <li>
              5. Consent to the purchase request for "Products" and the relevant confirmation or the consent
              to the confirmation of the "Mall";
            </li>
            <li>6. Selection of payment means.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 10 (Conclusion of an Agreement)</h3>
          <ol>
            <li>
              ① The "Mall" may not accept a purchase request as provided in Article 9 under any of the
              following circumstances: However, if an agreement is signed with a minor, the minor will be
              notified that he/she or his/her legal representative may cancel the agreement if a consent of
              his/her legal representative is not obtained.
              <ol>
                <li>1. Where there is any false, omitted or misleading entry;</li>
                <li>2. Where a minor purchases "Products" prohibited by the Juvenile Protection Act";</li>
                <li>
                  3. Where the approval of the purchase request is deemed to cause a major technical
                  difficulty.
                </li>
                <li>4. Where the design of “Products” violates Guideline.</li>
              </ol>
            </li>
            <li>
              ② The agreement will be deemed to have been concluded at the time when the acceptance of the
              "Mall" reaches the "Customer" in the form of notification of receipt confirmation under Article
              12 paragraph 1.
            </li>
            <li>
              ③ The "Mall’s" expression of approval must include information on whether the confirmation of
              the "Customer’s" purchase request and the sales are available and the information on the
              modification and cancellation of purchase request.
            </li>
            <li>
              ④ If the "Mall" discovers any of the reasons under the subparagraphs of paragraph 1 after the
              conclusion of an agreement, the "Mall" may cancel the agreement immediately and upon the
              agreement cancellation, the payment for "Products" paid by the "Customer" will be refunded
              immediately.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 11 (Payment Method)</h3>
          <p>
            The payment for "Products" purchased at the "Mall" can be made using the any of the following
            means available: However, the "Mall" does not collect any commission fees in addition to the
            payment of "Products" for the payment means of the "Customer."
          </p>
          <ol>
            <li>1. Various account transfers, such as Internet banking;</li>
            <li>2. Various card payments, such as prepaid, debit, credit card, etc.;</li>
            <li>3. Online deposit without a bankbook;</li>
            <li>4. Payment by digital currency;</li>
            <li>5. Payment by points and coupons provided by the "Mall";</li>
            <li>6. Payment by gift vouchers under contract with or recognized by the "Mall";</li>
            <li>7. Payment by other electronic payment means, etc.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 12 (Notification of Receipt, and Change and Cancellation of Purchase Request)</h3>
          <ol>
            <li>
              ① The "Mall" will notify the "Customer" of receipt confirmation when the "Customer" files a
              purchase request.
            </li>
            <li>
              ② The "Customer" who receives the receipt confirmation notice may request to change or cancel
              the purchase request immediately after receiving the receipt confirmation notice, and if there
              is a request made prior to the shipping, the "Mall" must process according to the request
              without delay. However, if the payment has already been made, such request will be subject to
              the provisions of Article 15 concerning subscription withdrawal, etc.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 13 (Supply of "Products")</h3>
          <ol>
            <li>
              ① The "Mall" will take measures such as placing orders, packaging, etc. to deliver the
              "Products" within seven (7) days from the date of execution of the purchase agreement, unless
              there is a separate agreement regarding the supply timing of "Products" with the "Customer."
              However, if the "Mall" has already received all or part of the payment for "Products," the
              measures will be enforced within three (3) business days from the date of receipt of all or part
              of the payment. At this time, the "Mall" will enforce appropriate measures to allow that the
              "Customer" to check the supply procedure and progress regarding the "Products"
            </li>
            <li>
              ② The "Mall" will specify delivery means, the party who bears the shipping charges by means,
              delivery time-line by means, etc. for goods purchased by "Customer." If the shipping exceeds the
              agreed the delivery time-line due to intentional or negligent act of the “Mall” the "Customer"
              will be indemnified for damages resulting from such delay.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 14 (Refund)</h3>
          <p>
            The "Mall" will notify the "Customer" of the reason for not being able to deliver or supply the
            "Products" purchased by the "Customer" without delay, and refund within three (3) business days
            from the date of receipt of the payment or take necessary measures for the refund if any payment
            has been made.
          </p>
        </div>
        <div class="paragraph">
          <h3>Article 15 (Agreement Cancellation, etc.)</h3>
          <ol>
            <li>
              ① The "Customer" who has entered into an agreement for the purchase of "Products" with the
              "Mall" may cancel such agreement within seven days of the receipt confirmation.
            </li>
            <li>
              ② The "Customer" is not allowed to return or exchange the "Products" if the "Products" have been
              delivered and fall under any of the following subparagraph:
              <ol>
                <li>
                  1. Where the "Products" are lost or damaged for a reason attributable to the "Customer"
                  (provided that, the agreement may be canceled if the packaging has been damaged to verify
                  the content of the "Products") the subscription can be withdrawn);
                </li>
                <li>
                  2. Where the value of "Products" has been significantly reduced by their use or partial
                  consumption by the "Customer";
                </li>
                <li>
                  3. Where the value of "Products" has been significantly reduced over time to the point where
                  their resale is difficult;
                </li>
                <li>
                  4. Where the “Customer” could duplicate or copy the "Products" with the same performance and
                  the packaging of the original "Products" has been damaged;
                </li>
                <li>
                  5. Where "Products" are individually customized according to the instruction of the
                  "Customer" or other similar goods;
                </li>
                <li>
                  6. Where the "Customer" has been notified of the restriction on the agreement cancellation
                  and provided consent.
                </li>
              </ol>
            </li>
            <li>
              ③ With respect to Section 2 Subsection 2 to 4, if the "Mall" has not provided the restriction on
              the agreement cancellation in advance that is easily discoverable by consumers or failed to take
              measures such as providing trial products, the agreement cancellation, etc. of the "Customer"
              will not be restricted.
            </li>
            <li>
              ④ Notwithstanding the provisions under Section 1 and 2 above, the "Customer" may cancel
              agreement, etc. within three (3) months from the date of receiving the "Products" or within
              thirty (30) days from the date in which he/she became aware of such fact if the content of the
              "Products" are different from those under indication or advertisement or the details under the
              agreement.
            </li>
            <li>
              ⑤ The “Mall” may cancel the purchase agreement with “Customer” if any “Products” designed or
              customized by “Customer” violates “Guidelines”
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 16 (Effect of Agreement Cancellation, etc.)</h3>
          <ol>
            <li>
              ① The "Mall" will refund the payment of the "Products" already made within three (3) business
              days if the "Products" have been returned by the "Customer." In this case, if the "Mall" has
              delayed the refund for the payment of the "Products" to the "Customer," the interest for such
              delay will be paid based on the calculation of delayed period multiplied by the delayed interest
              rate stipulated in the "Enforcement Decree of the Act on the Consumer Protection in Electronic
              Commerce, etc."
            </li>
            <li>
              ② Where the "Customer" has made payment for the "Products" with credit card or digital currency
              as a payment means, the "Mall" requests the provider of such payment means to suspend or cancel
              charging for the payment of the "Products" without delay while providing refund of the above
              amount.
            </li>
            <li>
              ③ In the agreement cancellation, the cost required for returning the provided the "Products"
              will be paid by the "Customer." The "Mall" will not claim for penalty or damages from the
              "Customer" for such agreement cancellation. However, if the agreement cancellation, etc. has
              been carried out because the content of "Products" are different from those under indication or
              advertisement or the details under the agreement, the cost required for returning the "Products"
              will be paid by the "Mall."
            </li>
            <li>
              ④ If the "Customer" paid for shipping when the "Products" were provided, the "Mall" clearly
              indicates who will pay for the cost incurred by an agreement cancellation.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 17 (Privacy Policy)</h3>
          <ol>
            <li>
              ① The "Mall" collects the minimum amount of personal information required to provide "Services,"
              and performs purchase agreements when collecting the personal information of the "Customer."
            </li>
            <li>
              ② The "Mall" makes sure to obtain the consent of the "Customer" when collecting personally
              identifiable personal information of the "Customer."
            </li>
            <li>
              ③ The personal information provided is not be used for purposes other than the stipulated
              purpose or provided to a third party without the consent of the "Customer," and the "Mall" will
              be held fully liable. However, the following exceptions may apply:
              <ol>
                <li>
                  1. Informing the shipping company of the minimum user information (name, address, phone
                  number) required for delivery;
                </li>
                <li>
                  2. Where the personal information is provided in the form not personally identifiable for
                  statistical preparation, academic research or market research;
                </li>
                <li>
                  3. Where it is required for the settlement of the payment in accordance with the transaction
                  of "Products";
                </li>
                <li>4. Where it is required for personal identification to prevent identity theft;</li>
                <li>5. Where there are legal provisions or inevitable reasons under the statues.</li>
              </ol>
            </li>
            <li>
              ④ If the "Mall" is required to obtain the consent of the "Customer", the "Mall" must specify in
              advance including purpose of collection and use of the information, and matters related to
              providing information to third parties
            </li>
            <li>
              ⑤ The "Customer" may request an access to and an error correction of personal information
              possessed by the "Mall" at any time, and the "Mall" is obliged to take necessary measures
              without delay. If the "Customer" requests correction of an error, the "Mall" will not use the
              personal information until the error is corrected.
            </li>
            <li>
              ⑥ The "Mall" must limit the persons handling personal information to a minimum in order to
              protect personal information and take full responsibility for damages caused by loss, theft,
              leakage, non-consented provision to third parties and falsification of the personal information
              of the "Customer" including credit card bank account information.
            </li>
            <li>
              ⑦ The "Mall" or a third party who has received personal information from the "Mall" destroy the
              personal information without delay once the purpose of collection or provision is fulfilled.
            </li>
            <li>
              ⑧ Other information related to handling, such as the provision of personal information, will be
              notified in detail in the Privacy Policy in the initial screen of the "Mall."
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 18 (Duties of the "Mall")</h3>
          <ol>
            <li>
              ① The "Mall" must make its utmost efforts to provide the "Products" in a steady and stable
              manner as provided under this Agreement without committing acts against the laws and
              regulations, these Terms of Use, and the public order and good morals.
            </li>
            <li>
              ② The "Mall" must be equipped with a security system for the protection of personal information
              (including credit information) of the "Customer" to allow the "Customer" to safely use the
              Internet services.
            </li>
            <li>
              ③ In the event that the "Mall" causes any damage to the "Customer" by providing unfair labels or
              advertisements that fall within the scope of Article 3 of the "Act on Fair Labeling and
              Advertising," the "Mall" will be held liable for such damages.
            </li>
            <li>
              ④ The "Mall" does not send for-profit advertising e-mails against the will of the "Customer."
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 19 (Duties for "Customer" Member IDs and Passwords)</h3>
          <ol>
            <li>
              ① Except in the case for Article 17, the "Customer" is responsible for managing his/her member
              ID and password.
            </li>
            <li>② The "Customer" must not allow third parties to use his/her member ID and password.</li>
            <li>
              ③ If the "Customer" becomes aware that his/her member ID and password have been stolen or used
              by a third party, he/she must notify the "Mall" immediately and follow the guidance of the
              "Mall," if provided. The "Mall" will not be held liable for any disadvantages arising from the
              failure to notify the "Mall" despite his/her awareness or to follow the guidance of the "Mall"
              even if notified.
            </li>
            <li>
              ④ The "Mall" will not be held liable for damages caused by the "Customer’s" negligent management
              of his/her member ID or allowing its use by a third party.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 20 (Duties of the "Customer")</h3>
          <p>The "Customer" must not conduct any of the following acts:</p>
          <ol>
            <li>1. Registration of false information upon application or modification;</li>
            <li>2. Theft of information of others;</li>
            <li>3. Modification of information posed on this "Mall";</li>
            <li>
              4. Sending or posting information (computer program, etc.) other than that specified by the
              "Mall";
            </li>
            <li>
              5. Infringement on intellectual property rights, such as copyrights, of the "Mall" and other
              third parties;
            </li>
            <li>6. Acts of defaming or obstructing the work of a third party;</li>
            <li>
              7. The act of disclosing or posting obscene or violent messages, video, voice, or other
              information against the public order and good morals on the "Mall."
            </li>
            <li>8. Violations of any terms of Guideline.</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 21 (Copyright Ownership and Restricted Use)</h3>
          <ol>
            <li>
              ① Copyright and other intellectual property rights for works created by the Mall are reserved to
              the "Mall."
            </li>
            <li>
              ② The "Customer" must not use information with intellectual property rights reserved to the
              "Mall" among the information obtained during his/her use of the "Mall" for profit, or allow a
              third party use such information through reproduction, transmission, publication, distribution,
              broadcasting, and other means.
            </li>
            <li>
              ③ The "Mall" must notify the "Customer" of the use of copyright reserved to the corresponding
              "Customer" under the Agreement.
            </li>
            <li>
              ④ Where a claim for loss and damage has been filed against the "Mall" by any action by
              "Customer" including but not limited to infringement of the trademark, copyright, program
              copyright, and other intellectual property infringement, the "Customer" must indemnify the
              "Mall" for all the damages and losses caused by the act of the “Customer” and the "Customer"
              will be fully liable for all damages sustained by the "Mall" due to such failure.
            </li>
            <li>
              ⑤ The "Mall" may delete or modify a post uploaded by a "Member" without notice when the Mall
              determines that the relevant post falls within the scope of any of the following subparagraphs,
              and the "Mall" will not be held liable for such deletion or modification whatsoever.
              <ol>
                <li>1. A Spam post and commercial post;</li>
                <li>
                  2. A post that defames others by spreading false information with the aim of maligning
                  others;
                </li>
                <li>
                  3. A post containing personal information of others without consent and contents that
                  infringe upon the rights such as copyright of a third party;
                </li>
                <li>
                  4. Where the post is deemed to be in violation of other related statutes and the guidelines
                  of the "Mall."
                </li>
                <li>5. Violates Guidelines</li>
              </ol>
            </li>
            <li>
              ⑥ Where a post of a "Customer" contains contents that violate the relevant statutes such as the
              “Information and Communications Network Act” and the "Copyright Act,” the administrator may
              request for cessation of posting and deletion of such post under the procedure set out by
              relevant statutes, and the "Mall" must carry out necessary measures according to such relevant
              statutes.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 22 (Dispute Resolution)</h3>
          <ol>
            <li>
              ①The "Mall" places priority in processing complaints submitted from the "Customer." However, if
              it is difficult to expedite the process, the "Customer" will be informed of the reason and the
              schedule of the processing.
            </li>
            <li>
              ② In the event that the "Customer" has requested for a damage relief related to an e-commerce
              dispute between the "Mall" and the "Customer," the parties may rely on the mediation held by the
              Korea Fair Trade Commission or the dispute resolution agency commissioned by the mayor or
              provincial governor.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 23 (Product Coupon)</h3>
          <ol>
            <li>
              ① The "Mall" may issue gift coupons to the "Customer" for a certain amount or percentage of
              discount when purchasing specified products.
            </li>
            <li>
              ② The "Member" is allowed to use the product coupons only for his/her own purchases, and in no
              event will the "Member" be allowed to sell or transfer the coupons to others.
            </li>
            <li>
              ③ The use of product coupons may be limited for some items or amount and they cannot be uses
              once they expire.
            </li>
            <li>
              ④ Once a "Member" withdraws his/her subscription, the product coupons will cease to exist.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 24 (Indemnity)</h3>
          <ol>
            <li>
              ①The "Mall" will not be held liable if the "Services" cannot be provided due to natural
              disasters, force majeure and other reasons beyond the reasonable control of the Mall.
            </li>
            <li>
              ② The "Mall" will not be held liable if the "Services" cannot be provided due to the reasons
              attributable to "Customer."
            </li>
            <li>
              ③ The "Mall" will not be held liable for damages incurred to the "Customer" or a third party due
              to an intentional act or negligence of the "Customer" in relation to the use of the "Services."
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 25 (Information Provision and Advertisement Publication)</h3>
          <ol>
            <li>
              ① The "Mall" may post advertisements on the website, service screen, SMS, and e-mail related to
              the operation of the services. However, e-mails or letter mails will no longer be sent to
              "Members" who clearly express their intent to refuse such receipt.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3>Article 26 (Jurisdiction and Governing Law)</h3>
          <ol>
            <li>
              ① Any litigation arising from e-commerce disputes between the "Mall" and the "Customer" will be
              subject to the court that has jurisdiction over the registered address of the "Customer" at the
              time of complaint filing, and where there is no such registered address, such litigation will be
              subject to the exclusive jurisdiction of the district court having jurisdiction over the
              permanent residence of the "Customer." However, in the event where the address or permanent
              residence of the "Customer" is unclear at the time of filing the complaint, or the "Customer"
              resides outside the territory of the Republic of Korea, the case will be brought to the Seoul
              Central District Court
            </li>
            <li>
              ② Any e-commerce litigations filed between the "Mall" and the "Customer" will be governed by and
              construed in accordance with the laws of the Republic of Korea.
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <p>These Terms of Use will come into effect on September 17, 2019.</p>
        </div>
        <div class="paragraph" style="margin-top: 80px;">
          <h3>Attachment 1. Guideline</h3>
          <p>
            “Customer” can only purchase only “Products” they have customized or designed once they have been
            approved by the review process of the “Mall”. “Products” designs that are determined by “Mall” to
            fall under or risk falling under the categories listed below stand a good chance of being rejected
            for or removed from sale by “Mall”. Please review the following guidelines carefully before
            submitting your customizations or designs of the “Products”.
          </p>
          <ol>
            <li>
              1. Moral Guidelines
              <ol>
                <li>1.1. Images that encourage or promote criminal activity</li>
                <li>1.2. Images that depict violence, child abuse, or child pornography</li>
                <li>
                  1.3. Images that depict excessive drinking or illegal substance abuse, as well as elements
                  that encourage alcohol or tobacco consumption by underage users
                </li>
                <li>1.4. Images that encourage driving under the influence of alcohol</li>
                <li>1.5. Images that depict illegal arms realistically, or might promote the use thereof</li>
                <li>1.6. Images used for spamming or phishing other users</li>
                <li>
                  1.7. Images that graphically depict the murder, shooting, stabbing, or torture of people or
                  animals
                </li>
                <li>
                  1.8. Images that may slander, injure, or attack the reputation of a particular person, legal
                  entity, nationality, or group
                </li>
                <li>
                  1.9. Images that disclose or could potentially disclose personal or third party information
                </li>
                <li>1.10. Images that display overly offensive or crass images</li>
                <li>
                  1.11. Images that may potentially attack or cause particular displeasure to a religion,
                  culture, people group, or nationality
                </li>
                <li>
                  1.12. Images designed to solicit or spread religion, or with strong religious components
                </li>
                <li>1.13. Images with political imagery or election-related material</li>
                <li>1.14. Images designed to confuse or disgust the viewer</li>
                <li>1.15. Images that contain sexual explicit elements</li>
                <li>1.16. Images that encourage gambling or elements related to gambling</li>
                <li>1.17. Images designed to obtain the user's password or other private user data</li>
                <li>
                  1.18. Images that depict elements that harm the healthy development of young users, such as
                  slot machines or horse racing
                </li>
                <li>1.19. Images that induce or encourage suicide, self-harm, or drug abuse</li>
                <li>1.20. Images that incite or encourage bullying</li>
                <li>1.21. Images that depict elements that promote discrimination</li>
                <li>
                  1.22. Images that otherwise depict antisocial elements that may cause discomfort to the user
                </li>
              </ol>
            </li>
            <li>
              2. Business, Advertising, and Other Guidelines
              <ol>
                <li>
                  2.1. Images that contain references to other messenger apps, similar services, or related
                  characters
                </li>
                <li>
                  2.2. Images designed for commercial advertisements or promotions, such as job recruitment
                  services
                </li>
                <li>2.3. Images designed to collect donations for charity</li>
                <li>
                  2.4. Images that solicit donations or otherwise encourage participation in political,
                  religious, antisocial, or other organizations
                </li>
              </ol>
            </li>
            <li>
              3. Rights and Legal Guidelines
              <ol>
                <li>
                  3.1. Images that infringe on trademarks, copyrights, or patents held by LINE Friends
                  Corporation or its affiliated companies or third parties, or violate the conditions of third
                  parties included in the images
                </li>
                <li>3.2. Images that make use of copyrights that lack a clear owner, such as fan fiction</li>
                <li>
                  3.3. Images that infringe on others' right of portrait, such as pictures of others' faces
                  taken without their consent or caricatures
                </li>
                <li>
                  3.4. Images that make use of material without proven permission from the copyright owner
                </li>
                <li>
                  3.5. Images that do not adhere to the laws of the regions in which the LINE service is
                  available, or infringe on third party rights or profits
                </li>
              </ol>
            </li>
          </ol>
          <p>
            In addition to the guidelines listed above, “Mall” reserves the right to restrict, suspend, change
            or terminate the sale of ANY “Products” that “Mall” in its sole and absolute discretion deems to
            be inappropriate. The same applies to any “Products” reported through reporting system of “Mall”
            that are deemed innapropriate.
          </p>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">Done</button>
    </div>
  </div>
`;

export const kr_terms = () => {
  return html`
    <div class="terms_body">
      <div class="title">
        <h1 class="text_align_center">서비스 이용 약관</h1>
      </div>
      <div class="body">
        <div class="text_body">
          <div class="paragraph">
            <h3 id="service1" class="header">제1조(목적)</h3>
            <p>
              이 약관은 라인프렌즈 주식회사(전자상거래 사업자)가 운영하는 라인프렌즈 크리에이터(이하 "몰"이라
              한다)에서 제공하는 주문인쇄제작(PRINT ON DEMAND)을 통한 전자상거래 관련 서비스(이하 "서비스"라
              한다)를 이용함에 있어 “몰”과 ”고객”의 권리・의무 및 책임사항을 규정함을 목적으로 합니다.<br />※「PC통신,
              무선 등을 이용하는 전자거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」
            </p>
          </div>
          <div class="paragraph">
            <h3 id="service2" class="header">제2조(정의)</h3>
            <ol>
              <li>
                ① "몰"이란 라인프렌즈 주식회사가 재화 또는 용역(이하 "재화 등"이라 함)을 ”고객”에게 제공하기
                위하여 컴퓨터 등 정보통신설비를 이용하여 “재화 등”을 거래할 수 있도록 설정한 가상의 영업장을
                말하며, 아울러 “몰”을 운영하는 사업자의 의미로도 사용합니다.
              </li>
              <li>② “고객”이란 이 약관에 따라 "몰"이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
              <li>
                ③ “회원”이라 함은 "몰"에 회원등록을 한 자로서, 계속적으로 “몰”에서 제공하는 서비스를 이용할 수
                있는 자를 말합니다.
              </li>
              <li>
                ④ “비회원”이라 함은 “회원”으로 가입하지 않고 "몰"이 제공하는 서비스를 이용하는 자를 말합니다.
              </li>
              <li>
                ⑤ “가이드라인”이라 함은 아래 첨부문서 또는 “몰”을 통해 통지되는 가이드라인으로 “고객”이 “재화
                등”을 디자인하는데 있어 따라야할 가이드라인을 의미한다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service3" class="header">제3조 (약관 등의 명시와 설명 및 개정)</h3>
            <ol>
              <li>
                ① "몰"은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수
                있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호,
                통신판매업신고번호, 개인정보관리책임자 등을 ”고객”이 쉽게 알 수 있도록 “몰”의 초기 서비스
                화면에 게시합니다. 다만, 약관의 내용은 ”고객”이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
              </li>
              <li>
                ② "몰"은 ”고객”이 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임,
                환불조건 등과 같은 중요한 내용을 ”고객”이 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을
                제공하여 ”고객”의 확인을 구하여야 합니다.
              </li>
              <li>
                ③ "몰"은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」,
                「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및
                정보보호 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을
                개정할 수 있습니다.
              </li>
              <li>
                ④ "몰"이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 “몰”의
                초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, ”고객”에게 불리한
                내용으로 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.
              </li>
              <li>
                ⑤ "몰"이 약관을 개정할 경우에 그 개정약관을 공지 또는 통지하면서 “고객”에게 공지일 또는
                통지일로부터 7일(본조 4항에 따른 “고객”에게 불리한 내용 변경의 경우 30일) 이내에 거부 의사를
                표시하지 않으면 동의한것으로 간주합니다.
              </li>
              <li>
                ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에
                관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 「전자상거래 등에서의 소비자
                보호지침」 및 관계법령 또는 상관례에 따릅니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service4" class="header">제4조(서비스의 제공 및 변경)</h3>
            <ol>
              <li>
                ① "몰"은 다음과 같은 업무를 수행합니다.
                <ol>
                  <li>1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결</li>
                  <li>2. 구매계약이 체결된 재화 또는 용역의 제작 및 배송</li>
                  <li>3. 기타 "몰"이 정하는 업무</li>
                </ol>
              </li>
              <li>
                ② "몰"은 “재화 등”의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해
                제공할 내용을 변경할 수 있습니다. 이 경우에는 변경된 내용 및 제공일자를 명시하여 현재의 내용을
                게시한 곳에 즉시 공지합니다.
              </li>
              <li>
                ③ "몰"이 제공하기로 ”고객”와 계약을 체결한 서비스의 내용을 “재화 등”의 품절 또는 기술적 사양의
                변경 등의 사유로 변경할 경우에는 그 사유를 ”고객”에게 즉시 통지합니다.
              </li>
              <li>
                ④ 전항의 경우 "몰"은 이로 인하여 ”고객”이 입은 인과관계가 입증된 실제 손해를 배상합니다. 다만,
                "몰"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service5" class="header">제5조(서비스의 중단)</h3>
            <ol>
              <li>
                ① "몰"은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한
                경우에는 “서비스”의 제공을 일시적으로 중단할 수 있습니다. 이 경우 “서비스” 일시 중단 사실과 그
                사유를 “몰” 초기화면에 공지합니다.
              </li>
              <li>
                ② "몰"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 ”고객” 또는 제3자가 입은
                손해에 대하여 배상합니다. 단, "몰"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
                아니합니다.
              </li>
              <li>
                ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는
                경우에는 "몰"은 제8조에 정한 방법으로 ”고객”에게 통지합니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service6" class="header">제6조(회원가입)</h3>
            <ol>
              <li>
                ① ”고객”는 "몰"이 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관과 "개인정보취급방침"에
                동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
              </li>
              <li>
                ② "몰"은 제1항과 같이 회원으로 가입할 것을 신청한 ”고객” 중 다음 각 호에 해당하지 않는 한
                회원으로 등록합니다.
                <ol>
                  <li>
                    1. 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만
                    제7조 제3항에 의한 회원자격 상실 후 2년 경과 또는 "몰"의 회원재가입 승낙을 얻은 경우에는
                    예외로 한다.
                  </li>
                  <li>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                  <li>3. 회원 가입일 현재 만 14세 미만인 경우</li>
                  <li>4. 기타 회원으로 등록하는 것이 "몰"의 기술상 현저히 지장이 있다고 판단되는 경우</li>
                  <li>5. 회원가입계약의 성립 시기는 "몰"의 승낙이 회원에게 도달한 시점으로 합니다.</li>
                  <li>
                    6. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 "몰"에 대하여
                    회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service7" class="header">제7조(회원 탈퇴 및 자격 상실 등)</h3>
            <ol>
              <li>
                ① 회원은 "몰"에 언제든지 탈퇴를 요청할 수 있으며 "몰"은 즉시 회원탈퇴를 처리합니다. 회원탈퇴
                시 회원자격이 상실되며, 회사가 제공하는 각종 할인쿠폰, 이벤트 혜택 등이 소멸됩니다. 단,
                회원탈퇴 시 소진되지 않은 적립금 등이 있는 경우 회원의 의사에 따라 적립금 등에 대한 권리를
                포기한 것으로 간주하여, 탈퇴와 동시에 회원의 적립금 등은 전부 소멸됩니다.
              </li>
              <li>
                ② 회원이 다음 각 호의 사유에 해당하는 경우, "몰"은 “서비스”의 이용자격을 제한 및 정지시킬 수
                있습니다.
                <ol>
                  <li>1. 회원정보에 허위 내용을 입력하거나 타인의 정보를 도용한 경우</li>
                  <li>
                    2. "몰"을 이용하여 구입한 “재화 등”의 대금, 기타 "몰"이용에 관련하여 “고객”이 부담하는
                    채무를 기일에 지급하지 않는 경우
                  </li>
                  <li>
                    3. 다른 사람의 "몰" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는
                    경우
                  </li>
                  <li>
                    4. "몰"을 이용하여 법령 또는 이 약관 및 첨부된 “가이드라인”이 금지하거나 공서양속에 반하는
                    행위를 하는 경우
                  </li>
                  <li>5. 기타 서비스 운영을 고의로 방해하는 행위를 하는 경우</li>
                </ol>
              </li>
              <li>
                ③ "몰"이 회원 자격을 제한, 정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그
                사유가 시정되지 아니하는 경우 "몰"은 회원자격을 상실시킬 수 있습니다.
              </li>
              <li>
                ④ "몰"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고,
                회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service8" class="header">제8조(고객에 대한 통지)</h3>
            <ol>
              <li>
                ① "몰"이 “고객”에 대한 통지를 하는 경우, 회원이 "몰"과 미리 약정하여 지정한 전자우편, SMS,
                전화, 팩스 등으로 할 수 있습니다.
              </li>
              <li>
                ② "몰"은 불특정다수 “고객”에 대한 통지의 경우 1주일이상 "몰" 게시판에 게시함으로서 전항의
                통지에 갈음할 수 있습니다. 다만, “고객” 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
                대하여는 전항의 통지를 합니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service9" class="header">제9조(구매신청)</h3>
            <p>
              ”고객”은 "몰"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "몰"은 ”고객”이
              구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
            </p>
            <ol>
              <li>1. “재화 등”의 검색 및 선택</li>
              <li>2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</li>
              <li>
                3. 약관내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한
                확인
              </li>
              <li>4. 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)</li>
              <li>5. “재화 등”의 구매신청 및 이에 관한 확인 또는 "몰"의 확인에 대한 동의</li>
              <li>6. 결제방법의 선택</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service10" class="header">제10조 (계약의 성립)</h3>
            <ol>
              <li>
                ① "몰"은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만,
                미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는
                법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
                <ol>
                  <li>1. 신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                  <li>2. 미성년자가 청소년보호법에서 금지하는 “재화 등”을 구매하는 경우</li>
                  <li>3. 기타 구매신청에 승낙하는 것이 "몰" 기술상 현저히 지장이 있다고 판단하는 경우</li>
                  <li>4. “재화 등”의 디자인이 “가이드라인”을 위반할 경우</li>
                </ol>
              </li>
              <li>
                ② "몰"의 승낙이 제12조 제1항의 수신확인 통지형태로 ”고객”에게 도달한 시점에 계약이 성립한
                것으로 봅니다.
              </li>
              <li>
                ③ "몰"의 승낙의 의사표시에는 ”고객”의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정
                취소 등에 관한 정보 등을 포함하여야 합니다.
              </li>
              <li>
                ④ 계약 성립 후 “몰”이 제 1항 각 호 사유를 발견한 경우 “몰”은 즉시 계약을 취소할 수 있으며,
                계약 취소 시 “고객”이 결제한 “재화 등”의 대금은 즉시 환불처리 됩니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service11" class="header">제11조(지급방법)</h3>
            <p>
              "몰"에서 구매한 “재화 등”에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수
              있습니다. 단, "몰"은 ”고객”의 지급방법에 대하여 “재화 등”의 대금에 어떠한 명목의 수수료도
              추가하여 징수하지 않습니다.
            </p>
            <ol>
              <li>1. 인터넷뱅킹 등의 각종 계좌이체</li>
              <li>2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
              <li>3. 온라인무통장입금</li>
              <li>4. 전자화폐에 의한 결제</li>
              <li>5. "몰"이 지급한 포인트, 쿠폰에 의한 결제</li>
              <li>6. "몰"과 계약을 맺었거나 "몰"이 인정한 상품권에 의한 결제</li>
              <li>7. 기타 전자적 지급 방법에 의한 대금 지급 등</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service12" class="header">제12조(수신확인통지, 구매신청 변경 및 취소)</h3>
            <ol>
              <li>① "몰"은 ”고객”의 구매신청이 있는 경우 ”고객”에게 수신확인통지를 합니다.</li>
              <li>
                ② 수신확인통지를 받은 ”고객”는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후
                즉시 구매신청 변경 및 취소를 요청할 수 있고 "몰"은 배송 전에 ”고객”의 요청이 있는 경우에는
                지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회
                등에 관한 규정에 따릅니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service13" class="header">제13조(“재화 등”의 공급)</h3>
            <ol>
              <li>
                ① "몰"은 ”고객”과 “재화 등”의 공급시기에 관하여 별도의 약정이 없는 이상, ”고객”이 청약을 한
                날부터 7일 이내에 “재화 등”을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를
                취합니다. 다만, "몰"이 이미 “재화 등”의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는
                일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 "몰"은 ”고객”이 “재화 등”의 공급 절차
                및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
              </li>
              <li>
                ② "몰"은 ”고객”이 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을
                명시합니다. 만약 "몰"이 약정 배송기간을 초과한 경우에는 그로 인한 ”고객”의 손해를 배상하여야
                합니다. 다만 "몰"이 고의, 과실이 없음을 입증한 경우에는 그러하지 아니합니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service14" class="header">제14조(환급)</h3>
            <p>
              "몰"은 ”고객”이 구매 신청한 “재화 등”이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체
              없이 그 사유를 ”고객”에게 통지하고 사전에 “재화 등”의 대금을 받은 경우에는 대금을 받은 날부터
              3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
            </p>
          </div>
          <div class="paragraph">
            <h3 id="service15" class="header">제15조(청약철회 등)</h3>
            <ol>
              <li>
                ① "몰"과 “재화 등”의 구매에 관한 계약을 체결한 ”고객”은 수신확인의 통지를 받은 날부터 7일
                이내에는 청약의 철회를 할 수 있습니다.
              </li>
              <li>
                ② ”고객”은 “재화 등”을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수
                없습니다.
                <ol>
                  <li>
                    1. ”고객”에게 책임 있는 사유로 “재화 등”이 멸실 또는 훼손된 경우(다만, “재화 등”의 내용을
                    확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
                  </li>
                  <li>2. ”고객”의 사용 또는 일부 소비에 의하여 “재화 등”의 가치가 현저히 감소한 경우</li>
                  <li>
                    3. 시간의 경과에 의하여 재판매가 곤란할 정도로 “재화 등”의 가치가 현저히 감소한 경우
                  </li>
                  <li>
                    4. 같은 성능을 지닌 “재화 등”으로 복제가 가능한 경우 그 원본인 “재화 등”의 포장을 훼손한
                    경우
                  </li>
                  <li>5. “고객”의 주문에 따라 개별적으로 생산된 “재화 등” 또는 이와 유사한 제품의 경우</li>
                  <li>6. 청약철회 제한에 대하여 고지하고 ”고객”의 동의를 받은 경우</li>
                </ol>
              </li>
              <li>
                ③ 제2항 제2호 내지 제4호의 경우에 "몰"이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알
                수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 ”고객”의 청약철회 등이
                제한되지 않습니다.
              </li>
              <li>
                ④ ”고객”은 제1항 및 제2항의 규정에 불구하고 “재화 등”의 내용이 표시・광고 내용과 다르거나
                계약내용과 다르게 이행된 때에는 당해 “재화 등”을 공급받은 날부터 3월 이내, 그 사실을 안 날
                또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
              </li>
              <li>
                ⑤ “몰”은 “고객”이 제작 혹은 디자인한 “재화 등”이 가이드라인을 위반할 경우 청약을 철회 할 수
                있다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service16" class="header">제16조(청약철회 등의 효과)</h3>
            <ol>
              <li>
                ① "몰"은 ”고객”으로부터 “재화 등”을 반환 받은 경우 3영업일 이내에 이미 지급받은 “재화 등”의
                대금을 환급합니다. 이 경우 "몰"이 ”고객”에게 “재화 등”의 대금 환급을 지연한 때에는 그
                지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」에서 정하는
                지연이자율을 곱하여 산정한 지연이자를 지급합니다.
              </li>
              <li>
                ② "몰"은 위 대금을 환급함에 있어서 ”고객”이 신용카드 또는 전자화폐 등의 결제수단으로 “재화
                등”의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 “재화 등”의 대금의
                청구를 정지 또는 취소하도록 요청합니다.
              </li>
              <li>
                ③ 청약철회 등의 경우 공급받은 “재화 등”의 반환에 필요한 비용은 ”고객”이 부담합니다. "몰"은
                ”고객”에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 “재화 등”의
                내용이 표시・광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 “재화
                등”의 반환에 필요한 비용은 "몰"이 부담합니다.
              </li>
              <li>
                ④ ”고객”이 “재화 등”을 제공받을 때 발송비를 부담한 경우에 "몰"은 청약철회 시 그 비용을 누가
                부담하는지를 ”고객”이 알기 쉽도록 명확하게 표시합니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service17" class="header">제17조(개인정보보호)</h3>
            <ol>
              <li>
                ① "몰"은 ”고객”의 개인정보 수집시 서비스 제공 및 구매계약 이행에 필요한 최소한의 개인정보를
                수집합니다.
              </li>
              <li>
                ② "몰"은 ”고객”의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 ”고객”의 동의를
                받습니다.
              </li>
              <li>
                ③ 제공된 개인정보는 당해 “고객”의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며,이에
                대한 모든 책임은 “몰”이 집니다. 다만,다음의 경우에는 예외로 합니다.
                <ol>
                  <li>
                    1. 개인정보처리방침에 고지된 개인정보 수집∙이용 목적과 항목에 따른 개인정보의 수집 시,
                    “고객”의 동의를 받은 경우
                  </li>
                  <li>
                    2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는
                    형태로 제공하는 경우
                  </li>
                  <li>3. “재화 등”의 거래에 따른 대금정산을 위하여 필요한 경우</li>
                  <li>4. 도용방지를 위하여 본인확인에 필요한 경우</li>
                  <li>5. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우</li>
                </ol>
              </li>
              <li>
                ④ "몰"이 제3항과 제4항에 의해 ”고객”의 동의를 받아야 하는 경우에는 개인정보관리 책임자의
                신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공
                관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에
                관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 ”고객”은 언제든지 이
                동의를 철회할 수 있습니다.
              </li>
              <li>
                ⑤ ”고객”은 언제든지 "몰"이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수
                있으며 "몰"은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. ”고객”이 오류의 정정을
                요구한 경우에는 "몰"은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
              </li>
              <li>
                ⑥ "몰"은 개인정보 보호를 위하여 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드,
                은행계좌 등을 포함한 ”고객”의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로
                인한 ”고객”의 손해에 대하여 모든 책임을 집니다.
              </li>
              <li>
                ⑦ "몰" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을
                달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
              </li>
              <li>
                ⑧ 기타 개인정보의 제공 등 취급과 관련된 내용은 “몰”의 초기 화면에서 개인정보취급방침으로
                자세히 고지합니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service18" class="header">제18조("몰"의 의무)</h3>
            <ol>
              <li>
                ① "몰"은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는
                바에 따라 지속적이고, 안정적으로 “재화 등” 을 제공하는데 최선을 다하여야 합니다.
              </li>
              <li>
                ② "몰"은 ”고객”이 안전하게 인터넷 서비스를 이용할 수 있도록 ”고객”의 개인정보(신용정보
                포함)보호를 위한 보안 시스템을 갖추어야 합니다.
              </li>
              <li>
                ③ "몰"이 “재화 등”에 대하여 「표시・광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시,
                광고행위를 함으로써 ”고객”이 손해를 입은 때에는 이를 배상할 책임을 집니다.
              </li>
              <li>④ "몰"은 ”고객”이 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service19" class="header">제19조(“고객”의 회원ID 및 비밀번호에 대한 의무)</h3>
            <ol>
              <li>① 제17조의 경우를 제외한 회원ID와 비밀번호에 관한 관리책임은 “고객”에게 있습니다.</li>
              <li>② “고객”은 자신의 회원ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
              <li>
                ③ “고객”이 자신의 회원ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는
                바로 "몰"에 통보하고 "몰"의 안내가 있는 경우에는 그에 따라야 합니다. “고객”이 이를
                인지하고서도 통지하지 않거나, 통지한 경우에도 "몰"의 안내에 따르지 않아 발생한 불이익에 대해
                "몰"은 책임지지 않습니다.
              </li>
              <li>
                ④ “고객”이 본인의 회원ID를 소홀히 관리하거나 제 3자에게 이용을 승낙함으로써 발생하는 손해에
                대하여는 "몰"은 책임지지 않습니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service20" class="header">제20조(”고객”의 의무)</h3>
            <p>”고객”은 다음 행위를 하여서는 안 됩니다</p>
            <ol>
              <li>1. 신청 또는 변경시 허위 내용의 등록</li>
              <li>2. 타인의 정보 도용</li>
              <li>3. "몰"에 게시된 정보의 변경</li>
              <li>4. "몰"이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
              <li>5. "몰" 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
              <li>6. "몰" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
              <li>
                7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 “몰”에 공개 또는
                게시하는 행위
              </li>
              <li>8. “가이드라인”을 위반하는 행위</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service21" class="header">제21조(저작권의 귀속 및 이용제한)</h3>
            <ol>
              <li>① "몰"이 작성한 저작물에 대한 저작권 기타 지적재산권은 "몰"에 귀속합니다.</li>
              <li>
                ② ”고객”은 "몰"을 이용함으로써 얻은 정보 중 "몰"에게 지적재산권이 귀속된 정보를 "몰"의 사전
                승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게
                이용하게 하여서는 안됩니다.
              </li>
              <li>
                ③ "몰"은 약정에 따라 ”고객”에게 귀속된 저작권을 사용하는 경우 당해 ”고객”에게 통보하여야
                합니다.
              </li>
              <li>
                ④ "몰"은 “고객”이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램저작권 등을 침해하였음을
                이유로 "몰"이 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 “고객”은 "몰"을 면책하여야
                하며, "몰"이 면책되지 못한 경우 “고객”은 그로 인해 "몰"에 발생한 모든 손해를 책임집니다.
              </li>
              <li>
                ⑤ "몰"은 회원이 서비스 내에 게시한 게시물이 다음 각 호의 경우에 해당한다고 판단되는 경우
                사전통지 없이 삭제, 변경할 수 있으며, 이에 대해 "몰"은 어떠한 책임도 지지 않습니다.
                <ol>
                  <li>1. 스팸(spam)성 게시물 및 상업성 게시물</li>
                  <li>2. 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 게시물</li>
                  <li>3. 동의 없는 타인의 신상공개, 제3자의 저작권 등 권리를 침해하는 내용을 담은 게시물</li>
                  <li>4. 기타 관계 법령 및 "몰"의 지침 등에 위반된다고 판단되는 경우</li>
                  <li>5. “가이드라인”을 위반하는 경우</li>
                </ol>
              </li>
              <li>
                ⑥ "고객"의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우
                관리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며,
                "몰"은 관련법에 따라 조치를 취하여야 합니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service22" class="header">제22조(분쟁해결)</h3>
            <ol>
              <li>
                ① "몰"은 ”고객”으로부터 제출되는 불만사항 등을 우선적으로 그 사항을 처리합니다. 다만, 신속한
                처리가 곤란한 경우에는 ”고객”에게 그 사유와 처리일정을 알 수 있도록 제공합니다.
              </li>
              <li>
                ② "몰"과 ”고객” 간에 발생한 전자상거래 분쟁과 관련하여 ”고객”의 피해구제신청이 있는 경우에는
                공정거래위원회 또는 시・도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service23" class="header">제 23조(상품쿠폰)</h3>
            <ol>
              <li>
                ① "몰"은 “고객”에게 지정된 상품 구매시 일정액 또는 일정비율을 할인 받을 수 있는 상품쿠폰을
                발급할 수 있습니다.
              </li>
              <li>
                ② 회원은 상품쿠폰을 회원 본인의 구매에 한해서만 사용할 수 있으며, 어떠한 경우에도 이를
                타인에게 실질적으로 매매 또는 양도할 수 없습니다.
              </li>
              <li>
                ③ 상품쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며, 유효기간이 지난 후에는 사용할
                수 없습니다.
              </li>
              <li>④ 회원을 탈퇴한 경우 상품쿠폰은 소멸됩니다.</li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service24" class="header">제 24조(면책)</h3>
            <ol>
              <li>
                ① "몰"은 천재지변, 불가항력 기타 "몰"의 합리적인 통제범위를 벗어난 사유로 인하여 서비스를
                제공할 수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.
              </li>
              <li>
                ② "몰"은 ”고객”의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지
                않습니다.
              </li>
              <li>
                ③ "몰"은 서비스 이용과 관련하여 ”고객”의 고의 또는 과실로 인하여 ”고객” 또는 제3자에게 발생한
                손해에 대하여는 아무런 책임을 부담하지 않습니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service25" class="header">제 25조(정보의 제공 및 광고의 게재)</h3>
            <ol>
              <li>
                ① "몰"은 서비스의 운영과 관련하여 홈페이지, 서비스 화면, SMS, 전자우편 등에 광고 등을 게재할
                수 있습니다. 단, 수신거절의 의사를 명백히 표시한 회원에 대해서는 더 이상 전자우편 또는
                서신우편 등을 발송하지 않습니다.
              </li>
            </ol>
          </div>
          <div class="paragraph">
            <h3 id="service26" class="header">제 26조(재판권 및 준거법)</h3>
            <ol>
              <li>
                ① "몰"과 ”고객” 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 ”고객”의 국내 주소에
                의하고, 국내 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소
                당시 ”고객”의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 서울중앙지방법원에
                제기합니다.
              </li>
              <li>② "몰"과 ”고객” 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.</li>
            </ol>
          </div>
          <p>본 약관은 2019년 9월 19일부터 적용됩니다.</p>

          <div class="paragraph" style="margin-top: 80px;">
            <h3 id="guideline1" class="header">첨부 1. 가이드라인</h3>
            <p>
              “몰”은 본 가이드라인을 기반으로 검토 절차를 통과한 “재와 등”만 판매할 수 있습니다. 다음에
              해당하거나 해당할 가능성이 있는 “재화 등”의 경우 “몰”에서 판매가 거부되거나 중단될 수 있으므로
              “재화 등”의 주문 전에 아래 가이드라인을 상세히 확인해 주시기 바랍니다.
            </p>
            <ol>
              <li>
                1. 윤리지침
                <ol>
                  <li>1.1. 범죄 행위를 조장하는 경우</li>
                  <li>1.2. 폭력, 아동 학대, 아동 포르노물을 묘사하는 경우</li>
                  <li>
                    1.3. 미성년자의 음주, 흡연을 조장하거나 폭음, 불법 약물 복용 행위 등을 묘사하는 경우
                  </li>
                  <li>1.4. 음주운전을 조장하는 경우</li>
                  <li>1.5. 불법 무기를 현실적으로 묘사하거나 이러한 무기의 사용을 조장하는 경우</li>
                  <li>1.6. 스팸, 피싱 등을 목적으로 하는 경우</li>
                  <li>
                    1.7. 살인, 총기 및 도검류의 사용, 사람이나 동물에 대한 위해 행위를 시각적으로 묘사하는
                    경우
                  </li>
                  <li>
                    1.8. 특정 국적 소유자, 인물, 법인, 집단에 대한 비방이나, 폄훼, 공격으로 해석될 수 있는
                    경우
                  </li>
                  <li>1.9. 특정 개인 또는 제3자의 정보를 공개하거나 공개할 가능성이 있는 경우</li>
                  <li>1.10. 과도하게 공격적이거나 저속한 이미지가 포함된 경우</li>
                  <li>
                    1.11. 특정 국적 소유자, 종교, 문화, 집단에 대한 공격으로 해석되거나 이들에게 불쾌감을
                    유발할 소지가 있는 경우
                  </li>
                  <li>1.12. 포교를 목적으로 제작되었거나 종교적 색채가 강한 경우</li>
                  <li>1.13. 정치적 이미지나 선거 관련 내용을 포함하는 경우</li>
                  <li>1.14. 사용자에게 혼동이나 혐오감을 유발하도록 제작된 경우</li>
                  <li>1.15. 노골적인 성적 묘사가 포함된 경우</li>
                  <li>1.16. 도박이나 이와 관련된 행위를 조장하는 경우</li>
                  <li>1.17. 사용자의 비밀번호나 기타 개인 데이터 수집을 시도하는 경우</li>
                  <li>1.18. 슬롯머신, 경마 등 청소년의 건전한 정서 발달을 저해하는 내용이 포함된 경우</li>
                  <li>1.19. 자살, 자해, 약물 남용 등을 조장하는 경우</li>
                  <li>1.20. 왕따 행위를 유발 또는 조장하는 경우</li>
                  <li>1.21. 차별을 조장하거나 조장할 소지가 있는 경우</li>
                  <li>1.22. 기타 타인에게 불안감을 조성할 수 있는 반사회적 내용을 묘사하는 경우</li>
                </ol>
              </li>
              <li>
                2. 사업, 광고 및 기타 가이드라인.
                <ol>
                  <li>2.1. 타사 메신저 앱이나 유사 서비스, 관련 캐릭터를 언급하는 경우</li>
                  <li>2.2. 영업 목적의 광고로 제작된 경우</li>
                  <li>2.3. 모금 활동을 목적으로 제작된 경우</li>
                  <li>
                    2.4. 정치 단체, 종교 단체, 반사회적 세력, 기타 단체 가입을 권유하거나 모금 활동을 요구하는
                    경우
                  </li>
                </ol>
              </li>
              <li>
                3. 권리 및 법적 가이드라인
                <ol>
                  <li>
                    3.1. 라인프렌즈 주식회사 및 계열사 또는 제3자의 상표권, 저작권, 특허권을 침해하거나
                    제3자의 사용조건을 위배하는 이미지를 사용하는 경우
                  </li>
                  <li>3.2. 팬픽션 등 저작권 소유자가 명확하지 않은 콘텐츠를 사용하는 경우</li>
                  <li>
                    3.3. 타인의 초상권을 침해하는 경우(예: 캐리커처, 상대방의 동의 없이 촬영한 얼굴 사진 등)
                  </li>
                  <li>3.4. 저작권 소유자가 사용을 명시적으로 허가하지 않은 내용이 포함된 경우</li>
                  <li>
                    3.5. “고객”이 생활하는 주소지 국가의 법을 준수하지 않거나 제3자의 권리 또는 이익을
                    침해하는 경우
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              “몰”은 상기 가이드라인에 해당하지 않는 경우에도, 부적절하다고 판단되는 “재화 등”의 판매를 제한,
              중단, 변경, 종료할 수 있습니다. “몰”에 신고 문의가 들어와 “몰”이 부적절하다고 판단한 “재화 등”도
              동일합니다.
            </p>
          </div>
        </div>
      </div>
      <div class="option">
        <button class="done">확인</button>
      </div>
    </div>
  `;
};
