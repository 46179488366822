import { CommonNS, PathEditorNS } from '@marpple/sticker-editor';
import { each } from 'fxjs/es';
import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';

export const handleBottomDoneButtonClick = () => () => MuiF.closeFrame();

export const handlePathEditorAddLButtonClick =
  ({ tab_el }) =>
  () => {
    const active_command = tab_el.__mp_keyring_editor?.getActivePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    const next_command = tab_el.__mp_keyring_editor?.getNextPathCommand(active_command);
    const next_command_target_point = next_command?.getTargetPoint();

    tab_el.__mp_keyring_editor?.insertPathEditorBefore({
      ref_command: next_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const target_point = (() => {
          if (next_command_target_point == null) {
            const ctm_inverse = getPathElCTM().inverse();
            const { innerWidth: client_width, innerHeight: client_height } = window;
            const client_x = client_width / 2;
            const client_y = client_height / 2;
            return CommonNS.UtilNS.createSVGPoint(svg_el)([client_x, client_y]).matrixTransform(ctm_inverse);
          }
          return {
            x: (active_command_target_point.x + next_command_target_point.x) / 2,
            y: (active_command_target_point.y + next_command_target_point.y) / 2,
          };
        })();

        const command_l = new PathEditorNS.ModelNS.CommandL({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: target_point.x,
            y: target_point.y,
          }),
        });
        const command_l_controller = new PathEditorNS.ControllerNS.CommandLController({
          svg_el,
          command_l,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const command_l_view = new PathEditorNS.ViewNS.CommandLView({
          command_l_controller,
          target_point_view,
        });
        return {
          model: command_l,
          view: command_l_view,
          point_el_list: [target_point_view.getEl()],
          line_el_list: [],
        };
      },
    });
  };

export const handlePathEditorAddCButtonClick =
  ({ tab_el }) =>
  () => {
    const active_command = tab_el.__mp_keyring_editor?.getActivePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    const next_command = tab_el.__mp_keyring_editor?.getNextPathCommand(active_command);
    const next_command_target_point = next_command?.getTargetPoint();

    tab_el.__mp_keyring_editor?.insertPathEditorBefore({
      ref_command: next_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const current_point_x = active_command_target_point.x;
        const current_point_y = active_command_target_point.y;
        const { x: target_point_x, y: target_point_y } = (() => {
          if (next_command_target_point == null) {
            const ctm_inverse = getPathElCTM().inverse();
            const { innerWidth: client_width, innerHeight: client_height } = window;
            const client_x = client_width / 2;
            const client_y = client_height / 2;
            return CommonNS.UtilNS.createSVGPoint(svg_el)([client_x, client_y]).matrixTransform(ctm_inverse);
          }
          return {
            x: (active_command_target_point.x + next_command_target_point.x) / 2,
            y: (active_command_target_point.y + next_command_target_point.y) / 2,
          };
        })();
        const control_point1_x = (current_point_x * 2 + target_point_x) / 3;
        const control_point1_y =
          current_point_y < target_point_y
            ? current_point_y - Math.abs(current_point_y - target_point_y) / 2
            : current_point_y + Math.abs(current_point_y - target_point_y) / 2;
        const control_point2_x = (current_point_x + target_point_x * 2) / 3;
        const control_point2_y =
          current_point_y < target_point_y
            ? target_point_y + Math.abs(current_point_y - target_point_y) / 2
            : target_point_y - Math.abs(current_point_y - target_point_y) / 2;

        const command_c = new PathEditorNS.ModelNS.CommandC({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: target_point_x,
            y: target_point_y,
          }),
          control_point1: new PathEditorNS.ModelNS.Point({
            x: control_point1_x,
            y: control_point1_y,
          }),
          control_point2: new PathEditorNS.ModelNS.Point({
            x: control_point2_x,
            y: control_point2_y,
          }),
        });
        const command_c_controller = new PathEditorNS.ControllerNS.CommandCController({
          svg_el,
          command_c,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point1_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point2_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_line1_view = new PathEditorNS.ViewNS.LineView({ document });
        const control_line2_view = new PathEditorNS.ViewNS.LineView({ document });
        const command_c_view = new PathEditorNS.ViewNS.CommandCView({
          command_c_controller,
          target_point_view,
          control_point1_view,
          control_point2_view,
          control_line1_view,
          control_line2_view,
        });
        return {
          model: command_c,
          view: command_c_view,
          point_el_list: [
            control_point1_view.getEl(),
            control_point2_view.getEl(),
            target_point_view.getEl(),
          ],
          line_el_list: [control_line1_view.getEl(), control_line2_view.getEl()],
        };
      },
    });
  };

export const handlePathEditorRemoveButtonClick =
  ({ tab_el }) =>
  () => {
    const active_command = tab_el.__mp_keyring_editor?.getActivePathCommand();
    if (active_command == null) {
      return;
    }

    tab_el.__mp_keyring_editor?.removePathCommand(active_command);
  };

export const handlePathEditorCloseOrOpenButtonClick =
  ({ tab_el }) =>
  ({ currentTarget: el }) => {
    const z_list = tab_el.__mp_keyring_editor?.getClosePathCommandList() ?? [];

    if (z_list.length > 0) {
      each((z) => tab_el.__mp_keyring_editor?.removePathCommand(z))(z_list);
      el.dataset.is_closed = 'false';
      return;
    }

    tab_el.__mp_keyring_editor?.insertPathEditorBefore({
      ref_command: null,
      makePathEditor: ({ document }) => {
        const command_z = new PathEditorNS.ModelNS.CommandZ();
        const placeholder_g_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
        const command_z_view = new PathEditorNS.ViewNS.CommandZView({ placeholder_g_el });
        return {
          model: command_z,
          view: command_z_view,
          point_el_list: [placeholder_g_el],
          line_el_list: [],
        };
      },
    });
    el.dataset.is_closed = 'true';
  };

export const handlePathEditorChangeToLButtonClick =
  ({ tab_el }) =>
  () => {
    const active_command = tab_el.__mp_keyring_editor?.getActivePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    tab_el.__mp_keyring_editor?.replacePathCommand({
      ref_command: active_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const command_l = new PathEditorNS.ModelNS.CommandL({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: active_command_target_point.x,
            y: active_command_target_point.y,
          }),
        });
        const command_l_controller = new PathEditorNS.ControllerNS.CommandLController({
          svg_el,
          command_l,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const command_l_view = new PathEditorNS.ViewNS.CommandLView({
          command_l_controller,
          target_point_view,
        });
        return {
          model: command_l,
          view: command_l_view,
          point_el_list: [target_point_view.getEl()],
          line_el_list: [],
        };
      },
    });
  };

export const handlePathEditorChangeToCButtonClick =
  ({ tab_el }) =>
  () => {
    const active_command = tab_el.__mp_keyring_editor?.getActivePathCommand();
    if (active_command == null) {
      return;
    }
    const active_command_target_point = active_command.getTargetPoint();

    const prev_command = tab_el.__mp_keyring_editor?.getPrevPathCommand(active_command);
    if (prev_command == null) {
      return;
    }
    const prev_command_target_point = prev_command.getTargetPoint();

    tab_el.__mp_keyring_editor?.replacePathCommand({
      ref_command: active_command,
      makePathEditor: ({
        svg_el,
        document,
        path_editor_point_size_controller,
        getPathElCTM,
        getPathEditorLayerElCTM,
      }) => {
        const current_point_x = prev_command_target_point.x;
        const current_point_y = prev_command_target_point.y;
        const target_point_x = active_command_target_point.x;
        const target_point_y = active_command_target_point.y;
        const control_point1_x = (current_point_x * 2 + target_point_x) / 3;
        const control_point1_y =
          current_point_y < target_point_y
            ? current_point_y - Math.abs(current_point_y - target_point_y) / 2
            : current_point_y + Math.abs(current_point_y - target_point_y) / 2;
        const control_point2_x = (current_point_x + target_point_x * 2) / 3;
        const control_point2_y =
          current_point_y < target_point_y
            ? target_point_y + Math.abs(current_point_y - target_point_y) / 2
            : target_point_y - Math.abs(current_point_y - target_point_y) / 2;
        const command_c = new PathEditorNS.ModelNS.CommandC({
          svg_el,
          target_point: new PathEditorNS.ModelNS.Point({
            x: target_point_x,
            y: target_point_y,
          }),
          control_point1: new PathEditorNS.ModelNS.Point({
            x: control_point1_x,
            y: control_point1_y,
          }),
          control_point2: new PathEditorNS.ModelNS.Point({
            x: control_point2_x,
            y: control_point2_y,
          }),
        });
        const command_c_controller = new PathEditorNS.ControllerNS.CommandCController({
          svg_el,
          command_c,
          getPathElCTM,
          getPathEditorLayerElCTM,
        });
        const target_point_view = new PathEditorNS.ViewNS.TargetPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point1_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_point2_view = new PathEditorNS.ViewNS.ControlPointView({
          document,
          point_size_controller: path_editor_point_size_controller,
        });
        const control_line1_view = new PathEditorNS.ViewNS.LineView({
          document,
        });
        const control_line2_view = new PathEditorNS.ViewNS.LineView({
          document,
        });
        const command_c_view = new PathEditorNS.ViewNS.CommandCView({
          command_c_controller,
          target_point_view,
          control_point1_view,
          control_point2_view,
          control_line1_view,
          control_line2_view,
        });
        return {
          model: command_c,
          view: command_c_view,
          point_el_list: [
            control_point1_view.getEl(),
            control_point2_view.getEl(),
            target_point_view.getEl(),
          ],
          line_el_list: [control_line1_view.getEl(), control_line2_view.getEl()],
        };
      },
    });
  };
