import { curry, filter, go, isNil, map, not, pipe, pluck, reject, sel } from 'fxjs/es';
import { CateListS } from '../../../CateList/S/Function/module/CateListS.js';
import { isBpStrapCase, isNeedPreview } from '../../../Maker/S/categorize.js';
import { creatorPriceOfProduct, totalProfitOfProduct } from '../../../ProductColorPrice/S/fs.js';
import { calcCrewMargins } from '../../Adjustment/S/util.js';
import { PRODUCT_STATUS } from '../../Product/S/constant.js';

const getColorIds = curry((prop, colors) => go(colors, filter(sel(prop)), pluck('base_product_color_id')));
const getColorCodes = curry((prop, colors) =>
  go(colors, filter(sel(prop)), map(sel('_.base_product_color.color_code'))),
);

const filterColorBy = curry((prop, base_product_colors, prohibited_colors, enabled_colors) =>
  go(
    base_product_colors,
    filter((c) => enabled_colors.includes(c[prop])),
    reject((c) => prohibited_colors.includes(c[prop])),
  ),
);

export const filterAvailableColorsById = filterColorBy('id');

export const getAvailableColors = (base_product_colors, store_product_colors) => {
  if (store_product_colors.length === 0) return base_product_colors;
  const prohibited_color_ids = getColorIds('prohibited', store_product_colors);
  const enabled_color_ids = getColorIds('enabled', store_product_colors);
  return filterAvailableColorsById(base_product_colors, prohibited_color_ids, enabled_color_ids);
};

export const getAvailableColorsByCode = (base_product_colors, store_product_colors) => {
  if (store_product_colors.length === 0) return base_product_colors;
  const prohibited_color_codes = getColorCodes('prohibited', store_product_colors);
  const enabled_color_codes = getColorCodes('enabled', store_product_colors);
  return filterColorBy('color_code', base_product_colors, prohibited_color_codes, enabled_color_codes);
};

export const getAvailableColorIds = pipe(getAvailableColors, pluck('id'));
export const getProhibitedColors = (base_product_colors, store_product_colors) => {
  const prohibited_color_ids = getColorIds('prohibited', store_product_colors);
  return filter(({ id }) => prohibited_color_ids.includes(id), base_product_colors);
};

export const getEnabledColors = (base_product_colors, store_product_colors) => {
  const enabled_color_ids = getColorIds('enabled', store_product_colors);
  return filter(({ id }) => enabled_color_ids.includes(id), base_product_colors);
};

export const hasColorOptions = (base_product, color_count, can_not_change_color) => {
  const { cate_item_id } = base_product;
  const is_phone_case = CateListS.isPhoneCaseByCateItemId(cate_item_id);
  return (
    (isBpStrapCase(base_product) || !can_not_change_color) &&
    color_count > 1 &&
    (!is_phone_case || !isNeedPreview(base_product))
  );
};

export const makePriceListData = (product, discount_rate = product._.discount_rate) => {
  const creator_price = creatorPriceOfProduct(product);
  return map(
    (quantity) => {
      const profit = totalProfitOfProduct(product, quantity);
      const product_price = creator_price - profit;
      return {
        quantity,
        profit,
        product_price,
        crew_margins: calcCrewMargins(product, quantity, discount_rate) / quantity,
      };
    },
    [1, 10, 20, 50, 100],
  );
};

export const selProductStatus = (product) => {
  const { is_public, _: { stores_product, base_product } = {} } = product;
  const {
    is_prohibited,
    is_check_design,
    is_confirmed,
    is_confirm_req,
    _: { digital_product } = {},
  } = stores_product;
  const { is_use_creator, is_use_creator_en, is_use_creator_jp } = base_product;
  return {
    is_prohibited,
    is_digital_product: Boolean(digital_product?.id),
    is_public,
    is_confirmed,
    is_confirm_req,
    is_check_design,
    is_use_creator,
    is_use_creator_en,
    is_use_creator_jp,
  };
};

const getDigitalProductStatus = ({
  is_public,
  is_prohibited,
  is_check_design,
  is_confirmed,
  is_confirm_req,
  is_use_creator,
  is_pb_mg,
}) => {
  if (!is_use_creator) return PRODUCT_STATUS.NOT_USE_PRODUCT;
  if (!is_confirm_req) return PRODUCT_STATUS.READY_CONFIRM;
  if (!is_confirmed) return PRODUCT_STATUS.NOT_CONFIRMED;
  if (is_prohibited) {
    if (is_check_design) return PRODUCT_STATUS.PROHIBITED;
    else return PRODUCT_STATUS.CHECKING;
  }
  if (!is_public) return PRODUCT_STATUS.PRIVATE;
  if (!is_pb_mg) return PRODUCT_STATUS.PB_ME;
  return PRODUCT_STATUS.PUBLIC;
};

const getNormalProductStatus = ({
  is_prohibited,
  is_public,
  is_confirmed,
  is_use_creator,
  is_confirm_req,
  is_pb_mg,
}) => {
  if (!is_use_creator) return PRODUCT_STATUS.NOT_USE_PRODUCT;
  if (is_prohibited) return PRODUCT_STATUS.PROHIBITED;
  if (!is_confirm_req) return PRODUCT_STATUS.READY_CONFIRM;
  if (!is_confirmed) return PRODUCT_STATUS.NOT_CONFIRMED;
  if (!is_public) return PRODUCT_STATUS.PRIVATE;
  if (!is_pb_mg) return PRODUCT_STATUS.PB_ME;
  return PRODUCT_STATUS.PUBLIC;
};

const getAfreecaNormalProductStatus = ({ is_prohibited, is_public, is_use_creator, is_check_design }) => {
  if (!is_use_creator) return PRODUCT_STATUS.NOT_USE_PRODUCT;
  if (is_prohibited) return PRODUCT_STATUS.PROHIBITED;
  if (is_public) {
    if (is_check_design) {
      return PRODUCT_STATUS.PUBLIC;
    } else {
      return PRODUCT_STATUS.CHECKING;
    }
  }
  return PRODUCT_STATUS.PRIVATE;
};

export const getProductStatus = (product, is_afreeca) => {
  const {
    digital_product_id,
    is_digital_product = not(isNil(digital_product_id)),
    is_prohibited,
    is_public,
    is_confirmed,
    is_confirm_req,
    is_check_design,
    is_pb_mg,
  } = product;
  const is_use_creator = product['is_use_creator' + G._en];

  if (is_afreeca && !is_digital_product) {
    return getAfreecaNormalProductStatus({
      is_public,
      is_prohibited,
      is_check_design,
      is_confirmed,
      is_use_creator,
      is_confirm_req,
      is_pb_mg,
    });
  }

  return is_digital_product
    ? getDigitalProductStatus({
        is_public,
        is_prohibited,
        is_check_design,
        is_confirmed,
        is_use_creator,
        is_confirm_req,
        is_pb_mg,
      })
    : getNormalProductStatus({
        is_prohibited,
        is_public,
        is_confirmed,
        is_confirm_req,
        is_use_creator,
        is_pb_mg,
      });
};
