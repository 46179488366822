import { html } from 'fxjs/es';
export const tmpl = ({ attached_id, attached_type, url }) => {
  return html`<div class="thumbnail-modal cate_item">
    <h3>마플 썸내일 이미지</h3>
    <h3>
      <button class="save-btn" type="button">저장</button>
    </h3>
    <div class="img">
      <label
        >440x440 (PC/모바일)
        <input type="file" accept="image/*" attached_type="${attached_type}" attached_id="${attached_id}" />
        <input class="cate_url" type="hidden" name="url" value="${url || ''}" />
      </label>
      <div>
        <img src="${url || ''}" alt="" />
      </div>
    </div>
  </div>`;
};
