import { isObject, extend } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { FRAME_CUSTOM_DATA } from '../Constant/mobilepdfuploader.js';

/**
 * @define 프레임 객체게 사용자 정의 데이터 SET
 * @param {object | HTMLElement} frame
 * @param {object} data
 * @return {undefined}
 * */
export function setFrameCustomData({ frame, data }) {
  if (!isObject(data)) throw new Error(`Data must be an object`);
  if (frame == null) throw new Error(`Frame is required`);

  const set_data = extend(frame[FRAME_CUSTOM_DATA] ?? {}, data);

  if (UtilS.isNode) {
    frame[FRAME_CUSTOM_DATA] = set_data;
  } else {
    if (frame instanceof window.HTMLElement) {
      frame.frame_opt[FRAME_CUSTOM_DATA] = extend(frame.frame_opt[FRAME_CUSTOM_DATA] ?? {}, data);
    } else {
      frame[FRAME_CUSTOM_DATA] = set_data;
    }
  }
}

/**
 * @param {object | HTMLElement} frame
 * @param {string} key
 * @return {undefined}
 * */
export function getFrameCustomData({ frame, key }) {
  if (frame == null) throw new Error(`Frame is required`);
  if (UtilS.isNode) {
    return frame?.[FRAME_CUSTOM_DATA]?.[key];
  } else {
    if (frame instanceof window.HTMLElement) {
      return frame?.frame_opt?.[FRAME_CUSTOM_DATA]?.[key];
    } else {
      return frame?.[FRAME_CUSTOM_DATA]?.[key];
    }
  }
}

/**
 * @param {number} bp_id
 */
export function getAiTemplateUrl({ bp_id }) {
  const S3_URL = `http://s3`;
  return `${S3_URL}/${bp_id}_${G._en}.zip`;
}

export function hasPdfFile({ pdf_printing_file }) {
  return !UtilS.isEmpty(pdf_printing_file) && !!pdf_printing_file?.pdf_url;
}
