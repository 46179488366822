import { CommonNS, EditorNS } from '@marpple/sticker-editor';
import {
  getOutlinePathsFromImgWithAdaptiveSimplify,
  makePathsBridged,
  pathOffsets,
} from '@marpple/stickerizer';
import { $find, $findAll, $on, $setCss } from 'fxdom/es';
import svgpath from 'svgpath';
import { PCRightFixedPanelF } from '../../../../../../../PCRightFixedPanel/F/Function/module/PCRightFixedPanelF.js';
import { VectorEditorFontF } from '../../../../../../Font/F/Function/module/VectorEditorFontF.js';
import { VectorEditorKeyringFreeConstantS } from '../../../../S/Constant/module/VectorEditorKeyringFreeConstantS.js';
import { VectorEditorKeyringFreePCCuttingLineAutoMuiS } from '../../S/Mui/module/VectorEditorKeyringFreePCCuttingLineAutoMuiS.js';
import { VectorEditorKeyringFreePCCuttingLineAutoF } from '../Function/module/VectorEditorKeyringFreePCCuttingLineAutoF.js';
import { each, go, tap } from 'fxjs/es';
import { SMOOTH_PERCENT } from '../../../../S/Constant/cutting_line.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorKeyringFreePCCuttingLineAutoMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_frame_position_top = tab_el.__mp_frame_position_top ?? 0;
    tab_el.__mp_frame_position_height = tab_el.__mp_frame_position_height ?? 0;
    tab_el.__mp_prev_right_panel_el = tab_el.__mp_prev_right_panel_el ?? null;
    tab_el.__mp_keyring_cutting_line_editor = null;
    tab_el.__mp_factor = 1;
    tab_el.__mp_art_board_width = tab_el.__mp_art_board_width ?? 0;
    tab_el.__mp_art_board_height = tab_el.__mp_art_board_height ?? 0;
    tab_el.__mp_makeTargetImageEl = tab_el.__mp_makeTargetImageEl ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_cutting_lines = [];
    tab_el.__mp_max_cutting_line_index = 5;
    tab_el.__mp_cur_cutting_line_index = 0;

    go(
      tab_el,

      tap(
        $findAll(`.wrapper_body_control_wrapper_auto_minus`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreePCCuttingLineAutoF.handleControlAutoMinusButtonClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.wrapper_body_control_wrapper_auto_plus`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreePCCuttingLineAutoF.handleControlAutoPlusButtonClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.wrapper_body_control_wrapper_manual`),
        each(
          $on('click', VectorEditorKeyringFreePCCuttingLineAutoF.handleControlManualButtonClick({ tab_el })),
        ),
      ),

      tap(
        $findAll(`.right_container .wrapper_footer .prev`),
        each($on('click', VectorEditorKeyringFreePCCuttingLineAutoF.handleRightPrevButtonClick())),
      ),
      tap(
        $findAll(`.right_container .wrapper_footer .next`),
        each($on('click', VectorEditorKeyringFreePCCuttingLineAutoF.handleRightNextButtonClick({ tab_el }))),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    const keyring_cutting_line_editor =
      new EditorNS.AcrylicCuttingLineEditorNS.PCNS.AcrylicCuttingLinePCEditor({
        window,
        document,
        min_zoom_scale: 1,
        max_zoom_scale: 1,
      });
    tab_el.__mp_keyring_cutting_line_editor = keyring_cutting_line_editor;
    keyring_cutting_line_editor.initDOM();
    keyring_cutting_line_editor.initEditor();

    const editor_container_el = $find('.editor_container')(tab_el);
    if (editor_container_el == null) {
      const error = new Error(`editor_container 클래스를 가진 엘리먼트를 찾을 수 없습니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::template::error_message::no_editor_container_el',
      );
      throw error;
    }
    keyring_cutting_line_editor.appendTo(editor_container_el);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    $setCss(['top', `${tab_el.__mp_frame_position_top}px`])(tab_el);
    $setCss(['height', `${tab_el.__mp_frame_position_height}px`])(tab_el);

    return (() => {
      const right_el = $find(`.right_container .right_wrapper`)(tab_el) ?? document.createElement('div');
      const left_el = tab_el.__mp_prev_right_panel_el ?? document.createElement('div');
      return PCRightFixedPanelF.showPcRightFixedPanel({ right_el, left_el });
    })();
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  async rendered(tab_el) {
    if (tab_el.__mp_keyring_cutting_line_editor == null) {
      const error = new Error(`tab_el.__mp_keyring_cutting_line_editor 객체가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::template::error_message::no_keyring_cutting_line_editor',
      );
      throw error;
    }
    const editor = tab_el.__mp_keyring_cutting_line_editor;

    if (tab_el.__mp_makeTargetImageEl == null) {
      const error = new Error(`tab_el.__mp_makeTargetImageEl 함수가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::template::error_message::no_make_target_image_el',
      );
      throw error;
    }
    const makeTargetImageEl = tab_el.__mp_makeTargetImageEl;

    $.don_loader_start();
    try {
      editor.fitSize();
      const view_box_vo = editor.calculateViewBoxFitTo({
        size_in_ucs: {
          width: tab_el.__mp_art_board_width,
          height: tab_el.__mp_art_board_height,
        },
        padding_in_vcs: { top: 180, right: 300, bottom: 180, left: 300 },
      });
      editor.setViewBox(view_box_vo);

      const target_image_el = await makeTargetImageEl({ factor: 1 });
      editor.setTargetImageEl(target_image_el);
      const min_px = 1024;
      const factor_x = min_px / target_image_el.width.baseVal.value;
      const factor_y = min_px / target_image_el.height.baseVal.value;
      tab_el.__mp_factor = Math.max(factor_x, factor_y);
      const large_target_image_el = await makeTargetImageEl({ factor: tab_el.__mp_factor });
      const font_style_el = await VectorEditorFontF.makeFontStyleEl({ is_encode_base64: true })(
        large_target_image_el,
      );
      const font_style_defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
      font_style_defs_el.appendChild(font_style_el);
      large_target_image_el.prepend(font_style_defs_el);
      const large_target_image_svg_data_url = /** @type {string} */ await new Promise((resolve, reject) => {
        try {
          const svg_str = new XMLSerializer().serializeToString(large_target_image_el);
          const svg_blob = new Blob([svg_str], { type: 'image/svg+xml' });
          const file_reader = new FileReader();
          file_reader.onerror = (event) => {
            console.error(event);
            const error = new Error('large_target_image_el 을 data_url 로 변환하는 데 실패했습니다.');
            error.__mp_alert_message = T(
              'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::template::error_message::svg_data_url_making_error',
            );
            reject(error);
          };
          file_reader.onload = () => resolve(file_reader.result);
          file_reader.readAsDataURL(svg_blob);
        } catch (error) {
          reject(error);
        }
      });
      const large_target_image_img_el = /** @type {HTMLImageElement} */ await new Promise(
        (resolve, reject) => {
          const img_el = new Image();

          function onLoad() {
            img_el.removeEventListener('load', onLoad);
            img_el.removeEventListener('error', onError);
            resolve(img_el);
          }
          function onError(event) {
            img_el.removeEventListener('load', onLoad);
            img_el.removeEventListener('error', onError);

            console.error(event);
            const error = new Error('large_target_image_svg_data_url 을 img_el 에 로드하는 데 실패했습니다.');
            error.__mp_alert_message = T(
              'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::template::error_message::svg_img_el_load_error',
            );
            reject(error);
          }
          img_el.addEventListener('load', onLoad);
          img_el.addEventListener('error', onError);

          img_el.src = large_target_image_svg_data_url;
        },
      );
      await new Promise((resolve) => setTimeout(resolve, 600));
      const large_target_image_canvas_el = document.createElement('canvas');
      large_target_image_canvas_el.width = large_target_image_img_el.width;
      large_target_image_canvas_el.height = large_target_image_img_el.height;
      const large_target_image_canvas_ctx = large_target_image_canvas_el.getContext('2d');
      large_target_image_canvas_ctx.drawImage(
        large_target_image_img_el,
        0,
        0,
        large_target_image_img_el.width,
        large_target_image_img_el.height,
        0,
        0,
        large_target_image_canvas_el.width,
        large_target_image_canvas_el.height,
      );
      const large_target_image_png_blob = /** @type {Blob} */ await new Promise((resolve, reject) => {
        try {
          large_target_image_canvas_el.toBlob((blob) => {
            if (blob == null) {
              const error = new Error(
                'large_target_image_canvas_el 에서 Blob 객체를 만드는 데 실패했습니다.',
              );
              error.__mp_alert_message = T(
                'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::error_message::svg_canvas_to_blob_error',
              );
              reject(error);
            } else {
              resolve(blob);
            }
          }, 'image/png');
        } catch (error) {
          reject(error);
        }
      });
      const large_target_image_png_data_url = /** @type {string} */ await new Promise((resolve, reject) => {
        try {
          const file_reader = new FileReader();
          file_reader.onerror = (event) => {
            console.error(event);
            const error = new Error(`large_target_image_png_blob 을 data_url 로 변환하는 데 실패했습니다.`);
            error.__mp_alert_message = T(
              'modules::VectorEditor::Keyring::Free::PC::CuttingLineAuto::error_message::png_data_url_making_error',
            );
            reject(error);
          };
          file_reader.onload = () => resolve(file_reader.result);
          file_reader.readAsDataURL(large_target_image_png_blob);
        } catch (error) {
          reject(error);
        }
      });

      const fitted_cutting_line = await getOutlinePathsFromImgWithAdaptiveSimplify(
        large_target_image_png_data_url,
        null,
        SMOOTH_PERCENT,
      );
      const fitted_bridged_cutting_line = await makePathsBridged(fitted_cutting_line.paper_obj);

      const base_outer_cutting_line = await pathOffsets(
        fitted_bridged_cutting_line.paper_obj,
        2 * tab_el.__mp_factor * (1 - (20 * SMOOTH_PERCENT) / 100),
      );
      const base_outer_cutting_line_path_data = svgpath(base_outer_cutting_line.path_data)
        .scale(1 / tab_el.__mp_factor)
        .toString();
      const base_inner_cutting_line = await pathOffsets(
        base_outer_cutting_line.paper_obj,
        -VectorEditorKeyringFreeConstantS.SAFE_MARGIN_MM * tab_el.__mp_factor,
      );
      const base_inner_cutting_line_path_data = svgpath(base_inner_cutting_line.path_data)
        .scale(1 / tab_el.__mp_factor)
        .toString();
      tab_el.__mp_cutting_lines = [
        {
          offset: 0,
          inner_cutting_line: base_inner_cutting_line,
          inner_cutting_line_path_data: base_inner_cutting_line_path_data,
          outer_cutting_line: base_outer_cutting_line,
          outer_cutting_line_path_data: base_outer_cutting_line_path_data,
        },
      ];
      await VectorEditorKeyringFreePCCuttingLineAutoF.setCuttingLine({ tab_el, index: 0 });
    } finally {
      $.don_loader_end();
    }
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, _v) {
    return (() => {
      const right_el = $find(`.right_container .right_wrapper`)(tab_el) ?? document.createElement('div');
      const left_el = tab_el.__mp_prev_right_panel_el ?? document.createElement('div');
      return PCRightFixedPanelF.hidePcRightFixedPanel({ right_el, left_el });
    })();
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, _v) {
    if (tab_el.__mp_keyring_cutting_line_editor) {
      const editor = tab_el.__mp_keyring_cutting_line_editor;
      editor.destroy();
    }

    tab_el.__mp_frame_position_top = 0;
    tab_el.__mp_frame_position_height = 0;
    tab_el.__mp_prev_right_panel_el = null;
    tab_el.__mp_keyring_cutting_line_editor = null;
    tab_el.__mp_factor = 1;
    tab_el.__mp_art_board_width = 0;
    tab_el.__mp_art_board_height = 0;
    tab_el.__mp_makeTargetImageEl = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_cutting_lines = [];
    tab_el.__mp_max_cutting_line_index = 1;
    tab_el.__mp_cur_cutting_line_index = 0;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
