import axios from 'axios';
import { $attr, $closest, $find, $findAll, $hasClass, $qs, $setCss, $setHTML, $val } from 'fxdom/es';
import { each, filter, find, go, pick, pluck, reduce, reject, sel, sortBy } from 'fxjs/es';
import onScan from 'onscan.js';
import * as XLSX from 'xlsx';
import { makeDfProjectionListEvent } from '../../../../../modules/Df/Projection/List/F/event.js';
import { moveScrollToFirstPrj } from '../../../../../modules/Df/Projection/List/F/frame.js';
import {
  makeDfProjectionListUpdate,
  makeDfProjectionListUpdateByTaskSetId,
} from '../../../../../modules/Df/Projection/List/F/fs.js';
import {
  getProjectionLabelData,
  printProjectionLabel,
} from '../../../../../modules/Df/Projection/List/F/label.js';
import { scannerActionEvents } from '../../../../../modules/Df/Stock/ScanControl/F/scanEvents.js';
import { DfTaskDetailF } from '../../../../../modules/Df/Task/Detail/F/Function/module/DfTaskDetailF.js';
import { DfTaskF } from '../../../../../modules/Df/Task/F/Function/module/DfTaskF.js';
import { DfTaskHistoryF } from '../../../../../modules/Df/Task/History/F/Function/module/DfTaskHistoryF.js';
import { DfTaskImageWorkF } from '../../../../../modules/Df/Task/ImageWork/F/Function/module/DfTaskImageWorkF.js';
import { DfTaskListF } from '../../../../../modules/Df/Task/List/F/Function/module/DfTaskListF.js';
import { DfTaskListTmplS } from '../../../../../modules/Df/Task/List/S/Tmpl/module/DfTaskListTmplS.js';
import { DfTaskTmplS } from '../../../../../modules/Df/Task/S/Tmpl/module/DfTaskTmplS.js';
import { MuiF } from '../../../../../modules/Mui/F/Function/module/MuiF.js';
import { PriceS } from '../../../../../modules/Price/S/Function/module/PriceS.js';
import { initTaskFrames } from './df.task.frame.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

(function (LF) {
  G.df.task = {};
  G.df.search_btn_event = (e) => {
    e.preventDefault();
    const delegateTarget = e.$delegateTarget;
    const query = go(
      delegateTarget,
      $findAll('.query[name]:not(.arr)'),
      (not_arrs$) =>
        reduce(
          function (mem, query$) {
            if ($hasClass('radio', query$)) query$ = $find('input[type="radio"]:checked', query$);
            if (!query$) return mem;
            const val = query$.type === 'checkbox' ? query$.checked : $val(query$);
            if (val || _p.is_numeric(val)) mem[query$.getAttribute('name')] = val;
            return mem;
          },
          {},
          not_arrs$,
        ),
      (query) =>
        reduce(
          function (mem, el_query) {
            mem[el_query.getAttribute('name')] = mem[el_query.getAttribute('name')] || [];
            if (el_query.checked) mem[el_query.getAttribute('name')].push($val(el_query));
            return mem;
          },
          query,
          $findAll('.query[name].arr', delegateTarget),
        ),
    );

    const color_input$ = go(
      query,
      pick(['p_id', 'task_id', 'o_p_id', 'user_id']),
      Object.entries,
      find(([, val]) => find((id) => parseInt(id) > 2100000000, val.replace(/\s/g, '').split(','))),
      (key_val) =>
        key_val
          ? go(
              delegateTarget,
              $find(`.query[name=${key_val[0]}]`),
              $setCss({
                color: 'red',
              }),
            )
          : void 0,
    );

    if (query.product_name_only == 'not' && !query.store_name && !query.merged_parent_ids) {
      return go(
        $.alert(
          `~가 없는 주문서를 검색할때<br><b>"스토어이름"</b> 또는 <b>"병합주문서 ID"</b>를<br>반드시 입력하셔야 합니다.`,
        ),
        () => {
          $qs('input[name="store_name"]').focus();
        },
      );
    }

    return color_input$
      ? go(
          $.alert('ID 숫자가 너무 크거나 이상합니다. 다시 적어주세요.'),
          () => color_input$.focus(),
          () => setTimeout(() => $setCss({ color: '' }, color_input$), 888),
        )
      : (location.href = location.pathname + '?' + $.param(query).replace(/%2C/g, ','));
  };
  G.df.pagination = (states) => {
    const pagination_count = box.sel('pagination_count');
    const offset = states.offset;
    const limit = states.limit;
    const now_page = offset / limit;
    const start = parseInt(now_page / 10) * 10;
    const stop = start + 10;
    const stop2 = start + Math.ceil(pagination_count / limit);
    return pug`
    ${
      start == 0
        ? pug`
    sp.before <`
        : pug`
    a.before[href=${page_href(start - 1, limit, states)}] <`
    }
    ${_sum(_p.range(start, stop2), (page) =>
      page == now_page
        ? pug`
    sp ${page + 1}`
        : pug`
    a[href="${page_href(page, limit, states)}"] ${page + 1}`,
    )}
    ${
      stop2 < stop
        ? pug`
    sp.after >`
        : pug`
    a.after[href=${page_href(stop2, limit, states)}] >
    `
    }
    `;
  };

  function page_href(page, limit, states) {
    return (
      location.pathname +
      '?' +
      $.param(_p.defaults({ offset: page * limit, limit }, states)).replace(/%2C/g, ',')
    );
  }

  G.df.search9 = function (dates, name, is_projection) {
    return _p.sum(
      _p.filter(dates, 'due_at'),
      (date) => pug`
    .s_col
      label.due_at_filter_item[value="${date.due_at}" count="${date.count}"]
        input.arr.query[type=checkbox name="${name}_due_ats" value="${date.due_at}" ${
        date.checked ? 'checked' : ''
      }]
        span ${(function (due_at) {
          if (due_at == 'old_day') return is_projection ? '지난 출고일' : '지난 태스크';
          if (due_at == 'today') return '오늘';
          return due_at;
        })(date.due_at)} (${_p.commify(date.count)}건, ${_p.commify(date.quantity)}개)`,
    );
  };

  // input:checked + span

  $.frame.defn_frame({
    frame_name: 'df.task.list',
    el_class: 'task_list',
    page_name: 'task.list',
  });

  G.df.task.change_updated_tasks = function () {
    const task_items = _go($('.task_item'), _map(box.sel), _map(_p.pick(['id', 'updated_at'])));
    if (!$('.task_item').length) return;
    return _p.go(
      $.post('/@api/task/tasks_check_update', {
        task_items,
        product_mode: $.attr($1('[prj_product_mode]'), 'prj_product_mode'),
      }),
      _p.each(async function (task) {
        var el_task_item = $1(`.task_item[task_id = "${task.id}"]`);
        if (!el_task_item) return;
        el_task_item.outerHTML = await DfTaskTmplS.t_task_item(task);
        var el_task_item = $1(`.task_item[task_id = "${task.id}"]`);
        if (box().is_user?._?.policies?.outsourcing_worker_policy)
          DfTaskImageWorkF.makeOnlyMyTasks(el_task_item);
        _p.extend(box.sel(el_task_item), task);
        G.mp.maker.draw_product_face_in_ups(el_task_item);
      }),
    );
  };

  G.df.task.list_update = async function () {
    await G.df.task.change_updated_tasks();
    if (box().is_user?._?.policies?.outsourcing_worker_policy) {
      await makeDfProjectionListUpdateByTaskSetId();
    } else {
      await makeDfProjectionListUpdate();
    }
  };

  function get_bp_stocks_task_ids() {
    return _p.go(
      $('.check_bp_stock input:checked'),
      _p.map(__($.closest('.task_item'), box.sel)),
      function (tasks) {
        const bp_stocks = merge_bp_stocks(tasks);
        const task_ids = _p.pluck(tasks, 'id');
        const projection_ids = _p.pluck(tasks, 'projection_id');
        return { bp_stocks, task_ids, projection_ids };
      },
    );
  }

  // function issue_not_read_check() {
  //   const worker_id = box.sel('task->worker_id');
  //   return _.go(
  //     box.sel('task->_->issues'),
  //     _.find((issue) =>
  //       _.go(
  //         _.v(issue, '_.issues_users'),
  //         _.filter((issues_user) => issues_user.user_id == worker_id),
  //         _.some((issues_user) => !_.v(issues_user, 'is_read')),
  //       ),
  //     ),
  //   );
  // }

  G.df.task.set_task_box_task_detail_render = DfTaskDetailF.set_task_box_task_detail_render;

  function open_task_detail(task) {
    $.frame.open({
      frame_name: 'df.task.detail',
      frame_tag: task.id,
    });
  }

  LF.issue_event = {};
  LF.task_event = {};
  LF.tasks_event = {};
  LF.tasks_event.header = {};
  LF.tasks_event.header.cancel_tasks = function (func) {
    return async (e) => {
      // await $.alert(
      //   '태스크를 모두 취소해야 하는 경우가 있군요. 개발팀 박정익님께 먼저 문의 주시면 감사하겠습니다!!',
      // );
      const ok = await $.confirm('태스크를 모두 취소하시겠습니까?');
      if (
        !(
          box.sel('is_user->_->policies->task_manager_policy') ||
          box.sel('is_user->_->policies->task_create_policy')
        )
      ) {
        $.alert('태스크 취소 권한이 없습니다.');
        return;
      }
      if (!ok) return;
      await _p.go(
        e.currentTarget,
        $.closest('[prj_id]'),
        $.attr('prj_id'),
        _p.nest('projection_id'),
        _p(_p.extend, { is_canceled: true }),
        _p.tap((task) => $.post('/@api/task/task_all_cancel_and_change_status', task)),
        _p.v('projection_id'),
        function (projection_id) {
          func(e, projection_id);
        },
      );
    };
  };

  LF.issue_event.modal = function (e) {
    const dt = e.delegateTarget;
    let task_title;
    return _p.go(
      e.currentTarget,
      $.closest('[task_id]'),
      _p.tap($.find1('.title'), $.text, (title) => (task_title = title)),
      $.attr('task_id'),
      _p.nest('attached_id'),
      _p(_p.extend, _p, { attached_type: 'tasks' }),
      _p($.get, '/@api/issues_list'),
      (issues) => box.set('issues', issues),
      () => G.df.issue.modal(task_title, dt),
    );
  };

  LF.tasks_event.header.create_all_issues = function (ct) {
    return new Promise(function (resolve) {
      _p.go(
        ct,
        $.closest('.tasks_container'),
        box.sel,
        reject(
          (task) =>
            task._.worker.outsource_company_id && parseInt(task._.worker.outsource_company_id) !== 108,
        ),
        _map(function (task) {
          const uniq_users = _p.go(
            _p.uniq([task._.worker, box.sel('user')], _p.v('id')),
            _p.map(function (user) {
              return { user_id: user.id };
            }),
          );
          return {
            user_id: box.sel('user->id'),
            attached_type: 'tasks',
            attached_id: task.id,
            _: {
              issues_users: uniq_users,
            },
          };
        }),
        (issues) => box.set('issues', issues),
        function () {
          $.frame.open({
            frame_name: 'df.issue.create_mult',
            closed: function () {
              resolve();
            },
          });
        },
      );
    });
  };

  LF.tasks_event.body = {};

  LF.tasks_event.body.open_task_detail = _p
    .if2((e) => e.metaKey || e.ctrlKey)((e) => {
      return window.open($.attr($.closest(e.currentTarget, '.task'), 'href')), false;
    })
    .else(_p.idtt);
  LF.tasks_event.body.add_task = __(_p.v('currentTarget'), function (ct) {
    return post_task_create({
      projection_id: _go(ct, $.closest('[prj_id]'), $.attr('prj_id')),
      type: _go(ct, $.closest('.type'), $.attr('type')),
    });
  });

  function post_task_create(task) {
    if (
      !(
        (['as', 'before_deliver'].includes(task.type) &&
          box.sel('is_user->_->policies->task_manager_policy')) ||
        box.sel('is_user->_->policies->task_create_policy')
      )
    ) {
      $.alert('태스크 생성 권한이 없습니다.');
      return;
    }
    return _p.go(DfTaskDetailF.get_mihaldang(), _p.v('id'), async function (id) {
      return $.post(
        '/@api/task/create/',
        _p.extend(
          {
            // worker_id: box.sel('is_user').id || id
            worker_id: id,
          },
          task,
        ),
      );
    });
  }

  LF.task_sets_event = {};
  LF.task_sets_event.body = {};
  LF.task_sets_event.body.add_original_task = __(_p.v('currentTarget'), function (ct) {
    return post_task_create({
      type: _go(ct, $.closest('.type'), $.attr('type')),
      is_original: true,
    });
  });

  LF.issue_event.get_task = function (e) {
    return {
      id: _go(e.currentTarget, $.closest('[task_id]'), $.attr('task_id')),
      projection_id: _go(e.currentTarget, $.closest('[prj_id]'), $.attr('prj_id')),
    };
  };

  function task_items_btn_add(e) {
    const target = e.currentTarget;
    const projection_id = _go(target, $.closest('[prj_id]'), $.attr('prj_id'));
    const type = _go(target, $.closest('[type]'), $.attr('type'));
    if (
      !(
        (['as', 'before_deliver'].includes(type) && box.sel('is_user->_->policies->task_manager_policy')) ||
        box.sel('is_user->_->policies->task_create_policy')
      )
    ) {
      $.alert('태스크 생성 권한이 없습니다.');
      return;
    }
    const prj_item = $.closest(target, '.prj_item') || $.closest(target, '.prj');
    const projection = box.sel(prj_item);
    if (type !== 'as' && ['before_confirm', 'confirmed'].includes(projection.status)) {
      return $.alert('배송완료이거나 구매확정인 주문서는<br>AS/기타 태스크만 생성할 수 있습니다.');
    }
    if (
      !projection.is_repress &&
      projection.merged_type === 'child' &&
      (type === 'before_print' || type === 'printing')
    )
      return $.alert('마플샵(배송용) 주문서에는 제작준비중, 제작 태스크는 추가하실수 없습니다.');
    /*before_print, printing, 자식주문서일때는 task 추가 못하*/
    return _p.go(
      new Promise(function (resolve) {
        _p.go(
          undefined,
          function () {
            $.don_loader_start();
            return _p.go($.get('/@api/task/task_sets', { type }), function (task_sets) {
              box.set('task_sets', task_sets);
              $.don_loader_end();
              return task_sets;
            });
          },
          _p.reject((task_set) => task_set.is_auto),
          _p.filter((task_set) => task_set.type === type),
          sortBy((task_set) => task_set.no),
          (task_sets) => pug`
            .tooltip_task_items[data-task_set_type="${type}"]
              .bg
              .add_task_option
                .task_set_list[sets_length="${task_sets.length}" type="${type}"] 태스크 세트 가져오기
                  ul.body
                    ${_p.sum(
                      task_sets,
                      (task_set) => pug`
                      li.item[task_set_id="${task_set.id}"] ${task_set.title}
                    `,
                    )}
                .auto_task_create 기본 태스크 세트
                .task_create 만들기
                .auto_assignment 제작 태스크 세트 자동 생성
        `,
          $.append_to($1('#body')),
          function (tooltip_task_items_el) {
            const offset = $.offset(target);
            $.offset(tooltip_task_items_el, {
              top: offset.top,
              left: offset.left - $.width(tooltip_task_items_el) + $.width(target),
            });
            _go(
              tooltip_task_items_el,
              $.on('click', '.item', function (e) {
                const task_set_id = $.attr(e.currentTarget, 'task_set_id');
                $.remove_class($.closest(tooltip_task_items_el, '.btn.add'), 'clicked');
                resolve(task_set_id);
              }),
              $.on2('click', '.auto_task_create', function (e) {
                resolve(2);
              }),
              $.on('click', '.task_set_list', function (e) {
                $.css($.find(e.currentTarget, 'ul.body'), { display: 'flex' });
              }),
              $.on('click', '.bg', function () {
                resolve();
              }),
              $.on('click', '.auto_assignment', async function () {
                try {
                  $.don_loader_start();
                  await $.post('/@api/task/bp_mapped_task_sets', { projection_id });
                  await G.df.task.list_update();
                  if ($.closest(target, '[tab_name="projection.detail.tasks"]'))
                    await G.df.task.projection.render_projection_detail_tasks_tab(
                      $.closest(target, '.don_tab'),
                    );
                  $.don_loader_end();
                } catch (e) {
                  $.alert('문제가 발생했습니다.');
                }
              }),
              $.on(
                'click',
                '.task_create',
                __(
                  function () {
                    return post_task_create({
                      type,
                      projection_id,
                    });
                  },
                  async function (task) {
                    if (task) await open_task_detail(task);
                    resolve();
                  },
                ),
              ),
            );
          },
        );
      }),
      async function (task_set_id) {
        $.don_loader_start();
        $.remove($1('.tooltip_task_items'));
        const prj_el = $closest('.prj_item', target) || $closest('.prj', target);
        const has_wow_products = go(
          prj_el,
          box.sel,
          sel('_.ups'),
          filter(
            ({ quantity, _ }) => quantity > 0 && sel('base_product._.wow_base_product.base_product_id', _),
          ),
        );
        if (task_set_id === undefined) return;
        if (task_set_id === 2) {
          let add_text = '';
          if (has_wow_products.length > 0) {
            add_text =
              '와우지류 상품은 발주 후 취소가 불가능하며\n바로 제작이 진행됩니다.\n제작을 진행 하시겠어요?';
          }

          const bool = await $.confirm(add_text || '기본 태스크 세트를 추가하시겠습니까?');
          if (!bool) return;
        }

        let is_required_cost = true;
        if (has_wow_products.length > 0 && go(prj_el, box.sel).is_repress) {
          is_required_cost = await $.confirm(
            '와우프레스에 해당 건의 제작비를 지불해야하나요? \n' +
              '(와우측 제작오류일 경우 “아니오”를 선택해주세요.)',
          );
        }
        return _p.go(
          $.post('/@api/task/add_task_set_to_task_items', {
            task_set_id,
            projection_id,
            is_required_cost,
          }),
          _p
            .if2(_p.idtt)(function () {
              G.df.task.list_update();
              if ($.closest(target, '[tab_name="projection.detail.tasks"]'))
                G.df.task.projection.render_projection_detail_tasks_tab($.closest(target, '.don_tab'));
              return true;
            })
            .else(() => $.confirm('태스크가 생성 실패했습니다. 개발팀에 문의해 주세요.')),
        );
      },
      function () {
        $.don_loader_end();
      },
    );
  }
  function change_task_worker(task) {
    return new Promise(function (resolve) {
      return _p.go(
        DfTaskDetailF.get_mihaldang(),
        function (mihaldang) {
          box.set('df.worker.list_selected_workers', _p.compact([_p.v(task, '_.worker') || mihaldang]));
        },
        G.get_teams,
        function () {
          if (
            !(
              (['as', 'before_deliver'].includes(task.type) &&
                box.sel('is_user->_->policies->task_manager_policy')) ||
              box.sel('is_user->_->policies->task_create_policy') ||
              (/press/gi.exec(task.title) && box.sel('is_user->team_id') === 11)
            )
          ) {
            return $.alert('담당자 할당 권한이 없습니다.');
          }
          if (task.title === '이미지작업') {
            box.set(
              'forced_selected_team',
              _go(
                box.sel('teams'),
                _find((team) => team.name === '[운영]디자인'),
                _p.omit('_'),
              ),
            );
          }
          return $.frame.open({
            frame_name: 'df.worker.list',
            frame_tag: 'one',
            closed: __(
              function (el, selected_users) {
                if (!selected_users) return;
                return _p.go(
                  !selected_users[0] ? DfTaskDetailF.get_mihaldang() : selected_users[0],
                  function (selected_user) {
                    return {
                      id: task.id,
                      worker_id: selected_user.id,
                      assigned_at: new Date(),
                      projection_id: task.projection_id,
                    };
                  },
                  _p($.post, '/@api/task/modify'),
                );
              },
              function () {
                box.unset('df.worker.list_selected_workers');
                box.unset('forced_selected_team');
                resolve();
              },
            ),
          });
        },
        _p.catch(_p.noop),
        function () {
          $.don_loader_end();
        },
      );
    });
  }

  function make_tooltip(tooltip_obj, e) {
    _p.go(
      pug`
        .normal_tooltip
          .bg
          .body
            ${_p.sum(
              tooltip_obj.contents,
              (content) => pug`
              .content[class="${content.class_names || ''}"] ${content.text || ''}
            `,
            )}
      `,
      $.append_to($1('#body')),
      function (tooltip) {
        $.offset(tooltip, {
          left: e.pageX,
          top: e.pageY,
        });
        _go(
          tooltip,
          _p.tap((tooltip) =>
            _p.each(tooltip_obj.events, (event) => {
              $.on2(tooltip, 'click', '.' + event.class_name, function (e) {
                $.don_loader_start();
                $.remove(tooltip);
                return _p.go(
                  event.func(e),
                  function () {
                    $.don_loader_end();
                  },
                  _p.catch(function () {
                    $.don_loader_end();
                  }),
                );
              });
            }),
          ),
          $.on('click', '.bg', function () {
            $.remove(tooltip);
          }),
        );
      },
    );
  }

  function task_tooltip(e, task) {
    const obj = task.is_hidden
      ? {
          class_names: 'recover_task',
          text: '태스크 복구',
        }
      : {
          class_names: 'remove_task',
          text: '태스크 삭제',
        };
    make_tooltip(
      {
        contents: [
          {
            class_names: 'change_worker',
            text: '작업자 할당',
          },
          obj,
        ],
        events: [
          {
            class_name: 'remove_task',
            func: function () {
              if (
                !(
                  (['as', 'before_deliver'].includes(task.type) &&
                    box.sel('is_user->_->policies->task_manager_policy')) ||
                  box.sel('is_user->_->policies->task_create_policy')
                )
              ) {
                $.alert('태스크 삭제 권한이 없습니다.');
                return;
              }
              return _p.go(
                $.confirm('정말 삭제 하시겠습니까?'),
                _p.if2(_p.idtt)(
                  () =>
                    $.post('/@api/projection/task_update_and_change_status', {
                      id: task.id,
                      is_hidden: true,
                      projection_id: task.projection_id,
                      remover_id: box.sel('is_user->id'),
                    }),
                  () => G.df.task.list_update(),
                ),
              );
            },
          },
          {
            class_name: 'change_worker',
            func: function () {
              return _p.go(change_task_worker(task), G.df.task.list_update);
            },
          },
          {
            class_name: 'recover_task',
            func: function () {
              if (
                !(
                  (['as', 'before_deliver'].includes(task.type) &&
                    box.sel('is_user->_->policies->task_manager_policy')) ||
                  box.sel('is_user->_->policies->task_create_policy')
                )
              ) {
                $.alert('태스크 복구 권한이 없습니다.');
                return;
              }
              return _p.go(
                $.confirm('테스크를 복구 하시겠습니까?'),
                _p.if2(_p.idtt)(
                  () =>
                    $.post('/@api/projection/task_update_and_change_status', {
                      id: task.id,
                      is_hidden: false,
                      projection_id: task.projection_id,
                    }),
                  () => G.df.task.list_update(),
                ),
              );
            },
          },
        ],
      },
      e,
    );
  }

  const projection_tasks_event_init = function (el) {
    if (
      !box.sel('is_user->_->policies->mp_worker_policy') &&
      !box.sel('is_user->_->policies->outsourcing_worker_policy')
    ) {
      return el;
    }

    const searchProjectionPage = (projection_id) => {
      const p_id_el = $find('input[name="p_id"]', el);
      const filter_el = $find('#filter', el);
      go(
        filter_el,
        $findAll('input[type="checkbox"]'),
        each((checkbox_el) => (checkbox_el.checked = false)),
      );
      go(
        filter_el,
        $findAll('input[type="number"],input[type="text"]'),
        each((number_input_el) => (number_input_el.value = '')),
      );
      go(
        filter_el,
        $findAll('select'),
        each((select_el) => (select_el.selectedIndex = 0)),
      );
      p_id_el.value = projection_id;
      const $submit_btn = go(p_id_el, $closest('form'), $find(`button[type="submit"]`));
      $submit_btn.click();
    };

    const getCurrentPagePrjId = ({ $prj_frame }) => {
      const prjs = box($prj_frame).projections;
      if (prjs == null || !prjs.length) return window.alert(`주문 번호 정보를 알 수 없습니다.`);
      /* 주문서 root 페이지 */
      if (prjs.length > 1) return null;
      return prjs[0].id;
    };

    /* 이벤트 listener 등록 중복 방지 */
    if (onScan.isAttachedTo(document) === false) {
      scannerActionEvents(document, async ({ projection_id: scanned_projection_id }) => {
        const $frame = $qs('.don_frame');
        const frame_name = go($qs('.don_frame'), $attr('frame_name'));

        /* 동작 프레임 - 주문서 기본화면 + 주문서 상세 화면 */
        if (frame_name && frame_name.includes('projection')) {
          const current_page_projection_id = getCurrentPagePrjId({ $prj_frame: $frame });
          if (Number(current_page_projection_id) !== Number(scanned_projection_id)) {
            /* 주문서 상세는 신규 프레임이므로 닫은 후에 주문서 기본화면에서 주문서 id 로 검색 trigger 실행 */
            frame_name === 'projection.detail' && (await MuiF.closeFrame());
            searchProjectionPage(scanned_projection_id);
          } else {
            /* 동일 주문서 페이지에서 아즈텍 scan 재실행 한 경우 주문서 id 응답 */
            return scanned_projection_id;
          }
        }
      });
    }

    return _p.go(
      el,
      $.on('contextmenu', '.task_items .task', function (e) {
        const task = _go(e.currentTarget, $.closest('[_sel]'), box.sel);
        if (
          !(
            (['as', 'before_deliver'].includes(task.type) &&
              box.sel('is_user->_->policies->task_manager_policy')) ||
            box.sel('is_user->_->policies->task_create_policy') ||
            (/press/gi.exec(task.title) && box.sel('is_user->team_id') === 11)
          )
        ) {
          $.alert('태스크 수정 권한이 없습니다.');
          return;
        }
        e.preventDefault();
        task_tooltip(e, task);
      }),
      $.on('click', '.tasks_container >.head .show_hidden_tasks', function (e) {
        _p.go(e.currentTarget, $.closest('.tasks_container'), function (tasks_container) {
          const bool = $.attr(tasks_container, 'show_hidden_tasks');
          const hidden_task_length = _p.filter(box.sel(tasks_container), _p.v('is_hidden')).length;
          $.attr(tasks_container, { show_hidden_tasks: !bool });
          if (!bool) {
            $.text(e.currentTarget, '삭제된 태스크 숨기기');
          } else {
            $.text(e.currentTarget, '삭제된 태스크 보기(' + hidden_task_length + ')');
          }
        });
      }),
      $.on(
        'click',
        '.tasks_container >.head .cancel_all_tasks',
        LF.tasks_event.header.cancel_tasks(function () {
          G.df.task.list_update();
        }),
      ),
      $.on('click', '.task[has_issue="true"] .task_issue', LF.issue_event.modal),
      $.on('click', '.tasks_container >.head .create_all_issues', function (e) {
        return _p.go(LF.tasks_event.header.create_all_issues(e.currentTarget), function () {
          G.df.task.list_update();
        });
      }),
      $.on('click', '.tasks_container >.head .task_history', function (e) {
        const projection = box.sel(go(e.currentTarget, $closest('.prj_item')));
        DfTaskHistoryF.historyOpen(projection?.id);
      }),
      $.on(
        'click',
        '.task_items .task .title, .task_item .task .head',
        __(LF.tasks_event.body.open_task_detail, _p.if2(_p.idtt)(LF.issue_event.get_task, open_task_detail)),
      ),
      $.on('click', '.task_items .btn.add', __(task_items_btn_add, $.don_loader_end)),
    );
  };

  const projection_detail_tasks_event_init = __(
    $.on('contextmenu', '.task_items .task .title, .task_item .task .head', function (e) {
      e.preventDefault();
      const ct = e.currentTarget;
      const task = _go(ct, $.closest('[_sel]'), box.sel);
      if (
        !(
          (['as', 'before_deliver'].includes(task.type) &&
            box.sel('is_user->_->policies->task_manager_policy')) ||
          box.sel('is_user->_->policies->task_create_policy') ||
          (/press/gi.exec(task.title) && box.sel('is_user->team_id') === 11)
        )
      ) {
        $.alert('태스크 수정 권한이 없습니다.');
        return;
      }

      const obj = task.is_hidden
        ? {
            class_names: 'recover_task',
            text: '태스크 복구',
          }
        : {
            class_names: 'remove_task',
            text: '태스크 삭제',
          };
      make_tooltip(
        {
          contents: [
            {
              class_names: 'change_worker',
              text: '작업자 할당',
            },
            obj,
          ],
          events: [
            {
              class_name: 'remove_task',
              func: function () {
                if (
                  !(
                    (['as', 'before_deliver'].includes(task.type) &&
                      box.sel('is_user->_->policies->task_manager_policy')) ||
                    box.sel('is_user->_->policies->task_create_policy')
                  )
                ) {
                  $.alert('태스크 삭제 권한이 없습니다.');
                  return;
                }
                return _p.go(
                  $.confirm('정말 삭제 하시겠습니까?'),
                  _p.if2(_p.idtt)(
                    () =>
                      $.post('/@api/projection/task_update_and_change_status', {
                        id: task.id,
                        is_hidden: true,
                        remover_id: box.sel('is_user->id'),
                        projection_id: task.projection_id,
                      }),
                    () => render_projection_detail_tasks_tab(e.delegateTarget),
                  ),
                );
              },
            },
            {
              class_name: 'change_worker',
              func: function () {
                return _p.go(change_task_worker(task), () =>
                  render_projection_detail_tasks_tab(e.delegateTarget),
                );
              },
            },
            {
              class_name: 'recover_task',
              func: function () {
                if (
                  !(
                    (['as', 'before_deliver'].includes(task.type) &&
                      box.sel('is_user->_->policies->task_manager_policy')) ||
                    box.sel('is_user->_->policies->task_create_policy')
                  )
                ) {
                  $.alert('태스크 복구 권한이 없습니다.');
                  return;
                }
                return _p.go(
                  $.confirm('테스크를 복구 하시겠습니까?'),
                  _p.if2(_p.idtt)(
                    () =>
                      $.post('/@api/projection/task_update_and_change_status', {
                        id: task.id,
                        is_hidden: false,
                        projection_id: task.projection_id,
                      }),
                    () => render_projection_detail_tasks_tab(e.delegateTarget),
                  ),
                );
              },
            },
          ],
        },
        e,
      );
    }),
    $.on('click', '.tasks_container >.head .show_hidden_tasks', function (e) {
      _p.go(e.currentTarget, $.closest('.tasks_container'), $.attr({ show_hidden_tasks: true }));
    }),
    $.on(
      'click',
      '.tasks_container >.head .cancel_all_tasks',
      LF.tasks_event.header.cancel_tasks(function (e) {
        render_projection_detail_tasks_tab(e.delegateTarget);
      }),
    ),
    $.on('click', '.tasks_container >.head .create_all_issues', function (e) {
      return _p.go(LF.tasks_event.header.create_all_issues(e.currentTarget), function () {
        render_projection_detail_tasks_tab(e.delegateTarget);
      });
    }),
    $.on('click', '.tasks_container >.head .task_history', function (e) {
      const projection = box.sel(go(e.currentTarget, $closest('.df_grid')));
      DfTaskHistoryF.historyOpen(projection.id);
    }),
    $.on('click', '.task[has_issue="true"] .task_issue', LF.issue_event.modal),
    $.on(
      'click',
      '.task_items .task .title, .task_item .task .head',
      __(LF.tasks_event.body.open_task_detail, _p.if2(_p.idtt)(LF.issue_event.get_task, open_task_detail)),
    ),
    $.on('click', '.task_items .btn.add', __(task_items_btn_add, $.don_loader_end)),
  );

  const my_issue_task_event_init = __(
    $.on('click', '.task[has_issue="true"] .task_issue', __(LF.issue_event.modal)),
    $.on(
      'click',
      '.task_items .task .title, .task_item .task .head',
      __(LF.tasks_event.body.open_task_detail, _p.if2(_p.idtt)(LF.issue_event.get_task, open_task_detail)),
    ),
  );

  const task_set_tasks_event_init = __(
    $.on(
      'click',
      '.task_items .task',
      __(_p.v('currentTarget'), $.closest('[task_id]'), $.attr('task_id'), _p.nest('id'), open_task_detail),
    ),
    $.on(
      'click',
      '.task_set .task_items .btn.add',
      _p.throttle(__(LF.task_sets_event.body.add_original_task, open_task_detail), 1000, { trailing: false }),
    ),
  );

  function render_projection_detail_tasks_tab(don_tab_el) {
    const p = box.sel('df/projection/detail->projection');
    return _p.go(
      $.get('/@api/task/projection_tasks', _p.pick(box.sel('df/projection/detail->projection'), ['id'])),
      _p.v('_.tasks'),
      _p.tap((tasks) => box.set('df/projection/detail->projection->_->tasks', tasks)),
      function () {
        return box.sel('df/projection/detail->projection->_->tasks');
      },
      (tasks) => pug`
        .top_id
          a[href="/projection/detail/${p.id}" target="_blank"] ${p.lang == 'kr' ? '[국문]' : '[영문]'} #${
        p.id
      }
        h2 태스크
        .prj.df_grid[_sel="df/projection/detail->projection" prj_id="${p.id}"]
          ${G.df.task.projection.tmpl(tasks)}
      `,
      $.html_to($.find1(don_tab_el, '>.don_wrapper')),
    );
  }

  function merge_bp_stocks(tasks) {
    return _p.go(
      tasks,
      _p.map((task) => task._.projection),
      _p.uniq((p) => p.id),
      _p.map((p) => p._.bp_stocks),
      _p.flatten,
      _p.filter((b) => b._.quantity),
      _p.map(({ id, _: { quantity } }) => ({ id, quantity })), // debug_mode _.map(bp_stock => _.extend({id: bp_stock.id}, bp_stock._)),
      _p.group_by(({ id }) => `#${id}`),
      _p.map((bp_stocks) => ({
        id: bp_stocks[0].id,
        quantity: _p.reduce(_p.pluck(bp_stocks, 'quantity'), (a, b) => a + b),
      })),
    );
  }

  $.frame.defn_page({
    page_name: 'task.list',
    tabs: [
      {
        tab_name: 'task.list',
        title: '태스크 리스트',
        template: DfTaskListTmplS.template,
        appended: __(
          $.on2('click', 'button.get_dates', async (e) => {
            const { type } = e.currentTarget.dataset;
            const { data: dates } = await axios.get(`/@api/task/dates/${type}`, {
              params: {
                is_my_list: box.sel('states->is_my_list'),
                status: box.sel('states->status') || 'ready_on',
              },
            });
            $setHTML(G.df.search9(dates, type), $closest('.s_col.dates', e.currentTarget));
          }),

          $.on2('click', '.projection_code_image', async ({ currentTarget }) => {
            const label_data = await getProjectionLabelData($attr('data-projection_id', currentTarget));
            label_data && label_data.length && (await printProjectionLabel(label_data));
          }),

          $.on2('click', '.file_list a', function (e) {
            e.preventDefault();
            open($.attr(e.currentTarget, 'href'));
          }),
          projection_tasks_event_init,
          $.on(
            'click',
            '.search_result button.init',
            () => (window.location.href = window.location.pathname + `?status=${box.sel('states->status')}`),
          ),
          $.on('click', '.search_result button.go', G.df.search_btn_event),
          $.on2('change', 'select.prj_product_mode', G.df.search_btn_event),

          _p.tap(function (don_tab) {
            const marker = new Mark('#body .task_list');
            don_tab.marker = marker;
            marker.mark(
              _p
                .compact([
                  $.val($1('#filter input[name="task_title"]')) || '',
                  $.val($1('#filter input[name="worker_info"]')) || '',
                  $.val($1('#filter input[name="bp_name_info"]')) || '',
                  $.val($1('#filter input[name="bp_color_info"]')) || '',
                  $.val($1('#filter input[name="price"]'))
                    ? PriceS.pricify($.val($1('#filter input[name="price"]')))
                    : '',
                ])
                .join(' '),
              { separateWordSearch: true },
            );
          }),
          _p.tap((tab_el) => {
            if (box().is_user?._?.policies?.outsourcing_worker_policy) {
              go(tab_el, $findAll('.task_item'), each(DfTaskImageWorkF.makeOnlyMyTasks));
            }
            if (
              box.sel('is_user->_->policies->outsourcing_company_zip_download_policy') &&
              box().is_user?.outsourcing_company?.id === 3
                ? (window.location.search.includes('prj_status=printing') ||
                    window.location.search.includes('prj_status=before_print')) &&
                  window.location.search.includes('status2=ready')
                : window.location.search.includes('prj_status=printing') &&
                  window.location.search.includes('status2=ready')
            ) {
              const image_work_wrapper_el = $find('.image_work_wrapper')(tab_el);
              if (image_work_wrapper_el) {
                DfTaskImageWorkF.init(image_work_wrapper_el);
              }
            }
          }),
          _p.tap(
            _p.tap(function (...arg) {
              return G.df.projection.detail.img_thumbnail_error(...arg);
            }),
            G.mp.maker.draw_product_face_in_ups,
            _p.f('G.df.projection.detail.up_item_init'),
          ),
          $.on('click', '.check_bp_stock_option .btn_add_order', function (e) {
            const bp_stocks_task_ids = get_bp_stocks_task_ids();
            if (!bp_stocks_task_ids.bp_stocks.length) return $.alert('제품이 없습니다');
            if (!bp_stocks_task_ids.task_ids.length) return $.alert('태스크가 없습니다');

            $.frame.open(
              {
                frame_name: 'df.ready_order_list_frame',
                page_name: 'df.ready_order_list_page',
                closed: function (X, order) {
                  // if (!order) return;
                },
              },
              {
                page_name: 'df.ready_order_list_page',
                tabs: [
                  {
                    tab_name: 'df.ready_order_list_tab',
                    data_func: function () {
                      return _p.mr(bp_stocks_task_ids.bp_stocks, bp_stocks_task_ids.task_ids);
                    },
                  },
                ],
              },
            );
          }),
          $.on('click', '.check_bp_stock_option .btn_new_order', function (e) {
            const bp_stocks_task_ids = get_bp_stocks_task_ids();
            if (!bp_stocks_task_ids.bp_stocks.length) return $.alert('제품이 없습니다');
            if (!bp_stocks_task_ids.task_ids.length) return $.alert('태스크가 없습니다');

            $.frame.open(
              {
                frame_name: 'df.order_editor_frame',
                page_name: 'df.order_editor_page',
                title: '재고 확인',
                closed: function (X, order) {
                  // if (!order) return;
                },
              },
              {
                page_name: 'df.order_editor_page',
                tabs: [
                  {
                    tab_name: 'df.order_editor_tab',
                    data_func: function () {
                      return _p.mr(bp_stocks_task_ids.bp_stocks, void 0, bp_stocks_task_ids.task_ids);
                    },
                  },
                ],
              },
            );
          }),
          $.on2('click', '.check_bp_stock_option .btn_all_bp_stock_confirm_exeldown', async (e) => {
            const confirm = await $.confirm(
              '모든 재고 태스크가 완료된 후<br>엑셀파일이 다운로드 됩니다.<br>진행하시겠습니까?',
            );
            if (!confirm) return;

            try {
              $.don_loader_start();

              await axios.patch('/@api/tasks/update_product_transfer_stock_team');

              const { data } = await axios.post('/@api/df/all_bp_stock_confirm_excel_download');
              XLSX.writeFile(data, 'bp_stocks_confirm_' + moment().format('YYYY_MM_DD_HH_mm_ss') + '.xlsx');

              window.location.reload();
            } catch (err) {
              $.alert(`에러가 있습니다. ${err.message}`);
            } finally {
              $.don_loader_end();
            }
          }),
          $.on2('click', '.check_bp_stock_option .btn_today_bp_stock_confirm_exeldown', async (e) => {
            const today = new Date();
            try {
              const projection_ids_for_today = go(
                await axios.get('/@api/task/stock_confirm/complete', {
                  params: {
                    date: today,
                  },
                }),
                sel('data'),
                pluck('projection_id'),
              );

              if (projection_ids_for_today.length === 0) {
                $.alert('오늘 완료 된 재고 확인 태스크가 없습니다.');
              }

              const { data } = await axios.post('/@api/df/all_bp_stock_confirm_excel_download', {
                projection_ids: projection_ids_for_today,
                is_update_complete: false,
              });

              XLSX.writeFile(
                data,
                'today_bp_stocks_confirm_' + moment().format('YYYY_MM_DD_HH_mm_ss') + '.xlsx',
              );
            } catch (error) {
              const msg = UtilF.getErrMsg(error);
              $.alert(msg);
            }
          }),
          $.on2('click', '.check_bp_stock_option .btn_bp_stock_confirm_exeldown', function (e) {
            const bp_stocks_task_ids = get_bp_stocks_task_ids();

            if (!bp_stocks_task_ids.bp_stocks.length) return $.alert('제품이 없습니다');
            if (!bp_stocks_task_ids.task_ids.length) return $.alert('태스크가 없습니다');

            return _p.go(
              $.confirm('선택된 재고 태스크가 완료된 후<br>엑셀파일이 다운로드 됩니다.<br>진행하시겠습니까?'),
              _p.if2(_p.idtt)(function () {
                const el_mode_selector = $.closest(e.currentTarget, '#mode_selector');
                const el_task_ids = $.find1(el_mode_selector, 'form input[name="task_ids"]');
                const el_projection_ids = $.find1(el_mode_selector, 'form input[name="projection_ids"]');
                const el_bp_stocks_ids = $.find1(el_mode_selector, 'form input[name="bp_stocks"]');

                $.val(el_task_ids, JSON.stringify(bp_stocks_task_ids.task_ids));
                $.val(el_projection_ids, JSON.stringify(bp_stocks_task_ids.projection_ids));
                $.val(el_bp_stocks_ids, JSON.stringify(bp_stocks_task_ids.bp_stocks));

                $.find1(el_mode_selector, '.submit_bp_stock_confirm_exeldown').click();
                setTimeout(function () {
                  window.location.reload();
                }, 100);
              }),
            );
          }),
          $.on('click', '.check_bp_stock_option .check_all', function (e) {
            const is_checked = e.currentTarget.checked;
            _p.go(
              $('.check_bp_stock:not(.no_need) input[type="checkbox"]'),
              _each((v) => (v.checked = is_checked)),
            );
          }),
          makeDfProjectionListEvent,
          $.on('click', '.btn_transfer_product_empty_stock', DfTaskListF.handleTransferProductComplete),
        ),
        showed: __(async function (tab_el) {
          await G.df.task.list_update();
          $.on(window, 'scroll', G.df.task.list_update_debounce);
          $.on(window, 'focus', G.df.task.list_update);
          G.df.task.list_update_auto();
          $.on(window, 'blur', G.df.task.blur_list_update_auto);
        }),
        hiding: function () {
          $.off(window, 'scroll', G.df.task.list_update_debounce);
          $.off(window, 'focus', G.df.task.list_update);
          $.off(window, 'blur', G.df.task.blur_list_update_auto);
          G.df.task.list_update_auto_stop();
        },
        rendered: (tab_el) => {
          moveScrollToFirstPrj(tab_el);
        },
      },
    ],
  });

  initTaskFrames(LF);

  G.df.task.blur_list_update_auto = function () {
    G.df.task.list_update_auto_stop();
    $.once(window, 'focus', G.df.task.list_update_auto);
  };

  let interval;
  G.df.task.list_update_auto = function () {
    interval = setInterval(G.df.task.list_update_debounce, 8000);
  };

  G.df.task.list_update_auto_stop = function () {
    clearInterval(interval);
  };

  G.df.task.list_update_debounce = _p.debounce(function () {
    G.df.task.list_update();
  }, 400);

  G.df.task.merge_bp_stocks = merge_bp_stocks;

  G.df.task.projection = {};
  G.df.task.issue = {};
  G.df.task.projection.tmpl = DfTaskF.t_prj_task_column;

  G.df.task.projection.init = projection_tasks_event_init;
  G.df.task.projection.task_set_tasks_event_init = task_set_tasks_event_init;
  // G.df.task.projection.task_set_detail_tasks_event_init = task_set_detail_tasks_event_init;
  G.df.task.projection.projection_detail_tasks_event_init = projection_detail_tasks_event_init;
  G.df.task.projection.render_projection_detail_tasks_tab = render_projection_detail_tasks_tab;
  G.df.task.t_task_item = DfTaskTmplS.t_task_item;
  G.df.task.t_prj_tasks = DfTaskF.t_prj_tasks;
  G.df.task.detail_saving = DfTaskDetailF.detail_saving;
  G.df.task.set_task_box = DfTaskDetailF.set_task_box;
  G.df.task.t_my_task = DfTaskTmplS.t_my_task;
  G.df.task.issue.my_issue_task_event_init = my_issue_task_event_init;
})({});
