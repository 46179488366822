import { each, find, go } from 'fxjs/es';
import { $addClass, $closest, $delegate, $find, $findAll, $hasClass, $removeClass, $setText } from 'fxdom/es';
import { SVGEditorAttrS } from '../../../Attr/S/Function/module/SVGEditorAttrS.js';

import Pickr from '@simonwep/pickr/dist/pickr.es5.min.js';

export function isSameColorCode(c1, c2) {
  return c1?.toUpperCase() === c2?.toUpperCase();
}

function selectColorEl({ color_code, color_els, key, ___selected_el }) {
  const colors_wrapper_el = go(color_els[0], $closest('.colors_wrapper'));
  const etc_el = go(
    color_els,
    find((el) => el.dataset.is_etc === 'true'),
  );
  const title_el = $find('.title')(colors_wrapper_el);

  go(
    color_els,
    find((color_el) => isSameColorCode(color_el.dataset.color_code, color_code)),
    (el) => {
      go(color_els, each($removeClass('active')));
      if (el) {
        $addClass('active')(el);
        if (title_el) $setText(el.dataset.color_name, title_el);
      } else {
        $addClass('active')(etc_el);
        if (title_el) $setText(color_code, title_el);
      }
    },
  );
  SVGEditorAttrS.set({ [key]: color_code }, ___selected_el);
}
export const event = ({ el, key, ___selected_el }) => {
  const default_color_code = SVGEditorAttrS.get(key, ___selected_el);
  const color_els = $findAll('.colors .color')(el);
  const _picker = Pickr.create({
    el: $find('.color[data-is_etc="true"]')(el),
    container: 'body',
    theme: 'monolith',
    useAsButton: true,
    default: default_color_code,
    components: {
      preview: true,
      hue: true,
      interaction: {
        hex: true,
        input: true,
      },
    },
    i18n: { 'btn:clear': '초기화' },
  }).on('change', (color) => {
    const color_code = color.toHEXA().toString();
    selectColorEl({ color_code, color_els, key, ___selected_el });
  });
  go(
    el,
    $delegate('click', '.color:not(.active)', (e) => {
      if ($hasClass('is_not_color')(e.currentTarget)) return;
      const color_code = e.currentTarget.dataset.color_code;
      if (e.currentTarget.dataset.is_etc === 'true') {
        go(
          color_els,
          each($removeClass('active')),
          find((el) => el.dataset.is_etc === 'true'),
          $addClass('active'),
        );
        return;
      }
      if (color_code === 'none') {
        go(
          color_els,
          each($removeClass('active')),
          find((el) => el.dataset.color_code === color_code),
          $addClass('active'),
          (el) => {
            const colors_wrapper_el = go(color_els[0], $closest('.colors_wrapper'));
            const title_el = $find('.title')(colors_wrapper_el);
            if (title_el) $setText(el.dataset.color_name, title_el);
          },
        );
        SVGEditorAttrS.set({ [key]: color_code }, ___selected_el);
        return;
      }
      _picker.setColor(color_code);
    }),
  );
  return _picker;
};
