import querystring from 'querystring';
import { AdScriptNaverF } from '../../../../modules/AdScript/Naver/F/Function/module/AdScriptNaverF.js';
import { OMPCoreUtilS } from '../../../../modules/OMP/Core/Util/S/Function/module/OMPCoreUtilS.js';
import { UtilF } from '../../../../modules/Util/F/Function/module/UtilF.js';
import { VectorEditorConstantS } from '../../../../modules/VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { MuiF } from '../../../../modules/Mui/F/Function/module/MuiF.js';

export default function () {
  const href = location.href;
  _p.go($.frame({ frame_name: 'mp.product.detail' }), async () => {
    if (href !== location.href) $.frame.extend_state(void 0, null, href, 'replace');

    if (G.collabo_type === '') {
      const query = querystring.parse(location.search.slice(1));

      if (
        query.is_svg_f_m !== 'true' &&
        UtilF.isLegacyRealTablet() &&
        VectorEditorConstantS.EDITOR_TYPES.includes(
          box.sel('maker->product_color->_->base_product->maker_type'),
        )
      ) {
        query.is_svg_f_m = 'true';
        await $.alert(T('maker_error_alert::해당 상품은 태블릿에서 모바일 화면으로만 작업이 가능합니다.'));
        window.location.replace(`${window.location.pathname}?${querystring.stringify(query)}`);
      } else if (query.is_svg_f_m === 'true' && !UtilF.isLegacyRealTablet()) {
        delete query.is_svg_f_m;
        if (UtilF.isLegacyRealMobile()) {
          MuiF.extendHistoryState(
            null,
            null,
            `${window.location.pathname}?${querystring.stringify(query)}`,
            true,
          );
        } else {
          await $.alert(T('maker_error_alert::PC 화면으로 전환됩니다.'));
          window.location.replace(`${window.location.pathname}?${querystring.stringify(query)}`);
        }
      }
    }
  });

  $1('html').x_style_height_1 = $.height(window);
  const is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
  G.product_detail_scroll_pass = false;
  if (is_uiwebview || window.navigator.standalone) {
    $.attr($1('html'), 'x_style_navi_hidden', 'true');
  } else if (/(iPhone|iPod|iPad)/i.test(navigator.userAgent)) {
    $.on(window, 'scroll', function () {
      if ($1('html.mp_maker_not_ready')) return;
      if (G.product_detail_scroll_pass) return;
      // if (
      //   !$1('.don_frame[frame_name="mp.maker.fake.mobile"]') &&
      //   $1('.don_frame[frame_name="mp.product.detail"].is_show')
      // ) {
      //   if ($.scroll_top(window) > $.height(window)) {
      //     if ($1('#maker')) $.remove(G.mp.maker.maker_el);
      //   } else if (!$1('#maker')) {
      //     $.append($1('#maker_frame'), G.mp.maker.maker_el);
      //   }
      // }
      $.attr($1('html'), 'x_style_navi_hidden', $1('html').x_style_height_1 < $.height(window));
    });
  }

  try {
    if (!OMPCoreUtilS.isMobile()) {
      window.addEventListener('unload', function (e) {
        if (typeof gtag == 'function') {
          const init_time = localStorage.getItem('init_time');
          if (init_time) {
            gtag('event', 'maker_dwell_time', {
              dwell_time: new Date().getTime() - init_time,
            });
          }
        }
        localStorage.removeItem('init_time');
      });
    }
    if (typeof gtag == 'function') {
      gtag('event', 'view_item', {
        dynx_itemid: box.sel('maker->product_color->_->base_product->id'), // 상품 코드
        is_login_user: Boolean(box.sel('is_user')),
        dynx_itemid2: box.sel('maker->product_color->_->base_product->id'), // 상품 코드
        dynx_pagetype: 'offerdetail',
        dynx_totalvalue: box.sel('maker->product_color->price' + _en), // 상품 금액
        ecomm_prodid: box.sel('maker->product_color->_->base_product->id'),
        ecomm_pagetype: 'offerdetail',
        event_label: 'offerdetail',
        items: [
          {
            id: box.sel('maker->product_color->_->base_product->id'),
            name: box.sel('maker->product_color->_->base_product->name' + _en),
            list_name: '상품 상세',
            // "brand": "Google",
            // "category": "Apparel/T-Shirts",
            // "variant": "Black",
            list_position: 1,
            quantity: 1,
            price: box.sel('maker->product_color->price' + _en),
          },
        ],
      });
    }
    if (typeof fbq == 'function') {
      fbq('track', 'ViewContent', {
        value: box.sel('maker->product_color->price' + _en),
        currency: T.lang == 'kr' ? 'KRW' : 'USD',
        content_ids: box.sel('maker->product_color->_->base_product->id'),
        content_type: 'product',
      });
    }
    if (T.lang == 'kr') {
      AdScriptNaverF.sendMpViewProduct({
        items: [
          {
            id: box.sel('maker->product_color->_->base_product->id'),
            name: box.sel('maker->product_color->_->base_product->name'),
            quantity: 1,
            payAmount: box.sel('maker->product_color->price' + _en),
          },
        ],
      });
    }
  } catch (err) {}
}
