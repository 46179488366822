import { each, go, isNil, rejectL, takeAll, takeL, tap } from 'fxjs/es';
import { $findAll, $on } from 'fxdom/es';
import { VectorEditorStickerSingleCreatorPCCuttingLineManualMuiS } from '../../S/Mui/module/VectorEditorStickerSingleCreatorPCCuttingLineManualMuiS.js';
import { VectorEditorStickerSingleCreatorPCCuttingLineManualEventF } from '../Event/module/VectorEditorStickerSingleCreatorPCCuttingLineManualEventF.js';
import { CommonNS, EditorNS } from '@marpple/sticker-editor';
import { VectorEditorTopMenuPCF } from '../../../../../../TopMenu/PC/F/Function/module/VectorEditorTopMenuPCF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerSingleCreatorPCCuttingLineManualMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_target_image_el = tab_el.__mp_target_image_el ?? null;
    tab_el.__mp_cutting_line_path_data = tab_el.__mp_cutting_line_path_data ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_cutting_line_editor = null;
    tab_el.__mp_editor_padding_top = tab_el.__mp_editor_padding_top ?? 80;
    tab_el.__mp_editor_padding_bottom = tab_el.__mp_editor_padding_bottom ?? 80;
    tab_el.__mp_editor_padding_left = tab_el.__mp_editor_padding_left ?? 300;
    tab_el.__mp_editor_padding_right = tab_el.__mp_editor_padding_right ?? 300;

    go(
      tab_el,
      $findAll(`.top_menu_container .top_menus`),
      each(VectorEditorTopMenuPCF.hideToggleLayersModeIcon),
    );

    go(
      tab_el,

      tap(
        $findAll(`.right_container .right_wrapper .button_container .cancel`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleCreatorPCCuttingLineManualEventF.handleRightButtonContainerCancelButtonClick(),
          ),
        ),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .button_container .done`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleCreatorPCCuttingLineManualEventF.handleRightButtonContainerDoneButtonClick(
              {
                tab_el,
              },
            ),
          ),
        ),
      ),

      tap(
        $findAll(`.right_container .right_wrapper .right_panel .add_l`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleCreatorPCCuttingLineManualEventF.handleRightPanelAddLButtonClick({
              tab_el,
            }),
          ),
        ),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .right_panel .add_c`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleCreatorPCCuttingLineManualEventF.handleRightPanelAddCButtonClick({
              tab_el,
            }),
          ),
        ),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .right_panel .remove`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleCreatorPCCuttingLineManualEventF.handleRightPanelRemoveButtonClick({
              tab_el,
            }),
          ),
        ),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .right_panel .change_to_l`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleCreatorPCCuttingLineManualEventF.handleRightPanelChangeToLButtonClick({
              tab_el,
            }),
          ),
        ),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .right_panel .change_to_c`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleCreatorPCCuttingLineManualEventF.handleRightPanelChangeToCButtonClick({
              tab_el,
            }),
          ),
        ),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .right_panel .reset`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleCreatorPCCuttingLineManualEventF.handleRightPanelResetButtonClick({
              tab_el,
            }),
          ),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    const cutting_line_editor = new EditorNS.CuttingLineEditorNS.PCNS.CuttingLinePCEditor({
      window,
      document,
      min_zoom_scale: 1,
      max_zoom_scale: 3,
    });
    tab_el.__mp_cutting_line_editor = cutting_line_editor;
    cutting_line_editor.initDOM();
    cutting_line_editor.initEditor();
    go(
      tab_el,
      $findAll(`.left_container .editor_container`),
      takeL(1),
      each((container_el) => cutting_line_editor.appendTo(container_el)),
    );

    const task1 =
      tab_el.__mp_target_image_el == null
        ? null
        : go(CommonNS.UtilNS.deepCloneNode(tab_el.__mp_target_image_el), (clone_target_image_el) =>
            cutting_line_editor.setTargetImageEl(clone_target_image_el),
          );
    const task2 =
      tab_el.__mp_cutting_line_path_data == null
        ? null
        : cutting_line_editor.setCuttingLinePathData(tab_el.__mp_cutting_line_path_data);
    return go([task1, task2], rejectL(isNil), takeAll);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    tab_el.__mp_cutting_line_editor?.fitSize?.();
    const view_box_vo = (() => {
      const target_image_width = tab_el.__mp_target_image_el?.width?.baseVal?.value;
      const target_image_height = tab_el.__mp_target_image_el?.height?.baseVal?.value;

      if (target_image_width == null || target_image_height == null) {
        return null;
      }

      return tab_el.__mp_cutting_line_editor?.calculateViewBoxFitTo?.({
        size_in_ucs: {
          width: target_image_width,
          height: target_image_height,
        },
        padding_in_vcs: {
          top: tab_el.__mp_editor_padding_top,
          bottom: tab_el.__mp_editor_padding_bottom,
          left: tab_el.__mp_editor_padding_left,
          right: tab_el.__mp_editor_padding_right,
        },
      });
    })();
    if (view_box_vo != null) {
      tab_el.__mp_cutting_line_editor?.setViewBox?.(view_box_vo);
    }
    tab_el.__mp_cutting_line_editor?.startEditCuttingLinePath?.();
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {
    tab_el.__mp_target_image_el = null;
    tab_el.__mp_cutting_line_path_data = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_cutting_line_editor?.destroy?.();
    tab_el.__mp_cutting_line_editor = null;
    tab_el.__mp_editor_padding_top = 0;
    tab_el.__mp_editor_padding_bottom = 0;
    tab_el.__mp_editor_padding_left = 0;
    tab_el.__mp_editor_padding_right = 0;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
