import UAParser from 'ua-parser-js';

const ua_parser = new UAParser();
export const is_ios = () => {
  return /IOS/i.test(ua_parser.getOS()?.name);
};

export const is_android = () => {
  return /android/i.test(ua_parser.getOS()?.name);
};

export const is_safari = () => {
  return ua_parser.getBrowser()?.name?.toLowerCase()?.includes('safari');
};
