import { BpOptionS } from '../../../../BpOption/S/Function/module/BpOptionS.js';
import { keyringProcessByFile } from './private/keyringPostProcess.js';
import { acrylicFigureProcessByFile } from './private/acrylicFigurePostProcess.js';
import { VectorEditorConstantS } from '../../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { $qs, $qsa } from 'fxdom/es';
import { getProductColorInMaker } from '../../../../Maker/F/getSth.js';
import { NewMakerCvObjActionF } from '../../../../NewMaker/CvObjAction/F/Function/module/NewMakerCvObjActionF.js';
import { NewMakerBpOptionF } from '../../../../NewMaker/BpOption/F/Function/module/NewMakerBpOptionF.js';
import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { makeBpcfAvgSize } from '../../../../Maker/F/canvas_size.js';
import { BpOptionKeyRingConstantS } from '../../../../BpOption/KeyRing/S/Constant/module/BpOptionKeyRingConstantS.js';
import { makeFakeMpMakerSize } from '../../../../Maker/F/fake_mp_maker.js';
import { setDataUploadTypeIsDesigned } from '../../../../NewMaker/Util/F/Function/makeDataIsDesigned.js';
import { marpplizerProxy } from '../../../../Maker/F/Marpplizer/marpplizer.js';
import { renderProductColorInProductDetailMobile } from '../../../../Maker/F/maker_tmpl.js';

export const directFileUploadSvgProcess = async ({
  cutting_line_svg_file,
  key_item_coord_ratio,
  printable_png_file,
  thumbnail_png_file,
  original_svg_file,
  original_svg_file_name,
}) => {
  const bp_option_groups_el = $qs('.bp_option_groups');
  const selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);
  const new_pc = box().maker.product_color;
  new_pc._.selected_option_group = selected_option_group;
  const lockable_bp_option_group_els = $qsa('.bp_option_groups .bp_option_group[data-is_lockable="true"]');
  const product = getProductColorInMaker();

  const formData = new FormData();
  formData.append('original_svg_file', await original_svg_file);
  const { original_svg_file_url } = await $.upload(formData, {
    url: '/@fileUpload/svg_images_images/upload_svg_product_materials',
    data: {},
  });
  if (product._.base_product.maker_type === VectorEditorConstantS.KEYRING_EDITOR) {
    await keyringProcessByFile(
      { product, lockable_bp_option_group_els, bp_option_groups_el },
      {
        cutting_line_svg_file,
        makePrintablePNGFile: () => printable_png_file,
        hook_position_ratio: key_item_coord_ratio,
        makeThumbnailPNGFile: () => thumbnail_png_file,
        original_svg_file_url,
        original_svg_file_name,
      },
    );
    if (!bp_option_groups_el.dataset.is_designed) {
      await NewMakerCvObjActionF.render({ bp_option_ids: selected_option_group.bp_option_ids });
    }
  } else if (product._.base_product.maker_type === VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR) {
    await acrylicFigureProcessByFile(
      { product, lockable_bp_option_group_els, bp_option_groups_el },
      {
        original_svg_file,
        cutting_line_svg_file,
        makePrintablePNGFile: () => printable_png_file,
        stand_leg_position_ratio: key_item_coord_ratio,
        original_svg_file_url,
        original_svg_file_name,
      },
    );
    if (bp_option_groups_el.dataset.is_designed !== 'true') {
      const preview_images = BpOptionS.selectedOptionGroup.getPreviewImagesInMaker(
        box().maker.product_color._.base_product._.base_products_bp_option_groups,
        selected_option_group,
      );
      if (preview_images?.length) {
        await NewMakerBpOptionF.addPreviewImage(preview_images);
      }
    }
  }
  setDataUploadTypeIsDesigned();
  await BpOptionF.makeSelectedOptionPriceC2323({
    base_products_bp_option_groups: product._.base_product._.base_products_bp_option_groups,
    bp_option_groups_el,
    selected_option_group,
  });
  if (!UtilF.isLegacyMobile()) {
    await marpplizerProxy();
    // $qs('.svg_editor_pc_product_thumbnail_wrapper').outerHTML = SVGEditorProductDetailTmplS.pcProductDetail(
    //   product,
    //   box.sel('review_list->review_total'),
    // );
  } else {
    renderProductColorInProductDetailMobile();
    G.mp.maker.bpcf_size = makeBpcfAvgSize();
    if (selected_option_group.bp_option_ids.includes(BpOptionKeyRingConstantS.KEYRING_NO_GORI_BP_OPTION_ID)) {
      G.mp.maker.bpcf_size = {
        top: 207.12209302325581,
        left: 132.99418604651163,
        height: 239.38953488372093,
        bottom: 220.63953488372093,
        width: 110.75581395348837,
      };
    }
    makeFakeMpMakerSize();
    G.mp.maker.zoom.canvas_container_display_mode(true);
  }
};
