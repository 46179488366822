import { html } from 'fxjs/es';

export const jpFirst = () => html`
  <div class="terms_body">
    <div class="title">
      <h1>利用規約</h1>
    </div>
    <div class="body">
      <div class="text_body">
        <div class="paragraph">
          <h3 id="service1">[ 本規約の適用範囲及び変更 ]</h3>
          <ol>
            <li>
              •
              本規約は弊社サービスの提供またはその利用に関し、当サイト及び閲覧・購入などの利用を行ったお客様に適用されるものとします。
            </li>
            <li>• 本サービスをご利用いただいたお客様はこの規約に同意されたものとさせていただきます。</li>
            <li>• 当サイトは、利用者の事前の承諾を得る事なく本規約を変更できるものとします。</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service2">[ 弊社サービスの利用資格の停止 ]</h3>
          <p>
            当サイトは、利用者が以下の項目に該当する場合、お客様に事前の通知することなく本サービスの利用資格を取り消すことができるものとします。
          </p>
          <ol>
            <li>• 名誉や信用毀損などの犯罪的行為や法令に違反する行為があったとき</li>
            <li>• アカウントの不正使用があったとき</li>
            <li>• 過去に規約違反等により利用資格が取り消されていることが判明したとき</li>
            <li>• 一定回数以上のパスワードの入力ミスなど利用者のセキュリティ確保のために必要と認めたとき</li>
            <li>• 弊社に対する乱暴な言動、危害を加えるような告知行為など、不当な要求行為が行われたとき</li>
            <li>• その他、本規約に違反する行為があったとき</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service3">[ 著作権 ]</h3>
          <p>
            当サイト内全てのURL上に含まれる内容等の著作権は運営管理責任者に帰属します。すべての内容は、「個人的な使用」「引用」など著作権法上認められた場合を除き、著作権者に無断で利用（複製、転載、配布など）することはできません。
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service4">[ IDおよびパスワードの管理 ]</h3>
          <p>
            IDおよびパスワードは、他人に知られることがないよう定期的に変更する等、会員本人が責任をもって管理してください。盗用、不正使用その他の事情により会員以外の者が利用している場合であっても、それにより生じた損害について弊社は一切責任を負いません。
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service5">[ 会員の退会 ]</h3>
          <p>
            会員が退会を希望する場合には、会員本人が会員情報の削除を行ってください。所定の退会手続の終了後に、退会となります。
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service6">[ 個人情報の利用 ]</h3>
          <p>
            弊社は、会員または弊社サービスを利用したお客様からのご注文処理時に入力された税法に関する注文履歴のデータについて、会員の有効是是非に関係なく一定期間保管する義務があります。個⼈情報は会員退会時に削除し、別途弊社が定める「個⼈情報保護⽅針」に基づいて取り扱います。これらの情報は商品のお届けや事務処理手続き、新商品サービスのご案内に限ってのみ使用させて頂きます。お客様の個人情報を以下の項目に該当する場合を除き、当サイト以外の第三者に提供または開示しません。
          </p>
          <ol>
            <li>• ご本人の同意がある場合</li>
            <li>• その他法令にもとづき開示・提供を求められた場合</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service7">[ サービスの停止 ]</h3>
          <p>
            当サイトは弊社サービスを常に最善に保つため、次の項目に該当する場合はお客様に事前に通知を行うことなく弊社サービスの提供の全部あるいは一部を停止することができるものとします。
          </p>
          <ol>
            <li>• システムの定期点検及び緊急保守のために必要な場合</li>
            <li>• 火災、天災、停電、第三者による妨害行為などによりシステムの運用が困難になった場合</li>
            <li>• その他、システムの停止が必要と運営管理責任者が判断した場合</li>
            <li>
              •
              上記の内容によってお客様に損害が発生した場合、弊社及び当サイト、運営管理責任者は一切の責任を負わないものとします。
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service8">[ 交換・返品対象外の事柄 ]</h3>
          <p>
            実際発送日において商品発送後、配送業者配送の停滞、事故等によって、お客様に損害が生じた場合、弊社は責任を負わないものとします。
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service9">[ その他免責事項 ]</h3>
          <ol>
            <li>
              •
              当サイトは、不良品や商品発送時の不測の事態（交通事情、自然災害等）により納期の遅延が生じた場合の二次的に発生する損害の一切の責任を負わないものとします。
            </li>
            <li>
              •
              当サイトは、法律上の請求原因にかかわらず、いかなる場合においても弊社サービスにおいて売買される商品に関する損害、損失、不利益などに関して前項に定める以外の責任を一切負わないものとします。
            </li>
            <li>
              •
              当サイトは、お客様が弊社サービスをご利用になれなかったことにより発生した一切の損害について、いかなる責任を負わないものとします。
            </li>
            <li>
              •
              弊社グループサイトは、やむを得ぬ事情または突発的な事象により、万が一弊社での生産・出荷体制を確保できなくなった場合、受注後におきましてもご対応できない場合が
              ありますのでご了承くださいませ。
            </li>
            <li>
              •
              受注後、ご決済頂いているものに関してましても、ご決済頂いた金額のみを返金の形で対応させて頂きます。（代引きにつきましては、決済が未処理という形でご対応させて頂きます。）いかなる場合におきましても、お客様には一切の金銭的補償は行いませんのでご了承下さいませ。
            </li>
            <li>
              •
              ご注文頂いたお客様が弊社サービスをご利用になることにより、別のお客様または第三者に対して損害などを与えた場合にも、お客様の自己の責任と費用において解決していただき、弊社としては一切の責任を負えません。ご了承ください。
            </li>
            <li>
              •
              お客様が弊社サービスを利用するために必要なコンピュータ機器及び通信機器などの設置に関する費用、弊社サービスを利用するために要した電話料金、LANなどの利用料及び申請料金などは、お客様ご自身がご負担いただきます。
            </li>
            <li>• 会員が本規約等に違反したことによって生じた損害については、弊社は一切責任を負いません。</li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service10">[ その他 ]</h3>
          <ol>
            <li>
              • 当サイトと利用者との連絡方法は、原則として電子メール及び電話・ファックスによるものとします。
            </li>
            <li>
              •
              弊社サービスのご利用に関して、本利用者規約により解決出来ない問題が生じた場合には、当サイト運営側とお客様との間で双方誠意を持って話し合い、これを解決するものとします。
            </li>
            <li>
              •
              弊社サービスの利用に関して訴訟の必要が発生した場合には、当サイト所在地を管轄する裁判所を唯一の合意管轄裁判所といたします。
            </li>
            <li>
              •
              お客様の売買代金不払いその他本規約違反行為によって損害賠償義務が発生し、その請求回収のために当サイト運営側が弁護士を用いた場合には、弁護士報酬規定に基づく弁護士費用についてもお客様の負担とします。
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service11">[ 受注拒否 ]</h3>
          <ol>
            <li>
              • 弊社のお客様として不適切と判断し、以後のご注文をお断りする場合があることをご了承いただきます。
              <ol>
                <li>（一連の取引での総合的な判断によります）</li>
                <li>
                  ※
                  弊社の士気に影響を及ぼすと共に、他のお客様の案件作業にも影響を及ぼしかねませんので、弊社のお客様として不適切と判断した場合以後の受注をお断りしております。
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service12">[ 判断要素の一例 ]</h3>
          <ol>
            <li>• メールの文面が著しく配慮を欠いている</li>
            <li>• 連絡が頻繁で、即時返信を要求する</li>
            <li>
              •
              お客様がする必要のある義務を怠っている（規約の確認・メールチェック・データの修正・支払期日厳守など）
            </li>
            <li>
              •
              取引中であっても、弊社が不適切と判断した場合、ご注文をキャンセルさせていただく場合があることをご了承いただきます。
            </li>
            <li>• 取引中止または、受注拒否が発生しても、弊社はその理由の説明を行わないものとします。</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">閉じる</button>
    </div>
  </div>
`;
