import { DfImageEditorF } from './module/DfImageEditorF.js';
import {
  DATA_SET_KEY,
  ITEM_NAME,
  R_STROKE_PROPERTY_NAME,
  STROKE_STYLE,
  TOOL_NAME,
} from '../../S/Constant/constants.js';
import { RasterStroke } from '../../../../NewMaker/BaseProducts/WebGlProcessing/F/private/filters/rasterStroke.js';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';
import { getPrintableRasterImageWithMargin } from './helpers.raster.js';
import { go } from 'fxjs/es';
import { $addClass, $closest, $delegate, $find, $qs, $removeClass } from 'fxdom/es';
import { tools } from '../Lib/state.js';
import noUiSlider from 'nouislider';
import { prepareNumber } from './tool.brush.js';

export function toolRStroke({ tab_el }) {
  DfImageEditorF.initializeBpcfColorPicker({ tab_el });

  DfImageEditorLibF.setState.property.rStroke.thickness({
    value: STROKE_STYLE.r_stroke_initial_thickness,
    with_dom_update: true,
  });
  initializeRStrokePainter({ tab_el });
  initiateAlphaThresholdSlider({ tab_el });

  const stroke_color_control_property_class = `.property-panel .properties[tool-name=${
    TOOL_NAME.rStroke
  }] .property[property-name=${
    tools[TOOL_NAME.rStroke].properties[R_STROKE_PROPERTY_NAME.color].propertyName
  }]`;

  const stroke_thickness_control_property_class = `.property-panel .properties[tool-name=${
    TOOL_NAME.rStroke
  }] .property[property-name=${
    tools[TOOL_NAME.rStroke].properties[R_STROKE_PROPERTY_NAME.thickness].propertyName
  }]`;

  go(
    tab_el,
    $delegate('input', `${stroke_color_control_property_class} input[type="color"]`, (e) => {
      const ct = e.currentTarget;
      updateRStrokeColor({ tab_el, color: ct.value });
      DfImageEditorF.focusBackToFrame({ tab_el });
    }),
    $delegate(
      'click',
      `${stroke_color_control_property_class} .custom-bpcf-picker button.open-bpcf-color-picker`,
      (e) => {
        const ct = e.currentTarget;
        const $bpcf_window = go($closest('.custom-bpcf-picker', ct), $find('.bpcf-window'));
        go($bpcf_window, $removeClass('hidden'));
        DfImageEditorF.activateBpcfPaperScope({ tab_el });
      },
    ),
    $delegate('click', `${stroke_color_control_property_class} .bpcf-window button.close`, (e) => {
      go(e.currentTarget, $closest('.bpcf-window'), $addClass('hidden'));
      DfImageEditorF.activateDefaultPaperScope({ tab_el });
    }),
    $delegate('click', `${stroke_thickness_control_property_class} button`, (e) => {
      changeRStrokeThickness({ tab_el, control_name: e.currentTarget.name });
    }),
    $delegate('change', `${stroke_thickness_control_property_class} input.indicator`, (e) => {
      const input_value = e.currentTarget.value;
      changeRStrokeThickness({ tab_el, new_target_thickness: input_value });
      DfImageEditorF.focusBackToFrame({ tab_el });
    }),
    $delegate('click', `.property-panel .controls[tool-name=${TOOL_NAME.rStroke}] button`, async (e) => {
      const ct = e.currentTarget;
      const control_name = ct.name;
      switch (control_name) {
        case 'apply': {
          try {
            $.don_loader_start();
            await doRasterStroke({ tab_el });
          } catch (e) {
            await DfImageEditorF.donAlertAsync({
              tab_el,
              msg: e.message,
            });
          } finally {
            $.don_loader_end();
          }
          break;
        }
        case 'cancel': {
          cleanRasterStroke({ tab_el });
          break;
        }
      }
    }),
  );
}

export async function activateRasterStrokeTool({ tab_el }) {
  try {
    $.don_loader_start();
    const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });

    const r_stroke_tool_name = TOOL_NAME.rStroke;

    const r_stroke_tool = DfImageEditorF.prepareTool({
      paper_scope,
      tool_name: r_stroke_tool_name,
      eventHandlers: {
        activate: () => {
          const r_stroke_layer = DfImageEditorF.getRasterStrokeLayer({ paper_scope });
          r_stroke_layer.visible = true;
        },
        keydown: (e) => {
          const keyboard_event = e.event;

          if (DfImageEditorLibF.view_control.isControlling() === false) {
            if (keyboard_event.code === 'BracketLeft') {
              changeRStrokeThickness({ tab_el, control_name: 'minus' });
            }
            if (keyboard_event.code === 'BracketRight') {
              changeRStrokeThickness({ tab_el, control_name: 'plus' });
            }
          }
        },
      },
    });
    r_stroke_tool.activate();

    await doRasterStroke({ tab_el });
  } catch (e) {
    await DfImageEditorF.donAlertAsync({
      tab_el,
      msg: e.message,
    });
  } finally {
    $.don_loader_end();
  }
}

export function isExistRasterStroke({ tab_el }) {
  return getRStrokeRaster({ tab_el });
}

export function cleanRasterStroke({ tab_el }) {
  const r_stroke_raster = getRStrokeRaster({ tab_el });
  r_stroke_raster && r_stroke_raster.remove();

  const r_stroke_painter = getRStrokePainter({ tab_el });
  r_stroke_painter.clear();

  const fit_bounds = getRStrokeFitBounds({ tab_el });
  fit_bounds && fit_bounds.remove();
  DfImageEditorF.setDesignCmSize({
    tab_el,
    with_dom_update: true,
  });
}

export async function doRasterStroke({ tab_el }) {
  refreshTargetStrokeRaster({ tab_el });
  const margin_image = await getPrintableRasterImageWithMargin({ tab_el });

  const r_stroke_painter = getRStrokePainter({ tab_el });

  r_stroke_painter.setImage(margin_image);

  const color = DfImageEditorLibF.getState.property.rStroke.color().value;
  const thickness = DfImageEditorLibF.getState.property.rStroke.thickness().value;
  const alpha_threshold = DfImageEditorLibF.getState.property.rStroke.alphaThreshold().value;
  r_stroke_painter.drawStroke({ color, thickness, alpha_threshold });
  refreshRStrokeFitBounds({ tab_el });
}

function upsertStrokeRaster({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  const r_stroke_layer = DfImageEditorF.getRasterStrokeLayer({ paper_scope });
  const r_stroke_raster_size = DfImageEditorF.getPrintableRasterSizeWithMargin({ tab_el });

  const r_stroke_raster = getRStrokeRaster({ tab_el });

  r_stroke_raster && r_stroke_raster.remove();

  const raster = DfImageEditorF.insertRaster({
    image_src: DfImageEditorF.setEmptyImageWithSize({ size: r_stroke_raster_size }),
    paper_scope,
    layer: r_stroke_layer,
  });
  raster.smoothing = 'off';
  raster.name = ITEM_NAME.rStrokeRaster;
  raster.scale(printable_raster.scaling.x);

  return raster;
}

function initializeRStrokePainter({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const r_stroke_layer = DfImageEditorF.getRasterStrokeLayer({ paper_scope });
  r_stroke_layer.visible = false;

  const rStrokePainter = new RasterStroke();

  DfImageEditorF.addDataToItem({
    item: r_stroke_layer,
    data: { [DATA_SET_KEY.r_stroke_painter]: rStrokePainter },
  });
}

function getRStrokePainter({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const r_stroke_layer = DfImageEditorF.getRasterStrokeLayer({ paper_scope });

  const strokeRasterPainter = DfImageEditorF.getDataFromItem({
    item: r_stroke_layer,
    key: DATA_SET_KEY.r_stroke_painter,
  });
  if (strokeRasterPainter == null) {
    throw new Error(`Not exist stroke raster instance`);
  }

  return strokeRasterPainter;
}

function refreshTargetStrokeRaster({ tab_el }) {
  const r_stroke_raster = upsertStrokeRaster({ tab_el });
  const r_stroke_painter = getRStrokePainter({ tab_el });
  r_stroke_painter.setTargetRaster(r_stroke_raster);
}

function refreshRStrokeFitBounds({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const r_stroke_layer = DfImageEditorF.getRasterStrokeLayer({ paper_scope });

  const exist_fit_bounds = getRStrokeFitBounds({ tab_el });
  exist_fit_bounds && exist_fit_bounds.remove();

  const fit_bounds = createRStrokeFitBounds({ tab_el });
  r_stroke_layer.addChild(fit_bounds);

  DfImageEditorF.setDesignCmSize({
    tab_el,
    with_dom_update: true,
  });
}

export function getRStrokeFitBounds({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const r_stroke_layer = DfImageEditorF.getRasterStrokeLayer({ paper_scope });
  return DfImageEditorF.getItemInLayer({
    layer: r_stroke_layer,
    condition: { name: ITEM_NAME.rStrokeBounds },
  });
}

function createRStrokeFitBounds({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  const printable_raster_bounds = printable_raster.bounds.clone();
  const stroke_thickness =
    DfImageEditorLibF.getState.property.rStroke.thickness().value * printable_raster.scaling.x;

  printable_raster_bounds.left -= stroke_thickness;
  printable_raster_bounds.top -= stroke_thickness;
  printable_raster_bounds.width += stroke_thickness * 2;
  printable_raster_bounds.height += stroke_thickness * 2;

  const fit_bounds = new paper_scope.Shape.Rectangle(printable_raster_bounds);
  fit_bounds.name = ITEM_NAME.rStrokeBounds;
  return fit_bounds;
}

export async function setRedrawRStroke({ tab_el }) {
  const margin_image = await getPrintableRasterImageWithMargin({ tab_el });
  const r_stroke_painter = getRStrokePainter({ tab_el });

  r_stroke_painter.setImage(margin_image);
}

export function getRStrokeRaster({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });

  const r_stroke_layer = DfImageEditorF.getRasterStrokeLayer({ paper_scope });
  return DfImageEditorF.getItemInLayer({
    layer: r_stroke_layer,
    condition: { name: ITEM_NAME.rStrokeRaster },
  });
}

function changeRStrokeThickness({ tab_el, new_target_thickness, control_name }) {
  const thickness_property = DfImageEditorLibF.getState.property.rStroke.thickness();
  const { value, min, max, step } = thickness_property;

  if (new_target_thickness == null) {
    switch (control_name) {
      case 'plus': {
        new_target_thickness = Math.min(value + step, max);
        break;
      }
      case 'minus': {
        new_target_thickness = Math.max(value - step, min);
        break;
      }
      default:
        throw new Error(`Unhandled stroke control button ${control_name}`);
    }
  }

  new_target_thickness = DfImageEditorF.prepareNumber({
    num: new_target_thickness,
    min,
    max,
    decimal: 0,
  });

  updateRStrokeThickness({ tab_el, thickness: new_target_thickness });
}

export function updateRStrokeColor({ tab_el, color }) {
  applyRStrokeStyle({ tab_el, color });
}

export function updateRStrokeThickness({ tab_el, thickness }) {
  applyRStrokeStyle({ tab_el, thickness });
  refreshRStrokeFitBounds({ tab_el });
}

export function updateRStrokeAlphaThreshold({ tab_el, alpha_threshold }) {
  applyRStrokeStyle({ tab_el, alpha_threshold });
}

/* @param color 헥사 값 */
export function applyRStrokeStyle({ tab_el, color, thickness, alpha_threshold }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const rStroke_layer = DfImageEditorF.getRasterStrokeLayer({ paper_scope });

  if (rStroke_layer.visible) {
    const r_stroke_painter = getRStrokePainter({ tab_el });
    r_stroke_painter.drawStroke({
      color,
      thickness: Number(thickness),
      alpha_threshold: Number(alpha_threshold) / 100,
    });
  }

  color && DfImageEditorLibF.setState.property.rStroke.color({ value: color, with_dom_update: true });
  thickness &&
    DfImageEditorLibF.setState.property.rStroke.thickness({
      value: Number(thickness),
      with_dom_update: true,
    });
  alpha_threshold &&
    DfImageEditorLibF.setState.property.rStroke.alphaThreshold({ value: Number(alpha_threshold) / 100 });
}

function initiateAlphaThresholdSlider({ tab_el }) {
  const $alpha_slider = $qs(
    `div.property[property-name=${R_STROKE_PROPERTY_NAME.alpha_threshold}] div.slider`,
  );
  if ($alpha_slider == null) {
    throw new Error(`Not exist alpha slider element`);
  }

  const { min, max, start, step } = $alpha_slider.dataset;

  const slider_obj = noUiSlider.create($alpha_slider, {
    start,
    step: Number(step),
    range: { min: Number(min), max: Number(max) },
    connect: 'lower',
    keyboardSupport: true,
    keyboardDefaultStep: 1,
  });

  const $indicator = go($alpha_slider.parentElement, $find('.indicator'));

  slider_obj.on('update', (values, handle) => {
    const new_value = values[handle];
    $indicator.value = DfImageEditorF.prepareNumber({ num: new_value, min, max });

    updateRStrokeAlphaThreshold({ tab_el, alpha_threshold: new_value });
  });

  $indicator.addEventListener('change', (e) => {
    const input_value = e.currentTarget.value;
    const new_target_value = prepareNumber({ num: input_value, min, max, step });
    input_value !== new_target_value && (e.currentTarget.value = new_target_value);
    slider_obj.set(new_target_value);
  });
}
