import { entries, html, strMap, values } from 'fxjs/es';
import { OMPCoreCellTmplS } from '../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { upsertQueryParams } from '../../../Core/Util/S/Function/util.js';
import { ORDER_STATUS } from '../../Order/S/Constant/order.js';
import { OMPMyPageOrderS } from '../../Order/S/Function/module/OMPMyPageOrderS.js';
import { OMPMyPageConstantS } from '../Constant/module/OMPMyPageConstantS.js';

// TODO: wish count 추가 필요 @jhj
export const myPageLandingTmplPc = ({
  user,
  cart_count,
  review_count,
  wish_count,
  order_status_data,
  current_projections,
  current_order_total_page,
  current_order_page,
  latest_products,
}) => {
  const hello = TT('my_page::landing::landing_01', {
    name: user.nick_name || user.name || '',
  });
  const my_info = TT('my_page::landing::landing_02');
  const order_title = TT('my_page::order::order_16');
  const order_more = TT('common::show_all');
  const empty_notice = TT('my_page::order::order_22');
  const new_product_title = TT('my_page::landing::landing_11');

  const my_page_menu = OMPMyPageConstantS.MyPageMenu();
  const order_status = OMPMyPageConstantS.OrderStatus();

  const columns = OMPMyPageConstantS.OrderTableColumn();
  const process_projections = OMPMyPageOrderS.makeTableProjections(current_projections);

  const data_pair = OMPMyPageConstantS.getMyPageMenuDataPair();
  const data_pair_data = {
    user,
    cart_count,
    review_count,
    wish_count,
  };

  return html`
    <div class="omp-my-page-landing">
      <div class="omp-my-page-landing__dashboard">
        <div class="omp-my-page-landing__dashboard-owner">
          <a href="/${TT.lang}/${my_page_menu.info.url}" class="omp-my-page-landing__grey">${my_info}</a>
          <span class="omp-my-page-landing__dashboard-owner__title">${hello}</span>
        </div>
        <div class="omp-my-page-landing__dashboard-data">
          ${strMap((value) => {
            return html` <a href="/${TT.lang}/${value.url}" class="omp-my-page-landing__dashboard-data-pair">
              <span class="omp-my-page-landing__grey">${value.title}</span>
              <span class="omp-my-page-landing__title">${value.data(data_pair_data)}</span>
            </a>`;
          }, values(data_pair))}
        </div>
      </div>
      <div class="omp-my-page-landing__latest-order">
        <div class="omp-my-page-landing__latest-order__header">
          <span class="omp-my-page-landing__latest-order__header-title">${order_title}</span>
          <a
            href="${upsertQueryParams(`/${TT.lang}/${my_page_menu.order.url}`, {
              status: ORDER_STATUS.ALL,
            })}"
            class="omp-my-page-landing__latest-order__header-more"
            >${order_more}</a
          >
        </div>

        <div class="omp-my-page-landing__latest-order__status">
          ${strMap(([key, status]) => {
            const order_status_data_key = order_status_data?.[key] || 0;
            const order_status_data_additional_key = order_status_data?.[status?.additional_key] || 0;

            const count = order_status_data_key + order_status_data_additional_key;
            const href = upsertQueryParams(`/${TT.lang}/${my_page_menu.order.url}`, { status: key });
            return html`
              <a
                href=${href}
                class="omp-my-page-landing__latest-order__status-wrapper"
                data-active="${!!count}"
              >
                <span class="omp-my-page-landing__latest-order__status-count">${count}</span
                ><span class="omp-my-page-landing__latest-order__status-title">${status.title}</span>
                <span class="omp-my-page-landing__latest-order__status-desc">${status.desc}</span>
              </a>
            `;
          }, entries(order_status))}
        </div>

        <div class="omp-order__table">
          ${OMPCoreCellTmplS.paginatedTable({
            columns,
            data: process_projections,
            empty_notice: html` <div class="td-wrapper">${empty_notice}</div> `,
            merge_column_key: [columns[0].key],
            total_page: Number(current_order_total_page),
            current_page: Number(current_order_page),
          })}
        </div>
      </div>
      <div class="omp-my-page-landing__new-product">
        <div class="omp-my-page-landing__new-product__header">
          <span class="omp-my-page-landing__new-product__header-title">${new_product_title}</span>
        </div>
        <div class="omp-product-list__list">${strMap(OMPCoreCellTmplS.product, latest_products)}</div>
      </div>
    </div>
  `;
};
