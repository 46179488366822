import '../../../../Event/Detail/F/route.js';
import { go, noop, identity, tap, pipe } from 'fxjs/es';
const st = $.scroll_top(window);
export const defnDfEventDetailRoute = () =>
  $.frame(
    {
      frame_name: 'mp.event.detail',
    },
    {
      page_name: 'mp.event.detail',
      tabs: [
        {
          tab_name: 'mp.event.detail',
          infinite: noop,
          rendered: () => $.scroll_top2(window, st),
          appended: pipe(
            tap(function (tab) {
              if (!$1('html.event.update')) return;
              const editor_content = new tui.Editor({
                el: $1('#content_editor_md'),
                initialEditType: 'markdown',
                previewStyle: 'vertical',
                height: '500px',
              });
              editor_content.setValue(window.box.sel('event->content'));
              $.on($.find1(tab, 'form'), 'submit', function (e) {
                e.preventDefault();
                $.val($.find1(tab, 'input[name="content_html"]'), editor_content.getHtml());
                $.val($.find1(tab, 'input[name="content"]'), editor_content.getValue());
                e.currentTarget.submit();
              });
            }),
            $.on('change', 'input[type="file"]', function (e) {
              return go(
                $.upload(e.currentTarget, {
                  url: '/@fileUpload/file',
                }),
                function (file) {
                  const img_upload = $.closest(e.currentTarget, '.img_upload');
                  $.val($.find(img_upload, 'input[type="hidden"]'), file.url);
                  $.attr($.find(img_upload, 'img.preview'), 'src', file.url);
                },
              );
            }),
            $.on2('click', 'button.is_public', async function (e) {
              const will_public = $.attr(e.currentTarget, 'is_public') ? '비공개' : '공개';
              try {
                await go(
                  $.confirm(will_public + ' 하시겠습니까?'),
                  _p.if2(identity)(
                    () =>
                      $.post('/@api/event/update/public', {
                        id: window.box.sel('event->id'),
                      }),
                    () => $.alert(will_public + ' 되었습니다.'),
                    () => location.reload(),
                  ),
                );
              } catch (e) {
                $.alert('다시 시도해 주세요.');
              }
            }),
            $.on2('click', 'button#remove', () =>
              go(
                $.confirm('삭제 하시겠습니까?'),
                _p.if2(identity)(
                  () => $.delete('/@api/event', { id: box.sel('event->id') }),
                  () => (location.href = `/event/list`),
                ),
              ),
            ),
            $.on('click', 'button.view_change', function () {
              $.toggle_class($1('#event_detail'), 'mobile');
              const url = $.has_class($1('#event_detail'), 'mobile') ? '_mobile' : '';
              $.attr($1('.content_wrap .img img'), 'src', window.box.sel(`event->url${url}`));
            }),
          ),
        },
      ],
    },
  );
