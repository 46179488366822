import { each, go } from 'fxjs/es';
import { $closest, $delegate, $find, $findAll, $setText } from 'fxdom/es';
import { SVGEditorAttrS } from '../../../Attr/S/Function/module/SVGEditorAttrS.js';
import { SVGEditorStrokeDashF } from '../../../StrokeDash/F/Function/module/SVGEditorStrokeDashF.js';
function isInt(n) {
  return n != '' && !isNaN(n) && Math.round(n) == n;
}
function makeNum(v) {
  return isInt(v) ? parseInt(v) : parseFloat(parseFloat(v).toFixed(1));
}
export const event = ({ el, __mp_sticker_editor, ___selected_el }) => {
  go(
    el,
    $findAll('.range_slider_wrapper'),
    each((range_slider_wrapper_el) => {
      const range_input_el = $find('input[type="range"]')(range_slider_wrapper_el);
      const key = range_input_el.dataset.key;
      const unit = range_input_el.dataset.unit;
      const number_wrapper_el = $find('.number_wrapper')(range_slider_wrapper_el);
      const number_box_el = $find('.number_box')(number_wrapper_el);
      const step = makeNum(range_input_el.step);
      const has_stroke_el = go(range_slider_wrapper_el, $closest('[data-has_stroke]'));
      range_slider_wrapper_el.__range_slider = jQuery(range_input_el)
        .rangeslider('destroy')
        .rangeslider({
          polyfill: false,
          onSlide: function () {
            $setText(range_input_el.value)(number_box_el);
            const value = makeNum(range_input_el.value);
            const v = value * unit;
            SVGEditorAttrS.set(
              { [key]: SVGEditorStrokeDashF.viewToSvgUnit(v) },
              ___selected_el,
              __mp_sticker_editor,
            );
            if (has_stroke_el) has_stroke_el.dataset.has_stroke = String(v > 0);
          },
        });
      go(
        number_wrapper_el,
        $delegate('click', '.up', (e) => {
          const value = makeNum(range_input_el.value);
          range_input_el.value = value + step;
          range_slider_wrapper_el.__range_slider.rangeslider('update', true);
        }),
        $delegate('click', '.down', (e) => {
          const value = makeNum(range_input_el.value);
          range_input_el.value = value - step;
          range_slider_wrapper_el.__range_slider.rangeslider('update', true);
        }),
      );
    }),
  );
};
