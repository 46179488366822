import { deepFlat, some } from 'fxjs/es';

export const setRequestBgRemoval = (cv_obj, bool) => {
  cv_obj._data.request_bg_removal = bool;
  if (cv_obj._data?.cv_image_attrs?._data) {
    cv_obj._data.cv_image_attrs._data.request_bg_removal = cv_obj._data.request_bg_removal;
  }
};

function _objects(d) {
  return deepFlat(d.objects ? d.objects.map((d) => _objects(d)) : [d]);
}

export function getRequestBgRemoval(d) {
  return d._data.request_bg_removal || d._data.cv_image_attrs?._data?.request_bg_removal;
}

export function hasRequestBgRemoval(d) {
  return some((d) => getRequestBgRemoval(d), d._data.cv_type == 'cv_group' ? _objects(d) : [d]);
}
