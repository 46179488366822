import { SVGEditorUploadImageF } from '../../../../../../SVGEditor/UploadImage/F/Function/module/SVGEditorUploadImageF.js';
import { VectorEditorStickerSingleCreatorPCF } from '../Function/module/VectorEditorStickerSingleCreatorPCF.js';
import { VectorEditorFreeShapePCF } from '../../../../../FreeShape/PC/F/Function/module/VectorEditorFreeShapePCF.js';

export const handleLeftMenuUploadImageIconClick =
  ({ tab_el }) =>
  () =>
    SVGEditorUploadImageF.uploadImage({
      postProcess: (el) => {
        if (!el) {
          return;
        }
        tab_el.__mp_single_sticker_editor?.addEl?.(el);
        tab_el?.__mp_single_sticker_editor?.scaleWithinArtBoard({ el, scale: 0.4 });
        const els = [el];
        tab_el?.__mp_single_sticker_editor?.alignToHorizontalCenter?.(els);
        tab_el?.__mp_single_sticker_editor?.alignToVerticalCenter?.(els);
        tab_el?.__mp_single_sticker_editor?.selectEls(els);
      },
    });

export const handleLeftMenuMyImageIconClick =
  ({ tab_el }) =>
  () =>
    SVGEditorUploadImageF.openPcMyLibrary({
      postProcess: (el) => {
        if (!el) {
          return;
        }
        tab_el.__mp_single_sticker_editor?.addEl?.(el);
        tab_el?.__mp_single_sticker_editor?.scaleWithinArtBoard({ el, scale: 0.4 });
        const els = [el];
        tab_el?.__mp_single_sticker_editor?.alignToHorizontalCenter?.(els);
        tab_el?.__mp_single_sticker_editor?.alignToVerticalCenter?.(els);
        tab_el?.__mp_single_sticker_editor?.selectEls(els);
      },
    });

export const handleLeftMenuShapeIconClick =
  ({ tab_el }) =>
  () => {
    return VectorEditorFreeShapePCF.useFreeShape({
      postProcess: (el) => {
        if (tab_el.__mp_single_sticker_editor == null) {
          return;
        }
        const editor = tab_el.__mp_single_sticker_editor;
        editor.addEl(el);
        editor.scaleWithinArtBoard({ el, scale: 0.4 });
        const els = [el];
        editor.alignToHorizontalCenter(els);
        editor.alignToVerticalCenter(els);
        editor.setStrokeWidth({ el, stroke_width: 0 });
        editor.setStrokeDasharray({ el, stroke_dasharray: [0, 0] });
        editor.selectEls(els);
      },
    });
  };

export const handleLeftMenuPathIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    VectorEditorStickerSingleCreatorPCF.makeNewPath(tab_el.__mp_single_sticker_editor);
  };

export const handleLeftMenuTextIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_single_sticker_editor == null) {
      return;
    }
    VectorEditorStickerSingleCreatorPCF.makeNewText(tab_el.__mp_single_sticker_editor);
  };
