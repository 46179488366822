import { $append, $findAll, $qs, $qsa, $setHTML, $setScrollTop, $setText } from 'fxdom/es';
import { each, go, find, groupBy, sel, map, noop, omit, pluck, sortBy } from 'fxjs/es';
import querystring from 'querystring';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPProductListTmplS } from '../../S/Tmpl/module/OMPProductListTmplS.js';
import { axiosGetBps, axiosGetFilters } from './fn.js';

const queue = [];

export const finishActionBpList = async (query) => {
  const cate_list_id = query.cate_list_id;
  const cate_item_id = query.cate_item_id;

  const { cate_list_name, cate_item_name, description, og_img, og_title } = go(void 0, () => {
    const cate_list = find((_cate_list) => {
      return _cate_list.id == cate_list_id;
    }, box.sel('cate_lists'));
    const cate_item = find((ci) => {
      return ci.id == cate_item_id;
    }, sel('_.cate_items', cate_list));
    return {
      cate_list_name: cate_list.name,
      cate_item_name: cate_item?.name,
    };
  });

  $setText(`${TT('omp_layout::title')} ${cate_item_name || cate_list_name}`, $qs('title'));

  MuiF.extendHistoryState(
    null,
    null,
    `/${TT.lang}/product/list/${cate_list_id}?${querystring.stringify(omit('cate_list_id', query))}`,
    true,
  );
  $setScrollTop(0, window);
  OMPCoreUtilF.imageLazyLoad();

  if (OMPCoreUtilF.isMobile()) {
    $qs('.mp-header-ham__item[data-active="true"]').dataset.active = 'false';
    $qs(`.mp-header-ham__item[data-id="${query.cate_list_id}"]`).dataset.active = 'true';
  }
};
const makeQuery = (cate) => {
  const { cate_list_id, cate_item_id } = $qs('.omp-product-list__checked-cate-list').dataset;
  const checked_cate_list_id = cate ? cate.cate_list_id : cate_list_id;
  const checked_cate_item_id = cate ? cate.cate_item_id : cate_item_id;
  const sort = $qs('.omp-product-list__checked-sort-name').dataset.id;
  return go(
    $qsa('.omp-product-filter-item__input-checkbox:checked'),
    map((input) => {
      return {
        name: input.name,
        id: input.value,
      };
    }),
    groupBy(({ name }) => {
      return name;
    }),
    Object.entries,
    map(([name, filters]) => {
      return [name, pluck('id', filters)];
    }),
    Object.fromEntries,
    (filters) => {
      return {
        cate_list_id: checked_cate_list_id,
        cate_item_id: checked_cate_item_id,
        sort,
        ...filters,
      };
    },
  );
};

export const reloadByFilter = async () => {
  const list_body$ = $qs('.opm-product-list-bps');
  const check_filter_items$ = $qs('.opm-product-list-checked-filter-items');
  const check_filter_length = $findAll('button', check_filter_items$).length;
  if ((check_filter_items$.dataset.list_show == 'true') != check_filter_length > 0) {
    $setHTML('', list_body$);
    check_filter_items$.dataset.list_show = check_filter_length > 0;
    list_body$.dataset.filter_show = check_filter_length > 0;
  }
  const query = makeQuery();
  const promise = axiosGetBps(query);
  queue.push(promise);
  const bps = await promise;
  if (queue[queue.length - 1] == promise) {
    queue.splice(0);
    $setHTML(OMPProductListTmplS.pcListBody(bps), list_body$);
    finishActionBpList(query);
  }
};

export const changeSort = (currentTarget, func) => {
  const checked_name$ = $qs('.omp-product-list__checked-sort-name');
  const sort = currentTarget.dataset.id;
  if (checked_name$.dataset.id != sort) {
    checked_name$.dataset.id = sort;
    $setText(currentTarget.dataset.name, checked_name$);
    const list$ = $qs('.omp-product-list__list');
    go(
      $qsa('.omp-cell__product[data-id], .omp-cell__product-mo[data-id]'),
      sortBy(({ dataset }) => {
        if (sort == 'pick') {
          return parseInt(dataset.no);
        } else if (sort == 'low_price') {
          return parseFloat(dataset.price);
        } else if (sort == 'high_price') {
          return -parseFloat(dataset.price);
        }
      }),
      each((bp$) => {
        $append(bp$, list$);
      }),
      noop,
      func,
    );
  }
};

export const reloadBySort = (currentTarget) => {
  return changeSort(currentTarget, () => {
    finishActionBpList(makeQuery());
  });
};

export const reloadByCate = async ({ cate_list_id, cate_item_id }) => {
  /* .omp-product-list__checked-cate-list 를 바꾸지 않고 pcList 를 새로 그리기 때문에 일반적으로 다르다. */
  const query = makeQuery({ cate_list_id, cate_item_id });
  const promise = Promise.all([axiosGetBps(query), axiosGetFilters(query)]);
  queue.push(promise);
  const [bps, bp_filters] = await promise;
  if (queue[queue.length - 1] == promise) {
    queue.splice(0);
    const checked_cate_list = box.sel('cate_lists').find(({ id }) => {
      return id == query.cate_list_id;
    });
    const checked_cate_item = checked_cate_list._.cate_items.find(({ id }) => {
      return id == query.cate_item_id;
    });
    $setHTML(
      OMPProductListTmplS.pcList({
        checked_cate_list,
        checked_cate_item,
        bps,
        query,
        checked_filter_items: [],
      }),
      $qs('.omp-layout-body__cont'),
    );
    $setHTML(OMPProductListTmplS.leftFilter(bp_filters), $qs('.omp-left__filter-list'));
    finishActionBpList(query);
  }
};
