import { each, flatMap, go, sel } from 'fxjs/es';
import axios from 'axios';
import { changeDpiBlob } from 'changedpi';
import { SVGEditorUtilF } from './module/SVGEditorUtilF.js';

async function verifyKeyringHook(svg_product_page) {
  if (svg_product_page.is_legacy_no_hole === true) {
    const {
      is_OK,
      data: image_url,
      reason,
    } = await SVGEditorUtilF.verifySmallHoleSvg({
      svg_product_page_id: svg_product_page.id,
      printable_url: svg_product_page.printable_url,
      cutting_line_url: svg_product_page.cutting_line_url,
    });
    if (!is_OK) {
      throw new Error(`${svg_product_page.id} --- ${reason}`);
    }
    if (is_OK && image_url) {
      svg_product_page.printable_url = image_url;
    }
  } else if (svg_product_page.is_legacy_no_hole === false) {
    if (!svg_product_page.verified_printable_url)
      throw new Error(`${svg_product_page.id} --- svg_product_page.verified_printable_url 가 없습니다.`);
    svg_product_page.printable_url = svg_product_page.verified_printable_url;
  }
  if (svg_product_page.is_legacy_small_path === true) {
    const {
      is_OK,
      data: svg_url,
      reason,
    } = await SVGEditorUtilF.sanitizeSmallerAreaKeyringHole({
      svg_url: svg_product_page.cutting_line_url,
      svg_product_page_id: svg_product_page.id,
    });
    if (!is_OK) {
      throw new Error(`${svg_product_page.id} --- ${reason}`);
    }
    if (is_OK && svg_url) {
      svg_product_page.cutting_line_url = svg_url;
    }
  } else if (svg_product_page.is_legacy_small_path === false) {
    if (!svg_product_page.verified_cutting_line_url)
      throw new Error(`${svg_product_page.id} --- svg_product_page.verified_cutting_line_url 가 없습니다.`);
    svg_product_page.cutting_line_url = svg_product_page.verified_cutting_line_url;
  }
}

export const vectorDownload = async ({ projection_id, up_id, quantity, product_id, task_id, up_c_s_id }) => {
  return go(
    $.get('/@api/bp_option/file_name_and_files', { product_id, projection_id, up_id, quantity }),
    each(verifyKeyringHook),
    flatMap(async ({ png_file_name, svg_file_name, printable_url, cutting_line_url }) => {
      const [printable, svg] = await Promise.all([
        go(axios.get(printable_url, { responseType: 'arraybuffer' }), sel('data')),
        go(axios.get(cutting_line_url, { responseType: 'arraybuffer' }), sel('data')),
      ]);
      return [
        {
          file_name: png_file_name,
          canvas_blob: await go(printable, (buffer) => {
            const blob = new Blob([new Uint8Array(buffer)], { type: 'image/png' });
            return changeDpiBlob(blob, 300);
          }),
          task_id,
          up_c_s_id,
        },
        {
          file_name: svg_file_name,
          canvas_blob: new Blob([new Uint8Array(svg)], { type: 'image/svg+xml' }),
          task_id,
          up_c_s_id,
        },
      ];
    }),
  );
};

export const getCuttingLineSvg = async ({ product_id }) => {
  const cutting_line_url = (await axios.get('/@api/svg_product/cutting_line_url', { params: { product_id } }))
    .data;
  if (cutting_line_url == null) return;

  return await getCuttingLineSvgFromUrl({ cutting_line_url });
};

export const getCuttingLineSvgFromUrl = async ({ cutting_line_url }) => {
  return (await axios.get(cutting_line_url)).data;
};

export const vectorDownload2 = async ({ projection_id, up_id, quantity, product_id }) => {
  return go(
    $.get('/@api/bp_option/file_name_and_files', { product_id, projection_id, up_id, quantity }),
    each(verifyKeyringHook),
    flatMap(async ({ id, png_file_name, svg_file_name, printable_url, cutting_line_url }) => {
      const [printable, svg] = await Promise.all([
        go(axios.get(printable_url, { responseType: 'arraybuffer' }), sel('data')),
        go(axios.get(cutting_line_url, { responseType: 'arraybuffer' }), sel('data')),
      ]);
      return [
        {
          file_name: png_file_name,
          blob: await go(printable, (buffer) => {
            const blob = new Blob([new Uint8Array(buffer)], { type: 'image/png' });
            return changeDpiBlob(blob, 300);
          }),
        },
        {
          file_name: svg_file_name,
          blob: new Blob([new Uint8Array(svg)], { type: 'image/svg+xml' }),
        },
      ];
    }),
  );
};
