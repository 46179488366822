import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { go } from 'fxjs/es';
import { CommonNS } from '@marpple/sticker-editor';

export const handleFooterCancelButtonClick =
  ({ tab_el }) =>
  () => {
    tab_el.__mp_grid_sticker_editor?.abortCopy?.();
    MuiF.closeFrame(true);
  };

export const handleFooterDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    const is_ok = await go(
      tab_el.__mp_grid_sticker_editor?.getOverrideCopyDestinationGridIds()?.length,
      (override_count) => {
        if (override_count == null) {
          return false;
        }

        if (override_count <= 0) {
          return true;
        }

        return $.confirm(T('modules::VectorEditor::Sticker::FreeGrid::기존 스티커를 덮어쓰기 하시겠습니까?'));
      },
    );
    if (!is_ok) {
      return;
    }

    $.don_loader_start();
    try {
      await tab_el.__mp_grid_sticker_editor?.endCopy(async ({ id, target_image_el }) => {
        const grid = tab_el.__mp_grids?.get?.(id);
        if (!grid) {
          const error = new Error(`No grid`);
          error.__mp_alert_message = `복사할 대상 그리드 정보를 찾을 수 없습니다.`;
          throw error;
        }

        const { x, y } = grid;
        const clone_target_image_el = await CommonNS.UtilNS.deepCloneNode(target_image_el);
        clone_target_image_el.setAttributeNS(null, 'x', `${x}`);
        clone_target_image_el.setAttributeNS(null, 'y', `${y}`);
        return clone_target_image_el;
      });
    } catch (error) {
      $.alert(error.__mp_alert_message ?? T('modules::VectorEditor::Sticker::message::복사에 실패했습니다.'));
      throw error;
    } finally {
      $.don_loader_end();
    }
    MuiF.closeFrame(true);
  };
