export const CREW_DOMAIN_NAME = {
  ALL: 'all',
  MARPPLE: 'marpple',
  NAVER_WEBTOON: 'webtoonfriends',
  NAVER_WEBTOON_FAKE: 'webtoons-ds7f91dss4fy3sjofnvo8',
};

export const CREW_SELLER_SHARE_TYPE = {
  CUSTOMER_PRICE: 'customer_price',
  TOTAL_PROFIT: 'total_profit',
};
