import { go, map, pluck } from 'fxjs/es';
import moment from 'moment';
import { _getBusinessDayAfter } from '../S/fs.js';
import axios from 'axios';

let holidays;
export const getBusinessDayAfter = async (days, originDate) => {
  const formatted_holidays = await getHolidays(originDate);
  return _getBusinessDayAfter(days, originDate, formatted_holidays);
};

export const getHolidays = async (originDate) => {
  const thisYear = (originDate || new Date()).getFullYear();

  holidays =
    holidays ||
    (
      await axios.get(`/@api/holidays`, {
        // await axios.get(`/${T.lang}/@api/holidays`, {
        years: [thisYear - 1, thisYear, thisYear + 1],
      })
    ).data.holidays;

  return go(
    holidays,
    pluck('date'),
    map((d) => moment(d).format('YYYY-MM-DD')),
  );
};
