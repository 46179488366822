import { html, join, map } from 'fxjs/es';
import { makeColorSelectorHtml } from './color_selector.js';
import { VectorEditorColorConstS } from '../../../../Color/S/Const/module/VectorEditorColorConstS.js';

export const makeRightPanelHtml = ({
  title = '',
  price = '',
  color_selector = makeColorSelectorHtml({
    colors: map(
      ({
        name,
        is_etc,
        color_code,
        thumbnail: { color: background_color, border, url: background_image_url },
      }) => {
        const color_name = name[T.lang] ?? '';
        return {
          color_code,
          color_name,
          background_color,
          background_image: background_image_url ? `url(${background_image_url})` : null,
          border: border ? `1px solid ${border}` : null,
          is_etc: !!is_etc,
        };
      },
    )(VectorEditorColorConstS.COLORS32),
  }),
  class_list = [],
  no_price = false,
} = {}) => html`
  <div class="${join(' ')(new Set([...class_list, 'setting_background_right_panel']))}">
    <div class="header">
      <span class="title">${title}</span>
      ${no_price ? `` : html`<span class="price mp_currency">${price}</span>`}
    </div>
    ${color_selector}
  </div>
`;
