import { DfImageEditorF } from './module/DfImageEditorF.js';
import { RATIO, SCALE_PROPERTY_NAME, TOOL_NAME } from '../../S/Constant/constants.js';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';
import { cmToPx, rasterPxToCm } from './helpers.raster.js';
import { $delegate } from 'fxdom/es';
import { each, go, maxBy } from 'fxjs/es';
import { tools } from '../Lib/state.js';

export function toolScale({ tab_el }) {
  go(
    tab_el,
    $delegate(
      'change',
      `.property-panel .properties[tool-name=${TOOL_NAME.scale}] .property[property-name=${
        tools.scale.properties[SCALE_PROPERTY_NAME.size].propertyName
      }] input`,
      (e) => {
        const MIN = 0.1;
        const MAX = 500;
        const ct = e.currentTarget;
        const name = ct.name;
        let set_value = ct.value;

        const bounds_item = getMaxBounds({ tab_el });

        const truncated_value = DfImageEditorF.truncateNumber({ num: Number(set_value), min: MIN, max: MAX });
        if (Number(set_value) !== truncated_value) {
          ct.value = truncated_value;
          set_value = truncated_value;
        }

        let scale;
        switch (name) {
          case 'width': {
            scale = cmToPx({ tab_el, cm: set_value }) / bounds_item.bounds.width;
            break;
          }
          case 'height': {
            scale = cmToPx({ tab_el, cm: set_value }) / bounds_item.bounds.height;
            break;
          }
        }
        scale && doScale({ tab_el, scale, bounds_item });

        DfImageEditorF.focusBackToFrame({ tab_el });
      },
    ),
  );
}

export function activateScaleTool({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const scale_tool_name = TOOL_NAME.scale;

  let drag_start_position = null;
  let is_scaling = false;
  let target_bounds_item = null;

  const scale_tool = DfImageEditorF.prepareTool({
    paper_scope,
    tool_name: scale_tool_name,
    eventHandlers: {
      activate: () => {
        const bounds_item = getMaxBounds({ tab_el });
        bounds_item.bounds.selected = true;
        target_bounds_item = bounds_item;
      },
      deactivate: () => {
        const bounds_item = getMaxBounds({ tab_el });
        bounds_item.bounds.selected = false;
        target_bounds_item = null;
      },
      mousemove: (e) => {
        if (DfImageEditorLibF.view_control.isControlling()) return;

        /* mouse cursor 의 bounds position 에 따라 cursor 모양 변경 */
        const position = findWhere({
          point: e.point,
          bounds: target_bounds_item.bounds,
          tolerance: paper_scope.view.viewSize.width / RATIO.view_to_scale_tolerance,
        });
        setCursorByPosition({ position });
      },
      mousedown: (e) => {
        if (e.event.button === 0) {
          if (DfImageEditorLibF.view_control.isControlling()) return;

          /* bounds 경계에서만 scalable 활성화 */
          const position = findWhere({
            point: e.point,
            bounds: target_bounds_item.bounds,
            tolerance: paper_scope.view.viewSize.width / RATIO.view_to_scale_tolerance,
          });
          if (position) {
            drag_start_position = position;
            is_scaling = true;
          }
        }
      },
      mousedrag: (e) => {
        if (is_scaling) {
          const bounds = target_bounds_item.bounds;
          const scale = getScale({ point: e.point, bounds, drag_start_position });
          scale && doScale({ tab_el, scale, bounds_item: target_bounds_item });
        }
      },
      mouseup: () => {
        drag_start_position = null;
        is_scaling = false;
      },
    },
  });
  scale_tool.activate();
}

function findWhere({ point, bounds, tolerance = 10 }) {
  const { left, right, top, bottom } = bounds;

  const { x, y } = point;
  let position;

  const is_left = Math.abs(left - x) < tolerance;
  const is_right = Math.abs(right - x) < tolerance;
  const is_top = Math.abs(top - y) < tolerance;
  const is_bottom = Math.abs(bottom - y) < tolerance;

  if (is_right) {
    if (is_top) {
      position = 'tr';
    } else if (is_bottom) {
      position = 'br';
    } else {
      if (top < y && y < bottom) {
        position = 'r';
      } else {
        position = null;
      }
    }
  } else if (is_left) {
    if (is_top) {
      position = 'tl';
    } else if (is_bottom) {
      position = 'bl';
    } else {
      if (top < y && y < bottom) {
        position = 'l';
      } else {
        position = null;
      }
    }
  } else if (is_top) {
    if (left < x && x < right) {
      position = 't';
    } else {
      position = null;
    }
  } else if (is_bottom) {
    if (left < x && x < right) {
      position = 'b';
    } else {
      position = null;
    }
  } else {
    position = null;
  }

  return position;
}

function setCursorByPosition({ position }) {
  switch (position) {
    case 'l':
    case 'r': {
      DfImageEditorF.setCanvasCursor('ew-resize');
      break;
    }
    case 't':
    case 'b': {
      DfImageEditorF.setCanvasCursor('ns-resize');
      break;
    }
    case 'tl':
    case 'br': {
      DfImageEditorF.setCanvasCursor('nwse-resize');
      break;
    }
    case 'tr':
    case 'bl': {
      DfImageEditorF.setCanvasCursor('nesw-resize');
      break;
    }
    default: {
      DfImageEditorF.setCanvasCursor('default');
    }
  }
}

function getScale({ drag_start_position, bounds, point }) {
  const { x, y } = point;
  const { center, width, height } = bounds;

  const getWidthScale = () => {
    const target_width = Math.abs((x - center.x) * 2);
    return target_width / width;
  };

  const getHeightScale = () => {
    const target_height = Math.abs((y - center.y) * 2);
    return target_height / height;
  };

  let scale;

  switch (drag_start_position) {
    case 'r':
    case 'l': {
      /* width 스케일링 */
      scale = getWidthScale();
      break;
    }
    case 't':
    case 'b': {
      /* height 스케일링 */
      scale = getHeightScale();
      break;
    }
    case 'tl':
    case 'tr':
    case 'bl':
    case 'br': {
      scale = Math.max(getWidthScale(), getHeightScale());
      break;
    }
    default: {
      scale = null;
    }
  }
  return scale;
}

export function getMaxBounds({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });

  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  const r_stroke_bounds = DfImageEditorF.getRStrokeFitBounds({ tab_el });

  return go(
    [printable_raster, r_stroke_bounds],
    maxBy((item) => {
      return item?.bounds?.width ?? 0;
    }),
  );
}

function doScale({ tab_el, scale, bounds_item }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });

  const default_layer = DfImageEditorF.getDefaultLayer({ paper_scope });

  default_layer.scale(scale);

  const has_stroke = DfImageEditorF.hasStrokeItem({ paper_scope });
  if (has_stroke) {
    DfImageEditorF.getVectorStrokeLayer({ paper_scope }).scale(scale);
    go(
      DfImageEditorF.getAllStrokeItems({ paper_scope }),
      each((stroke_item) => {
        const new_width = stroke_item.strokeWidth * scale;
        stroke_item.strokeWidth = new_width;

        DfImageEditorLibF.setState.property.vStroke.width(Math.round(new_width), true);
      }),
    );
  }

  const erase_layer = DfImageEditorF.getEraseLayer({ paper_scope });
  erase_layer.scale(scale);

  const r_stroke_layer = DfImageEditorF.getRasterStrokeLayer({ paper_scope });
  r_stroke_layer.scale(scale);

  const { cm_width, cm_height } = rasterPxToCm({
    tab_el,
    width: bounds_item.bounds.width,
    height: bounds_item.bounds.height,
  });

  DfImageEditorLibF.setState.property.designSize({
    width: cm_width,
    height: cm_height,
    with_dom_update: true,
  });
}
