import { legacyHtml } from '../../../Util/S/Function/util.js';

export const main_tmpl = () => legacyHtml`
  <div class="main">
    <div class="option">
      <button class="save">리셋</button>
    </div>
    <div class="wrapper">
      <div class="heads">
        <label for="" class="before_reset_head">현재</label>
        <label for="" class="after_reset_head">리셋 후</label>
      </div>
      <div class="body">
        <div class="canvas_wrapper before_reset"></div>
        <div class="canvas_wrapper after_reset"></div>
      </div>
    </div>
  </div>
`;
