import {
  $addClass,
  $after,
  $append,
  $el,
  $find,
  $findAll,
  $hasClass,
  $hide,
  $qs,
  $removeAttr,
  $removeClass,
  $scrollTop,
  $setCss,
  $setOuterHTML,
  $setScrollTop,
  $show,
} from 'fxdom/es';
import { each, go, identity } from 'fxjs/es';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPSearchAutoCompleteTmplS } from '../../S/Tmpl/module/OMPSearchAutoCompleteTmplS.js';

const Klass = Object.freeze({
  item: 'omp-search__auto-complete-item',
  items: 'omp-search__auto-complete-items',
  itemsHidden: 'omp-search__auto-complete-items--hidden',
  searchForm: 'omp-atom__form-search',
  autoCompleteInput: 'omp-atom__input-auto-complete',
  autoCompleteInputActive: 'omp-atom__input-auto-complete--active',
});

/**
 * 모든 `omp-search__auto-complete-item` 엘리먼트를 삭제한다.
 * @param {HTMLElement} $el
 */
export const flushAutoCompleteItems = ($el) => {
  if (!$el) {
    throw new Error('$el은 필수입니다.');
  }

  const $form = isSearchForm($el) ? $el : $find(`.${Klass.searchForm}`)($el);
  const $items = $form && $find(`.${Klass.items}`)($qs('body'));
  const $input = $form && $find(`.${Klass.autoCompleteInput}`)($form);

  if ($items) {
    $setOuterHTML(OMPSearchAutoCompleteTmplS.emptyAutoCompleteTmpl(), $items);
    if (OMPCoreUtilF.isMobile()) {
      setBodyFixed(false);
    }
  }
  if ($input) {
    $input.last_value = '';
    $removeClass(Klass.autoCompleteInputActive)($input);
  }
};

/**
 * `ul.omp-search__auto-complete-items` 엘리먼트에 `omp-search__auto-complete-items--hidden` 클래스를 추가한다.
 * @param {HTMLElement} $el
 */
export const hideAutoCompleteItems = ($el) => {
  if (!$el) {
    throw new Error('$el은 필수입니다.');
  }
  const $form = isSearchForm($el) ? $el : $find(`.${Klass.searchForm}`)($el);
  const $items = $form && $find(`.${Klass.items}`)($qs('body'));
  const $input = $form && $find(`.${Klass.autoCompleteInput}`)($form);

  if ($items) {
    $addClass(Klass.itemsHidden)($items);
    if (OMPCoreUtilF.isMobile()) {
      setBodyFixed(false);
    }
    go($items, $findAll(`.${Klass.item}`), each($removeAttr('selected')));
  }
  if ($input) {
    $removeClass(Klass.autoCompleteInputActive)($input);
  }
};

/**
 * `ul.omp-search__auto-complete-items` 엘리먼트의 `omp-search__auto-complete-items--hidden` 클래스를 삭제한다.
 * @param {HTMLElement} $el
 */
export const showAutoCompleteItems = ($el) => {
  if (!$el) {
    throw new Error('$el은 필수입니다.');
  }
  const $form = isSearchForm($el) ? $el : $find(`.${Klass.searchForm}`)($el);
  const $input = $form && $find(`.${Klass.autoCompleteInput}`)($form);
  const $items = $form && $find(`.${Klass.items}`)($qs('body'));
  const $item = $items && $find(`.${Klass.item}`)($items);

  if (!$items) {
    return;
  }

  if ($item) {
    // `li.omp-search__auto-complete-item`이 있으면 자동완성 목록을 보여준다.
    $removeClass(Klass.itemsHidden)($items);
    $input && $addClass(Klass.autoCompleteInputActive)($input);
  } else {
    // `li.omp-search__auto-complete-item`이 존재하지 않으면 자동완성 목록을 숨긴다.
    $addClass(Klass.itemsHidden)($items);
  }
};

/**
 * `items`로 자동완성 목록을 갱신한다.
 * @param {AutoCompleteItem[]} auto_complete_items
 * @param {!HTMLFormElement} $form
 */
export const updateAutoCompleteItems = (auto_complete_items, $form) => {
  if (!$form) {
    throw new Error('$form은 필수입니다.');
  }
  if (!isSearchForm($form)) {
    throw new Error('올바르지 않은 $form 입니다.');
  }

  const $items = $find(`.${Klass.items}`)($qs('body'));
  const $input = $find(`.${Klass.autoCompleteInput}`)($form);

  if (auto_complete_items?.length) {
    const tmpl = OMPSearchAutoCompleteTmplS.autoCompleteTmpl(auto_complete_items);
    if (OMPCoreUtilF.isMobile()) {
      setBodyFixed(true);
    }
    if ($items) {
      $setOuterHTML(tmpl)($items);
    } else {
      if (OMPCoreUtilF.isMobile()) {
        // $after($el(tmpl))($input);
        $append($el(tmpl), $qs('.don_frame[frame_index="0"] > .don_wrapper'));
      } else {
        $after($el(tmpl))($input);
      }
    }
    $addClass(Klass.autoCompleteInputActive)($input);
  } else {
    flushAutoCompleteItems($form);
  }
};

/**
 * body 엘리먼트의 스크롤을 막거나 해제한다.
 * @param {boolean} active_fix
 */

const setBodyFixed = (active_fix) => {
  if (active_fix) {
    document.body.fixed_top = $scrollTop(window);
    go(document.body, $setCss({ height: window.visualViewport.height }), $addClass('omp-search__body-fixed'));
    $hide($qs('.omp-layout-body'));
    window.addEventListener('scroll', preventScroll, false);
    window.addEventListener('touchmove', handleEvent, false);
  } else {
    go(
      document.body,
      OMPCoreUtilF.isAndroid() ? identity : $setCss({ height: 'auto' }),
      $removeClass('omp-search__body-fixed'),
    );
    $setScrollTop(document.body.fixed_top, window);
    document.body.fixed_top = null;
    $show($qs('.omp-layout-body'));
    window.removeEventListener('scroll', preventScroll, false);
    window.removeEventListener('touchmove', handleEvent, false);
  }
};

/**
 * @param {HTMLElement} $el
 * @return {boolean}
 */
const isSearchForm = ($el) => {
  return $el instanceof HTMLFormElement && $hasClass(Klass.searchForm, $el);
};

const preventScroll = function (e) {
  e.preventDefault();
  window.scrollTo(0, 0);
};

const handleEvent = (e) => {
  e.preventDefault();
};
