import { go, html, sortByDesc, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

const LANGS = ['kr', 'en', 'jp'];
export const sideHeaderPc = ({ wish_count = 0, cart_count = 0 } = {}) => {
  const language = TT.lang;
  return html`
    <div class="omp-cell__side-header">
      <div class="omp-cell__side-header-lang-wrapper" data-list-show="false">
        <div class="omp-cell__side-header-lang-head">
          <span class="omp-cell__side-header-lang"> ${language.toUpperCase()} </span>
          <i class="omp-cell__side-header-lang-icon"></i>
        </div>
        <ul class="omp-cell__side-header-lang-list">
          ${go(
            LANGS,
            sortByDesc((lang) => {
              return lang == TT.lang;
            }),
            strMap((lang) => {
              const is_on = lang == TT.lang;
              return html` <li class="omp-cell__side-header-lang-li" data-is_on="${is_on}">
                <a class="omp-change-lang" ${is_on ? '' : ` href="/${lang}"`} data-lang="${lang}"
                  >${lang.toUpperCase()}</a
                >
              </li>`;
            }),
          )}
        </ul>
      </div>
      <div class="omp-cell__side-header-button-wrapper">
        <a href="/${language}/wish/list" class="omp-cell__side-header-wish">
          ${OMPCoreAtomTmplS.heartButton({ badge: wish_count })}
        </a>
        <a href="/${language}/cart" class="omp-cell__side-header-cart">
          ${OMPCoreAtomTmplS.cartButton({ badge: cart_count })}
        </a>
        <a href="/${language}/my-page" class="omp-cell__side-header-my-page">
          ${OMPCoreAtomTmplS.avatarButton()}
        </a>
      </div>
    </div>
  `;
};
