import axios from 'axios';
import { $find } from 'fxdom/es';
import { go, sel } from 'fxjs/es';
import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { SVGEditorProductDetailF } from '../../../ProductDetail/F/Function/module/SVGEditorProductDetailF.js';
import { BpOptionTmplS } from '../../../../BpOption/S/Tmpl/module/BpOptionTmplS.js';

const getTempSvgProduct = async ({ user_id, base_product_id }) => {
  if (!(user_id && base_product_id)) return;
  return go(
    axios.get(`/${T.lang}/@api/svg_editor/temp_svg_products/get`, {
      params: { user_id, base_product_id, collabo_type },
    }),
    sel('data'),
  );
};

const removeTempSvgProduct = async ({ user_id, base_product_id }) => {
  if (!(user_id && base_product_id)) return;
  await axios.post(`/${T.lang}/@api/svg_editor/temp_svg_products/remove`, {
    user_id,
    base_product_id,
    collabo_type,
  });
};

export const forceRemoveTempSvgProduct = async ({ user_id, base_product_id }) => {
  const temp_svg_product = await getTempSvgProduct({ user_id, base_product_id });

  if (temp_svg_product) await removeTempSvgProduct({ user_id, base_product_id });
};

export const saveTempSvgProduct = async ({
  bp_option_ids,
  original_svg_file_url,
  user_id,
  base_product_id,
}) => {
  try {
    if (!(user_id && base_product_id)) return;
    await axios.post(`/${T.lang}/@api/svg_editor/temp_svg_products/upsert`, {
      bp_option_ids,
      original_svg_file_url,
      user_id,
      base_product_id,
      collabo_type,
    });
  } catch (e) {
    ConfigSentryF.capture(e);
  }
};

export const applyTempSvgProduct = async ({ user_id, tab_el, product }) => {
  try {
    if (!(user_id && product.base_product_id)) return;
    const temp_svg_product = await getTempSvgProduct({ user_id, base_product_id: product.base_product_id });
    if (temp_svg_product) {
      $.don_loader_start();
      if (await $.confirm(T('maker_error_alert::임시 저장된 디자인을 불러오겠습니까?'))) {
        const bp_option_groups_el = $find('.bp_option_groups')(tab_el);
        bp_option_groups_el.outerHTML = BpOptionTmplS.bpAllOptions({
          product,
          selected_option_group: { bp_option_ids: temp_svg_product.bp_option_ids },
        });
        await SVGEditorProductDetailF.enterSvgApplication({
          tab_el,
          svg_url: temp_svg_product.original_svg_file_url,
        });
      } else {
        await removeTempSvgProduct({ user_id, base_product_id: product.base_product_id });
      }
      $.don_loader_end();
    }
  } catch (e) {
    ConfigSentryF.capture(e);
  }
};
