!(function () {
  G.mp.frame = G.mp.frame || {};
  G.mp.frame.v_height = 30;
  G.mp.frame.t_blue_tab_buttons = _p.t$(
    '\
    button[type=button].don_back_page\
      span 이전\
    .title {{$.title}}\
    .tab_buttons.blue_tab_buttons\
      {{_.sum($.tabs, ',
    _p.t$(
      '\
        .tab_button[selected={{!!$.selected}} tab_name={{$.tab_name}}]\
          button {{$.title}}\
      ',
    ),
    ')}}\
    button[type=button].don_hide_frame',
  );
})();
