import { constant, go, identity, tap } from 'fxjs/es';
import './frame.js';
import './page.js';
import { frameOpenReviewDetail } from '../../Detail/F/fs.js';
import { makeReviewListCommentMoreBtn } from './fs.js';
import { makeReviewItemHtml2 } from '../S/tmpl.js';
import { REVIEW_LIST_LIMIT } from '../S/constant.js';
import { makeApiUrl } from '../../../Util/S/Function/util.js';

export const defnReviewListRoute = () =>
  go(
    $.frame(
      {
        frame_name: 'mp.review.list',
        appending: () =>
          $.don_select($1('#cate_list_selector'), void 0, window.box.sel('review_list->query->cate_list_id')),
      },
      {
        page_name: 'mp.review.list',
        tabs: [
          {
            tab_name: 'mp.review.list',
          },
        ],
      },
    ),
    constant(window.box.sel('review_list->query->r_id')),
    _p.if2(identity)((r_id) => {
      const get_url = makeApiUrl('/@api/review/detail/:r_id', {
        r_id,
      });
      return $.get(get_url);
    }, frameOpenReviewDetail),
  );

export const defnMyReviewRoute = () =>
  $.frame({
    frame_name: 'mp.my_review',
  });

export const defnMyReviewListRoute = () =>
  $.frame(
    {
      frame_name: 'mp.review.list',
      page_name: 'mp.review.list',
    },
    {
      page_name: 'mp.review.list',
      tabs: [
        {
          tab_name: 'mp.review.list',
          infinite: (don_tab) =>
            $.frame.infi($.find1(don_tab, '.reviews_wrap .review_list'), {
              limit: REVIEW_LIST_LIMIT,
              off: true,
              template: makeReviewItemHtml2,
              appended: makeReviewListCommentMoreBtn,
              data_func: (offset, limit) =>
                go(
                  $.get('/@api/my/review/list', {
                    offset,
                    limit,
                  }),
                  tap((reviews) => window.box.push.apply(null, ['review_list->reviews'].concat(reviews))),
                ),
            }),
        },
      ],
    },
  );
