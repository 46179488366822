import Pickr from '@simonwep/pickr/dist/pickr.es5.min.js';
import { $el, $find } from 'fxdom/es';
import { equals2, html, isNil } from 'fxjs/es';
import { VectorEditorSettingBackgroundMobileF } from '../Function/module/VectorEditorSettingBackgroundMobileF.js';

export const handleColorSelectorNotEtcClick =
  ({ tab_el, f }) =>
  async ({ currentTarget: color_el }) => {
    const color_selector_el = $find('.color_selector')(tab_el);
    if (isNil(color_selector_el)) {
      return;
    }

    const color_code = color_el?.dataset?.color_code;
    if (!color_code) {
      return;
    }

    $.don_loader_start();
    try {
      await f?.(color_code);
    } finally {
      $.don_loader_end();
    }

    VectorEditorSettingBackgroundMobileF.setColor({ color_selector_el, color_code });
  };

export const handleColorSelectorEtcClick =
  ({ tab_el, f }) =>
  async () => {
    const color_selector_el = $find('.color_selector')(tab_el);
    if (isNil(color_selector_el)) {
      return;
    }

    const default_color = await (async () => {
      if (!equals2(tab_el.__mp_color)('none')) {
        return tab_el.__mp_color;
      }

      $.don_loader_start();
      try {
        await f('#ffffff');
        VectorEditorSettingBackgroundMobileF.setColor({ color_selector_el, color_code: '#ffffff' });
      } finally {
        $.don_loader_end();
      }
      return '#ffffff';
    })();

    const trigger_el = $el(html`<button type="button"></button>`);
    const picker = Pickr.create({
      el: trigger_el,
      container: 'body',
      theme: 'monolith',
      useAsButton: true,
      default: default_color,
      components: {
        preview: true,
        hue: true,
        interaction: {
          hex: true,
          input: true,
        },
      },
      i18n: { 'btn:clear': '초기화' },
    })
      .on('hide', () => {
        picker.destroyAndRemove();
      })
      .on('change', async (color) => {
        const color_code = color.toHEXA().toString().toLowerCase();
        $.don_loader_start();
        try {
          await f(color_code);
          VectorEditorSettingBackgroundMobileF.setColor({ color_selector_el, color_code });
        } finally {
          $.don_loader_end();
        }
      });
    await new Promise((resolve, reject) => {
      try {
        setTimeout(resolve, 30);
      } catch (error) {
        reject(error);
      }
    });
    picker.show();
  };
