import { go, map } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const makeBpOptionCombinationIds = (bp_option_groups) => {
  return go(
    bp_option_groups,
    map((bp_option_group) => bp_option_group._.bp_options),
    UtilS.makeCombinations,
  );
};
