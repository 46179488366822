import { go, tap } from 'fxjs/es';
let i = 0;
export const timeTest =
  (func, name = 'time_test-----') =>
  (...arg) => {
    const ii = ++i;
    console.time(`${name}-${ii}`);
    return go(
      func(...arg),
      tap(() => {
        console.timeEnd(`${name}-${ii}`);
      }),
    );
  };
