$.frame.defn_frame({
  frame_name: 'df.user.detail',
  page_name: 'df.user.detail',
});

$.frame.defn_frame({
  frame_name: 'df.user.join',
  page_name: 'df.user.join',
});

$.frame.defn_frame({
  frame_name: 'df.user.point',
  page_name: 'df.user.point',
});

$.frame.defn_frame({
  frame_name: 'df.user.review',
  page_name: 'df.user.review',
});
