import { $append, $appendTo, $closest, $delegate, $find, $findAll, $qs, $qsa, $setCss, $val } from 'fxdom/es';
import { each, go, map, pipe } from 'fxjs/es';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { LIST_FRAME, LIST_PAGE, LIST_TAB } from '../../S/constant.js';
import { makeFcanvasBasic, unsetFcanvass } from '../../../../Maker/F/mp_maker.js';
import { makeTrimCanvas } from '../../../../Maker/F/canvas_trim.js';
import { makeCanvasByImageWithSize } from '../../../../Canvas/S/util.js';

$.frame.defn_frame({
  frame_name: LIST_FRAME,
  page_name: LIST_PAGE,
});

$.frame.defn_frame({
  frame_name: 'url_mask_url_modal',
  page_name: 'url_mask_url_modal',
  always_remove: true,
  is_modal: true,
});

$.frame.defn_page({
  page_name: 'url_mask_url_modal',
  hide_frame_button_type: 'X',
  hide_frame_button_position: 'RIGHT',
  tabs: [
    {
      tab_name: 'url_mask_url_modal',
      template: () => legacyHtml`
        <div class="url_canvas">
          <div class="title">면 이미지</div>
        </div>
        <div class="mask_url_canvas">
          <div class="title">마스크 이미지</div>
        </div>
        <div class="shading_url_canvas">
          <div class="title">명암 이미지</div>
        </div>
      `,
    },
  ],
});

function redirect() {
  go($qs('#search_base_products'), $val, (search) => {
    window.location.href = window.location.pathname + '?name=' + search;
  });
}

$.frame.defn_page({
  page_name: LIST_PAGE,
  tabs: [
    {
      tab_name: LIST_TAB,
      showed: render_faces,
      appended: pipe(
        $delegate('keyup', '#search_base_products', function (e) {
          if (e.keyCode === 13) {
            redirect();
          }
        }),
        $delegate('click', '#search_btn', function (e) {
          redirect();
        }),
        $delegate('click', '.base_product_color_face', async function (e) {
          const base_product_color_face_el = e.currentTarget;
          const f = pipe(
            (img) => makeCanvasByImageWithSize(img, { width: img.width, height: img.height }),
            makeTrimCanvas,
          );
          const url_canvas = await go(
            base_product_color_face_el,
            $findAll('.url[is_exist="true"] img'),
            map(f),
          );
          const mask_url_canvas = await go(
            base_product_color_face_el,
            $findAll('.mask_url[is_exist="true"] img'),
            map(f),
          );
          const shading_url_canvas = await go(
            base_product_color_face_el,
            $findAll('.shading_url[is_exist="true"] img'),
            map(f),
          );
          $.frame.open(
            { frame_name: 'url_mask_url_modal' },
            {
              page_name: 'url_mask_url_modal',
              showed: function (el) {
                const url_el = $find('.url_canvas', el);
                const mask_url_el = $find('.mask_url_canvas', el);
                const shading_url_el = $find('.shading_url_canvas', el);
                each($appendTo(url_el), url_canvas);
                each($appendTo(mask_url_el), mask_url_canvas);
                each($appendTo(shading_url_el), shading_url_canvas);
              },
            },
          );
        }),
      ),
    },
  ],
});

function makeProductColorAttr(bp, bpc) {
  return {
    base_product_id: bp.base_product_id,
    base_product_size_id: bp.base_product_size_id,
    base_product_color_id: bpc.id,
  };
}

function render_faces() {
  box().maker = box().maker || {};
  $.don_loader_start();
  go(
    box.sel('base_products'),
    each(function (bp) {
      return go(
        bp._.base_product_colors,
        each(async (bpc) => {
          const product_color = makeProductColorAttr(bp, bpc);
          product_color._ = {
            base_product_color: bpc,
          };
          box.set('maker->product_color', product_color);

          const canvass = $qsa(`[_sel="./_->base_product_colors->(#${bpc.id})"] canvas`);
          await makeFcanvasBasic(canvass);
          go(
            canvass,
            each((canvas) => {
              const canvas_el = $closest('.canvas', canvas);
              $append(makeTrimCanvas(canvas, 400), canvas_el);
              $setCss({ display: 'block' }, canvas_el);
            }),
          );
          unsetFcanvass();
        }),
      );
    }),
    function () {
      $.don_loader_end();
    },
  );
}
