import flatpickr from 'flatpickr/dist/flatpickr.js';
import { Korean } from 'flatpickr/dist/l10n/ko.js';
import base_product_editor from './df/js/base_product_editor.js';
import base_product_list from './df/js/base_product_list.js';
import bks_list from './df/js/bks_list.js';
import bp_stock_list from './df/js/bp_stock_list.js';
import dev_image from './df/js/dev_image.js';
import df_order from './df/js/df.order.js';
import df_order_detail from './df/js/df.order_detail.js';
import folder_detail from './df/js/folder.detail.js';
import folder_list from './df/js/folder.list.js';
import folder2_detail from './df/js/folder2.detail.js';
import folder2_list from './df/js/folder2.list.js';
import font_manager from './df/js/font_manager.js';
import help_group from './df/js/help_group.js';
import iamport_list from './df/js/iamport_list.js';
import smsCreatorProductGroup from '../../modules/Df/Sms/Creator/F/root_frame.js';
import kakao_surem from './df/js/kakao_surem.js';
import kakao_surem_logs from './df/js/kakao_surem_logs.js';
import projection_detail from './df/js/projection.detail.js';
import receipt_document from './df/js/receipt_document.js';
import simple_group from './df/js/simple_group.js';
import simple_groups_data from './df/js/simple_groups_data.js';
import stock from './df/js/stock.js';
import task_detail from './df/js/task.detail.js';
import task_list from './df/js/task.list.js';
import task_task_set_list from './df/js/task.task_set.list.js';
/*
 * ./base.js 에서 자동으로 frame을 실행해주는 부분이 있습니다.
 * 사용되지 않더라도 ../../modules/Df/User/List/F/index.js 처럼 frame을 등록시키는 모듈을 가져와주세요
 * */
import '../../modules/Df/User/Login/F/route.js';
import '../../modules/Df/Review/F/route.js';
import '../../modules/Df/User/List/F/route.js';
import '../../modules/Df/PromotionCode/F/route.js';
import '../../modules/Df/AdvanceReservation/F/frame/list.js';

import '../../modules/Df/BaseProductColorFaceImage/F/root_frame/list.js';

import '../../modules/Df/CateList/F/cate_list_frame.js';

import DfLogF from '../../modules/Df/Log/F/index.js';

import { defnBannerItemRoute } from '../../modules/Df/Banner/Item/F/route.js';
import { defnCateListRoute, defnCateListInfoRoute } from '../../modules/Df/CateList/F/route.js';
import { defnCheckPointRoute } from '../../modules/Df/CheckPoint/F/route.js';
import { defnDfEventDetailRoute } from '../../modules/Df/Event/Detail/F/route.js';
import { defnDfEventListRoute } from '../../modules/Df/Event/List/F/route.js';
import { defnDfUserDetailRoute } from '../../modules/Df/User/Detail/F/route.js';
import { defnWaybillRoute } from '../../modules/Df/Waybill/F/Function/route.js';
import { defnDfProjectionRoute } from '../../modules/Df/Projection/List/F/route.js';
import { getHolidays } from '../../modules/Holiday/F/holiday.js';

Routes.df = {
  base_product_editor,
  base_product_list,
  bks_list,
  bp_cate_list: defnCateListRoute,
  bp_stock_list,
  cate_list_info: defnCateListInfoRoute,
  checkpoint: defnCheckPointRoute,
  dev_image,
  df_order,
  df_order_detail,
  event_detail_df: defnDfEventDetailRoute,
  event_list_df: defnDfEventListRoute,
  folder_detail,
  folder_list,
  folder2_detail,
  folder2_list,
  font_manager,
  help_group,
  iamport_list,
  smsCreatorProductGroup,
  kakao_surem,
  kakao_surem_logs,
  projection_detail,
  projection: defnDfProjectionRoute,
  receipt_document,
  simple_group,
  simple_groups_data,
  stock,
  task_detail,
  task_list,
  task_task_set_list,
  user: defnDfUserDetailRoute,
  waybill: defnWaybillRoute,
  banner_item: defnBannerItemRoute,
  logs: DfLogF.route.main,
};
$.on($1('body'), 'mouseenter', 'input.due_at:not(.flatpickr-input)', async function (e) {
  const needHoliday = e.currentTarget.getAttribute('data-holiday');
  const holidays = needHoliday ? await getHolidays() : [];

  flatpickr(
    e.currentTarget,
    $.hasClass(e.currentTarget, 'no_time')
      ? {
          locale: Korean,
          disableMobile: true,
          minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
          disable: holidays,
        }
      : {
          disableMobile: true,
          enableTime: true,
          locale: Korean,
          time_24hr: true,
          defaultHour: 0,
          dateFormat: 'Y.n.j H:i',
          disable: holidays,
        },
  );
});
