import { guard } from '../../S/guard.js';
import { DfImageEditorLibF } from './module/DfImageEditorLibF.js';
import {
  BACKGROUND_PROPERTY_NAME,
  BRUSH_PROPERTY_NAME,
  R_STROKE_PROPERTY_NAME,
  RATIO,
  SCALE_PROPERTY_NAME,
  STROKE_PROPERTY_NAME,
  SUB_TOOL_NAME,
  TOOL_NAME,
} from '../../S/Constant/constants.js';
import { DfImageEditorF } from '../Function/module/DfImageEditorF.js';
import { setCanvasCursor } from '../Function/helpers.dom.js';
import { tools } from './state.js';
import { $addClass, $find, $qs, $removeClass, $trigger } from 'fxdom/es';

export const setState = {
  toolSelection: ({ tool_name, with_dom_update = false }) => {
    const check = guard.checkToolName({ tool_name });
    if (check) throw new Error(check);

    DfImageEditorLibF.setState.subToolSelection({
      tool_name,
      with_dom_update,
    });

    DfImageEditorLibF.imageEditorStatus.selectedTool = tool_name;

    if (with_dom_update) {
      DfImageEditorLibF.updateDomState.toolSelection(tool_name);
    }
  },
  subToolSelection: ({ tool_name, sub_tool_name, with_dom_update = false }) => {
    const check = guard.checkToolName({ tool_name });
    if (check) throw new Error(check);

    if (sub_tool_name == null) {
      const sub_tool =
        DfImageEditorLibF.getState.subTool[tool_name] && DfImageEditorLibF.getState.subTool[tool_name]();
      if (sub_tool == null) {
        return;
      } else {
        sub_tool_name = sub_tool;
      }
    }

    DfImageEditorLibF.imageEditorStatus.tool[tool_name].subTool = sub_tool_name;

    switch (tool_name) {
      case TOOL_NAME.erase: {
        const brush_item = DfImageEditorLibF.getState.property.eraseBrush().item;

        if (sub_tool_name !== SUB_TOOL_NAME.erase.brush) {
          DfImageEditorF.deactivateBrush({ brush_item });
          setCanvasCursor('crosshair', true);
          DfImageEditorLibF.setState.property.brush.sliderControl({
            tool_name,
            brush_name: BRUSH_PROPERTY_NAME.size,
            is_activate: false,
          });
          DfImageEditorLibF.setState.property.brush.sliderControl({
            tool_name,
            brush_name: BRUSH_PROPERTY_NAME.hardness,
            is_activate: false,
          });
          DfImageEditorLibF.setState.property.brush.indicatorControl({
            tool_name,
            brush_name: BRUSH_PROPERTY_NAME.size,
            is_activate: false,
          });
          DfImageEditorLibF.setState.property.brush.indicatorControl({
            tool_name,
            brush_name: BRUSH_PROPERTY_NAME.hardness,
            is_activate: false,
          });
        } else {
          DfImageEditorF.activateBrush({ brush_item });
          DfImageEditorLibF.setState.property.brush.sliderControl({
            tool_name,
            brush_name: BRUSH_PROPERTY_NAME.size,
            is_activate: true,
          });
          DfImageEditorLibF.setState.property.brush.sliderControl({
            tool_name,
            brush_name: BRUSH_PROPERTY_NAME.hardness,
            is_activate: true,
          });
          DfImageEditorLibF.setState.property.brush.indicatorControl({
            tool_name,
            brush_name: BRUSH_PROPERTY_NAME.size,
            is_activate: true,
          });
          DfImageEditorLibF.setState.property.brush.indicatorControl({
            tool_name,
            brush_name: BRUSH_PROPERTY_NAME.hardness,
            is_activate: true,
          });
          setCanvasCursor('none', true);
        }
      }
    }

    with_dom_update && DfImageEditorLibF.updateDomState.subToolSelection({ tool_name, sub_tool_name });
  },
  property: {
    designSize: ({ width, height, with_dom_update = false }) => {
      const size_value =
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.scale].properties[SCALE_PROPERTY_NAME.size].value;
      size_value.width = width;
      size_value.height = height;
      with_dom_update && updateDomState.property.designSize({ width, height });
    },
    background: {
      color: ({ value }) => {
        let color_hex_code = null;
        const $artboard = $qs('div.artboard');
        if (value === 'transparent') {
          $artboard.setAttribute('color', 'transparent');
          $artboard.style.backgroundColor = null;
        } else {
          $artboard.removeAttribute('color');
          $artboard.style.backgroundColor = value;
          color_hex_code = value;
        }

        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.background].properties[
          BACKGROUND_PROPERTY_NAME.color
        ].value = color_hex_code;
      },
    },
    rStroke: {
      color: ({ value, with_dom_update = false }) => {
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.rStroke].properties[
          R_STROKE_PROPERTY_NAME.color
        ].value = value;
        with_dom_update && updateDomState.property.rStroke.color();
      },
      thickness: ({ value, with_dom_update = false }) => {
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.rStroke].properties[
          R_STROKE_PROPERTY_NAME.thickness
        ].value = value;
        with_dom_update && updateDomState.property.rStroke.thickness();
      },
      alphaThreshold: ({ value, with_dom_update = false }) => {
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.rStroke].properties[
          R_STROKE_PROPERTY_NAME.alpha_threshold
        ].value = value;
      },
    },
    vStroke: {
      color: (new_color, with_dom_update = false) => {
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.vStroke].properties[
          STROKE_PROPERTY_NAME.color
        ].value = new_color;
        with_dom_update && updateDomState.property.strokeColor();
      },
      width: (new_width, with_dom_update = false) => {
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.vStroke].properties[
          STROKE_PROPERTY_NAME.width
        ].value = Number(new_width);
        with_dom_update && updateDomState.property.strokeWidth();
      },
      offset: (new_offset, with_dom_update = false) => {
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.vStroke].properties[
          STROKE_PROPERTY_NAME.offset
        ].value = Number(new_offset);
        with_dom_update && updateDomState.property.strokeOffset();
      },
      boundary: ({ boundary_strokes }) => {
        if (boundary_strokes == null || Object.keys(boundary_strokes).length === 0) {
          throw new Error(`Not exist boundary_paths ${boundary_strokes}`);
        }
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.vStroke].boundary.strokes = boundary_strokes;
      },
      bounds: ({ bounds_id }) => {
        if (bounds_id != null && typeof bounds_id !== 'number') {
          throw new Error(`Wrong bounds_id ${bounds_id}`);
        }
        DfImageEditorLibF.imageEditorStatus.tool[TOOL_NAME.vStroke].boundary.bounds = bounds_id;
      },
    },
    brush: {
      tool: (tool_name, tool) => {
        DfImageEditorLibF.imageEditorStatus.tool[tool_name].properties.brush.tool = tool;
      },
      item: (tool_name, item) => {
        DfImageEditorLibF.imageEditorStatus.tool[tool_name].properties.brush.item = item;
      },
      slider: (tool_name, brush_name, slider_el) => {
        DfImageEditorLibF.imageEditorStatus.tool[tool_name].properties.brush[brush_name].slider = slider_el;
      },
      indicator: (tool_name, brush_name, indicator_el) => {
        DfImageEditorLibF.imageEditorStatus.tool[tool_name].properties.brush[brush_name].indicator =
          indicator_el;
      },
      sliderControl: ({ tool_name, brush_name, is_activate }) => {
        const slider_obj = DfImageEditorLibF.getState.brush.slider({ tool_name, brush_name });
        is_activate ? slider_obj.enable() : slider_obj.disable();
      },
      indicatorControl: ({ tool_name, brush_name, is_activate }) => {
        const $indicator = DfImageEditorLibF.getState.brush.indicator({ tool_name, brush_name });
        $indicator.disabled = !is_activate;
      },
      [BRUSH_PROPERTY_NAME.size]: (tool_name, new_size, with_dom_update = false) => {
        const {
          item: brush_item,
          tool: brush_tool,
          size: brush_size,
        } = DfImageEditorLibF.getBrushState(tool_name);
        const new_size_number = Number(new_size);

        if (brush_item == null) return;

        if (brush_size.value == null) {
          brush_size.value = new_size_number;
        } else {
          if (brush_size.value !== new_size_number) {
            brush_item.scale(new_size_number / brush_size.value);
            brush_size.value = new_size_number;
          }
        }

        brush_tool.fixedDistance = Math.round(brush_size.value / RATIO.brush_size_to_fixed_distance);
        if (with_dom_update) {
          updateDomState.property.brushSize({
            tool_name,
            value: new_size_number,
          });
        }
      },
      [BRUSH_PROPERTY_NAME.hardness]: (tool_name, new_value, with_dom_update = false) => {
        const { [BRUSH_PROPERTY_NAME.hardness]: brush_hardness } = DfImageEditorLibF.getBrushState(tool_name);
        const new_hardness_value = Number(new_value);

        brush_hardness.value = new_hardness_value;

        brush_hardness.gradientColor = DfImageEditorF.setBrushHardnessGradientColor({
          hardness: new_hardness_value,
        });

        if (with_dom_update) {
          updateDomState.property.brushHardness({
            tool_name,
            value: new_hardness_value,
          });
        }
      },
    },
  },
};

export const updateDomState = {
  toolSelection: (tool_name) => {
    const selected_tool_name = tool_name ?? DfImageEditorLibF.getState.selected_tool();
    const tool_panel_class_name = '.image-editor .tool-panel';
    const property_panel_class_name = '.image-editor .property-panel';

    const $tool_panel_selected = $qs(`${tool_panel_class_name} .selected`);
    const $tool_panel_tobe_selected = $qs(`${tool_panel_class_name} .${selected_tool_name}`);

    const $property_panel_selected = $qs(`${property_panel_class_name} section.selected`);
    const $property_panel_tobe_selected = $qs(`${property_panel_class_name} section.${selected_tool_name} `);

    if ($tool_panel_selected == null) throw new Error(`Not exist tool panel selected`);
    if ($tool_panel_tobe_selected == null) throw new Error(`Not exist tool panel to be selected`);

    if ($property_panel_selected == null) throw new Error(`Not exist property panel selected`);
    if ($property_panel_tobe_selected == null) throw new Error(`Not exist property panel to be selected`);

    $removeClass('selected')($tool_panel_selected);
    $addClass('selected')($tool_panel_tobe_selected);

    $removeClass('selected')($property_panel_selected);
    $addClass('selected')($property_panel_tobe_selected);
  },
  subToolSelection: ({ tool_name, sub_tool_name }) => {
    const $sub_tool = $qs(`.sub-tools[tool-name=${tool_name}`);
    const $selected_sub_tool = $find('button.selected', $sub_tool);
    const is_same_el = $selected_sub_tool.name === sub_tool_name;

    if (is_same_el) return;
    $removeClass('selected')($selected_sub_tool);
    $addClass('selected')($find(`button[name=${sub_tool_name}]`, $sub_tool));
  },
  property: {
    rStroke: {
      color: () => {
        const $stroke_color_indicator = $qs(
          `.property-panel .properties[tool-name=${TOOL_NAME.rStroke}] .property[property-name=${
            tools[TOOL_NAME.rStroke].properties[R_STROKE_PROPERTY_NAME.color].propertyName
          }] input[type='color']`,
        );

        if ($stroke_color_indicator == null) {
          throw new Error(`Not exist stroke color indicator input el`);
        }
        $stroke_color_indicator.value = DfImageEditorLibF.getState.property.rStroke.color().value;
      },
      thickness: () => {
        const $r_stroke_thickness_input = $qs(
          `.property-panel .properties[tool-name=${TOOL_NAME.rStroke}] .property[property-name=${
            tools[TOOL_NAME.rStroke].properties[R_STROKE_PROPERTY_NAME.thickness].propertyName
          }] input`,
        );
        if ($r_stroke_thickness_input == null) {
          throw new Error(`Not exist raster stroke thickness input el`);
        }
        $r_stroke_thickness_input.value = DfImageEditorLibF.getState.property.rStroke.thickness().value;
      },
    },
    strokeColor: () => {
      const $stroke_color_indicator = $qs(
        `.property-panel .properties[tool-name=${TOOL_NAME.vStroke}] .property[property-name=${
          tools[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.color].propertyName
        }] input[type='color']`,
      );
      if ($stroke_color_indicator == null) {
        throw new Error(`Not exist stroke color indicator input el`);
      }
      $stroke_color_indicator.value = DfImageEditorLibF.getState.property.strokeColor().value;
    },
    strokeWidth: () => {
      const $stroke_width_input = $qs(
        `.property-panel .properties[tool-name=${TOOL_NAME.vStroke}] .property[property-name=${
          tools[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.width].propertyName
        }] input`,
      );
      if ($stroke_width_input == null) {
        throw new Error(`Not exist stroke width indicator input el`);
      }
      $stroke_width_input.value = DfImageEditorLibF.getState.property.strokeWidth().value;
    },
    strokeOffset: () => {
      const $stroke_offset_input = $qs(
        `.property-panel .properties[tool-name=${TOOL_NAME.vStroke}] .property[property-name=${
          tools[TOOL_NAME.vStroke].properties[STROKE_PROPERTY_NAME.offset].propertyName
        }] input`,
      );
      if ($stroke_offset_input == null) {
        throw new Error(`Not exist stroke width indicator input el`);
      }
      $stroke_offset_input.value = DfImageEditorLibF.getState.property.strokeOffset().value;
    },
    brushSize: ({ tool_name, value }) => {
      const $brush_size_input = $qs(
        `.brush[tool-name=${tool_name}][brush-name=${BRUSH_PROPERTY_NAME.size}] input.indicator`,
      );
      if ($brush_size_input == null) {
        throw new Error(`Not exist brush size indicator el`);
      }
      $brush_size_input.value = Number(value);
      $trigger('change', $brush_size_input);
    },
    brushHardness: ({ tool_name, value }) => {
      const $brush_hardness_input = $qs(
        `.brush[tool-name=${tool_name}][brush-name=${BRUSH_PROPERTY_NAME.hardness}] input.indicator`,
      );
      if ($brush_hardness_input == null) {
        throw new Error(`Not exist brush hardness indicator el`);
      }
      $brush_hardness_input.value = Number(value);
      $trigger('change', $brush_hardness_input);
    },
    designSize: ({ width, height }) => {
      const $size_indicator = $qs('section.work-area .size-indicator');
      const $width_indicator = $find('span[name="width"]', $size_indicator);
      const $height_indicator = $find('span[name="height"]', $size_indicator);

      if ($width_indicator == null || $height_indicator == null) {
        throw new Error(`Not exist printable raster size indicator`);
      }
      const OneDecimalize = (val) => Number(val).toFixed(2);
      const width_text = `${OneDecimalize(width)}`;
      const height_text = `${OneDecimalize(height)}`;
      $width_indicator.textContent = width_text;
      $height_indicator.textContent = height_text;

      const { $width_input, $height_input } = DfImageEditorF.getPrintableRasterSizeControlInputEls();

      $width_input.value = width_text;
      $height_input.value = height_text;
    },
  },
};
