import axios from 'axios';
import { makeColorTabHtml } from '../S/tmpl.js';
import { debounce, delay, filter, go, map, object, pipe } from 'fxjs/es';
import { $addClass, $attr, $closest, $delegate, $find, $findAll, $qs, $removeClass, $setCss } from 'fxdom/es';
import { makeIsDoneFalse, renderDonTabCanvas } from './fns.js';

$.frame.defn_page({
  page_name: 'composite_templates_color_page',
  hide_frame_button_type: 'x',
  title: '절대 색상 선택 툴',
  tabs: [
    {
      tab_name: 'composite_templates_color_tab',
      template: makeColorTabHtml,
      appended: pipe(
        $delegate('change', 'input[name="is_abs_color_done"]', async function (e) {
          if (e.currentTarget.checked) {
            $.don_loader_start();
            const parentNode = e.currentTarget.closest('tr');
            const color_data = go(
              parentNode,
              $findAll('input'),
              map((el) => (el.type === 'checkbox' ? [el.name, el.checked] : [el.name, el.value])),
              object,
            );
            color_data.color_id = $attr('data-id', parentNode);
            await axios //Update DB for Color Data
              .post('/@api/base_product_color/update_color_data', color_data)
              .catch((err) => console.error(err));
            $.don_loader_end();
          }
        }),
        // $delegate('change', 'input[name="is_melange"]', async function(e) {
        //   makeIsDoneFalse(e);
        //   $closest('.color_info', e.currentTarget).setAttribute('data-is_melange', e.currentTarget.checked);
        // }),
        $delegate(
          'change',
          'input[name="abs_color_code"]',
          debounce(async function (e) {
            makeIsDoneFalse(e);
            $closest('.color_info', e.currentTarget).setAttribute(
              'data-abs_color_code',
              e.currentTarget.value,
            );
            if ($.hasClass($closest('tr', e.currentTarget), 'selected')) {
              await renderDonTabCanvas(e.delegateTarget);
            }
          }, 50),
        ),
        $delegate('click', '.color_pick', async function (e) {
          go(e.delegateTarget, $find('.selected'), $removeClass('selected'));
          go(e.currentTarget, $closest('tr'), $addClass('selected'));
          go(e.delegateTarget, delay(600), renderDonTabCanvas);
        }),
        function (don_tab_el) {
          go(don_tab_el, $find('.color_info'), $addClass('selected'));
          renderDonTabCanvas(don_tab_el);
          return don_tab_el;
        },
      ),
    },
  ],
});

$.frame.defn_frame({
  frame_name: 'composite_templates_color',
  page_name: 'composite_templates_color_page',
  is_modal: true,
  showed: () => {
    $setCss({ overflow: 'hidden' }, $qs('#body'));
  },
  closed: () => {
    $setCss({ overflow: 'visible' }, $qs('#body'));
  },
  // always_remove: true,
  // prev_frame_show: G.is_mobile_size()
});

export async function openCompositeTemplatesColorFrame(base_product_id) {
  $.frame.open(
    {
      frame_name: 'composite_templates_color',
      always_remove: true,
    },
    {
      page_name: 'composite_templates_color_page',
      tabs: [
        {
          data_func: async (tab_opt) => {
            const composite_template_list = await $.get('/@api/composite/select_full_composite_templates', {
              base_product_id,
            });
            tab_opt.assoc_composite_templates = go(
              composite_template_list,
              filter((ct) => ct.is_done),
            );
            const base_product_colors = await $.get('/@api/base_product_color/list', {
              base_product_id,
              is_public: true,
              is_hidden: false,
            });
            return [tab_opt.assoc_composite_templates, base_product_colors];
          },
        },
      ],
    },
  );
}
