export const PDF_BP_IDS = new Set([
  4577, 4578, 4579, 4580, 4602, 4797, 4798, 4799, 4800, 4801, 4802, 4803, 4804, 4837, 4838, 4839, 4840, 4841,
  4842, 4843, 4844, 4845, 4846, 4856, 4857, 4858, 4887, 4888, 4889, 4890, 4891, 4892, 4893, 4894, 4895, 4896,
  4897, 4898, 4911, 4912, 4913, 4914, 4915, 4916, 4917, 4918, 4919, 4920, 4921, 4922, 4942, 4943, 4944, 4945,
  4946, 4947, 4951, 4952, 4953, 4954, 4955, 4956, 4957, 4958, 4959, 4960, 4961, 4962, 5024, 5025, 5026, 5027,
  5028, 5029, 5030, 5031, 5032, 5033, 5034, 5035, 5036, 5037, 5055, 5057, 5058, 5059, 5060, 5061, 5062, 5063,
  5064, 5065, 5066, 5103, 5104, 5105, 5106, 5107, 5116, 5117, 5118, 5119, 5120, 5121, 5122, 5123, 5124, 5125,
  5126, 5127, 5128, 5129, 5130, 5131, 5224, 5225, 5226, 5227, 5228, 5229, 5230, 5231, 5232, 5233, 5234, 5235,
  5236, 5237, 5238, 5239, 5241, 5283, 5284, 5285, 5286, 5287, 5288, 5289, 5290, 5291, 5292, 5293, 5294, 5295,
  5296, 5297, 5298, 5301, 5302, 5303, 5304, 5305, 5306, 5307, 5308, 5309, 5310, 5311, 5312, 5313, 5314, 5315,
  5316, 5374, 5375, 5376, 5377, 5378, 5379, 5380, 5395, 5396, 5398, 5399, 5400, 5401, 5402, 5445, 5446, 5447,
  5448, 5449, 5450, 5451, 5452, 5453, 5454, 5455, 5456, 5457, 5458, 5459, 5460, 5757, 5200, 5203, 5205, 5206,
  5207, 5208, 6038, 6039, 6040, 6041,
]);
