import { $closest, $findAll, $find, $addClass, $removeClass, $attr, $data } from 'fxdom/es';
import { zipWithIndexL, go, map, every, some, filter, each, identity } from 'fxjs/es';
import { DfInhouseF } from '../../../../Inhouse/F/Function/module/DfInhouseF.js';
import { alertsLgl } from '../../../F/Function/alerts.js';
import { addProductsToWaybill, removeProductsFromWaybill } from './item.js';

export function removeClassAllRows({ el, className = 'active', filter_func = identity }) {
  go(
    el,
    $findAll('tbody > tr'),
    filter_func,
    each((tr) => $removeClass(className, tr)),
  );
}

export function unCheckedAllCheckboxes({ el, filter_func = identity }) {
  go(
    el,
    $findAll('input[type="checkbox"]'),
    filter_func,
    each((checkbox) => (checkbox.checked = false)),
  );

  removeClassAllRows({ el, filter_func });
}
export function handleTransferToolDisabled({ table_el, checked }) {
  const table_left = go(table_el, $closest('.table_left'));
  const table_right = go(table_el, $closest('.table_right'));
  const body = go(table_el, $closest('.body'));
  const transfer_tool = go(body, $find('.transfer_tool'));
  const button_left = go(transfer_tool, $find('.left'));
  const button_right = go(transfer_tool, $find('.right'));
  const rebound_waybill = go(body, $find('.table_right .tab_content.selected'));
  let packed_rebound_waybill = false;
  if (rebound_waybill) {
    packed_rebound_waybill = go(rebound_waybill, $find('.backdrop'));
  }

  go([button_left, button_right], each($addClass('disabled')));

  if (table_left) {
    button_right.classList.toggle('disabled', packed_rebound_waybill || !checked);
  } else if (table_right) {
    button_left.classList.toggle('disabled', !checked);
  }
}

export function handleHeaderCheckboxChanged({ target, originalEvent: e }) {
  const { checked } = target;
  const tab_el = $closest('.tab_wrapper', target);
  const table_el = $closest('.table', target);
  const checkboxes = $findAll('input[type="checkbox"]', table_el);

  if (checkboxes.length === 1) {
    return e.preventDefault();
  }
  go(
    checkboxes,
    map((checkbox) => (checkbox.checked = target.checked)),
  );

  const rows = $findAll('tr', table_el);
  go(
    rows,
    map((row) => row.classList.toggle('active', checked)),
  );

  unCheckedAllCheckboxes({
    el: tab_el,
    filter_func: filter((checkbox) => go(checkbox, $closest('.table')) !== table_el),
  });

  handleTransferToolDisabled({ table_el, checked });

  handleReturnRequestButtonDisabled({ tab_el, checked });
}

export function handleCheckboxChanged({ target }) {
  const tab_el = $closest('.tab_wrapper', target);
  const table_el = $closest('table', target);
  const table_header = $find('thead', table_el);
  const table_body = $find('tbody', table_el);
  const header_checkbox = $find('input[type="checkbox"]', table_header);

  const row = $closest('tr', target);
  row.classList.toggle('active', target.checked);

  const checkboxes = $findAll('input[type="checkbox"]', table_body);
  header_checkbox.checked = go(
    checkboxes,
    every((checkbox) => checkbox.checked),
  );

  const someCheckboxChecked = go(
    checkboxes,
    some((checkbox) => checkbox.checked),
  );

  unCheckedAllCheckboxes({
    el: tab_el,
    filter_func: filter((checkbox) => go(checkbox, $closest('.table')) !== table_el),
  });

  handleTransferToolDisabled({ table_el, checked: someCheckboxChecked });

  handleReturnRequestButtonDisabled({ tab_el, checked: someCheckboxChecked });
}

function handleReturnRequestButtonDisabled({ tab_el, checked }) {
  const return_button = go(tab_el, $find('.return_request_inbound'));
  if (return_button) {
    return_button.classList.toggle('disabled', !checked);
    return_button.classList.toggle('gray', !checked);
    return_button.classList.toggle('danger_alt', checked);
  }
}

export function handleMoveQuantityOnlyNumber({ key, originalEvent }) {
  if (key === '.' || key === '-') {
    originalEvent.preventDefault();
  }
}

export function handleMoveQuantityUnderMaxValue({ target }) {
  const max_val = parseInt(target.getAttribute('max'));
  const min_val = parseInt(target.getAttribute('min'));
  const current_val = parseInt(target.value);
  if (current_val > max_val) {
    target.value = max_val;
  } else if (current_val < min_val) {
    target.value = min_val;
  }
}

export function handleMoveQuantityButtonClick({ target }) {
  const input_number_wrapper = go(target, $closest('.input_number_wrapper'));
  const input_number = go(input_number_wrapper, $find('input[type="number"]'));
  const decrement = go(target, $closest('.input_number_decrement'));
  const increment = go(target, $closest('.input_number_increment'));

  const current_val = parseInt(input_number.value);
  const max_val = parseInt(input_number.getAttribute('max'));

  if (decrement) {
    if (current_val > 1) {
      input_number.value = parseInt(input_number.value) - 1;
    }
  } else if (increment) {
    if (current_val < max_val) {
      input_number.value = parseInt(input_number.value) + 1;
    }
  }
}

export async function handleMoveItemToWaybill({ target }) {
  try {
    DfInhouseF.lottie_loader.start('cardboard', null, null, null);

    const don_tab = go(target, $closest('.don_tab'));
    const don_wrapper = go(don_tab, $closest('.don_wrapper'));
    const tab_buttons = go(don_wrapper, $find('.header > .tab_buttons'));
    const selected_tab = go(tab_buttons, $find('.tab_button[selected="true"]'), $attr('tab_name'));

    const el_tab = go(target, $closest('.tab_wrapper'));
    const from_table_wrapper = go(el_tab, $find('.queue_table'));
    const from_table = go(from_table_wrapper, $find('.table'));
    const selected_waybill = go(el_tab, $find('.tab_content.selected'));

    const { current_status } = $data(selected_waybill);
    if (current_status !== 'PACKING') {
      // alert 추가 또는 이동 버튼 비활성화
      // LGL-BUWHAL
      return;
    }

    const to_table = go(selected_waybill, $find('.table'));

    await addProductsToWaybill({ from_table, to_table, rebound: selected_tab === 'rebound' });

    handleResizeTableHeader({ el: el_tab });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleMoveItemToQueue({ target }) {
  try {
    DfInhouseF.lottie_loader.start('cardboard', null, null, null);

    const don_tab = go(target, $closest('.don_tab'));
    const don_wrapper = go(don_tab, $closest('.don_wrapper'));
    const tab_buttons = go(don_wrapper, $find('.header > .tab_buttons'));
    const selected_tab = go(tab_buttons, $find('.tab_button[selected="true"]'), $attr('tab_name'));

    const el_tab = go(target, $closest('.tab_wrapper'));
    const to_table_wrapper = go(el_tab, $find('.queue_table'));
    const to_table = go(to_table_wrapper, $find('.table'));
    const selected_waybill = go(el_tab, $find('.tab_content.selected'));
    const from_table = go(selected_waybill, $find('.table'));

    await removeProductsFromWaybill({ from_table, to_table, rebound: selected_tab === 'rebound' });

    handleResizeTableHeader({ el: el_tab });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export function handleResizeTableHeader({ el }) {
  const tables = go(el, $findAll('table'));
  go(
    tables,
    each((table) => {
      const body_cells = go(table, $findAll('tbody tr:first-of-type > td'));

      if (body_cells.length > 1) {
        const col_width = go(
          body_cells,
          map((cell) => {
            const computedStyle = window.getComputedStyle(cell);
            return computedStyle.width;
          }),
        );
        const headers = go(table, $findAll('thead tr > th'));

        go(
          headers,
          zipWithIndexL,
          map(([idx, th]) => (th.style.width = col_width[idx])),
        );
      }
    }),
  );
}

export function handleCheckAllItemsInQueueTable({ el_tab }) {
  const queue_table = go(el_tab, $find('.queue_table'));
  const waybill_table = go(el_tab, $find('.tab_content.selected'));
  if (queue_table) {
    const transfer_tool = go(el_tab, $find('.transfer_tool'));
    if (transfer_tool) {
      const button_right = go(transfer_tool, $find('.right'));
      if (button_right) {
        const checkboxes = go(
          queue_table,
          $findAll('tbody tr input[type="checkbox"]'),
          each((checkbox) => (checkbox.checked = true)),
          each((checkbox) => go(checkbox, $closest('tr'), $addClass('active'))),
        );

        if (checkboxes.length > 0) {
          go(button_right, $removeClass('disabled'));
        }

        if (waybill_table) {
          const packed_waybill = go(waybill_table, $find('.backdrop'));
          if (packed_waybill) {
            go(button_right, $addClass('disabled'));
          }
        }
      }
    }
  }
}
