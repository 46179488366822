import { html } from 'fxjs/es';

export const kr20130507 = () => html`
  <div class="terms_body">
    <div class="title">
      <h2>이용약관</h2>
    </div>
    <div class="body">
      <div class="agree_header">
        <p>회원가입 전 반드시 이용약관을 읽어보시기 바랍니다.</p>
        <div class="table_of_contents">
          <ul>
            <li><a href="#service1">제1조(목적)</a></li>
            <li><a href="#service2">제2조(정의)</a></li>
            <li><a href="#service3">제3조(약관 등의 명시와 설명 및 개정)</a></li>
            <li><a href="#service4">제4조(서비스의 제공 및 변경)</a></li>
            <li><a href="#service5">제5조(서비스의 중단)</a></li>
            <li><a href="#service6">제6조(회원가입)</a></li>
            <li><a href="#service7">제7조(회원 탈퇴 및 자격 상실 등)</a></li>
          </ul>
          <ul>
            <li><a href="#service8">제8조(회원에 대한 통지)</a></li>
            <li><a href="#service9">제9조(상품구매)</a></li>
            <li><a href="#service10">제10조(지급방법)</a></li>
            <li><a href="#service11">제11조(상품 등의 공급)</a></li>
            <li><a href="#service12">제12조(반품/교환/환불에 관한 규정)</a></li>
            <li><a href="#service13">제13조(개인정보보호 및 이용)</a></li>
            <li><a href="#service14">제14조("회사"의 의무)</a></li>
          </ul>
          <ul>
            <li><a href="#service15">제15조(회원의 ID 및 비밀번호에 대한 의무)</a></li>
            <li><a href="#service16">제16조(이용자의 의무)</a></li>
            <li><a href="#service17">제17조(마플포인트)</a></li>
            <li><a href="#service18">제18조(상품쿠폰)</a></li>
            <li><a href="#service19">제19조(저작권의 귀속 및 게시물 이용 제한)</a></li>
            <li><a href="#service20">제20조(면책)</a></li>
            <li><a href="#service21">제21조(정보의 제공 및 광고의 게재)</a></li>
          </ul>
          <ul>
            <li><a href="#service22">제 22조 (재판권 및 준거법)</a></li>
          </ul>
        </div>
      </div>
      <div class="text_body">
        <h3 class="title">[제 1장 총 칙]</h3>
        <div class="paragraph">
          <h3 id="service1">제 1조 (목적)</h3>
          <p>
            본 약관은 (주)마플코퍼레이션(이하 “회사”라 한다.)에서 개발 및 제공하는 주문인쇄제작(PRINT ON
            DEMAND)을 통한 전자상거래 관련 서비스(이하 "서비스")를 이용함에 있어 회사와 이용자의 권리·의무 및
            책임사항을 규정함을 목적으로 합니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service2">제 2조 (정의)</h3>
          <p>
            "회사"란 ㈜마플코퍼레이션이 재화 또는 용역(이하 "재화 등"이라 함)을 이용자에게 제공하기 위하여
            컴퓨터 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며,
            아울러 사이버 몰을 운영하는 사업자의 의미로도 사용합니다.<br />
            "이용자"란 "회사"에 접속하여 이 약관에 따라 "회사"가 제공하는 서비스를 받는 회원 및 비회원을
            말합니다.<br />
            회원이라 함은 "회사"에 개인정보를 제공하여 회원 등록을 한 자로서, "회사"의 정보를 지속적으로 제공
            받으며, "회사"가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.<br />
            "비회원"이라 함은 회원에 가입하지 않고 "회사"가 제공하는 서비스를 이용하는 자를 말합니다.<br />
            "디자이너"라 함은 사이트 내에 제공되는 회원의 저작물과 무지의 상품을 조합하고 변형하여 사이트 내의
            '아트샵' 및 “무료이미지”에서 판매하고, 이에 따른 수익을 얻는 판매 회원을 말합니다.<br />
            이외에 이 약관에서 사용하는 용어의 정의는 관계 법령 및 서비스 별 안내에서 정하는 바에 의합니다.<br />
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service3">제 3조 (약관 등의 명시와 설명 및 개정)</h3>
          <p>
            "회사"는 이 약관의 내용과 상호, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를
            포함), 전화번호, 모사전송번호, 이메일주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자
            등을 이용자가 쉽게 알 수 있도록 "회사"의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은
            이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br />
            "회사"는 약관의규제에 관한 법률, 전자상거래등에서의 소비자보호에 관한 법률, 소비자기본법 등
            관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br />
            "회사"는 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 "회사"의 초기화면이나
            팝업화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게
            약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "회사"는
            개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.<br />
            "회사"가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그
            이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한
            이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제4항에 의한 개정약관의 공지기간 내에 "회사"에
            송신하여 "회사"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.<br />
            이 약관에서 정하지 아니한 내용과 이 약관의 해석에 관하여는 전자상거래등에서의 소비자보호에 관한
            법률, 약관의규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래등에서의 소비자보호지침 및
            관계법령 또는 상관례에 따릅니다.<br />
          </p>
        </div>
        <h3 class="title">[제 2장 회사의 서비스]</h3>
        <div class="paragraph">
          <h3 id="service4">제 4조 (서비스의 제공 및 변경)</h3>
          <p>
            "회사"는 다음과 같은 서비스를 제공합니다.<br />
            재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결<br />
            구매 계약이 체결된 재화 또는 용역의 제작 및 배송<br />
            기타 "회사"가 정하는 업무<br />
            "회사"는 상품 또는 용역이 품절되거나 기술적 사양의 변경 등으로 더 이상 제공할 수 없는 경우에는
            장차 체결되는 계약에 의해 제공할 상품, 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화
            또는 용역의 내용 및 제공일자를 명시하여 공지합니다.<br />
            "회사"가 제공하기로 이용자와 계약을 체결한 서비스나 상품의 내용을 상품 등의 품절 또는 기술적
            사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 방법으로 통지합니다.<br />
            전항의 경우 "회사"는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, "회사"가 고의 또는 과실이
            없음을 입증한 경우에는 아무런 책임을 부담하지 않습니다.<br />
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service5">제 5조 (서비스 중단)</h3>
          <p>
            "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는
            서비스의 제공을 일시적으로 중단할 수 있습니다.<br />
            제1항에 의한 서비스 중단의 경우에는 "회사"는 제8조에 정한 방법으로 이용자에게 통지합니다.<br />
            "회사"는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은
            손해에 대하여 "회사"의 고의성의 없는 경우에는 배상하지 아니합니다.
          </p>
        </div>
        <h3 class="title">[제 3장 회원가입 계약]</h3>
        <div class="paragraph">
          <h3 id="service6">제 6조 (회원가입)</h3>
          <p>
            이용자는 무료로 회원에 가입할 수 있으며, "회사"가 정한 가입 양식에 회원정보를 기입한 후 이 약관에
            동의한다는 의사표시를 함으로서 회원가입을 신청합니다.<br />
            "회사"는 제1항에 따라 회원가입을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로
            등록합니다.<br />
            가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조
            제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "회사"가 회원 재가입을 승낙한 경우는 예외로
            합니다.<br />
            등록 내용에 허위, 기재누락, 오기가 있는 경우<br />
            기타 회원으로 등록하는 것이 "회사"의 기술상 현저히 지장이 있다고 판단되는 경우<br />
            만 14세 미만의 아동으로서 부모 등 법정대리인의 동의를 얻지 아니한 경우<br />
            회원가입은 이용자가 "회사"에 가입 신청한 때에 완료됩니다.<br />
            "회원"은 "회사"에 등록한 회원정보에 변경이 있는 경우, 즉시 "회사"에서 정하는 방법에 따라 해당
            변경사항을 "회사"에게 통지하거나 수정하여야 합니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service7">제 7조 (회원 탈퇴 및 자격 상실등)</h3>
          <p>
            회원은 "회사"에 언제든지 탈퇴를 요청할 수 있으며 "회사"는 즉시 회원 탈퇴를 처리합니다.<br />
            회원이 다음 각호의 사유에 해당하는 경우, "회사"는 회원 자격을 제한 및 정지시킬 수 있습니다.<br />
            가입 신청 시에 허위 내용을 등록한 경우<br />
            "회사"를 이용하여 구입한 재화 등의 대금, 기타 "회사" 이용에 관련하여 회원이 부담하는 채무를 기일에
            지급하지 않는 경우<br />
            타인의 ID와 비밀번호 또는 그 개인정보를 도용한 경우<br />
            다른 사람의 "회사" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br />
            "회사"를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br />
            기타 서비스 운영을 고의로 방해하는 행위를 하는 경우<br />
            "회사"가 회원 자격을 제한, 정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가
            시정 되지 아니하는 경우 "회사"는 회원 자격을 상실시킬 수 있습니다.<br />
            "회사"가 회원 자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고,
            회원 등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service8">제 8조 (회원에 대한 통지)</h3>
          <p>
            회원에 대한 통지를 하는 경우 "회사"는 회원이 등록한 e-mail 주소 또는 SMS 등으로 할 수 있습니다.<br />
            "회사"는 불특정 다수 회원에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지에 갈음할 수
            있습니다.
          </p>
        </div>
        <h3 class="title">[제 4장 구매계약 및 대금 결제]</h3>
        <div class="paragraph">
          <h3 id="service9">제 9조 (상품구매)</h3>
          <p>
            이용자는 "회사"에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "회사"는 이용자가
            구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. 단, 회원인 경우 제2호 내지
            제4호의 적용을 제외할 수 있습니다.<br />
            재화 등의 검색 및 선택<br />
            성명, 주소, 전화번호, 이메일 주소(또는 이동전화번호) 등의 입력<br />
            약관내용, 청약 철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한 확인<br />
            재화 등의 구매신청 및 이에 관한 확인 또는 "회사"의 확인에 대한 동의<br />
            결제 방법의 선택
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service10">제 10조 (지급방법)</h3>
          <p>
            "회사"에서 구매한 상품 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수
            있습니다. 단, "회사"는 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여
            징수할 수 없습니다.<br />
            폰뱅킹, 인터넷뱅킹, 메일뱅킹 등의 각종 계좌이체<br />
            선불카드, 직불카드, 신용카드 등의 각종 카드 결제<br />
            온라인무통장입금<br />
            마일리지 등 "회사"가 지급한 포인트에 의한 결제<br />
            "회사"와 계약을 맺었거나 "회사"가 인정한 상품권에 의한 결제<br />
            기타 전자적 지급 방법에 의한 대금 지급 등<br />
            구매대금의 결제와 관련하여 이용자가 입력한 정보 및 그와 관련된 책임은 이용자에게 있으며, 재화 또는
            용역의 청약 후 합리적인 일정 기간 내에 결제가 이루어 지지 않는 경우 "회사"는 이에 해당주문을
            취소할 수 있습니다.<br />
            "회사"는 구매자의 결제수단에 대하여 정당한 사용권한 보유여부를 확인할 수 있으며 필요한 경우 해당
            거래진행의 정지 및 소명자료의 제출을 요청할 수 있습니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service11">제 11조 (상품 등의 공급)</h3>
          <p>
            "회사"는 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날로부터
            10영업일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 이때
            "회사"는 이용자가 재화 등의 공급 절차 및 진행 사항을 확인 할 수 있도록 적절한 조치를 합니다.<br />
            공휴일 및 기타 휴무일 또는 천재지변 등의 불가항력적 사유가 발생하는 경우 그 해당기한은
            배송소요기간에서 제외합니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service12">제 12조 (반품/교환/환불에 관한 규정)</h3>
          <p>
            "회사"는 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 적절한
            수단에 의해 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우 이용자의
            환급 요청이 있을 때 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.<br />
            회원은 판매자의 상품 발송 시로부터 배송완료일 후 5일 이내까지 관계법령에 의거하여 반품을 신청할 수
            있습니다.<br />
            반품에 관한 일반적인 사항은 전자상거래등에서의소비자보호에관한법률 등 관련법령이 판매자가 제시한
            조건보다 우선합니다.<br />
            반품에 소요되는 비용은 반품에 대한 귀책사유가 있는 자에게 일반적으로 귀속됩니다. (예를 들어,
            단순변심: 구매자부담, 상품하자: 판매자부담 등)<br />
            반품 신청 시 반품송장번호를 미기재하거나 반품사유에 관하여 판매자에게 정확히 통보(구두 또는
            서면으로)하지 않을 시 반품처리 및 환불이 지연될 수 있습니다.<br />
            반품에 따른 환불은 반품 상품이 판매자에게 도착되고 반품사유 및 반품배송비 부담자가 확인된 이후에
            현금결제의 경우에는 3영업일 이내에 현금잔고로 환불되고, 카드 결제의 경우 즉시 결제가
            취소됩니다.<br />
            반품배송비를 구매자가 부담하여야 하는 경우 반품배송비의 추가 결제가 이루어지지 않으면 환불이
            지연될 수 있습니다.<br />
            구매자는 다음 각 호의 경우에는 환불 또는 교환을 요청할 수 없습니다.<br />
            주문에 따라 개별적으로 생산되는 물품에 회복할 수 없는 피해가 예상되는 경우로서 사전에 해당 거래에
            대하여 별도로 그 사실을 고지하고 구매자의 동의를 받은 경우<br />
            구매자의 귀책사유로 말미암아 상품이 멸실ㆍ훼손된 경우<br />
            구매자의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우<br />
            시간의 경과에 의하여 재판매가 곤란할 정도로 상품의 가치가 현저히 감소한 경우<br />
            복제가 가능한 상품의 포장을 훼손한 경우
          </p>
        </div>
        <h3 class="title">[제 5장 계약당사자의 의무]</h3>
        <div class="paragraph">
          <h3 id="service13">제 13조 (개인정보보호 및 이용)</h3>
          <p>
            "회사"는 이용자의 정보수집시 서비스의 제공에 필요한 최소한의 정보를 수집합니다. 다음 사항을
            필수사항으로 하며 그 외 사항은 선택사항으로 합니다.<br />
            희망 ID (회원의 경우)<br />
            성명<br />
            비밀번호 (회원의 경우)<br />
            E-mail(전자우편) 주소<br />
            기타 "회사"가 필요하다고 인정하는 사항<br />
            "회사"는 이용자의 개인 식별이 가능한 개인 정보를 수집하는 때에는 반드시 당해 이용자의 동의를
            받습니다.<br />
            제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한
            모든 책임은 "회사"가 집니다. 다만, 다음의 경우는 예외로 합니다.<br />
            배송 업무상 배송 업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는
            경우<br />
            정보통신서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적, 기술적인 사유로
            통상의 동의를 받는 것이 현저히 곤란한 경우<br />
            재화 등의 거래에 따른 대금 정산을 위하여 필요한 경우<br />
            도용 방지를 위하여 본인 확인에 필요한 경우<br />
            법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우<br />
            신속한 이용문의 상담 및 이용자의 불만처리 업무를 대행하는 "회사"에 상담업무에 필요한 이용자의
            정보를 제공하는 경우<br />
            회원가입 시 동의하신 제휴사별 제휴 업무 진행을 위한 필요로 하는 본인확인을 위한 최소한의
            정보(성명, 연락처, 주문상품평 등)를 제휴사에게 제공하는 경우<br />
            "회사"가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의
            신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집 목적 및 이용 목적, 제3자에 대한 정보 제공
            관련 사항(제공 받은자, 제공 목적 및 제공할 정보의 내용) 등 정보통신망 이용촉진 등에 관한 법률
            제22조 등에서 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수
            있습니다.<br />
            이용자는 언제든지 "회사"가 가지고 있는 자신의 개인 정보에 대해 열람 및 오류 정정을 요구할 수
            있으며 "회사"는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을
            요구한 경우에는 "회사"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.<br />
            "회사"는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화 하며 신용카드, 은행계좌 등을
            포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을
            집니다.<br />
            "회사" 또는 그로부터 개인 정보를 제공받는 제3자는 개인정보의 수집 목적 또는 제공받은 목적을 달성한
            때에는 당해 개인정보를 지체 없이 파기합니다.<br />
            "회사"가 회원의 개인정보를 수집, 이용, 제공 등을 할 경우에는 정보통신망 이용촉진 및 정보보호등에
            관한 법률에 따라 회원의 동의를 받습니다.<br />
            회원은 원하는 경우 언제든 "회사"에 제공한 개인정보의 수집과 이용에 대한 동의를 철회할 수 있으며
            동의의 철회는 회원 탈퇴를 하는 것으로 이루어 집니다.<br />
            * 개인정보와 관련된 보다 구체적인 사항은 개인정보취급방침을 따릅니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service14">제 14조 ("회사"의 의무)</h3>
          <p>
            "회사"는 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 상품 또는 용역을 제공하는데 최선을
            다합니다.<br />
            "회사"는 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한
            보안 시스템을 갖추어야 합니다.<br />
            "회사"는 판매하는 상품이나 용역에 대하여 「표시·광고의공정화에관한법률」 제3조 의 규정에 위반하는
            표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 부담합니다.<br />
            "회사"는 수신거절의 의사를 명백히 표시한 이용자에 대해서는 영리목적의 광고성 이메일을 발송하지
            않습니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service15">제 15조 (회원의 ID 및 비밀번호에 대한 의무)</h3>
          <p>
            제18조의 경우를 제외하고, 회원의 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.<br />
            회원은 자신의 ID 및 비밀번호를 제3자가 이용하게 해서는 안됩니다.<br />
            회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "회사"에
            통보하고 "회사"의 안내가 있는 경우에는 그에 따라야 합니다.<br />
            "회사"는 회원이 상기 제1항, 제2항, 제3항을 위반하여 회원에게 발생한 손해에 대하여 아무런 책임을
            부담하지 않습니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service16">제 16조 (이용자의 의무)</h3>
          <p>
            이용자는 다음 행위를 하여서는 안됩니다.<br />
            개인정보의 등록(변경의 경우를 포함함)시 허위내용을 등록<br />
            "회사"에 게시된 정보를 임의로 변경<br />
            "회사"가 허락하지 않은 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br />
            "회사" 기타 제3자의 저작권 등 지적재산권에 대한 침해<br />
            "회사" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br />
            외설 또는 폭력적인 메시지·화상·음성 기타 공서양속에 반하는 정보를 화면에 공개 또는 게시하는 행위
          </p>
        </div>
        <h3 class="title">[제 6장 부가서비스의 이용]</h3>
        <div class="paragraph">
          <h3 id="service17">제 17조 (마플포인트)</h3>
          <p>
            "회사"는 회원이 상품을 구매하거나, 상품평의 작성 등의 경우 또는 회원이 제휴사 포인트를 전환하는
            경우 회원에게 일정한 포인트를 부여할 수 있습니다. 다만, 상품구매 후 취소 또는 반품을 할 경우에는
            상품 구매 시 회사가 부여한 마일리지를 회수합니다. 그 구체적인 운영방법은 "회사"의 운영정책에
            의합니다.<br />
            포인트는 상품 구매시 사용가능 기준 하에 현금가액과 동일하게 사용할 수 있으나 (단 사용불가
            사전고지품목 제외), 현금으로 환불되지는 않습니다. 또한, 마일리지 서비스는 회원에게만 제공되며
            타인에게 양도할 수 없습니다.<br />
            부여된 마일리지는 부여일로부터 2년간 유효합니다. 다만, 회사가 별도로 유효기간을 정한 때에는 이에
            따릅니다. 유효기간이 경과한 마일리지는 자동적으로 소멸하게 됩니다.<br />
            "회사"의 회원 중에 2년간 거래가 없고, 적립된 포인트가 3000포인트 미만인 경우에는 휴면회원으로
            간주하여 회사는 해당 회원의 적립된 마일리지를 회수하게 됩니다.<br />
            회원을 탈퇴한 경우 마일리지는 자동 소멸됩니다.<br />
            부정한 방법으로 포인트를 획득한 사실이 확인될 경우 "회사"는 회원의 포인트 회수, ID(고유번호) 삭제
            및 형사 고발 등 기타 조치를 취할 수 있습니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service18">제 18조 (상품쿠폰)</h3>
          <p>
            "회사"는 구매서비스를 이용하는 회원에게 지정된 상품 구매 시 일정액 또는 일정비율을 할인 받을 수
            있는 상품쿠폰을 발급할 수 있습니다.<br />
            회원은 상품쿠폰을 회원 본인의 구매에 한해서만 사용할 수 있으며, 어떠한 경우에도 이를 타인에게
            실질적으로 매매 또는 양도할 수 없습니다.<br />
            상품쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며, 유효기간이 지난 후에는 사용할 수
            없습니다.<br />
            회원을 탈퇴한 경우 상품쿠폰은 소멸됩니다.
          </p>
        </div>
        <h3 class="title">[제 7장 기타]</h3>
        <div class="paragraph">
          <h3 id="service19">제 19조 (저작권의 귀속 및 게시물 이용 제한)</h3>
          <p>
            "회사"가 작성한 저작물에 대한 저작권 기타 지적 재산권은 "회사"에 귀속합니다.<br />
            이용자는 서비스를 이용함으로써 얻은 정보를 "회사"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송
            기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.<br />
            "회사"는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야
            합니다.<br />
            회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램저작권 등을 침해하였음을 이유로
            "회사"가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 "회사"의 면책을 위하여
            노력하여야 하며, "회사"가 면책되지 못한 경우 회원은 그로 인해 "회사"에 발생한 모든 손해를
            부담하여야 합니다.<br />
            "회사"는 회원이 서비스 내에 게시한 게시물이 다음 각 호의 경우에 해당한다고 판단되는 경우 사전통지
            없이 삭제, 변경할 수 있으며, 이에 대해 "회사"는 어떠한 책임도 지지 않습니다.<br />
            스팸(spam)성 게시물 및 상업성 게시물<br />
            타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 게시물<br />
            동의 없는 타인의 신상공개, 제3자의 저작권 등 권리를 침해하는 내용을 담은 게시물<br />
            기타 관계 법령 및 "회사"의 지침 등에 위반된다고 판단되는 경우
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service20">제 20조 (면책)</h3>
          <p>
            "회사"는 천재지변, 불가항력 기타 "회사"의 합리적인 통제범위를 벗어난 사유로 인하여 서비스를 제공할
            수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.<br />
            "회사"는 이용자의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지
            않습니다.<br />
            "회사"는 이용자가 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해 얻은 자료를
            이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다.<br />
            이용자가 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 또는 정확성에 대하여는 해당
            이용자가 책임을 부담하며, "회사"는 내용의 부적정성으로 인해 이용자 또는 제3자에게 발생한 손해에
            대하여는 아무런 책임을 부담하지 않습니다.<br />
            "회사"는 서비스 이용과 관련하여 이용자의 고의 또는 과실로 인하여 이용자 또는 제3자에게 발생한
            손해에 대하여는 아무런 책임을 부담하지 않습니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service21">제 21조 (정보의 제공 및 광고의 게재)</h3>
          <p>
            "회사"는 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 e-mail 및 서신우편 등의
            방법으로 회원에게 제공할 수 있습니다.<br />
            "회사"는 서비스의 운영과 관련하여 홈페이지, 서비스 화면, SMS, e-mail등에 광고 등을 게재할 수
            있습니다.<br />
            회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의
            방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주 간의 문제입니다. 만약 회원과 광고주간에
            문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와 관련하여 "회사"는 어떠한 책임도
            지지 않습니다.
          </p>
        </div>
        <div class="paragraph">
          <h3 id="service22">제 22조 (재판권 및 준거법)</h3>
          <p>
            "회사"와 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고,
            주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소
            또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.<br />
            "회사"와 이용자간에 제기된 전자상거래 소송에는 대한민국법을 적용합니다.<br />
          </p>
        </div>
      </div>
    </div>
  </div>
`;
