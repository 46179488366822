import DOMPurify from 'dompurify';
import { go, tap } from 'fxjs/es';
import { $appendTo, $el, $find, $on, $qs, $remove } from 'fxdom/es';
import { SVGEditorMobileTextInputTmplS } from '../../S/Tmpl/module/SVGEditorMobileTextInputTmplS.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { SVGEditorTextF } from '../../../Text/F/Function/module/SVGEditorTextF.js';
import { NewMakerUtilF } from '../../../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';

function focusOutEvent({ e, text_el, sticker_editor }) {
  const purify = DOMPurify(window);
  const _text = e.currentTarget.value.trim();
  const text = purify.sanitize(SVGEditorTextF.getOnlyPossibleText(_text), {
    USE_PROFILES: { svg: true, svgFilters: true },
  });
  e.currentTarget.value = text;
  text_el.innerHTML = text;
  sticker_editor.syncSelectorWithSelection();
  if (_text?.length !== text?.length) {
    $.alert(T('maker::Please notice that unsupportive characters are going to be excluded.'));
  }
  MuiF.closeFrame();
}
export const create = ({ text_el, sticker_editor }) => {
  NewMakerUtilF.systemFontAffectCanvasFont();
  go(
    SVGEditorMobileTextInputTmplS.fn(text_el.innerHTML),
    $el,
    tap(
      $find('input'),
      $on('focusout', (e) => {
        focusOutEvent({ e, text_el, sticker_editor });
      }),
      $on('keyup', (e) => {
        if (e.key === 'Enter') {
          return focusOutEvent({ e, text_el, sticker_editor });
        }
        const text = e.currentTarget.value;
        e.currentTarget.value = text;
        text_el.innerHTML = text;
        sticker_editor.syncSelectorWithSelection();
      }),
    ),
    $appendTo($qs('#body')),
    $find('input'),
    (input$) => {
      const value_length = input$.value.length;
      input$.focus();
      input$.setSelectionRange(value_length, value_length);
    },
  );
};
export const destroy = () => $remove($qs('#mobile_text_input_81102'));
