export const Category = {
  FabricEvent: 'FabricEvent',
  PostMaker: 'PostMaker',
  Text: 'Text',
  ImageUpload: 'ImageUpload',
  SvgEditorImageUpload: 'SvgEditorImageUpload',
  MakerInit: 'MakerInit',
  SelectFace: 'SelectFace',
  SvgEditor: 'SvgEditor',
  ChangeBp: 'ChangeBp',
  PrevProductMatcher: 'PrevProductMatcher',
  BpOption: 'BpOption',
  Cleared: 'Cleared',
};
