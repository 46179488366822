import {
  $addClass,
  $el,
  $find,
  $height,
  $on,
  $prependTo,
  $removeClass,
  $scrollTop,
  $setCss,
  $setScrollTop,
} from 'fxdom/es';
import { go, html } from 'fxjs/es';
import { isMobile } from '../../../../OMP/Core/Util/F/Function/util.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';

function _bodyFixed$(be_fix, class_name, frame_el) {
  if (be_fix) {
    if (!isMobile() && $height(document.body) >= window.innerHeight) {
      $setCss({ 'overflow-y': 'scroll' }, document.body);
    }
    document.body.fixed_top = $scrollTop(window);
    go(document.body, $addClass(class_name), $setCss({ top: -document.body.fixed_top }));
    if (frame_el) {
      const prev_close_bg_el = go(frame_el, $find('.mp-maker-body-fixed__close-bg'));
      if (!prev_close_bg_el) {
        go(
          html`<div class="mp-maker-body-fixed__close-bg"></div>`,
          $el,
          $prependTo(frame_el),
          $on('click', () => {
            MuiF.closeFrame();
          }),
        );
      }
    }
  } else {
    go(document.body, $removeClass(class_name), $setCss({ top: '', 'overflow-y': '' }));
    $setScrollTop(document.body.fixed_top, window);
    document.body.fixed_top = void 0;
  }
}

export const bodyFixed$ = (be_fix, frame_el) => {
  return _bodyFixed$(be_fix, 'mp-maker-body-fixed', frame_el);
};

export const bodyFixedDimmed$ = (be_fix, frame_el) => {
  return _bodyFixed$(be_fix, 'mp-maker-body-fixed mp-maker-body-fixed-dimmed', frame_el);
};
