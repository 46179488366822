import { html } from 'fxjs/es';
import { VectorEditorKeyringFreeMobileConstantS } from '../Constant/module/VectorEditorKeyringFreeMobileConstantS.js';

const mui_ref_path = '/modules/VectorEditor/Keyring/Free/Mobile/S/Mui/tab.js';
/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  title: '', // tab button의 title
  selected: false, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  makeData(tab) {}, // template 함수 인자에 들어갈 값, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
  template(_data) {
    return html`
      <div class="editor_container"></div>

      <div class="header" data-is_show="true">
        <button type="button" class="cancel">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::cancel_button')}
        </button>

        <div class="icons">
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_RESET.ID}"
          >
            <input type="button" class="icon" disabled />
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_UNDO.ID}"
          >
            <input type="button" class="icon" disabled />
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_REDO.ID}"
          >
            <input type="button" class="icon" disabled />
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.TOGGLE_LAYERS_MODE.ID}"
            data-is_active="false"
          >
            <input type="button" class="icon" />
          </div>
          <div class="small_icon_menu" data-icon_type="more">
            <input type="button" class="icon" />
          </div>
        </div>

        <button type="button" class="next">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::next_button')}
        </button>
      </div>

      <div class="icon_menu footer text" data-is_show="false" data-icon_type="decorate_text">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::text_menu_decorate_text')}
        </span>
      </div>
      <div class="icon_menu footer text" data-is_show="false" data-icon_type="open_keyboard">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::text_menu_open_keyboard')}
        </span>
      </div>
      <div class="icon_menu footer text" data-is_show="false" data-icon_type="duplicate">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::text_menu_duplicate')}
        </span>
      </div>
      <div class="icon_menu footer text" data-is_show="false" data-icon_type="delete">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::text_menu_delete')}
        </span>
      </div>

      <div class="icon_menu footer path" data-is_show="false" data-icon_type="set_color">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::path_menu_set_color')}
        </span>
      </div>
      <div class="icon_menu footer path" data-is_show="false" data-icon_type="set_stroke">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::path_menu_set_stroke')}
        </span>
      </div>
      <div class="icon_menu footer path" data-is_show="false" data-icon_type="edit_path">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::path_menu_edit_path')}
        </span>
      </div>
      <div class="icon_menu footer path" data-is_show="false" data-icon_type="duplicate">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::path_menu_duplicate')}
        </span>
      </div>
      <div class="icon_menu footer path" data-is_show="false" data-icon_type="delete">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::path_menu_delete')}
        </span>
      </div>

      <div class="icon_menu footer shape" data-is_show="false" data-icon_type="set_color">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::shape_menu_set_color')}
        </span>
      </div>
      <div class="icon_menu footer shape" data-is_show="false" data-icon_type="set_stroke">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::shape_menu_set_stroke')}
        </span>
      </div>
      <div class="icon_menu footer shape" data-is_show="false" data-icon_type="duplicate">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::shape_menu_set_duplicate')}
        </span>
      </div>
      <div class="icon_menu footer shape" data-is_show="false" data-icon_type="delete">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::shape_menu_set_delete')}
        </span>
      </div>

      <div class="icon_menu footer object" data-is_show="false" data-icon_type="duplicate">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::object_menu_duplicate')}
        </span>
      </div>
      <div class="icon_menu footer object" data-is_show="false" data-icon_type="delete">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::object_menu_delete')}
        </span>
      </div>

      <div class="blank_plate" data-is_show="false" data-target="bottom_menu"></div>
      <div class="icon_menu bottom_menu" data-icon_type="image" data-is_show="true" data-is_active="false">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::bottom_menu_image')}
        </span>
      </div>
      <div class="icon_menu bottom_menu" data-icon_type="shape" data-is_show="true" data-is_active="false">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::bottom_menu_shape')}
        </span>
      </div>
      <div class="icon_menu bottom_menu" data-icon_type="path" data-is_show="true" data-is_active="false">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::bottom_menu_path')}
        </span>
      </div>
      <div class="icon_menu bottom_menu" data-icon_type="text" data-is_show="true" data-is_active="false">
        <button type="button" class="icon"></button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::bottom_menu_text')}
        </span>
      </div>
      <div class="icon_menu footer none" data-is_show="true" data-is_active="false" data-icon_type="add">
        <button type="button" class="icon">
          <div class="inactive_icon"></div>
          <div class="active_icon"></div>
        </button>
        <span class="label_text">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::template::bottom_menu_group')}
        </span>
      </div>

      <div class="blank_plate" data-is_show="false" data-target="top_menu"></div>
      <div class="top_menu_container" data-is_show="false">
        <div class="top_menu_line">
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_RESET.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_RESET.NAME)}
            </span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_UNDO.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_UNDO.NAME)}
            </span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_REDO.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.HISTORY_REDO.NAME)}
            </span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.DELETE.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.DELETE.NAME)}</span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.FORWARD.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.FORWARD.NAME)}</span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.BACKWARD.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text"
              >${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.BACKWARD.NAME)}</span
            >
          </div>
        </div>
        <div class="top_menu_line">
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.GROUP.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.GROUP.NAME)}</span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.UNGROUP.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.UNGROUP.NAME)}</span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.LOCK.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.LOCK.NAME)}</span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.UNLOCK.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.UNLOCK.NAME)}</span>
            <span class="lock_count"></span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.FLIP_HORIZONTAL.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.FLIP_HORIZONTAL.NAME)}
            </span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.FLIP_VERTICAL.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.FLIP_VERTICAL.NAME)}
            </span>
          </div>
        </div>
        <div class="top_menu_line">
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_LEFT_END.NAME)}
            </span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.NAME)}
            </span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_RIGHT_END.NAME)}
            </span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_TOP_END.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_TOP_END.NAME)}
            </span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.NAME)}
            </span>
          </div>
          <div
            class="small_icon_menu"
            data-icon_type="${VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID}"
          >
            <input type="button" class="icon" disabled />
            <span class="label_text">
              ${T(VectorEditorKeyringFreeMobileConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.NAME)}
            </span>
          </div>
        </div>
      </div>
    `;
  }, // 탭에 들어갈 html 을 그리는 함수
};
