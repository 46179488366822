import { find, go, some, every } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export function getPackagingFooterStatus({ empty_left_products, non_empty_waybill }) {
  let footer_status = 'EMPTY_WAYBILL';

  if (empty_left_products) {
    footer_status = 'FULL_WAYBILL';
  } else if (non_empty_waybill) {
    footer_status = 'NON_EMPTY_WAYBILL';
  }

  return footer_status;
}

export function getShippingStatus({ projection_status, inbound_order_statuses, outbound_order_statuses }) {
  let status = 'before_print';
  switch (projection_status) {
    case 'ordering':
    case 'before_print':
    case 'printing':
      status = 'BEFORE_PRINT';
      break;
    case 'before_deliver':
      status = 'BEFORE_DELIVER';
      break;
  }

  if (inbound_order_statuses && inbound_order_statuses.length > 0) {
    const PACKING = go(
      inbound_order_statuses,
      some((status) => status === 'PACKING'),
    );
    const PACKED = go(
      inbound_order_statuses,
      some((status) => status === 'PACKED'),
    );
    const REQUESTED = go(
      inbound_order_statuses,
      every((status) => ['REQUESTED', 'SHIPOUT', 'ARRIVED', 'RECEIVED'].includes(status)),
    );
    const RECEIVED = go(
      inbound_order_statuses,
      every((status) => ['RECEIVED', 'INSPECTING', 'INSPECTED'].includes(status)),
    );
    const INSPECTING = go(
      inbound_order_statuses,
      every((status) => ['INSPECTING', 'INSPECTED'].includes(status)),
    );

    if (PACKING) {
      // 운송장 발급
      status = 'CREATED';
    }

    if (PACKED) {
      // 포장 완료
      status = 'PACKED';
    }

    if (REQUESTED) {
      // 입고 요청
      status = 'REQUESTED';
    }
    // 까지 모든 입고 주문의 상태 값이 같음

    if (RECEIVED) {
      // LGL 입고 완료
      status = 'RECEIVED';
    }

    if (INSPECTING) {
      // LGL 검수 시작
      status = 'INSPECTING';
    }
  }

  if (!UtilS.isEmpty(outbound_order_statuses)) {
    status = 'CREATED';

    const {
      is_packing,
      _: { lgl_outbound_orders },
    } = outbound_order_statuses;

    if (is_packing) {
      status = 'PACKING';
    }

    if (lgl_outbound_orders.length > 0) {
      const SHIPOUT_FROM_LGL = go(
        lgl_outbound_orders,
        every(({ current_status }) => current_status !== 'PACKED'),
      );

      if (SHIPOUT_FROM_LGL) {
        status = 'SHIPOUT_FROM_LGL';
      }

      const DELIVERED = go(
        lgl_outbound_orders,
        every(({ current_status }) => current_status === 'DELIVERED'),
      );

      if (DELIVERED) {
        status = 'DELIVERED';
      }
    }
  }

  return status;
}

export function getProjectionStatus({ projection }) {
  const { collabo_type, merged_type, status } = projection;
  let projection_status = status;
  // 마플샵 주문 && 배송용 주문서
  // 제작준비중(before_print), 제작중(printing)도 배송 작업이 가능해야 함
  if (collabo_type === 'creator' && merged_type === 'child') {
    if (status === 'before_print' || status === 'printing') {
      projection_status = 'before_deliver';
    }
  }
  return projection_status;
}

export function getStatusAt({ histories, status_text }) {
  return (
    go(
      histories,
      find(({ status }) => status === status_text),
    ) || {}
  );
}
