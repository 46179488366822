import { html } from 'fxjs/es';

export const enFirst = () => html`
  <div class="terms_body">
    <div class="title"><h1>Privacy Policy</h1></div>
    <div class="body">
      <div class="text_body">
        <div class="paragraph">
          <h3>Introduction</h3>
          <p>
            Marpple Corp. (“Marpple”, “we”, “our” or “us”) respects the privacy of all of our visitors and
            pledges to provide you with the best online experience possible. This Privacy Policy sets out how
            we will collect and use the personal information you provide to us while using the website located
            at www.marpple.com (the “Site”) and/or the services provided from it (the “Services”). This
            Privacy Policy applies solely to the Site and the Services. It does not apply to any other site,
            product or service of Marpple, its affiliates, third party sellers, or customers.
          </p>
          <p>
            By accessing or using the Site, you are deemed to accept the terms of this Privacy Policy. If you
            do not agree to this Privacy Policy, you may not access or otherwise use the Site or Services. We
            reserve the right, at our discretion, to change, modify, add or remove portions of this Privacy
            Policy from time to time and such changes shall be posted on the Site. We encourage you to
            periodically review the Site for the latest information on our privacy practices
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Information That We Collect</h3>
          <p>
            We collect information you give us or permit us to access. Information may include, but is not
            limited to, your name, image, birth date, email and physical address, IP address, telephone number
            or mobile phone identifier, gender, contact lists, social media information, location (including
            GPS) information, activity and performance information, and when necessary, credit card
            information.
          </p>
          <p>
            We may also collect information regarding your interaction with, and use of, our products and
            services. Information we may collect includes, but is not limited to, your telephone number,
            Device identifier and hardware information, IP address, browser type and language, cookie
            information, system type, whether you have enabling software to access certain features, access
            times, referring website URLs, information about your purchases and other information about your
            interactions with us.
          </p>
          <p>
            To provide the Site and the Services, we may collect and process certain personal information that
            you voluntarily submit to us, such as through our contact pages, by email, by creating an account
            on the Site, by making purchases on the Site or some other means. We take care to keep this
            information secure and prevent any unauthorized access.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>How We Collect Information</h3>
          <p>
            We may use cookies and other collection tools, such as clickstream data and web beacons, to gather
            information about your visits on our website so we can enhance your experience. This information
            may be combined with information you have provided to us, which will enable us to analyze and
            better customize your visits.
          </p>
          <p>
            What Are Cookies? Cookies are data files stored within your browser when you interact with a
            website. These data files allow the website to recall important information about your visit, help
            better understand your preferences and improve your overall experience.
          </p>
          <p>
            You may limit or turn off cookies and other collection tools by adjusting your browser settings.
            If you turn off certain collection tools, you may not have access to many features that make your
            experience more efficient and some of our services and features may not function properly. We
            recommend that you leave cookies “turned on” so we can offer you a better shopping experience on
            our sites.
          </p>
          <p>
            We also work with other companies who use tracking technologies to serve ads on our behalf across
            the Internet. For example, we use Google Analytics on the website to analyze how users use the
            website and to provide advertisements to you on other websites. For more information about how to
            opt-out of having your information used by Google Analytics, visit
            https://tools.google.com/dlpage/gaoptout. These companies may not recognize or respond to browser
            “Do Not Track” signals. For more information about third party cookies, including information
            about how to opt-out of these technologies. We do not operate or control these sites, and are not
            responsible for the opt-out choices available there.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Protection of Personal Information Across Borders.</h3>
          <p>
            We do not and will never share, disclose, sell, rent or otherwise provide personal information to
            other companies for the marketing of their own products or services.
          </p>
          <p>
            We provide services to individuals and our technology processes data from users around the world.
            Accordingly, Marpple may transmit your personal information outside of the country, state, or
            province in which you are located.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Protection of Information</h3>
          <p>
            Marpple uses technical and organizational measures designed to protect your information against
            unauthorized access, theft, and loss. While Marpple strives to protect its user's personal
            information and privacy, we cannot guarantee 100% the security or confidentiality of any
            information that you provide to us or that is otherwise disclosed by you online. We recommend that
            you take additional measures to protect yourself and your information, such as installing
            anti-virus software, closing browsers after use, keeping confidential your log-in credentials and
            passwords.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Children’s Privacy</h3>
          <p>
            We do not knowingly collect or solicit information online from children under 13 and we do not
            specifically target children under 13 in our marketing materials or other advertising.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Sharing Information</h3>
          <p>
            We may provide your information to our affiliates and subsidiaries, as well as to our partners,
            vendors, distributors, and suppliers, some of which may be outside of your country. We may
            transfer your information to certain service providers in order to conduct our business
            operations. For example, service providers may handle web hosting, credit card processing,
            shipping, data management, email distribution, market research, information analysis, and
            promotions management. We may also share your information as is necessary to complete a
            transaction or provide a product, service, or feature that you have requested. These serve
            providers will only use your information for the purpose of carrying out the services provided to
            Marpple and are required to keep all such information confidential. We may disclose information
            upon governmental request, in response to a court order, when required by law, to enforce our
            policies, or to protect our or others' rights, property or safety. We may share information to
            prevent illegal uses of Marpple's products and services or violations of the Terms of Use, or to
            defend ourselves against third-party claims. We may also share information with companies
            assisting in fraud protection or investigation.
          </p>
          <p>
            Your information may be transferred to a third party as a part of our business assets in a sale of
            a part or all of Marpple. If this should happen, notice will be provided via the website or other
            appropriate form of communication.
          </p>
          <p>
            You may also choose to share certain information. When you join or use certain services, you agree
            to publicly share a basic amount of information, which may include your username, city location,
            and profile picture. In order to participate in certain features, you may have to adjust your
            privacy settings and share additional information. You may also choose to share your activity on
            other platforms, such as Instagram, Facebook and Twitter. Please read the privacy policies of
            those platforms, because your Marpple activity published on those platforms will no longer be
            governed by this Privacy Policy.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Residents of The European Economic Area (“EEA”)</h3>
          <p>
            Marpple works with artists/marketers and users around the world, including in the EEA. If you are
            located in the EEA, you have certain rights under European law with respect to your personal data,
            including the right to request access to, correct, amend, delete, or limit the use of your
            personal data. If you wish to exercise these rights, please reach out to us using the contact
            information below. Additionally, if you are located in the EEA, we note that we are generally
            processing your information in order to fulfill contracts we might have with you (for example if
            you make an order through the Site), or otherwise to pursue our legitimate business interests
            listed above, unless we are required by law to obtain your consent for a particular processing
            operation.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Data Transfer</h3>
          <p>
            Our digital operations are conducted, in whole or in part, in your country. Regardless of where
            you live, you consent to have your personal data transferred, processed and stored in your
            country, and allow Marpple to use and collect your personal information in accordance with this
            Privacy Policy.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>OPT-OUT, Delete or Modify Information</h3>
          <p>
            You may cancel your account, or opt-out of receiving e-mail messages from Marpple at any time. To
            unsubscribe and stop receiving communications from Marpple, you can change your account settings
            or follow the instructions enclosed within the email, text message, notification or other
            promotional message or communication. If you have unsubscribed from any Marpple communications,
            due to production schedules, you may receive communications already in production. Marpple may
            keep information and content in our backup files and archives.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Question and Comments</h3>
          <p>
            If you have any queries or comments about this Privacy Policy or our privacy practices, or wish to
            resume receiving information which you may have previously opted-out of receiving, please contact
            us at <a href="mailto:info@marpple.com">info@marpple.com</a>.
          </p>
          <p>Attn: Marpple Corp.<br />MarioTower 6F, Digital-ro 30,<br />Guro-gu Seoul,<br />Korea 08389</p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Terms of Use</h3>
          <p>
            Your use of our products and services, and any disputes arising from them, is subject to this
            Privacy Policy as well as the Terms of Use. Please visit our Terms of Use, which explains other
            terms governing the use of this website, as well as our products and services.
          </p>
        </div>
      </div>
    </div>
    <div class="option"><button class="done">Done</button></div>
  </div>
`;
