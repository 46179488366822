import { each, find, go, sel, tap } from 'fxjs/es';
import { createCanvasElement } from '../../../../Canvas/S/util.js';
import {
  downloadCanvas,
  downloadTemplatedPrint,
  makeFileName,
} from '../../../PrintResult/F/Function/makePxToResultPx.js';
import { drawProductFace, makeFcanvasByProductColor } from '../../../../Maker/F/draw_product_faces.js';
import {
  getBaseProductInMaker,
  getItsBpf,
  getProductFaces2InMaker,
  getRealFcanvass,
} from '../../../../Maker/F/getSth.js';
import { addImageUrlToCanvas } from '../../../CvObject/Util/F/Function/util.js';

export const checkTemplateIsDone = async ({ template_meta, product_color_id, base_product_size_id }) => {
  const product_color = await $.get('/@api/product_color', {
    id: product_color_id,
  });
  /*DF*/
  await makeFcanvasByProductColor(product_color);
  box.sel('maker->product_color').base_product_size_id = base_product_size_id;
  const base_product = getBaseProductInMaker();
  await go(
    getRealFcanvass(),
    each(async (fcanvas) => {
      const { size_faces } = getItsBpf(fcanvas);
      const sf = find((sf) => sf.base_product_size_id == base_product_size_id)(size_faces);
      await go(
        sf,
        sel('items'),
        tap((items) => {
          if (!items?.length) {
            alert('item이 없습니다.');
          }
        }),
        each(async (item) => {
          if (!item.px) {
            alert('면작업에서 템플릿 위치 잡기가 끝나지 않았습니다.');
          }
          await addImageUrlToCanvas({ fcanvas, url: item.print_item_url, location: item.px });
        }),
      );
    }),
    () => {
      G.mp.maker.reset_box_data();
    },
  );

  await go(
    getProductFaces2InMaker(),
    each(async (pf) => {
      await go(
        drawProductFace(createCanvasElement({ width: 860, height: 860 }), pf, false, false, true),
        (canvas) =>
          downloadCanvas({
            canvas,
            image_type: 'image/jpeg',
            title: `Preview_${base_product.name}`,
          }),
      );
    }),
  );

  await downloadTemplatedPrint({
    title: makeFileName({
      up_c: { up_id: '000' },
      base_product,
      up_c_s: { id: '000', quantity: 1, _: { base_product_size: { name: 'size_name' } } },
      merged_type: 'unmerged',
      base_product_color: { name: 'color' },
    }),
    base_product_size_id,
    product_faces2: getProductFaces2InMaker(),
    base_product_color_id: product_color.base_product_color_id,
    template_meta,
  });
  return $.confirm('템플릿이 정상입니까?');
};
