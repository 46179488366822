import { DfInhouseF } from './module/DfInhouseF.js';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';
import { zplWriteGenerator } from '../../../Stock/Labeling/F/Function/events.js';
import { go, map, zip } from 'fxjs/es';
import { DfWaybillF } from '../../../Waybill/F/Function/module/DfWaybillF.js';

export const labelPrint = {
  product: handleProductLabel, // 해외 배송 - 상품 라벨
  projectionBox: handleInhouseProjectionBoxLabel, // 사내 배송 - 주문 박스 라벨
  bucketWaybill: handleBucketWaybillLabel, // 사내 배송 - 운송장 라벨
};

export async function handleProductLabel() {
  console.log('상품 라벨 입니다.');
}

export async function handleBucketWaybillLabel({ shippings, is_override = false }) {
  const receivers = map((ship) => {
    const {
      company_label: name,
      company_address: address,
      company_name: address2,
      company_postcode: postcode,
      company_tel,
    } = ship;
    return {
      name,
      address,
      address2,
      postcode,
      mobile1: company_tel,
    };
  }, shippings);

  const {
    waybill: { orderer_name, printer_serial, format_data, dataFn },
  } = DfInhouseF.LABEL_CONFIG;

  const { process_statuses, waybill_label_datas } = await DfWaybillF.waybillGaraGo({
    receivers,
    additional_label_datas: {
      dataFn,
      formats: format_data,
    },
    orderer_name,
    printer_serial,
    is_alert_popup: true,
    is_override,
  });

  return go(
    zip(process_statuses, shippings, waybill_label_datas),
    map(([print_result, shipping, waybill_label_data]) => ({ print_result, shipping, waybill_label_data })),
  );
}

export async function handleInhouseProjectionBoxLabel({ projection_id, is_override = false }) {
  $.don_loader_start();

  try {
    const { username, has_inhouse_labeling_policy } =
      await DfInhouseF.apiCalls.get.hasUserInhouseLabelingPolicy();

    /* 0. 가드 - 사내배송 라벨링 권한 체크 (inhouse_labeling_policy) - 사내배송 작업대에 inhouse@marpple.com 로그인 -> 라벨링 가능 */
    if (has_inhouse_labeling_policy === false) {
      await DfInhouseF.alert.error({
        title: '사내 배송 라벨링 권한 에러',
        err: new Error(
          `${
            username ?? ''
          } 직원님은 사내 배송 라벨링 권한이 없습니다.<br>사내 배송 작업대에서 작업해 주세요.`,
        ),
      });
      return { is_OK: false };
    }
  } catch (err) {
    await DfInhouseF.alert.error({ err });
    return { is_OK: false };
  }

  try {
    const inhouse_shipping_box = await DfInhouseF.apiCalls.get.inhouseShippingBoxFromPrjId({ projection_id });

    const is_exist_inhouse_shipping_box = UtilObjS.isNotEmpty(inhouse_shipping_box);

    if (is_exist_inhouse_shipping_box) {
      /* 라벨 재출력 선택 prompt */
      const { is_reprint } = await DfInhouseF.alert.prompt.reprintOrCancel();
      if (!is_reprint) {
        return { is_OK: false };
      }
    }

    /* 라벨 생성을 위한 데이터 요청 */

    const { label_data, customer_order_crew_inhouse } = await DfInhouseF.generateProjectionBoxLabelData({
      projection_id,
    });

    const { is_OK } = await DfInhouseF.printInhouseProjectionBoxLabel({
      CONFIG: DfInhouseF.LABEL_CONFIG.prj_box,
      label_data,
      is_override,
    });

    if (is_OK) {
      if (!is_exist_inhouse_shipping_box) {
        const customer_order_crew_inhouse_id = customer_order_crew_inhouse.id;

        // 라벨 출력 정상 - 인서트
        await DfInhouseF.apiCalls.post.inhouseShippingBox({
          projection_id,
          customer_order_crew_inhouse_id,
        });

        /* gBox 신규 데이터로 sync */
        await DfInhouseF.stateProjection.gBox.hydrate.customerOrderCrewInhouse({
          projection_id,
          customer_order_crew_inhouse_id,
        });
      }
    } else {
      // 라벨 출력 에러
      await DfInhouseF.alert.error({ err: new Error(`라벨 출력 문제 발생`) });
      return { is_OK: false };
    }

    return { is_OK: true };
  } catch (err) {
    await DfInhouseF.alert.error({ err });
    return { is_OK: false };
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function printInhouseProjectionBoxLabel({ CONFIG, label_data, is_override }) {
  if (is_override) {
    return { is_OK: true };
  }
  const error_title = '라벨 출력 오류';
  try {
    DfInhouseF.lottie_loader.start('printer');
    const { printer_serial, device_setting_fn, ...configs } = CONFIG;

    const device = await DfWaybillF.prepareLabelPrinter({
      setting_fn: DfWaybillF.printer_config_fn.inhouse_projection_box,
      printer_serial,
    });

    const format = DfInhouseF.labelAction.createAndSetupFormat(configs);
    const page = DfInhouseF.labelAction.createPage({ format, label_data });

    /* Layout Lines */
    page.addGraphicBox(2, 0, 96, 75, 5, 'B', 1); // Layout Global Box
    page.addGraphicBox(52, 0, 0, 18, 5, 'B', 10); // 1st vertical
    page.addGraphicBox(22, 18, 0, 22, 5, 'B', 10); // 2-4 vertical
    page.addGraphicBox(2, 18, 96, 0, 5, 'B', 10); // 1st
    page.addGraphicBox(2, 24, 96, 0, 5, 'B', 10); // 2nd
    page.addGraphicBox(2, 34, 96, 0, 5, 'B', 10); // 3rd
    page.addGraphicBox(2, 40, 96, 0, 5, 'B', 10); // 4th

    await DfInhouseF.labelAction.setFormatZplToDevice({ device, format });

    const zpl = page.toZPLStr();
    let print_progress = 0;
    for await (const value of zplWriteGenerator(device, [zpl])) {
      print_progress = value;
    }

    return { is_OK: print_progress === 1 };
  } catch (err) {
    await DfInhouseF.alert.error({ title: error_title, err });
    return { is_OK: false };
  } finally {
    DfInhouseF.lottie_loader.end(1500);
  }
}
