import { html } from 'fxjs/es';

export const resetPasswordTmpl = () => {
  return html`
    <div class="reset-password-wrapper">
      <div class="reset-password">
        <form class="reset-password__email" id="reset-password__email">
          <h2 class="reset-password__email__title">이메일</h2>
          <input
            class="reset-password__email__value"
            name="email"
            type="email"
            placeholder="이메일 주소를 입력해 주세요."
            required
          />
        </form>
        <div class="reset-password__buttons">
          <button disabled class="reset-password__button" type="submit" form="reset-password__email">
            비밀번호 재설정 메일 전송
          </button>
        </div>
      </div>
    </div>
  `;
};
