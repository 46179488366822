/**
 * @module item_to_pages
 * 인쇄 아이템을 페이지로 구성하는 유틸리티 함수 제공.
 */

import { chunk, flatL, go, map, rangeL } from 'fxjs/es';

/**
 * 아이템들을 세로로 쌓아 페이지를 구성하는 함수
 * @function
 * @param {Array} print_items - 인쇄할 아이템 배열
 * @param {Object} options - 옵션 객체
 * @param {number} options.max_print_per_paper - 한 페이지당 최대 인쇄 가능 아이템 수
 * @returns {Array<Array>} 페이지별로 구성된 아이템 배열
 */
export const stackVertically = (print_items, { max_print_per_paper }) => {
  /**
   * 필요한 총 페이지 수
   * @type {number}
   */
  const sheet_count = go(print_items.length, (n) => n / max_print_per_paper, Math.ceil);

  /**
   * 아이템들을 sheet_count 만큼의 청크로 나눈 배열
   * @type {Array<Array>}
   */
  const slice_chunks = go(print_items, flatL, chunk(sheet_count));

  return go(
    rangeL(sheet_count),
    map((i) => map((slices) => slices[i], slice_chunks)),
  );
};

/**
 * 아이템들을 첫 장부터 순차적으로 쌓아 페이지를 구성하는 함수
 * @function
 * @param {Array} print_items - 인쇄할 아이템 배열
 * @param {Object} options - 옵션 객체
 * @param {number} options.max_print_per_paper - 한 페이지당 최대 인쇄 가능 아이템 수
 * @returns {Array<Array>} 페이지별로 구성된 아이템 배열
 */
export const stackHorizontally = (print_items, { max_print_per_paper }) => {
  return chunk(max_print_per_paper, print_items);
};
