import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';
import { DfInhouseConstantS } from '../../S/Constant/module/DfInhouseConstantS.js';

const {
  PACK: { key: pack_mode, name: pack_mode_name },
  UNPACK: { key: unpack_mode, name: unpack_mode_name },
} = DfInhouseConstantS.BUCKET_MODE_KEY;
const {
  COMPLETE: { key: complete_command, name: complete_command_name },
} = DfInhouseConstantS.BUCKET_CONTROL_NAME;

const controlQrCodeKey = {
  [pack_mode]: 'bucket_pack_mode',
  [unpack_mode]: 'bucket_unpack_mode',
  [complete_command]: 'bucket_complete_mode',
};

export const LABEL_CONFIG = {
  waybill: {
    printer_serial: 'D0J213401890',
    orderer_name: '마플 사내배송 관리자',
    dataFn: (label_data) => {
      const waybill_no = UtilStringS.extractNumbersFromString(label_data.invoice_no);
      return {
        [controlQrCodeKey[pack_mode]]: makeWaybillControlStr({
          mode_key: pack_mode,
          waybill_no,
        }),
        [controlQrCodeKey[unpack_mode]]: makeWaybillControlStr({
          mode_key: unpack_mode,
          waybill_no,
        }),
        [controlQrCodeKey[complete_command]]: makeWaybillControlStr({
          mode_key: complete_command,
          waybill_no,
        }),
      };
    },
    format_data: [
      {
        type: 'static',
        static_data: [pack_mode_name],
        field_box: {
          width: 20,
          max_lines: 1,
          line_gap: 0,
          text_just: 'C',
          indent: 0,
        },
        position: { x: 4, y: 74 },
        font: {
          name: 'J',
          height: 3,
        },
      },
      {
        type: 'static',
        static_data: [unpack_mode_name],
        field_box: {
          width: 20,
          max_lines: 1,
          line_gap: 0,
          text_just: 'C',
          indent: 0,
        },
        position: { x: 55, y: 74 },
        font: {
          name: 'J',
          height: 3,
        },
      },
      {
        type: 'static',
        static_data: [complete_command_name],
        field_box: {
          width: 20,
          max_lines: 1,
          line_gap: 0,
          text_just: 'C',
          indent: 0,
        },
        position: { x: 102.5, y: 74 },
        font: {
          name: 'J',
          height: 3,
        },
      },
      {
        description: '담기 모드',
        title: [controlQrCodeKey[pack_mode]],
        type: 'variable',
        scan_code_type: 'aztec',
        scan_code_options: { mag: 5 },
        position: { x: 7, y: 60 },
      },
      {
        description: '제거 모드',
        title: [controlQrCodeKey[unpack_mode]],
        type: 'variable',
        scan_code_type: 'aztec',
        scan_code_options: { mag: 5 },
        position: { x: 58.5, y: 60 },
      },
      {
        description: '포장 완료',
        title: [controlQrCodeKey[complete_command]],
        type: 'variable',
        scan_code_type: 'aztec',
        scan_code_options: { mag: 5 },
        position: { x: 106, y: 60 },
      },
    ],
  },
  prj_box: {
    format_path: 'E:inhouse_prj_label_format.ZPL',
    // printer_serial: 'D6J231712320', // 개발팀 라벨프린터 시리얼번호
    printer_serial: 'D6J231712336', // 배송팀 NBOX 전용 라벨프린터 시리얼번호
    width: 100, // mm
    rotation: false,
    dpi: 203,
    home: [1, 3],
    format_data: [
      {
        description: '회사 라벨 타이틀 - ex. NBOX',
        title: 'company_label',
        type: 'variable',
        tree_name: null,
        field_box: {
          width: 50,
          max_lines: 1,
          line_gap: 0,
          text_just: 'C',
          indent: 0,
        },
        position: { x: 4, y: 4 },
        font: {
          name: '0',
          orient: 'N',
          height: 14,
          width: null,
        },
      },
      {
        type: 'static',
        static_data: ['Marpple Corporation'],
        tree_name: null,
        field_box: {
          width: 25,
          max_lines: 1,
          line_gap: 0,
          text_just: 'C',
          indent: 0,
        },
        position: { x: 63, y: 3.5 },
        font: {
          name: '0',
          orient: 'N',
          height: 2,
          width: null,
        },
      },
      {
        description: '주문 번호',
        title: 'projection_id',
        type: 'variable',
        tree_name: null,
        field_box: {
          width: 25,
          max_lines: 1,
          line_gap: 0,
          text_just: 'C',
          indent: 0,
        },
        position: { x: 62.5, y: 13 },
        font: {
          name: '0',
          orient: 'N',
          height: 2,
          width: null,
        },
      },
      {
        description: '주문번호 바코드',
        title: 'barcode_projection_id',
        type: 'variable',
        scan_code_type: 'code128,C',
        position: { x: 60, y: 6 },
        scan_code_options: {
          module_width: 3,
          height: 6.5,
        },
      },

      {
        type: 'static',
        static_data: ['회사'],
        tree_name: null,
        field_box: {
          width: 22,
          max_lines: 1,
          line_gap: 0,
          text_just: 'C',
          indent: 0,
        },
        position: { x: 2, y: 19.5 },
        font: {
          name: 'J',
          orient: 'N',
          height: 4,
          width: null,
        },
      },
      {
        type: 'static',
        static_data: ['주소'],
        tree_name: null,
        field_box: {
          width: 22,
          max_lines: 1,
          line_gap: 0,
          text_just: 'C',
          indent: 0,
        },
        position: { x: 2, y: 27.5 },
        font: {
          name: 'J',
          orient: 'N',
          height: 4,
          width: null,
        },
      },
      {
        type: 'static',
        static_data: ['받는분'],
        tree_name: null,
        field_box: {
          width: 22,
          max_lines: 1,
          line_gap: 0,
          text_just: 'C',
          indent: 0,
        },
        position: { x: 2, y: 35.5 },
        font: {
          name: 'J',
          orient: 'N',
          height: 4,
          width: null,
        },
      },

      {
        title: 'location_name',
        type: 'variable',
        tree_name: null,
        field_box: {
          width: 70,
          max_lines: 1,
          line_gap: 0,
          text_just: 'L',
          indent: 0,
        },
        position: { x: 25, y: 19.5 },
        font: {
          name: 'J',
          orient: 'N',
          height: 3.5,
          width: null,
        },
      },
      {
        title: 'address',
        type: 'variable',
        tree_name: null,
        field_box: {
          width: 70,
          max_lines: 2,
          line_gap: 0,
          text_just: 'L',
          indent: 0,
        },
        position: { x: 25, y: 25.8 },
        font: {
          name: 'J',
          orient: 'N',
          height: 3.5,
          width: null,
        },
      },
      {
        title: 'employee',
        type: 'variable',
        tree_name: null,
        field_box: {
          width: 70,
          max_lines: 1,
          line_gap: 0,
          text_just: 'L',
          indent: 0,
        },
        position: { x: 25, y: 35.5 },
        font: {
          name: 'J',
          orient: 'N',
          height: 3.5,
          width: null,
        },
      },

      {
        description: '주문번호 조회 2D코드',
        title: 'qrcode',
        type: 'variable',
        scan_code_type: 'aztec',
        scan_code_options: { mag: 4 },
        position: { x: 88, y: 65 },
      },
      {
        description: '상품명',
        title: 'up_name_list',
        type: 'variable',
        field_box: {
          width: 91,
          max_lines: 11, // 주문 정보 1줄 + 아이템 정보 9 줄
          line_gap: 0.3,
          text_just: 'L',
          indent: 0,
        },
        position: { x: 5, y: 42 },
        font: {
          name: 'J',
          height: 2.5,
        },
      },
    ],
  },
};

function makeWaybillControlStr({ mode_key, waybill_no }) {
  return `${DfInhouseConstantS.BUCKET_CONTROL_PREFIX.key}-${mode_key}-${waybill_no}`;
}
