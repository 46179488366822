import { go, sel } from 'fxjs/es';
import { BNF, BRAWLSTARS, BT21, TRUZ } from '../../Line/Home/S/constant.js';
import { addCvImage } from './CvImage/fs.js';
import { createCvPattern } from './CvPattern/fs.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import axios from 'axios';
import { makeApiUrl } from '../../Util/S/Function/util.js';
import { $qs } from 'fxdom/es';
import { NewMakerPropertyBpfF } from '../../NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';
import { getCurrentFcanvas } from './Fcanvas/cv_object.js';

const image_colors = new Map();
const memoizeImageColor = async (image_id) => {
  if (!image_colors.get(image_id)) {
    image_colors.set(image_id, await $.get('/@api/image/image_colors', { image_id }));
  }
  return image_colors.get(image_id);
};
export const select_color_images = (cv_object) => {
  if (NewMakerPropertyBpfF.bpcColorCode2Render.isTarget(getCurrentFcanvas())) {
    return;
  }
  if (!cv_object._data.image_color_id && !cv_object._data.old_image_id) {
    return null;
  } else if (cv_object._data.image_color_id && !cv_object._data.old_image_id) {
    return _p.go(memoizeImageColor(cv_object._data.image_id), function (images) {
      box.set('maker->image_colors', images);
      return images;
    });
  } else if (cv_object._data.old_image_id && !cv_object._data.old_image_color_id) {
    return go(
      $.get('/@api/image/old_image', {
        old_image_id: cv_object._data.old_image_id,
      }),
      function (image) {
        cv_object._data.image_id = _p.v(image, 'id');
        cv_object._data.old_image_id = null;
        return null;
      },
    );
  } else {
    return go(
      $.get('/@api/image/old_image_colors', {
        old_image_id: cv_object._data.old_image_id,
      }),
      function (image) {
        cv_object._data.image_id = _p.v(image, 'id');
        cv_object._data.image_color_id = _p.v(image, '_.image_colors.id');
        cv_object._data.old_image_id = null;
        cv_object._data.old_image_color_id = null;
        box.set('maker->image_colors', _p.v(image, '_.image_colors'));
        return _p.v(image, '_.image_colors');
      },
    );
  }
};

export const check_only_one_image_color = (cv_target) => {
  if (!cv_target || !cv_target._data) return;

  const cv_type = cv_target._data.cv_type;
  return go(
    (cv_type === 'cv_image' || cv_type === 'cv_pattern') && !!cv_target._data.image_color_id && cv_target,
    function (cv_image) {
      if (!cv_image) return;
      if (sel('_data.is_only_one', cv_image) !== undefined) return;
      return go(
        undefined,
        function () {
          if (!cv_image._data.image_color_id && !cv_image._data.old_image_id) {
            return false;
          } else if (cv_image._data.image_color_id && !cv_image._data.old_image_id) {
            return $.get('/@api/image/is_one_image_color', {
              image_id: cv_image._data.image_id,
            });
          } else if (cv_image._data.old_image_id && !!cv_image._data.old_image_color_id) {
            return go(
              $.get('/@api/image/old_image_colors', {
                old_image_id: cv_image._data.old_image_id,
              }),
              function (image) {
                const image_colors = sel('_.image_colors', image);
                return image_colors && image_colors.length === 1;
              },
            );
          } else return false;
        },
        function (is_only_one) {
          cv_image._data.is_only_one = is_only_one;
        },
      );
    },
  );
};

export const selectColorImagesInFlex = (cv_object) => {
  return go(select_color_images(cv_object), async function (image_colors) {
    if (G.mp.maker.can_flex()) return image_colors;
    return _p.reject(image_colors, function (color) {
      return color.only_flex;
    });
  });
};

async function design_collection_id_141(closing) {
  const folder = await $.get('/@api/line_sticker/469');
  if (!folder) return;

  $.frame.open(
    {
      is_modal: !UtilF.isLegacyMobile(),
      frame_name: 'maker.line.sticker',
      page_name: 'maker_sticker_line_page2',
      appending: __($.attr('is_fixed_head', 'true')),
      closing,
    },
    {
      page_name: 'maker_sticker_line_page2',
      hide_frame_button_type: 'X',
      folder2: folder,
      tabs: [
        {
          tab_name: 'maker_sticker_line_page2_tab',
          title: folder.name || '',
          selected: true,
          data_func: function () {
            return folder;
          },
        },
      ],
    },
  );
}

async function openLineSticker(closing) {
  if (!$1('html[collabo_type="line"]')) return;

  if (box.sel('maker->product_color->design_collection_id') == 141) {
    return design_collection_id_141(closing);
  }
  $.frame.open(
    {
      is_modal: !UtilF.isLegacyMobile(),
      frame_name: 'maker.line.sticker',
      page_name: 'maker_line_sticker_page',
      animation: true,
      frame_tag: $.attr($1('html'), 'design_collection_type'),
      appending: __($.attr('is_fixed_head', 'true')),
      closing,
    },
    {
      page_name: 'maker_line_sticker_page',
      hide_frame_button_type: !UtilF.isLegacyMobile() ? 'x' : 'v',
      tabs: [
        {
          tab_name: 'maker_line_bnf_tab',
          selected:
            $.attr($1('html'), 'design_collection_type') == BNF ||
            !$.attr($1('html'), 'design_collection_type'),
        },
        {
          tab_name: 'maker_line_bt21_tab',
          selected: $.attr($1('html'), 'design_collection_type') == BT21,
        },
        {
          tab_name: 'maker_line_bs_tab',
          selected: $.attr($1('html'), 'design_collection_type') == BRAWLSTARS,
        },
        {
          tab_name: 'maker_line_truz_tab',
          selected: $.attr($1('html'), 'design_collection_type') == TRUZ,
        },
      ],
    },
  );
}

export const addLineSticker = () => {
  return openLineSticker(function (don_frame, image) {
    if (!image) return;
    return _p.go(
      addCvImage({ image }),
      G.mp.maker.modified,
      _p.tap(function () {
        don_frame.frame_opt.frame_tag = box.sel('maker->product_color').design_collection_type;
        $.attr(don_frame, { frame_tag: don_frame.frame_opt.frame_tag });
      }),
    );
  });
};

export const changeLineSticker = () => {
  return openLineSticker(function (don_frame, image) {
    if (!image) return;

    const active = G.mp.maker.active();
    if (!active) return;
    const canvas = active.canvas;
    const center = active.getCenterPoint();
    const idx = canvas._objects.indexOf(active);
    const width = G.mp.maker.fabric_get_width(active);
    const active_cv_type = active._data.cv_type;
    const active_angle = active.angle;
    const active_pattern_data = active._data.pattern_data;

    canvas.remove(active);
    return _p.go(
      addCvImage({ image }),
      function (cv_image) {
        if (active_cv_type == 'cv_pattern') {
          return _p.go(
            createCvPattern(G.mp.maker.to_attrs(cv_image), active_pattern_data),
            function (cv_pattern) {
              canvas.remove(cv_image);
              canvas.insertAt(cv_pattern, idx);
              canvas.setActiveObject(cv_pattern);
              G.mp.maker.nscreen(cv_pattern);
              return cv_pattern;
            },
          );
        } else {
          return cv_image;
        }
      },
      function (cv_image) {
        cv_image.scaleToWidth(width);
        cv_image.set('left', center.x - width / 2).setCoords();
        cv_image.set('top', center.y - (cv_image.height * cv_image.scaleY) / 2).setCoords();
        const point = new fabric.Point(cv_image.getCenterPoint().x, cv_image.getCenterPoint().y);
        cv_image.set('angle', active_angle).setCoords();
        cv_image.setPositionByOrigin(point, 'center', 'center');

        /*뭔가 이렇게 해야지만 작동함*/
        cv_image.set('left', cv_image.left).setCoords();

        canvas.renderAll();
        G.mp.maker.reset_data_cm(cv_image);
      },
      G.mp.maker.modified,
      _p.tap(function () {
        don_frame.frame_opt.frame_tag = box.sel('maker->product_color').design_collection_type;
        $.attr(don_frame, { frame_tag: don_frame.frame_opt.frame_tag });
      }),
    );
  });
};

export const getMetadataFromImageUrl = async ({ url }) => {
  const is_df = $qs('#dream_factory');
  return (
    await axios.get(
      makeApiUrl(`${is_df ? '' : '/:lang'}/@api/image/metadata`, {
        lang: T.lang,
      }),
      { params: { url } },
    )
  ).data;
};
