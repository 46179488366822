import axios from 'axios';
import { $addClass, $delegate, $find, $removeAttr, $removeClass, $setAttr, $setVal, $val } from 'fxdom/es';
import { go, object } from 'fxjs/es';
import { AlertTmplS } from '../../../../Alert/S/Tmpl/module/AlertTmplS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

export const events = (tab_el) => {
  return go(
    tab_el,
    $delegate('focusout', '.reset-password__email__value', function (e) {
      $setVal(UtilS.lowerCase($val(e.currentTarget)), e.currentTarget);
    }),
    $delegate('input', '.reset-password__email__value', (e) => {
      const email = $val(e.currentTarget).trim();
      const btn = $find('.reset-password__button', e.delegateTarget);
      const disabled = btn.disabled;

      if (!UtilS.is_valid_email(email)) {
        if (!disabled) go(btn, $removeClass('enable'), $setAttr({ disabled: '' }));
        return;
      }

      if (disabled) {
        go(btn, $addClass('enable'), $removeAttr('disabled'));
      }
    }),
    $delegate('submit', '#reset-password__email', async (e) => {
      e.originalEvent.preventDefault();
      try {
        await go(
          e.currentTarget,
          (el) => new FormData(el).entries(),
          object,
          async ({ email }) => {
            if (!email) {
              $find('.password__email__value', e.currentTarget).focus();
              throw new Error('이메일이 비어있습니다.');
            }

            if (!UtilS.is_valid_email(email)) {
              $find('.password__email__value', e.currentTarget).focus();
              throw new Error('이에일이 유효하지 않습니다.');
            }

            $.don_loader_start();
            const res = await axios.post('/@api/reset_password/request_cert', { email });
            $.don_loader_end();
            await $.alert(AlertTmplS.alertTmpl(res.data));
          },
        );
      } catch (e) {
        $.don_loader_end();
        $.alert(AlertTmplS.alertTmpl(e?.response?.data || e?.message));
      }
    }),
  );
};
