import { find, html, strMap } from 'fxjs/es';
import { OMPCoreFramesTabStyleConstantS } from '../../../../OMP/Core/Frames/TabStyle/S/Constant/module/OMPCoreFramesTabStyleConstantS.js';

export const tmpl = (data) => {
  console.log(data);
  return html` <div class="pdf-file-error-modal-wrapper">
    <div class="header">
      <div class="empty"></div>
      <div class="title">지류 PDF 파일 이슈 등록</div>
      <div class="close">X</div>
    </div>
    <div class="body">
      <form class="pdf-file-error-modal__form">
        <input type="hidden" name="id" value="${data?.history?.printing_file_id}" />
        <input type="hidden" name="product_id" value="${data?.history?.product_id}" />
        <label for="issue_customer">와우프레스 에러 메세지</label>
        <textarea readonly name="issue_manufacturer">${data?.history?.issue_manufacturer || ''}</textarea>
        <label for="issue_customer">고객용 메세지</label>
        ${strMap((i) => {
          return html`
            <label>
              <input
                type="checkbox"
                name="need_modify"
                value="${i.name}"
                ${find((n) => n === i.name, data?.history?.modify_item_list) ? 'checked' : ''}
              />
              ${i.name}
            </label>
          `;
        }, OMPCoreFramesTabStyleConstantS.infos(OMPCoreFramesTabStyleConstantS.TYPE.MODIFY)[OMPCoreFramesTabStyleConstantS.TYPE.MODIFY].tabs)}
        <textarea id="issue_customer" name="issue_customer">${data?.history?.issue_customer || ''}</textarea>
        <div class="btn-area">
          <button class="cancel" type="button">취소</button>
          <button class="save" type="submit">저장</button>
        </div>
      </form>
    </div>
  </div>`;
};
