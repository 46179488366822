import { makeReturnTabHtml } from '../Tmpl/tmpl.tabs.js';
import { base_tab } from './base_tab.js';

const mui_ref_path = '/modules/Df/Lgl/ShippingDetail/S/Mui/tab_return.js';
export const tab_return = base_tab({
  mui_ref_path,
  title: '회수 상태',
  tag: 'return',
  template: makeReturnTabHtml,
});
