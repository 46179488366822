import { VectorEditorAcrylicFigureF } from '../../../../../VectorEditor/AcrylicFigure/F/Function/module/VectorEditorAcrylicFigureF.js';
import { $qs, $scrollTop } from 'fxdom/es';
import { acrylicFigurePostProcess } from './acrylicFigurePostProcess.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';

export const openAcrylicFigureEditor = async ({
  app,
  post_process_props,
  editor_props: { title, options, svg_file, price, tab_el },
}) => {
  if (UtilF.isLegacyMobile()) {
    await VectorEditorAcrylicFigureF.makeAcrylicFigureMobile({
      svg_file,
      options,
      postProcess: acrylicFigurePostProcess(post_process_props),
    });
  } else {
    const { y: frame_position_top, height: frame_position_height } = tab_el
      ?.querySelector?.('.mp_maker')
      ?.getBoundingClientRect?.() ?? {
      y: 70,
      height: 926,
    };
    if (app === 'creator') {
      await VectorEditorAcrylicFigureF.makeAcrylicFigureCreatorPC({
        title,
        price,
        svg_file,
        options,
        postProcess: acrylicFigurePostProcess(post_process_props),
      });
    } else {
      await VectorEditorAcrylicFigureF.makeAcrylicFigurePC({
        title,
        price,
        frame_position: { top: frame_position_top, height: frame_position_height },
        prev_frame_right_panel: $qs('#marpplizer'),
        onFrameHiding: () => {
          (tab_el?.tab_opt ?? {}).tab_top = $scrollTop(window);
        },
        svg_file,
        options,
        postProcess: acrylicFigurePostProcess(post_process_props),
      });
    }
  }
};
