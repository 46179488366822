import { formatDistanceToNow } from 'date-fns';
import en from 'date-fns/locale/en-US/index.js';
import jp from 'date-fns/locale/ja/index.js';
import kr from 'date-fns/locale/ko/index.js';
import { find, go, last, sumBy } from 'fxjs/es';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';

const makeD = ({ day, hour, minute }) =>
  day > 0
    ? `D-${day}`
    : hour > 0
    ? T('mshop::ProductBadge::??시간전', { hour })
    : T('mshop::ProductBadge::??분전', { minute: Math.max(minute, 1) });

export const setBadge = (
  {
    sell_start_at,
    sell_end_at,
    quantity,
    is_quantity_public = true,
    order_count,
    ordered_at,
    is_sptg,
    spo_item_stocks,
  },
  is_studio,
) => {
  if (!quantity && !sell_start_at && !sell_end_at && !ordered_at && !is_sptg && !spo_item_stocks?.length)
    return null;

  const is_no_spo_item_stock =
    spo_item_stocks?.length &&
    go(
      spo_item_stocks,
      sumBy(({ quantity }) => quantity),
    ) <= 0;
  const start_at = new Date(sell_start_at);
  const end_at = new Date(sell_end_at);
  const now = Date.now();
  /* 매진 */
  const badges = [];

  if (!is_studio && ordered_at) {
    const lang = { kr, en, jp };
    return {
      type: 'real',
      name: formatDistanceToNow(new Date(ordered_at), {
        // unit: 'minute',
        // roundingMethod: 'ceil',
        locale: lang[T.lang.toLowerCase()],
      }),
    };
  }

  if ((quantity > 0 && quantity <= order_count) || (sell_start_at && end_at <= now) || is_no_spo_item_stock) {
    badges.push({ type: 'sold_out', name: T('mshop::ProductBadge::매진') });
  } else {
    if (sell_start_at && sell_end_at) {
      const is_coming = now < start_at;
      const { day, hour, minute } = UtilS.differenceTimeInUnits(
        ['day', 'hour', 'minute', 'second'],
        is_coming ? start_at : end_at,
        now,
        true,
      );
      const d = makeD({ day, hour, minute });
      if (is_coming)
        return { type: 'coming', name: T(`mshop::ProductBadge::출시${day > 7 ? '예정' : '??'}`, { d }) };
      else if (end_at > now)
        badges.push({
          type: day > 2 ? 'sell_close' : 'sell_at',
          name: T(`mshop::ProductBadge::${day > 2 ? '한정' : '마감??'}`, { d }), // d:  `${hour}:${minute}:${second}`,
        });
    }

    /* 한정 수량 */
    if (quantity > 0 && quantity > order_count) {
      const c = quantity - order_count;
      badges.push({
        type: 'limit',
        name: T(
          `mshop::ProductBadge::${
            is_quantity_public && (c / quantity <= 0.2 || c <= 10) ? '??개남음' : '한정'
          }`,
          {
            c,
          },
        ),
      });
    }

    if (is_sptg) {
      badges.push({
        type: 'nft',
        name: 'NFT' + T(`mshop::ProductBadge::한정`),
      });
    }
  }
  /* 설정기간 이전 */

  /* 가격할인 */

  /* 실시간 판매 */
  return is_studio
    ? badges
    : find(({ name }) => name != T(`mshop::ProductBadge::한정`), badges) || last(badges);
};

export const setSimpleBadge = ({
  sell_start_at,
  sell_end_at,
  quantity,
  order_count,
  ordered_at,
  is_sptg,
  spo_item_stocks,
  is_quantity_public,
}) => {
  if (!quantity && !sell_start_at && !sell_end_at && !ordered_at && !is_sptg && !spo_item_stocks?.length)
    return null;

  const is_no_spo_item_stock =
    spo_item_stocks?.length &&
    go(
      spo_item_stocks,
      sumBy(({ quantity }) => quantity),
    ) === 0;
  const start_at = new Date(sell_start_at);
  const end_at = new Date(sell_end_at);
  const now = Date.now();
  /* 매진 */
  const badges = [];

  if ((quantity > 0 && quantity <= order_count) || (sell_start_at && end_at <= now) || is_no_spo_item_stock) {
    badges.push({ type: 'sold_out' });
  } else {
    if (sell_start_at && sell_end_at) {
      const is_coming = now < start_at;
      const { day, hour, minute } = UtilS.differenceTimeInUnits(
        ['day', 'hour', 'minute', 'second'],
        is_coming ? start_at : end_at,
        now,
        true,
      );
      if (is_coming) {
        return { type: 'coming', day, hour, minute };
      } else if (end_at > now) {
        badges.push({ type: 'remaining', day, hour, minute });
      }
    }

    /* 한정 수량 */
    if (quantity > 0 && quantity > order_count) {
      const qty = quantity - order_count;
      badges.push(is_quantity_public ? { type: 'qty', qty } : { type: 'qty_hide' });
    }

    if (is_sptg) {
      badges.push({
        type: 'nft',
        name: 'NFT',
      });
    }
  }
  /* 설정기간 이전 */

  /* 가격할인 */

  /* 실시간 판매 */
  return badges;
};
