import { each, go, head, identity, noop, pipe, sel } from 'fxjs/es';
import { frameOpenSignUpIn } from '../../../User/Login/F/tab.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { makeEventCommentHtml } from '../S/tmpl.js';

const changeFileName = function (file) {
  const input = $1('#event_comment_upload input[type="file"]');
  const name = file ? file.name : '';
  if (file) window.box.push('event_comment_file', file);
  else window.box.pop('event_comment_file');
  $.html($.attr($1('#event_comment_upload span.file_name'), 'is_show', !!name), name || '');
  $.attr($1('#event_comment_upload'), 'is_close', !!name);
  setTimeout(function () {
    input.type = '';
    input.type = 'file';
  }, 0);
};

export const defnEventDetailTab = () => ({
  tab_name: 'mp.event.detail',
  infinite: function (don_tab) {
    const el_event_list = $.find1(don_tab, '.event_comment_list');
    return $.frame.infi(el_event_list, {
      limit: 12,
      off: true,
      template: makeEventCommentHtml,
      appended: function add_link(el_event_comments) {
        if (window.box.sel('is_user->_->policies->mp_worker_policy'))
          return go(
            el_event_comments,
            $.find('.user_name'),
            each(function (user_name) {
              $.append(
                user_name,
                legacyHtml`
                  <a
                    href="/${T.lang}/df_user_link?id=${box.sel(user_name).id}&table=event_comments"
                    target="_blank"
                    >유저확인</a
                  >
                `,
              );
            }),
          );
      },
      data_func: function (offset, limit) {
        return go(
          $.get('/@api/event/' + window.box.sel('event->id') + '/comments', {
            offset,
            limit,
          }),
          function (events) {
            window.box.push.apply(null, ['event->_->event_comments'].concat(events));
            return events;
          },
        );
      },
    });
  },
  appended: pipe(
    $.on2('click', 'form#event_comment #write', async function (e) {
      const form = $.closest(e.currentTarget, 'form#event_comment');
      const event = box.sel('event');
      const now = moment().format('YYYY-MM-DD HH:mm');
      if (
        now < moment(event.open_at).format('YYYY-MM-DD HH:mm') ||
        moment(event.close_at).format('YYYY-MM-DD HH:mm') < now
      )
        return $.alert(T('이벤트기간에만 댓글을 작성할 수 있습니다.'));
      const comment_val = $.val($.find1(form, 'textarea')) || '';
      if (!window.box.sel('is_user'))
        return go($.alert(T('로그인 후 이용해 주세요.')), noop, frameOpenSignUpIn);
      if (window.box.sel('is_user->type') == 'NEED_VERIFY')
        return go(
          $.confirm(T('login::Your email address is not verified2')),
          _p.if2(identity)(
            function () {
              return $.post('/@api/mp/resend_verification', {});
            },
            function (data) {
              return $.alert(!data.result ? T('event::e14') : T('event::e15'));
            },
          ),
        );
      if (comment_val.length > 1000 || comment_val.length < 5) return $.alert(T('event::e16'));
      $.don_loader_start();
      try {
        await go(
          $.upload_exif(
            {
              files: window.box.sel('event_comment_file'),
            },
            {
              max_resize: 1200,
              url: form.action,
              data: {
                event_id: window.box.sel('event->id'),
                comment: comment_val,
              },
            },
          ),
          function (r) {
            if (r == 'cancel') return;
            if (r) return location.reload();
            $.alert(T('event::e17'));
          },
        );
      } catch (err) {
        if (sel('response', err) == 'Not signed in')
          return go($.alert(T('로그인 후 이용해 주세요.')), noop, frameOpenSignUpIn);
        if (sel('response', err) == 'Unvalidated email')
          return go(
            $.confirm(T('login::Your email address is not verified2')),
            _p.if2(identity)(
              function () {
                return $.post('/@api/mp/resend_verification', {});
              },
              function (data) {
                return $.alert(!data.result ? T('event::e14') : T('event::e15'));
              },
            ),
          );
        $.alert(err.msg);
      } finally {
        $.don_loader_end();
      }
    }),
    $.on('click', '#event_comment_upload .close', function () {
      changeFileName();
    }),
    $.on('change', '#event_comment_upload input[type="file"]', function (e) {
      if ($.valid_files(e.currentTarget)) return $.alert(T('review::Accepted File Types: JPEG, PNG, GIF'));
      const file = head(e.currentTarget.files);
      if (file.size > 10485760)
        return go($.alert(T('File size is too large', { mb: 10 })), noop, changeFileName);
      return changeFileName(file);
    }),
    $.on2('click', '.event_comment_item .like_point_wrap button', async function (e) {
      try {
        await go(
          $.post('/@api/event/comment/like_point', {
            event_comment_id: box.sel(e.currentTarget).id,
          }),
          function (r) {
            if (!r.id)
              return go($.alert(T('event::e18')), function () {
                location.reload();
              });
            box.extend2(e.currentTarget, r);
            $.attr(e.currentTarget, 'selected', !!r._.event_comment_like_points.length);
            $.html($.attr($.prev(e.currentTarget, '.like_point'), 'like_point', r.like_point), r.like_point);
          },
        );
      } catch (e) {
        go($.alert(T('로그인 후 이용해 주세요.')), noop, frameOpenSignUpIn);
      }
    }),
    $.on2('click', '.event_comment_item .remove[is_me="true"] button', function (e) {
      return go(
        $.confirm(T('Are you sure you want to delete?')),
        _p.if2(identity)(
          function () {
            return $.delete('/@api/event/comment', {
              event_comment_id: window.box.sel(e.currentTarget).id,
            });
          },
          function () {
            window.location.reload();
          },
        ),
      );
    }),
  ),
});
