import { compact, go, map } from 'fxjs/es';

export function isArrayOk(arr) {
  return Array.isArray(arr) && arr.length > 0;
}

export function isEmNil(arr) {
  return arr == null || (Array.isArray(arr) && arr.length === 0);
}

export function insertValueIntoArray({ array, index, value }) {
  if (index < 0 || index > array.length) {
    throw new Error('Index out of bounds');
  }

  // side effect
  array.splice(index, 0, value);
  return array;
}

// 모든 subset_array의 요소가 main_array 에 포함되어 있는지 확인
export function isSubset(main_array, subset_array) {
  return subset_array.every((item) => main_array.includes(item));
}

export function removeValueFromArrayInPlace(arr, values_to_remove) {
  return go(
    arr,
    map((el) => (values_to_remove.includes(el) ? null : el)),
    compact,
  );
}

export function arraysAreEqual(arr1, arr2) {
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
}

export function repeatArray(arr, ctn) {
  return new Array(ctn).fill(arr).flat();
}
