import { go, html, tap } from 'fxjs/es';
import { $appendTo, $el, $find, $qs, $remove, $setCss } from 'fxdom/es';
import lottie from 'lottie-web';
import { lottieAnimationData } from './don_loader_animaiton_data.js';

export const loaderStart = (parent, opt, timeout) => {
  const before_don_loader_wrap_el = $qs('.don_loader_wrap');
  if (before_don_loader_wrap_el) {
    return;
  }
  return go(
    html`<div class="don_loader_wrap">
      <div class="don_loader_img_plus_logo"></div>
    </div>`,
    $el,
    $setCss(opt),
    $appendTo(parent || $qs('body')),
    (don_loader_wrapper) => {
      don_loader_wrapper.__lottie__ = lottie.loadAnimation({
        container: $find('.don_loader_img_plus_logo', don_loader_wrapper), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: lottieAnimationData,
      });
      don_loader_wrapper.__lottie__.play();
      return don_loader_wrapper;
    },
    tap(() => {
      if (timeout) {
        setTimeout(() => {
          $.don_loader_end(parent);
        }, timeout);
      }
    }),
  );
};
export const loaderEnd = (parent) => {
  const don_loader_wrapper = $find('.don_loader_wrap', parent || $1('body'));
  if (don_loader_wrapper) {
    if (don_loader_wrapper?.__lottie__) {
      don_loader_wrapper?.__lottie__.destroy();
    }
    return $remove(don_loader_wrapper);
  }
};
export const initLoader = () => {
  if ($1('body.omp')) {
    $.don_loader_start = loaderStart;
    $.don_loader_end = loaderEnd;
  }
};
