import { go } from 'fxjs/es';
import { makeEventItemHtml } from '../S/tmpl.js';

export const defnEventListTab = () => ({
  tab_name: 'mp.event.list',
  infinite: function (don_tab) {
    const el_event_list = $.find1(don_tab, '.event_list');
    return $.frame.infi(el_event_list, {
      limit: 12,
      off: true,
      template: makeEventItemHtml,
      data_func: function (offset, limit) {
        return go($.get('/@api/event/list', { offset, limit }), function (events) {
          window.box.push.apply(null, ['event_list'].concat(events));
          return events;
        });
      },
    });
  },
});
