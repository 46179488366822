import { each, go, isNil, reject, takeL, tap } from 'fxjs/es';
import { $findAll, $on, $setCss } from 'fxdom/es';
import { VectorEditorKeyringFreeCreatorPCPathEditorMuiS } from '../../S/Mui/module/VectorEditorKeyringFreeCreatorPCPathEditorMuiS.js';
import { VectorEditorKeyringFreeCreatorPCPathEditorF } from '../Function/module/VectorEditorKeyringFreeCreatorPCPathEditorF.js';
import { PCRightFixedPanelF } from '../../../../../../../PCRightFixedPanel/F/Function/module/PCRightFixedPanelF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorKeyringFreeCreatorPCPathEditorMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_left_menu_els = tab_el.__mp_left_menu_els ?? [];
    tab_el.__mp_prev_frame_right_panel_el = tab_el.__mp_prev_frame_right_panel_el ?? null;
    tab_el.__mp_frame_position_top = tab_el.__mp_frame_position_top ?? 0;
    tab_el.__mp_frame_position_left = tab_el.__mp_frame_position_left ?? 0;
    tab_el.__mp_frame_position_width = tab_el.__mp_frame_position_width ?? 0;
    tab_el.__mp_frame_position_height = tab_el.__mp_frame_position_height ?? 0;
    tab_el.__mp_keyring_editor = tab_el.__mp_keyring_editor ?? null;
    tab_el.__mp_path_el = tab_el.__mp_path_el ?? null;

    go(
      tab_el,

      tap(
        $findAll(`.button_container .done`),
        each($on('click', VectorEditorKeyringFreeCreatorPCPathEditorF.handleBottomDoneButtonClick())),
      ),

      tap(
        $findAll(`.wrapper .add_l`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeCreatorPCPathEditorF.handlePathEditorAddLButtonClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.wrapper .add_c`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeCreatorPCPathEditorF.handlePathEditorAddCButtonClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.wrapper .remove`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeCreatorPCPathEditorF.handlePathEditorRemoveButtonClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.wrapper .close_or_open`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeCreatorPCPathEditorF.handlePathEditorCloseOrOpenButtonClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.wrapper .change_to_l`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeCreatorPCPathEditorF.handlePathEditorChangeToLButtonClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.wrapper .change_to_c`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeCreatorPCPathEditorF.handlePathEditorChangeToCButtonClick({ tab_el }),
          ),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  async showed(tab_el) {
    $setCss(['top', `${tab_el.__mp_frame_position_top}`])(tab_el);
    $setCss(['left', `${tab_el.__mp_frame_position_left}`])(tab_el);
    $setCss(['width', `${tab_el.__mp_frame_position_width}`])(tab_el);
    $setCss(['height', `${tab_el.__mp_frame_position_height}`])(tab_el);

    each((el) => (el.dataset.is_show = 'false'))(tab_el.__mp_left_menu_els);
    await go(
      tab_el,
      $findAll(`.container`),
      takeL(1),
      each((container_el) => {
        if (tab_el.__mp_prev_frame_right_panel_el != null) {
          return PCRightFixedPanelF.showPcRightFixedPanel({
            right_el: container_el,
            left_el: tab_el.__mp_prev_frame_right_panel_el,
          });
        }
      }),
    );

    if (tab_el.__mp_path_el != null) {
      tab_el.__mp_keyring_editor?.startEditPath(tab_el.__mp_path_el);
      const is_closed = (tab_el.__mp_keyring_editor?.getClosePathCommandList().length ?? 0) > 0;
      go(
        tab_el,
        $findAll(`.wrapper .close_or_open`),
        each((el) => (el.dataset.is_closed = `${is_closed}`)),
      );
    }
    return new Promise((resolve) => setTimeout(resolve, 300));
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {
    if (tab_el.__mp_path_el != null) {
      tab_el.__mp_keyring_editor?.turnOffArtBoardClipPath();
    }
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {
    tab_el.__mp_keyring_editor?.endEditPath();
    tab_el.__mp_keyring_editor?.selectEls(reject(isNil)([tab_el.__mp_path_el]));

    each((el) => (el.dataset.is_show = 'true'))(tab_el.__mp_left_menu_els);
    return go(
      tab_el,
      $findAll(`.container`),
      takeL(1),
      each((container_el) => {
        if (tab_el.__mp_prev_frame_right_panel_el != null) {
          return PCRightFixedPanelF.hidePcRightFixedPanel({
            right_el: container_el,
            left_el: tab_el.__mp_prev_frame_right_panel_el,
          });
        }
      }),
    );
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {
    tab_el.__mp_left_menu_els = [];
    tab_el.__mp_prev_frame_right_panel_el = null;
    tab_el.__mp_frame_position_top = 0;
    tab_el.__mp_frame_position_left = 0;
    tab_el.__mp_frame_position_width = 0;
    tab_el.__mp_frame_position_height = 0;
    tab_el.__mp_single_sticker_editor = null;
    tab_el.__mp_path_el = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
