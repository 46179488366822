import { each, equals2, flatMap, go, isNil } from 'fxjs/es';
import { CommonNS } from '@marpple/sticker-editor';
import { VectorEditorStickerSinglePCF } from '../../../../Single/PC/F/Function/module/VectorEditorStickerSinglePCF.js';
import { $find, $findAll, $scrollTop, $setScrollTop } from 'fxdom/es';

export const handleRightPanelSelectBackClick =
  ({ tab_el }) =>
  () => {
    tab_el.__mp_free_sticker_editor?.selectEls([]);
  };

export const handleRightPanelSelectEditClick =
  ({ tab_el }) =>
  async () => {
    if ($scrollTop(window) > 30) {
      await window.anime({
        targets: 'html, body',
        scrollTop: 0,
        duration: 400,
        easing: 'easeInOutCubic',
      }).finished;
    } else {
      $setScrollTop(0)(window);
    }

    const right_wrapper_el = $find(`.right_container .right_wrapper`)(tab_el);
    if (isNil(right_wrapper_el)) {
      return;
    }

    const el = tab_el?.__mp_free_sticker_editor?.getSelectedEls?.()?.[0];
    if (el == null) {
      $.alert(T('modules::VectorEditor::Sticker::message::선택된 스티커가 없습니다.'));
      return;
    }
    const { id, target_image_el } = el;

    const clone_target_image_el = await (async () => {
      $.don_loader_start();
      try {
        return await CommonNS.UtilNS.deepCloneNode(target_image_el);
      } finally {
        $.don_loader_end();
      }
    })();

    const { fill, opacity } = (() => {
      const background_vo = tab_el.__mp_free_sticker_editor?.getBackground();
      if (!equals2(background_vo?.value?.type)('color')) {
        tab_el.__mp_free_sticker_editor?.setBackground({
          type: 'color',
          fill: '#ffffff',
          opacity: 1,
        });
        return { fill: '#ffffff', opacity: 1 };
      }
      return background_vo.value;
    })();

    let is_added = false;
    await VectorEditorStickerSinglePCF.makeSticker({
      title: tab_el.__mp_title,
      price: tab_el.__mp_price,
      frame_position: { top: tab_el.__mp_frame_position_top, height: tab_el.__mp_frame_position_height },
      prev_frame_right_panel: right_wrapper_el,
      art_board_size: tab_el.__mp_single_art_board_size,
      foreground_el: null,
      empty_template_el: tab_el.__mp_single_empty_template_svg_el?.cloneNode?.(true) ?? null,
      is_need_cutting_line: true,
      can_set_background: false,
      background: { fill, opacity },
      onFrameHiding: () => {
        (tab_el?.tab_opt ?? {}).tab_top = $scrollTop(window);
      },
      preProcess: (single_sticker_editor) => {
        go(
          clone_target_image_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="working-layer"]`),
          flatMap((layer_el) => layer_el.children),
          each((el) => single_sticker_editor.addEl(el)),
        );
      },
      postProcess: ({ target_image_el, path_data }) => {
        tab_el.__mp_free_sticker_editor.removeEl(id);
        const { id: new_id, wrapper_el } = tab_el.__mp_free_sticker_editor.addEl({
          target_image_el,
          cutting_line_path_data: path_data,
        });
        is_added = true;
        tab_el.__mp_free_sticker_editor.selectEls([new_id]);

        const wrapper_bbox = wrapper_el.getBBox();
        let wrapper_center_point = target_image_el.createSVGPoint();
        wrapper_center_point.x = wrapper_bbox.x + wrapper_bbox.width / 2;
        wrapper_center_point.y = wrapper_bbox.y + wrapper_bbox.height / 2;
        const wrapper_consolidated_transform_matrix =
          CommonNS.UtilNS.getConsolidatedTransformMatrix(wrapper_el) ?? target_image_el.createSVGMatrix();
        wrapper_center_point = wrapper_center_point.matrixTransform(wrapper_consolidated_transform_matrix);

        const art_board_center_x = tab_el.__mp_art_board_size.width / 2;
        const art_board_center_y = tab_el.__mp_art_board_size.height / 2;

        const tx = art_board_center_x - wrapper_center_point.x;
        const ty = art_board_center_y - wrapper_center_point.y;

        tab_el?.__mp_free_sticker_editor?.translateEl?.({ id: new_id, tx, ty });
      },
    });
    if (is_added) {
      go(
        tab_el,
        $findAll(`.new_sticker_button`),
        each((el) => (el.dataset.is_show = 'true')),
      );
    }
    if (is_added && tab_el.__mp_free_template_svg_el != null) {
      tab_el.__mp_free_template_svg_el.dataset.is_show = 'false';
    }
  };
