import axios from 'axios';
import { $qs, $delegate, $find, $findAll, $hide, $remove, $setHTML, $setText, $show } from 'fxdom/es';
import { each, go, groupBy, map, pluck } from 'fxjs/es';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPProductListTmplS } from '../../S/Tmpl/module/OMPProductListTmplS.js';

export const delegateOpenCateSelector = (cate_lists_el) =>
  $delegate('click', '.omp-product-list__checked-cate-list', (e) => {
    if (e.currentTarget.dataset.open == 'true') {
      $hide(cate_lists_el);
      e.currentTarget.dataset.open = false;
    } else {
      $show(cate_lists_el);
      e.currentTarget.dataset.open = true;
    }
  });

export const checkedCateItem = ({ cate_list_id, cate_item_id }) => {
  const checked_cate_list = box.sel('cate_lists').find(({ id }) => {
    return id == cate_list_id;
  });
  const checked_cate_item = checked_cate_list?._.cate_items.find(({ id }) => {
    return id == cate_item_id;
  });
  return { checked_cate_list, checked_cate_item };
};

export const setFilterOpenBtnCount = (tab_el, count) => {
  const filter_open_count_el = $find('.opm-product-list-checked-filter-count', tab_el);
  filter_open_count_el.dataset.count = count;
  $setText(`(${count})`, filter_open_count_el);
  return tab_el;
};

export const axiosGetBps = (params) => {
  const list_bps$ = $qs('.opm-product-list-bps');
  OMPCoreUtilF.loaderStart(list_bps$);
  return go(
    axios.get(`/${TT.lang}/@api/product/list`, {
      params,
    }),
    ({ data }) => {
      OMPCoreUtilF.loaderEnd(list_bps$);
      return data;
    },
  );
};

export const axiosGetFilters = (params) => {
  return go(
    axios.get(`/${TT.lang}/@api/filter/list`, {
      params,
    }),
    ({ data }) => data,
  );
};

export const makeQueryWithFilterModal = ({ checked_filter_items, product_list_tap_el, query }) => {
  const check_filter_items$ = $find('.opm-product-list-checked-filter-items', product_list_tap_el);
  const check_filter_length = checked_filter_items.length;
  const list_body$ = $find('.opm-product-list-bps', product_list_tap_el);
  if ((check_filter_items$.dataset.list_show == 'true') != check_filter_length > 0) {
    $setHTML('', list_body$);
  }
  $setHTML(OMPProductListTmplS.checkedFilterItemsWithReset(checked_filter_items), check_filter_items$);
  check_filter_items$.dataset.list_show = check_filter_length > 0;
  list_body$.dataset.filter_show = check_filter_length > 0;
  setFilterOpenBtnCount(product_list_tap_el, check_filter_length);
  const filters = go(
    checked_filter_items,
    groupBy(({ filter_list_id }) => {
      return `f_${filter_list_id}[]`;
    }),
    Object.entries,
    map(([name, filters]) => {
      return [name, pluck('id', filters)];
    }),
    Object.fromEntries,
  );

  return {
    ...query,
    ...filters,
  };
};

export const resetWithFilterModal = (product_list_tap_el) => {
  if (product_list_tap_el.tab_opt.bp_filters) {
    $setHTML('', $find('.opm-product-list-bps', product_list_tap_el));
    each(
      $remove,
      $findAll(
        '.opm-product-list-checked-filter-items .omp-product-list__checked-filter-item',
        product_list_tap_el,
      ),
    );
    setFilterOpenBtnCount(product_list_tap_el, 0);
    product_list_tap_el.tab_opt.bp_filters = null;
    $find('.opm-product-list-checked-filter-items', product_list_tap_el).dataset.list_show = false;
    $find('.opm-product-list-bps', product_list_tap_el).dataset.filter_show = false;
  }
};
