export const CONFIRM_HAS_NOT_DESIGNED_FACE = 'CONFIRM_HAS_NOT_DESIGNED_FACE';
export const UNABLE_UNDO_REDO = 'UNABLE_UNDO_REDO';
export const DIFFERENT_DELIVERY_FEE = 'DIFFERENT_DELIVERY_FEE';
export const WHEN_CHANGE_REMOVE_DESIGN = 'WHEN_CHANGE_REMOVE_DESIGN';
export const NOT_SHOW_ADD_COLOR = 'NOT_SHOW_ADD_COLOR';
export const NOT_WORKING_COPY_AND_PASTE = 'NOT_WORKING_COPY_AND_PASTE';
export const LINK_TO_BP_ID = 'LINK_TO_BP_ID';
export const DOSU_1_OR_2 = 'DOSU_1_OR_2';
export const NO_REQUEST_BG_REMOVAL = 'NO_REQUEST_BG_REMOVAL';
export const COMPOSITE_BPC_COLOR_CODE2 = 'COMPOSITE_BPC_COLOR_CODE2';
export const PDF_FILE_POSSIBLE = 'PDF_FILE_POSSIBLE';
export const BP_OPTION_PREVIEWS = 'BP_OPTION_PREVIEWS';
/*
 * [{bp_option_ids:[], tmpl:string}]
 * */
export const ALL_FACES_SINGLE_COLOR = 'ALL_FACES_SINGLE_COLOR';
export const BP_OPTION_MAKER_MATERIAL = 'BP_OPTION_MAKER_MATERIAL';
export const NEED_PRINT_IMAGE_URL = 'NEED_PRINT_IMAGE_URL';
