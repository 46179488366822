import { StockLabelFormat } from '../../../Stock/zpl_module/zpl_class.js';
import { writeToZPLdata } from '../../../Stock/zpl_module/zpl_command_utils.js';

export function createLabelFormatObj({ label_width, path, dpi, home_pos, is_rotate }) {
  const format = new StockLabelFormat(path, dpi, home_pos.x, home_pos.y, is_rotate, label_width);
  format.setDefaultFont('0', 20);
  return format;
}

export async function setFormatZplToDevice({ device, format }) {
  const format_zpl = format.toDownloadFormat();
  await writeToZPLdata(device, format_zpl);
}
