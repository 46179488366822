import {
  each,
  eachL,
  equals2,
  flatMapL,
  go,
  isNil,
  mapL,
  pipe,
  reduce,
  rejectL,
  some,
  takeL,
  tap,
} from 'fxjs/es';
import { $findAll, $on, $scrollTop, $setCss, $setScrollTop } from 'fxdom/es';
import { EditorNS } from '@marpple/sticker-editor';
import { VectorEditorStickerGridCreatorPCMuiS } from '../../S/Mui/module/VectorEditorStickerGridCreatorPCMuiS.js';
import { VectorEditorStickerGridCreatorPCEventF } from '../Event/module/VectorEditorStickerGridCreatorPCEventF.js';
import { VectorEditorSettingBackgroundPCF } from '../../../../../SettingBackground/PC/F/Function/module/VectorEditorSettingBackgroundPCF.js';
import { VectorEditorStickerSingleCreatorPCF } from '../../../../Single/CreatorPC/F/Function/module/VectorEditorStickerSingleCreatorPCF.js';
import { VectorEditorTopMenuPCF } from '../../../../../TopMenu/PC/F/Function/module/VectorEditorTopMenuPCF.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';
import { VectorEditorSettingBackgroundPCEventF } from '../../../../../SettingBackground/PC/F/Event/module/VectorEditorSettingBackgroundPCEventF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerGridCreatorPCMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_title = tab_el.__mp_title ?? '';
    tab_el.__mp_price = tab_el.__mp_price ?? '';
    tab_el.__mp_art_board_size = {
      width: tab_el.__mp_art_board_size?.width ?? 0,
      height: tab_el.__mp_art_board_size?.height ?? 0,
    };
    tab_el.__mp_background_color = tab_el.__mp_background_color ?? 'none';
    tab_el.__mp_background_opacity = tab_el.__mp_background_opacity ?? 1;
    tab_el.__mp_grids = tab_el.__mp_grids ?? new Map();
    tab_el.__mp_preProcess = tab_el.__mp_preProcess ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_grid_sticker_editor = null;
    tab_el.__mp_selected_grid_id = null;

    go(
      tab_el.__mp_grids.values(),
      eachL(
        ({
          id,
          x,
          y,
          width,
          height,
          empty_grid_el,
          existing_grid_el,
          selector_grid_el,
          copy_destination_unselected_empty_grid_el,
          copy_destination_unselected_existing_grid_el,
          copy_destination_selected_grid_el,
          foreground_el,
        }) => {
          each($setCss(['cursor', 'pointer']))([
            empty_grid_el,
            existing_grid_el,
            selector_grid_el,
            copy_destination_unselected_empty_grid_el,
            copy_destination_unselected_existing_grid_el,
            copy_destination_selected_grid_el,
          ]);

          $on('click', async () => {
            const { fill: background_fill, opacity: background_opacity } = (() => {
              const background_vo = tab_el.__mp_grid_sticker_editor?.getBackground?.();
              if (isNil(background_vo)) {
                return { fill: 'none', opacity: 1 };
              }
              const { value } = background_vo;
              if (isNil(value)) {
                return { fill: 'none', opacity: 1 };
              }
              const { type, fill, opacity } = value;
              if (!equals2(type)('color')) {
                return { fill: 'none', opacity: 1 };
              }
              return { fill, opacity };
            })();

            if ($scrollTop(window) > 30) {
              await window.anime({
                targets: 'html, body',
                scrollTop: 0,
                duration: 400,
                easing: 'easeInOutCubic',
              }).finished;
            } else {
              $setScrollTop(0)(window);
            }

            return VectorEditorStickerSingleCreatorPCF.makeSticker({
              title: tab_el.__mp_title,
              price: tab_el.__mp_price,
              art_board_size: { width, height },
              foreground_el,
              is_need_cutting_line: false,
              can_set_background: true,
              background: { fill: background_fill, opacity: background_opacity },
              postProcess: async (single_sticker_editor) => {
                if (equals2(single_sticker_editor.getGraphicsEls().size)(0)) {
                  const error = new Error('the sticker is empty.');
                  error.__mp_alert_message = T(
                    'modules::VectorEditor::Sticker::message::빈 스티커입니다. 스티커를 만들어주세요 :)',
                  );
                  throw error;
                }

                const target_image_el = await (async () => {
                  $.don_loader_start();
                  try {
                    return (
                      await single_sticker_editor.exportEditor({
                        factor: 1,
                        container_el: document.body,
                      })
                    ).with_background_el;
                  } finally {
                    $.don_loader_end();
                  }
                })();

                target_image_el.setAttributeNS(null, 'x', `${x}`);
                target_image_el.setAttributeNS(null, 'y', `${y}`);
                tab_el.__mp_grid_sticker_editor?.setTargetImage({
                  id,
                  target_image_el,
                });
                tab_el.__mp_grid_sticker_editor?.selectGrid(id);
              },
            });
          })(empty_grid_el);

          $on('click', () => tab_el.__mp_grid_sticker_editor?.selectGrid(id))(existing_grid_el);

          each($on('click', () => tab_el.__mp_grid_sticker_editor?.selectCopyDestination(id)))([
            copy_destination_unselected_empty_grid_el,
            copy_destination_unselected_existing_grid_el,
          ]);

          $on('click', () => tab_el.__mp_grid_sticker_editor?.unselectCopyDestination(id))(
            copy_destination_selected_grid_el,
          );
        },
      ),
      flatMapL(
        ({
          existing_grid_el,
          empty_grid_el,
          copy_source_grid_el,
          copy_destination_unselected_empty_grid_el,
          copy_destination_unselected_existing_grid_el,
          copy_destination_selected_grid_el,
        }) => [
          existing_grid_el,
          empty_grid_el,
          copy_source_grid_el,
          copy_destination_unselected_empty_grid_el,
          copy_destination_unselected_existing_grid_el,
          copy_destination_selected_grid_el,
        ],
      ),
      (iter) =>
        reduce(
          (s, el) => {
            s.add(el);
            return s;
          },
          new Set(),
          iter,
        ),
      (ignore_els) =>
        go(
          tab_el,
          $findAll('.editor_container'),
          each(
            $on('click', ({ target }) => {
              if (some((el) => el.contains(target))(ignore_els)) {
                return;
              }
              tab_el.__mp_grid_sticker_editor?.selectGrid(null);
              tab_el.__mp_selected_grid_id = null;
            }),
          ),
        ),
    );

    go(
      tab_el,
      $findAll(`.top_menu_container .top_menus`),
      each(VectorEditorTopMenuPCF.hideToggleLayersModeIcon),
    );

    go(
      tab_el,

      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID}"]`,
        ),
        each($on('click', VectorEditorStickerGridCreatorPCEventF.handleTopMenuDeleteClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID}"]`,
        ),
        each($on('click', VectorEditorStickerGridCreatorPCEventF.handleTopMenuDuplicateClick({ tab_el }))),
      ),

      tap(
        $findAll(`.right_container .right_wrapper .button_container .cancel`),
        each($on('click', VectorEditorStickerGridCreatorPCEventF.handleRightCancelButtonClick)),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .button_container .done`),
        each($on('click', VectorEditorStickerGridCreatorPCEventF.handleRightDoneButtonClick({ tab_el }))),
      ),

      tap(
        $findAll(`.right_container .right_wrapper .panel_container .right_panel.right_panel_home`),
        each(
          pipe(
            tap((el) => VectorEditorSettingBackgroundPCF.setTitle({ el, title: tab_el.__mp_title })),
            tap((el) =>
              VectorEditorSettingBackgroundPCEventF.addColorSelectorNotEtcClickEventHandler({
                el,
                f: VectorEditorStickerGridCreatorPCEventF.handleRightPanelHomeColorSelectorNotEtcClick({
                  tab_el,
                }),
              }),
            ),
            tap((el) =>
              VectorEditorSettingBackgroundPCEventF.addColorSelectorEtcClickEventHandler({
                el,
                ...VectorEditorStickerGridCreatorPCEventF.handleRightPanelHomeColorSelectorEtcClick({
                  tab_el,
                }),
              }),
            ),
            tap((el) =>
              VectorEditorSettingBackgroundPCF.setColor({ el, color_code: tab_el.__mp_background_color }),
            ),
          ),
        ),
      ),

      tap(
        $findAll(`.right_container .right_wrapper .panel_container .right_panel.right_panel_select`),
        each(
          pipe(
            tap(
              $findAll(`.header .back`),
              each(
                $on(
                  'click',
                  VectorEditorStickerGridCreatorPCEventF.handleRightPanelSelectBackButtonClick({ tab_el }),
                ),
              ),
            ),
            tap(
              $findAll(`.edit`),
              each(
                $on(
                  'click',
                  VectorEditorStickerGridCreatorPCEventF.handleRightPanelSelectEditButtonClick({ tab_el }),
                ),
              ),
            ),
          ),
        ),
      ),

      tap(
        $on(
          '@mp/sticker-editor/select',
          VectorEditorStickerGridCreatorPCEventF.handleGridStickerEditorSelect({ tab_el }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/unselect',
          VectorEditorStickerGridCreatorPCEventF.handleGridStickerEditorUnselect({ tab_el }),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    const grid_sticker_editor = new EditorNS.GridStickerEditorNS.PCNS.GridStickerPCEditor({
      window,
      document,
    });
    tab_el.__mp_grid_sticker_editor = grid_sticker_editor;
    grid_sticker_editor.initDOM();
    grid_sticker_editor.initEditor();
    grid_sticker_editor.setBackground({
      type: 'color',
      fill: tab_el.__mp_background_color,
      opacity: tab_el.__mp_background_opacity,
    });
    grid_sticker_editor.setArtBoardSize({
      width: tab_el.__mp_art_board_size.width,
      height: tab_el.__mp_art_board_size.height,
    });
    go(
      tab_el,
      $findAll(`.editor_container`),
      takeL(1),
      each((el) => grid_sticker_editor.appendTo(el)),
    );
    each((grid) => grid_sticker_editor.addGrid(grid))(tab_el.__mp_grids.values());
    return tab_el.__mp_preProcess?.(grid_sticker_editor);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    tab_el.__mp_grid_sticker_editor?.fitSize();

    go(
      tab_el,
      $findAll(`.top_menu_container`),
      mapL((el) => el.getBoundingClientRect().height),
      mapL((top_menu_height) =>
        tab_el.__mp_grid_sticker_editor?.calculateViewBoxFitToArtBoard({
          padding_in_vcs: { top: top_menu_height + 80, bottom: 80, left: 300, right: 300 },
        }),
      ),
      rejectL(isNil),
      takeL(1),
      each((view_box_vo) => tab_el.__mp_grid_sticker_editor?.setViewBox(view_box_vo)),
    );
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {
    tab_el.__mp_grid_sticker_editor?.selectGrid?.(null);
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {
    tab_el.__mp_title = '';
    tab_el.__mp_price = '';
    tab_el.__mp_art_board_size = { width: 0, height: 0 };
    tab_el.__mp_background_color = 'none';
    tab_el.__mp_background_opacity = 1;
    tab_el.__mp_grids = new Map();
    tab_el.__mp_preProcess = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_grid_sticker_editor?.destroy();
    tab_el.__mp_grid_sticker_editor = null;
    tab_el.__mp_selected_grid_id = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
