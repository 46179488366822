import { reject, filter, find, go, html, partition, map, sel, strMap } from 'fxjs/es';
import { DfOMPNavigationMenuConstantS } from '../../../../../../Df/OMP/NavigationMenu/S/Constant/module/DfOMPNavigationMenuConstantS.js';
import { PriceS } from '../../../../../../Price/S/Function/module/PriceS.js';
import { UtilImageS } from '../../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { OMPCoreAtomTmplS } from '../../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreLayoutTmplS } from '../../../S/Tmpl/module/OMPCoreLayoutTmplS.js';

export const categoryProductItemHtml = (item) => {
  return html`
    <a href="/${T.lang}/product/detail?bp_id=${item.base_product_id}">
      <div class="omp-mp-navigation__item__product">
        <div class="omp-mp-navigation__item__product__img-wrap">
          <img
            class="omp-mp-navigation__item__product__img"
            src="${UtilImageS.getResized70Webp(
              item._.base_products._.base_product_color.product_thumb_url ||
                item._.base_products['model_thumb_url' + G._en],
              53,
            )}"
            alt="${item.base_product_id}"
          />
        </div>

        <div class="omp-mp-navigation__item__product__texts">
          <div class="omp-mp-navigation__item__product__text name">
            ${item._.base_products['name' + G._en]}
          </div>
          <div class="omp-mp-navigation__item__product__text price">
            ${PriceS.pricify(
              PriceS.add(item._.base_products['price' + G._en], item._.base_products['print_price' + G._en]),
            )}${TT('navigation::navigation_01')}
          </div>
        </div>
      </div>
    </a>
  `;
};

export const categoryTextItemHtml = (item) => {
  const qs = item.url.includes('?') ? '&' : '?';
  return `<a class="omp-mp-navigation__item__url" href="/${T.lang}${item.url}${qs}mp_navigation_section_category_item_id=${item.id}">${item.title}</a> `;
};
export const categoryCateItemItemHtml = (item) => {
  return `<a class="omp-mp-navigation__item__url" href="${item.link.href}">${item.name}</a> `;
};
export const categoryColorItemHtml = (item) => {
  return `
    <div class="omp-mp-navigation__item__color">
      <a class="omp-mp-navigation__item__url" href="/${T.lang}/product/list/${item.cate_list_id}?f_color%5B%5D=${item.id}">
        <div
          class="omp-mp-navigation__item__color__circle-wrap">
          <div  data-color-code="${item.color_code}" class="omp-mp-navigation__item__color__circle"  style="background-color : ${item.color_code}">
          </div>
        </div>
      </a>
    </div>
  `;
};

export const cateItemsAllProductsHtml = (sections) => {
  return `
      <div class="omp-mp-navigation__items-cate">
        <a class="omp-mp-navigation__item__url" href="${sections.cate_lists.link.href}">  ${TT(
    'navigation::navigation_02',
  )}
        </a>
        ${strMap(categoryCateItemItemHtml, sections.cate_lists._.cate_items)}
      </div>`;
};

export const categoryTitleHtml = ({ sections, categories, is_all_cate }) => {
  return categories.type === 'BANNER'
    ? strMap((items) => {
        const qs = items.url.includes('?') ? '&' : '?';
        return html`
          <a
            href="/${T.lang}${items.url}${qs}mp_navigation_section_category_item_id=${items.id}"
            class="omp-mp-navigation__item__banner"
          >
            <img
              src="${UtilImageS.getResizedUrl({ url: items.banner_img_url, width: 288, format: 'webp' })}"
              alt="${items.title}"
              class="omp-mp-navigation__item__banner-img"
            />
          </a>

          <div class="omp-mp-navigation__category__title">${categories.title}</div>
          <div class="omp-mp-navigation__item__banner-desc">${items.desc}</div>
        `;
      }, categories._.mp_navigation_section_category_items)
    : categories.type === 'LINK'
    ? `<a class="omp-mp-navigation__category__title" href="/${T.lang}${
        categories._.mp_navigation_section_category_items[0].url
      }" >${categories.title}
${OMPCoreAtomTmplS.navLinkIcon()}</a>`
    : categories.type === 'CATE_ITEMS'
    ? `<div class="omp-mp-navigation__category__title">${categories.title} ${
        categories.mobile_title || ''
      }</div>
          ${sections.all_cate_mobile_html || cateItemsAllProductsHtml(sections)}
        `
    : is_all_cate
    ? `<a class="omp-mp-navigation__category__title" href="/${T.lang}${
        categories._.mp_navigation_section_category_items[0].url.split('?')[0]
      }" >${categories.title} ${OMPCoreAtomTmplS.navLinkIcon()}</a>`
    : `<div class="omp-mp-navigation__category__title">${categories.title}</div> `;
};
const categoryContentHtml = (e, sections, is_all_cate, cate_colors) => {
  if (e.type === 'PRODUCT') {
    return html`<div class="omp-mp-navigation__items-products">
      ${strMap(categoryProductItemHtml, sel('_.mp_navigation_section_category_items', e))}
    </div>`;
  } else if (e.type === 'TEXT') {
    return html`<div class="omp-mp-navigation__items ${is_all_cate ? 'all-category-type-text' : ''}">
      ${strMap(categoryTextItemHtml, sel('_.mp_navigation_section_category_items', e))}
    </div>`;
  } else if (e.type === 'COLOR') {
    return html`<div class="omp-mp-navigation__items">
      <div class="omp-mp-navigation__items__color-wrap">${strMap(categoryColorItemHtml, cate_colors)}</div>
    </div>`;
  } else {
    return '';
  }
};

export const sectionHoverHtml = ({ sections, cate_colors }) => {
  const is_all_cate = !sections.cate_lists;

  const [other_type, link_type] = go(
    sections,
    sel('_.mp_navigation_section_categories'),
    partition((s) => s.type !== 'LINK'),
  );
  const content = strMap((e) => {
    return html`
      <div class="omp-mp-navigation__link-category">
        ${categoryTitleHtml({ sections, categories: e, is_all_cate })}
      </div>
    `;
  }, link_type);

  return html`
    <div class="omp-mp-navigation__section ${is_all_cate ? 'all-section' : ''}">
      ${strMap((e) => {
        return html`
          <div
            class="${e.type === 'BANNER'
              ? 'omp-mp-navigation__banner-category'
              : 'omp-mp-navigation__category'}"
          >
            ${categoryTitleHtml({ sections, categories: e, is_all_cate })}
            ${categoryContentHtml(e, sections, is_all_cate, cate_colors)}
          </div>
        `;
      }, other_type)}

      <div class="omp-mp-navigation__link-wrapper">${content}</div>
    </div>
  `;
};

export const makeNavi = ({ cate_list_id, is_mobile, mp_navigations, cate_lists, login_user }) => {
  const main_navigation = is_mobile
    ? {
        id: DfOMPNavigationMenuConstantS.allCategoryNavigationMenuIDByLang.kr[1],
        id_en: DfOMPNavigationMenuConstantS.allCategoryNavigationMenuIDByLang.en[1],
        id_jp: DfOMPNavigationMenuConstantS.allCategoryNavigationMenuIDByLang.jp[1],
      }
    : {
        id: DfOMPNavigationMenuConstantS.allCategoryNavigationMenuIDByLang.kr[0],
        id_en: DfOMPNavigationMenuConstantS.allCategoryNavigationMenuIDByLang.en[0],
        id_jp: DfOMPNavigationMenuConstantS.allCategoryNavigationMenuIDByLang.jp[0],
      };

  const mp_navigation = cate_list_id
    ? go(
        mp_navigations,
        find(
          ({ cate_list_id: _cate_list_id, is_mobile: _is_mobile }) =>
            _cate_list_id == cate_list_id && _is_mobile === is_mobile,
        ),
      )
    : go(
        mp_navigations,
        find(({ id }) => id == main_navigation['id' + G._en]),
      );

  const with_color_chips = go(
    mp_navigation,
    sel('_.mp_navigation_sections'),
    map((section) => {
      return {
        ...section,
        cate_lists: go(
          cate_lists,
          find(({ id }) => id == cate_list_id),
        ),
      };
    }),
  );

  const sections = is_mobile
    ? go(
        with_color_chips,
        filter((section) => {
          const filtering_cate = go(
            section,
            sel('_.mp_navigation_section_categories'),
            reject((category) => category.type === 'PRODUCT' || category.type === 'BANNER'),
            map((category) => {
              if (category.type === 'CATE_ITEMS') {
                section.all_cate_mobile_html = `
                    <div class="omp-mp-navigation__items-cate">
                      <a class="omp-mp-navigation__item-wrap" href="${section.cate_lists.link.href}">
                        <img class="omp-mp-navigation__item-wrap__img" alt="${
                          section.cate_lists.link.href
                        }" src="${section.cate_lists.mp_thumbnail_url}" />
                        <div class="omp-mp-navigation__item-wrap__text">${TT(
                          'navigation::navigation_02',
                        )}</div>
                      </a>
                      ${strMap(
                        (item) => `
                          <a class="omp-mp-navigation__item-wrap" href="${item.link.href}">
                            <img class="omp-mp-navigation__item-wrap__img" alt="${item.name}" src="${item.mp_thumbnail_url}" />
                            <div class="omp-mp-navigation__item-wrap__text">${item.name}</div>
                          </a>`,
                        section.cate_lists._.cate_items,
                      )}
                    </div>`;
                category.mobile_title = TT('navigation::navigation_03');
              }
              return { ...section };
            }),
          );
          if (filtering_cate.length) return filtering_cate;
        }),
      )
    : with_color_chips;

  if (!sections?.length) return '';

  if (!is_mobile) {
    const description_el = () => {
      if (cate_list_id) {
        return html`
          <div class="omp-mp-navigation__description">
            <div class="omp-mp-navigation__description__text">${mp_navigation?.desc || ''}</div>
          </div>
        `;
      } else {
        return html`
          <div class="omp-mp-navigation__description omp-mp-navigation__description--menu">
            ${OMPCoreLayoutTmplS.leftHTML({
              locals: {
                login_user,
              },
            })}
          </div>
        `;
      }
    };

    const last_section = sections.pop();

    return html`
      <div class="omp-mp-navigation">
        <div class="omp-mp-navigation__wrap">
          <div class="omp-mp-navigation__left">${description_el()}</div>
          <div class="omp-mp-navigation__center">
            ${strMap(
              (section) => sectionHoverHtml({ sections: section, cate_colors: mp_navigation._.cate_colors }),
              sections,
            )}
          </div>
          <div class="omp-mp-navigation__right">
            ${sectionHoverHtml({ sections: last_section, cate_colors: mp_navigation._.cate_colors })}
          </div>
        </div>
      </div>
    `;
  } else {
    return html`
      <div class="omp-mp-navigation ${cate_list_id ? '' : 'all-menu'}">
        <div class="omp-mp-navigation__wrap ${cate_list_id ? '' : 'all-menu__wrap'}">
          ${strMap(
            (section) => sectionHoverHtml({ sections: section, cate_colors: mp_navigation._.cate_colors }),
            sections,
          )}
        </div>
      </div>
    `;
  }
};
