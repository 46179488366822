import { CommonNS, StrokeNS } from '@marpple/sticker-editor';
import { SVGEditorAttrS } from '../../../../../../SVGEditor/Attr/S/Function/module/SVGEditorAttrS.js';
import { SVGEditorFontConstS } from '../../../../../../SVGEditor/Font/S/Const/module/SVGEditorFontConstS.js';
import { VectorEditorFreeShapePCF } from '../../../../../FreeShape/PC/F/Function/module/VectorEditorFreeShapePCF.js';
import { SVGEditorUploadImageF } from '../../../../../../SVGEditor/UploadImage/F/Function/module/SVGEditorUploadImageF.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';
import {
  appendL,
  defaultTo,
  dropL,
  each,
  eachC,
  eachL,
  equals,
  equals2,
  filterL,
  go,
  head,
  html,
  isNil,
  join,
  mapL,
  noop,
  not,
  reduce,
  rejectL,
  takeL,
  tap,
  zipL,
} from 'fxjs/es';
import { $append, $appendTo, $closest, $el, $find, $findAll, $on, $remove, $setHTML } from 'fxdom/es';
import { VectorEditorTopMenuPCF } from '../../../../../TopMenu/PC/F/Function/module/VectorEditorTopMenuPCF.js';
import { SVGEditorRightPaneltextF } from '../../../../../../SVGEditor/RightPanel/text/F/Function/module/SVGEditorRightPaneltextF.js';
import { SVGEditorRightPanelShapeF } from '../../../../../../SVGEditor/RightPanel/Shape/F/Function/module/SVGEditorRightPanelShapeF.js';
import { VectorEditorKeyringGridPCPathEditorF } from '../../PathEditor/F/Function/module/VectorEditorKeyringGridPCPathEditorF.js';
import { VectorEditorKeyringHookPCF } from '../../../../Hook/PC/F/Function/module/VectorEditorKeyringHookPCF.js';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';

export const handleRightBottomCancelButtonClick = () => async () => {
  const is_close = await $.confirm(
    T('modules::VectorEditor::Keyring::Grid::PC::alert_message::close_keyring_editor'),
  );
  if (!is_close) {
    return;
  }
  MuiF.closeFrame(null);
};

export const handleRightBottomNextButtonClick =
  ({ tab_el }) =>
  async () => {
    try {
      const editor = tab_el.__mp_keyring_editor;
      const postProcess = tab_el.__mp_postProcess;

      if (editor.getGraphicsEls().size <= 0) {
        await $.alert(T('modules::VectorEditor::Keyring::Grid::PC::alert_message::empty_keyring'));
        return;
      }

      await VectorEditorKeyringHookPCF.makeHook({
        frame_position: { top: tab_el.__mp_frame_position_top, height: tab_el.__mp_frame_position_height },
        prev_right_panel_el: $find(`.right_container .right_wrapper`)(tab_el),
        art_board: {
          width: tab_el.__mp_art_board_width,
          height: tab_el.__mp_art_board_height,
          shape_path_data: tab_el.__mp_art_board_shape_path_data,
        },
        makeTargetImageEl: () =>
          editor.exportEditor({
            factor: 1,
            is_art_board_clip_path_on: true,
            container_el: document.body,
          }),
        postProcess: async ({
          cutting_line_path_data,
          hook_position: { x: hook_position_x, y: hook_position_y },
        }) => {
          const cutting_line_result = (() => {
            try {
              const cutting_line_svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
              cutting_line_svg_el.setAttributeNS(
                'http://www.w3.org/2000/xmlns/',
                'xmlns',
                'http://www.w3.org/2000/svg',
              );
              cutting_line_svg_el.setAttributeNS(null, 'viewBox', '0 0 0 0');

              const cutting_line_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
              cutting_line_path_el.setAttributeNS(null, 'd', cutting_line_path_data);
              cutting_line_path_el.setAttributeNS(null, 'fill', 'none');
              cutting_line_path_el.setAttributeNS(null, 'stroke', '#FF0000');
              cutting_line_path_el.setAttributeNS(null, 'stroke-width', '0.001');
              cutting_line_svg_el.appendChild(cutting_line_path_el);

              document.body.appendChild(cutting_line_svg_el);
              const margin = 2;
              const bbox = cutting_line_path_el.getBBox();
              const view_box_x = Math.floor(bbox.x) - margin;
              const view_box_y = Math.floor(bbox.y) - margin;
              const view_box_width = Math.ceil(bbox.width) + margin * 2;
              const view_box_height = Math.ceil(bbox.height) + margin * 2;
              document.body.removeChild(cutting_line_svg_el);

              cutting_line_svg_el.setAttributeNS(
                null,
                'viewBox',
                go(
                  [view_box_x, view_box_y, view_box_width, view_box_height],
                  mapL((a) => `${a}`),
                  join(' '),
                ),
              );

              const hook_position_ratio = {
                x: (hook_position_x - view_box_x) / view_box_width,
                y: (hook_position_y - view_box_y) / view_box_height,
              };

              return {
                success: true,
                value: {
                  cutting_line_svg_el,
                  hook_position_ratio,
                  view_box: { x: view_box_x, y: view_box_y, width: view_box_width, height: view_box_height },
                },
              };
            } catch (error) {
              console.error(error);
              return {
                success: false,
                value: T(
                  'modules::VectorEditor::Keyring::Grid::PC::error_message::cutting_line_calculation_error',
                ),
              };
            }
          })();
          if (!cutting_line_result.success) {
            return cutting_line_result;
          }
          const { cutting_line_svg_el, hook_position_ratio, view_box } = cutting_line_result.value;

          return postProcess({
            cutting_line_svg_el,
            hook_position_ratio,
            view_box,
            makeTargetImageEl: ({ factor }) =>
              editor.exportEditor({
                factor,
                is_art_board_clip_path_on: true,
                container_el: document.body,
              }),
          });
        },
      });
    } catch (error) {
      await $.alert(
        error?.__mp_alert_message ??
          T('modules::VectorEditor::Keyring::Grid::PC::error_message::hook_cutting_line_making_error'),
      );
      throw error;
    }
  };

export const handleTopMenuToggleLayerModeClick =
  ({ tab_el }) =>
  () => {
    return go(
      tab_el,
      $findAll(`.left_container .layer_container`),
      each((layer_container_el) => {
        const is_layer_mode = layer_container_el.dataset.is_show === 'true';
        if (is_layer_mode) {
          layer_container_el.dataset.is_show = 'false';
        } else {
          layer_container_el.dataset.is_show = 'true';
          return (async () => {
            $.don_loader_start();
            try {
              await go(
                layer_container_el,
                $findAll(`.layer_item`),
                eachC(
                  (layer_item_el) =>
                    new Promise((resolve) =>
                      requestAnimationFrame(() => {
                        try {
                          const bbox = layer_item_el.__mp_clone_el.getBBox();
                          const matrix =
                            CommonNS.UtilNS.getConsolidatedTransformMatrix(layer_item_el.__mp_clone_el) ??
                            layer_item_el.__mp_svg_el.createSVGMatrix();
                          const view_box = go(
                            [
                              [bbox.x, bbox.y],
                              [bbox.x + bbox.width, bbox.y],
                              [bbox.x + bbox.width, bbox.y + bbox.height],
                              [bbox.x, bbox.y + bbox.height],
                            ],
                            mapL(CommonNS.UtilNS.createSVGPoint(layer_item_el.__mp_svg_el)),
                            mapL((point) => point.matrixTransform(matrix)),
                            (points) =>
                              reduce(
                                (acc, { x, y }) => {
                                  acc.min_x = Math.min(acc.min_x, x);
                                  acc.min_y = Math.min(acc.min_y, y);
                                  acc.max_x = Math.max(acc.max_x, x);
                                  acc.max_y = Math.max(acc.max_y, y);
                                  return acc;
                                },
                                { min_x: Infinity, min_y: Infinity, max_x: -Infinity, max_y: -Infinity },
                                points,
                              ),
                            ({ min_x, min_y, max_x, max_y }) => {
                              const fit_width = max_x - min_x;
                              const fit_height = max_y - min_y;
                              const x_margin = fit_width / 10;
                              const y_margin = fit_height / 10;
                              return `${min_x - x_margin} ${min_y - y_margin} ${fit_width + x_margin * 2} ${
                                fit_height + y_margin * 2
                              }`;
                            },
                          );
                          layer_item_el.__mp_svg_el.setAttributeNS(null, 'viewBox', view_box);
                          layer_item_el.__mp_stroke_sync.sync();
                        } catch (error) {
                          console.error(error);
                        }
                        resolve();
                      }),
                    ),
                ),
              );
            } finally {
              $.don_loader_end();
            }
          })();
        }
      }),
    );
  };

export const handleTopMenuDuplicateClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const selected_els = editor.getSelectedEls();
    const selected_count = selected_els.size;
    if (selected_count <= 0 || selected_count > 1) {
      return;
    }
    const el = selected_els.values().next().value;
    if (el == null) {
      return;
    }
    const clone_el = editor.copyEl(el);
    editor.selectEls([clone_el]);
  };

export const handleTopMenuDeleteClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const selected_els = editor.getSelectedEls();
    for (const el of selected_els) {
      editor.removeEl(el);
    }
  };

export const handleTopMenuForwardClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    go(
      editor.getSelectedEls(),
      takeL(1),
      each((el) => editor.moveForward(el)),
    );
  };

export const handleTopMenuBackwardClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    go(
      editor.getSelectedEls(),
      takeL(1),
      each((el) => editor.moveBackward(el)),
    );
  };

export const handleTopMenuGroupClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const els = Array.from(editor.getSelectedEls());
    if (els.length <= 1) {
      return;
    }
    const group_el = editor.groupEls(els);
    editor.selectEls([group_el]);
  };

export const handleTopMenuUngroupClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const group_el = go(
      editor.getSelectedEls(),
      filterL((el) => editor.getIsGroupEl(el)),
      head,
    );
    if (group_el == null) {
      return;
    }
    const ungrouped_els = editor.ungroupEl(group_el);
    editor.selectEls(ungrouped_els);
  };

export const handleTopMenuFlipHorizontalClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const selected_els = editor.getSelectedEls();
    for (const el of selected_els) {
      editor.flipHorizontal(el);
    }
  };

export const handleTopMenuFlipVerticalClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const selected_els = editor.getSelectedEls();
    for (const el of selected_els) {
      editor.flipVertical(el);
    }
  };

export const handleTopMenuMoveToLeftEndClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const els = Array.from(editor.getSelectedEls());
    editor.alignToLeftEnd(els);
  };

export const handleTopMenuMoveToHorizontalCenterClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const els = Array.from(editor.getSelectedEls());
    editor.alignToHorizontalCenter(els);
  };

export const handleTopMenuMoveToRightEndClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const els = Array.from(editor.getSelectedEls());
    editor.alignToRightEnd(els);
  };

export const handleTopMenuMoveToTopEndClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const els = Array.from(editor.getSelectedEls());
    editor.alignToTopEnd(els);
  };

export const handleTopMenuMoveToVerticalCenterClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const els = Array.from(editor.getSelectedEls());
    editor.alignToVerticalCenter(els);
  };

export const handleTopMenuMoveToBottomEndClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;
    const els = Array.from(editor.getSelectedEls());
    editor.alignToBottomEnd(els);
  };

export const handleLeftMenuUploadImageIconClick =
  ({ tab_el }) =>
  () => {
    return SVGEditorUploadImageF.uploadImage({
      postProcess: (el) => {
        if (!el) {
          return;
        }
        if (tab_el.__mp_keyring_editor == null) {
          return;
        }

        const editor = tab_el.__mp_keyring_editor;

        editor.addEl(el);
        editor.scaleWithinArtBoard({ el, scale: 0.4 });
        const els = [el];
        editor.alignToHorizontalCenter(els);
        editor.alignToVerticalCenter(els);
        editor.selectEls(els);
      },
    });
  };

export const handleLeftMenuMyImageIconClick =
  ({ tab_el }) =>
  () => {
    return SVGEditorUploadImageF.openPcMyLibrary({
      postProcess: (el) => {
        if (!el) {
          return;
        }
        if (tab_el.__mp_keyring_editor == null) {
          return;
        }

        const editor = tab_el.__mp_keyring_editor;

        editor.addEl(el);
        editor.scaleWithinArtBoard({ el, scale: 0.4 });
        const els = [el];
        editor.alignToHorizontalCenter(els);
        editor.alignToVerticalCenter(els);
        editor.selectEls(els);
      },
    });
  };

export const handleLeftMenuShapeIconClick =
  ({ tab_el }) =>
  () => {
    return VectorEditorFreeShapePCF.useFreeShape({
      postProcess: (el) => {
        if (tab_el.__mp_keyring_editor == null) {
          return;
        }
        const editor = tab_el.__mp_keyring_editor;
        editor.addEl(el);
        editor.scaleWithinArtBoard({ el, scale: 0.4 });
        const els = [el];
        editor.alignToHorizontalCenter(els);
        editor.alignToVerticalCenter(els);
        editor.setStrokeWidth({ el, stroke_width: 0 });
        editor.setStrokeDasharray({ el, stroke_dasharray: [0, 0] });
        editor.selectEls(els);
      },
    });
  };

export const handleLeftMenuPathIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;

    const path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
    path_el.setAttributeNS(null, 'fill', 'none');
    path_el.setAttributeNS(null, 'stroke', '#000000');
    path_el.setAttributeNS(null, 'd', 'M 0 0 C 50 100 100 100 150 0 C 200 -100 250 -100 300 0');
    path_el.setAttributeNS(null, 'opacity', '1');

    editor.addEl(path_el);
    editor.scaleWithinArtBoard({ el: path_el, scale: 0.4 });
    const els = [path_el];
    editor.alignToHorizontalCenter(els);
    editor.alignToVerticalCenter(els);
    editor.setStrokeWidth({ el: path_el, stroke_width: 1 });
    editor.setStrokeDasharray({ el: path_el, stroke_dasharray: [0, 0] });
    editor.selectEls(els);
  };

export const handleLeftMenuTextIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_keyring_editor;

    const text_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'text');
    text_el.innerHTML = T.lang === 'kr' ? '텍스트' : 'Text';

    editor.addEl(text_el);
    editor.scaleWithinArtBoard({ el: text_el, scale: 0.4 });
    const els = [text_el];
    editor.alignToHorizontalCenter(els);
    editor.alignToVerticalCenter(els);
    SVGEditorAttrS.set(SVGEditorFontConstS.DEFAULT_FONT_STYLE, text_el, editor);
    editor.selectEls(els);
  };

export const handleLayerCloseClick = () => (event) => {
  go(event.currentTarget, $closest(`.layer_container`), (el) => ((el?.dataset ?? {}).is_show = 'false'));
};

export const handleLayerLayerItemClick =
  ({ tab_el }) =>
  (event) => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    if (event?.currentTarget?.__mp_original_el == null) {
      return;
    }
    const el = event?.currentTarget?.__mp_original_el;

    const is_selected = editor.getSelectedEls().has(el);
    if (is_selected) {
      editor.unselectEl(el);
    } else {
      editor.selectEl(el);
    }
  };

export const handleLayerLayerItemLockClick =
  ({ tab_el }) =>
  (event) => {
    event.stopPropagation();

    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const layer_item_el = $closest(`.layer_item`)(event.currentTarget);
    if (layer_item_el == null) {
      return;
    }
    const el = layer_item_el.__mp_original_el;
    if (el == null) {
      return;
    }

    const is_locked = editor.getIsLocked(el);
    if (is_locked) {
      editor.unlockEl(el);
    } else {
      editor.lockEl(el);
    }
  };

export const handleLayerLayerItemVisibleClick =
  ({ tab_el }) =>
  (event) => {
    event.stopPropagation();

    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const layer_item_el = $closest(`.layer_item`)(event.currentTarget);
    if (layer_item_el == null) {
      return;
    }
    const el = layer_item_el.__mp_original_el;
    if (el == null) {
      return;
    }

    const is_visible = el.getAttributeNS(null, 'display') !== 'none';
    if (is_visible) {
      editor.hideEl(el);
    } else {
      editor.showEl(el);
    }
  };

export const handleKeyringEditorSelect =
  ({ tab_el }) =>
  (event) =>
    handleKeyringEditorSelectOrUnselect({ tab_el, event });

export const handleKeyringEditorUnselect =
  ({ tab_el }) =>
  (event) =>
    handleKeyringEditorSelectOrUnselect({ tab_el, event });

export const handleKeyringEditorAdd =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    if (tab_el.__mp_empty_template_el != null) {
      tab_el.__mp_empty_template_el.setAttributeNS(null, 'display', 'none');
    }

    const layer_container_el = $find(`.left_container .layer_container`)(tab_el);
    if (isNil(layer_container_el)) {
      return;
    }

    const list_container_el = $find(`.list_container`)(layer_container_el);
    if (isNil(list_container_el)) {
      return;
    }

    const els = (() => {
      const els = tab_el?.__mp_keyring_editor?.getGraphicsEls?.();
      if (isNil(els)) {
        return null;
      }
      return [...els].reverse();
    })();
    if (isNil(els)) {
      return;
    }

    const layer_item_els = $findAll(`.layer_item`)(layer_container_el);

    const next_el = go(
      zipL(els, appendL(null, dropL(1, els))),
      filterL(([_el]) => equals(el)(_el)),
      mapL(([, el]) => el),
      head,
    );
    const next_layer_item_el =
      go(
        layer_item_els,
        rejectL((layer_item_el) => isNil(layer_item_el.__mp_original_el)),
        filterL((layer_item_el) => equals(next_el)(layer_item_el.__mp_original_el)),
        head,
      ) ?? null;

    const label = (() => {
      if (tab_el.__mp_keyring_editor?.getIsGroupEl(el)) {
        return T('modules::VectorEditor::Keyring::Grid::PC::template::layer_label_group');
      }

      switch (el.nodeName?.toLowerCase?.()) {
        case 'text': {
          return T('modules::VectorEditor::Keyring::Grid::PC::template::layer_label_text');
        }
        case 'circle':
        case 'path': {
          return T('modules::VectorEditor::Keyring::Grid::PC::template::layer_label_shape');
        }
        case 'image': {
          return T('modules::VectorEditor::Keyring::Grid::PC::template::layer_label_image');
        }
        default: {
          return '';
        }
      }
    })();
    const layer_item_el = go(
      html`
        <div
          class="layer_item"
          data-is_selected="${!!(tab_el.__mp_keyring_editor?.getSelectedEls?.()?.has?.(el) ?? false)}"
          data-is_visible="${!equals2('none')(el.getAttributeNS(null, 'display'))}"
          data-is_locked="${!!(tab_el.__mp_keyring_editor?.getIsLocked?.(el) ?? false)}"
        >
          <div class="image_container"></div>
          <div class="label_container">
            <span class="label">${label}</span>
          </div>
          <div class="button_container">
            <button type="button" class="visible"></button>
            <button type="button" class="lock"></button>
          </div>
        </div>
      `,
      $el,
      tap((layer_item_el) => (layer_item_el.__mp_original_el = el)),
      tap($on('click', handleLayerLayerItemClick({ tab_el }))),
      tap($findAll(`.button_container .lock`), each($on('click', handleLayerLayerItemLockClick({ tab_el })))),
      tap(
        $findAll(`.button_container .visible`),
        each($on('click', handleLayerLayerItemVisibleClick({ tab_el }))),
      ),
      tap((layer_item_el) => {
        const svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
        svg_el.setAttributeNS(null, 'width', '100%');
        svg_el.setAttributeNS(null, 'height', '100%');
        svg_el.setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid meet');
        svg_el.setAttributeNS(null, 'viewBox', '0 0 0 0');
        go(layer_item_el, $findAll(`.image_container`), takeL(1), each($append(svg_el)));
        layer_item_el.__mp_svg_el = svg_el;

        const dummy_g_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
        svg_el.appendChild(dummy_g_el);
        layer_item_el.__mp_dummy_g_el = dummy_g_el;

        layer_item_el.__mp_clone_el = null;

        const stroke = new StrokeNS.ModelNS.Stroke({ svg_el, getCTM: () => dummy_g_el.getCTM() });
        layer_item_el.__mp_stroke = stroke;
        layer_item_el.__mp_stroke_sync = new StrokeNS.ModelNS.StrokeSync({
          el: svg_el,
          stroke,
          SVGGraphicsElement: window.SVGGraphicsElement,
        });

        layer_item_el.__mp_task_queue = [];
        layer_item_el.__mp_is_processing = false;
      }),
    );
    list_container_el.insertBefore(layer_item_el, next_layer_item_el);
    list_container_el.dataset.count = `${layer_item_els.length + 1}`;

    const f = async () => {
      layer_item_el.__mp_is_processing = true;

      if (!isNil(layer_item_el.__mp_clone_el)) {
        layer_item_el.__mp_svg_el.removeChild(layer_item_el.__mp_clone_el);
      }
      layer_item_el.__mp_clone_el = /** @type {SVGGraphicsElement} */ await CommonNS.UtilNS.deepCloneNode(
        layer_item_el.__mp_original_el,
      );
      layer_item_el.__mp_clone_el.removeAttributeNS(null, 'display');
      layer_item_el.__mp_svg_el.appendChild(layer_item_el.__mp_clone_el);

      if (
        go(
          layer_item_el,
          $closest(`.layer_container`),
          (layer_container_el) => equals2('true')(layer_container_el?.dataset?.is_show),
          not,
        )
      ) {
        const f = layer_item_el.__mp_task_queue.shift();
        if (isNil(f)) {
          layer_item_el.__mp_is_processing = false;
          return;
        }
        f();
        return;
      }

      const bbox = layer_item_el.__mp_clone_el.getBBox();
      const matrix =
        CommonNS.UtilNS.getConsolidatedTransformMatrix(layer_item_el.__mp_clone_el) ??
        layer_item_el.__mp_svg_el.createSVGMatrix();
      const view_box = go(
        [
          [bbox.x, bbox.y],
          [bbox.x + bbox.width, bbox.y],
          [bbox.x + bbox.width, bbox.y + bbox.height],
          [bbox.x, bbox.y + bbox.height],
        ],
        mapL(CommonNS.UtilNS.createSVGPoint(layer_item_el.__mp_svg_el)),
        mapL((point) => point.matrixTransform(matrix)),
        (points) =>
          reduce(
            (acc, { x, y }) => {
              acc.min_x = Math.min(acc.min_x, x);
              acc.min_y = Math.min(acc.min_y, y);
              acc.max_x = Math.max(acc.max_x, x);
              acc.max_y = Math.max(acc.max_y, y);
              return acc;
            },
            { min_x: Infinity, min_y: Infinity, max_x: -Infinity, max_y: -Infinity },
            points,
          ),
        ({ min_x, min_y, max_x, max_y }) => {
          const fit_width = max_x - min_x;
          const fit_height = max_y - min_y;
          const x_margin = fit_width / 10;
          const y_margin = fit_height / 10;
          return `${min_x - x_margin} ${min_y - y_margin} ${fit_width + x_margin * 2} ${
            fit_height + y_margin * 2
          }`;
        },
      );
      layer_item_el.__mp_svg_el.setAttributeNS(null, 'viewBox', view_box);
      layer_item_el.__mp_stroke_sync.sync();

      const f = layer_item_el.__mp_task_queue.shift();
      if (isNil(f)) {
        layer_item_el.__mp_is_processing = false;
        return;
      }
      f();
    };
    layer_item_el.__mp_task_queue.push(f);
    if (layer_item_el.__mp_task_queue.length > 1) {
      layer_item_el.__mp_task_queue.splice(0, layer_item_el.__mp_task_queue.length - 1);
    }
    if (layer_item_el.__mp_is_processing) {
      return;
    }
    layer_item_el.__mp_task_queue?.shift?.()?.();
  };

export const handleKeyringEditorRemove =
  ({ tab_el }) =>
  (event) => {
    if (tab_el.__mp_keyring_editor != null && tab_el.__mp_empty_template_el != null) {
      const editor = tab_el.__mp_keyring_editor;
      const empty_template_el = tab_el.__mp_empty_template_el;
      const count = editor.getGraphicsEls().size;
      if (count <= 0) {
        empty_template_el.removeAttributeNS(null, 'display');
      }
    }

    const layer_container_el = $find(`.left_container .layer_container`)(tab_el);
    if (layer_container_el == null) {
      return;
    }

    const list_container_el = $find(`.list_container`)(layer_container_el);
    if (list_container_el == null) {
      return;
    }

    const el = event.detail.el;
    go(
      list_container_el,
      $findAll(`.layer_item`),
      filterL((layer_item_el) => el != null && layer_item_el.__mp_original_el === el),
      each($remove),
    );
    list_container_el.dataset.count = go(
      list_container_el,
      $findAll(`.layer_item`),
      (els) => els.length,
      (l) => `${l}`,
    );
  };

export const handleKeyringEditorReorder =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const graphics_els = editor.getGraphicsEls();
    if (graphics_els == null) {
      return;
    }
    const els = Array.from(graphics_els).reverse();

    const layer_container_el = $find(`.left_container .layer_container`)(tab_el);
    if (layer_container_el == null) {
      return;
    }

    const list_container_el = $find(`.list_container`)(layer_container_el);
    if (list_container_el == null) {
      return;
    }

    const layer_item_els_wm = go(list_container_el, $findAll(`.layer_item`), eachL($remove), (iter) =>
      reduce(
        (wm, layer_item_el) => {
          wm.set(layer_item_el.__mp_original_el, layer_item_el);
          return wm;
        },
        new WeakMap(),
        iter,
      ),
    );
    go(
      els,
      mapL((el) => layer_item_els_wm.get(el) ?? null),
      /** @todo 나중에는 layer_item_el 못 찾으면 새로 만드는 로직으로 변경 */
      rejectL(isNil),
      each($appendTo(list_container_el)),
    );
  };

export const handleKeyringEditorLock =
  ({ tab_el }) =>
  (event) => {
    if (event?.detail?.el == null) {
      return;
    }
    const el = event?.detail?.el;

    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => layer_item_el.__mp_original_el === el),
      each((layer_item_el) => (layer_item_el.dataset.is_locked = 'true')),
    );
  };

export const handleKeyringEditorUnlock =
  ({ tab_el }) =>
  (event) => {
    if (event?.detail?.el == null) {
      return;
    }
    const el = event?.detail?.el;

    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => layer_item_el.__mp_original_el === el),
      each((layer_item_el) => (layer_item_el.dataset.is_locked = 'false')),
    );
  };

export const handleKeyringEditorShow =
  ({ tab_el }) =>
  (event) => {
    if (event?.detail?.el == null) {
      return;
    }
    const el = event?.detail?.el;

    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => layer_item_el.__mp_original_el === el),
      each((layer_item_el) => (layer_item_el.dataset.is_visible = 'true')),
    );
  };

export const handleKeyringEditorHide =
  ({ tab_el }) =>
  (event) => {
    if (event?.detail?.el == null) {
      return;
    }
    const el = event?.detail?.el;

    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => layer_item_el.__mp_original_el === el),
      each((layer_item_el) => (layer_item_el.dataset.is_visible = 'false')),
    );
  };

export const handleKeyringEditorTransform =
  ({ tab_el }) =>
  (event) => {
    const { el, transform_type, transform_status } = event?.detail ?? {};
    if (el == null) {
      return;
    }
    if (transform_type === 'translate') {
      return;
    }
    if (transform_status !== 'end') {
      return;
    }

    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => layer_item_el.__mp_original_el === el),
      each((layer_item_el) => {
        const f = async () => {
          layer_item_el.__mp_is_processing = true;

          if (layer_item_el.__mp_clone_el != null) {
            layer_item_el.__mp_svg_el.removeChild(layer_item_el.__mp_clone_el);
          }
          layer_item_el.__mp_clone_el = /** @type {SVGGraphicsElement} */ await CommonNS.UtilNS.deepCloneNode(
            layer_item_el.__mp_original_el,
          );
          layer_item_el.__mp_clone_el.removeAttributeNS(null, 'display');
          layer_item_el.__mp_svg_el.appendChild(layer_item_el.__mp_clone_el);

          if (
            go(
              layer_item_el,
              $closest(`.layer_container`),
              (layer_container_el) => layer_container_el?.dataset?.is_show === 'true',
              not,
            )
          ) {
            const f = layer_item_el.__mp_task_queue.shift();
            if (f == null) {
              layer_item_el.__mp_is_processing = false;
              return;
            }
            f();
            return;
          }

          const bbox = layer_item_el.__mp_clone_el.getBBox();
          const matrix =
            CommonNS.UtilNS.getConsolidatedTransformMatrix(layer_item_el.__mp_clone_el) ??
            layer_item_el.__mp_svg_el.createSVGMatrix();
          const view_box = go(
            [
              [bbox.x, bbox.y],
              [bbox.x + bbox.width, bbox.y],
              [bbox.x + bbox.width, bbox.y + bbox.height],
              [bbox.x, bbox.y + bbox.height],
            ],
            mapL(CommonNS.UtilNS.createSVGPoint(layer_item_el.__mp_svg_el)),
            mapL((point) => point.matrixTransform(matrix)),
            (points) =>
              reduce(
                (acc, { x, y }) => {
                  acc.min_x = Math.min(acc.min_x, x);
                  acc.min_y = Math.min(acc.min_y, y);
                  acc.max_x = Math.max(acc.max_x, x);
                  acc.max_y = Math.max(acc.max_y, y);
                  return acc;
                },
                { min_x: Infinity, min_y: Infinity, max_x: -Infinity, max_y: -Infinity },
                points,
              ),
            ({ min_x, min_y, max_x, max_y }) => {
              const fit_width = max_x - min_x;
              const fit_height = max_y - min_y;
              const x_margin = fit_width / 10;
              const y_margin = fit_height / 10;
              return `${min_x - x_margin} ${min_y - y_margin} ${fit_width + x_margin * 2} ${
                fit_height + y_margin * 2
              }`;
            },
          );
          layer_item_el.__mp_svg_el.setAttributeNS(null, 'viewBox', view_box);
          layer_item_el.__mp_stroke_sync.sync();

          const f = layer_item_el.__mp_task_queue.shift();
          if (isNil(f)) {
            layer_item_el.__mp_is_processing = false;
            return;
          }
          f();
        };
        layer_item_el.__mp_task_queue.push(f);
        if (layer_item_el.__mp_task_queue.length > 1) {
          layer_item_el.__mp_task_queue.splice(0, layer_item_el.__mp_task_queue.length - 1);
        }
        if (layer_item_el.__mp_is_processing) {
          return;
        }
        return layer_item_el.__mp_task_queue?.shift?.()?.();
      }),
    );
  };

export const handleKeyringEditorAttrChange =
  ({ tab_el }) =>
  (event) => {
    if (event?.detail?.el == null) {
      return;
    }
    const el = event?.detail?.el;

    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => layer_item_el.__mp_original_el === el),
      each((layer_item_el) => {
        const f = async () => {
          layer_item_el.__mp_is_processing = true;

          if (layer_item_el.__mp_clone_el != null) {
            layer_item_el.__mp_svg_el.removeChild(layer_item_el.__mp_clone_el);
          }
          layer_item_el.__mp_clone_el = /** @type {SVGGraphicsElement} */ await CommonNS.UtilNS.deepCloneNode(
            layer_item_el.__mp_original_el,
          );
          layer_item_el.__mp_clone_el.removeAttributeNS(null, 'display');
          layer_item_el.__mp_svg_el.appendChild(layer_item_el.__mp_clone_el);

          if (
            go(
              layer_item_el,
              $closest(`.layer_container`),
              (layer_container_el) => layer_container_el?.dataset?.is_show === 'true',
              not,
            )
          ) {
            const f = layer_item_el.__mp_task_queue.shift();
            if (f == null) {
              layer_item_el.__mp_is_processing = false;
              return;
            }
            f();
            return;
          }

          const bbox = layer_item_el.__mp_clone_el.getBBox();
          const matrix =
            CommonNS.UtilNS.getConsolidatedTransformMatrix(layer_item_el.__mp_clone_el) ??
            layer_item_el.__mp_svg_el.createSVGMatrix();
          const view_box = go(
            [
              [bbox.x, bbox.y],
              [bbox.x + bbox.width, bbox.y],
              [bbox.x + bbox.width, bbox.y + bbox.height],
              [bbox.x, bbox.y + bbox.height],
            ],
            mapL(CommonNS.UtilNS.createSVGPoint(layer_item_el.__mp_svg_el)),
            mapL((point) => point.matrixTransform(matrix)),
            (points) =>
              reduce(
                (acc, { x, y }) => {
                  acc.min_x = Math.min(acc.min_x, x);
                  acc.min_y = Math.min(acc.min_y, y);
                  acc.max_x = Math.max(acc.max_x, x);
                  acc.max_y = Math.max(acc.max_y, y);
                  return acc;
                },
                { min_x: Infinity, min_y: Infinity, max_x: -Infinity, max_y: -Infinity },
                points,
              ),
            ({ min_x, min_y, max_x, max_y }) => {
              const fit_width = max_x - min_x;
              const fit_height = max_y - min_y;
              const x_margin = fit_width / 10;
              const y_margin = fit_height / 10;
              return `${min_x - x_margin} ${min_y - y_margin} ${fit_width + x_margin * 2} ${
                fit_height + y_margin * 2
              }`;
            },
          );
          layer_item_el.__mp_svg_el.setAttributeNS(null, 'viewBox', view_box);
          layer_item_el.__mp_stroke_sync.sync();

          const f = layer_item_el.__mp_task_queue.shift();
          if (isNil(f)) {
            layer_item_el.__mp_is_processing = false;
            return;
          }
          f();
        };
        layer_item_el.__mp_task_queue.push(f);
        if (layer_item_el.__mp_task_queue.length > 1) {
          layer_item_el.__mp_task_queue.splice(0, layer_item_el.__mp_task_queue.length - 1);
        }
        if (layer_item_el.__mp_is_processing) {
          return;
        }
        return layer_item_el.__mp_task_queue?.shift?.()?.();
      }),
    );
  };

const TYPE_NONE_TOP_MENU_ID = {
  ACTIVATE: new Set(),
  DEACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
};
const TYPE_SINGLE_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
  DEACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP.ID,
  ]),
};
const TYPE_GROUP_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
  DEACTIVATE: new Set([VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP.ID]),
};
const TYPE_MULTIPLE_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
  DEACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP.ID,
  ]),
};
function handleKeyringEditorSelectOrUnselect({ tab_el, event }) {
  const editor = tab_el.__mp_keyring_editor;
  const selected_els = event.detail.selected_els;

  go(
    tab_el,
    $findAll(`.layer_container .layer_item`),
    each((layer_item_el) => {
      const el = layer_item_el.__mp_original_el;
      const is_selected = el != null && (selected_els?.has?.(el) ?? false);
      layer_item_el.dataset.is_selected = `${is_selected}`;
    }),
  );

  if (editor == null) {
    go(
      tab_el,
      $findAll(`.top_menu`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'left';
        right_container_el.dataset.show_panel = 'home';
        right_container_el.__mp_right_panel_removed?.();
        right_container_el.__mp_right_panel_removed = null;
      }),
    );
    return;
  }

  if (selected_els == null) {
    go(
      tab_el,
      $findAll(`.top_menu`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'left';
        right_container_el.dataset.show_panel = 'home';
        right_container_el.__mp_right_panel_removed?.();
        right_container_el.__mp_right_panel_removed = null;
      }),
    );
    return;
  }

  const count = selected_els.size;
  if (count > 0) {
    editor.turnOffArtBoardClipPath();
  } else {
    editor.turnOnArtBoardClipPath();
  }

  if (count <= 0) {
    go(
      tab_el,
      $findAll(`.top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'left';
        right_container_el.dataset.show_panel = 'home';
        right_container_el.__mp_right_panel_removed?.();
        right_container_el.__mp_right_panel_removed = null;
      }),
    );
    return;
  }

  if (count >= 2) {
    go(
      tab_el,
      $findAll(`.top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_MULTIPLE_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_MULTIPLE_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'left';
        right_container_el.dataset.show_panel = 'home';
        right_container_el.__mp_right_panel_removed?.();
        right_container_el.__mp_right_panel_removed = null;
      }),
    );
    return;
  }

  const selected_el = selected_els.values().next().value;

  if (selected_el == null) {
    go(
      tab_el,
      $findAll(`.top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'left';
        right_container_el.dataset.show_panel = 'home';
        right_container_el.__mp_right_panel_removed?.();
        right_container_el.__mp_right_panel_removed = null;
      }),
    );
    return;
  }

  const selected_el_tag_name = selected_el.tagName;

  if (selected_el_tag_name === 'text') {
    go(
      tab_el,
      $findAll(`.top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      eachL((right_container_el) =>
        go(
          right_container_el,
          tap((el) => el.__mp_right_panel_removed?.()),
          $findAll(`.panel_wrapper[data-panel_wrapper="right"]`),
          eachL($setHTML('')),
          each((panel_wrapper_el) => {
            const { el, appended, removed } = SVGEditorRightPaneltextF.fn({
              svg_el: selected_el,
              single_sticker_editor: editor,
              fonts: tab_el.__mp_fonts,
            });
            $append(el)(panel_wrapper_el);
            appended(el);
            right_container_el.__mp_right_panel_removed = () => removed(el);
          }),
        ),
      ),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'right';
        right_container_el.dataset.show_panel = 'text';
      }),
    );
    return;
  }

  if (selected_el_tag_name === 'image') {
    go(
      tab_el,
      $findAll(`.top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'left';
        right_container_el.dataset.show_panel = 'home';
        right_container_el.__mp_right_panel_removed?.();
        right_container_el.__mp_right_panel_removed = null;
      }),
    );
    return;
  }

  if (selected_el_tag_name === 'path') {
    go(
      tab_el,
      $findAll(`.top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      eachL((right_container_el) =>
        go(
          right_container_el,
          tap((el) => el.__mp_right_panel_removed?.()),
          $findAll(`.panel_wrapper[data-panel_wrapper="right"]`),
          eachL($setHTML('')),
          each((panel_wrapper_el) => {
            const { el, appended, removed } = SVGEditorRightPanelShapeF.fn({
              tab_el,
              svg_el: selected_el,
              is_path: true,
              single_sticker_editor: editor,
              prev_frame_right_panel_el: $find(`.right_container .right_wrapper`)(tab_el),
              frame_position:
                G.collabo_type === ''
                  ? right_container_el.getBoundingClientRect?.()
                  : {
                      top: tab_el.__mp_frame_position_top,
                      width: go(
                        tab_el,
                        $findAll(`.right_container`),
                        mapL((el) => el.getBoundingClientRect().width),
                        head,
                        defaultTo(0),
                      ),
                      height: tab_el.__mp_frame_position_height,
                    },
              editPath: (payload) =>
                VectorEditorKeyringGridPCPathEditorF.editPath({
                  ...payload,
                  keyring_editor: editor,
                }),
            });
            $append(el)(panel_wrapper_el);
            appended(el);
            right_container_el.__mp_right_panel_removed = () => removed(el);
          }),
        ),
      ),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'right';
        right_container_el.dataset.show_panel = 'shape';
      }),
    );
    return;
  }

  if (new Set(['circle']).has(selected_el_tag_name)) {
    go(
      tab_el,
      $findAll(`.top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      eachL((right_container_el) =>
        go(
          right_container_el,
          tap((el) => el.__mp_right_panel_removed?.()),
          $findAll(`.panel_wrapper[data-panel_wrapper="right"]`),
          eachL($setHTML('')),
          each((panel_wrapper_el) => {
            const { el, appended, removed } = SVGEditorRightPanelShapeF.fn({
              tab_el,
              svg_el: selected_el,
              is_path: false,
              single_sticker_editor: editor,
              prev_frame_right_panel_el: $find(`.right_container .right_wrapper`)(tab_el),
              frame_position: {
                top: tab_el.__mp_frame_position_top,
                width: go(
                  tab_el,
                  $findAll(`.right_container`),
                  mapL((el) => el.getBoundingClientRect().width),
                  head,
                  defaultTo(0),
                ),
                height: tab_el.__mp_frame_position_height,
              },
              editPath: noop,
            });
            $append(el)(panel_wrapper_el);
            appended(el);
            right_container_el.__mp_right_panel_removed = () => removed(el);
          }),
        ),
      ),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'right';
        right_container_el.dataset.show_panel = 'shape';
      }),
    );
    return;
  }

  if (editor.getIsGroupEl(selected_el)) {
    go(
      tab_el,
      $findAll(`.top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_GROUP_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_GROUP_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'left';
        right_container_el.dataset.show_panel = 'home';
        right_container_el.__mp_right_panel_removed?.();
        right_container_el.__mp_right_panel_removed = null;
      }),
    );
    return;
  }

  go(
    tab_el,
    $findAll(`.top_menus`),
    each((top_menus_el) =>
      VectorEditorTopMenuPCF.activateTopMenus({
        top_menus_el,
        activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
        deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
      }),
    ),
  );
  go(
    tab_el,
    $findAll(`.right_container`),
    each((right_container_el) => {
      right_container_el.dataset.show_panel_wrapper = 'left';
      right_container_el.dataset.show_panel = 'home';
      right_container_el.__mp_right_panel_removed?.();
      right_container_el.__mp_right_panel_removed = null;
    }),
  );
}
