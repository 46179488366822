import { DfInhouseS } from '../../S/Function/module/DfInhouseS.js';
import format from 'date-fns/format/index.js';
import { $qs } from 'fxdom/es';

export const datePicker = {
  el: () => {
    const picker_el = $qs('.date-picker');
    if (picker_el == null) throw new Error(`date picker 요소가 존재하지 않습니다.`);
    return picker_el;
  },
  instance: () => {
    return datePicker.el()._flatpickr;
  },
  init: (date_picker_el) => {
    date_picker_el = date_picker_el ?? datePicker.el();
    const { start_date, end_date } = date_picker_el.dataset;
    let previous_selected_dates;
    window.flatpickr(date_picker_el, {
      mode: 'range',
      dateFormat: 'Y/m/d',
      maxDate: 'today',
      weekNumbers: true,
      defaultDate: [start_date, end_date].map(DfInhouseS.dateHandler.datePickerFormatting),
      formatDate: (date) => format(date, 'yy/MM/dd'), // ISO 형식으로 날짜를 변환
      onChange: (selectedDates) => {},
      onReady: () => {
        date_picker_el.style.color = 'black';
      },
      onOpen: (selectedDates) => {
        previous_selected_dates = selectedDates;
      },
      onClose: (selectedDates, _, instance) => {
        if (selectedDates.length === 1) {
          // 시작일만 선택하고 focus out 했으면 종료일은 기존 종료일로 자동 주입해서 에러 방지
          instance.setDate([selectedDates[0], previous_selected_dates[1]]);
        }
      },
    });
  },
  getDates: () => {
    const date_picker = datePicker.instance();
    const [start_date, end_date] = date_picker.selectedDates;
    return { start_date, end_date };
  },
  setDateRangeByBucketPackingAt: ({ bucket }) => {
    if (bucket == null) throw new Error(`Bucket 이 없습니다.`);

    if (bucket?.packing_at == null) {
      throw new Error(`Bucket 포장 시작된 시각이 없습니다. 운송장: ${bucket.waybill_no}`);
    }

    const packing_at = format(new Date(bucket.packing_at), 'yyyy-MM-dd');
    datePicker.setDateRange({ start_date: packing_at, end_date: packing_at });
    return datePicker.getDates();
  },
  setDateRange: ({ start_date, end_date }) => {
    const date_picker = datePicker.instance();
    date_picker.setDate([start_date, end_date]);
  },
};
