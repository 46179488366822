import { $hasClass } from 'fxdom/es';
import { OMPCoreCellPaginatedTableConstantS } from '../../S/Constant/module/OMPCoreCellPaginatedTableConstantS.js';

/**
 *
 * @param {HTMLElement} arrow_btn
 * @return {'first'|'prev'|'next'|'last'|null}
 */
export const getPageArrowType = (arrow_btn) => {
  const klass = {
    first: 'omp-cell__table-page-prev__first', // <<
    prev: 'omp-cell__table-page-prev__prev', // <
    next: 'omp-cell__table-page-prev__next', // >
    last: 'omp-cell__table-page-prev__last', // >>
  };
  if ($hasClass(klass.first, arrow_btn)) return OMPCoreCellPaginatedTableConstantS.paginationArrowType.first;
  if ($hasClass(klass.prev, arrow_btn)) return OMPCoreCellPaginatedTableConstantS.paginationArrowType.prev;
  if ($hasClass(klass.next, arrow_btn)) return OMPCoreCellPaginatedTableConstantS.paginationArrowType.next;
  if ($hasClass(klass.last, arrow_btn)) return OMPCoreCellPaginatedTableConstantS.paginationArrowType.last;
  return null;
};
