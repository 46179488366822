!(function () {
  G.$t_$order = (order) => pug`
    tr.order[order_id="${order.id}" _sel="./(#${order.id})"]
      td.name ${_p.v(order, 'name') || '이름없음'}
      td ${moment(order.created_at).format('YYYY-MM-DD HH:mm')}
      td ${_p.reduce(
        order._.order_items,
        function (count, order_item) {
          return count + _p.v(order_item, 'order_count');
        },
        0,
      )}
      td.user_name ${_p.v(order, '_.user.name') || ''}
      td ${order.ordered_at ? moment(order.ordered_at).format('YYYY-MM-DD HH:mm') : ''}
      td ${order.ordered_at ? '발주완료' : '발주준비중'}
      td
        a[href="/df/order_detail/${order.id}"].btn_order_detail 상세
  `;

  G.$t_$order_item = (order_item, task_ids) => pug`
    tr.order_item[order_id="${order_item.id}" _sel="./(#${order_item.id})" bp_stock_id="${_p.v(
    order_item,
    '_.bp_stock.id',
  )}"]
      td
        input[type="text" name="company" value="${
          _p.v(order_item, 'company') || _p.v(order_item, '_.bp_stock._.base_product.company') || ''
        }" placeholder="업체명"]
      td.bp_name ${_p.v(order_item, '_.bp_stock._.base_product.name') || '이름없음'}
      td
        input[type="text" name="company_pd_name" value="${
          _p.v(order_item, 'company_pd_name') ||
          _p.v(order_item, '_.bp_stock._.base_product.company_pd_name') ||
          ''
        }" placeholder="업체상품명"]
      td.bpc_name ${
        _p.v(order_item, '_.bp_stock._.base_product_color.name2') ||
        _p.v(order_item, '_.bp_stock._.base_product_color.name') ||
        '색상명 없음'
      }
      td.bps_name ${_p.v(order_item, '_.bp_stock._.base_product_size.name') || '사이즈명 없음'}
      td.projection_count ${_p.v(task_ids, 'length') ? _p.v(order_item, 'order_count') || 0 : 0} 
        input[type="hidden" name="projection_count" value="${
          _p.v(task_ids, 'length') ? _p.v(order_item, 'order_count') : 0
        }"]
      td.order_count
        input[type="number" name="order_count" value="${_p.v(order_item, 'order_count') || 0}"]
      td
      td.stock_count ${_p.v(order_item, '_.bp_stock.stock_count')}
      td.ordering_count ${_p.v(order_item, '_.bp_stock.ordering_count')}
      td.expect_count ${
        -(_p.v(task_ids, 'length') ? _p.v(order_item, 'order_count') : 0) +
        _p.v(order_item, '_.bp_stock.stock_count') +
        _p.v(order_item, '_.bp_stock.ordering_count') +
        (_p.v(order_item, 'order_count') || 0)
      }
  `;

  G.$t_$order_detail_item = (order_item, order) => pug`
    tr.order_item[order_id="${order_item.id}" _sel="./(#${order_item.id})"]
      td
        input[type="text" name="company" value="${
          order_item.company || _p.v(order_item, '_.bp_stock._.base_product.company') || ''
        }" placeholder="업체명"]
        button[type="button"].btn_order_company 변경
      td.bp_name ${_p.v(order_item, '_.bp_stock._.base_product.name') || '제품 이름없음'}
      td
        input[type="text" name="company_pd_name" value="${
          order_item.company_pd_name || _p.v(order_item, '_.bp_stock._.base_product.company_pd_name') || ''
        }" placeholder="업체상품명"]
        button[type="button"].btn_order_company_pd_name 변경
      td.bpc_name ${
        _p.v(order_item, '_.bp_stock._.base_product_color.name2') ||
        _p.v(order_item, '_.bp_stock._.base_product_color.name') ||
        '색상명 없음'
      }
      td.bps_name ${_p.v(order_item, '_.bp_stock._.base_product_size.name') || '사이즈명 없음'}
      td.order_count ${_p.v(order_item, 'order_count') || 0}
      ${
        !_p.v(order, 'ordered_at')
          ? pug`
        td.add_order_count
          input[type="number" name="add_order_count" value="0"] 
          button[type="button"].btn_order_count 추가
      `
          : pug`
        td.arrival_count ${_p.v(order_item, 'arrival_count') || 0}
        td.add_arrival_count
          input[type="number" name="add_arrival_count" value="0"]
          button[type="button"].btn_add_arrival_count 추가
        td.cancel_count
          input[type="number" name="cancel_count" value="${_p.v(order_item, 'cancel_count') || 0}"]
          button[type="button"].btn_add_cancel_count 변경
        td
      `
      }
      td.stock_count ${_p.v(order_item, '_.bp_stock.stock_count')}
      td.ordering_count ${_p.v(order_item, '_.bp_stock.ordering_count')}
      td.expect_count ${
        _p.v(order_item, '_.bp_stock.stock_count') + _p.v(order_item, '_.bp_stock.ordering_count')
      }
      ${
        !_p.v(order, 'ordered_at')
          ? pug`
        td.btns
          button[type="button"].btn_order_item_delete 삭제`
          : ''
      }
  `;
})();
