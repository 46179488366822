export default function (lo) {
  // $.frame2({
  //   is_mobile_size: false,
  //   open_frame: {
  //     frame_name: 'df.folder2_list_frame'
  //   }
  // });
  // _.each($('html.folder2_list'), __(
  //   _.c('.folder_list_area'), $1,
  //   $.on('keydown', '[name="name"]', function(e) {
  //     if (e.keyCode == 13) $.trigger($.find(e.$delegateTarget, '.btn_add_folder'), 'click');
  //   }),
  //   $.on('click', '.btn_add_folder', function(e) {
  //     var jq_name_input = $.find1(e.$delegateTarget, 'input[name="name"]');
  //
  //     if ($.val(jq_name_input).length <= 0) {
  //       $.alert('폴더 이름을 입력해 주세요.');
  //       return;
  //     }
  //
  //     $.post('/@api/folder2/create', {
  //       name: $.val(jq_name_input)
  //     }).then(__(
  //       function(data) {
  //         if (!data || !data.id) return _.stop();
  //         return data;
  //       },
  //       G.$t_$folder2_list_item,
  //       $.el,
  //       function(jq_folder) {
  //         $.trigger($.val(jq_name_input, ''), 'focus');
  //         $.prependTo(jq_folder, $.find(e.$delegateTarget, '.folder_list'));
  //       }
  //     ));
  //   }),
  //   $.on('click', '.folder_list .folder_item .btn_delete', function(e) {
  //     if (!confirm('삭제하시겠습니까?')) return;
  //
  //     var jq_folder = $.closest(e.$currentTarget, '.folder_item');
  //     $.delete('/@api/folder2/delete', {
  //       id: $.attr(jq_folder, 'folder_id')
  //     }).then(function() {
  //       $.remove(jq_folder);
  //       $.alert('삭제되었습니다.');
  //     });
  //   })
  // ));
}
