import { $val } from 'fxdom/es';
import { entries, go, map, reject } from 'fxjs/es';
import { UtilS } from '../../S/Function/module/UtilS.js';

/*
 * validation default error messages
 */

// export const ValidateErrorMessages = {
//   lineNumberCheck:
// }

/**
 *
 * @param validate_map {Object.<string, (any) => string | null>} 검증하려는 필드를 key, 검증하는 함수를 value 로 주고, 문제가 있으면 에러 메세지(string) 리턴 없으면 null 리턴
 * @return {(any) => Array.<[string, string]>} 문제가 있는 필드 값들만 에러 메세지와 맵핑해서 리턴
 */
export const validateFieldsWithMessage = (validate_map) => (data) =>
  go(
    validate_map,
    entries,
    map(([k, validator]) => [k, validator(data[k], data)]),
    reject(([, err_message]) => err_message == null),
  );

/**
 * @param v {string}
 * @param custom_err_message {string=} - 기본 에러 메세지 외에 오버라이딩할 커스텀 에러 메세지
 * @param max_num {number} - validation 을 적용할 max line number
 * @return {string | null}
 */
export const checkLineNumberValidator = (v, { custom_err_message, max_num }) => {
  if (!v) return null;
  return v.split('\n').length > max_num
    ? custom_err_message || `Number of line is more than allowed count (${max_num})`
    : null;
};

/**
 *
 * @param postcode$ {HTMLElement} 우편번호 element
 * @param callback {Function} validation 실패시 호출할 함수
 * @return {boolean} 우편번호 element가 비어있으면 true, 비어있지 않으면 false
 */
export const checkPostCodeEmpty = ({ postcode$, callback }) => {
  if (postcode$) {
    if (UtilS.isEmpty(go(postcode$, $val).trim())) {
      callback();
      return true;
    }
  }
  return false;
};
