import { sel } from 'fxjs/es';
import { CateListS } from '../../CateList/S/Function/module/CateListS.js';
import { NewMakerProductStyleS } from '../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { getBaseProductInMaker, isCanOverflow } from './getSth.js';
import { isDreamFactory } from './util.js';

export const isCateItemPhoneCase = function (base_product) {
  base_product = base_product || getBaseProductInMaker();
  if (NewMakerProductStyleS.isCarvedPhoneCase(base_product)) return;
  return CateListS.isPhoneCaseByCateItemId(base_product.cate_item_id);
};

export const isCylinderStyle = function (fcanvas_or_size_face) {
  return sel('preview.cylinder', fcanvas_or_size_face);
};

export const isCompositeStyle = function (fcanvas_or_size_face) {
  return sel('preview.composite.logo', fcanvas_or_size_face);
};

export const isPreviewStyle = function (fcanvas_or_size_face) {
  return sel('preview', fcanvas_or_size_face);
};

export const phonecase_strap_bp_ids = [4220, 4230, 4231, 4755, 4783, 4784];

export const isAirPodCase = function (base_product) {
  return base_product && base_product.name.indexOf('에어팟') > -1;
};

export const isBuzzCase = function (base_product) {
  return base_product && base_product.name.indexOf('버즈') > -1;
};

export const isTinCase = function (base_product) {
  return base_product && base_product.name.indexOf('틴케이스') > -1;
};

export const isCarrier = function (base_product) {
  return base_product && base_product.id === 4055;
};

export const isNeedLine = function (base_product) {
  base_product = base_product || getBaseProductInMaker();
  if (!base_product) return;
  return base_product.is_need_line;
};

export const isNeedCvPrintAreaMask = function () {
  return isDreamFactory() ? false : !isCanOverflow();
};

export const isNeedFullCover = function () {
  return sel('is_need_full_cover', box.sel('maker->product_color->_->base_product'));
};

export const isBpIds = (arr) => arr.includes(box.sel('maker->product_color->_->base_product->id'));

export const isBpMousePad = () => isBpIds(NewMakerProductStyleS.OUTSRC_MOUSE_PAD_BP_IDS);
export const isArosegidaBp = () => isBpIds(NewMakerProductStyleS.AROSEGIDA_BP_IDS);

export const isNoNeedAutoCenter = function () {
  const bp = box.sel('maker->product_color->_->base_product');
  return (
    G.mp.maker.is_ie ||
    phonecase_strap_bp_ids.includes(bp.id) ||
    isBpIds([4444, 4445, 4447, 4448, 4449, 4450, 4451, 4452])
  );
};

export const isSquarePrintArea = function () {
  return phonecase_strap_bp_ids.includes(box.sel('maker->product_color->_->base_product->id'));
};

export const isNeedTextVertically = function () {
  return phonecase_strap_bp_ids.includes(box.sel('maker->product_color->_->base_product->id'));
};

/*
 * 68 Bangers
 * 104 Holtwood One SC
 * 81 Ultra
 * 136 ZCOOL QingKe HuangYou
 * */
export const arosegida_reject_fonts = [
  { id: 68, fontFamily: 'Bangers' },
  { id: 104, fontFamily: 'Holtwood One SC' },
  { id: 81, fontFamily: 'Ultra' },
  { id: 136, fontFamily: 'ZCOOL QingKe HuangYou' },
];

export const is_selected_shading = () => {
  return isBpIds([4498]);
};
