import ExifReader from 'exifreader';
import {
  $addClass,
  $attr,
  $closest,
  $delegate,
  $find,
  $findAll,
  $hasClass,
  $off,
  $on,
  $qs,
  $removeClass,
  $setAttr,
  $setCss,
  $trigger,
} from 'fxdom/es';
import { each, go, map, mapObject, noop, pipe, values } from 'fxjs/es';
import { changeCvImageByBase64 } from '../../../Maker/F/CvImage/fs.js';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';
import { MarstagramEditorMuiF } from '../Mui/module/MarstagramEditorMuiF.js';
import { getLinearInterpolation } from './marstagramCore.js';
import {
  animateCropOut,
  animateCropViewportInitialize,
  animateGridLine,
  animateMinorGridLine,
  animateTransformInit,
  animateViewportFitting,
  calcTransformFitting,
  changeCrop,
  changeMoveItemsActive,
  changeShiftIconsDegree,
  cropPosStatus,
  doRafEvent,
  drawGLcanvas,
  getAdjustmentInputs$,
  getAllInputValues,
  getCropToViewCropPos,
  getCurrentCropPosFromGPU,
  getCurrentViewportFromGPU,
  getCursorOrTouchPos,
  mobileEvt,
  multipleClickEventHandler,
  preset_filters,
  renderImageEditor,
  selectedSwitch,
  setLerpAdjustments,
  setTransformDataToSlider,
  shiftViewport,
  switchingViewportFitting,
  updateCropOn,
  updateCursorStat,
  updateUniforms,
  updateViewport,
  updateViewportScale,
  writeSpan,
} from './module/index.js';

export const imageEditorOpenBtnsEvent = (sel) =>
  pipe(
    ...map(
      (type) =>
        $delegate('click', `${sel} .${type}`, async () => {
          //IE check
          if (window.is_ie) {
            $.alert(`${T('image_editor::ie_warning')}`);
          } else {
            const active_cv_image = G.mp.maker.active();
            const image_url = G.to_original(
              active_cv_image._data.cv_type === 'cv_pattern'
                ? active_cv_image._data.cv_image_attrs._data.image_url
                : active_cv_image._data.image_url,
            );
            const getBlobFromUrl = (myImageUrl) => {
              return new Promise((resolve, reject) => {
                const request = new XMLHttpRequest();
                request.open('GET', myImageUrl, true);
                request.responseType = 'arraybuffer';
                request.onload = () => {
                  resolve(request.response);
                };
                request.onerror = reject;
                request.send();
              });
            };

            const image_tag = ExifReader.load(await getBlobFromUrl(image_url + '?canvas=v1'));

            MuiF.openFrame(MarstagramEditorMuiF.frame, (frame, page, [tab]) => {
              tab.initial_editor_selected = type;
              tab.image_url = image_url;
              tab.image_tag = image_tag;
              tab.is_mp_worker_policy = !!box()?.is_user?._?.policies?.mp_worker_policy;
              frame.closing = async function (frame, data) {
                if (data?.is_done) {
                  $.don_loader_start();
                  await changeCvImageByBase64(active_cv_image, data.cropped_canvases.original.toDataURL());
                  $.don_loader_end();
                }
                go(
                  frame,
                  $findAll('input'),
                  each((input$) => {
                    const jInput = jQuery(input$);
                    jInput.off();
                    jInput.rangeslider('destroy');
                  }),
                );
              };
            });
          }
        }),
      ['filter', 'adjustment', 'crop'],
    ),
  );

export const getInput$ = (item_name, tab_el$) => $find(`input[item="${item_name}"]`, tab_el$);

export const initialCanvasRender = (tab_el$, image_editor_gl, view_gl) => {
  const current_editor = go(tab_el$, $find('.editor_body'), $attr('current_editor'));
  const adjustment_inputs$ = getAdjustmentInputs$(tab_el$);
  if (current_editor === 'crop') {
    updateUniforms(view_gl, view_gl.programs.view, {
      u_outside_crop_color: { type: '1f', value: [0.4] },
      u_outside_crop_alpha: { type: '1f', value: [1.0] },
      u_grid_line_alpha: { type: '1f', value: [1.0] },
      u_minor_grid_line_alpha: { type: '1f', value: [1.0] },
    });
  } else {
    updateUniforms(view_gl, view_gl.programs.view, {
      u_outside_crop_color: { type: '1f', value: [1.0] },
      u_outside_crop_alpha: { type: '1f', value: [0.0] },
      u_grid_line_alpha: { type: '1f', value: [0.0] },
      u_minor_grid_line_alpha: { type: '1f', value: [0.0] },
    });
  }
  renderImageEditor(image_editor_gl, view_gl, adjustment_inputs$, null, true);
};

export const initialEditorTrigger = (tab_el$) => {
  const initial_editor = go(tab_el$, $find('.editor_body'), $attr('current_editor'));
  $trigger('click', go(tab_el$, $find(`.editor_selection_box button[item="${initial_editor}"]`)));
  if (initial_editor === 'crop' && $hasClass('crop_limit', $qs('#view_gl_canvas'))) {
    $trigger('click', go(tab_el$, $find(`.editor_selection_box button[item="filter"]`)));
  }
};

export const filterEvents = (tab_el$, image_editor_gl, view_gl) => {
  const adjustment_inputs$ = getAdjustmentInputs$(tab_el$);
  const view_canvas = view_gl.canvas;
  go(
    $findAll('.filter_item_sliders input', tab_el$),
    each((input$) => {
      jQuery(input$)
        .on(mobileEvt('mouseup'), (e) => {
          const ct = e.currentTarget;
          const filter_selected_name = $attr('item', ct);
          const filter_strength = ct.value;
          setLerpAdjustments(filter_selected_name, filter_strength);
        })
        .on(
          'input',
          doRafEvent((e) => {
            const ct = e.currentTarget;
            setLerpAdjustments($attr('item', ct), Number(ct.value));
            renderImageEditor(image_editor_gl, view_gl, adjustment_inputs$, null, false);
          }),
        );
    }),
  );

  go(
    tab_el$,
    $delegate('click', '.filter_item canvas', (e) => {
      const ct = e.currentTarget;
      const sel_filter = $attr('item', ct);
      const strength =
        sel_filter === 'original'
          ? 1.0
          : go(ct, $closest('.filter_items'), $find(`input[item="${sel_filter}"]`)).value;
      if (sel_filter === 'original') {
        $removeClass('color_changed', view_canvas);
      } else {
        $addClass('color_changed', view_canvas);
      }
      selectedSwitch(ct, '.filter_items', sel_filter);
      setLerpAdjustments(sel_filter, strength, false);
      renderImageEditor(image_editor_gl, view_gl, adjustment_inputs$, null, true);
    }),
  );
};

export const adjustmentEvents = (tab_el$, image_editor_gl, view_gl) => {
  const adjustment_inputs$ = getAdjustmentInputs$(tab_el$);
  const view_canvas = view_gl.canvas;
  go(
    tab_el$,
    //조정 초기화 클릭 이벤트
    $delegate('click', '.adjustment_item_buttons .button.initializer', (e) => {
      const selected_filter = $qs('canvas.filter.selected');
      const selected_adjustment_item = $find('.adjustment_item_slider.selected input', tab_el$);
      $trigger('click', selected_filter);
      jQuery(selected_adjustment_item).rangeslider('update', true);
      //selected 필터가 original인 경우에는 adjustment도 초기화 되면 color change 가 없는 원본과 같은 상황.
      const current_filter = go(tab_el$, $find('.filter_item canvas.selected'), $attr('item'));
      current_filter === 'original' && $removeClass('color_changed', view_canvas);
    }),
    //조정 버튼 클릭 이벤트
    $delegate('click', '.adjustment_item_buttons .button:not(.initializer)', (e) => {
      const ct = e.currentTarget;
      const item_name = $attr('item', ct);
      if (item_name === 'invert') {
        const ct_slider$ = $qs(`input[item="${item_name}"]`);
        const val = Number(ct_slider$.value);
        const ct_span$ = $find('span', ct_slider$.parentElement);
        writeSpan(item_name, ct_span$, val);
      }
      selectedSwitch(ct, '.adjustment_items', $attr('item', ct));
      const selected_adjustment_item = $find('.adjustment_item_slider.selected input', tab_el$);
      jQuery(selected_adjustment_item).rangeslider('update', true);
    }),
    $delegate(
      'click',
      '.adjustment_item_buttons .button:not(.initializer)',
      multipleClickEventHandler(2, (e) => {
        const ct = e.currentTarget;
        const adjustment_item = $attr('item', ct);
        const selected_filter = go(tab_el$, $find('canvas.filter.selected'), $attr('item'));
        const original_val = preset_filters.original.items_value[adjustment_item];
        let target_val;
        if (selected_filter !== 'original') {
          const strength = go(tab_el$, $find(`.filter_strength.selected`)).value;
          const preset_val = preset_filters[selected_filter].items_value[adjustment_item];
          target_val = getLinearInterpolation(original_val, preset_val, strength, 1000);
        } else {
          target_val = original_val;
        }
        const target_slider$ = $find(`input[item="${adjustment_item}"]`, tab_el$);
        target_slider$.value = target_val;
        jQuery(target_slider$).rangeslider('update', true);
        renderImageEditor(image_editor_gl, view_gl, adjustment_inputs$, null, true);
      }),
    ),
    // 조정 슬라이더 인풋 이벤트
    $findAll('.adjustment_item_sliders input'),
    each((input$) => {
      jQuery(input$)
        .on(
          'input',
          doRafEvent((e) => {
            renderImageEditor(image_editor_gl, view_gl, adjustment_inputs$, null, false);
          }),
        )
        .on(mobileEvt('mouseup'), (e) => {
          $addClass('color_changed', view_canvas);
        });
    }),
  );
};

export const cropEvents = (tab_el$, image_editor_gl, view_gl) => {
  const shift$ = $findAll('.crop_item_buttons .button[item*="shift"]', tab_el$);
  const adjustment_inputs$ = getAdjustmentInputs$(tab_el$);
  const crop_sliders$ = $findAll('.crop_items input[type="range"]', tab_el$);
  const view_canvas = view_gl.canvas;
  let cancel_vp_fit = noop;
  let cancel_grid_fit = noop;
  changeMoveItemsActive(0, 0);
  go(
    $findAll('.crop_item_sliders input', tab_el$),
    each((input$) => {
      jQuery(input$)
        .on(mobileEvt('mousedown'), (e) => {
          animateMinorGridLine(view_gl, 0, 2.0, (fn) => (cancel_grid_fit = fn), 1.0);
          // const ct = e.currentTarget;
          //viewport 초기화 여부 확인
          // const initialized = $hasClass('initialized', ct);
          // if (isCropChange(view_gl) && !initialized) {
          //   cancel_vp_fit();
          //   cancel_grid_fit();
          // $attr('item', e.currentTarget) === 'rotation' &&
          //즉시 전체 view를 보여줌
          // animateViewportFitting(
          //   view_gl,
          //   0,
          //   G.is_pc_size() ? 2.5 : 2.0,
          //   (fn) => (cancel_vp_fit = fn),
          //   true,
          // );
          // }
        })
        .on(mobileEvt('mouseup'), (e) => {
          $addClass('transformed', view_canvas);
          cancel_vp_fit();
          cancel_grid_fit();
          // $attr('item', e.currentTarget) === 'rotation' &&
          // 몇 초 있다가 viewport를 크롭 영역에 fitting 시킴
          animateViewportFitting(view_gl, G.is_pc_size() ? 0 : 0, 3.0, (fn) => (cancel_vp_fit = fn), false);
          animateMinorGridLine(view_gl, 100, 2.0, (fn) => (cancel_grid_fit = fn), 0.0);
        })
        .on(
          'input',
          doRafEvent((e) => {
            const ct = e.currentTarget;
            const val = Number(ct.value);
            renderImageEditor(image_editor_gl, view_gl, adjustment_inputs$, crop_sliders$, false);
            $attr('item', ct) === 'rotation' && changeShiftIconsDegree(shift$, val);
          }),
        );
    }),
  );

  go(
    tab_el$,
    //크롭 버튼 "클릭"
    $delegate('click', '.crop_item_buttons .button', (e) => {
      const ct = e.currentTarget;
      if ($hasClass('initializer', ct)) {
        $removeClass('cropped transformed', view_canvas);
        go(crop_sliders$, each($addClass('initialized')));
        changeShiftIconsDegree(shift$, 0);
        animateTransformInit(image_editor_gl, view_gl, 0, 2.5, getAllInputValues('.crop_item_sliders input'));
        changeMoveItemsActive(0, 0);
        cancel_vp_fit();
        cancel_grid_fit();
      } else {
        const item = $attr('item', ct);
        selectedSwitch(ct, '.crop_items', item);
        $attr('item', ct).includes('shift') &&
          calcTransformFitting(view_gl, getAllInputValues('.crop_item_sliders input'));
      }
    }),
    $delegate('dblclick', '.crop_item_buttons .button:not(.initializer)', (e) => {
      if (G.is_pc_size()) {
        const ct = e.currentTarget;
        const item = $attr('item', ct);
        const target_slider$ = $find(`input[item="${item}"]`, tab_el$);
        target_slider$.value = 0;
        $trigger('input', target_slider$);
        jQuery(target_slider$).rangeslider('update', true);
      }
    }),
    $delegate(mobileEvt('mousedown'), '#view_gl_canvas', (e) => {
      cancel_vp_fit();
      cancel_grid_fit();
    }),
  );
};

// 크롭 에디터 모드 이벤트
export const cropCanvasEvents = (tab_el$, image_editor_gl, view_gl) => {
  const canvas$ = view_gl.canvas;
  const frame$ = $closest('.don_frame[frame_name="marstagram_editor"]', tab_el$);
  const transform$ = $findAll('.crop_items input', tab_el$);
  const rotation$ = getInput$('rotation', tab_el$);
  // const scale = getInput$('scale', tab_el$);
  // const x_shift$ = getInput$('x_shift', tab_el$);
  // const y_shift$ = getInput$('y_shift', tab_el$);
  const initial_crop_pos = getCurrentCropPosFromGPU(view_gl);
  const adjustment_inputs$ = getAdjustmentInputs$(tab_el$);
  const transform_inputs$ = $findAll('.crop_item_sliders input', tab_el$);

  let cursor_status;
  let down_coord;
  let down_crop_pos;
  let is_mouse_down = false;
  let multi_touch_vp;
  let multi_touch_crop_pos;
  let transform_slider_data;
  let is_zoom_changed = false;
  // let touch_coord_x;
  // let touch_coord_y;

  const animationCancelers = {
    viewportFit: noop,
    gridline: noop,
    cropout: noop,
    minorGridline: noop,
  };

  const cancelAnimations = (animation_names) => {
    animation_names
      ? each((h) => animationCancelers[h](), animation_names)
      : each((h) => h(), values(animationCancelers));
  };

  // const getTouchData = (e, sel) =>
  //   go(
  //     e.touches,
  //     valuesL,
  //     mapL((x) => x[sel]),
  //     filterL((x) => !isNaN(x)),
  //     takeAll,
  //   );

  // const af =
  !G.is_pc_size() &&
    new AlloyFinger(frame$, {
      touchStart: (e) => {
        is_zoom_changed = false;
        if (e.touches.length > 1) {
          if (e.touches.length > 2) {
            transform_slider_data = getAllInputValues('.crop_item_sliders input');
            // touch_coord_x = getTouchData(e, 'pageX');
            // touch_coord_y = getTouchData(e, 'pageY');
          }
          e.preventDefault();
        }
      },
      pinch: (e) => {
        cancelAnimations(['viewportFit']);
        if (e.touches.length === 2) {
          const shifted_vp = shiftViewport(view_gl, e.zoom - 1.0, multi_touch_vp, multi_touch_crop_pos);
          go(
            view_gl,
            updateViewport(shifted_vp),
            updateViewportScale(shifted_vp.vw / view_gl.canvas.width),
            drawGLcanvas,
          );
          is_zoom_changed = e.zoom !== 1.0;
        }
      },

      rotate: (e) => {
        if (e.touches.length > 2) {
          transform_slider_data.rotation += e.angle / 1.5 / 90;
          if (go($qs('.crop_item .button.selected'), $attr('item')) !== 'rotation') {
            $trigger('click', $qs('.crop_item .button[item="rotation"]'));
          }
          if (!$hasClass('multi_point_rotate', canvas$)) {
            $addClass('multi_point_rotate', canvas$);
            animateViewportFitting(view_gl, 0, 2.0, noop, true);
            animateMinorGridLine(view_gl, 100, 2.0, noop, 1.0);
          }
          setTransformDataToSlider(rotation$, transform_slider_data.rotation);
          renderImageEditor(image_editor_gl, view_gl, adjustment_inputs$, transform$, false);
        }
      },
      multipointStart: () => {
        multi_touch_vp = getCurrentViewportFromGPU(view_gl);
        multi_touch_crop_pos = getCurrentCropPosFromGPU(view_gl, true);
        $addClass('multi_touch', canvas$);
      },
      multipointEnd: (e) => {
        $removeClass('multi_touch', canvas$);
        if ($hasClass('multi_point_rotate', canvas$)) {
          $removeClass('multi_point_rotate', canvas$);
        }
      },
      doubleTap: (e) => {
        const current_editor = go(tab_el$, $find('.editor_body'), $attr('current_editor'));
        if (e.target === canvas$ && current_editor === 'crop') {
          cancelAnimations(['viewportFit']);
          switchingViewportFitting(view_gl, 0, 3.0);
        }
      },
    });

  $on('selectstart', (e) => e.preventDefault())(frame$);

  let ani_canceled = false;

  const mousemoveHandler = doRafEvent((e) => {
    if (!$find('#view_gl_canvas', tab_el$)) return;
    if (!$hasClass('multi_touch', view_gl.canvas)) {
      const cursor_pos = getCursorOrTouchPos(canvas$, e);
      const min_crop_length = 80;
      if (is_mouse_down) {
        if (!ani_canceled) {
          cancelAnimations(['viewportFit']);
          ani_canceled = true;
        }
        if (cursor_status === 'in') {
          animateViewportFitting(view_gl, 0, 3.0, noop, true);
        }
        changeCrop(view_gl, down_crop_pos, cursor_status, cursor_pos, down_coord, min_crop_length);
      } else {
        ani_canceled = false;
        cursor_status = updateCursorStat(
          view_gl,
          cursor_pos,
          getCropToViewCropPos(view_gl, getCurrentCropPosFromGPU(view_gl)),
        );
      }
    }
  });

  const mouseupHandler = (e) => {
    const cursor_pos = getCursorOrTouchPos(canvas$, e);
    if (is_mouse_down && !is_zoom_changed) {
      go(view_gl.canvas, $hasClass('cropped') && $addClass('cropped'));
      cancelAnimations(['viewportFit', 'minorGridline']);
      animateViewportFitting(
        view_gl,
        G.is_pc_size() ? 1000 : 800,
        3.0,
        (fn) => (animationCancelers.viewportFit = fn),
      );
      animateMinorGridLine(view_gl, 300, 2.5, (fn) => (animationCancelers.minorGridline = fn), 0);
    }
    cursor_status = updateCursorStat(
      view_gl,
      cursor_pos,
      getCropToViewCropPos(view_gl, getCurrentCropPosFromGPU(view_gl)),
    );
    is_mouse_down = false;
  };

  go(
    tab_el$,
    $delegate(mobileEvt('mousemove'), '.div_canvas_box', (e) => {
      e.originalEvent.preventDefault();
    }),
    $delegate(mobileEvt('mousedown'), '.editor_body[current_editor="crop"] canvas', (e) => {
      cancelAnimations(['viewportFit', 'minorGridline']);
      animateMinorGridLine(view_gl, 100, 2.5, (fn) => (animationCancelers.minorGridline = fn), 1.0);
      const cursor_pos = getCursorOrTouchPos(canvas$, e);
      down_coord = { down_x: cursor_pos.x, down_y: cursor_pos.y };
      down_crop_pos = getCurrentCropPosFromGPU(view_gl);
      cursor_status = updateCursorStat(
        view_gl,
        cursor_pos,
        getCropToViewCropPos(view_gl, getCurrentCropPosFromGPU(view_gl)),
      );
      is_mouse_down = true;
      go(transform_inputs$, each($removeClass('initialized')));
    }),
    $delegate('dblclick', '.editor_body[current_editor="crop"] canvas', (e) => {
      if (G.is_pc_size()) {
        cancelAnimations(['viewportFit']);
        switchingViewportFitting(view_gl, 0, 3.0);
      }
    }),
    $delegate(mobileEvt('mousedown'), '.crop_item_sliders input', (e) => {
      cancelAnimations(['viewportFit']);
    }),
    $delegate('wheel', '.editor_body[current_editor="crop"] canvas', (e) => {
      e.originalEvent.preventDefault();
      doRafEvent((e) => {
        cancelAnimations(['viewportFit']);
        const wheel_retard = -300;
        const shifted_vp = shiftViewport(view_gl, e.deltaY / wheel_retard);
        go(
          view_gl,
          updateViewport(shifted_vp),
          updateViewportScale(shifted_vp.vw / canvas$.width),
          drawGLcanvas,
        );
      })(e);
    }),
    $delegate('click', '.crop_item_buttons .button.initializer', (e) => {
      const reduced_ratio = $attr('reduced_ratio', canvas$);
      const reduced_crop_pos = {
        left: Math.round(initial_crop_pos.left * (1 - reduced_ratio)),
        right: Math.round(initial_crop_pos.right * (1 - reduced_ratio)),
        top: Math.round(initial_crop_pos.top * (1 - reduced_ratio)),
        bottom: Math.round(initial_crop_pos.bottom * (1 - reduced_ratio)),
      };
      cancelAnimations(['viewportFit']);
      animateCropViewportInitialize(view_gl, 0, 2.5, reduced_crop_pos);
      animateMinorGridLine(view_gl, 100, 2.0, noop, 1.0);
    }),
    ...map(
      (editor_mode) =>
        $delegate('click', `.editor_selection_box button[item="${editor_mode}"]`, async (e) => {
          const ct = e.currentTarget;
          const editor$ = go(ct, $closest('.editor_body'));
          const previous_editor = $attr('current_editor', editor$);
          const sel_editor = ct.getAttribute('item');
          const is_just_opened = go($closest('.don_frame', tab_el$), $hasClass('hide_frame'));
          // const view_gl_canvas$ = canvas$;
          // 방금 editor 열었을 경우에는 무조건 한번 실행. 그외에는 중복 실행 방지
          if (is_just_opened || previous_editor !== sel_editor) {
            const image_editor_gl_canvas$ = $find('#image_editor_gl_canvas', tab_el$);
            const original_w = parseInt(image_editor_gl_canvas$.style.width);
            const original_h = parseInt(image_editor_gl_canvas$.style.height);
            const size_reduce = G.is_pc_size() ? 0.025 : 0.07;
            $setAttr({ reduced_ratio: size_reduce }, canvas$);
            // 크롭모드
            if (sel_editor === 'crop') {
              //크롭 기능 제한인 경우
              if ($hasClass('crop_limit', canvas$)) {
                const ratio = canvas$.width / canvas$.height;
                await $.alert(
                  `${T('image_editor::crop_limit_1')}${
                    ratio < 1 ? T('image_editor::horizontal') : T('image_editor::vertical')
                  }${T('image_editor::crop_limit_2')}`,
                );
              }
              //크롭 기능 제한 아닌 경우
              else {
                selectedSwitch(ct, '.editor_selection_box', $attr('item', ct));
                $setAttr({ current_editor: sel_editor }, editor$);
                const reduced_w = Math.round(original_w * (1 - size_reduce));
                const reduced_h = Math.round(original_h * (1 - size_reduce));
                $setCss({ width: reduced_w, height: reduced_h }, canvas$);
                const crop_pos = mapObject(
                  (x) => Math.round(x * (1 - size_reduce)),
                  getCurrentCropPosFromGPU(view_gl),
                );
                updateUniforms(view_gl, view_gl.programs.view, {
                  u_view_res: { type: '2f', value: [reduced_w, reduced_h] },
                  u_crop_pos: { type: '4fv', value: [Object.values(crop_pos)] },
                });

                cancelAnimations();
                animateCropOut(view_gl, 0, 2.5, 0.4, 1.0, (fn) => (animationCancelers.cropout = fn));
                animateGridLine(view_gl, 0, 2.0, 1.0, (fn) => (animationCancelers.gridline = fn));
                animateMinorGridLine(view_gl, 100, 2.0, noop, 1.0);
                updateCropOn(view_gl, true);

                go(
                  frame$,
                  $off(mobileEvt('mousemove'), mousemoveHandler),
                  $off(mobileEvt('mouseup'), mouseupHandler),
                  $on(mobileEvt('mousemove'), mousemoveHandler),
                  $on(mobileEvt('mouseup'), mouseupHandler),
                );
              }
            }
            // 필터, 조정 모드인 경우
            else {
              selectedSwitch(ct, '.editor_selection_box', $attr('item', ct));
              $setAttr({ current_editor: sel_editor }, editor$);
              getCurrentCropPosFromGPU(view_gl);
              if (previous_editor === 'crop') {
                $setCss({ width: original_w, height: original_h }, canvas$);
                const crop_pos = mapObject(
                  (x) => Math.round(x / (1 - size_reduce)),
                  getCurrentCropPosFromGPU(view_gl),
                );
                updateUniforms(view_gl, view_gl.programs.view, {
                  u_view_res: { type: '2f', value: [original_w, original_h] },
                  u_crop_pos: { type: '4fv', value: [Object.values(crop_pos)] },
                });
              }
              if (sel_editor === 'adjustment') {
                const selected_adjustment_item = $find('.adjustment_item_slider.selected input', tab_el$);
                jQuery(selected_adjustment_item).rangeslider('update', true);
              }
              cancelAnimations();
              animateGridLine(view_gl, 0, 2.0, 0.0, (fn) => (animationCancelers.gridline = fn));
              //크롭 영역 변경되지 않은 경우
              if (cropPosStatus(initial_crop_pos, getCurrentCropPosFromGPU(view_gl)) < 3) {
                animateCropOut(view_gl, 0, 2.5, 1.0, 1.0, (fn) => (animationCancelers.cropout = fn));
                animateViewportFitting(view_gl, 0, 3.0, (fn) => (animationCancelers.viewportFit = fn), true);
              }
              //크롭 영역 변경된 경우
              else {
                // $addClass('cropped', view_gl.canvas);
                animateCropOut(view_gl, 0, 2.5, 0.4, 0.0, (fn) => (animationCancelers.cropout = fn));
                animateViewportFitting(view_gl, 0, 3.0, (fn) => (animationCancelers.viewportFit = fn), false);
              }
              updateCropOn(view_gl, false);
            }
            if (!$hasClass('applied_swiper', ct)) {
              new Swiper($find(`.${editor_mode}_items .swiper-container`, tab_el$), {
                slidesPerView: G.is_pc_size() ? 4 : 'auto',
                spaceBetween: G.is_pc_size() ? 6 : editor_mode == 'filter' ? 12 : 4,
                slidesPerGroup: G.is_pc_size() ? 3 : 1,
                allowTouchMove: !G.is_pc_size(),
                slidesOffsetBefore: G.is_pc_size() ? 0 : editor_mode == 'filter' ? 24 : 20,
                slidesOffsetAfter: G.is_pc_size() ? 0 : 24,
                noSwiping: G.is_pc_size(),
                freeModeMomentumRatio: 0.5,
                freeMode: !G.is_pc_size(),
                navigation: {
                  prevEl: `.${editor_mode}_items .swiper-button-prev`,
                  nextEl: `.${editor_mode}_items .swiper-button-next`,
                },
              });
              $addClass('applied_swiper', ct);
            }
          }
        }),
      ['filter', 'adjustment', 'crop'],
    ),
  );
};
