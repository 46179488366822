import { cond, curry, identity, omit, sel } from 'fxjs/es';
import { ConfigSentryConstantS } from '../../../S/Constant/module/ConfigSentryConstantS.js';
import { ConfigSentryS } from '../../../S/Function/module/ConfigSentryS.js';

const baseBeforeSend = curry((f, event) => {
  if (event?.location?.includes('chrome-extension://fcneeeckalcgcklbloocjmhapagkkbfa')) return null;
  return f(event);
});

const addMakerData = (event) => {
  // event.extra.product_color = omit('_', sel('maker.product_color', window.box()));
  return event;
};

const addProductDetailData = (event) => {
  event.extra.product_color = omit('_', sel('product_detail.product_color.id', window.box()));
  return event;
};

export const getSentryBrowserOption = () => {
  const url = window.location.href;
  const { app } = window.ENV;
  const app_name = ConfigSentryS.getAppName(app);

  const beforeSend = baseBeforeSend(
    cond(
      [({ app, url }) => app === 'mp' && url.includes('product/detail'), () => addMakerData],
      [({ app, url }) => app === 'creator' && /products\/\d+$/.test(url), () => addProductDetailData],
      [() => true, () => identity],
    )({ app, url }),
  );

  const environment = ConfigSentryS.getEnvironmentLabel({
    is_prod: window.ENV?.is_prod,
    is_stg: window.ENV?.is_stg,
  });

  return {
    app: app_name,
    environment,
    enabled: ConfigSentryS.isSentryLoggingEnabled(environment, window.ENV?.is_sentry_active_at_dev),
    release: `${app_name}@${process.env.VERSION}`,
    dsn: 'https://2d0347f0cee34580bf3dafb5b9351211@o4505344647757824.ingest.sentry.io/4505344661192704',
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      /Loading chunk [\d]+ failed/,
      /Loading CSS chunk [\d]+ failed/,
      'SecurityError',
      /googleads/i,
      /googlesyndication/i,
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: Cancelled',
      /Network Error/,
      // /Invariant Violation: Minified invariant #\d+; %s Params: %s/, // 인스타그램 오류
      /__gCrWeb/i, // iOS Chrome error
      /__naverapp__/i,
    ],
    denyUrls: ConfigSentryConstantS.DENY_URLS,
    beforeSend,
    maxValueLength: 500,
    normalizeDepth: 5,
  };
};
