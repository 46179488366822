import { go, html } from 'fxjs/es';
import { $el, $insertBefore, $on, $qs, $remove } from 'fxdom/es';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';

export const hidden_screen = {
  init: (el) => {
    /*z-index 있음...*/
    go(
      html`
        <div
          class="hidden-screen-for-frame"
          style="position:fixed; 
        top:0; 
        left:0;
        right:0;
        bottom:0;
        background: #000;
        opacity: 0.5;
        z-index:1;
"
        ></div>
      `,
      $el,
      $on('click', () => {
        hidden_screen.destroy();
        MuiF.closeFrame();
      }),
      $insertBefore(el),
    );
  },
  destroy: () => {
    const el = $qs('.hidden-screen-for-frame');
    if (el) $remove(el);
  },
};
