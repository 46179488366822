import { each, filter, go, identity, join, map, mapL, pluck, rangeL, takeAll } from 'fxjs/es';
import { height_info } from '../Labeling/F/Constant/stock_label_formats.js';
import { UtilStringS } from '../../../Util/String/S/Function/module/UtilStringS.js';

const getCharacterSetForCode128Atype = (data_str) => {
  return go(
    data_str.split(''),
    map((d) => d.charCodeAt() - 32),
    join(''),
  );
};

const setCode128Type = (data, type) => {
  // code128 은 짝수개의 숫자를 사용해야 한다.
  // c type 은 00 ~ 99 숫자 쌍이 이용되며 반드시 두자리씩 파싱된다. -> 따라서 홀수개 번호가 오면 제일 앞에 0 을 붙여서 Number 로 파싱하도록 한다.
  let str = '';

  if (UtilStringS.isNumericString(data) && ('' + data).length % 2 === 1) {
    // 홀수개 (숫자) -> 0 을 붙여 짝수개수로 변경
    data = '0' + data;
  }

  switch (type) {
    case 'A': {
      str = '>9' + getCharacterSetForCode128Atype(data);
      break;
    }
    case 'B': {
      str = '>:' + data;
      break;
    }
    case 'C': {
      str = '>;' + data;
      break;
    }
    default: {
      str = data;
      break;
    }
  }
  return str;
};

const escapingContentsFromZPLCmd = (content_str) => {
  return typeof content_str === 'string' ? content_str.replace(/~/g, '-').replace(/\^/g, '*') : content_str;
};

export class StockLabelFormat {
  static format_no = 0;
  no;
  constructor(name, dpi, home_x, home_y, is_rotate_clockwise_90, label_width) {
    this.init();
    this.no = StockLabelFormat.format_no++;
    this.label_width = label_width;
    this.name = name;
    this.fields = [];
    this.home_x = home_x;
    this.home_y = home_y;
    this.dpi = dpi;
    this.is_rotate_clockwise_90 = is_rotate_clockwise_90;
    this.zpl_str = this.makeLabelOrientation() + this.makeHomeOrigin();
  }

  init() {
    StockLabelFormat.format_no = 0;
    LabelPage.page_no = 0;
    LabelField.field_no = 0;
  }

  makeLabelOrientation() {
    //Rotation 일때는 Right Justification = 1번
    //Normal 일때는 Left Justification = 0번
    return this.is_rotate_clockwise_90 ? '^FWR,1' : '^FWN,0';
  }

  makeHomeOrigin() {
    return `^LH${this.toDot(this.home_x)},${this.toDot(this.home_y)}`;
  }

  toDownloadFormat() {
    return `^XA^DF${this.name}^FS^CI28` + this.zpl_str + '^XZ';
  }

  toDot = (v) => {
    //300dpi 기준 (= 12 dpmm) 으로 1 dot 이 1/12 mm 이므로 거의 육안구분 어려움.
    //Integer 만 사용
    return Math.round((this.dpi / 25.4) * v);
  };

  setFieldOrigin(x, y) {
    // 만약 90도 시계방향 rotate 출력이라면, x-y 90도 회전한 다음 y 방향을 flip시킴.
    this.zpl_str += `^FO${this.toDot(this.is_rotate_clockwise_90 ? this.label_width - y : x)},${this.toDot(
      this.is_rotate_clockwise_90 ? x : y,
    )}`;
  }

  setFont(font_name, orient, h, w) {
    this.zpl_str += `^A${font_name}${(orient && orient) || ''}${(h && ',' + this.toDot(h)) || ''}${
      (w && ',' + this.toDot(w)) || ''
    }`;
  }

  setDefaultFont(name, h, w) {
    this.zpl_str += `^CF${name}${(h && ',' + h) || ''}${(w && ',' + w) || ''}`;
    // this.zpl_str += `${font_link}`;
  }

  setFieldData(field) {
    const data = field.static_data;
    this.zpl_str += data && data.length ? `^FD${data.join('\\&')}^FS` : `^FN${field.no}^FS`;
  }

  pushFieldData(field, pos_x, pos_y, font_name, font_orient, font_h, font_w) {
    this.zpl_str += field.zpl_field_str;
    this.setFieldOrigin(pos_x, pos_y);
    field.pos_x = pos_x;
    field.pos_y = pos_y;
    field.height = font_h;
    font_name !== undefined && this.setFont(font_name, font_orient, font_h, font_w);
    this.setFieldData(field);
  }

  makeField(title, type, static_data, mutator, tree_name, scan_code_type) {
    //type: static or dynamic
    const field = new LabelField(title, type, this.toDot, static_data, mutator, tree_name, scan_code_type);
    this.fields.push(field);
    return field;
  }
}

export class LabelField {
  static field_no = 0;
  no;
  constructor(title, type, toDot, field_static_data, mutator, tree_name, scan_code_type) {
    this.no = LabelField.field_no++;
    this.zpl_field_str = '';
    this.title = title;
    this.type = type;
    this.toDot = toDot;
    this.mutator = mutator;
    this.static_data = field_static_data;
    this.tree_name = tree_name;
    this.width = 0;
    this.scan_code_type = scan_code_type;
    return this.no;
  }

  setCode93BarCode({
    orientation = 'N',
    height,
    print_int_line = 'N',
    print_int_line_above = 'N',
    UCC_check_digit = 'Y',
    module_width = 2,
  }) {
    this.zpl_field_str += `^BY${module_width}^BA${orientation},${this.toDot(
      height,
    )},${print_int_line},${print_int_line_above},${UCC_check_digit}`;
  }

  setCode128BarCode({
    orientation = '',
    height,
    print_int_line = 'N',
    print_int_line_above = 'N',
    UCC_check_digit = 'Y',
    module_width = 2,
  }) {
    this.zpl_field_str += `^BY${module_width}^BC${orientation},${this.toDot(
      height,
    )},${print_int_line},${print_int_line_above},${UCC_check_digit}`;
  }

  setAztecCode(mag) {
    //orient: N = normal, R = rotated, I = 180, B = 270
    //mag: magnification factor 1 ~ 10 (defualt: 3 for 300 dpi, 6 for 600 dpi)
    this.zpl_field_str += `^B0,${mag}`;
  }

  setImage({ x, y, name }) {
    let image_str = '';
    switch (name) {
      case 'marpple': {
        image_str = `^FO${this.toDot(x)},${this.toDot(
          y,
        )}^GFA,60,60,3,,:00F,06F6,0EF7,1EF78,1CF38,,3EF7C,:::,1CF38,1EF78,0EF7,06F6,00F,,:^FS`;
        break;
      }
    }
    this.zpl_field_str += image_str;
  }

  setFieldBox(width, max_line, line_gap, text_justification, indent) {
    //width: in dot
    //line_gap: -9999 ~ 9999
    //text_justification: L = left, C = center, R = right, J = justified
    //indent: next line indent 0 ~ 9999
    this.width = width;
    this.zpl_field_str += `^FB${this.toDot(width)},${max_line},${Math.round(
      this.toDot(line_gap),
    )},${text_justification},${indent}`;
  }
}

export class LabelPage {
  static page_no = 0;
  no;
  constructor(format, page_data, tree_name) {
    //manual_input_arr = {x, y, y_step, code}
    this.no = LabelPage.page_no++;
    this.format = format;
    this.fields = format.fields;
    this.page_data = page_data;
    this.tree_name = tree_name;
    this.total_row_ctn = page_data[tree_name]?.length;
    this.recall_format_name = format.name;
    this.dpi = format.dpi;
    this.zpl_page_str = `${this.labelling()}`;
  }

  addGraphicBox(pos_x, pos_y, box_w, box_h, thick, color, round, rotation_override = false) {
    round = round ?? '0';
    if (this.format.is_rotate_clockwise_90) {
      [box_w, box_h] = [box_h, box_w];
    }
    this.zpl_page_str += `${this.setFieldOrigin(pos_x, pos_y)}^GB${this.toDot(box_w)},${this.toDot(
      box_h,
    )},${thick},${color},${round}^FS`;
  }

  addStarMark(pos_x, pos_y, height_dot) {
    this.zpl_page_str += `${this.setFieldOrigin(pos_x, pos_y)}^AJN,${height_dot}^FH^FD_E2_98_85^FS`;
  }

  addBlankStarMark(pos_x, pos_y, height_dot) {
    this.zpl_page_str += `${this.setFieldOrigin(pos_x, pos_y)}^AJN,${height_dot}^FH^FD_E2_98_86^FS`;
  }

  addText(pos_x, pos_y, height_dot, text) {
    this.zpl_page_str += `${this.setFieldOrigin(pos_x, pos_y)}^AJR,${height_dot}^FD${text}\\&^FS`;
  }

  addRepressMark(pos_x, pos_y, height_dot) {
    this.zpl_page_str += `${this.setFieldOrigin(pos_x, pos_y)}^A0N,${height_dot}^FH^FD_E2_86_94^FS`;
  }

  addGraphicCircle(pos_x, pos_y, dia, thick) {
    this.zpl_page_str += `${this.setFieldOrigin(pos_x, pos_y)}^GC${dia},${thick}^FS`;
  }

  toDot(v) {
    //300dpi 기준 (= 12 dpmm) 으로 1 dot 이 1/12 mm 이므로 거의 육안구분 어려움.
    //Integer 만 사용
    return Math.round((this.dpi / 25.4) * v);
  }

  recallFormat() {
    return `^XF${this.recall_format_name}^FS`;
  }

  addEOFLines(total_page_no) {
    const pos_y = height_info.margin_top + height_info.header + height_info.row * (this.total_row_ctn - 1);
    const label_width = 110;
    this.addGraphicBox(0, pos_y, label_width, 0.3, 4, 'B', 0);
    this.addGraphicBox(0, pos_y + 1, label_width, 0.3, 4, 'B', 0);
    this.zpl_page_str +=
      this.setFieldOrigin(label_width / 2 - 5, pos_y + 2) + `^CF0,25^FD${this.no + 1}/${total_page_no}^FS`;
  }

  setFieldOrigin(x, y) {
    return `^FO${this.toDot(
      this.format.is_rotate_clockwise_90 ? this.format.label_width - y : x,
    )},${this.toDot(this.format.is_rotate_clockwise_90 ? x : y)}`;
  }

  printCheckBoxes(pos_x, pos_y, pos_y_step, w_dot, h_dot, t_dot, round) {
    this.zpl_page_str += go(
      rangeL(this.total_row_ctn),
      mapL(
        (row_no) =>
          `${this.setFieldOrigin(
            pos_x,
            pos_y + row_no * pos_y_step,
          )}^FD^GB${w_dot},${h_dot},${t_dot},B,${round}^FS`,
      ),
      takeAll,
      join(''),
    );
  }

  printFieldBox() {
    go(
      this.format.fields,
      each((field) => {
        if (field.width) {
          this.addGraphicBox(field.pos_x, field.pos_y, field.width, field.height, 0.5, 'black');
        }
      }),
    );
  }

  labelling() {
    //format 에 들어 있는 fields array 데이터를 추출하고, 그 중 variable 변수로 타입 지정되어 있는 것에 대해
    //전달 받은 data의 field.title을 찾아서 반환.
    //만약에 tree_name 이 있다면 tree_name의 child 안에서 field.title을 찾아서 반환
    //반환된 value 를 mutator 함수에 통과
    return go(
      this.format.fields,
      map((field) => {
        if (field.type === 'variable') {
          let field_data_str;
          if (field.tree_name) {
            field_data_str = go(
              this.page_data[field.tree_name],
              pluck(field.title),
              map((val) => (field.mutator ? field.mutator(val) : val)),
              join('\\&'),
            );
          } else {
            const val = this.page_data[field.title];
            field_data_str = field.mutator ? field.mutator(val) : val;
          }
          field_data_str = escapingContentsFromZPLCmd(field_data_str);
          if (field.title === 'scan_info') {
            //scan_info (aztec code) 생성 문자열에는 앞에 # 을 붙여줌
            return `^FN${field.no}^FD${field_data_str}^FS`;
          } else if (field.scan_code_type) {
            const [code_name, sub_type] = field.scan_code_type.split(',');
            switch (code_name) {
              case 'code93': {
                return `^FN${field.no}^FD${field_data_str}^FS`;
              }
              case 'code128': {
                //sub_type can be 'A', 'B', 'C'
                return `^FN${field.no}^FD${setCode128Type(field_data_str, sub_type)}^FS`;
              }
              case 'aztec': {
                return `^FN${field.no}^FD${field_data_str}^FS`;
              }
            }
          } else {
            //일반 문자열에는 carriage return 을 붙여줌 (ZPL에서는 앰퍼샌드)
            return `^FN${field.no}^FD${field_data_str ?? ''}^FS`;
          }
        }
      }),
      filter(identity),
      join(''),
    );
  }

  toZPLStr(print_ctn) {
    return `^XA${
      this.total_row_ctn
        ? `^LL${this.toDot(
            height_info.margin_top +
              height_info.header +
              height_info.row * this.total_row_ctn +
              height_info.margin_bottom,
          )}`
        : ''
    }${this.recallFormat()}${this.zpl_page_str}^PQ${print_ctn ?? '1'}^XZ`;
  }
}
