import { each, equals2, flatMap, go, isNil, mapL, rejectL, takeAllC } from 'fxjs/es';
import { CommonNS } from '@marpple/sticker-editor';
import { VectorEditorStickerSingleMobileF } from '../../../../Single/Mobile/F/Function/module/VectorEditorStickerSingleMobileF.js';
import { VectorEditorSettingBackgroundMobileF } from '../../../../../SettingBackground/Mobile/F/Function/module/VectorEditorSettingBackgroundMobileF.js';
import { VectorEditorStickerFreeMobileF } from '../Function/module/VectorEditorStickerFreeMobileF.js';

export const handleFooterNoneSetBackgroundIconClick =
  ({ tab_el }) =>
  () =>
    VectorEditorSettingBackgroundMobileF.setBackground({
      getInitBackground: () => {
        const background_value_vo = tab_el.__mp_free_sticker_editor?.getBackground?.()?.value;
        if (equals2(background_value_vo?.type)('color')) {
          return { color: background_value_vo.fill };
        } else {
          tab_el.__mp_free_sticker_editor?.setBackground?.({
            type: 'color',
            fill: 'none',
            opacity: 1,
          });
          return { color: 'none' };
        }
      },
      setBackground: ({ color }) => {
        tab_el.__mp_free_sticker_editor?.setBackground({
          type: 'color',
          fill: color,
          opacity: 1,
        });
      },
    });

export const handleFooterNoneMakeStickerIconClick =
  ({ tab_el }) =>
  () =>
    VectorEditorStickerFreeMobileF.addNewSticker({ tab_el });

export const handleFooterSelectEditStickerIconClick =
  ({ tab_el }) =>
  async () => {
    const el = tab_el?.__mp_free_sticker_editor?.getSelectedEls?.()?.[0];
    if (el == null) {
      $.alert(T('modules::VectorEditor::Sticker::message::선택된 스티커가 없습니다.'));
      return;
    }
    const { id, target_image_el } = el;

    const clone_target_image_el = await (async () => {
      $.don_loader_start();
      try {
        return await CommonNS.UtilNS.deepCloneNode(target_image_el);
      } finally {
        $.don_loader_end();
      }
    })();

    const { fill, opacity } = (() => {
      const background_vo = tab_el.__mp_free_sticker_editor?.getBackground();
      if (!equals2(background_vo?.value?.type)('color')) {
        tab_el.__mp_free_sticker_editor?.setBackground({
          type: 'color',
          fill: '#ffffff',
          opacity: 1,
        });
        return { fill: '#ffffff', opacity: 1 };
      }
      return background_vo.value;
    })();

    tab_el.__mp_is_prevent_mouse_move = false;
    try {
      let is_added = false;
      await VectorEditorStickerSingleMobileF.makeSticker({
        art_board_size: tab_el.__mp_single_art_board_size,
        foreground_el: null,
        empty_template_el: tab_el.__mp_single_empty_template_svg_el?.cloneNode?.(true) ?? null,
        preProcess: (single_sticker_editor) => {
          go(
            clone_target_image_el.querySelectorAll(
              `[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="working-layer"]`,
            ),
            flatMap((layer_el) => layer_el.children),
            each((el) => single_sticker_editor.addEl(el)),
          );
        },
        postProcess: ({ target_image_el, path_data }) => {
          tab_el?.__mp_free_sticker_editor?.removeEl(id);
          const { id: new_id, wrapper_el } = tab_el?.__mp_free_sticker_editor?.addEl({
            target_image_el,
            cutting_line_path_data: path_data,
          });
          is_added = true;
          tab_el?.__mp_free_sticker_editor?.selectEls([new_id]);

          const wrapper_bbox = wrapper_el.getBBox();
          let wrapper_center_point = target_image_el.createSVGPoint();
          wrapper_center_point.x = wrapper_bbox.x + wrapper_bbox.width / 2;
          wrapper_center_point.y = wrapper_bbox.y + wrapper_bbox.height / 2;
          const wrapper_consolidated_transform_matrix =
            CommonNS.UtilNS.getConsolidatedTransformMatrix(wrapper_el) ?? target_image_el.createSVGMatrix();
          wrapper_center_point = wrapper_center_point.matrixTransform(wrapper_consolidated_transform_matrix);

          const art_board_center_x = tab_el.__mp_art_board_size.width / 2;
          const art_board_center_y = tab_el.__mp_art_board_size.height / 2;

          const tx = art_board_center_x - wrapper_center_point.x;
          const ty = art_board_center_y - wrapper_center_point.y;

          tab_el?.__mp_free_sticker_editor?.translateEl?.({ id: new_id, tx, ty });
        },
        is_need_cutting_line: true,
        can_set_background: false,
        background: { fill, opacity },
      });
      if (is_added && tab_el.__mp_free_template_svg_el != null) {
        tab_el.__mp_free_template_svg_el.dataset.is_show = 'false';
        VectorEditorStickerFreeMobileF.show2TypeNoneMakeStickerFooter(tab_el);
      }
    } finally {
      tab_el.__mp_is_prevent_mouse_move = true;
    }
  };

export const handleFooterSelectDuplicateIconClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_free_sticker_editor?.getIsFull?.()) {
      await $.alert(T('modules::VectorEditor::Sticker::message::스티커는 최대 20개까지 생성할 수 있습니다.'));
      return;
    }

    $.don_loader_start();
    try {
      await go(
        tab_el?.__mp_free_sticker_editor?.getSelectedEls?.() ?? [],
        mapL(({ id }) => id),
        rejectL(isNil),
        mapL((id) => tab_el?.__mp_free_sticker_editor?.copyEl?.(id)),
        rejectL(isNil),
        takeAllC,
        (ids) => tab_el?.__mp_free_sticker_editor?.selectEls?.(ids),
      );
    } finally {
      $.don_loader_end();
    }
  };

export const handleFooterSelectDeleteIconClick =
  ({ tab_el }) =>
  () => {
    go(
      tab_el?.__mp_free_sticker_editor?.getSelectedEls?.() ?? [],
      mapL(({ id }) => id),
      rejectL(isNil),
      each((id) => tab_el?.__mp_free_sticker_editor?.removeEl?.(id)),
    );
  };
