import { each, go } from 'fxjs/es';
import { getActiveCvObj, getActiveFcanvas } from '../../../Get/F/Function/get.js';

export const removeCvObj = (cv_obj) => {
  const fcanvas = cv_obj?.canvas;
  if (!fcanvas) return;
  if (cv_obj._data.cv_type !== 'cv_group' && cv_obj._objects?.length) {
    const active_temp_group = cv_obj;
    go(
      [...active_temp_group._objects],
      each((cv_obj) => {
        active_temp_group.removeWithUpdate(cv_obj);
        fcanvas.remove(cv_obj);
      }),
    );
    fcanvas.discardActiveGroup();
    fcanvas.remove(cv_obj);
    return fcanvas;
  }
  if (cv_obj) {
    fcanvas.remove(cv_obj);
    return fcanvas;
  }
};

export const removeActiveCvObj = async () => {
  removeCvObj(getActiveCvObj(getActiveFcanvas()));
  await G.mp.maker.modified();
};
