import { printSkuLabel } from './label.sku.js';
import { UtilAlertF } from '../../../../Util/Alert/F/Function/module/UtilAlertF.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';

export async function handleSkuLabelPrint({ currentTarget: ct }) {
  try {
    DfInhouseF.lottie_loader.start('printer');
    const upc = box.sel(ct);

    await printSkuLabel({ upc });
  } catch (err) {
    console.error(err);
    await UtilAlertF.error({ title: 'SKU 라벨 생성 오류', msg: err.message, timer: 7000 });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}
