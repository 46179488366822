import { flat, go, map, once, sumBy } from 'fxjs/es';
import { AdScriptCriteoMpConstantF } from '../Constant/module/AdScriptCriteoMpConstantF.js';

export const purchase = once((projection) => {
  if (!projection || !window.criteo_q || projection.lang != 'kr') return;

  window.criteo_q.push(
    ...AdScriptCriteoMpConstantF.eventSet({
      event: 'trackTransaction',
      id: projection.id,
      item: map(function (up) {
        const up_c_ss = go(
          up._.up_cs,
          map(function (up_c) {
            return up_c._.up_c_ss;
          }),
          flat,
        );

        const quantity = sumBy(function (up_c_s) {
          return up_c_s.quantity;
        }, up_c_ss);

        return {
          id: up.base_product_id,
          price: up._.product_color['price' + _en],
          quantity,
        };
      }, projection._.ups),
    }),
  );
});
