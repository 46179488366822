export const makeCurrencyPrice = (price, lang) => {
  if (lang === 'kr') return `${price}원`;
  if (lang === 'en') return `$${price}`;
  if (lang === 'jp') {
    if (G.collabo_type === '') return `${price}円`;
    else return `¥${price}`;
  }
};

export const getPriceCurrency = () => {
  if (T.lang === 'kr') return `원`;
  if (T.lang === 'en') return `$`;
  if (T.lang === 'jp') return `円`;
};
