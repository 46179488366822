/**
 * @define File 객체 -> FormData 생성
 * */
export function getFileFormData({ file }) {
  if (!(file instanceof File)) throw new Error(`file is not the instance of File`);

  const formData = new FormData();
  formData.append('file', file);

  return formData;
}

/**
 * @define pdf 단일 파일 업로드 input trigger
 * */
export function triggerPdfFileInput() {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';
    input.multiple = false;

    input.addEventListener('change', function () {
      const file = input.files[0];
      resolve(file);
    });

    input.click();
  });
}
