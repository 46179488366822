export default function (lo) {
  if (box.sel('task->is_hidden'))
    return $.html(
      $1('#body'),
      `<h1 style="margin-top:150px;"> #${box.sel('task->id')}는 삭제된 태스크입니다.</h1>`,
    );

  $.frame({
    frame_name: 'df.task.detail',
    frame_tag: _p.last(location.pathname.split('/task/detail/')),
  });
}
