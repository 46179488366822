export const TOP_MENU = {
  HISTORY_RESET: {
    ID: 'history_reset',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_history_reset'),
  },
  HISTORY_UNDO: {
    ID: 'history_undo',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_history_undo'),
  },
  HISTORY_REDO: {
    ID: 'history_redo',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_history_redo'),
  },
  TOGGLE_LAYERS_MODE: {
    ID: 'toggle_layers_mode',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_toggle_layers_mode'),
  },
  DUPLICATE: {
    ID: 'duplicate',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_duplicate'),
  },
  DELETE: {
    ID: 'delete',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_delete'),
  },
  FORWARD: {
    ID: 'forward',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_forward'),
  },
  BACKWARD: {
    ID: 'backward',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_backward'),
  },
  GROUP: {
    ID: 'group',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_group'),
  },
  UNGROUP: {
    ID: 'ungroup',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_ungroup'),
  },
  FLIP_HORIZONTAL: {
    ID: 'flip_horizontal',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_flip_horizontal'),
  },
  FLIP_VERTICAL: {
    ID: 'flip_vertical',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_flip_vertical'),
  },
  MOVE_TO_LEFT_END: {
    ID: 'move_to_left_end',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_move_to_left_end'),
  },
  MOVE_TO_RIGHT_END: {
    ID: 'move_to_right_end',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_move_to_right_end'),
  },
  MOVE_TO_TOP_END: {
    ID: 'move_to_top_end',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_move_to_top_end'),
  },
  MOVE_TO_BOTTOM_END: {
    ID: 'move_to_bottom_end',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_move_to_bottom_end'),
  },
  MOVE_TO_HORIZONTAL_CENTER: {
    ID: 'move_to_horizontal_center',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_move_to_horizontal_center'),
  },
  MOVE_TO_VERTICAL_CENTER: {
    ID: 'move_to_vertical_center',
    NAME: T('modules::VectorEditor::TopMenu::PC::template::top_menu_move_to_vertical_center'),
  },
};
