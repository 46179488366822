import { $qs, $trigger } from 'fxdom/es';
import { MuiF } from '../../../../modules/Mui/F/Function/module/MuiF.js';
const initReviewWrite = () => {
  const location = G.$location();
  MuiF.extendHistoryState(null, null, location.pathname, true);
  if (location.query?.verified == 'true') $.alert(T('login::Your email address is verified.'));
  if (location.query?.review == 'true') $trigger('click', $qs('.write_a_review'));
};
export default function (lo) {
  _p.go($.frame({ frame_name: 'mp.order.detail' }), initReviewWrite);
}
