import { $addClass, $appendTo, $el, $find, $qs, $remove, $removeClass, $setText } from 'fxdom/es';
import { delay, go, html } from 'fxjs/es';

function percent(current, total) {
  return `${Math.min(Math.round((current / total) * 100, 100))}%`;
}
export const init = ({ message, clazz = 'pc', work_count, percentage }) => {
  const total_work_count = work_count;
  let current_work_count = 0;

  const el = go(
    html`
      <div class="process_time_loader ${clazz}">
        <div class="process_time_loader__container">
          <div class="process_time_loader__percent">
            ${percentage ? 0 : percent(current_work_count, total_work_count)}
          </div>
          <div class="process_time_loader__message">${message}</div>
        </div>
      </div>
    `,
    $el,
  );
  const percent_el = go(el, $appendTo($qs('body')), $find('.process_time_loader__percent'));
  const loaderDone = async () => {
    if (!$qs('.process_time_loader')) return;
    $removeClass('fixed')($qs('html'));
    $removeClass('fixed')($qs('body'));
    await delay(800, null);
    $remove(el);
  };
  $addClass('fixed')($qs('html'));
  $addClass('fixed')($qs('body'));
  return async (percentage) => {
    if (percentage) {
      $setText(percentage)(percent_el);
      if (percentage > 98) {
        await loaderDone();
      }
    } else {
      current_work_count++;
      $setText(percent(current_work_count, total_work_count))(percent_el);
      if (current_work_count === total_work_count) await loaderDone();
    }
  };
};
