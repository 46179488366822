import { go } from 'fxjs/es';
import { $delegate, $on, $prependTo, $setCss } from 'fxdom/es';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';

export const halfFrameClose = (frame_el$) => {
  go(
    frame_el$,
    $delegate('click', '>.don_wrapper >.header', (e) => {
      MuiF.closeFrame();
    }),
  );
  go(
    document.createElement('div'),
    $setCss({
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }),
    $prependTo(frame_el$),
    $on('click', (e) => {
      MuiF.closeFrame();
    }),
  );
};
