import { compact, filter, find, flatMap, go, html, map, strMap } from 'fxjs/es';
import { BpOptionConstantS } from '../Constant/module/BpOptionConstantS.js';
import { UtilArrayS } from '../../../Util/Array/S/Function/module/UtilArrayS.js';
import { OMPDosuS } from '../../../OMP/Dosu/S/Function/module/OMPDosuS.js';
import {
  getMaxDosuCountInFacesOptionGroups,
  selectBaseProductFaceOptionGroup,
  selectDosuCountOptionByCount,
} from './bpoption.js';
import { NewMakerPropertyBpOptionS } from '../../../NewMaker/Property/BpOption/S/Function/module/NewMakerPropertyBpOptionS.js';

export function convertBpBpogToBizBpog({ base_products_bp_option_groups }) {
  return go(
    base_products_bp_option_groups,
    map((g) => {
      const { base_product_face_id, can_change, default_bp_option_id } = g;
      return { base_product_face_id, can_change, default_bp_option_id, ...g._.bp_option_group };
    }),
    filter((g) => BpOptionConstantS.BIZ_RELATED_OPTION_GROUP_DEV_NAMES.includes(g.dev_name)),
  );
}

export function hasBpDosuRelatedOptions({ bp_option_groups }) {
  if (bp_option_groups != null) {
    return !!bp_option_groups.find(
      (g) => g.dev_name === BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COUNTS,
    );
  }
  return false;
}

export function getBizOptionIds({ biz_bp_option_groups }) {
  return go(
    biz_bp_option_groups,
    flatMap((g) => map((o) => o.id, g._.bp_options)),
  );
}

function cv_objects_deep_each(cv_object, func) {
  return (function f(cv_object) {
    if (!cv_object) return;
    const objects = cv_object._objects || cv_object.objects;
    if (objects) {
      return _p.each(objects, function (cv_object) {
        return f(cv_object);
      });
    }
    return _p.is_array(cv_object)
      ? _p.each(cv_object, function (cv_obj) {
          return f(cv_obj);
        })
      : func(cv_object);
  })(cv_object);
}

export const makerHelper = {
  get: {
    unGroupDesigns: (pf_designs) => {
      const design_arr = [];
      cv_objects_deep_each(pf_designs, (cv_obj) => {
        design_arr.push(cv_obj);
      });
      return design_arr;
    },
  },
};

export function getBizDosuOptionCardHtml({ pf2, base_products_bp_option_groups, base_product }) {
  const body_params = go(
    pf2,
    // 불필요한 카드 필터 로직
    filter((pf) => {
      if (UtilArrayS.isEmNil(pf?.designs)) return;
      return pf;
    }),
    map((pf) => {
      const bpf_id = pf.bpf_id;
      const selected_dosu_related_bp_option_groups = selectBaseProductFaceOptionGroup({
        base_products_bp_option_groups,
        bpf_id,
      });
      const used_colors = OMPDosuS.cvDesign.get.usedDosuColorFromPf(pf);
      const dosu_ctn = used_colors.length;

      const selected_dosu_count_option = selectDosuCountOptionByCount({
        bp_option_groups: selected_dosu_related_bp_option_groups,
        dosu_ctn,
      });
      const dosu_max_ctn = getMaxDosuCountInFacesOptionGroups({
        face_option_groups: selected_dosu_related_bp_option_groups,
        bpf_id,
      });
      let color_name;
      if (dosu_max_ctn === 1 && used_colors.length) {
        const bp_option = NewMakerPropertyBpOptionS.dosuColors.findBpOption({
          bpf_id,
          base_product,
          color_code: used_colors[0],
        });
        if (bp_option) color_name = bp_option['name' + G._en];
      }
      if (!dosu_ctn) return;
      return {
        face_name: pf['face_name' + G._en],
        selected_dosu_count_option,
        dosu_ctn,
        used_colors,
        dosu_max_ctn,
        color_name,
      };
    }),
    compact,
  );
  if (!body_params?.length) return;
  const error_face = go(
    body_params,
    find(({ dosu_ctn, dosu_max_ctn }) => dosu_ctn > dosu_max_ctn),
  );

  return html`
    <div class="dosu_option_cards section ${error_face ? 'dosu_option_cards__error' : ''}">
      <div class="head">
        ${body_params[0]?.selected_dosu_count_option?.name?.includes('자수')
          ? TT('biz::opt::title::embroidery_color')
          : TT('biz::opt::title::print_color')}
      </div>
      <div class="body">
        ${go(
          body_params,
          strMap(
            ({ face_name, selected_dosu_count_option, dosu_ctn, used_colors, dosu_max_ctn, color_name }) => {
              return html`
                <div class="dosu_option_card">
                  <div class="description option_summary">
                    <span class="option_text">
                      <span class="option_text__title">${face_name} : </span>
                      <span class="option_text__body"
                        >${bizOptionCardSummaryText({
                          selected_dosu_count_option,
                          dosu_ctn,
                          color_name,
                        })}</span
                      >
                    </span>
                  </div>
                  <div class="description option_color">
                    <div class="color-chips">
                      ${strMap(
                        (hex_color) =>
                          html`<span class="color-chip" style="background-color:${hex_color};"></span>`,
                        used_colors.slice(0, dosu_max_ctn),
                      )}
                      ${dosu_ctn > dosu_max_ctn
                        ? html`<div class="color_count">
                            <span class="count"
                              >+${used_colors.length - dosu_max_ctn > 99
                                ? '99+'
                                : used_colors.length - dosu_max_ctn}</span
                            >
                          </div>`
                        : ''}
                    </div>
                  </div>
                </div>
              `;
            },
          ),
        )}
      </div>
      <div class="footer">
        <span class="dosu_option_cards__footer-message">
          ${error_face
            ? TT('biz::opt::title::error_message', {
                dosu_max_ctn: error_face.dosu_max_ctn,
              })
            : ``}
        </span>
      </div>
    </div>
  `;
}

function bizOptionCardSummaryText({ selected_dosu_count_option, dosu_ctn, color_name }) {
  /* 옵션들 요약 로직
   *  1. 대 순서 : 도수 수량 (제작 방식: 도수 수량에 옵션명을 함께 사용) - 도수 색상
   *  2. 중 순서 :
   *     - 도수 수량 [dosu_counts] : bp 도수 옵션이 있으면 -> 선택된 도수 옵션 이름, 없으면 -> 풀컬러 인쇄
   *     - 도수 색상 [dosu_colors] : 별색 중 하나만 표시
   * */
  let option_summary_text = ``;

  option_summary_text +=
    selected_dosu_count_option == null
      ? TT('biz::opt::title::more_dosu', { dosu_ctn })
      : selected_dosu_count_option['name' + G._en] === color_name
      ? color_name
      : `${selected_dosu_count_option['name' + G._en]}${color_name ? ` - ${color_name}` : ''}`;

  // 도수 컬러
  // const dosu_colors_selected_options =
  //   option_by_group_name[BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COLORS];
  //
  // if (dosu_colors_selected_options != null) {
  //   const spot_color_options = filter((o) => {
  //     const [, spot_color_name] = o.dev_name.split('**'); // 별색 대응
  //     return spot_color_name;
  //   }, dosu_colors_selected_options);
  //   if (UtilArrayS.isArrayOk(spot_color_options)) {
  //     option_summary_text += ' / ' + spot_color_options[0]['name' + G._en];
  //   }
  // }

  return option_summary_text;
}

export const getBpDiscountRanges = (product_color) => {
  return go(product_color._.base_product._.bp_discount_set?._?.bp_discount_ranges);
};
