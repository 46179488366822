import './route.js';

export const frameOpenReviewWrite = async (up, current_target) => {
  const prj = await $.get('/@api/review/projection_status', { up_id: up.id });
  if (prj || (await $.confirm(T('review::The status will be changed to Delivered'))))
    $.frame.open(
      {
        frame_name: 'mp.review.write',
        frame_tag: up.id,
        closing: function () {
          if (window.box.sel('writing_review->id')) return;
          $.after(current_target, '<div>' + T('review::Thank you for your comment') + '.</div>');
          $.remove(current_target);
        },
        removed: () => {
          if (!prj) window.location.reload();
        },
      },
      {
        page_name: 'mp.review.write',
        tabs: [
          {
            tab_name: 'mp.review.write',
            data_func: () => up,
          },
        ],
      },
    );
};
