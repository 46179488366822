import { go } from 'fxjs/es';
import { $closest, $find, $setVal, $trigger, $val } from 'fxdom/es';

export const selectQuantityEvent = (e) => {
  const quantity = go(e.currentTarget, $val, parseInt);
  go(e.currentTarget, $closest('.mp-select-product-quantity'), $find('input[type="number"]'), (input_el) => {
    $setVal(quantity, input_el);

    $trigger('input', input_el);
    $trigger('change', input_el);
  });
};
