import { html } from 'fxjs/es';
import { MP_MAIL_ORDER_SALES } from '../../../../../Mp/Address/S/Constant/address.js';
import { MpAddressConstantS } from '../../../../../Mp/Address/S/Constant/module/MpAddressConstantS.js';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

const makeFooterKrHtml = () => {
  return html`
    <div class="omp-footer">
      <h3 class="omp-footer__logo">
        <a class="omp-footer__logo-a" href="/${TT.lang}/">
          <img
            class="omp-footer__logo-img"
            src="//s3.marpple.co/files/u_218933/2023/7/original/f01a70531eac8ab8ced3654146a153db12da80a91.svg"
            alt="MARPPLE"
          />
        </a>
      </h3>
      <div class="omp-footer__customer">
        <h4 class="omp-footer__customer-title">고객센터</h4>
        <div class="omp-footer__customer-content">평일 10:00 ~ 18:00</div>
        <div class="omp-footer__customer-email">
          <a class="omp-footer__customer-email-a" href="mailto:help@marpple.com">help@marpple.com</a>
        </div>
        <div class="omp-footer__buttons">
          <div class="omp-footer__call omp-footer__button">
            <a class="omp-footer__button-a" href="tel:1566-9437">1566-9437</a>
          </div>
          <div class="omp-footer__button live_chat">
            <span class="omp-footer__button-a">
              <span class="omp-footer__live-chat-icon"
                >${OMPCoreAtomTmplS.customerIcon({ color: 'WHT' })}</span
              >
              1:1 채팅
            </span>
          </div>
        </div>
      </div>
      <div class="omp-footer__footer-menus">
        <div class="omp-footer__footer-menu" data-open="false">
          <h4 class="omp-footer__footer-menu-h4">
            About
            <span class="omp-footer__footer-menu-title-icon"
              >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
            >
          </h4>
          <ul class="omp-footer__footer-menu-ul">
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/about">마플에 처음 오셨나요?</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/review/list?is_best=true&is_all=false">실시간 리뷰</a>
            </li>
            <!--            <li class="omp-footer__footer-menu-li">-->
            <!--              <a href="/${TT.lang}/event/list">이벤트</a>-->
            <!--            </li>-->
            <li class="omp-footer__footer-menu-li">
              <a href="https://marpple.shop/${TT.lang}" target="_blank">마플샵</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="https://www.marpplecorp.com/" target="_blank">마플코퍼레이션</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a
                href="https://updatenote.marpplecorp.com/c6e12542-33da-4818-8f24-5f767e5c8a2a"
                target="_blank"
                >업데이트 노트</a
              >
            </li>
          </ul>
        </div>
        <div class="omp-footer__footer-menu" data-open="false">
          <h4 class="omp-footer__footer-menu-h4">
            Help
            <span class="omp-footer__footer-menu-title-icon"
              >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
            >
          </h4>
          <ul class="omp-footer__footer-menu-ul">
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/help_center?type=order_pay_kr">주문 / 결제 문의</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/help_center?type=make_group_kr">제작 / 단체 문의</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/help_center?type=image_edit_kr">이미지 / 프린팅 문의</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="omp-footer__follow">
        <ul class="omp-footer__follow-ul">
          <li class="omp-footer__follow-li omp-footer__follow-li--instagram">
            <a href="https://www.instagram.com/marpple_official/" target="_blank">
              ${OMPCoreAtomTmplS.instaIconMo()}
            </a>
          </li>
          <li class="omp-footer__follow-li omp-footer__follow-li--facebook">
            <a href="https://www.facebook.com/marpplecom" target="_blank">
              ${OMPCoreAtomTmplS.facebookIconMo()}
            </a>
          </li>
          <li class="omp-footer__follow-li omp-footer__follow-li--blog">
            <a href="https://blog.naver.com/marpple" target="_blank"> ${OMPCoreAtomTmplS.blogIconMo()} </a>
          </li>
          <li class="omp-footer__follow-li omp-footer__follow-li--youtube">
            <a href="https://www.youtube.com/@Marpple.official" target="_blank">
              ${OMPCoreAtomTmplS.youtubeIconMo()}
            </a>
          </li>
          <li class="omp-footer__follow-li omp-footer__follow-li--twitter">
            <a href="https://twitter.com/marpple_life" target="_blank">
              ${OMPCoreAtomTmplS.twitterIconMo()}
            </a>
          </li>
        </ul>
      </div>
      <div class="omp-footer__logos">
        <div class="omp-footer__for-the-planet">
          <a href="https://www.onepercentfortheplaneTT.org/" target="_blank">
            <img
              class="don_lazy omp-footer__for-the-planet-img"
              data-src="//s3.marpple.co/files/u_218933/2023/7/original/c1a461770a27a6973657c228591ac9b8aa13754b1.png"
              alt="1% FOR THE PLANET"
            />
          </a>
        </div>
        <div class="omp-footer__iflogo">
          <a href="https://ifdesign.com/en/winner-ranking/project/marpple/206874?q=marpple" target="_blank">
            <img
              class="don_lazy omp-footer__iflogo-img"
              data-src="//s3.marpple.co/files/u_218933/2023/7/original/6328ef8c9ceefb81f3bcc02680d79bdedbf292cf1.png"
              alt=""
            />
          </a>
        </div>
      </div>
      <div class="omp-footer__company-info" data-open="false">
        <div class="omp-footer__company-info-title">
          <span>(주)마플코퍼레이션 사업자 정보</span>
          <span class="omp-footer__company-info-title-icon"
            >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down', color: 'BK' })}</span
          >
        </div>
        <div class="omp-footer__company-info-cont">
          <div>대표 박혜윤</div>
          <div>
            <span
              >사업자등록번호 105-88-13322<a
                href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1058813322&apv_perm_no="
                class="omp-footer__ftc"
                target="_blank"
                >사업자정보 확인</a
              ></span
            >
          </div>
          <div>
            <span>통신판매업신고 ${MP_MAIL_ORDER_SALES}</span>
          </div>
          <div>${MpAddressConstantS.MP_ADDRESS}</div>
          <div class="omp-footer__company-info-info-fax">
            <span class="omp-footer__bar"
              >이메일<a href="mailto:help@marpple.com" class="omp-footer__mail">help@marpple.com</a></span
            >
            <span>팩스 02-6486-9437</span>
          </div>
        </div>
        <div class="omp-footer__copyright">
          <span>&copy; ${new Date().getFullYear()} Marpple Corp. All rights reserved.</span>
        </div>
        <div class="omp-footer__terms">
          <span class="omp-footer__bar">
            <a href="/${TT.lang}/privacy">개인정보취급방침</a>
          </span>
          <span>
            <a href="/${TT.lang}/terms">이용약관</a>
          </span>
        </div>
      </div>
    </div>
  `;
};

const makeFooterEnHtml = () => {
  return html`
    <div class="omp-footer">
      <h3 class="omp-footer__logo">
        <a class="omp-footer__logo-a" href="/${TT.lang}/">
          <img
            class="omp-footer__logo-img"
            src="//s3.marpple.co/files/u_218933/2023/7/original/f01a70531eac8ab8ced3654146a153db12da80a91.svg"
            alt="MARPPLE"
          />
        </a>
      </h3>
      <div class="omp-footer__customer">
        <h3 class="omp-footer__customer-title">Get in Touch</h3>
        <div class="omp-footer__customer-content">
          Visit our FAQ, write us in the chat or contact us at cs@marpple.com
        </div>
        <div class="omp-footer__customer-email">
          <a class="omp-footer__customer-email-a" href="mailto:cs@marpple.com">Email Us</a>
        </div>
        <div class="omp-footer__buttons">
          <div class="omp-footer__button live_chat">
            <span class="omp-footer__button-a">
              <span class="omp-footer__live-chat-icon"
                >${OMPCoreAtomTmplS.customerIcon({ color: 'WHT' })}</span
              >
              1:1 Live Chat
            </span>
          </div>
        </div>
      </div>
      <div class="omp-footer__footer-menus">
        <div class="omp-footer__footer-menu" data-open="false">
          <h4 class="omp-footer__footer-menu-h4">
            About
            <span class="omp-footer__footer-menu-title-icon"
              >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
            >
          </h4>
          <ul class="omp-footer__footer-menu-ul">
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/about">About Us</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/review/list?is_best=true&is_all=false">Reviews</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="https://marpple.shop/${TT.lang}" target="_blank">MARPPLE SHOP</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="https://www.marpplecorp.com/" target="_blank">Marpple Corporation</a>
            </li>
          </ul>
        </div>

        <div class="omp-footer__footer-menu" data-open="false">
          <h4 class="omp-footer__footer-menu-h4">
            Guideline
            <span class="omp-footer__footer-menu-title-icon"
              >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
            >
          </h4>
          <ul class="omp-footer__footer-menu-ul">
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/guideline/images">Image Guideline</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/guideline/copyright">Copyright Guideline</a>
            </li>
          </ul>
        </div>

        <div class="omp-footer__footer-menu" data-open="false">
          <h4 class="omp-footer__footer-menu-h4">
            Help
            <span class="omp-footer__footer-menu-title-icon"
              >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
            >
          </h4>
          <ul class="omp-footer__footer-menu-ul">
            <li class="omp-footer__footer-menu-li">
              <a href="/${T.lang}/help_center?type=buying_shipping">Buying & Shipping</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${T.lang}/help_center?type=creating_selling">Creating & Selling</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <span class="btn_shipping_county">Shipping Country</span>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/contact_us">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="omp-footer__logos">
        <div class="omp-footer__for-the-planet">
          <a href="https://www.onepercentfortheplaneTT.org/" target="_blank">
            <img
              class="omp-footer__for-the-planet-img"
              src="//s3.marpple.co/files/u_218933/2023/7/original/c1a461770a27a6973657c228591ac9b8aa13754b1.png"
              alt="1% FOR THE PLANET"
            />
          </a>
        </div>
        <div class="omp-footer__iflogo">
          <a href="https://ifdesign.com/en/winner-ranking/project/marpple/206874?q=marpple" target="_blank">
            <img
              class="omp-footer__iflogo-img"
              src="//s3.marpple.co/files/u_218933/2023/7/original/6328ef8c9ceefb81f3bcc02680d79bdedbf292cf1.png"
              alt="iflogo"
            />
          </a>
        </div>
      </div>
      <div class="omp-footer__company-info" data-open="false">
        <div class="omp-footer__company-info-title">
          <span>Marpple Corp.</span>
          <span class="omp-footer__company-info-title-icon"
            >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down', color: 'BK' })}</span
          >
        </div>
        <div class="omp-footer__company-info-cont">
          <div>${MpAddressConstantS.MP_ADDRESS_EN}</div>
          <div>
            <a href="mailto:cs@marpple.com">cs@marpple.com</a>
          </div>
        </div>

        <div class="omp-footer__copyright">
          Copyright © ${new Date().getFullYear()} Marpple Corp.<br />All rights reserved.
        </div>
        <div class="omp-footer__terms">
          <span class="bar">
            <a href="/${T.lang}/terms" class="terms_of_service">Terms & Conditions</a>
          </span>
          <span>
            <a href="/${T.lang}/privacy" class="privacy_policy">Privacy Policy</a>
          </span>
        </div>
      </div>
    </div>
  `;
};

const makeFooterJpHtml = () => {
  return html`
    <div class="omp-footer">
      <h3 class="omp-footer__logo">
        <a class="omp-footer__logo-a" href="/${TT.lang}/">
          <img
            class="omp-footer__logo-img"
            src="//s3.marpple.co/files/u_218933/2023/7/original/f01a70531eac8ab8ced3654146a153db12da80a91.svg"
            alt="MARPPLE"
          />
        </a>
      </h3>

      <div class="omp-footer__customer">
        <h3 class="omp-footer__customer-title">カスタマーサービス</h3>
        <div class="omp-footer__customer-content">平日 10:00 ~ 18:00</div>
        <div class="omp-footer__customer-email">
          <a class="omp-footer__customer-email-a" href="mailto:cs@marpple.com">メールでお問い合わせ</a>
        </div>
        <div class="omp-footer__buttons">
          <div class="omp-footer__button live_chat">
            <span class="omp-footer__button-a">
              <span class="omp-footer__live-chat-icon"
                >${OMPCoreAtomTmplS.customerIcon({ color: 'WHT' })}</span
              >
              1:1相談サービス
            </span>
          </div>
        </div>
      </div>

      <div class="omp-footer__footer-menus">
        <div class="omp-footer__footer-menu" data-open="false">
          <h4 class="omp-footer__footer-menu-h4">
            About
            <span class="omp-footer__footer-menu-title-icon"
              >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
            >
          </h4>
          <ul class="omp-footer__footer-menu-ul">
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/about">MARPPLEとは</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/review/list?is_best=true&is_all=false">お客様の声</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="https://marpple.shop/${TT.lang}" target="_blank">MARPPLE SHOP</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="https://www.marpplecorp.com/" target="_blank">Marpple Corporation</a>
            </li>
          </ul>
        </div>

        <div class="omp-footer__footer-menu" data-open="false">
          <h4 class="omp-footer__footer-menu-h4">
            Guideline
            <span class="omp-footer__footer-menu-title-icon"
              >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
            >
          </h4>
          <ul class="omp-footer__footer-menu-ul">
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/guideline/images">イメージガイドライン</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${TT.lang}/guideline/copyright">著作権ガイドライン</a>
            </li>
          </ul>
        </div>

        <div class="omp-footer__footer-menu" data-open="false">
          <h4 class="omp-footer__footer-menu-h4">
            Help
            <span class="omp-footer__footer-menu-title-icon"
              >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
            >
          </h4>
          <ul class="omp-footer__footer-menu-ul">
            <li class="omp-footer__footer-menu-li">
              <a href="/${T.lang}/help_center?type=order_pay_jp">注文・決済について</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${T.lang}/help_center?type=make_group_jp">製作・製作過程について</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${T.lang}/help_center?type=image_edit_jp">イメージ・プリントについて</a>
            </li>
            <li class="omp-footer__footer-menu-li">
              <a href="/${T.lang}/contact_us">お問い合わせ</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="omp-footer__logos">
        <div class="omp-footer__for-the-planet">
          <a href="https://www.onepercentfortheplaneTT.org/" target="_blank">
            <img
              class="don_lazy omp-footer__for-the-planet-img"
              data-src="//s3.marpple.co/files/u_218933/2023/7/original/c1a461770a27a6973657c228591ac9b8aa13754b1.png"
              alt="1% FOR THE PLANET"
            />
          </a>
        </div>
        <div class="omp-footer__iflogo">
          <a href="https://ifdesign.com/en/winner-ranking/project/marpple/206874?q=marpple" target="_blank">
            <img
              class="don_lazy omp-footer__iflogo-img"
              data-src="//s3.marpple.co/files/u_218933/2023/7/original/6328ef8c9ceefb81f3bcc02680d79bdedbf292cf1.png"
              alt=""
            />
          </a>
        </div>
      </div>
      <div class="omp-footer__company-info" data-open="false">
        <div class="omp-footer__company-info-title">
          <span>Marpple Corp.</span>
          <span class="omp-footer__company-info-title-icon"
            >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down', color: 'BK' })}</span
          >
        </div>
        <div class="omp-footer__company-info-cont">
          <div>${MpAddressConstantS.MP_ADDRESS_JP}</div>
          <div>
            <a href="mailto:cs@marpple.com">cs@marpple.com</a>
          </div>
        </div>
        <div class="omp-footer__copyright">
          Copyright © ${new Date().getFullYear()} Marpple Corp.<br />All rights reserved.
        </div>
        <div class="omp-footer__terms jp">
          <span class="bar">
            <a href="/${T.lang}/terms" class="terms_of_service">利用規約</a>
          </span>
          <span>
            <a href="/${T.lang}/privacy" class="privacy_policy">プライバシーポリシー</a>
          </span>
          <span>
            <a href="/${T.lang}/commerce_law">特定商取引法に基づく表記</a>
          </span>
        </div>
      </div>
    </div>
  `;
};

const makeMpFooterHTML = () => {
  if (TT.lang == 'kr') return makeFooterKrHtml();
  if (TT.lang == 'en') return makeFooterEnHtml();
  if (TT.lang == 'jp') return makeFooterJpHtml();
};

export const footerMobileHTML = makeMpFooterHTML;
