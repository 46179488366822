export default function (lo) {
  const projection = box.sel('df/projection/detail->projection');
  $.frame({
    title: `주문서 상세 - [${projection.lang == 'kr' ? '국문' : '영문'}] #${projection.id}`,
    frame_name: 'projection.detail',
    projection_id: projection.id,
    collabo_type: projection.collabo_type,
    projection_type: projection.type,
    frame_tag: projection.id,
  });
}
