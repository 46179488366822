import { pixelToIdx } from './composite_utils.js';

export function interpolateNN(src_pixel_arr, dest_pixel_arr, src_x, src_y, src_width, src_height, dest_idx) {
  const nearest_src_x = Math.round(src_x);
  const nearest_src_y = Math.round(src_y);
  const nearest_src_idx = pixelToIdx(nearest_src_x, nearest_src_y, src_width);
  for (let k = 0; k < 4; k++) {
    dest_pixel_arr[dest_idx + k] = src_pixel_arr[nearest_src_idx + k];
  }
}

export function interpolateBilinear(
  src_pixel_arr,
  dest_pixel_arr,
  src_x,
  src_y,
  src_width,
  dest_idx,
  map_pixel_arr,
) {
  const src_x0 = Math.floor(src_x);
  const src_y0 = Math.floor(src_y);
  const src_x1 = Math.ceil(src_x);
  const src_y1 = Math.ceil(src_y);
  const src_idx_00 = pixelToIdx(src_x0, src_y0, src_width);
  const src_idx_10 = pixelToIdx(src_x1, src_y0, src_width);
  const src_idx_01 = pixelToIdx(src_x0, src_y1, src_width);
  const src_idx_11 = pixelToIdx(src_x1, src_y1, src_width);
  const dx = src_x - src_x0;
  const dy = src_y - src_y0;

  const src_alpha_arr = [
    src_pixel_arr[src_idx_00 + 3],
    src_pixel_arr[src_idx_10 + 3],
    src_pixel_arr[src_idx_01 + 3],
    src_pixel_arr[src_idx_11 + 3],
  ];
  const src_alpha_zero_count = src_alpha_arr.filter((alpha) => alpha === 0).length;
  if (src_alpha_zero_count < 4) {
    // if (src_alpha_zero_count > 0) {
    //   for (let k = 0; k < 3; k++) {
    //     let src_color_arr = [
    //       src_pixel_arr[src_idx_00 + k],
    //       src_pixel_arr[src_idx_10 + k],
    //       src_pixel_arr[src_idx_01 + k],
    //       src_pixel_arr[src_idx_11 + k]
    //     ];
    //     let sum = 0;
    //     let len = 0;
    //     src_alpha_arr.forEach((src_alpha, idx) => {
    //       if (src_alpha !== 0) {
    //         sum += src_color_arr[idx];
    //         len++;
    //       }
    //     });
    //     const avg_src_color = sum / len;
    //     src_alpha_arr.forEach((src_alpha, idx) => {
    //       if (src_alpha === 0) {
    //         src_color_arr[idx] = avg_src_color;
    //       }
    //     });
    //     // src_pixel_arr[src_idx_00 + k] = src_color_arr[0];
    //     // src_pixel_arr[src_idx_10 + k] = src_color_arr[1];
    //     // src_pixel_arr[src_idx_01 + k] = src_color_arr[2];
    //     // src_pixel_arr[src_idx_11 + k] = src_color_arr[3];
    //   }
    // }
    for (let k = 0; k < 3; k++) {
      dest_pixel_arr[dest_idx + k] = solveBilinearFunction(
        src_pixel_arr[src_idx_00 + k],
        src_pixel_arr[src_idx_10 + k],
        src_pixel_arr[src_idx_01 + k],
        src_pixel_arr[src_idx_11 + k],
        dx,
        dy,
      );
    }
    const alpha_interpolated = solveBilinearFunction(
      src_pixel_arr[src_idx_00 + 3],
      src_pixel_arr[src_idx_10 + 3],
      src_pixel_arr[src_idx_01 + 3],
      src_pixel_arr[src_idx_11 + 3],
      dx,
      dy,
    );
    dest_pixel_arr[dest_idx + 3] = map_pixel_arr
      ? Math.min(alpha_interpolated, (map_pixel_arr[dest_idx + 3] / 65535) * 255)
      : alpha_interpolated;
  }
}

function solveBilinearFunction(f00, f10, f01, f11, dx, dy) {
  return f00 * (1 - dx) * (1 - dy) + f10 * dx * (1 - dy) + f01 * (1 - dx) * dy + f11 * dx * dy;
}
