import { html } from 'fxjs/es';
import { OMPMyPageWishConstantS } from '../Constant/module/OMPMyPageWishConstantS.js';

const active_klass = 'omp-my-wish__tab--active';

export const myPageWishLayoutTmpl = ({ active_tab, tab_count, tmpl }) => {
  return html`<div class="omp-my-wish">
    ${myPageWishTabTmpl(active_tab, tab_count)}
    <div class="omp-my-wish__content">${tmpl}</div>
  </div>`;
};

export const myPageWishTabTmpl = (active_tab, tab_count) => {
  return html`
    <div class="omp-my-wish__tabs">
      <a
        href="/${TT.lang}/wish/list"
        class="omp-my-wish__tab ${active_tab === OMPMyPageWishConstantS.WishTab.WishProducts
          ? active_klass
          : ''}"
        data-tab="${OMPMyPageWishConstantS.WishTab.WishProducts}"
      >
        <span class="omp-my-wish__tab-title">${TT('my_page::wish::wish_03')}</span>
        <span
          class="omp-my-wish__tab-count ${tab_count.wish_base_products
            ? ''
            : 'omp-my-wish__tab-count--hidden'}"
        >
          <span>${tab_count.wish_base_products}</span>
        </span>
      </a>
      <a
        href="/${TT.lang}/wish"
        class="omp-my-wish__tab ${active_tab === OMPMyPageWishConstantS.WishTab.BuyLater ? active_klass : ''}"
        data-tab="${OMPMyPageWishConstantS.WishTab.BuyLater}"
      >
        <span class="omp-my-wish__tab-title">${TT('my_page::wish::wish_02')}</span>
        <span class="omp-my-wish__tab-count ${tab_count.buy_later ? '' : 'omp-my-wish__tab-count--hidden'}">
          <span>${tab_count.buy_later}</span>
        </span>
      </a>
    </div>
  `;
};
