import { go, join, map } from 'fxjs/es';

export function getMimeTypeFromArrayBuffer(array_buffer) {
  const signature = go(
    new Uint8Array(array_buffer).subarray(0, 4),
    map((x) => x.toString(16)),
    join(''),
    (str) => str.toUpperCase(),
  );
  switch (signature) {
    case '89504E47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case '25504446':
      return 'application/pdf';
    case 'FFD8FFDB':
    case 'FFD8FFE0':
      return 'image/jpeg';
    case '504B0304':
      return 'application/zip';
    default:
      return null;
  }
}
