import { getBpfOfFcanvas } from '../../../../../Maker/F/getSth.js';
import { NewMakerPropertyBpfConstantS } from '../../S/Constant/module/NewMakerPropertyBpfConstantS.js';

export const additionalRender = {
  getIt: (fcanvas) => {
    const bpf = getBpfOfFcanvas(fcanvas);
    return bpf?.preview?.[NewMakerPropertyBpfConstantS.ADDITIONAL_RENDER];
  },
  getScale: (fcanvas) => {
    const additional_render = additionalRender.getIt(fcanvas);
    return additional_render?.scale || 2;
  },
};
