import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { go, tap } from 'fxjs/es';
import { VectorEditorFreeShapeMobileMuiF } from '../Mui/module/VectorEditorFreeShapeMobileMuiF.js';

export const useFreeShape = ({ postProcess }) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorFreeShapeMobileMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = (frame_el, val) =>
        go(
          frame_closed.call(frame, frame_el, val),
          tap(() => resolve(val)),
        );

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_postProcess = postProcess;
        return tab_appending.call(tab, tab_el);
      };
    }).catch(reject),
  );
