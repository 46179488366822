import { $attr, $closest, $hasClass, $height, $qs } from 'fxdom/es';
import { go } from 'fxjs/es';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

export const getMakerBoxColor = (o = 1) => {
  if (G.collabo_type === '') return `rgba(255, 107, 0, ${o})`;
  return `rgba(100,130,255, ${o})`;
};

export const marpplizerProductSize = (is_basic) => {
  const marpplizer_wrapper_width = is_basic ? (G.collabo_type === '' ? 400 : 380) : 260;
  const min_width = G.collabo_type === '' ? 73 : 67;
  const item_margin_right = 8;
  const item_min_padding = 10;
  const border = 2;
  const font_size = 14;
  return {
    marpplizer_wrapper_width,
    min_width,
    item_margin_right,
    item_min_padding,
    border,
    font_size,
  };
};

export const getPcBodyWidth = () => {
  const mp_product_detail_el = $qs('.mp_product_detail .mp_maker');
  return G.collabo_type === '' && mp_product_detail_el ? $.width(mp_product_detail_el) : $.width(window);
};

export const getPcHeaderHeight = () => {
  return (
    (G.collabo_type === ''
      ? go($qs('.omp-header'), (a) => a && $height(a))
      : go($qs('#header'), (a) => a && $height(a))) || 0
  );
};

export const getMobileHeader = () => {
  const is_marpple = G.collabo_type === '';
  const is_creator = G.collabo_type === 'creator';
  if (is_marpple) return $closest('.headroom', $qs('.omp-header'));
  if (is_creator)
    return $qs('[page_name="/modules/Creator/MobileMaker/S/Mui/page.js"] >.don_wrapper >.header');
  return $1('#header');
};
export const getMobileHeaderHeight = () => {
  const header = getMobileHeader();
  if (G.collabo_type === '') {
    return go(header, $closest('.don_frame'), $attr('header_height'), (a) => parseInt(a) || 0);
  } else {
    return header ? $height(header) : 0;
  }
};

export const getCssStyle = () => {
  return G.collabo_type === 'creator'
    ? 'background-color: #3c89f9; color:#fff;'
    : 'background-color: #000; color:#fff;';
};
// G.collabo_type === 'creator'
//   ? 'background-color: #3c89f9; color:#fff;'
//   : 'background-color: #000; color:#fff;'

export const is_mobile_header_headroom = () => {
  if (!UtilF.isLegacyMobile()) return;
  return go(getMobileHeader(), (el) => (el ? $hasClass('headroom')(el) : false));
};

export const findCartTarget = () => {
  return G.collabo_type == 'line'
    ? !UtilF.isLegacyMobile()
      ? '.pc-header:not(.hover-header) > .pc-header-top >.pc-header-wrapper >.right_gnb li.cart .icon_img'
      : '.navigation .mobile_v li.cart .icon_img'
    : !UtilF.isLegacyMobile()
    ? '.omp-cell__side-header-cart'
    : '.mp-header-mo__cart';
};
