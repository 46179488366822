import axios from 'axios';
import format from 'date-fns/format/index.js';
import { $closest, $find, $findAll, $setHTML } from 'fxdom/es';
import { compact, flatMap, go, html, map, some, strMap, values } from 'fxjs/es';
import { DfIssueMemoModalMuiF } from '../../../../../modules/Df/IssueMemoModal/F/Mui/module/DfIssueMemoModalMuiF.js';
import { makeDfProjectionListEvent } from '../../../../../modules/Df/Projection/List/F/event.js';
import { DfTaskDetailF } from '../../../../../modules/Df/Task/Detail/F/Function/module/DfTaskDetailF.js';
import { DfTaskF } from '../../../../../modules/Df/Task/F/Function/module/DfTaskF.js';
import { MuiF } from '../../../../../modules/Mui/F/Function/module/MuiF.js';

const shipping_warning_msg = `운송장이 기입되어 있지 않습니다. 확인해 주세요. <br>(개별 배송일 경우 개별 운송장 중 하나를 꼭 넣어주셔야 합니다.)
                        '<br>(배송방식이 '퀵' 또는 '방문' 이라면 배송수정에서 배송타입을 '퀵' 또는 '방문' 으로 변경한 뒤 태스크를 완료해 주세요.)`;

export const initTaskFrames = (LF) => {
  function open_task_detail(task) {
    $.frame.open({
      frame_name: 'df.task.detail',
      frame_tag: task.id,
    });
  }

  const task_detail_tasks_event_init = __(
    $.on('click', '.task[has_issue="true"] .task_issue', __(_p.tap(LF.issue_event.modal))),
    $.on(
      'click',
      '.task_items .task .title, .task_item .task .head',
      __(
        LF.tasks_event.body.open_task_detail,
        _p.if2(_p.idtt)(LF.issue_event.get_task, (task) => open_task_detail(task)),
      ),
    ),

    $.on(
      'click',
      '.task_create_possible .task_items .btn.add',
      _p.throttle(
        __(LF.tasks_event.body.add_task, (task) => open_task_detail(task)),
        1000,
        { trailing: false },
      ),
    ),
  );

  async function task_update_and_status_change(keys) {
    const isNotHanjinAndWaybillEmpty = !DfTaskF.isShippingCompanyHanjin() && !DfTaskF.isWaybillNumberFilled();
    if (
      DfTaskF.isDeliveryCompleted() &&
      DfTaskF.isProjectionNeedWaybillNumber() &&
      isNotHanjinAndWaybillEmpty
    ) {
      box.set('task->status', 'ready');
      return $.alert(shipping_warning_msg);
    }
    const task_type = box.sel('task->type');
    const task = go(box.sel('task'), _p.pick(['id', 'projection_id'].concat(keys)));
    if (
      task.is_canceled === true &&
      !(
        (['as', 'before_deliver'].includes(task_type) &&
          box.sel('is_user->_->policies->task_manager_policy')) ||
        box.sel('is_user->_->policies->task_create_policy')
      )
    ) {
      $.alert('태스크 취소 권한이 없습니다.');
      return;
    }
    if (
      task.is_canceled === false &&
      !(
        (['as', 'before_deliver'].includes(task_type) &&
          box.sel('is_user->_->policies->task_manager_policy')) ||
        box.sel('is_user->_->policies->task_create_policy')
      )
    ) {
      $.alert('태스크 복구 권한이 없습니다.');
      return;
    }
    if (
      task.is_hidden === true &&
      !(
        (['as', 'before_deliver'].includes(task_type) &&
          box.sel('is_user->_->policies->task_manager_policy')) ||
        box.sel('is_user->_->policies->task_create_policy')
      )
    ) {
      $.alert('태스크 삭제 권한이 없습니다.');
      return;
    }
    if (
      task.status === 'completed' &&
      ['이미지작업', 'CS고객확인', '일정확인', '마플샵고객확인'].includes(
        box.sel('task->title').replace(/\s/g, ''),
      )
    ) {
      const user_id = box().is_user.id;
      const is_not_read_issue = go(
        box.sel('task->_->issues'),
        flatMap((issue) => issue._.issues_users),
        some((issues_user) => issues_user.user_id === user_id && !issues_user.is_read),
      );
      if (is_not_read_issue) return $.alert('읽지 않은 이슈가 있습니다.');
      const special_request = box.sel('task->_->projection->special_request');
      const ups_memos = go(
        box.sel('task->_->projection->_->ups'),
        map((up) => up.quantity > 0 && up.memo),
        compact,
        map(values),
        flatMap(values),
      );
      if (special_request || ups_memos.length) {
        return new Promise((resolve) => {
          MuiF.openFrame(DfIssueMemoModalMuiF.frame, (frame, page, [tab]) => {
            tab.makeData = () => ({
              issues: box.sel('task->_->issues'),
              ups_memos,
              prj_memo: special_request,
            });
            frame.closed = async (frame_el, data) => {
              if (data) {
                if (box.sel('task->title').replace(/\s/g, '') === '이미지작업') {
                  // const data = await DfImageWorkReviewF.openFrame(box.sel('task->id'));
                  await axios
                    .put('/@api/projection/image_task_complete', {
                      task_id: box.sel('task->id'),
                    })
                    .catch((error) => {
                      if (error.response?.data?.message) {
                        $.alert(error.response?.data?.message);
                      } else {
                        console.error(error);
                      }
                    });
                  resolve();
                  return;
                }
                return go($.post('/@api/projection/task_update_and_change_status', task), resolve);
              } else {
                resolve();
              }
            };
          });
        });
      }
      if (box.sel('task->title').replace(/\s/g, '') === '이미지작업') {
        // const data = await DfImageWorkReviewF.openFrame(box.sel('task->id'));
        await axios
          .put('/@api/projection/image_task_complete', {
            task_id: box.sel('task->id'),
          })
          .catch((error) => {
            if (error.response?.data?.message) {
              $.alert(error.response?.data?.message);
            } else {
              console.error(error);
            }
          });
        return;
      }
    }
    return _p.go(task, _p($.post, '/@api/projection/task_update_and_change_status'));
  }

  function task_box_to_server(keys) {
    return _p.go(
      box.sel('task'),
      _p.pick(['id', 'projection_id'].concat(keys)),
      _p($.post, '/@api/task/modify'),
    );
  }

  $.frame.defn_frame({
    frame_name: 'df.task.detail',
    el_class: 'task_detail',
    page_name: 'task.detail',
    appended: function () {
      $.don_loader_start();
    },
  });

  $.frame.defn_page({
    page_name: 'task.detail',
    el_class: 'task_detail_page',
    tabs: [
      {
        tab_name: 'task_detail_tab',
        title: '태스크',
        template: __(function () {
          return pug`
            `;
        }),
        showed: async function (don_tab) {
          $.don_loader_start();
          const task_id = $.attr($.closest(don_tab, '.don_frame'), 'frame_tag');
          await _p.go(task_id, DfTaskDetailF.set_task_box_task_detail_render, async function () {
            if (box().is_user._.policies.outsourcing_worker_policy) {
              if (box().task.worker_id !== box().is_user.id) {
                setTimeout(async () => {
                  await $.alert('본인의 태스크가 아닙니다.');
                  $.frame.close();
                  $.don_loader_end();
                }, 1000);
              } else if (box().is_user._.policies.task_progress_policy) {
                const {
                  data: { ups_task_progress },
                } = await axios.get('/@api/task/progress', { params: { task_id } });
                if (ups_task_progress) {
                  // setTimeout(async () => {
                  //   await $.alert('해당 태스크는 태스크 상세 작업에서 완료 가능합니다.');
                  //   $.frame.close();
                  $.don_loader_end();
                  // }, 1000);
                } else {
                  $.don_loader_end();
                }
              } else {
                $.don_loader_end();
              }
            } else {
              $.don_loader_end();
            }
          });
        },
        appended: __(
          (don_tab) => task_detail_tasks_event_init(don_tab),
          (don_tab) => makeDfProjectionListEvent(don_tab),
          (don_tab) => G.df.issue.task_issue_list_events(don_tab),
          $.on('click', '.tab > .tab_tasks_log', async ({ currentTarget, delegateTarget }) => {
            if (box.sel('user->_->policies->master_admin')) {
              $.don_loader_start();
              const { data: df_logs } = await axios.get('/@api/df_logs/task', {
                params: {
                  task_id: currentTarget.dataset.task_id,
                },
              });
              $.don_loader_end();
              $setHTML(
                strMap(({ status, request_body, user_id, created_at, user_name }) => {
                  return html`
                    <tr>
                      <td>${format(new Date(created_at), 'yyyy-MM-dd HH:mm:ss')}</td>
                      <td>${status}</td>
                      <td>${user_name}</td>
                      <td>${user_id}</td>
                      <td class="rowrow">${request_body}</td>
                    </tr>
                  `;
                }, df_logs),
                $find('.task_logs tbody', delegateTarget),
              );
            }
          }),
          $.on('click', '.files a', function (e) {
            e.preventDefault();
            window.open($.attr(e.currentTarget, 'href'));
          }),
          $.on('click', '.tab > div', function (e) {
            _go(
              e.currentTarget,
              _p.add_arg($.attr('key')),
              (el, key) => {
                _go(
                  e.currentTarget,
                  $.closest('.don_tab'),
                  $.attr({
                    detail_tab: key,
                  }),
                );
                _go(el, $.closest('.option'), $.attr('is_show', key));
              },
              function () {
                DfTaskDetailF.task_detail_render();
              },
            );
          }),
          $.on('click', '.sub-tab > div', function (e) {
            _go(e.currentTarget, _p.add_arg($.attr('key')), (el, key) => {
              _go(el, $.closest('.body'), $.attr('is_show', key));
            });
          }),
          $.on('click', '.don_wrapper >.wrapper >.head .create', function (e) {
            _p.go(
              box.sel('task->title'),
              _p
                .if2(_p.idtt)(
                  () => $.post('/@api/task/complete_create', _p.omit(box.sel('task'), ['_'])),
                  function () {
                    _p.go($.confirm('태스크가 생성되었습니다.'), _p.noop, $.frame.close);
                  },
                )
                .else(_p.c('작업명을 입력해 주세요.'), $.confirm),
            );
          }),
          $.on(
            'click',
            '.body_wrapper >.option .btn.issue_create',
            DfTaskDetailF.detail_saving(function (e) {
              box.set('issue', {
                user_id: box.sel('user->id'),
                attached_type: 'tasks',
                attached_id: box.sel('task').id,
              });
              const user = box.sel('user') || box.sel('is_user');
              const uniq_users = _p.filter(
                _p.uniq([box.sel('task->_->worker'), user], _p.v('id')),
                (user) => user.id,
              );
              _p.go(
                uniq_users,
                _p.map(function (user) {
                  return { user_id: user.id };
                }),
                (users) => box.extend2('issue', { _: { issues_users: users } }),
              );
              box.set('df.issue.create_one_issuer', [user]);
              box.set('df.issue.create_one_issuees', uniq_users);
              return $.frame.open({
                frame_name: 'df.issue.create_one',
                closed: function () {
                  return _p.go(DfTaskDetailF.set_task_box_task_detail_render(), function () {
                    box.unset('df.issue.create_one_issuer');
                    box.unset('df.issue.create_one_issuees');
                  });
                },
              });
            }),
          ),
          $.on2(
            'click',
            '#files .remove',
            DfTaskDetailF.detail_saving(function (e) {
              return _p.go(
                $.confirm('삭제 하시겠습니까?'),
                _p.if2(_p.idtt)(async function () {
                  const file = _p.go(e.currentTarget, $.closest('.file'), box.sel);
                  await $.delete('/@api/task/delete_file', { id: file.id });
                  return DfTaskDetailF.set_task_box_task_detail_render();
                }),
              );
            }),
          ),
          $.on2(
            'change',
            '.attached_file input[type="file"]',
            DfTaskDetailF.detail_saving(function (e) {
              return _p.go(
                $.upload(e.currentTarget, {
                  url: '/@api/task/upload',
                  data: { id: box.sel('task->id') },
                }),
                _p.noop,
                DfTaskDetailF.set_task_box_task_detail_render,
              );
            }),
          ),
          $.on('click', '.wrapper >.head .close', function (e) {
            $.frame.close();
          }),
          $.on(
            'click',
            '.wrapper >.head .cancel[val="false"]',
            _p.if2(
              _p.c('태스크를 취소하시겠습니까?'),
              $.confirm,
            )(
              DfTaskDetailF.detail_saving(function (e) {
                if (
                  box.sel('task->title') == '제품확인' &&
                  _p.find(
                    box.sel('task')._.projection._.tasks,
                    (abc) => abc.is_canceled == false && abc.title != '제품확인',
                  )
                )
                  return alert('제품확인 태스크는 취소 할 수 없습니다.');
                $.attr(e.currentTarget, 'val', !$.attr(e.currentTarget, 'val'));
                const k = _go(e.currentTarget, $.attr('type'));
                const v = _go(e.currentTarget, $.attr('val'));
                box.extend2('task', { [k]: v });
                return _p.go(task_update_and_status_change([k]), function () {
                  $.frame.close();
                });
              }),
            ),
          ),
          $.on(
            'click',
            '.wrapper >.head .cancel[val="true"]',
            _p.if2(
              _p.c('태스크를 복구하시겠습니까?'),
              $.confirm,
            )(
              DfTaskDetailF.detail_saving(function (e) {
                $.attr(e.currentTarget, 'val', !$.attr(e.currentTarget, 'val'));
                const k = _go(e.currentTarget, $.attr('type'));
                const v = _go(e.currentTarget, $.attr('val'));
                box.extend2('task', { [k]: v });
                return _p.go(
                  task_update_and_status_change([k]),
                  _p.noop,
                  DfTaskDetailF.set_task_box_task_detail_render,
                );
              }),
            ),
          ),
          $.on(
            'click',
            '.delete_task[val="false"]',
            _p.if2(
              _p.c('태스크를 삭제하시겠습니까?'),
              $.confirm,
            )(function (e) {
              $.attr(e.currentTarget, 'val', !$.attr(e.currentTarget, 'val'));
              const k = _go(e.currentTarget, $.attr('type'));
              const v = _go(e.currentTarget, $.attr('val'));
              box.extend2('task', {
                [k]: v,
                remover_id: box.sel('is_user->id'),
              });
              return _p.go(task_box_to_server([k, 'remover_id']), function () {
                $.frame.close();
              });
            }),
          ),
          $.on('click', '.create_task', function () {
            _p.go(
              box.sel('task->title'),
              _p
                .if2(_p.idtt)(
                  function () {
                    box.extend2('task', { is_draft: false });
                  },
                  () => task_box_to_server(['is_draft']),
                  function () {
                    _p.go($.confirm('태스크가 생성되었습니다.'), _p.noop, $.frame.close);
                  },
                )
                .else(_p.c('작업명을 입력해 주세요.'), $.confirm),
            );
          }),
          $.on(
            'click',
            '.wrapper >.head .delete[val="false"]',
            _p.if2(
              _p.c('태스크를 삭제하시겠습니까?'),
              $.confirm,
            )(function (e) {
              if (box.sel('task->title') == '제품확인') return alert('제품확인은 지울수 없는 태스크입니다.');
              $.attr(e.currentTarget, 'val', !$.attr(e.currentTarget, 'val'));
              const k = _go(e.currentTarget, $.attr('type'));
              const v = _go(e.currentTarget, $.attr('val'));
              box.extend2('task', {
                [k]: v,
                remover_id: box.sel('is_user->id'),
              });
              return _p.go(
                task_update_and_status_change(['status', 'completed_at', 'start_at', k, 'remover_id']),
                function () {
                  $.frame.close();
                },
              );
            }),
          ),
          $.on(
            'click',
            '.wrapper >.head .delete[val="true"]',
            _p.if2(
              _p.c('태스크를 복구 하시겠습니까?'),
              $.confirm,
            )(function (e) {
              $.attr(e.currentTarget, 'val', !$.attr(e.currentTarget, 'val'));
              const k = _go(e.currentTarget, $.attr('type'));
              const v = _go(e.currentTarget, $.attr('val'));
              box.extend2('task', { [k]: v });
              return _p.go(
                task_update_and_status_change(['status', 'completed_at', 'start_at', k]),
                function () {
                  $.frame.close();
                },
              );
            }),
          ),
          $.on(
            'change',
            '.task_body .date_time input.date, .task_body .date_time select.time',
            DfTaskDetailF.detail_saving(function (e) {
              _p.go(
                e.currentTarget,
                $.closest('.date_time'),
                function (date_time_el) {
                  const date = _go($.find1(date_time_el, '.date'), $.val);
                  const time = _go($.find1(date_time_el, '.time'), $.val);
                  if (isNaN(parseInt(date)) || isNaN(parseInt(time))) return;
                  const date_time = date.concat(' ' + time);
                  return { start_at: new Date(date_time) };
                },
                _p.if2(_p.idtt)(
                  _p(box.extend2, 'task'),
                  _p(task_box_to_server, ['start_at']),
                  _p.noop,
                  DfTaskDetailF.set_task_box_task_detail_render,
                ),
              );
            }),
          ),
          $.on('change', '.product_position_status select', function (e) {
            let val = $.val(e.currentTarget);
            _p.go(
              e,
              DfTaskDetailF.detail_saving(function (e) {
                return _p.go(
                  e,
                  _p.v('currentTarget'),
                  $.closest('.content'),
                  $.attr('type'),
                  function (key) {
                    if (val === 'null') val = null;
                    box.extend2('task', { [key]: val, product_position_status_changed_at: new Date() });
                    return task_box_to_server([key, 'product_position_status_changed_at']);
                  },
                  _p.noop,
                  DfTaskDetailF.set_task_box_task_detail_render,
                );
              }),
            );
          }),
          $.on('change', '.press_type_select select', function (e) {
            let val = $.val(e.currentTarget);
            _p.go(
              e,
              DfTaskDetailF.detail_saving(function (e) {
                return _p.go(
                  e,
                  _p.v('currentTarget'),
                  $.closest('.content'),
                  $.attr('type'),
                  function (key) {
                    if (val === 'null') val = null;
                    box.extend2('task', { [key]: val });
                    return task_box_to_server([key]);
                  },
                  _p.noop,
                  DfTaskDetailF.set_task_box_task_detail_render,
                );
              }),
            );
          }),
          $.on(
            'change',
            '.task_body .status select',
            DfTaskDetailF.detail_saving(function (e) {
              $.don_loader_start();
              const val = $.val(e.currentTarget);
              return _p.go(
                val,
                _p
                  .if2((v) => v == 'completed')((v) => {
                    if (box.sel('task->is_draft')) {
                      return $.alert('태스크 생성전에 완료 시킬수 없습니다.');
                    }
                    const isNotHanjinAndWaybillEmpty =
                      !DfTaskF.isShippingCompanyHanjin() && !DfTaskF.isWaybillNumberFilled();

                    if (
                      DfTaskF.isDeliveryTask() &&
                      DfTaskF.isProjectionNeedWaybillNumber() &&
                      isNotHanjinAndWaybillEmpty
                    ) {
                      return $.alert(shipping_warning_msg);
                    }

                    box.extend2('task', {
                      status: v,
                      completed_at: new Date(),
                      start_at: box.sel('task->start_at') || new Date(),
                    });
                  })
                  .else_if((v) => v == 'on')((v) =>
                    box.extend2('task', {
                      status: v,
                      start_at: box.sel('task->start_at') || new Date(),
                    }),
                  )
                  .else((v) => box.extend2('task', { status: v })),
                () => task_update_and_status_change(['status', 'completed_at', 'start_at']),
                _p.noop,
                DfTaskDetailF.set_task_box_task_detail_render,
                _p.noop,
                $.don_loader_end,
              );
            }),
          ),
          $.on('keyup', '.task_body .title input', function (e) {
            if (!(e.keyCode == 13 || e.which == 13)) return;
            const val = _go(e.currentTarget, $.val);
            if (val == '제품확인') return alert('제품확인 이란 이름으로 태스크를 더이상 만들수 없습니다.');
            _p.go(
              e,
              DfTaskDetailF.detail_saving(function (e) {
                return _p.go(
                  e,
                  _p.v('currentTarget'),
                  $.closest('.content'),
                  $.attr('type'),
                  function (key) {
                    box.extend2('task', { [key]: val });
                    return task_box_to_server([key]);
                  },
                  _p.noop,
                  DfTaskDetailF.set_task_box_task_detail_render,
                );
              }),
            );
          }),
          $.on(
            'click',
            '.task_body .title .save, .task_body .body.content .save',
            DfTaskDetailF.detail_saving(function (e) {
              let key, val;
              _go(
                e.currentTarget,
                $.closest('.option'),
                $.siblings('.input'),
                _p.first,
                _p.all(__($.closest('.content'), $.attr('type')), __($.val)),
                function (k, v) {
                  key = k;
                  val = v;
                },
              );
              if (val == '제품확인') return alert('제품확인 이란 이름으로 태스크를 더이상 만들수 없습니다.');
              return _p.go(
                box.extend2('task', { [key]: val }),
                function () {
                  return task_box_to_server([key]);
                },
                _p.noop,
                DfTaskDetailF.set_task_box_task_detail_render,
              );
            }),
          ),
          $.on(
            'click',
            '.check_box .save',
            DfTaskDetailF.detail_saving(function (e) {
              return _p.go(
                e.currentTarget,
                $closest('.check_box'),
                $findAll('input'),
                map((input_el) => {
                  const key = input_el.name;
                  const val = input_el.checked;
                  box.extend2('task', { [key]: val });
                  return key;
                }),
                function (keys) {
                  return task_box_to_server(keys);
                },
                _p.noop,
                DfTaskDetailF.set_task_box_task_detail_render,
              );
            }),
          ),
          $.on(
            'click',
            '.task_body .title .cancel, .task_body .body.content .cancel',
            DfTaskDetailF.detail_saving(function (e) {
              DfTaskDetailF.task_detail_render();
            }),
          ),
          $.on(
            'click',
            '.manager .add',
            DfTaskDetailF.detail_saving(function (e) {
              const key = _go(e.currentTarget, $.closest('.manager'), $.attr('key'));
              box.set(
                'df.worker.list_selected_workers',
                _p.compact([box.sel('task->' + key) ? box.sel('task->_->' + key.split('_id')[0]) : null]),
              );
              return _p.go(G.get_teams(), function () {
                const task = box.sel('task');
                if (
                  !(
                    (['as', 'before_deliver'].includes(task.type) &&
                      box.sel('is_user->_->policies->task_manager_policy')) ||
                    box.sel('is_user->_->policies->task_create_policy') ||
                    (/press/gi.exec(task.title) && box.sel('is_user->team_id') === 11)
                  )
                ) {
                  return $.alert('담당자 변경 권한이 없습니다.');
                }
                if (box.sel('task->title') === '이미지작업') {
                  box.set(
                    'forced_selected_team',
                    _go(
                      box.sel('teams'),
                      _find((team) => team.name === '[운영]디자인'),
                      _p.omit('_'),
                    ),
                  );
                }
                return $.frame.open({
                  frame_name: 'df.worker.list',
                  frame_tag: 'one',
                  closed: function (el, selected_users) {
                    if (!selected_users) return;
                    return _p.go(
                      !selected_users[0] ? DfTaskDetailF.get_mihaldang() : selected_users[0],
                      (selected_user) =>
                        box.extend2('task', {
                          [key]: selected_user.id,
                          assigned_at: new Date(),
                        }),
                      () => task_box_to_server(['assigned_at', key]),
                      _p.noop,
                      DfTaskDetailF.set_task_box_task_detail_render,
                      function () {
                        box.unset('df.worker.list_selected_workers');
                        box.unset('df.worker.list_disabled_workers');
                        box.unset('forced_selected_team');
                      },
                    );
                  },
                });
              });
            }),
          ),
        ),
      },
    ],
  });
};
