import { html } from 'fxjs/es';

export const SMALL_PRODUCT_BP_IDS = [
  4444, 4445, 4446, 4447, 4448, 4449, 4450, 4451, 4452, 4634, 4644, 4692, 4702, 4703, 4553, 4554, 4555, 4556,
  4401, 4395, 4498, 4402, 4399, 4400, 4349, 4350, 4351, 4352, 4348, 4347,
];

export const SIZE_TIP_TEXT = {
  SIZE: html`<li>
    사이즈 측정은 상품을 바닥에 펼친 후 끝선과 끝선까지 측정해야하며, 신축성 있는 소재일 경우, 잡아당기지 않고
    바닥에 펼쳐진 상태 그대로 측정해야합니다. 오차범위 이외 잘못된 측정으로 인한 반품 및 교환은 어려운 점 양해
    부탁드립니다.
  </li> `,
  CHECK_REAL_DETAIL_SIZE: html`<li>
    마플에서 판매되는 상품은 각 브랜드 및 상품 공급 협력사에서 샘플링을 통해 공급되는 고유제품입니다. 특정
    브랜드 또는 가지고 계신 상품의 사이즈와 실측 사이즈가 다를 수 있습니다.<br />
    실측 구매 시 상세 사이즈를 반드시 확인 부탁드립니다.
  </li> `,
  TOLERANCE: html`
    <li>
      상세 사이즈는 측정 방법과 위치에 따라 1~3cm 오차가 발생할 수 있습니다. 기재된 상세 사이즈와 실측
      오차(1~3cm)에 대한 불량처리는 어려우며 교환 및 환불 사유가 될 수 없습니다.
    </li>
  `,
  ONLY_MARPPLE_SIZE: html` <li>
    마플에서 판매되고 있는 상품의 사이즈 외 다른 사이즈로는 제작이 불가한 점 양해 부탁드립니다.
  </li>`,
  PHONE_CASE_SIZE: html`
    <li>
      통신사에 따라 아주 미세한 차이가 있을 수 있으며, 특히 카메라의 위치, 버튼의 위치가 다른 경우가 있을 수
      있습니다. 구매 전 기종을 반드시 정확하게 확인해 주세요.
    </li>
    <li>
      같은 기종의 연도별 사이즈가 상이하니, 주문 전 해당 기종의 출시 연도를 반드시 확인해 주세요. (예: 갤럭시
      A7의 경우 2015, 2016, 2017년 버전 사이즈가 다릅니다.)
    </li>
  `,
  PANTS_RISE_SIZE: html`<li>밑위길이는 상단 허리선부터 사타구니 봉제선까지의 길이입니다.</li> `,
};
