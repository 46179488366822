import { makeDfProjectionShipEvent } from '../../List/F/event.js';
import { makeDfProjectionItemsHtml } from '../../List/F/tmpl.js';
import { html } from 'fxjs/es';
export const baseProjectionDetailSubProjectionsTab = ({ title, tab_name, merged_type, order_by }) => ({
  title,
  // selected: true,
  tab_name,
  hiding: function (el_don_tab) {
    const el_don_page = $.closest(el_don_tab, '.don_page');
    const el_don_wrapper = $.find1(el_don_page, '>.don_wrapper');
    $.attr(el_don_wrapper, { prev_scroll_top: $.scroll_top(el_don_wrapper) });
    $.css($.find1(el_don_tab, '>.don_wrapper'), { visibility: 'hidden' });
  },
  template: () => '',
  appended: makeDfProjectionShipEvent,
  showed: async (child) => {
    $.don_loader_start();

    const el_don_tab = $.closest(child, '.don_tab');
    // const { tab_opt } = el_don_tab;
    const el_don_frame = $.closest(el_don_tab, '.don_frame');
    const { frame_opt } = el_don_frame;
    const el_don_wrapper = $.find1(el_don_tab, '>.don_wrapper');
    const el_don_page_don_wrapper = $.find1(el_don_frame, '.don_page >.don_wrapper');
    const projection = box.sel('df/projection/detail->projection') || {};
    const { id, updated_at } = projection;
    const need_change = frame_opt.projection_id != id;
    const new_projection = need_change
      ? await $.get('/@api/projection/take', { id: frame_opt.projection_id })
      : (await $.get('/@api/projection/check_updated', { id, updated_at })) || projection;

    box.set('df/projection/detail', { projection: new_projection });

    if (need_change)
      _p.each($.find(el_don_frame, '.don_tab'), (el_don_tab) => (el_don_tab.tab_opt.updated_at = null));

    if ($.find1(el_don_wrapper, '>*')) {
      $.css(el_don_wrapper, { visibility: 'visible' });
      $.scroll_top2(el_don_page_don_wrapper, $.attr(el_don_page_don_wrapper, 'prev_scroll_top'));
      $.don_loader_end();
      return;
    }

    G.df.projection.detail.render_sidebar(el_don_tab);

    const p = new_projection;

    const payload = {
      status: 'all',
      order_by,
      merged_type: [merged_type],
      merged_parent_id: merged_type == 'child' ? p.id : undefined,
      store_id: p.store_id,
      is_detail_sub_projections: true,
      collabo_type: 'creator',
      limit: 10,
      with_cancel: true,
    };

    box.set(`df/${tab_name}`, await $.get('/@api/projection/list', payload));

    const last_page = Math.ceil(
      (Number(window.box.sel(`df/${tab_name}->pagination_count`)) || 1) / payload.limit,
    );

    $.html(
      el_don_wrapper,
      await html`
        <div class="user_projections" lang="${p.lang || 'en'}">
          <div class="top_id">
            <a href="/projection/detail/${p.id}" target="_blank">
              ${p.lang == 'kr' ? '[국문]' : '[영문]'} #${p.id}</a
            >
          </div>
          <h2>${title}</h2>
          <div
            class="projection_list_container don_pgn_container"
            _sel="df/${tab_name}->projections"
            prj_product_mode="up_list"
          >
            <ul class="df_grid prj don_pgn_wrap">
              ${makeDfProjectionItemsHtml(box.sel(`df/${tab_name}->projections`))}
            </ul>
            <div class="don_pagination"></div>
            ${last_page > 1
              ? html`
                  <div class="projection_list_last_page" style="text-align: center;cursor: pointer;">
                    마지막 페이지로 이동
                  </div>
                `
              : ''}
          </div>
        </div>
      `,
    );

    if (last_page > 1) {
      $.on($.find1(el_don_tab, '.projection_list_last_page'), 'click', () => {
        const page_num = $.find1(el_don_tab, '.don_pagination input');
        if (Number(page_num.value) !== last_page) {
          page_num.value = last_page;
          $.trigger(page_num, 'change');
        }
      });
    }

    _p.go(
      el_don_wrapper,
      $.find1('.projection_list_container'),
      G.mp.maker.draw_product_face_in_ups,
      _p.f('G.df.projection.detail.up_item_init'),
      G.df.task.projection.init,
    );

    await $.ui.pagination(
      $.find1(el_don_tab, '.projection_list_container'),
      {
        url: '/@api/projection/list',
        render_server: true,
        data: { limit: 10 },
        real_data: () => ({
          is_page: true,
          ...payload,
        }),
        is_url_state: false,
        paging_animate: function (don_pgn_wrap, new_items) {
          return _p.go(
            _p,
            _p.tap(() => $.scrollTop(el_don_page_don_wrapper, 0)),
            _p($.html, don_pgn_wrap, new_items),
            G.mp.maker.draw_product_face_in_ups,
          );
        },
      },
      makeDfProjectionItemsHtml,
    );

    $.don_loader_end();
  },
});
