import { go, join, map } from 'fxjs/es';
import axios from 'axios';
import opentype from 'opentype.js';
// const taviraj =
//   'ABCČĆDĐEFGHIJKLMNOPQRSŠTUVWXYZŽabcčćdđefghijklmnopqrsštuvwxyzžกขคฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮะาเใไโฤฤๅĂÂÊÔƠƯăâêôơư1234567890๐๑๒๓๔๕๖๗๘๙‘?’“!”(%)[#]{@}/&<-+÷×=>®©$€£¥¢:;,.*ๆฯฯฯ๏๚๛฿';
// const roboto =
//   'ABCČĆDĐEFGHIJKLMNOPQRSŠTUVWXYZŽabcčćdđefghijklmnopqrsštuvwxyzžАБВГҐДЂЕЁЄЖЗЅИІЇЙЈКЛЉМНЊОПРСТЋУЎФХЦЧЏШЩЪЫЬЭЮЯабвгґдђеёєжзѕиіїйјклљмнњопрстћуўфхцчџшщъыьэюяΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψωάΆέΈέΉίϊΐΊόΌύΰϋΎΫΏĂÂÊÔƠƯăâêôơư1234567890‘?’“!”(%)[#]{@}/&<-+÷×=>®©$€£¥¢:;,.*';

// const CH_JP_TEXT_REG = /[ぁ-ゔァ-ヴー々〆〤一-龥]/g;
const ALL_POSSIBLE_TEXT_REG_WITHOUT_CH =
  /[\sa-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣ぁ-ゔァ-ヴー々〆〤[{}[\]/?.ᆢㆍᆞ,;:|)*~_`!^\-+<>@#$%&\\=('"ABCČĆDĐEFGHIJKLMNOPQRSŠTUVWXYZŽabcčćdđefghijklmnopqrsštuvwxyzžกขคฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮะาเใไโฤๅĂÂÊÔƠƯăâêôơư1234567890๐๑๒๓๔๕๖๗๘๙‘’“”÷×®©€£¥¢ๆฯ฿АБВГҐДЂЕЁЄЖЗЅИІЇЙЈКЛЉМНЊОПРСТЋУЎФХЦЧЏШЩЪЫЬЭЮЯабвгґдђеёєжзѕиіїйјклљмнњопрстћуўфхцчџшщъыьэюяΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψωάΆέΈΉίϊΐΊόΌύΰϋΎΫΏ]/g;
const ENG_NUM_TEXT_REG = /[a-zA-Z0-9]/g;
const not_good_spaces =
  // eslint-disable-next-line no-control-regex,no-misleading-character-class
  /[\v\f\b\u200B\u200C\u200D\u200E\u200F\u000b\u2028\u2029\uFEFF\u202D\xA0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000]/g;

const ENG_KR = /[\sa-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣[{}[\]/?.ᆢㆍᆞ,;:|)*~_`!^\-+<>@#$%&\\=(]/g;

async function getHanjaRegExp() {
  const response_tc = await axios.get(
    '/font/maker/jp_ch/noto-sans-tc-v4-chinese-traditional_japanese_latin-regular.woff',
    {
      responseType: 'arraybuffer',
    },
  );

  const response_jp = await axios.get('/font/maker/jp_ch/noto-sans-jp-v21-japanese_latin-regular.woff', {
    responseType: 'arraybuffer',
  });

  const hanziRange = [
    [0x4e00, 0x9fff], // CJK Unified Ideographs
    [0x3400, 0x4dbf], // CJK Unified Ideographs Extension A
    [0x20000, 0x2a6df], // CJK Unified Ideographs Extension B
    [0x2a700, 0x2b73f], // CJK Unified Ideographs Extension C
    [0x2b740, 0x2b81f], // CJK Unified Ideographs Extension D
    [0x2b820, 0x2ceaf], // CJK Unified Ideographs Extension E
  ];

  const extractHanzi = (fontBuffer) => {
    const font = opentype.parse(fontBuffer);
    const hanzi = [];
    for (let i = 0; i < font.glyphs.length; i++) {
      const glyph = font.glyphs.get(i);
      for (const unicode of glyph.unicodes) {
        if (unicode) {
          for (const range of hanziRange) {
            if (unicode >= range[0] && unicode <= range[1]) {
              hanzi.push(String.fromCodePoint(unicode));
              break;
            }
          }
        }
      }
    }
    return hanzi;
  };

  const hanzi_tc = extractHanzi(response_tc.data);
  const hanzi_jp = extractHanzi(response_jp.data);
  const all_hanzi = [...new Set([...hanzi_tc, ...hanzi_jp])];

  return go(all_hanzi, join(''), (str) => new RegExp(`[${str}]`, 'g'));
}
let chache_regexp = null;
const makeRegExp = async () => {
  try {
    chache_regexp =
      chache_regexp ??
      new RegExp(`${(await getHanjaRegExp()).source}|${ALL_POSSIBLE_TEXT_REG_WITHOUT_CH.source}`, 'g');
    return chache_regexp;
  } catch (e) {
    return new RegExp(`[一-龥]|${ALL_POSSIBLE_TEXT_REG_WITHOUT_CH.source}`, 'g');
  }
};
const hasHanja = (text) => {
  return /[一-龥]/g.test(text);
};

export const getOnlyPossibleText = async (text) => {
  if (!text) return '';

  const regexp = hasHanja(text)
    ? new RegExp(`${(await makeRegExp()).source}|${ALL_POSSIBLE_TEXT_REG_WITHOUT_CH.source}`, 'g')
    : ALL_POSSIBLE_TEXT_REG_WITHOUT_CH;
  const _text = go(
    text.replace(not_good_spaces, ''),
    (text) => text.matchAll(regexp),
    map((v) => v[0]),
    join(''),
  );
  // if (hasChJp(_text)) {
  //   return filterAvailableHanja(_text) || '';
  // }
  return _text || '';
};

export const getOnlyPossibleEnAndKrText = (text) => {
  if (!text) return '';
  const _text = go(
    text.replace(not_good_spaces, ''),
    (text) => text.matchAll(ENG_KR),
    map((v) => v[0]),
    join(''),
  );
  return _text || '';
};

export const hasOnlyEngAndNum = (text) => {
  return text.replace(ENG_NUM_TEXT_REG, '') === '';
};
