import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';

export const handleRightButtonContainerCancelButtonClick = () => () => MuiF.popPage();

export const handleRightButtonContainerDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_cutting_line_editor == null) {
      return;
    }
    const cutting_line_editor = tab_el.__mp_cutting_line_editor;

    if (tab_el.__mp_target_image_el == null) {
      return;
    }
    const target_image_el = tab_el.__mp_target_image_el;

    const path_data = cutting_line_editor.getCuttingLinePathData();

    const result = await tab_el.__mp_postProcess({
      target_image_el,
      path_data,
    });

    tab_el.__mp_is_go_to_prev_frame = true;
    await MuiF.closeFrame(result);
  };
