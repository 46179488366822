export const color_pallete = {
  black: '#111111',
  purple: '#580DE2',
  blue: '#0E2EE2',
  green: '#24D8B3',
  yellow: '#FFD802',
  orange: '#FF7200',
  red: '#EC1131',
  pink: '#EE19A4',
  white: '#FFFFFF',
};

export const color_pallete_list = [
  color_pallete.black,
  color_pallete.purple,
  color_pallete.blue,
  color_pallete.green,
  color_pallete.yellow,
  color_pallete.orange,
  color_pallete.red,
  color_pallete.pink,
  color_pallete.white,
];

export const black_pallete_list = [
  '#111111',
  '#4E4E4E',
  '#828282',
  '#A2A2A2',
  '#DDDDDD',
  '#F8F8F8',
  '#FFFFFF',
];
