import { $addClass, $attr, $closest, $find, $findAll, $removeClass } from 'fxdom/es';
import { each, go } from 'fxjs/es';

export function getTabId({ type, status }) {
  let selected_tab_id = 'PACKAGING';
  if (type === 'INBOUND') {
    switch (status) {
      case 'PACKED':
      case 'REQUESTED':
      case 'SHIPOUT':
      case 'ARRIVED':
      case 'RECEIVED':
      case 'INSPECTING':
      case 'INSPECTED':
        selected_tab_id = 'INBOUND';
        break;
      default:
        selected_tab_id = 'PACKAGING';
        break;
    }
  } else if (type === 'OUTBOUND') {
    switch (status) {
      case 'CREATED':
      case 'PACKING':
      case 'PACKED':
        selected_tab_id = 'OUTBOUND';
        break;
      default:
        selected_tab_id = 'TRACKING';
        break;
    }
  }
  return selected_tab_id;
}

export function getShowTabByStatus({ type, status }) {
  let tab = 'INITIAL';
  if (type === 'INBOUND') {
    switch (status) {
      case 'CREATED':
        tab = 'CREATED';
        break;
      case 'PACKED':
      case 'REQUESTED':
      case 'SHIPOUT':
      case 'ARRIVED':
      case 'RECEIVED':
        tab = 'INBOUND';
        break;
      case 'INSPECTING':
      case 'INSPECTED':
        tab = 'RETURN';
        break;
      default:
        tab = 'INITIAL';
        break;
    }
  } else if (type === 'OUTBOUND') {
    tab = type;
  }
  return tab;
}

export function showTabContent({ el }) {
  const selected_tab_name = go(
    el,
    $closest('.don_wrapper'),
    $find('.tab_button[selected="true"]'),
    $attr('tab_name'),
  );
  const tab_body = go(el, $closest('.body'));
  go(tab_body, $findAll('.don_tab'), each($removeClass('is_show')));
  const selected_tab_content = go(tab_body, $find(`.don_tab[tab_name="${selected_tab_name}"`));
  if (selected_tab_content) {
    go(selected_tab_content, $addClass('is_show'));
  }
}

export function renderAllTabContent({ el }) {
  const selected_tab = go(el, $find('.tab_button[selected="true"]'));
  const tab_packaging = go(el, $find('.tab_button[tab_name="packaging"]'));
  const tab_inbound = go(el, $find('.tab_button[tab_name="inbound"]'));
  const tab_rebound = go(el, $find('.tab_button[tab_name="rebound"]'));
  const tab_return = go(el, $find('.tab_button[tab_name="return"]'));
  const tab_outbound = go(el, $find('.tab_button[tab_name="outbound"]'));
  const tab_tracking = go(el, $find('.tab_button[tab_name="tracking"]'));
  const tabs = [tab_packaging, tab_inbound, tab_rebound, tab_return, tab_outbound, tab_tracking];

  go(
    tabs,
    each((tab) => tab?.click()),
  );

  if (selected_tab) {
    selected_tab.click();
  }
}
