export const STATUS = {
  before_order: { id: 'before_order', label: '주문전' },
  ordering: { id: 'ordering', label: '주문완료' },
  before_print: { id: 'before_print', label: '제작준비중' },
  printing: { id: 'printing', label: '제작중' },
  before_deliver: { id: 'before_deliver', label: '제작완료' },
  delivering: { id: 'delivering', label: '배송중' },
  before_confirm: { id: 'before_confirm', label: '배송완료' },
  confirmed: { id: 'confirmed', label: '구매확정' },
  canceled: { id: 'canceled', label: '주문취소' },
};
