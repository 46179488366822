import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreCellTmplS } from '../../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { OMPMyPageWishConstantS } from '../Constant/module/OMPMyPageWishConstantS.js';
import { OMPMyPageWishTmplS } from './module/OMPMyPageWishTmplS.js';
import { wishUserProduct } from './wishUserProductColor.js';

export const buyLaterTmpl = ({ user_products, best_products, is_mobile }) => {
  if (!user_products?.length) {
    return emptyBuyLaterTmpl(best_products, is_mobile);
  }

  return html`<div class="omp-my-wish__buy-later">
    ${is_mobile ? wishBuyLaterMoTmpl(user_products) : wishBuyLaterPcTmpl(user_products)}
    <div class="omp-my-wish__add-cart-all-wrapper">
      ${OMPCoreAtomTmplS.actionButton({
        text: TT('my_page::wish::wish_10'),
        klass: 'omp-my-wish__add-cart-all',
        solidity: 'thick',
      })}
    </div>
  </div>`;
};

export const emptyBuyLaterTmpl = (best_products, is_mobile) => {
  return html`
    <div class="omp-my-wish__buy-later">
      ${OMPMyPageWishTmplS.myPageEmptyWishTmpl({
        title: TT('my_page::wish::wish_04'),
        description: TT('my_page::wish::wish_05', { br: is_mobile ? '<br>' : '' }),
      })}
      ${bestProductsTmpl(best_products, is_mobile)}
    </div>
  `;
};

export const bestProductsTmpl = (best_products, is_mobile) => {
  return html`<section class="omp-my-wish__best-products">
    <span class="omp-my-wish__best-products__title">${TT('my_page::wish::wish_09')}</span>
    <div class="omp-my-wish__best-products__list omp-product-list__list">
      ${strMap(!is_mobile ? OMPCoreCellTmplS.product : OMPCoreCellTmplS.productMo, best_products)}
    </div>
  </section>`;
};

export const wishBuyLaterPcTmpl = (user_products) => {
  return html`<div class="omp-cart__product-table">
    ${OMPCoreCellTmplS.paginatedTable({
      row_klass: 'omp-my-wish__buy-later__product',
      columns: OMPMyPageWishConstantS.WishBuyLaterTableColumns(),
      data: user_products,
      is_pagination_hidden: true,
      style: {
        inter_border_color: 'GY_50',
      },
    })}
  </div>`;
};

export const wishBuyLaterMoTmpl = (user_products) => {
  return html`<div class="omp-cart__product-wrapper">${strMap(wishUserProduct, user_products)}</div>`;
};
