import { NewMakerFcanvasGetF } from '../../../Fcanvas/Get/F/Function/module/NewMakerFcanvasgetF.js';
import { filter, go, some, tap } from 'fxjs/es';
import { getCvDesigns } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { $addClass, $closest, $css, $find, $height, $qs, $removeClass, $setCss } from 'fxdom/es';
import { NewMakerCheckerF } from '../../../Checker/F/Function/module/NewMakerCheckerF.js';
import { decorationOff } from '../../../IconMenu/F/Function/decorationEvent.js';
import { NewMakerMaskingTapeF } from '../../../MaskingTape/F/Function/module/NewMakerMaskingTapeF.js';
import { NewMakerFitPrintWidthF } from '../../../FitPrintWidth/F/Function/module/NewMakerFitPrintWidthF.js';
import { NewMakerUtilF } from '../../../Util/F/Function/module/NewMakerUtilF.js';

function makeProperAngle(source_angle) {
  const angle_360 = source_angle % 360;
  const angle_45 = Math.abs(angle_360) % 45;
  const is_minus = source_angle < 0;
  let angle;
  if (angle_45 <= 2) {
    const a = is_minus ? Math.ceil(angle_360 / 45) : Math.floor(angle_360 / 45);
    angle = a * 45;
  } else if (angle_45 >= 43) {
    const a = is_minus ? Math.ceil(angle_360 / 45) - 1 : Math.floor(angle_360 / 45) + 1;
    angle = a * 45;
  } else {
    angle = angle_360;
  }
  return is_minus ? 360 + angle : angle;
}
const MODE = {
  SCALE: 'SCALE',
  MOVE: 'MOVE',
  TL: 'tl',
  BL: 'bl',
  TR: 'tr',
  BR: 'br',
  MTR: 'mtr',
  SELECT: 'SELECT',
  TOOL_TIP: 'TOOL_TIP',
  ZOOM: 'ZOOM',
};
const all_class_names = [
  'has_clip_active',
  'has_active',
  'none_active',
  'has_clip_none',
  'has_clip_active',
  'is_show',
];
function getScreenScale(e) {
  const { width } = e.target.getBoundingClientRect();
  return width / window.innerWidth;
}
function getTopLeft(tooltip, point, { tl, tr, bl, br }) {
  const tool_tip_expect_location = {
    top: point.y - tooltip.height,
    left: point.x - tooltip.width / 2,
  };
  const tooltip_tl = new fabric.Point(tool_tip_expect_location.left, tool_tip_expect_location.top);
  const tooltip_tr = new fabric.Point(
    tool_tip_expect_location.left + tooltip.width,
    tool_tip_expect_location.top,
  );
  const tooltip_bl = new fabric.Point(
    tool_tip_expect_location.left,
    tool_tip_expect_location.top + tooltip.height,
  );
  const tooltip_br = new fabric.Point(
    tool_tip_expect_location.left + tooltip.width,
    tool_tip_expect_location.top + tooltip.height,
  );
  const points = [tooltip_tl, tooltip_tr, tooltip_bl, tooltip_br];
  /*top*/
  const margin = 2;
  if (fabric.Intersection.intersectLinePolygon(tl, tr, points).status === 'Intersection') {
    tool_tip_expect_location.top = tl.y + margin;
  }
  /*left*/
  if (fabric.Intersection.intersectLinePolygon(tl, bl, points).status === 'Intersection') {
    tool_tip_expect_location.left = tl.x + margin;
  }
  /*right*/
  if (fabric.Intersection.intersectLinePolygon(tr, br, points).status === 'Intersection') {
    tool_tip_expect_location.left = tr.x - tooltip.width - margin;
  }
  /*bottom*/
  if (fabric.Intersection.intersectLinePolygon(bl, br, points).status === 'Intersection') {
    tool_tip_expect_location.top = bl.y - tooltip.height - margin;
  }
  return tool_tip_expect_location;
}

function getPointer(e) {
  const { x, y, width, height } = e.target.getBoundingClientRect();
  const offsetX = ((e.touches[0].clientX - x) / width) * e.target.width;
  const offsetY = ((e.touches[0].clientY - y) / height) * e.target.height;
  return new fabric.Point(offsetX, offsetY);
}

function getAngle(center_point, target_point) {
  const target = {
    x: target_point.x - center_point.x,
    y: target_point.y - center_point.y,
  };
  const angle = Math.atan2(target.y, target.x) / (Math.PI / 180) + 90;
  if (angle < 0) {
    return 360 + angle;
  }
  return angle;
}

export const init = (fcanvas) => {
  const top_upper_canvas_el = fcanvas.topUpperCanvasEl;
  top_upper_canvas_el.style.display = 'block';
  const canvas_zoom_wrapper = $closest('.canvas_zoom_wrapper')(top_upper_canvas_el);
  let screen_scale = 1;
  let selected_init_scale = 1;
  let selected;
  let mode = '';
  let picked_cv_obj;
  let o_coords;
  let initial_distance;
  let previous_point;
  let select_box;
  let diff_angle;
  let one_time;
  const auto_hold = {
    pinch_x: 0,
    pinch_y: 0,
  };
  const zoom = {
    prev_center: 0,
    prev_zoom: 1,
    center: {},
    translateXY: {},
  };

  const can_press_move = { x: 0, y: 0 };
  const el_maker_frame = $qs('#maker_frame');

  if (top_upper_canvas_el._pinch_mode) top_upper_canvas_el._pinch_mode.destroy();
  top_upper_canvas_el._pinch_mode = new AlloyFinger(top_upper_canvas_el, {
    touchStart: (e) => {
      e.preventDefault();
      can_press_move.x = 0;
      can_press_move.y = 0;
      mode = null;
      previous_point = null;
      select_box = null;
      initial_distance = null;
      diff_angle = null;
      screen_scale = getScreenScale(e);
      selected = NewMakerFcanvasGetF.getActiveCvObj(fcanvas);
      const pointer = getPointer(e);
      picked_cv_obj = fcanvas.findTarget(e);
      if (!selected) {
        if (picked_cv_obj) {
          selected = picked_cv_obj;
          G.mp.maker.multiple_select(selected);
        }
      } else {
        mode = selected._findTargetCorner(pointer);
        if (!mode && picked_cv_obj && picked_cv_obj !== selected) {
          selected = picked_cv_obj;
          G.mp.maker.multiple_select(selected);
        }
        selected_init_scale = selected.scaleX;
        o_coords = selected.oCoords;
      }
      if (selected) {
        if (selected._objects && selected._data?.cv_type !== 'cv_group') {
          go(
            selected._objects,
            some((cv_obj) => cv_obj._data.is_fitted_to_print_width),
            (fitted_cv_obj) => {
              if (!fitted_cv_obj) return;
              NewMakerFitPrintWidthF.activateDataFitToPrintWidth(selected);
            },
          );
        }
      }
    },
    multipointStart: (e) => {
      if (mode === MODE.TOOL_TIP) return;
      if (e.touches.length !== 2) return;
      e.preventDefault();
      if (selected) {
        mode = MODE.SCALE;
        selected_init_scale = selected.scaleX;
      } else {
        mode = MODE.ZOOM;
        zoom.prev_zoom = 1;
        const x = Math.min(e.touches[0].pageX, e.touches[1].pageX);
        const y = Math.min(e.touches[0].pageY, e.touches[1].pageY);
        zoom.center = {
          x: x + Math.abs((e.touches[0].pageX - e.touches[1].pageX) / 2),
          y: y + Math.abs((e.touches[0].pageY - e.touches[1].pageY) / 2),
        };
        zoom.prev_center = zoom.center;
        zoom.center = G.mp.maker.zoom.maker_center(canvas_zoom_wrapper, zoom.center);
        zoom.translateXY = G.mp.maker.zoom.make_translateXY(canvas_zoom_wrapper, zoom.center);
      }
      one_time = true;
    },
    pinch: (e) => {
      if (mode === MODE.TOOL_TIP) return;
      e.preventDefault();
      if (selected) {
        if (mode !== MODE.SCALE) return;
        if (selected?._data?.is_embro) return;
        if (el_maker_frame.dataset.is_carved_phonecase_product === 'true') return;
        if (el_maker_frame.dataset.is_embroidery_app === 'true') return;
        if (selected.lockScalingX || selected.lockScalingY) return;
        const scale = e.zoom * selected_init_scale;
        const point = new fabric.Point(selected.getCenterPoint().x, selected.getCenterPoint().y);
        selected.scale(scale);
        selected.setPositionByOrigin(point, 'center', 'center');
        fcanvas.renderAll();
        G.mp.maker.reset_corner_size2(selected);
        G.mp.maker.scaling(selected);
        NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(selected);
      } else {
        if (mode !== MODE.ZOOM) return;
        if (e.touches.length !== e.targetTouches.length && e.targetTouches.length !== 2) return;
        const x = _p.min([e.targetTouches[0].pageX, e.targetTouches[1].pageX]);
        const y = _p.min([e.targetTouches[0].pageY, e.targetTouches[1].pageY]);
        const current_center = {
          x: x + Math.abs((e.targetTouches[0].pageX - e.targetTouches[1].pageX) / 2),
          y: y + Math.abs((e.targetTouches[0].pageY - e.targetTouches[1].pageY) / 2),
        };

        const deltaX = current_center.x - zoom.prev_center.x;
        const deltaY = current_center.y - zoom.prev_center.y;
        zoom.prev_center = current_center;

        zoom.translateXY.x += deltaX;
        zoom.translateXY.y += deltaY;

        let zoom_value = e.zoom - zoom.prev_zoom;
        if (zoom_value >= 0) {
          zoom_value += 1;
        } else {
          zoom_value = Math.abs(zoom_value);
          zoom_value += 1;
          zoom_value = 1 / zoom_value;
        }

        let scale = anime.getValue(canvas_zoom_wrapper, 'scale');
        scale *= zoom_value;
        G.mp.maker.zoom.adjust_scale(canvas_zoom_wrapper, scale, zoom.center, zoom.translateXY);
        zoom.prev_zoom = e.zoom;
      }
    },
    pressMove: (e) => {
      e.preventDefault();
      if (mode === MODE.TOOL_TIP) return;

      if (!selected && !picked_cv_obj) {
        mode = MODE.SELECT;
      }
      if (mode && mode === MODE.SELECT) {
        const pointer = getPointer(e);
        previous_point = previous_point || pointer;
        const ctx = fcanvas.contextTopUpper || fcanvas.topUpperCanvasEl?.getContext('2d');
        if (!ctx) return;
        ctx.fillStyle = NewMakerUtilF.getMakerBoxColor(0.2);
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.3)';
        const line_width = 1;
        ctx.lineWidth = line_width;
        ctx.clearRect(0, 0, fcanvas.topUpperCanvasEl.width, fcanvas.topUpperCanvasEl.height);
        select_box = [
          previous_point.x,
          previous_point.y,
          pointer.x - previous_point.x,
          pointer.y - previous_point.y,
        ];
        ctx.fillRect(...select_box);
        ctx.strokeRect(...select_box);
      }

      if (el_maker_frame.dataset.is_carved_phonecase_product === 'true') return;
      if (el_maker_frame.dataset.is_embroidery_app === 'true') return;
      if (mode && some((v) => v === mode)([MODE.TL, MODE.TR, MODE.BL, MODE.BR])) {
        if (selected.lockScalingX || selected.lockScalingY) return;
        let counterpart, origin;
        if (mode === MODE.TL) {
          counterpart = o_coords.br;
          origin = ['right', 'bottom'];
        } else if (mode && mode === MODE.TR) {
          counterpart = o_coords.bl;
          origin = ['left', 'bottom'];
        } else if (mode && mode === MODE.BL) {
          counterpart = o_coords.tr;
          origin = ['right', 'top'];
        } else if (mode && mode === MODE.BR) {
          counterpart = o_coords.tl;
          origin = ['left', 'top'];
        }
        const pointer = getPointer(e);
        const distance = pointer.distanceFrom(counterpart);
        initial_distance = initial_distance || distance;
        const scale = 1 + (distance - initial_distance) / initial_distance;
        selected.scale(scale * selected_init_scale);
        selected.setPositionByOrigin(counterpart, ...origin);
        selected.setCoords();
        fcanvas.renderAll();
        G.mp.maker.reset_corner_size2(selected);
        G.mp.maker.scaling(selected);
        NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(selected);
      }
      if (mode && mode === MODE.MTR) {
        if (selected.lockRotation) return;
        const pointer = getPointer(e);
        diff_angle = diff_angle || selected.angle - getAngle(selected.getCenterPoint(), pointer);
        go(getAngle(selected.getCenterPoint(), pointer) + diff_angle, makeProperAngle, (angle) =>
          selected.setAngle(angle),
        );
        selected.setCoords();
        NewMakerMaskingTapeF.rotateCloneHorizontalCvObj(selected);
        G.mp.maker.show_rotation_info(selected);
        fcanvas.renderAll();
      }

      if (Math.sqrt(Math.pow(can_press_move.x, 2) + Math.pow(can_press_move.y, 2)) < 2) {
        can_press_move.x += e.deltaX;
        can_press_move.y += e.deltaY;
        return;
      }

      if (!mode || mode === MODE.MOVE) {
        mode = MODE.MOVE;
        const pinch_x_is_more = Math.abs(auto_hold.pinch_x) >= G.mp.maker.center_mode.SENS;
        const pinch_y_is_more = Math.abs(auto_hold.pinch_y) >= G.mp.maker.center_mode.SENS;
        e.deltaX = (e.deltaX / window.innerWidth) * G.mp.maker.CANVAS_WIDTH;
        e.deltaY = (e.deltaY / window.innerHeight) * G.mp.maker.CANVAS_HEIGHT;
        e.deltaX /= screen_scale;
        e.deltaY /= screen_scale;
        if (!selected.lockMovementX)
          selected
            .set('left', selected.left + e.deltaX + (pinch_x_is_more ? auto_hold.pinch_x : 0))
            .setCoords();
        if (!selected.lockMovementY)
          selected
            .set('top', selected.top + e.deltaY + (pinch_y_is_more ? auto_hold.pinch_y : 0))
            .setCoords();

        if (pinch_x_is_more) auto_hold.pinch_x = 0;
        if (pinch_y_is_more) auto_hold.pinch_y = 0;
        if (G.mp.maker.center_mode.active && (Math.abs(e.deltaX) < 1 || Math.abs(e.deltaY) < 1)) {
          _p.go(G.mp.maker.center_mode.auto_center(selected), function (hori, vert) {
            if (hori && vert) {
              auto_hold.pinch_x += e.deltaX;
              auto_hold.pinch_y += e.deltaY;
            } else if (hori) {
              auto_hold.pinch_x += e.deltaX;
              auto_hold.pinch_y = 0;
            } else if (vert) {
              auto_hold.pinch_y += e.deltaY;
              auto_hold.pinch_x = 0;
            } else {
              auto_hold.pinch_x = 0;
              auto_hold.pinch_y = 0;
            }
          });
        }
        NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(selected);
        fcanvas.renderAll();
      }
    },
    longTap: (e) => {
      e.preventDefault();
      if (el_maker_frame.dataset.is_carved_phonecase_product === 'true') return;
      if (el_maker_frame.dataset.is_embroidery_app === 'true') return;
      const pointer = getPointer(e);
      const is_picked_selected = selected?.containsPoint(pointer);
      if (selected && !is_picked_selected) return;
      if (
        some((v) => v === mode)([
          MODE.ZOOM,
          MODE.SCALE,
          MODE.TL,
          MODE.BL,
          MODE.TR,
          MODE.BR,
          MODE.MTR,
          MODE.MOVE,
        ])
      )
        return;
      mode = MODE.TOOL_TIP;
      go(
        $qs('#tooltip_canvas'),
        $removeClass(all_class_names.join(' ')),
        tap(function (el) {
          const tooltip = { height: 40 };
          const class_name = (() => {
            if (G.mp.maker.copied_cv_object) {
              if (selected && is_picked_selected) {
                return 'has_clip_active';
              } else {
                return 'has_clip_none';
              }
            } else if (selected && is_picked_selected) {
              return 'has_active';
            } else {
              return 'none_active';
            }
          })();
          tooltip.width = go(
            el,
            $addClass('is_show'),
            $addClass(class_name),
            $css('width'),
            (width) => parseInt(width) + 10,
          );
          const maker_menu_height = go(el_maker_frame, $find('.maker_menu'), $height);
          el_maker_frame.dataset.long_press_top = pointer.y;
          el_maker_frame.dataset.long_press_left = pointer.x;
          go(
            el,
            $setCss(
              getTopLeft(
                tooltip,
                { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY },
                {
                  tl: new fabric.Point(0, maker_menu_height),
                  tr: new fabric.Point(window.innerWidth, maker_menu_height),
                  bl: new fabric.Point(0, window.innerHeight),
                  br: new fabric.Point(window.innerWidth, window.innerHeight),
                },
              ),
            ),
          );
        }),
      );
    },
    touchEnd: async (e) => {
      if ($qs('#maker_frame .decoration_menu.decoration_mode')) await decorationOff();
      e.preventDefault();
      G.mp.maker.hide_width_info();
      G.mp.maker.center_mode.hide();
      if (mode === MODE.TOOL_TIP) {
        return;
      }
      if ($qs('#tooltip_canvas.is_show')) G.mp.maker.tooltip_canvas.close();
      const maker_menu_el = $qs('.maker_menu');
      if (maker_menu_el?.dataset?.down_list_show === 'true') {
        maker_menu_el.dataset.down_list_show = 'false';
      }
      if (mode === MODE.SELECT) {
        const ctx = fcanvas.contextTopUpper || fcanvas.topUpperCanvasEl?.getContext('2d');
        if (!ctx) return;
        ctx.clearRect(0, 0, fcanvas.topUpperCanvasEl.width, fcanvas.topUpperCanvasEl.height);

        let selection_x1y1;
        let selection_x2y2;
        if (select_box[2] > 0 && select_box[3] < 0) {
          selection_x1y1 = new fabric.Point(select_box[0], select_box[1] + select_box[3]);
          selection_x2y2 = new fabric.Point(select_box[0] + select_box[2], select_box[1]);
        } else if (select_box[2] < 0 && select_box[3] < 0) {
          selection_x1y1 = new fabric.Point(select_box[0] + select_box[2], select_box[1] + select_box[3]);
          selection_x2y2 = new fabric.Point(select_box[0], select_box[1]);
        } else if (select_box[2] < 0 && select_box[3] > 0) {
          selection_x1y1 = new fabric.Point(select_box[0] + select_box[2], select_box[1]);
          selection_x2y2 = new fabric.Point(select_box[0], select_box[1] + select_box[3]);
        } else {
          selection_x1y1 = new fabric.Point(select_box[0], select_box[1]);
          selection_x2y2 = new fabric.Point(select_box[0] + select_box[2], select_box[1] + select_box[3]);
        }
        go(
          getCvDesigns(fcanvas._objects),
          filter((cv_obj) => cv_obj.evented),
          filter((cv_obj) => {
            return (
              cv_obj.intersectsWithRect(selection_x1y1, selection_x2y2) ||
              cv_obj.isContainedWithinRect(selection_x1y1, selection_x2y2) ||
              cv_obj.containsPoint(selection_x1y1) ||
              cv_obj.containsPoint(selection_x2y2)
            );
          }),
          (arr) => arr.length && G.mp.maker.multiple_select(arr),
        );
        return;
      }
      if (some((v) => v === mode)([MODE.MOVE, MODE.TL, MODE.TR, MODE.BL, MODE.BR, MODE.MTR])) {
        if (selected) {
          // NewMakerMaskingTapeF.moveActiveIntoRect(fcanvas);
          fcanvas.fire('object:modified', { target: selected });
          if (some((v) => v === mode)([MODE.TL, MODE.TR, MODE.BL, MODE.BR])) {
            await NewMakerCheckerF.alertImageQuality({
              cv_obj: selected,
              ratio: fcanvas._print_ratio,
            });
          }
        }
        return;
      }

      if (mode === MODE.SCALE) {
        if (one_time) {
          one_time = false;
          if (selected) {
            fcanvas.fire('object:modified', { target: selected, _transform_kind: 'scaling' });
          }
        }
      }
      if (mode === MODE.ZOOM) {
        if (one_time) {
          G.mp.maker.zoom.set_zoom_attr();
          one_time = false;
        }
      }
      if (!mode && !picked_cv_obj) {
        G.mp.maker.unselect_all2(fcanvas);
      }
    },
  });
};
