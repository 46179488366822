import axios from 'axios';
export const apiCalls = {
  get: {
    cjWaybillTracking: async ({ waybill_no }) =>
      (
        await axios.get('/@api/waybill/cj/req_parcel_req_parcel_tracking', {
          params: { INVC_NO: waybill_no },
        })
      ).data,
    projectionStatus: async ({ projection_id }) =>
      (
        await axios.get('/@api/projection/status', {
          params: {
            projection_id,
          },
        })
      ).data,
    customerOrderCrewInhouseFromPrjId: async ({ projection_id }) =>
      (
        await axios.get('/@api/projection/customer_order_crew_inhouse_from_projection', {
          params: {
            projection_id,
          },
        })
      ).data,
    customerOrderCrewInhouseFromId: async ({ customer_order_crew_inhouse_id }) =>
      (
        await axios.get('/@api/projection/customer_order_crew_inhouse', {
          params: {
            customer_order_crew_inhouse_id,
          },
        })
      ).data,
    crewInhouseShippingFromId: async ({ crew_inhouse_id }) =>
      (
        await axios.get('/@api/projection/crew_inhouse_shippings', {
          params: { crew_inhouse_id },
        })
      ).data,
    hasShippingWaybill: async ({ shipping_id }) =>
      (
        await axios.get('/@api/projection/has_shipping_waybill', {
          params: { shipping_id },
        })
      ).data,
    hasUserInhouseLabelingPolicy: async () =>
      (await axios.get('/@api/projection/has_inhouse_labeling_policy')).data,

    inhouseShippingBoxFromId: async ({ inhouse_shipping_box_id }) =>
      (await axios.get('/@api/projection/inhouse_shipping_box', { params: { inhouse_shipping_box_id } }))
        .data,

    inhouseShippingBoxFromPrjId: async ({ projection_id }) =>
      (await axios.get('/@api/projection/inhouse_shipping_box_from_prj_id', { params: { projection_id } }))
        .data,

    inhouseShippingBucketFromId: async ({ inhouse_shipping_bucket_id }) =>
      (
        await axios.get('/@api/projection/inhouse_shipping_bucket', {
          params: { inhouse_shipping_bucket_id },
        })
      ).data,

    inhouseShippingBucketFromWaybillNo: async ({ waybill_no }) =>
      (
        await axios.get('/@api/projection/inhouse_shipping_bucket_from_waybill_no', {
          params: { waybill_no },
        })
      ).data,
    inhouseShippingBucketsFromIds: async ({ inhouse_shipping_bucket_ids }) =>
      (
        await axios.get('/@api/projection/inhouse_shipping_buckets_from_ids', {
          params: { inhouse_shipping_bucket_ids },
        })
      ).data,
    inhouseShippingBucketsFromDateRange: async ({ start_date, end_date }) =>
      (
        await axios.get('/@api/projection/inhouse_shipping_buckets_from_date_range', {
          params: {
            start_date,
            end_date,
          },
        })
      ).data,
    bucketPackedStatus: async ({ bucket_id }) => {
      return (
        await axios.get('/@api/projection/inhouse_shipping_bucket_packed_status', {
          params: { bucket_id },
        })
      ).data;
    },
  },
  put: {
    shippingTaskStatus: async ({ projection_id, status_tobe }) =>
      (
        await axios.put('/@api/projection/shipping_task_status', {
          projection_id,
          status_tobe,
        })
      ).data,

    boxUnlinkingToBucket: async ({ box_id }) =>
      (await axios.put('/@api/projection/box_unlinking_to_bucket', { box_id })).data,

    boxLinkingToBucket: async ({ box_id, bucket_id }) =>
      (await axios.put('/@api/projection/box_linking_to_bucket', { box_id, bucket_id })).data,

    // @description 버킷 포장 완료시 action - 각 주문서 상태 처리, 운송장 처리, 배송 태스크 처리, 버킷 완료 처리
    completeBucket: async ({ bucket_id }) =>
      (
        await axios.put('/@api/projection/complete_inhouse_shipping_bucket', {
          bucket_id,
        })
      ).data,
  },
  post: {
    customerOrderCrewInhouseCrewInhouseShippingId: async ({
      customer_order_crew_inhouse_id,
      crew_inhouse_shipping_id,
    }) =>
      (
        await axios.post('/@api/projection/customer_order_crew_inhouse_crew_inhouse_shipping_id', {
          customer_order_crew_inhouse_id,
          crew_inhouse_shipping_id,
        })
      ).data,
    customerOrderCrewInhouseIsHidden: async ({ projection_id, customer_order_crew_inhouse_id, is_hidden }) =>
      (
        await axios.post('/@api/projection/customer_order_crew_inhouse_is_hidden', {
          projection_id,
          customer_order_crew_inhouse_id,
          is_hidden,
        })
      ).data,
    inhouseShippingBox: async ({ projection_id, customer_order_crew_inhouse_id }) =>
      (
        await axios.post('/@api/projection/inhouse_shipping_box', {
          projection_id,
          customer_order_crew_inhouse_id,
        })
      ).data,
    inhouseShippingBucket: async ({ crew_inhouse_shipping_id, waybill_no, waybill_label_data }) =>
      (
        await axios.post('/@api/projection/inhouse_shipping_bucket', {
          crew_inhouse_shipping_id,
          waybill_no,
          waybill_label_data,
        })
      ).data,
  },
  delete: {
    inhouseShippingBucketByBucketId: async ({ bucket_id }) =>
      (
        await axios.delete('/@api/projection/inhouse_shipping_bucket', {
          params: { bucket_id },
        })
      ).data,
    inhouseShippingBoxByPrjId: async ({ projection_id }) =>
      (
        await axios.delete('/@api/projection/inhouse_shipping_box', {
          params: { projection_id },
        })
      ).data,
  },
};
