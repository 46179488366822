import { each, flatten, go, tap } from 'fxjs/es';
import { $closest, $delegate, $find, $setVal, $trigger, $val } from 'fxdom/es';
import { rerenderCvPreview } from '../cv_object.js';
import { getCurrentFcanvas, getCvDesigns, getCvObj } from '../../Fcanvas/cv_object.js';
import { updateBaseProductFacePreview, updateBaseProductFaceSizeFaces } from '../../CvPrintArea/cv_object.js';
import { makeCvPrintAreaEvent } from './cv_print_area.js';
import { makeCylinderEvent } from './cylinder.js';
import { makeShadeEvent } from './shade.js';
import { makeCompositeEvent } from './composite.js';
import { getBPFTemplateSaveInfo, makeBPFTemplateEvent } from './template.js';
import { getKey, initCvPreviewEditorHtml } from './fs.js';

export function control(fcanvas, func) {
  return go(
    [getCvObj(fcanvas._objects, 'cv_print_area'), getCvDesigns(fcanvas._objects)],
    flatten,
    each(func),
    async function () {
      if (fcanvas.preview?.on) {
        await rerenderCvPreview(fcanvas);
      }
    },
  );
}

async function save(el) {
  $.don_loader_start();
  const fcanvas = getCurrentFcanvas();
  const preview = go(fcanvas.preview && JSON.parse(JSON.stringify(fcanvas.preview)), function (preview) {
    if (!preview) return null;
    delete preview.on;
    if (preview.composite && preview.composite.m) {
      delete preview.composite.m;
    }
    return preview;
  });
  await updateBaseProductFacePreview(fcanvas, preview, true);
  await updateBaseProductFaceSizeFaces(
    fcanvas,
    (sf) => {
      sf.template = getBPFTemplateSaveInfo(el);
    },
    true,
  );
  $.don_loader_end();
  $.alert('성공적으로 저장 됐습니다.');
}

export const makeCvPreviewEditorEvent = (el) =>
  go(
    el,
    makeCvPrintAreaEvent,
    makeCylinderEvent,
    makeShadeEvent,
    makeCompositeEvent,
    makeBPFTemplateEvent,
    tap(
      $.on3('click', '.cv_preview_editor .shade2_editor .is_color_code2', async (e) => {
        const fcanvas = getCurrentFcanvas();
        fcanvas.preview.shade2[getKey(e)] = e.currentTarget.checked === true;
        await control(fcanvas, (cv_obj) => {});
      }),
      $.on3('change', '.cv_preview_editor .shade2_editor .item[key="deep_step"] input', async (e) => {
        const fcanvas = getCurrentFcanvas();
        fcanvas.preview.shade2[getKey(e)] = Number($val(e.currentTarget));
        await control(fcanvas, (cv_obj) => {});
      }),
    ),
    $delegate('click', '.cv_preview_editor .refresh', (e) => {
      initCvPreviewEditorHtml($1('.mp_maker'), getCurrentFcanvas());
    }),
    $.on3('click', '.cv_preview_editor .horizontal_func .horizontal_center', async (e) => {
      go(
        e.currentTarget,
        $closest('.horizontal_func'),
        (cv_print_area_el) => {
          return [
            $find('input[name="left"]', cv_print_area_el),
            $find('input[name="width"]', cv_print_area_el),
          ];
        },
        ([left_el, width_el]) => {
          const width = go(width_el, $val, parseInt);
          const left = (860 - width) / 2;
          $setVal(left)(left_el);
          $trigger('change', left_el);
        },
      );
    }),
    $delegate('click', '.cv_preview_editor >.option .save', async (e) => {
      try {
        $.don_loader_start();
        await save($find('.cv_preview_editor', e.delegateTarget));
        $.don_loader_end();
        $.alert('성공적으로 저장 됐습니다.');
      } catch (e) {
        $.don_loader_end();
        console.error(e);
        $.alert('저장 실팼습니다.');
      }
    }),
  );
