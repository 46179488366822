import { constant, each, entries, go, head, range, sel, strMap } from 'fxjs/es';
import { OMPAuthConstantS } from '../Constant/module/OMPAuthConstantS.js';

export const validPassword = (pwd, email) => {
  if (!pwd || pwd.length < 10) {
    return {
      message: TT('error::auth::error_01'),
      result: false,
    };
  }

  if (/(\w)\1\1\1/.test(pwd)) {
    return {
      message: TT('error::auth::error_02'),
      result: false,
    };
  }

  if (email) {
    if (pwd.search(email.split('@')[0]) > -1) {
      return {
        message: TT('error::auth::error_10'),
        result: false,
      };
    }
  }

  let ascSeqCharCnt = 0; // 오름차순 연속 문자 카운트
  let descSeqCharCnt = 0; // 내림차순 연속 문자 카운트

  for (let i = 0; i < pwd.length; i++) {
    // charAt(): 문자값 반환
    const char_0 = pwd.charAt(i);
    const char_1 = pwd.charAt(i + 1);
    const char_2 = pwd.charAt(i + 2);

    // charCodeAt(): 유니코드값 반환
    const diff_0_1 = char_0.charCodeAt(0) - char_1.charCodeAt(0);
    const diff_1_2 = char_1.charCodeAt(0) - char_2.charCodeAt(0);

    if (diff_0_1 === 1 && diff_1_2 === 1) {
      ascSeqCharCnt += 1;
    }

    if (diff_0_1 === -1 && diff_1_2 === -1) {
      descSeqCharCnt += 1;
    }

    if (ascSeqCharCnt > 1 || descSeqCharCnt > 1) {
      return {
        message: TT('error::auth::error_03'),
        result: false,
      };
    }
  }

  if (!/((?=.*\d)(?=.*[a-zA-Z])(?=.*[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]).{10,})/.test(pwd)) {
    return {
      message: TT('error::auth::error_04'),
      result: false,
    };
  }

  return { result: true };
};

export const signupValid = (signup_data) => {
  const sign_up = OMPAuthConstantS.SIGN_UP();

  go(
    { ...sign_up.inputs, ...sign_up.terms },
    entries,
    each(([key, value]) => {
      const error_message = value?.valid && value?.valid(signup_data?.[key]);
      if (error_message) throw new OMPAuthConstantS.AuthError(error_message, { id: key }, 400);
    }),
  );

  return signup_data;
};

export const signupInputValid = (signup_input_data) => {
  go(
    OMPAuthConstantS.SIGN_UP().inputs,
    entries,
    each(([key, value]) => {
      const error_message = value?.valid && value?.valid(signup_input_data?.[key]);
      if (error_message) throw new OMPAuthConstantS.AuthError(error_message, { id: key }, 400);
    }),
  );

  return signup_input_data;
};

export const loginInputValid = (signup_input_data) => {
  go(
    OMPAuthConstantS.LOGIN().inputs,
    entries,
    each(([key, value]) => {
      const error_message = value?.valid && value?.valid(signup_input_data?.[key]);
      if (error_message) throw new OMPAuthConstantS.AuthError(error_message, { id: key }, 400);
    }),
  );

  return signup_input_data;
};

export const makeBlindUser = (user) => {
  const name = sel('email', user) ? head(user.email.split('@')) : sel('name', user);
  const len = sel('length', name);
  const len2 = Math.min(Math.round(len / 3), 3);
  return {
    email: name ? name.substr(0, Math.min(len - len2, 4)) + strMap(constant('*'), range(len2)) : 'Guest',
  };
};
