import { $delegate, $el, $find, $findAll, $height, $on, $scrollTop, $setScrollTop } from 'fxdom/es';
import { SVGEditorRangeSliderWrapperF } from '../../../../RangeSliderWrapper/F/Function/module/SVGEditorRangeSliderWrapperF.js';
import { shape } from '../../S/Tmpl/shape.js';
import { SVGEditorColorF } from '../../../../Color/F/Function/module/SVGEditorColorF.js';
import { each, go, tap } from 'fxjs/es';
import { SVGEditorStrokeDashF } from '../../../../StrokeDash/F/Function/module/SVGEditorStrokeDashF.js';

export const fn = ({
  tab_el,
  svg_el,
  is_path,
  single_sticker_editor,
  frame_position: {
    top: frame_position_top,
    left: frame_position_left,
    width: frame_position_width,
    height: frame_position_height,
  },
  prev_frame_right_panel_el,
  editPath,
}) => {
  return {
    el: $el(shape({ svg_el, is_path })),
    removed: (el) => {
      el.myScroll?.destroy();
      el.myScroll = null;
      el.__pickr_color_tab?.destroyAndRemove();
      el.__pickr_color_tab = null;
      el.__pickr_border_tab?.destroyAndRemove();
      el.__pickr_border_tab = null;
    },
    appended: (el) => {
      if ($height(el) < go(el, $find('.scroll_wrapper'), $height)) {
        el.myScroll = new IScroll(el, {
          mouseWheel: true,
          scrollbars: true,
        });
      }

      SVGEditorStrokeDashF.event({ target_el: el, svg_el, mp_sticker_editor: single_sticker_editor });

      go(
        el,
        $delegate('click', '.back', () => single_sticker_editor.selectEls([])),
        $delegate('click', '.tab', (e) => {
          el.dataset.current_tab = e.currentTarget.dataset.tab_name;
          go(
            el,
            $findAll('.range_slider_wrapper'),
            each((range_slider_wrapper_el) =>
              range_slider_wrapper_el.__range_slider.rangeslider('update', true),
            ),
          );
          el.myScroll?.refresh();
        }),

        tap(
          $findAll(`.go_to_path_editor_wrapper .go_to_path_editor`),
          each(
            $on('click', async () => {
              if ($scrollTop(window) > 30) {
                await window.anime({
                  targets: 'html, body',
                  scrollTop: 0,
                  duration: 400,
                  easing: 'easeInOutCubic',
                }).finished;
              } else {
                $setScrollTop(0)(window);
              }

              return editPath({
                left_menu_els: $findAll(`.left_menu`)(tab_el),
                onFrameHiding: () => ((tab_el?.tab_opt ?? {}).tab_top = $scrollTop(window)),
                prev_frame_right_panel_el,
                frame_position: {
                  top: frame_position_top,
                  left: frame_position_left,
                  width: frame_position_width,
                  height: frame_position_height,
                },
                single_sticker_editor,
                path_el: svg_el,
              });
            }),
          ),
        ),
      );
      SVGEditorRangeSliderWrapperF.event({
        el,
        __mp_sticker_editor: single_sticker_editor,
        ___selected_el: svg_el,
      });
      el.__pickr_color_tab = SVGEditorColorF.event({
        el: $find('.color_tab_contents')(el),
        key: 'fill',
        ___selected_el: svg_el,
      });
      el.__pickr_border_tab = SVGEditorColorF.event({
        el: $find('.border_tab_contents')(el),
        key: 'stroke',
        ___selected_el: svg_el,
      });
    },
  };
};
