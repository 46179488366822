import {
  $append,
  $closest,
  $delegate,
  $el,
  $find,
  $findAll,
  $next,
  $qs,
  $qsa,
  $remove,
  $setText,
} from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { OMPProductListTmplS } from '../../S/Tmpl/module/OMPProductListTmplS.js';
import { delegateBaseProductLike } from './like.js';
import { reloadByCate, reloadByFilter, reloadBySort } from './reload.js';

const triggerCheckbox = (currentTarget) => {
  const checkbox_checked = $next('[data-checkbox-checked]', currentTarget);
  checkbox_checked.dataset.checkboxChecked = currentTarget.checked;
  go($closest('.omp-product-filter-list__list', currentTarget), (list$) => {
    const { length } = $findAll('.omp-product-filter-item__input-checkbox:checked', list$);
    $setText(`(${length})`, $find('.omp-product-filter-list__select-count', list$)).dataset.count = length;
  });
};

const resetCheckbox = () => {
  const checked_inputs = $qsa('.omp-product-filter-item__input-checkbox:checked');
  if (checked_inputs.length) {
    each((input) => {
      input.checked = false;
      triggerCheckbox(input);
    }, checked_inputs);
    return true;
  } else {
    return false;
  }
};

export const delegatePc = (tab_el) => {
  go(
    tab_el,
    delegateBaseProductLike,
    $delegate('click', '.omp-product-filter-list__name', ({ currentTarget }) => {
      const is_open_el$ = $closest('[data-is_open]', currentTarget);
      is_open_el$.dataset.is_open = !(is_open_el$.dataset.is_open == 'true');
    }),
    $delegate('input', '.omp-product-filter-item__input-checkbox', ({ currentTarget }) => {
      if (currentTarget.checked) {
        $append(
          $el(
            OMPProductListTmplS.checkedFilterItem({
              id: parseInt(currentTarget.value),
              name: currentTarget.dataset.name,
              filter_list_id: currentTarget.dataset.filter_list_id,
            }),
          ),
          $qs('.opm-product-list-checked-filter-items'),
        );
      } else {
        $remove($qs(`.omp-product-list__checked-filter-item[data-id="${currentTarget.value}"]`));
      }
      triggerCheckbox(currentTarget);
      return reloadByFilter();
    }),
    $delegate('click', '.omp-product-filter__reset-icon', () => {
      if (resetCheckbox()) {
        each($remove, $qsa('.opm-product-list-checked-filter-items .omp-product-list__checked-filter-item'));
        return reloadByFilter();
      }
    }),
    $delegate('click', '.omp-product-list__checked-filter-item', ({ currentTarget }) => {
      const input = $qs(
        `.omp-product-filter-item__input-checkbox[value="${currentTarget.dataset.id}"][data-filter_list_id="${currentTarget.dataset.filter_list_id}"]:checked`,
      );
      if (input) {
        input.checked = false;
        $remove(currentTarget);
        triggerCheckbox(input);
        return reloadByFilter();
      }
    }),
    $delegate('click', '.omp-product-list__sort-item', ({ currentTarget }) => {
      reloadBySort(currentTarget);
    }),
    $delegate('click', '.omp-product-list__cate-item ', (e) => {
      e.originalEvent.preventDefault();
      resetCheckbox();
      return reloadByCate({
        cate_list_id: $qs('.omp-product-list__checked-cate-list').dataset.cate_list_id,
        cate_item_id: e.currentTarget.dataset.id,
      });
    }),
    $delegate('click', '.omp-product-list__checked-cate-list', () => {
      $.body_fixed(true);
      const { cate_list_id: checked_cate_list_id, cate_item_id: checked_cate_item_id } = $qs(
        '.omp-product-list__checked-cate-list',
      ).dataset;
      go(
        OMPProductListTmplS.cateSelector({
          checked_cate_item_id,
          checked_cate_list_id,
          cate_lists: box.sel('cate_lists'),
        }),
        $el,
        (cate_selector$) => {
          $append(cate_selector$, $qs('.omp-layout-body'));
          $find(
            '.omp-product-cate-selector__cate-item-name[data-radio-checked="true"]',
            cate_selector$,
          )?.scrollIntoView({
            block: 'center',
          });
          return cate_selector$;
        },
        (cate_selector$) => {
          go(
            cate_selector$,
            $delegate('input', '.omp-product-cate-selector__cate-item-radio', ({ currentTarget }) => {
              $find('[data-radio-checked="true"]', cate_selector$).dataset.radioChecked = false;
              $next('[data-radio-checked]', currentTarget).dataset.radioChecked = true;
              const { cate_list_id, cate_item_id } = currentTarget.dataset;
              $remove(cate_selector$);
              $.body_fixed(false);
              if (checked_cate_item_id != cate_item_id || cate_list_id != checked_cate_list_id) {
                resetCheckbox();
                return reloadByCate({
                  cate_list_id,
                  cate_item_id,
                });
              }
            }),
            $delegate('click', '.omp-product-cate-selector__x-btn', () => {
              $remove(cate_selector$);
              $.body_fixed(false);
            }),
          );
        },
      );
    }),
  );
};
