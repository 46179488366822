import { html } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';

/**
 * Create a product content component with the specified options.
 *
 * @param {Object} options - The options for the curation pod item.
 * @param {string} [options.image_src=''] - The URL of the product content thumbnail (optional, default: '').
 * @param {string} [options.title=''] - The name of the product (optional, default: '').
 * @param {string} [options.sub_title=''] - The minimum price text of the product (optional, default: '').
 * @param {string} [options.url=''] - Whether the product is in the wish list (optional, default: false).
 * @returns {string} The HTML markup for the curation pod item component.
 */
export const curationPODItem = ({
  id,
  content_title = '',
  content_description = '',
  thumbnail_url = '',
  link: { is_new_tab = false, href = '' } = {},
} = {}) => {
  return html`
    <div class="curation-pod-item" data-id="${id}">
      <a class="curation-pod-item__anchor" href="${href}" ${is_new_tab ? 'target="_blank"' : ''}>
        <div class="curation-pod-item__image">
          <div class="curation-pod-item__dim"></div>
          <img src="${UtilImageS.getResized70Webp(thumbnail_url, 400)}" alt="" />
        </div>
        <div class="curation-pod-item__meta">
          <h3 class="curation-pod-item__title">${content_title}</h3>
          <p class="curation-pod-item__sub-title">${content_description}</p>
        </div>
      </a>
    </div>
  `;
};

/**
 * Create a product content component with the specified options.
 *
 * @param {Object} options - The options for the curation pod item.
 * @param {string} [options.image_src=''] - The URL of the product content thumbnail (optional, default: '').
 * @param {string} [options.content=''] - The name of the product (optional, default: '').
 * @param {string} [options.url=''] - Whether the product is in the wish list (optional, default: false).
 * @returns {string} The HTML markup for the curation pod item component.
 */
export const curationKeywordItem = ({ image_src, content = '', url = '' } = {}) => {
  return html`
    <div class="curation-keyword-item">
      <a class="curation-keyword-item__anchor" href="${url}">
        <div class="curation-keyword-item__image">
          <img src="${UtilImageS.getResized70Webp(image_src, 140)}" alt="" />
        </div>
        <h3 class="curation-keyword-item__title">${content}</h3>
      </a>
    </div>
  `;
};

/**
 * Create a product content component with the specified options.
 *
 * @param {Object} options - The options for the curation pod item.
 * @param {string} [options.image_src=''] - The URL of the product content thumbnail (optional, default: '').
 * @param {string} [options.content=''] - The name of the product (optional, default: '').
 * @param {string} [options.icon=''] - The name of the product (optional, default: '').
 * @param {string} [options.url=''] - Whether the product is in the wish list (optional, default: false).
 * @returns {string} The HTML markup for the curation pod item component.
 */
export const curationKeywordItemMo = ({ image_src, title = '', emoji, url = '' } = {}) => {
  return html`
    <div class="curation-keyword-item">
      <a class="curation-keyword-item__anchor" href="${url}">
        <h3 class="curation-keyword-item__title">
          <span class="curation-keyword-item__emoji">${emoji}</span>
          ${title}
        </h3>
      </a>
    </div>
  `;
};
