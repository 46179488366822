import { omitBy } from 'fxjs/es';

export const FRONT_SHIPPING_PROCESS_ALERT_TIMEOUT = 7000;

export const COLLABO_TYPE_OBJ = {
  '': '마플',
  line: '라인프렌즈',
  creator: '마플샵(배송용)',
  'creator-sample': '마플샵(샘플)',
};

export const APP_KEYNAME_FOR_PARCEL_CJ_TOKEN = 'parcel_cj';
export const CJ_SHIPPING_PARAMS_KEY = 'cj_request_parameters';
export const PARCEL_CJ_TOKEN_EXPIRATION_DAY_DURATION = 1;

const PARCEL_CJ_OPEN_API_COMMON_HEADER = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const PARCEL_CJ_TRACKING_STATUS_CODE = {
  '01': '집화지시',
  11: '집화처리',
  12: '미집화',
  41: '간선상차',
  42: '간선하차',
  82: '배송출발',
  84: '미배달',
  91: '배송완료',
};

export const PARCEL_CJ_OPEN_APIs = {
  ReqOneDayToken: {
    url: 'https://dxapi.cjlogistics.com:5052/ReqOneDayToken',
    method: 'post',
    name: '1Day 토큰 발행',
    description: '대한통운에서 제공하는 서비스를 사용하기 위한 유효기간 1일인 보안 토큰 발행',
    sending_data: ({ CUST_ID, BIZ_REG_NUM }) =>
      JSON.stringify({
        DATA: {
          CUST_ID, //계약된 고객사 코드, VARCHAR(20), NOT NULL
          BIZ_REG_NUM, //고객ID에 해당하는 청구 사업자 번호, VARCHAR(10), NOT NULL
        },
      }),
    headers: PARCEL_CJ_OPEN_API_COMMON_HEADER,
  },
  ReqAddrRfn: {
    url: 'https://dxapi.cjlogistics.com:5052/ReqAddrRfn',
    method: 'post',
    name: '주소정제',
    description: '고객사 주소 정보를 정제하여 권역 정보와 함께 제공',
    sending_data: ({ TOKEN_NUM, CLNTNUM, CLNTMGMCUSTCD, USER_ID, ADDRESS }) =>
      JSON.stringify({
        DATA: {
          TOKEN_NUM, //토큰 번호, VARCHAR(40), NOT NULL
          CLNTNUM, //고객 ID, VARCHAR(20), NOT NULL
          CLNTMGMCUSTCD, //협력사 코드, VARCHAR(20), 협력사 코드가 없을 경우 고객 ID로 대체
          USER_ID, //중개업체 사용시 필수, 대한통운 OPEN DB 접속 시 사용하는 유저 계정, VARCHAR(20)
          ADDRESS, //주소, VARCHAR(100), NOT NULL
        },
      }),
    headers: PARCEL_CJ_OPEN_API_COMMON_HEADER,
  },
  ReqInvcNo: {
    url: 'https://dxapi.cjlogistics.com:5052/ReqInvcNo',
    method: 'post',
    name: '채번',
    description: '고객사에 운송장 번호 단건 제공',
    sending_data: ({ TOKEN_NUM, CLNTNUM, CLNTMGMCUSTCD, USER_ID, ADDRESS }) =>
      JSON.stringify({
        DATA: {
          TOKEN_NUM, //토큰 번호, VARCHAR(40), NOT NULL
          CLNTNUM, //고객 ID, VARCHAR(20), NOT NULL
          USER_ID, //중개업체 사용시 필수, 대한통운 OPEN DB 접속 시 사용하는 유저 계정, VARCHAR(20)
        },
      }),
    headers: PARCEL_CJ_OPEN_API_COMMON_HEADER,
  },

  ReqOneGdsTrc: {
    url: 'https://dxapi.cjlogistics.com:5052/ReqOneGdsTrc',
    method: 'post',
    name: '상품추적 단건',
    description: '운송장 번호를 기준으로 상품추적 정보 제공',
    sending_data: ({ TOKEN_NUM, CLNTNUM, INVC_NO }) =>
      JSON.stringify({
        DATA: {
          TOKEN_NUM, //토큰 번호, VARCHAR(40), NOT NULL
          CLNTNUM, //고객 ID, VARCHAR(20), NOT NULL
          INVC_NO, // 운송장 번호
        },
      }),
    headers: PARCEL_CJ_OPEN_API_COMMON_HEADER,
  },

  RegBook: {
    url: 'https://dxapi.cjlogistics.com:5052/RegBook',
    method: 'post',
    name: '예약',
    description: '고객사의 배송 요청정보를 대한통운에 등록',
    sending_data: ({
      TOKEN_NUM, //토큰 번호, VARCHAR(40), NOT NULL
      CUST_ID, //계약된 고객사 코드, VARCHAR(20), NOT NULL
      RCPT_YMD, //접수일자(YYYYMMDD), VARCHAR(9)
      CUST_USE_NO, //고객사용번호(기업고객이 관리하는 주문번호/ 영수번호 등 내부 관리번호취소 시에 취소처리 기준(중복되면 취소 불가능)), VARCHAR2(50)
      RCPT_DV = '01', //접수구분(01: 일반, 02: 반품)
      WORK_DV_CD = '01', //작업구분코드(01: 일반, 02: 교환, 03: A/S)
      REQ_DV_CD = '01', //요청구분코드(01: 요청, 02: 취소)
      MPCK_KEY, //합포장 키(다수데이터를 한 송장에 출력할 경우 처리(ex:합포 없는 경우 YYYYMMDD_고객ID_고객사용번호 or YYYYMMDD_고객ID_운송장번호)합포장 키와 합포장 순번은 반드시 무결성이보장 되어야 합니다.), VARCHAR2(100)
      CAL_DV_CD = '01', //정산구분코드(01: 계약 운임)
      FRT_DV_CD = '03', //운임구분코드(01: 선불, 02: 착불, 03: 신용) - 착불/신용 사용
      CNTR_ITEM_CD = '01', //계약품목코드(01: 일반 품목)
      BOX_TYPE_CD = '01', //박스타입코드(01: 극소, 02: 소, 03: 중, 04: 대, 05: 특대)
      BOX_QTY = '01', //박스 수량
      FRT = '0', //운임(운임적용구분이 자료 운임일 경우 등록 처리)
      CUST_MGMT_DLCM_CD, //고객관리거래처코드(주관사 관리 협력업체 코드 혹은 택배사 관리업체코드)
      SENDR_NM = '마플코퍼레이션', //보내는분 명, VARCHAR2(100)
      SENDR_TEL_NO1 = '02', //유선 번호, VARCHAR2(4)
      SENDR_TEL_NO2 = '1566', //VARCHAR2(4)
      SENDR_TEL_NO3 = '9437', //VARCHAR2(4)
      SENDR_CELL_NO1, //휴대폰 번호, VARCHAR2(4)
      SENDR_CELL_NO2, //VARCHAR2(4)
      SENDR_CELL_NO3, //VARCHAR2(4)
      SENDR_SAFE_NO1, //안심번호, VARCHAR2(4)
      SENDR_SAFE_NO2, //VARCHAR2(4)
      SENDR_SAFE_NO3, //VARCHAR2(4)
      SENDR_ZIP_NO = '08589', //보내는 분 우편번호
      SENDR_ADDR = '서울특별시 금천구 가산동 가산디지털1로 75-24', //보내는 분 주소
      SENDR_DETAIL_ADDR = '가산아이에스비즈타워 10층', //보내는 분 상세주소
      RCVR_NM,
      RCVR_TEL_NO1,
      RCVR_TEL_NO2,
      RCVR_TEL_NO3,
      RCVR_CELL_NO1,
      RCVR_CELL_NO2,
      RCVR_CELL_NO3,
      RCVR_SAFE_NO1,
      RCVR_SAFE_NO2,
      RCVR_SAFE_NO3,
      RCVR_ZIP_NO,
      RCVR_ADDR,
      RCVR_DETAIL_ADDR,
      ORDRR_NM,
      ORDRR_TEL_NO1,
      ORDRR_TEL_NO2,
      ORDRR_TEL_NO3,
      ORDRR_CELL_NO1,
      ORDRR_CELL_NO2,
      ORDRR_CELL_NO3,
      ORDRR_SAFE_NO1,
      ORDRR_SAFE_NO2,
      ORDRR_SAFE_NO3,
      ORDRR_ZIP_NO,
      ORDRR_ADDR,
      ORDRR_DETAIL_ADDR,
      INVC_NO, //운송장 번호(RCPT_DV(접수구분) = '02' (반품,회수) 반품의 경우에는 사용 안함 (빈칸으로 전송)), VARCHAR2(20)
      ORI_INVC_NO, //원운송장번호(RCPT_DV(접수구분) = '02' (반품,회수)시 대상이되는 원 출고 운송장번호), VARCHAR2(20)
      ORI_ORD_NO, //원주문 번호, VARCHAR(100)
      PRT_ST, //운송장 출력상태(01: 미출력, 02: 선출력, 03: 선발번 (반품은 선발번이 없음) 반품 (RCPT_DV = '02') 진행 시,PRT_ST = '01' 기재), VARCHAR2(3), NOT NULL
      ARTICLE_AMT, //물품가 액, NUMBER(15)
      REMARK_1, //배송 메세지1 (비고)
      REMARK_2, //배송 메세지2 (보내는분비고)
      REMARK_3, //배송 메세지3 (받는분비고)
      COD_YN = 'N', //COD여부
      ETC_1,
      ETC_2,
      ETC_3,
      ETC_4,
      ETC_5,
      DLV_DV = '01', //택배구분(‘01’: 택배), VARCHAR(2)
      RCPT_SERIAL, //접수시리얼 번호, VARCHAR(50)
      /* 여기서부터 DATA ARRAY */
      MPCK_SEQ = '01', //합포장 순번, NUMBER(20)
      GDS_CD, //상품코드, VARCHAR2(20)
      GDS_NM, //상품명, VARCHAR2(500), NOT NULL
      GDS_QTY, //상품수량, NUMBER(11)
      UNIT_CD, //단품코드, VARCHAR(20)
      UNIT_NM, //단품명, VARCHAR2(500)
      GDS_AMT, //상품가액, NUMBER(15)
    }) =>
      omitBy(([_, v]) => !v, {
        TOKEN_NUM, //토큰 번호, VARCHAR(40), NOT NULL
        CUST_ID, //계약된 고객사 코드, VARCHAR(20), NOT NULL
        RCPT_YMD, //접수일자(YYYYMMDD), VARCHAR(9)
        CUST_USE_NO, //고객사용번호(기업고객이 관리하는 주문번호/ 영수번호 등 내부 관리번호취소 시에 취소처리 기준(중복되면 취소 불가능)), VARCHAR2(50)
        RCPT_DV, //접수구분(01: 일반, 02: 반품)
        WORK_DV_CD, //작업구분코드(01: 일반, 02: 교환, 03: A/S)
        REQ_DV_CD, //요청구분코드(01: 요청, 02: 취소)
        MPCK_KEY, //합포장 키(다수데이터를 한 송장에 출력할 경우 처리(ex:합포 없는 경우 YYYYMMDD_고객ID_고객사용번호 or YYYYMMDD_고객ID_운송장번호)합포장 키와 합포장 순번은 반드시 무결성이보장 되어야 합니다.), VARCHAR2(100)
        CAL_DV_CD, //정산구분코드(01: 계약 운임)
        FRT_DV_CD, //운임구분코드(01: 선불, 02: 착불, 03: 신용) - 착불/신용 사용
        CNTR_ITEM_CD, //계약품목코드(01: 일반 품목)
        BOX_TYPE_CD, //박스타입코드(01: 극소, 02: 소, 03: 중, 04: 대, 05: 특대)
        BOX_QTY, //박스 수량
        FRT, //운임(운임적용구분이 자료 운임일 경우 등록 처리)
        CUST_MGMT_DLCM_CD, //고객관리거래처코드(주관사 관리 협력업체 코드 혹은 택배사 관리업체코드)
        SENDR_NM, //보내는분 명, VARCHAR2(100)
        SENDR_TEL_NO1, //유선 번호,
        SENDR_TEL_NO2, //VARCHAR2(4)
        SENDR_TEL_NO3, //VARCHAR2(4)
        SENDR_CELL_NO1, //휴대폰 번호, VARCHAR2(4)
        SENDR_CELL_NO2, //VARCHAR2(4)
        SENDR_CELL_NO3, //VARCHAR2(4)
        SENDR_SAFE_NO1, //안심번호, VARCHAR2(4)
        SENDR_SAFE_NO2, //VARCHAR2(4)
        SENDR_SAFE_NO3, //VARCHAR2(4)
        SENDR_ZIP_NO, //보내는 분 우편번호
        SENDR_ADDR, //보내는 분 주소
        SENDR_DETAIL_ADDR, //보내는 분 상세주소
        RCVR_NM,
        RCVR_TEL_NO1,
        RCVR_TEL_NO2,
        RCVR_TEL_NO3,
        RCVR_CELL_NO1,
        RCVR_CELL_NO2,
        RCVR_CELL_NO3,
        RCVR_SAFE_NO1,
        RCVR_SAFE_NO2,
        RCVR_SAFE_NO3,
        RCVR_ZIP_NO,
        RCVR_ADDR,
        RCVR_DETAIL_ADDR,
        ORDRR_NM,
        ORDRR_TEL_NO1,
        ORDRR_TEL_NO2,
        ORDRR_TEL_NO3,
        ORDRR_CELL_NO1,
        ORDRR_CELL_NO2,
        ORDRR_CELL_NO3,
        ORDRR_SAFE_NO1,
        ORDRR_SAFE_NO2,
        ORDRR_SAFE_NO3,
        ORDRR_ZIP_NO,
        ORDRR_ADDR,
        ORDRR_DETAIL_ADDR,
        INVC_NO, //운송장 번호(RCPT_DV(접수구분) = '02' (반품,회수) 반품의 경우에는 사용 안함 (빈칸으로 전송)), VARCHAR2(20)
        ORI_INVC_NO, //원운송장번호(RCPT_DV(접수구분) = '02' (반품,회수)시 대상이되는 원 출고 운송장번호), VARCHAR2(20)
        ORI_ORD_NO, //원주문 번호, VARCHAR(100)
        PRT_ST, //운송장 출력상태(01: 미출력, 02: 선출력, 03: 선발번 (반품은 선발번이 없음) 반품 (RCPT_DV = '02') 진행 시,PRT_ST = '01' 기재), VARCHAR2(3), NOT NULL
        ARTICLE_AMT, //물품가 액, NUMBER(15)
        REMARK_1, //배송 메세지1 (비고)
        REMARK_2, //배송 메세지2 (보내는분비고)
        REMARK_3, //배송 메세지3 (받는분비고)
        COD_YN, //COD여부
        ETC_1,
        ETC_2,
        ETC_3,
        ETC_4,
        ETC_5,
        DLV_DV, //택배구분(‘01’: 택배), VARCHAR(2)
        RCPT_SERIAL, //접수시리얼 번호, VARCHAR(50)
        ARRAY: [
          {
            MPCK_SEQ,
            GDS_CD,
            GDS_NM,
            GDS_QTY,
            UNIT_CD,
            UNIT_NM,
            GDS_AMT,
          },
        ],
      }),
    headers: PARCEL_CJ_OPEN_API_COMMON_HEADER,
  },
};
