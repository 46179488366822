export default function (lo) {
  $.frame(
    {
      frame_name: 'df.folder_list_frame',
      page_name: 'df.folder_list_page',
    },
    {
      page_name: 'df.folder_list_page',
    },
  );
}
