$.frame.defn_frame({
  frame_name: 'mp.review.list',
  appended: $.on('click', function (e) {
    if (e.target == e.delegateTarget) $.frame.close();
  }),
  appending: (don_frame) => $.attr($.find1(don_frame, '.don_head_btn.don_close'), 'don_is_show', false),
});

$.frame.defn_frame({
  frame_name: 'mp.my_review',
  page_name: 'mp.my_review',
});
