import { CommonNS, EditorNS } from '@marpple/sticker-editor';
import { $findAll, $off, $on } from 'fxdom/es';
import {
  appendL,
  concatL,
  constant,
  defaultTo,
  each,
  equals2,
  findIndex,
  go,
  head,
  isNil,
  join,
  map,
  mapL,
  rangeL,
  takeAll,
  takeL,
  tap,
} from 'fxjs/es';
import { VectorEditorStickerSingleMobileCuttingLineAutoMuiS } from '../../S/Mui/module/VectorEditorStickerSingleMobileCuttingLineAutoMuiS.js';
import { VectorEditorStickerSingleMobileCuttingLineAutoEventF } from '../Event/module/VectorEditorStickerSingleMobileCuttingLineAutoEventF.js';
import { getOutlinePathsFromImgWithAdaptiveSimplify, makePathsBridged } from '@marpple/stickerizer';
import { makeCuttingLine, setCuttingLine } from '../Function/cutting_line.js';
import { VectorEditorFontF } from '../../../../../../Font/F/Function/module/VectorEditorFontF.js';

const preventDefault = (event) => event.preventDefault();

const START_OFFSET = 2;

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerSingleMobileCuttingLineAutoMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {
    tab_el$.__mp_single_sticker_editor = tab_el$.__mp_single_sticker_editor ?? null;
    tab_el$.__mp_postProcess = tab_el$.__mp_postProcess ?? null;
    tab_el$.__mp_target_image_el = null;
    tab_el$.__mp_factor = 1;
    tab_el$.__mp_base_cutting_line = null;
    tab_el$.__mp_offsets = [];
    tab_el$.__mp_cutting_lines = [];
    tab_el$.__mp_cutting_line_index = 0;
    tab_el$.__mp_cutting_line_editor = null;

    go(
      tab_el$,

      tap(
        $findAll(`.header .cancel`),
        each(
          $on('click', VectorEditorStickerSingleMobileCuttingLineAutoEventF.handleHeaderCancelButtonClick()),
        ),
      ),
      tap(
        $findAll(`.header .done`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineAutoEventF.handleHeaderDoneButtonClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),

      tap(
        $findAll('.footer .controls .decrease'),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineAutoEventF.handleFooterDecreaseButtonClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
      tap(
        $findAll('.footer .controls .increase'),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineAutoEventF.handleFooterIncreaseButtonClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),

      tap(
        $findAll(`.footer .edit_manual`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileCuttingLineAutoEventF.handleFooterEditManualButtonClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    $on('touchmove', preventDefault, { passive: false })(window);

    const cutting_line_editor = new EditorNS.CuttingLineEditorNS.MobileNS.CuttingLineMobileEditor({
      window,
      document,
      touch_margin: 6,
      min_zoom_scale: 1,
      max_zoom_scale: 3,
    });
    tab_el$.__mp_cutting_line_editor = cutting_line_editor;
    cutting_line_editor.initDOM();
    cutting_line_editor.initEditor();
    go(
      tab_el$,
      $findAll('.editor_container'),
      takeL(1),
      each((editor_container_el) => cutting_line_editor.appendTo(editor_container_el)),
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  async rendered(tab_el$) {
    $.don_loader_start();
    try {
      const { without_background_el: target_image_el } =
        await tab_el$.__mp_single_sticker_editor.exportEditor({
          factor: 1,
          is_art_board_clip_path_on: false,
          container_el: document.body,
        });
      const font_style_defs_el = /** @type {SVGDefsElement} */ await go(
        target_image_el,
        VectorEditorFontF.makeFontStyleEl({ is_encode_base64: true }),
        (style_el) => {
          const defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
          defs_el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE] = 'font-face-defs';
          defs_el.appendChild(style_el);
          return defs_el;
        },
      );
      tab_el$.__mp_target_image_el = target_image_el;
      const target_image_width = target_image_el.width.baseVal.value;
      const target_image_height = target_image_el.height.baseVal.value;
      tab_el$.__mp_cutting_line_editor.fitSize();
      const view_box_vo = tab_el$.__mp_cutting_line_editor.calculateViewBoxFitTo({
        size_in_ucs: {
          width: target_image_width,
          height: target_image_height,
        },
        padding_in_vcs: { top: 16 + 48, right: 16, bottom: 16 + 144, left: 16 },
      });
      tab_el$.__mp_cutting_line_editor.setViewBox(view_box_vo);

      await (async () => {
        const min_px = 1024;

        const sx = min_px / target_image_width;
        const sy = min_px / target_image_height;
        tab_el$.__mp_factor = Math.max(sx, sy);
        const { without_background_el: large_target_image_el } =
          await tab_el$.__mp_single_sticker_editor.exportEditor({
            factor: tab_el$.__mp_factor,
            is_art_board_clip_path_on: false,
            container_el: document.body,
          });
        large_target_image_el.prepend(await CommonNS.UtilNS.deepCloneNode(font_style_defs_el));
        const large_target_image_blob = new Blob(
          [new XMLSerializer().serializeToString(large_target_image_el)],
          {
            type: 'image/svg+xml',
          },
        );
        const large_target_image_svg_data_url = /** @type {string} */ await new Promise((resolve, reject) => {
          const file_reader = new FileReader();
          file_reader.onerror = reject;
          file_reader.onload = () => resolve(file_reader.result);
          file_reader.readAsDataURL(large_target_image_blob);
        });
        const large_target_image_img_el = /** @type {HTMLImageElement} */ await new Promise(
          (resolve, reject) => {
            const img_el = new Image();
            img_el.onload = () => resolve(img_el);
            img_el.onerror = reject;
            img_el.src = large_target_image_svg_data_url;
          },
        );
        await new Promise((resolve) => setTimeout(resolve, 600));
        const large_target_image_canvas_el = document.createElement('canvas');
        large_target_image_canvas_el.width = large_target_image_img_el.width;
        large_target_image_canvas_el.height = large_target_image_img_el.height;
        const large_target_image_canvas_ctx = large_target_image_canvas_el.getContext('2d');
        large_target_image_canvas_ctx.drawImage(
          large_target_image_img_el,
          0,
          0,
          large_target_image_img_el.width,
          large_target_image_img_el.height,
          0,
          0,
          large_target_image_canvas_el.width,
          large_target_image_canvas_el.height,
        );
        const large_target_image_png_blob = /** @type {Blob} */ await new Promise((resolve, reject) =>
          large_target_image_canvas_el.toBlob((blob) => {
            if (isNil(blob)) {
              const error = new Error(`Cannot make a PNG image.`);
              error.__mp_alert_message = 'PNG 이미지를 해석할 수 없습니댜.';
              reject(error);
              return;
            }
            resolve(blob);
          }, 'image/png'),
        );
        const cutting_line_target_image_png_data_url = await new Promise((resolve, reject) => {
          const file_reader = new FileReader();
          file_reader.addEventListener('load', () => resolve(file_reader.result));
          file_reader.addEventListener('error', reject);
          file_reader.readAsDataURL(large_target_image_png_blob);
        });

        const fit_cutting_line = await getOutlinePathsFromImgWithAdaptiveSimplify(
          cutting_line_target_image_png_data_url,
        );
        tab_el$.__mp_base_cutting_line = await makePathsBridged(fit_cutting_line.paper_obj);
        tab_el$.__mp_offsets = takeAll(concatL(rangeL(-2, 0, 0.5))(rangeL(0, 5, 1)));
        tab_el$.__mp_cutting_lines = map(constant(null))(tab_el$.__mp_offsets);
      })();

      await (async () => {
        const index =
          findIndex(equals2(START_OFFSET), tab_el$.__mp_offsets) ?? tab_el$.__mp_offsets.length - 1;
        const offset = tab_el$.__mp_offsets[index];
        const label = `${offset}`;
        const path_data = await makeCuttingLine({ tab_el: tab_el$, offset }).catch(async (error) => {
          console.error(error);
          await $.alert(T('modules::VectorEditor::Sticker::message::cutting_line_making_error2'));

          const { innerWidth: window_width, innerHeight: window_height } = window;
          const header_height = go(
            tab_el$,
            $findAll(`.header`),
            mapL((el) => el.getBoundingClientRect().height),
            head,
            defaultTo(0),
          );
          const footer_height = go(
            tab_el$,
            $findAll(`.footer`),
            mapL((el) => el.getBoundingClientRect().height),
            head,
            defaultTo(0),
          );

          const width = Math.round(window_width / 3);
          const height = Math.round((window_height - header_height - footer_height) / 3);
          const x = (window_width - width) / 2;
          const y = header_height + (window_height - header_height - footer_height - height) / 2;
          return go(
            [
              ['M', x, y],
              ['L', x + width, y],
              ['L', x + width, y + height],
              ['L', x, y + height],
            ],
            mapL(([command, x, y]) => {
              const view_port_point = tab_el$.__mp_cutting_line_editor.toViewPortPoint({ x, y });
              const user_point = tab_el$.__mp_cutting_line_editor.toUserPoint({
                x: view_port_point.x,
                y: view_port_point.y,
              });
              return `${command} ${user_point.x} ${user_point.y}`;
            }),
            appendL('Z'),
            join(' '),
          );
        });
        const clone_target_image_el = await CommonNS.UtilNS.deepCloneNode(target_image_el);
        tab_el$.__mp_cutting_line_editor?.disconnectCuttingLineClipPath?.();
        setCuttingLine({ tab_el: tab_el$, path_data, label });
        tab_el$.__mp_cutting_line_editor.setTargetImageEl(clone_target_image_el);
        tab_el$.__mp_cutting_lines[index] = { index, label, path_data };
        tab_el$.__mp_cutting_line_index = index;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        tab_el$.__mp_cutting_line_editor?.connectCuttingLineClipPath?.();
      })();
    } finally {
      $.don_loader_end();
    }
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {
    $off('touchmove', preventDefault, { passive: false })(window);

    tab_el$.__mp_single_sticker_editor = null;
    tab_el$.__mp_postProcess = null;
    tab_el$.__mp_target_image_el = null;
    tab_el$.__mp_factor = 1;
    tab_el$.__mp_base_cutting_line = null;
    tab_el$.__mp_offsets = [];
    tab_el$.__mp_cutting_lines = [];
    tab_el$.__mp_cutting_line_index = 0;
    tab_el$.__mp_cutting_line_editor?.destroy?.();
    tab_el$.__mp_cutting_line_editor = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
