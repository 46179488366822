import rfdc from 'rfdc';

import {
  BRUSH_PROPERTY_NAME,
  TOOL_NAME,
  SUB_TOOL_NAME,
  STROKE_PROPERTY_NAME,
  ERASE_PROPERTY_NAME,
  SPOT_PROPERTY_NAME,
  SCALE_PROPERTY_NAME,
  R_STROKE_PROPERTY_NAME,
  ALPHA_THRESHOLD,
  BACKGROUND_PROPERTY_NAME,
} from '../../S/Constant/constants.js';

const INITIAL_VIEW_CONTROL_MODE = {
  zoom: false, // key(alt) + mouse(wheel)
  pan: false, // key(space) + mouse(drag)
  fit: false, // key(alt + space)
};

export const view_control = {
  modes: { ...INITIAL_VIEW_CONTROL_MODE },
  initialize: () => {
    view_control.modes = { ...INITIAL_VIEW_CONTROL_MODE };
  },
  isControlling: () => Object.values(view_control.modes).some((mode) => mode === true),
};

const deepClone = rfdc();

export const tools = {
  [TOOL_NAME.selection]: {
    name: TOOL_NAME.selection,
    className: TOOL_NAME.selection,
    displayName: '-',
  },
  [TOOL_NAME.background]: {
    name: TOOL_NAME.background,
    className: TOOL_NAME.background,
    displayName: '배경색',
    subTool: null,
    properties: {
      [BACKGROUND_PROPERTY_NAME.color]: {
        propertyName: [BACKGROUND_PROPERTY_NAME.color],
        displayName: '색상',
        value: null,
      },
    },
  },
  [TOOL_NAME.erase]: {
    name: TOOL_NAME.erase,
    className: TOOL_NAME.erase,
    displayName: '지우개',
    subTool: SUB_TOOL_NAME.erase.brush,
    properties: {
      [ERASE_PROPERTY_NAME.brush]: {
        propertyName: [ERASE_PROPERTY_NAME.brush],
        item: null,
        tool: null,
        className: TOOL_NAME.erase,
        [BRUSH_PROPERTY_NAME.size]: {
          brush_name: BRUSH_PROPERTY_NAME.size,
          displayName: '크기',
          slider: null,
          indicator: null,
          value: null,
          start: null,
          max: 1000,
          min: 1,
          step: 1,
        },
        [BRUSH_PROPERTY_NAME.hardness]: {
          brush_name: BRUSH_PROPERTY_NAME.hardness,
          displayName: '경도',
          slider: null,
          indicator: null,
          gradientColor: null,
          value: 100,
          start: 100,
          max: 100,
          min: 1,
          step: 1,
        },
      },
    },
  },
  [TOOL_NAME.scale]: {
    name: TOOL_NAME.scale,
    className: TOOL_NAME.scale,
    displayName: '스케일',
    subTool: null,
    properties: {
      [SCALE_PROPERTY_NAME.size]: {
        propertyName: [SCALE_PROPERTY_NAME.size],
        displayName: '가로 x 세로',
        value: { width: null, height: null },
      },
    },
  },
  [TOOL_NAME.spot]: {
    name: TOOL_NAME.spot,
    className: TOOL_NAME.spot,
    displayName: '잡티',
    subTool: null,
    properties: {
      [SPOT_PROPERTY_NAME.area]: {
        propertyName: [SPOT_PROPERTY_NAME.area],
        displayName: '선택 면적 범위',
        value: null,
      },
    },
  },
  [TOOL_NAME.rStroke]: {
    name: TOOL_NAME.rStroke,
    className: TOOL_NAME.rStroke,
    displayName: '윤곽선',
    subTool: null,
    properties: {
      [R_STROKE_PROPERTY_NAME.color]: {
        propertyName: [R_STROKE_PROPERTY_NAME.color],
        displayName: '색상',
        value: null,
      },
      [R_STROKE_PROPERTY_NAME.thickness]: {
        propertyName: [R_STROKE_PROPERTY_NAME.thickness],
        displayName: '두께',
        value: null,
        min: 1,
        max: 50,
        step: 1,
      },
      [R_STROKE_PROPERTY_NAME.alpha_threshold]: {
        propertyName: [R_STROKE_PROPERTY_NAME.alpha_threshold],
        displayName: '경계 보드라미',
        value: ALPHA_THRESHOLD.rStroke,
        start: ALPHA_THRESHOLD.rStroke,
        min: 1,
        max: 100,
        step: 1,
      },
    },
  },
  [TOOL_NAME.vStroke]: {
    name: TOOL_NAME.vStroke,
    className: TOOL_NAME.vStroke,
    displayName: '칼선',
    subTool: null,
    boundary: { bounds: /* id */ null, strokes: { top: null, bottom: null, right: null, left: null } },
    properties: {
      [STROKE_PROPERTY_NAME.color]: {
        propertyName: [STROKE_PROPERTY_NAME.color],
        displayName: '색상',
        value: '#FF1493',
      },
      [STROKE_PROPERTY_NAME.width]: {
        propertyName: [STROKE_PROPERTY_NAME.width],
        displayName: '두께',
        value: null,
        min: 1,
        max: 1000,
        step: 1,
      },
      [STROKE_PROPERTY_NAME.offset]: {
        propertyName: [STROKE_PROPERTY_NAME.offset],
        displayName: '오프셋',
        value: 0,
        start: 0,
        min: -500,
        max: 500,
        step: 1,
      },
    },
  },
  [TOOL_NAME.file]: {
    className: TOOL_NAME.file,
    displayName: '시안 등록',
    properties: {
      pressType: {
        displayName: '인쇄방식',
        className: 'press-type',
        value: null,
      },
    },
  },
};

const initialState = {
  printable_file_info: null,
  selectedTool: TOOL_NAME.selection,
  tool: tools,
};

export const TOOL_NAMES = Object.keys(tools);

export let imageEditorStatus = deepClone(initialState);

export const initializeImageEditorStatus = ({ printable_file_info }) => {
  imageEditorStatus = deepClone(initialState);
  // tab option 을 통해 들어온 프린트 파일 정보 인자를 상태 관리 변수에 등록
  imageEditorStatus.printable_file_info = printable_file_info;
};
