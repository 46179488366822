import { NewMakerPropertyBaseProductConstantS } from '../Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { html } from 'fxjs/es';
import { NewMakerFramesMobilePdfUploaderTmplS } from '../../../../Frames/MobilePdfUploader/S/Tmpl/module/NewMakerFramesMobilePdfUploaderTmplS.js';

export const pdfFilePossible = {
  isIt: (base_product) => {
    return !!base_product.maker_features?.[NewMakerPropertyBaseProductConstantS.PDF_FILE_POSSIBLE];
  },
  getFileType: () => {
    return {
      mimetype: 'application/pdf',
      file: 'PDF',
      file_descriptions: [
        T('maker_upload_type::템플릿은 일러스트레이터 CS6 이상에서만 사용 가능합니다.'),
        T('maker_upload_type::첨부 파일 형식 : PDF (100MB 이하)'),
        T('maker_upload_type::템플릿 파일의 가이드를 준수하여 디자인 작업해 주세요.'),
      ],
      /* class_name maker-upload-type-selector__is-not-ready-editor */
    };
  },
  getPrintingFile: (product) => {
    if (!product._?.pdf_printing_file?.id) return;
    return product._.pdf_printing_file;
  },
  makeResultTmpl: ({ pdf_printing_file, printing_template }) => {
    return html`
      <div class="upload-type-direct-result">
        <div class="upload-type-direct-result__text">
          ${NewMakerFramesMobilePdfUploaderTmplS.pdfFileDesc({
            pdf_printing_file,
            template: printing_template,
          })}
        </div>
        <div class="upload-type-direct-result__image">
          <img src="${pdf_printing_file.thumbnail_urls[0]}" alt="" />
        </div>
        <div class="upload-type-direct-result__footer">${pdf_printing_file.file_name}</div>
      </div>
    `;
  },
  getOriginalFileName: (product_color) => {
    return product_color._.pdf_printing_file?.file_name;
  },
};
