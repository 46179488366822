import { $hasClass, $on, $qs, $qsa, $setAttr, $setText } from 'fxdom/es';
import { curry, go, each } from 'fxjs/es';
import { ComponentsInputF } from '../../../../../Components/Input/F/Function/module/ComponentsInputF.js';
import { OMPCoreUtilS } from '../../../Util/S/Function/module/OMPCoreUtilS.js';

/**
 * @param {T<HTMLInputElement | HTMLElement>} el
 * @return {T}
 */
export const delegateInput = function (el) {
  const klass = 'omp-atom__input';
  const $input_els = $hasClass(klass, el) ? [el] : $qsa(`.${klass}`, el);

  go(
    $input_els,
    each(($input) => {
      let timer;
      const debounce_time = OMPCoreUtilS.safeParseInt($input.dataset.debounce_time);

      $on('input', (e) => {
        const inputValue = ComponentsInputF.getSafeInputValue(e.target);
        $setAttr({ value: inputValue })(e.target);

        if ($input.last_value === inputValue) {
          return;
        }

        const debounceInputEvent = new CustomEvent('debounce-input', {
          bubbles: true,
          cancelable: true,
          detail: {
            inputValue,
          },
        });
        if (debounce_time <= 0) return $input.dispatchEvent(debounceInputEvent);
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
          $input.last_value = $input.value;
          $input.dispatchEvent(debounceInputEvent);
        }, debounce_time);
      })($input);
    }),
  );
  return el;
};

export const deactivateWarning = (name) => {
  const warning$ = $qs(`.omp-atom__warning-input[data-name="${name}"]`);
  if (warning$) {
    warning$.dataset.visible = 'false';
    $setText('', warning$);
  }
};

export const activateWarning = curry((text, name) => {
  const warning$ = $qs(`.omp-atom__warning-input[data-name="${name}"]`);
  if (warning$) {
    warning$.dataset.visible = 'true';
    $setText(text, warning$);
  }
});
