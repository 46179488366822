import { html } from 'fxjs/es';

const mui_ref_path = '/modules/NewMaker/Preview/Modal/S/Mui/tab.js';
/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  title: '', // tab button의 title
  selected: false, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  makeData(tab) {}, // template 함수 인자에 들어갈 값, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
  template: ({ data_url }) => html`
    <div class="simple_modal">
      <div class="simple_modal_blank"></div>
      <div class="simple_modal_wrapper">
        <div class="header">${T('masking_tape_modal_page::title')}</div>
        <div class="body">
          <div class="preview-wrapper">
            <div class="preview"><img src="${data_url}" alt="" /></div>
          </div>
          <div class="description-wrapper">
            <div class="description">
              <div>${T('masking_tape_modal_page::description1')}</div>
              <div>${T('masking_tape_modal_page::description2')}</div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button type="button" class="cancel">${T('masking_tape_modal_page::cancel')}</button>
          <button type="button" class="done">${T('masking_tape_modal_page::done')}</button>
        </div>
      </div>
    </div>
  `, // 탭에 들어갈 html 을 그리는 함수
};
