import { go, map, find, uniqBy, each, compact, flatMap } from 'fxjs/es';
import { BpOptionConstantS } from '../../../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { OMPDosuF } from '../../../../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { getRealFcanvass } from '../../../../../Maker/F/getSth.js';
import { getFcanvasFlattenCvObjs } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { rerenderCvPreview } from '../../../../../Maker/F/CvPreview/cv_object.js';
import { OMPDosuConstantS } from '../../../../../OMP/Dosu/S/Constant/module/OMPDosuConstantS.js';

export const allFacesSingleColor = {
  setAllFacesSingleColor: (product_faces2) => {
    /* selected_bp_option */
    product_faces2.all_faces_single_color = go(
      product_faces2.value,
      map(({ selected_bp_options }) => {
        return go(
          selected_bp_options,
          find(
            ({ bp_option_group_dev_name }) =>
              bp_option_group_dev_name === BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COLORS,
          ),
        );
      }),
      compact,
      uniqBy(({ id }) => id),
      (arr) => {
        if (!arr?.length) return;
        if (arr?.length > 1) {
          $.alert('이 상품에는 2가지 이상의 색상이 디자인 될 수 없습니다.');
        }
        return arr[0];
      },
    );
  },
  getCurrentColorCode: () => {
    return go(getRealFcanvass(), flatMap(getFcanvasFlattenCvObjs), (cv_objs) =>
      cv_objs?.length ? cv_objs[0]._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR] : null,
    );
  },
  applyAllFacesSingleColor: async (apply_color) => {
    await go(
      getRealFcanvass(),
      each(async (fcanvas) => {
        await OMPDosuF.cvObj.update.dosuColorsToAllCvObjs({
          cv_objs: getFcanvasFlattenCvObjs(fcanvas),
          color_code: apply_color,
        });
        fcanvas.renderAll();
        if (fcanvas?.preview?.on) {
          await rerenderCvPreview(fcanvas);
        }
      }),
    );
  },
};
