import axios from 'axios';
import { each, find, flatMap, go, mapC, pluck, sel, unique } from 'fxjs/es';
import { makeOnlyDesignFaceCanvas } from '../../../../Maker/F/draw_product_faces.js';
import { makeCanvasByUrl } from '../../../../Canvas/S/util.js';
import { render_canvas } from '../../../../Composite/Core/S/composite_template_canvas.js';
import { NewMakerPropertyBpfConstantS } from '../../../Property/Bpf/S/Constant/module/NewMakerPropertyBpfConstantS.js';
import { makeCylinderDesignCanvas, makeMockupSize } from '../../../../Composite/Core/F/mockup_fns.js';
import { UtilFileF } from '../../../../Util/File/F/Function/module/UtilFileF.js';

export const makeCompositePreview = async ({ assoc_composite_template, product }) => {
  const size = await makeMockupSize([assoc_composite_template]);
  const cylinder_width_cm =
    box().maker.product_color._.base_product_color._.base_product_color_faces[0]._.base_product_face
      .size_faces[0].print?.etc_meta?.cylinder_width_cm;
  const width_cm =
    box().maker.product_color._.base_product_color._.base_product_color_faces[0]._.base_product_face
      .size_faces[0].print?.cm?.width;
  if (cylinder_width_cm && width_cm) {
    size.width = size.width * (width_cm / cylinder_width_cm) * 2;
  }
  const is_cylinder =
    assoc_composite_template._.composite_masks[0]._.composite_faces[0]?.etc_meta?.is_cylinder;
  const print_area_canvass = await go(
    assoc_composite_template._.composite_masks,
    flatMap((cm) => cm._.composite_faces),
    pluck('base_product_face_id'),
    unique,
    mapC(async (base_product_face_id) => {
      const pf = go(
        product.product_faces2.value,
        find((pf) => pf.bpf_id === base_product_face_id),
      );
      return {
        base_product_face_id,
        canvas:
          pf?.cv_preview?._data?.shade_material?.name === NewMakerPropertyBpfConstantS.FOIL_EFFECTS
            ? await makeCanvasByUrl(pf.cv_preview.src)
            : await makeOnlyDesignFaceCanvas(
                go(
                  product.product_faces2.value,
                  find((pf) => pf.bpf_id === base_product_face_id),
                ),
                product.base_product_size_id,
                size,
              ),
      };
    }),
    mapC(async ({ canvas, base_product_face_id }) => {
      if (is_cylinder)
        return {
          url: canvas.toDataURL(),
          base_product_face_id,
        };
      const blob = await new Promise((resolve, reject) => {
        canvas.toBlob(resolve);
      });
      const file = new File([blob], 'temp_file.' + blob.type.replace(/image\//, ''), {
        type: blob.type,
        lastModified: new Date(),
      });
      const response = await axios
        .postForm(`/${T.lang}/@api/prerequisite_maker/temp_printable_image_files`, {
          files: [file],
        })
        .catch((error) => {
          $.alert(error?.response?.data?.message || '문제가 발생 했습니다.');
        });
      if (!response) return;
      return {
        base_product_face_id,
        url: response.data.files[0].url,
      };
    }),
  );

  if (!print_area_canvass?.length) return $.alert('문제가 발생 했습니다.');

  await go(
    assoc_composite_template._.composite_masks,
    each((composite_mask) =>
      go(
        composite_mask._.composite_faces,
        each(async (cf) => {
          cf.mockup_url = go(
            print_area_canvass,
            find((pf) => pf.base_product_face_id === cf.base_product_face_id),
            sel('url'),
          );
        }),
      ),
    ),
  );

  if (is_cylinder) {
    const result_canvas = await makeCylinderDesignCanvas(assoc_composite_template);
    assoc_composite_template._.composite_result.design_ready_url = result_canvas.toDataURL();
  } else {
    assoc_composite_template._.composite_result.design_ready_url = await go(
      axios.post(
        `/${T.lang}/@fileUpload/composite/create_design_ready_url_buffer`,
        {
          assoc_composite_template,
        },
        { responseType: 'arraybuffer' },
      ),
      sel('data'),
      (buffer) => new Uint8Array(buffer),
      function (buffer) {
        const blob = new Blob([buffer], { type: 'image/png' });
        return UtilFileF.blobToDataURL(blob);
      },
    );
  }

  const canvas_el = document.createElement('canvas');
  const design_ready_canvas =
    assoc_composite_template._.composite_result.design_ready_url &&
    (await makeCanvasByUrl(assoc_composite_template._.composite_result.design_ready_url));
  canvas_el.width = 900;
  await render_canvas({
    assoc_composite_template,
    selected_base_product_colors: [
      {
        base_product_id: product.base_product_id,
        id: product.base_product_color_id,
      },
    ],
    design_ready_canvas,
    canvas_el,
  });
  return canvas_el;
};
