/**
 * Return of `extractKeywordAndSurroundingsInText`;
 * @typedef {object} ExtractedKeywordAndSurroundings
 * @property {boolean} is_matched 탐색 성공 여부
 * @property {string|null} prefix
 * @property {string|null} extracted
 * @property {string|null} suffix
 */

import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

/**
 * @param {!string} keyword `prefix`, `extracted`, `suffix`를 찾고자 하는 단어
 * @param {!string} text 탐색할 텍스트
 * @throws {Error} `keyword`가 유효하지 않을 때
 * @return {ExtractedKeywordAndSurroundings}
 *
 * @example
 *  extractKeywordAndSurroundingsInText('어페럴', '아메리칸 어페럴 반바지')
 *  { prefix: '아메리칸 ', extracted: '어페럴', suffix: ' 반바지' }
 */
export const extractKeywordAndSurroundingsInText = (keyword, text) => {
  const regex = new RegExp(`(.*)(${UtilS.escapeRegExp(keyword)})(.*)`);

  const match = text.match(regex);

  if (!match) {
    return {
      prefix: null,
      extracted: null,
      suffix: null,
      is_matched: false,
    };
  }

  const [, prefix, extracted, suffix] = match;
  return {
    prefix,
    extracted,
    suffix,
    is_matched: true,
  };
};
