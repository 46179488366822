import { go, html, indexBy, map, zipWithIndexL } from 'fxjs/es';
import { OUTBOUND_PRODUCT_BY_PROJECTION_TABLE_COLUMN } from '../../../S/Constant/lgl.js';
import { getProductInfoByUpc } from '../../../ShippingDetail/F/Function/inbound.js';
import { makeTableHtml } from '../../../ShippingDetail/S/Tmpl/tmpl.table.js';
import { makeTabsWithTablesHtml } from '../../../ShippingDetail/S/Tmpl/tmpl.tabs.js';
import { LOC_OPT_ACTIVATE_ATTR_NAME } from '../../../../../Maker/S/constant.js';

export const makeOutboundProductListHtml = ({ data, base = 'waybill' }) => {
  const order_count = 3;
  const order_id = 'MP1231';
  // LGL-BUWHAL
  const test_data = [
    {
      id: 228,
      created_at: '2024-01-15T01:19:46.429Z',
      updated_at: '2024-01-15T02:41:33.714Z',
      projection_id: 2034484,
      lgl_inbound_trans_code_id: 'CJ',
      waybill_no_domestic: '540105148330',
      current_status: 'ARRIVED',
      lgl_inbound_order_group_id: 106,
      _: {
        projection: {
          status: 'before_deliver',
          projection_id: 2034484,
          is_cancel_req: false,
          is_canceled: false,
          is_hidden: false,
          id: 2034484,
        },
        current_status: {
          id: 'ARRIVED',
          is_cancelable: false,
          is_returnable: false,
          is_outboundable: false,
        },
        lgl_items: [
          {
            id: 515,
            created_at: '2024-01-12T06:20:54.317Z',
            updated_at: '2024-01-15T01:19:46.429Z',
            lgl_product_id: 387,
            user_product_id: 12493989,
            projection_id: 2034484,
            combined_projection_id: 2034484,
            lgl_inbound_order_id: 228,
            lgl_outbound_order_id: null,
            lgl_return_order_id: null,
            lgl_inbound_order_group_id: 106,
            lgl_return_order_group_id: null,
            lgl_outbound_order_group_id: null,
            _: {
              lgl_item_quantities: {
                id: 416,
                lgl_item_id: 515,
                inbound_order_requested_quantity: 20,
                inbound_order_confirmed_quantity: null,
                return_order_requested_quantity: null,
                return_order_confirmed_quantity: null,
                outbound_order_requested_quantity: null,
                outbound_order_confirmed_quantity: null,
              },
              lgl_product: {
                id: 387,
                created_at: '2024-01-09T06:23:19.766Z',
                updated_at: null,
                sku_id: 315,
                lgl_seller_id: 1,
              },
              up_c_ss: {
                id: 12493989,
                type: 'projection',
                is_selected: true,
                quantity: 20,
                user_id: 1773756,
                product_color_id: 15787645,
                created_at: '2023-12-13T10:59:41.964Z',
                updated_at: '2023-12-13T11:01:02.355Z',
                user_product_id: null,
                projection_id: 2034484,
                base_product_id: 4254,
                base_product_color_id: 37546,
                base_product_size_id: 8827,
                buyer_id: null,
                shipping_id: 2456726,
                printable_product_id: null,
                up_id: 12493989,
                up_c_id: 12493989,
                bp_price: 1800,
                print_price: 0,
                price: 1800,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2023-12-13T10:59:41.964Z',
                up_c_cart_at: '2023-12-13T10:59:41.964Z',
                discounted_price: 1800,
                bp_price_en: '1.640000',
                print_price_en: '0.000000',
                price_en: '1.640000',
                discounted_price_en: '1.640000',
                memo: null,
                is_review: false,
                review_id: null,
                _name: '5.8 cm',
                _name_en: '2.3 inch',
                _is_not_stock: false,
                _bp_stock_id: 30429,
                _no: 0,
                oem_price: 0,
                collabo_type: '',
                bp_price_jp: 176,
                print_price_jp: 0,
                price_jp: 176,
                discounted_price_jp: 176,
                _name_jp: '5.8 cm',
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                spo_item_id: null,
                original_quantity: 20,
                is_pb: false,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                goods_type_id: null,
                _is_discontinued: false,
                _: {
                  base_product_size: {
                    name: '5.8 cm',
                    id: 8827,
                  },
                  base_product_color: {
                    name: '연핑크',
                    id: 37546,
                  },
                },
              },
              up_c: {
                id: 12493989,
                type: 'projection',
                is_selected: true,
                quantity: 20,
                user_id: 1773756,
                product_color_id: 15787645,
                created_at: '2023-12-13T10:59:41.964Z',
                updated_at: '2023-12-13T11:01:02.355Z',
                user_product_id: null,
                projection_id: 2034484,
                base_product_id: 4254,
                base_product_color_id: 37546,
                base_product_size_id: 8827,
                buyer_id: null,
                shipping_id: 2456726,
                printable_product_id: null,
                up_id: 12493989,
                up_c_id: 12493989,
                bp_price: 1800,
                print_price: 0,
                price: 1800,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2023-12-13T10:59:41.964Z',
                up_c_cart_at: '2023-12-13T10:59:41.964Z',
                discounted_price: 1800,
                bp_price_en: '1.640000',
                print_price_en: '0.000000',
                price_en: '1.640000',
                discounted_price_en: '1.640000',
                memo: null,
                is_review: false,
                review_id: null,
                o_id: null,
                o_pp_id: null,
                o_pcp_id: null,
                o_user_id: null,
                o_projection_id: null,
                o_base_product_size_id: null,
                o_colored_preview_id: null,
                o_copied_colored_preview_id: null,
                up_main: null,
                upc_main: null,
                collabo_type: '',
                oem_price: 0,
                bp_price_jp: 176,
                print_price_jp: 0,
                price_jp: 176,
                discounted_price_jp: 176,
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                original_quantity: 20,
                is_pb: false,
                spo_item_id: null,
                goods_type_id: null,
                partial_delivery_at: null,
                _: {
                  printable_product: {},
                  base_product: {
                    name: '핀버튼',
                    id: 4254,
                  },
                  product_color: {
                    name: null,
                    product_faces2: {
                      value: [
                        {
                          debug: {},
                          bpf_id: 7998,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c12',
                            src: '//s3.marpple.co/files/u_1150555/2020/5/original/f_2447215_1589771631940_uO5au4bT5nbXC9s5TxTu.png',
                            top: 223,
                            fill: 'rgb(0,0,0)',
                            left: 223,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_bpcf',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 414,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 414,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c22',
                              src: '//s3.marpple.co/files/guest/2022/10/600/e3a56cc9789c979e4a39445ba681a2b8b53aaed51.png',
                              top: 310.2969900240797,
                              fill: 'rgb(0,0,0)',
                              left: 324.75652920396357,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9670625,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 2.9,
                                height_cm: 2.6,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/10/original/e3a56cc9789c979e4a39445ba681a2b8b53aaed51.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 1197,
                                image_height: 1073,
                                real_width_cm: 2.9,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 2.6,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                is_low_quality_agree: false,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.999841,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 537.8444689999999,
                              scaleX: 0.35247832939354545,
                              scaleY: 0.35247832939354545,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                            {
                              cid: 'c24',
                              src: '//s3.marpple.co/files/guest/2022/10/600/42759cea9bfe050ec6ffd2ab3062e6c7294ff97e1.png',
                              top: 371.39411764705596,
                              fill: 'rgb(0,0,0)',
                              left: 322.3139290407359,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9670631,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 3,
                                height_cm: 3.8,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/10/original/42759cea9bfe050ec6ffd2ab3062e6c7294ff97e1.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 4096,
                                image_height: 5327,
                                real_width_cm: 3,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 3.8,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.998464,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 780.320268,
                              scaleX: 0.36062115972104924,
                              scaleY: 0.36062115972104924,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c13',
                            src: '//s3.marpple.co/files/u_1150555/2020/4/original/f_2143358_1587713656921_oh4X4HXJTK3VC8RsS3h6z.png',
                            top: 223,
                            fill: 'rgb(0,0,0)',
                            left: 223,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask1',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 414,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 414,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c14',
                            src: '//s3.marpple.co/files/u_1686012/2023/5/original/8cf15e71af59646e9798724bac26471674a45b711.png',
                            top: 223,
                            fill: 'rgb(0,0,0)',
                            left: 223,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask2',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 414,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 414,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '앞면',
                          size_info: {
                            top: 254.86046511627904,
                            left: 117.90697674418604,
                            right: 117.90697674418604,
                            width: 154.1860465116279,
                            bottom: 254.86046511627904,
                            height: 154.1860465116279,
                            x_diff: 0,
                            y_diff: 0,
                          },
                          cv_shading: {
                            cid: 'c15',
                            src: '//s3.marpple.co/files/u_1150555/2020/12/original/69acd4854824a0da3d96e79f26f058b424bbc0c01.png',
                            top: 223,
                            fill: 'rgb(0,0,0)',
                            left: 223,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_shading',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 414,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 414,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              dpi: 300,
                              img: {
                                diff: 0,
                                width: 414,
                                height: 414,
                              },
                              line: {
                                top: 7,
                                left: 7,
                                width: 400,
                                height: 400,
                              },
                              real: {
                                cm: {
                                  width: 6.2,
                                  height: 6.2,
                                },
                                px: {
                                  top: 260,
                                  left: 260,
                                  width: 341,
                                  height: 341,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              items: [
                                {
                                  cm: {
                                    width: 9.2371,
                                    height: 9.2287,
                                  },
                                  px: {
                                    top: 165,
                                    left: 166,
                                    width: 530,
                                    height: 530,
                                  },
                                  is_90_rotate: false,
                                  is_180_rotate: false,
                                  is_270_rotate: false,
                                  print_item_url:
                                    '//s3.marpple.co/files/u_193535/2023/5/original/4a90f2b451a62fb39175bc7ab57346fb2b8c91081.png',
                                },
                              ],
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 5.6206,
                                  height: 5.6206,
                                },
                                px: {
                                  rx: 360,
                                  ry: 360,
                                  top: 225,
                                  left: 225,
                                  width: 412,
                                  height: 412,
                                },
                                img: {
                                  url: null,
                                },
                                etc_meta: {},
                                result_px: {
                                  width: 0,
                                  height: 0,
                                },
                              },
                              mockup_url: null,
                              px_per_1cm: 73.30178,
                              is_standard: false,
                              end_point_cm: 0,
                              is_90_rotate: false,
                              start_point_cm: 0,
                              has_cutting_line: false,
                              legacy_print_area: {
                                rx: 200,
                                ry: 200,
                                top: 231,
                                left: 231,
                                width: 399,
                                height: 400,
                                temp_width: 399,
                                ratio_width: 5.6206,
                                temp_height: 399,
                                ratio_height: 5.6206,
                              },
                              base_product_size_id: 8827,
                              is_print_area_legacy: true,
                            },
                          ],
                          start_name: '',
                          collabo_type: '',
                          face_name_en: 'Front',
                          face_name_jp: '前面',
                          cv_print_area: {
                            rx: 360,
                            ry: 360,
                            cid: 'c17',
                            top: 223,
                            fill: 'rgba(0,0,0,0)',
                            left: 223,
                            type: 'rect',
                            _data: {
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 360,
                                ry: 360,
                                top: 225,
                                left: 225,
                                width: 412,
                                height: 412,
                              },
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 414,
                            clipTo: null,
                            height: 414,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#2f2f2f',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: [6, 3],
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: false,
                          bpf_mask2_padding_top: 0,
                          [LOC_OPT_ACTIVATE_ATTR_NAME]: false,
                        },
                      ],
                      can_not_change_color: false,
                    },
                    thumbnails: null,
                    id: 15787645,
                  },
                },
              },
            },
          },
        ],
      },
    },
    {
      id: 229,
      created_at: '2024-01-15T01:19:46.429Z',
      updated_at: '2024-01-16T02:48:17.588Z',
      projection_id: 2034484,
      lgl_inbound_trans_code_id: 'CJ',
      waybill_no_domestic: '530106100304',
      current_status: 'RECEIVED',
      lgl_inbound_order_group_id: 106,
      _: {
        projection: {
          status: 'before_deliver',
          projection_id: 2034484,
          is_cancel_req: false,
          is_canceled: false,
          is_hidden: false,
          id: 2034484,
        },
        current_status: {
          id: 'RECEIVED',
          is_cancelable: false,
          is_returnable: true,
          is_outboundable: false,
        },
        lgl_items: [
          {
            id: 516,
            created_at: '2024-01-12T06:21:06.991Z',
            updated_at: '2024-01-15T01:19:46.429Z',
            lgl_product_id: 388,
            user_product_id: 12493990,
            projection_id: 2034484,
            combined_projection_id: 2034484,
            lgl_inbound_order_id: 229,
            lgl_outbound_order_id: null,
            lgl_return_order_id: null,
            lgl_inbound_order_group_id: 106,
            lgl_return_order_group_id: null,
            lgl_outbound_order_group_id: null,
            _: {
              lgl_item_quantities: {
                id: 417,
                lgl_item_id: 516,
                inbound_order_requested_quantity: 20,
                inbound_order_confirmed_quantity: null,
                return_order_requested_quantity: null,
                return_order_confirmed_quantity: null,
                outbound_order_requested_quantity: null,
                outbound_order_confirmed_quantity: null,
              },
              lgl_product: {
                id: 388,
                created_at: '2024-01-09T06:24:11.790Z',
                updated_at: null,
                sku_id: 312,
                lgl_seller_id: 1,
              },
              up_c_ss: {
                id: 12493990,
                type: 'projection',
                is_selected: true,
                quantity: 20,
                user_id: 1773756,
                product_color_id: 15787646,
                created_at: '2023-12-13T11:00:02.134Z',
                updated_at: '2024-01-12T02:26:32.580Z',
                user_product_id: null,
                projection_id: 2034484,
                base_product_id: 4254,
                base_product_color_id: 37535,
                base_product_size_id: 8827,
                buyer_id: null,
                shipping_id: 2456726,
                printable_product_id: null,
                up_id: 12493990,
                up_c_id: 12493990,
                bp_price: 1800,
                print_price: 0,
                price: 1800,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2023-12-13T11:00:02.134Z',
                up_c_cart_at: '2023-12-13T11:00:02.134Z',
                discounted_price: 1800,
                bp_price_en: '1.640000',
                print_price_en: '0.000000',
                price_en: '1.640000',
                discounted_price_en: '1.640000',
                memo: null,
                is_review: false,
                review_id: null,
                _name: '5.8 cm',
                _name_en: '2.3 inch',
                _is_not_stock: false,
                _bp_stock_id: 30418,
                _no: 0,
                oem_price: 0,
                collabo_type: '',
                bp_price_jp: 176,
                print_price_jp: 0,
                price_jp: 176,
                discounted_price_jp: 176,
                _name_jp: '5.8 cm',
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                spo_item_id: null,
                original_quantity: 20,
                is_pb: false,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                goods_type_id: null,
                _is_discontinued: false,
                _: {
                  base_product_size: {
                    name: '5.8 cm',
                    id: 8827,
                  },
                  base_product_color: {
                    name: '흰색',
                    id: 37535,
                  },
                },
              },
              up_c: {
                id: 12493990,
                type: 'projection',
                is_selected: true,
                quantity: 20,
                user_id: 1773756,
                product_color_id: 15787646,
                created_at: '2023-12-13T11:00:02.134Z',
                updated_at: '2024-01-12T02:26:32.580Z',
                user_product_id: null,
                projection_id: 2034484,
                base_product_id: 4254,
                base_product_color_id: 37535,
                base_product_size_id: 8827,
                buyer_id: null,
                shipping_id: 2456726,
                printable_product_id: null,
                up_id: 12493990,
                up_c_id: 12493990,
                bp_price: 1800,
                print_price: 0,
                price: 1800,
                is_hidden: false,
                ss_seller_id: null,
                ss_content_id: null,
                cart_at: '2023-12-13T11:00:02.134Z',
                up_c_cart_at: '2023-12-13T11:00:02.134Z',
                discounted_price: 1800,
                bp_price_en: '1.640000',
                print_price_en: '0.000000',
                price_en: '1.640000',
                discounted_price_en: '1.640000',
                memo: null,
                is_review: false,
                review_id: null,
                o_id: null,
                o_pp_id: null,
                o_pcp_id: null,
                o_user_id: null,
                o_projection_id: null,
                o_base_product_size_id: null,
                o_colored_preview_id: null,
                o_copied_colored_preview_id: null,
                up_main: null,
                upc_main: null,
                collabo_type: '',
                oem_price: 0,
                bp_price_jp: 176,
                print_price_jp: 0,
                price_jp: 176,
                discounted_price_jp: 176,
                store_id: null,
                profit: null,
                profit_en: null,
                profit_jp: null,
                product_option_id: null,
                option_price: 0,
                option_price_en: '0.000000',
                option_price_jp: 0,
                original_quantity: 20,
                is_pb: false,
                spo_item_id: null,
                goods_type_id: null,
                partial_delivery_at: null,
                _: {
                  printable_product: {},
                  base_product: {
                    name: '핀버튼',
                    id: 4254,
                  },
                  product_color: {
                    name: null,
                    product_faces2: {
                      value: [
                        {
                          debug: {},
                          bpf_id: 7998,
                          rotate: 0,
                          cv_bpcf: {
                            cid: 'c12',
                            src: '//s3.marpple.co/files/u_1150555/2020/5/original/f_2447022_1589771040423_zx5SsPowPuf7pB3ED0o.png',
                            top: 223,
                            fill: 'rgb(0,0,0)',
                            left: 223,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_bpcf',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 414,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 414,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-over',
                          },
                          designs: [
                            {
                              cid: 'c22',
                              src: '//s3.marpple.co/files/guest/2022/10/600/e3a56cc9789c979e4a39445ba681a2b8b53aaed51.png',
                              top: 310.2969900240797,
                              fill: 'rgb(0,0,0)',
                              left: 324.75652920396357,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9670625,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 2.9,
                                height_cm: 2.6,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/10/original/e3a56cc9789c979e4a39445ba681a2b8b53aaed51.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 1197,
                                image_height: 1073,
                                real_width_cm: 2.9,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 2.6,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                is_low_quality_agree: false,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.999841,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 537.8444689999999,
                              scaleX: 0.35247832939354545,
                              scaleY: 0.35247832939354545,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                            {
                              cid: 'c24',
                              src: '//s3.marpple.co/files/guest/2022/10/600/42759cea9bfe050ec6ffd2ab3062e6c7294ff97e1.png',
                              top: 371.39411764705596,
                              fill: 'rgb(0,0,0)',
                              left: 322.3139290407359,
                              type: 'image',
                              _data: {
                                name: '',
                                price: 0,
                                rotate: 0,
                                cv_type: 'cv_image',
                                name_en: '',
                                image_id: 9670631,
                                is_embro: false,
                                price_en: 0,
                                width_cm: 3,
                                height_cm: 3.8,
                                image_url:
                                  '//s3.marpple.co/files/guest/2022/10/original/42759cea9bfe050ec6ffd2ab3062e6c7294ff97e1.png',
                                nscreened: false,
                                only_flex: false,
                                image_width: 4096,
                                image_height: 5327,
                                real_width_cm: 3,
                                image_color_id: null,
                                image_width_cm: null,
                                original_is_ai: false,
                                press_color_id: null,
                                real_height_cm: 3.8,
                                image_height_cm: null,
                                is_marpple_image: false,
                                press_color_code: null,
                                press_color_name: null,
                                image_original_url: null,
                                design_collection_type: null,
                              },
                              angle: 0,
                              flipX: false,
                              flipY: false,
                              skewX: 0,
                              skewY: 0,
                              width: 599.998464,
                              alignX: 'none',
                              alignY: 'none',
                              clipTo: null,
                              height: 780.320268,
                              scaleX: 0.36062115972104924,
                              scaleY: 0.36062115972104924,
                              shadow: null,
                              stroke: null,
                              evented: true,
                              filters: [],
                              opacity: 1,
                              originX: 'left',
                              originY: 'top',
                              visible: true,
                              fillRule: 'nonzero',
                              selectable: true,
                              crossOrigin: 'Anonymous',
                              is_overflow: false,
                              meetOrSlice: 'meet',
                              strokeWidth: 0,
                              is_oversized: false,
                              is_smallsized: false,
                              resizeFilters: [],
                              strokeLineCap: 'butt',
                              strokeLineJoin: 'miter',
                              backgroundColor: '',
                              strokeDashArray: null,
                              transformMatrix: null,
                              strokeMiterLimit: 10,
                              globalCompositeOperation: 'source-over',
                            },
                          ],
                          cv_mask1: {
                            cid: 'c13',
                            src: '//s3.marpple.co/f1/2020/4/1330618_1587632307649_55672.png',
                            top: 223,
                            fill: 'rgb(0,0,0)',
                            left: 223,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask1',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 414,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 414,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          cv_mask2: {
                            cid: 'c14',
                            src: '//s3.marpple.co/files/u_1686012/2023/5/original/394e3979f17978292544e3e5afedcf6e87d38a801.png',
                            top: 223,
                            fill: 'rgb(0,0,0)',
                            left: 223,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_mask2',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 414,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 414,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'destination-in',
                          },
                          face_name: '앞면',
                          size_info: {
                            top: 254.86046511627904,
                            left: 117.90697674418604,
                            right: 117.90697674418604,
                            width: 154.1860465116279,
                            bottom: 254.86046511627904,
                            height: 154.1860465116279,
                            x_diff: 0,
                            y_diff: 0,
                          },
                          cv_shading: {
                            cid: 'c15',
                            src: '//s3.marpple.co/files/u_1150555/2020/12/original/69acd4854824a0da3d96e79f26f058b424bbc0c01.png',
                            top: 223,
                            fill: 'rgb(0,0,0)',
                            left: 223,
                            type: 'image',
                            _data: {
                              cv_type: 'cv_shading',
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 414,
                            alignX: 'none',
                            alignY: 'none',
                            clipTo: null,
                            height: 414,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: null,
                            evented: false,
                            filters: [],
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: true,
                            fillRule: 'nonzero',
                            selectable: false,
                            crossOrigin: 'Anonymous',
                            meetOrSlice: 'meet',
                            strokeWidth: 0,
                            resizeFilters: [],
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: null,
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          size_faces: [
                            {
                              dpi: 300,
                              img: {
                                diff: 0,
                                width: 414,
                                height: 414,
                              },
                              line: {
                                top: 7,
                                left: 7,
                                width: 400,
                                height: 400,
                              },
                              real: {
                                cm: {
                                  width: 6.2,
                                  height: 6.2,
                                },
                                px: {
                                  top: 260,
                                  left: 260,
                                  width: 341,
                                  height: 341,
                                  scaleX: 1,
                                  scaleY: 1,
                                },
                              },
                              items: [
                                {
                                  cm: {
                                    width: 9.2371,
                                    height: 9.2287,
                                  },
                                  px: {
                                    top: 165,
                                    left: 166,
                                    width: 530,
                                    height: 530,
                                  },
                                  is_90_rotate: false,
                                  is_180_rotate: false,
                                  is_270_rotate: false,
                                  print_item_url:
                                    '//s3.marpple.co/files/u_193535/2023/5/original/4a90f2b451a62fb39175bc7ab57346fb2b8c91081.png',
                                },
                              ],
                              mask2: {
                                other_height: 0,
                              },
                              print: {
                                cm: {
                                  width: 5.6206,
                                  height: 5.6206,
                                },
                                px: {
                                  rx: 360,
                                  ry: 360,
                                  top: 225,
                                  left: 225,
                                  width: 412,
                                  height: 412,
                                },
                                img: {
                                  url: null,
                                },
                                etc_meta: {},
                                result_px: {
                                  width: 0,
                                  height: 0,
                                },
                              },
                              mockup_url: null,
                              px_per_1cm: 73.30178,
                              is_standard: false,
                              end_point_cm: 0,
                              is_90_rotate: false,
                              start_point_cm: 0,
                              has_cutting_line: false,
                              legacy_print_area: {
                                rx: 200,
                                ry: 200,
                                top: 231,
                                left: 231,
                                width: 399,
                                height: 400,
                                temp_width: 399,
                                ratio_width: 5.6206,
                                temp_height: 399,
                                ratio_height: 5.6206,
                              },
                              base_product_size_id: 8827,
                              is_print_area_legacy: true,
                            },
                          ],
                          start_name: '',
                          collabo_type: '',
                          face_name_en: 'Front',
                          face_name_jp: '前面',
                          cv_print_area: {
                            rx: 360,
                            ry: 360,
                            cid: 'c17',
                            top: 223,
                            fill: 'rgba(0,0,0,0)',
                            left: 223,
                            type: 'rect',
                            _data: {
                              cv_type: 'cv_print_area',
                              print_px: {
                                rx: 360,
                                ry: 360,
                                top: 225,
                                left: 225,
                                width: 412,
                                height: 412,
                              },
                              nscreened: false,
                              is_not_design: true,
                            },
                            angle: 0,
                            flipX: false,
                            flipY: false,
                            skewX: 0,
                            skewY: 0,
                            width: 414,
                            clipTo: null,
                            height: 414,
                            scaleX: 1,
                            scaleY: 1,
                            shadow: null,
                            stroke: '#2f2f2f',
                            evented: false,
                            opacity: 1,
                            originX: 'left',
                            originY: 'top',
                            visible: false,
                            fillRule: 'nonzero',
                            selectable: false,
                            strokeWidth: 2,
                            strokeLineCap: 'butt',
                            strokeLineJoin: 'miter',
                            backgroundColor: '',
                            strokeDashArray: [6, 3],
                            transformMatrix: null,
                            strokeMiterLimit: 10,
                            globalCompositeOperation: 'source-over',
                          },
                          printable_files: [],
                          is_design_over_bpf: false,
                          base_product_size_id: false,
                          bpf_mask2_padding_top: 0,
                          [LOC_OPT_ACTIVATE_ATTR_NAME]: false,
                        },
                      ],
                      can_not_change_color: false,
                    },
                    thumbnails: null,
                    id: 15787646,
                  },
                },
              },
            },
          },
        ],
      },
    },
  ];

  const outbound_items_by_waybill = go(
    test_data,
    zipWithIndexL,
    map(
      ([
        idx,
        {
          id: key,
          waybill_no_domestic: waybill_no,
          _: { lgl_items },
        },
      ]) => {
        const rows = go(
          lgl_items,
          map(
            ({
              id: lgl_item_id,
              user_product_id: upcs_id,
              _: {
                up_c,
                up_c_ss,
                lgl_item_quantities: { inbound_order_requested_quantity: loaded_quantity },
              },
            }) => {
              const product_info = getProductInfoByUpc({ up_c, up_c_ss });

              return {
                ...product_info,
                upcs_id,
                lgl_item_id,
                loaded_quantity,
                waybill_no: '129572957293',
                quantity: 100,
              };
            },
          ),
          indexBy(({ upcs_id }) => upcs_id),
        );

        return {
          title: html`<span class="strong title">운송장 ${idx + 1}</span>
            <span class="waybill_no">${waybill_no}</span>`,
          name: 'waybill',
          idx,
          key,
          data: test_data[idx],
          contentTemplate: makeTableHtml({
            data: rows,
            columns: OUTBOUND_PRODUCT_BY_PROJECTION_TABLE_COLUMN,
          }),
          selected: idx === 0,
        };
      },
    ),
  );

  const outbound_items_by_projection = [
    {
      title: html`<span class="strong">주문번호</span> <span class="projection_no">${3947295}</span>`,
      name: 'projection',
      idx: 0,
      key: 3947295,
      data: [],
      contentTemplate: makeTableHtml({
        data: [],
        columns: OUTBOUND_PRODUCT_BY_PROJECTION_TABLE_COLUMN,
      }),
      selected: true,
    },
    {
      title: html`<span class="strong">주문번호</span> <span class="project ion_no">${9473659}</span>`,
      name: 'projection',
      idx: 1,
      key: 9473659,
      data: [],
      contentTemplate: makeTableHtml({
        data: [],
        columns: OUTBOUND_PRODUCT_BY_PROJECTION_TABLE_COLUMN,
      }),
      selected: false,
    },
    {
      title: html`<span class="strong">주문번호</span> <span class="projection_no">${9473659}</span>`,
      name: 'projection',
      idx: 1,
      key: 1,
      data: [],
      contentTemplate: makeTableHtml({
        data: [],
        columns: OUTBOUND_PRODUCT_BY_PROJECTION_TABLE_COLUMN,
      }),
      selected: false,
    },
  ];

  return `<div class="header">
            <div class="title">
                <h1>출고 상품 목록 / 총 ${order_count}개의 주문서 합포장 완료</h1>
                <span>출고 주문 번호: ${order_id}</span>
            </div>
            <div class="switch_field">
                <input type="radio" id="radio_projection" class="base_switch" name="base_switch" value="projection" ${
                  base === 'projection' ? 'checked' : ''
                }/>
                <label for="radio_projection">주문서 기준</label>
                <input type="radio" id="radio_waybill" class="base_switch" name="base_switch" value="waybill" ${
                  base === 'waybill' ? 'checked' : ''
                }/>
                <label for="radio_waybill">운송장 기준</label>
            </div>
          </div>
          <div class="body">
          <div class="tab_wrapper">
            <div class="table_wrapper">
                ${makeTabsWithTablesHtml({
                  data: base === 'waybill' ? outbound_items_by_waybill : outbound_items_by_projection,
                })}
            </div>
            </div>
        </div>`;
};
