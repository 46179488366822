import { each, go, isNil, mapL, rejectL, takeAllC } from 'fxjs/es';

export const handleTopMenuDeleteClick =
  ({ tab_el }) =>
  () =>
    go(
      tab_el?.__mp_free_sticker_editor?.getSelectedEls?.() ?? [],
      mapL((el) => el?.id),
      rejectL(isNil),
      each((id) => tab_el?.__mp_free_sticker_editor?.removeEl?.(id)),
    );

export const handleTopMenuDuplicateClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_free_sticker_editor?.getIsFull?.()) {
      await $.alert(T('modules::VectorEditor::Sticker::message::스티커는 최대 20개까지 생성할 수 있습니다.'));
      return;
    }

    $.don_loader_start();
    try {
      await go(
        tab_el?.__mp_free_sticker_editor?.getSelectedEls?.() ?? [],
        mapL((el) => el?.id),
        rejectL(isNil),
        mapL((id) => tab_el?.__mp_free_sticker_editor?.copyEl?.(id)),
        rejectL(isNil),
        takeAllC,
        (ids) => tab_el?.__mp_free_sticker_editor?.selectEls?.(ids),
      );
    } finally {
      $.don_loader_end();
    }
  };
