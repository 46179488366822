import { defaults, extend, go, tap } from 'fxjs/es';
import { isCylinderStyle } from '../../categorize.js';
import { getCvObj } from '../../Fcanvas/cv_object.js';

export function cylinderObj(obj) {
  return extend(
    {
      up_side: 0,
      down_side: 0,
      reflection_url: null,
      degree: 0,
      area: { top: 0, left: 0, width: 0, height: 0 },
    },
    obj,
  );
}

export function getPreviewClinder(fcanvas) {
  if (!isCylinderStyle(fcanvas)) return;
  const cylinder = JSON.parse(JSON.stringify(fcanvas.preview.cylinder));
  return cylinder;
}

export const makeCylinderAreaInMaker = function (fcanvas) {
  const last_cv_print_area = getCvObj(fcanvas._objects, 'cv_cylinder_area');
  if (last_cv_print_area) fcanvas.remove(last_cv_print_area);
  return go(
    new fabric.Rect(
      defaults(
        {
          selectable: false,
          evented: false,
          visible: false,
          fill: 'transparent',
          // fill: 'rgb(0, 0, 0, 1)',
          _data: {
            cv_type: 'cv_cylinder_area',
            is_not_design: true,
          },
        },
        fcanvas.preview.cylinder.area,
      ),
    ),
    G.mp.maker.nscreen,
    tap((cv_obj) => fcanvas.add(cv_obj)),
  );
};
