import { imageUploadAlert } from '../../../../Maker/F/imageUploadAlert.js';
import { SVGEditorMobileImageUploadF } from '../../../../SVGEditor/MobileImageUpload/F/Function/module/SVGEditorMobileImageUploadF.js';
import { SVGEditorUploadImageF } from '../../../../SVGEditor/UploadImage/F/Function/module/SVGEditorUploadImageF.js';
import { NewMakerImageF } from '../../../Image/F/Function/module/NewMakerImageF.js';
import { MakerDebugF } from '../../../../MakerDebug/F/Function/module/MakerDebugF.js';
import { getProductColorInMaker } from '../../../../Maker/F/getSth.js';
import { MakerDebugConstantS } from '../../../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';

export const uploadFile = async (file_input_el) => {
  if ($.valid_files(file_input_el)) {
    await imageUploadAlert(file_input_el.getAttribute('accept').split(','));
    return;
  }
  try {
    const file = file_input_el.files[0];
    if (!file) {
      return;
    }
    if (file?.type === 'image/svg+xml') {
      const { svg_file, png_file } = await SVGEditorMobileImageUploadF.processSVGFile(file);
      const { image } = await SVGEditorUploadImageF.uploadSvgFilePngFileToImages({
        svg_file,
        png_file,
      });
      return image;
    } else {
      const valiation_result = await NewMakerImageF.validateImageSize(file);
      if (!valiation_result?.result) {
        if (valiation_result.message) $.alert(valiation_result.message);
        return;
      }
      const { result, image, msg } = await $.upload(file_input_el, {
        url: '/@fileUpload/maker/add_img',
        data: {},
      });
      if (!result && msg) {
        $.alert(msg);
        return;
      }
      return image;
    }
  } catch (err) {
    if (err?.__mp_alert_message) {
      $.alert(err.__mp_alert_message);
      return;
    }
    $.alert(T(`maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.`));
    MakerDebugF.api.MakerDebugPost(
      getProductColorInMaker(),
      err,
      MakerDebugConstantS.Category.ImageUpload,
      'uploadFile',
    );
  } finally {
    file_input_el.type = 'file';
    file_input_el.value = '';
  }
};
