import axios from 'axios';
import { $delegate, $find, $val } from 'fxdom/es';
import { go } from 'fxjs/es';
import { ConfigSentryF } from '../../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { OMPCoreUtilF } from '../../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPAuthConstantS } from '../../../../S/Constant/module/OMPAuthConstantS.js';

export const catchError = async (e) => {
  $.don_loader_end();

  // MEMO: axios 에러
  if (e?.response?.data) {
    let error = {};
    if (typeof e?.response?.data === 'string') error = JSON.parse(e?.response?.data);
    else error = e?.response?.data;

    if (e?.response?.data?.title) {
      await OMPCoreUtilF.titleAlert(error);
    } else {
      await OMPCoreUtilF.alert({
        content: error?.message || TT('error::common::error_01'),
      });
    }
    return;
  }

  // MEMO: 프론트 에러
  if (e?.handle) {
    if (e?.json) {
      await OMPCoreUtilF.titleAlert(e.json);
      return;
    }

    await OMPCoreUtilF.alert({
      content: e?.message || TT('error::common::error_01'),
    });
    return;
  }

  OMPCoreUtilF.alert({
    content: TT('error::common::error_01'),
  });
  ConfigSentryF.error(e);
};

export const events = (tab_el) => {
  return go(
    tab_el,
    $delegate('click', '.omp-find-id-result__button-reset_pw', async (e) => {
      try {
        const email$ = go(e.delegateTarget, $find('input[name="user"]:checked'));
        if (!email$) throw new OMPAuthConstantS.AuthError(TT('error::auth::error_36'));
        $.don_loader_start();
        const res = await axios.post('/@api/reset_password/request_cert', { email: $val(email$) });
        OMPCoreUtilF.titleAlert(res.data);
        $.don_loader_end();
      } catch (e) {
        return catchError(e);
      }
    }),
    $delegate('click', '.omp-find-id-result__button-login', (e) => {
      const email$ = go(e.delegateTarget, $find('input[name="user"]:checked'));
      if (email$) MuiF.closeFrame({ email: $val(email$) });
      else MuiF.closeFrame({ email: '' });
    }),
  );
};
