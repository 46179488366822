import { pipe } from 'fxjs/es';
import { changeColorOfCvImage, getCvImage, objForCvImageFrameTmpl } from './fs.js';
import { makeCvImageFrameHtml } from './tmpl.js';
import { makeAutoSwiperActive } from '../util.js';
import { SVGEditorUtilF } from '../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';

$.frame.defn_frame({
  frame_name: 'maker.image_color',
  el_class: 'image_color maker_dialog editor_menu after_bar',
  el_id: 'image_color_app',
  page_name: 'maker.image_color',
  title: T('Color'),
  animation: true,
  height: 158 + 48 + G.mp.frame.v_height,
  header_height: 48 + G.mp.frame.v_height,
  always_remove: true,
  hide_frame_button_type: 'V',
  prev_frame_show: true,
  appended: function (frame_el) {
    if (T.lang === 'kr') {
      const cv_image = getCvImage(G.mp.maker.active());
      const color_name = cv_image._data['press_color_name' + _en] || '색 이름 없음';
      $.add_class($1('[frame_name="maker.image_color"] >.don_wrapper >.header >.title'), 'color_name');
      _go($1('[frame_name="maker.image_color"] .color_name'), $.text(color_name));
    }
    G.mp.maker.zoom.by_active1(G.mp.maker.active(), 158 + 48 + G.mp.frame.v_height);
    SVGEditorUtilF.halfFrameClose(frame_el);
  },
  closing: function () {
    G.mp.maker.ban_frame_hide();
    G.mp.maker.zoom.recover();
  },
  closed: function () {
    G.mp.maker.modified();
  },
  showing: function () {
    G.mp.maker.ban_frame_show();
  },
  showed: $.on_prevent_default,
  hiding: $.off_prevent_default,
});

$.frame.defn_page({
  page_name: 'maker.image_color',
  tabs: [
    {
      tab_name: 'maker.image_color',
      template: pipe(objForCvImageFrameTmpl, makeCvImageFrameHtml),
      appended: _pipe(
        _p.tap(function (tab_el) {}),
        $.on('click', '.image_color_item', async function (e) {
          if (!G.mp.maker.active()) return $.frame.close();
          try {
            const currentTarget = e.currentTarget;
            const image_color = box.sel(currentTarget);
            await changeColorOfCvImage(image_color);
            $.selectable3(currentTarget);
            if (T.lang === 'kr')
              _go(
                $1('#image_color_app .color_name'),
                $.text(image_color['press_color_name' + _en] || T('maker::Unknown')),
              );
          } catch (e) {
            if (e === 'not_available_color') return $.alert(T('선택된 색상으로 패턴을 만들수 없습니다.'));
            throw e;
          }
        }),
      ),
      showed: function (el_tab) {
        makeAutoSwiperActive(el_tab);
      },
    },
  ],
});

export function openMakerImageColorFrame() {
  $.frame.open({
    frame_name: 'maker.image_color',
  });
}
