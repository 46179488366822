import { OMPCartTmplS } from '../../../../Cart/S/Tmpl/module/OMPCartTmplS.js';
import { OMPMyPageOrderS } from '../../../Order/S/Function/module/OMPMyPageOrderS.js';
import { OMPMyPageWishTmplS } from '../Tmpl/module/OMPMyPageWishTmplS.js';

export const WishBuyLaterTableColumns = () => {
  return [
    {
      key: 'product_number',
      title: TT('cart::cart_04'),
      size: 12,
      template: OMPCartTmplS.cartProductNumberColumnTmpl,
    },
    {
      key: 'product_info',
      title: TT('cart::cart_05'),
      url: (up_c) =>
        up_c.base_product_id && up_c._.product_color._.base_product['is_use' + G._en]
          ? OMPMyPageOrderS.makeUpcUrl(up_c)
          : '',
      template: OMPCartTmplS.cartProductInfoColumnTmpl,
    },
    {
      key: 'product_sizes',
      title: TT('cart::cart_06'),
      size: 18,
      template: OMPCartTmplS.cartProductSizesColumnTmpl,
    },
    {
      key: 'product_price',
      title: TT('cart::cart_07'),
      size: 12,
      template: OMPCartTmplS.cartProductPriceColumnTmpl,
    },
    {
      key: 'product_edit',
      title: TT('cart::cart_08'),
      size: 15,
      template: OMPMyPageWishTmplS.buyLaterProductEditColumnTmpl,
    },
  ];
};
