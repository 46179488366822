import { each, go } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';

export const handleRightCancelButtonClick = () => () => MuiF.popPage().catch(console.error);

export const handleRightDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_hook == null) {
      return;
    }
    const hook = tab_el.__mp_hook;

    if (tab_el.__mp_postProcess == null) {
      return;
    }
    const postProcess = tab_el.__mp_postProcess;

    const { x: hook_cx, y: hook_cy } = hook.last_center_position;

    const path_data = hook.getPathDataHookUnited();
    const { success, value } = await postProcess({
      cutting_line_path_data: path_data,
      hook_position: { x: hook_cx, y: hook_cy },
    });

    if (!success && value == null) {
      return;
    }
    if (!success) {
      await $.alert(value);
      return;
    }

    return MuiF.closeFrame(value);
  };

export const handleRightInsideButtonClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_hook == null) {
      return;
    }
    const hook = tab_el.__mp_hook;

    if (tab_el.__mp_keyring_hook_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_hook_editor;

    if (hook.current_positioning_mode === 'inside') {
      return;
    }

    const { x: last_hook_center_x, y: last_hook_center_y } = hook.last_center_position;
    hook.updateHookPositioningMode('inside');
    const { x, y } = hook.getHookCenter({ x: last_hook_center_x, y: last_hook_center_y });
    editor.setHookPosition({ x, y });
    go(
      tab_el,
      $findAll(`.right_container .wrapper_body_control_wrapper`),
      each((el) => (el.dataset.hook_position = 'inside')),
    );
  };

export const handleRightOutsideButtonClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_hook == null) {
      return;
    }
    const hook = tab_el.__mp_hook;

    if (tab_el.__mp_keyring_hook_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_hook_editor;

    if (hook.current_positioning_mode === 'outside') {
      return;
    }

    const { x: last_hook_center_x, y: last_hook_center_y } = hook.last_center_position;
    hook.updateHookPositioningMode('outside');
    const { x, y } = hook.getHookCenter({ x: last_hook_center_x, y: last_hook_center_y });
    editor.setHookPosition({ x, y });
    go(
      tab_el,
      $findAll(`.right_container .wrapper_body_control_wrapper`),
      each((el) => (el.dataset.hook_position = 'outside')),
    );
  };

export const handleEditorContainerMouseDown =
  ({ tab_el }) =>
  ({ target: target_el, clientX: client_x, clientY: client_y }) => {
    if (tab_el.__mp_is_hook_moving) {
      return;
    }

    if (tab_el.__mp_keyring_hook_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_hook_editor;

    if (tab_el.__mp_hook == null) {
      return;
    }
    const hook = tab_el.__mp_hook;

    if (target_el instanceof Node && editor.getIsInHookEl(target_el)) {
      const { x, y } = editor.convertCoordsToInside({ x: client_x, y: client_y });
      const { x: hook_center_x, y: hook_center_y } = hook.getHookCenter({ x, y });

      tab_el.__mp_is_hook_moving = true;
      editor.setHookPosition({ x: hook_center_x, y: hook_center_y });
    }
  };

export const handleTabElMouseMove =
  ({ tab_el }) =>
  (event) => {
    if (!tab_el.__mp_is_hook_moving) {
      return;
    }

    event.preventDefault();

    if (tab_el.__mp_keyring_hook_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_hook_editor;

    if (tab_el.__mp_hook == null) {
      return;
    }
    const hook = tab_el.__mp_hook;

    const { clientX: client_x, clientY: client_y } = event;
    const { x, y } = editor.convertCoordsToInside({ x: client_x, y: client_y });
    const { x: hook_center_x, y: hook_center_y } = hook.getHookCenter({ x, y });
    editor.setHookPosition({ x: hook_center_x, y: hook_center_y });
  };

export const handleTabElMouseUp =
  ({ tab_el }) =>
  (event) =>
    handleTabElMouseUpOrMouseLeave({ tab_el, event });

export const handleTabElMouseLeave =
  ({ tab_el }) =>
  (event) =>
    handleTabElMouseUpOrMouseLeave({ tab_el, event });

function handleTabElMouseUpOrMouseLeave({ tab_el, event }) {
  if (!tab_el.__mp_is_hook_moving) {
    return;
  }
  event.preventDefault();
  tab_el.__mp_is_hook_moving = false;
}
