import { TabulatorFull as Tabulator } from 'tabulator_new';

import { DfInhouseF } from './module/DfInhouseF.js';
import { go, mapL, extend, takeAll, omit, pluck, intersectionBy, differenceBy } from 'fxjs/es';
import { DfInhouseConstantS } from '../../S/Constant/module/DfInhouseConstantS.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { $addClass, $removeClass } from 'fxdom/es';

export const boxTable = {
  init: async () => {
    const table_el = DfInhouseF.stateDOM.get.boxTable();

    const boxes = DfInhouseF.stateDOM.get.selectedBucketBoxes(true);

    const rows_data = DfInhouseF.adapterInhouseBoxesToTableRows({ boxes });

    const columns = go(
      [...DfInhouseF.TABLE_CONST.columns.parent, ...DfInhouseF.TABLE_CONST.columns.child],
      mapL(omit(['adapter'])),
      mapL((data) => extend({ vertAlign: 'middle', headerHozAlign: 'center', hozAlign: 'center' }, data)),
      takeAll,
    );

    const table = new Tabulator(table_el, {
      layout: 'fitDataStretch',
      data: rows_data,
      maxHeight: '100%',
      height: '100%',
      initialSort: [{ column: 'projection_id', dir: 'desc' }],
      dataTree: true,
      dataTreeChildField: DfInhouseF.TABLE_CONST.childTreeFieldName,
      dataTreeStartExpanded: true,
      dataTreeCollapseElement: DfInhouseF.TABLE_CONST.treeElements.collapse,
      dataTreeExpandElement: DfInhouseF.TABLE_CONST.treeElements.expand,
      dataTreeElementColumn: 'tree_toggler',
      responsiveLayout: true,
      columns,
    });

    table.on('tableBuilt', () => {
      // ssr 에 loader 들어가 있음. -> frame 띄워지고 table init 후에 loader 제거
      $.don_loader_end();
    });

    table_el[DfInhouseConstantS.TABLE.KEY] = table;
    window[DfInhouseConstantS.TABLE.KEY] = table;
  },
  getTable: () => {
    return DfInhouseF.stateDOM.get.boxTable(false);
  },
  getTableData: () => {
    return DfInhouseF.boxTable.getTable().getData();
  },
  replaceRowsToSelectedBucket: async (is_hydration = false, is_only_hydration = false) => {
    if (is_hydration) {
      const bucket = DfInhouseF.stateDOM.get.selectedBucket(true);
      await DfInhouseF.stateBucket.gBox.hydrate.bucket({ bucket_id: bucket.id });
    }

    if (!is_only_hydration) {
      const boxes = DfInhouseF.stateDOM.get.selectedBucketBoxes(true);
      DfInhouseF.guard.array(boxes);

      const rows_data = DfInhouseF.adapterInhouseBoxesToTableRows({ boxes });
      DfInhouseF.boxTable.getTable().replaceData(rows_data, { sort: { field: 'id', dir: 'desc' } });
    }
  },
  upsertOrDeleteRows: async (boxes) => {
    DfInhouseF.guard.array(boxes);

    const table = DfInhouseF.boxTable.getTable();

    const exist_rows = DfInhouseF.boxTable.getTableData();

    // 업데이트
    const intersected_rows = intersectionBy((box) => box.id, exist_rows, boxes);

    // 제거
    const removing_rows = differenceBy((box) => box.id, intersected_rows, exist_rows);

    // 추가
    const adding_rows = differenceBy((box) => box.id, intersected_rows, boxes);

    UtilArrayS.isArrayOk(removing_rows) && (await table.deleteRow(pluck('id', removing_rows)));
    UtilArrayS.isArrayOk(intersected_rows) &&
      (await table.updateData(DfInhouseF.adapterInhouseBoxesToTableRows({ boxes: intersected_rows })));

    UtilArrayS.isArrayOk(adding_rows) &&
      table.addData(DfInhouseF.adapterInhouseBoxesToTableRows({ boxes: adding_rows }));
  },
  addBoxDataToRow: async ({ inhouse_shipping_box, target_bucket_id }) => {
    const bucket_selected = DfInhouseF.stateDOM.get.selectedBucket(true);

    // 추가 함수 실행 시점에 추가하려는 타켓 bucket 이 선택되어 있을 때에만 추가함.
    if (bucket_selected.id === target_bucket_id) {
      const table = DfInhouseF.boxTable.getTable();
      table
        .addData(DfInhouseF.adapterInhouseBoxesToTableRows({ boxes: [inhouse_shipping_box] }))
        .then((rows) => {
          const row_el = rows[0].getElement(); // single add

          DfInhouseF.boxTable.highlightRow({ row_el, color: 'green' });
        });
    }
  },
  deleteBoxDataRow: async ({ box_id, target_bucket_id }) => {
    const bucket_selected = DfInhouseF.stateDOM.get.selectedBucket(true);

    // 추가 함수 실행 시점에 추가하려는 타켓 bucket 이 선택되어 있을 때에만 제거함.
    if (bucket_selected.id === target_bucket_id) {
      DfInhouseF.boxTable.highlightRow({
        row_id: box_id,
        color: 'red',
        timeout: 1500,
        cb_after_highlighting: (table) => {
          table.deleteRow(box_id);
        },
      });
    }
  },
  highlightRow: ({ row_el, row_id, color, timeout = 1500, cb_after_highlighting }) => {
    const table = DfInhouseF.boxTable.getTable();

    if (row_el == null) {
      const row_component = table.getRow(row_id);
      if (row_component == null) throw new Error(`${row_id} 아이디를 가진 행이 없습니다.`);
      row_el = row_component.getElement();
    }

    table.scrollToRow(row_el, 'top', false).then(() => {
      const className = `highlight_${color}`;
      $addClass(className, row_el);
      setTimeout(() => {
        $removeClass(className, row_el);
        cb_after_highlighting && cb_after_highlighting(table);
      }, timeout);
    });
  },
  getRowByFilter: (filterFn) => {
    const table = DfInhouseF.boxTable.getTable();
    return table.getRows().filter(filterFn);
  },
  download_excel: ({ bucket_el }) => {
    const table = DfInhouseF.boxTable.getTable();

    const {
      waybill_no,
      _: {
        crew_inhouse_shipping: { company_address, location_name },
      },
    } = box.sel(bucket_el);

    table.options.printStyled = true;
    table.options.printHeader = `
          <div>
            <h1>${location_name} - ${waybill_no}</h1>
            <h2>${company_address}</h2>
          </div>
      `;
    table.options.printFooter = `주문해 주셔서 감사합니다❤️ (주)마플코퍼레이션`;
    table.print('active', true, {
      dataTree: false,
    });
  },
};
