import { PNG } from 'pngjs/browser.js';
import { go, map, range, sel, some } from 'fxjs/es';
import axios from 'axios';

const makeNewCanvas = (canvas) => {
  const new_canvas = document.createElement('canvas');
  new_canvas.width = canvas.width;
  new_canvas.height = canvas.height;
  const new_canvas_ctx = new_canvas.getContext('2d');
  return { new_canvas, new_canvas_ctx };
};

const makeEdgeBlur = (src_canvas) => {
  const { new_canvas_ctx } = makeNewCanvas(src_canvas);
  const { width, height } = src_canvas;
  new_canvas_ctx.filter = 'blur(1px)';
  new_canvas_ctx.drawImage(src_canvas, 0, 0);
  const blur_pixel_arr = new_canvas_ctx.getImageData(0, 0, width, height).data;
  const src_pixel_arr = src_canvas.getContext('2d').getImageData(0, 0, width, height).data;
  for (let i = 0, len = blur_pixel_arr.length; i < len; i += 4) {
    if (blur_pixel_arr[i + 3]) {
      if (src_pixel_arr[i + 3]) {
        for (let k = 0; k < 3; k++) {
          blur_pixel_arr[i + k] = src_pixel_arr[i + k];
        }
      }
    }
    blur_pixel_arr[i + 3] = src_pixel_arr[i + 3];
  }
  return blur_pixel_arr;
  // new_canvas_ctx.putImageData(new ImageData(blur_pixel_arr, width, height), 0, 0);
  // return new_canvas
  // return go(
  //   new_canvas,
  //   makeCloneCanvas,
  //   (c)=> {
  //     const ctx = c.getContext('2d');
  //     ctx.drawImage(canvas, 0, 0);
  //     return c;
  //   }
  // );
};

const getIndex = (x, y, width) => (x + y * width) * 4;

const bilinearInterpolate = (f00, f10, f01, f11, dx, dy) => {
  return f00 * (1 - dx) * (1 - dy) + f10 * dx * (1 - dy) + f01 * (1 - dx) * dy + f11 * dx * dy;
};

const interpolation = (src_pixel_arr, map_pixel_arr, dest_pixel_arr, src_x, src_y, dest_idx, width) => {
  const src_x0 = Math.floor(src_x);
  const src_y0 = Math.floor(src_y);
  const src_x1 = Math.ceil(src_x);
  const src_y1 = Math.ceil(src_y);
  const src_idx_00 = getIndex(src_x0, src_y0, width);
  const src_idx_10 = getIndex(src_x1, src_y0, width);
  const src_idx_01 = getIndex(src_x0, src_y1, width);
  const src_idx_11 = getIndex(src_x1, src_y1, width);
  const dx = src_x - src_x0;
  const dy = src_y - src_y0;
  const src_alpha_arr = [
    src_pixel_arr[src_idx_00 + 3],
    src_pixel_arr[src_idx_10 + 3],
    src_pixel_arr[src_idx_01 + 3],
    src_pixel_arr[src_idx_11 + 3],
  ];
  const src_alpha_zero_count = src_alpha_arr.filter((alpha) => alpha === 0).length;
  if (src_alpha_zero_count < 4) {
    for (let k = 0; k < 3; k++) {
      dest_pixel_arr[dest_idx + k] = bilinearInterpolate(
        src_pixel_arr[src_idx_00 + k],
        src_pixel_arr[src_idx_10 + k],
        src_pixel_arr[src_idx_01 + k],
        src_pixel_arr[src_idx_11 + k],
        dx,
        dy,
      );
    }
    const alpha_interpolated = bilinearInterpolate(
      src_pixel_arr[src_idx_00 + 3],
      src_pixel_arr[src_idx_10 + 3],
      src_pixel_arr[src_idx_01 + 3],
      src_pixel_arr[src_idx_11 + 3],
      dx,
      dy,
    );
    dest_pixel_arr[dest_idx + 3] = Math.min(alpha_interpolated, (map_pixel_arr[dest_idx + 3] / 65535) * 255);
  }
};

export const imageMapper = async (src_canvas, map_pixel_arr) => {
  const { width, height } = src_canvas;
  const src_pixel_arr = makeEdgeBlur(src_canvas);
  const dest_pixel_arr = new Uint8ClampedArray(width * height * 4);
  for (let dest_y = 0; dest_y < height; dest_y++) {
    for (let dest_x = 0; dest_x < width; dest_x++) {
      const dest_idx = getIndex(dest_x, dest_y, width);
      const src_x = (map_pixel_arr[dest_idx] / 65535) * width;
      const src_y = (map_pixel_arr[dest_idx + 1] / 65535) * height;
      if (src_x < 0 || src_y < 0 || src_y > height - 1 || src_x > width - 1) continue;
      if (map_pixel_arr[dest_idx + 3] > 1) {
        interpolation(src_pixel_arr, map_pixel_arr, dest_pixel_arr, src_x, src_y, dest_idx, width);
      }
    }
  }
  const { new_canvas, new_canvas_ctx } = makeNewCanvas(src_canvas);
  new_canvas_ctx.putImageData(new ImageData(dest_pixel_arr, width, height), 0, 0);
  return new_canvas;
};

export async function decodePNG16bit(arr) {
  const array_buffer = [137, 80, 78, 71, 13, 10, 26, 10].concat(arr);
  return new Promise((resolve, reject) => {
    new PNG({ skipRescale: true }).parse(array_buffer, (err, result) => {
      err ? reject(err) : resolve(result.data);
    });
  });
}

export async function decodePNG16bits(url) {
  const map_pixel_arr = await go(
    axios.get(url, { responseType: 'arraybuffer' }),
    sel('data'),
    (buffer) => new Uint8Array(buffer),
    (view) => view.subarray(7163),
  );
  return new Promise((resolve, reject) => {
    new PNG({ skipRescale: true }).parse(map_pixel_arr, (err, result) => {
      err ? reject(err) : resolve(result.data);
    });
  });
}

export async function encodePNG16bits(encoded_array) {
  const { data: fake_image_data } = await axios.get(
    '//s3.marpple.co/files/u_1187078/2020/9/original/a78c093d1b30a72b27eb9b8bba99619e6f9e2ade1.jpeg',
    {
      responseType: 'arraybuffer',
    },
  );
  const fake_array_buf = new Uint8Array(fake_image_data);
  const secret_array_buf = Uint8Array.from([...fake_array_buf].concat([...encoded_array]));
  const file = new Blob([secret_array_buf], { type: 'image/jpeg' });
  const formData = new FormData();
  formData.append('file', file, 'secret_array_buf.jpeg');
  const { url } = await $.upload(formData, {
    url: '/@fileUpload/file_only_original',
  });
  return url;
}

/* makeGrid 사용설명서 */
// 캔버스 위에 grid 선을 자유롭게 긋고 캔버스를 리턴하는 함수
// 캔버스 내에서 grid 가 그려질 위치를 x, y, w, h 를 이용해 지정 가능
// grid 선의 간격, 굵기, 색상, 투명도 지정 가능
// grid 바깥 border 선을 on/off 할 수 있으며 굵기, 색상, 투명도 지정 가능
/*
 * canvas_w       : 캔버스 가로 사이즈 (px)
 * canvas_h       : 캔버스 세로 사이즈 (px)
 * grid_x         : grid 가 생성될 x 좌표 (px)
 * grid_y         : grid 가 생성될 y 좌표 (px)
 * grid_w         : grid 가 생성될 가로 길이 (px)
 * grid_h         : grid 가 생성될 세로 길이 (px)
 * is_border      : grid 바깥 border 그릴 것인지 여부 선택 (true / false)
 * border_size    : border 선의 굵기 지정 (px)
 * border_color   : border 선 색상 지정 ("#ffffff")
 * border_opacity : border 선 투명도 지정 (0 ~ 1), border 의 투명도를 지정하면 배경 사진의 제품 윤곽을 mockup 경계와 같이 볼 수 있음
 * line_size      : grid 선의 굵기 지정 (px)
 * line_n         : grid 선의 개수 지정 (#), 숫자가 클 수록 촘촘해짐.
 * line_color     : grid 선 색상 지정 ("#ffffff")
 * line_opacity   : grid 선 투명도 지정(0 ~ 1), grid 선 아래로 배경 이미지가 함께 보여야 할 때 적절히 사용
 * */
export function makeGrid1(canvas, is_border) {
  return makeGrid(canvas, 0, 0, canvas.width, canvas.height, is_border, 2, '#000000', 1, 2, 40, '#000000', 1);
}
export function makeGrid(
  canvas,
  grid_x,
  grid_y,
  grid_w,
  grid_h,
  is_border,
  border_size,
  border_color,
  border_opacity,
  line_size,
  line_n,
  line_color,
  line_opacity,
) {
  const ctx = canvas.getContext('2d');
  const { width: canvas_w, height: canvas_h } = canvas;
  const interval = (Math.max(canvas_w, canvas_h) - line_size * line_n) / (line_n + 1);
  const pixel_arr = ctx.getImageData(0, 0, canvas_w, canvas_h).data;
  const isBetween = (ll, hl) => (val) => val >= ll && val < hl;
  const getIndex = (x, y, w) => (x + y * w) * 4;
  const hexToRGB = (hex) => hex.match(/[A-Za-z0-9]{2}/g).map((v) => parseInt(v, 16));
  line_color = hexToRGB(line_color).concat(line_opacity * 255);
  border_color = hexToRGB(border_color).concat(border_opacity * 255);
  for (let y = 0; y < canvas_h; y++) {
    if (isBetween(grid_y, grid_y + grid_h)(y)) {
      for (let x = 0; x < canvas_w; x++) {
        if (isBetween(grid_x, grid_x + grid_w)(x)) {
          const r_x = (x - grid_x) % (interval + line_size);
          const r_y = (y - grid_y) % (interval + line_size);
          if (some(isBetween(interval, interval + line_size), [r_x, r_y])) {
            const idx = getIndex(x, y, canvas_w);
            map((n) => (pixel_arr[idx + n] = line_color[n]), range(4));
          }
          if (is_border) {
            if (
              !isBetween(grid_x + border_size, Math.min(grid_x + grid_w, canvas_w) - border_size)(x) ||
              !isBetween(grid_y + border_size, Math.min(grid_y + grid_h, canvas_h) - border_size)(y)
            ) {
              const idx = getIndex(x, y, canvas_w);
              map((n) => (pixel_arr[idx + n] = border_color[n]), range(4));
            }
          }
        }
      }
    }
  }
  ctx.putImageData(new ImageData(pixel_arr, canvas_w, canvas_h), 0, 0);
  return canvas;
}
