import { html } from 'fxjs/es';
export const myPageResubmitFileTmpl = ({ mobile = false }) => {
  return html`<div class="omp-my-resubmit__wrapper">
    <div class="omp-my-resubmit__header">${TT('pdf::agreements::4')}</div>
    <div class="omp-my-resubmit__body">
      <div class="omp-my-resubmit__desc">${mobile ? TT('pdf::agreements::6') : TT('pdf::agreements::5')}</div>
      <div class="omp-my-resubmit__agreed">
        <div class="omp-my-resubmit__checkbox-wrapper">
          <input id="omp-my-resubmit-agreed" type="checkbox" name="resubmit-agreed" />
          <label for="omp-my-resubmit-agreed">${TT('pdf::agreements::7')}</label>
        </div>
      </div>
    </div>
    <div class="omp-my-resubmit__footer">
      <button class="omp-my-resubmit__upload-button disabled">${TT('pdf::resubmit::4')}</button>
    </div>
  </div>`;
};
