const korean_reg = /[\uac00-\ud7af]|[\u1100-\u11ff]|[\u3130-\u318f]|[\ua960-\ua97f]|[\ud7b0-\ud7ff]/;

export const truncText = (val, len) => {
  const v = val.toString();
  return v.length > len ? v.substr(0, len) + '.' : v;
};

export const truncTextAdv = (text, two_byte_font_len, field_len) => {
  if (!text) return '';
  let i;
  let acc_len = 0;
  let is_truncated = false;
  const len = text.length;
  for (i = 0; i < len; i++) {
    acc_len += korean_reg.test(text[i]) ? two_byte_font_len : two_byte_font_len / 2;
    if (acc_len > field_len) {
      is_truncated = true;
      break;
    }
  }
  return text.substr(0, i) + `${is_truncated ? '.' : ''}`;
};

export const animateColor = (row_el, duration, iteration, color_arr) => {
  row_el.animate(
    { backgroundColor: color_arr },
    {
      direction: 'alternate',
      easing: 'ease-out',
      duration,
      iterations: iteration,
      fill: 'none',
    },
  );
};

const numToPadStr = (num, digit, fill_str) => {
  return num.toString().padStart(digit, fill_str);
};

const date_label_format = (date_obj) =>
  `${numToPadStr(date_obj.getMonth() + 1, 2, '0')}/${numToPadStr(date_obj.getDate(), 2, '0')} '${numToPadStr(
    date_obj.getFullYear() - 2000,
    2,
    '0',
  )}`;

export const convertISODateToMMDD = (date_str) => {
  if (date_str.split('.')[0].length === 2) {
    date_str = '20' + date_str;
  }
  const date_obj = new Date(date_str);
  return `${numToPadStr(date_obj.getMonth() + 1, 2, '0')}/${numToPadStr(date_obj.getDate(), 2, '0')}`;
};

export const convertISODateToLabel1 = (date_str) => {
  //Input: ISO date format
  return `${date_label_format(new Date(date_str))}`;
};

export const convertISODateToLabel2 = (date_str) => {
  //Input: ISO date format
  const date_obj = new Date(date_str);
  return `${numToPadStr(date_obj.getDate(), 2, '0')} ${numToPadStr(
    date_obj.getMonth() + 1,
    2,
    '0',
  )} ${numToPadStr(date_obj.getFullYear() - 2000, 2, '0')}`;
};

export const convertISODateTimeToLabel1 = (date_str) => {
  //Input: ISO date format
  const date_obj = new Date(date_str);
  return `${date_label_format(date_obj)}  ${numToPadStr(date_obj.getHours(), 2, '0')}:${numToPadStr(
    date_obj.getMinutes(),
    2,
    '0',
  )}`;
};

export const convertISODateTimeToLabel2 = (date_str) => {
  //Input: ISO date format
  const date_obj = new Date(date_str);
  return `${numToPadStr(date_obj.getDate(), 2, '0')}일 ${numToPadStr(
    date_obj.getMonth() + 1,
    2,
    '0',
  )}월 ${numToPadStr(date_obj.getFullYear() - 2000, 2, '0')}년 ${numToPadStr(
    date_obj.getHours(),
    2,
    '0',
  )}:${numToPadStr(date_obj.getMinutes(), 2, '0')}`;
};
