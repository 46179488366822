import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorAcrylicFigureFreeMobileCuttingLineAutoMuiF } from '../Mui/module/VectorEditorAcrylicFigureFreeMobileCuttingLineAutoMuiF.js';

export const makeCuttingLineAuto = ({
  art_board: { width: art_board_width, height: art_board_height },
  stand_leg: {
    ground: {
      size_delta: stand_leg_ground_size_delta,
      position_delta: stand_leg_ground_position_delta,
      min_width: stand_leg_ground_min_width,
      max_width: stand_leg_ground_max_width,
      width: stand_leg_ground_width,
      height: stand_leg_ground_height,
    },
    notch: { round: stand_leg_notch_round, width: stand_leg_notch_width, height: stand_leg_notch_height },
  },
  makeTargetImageEl,
  postProcess,
}) =>
  MuiF.pushPage(VectorEditorAcrylicFigureFreeMobileCuttingLineAutoMuiF.page, (page, [tab]) => {
    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_art_board_width = art_board_width;
      tab_el.__mp_art_board_height = art_board_height;
      tab_el.__mp_stand_leg_ground_size_delta = stand_leg_ground_size_delta;
      tab_el.__mp_stand_leg_ground_position_delta = stand_leg_ground_position_delta;
      tab_el.__mp_stand_leg_ground_min_width = stand_leg_ground_min_width;
      tab_el.__mp_stand_leg_ground_max_width = stand_leg_ground_max_width;
      tab_el.__mp_stand_leg_ground_width = stand_leg_ground_width;
      tab_el.__mp_stand_leg_ground_height = stand_leg_ground_height;
      tab_el.__mp_stand_leg_notch_round = stand_leg_notch_round;
      tab_el.__mp_stand_leg_notch_width = stand_leg_notch_width;
      tab_el.__mp_stand_leg_notch_height = stand_leg_notch_height;
      tab_el.__mp_makeTargetImageEl = makeTargetImageEl;
      tab_el.__mp_postProcess = postProcess;

      return tab_appending.call(tab, tab_el);
    };
  }).catch(async (error) => {
    try {
      await MuiF.popPage();
    } catch (error) {
      console.error(error);
    }

    throw error;
  });
