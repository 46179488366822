import { each, go, noop, reduce, tap } from 'fxjs/es';
import {
  $addClass,
  $attr,
  $closest,
  $find,
  $findAll,
  $hasClass,
  $qsa,
  $removeClass,
  $setCss,
  $setText,
} from 'fxdom/es';

export const getAdjustmentInputs$ = (tab_el$) => {
  const non_kernels$ = $findAll(`.adjustment_item_sliders input[method='non_kernel']`, tab_el$);
  const single_components$ = $findAll(`.adjustment_item_sliders input:not([method='non_kernel'])`, tab_el$);
  return [non_kernels$, single_components$];
};

export const makeRangeSliderInput = (inputs$) =>
  go(
    inputs$,
    _p.each((input$) => {
      const span$ = $find('span', input$.parentElement);
      const item_name = $attr('item', input$);
      let click = 0;
      let timeout_handler;
      jQuery(input$)
        .rangeslider('destroy')
        .rangeslider({
          polyfill: false,
          onSlide: (_, val) => {
            if (!$hasClass('down', input$)) {
              $addClass('down', input$);
              jQuery(input$).trigger(mobileEvt('mousedown'));
            }
            const display_num = Math.round(val * 100);
            requestAnimationFrame(() => writeSpan(item_name, span$, display_num));
          },
          onSlideEnd: (_) => {
            $removeClass('down', input$);
            clearTimeout(timeout_handler);
            timeout_handler = setTimeout(() => (click = 0), 50);
            if (!click) {
              jQuery(input$).trigger(mobileEvt('mouseup'));
            }
            click++;
          },
        });
    }),
  );

export const multipleClickEventHandler =
  (click_take, listener, click = 0, timeout_handler = noop) =>
  (e) => {
    ++click;
    if (click >= click_take) {
      listener(e);
      click = 0;
    } else {
      clearTimeout(timeout_handler);
      timeout_handler = setTimeout(() => (click = 0), 300);
    }
  };

export const doRafEvent = (handler) => {
  let ticking = false;
  return (e) => {
    if (!ticking) {
      requestAnimationFrame(() => {
        handler(e);
        ticking = false;
      });
      ticking = true;
    }
  };
};

export const getAllInputValues = (sel) =>
  go($qsa(sel), (inputs$) =>
    reduce(
      (acc, input$) => {
        acc[input$.getAttribute('item')] = Number(input$.value);
        return acc;
      },
      {},
      inputs$,
    ),
  );

export const writeSpan = (item_name, span$, val) => {
  if (item_name === 'invert') {
    const text = val ? 'ON' : 'OFF';
    $setText(text, span$);
  } else {
    $setText(val, span$);
  }
};

export const selectedSwitch = (ct, parent_sel, item) =>
  go(
    ct,
    $closest(parent_sel),
    tap($findAll('.selected'), each($removeClass('selected'))),
    $findAll(`[item="${item}"]`),
    each($addClass('selected')),
  );

export const changeShiftIconsDegree = (shift_els, deg) => {
  go(shift_els, each($setCss({ transform: `rotate(${deg * 100}deg)` })));
};

export const mobileEvt = (event) => {
  if (!G.is_pc_size()) {
    if (event === 'mousemove') return 'touchmove';
    if (event === 'mousedown') return 'touchstart';
    if (event === 'mouseup') return 'touchend';
  }
  return event;
};

export const easterEggListener = () => {
  const adj_values = getAllInputValues('.adjustment_item_sliders input');
  const output = JSON.stringify(adj_values);
  const tempElem = document.createElement('textarea');
  tempElem.value = output;
  document.body.appendChild(tempElem);
  tempElem.select();
  document.execCommand('copy');
  document.body.removeChild(tempElem);
  $.alert(`조정 값이 클립보드에 복사되었습니다.`);
};
