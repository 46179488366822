import axios from 'axios';
import { sel, delay, go, pipe, tap } from 'fxjs/es';
import { makeDfReviewListReplyHtml } from '../../../Review/List/S/tmpl.js';
import { UtilS } from '../../../Util/S/Function//module/UtilS.js';
import { makeDfReviewListAdminNoTranslateHtml, makeDfReviewListAdminPointHtml } from './tmpl.js';
import { makeApiUrl } from '../../../Util/S/Function/util.js';

export const defnReviewListAdminEvent = pipe(
  $.on2('click', '.admin_review > .points button.save', function (e) {
    const point = $.val(e.currentTarget);
    return go(
      $.put('/@api/review/point/' + window.box.sel(e.currentTarget).id, {
        point: point == 'input' ? $.val($.prev(e.currentTarget, 'input')) : point,
      }),
      function (r) {
        if (r.err) return $.alert('이미 포인트가 지급된 상태거나 이미 포인트를 사용하여 취소할수 없습니다.');
        $.html($.closest(e.currentTarget, '.points'), $.el(makeDfReviewListAdminPointHtml(r)));
      },
    );
  }),
  $.on('click', '.admin_review > .buttons button', (e) =>
    go(
      $.put('/@api/review/' + $.attr(e.currentTarget, 'attr') + '/' + box.sel(e.currentTarget).id),
      function (r) {
        window.box.extend2(e.currentTarget, r);
        $.attr(e.currentTarget, r);
      },
    ),
  ),
  $.on('change', '.is_main_check input', async (e) => {
    try {
      const put_url = makeApiUrl('/@api/review/is_main/:review_id', {
        review_id: box.sel(e.currentTarget).id,
      });
      const {
        data: { is_main },
      } = await axios.put(put_url);
      if (is_main != e.currentTarget.checked) $.alert('[오류]');
    } catch (e) {
      alert(e);
    }
  }),
  $.on('change', '.is_best input', async (e) => {
    try {
      const put_url = makeApiUrl('/@api/review/is_best/:review_id', {
        review_id: box.sel(e.currentTarget).id,
      });
      const {
        data: { is_best },
      } = await axios.put(put_url, {
        is_best: e.currentTarget.checked,
      });
      if (is_best != e.currentTarget.checked) $.alert('[오류]');
    } catch (e) {
      alert(e);
    }
  }),
  $.on('click', '.admin_review > .txt.reply button', (e) =>
    go(
      $.put('/@api/review/reply/' + box.sel(e.currentTarget).id, {
        reply: UtilS.trim($.val($.prev(e.currentTarget, 'textarea.reply'))),
      }),
      function (r) {
        window.box.extend2(e.currentTarget, r);
        go(
          e.currentTarget,
          $.closest('.review_item'),
          tap($.find1('.reply_wrap'), $.remove),
          $.find1('.sns_url_wrap'),
          $.after(makeDfReviewListReplyHtml(r)),
        );
      },
    ),
  ),
  $.on('click', '.translate  button', (e) => {
    const lang = $.attr(e.currentTarget, 'lang');
    const comment_ = 'comment_' + lang;
    go(
      $.closest(e.currentTarget, '.translate'),
      tap(
        (el) => {
          const put_url = makeApiUrl('/@api/review/comment_en/:review_id', {
            review_id: box.sel(e.currentTarget).id,
          });
          return $.put(put_url, {
            [comment_]: UtilS.trim($.val($.find1(el, 'textarea.' + comment_))),
          });
        },
        function (r) {
          window.box.extend2(e.currentTarget, r);
          $.html(
            $.find1($.closest(e.currentTarget, '.translate'), `.translate_comment_${lang} div`),
            makeDfReviewListAdminNoTranslateHtml(r['comment_' + lang]),
          );
        },
      ),
      $.css({ filter: 'blur(1px)' }),
      delay(500),
      $.css({ filter: '' }),
    );
  }),
  $.on('click', '.review_item .user_name', (e) => {
    const user_product_id = box.sel(e.currentTarget).user_product_id;
    if (!user_product_id)
      return go($.alert('데이터 오류입니다. 고객 전체 주문내역으로 보여드립니다.'), () =>
        window.open(`/redirect/review/up_id/${null}?review_id=${box.sel(e.currentTarget).id}`, '_blank'),
      );
    window.open(`/redirect/review/up_id/${user_product_id}`, '_blank');
  }),

  $.on('click', '.review-delete', async (e) => {
    const { id, is_me } = box.sel(e.currentTarget);
    if (!is_me || !(await $.confirm(T('삭제 하시겠습니까?')))) return;
    try {
      $.don_loader_start();
      await axios.delete(`/@api/review`, { data: { id } });
      location.reload();
    } catch (e) {
      $.alert(sel('response.data.message', e) || '삭제가 불가능합니다.');
    }
    $.don_loader_end();

    e.stopPropagation();
  }),
);
