import { defaults, go } from 'fxjs/es';
import { MAKER_STROKE_WIDTH, plusStrokeWidth } from '../../../../Maker/F/Fcanvas/stroke.js';

export const create = function (safety) {
  return go(
    new window.fabric.Rect(
      defaults(
        {
          selectable: false,
          evented: false,
          visible: false,
          stroke: '#3cc16c',
          rx: safety.px.rx || 0,
          ry: safety.px.ry || 0,
          strokeDashArray: [6, 3],
          fill: 'rgb(0, 0, 0, 0)',
          strokeWidth: MAKER_STROKE_WIDTH,
          _data: {
            cv_type: 'cv_safety_area',
            is_not_design: true,
          },
        },
        plusStrokeWidth(safety.px),
      ),
    ),
    G.mp.maker.nscreen,
  );
};
