import { html, sel, strMap } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { makeDfProjectionListFilterHeader } from '../../../../Projection/List/S/tmpl.js';
import { DfTaskTmplS } from '../../../S/Tmpl/module/DfTaskTmplS.js';

export const productTypeInfo = (states) => html`
  <div class="s_col query" name="bp_type_info">
    <select name="bp_type_info">
      <option>포함(기본)</option>
      <option value="not" ${states.bp_type_info == 'not' ? 'selected' : ''}>없는</option>
      <option value="only" ${states.bp_type_info == 'only' ? 'selected' : ''}>만 있는</option>
      <option value="more" ${states.bp_type_info == 'more' ? 'selected' : ''}>있고 다른상품도 있는</option>
    </select>
  </div>
`;

export const template = __(
  window.box,
  ({ task_list, state_data, states, is_user }, { product_mode = 'up_list' } = states) => pug`
        .task_list_container[_sel="task_list" prj_product_mode="${product_mode}"]
          .nav_container
            nav
              a.ready_on[href='/task/${state_data.href}?order_by=${
    states.is_my_list ? 'ASC' : 'DESC'
  }&status=ready_on${states.task_title ? `&task_title=${states.task_title}` : ''}' class="${
    state_data.status == 'ready_on' ? 'selected' : ''
  }"] 진행전 / 진행중 태스크 ${states.is_my_list ? `(총 ${state_data.counts.ready_on})` : ''}
              a.today_complete[href='/task/${state_data.href}?order_by=DESC&status=today_complete${
    states.task_title ? `&task_title=${states.task_title}` : ''
  }' class="${state_data.status == 'today_complete' ? 'selected' : ''}"] 오늘 완료된 태스크 ${
    states.is_my_list ? `(총 ${state_data.counts.today_complete})` : ''
  }           
              a.completed[href='/task/${state_data.href}?order_by=DESC&status=completed${
    states.task_title ? `&task_title=${states.task_title}` : ''
  }' class="${state_data.status == 'completed' ? 'selected' : ''}"] 완료된 태스크
              ${
                states.is_my_list
                  ? pug`                    
                    a.canceled[href='/task/${state_data.href}?order_by=DESC&status=canceled${
                      states.task_title ? `&task_title=${states.task_title}` : ''
                    }' class="${state_data.status == 'canceled' ? 'selected' : ''}"] 취소된 태스크`
                  : ''
              }
            .bar
          #filter
            .title
              h1 ${states.is_my_list ? '내 태스크' : '모든 태스크'}
            .search_bar
              form#search_bar[action="/task/list" method="get"]
                input.query[type=hidden name=status value="${UtilS.escape(states.status)}"]
                input.query[type=hidden name=is_my_list value="${UtilS.escape(states.is_my_list)}"]
                ${makeDfProjectionListFilterHeader(states)}                  
                .search_projection_filter.sd
                  .s_col.title 주문서 상태
                  .s_col.search_prj_status.query.radio[name=prj_status]
                    .s_col
                      label
                        input[value="" type=radio name=prj_status ${!states.prj_status ? 'checked' : ''}]
                        sp 모든 주문서
                    .s_col
                      label
                        input[value=ing type=radio name=prj_status ${
                          states.prj_status == 'ing' ? 'checked' : ''
                        }]
                        sp 작업중+오늘출고
                    .s_col
                      label
                        input[value=before_print type=radio name=prj_status ${
                          states.prj_status == 'before_print' ? 'checked' : ''
                        }]
                        sp 제작준비중
                    .s_col
                      label
                        input[value=printing type=radio name=prj_status ${
                          states.prj_status == 'printing' ? 'checked' : ''
                        }]
                        sp 제작중
                    .s_col
                      label
                        input[value=before_deliver type=radio name=prj_status ${
                          states.prj_status == 'before_deliver' ? 'checked' : ''
                        }]
                        sp 제작완료
                    .s_col
                      label
                        input[value=delivering type=radio name=prj_status ${
                          states.prj_status == 'delivering' ? 'checked' : ''
                        }]
                        sp 배송중
                    .s_col
                      label
                        input[value=delivering_today type=radio name=prj_status ${
                          states.prj_status == 'delivering_today' ? 'checked' : ''
                        }]
                        sp 오늘출고된
                    .s_col
                      label
                        input[value=before_confirm type=radio name=prj_status ${
                          states.prj_status == 'before_confirm' ? 'checked' : ''
                        }]
                        sp 배송완료
                    .s_col
                      label
                        input[value=confirmed type=radio name=prj_status ${
                          states.prj_status == 'confirmed' ? 'checked' : ''
                        }]
                        sp 구매완료
                  .s_col.title 주문서 설정
                  .s_col
                    label
                      input.query[type=checkbox name=important ${states.important ? 'checked' : ''}]
                      sp 긴급출고-고객요청
                  .s_col
                    label
                      input.query[type=checkbox name=internal_important ${
                        states.internal_important ? 'checked' : ''
                      }]
                      sp 긴급출고-내부전용                
                .sd
                  .s_col.title 취소 주문서
                  .s_col.query.radio[name=is_cancel]
                    .s_col
                      label
                        input[value="" type=radio name=is_cancel ${!states.is_cancel ? 'checked' : ''}]
                        sp 선택안됨
                    .s_col
                      label
                        input[value="none" type=radio name=is_cancel ${
                          states.is_cancel == 'none' ? 'checked' : ''
                        }]
                        sp 취소 안됨
                    .s_col
                      label
                        input[value=is_cancel type=radio name=is_cancel ${
                          states.is_cancel == 'is_cancel' ? 'checked' : ''
                        }]
                        sp 취소요청+취소 완료
                    .s_col
                      label
                        input[value=is_cancel_req type=radio name=is_cancel ${
                          states.is_cancel == 'is_cancel_req' ? 'checked' : ''
                        }]
                        sp 취소요청만
                    .s_col
                      label
                        input[value=is_canceled type=radio name=is_cancel ${
                          states.is_cancel == 'is_canceled' ? 'checked' : ''
                        }]
                        sp 취소 완료만
                .sd
                  .s_col.title[value="${states.shipping_type}"] 배송 방식
                  ${html`
                    <div class="s_col">
                      <label
                        ><input
                          class="query arr"
                          type="checkbox"
                          name="shipping_types"
                          value="parcel"
                          ${states.shipping_types?.indexOf('parcel') > -1 ? 'checked' : ''}
                        /><span>택배</span></label
                      >
                    </div>
                    <div class="s_col">
                      <label
                        >[<input
                          class="query arr"
                          type="checkbox"
                          name="shipping_types"
                          value="combined"
                          ${states.shipping_types?.indexOf('combined') > -1 ? 'checked' : ''}
                        /><span>묶음배송</span></label
                      >
                    </div>
                    <div class="s_col">
                      <label
                        >-<input
                          class="query arr"
                          type="checkbox"
                          name="only_parent"
                          value="true"
                          ${states.only_parent > -1 ? 'checked' : ''}
                        /><span>(부모주문서만)]</span></label
                      >
                    </div>
                    <div class="s_col">
                      <label
                        ><input
                          class="query arr"
                          type="checkbox"
                          name="shipping_types"
                          value="partition"
                          ${states.shipping_types?.indexOf('partition') > -1 ? 'checked' : ''}
                        /><span>개별배송</span></label
                      >
                    </div>
                    <div class="s_col">
                      <label
                        ><input
                          class="query arr"
                          type="checkbox"
                          name="shipping_types"
                          value="quick"
                          ${states.shipping_types?.indexOf('quick') > -1 ? 'checked' : ''}
                        /><span>퀵</span></label
                      >
                    </div>
                    <div class="s_col">
                      <label
                        ><input
                          class="query arr"
                          type="checkbox"
                          name="shipping_types"
                          value="visit"
                          ${states.shipping_types?.indexOf('visit') > -1 ? 'checked' : ''}
                        /><span>방문</span></label
                      >
                    </div>
                  `}
                .search_date.sd
                  .s_col.title 날짜 및 정렬
                  .s_col
                    label
                      select.query[name="at_opt"]
                        option[value="pj.due_at"  ${states.at_opt == 'pj.due_at' ? 'selected' : ''}] 출고일 
                        option[value="pj.ordered_at"  ${
                          states.at_opt == 'pj.ordered_at' ? 'selected' : ''
                        }] 주문일 
                        option[value="t.created_at" ${
                          states.at_opt == 't.created_at' ? 'selected' : ''
                        }] 태스크 생성일 
                        option[value="t.completed_at" ${
                          states.at_opt == 't.completed_at' ? 'selected' : ''
                        }] 태스크 완료일
                        option[value="t.due_at" ${states.at_opt == 't.due_at' ? 'selected' : ''}] 태스크 기한
                      span 기준
                  .s_col.search_order_by.query.radio[name=order_by]
                    .s_col
                      label
                        input[value=ASC type=radio name=order_by ${states.order_by == 'ASC' ? 'checked' : ''}]
                        span 오름차순
                    .s_col
                      label
                        input[value=DESC type=radio name=order_by ${
                          states.order_by == 'DESC' ? 'checked' : ''
                        }]
                        span 내림차순
                    .s_col                      
                      .s_col
                        input.query.due_at[readonly type=text name=s_at value="${UtilS.escape(
                          states.s_at,
                        )}" placeholder="날짜를 선택해 주세요."] 
                        span ~
                        input.query.due_at[readonly type=text name=e_at value="${UtilS.escape(
                          states.e_at,
                        )}" placeholder="날짜를 선택해 주세요."]
                ${html`
                  <div class="search_text sd">
                    <div class="s_col title">태스크검색</div>
                    <div class="s_col">
                      <div class="s_col">
                        <input
                          type="text"
                          class="query"
                          name="task_title"
                          placeholder="태스크 작업명"
                          value="${UtilS.escape(states.task_title)}"
                        />
                      </div>
                      <div class="s_col">
                        <input
                          type="text"
                          class="query"
                          name="worker_info"
                          placeholder="작업자 이름 또는 ID"
                          value="${UtilS.escape(states.worker_info)}"
                        />
                      </div>
                      <div class="s_col">
                        <input
                          type="text"
                          class="query"
                          name="task_id"
                          placeholder="태스크 ID"
                          value="${UtilS.escape(states.task_id)}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="sd">
                    <div class="s_col title">마플샵 검색</div>
                    <div class="s_col">
                      <input
                        type="text"
                        name="store_name"
                        placeholder="스토어 이름"
                        value="${UtilS.escape(states.store_name) || ''}"
                        class="query"
                      />
                    </div>
                    <div class="s_col">
                      <input
                        type="text"
                        name="product_name"
                        placeholder="마플샵 상품 이름"
                        value="${UtilS.escape(states.product_name) || ''}"
                        class="query"
                      />
                    </div>
                    <div class="s_col query">
                      <select name="product_name_only" class="query">
                        <option value="">포함(기본)</option>
                        <option value="only" ${states.product_name_only == 'only' ? 'selected' : ''}>
                          만 있는
                        </option>
                        <option value="not" ${states.product_name_only == 'not' ? 'selected' : ''}>
                          가 없는
                        </option>
                      </select>
                    </div>
                    <div class="s_col title">숲토어 주문번호</div>
                    <div class="s_col">
                      <input
                        style="width: 300px;"
                        type="text"
                        name="afreeca_no"
                        placeholder="숲토어 주문번호/상품주문번호/옵션주문번호"
                        value="${states.afreeca_no || ''}"
                        class="query"
                      />
                    </div>
                  </div>
                  <div class="search_text sd">
                    <div class="s_col title">주문서 검색</div>
                    <div class="s_col">
                      <div class="s_col">
                        <input
                          type="text"
                          name="p_id"
                          placeholder="주문서 ID"
                          value="${UtilS.escape(states.p_id)}"
                          class="query"
                        />
                      </div>
                      <div class="s_col">
                        <input
                          type="text"
                          name="merged_parent_ids"
                          placeholder="병합주문서 ID"
                          value="${UtilS.escape(states.merged_parent_ids)}"
                          class="query"
                        />
                      </div>
                      <div class="s_col">
                        <input
                          type="text"
                          name="o_p_id"
                          placeholder="구 주문서 ID"
                          value="${UtilS.escape(states.o_p_id)}"
                          class="query"
                        />
                      </div>
                      <div class="s_col title">주문수량</div>
                      <div class="s_col">
                        <input
                          type="number"
                          name="prj_quantity"
                          placeholder="주문수량"
                          value="${states.prj_quantity || ''}"
                          class="query"
                        />
                      </div>
                      <div class="s_col title" style="margin-right: 4px;">재제작 여부</div>
                      <div class="s_col">
                        <label>
                          <input
                            value="true"
                            type="checkbox"
                            name="is_repress"
                            class="query"
                            ${states.is_repress ? 'checked' : ''}
                          />
                          <span>재제작주문만</span>
                        </label>
                      </div>
                      <div class="s_col title" style="margin-right: 4px;">고객요청</div>
                      <div class="s_col">
                        <label>
                          <label>
                            <input
                              value="true"
                              type="checkbox"
                              name="special_request"
                              class="query"
                              ${states.special_request ? 'checked' : ''}
                            />
                            <span>고객요청만</span>
                          </label>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="search_text sd">
                    <div class="s_col title">기본 상품 검색</div>
                    <div class="s_col">
                      <div class="s_col">
                        <input
                          type="text"
                          name="bp_name_info"
                          placeholder="기본 상품 이름"
                          value="${UtilS.escape(states.bp_name_info) || ''}"
                          class="query"
                        />
                      </div>
                      <div class="s_col">
                        <input
                          type="text"
                          name="bp_color_info"
                          placeholder="기본 상품 색상"
                          value="${UtilS.escape(states.bp_color_info) || ''}"
                          class="query"
                        />
                      </div>
                    </div>
                    ${productTypeInfo(states)}
                  </div>
                `}
                
                ${
                  sel('_.policies.outsourcing_worker_policy', is_user)
                    ? ''
                    : pug`
                .sd
                  .s_col.title 금액검색
                  .s_col
                    input.query[type=number name=price placeholder="가격" value='${
                      UtilS.escape(states.price) || ''
                    }']
                  .s_col -
                  .s_col 주문금액, 포인트사용, 쿠폰사용, 추가금, 추가배송비, 결제한금액, 미수금, 환불한 총액 등 ...
                `
                }
                ${
                  states.status == 'ready_on'
                    ? pug`
                .search_due_at.important.sd
                  .s_col.title 긴급 출고일 (태스크, 상품수)
                  .tr
                    .s_col.dates
                      button.get_dates[type="button" data-type="imp_prj"] 가져오기
                .search_due_at.sd
                  .s_col.title 모든 출고일 (태스크, 상품수)
                  .tr
                    .s_col.dates
                      button.get_dates[type="button" data-type="prj"] 가져오기
                .search_due_at.sd
                  .s_col.title 태스크 기한 (태스크, 상품수)
                  .tr
                    .s_col.dates
                      button.get_dates[type="button" data-type="task"] 가져오기`
                    : ''
                }
                
                ${
                  states.due_ats
                    ? pug`
                .search_due_at.sd
                  .tr
                    .s_col.title 체크포인트 전용 출고일
                    .s_col.dates
                      ${strMap(
                        (due_at) => pug`
                      .s_col.due_at ${due_at}`,
                        states.due_ats.split(','),
                      )}
                      input.query[type=hidden name=due_ats value="${UtilS.escape(states.due_ats)}"]
                      div (체크포인트에서 지정되어있던 출고일입니다. 지우시려면 초기화해서 사용하여주세요.) 
                `
                    : ''
                }
                .sd
                  .s_col.title 태스크 진행 상태
                  .s_col
                    select.query[name="status2"]
                      option[value=""] 진행전,진행중
                      option[value="ready" ${states.status2 == 'ready' ? 'selected' : ''}] 진행전
                      option[value="on" ${states.status2 == 'on' ? 'selected' : ''}] 진행중
                ${
                  states.checkpoint_ats?.length
                    ? html`
                        <div class="sd">
                          <div class="s_col title">체크포인트 선택날짜(검색을 누르면 미적용)</div>
                          <div class="s_col">${states.checkpoint_ats.join(' / ')}</div>
                        </div>
                      `
                    : ''
                }
                .search_result.search_btn
                  button.init[type="button"] 초기화
                  button.go[type=submit] 검색
          .prj_task_pagination
            ${G.df.pagination(states)}
          #mode_selector
            .check_bp_stock_option[style="display:none;"]
              input#check_bp_stock_all.check_all[type="checkbox"]
              label[for="check_bp_stock_all"] 재고 전체 선택
              button.btn_add_order 기존 발주에 추가 
              button.btn_new_order 새로운 발주
              button.btn_bp_stock_confirm_exeldown 재고 확인 및 엑셀 다운로드
              button.btn_transfer_product_empty_stock (재고팀 외 품목)재고/라벨부착 일괄완료
              button.btn_all_bp_stock_confirm_exeldown[style="background-color: #0175ff; color: white;"] 전체 재고확인 및 엑셀 다운
              button.btn_today_bp_stock_confirm_exeldown[style="background-color: purple; color: white;"] 오늘 재고확인 엑셀 다운
            select.prj_product_mode.prj_list.query[name="product_mode"]
              option[value="up_list" ${product_mode == 'up_list' ? 'selected' : ''}] 기본 모드
              option[value="bp_stocks" ${product_mode == 'bp_stocks' ? 'selected' : ''}] 상품 / 재고 모드
            form[method="POST" target="_blank" action="/@api/df/bp_stock_confirm_excel_download" style="display: none;"]
              input[type="hidden" name="task_ids" value=""]
              input[type="hidden" name="projection_ids" value=""]
              input[type="hidden" name="bp_stocks" value=""]
              button[type="submit" style="margin-left: 10px;"].submit_bp_stock_confirm_exeldown 다운받기
          .image_work_wrapper
          .task_list 
            ${_p.sum(task_list, DfTaskTmplS.t_task_item)}
          .prj_task_pagination
            ${G.df.pagination(states)}
          `,
);
