import i18next from 'i18next';
import omp_translator from '../../../../../../modules/OMP/Core/Translation/omp_i18next.config.js';
import { UtilPdfF } from '../../../../../../modules/Util/Pdf/F/Function/module/UtilPdfF.js';
let _T = null;
const _TPromise = new Promise(function (resolve) {
  i18next.changeLanguage(ENV.lang).then(function (T) {
    _T = T;
    window.lang = i18next.language;
    resolve();
  });
});

window.T = function (a) {
  const args = arguments;
  if (_T) return _T.apply(null, args);
  return _TPromise.then(function () {
    return _T.apply(null, args);
  });
};

window.T.lang = 'kr';

// Graphic Magick 의 동작 여부 상태 확인
window.hello_gm = UtilPdfF.callGraphicMagickStatus;

let _TT = null;
const _TTPromise = new Promise(function (resolve) {
  omp_translator.changeLanguage(ENV.lang).then(function (TT) {
    _TT = TT;
    window.lang = omp_translator.language;
    resolve();
  });
});
window.TT = function (a) {
  const args = arguments;
  if (_TT) return _TT.apply(null, args);
  return _TTPromise.then(function () {
    return _TT.apply(null, args);
  });
};
