import { html, go, strMap, values } from 'fxjs/es';
export const main = ({ banks = [], info, button_title }) => {
  const selected_bank = info?.bank_code && banks[info?.bank_code];
  const selected_branch = info.branch_code && selected_bank && selected_bank.branches[info.branch_code];
  return html` <div class="page_body">
    <div class="section payment_type_container">
      <div class="section_header">
        <div class="txt">${T('refund_bank::01')}</div>
      </div>
      <div class="card_body">
        <form>
          <div class="bank_info">
            <select name="bank_code">
              ${!selected_bank ? html` <option value="" selected>-</option> ` : html``}
              ${go(
                banks,
                values,
                strMap(
                  ({ code, name }) => html`
                    <option value="${code}" ${selected_bank?.code == code ? 'selected' : ''}>${name}</option>
                  `,
                ),
              )}
            </select>
            <select name="branch_code">
              ${!selected_branch ? html` <option value="" selected>-</option> ` : html``}
              ${go(
                selected_bank?.branches,
                values,
                strMap(({ code, name }) => {
                  return html`
                    <option value="${code}" ${selected_branch?.code === code ? 'selected' : ''}>
                      ${name}
                    </option>
                  `;
                }),
              )}
            </select>
            <input
              type="text"
              class="width366"
              name="bank_account"
              placeholder="${T('refund_bank::02')}"
              value="${info.bank_account || ''}"
            />
            <input
              type="text"
              class="width366"
              placeholder="${T('refund_bank::03')}"
              name="bank_user_name"
              value="${info.bank_user_name || ''}"
            />
          </div>
          <div class="options">
            <button type="button" class="submit">${button_title}</button>
          </div>
        </form>
      </div>
    </div>
  </div>`;
};
