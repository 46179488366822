import { each, every, go } from 'fxjs/es';
import { $closest, $delegate, $find, $findAll, $setText } from 'fxdom/es';
import { SVGEditorAttrS } from '../../../Attr/S/Function/module/SVGEditorAttrS.js';

export const init = () => {};

export const viewToSvgUnit = (num) => {
  return parseFloat((num * 0.1).toFixed(1));
};
export const svgToViewUnit = (num) => {
  return parseInt(num * 10);
};

function setAllVal({ target_v, wrapper_el, svg_el, mp_sticker_editor }) {
  go(
    $findAll('.control_box')(wrapper_el),
    each((el) => {
      const key = el.dataset.key;
      SVGEditorAttrS.set({ [key]: target_v }, svg_el, mp_sticker_editor);
      go(el, $find('.number'), $setText(svgToViewUnit(SVGEditorAttrS.get(key, svg_el))));
    }),
  );
}
function setValByOperator({ control_box_els, step, svg_el, mp_sticker_editor }) {
  go(
    control_box_els,
    each((el) => {
      const key = el.dataset.key;
      const current_v = SVGEditorAttrS.get(key, svg_el);
      SVGEditorAttrS.set(
        {
          [key]: Math.max(parseFloat((current_v + parseFloat(step)).toFixed(1)), 0),
        },
        svg_el,
        mp_sticker_editor,
      );
      go(el, $find('.number'), $setText(svgToViewUnit(SVGEditorAttrS.get(key, svg_el))));
    }),
  );
}
export const event = ({ target_el, svg_el, mp_sticker_editor }) => {
  const wrapper_el = go(target_el, $find('.svg_editor_stroke_dash_wrapper'));
  go(
    target_el,
    $delegate('click', '.svg_editor_stroke_dash_wrapper .title span', (e) => {
      if (wrapper_el.dataset.has_dash_stroke === 'true') {
        wrapper_el.dataset.has_dash_stroke = 'false';
        setAllVal({ target_v: 0, wrapper_el, svg_el });
      } else {
        wrapper_el.dataset.has_dash_stroke = 'true';
        setAllVal({ target_v: 0.1, wrapper_el, svg_el });
      }
    }),
    $delegate('click', '.svg_editor_stroke_dash_wrapper .minus', (e) => {
      const { is_locked } = wrapper_el.dataset;
      const control_box_els =
        is_locked === 'true'
          ? $findAll('.control_box')(wrapper_el)
          : [$closest('.control_box')(e.currentTarget)];
      const step = parseFloat(e.currentTarget.dataset.step);
      setValByOperator({ control_box_els, step, svg_el, mp_sticker_editor });
      go(
        $findAll('.control_box')(wrapper_el),
        every((el) => {
          const key = el.dataset.key;
          return SVGEditorAttrS.get(key, svg_el) === 0;
        }),
        (bool) => {
          wrapper_el.dataset.has_dash_stroke = String(!bool);
        },
      );
    }),
    $delegate('click', '.svg_editor_stroke_dash_wrapper .plus', (e) => {
      const { is_locked } = wrapper_el.dataset;
      wrapper_el.dataset.has_dash_stroke = 'true';
      const control_box_els =
        is_locked === 'true'
          ? $findAll('.control_box')(wrapper_el)
          : [$closest('.control_box')(e.currentTarget)];
      const step = parseFloat(e.currentTarget.dataset.step);
      setValByOperator({ control_box_els, step, svg_el, mp_sticker_editor });
    }),
    $delegate('click', '.svg_editor_stroke_dash_wrapper .lock', (e) => {
      const { has_dash_stroke, is_locked } = wrapper_el.dataset;
      if (has_dash_stroke === 'false') return;
      if (is_locked === 'true') {
        wrapper_el.dataset.is_locked = 'false';
      } else {
        wrapper_el.dataset.is_locked = 'true';
        const target_key = go(wrapper_el, $find('.control_box'), (el) => el.dataset.key);
        const target_v = SVGEditorAttrS.get(target_key, svg_el);
        setAllVal({ target_v, wrapper_el, svg_el });
      }
    }),
  );
};
