import {
  changeStyleOffHyperlink,
  changeStyleOnHyperlink,
  convertDateToHumanize,
  convertUTCtoHyphenDate,
  dateFormatting,
  dayFormatting,
  getDateObjFromToday,
  isTimeInRange,
} from '../Function/utils.js';
import { press_type_names } from './naming.js';
import { svg_icons } from '../Tmpl/svg_icons.js';
import { Korean } from 'flatpickr/dist/l10n/ko.js';

export const aztec_codes_info = ['projection_id', 'label_id', 'label_ctn'];

export const label_date_picker_date_format = 'Y-m-d H:i';

export const before_print_time_info = {
  label_retrieve_stock_confirm_time_cycle: '08:50',
  stock_confirm_started_at: '08:50',
  stock_confirm_ended_at: '13:30',
};

export const default_label_date_hyphen = {
  start: `${convertUTCtoHyphenDate(getDateObjFromToday(-1))} ${
    before_print_time_info.label_retrieve_stock_confirm_time_cycle
  }`,
  end: `${convertUTCtoHyphenDate(getDateObjFromToday(0))} ${
    before_print_time_info.label_retrieve_stock_confirm_time_cycle
  }`,
};

const lookUpProjectionId = (data, filterParmas) => {
  const projectionIds = data.replaceAll(' ', '').split(',');
  return projectionIds.includes('' + filterParmas);
};

export const date_picker_options = {
  altInput: true,
  weekNumbers: true,
  enableTime: true,
  altFormat: 'Y년 F j h:i',
  dateFormat: label_date_picker_date_format,
  time_24hr: true,
  locale: Korean,
  wrap: true,
  formatDate: (date, format, locale) => {
    return convertDateToHumanize(date);
  },
};

export const label_table_columns = [
  {
    title: '번호',
    field: 'no',
    minWidth: '60px',
    headerFilter: false,
    formatter: (cell, formatterParams, onRendered) => {
      if (cell.getData()?.search_mode === 'single') {
        const cell_el = cell.getElement();
        !cell_el.classList.contains('blink') && cell_el.classList.add('blink');
      }
      return cell.getValue();
    },
    visible: true,
    download: true,
  },
  {
    title: '출력',
    field: 'printed_at',
    mutator: (value, data, type, params, component) => {
      const val = data.printed_at;
      if (typeof val === 'boolean') {
        return val;
      }
      const len = val?.length;
      if (len > 0) {
        return true;
      } else if (len === 0) {
        return false;
      }
    },
    formatter: 'tickCross',
    formatterParams: {
      allowEmpty: true,
      allowTruthy: true,
    },
    headerFilter: 'tickCross',
    headerFilterParams: { tristate: true },
    hozAlign: 'center',
    headerTooltip: '이전에 라벨이 프린트 된 적이 있는지 여부를 나타냅니다.',
    visible: true,
    download: false,
  },
  {
    title: '취소',
    field: 'is_cancel_requested',
    editor: true,
    formatter: 'tickCross',
    formatterParams: {
      allowEmpty: true,
      allowTruthy: false,
      tickElement: svg_icons.exclaim,
      crossElement: svg_icons.green_hyphen,
    },
    //True: good (괜찮다, 취소 안된 거다.)   False: bad (나쁘다, 취소 된 거다.)
    headerFilter: 'tickCross',
    headerFilterParams: { tristate: true },
    hozAlign: 'center',
    headerTooltip: '빨강(취소된 주문) 초록(취소 아님)',
    visible: true,
    download: true,
  },
  {
    title: '확인',
    field: 'is_check',
    editor: true,
    formatter: 'tickCross',
    formatterParams: {
      allowEmpty: true,
      allowTruthy: true,
      tickElement: svg_icons.exclaim,
      crossElement: svg_icons.green_hyphen,
    },
    hozAlign: 'center',
    headerFilter: 'tickCross',
    headerFilterParams: { tristate: true },
    //True: good (괜찮다, 체크 안해도 된다.)   False: bad (나쁘다, 체크 해야 한다.)
    mutator: (value, data, type, params, component) => {
      if (data.stock_confirmed_at) {
        return !isTimeInRange(
          data.stock_confirmed_at,
          before_print_time_info.stock_confirm_started_at,
          before_print_time_info.stock_confirm_ended_at,
        );
      }
    },
    headerTooltip: '빨강(주문 확인 필요) 초록(확인 필요 없음)',
    visible: true,
    download: true,
  },
  {
    title: '긴급',
    field: 'is_urgent',
    formatter: 'tickCross',
    editor: true,
    formatterParams: {
      allowEmpty: true,
      allowTruthy: false,
      tickElement: svg_icons.exclaim,
      crossElement: svg_icons.green_hyphen,
    },
    hozAlign: 'center',
    headerFilter: 'tickCross',
    headerFilterParams: { tristate: true },
    headerTooltip: '빨강(긴급건) 초록(일반건)',
    visible: true,
    download: true,
  },
  {
    title: '내부긴급',
    field: 'is_internal_urgent',
    editor: true,
    formatter: 'tickCross',
    formatterParams: {
      allowEmpty: true,
      allowTruthy: false,
      tickElement: svg_icons.exclaim_warning,
      crossElement: svg_icons.green_hyphen,
    },
    hozAlign: 'center',
    headerFilter: 'tickCross',
    headerFilterParams: { tristate: true },
    headerTooltip: '빨강(내부 긴급건) 초록(일반건)',
    visible: true,
    download: true,
  },
  {
    title: '재제작',
    field: 'is_repress',
    editor: true,
    formatter: 'tickCross',
    formatterParams: {
      allowEmpty: true,
      allowTruthy: false,
      tickElement: svg_icons.repress,
      crossElement: svg_icons.green_hyphen,
    },
    hozAlign: 'center',
    headerFilter: 'tickCross',
    headerFilterParams: { tristate: true },
    headerTooltip: '빨강(재제작) 초록(일반 제작)',
    visible: true,
    download: true,
  },
  {
    title: '주문 ID',
    field: 'projection_id',
    headerFilter: true,
    headerFilterPlaceholder: '# 번호',
    headerFilterFunc: lookUpProjectionId,
    width: 83,
    cellClick: function (e, cell) {
      const prj_id = cell._cell.value;
      prj_id &&
        window.open(
          `/projection/list?status=all&order_by=DESC&at_opt=ordered&p_id=${prj_id}&product_mode=up_list`,
          '_blank',
        );
    },
    cellMouseOver: changeStyleOnHyperlink,
    cellMouseLeave: changeStyleOffHyperlink,
    formatter: (cell, formatterParams, onRendered) => {
      const val = cell.getValue();
      if (val && cell.getData().is_cancel_requested) {
        cell.getElement().classList.add('cancel');
      }
      return val;
    },
    hozAlign: 'center',
    visible: true,
    download: true,
  },
  {
    title: '출고',
    field: 'due_at',
    formatter: dayFormatting,
    hozAlign: 'center',
    width: 50,
    visible: true,
    download: true,
  },
  {
    title: '배송',
    field: 'shipping_type',
    hozAlign: 'center',
    headerFilter: 'select',
    headerFilterParams: { values: true },
    headerFilterPlaceholder: '방식',
    width: 65,
    visible: true,
    download: true,
  },
  {
    title: '발주상태',
    field: 'confirm_task_status',
    formatter: function (cell, formatterParams, onRendered) {
      const val = cell.getValue();
      const el = cell.getElement();
      switch (val) {
        case 'ready': {
          return '진행전';
        }
        case 'on': {
          el.style.color = '#f4661e';
          return '진행중';
        }
        case 'completed': {
          el.style.color = '#39B54A';
          return '완료';
        }
        default:
          return val;
      }
    },
    hozAlign: 'center',
    visible: true,
    download: true,
  },
  {
    title: '발주일',
    field: 'stock_confirmed_at',
    formatter: dateFormatting,
    formatterParams: { is_color: true },
    sorterParams: {
      format: 'YY-MM-DD HH:mm',
      alignEmptyValues: 'bottom',
    },
    minWidth: 76,
    hozAlign: 'center',
    visible: true,
    download: true,
  },
  {
    title: '주문일',
    field: 'ordered_at',
    formatter: dateFormatting,
    hozAlign: 'center',
    minWidth: 76,
    visible: true,
    download: true,
  },
  {
    title: '주문 유형',
    field: 'order_category',
    hozAlign: 'center',
    headerFilter: 'select',
    headerFilterParams: { values: true },
    headerFilterPlaceholder: '유형',
    width: 80,
    visible: true,
    download: true,
  },
  {
    title: '패스트트랙',
    field: 'fast_track_outsourcing_is_show',
    formatter: 'tickCross',
    formatterParams: {
      allowEmpty: true,
      allowTruthy: true,
    },
    headerFilter: 'tickCross',
    headerFilterParams: { tristate: true },
    hozAlign: 'center',
    headerTooltip: '외주로 보낼 태스크를 단독으로 가진 주문 입니다.',
    visible: true,
    download: true,
  },
  {
    title: '제작 방식',
    field: 'press_type',
    editor: 'input',
    hozAlign: 'center',
    headerFilter: 'select',
    headerFilterParams: { values: true },
    headerFilterPlaceholder: '방식',
    mutator: (value, data, type, params, component) => {
      if (data.projection_id) {
        const press_type = data.press_type;
        const is_fast_track = data.fast_track_outsourcing_is_show;

        /* 패스트 트랙인 경우 HOLDING (외주 업체명) 을 괄호로 추가 표시 */
        if (press_type === '') {
          return (
            press_type_names.holding + (is_fast_track ? `(${data.fast_track_outsourcing_company_name})` : '')
          );
        } else {
          return press_type;
        }
      }
    },
    formatter: (cell, formatterParams, onRendered) => {
      const val = cell.getValue();
      if (val) {
        if (val === press_type_names.holding) {
          cell.getElement().style.color = '#f3722c';
        } else {
          cell.getElement().style.color = 'green';
        }
      }
      return cell.getValue();
    },
    width: 120,
    maxWidth: 130,
    visible: true,
    download: true,
  },
  {
    title: '주요 발주처',
    field: 'priority_supplier',
    hozAlign: 'center',
    headerFilter: 'select',
    headerFilterParams: { values: true },
    headerFilterPlaceholder: '발주처',
    visible: true,
    download: true,
  },
  {
    title: '총수량',
    field: 'qty_label',
    editor: 'input',
    hozAlign: 'center',
    // minWidth: '90px',
    // headerFilter: inEqualityFilterEditor,
    // headerFilterFunc: inEqualityFilterFunction,
    visible: true,
    download: true,
  },
  {
    //column group
    title: '주문 상세 제품 목록 (같은 품목끼리 합산)',
    columns: [
      {
        title: '번호',
        field: 'up_no',
        hozAlign: 'center',
        download: true,
      },
      {
        title: '발주처',
        field: 'supplier',
        hozAlign: 'center',
        minWidth: 80,
        download: true,
      },
      {
        title: 'SKU(업체)',
        field: 'sku_nickname',
        editor: 'input',
        mutator: (value) => {
          return value ?? '';
        },
        minWidth: 100,
        download: true,
      },
      {
        title: '제품명',
        field: 'bp_name',
        editor: 'input',
        mutator: G._unescape,
        minWidth: 220,
        maxWidth: 530,
        download: true,
      },
      {
        title: '색상',
        field: 'color',
        editor: 'input',
        minWidth: 83,
        download: true,
      },
      {
        title: '크기',
        field: 'size',
        editor: 'input',
        width: 96,
        download: true,
      },
      {
        title: '수량',
        field: 'qty_bp',
        editor: 'input',
        hozAlign: 'left',
        download: true,
      },
    ],
  },
];

export const label_table_options = (columns) => ({
  height: 680, // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
  layout: 'fitDataStretch', //fit columns to width of table (optional)
  dataTree: true,
  dataTreeFilter: false,
  dataTreeChildField: 'bp_c_ss',
  index: 'no',
  dataTreeStartExpanded: true,
  columns,
  tooltipsHeader: true,
  tooltipGenerationMode: 'hover',
  cellVertAlign: 'middle',
  headerSort: false,
  layoutColumnsOnNewData: true,
});
