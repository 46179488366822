import { sel } from 'fxjs/es';
import './frame.js';
import './page.js';
import { PriceS } from '../../../Price/S/Function/module/PriceS.js';

export const defnProductListRoute = () =>
  $.frame({
    // top: G.is_mobile_size() ? $.height($1('#header')) + 8 : 0,
    frame_name: 'mp.product.list',
    page_name: 'mp.product.list',
    appending: $.on('click', '.base_product_list .base_product > a', function (e) {
      // e.preventDefault();
      // TODO gtag
      try {
        if (typeof gtag == 'function') {
          const _bp = box.sel($.closest(e.$currentTarget, '.base_product'));
          gtag('event', 'select_content', {
            content_type: 'product',
            items: [
              {
                id: sel('id', _bp),
                name: sel('name', _bp),
                list_name: '상품 목록',
                // "brand": "Google",
                // "category": "Apparel/T-Shirts",
                // "variant": "Black",
                list_position: 1,
                quantity: 1,
                price: PriceS.add(sel('print_price', _bp), sel('price', _bp)),
              },
            ],
          });
        }
      } catch (err) {}
    }),
  });
