export function statusToKr(status) {
  let res;
  switch (status) {
    case 'ready':
      res = '진행전';
      break;
    case 'on':
      res = '진행중';
      break;
    case 'completed':
      res = '완료';
      break;
  }
  return res;
}
