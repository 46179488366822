export const validateImageSize = async (file) => {
  if (file.type === 'application/postscript') return { result: true };
  const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.addEventListener('error', () => {
      resolve();
    });
    file_reader.readAsDataURL(file);
  });
  if (!data_url) {
    return {
      message: T('maker::file_error'),
    };
  }
  const img_el = /** @type {HTMLImageElement} */ await new Promise((resolve, reject) => {
    const img_el = new Image();
    img_el.onload = () => resolve(img_el);
    img_el.onerror = () => {
      resolve();
    };
    img_el.src = data_url;
  });
  if (!img_el) {
    return {
      message: T('maker::file_error'),
    };
  }
  const min_img_size = 500;
  if (img_el.width < min_img_size && img_el.height < min_img_size) {
    return {
      message: T('maker::At least 500px'),
    };
  }
  const max_img_size = 8000;
  if (img_el.width > max_img_size || img_el.height > max_img_size) {
    return {
      message: T('maker::MAX 8000px'),
    };
  }
  if (file.size > 26214400)
    return {
      message: T('File size is too large', { mb: 25 }),
    };
  return {
    result: true,
  };
};
