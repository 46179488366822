import { each, go, mapL, noop, takeL } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { CommonNS } from '@marpple/sticker-editor';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';

export const handleInfiItemClick =
  ({ tab_el }) =>
  async ({ currentTarget: infi_item_el }) => {
    const postProcess = tab_el.__mp_postProcess ?? noop;
    $.don_loader_start();
    try {
      await go(
        infi_item_el,
        $findAll(`svg .target`),
        takeL(1),
        mapL(CommonNS.UtilNS.deepCloneNode),
        each(postProcess),
      );
    } finally {
      $.don_loader_end();
    }
    MuiF.closeFrame();
  };
