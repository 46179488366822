import { DfImageEditorMuiS } from '../../S/Mui/module/DfImageEditorMuiS.js';
import { DfImageEditorF } from '../Function/module/DfImageEditorF.js';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';
import { guard } from '../../S/guard.js';
import { PAPER_NAME } from '../../S/Constant/constants.js';
import { $find } from 'fxdom/es';
import { setDfProjectionListUpdateIsStop } from '../../../Projection/List/F/fs.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */

/*
 * 이미지 에디터 테스트 리소스
 * https://docs.google.com/spreadsheets/d/1lU2TiDSh33xrbBm95sFNaIyNC5bGoNBo8SVgO4D0MBM/edit#gid=0
 *
 */

export const tab = {
  ...DfImageEditorMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  async appending(tab_el) {
    setDfProjectionListUpdateIsStop(true);
    const { printable_file_info } = tab_el.tab_opt;
    const check = guard.checkPrintableFileInfoExist({ printable_file_info });
    if (check) DfImageEditorF.closeFrameWhenError({ msg: check });

    DfImageEditorLibF.initializeImageEditorStatus({ printable_file_info });
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  async appended(tab_el) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  async showed(tab_el) {
    const paper_scope = DfImageEditorF.getTabElPaperScope({ tab_el });
    const selected_tool = DfImageEditorLibF.getState.selected_tool();

    if (paper_scope && selected_tool) {
      await DfImageEditorF.activateTool({ tab_el, tool_name: selected_tool });
      window.addEventListener('resize', tab_el.tab_opt.viewResizeFn);
    }
    DfImageEditorF.focusBackToFrame({ tab_el });
  }, // tab 엘리먼트 show 하고 나서 울리는 함수
  async rendered(tab_el) {
    const design_source = DfImageEditorF.getPrintableDesign({ tab_el });
    const img_src = design_source.data_url;

    const check = guard.checkImgSrc({ img_src });
    if (check) DfImageEditorF.closeFrameWhenError({ msg: check });

    /* for debugging */
    const is_window_attach = false;

    const $work_canvas = DfImageEditorF.prepareCanvas({ tab_el });
    const paper_scope = DfImageEditorF.preparePaperSetup({ canvas_el: $work_canvas, is_window_attach });

    DfImageEditorF.setPaperScopeToTabEl({ tab_el, paper_scope, paper_name: PAPER_NAME.default });

    await DfImageEditorF.insertPrintableRaster({ paper_scope, img_src, is_window_attach });

    DfImageEditorF.toolView({ tab_el });
    DfImageEditorF.toolSelect({ tab_el });
    DfImageEditorF.toolBackground({ tab_el });
    DfImageEditorF.toolSpot({ tab_el });
    DfImageEditorF.toolScale({ tab_el });
    DfImageEditorF.toolErase({ tab_el });
    DfImageEditorF.toolVStroke({ tab_el });
    DfImageEditorF.toolRStroke({ tab_el });
    DfImageEditorF.toolFile({ tab_el });

    DfImageEditorF.buttonEvents({ tab_el });
    DfImageEditorF.brushControlEvent({ tab_el });

    DfImageEditorF.triggerSelectedTool({ tab_el });
    DfImageEditorF.focusBackToFrame({ tab_el });

    const $artboard = $find('.artboard', tab_el);

    /* view resize 조정 */
    const viewResizeFn = () => {
      const { width, height } = $artboard.getBoundingClientRect();
      DfImageEditorF.resizeAndRedrawCanvas({ paper_scope, width, height });
    };
    tab_el.tab_opt.viewResizeFn = viewResizeFn;
    window.addEventListener('resize', viewResizeFn);

    await DfImageEditorF.activateTool({ tab_el, tool_name: DfImageEditorLibF.getState.selected_tool() });
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {
    setDfProjectionListUpdateIsStop(false);
    const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
    paper_scope.tools.map((x) => x).forEach((tool) => tool.remove());

    window.removeEventListener('resize', tab_el.tab_opt.viewResizeFn);
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {
    DfImageEditorF.destroyAllPaperScope({ tab_el });
  }, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
