import { equals2, go, html, mapL, reduce, rejectL } from 'fxjs/es';
import { VectorEditorTopMenuPCTmplS } from '../../../../../TopMenu/PC/S/Tmpl/module/VectorEditorTopMenuPCTmplS.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';

const mui_ref_path = '/modules/VectorEditor/Keyring/Grid/CreatorPC/S/Mui/tab.js';
/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  title: '', // tab button의 title
  selected: false, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  makeData(tab) {}, // template 함수 인자에 들어갈 값, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
  template(_data) {
    return html`
      <div class="left_container">
        <div class="editor_container"></div>

        <div class="top_menu_container">
          ${VectorEditorTopMenuPCTmplS.makeTopMenusHtml({
            disabled_ids: go(
              Object.values(VectorEditorTopMenuPCConstantS.TOP_MENU),
              mapL(({ ID }) => ID),
              rejectL(equals2(VectorEditorTopMenuPCConstantS.TOP_MENU.TOGGLE_LAYERS_MODE.ID)),
              (ids) =>
                reduce(
                  (set, id) => {
                    set.add(id);
                    return set;
                  },
                  new Set(),
                  ids,
                ),
            ),
          })}
        </div>

        <div class="left_menu" data-icon_type="upload_image" data-is_show="true">
          <button type="button" class="icon"></button>
          <span class="label_text">
            ${T('modules::VectorEditor::Keyring::Grid::CreatorPC::template::menu_upload_image')}
          </span>
        </div>
        <div class="left_menu" data-icon_type="my_image" data-is_show="true">
          <button type="button" class="icon"></button>
          <span class="label_text">
            ${T('modules::VectorEditor::Keyring::Grid::CreatorPC::template::menu_my_image')}
          </span>
        </div>
        <div class="left_menu" data-icon_type="shape" data-is_show="true">
          <button type="button" class="icon"></button>
          <span class="label_text">
            ${T('modules::VectorEditor::Keyring::Grid::CreatorPC::template::menu_shape')}
          </span>
        </div>
        <div class="left_menu" data-icon_type="path" data-is_show="true">
          <button type="button" class="icon"></button>
          <span class="label_text">
            ${T('modules::VectorEditor::Keyring::Grid::CreatorPC::template::menu_path')}
          </span>
        </div>
        <div class="left_menu" data-icon_type="text" data-is_show="true">
          <button type="button" class="icon"></button>
          <span class="label_text">
            ${T('modules::VectorEditor::Keyring::Grid::CreatorPC::template::menu_text')}
          </span>
        </div>

        <div class="layer_container" data-is_show="false">
          <div class="header">
            <span class="title">
              ${T('modules::VectorEditor::Keyring::Grid::CreatorPC::template::layer_title')}
            </span>
            <button type="button" class="close_layer"></button>
          </div>
          <div class="list_container" data-count="0"></div>
        </div>
      </div>

      <div class="right_container" data-show_panel_wrapper="left" data-show_panel="home">
        <div class="right_wrapper">
          <div class="panel_container">
            <div class="panel_wrapper" data-panel_wrapper="left">
              <div class="inner_wrapper">
                <div class="right_panel_home">
                  <div class="header">
                    <span class="title"></span>
                  </div>
                </div>
              </div>
              <div class="button_container">
                <button type="button" class="cancel">
                  ${T('modules::VectorEditor::Keyring::Grid::CreatorPC::template::cancel_button')}
                </button>
                <button type="button" class="next">
                  ${T('modules::VectorEditor::Keyring::Grid::CreatorPC::template::next_button')}
                </button>
              </div>
            </div>
            <div class="panel_wrapper" data-panel_wrapper="right"></div>
          </div>
        </div>
      </div>
    `;
  }, // 탭에 들어갈 html 을 그리는 함수
};
