import { $addClass, $appendTo, $el, $find, $qs, $remove, $removeClass, $setText } from 'fxdom/es';
import { delay, go, html } from 'fxjs/es';

export const imageWorkPercentLoader = ({ message, time, clazz = '' }) => {
  const obj = { time, charged: '0%' };
  const el = go(
    html`
      <div class="process_time_loader ${clazz}">
        <div class="process_time_loader__container">
          <div class="process_time_loader__percent">${obj.charged}</div>
          <div class="process_time_loader__message">${message}</div>
        </div>
      </div>
    `,
    $el,
  );
  const percent_el = go(el, $appendTo($qs('body')), $find('.process_time_loader__percent'));
  let is_done = false;
  const loaderDone = async () => {
    is_done = true;
    $setText('100%')(percent_el);
    $removeClass('fixed')($qs('html'));
    $removeClass('fixed')($qs('body'));
    await delay(800, null);
    $remove(el);
  };
  $addClass('fixed')($qs('html'));
  $addClass('fixed')($qs('body'));
  const an = anime({
    targets: obj,
    charged: '100%',
    duration: time,
    round: 1,
    easing: 'linear',
    update: function () {
      if (is_done) {
        an.pause();
        return;
      }
      if (obj.charged === '100%') {
        an.pause();
        return;
      }
      $setText(obj.charged)(percent_el);
    },
  });
  return loaderDone;
};
