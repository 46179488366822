import axios from 'axios';
import streamSaver from 'streamsaver';
import format from 'date-fns/format/index.js';
import { saveAs } from 'file-saver';
import {
  compact,
  each,
  entries,
  flatMap,
  go,
  groupBy,
  ippL,
  last,
  map,
  pluck,
  reject,
  sel,
  some,
  strMap,
  tap,
  uniq,
  uniqueBy,
} from 'fxjs/es';
import JSZip from 'jszip';
import { NewMakerPrintResultF } from '../../../../../../NewMaker/PrintResult/F/Function/module/NewMakerPrintResultF.js';
import { SVGEditorUtilF } from '../../../../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { VectorEditorConstantS } from '../../../../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { LoaderF } from '../../../../../../Loader/F/Function/module/LoaderF.js';
/*
 * task_projection {merged_type, important, task_id}
 *   < up_cs {id, product_color_id, printable_product_id, up_id, up_c_id, projection_id}
 *     - product_color {id, product_faces2}
 *     - printable_product {id, product_faces2}
 *     - base_product {id, name, maker_type, is_not_image_task, has_template}
 *     - base_product_color {id, name}
 *     - base_product_size {id, name}
 *     < up_c_ss {id, quantity, base_product_size_id}
 * */
/*
 * [] 키링
 * [] 올리고
 * [] 레이저 라이플
 * [] 피디랜드
 * [] 서블리원 - 자동, printable_files
 * */
const makeFileNam2 = (up_c_ss_projection) => {
  return {
    projection_id: up_c_ss_projection.projection_id,
    up_id: up_c_ss_projection.up_id,
    up_c_id: up_c_ss_projection.up_c_id,
    imp: up_c_ss_projection.important ? '#긴급_' : '',
    base_product_name: up_c_ss_projection._.base_product.name?.replace(/\s/g, ''),
    base_product_size_name: up_c_ss_projection._.base_product_size.name?.replace(/\s/g, ''),
    quantity: up_c_ss_projection.quantity,
    base_product_color_name: up_c_ss_projection._.base_product_color.name?.replace(/\s/g, ''),
  };
};
export async function makeZipFile(up_c_ss_projections, is_projection_id_folder) {
  const error_messages = [];
  const ready_files = await go(undefined, async () => {
    const loaderCount = LoaderF.init({
      work_count: up_c_ss_projections.length,
      message: '다운로드 진행중입니다.',
    });
    try {
      return go(
        up_c_ss_projections,
        flatMap(
          async ({
            value: up_c_ss_projection,
            product_faces2,
            product_faces2_printable_files,
            all_faces_single_color,
          }) => {
            const { task_id, projection_id, up_c_s_id } = up_c_ss_projection;
            const base_product = up_c_ss_projection._.base_product;
            const title = makeFileNam2(up_c_ss_projection);
            try {
              if (product_faces2_printable_files?.length) {
                return await go(
                  product_faces2_printable_files,
                  map(async (printable_file) => {
                    const url = printable_file.ai_url || printable_file.url;
                    const { data: buffer, headers } = await axios.get(url, {
                      responseType: 'arraybuffer',
                    });
                    const file_blob = new Blob([new Uint8Array(buffer)], { type: headers['content-type'] });
                    const extension = last(url.split('.'));
                    return {
                      file_name: `#${title.projection_id}/${title.up_c_id}/${title.base_product_name}/${
                        printable_file.face_name
                      }/${title.base_product_color_name}/${title.base_product_size_name}/${title.quantity}ea${
                        all_faces_single_color?.name ? `_${all_faces_single_color.name}` : ``
                      }.${extension}`,
                      printable_file_blob: file_blob,
                      task_id,
                      projection_id,
                      up_c_s_id,
                    };
                  }),
                  (result) => {
                    loaderCount();
                    return result;
                  },
                );
              }
              if (
                VectorEditorConstantS.KEYRING_EDITOR == base_product.maker_type ||
                VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR == base_product.maker_type
              ) {
                return await go(
                  SVGEditorUtilF.vectorDownload({
                    product_id: up_c_ss_projection.product_color_id,
                    task_id,
                    up_c_s_id: up_c_ss_projection.up_c_s_id,
                    up_id: up_c_ss_projection.up_id,
                    quantity: up_c_ss_projection.quantity,
                    projection_id: up_c_ss_projection.projection_id,
                  }),
                  map((obj) => ({
                    ...obj,
                    task_id,
                    projection_id,
                    up_c_s_id,
                  })),
                  (result) => {
                    loaderCount();
                    return result;
                  },
                );
              }
              return await go(
                NewMakerPrintResultF.makeCanvasForPrint({
                  title,
                  up_c_s_projection: up_c_ss_projection,
                  product_faces2,
                  all_faces_single_color,
                }),
                map((obj) => ({
                  ...obj,
                  task_id,
                  projection_id,
                  up_c_s_id,
                })),
                (result) => {
                  loaderCount();
                  return result;
                },
              );
            } catch (error) {
              console.log('error', error);

              error_messages.push({ projection_id, up_c_s_id });
              await $.post('/@api/prerequisite_maker/error_logs', {
                json_memo: {
                  user: box().is_user,
                  task_id,
                  projection_id,
                  up_c_s_id,
                  error: error.message,
                },
                name: 'DOWNLOAD-MAKING-CANVAS',
              });
              loaderCount();
            }
          },
        ),
        compact,
        reject(({ projection_id }) => some((e) => e.projection_id === projection_id, error_messages)),
        groupBy(({ file_name }) => file_name),
        entries,
        flatMap(([k, arr]) => {
          return go(
            arr,
            ippL,
            map(([i, obj]) => {
              if (i === 0) return obj;
              const mime = last(obj.file_name.split('.'));
              const name = obj.file_name.split(mime)[0];
              obj.file_name = `${name}(${i}).${mime}`;
              return obj;
            }),
          );
        }),
      );
    } catch (error) {
      console.log(error);
      $.alert('문제가 발생했습니다. 개발팀에 문의해주세요.(MAKING-FILE)');
    }
  });
  if (!ready_files?.length) {
    return $.alert(
      `다운로드 파일이 없습니다.${
        error_messages.length
          ? `<br>ERROR::${go(
              error_messages,
              strMap(
                ({ projection_id, up_c_s_id }) => `주문번호-${projection_id}, 상품상세번호-${up_c_s_id}`,
              ),
            )}`
          : ''
      }\``,
    );
  }
  $.don_loader_start();
  try {
    const zip = new JSZip();
    await go(
      ready_files,
      is_projection_id_folder
        ? tap(
            groupBy(sel('projection_id')),
            entries,
            each(([projection_id, files]) => {
              go(
                files,
                each(({ canvas_blob, file_name, printable_file_blob }) => {
                  zip
                    .folder(`#${projection_id}`)
                    .file(file_name.replace(/\//g, '_'), canvas_blob || printable_file_blob);
                }),
              );
            }),
          )
        : each(({ canvas_blob, file_name, printable_file_blob }) => {
            zip.file(file_name.replace(/\//g, '_'), canvas_blob || printable_file_blob);
          }),
      async (arr) => {
        const task_ids = go(arr, pluck('task_id'), uniq);
        const projection_ids = go(arr, pluck('projection_id'), uniq);
        const up_c_s_ids = go(arr, pluck('up_c_s_id'), uniq);
        const a = document.createElement('a');
        const task_status_progress_data = go(
          arr,
          uniqueBy(({ up_c_s_id }) => up_c_s_id),
          map(({ task_id, up_c_s_id }) => ({ task_id, up_c_s_id })),
        );
        const file_name = `ZIP_(${format(new Date(), 'MMddHHmmss')})`;
        if (box().is_user.id === 2162577) {
          $.don_loader_end();
          await zipWithStreamSaver(zip, file_name);
          $.don_loader_start();
        } else {
          const blob = await zip.generateAsync({ type: 'blob' });
          saveAs(blob, file_name);
        }
        await $.post('/@api/tasks/update_all_status_on_and_ups_tasks_progress', {
          task_status_progress_data,
        });
        a.href = `/@api/tasks/outsourcing_up_cs_info?up_c_s_ids=${up_c_s_ids.join(',')}&image_length=${
          arr.length
        }`;
        a.click();
        $.don_loader_end();
        await $.alert(
          `상세 작업 수 ${task_status_progress_data.length}개, 주문서 ${projection_ids.length}개, 태스크 ${
            task_ids.length
          }개 진행중으로 변경 됐습니다.${
            error_messages.length
              ? `<br>ERROR::${go(
                  error_messages,
                  strMap(
                    ({ projection_id, up_c_s_id }) => `주문번호-${projection_id}, 상품상세번호-${up_c_s_id}`,
                  ),
                )}`
              : ''
          }`,
        );
        await G.df.task.list_update();
        window.location.reload();
      },
    );
    $.don_loader_end();
  } catch (error) {
    console.log(error);
    await $.post('/@api/prerequisite_maker/error_logs', {
      json_memo: {
        user: box().is_user,
        error: error.stack,
      },
      name: 'DOWNLOAD-ZIP-STATUS',
    });
    $.alert('문제가 발생했습니다. 개발팀에 문의해주세요.(ZIP-STATUS)');
    $.don_loader_end();
  }
}

function zipWithStreamSaver(zip, file_name) {
  const loaderPercentage = LoaderF.init({
    percentage: 1,
    message: '파일 다운로드 중입니다.',
  });
  const writeStream = streamSaver.createWriteStream(`${file_name}.zip`).getWriter();

  // 여기 비동기 처리하기
  const zip_file = new Promise((resolve, reject) => {
    zip
      .generateInternalStream({
        type: 'uint8array',
        compression: 'DEFLATE',
        compressionOptions: {
          level: 1,
        },
        streamFiles: true,
      })
      .on('data', (data, { percent }) => {
        writeStream.write(data);
        loaderPercentage(`${parseInt(percent)}%`);
      })
      .on('error', (err) => {
        console.error(err);
        reject(err);
      })
      .on('end', () => {
        writeStream.close();
        resolve();
      })
      .resume();
  });
  return zip_file;
}
