import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorKeyringFreeMobileCuttingLineManualMuiF } from '../Mui/module/VectorEditorKeyringFreeMobileCuttingLineManualMuiF.js';

export const makeCuttingLineManual = ({
  art_board: { width: art_board_width, height: art_board_height },
  cutting_line: { inner: inner_cutting_line_path_data, outer: outer_cutting_line_path_data },
  makeTargetImageEl,
  postProcess,
}) =>
  MuiF.pushPage(VectorEditorKeyringFreeMobileCuttingLineManualMuiF.page, (page, [tab]) => {
    const tab_appending = tab.appending;
    tab.appending = (tab_el) => {
      tab_el.__mp_art_board_width = art_board_width;
      tab_el.__mp_art_board_height = art_board_height;
      tab_el.__mp_base_inner_cutting_line_path_data = inner_cutting_line_path_data;
      tab_el.__mp_base_outer_cutting_line_path_data = outer_cutting_line_path_data;
      tab_el.__mp_makeTargetImageEl = makeTargetImageEl;
      tab_el.__mp_postProcess = postProcess;

      return tab_appending.call(tab, tab_el);
    };
  }).catch(async (error) => {
    try {
      await MuiF.popPage();
    } catch (error) {
      console.error(error);
    }

    throw error;
  });
