import { $data, $delegate, $find } from 'fxdom/es';
import { go, filter, zip } from 'fxjs/es';
import { OMPCoreAtomF } from '../../../Core/Atom/F/Function/module/OMPCoreAtomF.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPSearchHistoryF } from '../../History/F/Function/module/OMPSearchHistoryF.js';
import { SearchHistoryApiHelper } from '../../History/F/Function/SearchHistoryApiHelper.js';
import { OMPSearchS } from '../../S/Function/module/OMPSearchS.js';

const Klass = Object.freeze({
  historyBtnWrap: 'omp-search__search-history__history-btns',
  historyFlushBtn: 'omp-search__search-history__remove-all-btn',
});

/**
 * 검색어 자동완성 F 이벤트
 * @param {HTMLElement} el
 * @return {HTMLElement}
 */
export const delegateSearchHistory = (el) => {
  return go(
    el,
    OMPCoreAtomF.delegateRemovableButton,
    $delegate('select', `.${Klass.historyBtnWrap}`, async (e) => {
      const { text: keyword } = /** @type {RemovableButtonParam} */ e.detail;
      await SearchHistoryApiHelper.post([keyword]);
      location.href = OMPSearchS.getSearchResultUrl(keyword);
    }),
    $delegate('remove', `.${Klass.historyBtnWrap}`, async (e) => {
      const { text } = /** @type {RemovableButtonParam} */ e.detail;
      const reset_history = /** @type {SearchHistory} */ await SearchHistoryApiHelper.delete(text);
      OMPSearchHistoryF.updateSearchHistoryTmpl(reset_history, e.currentTarget);
      e.originalEvent.stopPropagation();
    }),
    $delegate('click', `.${Klass.historyFlushBtn}`, async (e) => {
      /** @type {SearchHistory} */
      const empty_history = await SearchHistoryApiHelper.flush();
      OMPSearchHistoryF.updateSearchHistoryTmpl(empty_history, e.currentTarget);
    }),
  );
};

/**
 * @param {HTMLElement} el
 * @return {Promise<void>}
 */
export const rerenderSearchHistory = async (el) => {
  const $history_wrap = $find(`.${Klass.historyBtnWrap}`)(el);
  const rendered_queries = $history_wrap ? $data($history_wrap) : [];
  const fetched_search_history = await SearchHistoryApiHelper.get();

  const diff_history = go(
    zip(fetched_search_history.queries, rendered_queries),
    filter(([q1, q2]) => q1 !== q2),
  );
  if (!diff_history.length) {
    return;
  }
  OMPCoreUtilF.loaderStart($history_wrap);
  OMPSearchHistoryF.updateSearchHistoryTmpl(fetched_search_history, $history_wrap);
  OMPCoreUtilF.loaderEnd($history_wrap);
};
