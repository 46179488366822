import { $attr, $closest, $delegate, $find, $remove, $setCss, $trigger, $val } from 'fxdom/es';
import { makeRepeatPatternToCanvas } from '../../../Canvas/S/util.js';
import { isMimeTypeCorrect } from '../../../Util/F/fs.js';
import { makeCompositeMasksStepHtml } from '../S/composite_template_maker_tmpl.js';
import { go, pick, pipe } from 'fxjs/es';
import {
  updateCompositeMask,
  updateCompositeTemplate,
  updateCompositeTexture,
  updateDateCompositeTemplate,
} from './fs.js';
import {
  getBoxData,
  getClosestBoxData,
  initializeBoxDataAssocCompositeTemplate,
  invertDarkColorToWhite,
  renderUploadTmpl,
} from './util.js';
import { openCompositeFacesSelectStepPage } from './composite_faces_select_step_page.js';

$.frame.defn_page({
  page_name: 'composite_masks_step_page',
  hide_frame_button_type: 'X',
  title: '색상 마스크 / 멜란지 패턴 (2/5)',
  tabs: [
    {
      tab_name: 'composite_masks_step_tab',
      template: async (assoc_composite_template) => {
        return makeCompositeMasksStepHtml(assoc_composite_template);
      },
      appended: pipe(
        $delegate('change', '.img_input_wrapper input[type="file"]', async function (e) {
          const ct = e.currentTarget;
          $.don_loader_start();
          if (await isMimeTypeCorrect(ct.files[0], true, true, false, false)) {
            const key = $attr('key', ct);
            const data = await $.upload(ct);
            const item = getClosestBoxData(ct);
            item[key] = data.url;
            if (key === 'mask_url' || key === 'product_url' || key === 'design_shade_url')
              await updateCompositeMask(pick(['id', key], item));
            else if (key === 'texture_url') await updateCompositeTexture(pick(['id', key], item));
            else if (key === 'image_url') await updateCompositeTexture(pick(['id', key], item));
            renderUploadTmpl(ct, item[key]);
            await updateCompositeTemplate({ id: item.composite_template_id });
            await updateDateCompositeTemplate(item.composite_template_id);
          } else {
            $.alert('지원되지 않는 파일 형식 입니다. 파일을 확인하시고 다시 시도해 주세요.');
          }
          $.don_loader_end();
        }),
        $delegate('click', '.img_input_wrapper .upload', function (e) {
          $trigger('click', go(e.currentTarget, $closest('.img_input_wrapper'), $find('input[type="file"]')));
        }),
        $delegate('click', '.delete', async function (e) {
          $.don_loader_start();
          const ct = e.currentTarget;
          const key = go(ct, $attr('key'));
          const item = getClosestBoxData(ct);
          item[key] = true;
          await updateCompositeTexture(pick(['id', key], item));
          go(ct, $closest('[_sel]'), $remove);
          await initializeBoxDataAssocCompositeTemplate(box.sel('assoc_composite_template').id);
          $.don_loader_end();
        }),
        $delegate('change', 'select.base_product_colors_color_code', async function (e) {
          const ct = e.currentTarget;
          const composite_mask = getBoxData(e.currentTarget, '.composite_mask');
          composite_mask.base_product_color_id = $val(e.currentTarget);
          go(
            ct,
            $find(`option[value="${composite_mask.base_product_color_id}"]`),
            (el) => el.getAttribute('data-color_code'),
            (selected_color) => {
              $setCss(
                {
                  'background-color': selected_color,
                  color: `${invertDarkColorToWhite(selected_color, 0.35)}`,
                },
                ct,
              );
            },
          );
          $.don_loader_start();
          if (ct.selectedIndex)
            await updateCompositeMask(pick(['id', 'base_product_color_id'], composite_mask));
          $.don_loader_end();
        }),
        $delegate('click', '.next', async function (e) {
          openCompositeFacesSelectStepPage(box.sel('assoc_composite_template').id);
        }),
        $delegate('click', '.texture_pattern_download .download_btn', async function (e) {
          e.originalEvent.preventDefault();
          const seamless_texture_url = e.currentTarget.getAttribute('url');
          let { width, height } = box.sel('assoc_composite_template');
          const ratio = go(prompt('몇 배 크게 원하십니까?', 1), parseFloat);
          width *= ratio;
          height *= ratio;
          await go(
            makeRepeatPatternToCanvas(seamless_texture_url, width, height),
            (c) => c.toDataURL(),
            (dataURL) => G.mp.maker.download(dataURL, $attr('download_name', e.currentTarget)),
          );
        }),
      ),
    },
  ],
});

export function openCompositeMaksStepPage(composite_template_id) {
  $.frame.add_page({
    page_name: 'composite_masks_step_page',
    tabs: [
      {
        tab_name: 'composite_masks_step_tab',
        data_func: async () => {
          await initializeBoxDataAssocCompositeTemplate(composite_template_id);
          return box.sel('assoc_composite_template');
        },
      },
    ],
  });
}
