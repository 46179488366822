import {
  $attr,
  $closest,
  $delegate,
  $el,
  $find,
  $findAll,
  $hide,
  $insertAfter,
  $qs,
  $remove,
  $setAttr,
  $setCss,
  $setVal,
  $show,
  $trigger,
  $val,
} from 'fxdom/es';
import {
  delay,
  each,
  find,
  flatMap,
  go,
  html,
  map,
  mapL,
  mapObject,
  object,
  pick,
  pipe,
  sel,
  some,
} from 'fxjs/es';
import { makeCanvasByUrl, makeGradientCanvas } from '../../../Canvas/S/util.js';
import { openMakerProductColorInOut } from '../../../Maker/F/maker.inOutPc.js';
import { render_canvas } from '../../Core/S/composite_template_canvas.js';
import { composite_result_step } from '../S/composite_template_maker_tmpl.js';
import {
  getShadeUrlForTexture,
  updateCompositeFaces,
  updateCompositeMask,
  updateCompositeTemplate,
  updateCropData,
  updateDateCompositeTemplate,
} from './fs.js';
import axios from 'axios';
import {
  makeMockupSize,
  makeTestMockupUrl,
  setCompositeTemplateMockupUrl,
  setTestDesignReadyUrl,
} from '../../Core/F/mockup_fns.js';
import {
  directDownload,
  getBoxData,
  getFrameTag,
  initializeBoxDataAssocCompositeTemplate,
  invertDarkColorToWhite,
  renderUploadTmpl,
} from './util.js';
import { makeDesignReadyBlobUrl } from '../../Core/F/render_canvas.js';
import { isMimeTypeCorrect } from '../../../Util/F/fs.js';
import { makeWarpCylinder } from '../../Core/F/cylinder.js';
import { makeOnlyDesignFaceCanvas } from '../../../Maker/F/draw_product_faces.js';
import { makeImageFromUrl } from '../../../Maker/F/util.js';
import { getThumbnailAssocCompositeTemplates } from '../../Thumbnail/F/fs.js';

function renderCylinder(e, gradientFunc, is_gradient_dataurl) {
  const {
    percent,
    up_side,
    down_side,
    top,
    left,
    width,
    height,
    angle_v2,
    x1_perspective,
    y1_perspective,
    x2_perspective,
    y2_perspective,
    x3_perspective,
    y3_perspective,
    x4_perspective,
    y4_perspective,
  } = go(
    e.currentTarget,
    $closest('.etc_meta_wrapper'),
    $find('.etc_meta'),
    (form_el) => new FormData(form_el),
    (form_dt) => form_dt.entries(),
    object,
    mapObject((v) => makeNum(v)),
  );
  const etc_meta = {
    percent,
    up_side,
    down_side,
    top,
    left,
    width,
    height,
    angle_v2,
    x1_perspective,
    y1_perspective,
    x2_perspective,
    y2_perspective,
    x3_perspective,
    y3_perspective,
    x4_perspective,
    y4_perspective,
  };
  if (
    go(
      [
        percent,
        up_side,
        down_side,
        top,
        left,
        width,
        height,
        angle_v2,
        x1_perspective,
        y1_perspective,
        x2_perspective,
        y2_perspective,
        x3_perspective,
        y3_perspective,
        x4_perspective,
        y4_perspective,
      ],
      some(isNaN),
    )
  )
    return;
  const composite_template_result_el = $closest('.composite_template_result')(e.currentTarget);
  return go(
    box.sel('assoc_composite_template')._.composite_masks,
    flatMap((cm) =>
      go(
        cm._.composite_faces,
        map(async (cf) => {
          if (!composite_template_result_el.__meterial) {
            const assoc_composite_templates = await getThumbnailAssocCompositeTemplates(
              { base_product_id: cm.base_product_id },
              true,
            );
            const size = await makeMockupSize(assoc_composite_templates);
            const base_product_size_id = await go(
              $.get('/@api/prerequisite_maker/thumb_base_product_size', {
                base_product_id: cm.base_product_id,
              }),
              sel('id'),
            );
            const size_face = await go(
              $.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
                id: cf.base_product_face_id,
              }),
              sel('size_faces'),
              find((sf) => sf.base_product_size_id === base_product_size_id),
            );
            size.width =
              size.width * (size_face.print.cm.width / size_face.print.etc_meta.cylinder_width_cm) * 2;

            const mockup_url = await makeTestMockupUrl(cf.base_product_face_id, false, size.width);
            composite_template_result_el.__meterial = { mockup_url, size_face };
          }
          const { mockup_url, size_face } = composite_template_result_el.__meterial;
          return go($qs('.composite_result .result_url'), async (result_url_el) => {
            const cc = $find('canvas')(result_url_el);
            return go(
              makeWarpCylinder(
                {
                  size_face,
                  canvas_width: box.sel('assoc_composite_template').width,
                  print_area_canvas: gradientFunc
                    ? await gradientFunc(mockup_url)
                    : await makeCanvasByUrl(mockup_url),
                  bg_url: box.sel('assoc_composite_template').bg_url,
                  mask_url: box.sel('assoc_composite_template->_->composite_masks->0->mask_transparent_url'),
                },
                etc_meta,
                !is_gradient_dataurl && true,
              ),
              async (design_ready_canvas) => {
                if (is_gradient_dataurl) return design_ready_canvas.toDataURL();
                const assoc_composite_template = box.sel('assoc_composite_template');
                const base_product_color_id = go(
                  e.currentTarget,
                  $closest('.composite_template_result'),
                  $find('select.base_product_colors_color_code'),
                  $val,
                  parseInt,
                );
                const base_product_id = box.sel('bp').id;

                if (
                  assoc_composite_template._.composite_masks[0]._.composite_faces[0]?.etc_meta
                    ?.reflection_meta
                ) {
                  assoc_composite_template._.composite_masks[0]._.composite_faces[0].etc_meta.reflection_meta =
                    null;
                }
                await render_canvas({
                  assoc_composite_template,
                  canvas_el: cc,
                  selected_base_product_colors: [
                    {
                      id: base_product_color_id,
                      base_product_id,
                    },
                  ],
                  design_ready_canvas,
                });
                const reflection_meta_el = $qs('textarea[name="reflection_meta"]');
                if (assoc_composite_template._.composite_masks[0]._reflection_meta.a)
                  reflection_meta_el.value = JSON.stringify(
                    assoc_composite_template._.composite_masks[0]._reflection_meta.a,
                  );
              },
            );
          });
        }),
      ),
    ),
  );
}

async function _renderCanvas(composite_template_result_el, assoc_composite_template, pc) {
  const target_width = assoc_composite_template.width;
  const canvas = go(composite_template_result_el, $find('.result_url'), $find('canvas'));

  const ratio = target_width / assoc_composite_template.width;
  canvas.width = assoc_composite_template.width * ratio;
  canvas.height = assoc_composite_template.height * ratio;

  const etc_meta = assoc_composite_template._.composite_masks[0]._.composite_faces[0].etc_meta;
  if (etc_meta?.is_cylinder) {
    const base_product_id = assoc_composite_template._.composite_masks[0].base_product_id;
    const base_product_size_id = await go(
      $.get('/@api/prerequisite_maker/thumb_base_product_size', {
        base_product_id,
      }),
      sel('id'),
    );
    const assoc_composite_templates = await getThumbnailAssocCompositeTemplates(pc, true);
    const size = await makeMockupSize(assoc_composite_templates);
    const size_face = await go(
      $.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
        id: assoc_composite_template._.composite_masks[0]._.composite_faces[0].base_product_face_id,
      }),
      sel('size_faces'),
      find((sf) => sf.base_product_size_id === base_product_size_id),
    );
    size.width = size.width * (size_face.print.cm.width / size_face.print.etc_meta.cylinder_width_cm) * 2;
    const print_area_canvas = await makeOnlyDesignFaceCanvas(
      pc.product_faces2.value[0],
      base_product_size_id,
      { width: size.width },
    );

    assoc_composite_template._.composite_masks[0]._.composite_faces[0].mockup_url = await go(
      $.uploadFileToOnlyOriginalUrl({
        original_name: 'mockup_url',
        image_type: 'PNG',
        url: print_area_canvas.toDataURL(),
      }),
      sel('url'),
    );
    const design_ready_canvas = await makeWarpCylinder(
      {
        size_face,
        print_area_canvas,
        canvas_width: assoc_composite_template.width,
        mask_url: assoc_composite_template._.composite_masks[0].mask_transparent_url,
      },
      etc_meta,
    );
    $setCss({ visibility: 'hidden' }, canvas);
    assoc_composite_template._.composite_result.design_ready_canvas = design_ready_canvas;
  } else {
    await setCompositeTemplateMockupUrl([assoc_composite_template], pc, target_width);
    assoc_composite_template._.composite_result = {
      design_ready_url: await makeDesignReadyBlobUrl(assoc_composite_template, target_width),
    };
  }
  $setCss({ visibility: 'visible' }, canvas);
  const select_el = go(composite_template_result_el, $find('select.base_product_colors_color_code'));
  go(
    select_el,
    $findAll('option'),
    find((option_el) => parseInt(option_el.dataset.id) === pc.base_product_color_id),
    $setAttr({ selected: 'selected' }),
  );
  $trigger('change', select_el);
}
function isInt(n) {
  return n != '' && !isNaN(n) && Math.round(n) == n;
}
function makeNum(v) {
  return isInt(v) ? parseInt(v) : parseFloat(v);
}
async function render_canvas2(assoc_composite_template, dt) {
  const design_ready_canvas =
    assoc_composite_template._.composite_result.design_ready_url &&
    (await makeCanvasByUrl(assoc_composite_template._.composite_result.design_ready_url));
  const canvas_el = go(dt, $find('.result_url canvas'));
  const base_product_color_id = go(dt, $find('select.base_product_colors_color_code'), $val, parseInt);
  const base_product_id = box.sel('bp').id;
  await render_canvas({
    assoc_composite_template,
    selected_base_product_colors: [
      {
        base_product_id,
        id: base_product_color_id,
      },
    ],
    design_ready_canvas,
    canvas_el,
  });
}

$.frame.defn_page({
  page_name: 'composite_result_step_page',
  hide_frame_button_type: 'X',
  title: '합성 테스트 및 완료 (5/5)',
  tabs: [
    {
      tab_name: 'composite_result_step_tab',
      template: composite_result_step,
      appended: pipe(
        $delegate('click', '.img_input_wrapper.top_layer_url .delete', async (e) => {
          const assoc_composite_template = box().assoc_composite_template;
          if (!assoc_composite_template.top_layer_url) return;
          if (!(await $.confirm('삭제하시겠습니까?'))) return;
          $.don_loader_start();
          const ct = e.currentTarget;
          renderUploadTmpl(ct, null);
          assoc_composite_template.top_layer_url = null;
          await render_canvas2(assoc_composite_template, e.delegateTarget);
          await updateCompositeTemplate(pick(['id', 'top_layer_url'], assoc_composite_template));
          $.don_loader_end();
        }),
        $delegate('change', '.img_input_wrapper input[type="file"]', async function (e) {
          const ct = e.currentTarget;
          if (!(await $.confirm('바로 반영됩니다. 업로드 하시겠습니까?'))) return;
          $.don_loader_start();
          if (await isMimeTypeCorrect(ct.files[0], false, true, false, false)) {
            const key = $attr('key', ct);
            const { url } = await $.upload(ct);
            renderUploadTmpl(ct, url);
            const assoc_composite_template = box().assoc_composite_template;
            assoc_composite_template[key] = url;
            await render_canvas2(assoc_composite_template, e.delegateTarget);
            await updateDateCompositeTemplate(assoc_composite_template.id);
            await updateCompositeTemplate(pick(['id', key], assoc_composite_template));
          } else {
            $.alert('지원되지 않는 파일 형식 입니다. 파일을 확인하시고 다시 시도해 주세요.');
          }
          $.don_loader_end();
        }),
        $delegate('click', '.img_input_wrapper .upload', function (e) {
          $trigger('click', go(e.currentTarget, $closest('.img_input_wrapper'), $find('input[type="file"]')));
        }),
        $delegate('click', '.create_shade_url_btn', (e) => {
          if (box().bp.print_type !== 'none_fabric') {
            $.alert('재질 억제 선택시 해당 기능이 가능합니다.');
            return;
          }
          const assoc_composite_template = box().assoc_composite_template;
          const initial_shade_url = assoc_composite_template._.composite_masks[0].shade_url;
          const dt = e.delegateTarget;
          const shade_url_box = new Map();
          const texture_table = new Map([
            [0, 'no texture'],
            [1, 'weak texture'],
            [2, 'medium texture'],
            [3, 'strong texture'],
          ]);
          function updateShadeUrl(composite_template, shade_url) {
            go(
              composite_template._.composite_masks,
              each(async (cm) => {
                cm.shade_url = shade_url;
              }),
            );
          }
          const el = go(
            $el(html`
              <form class="create_shade_url">
                <span style="text-align:end;margin:7px;padding-right:5px;width:140px"></span>
                <input type="number" name="shade_url_num" value="0" min="0" max="3" step="1" />
                <input type="text" name="shade_url" hidden />
                <button type="button" class="shade_url_save btn">재질 저장</button>
                <button type="button" class="shade_url_cancel btn">재질 취소</button>
              </form>
            `),
            $delegate('change', 'input[name="shade_url_num"]', async (e) => {
              $.don_loader_start();
              const strength = parseInt($val(e.currentTarget));
              const { id, bg_url, width, height } = assoc_composite_template;
              $qs('form.create_shade_url span').textContent = texture_table.get(strength);
              const prev_shade_url = shade_url_box.get(strength);
              const shade_url =
                prev_shade_url ||
                (strength === 3
                  ? bg_url
                  : await getShadeUrlForTexture({
                      id,
                      bg_url,
                      width,
                      height,
                      strength,
                    }));

              if (!shade_url_box.get(strength)) {
                shade_url_box.set(strength, shade_url);
              }

              updateShadeUrl(assoc_composite_template, shade_url);
              $setVal(shade_url)(e.delegateTarget.shade_url);
              await render_canvas2(assoc_composite_template, dt);
              $.don_loader_end();
            }),
            $delegate('click', '.shade_url_cancel', async () => {
              $.don_loader_start();
              updateShadeUrl(assoc_composite_template, initial_shade_url);
              $remove(el);
              $show(create_shade_url_btn);
              await render_canvas2(assoc_composite_template, dt);
              $.don_loader_end();
            }),
            $delegate('click', '.shade_url_save', async (e) => {
              $.don_loader_start();
              const shade_url = $val(e.delegateTarget.shade_url);
              if (!shade_url) {
                $.don_loader_end();
                return $.alert('문제가 있습니다.');
              }
              await go(
                assoc_composite_template._.composite_masks,
                each(async ({ id }) => {
                  await updateCompositeMask({ id, shade_url });
                }),
              );
              await updateDateCompositeTemplate(assoc_composite_template.id);
              $remove(el);
              $show(create_shade_url_btn);
              $.don_loader_end();
            }),
          );
          const create_shade_url_btn = $find('.create_shade_url_btn')(e.delegateTarget);
          $hide(create_shade_url_btn);
          $insertAfter(create_shade_url_btn)(el);
          $trigger('change', $qs('.process_shade_url input[name="shade_url_num"]'));
        }),
        $delegate('click', '.composite_toggle', function (e) {
          const ct = e.currentTarget;
          $find("input[type='checkbox']", ct).checked = $find("input[type='checkbox']", ct).checked === false;
        }),
        $delegate('click', '.cylinder_info .etc_meta_save', async function (e) {
          const etc_meta = go(
            e.currentTarget,
            $closest('form'),
            (form_el) => new FormData(form_el),
            (form_dt) => form_dt.entries(),
            object,
            (obj) => {
              obj.reflection_meta = JSON.parse(obj.reflection_meta);
              return obj;
            },
            mapObject((v) => {
              const num = makeNum(v);
              return num || v;
            }),
          );
          etc_meta.is_cylinder = true;
          box().assoc_composite_template._.composite_masks[0]._.composite_faces[0].etc_meta = etc_meta;
          $.don_loader_start();
          await updateCompositeFaces({
            id: box().assoc_composite_template._.composite_masks[0]._.composite_faces[0].id,
            etc_meta,
          });
          $.don_loader_end();
        }),
        $delegate('click', '.etc_meta .etc_meta_apply', async (e) => {
          $.don_loader_start();
          await renderCylinder(e);
          $.don_loader_end();
        }),
        $delegate('click', '.shade_maker .shade_maker_apply', async (e) => {
          $.don_loader_start();
          const obj = go(
            e.currentTarget,
            $closest('form'),
            (form_el) => new FormData(form_el),
            (form_dt) => form_dt.entries(),
            map(([k, v]) => [k, JSON.parse(v)]),
            object,
          );
          obj.arr = map((o) => {
            o.hex = `rgb(${o.strength}, ${o.strength}, ${o.strength})`;
            return o;
          }, obj.arr);
          if (box().assoc_composite_template._.composite_masks[0].design_shade_url)
            box().assoc_composite_template._.composite_masks[0].design_shade_url = null;
          await renderCylinder(e, async (url) => {
            const img = await makeImageFromUrl(url);
            const c = makeGradientCanvas(img, obj.arr, {
              x1: 0,
              y1: img.height / 2,
              x2: img.width,
              y2: img.height / 2,
            });
            return c;
          });
          $.don_loader_end();
        }),
        $delegate('click', '.shade_maker .shade_maker_save', async (e) => {
          const composite_mask = go(e.delegateTarget, $findAll('.composite_mask'), (arr) => {
            if (arr.length !== 1) throw new Error('cm이 2개 이상이거나 없습니다. 문제입니다!');
            return box.sel(arr[0]);
          });
          $.don_loader_start();
          const obj = go(
            e.currentTarget,
            $closest('form'),
            (form_el) => new FormData(form_el),
            (form_dt) => form_dt.entries(),
            map(([k, v]) => [k, JSON.parse(v)]),
            object,
          );
          obj.arr = map((o) => {
            o.hex = `rgb(${o.strength}, ${o.strength}, ${o.strength})`;
            return o;
          }, obj.arr);
          if (box().assoc_composite_template._.composite_masks[0].design_shade_url)
            box().assoc_composite_template._.composite_masks[0].design_shade_url = null;
          const shade_urls = await renderCylinder(
            e,
            async (url) => {
              const img = await makeImageFromUrl(url);
              const c = makeGradientCanvas(img, obj.arr, {
                x1: 0,
                y1: img.height / 2,
                x2: img.width,
                y2: img.height / 2,
              });
              return c;
            },
            true,
          );
          const design_shade_url = await go($.uploadFileToUrl(shade_urls[0], 'shade_url'), sel('url'));
          if (design_shade_url) await updateCompositeMask({ id: composite_mask.id, design_shade_url });
          $.don_loader_end();
        }),
        $delegate('click', '.edit_cylinder', function (e) {
          $show($qs('.etc_meta_wrapper'));
        }),
        $delegate('click', '.default_mockup', async function (e) {
          $.don_loader_start();
          const assoc_composite_template = box.sel('assoc_composite_template');
          const base_product_color_id = go(
            e.currentTarget,
            $closest('.composite_template_result'),
            $find('select.base_product_colors_color_code'),
            $val,
            parseInt,
          );
          const base_product_id = box.sel('bp').id;

          const canvas = go($find('.result_url', e.delegateTarget), $find('canvas'));
          canvas.width = assoc_composite_template.width;
          canvas.height = assoc_composite_template.height;
          await setTestDesignReadyUrl(assoc_composite_template, true);
          $setCss({ visibility: 'hidden' }, canvas);
          await render_canvas({
            assoc_composite_template,
            canvas_el: canvas,
            selected_base_product_colors: [
              {
                id: base_product_color_id,
                base_product_id,
              },
            ],
            design_ready_canvas: await makeCanvasByUrl(
              assoc_composite_template._.composite_result.test_design_ready_url,
            ),
          });
          await delay(500);
          $.don_loader_end();
          $setCss({ visibility: 'visible' }, canvas);
        }),
        $delegate('click', '.open_maker', async function (e) {
          const assoc_composite_template = box.sel('assoc_composite_template');
          const canvass = go(e.currentTarget, $closest('.don_tab'), $findAll('canvas'));
          const base_product_id = getFrameTag(e.currentTarget);
          const pc = await openMakerProductColorInOut({ base_product_id });
          const { tab_opt } = $closest('.don_tab', e.currentTarget);
          if (!pc) return;
          go(canvass, each($setCss({ visibility: 'hidden' })));
          tab_opt.pc = pc;
          $.don_loader_start();
          await delay(200);
          await _renderCanvas(e.delegateTarget, assoc_composite_template, pc);
          await go(
            assoc_composite_template._.composite_masks,
            each((cm) =>
              go(
                cm._.composite_faces,
                each(async (cf) => {
                  return updateCompositeFaces(pick(['id', 'mockup_url'], cf));
                }),
              ),
            ),
          );
          go(canvass, each($setCss({ visibility: 'visible' })));
          $.don_loader_end();
        }),
        $delegate('change', 'select.base_product_colors_color_code', async function (e) {
          const ct = e.currentTarget;
          go(
            ct,
            $find(`option[value="${ct.value}"]`),
            (el) => el.getAttribute('data-color_code'),
            (selected_color) =>
              $setCss(
                {
                  'background-color': selected_color,
                  color: `${invertDarkColorToWhite(selected_color, 0.35)}`,
                },
                ct,
              ),
          );
          const base_product_color_id = go(ct, $val, parseInt);
          const assoc_composite_template = box.sel('assoc_composite_template');
          const design_ready_canvas =
            assoc_composite_template._.composite_result.design_ready_canvas ||
            (assoc_composite_template._.composite_result.design_ready_url &&
              (await go(assoc_composite_template._.composite_result.design_ready_url, makeCanvasByUrl)));
          const canvas_el = go(e.currentTarget, $closest('.composite_result'), $find('.result_url canvas'));
          canvas_el.width = 900;
          await render_canvas({
            assoc_composite_template,
            selected_base_product_colors: [
              {
                id: base_product_color_id,
                base_product_id: box.sel('bp').id,
              },
            ],
            design_ready_canvas,
            canvas_el,
          });
        }),
        $.on('click', '.low_res', function (e) {
          e.originalEvent.preventDefault();
          const canvasImageDataUrl = go(e.delegateTarget, $find('canvas')).toDataURL('image/png', 1.0);
          directDownload(canvasImageDataUrl, $attr('download_name', e.currentTarget), 'image/png');
        }),
        $.on('click', '.high_res', function (e) {
          e.originalEvent.preventDefault();
          const composite_result_url =
            'https:' + box.sel('assoc_composite_template')._.composite_result.result_url;
          directDownload(composite_result_url, 'composite_result', 'image/png');
        }),
        $delegate('click', '.is_done input', async function (e) {
          const is_done = $val(e.currentTarget);
          const assoc_composite_template = getBoxData(e.currentTarget, '.assoc_composite_template');
          assoc_composite_template.is_done = is_done;

          $.don_loader_start();
          await updateCompositeTemplate(pick(['id', 'is_done'], box.sel('assoc_composite_template')));

          $.don_loader_end();
        }),
        $delegate('click', '#crop_submit', async function (e) {
          const crop_data = go(
            e.delegateTarget,
            $find('#crop_form'),
            ($el) => $el.querySelectorAll('input'),
            mapL(($input) => [$attr('name', $input), $input.value]),
            object,
          );
          $.don_loader_start();
          await updateCropData(crop_data);
          $.don_loader_end();
        }),
        async function (tab_el) {
          const { id } = box.sel('bp');
          await go(
            tab_el,
            $findAll('select.base_product_colors_color_code'),
            each(async (el) => {
              const base_product_color_id = go(el, $val, parseInt);
              const canvas_el = go(el, $closest('.composite_result'), $find('.result_url canvas'));
              const assoc_composite_template = box.sel('assoc_composite_template');
              if (
                !assoc_composite_template._.composite_masks[0]._.composite_faces[0]?.etc_meta?.is_cylinder &&
                assoc_composite_template._.composite_masks[0]._.composite_faces[0].mockup_url
              ) {
                assoc_composite_template._.composite_result.design_ready_url = await go(
                  axios.post(
                    '/@fileUpload/composite/create_design_ready_url_buffer',
                    {
                      assoc_composite_template,
                    },
                    { responseType: 'arraybuffer' },
                  ),
                  sel('data'),
                  (buffer) => new Uint8Array(buffer),
                  function (buffer) {
                    const blob = new Blob([buffer], { type: 'image/png' });
                    const urlCreator = window.URL || window.webkitURL;
                    return urlCreator.createObjectURL(blob);
                  },
                );
              }

              const design_ready_canvas =
                assoc_composite_template._.composite_result.design_ready_url &&
                (await makeCanvasByUrl(assoc_composite_template._.composite_result.design_ready_url));
              canvas_el.width = 900;
              await render_canvas({
                assoc_composite_template,
                selected_base_product_colors: [
                  {
                    base_product_id: id,
                    id: base_product_color_id,
                  },
                ],
                design_ready_canvas,
                canvas_el,
              });
            }),
          );
          return tab_el;
        },
      ),
    },
  ],
});

export function openCompositeResultStepPage(composite_template_id) {
  $.frame.add_page({
    page_name: 'composite_result_step_page',
    tabs: [
      {
        tab_name: 'composite_result_step_tab',
        data_func: async () => {
          await initializeBoxDataAssocCompositeTemplate(composite_template_id);
          return box.sel('assoc_composite_template');
        },
      },
    ],
  });
}
