import format from 'date-fns/format/index.js';
import { html, strMap } from 'fxjs/es';
export const tmpl = (data) => {
  return html` <div>
    <table>
      <thead>
        <tr>
          <th>id</th>
          <th>wow_id</th>
          <th>type</th>
          <th>updated_at</th>
          <th>req_status</th>
          <th>body</th>
        </tr>
      </thead>
      <tbody>
        ${strMap((log) => {
          return html`<tr>
            <td>${log.id}</td>
            <td>${log.reference_id}</td>
            <td>${log.type}</td>
            <td>${format(new Date(log.updated_at), 'yyyy-MM-dd HH:mm')}</td>
            <td>${log.req_status}</td>
            <td>
              <div class="log_body">${JSON.stringify(log.body)}</div>
            </td>
          </tr>`;
        }, data)}
      </tbody>
    </table>
  </div>`;
};
