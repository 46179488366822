import { $closest } from 'fxdom/es';
import { defaults, each, find, go, mapObject, pick, tap } from 'fxjs/es';
import axios from 'axios';
import { createCanvasElement, resizeImage } from '../../../Canvas/S/util.js';
import { rejectWrongCvTextImage } from '../util.js';
import { addTdeisngsToFcanvasInMaker } from '../draw_product_faces.js';
import { makeTrimedImageSize } from '../canvas_size.js';
import { copyModal } from '../../../Util/F/modal.js';
import { printableComposiedImage } from '../printable_compose_images.js';

export const downloadForPdLand = $.on('click', '.original_name_download', async function (e) {
  const ct = e.currentTarget;
  const title = _p.go(ct, $.closest('.file'), $.find('.select_press_type .selected'), $.text);
  if (title?.includes('플떡')) {
    try {
      const up_c = _p.go(ct, $.closest('.up_c_item'), box.sel);
      const projection =
        _p.go(ct, $.closest('.prj_item'), box.sel) || _p.go(ct, $.closest('.prj.up_item'), box.sel);
      const order_info = `${moment(projection.created_at).format('MMDD')}-마플-${up_c._.base_product.name}/${
        projection.id
      }_${up_c.up_id}-${up_c._.base_product_color.name}/${up_c.quantity}개.psd`;
      copyModal(
        `마플-${up_c._.base_product.name}/${projection.id}/${up_c._.base_product_color.name}/${up_c.quantity}개`,
      );
      $.don_loader_end();
      const href =
        location.origin +
        `/@api/pdland/download?url=${encodeURIComponent(ct.dataset.href)}&filename=${encodeURIComponent(
          order_info,
        )}`;

      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          const a = document.createElement('a');
          const url = URL.createObjectURL(this.response);
          a.href = url;
          a.download = order_info;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      };
      xhr.open('GET', href);
      xhr.responseType = 'blob';
      xhr.send();
      const task_item = go(e.currentTarget, $closest('.task_item'), box.sel);
      if (box().is_user._.policies.task_progress_policy && task_item?.worker_id === box().is_user.id)
        await axios.patch('/@api/task/progress_create_by_download', {
          task_id: task_item.id,
          up_c_id: up_c.id,
        });
      $.don_loader_end();
    } catch (e) {
      $.alert(`ERROR: ${e}`);
    }
  }
});

export async function downloadFileForPDland(up_c_id, projection) {
  $.don_loader_start();
  const { data: up_c } = await axios.get('/@api/user_product_colors', { params: { id: up_c_id } });
  const { data: product_color } = await axios.get('/@api/product_color', {
    /*DF*/
    params: { id: up_c.printable_product_id },
  });
  const up_id = up_c.up_id;
  const base_product = product_color._.base_product;
  const base_product_color = product_color._.base_product_color;
  const order_info = `마플-${base_product.name}/${projection.id}/${base_product_color.name}/${up_c.quantity}개`;

  go(
    product_color.product_faces2.value,
    each(async (pf) => {
      const { data: bpf } = await axios.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
        params: {
          id: pf.bpf_id,
        },
      });
      // const ratio = 1;
      const ratio = 300 / 96;
      const sf = go(
        bpf.size_faces,
        find((sf) => sf.base_product_size_id === product_color.base_product_size_id),
      );
      const template_size = go(
        pick(['width', 'height'], sf.template),
        mapObject((v) => v * ratio),
      );
      const maximum_print_area = go(
        sf.template.maximum_print_area,
        mapObject((v) => v * ratio),
      );
      const item_c = await getImageByMaximumPrintArea(pf, maximum_print_area, sf.template.mask_url);

      const color_print = go(createCanvasElement(template_size), (canvas) => {
        const ctx = canvas.getContext('2d');
        const rat = maximum_print_area.width / item_c.width;
        ctx.drawImage(
          item_c,
          0,
          0,
          item_c.width,
          item_c.height,
          maximum_print_area.left,
          maximum_print_area.top,
          item_c.width * rat,
          item_c.height * rat,
        );
        return canvas;
      });
      $.downloadUrl(sf.template.template_file_url, `${projection.id}_${up_id}_${order_info}.psd`);
      // FileSaver.saveAs(sf.template.template_file_url, `${projection.id}_${up_id}_${order_info}.psd`);
      G.mp.maker.download(color_print.toDataURL('image/png', 1), `${projection.id}_${up_id}_${order_info}`);
      $.don_loader_end();
      copyModal(order_info);
    }),
  );
}

async function getImageByMaximumPrintArea(pf, maximum_print_area, mask_url) {
  pf.cv_mask1.src = mask_url;
  const cv_mask1_size = await go(
    [defaults({ globalCompositeOperation: 'source-over' }, pf.cv_mask1)],
    rejectWrongCvTextImage,
    (Tdesigns) =>
      go(
        new fabric.StaticCanvas(document.createElement('canvas'), {
          width: G.mp.maker.CANVAS_WIDTH_ORIGIN,
          height: G.mp.maker.CANVAS_WIDTH_ORIGIN,
          enableRetinaScaling: false,
        }),
        async (fc) => {
          await addTdeisngsToFcanvasInMaker(Tdesigns, fc, 1);
          fc.renderAll();
          return go(
            makeTrimedImageSize(fc.lowerCanvasEl),
            tap(() => {
              fc.dispose();
            }),
          );
        },
      ),
  );
  const zoom = maximum_print_area.width / cv_mask1_size.width;
  // pf.cv_mask1._element = await loadImageFromUrl(mask_url);

  // const f_canvas = new fabric.StaticCanvas(document.createElement('canvas'), {
  //   width: width,
  //   height: width,
  //   enableRetinaScaling: false
  // });

  return go(
    printableComposiedImage(pf.designs.concat(pf.cv_mask1), zoom, 1, true),
    ([fcanvas]) => {
      fcanvas.renderAll();
      return fcanvas;
    },
    (fcanvas) => {
      return go(
        resizeImage(
          fcanvas.lowerCanvasEl,
          cv_mask1_size.left * zoom,
          cv_mask1_size.top * zoom,
          cv_mask1_size.width * zoom,
          cv_mask1_size.height * zoom,
          0,
          0,
          maximum_print_area.width,
          cv_mask1_size.height * zoom * (maximum_print_area.width / (cv_mask1_size.width * zoom)),
        ),
        tap(() => {
          fcanvas.dispose();
          fcanvas = null;
        }),
      );
    },
  );
}
