import { go } from 'fxjs/es';
import { $closest } from 'fxdom/es';
import { DfWaybillF } from '../../../Waybill/F/Function/module/DfWaybillF.js';
import { DfBpLabelF } from './module/DfBpLabelF.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import { UtilAlertF } from '../../../../Util/Alert/F/Function/module/UtilAlertF.js';

export async function event({ currentTarget: ct }) {
  try {
    const up_c_item = go(ct, $closest('.up_c_item'), box.sel);
    if (up_c_item == null) throw new Error(`up_c_item 이 없습니다. (개발팀 문의)`);

    const projection = go(ct, $closest('.prj_item'), box.sel);
    if (projection == null) throw new Error(`projection 이 없습니다. (개발팀 문의)`);

    const is_need_full_option_names = getIsBpLabelNeedFullOptionNames({ projection });

    const up_c_ss = up_c_item._?.up_c_ss;

    if (up_c_ss == null) throw new Error(`up_c_ss 가 존재하지 않습니다. (개발팀 문의)`);
    const labels = await DfBpLabelF.createBpLabelFromUpcs({ up_c_ss, is_need_full_option_names });

    DfInhouseF.lottie_loader.start('printer');

    if (UtilArrayS.isEmNil(labels)) {
      $.alert('출력할 상품 고시 정보 데이터가 없습니다. 개발자 문의');
      DfInhouseF.lottie_loader.end();
      return;
    }

    const product_label_printer = await DfBpLabelF.getValueFromLabelIndexedDB('product');

    if (product_label_printer == null) {
      $.alert('[프린터 등록 정보 없음]\n품표 인쇄를 위한 시리얼 번호 등록을 진행해 주세요.');
      DfInhouseF.lottie_loader.end();
      return;
    }

    const device = await DfWaybillF.prepareLabelPrinter({
      setting_fn: DfWaybillF.printer_config_fn.product_label,
      printer_serial: product_label_printer.printer_model,
    });

    await DfBpLabelF.printProductLabel({ device, labels });

    DfInhouseF.lottie_loader.end();
  } catch (e) {
    DfInhouseF.lottie_loader.end();
    await UtilAlertF.error({
      title: '상품 라벨 처리 오류',
      msg: e?.response?.data?.message ?? e?.message ?? '알수 없는 오류',
    });
  }
}

// @description 상품고시 라벨에 옵션명이 full 로 표시되어야 하는지 여부 판단
// @disclaimer 현재 해외 배송건에 한해서 full option name 표시 (2023/12~) 해외는 컬러, 사이즈까지 맞춰서 라벨링 필요. 그 외에는 컬러까지 맞춰서 사이즈 무관하게 공통 라벨링
function getIsBpLabelNeedFullOptionNames({ projection }) {
  const shipping = projection._.shippings[0];
  const shipping_company = shipping._?.shipping_company;

  return shipping_company?.oversea_3pl_company === 'lgl';
}
