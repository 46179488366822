export const NOT_FREE_BP_OPTION_ID = 245;
export const FREE_BP_OPTION_ID = 246;
export const KEYRING_FREE_SIZE_BP_OPTION_GROUP_ID = 89;
export const KEYRING_FREE_EDITOR_SIZE_BP_OPTION_GROUP_ID = 95;
export const KEYRING_MATERIAL_BP_OPTION_GROUP_ID = 77;
export const KEYRING_MATERIAL_GL_BP_OPTION_ID = 240;
export const KEYRING_MATERIAL_AU_BP_OPTION_ID = 241;
export const KEYRING_NO_GORI_BP_OPTION_ID = 348;
export const KEYRING_MATERIAL_GL_URL =
  '//s3.marpple.co/files/u_193535/2021/11/original/977b6dc22321f5e5571b664e4ce0693e4a1ee8641.png';
export const KEYRING_MATERIAL_AU_URL =
  '//s3.marpple.co/files/u_193535/2021/10/original/da8c1d91b560f899f49017f2385753669df707502.png';
export const KEYRING_FREE_EDITOR_DF_BP_OPTION_GROUPS_NAMES = {
  // 타입: '타입',
  재질: '재질',
  // 인쇄: '인쇄',
  고리: '고리',
  // 사이즈: '사이즈',
  // '에디터 사이즈': '에디터 사이즈',
};
