import { each, go } from 'fxjs/es';
import { convertImageDataToCanvas, createCanvasElement, loadImageFromUrl } from '../../../Canvas/S/util.js';
import { getPixelArrayFromURL } from './composite_utils.js';
import { imageDistortion } from './mapping_fns.js';

function convertPixelPointToArrIndex(x, y, width) {
  return (x + y * width) * 4;
}
function solveBilinearFunction(f00, f10, f01, f11, dx, dy) {
  return f00 * (1 - dx) * (1 - dy) + f10 * dx * (1 - dy) + f01 * (1 - dx) * dy + f11 * dx * dy;
}
export function mappingBilinearInterpolation(
  src_pixel_arr,
  dest_pixel_arr,
  src_x,
  src_y,
  src_width,
  dest_idx,
  map_pixel_arr,
) {
  const src_x0 = Math.floor(src_x);
  const src_y0 = Math.floor(src_y);
  const src_x1 = Math.ceil(src_x);
  const src_y1 = Math.ceil(src_y);
  const src_idx_00 = convertPixelPointToArrIndex(src_x0, src_y0, src_width);
  const src_idx_10 = convertPixelPointToArrIndex(src_x1, src_y0, src_width);
  const src_idx_01 = convertPixelPointToArrIndex(src_x0, src_y1, src_width);
  const src_idx_11 = convertPixelPointToArrIndex(src_x1, src_y1, src_width);
  const dx = src_x - src_x0;
  const dy = src_y - src_y0;

  const src_alpha_arr = [
    src_pixel_arr[src_idx_00 + 3],
    src_pixel_arr[src_idx_10 + 3],
    src_pixel_arr[src_idx_01 + 3],
    src_pixel_arr[src_idx_11 + 3],
  ];
  const src_alpha_zero_count = src_alpha_arr.filter((alpha) => alpha === 0).length;
  if (src_alpha_zero_count < 4) {
    if (src_alpha_zero_count > 0) {
      for (let k = 0; k < 3; k++) {
        const src_color_arr = [
          src_pixel_arr[src_idx_00 + k],
          src_pixel_arr[src_idx_10 + k],
          src_pixel_arr[src_idx_01 + k],
          src_pixel_arr[src_idx_11 + k],
        ];
        let sum = 0;
        let len = 0;
        src_alpha_arr.forEach((src_alpha, idx) => {
          if (src_alpha !== 0) {
            sum += src_color_arr[idx];
            len++;
          }
        });
        const avg_src_color = sum / len;
        src_alpha_arr.forEach((src_alpha, idx) => {
          if (src_alpha === 0) {
            src_color_arr[idx] = avg_src_color;
          }
        });
        src_pixel_arr[src_idx_00 + k] = src_color_arr[0];
        src_pixel_arr[src_idx_10 + k] = src_color_arr[1];
        src_pixel_arr[src_idx_01 + k] = src_color_arr[2];
        src_pixel_arr[src_idx_11 + k] = src_color_arr[3];
      }
    }
    for (let k = 0; k < 3; k++) {
      dest_pixel_arr[dest_idx + k] = solveBilinearFunction(
        src_pixel_arr[src_idx_00 + k],
        src_pixel_arr[src_idx_10 + k],
        src_pixel_arr[src_idx_01 + k],
        src_pixel_arr[src_idx_11 + k],
        dx,
        dy,
      );
    }
    const alpha_interpolated = solveBilinearFunction(
      src_pixel_arr[src_idx_00 + 3],
      src_pixel_arr[src_idx_10 + 3],
      src_pixel_arr[src_idx_01 + 3],
      src_pixel_arr[src_idx_11 + 3],
      dx,
      dy,
    );
    dest_pixel_arr[dest_idx + 3] = map_pixel_arr
      ? Math.min(alpha_interpolated, (map_pixel_arr[dest_idx + 3] / 65535) * 255)
      : alpha_interpolated;
  }
}

export const makeDesignReadyCanvasAtFront = async (assoc_composite_template) => {
  const map_url = assoc_composite_template.map_distort_url;
  const { width: w_original, height: h_original } = assoc_composite_template;
  const design_ready_canvas = createCanvasElement({ width: w_original, height: h_original });
  const design_ready_ctx = design_ready_canvas.getContext('2d');
  const { composite_masks } = assoc_composite_template._;
  await go(
    composite_masks,
    each(async (cm) => {
      await go(
        cm._.composite_faces,
        each(async ({ design_position, mockup_url, map_distort_url }) => {
          const { tl, tr, bl, br } = design_position;
          const mockup_image = await loadImageFromUrl(mockup_url);
          const { width: w_mockup, height: h_mockup } = mockup_image;
          const mockup_canvas = createCanvasElement({ width: w_mockup, height: h_mockup });
          mockup_canvas.getContext('2d').drawImage(mockup_image, 0, 0);
          //prettier-ignore
          await go(
            imageDistortion(mockup_canvas, w_mockup, h_mockup, w_original, h_original,
              0, 0, tl.x, tl.y,
              w_mockup, 0, tr.x, tr.y,
              0, h_mockup, bl.x, bl.y,
              w_mockup, h_mockup, br.x, br.y, await getPixelArrayFromURL(`${map_distort_url || map_url}` + '?canvas=1')),
            // fourPointMapping(mockup_canvas, w_mockup, h_mockup, w_original, h_original,
            //   0, 0, tl.x, tl.y,
            //   w_mockup, 0, tr.x, tr.y,
            //   0, h_mockup, bl.x, bl.y,
            //   w_mockup, h_mockup, br.x, br.y,
            // ),
            // async perspective_image_data =>
            //   lookUpTableMapping(
            //     convertImageDataToCanvas(perspective_image_data),
            //     w_original, h_original, w_original, h_original,
            //     await getPixelArrayFromURL(`${map_distort_url || map_url}` + '?canvas=1'),
            //     16,
            //     'bilinear'
            //   ),
            convertImageDataToCanvas,
            each_design_ready_canvas => {
              design_ready_ctx.drawImage(each_design_ready_canvas, 0, 0);
            }
          );
        }),
      );
    }),
  );
  return design_ready_canvas;
};
