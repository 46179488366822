import axios from 'axios';
import { loadImageFromUrl } from '../../../../Canvas/S/util.js';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';

export async function graphicsToZpl({ image_src }) {
  try {
    if (UtilStringS.isEmNil(image_src)) throw new Error(`이미지 경로가 필요합니다.`);

    const ext = getExtension({ path_str: image_src });
    if (ext !== 'png') throw new Error(`png 확장자만 가능합니다.`);

    const zpl = (
      await axios.get('/@api/df/label/convertImageToZPL', {
        params: {
          image_src,
          scale: 0.5,
          rotate: true,
        },
      })
    ).data;

    return {
      is_OK: true,
      data: zpl,
      error: null,
    };
  } catch (err) {
    console.error(err);
    return {
      is_OK: false,
      data: null,
      error: err,
    };
  }
}

function getExtension({ path_str }) {
  const file_name = path_str.split('/').pop().split('\\').pop();

  const last_dot_index = file_name.lastIndexOf('.');

  if (last_dot_index > 0) {
    return file_name.slice(last_dot_index + 1);
  } else {
    return null;
  }
}

async function imgProcessAndRotateCC90Image(img_src) {
  const SCALE = 1 / 2;

  const image = await loadImageFromUrl(img_src);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = image.height * SCALE;
  canvas.height = image.width * SCALE;

  ctx.imageSmoothingQuality = 'high';
  ctx.translate(0, canvas.height);
  ctx.rotate((-90 * Math.PI) / 180);
  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.height, canvas.width);

  const image_data = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const pixels = image_data.data;
  const TH = 125;

  for (let i = 3; i < pixels.length; i += 4) {
    const alpha = pixels[i];
    if (alpha < TH) {
      pixels[i - 3] = 255;
      pixels[i - 2] = 255;
      pixels[i - 1] = 255;
    } else {
      pixels[i - 3] = 0;
      pixels[i - 2] = 0;
      pixels[i - 1] = 0;
    }
    pixels[i] = 255;
  }

  // ctx.putImageData(image_data, 0, 0);
  // return image_data;

  const form_data = new FormData();
  form_data.append('file', new Blob([image_data]), { type: 'image/png' });

  return form_data;
}

async function canvasToFormData(canvas) {
  return new Promise((resolve) => {
    // Canvas에서 이미지 데이터를 ArrayBuffer로 가져오기
    canvas.toBlob(
      (blob) => {
        if (blob) {
          const reader = new FileReader();
          reader.onloadend = () => {
            // ArrayBuffer를 FormData에 추가하여 반환
            const formData = new FormData();
            formData.append('image', new Blob([reader.result], { type: 'image/png' }), 'processed_image.png');
            resolve(formData);
          };
          reader.readAsArrayBuffer(blob);
        }
      },
      'image/png',
      1, // 이미지 품질 (1이 가장 높은 품질)
    );
  });
}
