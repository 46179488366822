import { equals2, go, mapL, rangeL, strMap } from 'fxjs/es';
import { legacyHtml } from '../../Util/S/Function/util.js';

const makePaginationAnchorHtml = ({ page, url, is_current }) => legacyHtml`
  <a ${is_current ? 'class="current"' : `href="${url}"`}>${page}</a>
`;

export const makePaginationHtml = ({
  start,
  end,
  current_page,
  total_page_count,
  has_prev,
  has_next,
  is_not_last,
  makeUrlForPage,
} = {}) =>
  (start == 1 && start == end) || current_page > total_page_count
    ? ''
    : legacyHtml`
        <ul class="pagination-mp">
          ${
            is_not_last
              ? ''
              : legacyHtml`
                <li style="display: none;" class="pagination-item" data-page_type="first">
                  <a
                    data-direction="first"
                    ${current_page > 1 ? `href="${makeUrlForPage(1)}"` : 'class="disabled"'}
                    >«</a
                  >
                </li>
              `
          }
          <li class="pagination-item" data-page_type="prev">
            <a ${has_prev ? `href="${makeUrlForPage(start - 1)}"` : 'class="disabled"'}></a>
          </li>
          ${go(
            rangeL(start, end + 1),
            mapL((page) => ({
              page,
              url: makeUrlForPage(page),
              is_current: equals2(page, current_page),
            })),
            mapL(makePaginationAnchorHtml),
            strMap(
              (a_html) => legacyHtml` <li class="pagination-item" data-page_type="page">${a_html}</li> `,
            ),
          )}
          <li class="pagination-item" data-page_type="next">
            <a ${has_next ? `href="${makeUrlForPage(end + 1)}"` : 'class="disabled"'}></a>
          </li>
          ${
            is_not_last
              ? ''
              : legacyHtml`
                <li style="display: none;" class="pagination-item" data-page_type="last">
                  <a
                    data-direction="last"
                    ${
                      total_page_count > current_page
                        ? `href="${makeUrlForPage(total_page_count)}"`
                        : 'class="disabled"'
                    }
                    >»</a
                  >
                </li>
              `
          }
        </ul>
      `;
