import { html, go, object, strMap, values, every, identity } from 'fxjs/es';
import { $closest, $delegate, $find } from 'fxdom/es';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import zenginCode from 'zengin-code';

function renderBranchSelectEl(select_el, bank_code) {
  select_el.innerHTML = html`
    <option value="" selected>-</option>
    ${bank_code
      ? go(
          zenginCode[bank_code].branches,
          values,
          strMap(({ name, code }) => html` <option value="${code}">${name}</option> `),
        )
      : ``}
  `;
}

export const event = (tab_el) =>
  go(
    tab_el,
    $delegate('change', 'select[name="bank_code"]', (e) => {
      const bank_code = e.currentTarget.value;
      const branch_select_el = go(e.currentTarget, $closest('form'), $find('select[name="branch_code"'));

      renderBranchSelectEl(branch_select_el, bank_code);
    }),
    $delegate('click', '.submit', (e) => {
      const form_el = go(e.currentTarget, $closest('form'));
      const form = go(form_el, (el) => new FormData(el).entries(), object);
      const value = {
        bank_user_name: form.bank_user_name,
        bank_code: form.bank_code,
        branch_code: form.branch_code,
        bank_account: form.bank_account,
      };

      if (!go(value, values, every(identity))) {
        $.alert(T('refund_bank::06'));
      } else {
        MuiF.closeFrame(value);
      }
    }),
  );
