import { html } from 'fxjs/es';
export const findIdTmpl = () => {
  return html`
    <div class="find-id-wrapper">
      <div class="find-id">
        <form id="find-id__data" class="find-id__data">
          <h2 class="find-id__sub-title">휴대폰 인증</h2>
          <div class="find-id__data__name row">
            <h3 class="find-id__data__title">이름</h3>
            <input size="6" name="name" type="text" placeholder="이름을 입력해 주세요." maxlength="50" />
          </div>
          <div class="find-id__data__number row">
            <h3 class="find-id__data__title">연락처</h3>
            <input size="6" maxlength="11" name="mobile" type="text" placeholder="연락처를 입력하세요." />
            <button id="request-cert" class="find-id__button lightly" type="button">인증번호 요청</button>
          </div>
          <div class="find-id__data__cert disabled row">
            <input
              size="6"
              maxlength="6"
              name="code"
              type="number"
              placeholder="인증번호 6자리를 입력해주세요."
            />
            <div class="find-id__data__cert__timer-wrapper">
              <span class="find-id__data__cert__timer">5:00</span>
            </div>
          </div>
        </form>
        <div class="find-id__buttons">
          <button id="submit_code" class="find-id__button" type="submit" form="find-id__data">
            아이디 찾기
          </button>
        </div>
      </div>
    </div>
  `;
};
