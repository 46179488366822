import { $find, $setVal, $val } from 'fxdom/es';
import { go, sel } from 'fxjs/es';

export const eventSmsImageUpload = async (e) => {
  const el_textarea = $find('textarea[name="sms_body"]', e.delegateTarget);
  $.don_loader_start();
  try {
    await go(
      $.upload(e.currentTarget, {
        url: '/@api/sms_images/file_upload',
      }),
      (data) => {
        $setVal($val(el_textarea) + `https://marpple.com/si/${data.url}`, el_textarea);
      },
    );
  } catch (e) {
    if (sel('responseText.length', e) && sel('responseText', e).indexOf('File too large') != -1) {
      $.alert('파일 용량이 너무 큽니다.<br>50MB 이하로 업로드해 주세요.');
    } else {
      $.alert(
        '파일업로드가 실패했습니다.<br>다시 시도해주시거나<br><a href="mailto:partner@marppleshop.com">partner@marppleshop.com</a>에 문의해 주세요.',
      );
    }
  }
  $.don_loader_end();
};
