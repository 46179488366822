import Swal from 'sweetalert2';
import { html } from 'fxjs/es';

export async function confirm({ title, msg }) {
  const { isConfirmed } = await Swal.fire({
    icon: 'warning',
    title,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: '진행',
    cancelButtonText: '취소',
    html: html`<span>${msg}</span>`,
  });

  return isConfirmed;
}

export function success({ title, msg, timer = 1000 }) {
  Swal.fire({
    icon: 'success',
    timer,
    showConfirmButton: true,
    timerProgressBar: !!timer,
    title,
    html: msg,
    backdrop: false,
  }).then();
}

export async function error({ title, msg, timer = 3000 }) {
  await Swal.fire({
    icon: 'error',
    title,
    html: html`<span>${msg}</span>`,
    timerProgressBar: timer > 0,
    timer,
  });
}

export async function warning({ title, msg }) {
  await Swal.fire({
    icon: 'warning',
    title,
    html: html`<span>${msg}</span>`,
    showConfirmButton: true,
    confirmButtonText: '확인함',
  });
}
