import { pixelToIdx, makeEdgeBlurByCanvas, makeBlurByCanvas } from './composite_utils.js';
import { interpolateBilinear, interpolateNN } from './interpolation_filter_fns.js';
import { getBackwardProjectedPoint, getBackwardProjectionMatrix } from './matrix_fns.js';
//prettier-ignore
export function fourPointMapping(
  src_canvas,
  src_width, src_height, dest_width, dest_height,
  x1s,y1s,x1d,y1d,x2s,y2s,x2d,y2d,x3s,y3s,x3d,y3d,x4s,y4s,x4d,y4d,
) {
  const src_pixel_arr = makeEdgeBlurByCanvas(src_canvas, true).data;
  const dest_pixel_arr = new Uint8ClampedArray(dest_width * dest_height * 4);
  const backward_projection_matrix = getBackwardProjectionMatrix(x1s,y1s,x1d,y1d,x2s,y2s,x2d,y2d,x3s,y3s,x3d,y3d,x4s,y4s,x4d,y4d);
  for (let dest_y = 0; dest_y < dest_height; dest_y++) {
    for (let dest_x = 0; dest_x < dest_width; dest_x++) {
      const [src_x, src_y] = getBackwardProjectedPoint(backward_projection_matrix, dest_x, dest_y);
      if (src_x < 0 || src_y < 0 || src_y > src_height - 1 || src_x > src_width - 1) continue;
        interpolateBilinear(src_pixel_arr, dest_pixel_arr, src_x, src_y, src_width, pixelToIdx(dest_x, dest_y, dest_width));
    }
  }
  return new ImageData(dest_pixel_arr, dest_width, dest_height);
}

export function imageDistortion(
  src_canvas,
  src_width,
  src_height,
  dest_width,
  dest_height,
  x1s,
  y1s,
  x1d,
  y1d,
  x2s,
  y2s,
  x2d,
  y2d,
  x3s,
  y3s,
  x3d,
  y3d,
  x4s,
  y4s,
  x4d,
  y4d,
  map_pixel_arr,
) {
  console.time('distort');
  // const src_pixel_arr = makeEdgeBlurByCanvas(src_canvas, true).data;
  const src_pixel_arr = makeBlurByCanvas(src_canvas)
    .getContext('2d')
    .getImageData(0, 0, src_width, src_height).data;
  const dest_pixel_arr = new Uint8ClampedArray(dest_width * dest_height * 4);
  const backward_projection_matrix = getBackwardProjectionMatrix(
    x1s,
    y1s,
    x1d,
    y1d,
    x2s,
    y2s,
    x2d,
    y2d,
    x3s,
    y3s,
    x3d,
    y3d,
    x4s,
    y4s,
    x4d,
    y4d,
  );
  for (let dest_y = 0; dest_y < dest_height; dest_y++) {
    for (let dest_x = 0; dest_x < dest_width; dest_x++) {
      const dest_idx = pixelToIdx(dest_x, dest_y, dest_width);
      const mid_x = (map_pixel_arr[dest_idx] / 65535) * dest_width;
      const mid_y = (map_pixel_arr[dest_idx + 1] / 65535) * dest_height;
      if (mid_x < 0 || mid_y < 0 || mid_y > dest_height - 1 || mid_x > dest_width - 1) continue;
      if (map_pixel_arr[dest_idx + 3] > 1) {
        const [src_x, src_y] = getBackwardProjectedPoint(backward_projection_matrix, mid_x, mid_y);
        if (src_x < 0 || src_y < 0 || src_y > src_height - 1 || src_x > src_width - 1) continue;
        interpolateBilinear(src_pixel_arr, dest_pixel_arr, src_x, src_y, src_width, dest_idx, map_pixel_arr);
      }
    }
  }
  console.timeEnd('distort');
  return new ImageData(dest_pixel_arr, dest_width, dest_height);
}

export function lookUpTableMapping(
  src_canvas,
  src_width,
  src_height,
  dest_width,
  dest_height,
  map_pixel_arr,
  map_bit_depth,
  anti_aliasing_method = 'bilinear',
) {
  console.time('Lookup mapping');
  const src_pixel_arr = makeEdgeBlurByCanvas(src_canvas, true).data;
  const dest_pixel_arr = new Uint8ClampedArray(dest_width * dest_height * 4);
  for (let dest_y = 0; dest_y < dest_height; dest_y++) {
    for (let dest_x = 0; dest_x < dest_width; dest_x++) {
      const dest_idx = pixelToIdx(dest_x, dest_y, dest_width);
      const src_x = (map_pixel_arr[dest_idx] / 65535) * src_width;
      const src_y = (map_pixel_arr[dest_idx + 1] / 65535) * src_height;
      if (src_x < 0 || src_y < 0 || src_y > src_height - 1 || src_x > src_width - 1) continue;
      if (map_pixel_arr[dest_idx + 3] > 1) {
        switch (anti_aliasing_method) {
          case 'nearest_neighbor': {
            interpolateNN(src_pixel_arr, dest_pixel_arr, src_x, src_y, src_width, src_height, dest_idx);
            break;
          }
          case 'bilinear': {
            interpolateBilinear(
              src_pixel_arr,
              dest_pixel_arr,
              src_x,
              src_y,
              src_width,
              dest_idx,
              map_pixel_arr,
            );
            break;
          }
        }
      }
    }
  }
  console.timeEnd('Lookup mapping');
  return new ImageData(dest_pixel_arr, dest_width, dest_height);
}
