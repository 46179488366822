import { NewMakerConditionTooltipMoF } from '../../../ConditionTooltipMo/F/Function/module/NewMakerConditionTooltipMoF.js';
import { NewMakerCallConditionPopupF } from '../../../CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { is_mapping_confirm_popup } from '../../../CallConditionPopup/F/Function/callconditionpopup.js';
import { NewMakerConditionAgreeTextF } from '../../../ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

export class ConditionChecker {
  constructor() {
    this.is_passed = false;
  }

  passed() {
    this.is_passed = true;
    NewMakerCallConditionPopupF.resetForNotShowingWarning();
  }

  isPassed() {
    return this.is_passed;
  }

  async reset() {
    this.is_passed = false;
    await NewMakerCallConditionPopupF.reset();
  }

  async openConfirmModalMobile() {
    if (this.is_passed) return true;
    const is_done =
      !NewMakerConditionAgreeTextF.getAgreeTextData()?.length || !!(await NewMakerConditionTooltipMoF.open());
    if (is_done) {
      this.passed();
    }
    return is_done;
  }

  static canInstantiate() {
    return is_mapping_confirm_popup() && UtilF.isLegacyMobile();
  }
}
