import { identity } from 'fxjs/es';
import { addCanvasMargin, getJustifyCanvas } from '../../../../Maker/F/canvas_trim.js';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { changeDpiDataUrl } from 'changedpi';
import { format } from 'date-fns';

export function setCanvasSize({ canvas_el, width, height }) {
  if (canvas_el == null) throw new Error(`No canvas element exists`);
  canvas_el.width = width;
  canvas_el.height = height;
  canvas_el.style.width = `${width}px`;
  canvas_el.style.height = `${height}px`;

  return canvas_el;
}

export async function svgToImg({ svg_el }) {
  const svgXml = new XMLSerializer().serializeToString(svg_el);
  const blob = new Blob([svgXml], { type: 'image/svg+xml' });
  const url = URL.createObjectURL(blob);

  const image = new Image();
  image.src = url;

  return new Promise((res, rej) => {
    image.onload = () => {
      res(image);
    };
    image.onerror = (e) => {
      rej(e);
    };
  });
}

export function imageUrlToImageEl({ url, callback = identity }) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = function () {
      resolve(callback(image));
    };

    image.onerror = function () {
      reject(new Error('Failed to load image'));
    };

    image.src = url.includes('?') ? url + '&canvas=v10' : url + '?canvas=v10';
  });
}

export async function imageUrlToDataUrl({ url }) {
  return imageUrlToImageEl({ url, callback: (image) => getImageDataURL({ image }) });
}

export function imageToCanvas(image) {
  const canvas = document.createElement('canvas');
  cloneImageSizeToCanvas({ canvas, image });
  drawImageToCanvas({ canvas, image });
  return canvas;
}

export function toJustifyCanvas(canvas) {
  return getJustifyCanvas(canvas, 1);
}

export function getMarginAddFn({ upload_action, design_margin_x }) {
  return function (canvas) {
    if (upload_action !== 'margin-upload' || design_margin_x == null) {
      return canvas;
    } else {
      const { margin_x, margin_direction } = design_margin_x;
      return addCanvasMargin({
        src_canvas: canvas,
        margin_x,
        margin_direction,
      });
    }
  };
}

export function getSizeRecoverFn({ src_multiplier }) {
  return function (canvas) {
    return src_multiplier === 1 ? canvas : DfImageEditorF.resizeImage(canvas, 1 / src_multiplier);
  };
}

export function setDpiFn({ dpi }) {
  return function (data_url) {
    return changeDpiDataUrl(data_url, dpi);
  };
}

export function getImageDataURL({ image }) {
  const canvas = document.createElement('canvas');
  cloneImageSizeToCanvas({ canvas, image });
  drawImageToCanvas({ canvas, image });
  return canvas.toDataURL('image/png', 1);
}

function cloneImageSizeToCanvas({ canvas, image }) {
  canvas.width = image.width;
  canvas.height = image.height;
}

function drawImageToCanvas({ canvas, image }) {
  const context = canvas.getContext('2d');
  context.drawImage(image, 0, 0);
}

export async function dataUrlToImageEl({ data_url }) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.addEventListener('load', () => {
      resolve(img);
    });

    img.addEventListener('error', (error) => {
      reject(error);
    });

    img.src = data_url;
  });
}

export function setEmptyImageWithSize({ size }) {
  const image = new Image();
  image.width = size.w;
  image.height = size.h;

  return image;
}

export function addMarginToCanvas({ canvas, margin }) {
  const margin_canvas = document.createElement('canvas');
  const ctx = margin_canvas.getContext('2d');
  const { width: w, height: h } = canvas;

  margin_canvas.width = w + 2 * margin;
  margin_canvas.height = h + 2 * margin;

  ctx.drawImage(canvas, 0, 0, w, h, margin, margin, w, h);
  return margin_canvas;
}

export async function getImageWithMargin({ canvas, margin }) {
  return await canvasToImage({ canvas: addMarginToCanvas({ canvas, margin }) });
}

export async function canvasToImage({ canvas }) {
  return dataUrlToImageEl({ data_url: canvas.toDataURL() });
}

export function isImageElement(obj) {
  return obj instanceof HTMLImageElement;
}

/**
 * 이미지를 resize하는 함수
 * @param {HTMLCanvasElement} canvas 리사이즈할 캔버스
 * @param {number} resize_ratio 리사이즈 비율
 * @returns {HTMLCanvasElement} 리사이즈된 캔버스
 */

export function resizeImage(canvas, resize_ratio) {
  // 새로운 캔버스 생성
  const resizedCanvas = document.createElement('canvas');
  const context = resizedCanvas.getContext('2d');

  // 리사이즈된 크기 계산
  const newWidth = canvas.width * resize_ratio;
  const newHeight = canvas.height * resize_ratio;

  // 리사이즈된 캔버스 크기 설정
  resizedCanvas.width = newWidth;
  resizedCanvas.height = newHeight;

  // 이미지 리사이즈
  context.drawImage(canvas, 0, 0, newWidth, newHeight);

  // 리사이즈된 캔버스 반환
  return resizedCanvas;
}

export function downloadDataUrl(data_url, file_name) {
  const link = document.createElement('a');

  link.href = data_url;
  link.download = file_name ?? format(Date.now(), 'MM/dd HH:mm:ss');

  link.click();
  link.remove();
}
