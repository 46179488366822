import { html } from 'fxjs/es';

export const makeHtml = () => html`<div class="container">
  <div class="blank_plate"></div>
  <div class="wrapper">
    <div class="descriptions">
      <p class="description description1">
        ${T(
          `maker_upload_image_editor::업로드 가능한 이미지 파일은 JPEG 또는 PNG, SVG 파일이며 25MB 이하입니다.`,
        )}
      </p>
      <p class="description description2">
        ${T(
          `maker_upload_image_editor::제품의 색상과 디자인 시안 색상은 모니터 사양이나 컬러모드 (CMYK/RGB)에 따라 차이가 발생할 수 있으며 시안과 실제 상품의 인쇄 위치 및 크기의 오차가 발생할 수 있습니다. 이미지를 업로드하면 저작원에 대한 모든 권리와 책임이 있음을 인정하는 것입니다. 타인의 저작권 또는 개인정보 보호 권한을 침해하지 않음을 확인합니다.`,
        )}
      </p>
    </div>
    <div class="buttons">
      <button type="button" class="button add_img">${T(`maker_upload_image_editor::이미지 올리기`)}</button>
      <input type="file" accept="image/svg+xml,image/png,image/jpeg" />
      <button type="button" class="button my_lib">${T(`maker_upload_image_editor::내 이미지 보기`)}</button>
    </div>
  </div>
</div>`;
