function xy1xy2Distance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(Math.abs(x2 - x1), 2) + Math.pow(Math.abs(y2 - y1), 2));
}

export function getFDegree(x, y) {
  const radian = Math.atan2(y, x);
  const degree = radian / (Math.PI / 180);
  if (degree > 0) {
    return 360 - degree;
  } else {
    return Math.abs(degree);
  }
}

export function getMathRadian(current_deg) {
  if (current_deg > 180) {
    return (360 - current_deg) * (Math.PI / 180);
  } else {
    return -current_deg * (Math.PI / 180);
  }
}

export function locateXYInCircle(target_point, circle_point, angle) {
  const r = xy1xy2Distance(0, 0, target_point.x, target_point.y);
  const deg = getFDegree(target_point.x, target_point.y);
  const math_radian = getMathRadian(deg + angle);
  const x_to_move = Math.cos(math_radian) * r;
  const y_to_move = Math.sin(math_radian) * r;
  return {
    x: circle_point.x + x_to_move,
    y: circle_point.y - y_to_move,
  };
}
