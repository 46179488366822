import { LabelPage, StockLabelFormat } from '../../../Stock/zpl_module/zpl_class.js';
import { each, go } from 'fxjs/es';
import { writeToZPLdata } from '../../../Stock/zpl_module/zpl_command_utils.js';

export const labelAction = {
  // @description 라벨 포맷 객체를 생성하고 포맷 데이터를 받아서 포매팅 데이터를 설정
  createAndSetupFormat: (CONFIG) => {
    const { format_path, dpi, home, rotation, width, format_data } = CONFIG;
    if (format_path == null || dpi == null || home == null || rotation == null || width === null) {
      throw new Error(`Not exist part of config parameters`);
    }
    const label_format = new StockLabelFormat(format_path, dpi, ...home, rotation, width);
    label_format.setDefaultFont('J', 10);

    if (format_data == null) {
      throw new Error(`라벨 생성을 위한 format data 가 없습니다.`);
    }

    formattingLabel({ format: label_format, format_data });
    return label_format;
  },
  createPage: ({ format, label_data }) => {
    return new LabelPage(format, label_data, null);
  },
  setFormatZplToDevice: async ({ device, format }) => {
    const format_zpl = format.toDownloadFormat();
    await writeToZPLdata(device, format_zpl);
  },
};

// @description 라벨 포맷 객체에 요소의 포맷 형식을 주입 (side effect)
function formattingLabel({ format, format_data }) {
  go(
    format_data,
    each((f) => {
      const field = format.makeField(
        f.title,
        f.type,
        f.static_data,
        f.mutator,
        f.tree_name,
        f?.scan_code_type,
      );

      if (f.field_box) {
        const fb = f.field_box;
        field.setFieldBox(fb.width, fb.max_lines, fb.line_gap, fb.text_just, fb.indent);
      } else if (f.scan_code_type.includes('code93')) {
        field.setCode93BarCode(f.scan_code_options);
      } else if (f.scan_code_type.includes('code128')) {
        field.setCode128BarCode(f.scan_code_options);
      } else if (f.scan_code_type && f.scan_code_type.includes('aztec')) {
        field.setAztecCode(f.scan_code_options.mag);
      }

      const pos = f.position;
      const font = f.font;
      format.pushFieldData(field, pos.x, pos.y, font?.name, font?.orient, font?.height, font?.width);
    }),
  );
}
