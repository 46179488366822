import { writeToZPLdata } from '../../../Stock/zpl_module/zpl_command_utils.js';
import { zplWriteGenerator } from '../../../Stock/Labeling/F/Function/events.js';
import { waybill_cj_format_data, WAYBILL_CJ_LABEL_WIDTH } from './waybill_cj_formating.js';
import { each, go, identity, ifElse, isNil, map, filter, extend } from 'fxjs/es';
import { LabelPage, StockLabelFormat } from '../../../Stock/zpl_module/zpl_class.js';
import { $qs, $setCss } from 'fxdom/es';
import ProgressBar from 'progressbar.js';
import { DfWaybillF } from './module/DfWaybillF.js';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';

export const printWaybillCJ = async (
  label_data,
  { is_print_prj_qr, additional_label_datas, allowed_devices },
  printer_serial, // serial 번호가 들어오면 정확한 시리얼 번호 기기 연결성 검사
) => {
  try {
    if (isNil(label_data) || !label_data.length)
      return { is_OK: false, reason: 'CJ 운송장 출력 데이터가 없습니다. 개발팀 문의' };

    if (window.ENV?.label_printer_simulate) {
      return { is_OK: true };
    }

    const device = await DfWaybillF.prepareLabelPrinter({
      setting_fn: DfWaybillF.printer_config_fn.waybill_cj,
      allowed_devices,
      printer_serial,
    });

    const format = new StockLabelFormat('E:CJ_waybill_format.ZPL', 203, 0, 1.5, true, WAYBILL_CJ_LABEL_WIDTH);
    format.setDefaultFont('J', 10);

    go(
      waybill_cj_format_data,
      ifElse(
        () => is_print_prj_qr,
        identity,
        filter((format) => format.dev_name !== 'projection_id_2d_code'),
      ),
      ifElse(
        () => UtilObjS.isNotEmpty(additional_label_datas) && additional_label_datas?.formats != null,
        (formats) => formats.concat(additional_label_datas?.formats),
        identity,
      ), // 기존 format 을 덮어쓰지 않고 신규 key 만 추가
      each((format_data) => {
        const field = format.makeField(
          format_data.title,
          format_data.type,
          format_data.static_data,
          format_data.mutator,
          format_data.tree_name,
          format_data?.scan_code_type,
        );
        if (format_data.field_box) {
          //Text field box
          const fb = format_data.field_box;
          field.setFieldBox(fb.width, fb.max_lines, fb.line_gap, fb.text_just, fb.indent);
        } else if (format_data.scan_code_type.includes('code93')) {
          field.setCode93BarCode(format_data.scan_code_options);
        } else if (format_data.scan_code_type.includes('code128')) {
          field.setCode128BarCode(format_data.scan_code_options);
        } else if (format_data.scan_code_type.includes('aztec')) {
          field.setAztecCode(format_data.scan_code_options.mag);
        }
        const pos = format_data.position;
        const font = format_data.font;
        format.pushFieldData(field, pos.x, pos.y, font?.name, font?.orient, font?.height, font?.width);
      }),
    );

    const label_pages_zpl_str = go(
      label_data,
      map((label) => {
        UtilObjS.isNotEmpty(additional_label_datas) &&
          additional_label_datas?.dataFn != null &&
          extend(label, additional_label_datas?.dataFn(label));

        const page = new LabelPage(format, label, null);
        //Classification number prefix 에 underline 추가
        page.addGraphicBox(35.5, 23.5, 7, 0.3, 3, 'B', 0);
        //Sub-Classification number prefix 에 dash line 추가
        page.addGraphicBox(83, 18, 4, 0.5, 9, 'B', 0);
        page.addText(70, 82, 25, '본 상품은 CCTV 촬영후 발송됩니다.');
        return page.toZPLStr();
      }),
    );

    const format_zpl = format.toDownloadFormat();

    await writeToZPLdata(device, format_zpl);
    const id_progress_bar = 'print_progress_bar';
    let progress_bar_el = $qs(`#${id_progress_bar}`, document.body);
    if (progress_bar_el) {
      progress_bar_el.remove();
    }
    progress_bar_el = document.createElement('div');
    progress_bar_el.id = id_progress_bar;
    await document.body.appendChild(progress_bar_el);

    const size = { width: 100, height: 100 };
    $setCss(
      {
        position: 'fixed',
        width: `${size.width}px`,
        height: `${size.height}px`,
        top: `${(window.innerHeight - size.height) / 2}`,
        left: `${(window.innerWidth - size.width) / 2}`,
      },
      progress_bar_el,
    );

    const bar = new ProgressBar.Circle('#print_progress_bar', {
      color: '#222',
      // This has to be the same size as the maximum width to
      // prevent clipping
      strokeWidth: 10,
      trailWidth: 1,
      easing: 'easeInOut',
      duration: 700,
      text: {
        autoStyleContainer: false,
      },
      from: { color: '#555', width: 7 },
      to: { color: '#222', width: 10 },
      // Set default step function for all animate calls
      step: function (state, circle) {
        circle.path.setAttribute('stroke', state.color);
        circle.path.setAttribute('stroke-width', state.width);
        const value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText('');
        } else {
          circle.setText(value);
        }
      },
    });

    bar.text.style.fontSize = '2rem';
    bar._container.style.zIndex = Number.MAX_SAFE_INTEGER;

    $.don_loader_end();
    for await (const value of zplWriteGenerator(device, label_pages_zpl_str)) {
      bar.animate(value);
      if (value === 1) {
        window.setTimeout(() => {
          bar && bar.destroy();
          progress_bar_el && progress_bar_el.remove();
        }, 1000);
      }
    }
    return { is_OK: true };
  } catch (error) {
    return { is_OK: false, error, reason: error.message };
  }
};
