import './frame.js';
import './page.js';
export const frameOpenReviewDetail = (review, is_not_url) =>
  !review
    ? void 0
    : $.frame.open(
        {
          frame_name: 'mp.review.detail',
          page_name: 'mp.review.detail',
          is_modal: G.is_pc_size(),
          showed: function (frame) {
            if (frame.frame_opt.is_modal)
              $.css(
                $.find1(frame, '.comment_wrap'),
                'height',
                $.height($.find1(frame, '>.don_wrapper')) -
                  (!review.collabo_type == 'creator' && is_not_url ? 235 : 325),
              );
            if (is_not_url) return;
            window.box.unset('review_list->query->r_id');
            $.frame.extend_state(
              void 0,
              null,
              window.location.pathname + '?' + $.param({ r_id: review.id }),
              'replace',
            );
          },
          closing: function () {
            if (is_not_url) return;
            $.frame.extend_state(void 0, null, window.location.pathname, 'replace');
          },
        },
        {
          page_name: 'mp.review.detail',
          tabs: [
            {
              tab_name: 'mp.review.detail',
              data_func: () => review,
              inner_scroll_target: '.comment_wrap',
            },
          ],
        },
      );
