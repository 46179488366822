import { $find, $findAll, $on } from 'fxdom/es';
import { each, go, isNil } from 'fxjs/es';
import { VectorEditorSettingBackgroundMobileMuiS } from '../../S/Mui/module/VectorEditorSettingBackgroundMobileMuiS.js';
import { VectorEditorSettingBackgroundMobileEventF } from '../Event/module/VectorEditorSettingBackgroundMobileEventF.js';
import { VectorEditorSettingBackgroundMobileF } from '../Function/module/VectorEditorSettingBackgroundMobileF.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorSettingBackgroundMobileMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {
    go(
      tab_el$,
      $findAll('.blank_plate'),
      each($on('click', VectorEditorSettingBackgroundMobileEventF.handleBlankPlateClick)),
    );

    (() => {
      tab_el$.__mp_color = tab_el$.__mp_color ?? 'none';

      const color_selector_el = $find('.color_selector')(tab_el$);
      if (isNil(color_selector_el)) {
        return;
      }

      VectorEditorSettingBackgroundMobileF.setColor({
        color_selector_el,
        color_code: tab_el$.__mp_color,
      });

      const changeBackgroundColor = async (color_code) => {
        await tab_el$.__mp_handleBackgroundChange?.({
          color: color_code,
        });
        tab_el$.__mp_color = color_code;
      };

      each(
        $on(
          'click',
          VectorEditorSettingBackgroundMobileEventF.handleColorSelectorNotEtcClick({
            tab_el: tab_el$,
            f: changeBackgroundColor,
          }),
        ),
      )($findAll(`.color_selector .color[data-is_etc="false"]`)(tab_el$));

      each(
        $on(
          'click',
          VectorEditorSettingBackgroundMobileEventF.handleColorSelectorEtcClick({
            tab_el: tab_el$,
            f: changeBackgroundColor,
          }),
        ),
      )($findAll(`.color_selector .color[data-is_etc="true"]`)(tab_el$));
    })();
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
