import { html, extend, object, each, entries, go, pipe, filter, sel, pick } from 'fxjs/es';
import {
  $addClass,
  $closest,
  $delegate,
  $el,
  $find,
  $findAll,
  $hasClass,
  $qs,
  $removeAttr,
  $removeClass,
  $replaceWith,
  $setAttr,
  $setHTML,
} from 'fxdom/es';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { DfLglTrackingDetailMuiF } from '../../../Lgl/TrackingDetail/F/Mui/module/DfLglTrackingDetailMuiF.js';
import {
  isParcelShippingType,
  makeProjectionShipItemTmpl,
  makeShippingCompanyServiceOptions,
} from './shipItemTmpl.js';
import Swal from 'sweetalert2';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';
import { CJ, DHL, DHL_DIRECT, DOORA, EMS, HANJIN } from '../../../../Shipping/Waybill/S/constant.js';
import axios from 'axios';
import { makeDfProjectionListUpdate } from './fs.js';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';
import { UtilAlertF } from '../../../../Util/Alert/F/Function/module/UtilAlertF.js';
import { cjTrackingFromShippingId, lglExpressTracking } from './event.js';
import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import { handleInhouseToggle } from '../../../Inhouse/F/Function/event.inhouse.projection.js';
import { DfLglF } from '../../../Lgl/F/Function/module/DfLglF.js';
import { DfLglHaezleF } from '../../../Lgl/Haezle/F/Function/module/DfLglHaezleF.js';
import { makeApiUrl } from '../../../../Util/S/Function/util.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

function sanitizeToOnlyNumber({ currentTarget: ct }) {
  const input_val = ct.value;
  ct.value = UtilStringS.extractNumbersFromString(input_val);
}

export const shipping_events = pipe(
  $.on('input', '.shipping_item .field #mobile1', sanitizeToOnlyNumber),
  $.on('input', '.shipping_item .field #mobile2', sanitizeToOnlyNumber),
  $.on('input', '.shipping_item .field #mobile_en', sanitizeToOnlyNumber),
  $.on('input', '.shipping_item .field #waybill_number', sanitizeToOnlyNumber),
  $.on('input', '.shipping_item .field #waybill_number_inbound', sanitizeToOnlyNumber),
  $.on('click', '.shipping_item .buttons button', async ({ currentTarget: ct }) => {
    const projection = go(ct, $closest('.prj_item'), box.sel);
    const shipping_item_el = $closest('.shipping_item', ct);

    const action = ct.name;
    try {
      switch (action) {
        /* 배송 정보 수정 취소  */
        case 'cancel': {
          // box 데이터로 복원
          syncShippingItemHtmlWithBoxData({ projection, shipping_item_el });
          break;
        }

        /* 배송 편집 */
        case 'shipping_edit': {
          handleShippingItemEditable(shipping_item_el);
          focusToWaybillInput(shipping_item_el);

          break;
        }

        /* 사내 배송 편집 */
        case 'inhouse_shipping_edit': {
          await DfInhouseF.handleInhouseShippingEdit({ projection, shipping_item_el });
          syncShippingItemHtmlWithBoxData({ projection, shipping_item_el });
          break;
        }

        /* 사내 배송 (개인/사내 전환) */
        case 'inhouse_shipping_toggler': {
          await handleInhouseToggle({ ct, projection, shipping_item_el });
          syncShippingItemHtmlWithBoxData({ projection, shipping_item_el });

          makeDfProjectionListUpdate();

          const prj_detail_el = $qs('.don_frame.projection_detail');
          prj_detail_el && MuiF.closeFrame(prj_detail_el);

          break;
        }

        /* 해외 배송 LGL 관리 */
        case 'lgl_manager': {
          await DfLglF.handleOpenDetail({ projection });
          break;
        }

        /* 배송 정보 수정 저장 */
        case 'save': {
          const shipping_ori = box.sel(ct);
          const shipping_tobe_update = go(
            ct,
            $closest('.shipping_item'),
            selectsInputsToValueObjects,
            compareChanged(shipping_ori),
          );

          /* 0. 배송 수정 변경사항이 하나도 없는 경우에는 편집 모드 종료 */
          if (UtilObjS.isEmNil(shipping_tobe_update)) {
            UtilAlertF.success({ title: '변경사항 없음' });
            triggerShippingItemEditCancel(ct);
            return;
          }

          /* 1. 묶음 배송으로 변경 -> 부모 주문서 번호를 요구 받음 */
          let parent_projection_id;
          let is_diff_orderer = false;
          if (shipping_tobe_update.type === 'combined') {
            const { isConfirmed, value } = await Swal.fire({
              title: '묶음 주문 변경',
              html: getCombinedPopupHtml(
                '<b>부모 주문서의 ID</b>를 입력해 주세요.<br />부모 주문서는 맨위에 있는 <b>택배</b>주문서입니다.',
              ),
              input: 'number',
              showCancelButton: true,
              cancelButtonText: '취소',
              showConfirmButton: true,
              confirmButtonText: '적용',
              didOpen: (el) => {
                go(
                  el,
                  $delegate('click', 'input#diff_orderer', ({ currentTarget: ct }) => {
                    is_diff_orderer = ct.checked;
                  }),
                );
              },
              preConfirm: (input_value) => {
                if (UtilStringS.isEmNil(input_value)) {
                  Swal.showValidationMessage(`주문번호를 확인해 주세요. 부모 주문번호는 하나입니다.`);
                }
              },
            });
            if (!isConfirmed || !value) return;
            parent_projection_id = value;
          }

          if (shipping_tobe_update.is_combined) {
            const { isConfirmed, value } = await Swal.fire({
              title: '퀵+퀵, 방문+방문 묶기',
              html: getCombinedPopupHtml(
                '묶을 <b>주문번호</b>를 입력해 주세요.<br><strong>같은 배송방식</strong> 이어야 합니다.',
              ),
              input: 'number',
              showCancelButton: true,
              cancelButtonText: '취소',
              showConfirmButton: true,
              confirmButtonText: '적용',
              didOpen: (el) => {
                go(
                  el,
                  $delegate('click', 'input#diff_orderer', ({ currentTarget: ct }) => {
                    is_diff_orderer = ct.checked;
                  }),
                );
              },
              preConfirm: (input_value) => {
                if (UtilStringS.isEmNil(input_value)) {
                  Swal.showValidationMessage(`주문번호를 확인해 주세요. 부모 주문번호는 하나입니다.`);
                }
              },
            });
            if (!isConfirmed || !value) return;
            parent_projection_id = value;
          }

          /* 2. 운송장 번호 변경 || 운송사 변경된 경우, 운송장 번호 검증 */
          if (shipping_tobe_update.waybill_number || shipping_tobe_update.shipping_company_id) {
            const waybill_validation_result = { is_OK: true, error: null };
            /* 택배 유형이 아니면 검증 안함 */
            const is_parcel_type = ['parcel', 'combined', 'partition'].includes(
              shipping_tobe_update.type ?? shipping_ori.type,
            );
            if (is_parcel_type) {
              const shipping_company_id =
                shipping_tobe_update.shipping_company_id ?? shipping_ori.shipping_company_id;
              const shipping_waybill_number = (() => {
                if (shipping_tobe_update.waybill_number === null) {
                  return null;
                }

                if (UtilS.isNotEmpty(shipping_tobe_update.waybill_number)) {
                  return shipping_tobe_update.waybill_number;
                }

                return shipping_ori.waybill_number;
              })();

              if (
                UtilStringS.isNotEmpty(shipping_waybill_number) &&
                !/^mps-/i.test(shipping_waybill_number)
              ) {
                const waybill_validation_error = await validateWaybillNumber({
                  shipping_company_id,
                  projection,
                  waybill_number: shipping_waybill_number,
                });

                if (waybill_validation_error) {
                  waybill_validation_result.is_OK = false;
                  waybill_validation_result.error = waybill_validation_error;
                }
              }
            }

            if (waybill_validation_result.is_OK === false) {
              await UtilAlertF.error({
                title: '운송장 번호 오류',
                msg: `<span>${waybill_validation_result.error.message}</span>`,
                timer: 0,
              });
              return;
            }
          }

          if (shipping_tobe_update.shipping_company_id === 'hanjin') {
            await UtilAlertF.warning({
              title: '한진 작업 확인',
              msg: '한진 택배는 수동으로 운송장 처리를 해 주셔야 합니다.',
            });
          }

          /* 3. 같이 묶인 주문들이 있는 주문에 대해 운송장을 신규 입력하는 케이스에서는 더블체크 */
          if (
            UtilStringS.isEmNil(shipping_ori.waybill_number) &&
            UtilStringS.isNotEmpty(shipping_tobe_update.waybill_number)
          ) {
            const has_combined_projection = go(
              projection,
              sel(['_.projection._.projections', { type: 'combined' }]),
              Boolean,
            );
            if (has_combined_projection) {
              if (!(await UtilAlertF.confirm({ title: '묶음 배송건 재확인', msg: '계속 진행하시겠습니까?' })))
                return;
            }
          }

          if (shipping_tobe_update.country) {
            const country_name_tobe_update = shipping_tobe_update.country;

            const country = (
              await axios.get(makeApiUrl('/@api/shipping/country/:name', { name: country_name_tobe_update }))
            ).data;

            if (country == null)
              throw new Error(
                `해당 국가 이름으로 배송지 국가 정보를 찾을 수 없습니다.\n국가 이름을 다시 확인해 주세요.`,
              );

            // 국가 id 도 항상 같이 업데이트해서 정합성 맞춤
            shipping_tobe_update.country_id = country.id;
            shipping_tobe_update.country = country.name;
          }
          /* 3. 배송 정보 변경된 값들만 업데이트 */
          try {
            $.don_loader_start();

            await go(
              /* 배송 정보 업데이트 */
              $.post('/@api/shipping/modify', {
                attrs: extend(
                  pick(
                    [
                      'id',
                      'type',
                      'name',
                      'address',
                      'address2',
                      'mobile1',
                      'mobile2',
                      'orderer_sms',
                      'postcode',
                      'shipping_company_id',
                      'shipping_company_service_id',
                      'waybill_number',
                      'memo',
                    ],
                    shipping_ori,
                  ),
                  shipping_tobe_update,
                ),
                parent_projection_id: is_diff_orderer
                  ? '' + parent_projection_id + '다른주문자'
                  : parent_projection_id,
              }),
              async (response) => {
                $.don_loader_end();
                const update_result = response.status;
                if (update_result === false) {
                  await alertShippingWarning(response?.msg);
                  return;
                }

                UtilAlertF.success({ title: '배송 정보 업데이트' });

                /* 가격 정보 변동 반영 */
                const { data } = await axios.post('/@api/shipping_cost/set_projection_price', {
                  shipping_id: shipping_ori.id,
                  parent_projection_id,
                });

                if (data?.message) {
                  await alertShippingWarning(data?.message);
                }

                /* 태스크, 주문서 상태 업데이트 */
                await makeDfProjectionListUpdate();
                await G.df.task.change_updated_tasks();

                if ($1('.don_frame.projection_detail')) {
                  const don_tab = $.closest(ct, '.don_tab');
                  box.sel('df/projection/detail->projection').updated_at = null;
                  don_tab.tab_opt.showed(don_tab);
                }
              },
            );
            if (
              projection.collabo_type === 'creator' &&
              (UtilS.isNotEmpty(shipping_tobe_update.waybill_number) ||
                UtilS.isNotEmpty(shipping_tobe_update.shipping_company_id))
            ) {
              (
                await axios.post('/@api/item_orders/shipping_info', {
                  projection_id: shipping_ori.projection_id,
                  waybill_number: shipping_tobe_update.waybill_number,
                  shipping_company_id: shipping_tobe_update.shipping_company_id,
                })
              ).data;
            }
          } catch (err) {
            $.don_loader_end();
            await alertShippingWarning(err.response?.data?.message || err.message);
          }
          break;
        }

        /* 운송장 폼 다운로드 */
        case 'download': {
          const is_outsourcing_worker_policy = box.sel('is_user->_->policies->outsourcing_worker_policy');
          if (!is_outsourcing_worker_policy) {
            // 내부 워커인 경우에는 재확인
            if (
              !(await UtilAlertF.confirm({
                title: '재확인',
                msg: '전체 운송장 다운로드에서 안나오게 됩니다.<br><span style="color:red;">그래도 받으시겠습니까?</span>',
              }))
            )
              return;

            const { id: shipping_id, projection_id, shipping_company_id } = box.sel(shipping_item_el);
            submitForm({
              url: '/@api/waybill/down',
              fields: {
                shipping_id,
                projection_id,
                shipping_company_id,
                collabo_type: projection.collabo_type === '' ? 'mp' : projection.collabo_type,
              },
            });
          }
          break;
        }

        /* 배송 추적 */
        case 'tracking_cj': {
          const { id: shipping_id } = box.sel(shipping_item_el);
          await cjTrackingFromShippingId({ shipping_id });
          break;
        }
        case 'tracking_express': {
          const tracking_list = await lglExpressTracking({ projection_id: projection.id });

          await MuiF.openFrame(DfLglTrackingDetailMuiF.frame, async (frame, page, [tab]) => {
            tab.makeData = () => ({ data: tracking_list });
          });
          break;
        }

        /* LGL HAEZLE 운송장 폼 관리 기능 */
        case 'lgl_express_excel_down': {
          await DfLglHaezleF.downloadLglHaezleForm({ prj: projection });
          break;
        }
        case 'lgl_express_excel_up': {
          await DfLglHaezleF.upLoadLglHaezleForm();
          break;
        }

        default:
          throw new Error(`정의되지 않은 버튼 동작 입니다. ${action}`);
      }
    } catch (err) {
      ConfigSentryF.error(err);
      await UtilAlertF.error({
        title: '에러 발생 (개발팀 문의)',
        msg: err?.response?.data ?? err.message,
        timer: 0,
      });
    }
  }),
  $.on('change', '.shipping_item .carrier .field.type select', ({ currentTarget: ct }) => {
    const selected_option = ct.options[ct.selectedIndex];

    if (selected_option) {
      const selected_value = selected_option.value;
      const is_parcel_type_after = isParcelShippingType(selected_value);
      const shipping_item_el = go(ct, $closest('.shipping_item'));
      go(shipping_item_el, $setAttr({ is_parcel_type: is_parcel_type_after }));

      const shipping_asis = box.sel(shipping_item_el);

      if (shipping_asis.type === 'combined' && !is_parcel_type_after) {
        // combined 는 택배 묶음이므로 묶음이었다가 퀵, 방문으로 변경 시도하면서 묶음을 못하게 막음 (기존 부모 주문서의 배송 방식과 묶음 부조화 발생)
        go(shipping_item_el, $find('.carrier input[name="is_combined"]'), $setAttr({ disabled: true }));
      }
    }
  }),
  $.on('change', '.carrier .field.is_combined input', ({ currentTarget: ct }) => {
    const is_checked = ct.checked;

    const shipping_method_select_el = go(
      ct,
      $closest('.shipping_item'),
      $find('.carrier .field.type select'),
    );

    is_checked
      ? $setAttr({ disabled: true }, shipping_method_select_el)
      : $removeAttr('disabled', shipping_method_select_el);
  }),
  $.on('click', '.address_search', ({ currentTarget: ct }) => {
    const shipping_item = $closest('.shipping_item', ct);
    const address_search_layer = go(shipping_item, $find('.address_search_layer'));

    const is_opened = $hasClass('open', ct);
    if (is_opened) {
      $removeClass('open', ct);
      $removeClass('open', address_search_layer);
    } else {
      $addClass('open', ct);
      $addClass('open', address_search_layer);
      new daum.Postcode({
        theme: { searchBgColor: '#0B65C8', queryTextColor: '#FFFFFF' },
        oncomplete: function (data) {
          let fullAddr = data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress;
          let extraAddr = '';
          if (data.userSelectedType === 'R') {
            if (data.bname !== '') extraAddr += data.bname;
            if (data.buildingName !== '')
              extraAddr += extraAddr !== '' ? ', ' + data.buildingName : data.buildingName;
            fullAddr += extraAddr !== '' ? ' (' + extraAddr + ')' : '';
          }
          $.val($.find1(shipping_item, 'input[name="postcode"]'), data.zonecode);
          $.val($.find1(shipping_item, 'input[name="address"]'), fullAddr);
          $removeClass('open', ct);
          $removeClass('open', address_search_layer);
        },
        ...(window.is_ie ? {} : { submitMode: false }),
        width: '100%',
        height: '100%',
      }).embed(address_search_layer);
    }
  }),
  $.on('keydown', '.shipping_item input', (e) => {
    if (e.key === 'Escape') {
      triggerShippingItemEditCancel(e.currentTarget);
    }
  }),
  $.on('change', '.shipping_item select[name="shipping_company_id"]', (e) => {
    const ct = e.currentTarget;
    const shipping_company_services = box.sel('shipping_company_services');
    const shipping_company_id = ct.value;

    const shipping_company_service_options_html = makeShippingCompanyServiceOptions({
      shipping_companies: shipping_company_services,
      selected_shipping_company_id: shipping_company_id,
      selected_shipping_company_service_id: null,
    });

    go(
      ct,
      $closest('.shipping_item.editable'),
      $find('select[name="shipping_company_service_id"]'),
      $setHTML(shipping_company_service_options_html),
    );
  }),
);

function submitForm({ method = 'POST', url, fields }) {
  const form = document.createElement('form');
  form.method = method;
  form.action = url;

  go(
    fields,
    entries,
    each(([name, value]) => {
      const input = document.createElement('input');
      input.name = name;
      input.value = value;
      form.appendChild(input);
    }),
  );
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

async function alertShippingWarning(msg) {
  return await UtilAlertF.warning({
    title: '배송 변경',
    msg: msg ?? '',
  });
}

function getCombinedPopupHtml(msg) {
  return html`
    <div style="margin-bottom:8px;">
      <span style="font-size:18px;">${msg} </span>
    </div>
    <div style="font-size:18px;">
      <input type="checkbox" id="diff_orderer" style="transform:scale(1.5);cursor:pointer" />
      <label for="diff_orderer" style="cursor:pointer;user-select:none;">다른 주문자 묶음시 체크 </label>
    </div>
  `;
}

function triggerShippingItemEditCancel(ct) {
  const cancel_button_el = go(ct, $closest('.shipping_item'), $find('.buttons button[name="cancel"]'));
  cancel_button_el && cancel_button_el.click();
}

async function validateWaybillNumber({ projection, shipping_company_id, waybill_number }) {
  const ERROR_MSG_1 = '주문서의 운송장 번호를 다시 확인해 주세요.';
  const ERROR_MSG_2 = ERROR_MSG_1 + '<br>또는 라인과 마플이 바뀌지 않았는지 확인해 주세요.';
  const ERROR_MSG_3 =
    ERROR_MSG_1 + '<br>또는 운송장이 등록중일 수도 있습니다.<br>잠시 후 다시 시도해 주세요.';
  const ERROR_MSG_4 = ERROR_MSG_1 + '<br>12자리 숫자여야 합니다.';

  let error = null;

  switch (shipping_company_id) {
    case DOORA: {
      const doora_result = await $.get('/@api/is_doora', {
        waybill_number,
        collabo_type: projection.collabo_type,
        prj_id: projection.id,
      });
      if (doora_result === 'error') error = new Error(ERROR_MSG_2);
      if (doora_result === 'shipping') error = new Error(ERROR_MSG_1);
      break;
    }
    case DHL:
    case DHL_DIRECT: {
      const dhl_result = await $.get('/@api/is_dhl', {
        waybill_number,
        prj_id: projection.id,
      });
      if (dhl_result === 'error') error = new Error(ERROR_MSG_3);
      break;
    }
    case CJ:
    case HANJIN: {
      if (waybill_number.match(/[^0-9]/g) || waybill_number.length !== 12) error = new Error(ERROR_MSG_4);
      break;
    }
    case EMS: {
      if (waybill_number.search(/kr/i) === -1) error = new Error(ERROR_MSG_1);
      break;
    }
  }
  return error;
}

function selectsInputsToValueObjects(el) {
  const obj = {};
  go(
    [...$findAll('input', el), ...$findAll('select', el)],
    each((el) => {
      const key = el.name;
      let value = el.type === 'checkbox' ? el.checked : el.value;
      const is_hidden = window.getComputedStyle(el.parentElement)?.display === 'none';
      if (is_hidden || value === '') value = null;

      key && (obj[key] = value);
    }),
  );

  return obj;
}

function compareChanged(reference) {
  return pipe(
    entries,
    filter(([k, v]) => {
      return reference[k] !== v;
    }),
    object,
  );
}

function handleShippingItemEditable(shipping_item_el) {
  const is_editable_current = $hasClass('editable', shipping_item_el);
  const toggleShippingItemEditable = (shipping_item_el) => {
    !is_editable_current
      ? $addClass('editable', shipping_item_el)
      : $removeClass('editable', shipping_item_el);
  };

  const toggleInputReadonly = (input_el) => {
    input_el && (input_el.readOnly = is_editable_current);
  };

  toggleShippingItemEditable(shipping_item_el);
  go(shipping_item_el, $findAll('input'), each(toggleInputReadonly));
}

function focusToWaybillInput(shipping_item_el) {
  const waybill_number_input_el = $find('input#waybill_number', shipping_item_el);

  if (waybill_number_input_el) {
    waybill_number_input_el.focus();
    waybill_number_input_el.select();
  }
}

export function syncShippingItemHtmlWithBoxData({ projection, shipping_item_el }) {
  const shipping = box.sel(shipping_item_el);

  go(makeProjectionShipItemTmpl(projection, shipping), $el, (el) => $replaceWith(el, shipping_item_el));
}
