import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPAuthS } from '../Function/module/OMPAuthS.js';

export const SIGN_UP = () => ({
  inputs: {
    name: {
      template: () =>
        OMPCoreAtomTmplS.input({
          name: 'name',
          input_id: 'name',
          type: 'text',
          style: { underline: true },
          placeholder: TT('auth::signup::signup_02'),
          required: true,
          minlength: 2,
        }),
      valid: (v) => {
        if (!v || v.length < 2) {
          return TT('error::auth::error_06');
        }

        if (OMPCoreUtilS.isSpecialStr(v)) {
          return TT('error::auth::error_06_01');
        }
      },
    },
    email: {
      template: () =>
        OMPCoreAtomTmplS.input({
          name: 'email',
          input_id: 'email',
          type: 'email',
          style: { underline: true },
          placeholder: TT('auth::signup::signup_03'),
          required: true,
        }),
      valid: (v) => {
        if (!OMPCoreUtilS.isValidEmail(v)) {
          return TT('error::auth::error_05');
        }
      },
    },
    password: {
      template: () =>
        OMPCoreAtomTmplS.input({
          name: 'password',
          input_id: 'password',
          type: 'password',
          style: { underline: true },
          placeholder: TT('auth::signup::signup_04'),
          required: true,
          minlength: 10,
          maxlength: 64,
        }),
      valid: (v) => {
        const res = OMPAuthS.validPassword(v);

        if (!res.result) {
          return res.message;
        }
      },
    },
  },
  terms: {
    agree_use: {
      template: () =>
        html`
          <div class="omp-auth-signup__checkbox-wrapper">
            ${OMPCoreAtomTmplS.emptyCheckbox({
              name: 'agree_use',
              title: TT('auth::signup::signup_05'),
              style: { size: 'small' },
            })}
            <a href="/terms" class="agree_use" target="_blank">${TT('auth::signup::signup_09')}</a>
          </div>
        `,
      valid: (v) => {
        if (v !== 'on') {
          return TT('error::auth::error_07');
        }
      },
    },
    agree_privacy: {
      template: () =>
        html`
          <div class="omp-auth-signup__checkbox-wrapper">
            ${OMPCoreAtomTmplS.emptyCheckbox({
              name: 'agree_privacy',
              title: html`${TT('auth::signup::signup_06')}<a
                  href="/privacy"
                  class="agree_privacy"
                  target="_blank"
                  >${TT('auth::signup::signup_09')}</a
                >`,
              style: { size: 'small' },
            })}
          </div>
        `,
      valid: (v) => {
        if (v !== 'on') {
          return TT('error::auth::error_08');
        }
      },
    },
    agree_age: {
      template: () =>
        TT.lang !== 'en'
          ? html`
              <div class="omp-auth-signup__checkbox-wrapper">
                ${OMPCoreAtomTmplS.emptyCheckbox({
                  name: 'agree_age',
                  title: TT('auth::signup::signup_07'),
                  style: { size: 'small' },
                })}
              </div>
            `
          : '',
      valid: (v) => {
        if (TT.lang === 'en') return;
        if (v !== 'on') {
          return TT('error::auth::error_09');
        }
      },
    },
    agree_email: {
      template: () =>
        TT.lang === 'kr'
          ? html`
              <div class="omp-auth-signup__checkbox-wrapper">
                ${OMPCoreAtomTmplS.emptyCheckbox({
                  name: 'agree_email',
                  title: TT('auth::signup::signup_08'),
                  style: { size: 'small' },
                })}
                <a href="#" class="agree_email" target="_blank">${TT('auth::signup::signup_09')}</a>
              </div>
            `
          : '',
    },
  },
});

export const LOGIN = () => ({
  inputs: {
    email: {
      template: () =>
        OMPCoreAtomTmplS.input({
          name: 'email',
          input_id: 'email',
          type: 'email',
          placeholder: TT('auth::login::login_01'),
          style: {
            underline: true,
          },
        }),

      valid: (v) => {
        if (!OMPCoreUtilS.isValidEmail(v)) {
          return TT('error::auth::error_05');
        }
      },
    },
    password: {
      template: () =>
        OMPCoreAtomTmplS.input({
          name: 'password',
          input_id: 'password',
          type: 'password',
          placeholder: TT('auth::login::login_03'),
          style: { underline: true },
        }),
      valid: (v) => {
        const res = OMPAuthS.validPassword(v);

        if (!res.result) {
          return res.message;
        }
      },
    },
  },
});
