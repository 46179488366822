import { html, isNil, sumBy } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const makeDfReviewListAdminNoTranslateHtml = (comment) => html`
  <div>${UtilS.escape(comment) || '번역해 주세요.'}</div>
  <br />
`;

const makeAdminReviewInnerHtml = (review) => html`
  ${isNil(review.is_main_check)
    ? ''
    : html`
        <div class="is_main_check admin_review_item">
          <h4 class="is_main_check">메인 노출</h4>
          <label
            ><input type="checkbox" ${review.is_main_check ? 'checked' : ''} />메인 노출 (마플샵 전용)</label
          >
        </div>
      `}
  ${review.collabo_type == ''
    ? html`<div class="is_best admin_review_item">
        <h4>Hot</h4>
        <label><input type="checkbox" ${review.is_best ? 'checked' : ''} />핫한 리뷰 (마플 전용)</label>
      </div>`
    : ''}
`;

export const makeDfReviewListAdminButtonsHtml = (review) => html`
  <div class="admin_review">
    ${makeAdminReviewInnerHtml(review)}
    <div class="buttons admin_review_item">
      <h4>삭제</h4>
      <button class="is_public" type="button" is_public="${review.is_public}" attr="is_public"></button>
    </div>
  </div>
`;

const saveButton = (val, msg) =>
  html`<button class="save" type="button" value="${parseInt(val)}">${val}${msg}</button>`;

const setPoint = ({ review, discounted_price, quantity }) => {
  const price = discounted_price * quantity;
  if (!review.has_photo) {
    return price >= 3000 ? 50 : 25;
  }
  if (price >= 20000) {
    return 300;
  } else if (price >= 15000) {
    return 200;
  } else if (price >= 5000) {
    return 100;
  } else {
    return 50;
  }
};
export const makeDfReviewListAdminPointHtml = (review) => {
  const {
    discounted_price,
    _: { up_c_ss, item_order },
  } = review._.up_c;
  const quantity = sumBy(({ quantity }) => {
    return quantity;
  }, up_c_ss);
  const point = setPoint({ review, discounted_price, quantity });
  const is_inhouse = item_order?._?.store_order?._?.customer_order_crew_inhouse?.id;
  return html`
    ${review.merged_type == 'sample'
      ? html`<p style="background: #002aff; color: #fff;">샘플주문입니다.</p>`
      : is_inhouse
      ? html`<p style="background: #B4E114;">사내주문입니다.</p>`
      : ''}
    ${is_inhouse
      ? ''
      : html`
          <h4>포인트</h4>
          <div>
            ${review.point
              ? html`
                  <button class="notice" type="button">${review.point} 포인트 적립완료</button>
                  <button class="save" type="button" value="0">적립 취소</button>
                `
              : html`
                  ${saveButton(point)}${review.sns_url ? saveButton(500, ' (+SNS)') : ''}
                  <input type="number" value="0" style="margin: 0 -1px 0 10px;" />
                  <button class="save" type="button" value="input">적립</button>
                `}
          </div>
          <div class="up_info">
            <div class="cell">
              <div class="title">인쇄된 상품가격</div>
              <div class="value">${UtilS.commify(discounted_price)}</div>
            </div>
            <div class="cell">
              <div class="title">개수</div>
              <div class="value">${UtilS.commify(quantity)}</div>
            </div>
            <div class="cell">
              <div class="title">총 가격</div>
              <div class="value">${UtilS.commify(discounted_price * quantity)}</div>
            </div>
            <div class="cell">
              <div class="title">SNS</div>
              <div class="value">
                ${!review.sns_url
                  ? '없음'
                  : html`<a href="${UtilS.escape(review.sns_url)}" target="_blank" style="color:#002aff"
                      >확인하기</a
                    >`}
              </div>
            </div>
          </div>
        `}
  `;
};

export const makeDfReviewListAdminReplyHtml = (review) => html`
  <div class="admin_review">
    ${makeAdminReviewInnerHtml(review)}
    <div class="txt reply admin_review_item">
      <h4>댓글작성</h4>
      <div>
        <textarea class="reply" placeholder="댓글을 작성해 주세요.">
${UtilS.escape(review.reply) || ''}</textarea
        >
        <button class="reply" type="button">write</button>
      </div>
    </div>
    <div class="points admin_review_item">${makeDfReviewListAdminPointHtml(review)}</div>
    <div class="buttons admin_review_item">
      <h4>삭제</h4>
      <button class="is_public" type="button" is_public="${review.is_public}" attr="is_public"></button>
    </div>
  </div>
`;

export const makeDfReviewListAdminCommnet = (review) => html`
  <div class="translate">
    <div class="original_comment">
      <h4>국문</h4>
      <p>${UtilS.escape(review.comment)}</p>
    </div>
    <div class="translate_comment_en">
      <h4>영문</h4>
      <div></div>
    </div>
    <div class="translate_comment_jp">
      <h4>일문</h4>
      <div></div>
    </div>
    <div class="comment_en">
      <h4>영문번역</h4>
      <textarea class="comment_en" placeholder="영문">${UtilS.escape(review.comment_en) || ''}</textarea>
      <button class="comment_en" type="button" lang="en">write</button>
    </div>
    <div class="comment_jp">
      <h4>일문번역</h4>
      <textarea class="comment_jp" placeholder="일문">${UtilS.escape(review.comment_jp) || ''}</textarea>
      <button class="comment_en" type="button" lang="jp">write</button>
    </div>
  </div>
`;
