import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { ORDER_STATUS } from '../../Order/S/Constant/order.js';
import { OMPMyPageOrderTmplS } from '../../Order/S/Tmpl/module/OMPMyPageOrderTmplS.js';
import { html } from 'fxjs/es';

export const MyPageMenu = () => {
  const order = {
    title: TT('my_page::order::order_01'),
    key: 'order',
    url: `order`,
  };
  const wish = { title: TT('my_page::wish::wish_01'), key: 'wish', url: 'wish/list' };
  const my_review = { title: TT('my_page::my_review::my_review_01'), key: 'my_review', url: 'my/review' };
  const info = { title: TT('my_page::my_info::my_info_01'), key: 'info', url: 'my/info' };

  // kr only
  const point = { title: TT('my_page::point::point_01'), key: 'point', url: 'my/point' };

  if (TT.lang === 'kr') {
    return { order, wish, point, my_review, info };
  }
  return { order, wish, my_review, info };
};

export const getMyPageMenuDataPair = () => {
  const my_page_menu = MyPageMenu();
  const cart = {
    url: 'cart',
    title: TT('my_page::landing::landing_19'),
    data({ cart_count }) {
      return TT('my_page::landing::landing_20', { count: cart_count });
    },
  };
  const review = {
    title: TT('my_page::landing::landing_07'),
    data({ review_count }) {
      return TT('my_page::landing::landing_08', {
        count: review_count.completed_review_count,
        all_count: UtilS.commify(review_count.can_review_count),
      });
    },
    url: my_page_menu.my_review.url,
  };
  const wish = {
    url: my_page_menu.wish.url,
    title: TT('my_page::landing::landing_09'),
    data({ wish_count }) {
      return TT('my_page::landing::landing_10', { count: wish_count });
    },
  };

  if (TT.lang !== 'kr') {
    return { review, cart, wish };
  }

  const point = {
    url: my_page_menu.point.url,
    title: TT('my_page::landing::landing_05'),
    data({ user }) {
      return TT('my_page::landing::landing_06', { point: user.point });
    },
  };
  return { review, cart, wish, point };
};

export const MyPageMenuMo = () => {
  const order = {
    title: TT('my_page::order::order_01'),
    icon: OMPCoreAtomTmplS.orderIcon(),
    url: `order`,
  };
  const wish = { title: TT('my_page::wish::wish_01'), icon: OMPCoreAtomTmplS.heartIcon(), url: 'wish/list' };
  const my_review = {
    title: TT('my_page::my_review::my_review_01'),
    icon: OMPCoreAtomTmplS.myReviewIcon(),
    url: 'my/review',
  };
  const cart = { title: TT('cart::cart_01'), icon: OMPCoreAtomTmplS.cartIcon(), url: 'cart' };

  // kr only
  const point = {
    title: TT('my_page::point::point_01'),
    icon: OMPCoreAtomTmplS.pointIcon(),
    url: 'my/point',
  };

  if (TT.lang === 'kr') {
    return { order, wish, point, my_review, cart };
  }
  return { order, wish, my_review, cart };
};

export const MyPageMobileCustomerMenu = () => ({
  question: {
    icon: OMPCoreAtomTmplS.questionIcon(),
    title: TT('my_page::landing::landing_12'),
    url: '',
    klass: 'live_chat',
  },
  privacy: {
    icon: OMPCoreAtomTmplS.privacyIcon(),
    title: TT('my_page::landing::landing_13'),
    url: 'my/info',
  },
  customer_service: {
    icon: OMPCoreAtomTmplS.customerServiceIcon(),
    title: TT('my_page::landing::landing_14'),
    url: 'help_center?type=order_pay_kr&hg_id=24',
  },
  logout: {
    icon: OMPCoreAtomTmplS.logoutIcon(),
    title: TT('my_page::landing::landing_15'),
    url: 'logout',
  },
});

export const OrderTotalStatus = () => ({
  [ORDER_STATUS.ALL]: {
    title: TT('my_page::order::order_00'),
    desc: '',
    desc_oneline: '&ensp;',
  },
});

export const OrderCanceledStatus = () => ({
  [ORDER_STATUS.CANCELED]: {
    title: TT('my_page::order::order_01_01'),
    desc: '',
    desc_oneline: TT('my_page::order::order_01_03'),
  },
});

export const OrderStatus = () => ({
  [ORDER_STATUS.ORDERING]: {
    title: TT('my_page::order::order_02'),
    desc: TT('my_page::order::order_03'),
    desc_oneline: TT('my_page::order::order_39'),
  },
  [ORDER_STATUS.BEFORE_PRINT]: {
    title: TT('my_page::order::order_06'),
    desc: TT('my_page::order::order_07'),
    desc_oneline: TT('my_page::order::order_40'),
  },
  [ORDER_STATUS.PRINTING]: {
    title: TT('my_page::order::order_08'),
    desc: TT('my_page::order::order_09'),
    desc_oneline: TT('my_page::order::order_41'),
  },
  [ORDER_STATUS.BEFORE_DELIVER]: {
    title: TT('my_page::order::order_10'),
    desc: TT('my_page::order::order_11'),
    desc_oneline: TT('my_page::order::order_42'),
  },
  [ORDER_STATUS.DELIVERING]: {
    title: TT('my_page::order::order_12'),
    desc: TT('my_page::order::order_13'),
    desc_oneline: TT('my_page::order::order_43'),
  },
  [ORDER_STATUS.BEFORE_CONFIRM]: {
    title: TT('my_page::order::order_14'),
    desc: TT('my_page::order::order_15'),
    desc_oneline: TT('my_page::order::order_44'),
    additional_key: [ORDER_STATUS.CONFIRMED],
  },
});

export const OrderStatusShortDesc = () => ({
  [ORDER_STATUS.CANCEL_REQUEST]: TT('my_page::order::order_29_02'),
  [ORDER_STATUS.CANCELED]: TT('my_page::order::order_29_01'),
  [ORDER_STATUS.ORDERING]: TT('my_page::order::order_24'),
  [ORDER_STATUS.BEFORE_PRINT]: TT('my_page::order::order_25'),
  [ORDER_STATUS.PRINTING]: TT('my_page::order::order_26'),
  [ORDER_STATUS.BEFORE_DELIVER]: TT('my_page::order::order_27'),
  [ORDER_STATUS.DELIVERING]: TT('my_page::order::order_28'),
  [ORDER_STATUS.BEFORE_CONFIRM]: TT('my_page::order::order_29'),
  [ORDER_STATUS.CONFIRMED]: TT('my_page::order::order_29'),
});

export const OrderStatusTitle = () => ({
  [ORDER_STATUS.CANCEL_REQUEST]: TT('my_page::order::order_01_02'),
  [ORDER_STATUS.CANCELED]: TT('my_page::order::order_01_01'),
  [ORDER_STATUS.ORDERING]: TT('my_page::order::order_02'),
  [ORDER_STATUS.BEFORE_PRINT]: TT('my_page::order::order_06'),
  [ORDER_STATUS.PRINTING]: TT('my_page::order::order_08'),
  [ORDER_STATUS.BEFORE_DELIVER]: TT('my_page::order::order_10'),
  [ORDER_STATUS.DELIVERING]: TT('my_page::order::order_12'),
  [ORDER_STATUS.BEFORE_CONFIRM]: TT('my_page::order::order_14'),
  [ORDER_STATUS.CONFIRMED]: TT('my_page::order::order_14'),
  [ORDER_STATUS.INDIVIDUAL_CANCEL]: TT('my_page::order::order_45'),
});

export const OrderTableColumn = () => {
  return [
    {
      key: 'order_info',
      size: 12,
      title: TT('my_page::order::order_17'),
      url: (up_c) => up_c.table_data.order_detail_url,
      template: OMPMyPageOrderTmplS.orderTableOrderInfoColumTmpl,
    },
    {
      key: 'detail',
      title: TT('my_page::order::order_18'),
      url: (up_c) => up_c.table_data.order_detail_url,
      template: OMPMyPageOrderTmplS.orderTableDetailColumTmpl,
    },
    {
      key: 'file',
      size: 12,
      title: TT('my_page::order::order_36'),
      template: (up_c) => {
        const { pdf_printing_file } = up_c._.product_color._;
        return OMPMyPageOrderTmplS.orderTablePrintingFileColumTmpl(pdf_printing_file);
      },
    },
    {
      key: 'price',
      size: 12,
      title: TT('my_page::order::order_19'),
      url: (up_c) => up_c.table_data.order_detail_url,
      template: OMPMyPageOrderTmplS.orderTablePriceColumTmpl,
    },
    {
      key: 'status',
      size: 12,
      title: TT('my_page::order::order_20'),
      template: OMPMyPageOrderTmplS.orderTableStatusColumTmpl,
    },
    {
      key: 'etc',
      size: 15,
      title: '',
      template: OMPMyPageOrderTmplS.orderTableEtcColumTmpl,
    },
  ];
};

export const OUT_LINK_ICON = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
>
  <path
    d="M6.94146 2.85693H4.00028C3.3691 2.85693 2.85742 3.36861 2.85742 3.99979V11.9998C2.85742 12.631 3.3691 13.1426 4.00028 13.1426H12.0003C12.6315 13.1426 13.1431 12.631 13.1431 11.9998V9.20987"
    stroke="black"
    stroke-width="1.2"
    stroke-linecap="round"
  />
  <path
    d="M9.71484 2.85693H13.1434V6.2855"
    stroke="black"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M13.1429 2.85693L8 7.99979"
    stroke="black"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>`;
