import { changeDpiDataUrl } from 'changedpi';
import { go, tap } from 'fxjs/es';
import { makeCanvasByUrl } from '../../Canvas/S/util.js';
import { cropCanvas, getJustifyImageSize, makeTrimCanvas3 } from './canvas_trim.js';
import { getCvImage } from './CvImage/fs.js';
import { getFheight, getFwidth, ungroupAllWithObjAttrs } from './util.js';

export function makeCvGroup(product_face_designs, canvas_ratio) {
  const width = G.mp.maker.CANVAS_WIDTH_ORIGIN * canvas_ratio;
  const el = $.el('canvas');
  const canvas = new fabric.Canvas(el, {
    width,
    height: width,
    enableRetinaScaling: false,
  });
  const prev = G.mp.maker.is_auto_print;
  G.mp.maker.is_auto_print = false;
  return _p.go(
    product_face_designs,
    _p.reject((design) => design.visible === false),
    (product_face_designs) => ungroupAllWithObjAttrs(canvas, product_face_designs),
    tap(() => {
      G.mp.maker.is_auto_print = prev;
    }),
    _p.map((design) => G.mp.maker.from_cv_attrs_for_maker(design, canvas_ratio)),
    _p.each(function (obj) {
      obj.padding = 1;
    }),
    function (objs) {
      return G.mp.maker.create_cv_group(objs);
    },
    function (cv_group) {
      canvas.add(cv_group);
      canvas.renderAll();
      return [canvas, cv_group];
    },
  );
}
/* ratio 110 넘어가면 4000px 정도로 크게 만들어짐*/
export function printableComposiedImage(product_face_designs, canvas_ratio, ratio, is_force) {
  if (is_force) {
    G.mp.maker.is_auto_print = true;
    return go(
      makeCvGroup(product_face_designs, canvas_ratio),
      tap(() => {
        G.mp.maker.is_auto_print = false;
      }),
    );
  }
  return go(
    makeCvGroup(product_face_designs, canvas_ratio),
    function ([fcanvas, cv_object]) {
      G.mp.maker.is_auto_print = true;
      const before_width = getFwidth(cv_object);
      const before_height = getFheight(cv_object);
      const rect_ratio = before_width / before_height;
      const big_size = 5000;
      const current_width =
        ratio >= 111
          ? rect_ratio >= 1
            ? big_size
            : Math.round(big_size * rect_ratio)
          : make_canvas_min_width(cv_object) * ratio;
      fcanvas.dispose();
      return makeCvGroup(product_face_designs, (current_width / before_width) * canvas_ratio);
    },
    tap(() => {
      G.mp.maker.is_auto_print = false;
    }),
  );
}

/*
 * @description 이미지 편집기에서 사용할 인쇄용 시안 편집 이미지 source 를 생성하는 함수
 * @returns {design_margin_x : margin_direction: "left" | "right" | "center" , margin_x: number} 중심으로부터 디자인 여백
 * @returns {data_url: string} 편집용 이미지 소스 data url
 * @returns {multiplier: number} 인쇄용 시안 사이즈 기준 대비 크기 확대 비율 (편집시 퀄리티 향상 목적)
 * @returns {dpi: number} 인쇄용 시안 파일 dpi 적용 값
 * */
export const getPrintableImageEditableSource = async function ({
  dpi,
  pf_designs,
  print_area_bound,
  pixel_enhancement_scale,
  shiboris_to_print_area_top_cm,
  multiplier = 1,
}) {
  const MIME_TYPE = 'image/png';

  G.mp.maker.is_auto_print = true;

  return go(
    makeCvGroup(pf_designs, multiplier * pixel_enhancement_scale),
    tap(() => {
      G.mp.maker.is_auto_print = false;
    }),
    async ([_, cv_object]) => {
      const design_data_url = cv_object.toDataURL(MIME_TYPE, 1);

      const design_canvas = await makeCanvasByUrl(design_data_url);
      const justify_design_size = getJustifyImageSize({ canvas: design_canvas, margin: 0 });

      const justify_canvas = cropCanvas({ canvas: design_canvas, size: justify_design_size });
      const design_bound = {
        left: cv_object.left + justify_design_size.x,
        width: justify_design_size.w,
      };
      return {
        data_url: changeDpiDataUrl(justify_canvas.toDataURL(MIME_TYPE, 1), dpi),
        design_margin_x: calculateDesignMarginX({ design_bound, print_area_bound }),
        multiplier,
        dpi,
        trim_offset_y: justify_design_size.y, // 원본 디자인에서 justify 디자인으로 잘려 나간 top (마진 제거 폭)
      };
    },
  );
};

/*
 * @description 인쇄용 시안을 중심으로 디자인 위치에 의해 마진값을 계산하는 함수
 * @param {design_bound: {width: number, left: number}} 디자인 bound (정확한 값을 구하기 위해서는 justify 된 디자인 bound 가 필요)
 * @param {print_area_bound: {width: number, left: number}} 프린트 영역 bound
 * @returns margin_direction { 'left' | 'center' | 'right' } 디자인 이미지에 어디 방향으로 마진을 넣어야 하는지 판단 결과
 * @returns margin { number } 얼만큼 마진을 넣어야 하는지 px 값
 * */
const calculateDesignMarginX = ({ design_bound, print_area_bound }) => {
  if (design_bound == null || design_bound.width == null || design_bound.left == null) {
    throw new Error(`Wrong design bound value`);
  }

  if (print_area_bound == null) {
    return { margin_direction: 'center', margin_x: 0 };
  }

  /* 상품 (의류) 사진의 위치가 canvas 의 중심에 위치하고 있다고 가정 */
  const { width: p_width, left: p_left } = print_area_bound;
  const print_area_center = p_left + p_width / 2;
  const { width: design_width, left: design_left } = design_bound;

  const design_center = design_left + design_width / 2;

  const margin_direction =
    Math.round(print_area_center - design_center) === 0
      ? 'center'
      : print_area_center < design_center
      ? 'left'
      : 'right';

  const margin_required_relative_to_design = (() => {
    switch (margin_direction) {
      case 'center': {
        return 0;
      }
      /* 마진이 왼쪽에 들어가야 함. (디자인이 중심으로부터 오른쪽에 위치하고 있음을 의미) */
      case 'left': {
        const design_right = design_left + design_width;
        const right_distance_from_center = design_right - print_area_center;
        const left_distance_from_center = design_left - print_area_center;
        return left_distance_from_center + right_distance_from_center;
      }
      case 'right': {
        /* 마진이 오른쪽에 들어가야 함. (디자인이 중심으로부터 왼쪽에 위치하고 있음을 의미) */
        const design_right = design_left + design_width;
        const right_distance_from_center = print_area_center - design_right;
        const left_distance_from_center = print_area_center - design_left;
        return left_distance_from_center + right_distance_from_center;
      }
    }
  })();

  return { margin_direction, margin_x: Math.round(margin_required_relative_to_design) };
};

export const makePrintableComposedImage = async function (product_face_designs, px_per_1cm) {
  const ratio = prompt(
    '1배는 이미지 해상도를 고려하여 키울 수 있는 최대 크기로 만듭니다. 확인하시고 적절하지 않다면 0.1배~4배 정도로 변경해보세요.(111이상 입력시 가로 세로중 큰 쪽으로 5000px 이미지)',
    1,
  );
  fabric.charWidthsCache = {};
  if (!ratio) return 'cancel';
  const canvas_ratio = 1;

  if (ratio === 'r') {
    return makeResizedPrintableComposedImage(product_face_designs, px_per_1cm, 'image/png');
  }

  return _p.go(
    printableComposiedImage(product_face_designs, canvas_ratio, parseFloat(ratio)),
    ([canvas, cv_object]) => {
      const dataurl = cv_object.toDataURL('image/png', 1);
      canvas.dispose();
      return dataurl;
    },
  );
};

export const makeResizedPrintableComposedImage = async function (product_face_designs, px_per_1cm, mimetype) {
  const ratio = prompt(
    '300dpi에 해당되는 실제 사이즈에 맞는 이미지가 추출됩니다. 그 이상을 원하시면 숫자를 올려보세요.',
    1,
  );
  fabric.charWidthsCache = {};
  if (!ratio) return 'cancel';
  const canvas_ratio = ratio || 1;
  G.mp.maker.is_auto_print = true;
  const dpi = 300;
  return go(
    makeCvGroup(product_face_designs, (canvas_ratio * (860 / px_per_1cm / 2.54) * dpi) / 860),
    tap(() => {
      G.mp.maker.is_auto_print = false;
    }),
    async ([canvas, cv_object]) => {
      const trimmed_canvas = await go(cv_object.toDataURL(mimetype, 1), makeCanvasByUrl, makeTrimCanvas3);
      canvas.dispose();
      return changeDpiDataUrl(trimmed_canvas.toDataURL(mimetype, 1), dpi * ratio);
    },
  );
};

function make_canvas_min_width(cv_object) {
  const cv_object_width = G.mp.maker.fabric_get_width(cv_object);
  const cv_object_height = G.mp.maker.fabric_get_height(cv_object);

  const cv_objs = [];
  G.mp.maker.cv_objects_deep_each(cv_object, function (cv_obj) {
    if (
      cv_obj._data.cv_type === 'cv_text' ||
      cv_obj._data.cv_type === 'cv_text_image' ||
      cv_obj._data.cv_type === 'cv_text_image_pattern'
    ) {
      const width = G.mp.maker.fabric_get_width(cv_obj);
      const height = G.mp.maker.fabric_get_height(cv_obj);
      if (width > height) {
        const scale = 3000 / width;
        cv_obj._data.image_width = 3000;
        cv_obj._data.image_height = height * scale;
      } else {
        const scale = 3000 / height;
        cv_obj._data.image_height = 3000;
        cv_obj._data.image_width = width * scale;
      }
    }
    if (cv_obj._data.cv_type === 'cv_pattern') {
      const cv_image = getCvImage(cv_obj);
      cv_obj._data.image_width = cv_image._data.image_width;
      cv_obj._data.image_height = cv_image._data.image_height;
    }
    cv_objs.push(cv_obj);
  });
  return _p.go(
    cv_objs,
    _p.min(function (cv_obj) {
      if (cv_obj._data.cv_type === 'cv_pattern') {
        return (
          _p.min(
            cv_obj._data.image_width * cv_obj._data.pattern_data.x,
            cv_obj._data.image_height * cv_obj._data.pattern_data.y,
          ) / G.mp.maker.fabric_get_width(cv_obj)
        );
      }
      return cv_obj._data.image_width / G.mp.maker.fabric_get_width(cv_obj);
    }),
    function (cv_obj) {
      return cv_obj._data.image_width / G.mp.maker.fabric_get_width(cv_obj);
    },
    function (scale) {
      const width = scale * cv_object_width;
      const height = scale * cv_object_height;
      /*미란님이 8000 주문하심*/
      if (width > 8000 || height > 8000) {
        if (width > height) return 8000;
        return (8000 / height) * width;
      }
      return width;
    },
  );
}
