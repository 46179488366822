import {
  defaultTo,
  eachC,
  equals2,
  filterL,
  go,
  identity,
  isNil,
  join,
  mapC,
  mapL,
  rejectL,
  split,
  takeAll,
} from 'fxjs/es';
import { SVGEditorFontConstS } from '../../../../SVGEditor/Font/S/Const/module/SVGEditorFontConstS.js';
import axios from 'axios';
import { CommonNS } from '@marpple/sticker-editor';

export const loadBaseFonts = () =>
  mapC(([font_family, option = {}]) =>
    new FontFaceObserver(font_family, option).load(undefined, 70000).catch((e) => {
      console.error('font loading failed ' + font_family);
      console.error(e);
    }),
  )(SVGEditorFontConstS.BASE_FONTS);

export const getAllUsedFonts = async (el, fonts = new Set()) => {
  await go(
    [el?.style?.fontFamily, el?.getAttributeNS?.(null, 'font-family')],
    rejectL((font_family) => !font_family),
    eachC(async (font_family) => {
      const font_family_list = await new Promise((resolve, reject) =>
        requestAnimationFrame(() => {
          try {
            resolve(split(',')(font_family));
          } catch (error) {
            reject(error);
          }
        }),
      );
      return eachC(
        (font_family) =>
          new Promise((resolve, reject) =>
            requestAnimationFrame(() => {
              try {
                fonts.add(font_family.trim().replace(/"/g, ''));
                resolve();
              } catch (error) {
                reject(error);
              }
            }),
          ),
      )(font_family_list);
    }),
  );

  await eachC((el) => getAllUsedFonts(el, fonts))(Array.from(el.children));

  return fonts;
};

const loadFontAndToDataURL = async (url) => {
  const { data: blob } = await axios.get(url, { responseType: 'blob' });
  const data_url = await new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.addEventListener('error', reject);
    file_reader.readAsDataURL(blob);
  });
  if (!data_url || !equals2(typeof data_url)('string')) {
    throw new Error(`폰트를 불러올 수 없습니다.`);
  }
  return data_url;
};

export const makeFontStyleEl =
  ({ is_encode_base64 }) =>
  async (el) =>
    go(
      getAllUsedFonts(el),
      (font_family_set) =>
        filterL(({ fontFamily }) => font_family_set.has(fontFamily))(SVGEditorFontConstS.FONTS),
      mapL(({ fontFamily: font_family, fontStyle: font_style, fontWeight: font_weight, src }) =>
        go(
          src,
          is_encode_base64
            ? mapL(({ url, format }) =>
                loadFontAndToDataURL(url)
                  .then((url) => ({ url, format }))
                  .catch(() => null),
              )
            : identity,
          rejectL(isNil),
          takeAll,
          (src) => (src.length > 0 ? { font_family, font_style, font_weight, src } : null),
        ),
      ),
      rejectL(isNil),
      mapL(({ font_family, font_style, font_weight, src }) =>
        go(
          src,
          mapL(({ url, format }) => `url("${url}") format("${format}")`),
          join(', '),
          (src) => [
            `font-family: "${font_family}";`,
            `font-style: ${font_style};`,
            `font-weight: ${font_weight};`,
            `src: ${src};`,
          ],
          join('\n'),
          (font_face) => `@font-face {\n${font_face}\n}`,
        ),
      ),
      join('\n'),
      defaultTo(''),
      (font_faces) => {
        const style_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'style');
        style_el.innerHTML = font_faces;
        return style_el;
      },
    );
