import { each, filterL, go, head, takeL } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { SVGEditorMobileUnlockItemsMuiF } from '../../../../../../SVGEditor/MobileUnlockItems/F/Mui/module/SVGEditorMobileUnlockItemsMuiF.js';
import { VectorEditorStickerSingleMobileF } from '../Function/module/VectorEditorStickerSingleMobileF.js';

export const handleTopMenuBlankPlateClick =
  ({ tab_el }) =>
  () => {
    VectorEditorStickerSingleMobileF.hideTopMenuContainer(tab_el);
    VectorEditorStickerSingleMobileF.hideTopMenuBlankPlate(tab_el);
  };

export const handleTopMenuHistoryResetIconClick = () => () => {};

export const handleTopMenuHistoryUndoIconClick = () => () => {};

export const handleTopMenuHistoryRedoIconClick = () => () => {};

export const handleTopMenuToggleLayersModeIconClick = () => () => {};

export const handleTopMenuDeleteIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    each((el) => editor.removeEl(el))(editor.getSelectedEls());
    VectorEditorStickerSingleMobileF.hideTopMenuContainer(tab_el);
    VectorEditorStickerSingleMobileF.hideTopMenuBlankPlate(tab_el);
  };

export const handleTopMenuForwardIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    go(
      editor.getSelectedEls(),
      takeL(1),
      each((el) => editor.moveForward(el)),
    );
  };

export const handleTopMenuBackwardIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    go(
      editor.getSelectedEls(),
      takeL(1),
      each((el) => editor.moveBackward(el)),
    );
  };

export const handleTopMenuGroupIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    const group_el = editor.groupEls([...editor.getSelectedEls()]);
    editor.selectEls([group_el]);
  };

export const handleTopMenuUngroupIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    const group_el = go(
      editor.getSelectedEls(),
      filterL((el) => editor.getIsGroupEl(el)),
      head,
    );
    if (group_el == null) {
      return;
    }

    const ungrouped_els = editor.ungroupEl(group_el);
    editor.selectEls(ungrouped_els);
  };

export const handleTopMenuLockIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    each((el) => editor.lockEl(el))(editor.getSelectedEls());
  };

export const handleTopMenuUnlockIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.hideTopMenuContainer(tab_el);
    VectorEditorStickerSingleMobileF.hideTopMenuBlankPlate(tab_el);

    return new Promise((resolve, reject) =>
      MuiF.openFrame(SVGEditorMobileUnlockItemsMuiF.frame, (frame, page, [tab]) => {
        const frame_closed = frame.closed;
        frame.closed = async (frame_el, v) => {
          await frame_closed.call(frame, frame_el, v);
          v?.error != null ? reject(v.error) : resolve(null);
        };

        const tab_appending = tab.appending;
        tab.appending = (tab_el) => {
          tab_el.__mp_sticker_editor = editor;
          return tab_appending.call(tab, tab_el);
        };
      }).catch(reject),
    );
  };

export const handleTopMenuFlipHorizontalIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    each((el) => editor.flipHorizontal(el))(editor.getSelectedEls());
  };

export const handleTopMenuFlipVerticalIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    each((el) => editor.flipVertical(el))(editor.getSelectedEls());
  };

export const handleTopMenuMoveToLeftEndIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    editor.alignToLeftEnd([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToRightEndIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    editor.alignToRightEnd([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToTopEndIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    editor.alignToTopEnd([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToBottomEndIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    editor.alignToBottomEnd([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToHorizontalCenterIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    editor.alignToHorizontalCenter([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToVerticalCenterIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    editor.alignToVerticalCenter([...editor.getSelectedEls()]);
  };
