import { each, go, join, map, reject } from 'fxjs/es';
import { $addClass, $hasClass, $removeClass } from 'fxdom/es';
import { SVGEditorAttrS } from '../../../Attr/S/Function/module/SVGEditorAttrS.js';
import { ALL_POSSIBLE_TEXT_REG } from '../../../Font/S/Const/fonts.js';

export function eventFontStyle({ e, ___selected_el, __mp_sticker_editor }) {
  const key = e.currentTarget.dataset.key;
  const value = e.currentTarget.dataset.value;
  if ($hasClass('active')(e.currentTarget)) {
    $removeClass('active')(e.currentTarget);
    const default_value = e.currentTarget.dataset.default_value;
    if (key === 'text-decoration') {
      const value = go(
        SVGEditorAttrS.get('text-decoration', ___selected_el),
        reject((v) => v === e.currentTarget.dataset.value),
      );
      SVGEditorAttrS.set({ 'text-decoration': value }, ___selected_el);
    } else {
      SVGEditorAttrS.set({ [key]: default_value }, ___selected_el);
    }
  } else {
    $addClass('active')(e.currentTarget);
    if (key === 'text-decoration') {
      const value = go(
        SVGEditorAttrS.get('text-decoration', ___selected_el),
        reject((v) => v === e.currentTarget.dataset.value),
        (arr) => arr.concat(e.currentTarget.dataset.value),
      );
      SVGEditorAttrS.set({ 'text-decoration': value }, ___selected_el);
    } else {
      SVGEditorAttrS.set({ [key]: value }, ___selected_el);
    }
  }
  __mp_sticker_editor.syncSelectorWithSelection();
}

export const selectFontEvent = async ({ selected_el, font_els, ___selected_el, __mp_sticker_editor }) => {
  const font_family = selected_el.dataset.font_family;
  go(font_els, each($removeClass('selected')));
  $addClass('selected')(selected_el);
  await new FontFaceObserver(font_family).load(undefined, 70000).catch(function (e) {
    console.error('font loading failed ' + font_family);
    console.error(e);
  });
  SVGEditorAttrS.set({ 'font-family': font_family }, ___selected_el);
  __mp_sticker_editor.syncSelectorWithSelection();
};
const not_good_spaces =
  // eslint-disable-next-line no-control-regex,no-misleading-character-class
  /[\v\f\b\u200B\u200C\u200D\u200E\u200F\u000b\u2028\u2029\uFEFF\u202D\xA0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000]/g;
export const getOnlyPossibleText = (text) => {
  if (!text) return '';
  return (
    go(
      text.replace(not_good_spaces, ''),
      (text) => text.matchAll(ALL_POSSIBLE_TEXT_REG),
      map((v) => v[0]),
      reject((t) => not_good_spaces.test(t)),
      join(''),
    ) || ''
  );
};
