import noUiSlider from 'nouislider';

import { DfImageEditorF } from './module/DfImageEditorF.js';
import {
  ALPHA_THRESHOLD,
  CONTROL_NAME,
  DATA_SET_KEY,
  ITEM_NAME,
  LAYER_NAME,
  RATIO,
  SPOT_PROPERTY_NAME,
  TOOL_NAME,
} from '../../S/Constant/constants.js';
import { each, go, map, sort } from 'fxjs/es';
import { $delegate, $qs } from 'fxdom/es';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';

const FIXED_PRECISION = 0;

export function toolSpot({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  initiateAreaSlider({ paper_scope });

  go(
    tab_el,
    $delegate(
      'click',
      `.property-panel .controls[tool-name=${TOOL_NAME.spot}][control-name=${CONTROL_NAME.position}] button`,
      (e) => {
        const ct = e.currentTarget;
        const control_name = ct.name;
        const area_slider_obj = getAreaControlSliderObj();
        const range_option = area_slider_obj.options.range;

        switch (control_name) {
          case 'leftmost': {
            setHandlesLeftThenRight({
              handle_slider: area_slider_obj,
              set_values: [range_option.min, range_option.min],
            });
            break;
          }
          case 'rightmost': {
            setHandlesRightThenLeft({
              handle_slider: area_slider_obj,
              set_values: [range_option.max, range_option.max],
            });
            break;
          }
          case 'left': {
            moveHandleOneStep({
              handle_slider: area_slider_obj,
              left_or_right: 'left',
              control_handle: 'both',
            });
            break;
          }
          case 'right': {
            moveHandleOneStep({
              handle_slider: area_slider_obj,
              left_or_right: 'right',
              control_handle: 'both',
            });
          }
        }
      },
    ),
    $delegate(
      'click',
      `.property-panel .controls[tool-name=${TOOL_NAME.spot}][control-name=${CONTROL_NAME.execute}] button`,
      async (e) => {
        const ct = e.currentTarget;
        const control_name = ct.name;

        switch (control_name) {
          case 'delete': {
            await deleteSpot({ tab_el });
            break;
          }
          case 'cancel': {
            DfImageEditorF.cancelErase({
              tab_el,
              onAfterRestore: (printable_raster) =>
                (async () => {
                  await applySpotStroke({ paper_scope });
                  if (DfImageEditorF.hasStrokeItem({ paper_scope })) {
                    await DfImageEditorF.reCalculateStroke({
                      paper_scope,
                    });
                  }
                })(),
            });
            break;
          }
          default: {
            throw new Error(`Unhandled control name ${control_name}`);
          }
        }
      },
    ),
  );
}

async function deleteSpot({ tab_el }) {
  try {
    $.don_loader_start();
    const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
    const spot_layer = DfImageEditorF.getLayerByName({ paper_scope, name: LAYER_NAME.spot });
    const exist_spot_stroke_item = getSpotStroke({ spot_layer });
    if (exist_spot_stroke_item == null) return;
    await DfImageEditorF.erasePrintableRaster({
      paper_scope,
      erase_layer: spot_layer,
      onBeforeErase: () => {
        // Todo 재민 - 디폴트 스타일 결정
        clearSpotStrokeAlert({ item: exist_spot_stroke_item });

        /* Canvas blending 이 미세한 픽셀 어긋남이 발생할 수 있어 조금 더 여유롭게 삭제 */
        exist_spot_stroke_item.strokeWidth = 4;
        exist_spot_stroke_item.strokeColor = 'Black';
        exist_spot_stroke_item.fillColor = 'Black';
      },

      onAfterErase: async () => {
        if (DfImageEditorF.hasStrokeItem({ paper_scope })) {
          await DfImageEditorF.reCalculateStroke({
            paper_scope,
          });
        }
        await applySpotStroke({ paper_scope });
      },
    });
  } catch (e) {
    await DfImageEditorF.donAlertAsync({
      tab_el,
      msg: e.message,
    });
  } finally {
    $.don_loader_end();
  }
}

async function applySpotStroke({ paper_scope }) {
  const spot_layer = DfImageEditorF.getLayerByName({ paper_scope, name: LAYER_NAME.spot });
  const exist_spot_stroke = getSpotStroke({ spot_layer });
  exist_spot_stroke && exist_spot_stroke.remove();

  DfImageEditorF.addPrintableRasterRectangleBound({ paper_scope, target_layer: spot_layer });

  return await DfImageEditorF.tracePrintableRaster({
    paper_scope,
    alpha_threshold: ALPHA_THRESHOLD.spot,
    is_alpha_binarize: true,
    onload: (stroke_item) => {
      stroke_item.selected = true;
      stroke_item.name = ITEM_NAME.spot;
      // Todo 재민 - 디폴트 스타일 결정
      // setDefaultSpotStyle({ paper_scope, stroke_item });
      alertSpotStroke({ paper_scope, stroke_item });

      spot_layer.addChild(stroke_item);
      updateSliderSettings({ stroke_item });
    },
  });
}

export async function activateSpotTool({ tab_el }) {
  try {
    $.don_loader_start();
    const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
    const spot_layer = DfImageEditorF.getLayerByName({ paper_scope, name: LAYER_NAME.spot });

    paper_scope.settings.handleSize = 1;

    const spot_tool = DfImageEditorF.prepareTool({
      paper_scope,
      tool_name: TOOL_NAME.spot,
      eventHandlers: {
        keydown: async (e) => {
          const keyboard_event = e.event;
          const area_slider_obj = getAreaControlSliderObj();
          const control_handle = keyboard_event.ctrlKey ? 'left' : keyboard_event.altKey ? 'right' : 'both';
          const range_option = area_slider_obj.options.range;
          if (keyboard_event.code === 'BracketLeft') {
            moveHandleOneStep({ handle_slider: area_slider_obj, left_or_right: 'left', control_handle });
          }
          if (keyboard_event.code === 'BracketRight') {
            moveHandleOneStep({ handle_slider: area_slider_obj, left_or_right: 'right', control_handle });
          }
          if (keyboard_event.code === 'Comma') {
            setHandlesLeftThenRight({
              handle_slider: area_slider_obj,
              set_values: [range_option.min, range_option.min],
            });
          }
          if (keyboard_event.code === 'Period') {
            setHandlesRightThenLeft({
              handle_slider: area_slider_obj,
              set_values: [range_option.max, range_option.max],
            });
          }
          if (keyboard_event.code === 'Delete') {
            await deleteSpot({ tab_el });
          }
          if (keyboard_event.code === 'KeyC') {
            DfImageEditorF.cancelErase({
              tab_el,
              onAfterRestore: () =>
                (async () => {
                  await applySpotStroke({ paper_scope });
                  if (DfImageEditorF.hasStrokeItem({ paper_scope })) {
                    await DfImageEditorF.reCalculateStroke({
                      paper_scope,
                    });
                  }
                })(),
            });
          }
        },
        mousedown: (e) => {
          if (e.event.button === 0) {
            if (DfImageEditorLibF.view_control.isControlling() === false) {
              DfImageEditorF.getAllPaths({ item: spot_layer }).forEach((p) => {
                DfImageEditorF.addDataToItem({ item: p, data: { visibility: p.visible } });
                p.visible = true;
              });

              const hit_result = spot_layer.hitTest(e.point, {
                stroke: true,
                segments: false,
                handles: false,
                selected: false,
              });

              DfImageEditorF.getAllPaths({ item: spot_layer }).forEach((p) => {
                p.visible = DfImageEditorF.getDataFromItem({ item: p, key: 'visibility' });
                DfImageEditorF.removeDataFromItem({ item: p, key: 'visibility' });
              });

              if (hit_result && hit_result.type === 'stroke') {
                toggleItemSelect({ item: hit_result.item });
              }
            }
          }
        },
      },
    });
    spot_layer.visible = true;
    spot_tool.activate();

    await applySpotStroke({ paper_scope });
  } catch (e) {
    console.error(e);
  } finally {
    $.don_loader_end();
  }
}

function toggleItemSelect({ item }) {
  item.selected = !item.selected;
  item.visible = !item.visible;
}

export function deactivateSpotTool({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const spot_layer = DfImageEditorF.getLayerByName({ paper_scope, name: LAYER_NAME.spot });
  spot_layer.visible = false;
}

function getItemPathCount({ item }) {
  const paths = DfImageEditorF.getAllPaths({ item });
  if (paths == null) {
    return 0;
  } else {
    return paths.length;
  }
}

function initiateAreaSlider({ paper_scope }) {
  const $area_slider = getAreaControlSliderEl();
  const spot_layer = DfImageEditorF.getLayerByName({ paper_scope, name: LAYER_NAME.spot });

  if ($area_slider == null) {
    throw new Error(`Not exist area slider for spot tool`);
  }

  const slider_obj = noUiSlider.create($area_slider, {
    start: [0, 0],
    connect: true,
    step: 1,
    tooltips: {
      to: (value) => value.toFixed(0),
    },
    range: { min: 0, max: 0 },
    format: {
      to: areaValueFormatter,
      from: (value) => parseFloat(value),
    },
    keyboardSupport: true,
  });

  slider_obj.on('update', (values) => {
    const stroke_item = getSpotStroke({ spot_layer });
    if (stroke_item) {
      const [low_no, high_no] = values;
      const areas = getPathAreas({ stroke_item });
      go(
        DfImageEditorF.getAllPaths({ item: stroke_item }),
        each((path) => {
          const path_area = Math.abs(path.area);
          const is_in_range = path_area >= areas[low_no] && path_area <= areas[high_no];
          path.selected = is_in_range;
          path.visible = is_in_range;
        }),
      );
    }
  });
}

function areaValueFormatter(value) {
  return Number(value.toFixed(FIXED_PRECISION));
}

function getAreaControlSliderEl() {
  const $area_slider = $qs(`div.property[property-name=${SPOT_PROPERTY_NAME.area}] div.slider`);
  if ($area_slider == null) {
    throw new Error(`Not exist area slider for spot tool`);
  }
  return $area_slider;
}

function getAreaControlSliderObj() {
  const $area_slider = getAreaControlSliderEl();
  const slider_obj = $area_slider.noUiSlider;
  if (slider_obj == null) {
    throw new Error(`Not exist slider object for spot tool`);
  }
  return slider_obj;
}

function updateSliderSettings({ stroke_item }) {
  const area_control_slider_obj = getAreaControlSliderObj();

  const path_count = getItemPathCount({ item: stroke_item });
  if (path_count === 0) {
    throw new Error(`Not exist spot`);
  }

  const min = 0;
  const max = path_count - 1;
  area_control_slider_obj.updateOptions(
    {
      range: { min, max },
      pips: {
        mode: 'steps',
        density: 100,
        format: { to: () => '' },
      },
    },
    true,
  );

  area_control_slider_obj.set([min, max]);
}

function getPathAreas({ stroke_item }) {
  return go(
    DfImageEditorF.getAllPaths({ item: stroke_item }),
    map((path) => Math.abs(path.area)),
    sort,
  );
}

function setDefaultSpotStyle({ paper_scope, stroke_item }) {
  const strokeWidth = Math.ceil(
    DfImageEditorF.getPrintableRasterAverageSize({ paper_scope }) / RATIO.raster_size_to_spot_stroke_width,
  );
  setStrokeStyle({
    stroke_item,
    style: {
      strokeWidth,
      strokeColor: 'Blue',
    },
  });
}

function alertSpotStroke({ paper_scope, stroke_item }) {
  setDefaultSpotStyle({ paper_scope, stroke_item });

  const interval_ids = [
    setInterval(() => {
      setStrokeStyle({
        stroke_item,
        style: {
          strokeColor: 'Red',
        },
      });
    }, 500),
    setInterval(() => {
      setStrokeStyle({
        stroke_item,
        style: {
          strokeColor: 'Blue',
        },
      });
    }, 1000),
  ];

  DfImageEditorF.addDataToItem({ item: stroke_item, data: { [DATA_SET_KEY.interval_ids]: interval_ids } });
}

function clearSpotStrokeAlert({ item }) {
  const interval_ids = DfImageEditorF.getDataFromItem({
    item,
    key: [DATA_SET_KEY.interval_ids],
  });

  interval_ids.forEach((id) => {
    window.clearInterval(id);
  });
  DfImageEditorF.removeDataFromItem({ item, key: [DATA_SET_KEY.interval_ids] });
}

function setStrokeStyle({ stroke_item, style }) {
  Object.entries(style).forEach(([k, v]) => {
    stroke_item[k] = v;
  });
}

function getSpotStroke({ spot_layer }) {
  return DfImageEditorF.getItemInLayer({ layer: spot_layer, condition: { name: ITEM_NAME.spot } });
}

/*
 * @param {'left' | 'right'} left_or_right
 * @param {'left' | 'right' | 'both'} control_handle
 *  */
function moveHandleOneStep({ handle_slider, left_or_right, control_handle }) {
  const [handle1, handle2] = handle_slider.get();
  const { min, max } = handle_slider.options.range;
  if (left_or_right === 'left') {
    switch (control_handle) {
      case 'left': {
        setHandlesLeftThenRight({ handle_slider, set_values: [Math.max(min, handle1 - 1), handle2] });
        break;
      }
      case 'right': {
        setHandlesLeftThenRight({ handle_slider, set_values: [handle1, Math.max(min, handle2 - 1)] });
        break;
      }
      case 'both': {
        setHandlesLeftThenRight({
          handle_slider,
          set_values: [Math.max(min, handle1 - 1), Math.max(min, handle2 - 1)],
        });
        break;
      }
      default: {
        throw new Error(`Unhandled control handle ${control_handle}`);
      }
    }
  } else if (left_or_right === 'right') {
    switch (control_handle) {
      case 'left': {
        setHandlesRightThenLeft({ handle_slider, set_values: [Math.min(max, handle1 + 1), handle2] });
        break;
      }
      case 'right': {
        setHandlesRightThenLeft({ handle_slider, set_values: [handle1, Math.min(max, handle2 + 1)] });
        break;
      }
      case 'both': {
        setHandlesRightThenLeft({
          handle_slider,
          set_values: [Math.min(max, handle1 + 1), Math.min(max, handle2 + 1)],
        });
        break;
      }
      default: {
        throw new Error(`Unhandled control handle ${control_handle}`);
      }
    }
  } else {
    throw new Error(`Unhandled left or right ${left_or_right}`);
  }
}

function setHandlesLeftThenRight({ handle_slider, set_values: [val1, val2] }) {
  handle_slider.setHandle(0, val1);
  handle_slider.setHandle(1, val2);
}
function setHandlesRightThenLeft({ handle_slider, set_values: [val1, val2] }) {
  handle_slider.setHandle(1, val2);
  handle_slider.setHandle(0, val1);
}
