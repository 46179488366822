import { go, html, strMap, entries } from 'fxjs/es';

export const historyGroup = (task_histories) => {
  return html`
    <div class="task-history">
      <div class="header">
        <h1 class="title">태스크별 변경 내역</h1>
        <div class="btn-group" role="group" aria-label="initial and search buttons">
          <button class="btn btn-default task-history__close" type="button">닫기</button>
        </div>
      </div>
      <ul class="body mt-3 accordion">
        ${!task_histories || !Object.entries(task_histories)?.length
          ? html`<li class="accordion-item no-content">
              <div class="head-content"><h3>변경내역이 없습니다!</h3></div>
            </li>`
          : html``}
        ${go(
          task_histories,
          entries,
          strMap(
            ([k, v]) => html`
              <li class="accordion-item active">
                <div class="head-content">
                  <img
                    class="accordion-icon"
                    src="//s3.marpple.co/files/u_29089/2023/6/original/b1014f70e5a004f5798286df375ad72a41bd9e541.webp"
                    alt=""
                  />
                  <h3 class="align-middle">${k} | ${v?.[0]?.title || ''}</h3>
                </div>
                <div class="spread-row">
                  ${go(
                    v,
                    (histories) => html`
                      <table class="table table-bordered">
                        <thead class="table-light">
                          <tr class="text-center">
                            <th>히스토리 번호</th>
                            <th>태스크 명</th>
                            <th>변경 내역</th>
                            <th>진행 시간</th>
                            <th>작업자</th>
                            <th>생성일</th>
                          </tr>
                        </thead>
                        <tbody>
                          ${go(
                            histories,
                            strMap(
                              ({ id, title, created_at, name, change_cols, logs, time_taken }) => html`
                                <tr class="text-center">
                                  <td class="align-middle" style="width: 10%;">${id}</td>
                                  <td class="align-middle" style="width: 10%;">${title || ''}</td>
                                  <td class="align-middle" style="width: 50%;">
                                    ${logs}
                                    <span style="display: none"
                                      >${change_cols
                                        ? Object.entries(change_cols)
                                            .map(([k, v]) => `[${k}]: ${v}`)
                                            .join(', ')
                                        : ''}</span
                                    >
                                  </td>
                                  <td class="align-middle" style="width: 10%;">${time_taken || ''}</td>
                                  <td class="align-middle" style="width: 10%;">${name || ''}</td>
                                  <td class="align-middle" style="width: 10%;">
                                    ${moment(created_at).format('YYYY.MM.DD HH:mm')}
                                  </td>
                                </tr>
                              `,
                            ),
                          )}
                        </tbody>
                      </table>
                    `,
                  )}
                </div>
              </li>
            `,
          ),
        )}
      </ul>
    </div>
  `;
};
