import { DfLglHaezleConstantS } from '../../S/Constant/module/DfLglHaezleConstantS.js';
import { html, compact, flatMap, go, join, map, pipe, pluck, groupBy, sel, strMap } from 'fxjs/es';
import { UtilArrayS } from '../../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { DfLglF } from '../../../F/Function/module/DfLglF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import axios from 'axios';
import { DfInhouseF } from '../../../../Inhouse/F/Function/module/DfInhouseF.js';
import { DfExcelF } from '../../../../Excel/F/Function/module/DfExcelF.js';
import { UtilStringS } from '../../../../../Util/String/S/Function/module/UtilStringS.js';
import { $el } from 'fxdom/es';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { makeDfProjectionListUpdate } from '../../../../Projection/List/F/fs.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import Swal from 'sweetalert2';
import { UtilAlertF } from '../../../../../Util/Alert/F/Function/module/UtilAlertF.js';

export function makeLglHaezelFormHeader({ upcs_ctn }) {
  const main_cols = DfLglHaezleConstantS.FORM.GROUP_NAMES.flatMap(
    (GROUP_NAME) => DfLglHaezleConstantS.FORM.GROUP_COLS[GROUP_NAME],
  );

  const product_cols =
    DfLglHaezleConstantS.FORM.GROUP_COLS[DfLglHaezleConstantS.SUB_GROUP_NAMES.product_info];

  const main_headers = pluck('title', main_cols);
  const products_headers = UtilArrayS.repeatArray(pluck('title', product_cols), upcs_ctn);

  return {
    headers: main_headers.concat(products_headers).flat(),
    cols: { main: main_cols, product: product_cols },
  };
}

export async function upLoadLglHaezleForm() {
  const file_input_el = $el(html`<input type="file" accept=".xlsx, .xls" />`);
  file_input_el.addEventListener('change', async () => {
    try {
      $.don_loader_start();
      const file = file_input_el.files[0];

      const rows = await DfExcelF.parseExcel({ file });
      const header_row = rows[0];

      const projection_id_idx = header_row.indexOf('고객주문번호');
      const waybill_number_express_idx = header_row.indexOf('해외배송번호');

      const res = await go(
        rows.slice(2), // 2개의 header rows 스킵
        zipWithIndex,
        map(async ([idx, r]) => {
          const projection_id = r[projection_id_idx];
          const waybill_number_express = r[waybill_number_express_idx];
          const response = {
            is_OK: false,
            data: {
              idx,
              projection_id,
              waybill_number_express,
            },
            reason: null,
          };

          if (UtilS.isEmpty(projection_id)) {
            response.reason = '엑셀 주문서 번호 미확인';
            return response;
          }

          if (UtilS.isEmpty(waybill_number_express)) {
            response.reason = '엑셀 해외 운송장 번호 미확인';
            return response;
          }

          try {
            const projection_status = (
              await axios.get('/@api/projection/status', {
                params: {
                  projection_id,
                },
              })
            ).data;

            if (
              UtilS.isEmpty(projection_status) ||
              ['delivering', 'before_confirm', 'confirmed'].includes(projection_status.status)
            ) {
              response.reason = '이미 배송 처리된 주문';
              return response;
            }

            const updated_lgl_inbound_shipping_and_task = (
              await axios.post('/@api/waybill/lgl/waybill_number_express', {
                projection_id,
                waybill_number_express,
              })
            ).data;

            if (UtilS.isEmpty(updated_lgl_inbound_shipping_and_task)) {
              response.reason = '운송장 번호 업데이트 실패';
              return response;
            }

            response.is_OK = true;
            return response;
          } catch (err) {
            response.reason = UtilF.getErrMsg(err);
            return response;
          }
        }),
        groupBy(sel('is_OK')),
      );

      if (res?.true) {
        makeDfProjectionListUpdate();
      }

      const has_fail = res?.false;
      const has_all_fail = res?.success == null && has_fail;
      const result_projection_list_html_el = (title, list) =>
        list
          ? html`
              <div class="lgl_express_uploader">
                <p class="lgl_express_uploader__title"><strong>${title} ${list.length} 건</strong></p>
                ${title === '성공'
                  ? html`<div style="color:green;">
                      ${list.map(({ data: { projection_id } }) => projection_id).join(', ')}
                    </div>`
                  : strMap(
                      ({ data: { projection_id }, reason }) => html`
                        <div style="color:red;">
                          <span>${projection_id}</span>
                          <span>${reason}</span>
                        </div>
                      `,
                      list,
                    )}
              </div>
            `
          : '';

      $.don_loader_end();
      await Swal.fire({
        icon: has_all_fail ? 'error' : has_fail ? 'warning' : 'success',
        title: 'LGL특송 배송완료 처리 결과',
        html: html`
          <div>
            ${result_projection_list_html_el('성공', res?.true)}
            ${result_projection_list_html_el('실패', res?.false)}
          </div>
        `,
        width: '70%',
      });
    } catch (err) {
      $.don_loader_end();
      await UtilAlertF.error({
        title: '업로드 오류',
        msg: UtilF.getErrMsg(err),
        timer: 0,
      });
    }
  });

  file_input_el.click();
}

async function dataProcessPrjToLglExpress(prj) {
  if (prj == null) throw new Error(`${prj?.id ?? ''} 주문서 정보를 가져올 수 없습니다.`);
  const shipping = prj._.shippings[0];

  const {
    id: projection_id,
    _: { user, ups },
  } = prj;

  const upcss = go(
    ups,
    flatMap(({ _: { base_product, product_color, up_cs } }) => {
      return go(
        up_cs,
        flatMap(({ _: { up_c_ss } }) => {
          return up_c_ss.map((up_c_s) => {
            return { ...up_c_s, _: { ...up_c_s._, base_product, product_color } };
          });
        }),
      );
    }),
  );
  const { _: { country, shipping_company, shipping_company_service } = {} } = shipping;

  const { cols } = makeLglHaezelFormHeader({ upcs_ctn: upcss.length });

  const lgl_account_code = DfLglHaezleConstantS.LGL_ACCOUNT_CODE[shipping_company.id];

  if (['dhl', 'ups'].includes(shipping_company.id) && UtilStringS.isEmNil(lgl_account_code)) {
    throw new Error('DHL/UPS 배송 계정 코드가 없습니다.');
  }

  const express_code = shipping_company?.haezle_code ?? '';

  const expreses_service_code = (() => {
    switch (shipping_company.id) {
      case 'ems':
        return 'E';
      case 'k_packet':
        return 'K';
      default:
        return shipping_company_service?.haezle_code ?? '';
    }
  })();

  const main_row_data = {
    lgl_client_code: DfLglHaezleConstantS.LGL_CLIENT_CODE,
    lgl_account_code: DfLglHaezleConstantS.LGL_ACCOUNT_CODE[shipping_company.id],
    projection_id: '' + projection_id,
    express_code,
    express_service_code: expreses_service_code,
    currency: 'KRW',
    ...lglPackageInfo(),
    ...lglReceiver({ shipping, receiver_email: user?.email }),
    ...lglSender(),
  };

  let row_data_main;
  try {
    row_data_main = await go(cols.main, getCellData(main_row_data));
  } catch (err) {
    await $.alert(
      `${DfInhouseF.getProjectionLinkHtml({ projection_id })} ${projection_id} ${UtilF.getErrMsg(err)}`,
    );
    throw err;
  }

  const is_ship_to_japan = main_row_data.receiver_country_code === 'JP';

  const row_data_products = await go(
    upcss,
    flatMap(async (upcs) => {
      const {
        id: user_product_id,
        quantity,
        discounted_price: unit_price,
        _: { base_product, product_color },
      } = upcs;

      const { _: { stores_product } = {} } = product_color;

      const is_tpl = upcs.goods_type_id === 'TPL';
      const original_stores_product = stores_product?._?.origin_stores_product;

      let is_cloth = base_product._.cate_item.is_cloth;
      let cloth_material = base_product.material;
      let hs_code = base_product.hs_code;

      if (is_tpl) {
        is_cloth = stores_product._.cate_item.is_cloth;
        cloth_material = original_stores_product?.material;
        hs_code = original_stores_product?._?.tpl_hs_code?.name;
      }

      if (is_ship_to_japan && is_cloth && UtilS.isEmpty(cloth_material))
        throw new Error(
          `일본일 때 의류 소재는 필수정보로 요구됩니다.\n상품관리 메뉴에서 소재 정보를 입력해 주세요.`,
        );

      const product_name = UtilStringS.removeSpecialCharacter(
        is_tpl ? product_color.name_en : base_product.name_en ?? 'goods',
      ); // 해즐 영문으로만 상품명 받음

      const product_row_data = {
        user_product_id,
        product_name: product_name.slice(0, 30), // 해즐 30자 제한
        hs_code: hs_code?.replace(/\D+/g, '') ?? '',
        quantity,
        unit_price,
        is_cloth: is_cloth ? 'Y' : 'N',
        cloth_material: (cloth_material ?? '').slice(0, 30),
        origin: 'KR',
      };

      let product_row;
      try {
        product_row = go(cols.product, getCellData(product_row_data));
      } catch (err) {
        await $.alert(
          `${DfInhouseF.getProjectionLinkHtml({ projection_id })} ${projection_id} ${UtilF.getErrMsg(err)}`,
        );
        throw err;
      }

      return product_row;
    }),
  );

  return {
    row_data: [...row_data_main, ...row_data_products],
    shipping_company,
    shipping_company_service,
  };
}

export async function bulkDownloadLglHaezleForm({ filename, projections }) {
  try {
    DfInhouseF.lottie_loader.start();

    const rows = await go(projections, map(dataProcessPrjToLglExpress), map(sel('row_data')));

    DfExcelF.downloadExcel({
      aoa_data: rows,
      filename,
    });

    DfInhouseF.lottie_loader.end();
  } catch (err) {
    console.error(err);
    await DfLglF.alertsLgl.error({ err });
  }
}

export async function downloadLglHaezleForm({ prj }) {
  try {
    DfInhouseF.lottie_loader.start();
    const { row_data, shipping_company, shipping_company_service } = await dataProcessPrjToLglExpress(prj);

    DfExcelF.downloadExcel({
      aoa_data: [row_data],
      filename: `LGL_HAEZLE_FORM_${UtilStringS.joinArrStr([
        prj.id,
        prj._.user.name,
        shipping_company.name,
        shipping_company_service.display_name,
      ])}`,
    });

    DfInhouseF.lottie_loader.end();
  } catch (err) {
    console.error(err);
    await DfLglF.alertsLgl.error({ err });
  }
}

function lglPackageInfo() {
  return {
    cargo_type: DfLglHaezleConstantS.LGL_CARGO_TYPE.PACKAGE,
    package_ctn: 1,
    length: 1,
    width: 1,
    height: 1,
    weight: 1,
    volumetric_weight: 1,
  };
}

function lglReceiver({ shipping, receiver_email = '' }) {
  const {
    name: receiver_name = '',
    postcode: receiver_postcode = '0000',
    mobile_en: receiver_tel1,
    state: receiver_state = '',
    city: receiver_city,
    street = '',
    detail = '',
    _: { country: { code: receiver_country_code } = {} } = {},
  } = shipping;
  const receiver_address = UtilS.isEmpty(street) ? detail : street + ', ' + detail;
  return {
    receiver_name,
    receiver_store: '',
    receiver_postcode,
    receiver_tel1,
    receiver_tel2: receiver_tel1,
    receiver_country_code,
    receiver_state,
    receiver_city,
    receiver_address,
    receiver_email,
  };
}

function lglSender() {
  return DfLglHaezleConstantS.LGL_SENDER;
}

function getCellData(row_data) {
  return pipe(
    map(async (col) => {
      const key = col.dev;
      const cell_data = row_data?.[key];
      await col.validation({ [key]: cell_data });
      return cell_data ?? '';
    }),
  );
}

async function createLglProductName({ upcs }) {
  const {
    id: user_product_id,
    _: { base_product, base_product_color, base_product_size, product_color },
  } = upcs;
  const sku = (await axios.post('/@api/df/skus/product', { user_product_id })).data;

  return go(
    [base_product.name, product_color.name, base_product_color.name, base_product_size.name],
    compact,
    join('-'),
  );
}
