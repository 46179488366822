import { equals2, go, html, mapL, reduce, rejectL } from 'fxjs/es';
import { VectorEditorSettingBackgroundPCTmplS } from '../../../../../SettingBackground/PC/S/Tmpl/module/VectorEditorSettingBackgroundPCTmplS.js';
import { VectorEditorTopMenuPCTmplS } from '../../../../../TopMenu/PC/S/Tmpl/module/VectorEditorTopMenuPCTmplS.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';

export const makeTabHtml = ({ is_need_cutting_line = true } = {}) => html`
  <div class="left_container">
    <div class="editor_container"></div>

    <div class="top_menu_container">
      ${VectorEditorTopMenuPCTmplS.makeTopMenusHtml({
        disabled_ids: go(
          Object.values(VectorEditorTopMenuPCConstantS.TOP_MENU),
          mapL(({ ID }) => ID),
          rejectL(equals2(VectorEditorTopMenuPCConstantS.TOP_MENU.TOGGLE_LAYERS_MODE.ID)),
          (ids) =>
            reduce(
              (set, id) => {
                set.add(id);
                return set;
              },
              new Set(),
              ids,
            ),
        ),
      })}
    </div>

    <div class="left_menu" data-icon_type="upload_image" data-is_show="true">
      <button type="button" class="icon"></button>
      <span class="label_text"
        >${T('modules::VectorEditor::Sticker::Single::PC::template::menu_upload_image')}</span
      >
    </div>
    <div class="left_menu" data-icon_type="my_image" data-is_show="true">
      <button type="button" class="icon"></button>
      <span class="label_text"
        >${T('modules::VectorEditor::Sticker::Single::PC::template::menu_my_image')}</span
      >
    </div>
    <div class="left_menu" data-icon_type="shape" data-is_show="true">
      <button type="button" class="icon"></button>
      <span class="label_text">${T('modules::VectorEditor::Sticker::Single::PC::template::menu_shape')}</span>
    </div>
    <div class="left_menu" data-icon_type="path" data-is_show="true">
      <button type="button" class="icon"></button>
      <span class="label_text">${T('modules::VectorEditor::Sticker::Single::PC::template::menu_path')}</span>
    </div>
    <div class="left_menu" data-icon_type="text" data-is_show="true">
      <button type="button" class="icon"></button>
      <span class="label_text">${T('modules::VectorEditor::Sticker::Single::PC::template::menu_text')}</span>
    </div>

    <div class="layer_container" data-is_show="false">
      <div class="header">
        <span class="title">${T('modules::VectorEditor::Sticker::Single::PC::template::layer_title')}</span>
        <button type="button" class="close_layer"></button>
      </div>
      <div class="list_container" data-count="0"></div>
    </div>
  </div>
  <div class="right_container" data-show_panel_wrapper="left" data-show_panel="home">
    <div class="right_wrapper">
      <div class="panel_container">
        <div class="panel_wrapper" data-panel_wrapper="left">
          <div class="inner_wrapper">
            ${VectorEditorSettingBackgroundPCTmplS.makeRightPanelHtml({
              class_list: ['right_panel_home'],
            })}
          </div>
          <div class="button_container">
            <button type="button" class="cancel">
              ${T('modules::VectorEditor::Sticker::Single::PC::template::prev_button')}
            </button>
            <button type="button" class="next" data-is_show="${is_need_cutting_line}">
              ${T('modules::VectorEditor::Sticker::Single::PC::template::next_button')}
            </button>
            <button type="button" class="done" data-is_show="${!is_need_cutting_line}">
              ${T('modules::VectorEditor::Sticker::Single::PC::template::done_button')}
            </button>
          </div>
        </div>
        <div class="panel_wrapper" data-panel_wrapper="right"></div>
      </div>
    </div>
  </div>
`;
