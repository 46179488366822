import { each, go } from 'fxjs/es';
import { $find } from 'fxdom/es';

export const setProductAfterVectorEditor = ({
  lockable_bp_option_group_els,
  bp_option_groups_el,
  product,
  original_svg_file_url,
  printable_file,
  cutting_line_svg_file,
  process_time,
  original_svg_file_name,
}) => {
  go(
    lockable_bp_option_group_els,
    each((bp_virtual_option_group_el) =>
      go(bp_virtual_option_group_el, $find('select'), (el) => {
        el.disabled = true;
      }),
    ),
  );
  bp_option_groups_el.dataset.is_designed = 'true';
  product.svg_product_id = null;
  product._.svg_product_materials = [
    {
      original_svg_file_url,
      printable_file,
      original_cutting_line_svg_file: cutting_line_svg_file,
      process_time,
      original_svg_file_name,
    },
  ];
};
