export const LABEL_FORMAT = {
  SKU: {
    PATH: 'E:sku_label_format.ZPL',
    DPI: 203,
    HOME_POS: { x: 0, y: 1 }, // mm
    IS_ROTATE: false,
    LABEL_WIDTH: 50, // mm
    FORMAT_DATA: [
      {
        // MARPPLE LOGO
        title: 'marpple_logo',
        type: 'static',
        position: { x: 5, y: 1 },
        image: {
          name: 'marpple',
        },
      },
      {
        title: 'branding',
        type: 'static',
        static_data: ['Besties For Creator'],
        field_box: {
          width: 40,
          max_lines: 1,
          line_gap: 0,
          text_just: 'L',
          indent: 0,
        },
        position: { x: 8, y: 1.5 },
        font: {
          name: '0',
          orient: 'N',
          height: 2,
          width: null,
        },
      },
      {
        title: 'sku_no',
        type: 'variable',
        tree_name: null,
        field_box: {
          width: 10,
          max_lines: 1,
          line_gap: 0,
          text_just: 'R',
          indent: 0,
        },
        position: { x: 30, y: 1.5 },
        font: {
          name: '0',
          orient: 'N',
          height: 2,
          width: null,
        },
      },
      {
        title: 'barcode',
        type: 'variable',
        tree_name: null,
        scan_code_type: 'code128,A',
        position: { x: 5, y: 4.5 },
        scan_code_options: {
          module_width: 2,
          height: 7,
        },
      },
      {
        title: 'bp_name',
        type: 'variable',
        tree_name: null,
        field_box: {
          width: 28,
          max_lines: 1,
          line_gap: 0,
          text_just: 'L',
          indent: 0,
        },
        position: { x: 5, y: 13 },
        font: {
          name: '0',
          orient: 'N',
          height: 2,
          width: null,
        },
      },
      {
        title: 'product_option_str',
        type: 'variable',
        tree_name: null,
        field_box: {
          width: 28,
          max_lines: 1,
          line_gap: 0,
          text_just: 'L',
          indent: 0,
        },
        position: { x: 5, y: 16 },
        font: {
          name: '0',
          orient: 'N',
          height: 2,
          width: null,
        },
      },
    ],
  },
};
