export const isFullImage = function (canvas) {
  const image_data = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);
  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      const idx = (x + canvas.width * y) * 4;
      if (image_data.data[idx + 3] !== 255) {
        return;
      }
    }
  }
  return true;
};
