import { $qsa } from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { NewMakerPropertyBaseProductS } from '../../S/Function/module/NewMakerPropertyBaseProductS.js';

export const bpOptionPreviews = {
  getPreviewEls: () => {
    return $qsa('.maker-bp-option-preview');
  },
  renderElement: (product) => {
    return go(
      bpOptionPreviews.getPreviewEls(),
      each((preview_el) => {
        preview_el.outerHTML = NewMakerPropertyBaseProductS.bpOptionPreviews.makePreviewHtml(product);
      }),
    );
  },
};
