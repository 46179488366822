import axios from 'axios';
import { extend, go, sel } from 'fxjs/es';
import { alertWaybillCJstatus } from './alert_windows.js';
import Swal from 'sweetalert2';

export const popupChildShippingStatusForShopByChild = async ({
  projection_id,
  is_pop_up_only_all_merged_complete,
}) => {
  try {
    $.don_loader_start();
    const is_merged_child = await axios.get('/@api/waybill/is_merged_child_projection', {
      params: { projection_id },
    });

    if (is_merged_child) {
      const parent_shipping_task = go(
        await axios({
          url: '/@api/waybill/cj/parent_shipping_status_from_child',
          method: 'get',
          params: { child_projection_id: projection_id },
        }),
        sel('data'),
        (data) => extend({ projection_id: data.id }, data),
      );
      if (is_pop_up_only_all_merged_complete && parent_shipping_task.status !== 'completed') {
        return;
      }

      if (parent_shipping_task.projection_id == null) {
        return Swal.fire({
          icon: 'warning',
          title: '조회 불가',
          html: '<span>병합 주문서 생성 완료 후<br>전체 배송처리 현황 보기가 가능합니다.</span>',
          timer: 5000,
          timerProgressBar: true,
        });
      }

      const siblings_shipping_task = go(
        await axios({
          url: '/@api/waybill/cj/siblings_shipping_statuses',
          method: 'get',
          params: { child_projection_id: projection_id },
        }),
        sel('data'),
      );

      return alertWaybillCJstatus.shop_siblings_shipping_tasks(parent_shipping_task, siblings_shipping_task);
    }
  } catch (e) {
    alert(e.message);
  } finally {
    $.don_loader_end();
  }
};

export const popupChildShippingStatusForShopByParent = async ({ projection_id }) => {
  try {
    $.don_loader_start();
    const is_merged_parent = await axios.get('/@api/waybill/is_merged_parent_projection', {
      params: { projection_id },
    });

    if (is_merged_parent) {
      const parent_shipping_task = go(
        await axios({
          url: '/@api/waybill/cj/parent_shipping_status_from_parent',
          method: 'get',
          params: { projection_id },
        }),
        sel('data'),
        (data) => extend({ projection_id: data.id }, data),
      );

      const siblings_shipping_task = go(
        await axios({
          url: '/@api/waybill/cj/children_shipping_statuses_from_parent',
          method: 'get',
          params: { projection_id },
        }),
        sel('data'),
      );
      await alertWaybillCJstatus.shop_siblings_shipping_tasks(parent_shipping_task, siblings_shipping_task);
    }
  } catch (e) {
    alert(e.message);
  } finally {
    $.don_loader_end();
  }
};
