export const rowToCollaboType = function (row) {
  return row.collabo_type || '';
};

export const rowTo_CollaboType = function (row) {
  const ct = rowToCollaboType(row);
  const _ct = ct ? '_' + ct : '';
  return _ct;
};

export const langTo_en = function (lang) {
  return lang == 'kr' ? '' : `_${lang}`;
};

export const rowTo_en = function (row) {
  return langTo_en(row.lang);
};

const words = {
  df: {
    kr: '국문',
    en: '영문',
    jp: '일문',
  },
};

export const langToDfWord = (lang) => words.df[lang];
