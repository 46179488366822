/**
 * URL에서 확장자를 제외한 파일명을 추출
 * @param {string} url - 파일명을 추출할 URL 문자열
 * @returns {string} 확장자를 제외한 파일명
 */
export function extractFilenameWithoutExtension(url) {
  // URL에서 경로 부분만 추출
  const pathname = new URL(url).pathname;

  // 경로에서 마지막 '/' 이후의 부분(파일명)을 가져옴
  const fullFilename = pathname.split('/').pop();

  // 파일명이 없는 경우 빈 문자열 반환
  if (!fullFilename) return '';

  // 파일명에서 마지막 '.' 이전의 부분만 추출
  const filenameWithoutExtension = fullFilename.split('.').slice(0, -1).join('.');

  return filenameWithoutExtension || fullFilename;
}
