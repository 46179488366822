import { flatMap, go } from 'fxjs/es';
import { OMPProductListConstantS } from '../Constant/module/OMPProductListConstantS.js';

export const checkedFilterItems = ({
  brand_filter,
  color_filter,
  size_filter,
  filter_lists,
  total_count,
}) => {
  if (!total_count) {
    return [];
  }

  return go(
    [brand_filter, color_filter, size_filter, ...filter_lists],
    flatMap(({ _: { filter_items } }) => {
      return filter_items.filter(({ checked }) => {
        return checked;
      });
    }),
  );
};

export const setBpsBrand = (bps) => {
  const is_every_other_brand = !bps.find((bp) => {
    return bp._.brand.id != OMPProductListConstantS.OTHER_BRAND_ID;
  });
  if (is_every_other_brand) {
    return bps.map((bp) => {
      return {
        ...bp,
        _: {
          ...bp._,
          brand: {},
        },
      };
    });
  } else {
    return bps;
  }
};
