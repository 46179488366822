import { PDFDocument, degrees, cmyk } from 'pdf-lib';
import axios from 'axios';
import { PdfConstantS } from '../../S/Constant/module/PdfConstantS.js';
/**
 * @typedef PdfSpec
 * @property { Metadata } metadata
 * @property { PdfPage[] } pages
 * */

/**
 * @typedef Metadata
 * @property { string } title
 * @property { string } author
 * @property { string[] } keywords
 * */

/**
 * @typedef PdfPage
 * @property {{ w_mm: number, h:mm: number }} layout
 * @property {PdfImage[]} images
 * @property { { type: 'svg', geometry: GeometrySvg, border: BorderOptions} |
 *             { type: 'circle', geometry: GeometryCircle, border: BorderOptions} |
 *             { type: 'ellipse', geometry: GeometryEllipse, border: BorderOptions} |
 *             undefined } cutline
 * */

/**
 * @typedef PdfImage
 * @property {number} layer
 * @property {string} src
 * @property {{ x_ratio: number, y_ratio: number }} posToPage
 * @property {{ w_ratio: number, h_ratio: number }} sizeToPage
 * @property {number} rotate_def
 * @property {number} opacity
 * @property {boolean} with_white_background
 * */

/**
 * @typedef GeometryCircle
 * @property {number} center_x_ratio
 * @property {number} center_y_ratio
 * @property {number} diameter_to_width_ratio
 * */

/**
 * @typedef GeometryEllipse
 * @property {number} center_x_ratio
 * @property {number} center_y_ratio
 * @property {number} x_diameter_to_width_ratio
 * @property {number} y_diameter_to_height_ratio
 * */

/**
 * @typedef GeometryRectangle
 * @property {number} lt_x_to_page_width_ratio
 * @property {number} lt_y_to_page_height_ratio
 * @property {number} width_to_page_width_ratio
 * @property {number} height_to_page_height_ratio
 * */

/**
 * @typedef GeometrySvg
 * @property {string} pathdata
 * */

/**
 * @typedef BorderOptions
 * @property {CMYK} color
 * @property {number} width
 * @property {number} opacity
 * */

/**
 * @typedef CMYK
 * @property {number} cyan
 * @property {number} magenta
 * @property {number} yellow
 * @property {number} key
 * */

/**
 * @param {PdfSpec} pdf_spec
 * @return {Promise<File>}
 * */
export async function burnPDF({ pdf_spec }) {
  const pdf = await new MarpplePDF().init();

  const { metadata, pages } = pdf_spec;
  pdf.setMetadata({ metadata });

  for (const page_info of pages) {
    const image_page = pdf.addPage(page_info.layout);
    let peripheral_page = null;

    for (const image_info of page_info.images.sort((a, b) => b.layer - a.layer)) {
      await pdf.drawImageOnPage(image_info, image_page);

      // 흰색 배경 활성화 -> 이미지 페이지 다음 보조 페이지 생성해서 흰색 배경 추가
      if (image_info.with_white_background) {
        peripheral_page = peripheral_page || pdf.addPage(page_info.layout);
        await pdf.drawWhiteImageOnPage(image_info, peripheral_page);
      }
    }

    // Cutline 이 존재하면 보조 페이지에 추가
    if (page_info.cutline) {
      peripheral_page = peripheral_page || pdf.addPage(page_info.layout);

      switch (page_info.cutline.type) {
        case 'svg': {
          pdf.drawSvgPath({ pathdata: page_info.cutline.pathdata, border: page_info.border });
          break;
        }
        case 'circle': {
          pdf.drawCircle({ geometry: page_info.cutline.geometry, border: page_info.cutline.border });
          break;
        }
        case 'ellipse': {
          pdf.drawEllipse({ geometry: page_info.cutline.geometry, border: page_info.cutline.border });
          break;
        }
        case 'rectangle': {
          pdf.drawRectangle({ geometry: page_info.cutline.geometry, border: page_info.cutline.border });
          break;
        }
        default: {
          throw new Error(`Unhandled geometry type`);
        }
      }
    }
  }

  return pdf.toFile(PdfConstantS.PDF_MADE_BY_MARPPLE_EDITOR);
}

class MarpplePDF {
  #pdf;
  #last_page;
  #last_page_idx = 0;

  async init() {
    this.#pdf = await PDFDocument.create();
    return this;
  }

  mmToPt(v_pt) {
    return v_pt * 2.8346456692913;
  }

  flipY(y_pt) {
    return this.#last_page.getHeight() - y_pt;
  }

  addPage({ w_mm, h_mm }) {
    const added_page = this.#pdf.addPage([w_mm, h_mm].map(this.mmToPt));
    this.#last_page = added_page;
    this.#last_page_idx += 1;

    return added_page;
  }

  /**
   * @param {Metadata} metadata
   * */
  setMetadata({ metadata }) {
    const { title, author, keywords } = metadata;

    const now = new Date();

    this.#pdf.setTitle(title);
    this.#pdf.setAuthor(author);
    this.#pdf.setKeywords(keywords);
    this.#pdf.setCreationDate(now);
    this.#pdf.setModificationDate(now);
  }

  /**
   * @define PDF page 위에 white image 렌더링
   * @param {PdfImage}
   * @param {PDFPage} page
   * @return void
   * */
  async drawWhiteImageOnPage(
    { src, posToPage: { x_ratio, y_ratio }, sizeToPage: { w_ratio, h_ratio }, rotate_deg, opacity },
    page,
  ) {
    return this.drawImageOnPage(
      { src, posToPage: { x_ratio, y_ratio }, sizeToPage: { w_ratio, h_ratio }, rotate_deg, opacity },
      page,
      true,
    );
  }

  /**
   * @define PDF page 위에 image 렌더링
   * @param {PdfImage}
   * @param {boolean} is_white_background
   * @param {PDFPage} page
   * @return void
   * */
  async drawImageOnPage(
    { src, posToPage: { x_ratio, y_ratio }, sizeToPage: { w_ratio, h_ratio }, rotate_deg, opacity },
    page,
    is_white_background = false,
  ) {
    page = page || this.#last_page;
    const width = page.getWidth();
    const height = page.getHeight();

    const x_pt = x_ratio * width;
    const y_pt = y_ratio * height;

    const w_pt = w_ratio * width;
    const h_pt = h_ratio * height;

    const image_buffer = is_white_background
      ? await ImageKnife.getBlackImageArrayBuffer({ url: src })
      : await ImageKnife.getImageArrayBuffer({ url: src });
    const image_type = ImageKnife.getImageType({ arrayBuffer: image_buffer });

    let pdf_image;

    switch (image_type) {
      case 'png':
        pdf_image = await this.#pdf.embedPng(image_buffer);
        break;
      case 'jpeg':
        pdf_image = await this.#pdf.embedJpg(image_buffer);
        break;
      default:
        throw new Error(`Unhandled image extension. ${image_type}`);
    }

    const scale = Math.max(w_pt / pdf_image.width, h_pt / pdf_image.height);
    const pdf_dim = pdf_image.scale(scale);

    page.drawImage(pdf_image, {
      x: x_pt,
      y: this.flipY(y_pt) - h_pt,
      width: pdf_dim.width,
      height: pdf_dim.height,
      rotate: degrees(rotate_deg),
      opacity,
    });
  }

  /**
   * @param {GeometrySvg} geometry
   * @param {BorderOptions} border
   * @param {PDFPage} page
   * @return {undefined}
   * */
  drawSvgPath({ geometry, border }, page) {
    page = page || this.#last_page;
    page.drawSvgPath(geometry.pathdata, {
      x: 0,
      y: this.flipY(0),
      borderColor: cmyk(border.color.cyan, border.color.magenta, border.color.yellow, border.color.key),
      borderWidth: border.width,
      borderOpacity: border.opacity,
    });
  }

  /**
   * @param {GeometryCircle} geometry
   * @param {BorderOptions} border
   * @param {PDFPage} page
   * @return {undefined}
   * */
  drawCircle({ geometry, border }, page) {
    page = page || this.#last_page;
    const width = page.getWidth();
    const height = page.getHeight();

    const x_center_pt = geometry.center_x_ratio * width;
    const y_center_pt = geometry.center_y_ratio * height;
    const radius_pt = (geometry.diameter_to_width_ratio * width) / 2;
    page.drawCircle({
      x: x_center_pt,
      y: this.flipY(y_center_pt),
      size: radius_pt,
      borderWidth: border.width,
      borderColor: cmyk(border.color.cyan, border.color.magenta, border.color.yellow, border.color.key),
      borderOpacity: border.opacity,
    });
  }

  /**
   * @param {GeometryEllipse} geometry
   * @param {BorderOptions} border
   * @param {PDFPage} page
   * @return {undefined}
   * */
  drawEllipse({ geometry, border }, page) {
    page = page || this.#last_page;
    const width = page.getWidth();
    const height = page.getHeight();

    const x_center_pt = geometry.center_x_ratio * width;
    const y_center_pt = geometry.center_y_ratio * height;
    const x_scale_pt = (geometry.x_diameter_to_width_ratio * width) / 2;
    const y_scale_pt = (geometry.y_diameter_to_height_ratio * height) / 2;

    page.drawEllipse({
      x: x_center_pt,
      y: this.flipY(y_center_pt),
      xScale: x_scale_pt,
      yScale: y_scale_pt,
      borderWidth: border.width,
      borderColor: cmyk(border.color.cyan, border.color.magenta, border.color.yellow, border.color.key),
      borderOpacity: border.opacity,
    });
  }

  /**
   * @param {GeometryRectangle} geometry
   * @param {BorderOptions} border
   * @param {PDFPage} page
   * @return {undefined}
   * */
  drawRectangle({ geometry, border }, page) {
    page = page || this.#last_page;
    const width = page.getWidth();
    const height = page.getHeight();

    const x_left_top_pt = geometry.lt_x_to_page_width_ratio * width;
    const y_left_top_pt = geometry.lt_y_to_page_height_ratio * height;
    const width_pt = geometry.width_to_page_width_ratio * width;
    const height_pt = geometry.height_to_page_height_ratio * height;

    page.drawRectangle({
      x: x_left_top_pt,
      y: this.flipY(y_left_top_pt),
      width: width_pt,
      height: -height_pt,
      borderWidth: border.width,
      borderColor: cmyk(border.color.cyan, border.color.magenta, border.color.yellow, border.color.key),
      borderOpacity: border.opacity,
    });
  }

  async getBytes() {
    return this.#pdf.save();
  }

  async download() {
    const pdfBytes = await this.getBytes();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

    const pdfUrl = URL.createObjectURL(pdfBlob);

    const a = document.createElement('a');
    a.href = pdfUrl;
    a.download = 'example.pdf'; // 다운로드될 파일 이름 설정

    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(pdfUrl);
  }

  /**
   * @param {string} filename
   * @return {File} pdf_file
   * */
  async toFile(filename) {
    const pdfBytes = await this.getBytes();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    const file = new File([pdfBlob], `${filename}.pdf`, { type: 'application/pdf' });
    return file;
  }
}

class ImageKnife {
  static async getImageArrayBuffer({ url }) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.arrayBuffer();
  }

  static async getImageToCanvas({ url }) {
    const res = await fetch(url);
    if (!res.ok) {
      throw new Error('image source is not ok');
    }

    const blob = await res.blob();
    const src = URL.createObjectURL(blob);

    const img = new Image();
    img.crossOrigin = 'Anonymous';

    return new Promise((res, rej) => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        res(canvas);
      };

      img.onerror = (err) => {
        rej(err);
      };

      img.src = src;
    });
  }

  static async getK100JpegImageArrayBuffer({ url, color }) {
    const canvas = await ImageKnife.getImageToCanvas({ url });
    const ctx = canvas.getContext('2d');

    ctx.globalCompositeOperation = 'source-in';
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const blob = await new Promise((resolve) => canvas.toBlob(resolve));

    const form_data = new FormData();
    form_data.append('image', blob, 'image.png');

    try {
      const response = (
        await axios.post('/@api/image_process/convert_to_k100', form_data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob',
        })
      ).data;

      return response.arrayBuffer();
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  }

  static async fetchImageAsArrayBuffer(imageUrl) {
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'arraybuffer',
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  }

  static async getBlackImageArrayBuffer({ url }) {
    return ImageKnife.getK100JpegImageArrayBuffer({ url, color: 'black' });
  }

  static getImageType({ arrayBuffer }) {
    const view = new DataView(arrayBuffer);
    const imageFormats = [
      { type: 'jpeg', header: [0xff, 0xd8] }, // JPEG
      { type: 'png', header: [0x89, 0x50, 0x4e, 0x47] }, // PNG
      { type: 'gif', header: [0x47, 0x49, 0x46, 0x38] }, // GIF
      { type: 'bmp', header: [0x42, 0x4d] }, // BMP
      { type: 'webp', header: [0x52, 0x49, 0x46, 0x46] }, // WebP
    ];

    function compareHeaders(view, expectedHeader) {
      if (view.byteLength < expectedHeader.length) {
        return false;
      }
      for (let i = 0; i < expectedHeader.length; i++) {
        if (view.getUint8(i) !== expectedHeader[i]) {
          return false;
        }
      }
      return true;
    }
    for (const format of imageFormats) {
      if (compareHeaders(view, format.header)) {
        return format.type;
      }
    }

    return 'unknown';
  }
}
