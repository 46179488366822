import { defaults, go, mapObject, pick, reduce, reject, sel, tap } from 'fxjs/es';
import { makeCvPreviewEditorHtml } from './tmpl.js';
import {
  $appendTo,
  $attr,
  $closest,
  $el,
  $find,
  $findAll,
  $qs,
  $remove,
  $setAttr,
  $setText,
  $val,
} from 'fxdom/es';
import {
  createCanvasElement,
  loadImageFromUrl,
  makeCanvasByUrl,
  makeCanvasCutByLocation,
} from '../../../../Canvas/S/util.js';
import { makePrintAreaAttr } from '../../CvPrintArea/cv_object.js';
import {
  getCurrentFcanvas,
  getCvObj,
  getNotDesigns,
  makeCvObjsVisibleFalse,
  makeCvObjsVisibleTrue,
} from '../../Fcanvas/cv_object.js';
import { MAKER_STROKE_WIDTH, minusStrokeWidth } from '../../Fcanvas/stroke.js';
import { basicDataSetting } from '../../Setting/data.js';
import { initMpMaker } from '../../mp_maker.js';
import { unsetMakerPage } from '../../Setting/unset.js';
import { getItsSf } from '../../getSth.js';
import {
  cvMask1CvMask2CvPrintAreaForceOff,
  cvMask1CvMask2CvPrintAreaForceOffDone,
  getFwidth,
} from '../../util.js';
import { getPreviewClinder } from '../Cylinder/obj.js';
import { isCylinderStyle } from '../../categorize.js';
import axios from 'axios';
import { directDownload } from '../../../../Composite/Maker/F/util.js';
import { makeGrid1 } from '../../../../Composite/Core/F/image_mapping.js';
import { makeCvPreviewEditorEvent } from './event.js';

export function openCvPrintAreaEditor(base_product_id) {
  return new Promise((resolve) => {
    $.frame.open(
      {
        frame_name: 'maker.in_out_pc',
        el_class: 'cv_preview_editor_frame',
        closed: function (don_frame_el, pc) {
          resolve(pc);
        },
      },
      {
        page_name: 'maker.in_out_pc_page',
        appended: function (don_page) {
          go(don_page, $find('>.don_wrapper >.header .title'), $setText('Shade/Distortion 에디터 에디터'));
          go(don_page, $find('>.don_wrapper >.header .don_hide_frame span'), $setText('닫기'));
        },
        tabs: [
          {
            tab_name: 'maker.in_out_pc_tab',
            data_func: async function () {
              await basicDataSetting({
                base_product_id,
              });
              /*DF*/
            },
            showed: async function (el_don_tab) {
              fabric.charWidthsCache = {};
              await initMpMaker($.find1(el_don_tab, '> .don_wrapper'));
              $setAttr(
                {
                  marpplizer_mode: 'short',
                },
                $qs('html'),
              );
              const fcanvas = getCurrentFcanvas();
              initCvPreviewEditorHtml($1('.mp_maker'), fcanvas);

              makeCvPreviewEditorEvent($1('.mp_maker'));
            },
            removed: function () {
              unsetMakerPage();
            },
          },
        ],
      },
    );
  });
}

export const initCvPreviewEditorHtml = (parent, fcanvas) => {
  const preview = fcanvas.preview;
  const sf = getItsSf(fcanvas);
  const cv_preview_editor_el = $find('.cv_preview_editor')(parent);
  if (cv_preview_editor_el) {
    $remove(cv_preview_editor_el);
  }
  go(makeCvPreviewEditorHtml({ preview, sf }), $el, $appendTo(parent));
};

export async function changeCvBpcfElement(cv_obj, url) {
  const img = await loadImageFromUrl(url);
  cv_obj._element = img;
  cv_obj.canvas.renderAll();
}
export async function changeCvBpcfWithUrlAncCutCvPrintArea(url, fcanvas, is_preview) {
  if (url) await changeCvBpcfElement(getCvObj(fcanvas._objects, 'cv_bpcf'), url);
  const objs = getNotDesigns(fcanvas._objects);
  makeCvObjsVisibleFalse(objs);
  fcanvas.renderAll();
  const obj_location = go(
    isCylinderStyle(fcanvas)
      ? getPreviewClinder(fcanvas).area
      : go(
          getCvObj(fcanvas._objects, 'cv_print_area'),
          pick(['top', 'left', 'width', 'height', 'scaleX', 'scaleY', 'strokeWidth']),
          (cv_print_area) => {
            cv_print_area.width *= cv_print_area.scaleX;
            cv_print_area.height *= cv_print_area.scaleY;
            return cv_print_area;
          },
          minusStrokeWidth,
          pick(['top', 'left', 'width', 'height']),
        ),
    mapObject((v) => v * (fcanvas.lowerCanvasEl.width / fcanvas.width)),
  );
  return go(
    makeCanvasCutByLocation(fcanvas.lowerCanvasEl, obj_location),
    (c) => {
      return $.uploadFileToOnlyOriginalUrl({
        url: c.toDataURL(),
        original_name: 'mask_img',
      });
    },
    sel('url'),
    tap(function () {
      makeCvObjsVisibleTrue(objs);
      fcanvas.renderAll();
    }),
  );
}

export async function addCvMoireToFcanvas(fcanvas) {
  if (getCvObj(fcanvas._objects, 'cv_grid')) return;
  const moire_size = go(getCvObj(fcanvas._objects, 'cv_print_area'), minusStrokeWidth);
  const src = await go(
    makeCanvasByUrl(
      '//s3.marpple.co/files/u_193535/2020/6/600/27311350d1b615a72a173bc682b07e63e29e15ed1cfb4ce.png',
    ),
    (c_moire) =>
      go(createCanvasElement(moire_size), (c) => {
        const ctx = c.getContext('2d');
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, c.width, c.height);
        ctx.drawImage(c_moire, 0, 0, c_moire.width, c_moire.height, 0, 0, c.width, c.height);
        return c.toDataURL();
      }),
  );
  return new Promise(function (resolve) {
    fabric.Image.fromURL(src, async function (cv_image) {
      cv_image.set(G.mp.maker.IMAGE);
      cv_image._data.cv_type = 'cv_grid';
      cv_image.scaleToWidth(getFwidth(getCvObj(fcanvas._objects, 'cv_cylinder_area')));
      G.mp.maker.nscreen(cv_image);
      G.mp.maker.align_print_area_center(cv_image);
      fcanvas.add(cv_image);
      await G.mp.maker.cleared();
      resolve(cv_image);
    });
  });
}

export async function addCvMoireCvPrintArea(fcanvas) {
  const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
  if (getCvObj(fcanvas._objects, 'cv_grid')) return;
  return go(
    makeGrid1(
      createCanvasElement({
        width: cv_print_area.width - 2,
        height: cv_print_area.height - 2,
      }),
      true,
    ),
    (c) => c.toDataURL(),
    (src) =>
      new Promise(function (resolve) {
        fabric.Image.fromURL(src, async function (cv_image) {
          cv_image.top = cv_print_area.top + 2;
          cv_image.left = cv_print_area.left + 2;
          cv_image.set(G.mp.maker.IMAGE);
          cv_image._data.cv_type = 'cv_grid';
          G.mp.maker.nscreen(cv_image);
          fcanvas.add(cv_image);
          await G.mp.maker.cleared();
          resolve(cv_image);
        });
      }),
  );
}

export function addBlackSqaureCvPrintAreaToFcanvas(fcanvas, fill = 'rgb(255, 255, 255, 1)') {
  if (getCvObj(fcanvas._objects, 'cv_black_square_print_area')) return;
  const sf = getItsSf(fcanvas);
  return go(
    new fabric.Rect(
      defaults(
        {
          fill,
          opacity: 1,
          visible: true,
          selectable: true,
          evented: true,
        },
        makePrintAreaAttr(sf),
      ),
    ),
    (cv_black_squre) => {
      cv_black_squre._data.cv_type = 'cv_black_square_print_area';
      delete cv_black_squre._data.is_not_design;
      fcanvas.add(cv_black_squre);
      G.mp.maker.cleared();
    },
  );
}

export function makeCvPrintAreaForMask(fcanvas, fill = 'rgb(255, 255, 255, 1)') {
  if (getCvObj(fcanvas._objects, 'cv_print_area_for_mask')) return;
  const sf = getItsSf(fcanvas);
  return go(
    new fabric.Rect(
      defaults(
        {
          fill,
          opacity: 1,
          visible: true,
          selectable: true,
          evented: true,
        },
        sf.print.px,
      ),
    ),
    async (cv_black_squre) => {
      cv_black_squre._data = {
        cv_type: 'cv_print_area_for_mask',
      };
      fcanvas.add(cv_black_squre);
      await G.mp.maker.cleared();
      return cv_black_squre;
    },
  );
}

export function addFlexibleRectToFcanvas(fcanvas) {
  if (getCvObj(fcanvas._objects, 'cv_flexible_rect')) return;
  const sf = getItsSf(fcanvas);
  return go(
    new fabric.Rect(
      defaults(
        {
          visible: true,
          selectable: true,
          evented: true,
          strokeWidth: MAKER_STROKE_WIDTH,
          strokeDashArray: [6, 3],
          stroke: 'rgb(0, 0, 0, 1)',
        },
        makePrintAreaAttr(sf),
      ),
    ),
    (cv_flexible_rect) => {
      cv_flexible_rect._data.cv_type = 'cv_flexible_rect';
      fcanvas.add(cv_flexible_rect);
    },
  );
}
export async function downloadMapMcokupBackground(fcanvas) {
  fcanvas.is_preview_pass = true;
  const location = fcanvas.preview.composite.area;
  cvMask1CvMask2CvPrintAreaForceOff(fcanvas);
  const cv_morie = await addCvMoireCvPrintArea(fcanvas, location);

  const cv_objs = go(
    fcanvas._objects,
    reject((v) => v === cv_morie),
  );
  makeCvObjsVisibleFalse(cv_objs);
  fcanvas.renderAll();
  const mockup_url = makeCanvasCutByLocation(
    fcanvas.lowerCanvasEl,
    go(
      location,
      mapObject((v) => (v * fcanvas.lowerCanvasEl.width) / fcanvas.width),
    ),
  ).toDataURL();

  makeCvObjsVisibleTrue(cv_objs);
  cv_morie.remove();
  fcanvas.renderAll();

  const { width, height, top, left } = go(
    fcanvas.preview.composite.area,
    mapObject((v) => (v * fcanvas.lowerCanvasEl.width) / fcanvas.width),
  );
  const { data: buffer } = await axios.post('/@api/base_product/map_creation', {
    width,
    height,
  });

  const img_base64 =
    'data:image/png;base64,' + btoa(buffer.data.reduce((data, byte) => data + String.fromCharCode(byte), ''));

  directDownload(img_base64, 'map', 'image/png');

  G.mp.maker.download(mockup_url, 'mockup');
  getCvObj(fcanvas._objects, 'cv_print_area').visible = false;
  fcanvas.renderAll();
  G.mp.maker.download(
    go(
      makeCanvasCutByLocation(fcanvas.lowerCanvasEl, {
        width,
        height,
        top,
        left,
      }).toDataURL(),
    ),
    'background',
  );
  cvMask1CvMask2CvPrintAreaForceOffDone(fcanvas);
  delete fcanvas.is_preview_pass;
}

export function getItemValue(e) {
  return go(e.currentTarget, $closest('.item'), $findAll('input'), (arr) =>
    reduce(
      (m, v) => {
        m[$attr('name', v)] = parseInt($val(v));
        return m;
      },
      {},
      arr,
    ),
  );
}

export function getKey(e) {
  return go(e.currentTarget, $closest('.item'), $attr('key'));
}

export function startAdjust(e) {
  const name = go(e.currentTarget, $closest('[adjust-name]'), $attr('adjust-name'));
  const current_name = go(e.currentTarget, $closest('.main-wrapper'), $attr('adjust'));
  if (current_name !== '') return;
  go(e.currentTarget, $closest('.main-wrapper'), $setAttr({ adjust: name }));
  return true;
}

export function endAdjust(e) {
  go(e.currentTarget, $closest('.main-wrapper'), $setAttr({ adjust: '' }));
  $.alert('완료');
}
