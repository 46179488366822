import { VectorEditorKeyringFreeMobileCuttingLineManualMuiS } from '../../S/Mui/module/VectorEditorKeyringFreeMobileCuttingLineManualMuiS.js';
import { $find, $findAll, $on } from 'fxdom/es';
import { each, go, tap } from 'fxjs/es';
import { EditorNS } from '@marpple/sticker-editor';
import { VectorEditorKeyringFreeMobileCuttingLineManualF } from '../Function/module/VectorEditorKeyringFreeMobileCuttingLineManualF.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorKeyringFreeMobileCuttingLineManualMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_keyring_cutting_line_editor = null;
    tab_el.__mp_base_outer_cutting_line_path_data =
      tab_el.__mp_base_outer_cutting_line_path_data ?? 'M 0 0 Z';
    tab_el.__mp_base_inner_cutting_line_path_data =
      tab_el.__mp_base_inner_cutting_line_path_data ?? 'M 0 0 Z';
    tab_el.__mp_art_board_width = tab_el.__mp_art_board_width ?? 0;
    tab_el.__mp_art_board_height = tab_el.__mp_art_board_height ?? 0;
    tab_el.__mp_makeTargetImageEl = tab_el.__mp_makeTargetImageEl ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_inner_cutting_line_update_timeout_id = null;

    go(
      tab_el,

      tap($on('touchmove', (event) => event.preventDefault(), { passive: false })),

      tap(
        $on(
          '@mp/sticker-editor/outer-cutting-line-changed',
          VectorEditorKeyringFreeMobileCuttingLineManualF.handleEditorOuterCuttingLineChanged({ tab_el }),
        ),
      ),

      tap(
        $findAll(`.header .cancel`),
        each($on('click', VectorEditorKeyringFreeMobileCuttingLineManualF.handleHeaderCancelButtonClick())),
      ),
      tap(
        $findAll(`.header .done`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeMobileCuttingLineManualF.handleHeaderDoneButtonClick({ tab_el }),
          ),
        ),
      ),

      tap(
        $findAll(`.footer .footer_menu.reset`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeMobileCuttingLineManualF.handleFooterResetIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.add_l`),
        each(
          $on('click', VectorEditorKeyringFreeMobileCuttingLineManualF.handleFooterAddLIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.add_c`),
        each(
          $on('click', VectorEditorKeyringFreeMobileCuttingLineManualF.handleFooterAddCIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.remove`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeMobileCuttingLineManualF.handleFooterRemoveIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.change_to_l`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeMobileCuttingLineManualF.handleFooterChangeToLIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer .footer_menu.change_to_c`),
        each(
          $on(
            'click',
            VectorEditorKeyringFreeMobileCuttingLineManualF.handleFooterChangeToCIconClick({ tab_el }),
          ),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    const keyring_cutting_line_editor =
      new EditorNS.AcrylicCuttingLineEditorNS.MobileNS.AcrylicCuttingLineMobileEditor({
        window,
        document,
        touch_margin: 6,
        min_zoom_scale: 1,
        max_zoom_scale: 3,
      });
    tab_el.__mp_keyring_cutting_line_editor = keyring_cutting_line_editor;
    keyring_cutting_line_editor.initDOM();
    keyring_cutting_line_editor.initEditor();

    const editor_container_el = $find('.editor_container')(tab_el);
    if (editor_container_el == null) {
      const error = new Error(`editor_container 클래스를 가진 엘리먼트를 찾을 수 없습니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Free::Mobile::CuttingLineManual::template::error_message::no_editor_container_el',
      );
      throw error;
    }
    keyring_cutting_line_editor.appendTo(editor_container_el);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  async rendered(tab_el) {
    const footer_container_el = $find(`.footer_container`)(tab_el);
    if (footer_container_el != null) {
      new Swiper(footer_container_el, { slidesPerView: 'auto', freeMode: true });
    }

    if (tab_el.__mp_keyring_cutting_line_editor == null) {
      const error = new Error(`tab_el.__mp_keyring_cutting_line_editor 객체가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Free::Mobile::CuttingLineManual::template::error_message::no_keyring_cutting_line_editor',
      );
      throw error;
    }
    const editor = tab_el.__mp_keyring_cutting_line_editor;

    if (tab_el.__mp_makeTargetImageEl == null) {
      const error = new Error(`tab_el.__mp_makeTargetImageEl 함수가 null 입니다.`);
      error.__mp_alert_message = T(
        'modules::VectorEditor::Keyring::Free::Mobile::CuttingLineManual::template::error_message::no_make_target_image_el',
      );
      throw error;
    }
    const makeTargetImageEl = tab_el.__mp_makeTargetImageEl;

    $.don_loader_start();
    try {
      editor.fitSize();
      const view_box_vo = editor.calculateViewBoxFitTo({
        size_in_ucs: {
          width: tab_el.__mp_art_board_width,
          height: tab_el.__mp_art_board_height,
        },
        padding_in_vcs: { top: 16 + 48, right: 16, bottom: 16 + 144, left: 16 },
      });
      editor.setViewBox(view_box_vo);

      const target_image_el = await makeTargetImageEl({ factor: 1 });
      editor.setTargetImageEl(target_image_el);

      editor.setOuterCuttingLinePathData(tab_el.__mp_base_outer_cutting_line_path_data);
      editor.setInnerCuttingLinePathData(tab_el.__mp_base_inner_cutting_line_path_data);
      editor.startEditCuttingLinePath();
    } finally {
      $.don_loader_end();
    }
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, _v) {
    if (tab_el.__mp_inner_cutting_line_update_timeout_id != null) {
      const timeout_id = tab_el.__mp_inner_cutting_line_update_timeout_id;
      clearTimeout(timeout_id);
    }
    if (tab_el.__mp_keyring_cutting_line_editor != null) {
      const editor = tab_el.__mp_keyring_cutting_line_editor;
      editor.destroy();
    }

    tab_el.__mp_keyring_cutting_line_editor = null;
    tab_el.__mp_base_outer_cutting_line_path_data = 'M 0 0 Z';
    tab_el.__mp_base_inner_cutting_line_path_data = 'M 0 0 Z';
    tab_el.__mp_art_board_width = 0;
    tab_el.__mp_art_board_height = 0;
    tab_el.__mp_makeTargetImageEl = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_inner_cutting_line_update_timeout_id = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
