import { isObject } from 'fxjs/es';

export class ApiError extends Error {
  constructor(message, status = 500) {
    if (isObject(message)) {
      super(JSON.stringify(message));
      this.json = message;
    } else {
      super(message);
      this.message = message;
    }

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.status = status;
  }
}

export class BadRequestError extends ApiError {
  constructor(message) {
    super(message || 'Bad Request Error', 400);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BadRequestError);
    }
  }
}

export class ForbiddenError extends ApiError {
  constructor(message) {
    super(message || 'Forbidden Error', 403);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ForbiddenError);
    }
  }
}

export class InternalServerError extends ApiError {
  constructor(message) {
    super(message || 'Internal Server Error', 500);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InternalServerError);
    }
  }
}

export class NotFoundError extends ApiError {
  constructor(message) {
    super(message || 'Not Found', 404);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError);
    }
  }
}

export class TooManyRequestError extends ApiError {
  constructor(message) {
    super(message || 'Too Many Request', 429);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TooManyRequestError);
    }
  }
}

export class UnauthorizedError extends ApiError {
  constructor(message) {
    super(message || 'Unauthorized', 401);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnauthorizedError);
    }
  }
}
