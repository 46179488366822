import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPProductListConstantS } from '../../../S/Constant/module/OMPProductListConstantS.js';
import { OMPProductListTmplS } from '../../../S/Tmpl/module/OMPProductListTmplS.js';
export const modal = ({ cate_lists, bps, query, checked_cate_list, checked_cate_item, is_mobile }) => {
  const tt_filter = TT('product::list::list_16_1');
  if (is_mobile) {
    const sort_list = OMPProductListConstantS.SORT_LIST().map((data) => {
      return {
        ...data,
        checked: data.id == query.sort,
      };
    });
    const checked_sort = sort_list.find(({ checked }) => checked);
    return html`
      <div class="omp-product-list-modal">
        <div class="omp-mo-product-header">
          <div class="omp-mo-product-header__top">
            <div class="omp-product-list__flex-space-between">
              ${OMPProductListTmplS.moCateSelect({ checked_cate_list, checked_cate_item })}
              <div class="omp-product-list__flex-space-between">
                <button
                  class="omp-mo-product-header__filter-open-btn omp-product-list-modal__filter-open-btn"
                >
                  ${tt_filter}
                  <span
                    class="omp-mo-product-header__filter-count opm-product-list-checked-filter-count"
                    data-count="0"
                    >(0)</span
                  >
                </button>
                <div class="omp-mo-product-header__sort">
                  <div class="omp-mo-product-header__checked-sort">
                    <label class="omp-mo-product-header__checked-sort-name">
                      <span class="omp-product-list__checked-sort-name" data-id="${checked_sort.id}"
                        >${checked_sort.name}</span
                      >
                      <span class="omp-mo-product-header__checked-sort-arrow"
                        >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
                      >
                      <select class="omp-mo-product-header__sort-selector">
                        ${strMap((sort) => {
                          return html`
                            <option
                              class="omp-mo-product-header__sort-item"
                              data-id="${sort.id}"
                              data-name="${sort.name}"
                              value="${sort.id}"
                              ${checked_sort.id == sort.id ? ' selected' : ''}
                            >
                              ${sort.name}
                            </option>
                          `;
                        }, sort_list)}
                      </select>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="omp-product-cate-selector">
              <div class="omp-product-cate-selector__cate-lists omp-product-list-modal__cate-lists">
                <div class="omp-product-cate-selector__cate-list-wrapper">
                  ${OMPProductListTmplS.cateSelectorBody({
                    cate_lists,
                    checked_cate_list_id: checked_cate_list?.id,
                    checked_cate_item_id: checked_cate_item?.id,
                  })}
                </div>
              </div>
            </div>
          </div>
          <div
            class="omp-product-list-modal__checked-filter-items omp-product-list__checked-filter-items opm-product-list-checked-filter-items"
            data-list_show="false"
          ></div>
        </div>
        <div class="omp-product-list-modal__body opm-product-list-bps" data-filter_show="false">
          ${OMPProductListTmplS.moListBody(bps)}
        </div>
      </div>
    `;
  } else {
    return html`
      <div class="omp-product-list-modal">
        <div class="omp-product-list-modal__header">
          <div class="omp-product-list__flex-space-between">
            ${OMPProductListTmplS.cateSelectorTitle({ checked_cate_list, checked_cate_item })}
            <div class="omp-product-list__flex-space-between">
              <button type="button" class="omp-product-list-modal__filter-open-btn">
                <span class="omp-product-list-modal__filter-open-icon">${OMPCoreAtomTmplS.filterIcon()}</span>
                ${tt_filter}
                <span
                  class="omp-product-list-modal__filter-open-count opm-product-list-checked-filter-count"
                  data-count="0"
                  >(0)</span
                >
              </button>
              ${OMPProductListTmplS.sortSelector(query)}
            </div>
          </div>
          <div class="omp-product-list-modal__cate-lists">
            ${OMPProductListTmplS.cateSelectorBody({
              cate_lists,
              checked_cate_list_id: checked_cate_list?.id,
              checked_cate_item_id: checked_cate_item?.id,
            })}
          </div>
        </div>
        <div
          class="omp-product-list-modal__checked-filter-items opm-product-list-checked-filter-items"
          data-list_show="false"
        ></div>
        <div class="omp-product-list-modal__body opm-product-list-bps">
          ${OMPProductListTmplS.pcListBody(bps)}
        </div>
      </div>
    `;
  }
};
