import { entries, go, html, pick, strMap } from 'fxjs/es';
export const fn = ({ base_product_id }) => html`
  <div class="main" data-base_product_id="${base_product_id}">
    <div class="size_face_editor_wrapper"></div>
  </div>
`;

export const makeAdjustHtml = ({
  base_product_faces,
  item,
  sf_print,
  sf_safety,
  base_product_sizes,
  mobile_maker_size_ratio,
  pc_maker_size_ratio,
  sf,
  sticker_previews,
  current_bpf_id,
}) => {
  return html`
    <div class="size_face_editor">
      <div class="section select_face">
        <div class="head">면 고르기</div>
        <div class="body">
          <div class="row_wrapper">
            ${strMap(
              (base_product_face) => html`
                <button
                  class="change_bpf ${base_product_face.id === current_bpf_id ? 'selected' : ''}"
                  data-base_product_face_id="${base_product_face.id}"
                >
                  ${base_product_face.name}
                </button>
              `,
              base_product_faces,
            )}
          </div>
        </div>
      </div>
      <div class="section etc_function">
        <div class="head">다양한 기능</div>
        <div class="option">
          <div class="row_wrapper">
            <button class="apply_to_size_faces" type="button">모든 사이즈에 일괄 적용</button>
            <button class="make_mask" type="button">마스크 만들기</button>
            <button class="make_mask2" type="button">마스크2 만들기</button>
            <button class="export_area" type="button">영역 크기 추출</button>
            ${sf.safety
              ? html`<button class="delete_safety" type="button">안전 영역 지우기</button>`
              : html``}
            <button class="change_all_other_print_area" type="button">
              같은면의 전체 사이즈 프린트 영역, 해당 면 기준으로 변경(top은 동일)
            </button>
          </div>
        </div>
      </div>
      <div class="section select_size">
        <div class="head">사이즈 고르기</div>
        <div class="option">
          <div class="row_wrapper">
            ${strMap(
              (base_product_size) => html`
                <button
                  class="change_size ${sf.base_product_size_id === base_product_size.id ? 'selected' : ''}"
                  data-base_product_size_id="${base_product_size.id}"
                >
                  ${base_product_size.name}
                </button>
              `,
              base_product_sizes,
            )}
          </div>
        </div>
      </div>
      <div class="canvas_size section">
        <div class="head">#PC 만들기 캔버스 크기</div>
        <div class="body">
          <div class="row_wrapper">
            <label>width</label>
            <input type="number" name="sf_img_width" value="${sf.img.width}" />
          </div>
          <div class="row_wrapper">
            <label>height</label>
            <input type="number" name="sf_img_height" value="${sf.img.height}" />
          </div>
          <div class="row_wrapper">
            <label>vertical diff</label>
            <input type="number" name="sf_img_diff" value="${sf.img.diff}" />
          </div>
        </div>
        <div class="option row_wrapper">
          <button type="button" class="apply">적용</button>
          <button type="button" class="save">저장</button>
        </div>
      </div>
      <div class="section maker_size_ratio" data-target_device="mobile">
        <div class="head">#mobile 만들기 캔버스 크기</div>
        <div class="body">
          <div class="row_wrapper">
            <input type="number" value="${Math.ceil((mobile_maker_size_ratio || 1) * 100)}" />
          </div>
        </div>
        <div class="option row_wrapper"><button type="button" class="save">저장</button></div>
      </div>
      <div class="section maker_size_ratio" data-target_device="pc">
        <div class="head">#pc 만들기 캔버스 크기</div>
        <div class="body">
          <div class="row_wrapper">
            <input type="number" value="${Math.ceil((pc_maker_size_ratio || 1) * 100)}" />
          </div>
        </div>
        <div class="option row_wrapper"><button type="button" class="save">저장</button></div>
      </div>
      <div class="section sticker_previews">
        <div class="head">스티커 미리보기</div>
        <div class="body">
          ${strMap(
            (sticker_preview) => html`
              <form>
                <label for="">용지 및 사이즈(${sticker_preview.option_names.join('-')})</label>
                <textarea name="option_names" style="display:none;">
${JSON.stringify(sticker_preview.option_names)}</textarea
                >
                <div class="row_wrapper file_wrapper">
                  <input type="file" />
                  <input name="url" type="text" value="${sticker_preview.url}" />
                  <button type="button">업로드</button>
                </div>
              </form>
            `,
          )(sticker_previews || [])}
        </div>
        <div class="option">
          <div class="row_wrapper"><button type="button" class="save">저장</button></div>
        </div>
      </div>
      <div class="section manage_cv_obj_alignment">
        <div class="head">#아이템 정렬 박스 관리</div>
        <div class="option">
          <div class="row_wrapper">
            <button type="button" class="reset_cv_obj_alignment">새로 만들기</button>
            <button type="button" class="show_all_cv_obj_alignment">만들어진 정렬 확인</button>
          </div>
        </div>
        <div class="cv_obj_alignment_add_editor"></div>
      </div>
      <div class="section manage_cv_obj_actions">
        <div class="head">옵션-이미지-액션 관리</div>
        <div class="cv_obj_actions_editor_wrapper"></div>
      </div>
      <div class="section make_cv_safety_area_size">
        <form>
          <div class="head">자동 안전영역 만들기(바깥 점선과 안쪽 점선의 차이 단위(cm))</div>
          <div class="body">
            <div class="row_wrapper">
              <input type="number" name="diff_cm" />
            </div>
          </div>
          <div class="option">
            <button type="button" class="apply">적용</button>
          </div>
        </form>
      </div>
      <div class="section other_properties">
        <form>
          <div class="head">기타</div>
          <div class="body">
            <div class="row_wrapper">
              <label for="">px_per_1cm</label>
              <input type="number" name="px_per_1cm" value="${sf.px_per_1cm}" />
            </div>
            <div class="row_wrapper">
              <label for="">dpi</label>
              <input type="number" name="dpi" value="${sf.dpi}" />
            </div>
            <div class="row_wrapper">
              <label for="">print_area_legacy</label>
              <input
                type="checkbox"
                name="is_print_area_legacy"
                ${sf.is_print_area_legacy ? 'checked' : ''}
              />
            </div>
            <div class="row_wrapper">
              <label for="">start_point_cm</label>
              <input type="number" name="start_point_cm" value="${sf.start_point_cm}" />
            </div>
          </div>
          <div class="option row_wrapper">
            <button type="button" class="save">저장</button>
          </div>
        </form>
      </div>
      <div class="section">
        <div class="head">영역 보기</div>
        <div class="body">
          <div class="option row_wrapper">
            <button type="button" class="general show_cv_obj" data-cv_obj="cv_safe_area">
              안전 영역 이미지
            </button>
            <button type="button" class="cv_print_item show_cv_obj" data-cv_obj="cv_print_item">
              칼선 템플릿
            </button>
            <button type="button" class="print_area show_cv_obj" data-cv_obj="cv_print_area">
              인쇄 최대 영역
            </button>
            <button type="button" class="print_area show_cv_obj" data-cv_obj="cv_safety_area">
              인쇄 안전 영역
            </button>
            <button type="button" class="mask show_cv_obj" data-cv_obj="cv_mask1">마스크1</button>
            <button type="button" class="mask show_cv_obj" data-cv_obj="cv_mask2">마스크2</button>
          </div>
        </div>
      </div>
      ${item
        ? html`
            <div class="section item">
              <form>
                <div class="head">#칼선 템플릿(위치 맞추기)</div>
                <div class="body">
                  <div class="row_wrapper file_wrapper" style="display:none">
                    <label for="">파일</label>
                    <input type="file" />
                    <input name="print_item_url" type="text" value="${item.print_item_url}" />
                    <button type="button">업로드</button>
                  </div>
                  <div class="row_wrapper">
                    <label for="">#top</label>
                    <input name="top" type="number" value="${item.px?.top}" />
                  </div>
                  <div class="row_wrapper">
                    <label for="">#left</label>
                    <input name="left" type="number" value="${item.px?.left}" />
                  </div>
                  <div class="row_wrapper">
                    <label for="">#width</label>
                    <input name="width" type="number" value="${item.px?.width}" />
                  </div>
                  <div class="row_wrapper">
                    <label for="">#height</label>
                    <input name="height" type="number" value="${item.px?.height}" />
                  </div>
                  <div class="row_wrapper">
                    <label for="">cm_width</label>
                    <input name="cm_width" type="number" value="${item.cm?.width}" />
                  </div>
                  <div class="row_wrapper">
                    <label for="">cm_height</label>
                    <input name="cm_height" type="number" value="${item.cm?.height}" />
                  </div>
                  <div class="row_wrapper">
                    <label for="">is_90_rotate</label>
                    <input
                      class="item_rotate"
                      name="is_90_rotate"
                      type="checkbox"
                      ${item.is_90_rotate ? 'checked' : ''}
                    />
                    <label for="">is_180_rotate</label>
                    <input
                      class="item_rotate"
                      name="is_180_rotate"
                      type="checkbox"
                      ${item.is_180_rotate ? 'checked' : ''}
                    />
                    <label for="">is_270_rotate</label>
                    <input
                      class="item_rotate"
                      name="is_270_rotate"
                      type="checkbox"
                      ${item.is_270_rotate ? 'checked' : ''}
                    />
                  </div>
                </div>
                <div class="option row_wrapper">
                  <button type="button" class="save">저장</button>
                  <button type="button" class="to_center">center</button>
                  <button type="button" class="mask1">mask1</button>
                  <button type="button" class="mask2">mask2</button>
                </div>
              </form>
            </div>
          `
        : ''}
      ${strMap(adjustAreaHtml, [
        { adjust_area: sf_print, cv_type: 'cv_print_area', is_important: true, name: '최대 인쇄' },
        { adjust_area: sf_safety, cv_type: 'cv_safety_area', name: '안전 인쇄' },
      ])}
    </div>
  `;
};

function adjustAreaHtml({ adjust_area, cv_type, is_important, name }) {
  const { px, etc_meta } = adjust_area;
  let { result_px, cm, img, is_out_of_product } = adjust_area;
  result_px = result_px || { width: 0, height: 0 };
  cm = cm.width ? cm : { width: 0, height: 0 };
  img = img || {};
  return html`
    <div class="section adjust_area_size" data-cv_type="${cv_type}">
      <div class="head">${name}</div>
      <div class="body">
        <form data-obj="result_px">
          <div class="head">result_px(디지털 했을때 사용 했음.)</div>
          <div class="body">
            ${strMap(
              ([key, value]) => html`
                <div class="row_wrapper">
                  <label for="">${key}</label>
                  <input type="number" name="${key}" value="${value}" step="1" />
                </div>
              `,
            )(go(result_px, pick(['width', 'height']), entries))}
          </div>
        </form>
        <form data-obj="px">
          <div class="head">px</div>
          <div class="body">
            ${strMap(
              ([key, value]) => html`
                <div class="row_wrapper">
                  <label for="">${is_important ? '#' : ''}${key}</label>
                  <input type="number" name="${key}" value="${value}" step="1" />
                </div>
              `,
            )(go(px, pick(['top', 'left', 'width', 'height', 'rx', 'ry', 'scaleX', 'scaleY']), entries))}
          </div>
          <div class="option row_wrapper">
            <button type="button" class="apply">적용</button>
            <button type="button" class="mask1">mask1</button>
            <button type="button" class="mask2">mask2</button>
          </div>
        </form>
        <form data-obj="cm">
          <div class="head">result_cm</div>
          <div class="body">
            ${strMap(
              ([key, value]) => html`
                <div class="row_wrapper">
                  <label for="">${key}</label>
                  <input type="number" name="${key}" value="${value}" step="0.01" />
                </div>
              `,
            )(go(cm, pick(['width', 'height']), entries))}
          </div>
        </form>
        <form data-obj="img">
          <div class="head">이미지</label>
          </div>
          <div class="body">
            <div class="row_wrapper file_wrapper">
              <input type="file" />
              <input name="url" type="text" value="${img.url || ''}" />
              <button type="button">업로드</button>
            </div>
          </div>
        </form>
        <form data-obj="etc_meta">
          <div class="head">etc meta</div>
          <div class="body">
            <div class="row_wrapper">
              <textarea name="etc_meta" id="" cols="30" rows="1">${
                etc_meta ? JSON.stringify(etc_meta) : '{}'
              }</textarea>
            </div>
          </div>
        </form>
        ${
          cv_type === 'cv_print_area'
            ? html` <form data-obj="is_out_of_product">
                <div class="head">점선 색상</div>
                <div class="body">
                  <div class="row_wrapper">
                    <input type="checkbox" name="is_out_of_product" ${is_out_of_product ? 'checked' : ''} />
                    <label for="is_out_of_product">검정색 점선 고정</label>
                  </div>
                </div>
              </form>`
            : ``
        }
      </div>
      <div class="option row_wrapper">
        <button type="button" class="save">${name} 영역 저장</button>
      </div>
    </div>
  `;
}
