import { $attr, $css, $delegate, $find, $hide, $qs, $setCss, $show } from 'fxdom/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { NewMakerGuideConfirmMuiS } from '../../S/Mui/module/NewMakerGuideConfirmMuiS.js';
import { page } from './page.js';
import { go } from 'fxjs/es';
/*
 * 프론트엔드에서 사용될 frame 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#b847a14a76274bff9aaa6d549c5bef88
 * */
export const frame = {
  ...NewMakerGuideConfirmMuiS.frame, // S의 Mui에서 프레임옵션을 가져옵니다.

  root_page: page, // /F/page 옵션객체 - 필수

  height: undefined, // 모바일 전용, frame 높이
  always_remove: false, // closeFrame 때 무조건 삭제
  animation: !MuiF.IS_IOS, // frame을 열거나 닫을때 애니메이션 (기본 - ios면 false 이외 true)
  is_modal: false, // frame 을 모달로 열때

  appending(frame_el$) {}, // frame 엘리먼트가 만들어지면 울리는 함수
  appended(frame_el$) {
    G.mp.maker.reset_layer();
    if ($qs('.tip_box')) $hide($qs('.tip_box'));
    if ($attr('is_mobile', $qs('html')) === 'true') {
      go(frame_el$, $find('>.don_wrapper >.body'), (el) => el && $setCss({ 'overflow-y': 'scroll' })(el));
    }
    return go(
      frame_el$,
      $delegate('click', '.btn_confirm', () => {
        const src1_el = go(frame_el$, $find('.src1'));
        const src2_el = go(frame_el$, $find('.src2'));
        if (src2_el && $css('display')(src2_el) === 'none') {
          $setCss({ display: 'block' })(src2_el);
          $setCss({ display: 'none' })(src1_el);
          return;
        }
        MuiF.closeFrame();
      }),
    );
  }, // frame 엘리먼트가 html에 붙으면 울리는 함수
  showing(frame_el$) {}, // frame 엘리먼트 show하기 전 울리는 함수
  showed(frame_el$) {}, // frame 엘리먼트 show하고 나서 울리는 함수
  /* frame을 닫을때 울리는 함수 */
  closing(frame_el$, val) {
    if ($qs('.tip_box')) $show($qs('.tip_box'));
  }, // frame이 닫히기 전 울리는 함수
  hiding(frame_el$, val) {}, // frame이 가려지기 전 울리는 함수
  hided(frame_el$, val) {}, // frame이 가려진 후 울리는 함수
  removing(frame_el$, val) {}, // frame이 삭제되기 전 울리는 함수
  removed(frame_el$, val) {}, // frame이 삭제된 후 울리는 함수
  closed(frame_el$, val) {}, // frame이 닫힌 후 울리는 함수
};
