import { makeTrackingTabHtml } from '../Tmpl/tmpl.tabs.js';
import { base_tab } from './base_tab.js';

const mui_ref_path = '/modules/Df/Lgl/ShippingDetail/S/Mui/tab_tracking.js';
export const tab_tracking = base_tab({
  mui_ref_path,
  title: '현황 / 배송 조회',
  tag: 'tracking',
  template: makeTrackingTabHtml,
});
