import { chunk, html } from 'fxjs/es';
import { SVGEditorRangeSliderWrapperTmplS } from '../../../RangeSliderWrapper/S/Tmpl/module/SVGEditorRangeSliderWrapperTmplS.js';
import { SVGEditorAttrS } from '../../../Attr/S/Function/module/SVGEditorAttrS.js';
import { SVGEditorColorTmplS } from '../../../Color/S/Tmpl/module/SVGEditorColorTmplS.js';
import { VectorEditorColorConstS } from '../../../../VectorEditor/Color/S/Const/module/VectorEditorColorConstS.js';
import { SVGEditorStrokeDashF } from '../../../StrokeDash/F/Function/module/SVGEditorStrokeDashF.js';
import { SVGEditorStrokeDashTmplS } from '../../../StrokeDash/S/Tmpl/module/SVGEditorStrokeDashTmplS.js';

export const border = ({ svg_el, one_line_length }) => {
  const stroke_width = SVGEditorStrokeDashF.svgToViewUnit(SVGEditorAttrS.get('stroke-width', svg_el));
  return html`
    <div class="svg_editor_frame_border_wrapper" data-has_stroke="${stroke_width > 0}">
      <div class="colors_wrapper block_for_stroke_dash_wrapper" data-key="stroke">
        ${SVGEditorColorTmplS.colorsWrapper({
          color_code: SVGEditorAttrS.get('stroke', svg_el),
          colors: chunk(one_line_length, VectorEditorColorConstS.COLORS16),
        })}
        <div class="block_for_stroke_dash"></div>
      </div>
      <div data-key="strokeWidth" class="range_slider_wrapper">
        ${SVGEditorRangeSliderWrapperTmplS.fn({
          key: 'stroke-width',
          title: T('maker_border_editor::두께'),
          step: 1,
          min: 0,
          max: 30,
          value: stroke_width,
        })}
      </div>
      ${SVGEditorStrokeDashTmplS.makeStrokeDashHtml(svg_el)}
    </div>
  `;
};
