import { each, go, compact } from 'fxjs/es';
import { getCurrentFcanvas, getCvDesigns } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { makeCenterImageByPxPer1Cm } from './makeCenterImageByPxPer1Cm.js';

export const addPreviewImage = async (preview_images) => {
  const fcanvas = getCurrentFcanvas();
  const designs = getCvDesigns(fcanvas._objects);
  go(
    designs,
    each((cv_obj) => fcanvas.remove(cv_obj)),
  );
  await go(
    preview_images,
    compact,
    each(async (image) => {
      const cv_image = await makeCenterImageByPxPer1Cm({ image, fcanvas });
      cv_image.cid = 'c2323';
      fcanvas.add(cv_image);
    }),
  );
};
