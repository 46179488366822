import {
  each,
  entries,
  find,
  flatMap,
  go,
  groupBy,
  head,
  ippL,
  map,
  mapC,
  mapObject,
  pipe,
  reduce,
  sel,
  sortByDesc,
} from 'fxjs/es';
import { makeOnlyDesignFaceCanvas } from '../../../Maker/F/draw_product_faces.js';
import axios from 'axios';
import { createCanvasElement, makeCanvasByUrl } from '../../../Canvas/S/util.js';
import { makeWarpCylinder } from './cylinder.js';
import { BpOptionConstantS } from '../../../BpOption/S/Constant/module/BpOptionConstantS.js';

function xy1xy2Distance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(Math.abs(x2 - x1), 2) + Math.pow(Math.abs(y2 - y1), 2));
}
export async function makeMockupSize(assoc_composite_templates) {
  return go(
    assoc_composite_templates,
    flatMap((assoc_composite_template) => {
      return go(
        assoc_composite_template,
        sel('_.composite_masks'),
        flatMap(async (cm) => {
          const is_masking_tape = cm._?.base_product?.maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR;
          return go(
            cm._.composite_faces,
            map(async ({ design_position, etc_meta }) => {
              if (etc_meta?.is_cylinder) {
                return {
                  width: (etc_meta.width / 2) * Math.PI * 2,
                };
              }
              return go(
                design_position,
                ({ tl, bl, tr, br }) => {
                  return {
                    width: Math.max(
                      xy1xy2Distance(tl.x, tl.y, tr.x, tr.y),
                      xy1xy2Distance(bl.x, bl.y, br.x, br.y),
                    ),
                    height: Math.max(
                      xy1xy2Distance(tl.x, tl.y, bl.x, bl.y),
                      xy1xy2Distance(tr.x, tr.y, br.x, br.y),
                    ),
                  };
                },
                (size) => {
                  return is_masking_tape
                    ? {
                        width: Math.round(size.width / 2),
                        height: Math.round(size.height / 2),
                      }
                    : etc_meta?.is_fixed_width
                    ? {
                        width: Math.round(size.width * 4),
                      }
                    : size;
                },
              );
            }),
          );
        }),
        reduce((a, b) => {
          return {
            width: Math.max(a.width, b.width),
            height: Math.max(a.height, b.height),
          };
        }),
      );
    }),
    reduce((a, b) => {
      return {
        width: Math.max(a.width, b.width),
        height: Math.max(a.height, b.height),
      };
    }),
  );
}
/*
 * assoc_composite_templates
 *   cm
 *     cf {design_position, base_product_face_id}
 * product
 *   product_faces2.value
 *     only_design_face_url
 * */
function makeBPMockupSet(assoc_composite_templates, product) {
  const { base_product_size_id } = product;
  return go(
    assoc_composite_templates,
    flatMap((assoc_composite_template) => {
      return go(
        assoc_composite_template,
        sel('_.composite_masks'),
        ippL,
        flatMap(async ([idx, cm]) => {
          return go(
            cm._.composite_faces,
            map(async ({ design_position, base_product_face_id, etc_meta }) => {
              const size = go(design_position, ({ tl, bl, tr, br }) => {
                if (etc_meta?.is_fixed_width)
                  return {
                    width:
                      Math.max(
                        xy1xy2Distance(tl.x, tl.y, tr.x, tr.y),
                        xy1xy2Distance(bl.x, bl.y, br.x, br.y),
                      ) * 4,
                  };
                return {
                  width: Math.max(
                    xy1xy2Distance(tl.x, tl.y, tr.x, tr.y),
                    xy1xy2Distance(bl.x, bl.y, br.x, br.y),
                  ),
                  height: Math.max(
                    xy1xy2Distance(tl.x, tl.y, bl.x, bl.y),
                    xy1xy2Distance(tr.x, tr.y, br.x, br.y),
                  ),
                };
              });
              return {
                base_product_face_id,
                size,
                product_idx: idx,
              };
            }),
          );
        }),
      );
    }),
    groupBy((design_mockup_sets) => design_mockup_sets.base_product_face_id),
    mapObject((design_mockup_sets) => go(design_mockup_sets, sortByDesc('size'), head)),
    entries,
    map(([_, base_product_set]) => base_product_set),
    mapC(async (base_product_set) => {
      const { base_product_face_id, size } = base_product_set;

      const product_face = find((pf) => pf.bpf_id === base_product_face_id, product.product_faces2.value);
      base_product_set.mockup_todataurl =
        product_face?.only_design_face_url ||
        (await makeOnlyDesignFaceCanvas(product_face, base_product_size_id, size).then((c) => c.toDataURL()));
      base_product_set.product_id = product.id;
      return base_product_set;
    }),
  );
}

/*
 * assoc_composite_templates
 *   cm
 *     cf {design_position, base_product_face_id}
 * product
 *   product_faces2.value
 *     only_design_face_url
 *
 * return
 * assoc_composite_templates
 *   cm
 *     cf {design_position, base_product_face_id, mockup_url}
 * */
export async function setCompositeTemplateMockupUrl(assoc_composite_templates, product) {
  const bp_mockup_sets = await go(
    makeBPMockupSet(assoc_composite_templates, product),
    mapC(async (bp_mockup_set) => {
      bp_mockup_set.mockup_url =
        bp_mockup_set.mockup_todataurl.indexOf('base64') > -1
          ? await go(
              $.uploadFileToOnlyOriginalUrl({
                original_name: 'mockup_url',
                image_type: 'PNG',
                url: bp_mockup_set.mockup_todataurl,
              }),
              sel('url'),
            )
          : bp_mockup_set.mockup_todataurl;
      return bp_mockup_set;
    }),
  );
  go(
    assoc_composite_templates,
    each(
      pipe(
        sel('_.composite_masks'),
        each(
          pipe(
            sel('_.composite_faces'),
            each((cf) => {
              const bp_mockup_set = find(
                (bp_mockup_set) => bp_mockup_set.base_product_face_id === cf.base_product_face_id,
                bp_mockup_sets,
              );
              cf.mockup_url = bp_mockup_set.mockup_url;
            }),
          ),
        ),
      ),
    ),
  );
}

// async function makeProductImg(product_url, { top, left, width, height }) {
//   const size = G.mp.maker.CANVAS_WIDTH_ORIGIN;
//   const canvas = createCanvasElement({
//     width: size,
//     height: size,
//   });
//   const ctx = canvas.getContext('2d');
//   const img = await loadImageFromUrl(product_url);
//   if (width === height) {
//     const img_ratio = img.height / img.width;
//     ctx.drawImage(img, 0, 0, img.width, img.height, left, top, width, width * img_ratio);
//   } else {
//     ctx.drawImage(img, 0, 0, img.width, img.height, left, top, width, height);
//   }
//   return canvas;
// }

// function makeRedCanvas(color) {
//   const size = G.mp.maker.CANVAS_WIDTH_ORIGIN;
//   const canvas = createCanvasElement({
//     width: size,
//     height: size,
//   });
//   const ctx = canvas.getContext('2d');
//   ctx.fillStyle = color;
//   ctx.fillRect(0, 0, canvas.width, canvas.height);
//   return canvas;
// }
//
// function setRedProductCanvas(red_canvas, product_canvas) {
//   const ctx = red_canvas.getContext('2d');
//   ctx.globalCompositeOperation = 'destination-in';
//   ctx.drawImage(product_canvas, 0, 0);
//   return red_canvas;
// }
//
// function makeRedBorderProductCanvas(red_product_canvas, product_canvas, strokeWidth) {
//   const canvas_size = G.mp.maker.CANVAS_WIDTH_ORIGIN;
//   const canvas = createCanvasElement({
//     width: canvas_size,
//     height: canvas_size,
//   });
//   const ctx = canvas.getContext('2d');
//   ctx.drawImage(red_product_canvas, 0, 0);
//   const top_left_canvas = makeCloneCanvas(canvas);
//   const top_left_ctx = top_left_canvas.getContext('2d');
//   const right_bottom_canvas = makeCloneCanvas(canvas);
//   const right_bottom_ctx = right_bottom_canvas.getContext('2d');
//   top_left_ctx.globalCompositeOperation = 'destination-out';
//   top_left_ctx.drawImage(product_canvas, strokeWidth, strokeWidth);
//   right_bottom_ctx.globalCompositeOperation = 'destination-out';
//   right_bottom_ctx.drawImage(product_canvas, -strokeWidth, -strokeWidth);
//   const border_canvas = createCanvasElement({
//     width: canvas_size,
//     height: canvas_size,
//   });
//   const ctx_border = border_canvas.getContext('2d');
//   ctx_border.drawImage(top_left_canvas, 0, 0);
//   ctx_border.drawImage(right_bottom_canvas, 0, 0);
//   return border_canvas;
// }
//
// function makeProductImgByPrintArea({ top, left, width, height }) {
//   const size = G.mp.maker.CANVAS_WIDTH_ORIGIN;
//   const canvas = createCanvasElement({
//     width: size,
//     height: size,
//   });
//   const ctx = canvas.getContext('2d');
//   ctx.fillStyle = '#ffffff';
//   ctx.fillRect(left, top, width, height);
//   return canvas;
// }

const cmToPx = (cm, dpi) => Math.round((cm / 2.54) * dpi);
const makeRectCanvas = ({ width, height, color }) => {
  const canvas = createCanvasElement({ width, height });
  const ctx = canvas.getContext('2d');
  if (color) {
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, width, height);
  }
  return canvas;
};
const drawBorder = ({ canvas, width, height, line_width, line_color }) => {
  const ctx = canvas.getContext('2d');
  ctx.strokeStyle = line_color;
  ctx.lineWidth = line_width;
  ctx.strokeRect(0, 0, width, height);
  return canvas;
};
function drawGrid({ canvas, width, line_width, line_color }) {
  const ctx = canvas.getContext('2d');
  ctx.strokeStyle = line_color;
  ctx.lineWidth = line_width;
  for (let y = 0; y < canvas.height; ) {
    for (let x = 0; x < canvas.width; ) {
      ctx.strokeRect(x, y, width, width);
      x += width;
    }
    y += width;
  }
  return canvas;
}

function drawCross({ canvas, line_width, line_color }) {
  const grid_ctx = canvas.getContext('2d');
  grid_ctx.strokeStyle = line_color;
  grid_ctx.lineWidth = line_width;
  grid_ctx.beginPath(); // Start a new path
  grid_ctx.moveTo(canvas.width / 2, 0); // Move the pen to (30, 50)
  grid_ctx.lineTo(canvas.width / 2, canvas.height); // Draw a line to (150, 100)
  grid_ctx.stroke();

  grid_ctx.moveTo(0, canvas.height / 2); // Move the pen to (30, 50)
  grid_ctx.lineTo(canvas.width, canvas.height / 2); // Draw a line to (150, 100)
  grid_ctx.stroke();
  return canvas;
}
export async function makeRedLineMockup({ sf, width }) {
  const dpi = 300;
  const line_width = cmToPx(0.1, dpi);
  const grid_size = cmToPx(1, dpi);
  const ratio = width ? width / cmToPx(sf.print.cm.width, dpi) : 1;
  const rect_width = cmToPx(sf.print.cm.width, dpi) * ratio;
  const rect_height = cmToPx(sf.print.cm.height, dpi) * ratio;
  const canvas = makeRectCanvas({
    width: rect_width,
    height: rect_height,
    color: '#ffffff',
  });
  drawGrid({ canvas, width: grid_size, line_width, line_color: '#000000' });
  drawBorder({ canvas, width: rect_width, height: rect_height, line_width, line_color: '#ff0000' });
  drawCross({ canvas, line_width, line_color: '#ff0000' });
  return canvas;
}
export async function makeBlueMockup({ sf }) {
  const dpi = 300;
  const rect_width = cmToPx(sf.print.cm.width, dpi);
  const rect_height = cmToPx(sf.print.cm.height, dpi);
  const canvas = makeRectCanvas({
    width: rect_width,
    height: rect_height,
    color: '#0a0b5f',
  });
  return canvas;
}

export const makeCylinderDesignCanvas = async (assoc_composite_template) => {
  let result_canvas;
  await go(
    assoc_composite_template._.composite_masks,
    each((cm) =>
      go(
        cm._.composite_faces,
        each(async (cf) => {
          const print_area_canvas = await go(cf.mockup_url, makeCanvasByUrl);
          const base_product_size_id = await go(
            $.get('/@api/prerequisite_maker/thumb_base_product_size', {
              base_product_id: assoc_composite_template._.composite_masks[0].base_product_id,
            }),
            sel('id'),
          );
          const size_face = await go(
            $.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
              id: assoc_composite_template._.composite_masks[0]._.composite_faces[0].base_product_face_id,
            }),
            sel('size_faces'),
            find((sf) => sf.base_product_size_id === base_product_size_id),
          );
          const canvas = await makeWarpCylinder(
            {
              size_face,
              print_area_canvas,
              canvas_width: assoc_composite_template.width,
              bg_url: assoc_composite_template.bg_url,
              mask_url: assoc_composite_template._.composite_masks[0].mask_transparent_url,
            },
            cf.etc_meta,
          );
          if (result_canvas) {
            const ctx = result_canvas.getContext('2d');
            ctx.drawImage(canvas, 0, 0);
          } else {
            result_canvas = canvas;
          }
        }),
      ),
    ),
  );
  return result_canvas;
};

export const makeDesignReadyUrlAfterSetMockupUrl = async (assoc_composite_template) => {
  const is_cylinder =
    assoc_composite_template._.composite_masks[0]._.composite_faces[0]?.etc_meta?.is_cylinder;
  if (is_cylinder) {
    const result_canvas = await makeCylinderDesignCanvas(assoc_composite_template);
    return go(
      $.uploadFileToOnlyOriginalUrl({
        original_name: 'design_ready_url',
        image_type: 'PNG',
        url: result_canvas.toDataURL('image/png', 1),
      }),
      sel('url'),
    );
  } else {
    return go(
      axios.post('/@fileupload/composite/create_design_ready_url', {
        assoc_composite_template,
      }),
      sel('data'),
    );
  }
};

export const setTestDesignReadyUrl = async (assoc_composite_template) => {
  await go(
    assoc_composite_template._.composite_masks,
    each((cm) =>
      go(
        cm._.composite_faces,
        each(async (cf) => {
          cf.mockup_url = await makeTestMockupUrl(cf.base_product_face_id);
        }),
      ),
    ),
  );
  assoc_composite_template._.composite_result.test_design_ready_url =
    await makeDesignReadyUrlAfterSetMockupUrl(assoc_composite_template);
  const { id, test_design_ready_url } = assoc_composite_template._.composite_result;
  await axios.post('/@api/composite/update_composite_result', {
    id,
    composite_template_id: assoc_composite_template.id,
    test_design_ready_url,
  });
  return test_design_ready_url;
};

export const makeTestMockupUrl = async (base_product_face_id, is_force, width) => {
  const {
    data: { product_img_type, sf, mask_url, mask_url2, safe_area_url },
  } = await axios.get('/@api/composite/get_sf_and_product_url', {
    params: {
      base_product_face_id,
    },
  });
  if (sf.items && sf.items[0]?.print_item_url) return sf?.items[0]?.print_item_url;
  if (!is_force && sf.mockup_url) return sf.mockup_url;
  return go(
    makeRedLineMockup({
      product_img_type,
      sf,
      mask_url,
      mask_url2,
      safe_area_url,
      width,
    }),
    (canvas) =>
      $.uploadFileToOnlyOriginalUrl({
        url: canvas.toDataURL(),
        image_type: 'PNG',
        original_name: 'CfTestDesignReadyUrl',
      }),
    sel('url'),
  );
};
