import { html } from 'fxjs/es';

export const menu = html`
  <div class="maker_menu">
    <div class="done">
      <span>${T('Done')}</span>
    </div>
    <div class="list">
      <div class="reset">
        <span></span>
        <div class="text">${T('Revert')}</div>
      </div>
      <div class="undo">
        <span></span>
        <div class="text">${T('Undo')}</div>
      </div>
      <div class="redo">
        <span></span>
        <div class="text">${T('Redo')}</div>
      </div>
      <div class="trash">
        <span></span>
        <div class="text">${T('Delete')}</div>
      </div>
      <div class="forward">
        <span></span>
        <div class="text">${T('Forward')}</div>
      </div>
      <div class="backward">
        <span></span>
        <div class="text">${T('Backward')}</div>
      </div>
      <div class="group">
        <span></span>
        <div class="text">${T('Group')}</div>
      </div>
      <div class="ungroup">
        <span></span>
        <div class="text">${T('Ungroup')}</div>
      </div>
      <div class="lock">
        <span></span>
      </div>
      <div class="unlock">
        <span><i></i></span>
      </div>
      <div class="flip_h">
        <span></span>
        <div class="text">${T('Horizontal')}</div>
      </div>
      <div class="flip_v">
        <span></span>
        <div class="text">${T('Vertical')}</div>
      </div>
      <div class="group_left">
        <span></span>
        <div class="text">${T('Left')}</div>
      </div>
      <div class="group_center_h">
        <span></span>
        <div class="text">${T('Center')}</div>
      </div>
      <div class="group_right">
        <span></span>
        <div class="text">${T('Right')}</div>
      </div>
      <div class="group_top">
        <span></span>
        <div class="text">${T('Top')}</div>
      </div>
      <div class="group_center_v">
        <span></span>
        <div class="text">${T('Middle')}</div>
      </div>
      <div class="group_bottom">
        <span></span>
        <div class="text">${T('Bottom')}</div>
      </div>
      <div class="more_btn btn" ns="down_list7">
        <span></span>
      </div>
    </div>
    <div class="menu_down_list down_list7">
      <div class="reset">
        <span></span>
        <div class="text">${T('Revert')}</div>
      </div>
      <div class="undo">
        <span></span>
        <div class="text">${T('Undo')}</div>
      </div>
      <div class="redo">
        <span></span>
        <div class="text">${T('Redo')}</div>
      </div>
      <div class="trash">
        <span></span>
        <div class="text">${T('Delete')}</div>
      </div>
      <div class="forward">
        <span></span>
        <div class="text">${T('Forward')}</div>
      </div>
      <div class="backward">
        <span></span>
        <div class="text">${T('Backward')}</div>
      </div>
      <div class="group">
        <span></span>
        <div class="text">${T('Group')}</div>
      </div>
      <div class="ungroup">
        <span></span>
        <div class="text">${T('Ungroup')}</div>
      </div>
      <div class="lock">
        <span></span>
        <div class="text">${T('Lock')}</div>
      </div>
      <div class="unlock">
        <span><i></i></span>
        <div class="text">${T('Unlock')}</div>
      </div>
      <div class="flip_h">
        <span></span>
        <div class="text">${T('Horizontal')}</div>
      </div>
      <div class="flip_v">
        <span></span>
        <div class="text">${T('Vertical')}</div>
      </div>
      <div class="group_left">
        <span></span>
        <div class="text">${T('Left')}</div>
      </div>
      <div class="group_center_h">
        <span></span>
        <div class="text">${T('Center')}</div>
      </div>
      <div class="group_right">
        <span></span>
        <div class="text">${T('Right')}</div>
      </div>
      <div class="group_top">
        <span></span>
        <div class="text">${T('Top')}</div>
      </div>
      <div class="group_center_v">
        <span></span>
        <div class="text">${T('Middle')}</div>
      </div>
      <div class="group_bottom">
        <span></span>
        <div class="text">${T('Bottom')}</div>
      </div>
    </div>
  </div>
`;
