import { loadImageFromUrl } from '../../../../../Canvas/S/util.js';
import { getColorRemovedImageSrc } from '../private/filters/colorRemoval.js';
import { getBlurBinaryImageSrc } from '../private/filters/blurBinary.js';

/*
 * @param uri Glue layer 제작을 위한 원본 디자인 이미지 리소스
 * @param removalColor 디자인 이미지에서 제거하고자 하는 컬러 색상 값. normalized rgb vector (ex. [1.0, 1.0, 1.0] for white)
 * @param colorDistancePercent 제거하고 싶은 removalColor 로부터의 포함시킬 색상 거리 (%) (ex. 1.5)
 * @param offsetPixel Glue layer 의 디자인 바깥으로의 offset pixel (ex. 10.0)
 * @param color Glue layer 로 표현될 색상 지정 vec4 값 (ex. [1.0, 0.0, 0.5, 1.0] for pink )
 * */
export async function getGlueLayer({ uri, removalColor, colorDistancePercent, offsetPixel, color }) {
  const src_image = await loadImageFromUrl(uri);

  const color_removed_image_uri = await getColorRemovedImageSrc({
    image: src_image,
    targetColor: removalColor,
    colorDistancePercent,
  });

  const glue_image_uri = await getBlurBinaryImageSrc({
    image: await loadImageFromUrl(color_removed_image_uri),
    offsetPixel,
    color,
  });

  return glue_image_uri;
}

export async function getOutlineLayer({ uri, offsetPixel, color }) {
  const glue_image_uri = await getBlurBinaryImageSrc({
    image: await loadImageFromUrl(uri),
    offsetPixel,
    color,
  });

  return glue_image_uri;
}
