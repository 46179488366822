import Decimal from 'decimal.js';
import { reduce, curry } from 'fxjs/es';

export const countDecimal = (n) => n.toString().split('.')[1]?.length || 0;

// Big Number 에 대한 고려는 하지 않았음 Number.MAX_SAFE_INTEGER
// export const addDecimal = curry((...nums) => {
//   const largest_decimal = go(nums, map(countDecimal), apply(Math.max));
//
//   return go(
//     nums,
//     map((n) => n * 10 ** largest_decimal),
//     reduce(add),
//     (n) => n / 10 ** largest_decimal,
//   );
// });

const makeDecimalCalc = (method) =>
  curry((a, ...args) => {
    try {
      const d_a = new Decimal(a || 0);
      return reduce((acc, arg) => acc[method](arg), d_a, args).toNumber();
    } catch (err) {
      console.error(`${method}: ${a}, ${args}`);
      throw err;
    }
  });

export const addDecimal = makeDecimalCalc('plus');

export const subDecimal = makeDecimalCalc('minus');

export const mulDecimal = makeDecimalCalc('mul');

export const divDecimal = makeDecimalCalc('div');
