import { $attr } from 'fxdom/es';
import { find, go, map, reject, split } from 'fxjs/es';
import { ConfigSentryF } from '../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { OMPAuthF } from '../../../../Auth/F/Function/module/OMPAuthF.js';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPReviewWriteConstantS } from '../../S/Constant/module/OMPReviewWriteConstantS.js';

export const validFiles = ($input) => {
  const accepts = go($input, $attr('accept'), split(','), map(OMPCoreUtilS.toLower));

  const invalid = go(
    $input.files,
    find((file) => {
      const extension = (file.name.match(/\.[^/.]+$/) || [''])[0].toLowerCase();
      return accepts.indexOf(file.type) === -1 && accepts.indexOf(extension) === -1;
    }),
  );

  if (invalid) {
    $input.files = reject((file) => file.lastModified === invalid.lastModified, $input.files);
    throw new OMPReviewWriteConstantS.MyPageReviewWriteError(TT('error::review::write_01'));
  }
};

export const catchError = async (e) => {
  // MEMO: axios 에러
  if (e?.response?.data?.message) {
    await OMPCoreUtilF.alert({
      content: e?.response?.data?.message || TT('error::common::error_01'),
    });

    if (e.status === 401) {
      return await OMPAuthF.openSignUp();
    }

    return;
  }

  // MEMO: 프론트 에러
  if (e?.handle) {
    await OMPCoreUtilF.alert({
      content: e?.message || TT('error::common::error_01'),
    });
    return;
  }

  OMPCoreUtilF.alert({
    content: TT('error::common::error_01'),
  });
  e.name = '리뷰 쓰기 에러';
  ConfigSentryF.error(e);
};
