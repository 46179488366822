window.criteo_q = window.criteo_q || [];
window.criteo_deviceType = /iPad/.test(navigator.userAgent)
  ? 't'
  : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
  ? 'm'
  : 'd';

export const eventSet = (event) => {
  return [
    { event: 'setAccount', account: 82867 },
    { event: 'setEmail', email: box.sel('is_user->email') || '' },
    { event: 'setSiteType', type: window.criteo_deviceType },
    event,
  ];
};
