import { html } from 'fxjs/es';

export const enFirst = () => html`
  <div class="terms_body">
    <div class="title">
      <h1>Terms &amp; Conditions</h1>
    </div>
    <div class="body">
      <div class="text_body">
        <div class="paragraph">
          <h3>Visitor Agreement</h3>
          <p>
            Any person or entity (“User”) visiting, using or otherwise accessing the site at marpple.com
            (“Site”) or any information contained in the site agrees to and is bound by the following terms
            and conditions of this visitor agreement (“Agreement”). You understand that by using the Site and
            the Service, you have agreed to the terms and conditions of this Agreement and you agree to use
            the Site and the Service solely as provided in this Agreement.
          </p>
          <p>Intellectual Property Rights of Marpple.com</p>
          <p>
            Marpple.com is committed to the appropriate and legal use of the intellectual property of others,
            and we require our users to behave similarly. Unless otherwise noted, all materials, including
            images, illustrations, designs, icons, photographs and written and other materials that appear on
            the Marpple.com Site (collectively the "Contents") are copyrights, trademarks, trade dress and/or
            other intellectual properties owned, controlled or licensed by Marpple.com.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Eligibility</h3>
          <p>
            Our services are available only to, and may only be used by, individuals who can form legally
            binding contracts under applicable law. Our services are not available to persons under 16 years
            of age.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Use of Site</h3>
          <p>
            Generally, products created using images available on the site should not be resold commercially.
            In the event that you wish to resell such products, you agree to contact Marpple.com and verify
            the legality of reselling such products prior to doing so. Designs created using the text tools
            and images available through the site are in no way the exclusive property of the customers who
            assemble such designs. Marpple.com retains the right to display such designs or offer them (or
            variations of such designs) to other customers.
          </p>
          <p>
            Additionally, Marpple.com has the sole discretion to reject any order that it considers libelous,
            defamatory, obscene, profane (according to standards established by the FCC), portraying
            irresponsible use of alcohol or other substances, advocating persecution based on gender, age,
            race, religion, disability or national origin, containing explicit sexual content or is otherwise
            inappropriate for Marpple.com production.
          </p>
          <p>
            You agree not to use Marpple.com's service to create any material that is unlawful, harmful,
            threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of
            another's privacy, hateful, or racially, ethnically or otherwise objectionable, or that infringes
            on any patent, trademark, trade secret, copyright or other proprietary rights of any third party.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Featured Links</h3>
          <p>
            The Site displays and/or refers to links to other websites from time to time. Marpple: (i) does
            not endorse or take responsibility for the content of such websites, (ii) is not responsible for
            the availability of such websites; and (iii) will not be liable in any way for any loss or damage
            which you may suffer by using such websites. If you decide to access linked websites, you do so at
            your own risk.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Links to Us</h3>
          <p>
            Any other website may link to our Site, provided it does not imply any endorsement of its products
            or services by Marpple, does not misrepresent its relationship with or present false information
            about Marpple, does not infringe any intellectual property or other right of any person and
            complies with all relevant laws and regulations. Please note, however, that Marpple reserves the
            right to withdraw such permission at any time and to take any other appropriate action.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Putting content on the Marpple.com</h3>
          <p>
            You keep the copyright in any content you submit or upload to the website. In order to receive the
            Marpple services you grant Marpple a non-exclusive royalty free license to use and archive the
            content in accordance with or as reasonably contemplated by this agreement.
          </p>
          <ul class="list_style">
            <li>
              You own all copyright in the content, or if you are not the owner, that you have permission to
              use the content, and that you have all of the rights required to display, reproduce and sell the
              content;
            </li>
            <li>
              The content you upload will not infringe the intellectual property rights or other rights of any
              person or entity, including copyright, moral rights, trade mark, patent or rights of privacy or
              publicity;
            </li>
            <li>Your use of the website will comply with all applicable law, rules and regulations;</li>
            <li>
              The content does not contain material that defames or vilifies any person, people, races,
              religion or religious group and is not obscene, pornographic, indecent, harassing, threatening,
              harmful, invasive of privacy or publicity rights, abusive, inflammatory or otherwise
              objectionable;
            </li>
            <li>
              The content does not include malicious code, including but not limited to viruses, trojan
              horses, worms, time bombs, cancelbots, or any other computer programming routines that may
              damage, interfere with, surreptitiously intercept, or expropriate any system, program, data, or
              personal information; and
            </li>
            <li>
              The content is not misleading and deceptive and does not offer or disseminate fraudulent goods,
              services, schemes, or promotions.
            </li>
          </ul>
          <p>
            Marpple reserves the right to review and if in its sole discretion deemed necessary, remove any
            content from the website and / or cancel your account, because that content breaches your
            agreement with us and / or any applicable laws, or otherwise. You agree to indemnify Marpple in
            respect of any direct or indirect damage caused due to your breach of one or more of these
            warranties.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Intellectual Property Rights and License</h3>
          <p>
            By submitting listings to Marpple.com, you grant Marpple a non-exclusive, worldwide, royalty-free,
            sublicense able and transferable license to use, reproduce, distribute, prepare derivative works
            of and display the content of such listings in connection with Marpple's (and its successors' and
            affiliates') services and business in facilitating the sale of your product, including without
            limitation for promoting and redistributing part or all of the Marpple.com site (and derivative
            works thereof) in any media formats and through any media channels. You also hereby grant each
            user of the Marpple.com site a non-exclusive license to access your content through the site, and
            to use, reproduce, distribute, and display such content as permitted through the functionality of
            the site and under these Terms & Conditions. The above licenses terminate within a commercially
            reasonable time after you remove or delete your listings from Marpple.com. The above licenses
            granted by you in user comments you submit, are perpetual and irrevocable.
          </p>
          <p>
            All intellectual property rights in this website and the Marpple service (including the software
            and systems underlying the Marpple service, and text, graphics, logos, icons, sound recordings and
            software) are owned by or licensed to us. Other than for the purposes of, and subject to the
            conditions prescribed under relevant Copyright and Trade Mark legislation throughout the world,
            and except as expressly authorized by this agreement, you may not in any form or by any means:
          </p>
          <ul>
            <li>
              use, adapt, reproduce, store, distribute, print, display, perform, publish or create derivative
              works from any part of this website; or
            </li>
            <li>
              commercialize any information, products or services obtained from any part of this website,
            </li>
          </ul>
          <p>without our written permission.</p>
          <p>
            If you use any of our trademarks in reference to our activities, products or services, you must
            include a statement attributing that trade mark to us. You must not use any of our trademarks in
            or as the whole or part of your own trademarks; in connection with activities, products or
            services which are not ours; in a manner which may be confusing, misleading or deceptive; or in a
            manner that disparages us or our information, products or services (including this website).
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Reporting inappropriate content to Marpple</h3>
          <p>
            Marpple does not manually screen content before it is displayed on the website so occasionally
            members may inadvertently or deliberately submit and display content that breaches this agreement.
          </p>
          <p>
            Inappropriate content includes, but is not limited to, content that infringes the copyright or
            other intellectual property rights of any person or company, or that defames or vilifies any
            person, people, races, religion or religious group, is obscene, pornographic, indecent, harassing,
            threatening, harmful, invasive of privacy or publicity rights, abusive, inflammatory or otherwise
            objectionable. Marpple reserves the right (but not the obligation) to remove or edit such content,
            but does not regularly review posted content.
          </p>
          <p>
            Please help us by letting us know straight away about any inappropriate, or potentially
            inappropriate, content you see on the website. If you believe your copyright or other intellectual
            property rights are being infringed, you are able to make a formal complaint to
            <a href="mailto:info@marpple.com">info@marpple.com</a>.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Shipping / Delivery Times</h3>
          <p>
            Marpple’s orders are generally delivered within 14 days. Customers who are concerned about meeting
            deadlines should contact a Service/Sales Representative. Delivery times may vary during the
            holiday seasons.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Returns Policy</h3>
          <p>
            Marpple.com goes to great lengths to ensure that all orders are filled accurately and on time and
            are of the highest quality. Accordingly, Marpple.com accepts returns and will refund payment if
            and when customers are disappointed by Marpple.com merchandise for one of the following reasons:
            (a) the blank items themselves are materially flawed; (b) the quality of the decorating (i.e., the
            printing or embroidery) is below a reasonable range of expectations; or (c) the design of the
            final product is materially different from the final design submitted by the customer.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Damaged Goods</h3>
          <p>
            If a product is delivered to a customer, whether in electronic or printed format, that is
            electronically or physically damaged in some way (for example, a misprinted, a framed print has
            cracked, or there are loose products) Marpple will happily contact the manufacturer or seller to
            issue a replacement copy of the product after receiving reasonable proof of that damage.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Cancellation Policy</h3>
          <p>You may change or cancel your order within twenty-four (24) hours of submitting your order.</p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Hacking</h3>
          <p>
            You agree not to attempt to damage, deny service to, hack, crack, reverse-engineer, or otherwise
            interfere (collectively, “Interfere”) with the Site in any manner. If you in any way interfere
            with the Site, you agree to pay or refund all damages incurred by Marpple. Marpple will cooperate
            with the authorities in prosecuting any User who interferes with the Site or otherwise attempts to
            defraud Marpple or any other parties through User’s use of the Site or services provided via the
            Site. Marpple reserves the right to deny any or all access or service to any User for any reason,
            at any time, at our sole discretion. User agrees that we may block User’s IP address or addresses
            at any time, and at our sole discretion to disallow User’s continued use of the Site without
            giving you any advance notice. We reserve the right to take any action we may deem appropriate in
            our sole discretion with respect to violations or enforcement of the terms of this Agreement, and
            we expressly reserve all rights and remedies available to us at law or in equity.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Re-targeting</h3>
          <p>
            Marpple offers users the ability (through the Marketing tab on the dashboard) to run campaigns
            re-targeting consumers based on data that has been collected through the Marpple platform. Marpple
            works with a third party vendor called Unite and its partner Google and AppNexus to collect and
            manage such data and deliver the targeted advertisements on unaffiliated websites around the
            Internet. By using the marketing and retargeting feature, you agree that in addition to these
            terms and conditions, your activities shall be governed by
            http://www.appnexus.com/en/company/platform-privacy-policy#choices–and that Marpple, Unite and/or
            AppNexus have the right to insert an opt-out mechanism on your advertisements.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Complaints</h3>
          <p>
            If you believe that you are the owner of the copyright or other rights in any material(s)
            appearing on Marpple, and/or that ownership of the copyright or other rights in any material has
            been incorrectly used or represented on Marpple, please contact us via
            <a href="mailto:info@marpple.com">info@marpple.com</a>.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Trade Marks</h3>
          <p>
            The brands, products and service names used in this Site (including without limitation, “Marpple”)
            are the trademarks or trade names of Marpple or it’s trading partners unless otherwise stated. You
            may not distribute products or offer services under or by reference to or otherwise use or
            reproduce any such trademarks, trade names or taglines without the prior written permission of
            Marpple.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Registration</h3>
          <p>
            If you register with the Site, you must: agree to the terms of the Registered User Agreement;
            agree to keep your password secure and confidential and not allow anyone else to use your email
            address or password to access the Site; agree not to do anything which would assist anyone who is
            not a registered User to gain access to any secured area of the Site; and agree not to create
            additional registration accounts for the purpose of abusing the functionality of the Site, or
            other Users, or to seek to pass yourself off as another User.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Limitation of Liability</h3>
          <p>
            User agrees that the liability of Marpple to User under this Agreement shall be limited to the
            greater of the amount User has actually paid to Marpple for its services and $100. Nothing in this
            Agreement shall operate to limit or exclude liability for death or personal injury caused by
            negligence of Marpple.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Indemnity</h3>
          <p>
            User agrees to indemnify Marpple for any loss or damage that may be incurred by Marpple, including
            without limitation legal fees, arising from User’s use of the Site or User’s use of any
            information obtained through the Site.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Privacy Policy</h3>
          <p>
            Your privacy is very important to us. Users of our website should refer to our privacy policy –
            which is incorporated into this agreement by reference, for information about how we collect and
            use personal information.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Security of Information</h3>
          <p>
            No data transmission over the Internet can be guaranteed as totally secure. We strive to protect
            such information, however we do not warrant and cannot ensure the security of any information that
            you transmit to us. Accordingly, any information that you transmit to us is transmitted at your
            own risk.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Termination of Access</h3>
          <p>
            Access to this website may be terminated at any time by us without notice. Our disclaimer will
            nevertheless survive any such termination.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Severability</h3>
          <p>
            If any part, term, or provision of this Agreement shall be held by a court of competent
            jurisdiction to be illegal, unenforceable, or in conflict with any relevant law, the remaining
            portions or provisions shall still remain valid and continue in full force and effect.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Changes to this Agreement</h3>
          <p>
            This Agreement and/or any other terms of service may be changed without prior notice, and any such
            amendment will be applicable to all Users from the date and time such revised terms have been
            posted on the Site. User’s continued use of the Site or services constitutes agreement with and
            acceptance of any such amendment or other changes.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Indemnity</h3>
          <p>
            You agree to defend, indemnify and hold Marpple.com and its affiliates harmless from and against
            any and all claims, damages, costs and expenses, including reasonable attorneys' fees, arising
            from or relating to your use of Marpple.com's Site and the Service, your violation of this
            Agreement, or your violation of any rights of another.
          </p>
        </div>
        <hr />
        <div class="paragraph">
          <h3>Governing Law</h3>
          <p>
            This Agreement shall be governed by and will be construed in accordance with the laws of South
            Korea. Any disputes arising under or in connection with this Agreement shall be subject to the
            exclusive jurisdiction of the Korean Courts.
          </p>
        </div>
      </div>
    </div>
    <div class="option"><button class="done">Done</button></div>
  </div>
`;
