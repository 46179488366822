import { NewMakerPropertyBpfConstantS } from '../../S/Constant/module/NewMakerPropertyBpfConstantS.js';
import { find, go, sel, some } from 'fxjs/es';
import { resetCvMaskBySf } from '../../../../../Maker/F/SizeFaces/fs.js';
import { getCvObj } from '../../../../../Maker/F/Fcanvas/cv_object.js';

export const bpOptionLayers = {
  cv_type: 'cv_bp_option_layer',
  hasBpOptionLayers: (bpfs) => {
    return go(bpfs, some(bpOptionLayers.getBpOptionLayers));
  },
  getBpOptionLayers: (bpf) => {
    const bp_option_layers = bpf?.preview?.[NewMakerPropertyBpfConstantS.BP_OPTION_LAYERS];
    return bp_option_layers?.length ? bp_option_layers : null;
  },
  setBpf: ({ bpf, selected_bp_option_ids }) => {
    const bp_option_layers = bpOptionLayers.getBpOptionLayers(bpf);
    if (bp_option_layers && selected_bp_option_ids?.length) {
      const image_url = go(
        bp_option_layers,
        find((col) => selected_bp_option_ids.includes(parseInt(col.bp_option_id))),
        sel('image_url'),
      );
      if (image_url) {
        bpf[bpOptionLayers.cv_type] = image_url;
      } else {
        delete bpf[bpOptionLayers.cv_type];
      }
    } else {
      delete bpf[bpOptionLayers.cv_type];
    }
  },
  addOrDeleteCvObj: (canvas, image_url, sf) => {
    const last_cv_obj = getCvObj(canvas._objects, bpOptionLayers.cv_type);
    if (last_cv_obj) canvas.remove(last_cv_obj);
    if (!image_url) return;
    return new Promise(function (resolve) {
      fabric.Image.fromURL(G.mp.maker.url(image_url), function (img) {
        img.set({
          visible: !!image_url,
          selectable: false,
          evented: false,
          _data: { cv_type: bpOptionLayers.cv_type, is_not_design: true },
        });
        canvas.add(img);
        resetCvMaskBySf(img, sf);
        canvas.renderAll();
        resolve();
      });
    });
  },
};
