import { UtilImageS } from '../../../../Util/Image/S/Function/module/UtilImageS.js';
/*
 * 기본 jpeg 로 변경해주는 함수
 * bg 는 람다안에서 jpeg 면 기본으로 #ffffff
 * */

export const getBase64OrResize = ({ url = '', ...opt }) =>
  url.indexOf('data:image') > -1
    ? url
    : UtilImageS.getResizedUrl({
        url,
        ...opt,
      });

export const setOnDemandJpegUrl = (opt) =>
  getBase64OrResize({
    bg: 'ffffff',
    ...opt,
    format: 'jpeg',
  });
