const __hpt_info = { _account: 'UHPT-28853', _server: 'a28' };

export const initMp = () => {
  try {
    window.hpt_info = __hpt_info;

    (function () {
      const ta = document.createElement('script');
      ta.type = 'text/javascript';
      ta.async = true;
      ta.src = '//cdn.smlog.co.kr/core/smart.js';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ta, s);
    })();
  } catch (e) {}
};

export const purchaseMp = ({ user_id, price }) => {
  try {
    window.hpt_info = __hpt_info;
    window.hpt_trace_info = { _mode: 'order', _memid: user_id, _total_price: price };

    (function () {
      const ta = document.createElement('script');
      ta.type = 'text/javascript';
      ta.async = true;
      ta.src = '//cdn.smlog.co.kr/core/smart.js';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ta, s);
    })();
  } catch (e) {}
};

export const joinMp = ({ uesr_id }) => {
  try {
    window.hpt_info = __hpt_info;
    window.hpt_trace_info = { _mode: 'join', _memid: uesr_id };

    (function () {
      const ta = document.createElement('script');
      ta.type = 'text/javascript';
      ta.async = true;
      ta.src = '//cdn.smlog.co.kr/core/smart.js';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ta, s);
    })();
  } catch (e) {}
};

export const questionMp = ({ uesr_id }) => {
  try {
    window.hpt_info = __hpt_info;
    window.hpt_trace_info = { _mode: 'q', _memid: uesr_id };

    (function () {
      const ta = document.createElement('script');
      ta.type = 'text/javascript';
      ta.async = true;
      ta.src = '//cdn.smlog.co.kr/core/smart.js';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ta, s);
    })();
  } catch (e) {}
};
