$.frame.defn_frame([
  {
    frame_name: 'mp.login',
    page_name: 'mp_login_page',
    el_class: 'mp_login_frame',
    title: 'Login',
    always_remove: true,
    hide_frame_button_type: 'X',
    showing: function () {
      $.add_class($1('body'), 'body_scroll');
      $.add_class($1('html'), 'login_mode');
    },
    closing: function () {
      $.remove_class($1('body'), 'body_scroll');
      $.remove_class($1('html'), 'login_mode');
    },
  },
]);

$.frame.defn_frame([
  {
    frame_name: 'mp.reset_password_frame',
    page_name: 'mp.reset_password_page',
    el_class: 'reset_password_frame',
    title: 'Reset Password',
    always_remove: true,
    is_modal: G.is_pc_size(),
    hide_frame_button_type: 'X',
  },
]);

$.frame.defn_frame({
  frame_name: 'mp.id_pw_find_frame',
  page_name: 'mp.id_pw_find_page',
  el_class: 'mp_id_pw_find_frame',
  always_remove: true,
  is_modal: G.is_pc_size(),
  hide_frame_button_type: 'X',
});

$.frame.defn_frame({
  frame_name: 'mp.email_verify_frame',
  page_name: 'mp.email_verify_page',
  el_class: 'mp_email_verify_frame',
  title: T.lang == 'kr' ? '이메일 인증하기' : 'Email verification',
  always_remove: true,
  header_height: 80,
  is_modal: G.is_pc_size(),
  hide_frame_button_type: 'X',
});
