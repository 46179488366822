import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';
import Swal from 'sweetalert2';
import { DfInhouseF } from './module/DfInhouseF.js';
import { each, filter, go, html, map, strMap, object, pluck } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { $delegate, $qsa } from 'fxdom/es';
import $parseDataStr from 'fxdom/es/parseDataStr.js';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';

export const alert = {
  prompt: {
    confirmBucketCompletion: async ({ bucket }) => {
      const waybill_no = bucket.waybill_no;
      const {
        crew_inhouse_shipping: { company_address, location_name },
        inhouse_shipping_boxes,
      } = bucket._;

      const is_empty_box = inhouse_shipping_boxes.length === 0;

      const { isConfirmed: isCompleteConfirmed } = await Swal.fire({
        title: '완료 요청 의사 재확인',
        icon: 'warning',
        width: '680',
        html: `<b><span style="font-size:1.5rem">${location_name}<br>${company_address}<br>${UtilStringS.insertHyphens(
          {
            input_str: waybill_no,
            chunk_size: 4,
          },
        )}<br>
        ${
          is_empty_box
            ? `<span>주문이 안 담긴 빈 박스입니다. 그래도 완료 처리 하시겠습니까?</span></b>`
            : `총 ${inhouse_shipping_boxes.length} 개 주문 박스 담김</b></span><br><br>버킷 포장을 완료하시겠습니까?<br><br>포장 완료 후에는 되돌릴 수 없습니다.<br>(주문 고객 배송 문자 발송됨)`
        }
        
        `,
        backdrop: true,
        showCancelButton: true,
        cancelButtonText: '취소하기',
        confirmButtonText: '포장 완료 요청',
      });
      return { isCompleteConfirmed };
    },
    confirmBucketDeletion: async ({ box_ctn }) => {
      const { isConfirmed: isDeleteConfirmed } = await Swal.fire({
        title: '삭제 경고',
        icon: 'warning',
        width: '680',
        html: `<p><b>버킷에 담긴 주문 박스가 <span style="color:red;">${box_ctn} 개</span> 존재합니다.</b><br><br>삭제 후에는 버킷 운송장을 재사용 할 수 없습니다.<br>버킷에 담긴 박스들은 모두 초기화 되므로 새로운 버킷에 담아야 합니다.<br><br>그래도 버킷을 삭제하시겠습니까?</p>`,
        backdrop: true,
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '삭제',
      });
      return { isDeleteConfirmed };
    },
    selectCrewInhouse: async ({ crew_inhouses }) => {
      const { isConfirmed: is_selected_crew_inhouse, value: selected_crew_inhouse_idx } = await Swal.fire({
        title: '사내 배송 회사 선택',
        input: 'radio',
        inputOptions: DfInhouseF.getInhouseCrewOptions({ inhouse_shippings: crew_inhouses }),
        inputValue: '0',
        backdrop: true,
        showCancelButton: true,
        cancelButtonText: '닫기',
        confirmButtonText: '선택',
        customClass: 'inhouse_company_radios',
        inputValidator: (value) => {
          if (!value) {
            return '회사명을 선택해 주세요.';
          }
        },
      });

      return { is_selected_crew_inhouse, selected_crew_inhouse: crew_inhouses[selected_crew_inhouse_idx] };
    },
    selectCrewInhouseShippings: async ({ crew_inhouse_shippings }) => {
      const { isConfirmed: is_selected_crew_inhouse_shippings, value: selected_crew_inhouse_shippings } =
        await Swal.fire({
          title: '운송장 주소 선택',
          width: 900,
          html: html`<div id="inhouse-address-list-container">
            <div class="header">
              <input type="checkbox" class="check-all" />
              <div class="company-location-name">회사명</div>
              <div class="company-address">주소지</div>
            </div>
            ${strMap((shipping) => {
              const { location_name, company_address } = shipping;
              return html`
                <label>
                  <input
                    type="checkbox"
                    class="crew_inhouse_shipping_checkbox"
                    data-shipping="${$dataStr(shipping)}"
                  />
                  <div class="company-location-name">${location_name}</div>
                  <div class="company-address">${company_address}</div>
                </label>
              `;
            }, crew_inhouse_shippings)}
          </div>`,
          confirmButtonText: '출력',
          cancelButtonText: '취소',
          showCancelButton: true,
          didOpen: (el) => {
            go(
              el,
              $delegate('click', 'input.check-all', (e) => {
                const ct = e.currentTarget;
                go(
                  $qsa('input.crew_inhouse_shipping_checkbox'),
                  each((checkbox) => (checkbox.checked = ct.checked)),
                );
              }),
            );
          },
          preConfirm: () => {
            const checked_items = filter(
              (checkbox) => checkbox.checked,
              $qsa('.crew_inhouse_shipping_checkbox'),
            );
            if (checked_items.length === 0) {
              return Swal.showValidationMessage(`주소지를 선택해 주세요!`);
            }
            return map((checked_el) => $parseDataStr(checked_el.dataset.shipping), checked_items);
          },
        });
      return { is_selected_crew_inhouse_shippings, selected_crew_inhouse_shippings };
    },
    changeShippingAddressConfirm: async () => {
      DfInhouseF.lottie_loader.end();
      const { isConfirmed } = await Swal.fire({
        title: '이미 현재 주소로 라벨링 완료됨',
        icon: 'warning',
        html: '<span>기존 주소로 라벨링 된 주문 포장 박스가 있습니다.<br>그래도 사내배송 주소지를 변경하시겠습니까?</span>',
        width: 680,
        showCancelButton: true,
        cancelButtonText: '닫기',
        confirmButtonText: '주소 변경하기',
        backdrop: true,
      });
      return { is_confirmed_address_change: isConfirmed };
    },
    changeShippingAddress: async ({ input_options }) => {
      DfInhouseF.lottie_loader.end();
      const { isConfirmed, value: selected_crew_inhouse_shipping_id } = await Swal.fire({
        title: '사내 배송지 변경',
        input: 'select',
        inputOptions: input_options,
        inputPlaceholder: '배송지 선택',
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '완료',
        inputValidator: (value) => {
          if (value === '') {
            return Promise.resolve('배송지를 선택해 주세요.');
          }
        },
      });
      return { isConfirmed, selected_crew_inhouse_shipping_id };
    },
    switchingShippingWay: async ({ html }) => {
      DfInhouseF.lottie_loader.end();
      const { isConfirmed } = await Swal.fire({
        title: '배송 전환 의사 재확인',
        icon: 'warning',
        html,
        width: 500,
        showCancelButton: true,
        cancelButtonText: '취소',
        confirmButtonText: '전환하기',
        backdrop: true,
      });
      return { isConfirmed };
    },
    reprintOrCancel: async () => {
      DfInhouseF.lottie_loader.end();
      const { isConfirmed, isDenied } = await Swal.fire({
        title: '이미 배송 박스 라벨링 처리됨',
        icon: 'warning',
        html: '<span>라벨링 된 기존 주문 포장 박스가 있습니다.<br>중복해서 박스를 생성하시려는 것은 아닌지 확인해 주세요.<br><br>그래도 라벨을 재출력 하시겠습니까?</span>',
        width: 680,
        showCancelButton: true,
        cancelButtonText: '닫기',
        confirmButtonText: '라벨 재출력',
        backdrop: true,
      });
      return { is_reprint: isConfirmed, isCancel: isDenied };
    },
  },
  success: async ({ title, msg, timer, icon_override }) => {
    DfInhouseF.lottie_loader.end();
    return await Swal.fire({
      title,
      icon: icon_override ?? 'success',
      width: 600,
      html: msg ? `<p>${msg}</p>` : undefined,
      timerProgressBar: true,
      timer,
      backdrop: true,
    });
  },
  warning: async ({ msg, title = '경고', image }) => {
    DfInhouseF.lottie_loader.end();
    await warningAlert({ title, msg, image });
  },
  error: async ({ err, title = '확인 필요', image, additional_msg }) => {
    DfInhouseF.lottie_loader.end();
    if (err?.response) {
      // 서버 응답 상태 코드가 2xx 범위를 벗어난 경우
      const data = err.response.data;
      console.error('서버 응답 에러:', err.response.status, data);
      await errorAlert({ title, data, err, image, additional_msg });
    } else if (err?.request) {
      // 서버로 요청이 전송되지 않은 경우 (네트워크 문제 등)
      console.error('요청 전송 에러:', err.request);
      await errorAlert({ title, data: '서버 응답 없음', err, image, additional_msg });
    } else {
      // 다른 에러 (예를 들어 요청을 만들 때 발생한 에러)
      console.error('일반 에러:', err?.message);
      await errorAlert({ title, data: err?.message ?? '', err, image, additional_msg });
    }
  },
  normal: async ({ title, html }) => {
    DfInhouseF.lottie_loader.end();
    return await Swal.fire({
      title,
      html,
      backdrop: true,
    });
  },
};

async function errorAlert({ title, data, err, image, additional_msg }) {
  await Swal.fire({
    title,
    html: `<span>${parseErrorMessage(data)}${additional_msg ? `<br><br>${additional_msg}` : ''}</span>`,
    width: 500,
    imageUrl: image ?? err?.image ?? '',
    imageHeight: 300,
    icon: 'error',
    backdrop: true,
    timerProgressBar: true,
    timer: 10000,
  });
}

async function warningAlert({ title, msg, image }) {
  await Swal.fire({
    title,
    html: `<span>${msg}</span>`,
    width: 500,
    imageUrl: image ?? '',
    imageHeight: 300,
    icon: 'warning',
    backdrop: true,
    timerProgressBar: true,
    timer: 6000,
  });
}

export function parseErrorMessage(data) {
  if (typeof data === 'string') return data;

  if (UtilObjS.isNotEmpty(data)) {
    if (typeof data.message === 'string') return data.message;
  } else {
    return '알수 없는 에러';
  }
}

export function getInhouseCrewOptions({ inhouse_shippings }) {
  return go(inhouse_shippings, pluck('crew_company_name'), zipWithIndex, object);
}
