import { EditorNS } from '@marpple/sticker-editor';
import { $findAll, $off, $on } from 'fxdom/es';
import { each, eachL, go, takeAll, takeL, tap } from 'fxjs/es';
import { VectorEditorStickerFreeMobileMuiS } from '../../S/Mui/module/VectorEditorStickerFreeMobileMuiS.js';
import { VectorEditorStickerFreeMobileEventF } from '../Event/module/VectorEditorStickerFreeMobileEventF.js';
import { VectorEditorStickerFreeMobileF } from '../Function/module/VectorEditorStickerFreeMobileF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerFreeMobileMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {
    tab_el$.__mp_single_art_board_size = {
      width: tab_el$?.__mp_single_art_board_size?.width ?? 0,
      height: tab_el$?.__mp_single_art_board_size?.height ?? 0,
    };

    tab_el$.__mp_handleWindowMouseMove = null;
    tab_el$.__mp_is_prevent_mouse_move = false;

    go(
      tab_el$,

      tap(
        $findAll(`.header button.cancel`),
        each($on('click', VectorEditorStickerFreeMobileEventF.handleHeaderCancelButtonClick())),
      ),
      tap(
        $findAll(`.header button.done`),
        each(
          $on('click', VectorEditorStickerFreeMobileEventF.handleHeaderDoneButtonClick({ tab_el: tab_el$ })),
        ),
      ),

      tap(
        $findAll(`.footer.none[data-icon_type="set_background"]`),
        each(
          $on(
            'click',
            VectorEditorStickerFreeMobileEventF.handleFooterNoneSetBackgroundIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.none[data-icon_type="make_sticker"]`),
        each(
          $on(
            'click',
            VectorEditorStickerFreeMobileEventF.handleFooterNoneMakeStickerIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),

      tap(
        $findAll(`.footer.select[data-icon_type="edit_sticker"]`),
        each(
          $on(
            'click',
            VectorEditorStickerFreeMobileEventF.handleFooterSelectEditStickerIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.select[data-icon_type="duplicate"]`),
        each(
          $on(
            'click',
            VectorEditorStickerFreeMobileEventF.handleFooterSelectDuplicateIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.select[data-icon_type="delete"]`),
        each(
          $on(
            'click',
            VectorEditorStickerFreeMobileEventF.handleFooterSelectDeleteIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),

      tap(
        $on(
          '@mp/sticker-editor/select',
          VectorEditorStickerFreeMobileEventF.handleFreeStickerEditorSelect({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/unselect',
          VectorEditorStickerFreeMobileEventF.handleFreeStickerEditorUnselect({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/add',
          VectorEditorStickerFreeMobileEventF.handleFreeStickerEditorAdd({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/remove',
          VectorEditorStickerFreeMobileEventF.handleFreeStickerEditorRemove({ tab_el: tab_el$ }),
        ),
      ),
      tap(
        $on(
          '@mp/sticker-editor/transform',
          VectorEditorStickerFreeMobileEventF.handleFreeStickerEditorTransform({ tab_el: tab_el$ }),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    tab_el$.__mp_handleWindowMouseMove = VectorEditorStickerFreeMobileEventF.handleWindowMouseMove({
      tab_el: tab_el$,
    });
    $on('touchmove', tab_el$.__mp_handleWindowMouseMove, { passive: false })(window);

    const free_sticker_editor = new EditorNS.FreeStickerEditorNS.MobileNS.FreeStickerMobileEditor({
      window,
      document,
      touch_margin: 6,
      max_count: 20,
    });
    free_sticker_editor.initDOM();
    free_sticker_editor.initEditor();
    go(
      tab_el$,
      $findAll('.editor_container'),
      takeL(1),
      each((el) => free_sticker_editor.appendTo(el)),
    );
    if (tab_el$.__mp_free_template_svg_el != null) {
      each(
        $on(
          'touchend',
          VectorEditorStickerFreeMobileEventF.handleFreeTemplateCuttingLineTouchEnd({ tab_el: tab_el$ }),
        ),
      )($findAll(`[data-free-sticker-template-role="cutting-line"]`)(tab_el$.__mp_free_template_svg_el));
      tab_el$.__mp_free_template_svg_el.classList.add('free_template');
      free_sticker_editor.setTemplate(tab_el$.__mp_free_template_svg_el);
      tab_el$.__mp_free_template_svg_el.dataset.is_show = 'true';
      VectorEditorStickerFreeMobileF.hide2TypeNoneMakeStickerFooter(tab_el$);
    }
    tab_el$.__mp_free_sticker_editor = free_sticker_editor;

    return go(
      tab_el$?.__mp_preProcess?.(free_sticker_editor, tab_el$),
      () => tab_el$.__mp_free_sticker_editor?.getEls?.() ?? [],
      eachL(({ id, wrapper_el }) =>
        VectorEditorStickerFreeMobileF.setOffsetPathData({ tab_el: tab_el$, id, wrapper_el }),
      ),
      eachL(({ id, wrapper_el }) =>
        VectorEditorStickerFreeMobileF.transformOffsetPathData({ tab_el: tab_el$, id, wrapper_el }),
      ),
      takeAll,
      (stickers) => {
        if (stickers.length > 0) {
          VectorEditorStickerFreeMobileF.checkCollision({ tab_el: tab_el$ });
        }
      },
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {
    tab_el$.__mp_is_prevent_mouse_move = true;
  }, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {
    tab_el$.__mp_free_sticker_editor?.selectEls?.([]);
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {
    tab_el$.__mp_is_prevent_mouse_move = false;
  }, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {
    if (tab_el$.__mp_handleWindowMouseMove != null) {
      $off('touchmove', tab_el$.__mp_handleWindowMouseMove, { passive: false })(window);
    }

    tab_el$.__mp_handleWindowMouseMove = null;
    tab_el$.__mp_is_prevent_mouse_move = false;

    tab_el$.__mp_free_sticker_editor?.destroy();
    tab_el$.__mp_free_sticker_editor = null;

    tab_el$.__mp_single_art_board_size = { width: 0, height: 0 };
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
