import { go, head, html, reject, sel, strMap } from 'fxjs/es';

/* template_str, name */
export const makeHtml = (tabs) => html`
  <div
    class="product_detail_tabs_container"
    data-current_tab="${go(
      tabs,
      reject(({ is_hidden }) => is_hidden),
      head,
      sel('name'),
    )}"
  >
    <div class="product_detail_tabs">
      <div class="wrapper">
        ${strMap(
          ({ title, name, is_hidden }) =>
            html`
              <button
                type="button"
                class="product_detail_tab"
                data-tab_name="${name}"
                data-is_hidden="${is_hidden}"
              >
                ${title}
              </button>
            `,
        )(tabs)}
      </div>
    </div>
    <div class="product_detail_tab_bodys">
      ${strMap(
        ({ name, template_str, is_hidden }) =>
          html`<div class="product_detail_tab_body" data-tab_name="${name}" data-is_hidden="${is_hidden}">
            ${template_str}
          </div>`,
      )(tabs)}
    </div>
  </div>
`;
