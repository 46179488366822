import { go, strMap, entriesL, entries, takeAll, zipWithIndexL } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import {
  htmlAddPresetFilterCanvas,
  htmlAddPresetFilterSlider,
  htmlAddAdjustmentBtn,
  htmlEditorSelectBtns,
  htmlAddAdjustmentSlider,
  htmlAddCropButtons,
  htmlAddCropSliders,
} from '../Tmpl/module/index.js';
import { preset_filters, adjustment_items, crop_items, editor_mode } from '../../F/Function/module/index.js';

export const tab = {
  tab_name: 'marstagram_editor',
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  title: '', // tab button의 title
  selected: false, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  template(data) {
    const is_mp_worker_policy = data.is_mp_worker_policy;
    const filter_items_arr = go(entriesL(preset_filters), zipWithIndexL, takeAll);
    const adjustment_items_arr = go(entriesL(adjustment_items), zipWithIndexL, takeAll);
    const crop_items_arr = go(entriesL(crop_items), zipWithIndexL, takeAll);
    const swiper_prev_next_btns = (is_pc) => {
      if (is_pc) {
        return legacyHtml`
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        `;
      }
    };
    const url_init_btn_normal =
      'https://s3.marpple.co/files/u_1187078/2021/1/original/2728deaa8c2059ab6523174daf68aafeca6200271.png';
    const url_init_btn_active =
      'https://s3.marpple.co/files/u_1187078/2021/1/original/2f3b19421b0737362efe5b6921fe679acac120951.png';
    return legacyHtml`
      <div class="editor_body" current_editor="${data.initial_editor_selected}">
        <div class="div_canvas_box">
          <div class="canvas_box"></div>
        </div>
        <div class="marstagram_title">
          <span>${T('image_editor::image_editor')}</span>
        </div>
        <div class="div_editor_selection_box">
          <div class="editor_selection_box">
            ${htmlEditorSelectBtns(data.initial_editor_selected, entries(editor_mode))}
          </div>
        </div>
        <div class="div_editor_items_box">
          <div style="display:none">
            <img class="btn_img_normal" src="${url_init_btn_normal}" alt="" />
            <img class="btn_img_active" src="${url_init_btn_active}" alt="" />
          </div>
          <div class="editor_items_box">
            <div class="editor_items filters">
              <div class="filter_items">
                <div class="filter_item_canvases swiper-container">
                  <div class="swiper-wrapper">
                    ${go(
                      filter_items_arr,
                      strMap(([_, [item, data]]) => htmlAddPresetFilterCanvas(item, data.display_name)),
                    )}
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
                ${swiper_prev_next_btns(G.is_pc_size())}
                <div class="filter_item_sliders sliders">
                  ${go(
                    filter_items_arr,
                    strMap(([_, [item, __]]) => htmlAddPresetFilterSlider(item)),
                  )}
                </div>
              </div>
            </div>
            <div style="display:none">
              <img class="btn_img_normal" src="${url_init_btn_normal}" alt="" />
              <img class="btn_img_selected" src="${url_init_btn_active}" alt="" />
            </div>
            <div class="editor_items adjustment editor_btn_slider_design">
              <div class="adjustment_items">
                <div class="adjustment_item_buttons btns_container swiper-container">
                  <div class="swiper-wrapper">
                    <div class="adjustment_item btn_item swiper-slide initializer">
                      <span>${T('image_editor::initialize')}</span>
                      <div class="button initializer">
                      </div>
                    </div>
                    ${go(
                      adjustment_items_arr,
                      strMap(([idx, [item, data]]) =>
                        htmlAddAdjustmentBtn(item, data, idx, is_mp_worker_policy),
                      ),
                    )}
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
                ${swiper_prev_next_btns(G.is_pc_size())}
                <div class="adjustment_item_sliders sliders slider_items">
                  ${go(
                    adjustment_items_arr,
                    strMap(([idx, [item, data]]) =>
                      htmlAddAdjustmentSlider(
                        data.method,
                        item,
                        data.display_name,
                        data.range_options,
                        idx,
                        data.is_show,
                        is_mp_worker_policy,
                      ),
                    ),
                  )}
                </div>
              </div>
            </div>
            <div class="editor_items crop editor_btn_slider_design">
              <div class="crop_items">
                <div class="crop_item_buttons btns_container swiper-container">
                  <div class="swiper-wrapper">
                    <div class="crop_item btn_item swiper-slide initializer">
                      <span>${T('image_editor::initialize')}</span>
                      <div class="button initializer">
                      </div>
                    </div>
                    ${go(
                      crop_items_arr,
                      strMap(([idx, [item, data]]) => htmlAddCropButtons(item, data, idx)),
                    )}
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
                ${swiper_prev_next_btns(G.is_pc_size())}
                <div class="crop_item_sliders sliders slider_items">
                  ${go(
                    crop_items_arr,
                    strMap(([idx, [item, data]]) =>
                      htmlAddCropSliders(data.method, item, data.display_name, data.range_options, idx),
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="controller">
          <button class="header_btns" action="cancel">${T('image_editor::cancel')}</button>
          <button class="header_btns" action="complete">${T('image_editor::complete')}</button>
        </div>
      </div>
      </div>
    `;
  },

  // makeData(tab) {}, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함. template 함수 인자에 들어갈 값이며 이값은 프론트로 전달됨
};
