import { go, html } from 'fxjs/es';
import { $find, $qs, $setAttr, $setCss, $setText } from 'fxdom/es';

export const alert = ({ content = '', ok = '', msg_width }) => {
  const prom = $.alert(html`
    <div class="custom-body">
      <p>${content}</p>
    </div>
  `);

  setTimeout(() => {
    const el = $qs('.don_dialog_msg');
    if (el) $setAttr({ type: 'alert' }, el);
    if (ok) go(el, $find('.ok'), $setText(ok));
    if (msg_width) go(el, $find('.msg'), $setCss({ maxWidth: msg_width }));
    if (msg_width) go(el, $find('.custom-body'), $setCss({ maxWidth: msg_width }));
  }, 0);

  return prom;
};
