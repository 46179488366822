// version1 = prefix(1) + 01 + pdf_up(9)
export const parseTechPackBarcode = (barcode) => {
  const version = +barcode.slice(1, 3);

  // 추후에 추상화해서 관리
  if (version == 1) {
    const tech_pack_up_id = +barcode.slice(3, 12);
    return {
      tech_pack_up_id,
    };
  } else if (version == 2) {
    const up_id = +barcode.slice(3);
    return {
      up_id,
    };
  }
};
