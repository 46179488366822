import axios from 'axios';

export const api_call = {
  get: {
    mpsLabelData: async ({ base_product_id, base_product_color_id }) =>
      (
        await axios.get('/@api/df/base_product_labels/mps', {
          params: { base_product_id, base_product_color_id },
        })
      ).data,
  },
};
