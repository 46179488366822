import { $el, $findAll, $qs, $replaceWith } from 'fxdom/es';
import { each, go, partition } from 'fxjs/es';
import { computeShippingCostF } from '../../../../Shipping/Cost/F/fs.js';
import Ups from '../../../../Ups/S/ups.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPMyPageF } from '../../../MyPage/F/Function/module/OMPMyPageF.js';
import { OMPCartTmplS } from '../../S/Tmpl/module/OMPCartTmplS.js';
import { GoodsTypeS } from '../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { OMPCoreUserProductS } from '../../../Core/UserProduct/S/Function/module/OMPCoreUserProductS.js';
import { ShippingConstantS } from '../../../../Shipping/S/Constant/module/ShippingConstantS.js';
import { SamsungS } from '../../../../Samsung/S/Function/module/SamsungS.js';

export const rerenderCart = async (user_products) => {
  const price_info = Ups.calc_projection_price_info(user_products, G._en);
  const is_en = TT.lang === 'en';

  const [dtg_ups, not_dtg_ups] = partition((up) => GoodsTypeS.isDtg(up.goods_type_id))(user_products);
  const all_is_digital_product = !!dtg_ups.length && !not_dtg_ups.length;
  const upcs_ids = OMPCoreUserProductS.getUpcsIdsFromUserProducts({ user_products });

  /*카트라서 도서산간비 X */
  const shipping_price =
    is_en || all_is_digital_product
      ? 0
      : await computeShippingCostF({
          collabo_type: '',
          shipping_type: 'parcel',
          country_id: TT.lang == 'jp' ? ShippingConstantS.COUNTY_ID_JP : undefined,
          order_amount: price_info.product_price,
          lang: TT.lang,
          upcs_ids,
          is_samsung: SamsungS.hasSamsungBp(user_products),
        });

  const current_cart$ = $el(
    OMPCoreUtilF.isMobile()
      ? OMPCartTmplS.cartTmplMo({ user_products, price_info, shipping_price })
      : OMPCartTmplS.cartTmplPc({ user_products, price_info, shipping_price }),
  );
  const prev_cart$ = $qs('.omp-cart');

  try {
    $replaceWith(current_cart$, prev_cart$);
    go(current_cart$, $findAll('.omp-cart__product-thumbnail'), each(OMPMyPageF.drawThumbnail));
  } catch (e) {
    location.reload();
  }
};

export const afterEditCart = () => {
  const box = window.box;
  const user_products = box.sel('modal->_user_products_');
  box.unset('modal');
  if (!user_products) return;
  box.set('user_products', user_products);

  return rerenderCart(user_products);
};
