import { SVGEditorUtilF } from '../../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { $closest, $data, $find, $setText } from 'fxdom/es';
import { VectorEditorConstantS } from '../../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { go } from 'fxjs/es';
import { VectorEditorF } from '../../../../VectorEditor/F/Function/module/VectorEditorF.js';
import { SVGEditorProductDetailF } from '../../../../SVGEditor/ProductDetail/F/Function/module/SVGEditorProductDetailF.js';

function dataURLtoBlob(canvas) {
  const dataURL = canvas.toDataURL('image/png', 1);
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const blob = new Blob([u8arr], { type: mime });
  blob.lastModifiedDate = new Date();
  blob.name = 'printable_file.png';
  return blob;
}

function svgElToBlob(svg_el) {
  const svgString = new XMLSerializer().serializeToString(svg_el);

  // SVG 문자열을 Blob 객체로 변환
  const blob = new Blob([svgString], { type: 'image/svg+xml' });

  // Blob 객체를 파일 객체로 변환 (선택적)
  blob.lastModifiedDate = new Date();
  blob.name = 'cutting_line.svg';

  // 변환된 Blob 객체를 사용하거나 전송 등의 작업을 수행
  return blob;
}
const svgUpload = async (ct) => {
  /*키링, 아크릴스탠드*/
  let loaderDone = null;
  try {
    loaderDone = SVGEditorUtilF.percentLoader({
      message: T('modules::VectorEditor::AcrylicFigure::message::make_images'),
      time: 6 * 1000,
      clazz: UtilF.isLegacyMobile() ? 'mobile' : 'pc',
    });
    const file = ct.files[0];
    const original_svg_file_name = file.name;

    if (file) {
      const maker_type = $closest('#maker_frame', ct).dataset?.maker_type;

      const options = (() => {
        if (maker_type === VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR) {
          const acryl_stand_size_option_el = go(
            ct,
            $closest('#marpplizer_home'),
            $find('.bp_option_group[data-dev_name="아크릴_스탠드_사이즈"]'),
          );

          if (acryl_stand_size_option_el) {
            const size_select_el = $find('select', acryl_stand_size_option_el);
            const selected_option = $data(size_select_el.options[size_select_el.selectedIndex]);

            return selected_option._.maker_meta.value.free_meta;
          }
        }
      })();
      const result = await VectorEditorF.pro.processSvgUploadFile({ file, maker_type, options });

      const { printable_image_canvas, key_item_coord_ratio, cut_svg_el, toPrintableImageCanvas } = result;

      await SVGEditorProductDetailF.directFileUploadSvgProcess({
        cutting_line_svg_file: svgElToBlob(cut_svg_el),
        original_svg_file: file,
        key_item_coord_ratio,
        original_svg_file_name,
        printable_png_file: dataURLtoBlob(printable_image_canvas),
        thumbnail_png_file: dataURLtoBlob(await toPrintableImageCanvas(800)),
      });
      go(
        ct,
        $closest('.maker-upload-type-template__upload-file'),
        $find('.maker-upload-type-template__file-name'),
        $setText(original_svg_file_name),
      );
    }
  } catch (err) {
    if (loaderDone) {
      await loaderDone(true).catch(() => {});
      loaderDone = null;
    }
    await VectorEditorF.alertProEditorError(err);
  } finally {
    $.don_loader_end();
    if (loaderDone) {
      await loaderDone().catch(() => {});
      loaderDone = null;
    }
    ct.value = null;
  }
};
export const uploadTypeDirect = {
  /* SVG 업로드 온리 */
  svgUpload,
};
