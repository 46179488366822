export function makePrevCvBpcfAttr() {
  return {
    selectable: false,
    evented: false,
    globalCompositeOperation: 'destination-over',
    _data: { cv_type: 'prev_cv_bpcf', is_not_design: true },
  };
}

export const DOTTED_LINE = {
  fill: 'rgba(0,0,0,0)',
  strokeWidth: 2,
  strokeDashArray: [6, 3],
};
