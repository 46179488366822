import { filterL, flatL, go, mapL, object, zipL } from 'fxjs/es';
import { baud_table, print_mode_table, status_keys } from './zpl_constants.js';

const BrowserPrint = window.BrowserPrint;

const queryTimeout = (res, timeout) => {
  return setTimeout(() => {
    return res('timeout');
  }, timeout);
};

export const zplCMD = {
  getDefaultLabelDevice: async (timeout) => {
    return new Promise((res, rej) => {
      queryTimeout(res, timeout);
      BrowserPrint.getDefaultDevice(
        'printer',
        (default_device) => res(default_device),
        (e) => rej(e),
      );
    });
  },
  getLocalLabelDevices: async (timeout) => {
    return new Promise((res, rej) => {
      queryTimeout(res, timeout);
      BrowserPrint.getLocalDevices(
        (local_devices) => {
          return res(local_devices);
        },
        (e) => rej(e),
        'printer',
      );
    });
  },
  getDeviceStatus: async (device) => {
    return new Promise((res, rej) => {
      device.sendThenRead(
        '~hs',
        (status) => {
          return res(statusParser(status));
        },
        (e) => {
          rej(e);
        },
      );
    });
  },
  readFromDevice: async (device) => {
    return new Promise((res, rej) => {
      device.read(readCallback(res), readResponseErrorCallback(rej));
    });
  },
  sendThenReadToZPLcmd: async (device, cmd, cb) => {
    return new Promise((res, rej) => {
      device.sendThenRead(
        cmd,
        (data) => {
          return cb ? res(cb(data)) : res(data);
        },
        (e) => {
          rej(e);
        },
      );
    });
  },

  setDarkness: async (device, val) => {
    const data_to_write = `^XA~SD${val}^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  setPrintMode: async (device, val) => {
    /*
      T = Tear-off
      P = Peel-off (not available on S-300)
      R = Rewind (depends on printer model)
      A = Applicator (depends on printer model)
      C = Cutter (depends on printer model)
      D = Delayed cutter n
      F = RFID
      L = Reserved
      U = Reserved
      K = Kiosk
    * */
    const data_to_write = `^XA^MM${val}^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  setMediaType: async (device, val) => {
    /*
      T = thermal transfer media
      D = direct thermal media
    * */
    const data_to_write = `^XA^MT${val}^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  setTearLineOffset: async (device, val) => {
    /*
       –120 to 120 dots for 300 dpi
    * */
    const data_to_write = `^XA~TA${val}^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  setPrintSpeed: async (device, val) => {
    /*
       –120 to 120 dots for 300 dpi
    * */
    const data_to_write = `^XA^PR${val},D,A^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  setPrintWidth: async (device, w_mm, dpi) => {
    /*
        label width (in dots)
    * */
    const w_dots = Math.round((w_mm * dpi) / 25.4);
    const data_to_write = `^XA^PW${w_dots}^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  setInternationalEncoding: async (device, val = 26) => {
    //26 : Multibyte Asian Encodings with ASCII Transparency
    const data_to_write = `^XA^CI${val}^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  setFontIdentifier: async (device, identify_letter, font_path) => {
    const data_to_write = `^XA^CW${identify_letter},${font_path}^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  setEncodingTable: async (device, filename, encoding_char_set) => {
    //example of filename: E:UHANGUL.DAT
    const data_to_write = `^XA^SE${filename}^CI${encoding_char_set}^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  //TODO: print error checking ~WQ
  printDirectoryLabel: async (device, src_driver, extension) => {
    // src_driver: R = DRAM, E = FLASH, Z = ALL
    /* extension: .FNT = font
                  .BAR = bar code
                  .ZPL = stored ZPL format
                  .GRF = GRF graphic
                  .CO = memory cache
                  .DAT = font encoding
                  .BAS = ZBI encrypted program
                  .BAE = ZBI encrypted program
                  .STO = data storage
                  .PNG = PNG graphic
                   * = all objects
                  .TTF = TrueType Font
                  .TTE = True Type Extension
                  Default: *
    *
    * */
    const data_to_write = `^XA^WD${src_driver}:*.${extension}^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },

  printConfigurationLabel: async (device) => {
    const data_to_write = `^XA~WC^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  initializeFlashMemory: async (device) => {
    /*
    * • Initializing memory can take several minutes. Be sure to allow sufficient time for the
        initialization to complete before power cycling the printer
    * */
    const data_to_write = `^XA^JBE^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
  cancelAll: async (device) => {
    const data_to_write = `^XA~JA^XZ`;
    return await writeToZPLdata(device, data_to_write);
  },
};

export const writeToZPLdata = async (device, data_to_write) => {
  return new Promise((res, rej) => {
    device.send(
      data_to_write,
      (data) => res(data),
      (e) => rej(e),
    );
  });
};

const readCallback = (res) => (readData) => {
  if (readData === undefined || readData === null || readData === '') {
    //return data 가 없으면 null 반환
    res(null);
  } else {
    res(readData);
  }
};

const readResponseErrorCallback = (rej) => (errorMessage) => {
  rej(errorMessage);
  return $.alert('프린트로부터 응답이 없어요.');
};

const statusParser = (status_str) => {
  const status = go(
    status_str.split(','),
    mapL((x) => {
      const stx = '\x02';
      const etx = '\x03';
      x = x.replace('\r\n', '');
      x = x.replace(stx, ',');
      x = x.replace(etx, ',');
      x = x.split(',');
      return x;
    }),
    flatL,
    filterL((x) => x.length),
    zipL(status_keys),
    filterL(([k, v]) => k !== undefined && k !== 'unused'),
    object,
  );
  status.communication_settings = parseCommunicationSettings(status.communication_settings);
  status.print_mode = print_mode_table[status.print_mode];
  return status;
};

const getBit = (dec_num, bit_idx) => {
  return Number(Boolean(dec_num & (1 << bit_idx)));
};

const parseCommunicationSettings = (str) => {
  const dec_num = parseInt(str, 10);
  const baud_rate = baud_table[dec_num.toString(2).substr(-3)];
  const hand_shake = getBit(dec_num, 7);
  const parity = getBit(dec_num, 6);
  const enable = getBit(dec_num, 5);
  const stop_bit = getBit(dec_num, 4);
  const data_bit = getBit(dec_num, 3);
  return {
    binary: dec_num.toString(2).padStart(8, '0'),
    baud_rate,
    hand_shake,
    parity,
    enable,
    stop_bit,
    data_bit,
  };
};
