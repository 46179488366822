import { html } from 'fxjs/es';
import {
  design_collection_page_mobile_j,
  design_collection_page_pc_j,
} from '../../../DesignCollection/Detail/S/tmpl.js';
import { OMPReviewTmplS } from '../../../OMP/Review/S/Tmpl/module/OMPReviewTmplS.js';
import { ProductDetailTabsTmplS } from '../Tmpl/module/ProductDetailTabsTmplS.js';

export const STORY_TAB = {
  name: 'STORY_TAB',
  title: () => T('product_detail::Story'),
  template: ({ design_collection, is_mobile, alt }) =>
    design_collection?.id && design_collection?.is_hidden === false
      ? is_mobile
        ? html`
            <div class="body design_collection_body mp_today_detail_mobile_style">
              ${design_collection_page_mobile_j(design_collection, alt)}
            </div>
          `
        : html`
            <div class="body design_collection_body mp_today_detail_pc_style">
              ${design_collection_page_pc_j(design_collection, alt)}
            </div>
          `
      : '',
};

export const REVIEW_TAB = {
  name: 'REVIEW_TAB',
  title: (count) => '☆ ' + T('product_detail::Reviews') + `(${(count > 999 ? '999+' : count) || 0})`,
  template: ({ reviews, review_total, query, is_mobile }) =>
    html`
      <div class="tab_wrapper">
        ${G.collabo_type == ''
          ? OMPReviewTmplS.reviewTmpl({ is_mobile, reviews, review_total, cate_lists: [], query })
          : ProductDetailTabsTmplS.makeReviewsHtml({ reviews, review_total, query })}
      </div>
    `,
};

export const BP_DETAIL_TAB = {
  name: 'BP_DETAIL_TAB',
  title: () => T('product_detail::Product Info'),
  template: ({
    size_info_url,
    id,
    cate_list_id,
    cate_item_id,
    size_compare,
    brand_logo,
    brand_name,
    brand_description,
    product_info,
    guide,
    size_table,
    is_mobile,
  }) => html`
    <div class="tab_wrapper">
      ${ProductDetailTabsTmplS.bpInfo({ brand_logo, brand_name, brand_description, product_info })}
      ${guide ? ProductDetailTabsTmplS.guide({ guide }) : ''}
      ${ProductDetailTabsTmplS.size({
        size_table,
        size_info_url,
        id,
        cate_list_id,
        cate_item_id,
        lang: T.lang,
      })}
      ${size_compare?.is_public
        ? ProductDetailTabsTmplS.sizeCompare({ size_compare_items: size_compare?.items }, is_mobile)
        : ''}
    </div>
  `,
};

export const MANUFACTURE_TAB = {
  name: 'MANUFACTURE_TAB',
  title: () => T('product_detail::Print Guide'),
  template: ({ print_guide, id }) =>
    print_guide
      ? html`<div class="tab_wrapper">${ProductDetailTabsTmplS.printGuide({ print_guide })}</div>`
      : G.collabo_type === ''
      ? html`<div class="tab_wrapper">${ProductDetailTabsTmplS.defaultPrintGuide({ id })}</div>`
      : '',
};
