import paper from 'paper';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

import { DfImageEditorF } from './module/DfImageEditorF.js';
import { each, go } from 'fxjs/es';
import { $closest, $find, $findAll } from 'fxdom/es';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';
import { DATA_SET_KEY, GROUP_NAME } from '../../S/Constant/constants.js';

export function createBrush({ paper_scope, tool_name, position, radius, is_insert = true }) {
  if (tool_name == null) {
    throw new Error(`Not exist brush name`);
  }

  const transparent_color = new paper_scope.Color(0, 0, 0, 0);
  const brush = new paper_scope.Path.Circle({
    center: position ?? new paper_scope.Point(0, 0),
    radius,
    fillColor: transparent_color,
    strokeColor: 'black',
    dashArray: [1.8, 1.8],
    strokeWidth: 0.9,
    visible: false,
  });

  brush.name = tool_name;
  brush.strokeScaling = false;

  is_insert && DfImageEditorF.getToolLayer({ paper_scope }).addChild(brush);
  return brush;
}

export function activateBrush({ brush_item }) {
  brush_item.visible = true;
}

export function deactivateBrush({ brush_item }) {
  brush_item.visible = false;
}

export function getBrushGuideItem({ paper_scope, tool_name }) {
  return DfImageEditorF.getToolLayer({ paper_scope }).getItem({ name: tool_name });
}

export function getInitialBrushSize({ paper_scope }) {
  /* 디자인 픽셀 과 관련된 layer - stroke_layer 와 printable_raster */
  /* 중에서 가장 큰 bounds 영역으로 크기를 산출 */
  const { width, height } = DfImageEditorF.findOutmostPixelCoords({
    paper_scope,
  });
  const size = Math.max(width, height);
  return Math.round(size / 30);
}

export function brushControlEvent({ tab_el }) {
  go(
    tab_el,
    $findAll('.brush div.slider'),
    each(($brush_slider) => {
      const { tool_name, brush_name, min, max, step, start } = $brush_slider.dataset;
      const slider_obj = noUiSlider.create($brush_slider, {
        start: start ?? 0,
        step: Number(step),
        range: { min: Number(min), max: Number(max) },
        connect: 'lower',
        keyboardSupport: true,
        keyboardDefaultStep: 1,
      });

      DfImageEditorLibF.setState.property.brush.slider(tool_name, brush_name, slider_obj);

      const $indicator = go($brush_slider, $closest('.brush'), $find('input.indicator'));
      DfImageEditorLibF.setState.property.brush.indicator(tool_name, brush_name, $indicator);

      slider_obj.on('update', (values, handle) => {
        const new_value = values[handle];
        $indicator.value = prepareNumber({ num: new_value, min, max });
        DfImageEditorLibF.setState.property.brush[brush_name](tool_name, new_value);
      });

      $indicator.addEventListener('change', (e) => {
        const input_value = e.currentTarget.value;
        const new_target_value = prepareNumber({ num: input_value, min, max });
        input_value !== new_target_value && (e.currentTarget.value = new_target_value);
        slider_obj.set(new_target_value);
      });
    }),
  );
}

export function prepareNumber({ num, min, max, decimal = 0 }) {
  return precisionNumber({ num: truncateNumber({ num, min, max }), decimal });
}

export function precisionNumber({ num, decimal }) {
  if (num == null) {
    throw new Error('Not exist number');
  }
  return Number(num).toFixed(decimal);
}

export function truncateNumber({ num, min, max }) {
  if (num == null) {
    throw new Error('Not exist number');
  }
  return Math.min(Math.max(num, min), max);
}

const logitFn = (x) => truncateNumber({ num: 0.08 * Math.log((2 * x) / (1 - x)) + 0.5, min: 0, max: 1 });

export function setBrushHardnessGradientColor({ hardness }) {
  const h = hardness / 100; // 0.0 ~ 1.0
  const knot_alpha = logitFn(h);
  const center_alpha = createLerpFn({ from: [0, 1], to: [knot_alpha, 1] });

  const gradient_stops_ori = [
    [[new paper.Color(0, 0, 0, center_alpha(h)), 0.0]],
    [[new paper.Color(0, 0, 0, knot_alpha), h]],
    [[new paper.Color(0, 0, 0, 0.0), 1.0]],
  ];

  return new paper.Gradient({
    stops: gradient_stops_ori,
    radial: true,
  });
}

export async function addBrushEvents({ paper_scope, layer, tool_name, keyboard_event }) {
  // e: keyboard Event
}

function createLerpFn({ from: [a, b], to: [c, d] }) {
  return function (value) {
    value = Math.max(a, Math.min(value, b));
    return (value - a) * ((d - c) / (b - a)) + c;
  };
}

export function createBrushPaintingGroup({ paper_scope, layer, fnWhenCreate, data_tag_obj }) {
  let painting_group = getPaintingGroupInLayer({ layer });

  if (painting_group == null) {
    const new_painting_group = new paper_scope.Group();

    new_painting_group.name = GROUP_NAME.painting;
    DfImageEditorF.addDataToItem({
      item: new_painting_group,
      data: { [DATA_SET_KEY.is_brush]: true, ...data_tag_obj },
    });
    layer.addChild(new_painting_group);
    painting_group = new_painting_group;

    fnWhenCreate({ painting_group });
  }

  return painting_group;
}

export function paintBrushEnd({ layer }) {
  const painting_group = getPaintingGroupInLayer({ layer });
  if (painting_group) {
    painting_group.selected = false;
    painting_group.name = null;
    painting_group.closed = true;
  }
}

function getPaintingGroupInLayer({ layer }) {
  return layer.getItem({ name: GROUP_NAME.painting });
}

function getFirstInvisiblePaintBrushGroup({ layer }) {
  const paint_groups = layer.children;
  for (let i = 0; i < paint_groups.length; i++) {
    const paint_group = paint_groups[i];
    if (paint_group.visible === false) return paint_group;
  }
  return null;
}

function getLastVisiblePaintedBrushGroup({ layer }) {
  const paint_groups = layer.children;

  for (let i = paint_groups.length - 1; i >= 0; i--) {
    const paint_group = paint_groups[i];
    if (paint_group.visible === true) return paint_group;
  }
  return null;
}

export function undoBrush({ layer }) {
  const last_visible_paint_group = getLastVisiblePaintedBrushGroup({ layer });
  last_visible_paint_group ? (last_visible_paint_group.visible = false) : undoPrintableRasterImage({ layer });
}

export function redoBrush({ layer }) {
  const first_visible_paint_group = getFirstInvisiblePaintBrushGroup({ layer });
  first_visible_paint_group
    ? (first_visible_paint_group.visible = true)
    : redoPrintableRasterImage({ layer });
}

export function removeAllInvisibleBrushGroup({ layer }) {
  layer.children
    .filter((group) => isBrushGroup({ group_item: group }) && group.visible === false)
    .forEach(DfImageEditorF.destroyItem);
}

function isBrushGroup({ group_item }) {
  return group_item.data[DATA_SET_KEY.is_brush];
}

export function removeAllBrushGroup({ layer }) {
  layer.children.filter((group) => isBrushGroup({ group_item: group })).forEach(DfImageEditorF.destroyItem);
}

function undoPrintableRasterImage({ layer }) {
  const paper_scope = layer.getProject()._scope;
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });

  const previous_src = DfImageEditorF.getDataFromItem({ item: printable_raster, key: 'previous_src' });
  previous_src && (printable_raster.source = previous_src);
}

function redoPrintableRasterImage({ layer }) {
  const paper_scope = layer.getProject()._scope;
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  printable_raster.data.next_src && (printable_raster.source = printable_raster.data.next_src);
}

export function updateBrushColorBasedOnRasterSubPixel({ raster, brush_item }) {
  if (raster == null) {
    throw new Error(`Not exist raster`);
  }
  if (brush_item == null) {
    throw new Error(`Not exist brush item`);
  }
  const sub_pixel_color = raster.getAverageColor(brush_item);
  if (sub_pixel_color == null) {
    brush_item.strokeColor = 'Black';
    return;
  }

  const pixel = raster.getPixel(brush_item.position.add(raster.bounds.width / 2, raster.bounds.height / 2));

  const is_raster_dark = DfImageEditorF.isColorDark({ paper_color: sub_pixel_color, alpha: pixel.alpha });
  brush_item.strokeColor = is_raster_dark ? 'white' : 'Black';
}

export function getAllBrushItems({ layer }) {
  return DfImageEditorF.getItemsInLayer({ layer, condition: { data: { [DATA_SET_KEY.is_brush]: true } } });
}

export function getAllVisibleBrushItems({ layer }) {
  return DfImageEditorF.getItemsInLayer({
    layer,
    condition: { visible: true, data: { [DATA_SET_KEY.is_brush]: true } },
  });
}
