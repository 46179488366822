import { each, go } from 'fxjs/es';
import { $findAll, $removeAttr, $setAttr } from 'fxdom/es';
import { VectorEditorTopMenuPCConstantS } from '../../S/Constant/module/VectorEditorTopMenuPCConstantS.js';

export const hideToggleLayersModeIcon = (top_menus_el) => {
  go(
    top_menus_el,
    $findAll(`[data-top_menu_id="${VectorEditorTopMenuPCConstantS.TOP_MENU.TOGGLE_LAYERS_MODE.ID}"]`),
    each((el) => (el.dataset.is_show = 'false')),
  );
};

export const activateTopMenus = ({ top_menus_el, activate_ids = new Set(), deactivate_ids = new Set() }) => {
  go(
    top_menus_el,
    $findAll(`.top_menu`),
    each((el) => {
      if (activate_ids.has(el.dataset.top_menu_id)) {
        $removeAttr('disabled')(el);
        return;
      }

      if (deactivate_ids.has(el.dataset.top_menu_id)) {
        $setAttr(['disabled', ''])(el);
      }
    }),
  );
};
