import { go, tap } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorKeyringGridCreatorPCMuiF } from '../Mui/module/VectorEditorKeyringGridCreatorPCMuiF.js';

export const makeKeyring = ({
  title,
  price,
  art_board: { width: art_board_width, height: art_board_height, shape_path_data: art_board_shape_path_data },
  clip_path_shape_path_data,
  empty_template_el,
  preProcess,
  postProcess,
}) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorKeyringGridCreatorPCMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = (frame_el, data) =>
        go(
          frame_closed.call(frame, frame_el, data),
          tap(() => resolve(data)),
        );

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_title = title;
        tab_el.__mp_price = price;
        tab_el.__mp_art_board_width = art_board_width;
        tab_el.__mp_art_board_height = art_board_height;
        tab_el.__mp_art_board_shape_path_data = art_board_shape_path_data;
        tab_el.__mp_clip_path_shape_path_data = clip_path_shape_path_data;
        tab_el.__mp_empty_template_el = empty_template_el;
        tab_el.__mp_postProcess = postProcess;
        tab_el.__mp_preProcess = preProcess;
        if (typeof G.mp.maker.vectorEditorTabAppendingPc === 'function')
          G.mp.maker.vectorEditorTabAppendingPc(tab_el);
        return tab_appending.call(tab, tab_el);
      };
    }).catch(reject),
  );
