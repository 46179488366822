import { each, extend, find, go, indexBy, ippL, map, reject, sel, sortByDesc } from 'fxjs/es';
import { makeOnlyDesignFaceCanvasByPrintArea } from '../../../../../Maker/F/draw_product_faces.js';
import { getProductFaces2InMaker } from '../../../../../Maker/F/getSth.js';
import { BpOptionF } from '../../../../../BpOption/F/Function/module/BpOptionF.js';
import { BpOptionSelectedOptionGroupS } from '../../../../../BpOption/SelectedOptionGroup/S/Function/module/BpOptionSelectedOptionGroupS.js';
import { BpOptionS } from '../../../../../BpOption/S/Function/module/BpOptionS.js';
import { NewMakerF } from '../../../../F/Function/module/NewMakerF.js';
import { loadImageFromUrl } from '../../../../../Canvas/S/util.js';

const bp_option_maker_material_type = {
  반칼: '반칼',
  재단: '재단',
};

const bp_option_maker_material_shape = {
  rectangle: 'rectangle',
  circle: 'circle',
  ellipse: 'ellipse',
};

const DPI = 300;

const mmToPxByDpi = (mm, ratio = 1) => {
  return Math.ceil(((mm * 0.1) / 2.54) * DPI * ratio);
};

function makeBackgroundFcanvas(width_mm, height_mm, ratio) {
  return new fabric.StaticCanvas(document.createElement('canvas'), {
    width: mmToPxByDpi(width_mm, ratio),
    height: mmToPxByDpi(height_mm, ratio),
    enableRetinaScaling: false,
  });
}

function roundToDecimals(number, n = 3) {
  return Number(number.toFixed(n));
}

function uploadFcanvas(fcanvas) {
  return go($.uploadFileToUrl(fcanvas.toDataURL(), 'bpOptionMakerMaterial'), (image) => {
    return {
      url: image.url,
      width: image.width,
      height: image.height,
    };
  });
}

async function makeDottedArea({
  background_size_mm,
  main,
  ratio,
  strokeWidth,
  setting,
  inner_diff_from_main_mm,
  outer_diff_from_main_mm,
}) {
  return go(
    makeBackgroundFcanvas(background_size_mm.width, background_size_mm.height, ratio),
    async (fcanvas) => {
      go(
        [
          [inner_diff_from_main_mm, '#3cc16c'],
          [outer_diff_from_main_mm, '#ccc'],
        ],
        each(([diff_from_main_mm, color]) => {
          const width_mm = main.width + diff_from_main_mm * 2;
          const height_mm = main.height + diff_from_main_mm * 2;
          let width = mmToPxByDpi(width_mm, ratio);
          let height = mmToPxByDpi(height_mm, ratio);
          const stroke = color;

          const strokeDashArray = [6 * 3, 3 * 3];
          const common_setting = {
            top: (fcanvas.width - width) / 2,
            left: (fcanvas.height - height) / 2,
            strokeWidth,
            fill: 'transparent',
            stroke,
            strokeDashArray,
          };
          width -= strokeWidth;
          height -= strokeWidth;
          let cv_obj;
          if (setting.shape === bp_option_maker_material_shape.circle) {
            cv_obj = new fabric.Circle({
              ...common_setting,
              radius: width / 2,
            });
          } else if (setting.shape === bp_option_maker_material_shape.ellipse) {
            cv_obj = new fabric.Ellipse({
              ...common_setting,
              rx: width / 2,
              ry: height / 2,
            });
          } else if (setting.shape === bp_option_maker_material_shape.rectangle) {
            cv_obj = new fabric.Rect({
              ...common_setting,
              width,
              height,
            });
          }
          fcanvas.add(cv_obj);
        }),
      );
      return uploadFcanvas(fcanvas);
    },
  );
}

async function makeHalfCuttingArea({
  background_size_mm,
  main,
  strokeWidth,
  setting,
  ratio,
  diff_from_main_mm,
}) {
  return go(
    makeBackgroundFcanvas(background_size_mm.width, background_size_mm.height, ratio),
    async (fcanvas) => {
      const width_mm = main.width + diff_from_main_mm * 2;
      const height_mm = main.height + diff_from_main_mm * 2;
      const width = mmToPxByDpi(width_mm, ratio);
      const height = mmToPxByDpi(height_mm, ratio);

      const stroke = 'rgb(228,0,127)';
      // const strokeDashArray = [6, 3];
      const common_setting = {
        top: (fcanvas.width - width) / 2,
        left: (fcanvas.height - height) / 2,
        strokeWidth,
        fill: 'transparent',
        stroke,
      };
      let cv_obj;
      if (setting.shape === bp_option_maker_material_shape.circle) {
        cv_obj = new fabric.Circle({
          ...common_setting,
          radius: width / 2,
        });
      } else if (setting.shape === bp_option_maker_material_shape.ellipse) {
        cv_obj = new fabric.Ellipse({
          ...common_setting,
          rx: width / 2,
          ry: height / 2,
        });
      } else if (setting.shape === bp_option_maker_material_shape.rectangle) {
        cv_obj = new fabric.Rect({
          ...common_setting,
          width,
          height,
        });
      }

      fcanvas.add(cv_obj);
      return uploadFcanvas(fcanvas);
    },
  );
}
function makeSafeAreaData({ px_per_1cm, image, background, width_mm, height_mm }, printing_area) {
  const width_cm = width_mm * 0.1;
  const height_cm = height_mm * 0.1;
  return {
    cm: {
      width: roundToDecimals(width_cm),
      height: roundToDecimals(height_cm),
    },
    px: {
      top: Math.round(
        printing_area.top
          ? (printing_area.top / background.height) * image.height +
              (G.mp.maker.CANVAS_WIDTH_ORIGIN - image.height) / 2
          : (G.mp.maker.CANVAS_WIDTH_ORIGIN - height_cm * px_per_1cm) / 2,
      ),
      left: Math.round(
        printing_area.left
          ? (printing_area.left / background.width) * image.width +
              (G.mp.maker.CANVAS_WIDTH_ORIGIN - image.width) / 2
          : (G.mp.maker.CANVAS_WIDTH_ORIGIN - width_cm * px_per_1cm) / 2,
      ),
      width: Math.round(width_cm * px_per_1cm),
      height: Math.round(height_cm * px_per_1cm),
    },
    img_url: printing_area.url,
  };
}

async function makeMask1({ background_size_mm, main, setting, ratio, diff_from_main_mm }) {
  return go(
    makeBackgroundFcanvas(background_size_mm.width, background_size_mm.height, ratio),
    async (fcanvas) => {
      const width_mm = main.width + diff_from_main_mm * 2;
      const height_mm = main.height + diff_from_main_mm * 2;
      const width = mmToPxByDpi(width_mm, ratio);
      const height = mmToPxByDpi(height_mm, ratio);
      const common_setting = {
        top: Math.round((fcanvas.width - width) / 2),
        left: Math.round((fcanvas.height - height) / 2),
        fill: 'rgb(0, 0, 0, 1)',
        strokeWidth: 0,
      };
      let cv_obj;
      if (setting.shape === bp_option_maker_material_shape.circle) {
        cv_obj = new fabric.Circle({
          ...common_setting,
          radius: width / 2,
        });
      } else if (setting.shape === bp_option_maker_material_shape.ellipse) {
        cv_obj = new fabric.Ellipse({
          ...common_setting,
          rx: width / 2,
          ry: height / 2,
        });
      } else if (setting.shape === bp_option_maker_material_shape.rectangle) {
        cv_obj = new fabric.Rect({
          ...common_setting,
          width,
          height,
        });
      }

      fcanvas.add(cv_obj);
      return uploadFcanvas(fcanvas);
    },
  );
}

async function safeAreaMask({ main, setting, ratio, diff_from_main_mm }) {
  const width_mm = main.width + diff_from_main_mm * 2;
  const height_mm = main.height + diff_from_main_mm * 2;

  return go(makeBackgroundFcanvas(width_mm, height_mm, ratio), async (fcanvas) => {
    const width = fcanvas.width;
    const height = fcanvas.height;
    const common_setting = {
      top: 0,
      left: 0,
      fill: 'rgb(0, 0, 0, 1)',
      strokeWidth: 0,
    };
    let cv_obj;
    if (setting.shape === bp_option_maker_material_shape.circle) {
      cv_obj = new fabric.Circle({
        ...common_setting,
        radius: width / 2,
      });
    } else if (setting.shape === bp_option_maker_material_shape.ellipse) {
      cv_obj = new fabric.Ellipse({
        ...common_setting,
        rx: width / 2,
        ry: height / 2,
      });
    } else if (setting.shape === bp_option_maker_material_shape.rectangle) {
      cv_obj = new fabric.Rect({
        ...common_setting,
        width,
        height,
      });
    }

    fcanvas.add(cv_obj);
    return uploadFcanvas(fcanvas);
  });
}
function getMetaInfo(selected_bp_options, maker_meta_name) {
  return go(
    selected_bp_options,
    map((bp_option) => bp_option._.maker_meta),
    find((v) => v?.name === maker_meta_name),
    sel('value'),
  );
}

async function makeMaks2(printing_area, mask, opacity = 0.5) {
  /*재단만 가능*/
  const fcanvas = new fabric.StaticCanvas(document.createElement('canvas'), {
    width: printing_area.width,
    height: printing_area.height,
    enableRetinaScaling: false,
  });
  await new Promise((resolve) =>
    go(printing_area, ({ url, width, height }) => {
      fabric.Image.fromURL(url, function (img) {
        img.set({ width, height, opacity });
        fcanvas.add(img);
        fcanvas.centerObject(img);
        resolve();
      });
    }),
  );
  await new Promise((resolve) =>
    go(mask, ({ url, width, height }) => {
      fabric.Image.fromURL(url, function (img) {
        img.set({ width, height });
        fcanvas.add(img);
        fcanvas.centerObject(img);
        resolve();
      });
    }),
  );
  return uploadFcanvas(fcanvas);
}

function makeCutlineGeometry(real_printing_area, main, shape) {
  if (shape === bp_option_maker_material_shape.rectangle) {
    return {
      lt_x_to_page_width_ratio: roundToDecimals(
        (real_printing_area.width_mm - main.width) / 2 / real_printing_area.width_mm,
      ),
      lt_y_to_page_height_ratio: roundToDecimals(
        (real_printing_area.height_mm - main.height) / 2 / real_printing_area.height_mm,
      ),
      width_to_page_width_ratio: roundToDecimals(main.width / real_printing_area.width_mm),
      height_to_page_height_ratio: roundToDecimals(main.height / real_printing_area.height_mm),
    };
  }
  if (shape === bp_option_maker_material_shape.circle) {
    return {
      center_x_ratio: roundToDecimals(0.5),
      center_y_ratio: roundToDecimals(0.5),
      diameter_to_width_ratio: roundToDecimals(main.width / real_printing_area.width_mm),
    };
  }
  if (shape === bp_option_maker_material_shape.ellipse) {
    return {
      center_x_ratio: roundToDecimals(0.5),
      center_y_ratio: roundToDecimals(0.5),
      x_diameter_to_width_ratio: roundToDecimals(main.width / real_printing_area.width_mm),
      y_diameter_to_height_ratio: roundToDecimals(main.height / real_printing_area.height_mm),
    };
  }
}

const getPrintableInfo = (selected_bp_options) => {
  const value = getMetaInfo(selected_bp_options, 'printable_info');
  return value?.printable_info;
};
const getGangingSpec = (selected_bp_options) => {
  const value = getMetaInfo(selected_bp_options, 'printable_info');
  return value?.ganging_spec;
};
const getBpfPreviewInfo = (selected_bp_options) => {
  return getMetaInfo(selected_bp_options, 'bpf_preview_info');
};
const getProductMaterialInfo = (selected_bp_options) => {
  return getMetaInfo(selected_bp_options, 'product_material_info');
};

function makePxPer1cm(width_or_height) {
  const cm = width_or_height * 0.1;
  const max = 700;
  const min = 450;
  const diff = max - min;
  const max_cm = 10;
  const ratio = Math.min(1, cm / max_cm);
  return parseFloat(((diff * ratio + min) / cm).toFixed(2));
}
function makeSettingFromBpOption(bp_name, size_bp_option, i) {
  const type = go(undefined, () => {
    if ([266, 290, 291, 292, 398, 334].includes(size_bp_option.bp_option_group_id))
      return bp_option_maker_material_type.반칼;
    if ([286, 399, 400].includes(size_bp_option.bp_option_group_id))
      return bp_option_maker_material_type.재단;
  });
  const [width, height] = size_bp_option.name.match(/\d+/g);
  const shape = go(undefined, () => {
    if (bp_name.includes('사각')) return bp_option_maker_material_shape.rectangle;
    if (bp_name.includes('타원형')) return bp_option_maker_material_shape.ellipse;
    if (bp_name.includes('원형')) {
      if (width === height) {
        return bp_option_maker_material_shape.circle;
      } else {
        return bp_option_maker_material_shape.ellipse;
      }
    }
    throw new Error('shape 없음');
  });
  console.log('makePxPer1cm(Math.max(width, height))', makePxPer1cm(Math.max(width, height)));

  return {
    type,
    shape,
    main: {
      width: Number(width),
      height: Number(height),
    },
    // px_per_1cm:
    //   size_bp_option.id === 1095
    //     ? 100
    //     : size_bp_option.id === 1094
    //     ? 122
    //     : [399].includes(size_bp_option.bp_option_group_id)
    //     ? i * (0.1 * 80) + 80
    //     : [290, 286].includes(size_bp_option.bp_option_group_id)
    //     ? i * (0.04 * 60) + 60
    //     : i * (0.08 * 80) + 80,
    px_per_1cm: makePxPer1cm(Math.max(parseFloat(width), parseFloat(height))),
    need_mask:
      type === bp_option_maker_material_type.반칼 ||
      (type === bp_option_maker_material_type.재단 &&
        ![bp_option_maker_material_shape.rectangle].includes(shape)),
  };
}
async function makeMaterialForMakerMaterial(setting, with_cutline) {
  const image_size = G.mp.maker.CANVAS_WIDTH_ORIGIN;
  // const stroke_width_mm = 0.2;
  const stroke_width_px = 6;
  if (bp_option_maker_material_type.반칼 === setting.type) {
    const main = setting.main;
    const diff_background_from_main = 2.5;
    const background_size_mm = {
      width: main.width + diff_background_from_main * 2,
      height: main.height + diff_background_from_main * 2,
    };
    const ratio = (image_size * 2) / (((background_size_mm.width * 0.1) / 2.54) * DPI);
    const strokeWidth = stroke_width_px;

    const background = await go(
      makeBackgroundFcanvas(background_size_mm.width, background_size_mm.height, ratio),
      async (fcanvas) => {
        fcanvas.add(
          new fabric.Rect({
            top: 0,
            left: 0,
            fill: 'rgb(255,255,255)',
            width: fcanvas.width,
            height: fcanvas.height,
            strokeWidth: 0,
          }),
        );
        return uploadFcanvas(fcanvas);
      },
    );
    const print_mask_padding_mm = 0.5;
    /* 대지에서 실제 디자인되는 영역 사이 패딩 */
    const real_printing_area = go(undefined, () => {
      const diff_from_main_mm = 2.5;
      return {
        width_mm: main.width + diff_from_main_mm * 2,
        height_mm: main.height + diff_from_main_mm * 2,
        padding_mm: print_mask_padding_mm,
        safe_padding_mm: print_mask_padding_mm + 4,
      };
    });

    const half_cutting_area = await makeHalfCuttingArea({
      background_size_mm,
      main,
      strokeWidth,
      setting,
      ratio,
      diff_from_main_mm: 0,
    });

    const mask = await makeMask1({
      background_size_mm,
      main,
      setting,
      ratio,
      diff_from_main_mm: 2,
    });
    const printing_area = await safeAreaMask({
      main,
      setting,
      ratio,
      diff_from_main_mm: 2,
    });
    // const mask2 = await makeMaks2(
    //   await makeMask1({
    //     background_size_mm,
    //     main,
    //     setting,
    //     ratio,
    //     diff_from_main_mm: 2,
    //   }),
    //   mask,
    //   1,
    // );
    const safe_area = await safeAreaMask({
      main,
      setting,
      ratio,
      diff_from_main_mm: -2,
    });

    const dotted_area = await makeDottedArea({
      background_size_mm,
      main,
      ratio,
      strokeWidth,
      setting,
      inner_diff_from_main_mm: -2,
      outer_diff_from_main_mm: 2,
    });
    return {
      setting,
      real_printing_area,
      cutline: with_cutline
        ? {
            type: setting.shape,
            geometry: makeCutlineGeometry(real_printing_area, main, setting.shape),
            border: {
              color: {
                cyan: 0,
                magenta: 1,
                yellow: 0,
                key: 0,
              },
              width: 1,
              opacity: 1,
            },
          }
        : null,
      material_for_maker_material: {
        px_per_1cm: setting.px_per_1cm,
        printing_area,
        background,
        half_cutting_area,
        mask,
        // mask2,
        safe_area,
        dotted_area,
        print_mask_url: mask.url,
      },
    };
  } else if (bp_option_maker_material_type.재단 === setting.type) {
    const main = setting.main;
    const diff_background_from_main = 1.5;
    const background_size_mm = {
      width: main.width + diff_background_from_main * 2,
      height: main.height + diff_background_from_main * 2,
    };
    const ratio = (image_size * 2) / (((background_size_mm.width * 0.1) / 2.54) * DPI);
    const strokeWidth = stroke_width_px;

    const background = await go(
      makeBackgroundFcanvas(background_size_mm.width, background_size_mm.height, ratio),
      async (fcanvas) => {
        const width = mmToPxByDpi(main.width, ratio);
        const height = mmToPxByDpi(main.height, ratio);
        const common_setting = {
          top: (fcanvas.height - height) / 2,
          left: (fcanvas.width - width) / 2,
          fill: 'rgb(255,255,255)',
          width,
          height,
        };
        let cv_obj = null;
        if (setting.shape === bp_option_maker_material_shape.circle) {
          cv_obj = new fabric.Circle({
            ...common_setting,
            radius: width / 2,
          });
        } else if (setting.shape === bp_option_maker_material_shape.ellipse) {
          cv_obj = new fabric.Ellipse({
            ...common_setting,
            rx: width / 2,
            ry: height / 2,
          });
        } else {
          cv_obj = new fabric.Rect(common_setting);
        }
        fcanvas.add(cv_obj);
        return uploadFcanvas(fcanvas);
      },
    );

    const real_printing_area = go(undefined, () => {
      const diff_from_main_mm = 1.5;
      return {
        width_mm: main.width + diff_from_main_mm * 2,
        height_mm: main.height + diff_from_main_mm * 2,
        padding_mm: 0,
        safe_padding_mm: 4,
      };
    });

    const mask = await makeMask1({
      background_size_mm,
      main,
      setting,
      ratio,
      diff_from_main_mm: 0,
    });
    const printing_area = await safeAreaMask({
      main,
      setting,
      ratio,
      diff_from_main_mm: 1.5,
    });
    const safe_area = await safeAreaMask({
      main,
      setting,
      ratio,
      diff_from_main_mm: -1.5,
    });
    const mask2 = await makeMaks2(printing_area, mask);
    const print_mask_url = setting.need_mask ? await go(makeMaks2(printing_area, mask, 1), sel('url')) : null;
    const dotted_area = await makeDottedArea({
      background_size_mm,
      main,
      ratio,
      strokeWidth,
      setting,
      inner_diff_from_main_mm: -1.5,
      outer_diff_from_main_mm: 1.5,
    });

    return {
      setting,
      real_printing_area,
      material_for_maker_material: {
        px_per_1cm: setting.px_per_1cm,
        printing_area,
        background,
        mask2,
        mask,
        safe_area,
        dotted_area,
        print_mask_url,
      },
    };
  }
}
function mergeMakerMaterial({ real_printing_area, material_for_maker_material }) {
  const px_per_1cm = material_for_maker_material.px_per_1cm;
  // const ratio = result.image_size / result.background.width;

  const image = {
    width: real_printing_area.width_mm * 0.1 * px_per_1cm,
    height: real_printing_area.height_mm * 0.1 * px_per_1cm,
  };
  const print = makeSafeAreaData(
    {
      px_per_1cm,
      image,
      real_printing_area,
      width_mm: real_printing_area.width_mm - real_printing_area.padding_mm * 2,
      height_mm: real_printing_area.height_mm - real_printing_area.padding_mm * 2,
    },
    material_for_maker_material.printing_area,
  );
  const safety = makeSafeAreaData(
    {
      px_per_1cm,
      image,
      real_printing_area,
      width_mm: real_printing_area.width_mm - real_printing_area.safe_padding_mm * 2,
      height_mm: real_printing_area.height_mm - real_printing_area.safe_padding_mm * 2,
    },
    material_for_maker_material.safe_area,
  );

  return {
    sf: {
      img: {
        diff: 0,
        width: roundToDecimals(image.width),
        height: roundToDecimals(image.height),
      },
      print,
      safety,
      px_per_1cm,
    },
    base_product_color_face: {
      url: material_for_maker_material.background.url,
      mask_url: material_for_maker_material.mask.url,
      mask2_url: material_for_maker_material.mask2?.url,
    },
    base_product_face: {
      shading_url: material_for_maker_material.half_cutting_area?.url,
      safe_area_url: material_for_maker_material.dotted_area.url,
    },
  };
}
function makeMakerMaterial(selected_bp_options) {
  const printable_info = getPrintableInfo(selected_bp_options);
  if (!printable_info) return;
  const maker_material = mergeMakerMaterial(printable_info);
  return maker_material;
}

function makeBpfPreviewInfo(selected_bp_options) {
  const bpf_preview_info = getBpfPreviewInfo(selected_bp_options);
  return bpf_preview_info;
}

async function makeBpcfUrlByProductMaterialInfo(
  product_material_info,
  { size_faces, base_product_color_face, is_multiply, shading_url },
) {
  const { url, size } = product_material_info;
  const material_img = await loadImageFromUrl(url);
  const sf = size_faces[0];
  const background_width_mm = sf.img.width / sf.px_per_1cm;
  const background_height_mm = sf.img.height / sf.px_per_1cm;
  const [background_img, shading_img] = await Promise.all([
    loadImageFromUrl(base_product_color_face.url),
    shading_url && loadImageFromUrl(shading_url),
  ]);
  const canvas = document.createElement('canvas');
  canvas.width = background_img.width;
  canvas.height = background_img.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(
    material_img,
    0,
    0,
    material_img.width * (!size ? 1 : background_width_mm / (size.width_mm * 0.1)),
    material_img.height * (!size ? 1 : background_height_mm / (size.height_mm * 0.1)),
    0,
    0,
    canvas.width,
    canvas.height,
  );
  if (is_multiply) {
    ctx.globalCompositeOperation = 'multiply';
    ctx.drawImage(background_img, 0, 0);
  }
  ctx.globalCompositeOperation = 'destination-in';
  ctx.drawImage(background_img, 0, 0);
  if (shading_url) {
    ctx.globalCompositeOperation = 'source-over';
    ctx.drawImage(shading_img, 0, 0);
  }
  return canvas.toDataURL();
}

export const bpOptionMakerMaterial = {
  migration: async (product_color) => {
    const bp_name = product_color._.base_product.name;
    // 원형 롤스티커 : 7825
    // 사각 롤스티커 : 7826
    const is_wow = [7184, 7186, 7193, 7194, 7196].includes(product_color._.base_product.id);

    if (!is_wow && ![7825, 7826].includes(product_color._.base_product.id)) return;
    await go(
      BpOptionS.getBaseProductsListBpOptionGroups(product_color._.base_product),
      find(({ name }) => name === '사이즈'),
      async (bog) => {
        const bp_options = bog._.bp_options;
        await go(
          bp_options,
          reject((bo) => bo.name.includes('균등')),
          // take1,
          // filter((bo) => bo.name.includes('90 x 55')),
          // filter((bo) => bo.name.includes('40 x 60')),
          sortByDesc((size_bp_option) => {
            const [width, height] = size_bp_option.name.match(/\d+/g);
            return parseInt(width);
          }),
          ippL,
          each(async ([i, size_bp_option]) => {
            // console.log({ ...size_bp_option._.maker_meta.value });
            // return;
            const setting = makeSettingFromBpOption(bp_name, size_bp_option, i);
            console.log('setting', setting);

            const printable_info = await makeMaterialForMakerMaterial(setting, is_wow);
            console.log('printable_info', printable_info);
            console.log(
              await NewMakerF.apiCall.createMakerMeta({
                name: 'printable_info',
                value: { ...size_bp_option._.maker_meta.value, printable_info },
                // value: { printable_info },
                // value: { printable_info: size_bp_option._.maker_meta.value },
                bp_option_id: size_bp_option.id,
              }),
            );
            // console.log('size_bp_option._.maker_meta', size_bp_option._.maker_meta);
          }),
        );
      },
    );
    console.log('done--------------');
  },
  getMakerMaterialInfo: (product_color) => {
    const selected_option_group = product_color._.selected_option_group;
    if (!selected_option_group?.bp_option_ids?.length) return;
    const selected_bp_options = BpOptionF.getSelectedOptionGroupBpOptions(selected_option_group);
    const maker_info = makeMakerMaterial(selected_bp_options);
    return maker_info;
  },
  applyMakerInfo: async (product_color) => {
    const selected_option_group = product_color._.selected_option_group;
    if (!selected_option_group?.bp_option_ids?.length) return;
    const selected_bp_options = BpOptionF.getSelectedOptionGroupBpOptions(selected_option_group);
    const maker_info = makeMakerMaterial(selected_bp_options);
    let need_render = false;
    const product_material_info = getProductMaterialInfo(selected_bp_options);
    if (maker_info) {
      need_render = true;
      const base_product_color_face = product_color._.base_product_color._.base_product_color_faces[0];
      const base_product_face = base_product_color_face._.base_product_face;
      const size_faces = base_product_face.size_faces;
      base_product_color_face.mask_url = maker_info.base_product_color_face.mask_url;
      base_product_color_face.mask2_url = maker_info.base_product_color_face.mask2_url;
      base_product_face.safe_area_url = maker_info.base_product_face.safe_area_url;
      base_product_color_face.url = maker_info.base_product_color_face.url;
      base_product_face.shading_url = maker_info.base_product_face.shading_url;
      extend(size_faces[0], maker_info.sf);

      if (product_material_info) {
        need_render = true;
        const base_product_color_face = product_color._.base_product_color._.base_product_color_faces[0];
        const base_product_face = base_product_color_face._.base_product_face;
        if (product_material_info.is_upper_layer) {
          const bpcf_url = base_product_color_face.url;
          const shading_url = await makeBpcfUrlByProductMaterialInfo(product_material_info, {
            size_faces: base_product_face.size_faces,
            base_product_color_face,
            is_multiply: false,
            shading_url: base_product_face.shading_url,
          });
          base_product_face.shading_url = shading_url;
          base_product_color_face.url = bpcf_url;
        } else {
          const bpcf_url = await makeBpcfUrlByProductMaterialInfo(product_material_info, {
            size_faces: base_product_face.size_faces,
            base_product_color_face,
            is_multiply: true,
          });
          base_product_color_face.url = bpcf_url;
        }
      }
    }
    const bpf_preview_info = getBpfPreviewInfo(selected_bp_options);
    if (bpf_preview_info) {
      const base_product_color_face = product_color._.base_product_color._.base_product_color_faces[0];
      const base_product_face = base_product_color_face._.base_product_face;
      base_product_face.preview = makeBpfPreviewInfo(selected_bp_options);
      if (product_material_info.is_upper_layer && !base_product_face.preview?.multiply) {
        /* 일단 투명 스티커 표현(코팅으로 표현함)을위해 코팅이 있는 경우는 백색 추가(no multiply)를 할때 코팅을 제거하는 방식으로 처리
         *
         * */
        base_product_face.shading_url = null;
      }
    }
    return need_render;
  },
  setProductFaces2PrintImageUrl: async (product) => {
    /* ganging_spec 과 printable_info 가 maker_meta 에 있는 제품만 가능*/
    const selected_bp_options = BpOptionF.getSelectedOptionGroupBpOptions(product._.selected_option_group);
    if (!selected_bp_options?.length) return;
    const ganging_spec = getGangingSpec(selected_bp_options);
    if (!ganging_spec) return;
    const printable_info = getPrintableInfo(selected_bp_options);
    if (!printable_info) {
      console.error('no printable_info setting');
      return;
    }
    const indexed_printable_image = await go(
      bpOptionMakerMaterial.makePrintableImageFromPrintableInfo(product),
      indexBy(({ bpf_id }) => bpf_id),
    );
    await go(
      product.product_faces2.value,
      each(async (pf) => {
        const printable_image_url = await go(indexed_printable_image[pf.bpf_id], ({ img_src }) =>
          go(
            $.uploadFileToOnlyOriginalUrl({
              original_name: 'printable_image_url',
              image_type: 'PNG',
              url: img_src,
            }),
            sel('url'),
          ),
        );
        pf.print_image_url = printable_image_url;
      }),
    );
  },
  makePrintableImageFromPrintableInfo: (product) => {
    const selected_bp_options = BpOptionF.getSelectedOptionGroupBpOptions(product._.selected_option_group);
    const { real_printing_area, cutline, material_for_maker_material } =
      getPrintableInfo(selected_bp_options);
    const with_white_background =
      !!BpOptionSelectedOptionGroupS.getWhitePrintAddBpOption(selected_bp_options);
    return go(
      getProductFaces2InMaker(),
      map(async (product_face) => {
        G.mp.maker.is_auto_print = true;
        const canvas = await makeOnlyDesignFaceCanvasByPrintArea({
          product_face,
          print_area: product_face.size_faces[0].print.px,
          width: mmToPxByDpi(real_printing_area.width_mm - real_printing_area.padding_mm * 2),
          mask_cv_obj_data: material_for_maker_material.print_mask_url
            ? go(product_face.cv_mask1, (cv_obj) => {
                const _cv_obj = JSON.parse(JSON.stringify(cv_obj));
                _cv_obj.visible = true;
                _cv_obj.src = material_for_maker_material.print_mask_url;
                return _cv_obj;
              })
            : null,
        });
        G.mp.maker.is_auto_print = false;
        if (real_printing_area.padding_mm) {
          const ctx = canvas.getContext('2d');
          const image_data = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const canvas_with_padding = document.createElement('canvas');
          canvas_with_padding.width = mmToPxByDpi(real_printing_area.width_mm);
          canvas_with_padding.height = mmToPxByDpi(real_printing_area.height_mm);
          const canvas_with_padding_ctx = canvas_with_padding.getContext('2d');
          canvas_with_padding_ctx.putImageData(
            image_data,
            (canvas_with_padding.width - canvas.width) / 2,
            (canvas_with_padding.height - canvas.height) / 2,
          );
          return {
            canvas: canvas_with_padding,
            bpf_id: product_face.bpf_id,
          };
        } else {
          return {
            canvas,
            bpf_id: product_face.bpf_id,
          };
        }
      }),
      map(({ canvas, bpf_id }) => ({
        bpf_id,
        img_src: canvas.toDataURL('image/png'),
        size: {
          w_mm: real_printing_area.width_mm,
          h_mm: real_printing_area.height_mm,
        },
        cutline,
        with_white_background,
      })),
    );
  },
};
