import { compact, each, find, go, map } from 'fxjs/es';

export const keyringChecker = ({
  selected_option_group,
  svg_product_materials,
  svg_product_id,
  base_products_bp_option_groups,
}) => {
  if (svg_product_id) {
    console.log(!!svg_product_id);
  } else if (svg_product_materials) {
    go(
      svg_product_materials,
      each(({ original_cutting_line_svg_file_url, original_svg_file_url, printable_file_url }) => {
        console.log(!!original_cutting_line_svg_file_url, !!original_svg_file_url, !!printable_file_url);
      }),
    );
  } else {
    console.log(false);
  }
  const { bp_option_ids } = selected_option_group;

  go(
    base_products_bp_option_groups,
    map((bpbog) =>
      find((bp_option) => bp_option_ids.includes(bp_option.id))(bpbog._.bp_option_group._.bp_options),
    ),
    compact,
    (bp_options) => {
      console.log(bp_options.length === base_products_bp_option_groups?.length);
      each((bo) => console.log(bo.name))(bp_options);
    },
  );
};
