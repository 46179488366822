import { each, sortBy } from 'fxjs/es';

!(function () {
  $.frame.defn_frame({
    frame_name: 'df.worker.list',
    el_class: 'worker_list',
    page_name: 'worker.list',
    is_modal: true,
    always_remove: true,
  });

  function get_teams() {
    const team_ids = [
      9, 10, 11, 12, 13, 15, 187, 19, 20, 21, 22, 23, 25, 321, 59, 252, 325, 155, 152, 8, 353, 329, 185, 74,
      153, 159, 255, 284, 285, 317, 310, 451,
    ];
    const worker_ids = [
      1696033, 1593258, 1587029, 1562187, 1529692, 1385113, 1382993, 1337320, 2162577, 2050544, 1881673,
      1963314, 2461427, 1337317, 2481856, 2652343, 2436478, 1446309, 1735168, 3459, 1221218, 1216564, 1117854,
      1102741, 1299424, 1606414, 2383619, 1686012, 1715824, 1164280, 248388, 34042, 1529500, 14345, 163663,
      401667, 1298142, 1299532, 2385838, 1649941, 472345, 1304075, 1580618, 193535, 1184090, 2535934, 1318858,
      2049734, 1072937, 1984829, 1523803, 2232571, 1685762, 1163907, 230926, 1808786, 2162396, 1554173,
      2443575, 2037891, 2193865, 2062221, 1846726, 1552582, 28563, 1620969, 1649956, 1299161, 1870890, 209381,
      29089, 1271215, 2221621, 6, 1182281, 1649944, 1554170, 1121986, 1869996, 2065940, 1833641, 122204,
      2199479, 1771924, 1877891, 1603418, 1555687, 60236, 2339549, 2409428, 2127234, 2112612, 1818624, 34333,
      2249949, 231983, 1308319, 1840464, 1818542, 69815, 100180, 1451596, 1737137, 1434370, 2738477, 3052194,
      3165288, 2331824,
    ];
    return _p.go(
      !box.sel('teams') ? $.get('/@api/worker/list') : box.sel('teams'),
      (teams) =>
        teams.sort(({ id }, { id: _id }) => {
          const left_id = team_ids.indexOf(id);
          const right_id = team_ids.indexOf(_id);
          return (
            (left_id === -1 ? team_ids.length : left_id) - (right_id === -1 ? team_ids.length : right_id)
          );
        }),
      each((t) => {
        t._.workers = t._.workers.sort(({ id }, { id: _id }) => {
          const left_id = worker_ids.includes(id) ? worker_ids.indexOf(id) : id;
          const right_id = worker_ids.includes(_id) ? worker_ids.indexOf(_id) : _id;
          return left_id - right_id;
        });
      }),
      (teams) => box.set('teams', teams),
      _p.v('teams'),
    );
  }

  G.get_teams = get_teams;

  $.frame.defn_page({
    page_name: 'worker.list',
    tabs: [
      {
        tab_name: 'worker.list',
        title: '직원 선택',
        template: __(
          function () {
            return G.get_teams();
          },
          function () {
            return pug`
            .head
              .close 닫기
              .title 직원 선택
              .done 확인
            .body
          `;
          },
        ),
        showed: function () {
          box.set('df.worker.list_disabled_workers', box.sel('df.worker.list_disabled_workers') || []);
          render_workers_list(box.sel('forced_selected_team'));
        },
        appended: __(
          $.on(
            'click',
            '.selected_workers .worker',
            __(
              _p.v('currentTarget'),
              box.sel,
              (worker) => _p.find(box.sel('teams'), (team) => worker.team_id == team.id),
              render_workers_list,
            ),
          ),
          $.on(
            'click',
            '.worker_list .worker:not(.selected)',
            __(
              _p.v('currentTarget'),
              _p.tap(box.sel, (selected_worker) => {
                if ($.attr($1('.don_frame[frame_name="df.worker.list"]'), 'frame_tag') === 'multiple') {
                  box.push('df.worker.list_selected_workers', selected_worker);
                } else {
                  box.set('df.worker.list_selected_workers', [selected_worker]);
                }
              }),
              $.closest('.team'),
              box.sel,
              render_workers_list,
            ),
          ),
          $.on(
            'click',
            '.team_list .team:not(.selected)',
            __(_p.v('currentTarget'), box.sel, render_workers_list),
          ),
          $.on(
            'click',
            '.worker .cancel',
            __(function (e) {
              e.stopPropagation();
              return _p.go(
                e.currentTarget,
                $.closest('.worker'),
                box.sel,
                function (target_worker) {
                  _p.go(
                    box.sel('df.worker.list_selected_workers'),
                    _p.reject((worker) => worker.id === target_worker.id),
                    (selected_workers) => box.set('df.worker.list_selected_workers', selected_workers),
                  );
                },
                () =>
                  render_workers_list(
                    box.sel($1('.don_frame[frame_name="df.worker.list"] .select_worker.team')),
                  ),
              );
            }),
          ),
          $.on('click', '.head .close', function (e) {
            $.frame.close();
          }),
          $.on('click', '.head .done', function (e) {
            $.frame.close(box.sel('df.worker.list_selected_workers'));
          }),
        ),
      },
    ],
  });

  const t_worker = function (selected_workers) {
    return function (worker) {
      const selected = _p.some(selected_workers, (selected_worker) => selected_worker.id === worker.id)
        ? 'selected'
        : '';
      return pug`
        .worker[worker_id="${worker.id}" _sel="./_->workers->(#${worker.id})" class=${selected}] ${worker.name}
      `;
    };
  };
  const t_selected_worker = function (disabled_workers) {
    return function (worker) {
      const is_disabled = _p.some(disabled_workers, (disabled_worker) => disabled_worker.id === worker.id);
      const disabled = is_disabled ? 'disabled' : '';
      const box_sel = is_disabled ? 'df.worker.list_disabled_workers' : 'df.worker.list_selected_workers';
      return pug`
        .worker[worker_id="${worker.id}" _sel="${box_sel}->(#${worker.id})" class="${disabled}"]
          .txt ${worker.name}
          .cancel X
      `;
    };
  };

  const t_team = function (selected_team) {
    return function (team) {
      return pug`
        .team[team_id="${team.id}" _sel="teams->(#${team.id})" class=${
        (selected_team && selected_team.id) == team.id ? 'selected' : ''
      }] ${team.name}
      `;
    };
  };

  function t_style_user(_sel, mult) {
    return (user) => pug`
      .user[user_id="${user.id}" _sel="${mult ? _sel + `(#${user.id})` : _sel}"]
        .img
        .name ${user.name}
    `;
  }

  function render_workers_list(selected_team) {
    const selected_workers = _p.go(
      _p
        .compact(box.sel('df.worker.list_disabled_workers'))
        .concat(box.sel('df.worker.list_selected_workers')),
      _p.uniq,
    );
    const selected_worker = _p.last(selected_workers);
    const df_el = $1('.don_frame[frame_name="df.worker.list"]');
    if (selected_team) {
      var team_id = selected_team.id;
    } else if (!selected_team && !selected_worker) {
      var team_id = box.sel('teams')[0].id;
    } else {
      var team_id = selected_worker.team_id;
    }
    const box_team_sel = `teams->(#${team_id})`;
    const box_team_workers_sel = `teams->(#${team_id})->_->workers`;

    return _p.go(
      ___,
      function () {
        return pug`
          .selected_workers.change
            .head 선택된 직원
            .workers
              ${_p.sum(selected_workers, t_selected_worker(box.sel('df.worker.list_disabled_workers')))}
          .select_worker.team[_sel="${box_team_sel}"]
            .worker_list.change
              .head 팀원
              .workers
                ${_p.sum(box.sel(box_team_workers_sel), t_worker(selected_workers))}
            .team_list.change
              .head 모든 팀
              .teams
                ${_p.sum(box.sel('teams'), t_team(box.sel(box_team_sel)))}
        `;
      },
      $.html_to($.find1(df_el, '.don_tab >.don_wrapper >.body')),
    );
  }

  G.df.user = {};
  G.df.user.t_style_user = t_style_user;
})();
