import { isNeedCvPrintAreaMask } from '../categorize.js';
import { plusStrokeWidth } from '../Fcanvas/stroke.js';

export const makeCvPrintAreaMaskInMaker = function (canvas, sf) {
  const last_cv_print_area = G.mp.maker.cv_print_area_mask(canvas);
  if (last_cv_print_area) canvas.remove(last_cv_print_area);

  const radius = sf.print.radius;
  const px = sf.print.px;
  const print_area_attr = _p.defaults(plusStrokeWidth(px), {
    selectable: false,
    evented: false,
    rx: radius,
    ry: radius,
    visible: isNeedCvPrintAreaMask(canvas),
    globalCompositeOperation: 'destination-in',
    fill: 'rgba(0,0,0,1)',
    _data: { cv_type: 'cv_print_area_mask', is_not_design: true },
  });

  const print_area = new fabric.Rect(print_area_attr);
  G.mp.maker.nscreen(print_area);
  canvas.add(print_area);
};
