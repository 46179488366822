import { html } from 'fxjs/es';

export const badge = ({ color = 'bk', text, type } = {}) => {
  return html`
    <span class="omp-atom__badge" data-color="${color}" data-type="${type}">
      <span class="omp-atom__badge-text">${text}</span>
    </span>
  `;
};

export const badgeSmall = ({ color = 'bk', text } = {}) => {
  return html`
    <span class="omp-atom__badge" data-mag="s" data-color="${color}">
      <span class="omp-atom__badge-text">${text}</span>
    </span>
  `;
};

export const snsBadge = ({ sns = 'instagram', url } = { sns: 'instagram' }) => {
  return html`
    <a href="${url}" target="_blank" class="omp-atom__sns-badge">
      <span class="omp-atom__sns-icon" data-sns="${sns}"></span>
      <span class="omp-atom__sns-text">SNS</span>
    </a>
  `;
};
