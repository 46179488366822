import { reject, go, head, html, range, strMap, isFunction } from 'fxjs/es';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Util/S/Function/module/OMPCoreUtilS.js';

/**
 * @typedef {object} Column
 * @property {string} key
 * @property {number} size
 * @property {string} title
 * @property {function} template
 */

/**
 * @typedef {object} TableBodyParam
 * @property {Column[]} columns
 * @property {function} separator
 * @property {object[] | array []} data
 * @property {string} empty_notice
 * @property {string} merge_column_key
 * @property {boolean} is_pagination_hidden 하단 페이지네이션이 숨기고 싶으면 `true`
 * @property {object} style
 */

/**
 * @typedef {TableBodyParam} TableParam
 * @property {number?} total_page
 * @property {number?} current_page
 */

/**
 * @typedef {object} TablePageParam
 * @property {number?} total_page
 * @property {number?} current_page
 */

export const makeRow = ({ row_klass, columns, data, style, merge_column_key, is_last, is_first }) => {
  return html`
    <tr
      ${OMPCoreUtilS.createDataSet(data?.table_data)}
      ${OMPCoreUtilS.createStyleSet(style)}
      class="omp-cell__table-body ${row_klass || ''} ${is_last ? 'is_last' : ''}"
    >
      ${strMap(({ key, size, template, url }) => {
        if (merge_column_key && merge_column_key.includes(key) && !is_first)
          return html` <td ${size ? `data-size="${size}"` : ''}>
            <div class="empty"></div>
          </td>`;

        const link = isFunction(url) && url(data);

        return html`
          <td ${size ? `data-size="${size}"` : ''}>
            ${link
              ? html` <a href="${link}" class="td-wrapper" data-key="${key}">${template(data)}</a>`
              : html` <div class="td-wrapper" data-key="${key}">${template(data)}</div>`}
          </td>
        `;
      }, columns)}
    </tr>
  `;
};

/**
 * @param {TableBodyParam} param
 */
export const paginatedTableBodyTmpl = ({
  row_klass,
  merge_column_key,
  columns,
  separator = () => {},
  data = [],
  empty_notice,
  style = { border_color: 'BK' },
}) => {
  if (typeof merge_column_key === 'string') {
    merge_column_key = [merge_column_key];
  }

  // `data` 비어있음
  if (!data.length) {
    return html`
      <tbody>
        <tr data-last="true" class="omp-cell__table-body">
          <td 
            ${columns.size ? `data-size="${columns.size}"` : ''}
            colspan=${columns.length}"
          >
            ${empty_notice}
          </td>
        </tr>
      </tbody>
    `;
  }

  // `data`가 2차원 배열 (최대 2차원까지만 지원)
  if (Array.isArray(head(data))) {
    return html`
      <tbody>
        ${strMap((data_set) => {
          return html`${go(
            /** @type {object[]} */ data_set,
            zipWithIndex,
            strMap(([index, value]) =>
              makeRow({
                row_klass,
                columns,
                data: value,
                style,
                merge_column_key,
                is_first: index === 0,
                is_last: index + 1 === data_set.length,
              }),
            ),
          )}
          ${separator(data_set)
            ? html`<tr data-last="true" class="omp-cell__table-body">
              <td colspan=${columns.length}">${separator(data_set)}</td>
            </tr>`
            : ''} `;
        }, data)}
      </tbody>
    `;
  }

  // `data`가 1차원 배열

  return html`
    <tbody>
      ${strMap(
        /** @type {[number, object]} */ ([index, row_data]) =>
          makeRow({
            row_klass,
            columns,
            data: row_data,
            style,
            is_first: index === 0,
            is_last: index + 1 === data.length,
          }),
        zipWithIndex(data),
      )}
    </tbody>
  `;
};

/**
 * @param {TablePageParam} param
 */
export const paginatedTablePageTmpl = ({ total_page, current_page, limit = 10, page_button_length = 5 }) => {
  const pages = go(
    Math.floor((current_page - 1) / page_button_length) * page_button_length,
    (page_first) => range(page_first + 1, page_first + 6),
    reject((page) => page > total_page),
  );

  const is_first = current_page === 1;
  const is_last = current_page === total_page;
  const current_offset = (current_page - 1) * limit;

  return html` <div data-offset="${current_offset}" class="omp-cell__table-pages">
    <div class="omp-cell__table-page-buttons">
      <span
        class="omp-cell__table-page-prev__first"
        data-offset="0"
        disabled="${is_first}"
        data-limit="${limit}"
      >
        ${OMPCoreAtomTmplS.doubleArrowIcon({ direction: 'left', color: is_first ? 'GY_50' : 'BK' })}
      </span>
      <span
        class="omp-cell__table-page-prev__prev"
        data-offset="${(current_page - 2) * limit}"
        data-limit="${limit}"
        disabled="${is_first}"
      >
        ${OMPCoreAtomTmplS.arrowIcon({ direction: 'left', color: is_first ? 'GY_50' : 'BK' })}
      </span>
    </div>
    ${go(
      pages,
      strMap((page) => {
        const offset = (page - 1) * limit;
        return html`<span
          data-offset="${offset}"
          data-active="${page === current_page}"
          data-limit="${limit}"
          class="omp-cell__table-page"
        >
          ${page}
        </span>`;
      }),
    )}
    <div class="omp-cell__table-page-buttons">
      <span
        class="omp-cell__table-page-prev__next"
        data-offset="${current_page * limit}"
        data-limit="${limit}"
        disabled="${is_last}"
      >
        ${OMPCoreAtomTmplS.arrowIcon({ direction: 'right', color: is_last ? 'GY_50' : 'BK' })}
      </span>
      <span
        class="omp-cell__table-page-prev__last"
        data-offset="${(total_page - 1) * limit}"
        data-limit="${limit}"
        disabled="${is_last}"
      >
        ${OMPCoreAtomTmplS.doubleArrowIcon({ direction: 'right', color: is_last ? 'GY_50' : 'BK' })}
      </span>
    </div>
  </div>`;
};

export const emptyPaginatedTablePageTmpl = () => {
  return paginatedTablePageTmpl({ total_page: 1, current_page: 1 });
};

/**
 * @param {TableParam} body
 */
export const paginatedTable = ({
  klass,
  row_klass,
  merge_column_key,
  columns,
  separator = () => {},
  data = [],
  empty_notice,
  total_page,
  current_page,
  is_pagination_hidden,
  style,
}) => {
  return html`
    <div class="omp-cell__table-wrapper ${klass || ''}">
      <table class="omp-cell__table">
        <thead>
          <tr class="omp-cell__table-header">
            ${strMap(
              ({ size, title = '' }) => html`<th ${size ? `data-size="${size}"` : ''}>${title}</th>`,
              columns,
            )}
          </tr>
        </thead>
        ${paginatedTableBodyTmpl({
          merge_column_key,
          columns,
          separator,
          data,
          empty_notice,
          style,
          row_klass,
        })}
      </table>
      ${getTablePaginationTmpl({ is_pagination_hidden, total_page, current_page, data })}
    </div>
  `;
};

const getTablePaginationTmpl = ({ is_pagination_hidden, total_page, current_page, data }) => {
  if (is_pagination_hidden) {
    return '';
  }
  if (data.length && OMPCoreUtilS.isNumeric(total_page) && OMPCoreUtilS.isNumeric(current_page)) {
    return paginatedTablePageTmpl({ total_page, current_page });
  }
  return emptyPaginatedTablePageTmpl();
};
