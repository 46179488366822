import { $attr, $closest, $find, $hide, $qs } from 'fxdom/es';
import { defaults, each, entries, find, go, groupBy, last, map, mapObject, omit, pick, sel } from 'fxjs/es';
import Swal from 'sweetalert2';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { label_task_status_change_speech_data } from '../../Stock/Holding/F/Constant/tts.js';
import { downloadAudios, playAudio } from '../../Stock/Holding/F/Function/holding.js';
import { scannerActionEvents } from '../../Stock/ScanControl/F/scanEvents.js';
import { task_status_kr } from '../S/constant.js';
import { makeCheckPointTaskTitlesHtml } from '../S/tmpl.js';
import { popupFace } from './animations.js';
import {
  activateTaskChangeMode,
  postTaskStatus,
  refreshCheckPointTableOfSelectedTitle,
  searchTasksLink,
} from './events.js';
import { printLabelCheckpoint, printMultipleLabelsCheckpoint } from './print_label.js';

const repeatWarning = (repeat_status) => {
  Swal.fire({
    position: 'center',
    target: '#body',
    icon: 'warning',
    backdrop: false,
    title: `<span style="font-size:18px;">이미 ${repeat_status} 처리된 태스크 입니다.</span>`,
    showConfirmButton: false,
    timer: 1500,
  });
};

const omit_query = ['date_events', 'titles', 'uniq_by_month', 'selected_month'];

export const defnCheckPointRoute = async function () {
  const IS_ING = !!$1('html.checkpoint.ing');
  const audio_items = await downloadAudios('task_status_audios', label_task_status_change_speech_data);
  return go($.show($1('#checkpoint')), get_check_point);

  function select_all() {
    const selecteds = $('a.fc-event[selected="true"]');
    const all = $('a.fc-event');
    if (selecteds.length != all.length) $.trigger(selecteds, 'click');
    $.trigger(all, 'click');
  }

  async function get_check_point() {
    $.don_loader_start();
    const scroll_top = $.scroll_top(window);
    const states = box.sel('states');
    const query = omit(omit_query, states);
    /* 체크포인트에서는 샵 입고 포함주문 가리기 */
    $hide($closest('.s_col', $1('input[name="goods_type_id"]')));
    await go(
      states.is_me || states.is_team || states.title?.length
        ? $.get('/@api/checkpoint' + (IS_ING ? '/ing' : ''), query)
        : [],
      groupBy(sel('title')),
      mapObject(groupBy(sel('name'))),
      entries,
      (data) => legacyHtml` ${makeCheckPointTaskTitlesHtml(data, box.sel('states'), IS_ING)} `,
      (el) => $.append($1('#checkpoint > .body'), el),
      $.on('click', 'button.go', function () {
        replace_url();
        location.reload();
      }),
      $.on('change', '.search_status2 input', function (e) {
        box.set(`states->${$.attr(e.currentTarget, 'name')}`, e.currentTarget.checked || null);
        replace_url();
      }),
      $.on('change', '.search_title input', function (e) {
        box.set('states->title', map($.val, $('#filter .search_title input[name="title"]:checked')));
        replace_url();
      }),
      $.on('change', 'input[name="collabo_type"]', function (e) {
        box.set('states->collabo_type', map($.val, $('#filter input[name="collabo_type"]:checked')));
        replace_url();
      }),
      $.on('change', 'input[name="task_status"]', function (e) {
        box.set('states->task_status', map($.val, $('#filter input[name="task_status"]:checked')));
        replace_url();
      }),
      $.on('change', 'input[name="shipping_types"]', function (e) {
        box.set('states->shipping_types', map($.val, $('#filter input[name="shipping_types"]:checked')));
        replace_url();
      }),
      $.on('change', 'input[name="marketplace_ids"]', function (e) {
        box.set('states->marketplace_ids', map($.val, $('#filter input[name="marketplace_ids"]:checked')));
        replace_url();
      }),
      $.on('change', 'input[name="goods_type_id"]', function (e) {
        box.set('states->goods_type_id', map($.val, $('#filter input[name="goods_type_id"]:checked')));
        replace_url();
      }),
      $.on('change', 'input[name="is_biz"]', function (e) {
        box.set('states->is_biz', e.currentTarget.checked ? e.currentTarget.value : void 0);
        replace_url();
      }),
      $.on('change', 'input[name="is_wow"]', function (e) {
        box.set('states->is_wow', e.currentTarget.checked ? e.currentTarget.value : void 0);
        replace_url();
      }),
      $.on('change', 'input[name="is_afreeca"]', function (e) {
        box.set('states->is_afreeca', e.currentTarget.checked ? e.currentTarget.value : void 0);
        replace_url();
      }),
      $.on('change', 'input[name="is_labeling"]', function (e) {
        box.set('states->is_labeling', e.currentTarget.checked);
        replace_url();
      }),
      $.on('change', 'input[name="merged_type"]', function (e) {
        box.set('states->merged_type', map($.val, $('#filter input[name="merged_type"]:checked')));
        replace_url();
      }),
      $.on('change', 'input[name="shopify_merged_type"]', function (e) {
        box.set(
          'states->shopify_merged_type',
          map($.val, $('#filter input[name="shopify_merged_type"]:checked')),
        );
        replace_url();
      }),
      $.on('change', 'input[name="lang_kr"]', function (e) {
        box.set('states->lang_kr', !!$1('#filter input[name="lang_kr"]:checked') || void 0);
        replace_url();
      }),
      $.on('change', 'input[name="lang_en"]', function (e) {
        box.set('states->lang_en', !!$1('#filter input[name="lang_en"]:checked') || void 0);
        replace_url();
      }),
      $.on('change', 'input[name="lang_jp"]', function (e) {
        box.set('states->lang_jp', !!$1('#filter input[name="lang_jp"]:checked') || void 0);
        replace_url();
      }),
      $.on('change', 'select[name="order_by"]', function (e) {
        box.set('states->order_by', $.val(e.currentTarget) || void 0);
        replace_url();
      }),
      $.on('change', 'select[name="shipping_type"]', function (e) {
        box.set('states->shipping_type', $.val(e.currentTarget) || void 0);
        replace_url();
      }),
      $.on('change', 'select[name="important"]', function (e) {
        box.set('states->important', $.val(e.currentTarget) || void 0);
        replace_url();
      }),
      $.on('change', 'select[name="internal_important"]', function (e) {
        box.set('states->internal_important', $.val(e.currentTarget) || void 0);
        replace_url();
      }),
      $.on('change', '.search_status input', function (e) {
        $.don_loader_start();
        window.location.href =
          window.location.pathname +
          '?' +
          $.param(
            defaults(
              {
                status: $.val(e.currentTarget),
              },
              pick(['important', 'is_cancel', 'due_ats'], box.sel('states')),
            ),
          );
      }),
      $.on('click', '.tasks_group .group_name', function (e) {
        const group_name = $.attr(e.currentTarget, 'val');
        const select_list = go(
          [...box.sel('TASKS_GROUP'), ...box.sel('TASKS_GROUP_OUTSOURCING')],
          find((g) => g.name === group_name),
          sel('values'),
        );
        each((input) => {
          const value = (($.val(input) || '') + '').replace(/\s/g, '').toUpperCase();
          input.checked = !!find((select) => {
            if (group_name.indexOf('외주') === 0) {
              return value.indexOf(select.toUpperCase()) > -1;
            }
            if (group_name == 'PB') {
              return value.indexOf(select.toUpperCase()) > -1;
            }
            return value == select.toUpperCase();
          }, select_list);
        }, $('.search_title input'));
        box.set('states->title', map($.val, $('#filter .search_title input[name="title"]:checked')));
        replace_url();
      }),
      $.on('click', 'td.label_print_btn', printLabelCheckpoint),
      $.on('click', 'svg.print_labels', printMultipleLabelsCheckpoint),
      $.on('click', '.refresh_btn', (e) => refreshCheckPointTableOfSelectedTitle(e.currentTarget)),
      $.on('click', '.toggle_task_complete_mode', activateTaskChangeMode),
      $.on('click', '.t_status a', searchTasksLink),
      $.on('click', '.status_switch input[type="radio"]', async (e) => {
        const ct = e.currentTarget;
        const prev_checked_el = go(ct, $closest('.rating'), $find('input.checked'));
        const status_tobe = ct.value;
        if (prev_checked_el.value === status_tobe) {
          repeatWarning(`${task_status_kr[status_tobe]}`);
          audio_items && playAudio(audio_items[`task_${status_tobe}_again`]);
        } else {
          const task_id = ct.getAttribute('task_id');
          const projection_id = ct.getAttribute('projection_id');
          try {
            $.don_loader_start();
            const { task } = await postTaskStatus({
              task_id,
              status_tobe,
              projection_id,
            });
            $.don_loader_end();
            audio_items && playAudio(audio_items[`task_${task.status}`]);
            popupFace($qs('#body'), task.status);
            await refreshCheckPointTableOfSelectedTitle(
              go(ct, $closest('.task_title'), $find('.refresh_btn')),
              false,
            );
          } catch (e) {
            console.error(e);
            audio_items && playAudio(audio_items.task_process_fail);
            ct.checked = false;
            prev_checked_el.checked = true;
            Swal.fire({
              position: 'center',
              target: '#body',
              icon: 'error',
              backdrop: false,
              title: `<span style="font-size:18px;">에러가 났어요.ᕙ(⇀‸↼‶)ᕗ<br>태스크 상태를 변경에 실패했습니다.<br>개발자에게 문의주세요.</span>`,
            });
          }
        }
        $.don_loader_end();
      }),
      () =>
        scannerActionEvents(document, async ({ projection_id }) => {
          const task_completion_mode_selected_el = $qs('.task_title.selected', document);
          if (!task_completion_mode_selected_el) {
            // 조회 모드
            window.open(
              `${location.origin}/projection/list?status=all&order_by=DESC&at_opt=ordered&p_id=${projection_id}&product_mode=up_list`,
              '_blank',
            );
          } else {
            // 태스크 완료 처리 모드
            const target_el = go(
              task_completion_mode_selected_el,
              $find(`input[type='radio'][value='completed'][projection_id='${projection_id}']`),
            );
            if (target_el) {
              const prev_checked_el = go(target_el, $closest('.rating'), $find('input.checked'));
              if (prev_checked_el.value === 'completed') {
                audio_items && playAudio(audio_items.task_completed_again);
                repeatWarning(`${task_status_kr.completed}`);
              } else {
                try {
                  const { task } = await postTaskStatus({
                    task_id: $attr('task_id', target_el),
                    status_tobe: 'completed',
                    projection_id,
                  });
                  target_el.checked = true;
                  prev_checked_el.checked = false;
                  audio_items && playAudio(audio_items[`task_${task.status}`]);
                  popupFace($qs('#body'), task.status);
                  await refreshCheckPointTableOfSelectedTitle(
                    $find('.refresh_btn', task_completion_mode_selected_el),
                    false,
                  );
                } catch (e) {
                  console.error(e);
                  audio_items && playAudio(audio_items.task_process_fail);
                  target_el.checked = false;
                  prev_checked_el.checked = true;
                  await $.alert(
                    `에러가 났어요.ᕙ(⇀‸↼‶)ᕗ<br>태스크 상태를 변경 하지 못하였어요.<br>개발자에게 문의주세요.`,
                  );
                }
              }
            } else {
              audio_items && playAudio(audio_items.task_null);
              await $.alert('태스크 목록에 없는 라벨입니다.');
            }
          }
        }),
      function () {
        if (IS_ING) return;
        const calendar = jQuery('#calendar');
        calendar.fullCalendar({
          allDaySlot: false,
          contentHeight: 700,
          height: 'auto',
          locale: 'ko',
          titleFormat: 'YYYY-MM',
          defaultDate:
            box.sel('states->uniq_by_month').find((ud) => ud == moment().format('YYYY-MM')) ||
            last(box.sel('states->uniq_by_month').sort()),
          header: {
            right: 'prev,next select_all search',
            left: 'title',
          },
          customButtons: {
            select_all: {
              text: '전체',
              click: function () {
                select_all();
              },
            },
          },
          plugins: ['interaction', 'dayGrid', 'timeGrid'],
          events: [],
          viewRender: async function (e) {
            if (
              !box.sel('states->uniq_by_month').find((date) => {
                return date == e.title;
              })
            ) {
              box.sel('states->uniq_by_month').push(e.title);
              $.don_loader_start();
              const date_events = await $.get('/@api/checkpoint/date_events', {
                ...omit(omit_query, query),
                selected_month: e.title,
              });
              window.box.sel('states->date_events').push(...date_events);
              $.don_loader_end();
            }
            if (calendar) calendar.fullCalendar('renderEvents', window.box.sel('states->date_events'));
          },
          eventRender: function (e, el) {
            $.attr(el[0], {
              selected: e.date_event.selected,
              is_complete: e.date_event.is_complete,
            });
            $.html(
              $.find1(el[0], '.fc-title'),
              legacyHtml`
                <div class="head">
                  총 ${UtilS.commify(e.date_event.total_count)}건 / (취소
                  ${UtilS.commify(e.date_event.canceled_count)}건)
                </div>
                <div class="sub sub1">미완료 주문 ${UtilS.commify(e.date_event.ing_count)}건</div>
                <div class="sub sub2">상품 ${UtilS.commify(e.date_event.ing_quantity_count)}개</div>
              `,
            );
          },
          eventClick: function (e) {
            const selected = !$.attr($1(this), 'selected');
            $.attr($1(this), 'selected', selected);
            e.date_event.selected = selected;
            const due_ats = box.sel('states->due_ats');
            if (selected) due_ats.push(e.date_event.start);
            else due_ats.splice(due_ats.indexOf(e.date_event.start), 1);
            box.sel('states->due_ats').sort();
            replace_url();
          },
        });
        calendar.fullCalendar('removeEvents');
        calendar.fullCalendar('renderEvents', window.box.sel('states->date_events'));
      },

      function () {
        $.scroll_top2(window, scroll_top);
      },
    );
    $.don_loader_end();
  }
};
function replace_url() {
  window.history.replaceState(
    null,
    null,
    window.location.pathname + '?' + $.param(omit(omit_query, box.sel('states'))),
  );
}
