import { $setCss } from 'fxdom/es';
import { go, tap } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorKeyringFreePCMuiF } from '../Mui/module/VectorEditorKeyringFreePCMuiF.js';

export const makeKeyring = ({
  title,
  price,
  frame_position: { top: frame_position_top, height: frame_position_height },
  prev_right_panel_el,
  onFrameHiding,
  art_board: { width: art_board_width, height: art_board_height, shape_path_data: art_board_shape_path_data },
  empty_template_el,
  preProcess,
  postProcess,
}) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorKeyringFreePCMuiF.frame, (frame, page, [tab]) => {
      const frame_appending = frame.appending;
      frame.appending = (frame_el) => {
        $setCss(['z-index', '1'])(frame_el);
        return frame_appending.call(frame, frame_el);
      };
      const frame_hiding = frame.hiding;
      frame.hiding = (frame_el, val) =>
        go(onFrameHiding(frame_el, val), () => frame_hiding.call(frame, frame_el, val));
      const frame_closed = frame.closed;
      frame.closed = (frame_el, data) =>
        go(
          frame_closed.call(frame, frame_el, data),
          tap(() => resolve(data)),
        );

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_title = title;
        tab_el.__mp_price = price;
        tab_el.__mp_frame_position_top = frame_position_top;
        tab_el.__mp_frame_position_height = frame_position_height;
        tab_el.__mp_prev_right_panel_el = prev_right_panel_el;
        tab_el.__mp_art_board_width = art_board_width;
        tab_el.__mp_art_board_height = art_board_height;
        tab_el.__mp_art_board_shape_path_data = art_board_shape_path_data;
        tab_el.__mp_empty_template_el = empty_template_el;
        tab_el.__mp_postProcess = postProcess;
        tab_el.__mp_preProcess = preProcess;

        if (typeof G.mp.maker.vectorEditorTabAppendingPc === 'function')
          G.mp.maker.vectorEditorTabAppendingPc(tab_el);
        return tab_appending.call(tab, tab_el);
      };
    }).catch(reject),
  );
