import paper from 'paper';
import { ITEM_NAME, PAPER_NAME } from '../../S/Constant/constants.js';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { $find, $qs } from 'fxdom/es';

export function prepareCanvas({ tab_el, canvas }) {
  const $art_board = $qs('.artboard', tab_el);
  const { width, height } = $art_board.getBoundingClientRect();

  const canvas_el = canvas ?? $find('canvas.work-canvas', tab_el);
  DfImageEditorF.setCanvasSize({
    canvas_el,
    width,
    height,
  });

  return canvas_el;
}

export function resizeAndRedrawCanvas({ paper_scope, width, height }) {
  paper_scope.view.setViewSize(width, height);
}

export function preparePaperSetup({ canvas_el, is_window_attach }) {
  const paper_scope = DfImageEditorF.createPaperScope({ canvas_el, is_window_attach });
  DfImageEditorF.createLayerStructureAndStack({ paper_scope });
  return paper_scope;
}

export function addPrintableRasterRectangleBound({ paper_scope, target_layer, margin = 0 }) {
  const exist_printable_raster_bound_rectangle = DfImageEditorF.getPrintableRasterBound({ target_layer });

  if (exist_printable_raster_bound_rectangle) {
    exist_printable_raster_bound_rectangle.remove();
  }

  const printable_raster_bounds = DfImageEditorF.getPrintableRaster({ paper_scope }).bounds;

  const { topLeft, bottomRight } = printable_raster_bounds;

  const rectangle = new paper_scope.Shape.Rectangle({
    from: topLeft.add(-margin),
    to: bottomRight.add(margin),
  });
  rectangle.name = ITEM_NAME.printable_raster_bound;
  target_layer.addChild(rectangle);
}

export function getPrintableRasterBound({ target_layer }) {
  return DfImageEditorF.getItemInLayer({
    layer: target_layer,
    condition: { name: ITEM_NAME.printable_raster_bound },
  });
}

export function setPaperScopeToTabEl({ tab_el, paper_name, paper_scope }) {
  paper_scope.tab_el = tab_el;
  if (tab_el.tab_opt.paper_scopes == null) {
    tab_el.tab_opt.paper_scopes = { [paper_name]: paper_scope };
  } else {
    tab_el.tab_opt.paper_scopes[paper_name] = paper_scope;
  }
}

export function getTabElPaperScope({ tab_el }) {
  return tab_el.tab_opt.paper_scopes;
}

export function getPaperScopeFromTabEl({ tab_el, paper_name = PAPER_NAME.default }) {
  const paper_scope = tab_el.tab_opt.paper_scopes[paper_name];
  if (paper_scope == null) {
    throw new Error(`Not exist paper scope name ${paper_name}`);
  }
  paper_scope.activate();
  return paper_scope;
}

export function destroyAllPaperScope({ tab_el }) {
  const paper_scopes = tab_el.tab_opt.paper_scopes;

  if (paper_scopes == null) return;

  Object.values(paper_scopes).forEach((paper_scope) => {
    const projects = paper_scope.projects;
    [...projects].forEach((prj) => {
      prj.clear();
      prj.remove();
    });

    paper_scope = null;
  });

  tab_el.tab_opt.paper_scopes = null;
}

export function setBackgroundTransparent({ paper_scope }) {
  paper_scope.project.view.background = new paper_scope.Color(0, 0, 0, 0);
}

export function createPaperScope({ canvas_el, is_auto_insert = false, is_window_attach = false }) {
  const paper_scope = new paper.PaperScope();
  paper_scope.setup(canvas_el);
  paper_scope.settings.insertItems = is_auto_insert;
  paper_scope.settings.handleSize = 0;

  setBackgroundTransparent({ paper_scope });

  if (is_window_attach) {
    window.paper_scope = paper_scope;
    window.layers = paper_scope.project.layers;
  }
  return paper_scope;
}

export function setViewToBoundsFit({ paper_scope, bounds, margin_percent = 10 }) {
  const view = paper_scope.view;
  const MARGIN = 1 + margin_percent / 100;

  view.center = getCenterPointFromBounds({ paper_scope, bounds });

  const scaleX = view.bounds.width / (bounds.width * MARGIN);
  const scaleY = view.bounds.height / (bounds.height * MARGIN);
  const scale = Math.min(scaleX, scaleY);

  view.scale(scale);
  view.position = view.center;
}

export function getAllLayers({ paper_scope }) {
  return paper_scope.project.layers;
}

export function getLayersUniteBounds({ paper_scope }) {
  const bounds = {
    top: null,
    left: null,
    width: null,
    height: null,
  };

  if (Array.isArray(paper_scope.project.layers)) {
    paper_scope.project.layers.forEach((layer) => {
      if (Array.isArray(layer.children)) {
        layer.children.forEach((item) => {
          if (item.bounds) {
            ['top', 'left'].forEach((position) => {
              if (bounds[position] == null) {
                bounds[position] = item.bounds[position];
              } else {
                bounds[position] = Math.min(bounds[position], item.bounds[position]);
              }
            });
            ['width', 'height'].forEach((size) => {
              if (bounds[size] == null) {
                bounds[size] = item.bounds[size];
              } else {
                bounds[size] = Math.max(bounds[size], item.bounds[size]);
              }
            });
          }
        });
      }
    });
  }
  return bounds;
}

function getCenterPointFromBounds({ paper_scope, bounds }) {
  const { top, left, width, height } = bounds;
  const center_x = left + width / 2;
  const center_y = top + height / 2;
  return new paper_scope.Point(center_x, center_y);
}

export function activateToolLayer({ paper_scope }) {
  const tool_layer = DfImageEditorF.getToolLayer({ paper_scope });
  tool_layer.bringToFront();
  tool_layer.visible = true;
}

export function deactivateToolLayer({ paper_scope }) {
  const tool_layer = DfImageEditorF.getToolLayer({ paper_scope });
  tool_layer.sendToBack();
  tool_layer.visible = false;
}

export function activateBpcfPaperScope({ tab_el }) {
  const bpcf_paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el, paper_name: PAPER_NAME.bcpf });
  if (bpcf_paper_scope == null) {
    throw new Error(`Not exist bpcf_paper_scope`);
  }
  bpcf_paper_scope.activate();
}

export function activateDefaultPaperScope({ tab_el }) {
  const default_paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  if (default_paper_scope == null) {
    throw new Error(`Not exist default paper_scope`);
  }
  default_paper_scope.activate();
}

export function addDataToItem({ item, data }) {
  item.data = { ...item.data, ...data };
}

export function getDataFromItem({ item, key }) {
  return key ? item.data[key] : item.data;
}

export function removeDataFromItem({ item, key }) {
  key ? delete item.data[key] : (item.data = null);
}

export function destroyItem(item) {
  if (item == null) {
    throw new Error(`Not exist item to destroy`);
  }
  item.remove();
  item = null;
}

export function getColorDistance(hex1, hex2) {
  const rgb1 = hexToRgb(hex1);
  const rgb2 = hexToRgb(hex2);

  return Math.sqrt(
    Math.pow(rgb2.r - rgb1.r, 2) + Math.pow(rgb2.g - rgb1.g, 2) + Math.pow(rgb2.b - rgb1.b, 2),
  );
}

export function hexToRgb(hex) {
  if (hex.length === 4) {
    hex = hex.replace(/^#(.)(.)(.)$/, '#$1$1$2$2$3$3');
  }

  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  return { r, g, b };
}

export function hexToVector(hex) {
  if (hex.length === 4) {
    hex = hex.replace(/^#(.)(.)(.)$/, '#$1$1$2$2$3$3');
  }

  const r = parseInt(hex.substring(1, 3), 16) / 255;
  const g = parseInt(hex.substring(3, 5), 16) / 255;
  const b = parseInt(hex.substring(5, 7), 16) / 255;

  return [r, g, b, 1.0];
}

export function paperColorToHex({ paper_color, isWithoutAlpha }) {
  if (paper_color == null) {
    return null;
  }
  const r = Math.round(paper_color.red * 255);
  const g = Math.round(paper_color.green * 255);
  const b = Math.round(paper_color.blue * 255);
  const a = Math.round(paper_color.alpha * 255);

  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');
  const hexA = a.toString(16).padStart(2, '0');

  return '#' + hexR + hexG + hexB + (isWithoutAlpha ? '' : hexA);
}

export function rgbaHexToCssColor({ rgba_hex }) {
  const r = parseInt(rgba_hex.substr(1, 2), 16);
  const g = parseInt(rgba_hex.substr(3, 2), 16);
  const b = parseInt(rgba_hex.substr(5, 2), 16);

  if (rgba_hex.length === 7) {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  } else if (rgba_hex.length === 9) {
    const a = parseInt(rgba_hex.substr(7, 2), 16) / 255;
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
  } else {
    throw new Error(`Invalid hex color ${rgba_hex}`);
  }
}

export function getComplementaryColor({ paper_color }) {
  return (
    '#' +
    paper_color.components
      .map((c) => {
        const complementary_value = Math.round((1 - c) * 255).toString(16);
        return complementary_value.length === 1 ? '0' + complementary_value : complementary_value;
      })
      .join('')
  );
}

export function paperColorToCssColor({ paper_color, isWithoutAlpha }) {
  if (paper_color == null) return;
  return rgbaHexToCssColor({ rgba_hex: paperColorToHex({ paper_color, isWithoutAlpha }) });
}

export function getItemById({ paper_scope, id }) {
  return paper_scope.project.getItem({ id });
}

export function getItemInLayerById({ layer, id }) {
  return layer.getItem({ id });
}

export function groupItemsToSinglePath({ paper_scope, group }) {
  if (group instanceof paper.Group) {
    let single_item;
    if (group.children.length === 1) {
      single_item = group.reduce({});
    } else {
      let united_compound_path = new paper_scope.CompoundPath();
      group.children.forEach((child) => {
        if (child instanceof paper.Path || child instanceof paper.CompoundPath) {
          united_compound_path = united_compound_path.unite(child);
        }
      });
      single_item = united_compound_path;
    }
    return single_item;
  } else {
    return group;
  }
}

export function isPath({ item }) {
  return item instanceof paper.Path;
}

export function isCompoundPath({ item }) {
  return item instanceof paper.CompoundPath;
}

export function getAllPaths({ item }) {
  if (isPath({ item })) {
    return [item];
  } else {
    return item.getItems({ class: paper.Path });
  }
}

export function getAllVisiblePaths({ item }) {
  return item.getItems({
    class: paper.Path,
    visible: true,
    parent: { visible: true },
    layer: { visible: true },
  });
}
