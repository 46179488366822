export const ZOOM = {
  scale_delta: 0.05,
  bias: 80,
};

export const MARGIN = {
  RASTER: 50,
};

export const PAPER_NAME = {
  default: 'DEFAULT',
  bcpf: 'BPCF',
};

export const ITEM_NAME = {
  stroke: 'STROKE',
  stroke_offset: 'STROKE-OFFSET',
  spot: 'SPOT',
  erase: 'ERASE',
  brush_path: 'BRUSH-PATH',
  brush_circle: 'BRUSH-CIRCLE',
  printable_raster: 'PRINTABLE_RASTER',
  printable_raster_bound: 'PRINTABLE_RASTER_BOUND',
  rStrokeRaster: 'R-STROKE-RASTER',
  rStrokeBounds: 'R-STROKE-BOUNDS',
  stroke_bound: 'STROKE_BOUND',
};

export const LAYER_NAME = {
  tool: 'layer-tool',
  spot: 'layer-spot',
  erase: 'layer-erase',
  vStroke: 'layer-vstroke',
  rStroke: 'layer-rstroke',
  default: 'layer-default',
};

export const TOOL_NAME = {
  selection: 'selection',
  background: 'background',
  erase: 'erase',
  scale: 'scale',
  rStroke: 'raster-stroke',
  vStroke: 'vector-stroke',
  spot: 'spot',
  file: 'file',
};

export const CONTROL_NAME = {
  position: 'position',
  execute: 'execute',
  upload: 'upload',
};

export const SUB_TOOL_NAME = {
  erase: {
    brush: 'brush',
    square: 'square',
    circle: 'circle',
    free: 'free',
  },
};

export const BRUSH_PROPERTY_NAME = {
  size: 'size',
  hardness: 'hardness',
};

export const BACKGROUND_PROPERTY_NAME = {
  color: 'color',
};

export const ERASE_PROPERTY_NAME = {
  brush: 'brush',
};

export const SPOT_PROPERTY_NAME = {
  area: 'area',
};

export const SCALE_PROPERTY_NAME = {
  size: 'size',
};

export const STROKE_PROPERTY_NAME = {
  color: 'color',
  width: 'width',
  offset: 'offset',
};

export const R_STROKE_PROPERTY_NAME = {
  color: 'color',
  thickness: 'thickness',
  alpha_threshold: 'alpha-threshold',
};

export const FILE_PROPERTY_NAME = {
  press_type: 'press-type',
};

export const RATIO = {
  brush_size_to_fixed_distance: 15,
  stroke_item_size_to_stroke_width: 150,
  raster_size_to_spot_stroke_width: 300,
  raster_size_to_zoom_limit: 100,
  view_to_scale_tolerance: 100,
};

export const STROKE_STYLE = {
  v_stroke_initial_width: 4,
  r_stroke_initial_thickness: 2,
};

export const GROUP_NAME = {
  painting: 'brush-painting',
};

export const DATA_SET_KEY = {
  interval_ids: 'interval_ids',
  is_brush: 'is_brush',
  is_erase_brush: 'is_erase_brush',
  r_stroke_painter: 'r_stroke_painter',
  design_top_change: 'design_top_change',
};

export const ALPHA_THRESHOLD = {
  vStroke: 70.0,
  rStroke: 80.0,
  spot: 0.0,
};

export const SVG_EL_ITEM = {
  cut: '칼선',
  raster: '디자인',
  info: '시안 정보',
};
