import { html, strMap } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

/**
 * Create a product content component with the specified options.
 *
 * @param {Object} options - The options for the product content.
 * @param {string} [options.thumbnail_url=''] - The URL of the product content thumbnail (optional, default: '').
 * @param {string} [options.product_name=''] - The name of the product (optional, default: '').
 * @param {string} [options.product_minimum_price_text=''] - The minimum price text of the product (optional, default: '').
 * @param {boolean} [options.is_wish=false] - Whether the product is in the wish list (optional, default: false).
 * @param {number} [options.wish_count=0] - The count of wishes for the product (optional, default: 0).
 * @param {Object} [options.link] - The link options (optional).
 * @param {boolean} [options.link.is_new_tab=false] - Whether the link should be opened in a new tab (optional, default: false).
 * @param {string} [options.link.href=''] - The URL the product content should link to (optional, default: '').
 * @returns {string} The HTML markup for the product content component.
 */
export const contentProduct = ({
  base_product_id,
  thumbnail_url = '',
  product_name = '',
  product_minimum_price_text = '',
  is_wish = false,
  wish_count = 0,
  link: { is_new_tab = false, href = '' } = {},
} = {}) => {
  return html`
    <span class="omp-cell__content-product-wrapper">
      <a class="omp-cell__content-product" href="${href}" ${is_new_tab ? 'target="_blank"' : ''}>
        <span class="omp-cell__content-product-thumbnail">
          ${thumbnail_url
            ? html`<img src="${UtilImageS.getResized70Webp(thumbnail_url, 52)}" alt="${product_name}" />`
            : ''}
        </span>
        <span class="omp-cell__content-product-text">
          ${product_name ? html`<span class="omp-cell__content-product-name">${product_name}</span>` : ''}
          ${product_minimum_price_text
            ? html`<span class="omp-cell__content-product-minimum-price">${product_minimum_price_text}</span>`
            : ''}
        </span>
      </a>
      <span
        class="omp-cell__content-product-heart omp-cell__bp-like"
        data-active="${is_wish}"
        data-base_product_id="${base_product_id}"
        data-wish_count="${wish_count}"
      >
        ${OMPCoreAtomTmplS.heartCountButton({ active: is_wish, badge: wish_count, badge_max: 9999 })}
      </span>
    </span>
  `;
};

/**
 * Create a large content component with the specified options.
 *
 * @param {Object} options - The options for the large content.
 * @param {string} [options.id] - The ID of the large content.
 * @param {string} [options.content_title=''] - The title of the large content (optional, default: '').
 * @param {string} [options.content_description=''] - The description of the large content (optional, default: '').
 * @param {string} [options.thumbnail_url=''] - The URL of the large content thumbnail (optional, default: '').
 * @param {Object} [options.link] - The link options (optional).
 * @param {boolean} [options.link.is_new_tab=false] - Whether the link should be opened in a new tab (optional, default: false).
 * @param {string} [options.link.href=''] - The URL the large content should link to (optional, default: '').
 * @param {Array} [options.products=[]] - The products associated with the large content (optional, default: []).
 * @returns {string} The HTML markup for the large content component.
 */

export const contentLarge = ({
  id,
  content_title = '',
  content_description = '',
  thumbnail_url = '',
  link: { is_new_tab = false, href = '' } = {},
  products = [],
} = {}) => {
  return html`
    <div id="${id}" class="omp-cell__content-large">
      <div class="omp-cell__content-large-thumbnail-area">
        <a class="omp-cell__content-large-thumbnail" href="${href}" ${is_new_tab ? 'target="_blank"' : ''}>
          ${thumbnail_url
            ? html`<img src="${UtilImageS.getResized70Webp(thumbnail_url, 534)}" alt="${content_title}" />`
            : ''}
        </a>
      </div>
      ${content_title ? html`<div class="omp-cell__content-large-title">${content_title}</div>` : ''}
      ${content_description
        ? html`<div class="omp-cell__content-large-description">${content_description}</div>`
        : ''}
      <div class="omp-cell__content-large-product">${strMap(contentProduct, products.slice(0, 3))}</div>
    </div>
  `;
};

/**
 * Create a content component with the specified options.
 *
 * @param {Object} options - The options for the content.
 * @param {string} [options.id] - The ID of the content.
 * @param {string} [options.content_title=''] - The title of the content (optional, default: '').
 * @param {string} [options.content_description=''] - The description of the content (optional, default: '').
 * @param {string} [options.thumbnail_url=''] - The URL of the content thumbnail (optional, default: '').
 * @param {Object} [options.link] - The link options (optional).
 * @param {boolean} [options.link.is_new_tab=false] - Whether the link should be opened in a new tab (optional, default: false).
 * @param {string} [options.link.href=''] - The URL the content should link to (optional, default: '').
 * @returns {string} The HTML markup for the content component.
 */
export const content = ({
  id,
  content_title = '',
  content_description = '',
  thumbnail_url = '',
  link: { is_new_tab = false, href = '' } = {},
} = {}) => {
  return html`
    <a id="${id}" class="omp-cell__content" href="${href}" ${is_new_tab ? 'target="_blank"' : ''}>
      <span class="omp-cell__content-thumbnail">
        ${thumbnail_url
          ? html`<img src="${UtilImageS.getResized70Webp(thumbnail_url, 400)}" alt="${content_title}" />`
          : ''}
      </span>
      <span class="omp-cell__content-text" href="${href}" ${is_new_tab ? 'target="_blank"' : ''}>
        ${content_title ? html`<span class="omp-cell__content-title">${content_title}</span>` : ''}
        ${content_description
          ? html`<span class="omp-cell__content-description">${content_description}</span>`
          : ''}
      </span>
    </a>
  `;
};
