import { each, equals2, go } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { VectorEditorTopMenuPCF } from '../../../../../TopMenu/PC/F/Function/module/VectorEditorTopMenuPCF.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';

export const handleGridStickerEditorSelect =
  ({ tab_el }) =>
  ({ detail: { selected_grid_id } }) => {
    go(
      tab_el,
      $findAll(`.top_menu_container .top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: new Set([
            VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
            VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
          ]),
        }),
      ),
    );
    tab_el.__mp_selected_grid_id = selected_grid_id;

    go(
      tab_el,
      $findAll(`.right_container .right_wrapper .panel_container .panel_wrapper`),
      each((wrapper_el) => (wrapper_el.dataset.is_show = `${equals2('select')(wrapper_el.dataset.panel)}`)),
    );
  };

export const handleGridStickerEditorUnselect =
  ({ tab_el }) =>
  () => {
    go(
      tab_el,
      $findAll(`.top_menu_container .top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          deactivate_ids: new Set([
            VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
            VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
          ]),
        }),
      ),
    );
    tab_el.__mp_selected_grid_id = null;

    go(
      tab_el,
      $findAll(`.right_container .right_wrapper .panel_container .panel_wrapper`),
      each((wrapper_el) => (wrapper_el.dataset.is_show = `${equals2('home')(wrapper_el.dataset.panel)}`)),
    );
  };
