import { each, go } from 'fxjs/es';
import { getRealFcanvass } from '../../../../../Maker/F/getSth.js';
import { getCvDesigns } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { setRequestBgRemoval } from './setRbgr.js';
import { NewMakerCvObjectCvImageF } from './module/NewMakerCvObjectCvImageF.js';

export const makeRequestBgRemovalFalse = () => {
  let is_one_time = false;
  go(
    getRealFcanvass(),
    each((fcanvas) => {
      const cv_designs = getCvDesigns(fcanvas._objects);
      G.mp.maker.cv_objects_deep_each(cv_designs, (cv_obj) => {
        if (NewMakerCvObjectCvImageF.getRequestBgRemoval(cv_obj)) {
          if (!is_one_time) {
            $.alert(T('maker::Message::This product is does not allow background remove request.'));
            is_one_time = true;
          }
          setRequestBgRemoval(cv_obj, false);
        }
      });
    }),
  );
};
