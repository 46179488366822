import { $delegate, $find } from 'fxdom/es';
import { go } from 'fxjs/es';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';

/**
 * @param {HTMLElement} el
 * @return {HTMLElement}
 */
export const delegateFileDownload = (el) => {
  return $delegate('click', '.omp-order-product__printing-file-name', ({ currentTarget }) => {
    const title_el = go(currentTarget, $find('.omp-order-product__printing-file-title'));

    if (title_el) {
      const { fileUrl, fileName } = title_el.dataset;
      if (fileUrl) {
        OMPCoreUtilF.downloadFileAppCompatible({ url: fileUrl, file_name: fileName });
      }
    }
  })(el);
};
