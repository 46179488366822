import { find, filter, head, go, sel } from 'fxjs/es';

export function getTracedDesign({ pf, file }) {
  /*
   * Mission: face 내의 어떤 design 의 수치 값을 가지고 shibori 를 계산할 것인지 추적 -> 계산
   * 추적 로직
   *      1. face 의 design 중 visible 한 것들 filter
   *      2. design._data.original_cid === file.cid -> 추적 성공
   *         - 복붙을 통해 메이커에서 디자인이 생성된 경우 같은 것이 배열일 수 있음. (corner case) -> head
   *      3. design.cid === file.cid -> 추적 성공
   *      4. design._data.file_id === file.id -> 추적 성공
   *      4. 추적 실패 -> shibori 계산 포기
   * */
  const visible_designs = pf.designs.filter((d) => d.visible);

  return (
    head(
      filter((d) => {
        return d._data?.original_cid != null && file?.cid != null && d._data.original_cid === file.cid;
      }, visible_designs),
    ) ||
    find((d) => d.cid === file.cid, visible_designs) ||
    find((d) => d._data?.file_id === file.id, visible_designs)
  );
}

export function calculatePrintableFileShiboriCm({ pf, bp_size_id, file }) {
  if (pf.start_name === '') return null;
  const face_info = find((face) => Number(face.base_product_size_id) === Number(bp_size_id), pf.size_faces);
  if (!face_info) return null;

  const traced_design = getTracedDesign({ pf, file });

  if (!traced_design) return null;

  return calculateShiboriFromDesign({
    face_info,
    design: traced_design,
    is_include_editing_offset: !traced_design._data.original_cid, // 시안 불러오기로 저장된 파일
    offset_top_by_editing: file?.offset_top_by_editing,
  });
}

function calculateShiboriFromDesign({
  face_info,
  design,
  is_include_editing_offset,
  offset_top_by_editing = 0,
}) {
  // 시안 파일이 어떤 디자인으로 편집되었는지 추적에 성공한 경우 -> 시보리 계산 적용
  const DPC_300 = 300 / 2.54;
  const { print, start_point_cm, px_per_1cm } = face_info;

  if (start_point_cm <= 0) {
    return null;
  }

  const shibori_to_print_area_top = start_point_cm * DPC_300; // cm 를 px 로 환산
  const design_top = design.top;
  const print_area_top = print.px.top;

  /* px_per_1cm : 물리적인 1cm 가 메이커 fabric canvas 의 px 공간과 어떻게 mapping 되는지 정보
   *               fabric 의 px 정보들은 300 DPI 로 물리적 cm 수치로 변경되어야 하면 안되고 px_per_1cm 정보를 통해 환산되어야 함.
   *               이것을 다시 300 DPI px 수치로 환산해서 300 DPI 공간 상의 척도로 모든 계산 scale 을 일치시킴.
   * */
  const print_area_top_to_design_top = ((design_top - print_area_top) / px_per_1cm) * DPC_300;

  const A = Number(shibori_to_print_area_top); // px @300 DPI
  const B = Number(print_area_top_to_design_top); // px @300 DPI

  /*
   *  - is_include_editing_offset (편집시 발생한 offset top 을 계산에 포함시킬 건지 여부 판단)
   *    1. 디자인이 인쇄 시안 파일로부터 (file_id 가 있음) 만들어졌다면 고려 하지 않음
   *    2. 디자인이 원본 디자인으로부터 만들어졌다면 (file_id 가 없음) 편집시 발생한 추가 offset top 을 계산에 포함시킴.
   * */
  let shibori_cm;
  if (is_include_editing_offset) {
    const C = Number(offset_top_by_editing); // px @300 DPI
    /* 메이커 정보에 편집 offset top 까지 고려 */
    shibori_cm = (A + B + C) / DPC_300; // cm
  } else {
    /* 메이커 정보로만 계산 */
    shibori_cm = (A + B) / DPC_300; // cm
  }
  return shibori_cm;
}

export function calculateShiboriForDesign({ pf, base_product_size_id }) {
  const pf_design_size = pf?.design_size;

  if (pf_design_size?.from_print_top_to_design_cm != null) {
    const from_shibori_to_print_area_top = getFromShiboriToPrintAreaTop({ pf, base_product_size_id });
    if (from_shibori_to_print_area_top == null) return;
    return from_shibori_to_print_area_top + pf_design_size.from_print_top_to_design_cm;
  }

  if (pf_design_size?.distance_cm_from_rib != null) {
    return pf_design_size.distance_cm_from_rib;
  }

  return null;
}

function getFromShiboriToPrintAreaTop({ pf, base_product_size_id }) {
  return go(
    pf.size_faces,
    find((sf) => sf.base_product_size_id === base_product_size_id),
    sel('start_point_cm'),
  );
}
