import { html } from 'fxjs/es';

export const jpFirst = () => html`
  <div class="terms_body">
    <div class="title">
      <h1>プライバシーポリシー</h1>
      <p>
        個人情報保護方針<br />ティクーンジャパン株式会社は、ネット上での取引及びサービス利用にあたり、お客様からお預かりする個人情報の取り扱いには細心の注意を払い適切に管理し、個人情報の保護に努めてまいります。<br />ここでは、次の項目について下記に記述します。
      </p>
      <p>
        個人情報の収集について<br />
        利用目的について<br />
        個人情報等の取り扱いについて<br />
        個人情報保護方針について<br />
        お客様が当社およびサービスの利用規約に同意会員登録、サービスを利用し、下記に記述している内容に同意していただいたものとみなします。
      </p>
    </div>
    <div class="body">
      <div class="text_body">
        <div class="paragraph">
          <h3 id="service1">[ 情報の収集及び利用 ]</h3>
          <ol>
            <li>
              ▶ お客様から提供される情報
              <ol>
                <li>
                  お客様から注文などの
                  申し込みを受ける時に、氏名（フリガナを含む）、住所、電話番号(携帯電話・FAXを含む)、電子メールアドレス、携帯メールアドレス、会社名・団体名、その他連絡先に関する情報、生年月日情報など、お客様から当社に提供される一切の情報
                </li>
              </ol>
            </li>
            <br />
            <li>
              ▶ サービスの利用に関連して取得される情報
              <ol>
                <li>
                  お客様が当社またはサービス提供者が提供する商品または、プレゼント応募、その他の取引を申し込まれた場合の、お客様を識別できる情報と繋がる状況での取引履歴に関する情報
                </li>
                <li>当社からのメールマガジンなどの購読に関する情報</li>
                <li>当社のサービス中ポイント購入および獲得に関する情報</li>
                <li>
                  当社の電話や電子メールその他の手段により、当社またはサービス提供者に質問する、アンケートやキャンペーン、イベント、サービスを評価するなどを行った場合の、その発言または記載内容に関する情報
                </li>
              </ol>
            </li>
            <br />
            <li>
              ▶ アクセスしたことを契機として機械的に取得される情報
              <ol>
                <li>
                  お客様のコンピュータがインターネットに接続するときに使用されるIPアドレス、携帯端末の機体識別に関する情報
                </li>
                <li>
                  当社の運営するウェブサイトにアクセスしたことを契機として取得された、お使いのブラウザの種類・バージョン、オペレーティングシステムと当社のサービス利用に必要なその他アプリケーションプログラムの種類とバージョン、お客様の閲覧されたページ(URL)、閲覧した日時、表示または検索された商品などに関する情報
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service2">[ 利用目的について ]</h3>
          <ol>
            <li>
              ▶ お客様が当社の会員としてサービスを利用する場合
              <ol>
                <li>
                  お客様が当社の会員としてログイン時およびログイン後における本人認証、会員情報の自動表示提供
                </li>
                <li>
                  お客様が商品または予約・購入・配送・代金決済とお客様からのお問合せへの対応、当社からお客様へのお問合せ、関連するアフターサービス、その他取引遂行にあたって必要な業務
                </li>
              </ol>
            </li>
            <br />
            <li>
              ▶ 当社のサービス案内またはマーケティングなど
              <ol>
                <li>お客様向け各種メールマガジンなどの情報提供</li>
                <li>サービスについての電子メール、郵便、電話などによる情報提供</li>
                <li>お客様によるサービスの利用を分析し、新規サービスの開発や既存サービスの改善をするため</li>
                <li>
                  アンケート、キャンペーン、
                  評価掲示板などの意見・情報の交換サービスに関連して、お客様と連絡をとるため
                </li>
                <br />
                <li>個人情報等の取り扱いについて</li>
                <li>当社は、個人情報保護法に従い、個人情報を取り扱います。</li>
                <li>
                  当社は、お客様の個人情報を必要な範囲で当社およびサービスで共同利用して以下の項目に該当する場合を除き、当社およびサービス以外の第三者に提供または開示いたしません。
                </li>
                <br />
                <li>ご本人の同意がある場合</li>
                <li>その他法令にもとづき開示・提供を求められた場合</li>
                <li>
                  当社およびサービスの上でのお客様の行為がご利用規約に反しており、
                  当社の権利、サービス等を保護するために必要と求められる場合
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div class="paragraph">
          <h3 id="service3">[ 個人情報保護方針について ]</h3>
          <ol>
            <li>
              ▶ 基本方針
              <ol>
                <li>
                  当社およびサービスは、お客様の個人情報保護については重大な社会的責任と認識しており、個人情報保護に関して継続的に改善に取り組むことを宣言します。
                </li>
              </ol>
            </li>
            <br />
            <li>
              ▶ 活動指針
              <ol>
                <li>
                  個人情報の収集、利用、提供を適正に行い、個人情報の廃棄に関しては万全を期するようにいたします。
                </li>
                <li>
                  個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏洩などを防止するため適切な安全対策を行います。
                </li>
                <li>
                  個人情報保護方針を役員及び従業員に周知させるとともに、
                  当社およびサービスのサイトに掲載いたします。
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="option">
      <button class="done">閉じる</button>
    </div>
  </div>
`;
