import { $closest, $delegate, $find, $setVal, $val } from 'fxdom/es';
import { find, go } from 'fxjs/es';
import { message_collections } from '../S/message_collections.js';

export const msgSelectBoxEvent = (el) =>
  go(
    el,
    $delegate('change', '.msg_select_box', (e) => {
      const selected_msg_select_box_id = go(e.currentTarget, $closest('.msg_select_box'), $val);
      const textarea_el = go(e.currentTarget, $closest('.control'), $find('textarea'));
      if (!textarea_el) return;
      const projection = go(e.delegateTarget, $find('.prj_item'), box.sel);
      const projection_id = projection?.id;
      const client_name = projection && (projection?.orderer_name || '고객');
      const worker_name = box.sel('is_user->name');
      const orderer_name = box.sel('df/projection/detail->projection->orderer_name');
      const ups = box.sel('df/projection/detail->projection->_->ups');

      go(
        message_collections,
        find(({ id }) => id == selected_msg_select_box_id),
        (message_collection) => {
          $setVal(
            message_collection
              ? message_collection.content({ projection_id, worker_name, client_name, orderer_name, ups })
              : '',
          )(textarea_el);
        },
      );
    }),
  );
