import { $setCss } from 'fxdom/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { OMPCoreUtilF } from './module/OMPCoreUtilF.js';

// frame.el_class 에 no-background 를 추가 시 배경 색 사라집니다.
export const openFrameFit = async (frame, cb) => {
  return MuiF.openFrame(frame, async (frame, page, tabs) => {
    if (cb instanceof Promise) {
      await cb(frame, page, tabs);
    } else {
      cb(frame, page, tabs);
    }

    frame.hide_frame_button_type = '-';
    page.hide_frame_button_type = 'X';

    page.title = page.title ? page.title : frame.title;
    frame.el_class = `${frame.el_class} omp-frame-fit`;

    if (!OMPCoreUtilF.isMobile()) {
      const _appending = frame.appending;
      const _showed = frame.showed;

      frame.appending = (frame_el) => {
        $setCss({ opacity: 0 }, frame_el);
        _appending(frame_el);
      };

      frame.showed = (frame_el) => {
        $setCss({ opacity: 1 }, frame_el);
        _showed(frame_el);
      };
    }
    frame.is_modal = !OMPCoreUtilF.isMobile();
  });
};
