import { flatten, go, ippL, strMap } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { getObjectForBpsSizeItem, product_editor_size_init } from './bps_fs.js';

function makeBpsOnlyItemPcHtml(bpss, base_product_size_id, sold_out_size_ids) {
  const size = product_editor_size_init(bpss);
  return go(
    bpss,
    ippL,
    strMap(function ([i, bps]) {
      const style = {
        width: `width: ${size.width}px;`,
        margin: (i + 1) % size.one_line_items_length == 0 ? 'margin-right : 0px;' : '',
      };
      const id = bps.id;
      const short_name = bps['short_name' + _en];
      const selected = bpss.length === 1 || bps.id === base_product_size_id ? 'selected' : '';
      const sold_out = sold_out_size_ids.includes(bps.id) ? 'sold_out' : '';
      return legacyHtml`
        <li
          class="item ${selected} ${sold_out}"
          _id="${id}"
          _sel="./(#${id})"
          style="${style.width} ${style.margin}"
        >
          <span>${short_name}</span>
        </li>
      `;
    }),
  );
}

export function makeBpsSizeItemHtml(bpss, base_product_size_id, sold_out_size_ids) {
  return legacyHtml`
    <div class="size section" length="${bpss.length}">
      <div class="head">${T('Size')}</div>
      <div class="body">
        <ul _sel="maker->product_color->_->base_product->_->base_product_sizes">
          ${makeBpsOnlyItemPcHtml(bpss, base_product_size_id, sold_out_size_ids)}
        </ul>
      </div>
      <div class="one_size">${T('One size')}</div>
    </div>
  `;
}

function makeBpsOnlyItemMobileHtml(bpss, sold_out_size_ids) {
  return strMap((bps) => {
    const { id, sold_out, selected, short_name } = getObjectForBpsSizeItem(bps, sold_out_size_ids);
    return legacyHtml`
      <li class="item ${selected} ${sold_out}" _id="${id}" _sel="./(#${id})">
        <span>${short_name}</span>
      </li>
    `;
  }, bpss);
}

export function makeBpsSizeItemMobileHtml(bpsss, sold_out_size_ids) {
  const bpss_length = flatten(bpsss).length;
  return legacyHtml`
    <div class="product_editor_ev">
      <div class="size section" length="${bpss_length}">
        <div class="head">Size</div>
        <div class="body">
          <ul class="swiper-container" _sel="maker->product_color->_->base_product->_->base_product_sizes">
            <div class="swiper-wrapper">
              ${strMap((bpss) => {
                return legacyHtml`
                  <div class="swiper-slide">${makeBpsOnlyItemMobileHtml(bpss, sold_out_size_ids)}</div>
                `;
              }, bpsss)}
            </div>
            <div class="swiper-pagination"></div>
          </ul>
        </div>
        <div class="one_size">One size</div>
      </div>
    </div>
  `;
}
