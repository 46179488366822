import { getBaseProductFacesInMaker, getProductColorInMaker } from '../../../../../Maker/F/getSth.js';
import { getSizeFace } from '../../../../Checker/S/Function/getSizeFace.js';

export function cmToPxByCurrentBpfBps({ cm, base_product_face_id }) {
  const product_color = getProductColorInMaker();
  const sf = getSizeFace({
    base_product_faces: getBaseProductFacesInMaker(),
    base_product_size_id: product_color.base_product_size_id,
    base_product_face_id,
  });
  return sf.px_per_1cm * cm;
}
