import { equals2 } from 'fxjs/es';

export const handleListItemClick =
  ({ single_sticker_editor, el }) =>
  () => {
    const is_selected = single_sticker_editor.getSelectedEls().has(el);
    if (is_selected) {
      single_sticker_editor.unselectEl(el);
    } else {
      single_sticker_editor.selectEl(el);
    }
  };

export const handleListItemVisibleContainerClick =
  ({ single_sticker_editor, el }) =>
  (event) => {
    event.stopPropagation();

    const is_visible = !equals2('none')(el.getAttributeNS(null, 'display'));
    if (is_visible) {
      single_sticker_editor.hideEl(el);
    } else {
      single_sticker_editor.showEl(el);
    }
  };

export const handleListItemLockContainerClick =
  ({ single_sticker_editor, el }) =>
  (event) => {
    event.stopPropagation();

    const is_locked = single_sticker_editor.getLockedEls().has(el);
    if (is_locked) {
      single_sticker_editor.unlockEl(el);
    } else {
      single_sticker_editor.lockEl(el);
    }
  };
