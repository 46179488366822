import { VectorEditorAcrylicFigureFreeMobileMuiS } from '../../S/Mui/module/VectorEditorAcrylicFigureyFreeMobileMuiS.js';
import { CommonNS, EditorNS } from '@marpple/sticker-editor';
import { $find, $findAll, $off, $on } from 'fxdom/es';
import { each, go, noop, tap } from 'fxjs/es';
import { VectorEditorAcrylicFigureFreeMobileF } from '../Function/module/VectorEditorAcrylicFigureFreeMobileF.js';
import { VectorEditorFontF } from '../../../../../Font/F/Function/module/VectorEditorFontF.js';
import axios from 'axios';
import { VectorEditorAcrylicFigureFreeMobileConstantS } from '../../S/Constant/module/VectorEditorAcrylicFigureFreeMobileConstantS.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorAcrylicFigureFreeMobileMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  async appending(tab_el) {
    tab_el.__mp_is_prevent_touch_move = false;
    tab_el.__mp_handleWindowTouchMove = null;
    tab_el.__mp_acrylic_figure_editor = null;
    tab_el.__mp_art_board_width = tab_el.__mp_art_board_width ?? 0;
    tab_el.__mp_art_board_height = tab_el.__mp_art_board_height ?? 0;
    tab_el.__mp_art_board_shape_path_data = tab_el.__mp_art_board_shape_path_data ?? `M 0 0 Z`;
    tab_el.__mp_empty_template_el = tab_el.__mp_empty_template_el ?? null;
    tab_el.__mp_stand_leg_ground_size_delta = tab_el.__mp_stand_leg_ground_size_delta ?? 4;
    tab_el.__mp_stand_leg_ground_position_delta = tab_el.__mp_stand_leg_ground_position_delta ?? 1;
    tab_el.__mp_stand_leg_ground_min_width = tab_el.__mp_stand_leg_ground_min_width ?? 0;
    tab_el.__mp_stand_leg_ground_max_width = tab_el.__mp_stand_leg_ground_max_width ?? 0;
    tab_el.__mp_stand_leg_ground_width = tab_el.__mp_stand_leg_ground_width ?? 0;
    tab_el.__mp_stand_leg_ground_height = tab_el.__mp_stand_leg_ground_height ?? 0;
    tab_el.__mp_stand_leg_notch_round = tab_el.__mp_stand_leg_notch_round ?? 1;
    tab_el.__mp_stand_leg_notch_width = tab_el.__mp_stand_leg_notch_width ?? 0;
    tab_el.__mp_stand_leg_notch_height = tab_el.__mp_stand_leg_notch_height ?? 0;
    tab_el.__mp_preProcess = tab_el.__mp_preProcess ?? null;
    tab_el.__mp_postProcess = tab_el.__mp_postProcess ?? null;
    tab_el.__mp_fonts = null;

    go(
      tab_el,

      tap(
        $findAll(`.header button.cancel`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleHeaderCancelButtonClick())),
      ),
      tap(
        $findAll(`.header button.next`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleHeaderNextButtonClick({ tab_el }))),
      ),
      tap(
        $findAll(`.header .icons [data-icon_type="more"] input`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleHeaderMoreIconClick({ tab_el }))),
      ),
      tap(
        $findAll('.header .icons [data-icon_type="layers"] input'),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleHeaderLayersIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.blank_plate[data-target="top_menu"]`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuBlankPlateClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.HISTORY_RESET.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuHistoryResetIconClick())),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.HISTORY_UNDO.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuHistoryUndoIconClick())),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.HISTORY_REDO.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuHistoryRedoIconClick())),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.DELETE.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuDeleteIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.FORWARD.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuForwardIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.BACKWARD.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuBackwardIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.GROUP.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuGroupIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.UNGROUP.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuUngroupIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.LOCK.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuLockIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.UNLOCK.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuUnlockIconClick({ tab_el }))),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.FLIP_HORIZONTAL.ID}"] input[type="button"]`,
        ),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuFlipHorizontalIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.FLIP_VERTICAL.ID}"] input[type="button"]`,
        ),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuFlipVerticalIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID}"] input[type="button"]`,
        ),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuMoveToLeftEndIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileF.handleTopMenuMoveToHorizontalCenterIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID}"] input[type="button"]`,
        ),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuMoveToRightEndIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.MOVE_TO_TOP_END.ID}"] input[type="button"]`,
        ),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleTopMenuMoveToTopEndIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileF.handleTopMenuMoveToVerticalCenterIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorAcrylicFigureFreeMobileConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileF.handleTopMenuMoveToBottomEndIconClick({ tab_el }),
          ),
        ),
      ),

      tap(
        $findAll(`.footer.none[data-icon_type="add"]`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterNoneAddIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.footer.object[data-icon_type="duplicate"`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterObjectDuplicateIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer.object[data-icon_type="delete"`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterObjectDeleteIconClick({ tab_el })),
        ),
      ),

      tap(
        $findAll(`.footer.path[data-icon_type="set_color"`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterPathSetColorIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="set_stroke"`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterPathSetStrokeIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="edit_path"`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterPathEditPathIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="duplicate"`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterPathDuplicateIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="delete"`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterPathDeleteIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.footer.shape[data-icon_type="set_color"`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterShapeSetColorIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer.shape[data-icon_type="set_stroke"`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterShapeSetStrokeIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer.shape[data-icon_type="duplicate"`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterShapeDuplicateIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer.shape[data-icon_type="delete"`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterShapeDeleteIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.footer.text[data-icon_type="decorate_text"`),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileF.handleFooterTextDecorateTextIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.text[data-icon_type="open_keyboard"`),
        each(
          $on(
            'click',
            VectorEditorAcrylicFigureFreeMobileF.handleFooterTextOpenKeyboardIconClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.text[data-icon_type="duplicate"`),
        each(
          $on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterTextDuplicateIconClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.footer.text[data-icon_type="delete"`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleFooterTextDeleteIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.blank_plate[data-target="bottom_menu"]`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleBottomMenuBlankPlateClick({ tab_el }))),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="text"]`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleBottomMenuTextIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="path"]`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleBottomMenuPathIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="shape"]`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleBottomMenuShapeIconClick({ tab_el }))),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="image"]`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileF.handleBottomMenuImageIconClick({ tab_el }))),
      ),

      tap(
        $findAll(`.editor_container`),
        each(
          $on(
            '@mp/sticker-editor/select',
            VectorEditorAcrylicFigureFreeMobileF.handleAcrylicFigureEditorSelect({ tab_el }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/unselect',
            VectorEditorAcrylicFigureFreeMobileF.handleAcrylicFigureEditorUnselect({ tab_el }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/lock',
            VectorEditorAcrylicFigureFreeMobileF.handleAcrylicFigureEditorLock({ tab_el }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/unlock',
            VectorEditorAcrylicFigureFreeMobileF.handleAcrylicFigureEditorUnlock({ tab_el }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/add',
            VectorEditorAcrylicFigureFreeMobileF.handleAcrylicFigureEditorAdd({ tab_el }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/remove',
            VectorEditorAcrylicFigureFreeMobileF.handleAcrylicFigureEditorRemove({ tab_el }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/change-selector',
            VectorEditorAcrylicFigureFreeMobileF.handleAcrylicFigureEditorChangeSelector({ tab_el }),
          ),
        ),
      ),
    );

    tab_el.__mp_fonts = await go(
      axios.get(`/${T.lang}/@api/maker/fonts`),
      (res) => res?.data,
      (fonts) => fonts ?? null,
    );
    await VectorEditorFontF.loadBaseFonts();
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  async appended(tab_el) {
    tab_el.__mp_handleWindowTouchMove = VectorEditorAcrylicFigureFreeMobileF.handleWindowTouchMove({
      tab_el,
    });
    $on('touchmove', tab_el.__mp_handleWindowTouchMove, { passive: false })(window);

    const acrylic_figure_editor = new EditorNS.AcrylicEditorNS.MobileNS.AcrylicMobileEditor({
      window,
      document,
      touch_margin: 6,
    });
    tab_el.__mp_acrylic_figure_editor = acrylic_figure_editor;
    acrylic_figure_editor.initDOM();
    acrylic_figure_editor.initEditor();
    acrylic_figure_editor.setArtBoardSize({
      width: tab_el.__mp_art_board_width,
      height: tab_el.__mp_art_board_height,
    });
    acrylic_figure_editor.setArtBoardShapePath({ path_data: tab_el.__mp_art_board_shape_path_data });
    acrylic_figure_editor.setArtBoardClipPathShapePath({ path_data: tab_el.__mp_art_board_shape_path_data });
    acrylic_figure_editor.turnOnArtBoardClipPath();
    const foreground_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
    if (tab_el.__mp_empty_template_el != null) {
      foreground_el.appendChild(tab_el.__mp_empty_template_el);
    }
    acrylic_figure_editor.setForeground(foreground_el);

    const editor_container_el = $find('.editor_container')(tab_el);
    if (editor_container_el != null) {
      acrylic_figure_editor.appendTo(editor_container_el);
    }

    try {
      await tab_el.__mp_preProcess?.({ addEl: ({ el }) => acrylic_figure_editor.addEl(el) });
    } catch (error) {
      console.error(error);
      const message =
        error?.__mp_alert_message ??
        T(
          'modules::VectorEditor::AcrylicFigure::Free::Mobile::error_message::reopen_prev_acrylic_figure_error',
        );
      $.alert(message).catch(noop);
    }
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {
    tab_el.__mp_is_prevent_touch_move = true;
  }, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    if (tab_el.__mp_acrylic_figure_editor != null) {
      const editor = tab_el.__mp_acrylic_figure_editor;

      editor.fitSize();
      const view_box_vo = editor.calculateViewBoxFitToArtBoard({
        padding_in_vcs: { top: 16 + 48, right: 16, bottom: 16 + 144, left: 16 },
      });
      editor.setViewBox(view_box_vo);
    }
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, _v) {
    tab_el.__mp_is_prevent_touch_move = false;
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, _v) {
    if (tab_el.__mp_handleWindowTouchMove != null) {
      $off('touchmove', tab_el.__mp_handleWindowTouchMove, { passive: false })(window);
    }

    if (tab_el.__mp_acrylic_figure_editor != null) {
      const editor = tab_el.__mp_acrylic_figure_editor;
      editor.destroy();
    }

    tab_el.__mp_is_prevent_touch_move = false;
    tab_el.__mp_handleWindowTouchMove = null;
    tab_el.__mp_acrylic_figure_editor = null;
    tab_el.__mp_art_board_width = 0;
    tab_el.__mp_art_board_height = 0;
    tab_el.__mp_art_board_shape_path_data = `M 0 0 Z`;
    tab_el.__mp_empty_template_el = null;
    tab_el.__mp_stand_leg_ground_size_delta = 0;
    tab_el.__mp_stand_leg_ground_position_delta = 0;
    tab_el.__mp_stand_leg_ground_min_width = 0;
    tab_el.__mp_stand_leg_ground_max_width = 0;
    tab_el.__mp_stand_leg_ground_width = 0;
    tab_el.__mp_stand_leg_ground_height = 0;
    tab_el.__mp_stand_leg_notch_round = 1;
    tab_el.__mp_stand_leg_notch_width = 0;
    tab_el.__mp_stand_leg_notch_height = 0;
    tab_el.__mp_preProcess = null;
    tab_el.__mp_postProcess = null;
    tab_el.__mp_fonts = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
