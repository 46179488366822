import { $delegate } from 'fxdom/es';
import { SearchHistoryApiHelper } from '../../History/F/Function/SearchHistoryApiHelper.js';
import { OMPSearchS } from '../../S/Function/module/OMPSearchS.js';

/**
 * 추천 검색어 F 이벤트
 * @param {HTMLElement} el
 * @return {HTMLElement}
 */
export const delegateRecommendedKeywordEvent = (el) => {
  return $delegate('click', `.omp-search__keyword-btn`, async (e) => {
    const { text: keyword } = e.currentTarget.dataset;
    await SearchHistoryApiHelper.post([keyword]);
    location.href = OMPSearchS.getSearchResultUrl(keyword);
  })(el);
};
