import { $closest, $data, $delegate, $find, $findAll, $qs, $removeClass, $setData } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MarstagramEditorMuiS } from '../../S/Mui/module/MarstagramEditorMuiS.js';
import {
  adjustmentEvents,
  appendGLCanvasToDOM,
  createBoxFitCanvasImage,
  createWebGLCanvas,
  cropCanvasEvents,
  cropEvents,
  easterEggListener,
  filterEvents,
  generatePresetThumbnails,
  initialCanvasRender,
  initialEditorTrigger,
  is_apple_mobile_device,
  isSafari,
  loadImage,
  makeRangeSliderInput,
  multipleClickEventHandler,
  setGLCanvasSizeClone,
  setupImageEditorProgramToGL,
  setupViewProgramToGL,
} from '../Function/module/index.js';

export const tab = {
  ...MarstagramEditorMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {
    return tab;
  }, // template 함수 인자에 들어갈 값 또는 tab 엘리먼트에 데이터를 저장할 값을 리턴하는 함수,

  appending(tab_el$) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    makeRangeSliderInput($findAll('.sliders input[type="range"]', tab_el$));
  },
  showing(tab_el$) {},
  async showed(tab_el$) {
    const getReuseCanvas = (el$) => {
      try {
        return $data(el$);
      } catch (e) {
        return null;
      }
    };
    const maker$ = $qs('#maker');
    const gls = getReuseCanvas(maker$);
    const $canvas_box = $find('.canvas_box', tab_el$);
    const is_mp_worker_policy = !!box()?.is_user?._?.policies?.mp_worker_policy;

    // 시작할 이미지 로드
    $.don_loader_start();
    const image = await loadImage(tab_el$.tab_opt.image_url);

    const minimum_crop_length = 80;
    const preset_thumb_size = G.is_pc_size() ? 72 : 64;
    const resize_canvas_for_preset_filter_thumbnail = createBoxFitCanvasImage(
      image,
      true,
      'cover',
      preset_thumb_size,
      preset_thumb_size,
      true,
      6.0,
      640,
    );
    const preset_filter_gl = go(
      gls?.preset_filter_gl
        ? gls.preset_filter_gl
        : createWebGLCanvas({
            antialias: false,
            powerPreference: 'default',
            premultipliedAlpha: true,
            preserveDrawingBuffer: true,
            depth: false,
          }),
      setGLCanvasSizeClone(resize_canvas_for_preset_filter_thumbnail),
      appendGLCanvasToDOM('preset_filter_gl_canvas', $canvas_box, true),
      setupImageEditorProgramToGL(resize_canvas_for_preset_filter_thumbnail, true),
    );
    generatePresetThumbnails(tab_el$, preset_filter_gl, preset_thumb_size);
    // 컨테이너 사이즈 fit 되도록 이미지 리사이징 캔버스 생성 (크기와 퀄리티 조정)
    // 워커는 resize되지 않도록 막음. (pc에서만 진행될 것이라 생각하고 max texture 사이즈가 모바일보다 커서 4K까지 허용)
    const resize_canvas_image_editor = createBoxFitCanvasImage(
      image,
      !is_mp_worker_policy,
      'contain',
      $canvas_box.clientWidth,
      $canvas_box.clientHeight,
      true,
      10.0,
      2400,
      Math.min(preset_filter_gl.getParameter(preset_filter_gl.MAX_TEXTURE_SIZE), 4096),
    );
    // 이미지 에디터 렌더링 webGL 컨텍스트 생성
    let flip_required = false;
    if (!is_apple_mobile_device()) {
      flip_required = !isSafari();
    }

    const image_editor_gl = go(
      gls?.image_editor_gl
        ? gls.image_editor_gl
        : createWebGLCanvas({
            alpha: true,
            antialias: false,
            powerPreference: 'high-performance',
            premultipliedAlpha: false,
            preserveDrawingBuffer: true,
            // depth: false,
          }),
      setGLCanvasSizeClone(resize_canvas_image_editor),
      appendGLCanvasToDOM('image_editor_gl_canvas', $canvas_box, true),
      setupImageEditorProgramToGL(resize_canvas_image_editor, flip_required),
    );

    // 뷰 렌더링 webGL 컨텍스트 생성
    const view_gl = go(
      gls?.view_gl
        ? gls.view_gl
        : createWebGLCanvas({
            alpha: true,
            antialias: false,
            powerPreference: 'high-performance',
            premultipliedAlpha: false,
            preserveDrawingBuffer: false,
            // depth: false,
          }),
      setGLCanvasSizeClone(resize_canvas_image_editor, minimum_crop_length),
      appendGLCanvasToDOM('view_gl_canvas', $canvas_box),
      setupViewProgramToGL(image_editor_gl.canvas),
    );

    // 최초 렌더링
    initialCanvasRender(tab_el$, image_editor_gl, view_gl);

    // EVENTs ////////////////////////////////////////////////////////////////////////

    filterEvents(tab_el$, image_editor_gl, view_gl);
    adjustmentEvents(tab_el$, image_editor_gl, view_gl);
    cropEvents(tab_el$, image_editor_gl, view_gl);
    cropCanvasEvents(tab_el$, image_editor_gl, view_gl);

    initialEditorTrigger(tab_el$);
    $.don_loader_end();
    go($closest('.don_frame', tab_el$), $removeClass('hide_frame'));

    const image_tag = tab_el$.tab_opt.image_tag;
    const color_space = image_tag['Color Space'];
    if (color_space && color_space.value === 'CMYK') {
      $.alert(`${T(`image_editor::cmyk_alert`)}`);
    }

    //Easter_Egg
    go(
      tab_el$,
      $delegate(
        'click',
        G.is_pc_size() ? '.marstagram_title' : '.adjustment_select_icon',
        multipleClickEventHandler(7, easterEggListener),
      ),
    );

    if (!gls) {
      $setData({ view_gl, image_editor_gl, preset_filter_gl }, maker$);
    }
  },
  // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수
  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {}, // tab이 삭제된 후 울리는 함수
  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
