import { html } from 'fxjs/es';
import { makeReviewListMainHtml, makeReviewListStarsHtml } from '../../../Review/List/S/tmpl.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

// <span className="count">(${UtilS.commify(review_total.count)})</span>
export const makeReviewsHtml = ({ reviews, review_total, query }) => {
  if (Number(review_total.count) == 0) {
    return '';
  }
  return html`
    <div class="toggle_cont tg_reviews" is_open="true">
      <div class="tg_head">
        <span class="tit"
          >${G.collabo_type === 'creator'
            ? T('마플 개인 커스텀 주문 리뷰를 참고해보세요!')
            : T('product_detail::Reviews')}</span
        >
        <span class="review_title">
          <span class="count">(${UtilS.commify(review_total?.count)})</span>
          <span class="score_percent"> ${makeReviewListStarsHtml(review_total?.avg)} </span>
        </span>
        <span class="btn"></span>
      </div>
      <div class="tg_body">${makeReviewListMainHtml(reviews, review_total, query)}</div>
    </div>
  `;
};
