import { html } from 'fxjs/es';

const mui_ref_path = '/modules/VectorEditor/Keyring/Free/Mobile/CuttingLineAuto/S/Mui/tab.js';
/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  title: '', // tab button의 title
  selected: false, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  makeData(tab) {}, // template 함수 인자에 들어갈 값, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
  template(_data) {
    return html`
      <div class="editor_container"></div>
      <div class="header">
        <button class="prev" type="button">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::CuttingLineAuto::template::prev_button')}
        </button>
        <span class="title">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::CuttingLineAuto::template::header_title')}
        </span>
        <button class="next" type="button">
          ${T('modules::VectorEditor::Keyring::Free::Mobile::CuttingLineAuto::template::next_button')}
        </button>
      </div>
      <div class="footer">
        <div class="description">
          <span class="description_icon"></span>
          <span class="description_text">
            ${T('modules::VectorEditor::Keyring::Free::Mobile::CuttingLineAuto::template::description')}
          </span>
        </div>
        <div class="controls">
          <div class="control control_auto">
            <button class="control_auto_button control_auto_button_minus" type="button"></button>
            <span class="control_auto_label"></span>
            <button class="control_auto_button control_auto_button_plus" type="button"></button>
          </div>
          <div class="control control_manual">
            <button class="control_manual_button" type="button">
              ${T(
                'modules::VectorEditor::Keyring::Free::Mobile::CuttingLineAuto::template::edit_manual_button',
              )}
            </button>
          </div>
        </div>
      </div>
    `;
  }, // 탭에 들어갈 html 을 그리는 함수
};
