import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { filter, go, html, some, sortBy, strMap, keys } from 'fxjs/es';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';
import { getCustomerOrderCrewInhouse, isInhouseProjection, isShipToInhouse } from './inhouse.js';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';
import { makeInhouseTogglerButtonHtml } from '../../../Inhouse/S/Tmpl/tmpl.inhouse.projection_list.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { convertShippingTypeToLabel } from '../S/constants.js';

export const isParcelShippingType = (shipping_type) =>
  ['parcel', 'combined', 'partition'].includes(shipping_type);

export const makeProjectionShipItemTmpl = (projection, shipping) => {
  const is_parcel_type = isParcelShippingType(shipping.type);
  const is_domestic_prj = projection.lang === 'kr';

  const is_parent_and_has_combined_projections =
    projection.id === projection.projection_id &&
    go(
      projection._.projection._.projections,
      filter((p) => p.id !== projection.id && p.id !== p.projection_id),
      some((p) => p.type === 'combined'),
    );

  const is_inhouse = isInhouseProjection({ prj: projection });
  const is_ship_to_inhouse = isShipToInhouse({ prj: projection });
  const is_show_inhouse = is_inhouse && is_ship_to_inhouse;
  const coci = getCustomerOrderCrewInhouse({ prj: projection });
  const is_inhouse_box_created = UtilObjS.isNotEmpty(coci?._?.inhouse_shipping_box);

  const is_oversea_lgl = !is_domestic_prj && shipping._?.shipping_company?.oversea_3pl_company === 'lgl';

  const is_domestic_parcel_cj =
    is_domestic_prj &&
    is_parcel_type &&
    shipping?.shipping_company_id === 'cj' &&
    shipping?.waybill_number != null &&
    shipping?.waybill_number !== '';

  const lgl_shipping_countries = box.sel('lgl_shipping_countries') ?? [];

  return html`
    <section
      class="shipping_item"
      shipping_id="${shipping.id}"
      is_parcel_type="${is_parcel_type}"
      _sel="./_->shippings->(#${shipping.id})"
      is_domestic="${is_domestic_prj}"
      is_inhouse="${is_inhouse}"
      is_ship_to_inhouse="${is_ship_to_inhouse}"
      is_oversea_lgl="${is_oversea_lgl}"
    >
      <div class="header">
        <div class="domain">
          <div class="icon"></div>
          <span class="domain_name"
            >${is_show_inhouse ? '사내' : is_domestic_prj ? '국내' : '해외'} 배송 정보</span
          >
          ${is_show_inhouse
            ? html`<button class="inhouse_label_print">
                <div class="img ${is_inhouse_box_created ? 'created' : 'null'}"></div>
                ${is_inhouse_box_created ? '주문서 라벨 출력됨' : '주문서 라벨 출력 전'}
              </button>`
            : ``}
          <span class="is_edit">수정</span>
        </div>
        <div class="carrier">
          ${html`
            <div class="field is_combined">
              ${is_parent_and_has_combined_projections
                ? html`<span><strong>묶음 부모주문서</strong></span>`
                : html`<label class="is_combined"
                    >묶음여부
                    <input
                      name="is_combined"
                      class="is_combined"
                      type="checkbox"
                      ${shipping.is_combined ? 'checked' : ''}
                  /></label>`}
            </div>
          `}
          ${makeSelectBoxField({
            title: '배송방법',
            name: 'type',
            field_class: 'shipping_type',
            is_disabled:
              is_parent_and_has_combined_projections ||
              (!is_parcel_type && (shipping.is_combined || shipping.type === 'combined')),
            // 배송 유형 선택 옵션
            option_html: go(
              convertShippingTypeToLabel,
              keys,
              strMap((shipping_type) => {
                return is_show_inhouse
                  ? html`<option>사내배송</option>`
                  : html`<option
                      value="${shipping_type}"
                      ${shipping_type === shipping.type ? 'selected' : ''}
                    >
                      ${convertShippingTypeToLabel[shipping_type]}
                    </option>`;
              }),
            ),
          })}
          ${makeSelectBoxField({
            title: '운송사',
            name: 'shipping_company_id',
            option_html:
              UtilS.isEmpty(shipping._?.shipping_company) || shipping._.shipping_company.is_visible
                ? go(
                    box.sel('shipping_companies'),
                    filter(
                      (shipping_company) =>
                        shipping_company.is_visible &&
                        shipping_company[is_domestic_prj ? 'is_domestic' : 'is_oversea'],
                    ),
                    strMap((shipping_company) => {
                      const oversea_3pl_company = shipping_company.oversea_3pl_company;
                      return html`<option
                        value="${shipping_company.id}"
                        ${shipping._?.shipping_company?.id === shipping_company.id ? 'selected' : ''}
                      >
                        ${shipping_company.name}
                        ${!is_domestic_prj && UtilStringS.isNotEmpty(oversea_3pl_company)
                          ? `(${oversea_3pl_company})`
                          : ''}
                      </option>`;
                    }),
                  )
                : html`<option value="${shipping._?.shipping_company.id}">
                    ${shipping._?.shipping_company.name}${(function () {
                      const oversea_3pl_company = shipping._?.shipping_company.oversea_3pl_company;
                      return !is_domestic_prj && UtilStringS.isNotEmpty(oversea_3pl_company)
                        ? `(${oversea_3pl_company})`
                        : '';
                    })()}
                  </option>`,
          })}
          ${is_domestic_prj
            ? ''
            : makeSelectBoxField({
                title: '운송서비스',
                name: 'shipping_company_service_id',
                option_html: makeShippingCompanyServiceOptions({
                  shipping_companies: box.sel('shipping_company_services'),
                  selected_shipping_company_id: shipping.shipping_company_id,
                  selected_shipping_company_service_id: shipping.shipping_company_service_id,
                }),
              })}
          ${makeShippingInputField({
            title: `${is_domestic_prj ? '' : '해외'}운송장`,
            value: shipping.waybill_number,
            field_class: 'waybill_number',
            name: 'waybill_number',
          })}
          ${is_oversea_lgl
            ? makeShippingInputField({
                title: '국내운송장(CJ)',
                value: shipping.waybill_number_inbound,
                field_class: 'waybill_number_inbound',
                name: 'waybill_number_inbound',
              })
            : ''}
        </div>
      </div>
      <div class="body">
        <div class="flex-row">
          ${makeShippingInputField({
            title: is_show_inhouse ? '사원' : '수령인',
            value: is_show_inhouse
              ? `${coci?.employee_name ?? '-'} (${coci?.employee_no ?? '-'})`
              : shipping.name,
            name: 'name',
            field_class: 'receiver_name',
          })}
          ${is_domestic_prj
            ? makeShippingInputField({
                title: is_show_inhouse ? '사내 연락처' : '연락처1',
                value: is_show_inhouse ? coci?._?.crew_inhouse_shipping?.company_tel : shipping.mobile1,
                field_class: 'receiver_tel1 input_grow',
                name: 'mobile1',
              })
            : makeShippingInputField({
                title: '연락처1',
                value: shipping.mobile_en,
                field_class: 'receiver_mobile_en input_grow',
                name: 'mobile_en',
                is_number: true,
                html_before: html` <select name="dialing_code" class="dialing_code">
                  ${go(
                    box.sel('countries'),
                    sortBy((country) => parseInt(country.dialing_code)),
                    strMap(
                      (country) => legacyHtml`
                      <option
                        value="${country.dialing_code || ''}"
                        code="${country.code}"
                        ${country.dialing_code === shipping.dialing_code ? 'selected' : ''}
                      >
                        +${country.dialing_code} (${country.name} )
                      </option>
                    `,
                    ),
                  )}
                </select>`,
              })}
          ${is_show_inhouse
            ? makeShippingInputField({
                title: '사원 이메일',
                value: coci?.employee_email,
                name: 'employee_email',
                field_class: 'employee_email input_grow',
              })
            : makeShippingInputField({
                title: '연락처2',
                value: shipping.mobile2,
                name: 'mobile2',
                field_class: 'receiver_tel2 input_grow',
                is_number: true,
              })}
        </div>
        <div class="flex-row">
          ${makeShippingInputField({
            title: '우편번호',
            value: is_show_inhouse ? coci?._?.crew_inhouse_shipping?.company_postcode : shipping.postcode,
            name: 'postcode',
            field_class: `receiver_postcode ${is_domestic_prj ? 'input_short' : ''}`,
            html_after: is_domestic_prj
              ? html`<button class="address_search" type="button">주소 검색</button> `
              : html``,
          })}
          ${makeShippingInputField({
            title: is_show_inhouse ? '회사 주소' : '주소',
            value: is_show_inhouse
              ? coci?._?.crew_inhouse_shipping?.company_address
              : is_domestic_prj
              ? shipping.address
              : shipping.street,
            name: `${is_domestic_prj ? 'address' : 'street'}`,
            field_class: 'receiver_address input_grow',
          })}
          ${makeShippingInputField({
            title: '상세주소',
            value: is_show_inhouse
              ? `${coci?._?.crew_inhouse_shipping?.location_name ?? ''} (${
                  coci?._?.crew_inhouse_shipping?.company_code ?? ''
                })`
              : is_domestic_prj
              ? shipping.address2
              : shipping.detail,
            name: `${is_domestic_prj ? 'address2' : 'detail'}`,
            field_class: 'receiver_address2 input_grow',
          })}
        </div>

        <!-- 우편번호 탐색기 (DAUM) -->
        ${is_domestic_prj ? html` <div class="address_search_layer"></div> ` : ''}
        ${is_domestic_prj
          ? html``
          : html`
              <div class="flex-row">
                ${makeSelectBoxField({
                  title: '국가',
                  name: 'country',
                  field_class: 'receiver_country',
                  option_html: go(
                    lgl_shipping_countries,
                    strMap((c) => {
                      const is_selected =
                        c.name.trim().toLowerCase() === shipping.country.trim().toLowerCase()
                          ? 'selected'
                          : '';
                      return html`
                        <option
                          ${is_selected}
                          value="${c.name}"
                          id="${c.id}"
                          code="${c.code}"
                          dialing_code="${c.dialing_code}"
                        >
                          ${c.name}
                        </option>
                      `;
                    }),
                    (option_html) => {
                      const is_shipping_country_included_in_lgl = lgl_shipping_countries.find(
                        (c) => c.name.toLowerCase() === shipping.country.trim().toLowerCase(),
                      );

                      // 예전 주문들 중 lgl 미지원 국가인 경우도 정상적으로 표현될 수 있어야 함.
                      if (is_shipping_country_included_in_lgl == null) {
                        option_html =
                          html`<option
                            selected
                            value="${shipping.country}"
                            id="${shipping.country_id}"
                            dialing_code="${shipping.dialing_code}"
                          >
                            ${shipping.country}
                          </option>` + option_html;
                      }
                      return option_html;
                    },
                  ),
                })}
                ${makeShippingInputField({
                  title: '도시',
                  value: shipping.city,
                  name: 'city',
                  field_class: 'receiver_city input_grow',
                })}
                ${makeShippingInputField({
                  title: '주',
                  value: shipping.state,
                  name: 'state',
                  field_class: 'receiver_state input_grow',
                })}
              </div>
            `}

        <div class="flex-row">
          ${makeShippingInputField({
            title: '배송메모',
            value: shipping.memo,
            name: 'memo',
            field_class: 'receiver_memo full',
          })}
        </div>
      </div>
      <div class="footer">
        <div class="buttons normal">
          <!-- // LGL-BUWHAL -->
          <!-- ${is_oversea_lgl ? html`<button name="lgl_manager">해외 운송장 관리하기</button>` : ``} -->
          ${is_show_inhouse || is_oversea_lgl
            ? ``
            : html`<button name="download">운송장 폼 다운받기</button>`}
          ${is_oversea_lgl
            ? html`
                <button name="lgl_express_excel_down">LGL 폼 다운</button>
                <button name="lgl_express_excel_up">LGL 폼 업로드</button>
              `
            : ``}
          ${is_oversea_lgl && shipping.waybill_number
            ? html`<button name="tracking_express">LGL 해외 특송 추적</button>`
            : ``}
          ${shipping.waybill_number_inbound || is_domestic_parcel_cj
            ? html`<button name="tracking_cj">CJ 국내 배송 추적</button>`
            : ``}
          ${is_inhouse ? makeInhouseTogglerButtonHtml({ is_ship_to_inhouse }) : ''}
          ${is_ship_to_inhouse
            ? html`<button name="inhouse_shipping_edit">사내 배송지 수정</button>`
            : html` <button name="shipping_edit">배송 수정하기</button> `}
        </div>
        <div class="buttons editing">
          <button name="cancel">취소하기</button>
          <button name="save">저장하기</button>
        </div>
      </div>
    </section>
  `;
};

const makeSelectBoxField = ({ title, name, classname, option_html, is_disabled = false }) => {
  return html`
    <div class="field ${classname ?? name}">
      <label for="${classname}">${title}</label>
      <select name="${name}" ${is_disabled ? 'disabled' : ''}>
        ${option_html}
      </select>
    </div>
  `;
};

const makeShippingInputField = ({
  title,
  value,
  field_class,
  name,
  mutator,
  is_number = false,
  html_before,
  html_after,
}) => {
  return html` <div class="field ${field_class}">
    <label for="${name}">${title}</label>
    ${html_before}
    <input
      readonly
      type="${is_number ? 'number' : 'text'}"
      id="${name}"
      name="${name}"
      value="${mutator ? mutator(value ?? '') : value ?? ''}"
      placeholder="없음"
    />
    ${html_after}
  </div>`;
};

/**
 * @description DF 주문서 리스트 배송 정보 섹션 - 국내배송, 해외배송, 사내배송
 */
export const makeDfProjectionShipItemsHtml = ({ prj }) =>
  strMap((shipping) => makeProjectionShipItemTmpl(prj, shipping), prj._.shippings);

export const makeShippingCompanyServiceOptions = ({
  shipping_companies,
  selected_shipping_company_id,
  selected_shipping_company_service_id,
}) => {
  const shipping_company_services = shipping_companies?.filter(
    (sc) => sc.shipping_company_id === selected_shipping_company_id,
  );

  if (UtilS.isEmpty(shipping_company_services)) {
    return html`<option selected value="">DEFAULT</option>`;
  }

  return go(
    shipping_company_services,
    strMap((sc) => {
      const is_selected = sc.id === selected_shipping_company_service_id ? 'selected' : '';
      return html`<option ${is_selected} value="${sc.id}">${sc.display_name}</option> `;
    }),
  );
};
