export const TYPE_LABEL = {
  parcel: '택배',
  combined: '묶음배송',
  quick: '퀵 배송',
  visit: '방문수령',
  partition: '개별배송',
};

export const TYPE = {
  parcel: 'parcel',
  combined: 'combined',
  quick: 'quick',
  visit: 'visit',
  partition: 'partition',
};

export const EXPRESS_SERVICE_DEFAULT_NAME = 'DEFAULT SERVICE';

export const COUNTY_ID_JP = 110;
export const COUNTY_CODE_JP = 'JP';

export const DEFAULT_SHIPPING_COMPANY_FOR_JP = 'lotte_economy_jp';
