import {
  TRACKING_DETAIL_NOT_FULFILLMENT_TABLE_COLUMN,
  TRACKING_DETAIL_TABLE_COLUMN,
} from '../../../S/Constant/lgl.js';
import { truckIcon } from '../../../ShippingDetail/S/Tmpl/tmpl.icon.js';
import { makeTableHtml } from '../../../ShippingDetail/S/Tmpl/tmpl.table.js';

export function makeTrackingDetailHtml({ data }) {
  // LGL-BUWHAL
  return `<div class="header">
            <h1>배송 조회</h1>
            <span><strong>주문번호</strong>2035535</span>
          </div>
          <div class="body">
            <div class="box">
                ${truckIcon()}
                <div class="information">
                    <div class="information_item">
                        <strong>운송장1</strong>123456789012
                    </div>
                    <div class="information_item">
                      <strong>수령인</strong>마플님
                    </div>
                    <div class="information_item">
                        <strong>연락처</strong>01023456789
                    </div>
                </div>
            </div>
            <div class="table_wrapper">
                ${makeTableHtml({ data, columns: TRACKING_DETAIL_TABLE_COLUMN })}
            </div>
          </dl>
        </div>`;
}

export function makeTrackingDetailNotFulfillment({ data }) {
  return `<div class="header">
            <h1>배송 조회</h1>
          </div>
          <div class="body">
            <div class="box">
                ${truckIcon()}
                <!-- <div class="information">
                    <div class="information_item">
                        <strong>운송장1</strong>123456789012
                    </div>
                    <div class="information_item">
                      <strong>수령인</strong>마플님
                    </div>
                    <div class="information_item">
                        <strong>연락처</strong>01023456789
                    </div>
                </div> -->
            </div>
            <div class="table_wrapper">
                ${makeTableHtml({ data, columns: TRACKING_DETAIL_NOT_FULFILLMENT_TABLE_COLUMN })}
            </div>
          </dl>
        </div>`;
}
