import { VectorEditorStickerSingleMobileCuttingLineManualF } from '../../../CuttingLineManual/F/Function/module/VectorEditorStickerSingleMobileCuttingLineManualF.js';
import { VectorEditorStickerSingleMobileCuttingLineAutoF } from '../Function/module/VectorEditorStickerSingleMobileCuttingLineAutoF.js';

export const handleFooterDecreaseButtonClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_cutting_line_index <= 0) {
      return;
    }

    $.don_loader_start();
    try {
      const cutting_line = await VectorEditorStickerSingleMobileCuttingLineAutoF.getCuttingLine({
        tab_el,
        index: tab_el.__mp_cutting_line_index - 1,
      });
      VectorEditorStickerSingleMobileCuttingLineAutoF.setCuttingLine({
        tab_el,
        label: cutting_line.label,
        path_data: cutting_line.path_data,
      });
      tab_el.__mp_cutting_line_index = cutting_line.index;
    } catch (error) {
      $.alert(
        error?.__mp_alert_message ??
          T(
            'modules::VectorEditor::Sticker::Single::Mobile::CuttingLineAuto::error_message::cutting_line_making_error',
          ),
      );
    } finally {
      $.don_loader_end();
    }
  };

export const handleFooterIncreaseButtonClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_cutting_line_index >= tab_el.__mp_cutting_lines.length - 1) {
      return;
    }

    $.don_loader_start();
    try {
      const cutting_line = await VectorEditorStickerSingleMobileCuttingLineAutoF.getCuttingLine({
        tab_el,
        index: tab_el.__mp_cutting_line_index + 1,
      });
      VectorEditorStickerSingleMobileCuttingLineAutoF.setCuttingLine({
        tab_el,
        label: cutting_line.label,
        path_data: cutting_line.path_data,
      });
      tab_el.__mp_cutting_line_index = cutting_line.index;
    } catch (error) {
      $.alert(
        error?.__mp_alert_message ??
          T(
            'modules::VectorEditor::Sticker::Single::Mobile::CuttingLineAuto::error_message::cutting_line_making_error',
          ),
      );
    } finally {
      $.don_loader_end();
    }
  };

export const handleFooterEditManualButtonClick =
  ({ tab_el }) =>
  () => {
    const cutting_line_editor = tab_el.__mp_cutting_line_editor;
    if (!cutting_line_editor) {
      return;
    }
    const target_image_el = tab_el.__mp_target_image_el;
    if (!target_image_el) {
      return;
    }

    const cutting_line_data = cutting_line_editor.getCuttingLinePathData();

    return VectorEditorStickerSingleMobileCuttingLineManualF.makeCuttingLineManual({
      target_image_el,
      path_data: cutting_line_data,
      postProcess: tab_el.__mp_postProcess,
    });
  };
