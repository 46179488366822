import { each, equals2, go, isNil } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { VectorEditorStickerFreePCF } from '../Function/module/VectorEditorStickerFreePCF.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';
import { VectorEditorTopMenuPCF } from '../../../../../TopMenu/PC/F/Function/module/VectorEditorTopMenuPCF.js';

export const handleFreeStickerEditorAdd =
  ({ tab_el }) =>
  async (event) => {
    try {
      VectorEditorStickerFreePCF.setOffsetPathData({
        tab_el,
        wrapper_el: event?.detail?.wrapper_el,
        id: event?.detail?.id,
      });
      VectorEditorStickerFreePCF.transformOffsetPathData({
        tab_el,
        wrapper_el: event?.detail?.wrapper_el,
        id: event?.detail?.id,
      });
      VectorEditorStickerFreePCF.checkCollision({ tab_el });
    } catch (error) {
      console.error(error);
      const message = error?.__mp_alert_message;
      if (message) {
        await $.alert(message);
      }
    }
  };

export const handleFreeStickerEditorRemove =
  ({ tab_el }) =>
  async () => {
    const sticker_count = tab_el.__mp_free_sticker_editor?.getEls?.()?.length;
    if (sticker_count != null && sticker_count <= 0) {
      go(
        tab_el,
        $findAll(`.new_sticker_button`),
        each((el) => (el.dataset.is_show = 'false')),
      );
    }
    if (tab_el.__mp_free_template_svg_el != null && sticker_count != null && sticker_count <= 0) {
      tab_el.__mp_free_template_svg_el.dataset.is_show = 'true';
    }

    try {
      VectorEditorStickerFreePCF.checkCollision({ tab_el });
    } catch (error) {
      console.error(error);
      const message = error?.__mp_alert_message;
      if (message) {
        await $.alert(message);
      }
    }
  };

const TYPE_NONE_TOP_MENU_ID = {
  ACTIVATE: new Set(),
  DEACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
  ]),
};
const TYPE_SINGLE_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
  ]),
  DEACTIVATE: new Set(),
};
const TYPE_MULTIPLE_TOP_MENU_ID = {
  ACTIVATE: new Set([VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID]),
  DEACTIVATE: new Set([VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID]),
};
const handleFreeStickerEditorSelectOrUnselect =
  ({ tab_el }) =>
  async (event) => {
    const count = event?.detail?.selected_els?.length;

    if (count == null || count <= 0) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container .right_wrapper .panel_container .panel_wrapper`),
        each((wrapper_el) => (wrapper_el.dataset.is_show = `${equals2('home')(wrapper_el.dataset.panel)}`)),
      );
    } else if (count <= 1) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container .right_wrapper .panel_container .panel_wrapper`),
        each((wrapper_el) => (wrapper_el.dataset.is_show = `${equals2('select')(wrapper_el.dataset.panel)}`)),
      );
    } else {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_MULTIPLE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_MULTIPLE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container .right_wrapper .panel_container .panel_wrapper`),
        each((wrapper_el) => (wrapper_el.dataset.is_show = `${equals2('home')(wrapper_el.dataset.panel)}`)),
      );
    }
  };

export const handleFreeStickerEditorSelect =
  ({ tab_el }) =>
  (event) =>
    handleFreeStickerEditorSelectOrUnselect({ tab_el })(event);

export const handleFreeStickerEditorUnselect =
  ({ tab_el }) =>
  (event) =>
    handleFreeStickerEditorSelectOrUnselect({ tab_el })(event);

export const handleFreeStickerEditorTransform =
  ({ tab_el }) =>
  async (event) => {
    const { el, transform_status, transform_type } = event?.detail ?? {};

    if (isNil(el)) {
      return;
    }

    if (!equals2(transform_status)('end')) {
      return;
    }

    if (!new Set(['translate', 'rotate', 'scale']).has(transform_type)) {
      return;
    }

    try {
      VectorEditorStickerFreePCF.transformOffsetPathData({ tab_el, wrapper_el: el });
      VectorEditorStickerFreePCF.checkCollision({ tab_el });
    } catch (error) {
      console.error(error);
      const message = error?.__mp_alert_message;
      if (message) {
        await $.alert(message);
      }
    }
  };
