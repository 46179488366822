import { createCvTextImageA } from '../../../../Maker/F/text.js';

export const textTest = async ({ ratio = 1, text = '텍스트', lineHeight }) => {
  const width = 100 * ratio;
  const fcanvas = new fabric.Canvas(document.createElement('canvas'), {
    width,
    height: width * 2,
    enableRetinaScaling: false,
  });
  // const f_text = new fabric.IText(text, { top: 0, left: 0, scaleX: 1, scaleY: 1, lineHeight });
  const f_text = await createCvTextImageA({
    cid: 'c1899',
    _data: {
      width_cm: 1.4,
      height_cm: 1.4,
      rotate: 0,
      nscreened: true,
      only_flex: false,
      real_height_cm: 1.4,
      real_width_cm: 1.4,
      press_color_code: '#080c3e',
      press_color_id: 40,
      press_color_name: '네이비',
      press_color_name_en: 'Navy blue',
      press_color_name_jp: 'ネイビー',
      text_info: {
        left: 0,
        top: 0,
        text,
        angle: 0,
        scaleX: 1,
        styles: {},
        fontWeight: 'normal',
        fontStyle: 'normal',
        underline: false,
        linethrough: false,
        textAlign: 'center',
        fontFamily: 'Hogook',
        charSpacing: 180,
        lineHeight: 1.4,
        _data: {
          width_cm: 1.4,
          height_cm: 1.4,
          rotate: 0,
          nscreened: true,
          only_flex: false,
          real_height_cm: 1.4,
          real_width_cm: 1.4,
          press_color_code: '#080c3e',
          press_color_id: 40,
          press_color_name: '네이비',
          press_color_name_en: 'Navy blue',
          press_color_name_jp: 'ネイビー',
          cv_type: 'cv_text',
        },
      },
      cv_type: 'cv_text_image',
      old_scale: 0.06915629322268327,
      new_scale: 0.06915629322268327,
      pad: { left: 3, top: -10, r: 10.44030650891055, width: 50, height: 50, deg: 286.6992442339936 },
    },
    is_overflow: false,
    is_oversized: false,
    is_smallsized: false,
    left: 0,
    top: 0,
    width: 723,
    height: 719,
    fill: 'rgb(0,0,0)',
    stroke: null,
    evented: true,
    selectable: true,
    strokeWidth: 0,
    strokeDashArray: null,
    scaleX: 0.06915629322268327,
    scaleY: 0.06915629322268327,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    visible: true,
    globalCompositeOperation: 'source-over',
    fillRule: 'nonzero',
    transformMatrix: null,
    backgroundColor: '',
    clipTo: null,
    shadow: null,
    strokeMiterLimit: 10,
    strokeLineCap: 'butt',
    strokeLineJoin: 'miter',
    type: 'image',
    originX: 'left',
    originY: 'top',
    skewX: 0,
    skewY: 0,
    crossOrigin: 'Anonymous',
    alignX: 'none',
    alignY: 'none',
    meetOrSlice: 'meet',
    filters: [],
    resizeFilters: [],
  });
  f_text.scaleX = f_text.scaleX * ratio;
  f_text.scaleY = f_text.scaleY * ratio;
  fcanvas.add(f_text);
  fcanvas.renderAll();
  console.log(fcanvas.toDataURL());
};
