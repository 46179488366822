import { strMap, entries, html } from 'fxjs/es';
import { LANG_TO_KR } from './constant.js';

const makeEditorWrapHtml = (b, is_line, is_top_banner) => html`
  <div class="editor_wrap">
    <div>
      <button class="put editor" type="button">저장</button>
      <button class="delete editor" type="button">삭제</button>
    </div>
    <div>
      <select name="is_public" class="data_attr">
        <option value="true" ${b.is_public ? 'selected' : ''}>공개</option>
        <option value="false" ${!b.is_public ? 'selected' : ''}>비공개</option>
      </select>
    </div>
    <div>
      <input class="data_attr" type="text" name="href" placeholder="배너 링크" value="${b.href || ''}" />
    </div>
    ${is_line
      ? html`
          ${is_top_banner
            ? html`
                <div>
                  <input
                    class="data_attr"
                    type="text"
                    name="bg_color"
                    placeholder="IE전용 배너 색(#해시)"
                    value="${b.bg_color || ''}"
                  />
                </div>
              `
            : ``}
          <div>
            <textarea class="data_attr" name="title" placeholder="제목">${b.title || ''}</textarea>
          </div>
          <div>
            <textarea class="data_attr" name="sub_title" placeholder="서브 제목">
${b.sub_title || ''}</textarea
            >
          </div>
          ${is_top_banner
            ? html`
                <div>
                  <input
                    class="data_attr"
                    type="text"
                    name="title_color"
                    placeholder="제목 글자색(#해시)"
                    value="${b.title_color || ''}"
                  />
                </div>
              `
            : ''}
        `
      : ''}
    <div>
      <div>복사는 언어만 다른 같은 배너 위치로 복사됩니다</div>
      <div>(타이틀과 순서는 복사되지 않습니다)</div>
      ${strMap(
        ([lang, name]) => html`
          <button type="button" class="copy_to_lang" value="${lang}">${name}으로 복사</button>
        `,
        entries(LANG_TO_KR),
      )}
    </div>
  </div>
`;

export const makeTopBannerHtml = (bs, is_line) =>
  strMap(
    (b) => html`
      <li class="top_banner banner_item" _sel=./(#${b.id})>
        ${makeEditorWrapHtml(b, is_line, true)}
        <div class="banner_src">
          <div class="pc" video_show=${!!b.src_video}>
            <h3>PC ${is_line ? `(1180x620)` : `(3840x960) 400kb 이하로 최대한 용량이 작으면 좋아요!`}</h3>
            <div class="src" key="src_pc">
              <div class="header">이미지</div>
              <div class="body">
                <input type="file" accept="image/*">
                <div class="src_wrap src_pc">
                  <input class="data_attr" type="hidden" name="src_pc" value="${b.src_pc || ''}">
                  <img src="${b.src_pc || ''}" class="src_tag">
                </div>
              </div>
            </div>
          </div>
          <div class="mobile">
            <h3>모바일 ${is_line ? `(750x900)` : `(750x600) 300kb 이하로 최대한 용량이 작으면 좋아요!`}</h3>
            <div class="src" key="src_mobile">
              <div class="header">이미지</div>
              <div class="body">
                <input type="file" accept="image/*">
                <div class="src_wrap" is_show="true">
                  <input class="data_attr" type="hidden" name="src_mobile" value="${b.src_mobile || ''}">
                  <img class="src_tag" src="${b.src_mobile || ''}">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="handle">여기를 잡고 드래그하여 정렬해 주세요</div>
      </li>`,
    bs,
  );

export const makeMiddleBannerHtml = (bs, is_line) =>
  strMap(
    (b) => html`
      <li class="middle_banner banner_item" _sel=./(#${b.id})>
        ${makeEditorWrapHtml(b, is_line)}
        <div class="banner_src">
          <div class="pc" video_show=${!!b.src_video}>
            <h3>PC ${is_line ? '(540x680)' : '(1232x736) 350kb 이하로 최대한 용량이 작으면 좋아요!'}</h3>
            <div class="src" key="src_pc">
              <div class="header">이미지</div>
              <div class="body">
                <input type="file" accept="image/*">
                <div class="src_wrap src_pc">
                  <input class="data_attr" type="hidden" name="src_pc" value="${b.src_pc || ''}">
                  <img class="src_tag" src="${b.src_pc || ''}">
                </div>
              </div>
            </div>
          </div>
          <div class="mobile">
            <h3>모바일 ${is_line ? '(600x600)' : '(750x448)'}</h3>
            <div class="src" key="src_mobile">
              <div class="header">이미지</div>
              <div class="body">
                <input type="file" accept="image/*">
                <div class="src_wrap" is_show="true">
                  <input class="data_attr" type="hidden" name="src_mobile" value="${b.src_mobile || ''}">
                  <img class="src_tag" src="${b.src_mobile || ''}">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="handle">여기를 잡고 드래그하여 정렬해 주세요</div>
      </li>
    `,
    bs,
  );

export const makeSwipeBannerHtml = (bs, is_line) =>
  strMap(
    (b) => html`
      <li class="swipe_banner banner_item" _sel=./(#${b.id})>
        ${makeEditorWrapHtml(b, is_line)}
        <div class="banner_src">
          <div class="pc" video_show=${!!b.src_video}>
            <h3>PC ${is_line ? '(1180x160)' : '(616x368)'}</h3>
            <div class="src" key="src_pc">
              <div class="header">이미지</div>
              <div class="body">
                <input type="file" accept="image/*">
                <div class="src_wrap src_pc">
                  <input class="data_attr" type="hidden" name="src_pc" value="${b.src_pc || ''}">
                  <img class="src_tag" src="${b.src_pc || ''}">
                </div>
              </div>
            </div>
          </div>
          <div class="mobile">
            <h3>모바일 ${is_line ? '(750x220)' : '(750x447)'}</h3>
            <div class="src" key="src_mobile">
              <div class="header">이미지</div>
              <div class="body">
                <input type="file" accept="image/*">
                <div class="src_wrap" is_show="true">
                  <input class="data_attr" type="hidden" name="src_mobile" value="${b.src_mobile || ''}">
                  <img class="src_tag" src="${b.src_mobile || ''}">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="handle">여기를 잡고 드래그하여 정렬해 주세요</div>
      </li>
    `,
    bs,
  );
