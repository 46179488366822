import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { MALGUN_GOTIC } from './resources/font.js';
import { pdfUtil } from './pdfHelpers.js';
import { getQrImageEl } from '../../../../../../modules/Df/ImageEditor/F/Function/helpers.file.js';
import { renderFooter, renderHeader, renderOptionTable, renderThumbNails } from './renderSection.js';
import { format } from 'date-fns';

export async function createWorkOrderSheetPdf({ up_item, pf2, notes }) {
  try {
    const bp = up_item._.base_product;

    // pdf document 정의
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });
    autoTable.applyPlugin(jsPDF);

    const page_width = doc.getPageWidth();
    const page_height = doc.getPageHeight();

    /* 한글 폰트 설정 */
    doc.addFileToVFS('malgun.ttf', MALGUN_GOTIC);
    doc.addFont('malgun.ttf', 'malgun', 'normal');

    const page_margin = 10;
    const line_height = 12;
    const line2_y = 25;
    const major_font_size = 16;
    const minor_font_size = 12;

    /* Layout 선 그리기 */
    pdfUtil.drawLayoutBorder({ doc, page_margin, width: 0.5 });
    pdfUtil.drawFullHorLines({ doc, page_margin, start_top: line2_y, line_height: 12, counts: 3 });

    const qr_image = await getQrImageEl({ content: `#${up_item.projection_id}`, size: 150, format: 'png' });

    /* 1st line - header */
    renderHeader({ doc, page_margin, qr_image });

    /* 2nd line - major information ( 주문 관련 정보 ) */
    const line2_v_center = line2_y + major_font_size / 2;

    // -- order no.
    pdfUtil.drawGrayBox({ doc, x: page_margin, y: line2_y, w: 28, h: line_height });
    pdfUtil.drawText({ doc, size: major_font_size, text: 'Order #', x: page_margin + 4, y: line2_v_center });
    doc.text('' + up_item.projection_id, 41, line2_v_center);

    // -- product no.
    const product_no_cell_x = 75;
    pdfUtil.drawGrayBox({ doc, x: product_no_cell_x, y: line2_y, w: 33, h: line_height });
    pdfUtil.drawText({
      doc,
      size: major_font_size,
      text: 'Product #',
      x: product_no_cell_x + 4,
      y: line2_v_center,
    });
    doc.text('' + up_item.up_id, 111, line2_v_center);

    // -- order at
    const order_date_cell_x = 143;
    pdfUtil.drawGrayBox({ doc, x: order_date_cell_x, y: line2_y, w: 29, h: line_height });
    pdfUtil.drawText({
      doc,
      size: major_font_size,
      text: 'Order at',
      x: order_date_cell_x + 4,
      y: line2_v_center,
    });
    doc.text(format(new Date(up_item.created_at), 'yy/MM/dd'), 175, line2_v_center);

    /* 3rd line - 상품 정보 */
    const line3_v_center = line2_y + line_height + major_font_size / 2;
    pdfUtil.drawGrayBox({ doc, x: page_margin, y: line2_y + line_height, w: 28, h: line_height });
    pdfUtil.drawText({ doc, size: major_font_size, text: 'Product', x: page_margin + 4, y: line3_v_center });
    doc.setFont('malgun');
    doc.text(bp.name, 40, line3_v_center);

    /* 사이즈 컬러 수량 option 정보 */
    const up_cs = up_item._.up_cs;
    const { table_width, table_height } = renderOptionTable({
      doc,
      up_cs,
      right: page_width - page_margin,
      top: line2_y + line_height * 2,
    });

    const top_3rd_line = line2_y + line_height * 2;
    const margin_y_after_3rd_line = page_height - 2 * page_margin - top_3rd_line;

    const thumbnail_available_position = {
      left: {
        x: page_margin,
        y: top_3rd_line,
        w: page_width - 2 * page_margin - table_width,
        h: margin_y_after_3rd_line,
      },
      right: {
        x: page_width - page_margin - table_width,
        y: top_3rd_line + table_height + 3,
        w: table_width,
        h: margin_y_after_3rd_line - table_height,
      },
    };

    const [face1, face2, ...remaining_faces] = pf2;

    renderThumbNails({
      doc,
      pfs: [face1, face2],
      ...thumbnail_available_position.left,
      text: { offset_x: 5, offset_y: 8 },
    });
    renderThumbNails({
      doc,
      pfs: remaining_faces,
      ...thumbnail_available_position.right,
      text: { offset_x: -7, offset_y: 7 },
    });

    renderFooter({ doc, page_margin, notes });

    const file_name = `작업지시서_주문번호(${up_item.projection_id})_상품번호(${up_item.up_id})_${format(
      Date.now(),
      'HH:mm:ss',
    )}`;
    doc.save(file_name);
  } catch (e) {
    $.alert(`에러 발생: 개발팀 문의<br>${e.message}`);
  } finally {
    $.don_loader_end();
  }
}
