import axios from 'axios';
import { go, pipe, sel, tap } from 'fxjs/es';
import { frameOpenReviewDetail } from '../../Detail/F/fs.js';
import { changeReviewListInfi } from './fs.js';

export const defnReviewListEvent = tap(
  $.on2('click', '.review_item .review_img', (e) =>
    frameOpenReviewDetail(
      window.box.sel(e.currentTarget),
      $1('html.maker_page, html.my_review, html.creator'),
    ),
  ),
  $.on('click', '.review_item .more', (e) =>
    go(e.currentTarget, $.hide, $.prev('.comment_wrap2'), $.remove_class('comment_wrap2')),
  ),
  $.on('click', '.radio_toggle:not(.ing) .control_button', (e) =>
    go(
      $.closest(e.currentTarget, '.radio_toggle'),
      $.find1('input[type="radio"]:not(:checked)'),
      function (input) {
        input.checked = true;
        $.trigger(input, 'change');
      },
    ),
  ),
  $.on('change', '.photo_toggle .radio_toggle:not(.ing) input[type="radio"]', (e) =>
    go(
      $.closest(e.currentTarget, '.radio_toggle'),
      $.toggleClass('check_on'),
      $.add_class('ing'),
      tap($.has_class('check_on'), (check) =>
        go(
          $.closest(e.currentTarget, '.mp_review_list'),
          tap($.find1('.review_header'), $.attr('photo_review', check)),
          $.find1('.review_list'),
          $.attr('photo_review', check),
          $.css('visibility', 'hidden'),
          tap((review_list_el) => changeReviewListInfi(review_list_el, 'no')),
          $.css('visibility', ''),
        ),
      ),
      $.remove_class('ing'),
    ),
  ),
  () =>
    $.on2($1('body'), 'click', '.review-delete button', async (e) => {
      const { id, is_me } = box.sel(e.currentTarget);
      if (!is_me || !(await $.confirm(T('삭제 하시겠습니까?')))) return;
      try {
        $.don_loader_start();
        await axios.delete(`/${T.lang}/@api/review`, { data: { id } });
        location.reload();
      } catch (e) {
        $.alert(sel('response.data.message', e) || '삭제가 불가능합니다.');
      }
      $.don_loader_end();
    }),
  () => {
    if (G.collabo_type === 'line') {
      // 라인 리뷰 페이지에서만 쓰임
      new Swiper('.line_review_list .swiper-container', {
        spaceBetween: 30,
        effect: 'fade',
        speed: 1000,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      });
    }
  },
);

export const defnReviewListCateListEvent = pipe(
  $.on(
    'click',
    '#selected_cate_list',
    pipe(sel('currentTarget'), $, $.closest('#cate_list_selector'), $.toggle_class('is_open')),
  ),
  $.on(
    'change',
    '#cate_list_selector select',
    pipe(sel('currentTarget'), function (target) {
      const cate_list_id = $.val(target);
      window.box[cate_list_id == 'all' ? 'unset' : 'set']('review_list->query->cate_list_id', cate_list_id);
      const href = location.pathname + '?' + $.param(window.box.sel('review_list->query'));
      $.frame.extend_state(void 0, null, href, 'replace');
      changeReviewListInfi($.find1($.closest(target, '.mp_review_list'), '.review_list'), 'no');
    }),
  ),
);
