import { extend, filter, find, go, sel, flatMap } from 'fxjs/es';

function makeDefaultCvImageAttrs(image) {
  return {
    src: image.url,
    _data: {
      only_flex: image.only_flex,
      image_id: image.id,
      image_color_id: image.image_color_id,
      image_url: image.url,
      image_width: image.width,
      image_height: image.height,
      image_width_cm: image.width_cm,
      image_height_cm: image.height_cm,
      image_original_url: image.original_url,
      design_collection_type: _p.v(image, '_.design_collection_type') || null,
      is_marpple_image: !!image.image_color_id,
      press_color_code: image.press_color_code,
      press_color_id: image.press_color_id,
      press_color_name: image.press_color_name,
      original_is_ai: _p.last((image.original_url || '').split('.')).toLowerCase() == 'ai',
      name: (image.name || '').replace(/</g, '').replace(/>/g, ''),
      name_en: (image.name_en || '').replace(/</g, '').replace(/>/g, ''),
      is_embro: !!image.width_cm,
      price: image.price,
      price_en: parseFloat(image.price_en || 0),
    },
  };
}

export function applyC2323WhenStart({ product, base_products_bp_option_groups, selected_option_group }) {
  /*현재 오직 키링 */
  const { id: size_bp_option_id } = go(
    base_products_bp_option_groups,
    filter((bpbog) => bpbog._.bp_option_group.name === '사이즈'),
    flatMap((bpbog) => bpbog._.bp_option_group._.bp_options),
    find((bp_option) => selected_option_group.bp_option_ids.includes(bp_option.id)),
  );
  const cv_obj = go(
    product.product_faces2.value[0].designs,
    find((cv_obj) => cv_obj.cid == 'c2323'),
  );
  go(
    product._.base_product_color._.base_product_color_faces[0]._.base_product_face,
    sel('_.cv_obj_actions'),
    (cv_obj_actions) => {
      if (cv_obj_actions?.length) {
        go(
          cv_obj_actions,
          filter((cv_obj_action) => cv_obj_action.bp_option_id == size_bp_option_id),
          (cv_obj_actions) => {
            const for_acril = find(({ target_cid }) => target_cid == 'c2323')(cv_obj_actions);
            for_acril.cv_attr.src = G.to_900(cv_obj.src);
            extend(for_acril.cv_attr._data, cv_obj._data);
          },
        );
      }
    },
  );
}

export function applyC2323AfterEditor({ image, coord, fitted_size, product, size_bp_option_id }) {
  go(
    product._.base_product_color._.base_product_color_faces[0]._.base_product_face,
    sel('_.cv_obj_actions'),
    (cv_obj_actions) => {
      if (cv_obj_actions?.length) {
        go(
          cv_obj_actions,
          filter((cv_obj_action) => cv_obj_action.bp_option_id == size_bp_option_id),
          (cv_obj_actions) => {
            const for_acril = find(({ target_cid }) => target_cid == 'c2323')(cv_obj_actions);
            for_acril.cv_attr.src = G.to_900(image.url);
            extend(for_acril.cv_attr._data, makeDefaultCvImageAttrs(image)._data);
            for_acril.cv_attr._data.is_embro = false;
            for_acril.cv_attr._data.hole_position = coord;
            for_acril.cv_attr._data.fitted_size = fitted_size;
          },
        );
      }
    },
  );
}
