import { strMap } from 'fxjs/es';
import { BPF_WANRINGS, LOC_OPT_ENABLE_ATTR_NAME } from '../../../../../modules/Maker/S/constant.js';
import { legacyHtml } from '../../../../../modules/Util/S/Function/util.js';

G.$teach_$bp_face = (bpfs) => {
  return _p.sum(bpfs, (bpf) => {
    bpf.preview = bpf.preview || {};
    const bp_option_layers = bpf.preview.BP_OPTION_LAYERS || [];

    return legacyHtml`
      <div class="bp_face parent" bpf_id="${bpf.id}" _sel="bp->_->base_product_faces->(#${bpf.id})">
        ${_go(
          bpf,
          _p.if2(_p.v('id'))(
            (bpf) => legacyHtml`
              <div class="face_img">                
                <div class="file file_area" key="option_url">
                  <div class="file_area_wrapper"> 
                    <label class="flex-row">                                     
                      <button class="bp_option_layer_choice">옵션 선택</button>
                      <div class="bp_option_layer_choice-delete ${
                        !bp_option_layers.length ? 'd-none' : ''
                      }">X</div>
                    </label>
                    <span class="img bp_option_layer_choice-img">
                    ${bp_option_layers.map((layer) => {
                      return legacyHtml`<div><img src="${
                        layer.image_url ? G.to_150(layer.image_url) : ''
                      }" alt=""  class="${layer.image_url ? '' : 'd-none'}" /><div>${
                        layer.bp_option_name
                      }</div></div>`;
                    })}   
                    </span>
                   </div>                                          
                </div>
                <div class="file file_area" key="url">
                  <label
                    class="flex-column"
                    >면 이미지
                    <input type="file" class="file" />
                    <span class="img"><img src="${G.to_150(bpf.url)}" alt="" /></span>
                  </label>
                </div>
                <div class="file file_area" key="shading_url">
                  <label
                    class="flex-column"
                    >명암 이미지
                    <input type="file" class="file" />
                    <span class="img"><img src="${G.to_150(bpf.shading_url)}" alt="" /></span>
                  </label>
                </div>
                <div class="file file_area" key="safe_area_url">
                  <label
                    class="flex-column"
                    >안전 영역
                    <input type="file" class="file" />
                    <span class="img"><img src="${G.to_150(bpf.safe_area_url)}" alt="" /></span>
                  </label>
                </div>
              </div>
            `,
          ),
        )}
        <div class="control">
          <label>
            <span>이름</span>
            <input type="text" class="name" placeholder="이름" name="name" value="${bpf.name || ''}" />
          </label>
        </div>
        <div class="control">
          <label>
            <span>이름 en</span>
            <input
              type="text"
              class="name_en"
              placeholder="이름 en"
              name="name_en"
              value="${bpf.name_en || ''}"
            />
          </label>
        </div>
        <div class="control">
          <label>
            <span>이름 jp</span>
            <input
              type="text"
              class="name_jp"
              placeholder="이름 jp"
              name="name_jp"
              value="${bpf.name_jp || ''}"
            />
          </label>
        </div>
        <div class="control with_bp_discount_price">
          <label>
            <span class="mp_color">가격</span>
            <input type="number" class="price" placeholder="가격" name="price" value="${bpf.price}" />
          </label>
        </div>
        <div class="control with_bp_discount_price">
          <label>
            <span class="mp_color">가격 en</span>
            <input
              type="number"
              class="price_en"
              placeholder="가격 en"
              name="price_en"
              value="${bpf.price_en}"
            />
          </label>
        </div>
        <div class="control with_bp_discount_price">
          <label>
            <span class="mp_color">가격 jp</span>
            <input
              type="number"
              class="price_jp"
              placeholder="가격 jp"
              name="price_jp"
              value="${bpf.price_jp}"
            />
          </label>
        </div>
        <div class="control">
          <label>
            <span class="creator_color">마플샵 가격</span>
            <input
              type="number"
              class="price_creator"
              placeholder="가격"
              name="price_creator"
              value="${bpf.price_creator}"
            />
          </label>
        </div>
        <div class="control">
          <label>
            <span class="creator_color">마플샵 가격 en</span>
            <input
              type="number"
              class="price_creator_en"
              placeholder="가격 en"
              name="price_creator_en"
              value="${bpf.price_creator_en}"
            />
          </label>
        </div>
        <div class="control">
          <label>
            <span class="creator_color">마플샵 가격 jp</span>
            <input
              type="number"
              class="price_creator_jp"
              placeholder="가격 jp"
              name="price_creator_jp"
              value="${bpf.price_creator_jp}"
            />
          </label>
        </div>
        <div class="control">
          <label>
            <span class="creator_color">마플샵인쇄정산</span>
            <input
              type="number"
              class="oem_price_creator"
              placeholder="마플샵인쇄정산"
              name="oem_price_creator"
              value="${bpf.oem_price_creator}"
            />
          </label>
        </div>
        <div class="control">
          <label>
            <span>점선 문구</span>
            <select name="warning_type" id="">
              <option value="">없음</option>
              ${strMap(
                ({ type, description }) => legacyHtml`
                  <option value="${type}" ${type === bpf.warning_type ? 'selected' : ''}>
                    ${description}
                  </option>
                `,
              )(BPF_WANRINGS)}
            </select>
          </label>
        </div>
        <div class="control">
          <label>
            <span>배경색 고르기</span>
            <input
              type="checkbox"
              class="has_background"
              name="has_background"
              ${bpf.has_background ? ' checked' : ''}
            />
          </label>
        </div>
        <div class="control">
          <label>
            <span>위치최적화 활성</span>
            <input
              type="checkbox"
              class="${LOC_OPT_ENABLE_ATTR_NAME}"
              name="${LOC_OPT_ENABLE_ATTR_NAME}"
              ${bpf[LOC_OPT_ENABLE_ATTR_NAME] ? ' checked' : ''}
            />
          </label>
        </div>
        <div class="control">
          <label>
            <span class="mp_color">공개</span>
            <input type="checkbox" class="is_public" name="is_public" ${bpf.is_public ? ' checked' : ''} />
          </label>
        </div>
        <div class="control">
          <label>
            <span class="creator_color">마플샵 공개</span>
            <input
              type="checkbox"
              class="is_public_creator"
              name="is_public_creator"
              ${bpf.is_public_creator ? ' checked' : ''}
            />
          </label>
        </div>
        <div class="control">
          <button type='button' class='grid_print_area_download' style="margin-bottom: 8px;">격자 프린트 영역 다운로드</button>
          <button type='button' class='remove_shading_url' style="margin-bottom: 8px;">명암 이미지 삭제</button>
          <button type='button' class='remove_safe_area_url' style="margin-bottom: 8px;">안전 영역 이미지 삭제</button>
        </div>
        <button class="save" type="button">저장</button>
        <button class="bpfe_opener" type="button">커스텀정보편집</button>
        <button class="remove" type="button" style="display: none;">삭제</button>
      </div>
    `;
  });
};

G.df_$bpd_thumbnails = _map(
  (thumb) => pug`
  .item
    img[src="${G.to_150(thumb.url)}" url="${thumb.url}"]
    sp.btn_delete X
`,
);

G.df_$bp_size_item = function (bps, size_filter_lists, user) {
  const { _: { sku } = {} } = bps;

  return pug`
  .bp_size.parent[bps_id="${bps.id}" _sel="bp->_->base_product_sizes->(#${bps.id})"]
    div.check
      label 공개
        input[type="checkbox" name="is_public"${bps.is_public ? ' checked' : ''}]
      label 마플샵 공개
        input[type="checkbox" name="is_public_creator"${bps.is_public_creator ? ' checked' : ''}]
      label 대표
        input[type="radio" name="is_thumb"${bps.is_thumb ? ' checked' : ''}]
      label 사이즈 필터(폰케이스X)
        select[name=size_filter_id]
          option.none[value=''] 선택하세요.
          ${_p.sum(
            size_filter_lists,
            (size_filter) => pug`
            option[value="${size_filter.id}" ${bps.size_filter_id == size_filter.id ? 'selected' : ''}] ${
              size_filter.name
            }
          `,
          )}
      sp.btns
        button.save[type=button] 저장
        button.remove[type=button style="display: none;"] 삭제
    div
      label
        sp 긴 이름
        input.name[type=text placeholder="긴 이름" name="name" value="${bps.name}"]
      label
        sp 짧은 이름
        input.short_name[type=text placeholder="짧은 이름" name="short_name" value="${bps.short_name}"]
      label.with_bp_discount_price
        sp 가격
        input.price[type=number placeholder="가격" name="price" value="${bps.price}"]
    div
      label
        sp 긴 이름 en
        input.name_en[type=text placeholder="긴 이름" name="name_en" value="${bps.name_en || ''}"]
      label
        sp 짧은 이름 en
        input.short_name_en[type=text placeholder="짧은 이름" name="short_name_en" value="${
          bps.short_name_en || ''
        }"]
      label.with_bp_discount_price
        sp 가격 en
        input.price_en[type=number placeholder="가격" name="price_en" value="${bps.price_en}"]
    div
      label
        sp 긴 이름 jp
        input.name_jp[type=text placeholder="긴 이름" name="name_jp" value="${bps.name_jp || ''}"]
      label
        sp 짧은 이름 jp
        input.short_name_jp[type=text placeholder="짧은 이름" name="short_name_jp" value="${
          bps.short_name_jp || ''
        }"]
      label.with_bp_discount_price
        sp 가격 jp
        input.price_jp[type=number placeholder="가격" name="price_jp" value="${bps.price_jp}"]
    div
      label
        sp.creator_color 마플샵사이즈정산
        input.oem_price_creator[type=text placeholder="마플샵사이즈정산" name="oem_price_creator" value="${
          bps.oem_price_creator
        }"]
    div.bp_size_measure_editor
      label[for="bp_size_width_${bps.id}"]
        sp 너비(cm)
        input.bp_size_width[id="bp_size_width_${
          bps.id
        }" type=number placeholder="너비(cm)" name="bp_size_width" value="${sku?.width}"]
      label[for="bp_size_height_${bps.id}"]
        sp 높이(cm)
        input.bp_size_height[id="bp_size_height_${
          bps.id
        }" type=number placeholder="높이(cm)" name="bp_size_height" value="${sku?.height}"]
      label[for="bp_size_length_${bps.id}"]
        sp 길이(cm)
        input.bp_size_length[id="bp_size_length_${
          bps.id
        }" type=number placeholder="길이(cm)" name="bp_size_length" value="${sku?.length}"]
      label[for="bp_size_volumetric_weight_${bps.id}"]
        sp 부피 무게(g)
        input.bp_size_volumetric_weight[id="bp_size_volumetric_weight_${
          bps.id
        }" type=number placeholder="부피 무게(g)" name="bp_size_volumetric_weight" value="${
    sku?.volumetric_weight
  }" readonly]
      label[for="bp_size_weight_${bps.id}"]
        sp 중량(g)
        input.bp_size_weight[id="bp_size_weight_${
          bps.id
        }" type=number placeholder="중량(g)" name="bp_size_weight" value="${sku?.weight}"]
  `;
};

G.df_$bp_color_item = function (bpc, color_filter_lists, user) {
  return pug`
    .bp_color.parent[bpc_id="${bpc.id}" _sel="bp->_->base_product_colors->(#${bpc.id})"]
      .info
        label 색상 id
          span.bpc_id ${bpc.id || ''}
        label 이름
          input.name[type=text placeholder="이름" name="name" value="${bpc.name || ''}"]
        label 이름2
          input.name2[type=text placeholder="이름2" name="name2" value="${bpc.name2 || ''}"]
        label 이름 en
          input.name_en[type=text placeholder="이름 en" name="name_en" value="${bpc.name_en || ''}"]
        label 이름 jp
          input.name_jp[type=text placeholder="이름 jp" name="name_jp" value="${bpc.name_jp || ''}"]
        label 색상코드
          input.color_code[type=text placeholder="색상코드" name="color_code" value="${
            bpc.color_code ? bpc.color_code : ''
          }"]
        label 색상코드2
          input.color_code[type=text placeholder="색상코드2" name="color_code2" value="${
            bpc.color_code2 ? bpc.color_code2 : ''
          }"]
        label 색상코드(프린트용)
          input.rgb[type=text placeholder="색상코드" name="print_color_code" value="${
            bpc.print_color_code || ''
          }"]
        label.mp_color 공개
          input[type="checkbox" name="is_public"${bpc.is_public ? ' checked' : ''}]
        label.creator_color 마플샵 공개
          input[type="checkbox" name="is_public_creator"${bpc.is_public_creator ? ' checked' : ''}]
        label 대표
          input[type="radio" name="is_thumb"${bpc.is_thumb ? ' checked' : ''}]
        label 어두운 색상 체크(점선 색상을 위함)
          input[type="checkbox" name="is_black"${bpc.is_black ? ' checked' : ''}]
        label 백색 추가 필요
          input[type="checkbox" name="is_white_printing_required"${
            bpc.is_white_printing_required ? ' checked' : ''
          }]  
        label 컬러 필터
          select[name=color_filter_id]
            option.none[value=''] 선택하세요.
            ${_p.sum(
              color_filter_lists,
              (color_filter) => pug`
            option[value="${color_filter.id}" ${bpc.color_filter_id == color_filter.id ? 'selected' : ''}] ${
                color_filter.name
              }
            `,
            )}
        sp.btns
          button.save[type="button"] 저장
          button.remove[type="button"] 삭제
      .bp_color_thumb
        .input
          button[type="button"].btn_bp_color_thumb 색상 대표 썸네일 업로드
          button[type="button"].btn_bp_color_thumb_delete 삭제
          input.bp_color_thumb_file[type=file]
        .thumb
          img[src="${G.to_150(bpc.product_thumb_url) || ''}"]
      .bp_color_seamless_texture_url
        .input
          button[type="button"].btn_bp_color_seamless_texture_url 텍스처 업로드
          button[type="button"].btn_bp_color_seamless_texture_url_delete 삭제
          input.bp_color_seamless_texture_url_file[type=file]
        .seamless_texture_url
          img[src="${G.to_150(bpc.seamless_texture_url) || ''}"]
      .bp_color_faces[_sel="bp->_->base_product_colors->(#${bpc.id})->_->base_product_color_faces"]
        ${_p.sum(
          _p.v(bpc, '_.base_product_color_faces'),
          (bpcf) => pug`
        .bp_color_face[bpcf_id=${bpcf.id}]
          h4 ${bpcf.face_name}
          a[href="${bpcf.url}" class="download_original"] 면 원본
          a[href="${bpcf.mask_url}" class="download_original"] 마스크 원본
          button.remove_mask2_url[type="button" style="margin-top:8px;"] 마스크2 삭제
          .file.file_area[key="url"]
            label 컬러 면
              input.afile.img_upload[type=file name=file]
              br
              img[src=${G.to_150(bpcf.url)}]
          .mask.file_area[key="mask_url"]
            label 마스크
              input.mask_file.img_upload[type=file name=file]
              br
              img[src=${G.to_150(bpcf.mask_url)}]
          .mask2.file_area[key="mask2_url"]
            label 마스크2
              input.mask2_file.img_upload[type=file name=file]
              br
              img[src=${G.to_150(bpcf.mask2_url)}]
          .video.file_area[key="video_url"]
            label 동영상
              input.video_file[type=file name=file]
              br
              video[playsinline="" autoplay="" loop="" muted="" src="${
                bpcf.video_url || ''
              }" style="width: 100px; height: 100px; margin: 3px;"]
        `,
        )}
    `;
};

// G.$bp_bpd_thumbnails = _sum(_.t('thumb', '\
//   .item\
//     img[src="{{G.to_150(thumb.url)}}" url="{{thumb.url}}"]\
// '));
