import ProgressBar from 'progressbar.js';
import { each, go, groupBy, map, mapL, pluck, takeAll, uniqueByL } from 'fxjs/es';
import { $addClass, $closest, $find, $removeClass } from 'fxdom/es';
import {
  height_info,
  home_pos,
  printer_dpi,
  stock_label_field_data_info,
} from '../Constant/stock_label_formats.js';
import { LabelPage, StockLabelFormat } from '../../../zpl_module/zpl_class.js';
import { writeToZPLdata } from '../../../zpl_module/zpl_command_utils.js';
import { DfTaskConstantS } from '../../../../Task/S/Constant/module/DfTaskConstantS.js';
import { press_type_names } from '../../S/Constant/naming.js';
import axios from 'axios';
import { animateColor } from './labeling.js';
import { DfWaybillF } from '../../../../Waybill/F/Function/module/DfWaybillF.js';

export async function* zplWriteGenerator(device, label_zpl_arr) {
  const len = label_zpl_arr.length;
  for (let i = 0; i < len; i++) {
    await writeToZPLdata(device, label_zpl_arr[i]);
    yield (i + 1) / len;
  }
}

const printStockLabel = async (table, device) => {
  const stock_label_data = table.getData(true);
  const total_page_no = stock_label_data.length;
  const stock_label_format_filename = 'E:stock_format.ZPL';

  const format = new StockLabelFormat(
    stock_label_format_filename,
    printer_dpi,
    home_pos.x,
    home_pos.y,
    false,
  );

  format.setDefaultFont('J', 40);

  go(
    stock_label_field_data_info,
    each((d) => {
      const field = format.makeField(d.title, d.type, d.static_data, d.mutator, d.tree_name);
      const pos = d.position;
      const font = d.font;
      if (d.field_box) {
        const fb = d.field_box;
        field.setFieldBox(fb.width, fb.max_lines, fb.line_gap, fb.text_just, fb.indent);
      } else if (d.aztec_code) {
        field.setAztecCode(d.aztec_code.magnification);
      }
      format.pushFieldData(field, pos.x, pos.y, font?.name, font?.orient, font?.height, font?.width);
    }),
  );

  const label_pages_zpl_str = go(
    stock_label_data,
    // label_test_data,
    map((label) => {
      const page = new LabelPage(format, label, 'bp_c_ss');

      page.printCheckBoxes(0, 29.35, height_info.row, 35, 35, 3, 1);
      if (label.is_cancel_requested) {
        const letter_ctn = label.projection_id.toString().length;
        const letter_width = 35 / 6;
        const right_end_pos = 84;
        // 주문 취소인 경우 주문 번호에 두 줄 긋기
        go(
          [5, 8],
          each((y_pos) => {
            page.addGraphicBox(
              right_end_pos - letter_width * letter_ctn,
              y_pos,
              letter_width * letter_ctn,
              0.8,
              8,
              'B',
              0,
            );
          }),
        );
      }
      if (label.confirm_task_status === 'on') {
        // 발주가 진행중인 상태 (완료 => 진행 중 변경), 장기 미입고 혹은 입고 문제인 상품
        // 이 경우 발주 시각 옆에 점을 찍어 표시
        page.addGraphicCircle(40, 5.5, 28, 13);
      }
      // 발주 정해진 deadline 이후에 변경이 발생하여 라벨이 발주 내용과 mismatch 된 라벨
      // 주문 번호에 밑줄을 긋고 발주 시각 아래에 밑줄 긋기
      if (label.is_check) {
        page.addGraphicBox(30, 9, 9, 0.3, 3, 'B', 0);
        page.addGraphicBox(45, 13, 38, 0.8, 8, 'B', 0);
      }
      if (label.is_urgent) {
        page.addStarMark(39, 0, 45);
      } else {
        if (label.is_internal_urgent) {
          page.addBlankStarMark(39, 0, 45);
        }
      }
      if (label.is_repress) {
        page.addRepressMark(40, 10.5, 45);
      }

      // 아즈텍 우측 가장자리 인쇄 정상 여부 확인
      page.addGraphicBox(99.2, 13, 0.1, 2, 2, 'B', 0);
      //해당 라벨이 sub label 에서 마지막인 경우 EOF line 추가
      // const aztec_codes = parseAztecDataAdv(label.scan_info);
      // if (aztec_codes.label_id === aztec_codes.label_ctn) {
      page.addEOFLines(total_page_no);
      // }
      return page.toZPLStr();
    }),
  );

  const format_zpl = format.toDownloadFormat();
  await writeToZPLdata(device, format_zpl);

  const bar = new ProgressBar.Circle('#print_progress_bar', {
    color: '#222',
    // This has to be the same size as the maximum width to
    // prevent clipping
    strokeWidth: 10,
    trailWidth: 1,
    easing: 'easeInOut',
    duration: 1400,
    text: {
      autoStyleContainer: false,
    },
    from: { color: '#555', width: 7 },
    to: { color: '#222', width: 10 },
    // Set default step function for all animate calls
    step: function (state, circle) {
      circle.path.setAttribute('stroke', state.color);
      circle.path.setAttribute('stroke-width', state.width);
      const value = Math.round(circle.value() * 100);
      if (value === 0) {
        circle.setText('');
      } else {
        circle.setText(value);
      }
    },
  });
  bar.text.style.fontSize = '2rem';
  bar._container.style.zIndex = 99;

  $.don_loader_end();

  for await (const value of zplWriteGenerator(device, label_pages_zpl_str)) {
    bar.animate(value);
    if (value >= 1) {
      window.setTimeout(() => {
        bar._container.style.zIndex = 0;
        bar.destroy();
      }, 2000);
    }
  }
};

export const printStockLabels = (table) => async (e) => {
  if (!table.getDataCount()) {
    await $.alert('프린트 할 라벨이 없네요~<br>@( * O * )@');
  } else {
    if (await $.confirm('라벨을<br>정말 프린트 하시겠어요?<br>@( * O * )@')) {
      //라벨 프린트 할 때 is print 업데이트 할 수 있게 query 분리
      const is_header_filter_exist = table.getHeaderFilters();
      if (
        is_header_filter_exist.length &&
        !(await $.confirm(
          `적용된 필터가 발견되었어요!<br>필터에 제외된 라벨은<br>프린트 되지 않습니다.<br><br>의도하신 게 맞나요?<br>(╭ರ_•́)`,
        ))
      ) {
        await $.alert('필터를 모두 제거하시고<br>태스크 완료를 다시 진행해 주세요.<br>ᕙ(⇀‸↼‶)ᕗ');
        const clear_filter_el = go(
          e.currentTarget,
          $closest('.control_buttons'),
          $find('.button.clear_filter'),
          $addClass('blink'),
        );
        setTimeout(() => $removeClass('blink', clear_filter_el), 1000);
      } else {
        try {
          $.don_loader_start();
          const device = await DfWaybillF.prepareLabelPrinter({
            setting_fn: DfWaybillF.printer_config_fn.stock_label,
            allowed_devices: ['ZT411'],
          });
          await printStockLabel(table, device);
          $.don_loader_end();
        } catch (e) {
          $.don_loader_end();
          console.error(e);
          await $.alert(`라벨프린트에 문제가 있습니다.<br>연결 혹은 상태를 점검해 주세요.<br>${e}`);
        } finally {
          $.don_loader_end();
        }
      }
    }
  }
};

export const completeLabelTasks = (table) => async (e) => {
  if (!table.getDataCount()) {
    await $.alert('태스크 처리 할 라벨이 없어요~<br>@( * O * )@');
  } else {
    if (await $.confirm('라벨부착 태스크 처리를 진행하시겠어요?<br>@( * O * )@')) {
      const is_header_filter_exist = table.getHeaderFilters();
      if (
        is_header_filter_exist.length &&
        !(await $.confirm(
          `적용된 필터가 발견되었어요!<br>필터에 제외된 라벨은<br>라벨부착 태스크가 완료되지 않고<br>누락됩니다.<br><br>의도하신 게 맞나요?<br>(╭ರ_•́)`,
        ))
      ) {
        await $.alert('필터를 모두 제거하시고<br>태스크 완료를 다시 진행해 주세요.<br>ᕙ(⇀‸↼‶)ᕗ');
        const clear_filter_el = go(
          e.currentTarget,
          $closest('.control_buttons'),
          $find('.button.clear_filter'),
          $addClass('blink'),
        );
        setTimeout(() => $removeClass('blink', clear_filter_el), 1000);
      } else {
        await go(
          table.getData('active'),
          uniqueByL((data) => data.projection_id),
          mapL((data) => ({
            projection_id: data.projection_id,
            task_status:
              data.search_mode === 'holding'
                ? DfTaskConstantS.STATUS.completed
                : data.fast_track_outsourcing_is_show
                ? DfTaskConstantS.STATUS.completed
                : data.press_type === press_type_names.holding
                ? DfTaskConstantS.STATUS.on
                : DfTaskConstantS.STATUS.completed,
            search_mode: data.search_mode,
          })),
          takeAll,
          async (req_data) => {
            if (req_data.length) {
              $.don_loader_start();
              const update_group = go(
                req_data,
                groupBy((data) =>
                  ['holding', 'period'].includes(data.search_mode)
                    ? 'auto_task_update'
                    : 'manual_task_update',
                ),
              );
              if (update_group?.auto_task_update) {
                await axios
                  .patch('/@api/df/labels', update_group.auto_task_update)
                  .then(async (res) => {
                    await $.alert(
                      '라벨부착 태스크를<br>한방에 처리했어요~!<br><br>이미지작업 X => 진행중<br>이미지작업 O => 완료<br><br>ᕕ(⌐■_■)ᕗ ♪♬',
                    );
                  })
                  .catch(async (e) => {
                    console.error(e);
                    await $.alert(
                      `어머!<br>서버 에러가 났어요.<br>개발자에게 문의 주세요.<br>ˁ(OᴥO)ˀ<br>에러메세지: ${e}`,
                    );
                  });
              }
              if (update_group?.manual_task_update) {
                const manual_update_group_prj_ids = go(
                  update_group?.manual_task_update,
                  pluck('projection_id'),
                );
                if (
                  await $.confirm(
                    `${manual_update_group_prj_ids}<br>주문은 수동 추가된 라벨입니다.<br><br>태스크 완료를 진행하시겠어요?`,
                  )
                ) {
                  await axios
                    .patch('/@api/df/labels', update_group.manual_task_update)
                    .then(async (res) => {
                      await $.alert(
                        '라벨부착 태스크를<br>한방에 처리했어요~!<br><br>이미지작업 X => 진행중<br>이미지작업 O => 완료<br><br>ᕕ(⌐■_■)ᕗ ♪♬',
                      );
                      go(
                        manual_update_group_prj_ids,
                        each((prj_id) => {
                          go(
                            table.searchRows('projection_id', '=', prj_id),
                            each((row) => {
                              animateColor(row.getElement(), 200, 8, [
                                'rgba(220,155,2,0.69)',
                                'rgba(232,221,4,0.58)',
                              ]);
                            }),
                          );
                        }),
                      );
                      const idx = table
                        .searchRows('projection_id', '=', manual_update_group_prj_ids[0])[0]
                        .getIndex();
                      table.scrollToRow(idx, 'top', false);
                    })
                    .catch(async (e) => {
                      console.error(e);
                      await $.alert(
                        `어머!<br>서버 에러가 났어요.<br>개발자에게 문의 주세요.<br>ˁ(OᴥO)ˀ<br>에러메세지: ${e}`,
                      );
                    });
                }
              }
              $.don_loader_end();
            }
          },
        );
      }
      if (await $.confirm('이어서 라벨을 프린트 하시길 권장합니다.<br>프린트 하시겠어요?')) {
        try {
          $.don_loader_start();
          const device = await DfWaybillF.prepareLabelPrinter({
            setting_fn: DfWaybillF.printer_config_fn.stock_label,
            allowed_devices: ['ZT411'],
          });
          await printStockLabel(table, device);
          $.don_loader_end();
        } catch (e) {
          $.don_loader_end();
          await $.alert(`라벨 프린터 연결 혹은 상태를 점검해 주세요.<br>${e}`);
        } finally {
          $.don_loader_end();
        }
      }
    }
  }
};
