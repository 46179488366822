import { delay, each, extend, fromEntries, go, head, pick, sel, tap } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function//module/UtilS.js';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';
import { DfCateListThumbnailModalMuiF } from '../ThumbnailModal/F/Mui/module/DfCateListThumbnailModalMuiF.js';
import { $closest, $find, $next, $setHTML, $setVal, $trigger } from 'fxdom/es';

export const defnCateListRoute = async () => {
  if ($.query_to_object(location.search).warn)
    return (
      $.alert('오류입니다. 다시 시도해 주세요. 문제발생시 개발팀에 문의주세요.'),
      $.frame.extend_state(null, null, '/cate_list', 'replace')
    );
  const bp_cate_list = $1('#bp_cate_list');
  go(
    bp_cate_list,
    $.on('submit', '.delete form', async (e) => {
      e.preventDefault();
      if (
        await $.confirm(
          '카테고리에 포함된 모든 상품들의 카테고리를 잃어버리게됩니다. 삭제를 진행하시겠습니까?',
        )
      )
        return e.currentTarget.submit();
    }),
    $.on('change', 'li[type][_sel] > .info input', async (e) => {
      try {
        await go(
          e,
          sel('currentTarget'),
          (input) => [
            $.closest(input, 'li[type][_sel]'),
            $.attr(input, 'name'),
            input.type == 'checkbox'
              ? input.checked
              : input.type == 'radio' && !input.checked
              ? $.val(input)
              : (function (v) {
                  return typeof v == 'string' ? v.trim() : v;
                })($.val(input)),
          ],
          tap(([li, key, val]) =>
            $.put(
              `/@api/cate_list`,
              extend(
                pick(['id'], box.sel(li)),
                fromEntries([
                  [key, UtilS.trim(val)],
                  ['type', UtilS.trim($.attr(li, 'type'))],
                ]),
              ),
            ),
          ),
          tap(([li, key, val]) => (box.sel(li)[key] = val)),
          tap(([li, key, val]) => {
            if (key == 'no') {
              const ul = $.closest(li, 'ul');
              const list = box.sel(ul).sort((a, b) => a.no - b.no || a.id - b.id);
              const index = list.indexOf(box.sel(li));
              if (index == 0) return $.prepend(ul, li);
              else if (index == list.length - 1) return $.append(ul, li);
              const _li = $.eq($.find(ul, '>li'), index);
              const _index = list.indexOf(box.sel(_li));
              $[index > _index ? 'after' : 'before'](_li, li);
            } else if ($.attr(li, 'type') == 'bp_color_filters' && key == 'name2') {
              $.css($.find(li, '>.info label.name2 input'), 'background-color', val);
            }
          }),
          head,
          $.css({ filter: 'blur(1px)' }),
          delay(500),
          $.css({ filter: '' }),
        );
      } catch (e) {
        console.error(e);
        alert('오류입니다. 다시 시도해 주세요. 문제발생시 개발팀에 문의주세요.<br />');
      }
    }),
    $.on('click', '.mp-thumbnail-url', async (e) => {
      const item = go($.closest(e.currentTarget, 'li'), box.sel);
      await MuiF.openFrame(DfCateListThumbnailModalMuiF.frame, (f, p, [t]) => {
        f.closed = (f_el, result) => {
          if (result) {
            go(
              e,
              sel('currentTarget'),
              $closest('.mp-thumbnail'),
              $find('input[type="hidden"]'),
              $setVal(result),
              tap((el) => $trigger('change', el)),
              $next,
              $setHTML(`<img src="${G.to_150(result)}" alt="" />`),
            );
          }
        };
        t.makeData = () => {
          return {
            attached_id: 509,
            attached_type: 'dev_image',
            url: item.mp_thumbnail_url,
          };
        };
      });
    }),
    $.find('form1'),
    each((form1) => $.remove($.after(form1, form1.outerHTML.replace(/form1/g, 'form')))),
  );
};

export const defnCateListInfoRoute = () =>
  go(
    $1('#cate_list_info'),
    $.on('click', '.cate_list >.header button.save', function (e) {
      const el_cate_list = $.closest(e.currentTarget, '.cate_list');
      const cate_list_id = $.attr(el_cate_list, 'cate_list_id');
      go(
        $.put(`/@api/cate_list_info/${cate_list_id}`, {
          data: window.box.sel(el_cate_list).data,
        }),
        function (cate_list) {
          if (cate_list.id) alert('저장되었습니다.');
        },
      );
    }),
    $.on('click', '.cate_item button.save', function (e) {
      const el_cate_list = $.closest(e.currentTarget, '.cate_item');
      const cate_item_id = $.attr(el_cate_list, 'cate_item_id');
      const input = $.find1($.closest(e.currentTarget, '.cate_item'), 'input[type="hidden"]');
      go(
        $.put(`/@api/cate_item_info/${cate_item_id}`, {
          url: $.val(input),
        }),
        function (cate_item) {
          if (cate_item.id) alert('저장되었습니다.');
        },
      );
    }),
    $.on('change', '.cate_item input[type="file"]', function (e) {
      $.don_loader_start();
      go(
        $.upload(e.currentTarget, {
          url: '/@fileUpload/file',
          attached_type: $.attr(e.currentTarget, 'attached_type'),
          attached_id: $.attr(e.currentTarget, 'attached_id'),
        }),
        function (file) {
          $.val($.next(e.currentTarget, 'input[type="hidden"]'), file.url);
          $.attr($.find1($.closest(e.currentTarget, '.img'), 'img'), 'src', file.url);
          $.don_loader_end();
        },
      );
    }),
  );
