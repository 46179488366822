import { guards } from '../../../Lgl/S/Function/guards.js';
import { UtilAlertF } from '../../../../Util/Alert/F/Function/module/UtilAlertF.js';
import { createLabelFormatObj } from './label.helpers.js';
import { LABEL_FORMAT } from '../../S/Constant/formats.js';
import { filter, each, go, map } from 'fxjs/es';
import { LabelPage } from '../../../Stock/zpl_module/zpl_class.js';
import { getUpcsLabelNameTexts } from '../../../Lgl/F/Function/label.print.js';
import axios from 'axios';
import { makeApiUrl } from '../../../../Util/S/Function/util.js';
import { DfWaybillF } from '../../../Waybill/F/Function/module/DfWaybillF.js';
import { DfLabelF } from './module/DfLabelF.js';
import { zplWriteGenerator } from '../../../Stock/Labeling/F/Function/events.js';
import { alertsLgl } from '../../../Lgl/F/Function/alerts.js';

/**
 * @define DF projection list 에서 UPC 단위로 SKU 라벨 출력
 * @param {{_: {up_c_ss: object}}} upc
 * */
export async function printSkuLabel({ upc }) {
  const upcss = upc?._?.up_c_ss;
  guards.nullCheck(upcss, `Cannot get upcss from upc`);

  const base_product = upc?._?.base_product;
  guards.nullCheck(base_product, `Cannot get base_product from upc`);

  if (window.ENV?.label_printer_simulate) {
    await UtilAlertF.success({ title: 'SKU 라벨 출력 완료' });
    return;
  }

  const device = await DfWaybillF.prepareLabelPrinter({
    setting_fn: DfWaybillF.printer_config_fn.projection_label,
    allowed_devices: ['ZD420', 'ZD421'],
  });
  guards.nullCheck(device, `Cannot retrieve print device.`);

  const { FORMAT_DATA, PATH, DPI, LABEL_WIDTH, HOME_POS, IS_ROTATE } = LABEL_FORMAT.SKU;

  const sku_format = createLabelFormatObj({
    label_width: LABEL_WIDTH,
    home_pos: HOME_POS,
    path: PATH,
    dpi: DPI,
    is_rotate: IS_ROTATE,
  });

  formattingSkuLabel({ format: sku_format, format_data: FORMAT_DATA });

  await DfLabelF.setFormatZplToDevice({ device, format: sku_format });

  const label_page_zpl_str = await go(
    upcss,
    filter((upcs) => upcs.quantity > 0),
    map(async (upcs) => {
      const upcs_id = upcs.id;
      const sku = (
        await axios.get(
          makeApiUrl('/@api/df/sku/user_product_id/:user_product_id', { user_product_id: upcs_id }),
        )
      ).data;

      if (sku == null) {
        throw new Error(
          `SKU 정보가 없습니다.\nSKU 등록이 필요합니다.\n${JSON.stringify(
            {
              upcs_id,
              projection_id: upcs.projection_id,
              base_product_id: upcs.base_product_id,
              base_product_color_id: upcs.base_product_color_id,
              base_product_size_id: upcs.base_product_size_id,
              product_color_id: upcs.product_color_id,
            },
            null,
            2,
          )}`,
        );
      }

      const sku_no = sku.nickname ?? sku.id;

      const quantity = upcs.quantity;
      const { bp_name, product_option_str } = getUpcsLabelNameTexts({ upcs, base_product });
      const label_data = { sku_no, barcode: sku_no, bp_name, product_option_str };
      const page = new LabelPage(sku_format, label_data, null);
      return page.toZPLStr(quantity);
    }),
  );

  for await (const value of zplWriteGenerator(device, label_page_zpl_str)) {
    if (value === 1) {
      await alertsLgl.success({ title: 'SKU 라벨 출력 완료' });
    }
  }
}

function formattingSkuLabel({ format, format_data }) {
  go(
    format_data,
    each((f) => {
      const pos = f.position;
      const font = f.font;

      const field = format.makeField(
        f.title,
        f.type,
        f.static_data,
        f.mutator,
        f.tree_name,
        f.scan_code_type,
      );
      if (f.scan_code_type && f.scan_code_type.includes('code128')) {
        field.setCode128BarCode(f.scan_code_options);
      } else if (f.image) {
        const { mag, name } = f.image;
        field.setImage({ x: pos.x, y: pos.y, mag, name });
      }

      format.pushFieldData(field, pos.x, pos.y, font?.name, font?.orient, font?.height, font?.width);
    }),
  );
}
