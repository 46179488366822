import { main_tmpl } from './tmpl.js';
import { go, pipe } from 'fxjs/es';
import { $delegate, $find } from 'fxdom/es';
import {
  appendFcanvasRenderedByBpId,
  resetBpfSize,
  updateBaseProductFacesPrintArea,
} from './auto_cv_print_area_task.js';

$.frame.defn_frame({
  frame_name: 'auto_cv_print_area_task',
  page_name: 'auto_cv_print_area_task_page',
  is_modal: true,
  always_remove: true,
});

$.frame.defn_page({
  page_name: 'auto_cv_print_area_task_page',
  hide_frame_button_type: 'x',
  title: '프린트 영역 자동 세팅',
  tabs: [
    {
      tab_name: 'auto_cv_print_area_task_tab',
      template: main_tmpl,
      appended: pipe(
        $delegate('click', '.save', async function () {
          go($.confirm('리셋 하시면 프린트 영역값이 변경됩니다.'), async function (bool) {
            if (!bool) return;
            if (box().maker.product_color._.base_product._.base_product_sizes.length !== 1)
              return $.alert('사이즈가 하나가 아닐 경우 문제가 생길수 있습니다. 개발팀에 문의해 주세요.');
            $.don_loader_start();
            await updateBaseProductFacesPrintArea(box.sel('maker->product_color'));
            $.alert('리셋 되었습니다.');
            $.don_loader_end();
          });
        }),
        async function (el) {
          const before_reset_el = $find('.before_reset', el);
          const after_reset_el = $find('.after_reset', el);

          const { product_color_id } = await $.get('/@api/only_base_product_with_pc_id', {
            base_product_id: box().bp.id,
          });
          const product_color = await $.get('/@api/product_color', {
            id: product_color_id,
          });
          /*DF*/
          await appendFcanvasRenderedByBpId(product_color, before_reset_el);
          resetBpfSize(product_color);
          await appendFcanvasRenderedByBpId(product_color, after_reset_el);
        },
      ),
    },
  ],
});

export function openAutoCvPrintAreaTaskFrame(bp) {
  if (!bp.can_overflow) return $.alert('전면 인쇄가 아니라 현재 프린트 영역 작업이 불가능합니다.');
  $.frame.open({
    frame_name: 'auto_cv_print_area_task',
  });
}
