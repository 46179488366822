import { find, go, map, sel, reject, flatMap, filter, pluck, compact } from 'fxjs/es';

export const selectedOptionGroup = {
  getPreviewImagesInMaker: (base_products_bp_option_groups, selected_option_group) => {
    return go(
      base_products_bp_option_groups,
      flatMap((bpbog) => bpbog._.bp_option_group._.bp_options),
      filter(({ id }) => selected_option_group.bp_option_ids.includes(id)),
      pluck('preview_meta'),
      flatMap(sel('preview_image')),
      compact,
    );
  },
  getSize: (selected_option_group) => {
    return go(
      selected_option_group?.option_names,
      find((option_name) => option_name.option_group_name === '사이즈'),
    );
  },
  getSizeValue: (selected_option_group, _en = G._en) => {
    return go(selectedOptionGroup.getSize(selected_option_group), sel('option_name' + _en));
  },
  getNotSizeValue: (selected_option_group, _en = G._en) => {
    return go(
      selected_option_group?.option_names,
      reject((option) => option.option_group_name === '사이즈'),
    );
  },
  getOnlyOptionNames: (selected_option_group, _en = G._en) => {
    return go(selected_option_group?.option_names, map(sel('option_name' + _en)));
  },
  getSortedOptions: (selected_option_group, _en = G._en) => {
    return go(
      selected_option_group?.option_names,
      map((option_name) => {
        return {
          option_group_name: option_name['option_group_name' + _en],
          option_name: option_name['option_name' + _en],
        };
      }),
    );
  },
  getOnlyOptionTitle: (selected_option_group, _en = G._en) => {
    return selected_option_group['title' + _en];
  },
};
