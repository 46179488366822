import { go, entries, each, reduce, range, map } from 'fxjs/es';
import { $attr } from 'fxdom/es';
import { adjustment_items, calcTransformFitting } from './module/index.js';

export const setupShaderProgramToGL = (gl, program_name, vertex_src, fragment_src) => {
  const vertex_shader = createShader(gl, gl.VERTEX_SHADER, vertex_src);
  const fragment_shader = createShader(gl, gl.FRAGMENT_SHADER, fragment_src);
  const program = gl.createProgram();
  gl.attachShader(program, vertex_shader);
  gl.attachShader(program, fragment_shader);
  gl.linkProgram(program);
  if (gl.getProgramParameter(program, gl.LINK_STATUS)) {
    gl.programs = gl.programs || {};
    gl.programs[program_name] = program;
  } else {
    const err_msg = gl.getProgramInfoLog(program);
    gl.deleteProgram(program);
    throw new Error(err_msg);
  }
};

const createShader = (gl, type, src) => {
  const shader = gl.createShader(type);
  gl.shaderSource(shader, src);
  gl.compileShader(shader);
  if (gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    return shader;
  } else {
    const err_msg = gl.getShaderInfoLog(shader);
    gl.deleteShader(shader);
    throw new Error(err_msg);
  }
};

export const setupAttribBufferToProgram = (gl, program, attr_name, data) => {
  const gl_buffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, gl_buffer);
  gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW);
  const attr_location = gl.getAttribLocation(program, attr_name);
  gl.enableVertexAttribArray(attr_location);
  gl.vertexAttribPointer(attr_location, 2, gl.FLOAT, false, 0, 0);
  program.buffers = program.buffers || {};
  program.buffers[attr_name] = gl_buffer;
};

export const setRectFloat = (x1, x2, y1, y2) =>
  new Float32Array([x1, y1, x2, y1, x1, y2, x1, y2, x2, y1, x2, y2]);

export const getSingleComponentAdjArray = (single_components_els) => {
  return go(
    single_components_els,
    map((adj$) => {
      const obj = {};
      const adjustment_name = $attr('item', adj$);
      const item_data = adjustment_items[adjustment_name];
      obj.name = adjustment_name;
      obj.value = Number(adj$.value);
      obj.uniform = item_data.uniform;
      obj.gl_name = item_data.gl_name;
      return obj;
    }),
  );
};

export const updateFloatValueToGl = (gl, override, program, val, uniform) => {
  updateUniforms(gl, gl.programs[program], {
    [uniform]: { type: '1f', value: [val] },
  });
};

export const updateUniformsFromArray = (image_editor_gl, view_gl, component_arr, override) => {
  go(
    component_arr,
    each((adj_item) => {
      if (adj_item.gl_name === 'image_editor') {
        updateFloatValueToGl(image_editor_gl, override, 'image_editor', adj_item.value, adj_item.uniform);
      } else if (adj_item.gl_name === 'view') {
        view_gl && updateFloatValueToGl(view_gl, override, 'view', adj_item.value, adj_item.uniform);
      }
    }),
  );
};

const createAndSetupNullTexture = (gl, gl_filter) => {
  const gl_texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, gl_texture);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl_filter);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl_filter);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    gl.canvas.width,
    gl.canvas.height,
    0,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    null,
  );
  return gl_texture;
};
const isPowerOfTwo = (value) => (value & (value - 1)) === 0;

export const setupTextureToGL = (gl, texture_src, gl_filter) => {
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);
  if (texture_src) {
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, texture_src);
    if (isPowerOfTwo(texture_src.width) && isPowerOfTwo(texture_src.height)) {
      gl.generateMipmap(gl.TEXTURE_2D);
    }
  }
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl_filter);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl_filter);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texture = texture;
};

export const updateTexture = (gl, gl_texture, img_or_can) => {
  gl.bindTexture(gl.TEXTURE_2D, gl_texture);
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, img_or_can);
  return gl_texture;
};

export const updateUniforms = (gl, program, uniforms) => {
  gl.useProgram(program);
  go(
    entries(uniforms),
    each(([k, v]) => {
      if (v.type.includes('Matrix')) {
        gl[`uniform${v.type}`](program.locations[k], false, ...v.value);
      } else {
        gl[`uniform${v.type}`](program.locations[k], ...v.value);
      }
    }),
  );
};
export const updateCropOn = (gl, is_on) => {
  updateUniforms(gl, gl.programs.view, {
    u_crop_on: { type: '1i', value: [is_on] },
  });
};

export const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') >= 0 && ua.indexOf('chrome') < 0 && ua.indexOf('android') < 0;
};

export const updateViewport = (vp) => (gl) => {
  gl.viewport(...Object.values(vp));
  return gl;
};

export const updateViewportScale = (vp_scale) => (gl) => {
  gl.uniform1f(gl.programs.view.locations.u_viewport_scale_ratio, vp_scale);
  return gl;
};

export const drawGLcanvas = (gl) => {
  gl.drawArrays(gl.TRIANGLES, 0, 6);
  return gl;
};

export const setupFrameBuffersToGL = (gl, frame_num) => {
  gl.frames = go(
    range(frame_num),
    map((_) => {
      const texture = createAndSetupNullTexture(gl, gl.LINEAR);
      const buffer = createFrameBufferSetupFrameTexture(gl, texture);
      return { texture, buffer };
    }),
  );
};

const createFrameBufferSetupFrameTexture = (gl, frame_texture) => {
  const frame_buffer = gl.createFramebuffer();
  gl.bindFramebuffer(gl.FRAMEBUFFER, frame_buffer);
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, frame_texture, 0);
  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  return frame_buffer;
};

export const setupUniformLocationsToProgram = (gl, program, uniform_names) => {
  program.locations = reduce(
    (acc, uniform) => {
      acc[uniform] = gl.getUniformLocation(program, uniform);
      return acc;
    },
    {},
    uniform_names,
  );
};

export const getTransformData = (gl, transform_els) => {
  const transform_data = go(transform_els, (el_inputs) =>
    reduce(
      (acc, input) => {
        const k = $attr('item', input);
        acc[k] = Number(input.value);
        return acc;
      },
      {},
      el_inputs,
    ),
  );
  return calcTransformFitting(gl, transform_data);
};
