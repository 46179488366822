import { go, throttle } from 'fxjs/es';
import { $delegate } from 'fxdom/es';
import {
  BRUSH_PROPERTY_NAME,
  DATA_SET_KEY,
  ITEM_NAME,
  LAYER_NAME,
  SUB_TOOL_NAME,
  TOOL_NAME,
} from '../../S/Constant/constants.js';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';
import { redoBrush, undoBrush } from './tool.brush.js';
import { cropCanvas, getJustifyImageSize } from '../../../../Maker/F/canvas_trim.js';

export function toolErase({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const erase_layer = DfImageEditorF.getLayerByName({ paper_scope, name: LAYER_NAME.erase });

  DfImageEditorF.addPrintableRasterRectangleBound({ paper_scope, target_layer: erase_layer });
  go(
    tab_el,
    $delegate('click', `.property-panel .controls[tool-name=${TOOL_NAME.erase}] button`, async (e) => {
      const ct = e.currentTarget;
      const control_name = ct.name;
      switch (control_name) {
        case 'undo': {
          DfImageEditorF.undoBrush({ layer: erase_layer });
          break;
        }
        case 'redo': {
          DfImageEditorF.redoBrush({ layer: erase_layer });
          break;
        }
        case 'apply': {
          await doErase({ paper_scope });
          break;
        }
        case 'cancel': {
          DfImageEditorF.cancelErase({
            tab_el,
            onAfterRestore: async (printable_raster) => {
              const erase_layer = DfImageEditorF.getEraseLayer({ paper_scope });
              DfImageEditorF.removeAllBrushGroup({ layer: erase_layer });

              if (DfImageEditorF.hasStrokeItem({ paper_scope })) {
                await DfImageEditorF.reCalculateStroke({
                  paper_scope,
                });
              }
            },
          });
          break;
        }
      }
    }),
  );
}

export async function doErase({ paper_scope }) {
  const erase_layer = DfImageEditorF.getEraseLayer({ paper_scope });
  const visible_brush_items = DfImageEditorF.getAllVisibleBrushItems({ layer: erase_layer });

  if (visible_brush_items.length > 0) {
    await DfImageEditorF.applyEraseLayerToPrintableRaster({ tab_el: paper_scope.tab_el });
  }
}

export async function activateEraseTool({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });

  const erase_layer = DfImageEditorF.getLayerByName({ paper_scope, name: LAYER_NAME.erase });
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });

  const erase_tool_name = TOOL_NAME.erase;
  let erase_brush_item = DfImageEditorF.getBrushGuideItem({
    paper_scope,
    tool_name: erase_tool_name,
  });
  let is_erasing = false;

  const applyAdaptiveBrushColor = throttle(() => {
    DfImageEditorF.updateBrushColorBasedOnRasterSubPixel({
      raster: printable_raster,
      brush_item: erase_brush_item,
    });
  }, 25);

  const erase_tool = DfImageEditorF.prepareTool({
    paper_scope,
    tool_name: erase_tool_name,
    eventHandlers: {
      activate: () => {
        if (erase_brush_item == null) {
          const initial_brush_size = DfImageEditorF.getInitialBrushSize({ paper_scope });
          erase_brush_item = DfImageEditorF.createBrush({
            paper_scope,
            tool_name: erase_tool_name,
            radius: initial_brush_size / 2,
          });
          DfImageEditorLibF.setState.property.brush.item(erase_tool_name, erase_brush_item);
          DfImageEditorLibF.setState.property.brush.size(erase_tool_name, initial_brush_size, true);
        }
        DfImageEditorF.activateToolLayer({ paper_scope });
        DfImageEditorF.activateBrush({ brush_item: erase_brush_item });
      },
      deactivate: () => {
        DfImageEditorF.deactivateToolLayer({ paper_scope });
        DfImageEditorF.deactivateBrush({ brush_item: erase_brush_item });
      },
      mousedown: (e) => {
        if (e.event.button === 0) {
          if (DfImageEditorLibF.view_control.isControlling() === false) {
            is_erasing = true;
            DfImageEditorF.erase({
              paper_scope,
              layer: erase_layer,
              tool_name: TOOL_NAME.erase,
              position: erase_brush_item.position,
              is_history_clean: true,
              options: {
                /* destination-out 으로 해당 painting group 으로 아래 layer 를 지움  */
                blendMode: 'destination-out',
              },
            });
          }
        }
      },
      mousemove: (e) => {
        const brush_position = e.point;
        erase_brush_item.position = brush_position;
        const sub_tool = DfImageEditorLibF.getState.subTool[TOOL_NAME.erase]();

        if (sub_tool === SUB_TOOL_NAME.erase.brush) {
          /* brush 일 때 brush path 의 색상을 배경색에 맞게 동적으로 변경 */
          applyAdaptiveBrushColor();
        }

        if (is_erasing) {
          DfImageEditorF.erase({
            paper_scope,
            layer: erase_layer,
            tool_name: TOOL_NAME.erase,
            position: brush_position,
          });
        }
      },
      mouseup: () => {
        if (is_erasing) {
          DfImageEditorF.paintBrushEnd({ layer: erase_layer });
          is_erasing = false;
        }
      },
      keydown: async (e) => {
        const tool_name = TOOL_NAME.erase;
        const keyboard_event = e.event;
        const layer = erase_layer;
        const brush_properties = DfImageEditorLibF.getBrushState(tool_name);
        if (brush_properties == null) {
          throw new Error(`Not exist brush properties having name ${tool_name}`);
        }

        const is_shift = keyboard_event.shiftKey;
        const STEP_MULTIPLIER = 3;

        /* Undo - Redo */
        if (keyboard_event.code === 'KeyZ') {
          if (keyboard_event.metaKey || keyboard_event.ctrlKey) {
            keyboard_event.shiftKey ? redoBrush({ layer }) : undoBrush({ layer });
          }
        }
        if (DfImageEditorF.isNotKeyDownWithMeta({ keyboard_event })) {
          /* Size */
          if (keyboard_event.code === 'BracketLeft' || keyboard_event.code === 'BracketRight') {
            // brush size control
            let new_size;
            const { value: current_size, max, min, step } = brush_properties[BRUSH_PROPERTY_NAME.size];
            const displacement = is_shift ? step : step * STEP_MULTIPLIER;

            if (keyboard_event.code === 'BracketLeft') {
              new_size = Math.max(current_size - displacement, min);
            } else {
              new_size = Math.min(current_size + displacement, max);
            }
            DfImageEditorLibF.setState.property.brush[BRUSH_PROPERTY_NAME.size](tool_name, new_size, true);
          }

          /* Apply */
          if (keyboard_event.code === 'KeyA') {
            await doErase({ paper_scope });
          }
          /* Hardness */
          if (keyboard_event.code === 'Comma' || keyboard_event.code === 'Period') {
            // brush hardness control
            let new_hardness;
            const {
              value: current_hardness,
              max,
              min,
              step,
            } = brush_properties[BRUSH_PROPERTY_NAME.hardness];
            const displacement = is_shift ? step : step * STEP_MULTIPLIER;
            if (keyboard_event.code === 'Comma') {
              new_hardness = Math.max(current_hardness - displacement, min);
            } else {
              new_hardness = Math.min(current_hardness + displacement, max);
            }
            DfImageEditorLibF.setState.property.brush[BRUSH_PROPERTY_NAME.hardness](
              tool_name,
              new_hardness,
              true,
            );
          }
        }
      },
    },
    initializeFn: (tool) => {
      DfImageEditorLibF.setState.property.brush.tool(erase_tool_name, tool);
    },
  });
  erase_tool.activate();
}

export async function applyEraseLayerToPrintableRaster({ tab_el }) {
  try {
    $.don_loader_start();
    const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
    const erase_layer = DfImageEditorF.getEraseLayer({ paper_scope });
    const onAfterErase = async ({ printable_raster }) => {
      DfImageEditorF.removeAllBrushGroup({ layer: erase_layer });
      if (DfImageEditorF.hasStrokeItem({ paper_scope })) {
        await DfImageEditorF.reCalculateStroke({
          paper_scope,
        });
      }

      if (DfImageEditorF.isExistRasterStroke({ tab_el })) {
        await DfImageEditorF.doRasterStroke({ tab_el: paper_scope.tab_el });
      }
    };
    await erasePrintableRaster({
      paper_scope,
      erase_layer,
      onAfterErase,
    });
  } catch (e) {
    await DfImageEditorF.donAlertAsync({
      tab_el,
      msg: e.message,
    });
  } finally {
    $.don_loader_end();
  }
}

export async function erasePrintableRaster({ paper_scope, erase_layer, onBeforeErase, onAfterErase }) {
  onBeforeErase && onBeforeErase();
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });

  const scale = printable_raster.scaling.x;

  const erase_clone_layer = scalingEraseLayer({
    erase_layer,
    center: printable_raster.bounds.center,
    is_clone: true,
    scale: 1 / scale,
  });
  erase_clone_layer.visible = true;

  DfImageEditorF.allErasedItemBlendModeSwitchBackToNormal({ erase_layer: erase_clone_layer });

  const { pixelRatio, resolution } = paper_scope.project.view;

  const erase_layer_raster = erase_clone_layer.rasterize({
    resolution: resolution / pixelRatio,
    insert: false,
  });

  DfImageEditorF.addDataToItem({
    item: printable_raster,
    data: {
      previous_src: printable_raster.data.next_src ?? printable_raster.source,
    },
  });

  const erase_layer_raster_data_url = erase_layer_raster.toDataURL();

  const erase_layer_image = await DfImageEditorF.dataUrlToImageEl({
    data_url: erase_layer_raster_data_url,
  });

  const rb = printable_raster.bounds.clone().scale(1 / scale);
  const eb = erase_layer_raster.bounds;

  const x = rb.x - eb.x;
  const y = rb.y - eb.y;
  const w = rb.width;
  const h = rb.height;

  const ctx = printable_raster.canvas.getContext('2d');
  ctx.globalCompositeOperation = 'destination-out';
  ctx.drawImage(erase_layer_image, x, y, w, h, 0, 0, w, h);

  const justify_size = getJustifyImageSize({ canvas: printable_raster.canvas, margin: 0 });
  DfImageEditorF.updateDesignSizeChange({ printable_raster, erase_delta: justify_size.y });

  const justify_erased_canvas = cropCanvas({ canvas: printable_raster.canvas, size: justify_size });

  const erased_data_url = justify_erased_canvas.toDataURL();

  return new Promise((resolve, reject) => {
    printable_raster.onLoad = async () => {
      DfImageEditorF.addDataToItem({
        item: printable_raster,
        data: {
          next_src: erased_data_url,
        },
      });

      DfImageEditorF.setDesignCmSize({ tab_el: paper_scope.tab_el, with_dom_update: true });
      printable_raster.onLoad = null;

      onAfterErase && (await onAfterErase({ printable_raster }));
      resolve();
    };
    printable_raster.onError = (e) => {
      reject(e);
    };

    printable_raster.source = erased_data_url;
  });
}

export function restoreOriginalRaster({ paper_scope, original_image_src }) {
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
  printable_raster.source = original_image_src;
}

export function hasRemainingEraseTask({ paper_scope }) {
  const erase_layer = DfImageEditorF.getEraseLayer({ paper_scope });
  const brush_items = DfImageEditorF.getAllVisibleBrushItems({ layer: erase_layer });
  return brush_items.length > 0;
}

function createEraseRectangle({ paper_scope, painting_group, position }) {
  const from = DfImageEditorF.getDataFromItem({ item: painting_group, key: 'from' });
  const erase_rectangle = new paper_scope.Path.Rectangle({ from: from ?? position, to: position });
  erase_rectangle.selected = true;
  erase_rectangle.fillColor = 'black';
  erase_rectangle.strokeWidth = 0;

  return erase_rectangle;
}

function createEraseCircle({ paper_scope, painting_group, position }) {
  const center = DfImageEditorF.getDataFromItem({ item: painting_group, key: 'center' });
  const radius = center.getDistance(position);
  const erase_circle = new paper_scope.Path.Circle({ center, radius });
  erase_circle.selected = true;
  erase_circle.fillColor = 'black';
  erase_circle.strokeWidth = 0;

  return erase_circle;
}

export function allErasedItemBlendModeSwitchBackToNormal({ erase_layer }) {
  erase_layer.blendMode = 'normal';
  const erased_items = DfImageEditorF.getAllBrushItems({ layer: erase_layer });
  if (erased_items == null || erased_items.length === 0) return;
  erased_items.forEach((item) => {
    item.blendMode = 'normal';
  });
}

export function erase({ paper_scope, layer, tool_name, position, options, is_clean_history = false }) {
  /* paint brush 는 grouping
   *  grouping 규칙, name = "painting" | null
   *  painting 이 종료되면 반드시 name 을 null 로 변경해 줘야 함.
   * */

  if (is_clean_history) {
    DfImageEditorF.removeAllInvisibleBrushGroup({ layer });
    const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });
    DfImageEditorF.removeDataFromItem({ item: printable_raster });
  }

  const painting_group = DfImageEditorF.createBrushPaintingGroup({
    paper_scope,
    layer,
    fnWhenCreate: ({ painting_group }) => {
      if (options) {
        Object.entries(options).forEach(([k, v]) => {
          painting_group[k] = v;
        });
      }
    },
    data_tag_obj: { [DATA_SET_KEY.is_erase_brush]: true },
  });

  const sub_tool = DfImageEditorLibF.getState.subTool[tool_name]();
  switch (sub_tool) {
    case SUB_TOOL_NAME.erase.square: {
      if (painting_group.children.length === 0) {
        painting_group.selected = true;
        DfImageEditorF.addDataToItem({ item: painting_group, data: { from: [position.x, position.y] } });
        const rect = createEraseRectangle({ paper_scope, painting_group, position });
        painting_group.addChild(rect);
      } else {
        const rect = createEraseRectangle({ paper_scope, painting_group, position });
        painting_group.addChild(rect);
        painting_group.children[0].remove();
      }
      break;
    }
    case SUB_TOOL_NAME.erase.circle: {
      if (painting_group.children.length === 0) {
        painting_group.selected = true;
        DfImageEditorF.addDataToItem({ item: painting_group, data: { center: position } });
        const circle = createEraseCircle({ paper_scope, painting_group, position });
        painting_group.addChild(circle);
      } else {
        const circle = createEraseCircle({ paper_scope, painting_group, position });
        painting_group.addChild(circle);
        painting_group.children[0].remove();
      }
      break;
    }
    case SUB_TOOL_NAME.erase.free: {
      if (painting_group.children.length === 0) {
        painting_group.selected = true;
        const free_path = new paper_scope.Path();
        free_path.strokeWidth = 0;
        free_path.add(position);
        painting_group.fillColor = 'Black';
        painting_group.addChild(free_path);
      } else {
        painting_group.selected = true;
        painting_group.fillColor = 'Black';
        const free_path = painting_group.children[0];
        free_path.add(position);
      }
      break;
    }
    case SUB_TOOL_NAME.erase.brush: {
      const brush_state = DfImageEditorLibF.getBrushState(tool_name);
      const brush_radius = brush_state[BRUSH_PROPERTY_NAME.size].value / 2;
      const brush_hardness = brush_state[BRUSH_PROPERTY_NAME.hardness];

      /* 브러쉬 경도가 100 인 경우 path 생성 - stroke 속성으로 그리기 */
      /* 그렇지 않은 경우 수채화 느낌을 제공하기 위해 circle 을 연속 생성 */
      if (brush_hardness.value === 100) {
        if (painting_group.children.length === 0) {
          const brush_path = new paper_scope.Path(position);
          brush_path.add(position);
          brush_path.strokeWidth = brush_radius * 2;
          brush_path.strokeCap = 'round';
          brush_path.strokeJoin = 'round';
          brush_path.strokeColor = 'Black';
          brush_path.name = ITEM_NAME.brush_path;
          painting_group.addChild(brush_path);
        } else {
          const exist_brush_path = painting_group.getItem({ name: ITEM_NAME.brush_path });
          if (exist_brush_path == null) return;
          if (exist_brush_path instanceof paper_scope.Path) {
            exist_brush_path.add(position);
          }
        }
      } else {
        const brush_circle = new paper_scope.Path.Circle({ center: position, radius: brush_radius });
        brush_circle.name = ITEM_NAME.brush_circle;
        brush_circle.fillColor = new paper_scope.Color(
          brush_hardness.gradientColor,
          brush_circle.bounds.center,
          brush_circle.bounds.rightCenter,
        );
        brush_circle.strokeWidth = 0;
        painting_group.addChild(brush_circle);
      }

      break;
    }
  }
}

function scalingEraseLayer({ erase_layer, center, is_clone, scale }) {
  if (is_clone) {
    erase_layer = erase_layer.clone({ insert: false });
  }
  erase_layer.scale(scale, center);

  /* style 속성은 layer scaling 과 비례 적용되지 않으므로 직접 style 값을 scaling 해 주어야 함. */
  findAllEraseBrushes({ erase_layer }).forEach((erase_item) => {
    erase_item.strokeWidth > 0 && (erase_item.strokeWidth *= scale);
  });

  return erase_layer;
}

export function findAllEraseBrushes({ erase_layer }) {
  return DfImageEditorF.getItemsInLayer({
    layer: erase_layer,
    condition: { data: { [DATA_SET_KEY.is_erase_brush]: true } },
  });
}
