import { defnPromotionCodeTab, defnPromotionCodeUpsertTab } from './tab.js';

$.frame.defn_page({
  page_name: 'df.promotion_code',
  tabs: [defnPromotionCodeTab()],
});

$.frame.defn_page({
  page_name: 'df.promotion_code.upsert',
  tabs: [defnPromotionCodeUpsertTab()],
});
