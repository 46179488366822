import axios from 'axios';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export const handleTransferProductComplete = async (e) => {
  try {
    $.don_loader_start();

    const {
      data: { count_stock, count_transfer },
    } = await axios.patch('/@api/tasks/update_product_transfer_stock_team');

    $.don_loader_end();

    if (Number(count_stock) + Number(count_transfer)) {
      $.alert(
        `${UtilS.commaThousands(count_stock)}개 재고 확인 태스크<br>
        ${UtilS.commaThousands(count_transfer)} 의 라벨부착 태스크<br>
        완료 처리되었습니다.`,
      );
    } else {
      $.alert('완료할 태스크가 없습니다!');
    }
  } catch (err) {
    $.alert(`에러가 있습니다. ${err.message}`);
    $.don_loader_end();
  }
};
