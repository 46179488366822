import { equals2, isNil } from 'fxjs/es';
import { VectorEditorStickerFreeMobileF } from '../Function/module/VectorEditorStickerFreeMobileF.js';

const handleFreeStickerEditorSelectOrUnselect =
  ({ tab_el }) =>
  (event) => {
    const count = event?.detail?.selected_els?.length;

    if (count == null || count <= 0) {
      VectorEditorStickerFreeMobileF.showTypeNoneFooters(tab_el);
    } else if (count <= 1) {
      VectorEditorStickerFreeMobileF.showTypeSelectFooters(tab_el);
    } else {
      VectorEditorStickerFreeMobileF.hideAllFooters(tab_el);
    }
  };

export const handleFreeStickerEditorSelect =
  ({ tab_el }) =>
  (event) =>
    handleFreeStickerEditorSelectOrUnselect({ tab_el })(event);

export const handleFreeStickerEditorUnselect =
  ({ tab_el }) =>
  (event) =>
    handleFreeStickerEditorSelectOrUnselect({ tab_el })(event);

export const handleFreeStickerEditorAdd =
  ({ tab_el }) =>
  async (event) => {
    try {
      VectorEditorStickerFreeMobileF.setOffsetPathData({
        tab_el,
        wrapper_el: event?.detail?.wrapper_el,
        id: event?.detail?.id,
      });
      VectorEditorStickerFreeMobileF.transformOffsetPathData({
        tab_el,
        wrapper_el: event?.detail?.wrapper_el,
        id: event?.detail?.id,
      });
      VectorEditorStickerFreeMobileF.checkCollision({ tab_el });
    } catch (error) {
      console.error(error);
      const message = error?.__mp_alert_message;
      if (message) {
        await $.alert(message);
      }
    }
  };

export const handleFreeStickerEditorRemove =
  ({ tab_el }) =>
  async () => {
    try {
      const sticker_count = tab_el.__mp_free_sticker_editor?.getEls()?.length;
      if (tab_el.__mp_free_template_svg_el != null && sticker_count != null && sticker_count <= 0) {
        tab_el.__mp_free_template_svg_el.dataset.is_show = 'true';
        VectorEditorStickerFreeMobileF.hide2TypeNoneMakeStickerFooter(tab_el);
      }

      VectorEditorStickerFreeMobileF.checkCollision({ tab_el });
    } catch (error) {
      console.error(error);
      const message = error?.__mp_alert_message;
      if (message) {
        await $.alert(message);
      }
    }
  };

export const handleFreeStickerEditorTransform =
  ({ tab_el }) =>
  async (event) => {
    const { el, transform_status, transform_type } = event?.detail ?? {};

    if (isNil(el)) {
      return;
    }

    if (!equals2(transform_status)('end')) {
      return;
    }

    if (!new Set(['translate', 'rotate', 'scale']).has(transform_type)) {
      return;
    }

    try {
      VectorEditorStickerFreeMobileF.transformOffsetPathData({
        tab_el,
        wrapper_el: el,
      });
      VectorEditorStickerFreeMobileF.checkCollision({ tab_el });
    } catch (error) {
      console.error(error);
      const message = error?.__mp_alert_message;
      if (message) {
        await $.alert(message);
      }
    }
  };
