import { each, go, take } from 'fxjs/es';
import { legacyHtml } from '../../Util/S/Function/util.js';
import { makeRangeSliderEvent, makeRangeSliderHtml } from './range_slider.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';

function convert1To100(num) {
  return parseInt((num * 100).toFixed());
}
function convert100To1(num) {
  return num / 100;
}

export function isPossibleOpacity() {
  return !UtilF.isLegacyMobile() && $1('html#dream_factory');
}

function makeOpacityFunctionAttr(cv_text) {
  return {
    id: 'opacity_function',
    title: T('maker::Opacity'),
    value: cv_text && convert1To100(cv_text.opacity),
    min: 1,
    max: 100,
    step: 1,
    render: function (e) {
      const active = G.mp.maker.active();
      active.set('opacity', convert100To1($.val(e.currentTarget))).canvas.renderAll();
      return convert1To100(active.opacity);
    },
  };
}

export const makeOpacityFunctionHtml = function (cv_obj) {
  if (!isPossibleOpacity()) return legacyHtml` <div class="display_none" style="display:none;"></div> `;
  return makeRangeSliderHtml(makeOpacityFunctionAttr(cv_obj));
};

export const makeOpacityFunctionEvent = function (el) {
  if (!isPossibleOpacity()) return el;
  return makeRangeSliderEvent(el, makeOpacityFunctionAttr());
};

export const makeObjsOpacity1 = function (f_canvas) {
  f_canvas = f_canvas || G.mp.maker.editing_canvas();
  go(
    G.mp.maker.designs(f_canvas),
    each(function (design) {
      design.opacity = 1;
    }),
  );
  f_canvas.renderAll();
};

export const makeAllobjsOpacity1 = function () {
  go(
    box.sel('maker->canvass'),
    take($.attr($1('#maker_frame'), 'faces_length')),
    each((f_canvas) => makeObjsOpacity1(f_canvas)),
  );
};
