export default function (lo) {
  $.frame.defn_frame({
    frame_name: 'mp.guideline_frame',
    page_name: 'mp.guideline_page',
    el_class: 'guideline_page',
  });

  $.frame.defn_page({
    page_name: 'mp.guideline_page',
    tabs: [
      {
        tab_name: 'mp.guideline_tab',
      },
      {
        tab_name: 'mp.guideline_copy_tab',
        appended: __(
          $.on('click', '.qna .q', function (e) {
            const item = $.closest(e.$currentTarget, 'li');

            if ($.attr(item, 'selected') == true) {
              $.attr(item, 'selected', false);
              return;
            }
            $.attr(item, 'selected', true);
            $.scroll_top2($('html, body'), e.$currentTarget.offsetTop - 100 || 0, 400);
          }),
        ),
      },
    ],
  });

  $.frame({
    frame_name: 'mp.guideline_frame',
  });
}
