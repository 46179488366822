import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from './module/OMPCoreAtomTmplS.js';

/**
 * @property {string} [children = '']
 * @property {string} [customClass = '']
 * @property {string} [navigationClass = '']
 * @property {boolean} [arrow = false]
 * @property {boolean} [pagination = false]
 */
export const swiper = (params) => {
  const { children = '', customClass = '', navigationClass = '', arrow = false, pagination = false } = params;
  return html`
    <div class="omp-swiper__container">
      <div class="omp-swiper swiper-container ${customClass}">
        <div class="swiper-wrapper">${children}</div>
        <!-- pagination -->
        ${pagination ? `<div class="omp-swiper-pagination"></div>` : ``}
      </div>
      <!-- arrows -->
      ${arrow
        ? html`
            <div class="${navigationClass} omp-swiper-prev swiper-button-disabled">
              ${OMPCoreAtomTmplS.arrowCircleIcon()}
            </div>
            <div class="${navigationClass} omp-swiper-next swiper-button-disabled">
              ${OMPCoreAtomTmplS.arrowCircleIcon({ direction: 'right' })}
            </div>
          `
        : ``}
    </div>
  `;
};

/**
 * @property {string} [children = '']
 * @property {string} [class = '']
 */
export const swiperItems = (params) => {
  const { items = [], renderer = () => {}, customClass = '' } = params;
  return strMap((item) => `<div class="swiper-slide ${customClass}">${renderer(item)}</div>`, items);
};
