import { $append, $el, $find, $hide, $on } from 'fxdom/es';
import { delay, go, html } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPProductListF } from '../../../F/Function/module/OMPProductListF.js';
import { OMPProductListS } from '../../../S/Function/module/OMPProductListS.js';
import { OMPProductListFilterModalMuiF } from '../Mui/module/OMPProductListFilterModalMuiF.js';
import { filterListEvent } from './event.js';

export const openFrame = async ({ product_list_tap_el, cate_lists_el }, closeCallback) => {
  const tt_ok = TT('product::list::list_19_1');

  const checked_cate_list_el = $find('.omp-product-list__checked-cate-list', product_list_tap_el);
  if (checked_cate_list_el.dataset.open == 'true') {
    $hide(cate_lists_el);
    checked_cate_list_el.dataset.open = false;
    await delay(100)();
  }
  return MuiF.openFrame(OMPProductListFilterModalMuiF.frame, async (f, p, [t]) => {
    const { cate_list_id, cate_item_id } = $find(
      '.omp-product-list__checked-cate-list',
      product_list_tap_el,
    )?.dataset;
    const sort = $find('.omp-product-list__checked-sort-name', product_list_tap_el).dataset.id;

    const query = { cate_list_id, cate_item_id, sort };
    const bp_filters = (product_list_tap_el.tab_opt.bp_filters =
      product_list_tap_el.tab_opt.bp_filters || (await OMPProductListF.axiosGetFilters(query)));

    const checked_filter_items = OMPProductListS.checkedFilterItems(bp_filters);

    /* 항상 새로그리긴 해야함 */
    f.always_remove = true;

    f.appended = async (frame_el) => {
      go(
        frame_el,
        $find('>.don_wrapper'),
        $append(
          $el(html`<div class="omp-product-list-filter-modal-frame__footer">
            ${OMPCoreAtomTmplS.actionButton({
              text: tt_ok,
              solidity: 'thick',
            })}
          </div>`),
        ),
      );

      $on('click', ({ target }) => {
        if (target == frame_el) MuiF.closeFrame();
      })(frame_el);

      filterListEvent(frame_el, product_list_tap_el, { bp_filters, query });
    };
    t.makeData = async () => {
      return { bp_filters, checked_filter_items };
    };

    f.closing = (el, v) => {
      if (v) {
        if (closeCallback) {
          return closeCallback(v);
        }
      }
    };
  });
};
