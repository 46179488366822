import { guard } from '../../S/guard.js';
import { $find, $trigger } from 'fxdom/es';
import { DfImageEditorF } from './module/DfImageEditorF.js';

export function getTool({ paper_scope, tool_name }) {
  return paper_scope.tools.find((tool) => tool.name === tool_name);
}

export function prepareTool({ paper_scope, tool_name, eventHandlers, initializeFn }) {
  let tool = getTool({ paper_scope, tool_name });
  if (tool == null) {
    tool = new paper_scope.Tool();
    tool.name = tool_name;
    Object.entries(eventHandlers).map(async ([event_type, handler_fn]) => {
      const check = guard.checkAllowedEventType({ type: event_type });
      if (check) {
        await DfImageEditorF.donAlertAsync({ tab_el: paper_scope.tab_el, msg: check });
        return;
      }
      tool.on(event_type, handler_fn);
    });
    initializeFn && initializeFn(tool);
  }
  return tool;
}

export function triggerSelectedTool({ tab_el }) {
  $trigger('click', $find('.tool-panel button.selected', tab_el));
}
