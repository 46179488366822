import { createCanvasElement } from '../../../../Canvas/S/util.js';

export const makeCuttinglineCanvas = ({ target_canvas, width, color_code, ratio }) => {
  const rect_width = width * ratio;
  const rect_height = rect_width;
  const canvas = createCanvasElement({
    width: target_canvas.width + rect_width * 2,
    height: target_canvas.height + rect_height * 2,
  });
  const ctx = canvas.getContext('2d');
  ctx.lineWidth = ratio;
  ctx.strokeStyle = color_code;
  ctx.strokeRect(0, 0, rect_width, rect_height);
  ctx.strokeRect(target_canvas.width + rect_width + 1, 0, rect_width, rect_height);
  ctx.strokeRect(
    target_canvas.width + rect_width + 1,
    target_canvas.height + rect_height + 1,
    rect_width,
    rect_height,
  );
  ctx.strokeRect(0, target_canvas.height + rect_height + 1, rect_width, rect_height);
  ctx.drawImage(
    target_canvas,
    0,
    0,
    target_canvas.width,
    target_canvas.height,
    rect_width + 1,
    rect_height + 1,
    target_canvas.width,
    target_canvas.height,
  );
  return cutRect(canvas, ratio);
};

function cutRect(c, ratio) {
  const canvas = createCanvasElement({ width: c.width - ratio * 2, height: c.height - ratio * 2 });
  const ctx = canvas.getContext('2d');
  ctx.drawImage(
    c,
    ratio + 1,
    ratio + 1,
    c.width - ratio * 2,
    c.height - ratio * 2,
    0,
    0,
    canvas.width,
    canvas.height,
  );
  return canvas;
}
