import axios from 'axios';

export const apiCall = {
  get: {
    getUpFromWowOrder: async ({ wow_order_no }) => {
      return (
        await axios.get('/@api/wow_press/user_product', {
          params: {
            wow_order_no,
          },
        })
      ).data;
    },
  },
};
