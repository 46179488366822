import {
  defaults,
  delay,
  drop,
  each,
  entries,
  filter,
  find,
  go,
  head,
  ippL,
  map,
  mapC,
  object,
  pipe,
  reject,
  sel,
  sortBy,
  strMap,
  take,
  tap,
} from 'fxjs/es';
import {
  $append,
  $appendTo,
  $attr,
  $closest,
  $delegate,
  $el,
  $find,
  $findAll,
  $setAttr,
  $setText,
  $val,
} from 'fxdom/es';
import { evaluateColorBrightness, makeCanvasByUrl } from '../../../Canvas/S/util.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { render_canvas } from '../../Core/S/composite_template_canvas.js';
import { makeTestMockupUrl } from '../../Core/F/mockup_fns.js';
import { makeDesignReadyBlobUrl } from '../../Core/F/render_canvas.js';
import { drawProductFace } from '../../../Maker/F/draw_product_faces.js';
import axios from 'axios';
import { makeFullNewProductWithBothThumbnails } from '../../Thumbnail/F/fs.js';
import { updatePcForCompositeThumbnailByBpId } from '../../../Maker/F/Migrate/product_color.js';

function makeBpcHtml(bpc) {
  return legacyHtml`
    <div>${bpc.name}</div>
    <div
      class="color_code"
      style="background:${bpc.color_code}; color:${
    evaluateColorBrightness(bpc.color_code) < 0.3 ? '#fff' : '#000'
  }"
    >
      color_code : ${bpc.color_code || '없음'} ${bpc.base_product_color_id}
    </div>
    <div
      class="color_code"
      style="background:${bpc.abs_color_code}; color:${
    bpc.abs_color_code && evaluateColorBrightness(bpc.abs_color_code) < 0.3 ? '#fff' : '#000'
  }"
    >
      abs_color_code : ${bpc.abs_color_code || '없음'}
    </div>
  `;
}
function makeProductColorHtml(pc) {
  return legacyHtml`
    <label for="">만들기 툴</label>
    <div class="row_wrapper">
      ${strMap(
        (pf) => legacyHtml`
          <div class="wrapper">
            <label for="">${pf.face_name}</label>
            <canvas class="product_color_canvas" data-bpf_id="${pf.bpf_id}" width="600" height="600"></canvas>
          </div>
        `,
        pc.product_faces2.value,
      )}
    </div>
  `;
}

export function testPageLoader(f) {
  return async (e) => {
    // $addClass('test_page_started', $qs('#dream_factory'));
    $.don_loader_start();
    await delay(500, '');
    await f(e);
    $.don_loader_end();
  };
}

function makeGoToTheSiteHtml(pc) {
  return legacyHtml`
    <a href="${pc.domain_name}/products/${pc.id}" class="go_to_the_site">go to the site</a>
    <div class="created_at">created_at: ${moment(pc.created_at).format('YYYY MM D, h:mm:ss')}</div>
  `;
}

export async function renderThumbnailPage(el, product_colors) {
  return go(
    product_colors,
    each(
      pipe(
        (pc) => [
          go(
            legacyHtml`
              <div class="img_wrapper item" data-is_public="${pc.is_public}">
                <div class="wrapper">${makeGoToTheSiteHtml(pc)}</div>
                <div class="wrapper">${makeBpcHtml(pc)}</div>
                <div class="wrapper">${makeProductColorHtml(pc)}</div>
                <div class="wrapper thumbnails">
                  <label for="">썸네일</label>
                  ${strMap(
                    (thumb) => legacyHtml` <img src="${thumb.url || ''}" is_thumb="${!!thumb.is_thumb}" /> `,
                    pc.thumbnails.value,
                  )}
                </div>
                <div class="wrapper">
                  <label for="">og image</label>
                  <img src="${pc.og_image_url || ''}" />
                </div>
              </div>
            `,
            $el,
            tap($.appendTo(el)),
          ),
          pc,
        ],
        async function ([el, pc]) {
          const product_color_canvass = $findAll('.product_color_canvas', el);
          await _drawProductFaces(product_color_canvass, pc);
        },
      ),
    ),
  );
}

function _drawProductFaces(product_color_canvass, product_color) {
  return go(
    product_color_canvass,
    each((product_color_canvas) =>
      go(
        product_color_canvas.dataset,
        sel('bpf_id'),
        parseInt,
        (bpf_id) => find((pf) => pf.bpf_id === bpf_id, product_color.product_faces2.value),
        (pf) => drawProductFace(product_color_canvas, pf, null, null, true),
      ),
    ),
  );
}

const makeCompositeWithProductColorAppendingEl = (el, pc, assoc_composite_template) =>
  go(
    pc,
    (pc) => [
      go(
        legacyHtml`
          <div class="img_wrapper item" product_color_id="${pc.id}" data-is_public="${pc.is_public}">
            <div class="wrapper mockup">
              <div class="wrapper">
                <label for="">목업</label>
                <div class="wrapper mockup_img row_wrapper"></div>
              </div>
            </div>
            <div class="wrapper">
              <label for="">합성된 상태</label>
              <canvas class="composite_thumbnail"></canvas>
            </div>
          </div>
        `,
        $el,
        tap($appendTo(el)),
      ),
      pc,
    ],
    async ([el, product_color]) => {
      const new_assoc_composite_template = JSON.parse(JSON.stringify(assoc_composite_template));
      const composite_thumbnail_canvas = $find('.composite_thumbnail', el);
      const product_color_canvass = $findAll('.product_color_canvas', el);
      await _drawProductFaces(product_color_canvass, product_color);
      const mockup_img_el = $find('.mockup_img', el);
      // await setCompositeTemplateMockupUrl([new_assoc_composite_template], product_color);
      // const canvas = go($find('.result_url', e.delegateTarget), $find('canvas'));
      // canvas.width = assoc_composite_template.width;
      // canvas.height = assoc_composite_template.height;
      await go(
        new_assoc_composite_template._.composite_masks,
        each((cm) =>
          go(
            cm._.composite_faces,
            each(async (cf) => {
              cf.mockup_url = await makeTestMockupUrl(cf.base_product_face_id, true);
            }),
          ),
        ),
      );
      const target_width = 900;
      go(
        new_assoc_composite_template._.composite_masks,
        each(
          pipe(
            sel('_.composite_faces'),
            each(async (cf) => {
              const img = document.createElement('img');
              img.src = cf.mockup_url || '';
              $append(img, mockup_img_el);
            }),
          ),
        ),
      );
      const design_ready_canvas = await go(
        makeDesignReadyBlobUrl(new_assoc_composite_template, target_width),
        makeCanvasByUrl,
      );
      composite_thumbnail_canvas.width = target_width;
      await render_canvas({
        assoc_composite_template: new_assoc_composite_template,
        canvas_el: composite_thumbnail_canvas,
        design_ready_canvas,
        selected_base_product_colors: go(
          new_assoc_composite_template._.composite_masks,
          map(() => {
            return {
              id: product_color.base_product_color_id,
              base_product_id: product_color.base_product_id,
            };
          }),
        ),
      });
    },
  );
export async function renderMapTestPage(el, product_colors, assoc_composite_templates) {
  await go(
    product_colors,
    map((pc) =>
      go(
        assoc_composite_templates,
        mapC((assoc_composite_template) =>
          makeCompositeWithProductColorAppendingEl(el, pc, assoc_composite_template),
        ),
      ),
    ),
  );
}

export async function renderColorTestPage(el, selectAssoCompositeTemplates, base_product_colors) {
  return go(
    base_product_colors,
    sortBy((base_product_color) => evaluateColorBrightness(base_product_color.color_code)),
    each(async (bpc) => {
      await go(
        legacyHtml`
          <div class="img_wrapper">
            <div class="wrapper">${makeBpcHtml(bpc)}</div>
            <div class="canvas_wrapper">
              ${strMap(
                (selectAssoCompositeTemplate) => legacyHtml`
                  <div class="wrapper">
                    <div class="title">${selectAssoCompositeTemplate.title}</div>
                    <canvas></canvas>
                  </div>
                `,
                selectAssoCompositeTemplates,
              )}
            </div>
          </div>
        `,
        $el,
        $appendTo(el),
        $findAll('canvas'),
        ippL,
        each(([i, c]) => {
          const assoc_composite_template = JSON.parse(JSON.stringify(selectAssoCompositeTemplates[i]));
          c.width = 900;
          return render_canvas({
            assoc_composite_template,
            canvas_el: c,
            selected_base_product_colors: [
              {
                id: bpc.id,
                base_product_id: bpc.base_product_id,
              },
            ],
          });
        }),
      );
    }),
  );
}

export const goToTheSiteEvent = $delegate('click', '.go_to_the_site', function (e) {
  const href = $attr('href', e.currentTarget);
  e.originalEvent.preventDefault();
  if (location.origin.indexOf('df.marpple') > -1) {
    window.open(`https://marpple.shop/${href}`);
  } else {
    window.open(`http://127.0.0.1:9077/${href}`);
  }
});

function _checkboxSelect(don_tab, name) {
  return go(
    don_tab,
    $findAll(`input[name="${name}"]`),
    map((el) => el.checked),
    head,
  );
}
function isWithOg(don_tab) {
  return _checkboxSelect(don_tab, 'is_with_og');
}

function isOnlyOg(don_tab) {
  return _checkboxSelect(don_tab, 'is_only_og');
}

function selectNewThumb(don_tab) {
  return _checkboxSelect(don_tab, 'select_new_thumb');
}
function withOutCreatedCompositeThumbnail(don_tab) {
  return _checkboxSelect(don_tab, 'with_out_created_composite_thumbnail');
}

export function makeSelectBaseProductColorIdHtml(base_product_colors) {
  return legacyHtml`
    <div class="wrapper" filter_type="base_product_colors">
      <label for="">제품 색상</label>
      <select name="base_product_color_id" id="">
        <option value="null" selected>선택 없음</option>
        ${strMap(
          (bpc) => legacyHtml` <option value="${bpc.id}">${bpc.id}_${bpc.name}</option> `,
          base_product_colors,
        )}
      </select>
    </div>
  `;
}

export function makeChangeCompositeHtml(composite_templates) {
  return legacyHtml`
    <div class="wrapper" filter_type="composite_template_change">
      <label for="">합성템플릿 체인지</label>
      <select name="dest_ct_id" id="">
        <option value="null" selected>선택 없음</option>
        ${strMap(
          (ct) => legacyHtml` <option value="${ct.id}">${ct.id}_${ct.title}</option> `,
          composite_templates,
        )}
      </select>
      <span>을</span>
      <select name="src_ct_id" id="">
        <option value="null" selected>선택 없음</option>
        ${strMap(
          (ct) => (ct.is_public ? legacyHtml` <option value="${ct.id}">${ct.id}_${ct.title}</option> ` : ''),
          composite_templates,
        )}
      </select>
      <span>으로 대체하겠습니다.</span>
    </div>
  `;
}

export function getChangeCompositeValue(don_tab) {
  const _func = (name) =>
    pipe(
      $findAll(`>.don_wrapper >.header .filter [filter_type="composite_template_change"] [name="${name}"]`),
      map($val),
      map((val) => {
        if (val === 'null') return null;
        return parseInt(val);
      }),
      head,
    );
  const don_page = $closest('.don_page')(don_tab);

  return {
    src_ct_id: go(don_page, _func('src_ct_id')),
    dest_ct_id: go(don_page, _func('dest_ct_id')),
  };
}
export function makeCheckCompositeTemplateIdHtml(composite_templates) {
  return legacyHtml`
    <div class="wrapper" filter_type="composite_templates">
      <label for="">합성 템플릿</label>
      <div class="checkbox" name="composite_template_id">
        ${strMap(
          (ct) => legacyHtml`
            <div>
              <label for="">${ct.title}_${ct.id}</label>
              <input type="checkbox" name="${ct.id}" />
            </div>
          `,
          composite_templates,
        )}
      </div>
    </div>
  `;
}

export function changeCurrentTab(don_tab) {
  const tab_name = don_tab.tab_opt.tab_name;
  go(
    don_tab,
    $closest('.don_page'),
    tap(
      $findAll('.filter [filter_type="composite_templates"] input'),
      each((el) => (el.checked = false)),
    ),
    tap($findAll('.filter [filter_type="base_product_colors"] option'), head, (el) => (el.selected = true)),
    tap(
      $findAll('.filter [filter_type="composite_template_change"] [name="src_ct_id"] option'),
      head,
      (el) => (el.selected = true),
    ),
    tap(
      $findAll('.filter [filter_type="composite_template_change"] [name="dest_ct_id"] option'),
      head,
      (el) => (el.selected = true),
    ),
    tap($setAttr({ current_tab: tab_name })),
  );
}

export function getSelectValue(don_tab, name) {
  return go(
    don_tab,
    $closest('.don_page'),
    $findAll(`>.don_wrapper >.header .filter select[name="${name}"]`),
    map($val),
    map((val) => {
      if (val === 'null') return null;
      return parseInt(val);
    }),
    head,
  );
}

export function getCheckValue(don_tab, name) {
  return go(
    don_tab,
    $closest('.don_page'),
    $findAll(`>.don_wrapper >.header .filter .checkbox[name="${name}"] input[type="checkbox"]`),
    filter((el) => el.checked),
    map((el) => {
      return go(el, $attr('name'), parseInt);
    }),
  );
}

export function setSelectFirstValue(don_tab, name) {
  return go(
    don_tab,
    $closest('.don_page'),
    $findAll(`>.don_wrapper >.header .filter select[name="${name}"] option`),
    find((el) => $val(el) !== 'null'),
    $setAttr({ selected: true }),
  );
}

export async function filterPcByCompositeTemplateUpdatedAt(pcs, ct_id) {
  if (!ct_id) return pcs;
  const { data: ct } = await axios.get('/@api/composite/composite_template', { params: { id: ct_id } });
  return go(
    pcs,
    reject((pc) => {
      return go(
        pc.thumbnails.value,
        find((thumb) => thumb.updated_at === ct.updated_at),
      );
    }),
  );
}

export const compactObject = pipe(
  entries,
  filter(([_, v]) => v),
  object,
);

export const makeThumbnailMigrationTestTab = async function (
  don_tab,
  { is_composite, is_for_worker, is_real, is_with_og, is_for_migration },
) {
  const list_wrapper_el = $find('.list_wrapper', don_tab);
  let offset = 0;
  const step = 1;
  const cond = () =>
    compactObject({
      cond: compactObject({
        base_product_id: box.sel('bp->id'),
        base_product_color_id: getSelectValue(don_tab, 'base_product_color_id'),
      }),
      is_for_migration,
      composite_template_id: getCheckValue(don_tab, 'composite_template_id')[0],
      with_out_created_composite_thumbnail: withOutCreatedCompositeThumbnail(don_tab),
    });
  async function _renderTotalPcLength(don_tab) {
    const pc_length = await go(
      axios.post('/@api/composite/test_page/get_pcs', cond()),
      sel('data'),
      (pcs) => filterPcByCompositeTemplateUpdatedAt(pcs, getCheckValue(don_tab, 'composite_template_id')[0]),
      (pcs) => pcs.length,
    );
    go(don_tab, $findAll('.total_pc_length'), each($setText(` ${pc_length}`)));
  }
  async function _render() {
    if (!is_for_worker) await _renderTotalPcLength(don_tab);
    const product_colors = await go(
      axios.post('/@api/composite/test_page/get_pcs', cond()),
      sel('data'),
      (pcs) => filterPcByCompositeTemplateUpdatedAt(pcs, getCheckValue(don_tab, 'composite_template_id')[0]),
      drop(offset),
      take(step),
      map(async (pc) => {
        if (is_real) return pc;
        try {
          const new_pc = await makeFullNewProductWithBothThumbnails(
            pc,
            is_with_og ? true : isWithOg(don_tab),
            is_composite,
            is_for_worker,
            isOnlyOg(don_tab),
            getChangeCompositeValue(don_tab),
            selectNewThumb(don_tab),
          );
          return defaults(new_pc, pc);
        } catch (e) {
          return pc;
        }
      }),
    );
    offset += step;
    await renderThumbnailPage(list_wrapper_el, product_colors);
  }
  don_tab.tab_opt.reset_render = async function () {
    list_wrapper_el.innerHTML = '';
    offset = 0;
    await _render();
  };
  go(
    don_tab,
    $delegate('click', '.more', testPageLoader(_render)),
    $delegate(
      'click',
      '.migration',
      testPageLoader(async () => {
        await updatePcForCompositeThumbnailByBpId(
          cond(),
          is_with_og ? true : isWithOg(don_tab),
          is_composite,
          isOnlyOg(don_tab),
          getChangeCompositeValue(don_tab),
          selectNewThumb(don_tab),
          getCheckValue(don_tab, 'composite_template_id')[0],
        );
      }),
    ),
    goToTheSiteEvent,
  );
};
