import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { go, join, mapL, noop } from 'fxjs/es';
import { applyCuttingLine, setCuttingLine } from './cutting_line.js';
import { VectorEditorAcrylicFigureStandLegPCF } from '../../../../../StandLeg/PC/F/Function/module/VectorEditorAcrylicFigureStandLegPCF.js';
import { CommonNS } from '@marpple/sticker-editor';
import { $find } from 'fxdom/es';
import { VectorEditorAcrylicFigureFreePCCuttingLineManualF } from '../../../CuttingLineManual/F/Function/module/VectorEditorAcrylicFigureFreePCCuttingLineManualF.js';
import { setSizeGuide } from './size.js';

export const handleControlAutoMinusButtonClick =
  ({ tab_el }) =>
  async () => {
    try {
      await setCuttingLine({ tab_el, index: tab_el.__mp_cur_cutting_line_index - 1 });
      setSizeGuide({
        tab_el,
        editor: tab_el.__mp_acrylic_figure_cutting_line_editor,
      });
    } catch (error) {
      console.error(error);
      const message =
        error?.__mp_alert_message ??
        T(
          'modules::VectorEditor::AcrylicFigure::Free::PC::CuttingLineAuto::error_message::min_cutting_line_offset_error',
        );
      await $.alert(message).catch(noop);
    }
  };

export const handleControlAutoPlusButtonClick =
  ({ tab_el }) =>
  async () => {
    try {
      await setCuttingLine({ tab_el, index: tab_el.__mp_cur_cutting_line_index + 1 });
      setSizeGuide({
        tab_el,
        editor: tab_el.__mp_acrylic_figure_cutting_line_editor,
      });
    } catch (error) {
      console.error(error);
      const message =
        error?.__mp_alert_message ??
        T(
          'modules::VectorEditor::AcrylicFigure::Free::PC::CuttingLineAuto::error_message::max_cutting_line_offset_error',
        );
      await $.alert(message).catch(noop);
    }
  };

export const handleControlManualButtonClick =
  ({ tab_el }) =>
  async () => {
    try {
      const makeTargetImageEl = tab_el.__mp_makeTargetImageEl;
      const postProcess = tab_el.__mp_postProcess;
      const cutting_line = tab_el.__mp_cutting_lines[tab_el.__mp_cur_cutting_line_index];
      const { outer_cutting_line_path_data, inner_cutting_line_path_data } = cutting_line;

      await VectorEditorAcrylicFigureFreePCCuttingLineManualF.makeCuttingLineManual({
        frame_position: { top: tab_el.__mp_frame_position_top, height: tab_el.__mp_frame_position_height },
        prev_right_panel_el: $find(`.right_container .right_wrapper`)(tab_el),
        art_board: { width: tab_el.__mp_art_board_width, height: tab_el.__mp_art_board_height },
        cutting_line: { inner: inner_cutting_line_path_data, outer: outer_cutting_line_path_data },
        stand_leg: {
          ground: {
            size_delta: tab_el.__mp_stand_leg_ground_size_delta,
            position_delta: tab_el.__mp_stand_leg_ground_position_delta,
            min_width: tab_el.__mp_stand_leg_ground_min_width,
            max_width: tab_el.__mp_stand_leg_ground_max_width,
            width: tab_el.__mp_stand_leg_ground_width,
            height: tab_el.__mp_stand_leg_ground_height,
          },
          notch: {
            round: tab_el.__mp_stand_leg_notch_round,
            width: tab_el.__mp_stand_leg_notch_width,
            height: tab_el.__mp_stand_leg_notch_height,
          },
        },
        makeTargetImageEl,
        postProcess,
      });
    } catch (error) {
      console.error(error);
      const message =
        error?.__mp_alert_message ??
        T(
          'modules::VectorEditor::AcrylicFigure::Free::PC::CuttingLineAuto::error_message::manual_cutting_line_error',
        );
      await $.alert(message).catch(noop);
    }
  };

export const handleRightPrevButtonClick = () => () => MuiF.popPage().catch(noop);

export const handleRightNextButtonClick =
  ({ tab_el }) =>
  async () => {
    try {
      const makeTargetImageEl = tab_el.__mp_makeTargetImageEl;
      const postProcess = tab_el.__mp_postProcess;
      const cutting_line = tab_el.__mp_cutting_lines[tab_el.__mp_cur_cutting_line_index];
      const { outer_cutting_line_path_data, inner_cutting_line_path_data } = cutting_line;

      await VectorEditorAcrylicFigureStandLegPCF.makeStandLeg({
        frame_position: { top: tab_el.__mp_frame_position_top, height: tab_el.__mp_frame_position_height },
        prev_right_panel_el: $find(`.right_container .right_wrapper`)(tab_el),
        art_board: {
          width: tab_el.__mp_art_board_width,
          height: tab_el.__mp_art_board_height,
          shape_path_data: outer_cutting_line_path_data,
        },
        stand_leg: {
          ground: {
            size_delta: tab_el.__mp_stand_leg_ground_size_delta,
            position_delta: tab_el.__mp_stand_leg_ground_position_delta,
            min_width: tab_el.__mp_stand_leg_ground_min_width,
            max_width: tab_el.__mp_stand_leg_ground_max_width,
            width: tab_el.__mp_stand_leg_ground_width,
            height: tab_el.__mp_stand_leg_ground_height,
          },
          notch: {
            round: tab_el.__mp_stand_leg_notch_round,
            width: tab_el.__mp_stand_leg_notch_width,
            height: tab_el.__mp_stand_leg_notch_height,
          },
        },
        makeTargetImageEl: async () => {
          const target_image_el = await makeTargetImageEl({ factor: 1 });
          applyCuttingLine({ target_image_el, path_data: inner_cutting_line_path_data });
          return target_image_el;
        },
        postProcess: async ({
          cutting_line_path_data,
          stand_leg_position: { x: stand_leg_position_x, y: stand_leg_position_y },
        }) => {
          const cutting_line_result = (() => {
            try {
              const cutting_line_svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
              cutting_line_svg_el.setAttributeNS(
                'http://www.w3.org/2000/xmlns/',
                'xmlns',
                'http://www.w3.org/2000/svg',
              );
              cutting_line_svg_el.setAttributeNS(null, 'viewBox', '0 0 0 0');

              const cutting_line_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
              cutting_line_path_el.setAttributeNS(null, 'd', cutting_line_path_data);
              cutting_line_path_el.setAttributeNS(null, 'fill', 'none');
              cutting_line_path_el.setAttributeNS(null, 'stroke', '#FF0000');
              cutting_line_path_el.setAttributeNS(null, 'stroke-width', '0.001');
              cutting_line_svg_el.appendChild(cutting_line_path_el);

              document.body.appendChild(cutting_line_svg_el);
              const margin = 2;
              const bbox = cutting_line_path_el.getBBox();
              const view_box_x = Math.floor(bbox.x) - margin;
              const view_box_y = Math.floor(bbox.y) - margin;
              const view_box_width = Math.ceil(bbox.width) + margin * 2;
              const view_box_height = Math.ceil(bbox.height) + margin * 2;
              document.body.removeChild(cutting_line_svg_el);

              cutting_line_svg_el.setAttributeNS(
                null,
                'viewBox',
                go(
                  [view_box_x, view_box_y, view_box_width, view_box_height],
                  mapL((a) => `${a}`),
                  join(' '),
                ),
              );

              const stand_leg_position_ratio = {
                x: (stand_leg_position_x - view_box_x) / view_box_width,
                y: (stand_leg_position_y - view_box_y) / view_box_height,
              };

              return {
                success: true,
                value: {
                  cutting_line_svg_el,
                  stand_leg_position_ratio,
                  view_box: { x: view_box_x, y: view_box_y, width: view_box_width, height: view_box_height },
                },
              };
            } catch (error) {
              console.error(error);
              return {
                success: false,
                value:
                  error?.__mp_alert_message ??
                  T(
                    'modules::VectorEditor::AcrylicFigure::Free::PC::CuttingLineAuto::error_message::cutting_line_calculation_error',
                  ),
              };
            }
          })();
          if (!cutting_line_result.success) {
            return cutting_line_result;
          }
          const { cutting_line_svg_el, stand_leg_position_ratio, view_box } = cutting_line_result.value;

          return postProcess({
            cutting_line_svg_el,
            stand_leg_position_ratio,
            view_box,
            makeTargetImageEl: async ({ factor }) => {
              const target_image_el = await makeTargetImageEl({ factor });
              applyCuttingLine({ target_image_el, path_data: inner_cutting_line_path_data });
              return target_image_el;
            },
          });
        },
      });
    } catch (error) {
      await $.alert(
        error?.__mp_alert_message ??
          T(
            'modules::VectorEditor::AcrylicFigure::Free::PC::CuttingLineAuto::error_message::cutting_line_making_error',
          ),
      );
      throw error;
    }
  };
