import { $delegate, $el, $find, $next, $remove, $replaceWith, $setHTML, $trigger } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPProductListF } from '../../../F/Function/module/OMPProductListF.js';
import { OMPProductListFilterModalF } from '../../../FilterModal/F/Function/module/OMPProductListFilterModalF.js';
import { OMPProductListS } from '../../../S/Function/module/OMPProductListS.js';
import { OMPProductListTmplS } from '../../../S/Tmpl/module/OMPProductListTmplS.js';
import { axiosGetBpsWithoutCarvedBps, finishActionModal, setBpsWithFilter } from './fs.js';
import { OMPProductListModalF } from './module/OMPProductListModalF.js';
const makeQueryModal = (product_list_tap_el) => {
  const { cate_list_id, cate_item_id } = $find(
    '.omp-product-list__checked-cate-list',
    product_list_tap_el,
  ).dataset;
  const sort = $find('.omp-product-list__checked-sort-name', product_list_tap_el).dataset.id;
  return { cate_list_id, cate_item_id, sort };
};
export const event = (product_list_tap_el) => {
  const cate_lists_el = $find('.omp-product-list-modal__cate-lists', product_list_tap_el);
  go(
    product_list_tap_el,
    OMPProductListF.delegateBaseProductLike,
    OMPProductListF.delegateOpenCateSelector(cate_lists_el),
    $delegate('input', '.omp-product-cate-selector__cate-item-radio', ({ currentTarget }) => {
      const already_check_cate$ = $find('[data-radio-checked="true"]', cate_lists_el);
      already_check_cate$.dataset.radioChecked = false;
      $next('[data-radio-checked]', currentTarget).dataset.radioChecked = true;
      $trigger('click', $find('.omp-product-list__checked-cate-list', product_list_tap_el));

      const { cate_list_id, cate_item_id } = currentTarget.dataset;
      const sort = $find('.omp-product-list__checked-sort-name', product_list_tap_el).dataset.id;

      OMPProductListF.resetWithFilterModal(product_list_tap_el);

      go(axiosGetBpsWithoutCarvedBps({ cate_list_id, cate_item_id, sort }), (bps) => {
        const { checked_cate_list, checked_cate_item } = OMPProductListF.checkedCateItem({
          cate_list_id,
          cate_item_id,
        });
        const omp_product_list_bps_el = $find('.opm-product-list-bps', product_list_tap_el);
        if (OMPCoreUtilF.isMobile()) {
          $replaceWith(
            $el(OMPProductListTmplS.moCateSelect({ checked_cate_list, checked_cate_item })),
            $find('.omp-product-list__cate-lists', product_list_tap_el),
          );
          $setHTML(OMPProductListTmplS.moListBody(bps), omp_product_list_bps_el);
        } else {
          $replaceWith(
            $el(OMPProductListTmplS.cateSelectorTitle({ checked_cate_list, checked_cate_item })),
            $find('.omp-product-list__cate-lists', product_list_tap_el),
          );
          $setHTML(OMPProductListTmplS.pcListBody(bps), omp_product_list_bps_el);
        }

        finishActionModal(product_list_tap_el);
      });
    }),
    $delegate('click', '.omp-product-list__checked-filter-item', ({ currentTarget }) => {
      const { bp_filters } = product_list_tap_el.tab_opt;
      if (bp_filters) {
        $remove(currentTarget);

        const { fl, fi } = OMPProductListFilterModalF.findFilterItem(bp_filters, currentTarget.dataset);

        if (!fi) {
          return;
        }

        fl.count--;
        fi.checked = false;
        bp_filters.total_count--;

        const checked_filter_items = OMPProductListS.checkedFilterItems(bp_filters);

        return setBpsWithFilter({
          checked_filter_items,
          product_list_tap_el,
          query: makeQueryModal(product_list_tap_el),
        });
      }
    }),
    $delegate('click', '.omp-product-list__checked-filter-item-init', () => {
      OMPProductListF.resetWithFilterModal(product_list_tap_el);

      return setBpsWithFilter({
        checked_filter_items: [],
        product_list_tap_el,
        query: makeQueryModal(product_list_tap_el),
      });
    }),
    $delegate('click', '.omp-cell__product, .omp-cell__product-mo', async (e) => {
      e.originalEvent.preventDefault();
      const id = e.currentTarget.dataset.id;
      const base_product = box.sel('maker->product_color->_->base_product');
      if (base_product.id != id) {
        await MuiF.closeFrame({ id });
        if (OMPCoreUtilF.isMobile() && $1('html.prev_product_matcher'))
          await go(MuiF.closeFrame(true), function () {
            $.frame.open({
              frame_name: 'mp.maker.prev_product_matcher',
            });
          });
      } else {
        MuiF.closeFrame();
      }
    }),
    $delegate('click', '.omp-product-list__sort-item', ({ currentTarget }) => {
      OMPProductListF.changeSort(currentTarget, () => {
        finishActionModal(product_list_tap_el);
      });
    }),
    $delegate('click', '.omp-product-list-modal__filter-open-btn', async () => {
      return OMPProductListFilterModalF.openFrame(
        { product_list_tap_el, cate_lists_el },
        OMPProductListModalF.setBpsWithFilter,
      );
    }),
    $delegate('input', '.omp-mo-product-header__sort-selector', ({ currentTarget }) => {
      OMPProductListF.changeSort($find('.omp-mo-product-header__sort-item:checked', currentTarget), () => {
        finishActionModal(product_list_tap_el);
      });
    }),
  );
};
