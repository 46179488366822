import { $on } from 'fxdom/es';
import { go, tap } from 'fxjs/es';

export const makeDebounceInput = function (debounce_time, $input) {
  const DEFAULT_DEBOUNCE_TIME = 300;

  if (arguments.length === 1) {
    if (arguments[0] instanceof HTMLInputElement) {
      debounce_time = DEFAULT_DEBOUNCE_TIME;
      $input = arguments[0];
    } else {
      throw new Error('Invalid arguments.');
    }
  }

  if (!Number.isInteger(debounce_time)) {
    throw new Error('debounce_time is required.');
  }

  let timer;
  // let last_value = '';

  // const isSameValue = (value) => last_value === value;
  const isSameValue = ($input) => $input.last_value === $input.value;

  return go(
    $input,
    tap(
      $on('input', () => {
        // keydown 이벤트에 input이 울리는 경우가 있어서 추가
        if (isSameValue($input)) {
          return;
        }

        const debounce_input_event = new CustomEvent('debounce-input');
        if (debounce_time <= 0) return $input.dispatchEvent(debounce_input_event);
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
          $input.last_value = $input.value;
          $input.dispatchEvent(debounce_input_event);
        }, debounce_time);
      }),
    ),
  );
};
