import { CommonNS } from '@marpple/sticker-editor';
import { $findAll } from 'fxdom/es';
import { defaultTo, each, equals2, flatMap, go, head, isNil, mapL } from 'fxjs/es';
import { VectorEditorSettingBackgroundMobileF } from '../../../../../SettingBackground/Mobile/F/Function/module/VectorEditorSettingBackgroundMobileF.js';
import { VectorEditorStickerSingleMobileF } from '../../../../Single/Mobile/F/Function/module/VectorEditorStickerSingleMobileF.js';
import { VectorEditorStickerGridMobileCopyGridF } from '../../CopyGrid/F/Function/module/VectorEditorStickerGridMobileCopyGridF.js';

export const handleFooterSetBackgroundIconClick =
  ({ tab_el }) =>
  () =>
    VectorEditorSettingBackgroundMobileF.setBackground({
      getInitBackground: async () => {
        const background_value_vo = tab_el.__mp_grid_sticker_editor?.getBackground?.()?.value;
        if (equals2(background_value_vo?.type)('color')) {
          return { color: background_value_vo.fill };
        } else {
          await tab_el.__mp_grid_sticker_editor?.setBackground?.({
            type: 'color',
            fill: 'none',
            opacity: 1,
          });
          return { color: 'none' };
        }
      },
      setBackground: ({ color }) =>
        tab_el.__mp_grid_sticker_editor?.setBackground({
          type: 'color',
          fill: color,
          opacity: 1,
        }),
    });

export const handleFooterDeleteTargetImageIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_selected_grid_id == null) {
      return;
    }

    tab_el.__mp_grid_sticker_editor.unsetTargetImage(tab_el.__mp_selected_grid_id);
  };

export const handleFooterDuplicateTargetImageIconClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_selected_grid_id == null) {
      return;
    }

    const don_wrapper_el = go(tab_el, $findAll('.don_wrapper'), head);
    if (isNil(don_wrapper_el)) {
      return;
    }
    return VectorEditorStickerGridMobileCopyGridF.copyGrid({
      grids: tab_el.__mp_grids,
      grid_sticker_editor: tab_el.__mp_grid_sticker_editor,
      container_el: don_wrapper_el,
      onShowing: () => {
        go(
          tab_el,
          $findAll('.header'),
          each((el) => (el.dataset.is_show = 'false')),
        );
        go(
          tab_el,
          $findAll('.editor_container'),
          each((el) => (el.dataset.is_upper = 'true')),
        );
      },
      onHiding: () => {
        go(
          tab_el,
          $findAll('.header'),
          each((el) => (el.dataset.is_show = 'true')),
        );
        go(
          tab_el,
          $findAll('.editor_container'),
          each((el) => (el.dataset.is_upper = 'false')),
        );
      },
    });
  };

export const handleFooterEditTargetImageIconClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_selected_grid_id == null) {
      return;
    }
    const grid_id = tab_el.__mp_selected_grid_id;

    tab_el.__mp_grid_sticker_editor?.selectGrid?.(null);
    tab_el.__mp_selected_grid_id = null;

    const grid = tab_el.__mp_grids.get(grid_id);
    if (grid == null) {
      return;
    }
    const { x, y, width, height, foreground_el } = grid;

    const target_image_el = tab_el.__mp_grid_sticker_editor?.getTargetImage?.(grid_id);
    if (target_image_el == null) {
      return;
    }
    const clone_target_image_el = await (async () => {
      $.don_loader_start();
      try {
        return await CommonNS.UtilNS.deepCloneNode(target_image_el);
      } finally {
        $.don_loader_end();
      }
    })();

    const { fill, opacity } = go(
      clone_target_image_el.querySelectorAll(
        `[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-layer"] [data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-color"]`,
      ),
      mapL((rect_el) => {
        const fill = rect_el.getAttributeNS(null, 'fill');
        const opacity = rect_el.getAttributeNS(null, 'opacity');
        return { fill, opacity: parseFloat(opacity) };
      }),
      head,
      defaultTo({ fill: '#FFFFFF', opacity: 1 }),
    );

    const new_target_image_el = await (async () => {
      tab_el.__mp_is_prevent_mouse_move = false;
      try {
        return await VectorEditorStickerSingleMobileF.makeSticker({
          art_board_size: { width, height },
          background: { fill, opacity },
          foreground_el,
          preProcess: (single_sticker_editor) => {
            go(
              clone_target_image_el.querySelectorAll(
                `[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="working-layer"]`,
              ),
              flatMap((layer_el) => layer_el.children),
              each((el) => single_sticker_editor.addEl(el)),
            );
          },
          postProcess: async (single_sticker_editor) => {
            if (equals2(single_sticker_editor.getGraphicsEls().size)(0)) {
              const error = new Error('the sticker is empty.');
              error.__mp_alert_message = T(
                'modules::VectorEditor::Sticker::message::빈 스티커입니다. 스티커를 만들어주세요 :)',
              );
              throw error;
            }

            $.don_loader_start();
            try {
              const { with_background_el: svg_el } =
                (await single_sticker_editor?.exportEditor({
                  factor: 1,
                  container_el: document.body,
                })) ?? {};
              return svg_el;
            } finally {
              $.don_loader_end();
            }
          },
          is_need_cutting_line: false,
          can_set_background: true,
        });
      } finally {
        tab_el.__mp_is_prevent_mouse_move = true;
      }
    })();
    if (new_target_image_el == null) {
      return;
    }

    new_target_image_el.setAttributeNS(null, 'x', `${x}`);
    new_target_image_el.setAttributeNS(null, 'y', `${y}`);

    tab_el.__mp_grid_sticker_editor?.setTargetImage({
      id: grid_id,
      target_image_el: new_target_image_el,
    });
    tab_el.__mp_grid_sticker_editor?.selectGrid(grid_id);
  };
