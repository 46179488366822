import { $findAll, $removeAttr, $setAttr, $setHTML } from 'fxdom/es';
import { each, go, html } from 'fxjs/es';
import { VectorEditorStickerSingleMobileConstS } from '../../S/Const/module/VectorEditorStickerSingleMobileConstS.js';
import { hide, show } from './is_show.js';

export const showTopMenuBlankPlate = (tab_el) => {
  go(tab_el, $findAll(`.blank_plate[data-target="top_menu"]`), each(show));
};

export const hideTopMenuBlankPlate = (tab_el) => {
  go(tab_el, $findAll(`.blank_plate[data-target="top_menu"]`), each(hide));
};

export const showTopMenuContainer = (tab_el) => {
  go(tab_el, $findAll(`.top_menu_container`), each(show));
};

export const hideTopMenuContainer = (tab_el) => {
  go(tab_el, $findAll(`.top_menu_container`), each(hide));
};

export const activateTopMenus = ({ tab_el, activate_ids, deactivate_ids }) => {
  go(
    tab_el,
    $findAll(`.top_menu_container .small_icon_menu`),
    each((el) => {
      if (activate_ids.has(el.dataset.icon_type)) {
        go(el, $findAll('input[type="button"]:disabled'), each($removeAttr('disabled')));
        return;
      }

      if (deactivate_ids.has(el.dataset.icon_type)) {
        go(el, $findAll(`input[type="button"]:not(:disabled)`), each($setAttr(['disabled', ''])));
      }
    }),
  );
};

export const activateTopMenuUnlockIcon = (tab_el) => {
  go(
    tab_el,
    $findAll(
      `.top_menu_container .small_icon_menu[data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.UNLOCK.ID}"] input[type="button"]:disabled`,
    ),
    each($removeAttr('disabled')),
  );
};

export const deactivateTopMenuUnlockIcon = (tab_el) => {
  go(
    tab_el,
    $findAll(
      `.top_menu_container .small_icon_menu[data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.UNLOCK.ID}"] input[type="button"]:not(:disabled)`,
    ),
    each($setAttr(['disabled', ''])),
  );
};

export const setLockCount = ({ tab_el, count }) => {
  go(
    tab_el,
    $findAll(
      `.top_menu_container .small_icon_menu[data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.UNLOCK.ID}"] .lock_count`,
    ),
    each($setHTML(count <= 0 ? '' : html`<span>${count}</span>`)),
  );
};
