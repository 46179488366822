import { CommonNS, EditorNS } from '@marpple/sticker-editor';
import { $findAll, $off, $on, $remove } from 'fxdom/es';
import { each, go, identity, pipe, sel, takeL, tap } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSingleMobileConstS } from '../../S/Const/module/VectorEditorStickerSingleMobileConstS.js';
import { VectorEditorStickerSingleMobileMuiS } from '../../S/Mui/module/VectorEditorStickerSingleMobileMuiS.js';
import { VectorEditorStickerSingleMobileEventF } from '../Event/module/VectorEditorStickerSingleMobileEventF.js';
import axios from 'axios';
import { VectorEditorFontF } from '../../../../../Font/F/Function/module/VectorEditorFontF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerSingleMobileMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  async appending(tab_el$) {
    tab_el$.__mp_art_board_size = {
      width: tab_el$.__mp_art_board_size?.width ?? 0,
      height: tab_el$.__mp_art_board_size?.height ?? 0,
    };
    tab_el$.__mp_foreground_el = tab_el$.__mp_foreground_el ?? null;
    tab_el$.__mp_empty_template_el = tab_el$.__mp_empty_template_el ?? null;
    tab_el$.__mp_preProcess = tab_el$.__mp_preProcess ?? null;
    tab_el$.__mp_postProcess = tab_el$.__mp_postProcess ?? null;
    tab_el$.__mp_is_need_cutting_line = tab_el$.__mp_is_need_cutting_line ?? false;
    tab_el$.__mp_can_set_background = tab_el$.__mp_can_set_background ?? false;
    tab_el$.__mp_background_color = tab_el$.__mp_background_color ?? 'none';
    tab_el$.__mp_background_opacity = tab_el$.__mp_background_opacity ?? 1;
    tab_el$.__fonts = null;
    tab_el$.__mp_single_sticker_editor = null;
    tab_el$.__mp_is_prevent_mouse_move = false;
    tab_el$.__mp_handleWindowMouseMove = null;

    go(
      tab_el$,

      tap($findAll('.header .back'), each($on('click', () => MuiF.closeFrame(null)))),
      tap(
        $findAll('.header .done'),
        each(
          pipe(
            tap(
              $on(
                'click',
                VectorEditorStickerSingleMobileEventF.handleHeaderDoneButtonClick({ tab_el: tab_el$ }),
              ),
            ),
            tap((el) => (el.dataset.is_show = `${!tab_el$.__mp_is_need_cutting_line}`)),
          ),
        ),
      ),
      tap(
        $findAll('.header .next'),
        each(
          pipe(
            tap(
              $on(
                'click',
                VectorEditorStickerSingleMobileEventF.handleHeaderNextButtonClick({ tab_el: tab_el$ }),
              ),
            ),
            tap((el) => (el.dataset.is_show = `${tab_el$.__mp_is_need_cutting_line}`)),
          ),
        ),
      ),
      tap(
        $findAll('.header .icons [data-icon_type="layers"] input'),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleHeaderLayersIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.header .icons [data-icon_type="more"] input`),
        each(
          $on('click', VectorEditorStickerSingleMobileEventF.handleHeaderMoreIconClick({ tab_el: tab_el$ })),
        ),
      ),

      tap(
        $findAll(`.footer.none[data-icon_type="add"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterNoneAddIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.none[data-icon_type="set_background"]`),
        each(
          pipe(
            tap(
              $on(
                'click',
                VectorEditorStickerSingleMobileEventF.handleFooterNoneSetBackgroundIconClick({
                  tab_el: tab_el$,
                }),
              ),
            ),
            tab_el$.__mp_can_set_background ? identity : tap($remove),
          ),
        ),
      ),

      tap(
        $findAll(`.footer.object[data-icon_type="duplicate"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterObjectDuplicateIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.object[data-icon_type="delete"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterObjectDeleteIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),

      tap(
        $findAll(`.footer.path[data-icon_type="set_color"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterPathSetColorIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="set_stroke"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterPathSetStrokeIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="edit_path"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterPathEditPathIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="duplicate"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterPathDuplicateIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.path[data-icon_type="delete"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterPathDeleteIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),

      tap(
        $findAll(`.footer.shape[data-icon_type="set_color"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterShapeSetColorIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.shape[data-icon_type="set_stroke"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterShapeSetStrokeIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.shape[data-icon_type="duplicate"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterShapeDuplicateIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.shape[data-icon_type="delete"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterShapeDeleteIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),

      tap(
        $findAll(`.footer.text[data-icon_type="decorate_text"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterTextDecorateTextIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.text[data-icon_type="open_keyboard"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterTextOpenKeyboardIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.text[data-icon_type="duplicate"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterTextDuplicateIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.footer.text[data-icon_type="delete"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleFooterTextDeleteIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),

      tap(
        $findAll(`.blank_plate[data-target="top_menu"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuBlankPlateClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.HISTORY_RESET.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorStickerSingleMobileEventF.handleTopMenuHistoryResetIconClick())),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.HISTORY_UNDO.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorStickerSingleMobileEventF.handleTopMenuHistoryUndoIconClick())),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.HISTORY_REDO.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorStickerSingleMobileEventF.handleTopMenuHistoryRedoIconClick())),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.TOGGLE_LAYERS_MODE.ID}"] input[type="button"]`,
        ),
        each($on('click', VectorEditorStickerSingleMobileEventF.handleTopMenuToggleLayersModeIconClick())),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.DELETE.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuDeleteIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.FORWARD.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuForwardIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.BACKWARD.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuBackwardIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.GROUP.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuGroupIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.UNGROUP.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuUngroupIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.LOCK.ID}"] input[type="button"]`,
        ),
        each(
          $on('click', VectorEditorStickerSingleMobileEventF.handleTopMenuLockIconClick({ tab_el: tab_el$ })),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.UNLOCK.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuUnlockIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.FLIP_HORIZONTAL.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuFlipHorizontalIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.FLIP_VERTICAL.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuFlipVerticalIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_LEFT_END.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuMoveToLeftEndIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_RIGHT_END.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuMoveToRightEndIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_TOP_END.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuMoveToTopEndIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_BOTTOM_END.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuMoveToBottomEndIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuMoveToHorizontalCenterIconClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.top_menu_container [data-icon_type="${VectorEditorStickerSingleMobileConstS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID}"] input[type="button"]`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleTopMenuMoveToVerticalCenterIconClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),

      tap(
        $findAll(`.blank_plate[data-target="bottom_menu"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleBottomMenuBlankPlateClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="text"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleBottomMenuTextIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="path"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleBottomMenuPathIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="shape"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleBottomMenuShapeIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),
      tap(
        $findAll(`.bottom_menu[data-icon_type="image"]`),
        each(
          $on(
            'click',
            VectorEditorStickerSingleMobileEventF.handleBottomMenuImageIconClick({ tab_el: tab_el$ }),
          ),
        ),
      ),

      tap(
        $findAll(`.editor_container`),
        each(
          $on(
            '@mp/sticker-editor/select',
            VectorEditorStickerSingleMobileEventF.handleSingleStickerEditorSelect({ tab_el: tab_el$ }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/unselect',
            VectorEditorStickerSingleMobileEventF.handleSingleStickerEditorUnselect({ tab_el: tab_el$ }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/lock',
            VectorEditorStickerSingleMobileEventF.handleSingleStickerEditorLock({ tab_el: tab_el$ }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/unlock',
            VectorEditorStickerSingleMobileEventF.handleSingleStickerEditorUnlock({ tab_el: tab_el$ }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/add',
            VectorEditorStickerSingleMobileEventF.handleSingleStickerEditorAdd({ tab_el: tab_el$ }),
          ),
        ),
        each(
          $on(
            '@mp/sticker-editor/remove',
            VectorEditorStickerSingleMobileEventF.handleSingleStickerEditorRemove({ tab_el: tab_el$ }),
          ),
        ),
      ),
    );
    tab_el$.__fonts = await go(axios.get(`/${T.lang}/@api/maker/fonts`), sel('data'));
    await VectorEditorFontF.loadBaseFonts();
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    tab_el$.__mp_handleWindowMouseMove = VectorEditorStickerSingleMobileEventF.handleWindowMouseMove({
      tab_el: tab_el$,
    });
    $on('touchmove', tab_el$.__mp_handleWindowMouseMove, { passive: false })(window);

    const single_sticker_editor = new EditorNS.SingleStickerEditorNS.MobileNS.SingleStickerMobileEditor({
      window,
      document,
      touch_margin: 6,
    });
    tab_el$.__mp_single_sticker_editor = single_sticker_editor;
    single_sticker_editor.initDOM();
    single_sticker_editor.initEditor();
    single_sticker_editor.setArtBoardSize({
      width: tab_el$.__mp_art_board_size.width,
      height: tab_el$.__mp_art_board_size.height,
    });
    const foreground_el = (() => {
      const foreground_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
      if (tab_el$.__mp_foreground_el != null) {
        foreground_el.appendChild(tab_el$.__mp_foreground_el);
      }
      if (tab_el$.__mp_empty_template_el != null) {
        tab_el$.__mp_empty_template_el.removeAttributeNS(null, 'display');
        foreground_el.appendChild(tab_el$.__mp_empty_template_el);
      }
      return foreground_el;
    })();
    single_sticker_editor.setForeground(foreground_el);
    single_sticker_editor.setBackground({
      type: 'color',
      fill: tab_el$.__mp_background_color,
      opacity: tab_el$.__mp_background_opacity,
    });
    go(
      tab_el$,
      $findAll('.editor_container'),
      takeL(1),
      each((el) => single_sticker_editor.appendTo(el)),
    );
    tab_el$.__mp_preProcess?.(single_sticker_editor);
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {
    tab_el$.__mp_is_prevent_mouse_move = true;
    tab_el$.__mp_single_sticker_editor?.fitSize();
    const view_box_vo = tab_el$.__mp_single_sticker_editor?.calculateViewBoxFitToArtBoard({
      padding_in_vcs: { top: 16 + 48, right: 16, bottom: 16 + 144, left: 16 },
    });
    tab_el$.__mp_single_sticker_editor?.setViewBox(view_box_vo);
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {
    tab_el$.__mp_single_sticker_editor?.selectEls?.([]);
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {
    tab_el$.__mp_is_prevent_mouse_move = false;
  }, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {
    if (tab_el$.__mp_handleWindowMouseMove != null) {
      $off('touchmove', tab_el$.__mp_handleWindowMouseMove, { passive: false })(window);
    }

    tab_el$.__mp_art_board_size = { width: 0, height: 0 };
    tab_el$.__mp_foreground_el = null;
    tab_el$.__mp_empty_template_el = null;
    tab_el$.__mp_preProcess = null;
    tab_el$.__mp_postProcess = null;
    tab_el$.__mp_is_need_cutting_line = false;
    tab_el$.__mp_can_set_background = false;
    tab_el$.__mp_background_color = 'none';
    tab_el$.__mp_background_opacity = 1;
    tab_el$.__fonts = null;
    tab_el$.__mp_single_sticker_editor?.destroy();
    tab_el$.__mp_single_sticker_editor = null;
    tab_el$.__mp_is_prevent_mouse_move = false;
    tab_el$.__mp_handleWindowMouseMove = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
