import { CommonNS } from '@marpple/sticker-editor';
import { nanoid } from 'nanoid';

export const applyCuttingLine = ({ target_image_el, path_data }) => {
  const inner_cutting_line_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
  inner_cutting_line_path_el.setAttributeNS(null, 'd', path_data);

  const clip_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'clipPath');
  clip_path_el.setAttributeNS(
    null,
    'id',
    `${CommonNS.ConstNS.DATA_KEY_ROLE}__inner-cutting-line__${nanoid()}`,
  );
  clip_path_el.appendChild(inner_cutting_line_path_el);

  const defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
  defs_el.appendChild(clip_path_el);

  const wrapper_g_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
  wrapper_g_el.setAttributeNS(null, 'clip-path', `url(#${clip_path_el.id})`);

  Array.from(target_image_el.childNodes).forEach((node) => wrapper_g_el.appendChild(node));
  target_image_el.appendChild(defs_el);
  target_image_el.appendChild(wrapper_g_el);

  return target_image_el;
};
