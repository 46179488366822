import { toPrintCm } from '../../../../../../modules/Df/Projection/List/F/tmpl.js';

export const pdfUtil = {
  drawLayoutBorder: ({ doc, page_margin, width }) => {
    const [page_width, page_height] = [doc.getPageWidth(), doc.getPageHeight()];
    doc.setLineWidth(width);
    doc.rect(page_margin, page_margin, page_width - 2 * page_margin, page_height - 2 * page_margin);
  },
  drawGrayBox: ({ doc, x, y, w, h, fillColor = 200, style = 'FD', round = 0 }) => {
    doc.setFillColor(fillColor);
    round === 0 ? doc.rect(x, y, w, h, style) : doc.roundedRect(x, y, w, h, round, round, style);
  },
  drawText: ({ doc, size = 16, text, x, y, isBold = false, font = 'helvetica', textColor = '#000' }) => {
    doc.setFont(font, isBold ? 'bold' : 'normal');
    doc.setFontSize(size);
    doc.setTextColor(textColor);
    doc.text(text, x, y);
  },
  drawTextWithBox: ({
    doc,
    size = 16,
    text,
    x,
    y,
    isBold = false,
    font = 'helvetica',
    fillColor,
    w,
    single_text_w,
    text_h,
    textColor,
    rect_round,
  }) => {
    pdfUtil.drawGrayBox({
      doc,
      x: x - 2,
      y: y - 5,
      w: w ?? text.length * single_text_w,
      h: text_h,
      fillColor,
      style: 'F',
      round: rect_round,
    });
    pdfUtil.drawText({ doc, size, text, x, y, isBold, font, textColor });
  },
  drawFullHorLines: ({ doc, start_top, page_margin, line_height, counts }) => {
    const page_width = doc.getPageWidth();
    new Array(counts).fill().forEach((_, i) => {
      const top = start_top + line_height * i;
      doc.line(page_margin, top, page_width - page_margin, top);
    });
  },
};

export function isDarkColor(colorCode) {
  // Remove the '#' character from the beginning of the color code
  const hex = colorCode.replace('#', '');

  // Parse the hex color components (r, g, b)
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Calculate the brightness using a simple formula (perceived luminance)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // You can adjust this threshold to your preference
  const threshold = 128;

  // Return true if the brightness is below the threshold (considered dark)
  return brightness < threshold;
}

export function getFaceInfoText({ pf }) {
  let face_name_str;
  if (pf.face_name) {
    face_name_str = pf.face_name;
  }
  if (pf.face_name_en && pf.face_name !== pf.face_name_en) {
    face_name_str += `(${pf.face_name_en})`;
  }

  let design_size_str = '';

  if (pf.selected_printable_file) {
    // 인쇄용 시안 파일의 디자인을 추출해야 하는 경우
    const file = pf.selected_printable_file;
    if (Number(file.width) > 0 && Number(file.height) > 0) {
      const has_png = !!file.png;
      const is_ai = !!file.ai_url;
      const w = has_png ? file.png.width : file.width;
      const h = has_png ? file.png.height : file.height;
      design_size_str += `${toPrintCm(w, is_ai)} x ${toPrintCm(h, is_ai)} cm`;
    }
  } else {
    if (pf.design_size) {
      const is_exist_design_size = pf.design_size?.width_cm > 0 && pf.design_size?.height_cm > 0;
      if (is_exist_design_size) {
        design_size_str += `${getDesignSizeString({
          w: pf.design_size.width_cm,
          h: pf.design_size.height_cm,
        })}`;
      }
    }
  }

  return { face_name_str, design_size: { line: 1, str: design_size_str } };
}

function getDesignSizeString({ w, h }) {
  return `${w} x ${h} cm`;
}

export function countCharacters(str) {
  let koreanCount = 0;
  let englishCount = 0;
  let specialCount = 0;

  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i);

    if (/[\uAC00-\uD7AF]/.test(char)) {
      koreanCount++;
    } else if (/[a-zA-Z]/.test(char)) {
      englishCount++;
    } else if (/[^a-zA-Z\uAC00-\uD7AF]/.test(char)) {
      specialCount++;
    }
  }

  return { korean: koreanCount, english: englishCount, special: specialCount };
}

export function removeKoreanLetters(str) {
  return str.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
}
