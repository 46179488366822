import { go } from 'fxjs/es';
import { $closest, $delegate } from 'fxdom/es';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { getCurrentTool, setCanvasCursor } from './helpers.dom.js';
import { TOOL_NAME } from '../../S/Constant/constants.js';
import { hasRemainingEraseTask } from './tool.erase.js';

export function buttonEvents({ tab_el }) {
  go(
    tab_el,
    $delegate('click', '.tool-panel button', async (e) => {
      const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
      setCanvasCursor('default', true);
      const ct = e.currentTarget;
      const prev_selected_tool = getCurrentTool({ tab_el });
      const current_select_tool = ct.getAttribute('tool-name');

      if (prev_selected_tool === current_select_tool) return;

      switch (prev_selected_tool) {
        case TOOL_NAME.erase: {
          const is_remaining_erase_task = hasRemainingEraseTask({ paper_scope });
          if (is_remaining_erase_task) {
            const ask_if_do_erase = await $.confirm(
              `작업 중인 지우개 작업이 있습니다.<br>적용하고 넘어 가시겠습니까?`,
            );
            DfImageEditorF.focusBackToFrame({ tab_el });

            if (ask_if_do_erase) {
              await DfImageEditorF.doErase({ paper_scope });
            } else {
              return;
            }
          }
          DfImageEditorF.removeAllBrushGroup({ layer: DfImageEditorF.getEraseLayer({ paper_scope }) });
          break;
        }
      }
      DfImageEditorF.deactivateSpotTool({ tab_el });
      DfImageEditorLibF.updateDomState.toolSelection(current_select_tool);

      await activateTool({ tab_el, tool_name: current_select_tool });

      DfImageEditorLibF.setState.toolSelection({ tool_name: current_select_tool, with_dom_update: false });
    }),
    $delegate('click', '.property-panel .sub-tools button', (e) => {
      const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
      const ct = e.currentTarget;
      const $sub_tools = $closest('.sub-tools', ct);
      const tool_name = $sub_tools.getAttribute('tool-name');
      const sub_tool_name = ct.name;
      DfImageEditorLibF.setState.subToolSelection({
        paper_scope,
        tool_name,
        sub_tool_name,
        with_dom_update: true,
      });
    }),
    $delegate('click', 'button', () => {
      DfImageEditorF.focusBackToFrame({ tab_el });
    }),
  );
}

export async function activateTool({ tab_el, tool_name }) {
  switch (tool_name) {
    case TOOL_NAME.selection: {
      DfImageEditorF.activateSelectionTool({ tab_el });
      break;
    }
    case TOOL_NAME.background: {
      await DfImageEditorF.activateBackgroundTool({ tab_el });
      break;
    }
    case TOOL_NAME.erase: {
      await DfImageEditorF.activateEraseTool({ tab_el });
      break;
    }
    case TOOL_NAME.scale: {
      DfImageEditorF.activateScaleTool({ tab_el });
      break;
    }
    case TOOL_NAME.vStroke: {
      await DfImageEditorF.activateStrokeTool({ tab_el });
      break;
    }
    case TOOL_NAME.rStroke: {
      await DfImageEditorF.activateRasterStrokeTool({ tab_el });
      break;
    }
    case TOOL_NAME.spot: {
      await DfImageEditorF.activateSpotTool({ tab_el });
      break;
    }
    case TOOL_NAME.file: {
      DfImageEditorF.activateFileTool({ tab_el });
      break;
    }
  }
}
