import { entries, flat, go, groupBy, head, identity, mapObject, pipe, sel, sumBy, values } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import DfPaginationF from '../../Pagination/F/index.js';

export const worker_options = (group_by_team) =>
  go(
    group_by_team,
    values,
    flat,
    sumBy(({ id, name }) => legacyHtml` <option value="${id}">${name}</option> `),
  );

export const option_all = (value = 'all') => `<option value="${value}">전체</option>`;

export function search_form(workers) {
  const group_by_team = groupBy(sel('team_id'), workers);
  const teams = mapObject(pipe(head, sel('team_name')), group_by_team);

  return legacyHtml`
    <form id="search" action="/logs" method="get">
      <div class="search-form-item">
        <label for="input_url">URL</label>
        <input id="input_url" name="url_path" type="text" />
      </div>

      <div class="search-form-item">
        <label for="select_team">부서</label>
        <select id="select_team" name="team_id" type="text">
          ${option_all()}
          ${go(
            teams,
            entries,
            sumBy(([k, v]) => legacyHtml` <option value="${k}">${v}</option> `),
          )}
        </select>
      </div>

      <div class="search-form-item">
        <label for="select_user">사용자</label>
        <select id="select_user" name="user_id" type="text">
          ${option_all()} ${worker_options(group_by_team)}
        </select>
      </div>

      <button type="submit">검색</button>
    </form>
  `;
}

export function log_table(rows) {
  const columns = [
    { name: 'name' },
    { name: 'url_path' },
    { name: 'method' },
    { name: 'ip_address' },
    {
      name: 'created_at',
      format: (a) => moment(a).format('YYYY-MM-DD:HH:mm:ss'),
    },
    {
      name: 'detail',
      format: (_, { id }) => `<a href="/logs/detail?id=${id}">이동</a>`,
    },
  ];

  return legacyHtml`
    <table data-name="log_list">
      <thead>
        <tr>
          <th>사용자</th>
          <th data-name="url_path">URL</th>
          <th>METHOD</th>
          <th>IP 주소</th>
          <th>요청 시각</th>
          <th>상세 보기</th>
        </tr>
      </thead>
      <tbody>
        ${sumBy(
          (r) =>
            `<tr>${sumBy(
              ({ name, format = identity }) => `<td data-name="${name}">${format(r[name], r)}</td>`,
              columns,
            )}</tr>`,
          rows,
        )}
      </tbody>
    </table>
  `;
}

export function main(workers, logs, pagination_states) {
  return legacyHtml` ${search_form(workers)} ${log_table(logs)} ${DfPaginationF.tmpl.main(
    pagination_states,
  )} `;
}

export function detail(log_data) {
  const {
    id,
    user_id,
    name,
    team_name,
    ip_address,
    url_path,
    query_string,
    method,
    request_body,
    created_at,
  } = log_data;

  return legacyHtml`
    <div id="content">
      <h1 class="title">접속 기록 상세 조회</h1>
      <div class="horizontal">
        <h3 class="column-name">로그 ID</h3>
        <p>${id}</p>
      </div>

      <div class="horizontal">
        <h3 class="column-name">사용자 ID</h3>
        <p>${user_id}</p>
      </div>

      <div class="horizontal">
        <h3 class="column-name">사용자</h3>
        <p>${team_name} ${name}</p>
      </div>

      <div class="horizontal">
        <h3 class="column-name">요청 시각</h3>
        <p>${moment(created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')}</p>
      </div>

      <div class="horizontal">
        <h3 class="column-name">IP 주소</h3>
        <p>${ip_address}</p>
      </div>

      <div class="horizontal">
        <h3 class="column-name">URL</h3>
        <p>${method} ${url_path}</p>
      </div>

      ${
        query_string
          ? legacyHtml`
            <div class="vertical">
              <h3 class="column-name">Query String</h3>
              <p>${query_string}</p>
            </div>
          `
          : ''
      }
      ${
        request_body
          ? legacyHtml`
            <div class="vertical">
              <h3 class="column-name">Request Body</h3>
              <p>${request_body}</p>
            </div>
          `
          : ''
      }
    </div>
  `;
}
