import { CommonNS, EditorNS } from '@marpple/sticker-editor';
import { VectorEditorStickerSinglePCCuttingLineAutoMuiS } from '../../S/Mui/module/VectorEditorStickerSinglePCCuttingLineAutoMuiS.js';
import {
  appendL,
  concatL,
  constant,
  each,
  equals2,
  findIndex,
  go,
  head,
  html,
  isNil,
  join,
  map,
  mapL,
  rangeL,
  takeAll,
  takeL,
  tap,
} from 'fxjs/es';
import { $findAll, $on, $setCss } from 'fxdom/es';
import { VectorEditorStickerSinglePCCuttingLineAutoEventF } from '../Event/module/VectorEditorStickerSinglePCCuttingLineAutoEventF.js';
import { PCRightFixedPanelF } from '../../../../../../../PCRightFixedPanel/F/Function/module/PCRightFixedPanelF.js';
import { getOutlinePathsFromImgWithAdaptiveSimplify, makePathsBridged } from '@marpple/stickerizer';
import { makeCuttingLine, setCuttingLine } from '../Function/cutting_line.js';
import { VectorEditorStickerSinglePCCuttingLineAutoConstantS } from '../../S/Constant/module/VectorEditorStickerSinglePCCuttingLineAutoConstantS.js';
import { VectorEditorFontF } from '../../../../../../Font/F/Function/module/VectorEditorFontF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorStickerSinglePCCuttingLineAutoMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el$) {
    tab_el$.__mp_is_init = false;
    tab_el$.__mp_target_image_el = null;
    tab_el$.__mp_factor = 1;
    tab_el$.__mp_base_cutting_line = null;
    tab_el$.__mp_offsets = [];
    tab_el$.__mp_cutting_lines = [];
    tab_el$.__mp_cutting_line_index = 0;
    tab_el$.__mp_single_sticker_editor = tab_el$.__mp_single_sticker_editor ?? null;
    tab_el$.__mp_frame_position_top = tab_el$.__mp_frame_position_top ?? 0;
    tab_el$.__mp_frame_position_height = tab_el$.__mp_frame_position_height ?? 0;
    tab_el$.__mp_postProcess = tab_el$.__mp_postProcess ?? null;
    tab_el$.__mp_prev_frame_right_panel_el = tab_el$.__mp_prev_frame_right_panel_el ?? null;
    tab_el$.__mp_prev_page_right_panel_el = tab_el$.__mp_prev_page_right_panel_el ?? null;
    tab_el$.__mp_is_go_to_prev_frame = false;
    tab_el$.__mp_is_go_to_next_page = false;
    tab_el$.__mp_editor_padding_top = tab_el$.__mp_editor_padding_top ?? 66 + 30;
    tab_el$.__mp_editor_padding_bottom = tab_el$.__mp_editor_padding_bottom ?? 30;
    tab_el$.__mp_editor_padding_left = tab_el$.__mp_editor_padding_left ?? 0;
    tab_el$.__mp_editor_padding_right = tab_el$.__mp_editor_padding_right ?? 0;

    go(
      tab_el$,

      tap(
        $findAll(`.right_container .right_wrapper .button_container .cancel`),
        each(
          $on('click', VectorEditorStickerSinglePCCuttingLineAutoEventF.handleRightFooterCancelButtonClick()),
        ),
      ),
      tap(
        $findAll(`.right_container .right_wrapper .button_container .done`),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCCuttingLineAutoEventF.handleRightFooterDoneButtonClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),

      tap(
        $findAll(
          `.right_container .right_wrapper .panel_container .right_panel .control_container .offset_control .decrease`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCCuttingLineAutoEventF.handleRightPanelOffsetDecreaseClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
      tap(
        $findAll(
          `.right_container .right_wrapper .panel_container .right_panel .control_container .offset_control .increase`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCCuttingLineAutoEventF.handleRightPanelOffsetIncreaseClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),

      tap(
        $findAll(
          `.right_container .right_wrapper .panel_container .right_panel .control_container .edit_manual`,
        ),
        each(
          $on(
            'click',
            VectorEditorStickerSinglePCCuttingLineAutoEventF.handleRightPanelEditManualClick({
              tab_el: tab_el$,
            }),
          ),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el$) {
    const cutting_line_editor = new EditorNS.CuttingLineEditorNS.PCNS.CuttingLinePCEditor({
      window,
      document,
      min_zoom_scale: 1,
      max_zoom_scale: 3,
    });
    cutting_line_editor.initDOM();
    cutting_line_editor.initEditor();
    go(
      tab_el$,
      $findAll(`.editor_container`),
      takeL(1),
      each((editor_container_el) => cutting_line_editor.appendTo(editor_container_el)),
    );
    tab_el$.__mp_cutting_line_editor = cutting_line_editor;
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el$) {
    $setCss(['top', `${tab_el$.__mp_frame_position_top}px`])(tab_el$);
    $setCss(['height', `${tab_el$.__mp_frame_position_height}px`])(tab_el$);

    if (tab_el$.__mp_is_go_to_next_page) {
      tab_el$.__mp_is_go_to_next_page = false;
      return;
    }

    return go(
      tab_el$,
      $findAll(`.right_container .right_wrapper`),
      takeL(1),
      each((right_wrapper_el) => {
        if (tab_el$.__mp_prev_page_right_panel_el != null) {
          return PCRightFixedPanelF.showPcRightFixedPanel({
            right_el: right_wrapper_el,
            left_el: tab_el$.__mp_prev_page_right_panel_el,
          });
        }
      }),
    );
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  async rendered(tab_el$) {
    if (tab_el$.__mp_is_init) {
      return;
    }
    tab_el$.__mp_is_init = true;

    $.don_loader_start();
    try {
      const { without_background_el: target_image_el } =
        await tab_el$.__mp_single_sticker_editor?.exportEditor({
          factor: 1,
          is_art_board_clip_path_on: false,
          container_el: document.body,
        });
      const font_style_defs_el = /** @type {SVGDefsElement} */ await go(
        target_image_el,
        VectorEditorFontF.makeFontStyleEl({ is_encode_base64: true }),
        (style_el) => {
          const defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
          defs_el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE] = 'font-face-defs';
          defs_el.appendChild(style_el);
          return defs_el;
        },
      );
      tab_el$.__mp_target_image_el = target_image_el;
      const target_image_width = target_image_el.width.baseVal.value;
      const target_image_height = target_image_el.height.baseVal.value;
      tab_el$.__mp_cutting_line_editor.fitSize();
      const view_box_vo = tab_el$.__mp_cutting_line_editor.calculateViewBoxFitTo({
        size_in_ucs: {
          width: target_image_width,
          height: target_image_height,
        },
        padding_in_vcs: {
          top: tab_el$.__mp_editor_padding_top,
          bottom: tab_el$.__mp_editor_padding_bottom,
          left: tab_el$.__mp_editor_padding_left,
          right: tab_el$.__mp_editor_padding_right,
        },
      });
      tab_el$.__mp_cutting_line_editor.setViewBox(view_box_vo);

      await (async () => {
        const min_px = 1024;

        const sx = min_px / target_image_width;
        const sy = min_px / target_image_height;
        tab_el$.__mp_factor = Math.max(sx, sy);

        const { without_background_el: large_target_image_el } =
          await tab_el$.__mp_single_sticker_editor.exportEditor({
            factor: tab_el$.__mp_factor,
            is_art_board_clip_path_on: false,
            container_el: document.body,
          });
        large_target_image_el.prepend(await CommonNS.UtilNS.deepCloneNode(font_style_defs_el));
        const large_target_image_blob = new Blob(
          [new XMLSerializer().serializeToString(large_target_image_el)],
          {
            type: 'image/svg+xml',
          },
        );
        const large_target_image_svg_data_url = /** @type {string} */ await new Promise((resolve, reject) => {
          const file_reader = new FileReader();
          file_reader.onerror = reject;
          file_reader.onload = () => resolve(file_reader.result);
          file_reader.readAsDataURL(large_target_image_blob);
        });
        const large_target_image_img_el = /** @type {HTMLImageElement} */ await new Promise(
          (resolve, reject) => {
            const img_el = new Image();
            img_el.onload = () => resolve(img_el);
            img_el.onerror = reject;
            img_el.src = large_target_image_svg_data_url;
          },
        );
        await new Promise((resolve) => setTimeout(resolve, 600));
        const large_target_image_canvas_el = document.createElement('canvas');
        large_target_image_canvas_el.width = large_target_image_img_el.width;
        large_target_image_canvas_el.height = large_target_image_img_el.height;
        const large_target_image_canvas_ctx = large_target_image_canvas_el.getContext('2d');
        large_target_image_canvas_ctx.drawImage(
          large_target_image_img_el,
          0,
          0,
          large_target_image_img_el.width,
          large_target_image_img_el.height,
          0,
          0,
          large_target_image_canvas_el.width,
          large_target_image_canvas_el.height,
        );
        const large_target_image_png_blob = /** @type {Blob} */ await new Promise((resolve, reject) =>
          large_target_image_canvas_el.toBlob((blob) => {
            if (isNil(blob)) {
              const error = new Error(`Cannot make a PNG image.`);
              error.__mp_alert_message = 'PNG 이미지를 해석할 수 없습니댜.';
              reject(error);
              return;
            }
            resolve(blob);
          }, 'image/png'),
        );
        const cutting_line_target_image_png_data_url = await new Promise((resolve, reject) => {
          const file_reader = new FileReader();
          file_reader.addEventListener('load', () => resolve(file_reader.result));
          file_reader.addEventListener('error', reject);
          file_reader.readAsDataURL(large_target_image_png_blob);
        });

        const fit_cutting_line = await getOutlinePathsFromImgWithAdaptiveSimplify(
          cutting_line_target_image_png_data_url,
        );
        tab_el$.__mp_base_cutting_line = await makePathsBridged(fit_cutting_line.paper_obj);
        tab_el$.__mp_offsets = takeAll(concatL(rangeL(-2, 0, 0.5))(rangeL(0, 5, 1)));
        tab_el$.__mp_cutting_lines = map(constant(null))(tab_el$.__mp_offsets);
      })();

      await (async () => {
        const index =
          findIndex(
            equals2(VectorEditorStickerSinglePCCuttingLineAutoConstantS.START_OFFSET),
            tab_el$.__mp_offsets,
          ) ?? tab_el$.__mp_offsets.length - 1;
        const offset = tab_el$.__mp_offsets[index];
        const label = `${offset}`;
        const path_data = await makeCuttingLine({ tab_el: tab_el$, offset }).catch(async (error) => {
          console.error(error);
          await $.alert(html`
            재단선 자동 생성에 실패했습니다.<br />
            기본 재단선으로 시작합니다.
          `);

          const {
            x: container_x,
            y: container_y,
            width: container_width,
            height: container_height,
          } = go(
            tab_el$,
            $findAll(`.editor_container`),
            mapL((el) => el.getBoundingClientRect()),
            head,
          );

          const width = Math.round(container_width / 3);
          const height = Math.round(container_height / 3);
          const x = container_x + (container_width - width) / 2;
          const y = container_y + (container_height - height) / 2;

          return go(
            [
              ['M', x, y],
              ['L', x + width, y],
              ['L', x + width, y + height],
              ['L', x, y + height],
            ],
            mapL(([command, x, y]) => {
              const view_port_point = tab_el$.__mp_cutting_line_editor.toViewPortPoint({ x, y });
              const user_point = tab_el$.__mp_cutting_line_editor.toUserPoint({
                x: view_port_point.x,
                y: view_port_point.y,
              });
              return `${command} ${user_point.x} ${user_point.y}`;
            }),
            appendL('Z'),
            join(' '),
          );
        });
        const clone_target_image_el = await CommonNS.UtilNS.deepCloneNode(target_image_el);
        tab_el$.__mp_cutting_line_editor?.disconnectCuttingLineClipPath?.();
        setCuttingLine({ tab_el: tab_el$, path_data, label });
        tab_el$.__mp_cutting_line_editor.setTargetImageEl(clone_target_image_el);
        tab_el$.__mp_cutting_lines[index] = { index, label, path_data };
        tab_el$.__mp_cutting_line_index = index;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        tab_el$.__mp_cutting_line_editor?.connectCuttingLineClipPath?.();
      })();
    } finally {
      $.don_loader_end();
    }
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el$, v) {
    if (tab_el$.__mp_is_go_to_next_page) {
      return;
    }

    if (tab_el$.__mp_is_go_to_prev_frame) {
      return go(
        tab_el$,
        $findAll(`.right_container .right_wrapper`),
        takeL(1),
        each((right_wrapper_el) => {
          if (tab_el$.__mp_prev_frame_right_panel_el != null) {
            return PCRightFixedPanelF.hidePcRightFixedPanel({
              right_el: right_wrapper_el,
              left_el: tab_el$.__mp_prev_frame_right_panel_el,
            });
          }
        }),
        tap(() => (tab_el$.__mp_is_go_to_prev_frame = false)),
      );
    }

    return go(
      tab_el$,
      $findAll(`.right_container .right_wrapper`),
      takeL(1),
      each((right_wrapper_el) => {
        if (tab_el$.__mp_prev_page_right_panel_el != null) {
          return PCRightFixedPanelF.hidePcRightFixedPanel({
            right_el: right_wrapper_el,
            left_el: tab_el$.__mp_prev_page_right_panel_el,
          });
        }
      }),
    );
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {
    tab_el$.__mp_is_init = false;
    tab_el$.__mp_target_image_el = null;
    tab_el$.__mp_factor = 1;
    tab_el$.__mp_base_cutting_line = null;
    tab_el$.__mp_offsets = [];
    tab_el$.__mp_cutting_lines = [];
    tab_el$.__mp_cutting_line_index = 0;
    tab_el$.__mp_single_sticker_editor = null;
    tab_el$.__mp_frame_position_top = 0;
    tab_el$.__mp_frame_position_height = 0;
    tab_el$.__mp_postProcess = null;
    tab_el$.__mp_prev_frame_right_panel_el = null;
    tab_el$.__mp_prev_page_right_panel_el = null;
    tab_el$.__mp_is_go_to_prev_frame = false;
    tab_el$.__mp_is_go_to_next_page = false;
    tab_el$.__mp_editor_padding_top = 0;
    tab_el$.__mp_editor_padding_bottom = 0;
    tab_el$.__mp_editor_padding_left = 0;
    tab_el$.__mp_editor_padding_right = 0;

    tab_el$.__mp_factor = null;
    tab_el$.__mp_base_cutting_line = null;
    tab_el$.__mp_offsets = null;
    tab_el$.__mp_cutting_lines = null;
    tab_el$.__mp_cutting_line_index = null;

    tab_el$.__mp_cutting_line_editor?.destroy?.();
    tab_el$.__mp_cutting_line_editor = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el$) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
