import { truncTextAdv } from '../../../Stock/Labeling/F/Function/labeling.js';
import { chunk, go, join, map, pipe } from 'fxjs/es';

export const WAYBILL_CJ_LABEL_WIDTH = 102;

const addHypenToString = (str, step) => go(str, chunk(step), map(join('')), join('-'));

export const waybill_CJ_label_data_formatter = pipe(
  //input: data.CJ_apis {address_reference_params_list + reservation_params_list}
  map((d) => {
    if (!d) return;
    const invoice_no = addHypenToString(d.INVC_NO, 4);
    return {
      invoice_no, //운송장 번호
      projection_id: d?.projection_id ?? '',
      receipt_date: moment(d.RCPT_YMD, 'YYYYMMDD').format('YYYY.MM.DD'), //접수일자
      classification_barcode: d.CLSFCD, //도착지 코드 전체
      classification_code_prefix: d.CLSFCD[0], //도착지 코드 첫번째 코드
      classification_code: d.CLSFCD.substr(1), //도착지 코드 뒤 세자리
      sub_classification_code: d.SUBCLSFCD, //도착지 서브 코드
      invoice_no_barcode: d.INVC_NO, //운송장 번호
      receiver_name: d.RCVR_NM, //수취인 이름
      receiver_contact: `${d.RCVR_TEL_NO1}-${d.RCVR_TEL_NO2}-${d.RCVR_TEL_NO3}${
        d.RCVR_CELL_NO1 ? ` / ${d.RCVR_CELL_NO1}-${d.RCVR_CELL_NO2}-${d.RCVR_CELL_NO3}` : ''
      }`, //수취인 연락처
      receiver_address: `${d.RCVR_ADDR} ${d.RCVR_DETAIL_ADDR}`, //수취인 주소
      classification_address: d.CLSFADDR, //주소 약칭
      sender_name: d.SENDR_NM, //발송자 이름
      sender_contact: `${d.SENDR_TEL_NO2 === '1566' ? '' : `${d.SENDR_TEL_NO1}-`}${d.SENDR_TEL_NO2}-${
        d.SENDR_TEL_NO3
      }`, //발송자 연락처
      sender_address: `${d.SENDR_ADDR} ${d.SENDR_DETAIL_ADDR}`, //발송자 주소
      goods_quantity: '1', //제품 수량
      freight_price: '0', //운임
      freight_division_code: '신용',
      goods_name: d?.goods_name ?? d?.GDS_NM ?? '주문제작상품', //상품명
      footer_classification_address: d.CLSFADDR, //주소 약칭
      footer_remark: d.REMARK_1, //배송메세지
      footer_delivery_label: `${d.CLLDLVBRANNM ?? ''} ${d.CLLDLVEMPNM ?? ''} ${d.CLLDLVEMPNICKNM ?? ''}`,
      footer_freight_price: '0', //총 운임
      footer_freight_division_code: '신용', //운임구분
      footer_invoice_no_barcode: d.INVC_NO, //운송장 번호
      footer_invoice_no_barcode_display_no: invoice_no, //운송장 번호
      projection_id_2d_code: `#${d?.projection_id ?? ''}`, //주문번호
      shipping_id: d.shipping_id,
    };
  }),
);

const y_offset = 0.5;

export const waybill_cj_format_data = [
  {
    description: '운송장번호',
    title: 'invoice_no',
    dev_name: 'INVC_NO',
    type: 'variable',
    field_box: {
      width: 50,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 14, y: 3 + y_offset },
    font: {
      name: 'G',
      height: 4.4,
    },
  },
  {
    description: '접수일자',
    title: 'receipt_date',
    dev_name: 'RCPT_YMD',
    type: 'variable',
    field_box: {
      width: 30,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 90, y: 3.5 + y_offset },
    font: {
      name: 'G',
      height: 3,
    },
  },
  {
    description: '분류코드바코드',
    title: 'classification_barcode',
    dev_name: 'CLSFCD',
    type: 'variable',
    scan_code_type: 'code128,A',
    position: { x: 3, y: 9 + y_offset },
    scan_code_options: {
      module_width: 3,
      height: 15,
    },
  },
  {
    description: '분류코드_PREFIX',
    title: 'classification_code_prefix',
    dev_name: 'CLSFCD_prefix',
    type: 'variable',
    field_box: {
      width: 10,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 35, y: 13 + y_offset },
    font: {
      name: 'G',
      height: 12,
    },
  },
  {
    description: '분류코드',
    title: 'classification_code',
    dev_name: 'CLSFCD',
    type: 'variable',
    field_box: {
      width: 50,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 45, y: 9.5 + y_offset },
    font: {
      name: 'G',
      height: 18,
    },
  },
  {
    description: '서브분류코드',
    title: 'sub_classification_code',
    dev_name: 'SUBCLSFCD',
    type: 'variable',
    field_box: {
      width: 25,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 88, y: 13 + y_offset },
    font: {
      name: 'G',
      height: 12,
    },
  },
  {
    description: '운송장번호바코드',
    title: 'invoice_no_barcode',
    dev_name: 'INVC_NO',
    type: 'variable',
    scan_code_type: 'code128,C',
    position: { x: 79, y: 25 + y_offset },
    scan_code_options: {
      module_width: 3,
      height: 5,
    },
  },
  {
    description: '받는분 이름',
    title: 'receiver_name',
    dev_name: 'RCVR_NM',
    type: 'variable',
    field_box: {
      width: 25,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    mutator: (text) => truncTextAdv(text, 3, 20),
    position: { x: 8, y: 26.5 + y_offset },
    font: {
      name: 'G',
      height: 3.2,
    },
  },
  {
    description: '받는분 연락처',
    title: 'receiver_contact',
    dev_name: 'RCVR_CELL',
    //유선 + 핸드폰 join("/")
    type: 'variable',
    field_box: {
      width: 57,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 30, y: 26.5 + y_offset },
    font: {
      name: 'G',
      height: 3,
    },
  },
  {
    description: '받는분 주소',
    title: 'receiver_address',
    dev_name: 'RCVR_ADDR+RCVR_DETAIL_ADDR',
    type: 'variable',
    field_box: {
      width: 113,
      max_lines: 2,
      line_gap: 0.2,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 8, y: 31 + y_offset },
    font: {
      name: 'G',
      height: 3,
    },
  },
  {
    description: '주소약칭',
    title: 'classification_address',
    dev_name: 'CLSFADDR',
    type: 'variable',
    field_box: {
      width: 113,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 8, y: 38 + y_offset },
    font: {
      name: 'G',
      height: 8,
    },
  },

  {
    description: '보내는분 이름',
    title: 'sender_name',
    dev_name: 'SENDR_NM',
    type: 'variable',
    field_box: {
      width: 30,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 8, y: 46 + y_offset },
    font: {
      name: 'G',
      height: 2.5,
    },
  },
  {
    description: '보내는분 연락처',
    title: 'sender_contact',
    dev_name: 'SENDR_CELL',
    type: 'variable',
    field_box: {
      width: 30,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 35, y: 46 + y_offset },
    font: {
      name: 'G',
      height: 2.5,
    },
  },
  {
    description: '보내는분주소',
    title: 'sender_address',
    dev_name: 'SENDR_ADDR',
    type: 'variable',
    field_box: {
      width: 113,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 8, y: 49.3 + y_offset },
    font: {
      name: 'G',
      height: 3,
    },
  },
  {
    description: '수량',
    title: 'goods_quantity',
    dev_name: 'GDS_QTY',
    type: 'variable',
    field_box: {
      width: 10,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 70, y: 46 + y_offset },
    font: {
      name: 'G',
      height: 3,
    },
  },
  {
    description: '운임',
    title: 'freight_price',
    dev_name: 'FRT',
    type: 'variable',
    field_box: {
      width: 10,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 95, y: 46 + y_offset },
    font: {
      name: 'G',
      height: 3,
    },
  },
  {
    description: '운임구분',
    title: 'freight_division_code',
    dev_name: 'FRT_DV_CD',
    type: 'variable',
    field_box: {
      width: 10,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 115, y: 46 + y_offset },
    font: {
      name: 'G',
      height: 3,
    },
  },
  {
    description: '상품명',
    title: 'goods_name',
    dev_name: 'GDS_NM',
    type: 'variable',
    field_box: {
      width: 113,
      max_lines: 10, // 주문 정보 1줄 + 아이템 정보 9 줄
      line_gap: 0.3,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 12, y: 54 + y_offset },
    font: {
      name: 'G',
      height: 2.5,
    },
  },
  {
    description: '주문번호 조회 2D코드',
    title: 'projection_id_2d_code',
    dev_name: 'projection_id_2d_code',
    type: 'variable',
    scan_code_type: 'aztec',
    scan_code_options: { mag: 4 },
    position: { x: 3, y: 54 + y_offset },
  },
  {
    description: '푸터_주소약칭',
    title: 'footer_classification_address',
    dev_name: 'CLSFADDR',
    type: 'variable',
    field_box: {
      width: 73,
      max_lines: 1,
      line_gap: 0.2,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 5, y: 86.5 + y_offset },
    font: {
      name: 'G',
      height: 3.0,
    },
  },
  {
    description: '푸터_배송메세지',
    title: 'footer_remark',
    dev_name: 'REMARK',
    type: 'variable',
    field_box: {
      width: 66,
      max_lines: 1,
      line_gap: 0.2,
      text_just: 'L',
      indent: 0,
    },
    mutator: (text) => truncTextAdv(text, 3, 63),
    position: { x: 5, y: 90 + y_offset },
    font: {
      name: 'G',
      height: 2.9,
    },
  },
  {
    description: '푸터_배달점소+배송사원_별칭',
    title: 'footer_delivery_label',
    dev_name: 'CLLDLV',
    //CLLDLVBRANNM + CLLDLVEMPNM + CLLDLVEMPNICKNM
    type: 'variable',
    field_box: {
      width: 70,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 4, y: 94 + y_offset },
    font: {
      name: 'G',
      height: 3.9,
    },
  },
  {
    description: '푸터_운임',
    title: 'footer_freight_price',
    dev_name: 'FRT',
    type: 'variable',
    field_box: {
      width: 12,
      max_lines: 1,
      line_gap: 0,
      text_just: 'R',
      indent: 0,
    },
    position: { x: 62, y: 90 + y_offset },
    font: {
      name: 'G',
      height: 3.9,
    },
  },
  {
    description: '푸터_운임구분',
    title: 'footer_freight_division_code',
    dev_name: 'FRT_DV_CD',
    type: 'variable',
    field_box: {
      width: 60,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 67, y: 94 + y_offset },
    font: {
      name: 'G',
      height: 3.9,
    },
  },
  {
    description: '푸터_운송장번호바코드',
    title: 'invoice_no_barcode',
    dev_name: 'INVC_NO',
    type: 'variable',
    scan_code_type: 'code128,C',
    position: { x: 80, y: 86 + y_offset },
    scan_code_options: {
      module_width: 3,
      height: 10,
    },
  },
  {
    description: '푸터_운송장번호바코드디스플레이번호',
    title: 'footer_invoice_no_barcode_display_no',
    dev_name: 'INVC_NO',
    type: 'variable',
    field_box: {
      width: 35,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 85, y: 97 + y_offset },
    font: {
      name: 'G',
      height: 3,
    },
  },
];
