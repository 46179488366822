import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { SVGEditorMobileImageUploadMuiF } from '../Mui/module/SVGEditorMobileImageUploadMuiF.js';

export const uploadImage = ({ postProcess }) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(SVGEditorMobileImageUploadMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = async (frame_el, v) => {
        await frame_closed.call(frame, frame_el, v);
        resolve(v);
      };

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_postProcess = postProcess;
        return tab_appending.call(tab, tab_el);
      };
    }).catch(reject),
  );
