import { strMap } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';

export const makeCompositeTemplateSelectionHtml = (assoc_composite_templates) => legacyHtml`
  <div class="assoc_composite_templates assoc_composite_template_list_style" _sel="assoc_composite_templates">
    ${strMap(makeCompositeTemplateHtml, assoc_composite_templates)}
  </div>
  <div class="option"><button class="next">${T('선택 완료')}</button></div>
`;

function makeCompositeTemplateHtml(assoc_composite_template) {
  return legacyHtml`
    <div
      class="assoc_composite_template checked"
      _sel="assoc_composite_templates->(#${assoc_composite_template.id})"
      data-composite_template_id="${assoc_composite_template.id}"
      width_more="${assoc_composite_template.width > assoc_composite_template.height}"
    >
      <div class="wrapper">
        <div class="canvas_wrapper">
          <canvas></canvas>
        </div>
        <div class="check_box"></div>
      </div>
    </div>
  `;
}
