import axios from 'axios';
import { changeDpiBlob, changeDpiDataUrl } from 'changedpi';
import { saveAs } from 'file-saver';
import { $appendTo, $delegate, $el, $find, $hide, $on, $qs, $remove, $text } from 'fxdom/es';
import {
  compact,
  each,
  entries,
  every,
  find,
  flatMap,
  go,
  head,
  ippL,
  filter,
  map,
  pipe,
  reduce,
  reject,
  sel,
  tap,
  values,
  range,
  pluck,
} from 'fxjs/es';
import { BpOptionMaskingTapeConstantS } from '../../../../BpOption/MaskingTape/S/Constant/module/BpOptionMaskingTapeConstantS.js';
import {
  createCanvasElement,
  evaluateImageBrightness,
  fillColorOnCanvas,
  makeCanvasByUrl,
  makeCloneCanvas,
} from '../../../../Canvas/S/util.js';
import { isSameCanvas, isSameSize, makeTrimCanvas } from '../../../../Maker/F/canvas_trim.js';
import { drawProductFace } from '../../../../Maker/F/draw_product_faces.js';
import { getBaseProductSizesInMaker } from '../../../../Maker/F/getSth.js';
import { isMakerDone } from '../../../../Maker/F/overflow.js';
import { unsetMakerPage } from '../../../../Maker/F/Setting/unset.js';
import {
  makeImageFromUrl,
  rejectWrongCvTextImage,
  ungroupAllWithObjAttrs,
} from '../../../../Maker/F/util.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { copyText } from '../../../../Util/F/fs.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { NewMakerBaseProductsTattooF } from '../../../BaseProducts/Tattoo/F/Function/module/NewMakerBaseProductsTattooF.js';
import { NewMakerBaseProductsTattooConstantS } from '../../../BaseProducts/Tattoo/S/Constant/module/NewMakerBaseProductsTattooConstantS.js';
import { NewMakerProductStyleS } from '../../../ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { NewMakerUtilS } from '../../../Util/S/Function/module/NewMakerUtilS.js';
import { NewMakerVirtualMakerMuiF } from '../../../VirtualMaker/F/Mui/module/NewMakerVirtualMakerMuiF.js';
import { makeTiffBlob } from './makeTiff.js';
import { NewMakerPrintResultF } from './module/NewMakerPrintResultF.js';
import { makeCuttinglineCanvas } from './outline.js';
import { makePngForWeProduct } from './we___ready.js';

//TODO jip G.mp.maker.is_auto_print 나중에 꼭 없애보자...

const makeDesignCanvasByCutPrintArea = async ({ target_area, print_area, product_face }) => {
  const ratio = target_area.width / print_area.width;
  const fcanvas = new fabric.StaticCanvas(document.createElement('canvas'), {
    enableRetinaScaling: false,
    width: target_area.width,
    height: target_area.height,
  });
  const diff = {
    top: Math.floor(print_area.top * ratio),
    left: Math.floor(print_area.left * ratio),
  };
  if (product_face?.cv_background) {
    product_face.cv_background.top = print_area.top - 1;
    product_face.cv_background.left = print_area.left - 1;
    product_face.cv_background.width = target_area.width / ratio + 2;
    product_face.cv_background.height = target_area.height / ratio + 2;
  }
  if (product_face)
    await go(
      [product_face.cv_background].concat(product_face.designs),
      compact,
      rejectWrongCvTextImage,
      reject((design) => design.visible === false),
      (designs2) => ungroupAllWithObjAttrs(fcanvas, designs2),
      tap(() => (G.mp.maker.sharp_resize_print = true)),
      map((design) => {
        return G.mp.maker.from_cv_attrs_for_maker(design, ratio, true);
      }),
      tap(() => (G.mp.maker.sharp_resize_print = false)),
      each(function (item) {
        item.top = Math.round(item.top - diff.top);
        item.left = Math.round(item.left - diff.left);
        item && fcanvas.add(item);
      }),
    );
  return fcanvas.lowerCanvasEl;
};

function getSizeFace({ bpf_id, base_product_size_id }) {
  return go(
    axios.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
      params: { id: bpf_id },
    }),
    sel('data.size_faces'),
    find((sf) => sf.base_product_size_id == base_product_size_id),
  );
}

export function getAnySizeFace({ bpf_id }) {
  return go(
    axios.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
      params: { id: bpf_id },
    }),
    sel('data.size_faces'),
    head,
  );
}

const makePxToResultPx = async ({ product_face, base_product_size_id }) => {
  const size_face = await getSizeFace({ bpf_id: product_face.bpf_id, base_product_size_id });
  const { result_px, px } = size_face.print;
  if (!(result_px.width > 0 && result_px.height > 0)) throw Error('result_px 필요');
  return makeDesignCanvasByCutPrintArea({ target_area: result_px, print_area: px, product_face });
};

function makeBgColor(c, color_code) {
  const canvas = createCanvasElement(c);
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = color_code;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(c, 0, 0);
  return canvas;
}

export const makePrintAreaResultByProductFace = async ({
  product_face,
  base_product_size_id,
  base_product_color_id,
  base_product,
  title,
}) => {
  const size_face = await getSizeFace({ bpf_id: product_face.bpf_id, base_product_size_id });
  const {
    dpi,
    bg_color,
    result_mask_url,
    has_cutting_line,
    is_90_rotate,
    image_type = 'image/png',
  } = size_face;
  if (!dpi) {
    throw Error('dpi 입력해 주세요.');
  }
  const { cm, px, result_px } = size_face.print;
  const { printable_file, with_color } = size_face;
  const inch1 = 2.54;
  const result_canvas = (async function () {
    if (printable_file?.url) {
      const target_area = await makeCanvasByUrl(printable_file.url);
      return makeDesignCanvasByCutPrintArea({
        target_area: {
          width: target_area.width,
          height: target_area.height,
        },
        print_area: printable_file,
        product_face,
      });
    }
    if (result_px && result_px.width > 0 && result_px.height > 0) {
      return makeDesignCanvasByCutPrintArea({ target_area: result_px, print_area: px, product_face });
    }
    if (!(cm.width > 0 && cm.height > 0)) {
      $.alert('cm 입력해 주세요.');
      throw Error('cm 필요');
    }
    const target_area = {
      width: Math.round((cm.width / inch1) * dpi),
    };
    target_area.height = cm.height * (target_area.width / cm.width);
    return makeDesignCanvasByCutPrintArea({
      target_area,
      print_area: px,
      product_face,
    });
  })();
  return go(
    result_canvas,
    (c) => {
      if (with_color) {
        if (!base_product_color_id) throw Error(`base_product_color_id 필요`);
        const { color_code } = $.get('/@api/prerequisite_maker/base_product_color', {
          id: base_product_color_id,
        });
        const canvas = fillColorOnCanvas(createCanvasElement(c), color_code);
        const ctx = canvas.getContext('2d');
        ctx.drawImage(c, 0, 0);
        return canvas;
      }
      return c;
    },
    (c) => {
      if (printable_file?.url) {
        return applyCanvasMask(c, printable_file?.url);
      }
      if (result_mask_url) {
        return applyCanvasMask(c, result_mask_url);
      }
      return c;
    },
    (c) => {
      if (is_90_rotate) {
        return makeRotate90(c);
      }
      return c;
    },
    (c) => {
      if (has_cutting_line) {
        const cutting_line = {
          cm_width: 3,
          color_code: '#4f4f4f',
        };
        const out_line_px = {
          width: (cutting_line.cm_width / inch1) * 96,
        };
        const ratio = Math.round(dpi / 96);
        return go(
          makeCuttinglineCanvas({
            target_canvas: c,
            color_code: cutting_line.color_code,
            width: out_line_px.width,
            ratio,
          }),
          (c) => {
            if (title) {
              const width = c.width;
              const height = out_line_px.width * ratio;
              const ctx = c.getContext('2d');
              const font_size = Math.round(height * 0.3);
              ctx.font = `${font_size}px Arial`;
              ctx.textAlign = 'center';
              ctx.fillText(title, width / 2, font_size + height * 0.2);
              return c;
            }
            return c;
          },
        );
      }
      return c;
    },
    (c) => {
      if (bg_color) {
        return makeBgColor(c, bg_color);
      }
      return c;
    },
    (c) => {
      if (NewMakerProductStyleS.isCarvedPhoneCase(base_product)) {
        const canvas = createCanvasElement({ width: c.width, height: Math.round(c.height * (3.5 / 1.5)) });
        const ctx = canvas.getContext('2d');
        ctx.drawImage(c, 0, 0);
        return canvas;
      }
      return c;
    },
    (canvas) => {
      downloadCanvas({ canvas, image_type, title, dpi });
    },
  );
};

const makePrintAreaResultByProductFace2 = async ({
  product_face,
  template_meta_dpi,
  bpf_id,
  base_product_size_id,
  color_code,
  idx = 0,
}) => {
  const size_face = await getSizeFace({ bpf_id, base_product_size_id });
  let { dpi, is_90_rotate } = size_face;
  if (template_meta_dpi) dpi = template_meta_dpi;
  if (!dpi) {
    throw Error('dpi 입력해 주세요.');
  }
  const { cm, px, result_px } = size_face.print;
  const { printable_file, items } = size_face;
  const inch1 = 2.54;
  const result_canvas = (async function () {
    if (items?.length) {
      const { cm, px, is_90_rotate, is_180_rotate, is_270_rotate } = items[idx];
      return go(
        makeDesignCanvasByCutPrintArea({
          target_area: {
            width: Math.round((cm.width / inch1) * dpi),
            height: Math.round((cm.height / inch1) * dpi),
          },
          print_area: {
            top: px.top,
            left: px.left,
            width: px.width,
          },
          product_face,
        }),
        (c) => {
          if (is_90_rotate) {
            return makeRotate90(c);
          } else if (is_180_rotate) {
            return makeRotate180(c);
          } else if (is_270_rotate) {
            return makeRotate270(c);
          } else return c;
        },
      );
    }
    if (printable_file?.url || (printable_file?.cm_width && printable_file?.cm_height)) {
      const target_area = printable_file.url
        ? await makeCanvasByUrl(printable_file.url)
        : (() => {
            const target_area = {
              width: (printable_file.cm_width / inch1) * dpi,
            };
            target_area.height = printable_file.cm_height * (target_area.width / printable_file.cm_width);
            return target_area;
          })();
      return makeDesignCanvasByCutPrintArea({
        target_area: {
          width: target_area.width,
          height: target_area.height,
        },
        print_area: printable_file,
        product_face,
      });
    }
    if (result_px && result_px.width > 0 && result_px.height > 0) {
      return makeDesignCanvasByCutPrintArea({ target_area: result_px, print_area: px, product_face });
    }
    if (!(cm.width > 0 && cm.height > 0)) {
      $.alert('cm 입력해 주세요.');
      throw Error('cm 필요');
    }
    const target_area = {
      width: (cm.width / inch1) * dpi,
    };
    target_area.height = cm.height * (target_area.width / cm.width);
    return makeDesignCanvasByCutPrintArea({
      target_area,
      print_area: px,
      product_face,
    });
  })();
  return go(
    result_canvas,
    (c) => {
      if (color_code) {
        const canvas = fillColorOnCanvas(createCanvasElement(c), color_code);
        const ctx = canvas.getContext('2d');
        ctx.drawImage(c, 0, 0);
        return canvas;
      }
      return c;
    },
    (c) => {
      if (printable_file?.url) {
        return applyCanvasMask(c, printable_file?.url);
      }
      return c;
    },
    (c) => {
      if (is_90_rotate) {
        return makeRotate90(c);
      }
      return c;
    },
  );
};

export const makeCanvasAddBlackOutLine = (c, stroke_width) => {
  const lineWidth = 2 * stroke_width;
  const canvas = createCanvasElement({ width: c.width + lineWidth * 2, height: c.height + lineWidth * 2 });
  const ctx = canvas.getContext('2d');
  ctx.lineWidth = lineWidth;
  ctx.strokeRect(
    ctx.lineWidth / 2,
    ctx.lineWidth / 2,
    canvas.width - ctx.lineWidth,
    canvas.height - ctx.lineWidth,
  );
  ctx.drawImage(c, ctx.lineWidth, ctx.lineWidth);
  return canvas;
};

export const makeRotate90 = (c) => {
  const canvas = createCanvasElement({ width: c.height, height: c.width });
  const ctx = canvas.getContext('2d');
  ctx.save();
  ctx.translate(canvas.width, 0);
  ctx.rotate(Math.PI / 2);
  ctx.drawImage(c, 0, 0);
  ctx.restore();
  return canvas;
};

export const makeRotate180 = (c) => {
  const canvas = createCanvasElement(c);
  const ctx = canvas.getContext('2d');
  ctx.save();
  ctx.translate(canvas.width, c.height);
  ctx.rotate(Math.PI);
  ctx.drawImage(c, 0, 0);
  ctx.restore();
  return canvas;
};

export const makeRotate270 = (c) => {
  return makeRotate90(makeRotate180(c));
};

export const makeFlipX = (c) => {
  const canvas = createCanvasElement(c);
  const ctx = canvas.getContext('2d');
  ctx.save();
  ctx.translate(canvas.width, 0);
  ctx.scale(-1, 1);
  ctx.drawImage(c, 0, 0);
  ctx.restore();
  return canvas;
};

export const applyCanvasMask = async (c, mask_url) => {
  const mask_canvas = await makeCanvasByUrl(mask_url);
  const canvas = makeCloneCanvas(c);
  const ctx = canvas.getContext('2d');
  ctx.globalCompositeOperation = 'destination-in';
  ctx.drawImage(mask_canvas, 0, 0, mask_canvas.width, mask_canvas.height, 0, 0, canvas.width, canvas.height);
  return canvas;
};

export const downloadTextPrintImgOnly1Face1Size = async (base_product, is_rotate90 = false) => {
  const { product_color_id } = base_product;
  $.don_loader_start();
  MuiF.openFrame(NewMakerVirtualMakerMuiF.frame, (frame, page, [tab]) => {
    tab.product_color_id = product_color_id;
    tab.rendered = async () => {
      $.don_loader_start();
      if (
        go(getBaseProductSizesInMaker(), (bps) => find((bp) => bp.is_thumb, bps), sel('id')) !==
        box().maker.product_color.base_product_size_id
      ) {
        $.alert('base_product_size_id가 일치하지 않습니다.');
        throw Error('base_product_size_id가 일치하지 않습니다.');
      }
      $.don_loader_end();
    };
    frame.closed = () => {};
    frame.closing = async (frame, data) => {
      $hide(frame);
      $.don_loader_start();
      const is_done = await isMakerDone();
      unsetMakerPage();
      const is_confirmed = await $.confirm('preview, print 이미지를 다운 받으시겠습니까?');
      if (!is_confirmed) {
        $.don_loader_end();
        return;
      }
      const base_product = box().maker.product_color._.base_product;
      const { name: base_product_color_name } = box().maker.product_color._.base_product_color;
      // const base_product_sizes = box().maker.product_color._.base_product._.base_product_sizes;
      const product_faces2 = JSON.parse(JSON.stringify(box().maker.product_color.product_faces2.value));

      if (!is_done) {
        $.alert('에러');

        $.don_loader_end();
        return;
      }
      await go(
        product_faces2,
        each((product_face) =>
          go(
            drawProductFace(
              createCanvasElement({ width: 860, height: 860 }),
              product_face,
              false,
              false,
              true,
            ),
            (canvas) =>
              downloadCanvas({
                canvas,
                image_type: 'image/jpeg',
                title: `Preview_${base_product.name}`,
              }),
          ),
        ),
      );
      const base_product_color_id = box().maker.product_color.base_product_color_id;
      const base_product_size_id = box().maker.product_color.base_product_size_id;
      await go(
        box().maker.product_color._.base_product._.base_product_sizes,
        filter((bps) => bps.id === base_product_size_id),
        each(async ({ id: base_product_size_id, name: base_product_size_name }) => {
          if (NewMakerBaseProductsTattooConstantS.ids.includes(box().maker.product_color._.base_product.id)) {
            await go(
              NewMakerBaseProductsTattooF.makePrintableFiles(
                {
                  product_faces2,
                  base_product_size_id,
                  base_product_color_id,
                  title: {
                    projection_id: '000000',
                    up_id: '111111',
                    up_c_id: '222222',
                    base_product_name: base_product.name?.trim(),
                    base_product_size_name: base_product_size_name?.trim(),
                    quantity: 1,
                    base_product_color_name: base_product_color_name?.trim(),
                  },
                },
                false,
              ),
              each(({ data_url, glue_layer_data_url, mimetype, title }) => {
                const a = document.createElement('a');
                a.href = data_url;
                a.download = `${title}.${mimetype.split('/')[1]}`;
                a.click();
                const b = document.createElement('a');
                b.href = glue_layer_data_url;
                b.download = `${title}_glue_layer.${mimetype.split('/')[1]}`;
                b.click();
              }),
            );
            return;
          }
          await downloadPrintFile({
            product_faces2,
            base_product_size_id,
            base_product_color_id,
            base_product,
            title: {
              projection_id: '000000',
              up_id: '111111',
              up_c_id: '222222',
              base_product_name: base_product.name,
              base_product_size_name,
              quantity: 1,
              base_product_color_name,
            },
          });
        }),
      );
      $.don_loader_end();
    };
  });
};

export function downloadCanvas({ canvas, image_type, title, dpi = 72 }) {
  const a = document.createElement('a');
  a.href = changeDpiDataUrl(canvas.toDataURL(image_type, 1), dpi);
  a.download = `${title}.${image_type.split('/')[1]}`;
  a.click();
}

const isBandTokPuCaseStrap = (bp) =>
  [4763, 4720, 4721, 4728, 4718, 4722, 4723, 4724, 4725, 4727, 4726, 4719, 4729, 4717].includes(bp?.id);

export const makeFileName = ({
  up_c,
  important,
  base_product,
  up_c_s,
  merged_type,
  base_product_color,
  selected_option_group,
}) => {
  if (NewMakerProductStyleS.isRemovableFabric(base_product)) {
    const preface =
      merged_type === 'unmerged'
        ? '일반_'
        : merged_type === 'parent'
        ? '마플샵_'
        : merged_type === 'sample'
        ? '마플샵_샘플_'
        : merged_type === 'fast_child'
        ? '빠른POD_'
        : '';
    return `${preface}#${up_c.projection_id}_${base_product.name}-${up_c_s._.base_product_size.name}_수량_${up_c_s.quantity}`;
  }
  if (NewMakerProductStyleS.isSlipper(base_product)) {
    return `#${up_c.projection_id}_${base_product.name}-${up_c_s._.base_product_size.name}_수량_${up_c_s.quantity}`;
  }
  if (isBandTokPuCaseStrap(base_product)) {
    return `#${up_c.projection_id}_${up_c.up_id}_${base_product.name}_${up_c_s.quantity}ea`;
  }
  return {
    projection_id: up_c.projection_id,
    up_id: up_c.up_id,
    up_c_id: up_c.id,
    imp: important ? '#긴급_' : '',
    base_product_name: base_product.name?.replace(/\s/g, ''),
    base_product_size_name: up_c_s._.base_product_size.name?.replace(/\s/g, ''),
    quantity: up_c_s.quantity,
    base_product_color_name: base_product_color.name?.replace(/\s/g, ''),
    option_names: selected_option_group?.option_names,
  };
};

function makeColoredCanvas({ img, color_code }) {
  const canvas = createCanvasElement(img);
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = color_code;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.globalCompositeOperation = 'destination-in';
  ctx.drawImage(img, 0, 0);
  return canvas;
}

function isAllNumber(arr) {
  return go(
    arr,
    every((n) => !Number.isNaN(Number(n))),
  );
}

async function makeTemplateItemCanvas(template_item_info, design_canvas, px_per_cm) {
  if (!isAllNumber(values([template_item_info.width, template_item_info.height])))
    throw new Error('template_item_info.px 값이 정상이 아닙니다.');

  const template_location = await go(null, async () => {
    if (template_item_info.top_layer_url) {
      const top_layer_img = await makeImageFromUrl(template_item_info.top_layer_url);
      return {
        width: top_layer_img.width,
        height: top_layer_img.height,
      };
    }
    return {
      width: Math.round(template_item_info.width * px_per_cm),
      height: Math.round(template_item_info.height * px_per_cm),
    };
  });
  const c = document.createElement('canvas');
  c.width = template_location.width;
  c.height = template_location.height;
  const ctx = c.getContext('2d');
  const x = Math.max((template_location.width - design_canvas.width) / 2, 0);
  const y = Math.max((template_location.height - design_canvas.height) / 2, 0);
  const width = Math.min(template_location.width, design_canvas.width);
  const height = Math.min(template_location.height, design_canvas.height);

  // if (x !== 0) console.log('x !== 0');
  // if (y !== 0) console.log('y !== 0 ');
  // if (template_location.width !== design_canvas.width)
  //   console.log('template_location.width !== design_canvas.width');
  // if (template_location.height !== design_canvas.height)
  //   console.log('template_location.height !== design_canvas.height');

  ctx.drawImage(design_canvas, 0, 0, design_canvas.width, design_canvas.height, x, y, width, height);
  if (template_item_info.mask_url) {
    const item_mask = await makeImageFromUrl(template_item_info.mask_url);
    ctx.globalCompositeOperation = 'destination-in';
    // if (item_mask.width !== design_canvas.width)
    //   console.log(`마스크와 템플릿 아이템의 크기가 맞지 않습니다.::width`);
    // if (item_mask.height !== design_canvas.height)
    //   console.log('마스크와 템플릿 아이템의 크기가 맞지 않습니다.::height');
    ctx.drawImage(item_mask, 0, 0, item_mask.width, item_mask.height, x, y, width, height);
  }
  if (template_item_info.top_layer_url) {
    const top_layer_img = await makeImageFromUrl(template_item_info.top_layer_url);
    ctx.globalCompositeOperation = 'source-over';
    ctx.drawImage(top_layer_img, 0, 0, top_layer_img.width, top_layer_img.height, 0, 0, c.width, c.height);
  }
  return c;
}

async function makeNewTemplateItemCanvas(template_item_info, design_canvas, px_per_cm) {
  if (!isAllNumber(values([template_item_info.width, template_item_info.height])))
    throw new Error('template_item_info.px 값이 정상이 아닙니다.');

  const template_location = await go(null, async () => {
    return {
      width: Math.round(template_item_info.width * px_per_cm),
      height: Math.round(template_item_info.height * px_per_cm),
    };
  });
  const c = document.createElement('canvas');
  c.width = template_location.width;
  c.height = template_location.height;
  const ctx = c.getContext('2d');

  ctx.drawImage(design_canvas, 0, 0, design_canvas.width, design_canvas.height, 0, 0, c.width, c.height);
  if (template_item_info.mask_url) {
    const item_mask = await makeImageFromUrl(template_item_info.mask_url);
    ctx.globalCompositeOperation = 'destination-in';
    ctx.drawImage(item_mask, 0, 0, item_mask.width, item_mask.height, 0, 0, c.width, c.height);
  }
  if (template_item_info.top_layer_url) {
    const top_layer_img = await makeImageFromUrl(template_item_info.top_layer_url);
    ctx.globalCompositeOperation = 'source-over';
    ctx.drawImage(top_layer_img, 0, 0, top_layer_img.width, top_layer_img.height, 0, 0, c.width, c.height);
  }
  return c;
}
export const makePrintByBpsTemplateMeta = async ({
  base_product_size_id,
  template_meta,
  product_faces2,
  base_product_color_id,
  title,
}) => {
  template_meta.line_width = template_meta.line_width || 0;
  const px_per_cm = (1 / 2.54) * template_meta.dpi;
  const template_canvas = createCanvasElement({
    width: Math.round(template_meta.size.width * px_per_cm + template_meta.line_width),
    height: Math.round(template_meta.size.height * px_per_cm + template_meta.line_width),
  });
  const ctx = template_canvas.getContext('2d');
  ctx.save();
  ctx.translate(template_meta.line_width / 2, template_meta.line_width / 2);

  const color_code = await go(undefined, async () => {
    if (template_meta.with_color) {
      const { color_code, print_color_code } = await $.get('/@api/prerequisite_maker/base_product_color', {
        id: base_product_color_id,
      });
      return print_color_code || color_code;
      // return rgb ? `rgb(${rgb[0] || 0}, ${rgb[1] || 0}, ${rgb[2] || 0}, 1)` : color_code;
    }
  });
  await go(
    template_meta.items,
    each(async (template_info) => {
      const product_face = go(
        product_faces2,
        find((pf) => pf.bpf_id === template_info.base_product_face_id),
      );
      await go(
        makePrintAreaResultByProductFace2({
          product_face,
          template_meta_dpi: template_meta.dpi,
          bpf_id: template_info.base_product_face_id,
          version: template_info.version,
          base_product_size_id,
          color_code,
          idx: template_info.idx,
        }),
        (c) => {
          if (template_info.is_horizontal_flip) {
            return makeFlipX(c);
          }
          return c;
        },
        async (c) => {
          if (template_meta.version === 'v20230902') {
            const template_item_canvas = await makeTemplateItemCanvas(template_info, c, px_per_cm);
            ctx.drawImage(
              template_item_canvas,
              Math.round(template_info.left * px_per_cm),
              Math.round(template_info.top * px_per_cm),
            );
          } else if (template_meta.version === 'v-bps-template-3') {
            const template_item_canvas = await makeNewTemplateItemCanvas(template_info, c, px_per_cm);
            ctx.drawImage(
              template_item_canvas,
              Math.round(template_info.left * px_per_cm),
              Math.round(template_info.top * px_per_cm),
            );
          } else {
            const c_ctx = c.getContext('2d');
            const template_location = {
              y: Math.round(template_info.top * px_per_cm),
              x: Math.round(template_info.left * px_per_cm),
              width: template_info.width && Math.round(template_info.width * px_per_cm),
              height: template_info.height && Math.round(template_info.height * px_per_cm),
            };
            if (template_info.print_item_url) {
              const item_mask = await makeImageFromUrl(template_info.print_item_url);
              c_ctx.globalCompositeOperation = 'destination-in';
              c_ctx.drawImage(item_mask, 0, 0, item_mask.width, item_mask.height, 0, 0, c.width, c.height);
              ctx.drawImage(
                c,
                0,
                0,
                c.width,
                c.height,
                template_location.x,
                template_location.y,
                c.width,
                c.height,
              );
            } else {
              ctx.drawImage(
                c,
                0,
                0,
                c.width,
                c.height,
                template_location.x,
                template_location.y,
                template_location.width || c.width,
                template_location.height || c.height,
              );
            }

            if (template_info.top_layer_url) {
              ctx.globalCompositeOperation = 'source-over';
              const top_layer_img = await makeImageFromUrl(template_info.top_layer_url);
              ctx.drawImage(
                top_layer_img,
                0,
                0,
                top_layer_img.width,
                top_layer_img.height,
                template_location.x,
                template_location.y,
                c.width,
                c.height,
              );
            }
          }
        },
      );
    }),
  );

  if (template_meta.black_and_white_outline_layer_url) {
    const complementary_layer_img = await makeImageFromUrl(template_meta.black_and_white_outline_layer_url);
    const clone_c = makeCloneCanvas(template_canvas);
    const clone_ctx = clone_c.getContext('2d');
    clone_ctx.globalCompositeOperation = 'destination-in';
    clone_ctx.drawImage(
      complementary_layer_img,
      0,
      0,
      complementary_layer_img.width,
      complementary_layer_img.height,
      0,
      0,
      clone_c.width,
      clone_c.height,
    );
    const color_code = evaluateImageBrightness(clone_c) < 0.3 ? '#ffffff' : '#000000';
    const colored_canvas = makeColoredCanvas({ img: complementary_layer_img, color_code });
    ctx.drawImage(
      colored_canvas,
      0,
      0,
      colored_canvas.width,
      colored_canvas.height,
      0,
      0,
      template_canvas.width,
      template_canvas.height,
    );
  }
  if (template_meta.special_outline_layer_url) {
    const complementary_layer_img = await makeImageFromUrl(template_meta.special_outline_layer_url);
    const clone_c = makeCloneCanvas(template_canvas);
    const clone_ctx = clone_c.getContext('2d');
    clone_ctx.globalCompositeOperation = 'destination-in';
    clone_ctx.drawImage(
      complementary_layer_img,
      0,
      0,
      complementary_layer_img.width,
      complementary_layer_img.height,
      0,
      0,
      clone_c.width,
      clone_c.height,
    );
    const color_code = NewMakerUtilS.makeComplementaryColorCode(clone_c);
    const colored_canvas = makeColoredCanvas({ img: complementary_layer_img, color_code });
    ctx.drawImage(
      colored_canvas,
      0,
      0,
      colored_canvas.width,
      colored_canvas.height,
      0,
      0,
      template_canvas.width,
      template_canvas.height,
    );
  }

  if (template_meta.mask_url) {
    const mask_img = await makeImageFromUrl(template_meta.mask_url);
    ctx.globalCompositeOperation = 'destination-in';
    ctx.drawImage(
      mask_img,
      0,
      0,
      mask_img.width,
      mask_img.height,
      0,
      0,
      template_canvas.width,
      template_canvas.height,
    );
    ctx.globalCompositeOperation = 'source-over';
  }

  if (template_meta.top_layer_url) {
    const top_layer_img = await makeImageFromUrl(template_meta.top_layer_url);
    if (template_meta.stroke_color?.length === 7) {
      const stroke_canvas = createCanvasElement(top_layer_img);
      const stroke_ctx = stroke_canvas.getContext('2d');
      stroke_ctx.fillStyle = template_meta.stroke_color;
      stroke_ctx.fillRect(0, 0, stroke_canvas.width, stroke_canvas.height);
      stroke_ctx.globalCompositeOperation = 'destination-in';
      stroke_ctx.drawImage(top_layer_img, 0, 0);
      ctx.globalCompositeOperation = 'source-over';
      ctx.drawImage(
        stroke_canvas,
        0,
        0,
        stroke_canvas.width,
        stroke_canvas.height,
        0,
        0,
        template_canvas.width,
        template_canvas.height,
      );
    } else {
      ctx.globalCompositeOperation = 'source-over';
      ctx.drawImage(
        top_layer_img,
        0,
        0,
        top_layer_img.width,
        top_layer_img.height,
        0,
        0,
        template_canvas.width,
        template_canvas.height,
      );
    }
  }

  ctx.restore();
  return go(
    undefined,
    () => {
      if (template_meta.line_width > 0) {
        ctx.strokeStyle = template_meta.stroke_color;
        ctx.lineWidth = template_meta.line_width;
        ctx.strokeRect(0, 0, template_canvas.width, template_canvas.height);
      }
      return template_canvas;
    },
    (c) => {
      if (template_meta.is_flip_x) {
        return makeFlipX(c);
      }
      return c;
    },
    (c) => {
      if (template_meta.text?.info_arr_in_image?.length && title) {
        const { height, top, left, info_arr_in_image, is_separate } = template_meta.text;
        const little_margin = 0.2 * px_per_cm;
        const fontFamily = 'Arial';
        if (!Number.isNaN(parseInt(top)) && !Number.isNaN(parseInt(left)) && (top >= 0 || left >= 0)) {
          const ctx = c.getContext('2d');
          const px_top = top * px_per_cm;
          const px_left = left ? left * px_per_cm : c.width / 2;
          const font_px = height * px_per_cm;
          ctx.font = `${font_px}px ${fontFamily}`;
          ctx.textBaseline = 'top';
          ctx.fillStyle = '#000000';
          go(
            info_arr_in_image,
            map((image_key) => makeTitleByKeys(title, image_key)),
            ippL,
            each(([idx, title]) => {
              if (template_meta.text.is_flip_x) {
                ctx.save();
                ctx.scale(-1, 1);
                ctx.fillText(
                  title,
                  -(c.width - px_left),
                  px_top + idx * (font_px + little_margin),
                  c.width - px_left,
                );
                ctx.restore();
              } else {
                ctx.fillText(title, px_left, px_top + idx * (font_px + little_margin), c.width - px_left);
              }
            }),
          );
          return [{ canvas: template_canvas }];
        } else {
          if (is_separate) {
            const font_px = height * px_per_cm;
            const c_with_text = createCanvasElement({
              width: c.width,
              height: font_px * info_arr_in_image.length + (little_margin * info_arr_in_image.length - 1),
            });
            const c_with_text_ctx = c_with_text.getContext('2d');
            c_with_text_ctx.font = `${font_px}px ${fontFamily}`;
            c_with_text_ctx.textBaseline = 'top';
            c_with_text_ctx.fillStyle = '#000000';
            go(
              info_arr_in_image,
              map((image_key) => makeTitleByKeys(title, image_key)),
              ippL,
              each(([idx, title]) => {
                c_with_text_ctx.fillText(title, 0, idx * (font_px + little_margin), c_with_text.width);
              }),
            );

            return [{ canvas: c }, { canvas: makeTrimCanvas(c_with_text), plus_title: 'TEXT_' }];
          } else {
            const font_px = height * px_per_cm;
            const c_with_text = createCanvasElement({
              width: c.width,
              height:
                c.height +
                font_px * info_arr_in_image.length +
                (little_margin * info_arr_in_image.length - 1),
            });
            const c_with_text_ctx = c_with_text.getContext('2d');
            c_with_text_ctx.drawImage(c, 0, 0);
            c_with_text_ctx.font = `${font_px}px ${fontFamily}`;
            c_with_text_ctx.textBaseline = 'top';
            c_with_text_ctx.fillStyle = '#000000';

            go(
              info_arr_in_image,
              map((image_key) => makeTitleByKeys(title, image_key)),
              ippL,
              each(([idx, title]) => {
                if (template_meta.text.is_flip_x) {
                  c_with_text_ctx.save();
                  c_with_text_ctx.scale(-1, 1);
                  c_with_text_ctx.fillText(
                    title,
                    -c.width,
                    c.height + little_margin + idx * (font_px + little_margin),
                    c.width,
                  );
                  c_with_text_ctx.restore();
                } else {
                  c_with_text_ctx.fillText(
                    title,
                    0,
                    c.height + little_margin + idx * (font_px + little_margin),
                    c.width,
                  );
                }
              }),
            );
            return [{ canvas: c_with_text }];
          }
        }
      } else {
        return [{ canvas: c }];
      }
    },
    async (arr) => {
      const is_carved = title?.base_product_name.includes('각인');
      if (is_carved || template_meta.is_black_content) {
        const canvas = arr[0].canvas;
        const carved_canvas = await createCanvasElement({ width: canvas.width, height: canvas.height });
        const ctx = carved_canvas.getContext('2d');
        ctx.fillStyle = '#000000';
        ctx.fillRect(0, 0, carved_canvas.width, carved_canvas.height);
        ctx.globalCompositeOperation = 'destination-in';
        ctx.drawImage(canvas, 0, 0);
        arr[0].canvas = carved_canvas;
      }
      if (template_meta.need_trim) {
        arr[0].canvas = makeTrimCanvas(arr[0].canvas);
      }
      return arr;
    },
    (arr) => {
      if (template_meta.bg_color || template_meta.mimetype === 'image/jpeg') {
        const canvas = createCanvasElement(arr[0].canvas);
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = template_meta.bg_color || '#ffffff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(arr[0].canvas, 0, 0);
        arr[0].canvas = canvas;
        return arr;
      }
      return arr;
    },
    (arr) => {
      if ([11625, 11624].includes(base_product_size_id)) {
        const prev_canvas = arr[0].canvas;
        const prev_ctx = prev_canvas.getContext('2d');
        const prev_left_image_data = prev_ctx.getImageData(0, 0, prev_canvas.width / 2, prev_canvas.height);
        const prev_right_image_data = prev_ctx.getImageData(
          prev_canvas.width / 2,
          0,
          prev_canvas.width,
          prev_canvas.height,
        );
        const canvas = createCanvasElement({ width: 2164, height: 1418 });
        const ctx = canvas.getContext('2d');
        ctx.putImageData(prev_left_image_data, 0, 0);
        ctx.putImageData(prev_right_image_data, canvas.width - prev_canvas.width / 2, 0);
        arr[0].canvas = canvas;
        return arr;
      }
      return arr;
    },
    map((v) => {
      return {
        ...v,
        template_meta,
      };
    }),
  );
};

// const template_meta = {
//   size: {
//     width: 22,
//     height: 9.6,
//   },
//   dpi: 300,
//   mimetype: 'image/png',
//   bg_color: null,
//   line_width: 2,
//   stroke_color: '#ff0000',
//   text: {
//     height: 0.3,
//     x: 0.37,
//     y: 0.3,
//     fontFamily: 'Arial',
//     textBaseline: 'top',
//   },
//   items: [
//     {
//       base_product_face_id: 8637,
//       top: 0.8,
//       left: 0.6,
//     },
//   ],
// };

export async function downloadTemplatedPrint({
  title,
  base_product_size_id,
  base_product_color_id,
  product_faces2,
  template_meta,
  all_faces_single_color,
}) {
  await go(
    Array.isArray(template_meta) ? template_meta : [template_meta],
    each(async (template_meta) => {
      if (template_meta.text_only && template_meta.text.copy_text) {
        copyModal(makeTitleByKeys(title, template_meta.text.copy_text));
        return;
      }
      await go(
        makePrintByBpsTemplateMeta({
          template_meta,
          base_product_size_id,
          base_product_color_id,
          product_faces2,
          title,
        }),
        each(async ({ canvas, plus_title }) => {
          const file_name = `${plus_title || ''}${
            typeof title === 'string' ? title : makeTitleByKeys(title, template_meta.text.file_name)
          }${all_faces_single_color?.name ? `_${all_faces_single_color.name}` : ''}.${
            template_meta.mimetype.split('/')[1]
          }`;
          if (template_meta.mimetype === 'image/tiff') {
            saveAs(await makeTiffBlob(canvas, template_meta.dpi), file_name);
          } else {
            const a = document.createElement('a');
            a.href = changeDpiDataUrl(canvas.toDataURL(template_meta.mimetype, 1), template_meta.dpi);
            a.download = file_name;
            a.click();
          }
        }),
        () => {
          if (template_meta.text.copy_text) {
            copyModal(makeTitleByKeys(title, template_meta.text.copy_text));
          }
          if (template_meta.after_downloaded_file_url) {
            $.downloadUrl(
              template_meta.after_downloaded_file_url,
              `${makeTitleByKeys(title, template_meta.text.file_name)}.${
                template_meta.after_downloaded_file_url.split('original/')[1].split('.')[1]
              }`,
            );
          }
        },
      );
    }),
  );
}

const option_group_regex = /#op_(.*?)_#op/; // 정규식 패턴
function getOptionName(option_group_str) {
  const match = option_group_regex.exec(option_group_str); // 정규식에 매칭되는 부분 추출

  if (match) {
    const extractedValue = match[1]; // 첫 번째 캡처 그룹의 값 (괄호 안의 부분)
    return extractedValue;
  } else {
    console.log('일치하는 값이 없습니다.');
  }
}
export const makeTitleByKeys = (title_obj, title_str) => {
  return go(title_obj, entries, (arr) =>
    reduce(
      (mem, [k, v]) => {
        if (k === 'option_names') {
          const option_group_name = getOptionName(mem);

          if (!option_group_name) return mem;

          const option_name = go(
            v,
            find((on) => on.option_group_name === option_group_name),
          );

          return mem.replace(option_group_regex, option_name?.option_name || '');
        } else {
          return mem.replace(new RegExp(k), v);
        }
      },
      title_str,
      arr,
    ),
  );
  // return go(
  //   keys,
  //   strMap((k) => {
  //     return title_obj[k] || k;
  //   }),
  // );
};

export async function downloadPrintFile({
  product_faces2,
  base_product_size_id,
  base_product_color_id,
  base_product,
  title,
  all_faces_single_color,
}) {
  const { template_meta } = await $.get('/@api/prerequisite_maker/base_product_size', {
    id: base_product_size_id,
  });
  if (Array.isArray(template_meta)) {
    await downloadTemplatedPrint({
      title,
      base_product_size_id,
      product_faces2,
      base_product_color_id,
      template_meta,
      all_faces_single_color,
    });
    return;
  }

  if (template_meta?.items?.length) {
    await downloadTemplatedPrint({
      title,
      base_product_size_id,
      product_faces2,
      base_product_color_id,
      template_meta,
      all_faces_single_color,
    });
  } else {
    await makePrintAreaResultByProductFace({
      product_face: product_faces2[0],
      base_product_color_id,
      base_product_size_id,
      base_product,
      title: typeof title === 'string' ? title : title,
    });
  }
}

export function copyModal(text) {
  if ($qs('.modal_copy_and_paste_make_px_to_result_px')) return;
  const copy_modal_style = `
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `;
  const wrapper_style = `
    padding: 16px;
    background: #fff;
    border: 1px solid #484848;
    border-radius: 10px;
    font-size: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;
  const body_style = `
    text-align: center;
    margin-bottom: 16px;
  `;
  const option_style = `
    display: flex;
    justify-content: center;
  `;
  const button_style = `
    border-color: #000;
    background-color: #000;
    color: #fff;
  `;
  return go(
    legacyHtml`
      <div class="modal_copy_and_paste_make_px_to_result_px" style="${copy_modal_style}" tabindex="-1">
        <div style="${wrapper_style}">
          <div style="${body_style}">
            <div class="text-target">${text}</div>
          </div>
          <div style="${option_style}">
            <button type="button" class="copy-event" style="${button_style} margin-right: 16px;">복사</button>
            <button type="button" class="close-event" style="${button_style}">닫기</button>
          </div>
        </div>
      </div>
    `,
    $el,
    $appendTo($qs('body')),
    pipe(
      $delegate('click', '.copy-event', (e) => {
        go(e.delegateTarget, $find('.text-target'), $text, copyText);
      }),
      $delegate('click', '.close-event', (e) => {
        $remove(e.delegateTarget);
      }),
      $on('keydown', (e) => {
        if (e.key === 'Escape') $remove(e.currentTarget);
      }),
      (el) => el.focus(),
    ),
  );
}

G.jip__makePngForWeProductByWeTechPackPdfJson = async (we_tech_pack_pdf_json, width) => {
  await go(
    we_tech_pack_pdf_json,
    each(async (pdf) => {
      const up_ids = go(pdf.products, map(sel('id')));
      await makePngForWeProduct(up_ids, width);
    }),
  );
};
G.jip__makePngForWeProduct = async (up_ids, width) => {
  await makePngForWeProduct(up_ids, width);
};
function makeManyCanvas(length, func) {
  return go(
    range(length),
    map(() => func()),
  );
}

G.jip__exportMskingTapePatternSrcs = async ({ payload }) => {
  await go(
    payload,
    pluck('id'),
    each(async (up_id) => {
      const { product_id, product_faces2, px_height, print_px } = await $.get(
        '/@api/masking_tape/material_for_pattern',
        {
          up_id,
        },
      );
      const pattern_url = await go(product_faces2.value[0], async (pf) => {
        const canvass = await makeManyCanvas(3, () =>
          NewMakerPrintResultF.makePrintCanvasFromPrintPx({ pf, px_height, print_px }),
        );
        const is_same_size = isSameSize(canvass);
        if (!is_same_size) throw new Error(`NOT SAME SIZE::${up_id}`);
        const is_same_canvas = isSameCanvas(canvass, 50);
        if (!is_same_canvas) {
          throw new Error(`NOT SAME CANVAS::${up_id}`);
        }
        const canvas = canvass[0];
        return go(
          canvas,
          (canvas) => {
            const blobBin = atob(canvas.toDataURL('image/png', 1).split(',')[1]);
            const array = [];
            for (let i = 0; i < blobBin.length; i++) {
              array.push(blobBin.charCodeAt(i));
            }
            const file = new Blob([new Uint8Array(array)], { type: `image/png` });
            const formData = new FormData();
            formData.append('files', file, `pattern_src.png`);
            return $.upload(formData, {
              url: '/@fileUpload/masking_tape/upload_pattern_img',
              data: { up_id, pattern_length: BpOptionMaskingTapeConstantS.max_pattern_length },
            });
          },
          sel('url'),
        );
      });
      product_faces2.value[0].we_print_url = pattern_url;
      console.log(pattern_url);
      await $.post('/@api/prerequisite_maker/abc', { product_color_id: product_id, product_faces2 });
    }),
  );
  console.log('end');
};

G.jip__exportMskingTapePatternSrcs2 = async (up_id) => {
  const { product_faces2, px_height, print_px } = await $.get('/@api/masking_tape/material_for_pattern', {
    up_id,
  });
  await go(product_faces2.value[0], (pf) =>
    go(
      NewMakerPrintResultF.makePrintCanvasFromPrintPx({ pf, px_height, print_px }),
      (canvas) => {
        const blobBin = atob(canvas.toDataURL('image/png', 1).split(',')[1]);
        const array = [];
        for (let i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        const file = new Blob([new Uint8Array(array)], { type: `image/png` });
        const formData = new FormData();
        formData.append('files', file, `pattern_src.png`);
        return $.upload(formData, {
          url: '/@fileUpload/masking_tape/upload_pattern_img',
          data: {
            is_cmyk_convert: true,
            up_id,
            pattern_length: BpOptionMaskingTapeConstantS.max_pattern_length,
          },
        });
      },
      sel('url'),
      _p.hi,
    ),
  );
};

/*
 * base_product: name, id
 * upc: id, projection_id, up_id,
 * base_product_color: name, id,
 * up_c_ss: up_c_s.quantity, base_product_size_id
 * */
export const makeUpcsCanvasBlob = async ({
  up_c,
  important,
  base_product,
  product_faces2,
  base_product_color,
  up_c_ss,
  task_id,
  merged_type,
}) => {
  return go(
    up_c_ss,
    flatMap(async (up_c_s) => {
      const { template_meta } = await $.get('/@api/prerequisite_maker/base_product_size', {
        id: up_c_s.base_product_size_id,
      });
      const title = makeFileName({
        important,
        up_c,
        base_product,
        up_c_s,
        merged_type,
        base_product_color,
      });
      return go(
        Array.isArray(template_meta)
          ? flatMap(
              (template_meta) =>
                makePrintByBpsTemplateMeta({
                  template_meta,
                  base_product_size_id: up_c_s.base_product_size_id,
                  base_product_color_id: base_product_color.id,
                  product_faces2,
                  title,
                }),
              template_meta,
            )
          : makePrintByBpsTemplateMeta({
              template_meta,
              base_product_size_id: up_c_s.base_product_size_id,
              base_product_color_id: base_product_color.id,
              product_faces2,
              title,
            }),
        map(async ({ canvas, plus_title, template_meta }) => {
          const file_name = `${plus_title || ''}${
            typeof title === 'string' ? title : makeTitleByKeys(title, template_meta.text.file_name)
          }.${template_meta.mimetype.split('/')[1]}`;
          const canvas_blob =
            template_meta.mimetype === 'image/tiff'
              ? await makeTiffBlob(canvas, template_meta.dpi)
              : await new Promise((resolve) => {
                  canvas.toBlob(
                    (blob) => {
                      resolve(changeDpiBlob(blob, template_meta.dpi));
                    },
                    template_meta.mimetype,
                    1,
                  );
                });
          return { canvas_blob, file_name, task_id, up_c_s_id: up_c_s.id };
        }),
      );
    }),
  );
};
