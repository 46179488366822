import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from './module/OMPCoreAtomTmplS.js';
export const toggle = ({ id, active } = {}) => {
  return html`
    <label for="${id}" class="omp-atom__toggle">
      <input type="checkbox" ${active ? 'checked' : ''} id="${id}" />
      <span class="omp-atom__toggle-background">
        <span class="omp-atom__toggle-switch"></span>
      </span>
    </label>
  `;
};

export const checkbox = ({ name, active, title, class_name } = {}) => {
  return html`
    <label class="omp-atom__checkbox ${class_name || ''}">
      <input type="checkbox" ${active ? 'checked' : ''} name="${name}" />
      <span class="omp-atom__checkbox-box"></span>
      <span class="omp-atom__checkbox-title">${title}</span>
    </label>
  `;
};

export const emptyCheckbox = (
  { name, active, title, style: { size = 'normal' } = { size: 'normal' } } = { style: {} },
) => {
  return html`
    <label class="omp-atom__empty-checkbox">
      <input type="checkbox" ${active ? 'checked' : ''} name="${name || ''}" />
      <span class="omp-atom__empty-checkbox-box-wrapper" data-style_size="${size}">
        ${OMPCoreAtomTmplS.checkboxIcon({ klass: 'omp-atom__empty-checkbox-box' })}
      </span>
      <span class="omp-atom__empty-checkbox-title">${title}</span>
    </label>
  `;
};
