import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';

export const cancelStickerEditor = async () => {
  const is_close = await $.confirm(
    T('modules::VectorEditor::Sticker::message::디자인이 모두 삭제됩니다. 에디터를 종료하시겠습니까?'),
  );
  if (!is_close) {
    return;
  }
  MuiF.closeFrame(null);
};
