import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorKeyringGridMobilePathEditorMuiF } from '../Mui/module/VectorEditorKeyringGridMobilePathEditorMuiF.js';
import { go, tap } from 'fxjs/es';

export const editPath = ({ keyring_editor, path_el }) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorKeyringGridMobilePathEditorMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = (frame_el, val) =>
        go(
          frame_closed.call(frame, frame_el, val),
          tap(() => resolve(val)),
        );

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_keyring_editor = keyring_editor;
        tab_el.__mp_path_el = path_el;
        return tab_appending.call(tab, tab_el);
      };
    }).catch(reject),
  );
