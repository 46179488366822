import { $find } from 'fxdom/es';
import { VectorEditorStickerGridPCCopyGridF } from '../../CopyGrid/F/Function/module/VectorEditorStickerGridPCCopyGridF.js';

export const handleTopMenuDeleteClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_selected_grid_id == null) {
      return;
    }
    const grid_id = tab_el.__mp_selected_grid_id;

    tab_el.__mp_grid_sticker_editor?.unsetTargetImage?.(grid_id);
  };

export const handleTopMenuDuplicateClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_grid_sticker_editor == null) {
      return;
    }
    const grid_sticker_editor = tab_el.__mp_grid_sticker_editor;

    if (tab_el.__mp_frame_position_top == null) {
      return;
    }
    const frame_position_top = tab_el.__mp_frame_position_top;

    if (tab_el.__mp_frame_position_height == null) {
      return;
    }
    const frame_position_height = tab_el.__mp_frame_position_height;

    const right_wrapper_el = $find(`.right_container .right_wrapper`)(tab_el);
    if (right_wrapper_el == null) {
      return;
    }

    const frame_position_width = right_wrapper_el.getBoundingClientRect?.()?.width;
    if (frame_position_width == null) {
      return;
    }

    return VectorEditorStickerGridPCCopyGridF.copyGrid({
      frame_position:
        G.collabo_type === ''
          ? $find('.right_container')(tab_el).getBoundingClientRect?.()
          : { top: frame_position_top, width: frame_position_width, height: frame_position_height },
      prev_frame_right_panel: right_wrapper_el,
      grid_sticker_editor,
      grids: tab_el.__mp_grids,
    });
  };
