import {
  getCurrentFcanvas,
  getCvObj,
  getNotDesigns,
  makeCvObjsVisibleFalse,
  makeCvObjsVisibleTrue,
} from '../../../../Maker/F/Fcanvas/cv_object.js';
import { go, reject } from 'fxjs/es';
import { createCanvasElement, makeCanvasCutByLocation } from '../../../../Canvas/S/util.js';
import { NewMakerUtilS } from '../../../Util/S/Function/module/NewMakerUtilS.js';

export async function makeMaskImage(mask_target_str, target_mask, is_opacity, mask2_target_no_opacity) {
  const fcanvas = getCurrentFcanvas();
  const cv_mask1 = getCvObj(fcanvas._objects, 'cv_mask1');
  const mask_target = getCvObj(fcanvas._objects, mask_target_str);
  const bpf_id = fcanvas.bpf_id;
  const bool = confirm('확실 하십니까?');
  if (!bool) return;
  G.mp.maker.unselect_all();
  const { top, left, width, height } = go(cv_mask1, ({ top, left, width, height, scaleX, scaleY }) => {
    return {
      top,
      left,
      width: width * scaleX,
      height: height * scaleY,
    };
  });
  const objs = go(
    getNotDesigns(fcanvas._objects),
    reject((cv_obj) => cv_obj === mask_target),
  );
  makeCvObjsVisibleFalse(objs);
  mask_target.opacity = 1;
  mask_target.globalCompositeOperation = 'source-over';
  mask_target.visible = true;
  ``;
  fcanvas.renderAll();
  const canvas = makeCanvasCutByLocation(fcanvas.lowerCanvasEl, { top, left, width, height });
  const resize_canvas = go(canvas, (c) => {
    if (is_opacity) {
      return go(NewMakerUtilS.makeTransparentCanvas(canvas, 100), (c) => {
        // const ctx = c.getContext('2d');
        const cv_bpcf = getCvObj(fcanvas._objects, mask2_target_no_opacity);
        const new_c = createCanvasElement(cv_bpcf._element);
        const new_ctx = new_c.getContext('2d');
        new_ctx.drawImage(c, 0, 0, c.width, c.height, 0, 0, new_c.width, new_c.height);
        new_ctx.drawImage(
          cv_bpcf._element,
          0,
          0,
          cv_bpcf._element.width,
          cv_bpcf._element.height,
          0,
          0,
          new_c.width,
          new_c.height,
        );
        return new_c;
      });
    }
    return c;
  });
  const { url } = await $.uploadFileToUrl(resize_canvas.toDataURL('image/png', 1), 'mask_url', 'PNG');
  const values = {};
  values[target_mask] = url;
  await $.post('/@api/base_product_color_faces/update', {
    values,
    condition: { base_product_face_id: bpf_id },
  });
  makeCvObjsVisibleTrue(objs);
}

export function ratioCalcHeight({ width, height }, _width) {
  return Math.round((height * _width) / width);
}

export function ratioCalcWidth({ width, height }, _height) {
  return Math.round((width * _height) / height);
}
