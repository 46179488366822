import axios from 'axios';
import {
  $attr,
  $closest,
  $data,
  $delegate,
  $find,
  $findAll,
  $hasClass,
  $setOuterHTML,
  $text,
} from 'fxdom/es';
import { every, filter, sel, go, map, some, keys } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { DfInhouseF } from '../../../../Inhouse/F/Function/module/DfInhouseF.js';
import { alertTrackingResult, parseTrackingReturnErrorMsg } from '../../../../Projection/List/F/event.js';
import { alertsLgl } from '../../../F/Function/alerts.js';
import { apiCalls } from '../../../F/Function/api.calls.js';
import { LGL_SHIPPING_INFO } from '../../../F/Function/waybill.format.js';
import { DfLglInspectionDetailMuiF } from '../../../InspectionDetail/F/Mui/module/DfLglInspectionDetailMuiF.js';
import { DfLglOutboundDetailMuiF } from '../../../OutboundDetail/F/Mui/module/DfLglOutboundDetailMuiF.js';
import { DfLglOutboundProductListMuiF } from '../../../OutboundProductList/F/Mui/module/DfLglOutboundProductListMuiF.js';
import { DfLglReturnDetailMuiF } from '../../../ReturnDetail/F/Mui/module/DfLglReturnDetailMuiF.js';
import { MESSAGES } from '../../../S/Constant/lgl.js';
import {
  makeInboundTabActionButtonHtml,
  makeInboundTabFooterHtml,
  makePackagingTabFooterHtml,
  makeReboundTabActionButtonHtml,
} from '../../S/Tmpl/tmpl.tabs.js';
import { alertInbound, alertOutbound } from './alert.js';
import { getInboundTabFooterMessage } from './inbound.js';
import {
  renderAllTab,
  renderInboundTab,
  renderOutboundTab,
  renderPackagingTab,
  renderReboundTab,
  renderReturnTab,
  renderTrackingTab,
} from './render.js';
import { getPackagingFooterStatus } from './status.js';
import { handleTransferToolDisabled, unCheckedAllCheckboxes } from './table.event.js';

export async function handleCompletePackaging({ currentTarget: ct }) {
  try {
    DfInhouseF.lottie_loader.start();
    const tab = go(ct, $closest('.tab_wrapper'));
    const projection = $data(tab);
    const { id: projection_id } = projection;

    // 빈 운송장 체크
    const inbound_order_el = go(tab, $find('.table_right'));
    const tables = go(inbound_order_el, $findAll('.table'));
    const exist_empty_waybill = go(
      tables,
      map((table) => $data(table)),
      some((data) => go(data, keys).length === 0),
    );

    if (exist_empty_waybill) {
      await alertInbound.waybill.emptyWaybillAlert();
    } else {
      await apiCalls.put.inbound.changeOrderPackStatus({
        pack_status: 'packed',
        projection_id,
      });

      await renderAllTab({ projection_id });
    }
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleEditPackaging({ currentTarget: ct }) {
  try {
    DfInhouseF.lottie_loader.start();
    const tab = go(ct, $closest('.tab_wrapper'));
    const projection = $data(tab);
    const { id: projection_id } = projection;

    // 운송장 상태 체크
    // PACKED 상태인 경우에만 재포장 가능
    const inbound_order_el = go(tab, $find('.tab_content.selected'));
    const inbound_order = $data(inbound_order_el);

    const packed = inbound_order.current_status === 'PACKED';

    if (packed) {
      await apiCalls.put.inbound.changeOrderPackStatus({
        pack_status: 'packing',
        projection_id,
      });

      await renderAllTab({ projection_id });
    }
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleRequestInbound({ currentTarget: ct }) {
  try {
    await alertInbound.inbound.requestConfirm({
      confirmCallback: async () => {
        try {
          DfInhouseF.lottie_loader.start('dots', null, null, null);
          const tab_wrapper = go(ct, $closest('.tab_wrapper'));
          const tab = go(tab_wrapper, $find('.tab_content.selected'));
          const { projection_id } = $data(tab);

          await apiCalls.post.inbound.requestOrder({ projection_id });

          await renderAllTab({ projection_id });
        } catch (err) {
          console.error(err);
          await alertsLgl.error({ err });
        } finally {
          DfInhouseF.lottie_loader.end();
        }
      },
    });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleCancelRequestInbound({ currentTarget: ct }) {
  try {
    await alertInbound.inbound.cancelConfirm({
      confirmCallback: async () => {
        try {
          DfInhouseF.lottie_loader.start('dots', null, null, null);
          const tab_wrapper = go(ct, $closest('.tab_wrapper'));
          const tab = go(tab_wrapper, $find('.tab_content.selected'));
          const { projection_id } = $data(tab);

          await apiCalls.post.inbound.cancelRequestOrder({ projection_id });

          await renderAllTab({ projection_id });
        } catch (err) {
          console.error(err);
          await alertsLgl.error({ err });
        } finally {
          DfInhouseF.lottie_loader.end();
        }
      },
    });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}
export async function handleChangeInboundTab({ currentTarget: ct }) {
  const tab_wrapper = go(ct, $closest('.tab_wrapper'));
  const tabs = go(tab_wrapper, $findAll('.tab_item'));
  const selected_tab_item = go(tab_wrapper, $find('.tab_item.selected'));
  const tab_title = go(selected_tab_item, $find('.title'), $text);
  const selected_tab = go(tab_wrapper, $find('.tab_content.selected'));
  const footer = go(tab_wrapper, $find('.footer'));
  const inbound_order = $data(selected_tab);
  const inbound_order_ids = go(
    tabs,
    map((tab) => tab.dataset.id),
  );
  const { current_status, is_rebound: rebound } = inbound_order;
  const return_in_progress = false;
  const return_completed = false;
  const message = getInboundTabFooterMessage({
    current_status,
    return_in_progress,
    return_completed,
    rebound,
  });
  const waybill_index = inbound_order_ids.findIndex((id) => parseInt(id) === parseInt(inbound_order.id));
  const action_button_template = makeInboundTabActionButtonHtml({
    status: current_status,
    waybill_index,
    rebound,
    tab_title,
  });
  const class_name = !rebound && current_status === 'PACKED' ? 'primary' : '';
  go(footer, $setOuterHTML(makeInboundTabFooterHtml({ message, action_button_template, class_name })));

  unCheckedAllCheckboxes({ el: tab_wrapper });
}

export async function handleChangePackagingTab({ currentTarget: ct }) {
  const tab_wrapper = go(ct, $closest('.tab_wrapper'));

  const table_left = go(tab_wrapper, $find('.table_left'));
  const table_right = go(tab_wrapper, $find('.table_right'));
  unCheckedAllCheckboxes({ el: table_right });

  const checked =
    go(table_right, $findAll('input[type="checkbox"]:checked')).length === 0 &&
    go(table_left, $findAll('input[type="checkbox"]:checked')).length > 0;

  const table_el = checked ? go(table_left, $find('table')) : go(table_right, $find('table'));

  handleTransferToolDisabled({ table_el, checked });
}

export async function handleChangeReboundTab({ currentTarget: ct }) {
  await handleChangePackagingTab({ currentTarget: ct });
  const tab_wrapper = go(ct, $closest('.tab_wrapper'));
  const left_products = $data(go(tab_wrapper, $find('.queue_table .table')));
  const rebound_orders = $data(tab_wrapper);
  const tabs = go(tab_wrapper, $findAll('.tab_item'));
  const selected_tab = go(tab_wrapper, $find('.tab_content.selected'));
  const packaging_products = $data(go(selected_tab, $find('.table')));
  const footer = go(tab_wrapper, $find('.footer'));
  const current_rebound_order = $data(selected_tab);

  const { current_status } = current_rebound_order;
  const packing = current_status === 'PACKING';
  const after_requested = !['PACKING', 'PACKED'].includes(current_status);

  let footer_status = 'EMPTY_WAYBILL';

  const empty_left_products = go(left_products, keys).length === 0;
  const none_empty_waybill = go(packaging_products, keys).length > 0;

  if (packing) {
    footer_status = getPackagingFooterStatus({ empty_left_products, none_empty_waybill });
  } else {
    footer_status = 'PACKED';
  }

  const waybill_index = [...tabs].findIndex($hasClass('selected'));
  const waybill_count = rebound_orders.length;

  const action_button_template = makeReboundTabActionButtonHtml({
    empty_left_products,
    packing,
    after_requested,
    waybill_index,
    waybill_count,
  });
  go(
    footer,
    $setOuterHTML(
      makePackagingTabFooterHtml({ message: MESSAGES.PACKAGING[footer_status], action_button_template }),
    ),
  );
}

export async function handleTrackingInbound({ currentTarget: ct }) {
  // LGL-BUWHAL
  const tab_wrapper = go(ct, $closest('.tab_wrapper'));
  const selected_tab = go(tab_wrapper, $find('.tab_content.selected'));
  const inbound_order = $data(selected_tab);
  const { waybill_no_domestic: waybill_number } = inbound_order;

  //택배 추적 api 호출
  let error = true;
  let error_msg = '';
  let tracking_data;
  try {
    DfInhouseF.lottie_loader.start('dots');

    const returning_data = go(
      await axios({
        method: 'get',
        url: '/@api/waybill/cj/req_parcel_req_parcel_tracking',
        params: { INVC_NO: waybill_number },
      }),
      sel('data'),
    );
    switch (returning_data.RESULT_CD) {
      case 'S': {
        error = false;
        tracking_data = returning_data.DATA;
        break;
      }
      case 'E': {
        error_msg = parseTrackingReturnErrorMsg(returning_data.RESULT_DETAIL);
        break;
      }
    }
  } catch (e) {
    error = true;
    error_msg = '대한통운 서버 요청 에러 (개발자 문의)';
    console.error(e.response.data);
    await alertsLgl.error(`에러: ${e.response.data}`);
  }
  DfInhouseF.lottie_loader.end();
  const { name, mobile1 } = LGL_SHIPPING_INFO;
  await alertTrackingResult(
    error,
    error_msg,
    waybill_number,
    { name, waybill_number, mobile1 },
    tracking_data,
  );
}

export function handleTabRendering({ el }) {
  go(
    el,
    $delegate('click', '.header .tab_buttons .tab_button', async ({ currentTarget }) => {
      const { projection_id } = el.frame_opt;
      const tab_name = go(currentTarget, $attr('tab_name'));

      if (tab_name === 'packaging') {
        await renderPackagingTab({ projection_id });
      } else if (tab_name === 'inbound') {
        await renderInboundTab({ projection_id });
      } else if (tab_name === 'rebound') {
        await renderReboundTab({ projection_id });
      } else if (tab_name === 'return') {
        await renderReturnTab({ projection_id });
      } else if (tab_name === 'outbound') {
        await renderOutboundTab({ projection_id });
      } else if (tab_name === 'tracking') {
        await renderTrackingTab({ projection_id });
      }
    }),
  );
}

export async function handleCompleteReboundWaybill({ currentTarget: ct }) {
  try {
    DfInhouseF.lottie_loader.start();
    const tab = go(ct, $closest('.tab_wrapper'));
    const selected_tab = go(tab, $find('.table_right .tab_content.selected'));
    const inbound_order = $data(selected_tab);

    const { id: rebound_lgl_inbound_order_id, projection_id } = inbound_order;

    // 빈 운송장 체크
    const table = go(selected_tab, $find('.table'));
    const empty = go($data(table), keys).length === 0;

    if (empty) {
      await alertInbound.waybill.emptyWaybillAlert();
    } else {
      await apiCalls.put.rebound.changeOrderPackStatus({
        pack_status: 'packed',
        rebound_lgl_inbound_order_id,
      });

      await renderReboundTab({ projection_id });
    }
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleEditRebounWaybill({ currentTarget: ct }) {
  try {
    DfInhouseF.lottie_loader.start();
    const tab = go(ct, $closest('.tab_wrapper'));

    // 운송장 상태 체크
    // PACKED 상태인 경우에만 재포장 가능
    const inbound_order_el = go(tab, $find('.tab_content.selected'));
    const inbound_order = $data(inbound_order_el);
    const { id: rebound_lgl_inbound_order_id, projection_id } = inbound_order;

    const packed = inbound_order.current_status === 'PACKED';

    if (packed) {
      await apiCalls.put.rebound.changeOrderPackStatus({
        pack_status: 'packing',
        rebound_lgl_inbound_order_id,
      });

      await renderReboundTab({ projection_id });
    }
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleRequestRebound({ currentTarget: ct }) {
  try {
    await alertInbound.inbound.requestConfirm({
      confirmCallback: async () => {
        try {
          DfInhouseF.lottie_loader.start('dots', null, null, null);
          const tab_wrapper = go(ct, $closest('.tab_wrapper'));
          const tab = go(tab_wrapper, $find('.tab_content.selected'));
          const { id: rebound_lgl_inbound_order_id, projection_id } = $data(tab);

          await apiCalls.post.rebound.requestOrder({ rebound_lgl_inbound_order_id });

          await renderInboundTab({ projection_id });
        } catch (err) {
          console.error(err);
          await alertsLgl.error({ err });
        } finally {
          DfInhouseF.lottie_loader.end();
        }
      },
    });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleCancelRequestRebound({ currentTarget: ct }) {
  try {
    await alertInbound.inbound.cancelConfirm({
      confirmCallback: async () => {
        try {
          DfInhouseF.lottie_loader.start('dots', null, null, null);
          const tab_wrapper = go(ct, $closest('.tab_wrapper'));
          const tab = go(tab_wrapper, $find('.tab_content.selected'));
          const { id: rebound_lgl_inbound_order_id, projection_id } = $data(tab);

          await apiCalls.post.rebound.cancelRequestOrder({ rebound_lgl_inbound_order_id });

          await renderReboundTab({ projection_id });
        } catch (err) {
          console.error(err);
          await alertsLgl.error({ err });
        } finally {
          DfInhouseF.lottie_loader.end();
        }
      },
    });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleChangeAutoRequestOutbound({ currentTarget: ct }) {
  const origin_checked = !ct.checked;
  try {
    const { projectionId: projection_id } = ct.dataset;
    if (ct.checked) {
      await alertOutbound.outbound.enableAutoRequestAlert();
      await apiCalls.put.outbound.holding({ projection_id, is_outbound_holding: false });
    } else {
      await alertOutbound.outbound.disableAutoRequestAlert();
      await apiCalls.put.outbound.holding({ projection_id, is_outbound_holding: true });
    }
  } catch (err) {
    ct.checked = origin_checked;
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleRequestOutbound({ currentTarget: ct }) {
  try {
    // 출고 요청
    // LGL-BUWHAL
    await alertOutbound.outbound.requestConfirm({
      confirmCallback: async () => {
        // try {
        //   DfInhouseF.lottie_loader.start('dots', null, null, null);
        //
        //   const tab_wrapper = go(ct, $closest('.tab_wrapper'));
        //   const tab = $data(tab_wrapper);
        //   const { projection_id, id: lgl_outbound_order_group_id } = tab;
        //
        //   await apiCalls.post.outbound.cancelRequestOrder({ lgl_outbound_order_group_id });
        //
        //   await renderOutboundTab({ projection_id });
        // } catch (err) {
        //   console.error(err);
        //   await alertsLgl.error({ err });
        // } finally {
        //   DfInhouseF.lottie_loader.end();
        // }
      },
    });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleCancelRequestOutbound({ currentTarget: ct }) {
  try {
    await alertOutbound.outbound.cancelConfirm({
      confirmCallback: async () => {
        try {
          DfInhouseF.lottie_loader.start('dots', null, null, null);

          const tab_wrapper = go(ct, $closest('.tab_wrapper'));
          const tab = $data(tab_wrapper);
          const { projection_id, id: lgl_outbound_order_group_id } = tab;

          await apiCalls.post.outbound.cancelRequestOrder({ lgl_outbound_order_group_id });

          await renderOutboundTab({ projection_id });
        } catch (err) {
          console.error(err);
          await alertsLgl.error({ err });
        } finally {
          DfInhouseF.lottie_loader.end();
        }
      },
    });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleOpenReturnDetail({ currentTarget: ct }) {
  try {
    DfInhouseF.lottie_loader.start('dots', null, null, null);
    const return_detail = $data(ct);
    await MuiF.openFrame(DfLglReturnDetailMuiF.frame, async (frame, page, [tab]) => {
      tab.makeData = () => return_detail;
      DfInhouseF.lottie_loader.end();
    });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleOpenOutboundProductListDetail({ currentTarget: ct }) {
  try {
    // 출고 상품 목록 modal
    DfInhouseF.lottie_loader.start('dots', null, null, null);
    await MuiF.openFrame(DfLglOutboundProductListMuiF.frame, async (frame, page, [tab]) => {
      tab.makeData = () => ({ data: [], base: 'waybill' });
    });
  } catch (err) {
    console.error(err);
    await alertsLgl.error({ err });
  } finally {
    DfInhouseF.lottie_loader.end();
  }
}

export async function handleOpenOutboundDetail({ currentTarget: ct }) {
  DfInhouseF.lottie_loader.start('dots', null, null, null);
  const data = go(ct, $data);
  await MuiF.openFrame(DfLglOutboundDetailMuiF.frame, async (frame, page, [tab]) => {
    try {
      tab.makeData = () => data;
    } catch (err) {
      console.error(err);
      await alertsLgl.error({ err });
    } finally {
      DfInhouseF.lottie_loader.end();
    }
  });
}

export async function handleOpenInspectionDetail({ currentTarget: ct }) {
  DfInhouseF.lottie_loader.start('dots', null, null, null);

  const { lglItemId: lgl_item_id } = ct.dataset;
  await MuiF.openFrame(DfLglInspectionDetailMuiF.frame, async (frame, page, [tab]) => {
    try {
      const data = await apiCalls.get.item.inspection({ lgl_item_id });
      tab.makeData = () => data;
    } catch (err) {
      console.error(err);
      await alertsLgl.error({ err });
    } finally {
      DfInhouseF.lottie_loader.end();
    }
  });
}

export async function handleRequestReturn({ currentTarget: ct }) {
  const tab_wrapper = go(ct, $closest('.tab_wrapper'));
  const checked_items = go(
    tab_wrapper,
    $findAll('table .tbody tr input[type="checkbox"]'),
    filter((checkbox) => checkbox.checked),
  );
  if (checked_items.length > 0) {
    const data = go(
      checked_items,
      map((checkbox) => {
        const tr = go(checkbox, $closest('tr'));
        return $data(tr);
      }),
    );

    const only_ok = go(
      data,
      every(({ lgl_item_inbound_inspections }) => lgl_item_inbound_inspections.is_ok),
    );
    const only_reject = go(
      data,
      every(({ lgl_item_inbound_inspections }) => !lgl_item_inbound_inspections.is_ok),
    );

    const confirmCallback = () => {
      // LGL-BUWHAL
      console.log('회수 요청 api');
    };

    if (only_ok) {
      await alertInbound.inbound.returnOnlyOkItemsConfirm({ confirmCallback });
    } else if (only_reject) {
      await alertInbound.inbound.returnOnlyRejectItemsConfirm({ confirmCallback });
    } else {
      await alertInbound.inbound.returnItemsConfirm({ confirmCallback });
    }
  }
}
