import { go, find, html, sel } from 'fxjs/es';
import { SVGEditorRangeSliderWrapperTmplS } from '../../../RangeSliderWrapper/S/Tmpl/module/SVGEditorRangeSliderWrapperTmplS.js';
import { SVGEditorAttrS } from '../../../Attr/S/Function/module/SVGEditorAttrS.js';
import { SVGEditorTextTmplS } from '../../../Text/S/Tmpl/module/SVGEditorTextTmplS.js';
import { SVGEditorStrokeDashF } from '../../../StrokeDash/F/Function/module/SVGEditorStrokeDashF.js';

export const textMobile = ({ svg_el, fonts }) => {
  const font = find(
    ({ original_name }) => SVGEditorAttrS.get('font-family', svg_el).indexOf(original_name) > -1,
  )(fonts);
  return html`
    <div class="select_font" data-is_selected_font="${!!font}">
      <img src="${go(font, sel('url_2x'))}" alt="font" class="font" />
      <div style="padding-left:16px;" class="no_font">-</div>
      <img
        src="//s3.marpple.co/file/u_15/2018/7/original/f_5274_1531137521497_VF6f4lRDkA4DTihI1tX.svg"
        alt="arrow"
        class="arrow"
      />
    </div>
    ${SVGEditorTextTmplS.fontStyleWrapper(svg_el)}
    <div class="range_slider_wrapper">
      ${SVGEditorRangeSliderWrapperTmplS.fn({
        key: 'letter-spacing',
        title: T('maker_text_editor::문자 간격'),
        step: 1,
        min: -10,
        max: 300,
        value: SVGEditorStrokeDashF.svgToViewUnit(SVGEditorAttrS.get('letter-spacing', svg_el)),
      })}
    </div>
  `;
};
