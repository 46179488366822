import { makeCompositeTemplateSelectionHtml } from '../S/composite_template_selection.tmpl.js';
import { each, go, tap, chunk, eachC } from 'fxjs/es';
import { $addClass, $closest, $delegate, $find, $removeClass, $toggleClass } from 'fxdom/es';
import { renderAssocCompositeTemplates } from '../../CompositeList/F/fs.js';
import { compositeTemplateSelectionPageNext } from './fs.js';
import { COMPOSITE_TEMPLATE_SELECTION_PAGE, COMPOSITE_TEMPLATE_SELECTION_TAB } from '../S/constant.js';
import {
  makeDesignReadyUrlAfterSetMockupUrl,
  setCompositeTemplateMockupUrl,
} from '../../Core/F/mockup_fns.js';

$.frame.defn_page({
  page_name: COMPOSITE_TEMPLATE_SELECTION_PAGE,
  el_class: 'page_in_mp_maker',
  tabs: [
    {
      tab_name: COMPOSITE_TEMPLATE_SELECTION_TAB,
      template: makeCompositeTemplateSelectionHtml,
      showed: () => {
        $.don_loader_end3();
      },
      appended: ($tab_el) =>
        go(
          $tab_el,
          tap($addClass('hidden')),
          tap($find('.assoc_composite_templates'), async (assoc_composite_templates_els) => {
            const { product, assoc_composite_templates } = go(
              assoc_composite_templates_els,
              $closest('.don_tab'),
            ).tab_opt;
            $.don_loader_end();
            await renderAssocCompositeTemplates(
              assoc_composite_templates_els,
              assoc_composite_templates,
              product,
            );
          }),
          tap($removeClass('hidden')),
          $delegate('click', '.assoc_composite_template', function (e) {
            $toggleClass('checked', e.currentTarget);
          }),
          $delegate('click', '.next', compositeTemplateSelectionPageNext),
        ),
    },
  ],
});

export const addCompositeTemplateSelectionPage = async ({
  product,
  next,
  svg_product_materials,
  selected_option_group,
  assoc_composite_templates,
  animation,
}) => {
  $.don_loader_end();
  $.don_loader_start3();
  await setCompositeTemplateMockupUrl(assoc_composite_templates, product);
  await go(
    assoc_composite_templates,
    chunk(5),
    each(
      eachC(async (assoc_composite_template) => {
        assoc_composite_template._.composite_result = {
          design_ready_url: await makeDesignReadyUrlAfterSetMockupUrl(assoc_composite_template),
        };
      }),
    ),
  );
  await $.frame.add_page({
    page_name: COMPOSITE_TEMPLATE_SELECTION_PAGE,
    title: `${T('썸네일 선택')}`,
    animation,
    tabs: [
      {
        tab_name: COMPOSITE_TEMPLATE_SELECTION_TAB,
        data_func: function () {
          return assoc_composite_templates;
        },
        assoc_composite_templates,
        product,
        svg_product_materials,
        selected_option_group,
        next_func: next,
      },
    ],
  });
};
