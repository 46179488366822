import { sel, strMap } from 'fxjs/es';
import { UtilImageS } from '../../../Util/Image/S/Function/module/UtilImageS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import {
  makeReviewListComment,
  makeReviewListImgSrc,
  makeDfReviewListReplyHtml,
  makeReviewListScoreHtml,
  makeReviewListSnsHref,
  makeReviewListSnsUrl,
  makeDeleteReviewButton,
} from '../../List/S/tmpl.js';

export const makeReviewLink = (review) =>
  review.domain_name
    ? `/${T.lang}/${review.domain_name}/products/${review.product_id}`
    : `/${T.lang}/product/detail?bp_id=${review._.base_product.id}`;

export const makeReviewDetailHtml = (review) => legacyHtml`
  <div
    class="review_detail"
    _sel="review_list->review_detail"
    has_photo="${review.has_photo && !!sel('files.length', review)}"
  >
    <div class="left_info">
      <div class="review_imgs_wrap" _sel="./files" is_len="${review.files.length > 1}">
        <div class="review_imgs swiper-container">
          <ul class="swiper-wrapper">
            ${strMap(
              (file) => legacyHtml`
                <li class="review_img swiper-slide" _sel="./(#${file.id})">
                  <img src="${UtilImageS.getResizedUrl({
                    width: 686,
                    url: file.url,
                  })}" alt="" />
                </li>
              `,
              review.files,
            )}
          </ul>
          <div class="swiper-button-next swiper-button"></div>
          <div class="swiper-button-prev swiper-button"></div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="right_info">
      <div class="right_info_wrap">
        ${makeDeleteReviewButton(review)}
        <div class="user_name">${review._.user.email}</div>
        <div class="review_score">${makeReviewListScoreHtml(review.score)}</div>
        <div class="created_at">${moment(review.created_at).format('YYYY-MM-DD')}</div>
        <div class="comment_wrap">
          <div class="comment">${makeReviewListComment(review)}</div>
          <div class="sns_url_wrap" is_sns_url="${!!review.sns_url}">
            <a href="${makeReviewListSnsHref(review.sns_url)}" class="sns_url" target="_blank">
              <span class="sns_info" sns_info="${makeReviewListSnsUrl(review.sns_url)}"></span>
              <span>SNS</span>
            </a>
          </div>
        </div>
        <a
          href="${makeReviewLink(review)}"
          ${review.domain_name ? ' target="blank" ' : ''}class="base_product"
          is_bp_review="${!!review.base_product_id}"
        >
          <span class="product_thumb_url"><img src="${makeReviewListImgSrc(review)}" alt="" /></span>
          <span class="name">
            ${review.product_name || review._.base_product.name || T('review::Discontinued product')}
            <div class="color">${review._.base_product_color.name}</div>
          </span>
        </a>
        <div class="is_kr" lang="${T.lang}">
          ${makeDfReviewListReplyHtml(review)}
          <div class="best_mark_wrap" is_best="${review.is_best}">
            <div class="best_mark">BEST REVIEW</div>
          </div>
        </div>
      </div>
    </div>
  </div>
`;
