import { every, find, go, head, last, sel, sortBy, sortByDesc } from 'fxjs/es';
import { BpDiscountSetConstantS } from '../Constant/module/BpDiscountSetConstantS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { UtilConstantS } from '../../../Util/S/Constant/module/UtilConstantS.js';

export const getBpDiscountRangeByQuantity = (quantity, bp_discount_ranges) => {
  return go(
    bp_discount_ranges,
    sortBy((bp_discount_range) => bp_discount_range.start),
    find((bp_discount_range) => {
      return bp_discount_range.start <= quantity && bp_discount_range.end >= quantity;
    }),
  );
};

export const getMoqQuantity = (bp_discount_ranges) => {
  return go(bp_discount_ranges, find(sel('is_default')), sel('start'));
};
export const makePossibleQuantity = ({ quantity, is_plus = true }, bp_discount_ranges) => {
  const ranged_quantity = getBpDiscountRangeByQuantity(quantity, bp_discount_ranges);

  if (ranged_quantity) return { quantity };
  const start_quantity = head(bp_discount_ranges).start;
  const last_quantity = last(bp_discount_ranges).end;

  const final_value =
    start_quantity > quantity ? start_quantity : last_quantity < quantity ? last_quantity : quantity;
  if (is_plus) {
    const qty = go(
      bp_discount_ranges,
      sortBy((bp_discount_range) => bp_discount_range.end),
      find((bp_discount_range) => {
        return bp_discount_range.end >= quantity;
      }),
      (bp_discount_range) => bp_discount_range?.start || final_value,
    );

    return { quantity: qty, error: '잘못된 수량입니다.' };
  } else {
    const qty = go(
      bp_discount_ranges,
      sortByDesc((bp_discount_range) => bp_discount_range.start),
      find((bp_discount_range) => {
        return bp_discount_range.start <= quantity;
      }),
      (bp_discount_range) => bp_discount_range?.end || final_value,
    );
    return { quantity: qty, error: '잘못된 수량입니다.' };
  }
};

export const util = {
  isStepTypeBpDiscountRanges: (bp_discount_ranges) =>
    go(
      bp_discount_ranges,
      every(({ start, end }) => start === end),
    ),
};

export const getTypesFromApp = () => {
  return go(undefined, () => {
    if (G.collabo_type === '') return [BpDiscountSetConstantS.TYPE.MP, BpDiscountSetConstantS.TYPE.MP_WOW];
    if (UtilS.isNessApp()) return [BpDiscountSetConstantS.TYPE.NESS];
    if (UtilS.isNboxApp()) return [BpDiscountSetConstantS.TYPE.NBOX];
    if (UtilS.isMarppleShopApp()) return [BpDiscountSetConstantS.TYPE.MSHOP];
  });
};
export const getTypesFromService = (service_name) => {
  return go(undefined, () => {
    if (UtilConstantS.SERVICE_APP_NAME.NESS === service_name) return [BpDiscountSetConstantS.TYPE.NESS];
    if (UtilConstantS.SERVICE_APP_NAME.NBOX === service_name) return [BpDiscountSetConstantS.TYPE.NBOX];
    if ([UtilConstantS.SERVICE_APP_NAME.MARPPLE].includes(service_name))
      return [BpDiscountSetConstantS.TYPE.MP, BpDiscountSetConstantS.TYPE.MP_WOW];
    if (UtilConstantS.SERVICE_APP_NAME.MARPPLE_SHOP === service_name)
      return [BpDiscountSetConstantS.TYPE.MSHOP];
  });
};
