import { EVAL_RESULT_NAMES } from './eval_result_names.js';

export const makeSSML = (text, speed = 'fast') => {
  const voice_name = 'WOMAN_READ_CALM';
  const speed_rate = speed;
  const volume = 'loud';
  return `<speak><prosody rate="${speed_rate}" volume="${volume}"><voice name='${voice_name}'>${text}</voice></prosody></speak>`;
};

export const makeAudioSrc = (audio_src, level = '0dB', speed = '100%') => {
  return ` <speak><audio src="${audio_src}" soundLevel="${level}" speed="${speed}"/></speak>`;
};

export const label_holding_speech_data = {
  [EVAL_RESULT_NAMES.success.dev]: makeSSML('제품 전달 하세요!'),
  [EVAL_RESULT_NAMES.fail.dev]: makeSSML('대기 하세요!'),
  [EVAL_RESULT_NAMES.cancel.dev]: makeSSML('주문 취소 되었어요!'),
  [EVAL_RESULT_NAMES.success_to_fail.dev]: makeSSML('제품 전달에서 대기로 변경되었어요!'),
  [EVAL_RESULT_NAMES.success_to_cancel.dev]: makeSSML('제품 전달에서 주문 취소로 변경되었어요!'),
  [EVAL_RESULT_NAMES.fail_to_success.dev]: makeSSML('대기에서 제품 전달로 변경되었어요!'),
  [EVAL_RESULT_NAMES.fail_to_cancel.dev]: makeSSML('대기에서 주문 취소로 변경되었어요!'),
  [EVAL_RESULT_NAMES.cancel_to_success.dev]: makeSSML('주문 취소에서 제품 전달로 변경되었어요!'),
  [EVAL_RESULT_NAMES.cancel_to_fail.dev]: makeSSML('주문 취소에서 대기로 변경되었어요!'),
  [EVAL_RESULT_NAMES.cancel_to_cancel.dev]: makeSSML('계속 취소 상태에요.'),
  [EVAL_RESULT_NAMES.fail_to_fail.dev]: makeSSML('계속 대기하세요.'),
  [EVAL_RESULT_NAMES.success_to_success.dev]: makeSSML('계속 제품 전달 상태에요.'),
  find_error: makeSSML('이미 완료된 주문이거나 찾을 수 없는 주문이에요.'),
  server_error: makeSSML('조금 후에 다시 시도해주세요.'),
  recognition_fail: makeSSML('인식할 수 없는 코드에요!'),
  command_fail: makeSSML('인식 불가 명령이에요!'),
  call_dev: makeSSML('개발팀에게 연락주세요.'),
};

export const label_task_status_change_speech_data = {
  task_completed: makeSSML('완료 처리되었습니다.'),
  task_completed_again: makeSSML('이미 완료된 태스크 입니다.'),
  task_on: makeSSML('진행~ 중, 처리되었습니다.'),
  task_on_again: makeSSML('이미 진행~ 중, 태스크 입니다.'),
  task_ready: makeSSML('진행~ 전, 처리되었습니다.'),
  task_ready_again: makeSSML('이미 진행~ 전, 태스크 입니다.'),
  task_process_fail: makeSSML('처리 중 오류가 발생하였습니다.'),
  task_null: makeSSML('태스크 목록에 없습니다.'),
};

export const waybill_CJ_speech_data = {
  all_completed: makeSSML('모든 운송장이 정상 처리되었습니다.'),
  partial_completed: makeSSML('일부 운송장만 정상 처리되었습니다.'),
  all_fail: makeSSML('모든 운송장을 처리하지 못했습니다. 확인 해주세요.'),
  4001: makeSSML('주문 정보가 없습니다. 확인 해주세요.'),
  4002: makeSSML('배송 정보가 없습니다. 확인 해주세요.'),
  5001: makeSSML('주문 정보 데이터베이스 오류입니다. 개발자 문의해 주세요.'),
  5002: makeSSML('배송 정보 데이터베이스 오류입니다. 개발자 문의해 주세요.'),
  9900: makeSSML('서버 코드 에러 입니다. 개발자 문의해 주세요.'),
  '0000': makeSSML('정상 처리되었습니다.'),
  1101: makeSSML('취소된 주문입니다. 확인 해주세요.'),
  1102: makeSSML('아직 제작 준비중 입니다. 확인 해주세요.'),
  1103: makeSSML('아직 제작 중 입니다. 확인 해주세요.'),
  1104: makeSSML('이미 배송 처리되었습니다. 확인 해주세요.'),
  1105: makeSSML('아직 결제 전 주문입니다. 확인 해주세요.'),
  1106: makeSSML('제작용 주문서는 운송장 발급이 불가합니다. 배송용 주문서를 확인 해주세요.'),
  1107: makeSSML('묶음 배송 주문입니다. 확인 해주세요.'),
  1201: makeSSML('이미 송장번호가 존재합니다. 확인 해주세요.'),
  1202: makeSSML('이미 엑세 다운로드 처리된 건입니다. 확인 해주세요.'),
  1203: makeSSML('방문 수령 건 입니다. 확인 해주세요.'),
  1204: makeSSML('개별 배송 건 입니다. 확인 해주세요.'),
  1205: makeSSML('퀵 발송 건 입니다. 확인 해주세요.'),
  1206: makeSSML('묶음 배송 건 입니다. 확인 해주세요.'),
  1207: makeSSML('알수 없는 배송 방식입니다. 확인 해주세요.'),
  1208: makeSSML('택배사가 CJ가 아닙니다. 확인 해주세요.'),
  1209: makeSSML('유효하지 않은 배송 정보가 있습니다. 배송 정보를 확인 해주세요.'),
  1301: makeSSML('CJ 운송장 발급에 실패했습니다. 개발자에게 문의주세요.'),
  1302: makeSSML('CJ 주소를 정제하지 못했습니다. 개발자에게 문의주세요.'),
  1401: makeSSML('CJ 택배 집화 예약 요청에 실패했습니다. 개발자에게 문의주세요.'),
  1402: makeSSML('CJ 택배 집화 예약 코드에러가 발생했습니다. 개발자에게 문의주세요.'),
  1501: makeSSML('운송장이 출력되지 않았습니다. 프린터를 점검 바랍니다.'),
  complete_merged: makeSSML('축하합니다! 모든 배송용 주문서가 처리되었습니다.'),
  remaining_merged: makeSSML('아직 남은 배송용 주문서가 있습니다. 이어서 작업하세요.'),
  applause: makeAudioSrc(
    'https://s3.ap-northeast-2.amazonaws.com/s3.marpple.co/audios/applause.wav',
    '-10dB',
    '110%',
  ),
  complete_combined: makeSSML('묶음 배송들이 빠짐 없이 함께 포장되었는지 잘 확인해 주세요.'),
  complete_combined_goto_parent: makeSSML(
    '축하합니다! 모든 묶음 배송이 준비되었습니다. 부모 주문서에서 운송장을 출력하세요.',
  ),
  remaining_combined_parent: makeSSML('아직 처리되지 못한 묶음 주문서가 있어 운송장 발급이 불가합니다.'),
  remaining_combined_child: makeSSML('남아있는 묶음 주문서가 있습니다. 계속 진행해 주세요.'),
  9001: makeSSML('모든 배송 정보가 택배 요청에 실패했습니다.'),
};
