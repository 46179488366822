import { NewMakerBaseProductsTattooConstantS } from '../../../BaseProducts/Tattoo/S/Constant/module/NewMakerBaseProductsTattooConstantS.js';
import { NewMakerBaseProductsTattooF } from '../../../BaseProducts/Tattoo/F/Function/module/NewMakerBaseProductsTattooF.js';
import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';

export const postProcess = {
  tattoo: async (product) => {
    if (NewMakerBaseProductsTattooConstantS.ids.includes(product.base_product_id)) {
      try {
        product.product_faces2.printable_files = await NewMakerBaseProductsTattooF.uploadPrintableFiles({
          base_product_size_id: product.base_product_size_id,
          base_product_color_id: product.base_product_color_id,
          product_faces2: product.product_faces2,
        });
      } catch (e) {
        $.don_loader_end();
        if (e.stack && JSON.stringify(e.stack).includes('Browser cannot support webgl')) {
          ConfigSentryF.capture(new Error('WEBGL ERROR'));
          throw new Error(
            '현재 고객님의 기기는 타투 제품을 생성하는데 적합하지 않는것으로 보입니다. 다른 기기에서 생성 부탁 드립니다.',
          );
        } else {
          ConfigSentryF.makerCapture(e);
          throw new Error(T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.'));
        }
      }
    }
  },
};
