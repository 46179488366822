import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPAuthResetPasswordTmplS } from '../../S/Tmpl/module/OMPAuthResetPasswordTmplS.js';
import { OMPAuthResetPasswordMuiF } from '../Mui/module/OMPAuthResetPasswordMuiF.js';

export const openFrame = (closed, background = true) =>
  OMPCoreUtilF.openFrameFit(OMPAuthResetPasswordMuiF.frame, (frame, page, [tab]) => {
    frame.title = TT('auth::reset_password::reset_password_01');
    frame.el_class = background ? '' : 'no-background';
    frame.always_remove = true;
    frame.closed = closed;
    tab.template = OMPAuthResetPasswordTmplS.resetPasswordTmpl;
  });
