import { go } from 'fxjs/es';
import { extractImageIds, parseSvgText } from '../../../ProductColor/S/fs.js';

/**
 * @define svg element 로부터 에디터에서 사용된 이미지 id 배열을 추출하는 함수
 * @param {HTMLElement} svg_el - svg element
 * @return {number[]} - 에디터에서 사용된 이미지 id 배열
 * */
export const getUsedImageIdsFromSvgEditor = async (svg_el) => {
  if (!svg_el) return [];
  if (!(svg_el instanceof SVGElement)) return [];
  if (svg_el.tagName.toLowerCase() !== 'svg') return [];

  return go(svg_el, svgToString, parseSvgText, extractImageIds);
};

function svgToString(svgElement) {
  // SVG 요소의 직렬화된 문자열을 반환합니다
  const serializer = new XMLSerializer();
  return serializer.serializeToString(svgElement);
}
