import { CommonNS } from '@marpple/sticker-editor';
import { each, equals2, go, takeL } from 'fxjs/es';
import { SVGEditorAttrS } from '../../../../../../SVGEditor/Attr/S/Function/module/SVGEditorAttrS.js';
import { SVGEditorFontConstS } from '../../../../../../SVGEditor/Font/S/Const/module/SVGEditorFontConstS.js';

export const makeNewText = (tab_el) => {
  const editor = tab_el?.__mp_keyring_editor;

  const text_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'text');
  text_el.innerHTML = equals2(T.lang)('kr') ? '텍스트' : 'Text';
  editor.addEl(text_el);
  editor.scaleWithinArtBoard({ el: text_el, scale: 0.4 });
  const els = [text_el];
  editor.alignToHorizontalCenter(els);
  editor.alignToVerticalCenter(els);
  SVGEditorAttrS.set(SVGEditorFontConstS.DEFAULT_FONT_STYLE, text_el, editor);
  editor.selectEls(els);
};

export const makeNewPath = (tab_el) => {
  const editor = tab_el?.__mp_keyring_editor;

  const path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
  path_el.setAttributeNS(null, 'fill', 'none');
  path_el.setAttributeNS(null, 'stroke', '#000000');
  path_el.setAttributeNS(null, 'd', 'M 0 0 C 50 100 100 100 150 0 C 200 -100 250 -100 300 0');
  path_el.setAttributeNS(null, 'opacity', '1');

  editor.addEl(path_el);
  editor.scaleWithinArtBoard({ el: path_el, scale: 0.4 });
  const els = [path_el];
  editor.alignToHorizontalCenter(els);
  editor.alignToVerticalCenter(els);
  editor.setStrokeWidth({ el: path_el, stroke_width: 1 });
  editor.setStrokeDasharray({ el: path_el, stroke_dasharray: [0, 0] });
  editor.selectEls(els);
};

export const duplicateFirstSelectedEl = (tab_el) => {
  if (tab_el.__mp_keyring_editor != null) {
    const editor = tab_el.__mp_keyring_editor;
    go(
      editor.getSelectedEls(),
      takeL(1),
      each((el) => {
        const clone_el = editor.copyEl(el);
        editor.selectEls([clone_el]);
      }),
    );
  }
};

export const deleteAllSelectedEls = (tab_el) => {
  if (tab_el.__mp_keyring_editor != null) {
    const editor = tab_el.__mp_keyring_editor;
    each((el) => editor.removeEl(el))(editor.getSelectedEls());
  }
};
