import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreCellTmplS } from '../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { OMPProductListConstantS } from '../Constant/module/OMPProductListConstantS.js';
import { cateSelectorTitle, checkedFilterItem } from './fn.js';

export const sortSelector = (query) => {
  const sort_list = OMPProductListConstantS.SORT_LIST().map((data) => {
    return {
      ...data,
      checked: data.id == query.sort,
    };
  });
  const checked_sort = sort_list.find(({ checked }) => checked);
  return html`<div class="omp-product-list__sort">
    <div class="omp-product-list__checked-sort">
      <div class="omp-product-list__checked-sort-name" data-id="${checked_sort.id}">${checked_sort.name}</div>
      <span class="omp-product-list__checked-sort-triangle">${OMPCoreAtomTmplS.triangleIcon()}</span>
    </div>
    <div class="omp-product-list__sort-selector">
      ${strMap((sort) => {
        return html`
          <button
            type="button"
            class="omp-product-list__sort-item"
            data-id="${sort.id}"
            data-name="${sort.name}"
          >
            ${sort.name}
          </button>
        `;
      }, sort_list)}
    </div>
  </div>`;
};

const cateItemHtml =
  (query, checked_cate_list) =>
  ({ id, name }) => {
    return html`
      <a
        data-id="${id}"
        class="omp-product-list__cate-item ${id == query.cate_item_id || (!id && !query.cate_item_id)
          ? 'omp-product-list__cate-item--checked'
          : ''}"
        href="/${TT.lang}/product/list/${checked_cate_list.id}?cate_item_id=${id}"
        >${name}</a
      >
    `;
  };
export const pcListBody = (bps) => {
  if (bps.length) {
    return html`<div class="omp-product-list__list">${strMap(OMPCoreCellTmplS.product, bps)}</div>`;
  } else {
    const tt_no_list = TT('product::list::list_17_1');
    return html`<div class="omp-product-list__no-list">${tt_no_list}</div>`;
  }
};
export const pcList = ({ checked_cate_list, checked_filter_items, checked_cate_item, bps, query }) => {
  const tt_all_cate_item = TT('product::list::list_9_1');

  return html`<div class="omp-product-list">
    <div class="omp-product-list__header">
      ${cateSelectorTitle({ checked_cate_list, checked_cate_item })}
      <div class="omp-product-list__flex-space-between">
        <div class="omp-product-list__cate-items">
          ${cateItemHtml(query, checked_cate_list)({ id: '', name: tt_all_cate_item })}
          ${strMap(cateItemHtml(query, checked_cate_list), checked_cate_list._.cate_items)}
        </div>
        ${sortSelector(query)}
      </div>
      <div
        class="omp-product-list__checked-filter-items opm-product-list-checked-filter-items"
        data-list_show="${checked_filter_items.length > 0}"
      >
        ${strMap(checkedFilterItem, checked_filter_items)}
      </div>
    </div>
    <div class="omp-product-list__body opm-product-list-bps">${pcListBody(bps)}</div>
  </div>`;
};
