import rfdc from 'rfdc';
import { isEmpty, isNil } from 'fxjs/es';
const deepCloneObj = rfdc();

export function deepClone(obj) {
  return deepCloneObj(obj);
}

export function isEmNil(_) {
  return isEmpty(_) || isNil(_);
}

export function isNotEmpty(_) {
  return !isEmNil(_);
}

export function filterUndefined(obj) {
  const new_obj = {};
  Object.keys(obj).forEach((k) => {
    const v = obj[k];
    v !== undefined && (new_obj[k] = v);
  });
  return new_obj;
}
