import { eachL, equals2, every, go, isNil, mapL, takeAll, takeAllC, zipL } from 'fxjs/es';
import { stickerCollisionCheck } from '@marpple/stickerizer';
import svgpath from 'svgpath';
import { beforeLoginCheck } from '../../../../../../User/Login/F/fs.js';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';

export const handleHeaderCancelButtonClick = () => async () => {
  const is_close = await $.confirm(
    T('modules::VectorEditor::Sticker::message::디자인이 모두 삭제됩니다. 에디터를 종료하시겠습니까?'),
  );
  if (!is_close) {
    return;
  }
  MuiF.closeFrame(null);
};

export const handleHeaderDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    if (!box.sel('shared_product')) {
      const is_login = await beforeLoginCheck(true);
      if (!is_login) {
        return;
      }
    }

    $.don_loader_start();
    try {
      const collision_container_path_data = (() => {
        const collision_container_path_data = tab_el.__mp_collision_container_path_data;
        if (isNil(collision_container_path_data)) {
          const error = new Error(`tab_el.__mp_collision_container_path_data 가 없습니다.`);
          error.__mp_alert_message = T(
            'modules::VectorEditor::Sticker::message::안전 영역을 계산할 수 없습니다.',
          );
          throw error;
        }
        return collision_container_path_data;
      })();

      const stickers = (() => {
        const stickers = tab_el.__mp_free_sticker_editor?.getEls?.();
        if (isNil(stickers)) {
          const error = new Error(`tab_el.__mp_free_sticker_editor.getEls() 를 구할 수 없습니다.`);
          error.__mp_alert_message = T(
            'modules::VectorEditor::Sticker::message::스티커를 가져올 수 없습니다.',
          );
          throw error;
        }
        if (stickers.length <= 0) {
          const error = new Error(`스티커가 0 개 이하입니다.`);
          error.__mp_alert_message = T(
            'modules::VectorEditor::Sticker::message::스티커가 없습니다. 스티커를 만들어주세요.',
          );
          throw error;
        }
        if (equals2(stickers.length)(1)) {
          const error = new Error(`스티커가 1 개입니다.`);
          error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::현재 모든 스티커가 1개로');
          throw error;
        }
        return stickers;
      })();
      const sticker_path_datas = await go(
        stickers,
        mapL((sticker) => {
          const transform = sticker.wrapper_el.getAttributeNS(null, 'transform');
          const transformed_offset_path_data = !transform
            ? sticker.target_image_el.__mp_offset_path_data
            : svgpath(sticker.target_image_el.__mp_offset_path_data).transform(transform).toString();
          sticker.target_image_el.__mp_transformed_offset_path_data = transformed_offset_path_data;
          return transformed_offset_path_data;
        }),
        takeAllC,
      );
      const { container_path_tobe_resolved, design_paths_tobe_resolved_arr } = stickerCollisionCheck(
        collision_container_path_data,
        sticker_path_datas,
      );

      if (
        !container_path_tobe_resolved &&
        every((tobe_resolved) => !tobe_resolved)(design_paths_tobe_resolved_arr)
      ) {
        $.don_loader_end();
        const data = (await tab_el.__mp_postProcess?.(tab_el.__mp_free_sticker_editor)) ?? null;
        MuiF.closeFrame(data);
        return;
      } else if (box.sel('is_user->id') === 2928291) {
        try {
          await $.post('/@api/prerequisite_maker/error_logs', {
            json_memo: {
              user: box.sel('is_user'),
              collision_container_path_data,
              sticker_path_datas,
              design_paths_tobe_resolved_arr,
            },
            name: 'VECTOR-EDITOR-container_path_tobe_resolved',
          });
        } catch (e) {}
        $.don_loader_end();
        const data = (await tab_el.__mp_postProcess?.(tab_el.__mp_free_sticker_editor)) ?? null;
        MuiF.closeFrame(data);
        return;
      }
      if (container_path_tobe_resolved) {
        try {
          await $.post('/@api/prerequisite_maker/error_logs', {
            json_memo: {
              user: box.sel('is_user'),
              collision_container_path_data,
              sticker_path_datas,
              design_paths_tobe_resolved_arr,
            },
            name: 'VECTOR-EDITOR-container_path_tobe_resolved',
          });
        } catch (e) {}
      }

      go(
        zipL(stickers, design_paths_tobe_resolved_arr),
        eachL(([{ id }, tobe_resolved]) => {
          if (tobe_resolved) {
            tab_el.__mp_free_sticker_editor.setCuttingLineStrokeStyle({ id, stroke: '#FF0000' });
          } else {
            tab_el.__mp_free_sticker_editor.resetCuttingLineStrokeStyle({ id });
          }
        }),
        takeAll,
      );

      $.don_loader_end();
      await $.alert(
        T('modules::VectorEditor::Sticker::message::너무 가깝거나 가장자리에 있는 스티커를 움직여주세요.'),
      );
    } catch (error) {
      $.don_loader_end();
      await $.alert(error?.__mp_alert_message ?? error?.message ?? 'Oops!');
    }
  };
