import { html } from 'fxjs/es';

export const cartSizeOptionTmpl = ({ name, size = {} }) => {
  return html`<div
    class="omp-cart__product-size-set"
    data-is_not_stock="${size._is_not_stock}"
    data-is_discontinued="${size._is_discontinued}"
  >
    <span
      >${name +
      (size._is_discontinued
        ? ` (${TT('cart::cart_24')})`
        : size._is_not_stock
        ? ` (${TT('cart::cart_23')})`
        : '')}</span
    >
    <span
      >${TT('common::quantity', {
        quantity: size._is_discontinued ? 0 : size.quantity,
      })}</span
    >
    <input name="quantity" type="hidden" value="${size._is_discontinued ? 0 : size.quantity}" />
  </div> `;
};
