import { chunk, find, go, html, map, sel, strMap } from 'fxjs/es';
import { SVGEditorAttrS } from '../../../../Attr/S/Function/module/SVGEditorAttrS.js';
import { SVGEditorRangeSliderWrapperTmplS } from '../../../../RangeSliderWrapper/S/Tmpl/module/SVGEditorRangeSliderWrapperTmplS.js';
import { SVGEditorColorTmplS } from '../../../../Color/S/Tmpl/module/SVGEditorColorTmplS.js';
import { SVGEditorTextTmplS } from '../../../../Text/S/Tmpl/module/SVGEditorTextTmplS.js';
import { VectorEditorColorConstS } from '../../../../../VectorEditor/Color/S/Const/module/VectorEditorColorConstS.js';
import { SVGEditorStrokeDashTmplS } from '../../../../StrokeDash/S/Tmpl/module/SVGEditorStrokeDashTmplS.js';
import { SVGEditorStrokeDashF } from '../../../../StrokeDash/F/Function/module/SVGEditorStrokeDashF.js';

const fontTabContents = ({ svg_el, fonts }) => {
  const font = find(
    ({ original_name }) => SVGEditorAttrS.get('font-family', svg_el)?.indexOf(original_name) > -1,
  )(fonts);
  return html`
    <div class="font_tab_contents rc_contents">
      <div class="content">
        <div class="title">${T('maker_text_editor::텍스트 입력')}</div>
        <div class="wrapper"><input type="text" data-key="innerHTML" value="${svg_el.innerHTML}" /></div>
      </div>
      <div class="content">
        <div class="title">${T('maker_text_editor::서체 선택')}</div>
        <div class="wrapper">
          <div class="select_font is_cursor_pointer" data-is_selected_font="${!!font}">
            <img src="${go(font, sel('url_2x'))}" alt="font" class="font" />
            <div style="padding-left:16px;" class="no_font">-</div>
            <img
              src="//s3.marpple.co/file/u_15/2018/7/original/f_5274_1531137521497_VF6f4lRDkA4DTihI1tX.svg"
              alt="arrow"
              class="arrow"
            />
          </div>
          ${SVGEditorTextTmplS.fontStyleWrapper(svg_el)}
        </div>
      </div>
      <div class="content">
        <div class="title">${T('maker_text_editor::글씨 색상')}</div>
        <div class="colors_wrapper wrapper" data-key="fill">
          ${SVGEditorColorTmplS.colorsWrapper({
            colors: chunk(
              10,
              go(VectorEditorColorConstS.COLORS32, (arr) => {
                const _length = 10 - (arr.length % 10);
                const plus_arr = go(
                  Array(_length),
                  map((v) => {
                    return {};
                  }),
                );
                return arr.concat(plus_arr);
              }),
            ),
            color_code: SVGEditorAttrS.get('fill', svg_el),
          })}
        </div>
      </div>
      <div class="content">
        <div class="title">${T('maker_text_editor::문자 간격')}</div>
        <div class="wrapper range_slider_wrapper">
          ${SVGEditorRangeSliderWrapperTmplS.fn2({
            key: 'letter-spacing',
            is_hidden_range_slider: true,
            step: 1,
            min: -10,
            max: 300,
            value: SVGEditorStrokeDashF.svgToViewUnit(SVGEditorAttrS.get('letter-spacing', svg_el)),
          })}
        </div>
      </div>
    </div>
  `;
};
export const borderTabContents = ({ svg_el }) => html`
  <div
    class="border_tab_contents rc_contents"
    data-has_stroke="${SVGEditorAttrS.get('stroke-width', svg_el) > 0}"
  >
    <div class="content block_for_stroke_dash_wrapper">
      <div class="title">${T('maker_border_editor::테두리 색상')}</div>
      <div class="colors_wrapper wrapper" data-key="stroke">
        ${SVGEditorColorTmplS.colorsWrapper({
          colors: chunk(
            10,
            go(VectorEditorColorConstS.COLORS16, (arr) => {
              const _length = 10 - (arr.length % 10);
              const plus_arr = go(
                Array(_length),
                map((v) => {
                  return {};
                }),
              );
              return arr.concat(plus_arr);
            }),
          ),
          color_code: SVGEditorAttrS.get('stroke', svg_el),
        })}
      </div>
      <div class="block_for_stroke_dash"></div>
    </div>
    <div data-key="strokeWidth" class="content">
      <div class="title">${T('maker_border_editor::두께')}</div>
      <div class="wrapper range_slider_wrapper">
        ${SVGEditorRangeSliderWrapperTmplS.fn2({
          key: 'stroke-width',
          title: T('maker_border_editor::두께'),
          step: 1,
          min: 0,
          max: 30,
          value: SVGEditorStrokeDashF.svgToViewUnit(SVGEditorAttrS.get('stroke-width', svg_el)),
        })}
      </div>
    </div>
    ${SVGEditorStrokeDashTmplS.makeStrokeDashHtml(svg_el)}
  </div>
`;
export const text = ({ svg_el, fonts }) => html`
  <div class="rc_wrapper sticker_etc_share_style" data-current_tab="font_tab">
    <div class="scroll_wrapper">
      <div class="rc_header">
        <div class="back"></div>
        <div class="title">${T('maker_text_editor::텍스트 편집')}</div>
      </div>
      <div class="rc_tabs">
        <div class="tab is_cursor_pointer" data-tab_name="font_tab">${T('maker_text_editor::폰트')}</div>
        <div class="tab is_cursor_pointer" data-tab_name="border_tab">
          ${T('maker_border_editor::테두리')}
        </div>
      </div>
      ${fontTabContents({ svg_el, fonts })} ${borderTabContents({ svg_el })}
    </div>
  </div>
`;

export const makeSelectFontsHtml = ({ fonts, selected_font = {} }) => html`
  <div id="select_fonts_app">
    <div class="fonts iscroll_ul_wrapper">
      ${strMap(
        (font) => html`
          <div
            class="font i_item ${selected_font.original_name === font.original_name ? 'selected' : ''}"
            data-font_family="${font.original_name}"
            thumb_url="${font.url_2x}"
          >
            <img src="${font.url_2x || ''}" alt="" class="off" />
            <img src="${font.url_2x_a || ''}" alt="" class="on" />
          </div>
        `,
        fonts,
      )}
    </div>
  </div>
`;
