import { $data, $delegate } from 'fxdom/es';
import { go, strMap, html, head } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreFramesTabStyleF } from '../../../Core/Frames/TabStyle/F/Function/module/OMPCoreFramesTabStyleF.js';
import { OMPCoreFramesTabStyleConstantS } from '../../../Core/Frames/TabStyle/S/Constant/module/OMPCoreFramesTabStyleConstantS.js';
import { OMPCoreUtilF } from '../../../Core/Util/F/Function/module/OMPCoreUtilF.js';

/**
 * @param {HTMLElement} el
 * @return {HTMLElement}
 */

export const makeModalHtml = ({ type, tabs: modify_info_list, selected_name, bp_id, bp_name }) => {
  return go(
    modify_info_list,
    strMap((info) => {
      return html` <div
        class="modify_info ${info.name === selected_name ? 'selected' : ''}"
        data-title="${info.name}"
        data-modal_title=${type}
      >
        <div class="modify_info__content">
          <div class="modify_info__content__title">${info.name}</div>
          <div class="modify_info__content__description">${info.description}</div>
          ${strMap(
            (i) =>
              html` <img
                  class="modify_info__content__img"
                  src="${OMPCoreUtilF.isMobile() ? i.img_mo : i.img}"
                  alt=""
                />
                <div class="modify_info__content__content">${i.content}</div>`,
            info.images,
          )}
          ${info.name === '사이즈 다름' && bp_id
            ? html`
                <button class="size_issue_tmpl_download" data-bp_id=${bp_id}>
                  ${OMPCoreAtomTmplS.downloadIcon()}
                  <span>${bp_name} ${T('maker_upload_type::템플릿 다운로드')}</span>
                </button>
              `
            : ''}
        </div>
      </div>`;
    }),
  );
};

export const delegateCheckModifications = (el) => {
  return $delegate('click', '.table-button__check_modifications', async ({ currentTarget }) => {
    const {
      product_color_id,
      base_product_id,
      _: {
        product_color: {
          _: {
            base_product: { name: bp_name },
          },
        },
      },
    } = go(currentTarget, $data);

    const { issue_customer, modify_item_list: tab_names } = await $.get('/@api/projection/pdf_file_issue', {
      product_id: product_color_id,
    });

    const type = OMPCoreFramesTabStyleConstantS.TYPE.MODIFY;

    OMPCoreFramesTabStyleF.openInfo({
      tab_names,
      selected_name: head(tab_names),
      type,
      info: {
        description: {
          title: '<span>다음 항목을</span><br><span> 수정해 주세요</span>',
          content: issue_customer,
        },
        content: makeModalHtml,
      },
      bp_id: base_product_id,
      bp_name,
    });
  })(el);
};
