import { isNil } from 'fxjs/es';
import { SVGEditorTempSvgProductsF } from '../../../../TempSvgProducts/F/Function/module/SVGEditorTempSvgProductsF.js';
import { setProductAfterVectorEditor } from './setProductAfterVectorEditor.js';
import { getCurrentFcanvas, getCvDesigns } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { NewMakerCvObjectUtilF } from '../../../../../NewMaker/CvObject/Util/F/Function/module/NewMakerCvObjectUtilF.js';
import { getCurrentBpfInMaker } from '../../../../../Maker/F/getSth.js';
import { isPreviewStyle } from '../../../../../Maker/F/categorize.js';
import { addCvPreview, removeCvPreview } from '../../../../../Maker/F/CvPreview/cv_object.js';

async function setThumbnail({ thumbnail_png_data_url }) {
  const fcanvas = getCurrentFcanvas();
  if (getCvDesigns(fcanvas._objects)[0]) {
    await NewMakerCvObjectUtilF.changeCvObjByImage({
      fcanvas,
      image: { url: thumbnail_png_data_url },
      cv_obj: getCvDesigns(fcanvas._objects)[0],
    });
  } else {
    const print_area = getCurrentBpfInMaker().size_faces[0].print.px;
    fcanvas.add(
      await NewMakerCvObjectUtilF.addLockedAnyImageToCanvas({
        image: { url: thumbnail_png_data_url },
        location: print_area,
      }),
    );
  }

  if (isPreviewStyle(fcanvas)) {
    removeCvPreview(fcanvas, false, true);
    await addCvPreview(fcanvas, 0);
  }
}
export const stickerPostProcess =
  ({ product, lockable_bp_option_group_els, bp_option_groups_el }) =>
  async (result) => {
    if (isNil(result)) {
      return;
    }
    const { original_svg_file, original_cutting_line_svg_file, process_time, makeRasterImages } = result;

    const formData = new FormData();
    formData.append('original_svg_file', await original_svg_file);

    const { original_svg_file_url } = await $.upload(formData, {
      url: '/@fileUpload/svg_images_images/upload_svg_product_materials',
      data: {},
    });
    await SVGEditorTempSvgProductsF.saveTempSvgProduct({
      bp_option_ids: product._.selected_option_group.bp_option_ids,
      original_svg_file_url,
      user_id: box.sel('is_user->id'),
      base_product_id: product.base_product_id,
    });

    const { thumbnail_png_data_url, printable_jpeg_file: printable_file } = await makeRasterImages();
    setProductAfterVectorEditor({
      lockable_bp_option_group_els,
      bp_option_groups_el,
      product,
      original_svg_file_url,
      printable_file,
      cutting_line_svg_file: original_cutting_line_svg_file,
      process_time,
    });
    await setThumbnail({ thumbnail_png_data_url });
  };
