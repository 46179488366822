export function Marquee(el, speed) {
  const direction = el.dataset.marquee || 'left';
  const clone = el.innerHTML;
  const elWidth = el.clientWidth;

  if (direction === 'left') {
    el.insertAdjacentHTML('beforeend', clone);
  } else if (direction === 'right') {
    el.insertAdjacentHTML('afterbegin', clone);
  }

  let i = 0;
  setInterval(function () {
    if (direction === 'left') {
      el.style.transform = `translateX(-${Math.round(i)}px)`;
    } else if (direction === 'right') {
      el.style.transform = `translateX(${Math.round(i)}px)`;
    }
    if (i > elWidth) {
      i = 0;
    }
    i = i + speed;
  }, 0);
}
