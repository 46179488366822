import { PAPER_NAME, STROKE_PROPERTY_NAME, TOOL_NAME } from '../../S/Constant/constants.js';
import { tools } from '../Lib/state.js';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { $addClass, $qs, $qsa } from 'fxdom/es';
import paper from 'paper';
import { each, go } from 'fxjs/es';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';

export function initializeBpcfColorPicker({ tab_el }) {
  const r_stroke_color_control_property_class = `.property-panel .properties[tool-name=${
    TOOL_NAME.rStroke
  }] .property[property-name=${
    tools[TOOL_NAME.rStroke].properties[STROKE_PROPERTY_NAME.color].propertyName
  }]`;

  let mousedown = false;
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });

  const {
    printable_file_info: { bpcf_img_src },
  } = tab_el.tab_opt;

  if (bpcf_img_src == null) {
    throw new Error(`Not exist bpcf image src`);
  }

  const $bpcf_color_picker_r_stroke_canvas = $qs(
    `${r_stroke_color_control_property_class} .bpcf-window canvas`,
  );
  if ($bpcf_color_picker_r_stroke_canvas == null) {
    throw new Error(`Not exist r_stroke bpcf canvas el`);
  }

  const paper_scope_bpcf = new paper.PaperScope();

  DfImageEditorF.setPaperScopeToTabEl({ tab_el, paper_name: PAPER_NAME.bcpf, paper_scope: paper_scope_bpcf });

  paper_scope_bpcf.setup($bpcf_color_picker_r_stroke_canvas);
  paper_scope_bpcf.activate();

  let picker_brush_item;

  const bpcf_raster = new paper_scope_bpcf.Raster({
    source: bpcf_img_src,
    crossOrigin: 'anonymous',
    smoothing: 'off',
    onLoad: () => {
      DfImageEditorF.setViewToBoundsFit({
        paper_scope: paper_scope_bpcf,
        bounds: DfImageEditorF.getLayersUniteBounds({ paper_scope: paper_scope_bpcf }),
      });
      picker_brush_item = new paper_scope_bpcf.Path.Circle({
        center: new paper_scope_bpcf.Point(0, 0),
        radius: 20,
        dashArray: [1.5, 1.5],
        strokeWidth: 2,
        visible: true,
      });
      picker_brush_item.position = bpcf_raster.position;
      DfImageEditorF.updateBrushColorBasedOnRasterSubPixel({
        raster: bpcf_raster,
        brush_item: picker_brush_item,
      });

      const r_stroke_color = DfImageEditorLibF.getState.property.rStroke.color().value;

      if (r_stroke_color) {
        DfImageEditorF.updateRStrokeColor({ tab_el: paper_scope.tab_el, color: r_stroke_color });
      } else {
        updateRStrokeColorBasedOnBrushPicker({
          paper_scope,
          raster: bpcf_raster,
          brush_item: picker_brush_item,
          is_apply_color_distance: true,
        });
      }
    },
  });

  const picker_tool = new paper_scope_bpcf.Tool();

  picker_tool.onMouseDown = (e) => {
    if (e.event.button === 0) {
      mousedown = true;
      updateRStrokeColorBasedOnBrushPicker({
        paper_scope,
        raster: bpcf_raster,
        brush_item: picker_brush_item,
      });
    }
  };
  picker_tool.onMouseMove = (e) => {
    picker_brush_item.position = e.point;
    DfImageEditorF.updateBrushColorBasedOnRasterSubPixel({
      raster: bpcf_raster,
      brush_item: picker_brush_item,
    });

    mousedown &&
      updateRStrokeColorBasedOnBrushPicker({
        paper_scope,
        raster: bpcf_raster,
        brush_item: picker_brush_item,
      });
  };
  picker_tool.onMouseUp = (e) => {
    mousedown = false;
  };
  picker_tool.onKeyDown = (e) => {
    const keyboard_event = e.event;
    if (DfImageEditorF.isNotKeyDownWithMeta({ keyboard_event })) {
      if (keyboard_event.code === 'BracketLeft') {
        decreaseBrushPathItem({ item: picker_brush_item });
      }
      if (keyboard_event.code === 'BracketRight') {
        increaseBrushPathItem({ item: picker_brush_item });
      }
    }
  };

  paper_scope.activate();
}

function updateRStrokeColorBasedOnBrushPicker({
  paper_scope,
  raster,
  brush_item,
  is_apply_color_distance = false,
}) {
  let color = DfImageEditorF.getRasterSubPixelHexColor({
    raster,
    range_item: brush_item,
    isWithoutAlpha: true,
  });

  if (is_apply_color_distance && color) {
    const MIN_COLOR_DISTANCE = 20;
    const BLACK = '#000000';
    const WHITE = '#ffffff';

    const d1 = DfImageEditorF.getColorDistance(BLACK, color);
    const d2 = DfImageEditorF.getColorDistance(WHITE, color);

    if (d1 <= MIN_COLOR_DISTANCE) {
      color = BLACK;
    }
    if (d2 <= MIN_COLOR_DISTANCE) {
      color = WHITE;
    }
  }

  DfImageEditorF.updateRStrokeColor({ tab_el: paper_scope.tab_el, color });
}

function increaseBrushPathItem({ item }) {
  const current_radius = getBrushRadius({ item });
  const new_radius = current_radius + 1;
  item.scale(new_radius / current_radius);
}

function decreaseBrushPathItem({ item }) {
  const current_radius = getBrushRadius({ item });
  const new_radius = current_radius - 1;
  item.scale(new_radius / current_radius);
}

function getBrushRadius({ item }) {
  return item.bounds.width / 2;
}

export function closeAllBpcfPickerWindow() {
  go(
    $qsa('.bpcf-window:not(.hidden)'),
    each((bpcf_picker$) => {
      $addClass('hidden', bpcf_picker$);
    }),
  );
}
