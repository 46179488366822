import { $qs } from 'fxdom/es';

export default function () {
  if (!$.has_class($1('body'), 'help_group_detail')) {
    $.frame(
      {
        frame_name: 'df.help_groups_frame',
        page_name: 'df.help_groups_page',
      },
      {
        page_name: 'df.help_groups_page',
      },
    );
  } else {
    $.frame(
      {
        frame_name: 'df.help_group_detail_frame',
        page_name: 'df.help_group_detail_page',
      },
      {
        page_name: 'df.help_group_detail_page',
      },
    );
  }

  $.frame.defn_frame({
    frame_name: 'df.help_groups_frame',
    page_name: 'df.help_groups_page',
    el_class: 'df_help_groups_frame',
    title: '헬프 관리',
  });

  $.frame.defn_page({
    page_name: 'df.help_groups_page',
    tabs: [
      {
        tab_name: 'df.help_groups_tab',
        appended: __(
          _p.tap(function (tab) {}),
          _p.tap(function (tab) {
            // var cont = $.find1(tab, '.help_list_area');
            // var type = $.attr($.find1(tab, '.help_list_area'), 'type');
            // $.ui.pagination(
            //   $.find1(tab, '.help_pgn'),
            //   {
            //     url: '/@api/df/help/list/' + type,
            //     data: {
            //       //
            //       limit: 15 /* 기본값 20 */
            //     },
            //     // is_history: true, /* 기본값 false */
            //     is_memoize: true /* 기본값 false */,
            //     paging_animate: ___(
            //       function(don_pgn_wrap, new_items) {
            //         this.don_pgn_wrap = don_pgn_wrap;
            //         this.new_items = new_items;
            //         return anime({
            //           targets: don_pgn_wrap,
            //           opacity: 0,
            //           duration: 400,
            //           easing: 'linear'
            //         }).finished;
            //       },
            //       function() {
            //         $.html(this.don_pgn_wrap, this.new_items);
            //         anime({
            //           targets: this.don_pgn_wrap,
            //           opacity: 1,
            //           duration: 400,
            //           easing: 'linear'
            //         });
            //       }
            //     ),
            //     // target: '#scroll_test', /* 기본값 window 스크롤 이벤트 타겟 */
            //     total_count: function() {
            //       return box().total_count;
            //     }
            //   },
            //   _.sum(G.$t_$help_group_item)
            // );
          }),
          $.on('click', '.btn_add_help_group', function (e) {
            const name_input = $.find1(e.$delegateTarget, 'input[name="name"]');
            if ($.val(name_input).length <= 0) {
              $.alert('헬프 그룹 이름을 입력해 주세요.');
              return;
            }
            _p.go(
              $.post('/@api/df/help_group', {
                name: $.val(name_input),
                type: $.attr($.closest(e.$currentTarget, '.help_list_area'), 'type'),
              }),
              function (hg) {
                _p.go(hg, G.$t_$help_group_item, $.append_to($.find1(e.$delegateTarget, '.help_groups')));
                $.val(name_input, '');
                name_input.focus();
              },
            );
          }),
          $.on('click', '.help_group_item .btn_delete', function (e) {
            if (!confirm('삭제하시겠습니까?')) return;

            _p.go(
              $.delete('/@api/df/help_group', {
                id: box.sel($.closest(e.$currentTarget, '.help_group_item')).id,
              }),
              function () {
                $.remove($.closest(e.$currentTarget, '.help_group_item'));
              },
            );
          }),
          $.on('click', '.btn_order_align', function (e) {
            _p.go(
              $.get('/@api/df/help_groups', {
                type: $.attr($.closest(e.$currentTarget, '.help_list_area'), 'type'),
              }),
              function (help_groups) {
                return {
                  order_by: 'asc',
                  type: 'text',
                  items: _map(help_groups, function (hg) {
                    return {
                      id: hg.id,
                      name: hg.name,
                      no: hg.no,
                    };
                  }),
                };
              },
              G.order_align.editor,
              function (items) {
                if (!items || !items.length) return;

                $.don_loader_start();
                _p.go($.put('/@api/df/help_groups/order_align', { items }), function (data) {
                  if (!data.result) {
                    alert('저장되지 않았습니다. 다시 시도해 주세요.');
                    $.don_loader_end();
                    return;
                  }
                  window.location.reload();
                });
              },
            );
          }),
        ),
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'df.help_group_detail_frame',
    page_name: 'df.help_group_detail_page',
    title: '헬프 관리',
  });

  $.frame.defn_page({
    page_name: 'df.help_group_detail_page',
    tabs: [
      {
        tab_name: 'df.help_group_detail_tab',
        appended: __(
          $.on('click', '.help_group_editor .btn_save', function (e) {
            const editor = $.closest(e.$currentTarget, '.help_group_editor');
            const group_editor = $.closest(editor, '.help_group_detail_area');
            _p.go(
              $.put('/@api/df/help_group', {
                id: box.sel(group_editor).id,
                name: $.val($.find1(editor, 'input[name="name"]')),
                title: $.val($.find1(editor, 'input[name="title"]')),
                body: $.val($.find1(editor, 'textarea[name="body"]')),
              }),
              function (help_group) {
                box.extend2(group_editor, help_group);
                $.alert('저장되었습니다.');
              },
            );
          }),
          $.on('click', '.controls .btn_add_hp', function (e) {
            G.df_help_editor(
              {
                help_group_id: box.sel($.find1(e.$delegateTarget, '.help_group_detail_area')).id,
              },
              $.find1(e.$delegateTarget, '.hp_list'),
            );
          }),
          $.on('click', '.help_item .btn_modify', function (e) {
            G.df_help_editor(
              box.sel($.closest(e.$currentTarget, '.help_item')),
              $.find1(e.$delegateTarget, '.hp_list'),
            );
          }),
          $.on('click', '.help_item .btn_delete', function (e) {
            if (!confirm('삭제하시겠습니까?')) return;

            const item = $.closest(e.$currentTarget, '.help_item');
            _p.go(
              $.delete('/@api/df/help/delete', {
                id: box.sel(item).id,
              }),
              function () {
                $.remove(item);
              },
            );
          }),
          $.on('click', '.btn_order_align', function (e) {
            _p.go(
              $.get('/@api/df/helps', {
                help_group_id: box.sel($.find1(e.$delegateTarget, '.help_group_detail_area')).id,
              }),
              function (help_groups) {
                return {
                  order_by: 'asc',
                  type: 'text',
                  items: _map(help_groups, function (hg) {
                    return {
                      id: hg.id,
                      name: hg.title,
                      no: hg.no,
                    };
                  }),
                };
              },
              G.order_align.editor,
              function (items) {
                if (!items || !items.length) return;

                $.don_loader_start();
                _p.go($.put('/@api/df/helps/order_align', { items }), function (data) {
                  if (!data.result) {
                    alert('저장되지 않았습니다. 다시 시도해 주세요.');
                    $.don_loader_end();
                    return;
                  }
                  window.location.reload();
                });
              },
            );
          }),
        ),
      },
    ],
  });

  G.df_help_editor = function (hp, list_cont) {
    $.add_class($1('body'), 'no_scroll');
    _p.go(
      hp,
      _p.t(
        'help',
        '\
        .help_editor_area[_sel="help_group->_->helps->(#{{help.id}})" _id="{{help.id || ""}}" help_group_id="{{help.help_group_id}}"]\
          .bg\
          .editor\
            .close X\
            h3 Help Editor\
            .title\
              textarea[rows="3" cols="50" name="title" placeholder="Question"] {{help.title || ""}}\
            .body\
              #editor_section\
            .btn\
              button[type="button"].btn_cancel 취소\
              button[type="button"].btn_save_hp 저장\
      ',
      ),
      $.append_to($1('#body')),
      _p.tap(function (el_editor) {
        const editor = new tui.Editor({
          el: $qs('#editor_section'),
          previewStyle: 'vertical',
          height: '500px',
        });
        editor.setValue(hp.body || '');

        _p.go(
          el_editor,
          $.on('click', '.btn .btn_save_hp', function (e) {
            const id = $.attr(e.$delegateTarget, '_id');
            const body = {
              help_group_id: $.attr(e.$delegateTarget, 'help_group_id'),
              title: $.val($.find1(e.$delegateTarget, 'textarea[name="title"]')),
              body: editor.getValue(),
              body_html: editor.getHtml(),
            };

            if (id) {
              _p.extend(body, { id });
              _p.go($.put('/@api/df/help', body), function () {
                window.location.reload();
              });
            } else {
              _p.go($.post('/@api/df/help', body), function () {
                window.location.reload();
              });
            }
          }),
          // $.on('click', '.btn_help_file_upload', function(e) {
          //   $trigger('click', $find('input.help_file_upload', e.delegateTarget));
          // }),
          // $.on('change', 'input.help_file_upload', function(e) {
          //   const help_editor_el = $closest('.help_editor_area', e.currentTarget);
          //   const help_id = $attr('_id', help_editor_el);
          //
          //   $.don_loader_start();
          //   go(
          //     $.upload(e.$currentTarget, {
          //       url: `/@api/faq/image/${help_id}`
          //     }),
          //     function(image_urls) {
          //       if (!image_urls || !image_urls.length) {
          //         $.don_loader_end();
          //         return $.alert('이미지가 저장되지 않았습니다.<br>다시 시도해 주세요.');
          //       }
          //
          //       const str_images = strMap(url => `\n![image](${url})`, image_urls);
          //       editor.setValue(`${editor.getValue()}${str_images}`);
          //       $.don_loader_end();
          //     }
          //   );
          // }),
          $.on('click', '.close, .btn_cancel', function (e) {
            $.remove_class($1('body'), 'no_scroll');
            $.remove(e.$delegateTarget);
          }),
        );
      }),
    );
  };
}
