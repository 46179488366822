import { html } from 'fxjs/es';

export const createToastContainer = () => html` <div class="toast-message-container"></div> `;

export const getToastTypeIcon = (type) =>
  type == 'error'
    ? '//s3.marpple.co/files/u_1165777/2021/5/original/650910fc02b4acea7627cdb2af4b855b2ae038141.png'
    : type == 'info'
    ? '//s3.marpple.co/files/u_1165777/2021/5/original/64dbd5b4b2245fde2a03187f5fde9c597badbeae1.png'
    : type == 'confirm'
    ? '//s3.marpple.co/files/u_1165777/2021/5/original/7a9e7d3d98c13e890e87cd6590b9f744ffc042261.png'
    : '';

export const createToastItem = (inner, { type }) => html`
  <div class="toast-message">
    <img class="toast-message__icon" alt="toast message icon" src="${getToastTypeIcon(type)}" />
    <div class="toast-message__inner">${inner}</div>
    <img
      class="toast-message__close"
      alt="close button"
      src="//s3.marpple.co/files/u_1165777/2021/5/original/ebd0d0ac607378cc2c8047b91f20933cd695d4cb1.png"
    />
  </div>
`;
