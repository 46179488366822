import { html, strMap } from 'fxjs/es';
export const fn = ({ issues, ups_memos, prj_memo }) => {
  return html`
    <div class="issue-memo-modal">
      <div class="issue-memo-modal__header">
        <h1>이슈, 메모 확인</h1>
      </div>
      <div class="issue-memo-modal__body">
        ${prj_memo
          ? html`
              <div class="prj_memo">
                <div class="prj_memo__title">
                  <h2>고객 요청</h2>
                </div>
                <div class="prj_memo__body body-content">${prj_memo.replace(/\\n/g, '<br>')}</div>
              </div>
            `
          : html``}
        ${issues?.length
          ? html`
              <div class="issues">
                <div class="issues__title">
                  <h2>이슈</h2>
                </div>
                <div class="issues__body body-content">
                  <ul>
                    ${strMap(
                      (issue) => html`
                        <li>
                          <h3>${issue.title}</h3>
                          <div class="issue_content">${issue.body.replace(/\n/g, '<br>')}</div>
                        </li>
                      `,
                      issues,
                    )}
                  </ul>
                </div>
              </div>
            `
          : html``}
        ${ups_memos?.length
          ? html`<div class="up_memos">
              <div class="up_memos__title">
                <h2>제품 메모</h2>
              </div>
              <div class="up_memos__body body-content">
                <ul>
                  ${strMap((ups_memo) => html` <li>${ups_memo.replace(/\n/g, '<br>')}</li> `, ups_memos)}
                </ul>
              </div>
            </div>`
          : html``}
      </div>
      <div class="issue-memo-modal__footer">
        <button type="button" class="close">닫기</button>
        <button type="button" class="done">계속 하기</button>
      </div>
    </div>
  `;
};
