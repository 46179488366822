import { each, filterL, go, head, join, mapL, takeL } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import {
  activateFooterAddIcon,
  deactivateFooterAddIcon,
  hideAllFooters,
  showTypeNoneFooters,
  showTypeObjectFooters,
  showTypePathFooters,
  showTypeShapeFooters,
  showTypeTextFooters,
} from './footer.js';
import {
  activateBottomMenus,
  deactivateBottomMenus,
  hideBottomMenuBlankPlate,
  hideBottomMenus,
  showBottomMenuBlankPlate,
  showBottomMenus,
} from './bottom_menu.js';
import { SVGEditorMobileImageUploadF } from '../../../../../../SVGEditor/MobileImageUpload/F/Function/module/SVGEditorMobileImageUploadF.js';
import { deleteAllSelectedEls, duplicateFirstSelectedEl, makeNewPath, makeNewText } from './editor.js';
import { VectorEditorFreeShapeMobileF } from '../../../../../FreeShape/Mobile/F/Function/module/VectorEditorFreeShapeMobileF.js';
import { SVGEditorMobileTextInputF } from '../../../../../../SVGEditor/MobileTextInput/F/Function/module/SVGEditorMobileTextInputF.js';
import { SVGEditorMobileTextInputMuiF } from '../../../../../../SVGEditor/MobileTextInput/F/Mui/module/SVGEditorMobileTextInputMuiF.js';
import { SVGEditorTextFrameMuiF } from '../../../../../../SVGEditor/MobileText/F/Mui/module/SVGEditorTextFrameMuiF.js';
import { $width } from 'fxdom/es';
import { SVGEditorColorAndBorderMuiF } from '../../../../../../SVGEditor/MobileColorAndBorder/F/Mui/module/SVGEditorColorAndBorderMuiF.js';
import { VectorEditorKeyringGridMobilePathEditorF } from '../../PathEditor/F/Function/module/VectorEditorKeyringGridMobilePathEditorF.js';
import {
  activateTopMenus,
  activateTopMenuUnlockIcon,
  deactivateTopMenuUnlockIcon,
  hideTopMenuBlankPlate,
  hideTopMenuContainer,
  setLockCount,
  showTopMenuBlankPlate,
  showTopMenuContainer,
} from './top_menu.js';
import { VectorEditorKeyringGridMobileConstantS } from '../../S/Constant/module/VectorEditorKeyringGridMobileConstantS.js';
import { SVGEditorMobileUnlockItemsMuiF } from '../../../../../../SVGEditor/MobileUnlockItems/F/Mui/module/SVGEditorMobileUnlockItemsMuiF.js';
import { activateLayersIcon, deactivateLayersIcon } from './header.js';
import { VectorEditorKeyringGridMobileElementLayerF } from '../../ElementLayer/F/Function/module/VectorEditorKeyringGridMobileElementLayerF.js';
import { VectorEditorKeyringHookMobileF } from '../../../../Hook/Mobile/F/Function/module/VectorEditorKeyringHookMobileF.js';
import { CommonNS } from '@marpple/sticker-editor';

export const handleWindowTouchMove =
  ({ tab_el }) =>
  (event) => {
    if (tab_el.__mp_is_prevent_touch_move) {
      event.preventDefault();
    }
  };

export const handleHeaderCancelButtonClick = () => async () => {
  const is_close = await $.confirm(
    T('modules::VectorEditor::Keyring::Grid::Mobile::error_message::close_keyring_editor'),
  );
  if (!is_close) {
    return;
  }
  MuiF.closeFrame(null);
};

export const handleHeaderNextButtonClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    if (tab_el.__mp_postProcess == null) {
      return;
    }
    const postProcess = tab_el.__mp_postProcess;

    try {
      if (editor.getGraphicsEls().size <= 0) {
        await $.alert(T('modules::VectorEditor::Keyring::Grid::Mobile::error_message::empty_keyring'));
        return;
      }

      await VectorEditorKeyringHookMobileF.makeHook({
        art_board: {
          width: tab_el.__mp_art_board_width,
          height: tab_el.__mp_art_board_height,
          shape_path_data: tab_el.__mp_art_board_shape_path_data,
        },
        makeTargetImageEl: () =>
          editor.exportEditor({
            factor: 1,
            is_art_board_clip_path_on: true,
            container_el: document.body,
          }),
        postProcess: async ({
          cutting_line_path_data,
          hook_position: { x: hook_position_x, y: hook_position_y },
        }) => {
          const cutting_line_result = (() => {
            try {
              const cutting_line_svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
              cutting_line_svg_el.setAttributeNS(
                'http://www.w3.org/2000/xmlns/',
                'xmlns',
                'http://www.w3.org/2000/svg',
              );
              cutting_line_svg_el.setAttributeNS(null, 'viewBox', '0 0 0 0');

              const cutting_line_path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
              cutting_line_path_el.setAttributeNS(null, 'd', cutting_line_path_data);
              cutting_line_path_el.setAttributeNS(null, 'fill', 'none');
              cutting_line_path_el.setAttributeNS(null, 'stroke', '#FF0000');
              cutting_line_path_el.setAttributeNS(null, 'stroke-width', '0.001');
              cutting_line_svg_el.appendChild(cutting_line_path_el);

              document.body.appendChild(cutting_line_svg_el);
              const margin = 2;
              const bbox = cutting_line_path_el.getBBox();
              const view_box_x = Math.floor(bbox.x) - margin;
              const view_box_y = Math.floor(bbox.y) - margin;
              const view_box_width = Math.ceil(bbox.width) + margin * 2;
              const view_box_height = Math.ceil(bbox.height) + margin * 2;
              document.body.removeChild(cutting_line_svg_el);

              cutting_line_svg_el.setAttributeNS(
                null,
                'viewBox',
                go(
                  [view_box_x, view_box_y, view_box_width, view_box_height],
                  mapL((a) => `${a}`),
                  join(' '),
                ),
              );

              const hook_position_ratio = {
                x: (hook_position_x - view_box_x) / view_box_width,
                y: (hook_position_y - view_box_y) / view_box_height,
              };

              return {
                success: true,
                value: {
                  cutting_line_svg_el,
                  hook_position_ratio,
                  view_box: { x: view_box_x, y: view_box_y, width: view_box_width, height: view_box_height },
                },
              };
            } catch (error) {
              console.error(error);
              return {
                success: false,
                value: T(
                  'modules::VectorEditor::Keyring::Grid::Mobile::error_message::cutting_line_calculation_error',
                ),
              };
            }
          })();
          if (!cutting_line_result.success) {
            return cutting_line_result;
          }
          const { cutting_line_svg_el, hook_position_ratio, view_box } = cutting_line_result.value;

          return postProcess({
            cutting_line_svg_el,
            hook_position_ratio,
            view_box,
            makeTargetImageEl: ({ factor }) =>
              editor.exportEditor({
                factor,
                is_art_board_clip_path_on: true,
                container_el: document.body,
              }),
          });
        },
      });
    } catch (error) {
      await $.alert(
        error?.__mp_alert_message ??
          T('modules::VectorEditor::Keyring::Grid::Mobile::error_message::cutting_line_making_error'),
      );
      throw error;
    }
  };

export const handleHeaderMoreIconClick =
  ({ tab_el }) =>
  () => {
    showTopMenuBlankPlate({ tab_el });
    showTopMenuContainer({ tab_el });
  };

export const handleHeaderLayersIconClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    activateLayersIcon({ tab_el });
    await VectorEditorKeyringGridMobileElementLayerF.openElementLayer({
      keyring_editor: editor,
      editor_wrapper_el: tab_el,
    });
    deactivateLayersIcon({ tab_el });
  };

export const handleTopMenuBlankPlateClick =
  ({ tab_el }) =>
  () => {
    hideTopMenuContainer({ tab_el });
    hideTopMenuBlankPlate({ tab_el });
  };

export const handleTopMenuHistoryResetIconClick = () => () => {};

export const handleTopMenuHistoryUndoIconClick = () => () => {};

export const handleTopMenuHistoryRedoIconClick = () => () => {};

export const handleTopMenuDeleteIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    each((el) => editor.removeEl(el))(editor.getSelectedEls());
    hideTopMenuContainer({ tab_el });
    hideTopMenuBlankPlate({ tab_el });
  };

export const handleTopMenuForwardIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    go(
      editor.getSelectedEls(),
      takeL(1),
      each((el) => editor.moveForward(el)),
    );
  };

export const handleTopMenuBackwardIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    go(
      editor.getSelectedEls(),
      takeL(1),
      each((el) => editor.moveBackward(el)),
    );
  };

export const handleTopMenuGroupIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const group_el = editor.groupEls([...editor.getSelectedEls()]);
    editor.selectEls([group_el]);
  };

export const handleTopMenuUngroupIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const group_el = go(
      editor.getSelectedEls(),
      filterL((el) => editor.getIsGroupEl(el)),
      head,
    );
    if (group_el == null) {
      return;
    }

    const ungrouped_els = editor.ungroupEl(group_el);
    editor.selectEls(ungrouped_els);
  };

export const handleTopMenuLockIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    each((el) => editor.lockEl(el))(editor.getSelectedEls());
  };

export const handleTopMenuUnlockIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    hideTopMenuContainer({ tab_el });
    hideTopMenuBlankPlate({ tab_el });

    return new Promise((resolve, reject) =>
      MuiF.openFrame(SVGEditorMobileUnlockItemsMuiF.frame, (frame, page, [tab]) => {
        const frame_closed = frame.closed;
        frame.closed = async (frame_el, v) => {
          await frame_closed.call(frame, frame_el, v);
          v?.error != null ? reject(v.error) : resolve(null);
        };

        const tab_appending = tab.appending;
        tab.appending = (tab_el) => {
          tab_el.__mp_sticker_editor = editor;
          return tab_appending.call(tab, tab_el);
        };
      }).catch(reject),
    );
  };

export const handleTopMenuFlipHorizontalIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    each((el) => editor.flipHorizontal(el))(editor.getSelectedEls());
  };

export const handleTopMenuFlipVerticalIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    each((el) => editor.flipVertical(el))(editor.getSelectedEls());
  };

export const handleTopMenuMoveToLeftEndIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    editor.alignToLeftEnd([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToHorizontalCenterIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    editor.alignToHorizontalCenter([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToRightEndIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    editor.alignToRightEnd([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToTopEndIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    editor.alignToTopEnd([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToVerticalCenterIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    editor.alignToVerticalCenter([...editor.getSelectedEls()]);
  };

export const handleTopMenuMoveToBottomEndIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    editor.alignToBottomEnd([...editor.getSelectedEls()]);
  };

export const handleFooterNoneAddIconClick =
  ({ tab_el }) =>
  (event) => {
    switch (event.currentTarget.dataset.is_active) {
      case 'true': {
        deactivateFooterAddIcon(tab_el);
        deactivateBottomMenus(tab_el);
        hideBottomMenuBlankPlate(tab_el);
        return;
      }
      case 'false': {
        activateFooterAddIcon(tab_el);
        activateBottomMenus(tab_el);
        showBottomMenuBlankPlate(tab_el);
      }
    }
  };

export const handleFooterObjectDuplicateIconClick =
  ({ tab_el }) =>
  () =>
    duplicateFirstSelectedEl(tab_el);

export const handleFooterObjectDeleteIconClick =
  ({ tab_el }) =>
  () =>
    deleteAllSelectedEls(tab_el);

export const handleFooterPathSetColorIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const path_el = go(
      editor.getSelectedEls(),
      filterL((el) => el.nodeName === 'path'),
      head,
    );
    if (path_el == null) {
      return;
    }

    return MuiF.openFrame(SVGEditorColorAndBorderMuiF.frame, (frame, page, [color_tab, border_tab]) => {
      frame.___selected_el = path_el;
      frame.__mp_sticker_editor = editor;
      const one_line_length = $width(window) >= 640 ? 16 : 8;
      color_tab.makeData = () => ({ svg_el: path_el, one_line_length });
      border_tab.makeData = () => ({
        svg_el: path_el,
        one_line_length,
      });
    });
  };

export const handleFooterPathSetStrokeIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const path_el = go(
      editor.getSelectedEls(),
      filterL((el) => el.nodeName === 'path'),
      head,
    );
    if (path_el == null) {
      return;
    }

    return MuiF.openFrame(SVGEditorColorAndBorderMuiF.frame, (frame, page, [color_tab, border_tab]) => {
      frame.___selected_el = path_el;
      frame.__mp_sticker_editor = editor;
      border_tab.selected = true;
      const one_line_length = $width(window) >= 640 ? 16 : 8;
      color_tab.makeData = () => ({ svg_el: path_el, one_line_length });
      border_tab.makeData = () => ({
        svg_el: path_el,
        one_line_length,
      });
    });
  };

export const handleFooterPathEditPathIconClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const path_el = go(
      editor.getSelectedEls(),
      filterL((el) => el.nodeName === 'path'),
      head,
    );
    if (path_el == null) {
      return;
    }

    return VectorEditorKeyringGridMobilePathEditorF.editPath({
      keyring_editor: editor,
      path_el,
    });
  };

export const handleFooterPathDuplicateIconClick =
  ({ tab_el }) =>
  () =>
    duplicateFirstSelectedEl(tab_el);

export const handleFooterPathDeleteIconClick =
  ({ tab_el }) =>
  () =>
    deleteAllSelectedEls(tab_el);

export const handleFooterShapeSetColorIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const shape_el = go(
      editor.getSelectedEls(),
      filterL((el) => new Set(['circle']).has(el.nodeName)),
      head,
    );
    if (shape_el == null) {
      return;
    }

    return MuiF.openFrame(SVGEditorColorAndBorderMuiF.frame, (frame, page, [color_tab, border_tab]) => {
      frame.___selected_el = shape_el;
      frame.__mp_sticker_editor = editor;
      const one_line_length = $width(window) >= 640 ? 16 : 8;
      color_tab.makeData = () => ({ svg_el: shape_el, one_line_length });
      border_tab.makeData = () => ({
        svg_el: shape_el,
        one_line_length,
      });
    });
  };

export const handleFooterShapeSetStrokeIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const shape_el = go(
      editor.getSelectedEls(),
      filterL((el) => new Set(['circle']).has(el.nodeName)),
      head,
    );
    if (shape_el == null) {
      return;
    }

    return MuiF.openFrame(SVGEditorColorAndBorderMuiF.frame, (frame, page, [color_tab, border_tab]) => {
      frame.___selected_el = shape_el;
      frame.__mp_sticker_editor = editor;
      border_tab.selected = true;
      const one_line_length = $width(window) >= 640 ? 16 : 8;
      color_tab.makeData = () => ({ svg_el: shape_el, one_line_length });
      border_tab.makeData = () => ({
        svg_el: shape_el,
        one_line_length,
      });
    });
  };

export const handleFooterShapeDuplicateIconClick =
  ({ tab_el }) =>
  () =>
    duplicateFirstSelectedEl(tab_el);

export const handleFooterShapeDeleteIconClick =
  ({ tab_el }) =>
  () =>
    deleteAllSelectedEls(tab_el);

export const handleFooterTextDecorateTextIconClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;
    const fonts = tab_el.__mp_fonts;

    const text_el = go(
      editor.getSelectedEls(),
      filterL((el) => el.nodeName === 'text'),
      head,
    );
    if (text_el == null) {
      return;
    }
    return MuiF.openFrame(SVGEditorTextFrameMuiF.frame, (frame, page, [text_tab, color_tab, border_tab]) => {
      frame.___selected_el = text_el;
      frame.__fonts = fonts;
      frame.__mp_sticker_editor = editor;
      text_tab.makeData = () => ({ svg_el: text_el, fonts });

      color_tab.makeData = () => ({
        svg_el: text_el,
        one_line_length: $width(window) >= 640 ? 16 : 8,
      });
      border_tab.makeData = () => ({
        svg_el: text_el,
        one_line_length: $width(window) >= 640 ? 16 : 8,
      });
    });
  };

export const handleFooterTextOpenKeyboardIconClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const text_el = go(
      editor.getSelectedEls(),
      filterL((el) => el.nodeName === 'text'),
      head,
    );
    if (text_el == null) {
      return;
    }

    SVGEditorMobileTextInputF.create({ text_el, sticker_editor: editor });
    return MuiF.openFrame(SVGEditorMobileTextInputMuiF.frame, (frame) => {
      frame.closed = () => {
        SVGEditorMobileTextInputF.destroy();
        if (text_el.innerHTML.trim().length <= 0) {
          editor.removeEl(text_el);
        }
      };
    });
  };

export const handleFooterTextDuplicateIconClick =
  ({ tab_el }) =>
  () => {
    duplicateFirstSelectedEl(tab_el);
  };

export const handleFooterTextDeleteIconClick =
  ({ tab_el }) =>
  () => {
    deleteAllSelectedEls(tab_el);
  };

export const handleBottomMenuBlankPlateClick =
  ({ tab_el }) =>
  () => {
    hideBottomMenuBlankPlate(tab_el);
    deactivateBottomMenus(tab_el);
    deactivateFooterAddIcon(tab_el);
  };

export const handleBottomMenuTextIconClick =
  ({ tab_el }) =>
  () => {
    hideBottomMenuBlankPlate(tab_el);
    deactivateBottomMenus(tab_el);
    deactivateFooterAddIcon(tab_el);
    makeNewText(tab_el);
  };

export const handleBottomMenuPathIconClick =
  ({ tab_el }) =>
  () => {
    hideBottomMenuBlankPlate(tab_el);
    deactivateBottomMenus(tab_el);
    deactivateFooterAddIcon(tab_el);
    makeNewPath(tab_el);
  };

export const handleBottomMenuShapeIconClick =
  ({ tab_el }) =>
  async () => {
    hideBottomMenuBlankPlate(tab_el);
    deactivateBottomMenus(tab_el);
    deactivateFooterAddIcon(tab_el);

    tab_el.__mp_is_prevent_touch_move = false;
    try {
      await VectorEditorFreeShapeMobileF.useFreeShape({
        postProcess: (el) => {
          if (tab_el.__mp_keyring_editor != null) {
            const editor = tab_el.__mp_keyring_editor;
            editor.addEl(el);
            editor.scaleWithinArtBoard({ el, scale: 0.4 });
            const els = [el];
            editor.alignToHorizontalCenter(els);
            editor.alignToVerticalCenter(els);
            editor.setStrokeWidth({ el, stroke_width: 0 });
            editor.setStrokeDasharray({ el, stroke_dasharray: [0, 0] });
            editor.selectEls(els);
          }
        },
      });
    } finally {
      tab_el.__mp_is_prevent_touch_move = true;
    }
  };

export const handleBottomMenuImageIconClick =
  ({ tab_el }) =>
  async () => {
    hideBottomMenuBlankPlate(tab_el);
    deactivateBottomMenus(tab_el);
    deactivateFooterAddIcon(tab_el);
    await SVGEditorMobileImageUploadF.uploadImage({
      postProcess: (el) => {
        if (tab_el.__mp_keyring_editor != null) {
          const editor = tab_el.__mp_keyring_editor;
          editor.addEl(el);
          editor.scaleWithinArtBoard({ el, scale: 0.4 });
          const els = [el];
          editor.alignToHorizontalCenter(els);
          editor.alignToVerticalCenter(els);
          editor.selectEls(els);
        }
      },
    });
  };

export const handleKeyringEditorSelect =
  ({ tab_el }) =>
  (event) =>
    handleKeyringEditorSelectOrUnselect({ tab_el, event });

export const handleKeyringEditorUnselect =
  ({ tab_el }) =>
  (event) =>
    handleKeyringEditorSelectOrUnselect({ tab_el, event });

export const handleKeyringEditorLock =
  ({ tab_el }) =>
  () =>
    handleKeyringEditorLockOrUnlock({ tab_el });

export const handleKeyringEditorUnlock =
  ({ tab_el }) =>
  () =>
    handleKeyringEditorLockOrUnlock({ tab_el });

export const handleKeyringEditorAdd =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_empty_template_el != null) {
      tab_el.__mp_empty_template_el.setAttributeNS(null, 'display', 'none');
    }
  };

export const handleKeyringEditorRemove =
  ({ tab_el }) =>
  () => {
    const count = tab_el.__mp_keyring_editor?.getGraphicsEls?.()?.size;
    if (count != null && count <= 0 && tab_el.__mp_empty_template_el != null) {
      tab_el.__mp_empty_template_el.removeAttributeNS(null, 'display');
    }
  };

const TYPE_NONE_TOP_MENU_ID = {
  ACTIVATE: new Set(),
  DEACTIVATE: new Set([
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.DELETE.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.GROUP.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.UNGROUP.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.LOCK.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
};
const TYPE_SINGLE_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.DELETE.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.LOCK.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.BACKWARD.ID,
  ]),
  DEACTIVATE: new Set([
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.UNGROUP.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.GROUP.ID,
  ]),
};
const TYPE_GROUP_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.DELETE.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.LOCK.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.UNGROUP.ID,
  ]),
  DEACTIVATE: new Set([VectorEditorKeyringGridMobileConstantS.TOP_MENU.GROUP.ID]),
};
const TYPE_MULTIPLE_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.DELETE.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.GROUP.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.LOCK.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
  DEACTIVATE: new Set([
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorKeyringGridMobileConstantS.TOP_MENU.UNGROUP.ID,
  ]),
};

function handleKeyringEditorSelectOrUnselect({ tab_el, event }) {
  const editor = tab_el.__mp_keyring_editor;
  const { selected_els } = event.detail ?? {};

  if (editor == null || selected_els == null) {
    showTypeNoneFooters(tab_el);
    hideBottomMenuBlankPlate(tab_el);
    showBottomMenus(tab_el);
    deactivateBottomMenus(tab_el);
    activateTopMenus({
      tab_el,
      activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
      deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
    });
    return;
  }

  const count = selected_els?.size;
  if (count != null && count > 0) {
    editor.turnOffArtBoardClipPath();
  } else {
    editor.turnOnArtBoardClipPath();
  }

  if (count == null || count <= 0) {
    showTypeNoneFooters(tab_el);
    hideBottomMenuBlankPlate(tab_el);
    showBottomMenus(tab_el);
    deactivateBottomMenus(tab_el);
    activateTopMenus({
      tab_el,
      activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
      deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
    });
    return;
  }

  if (count >= 2) {
    hideBottomMenuBlankPlate(tab_el);
    hideBottomMenus(tab_el);
    deactivateBottomMenus(tab_el);
    hideAllFooters(tab_el);
    activateTopMenus({
      tab_el,
      activate_ids: TYPE_MULTIPLE_TOP_MENU_ID.ACTIVATE,
      deactivate_ids: TYPE_MULTIPLE_TOP_MENU_ID.DEACTIVATE,
    });
    return;
  }

  const selected_el = selected_els.values().next().value;

  if (selected_el == null) {
    showTypeNoneFooters(tab_el);
    hideBottomMenuBlankPlate(tab_el);
    showBottomMenus(tab_el);
    deactivateBottomMenus(tab_el);
    activateTopMenus({
      tab_el,
      activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
      deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
    });
    return;
  }

  const selected_el_tag_name = selected_el.tagName;

  if (selected_el_tag_name == null) {
    showTypeNoneFooters(tab_el);
    hideBottomMenuBlankPlate(tab_el);
    showBottomMenus(tab_el);
    deactivateBottomMenus(tab_el);
    activateTopMenus({
      tab_el,
      activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
      deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
    });
    return;
  }

  if (selected_el_tag_name === 'text') {
    showTypeTextFooters(tab_el);
    hideBottomMenuBlankPlate(tab_el);
    hideBottomMenus(tab_el);
    deactivateBottomMenus(tab_el);
    activateTopMenus({
      tab_el,
      activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
      deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
    });
    return;
  }

  if (selected_el_tag_name === 'path') {
    showTypePathFooters(tab_el);
    hideBottomMenuBlankPlate(tab_el);
    hideBottomMenus(tab_el);
    deactivateBottomMenus(tab_el);
    activateTopMenus({
      tab_el,
      activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
      deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
    });
    return;
  }

  if (selected_el_tag_name === 'circle') {
    showTypeShapeFooters(tab_el);
    hideBottomMenuBlankPlate(tab_el);
    hideBottomMenus(tab_el);
    deactivateBottomMenus(tab_el);
    activateTopMenus({
      tab_el,
      activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
      deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
    });
    return;
  }

  if (editor.getIsGroupEl(selected_el)) {
    hideBottomMenuBlankPlate(tab_el);
    hideBottomMenus(tab_el);
    deactivateBottomMenus(tab_el);
    hideAllFooters(tab_el);
    activateTopMenus({
      tab_el,
      activate_ids: TYPE_GROUP_TOP_MENU_ID.ACTIVATE,
      deactivate_ids: TYPE_GROUP_TOP_MENU_ID.DEACTIVATE,
    });
    return;
  }

  showTypeObjectFooters(tab_el);
  hideBottomMenuBlankPlate(tab_el);
  hideBottomMenus(tab_el);
  deactivateBottomMenus(tab_el);
  activateTopMenus({
    tab_el,
    activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
    deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
  });
}

function handleKeyringEditorLockOrUnlock({ tab_el }) {
  if (tab_el.__mp_keyring_editor == null) {
    return;
  }
  const editor = tab_el.__mp_keyring_editor;

  const lock_count = editor.getLockedEls().size;
  setLockCount({ tab_el, count: lock_count });
  lock_count <= 0 ? deactivateTopMenuUnlockIcon({ tab_el }) : activateTopMenuUnlockIcon({ tab_el });
}
