import { omit } from 'fxjs/es';

export const RECEIPT_STATUS = {
  done: 'done',
  need_cancel: 'need_cancel',
  is_canceled: 'is_canceled',
  receipt_error: 'receipt_error',
  need_reissue: 'need_reissue',
  need_issue: 'need_issue',
  cancel: 'cancel',
};

export const getReceiptStatus = (receipt) => {
  if (!receipt) return '';
  if (receipt.type === 0) return '';

  // if (receipt.receipt_error) return RECEIPT_STATUS.receipt_error;
  /*발급 실패*/

  if (receipt.done) return RECEIPT_STATUS.done;

  /*발행 완료*/
  if (receipt.cancel) {
    if (receipt.cancel.need_cancel) return RECEIPT_STATUS.need_cancel;
    /*취소 요청*/
    if (receipt.cancel.is_canceled) return RECEIPT_STATUS.is_canceled;
    /*취소 완료*/
  }
  if (receipt.need_reissue) return RECEIPT_STATUS.need_reissue;
  /*재발행 필요*/
  return RECEIPT_STATUS.need_issue;
  /*발행 필요*/
};

export const updateReceiptStatus = (receipt, status) => {
  if (!receipt) return receipt;
  if (receipt.type === 0) return receipt;

  const _receipt = omit(
    [RECEIPT_STATUS.done, RECEIPT_STATUS.cancel, RECEIPT_STATUS.receipt_error, RECEIPT_STATUS.need_reissue],
    receipt,
  );
  if (status === RECEIPT_STATUS.done) {
    _receipt.done = true;
    _receipt.registDate = new Date();
  }
  if (status === RECEIPT_STATUS.need_cancel) {
    _receipt.cancel = {
      need_cancel: true,
      is_canceled: false,
    };
  }
  if (status === RECEIPT_STATUS.is_canceled) {
    _receipt.cancel = {
      need_cancel: false,
      is_canceled: true,
    };
    _receipt.registDate = null;
  }
  if (status === RECEIPT_STATUS.need_reissue) {
    _receipt.need_reissue = true;
  }
  return _receipt;
};
