import { html } from 'fxjs/es';

export const informationIcon = () => {
  return html`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
      fill="#FF6B00"
      stroke="#FF6B00"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6798 5.71997L10.5743 11.7551H9.41413L9.30866 5.71997H10.6798ZM9.16804 13.4543C9.16218 12.9973 9.54304 12.6223 10.0001 12.6223C10.4512 12.6223 10.8262 12.9973 10.8321 13.4543C10.8262 13.9114 10.4512 14.2805 10.0001 14.2864C9.54304 14.2805 9.16218 13.9114 9.16804 13.4543Z"
      fill="white"
    />
  </svg>`;
};

export const informationIconAlt = () => {
  return html` <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M2.30078 12C2.30078 6.64284 6.64362 2.3 12.0008 2.3C17.3579 2.3 21.7008 6.64284 21.7008 12C21.7008 17.3572 17.3579 21.7 12.0008 21.7C6.64362 21.7 2.30078 17.3572 2.30078 12Z"
      stroke="#FF6B00"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.8141 6.864L12.6876 14.1062H11.2954L11.1688 6.864H12.8141ZM11.0001 16.1452C10.993 15.5968 11.4501 15.1468 11.9985 15.1468C12.5399 15.1468 12.9899 15.5968 12.997 16.1452C12.9899 16.6937 12.5399 17.1367 11.9985 17.1437C11.4501 17.1367 10.993 16.6937 11.0001 16.1452Z"
      fill="#FF6B00"
    />
  </svg>`;
};
