import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { isApp } from './util.js';
import { entries, go, isNil, join, mapL, rejectL } from 'fxjs/es';

export const openApp = ({ screen, uri, title, titleImage, is_home }) => {
  const query = go(
    { title, uri, titleImage },
    entries,
    rejectL(([, v]) => isNil(v)),
    mapL(([k, v]) => `${k}=${v}`),
    join('&'),
  );

  if (isApp()) return;

  const app_scheme = is_home ? 'home' : `${screen}?${query}`;
  if (UtilF.is_ios()) {
    location.href = `marppleshop://${app_scheme}`;
  } else if (UtilF.is_android()) {
    location.href = `intent://${app_scheme}#Intent;scheme=marppleshop;package=com.marppleshop;end`;
  }
};
