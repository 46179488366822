import { go, map } from 'fxjs/es';
import { changeDpiDataUrl } from 'changedpi';
import { NewMakerPrintResultF } from '../../../../PrintResult/F/Function/module/NewMakerPrintResultF.js';
import { NewMakerBaseProductsWebGlProcessingF } from '../../../WebGlProcessing/F/Function/module/NewMakerBaseProductsWebGlProcessingF.js';

export const makePrintableFiles = async ({
  base_product_size_id,
  base_product_color_id,
  product_faces2,
  title,
}) => {
  const { template_meta } = await $.get('/@api/prerequisite_maker/base_product_size', {
    id: base_product_size_id,
  });
  return go(
    NewMakerPrintResultF.makePrintByBpsTemplateMeta({
      base_product_size_id,
      template_meta,
      product_faces2,
      base_product_color_id,
      title,
    }),
    map(async ({ canvas }) => {
      const px_per_cm = (1 / 2.54) * template_meta.dpi;
      const data_url = canvas.toDataURL(template_meta.mimetype, 1);
      const glue_layer_data_url = await NewMakerBaseProductsWebGlProcessingF.getGlueLayer({
        uri: data_url,
        removalColor: [1.0, 1.0, 1.0],
        colorDistancePercent: 0.0,
        offsetPixel: Math.floor(0.1 * px_per_cm),
        color: [0.0, 0.0, 0.0, 1.0],
      });
      return {
        title: NewMakerPrintResultF.makeTitleByKeys(title, template_meta.text.file_name),
        dpi: template_meta.dpi,
        mimetype: template_meta.mimetype,
        data_url: changeDpiDataUrl(canvas.toDataURL(template_meta.mimetype, 1), template_meta.dpi),
        glue_layer_data_url: changeDpiDataUrl(glue_layer_data_url, template_meta.dpi),
      };
    }),
  );
};
