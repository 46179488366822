import { compact, each, find, go, map } from 'fxjs/es';
import { resetCvMaskBySf } from '../../../../Maker/F/SizeFaces/fs.js';
import { createFcanvas } from '../../../../Maker/F/util.js';
import { makeCvBpcfInMakerA } from '../../../../Maker/F/CvBpcf/fs.js';

async function makeCvMask1InMakerA({ mask_url, url, size_face, is_thumb }) {
  const canvas = createFcanvas(document.createElement('canvas'));
  await makeCvBpcfInMakerA(canvas, { url }, size_face);
  G.mp.maker.cv_bpcf(canvas).visible = false;
  const randomBetween = () => 100 + Math.floor(Math.random() * (256 - 100));
  const r = randomBetween();
  const g = randomBetween();
  const b = randomBetween();
  const rect = new window.fabric.Rect({
    selectable: false,
    evented: false,
    visible: true,
    fill: is_thumb ? `rgb(255, 0, 0)` : `rgb(${r}, ${g}, ${b}, 1)`,
    top: 0,
    left: 0,
    width: canvas.width,
    height: canvas.height,
  });
  canvas.add(rect);
  return new Promise(function (resolve) {
    fabric.Image.fromURL(G.mp.maker.url(mask_url), function (img) {
      img.set({
        visible: true,
        selectable: false,
        evented: false,
        globalCompositeOperation: is_thumb ? 'destination-in' : 'destination-out',
      });
      canvas.add(img);
      resetCvMaskBySf(img, size_face);
      canvas.renderAll();
      resolve(canvas);
    });
  });
}

export const compactAllPhoneCaseInCanvasByFilterItem = async ({ bp_id, filter_item_name }) => {
  const result = await $.get('/@api/base_product_color_faces/get_by_bp_filter', {
    bp_id,
    filter_item_name,
  });
  if (!result?.bp_and_mask_urls?.length) throw new Error('bp_and_mask_urls 없음');
  const representative = await go(
    result.bp_and_mask_urls,
    find((a) => a.bp_id === bp_id),
    async ({ mask_url, name, url, size_faces }) => {
      if (!mask_url) throw new Error('mask_url 없움');
      if (!size_faces) throw new Error('size_faces 없움');
      return {
        name,
        fcanvas: createFcanvas(document.createElement('canvas')),
        print_area: size_faces[0].print.px,
      };
    },
  );
  const representative_cv_print_area = representative.print_area;
  const representative_cv_print_area_center = {
    x: representative_cv_print_area.left + representative_cv_print_area.width / 2,
    y: representative_cv_print_area.top + representative_cv_print_area.height / 2,
  };
  const text = new fabric.Text(representative.name, {
    top: 0,
    left: 0,
  });
  text.scaleToWidth(500);
  representative.fcanvas.add(text);

  await go(
    result.bp_and_mask_urls,
    map(async ({ bp_id, mask_url, url, size_faces }) => {
      try {
        if (!mask_url) throw new Error('mask_url 없움');
        if (!size_faces) throw new Error('size_faces 없움');
        const fcanvas = await makeCvMask1InMakerA({ mask_url, url, size_face: size_faces[0] });
        const print_area = size_faces[0].print.px;
        return new Promise((resolve, reject) => {
          fabric.Image.fromURL(fcanvas.toDataURL(print_area), function (img) {
            const ratio = representative_cv_print_area.width / img.width;
            const top = 0 - print_area.height / 2;
            const left = 0 - print_area.width / 2;
            img.top = representative_cv_print_area_center.y + top * ratio;
            img.left = representative_cv_print_area_center.x + left * ratio;
            img.scaleX *= ratio;
            img.scaleY *= ratio;
            img.opacity = 1;
            resolve(img);
          });
        });
      } catch (e) {
        console.error(e);
        console.error(bp_id);
      }
    }),
    compact,
    each((img) => {
      representative.fcanvas.add(img);
    }),
    async () => {
      await go(
        result.bp_and_mask_urls,
        find((a) => a.bp_id === bp_id),
        async ({ bp_id, mask_url, url, size_faces }) => {
          const fcanvas = await makeCvMask1InMakerA({
            mask_url,
            url,
            size_face: size_faces[0],
            is_thumb: true,
          });
          const print_area = size_faces[0].print.px;
          return new Promise((resolve, reject) => {
            fabric.Image.fromURL(fcanvas.toDataURL(print_area), function (img) {
              const ratio = representative_cv_print_area.width / img.width;
              const top = 0 - print_area.height / 2;
              const left = 0 - print_area.width / 2;
              img.top = representative_cv_print_area_center.y + top * ratio;
              img.left = representative_cv_print_area_center.x + left * ratio;
              img.scaleX *= ratio;
              img.scaleY *= ratio;
              img.opacity = 0.7;
              representative.fcanvas.add(img);
              resolve();
            });
          });
        },
      );
      const a = document.createElement('a');
      a.href = representative.fcanvas.toDataURL();
      a.download = representative.name;
      document.body.appendChild(a);
      a.click();
    },
  );
};

// G.aaa_main = () =>
//   go(
//     [
//       { bp_id: 5466, filter_item_name: '한지가죽 스킨' },
//       { bp_id: 4970, filter_item_name: '하드' },
//       { bp_id: 4512, filter_item_name: '스트랩' },
//       { bp_id: 5020, filter_item_name: '범퍼' },
//       { bp_id: 4453, filter_item_name: '슬라이드' },
//       { bp_id: 4969, filter_item_name: '젤리' },
//       { bp_id: 4499, filter_item_name: '투명 범퍼' },
//       { bp_id: 4642, filter_item_name: '하드' },
//       { bp_id: 4267, filter_item_name: '스트랩' },
//       { bp_id: 4246, filter_item_name: '범퍼' },
//       { bp_id: 4076, filter_item_name: '슬라이드' },
//       { bp_id: 4616, filter_item_name: '젤리' },
//       { bp_id: 4120, filter_item_name: '투명 범퍼' },
//       { bp_id: 3192, filter_item_name: '하드' },
//       { bp_id: 3379, filter_item_name: '젤리' },
//     ],
//     each(({ bp_id, filter_item_name }) => main({ bp_id, filter_item_name })),
//   );
