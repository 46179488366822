import { each, entries, go, ippL } from 'fxjs/es';

function _hasSameObject(src_obj, target_obj) {
  if (!target_obj) return;
  return go(
    src_obj,
    entries,
    each(([k, v]) => {
      if (typeof target_obj[k] === 'object' && typeof v === 'object') return testSameObject(v, target_obj[k]);
      if (!target_obj[k]) throw new Error(`no---${k}----${JSON.stringify(src_obj[k])}`);
      return target_obj[k];
    }),
  );
}

export const testSameObject = function (src_obj, target_obj) {
  if (Array.isArray(src_obj) && Array.isArray(target_obj)) {
    return go(
      src_obj,
      ippL,
      each(([i, src_obj]) => _hasSameObject(src_obj, target_obj[i])),
    );
  }
  if (typeof src_obj === 'object' && typeof target_obj === 'object') {
    return _hasSameObject(src_obj, target_obj);
  }
  throw new Error(
    `isSameObject argument type error src_obj: ${typeof src_obj}, target_obj:${typeof target_obj}`,
  );
};
