import { getCurrentFcanvas } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { getItsSf } from '../../../../../Maker/F/getSth.js';

export function setSizeByHoleAndPxPer1cm(cv_obj) {
  const { px_per_1cm, print } = getItsSf(getCurrentFcanvas());
  const hole_position = print?.etc_meta?.hole_position;
  if (!hole_position) return;
  const width = cv_obj._data.image_width_cm * px_per_1cm;
  cv_obj.scaleToWidth(width);
  let top = hole_position.y;
  let left = hole_position.x;
  top -= cv_obj._data.hole_position.y * (cv_obj.height * cv_obj.scaleY);
  left -= cv_obj._data.hole_position.x * width;
  top += (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2;
  cv_obj.set({ top, left });
}
