import { all, each, go, map, not, pick, sel, string, values, zip } from 'fxjs/es';
import { $attr, $closest, $find, $setVal } from 'fxdom/es';
import { evaluateColorBrightness } from '../../../Canvas/S/util.js';
import { makeCompositeMaskHtml } from '../S/composite_template_maker_tmpl.js';
import { selectAssoCompositeTemplate, updateCompositeFaces, updateCompositeTemplate } from './fs.js';

export function getFrameTag(ct) {
  return go(ct, $closest('.don_frame'), $attr('frame_tag'));
}

export function renderUploadTmpl(ct, url) {
  const img_input_wrapper = go(ct, $closest('.img_input_wrapper'));
  img_input_wrapper.dataset.has_img = !!url;
  if (url) {
    go(img_input_wrapper, $find('.img_wrapper img'), (img) => (img.src = url));
  }
}

export async function uploadAssocFile(ct, key, box_data, table) {
  const data = await $.upload(ct);

  box_data[key] = data.url;
  renderUploadTmpl(ct, data.url);
  if (!table) await updateCompositeTemplate(pick(['id', key], box_data));
  else if (table === 'composite_faces') {
    await updateCompositeFaces(pick(['id', key], box_data));
  }
}

export function isNullBoxData(obj) {
  return go(obj, values, all, not);
}

export function renderCompositeMaskHtml(ct) {
  const composite_mask_el = go(ct, $closest('.composite_mask'));
  const composite_mask = box.sel(composite_mask_el);
  composite_mask_el.outerHTML = makeCompositeMaskHtml(
    composite_mask,
    box.sel('assoc_composite_template').bg_url,
  );
}

export function makeInputValue(ct, name, value) {
  go(ct, $closest('.content'), $find(`[name="${name}"]`), $setVal(value));
}

export function getBoxData(ct, class_name) {
  return go(ct, $closest(class_name), box.sel);
}

export function getClosestBoxData(ct) {
  return go(ct, $closest('[_sel]'), box.sel);
}

export async function initializeBoxDataAssocCompositeTemplate(composite_template_id) {
  if (!composite_template_id) return box.set('assoc_composite_template', {});
  const assoc_composite_template = await selectAssoCompositeTemplate(composite_template_id);
  box.set('assoc_composite_template', assoc_composite_template);
}

export function directDownload(url, fileName = 'downFile', mimeType) {
  if (url.indexOf('http') !== -1) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
  } else if (url.indexOf('base64') !== -1) {
    G.mp.maker.download(url, fileName, mimeType);
  }
}

export async function setAssocCompositeTemplateBaseProductColor(
  assoc_composite_template,
  base_product_color,
) {
  go(
    assoc_composite_template,
    sel('_.composite_masks'),
    each(async (cm) => {
      cm.base_product_color_id = base_product_color.id;
      cm._.base_product_color = base_product_color;
    }),
  );
  return assoc_composite_template;
}

export function invertColor(hex_color) {
  const hex = hex_color.replace('#', '');
  const hex_array = hex.match(/[a-f\d]{2}/gi);
  let r = (255 - parseInt(hex_array[0], 16)).toString(16);
  let g = (255 - parseInt(hex_array[1], 16)).toString(16);
  let b = (255 - parseInt(hex_array[2], 16)).toString(16);
  const zeroHandling = (val) => {
    if (val.length === 1) return 0 + val;
    else return val;
  };
  r = zeroHandling(r);
  g = zeroHandling(g);
  b = zeroHandling(b);
  return `#${r}${g}${b}`;
}

export function invertDarkColor(hex_color, dark_threshold) {
  const makeRGBArr = (hex_code) => hex_code.replace('#', '').match(/[a-f\d]{2}/gi);
  const compare = (input, threshold) =>
    parseInt(input, 16) < parseInt(threshold, 16)
      ? (255 - parseInt(input, 16)).toString(16)
      : parseInt(input, 16).toString(16);
  const zeroHandling = (val) => (val.length === 1 ? 0 + val : val);
  return (
    '#' +
    _p.go(
      zip(makeRGBArr(hex_color), makeRGBArr(dark_threshold)),
      map(([input, threshold]) => zeroHandling(compare(input, threshold))),
      string,
    )
  );
}

export function invertDarkColorToWhite(hex_color, dark_threshold) {
  const br = evaluateColorBrightness(hex_color, true);
  return br < dark_threshold ? '#ffffff' : '#000000';
}
