import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Core/Util/S/Function/module/OMPCoreUtilS.js';

export const cateSelectorTitle = ({ checked_cate_list, checked_cate_item }) => {
  const checked_name = checked_cate_item?.name || checked_cate_list.name;
  const tt_checked_cate_list = TT('product::list::list_8_1', {
    checked_cate_list_name: html`<button
      type="button"
      data-cate_list_id="${checked_cate_list?.id || ''}"
      data-cate_item_id="${checked_cate_item?.id || ''}"
      class="omp-product-list__checked-cate-list"
    >
      ${checked_name}
      <span class="omp-product-list__checked-cate-list-arrow"
        >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
      >
    </button>`,
    end_with: OMPCoreUtilS.isEndWithConsonant(checked_name) ? '을' : '를',
  });
  return html`<div class="omp-product-list__cate-lists">
    <div class="omp-product-list__select">${tt_checked_cate_list}</div>
  </div>`;
};

export const checkedFilterRestItem = () => {
  const tt_init = TT('product::list::list_20_1');
  return html`<button type="button" class="omp-product-list__checked-filter-item-init">
    <span class="omp-product-list__checked-filter-item-reset-icon">${OMPCoreAtomTmplS.resetIcon()}</span>
    <span>${tt_init}</span>
  </button>`;
};

export const checkedFilterItem = ({ id, filter_list_id, name }) => {
  return html`<button
    type="button"
    class="omp-product-list__checked-filter-item"
    data-id="${id}"
    data-filter_list_id="${filter_list_id}"
    data-name="${name}"
  >
    <span>${name}</span>
    <span class="omp-product-list__checked-filter-item-x-icon-wrapper">
      <span class="omp-product-list__checked-filter-item-x-icon"
        ><svg width="100%" height="100%" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Icon-Close-bk">
            <path
              id="Vector"
              d="M1.15625 0.843414L7.15625 6.84341M1.15625 6.84341L7.15625 0.843414"
              stroke="white"
              stroke-linecap="round"
            />
          </g>
        </svg>
      </span>
    </span>
  </button>`;
};

export const checkedFilterItemsWithReset = (checked_filter_items) => {
  return `${checkedFilterRestItem()}${strMap(checkedFilterItem, checked_filter_items)}`;
};
