import { resetBgWithoutCvMaskBySf } from '../SizeFaces/fs.js';
export function makeCvSafeAreaInMakerA(canvas, bpf_safe_area_url, sf) {
  const lastCvSafeArea = G.mp.maker.cv_safe_area(canvas);
  if (lastCvSafeArea) canvas.remove(lastCvSafeArea);
  return new Promise(function (resolve) {
    fabric.Image.fromURL(G.mp.maker.url(bpf_safe_area_url), function (img) {
      img.set({
        selectable: false,
        evented: false,
        _data: { cv_type: 'cv_safe_area', is_not_design: true },
      });
      img.visible = false;
      canvas.add(img);
      resetBgWithoutCvMaskBySf(img, sf);
      canvas.renderAll();
      resolve();
    });
  });
}
