import { html, strMap, zipWithIndexL } from 'fxjs/es';

export const findIdResultTmpl = (users) => {
  return html`
    <div class="find-id-result-wrapper">
      <div class="find-id-result">
        <div class="find-id-result__emails">
          <h2 class="find-id-result__sub-title">회원님의 정보가 일치하는 아이디 목록입니다.</h2>
          ${strMap(
            ([i, user]) => html`
              <label class="find-id-result__radio-label">
                ${user.email
                  ? html`<input
                      type="radio"
                      name="user"
                      value="${user.email}"
                      ${i === 0 ? ' checked' : ''}
                    /> `
                  : ''}
                <span
                  >${user.social_id ? `${user.email || '이메일 없음'}(페이스북 가입 계정)` : user.email}</span
                >
              </label>
            `,
            zipWithIndexL(users),
          )}
        </div>

        <div class="find-id-result__buttons">
          <button class="find-id-result__button reset_pw" type="button">비밀번호 재설정</button>
          <button class="find-id-result__button login" type="button">로그인</button>
        </div>
      </div>
    </div>
  `;
};
