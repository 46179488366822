export const svg_icons = {
  yesterday: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <rect x="4.7" y="7" style="fill:#53d173;" width="23" height="4" />
      <g style="fill:none;stroke:#000000;stroke-width:1.5;stroke-miterlimit:10;">
        <path d="M26.5,27.6H5.3c-0.7,0-1.2-0.6-1.2-1.2V7.7c0-0.7,0.6-1.2,1.2-1.2h21.1c0.7,0,1.2,0.6,1.2,1.2v18.7C27.7,27,27.1,27.6,26.5,27.6z"/>
        <line x1="3.7" y1="11.7" x2="27.3" y2="11.7"/>
        <ellipse style="fill:#FFFFFF;" cx="11" cy="6.4" rx="1.6" ry="2"/>
        <ellipse style="fill:#FFFFFF;" cx="20.9" cy="6.4" rx="1.6" ry="2"/>
        <g style="stroke-linecap:round;">
          <line x1="9.4" y1="19.9" x2="14.8" y2="19.9"/>
          <polyline points="18.8,15.5 21.2,15.5 21.2,23"/>
          <line x1="18.5" y1="23.4" x2="24" y2="23.4"/>
        </g>
      </g>
    </svg>
  `,
  tomorrow: `
   <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <rect x="4.7" y="7" style="fill:#53d173;" width="23" height="4" />
      <g style="fill:none;stroke:#000000;stroke-width:1.5;stroke-miterlimit:10;">
        <path d="M26.5,27.6H5.3c-0.7,0-1.2-0.6-1.2-1.2V7.7c0-0.7,0.6-1.2,1.2-1.2h21.1c0.7,0,1.2,0.6,1.2,1.2v18.7C27.7,27,27.1,27.6,26.5,27.6z"/>
        <line x1="3.7" y1="11.7" x2="27.3" y2="11.7"/>
        <ellipse style="fill:#FFFFFF;" cx="11" cy="6.4" rx="1.6" ry="2"/>
        <ellipse style="fill:#FFFFFF;" cx="20.9" cy="6.4" rx="1.6" ry="2"/>
        <g style="stroke-linecap:round;">
          <line x1="9.4" y1="19.9" x2="14.8" y2="19.9"/>
          <line x1="12.1" y1="17" x2="12.1" y2="23"/>
          <polyline points="18.8,15.5 21.2,15.5 21.2,23"/>
          <line x1="18.5" y1="23.4" x2="24" y2="23.4"/>
        </g>
      </g>
    </svg>
  `,
  refresh: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path style="fill:#32BEA6;" d="M2,32C2,15.43,15.43,2,32,2c16.57,0,30,13.43,30,30c0,16.57-13.43,30-30,30C15.43,62,2,48.57,2,32z"/>
      <path style="fill:#FFFFFF;" d="M53.77,22.89c-1.25,0-2.5,0.01-3.75,0.01c-2.81-6.31-8.34-10.87-15.32-11.96
        c-7.77-1.22-15.21,2.39-19.65,8.74c-1.97,2.82,2.66,5.46,4.61,2.66c6.08-8.71,19.08-7.92,24.42,0.57c-1.16,0-2.31,0.01-3.47,0.01
        c-0.32,0-0.55,0.14-0.7,0.35c-0.23,0.25-0.32,0.61-0.08,0.99c2.2,3.42,4.4,6.83,6.6,10.25c0.37,0.57,1.17,0.57,1.54-0.01
        c2.18-3.43,4.37-6.85,6.55-10.28C54.9,23.66,54.42,22.89,53.77,22.89z"/>
      <path style="fill:#FFFFFF;" d="M44.35,41.65c-6.04,8.65-18.88,7.94-24.29-0.37c1.11,0,2.22-0.01,3.32-0.01
        c0.32,0,0.55-0.14,0.69-0.35c0.23-0.25,0.32-0.61,0.08-0.99c-2.2-3.42-4.4-6.83-6.6-10.25c-0.37-0.57-1.17-0.57-1.54,0.01
        c-2.18,3.43-4.37,6.85-6.55,10.28c-0.36,0.57,0.11,1.33,0.76,1.33c1.29,0,2.58-0.01,3.87-0.01c2.84,6.21,8.32,10.68,15.22,11.76
        c7.77,1.22,15.21-2.39,19.65-8.74C50.94,41.49,46.31,38.85,44.35,41.65z"/>
    </svg>
  `,
  toggle_indicator: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
      <path
        d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"
      ></path>
    </svg>
  `,
  search: `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 485.6 485.6">
        <path style="fill:#E64C3D;"
          d="M428.4,456l27.5-27.5c3.6-3.6,3.6-9.4,0-13.1L320.6,280.1l-40.5,40.5L415.4,456C419,459.6,424.8,459.6,428.4,456z"      />
        <path style="fill:#2C2F33;"
          d="M170.8,83.4c-48.2,0-87.5,39.3-87.5,87.5c0,4.5,3.7,8.3,8.3,8.3c4.5,0,8.3-3.7,8.3-8.3c0-39.2,31.8-71,71-71c4.5,0,8.3-3.7,8.3-8.3C179,87,175.3,83.4,170.8,83.4z"/>
        <path style="fill:#2C2F33;stroke:#000000;stroke-width:6;stroke-miterlimit:10;"
          d="M326.4,274.3c-3.2-3.2-8.4-3.2-11.6,0
            l-14.4,14.4l-19.1-19.1c23.5-26.2,37.8-60.9,37.8-98.8c0-81.7-66.5-148.2-148.2-148.2S22.6,89.1,22.6,170.8S89.1,319,170.8,319
            c37.9,0,72.5-14.3,98.8-37.8l19.1,19.1l-14.4,14.4c-1.5,1.5-2.4,3.6-2.4,5.8c0,2.2,0.9,4.3,2.4,5.8L403,455.1
            c5.1,5.1,11.8,7.9,19,7.9c7.2,0,13.9-2.8,19-7.9l14.2-14.2c5.1-5.1,7.9-11.8,7.9-19c0-7.2-2.8-13.9-7.9-19L326.4,274.3z M39.1,170.8
            c0-72.6,59.1-131.8,131.8-131.8s131.8,59.1,131.8,131.8s-59.1,131.8-131.8,131.8S39.1,243.5,39.1,170.8z M443.5,429.3l-14.2,14.2
            c-2,2-4.5,3.1-7.3,3.1s-5.4-1.1-7.3-3.1L291.7,320.7l28.9-28.9l122.9,122.9c2,2,3.1,4.5,3.1,7.3
            C446.6,424.8,445.4,427.4,443.5,429.3z"/>
      </svg>
    `,
  force_search: `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 485.6 485.6">
    <path style="fill:#E64C3D;" d="M428.4,456l27.5-27.5c3.6-3.6,3.6-9.4,0-13.1L320.6,280.1l-40.5,40.5L415.4,456
        C419,459.6,424.8,459.6,428.4,456z"/>
    <path style="fill:#2C2F33;" d="M170.8,83.4c-48.2,0-87.5,39.3-87.5,87.5c0,4.5,3.7,8.3,8.3,8.3c4.5,0,8.3-3.7,8.3-8.3
        c0-39.2,31.8-71,71-71c4.5,0,8.3-3.7,8.3-8.3C179,87,175.3,83.4,170.8,83.4z"/>
    <path style="fill:#2C2F33;stroke:#000000;stroke-width:6;stroke-miterlimit:10;" d="M326.4,274.3c-3.2-3.2-8.4-3.2-11.6,0
        l-14.4,14.4l-19.1-19.1c23.5-26.2,37.8-60.9,37.8-98.8c0-81.7-66.5-148.2-148.2-148.2S22.6,89.1,22.6,170.8S89.1,319,170.8,319
        c37.9,0,72.5-14.3,98.8-37.8l19.1,19.1l-14.4,14.4c-1.5,1.5-2.4,3.6-2.4,5.8c0,2.2,0.9,4.3,2.4,5.8L403,455.1
        c5.1,5.1,11.8,7.9,19,7.9c7.2,0,13.9-2.8,19-7.9l14.2-14.2c5.1-5.1,7.9-11.8,7.9-19c0-7.2-2.8-13.9-7.9-19L326.4,274.3z M39.1,170.8
        c0-72.6,59.1-131.8,131.8-131.8s131.8,59.1,131.8,131.8s-59.1,131.8-131.8,131.8S39.1,243.5,39.1,170.8z M443.5,429.3l-14.2,14.2
        c-2,2-4.5,3.1-7.3,3.1s-5.4-1.1-7.3-3.1L291.7,320.7l28.9-28.9l122.9,122.9c2,2,3.1,4.5,3.1,7.3
        C446.6,424.8,445.4,427.4,443.5,429.3z"/>
    <linearGradient gradientUnits="userSpaceOnUse" x1="-62.6854" y1="638.0634" x2="-61.7804" y2="638.0634" gradientTransform="matrix(332.0951 -92.1951 -107.2486 -386.3193 89332.9219 241004.6094)">
        <stop  offset="0" style="stop-color:#FAFF00"/>
        <stop  offset="1" style="stop-color:#FFFF00"/>
    </linearGradient>
    <polygon style="opacity:0.71;fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_1_);stroke:#D70900;stroke-width:15.2327;" points="
        130.5,455.3 114,395.7 163.5,310.3 143,315.3 126.2,246.8 335.3,26.9 357.4,106.5 312.7,170.8 341,169.1 357.9,229.9 143.9,460.1 
        133.2,465 "/>
    <linearGradient gradientUnits="userSpaceOnUse" x1="-62.6606" y1="638.0571" x2="-61.8052" y2="638.0571" gradientTransform="matrix(332.0951 -92.1951 -107.2486 -386.3193 89332.9219 241004.6094)">
        <stop  offset="0" style="stop-color:#FAFF00"/>
        <stop  offset="1" style="stop-color:#FFFF00"/>
    </linearGradient>
    <path style="opacity:0.71;fill-rule:evenodd;clip-rule:evenodd;fill:yellow;stroke:#D70900;stroke-width:15.2327;" d="M138.8,453l209.6-225.5L334.6,178l-39,2.5l52.5-75.6l-17-61.1L135.6,249.3l13.7,55.7l31.7-7.7l-57.7,99.7L138.8,453L138.8,453z"/>
    <path style="opacity:0.71;fill-rule:evenodd;clip-rule:evenodd;fill:#FFB500;" d="M142.6,441.3l-11.1-41.9l197.4-216.2
        l11.9,43L142.6,441.3L142.6,441.3z"/>
    <path style="opacity:0.71;fill-rule:evenodd;clip-rule:evenodd;fill:#F5A700;" d="M154.3,297.5l41.3-11.5l37.2-58.4
        l-89.2,24.8L154.3,297.5z"/>
    <path style="opacity:0.71;fill-rule:evenodd;clip-rule:evenodd;fill:#FFA000;" d="M246,191.9l82.2-133.1l11.9,43
        l-59.3,87.6L246,191.9z"/>
  </svg>
  `,
  return: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.7 490.7" >
      <path style="stroke:#000000;stroke-width:5;stroke-miterlimit:10;" d="M337.9,160.3H62.5l77.4-78.2c3.5-3.8,3.5-9.7-0.2-13.3
         c-3.6-3.5-9.3-3.5-13,0l-93.3,94.3c-3.6,3.7-3.6,9.6,0,13.3l93.3,94.3c3.7,3.6,9.6,3.5,13.2-0.2c3.5-3.7,3.5-9.4,0-13.1
         l-77.4-78.3h275.4c57,0,103.4,50.8,103.4,113.2S395,405.6,337.9,405.6h-83c-4.7,0-8.9,3.4-9.5,8c-0.8,5.8,3.6,10.8,9.2,10.8h83.2
         c67.3,0,122.1-59.2,122.1-132.1S405.2,160.3,337.9,160.3z"/>
    </svg>
  `,
  fullscreen: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <rect x="16.97" y="17.64" style="fill:#CEE8FA;" width="44.61" height="44.61"/>
      <g>
        <path style="fill:#2D527C;" d="M61.58,15.68H16.97c-1.08,0-1.96,0.88-1.96,1.96v26.97H3.82v-7.44c0-1.08-0.88-1.96-1.96-1.96
          s-1.96,0.88-1.96,1.96v9.4c0,1.08,0.88,1.96,1.96,1.96H15v13.71c0,1.08,0.88,1.96,1.96,1.96h44.61c1.08,0,1.96-0.88,1.96-1.96
          V17.64C63.54,16.56,62.66,15.68,61.58,15.68z M59.61,60.29H18.93V19.61h40.68V60.29L59.61,60.29z"/>
        <path style="fill:#2D527C;" d="M1.86,24.34c1.08,0,1.96-0.88,1.96-1.96V3.93H44.5v4.65c0,1.08,0.88,1.96,1.96,1.96
          c1.08,0,1.96-0.88,1.96-1.96V1.96c0-1.08-0.88-1.96-1.96-1.96H1.86C0.77,0-0.11,0.88-0.11,1.96v20.41
          C-0.11,23.46,0.77,24.34,1.86,24.34z"/>
        <path style="fill:#2D527C;" d="M39.47,41.71c0.5,0,1.01-0.19,1.39-0.58l8.13-8.13v4.56c0,1.08,0.88,1.96,1.96,1.96
          c1.08,0,1.96-0.88,1.96-1.96v-9.3c0-1.08-0.88-1.96-1.96-1.96h-9.3c-1.08,0-1.96,0.88-1.96,1.96s0.88,1.96,1.96,1.96h4.56
          l-8.13,8.13c-0.77,0.77-0.77,2.01,0,2.78C38.46,41.52,38.97,41.71,39.47,41.71z"/>
        <path style="fill:#2D527C;" d="M27.59,53.59h9.3c1.08,0,1.96-0.88,1.96-1.96c0-1.08-0.88-1.96-1.96-1.96h-4.56l2.68-2.68
          c0.77-0.77,0.77-2.01,0-2.78c-0.77-0.77-2.01-0.77-2.78,0l-2.68,2.68v-4.56c0-1.08-0.88-1.96-1.96-1.96
          c-1.08,0-1.96,0.88-1.96,1.96v9.3C25.62,52.71,26.5,53.59,27.59,53.59z"/>
      </g>
    </svg>
  `,
  mode_switch: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 452">
      <path d="M340.2,73.3h-49c0,0-0.1,0,0-0.1l26.2-26.2c4-4,4.5-10.6,0.7-14.9c-4.1-4.7-11.2-4.8-15.6-0.5l-44.7,44.8
        c-4.2,4.2-4.2,10.9,0,15.1l46.1,46.1c2,2,4.8,3.1,7.6,3.1c2.8,0,5.4-1.1,7.6-3.1c4.2-4.2,4.2-10.9,0-15.1l-27.8-27.8
        c0,0,0-0.1,0-0.1h49c31.9,0,57.8,26,57.8,57.8V188c0,5.6,4.1,10.5,9.7,11c6.3,0.6,11.7-4.4,11.7-10.6v-35.7
        C419.5,109,383.8,73.3,340.2,73.3z"/>
      <path d="M116.6,382.6h49c0,0,0.1,0,0,0.1l-26.2,26.2c-4,4-4.5,10.6-0.7,14.9c4.1,4.7,11.2,4.8,15.6,0.5l44.7-44.8
        c4.2-4.2,4.2-10.9,0-15.1l-46.1-46.1c-2-2-4.8-3.1-7.6-3.1c-2.8,0-5.4,1.1-7.6,3.1c-4.2,4.2-4.2,10.9,0,15.1l27.8,27.8
        c0,0,0,0.1,0,0.1h-49c-31.9,0-57.8-26-57.8-57.8l0-35.5c0-5.6-4.1-10.5-9.7-11c-6.3-0.6-11.7,4.4-11.7,10.6l0,35.7
        C37.3,346.9,73,382.6,116.6,382.6z"/>
      <path d="M405.8,400.9c-2.5,0-5,1-6.7,2.8s-2.8,4.2-2.8,6.7c0,2.5,1,5,2.8,6.7s4.2,2.8,6.7,2.8c2.5,0,5-1,6.7-2.8s2.8-4.2,2.8-6.7
        c0-2.5-1-5-2.8-6.7C410.7,401.9,408.2,400.9,405.8,400.9z"/>
      <path d="M366.7,400.9c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5C376.2,405.1,371.9,400.9,366.7,400.9z"/>
      <path d="M288.5,400.9c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5C298,405.1,293.8,400.9,288.5,400.9z"/>
      <path d="M327.6,400.9c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5C337.1,405.1,332.8,400.9,327.6,400.9z"/>
      <path d="M249.5,400.9c-2.5,0-5,1-6.7,2.8c-1.7,1.7-2.8,4.2-2.8,6.7c0,2.5,1,5,2.8,6.7c1.7,1.7,4.2,2.8,6.7,2.8c2.5,0,5-1,6.7-2.8
        c1.7-1.7,2.8-4.2,2.8-6.7c0-2.5-1-5-2.8-6.7C254.5,401.9,251.9,400.9,249.5,400.9z"/>
      <path d="M249.5,361.8c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5S254.7,361.8,249.5,361.8z"/>
      <path d="M249.5,322.7c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5C259,326.9,254.7,322.7,249.5,322.7z"/>
      <path d="M249.5,283.6c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5C259,287.8,254.7,283.6,249.5,283.6z"/>
      <path d="M249.5,244.5c-2.5,0-5,1-6.7,2.8c-1.7,1.7-2.8,4.2-2.8,6.7c0,2.5,1,5,2.8,6.7c1.7,1.7,4.2,2.8,6.7,2.8c2.5,0,5-1,6.7-2.8
        c1.7-1.7,2.8-4.2,2.8-6.7c0-2.5-1-5-2.8-6.7C254.4,245.5,251.9,244.5,249.5,244.5z"/>
      <path d="M366.7,244.5c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5C376.2,248.8,371.9,244.5,366.7,244.5z"/>
      <path d="M327.6,244.5c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5C337.1,248.8,332.8,244.5,327.6,244.5z"/>
      <path d="M288.5,244.5c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5C298,248.8,293.8,244.5,288.5,244.5z"/>
      <path d="M405.8,244.5c-2.5,0-5,1-6.7,2.8s-2.8,4.2-2.8,6.7c0,2.5,1,5,2.8,6.7c1.7,1.7,4.2,2.8,6.7,2.8c2.5,0,5-1,6.7-2.8
        c1.7-1.7,2.8-4.2,2.8-6.7c0-2.5-1-5-2.8-6.7C410.7,245.6,408.2,244.5,405.8,244.5z"/>
      <path d="M405.8,283.6c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5C415.3,287.8,411,283.6,405.8,283.6z"/>
      <path d="M405.8,361.8c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5S411,361.8,405.8,361.8z"/>
      <path d="M405.8,322.7c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5C415.3,326.9,411,322.7,405.8,322.7z"/>
      <path class="switched_path" style="fill:#5FAD56;" d="M195.6,215.8H20.2C9,215.8,0,206.8,0,195.6V20.2C0,9,9,0,20.2,0h175.4c11.2,0,20.2,9,20.2,20.2v175.4
        C215.8,206.8,206.8,215.8,195.6,215.8z"/>
   </svg>
  `,
  expand_collapse: `
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 57" >
      <path class="path_arrow" style="fill:#0071BC;stroke:#0071BC;stroke-width:0.75;stroke-miterlimit:10;" d="M30.5,56.2h21.3c1.1,0,2-0.9,2-2V32.9
        c0-1.1-0.9-2-2-2H30.5c-1.1,0-2,0.9-2,2v21.3C28.5,55.3,29.4,56.2,30.5,56.2z M30.6,33h9.5v13.2l-4.5-4.5l-1.5,1.5l7.1,7.1l7.1-7.1
        l-1.5-1.5l-4.5,4.5V33h9.5v21h-21V33z"/>
      <path d="M26.7,13.1h16.7c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H26.7c-0.5,0-0.9,0.4-0.9,0.9S26.2,13.1,26.7,13.1z"/>
      <path style="stroke:#000000;stroke-miterlimit:10;" d="M43.3,1.1H11.7c-5.1,0-9.2,4.1-9.2,9.2v31.7c0,5.1,4.1,9.2,9.2,9.2h10.9
        c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H11.7c-4,0-7.3-3.3-7.3-7.3V10.3c0-4,3.3-7.3,7.3-7.3h31.7c4,0,7.3,3.3,7.3,7.3v15.8
        c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9V10.3C52.5,5.2,48.4,1.1,43.3,1.1z"/>
      <path style=" stroke:#000000;stroke-miterlimit:10;" d="M17.1,16.7h-4c-1.4,0-2.5-1.1-2.5-2.5v-4c0-1.4,1.1-2.5,2.5-2.5h4
        c1.4,0,2.5,1.1,2.5,2.5v4C19.6,15.6,18.5,16.7,17.1,16.7z"/>
      <g class="path_remove">
          <path stroke-dasharray="1" d="M44.3,25.2c0-0.5-0.4-0.9-0.9-0.9H26.7c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h16.7C43.9,26.1,44.3,25.7,44.3,25.2z"/>
          <path style="stroke:#000000;stroke-miterlimit:10;" d="M17.1,29.7h-4c-1.4,0-2.5-1.1-2.5-2.5v-4c0-1.4,1.1-2.5,2.5-2.5h4
          c1.4,0,2.5,1.1,2.5,2.5v4C19.6,28.6,18.5,29.7,17.1,29.7z"/>
          <path style="stroke:#000000;stroke-miterlimit:10;" d="M17.1,43.9h-4c-1.4,0-2.5-1.1-2.5-2.5v-4c0-1.4,1.1-2.5,2.5-2.5h4
          c1.4,0,2.5,1.1,2.5,2.5v4C19.6,42.8,18.5,43.9,17.1,43.9z"/>
      </g>
  </svg>
  `,
  table_delete: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 57">
      <path d="M26.7,14.1h16.7c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H26.7c-0.5,0-0.9,0.4-0.9,0.9S26.2,14.1,26.7,14.1z"/>
      <path style="stroke:#000000;stroke-miterlimit:10;" d="M43.3,1.1H11.7c-5.1,0-9.2,4.1-9.2,9.2v31.7c0,5.1,4.1,9.2,9.2,9.2h10.9
        c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H11.7c-4,0-7.3-3.3-7.3-7.3V10.3c0-4,3.3-7.3,7.3-7.3h31.7c4,0,7.3,3.3,7.3,7.3v15.8
        c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9V10.3C52.5,5.2,48.4,1.1,43.3,1.1z"/>
      <path style="fill:none;stroke:#000000;stroke-miterlimit:10;" d="M15.1,9.3"/>
      <g>
        <path d="M44.3,25.2c0-0.5-0.4-0.9-0.9-0.9H26.7c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h16.7C43.9,26.1,44.3,25.7,44.3,25.2z
          "/>
        <path style="fill:none;stroke:#000000;stroke-miterlimit:10;" d="M15.1,43.9"/>
      </g>
      <path style="stroke:#000000;stroke-miterlimit:10;" d="M17.1,17.7h-4c-1.4,0-2.5-1.1-2.5-2.5v-4c0-1.4,1.1-2.5,2.5-2.5h4
        c1.4,0,2.5,1.1,2.5,2.5v4C19.6,16.6,18.5,17.7,17.1,17.7z"/>
      <path style="stroke:#000000;stroke-miterlimit:10;" d="M17.1,30.7h-4c-1.4,0-2.5-1.1-2.5-2.5v-4c0-1.4,1.1-2.5,2.5-2.5h4
        c1.4,0,2.5,1.1,2.5,2.5v4C19.6,29.6,18.5,30.7,17.1,30.7z"/>
      <path style="stroke:#000000;stroke-miterlimit:10;" d="M17.1,43.9h-4c-1.4,0-2.5-1.1-2.5-2.5v-4c0-1.4,1.1-2.5,2.5-2.5h4
        c1.4,0,2.5,1.1,2.5,2.5v4C19.6,42.8,18.5,43.9,17.1,43.9z"/>
      <circle style="fill:none;stroke:#ED1C24;stroke-width:3;stroke-miterlimit:10;" cx="41" cy="42.7" r="12"/>
      <line style="fill:none;stroke:#ED1C24;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;" x1="35.8" y1="47.9" x2="46.3" y2="37.4"/>
      <line style="fill:none;stroke:#ED1C24;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;" x1="46.3" y1="47.9" x2="35.8" y2="37.4"/>
    </svg>
  `,
  add: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g style="stroke:#009245;stroke-width:2;">
        <line x1="1" y1="16" x2="31" y2="16" />
        <line x1="16" y1="31" x2="16" y2="1" />
      </g>
    </svg>
  `,
  subtract: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g style="stroke:#ff0099;stroke-width:2;">
        <line x1="1" y1="16" x2="31" y2="16" />
      </g>
    </svg>
  `,
  download_excel: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 110 110">
      <path style="fill:#207245;" d="M57.3,7.5h6.6v8.8c11.1,0,22.1,0,33.2,0c1.9,0.1,3.9-0.1,5.5,1.1c1.1,1.6,1,3.7,1.1,5.6
        c-0.1,19.2,0,38.3,0,57.5c-0.1,3.2,0.3,6.5-0.4,9.7c-0.4,2.3-3.2,2.4-5,2.4c-11.4,0-22.9,0-34.3,0v9.9H57
        c-16.8-3.1-33.7-5.9-50.5-8.8V16.4C23.4,13.4,40.3,10.5,57.3,7.5z"/>
      <path style="fill:#FFFFFF;" d="M63.8,19.7h36.4v69.6H63.8v-6.6h8.8v-7.7h-8.8v-4.4h8.8v-7.7h-8.8v-4.4h8.8v-7.7h-8.8v-4.4h8.8v-7.7
        h-8.8V34h8.8v-7.7h-8.8V19.7z"/>
      <path style="fill:#207245;" d="M77.1,26.3h15.5V34H77.1V26.3z"/>
      <path style="fill:#FFFFFF;" d="M39.1,36.7c2.5-0.2,5-0.3,7.5-0.5c-2.9,6-5.9,12.1-8.9,18.1c3,6.2,6.1,12.3,9.2,18.5
        c-2.7-0.2-5.3-0.3-8-0.5c-1.9-4.6-4.2-9.1-5.5-13.9c-1.5,4.5-3.6,8.7-5.4,13.1c-2.4,0-4.8-0.1-7.3-0.2c2.8-5.6,5.6-11.2,8.5-16.7
        c-2.5-5.7-5.2-11.3-7.8-16.9c2.4-0.1,4.9-0.3,7.3-0.4c1.6,4.3,3.4,8.6,4.8,13C35.1,45.6,37.3,41.2,39.1,36.7z"/>
      <path style="fill:#207245;" d="M77.1,38.5h15.5v7.7H77.1V38.5z M77.1,50.6h15.5v7.7H77.1V50.6z M77.1,62.7h15.5v7.7H77.1V62.7z
         M77.1,74.9h15.5v7.7H77.1V74.9z"/>
    </svg>
  `,
  print: `
    <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 485 485">
      <polygon style="fill:#F2F2F2;" points="378.3,247 378.3,437.2 106.7,437.2 106.7,247 106.7,183.6 378.3,183.6 "/>
      <path style="fill:#AFB6BB;" d="M106.7,183.6V247h-77V65.9c0-10,8.2-18.1,18.1-18.1h389.4c10,0,18.1,8.2,18.1,18.1V247h-77v-63.4H106.7z"/>
        <path style="fill:#F4661E;" d="M378.3,88.5c12.5,0,22.6,10.1,22.6,22.6s-10.1,22.6-22.6,22.6c-12.5,0-22.6-10.1-22.6-22.6S365.8,88.5,378.3,88.5z"/>
      <g>
        <path d="M462.1,65.9c0-13.7-11.2-24.9-24.9-24.9H47.8c-13.7,0-24.9,11.2-24.9,24.9v187.9h77V444h285.3V253.8h77V65.9z M371.6,430.4
          H113.4v-240h258.1V430.4z M448.5,240.2h-63.4v-49.8H410v-13.6H75v13.6h24.9v49.8H36.5V65.9c0-6.2,5.1-11.3,11.3-11.3h389.4
          c6.2,0,11.3,5.1,11.3,11.3V240.2z"/>
        <path d="M378.3,81.8c-16.2,0-29.4,13.2-29.4,29.4s13.2,29.4,29.4,29.4c16.2,0,29.4-13.2,29.4-29.4S394.6,81.8,378.3,81.8z
           M378.3,127c-8.7,0-15.8-7.1-15.8-15.8s7.1-15.8,15.8-15.8c8.7,0,15.8,7.1,15.8,15.8S387.1,127,378.3,127z"/>
        <rect x="74.5" y="123.4" width="45.3" height="13.6"/>
        <rect x="74.5" y="96.2" width="81.5" height="13.6"/>
        <rect x="151.9" y="367" width="154" height="13.6"/>
        <rect x="151.9" y="321.7" width="181.1" height="13.6"/>
        <rect x="151.9" y="276.5" width="181.1" height="13.6"/>
        <rect x="151.9" y="231.2" width="181.1" height="13.6"/>
      </g>
    </svg>
  `,
  print_fill: `
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.73 41.73" xml:space="preserve">
			<path d="M34.83,14.36v-2.71h-2.68l-0.11-5.28L27.8,2.25H9.18l0.16,9.4H6.79l0.02,2.71H0v13.95v0.51v7.28h7.11v-7.28h2.33v10.66
				H32.3V28.82h2.33v7.48h7.11v-7.48v-0.31V14.36H34.83z M11.16,4.23h15.62v3.14h3.29l0.05,4.29H11.23L11.16,4.23z M30.32,37.75
				H11.41v-8.93h18.91V37.75z M34.01,18.61c-0.78,0-1.41-0.63-1.41-1.41c0-0.78,0.63-1.41,1.41-1.41c0.78,0,1.41,0.63,1.41,1.41
				C35.42,17.98,34.78,18.61,34.01,18.61z M38.51,18.61c-0.78,0-1.41-0.63-1.41-1.41c0-0.78,0.63-1.41,1.41-1.41
				c0.78,0,1.41,0.63,1.41,1.41C39.92,17.98,39.28,18.61,38.51,18.61z"/>
			<path d="M14.8,31.2c4.23,0,8.46,0,12.69,0c1.06,0,1.06-1.44,0-1.44c-4.23,0-8.46,0-12.69,0C13.74,29.76,13.74,31.2,14.8,31.2z"/>
			<path d="M14.56,34.42c4.23,0,8.46,0,12.69,0c1.06,0,1.06-1.44,0-1.44c-4.23,0-8.46,0-12.69,0
				C13.49,32.98,13.49,34.42,14.56,34.42z"/>
  </svg>
  `,
  green_circle: `
    <svg version="1.1" class="print_labels" id="completed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" >
      <circle fill="#4cc37f" cx="64" cy="64" r="62"/>
    </svg>
  `,
  blue_circle: `
    <svg version="1.1" class="print_labels" id="on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" >
      <circle fill="#00bfff" cx="64" cy="64" r="62"/>
    </svg>
  `,
  yellow_circle: `
    <svg version="1.1" class="print_labels" id="ready" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" >
      <circle fill="#ffffb6" cx="64" cy="64" r="62"/>
    </svg>
  `,
  erase: `
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <path d="M9.207 8.5l6.646 6.646-.707.707L8.5 9.207l-6.646 6.646-.707-.707L7.793 8.5 1.146 1.854l.707-.707L8.5 7.793l6.646-6.646.707.707L9.207 8.5z" fill="#000"></path>
   </svg>
   <i class="icon-close"></i>
  `,
  calendar: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <path
        d="M14 2V1h-3v1H6V1H3v1H0v15h17V2h-3zM12 2h1v2h-1V2zM4 2h1v2H4V2zM16 16H1v-8.921h15V16zM1 6.079v-3.079h2v2h3V3h5v2h3V3h2v3.079H1z" fill="#000"></path>
    </svg>
    <i class="icon-calendar"></i>
  `,
  clear: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <path
        d="M9.207 8.5l6.646 6.646-.707.707L8.5 9.207l-6.646 6.646-.707-.707L7.793 8.5 1.146 1.854l.707-.707L8.5 7.793l6.646-6.646.707.707L9.207 8.5z" fill="#000"></path>
   </svg>
   <i class="icon-close"></i>
  `,
  complete_task: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" >
      <path style="fill:#FFFFFF;stroke:#000000;stroke-width:1.7;stroke-miterlimit:10;" d="M24.2,29.8H7.8c-1.3,0-2.4-1.1-2.4-2.4V7.4
        C5.5,6,6.5,5,7.8,5h16.3c1.3,0,2.4,1.1,2.4,2.4v20.1C26.5,28.8,25.5,29.8,24.2,29.8z"/>
      <path style="fill:#FFFFFF;stroke:#000000;stroke-width:1.7;stroke-miterlimit:10;" d="M21.5,7.2h-11V3.7c0-1.3,1.1-2.4,2.4-2.4h6.2
        c1.3,0,2.4,1.1,2.4,2.4V7.2z"/>
      <polygon style="fill:#009245;stroke:#009245;stroke-miterlimit:10;" points="13.9,20.2 10.1,16.5 8.7,17.9 13.9,23.2 23.3,13.8 
        21.9,12.3 	"/>
    </svg>
  `,
  clear_filter: `
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" >
      <path style="fill-rule:evenodd;clip-rule:evenodd;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;" d="M2.4,5h58.9
      c0.8,0,1.4,0.6,1.4,1.4c0,0.4-0.2,0.8-0.5,1.1l-4.4,5.6c-0.5,0.6-1.4,0.7-2,0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.5-0.7-1.3-0.3-1.9
      l0.9-1.3c0.7-0.9,0-2.2-1.1-2.2H8.7c-1.2,0-1.8,1.4-1.1,2.3L24.8,31c0.3,0.3,0.5,0.6,0.5,1.1v22.5c0,1,1.1,1.7,2,1.3l7.8-3.6
      c0.5-0.2,0.8-0.7,0.8-1.3v-4.3c0-1.1,1.3-1.8,2.2-1.1c0,0,0,0,0,0c0.4,0.3,0.6,0.7,0.6,1.1v6c0,0.6-0.3,1-0.8,1.3l-13.3,6.4
      c-0.7,0.4-1.5,0.2-1.9-0.5c-0.1-0.2-0.2-0.5-0.2-0.7v-26c0-0.3-0.1-0.6-0.3-0.9L1.4,7.4c-0.5-0.6-0.5-1.4,0-2C1.7,5.1,2,5,2.4,5
      L2.4,5z"/>
      <circle style="fill:none;stroke:#F15A24;stroke-width:3;stroke-miterlimit:10;" cx="49" cy="30" r="12.5"/>
      <line style="fill:none;stroke:#F15A24;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;" x1="43.5" y1="35.5" x2="54.5" y2="24.5"/>
      <line style="fill:none;stroke:#F15A24;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;" x1="54.5" y1="35.5" x2="43.5" y2="24.5"/>
    </svg>
  `,
  green_hyphen: `
   <svg version="1.1" class="cell_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path style="fill:#39B54A;" d="M56.89,25.5H7.11c-3.09,0-5.61,2.53-5.61,5.61v1.77c0,3.09,2.53,5.61,5.61,5.61h49.77
      c3.09,0,5.61-2.53,5.61-5.61v-1.77C62.5,28.03,59.97,25.5,56.89,25.5L56.89,25.5z"/>
		</svg>
  `,
  ok: `
   <svg version="1.1" class="cell_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path style="fill:#39B54A;" d="M32,13c10.477,0,19,8.523,19,19s-8.523,19-19,19s-19-8.523-19-19S21.523,13,32,13 M32,1
        C14.879,1,1,14.879,1,32c0,17.121,13.879,31,31,31c17.121,0,31-13.879,31-31C63,14.879,49.121,1,32,1L32,1z"/>
    </svg>
  `,
  exclaim: `
    <svg version="1.1" class="cell_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" >
      <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#E43535;" d="M27.728,5.578L1.16,53.647
		c-1.798,3.253,0.555,7.242,4.272,7.242h53.137c3.717,0,6.07-3.989,4.272-7.242L36.272,5.578
		C34.415,2.218,29.585,2.218,27.728,5.578z"/>
	<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;" d="M32,14.465L32,14.465c2.181,0,3.948,1.768,3.948,3.948V40.13
		c0,2.181-1.768,3.948-3.948,3.948l0,0c-2.181,0-3.948-1.768-3.948-3.948V18.414C28.052,16.233,29.819,14.465,32,14.465z"/>
	<circle style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;" cx="32" cy="51.975" r="3.948"/>
    </svg>
  `,
  exclaim_warning: `
    <svg version="1.1" class="cell_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" >
      <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#ff921d;" d="M27.728,5.578L1.16,53.647
		c-1.798,3.253,0.555,7.242,4.272,7.242h53.137c3.717,0,6.07-3.989,4.272-7.242L36.272,5.578
		C34.415,2.218,29.585,2.218,27.728,5.578z"/>
	<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;" d="M32,14.465L32,14.465c2.181,0,3.948,1.768,3.948,3.948V40.13
		c0,2.181-1.768,3.948-3.948,3.948l0,0c-2.181,0-3.948-1.768-3.948-3.948V18.414C28.052,16.233,29.819,14.465,32,14.465z"/>
	<circle style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;" cx="32" cy="51.975" r="3.948"/>
    </svg>
  `,
  repress: `
    <svg version="1.1" class="cell_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" >
    <polygon style="fill:#C1272D;" points="52,26 12,26 12,39 52,39 52,26 		"/>
		<path style="fill:#C1272D;" d="M43.28,17.09c-0.66,0-1.28,0.52-1.28,1.27v29.52c0,0.75,0.62,1.27,1.28,1.27
			c0.27,0,0.54-0.09,0.78-0.28l18.71-14.76c0.64-0.51,0.64-1.49,0-1.99L44.06,17.36C43.82,17.17,43.54,17.09,43.28,17.09
			L43.28,17.09z"/>
		<path style="fill:#C1272D;" d="M20.72,17.09c-0.27,0-0.54,0.09-0.78,0.28L1.24,32.12c-0.64,0.51-0.64,1.49,0,1.99l18.71,14.76
			c0.24,0.19,0.51,0.28,0.78,0.28c0.66,0,1.28-0.52,1.28-1.27V18.36C22,17.61,21.38,17.09,20.72,17.09L20.72,17.09z"/>
    </svg>
  `,
  cloth_pile: `
    <svg version="1.1" id="cloths_pile" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M94.4,29.9c1.1,0.4,2.1,0.7,3.1,1c0.7,0.2,1.3,0.5,1.5,1.3c0.2,0.8-0.2,1.3-0.9,1.8c-0.4,0.3-0.7,0.6-1.1,0.9
        c1,2,0.5,4.1,0.4,6.1c0,0.3-0.4,0.7-0.7,1c-0.7,0.6-1.4,1.2-2.3,1.8c1.2,0.4,2.3,0.7,3.2,1.1c0.5,0.2,1.1,0.7,1.3,1.3
        c0.3,0.7-0.1,1.3-0.7,1.7c-0.4,0.3-0.8,0.6-0.9,1c0.1,1,0.3,2,0.3,3c0.1,0.7,0,1.4,0,2.1c0,0.9-0.3,1.6-1.1,2.1
        c-0.7,0.5-1.3,1-2.1,1.6c1,0.3,1.9,0.7,2.8,0.9c0.7,0.2,1.5,0.4,1.7,1.4c0.2,0.9-0.4,1.5-1,2C85,72.3,72.1,82.4,59.2,92.7
        c-4.4,3.5-9.1,4.2-14.2,2.4c-8.7-3-17.4-6.3-26.1-9.4c-4.1-1.5-8.1-3-12.2-4.5c-5-1.9-7.4-7-5.7-12.1c0.5-1.4,1.3-2.6,1.9-4
        c-3.4-4.5-3.3-9.2,0.1-13.9c-1.6-1.8-2.4-3.9-2.6-6.3c-0.2-3.7,1.4-6.6,4-9c1.3-1.2,2.7-2.2,4.1-3.2c0.5-0.4,0.6-0.7,0.4-1.3
        c-1-3.3-1.2-2.9,1.6-5c7.7-6,15.5-12.1,23.2-18.1c1-0.8,1.9-1,2.9-0.2c1.4,1.1,2.5,1,3.7-0.3c0.5-0.6,1.3-1,1.9-1.5
        c0.9-0.8,1.7-0.9,2.8-0.5c1.8,0.6,3.5,1.1,5.3,1.6c0.4,0.1,1,0,1.4-0.2c1.5-0.8,2.8-1.8,4.3-2.5c0.6-0.3,1.4-0.4,2.1-0.3
        c8.4,2.5,16.8,5,25.1,7.5c0.7,0.2,1.2,0.6,1.5,1.3c0.7,1.5,1.3,3.1,2.1,4.5c0.2,0.4,0.6,0.7,1,0.9c2.7,0.9,5.4,1.7,8.1,2.4
        c1,0.3,1.5,0.9,1.5,1.9c0,1.2,0,2.4,0,3.6c0,0.8-0.3,1.4-0.9,1.8C96,28.7,95.3,29.2,94.4,29.9z M90.2,34.2c-0.8-0.3-1.3,0-1.8,0.5
        C79.9,41.4,71.4,48,63,54.7c-3,2.4-6.4,4.5-8.4,8c-0.5,0.8-1.5,0.9-2.2,0.4c-0.8-0.5-0.9-1.4-0.3-2.3c0.3-0.5,0.6-0.9,1.1-1.5
        c-0.8,0-1.3,0-1.9,0c-1,0-1.7-0.6-1.8-1.5c0-0.9,0.7-1.6,1.8-1.6c1.2,0,2.5,0.1,3.7,0c0.6,0,1.3-0.2,1.7-0.6
        c10.1-7.9,20.1-15.9,30.2-23.8c2.3-1.8,4.6-3.7,7-5.5c0.9-0.7,0.6-1.6,0.5-2.4c0-0.3-0.6-0.6-1-0.7c-1.3-0.5-2.7-0.7-4-1.3
        c-1.8-0.7-3-0.4-4.7,0.7C75,29,64.9,29.3,54.7,24.5c-3.5-1.6-5.9-4.4-6.7-8.3c-0.4-2.1-0.2-4.2,0.9-6.2c-1.5-0.5-2.9-0.9-4.3-1.3
        C44.3,8.6,44,8.8,43.8,9c-1.7,1.4-3.4,2.8-5.2,4.2c-0.5,0.4-0.5,0.8-0.4,1.3c1.4,5.3,2.8,10.6,4.2,16c0.5,1.8,0.1,2.4-1.7,2.8
        c-2.9,0.6-5.8,1.3-8.7,1.9c-6.2,1.3-12.3,2.7-18.5,4c-1.4,0.3-2,0-2.4-1.4c-0.2-0.7-0.5-1.5-0.7-2.3c-1.1,0.8-2.1,1.6-3,2.4
        c-1.3,1-2.3,2.3-3,3.8c-1.6,3.6,0,7.4,3.6,8.8c4.3,1.6,8.5,3.2,12.8,4.8c8.5,3.1,17.1,6.3,25.7,9.2c3.8,1.3,7.4,0.8,10.6-1.8
        c9.3-7.4,18.6-14.7,27.9-22.1c2.3-1.8,4.5-3.6,6.8-5.4c-0.3-0.2-0.5-0.3-0.6-0.4c1.5,0.5,1.9-0.9,3-1.4c-2.7-1.1-2.8-1.1-4.5,0.7
        C89.8,34,90,34.1,90.2,34.2z M53.1,87.3c-0.8,0-1.4,0-2,0c-1,0-1.7-0.7-1.7-1.6c0-0.9,0.7-1.5,1.7-1.6c1.1,0,2.2-0.1,3.3,0
        c1.1,0.1,1.9-0.3,2.8-1c12-9.5,24-19,36.1-28.5c0.8-0.7,1.4-1.3,1.2-2.4c-0.1-0.3-0.2-0.6-0.2-0.8c-0.3,0.1-0.6,0.1-0.8,0.3
        c-1.3,1-2.7,2.1-4,3.1C79,63,68.7,71.2,58.4,79.3c-1.1,0.9-2.4,1.6-3.7,2c-3.7,1.3-7.3,0.8-10.9-0.5c-11.4-4.2-22.7-8.3-34.1-12.5
        c-1.3-0.5-2.6-0.9-3.9-1.4c-0.4,0.5-0.7,0.9-1,1.4c-0.2,0.3-0.4,0.7-0.6,1.1c-1.6,3.6,0,7.5,3.7,8.8c8,3,16,5.9,24,8.9
        c4.8,1.7,9.5,3.5,14.3,5.1c3.9,1.3,7.5,0.8,10.8-1.8c10.1-8.1,20.3-16,30.4-24c1.4-1.1,2.7-2.1,4.1-3.3c-0.3-0.2-0.5-0.3-0.7-0.4
        c1.6,0.6,2-1,3.1-1.5c-2.5-1.1-3.2-1-4.6,0.8c0.2,0.1,0.4,0.2,0.3,0.2c-0.4,0.1-1,0.2-1.4,0.4c-8.3,6.5-16.5,13-24.8,19.5
        c-3.3,2.6-6.9,4.9-9.2,8.6c-0.4,0.7-1.5,0.8-2.1,0.4c-0.7-0.5-0.9-1.4-0.4-2.2C52.3,88.4,52.6,87.9,53.1,87.3z M53.1,73.4
        c-0.8,0-1.3,0-1.9,0c-1.1,0-1.8-0.7-1.8-1.6c0-0.9,0.7-1.6,1.8-1.6c1.2,0,2.4,0,3.7,0c0.8,0,1.3-0.2,1.9-0.7
        C69,59.9,81.2,50.2,93.5,40.6c1-0.8,0.9-1.7,0.9-2.7c0-0.1-0.5-0.3-0.7-0.3c-0.2,0-0.5,0.3-0.7,0.5C81.6,47,70.3,55.9,59,64.9
        c-4.2,3.4-8.8,4-13.7,2.3c-12.5-4.4-24.9-9-37.3-13.5c-2.4-0.9-2.6-0.8-3.7,1.6c0,0.1,0,0.1-0.1,0.2c-1.5,3.7,0,7.4,3.7,8.7
        c5.9,2.2,11.8,4.4,17.7,6.6c6.9,2.5,13.8,5.1,20.7,7.4c3.8,1.3,7.4,0.7,10.7-1.9c7-5.7,14.1-11.2,21.1-16.7c4.4-3.5,8.8-7,13.3-10.5
        c-0.2-0.2-0.4-0.3-0.6-0.4c1,0.2,1.8-0.3,2.9-1.5c-2.3-1.1-3-0.9-4.5,0.8c0.2,0.1,0.4,0.2,0.5,0.3c-0.1-0.1-0.1-0.1-0.2-0.2
        c-0.5,0.2-1.1,0.3-1.5,0.6C79.5,55.6,70.8,62.5,62,69.4c-2.7,2.2-5.6,4.2-7.4,7.3c-0.5,0.8-1.5,0.9-2.2,0.5
        c-0.8-0.5-0.9-1.4-0.4-2.3C52.3,74.4,52.6,74,53.1,73.4z M39.1,30.3c-0.1-0.4-0.1-0.6-0.2-0.9c-1.3-4.9-2.5-9.7-3.9-14.6
        c-0.4-1.5-0.5-2.7,0.9-3.6c0,0,0-0.1,0-0.4c-0.3,0.1-0.6,0.2-0.9,0.4C27.5,17.2,20,23.1,12.4,28.9C12,29.3,11.8,29.5,12,30
        c0.5,1.6,1.1,3.3,1.5,5c0.2,0.7,0.4,0.8,1.1,0.6c5.6-1.2,11.1-2.4,16.7-3.6C33.9,31.5,36.4,30.9,39.1,30.3z M52.7,10.2
        c-0.1,0.1-0.3,0.1-0.3,0.2c-0.2,0.2-0.3,0.3-0.4,0.5c-1.3,1.8-1.4,3.8-0.6,5.8c1,2.5,2.9,4.1,5.3,5.2c5.3,2.5,10.9,3.1,16.7,2.1
        c3.7-0.7,7.2-2.1,10.2-4.4c0.2-0.2,0.5-0.7,0.4-0.9c-0.3-0.9-0.8-1.8-1.2-2.6c-1.8,1-3.4,2.2-5.2,3c-6.9,3.1-13.7,3-20.3-0.8
        c-2-1.1-3.5-2.7-4.2-5C52.9,12.3,52.9,11.3,52.7,10.2z M80,14c-0.3-0.1-0.5-0.2-0.7-0.3c-6.8-2-13.5-4.1-20.3-6.1
        c-1.3-0.4-1.8-0.1-2.2,1.4c0.3,0.1,0.6,0.2,0.9,0.3c6.2,1.9,12.4,3.7,18.6,5.6c1.3,0.4,2.3,0.3,3.3-0.6C79.7,14.3,79.8,14.2,80,14z
         M56.1,12.2c0.4,1.4,1.2,2.2,2.1,2.9c1.7,1.2,3.6,2,5.6,2.5c3.1,0.7,6.1,0.7,9.2-0.4C67.4,15.5,61.9,13.9,56.1,12.2z"/>
      <g class="cloths" style="fill:#00ff00">
        <path  d="M90.2,34.2c-0.2-0.1-0.4-0.2-0.6-0.4c1.7-1.7,1.9-1.8,4.5-0.7c-1,0.5-1.5,1.9-3,1.4c0.2,0.1,0.4,0.2,0.6,0.4
          c-2.3,1.8-4.6,3.6-6.8,5.4C75.6,47.8,66.3,55.1,57,62.5c-3.3,2.6-6.9,3.1-10.6,1.8c-8.6-3-17.1-6.1-25.7-9.2
          c-4.3-1.6-8.5-3.2-12.8-4.8c-3.6-1.4-5.2-5.2-3.6-8.8C5,40,6,38.7,7.3,37.7c1-0.8,1.9-1.5,3-2.4c0.3,0.9,0.5,1.6,0.7,2.3
          c0.4,1.4,1,1.8,2.4,1.4c6.2-1.3,12.3-2.7,18.5-4c2.9-0.6,5.8-1.3,8.7-1.9c1.8-0.4,2.2-1,1.7-2.8c-1.4-5.3-2.8-10.7-4.2-16
          c-0.1-0.6-0.1-0.9,0.4-1.3c1.7-1.4,3.4-2.8,5.2-4.2c0.2-0.2,0.5-0.4,0.7-0.3c1.4,0.4,2.8,0.8,4.3,1.3c-1,2-1.3,4.1-0.9,6.2
          c0.8,3.9,3.3,6.7,6.7,8.3C64.9,29.3,75,29,84.5,22.8c1.7-1.1,3-1.4,4.7-0.7c1.3,0.5,2.7,0.8,4,1.3c0.4,0.1,1,0.4,1,0.7
          c0,0.8,0.4,1.7-0.5,2.4c-2.4,1.8-4.6,3.6-7,5.5c-10.1,7.9-20.1,15.9-30.2,23.8c-0.5,0.4-1.1,0.5-1.7,0.6c-1.2,0.1-2.5,0-3.7,0
          c-1.1,0-1.8,0.7-1.8,1.6c0,0.9,0.7,1.5,1.8,1.5c0.6,0,1.1,0,1.9,0c-0.4,0.6-0.8,1-1.1,1.5c-0.6,0.9-0.4,1.8,0.3,2.3
          c0.7,0.5,1.7,0.4,2.2-0.4c2.1-3.5,5.4-5.6,8.4-8c8.4-6.7,16.9-13.3,25.3-20C88.9,34.3,89.4,33.9,90.2,34.2z"/>
        <path d="M53.1,87.3c-0.5,0.7-0.9,1.1-1.2,1.6c-0.5,0.8-0.3,1.7,0.4,2.2c0.7,0.5,1.7,0.4,2.1-0.4c2.2-3.8,5.9-6,9.2-8.6
          c8.2-6.5,16.5-13,24.8-19.5c0.4-0.3,1-0.3,1.4-0.4c0,0-0.2-0.1-0.3-0.2c1.4-1.8,2.2-1.9,4.6-0.8c-1.1,0.5-1.5,2-3.1,1.5
          c0.2,0.1,0.4,0.2,0.7,0.4c-1.4,1.1-2.7,2.2-4.1,3.3c-10.1,8-20.3,16-30.4,24c-3.4,2.7-7,3.1-10.8,1.8c-4.8-1.6-9.6-3.3-14.3-5.1
          c-8-2.9-16-5.9-24-8.9c-3.7-1.4-5.3-5.2-3.7-8.8c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.5,0.6-0.9,1-1.4c1.3,0.5,2.6,1,3.9,1.4
          c11.4,4.2,22.7,8.3,34.1,12.5c3.6,1.3,7.2,1.8,10.9,0.5c1.3-0.5,2.6-1.2,3.7-2C68.7,71.2,79,63,89.3,54.9c1.3-1.1,2.7-2.1,4-3.1
          c0.2-0.2,0.6-0.2,0.8-0.3c0.1,0.3,0.2,0.6,0.2,0.8c0.2,1.1-0.3,1.7-1.2,2.4c-12,9.5-24.1,19-36.1,28.5c-0.8,0.7-1.7,1.1-2.8,1
          c-1.1-0.1-2.2,0-3.3,0c-1,0-1.7,0.7-1.7,1.6c0,0.9,0.7,1.5,1.7,1.6C51.7,87.3,52.3,87.3,53.1,87.3z"/>
        <path d="M53.1,73.4c-0.4,0.6-0.8,1-1.1,1.5c-0.6,0.9-0.4,1.8,0.4,2.3c0.7,0.5,1.7,0.4,2.2-0.5c1.8-3.1,4.7-5.1,7.4-7.3
          c8.8-6.9,17.5-13.8,26.3-20.7c0.4-0.3,1-0.4,1.5-0.6c0.1,0.1,0.1,0.1,0.2,0.2c-0.2-0.1-0.4-0.2-0.5-0.3c1.4-1.8,2.2-1.9,4.5-0.8
          c-1,1.3-1.9,1.7-2.9,1.5c0.2,0.2,0.3,0.3,0.6,0.4c-4.5,3.5-8.9,7-13.3,10.5c-7,5.6-14.1,11.1-21.1,16.7c-3.3,2.7-6.9,3.2-10.7,1.9
          c-6.9-2.4-13.8-4.9-20.7-7.4c-5.9-2.2-11.8-4.4-17.7-6.6c-3.7-1.4-5.2-5.1-3.7-8.7c0-0.1,0-0.1,0.1-0.2c1.1-2.4,1.3-2.5,3.7-1.6
          c12.4,4.5,24.8,9.2,37.3,13.5c4.8,1.7,9.5,1,13.7-2.3c11.3-9,22.6-17.9,33.9-26.8c0.2-0.2,0.4-0.5,0.7-0.5c0.2,0,0.7,0.2,0.7,0.3
          c0.1,1,0.2,1.9-0.9,2.7C81.2,50.2,69,59.9,56.8,69.5c-0.6,0.5-1.2,0.7-1.9,0.7c-1.2-0.1-2.4,0-3.7,0c-1.1,0-1.8,0.6-1.8,1.6
          c0,0.9,0.7,1.6,1.8,1.6C51.8,73.4,52.3,73.4,53.1,73.4z"/>
        <path d="M39.1,30.3c-2.7,0.6-5.2,1.2-7.8,1.7c-5.6,1.2-11.1,2.4-16.7,3.6c-0.7,0.1-0.9,0-1.1-0.6c-0.5-1.7-1-3.3-1.5-5
          c-0.2-0.5,0-0.8,0.4-1.1C20,23.1,27.5,17.2,35,11.3c0.2-0.2,0.5-0.2,0.9-0.4c0,0.2,0.1,0.3,0,0.4c-1.4,0.9-1.3,2.2-0.9,3.6
          c1.3,4.8,2.6,9.7,3.9,14.6C39,29.7,39,29.9,39.1,30.3z"/>
      </g>
        <g style="fill:#ffffff;">
            <path d="M52.7,10.2c0.1,1.1,0.2,2.1,0.4,3.1c0.6,2.3,2.2,3.8,4.2,5c6.6,3.8,13.4,3.9,20.3,0.8c1.8-0.8,3.4-1.9,5.2-3
                c0.4,0.8,0.8,1.7,1.2,2.6c0.1,0.2-0.2,0.7-0.4,0.9c-3,2.4-6.5,3.8-10.2,4.4c-5.8,1-11.4,0.5-16.7-2.1c-2.3-1.1-4.3-2.7-5.3-5.2
                c-0.8-2-0.7-4,0.6-5.8c0.1-0.2,0.3-0.4,0.4-0.5C52.5,10.4,52.6,10.3,52.7,10.2z"/>
            <path class="st0" d="M80,14c-0.2,0.2-0.3,0.3-0.4,0.3c-1,0.9-2,1-3.3,0.6c-6.2-1.9-12.4-3.7-18.6-5.6c-0.3-0.1-0.6-0.2-0.9-0.3
                c0.4-1.5,0.8-1.8,2.2-1.4c6.8,2,13.5,4.1,20.3,6.1C79.5,13.8,79.7,13.9,80,14z"/>
            <path class="st0" d="M56.1,12.2c5.7,1.7,11.3,3.4,16.9,5.1c-3.1,1-6.1,1.1-9.2,0.4c-2-0.5-3.9-1.3-5.6-2.5
                C57.3,14.4,56.5,13.5,56.1,12.2z"/>
        </g>
  </svg>

  `,
};
