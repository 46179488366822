export const SVG_ICONS = {
  delivery_process_icons: `
 <svg enable-background="new 0 0 1200 114" version="1.1" viewBox="0 0 1200 114" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
	<path d="m145.84 35.653h-68.606c-3.742 0-6.804 3.062-6.804 6.804v50.463c0 3.742 3.062 6.804 6.804 6.804h25.558l8.461 10.773 7.938-10.773h26.649c3.742 0 6.804-3.062 6.804-6.804v-50.463c0-3.742-3.062-6.804-6.804-6.804z" fill="#808080"/>
		<line x1="95.189" x2="131.75" y1="66.584" y2="66.584" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
	<g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2">
			<line x1="107.1" x2="107.1" y1="55.132" y2="77.656"/>
			<line x1="120.1" x2="120.1" y1="55.132" y2="77.656"/>
	</g>
		<circle cx="100.42" cy="87.424" r="3.256" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<circle cx="126.47" cy="87.424" r="3.256" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<polyline points="84.142 46.027 90.654 46.027 99.026 84.168 126 84.168" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<polyline points="97.656 77.656 128.92 77.656 134.98 55.132 92.816 55.132" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
	<path d="m341.84 35.653h-68.606c-3.742 0-6.804 3.062-6.804 6.804v50.463c0 3.742 3.062 6.804 6.804 6.804h25.558l8.461 10.773 7.938-10.773h26.649c3.742 0 6.804-3.062 6.804-6.804v-50.463c0-3.742-3.062-6.804-6.804-6.804z" fill="#808080"/>
	<g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2">
			<circle cx="305.21" cy="86.92" r="3.94"/>
			<polyline points="282.59 51.661 287.08 49.909 304.62 82.975 329.43 69.918"/>
	</g>
		<line x1="318.52" x2="326.44" y1="75.704" y2="81.409" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
	<g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2">
			<rect transform="matrix(.8847 -.4662 .4662 .8847 3.8798 152.34)" x="298.36" y="57.792" width="22.983" height="21.068"/>
			<rect transform="matrix(.8847 -.4662 .4662 .8847 8.9713 146.48)" x="291.12" y="49.608" width="18.86" height="10.994"/>
	</g>
	<path d="m536.84 35.653h-68.606c-3.742 0-6.804 3.062-6.804 6.804v50.463c0 3.742 3.062 6.804 6.804 6.804h25.558l8.461 10.773 7.938-10.773h26.649c3.742 0 6.804-3.062 6.804-6.804v-50.463c0-3.742-3.062-6.804-6.804-6.804z" fill="#808080"/>
		<rect x="487.35" y="50.68" width="30.88" height="38.218" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
	<path d="m505.66 47.8c-0.128-1.484-1.359-2.652-2.875-2.652h0c-1.517 0-2.747 1.168-2.875 2.652h-6.737v5.635h19.225v-5.635h-6.738z" fill="#808080" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
	<g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2">
			<line x1="494.7" x2="510.88" y1="64.025" y2="64.025"/>
			<line x1="494.7" x2="510.88" y1="69.789" y2="69.789"/>		
			<line x1="494.7" x2="510.88" y1="75.553" y2="75.553"/>
	</g>
	<path d="m731.84 35.653h-68.606c-3.742 0-6.804 3.062-6.804 6.804v50.463c0 3.742 3.062 6.804 6.804 6.804h25.558l8.461 10.773 7.938-10.773h26.649c3.742 0 6.804-3.062 6.804-6.804v-50.463c0-3.742-3.062-6.804-6.804-6.804z" fill="#808080"/>
		<circle cx="697.54" cy="60.907" r="7.427" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
	<path d="m712.24 61.351c0 6.635-9.825 21.47-13.405 26.656-0.623 0.902-1.948 0.902-2.571 0-3.58-5.186-13.405-20.021-13.405-26.656 0-8.113 6.577-14.69 14.69-14.69s14.691 6.577 14.691 14.69z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
	<path d="m926.84 35.653h-68.606c-3.742 0-6.804 3.062-6.804 6.804v50.463c0 3.742 3.062 6.804 6.804 6.804h25.558l8.461 10.773 7.938-10.773h26.649c3.742 0 6.804-3.062 6.804-6.804v-50.463c0-3.742-3.062-6.804-6.804-6.804z" fill="#808080"/>
		<rect x="869.39" y="53.072" width="29.967" height="25.475" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<polygon points="917.96 78.547 899.35 78.547 899.35 58.733 912.22 58.733 917.96 67.736" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<polygon points="906.66 68.445 917.96 68.445 917.96 67.736 914.23 61.888 906.66 61.888" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<circle cx="879.09" cy="79.029" r="3.699" fill="#808080" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<circle cx="908.36" cy="79.029" r="3.699" fill="#808080" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<line x1="876.48" x2="869.6" y1="71.909" y2="71.909" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<line x1="885.97" x2="898.55" y1="71.909" y2="71.909" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
	<polygon points="891.72 64.247 886.05 59.89 886.05 62.001 877.77 62.001 877.77 66.492 886.05 66.492 886.05 68.603" fill="#fff"/>
	<path d="m1121.8 35.653h-68.606c-3.742 0-6.804 3.062-6.804 6.804v50.463c0 3.742 3.062 6.804 6.804 6.804h25.558l8.461 10.773 7.938-10.773h26.649c3.742 0 6.804-3.062 6.804-6.804v-50.463c0-3.742-3.062-6.804-6.804-6.804z" fill="#808080"/>
		<rect x="1076.9" y="45.593" width="21.726" height="16.267" fill="#808080" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
	<path d="m1104.9 81.147h-34.242c-1.319 0-2.388-1.069-2.388-2.388v-20.714c0-1.319 1.069-2.388 2.388-2.388h34.242c1.319 0 2.388 1.069 2.388 2.388v20.715c0 1.318-1.07 2.387-2.388 2.387z" fill="#808080" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<rect x="1076.9" y="69.176" width="21.726" height="21.938" fill="#808080" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<line x1="1073.7" x2="1101.2" y1="69.176" y2="69.176" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
		<path d="m1089.9 82.399h-3.754l-0.672 1.631h-1.751l3.538-8.155h1.523l3.526 8.155h-1.739l-0.671-1.631zm-0.6-1.452-1.284-3.118-1.283 3.118h2.567z" fill="#fff"/>
	<path d="m79.835 18.853v1.875h-5.4v3.959h-2.1v-3.959h-5.4v-1.875h12.9zm-8.504-6.24h-3.84v-2.025h11.789v2.025h-3.765l3.675 2.775v2.4l-5.79-4.05-5.895 4.05v-2.4l3.826-2.775z"/>
	<path d="m94.235 17.757v1.89h-5.175v1.995h-1.995v-1.995h-2.58v3.015h9.044v2.025h-11.173v-5.04h-1.02v-1.89h12.899zm-1.005-7.184v5.85h-10.89v-5.85h10.89zm-8.909 3.855h6.915v-1.86h-6.915v1.86z"/>
	<path d="m103.49 10.573v1.965l-2.07 1.605 2.07 1.62v2.385l-3.6-2.805-3.78 2.955v-2.385l4.335-3.375h-4.335v-1.965h7.38zm2.009 8.775c1.485 0 2.67 1.2 2.67 2.669 0 1.47-1.185 2.67-2.67 2.67h-6.12c-1.47 0-2.67-1.2-2.67-2.67s1.2-2.669 2.67-2.669h6.12zm-6.119 2.025c-0.36 0-0.645 0.285-0.645 0.645s0.285 0.645 0.645 0.645h6.12c0.36 0 0.645-0.285 0.645-0.645s-0.285-0.645-0.645-0.645h-6.12zm6.644-8.101v-2.775h2.085v7.92h-2.085v-3.105h-1.8v-2.04h1.8z"/>
	<path d="m117.68 19.422v3.3h5.355v1.965h-12.899v-1.965h5.325v-3.3h-4.725v-8.925h2.325v3.045h7.05v-3.045h2.325v8.925h-4.756zm2.43-2.04v-1.815h-7.05v1.815h7.05z"/>
	<path d="m129.35 18.118h3.09v-0.765c-1.26-0.285-2.25-1.125-2.25-2.34v-0.855c0-0.405 0.12-0.78 0.315-1.11h-1.02v-2.04h2.82v-1.02h2.1v1.02h2.76v2.04h-1.035c0.21 0.33 0.33 0.705 0.33 1.11v0.855c0 1.125-0.87 1.935-1.995 2.265v0.84h4.695v-7.62h2.145v3.78h1.53v1.965h-1.53v3.69h-11.954v-1.815zm1.004 2.759h10.949v3.795h-2.145v-2.01h-8.805v-1.785zm1.74-6.119c0 0.63 0.525 1.05 1.23 1.05s1.23-0.42 1.23-1.05v-0.345c0-0.63-0.525-1.05-1.23-1.05s-1.23 0.42-1.23 1.05v0.345z"/>
	<path d="m148.54 10.573c1.815 0 3.27 1.47 3.27 3.27v1.5c0 1.815-1.455 3.27-3.27 3.27h-1.185c-1.815 0-3.27-1.455-3.27-3.27v-1.5c0-1.8 1.455-3.27 3.27-3.27h1.185zm-1.695 12.089h7.169v-12.164h2.1v14.189h-11.37v-4.62h2.1v2.595zm0.51-10.019c-0.66 0-1.2 0.54-1.2 1.2v1.5c0 0.66 0.54 1.2 1.2 1.2h1.185c0.66 0 1.2-0.54 1.2-1.2v-1.5c0-0.66-0.54-1.2-1.2-1.2h-1.185z"/>
	<path d="m271.52 24.612h-5.955v-14.04h2.07v4.965h1.815v-4.965h2.07v14.04zm-2.07-2.04v-4.995h-1.815v4.995h1.815zm8.55 2.115h-2.085v-7.169h-1.155v7.169h-2.025v-14.189h2.025v5.04h1.155v-5.04h2.085v14.189z"/>
	<path d="m285.11 16.797v-1.68h2.16v1.68h5.37v1.89h-12.899v-1.89h5.369zm7.035-1.079h-2.91l-2.985-3.42-2.985 3.42h-2.91l4.47-5.175h2.85l4.47 5.175zm-9.105 3.899h6.3c1.47 0 2.67 1.065 2.67 2.535s-1.2 2.535-2.67 2.535h-6.3c-1.47 0-2.67-1.065-2.67-2.535s1.2-2.535 2.67-2.535zm0 3.225h6.3c0.36 0 0.645-0.33 0.645-0.69s-0.285-0.69-0.645-0.69h-6.3c-0.36 0-0.645 0.33-0.645 0.69s0.285 0.69 0.645 0.69z"/>
	<path d="m301.89 10.573v1.965l-2.07 1.605 2.07 1.62v2.385l-3.6-2.805-3.78 2.955v-2.385l4.335-3.375h-4.335v-1.965h7.38zm2.01 8.775c1.485 0 2.67 1.2 2.67 2.669 0 1.47-1.185 2.67-2.67 2.67h-6.12c-1.47 0-2.67-1.2-2.67-2.67s1.2-2.669 2.67-2.669h6.12zm-6.12 2.025c-0.36 0-0.645 0.285-0.645 0.645s0.285 0.645 0.645 0.645h6.12c0.36 0 0.645-0.285 0.645-0.645s-0.285-0.645-0.645-0.645h-6.12zm6.645-8.101v-2.775h2.085v7.92h-2.085v-3.105h-1.8v-2.04h1.8z"/>
	<path d="m316.08 19.422v3.3h5.355v1.965h-12.899v-1.965h5.325v-3.3h-4.725v-8.925h2.325v3.045h7.05v-3.045h2.325v8.925h-4.756zm2.43-2.04v-1.815h-7.05v1.815h7.05z"/>
	<path d="m327.75 18.118h3.09v-0.765c-1.26-0.285-2.25-1.125-2.25-2.34v-0.855c0-0.405 0.12-0.78 0.315-1.11h-1.02v-2.04h2.82v-1.02h2.1v1.02h2.76v2.04h-1.035c0.21 0.33 0.33 0.705 0.33 1.11v0.855c0 1.125-0.87 1.935-1.995 2.265v0.84h4.695v-7.62h2.145v3.78h1.53v1.965h-1.53v3.69h-11.954v-1.815zm1.005 2.759h10.949v3.795h-2.145v-2.01h-8.805v-1.785zm1.74-6.119c0 0.63 0.525 1.05 1.23 1.05s1.23-0.42 1.23-1.05v-0.345c0-0.63-0.525-1.05-1.23-1.05s-1.23 0.42-1.23 1.05v0.345z"/>
	<path d="m346.94 10.573c1.815 0 3.27 1.47 3.27 3.27v1.5c0 1.815-1.455 3.27-3.27 3.27h-1.185c-1.815 0-3.27-1.455-3.27-3.27v-1.5c0-1.8 1.455-3.27 3.27-3.27h1.185zm-1.695 12.089h7.169v-12.164h2.1v14.189h-11.37v-4.62h2.1v2.595zm0.509-10.019c-0.66 0-1.2 0.54-1.2 1.2v1.5c0 0.66 0.54 1.2 1.2 1.2h1.185c0.66 0 1.2-0.54 1.2-1.2v-1.5c0-0.66-0.54-1.2-1.2-1.2h-1.185z"/>
	<path d="m477.44 17.607v2.025h-4.98v1.995h-2.04v-1.995h-2.835v3.015h9.135v2.04h-11.16v-5.055h-1.02v-2.025h12.9zm-3.42-7.034c1.47 0 2.67 1.29 2.67 2.925 0 1.65-1.2 2.94-2.67 2.94h-6.075c-1.47 0-2.67-1.29-2.67-2.94 0-1.635 1.2-2.925 2.67-2.925h6.075zm-6.075 1.98c-0.3 0-0.645 0.39-0.645 0.945s0.345 0.96 0.645 0.96h6.075c0.3 0 0.645-0.405 0.645-0.96s-0.345-0.945-0.645-0.945h-6.075z"/>
	<path d="m484.31 16.797v-1.68h2.16v1.68h5.37v1.89h-12.899v-1.89h5.369zm7.035-1.079h-2.91l-2.985-3.42-2.985 3.42h-2.91l4.47-5.175h2.85l4.47 5.175zm-9.105 3.899h6.3c1.47 0 2.67 1.065 2.67 2.535s-1.2 2.535-2.67 2.535h-6.3c-1.47 0-2.67-1.065-2.67-2.535s1.201-2.535 2.67-2.535zm0 3.225h6.3c0.36 0 0.645-0.33 0.645-0.69s-0.285-0.69-0.645-0.69h-6.3c-0.36 0-0.645 0.33-0.645 0.69s0.285 0.69 0.645 0.69z"/>
	<path d="m501.09 10.573v2.07l-1.995 1.56 2.07 1.62v2.505l-3.675-2.865-3.78 2.94v-2.505l4.26-3.255h-4.26v-2.07h7.38zm1.86 8.865c1.47 0 2.67 1.14 2.67 2.625 0 1.47-1.2 2.625-2.67 2.625h-6.12c-1.47 0-2.67-1.155-2.67-2.625 0-1.485 1.2-2.625 2.67-2.625h6.12zm-6.12 1.964c-0.36 0-0.645 0.3-0.645 0.66s0.285 0.645 0.645 0.645h6.12c0.345 0 0.645-0.285 0.645-0.645s-0.3-0.66-0.645-0.66h-6.12zm6.225-3.09v-7.815h2.25v2.715h1.53v2.04h-1.53v3.06h-2.25z"/>
	<path d="m512.91 17.413v-6.84h2.1v1.62h2.415v-1.62h2.1v6.84h-6.615zm9.6 0.944v-7.86h1.995v2.25h1.53v2.04h-1.53v7.485h-8.88v0.69h8.88v1.725h-10.995v-3.975h8.879v-0.66h-8.879v-1.695h9zm-5.085-2.819v-1.515h-2.415v1.515h2.415z"/>
	<path d="m535.89 16.408 0.63-3.69h-8.459v-2.145h10.649v2.22l-0.63 3.615h1.755v1.86h-12.899v-1.86h8.954zm-5.985 2.624v1.125h6.96v-1.125h2.1v5.655h-11.159v-5.655h2.099zm6.96 2.865h-6.96v1.05h6.96v-1.05z"/>
	<path d="m665.24 18.853v1.875h-5.4v3.959h-2.1v-3.959h-5.4v-1.875h12.9zm-6.134-6.24h-6.21v-2.025h11.789v1.725l-3.975 1.875 3.87 1.515v2.175l-6.21-2.58-5.475 2.58v-2.22l6.211-3.045z"/>
	<path d="m672.06 22.722v-4.035h2.25v4.035h5.325v1.965h-12.899v-1.965h5.324zm4.365-3.255-3.24-6.285-3.239 6.285h-2.774l4.59-8.924h2.85l4.59 8.924h-2.777z"/>
	<path d="m688.89 10.573v1.965l-2.07 1.605 2.07 1.62v2.385l-3.6-2.805-3.779 2.955v-2.385l4.334-3.375h-4.334v-1.965h7.379zm2.009 8.775c1.485 0 2.67 1.2 2.67 2.669 0 1.47-1.185 2.67-2.67 2.67h-6.119c-1.471 0-2.67-1.2-2.67-2.67s1.199-2.669 2.67-2.669h6.119zm-6.119 2.025c-0.36 0-0.645 0.285-0.645 0.645s0.284 0.645 0.645 0.645h6.119c0.36 0 0.646-0.285 0.646-0.645s-0.285-0.645-0.646-0.645h-6.119zm6.645-8.101v-2.775h2.085v7.92h-2.085v-3.105h-1.8v-2.04h1.8z"/>
	<path d="m703.08 19.422v3.3h5.355v1.965h-12.899v-1.965h5.325v-3.3h-4.725v-8.925h2.324v3.045h7.05v-3.045h2.325v8.925h-4.755zm2.431-2.04v-1.815h-7.05v1.815h7.05z"/>
	<path d="m722.49 10.573v1.965l-2.07 1.605 2.07 1.62v2.385l-3.6-2.805-3.779 2.955v-2.385l4.334-3.375h-4.334v-1.965h7.379zm2.01 8.775c1.485 0 2.67 1.2 2.67 2.669 0 1.47-1.185 2.67-2.67 2.67h-6.119c-1.471 0-2.67-1.2-2.67-2.67s1.199-2.669 2.67-2.669h6.119zm-6.119 2.025c-0.36 0-0.645 0.285-0.645 0.645s0.284 0.645 0.645 0.645h6.119c0.36 0 0.646-0.285 0.646-0.645s-0.285-0.645-0.646-0.645h-6.119zm6.644-8.101v-2.775h2.085v7.92h-2.085v-3.105h-1.8v-2.04h1.8z"/>
	<path d="m733.86 17.502 2.386 7.11h-2.101l-1.364-4.05-1.41 4.05h-2.266l4.26-12.03h-4.004v-2.01h6.225v2.01l-1.726 4.92zm4.89 7.185h-1.935v-6.975h-1.71v-1.92h1.71v-5.295h1.935v14.19zm3.045 0h-1.979v-14.189h1.979v14.189z"/>
	<path d="m845.24 10.573h5.641v1.875h-3.465v1.26h3.209v1.74h-3.209v1.185h3.719v1.86h-5.895v-7.92zm6.75-0.075h2.04v2.76h1.245v-2.76h2.039v10.755l-0.569 3.435h-2.235l0.586-3.435h-9.15v-1.86h9.33v-4.14h-1.245v3.39h-2.04v-8.145z"/>
	<path d="m865.52 24.612h-5.954v-14.04h2.069v4.965h1.815v-4.965h2.069v14.04zm-2.07-2.04v-4.995h-1.815v4.995h1.815zm8.55 2.115h-2.085v-7.169h-1.155v7.169h-2.025v-14.189h2.025v5.04h1.155v-5.04h2.085v14.189z"/>
	<path d="m886.29 10.573v2.07l-1.846 1.365 1.92 1.515v2.505l-3.584-2.79-3.87 2.865v-2.505l3.96-2.955h-3.96v-2.07h7.38zm-4.65 9.104h7.17v-9.18h2.1v14.189h-11.369v-6.075h2.1v1.066zm0 3.03h7.17v-1.17h-7.17v1.17z"/>
	<path d="m906.44 17.487h-1.53v7.2h-11.97v-2.04h3.211v-1.935c-1.426-0.36-2.49-1.44-2.49-2.73v-1.365c0-1.59 1.635-2.865 3.569-2.865s3.57 1.275 3.57 2.865v1.365c0 1.29-1.065 2.37-2.49 2.73v1.935h4.485v-12.15h2.114v5.01h1.53v1.98zm-10.23-6.344v-1.155h2.069v1.155h2.91v1.875h-7.875v-1.875h2.896zm-0.479 6.54c0 0.75 0.674 1.335 1.499 1.335s1.5-0.585 1.5-1.335v-0.765c0-0.75-0.675-1.335-1.5-1.335s-1.499 0.585-1.499 1.335v0.765z"/>
	<path d="m919.89 24.687v-4.125h-1.305v0.48c0 2.16-1.44 3.645-3.226 3.645-1.784 0-3.225-1.485-3.225-3.645v-6.9c0-2.16 1.44-3.645 3.225-3.645 1.695 0 3.075 1.35 3.21 3.33h1.32v-3.33h1.965v14.189h-1.964zm-3.406-10.544c0-0.825-0.39-1.47-1.125-1.47s-1.124 0.645-1.124 1.47v6.9c0 0.825 0.389 1.47 1.124 1.47s1.125-0.645 1.125-1.47v-6.9zm2.101 4.379h1.305v-2.67h-1.305v2.67zm4.244 6.165v-14.189h1.965v14.189h-1.965z"/>
	<path d="m931.9 10.573c1.515 0 2.896 1.185 2.896 2.46v2.895c0 1.275-1.381 2.46-2.896 2.46h-2.085c-1.529 0-2.91-1.185-2.91-2.46v-2.895c0-1.275 1.381-2.46 2.91-2.46h2.085zm6.031 14.114h-2.266l0.586-3.15h-8.715v-1.815h8.805v-9.225h2.159v1.665h1.53v1.83h-1.53v1.62h1.53v1.83h-1.53v4.095l-0.569 3.15zm-5.266-10.994c0-0.675-0.539-1.155-1.289-1.155h-1.035c-0.75 0-1.29 0.48-1.29 1.155v1.575c0 0.69 0.54 1.155 1.29 1.155h1.035c0.75 0 1.289-0.465 1.289-1.155v-1.575z"/>
	<path d="m1063.4 17.607v2.025h-4.979v1.995h-2.04v-1.995h-2.834v3.015h9.135v2.04h-11.16v-5.055h-1.02v-2.025h12.898zm-3.42-7.034c1.471 0 2.67 1.29 2.67 2.925 0 1.65-1.199 2.94-2.67 2.94h-6.074c-1.47 0-2.67-1.29-2.67-2.94 0-1.635 1.2-2.925 2.67-2.925h6.074zm-6.075 1.98c-0.3 0-0.645 0.39-0.645 0.945s0.345 0.96 0.645 0.96h6.074c0.301 0 0.646-0.405 0.646-0.96s-0.345-0.945-0.646-0.945h-6.074z"/>
	<path d="m1070.3 16.797v-1.68h2.16v1.68h5.369v1.89h-12.899v-1.89h5.37zm7.034-1.079h-2.909l-2.985-3.42-2.985 3.42h-2.91l4.471-5.175h2.85l4.468 5.175zm-9.105 3.899h6.3c1.47 0 2.67 1.065 2.67 2.535s-1.2 2.535-2.67 2.535h-6.3c-1.47 0-2.67-1.065-2.67-2.535s1.201-2.535 2.67-2.535zm0 3.225h6.3c0.36 0 0.646-0.33 0.646-0.69s-0.285-0.69-0.646-0.69h-6.3c-0.359 0-0.645 0.33-0.645 0.69s0.286 0.69 0.645 0.69z"/>
	<path d="m1087.1 10.573v2.07l-1.996 1.56 2.07 1.62v2.505l-3.675-2.865-3.78 2.94v-2.505l4.26-3.255h-4.26v-2.07h7.381zm1.859 8.865c1.47 0 2.67 1.14 2.67 2.625 0 1.47-1.2 2.625-2.67 2.625h-6.12c-1.47 0-2.67-1.155-2.67-2.625 0-1.485 1.2-2.625 2.67-2.625h6.12zm-6.12 1.964c-0.36 0-0.645 0.3-0.645 0.66s0.284 0.645 0.645 0.645h6.12c0.345 0 0.645-0.285 0.645-0.645s-0.3-0.66-0.645-0.66h-6.12zm6.226-3.09v-7.815h2.249v2.715h1.53v2.04h-1.53v3.06h-2.249z"/>
	<path d="m1110.6 22.333h-9.21v0.705h9.21v1.65h-11.159v-3.945h9.209v-0.69h-9.209v-1.59h4.529v-0.81h-5.399v-1.62h12.899v1.62h-5.4v0.81h4.53v3.87zm-4.424-11.31h4.484v1.725l-2.594 0.72 2.594 0.72v1.65l-5.504-1.575-5.775 1.575v-1.77l5.189-1.32h-5.189v-1.725h4.471v-1.035h2.324v1.035z"/>
	<path d="m1113.3 18.522v-4.815h4.545v-1.335h-4.545v-1.8h6.465v4.935h-4.604v1.215h4.694v1.8h-6.555zm9.87-6.584v-1.44h2.13v10.905l-0.57 3.285h-2.234l0.585-3.285h-9.149v-1.86h9.239v-2.415h-2.325v-1.89h2.325v-1.41h-2.325v-1.89h2.324z"/>

</svg>

  `,
  hoz_line: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 29" xml:space="preserve">
  <line style="fill:none;stroke:#CCCCCC;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="5" y1="9" x2="1196" y2="9"/>
  </svg>
  `,
  process_0: `
<svg enable-background="new 0 0 1200 40" version="1.1" viewBox="0 0 1200 40" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
    <line x1="1110" x2="1185" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <line x1="990.03" x2="1065" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <circle cx="1087.4" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
    <line x1="1082.2" x2="1092" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="1092" x2="1082.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="915.03" x2="989.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <line x1="795.03" x2="869.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <circle cx="892.44" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
    <line x1="887.2" x2="897" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="897" x2="887.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="720.03" x2="794.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <line x1="600.03" x2="674.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <circle cx="697.44" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
    <line x1="692.2" x2="702" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="702" x2="692.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="525.03" x2="599.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <line x1="405.03" x2="479.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <circle cx="502.44" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
    <line x1="497.2" x2="507" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="507" x2="497.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="330.03" x2="404.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <line x1="210.03" x2="284.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <circle cx="307.44" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
    <line x1="302.2" x2="312" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="312" x2="302.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="135.03" x2="209.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <line x1="15.028" x2="89.972" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
    <circle cx="112.44" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
    <line x1="107.2" x2="117" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    <line x1="117" x2="107.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
</svg>
`,
  process_1: `
<svg enable-background="new 0 0 1200 40" version="1.1" viewBox="0 0 1200 40" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="135.03" x2="209.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="15.028" x2="89.972" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="112.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="105.37 19 110.38 25.184 118.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
		<line x1="1110" x2="1185" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="990.03" x2="1065" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="1087.4" cy="20" r="14.43" fill="none" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
        <line x1="1082.2" x2="1092" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <line x1="1092" x2="1082.2" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="915.03" x2="989.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="795.03" x2="869.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="892.44" cy="20" r="14.43" fill="none" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
        <line x1="887.2" x2="897" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <line x1="897" x2="887.2" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="720.03" x2="794.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="600.03" x2="674.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="697.44" cy="20" r="14.43" fill="none" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
        <line x1="692.2" x2="702" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <line x1="702" x2="692.2" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="525.03" x2="599.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="405.03" x2="479.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="502.44" cy="20" r="14.43" fill="none" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
        <line x1="497.2" x2="507" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <line x1="507" x2="497.2" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="330.03" x2="404.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="210.03" x2="284.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="307.44" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
        <line x1="302.2" x2="312" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <line x1="312" x2="302.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
</svg>

  `,
  process_2: `
    <svg enable-background="new 0 0 1200 40" version="1.1" viewBox="0 0 1200 40" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="135.03" x2="209.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="15.028" x2="89.972" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="112.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="105.37 19 110.38 25.184 118.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="330.03" x2="404.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="210.03" x2="284.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="307.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="300.37 19 305.38 25.184 313.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
		<line x1="1110" x2="1185" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="990.03" x2="1065" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="1087.4" cy="20" r="14.43" fill="none" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
        <line x1="1082.2" x2="1092" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <line x1="1092" x2="1082.2" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="915.03" x2="989.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="795.03" x2="869.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="892.44" cy="20" r="14.43" fill="none" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
        <line x1="887.2" x2="897" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <line x1="897" x2="887.2" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="720.03" x2="794.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="600.03" x2="674.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="697.44" cy="20" r="14.43" fill="none" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
        <line x1="692.2" x2="702" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <line x1="702" x2="692.2" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="525.03" x2="599.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="405.03" x2="479.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="502.44" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
        <line x1="497.2" x2="507" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <line x1="507" x2="497.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
</svg>
  `,
  process_3: `
  <svg enable-background="new 0 0 1200 40" version="1.1" viewBox="0 0 1200 40" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="135.03" x2="209.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="15.028" x2="89.972" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="112.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="105.37 19 110.38 25.184 118.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="330.03" x2="404.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="210.03" x2="284.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="307.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="300.37 19 305.38 25.184 313.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="525.03" x2="599.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="405.03" x2="479.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="502.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="495.37 19 500.38 25.184 508.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
		<line x1="1110" x2="1185" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="990.03" x2="1065" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="1087.4" cy="20" r="14.43" fill="none" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
				<line x1="1082.2" x2="1092" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
				<line x1="1092" x2="1082.2" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="915.03" x2="989.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="795.03" x2="869.97" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="892.44" cy="20" r="14.43" fill="none" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
				<line x1="887.2" x2="897" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
				<line x1="897" x2="887.2" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="720.03" x2="794.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="600.03" x2="674.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="697.44" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
				<line x1="692.2" x2="702" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
				<line x1="702" x2="692.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
</svg>
  `,
  process_4: `
 <svg enable-background="new 0 0 1200 40" version="1.1" viewBox="0 0 1200 40" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="135.03" x2="209.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="15.028" x2="89.972" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="112.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="105.37 19 110.38 25.184 118.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="330.03" x2="404.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="210.03" x2="284.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="307.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="300.37 19 305.38 25.184 313.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="525.03" x2="599.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="405.03" x2="479.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="502.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="495.37 19 500.38 25.184 508.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="720.03" x2="794.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="600.03" x2="674.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="697.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="690.37 19 695.38 25.184 703.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
		<line x1="1110" x2="1185" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="990.03" x2="1065" y1="20.229" y2="20.229" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="1087.4" cy="20" r="14.43" fill="none" stroke="#bbb" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
				<line x1="1082.2" x2="1092" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
				<line x1="1092" x2="1082.2" y1="14.687" y2="24.489" fill="#fff" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
		<line x1="915.03" x2="989.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="795.03" x2="869.97" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="892.44" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
				<line x1="887.2" x2="897" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
				<line x1="897" x2="887.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
</svg>
  `,
  process_5: `
  <svg enable-background="new 0 0 1200 40" version="1.1" viewBox="0 0 1200 40" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="135.03" x2="209.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="15.028" x2="89.972" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="112.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="105.37 19 110.38 25.184 118.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="330.03" x2="404.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="210.03" x2="284.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="307.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="300.37 19 305.38 25.184 313.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="525.03" x2="599.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="405.03" x2="479.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="502.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="495.37 19 500.38 25.184 508.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="720.03" x2="794.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="600.03" x2="674.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="697.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="690.37 19 695.38 25.184 703.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
		<line x1="915.03" x2="989.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<line x1="795.03" x2="869.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
		<circle cx="892.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
		<polyline points="885.37 19 890.38 25.184 898.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
		<line x1="1110" x2="1185" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<line x1="990.03" x2="1065" y1="20.229" y2="20.229" fill="#fff" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.0397"/>
		<circle cx="1087.4" cy="20" r="14.43" fill="none" stroke="#FF1D25" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.8477"/>
				<line x1="1082.2" x2="1092" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
				<line x1="1092" x2="1082.2" y1="14.687" y2="24.489" fill="#fff" stroke="#ED1C24" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
</svg>
  `,
  process_6: `
  <svg enable-background="new 0 0 1200 40" version="1.1" viewBox="0 0 1200 40" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<g stroke="#39B54A" stroke-linecap="round" stroke-miterlimit="10">
			<line x1="135.03" x2="209.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="15.028" x2="89.972" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="112.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="105.37 19 110.38 25.184 118.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
			<line x1="330.03" x2="404.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="210.03" x2="284.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="307.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="300.37 19 305.38 25.184 313.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
			<line x1="525.03" x2="599.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="405.03" x2="479.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="502.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="495.37 19 500.38 25.184 508.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
			<line x1="720.03" x2="794.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="600.03" x2="674.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="697.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="690.37 19 695.38 25.184 703.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
			<line x1="915.03" x2="989.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="795.03" x2="869.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="892.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="885.37 19 890.38 25.184 898.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
			<line x1="1110" x2="1185" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="990.03" x2="1065" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="1087.4" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="1080.4 19 1085.4 25.184 1093.6 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
</svg>
  `,
  process_999: `
  <svg enable-background="new 0 0 1200 40" version="1.1" viewBox="0 0 1200 40" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
<g stroke="#ffb703" stroke-linecap="round" stroke-miterlimit="10">
			<line x1="135.03" x2="209.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="15.028" x2="89.972" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="112.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="105.37 19 110.38 25.184 118.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
			<line x1="330.03" x2="404.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="210.03" x2="284.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="307.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="300.37 19 305.38 25.184 313.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
			<line x1="525.03" x2="599.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="405.03" x2="479.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="502.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="495.37 19 500.38 25.184 508.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
			<line x1="720.03" x2="794.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="600.03" x2="674.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="697.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="690.37 19 695.38 25.184 703.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
			<line x1="915.03" x2="989.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="795.03" x2="869.97" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="892.44" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="885.37 19 890.38 25.184 898.62 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
			<line x1="1110" x2="1185" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<line x1="990.03" x2="1065" y1="20.229" y2="20.229" fill="#fff" stroke-width="4.0397"/>
			<circle cx="1087.4" cy="20" r="14.43" fill="none" stroke-width="4.8477"/>
			<polyline points="1080.4 19 1085.4 25.184 1093.6 15.564" fill="none" stroke-linejoin="round" stroke-width="4.8477"/>
</g>
</svg>
  `,
};
