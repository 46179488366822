import { makeCanvasByUrl, NormalizeHex } from '../../../../Canvas/S/util.js';
import { NewMakerBaseProductsWebGlProcessingF } from '../../../../NewMaker/BaseProducts/WebGlProcessing/F/Function/module/NewMakerBaseProductsWebGlProcessingF.js';

export const makeCanvasWithOutline = async (trimmed_canvas, hex_color, dpi) => {
  const rgba = NormalizeHex(hex_color);
  const offset_pixel = 2;
  const margin_canvas = document.createElement('canvas');
  const margin = offset_pixel + 10;
  margin_canvas.width = trimmed_canvas.width + margin * 2;
  margin_canvas.height = trimmed_canvas.height + margin * 2;
  const margin_canvas_ctx = margin_canvas.getContext('2d');
  margin_canvas_ctx.drawImage(
    trimmed_canvas,
    0,
    0,
    trimmed_canvas.width,
    trimmed_canvas.height,
    margin,
    margin,
    trimmed_canvas.width,
    trimmed_canvas.height,
  );
  const outline_layer_url = await NewMakerBaseProductsWebGlProcessingF.getOutlineLayer({
    uri: margin_canvas.toDataURL('image/png', 1),
    colorDistancePercent: 0.0,
    offsetPixel: offset_pixel,
    color: rgba,
  });
  const merged_canvas = await makeCanvasByUrl(outline_layer_url);
  const ctx = merged_canvas.getContext('2d');
  ctx.drawImage(margin_canvas, 0, 0);
  return merged_canvas;
};
