export const SVG_ICONS = {
  BULLET: `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <circle cx="8" cy="8" r="1.2" fill="black"/>
        </svg>`,
  PLUS: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M1.66675 9.99984H10.0001M10.0001 9.99984H18.3334M10.0001 9.99984V18.3332M10.0001 9.99984V1.6665"
          stroke="black"
          stroke-width="1.25"
          stroke-linecap="round"
        />
      </svg>`,
  X: `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path d="M1.5 1.5L10.5 10.5M1.5 10.5L10.5 1.5" stroke="black" stroke-linecap="round"/>
      </svg>`,
  DOWN: `
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.14453 16.6665H10.0017H17.8588" stroke="white" stroke-linecap="round"/>
        <path d="M10 3.57129L10 8.15462L10 12.738" stroke="white" stroke-linecap="round"/>
        <path d="M13.9297 8.80957L11.9654 10.7739L10.0011 12.7381" stroke="white" stroke-linecap="round"/>
        <path d="M6.07422 8.80957L8.0385 10.7739L10.0028 12.7381" stroke="white" stroke-linecap="round"/>
      </svg>
      `,
  WARN: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M2.5498 12C2.5498 6.78089 6.78071 2.54999 11.9998 2.54999C17.2189 2.54999 21.4498 6.78089 21.4498 12C21.4498 17.2191 17.2189 21.45 11.9998 21.45C6.78071 21.45 2.5498 17.2191 2.5498 12Z" stroke="#FF6B00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.8156 6.86401L12.689 14.1062H11.2969L11.1703 6.86401H12.8156ZM11.0015 16.1453C10.9945 15.5968 11.4515 15.1468 12 15.1468C12.5414 15.1468 12.9914 15.5968 12.9984 16.1453C12.9914 16.6937 12.5414 17.1367 12 17.1437C11.4515 17.1367 10.9945 16.6937 11.0015 16.1453Z" fill="#FF6B00"/>
        </svg>`,
};
