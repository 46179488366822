import { go, ippL, join, map, reject } from 'fxjs/es';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

export const message_collections = go(
  [
    {
      service_name: '넥슨 에센셜',
      title: '부재시 문발',
      sub_title: '주문건',
      content: ({ worker_name = '00', projection_id = '000000' }) =>
        `[NEXON ESSENTIAL]
안녕하세요. 넥슨에센셜 고객센터입니다.
주문번호 MP${projection_id} / 주문해주신 상품 관련하여 연락 드렸으나, 부재중이셔서 문자 드립니다. 연락 가능하실 때 1566-7960 으로 연락 부탁드리겠습니다. 감사합니다 :) -문자수신불가-`,
    },
    {
      service_name: '넥슨 에센셜',
      title: '사진요청(불량)',
      sub_title: '주문건',
      content: ({ worker_name = '00', projection_id = '000000' }) =>
        `[NEXON ESSENTIAL]
안녕하세요. 넥슨에센셜 고객센터입니다.
주문번호 MP${projection_id}/ 수령하신 상품으로 불편을 드리게 되어 죄송합니다. 
nexon@marpple.com 메일 주소로 주문번호와 함께 상품 사진 보내주시면 확인 후 안내드리겠습니다. 감사합니다. -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '부재시 문발',
      sub_title: '주문건',
      content: ({ worker_name = '00', projection_id = '000000' }) =>
        `[마플] 안녕하세요 고객님! 마플 운영팀 ${worker_name}입니다. 주문번호 : ${projection_id} / 주문해주신 상품 관련하여 연락 드렸으나, 부재중이셔서 문자 드립니다. 연락 가능하실 때 070-7815-XXXX(직통)로 연락 부탁드리겠습니다. 감사합니다 :) -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '부재시 문발',
      sub_title: '일정',
      content: ({ worker_name = '00', projection_id = '000000' }) =>
        `[마플] 안녕하세요 고객님! 마플 운영팀 ${worker_name}입니다. 주문번호 : ${projection_id} / 주문해주신 상품 일정 관련 건으로 연락 드렸으나, 부재중이어 문자 드립니다. 연락 가능하실 때  070-7815-XXXX(직통) 으로 연락 부탁드리겠습니다. 감사합니다 :) -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '부재시 문발',
      sub_title: '무지',
      content: ({ worker_name = '00', projection_id = '000000' }) =>
        `[마플_무지안내] 고객님 안녕하세요? 마플 운영팀 ${worker_name}입니다. 주문번호 : ${projection_id} / 주문해주신 상품 무지관련으로 안내해드릴 사항이 있어서 연락드렸습니다만 부재중으로 문자드립니다. 통화가능하실 때 070-7815-XXXX(직통)로 연락부탁드립니다. 감사합니다. -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '부재시 문발',
      sub_title: '시안',
      content: ({ worker_name = '00', projection_id = '000000' }) =>
        `[마플] 안녕하세요 고객님! 마플 운영팀 ${worker_name}입니다. 주문번호 : ${projection_id} / 고객님께서 주문하신 상품의 수정된 시안을 보여드리고자 연락 드립니다. 함께 보내드린 링크를 통해 시안 확인 뒤 이대로 진행 도와드려도 괜찮으실지  070-7815-XXXX(직통) 로 연락 부탁드리겠습니다. 감사합니다 :) -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '부재시 문발',
      sub_title: '입금확인',
      content: ({ worker_name = '00', projection_id = '000000' }) =>
        `[마플] 안녕하세요 고객님! 마플 운영팀 ${worker_name}입니다. 주문번호 : ${projection_id} / 주문서 진행여부 및 입금 확인 안내차 연락드렸으나, 부재중이셔서 문자 드립니다. 연락 가능하실 때 070-7815-xxxx(직통)로 연락 부탁드리겠습니다. 감사합니다 :) -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '부재시 문발',
      sub_title: '세금계산서 발행일자 확인',
      content: ({ worker_name = '00', projection_id = '000000' }) =>
        `[마플] 고객님, 안녕하세요:) 마플운영팀 ${worker_name}입니다. 
주문번호 ${projection_id} / 주문해주신 상품 ★세금계산서 발행일자★ 관련하여 연락드렸습니다만, 부재중이셔서 문자드립니다. 통화가능하실 때 070-7848-XXXX(직통)로 연락 부탁드립니다. 좋은 하루 보내세요~ 감사합니다. -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '일정(문발만진행)',
      sub_title: '일정 안내',
      content: ({
        projection_id = '000000',
      }) => `[마플] 안녕하세요 고객님! 마플입니다. 제작 일정 확인되어 안내드립니다. 주문번호 : ${projection_id} / 고객님의 주문건은 ★월 일~ 월 일★  저녁 출고[발송] 예정입니다.다만, 정확한 택배 도착 날짜는 안내 어려운점 양해 부탁드립니다.
출고시 보내드리는 송장번호로 배송현황 추적 부탁드립니다. 출고 이후 배송 관련된 자세한 사항은 CJ대한통운 고객센터 1588-1255 로 문의 바랍니다. 감사합니다 :) -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '방문수령일정안내(문발만진행)',
      sub_title: '',
      content: ({
        projection_id = '000000',
        worker_name = '00',
      }) => `[마플_방문수령 일정 안내] 고객님 안녕하세요. 마플 운영팀 ${worker_name}입니다. 주문번호 : ${projection_id} / 고객님께서 주문해주신 상품은 00일(0)~00일(0) 제작이 완료될 예정이며, 제작 완료 시 발송되는 알림 문자를 수신하신 후 방문 부탁드립니다. 감사합니다.
[방문하는곳 : 서울시 금천구 가산디지털1로 75-24 아이에스비즈타워 10층 / 방문시간 : 월~금 오전10시부터 오후 8시까지] -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '문발만 진행',
      sub_title: '색감',
      content:
        () => `[마플_이미지색감안내] 고객님 안녕하세요? 저희 마플에서 제품을 주문해주셔서 진심으로 감사드립니다.
제품에 있는 이미지 색상은 실제 제작 시 색감이 다운되어 화면에서 보는 것과는 다소 색감 차이가 발생할 수 있습니다.
(예 : 형광, 비비드한 컬러등은 어둡고 탁해질 수 있음) 이 부분 참고 부탁드리며, 최대한 예쁜 색상으로 만족할만한 상품을 받으실 수 있도록 제작하여 보내드리겠습니다. 감사합니다. -문자수신불가- `,
    },
    {
      service_name: '마플',
      title: '파일 요청',
      sub_title: '',
      content: ({ projection_id = '000000' }) =>
        `[마플] help@marpple.com 본 메일주소로 주문번호 ★${projection_id}★ 을(를) 제목에 함께 기입하여 파일 전송 부탁드리겠습니다. 감사합니다 :) -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '내부 자수실',
      sub_title: '',
      content: () =>
        `[마플] 자수실 컬러 URL : https://marpple.com/si/FKScaJCqVa 색상 실번호 확인 부탁드립니다. 감사합니다 :) -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '추가금 안내',
      sub_title: '자수비',
      content: ({ client_name = '00' }) =>
        `[마플] 국민은행 주식회사마플코퍼레이션 037601-04-041747 으로 자수비 총 10,000원 ${client_name}님 성함으로 입금 부탁드리겠습니다. 감사합니다 :) -문자수신불가-`,
    },
    {
      service_name: '마플',
      title: '추가금 안내',
      sub_title: '배송비',
      content: () =>
        '[마플] 국민은행 주식회사마플코퍼레이션 037601-04-041747 으로 추가 배송비 총 3,000원 입금 부탁드리겠습니다. 감사합니다 :) -문자수신불가-',
    },
    {
      service_name: '마플샵',
      title: '부재시 문발',
      sub_title: '주문건',
      content: ({
        projection_id = '000000',
      }) => `[마플샵] 안녕하세요! 주문번호 : ${projection_id} / 고객님의 주문건 관련하여 안내드리고자 연락드렸으나 부재중이셔서 문자드립니다. 
편하신 시간에 070-7815-XXXX(직통) 로 꼭 연락부탁드립니다. 감사합니다. - 문자수신불가-`,
    },
    {
      service_name: '마플샵',
      title: '부재시 문발',
      sub_title: '크리에이터',
      content: ({
        projection_id = '000000',
      }) => `[마플샵] 안녕하세요. 크리에이터님의 상품 관련하여 안내드리고자 연락드렸으나 부재중이셔서 문자드립니다. 
편하신 시간에 070-7815-XXXX(직통)로 주문번호 : ${projection_id} 관련해서 연락주셨다고 말씀주시면 안내 도와드리도록 하겠습니다. 감사합니다. - 문자수신불가-`,
    },
    {
      service_name: '마플샵',
      title: '부재시 문발',
      sub_title: '색감',
      content:
        () => `[마플샵_이미지색감안내] 고객님 안녕하세요? 저희 마플샵에서 제품을 주문해주셔서 진심으로 감사드립니다. 
제품에 있는 이미지 색상은 실제 제작 시 색감이 다운되어 화면에서 보는 것과는 다소 색감 차이가 발생할 수 있습니다.
(예 : 형광, 비비드한 컬러등은 어둡고 탁해질 수 있음) 이 부분 참고 부탁드리며, 최대한 예쁜 색상으로 만족할만한 상품을 받으실 수 있도록 제작하여 보내드리겠습니다. 감사합니다. -문자수신불가- `,
    },
    {
      service_name: '마플',
      title: '지류 문발',
      sub_title: '파일수정사항안내',
      content: ({ orderer_name, ups, projection_id }) => {
        const error_items = go(
          ups,
          reject((up) => UtilS.isEmpty(up._?.pdf_file?.issue_customer)),
          map((up) => {
            const { issue_customer } = up._?.pdf_file || {};
            return `상품번호 #${up.id}${issue_customer ? ` - ${issue_customer}` : ''}\n`;
          }),
          join(''),
        );

        return `[마플] 안녕하세요. 마플에서 구매해주셔서 감사합니다. 주문번호 : ${projection_id}_${
          error_items ? `${error_items}\n` : ''
        }주문내역보기 https://www.marpple.com/kr/order/detail/${projection_id}
-문자수신불가-
`;
      },
    },
  ],
  ippL,
  map(([i, v]) => ({
    id: i + 1,
    ...v,
  })),
);
