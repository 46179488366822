import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
export const emailVerifyPopUpMoTmpl = (user) => {
  const email_verify_request_desc = TT('auth::email_verify::email_verify_01');
  const email_verify_request_btn = TT('auth::email_verify::email_verify_03');
  return html`
    <div class="omp-email-verify-request">
      <div class="omp-email-verify-request__desc">
        <span>${email_verify_request_desc}</span>
        <span class="bold">${user?.email}</span>
      </div>
      ${OMPCoreAtomTmplS.actionButton({
        klass: 'omp-email-verify-request__request-button',
        text: email_verify_request_btn,
        solidity: 'thick',
        type: 'button',
      })}
    </div>
  `;
};
