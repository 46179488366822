import { $setVal } from 'fxdom/es';
import { PRODUCT_NAME_BLACK_LIST_REGX } from '../../../../Util/S/constant.js';
import { MShopUtilS } from '../../S/Function/module/MShopUtilS.js';

export const setProductNameBlackList = (value, target$) => {
  if (MShopUtilS.isProductNameBlackList(value)) {
    $.alert(T('사용 불가능한 단어가 포함되어 있습니다.'));
    $setVal(value.replace().replace(PRODUCT_NAME_BLACK_LIST_REGX, ''), target$);
    return true;
  }
};
