import { extend, filter, go, map, reject, sel } from 'fxjs/es';
import { duplicateLocationSize } from '../../../../../modules/Maker/F/util.js';
import { removeCvPreview } from '../../../../../modules/Maker/F/CvPreview/cv_object.js';
import { hasEmbroInMaker } from '../../../../../modules/Maker/F/overflow.js';
import { NewMakerUtilF } from '../../../../../modules/NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

!(function () {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  function is_apple_device() {
    const isIphone = navigator.userAgent.indexOf('iPhone') !== -1;
    const isIpod = navigator.userAgent.indexOf('iPod') !== -1;
    const isIpad = navigator.userAgent.indexOf('iPad') !== -1;
    return isIphone || isIpod || isIpad;
  }

  G.mp.maker = G.mp.maker || {};
  G.mp.maker = _p.extend(G.mp.maker, {
    is_android_chrome: UtilF.isLegacyMobile() && navigator.userAgent.indexOf('Chrome') !== -1,
    is_apple_device: is_apple_device(),
    is_marpple: location.href.indexOf('marpple.com') !== -1,
    isLegacyMobile: () => UtilF.isLegacyMobile(),
    cross_origin_query_str: '?canvas=v3',
    is_ie: /trident/i.test(ua),
    isFacebookApp: ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1,
    isInstagramApp: ua.indexOf('Insta') > -1,
    zoom_attr: {
      offset: { top: 0, left: 0 },
    },
    fake_mp_maker_size: {},
  });

  G.mp.maker.removeCvPreview = removeCvPreview;
  G.mp.maker.duplicateLocationSize = duplicateLocationSize;
  G.mp.maker.initWindowSizeInMaker = function () {
    G.mp.maker.window_height = $.height(window);
    G.mp.maker.window_width = $.width(window);
    G.mp.maker.canvas_container_height = G.mp.maker.window_height;
    $.attr($1('html'), {
      body_height: G.mp.maker.window_height - (G.mp.maker.window_height % 100),
    });
    G.mp.maker.CANVAS_WIDTH_ORIGIN = 860;
    G.mp.maker.NSCREEN_ZOOM = 1;
    G.mp.maker.CANVAS_WIDTH = G.mp.maker.CANVAS_HEIGHT = G.mp.maker.CANVAS_WIDTH_ORIGIN;
    if (UtilF.isLegacyMobile() && $1('html.maker_page'))
      G.mp.maker.CANVAS_HEIGHT = Math.round(
        G.mp.maker.window_height * (G.mp.maker.CANVAS_WIDTH / $.width(window)),
      );
  };
  G.mp.maker.cornerSize = function () {
    let cornerSize = UtilF.isLegacyMobile() ? 60 : UtilF.isLegacyTablet() ? 40 : 18;
    const scale = G.mp.maker.zoom_attr.scale;
    if (scale) cornerSize /= scale;
    return cornerSize;
  };

  G.mp.maker.BASECONF = function () {
    const cornerSize = G.mp.maker.cornerSize();
    const mp_maker_el = $1('#maker_frame');
    if (
      mp_maker_el?.dataset?.is_carved_phonecase_product === 'true' ||
      mp_maker_el?.dataset?.is_embroidery_app === 'true'
    ) {
      return {
        borderColor: NewMakerUtilF.getMakerBoxColor(0.5),
        hasControls: false,
        borderOpacityWhenMoving: 1,
        borderScaleFactor: 2,
        lockScalingFlip: true,
        lockMovementX: true,
        lockMovementY: true,
      };
    }

    return {
      cornerColor: UtilF.isLegacyMobile()
        ? NewMakerUtilF.getMakerBoxColor(0.8)
        : NewMakerUtilF.getMakerBoxColor(1),
      borderColor: NewMakerUtilF.getMakerBoxColor(0.5),
      cornerStrokeColor: 'white',
      cornerSize: cornerSize,
      cornerStyle: 'circle',
      cornerStrokeWidth: 2,
      hasControls: true,
      transparentCorners: false,
      lockScalingFlip: true,
    };
  };

  // export const default_text_attr = extend(T.lang == 'kr' ? {
  G.mp.maker.TEXT = extend(
    T.lang === 'kr'
      ? {
          text: '텍스트',
          fontSize: 60,
          textAlign: 'center',
          lineHeight: 1,
          fontFamily: 'Nanum Myeongjo',
          fontWeight: 'normal',
          globalCompositeOperation: 'source-over',
        }
      : T.lang === 'en'
      ? {
          text: 'TEXT',
          fontSize: 60,
          textAlign: 'center',
          lineHeight: 1,
          fontFamily: 'Bree Serif',
          fontWeight: 'normal',
          globalCompositeOperation: 'source-over',
        }
      : {
          text: 'TEXT',
          fontSize: 60,
          textAlign: 'center',
          lineHeight: 1,
          fontFamily: 'ipaexm',
          fontWeight: 'normal',
          globalCompositeOperation: 'source-over',
        },
    G.mp.maker.BASECONF(),
  );

  G.mp.maker.initWindowSizeInMaker();

  G.mp.maker.reject_ai = _p.reject(function (design) {
    return (
      design._data.image_width === 0 ||
      design._data.image_height === 0 ||
      (design.src && design.src.indexOf('.ai') >= 0)
    );
  });

  // if (G.mp.maker.is_android_chrome) $.add_class($1('html'), 'is_chrome');
  G.mp.maker.url = G.remove_protocol(function (url, zoom) {
    if (!url || url.indexOf('base64') !== -1) return url;
    zoom = zoom || 1;
    url = zoom > 1 ? G.to_original(url) : url;
    return url;
  });

  G.mp.maker.url2 = G.remove_protocol(function (url) {
    return url;
  });

  G.mp.maker.reset_corner_size2 = _tap(function (cv_object) {
    if (!cv_object) return;
    if (cv_object._data.is_not_design) return;
    const conf = G.mp.maker.BASECONF();
    _extend(cv_object, conf);

    if ($1('#maker_frame.pinch_mode')) return;
    const size = Math.max(G.mp.maker.fabric_get_width(cv_object), G.mp.maker.fabric_get_height(cv_object));
    cv_object.cornerSize = conf.cornerSize;
    cv_object.cornerColor = _p.v(cv_object, '_data.is_embro')
      ? conf.cornerColor
      : NewMakerUtilF.getMakerBoxColor(Math.min(size / 250, 0.8));
    cv_object.borderColor = _p.v(cv_object, '_data.is_embro')
      ? conf.borderColor
      : NewMakerUtilF.getMakerBoxColor(Math.min(size / 250, 1));
    cv_object.setControlsVisibility({
      ml: false,
      mb: false,
      mr: false,
      mt: false,
      wow: false,
    });
    if (_p.v(cv_object, '_data.cv_type') === 'cv_flexible_rect') {
      cv_object.setControlsVisibility({
        ml: true,
        mb: true,
        mr: true,
        mt: true,
        wow: false,
      });
    }

    if (hasEmbroInMaker(cv_object)) {
      cv_object.setControlsVisibility({
        tl: false,
        tr: false,
        bl: false,
        br: false,
      });
    }
    // cv_object.setControlsVisibility({ ml: false, mb: false, mt: false });
  });

  G.mp.maker.fabric_get_width = function ({ scaleX, width, strokeWidth = 0 }) {
    return scaleX * (width + strokeWidth);
  };

  G.mp.maker.fabric_get_height = function ({ scaleY, height, strokeWidth = 0 }) {
    return scaleY * (height + strokeWidth);
  };

  G.mp.maker.nscreen = function (cv_object) {
    if (!cv_object._data) return cv_object;
    if (cv_object._data.nscreened) return cv_object;
    cv_object.top = cv_object.top / G.mp.maker.NSCREEN_ZOOM;
    cv_object.top += (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2;
    cv_object.left = cv_object.left / G.mp.maker.NSCREEN_ZOOM;
    cv_object.scaleX = cv_object.scaleX / G.mp.maker.NSCREEN_ZOOM;
    cv_object.scaleY = cv_object.scaleY / G.mp.maker.NSCREEN_ZOOM;
    cv_object._data.nscreened = true;
    return cv_object;
  };
  G.mp.maker.nscreen_scale = function (cv_object) {
    if (cv_object._data.nscreened) return cv_object;
    cv_object.scaleX = cv_object.scaleX / G.mp.maker.NSCREEN_ZOOM;
    cv_object.scaleY = cv_object.scaleY / G.mp.maker.NSCREEN_ZOOM;

    cv_object._data.nscreened = true;
    return cv_object;
  };
  G.mp.maker.nscreen2 = function (css_attrs) {
    css_attrs.top = parseInt(css_attrs.top) / G.mp.maker.NSCREEN_ZOOM;
    css_attrs.top += (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2;
    css_attrs.left = parseInt(css_attrs.left) / G.mp.maker.NSCREEN_ZOOM;
    css_attrs.width = parseInt(css_attrs.width) / G.mp.maker.NSCREEN_ZOOM;
    css_attrs.height = parseInt(css_attrs.height) / G.mp.maker.NSCREEN_ZOOM;
    return css_attrs;
  };
  G.mp.maker.onescreen = function (cv_object) {
    if (!cv_object._data) return cv_object;
    if (!cv_object._data.nscreened) return cv_object;
    cv_object.top -= (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2;
    cv_object.top = cv_object.top * G.mp.maker.NSCREEN_ZOOM;
    cv_object.left = cv_object.left * G.mp.maker.NSCREEN_ZOOM;
    cv_object.scaleX = cv_object.scaleX * G.mp.maker.NSCREEN_ZOOM;
    cv_object.scaleY = cv_object.scaleY * G.mp.maker.NSCREEN_ZOOM;
    cv_object._data = _defaults({ nscreened: false }, cv_object._data);
    return cv_object;
  };

  G.mp.maker.onescreen2 = function (cv_attr) {
    cv_attr.top -= (G.mp.maker.CANVAS_HEIGHT - G.mp.maker.CANVAS_WIDTH) / 2;
    cv_attr.top = cv_attr.top * G.mp.maker.NSCREEN_ZOOM;
    cv_attr.left = cv_attr.left * G.mp.maker.NSCREEN_ZOOM;
    cv_attr.scaleX = cv_attr.scaleX * G.mp.maker.NSCREEN_ZOOM;
    cv_attr.scaleY = cv_attr.scaleY * G.mp.maker.NSCREEN_ZOOM;
    return cv_attr;
  };

  G.mp.maker.RECTANGLE = _p.extend(
    {
      width: 100,
      height: 100,
      fill: '#9999ff',
      globalCompositeOperation: 'source-atop',
      // globalCompositeOperation: 'source-over'
    },
    G.mp.maker.BASECONF(),
  );

  G.mp.maker.TRIANGLE = _p.extend(
    {
      width: 120,
      height: 100,
      fill: '#ff7777',
      globalCompositeOperation: 'source-atop',
      // globalCompositeOperation: 'source-over'
    },
    G.mp.maker.BASECONF(),
  );

  G.mp.maker.CIRCLE = _p.extend(
    {
      radius: 60,
      fill: '#ffff55',
      globalCompositeOperation: 'source-atop',
      // globalCompositeOperation: 'source-over'
    },
    G.mp.maker.BASECONF(),
  );

  G.mp.maker.HEART = _p.extend(
    {
      fill: 'red',
      globalCompositeOperation: 'source-atop',
      // globalCompositeOperation: 'source-over'
    },
    G.mp.maker.BASECONF(),
  );

  G.mp.maker.STAR = _p.extend(
    {
      numPoints: 5,
      innerRadius: 50,
      outerRadius: 100,
      fill: '#77cccc',
      globalCompositeOperation: 'source-atop',
      // globalCompositeOperation: 'source-over'
    },
    G.mp.maker.BASECONF(),
  );

  G.mp.maker.POLYGON = _p.extend(
    {
      sideCount: 6,
      radius: 80,
      settings: {
        fill: '#5599ff',
        globalCompositeOperation: 'source-atop',
        // globalCompositeOperation: 'source-over'
      },
    },
    G.mp.maker.BASECONF(),
  );

  G.mp.maker.IMAGE = _p.extend(
    {
      //width: ,
      //height: ,
      // globalCompositeOperation: 'source-atop'
      globalCompositeOperation: 'source-over',
    },
    G.mp.maker.BASECONF(),
  );

  G.mp.maker.BLUE_LINE = {
    //opacity: 0, // fill, stroke 포함
    stroke: 'blue',
    // stroke: 'rgba(246, 5, 50, 1)',
    fill: 'rgba(0,0,0,0)',
    strokeWidth: 2 /*,
    strokeDashArray: [6, 3],
    strokeLineJoin: 'miter',
    rx: 40,
    ry: 40*/,
  };

  G.mp.maker.IMG_LINE = {
    fill: 'rgba(0,0,0,0)',
    strokeWidth: 2,
    strokeDashArray: [6, 3],
  };

  G.mp.maker.designs = function (canvas) {
    return G.mp.maker.designs2(_p.v(canvas || G.mp.maker.editing_canvas(), '_objects'));
  };

  G.mp.maker.not_locked_designs = function (canvas) {
    return go(
      G.mp.maker.designs(canvas),
      reject((cv_obj) => cv_obj._data.clone_parent_cid),
      filter(sel('evented')),
    );
  };
  G.mp.maker.locked_designs = function (canvas) {
    return go(
      G.mp.maker.designs(canvas),
      reject((cv_obj) => cv_obj._data.clone_parent_cid),
      reject(sel('evented')),
    );
  };

  G.mp.maker.not_designs = function (canvas) {
    return G.mp.maker.not_designs2(_p.v(canvas || G.mp.maker.editing_canvas(), '_objects'));
  };

  G.mp.maker.designs2 = _p.reject(function (obj) {
    return obj._data.is_not_design;
  });

  G.mp.maker.not_designs2 = _p.filter(function (obj) {
    return obj._data.is_not_design || obj._data.clone_parent_cid;
  });

  G.mp.maker.all_designs = function () {
    const canvass = _p.initial(
      box.sel('maker->canvass'),
      box.sel('maker->canvass').length -
        box.sel('maker->product_color->_->base_product_color->_->base_product_color_faces').length,
    );
    return go(
      canvass,
      map((canvas) =>
        go(
          G.mp.maker.designs(canvas),
          reject((cv_obj) => cv_obj._data?.cv_type === 'cv_background'),
        ),
      ),
    );
  };

  G.mp.maker.unselect_all = function (canvas) {
    canvas = canvas || box.sel('maker->editing_canvas');
    G.mp.maker.unselect_all2(canvas);
    _each(G.mp.maker.designs(canvas), G.mp.maker.reset_data_cm);
  };
  G.mp.maker.unselect_all2 = function (canvas) {
    canvas = canvas || box.sel('maker->editing_canvas');
    canvas.getActiveGroup() && canvas.discardActiveGroup();
    canvas.getActiveObject() && canvas.discardActiveObject();
    return canvas.renderAll();
  };
  G.mp.maker.active = function (canvas) {
    canvas = canvas || box.sel('maker->editing_canvas');
    return canvas && (canvas.getActiveGroup() || canvas.getActiveObject());
  };

  G.mp.maker.multiple_select = function (objects, canvas) {
    if (!objects) return;
    if (!_p.is_array(objects)) {
      objects = G.mp.maker.get_cv_type(objects) === 'group' ? objects._objects : [objects];
    }
    if (!objects.length) return objects;
    canvas = canvas || box.sel('maker->editing_canvas');
    const last_cleared_pass = G.mp.maker.cleared_pass;
    G.mp.maker.cleared_pass = true;
    if (objects.length === 1) {
      canvas.discardActiveGroup().setActiveObject(objects[0]).renderAll();
    } else {
      canvas
        .discardActiveGroup()
        .setActiveGroup(
          new fabric.Group(
            _each(objects, _p.set('active', true)),
            _p.pick(objects[0], ['canvas']),
          ).setCoords(),
        )
        .renderAll();
    }
    G.mp.maker.cleared_pass = last_cleared_pass;
    return objects;
  };

  G.mp.maker.reject_select = function (obj) {
    obj.active = false;
    _go(G.mp.maker.designs(), _filter(_p.v('active')), function (filtered) {
      if (filtered.length === 0) G.mp.maker.unselect_all();
      if (filtered.length !== 0) G.mp.maker.multiple_select(filtered);
    });
  };

  G.mp.maker.add_select = function (obj) {
    obj.active = true;
    _go(G.mp.maker.designs(), _filter(_p.v('active')), function (filtered) {
      G.mp.maker.multiple_select(filtered);
    });
  };

  function has_active(methods, type) {
    return function () {
      const next = _p.pipe(arguments);
      return function (e) {
        let canvas, active;
        if (!(canvas = box.sel('maker->editing_canvas'))) return;
        if (methods) {
          active = _findval(methods, function (method) {
            return canvas[method]();
          });
          if (!active || (type && !active.type === 'group')) return;
        }
        e && e.preventDefault && e.preventDefault();
        return next(canvas, active, e);
      };
    };
  }
  G.mp.maker.has_editing_canvas = has_active();
  G.mp.maker.has_active_object = has_active(['getActiveObject']);
  G.mp.maker.has_active_group = has_active(['getActiveGroup']);
  G.mp.maker.has_active_object_or_group = has_active(['getActiveGroup', 'getActiveObject']);
  G.mp.maker.has_active_object_group = has_active(['getActiveObject'], 'group');
  G.mp.maker.resize_url = function (url, width) {
    return new Promise(function (resolve) {
      fabric.Image.fromURL(G.mp.maker.url(url), function (img) {
        const wx = G.mp.maker.fabric_get_width(img);
        const hy = G.mp.maker.fabric_get_height(img);
        if (wx > hy) {
          img.width = width;
          img.height = (hy * width) / wx;
        } else {
          img.height = width;
          img.width = (wx * width) / hy;
        }
        img.scaleX = 1;
        img.scaleY = 1;

        resolve(
          img.toDataURL({
            left: 0,
            top: 0,
            width: G.mp.maker.fabric_get_width(img),
            height: G.mp.maker.fabric_get_height(img),
          }),
        );
      });
    });
  };

  G.mp.maker.download = function (url, file_name) {
    const data = atob(url.split(',')[1]);
    const buffer = new ArrayBuffer(data.length);
    const view = new Uint8Array(buffer);

    _p.times(data.length, function (i) {
      view[i] = data.charCodeAt(i) & 0xff;
    });
    let blob;
    try {
      blob = new Blob([buffer], { type: 'image/png' });
    } catch (e) {
      const bb = new (window.WebKitBlobBuilder || window.MozBlobBuilder)();
      bb.append(buffer);
      blob = bb.getBlob('image/png');
    }
    const file_url = (window.webkitURL || window.URL).createObjectURL(blob);
    _go(
      _mr(file_url, file_name),
      _p.t(
        'file_url, file_name',
        '\
        a[href="{{file_url}}" download="{{file_name}}"]',
      ),
      $.appendTo(document.body),
      $.trigger('click'),
      $.remove,
    );
  };

  G.mp.maker.select_without_locked = function (els, attr) {
    const selected = _p.filter(G.mp.maker.not_locked_designs(), function (v, i) {
      return $.attr(els[i], attr);
    });
    G.mp.maker.unselect_all();
    G.mp.maker.multiple_select(selected);
  };

  G.mp.maker.get_canvas_size = function (can) {
    const ctx = can.getContext('2d');
    const canvas_height = $.height(can) || can.height;
    const height_ratio = canvas_height / can.height;
    const data = ctx.getImageData(0, 0, can.width, can.height).data;
    return _p.go(
      data,
      _p.all(
        _p.findIndex(function (v) {
          return v !== 0;
        }),
        __(function (arr) {
          const l = arr.length;
          // while (--l > -1 && arr[l] == 0) {}
          return arr.length - l;
        }),
      ),
      function (a, b) {
        a = a / 4;
        b = b / 4;
        const top = (a / can.width) * height_ratio;
        const bottom = (b / can.width) * height_ratio;
        const height = canvas_height - top - bottom;
        return { top: top, bottom: bottom, height: height };
      },
    );
  };

  G.mp.maker.bpcf_size = {};

  // G.mp.maker.set_bpcfs_length = function() {
  //   return $.attr($1('.mp_maker'), {
  //     faces_length: box.sel('maker->product_color->_->base_product_color->_->base_product_color_faces').length
  //   });
  // };

  G.mp.maker.get_bpcfs_length = function () {
    return $.attr($1('.mp_maker'), 'faces_length');
  };

  G.mp.maker.is_available_canvas_idx = function () {
    return G.mp.maker.get_bpcfs_length() - 1 >= G.mp.maker.editing_canvas_idx();
  };

  G.mp.maker.get_canvass = function () {
    return _go(G.mp.maker.get_bpcfs_length(), function (len) {
      return _p.initial(box.sel('maker->canvass'), 6 - len);
    });
  };

  G.mp.maker.get_other_canvass = function () {
    const f_canvas = box.sel('maker->editing_canvas');
    return _reject(G.mp.maker.get_canvass(), (c) => c === f_canvas);
  };
})();
