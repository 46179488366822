import { $closest } from 'fxdom/es';
import { html, isUndefined } from 'fxjs/es';
import { NewMakerCvObjectCvImageF } from '../../../../NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { MarppleShinhanConstantS } from '../../../Shinhan/S/Constant/module/MarppleShinhanConstantS.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { makeApiUrl } from '../../../../Util/S/Function/util.js';

export const addCustomSticker = () => {
  const info = MarppleShinhanConstantS.info;
  $.frame.open(
    {
      is_modal: !UtilF.isLegacyMobile(),
      frame_name: `maker.sticker.${info.don_frame_key}`,
      page_name: `maker_sticker_${info.don_frame_key}_page`,
      appending: __($.attr('is_fixed_head', 'true')),
      closing: function (don_frame, image) {
        if (!image) return;
        image.folder_type = info.don_frame_key;
        return NewMakerCvObjectCvImageF.addCvImageToMpMaker(image);
      },
    },
    {
      page_name: `maker_sticker_${info.don_frame_key}_page`,
      hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
      tabs: [
        {
          tab_name: `maker_sticker_${info.don_frame_key}_tab`,
          selected: true,
        },
        {
          tab_name: `maker_sticker_${info.don_frame_key}_free_tab`,
        },
      ],
    },
  );
};

export function defnCustomStickerFrame() {
  const info = MarppleShinhanConstantS.info;
  G.mp.maker = G.mp.maker || {};
  const { box_folders, box_group_images, box_images, don_frame_key } = info;
  // css는 잘 찾아서 수정해줘야 함
  const frame_name = `maker.sticker.${don_frame_key}`; // maker.sticker.shinhan
  const page_name = `maker_sticker_${don_frame_key}_page`; // maker_sticker_shinhan_page
  const page_name2 = `maker_sticker_${don_frame_key}_page2`; // maker_sticker_shinhan_page2
  const page_name3 = `maker_sticker_${don_frame_key}_page3`; // maker_sticker_shinhan_page3
  const tab_name = `maker_sticker_${don_frame_key}_page_tab`; // maker_sticker_shinhan_page_tab
  const tab_name2 = `maker_sticker_${don_frame_key}_page2_tab`; // maker_sticker_shinhan_page2_tab
  const tab_name3 = `maker_sticker_${don_frame_key}_page3_tab`; // maker_sticker_shinhan_page3_tab

  // defn_frame maker.sticker.shinhan
  $.frame.defn_frame([
    {
      frame_name,
      page_name,
      el_class: 'maker_sticker_frame',
      title: T('Design'),
      showing: function () {
        if (UtilF.isLegacyMobile()) $.add_class($1('body'), 'body_scroll');
      },
      closed: function () {
        if (UtilF.isLegacyMobile()) $.remove_class($1('body'), 'body_scroll');
      },
      attrs: {
        'data-only_flex': false,
      },
    },
  ]);

  $.frame.defn_page([
    {
      page_name,
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name,
          title: 'SHINHAN FRIENDS',
          template: () => {
            return html`
              <div id="select_sticker_${don_frame_key}_list" class="sticker_list" _sel="${box_folders}"></div>
            `;
          },
          appending: function (tab) {
            box.extend({
              [box_folders]: [],
              [box_group_images]: [],
              [box_images]: [],
            });
            return tab;
          },
          appended: _p.pipe(
            $.on('click', '.sticker_list .sticker_item', function (e) {
              const folder = box.sel(e.$currentTarget);
              $.frame.add_page({
                page_name: page_name2,
                loader: true,
                folder2: folder,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: tab_name2,
                    title: folder.name || '',
                    selected: true,
                    data_func: function () {
                      return folder;
                    },
                  },
                ],
              });
            }),
          ),
          infinite: function (don_ui_tab) {
            return $.frame.infi($.find1(don_ui_tab, `#select_sticker_${don_frame_key}_list`), {
              limit: 30,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$sticker_list_item,
              data_func: function (offset, limit) {
                return _p.go(
                  $.get('/@api/sticker/shinhan/list', {
                    offset,
                    limit,
                  }),
                  function (sticker) {
                    window.box.push.apply(null, [`${box_folders}`].concat(sticker));
                    return sticker;
                  },
                );
              },
            });
          },
        },
        {
          tab_name: `maker_sticker_${don_frame_key}_free_tab`,
          title: T('Design'),
          template: () => {
            return html` <div id="select_sticker_list" class="sticker_list" _sel="stickers"></div> `;
          },
          appending: function (tab) {
            box.extend({ stickers: [], sticker_images: [], group_images: [] });
            return tab;
          },
          appended: _p.pipe(
            $.on('click', '.sticker_list .sticker_item', function (e) {
              const folder = box.sel(e.$currentTarget);
              $.frame.add_page({
                page_name: 'maker_sticker_page2',
                loader: true,
                folder2: folder,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: 'maker_sticker_page2_tab',
                    title: folder.name || '',
                    selected: true,
                    data_func: function () {
                      return folder;
                    },
                  },
                ],
              });
            }),
          ),
          infinite: function (don_ui_tab) {
            return $.frame.infi($.find1(don_ui_tab, '#select_sticker_list'), {
              limit: 30,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$sticker_list_item,
              data_func: function (offset, limit) {
                return _p.go(
                  $.get(`/@api/sticker/${don_frame_key}/free`, {
                    offset,
                    limit,
                  }),
                  function (sticker) {
                    window.box.push.apply(null, ['stickers'].concat(sticker));
                    return sticker;
                  },
                );
              },
            });
          },
        },
      ],
    },
  ]);

  $.frame.defn_page([
    {
      page_name: page_name2,
      el_class: 'sticker_header_style1',
      custom_header: _p.t$(
        '\
        sp.don_back_page\
          sp.btn_back\
        sp.name {{ $.folder2["name"+_en] || "" }}\
        button[type=button].don_hide_frame\
      ',
      ),
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: tab_name2,
          template: __(
            function (folder2) {
              box.extend({ [`${box_images}`]: [] });
              return { folder2 };
            },
            (data) => {
              return html`
                <div class="folder_detail_area" folder2_id="${data.folder2.id}">
                  <div
                    id="select_sticker_${don_frame_key}_image_list"
                    class="sticker_image_list"
                    _sel="${box_images}"
                  ></div>
                </div>
              `;
            },
          ),
          appended: __(
            $.on('click', '.btn_prev', function (e) {
              // $.frame2.back();
            }),
            $.on('click', '.image_item[is_group="true"]', function (e) {
              const image = box.sel(e.$currentTarget);
              $.frame.add_page({
                page_name: page_name3,
                loader: true,
                image,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: tab_name3,
                    title: image.name || '',
                    selected: true,
                    data_func: function () {
                      return image;
                    },
                  },
                ],
              });
            }),
            $.on('click', '.image_item[is_group="false"]', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_tab) {
            const frame_el = $closest('.don_frame', don_tab);

            return $.frame.infi($.find1(don_tab, `#select_sticker_${don_frame_key}_image_list`), {
              limit: 30,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_tab, '.body') : window,
              template: G.$t_$sticker_image_list_item_custom,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };

                const get_url = makeApiUrl(`/@api/sticker_image/list/:folder_id`, {
                  folder_id: $.attr($.find1(don_tab, '.folder_detail_area'), 'folder2_id'),
                });

                return _p.go($.get(get_url, condition()), function (sticker) {
                  window.box.push.apply(null, [`${box_images}`].concat(sticker));
                  return sticker;
                });
              },
            });
          },
        },
      ],
    },
    {
      page_name: page_name3,
      el_class: 'sticker_header_style1',
      custom_header: _p.t$(
        '\
        sp.don_back_page\
          sp.btn_back\
        sp.name {{ $.image["name"+_en] || "" }}\
        button[type=button].don_hide_frame\
      ',
      ),
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: tab_name3,
          template: __(
            function (image, group_images) {
              box.extend({ [box_group_images]: [] });
              return { image };
            },
            (data) => {
              return html`
                <div class="folder_detail_area" image_id="${data.image.id}">
                  <div
                    id="select_sticker_${don_frame_key}_group_images"
                    class="sticker_image_list"
                    _sel="${box_group_images}"
                    data-only_flex="false"
                  ></div>
                </div>
              `;
            },
          ),
          appended: __(
            $.on('click', '.image_item', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_ui_tab) {
            const frame_el = $closest('.don_frame', don_ui_tab);
            return $.frame.infi($.find1(don_ui_tab, `#select_sticker_${don_frame_key}_group_images`), {
              limit: 30,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$layer_image_list_item_custom,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };

                const get_url = makeApiUrl(`/@api/sticker_image/:image_id/group_images`, {
                  image_id: $.attr($.find1(don_ui_tab, '.folder_detail_area'), 'image_id'),
                });

                return _p.go($.get(get_url, condition()), function (sticker) {
                  window.box.push.apply(null, [`${box_group_images}`].concat(sticker));
                  return sticker;
                });
              },
            });
          },
        },
      ],
    },
  ]);
  // end defn_frame maker.sticker.shinhan
}
