export const localForage = {
  set: ({ key, value, ttl_sec }) => {
    const data = {
      value,
      ttl: Date.now() + ttl_sec * 1000, // TTL을 현재 시간에서 ttlInSeconds 초 후로 설정
    };
    localStorage.setItem(key, JSON.stringify(data));
  },
  get: ({ key }) => {
    const dataString = localStorage.getItem(key);
    if (dataString) {
      const data = JSON.parse(dataString);
      // TTL 확인
      if (!data.ttl || data.ttl >= Date.now()) {
        return data.value; // 만료되지 않았으면 데이터 반환
      } else {
        localStorage.removeItem(key); // 만료되었으면 데이터 삭제
      }
    }
    return null; // 데이터가 없거나 만료되었으면 null 반환
  },
};
