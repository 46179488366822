import { go, reverseL, each, entriesL } from 'fxjs/es';
import { $attr, $css, $find, $findAll, $qs, $setCss } from 'fxdom/es';
import {
  image_editor_vertex_src,
  image_editor_fragment_src,
  view_vertex_src,
  view_fragment_src,
  preset_filters,
  setupAttribBufferToProgram,
  setupShaderProgramToGL,
  setupUniformLocationsToProgram,
  setRectFloat,
  setupTextureToGL,
  updateUniforms,
  getNonKernelMatrices,
  setCanvasClientSizeToSqaure,
  getTransformData,
  updateTexture,
  getAdjustmentInputs$,
  getSingleComponentAdjArray,
  updateUniformsFromArray,
} from './module/index.js';

export const setupImageEditorProgramToGL = (input_texture, flipY) => (gl) => {
  const program_name = 'image_editor';
  setupShaderProgramToGL(gl, program_name, image_editor_vertex_src, image_editor_fragment_src);
  setupAttribBufferToProgram(gl, gl.programs[program_name], 'a_position', setRectFloat(0, 1, 0, 1));
  setupAttribBufferToProgram(gl, gl.programs[program_name], 'a_tex_coord', setRectFloat(0, 1, 0, 1));
  setupUniformLocationsToProgram(gl, gl.programs[program_name], [
    'u_flipY',
    'u_res',
    'u_color_multiplier',
    'u_color_shifter',
    'u_sharp_blur_size',
    'u_rotation',
    'u_shift',
    'u_emboss_strength',
    'u_noise_strength',
    'u_vibrance_strength',
    'u_gamma_strength',
    'u_darken',
    'u_brighten',
  ]);
  updateUniforms(gl, gl.programs[program_name], {
    u_emboss_strength: { type: '1f', value: [0.0] },
    u_flipY: { type: '1f', value: [flipY ? -1.0 : 1.0] },
    u_res: { type: '2f', value: [gl.canvas.width, gl.canvas.height] },
    u_noise_strength: { type: '1f', value: [0.0] },
    u_vibrance_strength: { type: '1f', value: [0.0] },
    u_gamma_strength: { type: '1f', value: [1.0] },
    u_darken: { type: '1f', value: [0.0] },
    u_brighten: { type: '1f', value: [0.0] },
    u_color_multiplier: {
      type: 'Matrix4fv',
      value: [new Float32Array([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1])],
    },
    u_color_shifter: { type: '4fv', value: [new Float32Array([0, 0, 0, 0])] },
    u_sharp_blur_size: { type: '1f', value: [0] },
    u_rotation: {
      type: 'Matrix2fv',
      value: [new Float32Array([1, 0, 0, 1])],
    },
    u_shift: { type: '2f', value: [0.0, 0.0] },
  });
  setupTextureToGL(gl, input_texture, gl.LINEAR);
  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  return gl;
};

export const setupViewProgramToGL = (input_texture) => (gl) => {
  const program_name = 'view';
  setupShaderProgramToGL(gl, program_name, view_vertex_src, view_fragment_src);
  setupAttribBufferToProgram(gl, gl.programs[program_name], 'a_position', setRectFloat(0, 1, 0, 1));
  setupAttribBufferToProgram(gl, gl.programs[program_name], 'a_tex_coord', setRectFloat(0, 1, 0, 1));
  setupUniformLocationsToProgram(gl, gl.programs[program_name], [
    'u_flipY',
    'u_view_res',
    'u_crop_pos',
    'u_viewport_scale_ratio',
    'u_outside_crop_color',
    'u_outside_crop_alpha',
    'u_minor_grid_line_alpha',
    'u_grid_thickness',
    'u_crop_on',
    'u_grid_line_alpha',
    'u_vignette_strength',
  ]);
  const init_crop = 0;
  const { width: canvas_w, height: canvas_h } = gl.canvas;
  const client_w = parseInt(gl.canvas.style.width);
  const client_h = parseInt(gl.canvas.style.height);
  const crop_pos = {
    left: client_w * init_crop,
    right: client_w * (1 - init_crop),
    top: client_h * init_crop,
    bottom: client_h * (1 - init_crop),
  };
  updateUniforms(gl, gl.programs[program_name], {
    u_flipY: { type: '1f', value: [-1.0] },
    u_crop_on: { type: '1i', value: [false] },
    u_view_res: { type: '2f', value: [client_w, client_h] },
    u_crop_pos: { type: '4fv', value: [Object.values(crop_pos)] },
    u_viewport_scale_ratio: { type: '1f', value: [1.0] },
    u_grid_thickness: { type: '1f', value: [G.is_pc_size() ? 2.0 : 1.5] },
    u_outside_crop_color: { type: '1f', value: [1.0] },
    u_outside_crop_alpha: { type: '1f', value: [1.0] },
    u_minor_grid_line_alpha: { type: '1f', value: [1.0] },
    u_grid_line_alpha: { type: '1f', value: [1.0] },
    u_vibrance_strength: { type: '1f', value: [0.0] },
  });
  setupTextureToGL(gl, input_texture, gl.NEAREST);
  gl.viewport(0, 0, canvas_w, canvas_h);
  return gl;
};

export const renderImageEditor = (image_editor_gl, view_gl, adjustment_inputs$, transform$, override) => {
  const program = 'image_editor';
  const [non_kernels$, single_components$] = adjustment_inputs$;
  if (transform$) {
    const transform_data = getTransformData(image_editor_gl, transform$);
    updateUniforms(image_editor_gl, image_editor_gl.programs[program], {
      u_rotation: { type: 'Matrix2fv', value: [transform_data.rot_matrix] },
      u_shift: { type: '2f', value: [transform_data.translate_x, transform_data.translate_y] },
    });
  } else {
    const non_kernel_matrices = getNonKernelMatrices(non_kernels$);
    updateUniforms(image_editor_gl, image_editor_gl.programs[program], {
      u_color_multiplier: { type: 'Matrix4fv', value: [non_kernel_matrices.multiplier] },
      u_color_shifter: { type: '4fv', value: [non_kernel_matrices.shifter] },
    });
    const adj_components = getSingleComponentAdjArray(single_components$);
    updateUniformsFromArray(image_editor_gl, view_gl, adj_components, override);
  }
  image_editor_gl.drawArrays(image_editor_gl.TRIANGLES, 0, 6);
  if (view_gl) {
    updateTexture(view_gl, view_gl.texture, image_editor_gl.canvas);
    view_gl.drawArrays(view_gl.TRIANGLES, 0, 6);
  }
};

export const generatePresetThumbnails = (tab_el$, preset_filter_gl, view_size) => {
  const adjustment_inputs$ = getAdjustmentInputs$(tab_el$);
  const thumb_canvas$ = $findAll('canvas.filter', tab_el$);
  go(
    thumb_canvas$,
    reverseL,
    each(($thumb_canvas) => {
      const filter_gl_canvas = preset_filter_gl.canvas;
      setCanvasClientSizeToSqaure($thumb_canvas, view_size, 4);
      setLerpAdjustments($attr('item', $thumb_canvas), 1.0);
      renderImageEditor(preset_filter_gl, null, adjustment_inputs$, null, true);

      const thumb_view_ratio = 1;
      const filter_gl_canvas_ratio = filter_gl_canvas.width / filter_gl_canvas.height;
      if (filter_gl_canvas_ratio < thumb_view_ratio) {
        $thumb_canvas
          .getContext('2d')
          .drawImage(
            filter_gl_canvas,
            0,
            (filter_gl_canvas.height - filter_gl_canvas.width) / 2,
            filter_gl_canvas.width,
            filter_gl_canvas.width,
            0,
            0,
            $thumb_canvas.width,
            $thumb_canvas.height,
          );
      } else {
        $thumb_canvas
          .getContext('2d')
          .drawImage(
            filter_gl_canvas,
            (filter_gl_canvas.width - filter_gl_canvas.height) / 2,
            0,
            filter_gl_canvas.height,
            filter_gl_canvas.height,
            0,
            0,
            $thumb_canvas.width,
            $thumb_canvas.height,
          );
      }
    }),
  );
};

export const getLinearInterpolation = (v1, v2, amt, decimal) =>
  Math.round((v1 * (1 - amt) + v2 * amt) * decimal) / decimal;

export const setLerpAdjustments = (preset_filter_name, strength) => {
  const adj_sliders$ = $qs('.adjustment_item_sliders');
  const original_val = preset_filters.original.items_value;
  go(
    entriesL(preset_filters[preset_filter_name].items_value),
    each(([adjustment_item, set_val]) => {
      const input$ = $find(`input[item=${adjustment_item}]`, adj_sliders$);
      const init_val = original_val[adjustment_item];
      input$.value = getLinearInterpolation(init_val, set_val, strength, 1000);
    }),
  );
};

export const changeMoveItemsActive = (w_margin, h_margin) => {
  const crop_item_slider$ = $qs('.crop_item_sliders');
  const x_shift$ = $find('.crop_item_slider[item="x_shift"]', crop_item_slider$);
  const y_shift$ = $find('.crop_item_slider[item="y_shift"]', crop_item_slider$);
  if ($css('display', x_shift$) !== 'none') {
    if (w_margin > 0.001) {
      $setCss({ pointerEvents: 'auto', opacity: 1.0 }, x_shift$);
    } else {
      $setCss({ pointerEvents: 'none', opacity: 0.3 }, x_shift$);
    }
  }
  if ($css('display', y_shift$) !== 'none') {
    if (h_margin > 0.001) {
      $setCss({ pointerEvents: 'auto', opacity: 1.0 }, y_shift$);
    } else {
      $setCss({ pointerEvents: 'none', opacity: 0.3 }, y_shift$);
    }
  }
};
