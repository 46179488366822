import { noop, each } from 'fxjs/es';
import { produce, setAutoFreeze, enableES5 } from 'immer';
enableES5();
setAutoFreeze(false);
export const setFrame = async (original_frame, assign = noop, frameFunc) => {
  if (!original_frame.root_page)
    return Promise.reject(Error(`${original_frame.frame_name} root_page not defined.`));
  if (!$.frame.__frames[original_frame.frame_name]) $.frame.defn_frame(original_frame);
  if (!$.frame.__pages[original_frame.root_page.page_name]) $.frame.defn_page(original_frame.root_page);
  const frame = await produce(original_frame, async (frame) => {
    await assign(frame, frame.root_page, frame.root_page.tabs);
  });
  each((t) => (t.data_func = t.makeData || noop), frame.root_page.tabs);
  return frameFunc(frame, frame.root_page);
};
