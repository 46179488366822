import { each, go } from 'fxjs/es';
import { $findAll } from 'fxdom/es';

export const activateFooterAddIcon = (tab_el) => {
  go(
    tab_el,
    $findAll(`.footer[data-icon_type="add"]`),
    each((el) => {
      el.dataset.is_active = 'true';
    }),
  );
};

export const deactivateFooterAddIcon = (tab_el) => {
  go(
    tab_el,
    $findAll(`.footer[data-icon_type="add"]`),
    each((el) => {
      el.dataset.is_active = 'false';
    }),
  );
};

export const hideAllFooters = (tab_el) => {
  go(
    tab_el,
    $findAll(`.footer`),
    each((el) => {
      el.dataset.is_show = 'false';
    }),
  );
};

export const showTypeNoneFooters = (tab_el) => {
  go(
    tab_el,
    $findAll(`.footer`),
    each((el) => {
      if (el.classList.contains('none')) {
        el.dataset.is_show = 'true';
        return;
      }
      el.dataset.is_show = 'false';
    }),
  );
};

export const showTypeTextFooters = (tab_el) => {
  go(
    tab_el,
    $findAll('.footer'),
    each((el) => {
      if (el.classList.contains('text')) {
        el.dataset.is_show = 'true';
        return;
      }
      el.dataset.is_show = 'false';
    }),
  );
};

export const showTypePathFooters = (tab_el) => {
  go(
    tab_el,
    $findAll('.footer'),
    each((el) => {
      if (el.classList.contains('path')) {
        el.dataset.is_show = 'true';
        return;
      }
      el.dataset.is_show = 'false';
    }),
  );
};

export const showTypeShapeFooters = (tab_el) => {
  go(
    tab_el,
    $findAll(`.footer`),
    each((el) => {
      if (el.classList.contains('shape')) {
        el.dataset.is_show = 'true';
        return;
      }
      el.dataset.is_show = 'false';
    }),
  );
};

export const showTypeObjectFooters = (tab_el) => {
  go(
    tab_el,
    $findAll('.footer'),
    each((el) => {
      if (el.classList.contains('object')) {
        el.dataset.is_show = 'true';
        return;
      }
      el.dataset.is_show = 'false';
    }),
  );
};
