import axios from 'axios';
import format from 'date-fns/format/index.js';
import { $closest, $delegate, $find, $setAttr, $val } from 'fxdom/es';
import { compact, pluck, html } from 'fxjs';
import { go, reduce, replace, sel, tap } from 'fxjs/es';
import { renderProjectionSidebarInDetail } from '../../../../../modules/Df/Projection/Detail/F/fs.js';
import { makeDfProjectionSidebarItemHtml } from '../../../../../modules/Df/Projection/List/F/dfSidebarItemTmpl.js';
import { makeDfProjectionReceiptStatusForSidebarJson } from '../../../../../modules/Df/Projection/List/S/sidebar.js';
import { GoodsTypeS } from '../../../../../modules/GoodsType/S/Function/module/GoodsTypeS.js';
import { PriceS } from '../../../../../modules/Price/S/Function/module/PriceS.js';
import { needAdditionalPayment } from '../../../../../modules/Projection/S/fs.js';
import { getReceiptStatus, RECEIPT_STATUS } from '../../../../../modules/Receipt/S/fs.js';
import { UtilS } from '../../../../../modules/Util/S/Function/module/UtilS.js';
import { langTo_en, langToDfWord } from '../../../../../modules/Util/S/LangAndCollaboType.js';
import { legacyHtml } from '../../../../../modules/Util/S/Function/util.js';
import { MuiF } from '../../../../../modules/Mui/F/Function/module/MuiF.js';
import { GlobalPortOneConstantS } from '../../../../../modules/GlobalPortOne/S/Constant/module/GlobalPortOneConstantS.js';
import { GlobalPortOneRefundBankF } from '../../../../../modules/GlobalPortOne/RefundBank/F/Function/module/GlobalPortOneRefundBankF.js';

!(function (LF) {
  G.df.projection.detail = {};

  G.df.projection.detail.tab_hiding = function (el_don_tab) {
    const el_don_page = $.closest(el_don_tab, '.don_page');
    const el_don_wrapper = $.find1(el_don_page, '>.don_wrapper');
    $.attr(el_don_wrapper, { prev_scroll_top: $.scroll_top(el_don_wrapper) });
    $.css($.find1(el_don_tab, '>.don_wrapper'), { visibility: 'hidden' });
  };

  G.df.projection.detail.tab_hided = (el_don_tab) => {
    $.css($.find1(el_don_tab, '>.don_wrapper'), { visibility: 'hidden' });
  };

  G.df.projection.detail.base_tab_showed =
    (template, after = _p.noop) =>
    async (child) => {
      $.don_loader_start();
      const el_don_tab = $.closest(child, '.don_tab');
      const { tab_opt } = el_don_tab;
      const el_don_frame = $.closest(el_don_tab, '.don_frame');
      const { frame_opt } = el_don_frame;
      const el_don_wrapper = $.find1(el_don_tab, '>.don_wrapper');
      const el_don_page_don_wrapper = $.find1(el_don_frame, '.don_page >.don_wrapper');
      const projection = box.sel('df/projection/detail->projection') || {};
      const { id, updated_at } = projection;
      const need_change = frame_opt.projection_id != id;
      const new_projection = need_change
        ? await $.get('/@api/projection/take', { id: frame_opt.projection_id })
        : (await $.get('/@api/projection/check_updated', { id, updated_at })) || projection;

      box.set('df/projection/detail', { projection: new_projection });
      $setAttr(['collabo_type', new_projection.collabo_type], el_don_frame);
      $setAttr(['projection_type', new_projection.type], el_don_frame);
      $.html(
        $.find1(el_don_frame, '.projection_count'),
        `${new_projection._.user.name ?? new_projection.orderer_name}님 주문 (${
          new_projection._.user_projection_count
        })`,
      );

      if (frame_opt.bk) {
        const pp = _p.find(
          new_projection._.projection_payments,
          (pp) => pp.id == frame_opt.projection_payment_id,
        );
        if (pp && pp._.bks.every((bk) => bk.id != frame_opt.bk.id)) pp._.bks.push(frame_opt.bk);
      }

      if (need_change)
        _p.each($.find(el_don_frame, '.don_tab'), (el_don_tab) => (el_don_tab.tab_opt.updated_at = null));

      if (tab_opt.updated_at == new_projection.updated_at) {
        $.don_loader_end();
        setTimeout(() => {
          $.css(el_don_wrapper, { visibility: 'visible' });
          $.scroll_top2(el_don_page_don_wrapper, $.attr(el_don_page_don_wrapper, 'prev_scroll_top'));
        }, 100);
        return child;
      } else {
        return _p.go(
          void 0,
          template
            ? _p.pipe(
                _p.c(box.sel('df/projection/detail->projection')),
                template,
                (html) => $.show($.html(el_don_wrapper, html)),
                function () {
                  G.df.projection.detail.img_thumbnail_error(el_don_wrapper);
                },
                _p.c(child),
                after,
                _p.c(child),
                G.df.projection.detail.render_sidebar,
              )
            : _p.pipe(_p.c(child), tap(G.df.projection.detail.render_sidebar), after),
          function () {
            tab_opt.updated_at = new_projection.updated_at;
            setTimeout(() => {
              $.css(el_don_wrapper, { visibility: 'visible' });
              $.scroll_top2(el_don_page_don_wrapper, $.attr(el_don_page_don_wrapper, 'prev_scroll_top'));
            }, 100);
            $.don_loader_end();
          },
          _p.c(child),
        );
      }
    };

  G.df.projection.detail.tab_reload = (child) => {
    const el_don_tab = $.closest(child, '.don_tab');
    return el_don_tab.tab_opt.showed(el_don_tab);
  };

  G.df.projection.detail.render_sidebar = function (el_child) {
    const el_don_page = $.closest(el_child, '.don_page');
    const el_sidebar = $.find1(el_don_page, '>.don_wrapper .projection_sidebar');
    if (el_sidebar) {
      const items = $.find(el_sidebar, '.projection_item');
      _p.go(
        box.sel('df/projection/detail->projection'),
        (prj) => _p.sum(prj._.projection._.projections, (p) => makeDfProjectionSidebarItemHtml(prj, p)),
        $.el,
        $.append_to(el_sidebar),
      );
      $.remove(items);
    } else {
      return _p.go(
        box.sel('df/projection/detail->projection'),
        (prj) => legacyHtml`
          <div
            class='projection_sidebar in_projection_detail'
            _sel='df/projection/detail->projection->_->projection->_->projections'
            has_combined='${_p.some(prj._.projection._.projections, (p) => p.type == 'combined')}'
            merged_type='${prj.merged_type}'
            data-is_partial_delivery="${!!go(prj._.ups, pluck('partial_delivery_at'), compact).length}"
          >
            <div class='options'>
              <button type='button' class='add_repress'>재제작 주문서 만들기</button>
            </div>
            ${_p.sum(prj._.projection._.projections, (p) => makeDfProjectionSidebarItemHtml(prj, p))}
          </div>
        `,
        $.el,
        $.prepend_to($.find1(el_don_page, '>.don_wrapper')),
        _p.c(el_child),
      );
    }
  };

  const key_at = {
    is_cancel_req: 'cancel_requested_at',
    is_canceled: 'canceled_at',
    is_imp_cancel: 'imp_cancel_at',
    is_refund_req: 'refund_requested_at',
    is_refunded: 'refunded_at',
  };

  // const key_wid = {
  //   is_cancel_req: 'cancel_req_wid',
  //   is_canceled: 'cancel_wid',
  //   is_refund_req: 'refund_req_wid',
  //   is_refunded: 'refund_wid',
  // };

  const key_worker = {
    is_cancel_req: 'cancel_req_worker',
    is_canceled: 'cancel_worker',
    is_imp_cancel: 'imp_cancel_worker',
    is_refund_req: 'refund_req_worker',
    is_refunded: 'refund_worker',
  };

  function prj_log(key) {
    return function (prj) {
      const workers = box.sel('df/projection/detail->projection->_');
      return prj[key]
        ? `${moment(prj[key_at[key]]).format('YY.MM.DD HH:mm')} / ${
            workers[key_worker[key]].name || '[고객취소]'
          }`
        : '';
    };
  }

  const don_loader_pipe = (...fns) =>
    __(_p.tap(_p.noop, $.don_loader_start), ...fns, _p.tap(_p.noop, $.don_loader_end));

  function check_receipt_type(payment) {
    if (payment.pay_method != 'without_bank') return '';
    const type = _p.v(payment.receipt, 'type');
    if (type === undefined) return '0';
    return type + '';
  }

  // function disable_input(receipt) {
  //   return _.v(receipt, 'done') ? 'disabled' : '';
  // }

  function v_receipt_type(obj, key) {
    const receipt = _p.v(obj, 'type') && obj;
    if (!receipt) return '';
    return receipt[key] || '';
  }

  function receipt_tmpl(p_payment) {
    return pug`
      .cash.radio_target.receipt_body
        select.width366[name="identifier_type"]
          option[value="phone"] 휴대폰 번호
        input.width366.only_number[type="text" name="identifier" placeholder="- 없이 01000000000" value="${UtilS.escape(
          v_receipt_type(p_payment.receipt, 'identifier'),
        )}" size=50 maxlength=11]
      .company.radio_target.receipt_body
        select.width366[name="identifier_type"]
          option[value="business"] 사업자등록번호
        input.width366.only_number[type="text" name="identifier" size=50 maxlength=10 value="${UtilS.escape(
          v_receipt_type(p_payment.receipt, 'identifier'),
        )}"]
      .taxBill.radio_target.receipt_body
        .body
          .input_wrap
            label 상호
            input.width366.reciept_required[type="text" name="company_name" placeholder="상호(법인명)을 입력해 주세요." size=50 maxlength=50 value="${UtilS.escape(
              v_receipt_type(p_payment.receipt, 'company_name'),
            )}"]
          .input_wrap
            label 대표
            input.width366.reciept_required[type="text" name="worker_name" placeholder="이름을 입력해 주세요." size=50 maxlength=50 value="${UtilS.escape(
              v_receipt_type(p_payment.receipt, 'worker_name'),
            )}"]
          .input_wrap
            label 사업자번호
            input.width366.only_number.reciept_required[type="text" name="company_num" placeholder="사업자 등록번호를 입력해 주세요." size=50 maxlength=10 value="${UtilS.escape(
              v_receipt_type(p_payment.receipt, 'company_num'),
            )}"]
          .input_wrap
            label 담당자 이메일
            input.width366.reciept_required[type="text" name="email" placeholder="이메일을 입력해 주세요." size=50 maxlength=50 value="${UtilS.escape(
              v_receipt_type(p_payment.receipt, 'email'),
            )}"]
          .input_wrap
            label 사업장 주소
            input.postcode.reciept_required[type="text" name="postcode" placeholder="우편번호"size=20 maxlength=20 readonly value="${UtilS.escape(
              v_receipt_type(p_payment.receipt, 'postcode'),
            )}"]
            button[type="button"].search_postcode 우편번호 검색
          .input_wrap
            label
            input.address.no_label.width366.reciept_required[type="text" name="company_addr" placeholder="주소" size=50 maxlength=50 value="${UtilS.escape(
              v_receipt_type(p_payment.receipt, 'company_addr'),
            )}"]
          .input_wrap
            label
            input.no_label.address2.width366.reciept_required[type="text" name="company_addr2" placeholder="상세 주소를 입력해 주세요."size=50 maxlength=50 value="${UtilS.escape(
              v_receipt_type(p_payment.receipt, 'company_addr2'),
            )}"]
            .postcode_layer
              img.postcode_close[src="//t1.daumcdn.net/postcode/resource/images/close.png"]
          .input_wrap
            label 업태
            input.width366.reciept_required[type="text" name="business_status" placeholder="업태를 입력해 주세요." size=50 maxlength=50 value="${UtilS.escape(
              v_receipt_type(p_payment.receipt, 'business_status'),
            )}"]
          .input_wrap
            label 업종
            input.width366.reciept_required[type="text" name="business_type" placeholder="종목을 입력해 주세요." size=50 maxlength=50 value="${UtilS.escape(
              v_receipt_type(p_payment.receipt, 'business_type'),
            )}"]
          .input_wrap
            label 사업자등록증
            .input_file
              input.file_company_license[type="file"]
              input.hidden[type="text" name="business_license" value="${UtilS.escape(
                v_receipt_type(p_payment.receipt, 'business_license') || '',
              )}"]
            .img
              img[src="${UtilS.escape(v_receipt_type(p_payment.receipt, 'business_license') || '')}"]
    `;
  }

  const makeReceitHtml = (p_payment, p) => {
    const writeDate = (() => {
      const currentDate = format(new Date(), 'yyyy-MM-dd');

      if (sel('need_reissue', p_payment.receipt)) return currentDate;
      if (sel('delivering_at', p)) {
        return format(new Date(sel('delivering_at', p)), 'yyyy-MM-dd');
      }

      return currentDate;
    })();

    return pug`
      .receipt.postcode_scroll[_sel="./receipt" style="display: ${
        check_receipt_type(p_payment) ? 'block' : 'none'
      };" receipt_type="${check_receipt_type(p_payment)}" receipt_status="${getReceiptStatus(
      p_payment.receipt,
    )}" invoice_modify="${!!sel('receipt.invoiceModify', p_payment)}" self_invoice="${!!sel(
      'receipt.self_invoices',
      p_payment,
    )}" old_invoice="${!sel('receipt.invoices', p_payment) && !sel('receipt.self_invoices', p_payment)}"]
        h4 증빙 서류 [상태 : <span class='receipt_status'></span> ${
          sel('receipt.receipt_error', p_payment)
            ? `실패 사유 : ${sel('receipt.receipt_error', p_payment)}`
            : ''
        } ${
      sel('receipt.registDate', p_payment)
        ? ` - ${format(new Date(sel('receipt.registDate', p_payment)), 'yyyy-MM-dd')}`
        : ''
    } ]
        button.save_receipt 저장
        button.cancel_act 취소
        button.request_receipt 발행
        button.request_reissue 재발행
        button.request_receipt_manual 수기관리
        button.cancel_receipt 영수증 취소
        button.cancel_receipt_done 발행완료 취소하기
        button.show_receipt 영수증 보기
        input[type="text" placeholder="국세청승인번호" name="selfNtsConfirmNum"].input_self_receipt
        input[type="text" placeholder="발행금액" name="selfTotalPrice"].input_self_receipt.price
        button.self_receipt 수기발행
        button.self_cancel 수기취소
        form.radio_box.receipt_type
          label.none
            sp.mp_input
              input[type="radio" name="receipt_type2" value="0" ${
                check_receipt_type(p_payment) == '0' ? 'checked' : ''
              }]
              span
            sp 미발행
          label.cash
            sp.mp_input
              input[type="radio" name="receipt_type2" value="1" ${
                check_receipt_type(p_payment) == '1' ? 'checked' : ''
              }]
              span
            sp 현금영수증 소득공제
          label.company
            sp.mp_input
              input[type="radio" name="receipt_type2" value="2" ${
                check_receipt_type(p_payment) == '2' ? 'checked' : ''
              }]
              span
            sp 사업자 지출증빙
          label.taxBill
            sp.mp_input
              input[type="radio" name="receipt_type2" value="3" ${
                check_receipt_type(p_payment) == '3' ? 'checked' : ''
              }]
              span
            sp 세금계산서
        div[class="self_receipt_wrap" style="display:${
          sel('receipt.self_invoices', p_payment) ? 'block;' : 'none;'
        }"]
          h4 세금계산서 수기발행내역
          table.self_receipt_list
            thead
              tr
                th 국세청승인번호
                th 발행금액
                th 발행일시
            tbody
            ${_p.sum(
              sel('receipt.self_invoices', p_payment),
              ({ ntsConfirmNum, total_price, created_at }) => pug`
              tr
                td ${ntsConfirmNum}
                td ${PriceS.pricify_kr(total_price)}원
                td ${created_at}
                  `,
            )}
        form.tax-invoice
          label 작성일자
          input[type="date" name="writeDate" value="${writeDate}"]
          label 발행종류
          .radio-wrap
            label.tax-invoice__receipt
              sp.mp_input
                input[type="radio" name="purposeType" value="영수" checked]
                span
              sp 영수
            label.tax-invoice__claim
              sp.mp_input
                input[type="radio" name="purposeType" value="청구"]
                span
              sp 청구
        .body
          ${receipt_tmpl(p_payment)}
    `;
  };

  const prj_payment = (can_remove, p_payment, p, _en = langTo_en(p.lang)) => {
    return pug`
    .grid.payment[_sel='./_->projection_payments->(#${p_payment.id})' pay_method="${
      p_payment.pay_method
    }" is_global_vbank_style="${GlobalPortOneConstantS.VBANK_STYLE_PAY_METHODS.includes(
      p_payment.pay_method,
    )}" paid_amount="${p_payment['paid_amount' + _en]}" payment_type="${p_payment.type}"]
      .title
        .id 결제ID - ${p_payment.id}
        .type.${p_payment.type}.${p_payment.pay_method}
        .pay_method_changer
          label
            span 결제 방식 변경
            select[name="pay_method"]
              option[value="without_bank" ${
                p_payment.pay_method == 'without_bank' ? 'selected' : ''
              }] 무통장입금
              option[value="offline_card" ${
                p_payment.pay_method == 'offline_card' ? 'selected' : ''
              }] 오프라인카드
              option[value="offline_cash" ${
                p_payment.pay_method == 'offline_cash' ? 'selected' : ''
              }] 오프라인현금
        ${
          box.sel('user->_->policies->auth_payment_policy')
            ? pug`
              .move_to_other_projection
                button[type="button"].move 다른 주문서로 결제 이동
              .div_payment
                button[type="button"].div_payment 결제 금액 나누기
              ${
                can_remove
                  ? pug`
                    button[type="button" data-collabo_type=${p.collabo_type}].remove 삭제`
                  : ''
              }`
            : ''
        }
      .payment_body
        table
          tr
            th 결제 금액
            th.without_bank 입금자명
            th.without_bank 입금은행
            th 결제한 금액
            th 미수금
            th 결제 상태
            th 결제한 시각
            th 환불한 금액
            th 생성
          tr
            td
              sp.total_price.mp_currency.kr
                ${PriceS.pricify_kr(p_payment.total_price)}
              sp.total_price_en.mp_currency.en
                ${PriceS.pricify_en(p_payment.total_price_en)}
              sp.total_price_jp.mp_currency.jp
                ${PriceS.pricify_en(p_payment.total_price_jp)}
            td.without_bank.deposit_user_name
              input[type="text" name="deposit_user_name" value="${UtilS.escape(
                p_payment.deposit_user_name || '',
              )}"]
              button[type="button" style="margin-left: 8px;"] 변경
            td.without_bank.bank
              select[name="bank"]
                option[value="" ${
                  `${!p_payment.bank_name}` == '' ? 'selected' : ''
                }] 입급은행을 선택해 주세요.
                option[value="국민은행 (주)마플코퍼레이션|037601-04-041747" ${
                  `${p_payment.bank_number}` == '037601-04-041747' ? 'selected' : ''
                }] 국민은행 037601-04-041747
                option[value="우리은행 (주)마플코퍼레이션|1005-702-680981" ${
                  `${p_payment.bank_number}` == '1005-702-680981' ? 'selected' : ''
                }] 우리은행 1005-702-680981
                option[value="기업은행 (주)마플코퍼레이션|078-174234-01-013" ${
                  `${p_payment.bank_number}` == '078-174234-01-013' ? 'selected' : ''
                }] 기업은행 078-174234-01-013
            td.paid_amount
              sp.paid_amount.kr
                ${
                  box.sel('user->_->policies->auth_payment_policy')
                    ? pug`
                  label.mp_currency
                    span ${PriceS.pricify_kr(p_payment.paid_amount)}
                    input[type="text" name="paid_amount" value="${p_payment.paid_amount}"]
                  button[type="button"].paid_amount 변경`
                    : `${PriceS.pricify_kr(p_payment.paid_amount)}`
                }
              sp.paid_amount.mp_currency.en ${PriceS.pricify_en(p_payment.paid_amount_en)}
              sp.paid_amount.mp_currency.jp ${PriceS.pricify_jp(p_payment.paid_amount_jp)}
            td
              sp.amount.mp_currency.kr ${PriceS.pricify_kr(p_payment.amount)}
              sp.amount.mp_currency.en ${PriceS.pricify_en(p_payment.amount_en)}
              sp.amount.mp_currency.jp ${PriceS.pricify_jp(p_payment.amount_jp)}
            td.is_paid ${p_payment.is_paid ? '결제 완료' : '결제 대기'}
            td.paid_at ${
              p_payment.paid_at
                ? moment(p_payment.paid_at).format('YY.MM.DD HH:mm')
                : p_payment.is_paid
                ? ''
                : '결제대기중'
            }
            td
              sp.refund_amount.mp_currency.kr ${PriceS.pricify_kr(p_payment.refund_amount)}
              sp.refund_amount.mp_currency.en ${PriceS.pricify_en(p_payment.refund_amount_en)}
              sp.refund_amount.mp_currency.jp ${PriceS.pricify_jp(p_payment.refund_amount_jp)}
            td ${moment(p_payment.created_at).format('YY.MM.DD HH:mm')}
          tr[style="${p_payment._.bks.length ? '' : 'display: none;'}"]
            td <b>입급 내역</b>
            td[colspan="8" style="padding: 8px;"]
              .bks[_sel="./_->bks" style="${p_payment._.bks.length ? '' : 'display: none;'}"]
                table
                  tr
                    th #
                    th 입금시간
                    th 입금자명
                    th 입금액
                    th 은행
                    th 입금확인
                  ${_sum(
                    p_payment._.bks,
                    (bk, d = bk.bkdate, t = bk.bktime) => pug`
                  tr[is_hidden="${bk.is_hidden}" is_complete="${!!bk.projection_payment_id}"].infi_item
                    td ${bk.id}
                    td ${
                      d[2] +
                      d[3] +
                      '.' +
                      d[4] +
                      d[5] +
                      '.' +
                      d[6] +
                      d[7] +
                      ' ' +
                      t[0] +
                      t[1] +
                      ':' +
                      t[2] +
                      t[3]
                    }
                    td ${UtilS.escape(bk.bkjukyo)}
                    td ${PriceS.pricify(bk.bkinput)}
                    td ${G.df.bks.bank(bk)}
                    td ${
                      bk.projection_payment_id
                        ? '연결됨'
                        : box.sel('user->_->policies->auth_payment_policy')
                        ? `<button type='button' class='pair_pp_bk' bk_id='${bk.id}' projection_payment_id='${p_payment.id}'>연결하기</button>`
                        : ''
                    }`,
                  )}
      ${makeReceitHtml(p_payment, p)}
      .refunds[_sel="./_->refunds" style="${p_payment._.refunds.length ? '' : 'display: none;'}"]
        h4 환불 카드  ${
          GlobalPortOneConstantS.VBANK_STYLE_PAY_METHODS.includes(p_payment.pay_method)
            ? ' - 환불 카드 당 수수료 300엔'
            : ''
        }
        table
          tr
            th #
            th 환불 요청 금액
            th.ini 이니시스 tid
            th.ini 직접 환불
            th.imp 아임포트 uid
            th.imp 환불 상태
            th.global_port_one_vbank_style 환불 계좌 정보 저장
            th.without_bank.bank_code 환불 계좌 은행
            th.without_bank.bank_account 환불 계좌 번호
            th.without_bank.bank_user_name 환불 예금주
            th.without_bank.bank_save
            ${
              box.sel('user->_->policies->auth_payment_policy') && p_payment.pay_method !== 'vbank'
                ? pug`
            th.without_bank 환불 상태
            th.ini 환불 상태`
                : ''
            }
            th 환불 완료 시간
            th 환불자
            th 생성
          ${_p.sum(
            p_payment._.refunds,
            (refund) => pug`
          tr.refund[_sel="./(#${refund.id})"]
            td ${refund.id}
            td
              sp.price.mp_currency.kr ${PriceS.pricify_kr(refund.price)}
              sp.price_en.mp_currency.en ${PriceS.pricify_en(refund.price_en)}
              sp.price_jp.mp_currency.jp ${PriceS.pricify_jp(refund.price_jp)}
            td.ini
              ${p_payment.imp_uid}
            td.imp
              ${p_payment.imp_uid}
            td.imp
              ${
                refund.is_imp_cancel
                  ? '환불 완료'
                  : pug`
              button.imp_cancel.${refund.is_imp_cancel ? 'canceled' : 'false'} 아임포트 결제취소`
              }
            td.ini
              ${refund.is_imp_cancel ? '환불 완료' : '이니시스 관리창에서 환불해 주세요.'}
            td.global_port_one_vbank_style
              button.save_global_port_one_vbank_info 환불 계좌 정보 입력
            td.without_bank.bank_code
              <select name='bank_code'>
                <option value=''>은행선택</option>
                <option value='04' ${refund.bank_code == '04' ? 'selected' : ''}>KB국민은행</option>
                <option value='23' ${refund.bank_code == '23' ? 'selected' : ''}>SC제일은행</option>
                <option value='39' ${refund.bank_code == '39' ? 'selected' : ''}>경남은행</option>
                <option value='34' ${refund.bank_code == '34' ? 'selected' : ''}>광주은행</option>
                <option value='03' ${refund.bank_code == '03' ? 'selected' : ''}>기업은행</option>
                <option value='11' ${refund.bank_code == '11' ? 'selected' : ''}>농협</option>
                <option value='31' ${refund.bank_code == '31' ? 'selected' : ''}>대구은행</option>
                <option value='32' ${refund.bank_code == '32' ? 'selected' : ''}>부산은행</option>
                <option value='02' ${refund.bank_code == '02' ? 'selected' : ''}>산업은행</option>
                <option value='45' ${refund.bank_code == '45' ? 'selected' : ''}>새마을금고</option>
                <option value='07' ${refund.bank_code == '07' ? 'selected' : ''}>수협</option>
                <option value='88' ${refund.bank_code == '88' ? 'selected' : ''}>신한은행</option>
                <option value='48' ${refund.bank_code == '48' ? 'selected' : ''}>신협</option>
                <option value='05' ${refund.bank_code == '05' ? 'selected' : ''}>외환은행</option>
                <option value='20' ${refund.bank_code == '20' ? 'selected' : ''}>우리은행</option>
                <option value='71' ${refund.bank_code == '71' ? 'selected' : ''}>우체국</option>
                <option value='37' ${refund.bank_code == '37' ? 'selected' : ''}>전북은행</option>
                <option value='16' ${refund.bank_code == '16' ? 'selected' : ''}>축협</option>
                <option value='90' ${
                  ['K2', '90'].includes(refund.bank_code) ? 'selected' : ''
                }>카카오뱅크</option>
                <option value='89' ${refund.bank_code == '89' ? 'selected' : ''}>케이뱅크</option>
                <option value='81' ${refund.bank_code == '81' ? 'selected' : ''}>하나은행(서울은행)</option>
                <option value='53' ${
                  refund.bank_code == '53' ? 'selected' : ''
                }>한국씨티은행(한미은행)</option>
                <option value='92' ${refund.bank_code == '92' ? 'selected' : ''}>토스뱅크</option>
                <option value="D4" ${refund.bank_code == 'D4' ? 'selected' : ''}>한국투자증권</option>
                <option value="35" ${refund.bank_code == '35' ? 'selected' : ''}>제주은행</option>
                <!-- option value="">-가상계좌자동환불안됨-</option>
                <option value="06" ${refund.bank_code == '06' ? 'selected' : ''}>국민은행 구)주택</option>
                <option value="12" ${refund.bank_code == '12' ? 'selected' : ''}>단위농협</option>
                <option value="21" ${refund.bank_code == '21' ? 'selected' : ''}>구)조흥은행</option>
                <option value="22" ${refund.bank_code == '22' ? 'selected' : ''}>상업은행</option>
                <option value="24" ${refund.bank_code == '24' ? 'selected' : ''}>한일은행</option>
                <option value="25" ${refund.bank_code == '25' ? 'selected' : ''}>서울은행</option>
                <option value="26" ${refund.bank_code == '26' ? 'selected' : ''}>구)신한은행</option>
                <option value="27" ${refund.bank_code == '27' ? 'selected' : ''}>한미은행</option>
                <option value="38" ${refund.bank_code == '38' ? 'selected' : ''}>강원은행</option>
                <option value="41" ${refund.bank_code == '41' ? 'selected' : ''}>비씨카드</option>
                <option value="50" ${refund.bank_code == '50' ? 'selected' : ''}>상호저축은행</option>
                <option value="54" ${refund.bank_code == '54' ? 'selected' : ''}>홍콩상하이은행</option>
                <option value="55" ${refund.bank_code == '55' ? 'selected' : ''}>도이치은행</option>
                <option value="56" ${refund.bank_code == '56' ? 'selected' : ''}>ABN암로</option>
                <option value="57" ${refund.bank_code == '57' ? 'selected' : ''}>JP모건</option>
                <option value="64" ${refund.bank_code == '64' ? 'selected' : ''}>산림조합</option>
                <option value="70" ${refund.bank_code == '70' ? 'selected' : ''}>신안상호저축은행</option>
                <option value="83" ${refund.bank_code == '83' ? 'selected' : ''}>평화은행</option>
                <option value="87" ${refund.bank_code == '87' ? 'selected' : ''}>신세계</option>
                <option value="D1" ${refund.bank_code == 'D1' ? 'selected' : ''}>동양종합금융증권</option>
                <option value="D2" ${refund.bank_code == 'D2' ? 'selected' : ''}>현대증권</option>
                <option value="D3" ${refund.bank_code == 'D3' ? 'selected' : ''}>미래에셋증권</option>
                
                <option value="D5" ${refund.bank_code == 'D5' ? 'selected' : ''}>우리투자증권</option>
                <option value="D6" ${refund.bank_code == 'D6' ? 'selected' : ''}>하이투자증권</option>
                <option value="D7" ${refund.bank_code == 'D7' ? 'selected' : ''}>HMC투자증권</option>
                <option value="D8" ${refund.bank_code == 'D8' ? 'selected' : ''}>SK증권</option>
                <option value="D9" ${refund.bank_code == 'D9' ? 'selected' : ''}>대신증권</option>
                <option value="DA" ${refund.bank_code == 'DA' ? 'selected' : ''}>하나대투증권</option>
                <option value="DB" ${refund.bank_code == 'DB' ? 'selected' : ''}>신한금융투자</option>
                <option value="DC" ${refund.bank_code == 'DC' ? 'selected' : ''}>동부증권</option>
                <option value="DD" ${refund.bank_code == 'DD' ? 'selected' : ''}>유진투자증권</option>
                <option value="DE" ${refund.bank_code == 'DE' ? 'selected' : ''}>메리츠증권</option>
                <option value="DF" ${refund.bank_code == 'DF' ? 'selected' : ''}>신영증권</option>
                <option value="DG" ${refund.bank_code == 'DG' ? 'selected' : ''}>대우증권</option>
                <option value="DH" ${refund.bank_code == 'DH' ? 'selected' : ''}>삼성증권</option>
                <option value="DI" ${refund.bank_code == 'DI' ? 'selected' : ''}>교보증권</option>
                <option value="DJ" ${refund.bank_code == 'DJ' ? 'selected' : ''}>키움증권</option>
                <option value="DK" ${refund.bank_code == 'DK' ? 'selected' : ''}>이트레이드</option>
                <option value="DL" ${refund.bank_code == 'DL' ? 'selected' : ''}>솔로몬증권</option>
                <option value="DM" ${refund.bank_code == 'DM' ? 'selected' : ''}>한화증권</option>
                <option value="DN" ${refund.bank_code == 'DN' ? 'selected' : ''}>NH증권</option>
                <option value="DO" ${refund.bank_code == 'DO' ? 'selected' : ''}>부국증권</option>
                <option value="DP" ${refund.bank_code == 'DP' ? 'selected' : ''}>LIG증권</option>
                <option value="K1" ${refund.bank_code == 'K1' ? 'selected' : ''}>K뱅크</option -->
              </select>
            td.without_bank.bank_account
              input[type="text" name="bank_account" value="${UtilS.escape(
                refund.bank_account || '',
              )}" style="width: 160px"]
            td.without_bank.bank_user_name
              input[type="text" name="bank_user_name" value="${UtilS.escape(
                refund.bank_user_name || '',
              )}" style="width: 80px"]
            td.without_bank.bank_save
              button[type="button"].bank_save 정보 저장
            ${
              box.sel('user->_->policies->auth_payment_policy') &&
              p_payment.pay_method !== 'vbank' &&
              !GlobalPortOneConstantS.VBANK_STYLE_PAY_METHODS.includes(p_payment.pay_method)
                ? pug`
            td.ini.action
              button.refund_done.${refund.is_refunded} 환불 완료
            td.without_bank.action
              button.refund_done.${refund.is_refunded} 환불 완료`
                : ''
            }
            td ${refund.refunded_at ? moment(refund.refunded_at).format('YY.MM.DD HH:mm:ss') : ''}
            td ${refund._.refunded_worker.name}
            td ${moment(refund.created_at).format('YY.MM.DD HH:mm')}
          `,
          )}
    `;
  };

  function changeJsonReceiptStatusForSidebarInDetail(prj_id) {
    const prj_for_sidebar = box.sel(`./(#${prj_id})`);
    const prj_payments = box.sel('df/projection/detail->projection->_->projection_payments');
    prj_for_sidebar._.receipt_status = makeDfProjectionReceiptStatusForSidebarJson(prj_payments);
  }

  function renderReceiptInDetail(p_payment) {
    $1(
      `.detail_payment[_sel="df/projection/detail->projection"] [_sel="./_->projection_payments->(#${p_payment.id})"] .receipt`,
    ).outerHTML = makeReceitHtml(p_payment);
  }

  function whenReceiptChangedInDetail(p_payment) {
    if (p_payment.receipt.type === 3) changeJsonReceiptStatusForSidebarInDetail(p_payment.projection_id);
    renderReceiptInDetail(p_payment);
    renderProjectionSidebarInDetail(p_payment.projection_id);
  }

  // function saveReceiptLog(receipt_logs, act) {
  //   const receipt_log = {
  //     act: act,
  //     user_id: box.sel('is_user').id,
  //     created_at: new Date(),
  //   };
  //   return receipt_logs ? receipt_logs.concat(receipt_log) : [receipt_log];
  // }

  const prj_payments = (p, pps) =>
    _p.sum(pps, (pp) => {
      const not_paid =
        !box
          .sel('langs')
          .map((lang) => {
            return lang == 'kr' ? '' : `_${lang}`;
          })
          .filter((_en) => {
            return parseFloat(pp[`paid_amount${_en}`]) > 0;
          }).length && p['amount' + langTo_en(p.lang)] > 0;
      return prj_payment(
        (pps.length > 1 &&
          not_paid &&
          ['without_bank', 'offline_card', 'offline_cash'].includes(pp.pay_method)) ||
          (not_paid && p.collabo_type == 'creator') ||
          (not_paid && GlobalPortOneConstantS.VBANK_STYLE_PAY_METHODS.includes(pp.pay_method)),
        pp,
        p,
      );
    });

  const base_projection_cancel = (method) => {
    return async (e) => {
      try {
        return await _p.go(
          e,
          don_loader_pipe(({ currentTarget }) =>
            _p.go(
              currentTarget,
              box.sel,
              ({ id, collabo_type, _: { ups } }) => {
                return $.post(`/@api/projection/${method}`, {
                  id,
                  // 클라이언트에서 is_tpl 인지 확인하고 믿고 보낸다 (DF 니까)
                  is_tpl:
                    collabo_type == 'creator' &&
                    !!ups.find(({ goods_type_id }) => {
                      return GoodsTypeS.isTpl(goods_type_id);
                    }),
                });
              },
              tap((data) => {
                if (data.err) $.alert(data.err);
              }),
              _p.tap(_p.c(currentTarget), tab_reload),
            ),
          ),
        );
      } catch (e) {
        console.error(e);
      }
    };
  };

  function tab_reload(el_child) {
    return G.df.projection.detail.tab_reload(el_child);
  }

  function get_receipt_type_as_class(type) {
    if (type == 0) return '.none';
    if (type == 1) return '.cash';
    if (type == 2) return '.company';
    if (type == 3) return '.taxBill';
  }

  function receipt_alert(text, func) {
    return function (e) {
      return _p.go(
        $.confirm(text),
        _p.if2(_p.idtt)(function () {
          return func(e);
        }),
      );
    };
  }

  const langTo_enForShofify = (p) => (p.is_shopify ? '' : langTo_en(p.lang));
  const rowToLangForShofify = (p) => (p.is_shopify ? 'kr' : p.lang);

  function isSampleChangePossible(projection) {
    if (![14345, 193535].includes(box().is_user.id)) return;
    return projection.merged_type === 'child' && projection.collabo_type === 'creator';
  }

  const tab = {
    title: '결제정보',
    // selected: true,
    tab_name: 'projection.detail.payment',
    reload: tab_reload,
    template: () => '',
    hided: G.df.projection.detail.tab_hided,
    showed: G.df.projection.detail.base_tab_showed(
      (p, _en = langTo_enForShofify(p), lang = rowToLangForShofify(p)) => pug`
      .detail_payment.df_grid[_sel="df/projection/detail->projection" lang="${lang || 'en'}" is_cancel_req="${
        p.is_cancel_req
      }" is_canceled="${p.is_canceled}"]
        .top_id
          a[href="/projection/detail/${p.id}" target="_blank"] ${`[${langToDfWord(lang)}]`} #${p.id}
        h2 결제 정보
        .grid_wrapper
          .grid.price_info_prices.row
            .price_info
              table
                tr
                  th 주문자
                  td
                    a[href="/user/detail/${p._.user.id}"] ${UtilS.escape(p._.user.name)} (프로필 보기)
                tr.order
                  th 총 수량
                  td ${p.quantity}
                tr.order
                  th 상품 금액
                  td.mp_currency ${PriceS.pricify_by(p['product_price' + _en], _en)}
                tr.order
                  th 배송비
                  td.mp_currency ${PriceS.pricify_by(p['shipping_price' + _en], _en)}
                tr.order
                  th 쿠폰 할인
                  td.mp_currency ${PriceS.pricify_by(p['coupon_price' + _en], _en)}
                tr.point_price.kr.order
                  th 포인트 할인
                  td
                    label.mp_currency
                      input[type="text" name="${'point_price' + _en}" value="${
        p['point_price' + _en]
      }" prev="${p['point_price' + _en]}" max="${p._.user.point}"]
                    ${
                      box.sel('user->_->policies->auth_payment_policy')
                        ? pug`
                          button[type="button"] 변경`
                        : ''
                    }
                    div[style="margin-top: 8px; font-size: 12px;"]
                      <b>주문자의 현재 포인트:</b><br>
                      a[href="/user/detail/${p._.user.id}/point" target="_blank"] ${PriceS.pricify_kr(
        p._.user.point,
      )} (상세보기)
                tr.other_price_editor.order
                  th 추가금
                  td
                    label.mp_currency
                      input[type="text" name="other_price${_en}" value="${PriceS.price(
        p['other_price' + _en],
      )}"]
                    ${
                      box.sel('user->_->policies->auth_payment_policy')
                        ? pug`
                          button[type="button"] 변경`
                        : ''
                    }
                tr.other_price_editor.order
                  th 추가 배송비
                  td
                    label.mp_currency
                      input[type="text" name="other_shipping_price${_en}" value="${PriceS.price(
        p['other_shipping_price' + _en],
      )}"]
                    ${
                      box.sel('user->_->policies->auth_payment_policy')
                        ? pug`
                          button[type="button"] 변경`
                        : ''
                    }
                tr.other_price_editor.order
                  th 도서산간 배송비
                  td
                    label.mp_currency
                      input[type="text" name="additional_shipping_price" value="${PriceS.price(
                        p['additional_shipping_price'],
                      )}"]
                    ${
                      box.sel('user->_->policies->auth_payment_policy')
                        ? pug`
                          button[type="button"] 변경`
                        : ''
                    }
                tr.order
                  th 주문 금액
                  td.mp_currency ${PriceS.pricify_by(p['total_price' + _en], _en)}
                tr.cancel_price
                  th 주문 취소 금액
                  td.mp_currency_minus ${PriceS.pricify_by(p['total_price' + _en], _en)}
                tr.combined_shipping_price[style="${p.combined_shipping_price ? '' : 'display: none;'}"]
                  th 대체 묶음 배송비
                  td.mp_currency ${PriceS.pricify_kr(p.combined_shipping_price)}
              table[style="margin-top: 16px;"]
                tr
                  th 지불한 총액
                  td.mp_currency ${PriceS.pricify_by(p['paid_amount' + _en], _en)}
              table[style="margin-top: 16px;"]
                tr.refund_price
                  th 무조건 환불액
                  td
                    label.mp_currency
                      input[type="text" name="refund_price${_en}" value="${PriceS.price(
        p['refund_price' + _en],
      )}"]
                    ${
                      box.sel('user->_->policies->auth_payment_policy')
                        ? pug`
                          button[type="button"] 변경`
                        : ''
                    }
                tr.refunded_price
                  th 환불한 금액
                  td
                    label.mp_currency ${PriceS.pricify_by(p['refunded_price' + _en], _en)}
                tr.refunded_point[style="${lang != 'kr' ? 'display: none' : ''}"]
                  th 포인트로 환불
                  td
                    label.mp_currency
                      input[type="text" name="refunded_point" value="${p.refunded_point}"]
                    ${
                      box.sel('user->_->policies->auth_payment_policy')
                        ? pug`
                          button[type="button"] 변경`
                        : ''
                    }
                    div[style="margin-top: 8px; font-size: 12px;"]
                      <b>주문자의 현재 포인트:</b><br>
                      a[href="/user/detail/${p._.user.id}/point" target="_blank"] ${PriceS.pricify_kr(
        p._.user.point,
      )} (상세보기)
                tr.refunded_price
                  th 환불한 총액
                  td.mp_currency
                    ${PriceS.add(p['refunded_price' + _en], p.refunded_point)}
              table[style="margin-top: 16px;"]
                tr
                  th 미수금
                  td.mp_currency ${PriceS.pricify_by(Math.max(p['amount' + _en], 0), _en)}
                tr
                  th 미지급금
                  td.mp_currency ${PriceS.pricify_by(Math.abs(Math.min(p['amount' + _en], 0)), _en)}
            .projection_prices
              table
                tr
                  th.created_at 적용일
                  th.worker 처리자
                  th.description 변경 내용
                  th.change_amount 변경액
                ${_p.sum(
                  p._.projection_prices,
                  (p_price) => pug`
                  tr[_id="${p_price.id}"]
                    td.created_at ${moment(p_price.created_at).format('YY.MM.DD HH:mm')}
                    td.worker ${UtilS.escape(p_price._.user.name)}
                    td.description ${p_price.description}
                    td.change_amount ${PriceS.pricify_by(p_price['price' + _en], _en)}
                `,
                )}
                tr
                  tr
                    th[colspan="3"] 합계 (+미수금 -미지급금)
                    td ${_p.go(
                      p._.projection_prices,
                      _p.map((_) => _['price' + _en]),
                      _p.reduce((a, b) => PriceS.add(a, b)),
                      (_) => PriceS.pricify_by(_ || 0, _en),
                    )}
            .grid.prj_and_pay_controls
              .prj_control
                .row.with_title
                  .title 주문 취소
                  .input_wrap
                    div
                      button.cancel_req.${p.is_cancel_req} 취소 요청
                      span ${prj_log('is_cancel_req')(p)}
                    div
                      button.cancel_done.${p.is_canceled} 취소 완료
                      span ${prj_log('is_canceled')(p)}
                .row.with_title
                  .title 환불 상태
                  .input_wrap
                    div
                      .btnx.refund_req.${p.is_refund_req} 환불 요청
                    div
                      .btnx.refund_done.${p.is_refunded} 환불 완료
                .row.with_title
                  .title 사유
                  .input_wrap
                    div
                      textarea.cancel_reason[placeholder="200자 제한"] ${UtilS.escape(p.cancel_reason || '')}
                      button.save_cancel_reason 수정
                .row.with_title[style=${!isSampleChangePossible(p) ? 'display:none;' : 'margin-top:20px;'}]
                  .title 주문서
                  .input_wrap
                    div
                      button.change-to-sample-projection 샘플 주문서로 변경
          .grid.projection_payments
            h3.title 결제 내역
            .options[style="display: none;"]
              button[type="button"].fill_refunds 환불 카드 자동 생성 및 정리
            .additional_payment[style="${needAdditionalPayment(p) ? '' : 'display: none;'}"]
              span 추가 결제 대기 중 (고객이 주문 상세 화면에서 직접 추가 결제 가능한 상태입니다.)
              button[type="button"].worker_add 작업자가 직접 결제 추가 (이미 추가 입금이 되었거나, 오프라인 결제인 경우만 사용)
            .body
              ${prj_payments(p, p._.projection_payments)}`,
    ),
    appended: __(
      $delegate('click', '.change-to-sample-projection', async ({ currentTarget }) => {
        await $.alert('상품양에 따라 오래 걸릴 수 있습니다.');
        $.don_loader_start();
        const projection = go(currentTarget, $closest('.detail_payment'), box.sel);
        try {
          await axios.put('/@api/projection/change_to_sample', {
            projection_id: projection.id,
          });
          await $.alert('샘플 변경이 완료 됐습니다. 상세보기가 닫힘니다. 다시 열어주세요.');
          MuiF.closeFrame();
        } catch (e) {
          if (e.response.data?.message) {
            $.alert(e.response.data?.message);
          } else {
            $.alert(e.response.statusText);
          }
        } finally {
          $.don_loader_end();
        }
      }),
      $.on2('click', '.self_receipt', async function ({ currentTarget }) {
        try {
          const prj_payment_el$ = $.closest(currentTarget, '.payment[_sel]');
          const projection_payment = box.sel(prj_payment_el$);
          const ntsConfirmNum = $val($find('[name="selfNtsConfirmNum"', prj_payment_el$));
          const total_price = replace(/,/gm, '', $val($find('[name="selfTotalPrice"', prj_payment_el$)));
          $.don_loader_start();

          await go(
            axios.post('/@api/popbill_taxinvoice/self', {
              projection_payment_id: projection_payment.id,
              ntsConfirmNum,
              total_price,
            }),
            sel('data'),
            (receipt) => Object.assign(projection_payment, { receipt }),
            () => whenReceiptChangedInDetail(projection_payment),
          );
        } catch (e) {
          $.alert(`"ERROR: ${sel('response.data.message', e) || e}`);
        }
        $.don_loader_end();
      }),
      $.on2('click', '.save_cancel_reason', async function (e) {
        await $.post('/@api/projection/save_cancel_reason', {
          id: box.sel('df/projection/detail->projection->id'),
          cancel_reason: _p.go(
            e.currentTarget,
            $.closest('.input_wrap'),
            $.find1('textarea.cancel_reason'),
            $.val,
          ),
        });
        return $.alert('취소 사유가 저장되었습니다.');
      }),
      $.on2('click', '.receipt .show_receipt', function (e) {
        const p_payment = box.sel($.closest(e.currentTarget, '.payment[_sel]'));
        if (p_payment.receipt.type == 3) return $.alert('세금 계산서는 여기서 영수증을 볼수 없습니다.');
        return show_receipt(p_payment, e.currentTarget);
      }),
      $.on2(
        'click',
        '.receipt .request_reissue',
        receipt_alert('재발행 하시겠습니까?', async function (e) {
          try {
            $.don_loader_start();
            const projection_payment = box.sel($.closest(e.currentTarget, '.payment[_sel]'));

            await go(
              projection_payment,
              ({ id: projection_payment_id }) =>
                axios.post('/@api/popbill_taxinvoice/modify', {
                  projection_payment_id,
                  ...go(e.currentTarget, $closest('.receipt'), $find('.tax-invoice'), (form) =>
                    reduce(
                      (acc, key) => ({
                        ...acc,
                        [key]:
                          key == 'writeDate'
                            ? format(new Date(form[key].value), 'yyyyMMdd')
                            : form[key].value,
                      }),
                      {},
                      ['writeDate', 'purposeType'],
                    ),
                  ),
                }),
              sel('data'),

              (receipt) => Object.assign(projection_payment, { receipt }),
              () => whenReceiptChangedInDetail(projection_payment),
            );
            $.alert('재발행 됐습니다.');
          } catch (e) {
            $.alert(`"ERROR: ${sel('response.data.message', e) || e}`);
          }

          $.don_loader_end();
        }),
      ),
      $.on2(
        'click',
        '.receipt .self_cancel',
        receipt_alert(
          `취소 하시겠습니까?<br><br>기존 발행한 세금계산서 정보가 사라지므로 취소하기전 세금계산서 정보를 따로 저장해 주세요.`,
          async function (e) {
            try {
              $.don_loader_start();
              const projection_payment = box.sel($.closest(e.currentTarget, '.payment[_sel]'));

              await go(
                projection_payment,
                ({ id: projection_payment_id }) =>
                  axios.post('/@api/popbill_taxinvoice/cancel', {
                    projection_payment_id,
                  }),
                sel('data'),
                (receipt) => Object.assign(projection_payment, { receipt }),
                () => whenReceiptChangedInDetail(projection_payment),
              );
              $.alert('취소 되었습니다.');
            } catch (e) {
              $.alert(`"ERROR: ${sel('response.data.message', e) || e}`);
            }

            $.don_loader_end();
          },
        ),
      ),
      $.on2(
        'click',
        '.receipt .cancel_receipt_done',
        receipt_alert('영수증 발급을 취소하시겠습니까?', async function (e) {
          const projection_payment = box.sel($.closest(e.currentTarget, '.payment[_sel]'));
          if (!(projection_payment.receipt.type === 1 || projection_payment.receipt.type === 2))
            return $.alert('현금영수증, 사업자지출증빙만 해당됩니다.');
          $.don_loader_start();
          try {
            projection_payment.receipt = await (async (receipt, projection_payment_id) => {
              const { data } = await axios.post('/@api/receipt/cancel_receipt', {
                p_payment_id: projection_payment_id,
              });
              return data;
            })(projection_payment.receipt, projection_payment.id);
            whenReceiptChangedInDetail(projection_payment);
            $.alert('발행 취소 됐습니다.');
          } catch (e) {
            $.alert(`"ERROR: ${sel('response.data.message', e) || e}`);
          }

          $.don_loader_end();
        }),
      ),
      $.on2(
        'click',
        '.receipt .cancel_receipt',
        receipt_alert('영수증 발급을 취소하시겠습니까?', function (e) {
          const p_payment = box.sel($.closest(e.currentTarget, '.payment[_sel]'));
          if (p_payment.receipt.type !== 3) return;
          return _p.go(
            $.post('/@api/receipt/update', {
              receipt: _p.defaults(
                {
                  done: false,
                  cancel: {
                    need_cancel: false,
                    is_canceled: true,
                  },
                },
                p_payment.receipt,
              ),
              projection_payment_id: p_payment.id,
            }),
            function (receipt) {
              p_payment.receipt = receipt;
              whenReceiptChangedInDetail(p_payment);
            },
          );
        }),
      ),
      $.on2(
        'click',
        '.detail_payment[is_canceled="false"] .receipt .request_receipt',
        receipt_alert('영수증을 발행하시겠습니까?', async function (e) {
          try {
            $.don_loader_start();
            const el_prj_payment = $.closest(e.currentTarget, '.payment[_sel]');
            const projection_payment = box.sel(el_prj_payment);

            await go(
              projection_payment,
              ({ id: projection_payment_id, receipt }) =>
                receipt.type === 3
                  ? go(
                      axios.post('/@api/popbill_taxinvoice/closedown', {
                        checkCorpNum: receipt.company_num,
                      }),
                      ({ data: { state } }) => {
                        if (state !== '1')
                          throw new Error('등록되지 않은 사업자번호 또는 휴폐업된 사업자번호입니다.');

                        return axios.post('/@api/popbill_taxinvoice/regist', {
                          projection_payment_id,
                          ...go(e.currentTarget, $closest('.receipt'), $find('.tax-invoice'), (form) =>
                            reduce(
                              (acc, key) => ({
                                ...acc,
                                [key]:
                                  key == 'writeDate'
                                    ? format(new Date(form[key].value), 'yyyyMMdd')
                                    : form[key].value,
                              }),
                              {},
                              ['writeDate', 'purposeType'],
                            ),
                          ),
                        });
                      },
                      sel('data'),
                    )
                  : go(
                      axios.post('/@api/receipt/type_1_2_done', {
                        p_payment_id: projection_payment_id,
                      }),
                      sel('data'),
                    ),

              (receipt) => Object.assign(projection_payment, { receipt }),
              () => whenReceiptChangedInDetail(projection_payment),
            );
            $.alert('발행 완료 됐습니다.');
          } catch (e) {
            $.alert(`"ERROR: ${sel('response.data.message', e) || e.message || e}`);
          }
          $.don_loader_end();
        }),
      ),
      $.on2(
        'click',
        '.detail_payment[is_canceled="false"] .receipt .request_receipt_manual',
        receipt_alert(
          `세금계산서를 직접 관리 하시겠습니까?\n(전자세금계산서는 발행되지 않으며 발행완료 상태로 변경됩니다.)`,
          async function (e) {
            try {
              $.don_loader_start();
              const el_prj_payment = $.closest(e.currentTarget, '.payment[_sel]');
              const projection_payment = box.sel(el_prj_payment);

              await go(
                projection_payment,
                ({ id: projection_payment_id, receipt }) => {
                  if (receipt.type !== 3) return {};

                  return go(
                    axios.post('/@api/popbill_taxinvoice/manual_receipt', {
                      projection_payment_id,
                    }),
                    sel('data'),
                  );
                },
                (receipt) => Object.assign(projection_payment, { receipt }),
                () => whenReceiptChangedInDetail(projection_payment),
              );
              $.alert('발행 완료 처리 됐습니다.');
            } catch (e) {
              $.alert(`"ERROR: ${sel('response.data.message', e) || e.message || e}`);
            }
            $.don_loader_end();
          },
        ),
      ),
      $.on(
        'change',
        '.detail_payment[is_canceled="false"] .receipt input, .receipt select',
        function ({ currentTarget }) {
          switch ($.attr(currentTarget, 'name')) {
            case 'writeDate':
            case 'purposeType':
            case 'selfNtsConfirmNum':
            case 'selfTotalPrice':
              return;
          }

          const receiptAttr = $.closest(currentTarget, '.receipt');
          if ($.attr(receiptAttr, 'receipt_status') === 'done') return;
          $.add_class(receiptAttr, 'need_save');
        },
      ),
      $.on('click', 'button.search_postcode', function (e) {
        const ship_item = $.closest(e.currentTarget, '.receipt');
        const layer = $.find1(ship_item, '.postcode_layer');

        $.show(layer);
        new daum.Postcode({
          oncomplete: function (data) {
            let fullAddr = data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress;
            let extraAddr = '';
            if (data.userSelectedType === 'R') {
              if (data.bname !== '') extraAddr += data.bname;
              if (data.buildingName !== '')
                extraAddr += extraAddr !== '' ? ', ' + data.buildingName : data.buildingName;
              fullAddr += extraAddr !== '' ? ' (' + extraAddr + ')' : '';
            }
            $.val($.find1(ship_item, 'input.postcode'), data.zonecode);
            $.val($.find1(ship_item, 'input.address'), fullAddr);
            go(ship_item, $.add_class('changed'));
            $.hide(layer);
          },
          ...(window.is_ie ? {} : { submitMode: false }),
          width: '100%',
          height: '100%',
        }).embed(layer);
      }),
      $.on('click', '.postcode_close', function (e) {
        const input_wrap = $.closest(e.currentTarget, '.input_wrap');
        const layer = $.find1(input_wrap, '.postcode_layer');
        $.scroll_top2(window, $.offset($.closest(input_wrap, '.postcode_scroll')).top - 64, 500);
        $.hide(layer);
      }),
      $.on2('change', '.detail_payment[is_canceled="false"] .receipt .receipt_type input', function (e) {
        const receipt_el = $.closest(e.currentTarget, '.receipt');
        const receipt = box.sel($.closest(e.currentTarget, '.payment[_sel]')).receipt;
        if ($.attr(receipt_el, 'receipt_status') === 'done') {
          _p.go(
            receipt_el,
            $.find1(`.receipt_type ${get_receipt_type_as_class(_p.v(receipt, 'type'))} input`),
            function (el) {
              el.checked = true;
            },
          );
          return;
        }
        _p.go($.find1(receipt_el, '[name="receipt_type2"]:checked'), $.val, function (v) {
          $.attr(receipt_el, 'receipt_type', v);
        });
      }),
      $.on2('click', '.receipt .save_receipt', async function (e) {
        const receipt_el = $.closest(e.currentTarget, '.receipt');
        const projection_payment = box.sel($.closest(e.currentTarget, '.payment[_sel]'));

        switch (getReceiptStatus(projection_payment.receipt)) {
          case RECEIPT_STATUS.done:
          case RECEIPT_STATUS.need_reissue:
            whenReceiptChangedInDetail(projection_payment);
            return $.alert('이미 발행된 영수증은 변경이 불가합니다.');
        }

        const type = $.attr(receipt_el, 'receipt_type');
        const receipt = { type };

        const class_name = type == 1 ? 'cash' : type == 2 ? 'company' : type == 3 ? 'taxBill' : 'none';

        if (class_name != 'none') {
          const receipt_body = $.find1(receipt_el, '.receipt_body.' + class_name);
          _p.extend(receipt, G.$form_$to_attrs(receipt_body));
        }
        $.remove_class(receipt_el, 'need_save');

        try {
          projection_payment.receipt = await $.post_load2('/@api/receipt/update', {
            receipt,
            projection_payment_id: projection_payment.id,
          });
          whenReceiptChangedInDetail(projection_payment);
        } catch (e) {
          console.error(e);
          $.alert(`"ERROR: ${sel('response.data.message', e) || e.message}`);
        }
      }),
      $.on2('click', '.receipt .cancel_act', function (e) {
        const prj_payment_el = $.closest(e.currentTarget, '.payment[_sel]');
        const prj_payment = box.sel(prj_payment_el);
        renderReceiptInDetail(prj_payment);
      }),
      $.on2('change', '.file_company_license', function (e) {
        return _p.go(
          $.upload(e.currentTarget, {
            url: '/@fileUpload/file',
          }),
          function (file) {
            _p.go(e.currentTarget, $.closest('.input_wrap'), $.find1('img'), function (img_el) {
              img_el.src = file.url;
            });
            return _p.go(e.currentTarget, $.siblings('input.hidden'), _p.first, $.val(file.url));
          },
        );
      }),

      $.on2('click', '.move_to_other_projection button.move', async function (e) {
        const { id, projection_id } = box.sel(e.currentTarget);
        const ohter_projection_id = prompt('주문서의 ID를 입력해 주세요. (결제를 보낼 곳, 숫자만)');
        if (ohter_projection_id === '' || ohter_projection_id == null) return;
        $.don_loader_start();
        const res = await $.post('/@api/move_to_other_projection', {
          id,
          projection_id,
          ohter_projection_id,
        });
        await tab_reload(e.currentTarget);
        await $.alert(
          sel('warining', res)
            ? '다른 주문서에 증빙 신청 종류가 많아 자동 매칭이 안되었습니다. 더 상세히 확인해 주세요.'
            : '완료되었습니다. 이동된 주문서가 새창으로 띄워집니다. 확인해 주세요.',
        );
        window.open(`/projection/detail/${ohter_projection_id}`);
        $.don_loader_end();
      }),

      $.on2('click', '.div_payment button.div_payment', async function (e) {
        const { id, projection_id, total_price } = box.sel(e.currentTarget);
        const price = parseInt(prompt('뺄 금액을 입력해 주세요.') || 0);
        if (total_price <= price) return $.alert('나눌 금액이 원래 금액보다 크거나 같습니다.');
        if (price == 0) return;
        $.don_loader_start();
        await $.post('/@api/div_payment', { id, projection_id, price });
        await tab_reload(e.currentTarget);
        $.don_loader_end();
      }),

      $.on2('click', 'button.remove', async function ({ currentTarget }) {
        if (
          await $.confirm(
            `정말 삭제하시겠습니까?${
              currentTarget.dataset.collabo_type == 'creator'
                ? '<br>마플샵인 경우 결제수단을 변경할때도 사용합니다.'
                : ''
            }`,
          )
        ) {
          $.don_loader_start();
          const { id, projection_id } = box.sel(currentTarget);
          await $.post('/@api/remove_projection_payment', { id, projection_id });
          await tab_reload(currentTarget);
          $.don_loader_end();
        }
      }),

      $.on2('click', 'button.worker_add', async function (e) {
        $.don_loader_start();
        if (
          await $.confirm(
            '결제를 직접 추가하지 않아도<br>고객이 직접 추가 결제를 할 수 있는 상태입니다.<br><br>이미 추가 입금이 되었거나<br>오프라인 결제인 경우만 사용하는 기능입니다.<br><br>결제를 추가하시겠습니까?',
          )
        ) {
          const { id } = box.sel(e.currentTarget);
          await $.post('/@api/add_projection_payment_worker', {
            projection_id: id,
          });
          await tab_reload(e.currentTarget);
        }
        $.don_loader_end();
      }),

      $.on2('click', 'button.bank_save', async function (e) {
        $.don_loader_start();
        const { id } = box.sel(e.currentTarget);
        await $.post(
          '/@api/projection/refund_bank_update/' + id,
          G.$form_$to_attrs($.closest(e.currentTarget, '.refund')),
        );
        await tab_reload(e.currentTarget);
        await $.alert('변경되었습니다.');
        $.don_loader_end();
      }),
      $.on2('click', 'button.save_global_port_one_vbank_info', async function (e) {
        const refund = box.sel(e.currentTarget);

        const info = {
          bank_code: refund.bank_code,
          branch_code: refund.branch_code,
          bank_account: refund.bank_account,
          bank_user_name: refund.bank_user_name,
        };

        const new_info = await GlobalPortOneRefundBankF.openRefundBankInfo(info);
        if (new_info) {
          if (refund.is_refunded) {
            $.alert('이미 환불 완료된 건은 환불계좌 정보를 저장 할수 없습니다.');
            return;
          }
          $.don_loader_start();

          await $.post('/@api/projection/refund_bank_update/' + refund.id, new_info);
          await tab_reload(e.currentTarget);
          await $.alert('환불 계좌 정보가 저장 됐습니다.');
          $.don_loader_end();
        }
      }),

      $.on2('click', '.pair_pp_bk', async function (e) {
        $.don_loader_start();
        const bk_id = $.attr(e.currentTarget, 'bk_id');
        const projection_payment_id = $.attr(e.currentTarget, 'projection_payment_id');
        await $.post('/@api/projection/pair_pp_bk', {
          bk_id,
          projection_payment_id,
        });
        await tab_reload(e.currentTarget);
        G.df.bks.complete(bk_id);
        $.don_loader_end();
      }),

      $.on2(
        'click',
        '.refund_price button',
        base_projection_set_column('refund_price', 'set_refund_price', true),
      ),

      $.on2(
        'click',
        '.refunded_point button',
        base_projection_set_column('refunded_point', 'set_refunded_point', true),
      ),

      $.on2(
        'click',
        '.other_price_editor button',
        base_projection_set_column('other_price_editor', 'set_other_price'),
      ),

      $.on2(
        'click',
        '.point_price button',
        base_projection_set_column('point_price', 'set_point_price', false, (input) =>
          -parseInt($.val(input)) - -parseInt($.attr(input, 'prev')) <= parseInt($.attr(input, 'max'))
            ? input
            : _p.go(
                $.alert('고객이 소유한 포인트보다 많습니다.'),
                () => input.focus(),
                () => Promise.reject(new Error()),
              ),
        ),
      ),

      $.on2(
        'click',
        '.prj_control button.cancel_req.false',
        _p.if2(_p.c('직접 주문취소 요청하시겠습니까?'), $.confirm)(base_projection_cancel('cancel_req')),
      ),

      $.on2('click', '.prj_control button.cancel_done.false', function (e) {
        return _p.go(
          e,
          base_projection_cancel('canceled'),
          _p
            .if2(_p.idtt)(
              async (res) => res.fail_msg && (await $.alert(res.fail_msg)) && tab_reload(e.currentTarget),
            )
            .else(
              _p.c('df/projection/detail->projection'),
              box.sel,
              (prj) => ({
                phone: prj.orderer_mobile,
                name: prj.orderer_name,
                order_number: prj.id,
              }),
              _p($.post, '/@api/kakao_msg/sm_order_cancel'),
            ),
        );
      }),

      $.on('click', '.prj_control button.cancel_req.true', (e) => {
        if ($find('button.cancel_done.true', e.delegateTarget)) {
          return $.alert(
            '취소가 완료된 주문은 취소 요청을 되돌릴 수 없습니다. 주문 취소 완료를 먼저 되돌려 주세요.',
          );
        } else {
          return go(e, base_projection_cancel('cancel_cancel_req'));
        }
      }),
      $.on('click', '.prj_control button.cancel_done.true', base_projection_cancel('cancel_canceled')),

      $.on2('change', '.payment .pay_method_changer select', function (e) {
        const pp = box.sel(e.currentTarget);
        if (pp.paid_amount > 0 || pp.refund_amount) {
          e.currentTarget.value = pp.pay_method;
          return $.alert('결제한 금액이나 환불한 금액이 있으면 결제방식을 변경할 수 없습니다.');
        }
        return update_payment(e, undefined, '.pay_method_changer');
      }),
      $.on2('click', '.payment input[name="paid_amount"]', function (e) {
        if (!e.shiftKey) return;
        const price =
          parseInt(
            (_p.go(e.currentTarget, $.closest('tr'), $.find1('span.total_price'), $.text) || '').replace(
              /,/g,
              '',
            ),
          ) || 0;

        return _p.go(e.currentTarget, $.val(price));
      }),
      $.on2('click', '.payment .paid_amount button', update_payment),
      $.on2('click', '.payment .deposit_user_name button', update_payment),
      $.on2(
        'change',
        '.payment .bank select',
        _p(update_payment, _p, function (id, val) {
          const [bank_name, bank_number] = val.split('|');
          return { id, bank_name, bank_number };
        }),
      ),

      $.on2('click', '.payment .refund button.refund_done', async function (e) {
        $.don_loader_start();
        const refund = box.sel(e.currentTarget);
        const is_refunded = $.is(e.currentTarget, '.false');
        (await $.post('/@api/refund/refunded/' + refund.id, { is_refunded })) ||
          (await $.alert('실패했습니다. 계속 동작하지 않는다면 개발팀에 문의주세요.'));
        await tab_reload(e.currentTarget);
        $.don_loader_end();
      }),

      $.on(
        'click',
        '.payment .refund button.imp_cancel.false',
        don_loader_pipe(
          _p.if2(
            _p.$confirm(
              '아임포트 결제를 취소하시겠습니까?</br><strong>이 요청은 복구할 수 없습니다.</strong>',
            ),
          )(_p.v('currentTarget'), (button) =>
            _p.go(
              _p.mr(box.sel($.closest(button, '.payment')), box.sel(button)),
              (pay, refund) => ({
                projection_payment_id: pay.id,
                imp_uid: pay.imp_uid,

                projection_id: pay.projection_id,
                refund_id: refund.id,
                imp_cancel_wid: box.sel('user->id'),
              }),
              _p($.post, '/@api/refund/imp_cancel'),
              _p
                .if2((res) => res.err)((res) =>
                  $.alert(
                    res.err ||
                      '이미 취소 되었거나 일시적인 오류입니다. 계속 동작하지 않는다면 개발팀에 문의주세요.',
                  ),
                )
                .else(_p.$alert('완료되었습니다.')),
              _p.c(button),
              tab_reload,
            ),
          ),
        ),
      ),
    ),
  };
  G.df.projection.detail_payment = { tab };

  function show_receipt(p_payment, target) {
    return _p.go(
      _p.v(p_payment, 'receipt.response.receipt_url')
        ? p_payment.receipt
        : $.get('/@api/receipt/get_receipt', {
            params: {
              receipt: p_payment.receipt,
              p_payment_id: p_payment.id,
            },
          }),
      function (receipt) {
        if (!receipt) return $.alert('오류 났습니다.');
        if ($.siblings(target, '.iframe_wrapper_receipt').length)
          return $.remove($.siblings(target, '.iframe_wrapper_receipt'));
        return _p.go(
          receipt.response.receipt_url,
          _p.t$(
            '\
            .iframe_wrapper_receipt\
              <iframe src="{{$}}"></iframe>\
          ',
          ),
          $.insertAfter(target),
        );
      },
    );
  }

  function base_projection_set_column(class_name, url, is_plus = null, validation = _p.idtt) {
    return function (e) {
      return _p.go(
        e.currentTarget,
        $.closest('.' + class_name),
        $.find1('input'),
        (input) =>
          isNaN(parseFloat($.val(input)))
            ? _p.go(
                $.alert('숫자를 입력해 주세요.'),
                () => input.focus(),
                () => Promise.reject(new Error()),
              )
            : input,
        is_plus === null
          ? _p.idtt
          : is_plus
          ? (input) =>
              parseFloat($.val(input)) >= 0
                ? input
                : _p.go(
                    $.alert('플러스만 가능합니다.'),
                    () => input.focus(),
                    () => Promise.reject(new Error()),
                  )
          : (input) =>
              parseFloat($.val(input)) <= 0
                ? input
                : _p.go(
                    $.alert('마이너스만 가능합니다.'),
                    () => input.focus(),
                    () => Promise.reject(new Error()),
                  ),
        validation,
        (input) =>
          $.post('/@api/projection/' + url, {
            key: $.attr(input, 'name'),
            value: PriceS.price($.val(input)),
            projection_id: box.sel('df/projection/detail->projection->id'),
          }),
        function (ok) {
          if (!ok)
            return _p.go(
              $.alert('실패했습니다. 주문서를 다시 띄워주세요.'),
              () => input.focus(),
              () => Promise.reject(new Error()),
            );
        },
        _p.c(e.currentTarget),
        tab_reload,
        () => $.alert('변경되었습니다.'),
        _p.catch(_p.noop),
      );
    };
  }

  async function update_payment(e, f, selector) {
    const { id } = box.sel(e.currentTarget);
    const input = _p.go(e.currentTarget, $.closest(selector || 'td'), $.find1('[name]'));
    const result = await $.post(
      '/@api/payment/update',
      f ? f(id, $.val(input)) : { id, [$.attr(input, 'name')]: $.val(input) },
    );
    if (!result) {
      $.alert('실패했습니다. 다시 시도해보고 개발팀에 문의해주세요.');
    }
    return _p.go(result, _p.c(input), tab_reload, _p.c('변경되었습니다.'), $.alert);
  }
})({});
