import { map, go, sumBy, flat } from 'fxjs/es';

export const init = () => {
  !(function (e, t, n, s, u, a) {
    e.twq ||
      ((s = e.twq =
        function () {
          s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
        }),
      (s.version = '1.1'),
      (s.queue = []),
      (u = t.createElement(n)),
      (u.async = !0),
      (u.src = '//static.ads-twitter.com/uwt.js'),
      (a = t.getElementsByTagName(n)[0]),
      a.parentNode.insertBefore(u, a));
  })(window, document, 'script');
  twq('init', 'nzosi');
};

export const pageView = () => {
  if (!$.has_class(document.querySelector('html'), 'mp_order_complete')) twq('track', 'PageView');
};

export const purchaseMp = (projection, p_lang) => {
  if (typeof twq == 'function' && G.collabo_type == '') {
    const products = map(function (up) {
      const up_c_ss = go(
        up._.up_cs,
        map(function (up_c) {
          return up_c._.up_c_ss;
        }),
        flat,
      );

      const quantity = sumBy(function (up_c_s) {
        return up_c_s.quantity;
      }, up_c_ss);

      return {
        name: up._.base_product.name,
        quantity,
        price: up._.product_color['price' + _en],
      };
    }, projection._.ups);

    twq('track', 'Purchase', {
      value:
        p_lang == 'kr'
          ? projection.product_price
          : p_lang == 'jp'
          ? projection.product_price_jp
          : projection.product_price_en,
      currency: p_lang == 'kr' ? 'KRW' : p_lang == 'jp' ? 'JPY' : 'USD',
      num_items: sumBy(function (p) {
        return p.quantity;
      }, products),
    });
  }
};

export const purchaseCreator = (projection, p_lang) => {
  if (typeof twq == 'function' && G.collabo_type == 'creator') {
    const products = map(function (up) {
      const up_c_ss = go(
        up._.up_cs,
        map(function (up_c) {
          return up_c._.up_c_ss;
        }),
        flat,
      );

      const quantity = sumBy(function (up_c_s) {
        return up_c_s.quantity;
      }, up_c_ss);

      return {
        name: up._.base_product.name,
        quantity,
        price: up._.product_color['price' + _en],
      };
    }, projection._.ups);

    twq('track', 'Purchase', {
      value:
        p_lang == 'kr'
          ? projection.product_price
          : p_lang == 'jp'
          ? projection.product_price_jp
          : projection.product_price_en,
      currency: p_lang == 'kr' ? 'KRW' : p_lang == 'jp' ? 'JPY' : 'USD',
      num_items: sumBy(function (p) {
        return p.quantity;
      }, products),
    });
  }
};
