!(function (lo) {
  G.$t_$help_group_item = _p.t(
    'hg',
    '\
    .help_group_item[_sel="./(#{{hg.id}})"]\
      .id {{hg.id}}\
      .name\
        a[href="/help/detail/{{hg.id}}"] {{hg.name}}\
      .no {{hg.no}}\
      .created_at {{moment(hg.created_at).format("YYYY.M.DD")}}\
      .btns\
        a[href="/help/detail/{{hg.id}}"] 수정\
        button[type="button"].btn_delete 삭제\
 ',
  );

  G.$t_$help_item = _p.t(
    'help',
    '\
    .help_item[_sel="./(#{{help.id}})" _id="{{help.id}}"]\
      .question {{help.title}}\
      .answer {{help.body_html}}\
      .created_at {{moment(help.created_at).format("YYYY.M.DD")}}\
      .btns\
        button[type="button"].btn_modify 수정\
        button[type="button"].btn_delete 삭제\
  ',
  );
})({});
