import { OMPSearchS } from './module/OMPSearchS.js';

/**
 * 검색 결과 페이지 url을 생성하는 함수
 * `keyword`가 비어있으면 `/search`
 * `keyword`가 비어있지 않으면 `/search/all?q=${keyword}`
 *
 * @param {string?} keyword 검색어
 * @return {string} 검색 페이지 url
 */
export const getSearchResultUrl = (keyword) => {
  const trimmed = OMPSearchS.trim(keyword);
  if (trimmed) {
    return `/${TT.lang}/search/all?q=${trimmed}`;
  }
  return `/${TT.lang}/search`;
};
