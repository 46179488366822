import axios from 'axios';
import { saveAs } from 'file-saver';
import { $attr, $closest, $delegate, $find, $findAll, $setVal, $trigger, $val } from 'fxdom/es';
import {
  all,
  delay,
  each,
  entries,
  every,
  flatMap,
  go,
  map,
  mapObject,
  not,
  object,
  pick,
  pipe,
  sel,
  some,
  tap,
  values,
  zip,
} from 'fxjs/es';
import { BpOptionMaskingTapeConstantS } from '../../../BpOption/MaskingTape/S/Constant/module/BpOptionMaskingTapeConstantS.js';
import { BpOptionConstantS } from '../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { createCanvasElement } from '../../../Canvas/S/util.js';
import { isBase64 } from '../../../Maker/F/util.js';
import { downloadCanvas } from '../../../NewMaker/PrintResult/F/Function/makePxToResultPx.js';
import { NewMakerPrintResultF } from '../../../NewMaker/PrintResult/F/Function/module/NewMakerPrintResultF.js';
import { isMimeTypeCorrect } from '../../../Util/F/fs.js';
import { UtilImageS } from '../../../Util/Image/S/Function/module/UtilImageS.js';
import { makeRedLineMockup } from '../../Core/F/mockup_fns.js';
import { composite_faces_step } from '../S/composite_template_maker_tmpl.js';
import { openCompositeResultStepPage } from './composite_result_step_page.js';
import { updateCompositeFaces, updateDateCompositeTemplate } from './fs.js';
import {
  directDownload,
  initializeBoxDataAssocCompositeTemplate,
  isNullBoxData,
  uploadAssocFile,
} from './util.js';
import { getFcanvasByBpfId } from '../../../Maker/F/getSth.js';
import { NewMakerFcanvasSettingF } from '../../../NewMaker/FcanvasSetting/F/Function/module/NewMakerFcanvasSettingF.js';
import { makeTempCvImageByUrl } from '../../../Maker/F/CvImage/fs.js';
import { minusStrokeWidth } from '../../../Maker/F/Fcanvas/stroke.js';
import { getCvObj } from '../../../Maker/F/Fcanvas/cv_object.js';

export const composite_faces_step_page = 'composite_faces_step_page';
$.frame.defn_page({
  page_name: composite_faces_step_page,
  hide_frame_button_type: 'X',
  title: '이미지 매핑 정보 기입 (4/5)',
  tabs: [
    {
      tab_name: 'composite_faces_step_tab',
      template: composite_faces_step,
      appended: pipe(
        $delegate('click', '.etc_meta_save', async function (e) {
          $.don_loader_start();
          const composite_face = go(e.currentTarget, $closest('.composite_face'), box.sel);
          composite_face.etc_meta = go(
            e.currentTarget,
            $closest('.etc_meta'),
            $find('[name="etc_meta"]'),
            (el) => (el.value && go(el.value, JSON.parse)) || null,
          );
          await updateCompositeFaces(pick(['etc_meta', 'id', 'mockup_url'], composite_face));
          await updateDateCompositeTemplate(box.sel('assoc_composite_template').id);
          await initializeBoxDataAssocCompositeTemplate(box.sel('assoc_composite_template').id);
          $.don_loader_end();
        }),
        $delegate('click', '.mockup_download', async (e) => {
          $.don_loader_start();
          const { maker_type, id: base_product_id } = box.sel(
            'assoc_composite_template->_->composite_masks',
          )[0]._.base_product;
          if (maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR) {
            const height_cm = await $.get('/@api/prerequisite_maker/masking_tape_width', {
              base_product_id,
            });
            const width_cm = BpOptionMaskingTapeConstantS.mockup_pattern_cm;
            const px_width = 2000;
            const height_with_margin =
              px_width * ((height_cm + BpOptionMaskingTapeConstantS.safe_margin * 2) / width_cm);
            const height = px_width * (height_cm / width_cm);
            const url = await go(
              createCanvasElement({ width: px_width, height: height_with_margin }),
              (canvas) => UtilImageS.makeGrid(canvas, 50),
              (c) => {
                const ctx = c.getContext('2d');
                ctx.strokeStyle = '#ff0000';
                ctx.lineWidth = 4;
                ctx.strokeRect(0, (height_with_margin - height) / 2, c.width, height);
                return c;
              },
              tap((canvas) =>
                downloadCanvas({
                  canvas,
                  image_type: 'image/png',
                  title: `mockup`,
                }),
              ),
              (c) => {
                return $.uploadFileToOnlyOriginalUrl({
                  url: c.toDataURL(),
                  original_name: 'mockup.png',
                  image_type: 'PNG',
                });
              },
              sel('url'),
            );
            const cf = go(e.currentTarget, $closest('.composite_face'), box.sel);
            const size_face = await NewMakerPrintResultF.getAnySizeFace({
              bpf_id: cf.base_product_face_id,
            });
            size_face.mockup_url = url;
            await $.post('/@api/base_product_faces/size_faces_update', {
              id: cf.base_product_face_id,
              size_faces: [size_face],
            });
          } else {
            const cm_el = $closest('.composite_mask')(e.currentTarget);
            const cf_el = $closest('.composite_face')(e.currentTarget);
            const composite_mask = box.sel(cm_el);
            const cf = box.sel(cf_el);
            const size_face = await NewMakerPrintResultF.getAnySizeFace({
              bpf_id: cf.base_product_face_id,
            });
            let url;
            if (size_face.items && size_face.items[0]?.print_item_url) {
              url = size_face?.items[0]?.print_item_url;
            } else {
              await NewMakerFcanvasSettingF.init({ base_product_id: composite_mask._.base_product.id });
              /*DF*/
              const base_product_face_id = cf.base_product_face_id;
              const {
                data: { product_img_type, sf, mask_url, mask_url2, safe_area_url },
              } = await axios.get('/@api/composite/get_sf_and_product_url', {
                params: {
                  base_product_face_id,
                },
              });
              const data_url = await go(
                makeRedLineMockup({
                  product_img_type,
                  sf,
                  mask_url,
                  mask_url2,
                  safe_area_url,
                }),
                (canvas) => canvas.toDataURL(),
              );
              const fcanvas = getFcanvasByBpfId(base_product_face_id);
              const temp_cv_image = await makeTempCvImageByUrl(data_url);
              const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
              const location = minusStrokeWidth(cv_print_area);
              temp_cv_image.set(location);
              fcanvas.add(temp_cv_image);
              url = fcanvas.toDataURL();
              NewMakerFcanvasSettingF.destroy();
            }
            saveAs(url, `face_${cf._.base_product_face.name}_${cf._.base_product_face.base_product_id}.png`);
          }
          $.don_loader_end();
        }),
        $delegate('click', '.location_btns .copy', function (e) {
          const ct = e.currentTarget;
          function getPositionEls(ct, find_selector) {
            return go(
              ct,
              $closest('.design_position'),
              $findAll(map(([pos, tag]) => `tr[coord_name='${pos}'] input[name='${tag}']`, find_selector)),
            );
          }
          const { tl, br } = go(
            ct,
            $closest('.design_position'),
            $findAll('[coord_name]'),
            map((tr) => [
              $attr('coord_name', tr),
              go(
                tr,
                $findAll('[name]'),
                map((v) => [$attr('name', v), parseInt($val(v))]),
              ),
            ]),
            object,
            mapObject(object),
          );
          go(
            getPositionEls(ct, [
              ['tr', 'x'],
              ['tr', 'y'],
              ['bl', 'x'],
              ['bl', 'y'],
            ]),
            (els) => zip(els, [br.x, tl.y, tl.x, br.y]),
            each(([el, val]) => {
              el.value = parseInt(val, 10);
            }),
          );
        }),
        $delegate('click', '.location_btns .temp_load', (e) => {
          const ct = e.currentTarget;
          const saved_position = localStorage.getItem(`position`).split(',');
          go(
            ct,
            $closest('.design_position'),
            $findAll('input[type=number]'),
            (els) => zip(els, saved_position),
            each(([el, val]) => (el.value = Number(val))),
          );
        }),

        $delegate('click', '.location_btns .temp_save', (e) => {
          const ct = e.currentTarget;
          const input_position = go(
            ct,
            $closest('.design_position'),
            $findAll('input[type=number]'),
            map((el) => $val(el)),
          );
          localStorage.setItem(`position`, input_position);
        }),
        $delegate('click', '.design_position .position_apply', async function (e) {
          const form_el = $closest('form')(e.currentTarget);
          const form = go(new FormData(form_el), (form_dt) => form_dt.entries(), object, mapObject(parseInt));
          go(e.currentTarget, $closest('.design_position'), $find('[coord_name="tl"]'), (tl) => {
            go(tl, $find('input[name="x"]'), $setVal(form.x));
            go(tl, $find('input[name="y"]'), $setVal(form.y));
          });
          go(e.currentTarget, $closest('.design_position'), $find('[coord_name="tr"]'), (tl) => {
            go(tl, $find('input[name="x"]'), $setVal(form.x + form.width));
            go(tl, $find('input[name="y"]'), $setVal(form.y));
          });
          go(e.currentTarget, $closest('.design_position'), $find('[coord_name="bl"]'), (tl) => {
            go(tl, $find('input[name="x"]'), $setVal(form.x));
            go(tl, $find('input[name="y"]'), $setVal(form.y + form.height));
          });
          go(e.currentTarget, $closest('.design_position'), $find('[coord_name="br"]'), (tl) => {
            go(tl, $find('input[name="x"]'), $setVal(form.x + form.width));
            go(tl, $find('input[name="y"]'), $setVal(form.y + form.height));
          });
        }),
        $delegate('click', '.design_position .server_save', async function (e) {
          const ct = e.currentTarget;
          const design_position = go(
            ct,
            $closest('.design_position'),
            $findAll('[coord_name]'),
            map((tr) => [
              $attr('coord_name', tr),
              go(
                tr,
                $findAll('[name]'),
                map((v) => [$attr('name', v), parseInt($val(v))]),
              ),
            ]),
            object,
            mapObject(object),
          );
          const composite_face = go(ct, $closest('.composite_face'), box.sel);
          if (go(design_position, values, flatMap(values), all, not))
            return $.alert('입력되지 않은 포지션 값이 있습니다.');
          composite_face.design_position = design_position;
          $.don_loader_start();
          await delay(500);
          await updateCompositeFaces(pick(['design_position', 'id'], composite_face));
          await updateDateCompositeTemplate(box.sel('assoc_composite_template').id);
          await initializeBoxDataAssocCompositeTemplate(box.sel('assoc_composite_template').id);
          if (
            go(
              box.sel('assoc_composite_template')._.composite_masks,
              flatMap((cm) => cm._.composite_faces),
              every((cf) => cf.map_distort_url),
              not,
            )
          ) {
            $.don_loader_end();
            return $.alert('맵이 모두 업로드 되야 테두리 검사를 하실수 있습니다.');
          }

          await initializeBoxDataAssocCompositeTemplate(box.sel('assoc_composite_template').id);
          $.don_loader_end();
        }),
        $delegate('click', '.next', async function () {
          const datas = go(
            box.sel('assoc_composite_template->_->composite_masks'),
            flatMap((composite_mask) =>
              go(composite_mask._.composite_faces, map(pick(['design_position', 'map_distort_url']))),
            ),
          );
          if (datas.length === 0 || some(isNullBoxData, datas))
            return $.alert('모든 정보를 저장해야 넘어갈수 있습니다.');
          openCompositeResultStepPage(box.sel('assoc_composite_template').id);
        }),
        $delegate('change', '.map_distort_url input[type="file"]', async function (e) {
          const ct = e.currentTarget;
          $.don_loader_start();
          const composite_face = go(ct, $closest('.composite_face'), box.sel);
          const composite_mask_id = go(ct, $closest('.composite_mask'), box.sel).id;
          if (await isMimeTypeCorrect(ct.files[0], false, true, false, false)) {
            await uploadAssocFile(ct, 'map_distort_url', composite_face, 'composite_faces');
            await axios.post('/@api/composite/create_map_mask_and_update_url', { id: composite_mask_id });
            await updateDateCompositeTemplate(box.sel('assoc_composite_template').id);
            await initializeBoxDataAssocCompositeTemplate(box.sel('assoc_composite_template').id);
            if (
              go(
                box.sel('assoc_composite_template')._.composite_masks,
                flatMap((cm) => cm._.composite_faces),
                every((cf) =>
                  go(
                    cf.design_position,
                    entries,
                    every(([k, xy]) => xy.x && xy.y),
                  ),
                ),
                not,
              )
            ) {
              $.don_loader_end();
              return $.alert('맵이 모두 업로드 되야 테두리 검사를 하실수 있습니다.');
            }
            await initializeBoxDataAssocCompositeTemplate(box.sel('assoc_composite_template').id);
          } else {
            $.alert('지원되지 않는 파일 형식 입니다. 파일을 확인하시고 다시 시도해 주세요.');
          }
          $.don_loader_end();
        }),
        $delegate('click', '.map_distort_url .upload', function (e) {
          $trigger('click', go(e.currentTarget, $closest('.map_distort_url'), $find('input[type="file"]')));
        }),
        $.on('click', '.download_btn', function (e) {
          e.originalEvent.preventDefault();
          directDownload(
            isBase64($attr('url', e.currentTarget))
              ? $attr('url', e.currentTarget)
              : `https:${$attr('url', e.currentTarget)}`,
            $attr('download_name', e.currentTarget),
            'image/png',
          );
        }),
      ),
    },
  ],
});

export function openCompositeFacesStepPage(composite_template_id) {
  $.frame.add_page({
    page_name: 'composite_faces_step_page',
    tabs: [
      {
        tab_name: 'composite_faces_step_tab',
        data_func: async () => {
          await initializeBoxDataAssocCompositeTemplate(composite_template_id);
          return box.sel('assoc_composite_template');
        },
      },
    ],
  });
}
