import { resetBgWithoutCvMaskBySf } from '../SizeFaces/fs.js';
export function makeCvShadingInMakerA(canvas, bpf_shading_url, sf) {
  const lastCvShading = G.mp.maker.cv_shading(canvas);
  if (lastCvShading) canvas.remove(lastCvShading);
  return new Promise(function (resolve) {
    fabric.Image.fromURL(G.mp.maker.url(bpf_shading_url), function (img) {
      img.set({
        visible: !!bpf_shading_url,
        selectable: false,
        evented: false,
        _data: { cv_type: 'cv_shading', is_not_design: true },
      });
      canvas.add(img);
      resetBgWithoutCvMaskBySf(img, sf);
      canvas.renderAll();
      resolve();
    });
  });
}
