import { VectorEditorStickerFreeMobileF } from '../Function/module/VectorEditorStickerFreeMobileF.js';
import { equals2 } from 'fxjs/es';

export const handleFreeTemplateCuttingLineTouchEnd =
  ({ tab_el }) =>
  ({ changedTouches: changed_touches, touches }) => {
    if (!(changed_touches.length === 1 && touches.length <= 0)) {
      return;
    }
    if (!equals2(tab_el.__mp_free_template_svg_el?.dataset?.is_show)('true')) {
      return;
    }
    return VectorEditorStickerFreeMobileF.addNewSticker({ tab_el });
  };
