export default function (lo) {
  $.frame.defn_frame({
    frame_name: 'mp.high_quality_frame',
    page_name: 'mp.high_quality_page',
    header_height: G.is_pc_size() ? 0 : 60,
    appended: function () {
      // if (!G.is_pc_size()) {
      //   $.css($1('.main.section .img'), {height: $.width(window) * 240/ 375})
      // }
    },
  });

  $.frame.defn_page({
    page_name: 'mp.high_quality_page',
    tabs: [
      {
        tab_name: 'mp.high_quality_tab',
      },
    ],
  });

  $.frame({
    frame_name: 'mp.high_quality_frame',
  });
}
