import { go, html, join, mapL, takeL } from 'fxjs/es';

export const makeHeaderHtml = (tabs) =>
  go(
    tabs,
    mapL(({ title }) => title),
    mapL((title) => html`<span>${title}</span>`),
    takeL(1),
    join(''),
    (s) =>
      html`<div
        style="height: 48px; display: flex; align-items: center; justify-content: center; font-weight: bold; font-size: 14px;"
      >
        ${s}
      </div>`,
  );
