import { html } from 'fxjs/es';

export const buyLaterProductEditColumnTmpl = () => {
  return html`
    <button class="omp-my-wish__add-to-cart">${TT('my_page::wish::wish_11')}</button>
    <button type="button" class="omp-my-wish__product-delete divided-edit-column">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M1 1L13 13M1 13L13 1" stroke="#858585" stroke-width="1.2" stroke-linecap="round" />
      </svg>
    </button>
  `;
};
