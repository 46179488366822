import { html } from 'fxjs/es';
import { MP_MAIL_ORDER_SALES } from '../../../../../Mp/Address/S/Constant/address.js';
import { MpAddressConstantS } from '../../../../../Mp/Address/S/Constant/module/MpAddressConstantS.js';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

const makeFooterKrHtml = () => {
  return html`
    <div id="footer" class="${TT.lang}">
      <div class="footer_wrap">
        <div class="pc_v logo">
          <a href="/${TT.lang}"
            ><img
              src="//s3.marpple.co/files/u_218933/2023/7/original/f01a70531eac8ab8ced3654146a153db12da80a91.svg"
              alt=""
          /></a>
        </div>
        <div class="countries pc_v">
          <div class="selected">
            <span class="flag">${OMPCoreAtomTmplS.krIcon()}</span>
            <span>KR</span>
          </div>
          <ul>
            <li>
              <a href="/en" class="omp-change-lang" data-lang="en">
                <span class="flag">${OMPCoreAtomTmplS.enIcon()}</span>
                <span>EN</span>
              </a>
            </li>
            <li>
              <a href="/jp" class="omp-change-lang" data-lang="jp">
                <span class="flag">${OMPCoreAtomTmplS.jpIcon()}</span>
                <span>JP</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="footer_menus">
          <div class="footer_menu">
            <h4>About</h4>
            <ul>
              <li>
                <a href="/${T.lang}/about">마플에 처음 오셨나요?</a>
              </li>
              <li>
                <a href="/${T.lang}/high_quality">인쇄 퀄리티</a>
              </li>
              <li>
                <a href="/${T.lang}/review/list?is_best=true&is_all=false">실시간 리뷰</a>
              </li>
              <li>
                <a href="/${T.lang}/event/list">이벤트</a>
              </li>
              <li>
                <a href="https://marpple.shop/${T.lang}" target="_blank">마플샵</a>
              </li>
              <li>
                <a href="https://www.marpplecorp.com/" target="_blank">마플코퍼레이션</a>
              </li>
              <li>
                <a
                  href="https://updatenote.marpplecorp.com/c6e12542-33da-4818-8f24-5f767e5c8a2a"
                  target="_blank"
                  >업데이트 노트</a
                >
              </li>
            </ul>
          </div>
          <div class="footer_menu">
            <h4>Help</h4>
            <ul>
              <li>
                <a href="/${T.lang}/help_center?type=order_pay_kr">주문/결제 문의</a>
              </li>
              <li>
                <a href="/${T.lang}/help_center?type=make_group_kr">제작/단체 문의</a>
              </li>
              <li>
                <a href="/${T.lang}/help_center?type=image_edit_kr">이미지/프린팅 문의</a>
              </li>
            </ul>
          </div>
          <div class="footer_menu follow">
            <h4>Follow us</h4>
            <ul>
              <li class="instagram">
                <a href="https://www.instagram.com/marpple_official/" target="_blank">
                  ${OMPCoreAtomTmplS.instaIcon()}
                </a>
              </li>
              <li class="facebook">
                <a href="https://www.facebook.com/marpplecom" target="_blank">
                  ${OMPCoreAtomTmplS.facebookIcon()}
                </a>
              </li>
              <li class="blog">
                <a href="https://blog.naver.com/marpple" target="_blank"> ${OMPCoreAtomTmplS.blogIcon()} </a>
              </li>
              <li class="youtube">
                <a href="https://www.youtube.com/@Marpple.official" target="_blank">
                  ${OMPCoreAtomTmplS.youtubeIcon()}
                </a>
              </li>
              <li class="twitter">
                <a href="https://twitter.com/marpple_life" target="_blank">
                  ${OMPCoreAtomTmplS.twitterIcon()}
                </a>
              </li>
              <li>
                <a href="http://goto.kakao.com/@marpple" target="_blank" style="display:none;"></a>
              </li>
            </ul>
          </div>
          <div class="footer_menu contact_us">
            <h4>Contact us</h4>
            <ul>
              <li>
                <div class="phone_number"><div class="pc_v">1566-9437</div></div>
              </li>
              <li>상담가능시간: 평일 10:00 ~ 18:00</li>
              <li><a href="mailto:help@marpple.com">help@marpple.com</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer_wrap2 pc_v" lang="${T.lang}">
        <div class="policy">
          <div class="header">
            <a
              href="https://s3.marpple.co/files/u_29089/2023/6/original/2d560b10f72f8ab255c716c03392162e44523e7d1.pdf"
              class="bar"
              target="_blank"
              >회사소개 PDF</a
            >
            <a href="/${T.lang}/privacy" class="bar">개인정보취급방침</a>
            <a href="/${T.lang}/terms">이용약관</a>
          </div>
          <div class="body">
            <div>
              <span class="bar">(주)마플코퍼레이션<span class="info mobile_v">사업자 정보</span></span>
              <span class="bar">대표 박혜윤</span>
              <span class="bar"
                >사업자등록번호 105-88-13322<a
                  href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1058813322&apv_perm_no="
                  class="ftc"
                  target="_blank"
                  >사업자정보 확인</a
                ></span
              >
              <span>통신판매업신고 ${MP_MAIL_ORDER_SALES}</span>
            </div>
            <div>
              <span class="bar">${MpAddressConstantS.MP_ADDRESS}</span>
              <span class="bar">고객센터 1566-9437</span>
              <span>팩스 02-6486-9437</span>
            </div>
            <div>
              <span
                >마플 콘텐츠의 저작권은 저작권자 또는 제공처에 있으며, 이를 무단 사용 및 도용하는 경우
                저작권법 등에 따라 법적 책임을 질 수 있음을 알려드립니다.</span
              >
            </div>
            <div>
              <span>&copy; ${new Date().getFullYear()} Marpple Corp. All rights reserved.</span>
            </div>
          </div>
        </div>
        <div class="policy2">
          <img
            src="//s3.marpple.co/files/u_218933/2023/7/original/2474471db6a3fe9bf9ec444a3967ee69ce40f7641.png"
            alt=""
          />
          <a
            href="#"
            class="escrow"
            onclick="javascript:window.open('https://mark.inicis.com/mark/escrow_popup.php?mid=marketpre1','mark','scrollbars=no,resizable=no,width=565,height=683');"
          ></a>
        </div>
        <div class="logos">
          <div class="for_the_planet">
            <a href="https://www.onepercentfortheplanet.org/" target="_blank">
              <img
                src="//s3.marpple.co/files/u_218933/2023/7/original/9fb092c2ea97368a117c37697bf457ad51b9f5331.png"
                alt=""
              />
            </a>
          </div>
          <div class="iflogo">
            <a href="https://ifdesign.com/en/winner-ranking/project/marpple/206874?q=marpple" target="_blank">
              <img
                src="//s3.marpple.co/files/u_218933/2023/7/original/4e56cc04812c202df04d2480d75df38ea78cd8fb1.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  `;
};

const makeFooterEnHtml = () => {
  return html`
    <div id="footer" class="${T.lang}">
      <div class="footer_wrap">
        <div class="pc_v logo">
          <a href="/${T.lang}">
            <img
              src="//s3.marpple.co/files/u_218933/2023/7/original/f01a70531eac8ab8ced3654146a153db12da80a91.svg"
              alt=""
            />
          </a>
        </div>
        <div class="countries pc_v">
          <div class="selected">
            <span class="flag">${OMPCoreAtomTmplS.enIcon()}</span>
            <span>EN</span>
          </div>
          <ul>
            <li>
              <a href="/kr" class="omp-change-lang" data-lang="kr">
                <span class="flag">${OMPCoreAtomTmplS.krIcon()}</span>
                <span>KR</span>
              </a>
            </li>
            <li>
              <a href="/jp" class="omp-change-lang" data-lang="jp">
                <span class="flag">${OMPCoreAtomTmplS.jpIcon()}</span>
                <span>JP</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="how_can_we_help mobile_v">
          <div class="icon"></div>
          <div class="title">
            <h3>Get in Touch</h3>
          </div>
          <div class="content">Visit our FAQ, write us in the chat or contact us at cs@marpple.com</div>
          <div class="buttons">
            <div class="send_email button">
              <div>
                <a href="mailto:cs@marpple.com">Email Us</a>
              </div>
            </div>
            <div class="live_chat button">
              <div>
                <a href="#">1:1 Live Chat</a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer_menus">
          <div class="footer_menu">
            <h4>Marpple</h4>
            <ul>
              <li>
                <a href="/${T.lang}/about">About Us</a>
              </li>
              <li>
                <a href="/${T.lang}/high_quality">High Quality</a>
              </li>
              <li>
                <a href="https://marpple.shop/en" target="_blank">MarppleShop</a>
              </li>
              <li>
                <a href="/${T.lang}/review/list?is_best=true&is_all=false">Reviews</a>
              </li>
              <li>
                <a href="https://www.marpplecorp.com/" target="_blank">Marpple Corporation</a>
              </li>
            </ul>
          </div>
          <div class="footer_menu">
            <h4>Account</h4>
            <ul>
              <li style="display: none;">
                <a href="/${T.lang}/order">My Account</a>
              </li>
              <li>
                <a href="/${T.lang}/order">Order Status</a>
              </li>
              <li style="display: none;">
                <a href="/${T.lang}/order">My Store</a>
              </li>
            </ul>
          </div>
          <div class="footer_menu">
            <h4>Guideline</h4>
            <ul>
              <li>
                <a href="/${T.lang}/guideline/images">Image Guideline</a>
              </li>
              <li>
                <a href="/${T.lang}/guideline/copyright">Copyright Guideline</a>
              </li>
            </ul>
          </div>
          <div class="footer_menu">
            <h4>Help</h4>
            <ul>
              <li>
                <a href="/${T.lang}/help_center?type=buying_shipping">Buying & Shipping</a>
              </li>
              <li>
                <a href="/${T.lang}/help_center?type=creating_selling">Creating & Selling</a>
              </li>
              <li>
                <span class="btn_shipping_county">Shipping Country</span>
              </li>
              <li>
                <a href="/${T.lang}/contact_us">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer_wrap2 pc_v" lang="${T.lang}">
        <div class="policy">
          <div class="header">
            <a
              href="//s3.marpple.co/files/u_218933/2021/10/original/11763e629c0bebd81348531f9c1de386fbff0e701.pdf"
              class="bar company_profile"
              >Company Profile</a
            >
            <a href="/${T.lang}/terms" class="bar terms_of_service">Terms & Conditions</a>
            <a href="/${T.lang}/privacy" class="privacy_policy">Privacy Policy</a>
          </div>
          <div class="body">
            <div>
              <span class="bar">Marpple Corp.</span>
              <span class="bar">${MpAddressConstantS.MP_ADDRESS_EN}</span>
              <span>Customer Service:&nbsp;<a href="mailto:cs@marpple.com">cs@marpple.com</a></span>
            </div>
          </div>
        </div>
        <div class="copy_right">
          Copyright © ${new Date().getFullYear()} Marpple Corp. All rights reserved.
        </div>
        <div class="logos">
          <div class="for_the_planet">
            <a href="https://www.onepercentfortheplanet.org/" target="_blank">
              <img
                src="//s3.marpple.co/files/u_218933/2023/7/original/9fb092c2ea97368a117c37697bf457ad51b9f5331.png"
                alt=""
              />
            </a>
          </div>
          <div class="iflogo">
            <a href="https://ifdesign.com/en/winner-ranking/project/marpple/206874?q=marpple" target="_blank">
              <img
                src="//s3.marpple.co/files/u_218933/2023/7/original/4e56cc04812c202df04d2480d75df38ea78cd8fb1.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div class="footer_wrap3 mobile_v">
        <div class="logos">
          <div class="for_the_planet">
            <a href="https://www.onepercentfortheplanet.org/" target="_blank">
              <img
                class="don_lazy"
                data-src="//s3.marpple.co/files/u_29089/2019/8/original/f_267945_1565325799313_NwoycDl9r9JK0tUDzX.png"
                alt=""
              />
            </a>
          </div>
          <div class="iflogo">
            <a href="https://ifdesign.com/en/winner-ranking/project/marpple/206874?q=marpple" target="_blank">
              <img
                class="don_lazy"
                data-src="//s3.marpple.co/files/u_29089/2019/5/original/f_265690_1557983697139_t0dv5a5tt9nGuRST0iIh.png"
                alt=""
              />
            </a>
          </div>
        </div>
        <div class="policy">
          <div class="body">
            <div class="company_info">
              <span class="title">Marpple Corp.</span>
              <div class="company_info2">
                <div>${MpAddressConstantS.MP_ADDRESS_EN}</div>
                <div>
                  <a href="mailto:cs@marpple.com">cs@marpple.com</a>
                </div>
              </div>
            </div>
            <div class="copy_right">
              Copyright © ${new Date().getFullYear()} Marpple Corp.<br />All rights reserved.
            </div>
            <div>
              <span class="bar">
                <a href="/${T.lang}/terms" class="terms_of_service">Terms & Conditions</a>
              </span>
              <span>
                <a href="/${T.lang}/privacy" class="privacy_policy">Privacy Policy</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

const makeFooterJpHtml = () => {
  return html`
    <div id="footer" class="${T.lang}">
      <div class="footer_wrap">
        <div class="pc_v logo">
          <a href="/${T.lang}">
            <img
              src="//s3.marpple.co/files/u_218933/2023/7/original/f01a70531eac8ab8ced3654146a153db12da80a91.svg"
              alt=""
            />
          </a>
        </div>
        <div class="countries pc_v">
          <div class="selected">
            <span class="flag">${OMPCoreAtomTmplS.jpIcon()}</span>
            <span>JP</span>
          </div>
          <ul>
            <li>
              <a href="/kr" class="omp-change-lang" data-lang="kr">
                <span class="flag">${OMPCoreAtomTmplS.krIcon()}</span>
                <span>KR</span>
              </a>
            </li>
            <li>
              <a href="/en" class="omp-change-lang" data-lang="en">
                <span class="flag">${OMPCoreAtomTmplS.enIcon()}</span>
                <span>EN</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="how_can_we_help mobile_v">
          <div class="icon"></div>
          <div class="title">
            <h3>カスタマーサービス</h3>
          </div>
          <div class="content">平日 10:00 ~ 18:00</div>
          <div class="buttons">
            <div class="send_email button">
              <div>
                <a href="mailto:cs@marpple.com">メールでお問い合わせ</a>
              </div>
            </div>
            <div class="live_chat button">
              <div>
                <a href="#">1:1相談サービス</a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer_menus">
          <div class="footer_menu">
            <h4>Marpple</h4>
            <ul>
              <li>
                <a href="/${T.lang}/about">MARPPLEとは</a>
              </li>
              <li>
                <a href="/${T.lang}/high_quality">プリントクオリティー</a>
              </li>
              <li>
                <a href="/${T.lang}/review/list?is_best=true&is_all=false">お客様の声</a>
              </li>
              <li>
                <a href="https://marpple.shop/${T.lang}" target="_blank">MarppleShop</a>
              </li>
              <li>
                <a href="https://www.marpplecorp.com/" target="_blank">Marpple Corporation</a>
              </li>
            </ul>
          </div>
          <div class="footer_menu">
            <h4>Account</h4>
            <ul>
              <li style="display: none;">
                <a href="/${T.lang}/order">My Account</a>
              </li>
              <li>
                <a href="/${T.lang}/order">ご購入内容</a>
              </li>
              <li style="display: none;">
                <a href="/${T.lang}/order">My Store</a>
              </li>
            </ul>
          </div>
          <div class="footer_menu">
            <h4>Guideline</h4>
            <ul>
              <li>
                <a href="/${T.lang}/guideline/images">イメージガイドライン</a>
              </li>
              <li>
                <a href="/${T.lang}/guideline/copyright">著作権ガイドライン</a>
              </li>
            </ul>
          </div>
          <div class="footer_menu">
            <h4>HELP</h4>
            <ul>
              <li>
                <a href="/${T.lang}/help_center?type=order_pay_jp">注文・決済について</a>
              </li>
              <li>
                <a href="/${T.lang}/help_center?type=make_group_jp">製作・製作過程について</a>
              </li>
              <li>
                <a href="/${T.lang}/help_center?type=image_edit_jp">イメージ・プリントについて</a>
              </li>
              <li>
                <a href="/${T.lang}/contact_us">お問い合わせ</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer_wrap2 pc_v" lang="${T.lang}">
        <div class="policy">
          <div class="header">
            <a
              href="https://s3.marpple.co/files/u_218933/2019/11/original/f_781736_1574389540446_m1PKRJpq5IUi4A0K0UcO.pdf"
              class="bar company_profile"
              >会社概要</a
            >
            <a href="/${T.lang}/terms" class="bar terms_of_service">利用規約</a>
            <a href="/${T.lang}/privacy" class="bar privacy_policy">プライバシーポリシー</a>
            <a class="privacy_policy" href="/${T.lang}/commerce_law">特定商取引法に基づく表記</a>
          </div>
          <div class="body">
            <div>
              <span class="bar">Marpple Corp.</span>
              <span class="bar">${MpAddressConstantS.MP_ADDRESS_JP},</span>
              <span class="bar">050-5840-0207</span>
              <span>カスタマーサービス:&nbsp;<a href="mailto:cs@marpple.com">cs@marpple.com</a></span>
            </div>
          </div>
        </div>
        <div class="copy_right">
          Copyright © ${new Date().getFullYear()} Marpple Corp. All rights reserved.
        </div>
        <div class="logos">
          <div class="for_the_planet">
            <a href="https://www.onepercentfortheplanet.org/" target="_blank">
              <img
                src="//s3.marpple.co/files/u_218933/2023/7/original/9fb092c2ea97368a117c37697bf457ad51b9f5331.png"
                alt=""
              />
            </a>
          </div>
          <div class="iflogo">
            <a href="https://ifdesign.com/en/winner-ranking/project/marpple/206874?q=marpple" target="_blank">
              <img
                src="//s3.marpple.co/files/u_218933/2023/7/original/4e56cc04812c202df04d2480d75df38ea78cd8fb1.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div class="footer_wrap3 mobile_v">
        <div class="logos">
          <div class="for_the_planet">
            <a href="https://www.onepercentfortheplanet.org/" target="_blank">
              <img
                class="don_lazy"
                data-src="//s3.marpple.co/files/u_29089/2019/8/original/f_267945_1565325799313_NwoycDl9r9JK0tUDzX.png"
                alt=""
              />
            </a>
          </div>
          <div class="iflogo">
            <a href="https://ifdesign.com/en/winner-ranking/project/marpple/206874?q=marpple" target="_blank">
              <img
                class="don_lazy"
                data-src="//s3.marpple.co/files/u_29089/2019/5/original/f_265690_1557983697139_t0dv5a5tt9nGuRST0iIh.png"
                alt=""
              />
            </a>
          </div>
        </div>
        <div class="policy">
          <div class="body">
            <div class="company_info">
              <span class="title">Marpple Corp.</span>
              <div class="company_info2">
                <div>${MpAddressConstantS.MP_ADDRESS_JP}</div>
                <div>
                  <a>050-5840-0207</a>&nbsp;
                  <a href="mailto:cs@marpple.com">cs@marpple.com</a>
                </div>
              </div>
            </div>
            <div class="copy_right">
              Copyright © ${new Date().getFullYear()} Marpple Corp.<br />All rights reserved.
            </div>
            <div>
              <span class="bar">
                <a href="/${T.lang}/terms" class="terms_of_service">利用規約</a>
              </span>
              <span class="bar">
                <a href="/${T.lang}/privacy" class="privacy_policy">プライバシーポリシー</a>
              </span>
              <span>
                <a class="privacy_policy" href="/${T.lang}/commerce_law">特定商取引法に基づく表記</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

const makeMpFooterHtml = () => {
  if (T.lang == 'kr') return makeFooterKrHtml();
  if (T.lang == 'en') return makeFooterEnHtml();
  if (T.lang == 'jp') return makeFooterJpHtml();
};

export const footerHTML = makeMpFooterHtml;
