import { format } from 'date-fns';
import $dataStr from 'fxdom/es/dataStr.js';
import { filter, find, go, map, sel, html, strMap } from 'fxjs/es';
import { UserProductS } from '../../../../../UserProduct/S/Function/module/UserProductS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPMyPageOrderS } from '../../../../MyPage/Order/S/Function/module/OMPMyPageOrderS.js';
import { productRateStarsTmpl } from './reviewWriteTmplPc.js';

export const reviewWriteTmplMo = (user_product) => {
  const review_complete = TT('review::write::write_02');
  const comment_input_title = TT('review::write::write_03');
  const comment_input_desc = TT('review::write::write_11');
  const comment_input_placeholder = TT('review::write::write_04_01');
  const review_sns_title = TT('review::write::write_05');
  const review_sns_description = TT('review::write::write_06_01');
  const review_sns_placeholder = TT('review::write::write_07');
  const review_agree_marketing = TT('review::write::write_08_01');
  const review_rate = TT('review::write::write_09');
  const review_product_no = TT('review::write::write_13', { no: user_product.id });

  const can_purchase_url =
    user_product.base_product_id && user_product._.product_color._.base_product.id
      ? OMPMyPageOrderS.makeUpcUrl(user_product)
      : false;

  const product_face = sel('_.product_color.product_faces2.value.0', user_product);
  const thumbnail_url = go(
    sel('_.product_color.thumbnails.value', user_product),
    find(sel('is_thumb')),
    sel('url'),
  );

  const pdf_printing_file = user_product._.product_color._.pdf_printing_file;
  const has_pdf_printing_file = !UtilS.isEmpty(pdf_printing_file);
  const bp_thumbnail = user_product._.product_color._.base_product[OMPCoreUtilS.col('model_thumb_url')];

  const ordered_at = user_product._.projection.ordered_at;

  const currency_en = user_product._.projection.lang == 'kr' ? '' : `_${user_product._.projection.lang}`;

  const name = user_product._.product_color._.base_product[OMPCoreUtilS.col('name')];

  const select_option_name = user_product._.product_color._.selected_option_group[OMPCoreUtilS.col('title')];

  const infos = go(
    user_product._.up_c_ss,
    filter((up_c_s) => up_c_s.quantity),
    map((up_c_s) => {
      return {
        color: user_product._.product_color._.base_product_color[OMPCoreUtilS.col('name')],
        size: up_c_s._.base_product_size[OMPCoreUtilS.col('name')],
        select_option_name,
        quantity: UtilS.commify(up_c_s.quantity),
        selected_option_group: user_product._.product_color._.selected_option_group,
        base_product_size: up_c_s._.base_product_size,
        base_product_color: user_product._.product_color._.base_product_color,
        base_product: user_product._.product_color._.base_product,
        product_faces2: user_product._.product_color.product_faces2,
      };
    }),
  );

  const { price, discounted_price } = OMPMyPageOrderS.makeUpcProductPrice(user_product, currency_en);

  return html`
    <form class="omp-review-write">
      <div class="omp-review-write__product-wrapper">
        <a href="${can_purchase_url || ''}" class="omp-review-write__product-container">
          <div class="omp-review-write__product__order-info">
            <span class="omp-review-write__product__order-info-text underline">${review_product_no} </span>
            <span class="omp-review-write__product__order-info-text">
              ${format(new Date(ordered_at), 'yyyy-MM-dd')}
            </span>
          </div>
          <div class="omp-review-write__product">
            <div
              class="omp-review-write__product-thumbnail"
              data-fx-json="${!thumbnail_url && product_face ? $dataStr(product_face) : ''}"
            >
              ${has_pdf_printing_file
                ? `<img src="${bp_thumbnail}" alt="" />`
                : thumbnail_url
                ? html` <img src="${thumbnail_url}" alt="" /> `
                : html`${UserProductS.thumbnail.smallCanvasHtml()}`}
            </div>
            <div class="omp-review-write__product-info-wrapper">
              <input type="hidden" name="user_product_id" value="${user_product.id}" />
              <div class="mp-review-write__product-info-detail">${name}</div>
              <div class="mp-review-write__product-info-options">
                ${strMap(
                  ({
                    quantity,
                    selected_option_group,
                    base_product_size,
                    base_product_color,
                    base_product,
                  }) => {
                    const option_names = UserProductS.naming.makeOptionNames1({
                      selected_option_group,
                      base_product_size,
                      base_product_color,
                      base_product,
                    });

                    return html`
                      <div class="mp-review-write__product-info-detail">
                        ${!UtilS.isEmpty(option_names) ? `${option_names} /` : ''}
                        ${TT('common::quantity', { quantity })}
                      </div>
                    `;
                  },
                  infos,
                )}
              </div>
              <div class="omp-review-write__product-info-detail">
                ${price === discounted_price
                  ? OMPMyPageOrderS.getCurrency(price, currency_en)
                  : html`
                      <span class="mp-review-write__product-info-discount-price">
                        <span class="discounted">${OMPMyPageOrderS.getCurrency(price, currency_en)}</span>
                        <span>${OMPMyPageOrderS.getCurrency(discounted_price, currency_en)}</span>
                      </span>
                    `}
              </div>
            </div>
          </div>
        </a>
        <div class="omp-review-write__product-rate">
          <span class="omp-review-write__product-rate-title">${review_rate}</span>
          ${productRateStarsTmpl()}
          <input type="number" name="score" value="5" />
        </div>
      </div>
      <div class="omp-review-write__content">
        <span class="omp-review-write__content-title">${comment_input_title} ${comment_input_desc}</span>
        <div class="omp-review-write__content-wrapper">
          <textarea name="comment" placeholder="${comment_input_placeholder}"></textarea>
        </div>
        <div class="omp-review-write__photo-wrapper">
          <div class="omp-review-write__photos"></div>
          <label class="add-photo">
            <input type="file" name="file" accept="image/jpeg,image/png,image/pjpeg,image/gif" multiple="" />
            <span class="add-photo__icon-wrapper">${OMPCoreAtomTmplS.addPhotoIcon()}</span>
          </label>
        </div>
      </div>
      <div class="omp-review-write__sns-wrapper">
        <span class="omp-review-write__sns-title">${review_sns_title}</span>
        <span class="omp-review-write__sns-description">${review_sns_description}</span>
        <div class="omp-review-write__sns-input-wrapper">
          ${OMPCoreAtomTmplS.input({
            name: 'sns_url',
            type: 'text',
            placeholder: review_sns_placeholder,
            style: {
              height: 40,
              underline: true,
              text_size: 'normal',
            },
          })}
        </div>
      </div>
      <div class="omp-review-write__agree_marketing">
        ${OMPCoreAtomTmplS.emptyCheckbox({
          name: 'agree_marketing',
          title: review_agree_marketing,
          style: { size: 'normal' },
        })}
      </div>
      ${OMPCoreAtomTmplS.actionButton({ text: review_complete })}
    </form>
  `;
};
