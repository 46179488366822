import { $findAll } from 'fxdom/es';
import { each, go } from 'fxjs/es';

export const showTypeSelectFooters = (tab_el) => {
  go(
    tab_el,
    $findAll('.footer'),
    each((el) => {
      if (el.classList.contains('select')) {
        el.dataset.is_show = 'true';
        return;
      }
      el.dataset.is_show = 'false';
    }),
  );
};

export const showTypeUnselectFooters = (tab_el) => {
  go(
    tab_el,
    $findAll('.footer'),
    each((el) => {
      if (el.classList.contains('unselect')) {
        el.dataset.is_show = 'true';
        return;
      }
      el.dataset.is_show = 'false';
    }),
  );
};
