import { $qs } from 'fxdom/es';
import { BpOptionS } from '../../../../BpOption/S/Function/module/BpOptionS.js';
import { BpOptionUploadTypeS } from '../../../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';

export const setDataUploadTypeIsDesigned = () => {
  const product = box.sel('maker->product_color');
  if (!product) return;
  const is_designed = BpOptionS.isDesigned(product);
  const mp_maker_el = $qs('#maker_frame');
  if (mp_maker_el) {
    mp_maker_el.dataset.upload_type_direct_is_designed =
      is_designed && BpOptionUploadTypeS.UPLOAD_TYPE.isDirectType(product);
  }
};
