import { $closest } from 'fxdom/es';
import { each, extend, go, map, pick } from 'fxjs/es';
import { DfTaskImageWorkF } from '../../../Task/ImageWork/F/Function/module/DfTaskImageWorkF.js';
import { setProjectionsWithOnlyItsUp } from '../../../Task/ImageWork/S/Function/setTaskWithOnlyItsUp.js';
import { makeDfProjectionItemColsHtml } from './tmpl.js';

let is_stop = false;

export const setDfProjectionListUpdateIsStop = (t_f) => (is_stop = t_f);

export const makeDfProjectionListUpdate = function () {
  if (is_stop) return;
  const projections = go($('.prj_item'), map(box.sel), map(pick(['id', 'updated_at'])));
  return go(
    $.get('/@api/projection_list/check_updated', {
      projections,
      product_mode: $.attr($1('[prj_product_mode]'), 'prj_product_mode'),
    }),
    each(async function (projection) {
      await each(async function (el_prj_item) {
        extend(box.sel(el_prj_item), projection);
        const el = $.find1(el_prj_item, '.prj_item_inner');
        if (!el) return;
        el.outerHTML = await makeDfProjectionItemColsHtml(projection);
        if (box().is_user?._?.policies?.outsourcing_worker_policy)
          DfTaskImageWorkF.makeOnlyMyTasks(el_prj_item);
        G.mp.maker.draw_product_face_in_ups(el_prj_item);
      }, $(`.prj_item[prj_id="${projection.id}"]`));
    }),
  );
};

export const makeDfProjectionListUpdateByTaskSetId = function () {
  if (is_stop) return;
  const projections = go($('.prj_item'), map(box.sel), map(pick(['id', 'updated_at'])));
  return go(
    $.get('/@api/projection_list/check_updated', {
      projections,
      product_mode: $.attr($1('[prj_product_mode]'), 'prj_product_mode'),
    }),
    each(async function (prj) {
      await each(async function (el_prj_item) {
        const task = go($closest('.task_item')(el_prj_item), box.sel);
        const projection = setProjectionsWithOnlyItsUp({ projection: prj, task_parent_id: task.parent_id });
        extend(box.sel(el_prj_item), projection);
        const el = $.find1(el_prj_item, '.prj_item_inner');
        if (!el) return;
        el.outerHTML = await makeDfProjectionItemColsHtml(projection);
        if (box().is_user?._?.policies?.outsourcing_worker_policy)
          DfTaskImageWorkF.makeOnlyMyTasks(el_prj_item);
        G.mp.maker.draw_product_face_in_ups(el_prj_item);
      }, $(`.prj_item[prj_id="${prj.id}"]`));
    }),
  );
};
