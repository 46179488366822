import { compareLocationAndSize, trimCanvasSize } from '../../../../Maker/F/canvas_trim.js';
import { makeCanvasByUrl } from '../../../../Canvas/S/util.js';

export async function isSameCanvas(acanvas, bcanvas) {
  return compareLocationAndSize(acanvas, bcanvas);
}

export function compareCanvasSize(base_canvas, target_canvas) {
  const base_canvas_size = trimCanvasSize(base_canvas, 0);
  const target_canvas_size = trimCanvasSize(target_canvas, 0);

  return (
    base_canvas_size.width === target_canvas_size.width &&
    base_canvas_size.height === target_canvas_size.height
  );
}

export async function isSuccessCanvasLogicTest() {
  const [base_canvas, target_canvas] = await Promise.all([
    makeCanvasByUrl(
      '//s3.marpple.co/files/guest/2024/7/original/e0c9ec31d93f82bc16f45df3b2fa2b1e235577081.png',
    ),
    makeCanvasByUrl(
      '//s3.marpple.co/files/guest/2024/7/original/2b4ba93ba7e216d64fe30b98e31675c1471298091.png',
    ),
  ]);
  const base_canvas_ctx = base_canvas.getContext('2d');
  base_canvas_ctx.globalCompositeOperation = 'source-in';
  base_canvas_ctx.drawImage(target_canvas, 0, 0);
  return base_canvas.toDataURL() === target_canvas.toDataURL();
}
