import { $appendTo, $delegate, $el, $qs, $remove } from 'fxdom/es';
import { each, go, map, pick } from 'fxjs/es';
import { getCvObj, makeFilterCvObj } from '../../../../Maker/F/Fcanvas/cv_object.js';
import {
  makeCvObjAlignmentAddEditorTmpl,
  makeCvObjAlignmentEditorTmpl,
} from '../../S/Tmpl/cvobjalignment.js';
import { makeCvObjAlignment } from './cv_obj_alignment.js';
import { minusStrokeWidth } from '../../../../Maker/F/Fcanvas/stroke.js';

export const editorInit = () => {
  if ($qs('.cv_obj_alignment_editor')) return;
  return new Promise((resolve) => {
    const fcanvas = G.mp.maker.editing_canvas();
    go(
      makeFilterCvObj(fcanvas._objects, 'cv_obj_alignment'),
      each((cv_obj) => fcanvas.remove(cv_obj)),
    );
    go(
      makeCvObjAlignmentAddEditorTmpl(),
      $el,
      $appendTo($qs('.cv_obj_alignment_add_editor')),
      $delegate('click', '.add_cv_obj_alignment', () => {
        const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
        const cv_obj_alignment = makeCvObjAlignment(minusStrokeWidth(cv_print_area));
        cv_obj_alignment.set({ visible: true });
        fcanvas.add(cv_obj_alignment);
        go(
          makeCvObjAlignmentEditorTmpl(cv_obj_alignment),
          $el,
          $appendTo($qs('.cv_obj_alignment_editor_wrapper')),
          $delegate('change', '[name="top"]', (e) => {
            cv_obj_alignment.set({ top: parseInt(e.currentTarget.value) }).setCoords();
            fcanvas.renderAll();
          }),
          $delegate('change', '[name="left"]', (e) => {
            cv_obj_alignment.set({ left: parseInt(e.currentTarget.value) }).setCoords();
            fcanvas.renderAll();
          }),
          $delegate('change', '[name="width"]', (e) => {
            cv_obj_alignment.set({ width: parseInt(e.currentTarget.value) }).setCoords();
            fcanvas.renderAll();
          }),
          $delegate('change', '[name="height"]', (e) => {
            cv_obj_alignment.set({ height: parseInt(e.currentTarget.value) }).setCoords();
            fcanvas.renderAll();
          }),
          $delegate('click', '.cv_obj_alignment_cancel', (e) => {
            fcanvas.remove(cv_obj_alignment);
            $remove(e.delegateTarget);
          }),
        );
      }),
      $delegate('click', '.complete_cv_obj_alignment_editor', (e) => {
        const cv_obj_alignments_attrs = go(
          makeFilterCvObj(fcanvas._objects, 'cv_obj_alignment'),
          map(pick(['top', 'left', 'width', 'height'])),
        );

        // go(
        //   makeFilterCvObj(fcanvas._objects, 'cv_obj_alignment'),
        //   each((cv_obj) => fcanvas.remove(cv_obj)),
        // );
        $remove(e.delegateTarget);
        resolve(cv_obj_alignments_attrs);
      }),
      $delegate('click', '.cancel_cv_obj_alignment_editor', (e) => {
        go(
          makeFilterCvObj(fcanvas._objects, 'cv_obj_alignment'),
          each((cv_obj) => fcanvas.remove(cv_obj)),
        );
        $remove(e.delegateTarget);
        resolve(null);
      }),
    );
  });
};
