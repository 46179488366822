import { go, html, isNil, mapL, rangeL, rejectL, takeAllC } from 'fxjs/es';
import { VectorEditorFreeShapeCommonConstantS } from '../../../Common/S/Constant/module/VectorEditorFreeShapeCommonConstantS.js';
import axios from 'axios';

/*
 * tab의 함수중 infinite 함수안에서 MuiF.tabInfinite 를 실행할때 옵션값을 미리 정해둘 수 있습니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#4d6a1f4539274c0885771f559c3655af
 * */
export const infinite_opt = {
  container: '.infi_container', // 인피니티 스크롤을 적용할 컨테이너 엘리먼트
  limit: 30, // 데이터를 가져올때 리밋값
  col: 5, // 무한스크롤 컬럼 개수
  off: false, // true 이면 더 보기 버튼달림 (더 보기를 누른후 무한스크롤 적용)
  makeInfiniteData(offset, limit) {
    return go(
      rangeL(offset, offset + limit),
      mapL((i) => VectorEditorFreeShapeCommonConstantS.SVG_URLS[i]),
      rejectL(isNil),
      mapL((url) => axios.get(url)),
      mapL(({ data }) => data),
      takeAllC,
    );
  }, // 스크롤이 바닥에 가까워질때 가져올 데이터를 리턴하는 함수 (배열)
  template(data) {
    return html`
      <div class="infi_item">
        <div class="infi_item_outer_container">
          <button type="button" class="infi_item_inner_container">${data}</button>
        </div>
      </div>
    `;
  }, // 무한스크롤에 들어갈 .infi_item의 html을 그리는 함수
  appending(els$) {}, // 엘리먼트가 컨테이너에 붙기 전 울리는 함수
  appended(els$) {}, // 엘리먼트가 컨테이너에 붙은 후 울리는 함수
};
