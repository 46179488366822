import { VectorEditorKeyringGridMobilePathEditorMuiS } from '../../S/Mui/module/VectorEditorKeyringGridMobilePathEditorMuiS.js';
import { concatL, each, go, isNil, mapC, reject, takeL, tap } from 'fxjs/es';
import { $findAll, $on } from 'fxdom/es';
import { VectorEditorKeyringGridMobilePathEditorF } from '../Function/module/VectorEditorKeyringGridMobilePathEditorF.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorKeyringGridMobilePathEditorMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_keyring_editor = tab_el.__mp_keyring_editor ?? null;
    tab_el.__mp_path_el = tab_el.__mp_path_el ?? null;

    go(
      tab_el,

      tap($on('contextmenu', (event) => event.preventDefault())),

      tap(
        $findAll(`.top_menu_container .done`),
        each($on('click', VectorEditorKeyringGridMobilePathEditorF.handleTopMenuDoneButtonClick())),
      ),

      tap(
        $findAll(`.outer_container .bottom_menu.add_l`),
        each(
          $on('click', VectorEditorKeyringGridMobilePathEditorF.handleBottomMenuAddLButtonClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.outer_container .bottom_menu.add_c`),
        each(
          $on('click', VectorEditorKeyringGridMobilePathEditorF.handleBottomMenuAddCButtonClick({ tab_el })),
        ),
      ),
      tap(
        $findAll(`.outer_container .bottom_menu.remove`),
        each(
          $on(
            'click',
            VectorEditorKeyringGridMobilePathEditorF.handleBottomMenuRemoveButtonClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.outer_container .bottom_menu.change_to_l`),
        each(
          $on(
            'click',
            VectorEditorKeyringGridMobilePathEditorF.handleBottomMenuChangeToLButtonClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.outer_container .bottom_menu.change_to_c`),
        each(
          $on(
            'click',
            VectorEditorKeyringGridMobilePathEditorF.handleBottomMenuChangeToCButtonClick({ tab_el }),
          ),
        ),
      ),
      tap(
        $findAll(`.outer_container .bottom_menu.close_or_open`),
        each(
          $on(
            'click',
            VectorEditorKeyringGridMobilePathEditorF.handleBottomMenuCloseOrOpenButtonClick({ tab_el }),
          ),
        ),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {
    if (tab_el.__mp_path_el != null && tab_el.__mp_keyring_editor != null) {
      const path_el = tab_el.__mp_path_el;
      const editor = tab_el.__mp_keyring_editor;
      editor.startEditPath(path_el);
      const is_closed = editor.getClosePathCommandList().length > 0;
      go(
        tab_el,
        $findAll(`.outer_container .close_or_open`),
        each((el) => (el.dataset.is_closed = `${is_closed}`)),
      );
      editor.turnOffArtBoardClipPath();
    }
  }, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    go(
      tab_el,
      $findAll(`.outer_container`),
      takeL(1),
      each((el) => new Swiper(el, { slidesPerView: 'auto', freeMode: true })),
    );
    return go(
      tab_el,
      (el) => concatL($findAll('.top_menu_container')(el))($findAll('.outer_container')(el)),
      mapC((el) => {
        const wait = VectorEditorKeyringGridMobilePathEditorF.waitForTransitionEnd(el);
        el.dataset.is_show = 'true';
        return wait;
      }),
    );
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {
    if (tab_el.__mp_keyring_editor != null) {
      const editor = tab_el.__mp_keyring_editor;
      editor.turnOnArtBoardClipPath();
      editor.endEditPath();
      editor.selectEls(reject(isNil)([tab_el.__mp_path_el]));
    }
    return go(
      tab_el,
      (el) => concatL($findAll(`.top_menu_container`)(el))($findAll(`.outer_container`)(el)),
      mapC((el) => {
        const wait = VectorEditorKeyringGridMobilePathEditorF.waitForTransitionEnd(el);
        el.dataset.is_show = 'false';
        return wait;
      }),
    );
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {
    tab_el.__mp_keyring_editor = null;
    tab_el.__mp_path_el = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
