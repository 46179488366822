import { $attr, $qs } from 'fxdom/es';
import { drop, go, ifElse, initial, join, last, sel, selEq, slice, split } from 'fxjs/es';
import { MuiF } from '../../../../modules/Mui/F/Function/module/MuiF.js';

const makeLoader = (loadFrameModule, nextLoader) => (path, name) =>
  loadFrameModule(path).then(
    sel(name),
    ifElse(selEq('code', 'MODULE_NOT_FOUND'), nextLoader, (e) => Promise.reject(e)),
  );

const startFrameByName = ({ frame_name, page_name, tab_name }) => {
  $.frame(
    {
      frame_name,
      page_name,
    },
    {
      page_name,
      tabs: [{ tab_name }],
    },
  );
};

export const startFrame = (loadFrameModule, $don_frame) => {
  const frame_name = $attr('frame_name', $don_frame);
  const page_name = $attr('page_name', $qs('.don_frame .don_page'));
  const tab_name = $attr('tab_name', $qs('.don_frame .don_page .don_tab'));

  const module_path = go(frame_name, split('/'), drop(2));
  const file_path1 = module_path.concat(['F', 'root_frame']);
  const file_path2 = initial(module_path).concat(['F', 'root_frame']);
  const file_path3 = file_path2.concat([last(module_path)]);

  const module_types = [
    [join('/', file_path1), 'default'],
    [join('/', file_path2), last(module_path) || 'default'],
    [join('/', file_path3), 'default'],
  ];

  const [path1, name1] = module_types[0];
  const [path2, name2] = module_types[1];
  const [path3, name3] = module_types[2];

  const loader3 = makeLoader(loadFrameModule, (e) => Promise.reject(e));
  const loader2 = makeLoader(loadFrameModule, () => loader3(path3, name3));
  const loader1 = makeLoader(loadFrameModule, () => loader2(path2, name2));

  return loader1(path1, name1)
    .then((f) => typeof f === 'function' && f())
    .then(() => startFrameByName({ frame_name, page_name, tab_name }))
    .catch((e) => console.error('Failed to load frame.') || Promise.reject(e));
};

const loadMuiModule = (module_path, nextLoader = () => Promise.reject(new Error('MODULE_NOT_FOUND'))) =>
  import(
    /*
      webpackMode: "lazy",
      webpackInclude: /F\/Mui\/module/,
      webpackChunkName: "[request]"
    */
    `../../../../modules/${module_path.join('/')}/F/Mui/module/index.js`
  ).catch(
    ifElse(
      selEq('code', 'MODULE_NOT_FOUND'),
      nextLoader,
      (e) => console.error('Failed to load frame.') || Promise.reject(e),
    ),
  );

export const startMuiFrame = ($don_frame) =>
  go(
    $don_frame,
    $attr('frame_name'),
    split('/'),
    (full_path) => slice(2, full_path.indexOf('S'), full_path),
    loadMuiModule,
  )
    .then((module) => (typeof module.start === 'function' ? module.start() : MuiF.startFrame(module.frame)))
    .catch((e) => console.error('Failed to start frame.') || Promise.reject(e));
