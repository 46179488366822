import { go, some } from 'fxjs/es';
import { getRealFcanvass } from '../../../../Maker/F/getSth.js';
import { getCvDesigns } from '../../../../Maker/F/Fcanvas/cv_object.js';

export const findAiDesignInOnlyUser = () => {
  let cv_obj;
  go(
    getRealFcanvass(),
    some((fcanvas) => {
      cv_obj = G.mp.maker.cv_objects_deep_find(getCvDesigns(fcanvas._objects), (cv_obj) => {
        if (cv_obj._data.cv_type !== 'cv_image') return;
        return !cv_obj._data.image_color_id && cv_obj._data.image_original_url?.indexOf('.ai') > -1;
      });
      return cv_obj;
    }),
  );
  return cv_obj;
};
