import { $addClass, $appendTo, $setCss } from 'fxdom/es';
import { getSizeFitToBox, isCursorInsideCanvas } from './module/index.js';

export const createBoxFitCanvasImage = (
  image,
  resize_fit = false,
  fit_mode,
  resize_w,
  resize_h,
  on_dpr,
  quality,
  size_limit,
  max_texture_size = 16384,
) => {
  const canvas = document.createElement('canvas');
  const { width: img_w, height: img_h } = image;
  const img_ratio = img_w / img_h;
  const { width: view_width, height: view_height } = getSizeFitToBox(
    fit_mode,
    resize_w,
    resize_h,
    img_w,
    img_h,
  );
  if (resize_fit) {
    const quality_limit = 10.0;
    const canvas_to_view_ratio = Math.min(quality_limit, quality * (on_dpr ? window.devicePixelRatio : 1));
    if (img_ratio > 1) {
      canvas.width = Math.min(canvas_to_view_ratio * view_width, Math.max(img_w, 900), size_limit);
      canvas.height = canvas.width / img_ratio;
      if (canvas.height < 100) {
        const override_ratio = 100 / canvas.height;
        canvas.height = 100;
        canvas.width *= override_ratio;
      }
    } else {
      canvas.height = Math.min(canvas_to_view_ratio * view_height, Math.max(img_h, 900), size_limit);
      canvas.width = canvas.height * img_ratio;
      if (canvas.width < 100) {
        const override_ratio = 100 / canvas.width;
        canvas.width = 100;
        canvas.height *= override_ratio;
      }
    }
  } else {
    if (img_ratio > 1) {
      if (img_w > max_texture_size) {
        canvas.width = max_texture_size;
        canvas.height = canvas.width / img_ratio;
        $.alert(
          '이미지 크기가 이미지 필터 적용이 가능한 사이즈로 리사이즈 되었습니다. <br> 원본 크기를 유지해야 하는 경우에는 이미지 조정을 하지 않으시기를 권장합니다.',
        );
      } else {
        canvas.width = img_w;
        canvas.height = img_h;
      }
    } else {
      if (img_h > max_texture_size) {
        canvas.height = max_texture_size;
        canvas.width = img_ratio * canvas.height;
        $.alert(
          '이미지 크기가 이미지 필터 적용이 가능한 사이즈로 리사이즈 되었습니다. <br> 원본 크기를 유지해야 하는 경우에는 이미지 조정을 하지 않으시기를 권장합니다.',
        );
      } else {
        canvas.width = img_w;
        canvas.height = img_h;
      }
    }
  }

  canvas.style.width = `${view_width}px`;
  canvas.style.height = `${view_height}px`;
  drawImageToCanvasWithEqualRatio(fit_mode, image, canvas);
  return canvas;
};

export const createWebGLCanvas = (gl_options) => {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl', gl_options);
  if (!gl) {
    throw new Error('webgl is not supported at the current browser');
  }
  return gl;
};

export const appendGLCanvasToDOM = (id, parent_el, is_display_none) => (gl) => {
  const gl_canvas = gl.canvas;
  gl_canvas.id = id;
  $appendTo(parent_el, gl_canvas);
  if (is_display_none) {
    $setCss({ display: 'none' }, gl_canvas);
  }
  return gl;
};

export const setGLCanvasSizeClone =
  (size_clone_canvas, minimum_length = 0) =>
  (gl) => {
    const canvas = gl.canvas;
    const { width: canvas_w, height: canvas_h } = size_clone_canvas;
    canvas.width = canvas_w;
    canvas.height = canvas_h;
    canvas.style.width = size_clone_canvas.style.width;
    canvas.style.height = size_clone_canvas.style.height;
    if (
      parseInt(size_clone_canvas.style.width) < minimum_length ||
      parseInt(size_clone_canvas.style.height) < minimum_length
    ) {
      $addClass('crop_limit', canvas);
    }
    return gl;
  };

const drawImageToCanvasWithEqualRatio = (fit_mode, image, canvas) => {
  const ctx = canvas.getContext('2d');
  const s_w = image.width;
  const s_h = image.height;
  const d_w = canvas.width;
  const d_h = canvas.height;
  const s_ratio = s_w / s_h;
  const d_ratio = d_w / d_h;

  if (fit_mode === 'fill') {
    ctx.drawImage(image, 0, 0, s_w, s_h, 0, 0, d_w, d_h);
  }
  if (fit_mode === 'contain') {
    ctx.drawImage(image, 0, 0, s_w, s_h, 0, 0, d_w, d_h);
  }
  if (fit_mode === 'cover') {
    if (s_ratio < d_ratio) {
      const r_h = s_w / d_ratio;
      ctx.drawImage(image, 0, (s_h - r_h) / 2, s_w, r_h, 0, 0, d_w, d_h);
    } else {
      const r_w = s_h * d_ratio;
      ctx.drawImage(image, (s_w - r_w) / 2, 0, r_w, s_h, 0, 0, d_w, d_h);
    }
  }
};

export const setCanvasClientSizeToSqaure = ($canvas, length, mag) => {
  $canvas.style.width = `${length}px`;
  $canvas.width = length * mag;
  $canvas.style.height = `${length}px`;
  $canvas.height = length * mag;
};

const isOut = (gl, x, y, top, bottom, left, right, margin) => {
  return (
    x < Math.max(0, left - margin) ||
    x > Math.min(right + margin, parseInt(gl.canvas.style.width)) ||
    y < Math.max(0, top - margin) ||
    y > Math.min(bottom + margin, parseInt(gl.canvas.style.height))
  );
};

export const is_apple_mobile_device = () => {
  const isIphone = navigator.userAgent.indexOf('iPhone') != -1;
  const isIpod = navigator.userAgent.indexOf('iPod') != -1;
  const isIpad = navigator.userAgent.indexOf('iPad') != -1;
  return isIphone || isIpod || isIpad;
};

export const getCursorOrTouchPos = (reference$, e) => {
  const header_height = G.is_pc_size() ? 0 : 0; //px
  const canvas_border = G.is_pc_size() ? 0 : 0; //px
  const rect = reference$.getBoundingClientRect();
  const position = { x: 0, y: 0 };
  if (G.is_pc_size()) {
    position.x = e.pageX - (rect.left + canvas_border) + window.scrollX;
    position.y = e.pageY - (rect.top + canvas_border) + window.scrollY;
  } else {
    if (e.touches && e.touches.length) {
      position.x = e.touches[0].clientX - (reference$.offsetLeft + canvas_border);
      position.y = e.touches[0].clientY - (reference$.offsetTop + header_height + canvas_border);
    } else if (e.changedTouches && e.changedTouches.length) {
      position.x = e.changedTouches[0].clientX - (reference$.offsetLeft + canvas_border);
      position.y = e.changedTouches[0].clientY - (reference$.offsetTop + header_height + canvas_border);
    } else {
      position.x = e.pageX - (rect.left + canvas_border) + window.scrollX;
      position.y = e.pageY - (rect.top + canvas_border) + window.scrollY;
    }
  }

  return position;
};

export const updateCursorStat = (gl, cursor_pos, view_crop_pos) => {
  let cursor_status;
  let cursor_shape;
  const margin = G.is_pc_size() ? 10 : 20; //px
  const { top: c_top, bottom: c_bottom, left: c_left, right: c_right } = view_crop_pos;
  const { x, y } = cursor_pos;
  const client_w = parseInt(gl.canvas.style.width);
  const client_h = parseInt(gl.canvas.style.height);

  if (isCursorInsideCanvas(cursor_pos, client_w, client_h)) {
    if (isOut(gl, x, y, c_top, c_bottom, c_left, c_right, margin)) {
      cursor_status = 'out';
      cursor_shape = 'crosshair';
    } else {
      const isTop = Math.max(0, c_top - margin) <= y && y <= c_top + margin;
      const isBottom = c_bottom - margin <= y && y <= Math.min(c_bottom + margin, client_h);
      const isLeft = Math.max(0, c_left - margin) <= x && x <= c_left + margin;
      const isRight = c_right - margin <= x && x <= Math.min(c_right + margin, client_w);
      cursor_status = 'in';
      cursor_shape = 'move';
      if (isTop) {
        cursor_status = 'top';
        cursor_shape = 'ns-resize';
      }
      if (isBottom) {
        cursor_status = 'bottom';
        cursor_shape = 'ns-resize';
      }
      if (isLeft) {
        cursor_status = 'left';
        cursor_shape = 'ew-resize';
        if (isTop) {
          cursor_status = 'left_top';
          cursor_shape = 'nwse-resize';
        }
        if (isBottom) {
          cursor_status = 'left_bottom';
          cursor_shape = 'nesw-resize';
        }
      }
      if (isRight) {
        cursor_status = 'right';
        cursor_shape = 'ew-resize';
        if (isTop) {
          cursor_status = 'right_top';
          cursor_shape = 'nesw-resize';
        }
        if (isBottom) {
          cursor_status = 'right_bottom';
          cursor_shape = 'nwse-resize';
        }
      }
    }
  } else {
    cursor_status = 'out_of_canvas';
    cursor_shape = 'default';
  }
  document.body.style.cursor = cursor_shape;
  return cursor_status;
};
