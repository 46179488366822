import { html } from 'fxjs/es';

export function brushTool({ tool_name, brush_name, displayName, start, min, max, step }) {
  return html` <div class="brush" brush-name="${brush_name}" tool-name="${tool_name}">
    <label>${displayName}</label>
    <div
      class="slider"
      data-start="${start}"
      data-tool_name="${tool_name}"
      data-brush_name="${brush_name}"
      data-min="${min}"
      data-max="${max}"
      data-step="${step}"
    ></div>
    <input class="indicator" type="number" min="${min}" max=${max} step="${step}" />
  </div>`;
}

export function sliderTool({ tool_name, displayName, start, min, max, step }) {
  return html`
    <label>${displayName}</label>
    <div
      class="slider"
      data-start="${start}"
      data-tool_name="${tool_name}"
      data-min="${min}"
      data-max="${max}"
      data-step="${step}"
    ></div>
    <input class="indicator" type="number" min="${min}" max=${max} step="${step}" />
  `;
}

export function addButtonControllerProperty({ propertyName, displayName, min, max, step, start }) {
  return html`
    <div class="property" property-name="${propertyName}">
      <label>${displayName}</label>
      <div class="button-controller">
        <button class="minus" name="minus"></button>
        <input
          class="indicator"
          type="number"
          min="${min}"
          max=${max}
          step="${step}"
          value="${start ?? min}"
        />
        <button class="plus" name="plus"></button>
      </div>
    </div>
  `;
}
