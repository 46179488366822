!(function () {
  /*
   * df에서 권한이 없을때나 비동기통신 에러처리 간단하게
   * */
  _p.each(['get', 'post', 'put', 'del', 'patch', 'delete', 'upload', 'upload2'], function (method) {
    const $method = $[method];
    $[method] = (...args) =>
      $method(...args).catch((err) =>
        _p.go(
          err,
          _p.add_arg((err) => (err.json ? err.json() : err.responseText)),
          _p.tap(
            _p
              .if2(_p.negate(_p.args1))((err) => $.alert(err))
              .else((err, responseText) => {
                typeof responseText === 'string'
                  ? $.alert(`[${err.status}] ${err.statusText}<br>${responseText}`)
                  : $.alert(
                      `[${err.status}] ${err.statusText}<br>[code: ${responseText.code}] ${responseText.message}`,
                    );
              }),
          ),
          _p.tap(() => setTimeout($.don_loader_end, 1000)),
          (err, resText) => (typeof resText === 'string' ? Promise.reject(err) : Promise.reject(resText)),
        ),
      );
  });
  $.delete = $.del;
})();
