import { go, sel } from 'fxjs/es';
import axios from 'axios';

export function makeDesignReadyBlobUrl(assoc_composite_template) {
  return go(
    axios.post(
      '/@fileupload/composite/create_design_ready_url_buffer',
      {
        assoc_composite_template,
      },
      { responseType: 'arraybuffer' },
    ),
    sel('data'),
    function (buffer) {
      const blob = new Blob([new Uint8Array(buffer)], { type: 'image/png' });
      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(blob);
    },
  );
}
