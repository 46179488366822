export const sendEventclickBizButton = () => {
  if (typeof gtag == 'function') {
    gtag('event', 'open_biz_kit');
    gtag('event', 'conversion', { send_to: 'AW-881715956/gQaqCOCMkbMZEPTVt6QD' });
  }
};

export const sendEventclickBizSubmitButton = () => {
  if (typeof gtag == 'function') {
    gtag('event', 'conversion', { send_to: 'AW-881715956/5-lQCOWOkbMZEPTVt6QD' });
  }
};

export const sendEventclickBizFinishButton = () => {
  if (typeof gtag == 'function') {
    gtag('event', 'conversion', { send_to: 'AW-881715956/vunMCMDbkbMZEPTVt6QD' });
  }
};

export const sendEventFirstClickImageButton = ({ button_name }) => {
  if (typeof gtag == 'function') {
    gtag('event', 'first_click_button', {
      button_name,
    });
  }
};

export const sendEventProductOrderSpec = ({ title }) => {
  if (typeof gtag == 'function') {
    gtag('event', 'product_order_spec', {
      title,
    });
  }
};

export const sendEventProductOrderExcel = () => {
  if (typeof gtag == 'function') {
    gtag('event', 'product_order_excel');
  }
};

export const sendEventHome = ({ position }) => {
  if (typeof gtag == 'function') {
    gtag('event', 'home', {
      position,
    });
  }
};
