import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import { OMPDosuF } from './module/OMPDosuF.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { OMPDosuConstantS } from '../../S/Constant/module/OMPDosuConstantS.js';
import { find, go, maxBy } from 'fxjs/es';
import { getProductFaces2InMaker } from '../../../../Maker/F/getSth.js';
import { OMPDosuS } from '../../S/Function/module/OMPDosuS.js';

export const validation = {
  get: {
    forProduct: () => {
      const pf2 = getProductFaces2InMaker();
      const bp_option_groups_dosu_counts = BpOptionF.biz.get.currentBp.bpOptionGroupsForDosuCounts();

      return go(
        pf2,
        find((pf) => {
          const bpf_id = pf.bpf_id;
          const used_colors = OMPDosuS.cvDesign.get.usedDosuColorFromPf(pf);

          if (used_colors.length === 0) return;

          const current_face_dosu_counts_option_group = find(
            (g) => g.base_product_face_id === bpf_id,
            bp_option_groups_dosu_counts,
          );

          if (current_face_dosu_counts_option_group == null) return;

          const max_dosu_count_at_pf = go(
            current_face_dosu_counts_option_group._.bp_options,
            maxBy((o) => Number(o.dev_name)),
            (o) => Number(o.dev_name),
          );

          return max_dosu_count_at_pf < used_colors.length;
        }),
      );
    },
    forCurrentFace: {
      // 현재 면에 사용된 총 도수 색상의 수가 허용 도수량을 넘었는지 평가
      dosuCountAllowed: () => {
        const res = {
          allowed: 0,
          used: 0,
          is_OK: true,
        };
        const max_dosu_count = BpOptionF.biz.get.currentFace.maxDosuCount();

        // 도수 수량 제한이 없는 경우 -> 항상 OK
        if (max_dosu_count == null) return res;
        res.allowed = max_dosu_count;

        const used_dosu_colors = OMPDosuF.cvObj.get.usedDosuColorFromCurrentFace();

        if (UtilArrayS.isEmNil(used_dosu_colors)) return res;

        const used_dosu_color_counts = used_dosu_colors.length;

        res.used = used_dosu_color_counts;
        res.is_OK = used_dosu_color_counts <= max_dosu_count;

        return res;
      },
    },
    forImage: {
      dosuColorAllowed: (cv_image) => {
        cv_image = cv_image ?? G.mp.maker.active();

        // addCvImage 할 때 사전에 평가해서 _data 에 caching
        if (
          cv_image._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION]?.[
            OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION_COLORS
          ] != null
        ) {
          return cv_image._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION]?.[
            OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION_COLORS
          ];
        }

        if (BpOptionF.biz.get.currentFace.hasDosuColorOptions()) {
          // 도수 컬러 옵션이 있을 떄, 옵션에 제공된 색상으로만 디자인 original_color 가 구성되어야 함.
          const design_original_colors = OMPDosuF.cvObj.get.originalColors(cv_image);

          if (UtilArrayS.isArrayOk(design_original_colors)) {
            const { color_codes: allowed_hex_color_codes } =
              BpOptionF.biz.get.currentFace.dosuColorOptionHexCodes();

            return design_original_colors.every((design_color) =>
              allowed_hex_color_codes.includes(design_color),
            );
          }
        } else {
          // 자유 색상 옵션인 면은 항상 validation 통과
          return true;
        }
      },
      dosuCountAllowed: (cv_image) => {
        cv_image = cv_image ?? G.mp.maker.active();
        // addCvImage 할 때 사전에 평가해서 _data 에 caching
        if (
          cv_image._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION]?.[
            OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION_COUNTS
          ] != null
        ) {
          return cv_image._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION]?.[
            OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION_COUNTS
          ];
        }
        if (BpOptionF.biz.get.currentFace.hasDosuCountOptions()) {
          // 도수 수량 옵션이 있을 때, 디자인에 사용된 색상 수가 도수 수량 옵션의 최대값보다 작거나 같아야 함.
          const design_original_colors = OMPDosuF.cvObj.get.originalColors(cv_image);

          if (UtilArrayS.isArrayOk(design_original_colors)) {
            const max_dosu_count = BpOptionF.biz.get.currentFace.maxDosuCount();
            return design_original_colors.length <= max_dosu_count;
          } else {
            return true;
          }
        } else {
          // 자유 도수 옵션인 면은 항상 validation 통과
          return true;
        }
      },
    },
  },
  insert: {
    dosuColors: (cv_image) => {
      const is_valid_dosu_colors = validation.get.forImage.dosuColorAllowed(cv_image);

      const is_exist_validation_result = cv_image._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION];
      if (is_exist_validation_result == null) {
        cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION] = {
          [OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION_COLORS]: is_valid_dosu_colors,
        };
      } else {
        cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION][
          OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION_COLORS
        ] = is_valid_dosu_colors;
      }
      return is_valid_dosu_colors;
    },
    dosuCounts: (cv_image) => {
      const is_valid_dosu_counts = validation.get.forImage.dosuCountAllowed(cv_image);
      const is_exist_validation_result = cv_image._data?.[OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION];
      if (is_exist_validation_result == null) {
        cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION] = {
          [OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION_COUNTS]: is_valid_dosu_counts,
        };
      } else {
        cv_image._data[OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION][
          OMPDosuConstantS._DATA_NAMES.DOSU_VALIDATION_COUNTS
        ] = is_valid_dosu_counts;
      }
      return is_valid_dosu_counts;
    },
  },
  update: {
    // 면에 문제 발생 시 대응 - 추후 기획
    faceValidationText: () => {
      // 이미지의 단위의 validation 은 이미 처리된 상태이므로 면 단위의 validation 만 처리
      // 이미지 단위의 도수 색상 관련된 위반은 일어날 수 없도록 처리되어 있으므로
      // 도수 수량 관련된 validation 체크 로직만 존재
      // if (BpOptionF.biz.get.currentFace.hasDosuCountOptions()) {
      //   const { allowed, used, is_OK } = validation.get.forCurrentFace.dosuCountAllowed();
      // let warning = '';
      // if (!is_OK) {
      // const current_pf = BpOptionF.makerHelper.get.currentPf();
      // showToastMsg({text: })
      // warning = html` <p>현재 ${current_pf['face_name' + G._en]}에서 많은 색상 수를 사용하였습니다.</p>
      //   <p>(사용:${used} 개 / 제한:${allowed} 개)</p>`;
      // }
      // }
    },
  },
};
