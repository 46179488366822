import axios from 'axios';
import { $delegate, $find, $qs, $val } from 'fxdom/es';

import { go, throttle } from 'fxjs/es';
import {
  DF_SMS_MP_SHOP_PRODUCT_GROUP_FRAME,
  DF_SMS_MP_SHOP_PRODUCT_GROUP_PAGE,
  DF_SMS_MP_SHOP_PRODUCT_GROUP_TAB,
} from '../S/constant.js';

export default function () {
  if (!$qs('.df_sms_creator_product_group')) return;

  $.frame({
    frame_name: DF_SMS_MP_SHOP_PRODUCT_GROUP_FRAME,
  });

  $.frame.defn_frame({
    frame_name: DF_SMS_MP_SHOP_PRODUCT_GROUP_FRAME,
    page_name: DF_SMS_MP_SHOP_PRODUCT_GROUP_PAGE,
  });

  $.frame.defn_page({
    page_name: DF_SMS_MP_SHOP_PRODUCT_GROUP_PAGE,
    tabs: [
      {
        tab_name: DF_SMS_MP_SHOP_PRODUCT_GROUP_TAB,
        appended: (tab) => {
          go(
            tab,
            $delegate(
              'click',
              '.btn_send_sms',
              throttle(async (e) => {
                $.don_loader_start();

                const projection_id = $val($find('input[name="projection_id"]', e.delegateTarget));
                const body = $val($find('textarea[name="body"]', e.delegateTarget));

                if (!projection_id) {
                  $.don_loader_end();
                  return $.alert('주문번호를 입력해 주세요.');
                }
                if (!body) {
                  $.don_loader_end();
                  return $.alert('문자 내용을 입력해 주세요.');
                }

                try {
                  await go(
                    axios.post('/@api/df/send_sms_product_group', {
                      projection_id,
                      body,
                    }),
                    (result) => {
                      $.don_loader_end();
                      if (!result.data.result) return $.alert(result.data.msg || '전송 실패');
                      $.alert('전송되었습니다.');
                    },
                  );
                } catch (_err) {
                  $.don_loader_end();
                  $.alert('전송 실패');
                }
              }, 1000),
            ),
          );

          return tab;
        },
      },
    ],
  });
}
