import {
  convertISODateTimeToLabel1,
  convertISODateToLabel1,
  truncText,
  truncTextAdv,
} from '../Function/labeling.js';

export const printer_dpi = 300;
export const home_pos = { x: 4, y: 5 };
export const height_info = {
  // mm
  margin_top: home_pos.y,
  margin_bottom: 5,
  header: 30,
  row: 3.56,
};

const max_lines = 100;
const font_sizes = {
  time_lines: { h: 4.2 },
  projection_id: { h: 12 },
  press_type: { h: 5 },
  order_category: { h: 4 },
  qty_label: { h: 7 },
  tree_item: { h: 2.5 },
};
const design = {
  time_line_titles: {
    font: font_sizes.time_lines,
    max_lines: 3,
    line_gap: 1.1,
    fb_width: 15,
  },
  data_time_lines: {
    font: font_sizes.time_lines,
    max_lines: 1,
    line_gap: 0,
    fb_width: 30,
    origin: { x: 11 },
  },
  projection_id: {
    font: font_sizes.projection_id,
    max_lines: 1,
    fb_width: 43,
  },
  press_type: {
    font: font_sizes.press_type,
  },
  order_category: {
    font: font_sizes.order_category,
  },
  qty_label: {
    font: font_sizes.qty_label,
  },
  tree_item: {
    font: font_sizes.tree_item,
    line_gap: 1,
  },
};

export const stock_label_field_data_info = [
  {
    title: 'date_names',
    type: 'static',
    static_data: ['출고', '발주', '주문'],
    tree_name: null,
    field_box: {
      width: design.time_line_titles.fb_width,
      max_lines: design.time_line_titles.max_lines,
      line_gap: design.time_line_titles.line_gap,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 0, y: 0 },
    font: {
      name: 'J',
      orient: 'N',
      height: design.time_line_titles.font.h,
      width: null,
    },
  },
  {
    title: 'due_at',
    type: 'variable',
    static_data: null,
    mutator: convertISODateToLabel1,
    tree_name: null,
    field_box: {
      width: design.data_time_lines.fb_width - 10,
      max_lines: design.data_time_lines.max_lines,
      line_gap: design.data_time_lines.line_gap,
      text_just: 'L',
      indent: 0,
    },
    position: {
      x: design.data_time_lines.origin.x,
      y: 0,
    },
    font: {
      name: '0',
      orient: 'N',
      height: design.data_time_lines.font.h,
      width: null,
    },
  },
  {
    title: 'shipping_type',
    type: 'variable',
    field_box: {
      width: 10,
      max_lines: design.data_time_lines.max_lines,
      line_gap: design.data_time_lines.line_gap,
      text_just: 'C',
      indent: 0,
    },
    position: {
      x: 29,
      y: 0,
    },
    font: {
      name: 'J',
      orient: 'N',
      height: design.data_time_lines.font.h,
      width: null,
    },
  },
  {
    title: 'stock_confirmed_at',
    type: 'variable',
    static_data: null,
    mutator: convertISODateTimeToLabel1,
    tree_name: null,
    field_box: {
      width: design.data_time_lines.fb_width,
      max_lines: design.data_time_lines.max_lines,
      line_gap: design.data_time_lines.line_gap,
      text_just: 'L',
      indent: 0,
    },
    position: {
      x: design.data_time_lines.origin.x,
      y: design.time_line_titles.font.h + design.time_line_titles.line_gap,
    },
    font: {
      name: '0',
      orient: 'N',
      height: design.data_time_lines.font.h,
      width: null,
    },
  },
  {
    title: 'ordered_at',
    type: 'variable',
    static_data: null,
    mutator: convertISODateTimeToLabel1,
    tree_name: null,
    field_box: {
      width: design.data_time_lines.fb_width,
      max_lines: design.data_time_lines.max_lines,
      line_gap: design.data_time_lines.line_gap,
      text_just: 'L',
      indent: 0,
    },
    position: {
      x: design.data_time_lines.origin.x,
      y: 2 * (design.time_line_titles.font.h + design.time_line_titles.line_gap),
    },
    font: {
      name: '0',
      orient: 'N',
      height: design.data_time_lines.font.h,
      width: null,
    },
  },
  {
    title: 'projection_id',
    type: 'variable',
    static_data: null,
    mutator: null,
    tree_name: null,
    field_box: {
      width: design.projection_id.fb_width,
      max_lines: design.projection_id.max_lines,
      line_gap: 0,
      text_just: 'R',
      indent: 0,
    },
    position: { x: 41, y: 2 },
    font: {
      name: '0',
      orient: 'N',
      height: design.projection_id.font.h,
      width: null,
    },
  },
  {
    title: 'scan_info',
    type: 'variable',
    static_data: null,
    mutator: (v) => '#' + v.join(),
    tree_name: null,
    aztec_code: { magnification: 10 },
    position: { x: 86.5, y: 0 },
  },
  {
    title: 'press_type',
    type: 'variable',
    static_data: null,
    mutator: (text) => truncTextAdv(text, 5, 82),
    tree_name: null,
    field_box: {
      width: 86,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 0, y: 17 },
    font: {
      name: 'J',
      orient: 'N',
      height: design.press_type.font.h,
      width: null,
    },
  },
  {
    title: 'order_category',
    type: 'variable',
    static_data: null,
    mutator: (text) => `from ${text.toUpperCase()}`,
    tree_name: null,
    field_box: {
      width: 80,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 0, y: 23 },
    font: {
      name: 'J',
      orient: 'N',
      height: design.order_category.font.h,
      width: null,
    },
  },
  {
    title: 'qty_label',
    type: 'variable',
    static_data: null,
    mutator: (v) => truncText(v, 4),
    tree_name: null,
    field_box: {
      width: 15,
      max_lines: 1,
      line_gap: 0,
      text_just: 'C',
      indent: 0,
    },
    position: { x: 85, y: 17 },
    font: {
      name: '0',
      orient: 'N',
      height: design.qty_label.font.h,
      width: null,
    },
  },
  {
    title: 'supplier',
    type: 'variable',
    static_data: null,
    mutator: (text) => truncTextAdv(text, 2, 6),
    tree_name: 'bp_c_ss',
    field_box: {
      width: 10,
      max_lines,
      line_gap: design.tree_item.line_gap,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 4, y: 30 },
    font: {
      name: 'J',
      orient: 'N',
      height: design.tree_item.font.h,
      width: null,
    },
  },
  {
    title: 'bp_name',
    type: 'variable',
    static_data: null,
    mutator: (text) => '|' + truncTextAdv(text, Number(design.tree_item.font.h), 48),
    tree_name: 'bp_c_ss',
    field_box: {
      width: 54,
      max_lines,
      line_gap: design.tree_item.line_gap,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 13, y: 30 },
    font: {
      name: 'J',
      orient: 'N',
      height: design.tree_item.font.h,
      width: null,
    },
  },
  {
    title: 'color',
    type: 'variable',
    static_data: null,
    mutator: (text) =>
      '|' +
      truncTextAdv(
        text.replace('라이트', 'L').replace('세이프티', 'SF'),
        Number(design.tree_item.font.h),
        14,
      ),
    tree_name: 'bp_c_ss',
    field_box: {
      width: 18,
      max_lines,
      line_gap: design.tree_item.line_gap,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 63, y: 30 },
    font: {
      name: 'J',
      orient: 'N',
      height: design.tree_item.font.h,
      width: null,
    },
  },
  {
    title: 'size',
    type: 'variable',
    static_data: null,
    mutator: (text) => '|' + truncTextAdv(text.replace('세', ''), Number(design.tree_item.font.h), 14),
    tree_name: 'bp_c_ss',
    field_box: {
      width: 18,
      max_lines,
      line_gap: design.tree_item.line_gap,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 78, y: 30 },
    font: {
      name: 'J',
      orient: 'N',
      height: design.tree_item.font.h,
      width: null,
    },
  },
  {
    title: 'qty_bp',
    type: 'variable',
    static_data: null,
    mutator: (v) => '|' + truncText(v, 4),
    tree_name: 'bp_c_ss',
    field_box: {
      width: 8,
      max_lines,
      line_gap: design.tree_item.line_gap,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 94, y: 30 },
    font: {
      name: 'J',
      orient: 'N',
      height: design.tree_item.font.h,
      width: null,
    },
  },
];
