import { go, html, strMap } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';

export const cartAddColorSizesTmplPc = ({ filtered_bpss, user_product, bpc }) => {
  return html`<div class="sizes_body" data-fx-json="${$dataStr({ bpss: filtered_bpss, user_product })}">
    <div class="title">${TT('cart::cart_30')}</div>
    <div class="main">
      <div class="color">
        <div
          class="color_chip"
          style="background-color: ${bpc.color_code}"
          color_code="${bpc.color_code}"
          need_ccc="${bpc.need_ccc}"
        ></div>
        ${bpc['name' + G._en]}
      </div>
      <div class="divider">
        <hr />
      </div>
      <div class="sizes_container">
        ${go(
          filtered_bpss,
          strMap((bps) => {
            // const bps_id = up_c_s.base_product_size_id;
            const is_overflowed = false;
            // const is_overflowed = overflow_sizes
            //   ? overflow_sizes.includes(bps_id)
            //   : up_c_s._.user_product_editor_attr.is_overflowed;
            const { id, is_not_stock } = bps;
            return html`
              <div
                class="quantity-editor"
                data-bps_id="${id}"
                data-fx-json="${$dataStr(bps)}"
                data-is_not_stock="${is_not_stock}"
                data-is_overflowed="${is_overflowed}"
              >
                <div class="quantity-editor__quantity-wrapper">
                  <div class="quantity-editor__quantity-number">
                    <div class="quantity-editor__option-name ${is_not_stock ? 'is_not_stock' : ''}">
                      ${bps?.['name' + G._en]}${is_not_stock ? `<span>${TT('cart::cart_23')}</span>` : ''}
                    </div>
                    <div class="quantity-editor__input-wrapper">
                      <div class="omp-atom__input-number-plus-minus-button">
                        <button type="button" data-operator="-"></button>
                        <input
                          type="number"
                          class="quantity"
                          value="0"
                          min="0"
                          max="5000"
                          inputmode="numeric"
                          pattern="[0-9]*"
                        />
                        <button type="button" data-operator="+"></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            `;
          }),
        )}
      </div>
    </div>
    <div class="sizes_buttons">
      <button type="button" class="btn_prev">${TT('cart::cart_31')}</button>
      <button type="button" class="btn_add disabled">${TT('cart::cart_32')}</button>
    </div>
  </div>`;
};
