!(function () {
  $.on($1('body'), 'click', '.copy-table-btn', function (e) {
    const $button = e.$currentTarget;
    const targetID = $.attr($button, 'data-target');

    const sibls = $.siblings($button, `#${targetID}`);

    const $table = sibls.length > 0 ? sibls[0] : $button.nextElementSibling;

    const range = document.createRange();
    range.selectNode($table);

    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? '테이블이 클립보드에 복사되었습니다.' : '복사에 실패했습니다.';
      console.log(msg);
      // alert(msg);
    } catch (err) {
      console.error('복사 중 오류 발생:', err);
      // alert('복사 중 오류가 발생했습니다.');
    }

    window.getSelection().removeAllRanges();
  });

  $.on($1('body'), 'click', '#exportAllTables', function (e) {
    const bpDivs = document.querySelectorAll('.bp');
    let csvContent = '';

    bpDivs.forEach((bpDiv, index) => {
      if (index > 0) csvContent += '\n\n';

      const $bpNameEl = bpDiv.querySelector('.bp_name');
      const bpName = $bpNameEl.childNodes[0].textContent.trim();
      const bpTotalCount = $bpNameEl.querySelector('.total_count').textContent.trim();
      csvContent += `"${bpName}","${bpTotalCount}"\n`;

      const table = bpDiv.querySelector('.bp_table');
      const rows = table.querySelectorAll('tr');
      rows.forEach((row) => {
        const cells = row.querySelectorAll('th, td');
        const rowData = Array.from(cells).map((cell) => {
          let text = cell.textContent.trim();
          return text.includes(',') ? `"${text}"` : text;
        });
        csvContent += rowData.join(',') + '\n';
      });
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', '상품_상세_판매량.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
})({});
