import { go, groupBy, indexBy, mapObject } from 'fxjs/es';
import moment from 'moment';

export function makeInspectionDetailHtml(data) {
  const { lgl_item_inbound_inspection, lgl_inbound_inspection_reasons, inspected_at } = data;
  const { work_type, work_reason, work_url, reject_url, reject_reason, memo } =
    lgl_item_inbound_inspection || {};

  const reasons = go(
    lgl_inbound_inspection_reasons,
    groupBy(({ reason_type }) => reason_type),
    mapObject((v) =>
      go(
        v,
        indexBy(({ code }) => code),
      ),
    ),
  );

  const { name: work_name = '', description: work_description = '' } = reasons?.WORK[work_reason] || {};
  const { name: reject_name = '', description: reject_description = '' } =
    reasons?.REJECT[reject_reason] || {};

  return `<div class="header">
            <h1>검수 상세 정보</h1>
          </div>
          <div class="body">
            <dl>
              <dt>검수 일시</dt>
              <dd>${moment(inspected_at).format('YYYY.MM.DD HH:mm')}</dd>
              <dt>검수 유형</dt>
              <dd>${work_type === 'G' ? '일반' : '상세'} 검수</dd>
              ${
                work_url
                  ? `<dt>검수 작업</dt>
              <dd><img loading="lazy" alt="검수 작업 이미지" src="${work_url}"/></dd>`
                  : ''
              }
              <dt>검수 사유</dt>
              <dd>${work_name + `${work_description ? ` (${work_description})` : ''}`}</dd>
              ${
                reject_url
                  ? `<dt>검수 보류</dt>
                     <dd>
                        <img loading="lazy" alt="보류 사유 이미지" src="${reject_url}"/>
                     </dd>`
                  : ''
              }
              ${
                reject_reason
                  ? `<dt>보류 사유</dt>
                     <dd>${reject_name + `${reject_description ? ` (${reject_description})` : ''}`}</dd>`
                  : ``
              }
              <dt>memo</dt>
              <dd>${memo || ''}</dd>
            </dl>
        </div>`;
}
