import { find, go, map, range, sel } from 'fxjs/es';
import { getBaseProductSizesInMaker, setProductFaces2Value2 } from '../../../../Maker/F/getSth.js';
import { makeFcanvasBasic, unsetFcanvass } from '../../../../Maker/F/mp_maker.js';

export const init = async ({ base_product_id, product_color_id }) => {
  if (!(base_product_id || product_color_id)) throw new Error('base_product_id 또는 product_color_id 없음');
  const product_color = await $.get('/@api/product_color', {
    /*DF*/
    id: await go(undefined, async () => {
      if (product_color_id) return product_color_id;
      const { product_color_id: product_color_id_of_bp } = await $.get('/@api/only_base_product_with_pc_id', {
        base_product_id,
      });
      return product_color_id_of_bp;
    }),
  });
  box.set('maker', {});
  box.set('maker->product_color', product_color);
  if (base_product_id)
    box.sel('maker->product_color').base_product_size_id = go(
      getBaseProductSizesInMaker(),
      (bps) => find((bp) => bp.is_thumb, bps) || bps[0],
      sel('id'),
    );
  await go(
    range(6),
    map(() => document.createElement('canvas')),
    makeFcanvasBasic,
    function () {
      setProductFaces2Value2();
    },
  );
};

export const destroy = () => {
  unsetFcanvass();
  box.unset('maker');
};
