export const PDF_ERR_CODE = {
  // 파일 오류 관련
  101: '101',
  102: '102',
  103: '103',
  104: '104',
  105: '105',
  106: '106',
  107: '107',
  199: '199',
  // 메타데이터 추출 관련
  201: '201',
  202: '202',
  203: '203',
  204: '204',
  205: '205',
  206: '206',
  207: '207',
  299: '299',
  // 썸네일 추출 관련
  301: '301',
  302: '302',
  399: '399',
  // S3 업로드 관련
  499: '499',
  // DB 관련
  599: '599',
};

/**
 * @define PDF API 의 에러 코드 및 해당 이유를 관리하는 객체
 * @type {Object.<string, string>}
 * @constant
 * @readonly
 * @description 99 로 끝나는 코드는 해결해야 할 bug
 * */
export const PDF_ERR_CODE_REASON = {
  // 파일 오류 관련
  [PDF_ERR_CODE['101']]: '빈 파일임',
  [PDF_ERR_CODE['102']]: '파일명 문제',
  [PDF_ERR_CODE['103']]: '파일 형식(mimetype) 문제',
  [PDF_ERR_CODE['104']]: '파일 데이터(buffer) 문제',
  [PDF_ERR_CODE['105']]: '파일크기 문제',
  [PDF_ERR_CODE['199']]: '알수 없는 파일 핸들링 문제. 관리자 문의',

  // 메타데이터 추출 관련
  [PDF_ERR_CODE['201']]: 'PDF 문서 버전 없음',
  [PDF_ERR_CODE['202']]: 'PDF 문서 버전 허용 안됨',
  [PDF_ERR_CODE['203']]: 'PDF 문서 저자 없음',
  [PDF_ERR_CODE['204']]: 'PDF 문서 저자 허용 안됨',
  [PDF_ERR_CODE['205']]: 'PDF 페이지 없음',
  [PDF_ERR_CODE['206']]: 'PDF 페이지 제한', // 너무 많은 페이지를 올린 경우
  [PDF_ERR_CODE['207']]: '파일 데이터(buffer) 미존재',
  [PDF_ERR_CODE['208']]: 'PDF 메타데이터 분석 오류.',
  [PDF_ERR_CODE['299']]: '알수 없는 PDF 메타데이터 분석 오류. 관리자 문의',

  // 썸네일 추출 관련
  [PDF_ERR_CODE['301']]: '파일 데이터(buffer) 미존재',
  [PDF_ERR_CODE['302']]: '유저아이디 미존재',
  [PDF_ERR_CODE['399']]: '알수 없는 PDF 썸네일 추출 오류. 관리자 문의',

  // S3 업로드 관련
  [PDF_ERR_CODE['499']]: '알수 없는 리소스 업로드 오류. 관리자 문의',

  // DB 관련
  [PDF_ERR_CODE['599']]: '알수 없는 데이터베이서 처리 오류. 관리자 문의',
};
