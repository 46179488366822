export default function (lo) {
  const el_editor = $('#font_manager');

  function to_data1(e) {
    const tr = $.closest(e.currentTarget, 'tr');
    const font = box.sel(tr);
    return {
      id: font.id,
      type: $.val($.find1(tr, '[name="type"]:checked')),
      name: $.val($.find1(tr, '[name="name"]')) || $.val($.find1(tr, '[name="original_name"]')),
      name_en: $.val($.find1(tr, '[name="name_en"]')) || $.val($.find1(tr, '[name="original_name"]')),
      original_name: $.val($.find1(tr, '[name="original_name"]')),
      is_public: $.find1(tr, '[name="is_public"]').checked,
      is_public_en: $.find1(tr, '[name="is_public_en"]').checked,
      is_public_jp: $.find1(tr, '[name="is_public_jp"]').checked,
      has_bold: $.find1(tr, '[name="has_bold"]').checked,
    };
  }

  async function edit(e) {
    $.don_loader_start();
    await $.put('/@api/font/edit', to_data1(e));
    location.reload();
  }

  function createCvTextOriginal({ text, fontFamily, fontSize }) {
    return new fabric.IText(text, { fontFamily, fontSize });
  }

  _p.go(
    el_editor,
    $.on('click', '.create', async function (e) {
      $.don_loader_start();
      await $.post('/@api/font/create', {});
      location.reload();
    }),
    $.on('click', '.generate_url', async function (e) {
      const font = to_data1(e);
      await new FontFaceObserver(font.original_name).load(undefined, 30000);
      const cv_text = await createCvTextOriginal({
        text: font.name,
        fontSize: 60,
        fontFamily: font.original_name,
      });
      cv_text.scaleToHeight(60);
      cv_text.set('fill', '#000000');
      const url_2x = (await $.uploadFileToUrl(cv_text.toDataURL2(), font.original_name)).url;
      cv_text.set('fill', '#0079FA');
      const url_2x_a = (await $.uploadFileToUrl(cv_text.toDataURL2(), font.original_name)).url;
      await $.put('/@api/font/edit', {
        ...font,
        id: font.id,
        url_2x,
        url_2x_a,
      });
      location.reload();
    }),
    $.on('input', 'input[name="type"]', edit),
    $.on('change', 'input[name="name_en"]', edit),
    $.on('change', 'input[name="original_name"]', edit),
    $.on('input', 'input[name="has_bold"]', edit),
    $.on('input', 'input[name="is_public"]', edit),
    $.on('input', 'input[name="is_public_en"]', edit),
    $.on('input', 'input[name="is_public_jp"]', edit),
    $.on('click', '.remove', async function (e) {
      $.don_loader_start();
      await $.del('/@api/font/remove', { id: box.sel(e.currentTarget).id });
      location.reload();
    }),
  );
  $.don_loader_end();
}
