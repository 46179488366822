import { chunk, go, html, map } from 'fxjs/es';
import { borderTabContents } from '../../../text/S/Tmpl/text.js';
import { SVGEditorAttrS } from '../../../../Attr/S/Function/module/SVGEditorAttrS.js';
import { SVGEditorColorTmplS } from '../../../../Color/S/Tmpl/module/SVGEditorColorTmplS.js';
import { VectorEditorColorConstS } from '../../../../../VectorEditor/Color/S/Const/module/VectorEditorColorConstS.js';

const colorTabContents = ({ svg_el, is_path }) => html`
  <div class="color_tab_contents rc_contents">
    <div class="content">
      <div class="title">${T('maker_color_editor::색상')}</div>
      <div class="colors_wrapper wrapper" data-key="fill">
        ${SVGEditorColorTmplS.colorsWrapper({
          colors: chunk(
            10,
            go(VectorEditorColorConstS.COLORS32, (arr) => {
              const _length = 10 - (arr.length % 10);
              const plus_arr = go(
                Array(_length),
                map((v) => {
                  return {};
                }),
              );
              return arr.concat(plus_arr);
            }),
          ),
          color_code: SVGEditorAttrS.get('fill', svg_el),
        })}
      </div>
    </div>
    ${is_path
      ? html`
          <div class="content">
            <div class="title">${T('maker_path_editor::패스')}</div>
            <div class="wrapper go_to_path_editor_wrapper">
              <button type="button" class="go_to_path_editor">${T('maker_path_editor::패스 편집')}</button>
            </div>
          </div>
        `
      : html``}
  </div>
`;
export const shape = ({ svg_el, is_path }) => html`
  <div class="rc_wrapper sticker_etc_share_style" data-current_tab="color_tab">
    <div class="scroll_wrapper">
      <div class="rc_header">
        <div class="back"></div>
        <div class="title">${T('maker_path_editor::도형 편집')}</div>
      </div>
      <div class="rc_tabs">
        <div class="tab is_cursor_pointer" data-tab_name="color_tab">${T('maker_color_editor::색상')}</div>
        <div class="tab is_cursor_pointer" data-tab_name="border_tab">
          ${T('maker_border_editor::테두리')}
        </div>
      </div>
      ${colorTabContents({ svg_el, is_path })} ${borderTabContents({ svg_el })}
    </div>
  </div>
`;
