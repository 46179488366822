import { find, go } from 'fxjs/es';
import { GlobalPortOneConstantS } from '../../../GlobalPortOne/S/Constant/module/GlobalPortOneConstantS.js';

export function makePayMethodUsingProjectionPayment(pp) {
  const PAY_METHOD_LABEL = {
    free: T('plus_admin::pa369'),
    trans: T('plus_admin::pa370'),
    trans_ini: T('plus_admin::pa371'),
    card: T('plus_admin::pa372'),
    samsung: T('plus_admin::pa373'),
    payco: T('plus_admin::pa374'),
    naverpay: T('plus_admin::pa375'),
    ssgpay: T('plus_admin::pa376'),
    lpay: T('plus_admin::pa377'),
    alipay: T('plus_admin::pa378'),
    card_ini: T('plus_admin::pa379'),
    without_bank: T('plus_admin::pa380'),
    offline_card: T('plus_admin::pa381'),
    offline_cash: T('plus_admin::pa382'),
    vbank: T('plus_admin::pa383'),
    kakaopay: T('plus_admin::pa384'),
    tosspay: T('plus_admin::pa385'),
    kakao_shopping: T('plus_admin::pa385_1'),
    marpple: T('Point'),
  };
  const global_port_one_pay_method = go(
    GlobalPortOneConstantS.ALL_PAYMENT_METHODS,
    find((payment) => payment.method_key === pp.pay_method),
  );
  if (global_port_one_pay_method) return global_port_one_pay_method.name;
  if (pp.type === 'paypal' || pp.pay_method === 'paypal') return 'PayPal';
  if (pp.pay_method === 'card') return PAY_METHOD_LABEL.card;
  if (pp.pay_method === 'samsung') return PAY_METHOD_LABEL.samsung;
  if (pp.type === 'payco' || pp.pay_method === 'payco') return PAY_METHOD_LABEL.payco;
  if (pp.type === 'ssgpay' || pp.pay_method === 'ssgpay') return PAY_METHOD_LABEL.ssgpay;
  if (pp.pay_method === 'lpay') return PAY_METHOD_LABEL.lpay;
  if (pp.pay_method === 'alipay') return PAY_METHOD_LABEL.alipay;
  if (pp.type === 'naverpay' || pp.pay_method === 'naverpay') return PAY_METHOD_LABEL.naverpay;
  if (pp.pay_method === 'vbank') return PAY_METHOD_LABEL.vbank;
  if (pp.type === 'kakaopay' || pp.pay_method === 'kakaopay') return PAY_METHOD_LABEL.kakaopay;
  if (pp.type === 'tosspay' || pp.pay_method === 'tosspay') return PAY_METHOD_LABEL.tosspay;
  if (pp.pay_method === 'trans') return PAY_METHOD_LABEL.trans;
  if (pp.pay_method === 'without_bank') return PAY_METHOD_LABEL.without_bank;
  if (pp.pay_method === 'offline_card') return PAY_METHOD_LABEL.offline_card;
  if (pp.pay_method === 'offline_cash') return PAY_METHOD_LABEL.offline_cash;
  if (pp.pay_method === 'free') return PAY_METHOD_LABEL.free;
  if (pp.pay_method === 'kakao_shopping') return PAY_METHOD_LABEL.kakao_shopping;
  return '-';
}
