import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { each, filterL, go, takeL } from 'fxjs/es';
import { CommonNS } from '@marpple/sticker-editor';
import { setSizeDisplayValue } from './size.js';
import { $hide } from 'fxdom/es';

export const handleHeaderCancelButtonClick = () => () => MuiF.popPage().catch(console.error);

export const handleHeaderDoneButtonClick =
  ({ tab_el }) =>
  async () => {
    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    if (tab_el.__mp_postProcess == null) {
      return;
    }
    const postProcess = tab_el.__mp_postProcess;

    const result1 = acryl_stand.getGroundMidPoint();
    if (!result1.ok) {
      console.error(result1.value);
      // @TODO KHL i18n
      $.alert(`ERROR`);
      return;
    }
    const { x: stand_leg_x, y: stand_leg_y } = result1.value;

    const result2 = acryl_stand.getUnitedPathdata({});
    if (!result2.ok) {
      console.error(result2.value);
      // @TODO KHL i18n
      $.alert(`ERROR`);
      return;
    }
    const path_data = result2.value;
    const { success, value } = await postProcess({
      cutting_line_path_data: path_data,
      stand_leg_position: { x: stand_leg_x, y: stand_leg_y },
    });

    if (!success && value == null) {
      return;
    }
    if (!success) {
      await $.alert(value);
      return;
    }

    return MuiF.closeFrame(value);
  };

export const handleFooterMinusButtonClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    const new_width = tab_el.__mp_stand_leg_ground_width - tab_el.__mp_stand_leg_ground_size_delta;
    if (new_width < tab_el.__mp_stand_leg_ground_min_width) {
      $.alert(
        T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::min_stand_leg_width_error'),
      );
      return;
    }

    const result1 = acryl_stand.setGroundWidth({ new_width });
    if (!result1.ok) {
      console.error(result1.value);
      $.alert(
        T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::min_stand_leg_width_error'),
      );
      return;
    }
    const result2 = acryl_stand.getStandPathdata({});
    if (!result2.ok) {
      console.error(result2.value);
      $.alert(
        T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::min_stand_leg_width_error'),
      );
      return;
    }
    const path_data = result2.value;

    tab_el.__mp_stand_leg_ground_width = new_width;
    editor.setStandLegPath({ path_data });
    setSizeDisplayValue({ tab_el, size: new_width });
  };

export const handleFooterPlusButtonClick =
  ({ tab_el }) =>
  () => {
    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    const new_width = tab_el.__mp_stand_leg_ground_width + tab_el.__mp_stand_leg_ground_size_delta;
    if (new_width > tab_el.__mp_stand_leg_ground_max_width) {
      $.alert(
        T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::max_stand_leg_width_error'),
      );
      return;
    }

    const result1 = acryl_stand.setGroundWidth({ new_width });
    if (!result1.ok) {
      console.error(result1.value);
      $.alert(
        T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::max_stand_leg_width_error'),
      );
      return;
    }
    const result2 = acryl_stand.getStandPathdata({});
    if (!result2.ok) {
      console.error(result2.value);
      $.alert(
        T('modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::max_stand_leg_width_error'),
      );
      return;
    }
    const path_data = result2.value;

    tab_el.__mp_stand_leg_ground_width = new_width;
    editor.setStandLegPath({ path_data });
    setSizeDisplayValue({ tab_el, size: new_width });
  };

export const handleRightLeftButtonClick =
  ({ tab_el }) =>
  () => {
    tab_el.__mp_stand_leg_touch_id = null;

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    const result1 = acryl_stand.getGroundMidPoint();
    if (!result1.ok) {
      console.error(result1.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::cutting_line_calculation_error',
        ),
      );
      return;
    }
    const x = result1.value.x - tab_el.__mp_stand_leg_ground_position_delta;

    const result2 = acryl_stand.getStandPathdata({ x });
    if (!result2.ok) {
      console.error(result2.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::cutting_line_calculation_error',
        ),
      );
      return;
    }
    const path_data = result2.value;
    editor.setStandLegPath({ path_data });

    tab_el.querySelectorAll(`.foreground_container .reposition_guide_container`).forEach((el) => {
      $hide(el);
    });
  };

export const handleRightRightButtonClick =
  ({ tab_el }) =>
  () => {
    tab_el.__mp_stand_leg_touch_id = null;

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    const result1 = acryl_stand.getGroundMidPoint();
    if (!result1.ok) {
      console.error(result1.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::cutting_line_calculation_error',
        ),
      );
      return;
    }
    const x = result1.value.x + tab_el.__mp_stand_leg_ground_position_delta;

    const result2 = acryl_stand.getStandPathdata({ x });
    if (!result2.ok) {
      console.error(result2.value);
      $.alert(
        T(
          'modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::cutting_line_calculation_error',
        ),
      );
      return;
    }
    const path_data = result2.value;
    editor.setStandLegPath({ path_data });

    tab_el.querySelectorAll(`.foreground_container .reposition_guide_container`).forEach((el) => {
      $hide(el);
    });
  };

export const handleEditorContainerTouchStart =
  ({ tab_el }) =>
  (event) => {
    if (tab_el.__mp_stand_leg_touch_id != null) {
      return;
    }

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    event.preventDefault();

    go(
      event.changedTouches,
      CommonNS.UtilNS.convertTouchListToGen,
      filterL(({ target }) => target instanceof Node && editor.getIsInStandLegEl(target)),
      takeL(1),
      each(({ identifier, clientX: client_x, clientY: client_y }) => {
        const { x } = editor.convertCoordsToInside({ x: client_x, y: client_y });
        const result = acryl_stand.getStandPathdata({ x });
        if (!result.ok) {
          console.error(result.value);
          $.alert(
            T(
              'modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::cutting_line_calculation_error',
            ),
          );
          return;
        }
        const path_data = result.value;

        tab_el.__mp_stand_leg_touch_id = identifier;
        editor.setStandLegPath({ path_data });

        tab_el.querySelectorAll(`.foreground_container .reposition_guide_container`).forEach((el) => {
          $hide(el);
        });
      }),
    );
  };

export const handleTabElTouchMove =
  ({ tab_el }) =>
  (event) => {
    event.preventDefault();

    if (tab_el.__mp_stand_leg_touch_id == null) {
      return;
    }
    const touch_id = tab_el.__mp_stand_leg_touch_id;

    if (tab_el.__mp_acrylic_figure_stand_leg_editor == null) {
      return;
    }
    const editor = tab_el.__mp_acrylic_figure_stand_leg_editor;

    if (tab_el.__mp_acryl_stand == null) {
      return;
    }
    const acryl_stand = tab_el.__mp_acryl_stand;

    go(
      event.changedTouches,
      CommonNS.UtilNS.convertTouchListToGen,
      filterL(({ identifier }) => identifier === touch_id),
      takeL(1),
      each(({ clientX: client_x, clientY: client_y }) => {
        const { x } = editor.convertCoordsToInside({ x: client_x, y: client_y });
        const result = acryl_stand.getStandPathdata({ x });
        if (!result.ok) {
          console.error(result.value);
          $.alert(
            T(
              'modules::VectorEditor::AcrylicFigure::StandLeg::Mobile::error_message::cutting_line_calculation_error',
            ),
          );
          return;
        }
        const path_data = result.value;
        editor.setStandLegPath({ path_data });
      }),
    );
  };

export const handleTabElTouchEnd =
  ({ tab_el }) =>
  (event) =>
    handleTabElTouchEndOrTouchCancel({ tab_el, event });

export const handleTabElTouchCancel =
  ({ tab_el }) =>
  (event) =>
    handleTabElTouchEndOrTouchCancel({ tab_el, event });

function handleTabElTouchEndOrTouchCancel({ tab_el, event }) {
  if (tab_el.__mp_stand_leg_touch_id == null) {
    return;
  }
  const touch_id = tab_el.__mp_stand_leg_touch_id;

  event.preventDefault();

  go(
    event.changedTouches,
    CommonNS.UtilNS.convertTouchListToGen,
    filterL(({ identifier }) => identifier === touch_id),
    takeL(1),
    each(() => {
      tab_el.__mp_stand_leg_touch_id = null;
    }),
  );
}
