export const STATUS = {
  ready: 'ready', // 진행 전
  on: 'on', // 진행 중
  completed: 'completed', // 완료
};
export const TYPE = {
  before_print: 'before_print',
  printing: 'printing',
  before_deliver: 'before_deliver',
  as: 'as',
};

export const HARD_CODE_TASK = {
  HANJIN_NESS_TASK_SET_ID: 892,
  // HANJIN_NESS_TASK_SET_ID: 900,
};

export const PRODUCT_POSITION_STATUS = {
  NONE: 'NONE', // 없음(미입고)
  IMPORT: 'IMPORT', // 입고
  EXPORT: 'EXPORT', // 출고
  EXPORT_TO_DELIVERY: 'EXPORT_TO_DELIVERY', // 배송팀으로 출고
};

const step_in_icon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" fill="none" />
<circle cx="12" cy="12" r="10" fill="#FFFFFF" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11.7071 9.70711C12.0976 9.31658 12.0976 8.68342 11.7071 8.29289C11.3166 7.90237 10.6834 7.90237 10.2929 8.29289L7.37993 11.2059C7.36486 11.2209 7.35031 11.2363 7.33627 11.252C7.12998 11.4352 7 11.7024 7 12C7 12.2976 7.12998 12.5648 7.33627 12.748C7.3503 12.7637 7.36486 12.7791 7.37993 12.7941L10.2929 15.7071C10.6834 16.0976 11.3166 16.0976 11.7071 15.7071C12.0976 15.3166 12.0976 14.6834 11.7071 14.2929L10.4142 13L16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11L10.4142 11L11.7071 9.70711Z" fill="#323232"/>
</svg>`;

const step_out_icon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" fill="none"/>
<circle cx="12" cy="12" r="10" fill="#FFFFFF" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12.2929 14.2929C11.9024 14.6834 11.9024 15.3166 12.2929 15.7071C12.6834 16.0976 13.3166 16.0976 13.7071 15.7071L16.6201 12.7941C16.6351 12.7791 16.6497 12.7637 16.6637 12.748C16.87 12.5648 17 12.2976 17 12C17 11.7024 16.87 11.4352 16.6637 11.252C16.6497 11.2363 16.6351 11.2209 16.6201 11.2059L13.7071 8.29289C13.3166 7.90237 12.6834 7.90237 12.2929 8.29289C11.9024 8.68342 11.9024 9.31658 12.2929 9.70711L13.5858 11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H13.5858L12.2929 14.2929Z" fill="#323232"/>
</svg>`;

const step_out_delivery_icon = `<svg height="800" width="800"  id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
<circle fill="#0000FFFF" cx="256" cy="256" r="256"/>
<g>
  <polygon fill="#FFFFFF" points="204.472,425.088 276.48,352 204.472,278.912 204.472,310.28 106.048,310.28 106.048,393.72 204.472,393.72"/>
  <polygon fill="#FFFFFF" points="204.472,233.088 276.48,160 204.472,86.912 204.472,118.28 106.048,118.28 106.048,201.72 204.472,201.72"/>
  <polygon fill="#FFFFFF" points="381.944,329.088 453.952,256 381.944,182.912 381.944,214.28 283.52,214.28 283.52,297.72 381.944,297.72"/>
</g>
</svg>`;

export const PRODUCT_POSITION_STATUS_ICON = {
  NONE: null, // 없음
  IMPORT: step_in_icon, // 입고
  EXPORT: step_out_icon, // 출고
  EXPORT_TO_DELIVERY: step_out_delivery_icon, // 배송팀으로 출고
};

export const PAPER_TASK = {
  PRINTING_TASK_SET_ID: 942,
  PRINTING_PARENT_ID: 7580523,
  ERROR_TASK_SET_ID: 946,
  ERROR_PARENT_ID: 7587816,
};

export const MARPPLE_SHOP = {
  PARENT_PROJECTION_DELIVERY_TASK_PARENT_ID: 3661271,
};

export const TASK_SET_TITLE_FOR_VISIT_QUICK_TIME_CHECK = '퀵방문 일정확인';
