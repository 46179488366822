import { VectorEditorKeyringF } from '../../../../../VectorEditor/Keyring/F/Function/module/VectorEditorKeyringF.js';
import { $qs, $scrollTop } from 'fxdom/es';
import { keyringPostProcess } from './keyringPostProcess.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';

export const openKeyringEditor = async ({ post_process_props, app, editor_props }) => {
  const { tab_el, price, svg_file, title, options } = editor_props;
  const thumbnail_size_px = 800;
  if (!UtilF.isLegacyMobile()) {
    const { y: frame_position_top, height: frame_position_height } = tab_el
      ?.querySelector?.('.mp_maker')
      ?.getBoundingClientRect?.() ?? {
      y: 70,
      height: 926,
    };
    if (app === 'creator') {
      return VectorEditorKeyringF.makeKeyringCreatorPC({
        title,
        price,
        svg_file,
        thumbnail_size_px,
        options,
        postProcess: keyringPostProcess(post_process_props),
      });
    } else {
      return VectorEditorKeyringF.makeKeyringPC({
        title,
        price,
        frame_position: { top: frame_position_top, height: frame_position_height },
        prev_right_panel_el: $qs('#marpplizer'),
        onFrameHiding: () => {
          (tab_el?.tab_opt ?? {}).tab_top = $scrollTop(window);
        },
        svg_file,
        options,
        thumbnail_size_px,
        postProcess: keyringPostProcess(post_process_props),
      });
    }
  } else {
    return VectorEditorKeyringF.makeKeyringMobile({
      svg_file,
      options,
      thumbnail_size_px,
      postProcess: keyringPostProcess(post_process_props),
    });
  }
};
