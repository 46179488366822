import { filter, find, go, map, sel, sortBy } from 'fxjs/es';
import { UtilObjS } from '../../../Util/Object/S/Function/module/UtilObjS.js';

export const service = {
  getBpfOptionGroups: ({ bpf_id, base_product }) => {
    return go(
      base_product._.base_products_bp_option_groups,
      filter((base_products_bp_option_group) => base_products_bp_option_group.base_product_face_id == bpf_id),
      map((base_products_bp_option_group) => base_products_bp_option_group._.bp_option_group),
    );
  },
  getBpfOptionGroupByDevName: ({ bpf_id, base_product, dev_name }) => {
    return go(
      service.getBpfOptionGroups({ bpf_id, base_product }),
      find((bp_option_group) => bp_option_group.dev_name === dev_name),
    );
  },
  getBpOptions: (option_group) => {
    if (UtilObjS.isNotEmpty(option_group)) {
      return go(
        option_group._.bp_options.filter((o) => o.is_public),
        sortBy(sel('no')),
      );
    }
    return [];
  },
};
