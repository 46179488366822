import { SVGEditorAttrS } from '../../../Attr/S/Function/module/SVGEditorAttrS.js';
import { html } from 'fxjs/es';
import { SVGEditorStrokeDashF } from '../../F/Function/module/SVGEditorStrokeDashF.js';

export const makeStrokeDashHtml = (svg_el) => {
  const dash_1 = SVGEditorStrokeDashF.svgToViewUnit(SVGEditorAttrS.get('strokeDasharray1', svg_el));
  const dash_2 = SVGEditorStrokeDashF.svgToViewUnit(SVGEditorAttrS.get('strokeDasharray2', svg_el));
  return html`
    <div
      class="svg_editor_stroke_dash_wrapper content block_for_stroke_dash_wrapper"
      data-has_dash_stroke="${!!dash_1 || !!dash_2}"
      data-is_locked="${dash_1 === dash_2}"
    >
      <div class="title"><span></span>${T('maker_border_editor::점선')}</div>
      <div class="body">
        <div class="control_box" data-key="strokeDasharray1">
          <div class="minus operator" data-step="-0.1"></div>
          <div class="number">${dash_1}</div>
          <div class="plus operator" data-step="0.1"></div>
        </div>
        <div class="lock"></div>
        <div class="control_box" data-key="strokeDasharray2">
          <div class="minus operator" data-step="-0.1"></div>
          <div class="number">${dash_2}</div>
          <div class="plus operator" data-step="0.1"></div>
        </div>
      </div>
      <div class="block_for_stroke_dash"></div>
    </div>
  `;
};
