import { indexBy, find, sel, deepFlat, go, map, zipWithIndexL, sumBy } from 'fxjs/es';
import { GoodsTypeS } from '../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { apiCalls } from '../../../F/Function/api.calls.js';
import { MESSAGES } from '../../../S/Constant/lgl.js';

/*
 *  @description 주문 제품 정보 조회 (썸네일, 이름, 옵션 등)
 *  @params user_products - up_c, up_c_ss
 */
export function getProductInfoByUpc({ up_c, up_c_ss }) {
  const { quantity } = up_c_ss;

  const tpl = GoodsTypeS.isTpl(up_c.goods_type_id);
  const name = tpl ? up_c._.product_color.name : up_c._.base_product.name;
  const { product_faces2 } = up_c._.product_color;

  const options = [];
  if (up_c_ss._.base_product_color.name !== '') {
    options.push(up_c_ss._.base_product_color.name);
  }
  if (up_c_ss._.base_product_size.name !== '') {
    options.push(up_c_ss._.base_product_size.name);
  }
  const option = tpl ? '' : `${options.join(' / ')}`;

  const thumbnails = go(sel('_.product_color.thumbnails.value', up_c));
  const thumbnail_url = go(thumbnails, find(sel('is_thumb')), sel('url'));
  const thumbnail_urls = go(
    thumbnails,
    map(sel('url')),
    map((url) => `https:${url}`),
  );

  return {
    goods_type_id: up_c.goods_type_id ?? 'MP',
    tpl,
    thumbnail_url,
    thumbnail_urls,
    product_faces2,
    name,
    option,
    quantity,
  };
}

/*
 *  @description 주문 제품 정보 조회
 *  @params user_products - up[]
 */
export function getProducts({ user_products }) {
  return go(
    user_products,
    map((up) => {
      return go(
        up._.up_cs,
        map((up_c) => {
          const {
            _: { up_c_ss },
          } = up_c;
          return go(
            up_c_ss,
            zipWithIndexL,
            map(([i, size]) => {
              const {
                id: upcs_id,
                quantity,
                _: { lgl_items },
              } = size;

              const loaded_quantity =
                go(
                  lgl_items,
                  sumBy(
                    ({
                      _: {
                        lgl_item_quantity: { inbound_order_requested_quantity },
                      },
                    }) => inbound_order_requested_quantity,
                  ),
                ) || 0;

              const left_quantity = quantity - loaded_quantity;

              const product_info = getProductInfoByUpc({ up_c, up_c_ss: size });

              return {
                ...product_info,
                upcs_id,
                product_faces2: up_c._.product_color.product_faces2,
                lgl_product_id: up_c?.lgl_product_id,
                left_quantity,
              };
            }),
          );
        }),
      );
    }),
    deepFlat,
  );
}

export function getProductsById({ user_products }) {
  return go(
    getProducts({ user_products }),
    indexBy((p) => p.upcs_id),
  );
}

export async function setLglProductsId({ projection }) {
  const upcs = getProducts({ user_products: projection._.ups });
  const lgl_products = await go(
    upcs,
    map(async ({ upcs_id }) => {
      const result = go(await apiCalls.post.item.createLglProduct({ upcs_id }), sel('data'));
      return {
        upcs_id,
        lgl_product_id: result.id,
      };
    }),
  );

  go(
    projection._.ups,
    map((up) =>
      go(
        up._.up_cs,
        map((up_c) => {
          up_c.lgl_product_id = go(
            lgl_products,
            find(({ upcs_id }) => upcs_id === up_c.id),
          ).lgl_product_id;
          return up_c;
        }),
      ),
    ),
  );
}

export function getInboundTabFooterMessage({
  current_status,
  return_in_progress = false,
  return_completed = false,
  rebound = false,
}) {
  let message = MESSAGES.INBOUND[current_status];

  if (return_in_progress) {
    message = MESSAGES.INBOUND.RETURN_IN_PROGRESS;
  } else if (return_completed) {
    message = MESSAGES.INBOUND.RETURN_COMPLETED;
  }

  if (rebound) {
    message = MESSAGES.REBOUND[current_status];

    if (return_in_progress) {
      message = MESSAGES.REBOUND.RETURN_IN_PROGRESS;
    } else if (return_completed) {
      message = MESSAGES.REBOUND.RETURN_COMPLETED;
    }
  }

  return message;
}
