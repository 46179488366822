import { makeCanvasByUrl } from '../../../../../Canvas/S/util.js';
import { makeCanvasTextured } from '../../../../../Maker/F/cylinder.js';
import { getImageUrlByZoom } from '../../../../../Maker/F/util.js';
import { go } from 'fxjs/es';

export const common = {
  shadeMaterial: {
    makeShadeMaterialData: ({ color_code, bpc_target_key, design_data_url, name, deep_px }) => {
      if (!bpc_target_key) throw new Error('bpc_target_key required');
      if (!color_code) throw new Error('color_code required');
      if (!name) throw new Error('name required');
      if (!design_data_url) throw new Error('design_data_url required');
      return {
        color_code,
        bpc_target_key,
        design_data_url,
        name,
        deep_px,
      };
    },
    uploadShadeMaterialData: async (cv_preview) => {
      if (!cv_preview._data?.shade_material?.design_data_url) return;
      const { url: design_url } = await $.uploadFileToUrl(
        cv_preview._data.shade_material.design_data_url,
        'cv_preview',
      );
      cv_preview._data.shade_material.design_data_url = null;
      cv_preview._data.shade_material.design_url = design_url;
    },
    renderCvPreview: async (shade_material, renderFunc, zoom) => {
      if (!shade_material) return;
      const { original_color_code, color_code, design_data_url, design_url } = shade_material;
      if (original_color_code === color_code) return;

      if (design_data_url) {
        return go(makeCanvasByUrl(design_data_url), (c) => c.toDataURL());
      } else {
        return go(
          makeCanvasTextured(await makeCanvasByUrl(getImageUrlByZoom(design_url, zoom)), color_code),
          (canvas) => renderFunc(canvas, shade_material),
          (c) => c.toDataURL(),
        );
      }
    },
  },
};
