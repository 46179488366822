import { defnDfUserDetailTab, defnDfUserDetailPointTab, defnDfUserDetailReviewTab } from './tab.js';

$.frame.defn_page({
  page_name: 'df.user.detail',
  tabs: [defnDfUserDetailTab()],
});

$.frame.defn_page({
  page_name: 'df.user.point',
  tabs: [defnDfUserDetailPointTab()],
});

$.frame.defn_page({
  page_name: 'df.user.review',
  tabs: [defnDfUserDetailReviewTab()],
});
