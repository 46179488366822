import onScan from 'onscan.js';
import { DfInhouseF } from './module/DfInhouseF.js';
import { makeDfProjectionListUpdate } from '../../../Projection/List/F/fs.js';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';
import { DfInhouseConstantS } from '../../S/Constant/module/DfInhouseConstantS.js';

export const scannerEventHandler = {
  attach: (el = document.body) => {
    if (onScan.isAttachedTo(el)) return;
    onScan.attachTo(el, scanner_options);
  },
  detach: (el = document.body) => {
    onScan.detachFrom(el);
  },
};

const scanner_options = {
  onScan: async (code) => {
    try {
      DfInhouseF.lottie_loader.start();
      if (isScannerOnlyShippingTeam({ code }) === false) {
        throw new Error(
          `배송팀 전용 스캐너로 기능이 제한 되어 있습니다.<br>작업하시려면 개발팀에게 문의주세요.`,
        );
      }

      code = removePrefixAndSuffix({ code });
      /* code 후보
       *   - 주문 박스 라벨 스캔 -> code === projection_id
       *   - 운송장 라벨 스캔
       *      - 조회 모드
       *      - 포장 모드
       *      - 완료 엑션
       *  */
      const code_parsed = parseInhouseCode({ code });

      switch (code_parsed?.scan_target) {
        case 'projection_box': {
          const projection_id = code_parsed.value;
          await DfInhouseF.handleScanProjectionBox({ projection_id });
          break;
        }
        case 'waybill': {
          const { mode, waybill_no } = code_parsed.value;
          await DfInhouseF.handleScanWaybillControlCode({ mode, waybill_no });
          break;
        }
        default:
          return DfInhouseF.alert.error({
            title: '스캔 오류',
            err: new Error(`사내 배송으로 인식 불가한 코드를 스캔한 것 같아요.`),
          });
      }
    } catch (err) {
      DfInhouseF.lottie_loader.end();
      console.error(err);
      await DfInhouseF.alert.error({ err });
    } finally {
      DfInhouseF.lottie_loader.end();
    }
  },
  keyCodeMapper: function (oEvent) {
    if (oEvent.which === 188) return ',';
    if (oEvent.which === 189) return '-';
    if (oEvent.which === 219) return '[';
    if (oEvent.which === 221) return ']';
    // Fall back to the default decoder in all other cases
    return onScan.decodeKeyEvent(oEvent);
  },
  ignoreIfFocusOn: 'input',
  // reactToPaste: true,
};

function parseInhouseCode({ code }) {
  const split_codes = code.split('-');

  // 조건1. 하이픈- 없이 한 조각으로 이루어짐
  // 조건2. 숫자로만 이루어짐
  if (split_codes.length === 1) {
    if (UtilStringS.isNumericString(code)) {
      return {
        scan_target: 'projection_box',
        value: Number(code), // projection_id (code 128, c 규격, 짝수 길이 규격이므로 제일 앞에 0 이 붙은 주문 번호가 들어올 수 있음)
      };
    }
  }

  const { key: prefix, key_kr: prefix_kr } = DfInhouseConstantS.BUCKET_CONTROL_PREFIX;
  const {
    PACK: { key: pack_mode, key_kr: pack_mode_kr, key_kr2: pack_mode_kr2 },
    UNPACK: { key: unpack_mode, key_kr: unpack_mode_kr, key_kr2: unpack_mode_kr2 },
  } = DfInhouseConstantS.BUCKET_MODE_KEY;
  const {
    COMPLETE: { key: complete_command, key_kr: complete_command_kr, key_kr2: complete_command_kr2 },
  } = DfInhouseConstantS.BUCKET_CONTROL_NAME;

  // 조건1. 하이폰 존재, a-b-c 모두 값 존재 (inhouse_prefix - mode_key - waybill_no)
  const convertModeKrToEn = {
    [pack_mode_kr]: pack_mode,
    [pack_mode_kr2]: pack_mode,
    [unpack_mode_kr]: unpack_mode,
    [unpack_mode_kr2]: unpack_mode,
    [complete_command_kr]: complete_command,
    [complete_command_kr2]: complete_command,
  };

  if (split_codes.length === 3) {
    let [a, b, c] = split_codes;

    a = convertUpperCaseWhenEnglish(a);
    b = convertUpperCaseWhenEnglish(b);

    if (a === prefix || a === prefix_kr) {
      if (UtilStringS.hasKorean({ input_str: b })) {
        b = convertModeKrToEn[b];
      }

      if (
        // 모드 체크
        [pack_mode, unpack_mode, complete_command].includes(b)
      ) {
        if (UtilStringS.isNumericString(c)) {
          return {
            scan_target: 'waybill',
            value: {
              mode: b,
              waybill_no: c,
            },
          };
        }
      }
    }
  }
}

function convertUpperCaseWhenEnglish(str) {
  return UtilStringS.hasKorean({ input_str: str }) ? str : str.toUpperCase();
}

function removePrefixAndSuffix({ code }) {
  return code.slice(1, -1);
}

function isScannerOnlyShippingTeam({ code }) {
  const { first, last } = UtilStringS.getFirstAndLastCharacter({ str: code });

  return first === '!' && last === '@';
}

export async function handleScanProjectionLabel({ projection_id, is_override }) {
  try {
    DfInhouseF.lottie_loader.start();

    // 가드 - 유효한 주문번호 인지 확인
    const projection_status = await DfInhouseF.apiCalls.get.projectionStatus({ projection_id });
    if (projection_status == null) {
      throw new Error(`유효하지 않은 주문 번호 입니다.`);
    }

    // 가드 - 취소된 건인지 확인
    const { status, is_cancel_req, is_canceled } = projection_status;

    if (status === 'canceled' || is_cancel_req || is_canceled) {
      throw new Error(`취소된 주문 건 입니다.`);
    }

    if (['before_order', 'ordering', 'before_print'].includes(status)) {
      throw new Error(`아직 제작 전 단계의 주문입니다.`);
    }
  } catch (err) {
    console.error(err);
    await DfInhouseF.alert.error({ err });
    return { is_OK: false };
  } finally {
    DfInhouseF.lottie_loader.end();
  }

  const { is_OK } = await DfInhouseF.labelPrint.projectionBox({
    projection_id,
    is_override,
  });
  if (is_OK) {
    try {
      await DfInhouseF.apiCalls.put.shippingTaskStatus({ projection_id, status_tobe: 'on' });
      makeDfProjectionListUpdate();
      await DfInhouseF.alert.success({
        title: '주문 라벨 출력 성공',
        msg: '박스 상단에 라벨을 부착해 주세요.',
        timer: 1500,
      });
      return { is_OK: true };
    } catch (err) {
      await DfInhouseF.alert.error({
        err,
        title: '배송 태스크 업데이트 오류',
        additional_msg: '배송 태스크 [진행중] 상태로 변경 요망',
      });
      return { is_OK: false };
    }
  }
}
