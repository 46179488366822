import { VectorEditorStickerGridMobileF } from '../Function/module/VectorEditorStickerGridMobileF.js';

export const handleEditorSelect =
  ({ tab_el }) =>
  ({ detail: { selected_grid_id } }) => {
    VectorEditorStickerGridMobileF.showTypeSelectFooters(tab_el);
    tab_el.__mp_selected_grid_id = selected_grid_id;
  };

export const handleEditorUnselect =
  ({ tab_el }) =>
  () => {
    VectorEditorStickerGridMobileF.showTypeUnselectFooters(tab_el);
    tab_el.__mp_selected_grid_id = null;
  };
