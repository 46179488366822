import { go, omit } from 'fxjs/es';
import { beforeLoginCheck } from '../../User/Login/F/fs.js';
import { onbeforeunload_event_off, uploadMakerOneOgImage } from './util.js';
import { makeDataCmAndChangeCvTextResetBoxDataA } from './text.js';
import { isProductColorInGood } from './overflow.js';

export const go_sharing = async () => {
  await makeDataCmAndChangeCvTextResetBoxDataA();
  return go(
    isProductColorInGood(),
    async function (is_passed) {
      if (!is_passed) return $.don_loader_end();
      await makeDataCmAndChangeCvTextResetBoxDataA();
      return beforeLoginCheck();
    },
    function (is_login) {
      if (!is_login) return $.don_loader_end();
      $.don_loader_start();
      return go(
        uploadMakerOneOgImage(),
        function (url) {
          const pc = omit(['_'], box.sel('maker->product_color'));
          pc.og_image_url = url;
          return $.post('/@api/product_color/create', { pc });
        },
        function (pc) {
          $.frame.extend_state(void 0, null, G.update_query_str(location.href, 'pc_id', pc.id), 'replace');
          onbeforeunload_event_off();
          $.don_loader_end();
          return $.alert(T('line_maker::Address Copied. Paste the copied address.'));
        },
        function () {
          const textarea = document.createElement('textarea');
          textarea.value = location.href;
          document.body.appendChild(textarea);
          textarea.select();
          textarea.setSelectionRange(0, 9999); // 추가
          document.execCommand('copy');
          document.body.removeChild(textarea);
        },
      );
    },
  );
};
