import { html } from 'fxjs/es';

export const fn = ({ src1, src2, title, ok_text, cancel_text, is_agreement, type }) => {
  return html`
    <div class="condition_popup_body">
      <div class="img src1"><img src="${src1}" alt="" /></div>
      ${src2 ? html`<div class="img src2" style="display:none;"><img src="${src2}" alt="" /></div>` : html``}
      ${is_agreement
        ? html`<div class="checkboxs">
            <input type="checkbox" id="agreement_checkbox" /><label for="agreement_checkbox"
              >위 사항에 동의하시겠습니까?</label
            >
          </div>`
        : html``}
      <div class="btns">
        ${ok_text && cancel_text
          ? html`<button class="btn_next">${cancel_text || T('maker::Message::그대로 작업하기')}</button>
              <button class="btn_edit" data-type="${type}">
                ${ok_text || T('maker::Message::수정하러 가기')}
              </button>`
          : ok_text
          ? html`<button class="btn_confirm" data-type="${type} ">
              ${ok_text || T('maker_error_alert::확인했습니다.')}
            </button>`
          : html``}
      </div>
    </div>
  `;
};
