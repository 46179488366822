import {
  appendL,
  defaultTo,
  dropL,
  each,
  eachL,
  equals,
  equals2,
  filterL,
  go,
  head,
  isNil,
  mapL,
  noop,
  not,
  reduce,
  rejectL,
  takeL,
  tap,
  zipL,
} from 'fxjs/es';
import { $append, $appendTo, $closest, $el, $find, $findAll, $on, $remove, $setHTML } from 'fxdom/es';
import { CommonNS, StrokeNS } from '@marpple/sticker-editor';
import { VectorEditorStickerSinglePCTmplS } from '../../S/Tmpl/module/VectorEditorStickerSinglePCTmplS.js';
import {
  handleLayerLayerItemClick,
  handleLayerLayerItemLockClick,
  handleLayerLayerItemVisibleClick,
} from './layer.js';
import { SVGEditorRightPaneltextF } from '../../../../../../SVGEditor/RightPanel/text/F/Function/module/SVGEditorRightPaneltextF.js';
import { SVGEditorRightPanelShapeF } from '../../../../../../SVGEditor/RightPanel/Shape/F/Function/module/SVGEditorRightPanelShapeF.js';
import { VectorEditorStickerSinglePCPathEditorF } from '../../PathEditor/F/Function/module/VectorEditorStickerSinglePCPathEditorF.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';
import { VectorEditorTopMenuPCF } from '../../../../../TopMenu/PC/F/Function/module/VectorEditorTopMenuPCF.js';

const TYPE_NONE_TOP_MENU_ID = {
  ACTIVATE: new Set(),
  DEACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
};
const TYPE_SINGLE_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
  DEACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP.ID,
  ]),
};
const TYPE_GROUP_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
  DEACTIVATE: new Set([VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP.ID]),
};
const TYPE_MULTIPLE_TOP_MENU_ID = {
  ACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END.ID,
  ]),
  DEACTIVATE: new Set([
    VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD.ID,
    VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP.ID,
  ]),
};

const handleSingleStickerEditorSelectOrUnselect =
  ({ tab_el }) =>
  (event) => {
    const single_sticker_editor = tab_el.__mp_single_sticker_editor;
    const { selected_els } = event.detail;

    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      each(
        (layer_item_el) =>
          (layer_item_el.dataset.is_selected = `${selected_els.has(layer_item_el.__mp_original_el)}`),
      ),
    );

    if (single_sticker_editor == null) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'left';
          right_container_el.dataset.show_panel = 'home';
          right_container_el.__mp_right_panel_removed?.();
          right_container_el.__mp_right_panel_removed = null;
        }),
      );
      return;
    }

    if (selected_els == null) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'left';
          right_container_el.dataset.show_panel = 'home';
          right_container_el.__mp_right_panel_removed?.();
          right_container_el.__mp_right_panel_removed = null;
        }),
      );
      return;
    }

    const count = selected_els.size;
    if (count > 0) {
      single_sticker_editor.turnOffArtBoardClipPath();
    } else {
      single_sticker_editor.turnOnArtBoardClipPath();
    }

    if (count == null || count <= 0) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'left';
          right_container_el.dataset.show_panel = 'home';
          right_container_el.__mp_right_panel_removed?.();
          right_container_el.__mp_right_panel_removed = null;
        }),
      );
      return;
    }

    if (count >= 2) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_MULTIPLE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_MULTIPLE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'left';
          right_container_el.dataset.show_panel = 'home';
          right_container_el.__mp_right_panel_removed?.();
          right_container_el.__mp_right_panel_removed = null;
        }),
      );
      return;
    }

    const selected_el = selected_els.values().next().value;

    if (selected_el == null) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'left';
          right_container_el.dataset.show_panel = 'home';
          right_container_el.__mp_right_panel_removed?.();
          right_container_el.__mp_right_panel_removed = null;
        }),
      );
      return;
    }

    const selected_el_tag_name = selected_el.tagName;

    if (selected_el_tag_name == null) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_NONE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_NONE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'left';
          right_container_el.dataset.show_panel = 'home';
          right_container_el.__mp_right_panel_removed?.();
          right_container_el.__mp_right_panel_removed = null;
        }),
      );
      return;
    }

    if (equals2(selected_el_tag_name)('text')) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        eachL((right_container_el) =>
          go(
            right_container_el,
            tap((el) => el.__mp_right_panel_removed?.()),
            $findAll(`.panel_wrapper[data-panel_wrapper="right"]`),
            eachL($setHTML('')),
            each((panel_wrapper_el) => {
              const { el, appended, removed } = SVGEditorRightPaneltextF.fn({
                svg_el: selected_el,
                single_sticker_editor,
                fonts: tab_el.__fonts,
              });
              $append(el)(panel_wrapper_el);
              appended(el);
              right_container_el.__mp_right_panel_removed = () => removed(el);
            }),
          ),
        ),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'right';
          right_container_el.dataset.show_panel = 'text';
        }),
      );
      return;
    }

    if (equals2(selected_el_tag_name)('image')) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'left';
          right_container_el.dataset.show_panel = 'home';
          right_container_el.__mp_right_panel_removed?.();
          right_container_el.__mp_right_panel_removed = null;
        }),
      );
      return;
    }

    if (equals2(selected_el_tag_name)('path')) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        eachL((right_container_el) =>
          go(
            right_container_el,
            tap((el) => el.__mp_right_panel_removed?.()),
            $findAll(`.panel_wrapper[data-panel_wrapper="right"]`),
            eachL($setHTML('')),
            each((panel_wrapper_el) => {
              const { el, appended, removed } = SVGEditorRightPanelShapeF.fn({
                tab_el,
                svg_el: selected_el,
                is_path: true,
                single_sticker_editor,
                prev_frame_right_panel_el: $find(`.right_container .right_wrapper`)(tab_el),
                frame_position:
                  G.collabo_type === ''
                    ? right_container_el.getBoundingClientRect?.()
                    : {
                        top: tab_el.__mp_frame_position_top,
                        width: go(
                          tab_el,
                          $findAll(`.right_container`),
                          mapL((el) => el.getBoundingClientRect().width),
                          head,
                          defaultTo(0),
                        ),
                        height: tab_el.__mp_frame_position_height,
                      },
                editPath: (payload) => VectorEditorStickerSinglePCPathEditorF.editPath(payload),
              });
              $append(el)(panel_wrapper_el);
              appended(el);
              right_container_el.__mp_right_panel_removed = () => removed(el);
            }),
          ),
        ),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'right';
          right_container_el.dataset.show_panel = 'shape';
        }),
      );
      return;
    }

    if (new Set(['circle']).has(selected_el_tag_name)) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        eachL((right_container_el) =>
          go(
            right_container_el,
            tap((el) => el.__mp_right_panel_removed?.()),
            $findAll(`.panel_wrapper[data-panel_wrapper="right"]`),
            eachL($setHTML('')),
            each((panel_wrapper_el) => {
              const { el, appended, removed } = SVGEditorRightPanelShapeF.fn({
                tab_el,
                svg_el: selected_el,
                is_path: false,
                single_sticker_editor,
                prev_frame_right_panel_el: $find(`.right_container .right_wrapper`)(tab_el),
                frame_position: {
                  top: tab_el.__mp_frame_position_top,
                  width: go(
                    tab_el,
                    $findAll(`.right_container`),
                    mapL((el) => el.getBoundingClientRect().width),
                    head,
                    defaultTo(0),
                  ),
                  height: tab_el.__mp_frame_position_height,
                },
                editPath: noop,
              });
              $append(el)(panel_wrapper_el);
              appended(el);
              right_container_el.__mp_right_panel_removed = () => removed(el);
            }),
          ),
        ),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'right';
          right_container_el.dataset.show_panel = 'shape';
        }),
      );
      return;
    }

    if (single_sticker_editor.getIsGroupEl(selected_el)) {
      go(
        tab_el,
        $findAll(`.top_menus`),
        each((top_menus_el) =>
          VectorEditorTopMenuPCF.activateTopMenus({
            top_menus_el,
            activate_ids: TYPE_GROUP_TOP_MENU_ID.ACTIVATE,
            deactivate_ids: TYPE_GROUP_TOP_MENU_ID.DEACTIVATE,
          }),
        ),
      );
      go(
        tab_el,
        $findAll(`.right_container`),
        each((right_container_el) => {
          right_container_el.dataset.show_panel_wrapper = 'left';
          right_container_el.dataset.show_panel = 'home';
          right_container_el.__mp_right_panel_removed?.();
          right_container_el.__mp_right_panel_removed = null;
        }),
      );
      return;
    }

    go(
      tab_el,
      $findAll(`.top_menus`),
      each((top_menus_el) =>
        VectorEditorTopMenuPCF.activateTopMenus({
          top_menus_el,
          activate_ids: TYPE_SINGLE_TOP_MENU_ID.ACTIVATE,
          deactivate_ids: TYPE_SINGLE_TOP_MENU_ID.DEACTIVATE,
        }),
      ),
    );
    go(
      tab_el,
      $findAll(`.right_container`),
      each((right_container_el) => {
        right_container_el.dataset.show_panel_wrapper = 'left';
        right_container_el.dataset.show_panel = 'home';
        right_container_el.__mp_right_panel_removed?.();
        right_container_el.__mp_right_panel_removed = null;
      }),
    );
  };

export const handleSingleStickerEditorSelect =
  ({ tab_el }) =>
  (event) =>
    handleSingleStickerEditorSelectOrUnselect({ tab_el })(event);

export const handleSingleStickerEditorUnselect =
  ({ tab_el }) =>
  (event) =>
    handleSingleStickerEditorSelectOrUnselect({ tab_el })(event);

export const handleSingleStickerEditorAdd =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    if (tab_el.__mp_empty_template_el != null) {
      tab_el.__mp_empty_template_el.setAttributeNS(null, 'display', 'none');
    }

    const layer_container_el = $find(`.left_container .layer_container`)(tab_el);
    if (isNil(layer_container_el)) {
      return;
    }

    const list_container_el = $find(`.list_container`)(layer_container_el);
    if (isNil(list_container_el)) {
      return;
    }

    const els = (() => {
      const els = tab_el?.__mp_single_sticker_editor?.getGraphicsEls?.();
      if (isNil(els)) {
        return null;
      }
      return [...els].reverse();
    })();
    if (isNil(els)) {
      return;
    }

    const layer_item_els = $findAll(`.layer_item`)(layer_container_el);

    const next_el = go(
      zipL(els, appendL(null, dropL(1, els))),
      filterL(([_el]) => equals(el)(_el)),
      mapL(([, el]) => el),
      head,
    );
    const next_layer_item_el =
      go(
        layer_item_els,
        rejectL((layer_item_el) => isNil(layer_item_el.__mp_original_el)),
        filterL((layer_item_el) => equals(next_el)(layer_item_el.__mp_original_el)),
        head,
      ) ?? null;

    const label = (() => {
      if (tab_el.__mp_single_sticker_editor?.getIsGroupEl(el)) {
        return T('modules::VectorEditor::Sticker::Single::Mobile::ElementLayer::template::layer_label_group');
      }

      switch (el.nodeName?.toLowerCase?.()) {
        case 'text': {
          return T(
            'modules::VectorEditor::Sticker::Single::Mobile::ElementLayer::template::layer_label_text',
          );
        }
        case 'circle':
        case 'path': {
          return T(
            'modules::VectorEditor::Sticker::Single::Mobile::ElementLayer::template::layer_label_shape',
          );
        }
        case 'image': {
          return T(
            'modules::VectorEditor::Sticker::Single::Mobile::ElementLayer::template::layer_label_image',
          );
        }
        default: {
          return '';
        }
      }
    })();
    const layer_item_el = go(
      $el(
        VectorEditorStickerSinglePCTmplS.makeLayerItemHtml({
          label,
          is_selected: tab_el.__mp_single_sticker_editor?.getSelectedEls?.()?.has?.(el) ?? false,
          is_locked: tab_el.__mp_single_sticker_editor?.getIsLocked?.(el) ?? false,
          is_visible: !equals2('none')(el.getAttributeNS(null, 'display')),
        }),
      ),
      tap((layer_item_el) => (layer_item_el.__mp_original_el = el)),
      tap($on('click', handleLayerLayerItemClick({ tab_el }))),
      tap($findAll(`.button_container .lock`), each($on('click', handleLayerLayerItemLockClick({ tab_el })))),
      tap(
        $findAll(`.button_container .visible`),
        each($on('click', handleLayerLayerItemVisibleClick({ tab_el }))),
      ),
      tap((layer_item_el) => {
        const svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
        svg_el.setAttributeNS(null, 'width', '100%');
        svg_el.setAttributeNS(null, 'height', '100%');
        svg_el.setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid meet');
        svg_el.setAttributeNS(null, 'viewBox', '0 0 0 0');
        go(layer_item_el, $findAll(`.image_container`), takeL(1), each($append(svg_el)));
        layer_item_el.__mp_svg_el = svg_el;

        const dummy_g_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
        svg_el.appendChild(dummy_g_el);
        layer_item_el.__mp_dummy_g_el = dummy_g_el;

        layer_item_el.__mp_clone_el = null;

        const stroke = new StrokeNS.ModelNS.Stroke({ svg_el, getCTM: () => dummy_g_el.getCTM() });
        layer_item_el.__mp_stroke = stroke;
        layer_item_el.__mp_stroke_sync = new StrokeNS.ModelNS.StrokeSync({
          el: svg_el,
          stroke,
          SVGGraphicsElement: window.SVGGraphicsElement,
        });

        layer_item_el.__mp_task_queue = [];
        layer_item_el.__mp_is_processing = false;
      }),
    );
    list_container_el.insertBefore(layer_item_el, next_layer_item_el);
    list_container_el.dataset.count = `${layer_item_els.length + 1}`;

    const f = async () => {
      layer_item_el.__mp_is_processing = true;

      if (!isNil(layer_item_el.__mp_clone_el)) {
        layer_item_el.__mp_svg_el.removeChild(layer_item_el.__mp_clone_el);
      }
      layer_item_el.__mp_clone_el = /** @type {SVGGraphicsElement} */ await CommonNS.UtilNS.deepCloneNode(
        layer_item_el.__mp_original_el,
      );
      layer_item_el.__mp_clone_el.removeAttributeNS(null, 'display');
      layer_item_el.__mp_svg_el.appendChild(layer_item_el.__mp_clone_el);

      if (
        go(
          layer_item_el,
          $closest(`.layer_container`),
          (layer_container_el) => equals2('true')(layer_container_el?.dataset?.is_show),
          not,
        )
      ) {
        const f = layer_item_el.__mp_task_queue.shift();
        if (isNil(f)) {
          layer_item_el.__mp_is_processing = false;
          return;
        }
        f();
        return;
      }

      const bbox = layer_item_el.__mp_clone_el.getBBox();
      const matrix =
        CommonNS.UtilNS.getConsolidatedTransformMatrix(layer_item_el.__mp_clone_el) ??
        layer_item_el.__mp_svg_el.createSVGMatrix();
      const view_box = go(
        [
          [bbox.x, bbox.y],
          [bbox.x + bbox.width, bbox.y],
          [bbox.x + bbox.width, bbox.y + bbox.height],
          [bbox.x, bbox.y + bbox.height],
        ],
        mapL(CommonNS.UtilNS.createSVGPoint(layer_item_el.__mp_svg_el)),
        mapL((point) => point.matrixTransform(matrix)),
        (points) =>
          reduce(
            (acc, { x, y }) => {
              acc.min_x = Math.min(acc.min_x, x);
              acc.min_y = Math.min(acc.min_y, y);
              acc.max_x = Math.max(acc.max_x, x);
              acc.max_y = Math.max(acc.max_y, y);
              return acc;
            },
            { min_x: Infinity, min_y: Infinity, max_x: -Infinity, max_y: -Infinity },
            points,
          ),
        ({ min_x, min_y, max_x, max_y }) => {
          const fit_width = max_x - min_x;
          const fit_height = max_y - min_y;
          const x_margin = fit_width / 10;
          const y_margin = fit_height / 10;
          return `${min_x - x_margin} ${min_y - y_margin} ${fit_width + x_margin * 2} ${
            fit_height + y_margin * 2
          }`;
        },
      );
      layer_item_el.__mp_svg_el.setAttributeNS(null, 'viewBox', view_box);
      layer_item_el.__mp_stroke_sync.sync();

      const f = layer_item_el.__mp_task_queue.shift();
      if (isNil(f)) {
        layer_item_el.__mp_is_processing = false;
        return;
      }
      f();
    };
    layer_item_el.__mp_task_queue.push(f);
    if (layer_item_el.__mp_task_queue.length > 1) {
      layer_item_el.__mp_task_queue.splice(0, layer_item_el.__mp_task_queue.length - 1);
    }
    if (layer_item_el.__mp_is_processing) {
      return;
    }
    layer_item_el.__mp_task_queue?.shift?.()?.();
  };

export const handleSingleStickerEditorRemove =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    const count = tab_el.__mp_single_sticker_editor?.getGraphicsEls?.()?.size;
    if (count != null && count <= 0 && tab_el.__mp_empty_template_el != null) {
      tab_el.__mp_empty_template_el.removeAttributeNS(null, 'display');
    }

    const layer_container_el = $find(`.left_container .layer_container`)(tab_el);
    if (isNil(layer_container_el)) {
      return;
    }

    const list_container_el = $find(`.list_container`)(layer_container_el);
    if (isNil(list_container_el)) {
      return;
    }

    go(
      list_container_el,
      $findAll(`.layer_item`),
      filterL((layer_item_el) => equals(el)(layer_item_el.__mp_original_el)),
      each($remove),
    );
    list_container_el.dataset.count = go(
      list_container_el,
      $findAll(`.layer_item`),
      (els) => els.length,
      (l) => `${l}`,
    );
  };

export const handleSingleStickerEditorReorder =
  ({ tab_el }) =>
  () => {
    const layer_container_el = $find(`.left_container .layer_container`)(tab_el);
    if (isNil(layer_container_el)) {
      return;
    }

    const list_container_el = $find(`.list_container`)(layer_container_el);
    if (isNil(list_container_el)) {
      return;
    }

    const els = (() => {
      const els = tab_el?.__mp_single_sticker_editor?.getGraphicsEls?.();
      if (isNil(els)) {
        return null;
      }
      return [...els].reverse();
    })();
    if (isNil(els)) {
      return;
    }

    const layer_item_els_wm = go(list_container_el, $findAll(`.layer_item`), eachL($remove), (iter) =>
      reduce(
        (wm, layer_item_el) => {
          wm.set(layer_item_el.__mp_original_el, layer_item_el);
          return wm;
        },
        new WeakMap(),
        iter,
      ),
    );

    go(
      els,
      mapL((el) => layer_item_els_wm.get(el)),
      each($appendTo(list_container_el)),
    );
  };

export const handleSingleStickerEditorLock =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => equals(el)(layer_item_el.__mp_original_el)),
      each((layer_item_el) => (layer_item_el.dataset.is_locked = 'true')),
    );
  };

export const handleSingleStickerEditorUnlock =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => equals(el)(layer_item_el.__mp_original_el)),
      each((layer_item_el) => (layer_item_el.dataset.is_locked = 'false')),
    );
  };

export const handleSingleStickerEditorShow =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => equals(el)(layer_item_el.__mp_original_el)),
      each((layer_item_el) => (layer_item_el.dataset.is_visible = 'true')),
    );
  };

export const handleSingleStickerEditorHide =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => equals(el)(layer_item_el.__mp_original_el)),
      each((layer_item_el) => (layer_item_el.dataset.is_visible = 'false')),
    );
  };

export const handleSingleStickerEditorTransform =
  ({ tab_el }) =>
  ({ detail: { el, transform_type, transform_status } }) => {
    if (equals2('translate')(transform_type)) {
      return;
    }
    if (!equals2('end')(transform_status)) {
      return;
    }

    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => equals(el)(layer_item_el.__mp_original_el)),
      each((layer_item_el) => {
        const f = async () => {
          layer_item_el.__mp_is_processing = true;

          if (!isNil(layer_item_el.__mp_clone_el)) {
            layer_item_el.__mp_svg_el.removeChild(layer_item_el.__mp_clone_el);
          }
          layer_item_el.__mp_clone_el = /** @type {SVGGraphicsElement} */ await CommonNS.UtilNS.deepCloneNode(
            layer_item_el.__mp_original_el,
          );
          layer_item_el.__mp_clone_el.removeAttributeNS(null, 'display');
          layer_item_el.__mp_svg_el.appendChild(layer_item_el.__mp_clone_el);

          if (
            go(
              layer_item_el,
              $closest(`.layer_container`),
              (layer_container_el) => equals2('true')(layer_container_el?.dataset?.is_show),
              not,
            )
          ) {
            const f = layer_item_el.__mp_task_queue.shift();
            if (isNil(f)) {
              layer_item_el.__mp_is_processing = false;
              return;
            }
            f();
            return;
          }

          const bbox = layer_item_el.__mp_clone_el.getBBox();
          const matrix =
            CommonNS.UtilNS.getConsolidatedTransformMatrix(layer_item_el.__mp_clone_el) ??
            layer_item_el.__mp_svg_el.createSVGMatrix();
          const view_box = go(
            [
              [bbox.x, bbox.y],
              [bbox.x + bbox.width, bbox.y],
              [bbox.x + bbox.width, bbox.y + bbox.height],
              [bbox.x, bbox.y + bbox.height],
            ],
            mapL(CommonNS.UtilNS.createSVGPoint(layer_item_el.__mp_svg_el)),
            mapL((point) => point.matrixTransform(matrix)),
            (points) =>
              reduce(
                (acc, { x, y }) => {
                  acc.min_x = Math.min(acc.min_x, x);
                  acc.min_y = Math.min(acc.min_y, y);
                  acc.max_x = Math.max(acc.max_x, x);
                  acc.max_y = Math.max(acc.max_y, y);
                  return acc;
                },
                { min_x: Infinity, min_y: Infinity, max_x: -Infinity, max_y: -Infinity },
                points,
              ),
            ({ min_x, min_y, max_x, max_y }) => {
              const fit_width = max_x - min_x;
              const fit_height = max_y - min_y;
              const x_margin = fit_width / 10;
              const y_margin = fit_height / 10;
              return `${min_x - x_margin} ${min_y - y_margin} ${fit_width + x_margin * 2} ${
                fit_height + y_margin * 2
              }`;
            },
          );
          layer_item_el.__mp_svg_el.setAttributeNS(null, 'viewBox', view_box);
          layer_item_el.__mp_stroke_sync.sync();

          const f = layer_item_el.__mp_task_queue.shift();
          if (isNil(f)) {
            layer_item_el.__mp_is_processing = false;
            return;
          }
          f();
        };
        layer_item_el.__mp_task_queue.push(f);
        if (layer_item_el.__mp_task_queue.length > 1) {
          layer_item_el.__mp_task_queue.splice(0, layer_item_el.__mp_task_queue.length - 1);
        }
        if (layer_item_el.__mp_is_processing) {
          return;
        }
        return layer_item_el.__mp_task_queue?.shift?.()?.();
      }),
    );
  };

export const handleSingleStickerEditorAttrChange =
  ({ tab_el }) =>
  ({ detail: { el } }) =>
    go(
      tab_el,
      $findAll(`.layer_container .layer_item`),
      filterL((layer_item_el) => equals(el)(layer_item_el.__mp_original_el)),
      each((layer_item_el) => {
        const f = async () => {
          layer_item_el.__mp_is_processing = true;

          if (!isNil(layer_item_el.__mp_clone_el)) {
            layer_item_el.__mp_svg_el.removeChild(layer_item_el.__mp_clone_el);
          }
          layer_item_el.__mp_clone_el = /** @type {SVGGraphicsElement} */ await CommonNS.UtilNS.deepCloneNode(
            layer_item_el.__mp_original_el,
          );
          layer_item_el.__mp_clone_el.removeAttributeNS(null, 'display');
          layer_item_el.__mp_svg_el.appendChild(layer_item_el.__mp_clone_el);

          if (
            go(
              layer_item_el,
              $closest(`.layer_container`),
              (layer_container_el) => equals2('true')(layer_container_el?.dataset?.is_show),
              not,
            )
          ) {
            const f = layer_item_el.__mp_task_queue.shift();
            if (isNil(f)) {
              layer_item_el.__mp_is_processing = false;
              return;
            }
            f();
            return;
          }

          const bbox = layer_item_el.__mp_clone_el.getBBox();
          const matrix =
            CommonNS.UtilNS.getConsolidatedTransformMatrix(layer_item_el.__mp_clone_el) ??
            layer_item_el.__mp_svg_el.createSVGMatrix();
          const view_box = go(
            [
              [bbox.x, bbox.y],
              [bbox.x + bbox.width, bbox.y],
              [bbox.x + bbox.width, bbox.y + bbox.height],
              [bbox.x, bbox.y + bbox.height],
            ],
            mapL(CommonNS.UtilNS.createSVGPoint(layer_item_el.__mp_svg_el)),
            mapL((point) => point.matrixTransform(matrix)),
            (points) =>
              reduce(
                (acc, { x, y }) => {
                  acc.min_x = Math.min(acc.min_x, x);
                  acc.min_y = Math.min(acc.min_y, y);
                  acc.max_x = Math.max(acc.max_x, x);
                  acc.max_y = Math.max(acc.max_y, y);
                  return acc;
                },
                { min_x: Infinity, min_y: Infinity, max_x: -Infinity, max_y: -Infinity },
                points,
              ),
            ({ min_x, min_y, max_x, max_y }) => {
              const fit_width = max_x - min_x;
              const fit_height = max_y - min_y;
              const x_margin = fit_width / 10;
              const y_margin = fit_height / 10;
              return `${min_x - x_margin} ${min_y - y_margin} ${fit_width + x_margin * 2} ${
                fit_height + y_margin * 2
              }`;
            },
          );
          layer_item_el.__mp_svg_el.setAttributeNS(null, 'viewBox', view_box);
          layer_item_el.__mp_stroke_sync.sync();

          const f = layer_item_el.__mp_task_queue.shift();
          if (isNil(f)) {
            layer_item_el.__mp_is_processing = false;
            return;
          }
          f();
        };
        layer_item_el.__mp_task_queue.push(f);
        if (layer_item_el.__mp_task_queue.length > 1) {
          layer_item_el.__mp_task_queue.splice(0, layer_item_el.__mp_task_queue.length - 1);
        }
        if (layer_item_el.__mp_is_processing) {
          return;
        }
        return layer_item_el.__mp_task_queue?.shift?.()?.();
      }),
    );
