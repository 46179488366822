import { html } from 'fxjs/es';
import { MpAddressConstantS } from '../../../Mp/Address/S/Constant/module/MpAddressConstantS.js';

const makeProductDetailPrintAreaKrHtml = () => html`
  <div class="print_area">
    <div class="img1">
      <img
        src="//s3.marpple.co/files/u_218933/2020/1/original/1447427615f02a618208be86c5f98f28ba80d89291cabec.png"
        alt=""
        class="pc"
      />
      <img
        src="//s3.marpple.co/files/u_218933/2020/1/original/1447431a273455f34397ee326029512f66cb1fac2d0b341.png"
        alt=""
        class="m"
      />
    </div>
    <h2>주의사항</h2>
    <ul class="list1">
      <li>
        배경이 투명한 PNG의 경우, CMYK 색상모드로 웹용(Save for Web) 저장해 주세요.<br />배경이 꽉 찬 JPG의
        경우, RGB 색상모드로 저장해 주세요.<br />이미지 크기는 최소 2500~3500px / 해상도는 150dpi 이상으로
        제작해 주세요.
      </li>
      <li>상품마다 이미지 크기가 다르므로, 해당 상품의 이미지 가이드를 확인해 주세요.</li>
      <li>작은 원본 이미지를 사이즈 규격에 맞게 임의로 크게 확대할 경우 인쇄 시 화질이 깨질 수 있습니다.</li>
      <li>상품 실측 사이즈를 반드시 확인해 주세요.</li>
      <li>
        인쇄를 원하시는 위치가 있을 시 [상품 제작 요청사항]에 남겨주시거나, 1:1 상담 또는
        고객센터(help@marpple.com)로 메일 주세요.
      </li>
      <li>모니터, 핸드폰에 따라 실제 인쇄 색상과 다르게 보일 수 있습니다.</li>
    </ul>
    <h2>교환/환불 불가 사항</h2>
    <p class="txt1">
      마플의 모든 상품은 고객 주문에 따라 개별 제작되는 방식으로 단순 변심을 포함, 아래의 경우에는 교환 /
      환불이 불가합니다.
    </p>
    <h3>- 디자인 시안 색상의 차이</h3>
    <p class="txt2">
      프린팅 방식과 원단 재질에 따른 경우의 수가 다양하므로 인쇄 후 모니터, 혹은 종이 출력물과 색상 차이가
      발생할 수 있습니다.
    </p>
    <h3>- 인쇄 위치 및 크기의 차이</h3>
    <p class="txt2">
      제품 재질에 따른 특성의 차이와 대부분의 인쇄가 수작업으로 이루어진다는 점에서 시안과 실제 상품의 인쇄
      위치 및 크기의 오차가 발생할 수 있습니다. 인쇄 위치 및 크기를 별도로 [요청] 하지 않은 주문건에 대한 교환
      또는 환불은 불가합니다.
    </p>
    <h3>- 추가 주문 시 기존 상품 색상과의 컬러 차이</h3>
    <p class="txt2">
      상품 컬러 및 사이즈는 제작 시기별, 생산시즌별로 원단 컬러와 사이즈 차이가 발생할 수 있습니다.
    </p>
    <h3>- 화학 제품 사용으로 인한 손상</h3>
    <p class="txt2">
      우레탄 전사(PU Heat Transfer) 인쇄 제품에 솔벤트와 같은 용해력이 있는 용매를 사용한 향수를 직접적으로
      분사할 경우 인쇄 부분이 손상될 수 있으니 주의해 주세요.
    </p>
    <h2>교환/환불 기간</h2>
    <p class="txt1">
      물품에 문제 되는 사항에 대해서는 수령 후 7일 이내 이메일
      <a href="mailto:help@marpple.com">help@marpple.com</a> 또는 고객센터로 연락주셔야 가능합니다.
    </p>
    <p class="txt1">
      ★ 주문 완료 후 상품 및 이미지 변경을 원하실 경우 [제작준비중] 상태에서만 변경이 가능하며 [제작중]에는
      변경 및 취소가 불가합니다. 변경을 원하시는 경우 1:1 상담 및 카톡 또는 고객센터(<a href="tel:1566-9437"
        >1566-9437</a
      >)를 통해 문의 바랍니다.
    </p>
  </div>
`;

export const makeProductDetailPrintAreaJpHtml = () => html`
  <div class="print_area">
    <div class="img1">
      <img
        src="//s3.marpple.co/files/u_218933/2020/1/original/14474293083d7b1bbdcc69d5fb90ee7d29f3e76f9f415aa.png"
        alt=""
        class="pc"
      />
      <img
        src="//s3.marpple.co/files/u_218933/2020/1/original/144743003103bdb5b54807607ec1295b4b973d09f485672.png"
        alt=""
        class="m"
      />
    </div>
    <h2>注意事項</h2>
    <ul class="list1">
      <li>
        背景が透明なPNGファイルの場合、CMYKカラーモードでウェブ用(Save for Web)にて保存してください。<br />
        背景のあるJPGファイルの場合、RGBカラーモードにて保存してください。<br />
        イメージの大きさは最小2500~3500px / 解像度は150dpi以上で制作してください。
      </li>
      <li>商品によりイメージの大きさが違います。該当商品のイメージガイドをご確認ください。</li>
      <li>
        小さいイメージをサイズ規格に合わせて拡大する場合、プリント時に画質が荒い状態で印刷される場合がございます。
      </li>
      <li>商品のサイズ情報を必ず確認してください。</li>
      <li>
        ご希望のプリント位置がある場合、[商品製作リクエスト]に詳細をご記入いただくか、1:1相談サービスおよび、カスタマーセンターにご連絡ください。
        別途で位置指定が無い場合は、一番普遍的な位置にプリントいたします。
      </li>
      <li>
        スマートフォンやコンピューターのモニターによって実際にプリントされるカラーと多少誤差が生じる場合がございます。
      </li>
    </ul>
    <h2>返品・交換が不可能な場合</h2>
    <p class="txt1">注文制作商品のため、お客様都合および以下の場合の返品・交換はいたしかねます。</p>
    <h3>- カラーの違い</h3>
    <p class="txt2">
      プリント方法や生地により、印刷後の色とモニターの色や紙へ印刷した場合の色とは違いが生じる場合がございます。予めご了承ください。
    </p>
    <h3>- 印刷位置および大きさの違い</h3>
    <p class="txt2">
      生地の特性の違いや、ほとんどの印刷を手作業で行っている関係上、モニター上での位置や大きさと実際に印刷された位置や大きさに多少違いが生じる場合がございます。ご希望の印刷位置および大きさをご要望欄にご記入いただければご指定のとおり印刷いたします。別途で指定がなかった場合は、これらの理由での交換および返品はいたしかねます。
    </p>
    <h3>- 追加注文時の既存商品とのカラーの違い</h3>
    <p class="txt2">生地のカラーおよびサイズは制作時期、生産シーズンごとに多少異なる場合がございます。</p>
    <h3>- 科学製品の使用による損傷</h3>
    <p class="txt2">
      ウレタン転写(PU Heat
      Transfer)で印刷された商品は溶解力のある成分の入った香水などを使用する場合、印刷部分が解けてしまう場合がございます。ご注意ください。
    </p>
    <h2>返品・交換が可能な期間</h2>
    <p class="txt1">商品に問題のある場合は、受け取り後7日以内にお問い合わせいただければご対応いたします。</p>
    <p class="txt1">
      注文完了後、商品およびイメージを変更される場合、[制作準備中]の状態でのみ変更が可能でございます。[制作中]の場合は変更およびキャンセルができませんのでご了承ください。変更をご希望の場合は1:1相談サービスおよびカスタマーセンターにお問い合わせください。
    </p>
  </div>
`;

const makeProductDetailPrintAreaTempKrHtml = () => html`
  <div class="print_area">
    <h2>교환/환불 불가 사항</h2>
    <p class="txt1">
      마플의 모든 상품은 고객 주문에 따라 개별 제작되는 방식으로 단순 변심을 포함, 아래의 경우에는 교환 /
      환불이 불가합니다.
    </p>
    <h3>- 디자인 시안 색상의 차이</h3>
    <p class="txt2">
      프린팅 방식과 원단 재질에 따른 경우의 수가 다양하므로 인쇄 후 모니터, 혹은 종이 출력물과 색상 차이가
      발생할 수 있습니다.
    </p>
    <h3>- 인쇄 위치 및 크기의 차이</h3>
    <p class="txt2">
      제품 재질에 따른 특성의 차이와 대부분의 인쇄가 수작업으로 이루어진다는 점에서 시안과 실제 상품의 인쇄
      위치 및 크기의 오차가 발생할 수 있습니다. 인쇄 위치 및 크기를 별도로 [요청] 하지 않은 주문건에 대한 교환
      또는 환불은 불가합니다.
    </p>
    <h3>- 추가 주문 시 기존 상품 색상과의 컬러 차이</h3>
    <p class="txt2">
      상품 컬러 및 사이즈는 제작 시기별, 생산시즌별로 원단 컬러와 사이즈 차이가 발생할 수 있습니다.
    </p>
    <h3>- 화학 제품 사용으로 인한 손상</h3>
    <p class="txt2">
      우레탄 전사(PU Heat Transfer) 인쇄 제품에 솔벤트와 같은 용해력이 있는 용매를 사용한 향수를 직접적으로
      분사할 경우 인쇄 부분이 손상될 수 있으니 주의해 주세요.
    </p>
    <h2>교환/환불 기간</h2>
    <p class="txt1">
      물품에 문제 되는 사항에 대해서는 수령 후 7일 이내 이메일
      <a href="mailto:help@marpple.com">help@marpple.com</a> 또는 고객센터로 연락주셔야 가능합니다.
    </p>
    <p class="txt1">
      ★ 주문 완료 후 상품 및 이미지 변경을 원하실 경우 [제작준비중] 상태에서만 변경이 가능하며 [제작중]에는
      변경 및 취소가 불가합니다. 변경을 원하시는 경우 1:1 상담 및 카톡 또는 고객센터(<a href="tel:1566-9437"
        >1566-9437</a
      >)를 통해 문의 바랍니다.
    </p>
  </div>
`;

const makeProductDetailPrintAreaTempJpHtml = () => html`
  <div class="print_area">
    <h2>返品・交換が不可能な場合</h2>
    <p class="txt1">注文制作商品のため、お客様都合および以下の場合の返品・交換はいたしかねます。</p>
    <h3>- カラーの違い</h3>
    <p class="txt2">
      プリント方法や生地により、印刷後の色とモニターの色や紙へ印刷した場合の色とは違いが生じる場合がございます。予めご了承ください。
    </p>
    <h3>- 印刷位置および大きさの違い</h3>
    <p class="txt2">
      生地の特性の違いや、ほとんどの印刷を手作業で行っている関係上、モニター上での位置や大きさと実際に印刷された位置や大きさに多少違いが生じる場合がございます。ご希望の印刷位置および大きさをご要望欄にご記入いただければご指定のとおり印刷いたします。別途で指定がなかった場合は、これらの理由での交換および返品はいたしかねます。
    </p>
    <h3>- 追加注文時の既存商品とのカラーの違い</h3>
    <p class="txt2">生地のカラーおよびサイズは制作時期、生産シーズンごとに多少異なる場合がございます。</p>
    <h3>- 科学製品の使用による損傷</h3>
    <p class="txt2">
      ウレタン転写(PU Heat
      Transfer)で印刷された商品は溶解力のある成分の入った香水などを使用する場合、印刷部分が解けてしまう場合がございます。ご注意ください。
    </p>
    <h2>返品・交換が可能な期間</h2>
    <p class="txt1">商品に問題のある場合は、受け取り後7日以内にお問い合わせいただければご対応いたします。</p>
    <p class="txt1">
      注文完了後、商品およびイメージを変更される場合、[制作準備中]の状態でのみ変更が可能でございます。[制作中]の場合は変更およびキャンセルができませんのでご了承ください。変更をご希望の場合は1:1相談サービスおよびカスタマーセンターにお問い合わせください。
    </p>
  </div>
`;

const makeProductDetailDeliveryAreaKrHtml = () => html`
  <div class="delivery_area">
    <ul>
      <li>
        - 제품은 100% 주문 제작으로 만들어지며, 출고 이후에도 택배사의 사정에 따라 변수가 생길 수 있습니다.
      </li>
      <li>
        - 주문 전 1:1 상담 / 전화 상담 등을 통해 제작 일정을 확인하신 후 주문하시면 친절하게 안내해드립니다.
      </li>
      <li>
        - 대량 단체주문건의 경우에는 주문 및 결제 완료 후 영업일 기준으로 약 7~10일 가량 소요될 수 있습니다.
      </li>
    </ul>
    <div class="delivery_table">
      <h2>택배배송</h2>
      <table>
        <tr>
          <th>배송 지역</th>
          <td>CJ대한통운 / 전국지역</td>
        </tr>
        <tr>
          <th>상담 시간</th>
          <td>1588-1255 (평일 9AM-6PM / 토요일 9AM~ 1PM)</td>
        </tr>
        <tr>
          <th>배송비</th>
          <td>3,000원</td>
        </tr>
        <tr>
          <th>배송 기간</th>
          <td>
            택배사로 상품 출고 후 영업일 기준 1~2일 이내 수령 (단, 지역 및 배송사 상황에 따라 달라질 수 있음)
          </td>
        </tr>
      </table>

      <h2>퀵배송</h2>
      <table>
        <tr>
          <th>출발지</th>
          <td>서울시 금천구 가산동</td>
        </tr>
        <tr>
          <th>배송 비용</th>
          <td>
            구간 요금은 30분 ~120분 처리 기준이며 지역, 시간, 부피, 날씨 등에 따라 추가 할증이 붙을 수
            있습니다.<br />
            <span class="price_table">지역별 착불 예상 요금표 보기</span>
          </td>
        </tr>
      </table>

      <h2>방문 수령</h2>
      <table>
        <tr>
          <th>수령 안내</th>
          <td>
            주문 시 "방문수령"을 선택하시면 직접 방문하셔서 수령하실 수 있습니다. 제품이 완성되면 주문 시
            입력한 연락처로 문자메시지를 발송해드립니다.
          </td>
        </tr>
        <tr>
          <th>주소</th>
          <td>
            <span>${MpAddressConstantS.MP_ADDRESS}</span>
            <div class="map">
              <a href="http://naver.me/5qRUQ5oI" target="_blank">
                <img
                  src="//s3.marpple.co/files/u_218933/2021/4/original/7edbcc2936cec7c649fb7c4972fcf268bf14d3481.jpg"
                  style="max-width: 100%;"
                  alt="마플 위치"
                />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <th>오시는길</th>
          <td>
            [지하철 이용 시]<br />
            1, 7호선 가산디지털단지역 9번 출구 맞은편 마을버스 금천 05번 탑승 후 가산한화비즈메트로2차 하차<br />
            1, 7호선 가산디지털단지역 7번 출구로 나와 길건너 800m 도보<br />
            1호선 독산역 2번 출구에서 마을버스 금천 05번 탑승 후 에이스태세라타워 하차<br />
            1호선 독산역 2번 출구로 나와 800m 도보<br /><br />
            [자가용 이용 시] <br />
            아이에스비즈타워 건물 내 지하 주차장 이용
          </td>
        </tr>
        <tr>
          <th>방문 시간</th>
          <td>10:00 ~ 19:00 (주말/공휴일 제외)</td>
        </tr>
        <tr>
          <th>전화</th>
          <td>
            <a href="tel:1566-9437">1566-9437</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
`;

const makeProductDetailFaqKrHtml = () => html`
  <div class="faq_area">
    <ul>
      <li>
        <div class="q">저작권이 있는 이미지 사용이 가능한가요?</div>
        <div class="a">
          마플은 모든 저작권자를 존중합니다. 이미지의 저작권자 또는 원작자가 공공으로 쓸 수 있는 이미지라고
          명시했거나, 저작권이 만료된 이미지에 한해 사용이 가능합니다.
        </div>
      </li>
      <li>
        <div class="q">원하는 디자인을 의뢰할 수 있나요?</div>
        <div class="a">
          마플은 고객 맞춤 디자인을 제공하지 않습니다.<br />
          단, 업로드 한 이미지의 배경 제거 및 로고 컬러 수정 등 원활한 인쇄 작업을 위한 간단한 수정만
          제공합니다.
        </div>
      </li>
      <li>
        <div class="q">배경 제거가 가능한가요?</div>
        <div class="a">
          가능합니다. 주문 시 [배경 지우기] 요청에 체크 후 주문해 주세요.<br />배경 제거가 불가한 경우 별도로
          연락드립니다.
        </div>
      </li>
      <li>
        <div class="q">디자인을 자연스러운 위치에 배치하고 싶어요.</div>
        <div class="a">
          의류 상품은 각 면의 디자인에서 [위치 조정 요청]버튼을 클릭하여 위치 조정을 요청하실 수 있어요. 위치
          조정 요청 시 고객님께서 작업하신 디자인을 상품에 가장 어울리는 위치로 조정해 드립니다. (디자인의
          크기나 색상은 수정하지 않으며, 위치 조정으로 인한 교환/환불/반품은 불가합니다.)
        </div>
      </li>
      <li>
        <div class="q">인쇄 가능한 영역을 벗어났다는 메시지가 확인됩니다.</div>
        <div class="a">
          올려주신 이미지 또는 텍스트가 인쇄 영역보다 크게 삽입되었을 경우 팝업 되는 메시지입니다.<br />
          이미지 또는 텍스트 크기를 줄여서 인쇄 영역 안으로 이미지를 조정해 주세요.
        </div>
      </li>
      <li>
        <div class="q">AI나 PSD 파일로 제작할 수 있나요?</div>
        <div class="a">
          가능합니다. 주문 후 원본 파일을 <a href="mailto:help@marpple.com">help@marpple.com</a>으로
          전송해주시면 원본 파일로 보다 깔끔한 제작이 가능합니다.
        </div>
      </li>
      <li>
        <div class="q">자수로 제작하고 싶어요.</div>
        <div class="a">
          자수의 경우 주문 시 [상품 제작 요청사항] 메모를 남겨 주시면, 디자인 검토 후 가능 여부를 알려드리며,
          추가 비용이 발생할 수 있습니다.
        </div>
      </li>
    </ul>
  </div>
`;

const makeProductDetailFaqJpHtml = () => html`
  <div class="faq_area">
    <ul>
      <li>
        <div class="q">著作権のあるイメージを使用できますか?</div>
        <div class="a">
          著作権の権利を侵害するものはお断りしております。イメージの著作権者または、原作者が公共で使えるイメージだと明示している場合や、イメージの著作権が満了した場合に限り、ご使用いただけます。
        </div>
      </li>
      <li>
        <div class="q">希望のデザインを作ってもらうことはできますか？</div>
        <div class="a">
          デザインをお作りすることはできません。<br />
          しかし、アップロードしていただいたイメージの背景を消したり、ロゴカラーを修正したりするなど、
          円滑なプリント作業のための簡単な修正は可能です。
        </div>
      </li>
      <li>
        <div class="q">背景を消すことはできますか?</div>
        <div class="a">
          はい、可能です。注文する際、[背景を消す] にチェックし、ご注文してください。<br />背景を消すことができない場合もございますので、その場合はこちらからご連絡いたします。
        </div>
      </li>
      <li>
        <div class="q">デザインを自然な位置に配置したいです。</div>
        <div class="a">
          アパレル商品は各面のデザインから【位置調節要請】ボタンをクリックして位置調節を依頼することができます。
          位置調節をご希望の場合、お客様が作業されたデザインを商品に最も似合う位置に調節いたします。
          （デザインの大きさやカラーは修正せず、位置調節による交換/返品/払い戻しはできかねます。）
        </div>
      </li>
      <li>
        <div class="q">プリント可能な領域を超えたというメッセージが出てきました。</div>
        <div class="a">
          アップロードしていただいたイメージまたは、テキストがプリント領域を超えてしまっています。<br />
          イメージまたは、テキストのサイズを小さくし、プリント領域の中に納まるように調節してください。
        </div>
      </li>
      <li>
        <div class="q">AIやPSDファイルで作成できますか？</div>
        <div class="a">
          はい、可能です。ご注文後、ファイルの原本を<a href="mailto:cs@marpple.com">cs@marpple.com</a
          >に送っていただければ、よりきれいな作成が可能です。
        </div>
      </li>
      <li>
        <div class="q">刺繍で作成したいです。</div>
        <div class="a">
          刺繍の場合、ご注文の際に、
          [商品製作リクエスト]にその旨を記入していただければ、デザイン検討後、可能かどうかご連絡いたします。但し、追加料金が発生する場合もございますのでご注意ください。
        </div>
      </li>
    </ul>
  </div>
`;

const makeProductDetailGuidAreaKrHtml = () => html`
  <div class="guide_area">
    <h2>품질보증기준</h2>
    <ul class="list1">
      <li>
        - 본 제품은 철저한 품질관리와 공정관리를 거쳐 생산되었으며 외관, 규격, 물성검사에서 합격한 제품입니다.
      </li>
      <li>
        - 본 제품의 수명을 연장시키기 위하여 제품에 부착된 취급주의사항과 세탁방법을 필히 확인해주시기
        바랍니다.
      </li>
      <li>- 본 제품의 품질에 이상이 있을 경우 소비자 상담실로 연락 주시기 바랍니다.</li>
      <li>- 본 제품의 품질에 이상이 있을 경우 제품 수령일로부터 7일 이내 반품/환불 가능합니다.</li>
    </ul>
    <h2>청약철회안내</h2>
    <h3>- 가능한 경우</h3>
    <p class="txt1">
      제품에 문제가 있거나, 판매자의 실수로 인해 교환, 반품이 필요한 경우 (제품 불량인 경우 동일 상품 / 동일
      사이즈 / 동일한 색상으로 교환하거나 환불)
    </p>
    <h3>- 불가능한 경우</h3>
    <ul class="list2">
      <li>주문 제작 상품인 경우</li>
      <li>
        착용한 흔적이 남아있거나 세탁을 한 경우, 오염이나 특정한 향이 배어 있는 경우, 임의로 수선하신 경우,
        상품이 훼손 된 경우 (다만, 제품 확인을 위해 포장을 열어본 경우에는 청약철회를 할 수 있습니다.)
      </li>
      <li>시간의 경과에 의하여 재판매가 곤란할 정도로 상품의 가치가 현저히 감소한 경우</li>
    </ul>
    <p class="txt2">
      고객센터로 교환 및 반품 접수하신 후 7일 이내 제품이 도착해야 합니다. 단순 변심에 의한 교환 및 반품은
      고객이 택배비용을 전액 부담합니다.<br />
      (반품시에는 CJ택배를 이용해 주세요. 다른 택배사를 이용하실 때는 택배비용을 선불로 보내주세요)
    </p>
    <p class="txt3">소비자 피해보상 처리 및 상품 문의처: 고객센터 <a href="tel:1566-9437">1566-9437</a></p>
  </div>
`;

const defaultBpPrintGuideKrJp = ({ id }) => html`
  <div class="toggle_cont tg_print" is_open="true">
    ${[
      4398, 4399, 4400, 4401, 4402, 4436, 4437, 4438, 4439, 4440, 4441, 4442, 4443, 4444, 4445, 4446, 4447,
      4448, 4449, 4450, 4451, 4452, 4498, 4553, 4554, 4555, 4556,
    ].includes(id)
      ? html`
          <div class="tg_head">
            <span class="tit">${T('product_detail::교환 및 환불 안내')}</span>
            <span class="btn"></span>
          </div>
          <div class="tg_body">
            ${(() => {
              if (T.lang == 'kr') return makeProductDetailPrintAreaTempKrHtml();
              else if (T.lang == 'jp') return makeProductDetailPrintAreaTempJpHtml();
              else return '';
            })()}
          </div>
        `
      : html`
          <div class="tg_head">
            <span class="tit">${T('product_detail::인쇄 관련 사항')}</span>
            <span class="btn"></span>
          </div>
          <div class="tg_body">
            ${(() => {
              if (T.lang == 'kr') return makeProductDetailPrintAreaKrHtml();
              else if (T.lang == 'jp') return makeProductDetailPrintAreaJpHtml();
              else return '';
            })()}
          </div>
        `}
  </div>
  <div class="toggle_cont tg_faq" is_open="false">
    <div class="tg_head">
      <span class="tit">${T('product_detail::자주 묻는 질문')}</span>
      <span class="btn"></span>
    </div>
    <div class="tg_body">
      ${(() => {
        if (T.lang == 'kr') return makeProductDetailFaqKrHtml();
        else if (T.lang == 'jp') return makeProductDetailFaqJpHtml();
        else return '';
      })()}
    </div>
  </div>
  ${T.lang == 'kr'
    ? html`
        <div class="toggle_cont tg_delivery" is_open="false">
          <div class="tg_head">
            <span class="tit">${T('product_detail::배송안내')}</span>
            <span class="btn"></span>
          </div>
          <div class="tg_body">${makeProductDetailDeliveryAreaKrHtml()}</div>
        </div>
        <div class="toggle_cont tg_guide" is_open="false">
          <div class="tg_head">
            <span class="tit">품질보증/청약철회 안내</span>
            <span class="btn"></span>
          </div>
          <div class="tg_body">${makeProductDetailGuidAreaKrHtml()}</div>
        </div>
      `
    : ''}
  ${T.lang == 'kr'
    ? html`
        <div class="bp_info_pop_buttons">
          <span class="print">인쇄 관련 사항</span><span class="faq">자주 묻는 질문</span
          ><span class="delivery">배송안내</span><span class="guide">품질보증/청약철회 안내</span>
        </div>
      `
    : ''}
`;

const defaultPrintGuideEn = html`
  <div class="toggle_cont tg_guide_en" is_open="true">
    <div class="tg_head">
      <span class="tit">Exchange / Return(Refund) Policy</span>
      <span class="btn"></span>
    </div>
    <div class="tg_body">
      <p>
        All of Marpple’s products are personalized products required by customers. Therefore, we do not accept
        returns for refund or exchange, at the exception of items damaged in transit.<br />
        To be eligible for a refund or exchange, you must first email us within 7 days of receiving the
        products at cs@marpple.com with pictures of the damaged items.
      </p>
      <p>
        Please note that design edits are only available at the stage of 'Order Placed'. During the
        ‘Processing’ stage, any design changes or cancellation is not available. If you need design changes
        after your order please contact us through our chat system or via email.
      </p>
    </div>
  </div>
`;

export const defaultPrintGuide = ({ id }) =>
  T.lang === 'en' ? defaultPrintGuideEn : defaultBpPrintGuideKrJp({ id });
