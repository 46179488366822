import $dataStr from 'fxdom/es/dataStr.js';
import { html, strMap } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';
import { OMPCoreCellTmplS } from '../../../Core/Cell/S/Tmpl/module/OMPCoreCellTmplS.js';
import { OMPProductListConstantS } from '../Constant/module/OMPProductListConstantS.js';
import { cateSelectorBody } from './cate_selector.js';
import { checkedFilterItemsWithReset } from './fn.js';

export const moCateSelect = ({ checked_cate_list, checked_cate_item }) => {
  const tt_all_cate_item = TT('product::list::list_9_1');
  return html`<div class="omp-product-list__cate-lists">
    <div class="omp-mo-product-header__select">
      <button
        type="button"
        data-cate_list_id="${checked_cate_list.id}"
        data-cate_item_id="${checked_cate_item?.id || ''}"
        class="omp-mo-product-header__checked-cate-list omp-product-list__checked-cate-list"
      >
        <span
          class="omp-mo-product-header__cate-item omp-product-list__cate-item--checked"
          data-id="${checked_cate_item?.id}"
        >
          ${checked_cate_item?.name || `${checked_cate_list.name} ${tt_all_cate_item}`}
        </span>
        <span class="omp-mo-product-header__checked-cate-list-arrow"
          >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
        >
      </button>
    </div>
  </div>`;
};

export const moListBody = (bps) => {
  if (bps.length) {
    return html`<div class="omp-product-list__list">${strMap(OMPCoreCellTmplS.productMo, bps)}</div>`;
  } else {
    const tt_no_list = TT('product::list::list_17_1');
    return html`<div class="omp-product-list__no-list">${tt_no_list}</div>`;
  }
};
export const moList = ({
  bps,
  cate_lists,
  checked_cate_list,
  checked_cate_item,
  query,
  bp_filters,
  checked_filter_items,
}) => {
  const tt_filter = TT('product::list::list_16_1');
  const sort_list = OMPProductListConstantS.SORT_LIST().map((data) => {
    return {
      ...data,
      checked: data.id == query.sort,
    };
  });
  const checked_sort = sort_list.find(({ checked }) => checked);
  return html` <div class="omp-product-list">
    <div class="omp-mo-product-header">
      <div class="omp-mo-product-header__top">
        <div class="omp-product-list__flex-space-between">
          ${moCateSelect({ checked_cate_list, checked_cate_item })}
          <div class="omp-product-list__flex-space-between">
            <button class="omp-mo-product-header__filter-open-btn">
              ${tt_filter}
              <span
                class="omp-mo-product-header__filter-count opm-product-list-checked-filter-count"
                data-count="0"
                >(${bp_filters?.total_count || 0})</span
              >
            </button>
            <div class="omp-mo-product-header__sort">
              <div class="omp-mo-product-header__checked-sort">
                <label class="omp-mo-product-header__checked-sort-name">
                  <span class="omp-product-list__checked-sort-name" data-id="${checked_sort.id}"
                    >${checked_sort.name}</span
                  >
                  <span class="omp-mo-product-header__checked-sort-arrow"
                    >${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}</span
                  >
                  <select class="omp-mo-product-header__sort-selector">
                    ${strMap((sort) => {
                      return html`
                        <option
                          class="omp-mo-product-header__sort-item"
                          data-id="${sort.id}"
                          data-name="${sort.name}"
                          value="${sort.id}"
                          ${checked_sort.id == sort.id ? ' selected' : ''}
                        >
                          ${sort.name}
                        </option>
                      `;
                    }, sort_list)}
                  </select>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="omp-product-cate-selector">
        <div class="omp-product-cate-selector__cate-lists">
          <div class="omp-product-cate-selector__cate-list-wrapper">
            ${cateSelectorBody({
              cate_lists,
              checked_cate_list_id: checked_cate_list?.id,
              checked_cate_item_id: checked_cate_item?.id,
            })}
          </div>
        </div>
      </div>
      <div
        class="omp-product-list__checked-filter-items opm-product-list-checked-filter-items"
        data-list_show="${checked_filter_items.length > 0}"
        ${bp_filters ? `data-fx-json="${$dataStr({ bp_filters })}"` : ''}
      >
        ${checkedFilterItemsWithReset(checked_filter_items)}
      </div>
    </div>
    <div
      class="omp-product-list__body opm-product-list-bps"
      data-filter_show="${checked_filter_items.length > 0}"
    >
      ${moListBody(bps)}
    </div>
  </div>`;
};
