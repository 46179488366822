import { $closest, $data, $delegate, $el, $find, $replaceWith } from 'fxdom/es';
import { go, html } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { getColumnLength } from '../../../ImageDropBox/F/Function/imagedropbox.js';
import { NewMakerComponentsImageDropBoxF } from '../../../ImageDropBox/F/Function/module/NewMakerComponentsImageDropBoxF.js';
import { NewMakerComponentsImageSelectFrameMuiF } from '../Mui/module/NewMakerComponentsImageSelectFrameMuiF.js';

export const openImageSelectorFrame = async (data, selectFunc) => {
  return MuiF.openFrame(NewMakerComponentsImageSelectFrameMuiF.frame, async (frame, page, [tab]) => {
    const column_length = await getColumnLength(data.options[0].img);
    const height =
      column_length === 3
        ? data.options.length <= 3
          ? 244
          : 364
        : column_length === 2
        ? data.options.length <= 2
          ? 288
          : 454
        : undefined;
    frame.animation = true;
    frame.prev_frame_show = true;
    frame.always_remove = true;
    frame.height = height + G.mp.frame.v_height;
    frame.header_height = G.mp.frame.v_height;
    frame.hide_frame_button_type = 'V';
    frame.appended = $.frame_close_bg;

    page.custom_header = () => {
      return html` <div class="maker-image-selector__head">
        ${data.option_group_name}
        <button class="done">확인</button>
      </div>`;
    };

    tab.makeData = async () => {
      return { ...data, column_length, height };
    };
    frame.removed = () => $.body_fixed(false);
    page.appended = (tab_el) => {
      $.body_fixed(true);
      go(
        tab_el,
        $delegate('click', '.paper_option', ({ currentTarget }) => {
          const selector = go(currentTarget, $closest('.maker-image-selector'));
          const { column_length, option_height } = selector.dataset;
          go(
            selector,
            $find('.maker-image-selector__options'),
            $replaceWith(
              $el(
                NewMakerComponentsImageDropBoxF.imageSelectedOptions({
                  selected_bp_option_id: currentTarget.dataset.id,
                  options: $data(selector),
                  column_length,
                  height: option_height,
                }),
              ),
            ),
          );
        }),
        $delegate('click', '.done', ({ currentTarget }) => {
          const selected_bp_option_id = go(currentTarget, $closest('.don_wrapper'), $find('.selected_option'))
            ?.dataset?.id;
          selectFunc(selected_bp_option_id);
          MuiF.closeFrame();
        }),
      );
    };
  });
};
