import { go, html, sel, some, strMap } from 'fxjs/es';
import { $closest } from 'fxdom/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { invertDarkColorToWhite } from '../F/util.js';

export function makeImgWrapperTmpl(url) {
  return legacyHtml`
    <div class="img_wrapper" data-has_img="${!!url}">
      <img class="neo_border" style="padding:0" src="${url || ''}" alt="" />
      <div class="no_image neo_border">이미지 없음</div>
    </div>
  `;
}
export function uploadTmpl({ url, class_name, key }, buttonName = '업로드', accept_MIMEs) {
  return legacyHtml`
    <div class="img_input_wrapper body ${class_name}" data-has_img="${!!url}">
      <div class="img_wrapper neo_border">
        <img style="padding:0" src="${url || ''}" alt="" />
        <div class="no_image neo_border">이미지 없음</div>
        <input type="file" style="display:none;" key="${key}" accept="${accept_MIMEs || `image/*`}" />
      </div>
      <div class="option">
        <button type="button" class="upload btn">${buttonName}</button>
        <button type="button" class="delete btn">삭제</button>
      </div>
    </div>
  `;
}

export function downTmpl({ url, class_name, download_name }, buttonName = '다운로드') {
  return legacyHtml`
    <div class="img_input_wrapper ${class_name}" data-has_img="${!!url}">
      <div class="img_wrapper neo_border">
        <img style="padding:0" src="${url || ''}" alt="" />
        <div class="no_image neo_border">이미지 없음</div>
      </div>
      <div class="option">
      </div>
    </div>
  `;
}

function frameWrapper(body) {
  return legacyHtml`
    <div class="main">
      <div class="wrapper">${body}</div>
      <div class="option">
        <button class="prev" type="button"></button>
        <button class="next" type="button"></button>
      </div>
    </div>
  `;
}

export const composite_template_bg_step = (composite_template) =>
  frameWrapper(legacyHtml`
    <div class="composite_template" _sel="composite_template">
      <div class="body">
        <div class="row">
        <div class="head">합성 타이틀</div>
        <div class="title content neo_border">
          <div class="body">
            <input
              type="text"
              class="text_input"
              name="title"
              value="${composite_template.title || ''}"
              placeholder="합성 타이틀을 입력하세요"
            />
          </div>
          <div class="option">
            <button type="button" class="save btn">저장</button>
          </div>
        </div>
        </div>
        <div class="row">
        <div class="head">합성 이미지</div>
        <div class="bg_url content neo_border">
          <div class="body">
            ${makeImgWrapperTmpl(composite_template.bg_url)}
            <input type="file" style="display:none;" accept="image/png" />
          </div>
          <div class="option">
            <button type="button" class="upload btn">업로드</button>
            <button type="button" class="save btn">저장</button>
          </div>
        </div>
        </div>
        <div class="row" style="display: none;">
        <div class="head">참고 이미지</div>
        <div class="reference_url content neo_border">
          <div class="body">
            ${makeImgWrapperTmpl(composite_template.reference_url)}
            <input type="file" style="display:none;" accept="image/png" />
          </div>
          <div class="option">
            <button type="button" class="upload btn">업로드</button>
            <button type="button" class="save btn">저장</button>
          </div>
        </div>
        </div>
        <div class="row">
        <div class="head">합성 타입</div>
        <div class="type content neo_border">
          <div class="body">
            <select name="type" class="text_input">
              <option value="etc" ${composite_template.type == 'etc' ? 'selected' : ''}>선택 없음</option>
              <option value="thumbnail" ${composite_template.type == 'thumbnail' ? 'selected' : ''}>
                썸네일
              </option>
              <option value="preview" ${composite_template.type == 'preview' ? 'selected' : ''}>
                마플 미리보기
              </option>
            </select>
            <button type="button" class="make_composite_texture btn" style="display:inline-block; margin-left:10px;">색상 합성 제품 직접 올리기</button>
          </div>
          <div class="option">
            <button type="button" class="save btn">저장</button>
          </div>
        </div>
        </div>
        <div class="row">
        <div class="head"></div>
        <div class="is_cond_or content neo_border">
          <div class="body">
            <label for="ct_is_cond_or" style="padding-right:32px;">1개면이라도 디자인 있으면 나오도록</label>
            <input id="ct_is_cond_or" type="checkbox" name="is_cond_or" ${
              composite_template.is_cond_or ? 'checked' : ''
            }>            
          </div>
          <div class="option">
            <button type="button" class="save btn">저장</button>
          </div>
        </div>
        </div>
      </div>
    </div>
  `);

// style="background-color:${sel('color_code', composite_mask._.base_product_color) || '#ffffff'};"
export const makeColorCodeHtml = (composite_mask) => legacyHtml`
  <select
    ${composite_mask._.base_product._.base_product_colors.length === 1 ? 'disabled' : ''}
    class="base_product_colors_color_code text_input"
    style="background-color:${
      sel('color_code', composite_mask._.base_product_color) || '#ffffff'
    }; color:${invertDarkColorToWhite(
  sel('color_code', composite_mask._.base_product_color) || '#ffffff',
  0.35,
)}"
  >
    <option value="">색상선택</option>
    ${strMap(
      (bpc) => legacyHtml`
        <option
          value="${bpc.id}"
          data-id="${bpc.id}"
          data-color_code="${bpc.color_code}"
          data-abs_color_code="${bpc.abs_color_code}"
          ${bpc.is_thumb ? 'selected' : ''}
        >
          ${bpc.color_code}&nbsp:&nbsp${bpc.name}
        </option>
      `,
      composite_mask._.base_product._.base_product_colors,
    )}
  </select>
`;

export const makeCompositeMasksStepHtml = (assoc_composite_template) =>
  frameWrapper(legacyHtml`
    <div
      class="assoc_composite_templates composite_masks_set is_column_style"
      _sel="assoc_composite_template"
    >
      <div class="heads">
        <label class="head">제품명</label>
        <label class="head" style="display:none;">기본색상</label>
        <label class="head for_mask">색상마스크</label>
        <label class="head for_melange" style="display: none;">멜란지패턴</label>
      </div>
      <div class="list body ${assoc_composite_template._.composite_masks.length > 2 ? 'need_scroll' : ''}">
        ${strMap(
          (composite_mask) => legacyHtml`
            <div class="composite_mask" _sel="./_->composite_masks->(#${composite_mask.id})">
              <div class="base_product_name content neo_border">${composite_mask._.base_product.name}</div>
              <div class="base_product_colors content neo_border" style="display:none;">
                ${makeColorCodeHtml(composite_mask)}
              </div>
              <div class="mask_url content neo_border">
                ${uploadTmpl(
                  { url: composite_mask.mask_url, class_name: 'mask_url', key: 'mask_url' },
                  '마스크 업로드',
                )}
              </div>
              <div class="product_url content neo_border">
                ${uploadTmpl(
                  { url: composite_mask.product_url, class_name: 'product_url', key: 'product_url' },
                  '제품 이미지(임시)',
                )}
              </div>
              <div class="product_url content neo_border">
                ${uploadTmpl(
                  {
                    url: composite_mask.design_shade_url,
                    class_name: 'design_shade_url',
                    key: 'design_shade_url',
                  },
                  '디자인 음영',
                )}
              </div>
              <div class="composite_textures">
                ${strMap(makeCompositeTexturesHtml, composite_mask._.composite_textures)}
              </div>
            </div>
          `,
          assoc_composite_template._.composite_masks,
        )}
      </div>
    </div>
  `);
function makeCompositeTexturesHtml(composite_texture) {
  return legacyHtml`
    <div class="composite_texture content" _sel="./_->composite_textures->(#${composite_texture.id})">
      <div class="head">
        <div class="title neo_border">${composite_texture._.base_product_color.name}</div>
        <div class="option">
          <button class="btn delete" type="button" key="is_hidden">삭제</button>
        </div>
      </div>
      <div class="wrapper neo_border">
        <div>
          ${downTmpl(
            {
              url: composite_texture._.base_product_color.seamless_texture_url,
              class_name: 'texture_pattern_download',
              download_name: `texture_${composite_texture.id}`,
            },
            '원단 패턴 받기',
          )}
        </div>
        ${uploadTmpl(
          { url: composite_texture.texture_url, class_name: 'composite_pattern_upload', key: 'texture_url' },
          '텍스처 업로드',
        )}
      </div>
      <div class="wrapper neo_border">
        ${uploadTmpl(
          { url: composite_texture.image_url, class_name: 'image_url_upload', key: 'image_url' },
          '상품 이미지 업로드',
        )}
      </div>
    </div>
  `;
}
function makeBpfsHtml(base_product_faces, selected_base_product_faces) {
  return legacyHtml`
    <div class="base_product_faces neo_border">
      ${strMap((bpf) => {
        const is_checked = some((cf) => cf.base_product_face_id === bpf.id, selected_base_product_faces);
        return legacyHtml`
          <div class="wrapper" is_checked="${is_checked}">
            <input type="checkbox" id="${bpf.id}" value="${bpf.id}" ${is_checked ? 'checked disabled' : ''} />
            <label for="${bpf.id}">${bpf.name}</label>
          </div>
        `;
      }, base_product_faces)}
    </div>
  `;
}

function _design_position(design_position) {
  return legacyHtml`
    <div class="design_position">
      <label for="" style="display: none;">Design Position</label>
      <form>
        <label for=''>x</label>
        <input type='number' name='x' style='width:80px;margin-right:16px;'>
        <label for=''>y</label>
        <input type='number' name='y' style='width:80px;margin-right:16px;'>
        <label for=''>width</label>
        <input type='number' name='width' style='width:80px;margin-right:16px;'>
        <label for=''>height</label>
        <input type='number' name='height' style='width:80px;margin-right:16px;'>
        <button type='button' class='position_apply'>적용</button>
      </form>
      <table>
        <thead>
          <tr>
            <th>Location</th>
            <th>X</th>
            <th>Y</th>
          </tr>
        </thead>
        <tbody>
          <tr coord_name="tl">
            <td>TL</td>
            <td>
              <input type="number" name="x" value="${sel('tl.x', design_position)}" />
            </td>
            <td>
              <input type="number" name="y" value="${sel('tl.y', design_position)}" />
            </td>
          </tr>
          <tr coord_name="tr">
            <td>TR</td>
            <td>
              <input type="number" name="x" value="${sel('tr.x', design_position)}" />
            </td>
            <td>
              <input type="number" name="y" value="${sel('tr.y', design_position)}" />
            </td>
          </tr>
          <tr coord_name="bl">
            <td>BL</td>
            <td>
              <input type="number" name="x" value="${sel('bl.x', design_position)}" />
            </td>
            <td>
              <input type="number" name="y" value="${sel('bl.y', design_position)}" />
            </td>
          </tr>
          <tr coord_name="br">
            <td>BR</td>
            <td>
              <input type="number" name="x" value="${sel('br.x', design_position)}" />
            </td>
            <td>
              <input type="number" name="y" value="${sel('br.y', design_position)}" />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="location_btns">
        <button type="button" class="temp_save btn">임시저장</button>
        <button type="button" class="temp_load btn">임시로드</button>
        <button type="button" class="copy btn">값복제</button>
        <button type="button" class="server_save btn">서버저장</button>
      </div>
    </div>
  `;
}

function composite_faces(composite_faces, map_raw_url) {
  return legacyHtml`
    <div class="composite_faces">
      ${strMap(
        (cf) => legacyHtml`
          <div class="composite_face neo_border" _sel="./_->composite_faces->(#${cf.id})">
            <div class="body">
              <div class="face_info neo_border">
                <div class="head">합성 위치 [${cf._.base_product_face.name}]</div>
                <div class="img_wrapper neo_border">
                  <img style="padding:0" src="${cf._.base_product_face.url || ''}" alt="" />
                  <div class="no_image neo_border">이미지 없음</div>
                </div>
                <div style="margin:0 40%; width:100%;">
                  <button type="button" class="mockup_download neo_border" style="border:0;">목업 다운</button>
                </div>
                ${_design_position(cf.design_position)}
              </div>
              <div class="neo_border etc_meta">
                <div class="head">기타 필요한 경우(ex-마스킹 테이프, 머그컵)</div>
                <textarea name="etc_meta" id="" cols="100" rows="2">${
                  cf.etc_meta ? JSON.stringify(cf.etc_meta) : ''
                }</textarea>
                <div class="option" style='display:flex; width: 200px; justify-content: space-around; margin-top: 10px;'>
                  <button type="button" class="etc_meta_save btn">저장</button>
                </div>
              </div>
              <div class="map_upload_download map_distort_url neo_border">
                <div class="head">합성 맵</div>
                <div class="map_download">
                  ${downTmpl(
                    {
                      url: map_raw_url,
                      class_name: 'map_download',
                      download_name: `map_raw_${cf._.base_product_face.name}_${cf._.base_product_face.base_product_id}`,
                    },
                    '맵 다운로드',
                  )}
                </div>
                <div class="map_upload">
                  ${uploadTmpl(
                    { url: cf.map_distort_url, class_name: 'map_upload', key: 'map_distort_url' },
                    '맵 업로드',
                    ['image/png'],
                  )}
                </div>
              </div>
            </div>
          </div>
        `,
        composite_faces,
      )}
    </div>
  `;
}

export function makeCompositeMaskHtml(composite_mask, bg_url, map_raw_url) {
  const has_composite_faces = composite_mask._.composite_faces.length >= 1;
  return legacyHtml`
    <div
      class="composite_mask"
      has_composite_faces="${has_composite_faces}"
      _sel="assoc_composite_template->_->composite_masks->(#${composite_mask.id})"
    >
      <div class="title neo_border">제품명: ${composite_mask._.base_product.name}</div>
      <div class="bg_url_masked" style="display:none">
        ${makeImgWithMaskHtml(bg_url, composite_mask.mask_transparent_url)}
      </div>
      <div class="body">
        <div class="heads">
          <div class="head">합성면 선택</div>
        </div>
        <div class="body">${composite_faces(composite_mask._.composite_faces, map_raw_url)}</div>
      </div>
    </div>
  `;
}

export function renderCompositeMaskBpfsHtml(target, composite_mask, assoc_composite_template) {
  go(
    target,
    $closest('.composite_mask'),
    (el) => (el.outerHTML = makeCompositeMaskBpfsHtml(composite_mask, assoc_composite_template.bg_url)),
  );
}

function makeSelectBpfIdHtml(base_product_faces, selected_bpf_id, cm_id) {
  return legacyHtml`
    <select name="base_product_face_id#${cm_id}" id="">
      <option value="">선택없음</option>
      ${strMap((bpf) => {
        return legacyHtml`
          <option value="${bpf.id}" ${bpf.id == selected_bpf_id ? 'selected' : ''}>${bpf.name}</option>
        `;
      }, base_product_faces)}
    </select>
  `;
}

function makeImgWithMaskHtml(bg_url, mask_url) {
  if (mask_url) {
    return html`
      <div class="img_wrapper with_mask neo_border">
        <img
          src="${bg_url ? bg_url + '?mask=1' : ''}"
          crossorigin="anonymous"
          alt=""
          style="
              mask-image: url(${mask_url});
              -webkit-mask-image: url(${mask_url});"
        />
      </div>
    `;
  }
  return html`
    <div class="img_wrapper with_mask neo_border">
      <img src="${bg_url ? bg_url + '?mask=1' : ''}" crossorigin="anonymous" />
    </div>
  `;
}
/*is_shade_composite_ignore 변경시*/
export function makeCompositeMaskBpfsHtml(composite_mask, bg_url) {
  const has_composite_faces = composite_mask._.composite_faces.length >= 1;
  return legacyHtml`
    <div
      class="composite_mask"
      has_composite_faces="${has_composite_faces}"
      _sel="assoc_composite_template->_->composite_masks->(#${composite_mask.id})"
    >
      <div class="title neo_border">${composite_mask._.base_product.name}</div>
      <div class="bg_url_masked">${makeImgWithMaskHtml(bg_url, composite_mask.mask_transparent_url)}</div>
      <div
        class="select_thumb_bpfs body"
        composite_mask_bpf_id_selected="${!!composite_mask.base_product_face_id}"
        style="display: none;"
      >
        ${makeSelectBpfIdHtml(
          composite_mask._.base_product._.base_product_faces,
          composite_mask.base_product_face_id,
          composite_mask.id,
        )}
      </div>
      <div class="select_bpfs body neo_border" has_composite_faces="${has_composite_faces}">
        ${makeBpfsHtml(composite_mask._.base_product._.base_product_faces, composite_mask._.composite_faces)}
        <div class="option">
          <button type="button" class="apply btn">적용</button>
          <button type="button" class="revise btn">수정</button>
        </div>
      </div>
      <div class="body neo_border" style="display: none; grid-template-columns: 50% 50%; justify-items: center;">
        <label for="">순서</label>
        <input type="number" class="composite_mask_no" value="${
          composite_mask.no
        }" style="width:100%; text-align: center;">
      </div>
      <div class="body neo_border" style="display: none; grid-template-columns: 50% 50%; justify-items: center;">
        <label for="">음영 합성 안하기</label>
        <input type="checkbox" ${
          composite_mask.is_shade_composite_ignore ? 'checked' : ''
        } class="composite_mask_is_shade_composite_ignore">
      </div>
    </div>
  `;
}

export const composite_faces_step = (assoc_composite_template) =>
  frameWrapper(legacyHtml`
    <div class="composite_result"></div>
    <div class="composite_masks_list">
      ${strMap(
        (composite_mask) =>
          makeCompositeMaskHtml(
            composite_mask,
            assoc_composite_template.bg_url,
            assoc_composite_template.map_raw_url,
          ),
        assoc_composite_template._.composite_masks,
      )}
    </div>
  `);

export const composite_faces_select_step = (assoc_composite_template) =>
  frameWrapper(legacyHtml`
    <div class="composite_masks_select_bpfs" type="${assoc_composite_template.type}">
      <div class="heads">
        <label for="" class="head">제품명</label>
        <label for="" class="head bg_url_masked_head">합성면 이미지</label>
        <label for="" class="head select_bpfs_head">합성면 선택</label>
        <label for="" class="head select_thumb_bpfs_head" style="display: none;"
          >대표면(보이는 면 중 대표)선택</label
        >
      </div>
      <div class="list">
        ${strMap(
          (composite_mask) => makeCompositeMaskBpfsHtml(composite_mask, assoc_composite_template.bg_url),
          assoc_composite_template._.composite_masks,
        )}
      </div>
    </div>
  `);

export const composite_template_map_step = ({ assoc_composite_template }) =>
  frameWrapper(legacyHtml`
    <div class="map just_image_upload_download">
      <div class="wrapper">
        <div class="map_raw_url content">
          <div class="head">합성 맵 [raw]</div>
          ${downTmpl({
            url: assoc_composite_template.map_raw_url,
            class_name: 'map_raw_url',
            download_name: '맵',
          })}
        </div>
        <div class="map_distort_url content">
          <div class="head">합성 맵 [distort]</div>
          ${uploadTmpl({
            url: assoc_composite_template.map_distort_url,
            class_name: 'map_distort_url',
            key: 'map_distort_url',
          })}
        </div>
      </div>
    </div>
  `);

export const composite_template_other_url_step = (accos_composite_template) =>
  frameWrapper(legacyHtml`
    <div class="other_urls just_image_upload_download">
      <div class="wrapper">
        <div class="design_mask_url content">
          <div class="head">디자인 마스크</div>
          ${uploadTmpl({
            url: accos_composite_template.design_mask_url,
            class_name: 'design_mask_url',
            key: 'design_mask_url',
          })}
        </div>
      </div>
    </div>
  `);
export function makeCompositeResultHtml(composite_result, composite_mask, composite_template) {
  return legacyHtml`
    <div class="composite_result row neo_border">
      <div
        class="base_product_colors composite_mask color_code neo_border"
        _sel="./_->composite_masks->(#${composite_mask.id})"
        style="display:flex; justify-content:center"
      >
        ${makeColorCodeHtml(composite_mask)}
        <div class="option">
          <button class="open_maker btn" type="button" style="height:40px; border-radius:11px">
            메이커 열기
          </button>
          <button class="default_mockup btn" type="button" style="height:40px; border-radius:11px">
            격자 패턴 테스트
          </button>
          <button class="edit_cylinder btn" type="button" style="height:40px; border-radius:11px">
            (만지면 안되용)
          </button>
          <a
            class="download_btn low_res btn"
            download_name="합성테스트_템플릿#${composite_mask.composite_template_id}_${
    composite_mask._.base_product.name
  }_#${composite_mask.base_product_id}"
            href="#"
            style="height:40px; border-radius:11px"
            >테스트 이미지 다운</a
          >
          <div class="composite_toggle" hidden>
            고품질 합성 모드 켜기
            <div class="toggle_switch">
              <input class="toggle_state" type="checkbox" name="composite_hq" />
              <div class="indicator"></div>
            </div>
          </div>
        </div>
      </div>
          <div class="row neo_border etc_meta_wrapper" style="display:none;">
          <div class="head">기타 메타 정보(만지면 안되용.. y2 = upside * 1.5 )</div>
          ${go(
            composite_mask._.composite_faces,
            strMap(
              (cf) =>
                html`
                  <form class="etc_meta cylinder_info">
                    <label for="">percent:</label>
                    <input type="number" name="percent" value="${cf.etc_meta?.percent}" step="0.005" />
                    <label for="">up_side:</label>
                    <input type="number" name="up_side" value="${cf.etc_meta?.up_side}" step="0.01" />
                    <label for="">down_side:</label>
                    <input type="number" name="down_side" value="${cf.etc_meta?.down_side}" step="0.01" />
                    <label for="">top:</label>
                    <input type="number" name="top" value="${cf.etc_meta?.top}" />
                    <label for="">left:</label>
                    <input type="number" name="left" value="${cf.etc_meta?.left}" />
                    <label for="">width:</label>
                    <input type="number" name="width" value="${cf.etc_meta?.width}" />
                    <label for="">height:</label>
                    <input type="number" name="height" value="${cf.etc_meta?.height || 0}" />
                    <label for="">angle_v2:</label>
                    <input type="number" name="angle_v2" value="${cf.etc_meta?.angle_v2}" step="0.1" />
                    <label for="">x1_perspective:</label>
                    <input
                      type="number"
                      name="x1_perspective"
                      value="${cf.etc_meta?.x1_perspective}"
                      step="0.001"
                    />
                    <label for="">y1_perspective:</label>
                    <input
                      type="number"
                      name="y1_perspective"
                      value="${cf.etc_meta?.y1_perspective}"
                      step="0.001"
                    />
                    <label for="">x2_perspective:</label>
                    <input
                      type="number"
                      name="x2_perspective"
                      value="${cf.etc_meta?.x2_perspective}"
                      step="0.001"
                    />
                    <label for="">y2_perspective:</label>
                    <input
                      type="number"
                      name="y2_perspective"
                      value="${cf.etc_meta?.y2_perspective}"
                      step="0.001"
                    />
                    <label for="">x3_perspective:</label>
                    <input
                      type="number"
                      name="x3_perspective"
                      value="${cf.etc_meta?.x3_perspective}"
                      step="0.001"
                    />
                    <label for="">y3_perspective:</label>
                    <input
                      type="number"
                      name="y3_perspective"
                      value="${cf.etc_meta?.y3_perspective}"
                      step="0.001"
                    />
                    <label for="">x4_perspective:</label>
                    <input
                      type="number"
                      name="x4_perspective"
                      value="${cf.etc_meta?.x4_perspective}"
                      step="0.001"
                    />
                    <label for="">y4_perspective:</label>
                    <input
                      type="number"
                      name="y4_perspective"
                      value="${cf.etc_meta?.y4_perspective}"
                      step="0.001"
                    />
                    <textarea type="number" name="reflection_meta" style="width:100%;">
${JSON.stringify(cf.etc_meta?.reflection_meta || {})}</textarea
                    >
                    <div style="display: flex;">
                      <button type="button" class="etc_meta_apply btn">적용</button>
                      <button type="button" class="etc_meta_save btn">저장</button>
                    </div>
                  </form>
                  <form class="shade_maker" style="width:100%;">
                    <label for="">arr:</label>
                    <textarea name="arr" style="width:100%;">
${JSON.stringify([
                        {
                          offset: 0,
                          strength: 220,
                        },
                        {
                          offset: 0.3,
                          strength: 255,
                        },
                        {
                          offset: 1,
                          strength: 160,
                        },
                      ])}</textarea
                    >
                    <div style="display: flex;">
                      <button type="button" class="shade_maker_apply btn">적용</button>
                      <button type="button" class="shade_maker_save btn">업로드</button>
                    </div>
                  </form>
                `,
            ),
          )}
          </div>
      <div
        class="result_url"
        _sel="./_->composite_result"
        data-composite_template_id="${composite_mask.composite_template_id}"
      >
        <canvas></canvas>
        ${
          composite_template.reference_url
            ? html`<img src="${composite_template.reference_url}" alt="" />`
            : ''
        }
      </div>
    </div>
  `;
}
export const composite_result_step = (composite_template) => {
  const { left, top, width, height } = composite_template.crop_ratio || {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  };
  const crop_left = left * composite_template.width;
  const crop_top = top * composite_template.height;
  const crop_width = width * composite_template.width;
  const crop_height = height * composite_template.height;
  return frameWrapper(legacyHtml`
    <div class="step_6">
      <div class="wrapper">
        <div class="composite_template_result assoc_composite_template" _sel="assoc_composite_template">
          ${makeCompositeResultHtml(
            composite_template._.composite_result,
            composite_template._.composite_masks[0],
            composite_template,
          )}
          <div class="process_shade_url neo_border row">
            <button class="create_shade_url_btn btn" type="button" style="height:40px; border-radius:11px">재질 다루기</button>
          </div>    
          <div class="top_layer_url_wrapper content neo_border">
                ${uploadTmpl(
                  {
                    url: composite_template.top_layer_url,
                    class_name: 'top_layer_url',
                    key: 'top_layer_url',
                  },
                  '최상단 레이어 업로드',
                )}
              </div>
<form id="crop_form" class="neo_border">
            <div class="head">썸네일 크롭 정보</div>
            <!--            <fieldset>-->
            <!--              <legend>썸네일 이미지 CROP (px) 정보 입력</legend>-->
            <div class="crop_inputs text_input">
              <input type="" hidden name="id" value="${composite_template.id}" />
              <input type="" hidden name="width" value="${composite_template.width}" />
              <input type="" hidden name="height" value="${composite_template.height}" />
              <label for="crop_x">x </label>
              <input
                class="text_input"
                type="number"
                name="crop_left"
                placeholder="px"
                value="${Math.round(crop_left)}"
              />
              <label for="crop_y">y </label>
              <input
                class="text_input"
                type="number"
                name="crop_top"
                placeholder="px"
                value="${Math.round(crop_top)}"
              />
              <label for="crop_size">w </label>
              <input
                class="text_input"
                type="number"
                name="crop_width"
                placeholder="px"
                value="${Math.round(crop_width)}"
              />
              <label for="crop_size">h </label>
              <input
                class="text_input"
                type="number"
                name="crop_height"
                placeholder="px"
                value="${Math.round(crop_height)}"
              />
            </div>
            <div class="button">
              <button type="button" id="crop_submit" class="btn">저장</button>
            </div>
            <!--            </fieldset>-->
          </form>

          <div class="row is_done neo_border">
            <div class="head">합성 템플릿 완성 여부</div>
            <input
              type="radio"
              id="is_done_true"
              ${composite_template.is_done ? 'checked' : ''}
              name="is_done"
              value="true"
            />
            <label for="is_done_true">완성</label>
            <input
              type="radio"
              id="is_done_false"
              ${composite_template.is_done ? '' : 'checked'}
              name="is_done"
              value="false"
            />
            <label for="is_done_false">미완성</label>
          </div>
        </div>
      </div>
    </div>
  `);
};
