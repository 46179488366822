import { every, go } from 'fxjs/es';

export const needPrintImageUrl = {
  validate: (product) => {
    return go(
      product.product_faces2.value,
      every(({ print_image_url }) => {
        return print_image_url;
      }),
    );
  },
};
