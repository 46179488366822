import { go, sel } from 'fxjs/es';
import { $addClass, $delegate, $find, $qs, $setText } from 'fxdom/es';
import { enterSvgApplication } from './enterToVector.js';
import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { BpOptionUploadTypeS } from '../../../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { OMPCoreUtilF } from '../../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { getProductColorInMaker } from '../../../../Maker/F/getSth.js';
import { isLoginCheck } from '../../../../User/Login/F/fs.js';
import { setTempLocalStorageProduct } from '../../../../Maker/F/mp_maker.js';

const alert = ({ title, body, ok }) => {
  const prom = $.alert(`${title ? `<div class="title">${title}</div>` : ``}
      ${body ? `<div class="popup-body">${body}</div>` : ``}`);
  const el = $qs('.don_dialog_msg');
  go(el, $addClass('mshop-pop-up-style-1'));
  if (ok) {
    go(el, $find('.ok'), $setText(ok));
  }
  return prom;
};
export function vectorEditorOptionsEvent(tab_el) {
  go(
    tab_el,
    $delegate('click', '#to_vector_editor', async () => {
      if (G.collabo_type === '' && !isLoginCheck()) {
        // const bp_option_ids = getSelectedBpOptionIds(getProductColorInMaker());
        // redirect_url.searchParams.set('bp_option_ids', bp_option_ids);
        const product_color = box.sel('maker->product_color');
        setTempLocalStorageProduct(product_color);

        const redirect_url = new URL(location.href);
        redirect_url.searchParams.set('login_redirect', 'true');
        const login_url = new URL(`${location.origin}/${T.lang}/login`);
        login_url.searchParams.set('url', redirect_url.pathname + redirect_url.search + redirect_url.hash);
        location.href = login_url.toString();
        $.don_loader_start();
        setTimeout(() => {
          $.don_loader_end();
        }, 5000);
        return;
      }

      const mp_maker = $qs('#maker_frame');
      if (
        UtilF.isLegacyMobile() &&
        BpOptionUploadTypeS.UPLOAD_TYPE.isDirectType(getProductColorInMaker()) &&
        mp_maker.dataset.upload_type_direct_is_designed === 'true'
      ) {
        return BpOptionUploadTypeS.UPLOAD_TYPE.notAvailableSvgEnterIfUploadedAlert(
          G.collabo_type === 'creator' ? alert : OMPCoreUtilF.alert,
        );
      }
      const bp_option_groups_el = $qs('.bp_option_groups');
      if (bp_option_groups_el) {
        const isNotNormal = BpOptionF.isVirtualBpOptionNotNormal(bp_option_groups_el);
        if (isNotNormal) {
          await go(
            OMPCoreUtilF.alert({
              content: T(
                'maker_error_alert::We are very sorry, but there is a problem with loading the chosen design. It would be appreciated if you redesign the item and order again.',
              ),
            }),
            () => (window.location.search = `?bp_id=${box()?.maker?.product_color?.base_product_id}`),
          );
        }
      }
      const time_per_1000_px = 100;
      const duration = (Math.abs($.scroll_top(window) - 0) / 1000) * time_per_1000_px;
      await anime({
        targets: 'html, body',
        scrollTop: 0,
        duration,
        easing: 'easeInOutCubic',
      }).finished;
      await enterSvgApplication({
        tab_el,
        svg_url:
          sel('_.svg_product_materials.0.original_svg_file_url')(box().maker.product_color) ||
          sel('_.svg_product._.svg_product_pages.0._.svg_image.url')(box().maker.product_color),
      });
    }),
  );
}
