import { $findAll } from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { activate, deactivate } from './is_active.js';
import { show, hide } from './is_show.js';

export const showBottomMenuBlankPlate = (tab_el) => {
  go(tab_el, $findAll(`.blank_plate[data-target="bottom_menu"]`), each(show));
};

export const hideBottomMenuBlankPlate = (tab_el) => {
  go(tab_el, $findAll(`.blank_plate[data-target="bottom_menu"]`), each(hide));
};

export const activateBottomMenus = (tab_el) => {
  go(tab_el, $findAll('.bottom_menu'), each(activate));
};

export const deactivateBottomMenus = (tab_el) => {
  go(tab_el, $findAll('.bottom_menu'), each(deactivate));
};

export const showBottomMenus = (tab_el) => {
  go(tab_el, $findAll('.bottom_menu'), each(show));
};

export const hideBottomMenus = (tab_el) => {
  go(tab_el, $findAll('.bottom_menu'), each(hide));
};
