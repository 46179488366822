export default function (lo) {
  const el_editor = $('#simple_groups_data_editor');

  function to_data() {
    return {
      id: parseInt($.val($.find1(el_editor, '[name="id"]'))),
      simple_group_id: parseInt($.val($.find1(el_editor, '[name="simple_group_id"]'))),
      data: _p.go(
        el_editor,
        $.find('[name]:not([name="id"]):not([name="simple_group_id"])'),
        _p.map((el) => {
          let val = el.type == 'checkbox' ? el.checked : $.val(el);
          val = _p.is_numeric(val) ? parseInt(val) : val;
          return [$.attr(el, 'name'), val];
        }),
        _p.object,
      ),
    };
  }

  _p.go(
    el_editor,
    $.on('click', '.create', async function () {
      await $.post('/@api/simple_groups_data/create', _p.omit(to_data(), 'id'));
      history.back();
    }),
    $.on('click', '.edit', async function () {
      await $.put('/@api/simple_groups_data/edit', to_data());
      history.back();
    }),
    $.on('click', '.remove', async function () {
      await $.del('/@api/simple_groups_data/remove', _p.pick(to_data(), 'id'));
      history.back();
    }),
  );
}
