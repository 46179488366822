export const findFilterList = (bp_filters, { filter_list_id }) => {
  const { brand_filter, color_filter, size_filter, filter_lists } = bp_filters;
  const fls = [brand_filter, color_filter, size_filter, ...filter_lists];
  return {
    fl: fls.find(({ id }) => {
      return id == filter_list_id;
    }),
  };
};

export const findFilterItem = (bp_filters, { filter_list_id, id: filter_item_id }) => {
  const { fl } = findFilterList(bp_filters, { filter_list_id });
  if (!fl) {
    return {};
  }

  const fi = fl._.filter_items.find(({ id }) => {
    return id == filter_item_id;
  });
  return {
    fl,
    fi,
  };
};
