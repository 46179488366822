import { equals2 } from 'fxjs/es';
import { VectorEditorSettingBackgroundPCF } from '../../../../../SettingBackground/PC/F/Function/module/VectorEditorSettingBackgroundPCF.js';

export const handleRightPanelHomeColorSelectorNotEtcClick =
  ({ tab_el }) =>
  ({ el, color_code }) => {
    tab_el.__mp_free_sticker_editor?.setBackground({
      type: 'color',
      fill: color_code,
      opacity: 1,
    });
    VectorEditorSettingBackgroundPCF.setColor({ el, color_code });
    tab_el.__mp_background_color = color_code;
  };

export const handleRightPanelHomeColorSelectorEtcClick = ({ tab_el }) => ({
  f1: ({ el }) => {
    if (!equals2(tab_el.__mp_background_color)('none')) {
      return tab_el.__mp_background_color;
    }

    const color_code = '#ffffff';
    tab_el.__mp_free_sticker_editor?.setBackground({
      type: 'color',
      fill: color_code,
      opacity: 1,
    });
    VectorEditorSettingBackgroundPCF.setColor({ el, color_code });
    tab_el.__mp_background_color = color_code;
    return color_code;
  },
  f2: ({ el, color_code }) => {
    tab_el.__mp_free_sticker_editor?.setBackground({
      type: 'color',
      fill: color_code,
      opacity: 1,
    });
    VectorEditorSettingBackgroundPCF.setColor({ el, color_code });
    tab_el.__mp_background_color = color_code;
  },
});
