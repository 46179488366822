import { CommonNS } from '@marpple/sticker-editor';
import { equals2 } from 'fxjs/es';
import { SVGEditorAttrS } from '../../../../../../SVGEditor/Attr/S/Function/module/SVGEditorAttrS.js';
import { SVGEditorFontConstS } from '../../../../../../SVGEditor/Font/S/Const/module/SVGEditorFontConstS.js';

export const makeNewText = (single_sticker_editor) => {
  const text_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'text');
  text_el.innerHTML = equals2(T.lang)('kr') ? '텍스트' : 'Text';
  single_sticker_editor.addEl(text_el);
  single_sticker_editor.scaleWithinArtBoard({ el: text_el, scale: 0.4 });
  const els = [text_el];
  single_sticker_editor.alignToHorizontalCenter(els);
  single_sticker_editor.alignToVerticalCenter(els);
  SVGEditorAttrS.set(SVGEditorFontConstS.DEFAULT_FONT_STYLE, text_el, single_sticker_editor);
  single_sticker_editor.selectEls(els);
};

export const makeNewPath = (single_sticker_editor) => {
  const path_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'path');
  path_el.setAttributeNS(null, 'fill', 'none');
  path_el.setAttributeNS(null, 'stroke', '#000000');
  path_el.setAttributeNS(null, 'd', 'M 0 0 C 50 100 100 100 150 0 C 200 -100 250 -100 300 0');
  path_el.setAttributeNS(null, 'opacity', '1');

  single_sticker_editor.addEl(path_el);
  single_sticker_editor.scaleWithinArtBoard({ el: path_el, scale: 0.4 });
  const els = [path_el];
  single_sticker_editor.alignToHorizontalCenter(els);
  single_sticker_editor.alignToVerticalCenter(els);
  single_sticker_editor.setStrokeWidth({ el: path_el, stroke_width: 1 });
  single_sticker_editor.setStrokeDasharray({ el: path_el, stroke_dasharray: [0, 0] });
  single_sticker_editor.selectEls(els);
};
