import { once } from 'fxjs/es';
import { AdScriptCriteoMpConstantF } from '../Constant/module/AdScriptCriteoMpConstantF.js';

export const addCart = once((items) => {
  if (!window.criteo_q || T.lang != 'kr') return;
  window.criteo_q.push(
    ...AdScriptCriteoMpConstantF.eventSet({
      event: 'viewBasket',
      item: items,
    }),
  );
});
