import { every } from 'fxjs/es';
import { rowTo_en } from '../../Util/S/LangAndCollaboType.js';

export const needAdditionalPayment = function (projection) {
  const _en = rowTo_en(projection);
  return (
    projection['amount' + _en] > 0 &&
    (!projection._.projection_payments.length || every((pp) => pp.is_paid, projection._.projection_payments))
  );
};
