import { go, map, extend, tap, last, each, pick, omit } from 'fxjs/es';
import { BANNER_FUNCTIONS } from '../S/constant.js';
export const defnBannerItemRoute = () =>
  go(
    $1('#banner_items'),
    $.on('click', '.editor_wrap button.add.editor', function (e) {
      const value = $.attr(e.currentTarget, 'banner_value');
      const { banner_items, collabo_type, lang, path, is_line } = box.sel('banner');
      banner_items[value] = banner_items[value] || [];
      go(
        $.post('/@api/banner_item', {
          value,
          collabo_type,
          lang,
          path,
          no: banner_items[value].length,
        }),
        (banner_item) => banner_items[value].push(banner_item),
        () => BANNER_FUNCTIONS[value](banner_items[value], is_line),
        $.el,
        _p.wrap_arr,
        tap((els) => $.html($.find1($.closest(e.currentTarget, '.banners'), 'ul.banner_items'), els)),
        last,
        (el) => $.scroll_top2(window, $.offset(el).top - 300, 500),
      );
    }),
    $.on('click', '.editor_wrap button.put.editor', function (e) {
      const el_banner_item = $.closest(e.currentTarget, '.banner_item');
      go(
        el_banner_item,
        filterBlur,
        $.find('.data_attr'),
        map((el_data_attr) => [$.attr(el_data_attr, 'name'), $.val(el_data_attr) || null]),
        Object.fromEntries,
        (body) => $.put('/@api/banner_item/' + box.sel(el_banner_item).id, body),
        (banner_item) => extend(box.sel(el_banner_item), banner_item),
      );
    }),
    $.on('click', '.editor_wrap button.delete.editor', function (e) {
      if (confirm('삭제하시겠습니까?'))
        go($.del('/@api/banner_item', box.sel(e.currentTarget)), () => location.reload());
    }),
    $.on('input', '.banner_item input[type="file"]', function (e) {
      const banner_item = window.box.sel(e.currentTarget);
      const el_src = $.closest(e.currentTarget, '.src');
      const key = $.attr(el_src, 'key');
      $.don_loader_start();
      return go(
        $.upload(e.currentTarget, {
          data: { attached_type: 'banner_items', attached_id: banner_item.id },
        }),
        function (file) {
          banner_item[key] = file.url;
          $.val($.find1(el_src, `input.data_attr[name="${key}"]`), file.url);
          $.attr($.find1(el_src, '.src_tag'), 'src', file.url);
          $.attr($.closest(el_src, '.pc'), 'video_show', !!banner_item.src_video);
          $.don_loader_end();
        },
      );
    }),
    $.on('click', '.src_wrap .src_del', function (e) {
      if (!confirm('삭제하시겠습니까?')) return;
      const banner_item = window.box.sel(e.currentTarget);
      const el_src = $.closest(e.currentTarget, '.src');
      const key = $.attr(el_src, 'key');
      banner_item[key] = null;
      $.val($.find1(el_src, `input.data_attr[name="${key}"]`), void 0);
      $.attr($.find1(el_src, '.src_tag'), 'src', void 0);
      $.attr($.closest(el_src, '.pc'), 'video_show', !!banner_item.src_video);
    }),
    $.on('click', '.copy_to_lang', function (e) {
      if (!confirm('복사하시겠습니까?')) return;
      const lang = $.val(e.currentTarget);
      go(
        box.sel(e.currentTarget),
        omit(['id', 'is_public', 'user_id', 'no', 'created_at', 'updated_at', 'title', 'sub_title', 'lang']),
        pick([
          'href',
          'src_mobile',
          'src_pc',
          'src_video',
          'path',
          'collabo_type',
          'value',
          'bg_color',
          'title_color',
        ]),
        (body) => $.post('/@api/banner_item', extend(body, { lang, no: -1 })),
        () => alert(lang + ' 페이지로 이동합니다.'),
        () => (location.href = location.pathname + '?lang=' + lang),
      );
    }),
    tap(function (el) {
      $.remove($.find(el, `button.copy_to_lang[value=${box.sel('banner->lang')}]`));
    }),
    $.find('ul.banner_items'),
    each((ul_banner_items) =>
      Sortable.create(ul_banner_items, {
        handle: '.handle',
        onUpdate({ from }) {
          let no = 0;
          go(
            from,
            filterBlur,
            $.find('li.banner_item'),
            map((el_b) => extend(window.box.sel(el_b), { no: no++ })),
            tap((banner_items) => window.box.set(from, banner_items)),
            (banner_items) => $.put('/@api/banner_item_sort', { banner_items }),
          );
        },
      }),
    ),
  );
