export const guards = {
  /* @description - 널 체크 -> 에러 */
  nullCheck: (items, msg) => {
    if (items == null) throw new Error(`Error(null check) ${msg ?? ''}`);
    if (Array.isArray(items)) {
      items.forEach((item) => {
        if (item == null) throw new Error(`Error(null check) ${msg ?? ''}`);
      });
    }
  },
};
