import { $find, $setOuterHTML } from 'fxdom/es';
import { OMPMyPageWishTmplS } from '../../S/Tmpl/module/OMPMyPageWishTmplS.js';

/**
 * @param {HTMLElement} el
 * @param {OmpUserCount} user_count
 */
export const updateMyWishTabCount = (el, user_count) => {
  const $wish_tabs = $find('.omp-my-wish__tabs', el);
  if (!$wish_tabs) {
    return;
  }
  const active_tab = $find('.omp-my-wish__tab--active', $wish_tabs).dataset.tab;
  const tab_count = {
    buy_later: user_count.wish,
    wish_base_products: user_count.wish_products,
  };
  $setOuterHTML(OMPMyPageWishTmplS.myPageWishTabTmpl(active_tab, tab_count), $wish_tabs);
};
