export function isEmptyInCanvas(imageData) {
  let has_sth = false;
  for (let x = 0; x < imageData.width * imageData.height; ++x) {
    if (imageData.data[x * 4 + 3] !== 0) {
      has_sth = true;
      break;
    }
  }
  return !has_sth;
}
