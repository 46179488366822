import { $addClass, $find, $findAll, $removeClass, $setHTML } from 'fxdom/es';
import { each, equals, equals2, go, isNil, rejectL } from 'fxjs/es';

export const setColor = ({ color_selector_el, color_code }) => {
  const color_el =
    $find(`.color[data-color_code="${color_code}"]`)(color_selector_el) ??
    $find(`.color[data-is_etc="true"]`)(color_selector_el);
  if (isNil(color_el)) {
    return;
  }

  $addClass('active')(color_el);
  go(color_selector_el, $findAll('.color'), rejectL(equals(color_el)), each($removeClass('active')));
  go(
    color_selector_el,
    $findAll(`.color_selector_label`),
    each(
      $setHTML(
        equals2(color_el.dataset.is_etc)('true') ? color_code.toUpperCase() : color_el.dataset.color_name,
      ),
    ),
  );
};
