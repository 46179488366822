import { $closest, $data, $find, $findAll } from 'fxdom/es';
import { getProjectionFormattedLabelData, printProjectionLabel } from '../../Projection/List/F/label.js';
import { task_status_kr } from '../S/constant.js';
import { go, map, reverse } from 'fxjs/es';

export const printLabelCheckpoint = async (e) => {
  const ct = e.currentTarget;
  const data = $data(ct);
  const label_data = getProjectionFormattedLabelData(data.id, data);
  await printProjectionLabel([label_data]);
};

export const printMultipleLabelsCheckpoint = async (e) => {
  const ct = e.currentTarget;
  const status = ct.id;
  const task_charge_name = go(ct, $closest('.task_worker'), $find('.header h3')).textContent;
  const label_data_arr = go(
    ct,
    $closest('table'),
    $findAll(`tr[t_status=${status}]`),
    map((el) => {
      const data_el = $find('td.label_print_btn', el);
      const data = $data(data_el);
      return getProjectionFormattedLabelData(data.id, data);
    }),
    reverse,
  );
  const task_len = label_data_arr.length;
  if (!task_len) {
    $.alert(
      `${task_charge_name ? `${task_charge_name} 님<br>` : ''}라벨을 출력할<br>[${
        task_status_kr[status]
      }] 태스크가 없습니다.`,
    );
  } else {
    if (
      await $.confirm(
        `${task_charge_name ? `${task_charge_name} 님<br>` : ''}[${
          task_status_kr[status]
        }] 태스크 ${task_len}개<br>라벨을 출력하시겠어요?`,
      )
    ) {
      await printProjectionLabel(label_data_arr);
    }
  }
};
