export const EVAL_RESULT_NAMES = {
  success: { dev: 'success', kr: '전달' },
  fail: { dev: 'fail', kr: '대기' },
  cancel: { dev: 'cancel', kr: '취소' },
  success_to_fail: { dev: 'success_to_fail', kr: '전달 >> 대기' },
  success_to_cancel: { dev: 'success_to_cancel', kr: '전달 >> 취소' },
  fail_to_cancel: { dev: 'fail_to_cancel', kr: '대기 >> 취소' },
  fail_to_success: { dev: 'fail_to_success', kr: '대기 >> 전달' },
  cancel_to_fail: { dev: 'cancel_to_fail', kr: '취소 >> 대기' },
  cancel_to_success: { dev: 'cancel_to_success', kr: '취소 >> 전달' },
  success_to_success: { dev: 'success_to_success', kr: '전달!!' },
  fail_to_fail: { dev: 'fail_to_fail', kr: '대기!!' },
  cancel_to_cancel: { dev: 'cancel_to_cancel', kr: '취소!!' },
};
