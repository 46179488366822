import { create } from './create.js';
import { getCvObj } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { cv_type } from '../../S/Constant/cvbackground.js';
import { go } from 'fxjs/es';

export const add = (fcanvas) => {
  const last_cv_obj = getCvObj(fcanvas._objects, cv_type);
  if (last_cv_obj) fcanvas.remove(last_cv_obj);
  return go(
    create({
      fill: 'rgba(0,0,0,0)',
      top: 0,
      left: 0,
      width: G.mp.maker.CANVAS_WIDTH,
      height: G.mp.maker.CANVAS_HEIGHT,
    }),
    G.mp.maker.nscreen,
    (cv_obj) => {
      fcanvas.add(cv_obj);
      return cv_obj;
    },
  );
};
