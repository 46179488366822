import { chunkL, concatL, go, html, join, mapL, rangeL, takeL } from 'fxjs/es';

export const makeColorSelectorHtml = ({ colors = [] } = {}) => html`
  <div class="color_selector">
    <span class="color_selector_label">${T('modules::VectorEditor::Sticker::obj::배경 색상')}</span>
    <div class="color_grid">
      ${go(
        colors,
        mapL(({ color_code, color_name, border, background_color, background_image, is_etc }) =>
          go(
            [
              ['border', border],
              ['background-color', background_color],
              ['background-image', background_image],
            ],
            mapL(([k, v]) => (v ? `${k}: ${v};` : '')),
            join(' '),
            (style) => html`
              <div
                class="color"
                data-color_code="${color_code}"
                data-color_name="${color_name}"
                data-is_etc="${is_etc}"
              >
                <div class="color_outer"></div>
                <div class="color_inner" style="${style.trim()}"></div>
              </div>
            `,
          ),
        ),
        chunkL(10),
        mapL((iter) =>
          go(
            rangeL(Infinity),
            mapL(() => html`<div class="color_placeholder"></div>`),
            concatL(iter),
            takeL(10),
          ),
        ),
        mapL(join('\n')),
        mapL((s) => html`<div class="color_line">${s}</div>`),
        join('\n'),
      )}
    </div>
  </div>
`;
