import { $findAll, $on } from 'fxdom/es';
import { each, eachL, equals2, flatMapL, go, isNil, mapL, reduce, some } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSingleMobileF } from '../../../../Single/Mobile/F/Function/module/VectorEditorStickerSingleMobileF.js';
import { VectorEditorStickerGridMobileMuiF } from '../Mui/module/VectorEditorStickerGridMobileMuiF.js';

export const makeSticker = ({ title, art_board_size: { width, height }, grids, preProcess, postProcess }) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorStickerGridMobileMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = async (frame_el, data) => {
        await frame_closed.call(frame, frame_el, data);

        return resolve(data);
      };

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_preProcess = preProcess;
        tab_el.__mp_postProcess = postProcess;
        return tab_appending.call(tab, tab_el);
      };

      const tab_appended = tab.appended;
      tab.appended = async (tab_el) => {
        const return_val = await tab_appended.call(tab, tab_el);

        go(
          tab_el,
          $findAll('.header .title'),
          each((el) => (el.innerHTML = title)),
        );

        tab_el.__mp_grid_sticker_editor?.setArtBoardSize({ width, height });

        const ignore_els = go(
          grids,
          eachL(
            ({
              id,
              x,
              y,
              width,
              height,
              empty_grid_el,
              existing_grid_el,
              copy_destination_unselected_empty_grid_el,
              copy_destination_unselected_existing_grid_el,
              copy_destination_selected_grid_el,
              foreground_el,
            }) => {
              $on('click', async () => {
                const { fill: background_fill, opacity: background_opacity } = (() => {
                  const background_vo = tab_el.__mp_grid_sticker_editor?.getBackground?.();
                  if (isNil(background_vo)) {
                    return { fill: 'none', opacity: 1 };
                  }
                  const { value } = background_vo;
                  if (isNil(value)) {
                    return { fill: 'none', opacity: 1 };
                  }
                  const { type, fill, opacity } = value;
                  if (!equals2(type)('color')) {
                    return { fill: 'none', opacity: 1 };
                  }
                  return { fill, opacity };
                })();
                const target_image_el = await (async () => {
                  tab_el.__mp_is_prevent_mouse_move = false;
                  try {
                    return await VectorEditorStickerSingleMobileF.makeSticker({
                      art_board_size: { width, height },
                      background: { fill: background_fill, opacity: background_opacity },
                      foreground_el,
                      postProcess: async (single_sticker_editor) => {
                        if (equals2(single_sticker_editor.getGraphicsEls().size)(0)) {
                          const error = new Error('the sticker is empty.');
                          error.__mp_alert_message = T(
                            'modules::VectorEditor::Sticker::message::빈 스티커입니다. 스티커를 만들어주세요 :)',
                          );
                          throw error;
                        }

                        $.don_loader_start();
                        try {
                          const { with_background_el: svg_el } =
                            (await single_sticker_editor?.exportEditor({
                              factor: 1,
                              container_el: document.body,
                            })) ?? {};
                          return svg_el;
                        } finally {
                          $.don_loader_end();
                        }
                      },
                      is_need_cutting_line: false,
                      can_set_background: true,
                    });
                  } finally {
                    tab_el.__mp_is_prevent_mouse_move = true;
                  }
                })();
                if (target_image_el == null) {
                  return;
                }

                target_image_el.setAttributeNS(null, 'x', `${x}`);
                target_image_el.setAttributeNS(null, 'y', `${y}`);

                tab_el.__mp_grid_sticker_editor?.setTargetImage({
                  id,
                  target_image_el,
                });
                tab_el.__mp_grid_sticker_editor?.selectGrid(id);
              })(empty_grid_el);

              $on('click', () => tab_el.__mp_grid_sticker_editor.selectGrid(id))(existing_grid_el);

              each($on('click', () => tab_el.__mp_grid_sticker_editor.selectCopyDestination(id)))([
                copy_destination_unselected_empty_grid_el,
                copy_destination_unselected_existing_grid_el,
              ]);
              $on('click', () => tab_el.__mp_grid_sticker_editor.unselectCopyDestination(id))(
                copy_destination_selected_grid_el,
              );
            },
          ),
          eachL((grid) => tab_el.__mp_grid_sticker_editor?.addGrid(grid)),
          flatMapL(
            ({
              existing_grid_el,
              empty_grid_el,
              copy_destination_unselected_empty_grid_el,
              copy_destination_unselected_existing_grid_el,
              copy_destination_selected_grid_el,
            }) => [
              existing_grid_el,
              empty_grid_el,
              copy_destination_unselected_empty_grid_el,
              copy_destination_unselected_existing_grid_el,
              copy_destination_selected_grid_el,
            ],
          ),
          (iter) =>
            reduce(
              (s, el) => {
                s.add(el);
                return s;
              },
              new Set(),
              iter,
            ),
        );
        go(
          tab_el,
          $findAll('.editor_container'),
          each(
            $on('click', ({ target: target_el }) => {
              if (some((el) => el.contains(target_el))(ignore_els)) {
                return;
              }
              tab_el.__mp_grid_sticker_editor.selectGrid(null);
              tab_el.__mp_selected_grid_id = null;
            }),
          ),
        );
        tab_el.__mp_grids = go(
          grids,
          mapL((grid) => [grid.id, grid]),
          (entries) => new Map(entries),
        );

        await preProcess(tab_el.__mp_grid_sticker_editor);

        return return_val;
      };

      const tab_showed = tab.showed;
      tab.showed = async (tab_el) => {
        await tab_showed.call(tab, tab_el);

        tab_el.__mp_grid_sticker_editor?.fitSize();
        const view_box_vo = tab_el.__mp_grid_sticker_editor?.calculateViewBoxFitToArtBoard({
          padding_in_vcs: { top: 16 + 48, right: 16, bottom: 16 + 144, left: 16 },
        });
        tab_el.__mp_grid_sticker_editor?.setViewBox(view_box_vo);
      };
    }).catch(reject),
  );
