import { $delegate } from 'fxdom/es';
import { find, go, reduce, pipe, sel } from 'fxjs/es';
import { frameOpenReviewDetail } from '../../../../Review/Detail/F/fs.js';
import axios from 'axios';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { makeApiUrl } from '../../../../Util/S/Function/util.js';
function validateUserDetailForm(form) {
  return find($.find(form, 'input:not([type="checkbox"])'), function (el) {
    if (el.name == 'name' && el.value.length < 2) return el.value.length < 2;
    else if (el.name == 'password') return el.value && el.value.length < 6;
    else if (el.name == 'email') return !G.is_email(el.value);
  });
}

const submitUserDetail = (url, func) =>
  function (e) {
    e.preventDefault();
    if (validateUserDetailForm(e.currentTarget)) return $.alert('빈칸 또는 규칙을 확인해 주세요');
    return go(
      $.find(e.currentTarget, 'input'),
      function (inputs) {
        const select = $.find1(e.currentTarget, 'select');
        if (select) inputs.push(select);
        return inputs;
      },
      (inputs) =>
        reduce(
          function (mem, el_query) {
            const val = el_query.type == 'checkbox' ? el_query.checked : $.val(el_query);
            mem[el_query.getAttribute('name')] = val;
            return mem;
          },
          {},
          inputs,
        ),
      (body) => $.post(url, body),
      function (r) {
        return r.id
          ? func()
          : $.alert(r.msg || '가입 불가. 중복된 이메일인지 다른 이상이 있는지 체크해 주세요.');
      },
    );
  };

export const defnDfUserDetailTab = () => ({
  tab_name: 'df.user.detail',
  appended: pipe(
    $.on(
      'submit',
      'form.update',
      submitUserDetail(
        '/@api/df/user',
        pipe(
          () => $.alert('변경완료'),
          () => location.reload(),
        ),
      ),
    ),
    $.on('click', '#is_hidden', async () => {
      const info = box.sel('detail_user_info');
      const confirm_msg = go(void 0, () => {
        if (info?.is_seller && (info.adjustment_amount > 0 || info.ongoing_projection_count > 0)) {
          return `
              <p style="margin: 0 0 15px; padding: 0;">
                셀러의 거래와 잔여 수익 금액이 남아있습니다.<br />
                탈퇴처리 하시겠습니까?
              </p> 
              진행중인 거래 ${info.ongoing_projection_count}건<br />
              잔여 수익 금액 ${UtilS.commify(info?.adjustment_amount || '0')}원<br />
            `;
        } else {
          return '탈퇴하시겠습니까?';
        }
      });
      go($.confirm(confirm_msg), (_is) => {
        if (_is) {
          return go($.delete('/@api/df/user', { id: box.sel('detail_user->id') }), (u) => {
            if (u.id) {
              go($.alert('탈퇴완료.'), () => location.replace(`/user/list`));
            } else {
              $.alert('탈퇴 실패. 다시 확인해 주세요.');
            }
          });
        }
      });
    }),
    () => $.don_select($1('.team_select')),
  ),
});

export const defnDfUserDetailPointTab = () => ({
  tab_name: 'df.user.point',
  appended: $delegate('focusout', '.update-description', async (e) => {
    filterBlur(e.currentTarget);
    try {
      await axios.patch(
        makeApiUrl('/@api/df/user/point/description/:id', {
          id: e.currentTarget.dataset.id,
        }),
        {
          description: e.currentTarget.value,
        },
      );
    } catch (err) {
      $.alert(err?.response?.data?.message || err?.response?.data || err.message);
    }
  }),
});

export const defnDfUserDetailReviewTab = () => ({
  tab_name: 'df.user.review',
  appended: pipe(
    $.on2('click', '.review_item .review_img', function (e) {
      return frameOpenReviewDetail(window.box.sel(e.currentTarget), true);
    }),
    () =>
      $.on2($1('body'), 'click', '.review-delete button', async (e) => {
        const { id, is_me } = box.sel(e.currentTarget);
        if (!is_me || !(await $.confirm(T('삭제 하시겠습니까?')))) return;
        try {
          $.don_loader_start();
          await axios.delete(`/@api/review`, { data: { id } });
          location.reload();
        } catch (e) {
          $.alert(sel('response.data.message', e) || '삭제가 불가능합니다.');
        }
        $.don_loader_end();
      }),
    function () {
      $.remove_class($1('.review_list.infi_container'), 'infi_container');
    },
  ),
});
