import { BpOptionF } from '../../BpOption/F/Function/module/BpOptionF.js';
import { initMpMaker } from './mp_maker.js';
import { $qs, $setAttr } from 'fxdom/es';
import { unsetMakerPage } from './Setting/unset.js';
import { basicDataSetting } from './Setting/data.js';

$.frame.defn_frame({
  frame_name: 'maker.in_out_pc',
  is_modal: true,
  prev_frame_show: true,
  page_name: 'maker.in_out_pc_page',
  animation: false,
  always_remove: true,
});
export function openMakerProductColorInOut({ base_product_id, product_color_id }) {
  return new Promise(function (resolve) {
    $.frame.open(
      {
        frame_name: 'maker.in_out_pc',
        el_class: 'wow',
        closed: function (don_frame_el, pc) {
          resolve(pc);
        },
      },
      {
        page_name: 'maker.in_out_pc_page',
        tabs: [
          {
            tab_name: 'maker.in_out_pc_tab',
            data_func: async function () {
              await basicDataSetting({ base_product_id, product_color_id });
              /*DF*/
            },
            showed: async function (el_don_tab) {
              fabric.charWidthsCache = {};
              await initMpMaker($.find1(el_don_tab, '> .don_wrapper'));
              $setAttr({ marpplizer_mode: 'short' }, $qs('html'));
            },
            removed: function () {
              unsetMakerPage();
            },
          },
        ],
      },
    );
  });
}

$.frame.defn_page({
  page_name: 'maker.in_out_pc_page',
  custom_header: _p.t$(
    '\
  .title 메이커\
  button[class="don_hide_frame" type="button"]\
    span 취소\
  button[class="save" type="button"]\
    span 완료',
  ),
  appended: _p.pipe(
    $.on2('click', '> .don_wrapper >.header .save', function () {
      G.mp.maker.reset_box_data();
      $.frame.close(box.sel('maker->product_color'));
    }),
  ),
  tabs: [
    {
      tab_name: 'maker.in_out_pc_tab',
      template: () => '',
      appended: (tab_el) => BpOptionF.event(tab_el),
    },
  ],
});
