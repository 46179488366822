import { html } from 'fxjs/es';

export const modePopTemplate = () => {
  return html`<div class="df-scanning-page-confirm">
    <h3>원하는 스캔 모드를 선택해주세요.</h3>
    <div class="df-scanning-page-confirm__selector-area">
      <button type="button" data-mode="IMPORT" class="df-scanning-page-confirm__mode">입고 스캔</button>
      <button type="button" data-mode="EXPORT" class="df-scanning-page-confirm__mode">출고 스캔</button>
      <button type="button" data-mode="DUAL" class="df-scanning-page-confirm__mode">일출고 스캔</button>
    </div>
    <div class="df-scanning-page-confirm__bottom-area">
      <button type="button" class="df-scanning-page-confirm__cancel">취소</button>
    </div>
  </div>`;
};
