const PRODUCT_RANGES = [
  {
    id: 13,
    start: 1,
    end: 9,
    percentage: 0,
    type: 'PRODUCT',
    created_at: '2020-02-09T05:38:47.463Z',
    updated_at: '2020-02-09T05:38:47.463Z',
    collabo_type: 'creator',
  },
  {
    id: 11,
    start: 1,
    end: 4,
    percentage: 0,
    type: 'PRODUCT',
    created_at: '2015-06-12T10:15:21.588Z',
    updated_at: '2015-06-24T05:32:59.927Z',
    collabo_type: '',
  },
  {
    id: 1,
    start: 5,
    end: 20,
    percentage: 10,
    type: 'PRODUCT',
    created_at: '2015-06-12T10:15:21.588Z',
    updated_at: '2015-06-24T05:32:59.927Z',
    collabo_type: '',
  },
  {
    id: 14,
    start: 10,
    end: 19,
    percentage: 3,
    type: 'PRODUCT',
    created_at: '2020-02-09T05:38:47.463Z',
    updated_at: '2020-02-09T05:38:47.463Z',
    collabo_type: 'creator',
  },
  {
    id: 15,
    start: 20,
    end: 49,
    percentage: 6,
    type: 'PRODUCT',
    created_at: '2020-02-09T05:38:47.463Z',
    updated_at: '2020-02-09T05:38:47.463Z',
    collabo_type: 'creator',
  },
  {
    id: 2,
    start: 21,
    end: 50,
    percentage: 17,
    type: 'PRODUCT',
    created_at: '2015-06-12T10:15:21.588Z',
    updated_at: '2015-06-24T05:32:59.928Z',
    collabo_type: '',
  },
  {
    id: 16,
    start: 50,
    end: 99,
    percentage: 10,
    type: 'PRODUCT',
    created_at: '2020-02-09T05:38:47.463Z',
    updated_at: '2020-02-09T05:38:47.463Z',
    collabo_type: 'creator',
  },
  {
    id: 3,
    start: 51,
    end: 100,
    percentage: 24,
    type: 'PRODUCT',
    created_at: '2015-06-12T10:15:21.588Z',
    updated_at: '2015-06-24T05:32:59.928Z',
    collabo_type: '',
  },
  {
    id: 17,
    start: 100,
    end: 999999999,
    percentage: 17,
    type: 'PRODUCT',
    created_at: '2020-02-09T05:38:47.463Z',
    updated_at: '2020-02-09T05:38:47.463Z',
    collabo_type: 'creator',
  },
  {
    id: 4,
    start: 101,
    end: 200,
    percentage: 35,
    type: 'PRODUCT',
    created_at: '2015-06-12T10:15:21.589Z',
    updated_at: '2015-06-24T05:32:59.928Z',
    collabo_type: '',
  },
  {
    id: 5,
    start: 201,
    end: 999999999,
    percentage: 45,
    type: 'PRODUCT',
    created_at: '2015-06-12T10:15:21.589Z',
    updated_at: '2015-06-24T05:32:59.928Z',
    collabo_type: '',
  },
];

const PRINT_RANGES = [
  {
    id: 12,
    start: 1,
    end: 4,
    percentage: 0,
    type: 'PRINT',
    created_at: '2015-06-12T10:17:43.238Z',
    updated_at: '2015-06-24T05:32:06.759Z',
    collabo_type: '',
  },
  {
    id: 18,
    start: 1,
    end: 9,
    percentage: 0,
    type: 'PRINT',
    created_at: '2020-02-09T05:38:47.463Z',
    updated_at: '2020-02-09T05:38:47.463Z',
    collabo_type: 'creator',
  },
  {
    id: 6,
    start: 5,
    end: 20,
    percentage: 10,
    type: 'PRINT',
    created_at: '2015-06-12T10:17:43.238Z',
    updated_at: '2015-06-24T05:32:06.759Z',
    collabo_type: '',
  },
  {
    id: 19,
    start: 10,
    end: 19,
    percentage: 4,
    type: 'PRINT',
    created_at: '2020-02-09T05:38:47.463Z',
    updated_at: '2020-02-09T05:38:47.463Z',
    collabo_type: 'creator',
  },
  {
    id: 20,
    start: 20,
    end: 49,
    percentage: 8,
    type: 'PRINT',
    created_at: '2020-02-09T05:38:47.463Z',
    updated_at: '2020-02-09T05:38:47.463Z',
    collabo_type: 'creator',
  },
  {
    id: 7,
    start: 21,
    end: 50,
    percentage: 20,
    type: 'PRINT',
    created_at: '2015-06-12T10:17:43.238Z',
    updated_at: '2015-06-24T05:32:06.759Z',
    collabo_type: '',
  },
  {
    id: 21,
    start: 50,
    end: 99,
    percentage: 13,
    type: 'PRINT',
    created_at: '2020-02-09T05:38:47.463Z',
    updated_at: '2020-02-09T05:38:47.463Z',
    collabo_type: 'creator',
  },
  {
    id: 8,
    start: 51,
    end: 100,
    percentage: 30,
    type: 'PRINT',
    created_at: '2015-06-12T10:17:43.238Z',
    updated_at: '2015-06-24T05:32:06.759Z',
    collabo_type: '',
  },
  {
    id: 22,
    start: 100,
    end: 999999999,
    percentage: 20,
    type: 'PRINT',
    created_at: '2020-02-09T05:38:47.463Z',
    updated_at: '2020-02-09T05:38:47.463Z',
    collabo_type: 'creator',
  },
  {
    id: 9,
    start: 101,
    end: 200,
    percentage: 40,
    type: 'PRINT',
    created_at: '2015-06-12T10:17:43.238Z',
    updated_at: '2015-06-24T05:32:06.759Z',
    collabo_type: '',
  },
  {
    id: 10,
    start: 201,
    end: 999999999,
    percentage: 50,
    type: 'PRINT',
    created_at: '2015-06-12T10:17:43.238Z',
    updated_at: '2015-06-24T05:32:06.759Z',
    collabo_type: '',
  },
];

export const getProductDiscountRanges = () => PRODUCT_RANGES;
export const getPrintDiscountRanges = () => PRINT_RANGES;
