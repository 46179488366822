import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPAuthFindIDTmplS } from '../../S/Tmpl/module/OMPAuthFindIDTmplS.js';
import { OMPAuthFindIDMuiF } from '../Mui/module/OMPAuthFindIDMuiF.js';

export const openFrame = (closed, background) => {
  return OMPCoreUtilF.openFrameFit(OMPAuthFindIDMuiF.frame, (frame, page, [tab]) => {
    frame.always_remove = true;
    frame.el_class = background ? '' : 'no-background';
    frame.closed = closed;
    page.title = TT('auth::find_id::find_id_01');
    tab.template = OMPAuthFindIDTmplS.findIDTmpl;
  });
};
