import { baseCalcAll } from './baseCalcAll.js';

export const add = baseCalcAll(function add(a, b) {
  return a + b;
});

export const div = baseCalcAll(function div(a, b) {
  return a / b;
});

export const mult = baseCalcAll(function mult(a, b) {
  return a * b;
});

export const sub = baseCalcAll(function sub(a, b) {
  return a - b;
});
