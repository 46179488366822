import { MShopUtilF } from '../../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { VectorEditorPhotoCardF } from '../../../../../VectorEditor/PhotoCard/F/Function/module/VectorEditorPhotoCardF.js';
import { $qs, $scrollTop } from 'fxdom/es';

export const openPhotoCardEditor = async ({ app, editor_props, post_process_props }) => {
  const { title, options, svg_file, price, tab_el } = editor_props;
  if (MShopUtilF.isMobile()) {
    await VectorEditorPhotoCardF.makePhotoCardMobile({
      title,
      options,
      svg_el: svg_file,
      postProcess: (...args) => {
        console.log(post_process_props);
        console.log(args);
      },
    });
  } else {
    await VectorEditorPhotoCardF.makePhotoCardPC({
      app,
      title,
      price,
      options,
      svg_el: svg_file,
      postProcess: (...args) => {
        console.log(post_process_props);
        console.log(args);
      },
      prev_frame_right_panel: $qs('#marpplizer'),
      getFramePosition: () => {
        const { y, height } = tab_el?.querySelector?.('.mp_maker')?.getBoundingClientRect?.() ?? {
          y: 70,
          height: 926,
        };
        return { top: y, height };
      },
      onFrameHiding: () => {
        (tab_el?.tab_opt ?? {}).tab_top = $scrollTop(window);
      },
    });
  }
};
