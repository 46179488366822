import axios from 'axios';
import { saveAs } from 'file-saver';
import { strMap, sel } from 'fxjs/es';
import { legacyHtml } from '../../../../../modules/Util/S/Function/util.js';
import { go } from 'fxjs';
import { $closest, $css } from 'fxdom/es';

!(function (win, LF) {
  const $t_group_selected_item = (image) => legacyHtml`
    <div
      class="item"
      _id="{{image.id}}"
      _sel="folder->_->images->(#${image.id})"
      style="background-image: url(${G.to_150(image.url)})"
    >
      <button type="button" class="btn_delete">X</button>
    </div>
  `;

  const $t_group_selection_item = (image) => legacyHtml`
    <li
      class="image_item"
      _sel="folder->_->images->(#${image.id})"
      _id="${image.id}"
      is_group="${image.is_group}"
      style="background-image: url(${G.to_150(image.url)})"
    >
      <div class="thumbs" group_length="${image.group_thumb ? image.group_thumb.length : 0}">
        ${_go(image, (image) => {
          if (image.is_group)
            return _sum(
              image.group_thumb,
              (thumb) => legacyHtml`
                <span class="thumb" style="background-image: url(${G.to_150(thumb.url)});"></span>
              `,
            );
        })}
      </div>
      <div class="name">${image.name || '이름 없음'}</div>
      <div class="price">${G.$commify(image.price)}</div>
      <div class="btns">
        <button type="button" class="btn_image_select">그룹이미지선택</button>
        <button type="button" class="btn_image_cancel">그룹선택취소</button>
      </div>
    </li>
  `;

  const $t_selected_item = (image) => legacyHtml`
    <div
      class="item"
      _id="${image.id}"
      _sel="folder->_->images->(#${image.id})"
      style="background-image: url(${G.to_150(image.url)})"
    >
      <button type="button" class="btn_delete">X</button>
    </div>
  `;

  const $t_selection_item = (image) => legacyHtml`
    <li
      class="image_item"
      _sel="folder->_->images->(#${image.id})"
      _id="${image.id}"
      selected="false"
      style="background-image: url(${G.to_150(image.url)})"
    >
      <div class="name">${image.name || '이름 없음'}</div>
      <div class="price">${G.$commify(image.price)}</div>
      <div class="btns">
        <button type="button" class="btn_image_select">선택</button>
        <button type="button" class="btn_image_cancel">취소</button>
      </div>
    </li>
  `;

  const $t_layer_image_item = (image) => legacyHtml`
    <div
      class="don_is_item image_item"
      _sel="images->(#${image.id})"
      image_id="${image.id}"
      is_group="${image.is_group}"
      empty_folder2="${!image.folder2_id}"
    >
      <div class="thumbs don_img_wrap" group_length="${image.group_thumb ? image.group_thumb.length : 0}">
        ${_go(image, (image) => {
          if (image.is_group)
            return _sum(
              image.group_thumb,
              (thumb) => legacyHtml`
                <span class="thumb" style="background-image: url(${G.to_150(thumb.url)});"></span>
              `,
            );
        })}
      </div>
      <div class="image don_img_wrap">
        <img src="${G.to_150(image.url)}" alt="" />
      </div>
      <div class="folder2_name" title="${_p.v(image, _p.folder2.name) || ''}">
        ${_p.v(image, '_.folder2.name') || '이름 없음'}
      </div>
    </div>
  `;

  function empty_time(num) {
    if (num == '' || num == null || num == undefined) return true;
    return false;
  }

  $.frame.defn_frame({
    frame_name: 'df.folder_list_frame',
    page_name: 'df.folder_list_page',
    el_class: 'df_folder_list',
    title: '기본상품관리',
  });

  $.frame.defn_page({
    page_name: 'df.folder_list_page',
    tabs: [
      {
        tab_name: 'df.folder_list_tab',
        appended: function (tab) {
          const cont = $.find1(tab, '.folder_list_area');
          add_folder_list_events(tab, $.attr(cont, 'type'));
          set_folder_list_pager(tab, $.attr(cont, 'type'));

          _go(
            tab,
            $.on('click', '.btn_order_align', function (e) {
              _p.go(
                $.get('/@api/df/folder/list/' + $.attr(cont, 'type'), {
                  limit: 'all',
                }),
                function (folders) {
                  return {
                    type: 'image_text',
                    order_by: 'asc',
                    items: _map(folders, function (folder) {
                      return {
                        id: folder.id,
                        url: folder.url,
                        no: folder.no,
                        name: folder.name,
                      };
                    }),
                  };
                },
                G.order_align.editor,
                function (items) {
                  if (!items) return;

                  $.don_loader_start();
                  _p.go(
                    $.put('/@api/folder/order_align', {
                      items: _map(items, _p.omit('url')),
                    }),
                    function (data) {
                      if (!data.result) {
                        $.alert('저장되지 않았습니다. 다시 시도해 주세요.');
                        return;
                      }
                      win.location.reload();
                    },
                  );
                },
              );
            }),
          );
        },
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'df.free_folder_detail',
    el_class: 'df_folder_detail',
    page_name: 'df.free_folder_detail_page',
  });

  $.frame.defn_page({
    page_name: 'df.free_folder_detail_page',
    tabs: [
      {
        tab_name: 'df.free_folder_detail_tab',
        template: (folder) => legacyHtml`
          <div class="folder_detail_area" folder_id="${folder.id}" _sel="folder">
            <h2>폴더 정보</h2>
            <div class="folder_info">
              <div class="thumbnail">
                <div class="img"><img src="${folder.url || ''}" alt="" /></div>
                <input type="file" multiple class="folder_file" />
                <button type="button" class="btn_thumbnail">썸네일 이미지</button>
              </div>
              <div class="name">
                <span>폴더 이름:</span>
                <input type="text" name="name" value="${folder.name || ''}" />
              </div>
              <div class="name_en">
                <span>폴더 이름 영문:</span>
                <input type="text" name="name_en" value="${folder.name_en || ''}" />
              </div>
              <div class="name_jp">
                <span>폴더 이름 일문:</span>
                <input type="text" name="name_jp" value="${folder.name_jp || ''}" />
              </div>
              <div class="due_at">
                <span>기간설정:</span>
                <input
                  type="date"
                  name="expired_at"
                  value="${folder.expired_at ? moment(folder.expired_at).format('YYYY-MM-DD') : ''}"
                />
                <i>시간:</i>
                <input
                  type="number"
                  name="hour"
                  value="${folder.expired_at ? moment(folder.expired_at).format('HH') : ''}"
                  min="0"
                  max="23"
                  placeholder="0-23"
                />
                <i>분:</i>
                <input
                  type="number"
                  name="min"
                  value="${folder.expired_at ? moment(folder.expired_at).format('mm') : ''}"
                  min="0"
                  max="59"
                  placeholder="0-59"
                />
              </div>
              <div class="public">
                <span>공개</span>
                <input type="checkbox" name="is_public" ${folder.is_public ? 'checked' : ''} />
              </div>
              <button type="button" class="btn_save_folder">폴더 정보 저장</button>
            </div>
            <div class="image_controls">
              <div class="add_group_image">
                <span class="head">그룹 이미지 관리</span>
                <input type="text" name="name" placeholder="그룹 이미지 이름을 입력하세요." />
                <button type="button" class="btn_add_group_image">그룹 이미지 추가</button>
              </div>
              <div class="add_image">
                <span class="head">이미지 관리</span>
                <input type="text" name="name" placeholder="이미지 이름을 입력하세요." />
                <button type="button" class="btn_add_image">이미지 추가</button>
              </div>
            </div>
            <div class="image_list" _sel="folder->_->images">
              <div class="head">
                <h4>이미지 리스트</h4>
                <div class="buttons">
                  <button type="button" class="btn_delete_mode">삭제 모드</button>
                  <button type="button" class="btn_change_folder">폴더 변경</button>
                  <button type="button" class="btn_order_align">순서 편집</button>
                </div>
              </div>
              <ul>
                ${_sum(_p.v(folder, '_.images'), G.$t_$image_list_item)}
              </ul>
            </div>
          </div>
        `,
        appended: _p.pipe(
          $.on(
            'click',
            '.thumbnail .btn_thumbnail',
            __(_p.v('$delegateTarget'), $.find1('.thumbnail input.folder_file'), $.trigger('click')),
          ),
          $.on(
            'change',
            '.thumbnail input.folder_file',
            __(_p.v('$currentTarget'), function (current_target) {
              const container = $.closest(current_target, '.folder_detail_area');
              return _p.go(
                $.upload(current_target, {
                  url: '/@api/folder/' + box().folder.id + '/thumbnail',
                }),
                function (folder) {
                  box.set('folder->url', folder.url);
                  _p.go(container, $.find1('.thumbnail img'), $.attr('src', folder.url));
                },
              );
            }),
          ),
          $.on('click', '.btn_delete_mode', (e) => {
            const cont_el = $.find1(e.delegateTarget, '.image_list');
            $.attr(cont_el, 'mode', $.attr(cont_el, 'mode') == 'delete' ? '' : 'delete');
          }),
          $.on2('click', '.folder_info .btn_save_folder', function (e) {
            const container = $.find1(e.$delegateTarget, '.folder_detail_area');
            const date = $.val($.find1(container, '.folder_info input[name="expired_at"]'));
            const hour = $.val($.find1(container, '.folder_info input[name="hour"]')) + '' || '';
            const min = $.val($.find1(container, '.folder_info input[name="min"]')) + '' || '';
            let expired_at = '';

            if (date && !empty_time(hour) && !empty_time(min)) expired_at = date + ' ' + hour + ':' + min;
            else if (date || !empty_time(hour) || !empty_time(min))
              return $.alert('날짜와 시간이 전부 입력되어야 기간 설정이 됩니다.');

            return _p.go(
              {
                id: $.attr(container, 'folder_id'),
                name: $.val($.find1(container, '.folder_info input[name="name"]')),
                name_en: $.val($.find1(container, '.folder_info input[name="name_en"]')),
                name_jp: $.val($.find1(container, '.folder_info input[name="name_jp"]')),
                expired_at: expired_at ? new Date(expired_at) : null,
                is_public: $.is($.find1(container, '[name="is_public"]'), ':checked'),
              },
              _p($.put, '/@api/folder/update'),
              function (folder) {
                box.extend2(container, folder);
                $.alert('정보가 저장되었습니다.');
              },
            );
          }),
          $.on('keydown', '.image_controls .add_image input[name="name"]', function (e) {
            if (e.keyCode == 13) $.trigger($.find1(e.$delegateTarget, '.btn_add_image'), 'click');
          }),
          $.on('click', '.btn_add_image', function (e) {
            const el_name_input = $.find1($.closest(e.$currentTarget, '.add_image'), 'input[name="name"]');

            if ($.val(el_name_input).length <= 0) {
              alert('이미지 이름을 입력해 주세요.');
              return;
            }

            $.post('/@api/image/create', {
              name: $.val(el_name_input),
              folder_id: $.attr($.find1(e.$delegateTarget, '.folder_detail_area'), 'folder_id'),
            }).then(function (image) {
              _p.go(image, G.$t_$image_list_item, $.el, function (el_image) {
                box.unshift('folder->_->images', image);
                $.trigger($.val(el_name_input, ''), 'focus');
                $.prependTo(el_image, $.find1(e.$delegateTarget, '.image_list ul'));
              });
            });
          }),
          $.on('keydown', '.image_controls .add_group_image input[name="name"]', function (e) {
            if (e.keyCode == 13) $.trigger($.find1(e.$delegateTarget, '.btn_add_group_image'), 'click');
          }),
          $.on('click', '.btn_add_group_image', function (e) {
            const el_name_input = $.find1(
              $.closest(e.currentTarget, '.add_group_image'),
              'input[name="name"]',
            );

            if ($.val(el_name_input).length <= 0) {
              alert('그룹 이미지 이름을 입력해 주세요.');
              return;
            }

            $.post('/@api/df/group/image/create', {
              name: $.val(el_name_input),
              folder_id: $.attr($.find1(e.delegateTarget, '.folder_detail_area'), 'folder_id'),
            }).then(function (image) {
              _p.go(image, G.$t_$image_list_item, $.el, function (jq_image) {
                box.unshift('folder->_->images', image);
                $.trigger($.val(el_name_input, ''), 'focus');
                $.prependTo(jq_image, $.find1(e.delegateTarget, '.image_list ul'));
              });
            });
          }),

          $.on('click', '.btn_change_folder', function (e) {
            $.frame.open(
              {
                frame_name: 'df.change_folder',
                closed: function (X, result) {
                  if (result) return win.location.reload();
                },
              },
              {
                page_name: 'df.change_folder',
                tabs: [
                  {
                    tab_name: 'df.change_folder',
                    data_func: function () {
                      return box.sel($.closest(e.currentTarget, '.folder_detail_area'));
                    },
                  },
                ],
              },
            );
          }),

          $.on('click', '.btn_order_align', function (e) {
            _p.go(
              $.get(
                '/@api/df/image/list/' +
                  $.attr($.find1(e.$delegateTarget, '.folder_detail_area'), 'folder_id'),
              ),
              function (folder) {
                return {
                  order_by: 'asc',
                  items: _map(folder._.images, function (image) {
                    return {
                      id: image.id,
                      url: image.url,
                      no: image.folder_no,
                      is_group: image.is_group,
                    };
                  }),
                };
              },
              G.order_align.editor,
              function (items) {
                if (!items || !items.length) return;

                $.don_loader_start();
                _p.go(
                  $.put('/@api/folder/image/order_align', {
                    items: _map(items, _p.omit('url')),
                  }),
                  function (data) {
                    if (!data.result) {
                      alert('저장되지 않았습니다. 다시 시도해 주세요.');
                      $.don_loader_end();
                      return;
                    }
                    win.location.reload();
                  },
                );
              },
            );
          }),
          $.on('click', '.image_list li .btn_image_download', function (e) {
            const name = go(e.currentTarget, $closest('.image_item'), box.sel, ({ name }) => name);
            const original_url = go(
              e.currentTarget,
              $closest('.image_item'),
              $css('backgroundImage'),
              (bg) => {
                const url = bg.match(/"(.*?)"/)[1];
                return G.to_original(url);
              },
            );
            saveAs(original_url, name);
          }),
          $.on('click', '.image_list li .btn_group_make', function (e) {
            const el_cont = $.closest(e.$currentTarget, '.folder_detail_area');
            const item = $.closest(e.$currentTarget, 'li');
            // $.attr(item, 'group_first', 'true');
            // $.attr(el_cont, 'is_state', 'selection_images');

            $.frame.open(
              {
                frame_name: 'df.group_selected',
                closed: function (X, result) {
                  if (result) return win.location.reload();
                },
              },
              {
                page_name: 'df.group_selected_page',
                tabs: [
                  {
                    tab_name: 'df.group_selected_tab',
                    data_func: function () {
                      return {
                        first_image: box.sel(item),
                        selection_images: _go(
                          box.sel(el_cont),
                          _p.v('_.images'),
                          _p.reject(function (image) {
                            return image.is_group || box.sel(item).id == image.id;
                          }),
                        ),
                      };
                    },
                  },
                ],
              },
            );
          }),
          $.on('click', '.image_list li[is_group="true"] .btn_modify', function (e) {
            const image = _go(e.$currentTarget, $.closest('li'), box.sel);

            $.frame.open(
              {
                frame_name: 'df.group_image_editor',
                closing: function () {
                  return folder_detail_image_reload(
                    image.folder_id,
                    $.find1(e.$delegateTarget, '.folder_detail_area'),
                    $.find1(e.$delegateTarget, '.image_list ul'),
                  );
                },
              },
              {
                page_name: 'df.group_image_editor_page',
                title: '그룹 만들기',
                tabs: [
                  {
                    tab_name: 'df.group_image_editor_tab',
                    data_func: function () {
                      return _go(e.$currentTarget, $.closest('li'), box.sel);
                    },
                  },
                ],
              },
            );
            //
            // $.frame2.open_frame({
            //   frame_name: 'df.group_image_editor',
            //   animation: false,
            //   prev_frame_show: true,
            //   appended: function() {
            //     $.add_class($1('body'), 'no_scroll');
            //   },
            //   closing: function() {
            //     $.remove_class($1('body'), 'no_scroll');
            //
            //     return folder_detail_image_reload(
            //       image.folder_id,
            //       e.$delegateTarget,
            //       $.find1(e.$delegateTarget, '.image_list ul'));
            //   },
            //   group_canceled: function() {
            //     win.location.reload();
            //   },
            //   change_image_color: function() {
            //     win.location.reload();
            //   }
            // }, {
            //   data_func: function() {
            //     return _go(e.$currentTarget, $.closest('li'), box.sel);
            //   }
            // });
          }),
          $.on('click', '.image_list li[is_group="false"] .btn_modify', async function (e) {
            const item = _go(e.$currentTarget, $.closest('li'));
            const image = _go(e.$currentTarget, $.closest('li'), box.sel);

            const { data: press_colors } = await axios.get('/@api/press_colors');

            $.frame.open(
              {
                frame_name: 'df.image_editor',
              },
              {
                page_name: 'df.image_editor_page',
                hiding: function (page) {
                  const _image = box.sel($.find1(page, '.df_image_editor'));

                  $.don_loader_start();
                  box.extend2(item, _image);
                  $.css(item, 'background-image', 'url(' + G.to_150(_image.url) + ')');
                  $.html($.find1(item, '.name'), _image.name || '');
                  $.html($.find1(item, '.price '), G.$commify(_image.price));
                  $.don_loader_end();
                },
                tabs: [
                  {
                    tab_name: 'df.image_editor_tab',
                    data_func: function () {
                      return { image, press_colors };
                    },
                  },
                ],
              },
            );
          }),

          $.on('click', '.image_list li .btn_delete', function (e) {
            if (!confirm('삭제하시겠습니까?')) return;

            const el_image = $.closest(e.$currentTarget, 'li');
            $.delete('/@api/image/delete', {
              id: $.attr(el_image, '_id'),
            }).then(function () {
              el_image.remove();
            });
          }),
        ),
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'df.group_selected',
    el_class: 'df_group_selected',
    always_remove: true,
    page_name: 'df.group_selected_page',
    title: '그룹 만들기',
  });

  $.frame.defn_page({
    page_name: 'df.group_selected_page',
    tabs: [
      {
        tab_name: 'df.group_selected_tab',
        page_name: 'df.group_selected_page',
        template: _pipe(
          function (data) {
            return _mr(
              data.first_image,
              _p.v(data.first_image, '_.group_images')
                ? _p.v(data.first_image, '_.group_images').reverse()
                : [],
              data.selection_images ? data.selection_images : [],
            );
          },
          (image, group_images, images) => pug`
          .group_selected
            .selected_images
              h3 그룹 만들기
              .buttons
                button[type=button].btn_group_cancel 취소
                button[type=button].btn_group_finish 그룹 설정 완료
              .first
                ${_go(image, G.df.folder.$t_group_selected_item)}
              .list
                ${_sum(group_images, G.df.folder.$t_group_selected_item)}
            .selection_images
              ul
                ${_sum(images, G.df.folder.$t_group_selection_item)}
        `,
        ),
        appended: _p.pipe(
          $.on('click', '.selected_images .item .btn_delete', function (e) {
            const item = box.sel($.closest(e.$currentTarget, '.item'));
            $.remove($.closest(e.$currentTarget, '.item'));
            $.attr(
              $.find1(e.$delegateTarget, '.selection_images .image_item[_id="' + item.id + '"]'),
              'group_image',
              '',
            );
          }),
          $.on('click', '.selection_images .image_item .btn_image_select', function (e) {
            $.attr($.closest(e.$currentTarget, 'li'), 'group_image', 'true');
            _go(
              box.sel($.closest(e.$currentTarget, 'li')),
              G.df.folder.$t_group_selected_item,
              $.el,
              $.attr('is_new', 'true'),
              $.prependTo($.find1(e.$delegateTarget, '.selected_images .list')),
            );
          }),
          $.on('click', '.selection_images .image_item .btn_image_cancel', function (e) {
            const li = $.attr($.closest(e.$currentTarget, 'li'), 'group_image', '');

            _go(e.$delegateTarget, $.find1('.selected_images .item[_id="' + box.sel(li).id + '"]'), $.remove);
          }),
          $.on('click', '.btn_group_cancel', function (e) {
            $.frame.close(false);
          }),
          $.on('click', '.btn_group_finish', function (e) {
            _p.go(
              $.post('/@api/df/group_image', {
                first: _go(e.$delegateTarget, $.find1('.group_selected .first .item'), box.sel),
                list: _go(
                  e.$delegateTarget,
                  $.find('.group_selected .list .item[is_new="true"]'),
                  _map(box.sel),
                  function (list) {
                    return list && list.length ? list.reverse() : [];
                  },
                ),
              }),
              function (data) {
                if (!data.result) {
                  $.alert('다시 시도해 주세요.');
                  return;
                }
                $.frame.close(true);
              },
            );
          }),
        ),
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'df.group_image_editor',
    always_remove: true,
    hide_frame_button_type: 'X',
    el_class: 'df_group_image_editor',
    title: '그룹 설정',
    page_name: 'df.group_image_editor_page',
  });

  $.frame.defn_page({
    page_name: 'df.group_image_editor_page',
    title: '그룹 설정',
    tabs: [
      {
        tab_name: 'df.group_image_editor_tab',
        template: _pipe(
          function (image) {
            box.set('group_image_editor', image);
            return image;
          },
          (image) => pug`
          .df_image_editor[_id="${image.id}" folder_id="${image.folder_id}" _sel="group_image_editor"]
            .header
              h2 그룹 이미지 수정 - ${image.name || ''}
            .image_list[_sel="./_->group_images"]
              .image_controls
                label.public
                  sp 공개 
                  input[type="checkbox" name="is_public"${image.is_public ? ' checked' : ''}]
                input[type="file" multiple].image_file
                  input[type="text" name="name" placeholder="이미지 이름을 입력하세요."]
                button[type=button].btn_add_image 이미지 추가
              .buttons
                sp 순서정렬을 통해 그룹 썸네일을 바꿀 수 있습니다.
                button[type=button].btn_order_align 순서 정렬
                button[type=button].btn_change_image_color 컬러 이미지 셋트로 변경
                button[type=button].btn_group_cancel 그룹 전체 해제
              ul
                ${_sum(_p.v(image, '_.group_images'), G.$t_$image_list_item)}
        `,
        ),
        closed: function (tab) {
          box.unset($.find1(tab, '.df_image_editor'));
        },
        appended: function (tab) {
          const cont = $.find1(tab, '.df_image_editor');
          _p.go(
            tab,
            $.on('click', '.image_list li .btn_image_download', function (e) {
              const name = go(e.currentTarget, $closest('.image_item'), box.sel, ({ name }) => name);
              const original_url = go(
                e.currentTarget,
                $closest('.image_item'),
                $css('backgroundImage'),
                (bg) => {
                  const url = bg.match(/"(.*?)"/)[1];
                  return G.to_original(url);
                },
              );
              saveAs(original_url, name);
            }),
            $.on('click', '.image_item .btn_group_first', function (e) {
              _p.go(
                $.put('/@api/df/group/image/group_first_set', {
                  current_image_id: box.sel(cont).id,
                  next_image_id: _go(e.$currentTarget, $.closest('.image_item'), box.sel, _p.v('id')),
                }),
                function (image) {
                  box.extend2(cont, image);
                  change_editor(cont);
                },
              );
            }),
            $.on2('click', '.image_item .btn_pop', function (e) {
              const item = $.closest(e.$currentTarget, '.image_item');
              return _p.go(
                $.put('/@api/df/group/image/pop', {
                  pop_image: box.sel(item),
                  parent_image: box.sel(cont),
                }),
                function (parent_image) {
                  box.extend2(cont, parent_image);
                  $.remove(item);
                },
              );
            }),
            $.on('click', '.btn_order_align', function (e) {
              _p.go(
                box.sel(cont),
                function (image) {
                  return {
                    order_by: 'asc',
                    items: _map(image._.group_images, function (image) {
                      return {
                        id: image.id,
                        url: image.url,
                        no: image.group_no,
                        is_group: image.is_group,
                      };
                    }),
                  };
                },
                G.order_align.editor,
                function (items) {
                  if (!items || !items.length) return;

                  $.don_loader_start();
                  _p.go(
                    $.put('/@api/df/group_image/order_align', {
                      items: _map(items, _p.omit('url')),
                    }),
                    function (data) {
                      if (!data.result) {
                        $.alert('저장되지 않았습니다. 다시 시도해 주세요.');
                        $.don_loader_end();
                        return;
                      }

                      _go(
                        data.items,
                        _p.t(
                          'items',
                          '\
                        {{_sum(items, G.$t_$image_list_item)}}\
                      ',
                        ),
                        $.html_to('.image_list ul'),
                      );

                      $.don_loader_end();
                    },
                  );
                },
              );
            }),
            $.on2('click', '.btn_group_cancel', function (e) {
              if (!confirm('전체 그룹해제 하시겠습니까?')) return;

              return _p.go(
                $.put('/@api/df/group/group_cancel', {
                  image: _go(cont, box.sel),
                }),
                function (data) {
                  if (!data || !data.result) return;
                  win.location.reload();
                },
              );
            }),
            $.on('click', '.btn_change_image_color', function (e) {
              if (!confirm('컬러 이미지 셋트로 변경하시겠습니까?')) return;

              _p.go(
                $.put('/@api/df/group/change_image_color', {
                  image: _go(cont, box.sel),
                }),
                function (data) {
                  if (!data.result) {
                    alert('컬러 이미지 셋트 변경이 되지 않았습니다.');
                    return;
                  }
                  win.location.reload();
                },
              );
            }),
            $.on('click', '.image_controls [name="is_public"]', function (e) {
              const image = box.sel($.find1(e.$delegateTarget, '.df_image_editor'));
              _p.go(
                $.put('/@api/image/update', {
                  id: image.id,
                  is_public: $.is(e.$currentTarget, ':checked'),
                }),
                function (_image) {
                  box.extend2(cont, _image);
                  $.alert('이미지 공개 설정이 적용 되었습니다.');
                },
              );
            }),
            $.on('click', '.image_controls .btn_add_image', function (e) {
              const name_input = $.find1(e.delegateTarget, 'input[name="name"]');
              const el_editor = $.find1(e.delegateTarget, '.df_image_editor');
              const box_image = box.sel(el_editor);
              const group_images =
                _p.v(box_image, '_.group_images') && _p.v(box_image, '_.group_images').length
                  ? _p.v(box_image, '_.group_images')
                  : undefined;

              if (!$.val(name_input).length) {
                $.alert('이미지 이름을 입력해 주세요.');
                return;
              }

              $.post('/@api/image/create', {
                name: $.val(name_input),
                folder_id: box_image.folder_id,
                image_id: box_image.id,
                folder_no: group_images ? _p.first(box_image._.group_images).folder_no - 1 : 0,
              }).then(function (image) {
                _go(image, G.$t_$image_list_item, $.el, function (image_el) {
                  if (!_p.v(box_image, '_.group_images'))
                    box.extend2(el_editor, {
                      _: {
                        group_images: [],
                      },
                    });

                  box_image._.group_images.unshift(image);
                  $.val(name_input, '').focus();
                  $.prepend_to(image_el, $.find1(e.delegateTarget, '.image_list ul'));
                });
              });
            }),
            $.on('click', '.image_item .btn_modify', async function (e) {
              const item = _go(e.$currentTarget, $.closest('li'));
              const image = _go(e.$currentTarget, $.closest('li'), box.sel);

              const { data: press_colors } = await axios.get('/@api/press_colors');

              $.frame.open(
                {
                  frame_name: 'df.image_editor',
                },
                {
                  page_name: 'df.image_editor_page',
                  hiding: function (page) {
                    const _image = box.sel($.find1(page, '.df_image_editor'));
                    $.don_loader_start();
                    $.css(item, 'background-image', 'url(' + G.to_150(_image.url) + ')');
                    $.html($.find1(item, '.name'), _image.name || '');
                    $.html($.find1(item, '.price'), G.$commify(_image.price));
                    $.find1(cont, '.public [name="is_public"]').checked = _image.is_public;
                    _p.go(
                      $.put('/@api/df/group/image/make_group_thumbs', {
                        first_group_image_id: box.sel(cont).id,
                      }),
                      function (first_group_image) {
                        box.extend2(cont, first_group_image);
                        $.don_loader_end();
                      },
                    );
                  },
                  tabs: [
                    {
                      tab_name: 'df.image_editor_tab',
                      data_func: function () {
                        return { image, press_colors };
                      },
                    },
                  ],
                },
              );
            }),
            $.on('click', '.image_item .btn_delete', function (e) {
              if (!confirm('삭제하시겠습니까?')) return;

              const image = $.closest(e.$currentTarget, '.image_item');
              const parent_image = box.sel(cont);
              $.delete('/@api/df/group/image/delete', {
                image: box.sel(image),
                parent_image: parent_image,
              }).then(function (_image) {
                if (!_image) return;
                box.set(cont, _image);
                image.remove();
              });
            }),
          );

          function change_editor(cont) {
            _go(
              box.sel(cont),
              _tap(function (image) {
                $.text($.find1(cont, '.header h2'), '그룹 이미지 수정 - ' + image.name);
              }),
              function (image) {
                return _p.v(image, '_.group_images');
              },
              _p.t(
                'group_images',
                '\
              {{_sum(group_images, G.$t_$image_list_item)}}\
            ',
              ),
              $.html_to($.find1(cont, '.image_list ul')),
            );
          }

          return tab;
        },
      },
    ],
  });

  $.frame.defn_frame({
    frame_name: 'df.image_editor',
    always_remove: true,
    hide_frame_button_type: 'X',
    el_class: 'df_image_editor_frame',
    title: '이미지 수정',
    page_name: 'df.image_editor_page',
  });

  $.frame.defn_page({
    page_name: 'df.image_editor_page',
    title: '이미지 수정',
    tabs: [
      {
        tab_name: 'df.image_editor_tab',
        template: _pipe(
          function ({ image, press_colors }) {
            box.set('image_editor', image);
            box.set('press_colors', press_colors);

            return { image, press_colors };
          },
          ({ image, press_colors }) => legacyHtml`
            <div
              class="df_image_editor"
              _id="${image.id}"
              folder_id="${image.folder_id}"
              is_group_image="${!!image.image_id}"
              _sel="image_editor"
            >
              <div class="header">
                <h2>이미지 수정 - ${image.name || ''}</h2>
              </div>
              <div class="controls">
                <div class="control name">
                  <input type="hidden" name="id" value="${image.id}" />
                  <span class="head">이름</span>
                  <input type="text" name="name" value="${image.name || ''}" />
                </div>
                <div class="control name_en">
                  <span class="head">이름 en</span>
                  <input type="text" name="name_en" value="${image.name_en || ''}" />
                </div>
                <div class="control name_jp">
                  <span class="head">이름 jp</span>
                  <input type="text" name="name_jp" value="${image.name_jp || ''}" />
                </div>
                <div class="control price">
                  <span class="head">가격</span>
                  <input type="text" name="price" value="${image.price}" />
                </div>
                <div class="control price_en">
                  <span class="head">가격 en</span>
                  <input type="text" name="price_en" value="${image.price_en}" />
                </div>
                <div class="control price_jp">
                  <span class="head">가격 jp</span>
                  <input type="text" name="price_jp" value="${image.price_jp}" />
                </div>
                <div class="control width_cm">
                  <span class="head">width_cm</span>
                  <input type="text" name="width_cm" value="${image.width_cm || ''}" />
                </div>
                <div class="control height_cm">
                  <span class="head">height_cm</span>
                  <input type="text" name="height_cm" value="${image.height_cm || ''}" />
                </div>
                <div class="control is_public">
                  <span class="head">공개</span>
                  <input type="checkbox" name="is_public" ${image.is_public ? ' checked' : ''} />
                </div>
                <div class="btns">
                  <button type="button" class="btn_change_group_image">그룹이미지 셋트로 변경</button>
                  <button type="button" class="btn_save">이미지 정보 저장</button>
                </div>
                <div class="original_file">
                  <label>
                    <button type="button" class="btn_original_file_up">원본파일업로드</button>
                    <input type="file" class="original_file_input" />
                  </label>
                  <div class="original_thumb_url">
                    ${_go(image, (image) => {
                      if (!image.original_url || !image.original_thumb_url) return '';
                      return legacyHtml` <a href="${image.original_url}" target="_blank">원본 파일</a> `;
                    })}
                  </div>
                </div>
              </div>
              <div class="image_color_list" _sel="./_->image_colors">
                <div class="ic_controls">
                  <button type="button" class="btn_order_align">순서 정렬</button>
                  <input type="file" multiple class="image_color_file" />
                  <button type="button" class="btn_add_ic">컬러 이미지 추가</button>
                </div>
                <ul>
                  ${strMap((ic) => {
                    return G.$t_$image_color_item(image, ic, press_colors);
                  }, sel('_.image_colors', image))}
                </ul>
              </div>
            </div>
          `,
        ),
        closed: function (tab) {
          box.unset($.find1(tab, '.df_image_editor'));
        },
        appended: function (tab) {
          const cont = $.find1(tab, '.df_image_editor');

          _p.go(
            tab,
            $.on2('click', '.controls .btns .btn_save', function (e) {
              return _p.go(
                $.put('/@api/image/update', {
                  id: box.sel(cont).id,
                  name: $.val($.find1(cont, '.controls .name [name="name"]')),
                  name_en: $.val($.find1(cont, '.controls .name_en [name="name_en"]')),
                  name_jp: $.val($.find1(cont, '.controls .name_jp [name="name_jp"]')),
                  price: parseInt($.val($.find1(cont, '.controls .price [name="price"]'))),
                  price_en: $.val($.find1(cont, '.controls .price_en [name="price_en"]')),
                  price_jp: $.val($.find1(cont, '.controls .price_jp [name="price_jp"]')),
                  width_cm: $.val($.find1(cont, '.controls .width_cm [name="width_cm"]')) || null,
                  height_cm: $.val($.find1(cont, '.controls .height_cm [name="height_cm"]')) || null,
                  is_public: $.is($.find1(cont, '.controls [name="is_public"]'), ':checked'),
                }),
                function (_image) {
                  box.extend2(cont, _image);
                  $.alert('저장되었습니다.');
                },
              );
            }),
            $.on(
              'click',
              '.original_file .btn_original_file_up',
              __(_p.v('$delegateTarget'), $.find1('.original_file input[type="file"]'), $.trigger('click')),
            ),
            $.on('change', '.original_file input[type="file"]', function (e) {
              $.don_loader_start();
              _p.go(
                $.upload(e.$currentTarget, {
                  url: '/@api/image/original_file/' + box.sel(cont).id,
                }),
                function (image) {
                  box.extend2(cont, image);
                  $.html(
                    $.find1(cont, '.original_thumb_url'),
                    _p.t(
                      'image',
                      '\
                        a[href="{{image.original_url}}" target="_blank"] 원본 파일\
                      ',
                    )(image),
                  );
                  $.don_loader_end();
                },
              );
            }),
            $.on('click', '.controls .btns .btn_change_group_image', function (e) {
              _p.if2(_p.c($.confirm('컬러 이미지들과 함께 그룹으로 만드시겠습니까?')))(function () {
                _p.go(
                  $.put('/@api/df/change_group_image', {
                    image_id: box.sel(cont).id,
                  }),
                  function (data) {
                    if (!data.result) {
                      $.alert('다시 시도해 주세요.');
                      return;
                    }
                    win.location.reload();
                  },
                );
              })();
            }),
            $.on('click', '.btn_order_align', function (e) {
              const _image = box.sel(cont);
              _p.go(
                _image,
                function (image) {
                  return {
                    order_by: 'asc',
                    items: _map(_p.v(image, '_.image_colors'), function (ic) {
                      return {
                        id: ic.id,
                        url: ic.url,
                        no: ic.no,
                      };
                    }),
                  };
                },
                G.order_align.editor,
                function (items) {
                  if (!items || !items.length) return;

                  $.don_loader_start();
                  _p.go(
                    $.put('/@api/df/image_color/order_align', {
                      items: _map(items, _p.omit('url')),
                    }),
                    function (data) {
                      if (!data.result) {
                        $.alert('저장되지 않았습니다. 다시 시도해 주세요.');
                        $.don_loader_end();
                        return;
                      }

                      _go(
                        void 0,
                        function () {
                          box.extend2($.attr(cont, '_sel') + '->_->image_colors', data.items);
                          return _p.mr(_image, data.items);
                        },
                        _p.t(
                          'image, items',
                          '\
                        {{_.sum(image, items, G.$t_$image_color_item)}}\
                      ',
                        ),
                        $.html_to('.image_color_list ul'),
                      );

                      $.don_loader_end();
                    },
                  );
                },
              );
            }),
            $.on(
              'click',
              '.ic_controls .btn_add_ic',
              __(_p.v('$delegateTarget'), $.find1('.ic_controls input[type="file"]'), $.trigger('click')),
            ),
            $.on('change', '.ic_controls input[type="file"]', function (e) {
              $.don_loader_start();
              _p.go(
                $.upload(e.$currentTarget, {
                  url: '/@api/image_color/create/' + box.sel(cont).id,
                }),
                function (ic_list) {
                  if (!box.sel(cont)._) box.extend2(cont, { _: { image_colors: [] } });

                  _p.each(ic_list, function (ic) {
                    box.sel(cont)._.image_colors.unshift(ic);
                    _p.go(
                      _p.mr(box.sel(cont), ic, box.sel('press_colors')),
                      G.$t_$image_color_item,
                      $.prepend_to($.find1(cont, '.image_color_list ul')),
                    );
                  });
                  $.don_loader_end();
                },
              );
            }),
            $.on2('click', '.image_color_list li > .btns .btn_ic_delete', function (e) {
              if (!confirm('삭제하시겠습니까?')) return;
              const ic = $.closest(e.$currentTarget, '.ic_item');

              return _p.go(
                $.delete('/@api/image_color/delete', {
                  id: box.sel(ic).id,
                }),
                function () {
                  box.remove2(ic);
                  $.remove(ic);
                },
              );
            }),
            $.on(
              'click',
              '.image_color_list li > .btns .btn_ic_save',
              __(
                _p.v('$currentTarget'),
                $.closest('li'),
                _p.add_arg(G.$form_$to_attrs, _p($.put, '/@api/image_color/update')),
                box.extend2,
                function () {
                  $.alert('저장되었습니다.');
                },
              ),
            ),
            $.on('click', '.image_color_list li > .btns .btn_first_image', function (e) {
              _p.go(
                e.$currentTarget,
                $.closest('.ic_item'),
                box.sel,
                function (ic) {
                  return $.put('/@api/image/update', {
                    id: ic.image_id,
                    url: ic.url,
                    width: ic.width,
                    height: ic.height,
                    image_color_id: ic.id,
                    press_color_name: ic.press_color_name,
                    press_color_id: ic.press_color_id,
                    press_color_code: ic.press_color_code,
                  });
                },
                function (image) {
                  if (!image || !image.image_color_id) {
                    $.alert('대표 설정이 되지 않았습니다.');
                    return;
                  }

                  _go(
                    cont,
                    $.find1('.image_color_list li[is_first="true"]'),
                    $.attr('is_first', 'false'),
                    function () {
                      box.extend2(cont, image);
                      $.attr($.closest(e.$currentTarget, '.ic_item'), 'is_first', 'true');
                      $.alert('대표 설정이 되었습니다.');
                    },
                  );
                },
              );
            }),
          );

          return tab;
        },
      },
    ],
  });

  G.append_fixed_layer = function (jq_el) {
    return _p.go(undefined, G.$t_$fixed_layer, $.el, function (jq_layer) {
      return $.append($.find1(jq_layer, '.body'), jq_el), jq_layer;
    });
  };

  function add_folder_list_events(container, type) {
    return _p.go(
      container,
      $.on('keydown', '[name="name"]', function (e) {
        if (e.keyCode == 13) $.trigger($.find(e.$delegateTarget, '.btn_add_folder'), 'click');
      }),
      $.on('click', '.btn_add_folder', function (e) {
        const jq_name_input = $.find1(container, 'input[name="name"]');
        const first_folder = $.find1(container, '.folder_item');

        if ($.val(jq_name_input).length <= 0) {
          $.alert('폴더 이름을 입력해 주세요.');
          return;
        }

        $.post('/@api/folder/create', {
          no: box.sel(first_folder) ? box.sel(first_folder).no - 1 : 0,
          name: $.val(jq_name_input),
          type: type,
        }).then(
          __(
            function (data) {
              if (!data || !data.id) return _p.stop();
              return data;
            },
            G.$t_$folder_list_item,
            $.el,
            function (jq_folder) {
              $.trigger($.val(jq_name_input, ''), 'focus');
              $.prependTo(jq_folder, $.find(e.$delegateTarget, '.folder_list'));
            },
          ),
        );
      }),
      $.on('click', '.btn_delete_mode', (e) => {
        const cont_el = $.find1(e.delegateTarget, '.folder_list_area');
        $.attr(cont_el, 'mode', $.attr(cont_el, 'mode') == 'delete' ? '' : 'delete');
      }),
      $.on('click', '.folder_list .folder_item .btn_delete', function (e) {
        if (!confirm('삭제하시겠습니까?')) return;

        const jq_folder = $.closest(e.currentTarget, '.folder_item');
        $.delete('/@api/folder/delete', {
          id: $.attr(jq_folder, 'folder_id'),
        }).then(function () {
          $.remove(jq_folder);
          $.alert('삭제되었습니다.');
        });
      }),
    );
  }

  function set_folder_list_pager(container, type) {
    return _p.go(
      container,
      $.ui.pagination(
        $.find1(container, '.folder_pgn'),
        {
          url: '/@api/df/folder/list/' + type,
          data: {
            //
            limit: 300 /* 기본값 20 */,
          },
          // is_history: true, /* 기본값 false */
          is_memoize: true /* 기본값 false */,
          paging_animate: ___(
            function (don_pgn_wrap, new_items) {
              this.don_pgn_wrap = don_pgn_wrap;
              this.new_items = new_items;
              return anime({
                targets: don_pgn_wrap,
                opacity: 0,
                duration: 400,
                easing: 'linear',
              }).finished;
            },
            function () {
              $.html(this.don_pgn_wrap, this.new_items);
              anime({
                targets: this.don_pgn_wrap,
                opacity: 1,
                duration: 400,
                easing: 'linear',
              });
            },
          ),
          // target: '#scroll_test', /* 기본값 window 스크롤 이벤트 타겟 */
          total_count: function () {
            return box().total_count;
          },
        },
        _p.sum(G.$t_$folder_list_item),
      ),
    );
  }

  function folder_detail_image_reload(folder_id, box_el, parent_el) {
    return _p.go(
      $.get('/@api/df/image/list/' + folder_id),
      function (folder) {
        return box.extend2(box_el, folder), folder;
      },
      _p.t(
        'folder',
        '\
        {{_sum(_.v(folder, "_.images"), G.$t_$image_list_item)}}\
      ',
      ),
      $.html_to(parent_el),
    );
  }

  $.frame.defn_frame({
    frame_name: 'df.change_folder',
    el_class: 'df_change_folder',
    always_remove: true,
    page_name: 'df.change_folder',
  });

  $.frame.defn_page({
    page_name: 'df.change_folder',
    tabs: [
      {
        tab_name: 'df.change_folder',
        template: __((folder) => {
          return _p.go(
            void 0,
            () => {
              return $.get('/@api/df/change_folder_list', {
                type: folder.type,
              });
            },
            _p.reject((f) => {
              return f.id == folder.id;
            }),
            (folder_list) => {
              if (!_p.v(folder, '_.images')) return _mr(folder, [], []);
              return _p.mr(folder, _p.v(folder, '_.images'), folder_list);
            },
            (folder, images, folder_list) => pug`
              .change_group
                .selected_images
                  h3 폴더 변경하기
                  .controls
                    sp.select 이동할 폴더 선택
                    select[name="folder_list"]
                      option[value=""] 선택 안함
                      ${_sum(
                        folder_list,
                        (folder) => pug`
                        option[value="${folder.id}"] ${folder.name || ''}
                      `,
                      )}
                    button[type=button].btn_cancel 취소
                    button[type="button"].btn_change_folder 폴더 변경
                  .list
                  .folder_list
                .selection_images
                  ul
                    ${_sum(images, G.df.folder.$t_selection_item)}
            `,
          );
        }),
        appended: _p.pipe(
          $.on('click', '.selection_images .image_item .btn_image_select', function (e) {
            $.attr($.closest(e.currentTarget, 'li'), 'selected', 'true');
            // _.noti.emit("IMAGE_GROUP", "ADD_IMAGE", [box.sel($.closest(e.$currentTarget, 'li'))]);
            _go(
              box.sel($.closest(e.currentTarget, 'li')),
              G.df.folder.$t_selected_item,
              $.el,
              $.attr('is_new', 'true'),
              $.prependTo($.find1(e.delegateTarget, '.selected_images .list')),
            );
          }),
          $.on('click', '.selection_images .image_item .btn_image_cancel', function (e) {
            const li = $.attr($.closest(e.currentTarget, 'li'), 'selected', '');

            _go(e.delegateTarget, $.find1('.selected_images .item[_id="' + box.sel(li).id + '"]'), $.remove);
          }),
          $.on('click', '.btn_cancel', function (e) {
            $.frame.close(false);
          }),
          $.on('click', '.btn_change_folder', function (e) {
            if (!$.val($.find1(e.delegateTarget, 'select[name="folder_list"]')))
              return $.alert('이동할 폴더를 선택해 주세요.');

            if (!$.find(e.delegateTarget, '.selected_images .list .item').length)
              return $.alert('이동할 이미지를 선택해 주세요.');

            _p.go(
              $.post('/@api/df/change_folder', {
                folder_id: $.val($.find1(e.delegateTarget, 'select[name="folder_list"]')),
                images: _go(
                  e.delegateTarget,
                  $.find('.selected_images .list .item'),
                  _map(function (item) {
                    return _p.pick(box.sel(item), 'id');
                  }),
                ),
              }),
              function (data) {
                if (!data.result) return $.alert('다시 시도해 주세요.');
                $.frame.close(true);
              },
            );
          }),
        ),
      },
    ],
  });

  G.df.folder = {
    $t_group_selected_item: $t_group_selected_item,
    $t_group_selection_item: $t_group_selection_item,
    $t_selected_item: $t_selected_item,
    $t_layer_image_item: $t_layer_image_item,
    $t_selection_item: $t_selection_item,
  };
})(window, {});
