import axios from 'axios';
import { PNG } from 'pngjs/browser.js';
import { makeCloneCanvas } from '../../../Canvas/S/util.js';

export const getPixelArrayFromURL = async (url) => {
  //support 16bit depth image
  const { data: image_data } = await axios.get(url, {
    responseType: 'arraybuffer',
  });
  return new Promise((resolve, reject) => {
    new PNG({ skipRescale: true }).parse(image_data, (err, result) => {
      err ? reject(err) : resolve(result.data);
    });
  });
};

export const pixelToIdx = (x, y, width) => (x + y * width) * 4;

export const multiplyCanvasesToNewCanvas = (destCanvas, srcCanvas) => {
  const canvas = document.createElement('canvas');
  const { width: w, height: h } = destCanvas;
  canvas.width = w;
  canvas.height = h;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(destCanvas, 0, 0);
  ctx.globalCompositeOperation = 'multiply';
  ctx.drawImage(srcCanvas, 0, 0);
  return canvas;
};

export const makeCanvasGrayScale = (canvas, is_avg_method) => {
  const c = makeCloneCanvas(canvas);
  const { width: w, height: h } = c;
  const pixel_array = c.getContext('2d').getImageData(0, 0, w, h).data;
  for (let y = 0; y < h; y++)
    for (let x = 0; x < w; x++) {
      const idx = (x + w * y) * 4;
      const w_avg = is_avg_method
        ? pixel_array[idx] * 0.2126 + pixel_array[idx + 1] * 0.7152 + pixel_array[idx + 2] * 0.0722
        : pixel_array[idx] + pixel_array[idx + 1] + pixel_array[idx + 2];
      for (let k = 0; k < 3; k++) {
        pixel_array[idx + k] = w_avg;
      }
    }
  return c;
};

const fxSigmoid = (pixel, strength, threshold) => {
  const norm_p = pixel / 255;
  const a = 1 / (1 + Math.exp(strength * threshold));
  const b = 1 / (1 + Math.exp(strength * (threshold - 1)));
  return ((1 / (1 + Math.exp(strength * (threshold - norm_p))) - a) / (b - a)) * 255;
};

export const makeCanvasSigmoidContrast = (canvas, strength, threshold) => {
  console.time('sigmoid time:');
  const w = canvas.width;
  const h = canvas.height;
  const ctx = canvas.getContext('2d');
  const imageData = ctx.getImageData(0, 0, w, h).data;
  for (let y = 0; y < h; y++) {
    for (let x = 0; x < w; x++) {
      const idx = (x + w * y) * 4;
      imageData[idx] = fxSigmoid(imageData[idx], strength, threshold);
      imageData[idx + 1] = fxSigmoid(imageData[idx + 1], strength, threshold);
      imageData[idx + 2] = fxSigmoid(imageData[idx + 2], strength, threshold);
      imageData[idx + 3] = imageData[idx + 3];
    }
  }
  ctx.putImageData(new ImageData(imageData, w, h), 0, 0);
  console.timeEnd('sigmoid time:');
  console.log(`threshold: ${threshold}, strength: ${strength}`);
  return canvas;
};

export const marppleBlender = (srcCanvas, destCanvas, factor, l) => {
  console.time('mpl blending: ');
  const { width: w, height: h } = srcCanvas;
  const srcPixels = srcCanvas.getContext('2d').getImageData(0, 0, w, h).data;
  const destPixels = destCanvas.getContext('2d').getImageData(0, 0, destCanvas.width, destCanvas.height).data;
  const outputPixels = new Uint8ClampedArray(srcPixels.length);
  const marppleBlendingSecret = (src, dest, factor) => {
    const src_norm = src / 255;
    const dest_norm = dest / 255;
    if (dest_norm < l)
      return ((src_norm * dest_norm) / l + Math.pow(src_norm, factor) * (1 - dest_norm / l)) * 255;
    else
      return (
        ((src_norm / l) * (2 * l - dest_norm) +
          Math.pow(src_norm, (1 / factor) * 1.3) * (dest_norm / l - 1)) *
        255
      );
  };
  for (let i = 0, len = srcPixels.length; i < len; i += 4) {
    outputPixels[i + 3] = srcPixels[i + 3];
    outputPixels[i] = marppleBlendingSecret(srcPixels[i], destPixels[i], factor);
    outputPixels[i + 1] = marppleBlendingSecret(srcPixels[i + 1], destPixels[i + 1], factor);
    outputPixels[i + 2] = marppleBlendingSecret(srcPixels[i + 2], destPixels[i + 2], factor);
  }
  const resultCanvas = document.createElement('canvas');
  resultCanvas.width = w;
  resultCanvas.height = h;
  const resultCtx = resultCanvas.getContext('2d');
  const resultImgData = new ImageData(outputPixels, w, h);
  resultCtx.putImageData(resultImgData, 0, 0);
  console.timeEnd('mpl blending: ');
  console.log(`factor: ${factor}`);
  return resultCanvas;
};

export const convertURLtoCanvas = async (url) => {
  const canvas = document.createElement('canvas');
  const img = new Image();
  img.src = url;
  return new Promise((resolve, reject) => {
    img.addEventListener('load', function () {
      const { width, height } = img;
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas);
    });
    img.addEventListener('error', function (event) {
      reject(event);
    });
  });
};

// export const makeEdgeBlurByGLFX = (src_canvas, blur_pixel_length, conserve_alpha = false) => {
//   const { width: w, height: h } = src_canvas;
//   const src_pixel_arr = src_canvas.getContext('2d').getImageData(0, 0, w, h).data;
//   const glfx_canvas = glfx.canvas();
//   const texture = glfx_canvas.texture(src_canvas);
//   glfx_canvas
//     .draw(texture)
//     .triangleBlur(blur_pixel_length)
//     .update();
//   const blurred_canvas = document.createElement('canvas');
//   blurred_canvas.width = w;
//   blurred_canvas.height = h;
//   const blurred_ctx = blurred_canvas.getContext('2d');
//   blurred_ctx.drawImage(glfx_canvas, 0, 0);
//   const blurred_pixel_arr = blurred_ctx.getImageData(0, 0, w, h).data;
//   const output_pixel_arr = new Uint8ClampedArray(w * h * 4);
//   for (let y = 0; y < h; y++) {
//     for (let x = 0; x < w; x++) {
//       const idx = (x + y * w) * 4;
//       output_pixel_arr[idx] = src_pixel_arr[idx + 3] ? src_pixel_arr[idx] : blurred_pixel_arr[idx];
//       output_pixel_arr[idx + 1] = src_pixel_arr[idx + 3]
//         ? src_pixel_arr[idx + 1]
//         : blurred_pixel_arr[idx + 1];
//       output_pixel_arr[idx + 2] = src_pixel_arr[idx + 3]
//         ? src_pixel_arr[idx + 2]
//         : blurred_pixel_arr[idx + 2];
//       output_pixel_arr[idx + 3] = conserve_alpha ? src_pixel_arr[idx + 3] : blurred_pixel_arr[idx + 3];
//     }
//   }
//   return new ImageData(output_pixel_arr, w, h);
// };

export const makeEdgeBlurByCanvas = (src_canvas, conserve_alpha = false) => {
  const { width: w, height: h } = src_canvas;
  const src_pixel_arr = src_canvas.getContext('2d').getImageData(0, 0, w, h).data;
  const blurred_canvas = document.createElement('canvas');
  blurred_canvas.width = w;
  blurred_canvas.height = h;
  const blurred_ctx = blurred_canvas.getContext('2d');
  blurred_ctx.filter = 'blur(1px)';
  blurred_ctx.drawImage(src_canvas, 0, 0);

  const blurred_pixel_arr = blurred_ctx.getImageData(0, 0, w, h).data;
  for (let idx = 0, len = blurred_pixel_arr.length; idx < len; idx += 4) {
    if (blurred_pixel_arr[idx + 3] > 0) {
      if (src_pixel_arr[idx + 3]) {
        blurred_pixel_arr[idx] = src_pixel_arr[idx];
        blurred_pixel_arr[idx + 1] = src_pixel_arr[idx + 1];
        blurred_pixel_arr[idx + 2] = src_pixel_arr[idx + 2];
      }
      if (conserve_alpha) {
        blurred_pixel_arr[idx + 3] = src_pixel_arr[idx + 3];
      }
    }
  }
  return new ImageData(blurred_pixel_arr, w, h);
};

export const makeBlurByCanvas = (src_canvas) => {
  const { width: w, height: h } = src_canvas;
  const blurred_canvas = document.createElement('canvas');
  blurred_canvas.width = w;
  blurred_canvas.height = h;
  const blurred_ctx = blurred_canvas.getContext('2d');
  blurred_ctx.filter = 'blur(1px)';
  blurred_ctx.drawImage(src_canvas, 0, 0);
  return blurred_canvas;
};
