import { $find, $width } from 'fxdom/es';
import { equals2, filterL, go, head } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { SVGEditorColorAndBorderMuiF } from '../../../../../../SVGEditor/MobileColorAndBorder/F/Mui/module/SVGEditorColorAndBorderMuiF.js';
import { SVGEditorMobileTextInputF } from '../../../../../../SVGEditor/MobileTextInput/F/Function/module/SVGEditorMobileTextInputF.js';
import { SVGEditorMobileTextInputMuiF } from '../../../../../../SVGEditor/MobileTextInput/F/Mui/module/SVGEditorMobileTextInputMuiF.js';
import { VectorEditorSettingBackgroundMobileF } from '../../../../../SettingBackground/Mobile/F/Function/module/VectorEditorSettingBackgroundMobileF.js';
import { VectorEditorStickerSingleMobileF } from '../Function/module/VectorEditorStickerSingleMobileF.js';
import { SVGEditorTextFrameMuiF } from '../../../../../../SVGEditor/MobileText/F/Mui/module/SVGEditorTextFrameMuiF.js';
import { VectorEditorStickerSingleMobilePathEditorF } from '../../PathEditor/F/Function/module/VectorEditorStickerSingleMobilePathEditorF.js';

export const handleFooterNoneAddIconClick =
  ({ tab_el }) =>
  (event) => {
    switch (event.currentTarget.dataset.is_active) {
      case 'true': {
        VectorEditorStickerSingleMobileF.deactivateFooterAddIcon(tab_el);
        VectorEditorStickerSingleMobileF.deactivateBottomMenus(tab_el);
        VectorEditorStickerSingleMobileF.hideBottomMenuBlankPlate(tab_el);
        return;
      }
      case 'false': {
        VectorEditorStickerSingleMobileF.activateFooterAddIcon(tab_el);
        VectorEditorStickerSingleMobileF.activateBottomMenus(tab_el);
        VectorEditorStickerSingleMobileF.showBottomMenuBlankPlate(tab_el);
      }
    }
  };

export const handleFooterNoneSetBackgroundIconClick =
  ({ tab_el }) =>
  () =>
    VectorEditorSettingBackgroundMobileF.setBackground({
      getInitBackground: async () => {
        const background_value_vo = tab_el.__mp_single_sticker_editor?.getBackground?.()?.value;
        if (equals2(background_value_vo?.type)('color')) {
          return { color: background_value_vo.fill };
        } else {
          await tab_el.__mp_single_sticker_editor?.setBackground?.({
            type: 'color',
            fill: 'none',
            opacity: 1,
          });
          return { color: 'none' };
        }
      },
      setBackground: ({ color }) =>
        tab_el.__mp_single_sticker_editor?.setBackground({
          type: 'color',
          fill: color,
          opacity: 1,
        }),
    });

export const handleFooterObjectDuplicateIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.duplicateFirstSelectedEl(editor);
  };

export const handleFooterObjectDeleteIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.deleteAllSelectedEls(editor);
  };

export const handleFooterPathSetColorIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    const path_el = go(
      editor.getSelectedEls(),
      filterL((el) => equals2(el.nodeName)('path')),
      head,
    );
    if (path_el == null) {
      return;
    }

    return MuiF.openFrame(SVGEditorColorAndBorderMuiF.frame, (frame, page, [color_tab, border_tab]) => {
      frame.___selected_el = path_el;
      frame.__mp_sticker_editor = editor;
      const one_line_length = $width(window) >= 640 ? 16 : 8;
      color_tab.makeData = () => ({ svg_el: path_el, one_line_length });
      border_tab.makeData = () => ({
        svg_el: path_el,
        one_line_length,
      });
    });
  };

export const handleFooterPathSetStrokeIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    const path_el = go(
      editor.getSelectedEls(),
      filterL((el) => equals2(el.nodeName)('path')),
      head,
    );
    if (path_el == null) {
      return;
    }

    return MuiF.openFrame(SVGEditorColorAndBorderMuiF.frame, (frame, page, [color_tab, border_tab]) => {
      frame.___selected_el = path_el;
      frame.__mp_sticker_editor = editor;
      border_tab.selected = true;
      const one_line_length = $width(window) >= 640 ? 16 : 8;
      color_tab.makeData = () => ({ svg_el: path_el, one_line_length });
      border_tab.makeData = () => ({
        svg_el: path_el,
        one_line_length,
      });
    });
  };

export const handleFooterPathEditPathIconClick =
  ({ tab_el }) =>
  async () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    const container_el = $find('> .don_wrapper')(tab_el);
    if (container_el == null) {
      return;
    }

    const path_el = go(
      editor.getSelectedEls(),
      filterL((el) => equals2(el.nodeName)('path')),
      head,
    );
    if (path_el == null) {
      return;
    }

    return VectorEditorStickerSingleMobilePathEditorF.editPath({
      single_sticker_editor: editor,
      path_el,
    });
  };

export const handleFooterPathDuplicateIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.duplicateFirstSelectedEl(editor);
  };

export const handleFooterPathDeleteIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.deleteAllSelectedEls(editor);
  };

export const handleFooterShapeSetColorIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    const shape_el = go(
      editor.getSelectedEls(),
      filterL((el) => new Set(['circle']).has(el.nodeName)),
      head,
    );
    if (shape_el == null) {
      return;
    }

    return MuiF.openFrame(SVGEditorColorAndBorderMuiF.frame, (frame, page, [color_tab, border_tab]) => {
      frame.___selected_el = shape_el;
      frame.__mp_sticker_editor = editor;
      const one_line_length = $width(window) >= 640 ? 16 : 8;
      color_tab.makeData = () => ({ svg_el: shape_el, one_line_length });
      border_tab.makeData = () => ({
        svg_el: shape_el,
        one_line_length,
      });
    });
  };

export const handleFooterShapeSetStrokeIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    const shape_el = go(
      editor.getSelectedEls(),
      filterL((el) => new Set(['circle']).has(el.nodeName)),
      head,
    );
    if (shape_el == null) {
      return;
    }

    return MuiF.openFrame(SVGEditorColorAndBorderMuiF.frame, (frame, page, [color_tab, border_tab]) => {
      frame.___selected_el = shape_el;
      frame.__mp_sticker_editor = editor;
      border_tab.selected = true;
      const one_line_length = $width(window) >= 640 ? 16 : 8;
      color_tab.makeData = () => ({ svg_el: shape_el, one_line_length });
      border_tab.makeData = () => ({
        svg_el: shape_el,
        one_line_length,
      });
    });
  };

export const handleFooterShapeDuplicateIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.duplicateFirstSelectedEl(editor);
  };

export const handleFooterShapeDeleteIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.deleteAllSelectedEls(editor);
  };

export const handleFooterTextDecorateTextIconClick =
  ({ tab_el }) =>
  async () => {
    const editor = tab_el.__mp_single_sticker_editor;
    const fonts = tab_el.__fonts;
    if (editor == null) {
      return;
    }

    const text_el = go(
      editor.getSelectedEls(),
      filterL((el) => equals2(el.nodeName)('text')),
      head,
    );
    if (text_el == null) {
      return;
    }
    return MuiF.openFrame(SVGEditorTextFrameMuiF.frame, (frame, page, [text_tab, color_tab, border_tab]) => {
      frame.___selected_el = text_el;
      frame.__fonts = fonts;
      frame.__mp_sticker_editor = editor;
      text_tab.makeData = () => ({ svg_el: text_el, fonts });

      color_tab.makeData = () => ({
        svg_el: text_el,
        one_line_length: $width(window) >= 640 ? 16 : 8,
      });
      border_tab.makeData = () => ({
        svg_el: text_el,
        one_line_length: $width(window) >= 640 ? 16 : 8,
      });
    });
  };

export const handleFooterTextOpenKeyboardIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    const text_el = go(
      editor.getSelectedEls(),
      filterL((el) => equals2(el.nodeName)('text')),
      head,
    );
    if (text_el == null) {
      return;
    }

    SVGEditorMobileTextInputF.create({ text_el, sticker_editor: editor });
    return MuiF.openFrame(SVGEditorMobileTextInputMuiF.frame, (frame) => {
      frame.closed = () => {
        SVGEditorMobileTextInputF.destroy();
        text_el.innerHTML.trim().length || editor.removeEl(text_el);
      };
    });
  };

export const handleFooterTextDuplicateIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.duplicateFirstSelectedEl(editor);
  };

export const handleFooterTextDeleteIconClick =
  ({ tab_el }) =>
  () => {
    const editor = tab_el.__mp_single_sticker_editor;
    if (editor == null) {
      return;
    }

    VectorEditorStickerSingleMobileF.deleteAllSelectedEls(editor);
  };
