import axios from 'axios';
import * as XLSX from 'xlsx';
import { html, go, pick, map, strMap, uniqueBy, mapObject, pluck } from 'fxjs/es';
import { makeApiUrl } from '../../../../modules/Util/S/Function/util.js';
import { TabulatorFull as Tabulator } from 'tabulator_new';
import 'tabulator-tables/dist/css/tabulator_site.min.css';

import Swal from 'sweetalert2';
import { $attr, $closest, $delegate, $el, $find, $removeAttr } from 'fxdom/es';
import { UtilAlertF } from '../../../../modules/Util/Alert/F/Function/module/UtilAlertF.js';
import { UtilArrayS } from '../../../../modules/Util/Array/S/Function/module/UtilArrayS.js';
import format from 'date-fns/format/index.js';

export async function openSkuList({ bp }) {
  $.don_loader_start();
  const skus = (await axios.get(makeApiUrl('/@api/df/skus/bp/:bp_id', { bp_id: bp.id }))).data;
  $.don_loader_end();
  const sku_table_el = createSkuTable({ skus, bp });
  await Swal.fire({
    html: sku_table_el,
    width: '90%',
    grow: true,
    confirmButtonText: '나가기',
    didOpen: skuControlEvents,
  });
}

const BP_BPS_BPC_FILTER = [
  { field: 'bp_id', type: '!=', value: null },
  { field: 'bpc_id', type: '!=', value: null },
  { field: 'bps_id', type: '!=', value: null },
];

function createSkuTable({ skus, bp }) {
  const is_sku_empty = UtilArrayS.isEmNil(skus);
  const table_container_el = $el(html` <div class="sku-table-container">
    <h1 class="title">재고관리번호 SKU 목록</h1>
    <h2 class="bp_name">[ ${bp.name} ]</h2>
    <div class="controls">
      <div class="filters">
        ${strMap(
          ([label, value]) => {
            const id = `${value}_check`;
            const default_check = value === 'all';
            return html`<div class="filter">
              <input
                type="radio"
                id="${id}"
                value="${value}"
                name="filter_option"
                ${default_check ? 'checked' : ''}
              />
              <label for="${id}">${label}</label>
            </div>`;
          },
          [
            ['전체', 'all'],
            ['상품대표', 'bp'],
            ['상품+컬러', 'bp_bpc'],
            ['상품+사이즈', 'bp_bps'],
            ['상품+컬러+사이즈', 'bp_bpc_bps'],
          ],
        )}
      </div>
      <div class="buttons">
        <button type="button" name="renew-sku" bp_id="${bp.id}">SKU 갱신</button>
        <button type="button" name="update-sku-supplier" bp_id="${bp.id}" ${is_sku_empty ? 'disabled' : ''}>
          SKU 저장
        </button>
        <button type="button" name="download-excel" bp_id="${bp.id}" ${is_sku_empty ? 'disabled' : ''}>
          엑셀 다운로드
        </button>
        <button type="button" name="upload-excel" bp_id="${bp.id}" ${is_sku_empty ? 'disabled' : ''}>
          엑셀 업로드
        </button>
      </div>
    </div>
  </div>`);

  const table_el = document.createElement('div');

  const tabulator = new Tabulator(table_el, {
    data: skus,
    index: 'sku',
    layout: 'fitData',
    height: '100%',
    columns: [
      { title: 'SKU-마플', field: 'sku' },
      { title: 'SKU-업체', field: 'procure_sku', editor: 'input', editable: true },
      { title: 'SKU-고유이름', field: 'name' },
      { title: '상품번호', field: 'bp_id' },
      { title: '컬러번호', field: 'bpc_id' },
      { title: '사이즈번호', field: 'bps_id' },
      { title: '컬러', field: 'bpc_name' },
      { title: '사이즈', field: 'bps_name' },
      { title: '가로', field: 'length', editor: 'number', editable: false },
      { title: '세로', field: 'width', editor: 'number', editable: false },
      { title: '높이', field: 'height', editor: 'number', editable: false },
      { title: '무게', field: 'weight', editor: 'number', editable: false },
    ],
  });

  table_el.tabulator = tabulator;
  table_container_el.appendChild(table_el);

  return table_container_el;
}

async function skuControlEvents(el) {
  go(
    el,
    $delegate('click', '.controls button', async ({ currentTarget: ct }) => {
      const tabulator = go(ct, $closest('.sku-table-container'), $find('.tabulator'))?.tabulator;
      const bp_id = $attr('bp_id', ct);
      switch (ct.name) {
        // SKU 갱신
        case 'renew-sku': {
          try {
            $.don_loader_start();

            // SKU 모든 bp, bps, bpc 조합 생성
            await axios.post('/@api/df/skus/bp', { bp_id });
            await axios.post('/@api/df/skus/bp_bps', { bp_id });
            await axios.post('/@api/df/skus/bp_bpc', { bp_id });
            await axios.post('/@api/df/skus/bp_bpc_bpc', { bp_id });

            const skus = (await axios.get(makeApiUrl('/@api/df/skus/bp/:bp_id', { bp_id }))).data;
            if (skus.length === 0) {
              await UtilAlertF.warning({
                title: '갱신 완료',
                msg: '갱신될 SKU 항목이 존재하지 않습니다.',
              });
              $.don_loader_end();
              return;
            }

            await tabulator.updateOrAddData(skus);
            go(
              ct,
              $closest('.sku-table-container'),
              $find('.controls button[name="update-sku-supplier"]'),
              $removeAttr('disabled'),
            );
            go(
              ct,
              $closest('.sku-table-container'),
              $find('.controls button[name="download-excel"]'),
              $removeAttr('disabled'),
            );
            go(
              ct,
              $closest('.sku-table-container'),
              $find('.controls button[name="upload-excel"]'),
              $removeAttr('disabled'),
            );

            $.don_loader_end();
          } catch (err) {
            $.don_loader_end();
            console.error(err);
            const err_msg = err.isAxiosError
              ? err.response.data?.message
                ? err.response.data.message
                : err.response.data
              : err?.message;
            $.alert(err_msg);
          }

          break;
        }

        // 공급처 SKU 업로드
        case 'update-sku-supplier': {
          try {
            $.don_loader_start();
            const edited_cells = tabulator.getEditedCells();
            const edited_data = go(
              edited_cells,
              map((c) => {
                const data = mapObject((v) => (v === '' ? null : v), c.getRow().getData());
                const { width, height, length, weight } = data;
                if (width === 0 || height === 0 || length === 0 || weight === 0) {
                  throw new Error(`체적 정보에 0 은 허용되지 않습니다.`);
                }

                if (width != null || height != null || length != null || weight != null) {
                  if (Number(width) * Number(height) * Number(length) * Number(weight) === 0) {
                    throw new Error(`체적 정보는 가로, 세로, 높이, 무게 모든 값이 존재해야 합니다.`);
                  }
                }
                return data;
              }),
              uniqueBy((d) => d.sku),
            );
            if (UtilArrayS.isEmNil(edited_data)) {
              $.don_loader_end();
              $.alert('수정된 항목이 없습니다.');
              return;
            }

            const skus_data = go(edited_data, map(pick(['bp_id', 'bpc_id', 'bps_id', 'procure_sku'])));

            await axios.put('/@api/df/skus', {
              skus_data,
            });

            const skus = (await axios.get(makeApiUrl('/@api/df/skus/bp/:bp_id', { bp_id }))).data;

            await tabulator.updateOrAddData(skus);

            edited_cells.forEach((c) => c.clearEdited());
            $.don_loader_end();
          } catch (err) {
            $.don_loader_end();
            console.error(err);
            let err_msg = err.isAxiosError
              ? err.response.data?.message
                ? err.response.data.message
                : err.response.data
              : err?.message;

            if (err_msg.includes('skus_nickname_key')) {
              err_msg = 'SKU 업체코드의 중복 오류 입니다.';
            }

            $.alert(err_msg);
          }

          break;
        }
        case 'download-excel': {
          saveTabulatorDataToExcel({ tabulator, filename: `SKU_상품(${bp_id})` });
          break;
        }
        case 'upload-excel': {
          $.alert('기능 개발 예정입니다.');
          break;
        }
      }
    }),
    $delegate('change', '.controls .filter input[type="radio"]', ({ currentTarget: ct }) => {
      const tabulator = go(ct, $closest('.sku-table-container'), $find('.tabulator'))?.tabulator;

      tabulator.clearFilter();
      switch (ct.value) {
        case 'bp': {
          tabulator.setFilter([
            { field: 'bp_id', type: '!=', value: null },
            { field: 'bpc_id', type: '=', value: null },
            { field: 'bps_id', type: '=', value: null },
          ]);
          break;
        }
        case 'bp_bpc': {
          tabulator.setFilter([
            { field: 'bp_id', type: '!=', value: null },
            { field: 'bpc_id', type: '!=', value: null },
            { field: 'bps_id', type: '=', value: null },
          ]);
          break;
        }
        case 'bp_bps': {
          tabulator.setFilter([
            { field: 'bp_id', type: '!=', value: null },
            { field: 'bpc_id', type: '=', value: null },
            { field: 'bps_id', type: '!=', value: null },
          ]);
          break;
        }
        case 'bp_bpc_bps': {
          tabulator.setFilter(BP_BPS_BPC_FILTER);
          break;
        }
        case 'all': {
          break;
        }
      }
    }),
  );
}

function saveTabulatorDataToExcel({ tabulator, filename }) {
  // Get column headers
  const columns = tabulator.getColumns();
  const headers = columns.map((column) => ({
    title: String(column.getDefinition().title),
    field: column.getField(),
  }));

  // Get table data
  const tableData = tabulator.getData('active');

  // Create a worksheet
  const wsData = [pluck('title', headers), ...tableData.map((row) => headers.map(({ field }) => row[field]))];

  const ws = XLSX.utils.aoa_to_sheet(wsData);

  // Create a workbook and add the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Save the workbook to a file
  XLSX.writeFile(wb, `${filename}_${format(new Date(), 'yyyyMMdd_HHmmss')}.xlsx`);
}

export function guardSkuMeasurement(edit_data) {
  const { is_use_en, is_use_jp, is_use_creator_en, is_use_creator_jp } = edit_data;
  const is_sale_oversea = Boolean(is_use_en || is_use_jp || is_use_creator_en || is_use_creator_jp);

  if (is_sale_oversea) {
    const { width, length, height, weight } = edit_data;
    const has_measurements = Boolean(width && length && height && weight);

    if (!has_measurements) {
      throw new Error(`해외 판매시 체적정보 입력이 필요합니다.`);
    }
  }

  return edit_data;
}
