import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from '../../../../Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

export const resetPasswordTmpl = () => {
  const email = TT('auth::reset_password::reset_password_02');
  const email_placeholder = TT('auth::reset_password::reset_password_03');
  const reset_btn = TT('auth::reset_password::reset_password_04');

  return html`
    <div class="omp-reset-password">
      <form class="omp-reset-password__form">
        <div class="omp-reset-password__form-data">
          <div class="omp-reset-password__form-value">
            <span class="omp-reset-password__form-key">${email}</span>
            ${OMPCoreAtomTmplS.input({
              name: 'email',
              placeholder: email_placeholder,
              style: {
                height: 50,
                border: false,
              },
            })}
          </div>
        </div>

        <div class="omp-reset-password__button-wrapper">
          ${OMPCoreAtomTmplS.actionButton({
            text: reset_btn,
            solidity: 'thick',
            disabled: true,
            klass: 'omp-reset-password__button',
          })}
        </div>
      </form>
    </div>
  `;
};
