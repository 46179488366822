import qrcode from 'qrcode';
import { QrConstantS } from '../../../../Qr/S/Constant/module/QrConstantS.js';
import { PaperProductsWEConstantS } from '../Constant/module/PaperProductsWEConstantS.js';
import { WE_QR_PREFIX } from '../Constant/qr.js';
import { pipe } from 'fxjs/es';

// [prj, prj_id, pdf_id, is_we_combined]

export const makeProjectionQrText = ({ projection_id, pdf_id, is_we_combined }) =>
  `${QrConstantS.QR_PREFIX}${WE_QR_PREFIX},${PaperProductsWEConstantS.QR_ACTION.PRJ},${projection_id},${
    pdf_id || ''
  },,${is_we_combined}`;

export const makeProjectionQr = ({ projection_id, pdf_id, is_we_combined }, { type } = {}) => {
  const method = type && type == 'svg' ? 'toString' : 'toDataURL';
  return qrcode[method](makeProjectionQrText({ projection_id, pdf_id, is_we_combined }), {
    type,
    version: 3,
    margin: 0,
  });
};

// [up, prj_id, up_id, is_we_combined]

export const makeUpQrText = ({ projection_id, pdf_id, up_id, is_we_combined }) =>
  `${QrConstantS.QR_PREFIX}${WE_QR_PREFIX},${PaperProductsWEConstantS.QR_ACTION.PRODUCT},${projection_id},${pdf_id},${up_id},${is_we_combined}`;

export const makeUpQr = ({ up_id, projection_id, pdf_id, is_we_combined }, { type } = {}) => {
  const method = type && type == 'svg' ? 'toString' : 'toDataURL';
  return qrcode[method](makeUpQrText({ projection_id, pdf_id, up_id, is_we_combined }), {
    type,
    version: 3,
    margin: 0,
  });
};

export const parseQr = pipe(
  (str) => str.split(','),
  ([prefix, type, projection_id, pdf_id, up_id, is_we_combined]) => ({
    type,
    projection_id,
    up_id,
    pdf_id,
    is_we_combined: is_we_combined == 'true',
  }),
);
