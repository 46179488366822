import {
  each,
  eachL,
  go,
  isNil,
  mapL,
  rangeL,
  reduce,
  rejectL,
  takeAll,
  takeAllC,
  takeL,
  tap,
} from 'fxjs/es';
import { $append, $appendTo, $el, $findAll, $off, $on } from 'fxdom/es';
import { CommonNS, StrokeNS } from '@marpple/sticker-editor';
import { VectorEditorAcrylicFigureFreeMobileElementLayerMuiS } from '../../S/Mui/module/VectorEditorAcrylicFigureFreeMobileElementLayerMuiS.js';
import { VectorEditorAcrylicFigureFreeMobileElementLayerF } from '../Function/module/VectorEditorAcrylicFigureFreeMobileElementLayerF.js';
import { VectorEditorAcrylicFigureFreeMobileElementLayerTmplS } from '../../S/Tmpl/module/VectorEditorAcrylicFigureFreeMobileElementLayerTmplS.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...VectorEditorAcrylicFigureFreeMobileElementLayerMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {
    tab_el.__mp_acrylic_figure_editor = tab_el.__mp_acrylic_figure_editor ?? null;
    tab_el.__mp_editor_wrapper_el = tab_el.__mp_editor_wrapper_el ?? null;
    tab_el.__mp_handleAcrylicFigureEditorSelect = null;
    tab_el.__mp_handleAcrylicFigureEditorUnselect = null;
    tab_el.__mp_handleAcrylicFigureEditorLock = null;
    tab_el.__mp_handleAcrylicFigureEditorUnlock = null;
    tab_el.__mp_handleAcrylicFigureEditorShow = null;
    tab_el.__mp_handleAcrylicFigureEditorHide = null;

    go(
      tab_el,

      tap(
        $findAll(`.blank_plate`),
        each($on('click', VectorEditorAcrylicFigureFreeMobileElementLayerF.handleBlankPlateClick())),
      ),
    );
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    if (tab_el.__mp_acrylic_figure_editor != null) {
      const editor = tab_el.__mp_acrylic_figure_editor;
      const selected_els = editor.getSelectedEls();
      const locked_els = editor.getLockedEls();
      const list_item_els = go(
        editor.getGraphicsEls(),
        mapL((el) => {
          const label = (() => {
            if (editor.getIsGroupEl(el)) {
              return T(
                'modules::VectorEditor::AcrylicFigure::Free::Mobile::ElementLayer::template::layer_label_group',
              );
            }

            switch (el.nodeName?.toLowerCase?.()) {
              case 'text': {
                return T(
                  'modules::VectorEditor::AcrylicFigure::Free::Mobile::ElementLayer::template::layer_label_text',
                );
              }
              case 'circle':
              case 'path': {
                return T(
                  'modules::VectorEditor::AcrylicFigure::Free::Mobile::ElementLayer::template::layer_label_shape',
                );
              }
              case 'image': {
                return T(
                  'modules::VectorEditor::AcrylicFigure::Free::Mobile::ElementLayer::template::layer_label_image',
                  'modules::VectorEditor::AcrylicFigure::Free::Mobile::ElementLayer::template::layer_label_image',
                );
              }
              default: {
                return '';
              }
            }
          })();
          const list_item_el = $el(
            VectorEditorAcrylicFigureFreeMobileElementLayerTmplS.makeListItemHtml({
              label,
              is_selected: selected_els.has(el),
              is_locked: locked_els.has(el),
              is_visible: el.getAttributeNS(null, 'display') !== 'none',
            }),
          );
          list_item_el.__mp_el = el;

          go(
            list_item_el,
            tap(
              $on(
                'click',
                VectorEditorAcrylicFigureFreeMobileElementLayerF.handleListItemClick({
                  tab_el,
                  el,
                }),
              ),
            ),
            tap(
              $findAll(`.visible_container .visible_icon`),
              each(
                $on(
                  'click',
                  VectorEditorAcrylicFigureFreeMobileElementLayerF.handleListItemVisibleContainerClick({
                    tab_el,
                    el,
                  }),
                ),
              ),
            ),
            tap(
              $findAll(`.lock_container .lock_icon`),
              each(
                $on(
                  'click',
                  VectorEditorAcrylicFigureFreeMobileElementLayerF.handleListItemLockContainerClick({
                    tab_el,
                    el,
                  }),
                ),
              ),
            ),
          );

          return list_item_el;
        }),
        takeAll,
      );
      go(
        tab_el,
        $findAll(`.list_container .list_wrapper .list_inner_wrapper`),
        takeL(1),
        each((wrapper_el) => each($appendTo(wrapper_el))(list_item_els)),
      );
    }

    tab_el.__mp_handleAcrylicFigureEditorSelect =
      VectorEditorAcrylicFigureFreeMobileElementLayerF.handleAcrylicFigureEditorSelect({ tab_el });
    tab_el.__mp_handleAcrylicFigureEditorUnselect =
      VectorEditorAcrylicFigureFreeMobileElementLayerF.handleAcrylicFigureEditorUnselect({ tab_el });
    tab_el.__mp_handleAcrylicFigureEditorLock =
      VectorEditorAcrylicFigureFreeMobileElementLayerF.handleAcrylicFigureEditorLock({
        tab_el,
      });
    tab_el.__mp_handleAcrylicFigureEditorUnlock =
      VectorEditorAcrylicFigureFreeMobileElementLayerF.handleAcrylicFigureEditorUnlock({ tab_el });
    tab_el.__mp_handleAcrylicFigureEditorShow =
      VectorEditorAcrylicFigureFreeMobileElementLayerF.handleAcrylicFigureEditorShow({
        tab_el,
      });
    tab_el.__mp_handleAcrylicFigureEditorHide =
      VectorEditorAcrylicFigureFreeMobileElementLayerF.handleAcrylicFigureEditorHide({
        tab_el,
      });
    if (tab_el.__mp_editor_wrapper_el != null) {
      go(
        tab_el.__mp_editor_wrapper_el,
        tap($on('@mp/sticker-editor/select', tab_el.__mp_handleAcrylicFigureEditorSelect)),
        tap($on('@mp/sticker-editor/unselect', tab_el.__mp_handleAcrylicFigureEditorUnselect)),
        tap($on('@mp/sticker-editor/lock', tab_el.__mp_handleAcrylicFigureEditorLock)),
        tap($on('@mp/sticker-editor/unlock', tab_el.__mp_handleAcrylicFigureEditorUnlock)),
        tap($on('@mp/sticker-editor/show', tab_el.__mp_handleAcrylicFigureEditorShow)),
        tap($on('@mp/sticker-editor/hide', tab_el.__mp_handleAcrylicFigureEditorHide)),
      );
    }
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {
    return go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      mapL(async (list_item_el) => {
        const svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
        svg_el.setAttributeNS(null, 'width', '100%');
        svg_el.setAttributeNS(null, 'height', '100%');
        svg_el.setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid meet');
        svg_el.setAttributeNS(null, 'viewBox', '0 0 0 0');

        const dummy_g_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'g');
        svg_el.appendChild(dummy_g_el);

        const clone_el = /** @type {SVGElement} */ await CommonNS.UtilNS.deepCloneNode(list_item_el.__mp_el);
        clone_el.removeAttributeNS(null, 'display');
        svg_el.appendChild(clone_el);

        return { list_item_el, svg_el, dummy_g_el, clone_el };
      }),
      eachL(({ list_item_el, svg_el }) =>
        go(list_item_el, $findAll(`.image_container`), takeL(1), each($append(svg_el))),
      ),
      eachL(({ svg_el, clone_el }) => {
        const bbox = clone_el.getBBox();
        const matrix = go(
          rangeL(clone_el.transform?.baseVal?.numberOfItems ?? 0),
          mapL((i) => clone_el.transform?.baseVal?.getItem?.(i)),
          rejectL(isNil),
          mapL(({ matrix: m }) => m),
          (iter) => reduce((m1, m2) => m1.multiply(m2), svg_el.createSVGMatrix(), iter),
        );
        const view_box = go(
          [
            [bbox.x, bbox.y],
            [bbox.x + bbox.width, bbox.y],
            [bbox.x + bbox.width, bbox.y + bbox.height],
            [bbox.x, bbox.y + bbox.height],
          ],
          mapL(([x, y]) => {
            const point = svg_el.createSVGPoint();
            point.x = x;
            point.y = y;
            return point;
          }),
          mapL((point) => point.matrixTransform(matrix)),
          (points) =>
            reduce(
              (acc, { x, y }) => {
                acc.min_x = Math.min(acc.min_x, x);
                acc.min_y = Math.min(acc.min_y, y);
                acc.max_x = Math.max(acc.max_x, x);
                acc.max_y = Math.max(acc.max_y, y);
                return acc;
              },
              { min_x: Infinity, min_y: Infinity, max_x: -Infinity, max_y: -Infinity },
              points,
            ),
          ({ min_x, min_y, max_x, max_y }) => {
            const fit_width = max_x - min_x;
            const fit_height = max_y - min_y;
            const x_margin = fit_width / 10;
            const y_margin = fit_height / 10;
            return `${min_x - x_margin} ${min_y - y_margin} ${fit_width + x_margin * 2} ${
              fit_height + y_margin * 2
            }`;
          },
        );
        svg_el.setAttributeNS(null, 'viewBox', view_box);
      }),
      eachL(({ svg_el, dummy_g_el }) => {
        const stroke = new StrokeNS.ModelNS.Stroke({ svg_el, getCTM: () => dummy_g_el.getCTM() });
        const stroke_sync = new StrokeNS.ModelNS.StrokeSync({
          stroke,
          SVGGraphicsElement: window.SVGGraphicsElement,
          el: svg_el,
        });
        stroke_sync.sync();
      }),
      takeAllC,
    );
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, _v) {
    if (tab_el.__mp_editor_wrapper_el != null) {
      const wrapper_el = tab_el.__mp_editor_wrapper_el;
      if (tab_el.__mp_handleAcrylicFigureEditorSelect != null) {
        $off('@mp/sticker-editor/select', tab_el.__mp_handleAcrylicFigureEditorSelect)(wrapper_el);
      }
      if (tab_el.__mp_handleAcrylicFigureEditorUnselect != null) {
        $off('@mp/sticker-editor/unselect', tab_el.__mp_handleAcrylicFigureEditorUnselect)(wrapper_el);
      }
      if (tab_el.__mp_handleAcrylicFigureEditorLock != null) {
        $off('@mp/sticker-editor/lock', tab_el.__mp_handleAcrylicFigureEditorLock)(wrapper_el);
      }
      if (tab_el.__mp_handleAcrylicFigureEditorUnlock != null) {
        $off('@mp/sticker-editor/unlock', tab_el.__mp_handleAcrylicFigureEditorUnlock)(wrapper_el);
      }
      if (tab_el.__mp_handleAcrylicFigureEditorShow != null) {
        $off('@mp/sticker-editor/show', tab_el.__mp_handleAcrylicFigureEditorShow)(wrapper_el);
      }
      if (tab_el.__mp_handleAcrylicFigureEditorHide != null) {
        $off('@mp/sticker-editor/hide', tab_el.__mp_handleAcrylicFigureEditorHide)(wrapper_el);
      }
    }

    tab_el.__mp_acrylic_figure_editor = null;
    tab_el.__mp_editor_wrapper_el = null;
    tab_el.__mp_handleAcrylicFigureEditorSelect = null;
    tab_el.__mp_handleAcrylicFigureEditorUnselect = null;
    tab_el.__mp_handleAcrylicFigureEditorLock = null;
    tab_el.__mp_handleAcrylicFigureEditorUnlock = null;
    tab_el.__mp_handleAcrylicFigureEditorShow = null;
    tab_el.__mp_handleAcrylicFigureEditorHide = null;
  }, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
