import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPAuthEmailVerifyMuiF } from '../Mui/module/OMPAuthEmailVerifyMuiF.js';
import { OMPAuthEmailVerifyTmplS } from '../../S/Tmpl/module/OMPAuthEmailVerifyTmplS.js';
import { OMPAuthEmailVerifyF } from './module/OMPAuthEmailVerifyF.js';

export const openEmailVerifyPopUp = (user, closed) => {
  return OMPCoreUtilF.openFrameFit(OMPAuthEmailVerifyMuiF.frame, (f, p, [t]) => {
    f.el_class = 'title_center';
    p.title = TT('auth::email_verify::email_verify_04');
    t.makeData = () => user;
    f.closed = closed;
    t.template = OMPCoreUtilF.isMobile()
      ? OMPAuthEmailVerifyTmplS.emailVerifyPopUpMoTmpl
      : OMPAuthEmailVerifyTmplS.emailVerifyPopUpPcTmpl;
    t.appending = OMPAuthEmailVerifyF.popUpEvent;
  });
};
