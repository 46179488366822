import { go, reduce, each } from 'fxjs/es';
import { $attr, $qsa } from 'fxdom/es';
import { mat2, mat4, vec2, vec4 } from 'gl-matrix';
import { adjustment_items, changeMoveItemsActive } from './module/index.js';

export const getItemsValueForImageProcess = (program_name) =>
  go($qsa(`.editor_item input[program=${program_name}]`), (els) =>
    reduce(
      (item_values_obj, el) => {
        item_values_obj[$attr('item', el)] = Number(el.value);
        return item_values_obj;
      },
      {},
      els,
    ),
  );

export const getNonKernelMatrices = (non_kernel_els) => {
  const non_kernel_matrices = { multiplier: mat4.create(), shifter: vec4.create() };
  go(
    non_kernel_els,
    each((el) => {
      const item = $attr('item', el);
      const item_strength = Number(el.value);
      const item_multiplier_matrix = adjustment_items[item].multiplier(item_strength);
      const item_shifter_matrix = adjustment_items[item].shifter(item_strength);
      mat4.multiply(non_kernel_matrices.multiplier, non_kernel_matrices.multiplier, item_multiplier_matrix);
      vec4.add(non_kernel_matrices.shifter, non_kernel_matrices.shifter, item_shifter_matrix);
    }),
  );
  return non_kernel_matrices;
};

export const calcTransformFitting = (gl, transform_data) => {
  let { rotation, scale, x_shift, y_shift } = transform_data;
  scale += 1.0;
  rotation *= 100;
  const canvas_width = gl.canvas.width;
  const canvas_height = gl.canvas.height;
  const img_ratio = canvas_width / canvas_height;
  const sin = Math.abs(Math.sin((Math.PI / 180) * rotation));
  const cos = Math.abs(Math.cos((Math.PI / 180) * rotation));
  const rot_scale_factor = 1 / (cos + sin * (img_ratio > 1 ? img_ratio : 1 / img_ratio));

  const w_shift_margin_half_norm = (1 - (rot_scale_factor / scale) * (cos + sin / img_ratio)) / 2;
  const h_shift_margin_half_norm = (1 - (rot_scale_factor / scale) * (cos + sin * img_ratio)) / 2;

  const rot_matrix = mat2.create();
  mat2.fromScaling(
    rot_matrix,
    vec2.fromValues((canvas_width / scale) * rot_scale_factor, (canvas_height / scale) * rot_scale_factor),
  );
  mat2.rotate(rot_matrix, rot_matrix, (Math.PI / 180) * rotation);
  mat2.scale(rot_matrix, rot_matrix, vec2.fromValues(1 / canvas_width, 1 / canvas_height));
  const translate_x = x_shift * w_shift_margin_half_norm;
  const translate_y = -y_shift * h_shift_margin_half_norm;
  changeMoveItemsActive(w_shift_margin_half_norm, h_shift_margin_half_norm);
  return { rot_matrix, translate_x, translate_y, scale };
};
