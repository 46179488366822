import { $find, $setHTML, $setScrollTop } from 'fxdom/es';
import { go, reject } from 'fxjs/es';
import { NewMakerProductStyleS } from '../../../../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { OMPCoreUtilF } from '../../../../Core/Util/F/Function/module/OMPCoreUtilF.js';
import { OMPProductListF } from '../../../F/Function/module/OMPProductListF.js';
import { OMPProductListTmplS } from '../../../S/Tmpl/module/OMPProductListTmplS.js';

export const finishActionModal = (tab_el) => {
  $setScrollTop(0, $find('.opm-product-list-bps', tab_el));
  OMPCoreUtilF.imageLazyLoad(tab_el.tab_opt.lazy);
};
export const axiosGetBpsWithoutCarvedBps = (query) => {
  return go(OMPProductListF.axiosGetBps(query), reject(NewMakerProductStyleS.isCarvedPhoneCase));
};

export const setBpsWithFilter = ({ checked_filter_items, product_list_tap_el, query }) => {
  const params = OMPProductListF.makeQueryWithFilterModal({
    checked_filter_items,
    product_list_tap_el,
    query,
  });

  return go(axiosGetBpsWithoutCarvedBps(params), (bps) => {
    const omp_product_list_bps_el = $find('.opm-product-list-bps', product_list_tap_el);
    if (OMPCoreUtilF.isMobile()) {
      $setHTML(OMPProductListTmplS.moListBody(bps), omp_product_list_bps_el);
    } else {
      $setHTML(OMPProductListTmplS.pcListBody(bps), omp_product_list_bps_el);
    }
    finishActionModal(product_list_tap_el);
    return params;
  });
};
