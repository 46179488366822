import { MakerDebugF } from '../../../../MakerDebug/F/Function/module/MakerDebugF.js';
import { getProductColorInMaker } from '../../../../Maker/F/getSth.js';

export const errorHandlingEventWrapper = (eventFunc, category, category_item) => {
  return async (e) => {
    try {
      await eventFunc(e);
    } catch (err) {
      $.don_loader_end();
      if (err.__mp_message) return;
      MakerDebugF.api.MakerDebugPost(getProductColorInMaker(), err, category, category_item);
    }
  };
};
