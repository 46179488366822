/*
 * tab의 함수중 infinite 함수안에서 MuiF.tabInfinite 를 실행할때 옵션값을 미리 정해둘 수 있습니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#4d6a1f4539274c0885771f559c3655af
 * */
export const infinite_opt = {
  container: '', // 인피니티 스크롤을 적용할 컨테이너 엘리먼트
  limit: 24, // 데이터를 가져올때 리밋값
  col: 1, // 무한스크롤 컬럼 갯수
  off: false, // true 이면 더 보기 버튼달림 (더 보기를 누른후 무한스크롤 적용)
  makeInfiniteData(offset, limit) {}, // 스크롤이 바닥에 가까워질때 가져올 데이터를 리턴하는 함수 (배열)
  template(data) {}, // 무한스크롤에 들어갈 .infi_item의 html을 그리는 함수
  appending(els$) {}, // 엘리먼트가 컨테이너에 붙기 전 울리는 함수
  appended(els$) {}, // 엘리먼트가 컨테이너에 붙은 후 울리는 함수
};
