import { renderWebgl, setUniforms } from '../helpers/shaders.js';
import glueFragmentShaderSrc from '../../shaders/fragment/glue.fragment.glsl';
import { loadBaseWebGlImageCanvas } from '../helpers/webglCanvas.js';

export async function getBlurBinaryImageSrc({ image, offsetPixel, color }) {
  const { gl, webglProgram } = loadBaseWebGlImageCanvas({
    image,
    fragmentShaderSrc: glueFragmentShaderSrc,
  });

  /* Transfer Uniform */
  const isFlip = true;
  setUniforms({
    gl,
    webglProgram,
    uniforms: [
      { name: 'u_flipY', type: '1f', values: [isFlip ? -1.0 : 1.0] },
      {
        name: 'u_resolution',
        type: '2f',
        values: [gl.canvas.width, gl.canvas.height],
      },
      { name: 'u_blurSize', type: '1f', values: [offsetPixel / 2.0] },
      { name: 'u_color', type: '4f', values: color },
    ],
  });

  /* Render webgl */
  renderWebgl({ gl, points: 6 });

  return gl.canvas.toDataURL('image/png', 1);
}
