// const info1 = {
//   event_name: 'shinhanfriends',
//   public_name: '신한 프렌즈 이벤트',
//   bp_id: 3179,
//   base_product_face_ids: [5026],
//   view_style: 'custom-detail-style-a',
//   is_only_one_face: true,
//   no_story: true,
//   no_review: true,
//   event_url: 'shinhanfriends',
//   url: 'shinhanfriends/maker',
//   coupon: '신한브랜드',
//   purchase_limit_quantity: 1,
//   don_frame_key: 'shinhan',
//   box_folders: 'shinhan_folders',
//   box_group_images: 'shinhan_group_images',
//   box_images: 'shinhan_images',
//   max_day_coupon_count: 100,
// };

// const info2 = {
//   event_name: 'shinhanfriends2',
//   public_name: '신한 프렌즈 이벤트',
//   bp_id: 3179,
//   base_product_face_ids: [5026],
//   view_style: 'custom-detail-style-a',
//   is_only_one_face: true,
//   no_story: true,
//   no_review: true,
//   event_url: 'shinhanfriends',
//   url: 'shinhanfriends/maker',
//   coupon: '신한브랜드',
//   purchase_limit_quantity: 1,
//   don_frame_key: 'shinhan',
//   box_folders: 'shinhan_folders',
//   box_group_images: 'shinhan_group_images',
//   box_images: 'shinhan_images',
//   //   max_day_coupon_count: 100,
// };
//
const info3 = {
  event_name: 'shinhanfriends3',
  public_name: '신한 프렌즈 이벤트',
  bp_id: 3179,
  base_product_face_ids: [5026],
  view_style: 'custom-detail-style-a',
  is_only_one_face: true,
  no_story: true,
  no_review: true,
  event_url: 'shinhanfriends',
  url: 'shinhanfriends/maker',
  promotion_code_id: 11966,
  coupon: '신한프렌즈',
  purchase_limit_quantity: 1,
  don_frame_key: 'shinhan',
  box_folders: 'shinhan_folders',
  box_group_images: 'shinhan_group_images',
  box_images: 'shinhan_images',
  // max_day_coupon_count: 100,
};
export const info = info3;
