import { $delegate } from 'fxdom/es';
import { extend, go, pipe } from 'fxjs/es';
import {
  DF_ADVANCE_RESERVATION_DETAIL_FRAME,
  DF_ADVANCE_RESERVATION_DETAIL_PAGE,
  DF_ADVANCE_RESERVATION_DETAIL_TAB,
  DF_ADVANCE_RESERVATION_LIST_FRAME,
  DF_ADVANCE_RESERVATION_LIST_PAGE,
  DF_ADVANCE_RESERVATION_LIST_TAB,
} from '../../S/constant.js';
import { makeAdvanceReservationTrHtml } from '../../S/tmpl.js';
import './detail.js';

$.frame.defn_frame({
  frame_name: DF_ADVANCE_RESERVATION_LIST_FRAME,
  page_name: DF_ADVANCE_RESERVATION_LIST_PAGE,
});

$.frame.defn_page({
  page_name: DF_ADVANCE_RESERVATION_LIST_PAGE,
  tabs: [
    {
      tab_name: DF_ADVANCE_RESERVATION_LIST_TAB,
      appended: pipe(
        $delegate('click', '.btn_all_test', async function (e) {
          const result = await $.confirm('테스트 메일을 보내시겠습니까?');
          if (!result) return;

          $.don_loader_start();
          try {
            await go($.post('/@api/creator/ad_res_test', {}), async (data) => {
              if (!data.result) return $.alert(data.msg || '메일발송 실패');
              await $.alert('메일발송 완료.');
              location.reload();
            });
          } catch (err) {
            $.alert('메일발송 실패');
          }
          $.don_loader_end();
        }),
        $delegate('click', '.btn_all_pass_mail', async function (e) {
          const result = await $.confirm(
            '합격자에게 메일을 보내시겠습니까?<br>메일 시스템의 제한으로 한번 요청에 최대 10명까지 발송되도록 개발되었습니다.<br>모든 합격자에게 메일이 발송이 될때까지 요청해 주세요.',
          );
          if (!result) return;

          $.don_loader_start();
          try {
            await go($.post('/@api/creator/ad_res_pass', {}), async (data) => {
              if (!data.result) {
                await $.alert(data.msg || '메일발송 실패');
                location.reload();
                return;
              }
              await $.alert('메일발송 완료.');
              location.reload();
            });
          } catch (err) {
            $.alert('메일발송 실패');
          }
          $.don_loader_end();
        }),
        $delegate('click', '.btn_all_fail_mail', async function (e) {
          const result = await $.confirm('모든 미선정자에게 메일을 보내시겠습니까?');
          if (!result) return;

          $.don_loader_start();
          try {
            await go($.post('/@api/creator/ad_res_fail', {}), async (data) => {
              if (!data.result) return $.alert(data.msg || '메일발송 실패');
              await $.alert('메일발송 완료');
              location.reload();
            });
          } catch (err) {
            await $.alert('메일발송 실패');
          }
          $.don_loader_end();
        }),
        $delegate(
          'click',
          '.advance_reservation_item .patching, .advance_reservation_item .description, .advance_reservation_item .memo',
          function (e) {
            const $el_advance_reservation_item = $.closest(e.currentTarget, '.advance_reservation_item');
            const advance_item = box.sel($el_advance_reservation_item);
            $.frame.open(
              {
                frame_name: DF_ADVANCE_RESERVATION_DETAIL_FRAME,
                animation: false,
                is_modal: true,
              },
              {
                page_name: DF_ADVANCE_RESERVATION_DETAIL_PAGE,
                tabs: [
                  {
                    tab_name: DF_ADVANCE_RESERVATION_DETAIL_TAB,
                    data_func: () => advance_item,
                    appended: pipe(
                      $delegate('click', '.patch button.btn_modify', async function (e) {
                        const $a_d_d = $.closest(e.currentTarget, '#advance_reservation_detail');
                        const type = $.val($.find1($a_d_d, 'select')) || void 0;
                        const memo = $.val($.find1($a_d_d, 'textarea')) || void 0;
                        const sns_url = $.val($.find1($a_d_d, 'input[name="sns_url"]')) || void 0;
                        const sample_url = $.val($.find1($a_d_d, 'input[name="sample_url"]')) || void 0;
                        const admin_sns_url = $.val($.find1($a_d_d, 'input[name="admin_sns_url"]')) || void 0;

                        try {
                          await go(
                            advance_item.type == type &&
                              advance_item.memo == memo &&
                              advance_item.admin_sns_url == admin_sns_url &&
                              advance_item.sns_url == sns_url &&
                              advance_item.sample_url == sample_url
                              ? void 0
                              : $.patch(`/@api/advance_reservation`, {
                                  type,
                                  memo,
                                  admin_sns_url,
                                  sns_url,
                                  sample_url,
                                  id: advance_item.id,
                                }),
                            () => extend(advance_item, { type, memo, admin_sns_url, sns_url, sample_url }),
                            () =>
                              $.remove(
                                $.after(
                                  $el_advance_reservation_item,
                                  makeAdvanceReservationTrHtml(advance_item),
                                ),
                              ),
                            () => $.alert('저장되었습니다.'),
                            () => $.frame.close(),
                          );
                        } catch (_err) {
                          console.error(_err);
                        }
                      }),
                      $delegate('click', 'button.btn_send_mail', async function () {
                        const result = await $.confirm('사전신청 승인 메일을 보내시겠습니까??');
                        if (!result) return;
                        $.don_loader_start();
                        try {
                          await go(
                            $.post('/@api/creator/ad_res_complete', {
                              ad_res: advance_item,
                            }),
                            function (data) {
                              if (!data || !data.result)
                                return go('메일보내기 실패 개발팀에게 문의해 주세요.', $.alert, () => {
                                  location.reload();
                                });
                              return $.alert('사전신청 승인 메일 보내기 완료');
                            },
                          );
                        } catch (err) {
                          $.alert('메일보내기 실패 다시 시도하거나 개발팀에게 문의해 주세요.');
                        }
                        $.don_loader_end();
                      }),
                    ),
                  },
                ],
              },
            );
          },
        ),
      ),
    },
  ],
});
