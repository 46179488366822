import DOMPurify from 'dompurify';
import { makeSelectFontsHtml, text } from '../../S/Tmpl/text.js';
import {
  $appendTo,
  $closest,
  $delegate,
  $el,
  $find,
  $findAll,
  $height,
  $on,
  $qs,
  $remove,
  $setCss,
  $toggleClass,
} from 'fxdom/es';
import { each, find, go } from 'fxjs/es';
import { SVGEditorTextF } from '../../../../Text/F/Function/module/SVGEditorTextF.js';
import { SVGEditorRangeSliderWrapperF } from '../../../../RangeSliderWrapper/F/Function/module/SVGEditorRangeSliderWrapperF.js';
import { SVGEditorColorF } from '../../../../Color/F/Function/module/SVGEditorColorF.js';
import { SVGEditorAttrS } from '../../../../Attr/S/Function/module/SVGEditorAttrS.js';
import { SVGEditorStrokeDashF } from '../../../../StrokeDash/F/Function/module/SVGEditorStrokeDashF.js';
import { NewMakerUtilF } from '../../../../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { sortFonts } from '../../../../../Maker/F/util.js';

export const fn = ({ svg_el, single_sticker_editor, fonts }) => {
  const ___selected_el = svg_el;
  NewMakerUtilF.systemFontAffectCanvasFont();
  return {
    el: $el(text({ svg_el, fonts })),
    removed: (el) => {
      el.myScroll?.destroy();
      el.myScroll = null;
      el.__pickr_font_tab?.destroyAndRemove();
      el.__pickr_font_tab = null;
      el.__pickr_border_tab?.destroyAndRemove();
      el.__pickr_border_tab = null;
    },
    appended: (el) => {
      if ($height(el) < go(el, $find('.scroll_wrapper'), $height)) {
        el.myScroll = new IScroll(el, {
          mouseWheel: true,
          scrollbars: true,
        });
      }
      go(
        el,
        $delegate('click', '.back', () => single_sticker_editor.selectEls([])),
        $delegate('click', '.tab', (e) => {
          el.dataset.current_tab = e.currentTarget.dataset.tab_name;
          go(
            el,
            $findAll('.range_slider_wrapper'),
            each((range_slider_wrapper_el) =>
              range_slider_wrapper_el.__range_slider.rangeslider('update', true),
            ),
          );
          el.myScroll?.refresh();
        }),
      );
      SVGEditorRangeSliderWrapperF.event({
        el,
        __mp_sticker_editor: single_sticker_editor,
        ___selected_el,
      });
      el.__pickr_font_tab = SVGEditorColorF.event({
        el: $find('.font_tab_contents')(el),
        key: 'fill',
        ___selected_el: svg_el,
      });
      el.__pickr_border_tab = SVGEditorColorF.event({
        el: $find('.border_tab_contents')(el),
        key: 'stroke',
        ___selected_el: svg_el,
      });
      SVGEditorStrokeDashF.event({ target_el: el, svg_el, mp_sticker_editor: single_sticker_editor });
      go(
        el,
        $delegate('click', '.font_style', (e) => {
          SVGEditorTextF.eventFontStyle({ e, ___selected_el, __mp_sticker_editor: single_sticker_editor });
        }),
        $delegate('keyup', 'input[data-key="innerHTML"]', (e) => {
          const _text = e.currentTarget.value;
          const text = SVGEditorTextF.getOnlyPossibleText(_text);
          e.currentTarget.value = text;
          ___selected_el.innerHTML = text;
          single_sticker_editor.syncSelectorWithSelection();
          if (_text?.length !== text?.length) {
            $.alert(T('maker::Please notice that unsupportive characters are going to be excluded.'));
          }
        }),
        $delegate('focusout', 'input[data-key="innerHTML"]', (e) => {
          const _text = e.currentTarget.value.trim();
          const purify = DOMPurify(window);
          const text = purify.sanitize(SVGEditorTextF.getOnlyPossibleText(_text), {
            USE_PROFILES: { svg: true, svgFilters: true },
          });

          e.currentTarget.value = text;
          ___selected_el.innerHTML = text;
          single_sticker_editor.syncSelectorWithSelection();
          if (_text?.length !== text?.length) {
            $.alert(T('maker::Please notice that unsupportive characters are going to be excluded.'));
          }
        }),
        $delegate('click', '.select_font', (e) => {
          sortFonts(fonts);
          openSelectFont({
            fonts,
            svg_el,
            __mp_sticker_editor: single_sticker_editor,
            parent_select_font_el: $find('.select_font')(el),
          });
        }),
      );
    },
  };
};
function makeBlank(target_el) {
  return go(
    document.createElement('div'),
    $setCss({
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }),
    $appendTo(target_el),
  );
}

export const openSelectFont = ({ svg_el, fonts, parent_select_font_el, __mp_sticker_editor }) => {
  const font_family = SVGEditorAttrS.get('font-family', svg_el);
  const tab_el = $closest('.don_tab')(parent_select_font_el);
  const blank_el = makeBlank(tab_el);
  const selected_font = find((font) => font.original_name === font_family)(fonts);
  const select_font_el = $el(makeSelectFontsHtml({ fonts, selected_font }));
  const select_el = parent_select_font_el.getBoundingClientRect();
  const panel_el = $closest('.don_tab', parent_select_font_el).getBoundingClientRect();
  $toggleClass('opened', parent_select_font_el);
  go(
    select_font_el,
    $appendTo(tab_el),
    $setCss({
      top: select_el.top - panel_el.top + $.outerHeight(parent_select_font_el) - 1,
      width: $.outerWidth(parent_select_font_el),
      left: select_el.left - panel_el.left,
    }),
    (select_font_el) => {
      const selected_font_el = $find('.i_item.selected')(select_font_el);
      const myScroll = new IScroll(select_font_el, {
        mouseWheel: true,
        scrollbars: true,
        fadeScrollbars: true,
      });
      myScroll.scrollToElement(selected_font_el, 0, true, true);
      $on('click', (e) => {
        myScroll.destroy();
        $remove(select_font_el);
        $remove(blank_el);
        $toggleClass('opened', parent_select_font_el);
      })(blank_el);
      go(
        select_font_el,
        $delegate('click', '.font', async (e) => {
          const selected_el = e.currentTarget;
          const select_font_el = $qs('.font_tab_contents .select_font');
          if (select_font_el) select_font_el.dataset.is_selected_font = 'true';
          await SVGEditorTextF.selectFontEvent({
            ___selected_el: svg_el,
            __mp_sticker_editor,
            selected_el,
            font_els: $findAll('.font')(tab_el),
          });
          const font_family = selected_el.dataset.font_family;
          const selected_font = find((font) => font.original_name === font_family)(fonts);
          go(parent_select_font_el, $find('.font'), (img_el) => (img_el.src = selected_font.url_2x));
        }),
      );
    },
  );
};
