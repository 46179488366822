import { DfInhouseF } from './module/DfInhouseF.js';
import { $addClass, $attr, $closest, $find, $hasClass, $removeClass } from 'fxdom/es';
import { go, tap } from 'fxjs/es';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';

// @description 사내 배송용 라벨 출력
export async function handleInhouseLabelPrint({ currentTarget: ct }) {
  const prj = go(ct, $closest('.prj_item'), box.sel);
  const { is_OK } = await DfInhouseF.handleScanProjectionLabel({ projection_id: prj.id, is_override: false });

  if (is_OK) {
    const img_el = $find('div.img', ct);
    if (!$hasClass('created', img_el)) {
      if (img_el) {
        $removeClass('null', img_el);
        $addClass('created', img_el);
      }
      ct.textContent = '주문서 라벨 출력 후';
    }
  }
}

// @description 사내 배송 주문 -> 사내 배송지 수정 기능
export async function handleInhouseShippingEdit({ projection, shipping_item_el }) {
  const shipping = box.sel(shipping_item_el);
  const shipping_id = shipping.id;

  /* 1. 가드 - 운송장 이미 발급된 것 -> 사내 배송지 수정 X */
  try {
    $.don_loader_start();
    const has_waybill = await DfInhouseF.apiCalls.get.hasShippingWaybill({ shipping_id });
    if (has_waybill) {
      return await DfInhouseF.alert.error({
        err: new Error(`운송장이 이미 발급되어 사내 배송지 수정이 불가합니다.`),
        title: '사내 배송 수정 불가',
      });
    }
  } catch (err) {
    return await DfInhouseF.alert.error({ err });
  } finally {
    $.don_loader_end();
  }

  /* 2. 가드 - 이미 inhouse_box 가 bucket 에 담긴 경우 -> 박스 제거 후 수정 요망 */
  let is_exist_inhouse_shipping_box;
  try {
    $.don_loader_start();
    const inhouse_shipping_box = await DfInhouseF.apiCalls.get.inhouseShippingBoxFromPrjId({
      projection_id: projection.id,
    });

    is_exist_inhouse_shipping_box = UtilObjS.isNotEmpty(inhouse_shipping_box);
    if (is_exist_inhouse_shipping_box) {
      if (inhouse_shipping_box.inhouse_shipping_bucket_id != null) {
        return await DfInhouseF.alert.error({
          err: new Error(
            `이미 사내 배송 버킷에 포함되었습니다.<br>버킷에서 제거 후 사내 배송지 수정이 가능합니다.`,
          ),
          title: '사내 배송 수정 불가',
        });
      }

      /* bucket 에 담기지는 않았지만 기존 주소로 라벨링 된 박스가 존재하는 경우 확인 의사 재 요청 */
      const { is_confirmed_address_change } = await DfInhouseF.alert.prompt.changeShippingAddressConfirm();
      if (!is_confirmed_address_change) {
        return;
      }
    }
  } catch (err) {
    console.error(err);
    return await DfInhouseF.alert.error({ err });
  } finally {
    $.don_loader_end();
  }

  /* 배송지 변경 선택 프롬프트 */
  const { id: customer_order_crew_inhouse_id, crew_inhouse_id } =
    shipping._.customer_order._.customer_order_crew_inhouse;

  const { isConfirmed, selected_crew_inhouse_shipping_id } =
    await DfInhouseF.alert.prompt.changeShippingAddress({
      input_options: DfInhouseF.getCrewCompanyInhouseOptions({ crew_inhouse_id }),
    });

  if (isConfirmed) {
    try {
      $.don_loader_start();

      /* 사내 배송지 연결 update */
      await DfInhouseF.apiCalls.post.customerOrderCrewInhouseCrewInhouseShippingId({
        customer_order_crew_inhouse_id,
        crew_inhouse_shipping_id: Number(selected_crew_inhouse_shipping_id),
      });

      /* gBox 신규 데이터로 sync */
      await DfInhouseF.stateProjection.gBox.hydrate.customerOrderCrewInhouse({
        projection_id: projection.id,
        customer_order_crew_inhouse_id,
      });

      if (is_exist_inhouse_shipping_box) {
        /*
         * 기존에 만든 box 가 있는 경우
         *  -> 라벨 갈이 해야한다고 경고
         *  -> 새 배송지로 라벨 자동 출력
         *  -> inhouse_shipping_box INSERT 하지 않음.
         * */
        await DfInhouseF.alert.warning({
          msg: `변경된 주소지 라벨로<br>반드시 재부착 바랍니다.`,
          title: '사내 배송 수정 경고',
        });

        const projection_id = projection.id;
        /* 주문 박스 라벨 변경된 주소로 재출력 */
        const { label_data } = await DfInhouseF.generateProjectionBoxLabelData({
          projection_id,
        });

        await DfInhouseF.printInhouseProjectionBoxLabel({
          CONFIG: DfInhouseF.LABEL_CONFIG.prj_box,
          label_data,
        });
      }
    } catch (err) {
      return await DfInhouseF.alert.error({ err });
    } finally {
      DfInhouseF.lottie_loader.end(1500);
    }
  }
}

// @description 사내 배송 주문 -> 사내 배송 <-> 개인 배송 전환 기능
export async function handleInhouseToggle({ ct, projection, shipping_item_el }) {
  const shipping = box.sel(shipping_item_el);
  const is_hidden_tobe = $attr('is_hidden_tobe', ct) === 'true';
  const toggle_text = is_hidden_tobe ? '사내 -> 개인' : '개인 -> 사내';

  /* 가드 - 운송장 이미 발급된 배송을 수정 시도하는 지 */
  try {
    $.don_loader_start();
    const has_waybill = await DfInhouseF.apiCalls.get.hasShippingWaybill({ shipping_id: shipping.id });
    if (has_waybill) {
      return await DfInhouseF.alert.error({
        err: new Error(`운송장이 이미 발급되어<br>${toggle_text} 배송 전환이 불가합니다.`),
        title: '사내 배송 수정 불가',
      });
    }
  } catch (err) {
    return await DfInhouseF.alert.error({ err });
  } finally {
    $.don_loader_end();
  }

  /* 2. 가드 - 이미 inhouse_box 가 bucket 에 담긴 경우 -> 박스 제거 후 수정 요망 */
  let is_exist_inhouse_shipping_box;
  try {
    $.don_loader_start();
    const inhouse_shipping_box = await DfInhouseF.apiCalls.get.inhouseShippingBoxFromPrjId({
      projection_id: projection.id,
    });
    is_exist_inhouse_shipping_box = UtilObjS.isNotEmpty(inhouse_shipping_box);
    if (is_exist_inhouse_shipping_box) {
      if (inhouse_shipping_box.inhouse_shipping_bucket_id != null) {
        return await DfInhouseF.alert.error({
          err: new Error(
            `이미 사내 배송 버킷에 포함되었습니다.<br>버킷에서 제거 후 ${toggle_text} 전환이 가능합니다.`,
          ),
          title: '전환 불가',
        });
      }
    }
  } catch (err) {
    return await DfInhouseF.alert.error({ err });
  } finally {
    $.don_loader_end();
  }

  /* 정말로 스위칭 할 것인지 확인 Prompt */
  const { isConfirmed } = await DfInhouseF.alert.prompt.switchingShippingWay({
    html: is_exist_inhouse_shipping_box
      ? `<span>${
          is_hidden_tobe ? '사내' : '개인'
        } 주소로 이미 라벨링 된 주문 포장 박스가 있습니다.<br>그래도 <b>${toggle_text}</b> 전환 하시겠습니까?</span>`
      : `<span>정말로 <b>${toggle_text}</b> 전환 하시겠습니까?</span>`,
  });
  if (isConfirmed) {
    try {
      $.don_loader_start();
      const { id: customer_order_crew_inhouse_id } =
        DfInhouseF.stateProjection.gBox.get.customer_order_crew_inhouse({
          projection,
          projection_id: projection.id,
        });

      /*  전환에 따라 사내 배송 shipping - is_hidden UPDATE
       *  is_hidden (true -> 개인 주소지 배송, false -> 사내 주소지 배송)
       * */
      await go(
        await DfInhouseF.apiCalls.post.customerOrderCrewInhouseIsHidden({
          projection_id: shipping.projection_id,
          customer_order_crew_inhouse_id,
          is_hidden: is_hidden_tobe,
        }),
        // 사내 -> 개인배송 전환시 이미 사내 라벨링 포장 박스 가 존재한다면 data 제거
        tap(async () => {
          if (is_hidden_tobe && is_exist_inhouse_shipping_box) {
            await DfInhouseF.apiCalls.delete.inhouseShippingBoxByPrjId({ projection_id: projection.id });
            await DfInhouseF.alert.warning({
              msg: `이미 부착되어 있는 사내 배송지 라벨은 <br>반드시 제거 바랍니다.`,
              title: '개인 배송 전환 경고',
            });
          }
        }),
        async ({ id: customer_order_crew_inhouse_id }) => {
          /* gBox 신규 데이터로 sync */
          await DfInhouseF.stateProjection.gBox.hydrate.customerOrderCrewInhouse({
            projection_id: projection.id,
            customer_order_crew_inhouse_id,
          });
        },
      );
    } catch (err) {
      return await DfInhouseF.alert.error({ err });
    } finally {
      $.don_loader_end();
    }
  }
}
