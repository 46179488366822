import { html } from 'fxjs/es';

export const kr20240904 = () => {
  return html`
    <div class="terms_body">
      <div class="title">
        <h1>240911 이용약관, 개인정보 취급방침 변경 안내</h1>
      </div>
      <div class="body">
        <p>안녕하세요. 마플코퍼레이션입니다.</p>
        <p>
          더 나은 서비스를 제공하기 위해 이용약관과 개인정보취급방침이 2024년 9월 11일자로 변경될 예정입니다.
          <br />
          이에 개정에 관한 내용을 사전에 알려드리니 회원 여러분께서는 새로운 약관을 확인하시고 참고하여
          주시기를 바랍니다.
        </p>
        <p>
          변경고지일 : 2024년 9월 4일<br />
          변경시행일 : 2024년 9월 11일
        </p>
        <h3>이용약관 변경 내용</h3>
        <table class="terms_preview">
          <tbody>
            <tr>
              <th>변경 전</th>
              <th>변경 후</th>
              <th>비고</th>
            </tr>
            <tr>
              <td>
                <strong>제2조(용어의 정의)</strong><br />② 이용자: 란 "몰"에 접속하여 이 약관에 따라 "몰"이
                제공하는 서비스를 받는 회원 및 비회원을 말합니다.
              </td>
              <td>
                <strong>제2조(용어의 정의)</strong><br /><span class="underline"
                  >② "마켓플레이스"란 회사가 "몰"을 통하여 제공하는 통신판매중개 서비스 및 관련 부가서비스
                  일체를 말합니다.</span
                ><br /><span class="underline"
                  >③ "이용자"란 "몰"에 접속하여 이 약관에 따라 "몰"이 제공하는 서비스를 받는
                  <span class="underline">회원 임시회원</span> 및 비회원을 말합니다.</span
                ><br /><span class="underline"
                  >⑦ "판매자"란 회사와 별도의 마켓플레이스 계약을 체결한 자 또는 "마플샵 스튜디오" 약관에
                  동의한 "판매자 회원" 등 실제로 "몰"의 서비스를 이용하여 상품을 판매하는 자를 말합니다.</span
                >
              </td>
              <td>임시회원,마켓플레이스,판매자 정의 추가</td>
            </tr>
            <tr>
              <td><strong>제6조(회원가입 등)</strong><br />(신설)</td>
              <td>
                <span class="underline"
                  ><strong>제6조(회원가입 등)</strong><br />⑥ 회사는 서비스의 안전한 제공을 위하여 본인확인이
                  이루어지지 않은 "임시 회원"에 대해서 서비스 이용 또는 상품 등 구매를 제한할 수
                  있습니다.</span
                ><span class="underline"
                  >⑦ 회사는 『영화 및 비디오물의 진흥에 관한 법률』 및 『청소년 보호법』 및 『화학물질
                  관리법』등 관련 법령에 따른 등급 및 연령제한 준수를 위하여 회원의 서비스 이용 또는 상품 등
                  구매를 제한하거나, 회원에게 나이 및 본인확인 등의 절차를 요구할 수 있습니다.</span
                >
              </td>
              <td>임시회원 서비스 이용 제한,연령제한 관련 조항 추가</td>
            </tr>
            <tr>
              <td>
                <strong>제7조(회원 탈퇴 및 자격 상실 등)</strong><br />회원은 "몰"에 언제든지 탈퇴를 요청할 수
                있으며 "몰"은 즉시 회원탈퇴를 처리합니다.
              </td>
              <td>
                <strong>제7조(회원 탈퇴 및 자격 상실 등)</strong><br /><span class="underline"
                  >① 회원은 "몰"에 언제든지 탈퇴를 요청할 수 있으며 "몰"은 즉시 회원탈퇴를 처리합니다. 단,
                  다음의 경우의 경우에는 즉시 탈퇴처리가 불가능하며 고객에게 사유를 안내합니다.<br />1.
                  배송완료 후 7일이 지나지 않은 진행중인 거래 및 주문이 있을 경우<br />2. 포인트나 예치금의
                  잔액이 남아 있을 경우<br />3. 크리에이터회원의 경우 정산하지 않은 잔여수익금액이 있을
                  경우</span
                >
              </td>
              <td>회원 탈퇴 즉시 처리 불가 사유 추가</td>
            </tr>
            <tr>
              <td>
                <strong>제9조(구매신청)</strong><br />1-1. 마플샵 판매 상품의 정의<br />1. 마플샵 배송 상품:
                '회사'가 '판매자회원'으로부터 '아이디어, 콘텐츠, 디자인'의 이용을 허락받아 의류, 핸드폰케이스,
                악세사리 등으로, '회사'는 '구매자회원'과 '상품'에 대한 매매계약을 직접 체결함으로써 이를
                "몰"을 통해 판매되는 상품을 의미합니다.<br />2. 셀러 배송 상품: '판매자회원'이 직접 '회사'에
                직접 등록해서 판매하고 있는 상품, '마플 배송 상품'과는 다르게 판매자회원이 직접 마플샵 위탁
                제조 방식을 이용하지 않고 판매자회원이 상품을 자체적으로 공급하여 직접 판매하는 상품을
                의미합니다.
              </td>
              <td>
                <strong>제9조(구매신청)</strong><br />1-1. 마플샵 판매 상품의 정의<br />가) 마플샵 배송 상품:
                '회사'가 '<span class="underline">크리에이터회원</span>'으로부터 '아이디어, 콘텐츠, 디자인'의
                이용을 허락받고 제작한 의류, 핸드폰케이스, 악세사리 등으로, '회사'가 '구매자회원'과 '상품'에
                대한 매매계약을 직접 체결함으로써 이를 "몰"을 통해 판매되는 상품을 의미합니다.<br />나)
                <span class="underline">크리에이터</span> 배송 상품: '판매자회원'이 직접 '회사'에 직접
                등록해서 판매하고 있는 상품, '마플샵 배송 상품'과는 다르게 판매자회원이 직접 마플샵 위탁 제조
                방식을 이용하지 않고 판매자회원이 상품을 자체적으로 공급하여 직접 판매하는 상품을 의미합니다.
              </td>
              <td>'셀러 배송 상품'을 '크리에이터 배송 상품'으로 용어 변경</td>
            </tr>
            <tr>
              <td><strong>제15조(청약철회 등)</strong><br />(크리에이터 배송 상품 관련 내용 추가)</td>
              <td>
                <strong>제15조(청약철회 등)</strong><br /><span class="underline"
                  >③ 마플샵 배송 상품이 아닌 판매자가 직접 판매하는 "크리에이터 배송 상품"의 청약철회가 필요할
                  경우에는 "몰" 이용자가 판매자와 직접 소통을 통하여 진행해야 합니다.<br />④ "크리에이터 배송
                  상품"의 상품 및 서비스적 결함 그리고 하자가 발생할 경우 "몰"은 통신판매중개자로 거래
                  당사자가 아니므로, 본 상품에 대한 청약철회의 책임 "몰"에게 없으며 해당 판매자에게
                  있습니다.</span
                >
              </td>
              <td>크리에이터 배송 상품 관련 청약철회 절차 추가</td>
            </tr>
            <tr>
              <td><strong>제16조(청약철회 등의 효과)</strong><br />(크리에이터 배송 상품 관련 내용 추가)</td>
              <td>
                <strong>제16조(청약철회 등의 효과)</strong><br /><span class="underline"
                  >④ 다만, 이용자의 구매 상품이 "크리에이터 배송 상품"이며, 교환/환불의 귀책사유가 판매자에게
                  있는 경우 공급받은 재화 등의 반환에 필요한 비용은 크리에이터가 직접 부담을 하고 있습니다.<br />⑤
                  "크리에이터 배송 상품"의 교환/환불의 귀책사유가 불분명한 경우는 이용자가 판매자와 직접
                  소통을 통해서 해결하는 것을 원칙으로 삼고 있습니다.</span
                >
              </td>
              <td>크리에이터 배송 상품 관련 청약철회 효과 추가</td>
            </tr>
            <tr>
              <td><strong>제18조(마켓플레이스 서비스 이용)</strong><br />(마켓플레이스 내용 추가)<br /></td>
              <td>
                <span class="underline"
                  >마켓플레이스 서비스와 관련하여 회사는 통신판매중개자로서 회원과 판매자 간의 자유로운 상품
                  등의 거래를 위한 시스템을 운영 및 관리, 제공할 뿐이므로, 이용자는 상품 등을 구매하기 전에
                  반드시 판매자가 사이버몰 내에 작성한 상품 등의 상세 내용과 거래의 조건을 정확하게 확인해야
                  합니다. 구매하려는 상품 등의 내용과 거래의 조건을 확인하지 않고 구매하여 발생한 모든 손해는
                  회원 본인이 부담합니다.</span
                >
              </td>
              <td>마켓플레이스 서비스 이용 관련 조항 신설, 이후 번호 밀림</td>
            </tr>
            <tr>
              <td>
                <strong>제22조 → 제23조(저작권의 귀속 및 이용제한)</strong><br />(디지털 상품 추가)<br />
              </td>
              <td>
                <strong>제23조(저작권의 귀속 및 이용제한)</strong><br /><span class="underline"
                  >⑧ 판매자회원의 디지털 상품의 저작권 보호는 아래 항목을 따릅니다.<br />1. "몰"은 디지털
                  상품(전자책, 디지털 파일 등)의 저작권을 존중하며, 모든 디지털 상품은 저작권법 및 2. 관련
                  법령에 의해 보호됩니다.<br />2. "몰"에서 제공하는 디지털 상품의 저작권은 원칙적으로 해당
                  콘텐츠를 제작한 저작권자에게 귀속됩니다.<br />3. 이용자는 디지털 상품을 구매함으로써
                  저작권자의 동의 없이 이를 복제, 배포, 송신, 전시, 공연, 2차적 저작물 작성 등의 방법으로
                  이용하거나 제3자에게 제공할 수 없습니다.<br />4. "몰"은 디지털 상품의 무단 복제 및 배포를
                  방지하기 위해 기술적 보호조치를 시행할 수 있으며, 이용자는 이러한 보호조치를 회피하거나
                  제거해서는 안 됩니다<br />5. 이용자가 저작권을 침해하는 행위를 할 경우, "몰"은 해당 콘텐츠의
                  제공을 중단하고, 법적 조치를 취할 수 있습니다.<br />6. 저작권 침해 신고가 접수된 경우,
                  "몰"은 이를 검토하여 정당한 권리자의 요청에 따라 해당 디지털 상품을 삭제하거나 접근을 제한할
                  수 있습니다. 이 과정에서 "몰"은 신고자와 피신고자에게 각각 통지하고, 이의 신청 및 소명
                  기회를 제공합니다.<br />7. "몰"은 이용자가 제공한 디지털 상품을 "몰"의 홍보 및 서비스 개선을
                  위한 목적으로 이용할 수 있으며, 이 경우 이용자의 사전 동의를 받습니다.<br />8. 상품의
                  구매자는 해당 상품을 개인적인 용도로만 사용할 수 있으며, 상업적 용도로 이용할 경우 사전에
                  저작권자의 허가를 받아야 합니다.</span
                >
              </td>
              <td>디지털 상품의 저작권 보호 관련 조항 추가</td>
            </tr>
            <tr>
              <td><strong>제23조 → 제24조(분쟁해결)</strong><br />(크리에이터 배송 상품 내용 추가)<br /></td>
              <td>
                <strong>제24조(분쟁해결)</strong><br /><span class="underline"
                  >④ 이용자는 판매자가 직접 등록한 "크리에이터 배송 상품" 관련 분쟁해결을 위해 홈페이지에서
                  판매자에게 직접 문의를 할 수 있으며, 판매자는 접수된 이용자의 문의에 성실하게 답하고 사건을
                  처리할 책임을 가지고 있습니다.<br />⑤ "크리에이터 배송 상품"의 하자와 결함의 원인이
                  판매자에게 있을 경우, 결제 대금 및 교환 그리고 환불에 대한 책임은 판매자에게 있으며 판매자는
                  이용자가 느끼는 불편함에 대해서 해결하며 사건을 종결시킬 책임을 가지고 있습니다.</span
                >
              </td>
              <td>크리에이터 배송 상품 관련 분쟁해결 절차 추가</td>
            </tr>
            <tr>
              <td><strong>제24조(적립금 제도 운영) → 제25조(포인트 제도)</strong><br /></td>
              <td>
                <span class="underline"><strong>제25조(포인트 제도)</strong><br /></span>
              </td>
              <td>조항 명칭 및 번호 변경</td>
            </tr>
            <tr>
              <td><strong>제27조(면책)</strong><br />(크리에이터 배송 상품 추가)<br /></td>
              <td>
                <strong>제27조(면책)</strong><br />
                ⑥ "몰"은 "크리에이터 배송 상품"에 한하여 이용자와 판매자간의 상품 공급과 이용을 위한 중개
                서비스만을 제공할 뿐, 판매자가 마켓을 통해 취급하는 상품에 대하여 어떠한 보증을 제공하지
                않습니다.<br />⑧ "크리에이터 배송 상품"에 한해서는 이용자가 주문을 확정하면 거래가 종료됩니다.
                따라서 주문 확정을 통하여 대금의 지급이 완료된 이후 발생한 분쟁 또는 불만 사항은 원칙적으로
                거래 당사자 간 협의를 통해 해결해야 합니다.
              </td>
              <td>크리에이터 배송 상품에 대한 "몰"의 책임 한계 명시</td>
            </tr>
          </tbody>
        </table>

        <h3>개인정보 취급방침 변경 내용</h3>
        <table class="terms_preview">
          <tbody>
            <tr>
              <th>변경 전</th>
              <th>변경 후</th>
              <th>비고</th>
            </tr>

            <tr>
              <td>
                <strong>제2조 수집하는 개인정보의 항목 및 수집방법</strong><br /><br />
                1. 필수항목<br />
                - 개인회원 : 전자우편(e-mail) 주소, 비밀번호, 닉네임, 전화번호(휴대전화), 생년월일,
                ci(연계정보), di(중복가입정보)
              </td>
              <td>
                <strong>제2조 수집하는 개인정보의 항목 및 수집방법</strong><br /><br />
                1. 일반 회원 계정 가입 시<br />[필수]<br />
                - 개인회원 : 전자우편(e-mail) 주소, 비밀번호, 닉네임, 전화번호(휴대전화), 생년월일<br />
                [선택]<br />
                <strong>- 간편 로그인 이용 시 해당 소셜 플랫폼 연동 정보</strong>, 생년월일, 성별, 개인 맞춤
                쇼핑을 위한 선호, 기본주소지<br /><br />
                2. 사업자회원 및 판매자회원 가입 시 [필수]<br />
                회사/기관명, 사업자등록번호, 담당자이름, 전자우편(e-mail) 주소, 비밀번호 전화번호(휴대전화),
                ci(연계정보), di(중복가입정보), 마케팅 정보 발송에 대한 정보, 기본주소지
              </td>
              <td>
                알아보기 쉽도록 서비스 과정단위로 필수 항목과 선택항목을 구분<br />간편 로그인 관련 내용 추가
              </td>
            </tr>
            <tr>
              <td>
                <strong>제5조 개인정보의 취급위탁</strong><br /><br />
                CJ 대한통운, 이니시스, 슈어엠, (주)탑퀵로지스, 스티비 주식회사, 주식회사 채널코퍼레이션,
                Amazon Web Service, 주식회사 다날, 아임포트, 네이버파이낸셜(주), 마플샵 입점 셀러
              </td>
              <td>
                <strong>제5조 개인정보의 취급위탁</strong><br /><br />
                CJ 대한통운, 한진택배, 로젠택배, 롯데글로벌로지스, 슈어엠, (주)탑퀵로지스, 스티비 주식회사,
                주식회사 채널코퍼레이션, Amazon Web Service, 주식회사 다날, 코리아포트원, 글로벌포트원,
                네이버파이낸셜(주), 마플샵 입점 판매자
              </td>
              <td>위탁업체 목록 추가 및 "마플샵 입점 셀러"를 "마플샵 입점 판매자"로 확대</td>
            </tr>
            <tr>
              <td>
                <strong>제9조 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</strong><br /><br />
                3. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저 옵션에서
                모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할
                수 있습니다.<br /><br />
                1. Internet Explorer의 경우<br />
                - [도구]메뉴에서 [인터넷 옵션]을 선택 → [개인정보]를 클릭 → [고급]을 클릭 → 쿠키 허용여부를
                선택<br /><br />
                2. Safari의 경우<br />
                - MacOS 상단 좌측 메뉴바에서 [Safari]에서 [환경설정]선택 → [환경설정]창에서 [보안]으로
                이동하여 쿠키 허용여부 선택
              </td>
              <td>
                <strong>제9조 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</strong><br /><br />
                3. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저 옵션에서
                모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할
                수 있습니다.<br /><br />
                <span class="underline">[웹브라우저에서 쿠키 허용/차단]</span><br />
                - 크롬(Chrome) : 웹 브라우저 설정 &gt; 개인정보 보호 및 보안 &gt; 인터넷 사용 기록 삭제<br />
                - 엣지(Edge) : 웹 브라우저 설정 &gt; 쿠키 및 사이트 권한 &gt; 쿠키 및 사이트 데이터 관리 및
                삭제<br /><br />
                <span class="underline">[모바일 브라우저에서 쿠키 허용/차단]</span><br />
                - 크롬(Chrome) : 모바일 브라우저 설정 &gt; 개인정보 보호 및 보안 &gt; 인터넷 사용 기록 삭제<br />
                - 사파리(Safari) : 모바일 기기 설정 &gt; 사파리(Safari) &gt; 고급 &gt; 모든 쿠키 차단<br />
                - 삼성 인터넷 : 모바일 브라우저 설정 &gt; 인터넷 사용 기록 &gt; 인터넷 사용 기록 삭제<br /><br />
              </td>
              <td>웹 브라우저 및 모바일 브라우저의 쿠키 설정 방법 업데이트</td>
            </tr>
          </tbody>
        </table>

        <p>
          변경된 서비스 이용약관은 2024년 9월 11일부터 적용됩니다. <br />변경된 이용약관에 동의하시지 않는
          경우, 마플, 마플샵 홈페이지 또는 고객센터를 통해 탈퇴를 요청하실 수 있습니다.
        </p>
        <p>
          변경된 약관의 사전 변경 고지 개시 후 7일 이내에 별도의 이의 제기를 하지 않는 경우에는 본 약관 변경에
          동의한 것으로 간주하여 변경 된 이용약관이 적용됩니다.
        </p>
      </div>
    </div>
  `;
};
