export const editor_mode = {
  filter: {
    display_name: 'filter',
    url_normal_icon:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/25ccaed0605813808dbc0badfe0b0b75211dd8951.png',
    url_select_icon:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/da16fc8b198a37bc92807282d6d4b6abd3cae52f1.png',
  },
  adjustment: {
    display_name: 'adjustment',
    url_normal_icon:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/ab9c78606179f30512e674a654c71645933736e61.png',
    url_select_icon:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/b25d52d1dbacaae890a4df3716362d8059235e161.png',
  },
  crop: {
    display_name: 'transform',
    url_normal_icon:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/57472e0038a4ad9532a7d63a2644bec2a83d62bd1.png',
    url_select_icon:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/b4c6aaa243f693a4fac2068f6ecd9f090a7e81131.png',
  },
};

export const preset_filters = {
  original: {
    display_name: `original`,
    is_show: true,
    items_value: {
      sharp_blur: 0,
      brightness: 0,
      contrast: 0,
      saturation: 0,
      red: 0,
      blue: 0,
      green: 0,
      transparent: 0,
      hue: 0,
      sepia: 0,
      kodachrome: 0,
      vintage: 0,
      polaroid: 0,
      technicolor: 0,
      emboss: 0,
      vibrance: 0,
      vignette: 0,
      noise: 0,
      invert: 0,
      yellow: 0,
      gamma: 0,
      brighten: 0,
      darken: 0,
    },
  },
  preset_1: {
    display_name: `Paris`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.245,"contrast":0.293,"gamma":-0.022,"brighten":-0.011,"darken":0,"vibrance":0.082,"yellow":0.016,"hue":0,"saturation":0,"red":0,"green":0,"blue":0,"sepia":0,"transparent":0,"sharp_blur":0,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0,"polaroid":0,"vintage":0,"vignette":0}',
    ),
  },
  preset_2: {
    display_name: `LosAngeles`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.059,"contrast":0.053,"gamma":0.152,"brighten":0.099,"darken":0.011,"vibrance":0.259,"yellow":0.239,"hue":0,"saturation":-0.017,"red":0,"green":0,"blue":0,"sepia":0,"transparent":0,"sharp_blur":0.172,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0,"polaroid":0,"vintage":0,"vignette":0}',
    ),
  },
  preset_3: {
    display_name: `Oslo`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.215,"contrast":0.091,"gamma":0.01,"brighten":0.034,"darken":0,"vibrance":0,"yellow":0.131,"hue":0,"saturation":-0.122,"red":0,"green":0,"blue":0,"sepia":0,"transparent":0,"sharp_blur":0,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0,"polaroid":0,"vintage":0,"vignette":0}',
    ),
  },
  preset_4: {
    display_name: `Lagos`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.234,"contrast":0.098,"gamma":-0.32,"brighten":0.073,"darken":0,"vibrance":0.071,"yellow":0.131,"hue":0,"saturation":-0.224,"red":-0.013,"green":0.016,"blue":0,"sepia":0,"transparent":0,"sharp_blur":0.075,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0,"polaroid":0,"vintage":0,"vignette":0}',
    ),
  },
  preset_5: {
    display_name: `Melbourne`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.366,"contrast":-0.054,"gamma":-0.244,"brighten":0.137,"darken":0,"vibrance":0.068,"yellow":0,"hue":0,"saturation":-0.015,"red":0,"green":0,"blue":0,"sepia":0,"transparent":0,"sharp_blur":0,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0,"polaroid":0,"vintage":0,"vignette":0}',
    ),
  },
  preset_6: {
    display_name: `Jakarta`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.202,"contrast":-0.026,"gamma":0.09,"brighten":0.002,"darken":-0.124,"vibrance":-0.028,"yellow":-0.017,"hue":0.003,"saturation":-0.004,"red":0,"green":0.047,"blue":0,"sepia":0.05,"transparent":0,"sharp_blur":0.111,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0,"polaroid":0,"vintage":0,"vignette":0}',
    ),
  },
  preset_7: {
    display_name: `AbuDhabi`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.111,"contrast":-0.061,"gamma":-0.138,"brighten":0.151,"darken":-0.134,"vibrance":-0.079,"yellow":0.062,"hue":1.945,"saturation":0.106,"red":0.028,"green":0.049,"blue":-0.002,"sepia":0,"transparent":0,"sharp_blur":0,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0,"polaroid":0,"vintage":0,"vignette":0}',
    ),
  },
  preset_8: {
    display_name: `SaoPaulo`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.192,"contrast":0.104,"gamma":-0.378,"brighten":0.097,"darken":-0.041,"vibrance":0.068,"yellow":0,"hue":6.449,"saturation":0.072,"red":0,"green":0.025,"blue":0.073,"sepia":0,"transparent":0,"sharp_blur":0,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0,"polaroid":0,"vintage":0,"vignette":0}',
    ),
  },
  preset_9: {
    display_name: `NewYork`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.18,"contrast":-0.024,"gamma":-0.201,"brighten":0,"darken":-0.149,"vibrance":-0.195,"yellow":0.083,"hue":0,"saturation":0.099,"red":0,"green":0.02,"blue":0.026,"sepia":0.07,"transparent":0,"sharp_blur":0.332,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0.07,"polaroid":0,"vintage":0.06,"vignette":0}',
    ),
  },
  preset_10: {
    display_name: `Cairo`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.29,"contrast":-0.324,"gamma":-0.447,"brighten":0.131,"darken":-0.08,"vibrance":-0.077,"yellow":0.029,"hue":0,"saturation":0.093,"red":0.04,"green":0.031,"blue":0,"sepia":0,"transparent":0,"sharp_blur":0,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0,"polaroid":0,"vintage":0.02,"vignette":0}',
    ),
  },
  preset_11: {
    display_name: `Moon`,
    is_show: true,
    items_value: JSON.parse(
      '{"brightness":0.23,"contrast":0.105,"gamma":-0.005,"brighten":0.116,"darken":0,"vibrance":0.112,"yellow":0.109,"hue":0,"saturation":-1,"red":0,"green":0.023,"blue":0.004,"sepia":0,"transparent":0,"sharp_blur":0.055,"noise":0,"invert":0,"emboss":0,"technicolor":0,"kodachrome":0,"polaroid":0,"vintage":0,"vignette":0}',
    ),
  },
};

export const adjustment_items = {
  brightness: {
    method: 'non_kernel',
    display_name: `brightness`,
    is_show: true,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/92dc4c497707c3c083424463a915117ce833750f1.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/b81310376db74b775c4e4a4f74506c50216197c01.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
    //prettier-ignore
    multiplier: (s) => {
      const coeff = 0.5;
      const adj = coeff * s;
      return [
        adj + 1, 0, 0, 0,
        0, adj + 1, 0, 0,
        0, 0, adj + 1, 0,
        0, 0, 0, 1,
      ]
    },
    shifter: (s) => [0, 0, 0, 0],
  },
  contrast: {
    method: 'non_kernel',
    display_name: `contrast`,
    is_show: true,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/d1a5d1bd0e3ab5242bd7fe485c52ebee7b308b4c1.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/6b337bd70ab1bfea6665eae300cb524ac33bcb7c1.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
    //prettier-ignore
    multiplier: (s) => {
      const coeff = 0.5;
      const adj = coeff * s;
      return [
        adj + 1, 0, 0, 0,
        0, adj + 1, 0, 0,
        0, 0, adj + 1, 0,
        0, 0, 0, 1,
      ]
    },
    shifter: (s) => {
      const coeff = 0.5;
      const adj = coeff * s;
      return [-0.5 * adj, -0.5 * adj, -0.5 * adj, 0];
    },
  },
  gamma: {
    method: 'single_component',
    display_name: `gamma`,
    is_show: true,
    uniform: 'u_gamma_strength',
    gl_name: 'image_editor',
    url_img_normal:
      '//s3.marpple.co/files/u_1187078/2021/2/original/f52c15ae949ab7af230848ffc89581a898c49f091.png',
    url_img_selected:
      '//s3.marpple.co/files/u_1187078/2021/2/original/336cebf5a7ae9dd58e4958900ee405e23196aa631.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
  },
  brighten: {
    method: 'single_component',
    display_name: `bright_area`,
    is_show: false,
    uniform: 'u_brighten',
    gl_name: 'image_editor',
    url_img_normal:
      '//s3.marpple.co/files/u_1187078/2021/3/original/9d215f66475fcd01f0447e98ce8ffdfce89b97a31.png',
    url_img_selected:
      '//s3.marpple.co/files/u_1187078/2021/3/original/7e64b930cac9c4381929ae81209f49762648c2ec1.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
  },
  darken: {
    method: 'single_component',
    display_name: `dark_area`,
    is_show: false,
    uniform: 'u_darken',
    gl_name: 'image_editor',
    url_img_normal:
      '//s3.marpple.co/files/u_1187078/2021/3/original/63ac435b5f1a96fc738ab59e369a762440fac6301.png',
    url_img_selected:
      '//s3.marpple.co/files/u_1187078/2021/3/original/693b2002e3d8e0d69e9dd49eb913ec2a66afbc111.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
  },
  vibrance: {
    method: 'single_component',
    display_name: `vibrance`,
    is_show: true,
    uniform: 'u_vibrance_strength',
    gl_name: 'image_editor',
    url_img_normal:
      '//s3.marpple.co/files/u_1187078/2021/1/original/7cdb29eab00c625764755fe911b8cbcb237fe3c21.png',
    url_img_selected:
      '//s3.marpple.co/files/u_1187078/2021/1/original/226c39e2250a4b4e101a0511bbb4bee50f73336b1.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
  },
  yellow: {
    method: 'non_kernel',
    display_name: `warmth`,
    is_show: true,
    url_img_normal:
      '//s3.marpple.co/files/u_1187078/2021/1/original/b44b940c0b1d07339bfc654a8e0254f1110c53941.png',
    url_img_selected:
      '//s3.marpple.co/files/u_1187078/2021/1/original/8c89b89d9730b059ac512d20be627c8fd702119e1.png',
    range_options: {
      value: 0,
      min: -1.0,
      max: 1.0,
      step: 0.001,
    },
    //prettier-ignore
    multiplier: (s) => {
      const shift = s / 2.0;
      return [
        1 + shift, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1 - shift, 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => {
      return [0, 0, 0, 0];
    },
  },
  hue: {
    method: 'non_kernel',
    display_name: `hue`,
    is_show: false,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/e37cf378273afe8a6eab2b11735f7b7e00659feb1.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/fd5a8cceb1045f717d8e5a2277592811323387a51.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1,
      step: 0.001,
    },
    //prettier-ignore
    multiplier: (s) => {
      s *= 360;
      const rotation = s / 180 * Math.PI;
      const x = Math.cos(rotation);
      const y = Math.sin(rotation);
      const RC = 0.213;
      const GC = 0.715;
      const BC = 0.072;
      return [
        RC + x * (1 - RC) + y * (-RC), GC + x * (-GC) + y * (-GC), BC + x * (-BC) + y * (1 - BC), 0,
        RC + x * (-RC) + y * 0.143, GC + x * (1 - GC) + y * 0.14, BC + x * (-BC) + y * -0.283, 0,
        RC + x * (-RC) + y * (RC - 1), GC + x * (-GC) + y * (GC), BC + x * (1 - BC) + y * BC, 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => [0, 0, 0, 0],
  },
  saturation: {
    method: 'non_kernel',
    display_name: `saturation`,
    is_show: false,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/68a31c9515893a430bd1f2efc15c95596afbfb891.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/213f67c4f7f18a7e8e7504e69542594f2948037f1.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
    //prettier-ignore
    multiplier: (s) => {
      const x = s * 2 / 3 + 1;
      const y = ((1 - x) / 2);
      return [
        x, y, y, 0,
        y, x, y, 0,
        y, y, x, 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => [0, 0, 0, 0],
  },
  red: {
    method: 'non_kernel',
    display_name: `red_tone`,
    is_show: false,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/8a45691c86f89bb4a6f4f55885813e007af6cb8d1.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/c431d9f8163c0cb3210482210d5dc1b35c8844281.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
    //prettier-ignore
    multiplier: (s) => {
      return [
        1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => {
      const coeff = 0.5;
      const adj = coeff * s;
      return [adj, 0, 0, 0];
    },
  },
  green: {
    method: 'non_kernel',
    display_name: `green_tone`,
    is_show: false,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/037be34caa266756ddc4fc803f4ee0a5a9b43c421.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/70ebf7896786b06cd71b4f1ae3f4b121728159471.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
    //prettier-ignore
    multiplier: (s) => {
      return [
        1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => {
      const coeff = 0.5;
      const adj = coeff * s;
      return [0, adj, 0, 0];
    },
  },
  blue: {
    method: 'non_kernel',
    display_name: `blue_tone`,
    is_show: false,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/fefc9c1e348ced7c6baa8f4187758883b11b195a1.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/dcc7a586f2057d970cd0f5cf42748bc228fab6091.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
    //prettier-ignore
    multiplier: (s) => {
      return [
        1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => {
      const coeff = 0.5;
      const adj = coeff * s;
      return [0, 0, adj, 0];
    },
  },
  sepia: {
    method: 'non_kernel',
    display_name: `brown_tone`,
    is_show: false,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/7be38af6fb3ddec916e6684ec7d52621a57fff961.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/7ae0312f1ac35eda69b5a98c091bfc5c6a6e56d61.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },
    //prettier-ignore
    multiplier: (s) => {
      return [
        0.393 * (s) + (1 - s), 0.7689999 * (s), 0.18899999 * (s), 0,
        0.349 * (s), 0.6859999 * (s) + (1 - s), 0.16799999 * (s), 0,
        0.272 * (s), 0.5339999 * (s), 0.13099999 * (s) + (1 - s), 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => [0, 0, 0, 0],
  },
  transparent: {
    method: 'non_kernel',
    display_name: `opacity`,
    is_show: false,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/9907a8e69b77f42dc32b9937963d855347762a851.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/09fd9365f09525e5bdcec9c042e8dcaf9957d4861.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1,
      step: 0.001,
    },
    //prettier-ignore
    multiplier: (s) => {
      return [
        1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => [0, 0, 0, -s],
  },
  sharp_blur: {
    method: 'kernel',
    display_name: `definition`,
    is_show: true,
    uniform: 'u_sharp_blur_size',
    gl_name: 'image_editor',
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/4375583a50514a3ebfcdfc4b072157fc9e062a1a1.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/3f2e5951cebb86e5ca3758715b5eaef3c19571fb1.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
  },
  noise: {
    method: 'single_component',
    display_name: `noise`,
    is_show: true,
    uniform: 'u_noise_strength',
    gl_name: 'image_editor',
    url_img_normal:
      '//s3.marpple.co/files/u_1187078/2021/1/original/d2ae0afa8b5ea95688b7b526c16b6a3997b701651.png',
    url_img_selected:
      '//s3.marpple.co/files/u_1187078/2021/1/original/93880eca11c0ac15f0bbe1c7bf5f14ff4baa103d1.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1.0,
      step: 0.001,
    },
  },
  invert: {
    method: 'non_kernel',
    display_name: `invert`,
    is_show: false,
    url_img_normal:
      '//s3.marpple.co/files/u_1187078/2021/1/original/77a058436b2a713e3aca827449c16e359051e1b81.png',
    url_img_selected:
      '//s3.marpple.co/files/u_1187078/2021/1/original/18d18b58c13b0e3cd4360d17bad47656acad5ddc1.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1,
      step: 1,
    },
    //prettier-ignore
    multiplier: (bool) => {
      const v = 1 - 2 * bool;
      return [
        v, 0, 0, 0,
        0, v, 0, 0,
        0, 0, v, 0,
        0, 0, 0, 1,
      ]
    },
    shifter: (bool) => [bool, bool, bool, 0],
  },
  emboss: {
    method: 'kernel',
    display_name: `texture`,
    is_show: false,
    uniform: 'u_emboss_strength',
    gl_name: 'image_editor',
    url_img_normal:
      '//s3.marpple.co/files/u_1187078/2021/1/original/0d548d20ce5840517944fa0b9d125e987e51ec2c1.png',
    url_img_selected:
      '//s3.marpple.co/files/u_1187078/2021/1/original/52b13a4e32412966533e7a4be72bcf068ecb88991.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1,
      step: 0.001,
    },
  },
  technicolor: {
    method: 'non_kernel',
    display_name: `technicolor`,
    is_show: false,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/978c1e96ae71a1b703b6acc92895a8f7a90c32bd1.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/b07295ef6c7283184a9e0f96755c1a3cfe8e96d91.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },
    //prettier-ignore
    multiplier: (s) => {
      return [
        1.9125277891456083 * (s) + (1 - s), -0.8545344976951645 * (s), -0.09155508482755585 * (s), 0,
        -0.3087833385928097 * (s), 1.7658908555458428 * (s) + (1 - s), -0.10601743074722245 * (s), 0,
        -0.231103377548616 * (s), -0.7501899197440212 * (s), 1.847597816108189 * (s) + (1 - s), 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => [0.04624942523 * s, -0.2758903985 * s, 0.1213762387 * s, 0],
  },
  kodachrome: {
    method: 'non_kernel',
    display_name: `film_camera`,
    is_show: true,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/191a696e77af8947115e620124871bc72e76b77b1.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/56cc5e3c4da3d61f34755d8f461c401eaf2947c71.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },
    //prettier-ignore
    multiplier: (s) => {
      return [
        1.1285582396593525 * (s) + (1 - s), -0.3967382283601348 * (s), -0.03992559172921793 * (s), 0,
        -0.16404339962244616 * (s), 1.0835251566291304 * (s) + (1 - s), -0.05498805115633132 * (s), 0,
        -0.16786010706155763 * (s), -0.5603416277695248 * (s), 1.6014850761964943 * (s) + (1 - s), 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => [0.2499199515 * s, 0.09698983489 * s, 0.139724816 * s, 0],
  },
  polaroid: {
    method: 'non_kernel',
    display_name: `polaroid`,
    is_show: true,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/8f0fdcb6539401713f9f5d4691401290b589660a1.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/7da0682099741008eb2465185d2ca4ce736e4d6e1.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },
    //prettier-ignore
    multiplier: (s) => {
      return [
        1.438 * (s) + (1 - s), -0.062 * (s), -0.062 * (s), 0,
        -0.122 * (s), 1.378 * (s) + (1 - s), -0.122 * (s), 0,
        -0.016 * (s), -0.016 * (s), 1.483 * (s) + (1 - s), 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => [0, 0, 0, 0],
  },
  vintage: {
    method: 'non_kernel',
    display_name: `vintage`,
    is_show: true,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/eb23852ebe2ffa897f5a3649f7632c687a239a891.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/024a08ca272149a89d440b6bec5499b42c9d279a1.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },
    //prettier-ignore
    multiplier: (s) => {
      return [
        0.6279345635605994 * (s) + (1 - s), 0.3202183420819367 * (s), -0.03965408211312453 * (s), 0,
        0.02578397704808868 * (s), 0.6441188644374771 * (s) + (1 - s), 0.03259127616149294 * (s), 0,
        0.0466055556782719 * (s), -0.0851232987247891 * (s), 0.5241648018700465 * (s) + (1 - s), 0,
        0, 0, 0, 1,
      ];
    },
    shifter: (s) => [0.03784817975 * s, 0.02926599677 * s, 0.02023211995 * s, 0],
  },
  vignette: {
    method: 'single_component',
    display_name: `vignette`,
    is_show: true,
    uniform: 'u_vignette_strength',
    gl_name: 'view',
    url_img_normal:
      '//s3.marpple.co/files/u_1187078/2021/1/original/5fe2ee2734b1c4fb9cd89992cc6943e4cf66fc301.png',
    url_img_selected:
      '//s3.marpple.co/files/u_1187078/2021/1/original/1789e9f8581f136c53ec3b0199d66e6ad0cf7b6e1.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1.0,
      step: 0.001,
    },
  },
};

export const crop_items = {
  scale: {
    method: 'transform',
    display_name: `size`,
    is_show: true,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/baa9c3479659af118d0e438b8805e02b022599391.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/d5dd4a0536906b78e9ce2fe6b93e552469360f661.png',
    range_options: {
      value: 0,
      min: 0,
      max: 1,
      step: 0.001,
    },
  },
  rotation: {
    method: 'transform',
    display_name: `rotate`,
    is_show: true,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/5cbb4638c06e8144da2bd3936c4bef67d6d098451.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/8e8c09331d65bac8b6f65686de82fa47cd2322b21.png',
    range_options: {
      value: 0,
      min: -0.9,
      max: 0.9,
      step: 0.001,
    },
  },
  x_shift: {
    method: 'transform',
    display_name: `move_A`,
    is_show: true,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/a36aa28e87b38729bf7ad669bf15d166a0b26d911.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/55719ac69fd99c18bf86235f13ea313f252c0df21.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
  },
  y_shift: {
    method: 'transform',
    display_name: `move_B`,
    is_show: true,
    url_img_normal:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/a4b1fb7894dc66dfd5205064e427e54c0d876f661.png',
    url_img_selected:
      'https://s3.marpple.co/files/u_1187078/2021/1/original/83e88c5e87d49cc1783b403a9e2f90dc12d814a81.png',
    range_options: {
      value: 0,
      min: -1,
      max: 1,
      step: 0.001,
    },
  },
};
