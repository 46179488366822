import { each, go, map, mapObject, pick, tap } from 'fxjs/es';
import { VectorEditorConstantS } from '../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import {
  getBaseProductInMaker,
  getCanvasOtherObject,
  getCanvasScreenRatio,
  getCvBpcf,
  getOtherCanvass,
  getRealFcanvass,
} from './getSth.js';
import { trimCanvasSize } from './canvas_trim.js';
import { getCurrentFcanvas } from './Fcanvas/cv_object.js';
import { NewMakerBaseProductsEtcS } from '../../NewMaker/BaseProducts/Etc/S/Function/module/NewMakerBaseProductsEtcS.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import { NewMakerTopUpperCanvasF } from '../../NewMaker/TopUpperCanvas/F/Function/module/NewMakerTopUpperCanvasF.js';
import axios from 'axios';
import { makeCloneCanvas } from '../../Canvas/S/util.js';
import { NewMakerUtilF } from '../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';

function makeCanvasObjectUnvisibleWithoutMe(cv_obj) {
  const last_visible = cv_obj.visible;
  const last_globalCompositeOperation = cv_obj.globalCompositeOperation;
  cv_obj.visible = true;
  cv_obj.globalCompositeOperation = 'source-over';
  return go(
    getCanvasOtherObject(cv_obj),
    each(function (d) {
      d._data.prev_visible = d.visible;
      d._data.prev_overflow = d.is_overflow;
      d.is_overflow = false;
      d.visible = false;
    }),
    tap(function () {
      cv_obj.canvas.renderAll();
      cv_obj.visible = last_visible;
      cv_obj.globalCompositeOperation = last_globalCompositeOperation;
    }),
    each(function (d) {
      d.visible = d._data.prev_visible;
      d.is_overflow = d._data.prev_overflow;
      delete d._data.prev_visible;
      delete d._data.prev_overflow;
    }),
  );
}

function _cloneFabricCanvasInMaker(canvas, enableRetinaScaling) {
  G.mp.maker.cleared_pass = true;
  const canvas_idx = _p.find_i(box.sel('maker->canvass'), (c) => c === canvas);
  const objects = _p.clone(canvas._objects);
  try {
    canvas.dispose();
  } catch (e) {
    console.error(e);
    axios.post(`/${T.lang}/@api/prerequisite_maker/error_logs`, {
      json_memo: {
        error_stack: e.stack,
      },
      name: 'maker_canvas_dispose',
    });
  }
  G.mp.maker.cleared_pass = false;
  canvas = new fabric.Canvas(
    canvas.lowerCanvasEl,
    _p.extend(
      _p.pick(canvas, [
        'bpf_id',
        'face_name',
        'face_name_en',
        'size_info',
        'default_ratio',
        'start_name',
        'preview',
        '_print_ratio',
        '_px_per_1cm',
        '_dpi',
        '_real_dpi',
        'fcanvas_data',
        'selectionColor',
        'selectionBorderColor',
      ]),
      {
        preserveObjectStacking: true,
        width: G.mp.maker.CANVAS_WIDTH,
        height: G.mp.maker.CANVAS_HEIGHT,
        enableRetinaScaling,
      },
    ),
  );
  canvas.getContext().imageSmoothingQuality = 'high';
  canvas.add.apply(canvas, objects);
  box.sel('maker->canvass')[canvas_idx] = canvas;
  G.mp.maker.canvas_event(canvas);
  if (UtilF.isLegacyMobile()) NewMakerTopUpperCanvasF.init(canvas);
  return canvas;
}

export const changeEditingCanvasByClone = function (ratio) {
  const prev_devicePixelRatio = fabric.devicePixelRatio;
  fabric.devicePixelRatio = ratio || 1;
  const canvas = _cloneFabricCanvasInMaker(box.sel('maker->editing_canvas'), true);
  box.set('maker->editing_canvas', canvas);

  fabric.devicePixelRatio = prev_devicePixelRatio;
  return canvas;
};

export const changeOtherCanvassByClone = function () {
  each((canvas) => _cloneFabricCanvasInMaker(canvas, false), getOtherCanvass(getCurrentFcanvas()));
};

export const makeImageToCanvas = function (img) {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  return canvas;
};

export const makeTrimedImageSize = function (canvas, sx = 0, sy = 0, sw = canvas.width, sh = canvas.height) {
  const ctx = canvas.getContext('2d');
  const imageData = ctx.getImageData(sx, sy, sw, sh);
  var width = imageData.width;
  let top = 0;
  let bottom = imageData.height;
  let left = 0;
  let right = imageData.width;

  while (top < bottom && rowBlank(imageData, width, top)) ++top;
  while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1)) --bottom;
  while (left < right && columnBlank(imageData, width, left, top, bottom)) ++left;
  while (right - 1 > left && columnBlank(imageData, width, right - 1, top, bottom)) --right;

  bottom = imageData.height - bottom;
  right = imageData.width - right;
  const height = imageData.height - top - bottom;
  var width = imageData.width - left - right;
  return { top, bottom, left, right, width, height };
};

const escape_sizes = [
  {
    bp_id: 4981,
    width_to_plus: 100,
    height_to_plus: 100,
  },
  {
    bp_id: 4982,
    width_to_plus: 50,
    height_to_plus: 50,
  },
  {
    bp_id: 5077,
    width_to_plus: 150,
    height_to_plus: 150,
  },
  {
    bp_id: 5078,
    width_to_plus: 200,
    height_to_plus: 200,
  },
];
const sizeInfo = (mobile_size_percent, canvas) => {
  const size = Math.round(canvas.width * (mobile_size_percent / 100));
  const top_margin = Math.round(
    (canvas.height - G.mp.maker.CANVAS_WIDTH_ORIGIN) / 2 + (G.mp.maker.CANVAS_WIDTH_ORIGIN - size) / 2,
  );
  const left_margin = Math.round((G.mp.maker.CANVAS_WIDTH_ORIGIN - size) / 2);
  return {
    width: size,
    height: size,
    top: top_margin,
    bottom: top_margin,
    left: left_margin,
    right: left_margin,
    x_diff: 0,
    y_diff: 0,
  };
};

function makeSize(canvas, bp) {
  return go(
    makeTrimedImageSize(
      NewMakerUtilF.makerUser.isMacChrome() ? makeCloneCanvas(canvas) : canvas,
      0,
      0,
      canvas.width,
      canvas.height,
    ),
    tap((size) => {
      if (UtilF.isLegacyMobile()) {
        const escaped_size = escape_sizes.find((es) => es.bp_id === bp?.id);
        if (escaped_size) {
          size.left -= escaped_size.width_to_plus / 2;
          size.width += escaped_size.width_to_plus;
          size.right -= escaped_size.width_to_plus / 2;
          size.top -= escaped_size.height_to_plus / 2;
          size.height += escaped_size.height_to_plus;
          size.bottom -= escaped_size.height_to_plus / 2;
        }
      }
      if (!UtilF.isLegacyMobile() && bp.id === 4398) {
        size.right -= 80;
        size.width += 80;
      }
      if (UtilF.isLegacyMobile() && (bp.id === 4445 || bp.id === 4448 || bp.id === 4449)) {
        size.left -= 60;
        size.width += 120;
        size.right -= 60;
      }
    }),
  );
}
export function setSizeInfo(f_canvas, bp) {
  const canvas = f_canvas.lowerCanvasEl;
  const ratio = getCanvasScreenRatio(f_canvas) || 1;
  const cached_data = NewMakerBaseProductsEtcS.BP_VIEW_SIZE[bp.id];
  f_canvas.size_info = go(
    cached_data?.mobile_size_percent
      ? sizeInfo(cached_data.mobile_size_percent, canvas)
      : makeSize(canvas, bp),
    mapObject(function (v) {
      return v * ratio;
    }),
    (size) => {
      size.y_diff = -((size.top - size.bottom) / 2);
      size.x_diff = -((size.left - size.right) / 2);
      return size;
    },
  );
}

export function makeAllRealBpcfSize(findCvFunc) {
  const bp = getBaseProductInMaker();
  return go(
    getRealFcanvass(),
    map((f_canvas) => {
      if (
        bp.maker_type !== VectorEditorConstantS.KEYRING_EDITOR &&
        bp.maker_type !== VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR
      )
        makeCanvasObjectUnvisibleWithoutMe(findCvFunc(f_canvas));
      setSizeInfo(f_canvas, bp);
      f_canvas.renderAll();
      return pick(['bpf_id', 'size_info'], f_canvas);
    }),
  );
}

export function makeAllRealBpcfSize2(findCvFunc) {
  return go(
    getRealFcanvass(),
    map((f_canvas) => {
      makeCanvasObjectUnvisibleWithoutMe(findCvFunc(f_canvas));
      const canvas = f_canvas.lowerCanvasEl;
      const ratio = getCanvasScreenRatio(f_canvas) || 1;
      f_canvas.size_info = go(
        trimCanvasSize(canvas, 30),
        mapObject(function (v) {
          return v * ratio;
        }),
        (size) => {
          size.y_diff = -((size.top - size.bottom) / 2);
          size.x_diff = -((size.left - size.right) / 2);
          return size;
        },
      );
      f_canvas.renderAll();
      return pick(['bpf_id', 'size_info'], f_canvas);
    }),
  );
}

export const makeBpcfAvgSize = function () {
  if (!G.mp.maker.is_available_canvas_idx()) G.mp.maker.editing_canvas(0, true);
  return _p.go(makeAllRealBpcfSize(getCvBpcf), function () {
    if (G.mp.maker.is_available_canvas_idx()) {
      var current_idx = G.mp.maker.editing_canvas_idx();
    } else {
      var current_idx = 0;
    }
    return _p.go(
      _p.range(G.mp.maker.get_bpcfs_length()),
      _p.map(function (num) {
        return _p.go(G.mp.maker.editing_canvas(num, true), function (f_canvas) {
          return f_canvas.size_info;
        });
      }),
      function (arr) {
        return {
          top: _p.min(arr, function (v) {
            return v.top;
          }).top,
          left: _p.min(arr, function (v) {
            return v.left;
          }).left,
          height: _p.max(arr, function (v) {
            return v.height;
          }).height,
          bottom: _p.min(arr, function (v) {
            return v.bottom;
          }).bottom,
          width: _p.max(arr, function (v) {
            return v.width;
          }).width,
        };
      },
      _p.tap(function () {
        G.mp.maker.editing_canvas(current_idx, true);
        G.mp.maker.editing_canvas().renderAll();
      }),
    );
  });
};

function rowBlank(imageData, width, y) {
  for (let x = 0; x < width; ++x) {
    if (imageData.data[y * width * 4 + x * 4 + 3] > 20) return false;
  }
  return true;
}

function columnBlank(imageData, width, x, top, bottom) {
  for (let y = top; y < bottom; ++y) {
    if (imageData.data[y * width * 4 + x * 4 + 3] > 20) {
      return false;
    }
  }
  return true;
}

export function hasSthInCanvas(imageData) {
  const width = imageData.width;
  let top = 0;
  let bottom = imageData.height;
  let left = 0;
  let right = imageData.width;

  while (top < bottom && rowBlank(imageData, width, top)) ++top;
  while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1)) --bottom;
  while (left < right && columnBlank(imageData, width, left, top, bottom)) ++left;
  while (right - 1 > left && columnBlank(imageData, width, right - 1, top, bottom)) --right;
  return top !== bottom || right !== width || left !== width;
}
