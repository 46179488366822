import { go, map } from 'fxjs/es';
import { addTdeisngsToFcanvasInMaker } from '../../../../../Maker/F/draw_product_faces.js';

export const makeCanvasToCheckWithAnyCvObs = async ({ cv_objs, width = 300, zoom = 1 }) => {
  /* 어떤 cv_obj 든 확인 할수 있게 */
  cv_objs = go(
    cv_objs,
    map((cv_obj) => ({
      ...cv_obj,
      visible: true,
      globalCompositeOperation: 'source-over',
    })),
  );
  const ratio = width / G.mp.maker.CANVAS_WIDTH_ORIGIN;

  const f_canvas = new fabric.StaticCanvas(document.createElement('canvas'), {
    width: G.mp.maker.CANVAS_WIDTH_ORIGIN * ratio,
    height: G.mp.maker.CANVAS_WIDTH_ORIGIN * ratio,
    enableRetinaScaling: false,
  });
  await addTdeisngsToFcanvasInMaker(cv_objs, f_canvas, ratio * zoom);
  f_canvas.renderAll();
  return f_canvas.lowerCanvasEl;
};
