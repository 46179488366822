import { $delegate } from 'fxdom/es';
import { go } from 'fxjs/es';
import { BACKGROUND_PROPERTY_NAME, TOOL_NAME } from '../../S/Constant/constants.js';
import { tools } from '../Lib/state.js';
import { DfImageEditorF } from './module/DfImageEditorF.js';
import { DfImageEditorLibF } from '../Lib/module/DfImageEditorLibF.js';
import { getComplementaryColor } from './helpers.paper.js';

export function toolBackground({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const printable_raster = DfImageEditorF.getPrintableRaster({ paper_scope });

  const color_property_class = `.property-panel section.${
    tools[TOOL_NAME.background].className
  } div.property[property-name=${
    tools[TOOL_NAME.background].properties[BACKGROUND_PROPERTY_NAME.color].propertyName
  }]`;
  go(
    tab_el,
    $delegate('click', `${color_property_class} button`, (e) => {
      const ct = e.currentTarget;
      let set_color = ct.name;
      if (set_color === 'auto') {
        const avg_color = printable_raster.getAverageColor(printable_raster.bounds);
        set_color = getComplementaryColor({ paper_color: avg_color });
      }

      DfImageEditorLibF.setState.property.background.color({ value: set_color });
    }),
    $delegate('input', `${color_property_class} input[type="color"]`, (e) => {
      const set_color = e.currentTarget.value;
      DfImageEditorLibF.setState.property.background.color({ value: set_color });
    }),
  );
}

export function activateBackgroundTool({ tab_el }) {
  const paper_scope = DfImageEditorF.getPaperScopeFromTabEl({ tab_el });
  const background_tool_name = TOOL_NAME.background;

  const background_tool = DfImageEditorF.prepareTool({
    paper_scope,
    tool_name: background_tool_name,
    eventHandlers: {},
    initializeFn: (tool) => {},
  });
  background_tool.activate();
}
