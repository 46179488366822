import { makeReboundTabHtml } from '../Tmpl/tmpl.tabs.js';
import { base_tab } from './base_tab.js';

const mui_ref_path = '/modules/Df/Lgl/ShippingDetail/S/Mui/tab_rebound.js';
export const tab_rebound = base_tab({
  mui_ref_path,
  title: '재입고 포장',
  tag: 'rebound',
  template: makeReboundTabHtml,
});
