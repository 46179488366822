import { format } from 'date-fns';
import dataStr from 'fxdom/es/dataStr.js';
import { entries, ippL, sortBy, filter, map, find, go, sel, html, strMap, join, sumBy } from 'fxjs/es';
import { UserProductS } from '../../../../../UserProduct/S/Function/module/UserProductS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreUtilS } from '../../../../Core/Util/S/Function/module/OMPCoreUtilS.js';
import { isAllOrders, upsertQueryParams } from '../../../../Core/Util/S/Function/util.js';
import { OMPMyPageConstantS } from '../../../S/Constant/module/OMPMyPageConstantS.js';
import { OMPMyPageOrderS } from '../Function/module/OMPMyPageOrderS.js';
import { OMPMyPageOrderTmplS } from './module/OMPMyPageOrderTmplS.js';
import { informationIcon } from './orderInformationIcon.js';

export const orderProductCellMo = (up_c, { hide_reorder_btn, show_pdf_printing_file = true } = {}) => {
  const product_face = sel('_.product_color.product_faces2.value.0', up_c);
  const thumbnail_url = go(sel('_.product_color.thumbnails.value', up_c), find(sel('is_thumb')), sel('url'));
  const name = up_c._.product_color._.base_product[OMPCoreUtilS.col('name')];

  const bp_thumbnail = up_c._.product_color._.base_product[OMPCoreUtilS.col('model_thumb_url')];
  const pdf_printing_file = up_c._.product_color._.pdf_printing_file || {};
  const product_pdf_printing_file_histories = up_c._.product_color._.product_pdf_printing_file_histories;
  const [pdf_printing_file_history] = product_pdf_printing_file_histories || [];
  const { issue_customer } = pdf_printing_file_history || {};

  const has_issue_customer =
    !UtilS.isEmpty(issue_customer) && !(up_c.table_data.is_cancel_req || up_c.table_data.is_canceled);

  const infos = go(
    up_c._.up_c_ss,
    filter((up_c_s) => !up_c_s.is_hidden),
    map((up_c_s) => {
      return {
        color: up_c._.product_color._.base_product_color[OMPCoreUtilS.col('name')],
        size: up_c_s._.base_product_size[OMPCoreUtilS.col('name')],
        quantity: UtilS.commify(up_c_s.quantity),
      };
    }),
  );

  const { is_bps_hidden } = up_c._.product_color._.base_product;

  const has_pdf_printing_file = !UtilS.isEmpty(pdf_printing_file);
  const pdf_printing_file_el = has_pdf_printing_file
    ? OMPMyPageOrderTmplS.orderPrintingFileTmpl({ pdf_printing_file, label: true })
    : '';
  const pdf_file_expiration = has_pdf_printing_file
    ? OMPCoreUtilS.isExpired({ expired_at: pdf_printing_file?.expired_at })
    : false;

  const { currency_en } = up_c.table_data;
  const { price, discounted_price } = OMPMyPageOrderS.makeUpcProductPrice(up_c, currency_en);
  const can_purchase_url =
    !hide_reorder_btn && up_c.base_product_id && !has_issue_customer
      ? OMPMyPageOrderS.makeUpcUrl(up_c)
      : false;

  const order_review_condition =
    ['confirmed', 'before_confirm', 'delivering'].includes(up_c.table_data.status) &&
    !up_c.table_data.is_cancel_req &&
    !up_c.table_data.is_canceled;

  const can_review = order_review_condition && !up_c.review_id;

  const [first_up_c_ss] = infos;

  const product_color = up_c._.product_color;
  const base_product_color = product_color._.base_product_color;
  const base_product = product_color._.base_product;
  const product_faces2 = product_color.product_faces2;
  const selected_option_group = product_color._.selected_option_group;

  const is_designed_by_worker = up_c.product_color_id === up_c.printable_product_id;

  const all_option_names = go(
    UserProductS.naming.makeOptionNames1(
      {
        selected_option_group,
        base_product,
        base_product_color,
        product_faces2,
      },
      '',
    ),
    sortBy((option) => option.option_group_name === '사이즈'),
  );

  const option_names = go(
    all_option_names,
    ippL,
    filter(([idx]) => {
      const is_last = idx + 1 === all_option_names.length;
      return UtilS.isEmpty(selected_option_group?.option_names) ? true : !is_last;
    }),
    map(([, option]) => option),
  );

  const [last_option_name] = [...all_option_names].reverse();

  const total_upcs_qty = sumBy((upcs) => upcs.quantity, up_c._.up_c_ss);

  const is_individual_canceled = total_upcs_qty === 0;

  const render_price = () => {
    return price === discounted_price
      ? OMPMyPageOrderS.getCurrency(price, currency_en)
      : html`
          <span class="omp-my-page-orders__order-product-discount-price">
            <span class="discounted">${OMPMyPageOrderS.getCurrency(price, currency_en)}</span>
            <span>${OMPMyPageOrderS.getCurrency(discounted_price, currency_en)}</span>
          </span>
        `;
  };

  return html`
    <div class="omp-my-page-orders__order-product" data-file-error="${has_issue_customer}">
      <div class="omp-my-page-orders__order-product-wrapper">
        <div class="omp-my-page-orders__order-product-info">
          <div
            class="omp-my-page-orders__order-product-thumbnail omp-order-product__thumbnail"
            data-fx-json="${dataStr(product_face)}"
          >
            ${has_pdf_printing_file && !show_pdf_printing_file
              ? html`<img src="${bp_thumbnail}" alt="" />`
              : html`${has_issue_customer ? informationIcon() : ''}
                  <a
                    href="${up_c.table_data.order_detail_url}"
                    class="${pdf_file_expiration ? 'expiration' : ''}"
                  >
                    ${pdf_file_expiration
                      ? `${TT('pdf::expired::1')}`
                      : UserProductS.thumbnail.smallCanvasHtml()}
                  </a>`}
          </div>
          <div class="omp-my-page-orders__order-product-spec">
            <a
              href="${up_c.table_data.order_detail_url}"
              class="omp-my-page-orders__order-product-detail omp-my-page-orders__order-product-name"
            >
              ${name}
            </a>
            <div class="omp-my-page-orders__order-product-options">
              ${go(
                option_names,
                map((option) => {
                  return `<span class="omp-my-page-orders__product__info-key">${option.option_group_name}</span>
                    <span class="omp-my-page-orders__product__info-value">${option.option_name}</span>`;
                }),
                join(''),
              )}
              ${!UtilS.isEmpty(selected_option_group?.option_names) && last_option_name
                ? html`
                    <span class="omp-my-page-orders__product__info-key"
                      >${last_option_name.option_group_name}
                    </span>
                    <div class="omp-my-page-orders__product__info-value-wrapper">
                      <div class="omp-my-page-orders__product__info-value-container">
                        <span class="omp-my-page-orders__product__info-value">
                          ${last_option_name.option_name}
                        </span>
                        <span class="omp-my-page-orders__product__info-value">
                          ${UtilS.safeStrToNumber(first_up_c_ss.quantity) > 0
                            ? TT('my_page::landing::landing_10', { count: first_up_c_ss.quantity })
                            : TT('my_page::landing::landing_21')}
                        </span>
                      </div>
                    </div>
                  `
                : ''}
              ${UtilS.isEmpty(selected_option_group?.option_names) && !is_bps_hidden
                ? html` <span class="omp-my-page-orders__product__info-key"
                      >${TT('my_page::order::detail::detail_06')}
                    </span>
                    <div class="omp-my-page-orders__product__info-value-wrapper">
                      ${strMap((info) => {
                        return html`
                          <div class="omp-my-page-orders__product__info-value-container">
                            <span class="omp-my-page-orders__product__info-value"> ${info.size} </span>
                            <span class="omp-my-page-orders__product__info-value">
                              ${UtilS.safeStrToNumber(info.quantity) > 0
                                ? TT('my_page::landing::landing_10', { count: info.quantity })
                                : TT('my_page::landing::landing_21')}
                            </span>
                          </div>
                        `;
                      }, infos)}
                    </div>`
                : ``}
              ${show_pdf_printing_file ? pdf_printing_file_el : ''}
            </div>
          </div>
        </div>
        <div class="omp-my-page-orders__order-product-detail omp-my-page-orders__order-product-detail-price">
          ${is_individual_canceled ? TT('my_page::order::order_45') : render_price()}
        </div>
        <div class="omp-my-page-orders__order-product-etc">
          ${can_review
            ? html`<span class="table-button__review" data-up_c_id="${up_c.id}">
                ${TT('my_page::order::order_32')}
              </span>`
            : ''}
          ${can_purchase_url
            ? html` <a
                href="${can_purchase_url}"
                class="OG table-button__purchase"
                is_designed_by_worker="${is_designed_by_worker}"
              >
                ${TT('my_page::order::order_31')}
              </a>`
            : ''}
          ${has_issue_customer
            ? `<button class="table-button__check_modifications" type="button" data-fx-json="${dataStr(
                up_c,
              )}">${TT('pdf::resubmit::3')}</button
          ><button class="table-button__resubmit" type="button" data-fx-json="${dataStr(up_c)}">
            ${TT('pdf::resubmit::2')}
          </button>`
            : ''}
        </div>
      </div>
    </div>
  `;
};
export const orderCellMo = (up_cs) => {
  const order_first = up_cs?.[0];

  if (!order_first) return '';
  const order_status_title = OMPMyPageConstantS.OrderStatusShortDesc();
  const order_number = TT('my_page::order::order_33');
  const { prj_id, order_detail_url, ordered_at, status, is_cancel_req, is_canceled, shipping } =
    order_first.table_data;
  const status_key = is_cancel_req && is_canceled ? 'canceled' : is_cancel_req ? 'cancel_request' : status;
  const delivery_company_name = shipping._.shipping_company.name;
  const shipping_tracking_number = shipping.waybill_number;
  const shipping_tracking_url = OMPMyPageOrderS.makeShippingCheckLink(shipping);

  const product_pdf_printing_file_histories = go(
    up_cs,
    map(
      ({
        _: {
          product_color: {
            _: { product_pdf_printing_file_histories },
          },
        },
      }) => product_pdf_printing_file_histories,
    ),
  );
  const has_issue_file = go(
    product_pdf_printing_file_histories,
    filter((histories) => {
      const [pdf_printing_file_history] = histories || [];
      const { issue_customer } = pdf_printing_file_history || {};
      return !UtilS.isEmpty(issue_customer);
    }),
    (has_issue_files) => has_issue_files.length > 0 && !(is_cancel_req || is_canceled),
  );

  return html`
    <div class="omp-my-page-orders__order infi_item">
      <div class="omp-my-page-orders__order-status">
        <span
          class="omp-my-page-orders__order-status-name ${has_issue_file
            ? 'omp-my-page-orders__order-status-name-og'
            : ''}"
          >${has_issue_file ? `${TT('pdf::resubmit::6')}` : order_status_title[status_key]}</span
        >
        <div class="omp-my-page-orders__order-info">
          <span class="omp-my-page-orders__order-info-text">
            ${format(new Date(ordered_at), 'yyyy-MM-dd')}
          </span>
          <span class="omp-my-page-orders__order-info-text"
            >${order_number}<a
              href="${order_detail_url}"
              class="omp-my-page-orders__order-info-text underline"
              >${prj_id}</a
            ></span
          >
        </div>
        ${delivery_company_name && shipping_tracking_number && shipping_tracking_url
          ? html`<span class="omp-my-page-orders__order-status-delivery"
              >${delivery_company_name}
              <a
                href="${shipping_tracking_url}"
                target="_blank"
                class="omp-my-page-orders__order-status-delivery underline"
                >${shipping_tracking_number}</a
              ></span
            >`
          : ''}
      </div>
      <div class="omp-my-page-orders__order-products">${strMap(orderProductCellMo, up_cs)}</div>
      <div class="omp-my-page-orders__order-divider"></div>
    </div>
  `;
};

export const orderTmplMo = ({
  current_projections,
  order_total_page,
  order_page,
  order_status_data,
  current_status,
}) => {
  const order_status_title = OMPMyPageConstantS.OrderStatusTitle();
  const empty_notice = TT('my_page::order::order_23_01', {
    status: order_status_title[current_status],
  });

  const my_page_menu = OMPMyPageConstantS.MyPageMenu();
  const order_status = {
    ...OMPMyPageConstantS.OrderTotalStatus(),
    ...OMPMyPageConstantS.OrderStatus(),
    ...OMPMyPageConstantS.OrderCanceledStatus(),
  };
  const order_all_count = Object.values(order_status_data).reduce((prev, acc) => (prev += acc), 0);

  const orderStatusHtml = () => {
    return html`<div class="omp-my-page-orders__status">
      ${strMap(([key, status]) => {
        const order_status_data_key = order_status_data?.[key] || 0;
        const order_status_data_additional_key = order_status_data?.[status?.additional_key] || 0;

        const count = isAllOrders(key)
          ? order_all_count
          : order_status_data_key + order_status_data_additional_key;
        const href = upsertQueryParams(`/${TT.lang}/${my_page_menu.order.url}`, { status: key });

        return html`<a
          ${!!count && `href="${href}"`}
          class="omp-my-page-orders__status-wrapper"
          data-active="${!!count}"
          data-selected="${current_status === key}"
        >
          <span class="omp-my-page-orders__status-title">${status.title}</span>
          <span class="omp-my-page-orders__status-count">${count}</span>
        </a>`;
      }, entries(order_status))}
    </div>`;
  };

  if (!current_projections.length)
    return html`
      <div class="omp-my-page-orders">
        ${orderStatusHtml()}
        <div class="omp-my-page-orders__empty-notice">${empty_notice}</div>
      </div>
    `;

  const process_projections = OMPMyPageOrderS.makeTableProjections(current_projections);

  return html`
    <div
      class="omp-my-page-orders infi_container"
      data-page="${order_page}"
      data-total_page="${order_total_page}"
    >
      ${orderStatusHtml()} ${strMap(orderCellMo, process_projections)}
    </div>
  `;
};
