export const QR_URL_PREFIX = 'url';

export const QR_PREFIX = '^%';
export const BARCODE_PREFIX = '^';

export const DYNAMIC_QR_PREFIX = 'dqr_';

export const DYNAMIC_TYPES = {
  redirect: ['method', 'url'],
  get: [],
};

export const DYNAMIC_REQUIRED_FIELDS = ['created_at', 'type', 'payload'];
export const DYNAMIC_OPTIONAL_FIELDS = ['permanent'];

export const GENERATE_LAMBDA_URL = 'https://94gjec5pa4.execute-api.ap-northeast-2.amazonaws.com/prod/code';

export const GENERATE_PARAMS = ['width', 'height', 'value', 'margin', 'displayValue', 'format', 'codeType'];
