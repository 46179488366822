import { go, each, pipe } from 'fxjs/es';

import { defnReviewListCateListEvent, defnReviewListEvent } from './event.js';
import { makeReviewListInfi } from './fs.js';
import { frameOpenReviewWrite } from '../../Write/F/fs.js';
import { makeReviewUpCsHtml, makeReviewListMainHtml } from '../S/tmpl.js';

export const defnReviewListTab = () => ({
  tab_name: 'mp.review.list',
  template: makeReviewListMainHtml,
  appended: pipe(defnReviewListEvent, defnReviewListCateListEvent),
  infinite: makeReviewListInfi(),
});

const myReviewInfinite = (don_tab) =>
  $.frame.infi($.find1(don_tab, '.up_cs'), {
    limit: 10,
    off: true,
    template: makeReviewUpCsHtml,
    appended: each((e) => G.mp.maker.draw_product_face2(e, box.sel(e)._.product_color)),
    data_func: (offset, limit) =>
      go(
        $.get('/@api/my/review', {
          offset,
          limit,
        }),
        function (up_cs) {
          window.box.push.apply(null, ['up_cs'].concat(up_cs));
          return up_cs;
        },
      ),
  });

export const defnMyReviewListTab = () => ({
  tab_name: 'mp.my_review',
  appended: $.on('click', '.write_a_review', function (e) {
    const up = window.box.sel(e.currentTarget);
    frameOpenReviewWrite(up, e.currentTarget);
  }),
  infinite: myReviewInfinite,
});
