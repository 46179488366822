import { html } from 'fxjs/es';

export const makeCvObjAlignmentEditorTmpl = ({ top, left, width, height }) => {
  return html`
    <form class="cv_obj_alignment_editor">
      <label for="">정렬 아이템</label>
      <div class="body">
        <div class="input_wrapper">
          <label for="">top:</label>
          <input type="number" name="top" value="${top}" />
        </div>
        <div class="input_wrapper">
          <label for="">left:</label>
          <input type="number" name="left" value="${left}" />
        </div>
        <div class="input_wrapper">
          <label for="">width:</label>
          <input type="number" name="width" value="${width}" />
        </div>
        <div class="input_wrapper">
          <label for="">height:</label>
          <input type="number" name="height" value="${height}" />
        </div>
      </div>
    </form>
  `;
};

export const makeCvObjAlignmentAddEditorTmpl = () => html`
  <div class="cv_obj_alignment_add_editor">
    <button type="button" class="add_cv_obj_alignment">추가</button>
    <button type="button" class="complete_cv_obj_alignment_editor">완료</button>
    <button type="button" class="cancel_cv_obj_alignment_editor">취소</button>
    <div class="cv_obj_alignment_editor_wrapper"></div>
  </div>
`;
