import { go, html, join, mapL, pipe } from 'fxjs/es';
import { VectorEditorTopMenuPCConstantS } from '../Constant/module/VectorEditorTopMenuPCConstantS.js';

export const makeTopMenusHtml = ({ disabled_ids = new Set() } = {}) => html`
  <div class="top_menus">
    ${go(
      [
        [VectorEditorTopMenuPCConstantS.TOP_MENU.HISTORY_RESET],
        [
          VectorEditorTopMenuPCConstantS.TOP_MENU.HISTORY_UNDO,
          VectorEditorTopMenuPCConstantS.TOP_MENU.HISTORY_REDO,
        ],
        [
          VectorEditorTopMenuPCConstantS.TOP_MENU.TOGGLE_LAYERS_MODE,
          VectorEditorTopMenuPCConstantS.TOP_MENU.DUPLICATE,
          VectorEditorTopMenuPCConstantS.TOP_MENU.DELETE,
        ],
        [VectorEditorTopMenuPCConstantS.TOP_MENU.FORWARD, VectorEditorTopMenuPCConstantS.TOP_MENU.BACKWARD],
        [VectorEditorTopMenuPCConstantS.TOP_MENU.GROUP, VectorEditorTopMenuPCConstantS.TOP_MENU.UNGROUP],
        [
          VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_HORIZONTAL,
          VectorEditorTopMenuPCConstantS.TOP_MENU.FLIP_VERTICAL,
        ],
        [
          VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_LEFT_END,
          VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_HORIZONTAL_CENTER,
          VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_RIGHT_END,
          VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_TOP_END,
          VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_VERTICAL_CENTER,
          VectorEditorTopMenuPCConstantS.TOP_MENU.MOVE_TO_BOTTOM_END,
        ],
      ],
      mapL(
        pipe(
          mapL(({ ID: top_menu_id, NAME }) =>
            makeTopMenuHtml({
              top_menu_id,
              top_menu_label: NAME ?? '',
              is_disabled: disabled_ids.has(top_menu_id),
            }),
          ),
          join('\n'),
        ),
      ),
      mapL((top_menus_html) => html`<div class="top_menus_group">${top_menus_html}</div>`),
      join('\n'),
    )}
  </div>
`;

function makeTopMenuHtml({ top_menu_id, top_menu_label, is_disabled }) {
  return html`<button
    type="button"
    class="top_menu"
    data-is_show="true"
    data-top_menu_id="${top_menu_id}"
    ${is_disabled ? 'disabled' : ''}
  >
    <span class="top_menu_icon"></span>
    <span class="top_menu_label">${top_menu_label}</span>
  </button>`;
}
