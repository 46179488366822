import { go, pick, sel } from 'fxjs/es';

export const getIsUser = (user) =>
  user
    ? go(
        user,
        pick([
          'id',
          'type',
          'email',
          'name',
          'collabo_type',
          'review_alert',
          'is_mobile_cert',
          'team_id',
          sel('_.policies', user) ? '_' : void 0,
        ]),
      )
    : null;

export const checkPassword = function (pwd, email) {
  if (!pwd || pwd.length < 10) {
    return {
      msg: T('login::비밀번호는 10자 이상 최대한 길게 작성해주세요.'),
      result: false,
    };
  }

  if (/(\w)\1\1\1/.test(pwd)) {
    return {
      msg: T('login::비밀번호에 동일한 문자를 과도하게 연속해서 사용할 수 없습니다.'),
      result: false,
    };
  }

  if (email) {
    if (pwd.search(email.split('@')[0]) > -1) {
      return {
        msg: T('login::비밀번호에 ID를 포함할 수 없습니다.'),
        result: false,
      };
    }
  }

  let ascSeqCharCnt = 0; // 오름차순 연속 문자 카운트
  let descSeqCharCnt = 0; // 내림차순 연속 문자 카운트

  for (let i = 0; i < pwd.length; i++) {
    // charAt(): 문자값 반환
    const char_0 = pwd.charAt(i);
    const char_1 = pwd.charAt(i + 1);
    const char_2 = pwd.charAt(i + 2);

    // charCodeAt(): 유니코드값 반환
    const diff_0_1 = char_0.charCodeAt(0) - char_1.charCodeAt(0);
    const diff_1_2 = char_1.charCodeAt(0) - char_2.charCodeAt(0);

    if (diff_0_1 === 1 && diff_1_2 === 1) {
      ascSeqCharCnt += 1;
    }

    if (diff_0_1 === -1 && diff_1_2 === -1) {
      descSeqCharCnt += 1;
    }

    if (ascSeqCharCnt > 1 || descSeqCharCnt > 1) {
      return {
        msg: T('login::연속된 문자를 사용할 수 없습니다.'),
        result: false,
      };
    }
  }

  return { result: true };
};
