import { CommonNS, EditorNS, TransformNS } from '@marpple/sticker-editor';
import { getPlotterCutTime } from '@marpple/stickerizer';
import axios from 'axios';
import {
  each,
  eachL,
  equals2,
  every,
  filter,
  filterL,
  find,
  flatMapL,
  go,
  head,
  isNil,
  join,
  mapC,
  mapL,
  negate,
  noop,
  reduce,
  rejectL,
  takeAll,
  takeAllC,
  tap,
  zipWithIndexL,
  identity,
} from 'fxjs/es';
import { VectorEditorStickerFreeMobileF } from '../../Free/Mobile/F/Function/module/VectorEditorStickerFreeMobileF.js';
import { VectorEditorStickerGridMobileF } from '../../Grid/Mobile/F/Function/module/VectorEditorStickerGridMobileF.js';
import { VectorEditorStickerGridPCF } from '../../Grid/PC/F/Function/module/VectorEditorStickerGridPCF.js';
import { VectorEditorStickerFreePCF } from '../../Free/PC/F/Function/module/VectorEditorStickerFreePCF.js';
import { VectorEditorStickerGridCreatorPCF } from '../../Grid/CreatorPC/F/Function/module/VectorEditorStickerGridCreatorPCF.js';
import { VectorEditorStickerFreeCreatorPCF } from '../../Free/CreatorPC/F/Function/module/VectorEditorStickerFreeCreatorPCF.js';
import { SVGEditorFontConstS } from '../../../../SVGEditor/Font/S/Const/module/SVGEditorFontConstS.js';
import { SVGEditorUtilF } from '../../../../SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { $findAll } from 'fxdom/es';
import { VectorEditorFontF } from '../../../Font/F/Function/module/VectorEditorFontF.js';
import { VectorEditorForceCustomF } from '../../../ForceCustom/F/Function/module/VectorEditorForceCustomF.js';

const DPI = 300;
const INCH_PER_MM = 5 / 127;

const LOADER_MESSAGE = () => T('modules::VectorEditor::Sticker::message::make_images');
const LOADER_TIME = 30 * 1000;

const xml_serializer = new XMLSerializer();

const dom_parser = new DOMParser();
const parseSVGFromStr = (svg_str) => dom_parser.parseFromString(svg_str, 'image/svg+xml');

const makeFontFaceDefsEl = (style_el) => {
  const defs_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'defs');
  defs_el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE] = 'font-face-defs';
  defs_el.appendChild(style_el);
  return defs_el;
};

const makeOriginalSVGFile = async (original_svg_el) => {
  each((image_el) => {
    const original_url = image_el.dataset.mpse__original_url;
    image_el.setAttributeNS(null, 'href', original_url);
  })(original_svg_el.querySelectorAll('image[data-mpse__original_url]'));

  const font_style_el = await VectorEditorFontF.makeFontStyleEl({ is_encode_base64: false })(original_svg_el);
  const font_style_defs_el = makeFontFaceDefsEl(font_style_el);
  original_svg_el.prepend(font_style_defs_el);

  const original_svg_str = xml_serializer.serializeToString(original_svg_el);
  return new File([original_svg_str], 'original.svg', { type: 'image/svg+xml' });
};

const makeImageDataURL = async (url) => {
  const converted_url = (() => {
    const [url_without_qs, qs] = url.split(`?`);
    if (isNil(qs)) {
      return `${url_without_qs}?canvas=marpple-sticker-editor-v3`;
    }
    return go(
      qs.split('&'),
      mapL((a) => a.split('=')),
      mapL(([a, b]) => {
        if (equals2(decodeURIComponent(a))('canvas')) {
          return [a, 'marpple-sticker-editor-v3'];
        }
        return [a, b];
      }),
      mapL(mapL(encodeURIComponent)),
      mapL(join('=')),
      join('&'),
      (qs) => `${url}?${qs}`,
    );
  })();
  const blob = /** @type {Blob} */ await axios
    .get(converted_url, { responseType: 'blob' })
    .then(({ data }) => data);
  return /** @type {Promise<string>} */ new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.addEventListener('error', reject);
    file_reader.readAsDataURL(blob);
  });
};

const prepareGridStickerMetadata = async ({ meta }) => {
  const foreground_svg_url = meta.art_board_size?.foreground_svg_url;
  if (isNil(foreground_svg_url)) {
    const error = new Error(`No "foreground_svg_url" option.`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 앞면 이미지를 찾을 수 없습니다.',
    );
    throw error;
  }
  const foreground_svg_el = await go(
    axios.get(foreground_svg_url, { responseType: 'text', params: { now: `${Date.now()}` } }),
    ({ data }) => data,
    parseSVGFromStr,
    (doc) => doc.querySelector('.root'),
    tap((el) => el.removeAttributeNS(null, 'class')),
  );
  if (isNil(foreground_svg_el)) {
    const error = new Error(`No "foreground_svg_el".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 앞면 이미지를 찾을 수 없습니다.',
    );
    throw error;
  }

  const grid_size = meta.grid_size;
  if (isNil(grid_size)) {
    const error = new Error(`Invalid "grid_size" option. : ${grid_size}`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드 크기입니다.');
    throw error;
  }

  const grid = meta.grid_meta?.[grid_size];
  if (isNil(grid)) {
    const error = new Error(
      `Invalid "grid_meta" option. : ${CommonNS.UtilNS.tryNoop(() => JSON.stringify(grid)) ?? null}`,
    );
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }

  const grid_width = grid.size?.width;
  if (isNil(grid_width) || Number.isNaN(grid_width) || !Number.isFinite(grid_width) || grid_width <= 0) {
    const error = new Error(`Invalid "grid_meta.${grid_size}.size.width" : "${grid_width}"`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }

  const grid_height = grid.size?.height;
  if (isNil(grid_height) || Number.isNaN(grid_height) || !Number.isFinite(grid_height) || grid_height <= 0) {
    const error = new Error(`Invalid "grid_meta.${grid_size}.size.height" : "${grid_height}"`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }

  const cutting_line_svg_el = await go(
    axios.get(grid.cutting_line_svg_url, { responseType: 'text', params: { now: `${Date.now()}` } }),
    ({ data }) => data,
    parseSVGFromStr,
    (doc) => doc.querySelector('svg'),
  );
  if (isNil(cutting_line_svg_el)) {
    const error = new Error(`Cannot find the "cutting_line_svg_el" of "${grid.cutting_line_svg_url}"`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }

  const [
    foreground_el,
    clip_path_el,
    existing_grid_el,
    empty_grid_el,
    selector_grid_svg_el,
    copy_source_grid_svg_el,
    copy_destination_unselected_empty_grid_svg_el,
    copy_destination_unselected_existing_grid_svg_el,
    copy_destination_selected_grid_svg_el,
  ] = await go(
    [
      grid.foreground_svg_url,
      grid.clip_path_svg_url,
      grid.existing_grid_svg_url,
      grid.empty_grid_svg_url,
      grid.selector_grid_svg_url,
      grid.copy_source_grid_svg_url,
      grid.copy_destination_unselected_empty_grid_svg_url,
      grid.copy_destination_unselected_existing_grid_svg_url,
      grid.copy_destination_selected_grid_svg_url,
    ],
    mapL((url) => axios.get(url, { responseType: 'text', params: { now: `${Date.now()}` } })),
    mapL(({ data }) => data),
    mapL(parseSVGFromStr),
    mapC((doc) => doc.querySelector('.root')),
    G.collabo_type === '' ? mapC(VectorEditorForceCustomF.changeSvgColorToOG) : identity,
  );
  if (isNil(foreground_el)) {
    const error = new Error(`Cannot find the "foreground_el" of "${grid.foreground_svg_url}"`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }
  if (isNil(clip_path_el)) {
    const error = new Error(`Cannot find the "clip_path_el" of "${grid.clip_path_svg_url}"`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }
  if (isNil(existing_grid_el)) {
    const error = new Error(`Cannot find the "existing_grid_el" of "${grid.existing_grid_svg_url}"`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }
  if (isNil(empty_grid_el)) {
    const error = new Error(`Cannot find the "empty_grid_el" of "${grid.empty_grid_svg_url}"`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }
  if (isNil(selector_grid_svg_el)) {
    const error = new Error(`Cannot find the "selector_grid_svg_el" of "${grid.selector_grid_svg_url}"`);
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }
  if (isNil(copy_source_grid_svg_el)) {
    const error = new Error(
      `Cannot find the "copy_source_grid_svg_el" of "${grid.copy_source_grid_svg_url}"`,
    );
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }
  if (isNil(copy_destination_unselected_empty_grid_svg_el)) {
    const error = new Error(
      `Cannot find the "copy_destination_unselected_empty_grid_svg_el" of "${grid.copy_destination_unselected_empty_grid_svg_url}"`,
    );
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }
  if (isNil(copy_destination_unselected_existing_grid_svg_el)) {
    const error = new Error(
      `Cannot find the "copy_destination_unselected_existing_grid_svg_el" of "${grid.copy_destination_unselected_existing_grid_svg_url}"`,
    );
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }
  if (isNil(copy_destination_selected_grid_svg_el)) {
    const error = new Error(
      `Cannot find the "copy_destination_selected_grid_svg_el" of "${grid.copy_destination_selected_grid_svg_url}"`,
    );
    error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
    throw error;
  }
  const grids = await go(
    grid.positions,
    tap((positions) => {
      if (!Array.isArray(positions)) {
        const error = new Error(`The "positions" is not an array.`);
        error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
        throw error;
      }
    }),
    eachL((pos) => {
      const x = pos?.x;
      const y = pos?.y;

      if (isNil(x) || Number.isNaN(x) || !Number.isFinite(x)) {
        const error = new Error(`Invalid "x" of the "positions".`);
        error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
        throw error;
      }

      if (isNil(y) || Number.isNaN(y) || !Number.isFinite(y)) {
        const error = new Error(`Invalid "y" of the "positions".`);
        error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::잘못된 그리드입니다.');
        throw error;
      }
    }),
    mapL(async ({ x, y }) => {
      const clone_clip_path_el = await CommonNS.UtilNS.deepCloneNode(clip_path_el);
      if (equals2(clone_clip_path_el.nodeName)('rect')) {
        clone_clip_path_el.setAttributeNS(null, 'x', `${x}`);
        clone_clip_path_el.setAttributeNS(null, 'y', `${y}`);
      } else {
        clone_clip_path_el.setAttributeNS(null, 'transform', `translate(${x} ${y})`);
      }

      const clone_empty_grid_el = await CommonNS.UtilNS.deepCloneNode(empty_grid_el);
      clone_empty_grid_el.setAttributeNS(null, 'transform', `translate(${x} ${y})`);

      const clone_existing_grid_el = await CommonNS.UtilNS.deepCloneNode(existing_grid_el);
      clone_existing_grid_el.setAttributeNS(null, 'transform', `translate(${x} ${y})`);

      const clone_selector_grid_el = await CommonNS.UtilNS.deepCloneNode(selector_grid_svg_el);
      clone_selector_grid_el.setAttributeNS(null, 'transform', `translate(${x} ${y})`);

      const clone_foreground_el = await CommonNS.UtilNS.deepCloneNode(foreground_el);

      const clone_copy_source_grid_svg_el = await CommonNS.UtilNS.deepCloneNode(copy_source_grid_svg_el);
      clone_copy_source_grid_svg_el.setAttributeNS(null, 'transform', `translate(${x} ${y})`);

      const clone_copy_destination_unselected_empty_grid_svg_el = await CommonNS.UtilNS.deepCloneNode(
        copy_destination_unselected_empty_grid_svg_el,
      );
      clone_copy_destination_unselected_empty_grid_svg_el.setAttributeNS(
        null,
        'transform',
        `translate(${x} ${y})`,
      );

      const clone_copy_destination_unselected_existing_grid_svg_el = await CommonNS.UtilNS.deepCloneNode(
        copy_destination_unselected_existing_grid_svg_el,
      );
      clone_copy_destination_unselected_existing_grid_svg_el.setAttributeNS(
        null,
        'transform',
        `translate(${x} ${y})`,
      );

      const clone_copy_destination_selected_grid_svg_el = await CommonNS.UtilNS.deepCloneNode(
        copy_destination_selected_grid_svg_el,
      );
      clone_copy_destination_selected_grid_svg_el.setAttributeNS(null, 'transform', `translate(${x} ${y})`);

      return {
        x,
        y,
        width: grid_width,
        height: grid_height,
        clip_path_el: clone_clip_path_el,
        empty_grid_el: clone_empty_grid_el,
        existing_grid_el: clone_existing_grid_el,
        selector_grid_el: clone_selector_grid_el,
        foreground_el: clone_foreground_el,
        copy_source_grid_el: clone_copy_source_grid_svg_el,
        copy_destination_unselected_empty_grid_el: clone_copy_destination_unselected_empty_grid_svg_el,
        copy_destination_unselected_existing_grid_el: clone_copy_destination_unselected_existing_grid_svg_el,
        copy_destination_selected_grid_el: clone_copy_destination_selected_grid_svg_el,
      };
    }),
    zipWithIndexL,
    mapC(([i, grid]) => ({ ...grid, id: `grid_${i + 1}` })),
  );

  return { foreground_svg_el, cutting_line_svg_el, grids };
};

const makeGridStickerResult = async ({
  grid_sticker_editor,
  container_el,
  thumbnail_stroke_width_px,
  thumbnail_factor,
  printable_factor,
  cutting_line_svg_el,
  art_board_cutting: {
    x: art_board_cutting_x,
    y: art_board_cutting_y,
    width: art_board_cutting_width,
    height: art_board_cutting_height,
  },
  too_big_image_error_message,
}) => {
  const deepCloneNode = (node) => node.cloneNode(true);
  const original_svg_el = await grid_sticker_editor.exportEditor({
    factor: 1,
    container_el,
    deepCloneNode,
  });
  const thumbnail_svg_el = await grid_sticker_editor.exportEditor({
    factor: thumbnail_factor,
    container_el,
    deepCloneNode,
  });
  const printable_svg_el = await grid_sticker_editor.exportEditor({
    factor: printable_factor,
    container_el,
    deepCloneNode,
  });

  const original_svg_file = await makeOriginalSVGFile(original_svg_el);
  const original_cutting_line_svg_file = new File(
    [xml_serializer.serializeToString(cutting_line_svg_el)],
    'original_cutting_line.svg',
    {
      type: 'image/svg+xml',
    },
  );
  const { total_time: process_time } = getPlotterCutTime(cutting_line_svg_el);
  const makeRasterImages = async () => {
    const printable_canvas_el = await (async () => {
      const target_image_layer_el = go(
        printable_svg_el.children,
        filterL((el) => equals2(el?.dataset?.[CommonNS.ConstNS.DATA_KEY_ROLE])('target-image-layer')),
        head,
      );
      const target_image_els = go(
        target_image_layer_el?.children ?? [],
        filterL((el) => equals2(el?.tagName?.toLowerCase?.())('svg')),
        filterL((el) => equals2(el?.dataset?.[CommonNS.ConstNS.DATA_KEY_ROLE])('target-image')),
        takeAll,
      );
      await go(
        target_image_els,
        eachL(async (target_image_el) => {
          const x_str = target_image_el.getAttributeNS(null, 'x');
          const y_str = target_image_el.getAttributeNS(null, 'y');
          const width_str = target_image_el.getAttributeNS(null, 'width');
          const height_str = target_image_el.getAttributeNS(null, 'height');

          target_image_el.removeAttributeNS(null, 'x');
          target_image_el.removeAttributeNS(null, 'y');
          target_image_el.removeAttributeNS(null, 'width');
          target_image_el.removeAttributeNS(null, 'height');

          const font_face_defs_el = makeFontFaceDefsEl(
            await VectorEditorFontF.makeFontStyleEl({ is_encode_base64: true })(target_image_el),
          );
          target_image_el.prepend(font_face_defs_el);

          const width = Number.parseFloat(width_str);
          const height = Number.parseFloat(height_str);
          const scaled_width = width * printable_factor;
          const scaled_height = height * printable_factor;

          target_image_el.setAttributeNS(null, 'width', `${scaled_width}`);
          target_image_el.setAttributeNS(null, 'height', `${scaled_height}`);

          const target_image_data_url = /** @type {string} */ await new Promise((resolve, reject) => {
            const file_reader = new FileReader();
            file_reader.addEventListener('error', (event) => {
              console.error(event);
              const error = new Error(too_big_image_error_message);
              error.__mp_alert_message = too_big_image_error_message;
              reject(error);
            });
            file_reader.addEventListener('load', () => resolve(file_reader.result));
            file_reader.readAsDataURL(
              new Blob([xml_serializer.serializeToString(target_image_el)], {
                type: 'image/svg+xml',
              }),
            );
          });

          const target_image_img_el = /** @type {HTMLImageElement} */ await new Promise((resolve, reject) => {
            const img_el = new Image();
            img_el.addEventListener('error', (event) => {
              console.error(event);
              const error = new Error(too_big_image_error_message);
              error.__mp_alert_message = too_big_image_error_message;
              reject(error);
            });
            img_el.addEventListener('load', () => resolve(img_el));
            img_el.src = target_image_data_url;
          });

          await new Promise((resolve) => setTimeout(resolve, 5000));

          const target_image_png_data_url = (() => {
            const canvas_el = document.createElement('canvas');
            canvas_el.width = target_image_img_el.width;
            canvas_el.height = target_image_img_el.height;
            const ctx = canvas_el.getContext('2d');
            ctx.drawImage(
              target_image_img_el,
              0,
              0,
              target_image_img_el.width,
              target_image_img_el.height,
              0,
              0,
              canvas_el.width,
              canvas_el.height,
            );
            return canvas_el.toDataURL('image/png');
          })();

          const target_image_image_el = (() => {
            const image_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'image');
            image_el.setAttributeNS(null, 'x', x_str);
            image_el.setAttributeNS(null, 'y', y_str);
            image_el.setAttributeNS(null, 'width', width_str);
            image_el.setAttributeNS(null, 'height', height_str);
            image_el.setAttributeNS(null, 'preserveAspectRatio', `xMidYMid meet`);
            image_el.setAttributeNS(null, 'href', target_image_png_data_url);
            return image_el;
          })();

          target_image_layer_el?.insertBefore?.(target_image_image_el, target_image_el);
          target_image_layer_el?.removeChild?.(target_image_el);
        }),
        takeAllC,
      );

      const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
        const file_reader = new FileReader();
        file_reader.addEventListener('error', (event) => {
          console.error(event);
          const error = new Error(too_big_image_error_message);
          error.__mp_alert_message = too_big_image_error_message;
          reject(error);
        });
        file_reader.addEventListener('load', () => resolve(file_reader.result));
        file_reader.readAsDataURL(
          new Blob([xml_serializer.serializeToString(printable_svg_el)], {
            type: 'image/svg+xml',
          }),
        );
      });
      const img_el = /** @type {HTMLImageElement} */ await new Promise((resolve, reject) => {
        const img_el = new Image();
        img_el.addEventListener('error', (event) => {
          console.error(event);
          const error = new Error(too_big_image_error_message);
          error.__mp_alert_message = too_big_image_error_message;
          reject(error);
        });
        img_el.addEventListener('load', () => resolve(img_el));
        img_el.src = data_url;
      });
      await new Promise((resolve) => setTimeout(resolve, 5000));

      const canvas_el = document.createElement('canvas');
      canvas_el.width = img_el.width;
      canvas_el.height = img_el.height;
      const ctx = canvas_el.getContext('2d');
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas_el.width, canvas_el.height);
      ctx.drawImage(img_el, 0, 0, img_el.width, img_el.height, 0, 0, canvas_el.width, canvas_el.height);
      return canvas_el;
    })();

    const thumbnail_canvas_el = await (async () => {
      const cutting_line_img_el = await (async () => {
        const svg_width = thumbnail_svg_el.getAttributeNS(null, 'width');
        const svg_height = thumbnail_svg_el.getAttributeNS(null, 'height');
        const container_svg_el = document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg');
        container_svg_el.setAttributeNS(null, 'width', svg_width);
        container_svg_el.setAttributeNS(null, 'height', svg_height);
        container_svg_el.setAttributeNS(null, 'viewBox', `0 0 ${svg_width} ${svg_height}`);
        await go(
          cutting_line_svg_el,
          CommonNS.UtilNS.deepCloneNode,
          tap((svg_el) => svg_el.setAttributeNS(null, 'width', svg_width)),
          tap((svg_el) => svg_el.setAttributeNS(null, 'height', svg_height)),
          tap(
            (svg_el) => svg_el.children,
            each(
              (el) =>
                new Promise((resolve, reject) =>
                  requestAnimationFrame(() => {
                    try {
                      el.setAttributeNS(null, 'fill', 'none');
                      el.setAttributeNS(null, 'stroke', '#adb5bd');
                      el.setAttributeNS(null, 'stroke-width', `${thumbnail_stroke_width_px}`);
                      el.setAttributeNS(null, 'vector-effect', 'non-scaling-stroke');
                      resolve();
                    } catch (error) {
                      reject(error);
                    }
                  }),
                ),
            ),
          ),
          tap((svg_el) => container_svg_el.appendChild(svg_el)),
        );
        const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
          const file_reader = new FileReader();
          file_reader.addEventListener('error', (event) => {
            console.error(event);
            const error = new Error(too_big_image_error_message);
            error.__mp_alert_message = too_big_image_error_message;
            reject(error);
          });
          file_reader.addEventListener('load', () => resolve(file_reader.result));
          file_reader.readAsDataURL(
            new Blob([xml_serializer.serializeToString(container_svg_el)], {
              type: 'image/svg+xml',
            }),
          );
        });
        const img_el = /** @type {HTMLImageElement} */ await new Promise((resolve, reject) => {
          const img_el = new Image();
          img_el.addEventListener('error', (event) => {
            console.error(event);
            const error = new Error(too_big_image_error_message);
            error.__mp_alert_message = too_big_image_error_message;
            reject(error);
          });
          img_el.addEventListener('load', () => resolve(img_el));
          img_el.src = data_url;
        });
        await new Promise((resolve) => setTimeout(resolve, 3000));
        return img_el;
      })();

      const [thumbnail_width, thumbnail_height] = mapL((a) => Math.ceil(a * thumbnail_factor))([
        art_board_cutting_width,
        art_board_cutting_height,
      ]);
      const canvas_el = document.createElement('canvas');
      canvas_el.width = thumbnail_width;
      canvas_el.height = thumbnail_height;
      const ctx = canvas_el.getContext('2d');
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas_el.width, canvas_el.height);

      const [
        printable_canvas_el_x,
        printable_canvas_el_y,
        printable_canvas_el_width,
        printable_canvas_el_height,
      ] = mapL((a) => Math.ceil(a * printable_factor))([
        art_board_cutting_x,
        art_board_cutting_y,
        art_board_cutting_width,
        art_board_cutting_height,
      ]);
      ctx.drawImage(
        printable_canvas_el,
        printable_canvas_el_x,
        printable_canvas_el_y,
        printable_canvas_el_width,
        printable_canvas_el_height,
        0,
        0,
        canvas_el.width,
        canvas_el.height,
      );

      const [
        cutting_line_img_el_x,
        cutting_line_img_el_y,
        cutting_line_img_el_width,
        cutting_line_img_el_height,
      ] = mapL((a) => Math.ceil(a * thumbnail_factor))([
        art_board_cutting_x,
        art_board_cutting_y,
        art_board_cutting_width,
        art_board_cutting_height,
      ]);
      ctx.drawImage(
        cutting_line_img_el,
        cutting_line_img_el_x,
        cutting_line_img_el_y,
        cutting_line_img_el_width,
        cutting_line_img_el_height,
        0,
        0,
        canvas_el.width,
        canvas_el.height,
      );

      return canvas_el;
    })();

    const printable_jpeg_file = await (async () => {
      const blob = /** @type {Blob} */ await new Promise((resolve, reject) =>
        printable_canvas_el.toBlob(
          (blob) => {
            if (blob == null) {
              const error = new Error(`Cannot make a "printable_jpeg_blob".`);
              error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::fail_image');
              reject(error);
              return;
            }
            resolve(blob);
          },
          'image/jpeg',
          1,
        ),
      );
      return new File([blob], 'printable.jpeg', {
        type: 'image/jpeg',
      });
    })();

    const thumbnail_png_data_url = thumbnail_canvas_el.toDataURL('image/png');

    return {
      printable_jpeg_file,
      thumbnail_png_data_url,
    };
  };

  return {
    original_svg_file,
    original_cutting_line_svg_file,
    process_time,
    makeRasterImages,
  };
};

const prepareFreeStickerMetadata = async ({ meta, svg_el }) => {
  const foreground_svg_url = meta?.art_board_size?.foreground_svg_url;
  if (isNil(foreground_svg_url)) {
    const error = new Error(`No "foreground_svg_url" option.`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 앞면 이미지를 찾을 수 없습니다.',
    );
    throw error;
  }
  const foreground_svg_el = await go(
    axios.get(foreground_svg_url, { responseType: 'text', params: { now: `${Date.now()}` } }),
    ({ data }) => data,
    parseSVGFromStr,
    (doc) => doc.querySelector('.root'),
    tap((el) => el.removeAttributeNS(null, 'class')),
  );
  if (isNil(foreground_svg_el)) {
    const error = new Error(`No "foreground_svg_el".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 앞면 이미지를 찾을 수 없습니다.',
    );
    throw error;
  }
  const free_template_svg_type = meta?.free_template_svg_type;
  if (isNil(free_template_svg_type)) {
    const error = new Error(`No "free_template_svg_type".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 앞면 이미지를 찾을 수 없습니다.',
    );
    throw error;
  }
  const free_template_svg_url = meta?.free_meta?.template_svg_url?.[free_template_svg_type];
  if (isNil(free_template_svg_url)) {
    const error = new Error(`No "free_meta.template_svg_url.${free_template_svg_type}".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 앞면 이미지를 찾을 수 없습니다.',
    );
    throw error;
  }
  const free_template_svg_el = await go(
    axios.get(free_template_svg_url, { responseType: 'text', params: { now: `${Date.now()}` } }),
    ({ data }) => data,
    parseSVGFromStr,
    (doc) => doc.querySelector('.root'),
    tap((el) => el.removeAttributeNS(null, 'class')),
  );
  const single_empty_template_svg_url = meta?.free_meta?.single_empty_template_svg_url;
  if (isNil(single_empty_template_svg_url)) {
    const error = new Error(`No "free_meta.single_empty_template_svg_url".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 앞면 이미지를 찾을 수 없습니다.',
    );
    throw error;
  }
  const single_empty_template_svg_el = await go(
    axios.get(single_empty_template_svg_url, { responseType: 'text', params: { now: `${Date.now()}` } }),
    ({ data }) => data,
    parseSVGFromStr,
    (doc) => doc.querySelector('.root'),
    tap((el) => el.removeAttributeNS(null, 'class')),
  );
  const sticker_collision_margin = meta?.free_meta?.sticker_collision_margin;
  if (isNil(sticker_collision_margin)) {
    const error = new Error(`No "free_meta.sticker_collision_margin".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 안전 간격 찾을 수 없습니다.',
    );
    throw error;
  }
  const collision_container_path_data = meta?.free_meta?.collision_container_path_data;
  if (isNil(collision_container_path_data)) {
    const error = new Error(`No "free_meta.collision_container_path_data".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 안전 영역 찾을 수 없습니다.',
    );
    throw error;
  }
  const collision_container_area_position = meta?.free_meta?.collision_container_area_position;
  if (isNil(collision_container_area_position)) {
    const error = new Error(`No "free_meta.collision_container_area_position".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 안전 영역 찾을 수 없습니다.',
    );
    throw error;
  }
  const collision_forbidden_area_position = meta?.free_meta?.collision_forbidden_area_position;
  if (isNil(collision_forbidden_area_position)) {
    const error = new Error(`No "free_meta.collision_forbidden_area_position".`);
    error.__mp_alert_message = T(
      'modules::VectorEditor::Sticker::message::스티커 에디터 안전 영역 찾을 수 없습니다.',
    );
    throw error;
  }
  const single_art_board_size_width = meta?.free_meta?.single_art_board_size?.width;
  const single_art_board_size_height = meta?.free_meta?.single_art_board_size?.height;
  each((size) => {
    if (isNil(size) || Number.isNaN(size) || !Number.isFinite(size) || size <= 0) {
      const error = new Error(
        `Invalid "single_art_board_size" option. : { "width": ${single_art_board_size_width}, "height": ${single_art_board_size_height} }`,
      );
      error.__mp_alert_message = `잘못된 단일 아트보드 크기입니다.`;
      throw error;
    }
  })([single_art_board_size_width, single_art_board_size_height]);

  const { background_color, background_opacity } = (() => {
    if (!svg_el) {
      return { background_color: '#ffffff', background_opacity: 1 };
    }
    return go(
      svg_el.querySelector(
        `[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-layer"] [data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-color"]`,
      ),
      (background_color_el) => ({
        background_color: background_color_el?.getAttributeNS?.(null, 'fill') ?? '#ffffff',
        background_opacity: background_color_el?.getAttributeNS?.(null, 'opacity') ?? '1',
      }),
      ({ background_color, background_opacity }) => ({
        background_color,
        background_opacity: parseFloat(background_opacity),
      }),
    );
  })();

  return {
    foreground_svg_el,
    free_template_svg_el,
    single_empty_template_svg_el,
    sticker_collision_margin,
    collision_container_path_data,
    collision_container_area_position,
    collision_forbidden_area_position,
    background_color,
    background_opacity,
    single_art_board_size_width,
    single_art_board_size_height,
  };
};

const makeFreeStickerResult = async ({
  free_sticker_editor,
  thumbnail_stroke_width_px,
  thumbnail_factor,
  printable_factor,
  art_board_cutting: {
    x: art_board_cutting_x,
    y: art_board_cutting_y,
    width: art_board_cutting_width,
    height: art_board_cutting_height,
  },
  too_big_image_error_message,
  image_error_message,
}) => {
  const { target_image_el: original_svg_el, cutting_line_el: original_cutting_line_svg_el } =
    await free_sticker_editor.exportEditor(1);
  const { target_image_el: printable_svg_el } = await free_sticker_editor.exportEditor(printable_factor);
  const { cutting_line_el: thumbnail_cutting_line_svg_el } = await free_sticker_editor.exportEditor(
    thumbnail_factor,
  );

  const original_svg_file = await makeOriginalSVGFile(original_svg_el);
  const original_cutting_line_svg_file = new File(
    [xml_serializer.serializeToString(original_cutting_line_svg_el)],
    'original_cutting_line.svg',
    {
      type: 'image/svg+xml',
    },
  );
  const { total_time: process_time } = getPlotterCutTime(original_cutting_line_svg_el);
  const makeRasterImages = async () => {
    const printable_canvas_el = await go(
      printable_svg_el,
      tap(async (printable_svg_el) =>
        printable_svg_el.prepend(
          makeFontFaceDefsEl(
            await VectorEditorFontF.makeFontStyleEl({ is_encode_base64: true })(original_svg_el),
          ),
        ),
      ),
      (printable_svg_el) =>
        new Promise((resolve, reject) => {
          const file_reader = new FileReader();
          file_reader.addEventListener('error', (event) => {
            console.error(event);
            const error = new Error(too_big_image_error_message);
            error.__mp_alert_message = too_big_image_error_message;
            reject(error);
          });
          file_reader.addEventListener('load', () => resolve(file_reader.result));
          file_reader.readAsDataURL(
            new Blob([xml_serializer.serializeToString(printable_svg_el)], {
              type: 'image/svg+xml',
            }),
          );
        }),
      (printable_svg_data_url) =>
        new Promise((resolve, reject) => {
          const img_el = new Image();
          img_el.addEventListener('error', (event) => {
            console.error(event);
            const error = new Error(too_big_image_error_message);
            error.__mp_alert_message = too_big_image_error_message;
            reject(error);
          });
          img_el.addEventListener('load', () => resolve(img_el));
          img_el.src = printable_svg_data_url;
        }),
      tap(() => new Promise((resolve) => setTimeout(resolve, 5000))),
      (printable_img_el) => {
        const printable_canvas_el = document.createElement('canvas');
        printable_canvas_el.width = printable_img_el.width;
        printable_canvas_el.height = printable_img_el.height;
        const printable_canvas_ctx = printable_canvas_el.getContext('2d');
        printable_canvas_ctx.fillStyle = '#FFFFFF';
        printable_canvas_ctx.fillRect(0, 0, printable_canvas_el.width, printable_canvas_el.height);
        printable_canvas_ctx.drawImage(
          printable_img_el,
          0,
          0,
          printable_img_el.width,
          printable_img_el.height,
          0,
          0,
          printable_canvas_el.width,
          printable_canvas_el.height,
        );
        return printable_canvas_el;
      },
    );

    const thumbnail_canvas_el = await (async () => {
      const cutting_line_img_el = await (async () => {
        await go(
          thumbnail_cutting_line_svg_el.querySelectorAll('path'),
          each(
            (el) =>
              new Promise((resolve, reject) =>
                requestAnimationFrame(() => {
                  try {
                    el.setAttributeNS(null, 'fill', 'none');
                    el.setAttributeNS(null, 'stroke', '#adb5bd');
                    el.setAttributeNS(null, 'stroke-width', `${thumbnail_stroke_width_px}`);
                    el.setAttributeNS(null, 'vector-effect', 'non-scaling-stroke');
                    resolve();
                  } catch (error) {
                    reject(error);
                  }
                }),
              ),
          ),
        );
        const data_url = /** @type {string} */ await new Promise((resolve, reject) => {
          const file_reader = new FileReader();
          file_reader.addEventListener('error', (event) => {
            console.error(event);
            const error = new Error(too_big_image_error_message);
            error.__mp_alert_message = too_big_image_error_message;
            reject(error);
          });
          file_reader.addEventListener('load', () => resolve(file_reader.result));
          file_reader.readAsDataURL(
            new Blob([xml_serializer.serializeToString(thumbnail_cutting_line_svg_el)], {
              type: 'image/svg+xml',
            }),
          );
        });
        const img_el = /** @type {HTMLCanvasElement} */ await new Promise((resolve, reject) => {
          const img_el = new Image();
          img_el.addEventListener('error', (event) => {
            console.error(event);
            const error = new Error(too_big_image_error_message);
            error.__mp_alert_message = too_big_image_error_message;
            reject(error);
          });
          img_el.addEventListener('load', () => resolve(img_el));
          img_el.src = data_url;
        });
        await new Promise((resolve) => setTimeout(resolve, 3000));
        return img_el;
      })();

      const [thumbnail_width, thumbnail_height] = mapL((a) => Math.ceil(a * thumbnail_factor))([
        art_board_cutting_width,
        art_board_cutting_height,
      ]);
      const canvas_el = document.createElement('canvas');
      canvas_el.width = thumbnail_width;
      canvas_el.height = thumbnail_height;
      const ctx = canvas_el.getContext('2d');
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas_el.width, canvas_el.height);

      const [
        printable_canvas_el_x,
        printable_canvas_el_y,
        printable_canvas_el_width,
        printable_canvas_el_height,
      ] = mapL((a) => Math.ceil(a * printable_factor))([
        art_board_cutting_x,
        art_board_cutting_y,
        art_board_cutting_width,
        art_board_cutting_height,
      ]);
      ctx.drawImage(
        printable_canvas_el,
        printable_canvas_el_x,
        printable_canvas_el_y,
        printable_canvas_el_width,
        printable_canvas_el_height,
        0,
        0,
        canvas_el.width,
        canvas_el.height,
      );

      const [
        cutting_line_img_el_x,
        cutting_line_img_el_y,
        cutting_line_img_el_width,
        cutting_line_img_el_height,
      ] = mapL((a) => Math.ceil(a * thumbnail_factor))([
        art_board_cutting_x,
        art_board_cutting_y,
        art_board_cutting_width,
        art_board_cutting_height,
      ]);
      ctx.drawImage(
        cutting_line_img_el,
        cutting_line_img_el_x,
        cutting_line_img_el_y,
        cutting_line_img_el_width,
        cutting_line_img_el_height,
        0,
        0,
        canvas_el.width,
        canvas_el.height,
      );

      return canvas_el;
    })();

    const printable_jpeg_file = await go(
      printable_canvas_el,
      (printable_canvas_el) => {
        return new Promise((resolve, reject) =>
          printable_canvas_el.toBlob(
            (blob) => {
              if (blob == null) {
                const error = new Error(`Cannot make a "printable_jpeg_blob".`);
                error.__mp_alert_message = image_error_message;
                reject(error);
                return;
              }
              resolve(blob);
            },
            'image/jpeg',
            1,
          ),
        );
      },
      (printable_jpeg_blob) =>
        new File([printable_jpeg_blob], 'printable.jpeg', {
          type: 'image/jpeg',
        }),
    );

    const thumbnail_png_data_url = await (async () => {
      const thumbnail_png_blob = /** @type {Blob} */ await new Promise((resolve, reject) =>
        thumbnail_canvas_el.toBlob((blob) => {
          if (blob == null) {
            const error = new Error(`Cannot make a "thumbnail_png_blob".`);
            error.__mp_alert_message = image_error_message;
            reject(error);
            return;
          }
          resolve(blob);
        }, 'image/png'),
      );
      return await new Promise((resolve, reject) => {
        const file_reader = new FileReader();
        file_reader.addEventListener('load', () => resolve(file_reader.result));
        file_reader.addEventListener('error', (event) => {
          console.error(event);
          const error = new Error(too_big_image_error_message);
          error.__mp_alert_message = too_big_image_error_message;
          reject(error);
        });
        file_reader.readAsDataURL(thumbnail_png_blob);
      });
    })();

    return {
      printable_jpeg_file,
      thumbnail_png_data_url,
    };
  };

  return {
    original_svg_file,
    original_cutting_line_svg_file,
    process_time,
    makeRasterImages,
  };
};

const makeGridStickerMobile = async ({
  svg_el,
  title,
  postProcess,
  thumbnail_width_min_px,
  thumbnail_stroke_width_px,
  meta,
  art_board_size,
}) => {
  let foreground_svg_el;
  let cutting_line_svg_el;
  let grids;

  $.don_loader_start();
  try {
    ({ foreground_svg_el, cutting_line_svg_el, grids } = await prepareGridStickerMetadata({ meta }));
  } finally {
    $.don_loader_end();
  }

  return VectorEditorStickerGridMobileF.makeSticker({
    title,
    art_board_size,
    grids,
    preProcess: async (grid_sticker_editor) => {
      grid_sticker_editor.setForeground(foreground_svg_el);

      if (!svg_el) {
        return;
      }

      const { fill, opacity } = go(
        svg_el.querySelector(
          `[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-layer"] [data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-color"]`,
        ),
        (background_color_el) => ({
          fill: background_color_el?.getAttributeNS?.(null, 'fill') ?? '#ffffff',
          opacity: background_color_el?.getAttributeNS?.(null, 'opacity') ?? '1',
        }),
        ({ fill, opacity }) => ({ fill, opacity: parseFloat(opacity) }),
      );
      grid_sticker_editor.setBackground({
        type: 'color',
        fill,
        opacity,
      });

      const target_image_els = go(
        svg_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image-layer"]`),
        flatMapL((layer_el) =>
          layer_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image"]`),
        ),
        takeAll,
      );
      $.don_loader_start();
      await go(
        target_image_els,
        flatMapL((target_image_el) => target_image_el.querySelectorAll(`image[data-mpse__original_url]`)),
        eachL(async (image_el) => {
          const data_url = await makeImageDataURL(image_el.dataset.mpse__original_url);
          image_el.setAttributeNS(null, 'href', data_url);
        }),
        takeAllC,
      );
      await go(
        target_image_els,
        flatMapL((target_image_el) => VectorEditorFontF.getAllUsedFonts(target_image_el)),
        (iter) =>
          reduce(
            (acc, cur) => {
              acc.add(cur);
              return acc;
            },
            new Set(),
            iter,
          ),
        (font_family_set) =>
          filterL(({ fontFamily }) => font_family_set.has(fontFamily))(SVGEditorFontConstS.FONTS),
        mapL(({ fontFamily: font_family, fontStyle: font_style, fontWeight: font_weight }) =>
          new FontFaceObserver(font_family, { weight: font_weight, style: font_style }).load(null, 10000),
        ),
        takeAllC,
      );
      $.don_loader_end();

      go(
        svg_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image-layer"]`),
        flatMapL((layer_el) =>
          layer_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image"]`),
        ),
        each((target_image_el) => {
          const id = target_image_el.dataset[EditorNS.GridStickerEditorNS.BaseNS.DATA_KEY_GRID_ID];
          grid_sticker_editor.setTargetImage({
            id,
            target_image_el,
          });
        }),
      );
    },
    postProcess: async (grid_sticker_editor) => {
      const loaderDone = SVGEditorUtilF.percentLoader({
        message: LOADER_MESSAGE(),
        time: LOADER_TIME,
        clazz: 'mobile',
      });
      try {
        await new Promise((resolve) => setTimeout(resolve, 40));
        const thumbnail_factor = thumbnail_width_min_px / art_board_size.cutting.width;
        const printable_factor = INCH_PER_MM * DPI;
        const result = await makeGridStickerResult({
          grid_sticker_editor,
          container_el: document.body,
          thumbnail_stroke_width_px,
          thumbnail_factor,
          printable_factor,
          cutting_line_svg_el,
          art_board_cutting: {
            x: art_board_size.cutting.x,
            y: art_board_size.cutting.y,
            width: art_board_size.cutting.width,
            height: art_board_size.cutting.height,
          },
          too_big_image_error_message: T(
            'modules::VectorEditor::Sticker::message::스티커 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.',
          ),
        });
        await postProcess?.(result);
        return result;
      } finally {
        await loaderDone().catch(noop);
      }
    },
  });
};

const makeFreeStickerMobile = async ({
  meta,
  svg_el,
  title,
  postProcess,
  thumbnail_width_min_px,
  thumbnail_stroke_width_px,
  art_board_size,
}) => {
  let foreground_svg_el;
  let free_template_svg_el;
  let single_empty_template_svg_el;
  let sticker_collision_margin;
  let collision_container_path_data;
  let collision_container_area_position;
  let collision_forbidden_area_position;
  let background_color;
  let background_opacity;
  let single_art_board_size_width;
  let single_art_board_size_height;
  $.don_loader_start();
  try {
    ({
      foreground_svg_el,
      free_template_svg_el,
      single_empty_template_svg_el,
      sticker_collision_margin,
      collision_container_path_data,
      collision_container_area_position,
      collision_forbidden_area_position,
      background_color,
      background_opacity,
      single_art_board_size_width,
      single_art_board_size_height,
    } = await prepareFreeStickerMetadata({ meta, svg_el }));
    if (G._en !== '') single_empty_template_svg_el = null;
  } finally {
    $.don_loader_end();
  }

  return VectorEditorStickerFreeMobileF.makeSticker({
    title,
    art_board_size,
    free_template_svg_el,
    single_empty_template_svg_el,
    single_art_board_size: {
      width: single_art_board_size_width,
      height: single_art_board_size_height,
    },
    collision: {
      sticker_margin: sticker_collision_margin,
      container_path_data: collision_container_path_data,
      container_area_position: collision_container_area_position,
      forbidden_area_position: collision_forbidden_area_position,
    },
    preProcess: async (free_sticker_editor, tab_el) => {
      free_sticker_editor.setForeground(foreground_svg_el);

      free_sticker_editor.setBackground({
        type: 'color',
        fill: background_color,
        opacity: background_opacity,
      });

      if (!svg_el) {
        return;
      }

      const working_layer_el = find((el) =>
        equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('working-layer'),
      )(svg_el.children);
      if (isNil(working_layer_el)) {
        const error = new Error(`Cannot find the "working-layer" element.`);
        error.__mp_alert_message = `작업했던 정보를 찾을 수 없습니다.`;
        throw error;
      }
      const wrapper_els = filter((el) => equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('wrapper'))(
        working_layer_el.children,
      );
      const target_image_els = go(
        wrapper_els,
        flatMapL((wrapper_el) => wrapper_el.children),
        filterL((el) => equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('target-image-wrapper')),
        flatMapL((target_image_wrapper_el) => target_image_wrapper_el.children),
        filter((el) => equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('target-image')),
      );

      $.don_loader_start();
      try {
        await go(
          target_image_els,
          flatMapL((target_image_el) => target_image_el.querySelectorAll(`image[data-mpse__original_url]`)),
          eachL(async (image_el) => {
            const data_url = await makeImageDataURL(image_el.dataset.mpse__original_url);
            image_el.setAttributeNS(null, 'href', data_url);
          }),
          takeAllC,
        );
        await go(
          target_image_els,
          flatMapL((target_image_el) => VectorEditorFontF.getAllUsedFonts(target_image_el)),
          (iter) =>
            reduce(
              (acc, cur) => {
                acc.add(cur);
                return acc;
              },
              new Set(),
              iter,
            ),
          (font_family_set) =>
            filterL(({ fontFamily }) => font_family_set.has(fontFamily))(SVGEditorFontConstS.FONTS),
          mapL(({ fontFamily: font_family, fontStyle: font_style, fontWeight: font_weight }) =>
            new FontFaceObserver(font_family, { weight: font_weight, style: font_style }).load(null, 10000),
          ),
          takeAllC,
        );
      } finally {
        $.don_loader_end();
      }

      const transform_manipulator = new TransformNS.ModelNS.TransformManipulator({
        svg_el: document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg'),
      });
      each((wrapper_el) => {
        const cutting_line_path_data = wrapper_el
          .querySelector(`path[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="cutting-line-clip-path"]`)
          ?.getAttributeNS(null, 'd');
        if (!cutting_line_path_data) {
          const error = new Error(`No cutting_line_path_data`);
          error.__mp_alert_message = '재단선 정보를 찾을 수 없습니다.';
          throw error;
        }
        const target_image_el = wrapper_el.querySelector(
          `svg[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image"]`,
        );
        if (!target_image_el) {
          const error = new Error(`No target_image_el`);
          error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::스티커를 찾을 수 없습니다.');
          throw error;
        }

        const { wrapper_el: new_wrapper_el } = free_sticker_editor.addEl({
          target_image_el,
          cutting_line_path_data,
        });
        free_template_svg_el.dataset.is_show = 'false';
        VectorEditorStickerFreeMobileF.show2TypeNoneMakeStickerFooter(tab_el);
        if (transform_manipulator.getIsInitTransform(wrapper_el)) {
          const fixed_transform = transform_manipulator.getFixedTransform(wrapper_el);
          const before_base_transform = transform_manipulator.getBeforeBaseTransform(wrapper_el);
          const base_transform = transform_manipulator.getBaseTransform(wrapper_el);
          const after_base_transform = transform_manipulator.getAfterBaseTransform(wrapper_el);
          if (
            every(negate(isNil))([
              fixed_transform,
              before_base_transform,
              base_transform,
              after_base_transform,
            ])
          ) {
            transform_manipulator.initTransform(new_wrapper_el);
            transform_manipulator.setFixedTransform(fixed_transform.matrix, new_wrapper_el);
            transform_manipulator.setExtendedTransformBeforeBase(
              before_base_transform.matrix,
              new_wrapper_el,
            );
            transform_manipulator.setBaseTransform(base_transform.matrix, new_wrapper_el);
            transform_manipulator.setExtendedTransformAfterBase(after_base_transform.matrix, new_wrapper_el);
            new_wrapper_el.dataset[TransformNS.ModelNS.DATA_KEY_IS_SCALED] =
              wrapper_el.dataset[TransformNS.ModelNS.DATA_KEY_IS_SCALED] ?? 'false';
          }
        }
      })(wrapper_els);
    },
    postProcess: async (free_sticker_editor) => {
      const loaderDone = SVGEditorUtilF.percentLoader({
        message: LOADER_MESSAGE(),
        time: LOADER_TIME,
        clazz: 'mobile',
      });
      try {
        const thumbnail_factor = thumbnail_width_min_px / art_board_size.cutting.width;
        const printable_factor = INCH_PER_MM * DPI;
        const result = await makeFreeStickerResult({
          free_sticker_editor,
          thumbnail_stroke_width_px,
          thumbnail_factor,
          printable_factor,
          art_board_cutting: {
            x: art_board_size.cutting.x,
            y: art_board_size.cutting.y,
            width: art_board_size.cutting.width,
            height: art_board_size.cutting.height,
          },
          too_big_image_error_message: T(
            'modules::VectorEditor::Sticker::message::스티커 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.',
          ),
          image_error_message: T('modules::VectorEditor::Sticker::message::fail_image'),
        });
        await postProcess?.(result);
        return result;
      } finally {
        await loaderDone().catch(noop);
      }
    },
  });
};

export const makeStickerMobile = async ({
  title,
  options,
  svg_el: svg_file,
  thumbnail_width_min_px,
  thumbnail_stroke_width_px = 1,
  postProcess,
}) => {
  thumbnail_stroke_width_px = thumbnail_stroke_width_px ?? 1;

  const meta = go(
    options,
    mapL((o) => o?.snapshot?.maker_meta?.value),
    rejectL(isNil),
    (iter) => reduce((a, b) => Object.assign(a, b), {}, iter),
  );

  const vector_editor_type = meta.vector_editor_type;

  const art_board_width = meta.art_board_size?.width;
  const art_board_height = meta.art_board_size?.height;
  const art_board_cutting_x = meta.art_board_size?.cutting?.x;
  const art_board_cutting_y = meta.art_board_size?.cutting?.y;
  const art_board_cutting_width = meta.art_board_size?.cutting?.width;
  const art_board_cutting_height = meta.art_board_size?.cutting?.height;
  each((size) => {
    if (isNil(size) || Number.isNaN(size) || !Number.isFinite(size) || size <= 0) {
      const error = new Error(
        `Invalid "art_board_size" option. : { "width": ${art_board_width}, "height": ${art_board_height}, "cutting": { "x": ${art_board_cutting_x}, "y": ${art_board_cutting_y}, "width": ${art_board_cutting_width}, "height": ${art_board_cutting_height} } }`,
      );
      error.__mp_alert_message = `잘못된 아트보드 크기입니다.`;
      throw error;
    }
  })([
    art_board_width,
    art_board_height,
    art_board_cutting_width,
    art_board_cutting_height,
    art_board_cutting_x,
    art_board_cutting_y,
  ]);

  const svg_el = await (async () => {
    if (!svg_file) {
      return null;
    }

    const _svg_file = await svg_file;
    if (!_svg_file) {
      return null;
    }

    const svg_str = /** @type {string} */ await new Promise((resolve, reject) => {
      $.don_loader_start();
      try {
        const file_reader = new FileReader();
        file_reader.addEventListener('load', () => resolve(file_reader.result));
        file_reader.addEventListener('error', reject);
        file_reader.readAsText(_svg_file);
      } catch (error) {
        reject(error);
      } finally {
        $.don_loader_end();
      }
    });

    const svg_doc = parseSVGFromStr(svg_str);
    const parser_error_el = svg_doc.querySelector('parsererror');
    if (parser_error_el != null) {
      const error = new Error(parser_error_el.innerText);
      error.__mp_alert_message = 'SVG FILE ERROR';
      throw error;
    }
    const svg_el = svg_doc.querySelector('svg');
    if (svg_el == null) {
      const error = new Error('Cannot find a SVG element.');
      error.__mp_alert_message = 'SVG OBJECT ERROR';
      throw error;
    }

    return svg_el;
  })();

  if (equals2(vector_editor_type)('grid')) {
    return await makeGridStickerMobile({
      svg_el,
      title,
      postProcess,
      thumbnail_width_min_px,
      thumbnail_stroke_width_px,
      meta,
      art_board_size: {
        width: art_board_width,
        height: art_board_height,
        cutting: {
          x: art_board_cutting_x,
          y: art_board_cutting_y,
          width: art_board_cutting_width,
          height: art_board_cutting_height,
        },
      },
    });
  }

  if (equals2(vector_editor_type)('free')) {
    return await makeFreeStickerMobile({
      meta,
      svg_el,
      title,
      art_board_size: {
        width: art_board_width,
        height: art_board_height,
        cutting: {
          x: art_board_cutting_x,
          y: art_board_cutting_y,
          width: art_board_cutting_width,
          height: art_board_cutting_height,
        },
      },
      postProcess,
      thumbnail_width_min_px,
      thumbnail_stroke_width_px,
    });
  }

  const error = new Error(`Invalid "vector_editor_type" option. : ${vector_editor_type}`);
  error.__mp_alert_message = `TYPE ERROR`;
  throw error;
};

const makeGridStickerPC = async ({
  svg_el,
  title,
  price,
  postProcess,
  thumbnail_width_min_px,
  thumbnail_stroke_width_px,
  meta,
  prev_frame_right_panel,
  art_board_size,
  frame_position,
  onFrameHiding,
}) => {
  let foreground_svg_el;
  let cutting_line_svg_el;
  let grids;

  $.don_loader_start();
  try {
    ({ foreground_svg_el, cutting_line_svg_el, grids } = await prepareGridStickerMetadata({ meta }));
  } finally {
    $.don_loader_end();
  }

  const background = (() => {
    if (!svg_el) {
      return { fill: '#ffffff', opacity: 1 };
    }

    return go(
      svg_el.querySelector(
        `[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-layer"] [data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-color"]`,
      ),
      (background_color_el) => ({
        fill: background_color_el?.getAttributeNS?.(null, 'fill') ?? '#ffffff',
        opacity: background_color_el?.getAttributeNS?.(null, 'opacity') ?? '1',
      }),
      ({ fill, opacity: opacity_str }) => {
        let opacity = parseFloat(opacity_str);
        opacity = Number.isNaN(opacity) ? 1 : opacity;
        return { fill, opacity };
      },
    );
  })();

  return await VectorEditorStickerGridPCF.makeSticker({
    title,
    price,
    frame_position,
    art_board_size,
    background,
    grids,
    prev_frame_right_panel,
    onFrameHiding,
    preProcess: async (grid_sticker_editor) => {
      grid_sticker_editor.setForeground(foreground_svg_el);

      if (!svg_el) {
        return;
      }

      const target_image_els = go(
        svg_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image-layer"]`),
        flatMapL((layer_el) =>
          layer_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image"]`),
        ),
        takeAll,
      );
      $.don_loader_start();
      await go(
        target_image_els,
        flatMapL((target_image_el) => target_image_el.querySelectorAll(`image[data-mpse__original_url]`)),
        eachL(async (image_el) => {
          const data_url = await makeImageDataURL(image_el.dataset.mpse__original_url);
          image_el.setAttributeNS(null, 'href', data_url);
        }),
        takeAllC,
      );
      await go(
        target_image_els,
        flatMapL((target_image_el) => VectorEditorFontF.getAllUsedFonts(target_image_el)),
        (iter) =>
          reduce(
            (acc, cur) => {
              acc.add(cur);
              return acc;
            },
            new Set(),
            iter,
          ),
        (font_family_set) =>
          filterL(({ fontFamily }) => font_family_set.has(fontFamily))(SVGEditorFontConstS.FONTS),
        mapL(({ fontFamily: font_family, fontStyle: font_style, fontWeight: font_weight }) =>
          new FontFaceObserver(font_family, { weight: font_weight, style: font_style }).load(null, 10000),
        ),
        takeAllC,
      );
      $.don_loader_end();

      go(
        svg_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image-layer"]`),
        flatMapL((layer_el) =>
          layer_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image"]`),
        ),
        each((target_image_el) => {
          const id = target_image_el.dataset[EditorNS.GridStickerEditorNS.BaseNS.DATA_KEY_GRID_ID];
          grid_sticker_editor.setTargetImage({
            id,
            target_image_el,
          });
        }),
      );
    },
    postProcess: async (grid_sticker_editor) => {
      const loaderDone = SVGEditorUtilF.percentLoader({
        message: LOADER_MESSAGE(),
        time: LOADER_TIME,
        clazz: 'pc',
      });
      try {
        await new Promise((resolve) => setTimeout(resolve, 40));
        const thumbnail_factor = thumbnail_width_min_px / art_board_size.cutting.width;
        const printable_factor = INCH_PER_MM * DPI;
        const result = await makeGridStickerResult({
          grid_sticker_editor,
          container_el: document.body,
          thumbnail_stroke_width_px,
          thumbnail_factor,
          printable_factor,
          cutting_line_svg_el,
          art_board_cutting: {
            x: art_board_size.cutting.x,
            y: art_board_size.cutting.y,
            width: art_board_size.cutting.width,
            height: art_board_size.cutting.height,
          },
          too_big_image_error_message: T(
            'modules::VectorEditor::Sticker::message::스티커 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.',
          ),
        });
        await postProcess?.(result);
        return result;
      } finally {
        await loaderDone().catch(noop);
      }
    },
  });
};

const makeGridStickerCreatorPC = async ({
  svg_el,
  title,
  price,
  postProcess,
  thumbnail_width_min_px,
  thumbnail_stroke_width_px,
  meta,
  art_board_size,
}) => {
  let foreground_svg_el;
  let cutting_line_svg_el;
  let grids;

  $.don_loader_start();
  try {
    ({ foreground_svg_el, cutting_line_svg_el, grids } = await prepareGridStickerMetadata({ meta }));
  } finally {
    $.don_loader_end();
  }

  const background = (() => {
    if (!svg_el) {
      return { fill: '#ffffff', opacity: 1 };
    }

    return go(
      svg_el.querySelector(
        `[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-layer"] [data-${CommonNS.ConstNS.DATA_KEY_ROLE}="background-color"]`,
      ),
      (background_color_el) => ({
        fill: background_color_el?.getAttributeNS?.(null, 'fill') ?? '#ffffff',
        opacity: background_color_el?.getAttributeNS?.(null, 'opacity') ?? '1',
      }),
      ({ fill, opacity: opacity_str }) => {
        let opacity = parseFloat(opacity_str);
        opacity = Number.isNaN(opacity) ? 1 : opacity;
        return { fill, opacity };
      },
    );
  })();

  return VectorEditorStickerGridCreatorPCF.makeSticker({
    title,
    price,
    art_board_size,
    background,
    grids,
    preProcess: async (grid_sticker_editor) => {
      grid_sticker_editor.setForeground(foreground_svg_el);

      if (!svg_el) {
        return;
      }

      const target_image_els = go(
        svg_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image-layer"]`),
        flatMapL((layer_el) =>
          layer_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image"]`),
        ),
        takeAll,
      );
      $.don_loader_start();
      await go(
        target_image_els,
        flatMapL((target_image_el) => target_image_el.querySelectorAll(`image[data-mpse__original_url]`)),
        eachL(async (image_el) => {
          const data_url = await makeImageDataURL(image_el.dataset.mpse__original_url);
          image_el.setAttributeNS(null, 'href', data_url);
        }),
        takeAllC,
      );
      await go(
        target_image_els,
        flatMapL((target_image_el) => VectorEditorFontF.getAllUsedFonts(target_image_el)),
        (iter) =>
          reduce(
            (acc, cur) => {
              acc.add(cur);
              return acc;
            },
            new Set(),
            iter,
          ),
        (font_family_set) =>
          filterL(({ fontFamily }) => font_family_set.has(fontFamily))(SVGEditorFontConstS.FONTS),
        mapL(({ fontFamily: font_family, fontStyle: font_style, fontWeight: font_weight }) =>
          new FontFaceObserver(font_family, { weight: font_weight, style: font_style }).load(null, 10000),
        ),
        takeAllC,
      );
      $.don_loader_end();

      go(
        svg_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image-layer"]`),
        flatMapL((layer_el) =>
          layer_el.querySelectorAll(`[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image"]`),
        ),
        each((target_image_el) => {
          const id = target_image_el.dataset[EditorNS.GridStickerEditorNS.BaseNS.DATA_KEY_GRID_ID];
          grid_sticker_editor.setTargetImage({
            id,
            target_image_el,
          });
        }),
      );
    },
    postProcess: async (grid_sticker_editor) => {
      const loaderDone = SVGEditorUtilF.percentLoader({
        message: LOADER_MESSAGE(),
        time: LOADER_TIME,
        clazz: 'pc',
      });
      try {
        await new Promise((resolve) => setTimeout(resolve, 40));
        const thumbnail_factor = thumbnail_width_min_px / art_board_size.cutting.width;
        const printable_factor = INCH_PER_MM * DPI;
        const result = await makeGridStickerResult({
          grid_sticker_editor,
          container_el: document.body,
          thumbnail_stroke_width_px,
          thumbnail_factor,
          printable_factor,
          cutting_line_svg_el,
          art_board_cutting: {
            x: art_board_size.cutting.x,
            y: art_board_size.cutting.y,
            width: art_board_size.cutting.width,
            height: art_board_size.cutting.height,
          },
          too_big_image_error_message: T(
            'modules::VectorEditor::Sticker::message::스티커 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.',
          ),
        });
        await postProcess?.(result);
        return result;
      } finally {
        await loaderDone().catch(noop);
      }
    },
  });
};

const makeFreeStickerPC = async ({
  meta,
  svg_el,
  title,
  price,
  prev_frame_right_panel,
  art_board_size,
  frame_position,
  onFrameHiding,
  postProcess,
  thumbnail_width_min_px,
  thumbnail_stroke_width_px,
}) => {
  let foreground_svg_el;
  let free_template_svg_el;
  let single_empty_template_svg_el;
  let sticker_collision_margin;
  let collision_container_path_data;
  let collision_container_area_position;
  let collision_forbidden_area_position;
  let background_color;
  let background_opacity;
  let single_art_board_size_width;
  let single_art_board_size_height;
  $.don_loader_start();
  try {
    ({
      foreground_svg_el,
      free_template_svg_el,
      single_empty_template_svg_el,
      sticker_collision_margin,
      collision_container_path_data,
      collision_container_area_position,
      collision_forbidden_area_position,
      background_color,
      background_opacity,
      single_art_board_size_width,
      single_art_board_size_height,
    } = await prepareFreeStickerMetadata({ meta, svg_el }));
    if (G._en !== '') single_empty_template_svg_el = null;
  } finally {
    $.don_loader_end();
  }
  return VectorEditorStickerFreePCF.makeSticker({
    title,
    price,
    frame_position,
    prev_frame_right_panel,
    onFrameHiding,
    art_board_size,
    free_template_svg_el,
    single_empty_template_svg_el,
    single_art_board_size: {
      width: single_art_board_size_width,
      height: single_art_board_size_height,
    },
    collision: {
      sticker_margin: sticker_collision_margin,
      container_path_data: collision_container_path_data,
      container_area_position: collision_container_area_position,
      forbidden_area_position: collision_forbidden_area_position,
    },
    background_color,
    background_opacity,
    preProcess: async (free_sticker_editor, tab_el) => {
      free_sticker_editor.setForeground(foreground_svg_el);

      if (!svg_el) {
        return;
      }

      const working_layer_el = find((el) =>
        equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('working-layer'),
      )(svg_el.children);
      if (isNil(working_layer_el)) {
        const error = new Error(`Cannot find the "working-layer" element.`);
        error.__mp_alert_message = `작업했던 정보를 찾을 수 없습니다.`;
        throw error;
      }
      const wrapper_els = filter((el) => equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('wrapper'))(
        working_layer_el.children,
      );
      const target_image_els = go(
        wrapper_els,
        flatMapL((wrapper_el) => wrapper_el.children),
        filterL((el) => equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('target-image-wrapper')),
        flatMapL((target_image_wrapper_el) => target_image_wrapper_el.children),
        filter((el) => equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('target-image')),
      );

      $.don_loader_start();
      try {
        await go(
          target_image_els,
          flatMapL((target_image_el) => target_image_el.querySelectorAll(`image[data-mpse__original_url]`)),
          eachL(async (image_el) => {
            const data_url = await makeImageDataURL(image_el.dataset.mpse__original_url);
            image_el.setAttributeNS(null, 'href', data_url);
          }),
          takeAllC,
        );
        await go(
          target_image_els,
          flatMapL((target_image_el) => VectorEditorFontF.getAllUsedFonts(target_image_el)),
          (iter) =>
            reduce(
              (acc, cur) => {
                acc.add(cur);
                return acc;
              },
              new Set(),
              iter,
            ),
          (font_family_set) =>
            filterL(({ fontFamily }) => font_family_set.has(fontFamily))(SVGEditorFontConstS.FONTS),
          mapL(({ fontFamily: font_family, fontStyle: font_style, fontWeight: font_weight }) =>
            new FontFaceObserver(font_family, { weight: font_weight, style: font_style }).load(null, 10000),
          ),
          takeAllC,
        );
      } finally {
        $.don_loader_end();
      }

      const transform_manipulator = new TransformNS.ModelNS.TransformManipulator({
        svg_el: document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg'),
      });
      each((wrapper_el) => {
        const cutting_line_path_data = wrapper_el
          .querySelector(`path[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="cutting-line-clip-path"]`)
          ?.getAttributeNS(null, 'd');
        if (!cutting_line_path_data) {
          const error = new Error(`No cutting_line_path_data`);
          error.__mp_alert_message = '재단선 정보를 찾을 수 없습니다.';
          throw error;
        }
        const target_image_el = wrapper_el.querySelector(
          `svg[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image"]`,
        );
        if (!target_image_el) {
          const error = new Error(`No target_image_el`);
          error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::스티커를 찾을 수 없습니다.');
          throw error;
        }

        const { wrapper_el: new_wrapper_el } = free_sticker_editor.addEl({
          target_image_el,
          cutting_line_path_data,
        });
        free_template_svg_el.dataset.is_show = 'false';
        go(
          tab_el,
          $findAll(`.new_sticker_button`),
          each((el) => (el.dataset.is_show = 'true')),
        );
        if (transform_manipulator.getIsInitTransform(wrapper_el)) {
          const fixed_transform = transform_manipulator.getFixedTransform(wrapper_el);
          const before_base_transform = transform_manipulator.getBeforeBaseTransform(wrapper_el);
          const base_transform = transform_manipulator.getBaseTransform(wrapper_el);
          const after_base_transform = transform_manipulator.getAfterBaseTransform(wrapper_el);
          if (
            every(negate(isNil))([
              fixed_transform,
              before_base_transform,
              base_transform,
              after_base_transform,
            ])
          ) {
            transform_manipulator.initTransform(new_wrapper_el);
            transform_manipulator.setFixedTransform(fixed_transform.matrix, new_wrapper_el);
            transform_manipulator.setExtendedTransformBeforeBase(
              before_base_transform.matrix,
              new_wrapper_el,
            );
            transform_manipulator.setBaseTransform(base_transform.matrix, new_wrapper_el);
            transform_manipulator.setExtendedTransformAfterBase(after_base_transform.matrix, new_wrapper_el);
            new_wrapper_el.dataset[TransformNS.ModelNS.DATA_KEY_IS_SCALED] =
              wrapper_el.dataset[TransformNS.ModelNS.DATA_KEY_IS_SCALED] ?? 'false';
          }
        }
      })(wrapper_els);
    },
    postProcess: async (free_sticker_editor) => {
      const loaderDone = SVGEditorUtilF.percentLoader({
        message: LOADER_MESSAGE(),
        time: LOADER_TIME,
        clazz: 'pc',
      });
      try {
        const thumbnail_factor = thumbnail_width_min_px / art_board_size.cutting.width;
        const printable_factor = INCH_PER_MM * DPI;
        const result = await makeFreeStickerResult({
          free_sticker_editor,
          thumbnail_stroke_width_px,
          thumbnail_factor,
          printable_factor,
          art_board_cutting: {
            x: art_board_size.cutting.x,
            y: art_board_size.cutting.y,
            width: art_board_size.cutting.width,
            height: art_board_size.cutting.height,
          },
          too_big_image_error_message: T(
            'modules::VectorEditor::Sticker::message::스티커 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.',
          ),
          image_error_message: T('modules::VectorEditor::Sticker::message::fail_image'),
        });
        await postProcess?.(result);
        return result;
      } finally {
        await loaderDone().catch(noop);
      }
    },
  });
};

const makeFreeStickerCreatorPC = async ({
  meta,
  svg_el,
  title,
  price,
  art_board_size,
  postProcess,
  thumbnail_width_min_px,
  thumbnail_stroke_width_px,
}) => {
  let foreground_svg_el;
  let free_template_svg_el;
  let single_empty_template_svg_el;
  let sticker_collision_margin;
  let collision_container_path_data;
  let collision_container_area_position;
  let collision_forbidden_area_position;
  let background_color;
  let background_opacity;
  let single_art_board_size_width;
  let single_art_board_size_height;
  $.don_loader_start();
  try {
    ({
      foreground_svg_el,
      free_template_svg_el,
      single_empty_template_svg_el,
      sticker_collision_margin,
      collision_container_path_data,
      collision_container_area_position,
      collision_forbidden_area_position,
      background_color,
      background_opacity,
      single_art_board_size_width,
      single_art_board_size_height,
    } = await prepareFreeStickerMetadata({ meta, svg_el }));
    if (G._en !== '') single_empty_template_svg_el = null;
  } finally {
    $.don_loader_end();
  }

  return VectorEditorStickerFreeCreatorPCF.makeSticker({
    title,
    price,
    art_board_size,
    free_template_svg_el,
    single_empty_template_svg_el,
    single_art_board_size: {
      width: single_art_board_size_width,
      height: single_art_board_size_height,
    },
    collision: {
      sticker_margin: sticker_collision_margin,
      container_path_data: collision_container_path_data,
      container_area_position: collision_container_area_position,
      forbidden_area_position: collision_forbidden_area_position,
    },
    background_color,
    background_opacity,
    preProcess: async (free_sticker_editor, tab_el) => {
      free_sticker_editor.setForeground(foreground_svg_el);

      if (!svg_el) {
        return;
      }

      const working_layer_el = find((el) =>
        equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('working-layer'),
      )(svg_el.children);
      if (isNil(working_layer_el)) {
        const error = new Error(`Cannot find the "working-layer" element.`);
        error.__mp_alert_message = `작업했던 정보를 찾을 수 없습니다.`;
        throw error;
      }
      const wrapper_els = filter((el) => equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('wrapper'))(
        working_layer_el.children,
      );
      const target_image_els = go(
        wrapper_els,
        flatMapL((wrapper_el) => wrapper_el.children),
        filterL((el) => equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('target-image-wrapper')),
        flatMapL((target_image_wrapper_el) => target_image_wrapper_el.children),
        filter((el) => equals2(el.dataset[CommonNS.ConstNS.DATA_KEY_ROLE])('target-image')),
      );

      $.don_loader_start();
      try {
        await go(
          target_image_els,
          flatMapL((target_image_el) => target_image_el.querySelectorAll(`image[data-mpse__original_url]`)),
          eachL(async (image_el) => {
            const data_url = await makeImageDataURL(image_el.dataset.mpse__original_url);
            image_el.setAttributeNS(null, 'href', data_url);
          }),
          takeAllC,
        );
        await go(
          target_image_els,
          flatMapL((target_image_el) => VectorEditorFontF.getAllUsedFonts(target_image_el)),
          (iter) =>
            reduce(
              (acc, cur) => {
                acc.add(cur);
                return acc;
              },
              new Set(),
              iter,
            ),
          (font_family_set) =>
            filterL(({ fontFamily }) => font_family_set.has(fontFamily))(SVGEditorFontConstS.FONTS),
          mapL(({ fontFamily: font_family, fontStyle: font_style, fontWeight: font_weight }) =>
            new FontFaceObserver(font_family, { weight: font_weight, style: font_style }).load(null, 10000),
          ),
          takeAllC,
        );
      } finally {
        $.don_loader_end();
      }

      const transform_manipulator = new TransformNS.ModelNS.TransformManipulator({
        svg_el: document.createElementNS(CommonNS.ConstNS.SVG_NAMESPACE, 'svg'),
      });
      each((wrapper_el) => {
        const cutting_line_path_data = wrapper_el
          .querySelector(`path[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="cutting-line-clip-path"]`)
          ?.getAttributeNS(null, 'd');
        if (!cutting_line_path_data) {
          const error = new Error(`No cutting_line_path_data`);
          error.__mp_alert_message = '재단선 정보를 찾을 수 없습니다.';
          throw error;
        }
        const target_image_el = wrapper_el.querySelector(
          `svg[data-${CommonNS.ConstNS.DATA_KEY_ROLE}="target-image"]`,
        );
        if (!target_image_el) {
          const error = new Error(`No target_image_el`);
          error.__mp_alert_message = T('modules::VectorEditor::Sticker::message::스티커를 찾을 수 없습니다.');
          throw error;
        }

        const { wrapper_el: new_wrapper_el } = free_sticker_editor.addEl({
          target_image_el,
          cutting_line_path_data,
        });
        free_template_svg_el.dataset.is_show = 'false';
        go(
          tab_el,
          $findAll(`.new_sticker_button`),
          each((el) => (el.dataset.is_show = 'true')),
        );
        if (transform_manipulator.getIsInitTransform(wrapper_el)) {
          const fixed_transform = transform_manipulator.getFixedTransform(wrapper_el);
          const before_base_transform = transform_manipulator.getBeforeBaseTransform(wrapper_el);
          const base_transform = transform_manipulator.getBaseTransform(wrapper_el);
          const after_base_transform = transform_manipulator.getAfterBaseTransform(wrapper_el);
          if (
            every(negate(isNil))([
              fixed_transform,
              before_base_transform,
              base_transform,
              after_base_transform,
            ])
          ) {
            transform_manipulator.initTransform(new_wrapper_el);
            transform_manipulator.setFixedTransform(fixed_transform.matrix, new_wrapper_el);
            transform_manipulator.setExtendedTransformBeforeBase(
              before_base_transform.matrix,
              new_wrapper_el,
            );
            transform_manipulator.setBaseTransform(base_transform.matrix, new_wrapper_el);
            transform_manipulator.setExtendedTransformAfterBase(after_base_transform.matrix, new_wrapper_el);
            new_wrapper_el.dataset[TransformNS.ModelNS.DATA_KEY_IS_SCALED] =
              wrapper_el.dataset[TransformNS.ModelNS.DATA_KEY_IS_SCALED] ?? 'false';
          }
        }
      })(wrapper_els);
    },
    postProcess: async (free_sticker_editor) => {
      const loaderDone = SVGEditorUtilF.percentLoader({
        message: LOADER_MESSAGE(),
        time: LOADER_TIME,
        clazz: 'pc',
      });
      try {
        const thumbnail_factor = thumbnail_width_min_px / art_board_size.cutting.width;
        const printable_factor = INCH_PER_MM * DPI;
        const result = await makeFreeStickerResult({
          free_sticker_editor,
          thumbnail_stroke_width_px,
          thumbnail_factor,
          printable_factor,
          art_board_cutting: {
            x: art_board_size.cutting.x,
            y: art_board_size.cutting.y,
            width: art_board_size.cutting.width,
            height: art_board_size.cutting.height,
          },
          too_big_image_error_message: T(
            'modules::VectorEditor::Sticker::message::스티커 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.',
          ),
          image_error_message: T('modules::VectorEditor::Sticker::message::fail_image'),
        });
        await postProcess?.(result);
        return result;
      } finally {
        await loaderDone().catch(noop);
      }
    },
  });
};

export const makeStickerPC = async ({
  app,
  title,
  price,
  options,
  svg_el: svg_file,
  thumbnail_width_min_px,
  thumbnail_stroke_width_px = 1,
  prev_frame_right_panel,
  postProcess,
  getFramePosition,
  onFrameHiding,
}) => {
  thumbnail_stroke_width_px = thumbnail_stroke_width_px ?? 1;

  const svg_el = await (async () => {
    if (!svg_file) {
      return null;
    }

    $.don_loader_start();
    try {
      const _svg_file = await svg_file;
      if (!_svg_file) {
        return null;
      }

      const svg_str = /** @type {string} */ await new Promise((resolve, reject) => {
        try {
          const file_reader = new FileReader();
          file_reader.addEventListener('load', () => resolve(file_reader.result));
          file_reader.addEventListener('error', reject);
          file_reader.readAsText(_svg_file);
        } catch (error) {
          reject(error);
        }
      });

      const svg_doc = parseSVGFromStr(svg_str);
      const parser_error_el = svg_doc.querySelector('parsererror');
      if (parser_error_el != null) {
        const error = new Error(parser_error_el.innerText);
        error.__mp_alert_message = 'SVG 파일을 해석할 수 없습니다.';
        throw error;
      }
      const svg_el = svg_doc.querySelector('svg');
      if (svg_el == null) {
        const error = new Error('Cannot find a SVG element.');
        error.__mp_alert_message = 'SVG 객체를 찾을 수 없습니다.';
        throw error;
      }

      return svg_el;
    } finally {
      $.don_loader_end();
    }
  })();

  const { top: frame_top, height: frame_height } = await (async () => {
    $.don_loader_start();
    try {
      return await getFramePosition();
    } finally {
      $.don_loader_end();
    }
  })();

  const meta = go(
    options,
    mapL((o) => o?.snapshot?.maker_meta?.value),
    rejectL(isNil),
    (iter) => reduce((a, b) => Object.assign(a, b), {}, iter),
  );

  const vector_editor_type = meta.vector_editor_type;

  const art_board_width = meta.art_board_size?.width;
  const art_board_height = meta.art_board_size?.height;
  const art_board_cutting_x = meta.art_board_size?.cutting?.x;
  const art_board_cutting_y = meta.art_board_size?.cutting?.y;
  const art_board_cutting_width = meta.art_board_size?.cutting?.width;
  const art_board_cutting_height = meta.art_board_size?.cutting?.height;
  each((size) => {
    if (isNil(size) || Number.isNaN(size) || !Number.isFinite(size) || size <= 0) {
      const error = new Error(
        `Invalid "art_board_size" option. : { "width": ${art_board_width}, "height": ${art_board_height}, "cutting": { "x": ${art_board_cutting_x}, "y": ${art_board_cutting_y}, "width": ${art_board_cutting_width}, "height": ${art_board_cutting_height} } }`,
      );
      error.__mp_alert_message = `잘못된 아트보드 크기입니다.`;
      throw error;
    }
  })([
    art_board_width,
    art_board_height,
    art_board_cutting_width,
    art_board_cutting_height,
    art_board_cutting_x,
    art_board_cutting_y,
  ]);

  if (equals2(vector_editor_type)('grid') && equals2(app)('mp')) {
    return await makeGridStickerPC({
      svg_el,
      title,
      price,
      postProcess,
      thumbnail_width_min_px,
      thumbnail_stroke_width_px,
      meta,
      prev_frame_right_panel,
      frame_position: { top: frame_top, height: frame_height },
      art_board_size: {
        width: art_board_width,
        height: art_board_height,
        cutting: {
          x: art_board_cutting_x,
          y: art_board_cutting_y,
          width: art_board_cutting_width,
          height: art_board_cutting_height,
        },
      },
      onFrameHiding,
    });
  }

  if (equals2(vector_editor_type)('grid') && equals2(app)('creator')) {
    return await makeGridStickerCreatorPC({
      svg_el,
      title,
      price,
      postProcess,
      thumbnail_width_min_px,
      thumbnail_stroke_width_px,
      meta,
      art_board_size: {
        width: art_board_width,
        height: art_board_height,
        cutting: {
          x: art_board_cutting_x,
          y: art_board_cutting_y,
          width: art_board_cutting_width,
          height: art_board_cutting_height,
        },
      },
    });
  }

  if (equals2(vector_editor_type)('free') && equals2(app)('mp')) {
    return await makeFreeStickerPC({
      meta,
      svg_el,
      title,
      price,
      frame_position: { top: frame_top, height: frame_height },
      prev_frame_right_panel,
      onFrameHiding,
      art_board_size: {
        width: art_board_width,
        height: art_board_height,
        cutting: {
          x: art_board_cutting_x,
          y: art_board_cutting_y,
          width: art_board_cutting_width,
          height: art_board_cutting_height,
        },
      },
      postProcess,
      thumbnail_width_min_px,
      thumbnail_stroke_width_px,
    });
  }

  if (equals2(vector_editor_type)('free') && equals2(app)('creator')) {
    return await makeFreeStickerCreatorPC({
      meta,
      svg_el,
      title,
      price,
      art_board_size: {
        width: art_board_width,
        height: art_board_height,
        cutting: {
          x: art_board_cutting_x,
          y: art_board_cutting_y,
          width: art_board_cutting_width,
          height: art_board_cutting_height,
        },
      },
      postProcess,
      thumbnail_width_min_px,
      thumbnail_stroke_width_px,
    });
  }

  const error = new Error(`Invalid "vector_editor_type" option. : ${vector_editor_type} (app: ${app})`);
  error.__mp_alert_message = `잘못된 에디터 타입입니다.`;
  throw error;
};
