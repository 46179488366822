import {
  convertSvgElementToUploadedUrl,
  convertSvgTextToSvgEl,
  extractElsFromSvg,
  getSvgString,
} from './util.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import axios from 'axios';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

/**
 * @define 성공 결과 리턴
 * @return {SuccessReturn}
 * */
function returnSuccess({ data }) {
  return { is_OK: true, data, reason: null };
}

/**
 * @define 실패 결과 리턴
 * @return {FailReturn}
 * */
function returnFail({ reason = '' }) {
  return { is_OK: false, data: null, reason };
}

/**
 * @define 키링의 고리 구멍보다 작은 요소들 검사
 * @param {string} svg_url
 * @param {number} svg_product_page_id svg_product_pages.id
 * @return {SvgUtilReturn}
 * */
export async function sanitizeSmallerAreaKeyringHole({ svg_url, svg_product_page_id }) {
  function sendSentryF(msg) {
    ConfigSentryF.error(new Error(msg), { option: { extra: { svg_url, svg_product_page_id } } });
  }

  const { is_OK, data, reason } = await removeSmallAreaPathsInSvg({ svg_url, limit_area: 8 });

  if (!is_OK || data == null) {
    sendSentryF(reason);
    return returnSuccess({ data: svg_url });
  }
  try {
    let processed_svg_url;
    if (data.is_modified) {
      if (data?.svg_el == null || !(data.svg_el instanceof SVGElement)) {
        const reason = `Invalid output from function removeSmallAreaPathsInSvg`;
        sendSentryF(reason);
        return returnFail({ reason });
      }
      processed_svg_url = await convertSvgElementToUploadedUrl(data.svg_el);
    } else {
      processed_svg_url = svg_url;
    }

    await axios.post('/@api/svg_product_page/verified_url', {
      id: svg_product_page_id,
      verified_cutting_line_url: processed_svg_url,
      is_legacy_small_path: false,
    });
    return returnSuccess({ data: processed_svg_url });
  } catch (err) {
    const reason = UtilF.getErrMsg(err);
    sendSentryF(reason);
    return returnFail({ reason });
  }
}

/**
 * @define svg 칼선의 작은 area 를 검출해서 제거 혹은 유지
 * @param {string | undefined} svg 검증할 svg element 혹은 svg string
 * @param {string | undefined} svg_url 검증할 svg url
 * @param {number | undefined} product_color_id ups.product_color_id
 * @param {number} limit_area 제거 대상이 되는 path 의 pixel area (키링의 구멍 pixel_area 는 9)
 * @return {SvgUtilReturn} {data: 검증된 svg 요소}
 * */
export async function removeSmallAreaPathsInSvg({ svg, svg_url, product_color_id, limit_area }) {
  try {
    const svg_string = await getSvgString({ svg, svg_url, product_color_id });
    if (UtilS.isEmpty(svg_string)) {
      throw new Error(`Cannot retrieve SVG`);
    }

    const svg_el = convertSvgTextToSvgEl({ svg_string });

    const processed_svg_el = processSmallAreaEls({ svg_el, limit_area });

    return returnSuccess({ data: processed_svg_el });
  } catch (err) {
    return returnFail({ reason: err.message });
  }
}

/**
 * @define svg 요소에서 limit_area 이하의 요소들을 제거
 * @disclaimer path, circle, ellipse, polygon 외의 요소는 탐색하지 않음
 * @return {{is_modified: true, svg_el: SVGElement}}
 * */
function processSmallAreaEls({ svg_el, limit_area }) {
  const svg_el_cloned = cloneSvgElement({ svg_el });
  const svg_root_el = getRootSvgEl({ svg_el });

  const target_els = extractElsFromSvg({
    svg_el: svg_el_cloned,
    tags: ['path', 'circle', 'ellipse', 'polygon'],
  });

  let is_path_modified = false;
  let is_non_path_modified = false;

  target_els.forEach((el) => {
    if (isPathEl(el)) {
      is_path_modified = processSmallAreaForPathEl({ svg_root_el, path_el: el, limit_area });
    } else {
      is_non_path_modified = processSmallAreaForNonPathEl({ non_path_el: el, limit_area });
    }
  });

  return { is_modified: is_path_modified || is_non_path_modified, svg_el: svg_el_cloned };
}

function removeAllChildren(parent_el) {
  while (parent_el.firstChild) {
    parent_el.removeChild(parent_el.firstChild);
  }
}

function getRootSvgEl({ svg_el }) {
  const svg_cloned = cloneSvgElement({ svg_el });
  removeAllChildren(svg_cloned);

  return svg_cloned;
}

function processSmallAreaForPathEl({ svg_root_el, path_el, limit_area }) {
  let is_modified = false;
  const { is_compounded, path_data } = isCompoundPath({ path_el });

  if (is_compounded) {
    const processed_path_data = path_data
      .map((_path_data) => {
        const area = calculatePathBoundingAreaFromPathData({ svg_root_el, path_data: _path_data });
        if (area < limit_area) {
          is_modified = true;
          return null;
        } else {
          return _path_data;
        }
      })
      .join('');

    if (processed_path_data === '') {
      removeEl({ el: path_el });
    } else {
      path_el.setAttribute('d', processed_path_data);
    }
  } else {
    const area = calculatePathBoundingAreaFromPathData({ svg_root_el, path_data });
    if (area < limit_area) {
      removeEl({ el: path_el });
      is_modified = true;
    }
  }

  return is_modified;
}

function processSmallAreaForNonPathEl({ non_path_el, limit_area }) {
  let is_modified = false;
  const area = calculatePathBoundingAreaFromSvgChildEl({ svg_child_el: non_path_el });
  if (area < limit_area) {
    removeEl({ el: non_path_el });
    is_modified = true;
  }

  return is_modified;
}

function isPathEl(el) {
  if (!(el instanceof SVGElement)) throw new Error(`HTML 요소가 아닙니다.`);

  return el.tagName === 'path';
}

function cloneSvgElement({ svg_el }) {
  if (svg_el && svg_el instanceof SVGElement) {
    return svg_el.cloneNode(true);
  }

  throw new Error('Invalid SVG element provided');
}

function removeEl({ el }) {
  const parent_el = el.parentNode;
  if (parent_el) {
    parent_el.removeChild(el);
  } else {
    throw new Error(`path 요소의 부모 요소를 찾지 못해 제거할 수 없습니다.`);
  }
}

/**
 * @define 컴파운드 패스 인지 여부 확인
 * @description 비탐욕적으로 m 이나 M 을 search 해서 pathdata 를 분리
 * @param {SVGElement} path_el
 * @return {{is_compounded: true, path_data: string[] | {is_compounded: false, path_data: string}}} 컴파운드 패스 결과와 패스데이터 (배열 혹은 문자열)
 * */
function isCompoundPath({ path_el }) {
  const path_data = path_el.getAttribute('d');
  if (path_data == null) throw new Error(`path 요소 path_data 속성이 존재하지 않습니다.`);

  const splitted_path_data = path_data.split(/(?=M|m)/);

  const is_compounded = splitted_path_data.length > 1;

  return { is_compounded, path_data: is_compounded ? splitted_path_data : splitted_path_data[0] };
}

function calculatePathBoundingAreaFromPathData({ svg_root_el, path_data }) {
  const path_svg_el = createSvgWithPathByPathData({ svg_root_el, path_data });

  const unit = getUnitFromSvgEl({ svg_el: path_svg_el });

  document.body.appendChild(path_svg_el);
  const bbox = path_svg_el.getBBox();
  const area = bbox.width * bbox.height * (unit ? (25.4 / 72) ** 2 : 1); // SVG 에 unit 이 있는 경우 browser 가 72 dpi 로 해석해 버림. unit 이 없으면 1dot 으로 해석

  document.body.removeChild(path_svg_el);
  return area;
}

function calculatePathBoundingAreaFromSvgChildEl({ svg_child_el }) {
  const svg_el = createSvgWithChildEl({ svg_child_el });

  document.body.appendChild(svg_el);
  const bbox = svg_el.getBBox();
  const area = bbox.width * bbox.height;
  document.body.removeChild(svg_el);
  return area;
}

function createSvgWithPathByPathData({ svg_root_el, path_data }) {
  const svg_parent_el = svg_root_el
    ? cloneSvgElement({ svg_el: svg_root_el })
    : document.createElementNS('http://www.w3.org/2000/svg', 'svg');

  const path_el = document.createElementNS('http://www.w3.org/2000/svg', 'path');

  path_el.setAttribute('d', path_data);
  svg_parent_el.appendChild(path_el);

  return svg_parent_el;
}

function createSvgWithChildEl({ svg_child_el }) {
  const svg_parent_el = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg_parent_el.appendChild(svg_child_el);
  return svg_parent_el;
}

function getUnitFromSvgEl({ svg_el }) {
  const width = svg_el.getAttribute('width');

  if (width) {
    const reg = /(mm|cm|m|in)$/;
    const is_match = width.match(reg);
    if (is_match) {
      return is_match[0];
    }
  }

  return null;
}
