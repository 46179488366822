import { ProjectionConstantS } from '../../../../../modules/Projection/S/Constant/module/ProjectionConstantS.js';
import { getReceiptStatus } from '../../../../../modules/Receipt/S/fs.js';
import { UtilS } from '../../../../../modules/Util/S/Function/module/UtilS.js';

!(function () {
  G.df.receipt_document = G.df.receipt_document || {};
  G.df.receipt_document.tmpl = receipt_tmpl;

  const cash = (for_receipt) => pug`
    .for_receipt.infi_item[_sel="for_receipts->(#${for_receipt.id})" receipt_status="${getReceiptStatus(
    for_receipt.receipt,
  )}"]
      label
        span.mp_input
          input[type="checkbox" name="receipt" value="${for_receipt.projection_id}"]
          span
      .wrapper
        .rowm.info_1
          .colm
            a[href="/projection/detail/${for_receipt.projection_id}"].projection_id 주문번호 : ${
    for_receipt.projection_id
  }
            .date_time 주문날짜 : ${moment(for_receipt.created_at).format('YYYY-MM-DD')}
            .user_name 회원: ${for_receipt.member_name}
            .orderer_name 주문자: ${UtilS.escape(for_receipt.orderer_name)}
            .shipping_name 받는이: ${UtilS.escape(for_receipt.shipping_name)}
          .colm[style="display:none;"]
            button.open_receipt 영수증 보기
            button.request_receipt 발행 완료
        .rowm.info_2
          .type 현금영수증 신청
          .projection_status <span>주문서 상태: </span> ${projection_status(for_receipt.status)}
        .rowm.info_3
          table
            tr
              td.head 상태
              td.body.receipt_status
              td.head 금액
              td.body.won ${_p.commify(for_receipt.paid_amount - for_receipt.refund_amount)}
              td.head 휴대폰 번호
              td.body ${autoHypenPhone(UtilS.escape(for_receipt.receipt.identifier))}
        `;

  const company = (for_receipt) => pug`
    .for_receipt.infi_item[_sel="for_receipts->(#${for_receipt.id})" receipt_status="${getReceiptStatus(
    for_receipt.receipt,
  )}"]
      label
        span.mp_input
          input[type="checkbox" name="receipt" value="${for_receipt.projection_id}"]
          span
      .wrapper
        .rowm.info_1
          .colm
            a[href="/projection/detail/${for_receipt.projection_id}"].projection_id 주문번호 : ${
    for_receipt.projection_id
  }
            .date_time 주문날짜 : ${moment(for_receipt.created_at).format('YYYY-MM-DD')}
            .user_name 회원: ${for_receipt.member_name}
            .orderer_name 주문자: ${UtilS.escape(for_receipt.orderer_name)}
            .shipping_name 받는이: ${UtilS.escape(for_receipt.shipping_name)}
          .colm[style="display:none;"]
            button.open_receipt 영수증 보기
            button.request_receipt 발행 완료
        .rowm.info_2
          .type 사업자 지출증빙 신청
          .projection_status <span>주문서 상태: </span> ${projection_status(for_receipt.status)}
        .rowm.info_3
          table
            tr
              td.head 상태
              td.body.receipt_status
              td.head 금액
              td.body.won ${_p.commify(for_receipt.paid_amount - for_receipt.refund_amount)}
              td.head 사업자등록번호
              td.body ${UtilS.escape(for_receipt.receipt.identifier)}
    `;

  const taxBill = (for_receipt) => pug`
    .for_receipt.infi_item[_sel="for_receipts->(#${for_receipt.id})" receipt_status="${getReceiptStatus(
    for_receipt.receipt,
  )}"]
      label
        span.mp_input
          input[type="checkbox" name="receipt" value="${for_receipt.projection_id}"]
          span
      .wrapper
        .rowm.info_1
          .colm
            a[href="/projection/detail/${for_receipt.projection_id}"].projection_id 주문번호 : ${
    for_receipt.projection_id
  }
            .date_time 주문날짜 : ${moment(for_receipt.created_at).format('YYYY-MM-DD')}
            .user_name 회원: ${for_receipt.member_name}
            .orderer_name 주문자: ${UtilS.escape(for_receipt.orderer_name)}
            .shipping_name 받는이: ${UtilS.escape(for_receipt.shipping_name)}
          .colm[style="display:none;"]
            button.open_receipt 영수증 보기
            button.request_receipt 발행 완료
        .rowm.info_2
          .type 세금계산서 신청
          .projection_status <span>주문서 상태: </span> ${projection_status(for_receipt.status)}
        .rowm.info_3
          table
            tr
              td.head 상태
              td.body.receipt_status
              td.head 금액
              td.body.won ${_p.commify(for_receipt.paid_amount - for_receipt.refund_amount)}
              td.head 사업자 번호
              td.body ${UtilS.escape(for_receipt.receipt.company_num)}
            tr
              td.head 대표
              td.body ${UtilS.escape(for_receipt.receipt.worker_name)}
              td.head 업종
              td.body ${UtilS.escape(for_receipt.receipt.business_type)}
              td.head 업태
              td.body ${UtilS.escape(for_receipt.receipt.business_status)}
            tr
              td.head 이메일
              td.body ${UtilS.escape(for_receipt.receipt.email)}
              td.head 상호(기관명)
              td.body ${UtilS.escape(for_receipt.receipt.company_name)}
              td.head 사업자등록증
              td.body.business_license[class="${
                for_receipt.receipt.business_license ? 'cursor_pointer' : ''
              }"] ${for_receipt.receipt.business_license ? 'O' : 'X'}
            tr
              td.head 주소
              td.body[colspan="5"] ${UtilS.escape(for_receipt.receipt.company_addr)}, ${UtilS.escape(
    for_receipt.receipt.company_addr2,
  )}
      `;

  function autoHypenPhone(str) {
    str = str + '';
    str = str.replace(/[^0-9]/g, '');
    let tmp = '';
    if (str.length < 4) {
      return str;
    } else if (str.length < 7) {
      tmp += str.substr(0, 3);
      tmp += '-';
      tmp += str.substr(3);
      return tmp;
    } else if (str.length < 11) {
      tmp += str.substr(0, 3);
      tmp += '-';
      tmp += str.substr(3, 3);
      tmp += '-';
      tmp += str.substr(6);
      return tmp;
    } else {
      tmp += str.substr(0, 3);
      tmp += '-';
      tmp += str.substr(3, 4);
      tmp += '-';
      tmp += str.substr(7);
      return tmp;
    }
  }

  function projection_status(status) {
    if (status === 'before_confirm') return '회원확인전';
    if (status === 'confirmed') return '회원확인완료';
    return ProjectionConstantS.STATUS[status]?.label || '';
  }

  function receipt_tmpl(for_receipt) {
    if (for_receipt.receipt.type == 1) return cash(for_receipt);
    if (for_receipt.receipt.type == 2) return company(for_receipt);
    if (for_receipt.receipt.type == 3) return taxBill(for_receipt);
  }
})();
