import { go } from 'fxjs/es';
import { createCvImageP, makeDefaultCvImageAttrs } from '../../../../Maker/F/CvImage/fs.js';
import { NewMakerCvObjectUtilF } from '../../../CvObject/Util/F/Function/module/NewMakerCvObjectUtilF.js';

export const makeCenterImageByPxPer1Cm = async ({ image, fcanvas }) => {
  return go(makeDefaultCvImageAttrs(image), createCvImageP, (cv_image) => {
    G.mp.maker.nscreen(cv_image);
    const { width } = NewMakerCvObjectUtilF.getPxByDpiAndPxPer1cm(cv_image, fcanvas);
    cv_image.scaleToWidth(width);
    G.mp.maker.align_print_area_center(cv_image, fcanvas);
    return cv_image;
  });
};
