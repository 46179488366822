import { legacyHtml } from '../../../Util/S/Function/util.js';
import { changeCurrentTab } from './fs.js';

export const untitled_tab = {
  tab_name: 'untitled_tab',
  title: 'untitled',
  template: () => legacyHtml`
    <div class="option"></div>
    <div class="list_wrapper"></div>
  `,
  showed: function (don_tab) {
    changeCurrentTab(don_tab);
  },
};
