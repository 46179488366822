import { go } from 'fxjs/es';
import { $closest, $find, $height, $outerHeight, $qs, $setCss } from 'fxdom/es';

export function setIScroll(el) {
  if (!el) return;
  const marpplizer_main_height = go($qs('#marpplizer'), $outerHeight);

  // console.log($qs('#marpplizer .general_option_buttons').offsetHeight);
  const marpplizer_bottom_height = $closest('.home', el)
    ? go($qs('#marpplizer .general_option_buttons'), $outerHeight)
    : 0;
  const body_el = $find('.body')(el);
  const body_height = $height(body_el);
  $setCss({ height: marpplizer_main_height - marpplizer_bottom_height }, el);
  if (body_height < marpplizer_main_height - marpplizer_bottom_height) return el;
  if (el.myScroll) el.myScroll.refresh();
  else
    el.myScroll = new IScroll(el, {
      mouseWheel: true,
      scrollbars: true,
      fadeScrollbars: true,
    });
  return el;
  // const margin_top = parseInt($css('marginTop', el));
  // let bottom = go(
  //   $qs('#marpplizer .general_option_buttons'),
  //   (el) => $.height(el) + parseInt($css('bottom', el)),
  // );
  // // if ($qs('#maker_frame')?.dataset?.is_vector === 'true') {
  // //   $setCss({ height: $height($qs('#maker_frame')) - 120 - 32 - 16 }, el);
  // // } else {
  // // }
  // let agree_height = 0;
  // if (NewMakerCallConditionPopupF.is_mapping_confirm_popup()) {
  //   if ($closest('.home', el)) {
  //     agree_height = NewMakerConditionAgreeTextF.agreeTextCount() * 38 + 20;
  //     bottom = bottom + agree_height;
  //   } else {
  //     bottom = 0;
  //   }
  // }
  // const height = marpplizer_height - bottom - margin_top - 20;
  //
  // $setCss({ height }, el);
  // const buying_top = go(
  //   $qsa('#marpplizer .general_option_buttons'),
  //   map((buying) => $.offset(buying).top),
  //   (arr) => Math.max(...arr),
  // );
  // const body = go(el, $find('>.body'));
  // const body_bottom = $.offset(body).top + $.height(body);
  // if (buying_top - agree_height >= body_bottom) return el;
  // if (el.myScroll) el.myScroll.refresh();
  // else
  //   el.myScroll = new IScroll(el, {
  //     mouseWheel: true,
  //     scrollbars: true,
  //     fadeScrollbars: true,
  //   });
  // return el;
}

export const clickHandlerForReviewSummaryCount = () => {
  const review_el = $qs('.omp-review');
  if (review_el) {
    const el_position = review_el.getBoundingClientRect().top;
    window.scroll({
      top: window.scrollY + el_position - 50, // 상단 고정 header 고려
      behavior: 'smooth',
    });
  }
};
