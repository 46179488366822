import { go, html, mapL, reduce } from 'fxjs/es';
import { VectorEditorSettingBackgroundPCTmplS } from '../../../../../SettingBackground/PC/S/Tmpl/module/VectorEditorSettingBackgroundPCTmplS.js';
import { VectorEditorTopMenuPCTmplS } from '../../../../../TopMenu/PC/S/Tmpl/module/VectorEditorTopMenuPCTmplS.js';
import { VectorEditorTopMenuPCConstantS } from '../../../../../TopMenu/PC/S/Constant/module/VectorEditorTopMenuPCConstantS.js';

const mui_ref_path = '/modules/VectorEditor/Sticker/Grid/CreatorPC/S/Mui/tab.js';
/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  title: '', // tab button의 title
  selected: false, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  makeData(tab) {}, // template 함수 인자에 들어갈 값, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
  template(data) {
    return html`
      <div class="left_container">
        <div class="editor_container"></div>
        <div class="top_menu_container">
          ${VectorEditorTopMenuPCTmplS.makeTopMenusHtml({
            disabled_ids: go(
              Object.values(VectorEditorTopMenuPCConstantS.TOP_MENU),
              mapL(({ ID }) => ID),
              (ids) =>
                reduce(
                  (set, id) => {
                    set.add(id);
                    return set;
                  },
                  new Set(),
                  ids,
                ),
            ),
          })}
        </div>
      </div>
      <div class="right_container">
        <div class="right_wrapper">
          <div class="panel_container">
            <div class="panel_wrapper" data-panel="home" data-is_show="true" data-is_animation="false">
              <div class="inner_wrapper">
                ${VectorEditorSettingBackgroundPCTmplS.makeRightPanelHtml({
                  class_list: ['right_panel', 'right_panel_home'],
                  no_price: true,
                })}
              </div>
              <div class="button_container">
                <button type="button" class="cancel">
                  ${T('modules::VectorEditor::Sticker::FreeGrid::취소')}
                </button>
                <button type="button" class="done">
                  ${T('modules::VectorEditor::Sticker::FreeGrid::완료')}
                </button>
              </div>
            </div>
            <div class="panel_wrapper" data-panel="select" data-is_show="false" data-is_animation="false">
              <div class="right_panel right_panel_select">
                <div class="header">
                  <button type="button" class="back"></button>
                  <span class="title">${T('modules::VectorEditor::Sticker::FreeGrid::스티커')}</span>
                </div>
                <div class="wrapper">
                  <div class="description">
                    <svg
                      class="icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <g stroke="#000">
                          <g>
                            <g>
                              <path
                                d="M5.92 9.021l12.33.301c.407.01.773.181 1.037.452.264.27.427.64.427 1.048h0v2.362c0 .415-.168.79-.44 1.061-.27.27-.643.438-1.055.44h0l-12.289-.3-2.333-2.333 2.323-3.03z"
                                transform="translate(-1572 -507) translate(1460 71) translate(112 436) rotate(-45 11.375 11.875)"
                              />
                              <path
                                stroke-linecap="square"
                                d="M14.5 6.5L17.5 9.5M9 14L14 9"
                                transform="translate(-1572 -507) translate(1460 71) translate(112 436)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <p>${T('modules::VectorEditor::Sticker::FreeGrid::만든 스티커 수정이 필요하신가요?')}</p>
                  </div>
                  <button type="button" class="edit">
                    ${T('modules::VectorEditor::Sticker::FreeGrid::스티커 편집하기')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }, // 탭에 들어갈 html 을 그리는 함수
};
