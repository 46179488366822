import { entries, go, head, sortBy } from 'fxjs/es';

function xy1xy2Distance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(Math.abs(x2 - x1), 2) + Math.pow(Math.abs(y2 - y1), 2));
}

export const getNearCvObjAlignment = (arr_cv_objs, target_cv_obj) => {
  const cv_obj_center = target_cv_obj.getCenterPoint();
  return go(
    arr_cv_objs,
    sortBy((cv_obj_alignment) => {
      return go(
        cv_obj_alignment.absoluteCoords,
        entries,
        sortBy(([k, { x, y }]) => xy1xy2Distance(x, y, cv_obj_center.x, cv_obj_center.y)),
        head,
        ([k, { x, y }]) => xy1xy2Distance(x, y, cv_obj_center.x, cv_obj_center.y),
      );
    }),
    head,
  );
};
