/**
 * @param {string} str
 * @return {boolean} is normal string (not Infinity, NaN, some weird string)
 */
export const isNormalNumberString = (str) => {
  if (str === '') {
    return false; // It is not a normal number string
  }

  // Check if the string is a valid number and not NaN
  if (!isNaN(+str)) {
    // Check if the number is finite and not Infinity or -Infinity
    if (isFinite(+str)) {
      return true; // It is a normal number string
    }
  }
  return false; // It is not a normal number string
};

export const isNumber = (value) => {
  return typeof value === 'number' && isFinite(value);
};
