import { filter, map, go, flatMap, reject, some, find } from 'fxjs/es';

export const getBpOptionGroupsOfBpfId = (bpf_id, base_product) => {
  return go(
    base_product._.base_products_bp_option_groups,
    filter((base_products_bp_option_group) => base_products_bp_option_group.base_product_face_id == bpf_id),
    map((base_products_bp_option_group) => base_products_bp_option_group._.bp_option_group),
  );
};

export function getAllBpOptions() {
  return go(
    box.sel('maker->product_color->_->base_product->_->base_products_bp_option_groups'),
    reject((base_products_bp_option_group) => base_products_bp_option_group.base_product_face_id),
    flatMap((base_products_bp_option_group) => base_products_bp_option_group._.bp_option_group._.bp_options),
  );
}

export function getBpOptionGroup(selected_option_group_id) {
  return go(
    box.sel('maker->product_color->_->base_product->_->base_products_bp_option_groups'),
    find(
      (base_products_bp_option_group) =>
        base_products_bp_option_group.bp_option_group_id == selected_option_group_id,
    ),
    (base_products_bp_option_group) => base_products_bp_option_group?._?.bp_option_group,
  );
}
export const hasMakerMetaOptionGroup = (selected_option_group_id) => {
  return go(
    getBpOptionGroup(selected_option_group_id)._.bp_options,
    some((bp_option) => bp_option._?.maker_meta?.id),
  );
};

export const getDefaultBpOptionIdFromBpOption = (bp_option) => {
  if (!box.sel('maker->product_color->_->base_product->_->base_products_bp_option_groups')?.length) return;
  return go(
    box.sel('maker->product_color->_->base_product->_->base_products_bp_option_groups'),
    find(({ bp_option_group_id }) => bp_option_group_id == bp_option.bp_option_group_id),
    (bpbog) => bpbog?.default_bp_option_id,
  );
};
