import { add, go, pipe, sel, sumBy } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import * as tmpl from '../S/tmpl.js';

export const main = () => {
  go(
    $('#search #select_team'),
    $.on('change', (e) => {
      const { value: team_id } = e.target;
      go(
        box(),
        sel('users_by_team'),
        team_id === 'all'
          ? tmpl.worker_options
          : pipe(
              sel(team_id),
              sumBy(({ id, name }) => legacyHtml` <option value="${id}">${name}</option> `),
            ),
        add(tmpl.option_all()),
        (options) => $.html($('#search #select_user'), options),
      );
    }),
  );
};
