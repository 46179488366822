import {
  $closest,
  $data,
  $delegate,
  $el,
  $find,
  $next,
  $remove,
  $replaceWith,
  $setCss,
  $setHTML,
  $setScrollTop,
  $trigger,
} from 'fxdom/es';
import { go } from 'fxjs/es';
import { OMPProductListFilterModalF } from '../../FilterModal/F/Function/module/OMPProductListFilterModalF.js';
import { OMPProductListS } from '../../S/Function/module/OMPProductListS.js';
import { OMPProductListTmplS } from '../../S/Tmpl/module/OMPProductListTmplS.js';
import {
  axiosGetBps,
  checkedCateItem,
  delegateOpenCateSelector,
  makeQueryWithFilterModal,
  resetWithFilterModal,
} from './fn.js';
import { delegateBaseProductLike } from './like.js';
import { changeSort, finishActionBpList } from './reload.js';

const makeQueryMo = (product_list_tap_el) => {
  const { cate_list_id, cate_item_id } = $find(
    '.omp-product-list__checked-cate-list',
    product_list_tap_el,
  ).dataset;
  const sort = $find('.omp-product-list__checked-sort-name', product_list_tap_el).dataset.id;
  return { cate_list_id, cate_item_id, sort };
};

const reloadByFilter = ({ checked_filter_items, product_list_tap_el, query }) => {
  const params = makeQueryWithFilterModal({ checked_filter_items, product_list_tap_el, query });

  return go(axiosGetBps(params), (bps) => {
    $setHTML(OMPProductListTmplS.moListBody(bps), $find('.opm-product-list-bps', product_list_tap_el));
    finishActionBpList(params);
  });
};

export const delegateMo = (product_list_tap_el) => {
  const cate_lists_el = $find('.omp-product-cate-selector__cate-lists', product_list_tap_el);
  product_list_tap_el.tab_opt.bp_filters = $data(
    $find('.opm-product-list-checked-filter-items', product_list_tap_el),
  )?.bp_filters;
  return go(
    product_list_tap_el,
    delegateBaseProductLike,
    delegateOpenCateSelector(cate_lists_el),
    $delegate('input', '.omp-product-cate-selector__cate-item-radio', ({ currentTarget }) => {
      $setScrollTop(0, window);
      const already_check_cate$ = $find('[data-radio-checked="true"]', cate_lists_el);
      already_check_cate$.dataset.radioChecked = false;
      $next('[data-radio-checked]', currentTarget).dataset.radioChecked = true;
      $trigger('click', $find('.omp-product-list__checked-cate-list', product_list_tap_el));

      const { cate_list_id, cate_item_id } = currentTarget.dataset;
      const sort = $find('.omp-product-list__checked-sort-name', product_list_tap_el).dataset.id;
      const query = { cate_list_id, cate_item_id, sort };
      resetWithFilterModal(product_list_tap_el);

      go(axiosGetBps(query), (bps) => {
        const { checked_cate_list, checked_cate_item } = checkedCateItem(query);
        $replaceWith(
          $el(OMPProductListTmplS.moCateSelect({ checked_cate_list, checked_cate_item })),
          $find('.omp-product-list__cate-lists', product_list_tap_el),
        );
        $setHTML(OMPProductListTmplS.moListBody(bps), $find('.opm-product-list-bps', product_list_tap_el));
        finishActionBpList(query);
      });
    }),
    $delegate('click', '.omp-product-list__checked-filter-item-init', () => {
      $setScrollTop(0, window);
      resetWithFilterModal(product_list_tap_el);
      return reloadByFilter({
        checked_filter_items: [],
        product_list_tap_el,
        query: makeQueryMo(product_list_tap_el),
      });
    }),
    $delegate('click', '.omp-product-list__checked-filter-item', ({ currentTarget }) => {
      $setScrollTop(0, window);
      const { bp_filters } = product_list_tap_el.tab_opt;
      if (bp_filters) {
        $remove(currentTarget);

        const { fl, fi } = OMPProductListFilterModalF.findFilterItem(bp_filters, currentTarget.dataset);

        if (!fi) {
          return;
        }

        fl.count--;
        fi.checked = false;
        bp_filters.total_count--;

        const checked_filter_items = OMPProductListS.checkedFilterItems(bp_filters);
        return reloadByFilter({
          checked_filter_items,
          product_list_tap_el,
          query: makeQueryMo(product_list_tap_el),
        });
      }
    }),
    $delegate('click', '.omp-mo-product-header__filter-open-btn', async () => {
      return OMPProductListFilterModalF.openFrame({ product_list_tap_el, cate_lists_el }, (v) => {
        return go(reloadByFilter(v), () => {
          $setCss({ top: 0 }, $find('>.don_wrapper', $closest('.don_frame', product_list_tap_el)));
          product_list_tap_el.tab_opt.tab_top = 0;
        });
      });
    }),
    $delegate('input', '.omp-mo-product-header__sort-selector', ({ currentTarget }) => {
      changeSort($find('.omp-mo-product-header__sort-item:checked', currentTarget), () => {
        const { bp_filters } = product_list_tap_el.tab_opt;
        const checked_filter_items = bp_filters ? OMPProductListS.checkedFilterItems(bp_filters) : [];
        $setScrollTop(0, window);
        go(
          makeQueryWithFilterModal({
            checked_filter_items,
            product_list_tap_el,
            query: makeQueryMo(product_list_tap_el),
          }),
          finishActionBpList,
        );
      });
    }),
  );
};
