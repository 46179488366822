import { $attr, $hasClass, $qsa, $removeClass } from 'fxdom/es';
import { sel, filter, go, each } from 'fxjs/es';

let lazy_observer;
export const initLazyLoad = () => {
  const lazy_class = 'lazy-src';
  lazy_observer = new IntersectionObserver(
    (entries) => {
      go(
        entries,
        filter(sel('isIntersecting')),
        each((entry) => {
          if ($hasClass(lazy_class)) {
            const src = $attr('data-src', entry.target);
            const srcset = $attr('data-srcset', entry.target);
            const bg = $attr('data-background', entry.target);
            if (src) {
              entry.target.src = src;
            } else if (srcset) {
              entry.target.srcset = srcset;
            } else {
              entry.target.style.backgroundImage = bg ? `url('${bg}')` : '';
            }

            $removeClass(lazy_class, entry.target);
          }

          lazy_observer.unobserve(entry.target);
        }),
      );
    },
    {
      rootMargin: `${window.innerHeight}px 0px ${window.innerHeight}px 0px`,
    },
  );

  each((lazy_el) => {
    lazy_observer.observe(lazy_el);
  }, $qsa(`.${lazy_class}`));
};

export const refreshLazyLoad = () => {
  lazy_observer.disconnect();
  initLazyLoad();
};
