import { compact, find, go, html, sel, sortByDesc, strMap, takeL, zipWithIndexL } from 'fxjs/es';
import { UtilImageS } from '../../../Util/Image/S/Function/module/UtilImageS.js';
import { ProductDetailTabsTmplS } from './module/ProductDetailTabsTmplS.js';

export const productInfoDetail = ({ thumbnails, bp_id, tabs }, is_mobile) => {
  const compact_tabs = go(
    tabs,
    compact,
    sortByDesc((tab) => !tab.is_hidden),
  );
  const is_vertical = go(thumbnails, (thumbs) => {
    if (!thumbs || !thumbs.length) return false;
    const thumb = find((thumb) => !!thumb.width, thumbs);
    return thumb && parseInt(thumb.width) > parseInt(thumb.height);
  });
  return html`
    <div class="base_product product_info_detail" bp_id="${bp_id}">
      <div class="body">
        <div class="bp_info">
          <div class="bp_default_info" style="display: ${sel('length', thumbnails) ? 'block' : 'none'}">
            <div
              is_vertical="${is_vertical}"
              class="bp_thumb_swiper thumb_swiper"
              is_show="${!!sel('length', thumbnails)}"
            >
              <div class="thumbnails_area">
                <div class="swiper_thumbnails swiper-container">
                  <div class="swiper-wrapper">
                    ${strMap(
                      (thumb) => html`
                        <div
                          class="swiper-slide item swiper-lazy"
                          data-background="${thumb.width > thumb.height
                            ? UtilImageS.getResizedUrl({
                                url: thumb.url,
                                format: 'jpg',
                                quality: 90,
                                width: is_mobile ? 800 : 1600,
                              })
                            : UtilImageS.getResizedUrl({
                                url: thumb.url,
                                format: 'jpg',
                                quality: 90,
                                width: is_mobile ? 800 : 960,
                              })}"
                        >
                          <div class="swiper-lazy-preloader"></div>
                        </div>
                      `,
                      thumbnails,
                    )}
                  </div>
                  <div class="swiper-pagination"></div>
                  <div class="swiper-button-prev swiper-button-white"></div>
                  <div class="swiper-button-next swiper-button-white"></div>
                </div>
              </div>
              <div class="thumbnails_icon_area">
                <div class="bp_thumb_icon swiper_thumbnails_icon swiper-container">
                  <div class="swiper-wrapper">
                    ${go(
                      thumbnails,
                      zipWithIndexL,
                      takeL(8),
                      strMap(
                        ([idx, img]) =>
                          html`
                            <div
                              class="swiper-slide"
                              index="${idx}"
                              style="background-image: url(${G.to_150(img.url)})"
                            ></div>
                          `,
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          ${ProductDetailTabsTmplS.makeHtml(compact_tabs)}
        </div>
      </div>
    </div>
  `;
};
