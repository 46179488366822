export const SVG_URLS = [
  // 기본 도형
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_1-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_2-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_2-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_2-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_2-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_2-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_2-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_2_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_3-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_3-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_3-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_3-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_3-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_3-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_3-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_3_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_4-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_4-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_4-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_4-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_4-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_4-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_4-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_4_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_5-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_5-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_5-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_5-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_5-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_5-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_5-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_5_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_6-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_6-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_6-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_6-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_6-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_6-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_6-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_6_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_7-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_7-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_7-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_7-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_7-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_7-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_7_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_8-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_8-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_8-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_8-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_8-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_8_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_9-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_9-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_9-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/1. 기본도형_9_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_1-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_2-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_2_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_3-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_3-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_3-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_3-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_3-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_3-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_3-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_3_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_4-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_4-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_4-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_4-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_4-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_4-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_4-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_4_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_5-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_5-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_5-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_5-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_5-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_5-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_5_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_6-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_6-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_6-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_6-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/3. 화살표_6_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_1-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_10-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_10-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_10-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_10-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_10-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_10-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_10-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_10_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_11-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_11-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_11-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_11-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_11-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_11-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_11-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_11_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_2-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_2-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_2-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_2-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_2-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_2-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_2-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_2_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_3-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_3-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_3-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_3-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_3-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_3-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_3-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_3_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_4-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_4-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_4-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_4-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_4-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_4-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_4-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_4_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_5-01.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_5-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_5-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_5-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_6-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_6-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_6-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_6-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_6-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_6-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_6_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_7-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_7-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_7-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_7-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_7-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_7-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_7-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_7_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_8-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_8-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_8-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_8-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_8-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_8-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_8-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_8_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_9-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_9-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_9-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_9-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_9-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_9-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_9-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/4. 오가닉폼 도형_9_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_2-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_2-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_2-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_2-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_2-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_2-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_2-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_3-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_3-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_3-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_3-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_3-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_3-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/6. 별_3_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_1-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_2-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_2-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_2-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_2-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_2-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_2-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_2-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_2_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_3-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_3-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_3-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_3-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_3-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_3-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_3-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_3_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/7. 말풍선_4_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/8. 배너_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/8. 배너_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/8. 배너_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/8. 배너_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/8. 배너_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/8. 배너_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/8. 배너_1-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/8. 배너_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_1-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_2-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_2-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_2-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_2-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_2-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_2_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_3-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_3-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_3-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_3-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_3-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_3-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/기본 도형/svg/9. 하트_3_SVG스티커.svg',

  // 꽃 및 식물
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_2-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_2-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_2-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_2-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_2-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_2-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_2-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_2_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_3-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_3-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_3-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_3-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/1. 꽃_3_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_1-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_2-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_2-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_2-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_2-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_2-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_2-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_2-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_2_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_3-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_3-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_3-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_3-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_3-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_3-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_3-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_3_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_4-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_4-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_4-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_4-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_4-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_4-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_4-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_4_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_5-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_5-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_5-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_5-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_5-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_5-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/2. 나뭇잎_5_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_1-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_2-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_2-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_2-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_2-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_2-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_2-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_2-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_2_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_3-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_3-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_3-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_3-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_3-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_3-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_3-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/꽃 및 식물/svg/3. 나무_3_SVG스티커.svg',

  // 동물
  '//s3.marpple.co/vector-editor-free-shape/동물/svg/1. 토끼-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/동물/svg/1. 토끼-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/동물/svg/1. 토끼-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/동물/svg/1. 토끼-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/동물/svg/1. 토끼_SVG스티커.svg',

  // 문자 및 숫자
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_1-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_2-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_2-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_2-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_2-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_2-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_2-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_2_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/문자 및 숫자/svg/4. 특수문자_3-02.svg',

  // 신체 부위
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_1-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_1-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_1-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_1-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_1-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_1-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_1-08.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_1_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_2-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_2-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_2-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_2-05.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_2-06.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_2-07.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_2_SVG스티커.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_3-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/신체 부위/svg/1. 손_3_SVG스티커.svg',

  // 장식
  '//s3.marpple.co/vector-editor-free-shape/장식/svg/1. 토끼머리띠-02.svg',
  '//s3.marpple.co/vector-editor-free-shape/장식/svg/1. 토끼머리띠-03.svg',
  '//s3.marpple.co/vector-editor-free-shape/장식/svg/1. 토끼머리띠-04.svg',
  '//s3.marpple.co/vector-editor-free-shape/장식/svg/1. 토끼머리띠_SVG스티커.svg',
];
