import anime from 'animejs';
import { $attr, $closest, $css, $find, $findAll, $qs, $setAttr, $show } from 'fxdom/es';
import { each, filter, flat, go, indexBy, map, omit, pick, pipe, sel, some, tap } from 'fxjs/es';
import { isPreviewStyle } from '../../../../../modules/Maker/F/categorize.js';
import { checkCurrentFcanvasDesignSize } from '../../../../../modules/Maker/F/check_image.js';
import { removeCvBlueLineInMaker } from '../../../../../modules/Maker/F/cv_blue_line.js';
import { createCvImageP, getCvImage } from '../../../../../modules/Maker/F/CvImage/fs.js';
import { createCvPattern } from '../../../../../modules/Maker/F/CvPattern/fs.js';
import { addCvPreview, removeCvPreview } from '../../../../../modules/Maker/F/CvPreview/cv_object.js';
import {
  getCurrentBpfInMaker,
  getProductColorInMaker,
  getRealFcanvass,
  setProductFaces2Value,
} from '../../../../../modules/Maker/F/getSth.js';
import { check_only_one_image_color } from '../../../../../modules/Maker/F/image.js';
import { renderSelectInLayerInMaker } from '../../../../../modules/Maker/F/Layer/fs.js';
import {
  marpplizerProxy,
  mpMakerLoaderEnd,
  mpMakerLoaderStart,
} from '../../../../../modules/Maker/F/Marpplizer/marpplizer.js';
import { initMpMaker, removeMakerPageClass } from '../../../../../modules/Maker/F/mp_maker.js';
import { makeObjsOpacity1 } from '../../../../../modules/Maker/F/opacity.js';
import {
  checkIsOverflowInCurrentFace,
  checkIsOverflowOfObjInPrintArea,
  isMakerDone,
} from '../../../../../modules/Maker/F/overflow.js';
import {
  openProductEditorFrame,
  openProductEditorFrameNotBp,
} from '../../../../../modules/Maker/F/Product/frame.js';
import {
  getSpecialMessageType,
  hideSpecialMessage,
  showSpecialMessage,
} from '../../../../../modules/Maker/F/special_message.js';
import {
  changeCvTextImageToCvTextForTextingA,
  createCvTextImageA,
  createCvTextImageFromCvText,
  is_load_font_important,
  makeDataCmAndChangeCvTextResetBoxDataA,
  setCvTextImageNewScale,
  whenBlurFiredInText,
} from '../../../../../modules/Maker/F/text.js';
import {
  getImageUrlByZoom,
  isDFOrWorker,
  onbeforeunload_event_off,
  orderFaceBackgroundInMaker,
  readyPfColllaboTypeBpsId,
} from '../../../../../modules/Maker/F/util.js';
import { MarstagramEditorF } from '../../../../../modules/MarstagramEditor/F/Function/module/MarstagramEditorF.js';
import { NewMakerCallConditionPopupF } from '../../../../../modules/NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { NewMakerConditionTooltipF } from '../../../../../modules/NewMaker/ConditionTooltip/F/Function/module/NewMakerConditionTooltipF.js';
import { errorHandlingEventWrapper } from '../../../../../modules/NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { OMPCoreUtilF } from '../../../../../modules/OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { resetProductColorPrice } from '../../../../../modules/ProductColorPrice/S/fs.js';
import { openMpBpdTipFrame } from './mp.maker.tip.js';
import { NewMakerIconMenuF } from '../../../../../modules/NewMaker/IconMenu/F/Function/module/NewMakerIconMenuF.js';
import { NewMakerCheckerF } from '../../../../../modules/NewMaker/Checker/F/Function/module/NewMakerCheckerF.js';
import { getCurrentFcanvas } from '../../../../../modules/Maker/F/Fcanvas/cv_object.js';
import { SVGEditorTempSvgProductsF } from '../../../../../modules/SVGEditor/TempSvgProducts/F/Function/module/SVGEditorTempSvgProductsF.js';
import { SVGEditorProductDetailF } from '../../../../../modules/SVGEditor/ProductDetail/F/Function/module/SVGEditorProductDetailF.js';
import { addCompositeTemplateSelectionPage } from '../../../../../modules/Composite/SelectionPage/F/page.js';
import {
  getThumbnailAssocCompositeTemplates,
  setAndUploadThumbnailOgImage,
} from '../../../../../modules/Composite/Thumbnail/F/fs.js';
import { createSVGMaterialForPC } from '../../../../../modules/SVGEditor/ProductDetail/F/Function/fs.js';
import axios from 'axios';
import { setProductOnlyDesignFaceUrl } from '../../../../../modules/Maker/F/draw_product_faces.js';
import { makeMockupSize } from '../../../../../modules/Composite/Core/F/mockup_fns.js';
import { NewMakerMaskingTapeF } from '../../../../../modules/NewMaker/MaskingTape/F/Function/module/NewMakerMaskingTapeF.js';
import { BpOptionConstantS } from '../../../../../modules/BpOption/S/Constant/module/BpOptionConstantS.js';
import { NewMakerCvObjectCvBackgroundF } from '../../../../../modules/NewMaker/CvObject/CvBackground/F/Function/module/NewMakerCvObjectCvBackgroundF.js';
import { BpOptionF } from '../../../../../modules/BpOption/F/Function/module/BpOptionF.js';
import { ProductDetailTabsF } from '../../../../../modules/ProductDetailTabs/F/Function/module/ProductDetailTabsF.js';
import { ProductDetailTabsTmplS } from '../../../../../modules/ProductDetailTabs/S/Tmpl/module/ProductDetailTabsTmplS.js';
import { ProductDetailTabsConstantS } from '../../../../../modules/ProductDetailTabs/S/Constant/module/ProductDetailTabsConstantS.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';
import { OMPMakerEventMakerF } from '../../../../../modules/OMP/MakerEvent/Maker/F/Function/module/OMPMakerEventMakerF.js';
import { NewMakerPropertyBpfF } from '../../../../../modules/NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';
import { BpOptionUploadTypeS } from '../../../../../modules/BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../../../modules/NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { NewMakerPropertyBaseProductF } from '../../../../../modules/NewMaker/Property/BaseProduct/F/Function/module/NewMakerPropertyBaseProductF.js';
import { NewMakerPropertyBpfS } from '../../../../../modules/NewMaker/Property/Bpf/S/Function/module/NewMakerPropertyBpfS.js';
import { MakerDebugConstantS } from '../../../../../modules/MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import { PdfS } from '../../../../../modules/Pdf/S/Function/module/PdfS.js';
import {
  mpMakerSelectFaceActiveTippy,
  renderVisibleWhenCleared,
  renderVisibleWhenSelected,
} from '../../../../../modules/Maker/F/select_faces.js';
import { MakerDebugF } from '../../../../../modules/MakerDebug/F/Function/module/MakerDebugF.js';
import { resetUserCounts } from '../../../../../modules/MPay/Cart/F/fs.js';
import { NewMakerPropertyBaseProductS } from '../../../../../modules/NewMaker/Property/BaseProduct/S/Function/module/NewMakerPropertyBaseProductS.js';

!(function () {
  function canvas_event(canvas) {
    let last_time = new Date().getTime();
    let mouse_down_target;
    let transform_kind = '';
    canvas.on({
      'selection:cleared': errorHandlingEventWrapper(
        cleared,
        MakerDebugConstantS.Category.FabricEvent,
        'cleared',
      ),
      'object:selected': errorHandlingEventWrapper(
        _p.pipe(
          _tap((e) => G.mp.maker.reset_corner_size2(e?.target)),
          tap((e) => {
            const target = e?.target;
            if (target) {
              target.setCoords();
            }
          }),
          selected,
        ),
        MakerDebugConstantS.Category.FabricEvent,
        'selected',
      ),
      'object:rotating': errorHandlingEventWrapper(
        function (e) {
          transform_kind = 'rotate';
          rotating(e);
          NewMakerMaskingTapeF.rotateCloneHorizontalCvObj(e.target);
        },
        MakerDebugConstantS.Category.FabricEvent,
        'rotating',
      ),
      'object:scaling': errorHandlingEventWrapper(
        function (e) {
          transform_kind = 'scaling';
          G.mp.maker.reset_corner_size2(e.target);
          G.mp.maker.scaling(e.target);
          NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(e.target);
        },
        MakerDebugConstantS.Category.FabricEvent,
        'scaling',
      ),
      'object:moving': errorHandlingEventWrapper(
        function (e) {
          transform_kind = 'moving';
          if (G.mp.maker.center_mode.active) G.mp.maker.center_mode.auto_center(e.target);
          if ($1('#dream_factory')) G.mp.maker.show_rotation_info(e.target);
          NewMakerMaskingTapeF.moveScaleCloneHorizontalCvObj(e.target);
        },
        MakerDebugConstantS.Category.FabricEvent,
        'moving',
      ),
      'object:modified': errorHandlingEventWrapper(
        async function (e) {
          const cv_target = e.target;
          if (!cv_target) return;
          if (cv_target) setCvTextImageNewScale(cv_target);
          if ((e._transform_kind || transform_kind) === 'scaling') {
            if (cv_target && cv_target.canvas?._print_ratio) {
              await NewMakerCheckerF.alertImageQuality({
                cv_obj: cv_target,
                ratio: cv_target.canvas._print_ratio,
              });
            }
            if (cv_target._data.cv_type === 'cv_pattern') {
              const new_cv_pattern = await createCvPattern(
                G.mp.maker.to_attrs(cv_target),
                undefined,
                undefined,
                1,
                true,
              );
              if (new_cv_pattern._element && new_cv_pattern._originalElement) {
                cv_target._element = new_cv_pattern._element;
                cv_target._originalElement = new_cv_pattern._originalElement;
              }
            }
          }
          checkIsOverflowOfObjInPrintArea(cv_target);
          await G.mp.maker.modified(cv_target);
          NewMakerCallConditionPopupF.setAndRender(cv_target);
          transform_kind = '';
        },
        MakerDebugConstantS.Category.FabricEvent,
        'modified',
      ),
      'object:removed': errorHandlingEventWrapper(
        async function (e) {
          if (e?.target?._data?.is_not_design) return;
          if (e?.target?._data?.cv_type === 'cv_text') return;
          if (G.mp.maker.removed_pass) return;
          NewMakerMaskingTapeF.removeCloneHorizontalCvObj(e?.target);
          if (e?.target._objects && !e?.target?._data?.cv_type) {
            go(
              e?.target?._objects,
              each((cv_obj) => {
                NewMakerCallConditionPopupF.deleteAndRender(cv_obj?.cid);
              }),
            );
          } else {
            NewMakerCallConditionPopupF.deleteAndRender(e?.target?.cid);
          }
        },
        MakerDebugConstantS.Category.FabricEvent,
        'removed',
      ),
      'object:added': errorHandlingEventWrapper(
        async function (e) {
          if (!e.target) return;
          const fcanvas = sel('target.canvas', e);
          if (!fcanvas._is_bulk_added) {
            orderFaceBackgroundInMaker(fcanvas);
          }
          reset_data_cm(e.target);
          if (e?.target?._data?.cv_type === 'cv_text') return;
          if (e.target._data.is_not_design || e.target._data.clone_parent_cid) return;
          if (G.mp.maker.added_pass) return;
          if (fcanvas.fcanvas_data?.maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR) {
            NewMakerMaskingTapeF.insertCloneHorizontalCvObj(e.target);
          }
          G.mp.maker.reset_corner_size2(e.target);
          // if (!e.target?._data?.is_not_design) {
          //   await NewMakerCallConditionPopupF.setAndRender(e.target);
          // }
        },
        MakerDebugConstantS.Category.FabricEvent,
        'added',
      ),
      'mouse:up': errorHandlingEventWrapper(
        function (e) {
          G.mp.maker.center_mode.hide();
        },
        MakerDebugConstantS.Category.FabricEvent,
        'up',
      ),
      'mouse:down': errorHandlingEventWrapper(
        function (e) {
          const new_target = e.target;
          const new_time = new Date().getTime();
          if (
            !UtilF.isLegacyMobile() &&
            new_target &&
            new_target._data.cv_type === 'cv_text_image' &&
            new_target === mouse_down_target &&
            new_time - last_time < 300
          ) {
            changeCvTextImageToCvTextForTextingA(new_target);
          }
          mouse_down_target = new_target;
          last_time = new_time;
        },
        MakerDebugConstantS.Category.FabricEvent,
        'down',
      ),
      'text:editing:entered': errorHandlingEventWrapper(
        async function (e) {
          await marpplizerProxy();
        },
        MakerDebugConstantS.Category.FabricEvent,
        'entered',
      ),
      'text:editing:exited': errorHandlingEventWrapper(
        async function (e) {
          await whenBlurFiredInText(e.target);
        },
        MakerDebugConstantS.Category.FabricEvent,
        'exited',
      ),
    });
  }

  G.mp.maker.canvas_event = canvas_event;

  G.mp.maker.image_upload_desc_event = function () {
    /* pc image_upload_desc */
    _go(
      $1('body'),
      $.on('mouseenter', '.mp_product_detail .image_upload_desc', function (e) {
        $.attr(e.$currentTarget, 'is_enter', 'true');
      }),
      $.on('mouseleave', '.mp_product_detail .image_upload_desc', function (e) {
        $.attr(e.$currentTarget, 'is_enter', 'false');
        setTimeout(function () {
          if ($.attr(e.$currentTarget, 'is_enter') == true) return;
          $.hide(e.$currentTarget);
        }, 1000);
      }),
    );
  };

  G.mp.maker.loc_opt_desc_event = function () {
    _go(
      $1('body'),
      $.on('mouseenter', '.mp_product_detail .loc_opt_desc', function (e) {
        $.attr(e.$currentTarget, 'is_enter', 'true');
      }),
      $.on('mouseleave', '.mp_product_detail .loc_opt_desc', function (e) {
        $.attr(e.$currentTarget, 'is_enter', 'false');
        setTimeout(function () {
          if ($.attr(e.$currentTarget, 'is_enter') == true) return;
          $.hide(e.$currentTarget);
        }, 1000);
      }),
    );
  };

  G.mp.maker.create_el_maker = create_el_maker;
  function create_el_maker(el_maker_body) {
    /* pc decoration_menu */

    _go(
      el_maker_body,
      $.on('mouseenter', '.how_to_custom[is_pc="true"]', function (e) {
        $.add_class(e.$currentTarget, 'tip_open');
      }),
      $.on('mouseleave', '.how_to_custom[is_pc="true"]', function (e) {
        if (UtilF.isLegacyMobile()) return;
        $.remove_class(e.$currentTarget, 'tip_open');
      }),
      $.on('click', '.how_to_custom[is_pc="true"]', function (e) {
        openMpBpdTipFrame();
      }),
      $.on('click', '.how_to_custom[is_pc="false"] .btn_close', function (e) {
        $.remove_class($.closest(e.$currentTarget, '.how_to_custom'), 'tip_open');
      }),
      $.on('click', '.how_to_custom[is_pc="false"] .icon', function (e) {
        const how_to_custom = $.closest(e.$currentTarget, '.how_to_custom');
        if (UtilF.isLegacyMobile() && $.has_class(how_to_custom, 'tip_open')) return;
        $.add_class(how_to_custom, 'tip_open');
      }),
      $.on(
        'click',
        '.how_to_custom.tip_open[is_pc="false"] .icon, .how_to_custom.tip_open[is_pc="false"] .txt2, .how_to_custom.tip_open[is_pc="false"] .txt3',
        function (e) {
          openMpBpdTipFrame();
          $.remove_class($.closest(e.$currentTarget, '.how_to_custom'), 'tip_open');
        },
      ),
      NewMakerConditionTooltipF.clickEvent(),
    );

    /* editing */
    _go(
      el_maker_body,
      MarstagramEditorF.imageEditorOpenBtnsEvent('.btm_menu.image_editor'),
      $.on('click', '.canvas_container', function (e) {
        // if($.has_class($1('.mp_maker'), 'prev_product_matcher')) return;
        ($.is(e.target, '.canvass') || e.target == e.$currentTarget) && G.mp.maker.unselect_all();
      }),
    );

    /* .product_menu */
    _go(
      el_maker_body,
      $.on('click', '.super_menu .product_icon', function () {
        // G.mp.maker.zoom(false);
        G.mp.maker.zoom.maker_modeA(
          {
            scale: 1,
            transformOrigin: `${$.width($1('.canvas_container.selected .canvas_zoom_wrapper')) / 2}px ${
              $.height($1('.canvas_container.selected .canvas_zoom_wrapper')) / 2
            }px 0`,
          },
          212,
        );
        if ($qs('html.custom-detail-style-a')) {
          openProductEditorFrameNotBp();
        } else {
          openProductEditorFrame();
        }
      }),
      $.on('click', '.super_menu .zoom_in_1', function () {
        G.mp.maker.zoom.maker_modeA({
          transformOrigin: `${$.width($1('.canvas_container.selected .canvas_zoom_wrapper')) / 2}px ${
            $.height($1('.canvas_container.selected .canvas_zoom_wrapper')) / 2
          }px 0`,
        });
      }),
    );

    /* cv_object_editor */
    // $.off($('#body'), 'click.maker');
    // $.off($('#body'), 'input.maker');

    return el_maker_body;
  }
  G.mp.maker.start1 = function () {
    // box.set('done_tutorial', true);

    go($1('.omp-header__menus'), (swiper_el) => {
      if (!swiper_el) return;
      const buttons = $findAll('.omp-atom__header-menu-button', swiper_el);
      const header_menu = new Swiper('.omp-header__menu-buttons', {
        slidesPerView: 'auto',
        grabCursor: false,
        cssMode: true,
        freeMode: true,
        touchRatio: 0,
        initialSlide: 0,
        navigation: {
          nextEl: '.omp-header__menu-buttons-next',
          prevEl: '.omp-header__menu-buttons-prev',
        },
        on: {
          init: function () {
            anime({
              targets: $find('.omp-header__menu-buttons-next', swiper_el),
              opacity: 1,
              duration: 400,
              easing: 'linear',
            });
            anime({
              targets: $find('.omp-header__menu-buttons-prev', swiper_el),
              opacity: 1,
              duration: 400,
              easing: 'linear',
            });
          },
        },
      });
      header_menu.on('slidePrevTransitionStart', () => {
        header_menu.slideTo(0);
      });
      header_menu.on('slideNextTransitionStart', () => {
        header_menu.slideTo(buttons.length);
      });
    });
    _p.go(initMpMaker($1('.don_frame[frame_index="0"] .mp_product_detail >.body')), async function () {
      if (box.sel('maker->has_no_that_size')) $.alert(T('maker::This size is temporarily out of stock.'));
      if (!UtilF.isLegacyMobile()) {
        mpMakerSelectFaceActiveTippy();
        if (window.localStorage.getItem('done_tutorial') !== 'true') {
          if (box.sel('done_tutorial')) window.localStorage.setItem('done_tutorial', 'true');
          else G.mp.maker.tip_func_pc_start();
        }
      } else if (UtilF.isLegacyMobile() && box.sel('shared_product')) {
        if (window.localStorage.getItem('done_tutorial') !== 'true') {
          if (box.sel('done_tutorial')) window.localStorage.setItem('done_tutorial', 'true');
          else G.mp.maker.tip_func_shared_mo_start();
        }
      }
      const maker_frame_el = $qs('#maker_frame');
      if (maker_frame_el?.dataset?.is_vector === 'true' && !box.sel('shared_product')) {
        // const shared_product = box.sel('shared_product');
        // const user_id = shared_product ? shared_product.user_id : box.sel('is_user->id');

        SVGEditorTempSvgProductsF.applyTempSvgProduct({
          tab_el: $closest('.don_tab')(maker_frame_el),
          user_id: box.sel('is_user->id'),
          product: box().maker.product_color,
        });
      }
      const bp_option_groups_el = $qs('.bp_option_groups');
      const params = new URLSearchParams(location.search);
      if (!params.get('pc_id') && bp_option_groups_el) {
        const isNotNormal = BpOptionF.isVirtualBpOptionNotNormal(bp_option_groups_el);
        if (isNotNormal) {
          go($.alert(T('maker_error_alert::정보가 변경되어 새로고침 합니다.')), () =>
            window.location.reload(),
          );
        }
      }
      if (params.get('bps_id')) {
        params.delete('bps_id');
        window.history.replaceState(
          window.history.state,
          '',
          `${location.pathname}?${params.toString()}` || '',
        );
      }

      const urlParams = new URLSearchParams(window.location.search);
      const isRedirectFromLogin = urlParams.get('is_redirect_from_login') === 'true';

      if (isRedirectFromLogin) {
        resetUserCounts();
        new Promise(async (resolve) => {
          const url = new URL(window.location.href);

          const res = await OMPCoreUtilF.confirm({
            title: TT('auth::cart::confirm::title'),
            content: TT('auth::cart::confirm::content'),
            ok: TT('auth::cart::confirm::ok'),
            cancel: TT('auth::cart::confirm::cancel'),
            min_width: 300,
          });

          url.searchParams.delete('is_redirect_from_login');
          const newUrl = url.pathname + '?' + url.searchParams.toString();
          window.history.replaceState({}, '', newUrl);

          if (res) {
            window.location.href = `/${T.lang}/cart`;
            $.don_loader_start();
            setTimeout(() => {
              $.don_loader_end();
            }, 5000);
          }
          resolve();
        });
      }
      const pc_icon_menu = $qs('.decoration_menu_for_pc');
      const image_icon = $qs('.decoration_menu_for_pc .add_img');
      if (pc_icon_menu && image_icon && !UtilF.isLegacyMobile()) {
        const product = getProductColorInMaker();
        const maker_material_info =
          NewMakerPropertyBaseProductF.bpOptionMakerMaterial.getMakerMaterialInfo(product);
        if (
          maker_material_info &&
          $css('display', pc_icon_menu) !== 'none' &&
          $css('display', image_icon) !== 'none'
        ) {
          const image_upload_desc_el = $qs('.image_upload_desc');
          if (image_upload_desc_el) {
            $.css(image_upload_desc_el, {
              top: $.position(pc_icon_menu).top + $.position(image_icon).top - 10,
              left: $.position(pc_icon_menu).left - $.innerWidth(image_upload_desc_el) - 20,
            });
            $setAttr({ is_enter: true }, image_upload_desc_el);
            $show(image_upload_desc_el);
          }
        }
      }
      // bpOptionMakerMaterial.migration(box.sel('maker->product_color'));
    });
  };

  G.mp.maker.reset_box_data = function () {
    const product_color = getProductColorInMaker();
    if (box.sel('maker->event')) {
      OMPMakerEventMakerF.shinhanPriceMaker(box.sel('maker->event'), {
        fcanvass: getRealFcanvass(),
        base_product_id: box.sel('maker->product_color->base_product_id'),
      });
      if (
        go(
          product_color.product_faces2?.value,
          some((pf) => {
            return pf.designs?.length;
          }),
        )
      ) {
        product_color.collabo_type = 'creator';
      } else {
        product_color.collabo_type = '';
      }
    }
    setProductFaces2Value();
    if (BpOptionF.biz.get.currentBp.hasDosuRelatedOptions()) {
      BpOptionF.handleSelectedOptionGroup.update.boxData();
      if (
        product_color._.base_product?.maker_features?.[
          NewMakerPropertyBaseProductConstantS.ALL_FACES_SINGLE_COLOR
        ]
      ) {
        NewMakerPropertyBaseProductF.allFacesSingleColor.setAllFacesSingleColor(product_color.product_faces2);
      }
    }
    /* 주문전 만들기툴페이지 박스 데이터*/
    resetProductColorPrice(product_color);
    if (NewMakerPropertyBaseProductS.pdfFilePossible.isIt(product_color._.base_product)) {
      const pdf_printing_file = BpOptionUploadTypeS.UPLOAD_TYPE.option.direct.getPrintingFile(product_color);
      if (pdf_printing_file) {
        if (PdfS.is_pdf_uploaded_by_customer({ pdf_printing_file })) {
          NewMakerPropertyBaseProductF.pdfFilePossible.setProductFaceThumbnail(pdf_printing_file);
        }
      }
    }
  };

  G.mp.maker.up_c_setting = function () {
    if (!box.sel('maker->up_c')) box.set('maker->up_c', {});
    const up_c = box.sel('maker->up_c');
    const product_color = box.sel('maker->product_color');
    const changed_bp = up_c.base_product_id != product_color.base_product_id;
    up_c.base_product_id = product_color.base_product_id;
    up_c.base_product_size_id = product_color.base_product_size_id;
    up_c.base_product_color_id = product_color.base_product_color_id;
    if (!up_c._) up_c._ = {};
    const sold_out_sizes = _go(
      product_color._.base_product._.base_product_colors,
      _p.find_where({ id: product_color.base_product_color_id }),
      _p.val('_.sold_out_sizes'),
      _p.index_by('id'),
    );

    const discontinued_sizes = go(
      product_color._.base_product._.base_product_colors,
      _p.find_where({ id: product_color.base_product_color_id }),
      _p.val('_.discontinued_sizes'),
      _p.index_by('id'),
    );

    const previousQuantities = up_c._.up_c_ss
      ? go(
          up_c._.up_c_ss,
          indexBy(({ base_product_size_id }) => base_product_size_id),
        )
      : {};

    up_c._.up_c_ss = go(
      product_color.price_info.sizes_prices,
      _p.reject(({ id }) => discontinued_sizes[id]),
      _p.map(function (sizes_price) {
        return {
          base_product_size_id: sizes_price.id,
          bp_price: sizes_price.product_price,
          bp_price_en: sizes_price.product_price_en,
          bp_price_jp: sizes_price.product_price_jp,
          print_price: sizes_price.print_price,
          print_price_en: sizes_price.print_price_en,
          print_price_jp: sizes_price.print_price_jp,
          price: sizes_price.price,
          price_en: sizes_price.price_en,
          price_jp: sizes_price.price_jp,
          discounted_price: sizes_price.price,
          discounted_price_en: sizes_price.price_en,
          discounted_price_jp: sizes_price.price_jp,
          profit: sizes_price.profit,
          profit_en: sizes_price.profit_en,
          profit_jp: sizes_price.profit_jp,
          quantity: up_c.base_product_size_id == sizes_price.id ? 1 : 0,
          _is_not_stock: !!sold_out_sizes[sizes_price.id],
        };
      }),
    );

    if (!changed_bp) {
      _p.each(up_c._.up_c_ss, function (up_c_s) {
        const sizes_price = product_color.price_info.sizes_prices[up_c_s.base_product_size_id];
        _p.extend(up_c_s, {
          quantity: previousQuantities[up_c_s.base_product_size_id]?.quantity ?? up_c_s.quantity,
        });
      });
      const total_quantity = _p.sum(up_c._.up_c_ss, function (up_c_s) {
        return up_c_s.quantity;
      });
      if (total_quantity == 1) {
        /*???????*/
        return _p.each(up_c._.up_c_ss, function (up_c_s) {
          if (up_c_s.base_product_size_id == up_c.base_product_size_id) {
            if (up_c_s.quantity == 0) up_c_s.quantity++;
          } else {
            up_c_s.quantity = 0;
          }
        });
      } else {
        const up_c_s = _p.find(up_c._.up_c_ss, function (up_c_s) {
          return up_c_s.base_product_size_id == up_c.base_product_size_id;
        });
        if (up_c_s && up_c_s.quantity == 0) up_c_s.quantity++;
      }
    }
  };

  function extend_state_bp(bp_id) {
    $.frame.extend_state(
      void 0,
      null,
      G.update_query_str(location.href, 'bp_id', bp_id).replace(/pc_id=\d{1,}/gi, ''),
      'replace',
    );
  }
  G.mp.maker.extend_state_bp = extend_state_bp;

  /*
   * 1:1 -> 자동
   *
   * */

  function from_cv_attrs_for_maker(cv_attrs, zoom, is_draw) {
    if (cv_attrs == null) return;
    zoom = zoom || 1;
    cv_attrs = _defaults({ _data: _clone(cv_attrs._data) }, cv_attrs);
    cv_attrs.top = cv_attrs.top * zoom;
    cv_attrs.left = cv_attrs.left * zoom;
    cv_attrs.scaleX = cv_attrs.scaleX * zoom;
    cv_attrs.scaleY = cv_attrs.scaleY * zoom;
    return _from_cv_attrs_for_maker(cv_attrs, zoom, is_draw);
  }

  function from_cv_attrs_for_maker_for_group(cv_attrs, zoom, is_draw, group_zoom) {
    if (cv_attrs == null) return;
    zoom = zoom || 1;
    cv_attrs = _defaults({ _data: _clone(cv_attrs._data) }, cv_attrs);
    return _from_cv_attrs_for_maker(cv_attrs, zoom, is_draw, group_zoom);
  }

  function _from_cv_attrs_for_maker(cv_attrs, zoom, is_draw, group_zoom) {
    const cv_type = cv_attrs._data.cv_type;
    if (
      ['cv_bpcf', 'cv_mask1', 'cv_mask2', 'cv_shading', NewMakerPropertyBpfF.bpOptionLayers.cv_type].includes(
        cv_type,
      )
    )
      return create_cv_type(cv_attrs, cv_type, zoom);
    else if (cv_type === 'cv_preview') return create_cv_preview(cv_attrs, zoom);
    else {
      if (cv_type === 'cv_print_area' || cv_type === 'cv_safety_area') {
        return new fabric.Rect(cv_attrs);
      }
      if (cv_type === 'cv_background') {
        return NewMakerCvObjectCvBackgroundF.create(cv_attrs);
      }
      if (cv_type === 'cv_text_image') return createCvTextImageA(cv_attrs, zoom, is_draw);
      if (cv_type === 'cv_text') return createCvTextImageFromCvText(cv_attrs.text, cv_attrs);
      if (cv_type === 'cv_old_text') return G.mp.maker.create_cv_old_text(cv_attrs, zoom);
      if (cv_type === 'cv_image') return createCvImageP(cv_attrs, zoom);
      if (cv_type === 'cv_pattern') return createCvPattern(cv_attrs, undefined, undefined, zoom, true);
      if (cv_type === 'cv_text_image_pattern')
        return createCvPattern(cv_attrs, undefined, undefined, zoom, true, group_zoom);
      if (cv_type === 'cv_group') return G.mp.maker.create_cv_group(cv_attrs, zoom, is_draw);
    }
  }
  G.mp.maker._from_cv_attrs_for_maker = _from_cv_attrs_for_maker;
  G.mp.maker.from_cv_attrs_for_maker = from_cv_attrs_for_maker;

  function create_cv_type(data, type, zoom) {
    return new Promise(function (resolve) {
      // TODO 크기를 작은 이미지를 써서 자연스럽게 만들기, 아니면 G.mp.maker.resize_url 활용하기
      const url = getImageUrlByZoom(data.src, zoom);
      fabric.Image.fromURL(
        G.mp.maker.url(url, zoom),
        function (cv_image) {
          cv_image.set({ _data: data._data || { type: type } });
          resolve(cv_image);
        },
        data,
      );
    });
  }
  async function create_cv_preview(cv_attrs, zoom) {
    const url = getImageUrlByZoom(cv_attrs.src, zoom);
    const shade_material = cv_attrs._data.shade_material;
    const src = shade_material
      ? await NewMakerPropertyBpfF.common.shadeMaterial.renderCvPreview(
          shade_material,
          go(undefined, () => {
            const bpcColorCode2Render_shade_material =
              NewMakerPropertyBpfF.bpcColorCode2Render.getMyShadeMaterial(shade_material);

            if (bpcColorCode2Render_shade_material) {
              return NewMakerPropertyBpfS.bpcColorCode2Render.getShadeCanvas;
            }
            const foilEffects_shade_material =
              NewMakerPropertyBpfF.foilEffects.getMyShadeMaterial(shade_material);

            if (foilEffects_shade_material) {
              return NewMakerPropertyBpfF.foilEffects.makeShadeResultCanvas;
            }
          }),
          zoom,
        )
      : G.mp.maker.url(url, zoom);
    return new Promise(function (resolve) {
      // TODO 크기를 작은 이미지를 써서 자연스럽게 만들기, 아니면 G.mp.maker.resize_url 활용하기
      fabric.Image.fromURL(
        src,
        function (cv_image) {
          cv_image.set({ _data: cv_attrs._data || { type: type } });
          resolve(cv_image);
        },
        cv_attrs,
      );
    });
  }
  G.mp.maker.appropriateUrlByRealWidth = function appropriateUrlByRealWidth(real_width, url) {
    if (url.indexOf('base64') !== -1) {
      return url;
    } else if (real_width <= 150) {
      return G.to_150(url);
    } else if (real_width > 150 && real_width <= 350) {
      return G.to_350(url);
    } else if (real_width > 350 && real_width <= 600) {
      return G.to_600(url);
    } else if (real_width > 600 && real_width <= 1100) {
      return G.to_900(url);
    } else if (real_width > 900) {
      return G.to_original(url);
    }
  };

  function create_cv_old_text(data, zoom) {
    return new Promise(function (resolve) {
      fabric.Image.fromURL(
        G.mp.maker.url(data.src, zoom),
        function (cv_image) {
          cv_image.set(_p.omit(data, 'src'));
          cv_image.set(G.mp.maker.IMAGE);
          cv_image._data.cv_type = 'cv_old_text';
          resolve(cv_image);
        },
        data,
      );
    });
  }

  G.mp.maker.create_cv_old_text = create_cv_old_text;

  function create_cv_group(cv_attrs, zoom, is_draw) {
    return _p.is_array(cv_attrs)
      ? create_cv_group2(cv_attrs, null, zoom)
      : _p.go(
          cv_attrs.objects,
          _p.map(
            _p.async(function (object) {
              return from_cv_attrs_for_maker_for_group(object, zoom, is_draw, zoom);
            }),
          ),
          _p.compact,
          _p(create_cv_group2, _p, cv_attrs, zoom),
        );
  }

  G.mp.maker.create_cv_group = create_cv_group;

  function create_cv_group2(objects, options, zoom) {
    if (options) {
      delete options.objects;
      delete options.fill;
      delete options.fillRule;
    }
    const cv_group = new fabric.Group(objects, options);
    if (options?.cid) {
      cv_group.cid = options.cid;
    }
    return to_cv_group(cv_group);
  }

  function to_cv_group(group) {
    const cv_group = _extend(
      group,
      G.mp.maker.BASECONF(),
      /*, { globalCompositeOperation: 'source-atop' }*/
    );
    cv_group.id = cv_group.cid;
    cv_group._data.cv_type = 'cv_group';
    // _each(cv_group._objects, function(obj) { obj.globalCompositeOperation = 'source-over'; });
    return cv_group;
  }

  G.mp.maker.create_cv_group = create_cv_group;

  /* add object */

  G.mp.maker.make_img_width = function (image, size) {
    let width, height;
    if (image.width >= size) {
      const sc = parseFloat((size / image.width).toFixed(6));
      width = image.width * sc;
      height = image.height * sc;
    } else {
      width = image.width || _p.v(image, '_.image_colors.0.width') || 100;
      height = image.height || _p.v(image, '_.image_colors.0.height') || 100;
    }
    return { width, height };
  };

  function cv_print_area(canvas) {
    return _find(G.mp.maker.not_designs(canvas), function (obj) {
      return obj._data.cv_type === 'cv_print_area';
    });
  }

  G.mp.maker.cv_print_area = cv_print_area;

  function cv_print_area_mask(canvas) {
    return _find(G.mp.maker.not_designs(canvas), function (obj) {
      return obj._data.cv_type === 'cv_print_area_mask';
    });
  }

  G.mp.maker.cv_print_area_mask = cv_print_area_mask;

  function cv_img_line(canvas) {
    return _find(G.mp.maker.not_designs(canvas), function (obj) {
      return obj._data.cv_type === 'cv_img_line';
    });
  }
  G.mp.maker.cv_img_line = cv_img_line;

  function findCvBpcf(cv_objs) {
    return _p.find(cv_objs, function (obj) {
      return obj._data.cv_type == 'cv_bpcf';
    });
  }

  function cv_bpcf(canvas) {
    return findCvBpcf(G.mp.maker.not_designs(canvas));
  }

  G.mp.maker.cv_bpcf = cv_bpcf;

  function prev_cv_bpcf(canvas) {
    return _find(G.mp.maker.not_designs(canvas), function (obj) {
      return obj._data.cv_type == 'prev_cv_bpcf';
    });
  }

  G.mp.maker.prev_cv_bpcf = prev_cv_bpcf;

  function findCvMask1(cv_objs) {
    return _p.find(cv_objs, function (obj) {
      return obj._data.cv_type == 'cv_mask1';
    });
  }

  function cv_mask1(canvas) {
    return findCvMask1(G.mp.maker.not_designs(canvas));
  }
  G.mp.maker.cv_mask1 = cv_mask1;

  function findCvMask2(cv_objs) {
    return _p.find(cv_objs, function (obj) {
      return obj._data.cv_type == 'cv_mask2';
    });
  }

  function cv_mask2(canvas) {
    return findCvMask2(G.mp.maker.not_designs(canvas));
  }

  G.mp.maker.cv_mask2 = cv_mask2;

  function findCvShading(cv_objs) {
    return _p.find(cv_objs, function (obj) {
      return obj._data.cv_type == 'cv_shading';
    });
  }

  function findCvSafeArea(cv_objs) {
    return _p.find(cv_objs, function (obj) {
      return obj._data.cv_type == 'cv_safe_area';
    });
  }

  function cv_shading(canvas) {
    return findCvShading(G.mp.maker.not_designs(canvas));
  }
  G.mp.maker.cv_shading = cv_shading;

  function cvSafeArea(canvas) {
    return findCvSafeArea(G.mp.maker.not_designs(canvas));
  }
  G.mp.maker.cv_safe_area = cvSafeArea;

  function hide_cv_print_area(canvas) {
    cv_print_area(canvas).set('visible', false);
  }
  G.mp.maker.hide_cv_print_area = hide_cv_print_area;

  function get_cv_type(cv_object) {
    if (!cv_object) return;
    return (cv_object._data && cv_object._data.cv_type) || cv_object.type;
  }

  function is_from_library(cv_object) {
    if (!cv_object) return;
    return (
      (cv_object._data.cv_type == 'cv_image' || cv_object._data.cv_type == 'cv_pattern') &&
      !(cv_object._data.image_color_id || sel('_data.cv_image_attrs._data.image_color_id', cv_object))
    );
  }

  function is_not_show_image_color(cv_object) {
    const fcanvas = getCurrentFcanvas();
    if (NewMakerPropertyBpfF.bpcColorCode2Render.isTarget(fcanvas)) {
      return true;
    }
    if (!cv_object) return;
    const dosu_ctn = BpOptionF.biz.get.currentFace.maxDosuCount(fcanvas.bpf_id);
    if (dosu_ctn == 1) return;
    if (
      cv_object._data &&
      (cv_object._data.cv_type == 'cv_image' || cv_object._data.cv_type == 'cv_pattern')
    ) {
      const cv_image = getCvImage(cv_object);
      return cv_image._data.is_only_one;
    }
  }

  G.mp.maker.get_cv_type = get_cv_type;

  G.mp.maker.is_embro = function (cv_object) {
    return G.mp.maker.cv_objects_deep_find(cv_object, (cv_obj) => {
      return cv_obj._data.is_embro;
    });
  };

  G.mp.maker.only_flex = function (cv_object) {
    return G.mp.maker.cv_objects_deep_find(cv_object, (cv_obj) => {
      return cv_obj._data.only_flex;
    });
  };

  G.mp.maker.can_embro = function (bp) {
    return bp === undefined
      ? box.sel('maker->product_color->_->base_product->can_embro')
      : _p.v(bp, 'can_embro');
  };

  G.mp.maker.can_flex = function (bp) {
    return bp === undefined
      ? box.sel('maker->product_color->_->base_product->can_flex')
      : _p.v(bp, 'can_flex');
  };

  // function is_charCode_65039(text) {
  //   return text.length === 1 && text.charCodeAt(0) === 65039;
  // }

  function remove_empty_cv_texts() {
    setTimeout(function () {
      const canvas = G.mp.maker.editing_canvas();
      _p.go(
        G.mp.maker.designs(canvas),
        _p.filter(function (target) {
          if (get_cv_type(target) !== 'cv_text') return undefined;
          return target.text.trim().length === 0;
        }),
        _p.each(function (target) {
          G.mp.maker.unselect_all();
          canvas.remove(target);
        }),
        function (cv_objs) {
          if (cv_objs.length > 0) return marpplizerProxy();
        },
      );
    }, 10);
  }

  G.mp.maker.selected_pass = false;
  box.set('done_tip_lock', false);
  function show_mobile_tip() {
    if (!UtilF.isLegacyMobile() || getSpecialMessageType(G.mp.maker.editing_canvas())) {
      $.hide($1('.tip_lock'));
      return;
    }

    if (!box.sel('done_tip_lock') && G.mp.maker.designs().length >= 3 && $1('.tip_lock')) {
      $.show($1('.tip_lock'));
      box.set('done_tip_lock', true);
      $.add_class($1('.maker_menu .list .more_btn'), 'tip_expression');
    }
  }
  G.show_mobile_tip = show_mobile_tip;

  function add_class_cv_type(target) {
    $.add_class(
      $1('#maker_frame'),
      'cv_object_selected ' +
        (get_cv_type(target) || '') +
        ((is_from_library(target) && ' from_library') || '') +
        ((is_not_show_image_color(target) && ' not_show_image_color') || ''),
    );
  }

  function doneBtnShow() {
    anime({
      targets: $qs('.maker_menu .done'),
      duration: 200,
      opacity: 1,
      easing: 'linear',
    });
  }

  function doneBtnHide() {
    anime({
      targets: $qs('.maker_menu .done'),
      duration: 200,
      opacity: 0,
      easing: 'linear',
    });
  }
  async function renderCanvasViewSelected(cv_target) {
    show_mobile_tip();
    const canvas = G.mp.maker.editing_canvas();
    if (canvas) {
      renderVisibleWhenSelected(canvas);
      await showSpecialMessage(canvas);
    }

    if (UtilF.isLegacyMobile()) {
      doneBtnHide();

      if ($1('#maker_frame.from_library')) {
        go(
          $('.btm_menu.image_editor .request_bg_removal'),
          each((request_bg_removal_el) => {
            if (cv_target._data.request_bg_removal) $.add_class(request_bg_removal_el, 'checked');
            else $.remove_class(request_bg_removal_el, 'checked');
          }),
        );
      }
      go(
        $('.btm_menu.image_editor .fit_to_print_width'),
        each((fit_to_print_width_el) => {
          if (cv_target._data.is_fitted_to_print_width) $.add_class(fit_to_print_width_el, 'checked');
          else $.remove_class(fit_to_print_width_el, 'checked');
        }),
      );
    }

    if (G.mp.maker.is_embro(cv_target)) {
      $.text(
        $1('.fetch_warning span'),
        cv_target._data.image_width_cm + ' x ' + cv_target._data.image_height_cm,
      );
      $.add_class($1('#maker_frame'), 'has_embro');
    }
    if ($1('html#dream_factory')) G.mp.maker.show_rotation_info(cv_target);
    if (canvas) {
      canvas.renderAll();
      canvas.renderAll();
    }
  }

  function renderCanvasViewCleared() {
    const canvas = G.mp.maker.editing_canvas();
    hide_mobile_tip();
    renderVisibleWhenCleared(canvas);
    hideSpecialMessage();
    if (isDFOrWorker()) makeObjsOpacity1();
    if ($1('html#dream_factory')) G.mp.maker.hide_rotation_info();
  }

  async function selected(e) {
    if (G.mp.maker.selected_pass) return;
    remove_empty_cv_texts();
    const cv_target = e?.target;

    if (!cv_target) return;
    if (sel('preview.on', e.target.canvas)) removeCvPreview(e.target.canvas);
    NewMakerMaskingTapeF.moveActiveIntoRect();
    remove_class_el_maker();
    await check_only_one_image_color(e.target);
    add_class_cv_type(e.target);
    close_half_frame('cv_text_editor cv_image_editor image_color');
    await renderCanvasViewSelected(e.target);
    await marpplizerProxy(e.target);
    renderSelectInLayerInMaker();
    return e.target;
  }

  async function cleared() {
    const canvas = G.mp.maker.editing_canvas();
    if (!canvas) return;
    if (G.mp.maker.cleared_pass) return;
    NewMakerMaskingTapeF.moveActiveIntoRect();
    remove_empty_cv_texts();
    remove_class_el_maker();
    close_half_frame('cv_text_editor cv_image_editor image_color');
    // await NewMakerCallConditionPopupF.callForAll();
    checkIsOverflowInCurrentFace();
    checkCurrentFcanvasDesignSize();

    if (!NewMakerCallConditionPopupF.is_mapping_confirm_popup()) {
      renderCanvasViewCleared();
    }

    if (UtilF.isLegacyMobile()) {
      doneBtnShow();
    }

    await marpplizerProxy();
    if (isPreviewStyle(canvas)) {
      if (G.mp.maker.cleared_pass) return;
      const don_loader_wrap_el = !!$qs('.don_loader_wrap');
      if (!don_loader_wrap_el) mpMakerLoaderStart();
      // if (BpOptionF.biz.get.currentFace.hasDosuRelatedOptions()) {
      //   await updateCvObjToDefaultColorCodesInDosu();
      // }
      try {
        await addCvPreview(canvas, 0);
      } catch (e) {
        MakerDebugF.api.MakerDebugPost(
          getProductColorInMaker(),
          e,
          MakerDebugConstantS.Category.Cleared,
          'addCvPreview',
        );
      }
      if (!don_loader_wrap_el) mpMakerLoaderEnd();
    }
    renderSelectInLayerInMaker();
    // go(G.mp.maker.designs(), each(NewMakerCallConditionPopupF.setAndRender));
    // NewMakerConditionAgreeTextF.checkAddCartDisabled();
  }

  function remove_class_el_maker() {
    $.remove_class(
      $1('#maker_frame'),
      'cv_object_selected cv_text_image_pattern cv_text_image cv_text cv_image cv_pattern cv_group group locking unlocking has_embro from_library not_show_image_color old_cv_text',
    );
  }

  G.mp.maker.remove_class_el_maker = remove_class_el_maker;

  G.mp.maker.cleared = cleared;
  G.mp.maker.cleared_pass = false;

  function hide_mobile_tip() {
    if (!UtilF.isLegacyMobile() || getSpecialMessageType(G.mp.maker.editing_canvas())) return;

    const target = $1('.tip_lock');
    if (box.sel('done_tip_lock') && target) {
      target && $.remove_class($1('.maker_menu .list .more_btn'), 'tip_expression');
      target &&
        anime({
          targets: target,
          opacity: 0,
          duration: 200,
          complete: function () {
            $.remove(target);
          },
        });
    }
  }
  G.mp.maker.hide_mobile_tip = hide_mobile_tip;

  function close_half_frame(el_class) {
    if (!UtilF.isLegacyMobile()) return;
    _p.go(
      el_class.split(' '),
      _p.some(function (el_class) {
        return $1('.don_frame.' + el_class + '.is_show');
      }),
      _p.if2(_p.idtt)(_p.noop, $.frame.close),
    );
  }

  G.mp.maker.modified = modified;
  function design_collection_type_setting() {
    if ($.attr($1('html'), 'collabo_type') !== 'line') return;
    const design_collection_origin_type = box.sel('maker->product_color').design_collection_origin_type;
    if (design_collection_origin_type) {
      $.attr($1('html'), {
        design_collection_type: design_collection_origin_type,
      });
      return;
    }
    let design_collection_type = null;
    return _p.go(
      _p.find(
        box.sel('maker->product_color->_->base_product_color->_->base_product_color_faces'),
        function (v, idx) {
          return _p.go(
            G.mp.maker.designs(box.sel('maker->canvass')[idx]),
            _p.find(function (cv_object) {
              return G.mp.maker.cv_objects_deep_find(cv_object, function (cv_obj) {
                const dct = _p.v(cv_obj, '_data.design_collection_type');
                if (dct) {
                  design_collection_type = dct;
                  return true;
                }
                const dct2 = _p.v(cv_obj, '_data.cv_image_attrs._data.design_collection_type');
                if (dct2) {
                  design_collection_type = dct2;
                  return true;
                }
              });
            }),
          );
        },
      ),
      function () {
        box.sel('maker->product_color').design_collection_type = design_collection_type;
        $.attr($1('html'), { design_collection_type: design_collection_type });
      },
    );
  }
  G.mp.maker.design_collection_type_setting = design_collection_type_setting;

  // function getCvObjsToChangeColor(fcanvass, { option_color_codes }) {
  //   return go(
  //     fcanvass,
  //     map(async (fcanvas) => {
  //       // const original_color_codes =
  //       const fcanvas_pallets = NewMakerPropertyBpOptionF.dosuColors.getFcanvasPallets(
  //         getRealFcanvass(),
  //         fcanvas,
  //       );
  //       const bpf = getBpfOfFcanvas(fcanvas);
  //       const is_allow_user_image_color = NewMakerPropertyBpOptionF.dosuColors.isUnlimited({
  //         base_product,
  //         bpf_id: bpf.id,
  //       });
  //       const color_codes = (is_allow_user_image_color ? fcanvas_pallets : []).concat(option_color_codes);
  //       const cv_objs_to_change_color = await go(
  //         getCvDesigns(fcanvas._objects),
  //         filter(async (cv_obj) => {
  //           return go(
  //             [cv_obj],
  //             getFlattenCvDesigns,
  //             some((cv_obj) => {
  //               const target_color_code =
  //                 cv_obj._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR]?.toUpperCase();
  //               if (!target_color_code) return;
  //               return !color_codes.includes(target_color_code);
  //             }),
  //           );
  //         }),
  //       );
  //       return {
  //         fcanvas,
  //         cv_objs_to_change_color,
  //         color_codes,
  //       };
  //     }),
  //     filter(({ cv_objs_to_change_color }) => cv_objs_to_change_color.length),
  //   );
  // }

  // async function updateCvObjToDefaultColorCodesInDosu() {
  //   const { color_codes: option_color_codes } = BpOptionF.biz.get.currentFace.dosuColorOptionHexCodes();
  //   const fcanvass = getRealFcanvass();
  //   let changed_count = 0;
  //   let is_other_face_changed = false;
  //   const current_fcanvas = getCurrentFcanvas();
  //   await go(
  //     getCvObjsToChangeColor(fcanvass, {
  //       option_color_codes,
  //     }),
  //     each(async ({ fcanvas, cv_objs_to_change_color, color_codes }) => {
  //       changed_count++;
  //       if (fcanvas !== current_fcanvas) is_other_face_changed = true;
  //       OMPDosuF.showToastMsg({
  //         text: TT('biz::dosu::warning_02'),
  //         target_el: $qs('#maker'),
  //       });
  //       const default_color_code = head(color_codes);
  //       await go(
  //         cv_objs_to_change_color,
  //         each(async (cv_obj) => {
  //           await applyColorPickerToCvObj({ cv_obj: cv_obj, apply_color: default_color_code });
  //           if (cv_obj._data.cv_type === 'cv_text_image_pattern')
  //             await full_cv_text_image(function (cv_text) {});
  //         }),
  //       );
  //       if (fcanvas?.preview?.on) {
  //         await removeCvPreview(fcanvas, true, true);
  //         await addCvPreview(fcanvas, true);
  //       }
  //     }),
  //   );
  //   if (changed_count > 0) {
  //     if (is_other_face_changed)
  //       $.alert('이미지가 삭제되어, 그 색상을 사용한 다른 면의 디자인도 다른 색상으로 변경 됐습니다.');
  //     await G.mp.maker.reset_layer();
  //     if (!G.mp.maker.active()) {
  //       G.mp.maker.reset_box_data();
  //       await marpplizerProxy();
  //     }
  //   }
  // }
  async function modified() {
    checkCurrentFcanvasDesignSize();
    design_collection_type_setting();
    G.mp.maker.lock.render_state();
    if (!$1('#dream_factory')) {
      G.mp.maker.hide_rotation_info();
      G.mp.maker.hide_width_info();
    }
    product_created();
    G.mp.maker.remove_garbage();
    NewMakerMaskingTapeF.moveActiveIntoRect();
    G.mp.maker.state.push();
    await G.mp.maker.reset_layer();
  }

  G.mp.maker.remove_garbage = function () {
    const canvas = G.mp.maker.editing_canvas();
    _p.go(
      G.mp.maker.designs(),
      _p.filter((d) => d._data.cv_type == 'cv_text_image' && d._data.text_info.text == '️'),
      _p.each((d) => canvas.remove(d)),
    );
  };

  function product_created() {
    box.unset('maker->product_color->id');
    box.unset('maker->product_color->product_id');
  }

  function canvas_main_item_changed2() {
    checkIsOverflowInCurrentFace();
  }
  G.mp.maker.canvas_main_item_changed2 = canvas_main_item_changed2;

  function rotating(e) {
    e.target.rotate(Math.round(e.target.angle));
    G.mp.maker.show_rotation_info(e.target);
  }

  G.mp.maker.remove_all_empty_text = function () {
    const canvass = box.sel('maker->canvass');
    _p.each(canvass, function (c, i) {
      _p.each(G.mp.maker.designs(c), function (d) {
        if (_p.v(d, '_data.text_info.text') === '') c.remove(d);
      });
      c.renderAll();
    });
  };

  function reset_data_cm(cv_object, idx) {
    if (!cv_object) return;
    if (cv_object._data.cv_type === 'cv_text') return;

    const rect = {
      width: G.mp.maker.fabric_get_width(cv_object),
      height: G.mp.maker.fabric_get_height(cv_object),
    };
    const width_cm = to_cm('width', rect, idx);
    const height_cm = to_cm('height', rect, idx);
    let rotate = Math.round(cv_object.angle == 360 ? 0 : cv_object.angle);
    rotate = rotate < 0 ? 360 + rotate : rotate;
    cv_object._data.rotate = rotate;
    cv_object._data.width_cm = width_cm;
    cv_object._data.height_cm = height_cm;
    cv_object._data.real_width_cm = width_cm;
    cv_object._data.real_height_cm = height_cm;
  }
  G.mp.maker.reset_data_cm = reset_data_cm;
  function all_reset_data_cm() {
    return _p.each(
      box.sel('maker->product_color->_->base_product_color->_->base_product_color_faces'),
      function (v, idx) {
        return _p.go(
          G.mp.maker.designs(box.sel('maker->canvass')[idx]),
          _p.each(function (cv_object) {
            return G.mp.maker.cv_objects_deep_each(cv_object, function (cv_obj) {
              return reset_data_cm(cv_obj, idx);
            });
          }),
        );
      },
    );
  }

  function from_start_cm(design, sf) {
    sf = sf || current_size_face(box().maker.product_color);
    const cv_object = new fabric.Object(
      _p.pick(design, [
        'top',
        'left',
        'scaleX',
        'scaleY',
        'angle',
        'width',
        'height',
        'flipX',
        'flipY',
        'originX',
        'originY',
      ]),
    );
    let top = cv_object.getBoundingRect().top;
    if (design.group) {
      // 임시그룹
      const rect = design.group.getBoundingRect();
      top = rect.top + top + rect.height / 2;
    }
    return ((top - sf.print.px.top) / sf.px_per_1cm + sf.start_point_cm).toFixed(1) + 'cm';
  }
  G.mp.maker.from_start_cm = from_start_cm;

  function from_start_info(design, sf) {
    const bpf = getCurrentBpfInMaker();
    if (!bpf.start_name) return '';
    return `${getCurrentBpfInMaker().start_name} ${from_start_cm(design, sf)}`;
  }
  G.mp.maker.from_start_info = from_start_info;

  G.mp.maker.all_reset_data_cm = all_reset_data_cm;

  G.mp.maker.reset_data_cm = reset_data_cm;

  function info_top_left(rect, canvas_wrapperEl, target_width) {
    // // var rect = cv_object.getBoundingRect();
    // const w_zoom = $.width(canvas_wrapperEl) / G.mp.maker.CANVAS_WIDTH;
    // const h_zoom = $.height(canvas_wrapperEl) / G.mp.maker.CANVAS_HEIGHT;
    // const cl = current_location(rect);
    // let top = cl.y + rect.height * h_zoom * G.mp.maker.zoom_attr.scale + 30;
    // if (!UtilF.isLegacyMobile()) top -= $.offset($1('.canvas_container.selected')).top;
    // top = Math.min($.height(canvas_wrapperEl) - 90, top);
    // const left = cl.x + (rect.width * w_zoom * G.mp.maker.zoom_attr.scale) / 2 - target_width / 2;
    //
    // return { top, left };
    const wrapper_width = $.width(canvas_wrapperEl);
    const w_zoom = wrapper_width / G.mp.maker.CANVAS_WIDTH;
    const h_zoom = $.height(canvas_wrapperEl) / G.mp.maker.CANVAS_HEIGHT;
    const scale = G.mp.maker.zoom_attr.scale;
    const canvass_offset = $.offset($1('.mp_maker .board'));
    const zoom_wrapper_offset = $.offset($1('.canvas_container.selected .canvas_zoom_wrapper'));
    const top = zoom_wrapper_offset.top - canvass_offset.top;
    const left = zoom_wrapper_offset.left - canvass_offset.left;
    const obj_top = rect.top * h_zoom * scale;
    const obj_width = rect.width * w_zoom * scale;
    const obj_height = rect.height * h_zoom * scale;
    const obj_left = rect.left * h_zoom * scale;
    return {
      top: obj_top + top + obj_height + 20,
      left: obj_left + left + obj_width / 2 - target_width / 2,
    };
  }
  G.mp.maker.info_top_left = info_top_left;
  G.mp.maker.show_width_info = function (cv_object) {
    if (!$1('#maker')) return;
    if ($qs('#maker_frame').dataset.is_digital_product === 'true') return;
    if ($attr('is_shade', $qs('#maker_frame')) === 'true') return;
    if ($1('#dream_factory')) return G.mp.maker.show_rotation_info(cv_object);
    // G.mp.maker.hide_all_info();
    const el_info = $1('#maker .width_info');
    // var {top, left} = info_top_left(cv_object, el_info);
    const rect = cv_object.getBoundingRect();
    if (!cv_object.canvas) return;
    const canvas_wrapperEl = cv_object.canvas.wrapperEl;
    const target_width = $.width(el_info);
    const { top, left } = info_top_left(rect, canvas_wrapperEl, target_width);

    $.show(
      $.text(
        $.css(el_info, {
          top: top,
          left: left,
          opacity: 1,
        }),
        cv_object._data.width_cm + ' x ' + cv_object._data.height_cm,
      ),
    );
  };

  G.mp.maker.hide_width_info = function () {
    if (!$1('#maker')) return;
    if ($qs('#maker_frame').dataset.is_digital_product === 'true') return;
    $1('#maker') &&
      anime({
        targets: $('#maker .width_info'),
        opacity: 0,
        duration: $1('#dream_factory') ? 0 : 300,
        easing: 'linear',
        complete: function () {
          $.hide($1('#maker .width_info'));
        },
      });
  };

  G.mp.maker.hide_rotation_info = function () {
    if (!$1('#maker')) return;
    if ($qs('#maker_frame').dataset.is_digital_product === 'true') return;
    anime({
      targets: [$1('#maker .rotation_info'), $1('#maker .only_rotation_info')],
      opacity: 0,
      duration: $1('#dream_factory') ? 0 : 300,
      easing: 'linear',
      complete: function () {
        $.hide($1('#maker .rotation_info'));
        $.hide($1('#maker .only_rotation_info'));
      },
    });
  };

  G.mp.maker.show_rotation_info = function (cv_object) {
    if (!$1('#maker')) return;
    reset_data_cm(cv_object);
    if ($qs('#maker_frame').dataset.is_digital_product === 'true') return;
    if ($attr('is_shade', $qs('#maker_frame')) === 'true') return;
    const fcanvas = cv_object.canvas;

    const el_info = cv_object._data.is_embro ? $1('#maker .only_rotation_info') : $1('#maker .rotation_info');

    const rect = cv_object.getBoundingRect();
    const canvas_wrapperEl = fcanvas.wrapperEl;
    const target_width = $.width(el_info);
    const { top, left } = info_top_left(rect, canvas_wrapperEl, target_width);

    $.text($.find1(el_info, '.rotate'), cv_object._data.rotate);
    $.text($.find1(el_info, '.width'), cv_object._data.width_cm + ' x ' + cv_object._data.height_cm);
    $.text($.find1(el_info, '.from_start'), from_start_info(cv_object));

    if (!UtilF.isLegacyMobile()) {
      _go($1('#angle_function input[type="number"]'), $.val(cv_object._data.rotate));
    }

    $.show(
      $.css(el_info, {
        top: top,
        left: left,
        opacity: 1,
      }),
    );
  };

  G.mp.maker.hide_all_info = function () {
    $.hide($1('#maker .width_info'));
    $.hide($1('#maker .rotation_info'));
    $.hide($1('#maker .only_rotation_info'));
  };

  function scaling(cv_obj) {
    reset_data_cm(cv_obj);
    G.mp.maker.show_width_info(cv_obj);
  }
  G.mp.maker.scaling = scaling;

  function current_size_face(product_color, idx) {
    if (idx == undefined) idx = G.mp.maker.editing_canvas_idx();
    const bpf = product_color._.base_product_color._.base_product_color_faces[idx]._.base_product_face;
    return _p.find(bpf.size_faces, function (sf) {
      return sf.base_product_size_id === product_color.base_product_size_id;
    });
  }
  G.mp.maker.current_size_face = current_size_face;
  function to_cm(key, rect, idx) {
    const fcanvas = idx !== undefined ? getRealFcanvass()[idx] : getCurrentFcanvas();
    const px_per_1cm = fcanvas._px_per_1cm;
    return parseFloat(((rect[key] * G.mp.maker.NSCREEN_ZOOM) / px_per_1cm).toFixed(1));
  }
  G.mp.maker.to_cm = to_cm;

  function to_px(key, rect) {
    const px_per_1cm = current_size_face(box().maker.product_color).px_per_1cm;
    return Math.round(rect[key] * G.mp.maker.NSCREEN_ZOOM * px_per_1cm);
  }
  G.mp.maker.to_px = to_px;

  function current_location(point) {
    const offset = $.offset($1('.canvas_container.selected .canvas_zoom_wrapper'));
    const canvas_wrapperEl = $1('.canvas_container.selected .canvas-container');
    return {
      x:
        point.left * ($.width(canvas_wrapperEl) / G.mp.maker.CANVAS_WIDTH) * G.mp.maker.zoom_attr.scale +
        offset.left,
      y:
        point.top * ($.height(canvas_wrapperEl) / G.mp.maker.CANVAS_HEIGHT) * G.mp.maker.zoom_attr.scale +
        offset.top,
    };
  }

  function getCurrentCanavsLocation(point) {
    const offset = $.offset($1('.canvas_container.selected .canvas_zoom_wrapper'));
    const width_ratio =
      G.mp.maker.CANVAS_WIDTH /
      ($.width($1('.canvas_container.selected .canvas_zoom_wrapper')) * G.mp.maker.zoom_attr.scale);
    const left = (point.left - offset.left) * width_ratio;
    const top = (point.top - offset.top) * width_ratio;
    return { left, top };
  }
  G.mp.maker.getCurrentCanavsLocation = getCurrentCanavsLocation;

  G.mp.maker.modified = modified;
  G.mp.maker.debounced_modified = _p.debounce((cv_obj) => {
    modified(cv_obj);
    NewMakerCallConditionPopupF.setAndRender(cv_obj);
  }, 300);
  G.mp.maker.to_attrs = function (cv_object) {
    return G.mp.maker.to_attrs2(cv_object.toObject());
  };
  G.mp.maker.to_attrs2 = function (cv_attrs) {
    return G.mp.maker.onescreen(cv_attrs);
  };

  G.mp.maker.maker_mobile_close = __(
    _p.if2(function () {
      if ($1('.mp_maker.decoration_mode')) NewMakerIconMenuF.decorationOff();
      return $1('html.all_faces_mode');
    })(
      _p.cb(function (next) {
        _p.delay(function () {
          next(1);
        }, 500);
      }),
    ),
    function () {
      G.mp.headroom?.pin();
      G.mp.maker.unselect_all();
      G.mp.maker.pinch_mode.close();
      hideSpecialMessage();
    },
  );

  $.frame.defn_frame({
    frame_name: 'maker.design_collection',
    page_name: 'maker.design_collection',
    el_id: 'maker_design_collection',
    always_remove: true,
    animation: false,
    title: '상품 만들기',
    hide_frame_button_type: 'X',
    header_height: 60,
    closed: function () {
      onbeforeunload_event_off();
      $.remove_class($1('html'), 'maker_design_collection');
      $.off(window, 'resize.maker');
      removeMakerPageClass();
    },
    appended: _p.pipe(
      $.on('click', '.maker_go_next', async function () {
        $.don_loader_start();
        _p.go(isMakerDone(), async function (is_maker_done) {
          if (!is_maker_done) return $.don_loader_end();
          const { svg_product_materials, selected_option_group } = await createSVGMaterialForPC(
            pick(['svg_product_materials', 'selected_option_group'])(box.sel('maker->product_color->_')),
          );
          const need_composite_thumbnail =
            G.collabo_type !== 'line' &&
            (await go(
              axios.get('/@api/composite/need_composite_thumbnail', {
                params: {
                  base_product_id: box.sel('maker->product_color').base_product_id,
                  type: 'thumbnail',
                },
              }),
              sel('data'),
            ));
          box.sel('maker->product_color').thumbnails = null;
          if (need_composite_thumbnail) {
            const assoc_composite_templates = await getThumbnailAssocCompositeTemplates(
              box.sel('maker->product_color'),
              true,
            );
            const size = await makeMockupSize(assoc_composite_templates);
            G.mp.maker.is_auto_print = true;
            await setProductOnlyDesignFaceUrl(box.sel('maker->product_color'), size);
            G.mp.maker.is_auto_print = false;
            await addCompositeTemplateSelectionPage({
              svg_product_materials,
              selected_option_group,
              assoc_composite_templates,
              product: omit(['_', 'id', 'product_id'], box.sel('maker->product_color')),
              next: async function (pc) {
                await setAndUploadThumbnailOgImage(pc, true);
                box().maker.product_color.thumbnails = pc.thumbnails;
                box().maker.product_color.og_image_url = pc.og_image_url;
                await $.frame.open(
                  {
                    frame_name: 'mp.make_product_color_price',
                    is_modal: !UtilF.isLegacyMobile(),
                  },
                  {
                    tabs: [
                      {
                        page_name: 'mp.make_product_color_price_page',
                        data_func: function () {
                          return box().maker.product_color;
                        },
                      },
                    ],
                  },
                );
              },
            });
          } else {
            await $.frame.open(
              {
                frame_name: 'mp.make_product_color_price',
                is_modal: !UtilF.isLegacyMobile(),
              },
              {
                tabs: [
                  {
                    page_name: 'mp.make_product_color_price_page',
                    data_func: function () {
                      return box().maker.product_color;
                    },
                  },
                ],
              },
            );
          }
          $.don_loader_end();
        });
      }),
    ),
  });
  $.frame.defn_page({
    page_name: 'maker.design_collection',
    animation: false,
    tabs: [
      {
        tab_name: 'maker.design_collection',
        template: _p.pipe(
          _p.tap(function (product_color) {
            box().maker = box().maker || {};
            box.set('maker->up_c', {
              base_product_id: product_color.base_product_id,
              base_product_color_id: product_color.base_product_color_id,
              base_product_size_id: product_color.base_product_size_id,
            });
            box.set('maker->product_color', product_color);
          }),
          _p.t$(
            '\
          .detail_wrapper#for_detail_change\
        ',
          ),
        ),
        appended: pipe(
          tap(BpOptionF.event),
          tap(ProductDetailTabsF.event),
          tap(SVGEditorProductDetailF.vectorEditorOptionsEvent),
          async (el_don_tab) => {
            const product_color = box.sel('maker->product_color');

            if (!UtilF.isLegacyMobile()) {
              const is_mobile = UtilF.isLegacyMobile();
              const base_product = product_color._.base_product;
              const print_guide = base_product['print_guide' + (is_mobile ? '_m' : '_pc') + G._en];
              $.append(
                $.find1(el_don_tab, '#for_detail_change'),
                ProductDetailTabsTmplS.productInfoDetail(
                  {
                    bp_id: base_product.id,
                    thumbnails: base_product._.base_product_detail['thumbnails' + G._en],
                    tabs: [
                      {
                        name: ProductDetailTabsConstantS.BP_DETAIL_TAB.name,
                        title: ProductDetailTabsConstantS.BP_DETAIL_TAB.title() + G._en,
                        template_str: ProductDetailTabsConstantS.BP_DETAIL_TAB.template({
                          id: base_product.id,
                          size_info_url: base_product.size_info_url,
                          cate_list_id: base_product.cate_list_id,
                          cate_item_id: base_product.cate_item_id,
                          size_compare: base_product._.base_product_detail.size_compare,
                          brand_logo: base_product.brand_logo,
                          brand_name: base_product['brand_name' + G._en],
                          brand_description: base_product['brand_description' + G._en],
                          product_info: base_product['product_info' + G._en],
                          guide: is_mobile ? base_product.guide_m : base_product.guide_pc,
                          size_table: base_product['size_table' + G._en],
                          is_mobile,
                        }),
                        is_hidden: false,
                      },
                      {
                        name: ProductDetailTabsConstantS.MANUFACTURE_TAB.name,
                        title: ProductDetailTabsConstantS.MANUFACTURE_TAB.title() + G._en,
                        template_str: ProductDetailTabsConstantS.MANUFACTURE_TAB.template({
                          print_guide,
                          id: base_product.id,
                        }),
                        is_hidden: G.collabo_type !== '' && !print_guide,
                      },
                    ],
                  },
                  is_mobile,
                ),
              );
            }
            await initMpMaker(
              $.find1(el_don_tab, '> .don_wrapper'),
              $.height($.find1($.closest(el_don_tab, '.don_frame'), '>.don_wrapper >.header')),
            );
            setTimeout(() => {
              ProductDetailTabsF.init(el_don_tab);
            }, 100);
            const maker_frame_el = $qs('#maker_frame');
            if (maker_frame_el?.dataset?.is_vector === 'true') {
              const bp_option_groups_el = $qs('.bp_option_groups');
              await SVGEditorProductDetailF.vectorEditorPreviewInit(bp_option_groups_el);
            }
          },
        ),
        showed: __(async function (el_don_tab) {
          await marpplizerProxy();
          await G.mp.maker.reset_layer();
        }),
      },
    ],
  });

  let saved = false;
  $.frame.defn_frame({
    frame_name: 'maker.printable_product',
    page_name: 'maker.printable_product',
    el_id: 'maker_printable_product',
    animation: false,
    always_remove: true,
    custom_header: _p.t$(
      '\
      .title\
      button[class="don_hide_frame" type="button"]\
        span 취소\
      button[class="save" type="button"]\
        span 저장하기',
    ),
    close_confirm: function () {
      return box.sel('maker->product_color->id') || saved || $.confirm('정말 닫으시겠습니까?');
    },
    appended: _p.pipe(
      $.on2('click', '> .don_wrapper >.header .save', async function () {
        saved = true;
        removeCvBlueLineInMaker();
        G.mp.maker.unselect_all();

        if (isDFOrWorker()) go(getRealFcanvass(), each(makeObjsOpacity1));

        product_created();
        $.don_loader_start();

        return _p.go(makeDataCmAndChangeCvTextResetBoxDataA(), async function () {
          await go(
            box.sel('maker->product_color->product_faces2->value'),
            filter(sel('cv_preview')),
            each(async (pf) => {
              const { url } = await $.uploadFileToUrl(pf.cv_preview.src, 'cv_preview');
              pf.cv_preview.src = url;
              if (pf.cv_preview._data.shade_material) {
                await NewMakerPropertyBpfF.common.shadeMaterial.uploadShadeMaterialData(pf.cv_preview);
              }
            }),
          );
          const pc = box.sel('maker->product_color');
          return _p.go($.frame.close(pc), function () {
            $.don_loader_end();
          });
        });
      }),
    ),
  });

  $.frame.defn_page({
    page_name: 'maker.printable_product',
    tabs: [
      {
        tab_name: 'maker.printable_product',
        template: _p.pipe(function ([product_color, user_made_product_color]) {
          box.set('maker', {});
          box.set('user_made_product_color', user_made_product_color);
          box.set('maker->up_c', {
            base_product_id: product_color.base_product_id,
            base_product_color_id: product_color.base_product_color_id,
            base_product_size_id: product_color.base_product_size_id,
          });
          box.set('maker->product_color', product_color);
          readyPfColllaboTypeBpsId();
        }, _p.c('')),
        hided: function () {
          $.off(window, 'resize.maker');
          is_load_font_important.value = false;
        },
        showed: async function (el_don_tab) {
          is_load_font_important.value = true;
          fabric.charWidthsCache = {};
          const projection = box.sel('df/projection/detail->projection');
          _p.go(
            el_don_tab,
            $.closest('.don_frame'),
            $.find1('>.don_wrapper >.header .title'),
            $.html(
              `시안 작업 - <a href="/projection/detail/${projection.id}" target="_blank">주문서 새창에 띄우기</a>`,
            ),
          );

          const product_color = box.sel('maker->product_color');
          go(
            product_color.product_faces2.value,
            map((pf) => pf.designs),
            flat,
            each((cv_obj) => {
              cv_obj.selectable = true;
              cv_obj.evented = true;
            }),
          );

          await initMpMaker($.find1(el_don_tab, '> .don_wrapper'), true);
          if (el_don_tab.tab_opt.is_change_product) {
            $.trigger($.find1(el_don_tab, '.open_chooser'), 'click');
          }
        },
        removed: function () {
          removeMakerPageClass();
        },
      },
    ],
  });
})({});
