import format from 'date-fns/format/index.js';
import ko from 'date-fns/locale/ko/index.js';
import { html, sel } from 'fxjs/es';
import { MShopUtilConstantS } from '../../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { createImageUrl } from '../../../../Qr/S/Function/create.js';
import { getReceiptStatus } from '../../../../Receipt/S/fs.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { langTo_en } from '../../../../Util/S/LangAndCollaboType.js';
import { isCanQuickCancel } from '../../Detail/F/fs.js';
import { label_aztec_code_src } from '../S/label_aztec_code_src.js';
import { langToKR, makeProjectionItemHeadHtml } from './tmpl.js';
import { isInhouseProjection } from './inhouse.js';
import { makePayMethodUsingProjectionPayment } from '../../../../MPay/Payment/S/fs.js';

const remainingPeriod = (date) => {
  const result_period = Math.floor((new Date(date) - new Date()) / 1000 / 60 / 60 / 24);
  if (result_period < 0) {
    return `약${result_period * -1}일 지남`;
  }
  return `약${result_period}일 남음`;
};

const estimatedProductionDate = (business_after) => {
  const { date, fast_date, production_period, production_fast_period, is_collabo_type_creator } =
    business_after || {};
  if (is_collabo_type_creator) {
    const add_day = {
      production_period: remainingPeriod(date),
      production_fast_period: remainingPeriod(fast_date),
    };
    return {
      normal: business_after?.date
        ? html`<span style="color: #506eff"
            >${format(new Date(date), 'yy.MM.dd (EEE)', {
              locale: ko,
            })}<br />${add_day.production_period}</span
          >`
        : html`<span style="color: #506eff">알 수 없음</span>`,

      fast: business_after?.fast_date
        ? html`<span style="color: #ff382f"
            >${format(new Date(fast_date), 'yy.MM.dd (EEE)', {
              locale: ko,
            })}<br />${add_day.production_fast_period}</span
          >`
        : html`<span style="color: #ff382f">알 수 없음</span>`,
    };
  } else {
    const add_day = {
      production_period,
      production_fast_period,
    };
    return {
      normal: business_after?.date
        ? html`<span style="color: #506eff"
            >${format(new Date(date), 'yy.MM.dd (EEE)', { locale: ko })}<br />약${add_day.production_period}일
            걸림</span
          >`
        : html`<span style="color: #506eff">알 수 없음</span>`,

      fast: business_after?.fast_date
        ? html`<span style="color: #ff382f"
            >${format(new Date(fast_date), 'yy.MM.dd (EEE)', {
              locale: ko,
            })}<br />약${add_day.production_fast_period}일 걸림</span
          >`
        : html`<span style="color: #ff382f">알 수 없음</span>`,
    };
  }
};

const getAfreecaOrderNo = (prj) => {
  if (
    prj.merged_type == 'child' &&
    prj._?.store?._?.crew?.crew_id == MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID
  ) {
    const [afreeca_order_option] = prj._.afreeca_orders
      .map(({ af_order_no, canceled_at, _: { afreeca_order_options } }) => {
        return {
          af_order_no,
          canceled_at,
          afreeca_order_options: afreeca_order_options.map(
            ({ af_order_product_no, af_order_product_option_no, canceled_at }) => {
              return { no: `${af_order_product_no}/${af_order_product_option_no}`, canceled_at };
            },
          ),
        };
      })
      .flat();
    if (afreeca_order_option) {
      return html`
        <tr>
          <th rowspan="2">숲토어</th>
          <td colspan="3">
            주문번호<br /><span
              ${afreeca_order_option.canceled_at ? 'style="text-decoration: line-through;"' : ''}
              >${afreeca_order_option.af_order_no}</span
            >
          </td>
        </tr>
        <tr>
          <td colspan="3">
            상품주문/옵션주문<br />${afreeca_order_option.afreeca_order_options
              .map(
                ({ no, canceled_at }) =>
                  html`<span ${canceled_at ? 'style="text-decoration: line-through;"' : ''}>${no}</span>`,
              )
              .join('<br />')}
          </td>
        </tr>
      `;
    }
  }
  return '';
};

const IS_ONLY_ONE_MERGED_TYPES = ['fast_child', 'sample'];
export const makeDfProjectionSidebarItemHtml = (
  parent_prj,
  prj,
  _en = langTo_en(prj.lang),
  selected = parent_prj.id === prj.id,
) => {
  const is_only_one = IS_ONLY_ONE_MERGED_TYPES.includes(parent_prj.merged_type);
  /* 샘플이거나 빠른POD이면 부모랑 자식이랑 같은 merged_type 만 나온다. */
  if (is_only_one && parent_prj.merged_type != prj.merged_type) return '';
  /* 샘플이거나 빠른POD이 아니면 자식에는 샘플이나 빠른POD이 나오면 안된다 */
  if (!is_only_one && IS_ONLY_ONE_MERGED_TYPES.includes(prj.merged_type)) return '';

  const shipping_type = sel('_.shippings.0.type', prj);

  const { projections_tag } = prj._;

  const is_biz_managed_projection = parent_prj._.tasks?.some((task) => task.title.match(/\[BIZ\] 관리/));

  const is_merged_parent = prj.type === 'merged_parent';
  const is_creator = prj.collabo_type === 'creator';

  const is_show_waybill_status_btn = !prj.is_cancel_req && !prj.is_canceled;

  let is_projection_card_expose;

  const is_parent_and_self_both_shop_projections = parent_prj.store_id != null && prj.store_id != null;
  const is_same_store = prj.store_id === parent_prj.store_id;

  if (is_parent_and_self_both_shop_projections) {
    // 샵에서는 묶음 주문들 중 같은 스토어 주문들만 노출
    is_projection_card_expose =
      /* 숲토어는 일단 다 노출 */
      (prj.type === 'combined' &&
        prj._?.store?._?.crew?.crew_id == MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID) ||
      is_same_store ||
      prj.type === 'combined' ||
      (is_merged_parent && prj.id === parent_prj.projection_id);
  } else {
    // 둘 다 MP 이거나 MP + SHOP 유형일 때는
    // 기본적으로 다 보여주되
    // 다른 스토어의 병합 주문서는 보여주지 않음
    is_projection_card_expose = !(!is_same_store && is_merged_parent);
  }

  if (!is_projection_card_expose) return '';

  return html`
    <div
      _sel="./(#${prj.id})"
      class="projection_item ${selected ? 'selected' : ''}"
      lang="${prj.lang}"
      type="${prj.type}"
      collabo_type="${prj.collabo_type}"
      merged_type="${prj.merged_type}"
      is_repress="${prj.is_repress}"
      is_inhouse="${isInhouseProjection({ prj })}"
      is_shopify="${prj.is_shopify}"
      status="${prj.status}"
      lang="${prj.lang || 'en'}"
      shipping_type="${shipping_type}"
      is_cancel_req="${prj.is_cancel_req}"
      is_canceled="${prj.is_canceled}"
      is_refund_req="${prj.is_refund_req}"
      is_refunded="${prj.is_refunded}"
      receipt_status="${getReceiptStatus(sel('_.receipt_status', prj))}"
      is_can_quick_cancel="${isCanQuickCancel(prj)}"
    >
      <div class="droparea"></div>
      <div class="check"></div>
      <div class="remove"></div>
      <div class="type_status">
        <div class="type"></div>
        <div class="status"></div>
        <button
          type="button"
          class="shop-waybill-status ${is_creator
            ? is_merged_parent
              ? 'merged_parent'
              : 'merged_child'
            : ''}"
          is_creator="${is_creator}"
          style="${`display: ${is_show_waybill_status_btn ? 'block' : 'none'};`}"
        ></button>
      </div>
      <div class="cancel_refund">
        <div class="is_refund_req">환불 요청</div>
        <div class="is_refunded">환불 완료</div>
        <div class="is_cancel_req">취소 요청</div>
        <div class="is_canceled">취소 완료</div>
      </div>
      <table class="for_task">
        <colgroup>
          <col style="width: 54px;" />
          <col />
          <col style="width: 54px;" />
          <col />
        </colgroup>
        ${makeProjectionItemHeadHtml(prj)}
        <tr>
          <th>[${langToKR(prj.lang)}]</th>
          <td><b>#${prj.id}</b></td>
        </tr>
        ${getAfreecaOrderNo(prj)}
        <tr>
          <th>수량</th>
          <td class="quantity"><b>${UtilS.commify(prj.quantity)}개</b></td>
        </tr>
        <tr
          class="due_at no_admin"
          important="${prj.important}"
          internal-important="${prj.internal_important}"
        >
          <th>출고일</th>
          <td>
            ${prj.due_at ? moment(UtilS.escape(prj.due_at)).format('YY.MM.DD HH:mm') : ''}
            ${prj.important ? '(긴급-고객요청)' : prj.internal_important ? '(긴급-내부전용)' : ''}
          </td>
          <th>주문일</th>
          <td>${moment(UtilS.escape(prj.ordered_at)).format('YY.MM.DD HH:mm')}</td>
        </tr>
        <tr class="production_period_at">
          <th>제작완료 예상날짜</th>
          <td>${estimatedProductionDate(prj.business_after).normal}</td>
        </tr>
        <tr class="production_fast_period_at">
          <th>가장빠른 예상날짜</th>
          <td>${estimatedProductionDate(prj.business_after).fast}</td>
        </tr>
        <tr class="user">
          <th>사용자</th>
          <td>
            <a target="_blank" href="/user/detail/${prj.user_id}/projection"
              >${UtilS.escape(prj._.user.name) || '이름 없음'}
              ${UtilS.escape(prj._.user.id) ? `(${UtilS.escape(prj.user_id)})` : ''}</a
            >
          </td>
          <th>주문자</th>
          <td>
            ${!UtilS.escape(prj.orderer_name)
              ? ''
              : `
          <div class="name">${UtilS.escape(prj.orderer_name)}</div>
          <div class="mobile">${UtilS.escape(_p.dashify(prj.orderer_mobile))}</div>
          <div class="email">${UtilS.escape(prj.orderer_email) || ''}</div>`}
          </td>
        </tr>
      </table>

      <table class="for_projection">
        <colgroup>
          <col style="width: 54px;" />
          <col />
          <col style="width: 20px;" />
          <col style="width: 50px;" />
        </colgroup>
        <tr>
          <td
            colspan="4"
            class="partial-delivery__text"
            style="display: none; background: lightseagreen; color: white; text-align: center"
          >
            부분 출고
          </td>
        </tr>
        ${makeProjectionItemHeadHtml(prj)}
        <tr>
          <th>[${langToKR(prj.lang)}]</th>
          <td colspan="3" class="text_projection_id">
            <div class="id_container">
              <b>#${prj.id}</b>
              <img
                class="projection_code_image"
                data-projection_id="${prj.id}"
                src="${UtilS.escape(
                  createImageUrl({
                    value: label_aztec_code_src({ projection_id: prj.id }),
                    margin: 0,
                    format: 'webp',
                    codeType: 'aztec',
                    displayValue: false,
                  }),
                )}"
                alt=""
              />
            </div>
          </td>
        </tr>
        ${getAfreecaOrderNo(prj)}
        ${prj.merged_type == 'parent'
          ? html` <tr>
              <th>배송용<br />주문수</th>
              <td colspan="3" class="quantity">
                <b>${UtilS.commify(parent_prj.child_count || 0)}개</b>
              </td>
            </tr>`
          : ''}
        ${box.sel('user->_->policies->outsourcing_worker_policy')
          ? ``
          : html`
              <tr>
                <th>수량</th>
                <td colspan="3" class="quantity">
                  <b>${UtilS.commify(prj.quantity)}개</b>
                </td>
              </tr>
            `}
        ${box.sel('user->_->policies->due_at_policy')
          ? html`
              <tr class="due_at" important="${prj.important}" internal-important="${prj.internal_important}">
                <th>출고일</th>
                <td colspan="3">
                  <input
                    class="due_at"
                    type="text"
                    value="${(prj.due_at
                      ? moment(UtilS.escape(prj.due_at)).format('YYYY.M.D HH:mm')
                      : moment(UtilS.escape(prj.ordered_at)).add(4, 'days').format('YYYY-MM-DD')) || ''}"
                    data-holiday="true"
                  />
                  <button type="button">변경</button>
                </td>
              </tr>
              <tr class="important">
                <th>긴급출고<br />고객요청</th>
                <td colspan="3">
                  <input type="checkbox" ${prj.important ? 'checked' : ''} />
                  <button type="button">변경</button>
                </td>
              </tr>
            `
          : html`
              <tr class="due_at" important="${prj.important}" internal-important="${prj.internal_important}">
                <th>출고일</th>
                <td colspan="3">
                  ${prj.due_at ? moment(UtilS.escape(prj.due_at)).format('YY.MM.DD HH:mm') : ''}
                  <br />${prj.important ? '(긴급-고객요청)' : prj.internal_important ? '(긴급-내부전용)' : ''}
                </td>
              </tr>
            `}
        ${box.sel('user->_->policies->projection_internal_important_policy')
          ? html` <tr class="internal-important">
              <th>긴급출고<br />내부전용</th>
              <td colspan="3">
                <input type="checkbox" ${prj.internal_important ? 'checked' : ''} />
                <button type="button">변경</button>
              </td>
            </tr>`
          : ''}
        <tr class="order_at">
          <th>주문일</th>
          <td colspan="3">${moment(UtilS.escape(prj.ordered_at)).format('YY.MM.DD HH:mm')}</td>
        </tr>
        <tr class="production_period_at">
          <th>제작완료 예상날짜</th>
          <td colspan="3">${estimatedProductionDate(prj.business_after).normal}</td>
        </tr>
        <tr class="production_fast_period_at">
          <th>가장빠른 예상날짜</th>
          <td colspan="3">${estimatedProductionDate(prj.business_after).fast}</td>
        </tr>
        <tr class="canceled_at" style="${prj.is_canceled ? '' : 'display: none;'}">
          <th>취소 완료</th>
          <td colspan="3">${moment(UtilS.escape(prj.canceled_at)).format('YY.MM.DD HH:mm')}</td>
        </tr>
        <tr class="user">
          <th>사용자</th>
          <td colspan="3">
            <a target="_blank" href="/user/detail/${prj.user_id}/projection"
              >${prj._.user.name || '이름 없음'} ${prj._.user.id ? `(${prj.user_id})` : ''}</a
            >
          </td>
        </tr>
        ${!UtilS.escape(prj.orderer_name)
          ? ''
          : html` <tr class="user">
              <th>주문자</th>
              <td colspan="3">
                <div class="name">${UtilS.escape(prj.orderer_name)}</div>
                <div class="mobile">
                  ${_p.dashify(UtilS.escape(prj.orderer_mobile) || UtilS.escape(prj._.user.mobile))}
                </div>
                <div class="email">${UtilS.escape(prj.orderer_email) || ''}</div>
              </td>
            </tr>`}
        ${!sel('_.shippings.0.name', prj)
          ? ''
          : html`
              <tr class="user">
                <th>받는이</th>
                <td colspan="3">
                  <div class="name">${UtilS.escape(sel('_.shippings.0.name', prj))}</div>
                  <div class="mobile">
                    ${prj.lang == 'kr'
                      ? _p.dashify(UtilS.escape(sel('_.shippings.0.mobile1', prj)))
                      : `+${UtilS.escape(sel('_.shippings.0.dialing_code', prj))} ${UtilS.escape(
                          sel('_.shippings.0.mobile_en', prj),
                        )}`}
                  </div>
                  <div class="address">
                    ${prj.lang == 'kr'
                      ? ` ${UtilS.escape(sel('_.shippings.0.address', prj)) || ''} ${
                          UtilS.escape(sel('_.shippings.0.address2', prj)) || ''
                        } `
                      : `
                              ${UtilS.escape(sel('_.shippings.0.detail', prj))} ${UtilS.escape(
                          sel('_.shippings.0.street', prj),
                        )}
                              ${UtilS.escape(sel('_.shippings.0.city', prj))} ${UtilS.escape(
                          sel('_.shippings.0.state', prj),
                        )}
                              ${UtilS.escape(sel('_.shippings.0.country', prj))} ${UtilS.escape(
                          sel('_.shippings.0.postcode', prj),
                        )}
                            `}
                  </div>
                </td>
              </tr>
            `}
        ${!sel('_.projection_payments.length', prj)
          ? ''
          : html` <tr>
              <th>결제수단</th>
              <td colspan="3">
                ${prj._.projection_payments
                  .map((pp) => `<span class="payment_type">${makePayMethodUsingProjectionPayment(pp)}</span>`)
                  .join(', ')}
              </td>
            </tr>`}
        ${is_biz_managed_projection
          ? html` <tr>
              <th>태그</th>
              <td colspan="3">
                ${projections_tag?.tags?.join(', ') || '-'}
                <a href="/projection/tags?projection_id=${prj.id}" target="_blank">
                  <button style="margin-left: 5px;">수정</button>
                </a>
              </td>
            </tr>`
          : ''}
      </table>
      <div
        class="price"
        paid="${!PriceS.price(prj['amount' + _en])}"
        paid_amount="${prj['paid_amount' + _en]}"
      >
        <span class="mp_currency"
          >${PriceS.pricify(
            PriceS.sub(prj['paid_amount' + _en], prj['refunded_price' + _en], prj.refunded_point),
          )}</span
        >
        <span>/</span>
        <span class="mp_currency">${PriceS.pricify(prj['total_price' + _en])}</span>
      </div>
      <div class="small_info">
        #${prj.id} / ${UtilS.commify(prj.quantity)}개
        <span class="price" paid="${!prj['amount' + _en]}" paid_amount="${prj['paid_amount' + _en]}">
          /
          <span class="mp_currency">${PriceS.pricify(prj['total_price' + _en])}</span>
        </span>
      </div>
      <a href="/projection/detail/${prj.id}" class="link">주문서 상세 보기</a>
      <button type="button" class="quick_cancel">환불&취소완료</button>
      <span class="link"></span>
    </div>
  `;
};
