import { VectorEditorAcrylicFigureFreeCreatorPCMuiS } from '../../S/Mui/module/VectorEditorAcrylicFigureFreeCreatorPCMuiS.js';
import { tab } from './tab.js';
/*
 * 프론트에서 사용될 page 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#88ba7eb3b2d042cea810e68127da667d
 * */
export const page = {
  ...VectorEditorAcrylicFigureFreeCreatorPCMuiS.page,
  tabs: [tab],

  animation: false, // page을 열거나 닫을때 애니메이션 (기본 - ios면 false 이외 true)

  appending(page_el$) {}, // page 엘리먼트가 만들어지면 울리는 함수
  appended(page_el$) {}, // page 엘리먼트가 html에 붙으면 울리는 함수
  showing(page_el$) {}, // page 엘리먼트 show하기 전 울리는 함수
  showed(page_el$) {}, // page 엘리먼트 show하고 나서 울리는 함수
  /* page를 닫을때 울리는 함수들 */
  hiding(page_el$, val) {}, // page이 가려지기 전 울리는 함수
  hided(page_el$, val) {}, // page이 가려진 후 울리는 함수
  removing(page_el$, val) {}, // page이 삭제되기 전 울리는 함수
  removed(page_el$, val) {}, // page이 삭제된 후 울리는 함수
};
