import { isBase64 } from '../../../Maker/F/util.js';

export const resizeImage = (target_width, img_or_canvas) => {
  const { width: original_w, height: original_h } = img_or_canvas;
  const target_to_original_width_ratio = target_width / original_w;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = target_width;
  canvas.height = original_h * target_to_original_width_ratio;
  ctx.drawImage(img_or_canvas, 0, 0, original_w, original_h, 0, 0, canvas.width, canvas.height);
  return canvas;
};

export const getSizeFitToBox = (fit_mode, box_w, box_h, item_w, item_h) => {
  const item_ratio = item_w / item_h;
  const box_ratio = box_w / box_h;
  let width = box_w;
  let height = box_h;
  if (fit_mode === 'contain') {
    if (item_ratio < box_ratio) {
      width = box_h * item_ratio;
    } else {
      height = box_w / item_ratio;
    }
  }
  return {
    width: Math.round(width),
    height: Math.round(height),
  };
};

export const loadImage = async (url) => {
  const image = new Image();
  image.crossOrigin = 'Anonymous';
  image.src = isBase64(url) ? url : url + G.mp.maker.cross_origin_query_str;
  return new Promise((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = (e) => reject(e);
  });
};
