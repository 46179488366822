import $dataStr from 'fxdom/es/dataStr.js';
import { html } from 'fxjs/es';
import { OMPCoreAtomTmplS } from './module/OMPCoreAtomTmplS.js';
import { OMPCoreUtilS } from '../../../Util/S/Function/module/OMPCoreUtilS.js';

export const categoryButton = ({ id, new_tag = false, active = false, text } = {}) => {
  return html`
    <button ${id ? html`id=${id}` : ''} class="omp-atom__category-button" data-active="${active}">
      <span class="omp-atom__category-button-text-transparent">${text}</span>
      <span class="omp-atom__category-button-text">${text}</span>
      ${new_tag ? html`<span class="omp-atom__category-button-new-tag"></span>` : ''}
    </button>
  `;
};

export const headerMenuArrow = ({ id, new_tag = false, active = false, text } = {}) => {
  return html`
    <button
      ${id ? html`id=${id}` : ''}
      class="omp-atom__header-menu-arrow"
      data-active="${active}"
      data-arrow="down"
    >
      <span class="omp-atom__header-menu-arrow-text-transparent">${text}</span>
      <span class="omp-atom__header-menu-arrow-text">${text}</span>
      <span class="omp-atom__header-menu-arrow-icon">
        ${OMPCoreAtomTmplS.arrowIcon({ direction: 'down' })}
      </span>
      ${new_tag ? html`<span class="omp-atom__header-menu-arrow-new-tag"></span>` : ''}
    </button>
  `;
};

export const categoryLink = ({
  id,
  new_tag = false,
  active = false,
  text,
  link: { href = '', is_new_tab = false },
} = {}) => {
  return html`
    <a
      href="${href}"
      ${is_new_tab ? ' target="_blank"' : ''}
      ${id ? html`id=${id}` : ''}
      class="omp-atom__category-button"
      data-active="${active}"
    >
      <span class="omp-atom__category-button-text">${text}</span>
      <span class="omp-atom__category-button-text-transparent">${text}</span>
      ${new_tag ? html`<span class="omp-atom__category-button-new-tag"></span>` : ''}
    </a>
  `;
};

export const headerMenu = ({
  id,
  new_tag = false,
  active = false,
  text,
  link: { href = '', is_new_tab = false },
} = {}) => {
  return html`
    <a
      href="${href}"
      ${is_new_tab ? ' target="_blank"' : ''}
      ${id ? html`data-id=${id}` : ''}
      class="omp-atom__header-menu-button swiper-slide"
      data-active="${active}"
    >
      <span class="omp-atom__header-menu-button-text">${text}</span>
      <span class="omp-atom__header-menu-button-text-transparent">${text}</span>
      ${new_tag ? html`<span class="omp-atom__header-menu-button-new-tag"></span>` : ''}
    </a>
  `;
};

export const categoryButtonSmall = ({ new_tag = false, active = false, text } = {}) => {
  return html`
    <button class="omp-atom__category-button" data-mag="s" data-active="${active}">
      <span class="omp-atom__category-button-text-transparent">${text}</span>
      <span class="omp-atom__category-button-text">${text}</span>
      ${new_tag ? html`<span class="omp-atom__category-button-new-tag"></span>` : ''}
    </button>
  `;
};

export const categoryLinkSmall = ({
  id,
  new_tag = false,
  active = false,
  text,
  link: { href = '', is_new_tab = false },
} = {}) => {
  return html`
    <a
      href="${href}"
      ${is_new_tab ? ' target="_blank"' : ''}
      ${id ? html`id=${id}` : ''}
      class="omp-atom__category-button"
      data-mag="s"
      data-active="${active}"
    >
      <span class="omp-atom__category-button-text-transparent">${text}</span>
      <span class="omp-atom__category-button-text">${text}</span>
      ${new_tag ? html`<span class="omp-atom__category-button-new-tag"></span>` : ''}
    </a>
  `;
};

/**
 * Create an action button component with the specified options.
 *
 * @param {Object} options - The options for the action button.
 * @param {string} [options.klass=''] - The class name for additional styling (optional, default: '').
 * @param {number} [options.fill=100] - The fill percentage of the button (optional, default: 100).
 * @param {'normal' | 'thin' | 'thick'} [options.solidity='normal'] - The solidity of the button (optional, default: 'normal').
 * @param {'normal' | 'small'} [options.text_size='normal'] - The size of the button text (optional, default: 'normal').
 * @param {string} [options.text=''] - The text displayed on the button (optional, default: '').
 * @param {Object?} [options.data={}] - Additional data associated with the button (optional, default: {}).
 * @param {string} options.type - The type of action performed by the button.
 * @param {string?} options.form - The form ID the button belongs to.
 * @param {boolean?} options.disabled
 * @returns {string} The HTML markup for the action button component.
 */
export const actionButton = ({
  klass = '',
  fill = 100,
  solidity = 'normal',
  text_size = 'normal',
  text = '',
  data = {},
  type,
  form,
  disabled,
} = {}) => {
  return html`
    <button
      ${disabled ? 'disabled' : ''}
      class="omp-atom__action-button ${klass}"
      data-fill="${fill}"
      data-solidity="${solidity}"
      data-text_size="${text_size}"
      ${type ? html`type="${type}"` : ''}
      ${form ? html`form="${form}"` : ''}
      ${OMPCoreUtilS.createDataSet(data)}
    >
      ${text}
    </button>
  `;
};

/**
 * @typedef {object} RemovableButtonParam
 * @property {string?} id
 * @property {string?} text
 * @property {object?} data
 * @property {boolean?} [is_active = false]
 */

/**
 * @param {RemovableButtonParam?} param
 * @param {string?} param.klass
 */
export const removableButton = ({ klass, id, text, data, is_active = false } = {}) => {
  return html`
    <button
      ${id ? html`id=${id}` : ''}
      class="omp-atom__removable-button ${klass || ''}"
      data-id="${id}"
      data-text="${text}"
      data-fx-json="${$dataStr(data || {})}"
      data-is_active="${is_active || false}"
    >
      <span class="omp-atom__removable-button__text">${text}</span>
      <span class="omp-atom__removable-button__icon-wrap">
        ${OMPCoreAtomTmplS.xIcon({ color: is_active ? 'WHT' : 'GY_80' })}
      </span>
    </button>
  `;
};

/**
 * category button에 document 이벤트가 달려있어서 만든 버튼
 * `categoryButton` new_tag 옵션만 빠지고 디자인 동일
 * @param {{id: string?, active: boolean?, string}} [param = {}]
 */
export const textButton = ({ id, active = false, text } = {}) => {
  return html`
    <button ${id ? html`id=${id}` : ''} class="omp-atom__text-button" data-active="${active}">
      <span class="omp-atom__text-button__text--transparent">${text}</span>
      <span class="omp-atom__text-button__text">${text}</span>
    </button>
  `;
};
