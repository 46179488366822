import { getAiTemplateUrl } from '../../S/Function/mobilepdfuploader.js';

/**
 * @param {number} bp_id
 * @param {string} filename
 */
export function downloadAiTemplate({ bp_id, filename = 'template.zip' }) {
  const link = document.createElement('a');
  link.href = getAiTemplateUrl({ bp_id });
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
