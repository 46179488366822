import { $addClass, $closest, $delegate, $find, $removeClass, $setAttr } from 'fxdom/es';
import { go } from 'fxjs/es';
import { ComponentsInputF } from '../../../../../Components/Input/F/Function/module/ComponentsInputF.js';
import { OMPCoreUtilF } from '../../../Util/F/Function/module/OMPCoreUtilF.js';
import { OMPCoreAtomF } from './module/OMPCoreAtomF.js';

/**
 * `OMPCoreAtomTmplS.inputForm` 이벤트
 * @param {HTMLElement} el
 * @return {HTMLElement}
 */
export const delegateInputForm = (el) => {
  const Klass = Object.freeze({
    inputForm: 'omp-atom__form-input',
    input: 'omp-atom__input',
    reset: 'omp-atom__input-reset',
    resetHidden: 'omp-atom__input-reset--hidden',
  });

  /** @type {HTMLInputElement | null} */
  const $inputForm = $find(`.${Klass.inputForm}`)(el);
  if (!$inputForm) {
    throw new Error(`${Klass.inputForm} 클래스를 가진 엘리먼트가 존재하지 않습니다.`);
  }

  const resetEvent = new CustomEvent('reset', {
    bubbles: true,
    detail: {
      inputValue: '',
    },
  });

  return go(
    el,
    OMPCoreAtomF.delegateInput,
    $delegate('submit', `.${Klass.inputForm}`, (e) => {
      const $input = $find(`.${Klass.input}`)(e.currentTarget);
      const submitEvent = new CustomEvent('submit-input-form', {
        bubbles: true,
        detail: {
          inputValue: ComponentsInputF.getSafeInputValue($input),
        },
      });
      e.originalEvent.preventDefault();
      e.currentTarget.dispatchEvent(submitEvent);
    }),
    $delegate('click', `.${Klass.reset}`, (e) => {
      const $inputForm = $closest(`.${Klass.inputForm}`)(e.currentTarget);
      const $input = $find(`.${Klass.input}`)($inputForm);
      const $reset = e.currentTarget;

      $input.dataset.prev_value = $input.value;
      $addClass(Klass.resetHidden)($reset);
      $setAttr({ value: '' })($input);
      if (!OMPCoreUtilF.isMobile()) {
        ComponentsInputF.focusToInput($input);
      }

      $inputForm.dispatchEvent(resetEvent);
    }),
    $delegate('input', `.${Klass.input}`, (e) => {
      const $inputForm = $closest(`.${Klass.inputForm}`)(e.currentTarget);
      const $input = e.currentTarget;
      const $reset = $find(`.${Klass.reset}`)($inputForm);

      const inputValue = ComponentsInputF.getSafeInputValue($input);

      if (inputValue) {
        $removeClass(Klass.resetHidden)($reset);
      } else {
        $addClass(Klass.resetHidden)($reset);
        $inputForm.dispatchEvent(resetEvent);
      }
    }),
  );
};
