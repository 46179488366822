export function multiplyMatrixVector(m, v) {
  return [
    m[0] * v[0] + m[1] * v[1] + m[2] * v[2],
    m[3] * v[0] + m[4] * v[1] + m[5] * v[2],
    m[6] * v[0] + m[7] * v[1] + m[8] * v[2],
  ];
}
export function multiplyMatrices(m_a, m_b) {
  const matrix = [];
  for (let i = 0; i < 3; ++i) {
    for (let j = 0; j < 3; ++j) {
      let value_ij = 0;
      for (let k = 0; k < 3; ++k) {
        value_ij += m_a[3 * i + k] * m_b[3 * k + j];
      }
      matrix.push(value_ij);
    }
  }
  return matrix;
}
export function adj_matrix(m) {
  return [
    m[4] * m[8] - m[5] * m[7],
    m[2] * m[7] - m[1] * m[8],
    m[1] * m[5] - m[2] * m[4],
    m[5] * m[6] - m[3] * m[8],
    m[0] * m[8] - m[2] * m[6],
    m[2] * m[3] - m[0] * m[5],
    m[3] * m[7] - m[4] * m[6],
    m[1] * m[6] - m[0] * m[7],
    m[0] * m[4] - m[1] * m[3],
  ];
}

export function getBackwardProjectionMatrix(
  x1s,
  y1s,
  x1d,
  y1d,
  x2s,
  y2s,
  x2d,
  y2d,
  x3s,
  y3s,
  x3d,
  y3d,
  x4s,
  y4s,
  x4d,
  y4d,
) {
  const src_bp = basisToPoints(x1s, y1s, x2s, y2s, x3s, y3s, x4s, y4s);
  const dest_bp = basisToPoints(x1d, y1d, x2d, y2d, x3d, y3d, x4d, y4d);
  return multiplyMatrices(src_bp, adj_matrix(dest_bp));
}

function basisToPoints(x1, y1, x2, y2, x3, y3, x4, y4) {
  const m = [x1, x2, x3, y1, y2, y3, 1, 1, 1];
  const v = multiplyMatrixVector(adj_matrix(m), [x4, y4, 1]);
  return multiplyMatrices(m, [v[0], 0, 0, 0, v[1], 0, 0, 0, v[2]]);
}

export function getBackwardProjectedPoint(projection_matrix, dest_x, dest_y) {
  const src_vector = multiplyMatrixVector(projection_matrix, [dest_x, dest_y, 1]);
  return [src_vector[0] / src_vector[2], src_vector[1] / src_vector[2]];
}
