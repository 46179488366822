import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';
import { html, mapObject, strMap } from 'fxjs/es';

export function makeProjectionInhouseShipItemHtml(prj, shipping, customer_order_crew_inhouse) {
  if (UtilObjS.isEmNil(customer_order_crew_inhouse)) {
    return '';
  }
  /* escaping */
  shipping = mapObject((v) => _escape(v), shipping);

  const {
    employee_name,
    employee_no,
    _: { inhouse_shipping_box },
  } = customer_order_crew_inhouse;

  const is_box_created = UtilObjS.isNotEmpty(inhouse_shipping_box);

  const crew_inhouse_shipping = customer_order_crew_inhouse._.crew_inhouse_shipping;
  const table_data = {
    header: ['라벨', '회사', '코드', '사원', '사번', '회사 주소', '우편번호'],
    row: [
      crew_inhouse_shipping?.company_label ?? '-',
      crew_inhouse_shipping?.company_name ?? '-',
      crew_inhouse_shipping?.company_code ?? '-',
      employee_name,
      employee_no,
      crew_inhouse_shipping?.company_address ?? '-',
      crew_inhouse_shipping?.company_postcode ?? '-',
    ],
  };

  return html`
    <div
      class="ship_item inhouse"
      shipping_id="${shipping.id}"
      _sel="./_->shipping_order->_->store_order->_->customer_order_crew_inhouse"
    >
      <div class="header">
        <span>사내 배송 수령</span>
        <button class="inhouse_label_print">
          <div class="img ${is_box_created ? 'created' : 'null'}"></div>
          ${is_box_created ? '주문서 라벨 출력됨' : '주문서 라벨 출력 전'}
        </button>
      </div>
      <div class="ship_info">
        <table>
          <thead>
            <tr>
              ${strMap((header) => html`<th>${header}</th>`, table_data.header)}
            </tr>
          </thead>
          <tbody>
            <tr>
              ${strMap((header) => html`<td>${header}</td>`, table_data.row)}
            </tr>
          </tbody>
        </table>
      </div>
      <div class="controllers">
        ${makeInhouseShippingEditorButtonHtml()} ${makeInhouseTogglerButtonHtml({ is_ship_to_inhouse: true })}
      </div>
    </div>
  `;
}

export function makeInhouseShippingEditorButtonHtml() {
  return html` <button class="inhouse_shipping_edit">사내 배송지 수정</button> `;
}

export function makeInhouseTogglerButtonHtml({ is_ship_to_inhouse }) {
  if (is_ship_to_inhouse) {
    // inhouse, 사내 배송으로 주문
    return html`<button name="inhouse_shipping_toggler" class="inhouse_toggler" is_hidden_tobe="true">
      사내 -> <b>개인</b>&nbsp배송 전환
    </button>`;
  } else {
    // inhouse, 개인 배송으로 주문
    return html`<button name="inhouse_shipping_toggler" class="inhouse_toggler" is_hidden_tobe="false">
      개인 -> <b>사내</b>&nbsp배송 전환
    </button>`;
  }
}
