import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { each, equals, filterL, go } from 'fxjs/es';
import { $findAll } from 'fxdom/es';

export const handleBlankPlateClick = () => () => {
  MuiF.closeFrame();
};

export const handleListItemClick =
  ({ tab_el, el }) =>
  () => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    const is_selected = editor.getSelectedEls().has(el);
    if (is_selected) {
      editor.unselectEl(el);
    } else {
      editor.selectEl(el);
    }
  };

export const handleListItemVisibleContainerClick =
  ({ tab_el, el }) =>
  (event) => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    event.stopPropagation();

    const is_visible = el.getAttributeNS(null, 'display') !== 'none';
    if (is_visible) {
      editor.hideEl(el);
    } else {
      editor.showEl(el);
    }
  };

export const handleListItemLockContainerClick =
  ({ tab_el, el }) =>
  (event) => {
    if (tab_el.__mp_keyring_editor == null) {
      return;
    }
    const editor = tab_el.__mp_keyring_editor;

    event.stopPropagation();

    const is_locked = editor.getLockedEls().has(el);
    if (is_locked) {
      editor.unlockEl(el);
    } else {
      editor.lockEl(el);
    }
  };

export const handleKeyringEditorSelect =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_selected = 'true')),
    );
  };

export const handleKeyringEditorUnselect =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_selected = 'false')),
    );
  };

export const handleKeyringEditorLock =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_locked = 'true')),
    );
  };

export const handleKeyringEditorUnlock =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_locked = 'false')),
    );
  };

export const handleKeyringEditorShow =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_visible = 'true')),
    );
  };

export const handleKeyringEditorHide =
  ({ tab_el }) =>
  ({ detail: { el } }) => {
    go(
      tab_el,
      $findAll(`.list_container .list_wrapper .list_inner_wrapper .list_item`),
      filterL((list_item_el) => equals(el)(list_item_el.__mp_el)),
      each((list_item_el) => (list_item_el.dataset.is_visible = 'false')),
    );
  };
