import { constant, each, go, negate, omit, pick, pipe, sel, tap } from 'fxjs/es';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import {
  makeFilterItemsHtml,
  makeProductFilterListHtml,
  makeProductListHtml,
  makeSelectedFilterItemsHtml,
} from '../S/tmpl.js';
import { IS_PRODUCT_LIST_PC_SIZE } from './constant.js';
import {
  changeSelectedFilterItem,
  changFilterListCount,
  checkedFilterItem,
  clickProductListClear,
  makeStickySlideBar,
  updateProductListView,
} from './fs.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';

export const defnProductListTab = () => ({
  showed: function (don_tab) {
    const don_frame = $.closest(don_tab, '.don_frame');
    $.don_select($1('#ordering'));
    // if (G.mp.maker.mp_product_list_scroll_top) {
    //   $.css($1('body'), {visibility: 'hidden'});
    //   setTimeout(function(){
    //     $.scroll_top2(window, G.mp.maker.mp_product_list_scroll_top);
    //     $.css($1('body'), {visibility: 'visible'});
    //   }, 100)
    // }
    if (!don_frame.frame_opt.is_modal) return G.don_lazy();
    don_frame.frame_opt.modal_lazy = new Blazy({
      selector: '.don_lazy',
      container: '.don_frame.product_detail_list >.don_wrapper >.body',
    });
  },
  tab_name: 'mp.product.list',
  template: function ([result, cate_lists]) {
    return makeProductListHtml(result.bp_info, parseInt(result.cate_list_id), result.query, cate_lists);
  },
  appended: pipe(
    tap(makeStickySlideBar),
    $.on('change', '#ordering > select', updateProductListView()),
    $.on('click', '.product_list .base_product_list .base_product a', async function (e) {
      if (!$.closest(e.currentTarget, '.don_frame.product_detail_list')) return true;
      e.preventDefault();
      const bp = window.box.sel($.closest(e.currentTarget, '.base_product'));
      G.mp.maker.extend_state_bp(bp.id);
      go($.frame.close(bp), async function () {
        if (UtilF.isLegacyMobile() && $1('html.prev_product_matcher'))
          go($.frame.close(true), function () {
            $.frame.open({
              frame_name: 'mp.maker.prev_product_matcher',
            });
          });
      });
    }),
    $.on(
      'click',
      'ul.cate_lists li.cate_selector',
      pipe(
        tap((e) => e.preventDefault()),
        sel('currentTarget'),
        function (cate_selector) {
          if ($.attr(cate_selector, 'selected')) return;
          const cate_item_id = $.attr(cate_selector, 'cate_item_id');
          // var before_cate_list_id = window.box.sel('product_list->cate_list_id');
          window.box[cate_item_id ? 'set' : 'unset']('product_list->query->cate_item_id', cate_item_id);
          window.box.set('product_list->cate_list_id', $.attr(cate_selector, 'cate_list_id'));
          window.box.set(
            'product_list->query',
            pick(
              ['cate_item_id', 'ordering' /*, 'cut_img' 한글버전*/],
              window.box.sel('product_list->query'),
            ),
          );
          $.html(
            $1('#selected_cate_list'),
            window.box.sel($.closest(cate_selector, '[_sel]')).name + '<span></span>',
          );
          $.attr($('ul.cate_lists .cate_selector[selected="true"]'), 'selected', false);
          $.attr(cate_selector, 'selected', true);
          return updateProductListView(function ({ bp_info: { filter_lists } }) {
            if (IS_PRODUCT_LIST_PC_SIZE)
              $.html(
                $1('#product_list > .header .filter_selector_wrap.pc_v .filter_selector'),
                makeProductFilterListHtml(filter_lists),
              );
          })();
        },
      ),
    ),
    $.on(
      'click',
      '.filter_selector_wrap.pc_v .filter_list .filter_list_name',
      pipe(sel('currentTarget'), $.closest('.filter_list'), function (filter_list) {
        $.attr(filter_list, 'selected', !$.attr(filter_list, 'selected'));
      }),
    ),
    $.on(
      'click',
      'a.clear',
      pipe(
        _p.if2(function () {
          return _p.size(
            omit(
              ['cate_item_id', 'ordering' /*, 'cut_img' 한글버전*/],
              window.box.sel('product_list->query'),
            ),
          );
        })(clickProductListClear, updateProductListView()),
      ),
    ),
    $.on(
      'click',
      'div.clear',
      pipe(
        sel('currentTarget'),
        $.next('ul'),
        $.find('input'),
        each((input) => (input.checked = false)),
        each(checkedFilterItem),
        $.closest('.filter_item'),
        $.remove,
        updateProductListView(),
      ),
    ),
    $.on(
      'change',
      '.filter_selector_wrap.pc_v .filter_list:not([filter_list_id="color"]) .filter_item input[type="checkbox"]:not(.ing)',
      pipe(
        sel('currentTarget'),
        _p.if2(negate($.has_class('ing')))(
          $.add_class('ing'),
          checkedFilterItem,
          tap($.closest('.filter_list'), tap(updateProductListView()), changFilterListCount),
          $.remove_class('ing'),
        ),
      ),
    ),
    $.on(
      'change',
      '.filter_selector_wrap.pc_v .filter_list[filter_list_id="color"] .filter_item input[type="checkbox"]:not(.ing)',
      pipe(
        sel('currentTarget'),
        _p.if2(negate($.has_class('ing')))(
          tap(
            $.closest('.filter_item'),
            $.siblings('.filter_item'),
            $.attr({ selected: false }),
            each(
              pipe(
                $.find1('input'),
                tap((input) => (input.checked = false)),
                checkedFilterItem,
              ),
            ),
          ),
          $.add_class('ing'),
          checkedFilterItem,
          tap($.closest('.filter_list'), tap(updateProductListView()), changFilterListCount),
          $.remove_class('ing'),
        ),
      ),
    ),
    $.on(
      'click',
      '#selected_cate_list.mobile_v',
      pipe(sel('currentTarget'), $, function (target) {
        if (IS_PRODUCT_LIST_PC_SIZE) return;
        const cate_list_wrap = $.siblings($.toggle_class(target, 'open'), '.cate_list_wrap');
        return $.css(cate_list_wrap, {
          height: $.has_class(target, 'open') ? $.outerHeight($.find1(cate_list_wrap, 'ul.cate_lists')) : 0,
        });
      }),
    ),
    // $.on('click', '#side_bar_wrap', function(e) {
    //   if (e.target !== e.currentTarget) return ;
    //   var cate_list_wrap = $.find1(e.currentTarget, '.cate_list_wrap');
    //   $.css(cate_list_wrap, { 'height': 0});
    //   $.remove_class(e.currentTarget, 'is_open');
    //   // if (e.target == e.currentTarget) $.trigger($1('#selected_cate_list.mobile_v'), 'click');
    // }),
    $.on('click', '#product_list_body .count_sort .filter', function () {
      return $.frame.open({
        frame_name: 'mp.product.list.filter',
        page_name: 'mp.product.list.filter',
      });
    }),
    $.on(
      'change',
      '.selected_filter_items li.selected_filter_item input',
      pipe(
        sel('currentTarget'),
        checkedFilterItem,
        $.closest('.selected_filter_item'),
        $.remove,
        function (selected_filter_item) {
          if (!IS_PRODUCT_LIST_PC_SIZE) return go(void 0, updateProductListView());
          const filter_item = window.box.sel(selected_filter_item);
          const selected = $1(
            '#side_bar .filter_selector_wrap.pc_v li.filter_list[filter_list_id="' +
              filter_item.filter_list_id +
              '"] li.filter_item[selected="true"][filter_item_id="' +
              filter_item.id +
              '"] input',
          );
          $.trigger(selected, 'click');
        },
      ),
    ),
  ),
});

export const defnProductListFilterTab = () => ({
  tab_name: 'mp.product.list.filter',
  data_func: function () {
    return window.box.sel('product_list->bp_info');
  },
  template: (bp_info) => legacyHtml`
    <div class="filter_list_wrap">
      ${makeSelectedFilterItemsHtml(bp_info)}
      <div class="filter_lists_body">${makeProductFilterListHtml(bp_info.filter_lists)}</div>
    </div>
  `,
  appended: pipe(
    $.on('click', '.product_filter_list .filter_list', function (e) {
      const filter_list = window.box.sel(e.currentTarget);
      $.frame.add_page({
        tabs: [
          {
            tab_name: 'mp.product.list.filter2',
            data_func: function () {
              return filter_list;
            },
          },
        ],
        page_name: 'mp.product.list.filter2',
        appending: function (el_page) {
          $.remove($.find1(el_page, '.don_back_page'));
        },
        showing: function (el_page) {
          $.once(
            $.html(
              $.find1($.closest(el_page, '.don_frame'), '>.don_wrapper >.header .title'),
              legacyHtml`
                <button class="don_back_page" type="button"></button>
                <div class="title2">${filter_list.name}</div>
              `,
            ),
            'click',
            '.don_back_page',
            $.frame.back_page,
          );
        },
        removing: function () {
          changeSelectedFilterItem(e.currentTarget);
          changFilterListCount(e.currentTarget);
        },
      });
    }),
    $.on(
      'change',
      '.selected_filter_items li.selected_filter_item input',
      pipe(
        sel('currentTarget'),
        checkedFilterItem,
        changeSelectedFilterItem,
        constant('.don_tab[tab_name="mp.product.list.filter"] .product_filter_list .filter_list'),
        $,
        each(changFilterListCount),
      ),
    ),
    $.on('click', '.clear', function (e) {
      clickProductListClear(e);
      changeSelectedFilterItem(e.currentTarget);
    }),
  ),
});

export const defnProductListFilterTab2 = () => ({
  tab_name: 'mp.product.list.filter2',
  template: (filter_list) => legacyHtml`
    <div
      class="filter_item_wrap"
      filter_list_type="{{filter_list.id}}"
      _sel="product_list->bp_info->filter_lists->(fl=>fl.id=='${filter_list.id}')"
    >
      <div class="body">${makeFilterItemsHtml(filter_list._.filter_items)}</div>
    </div>
  `,
  appended: pipe(
    $.on(
      'change',
      'li.filter_item input:not([filter_type="f_color"])',
      pipe(sel('currentTarget'), checkedFilterItem),
    ),
    $.on(
      'change',
      'li.filter_item input[filter_type="f_color"]',
      pipe(
        sel('currentTarget'),
        checkedFilterItem,
        $.closest('.filter_item'),
        tap(
          $.siblings('.filter_item'),
          $.attr({ selected: false }),
          $.find('input'),
          each(function (input) {
            input.checked = false;
            checkedFilterItem(input);
          }),
        ),
      ),
    ),
  ),
});
