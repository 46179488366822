export const kakao = () => {};

export const signUp = () => {
  try {
    if (T.lang == 'kr' && typeof kakaoPixel == 'function') {
      kakaoPixel('2648073228351915297').completeRegistration();
    }
  } catch (e) {}
};

export const bizGoodsTalk = () => {
  try {
    if (T.lang == 'kr' && typeof kakaoPixel == 'function') {
      kakaoPixel('2648073228351915297').signUp();
    }
  } catch (e) {}
};
