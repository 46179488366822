const duration = 200;
const easing = 'easeInOutQuart';
export const showPcRightFixedPanel = async ({ right_el, left_el }) => {
  await Promise.all([
    anime({
      targets: [left_el],
      translateX: '0',
      opacity: 1,
      duration: 0,
    }).finished,
    anime({
      targets: [right_el],
      translateX: '20%',
      opacity: 0,
      duration: 0,
    }).finished,
  ]);
  return Promise.all([
    anime({
      targets: [left_el],
      translateX: '-20%',
      opacity: 0,
      duration,
      easing,
    }).finished,
    anime({
      targets: [right_el],
      translateX: '0',
      opacity: 1,
      duration,
      easing,
    }).finished,
  ]);
};
export const hidePcRightFixedPanel = async ({ right_el, left_el }) => {
  await Promise.all([
    anime({
      targets: [left_el],
      translateX: '-20%',
      opacity: 0,
      duration: 0,
    }).finished,
    anime({
      targets: [right_el],
      translateX: '0',
      opacity: 1,
      duration: 0,
    }).finished,
  ]);
  return Promise.all([
    anime({
      targets: [right_el],
      translateX: '20%',
      opacity: 0,
      duration,
      easing,
    }).finished,
    anime({
      targets: [left_el],
      translateX: '0',
      opacity: 1,
      duration,
      easing,
    }).finished,
  ]);
};
